import { request, getBaseUrl } from '~/utils/request'
import {
  XeroConnectionState,
  XeroTenantState,
  XeroTenantMapState,
  XeroLineItemMapState,
  XeroAccountState,
  XeroContactState,
  XeroConfigState,
  IUpdateXeroConfig
} from '../types'

const baseUrl = getBaseUrl('/payroll-sg/xero')

export const apiGetXeroAuthUrl = () => request<string>('get', `${baseUrl}/login`)

export const apiGetXeroAuthToken = (authorizationCode: string, state: string) =>
  request<boolean>('post', `${baseUrl}/token`, { authorizationCode, state })

export const apiGetXeroConnections = () => request<XeroConnectionState[]>('get', `${baseUrl}/connection`)

export const apiDisconnectXero = (connectionId: string) =>
  request<boolean>('delete', `${baseUrl}/connection/${connectionId}`)

export const apiGetXeroTenants = () => request<XeroTenantState[]>('get', `${baseUrl}/tenant`)

export const apiGetXeroTenantMaps = () => request<XeroTenantMapState[]>('get', `${baseUrl}/tenantmap`)

export const apiUpdateXeroTenantMaps = (data: XeroTenantMapState[]) =>
  request<XeroTenantMapState[]>('post', `${baseUrl}/tenantmap`, data)

export const apiGetXeroAccounts = (tenantId: string) =>
  request<XeroAccountState[]>('get', `${baseUrl}/${tenantId}/account`)

export const apiGetXeroContacts = (tenantId: string) =>
  request<XeroContactState[]>('get', `${baseUrl}/${tenantId}/contact`)

export const apiGetXeroConfig = (companyId: string) => request<XeroConfigState>('get', `${baseUrl}/${companyId}/config`)

export const apiUpdateXeroConfig = (companyId: string, data: IUpdateXeroConfig) =>
  request<XeroConfigState>('post', `${baseUrl}/${companyId}/config`, data)

export const apiGetXeroLineItemMaps = (companyId: string) =>
  request<XeroLineItemMapState[]>('get', `${baseUrl}/${companyId}/lineitemmap`)

export const apiUpdateXeroLineItemMaps = (companyId: string, data: XeroLineItemMapState[]) =>
  request<XeroLineItemMapState[]>('post', `${baseUrl}/${companyId}/lineitemmap`, data)
