import React, { ChangeEvent, CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import confirm from 'antd/lib/modal/confirm'
import { Col, DrawerForm, PercentInput, Row, SalaryInput, SysOptions } from '~/components'
import { Button, Form, Input, Space } from '~/core-components'
import { Screen, useViewSchemaByName, ViewCriteria, ViewCriteriaAdvanced } from '~/features/selection'
import { ActionResult, Errors } from '~/types/store'
import { dispatch } from '~/stores/store'
import { useFocus } from '~/hooks/use-focus'
import { ClaEntitlementType, ClaExpenseSubmissionType, ClaPerTimeLimit, emptyGuid } from '~/constants'
import { newGuid } from '~/utils'
import { ClaimTypeState, CtPolicyState } from '../../../types'
import { addCtPolicy, deleteCtPolicy, updateCtPolicy } from '../../../actions'
import { CtPolicyExpenseType } from './CtPolicyExpenseType'
import './MutateCtPolicyDrawer.less'

interface MutateCtPolicyDrawerProps {
  visible: boolean
  claimType?: ClaimTypeState
  data?: CtPolicyState
  onClose: () => void
}

type FormData = CtPolicyState & {
  criteria: ViewCriteria[]
  expenseTypes: ViewCriteria[]
}

const EMPTY_FORM_DATA: FormData = {
  id: '',
  claimTypeId: '',
  policyName: '',
  requireConfirm: '',
  requireMonths: 0,
  viewId: '',
  criteria: [],
  entitlementAmount: 0,
  dailyLimit: 0,
  monthlyLimit: 0,
  perTimeAmount: 0,
  perTimePercentage: 0,
  perTimeEmAmount: 0,
  maxTimes: 0,
  maxTimesBasis: '',
  limitBasis: '',
  minSubmissionInterval: 0,
  minSubmissionIntervalUnit: '',
  expenseTypes: []
}

const SCREEN_CODE: Screen = 'claim_type_policy'
const btnGroupsStyle: CSSProperties = { marginBottom: 10, flexWrap: 'wrap' }
const btnLimitStyle: CSSProperties = { marginBottom: 8 }

export const MutateCtPolicyDrawer: FC<MutateCtPolicyDrawerProps> = ({ visible, claimType, data, onClose }) => {
  const [loading, setLoading] = useState(false)
  const [isNew, setIsNew] = useState(false)
  const [errors, setErrors] = useState<Errors>()
  const [newId, setNewId] = useState<string>()
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)
  const [focusRef] = useFocus(true)
  const claimTypeId = claimType?.id || ''
  const viewName = formData?.id ? `ct_policy_${formData.id}` : undefined
  const [view] = useViewSchemaByName(SCREEN_CODE, viewName, false)
  const viewId = view?.id || ''
  const [resetCriteria, setResetCriteria] = useState<number>(0)

  const [perTime, setPerTime] = useState<ClaPerTimeLimit>(ClaPerTimeLimit.Amount)
  const [showDailyLimit, setShowDailyLimit] = useState(false)
  const [showMonthlyLimit, setShowMonthlyLimit] = useState(false)
  const [showPerTime, setShowPerTime] = useState(false)
  const [showMaxTimes, setShowMaxTimes] = useState(false)
  const [showMinSubmissionInterval, setShowMinSubmissionInterval] = useState(false)

  const isMultiple = claimType?.expenseSubmissionType === ClaExpenseSubmissionType.Multiple
  const hasEntitlement =
    claimType?.entitlementType === ClaEntitlementType.PerPeriod ||
    claimType?.entitlementType === ClaEntitlementType.PerDiem

  const subtitleEntitlement = hasEntitlement
    ? isMultiple
      ? 'Entitlement'
      : 'Entitlement & limit'
    : !isMultiple
    ? 'Limit'
    : ''

  useEffect(() => {
    setNewId(newGuid())
  }, [])

  useEffect(() => {
    setResetCriteria(reset => reset + 1)
  }, [viewName])

  useEffect(() => {
    setIsNew(false)
    setErrors(undefined)
  }, [visible])

  useEffect(() => {
    if (visible && isMultiple) {
      setFormData({ ...EMPTY_FORM_DATA, viewId: view?.id, id: newId || '' })
    }
  }, [visible, isMultiple, newId, view?.id])

  useDeepCompareEffect(() => {
    if (data) {
      setFormData({ ...data, viewId: view?.id, criteria: view?.criteria || [], expenseTypes: [] })

      setShowDailyLimit(data.dailyLimit !== 0)
      setShowMonthlyLimit(data.monthlyLimit !== 0)
      setShowPerTime(data.perTimeAmount !== 0 || data.perTimeEmAmount !== 0 || data.perTimePercentage !== 0)
      setShowMaxTimes(data.maxTimes !== 0)
      setShowMinSubmissionInterval(data.minSubmissionInterval !== 0)
    } else {
      setFormData({ ...EMPTY_FORM_DATA, viewId: view?.id, id: newId || '' })
    }
  }, [data || {}, view || {}, newId])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setErrors(undefined)
    setFormData(data => ({ ...data, ...updates }))
  }, [])

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      if (isNew || data == null) {
        if (claimTypeId) {
          result = await dispatch(addCtPolicy(claimTypeId, { ...formData, id: emptyGuid, claimTypeId }))
        }
      } else {
        if (data) {
          result = await dispatch(updateCtPolicy(claimTypeId, data.id, formData))
        }
      }
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData({ ...EMPTY_FORM_DATA, viewId })
    }
  }, [isNew, claimTypeId, data, formData, viewId, onClose])

  const handleDelete = useCallback(
    (ctPolicy: CtPolicyState | undefined) => {
      if (ctPolicy) {
        const { id, claimTypeId } = ctPolicy
        confirm({
          title: 'Delete claim policy',
          content: `Do you want to delete claim policy?`,
          onOk: async () => {
            const result: ActionResult | undefined = await dispatch(deleteCtPolicy(claimTypeId, id))
            if (result?.errors) {
              setErrors(result.errors)
            }
            if (!result?.errors) {
              typeof onClose === 'function' && onClose()
            }
          },
          okText: 'Delete',
          okType: 'danger'
        })
      }
    },
    [onClose]
  )

  const handleShowDailyLimit = useCallback(() => {
    setShowDailyLimit(show => !show)
    handleFormDataChange({ dailyLimit: 0 })
  }, [handleFormDataChange])

  const handleShowMonthlyLimit = useCallback(() => {
    setShowMonthlyLimit(show => !show)
    handleFormDataChange({ monthlyLimit: 0 })
  }, [handleFormDataChange])

  const handleShowPerTime = useCallback(() => {
    setShowPerTime(show => !show)
    handleFormDataChange({ perTimeAmount: 0, perTimeEmAmount: 0, perTimePercentage: 0 })
  }, [handleFormDataChange])

  const handleShowMaxTimes = useCallback(() => {
    setShowMaxTimes(show => !show)
    handleFormDataChange({ maxTimes: 0, maxTimesBasis: '' })
  }, [handleFormDataChange])

  const handleShowMinSubmissionInterval = useCallback(() => {
    setShowMinSubmissionInterval(show => !show)
    handleFormDataChange({ minSubmissionInterval: 0, minSubmissionIntervalUnit: '' })
  }, [handleFormDataChange])

  return (
    <DrawerForm
      open={visible}
      title="Claim policy"
      onClose={onClose}
      confirmLoading={loading}
      width={750}
      showDelete={isNew || data == null ? false : true}
      onDelete={() => handleDelete(data)}
      className="mutate-ct-policy-drawer"
      formId="form-ct-policy"
    >
      <Form id="form-ct-policy" onFinish={handleOk}>
        <Row gutter={30}>
          <Col flex="1">
            <Form.Item label="Name" validateStatus={errors?.policyName ? 'error' : ''} help={errors?.policyName}>
              <Input
                value={formData.policyName}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ policyName: e.target.value })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col span={17}>
            <Form.Item
              label="Requirements"
              validateStatus={errors?.requireConfirm ? 'error' : ''}
              help={errors?.requireConfirm}
            >
              <SysOptions
                ref={focusRef}
                type="cla_require_confirm"
                value={formData.requireConfirm}
                onChange={(requireConfirm: string) => handleFormDataChange({ requireConfirm })}
              />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              label="Min. service months"
              validateStatus={errors?.requireMonths ? 'error' : ''}
              help={errors?.requireMonths}
              tooltip={{
                title: 'Leave the value as 0 if there is no min. service months required',
                icon: (
                  <span>
                    <i className="fal fa-circle-info" />
                  </span>
                )
              }}
            >
              <Input.Number
                min={0}
                value={formData.requireMonths}
                onChange={(requireMonths: number | null) => handleFormDataChange({ requireMonths })}
              />
            </Form.Item>
          </Col>
        </Row>
        <div className="ct-policy__subtitle">Criteria</div>
        <ViewCriteriaAdvanced
          screenCode={SCREEN_CODE}
          viewId={view?.id}
          label=""
          onChange={(criteria: ViewCriteria[]) => handleFormDataChange({ criteria })}
          reset={resetCriteria}
        />
        {subtitleEntitlement && <div className="ct-policy__subtitle">{subtitleEntitlement}</div>}
        <Row hidden={!hasEntitlement}>
          <Col span={12}>
            <Form.Item
              label="Entitlement amount"
              validateStatus={errors?.entitlementAmount ? 'error' : ''}
              help={errors?.entitlementAmount}
            >
              <Space>
                <span>{claimType?.currencyCode.toUpperCase()}</span>
                <SalaryInput
                  value={formData.entitlementAmount}
                  onChange={(entitlementAmount: number | null) => handleFormDataChange({ entitlementAmount })}
                />
              </Space>
            </Form.Item>
          </Col>
        </Row>
        <Row
          hidden={
            isMultiple ||
            (showDailyLimit && showMonthlyLimit && showPerTime && showMaxTimes && showMinSubmissionInterval)
          }
        >
          <Col span={24}>
            <Space style={btnGroupsStyle}>
              {!showDailyLimit && (
                <Button size="small" style={btnLimitStyle} onClick={handleShowDailyLimit}>
                  <Space>
                    <i className="fal fa-plus" />
                    add daily limit
                  </Space>
                </Button>
              )}
              {!showMonthlyLimit && (
                <Button size="small" style={btnLimitStyle} onClick={handleShowMonthlyLimit}>
                  <Space>
                    <i className="fal fa-plus" />
                    add monthly limit
                  </Space>
                </Button>
              )}
              {!showPerTime && (
                <Col span={12}>
                  <Button size="small" style={btnLimitStyle} onClick={handleShowPerTime}>
                    <Space>
                      <i className="fal fa-plus" />
                      add per time limit
                    </Space>
                  </Button>
                </Col>
              )}
              {!showMinSubmissionInterval && (
                <Button size="small" style={btnLimitStyle} onClick={handleShowMinSubmissionInterval}>
                  <Space>
                    <i className="fal fa-plus" />
                    add minimum submission interval
                  </Space>
                </Button>
              )}
              {!showMaxTimes && (
                <Button size="small" style={btnLimitStyle} onClick={handleShowMaxTimes}>
                  <Space>
                    <i className="fal fa-plus" />
                    add maximum no. of request limit
                  </Space>
                </Button>
              )}
            </Space>
          </Col>
        </Row>

        {isMultiple ? (
          <>
            <div className="ct-policy__subtitle">Expenses setting</div>
            <CtPolicyExpenseType
              claimTypeId={claimTypeId}
              ctPolicyId={formData.id}
              errors={errors}
              onChange={handleFormDataChange}
            />
          </>
        ) : (
          <>
            <Row>
              <Col span={12}>
                {showDailyLimit && (
                  <Form.Item
                    label="Daily limit"
                    validateStatus={errors?.dailyLimit ? 'error' : ''}
                    help={errors?.dailyLimit}
                  >
                    <Space>
                      <SalaryInput
                        min={0}
                        value={formData.dailyLimit}
                        onChange={(dailyLimit: number | null) => handleFormDataChange({ dailyLimit })}
                      />
                      <Button
                        size="small"
                        type="text"
                        onClick={handleShowDailyLimit}
                        icon={<i className="fal fa-xmark" />}
                      />
                    </Space>
                  </Form.Item>
                )}
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                {showMonthlyLimit && (
                  <Form.Item
                    label="Monthly limit"
                    validateStatus={errors?.monthlyLimit ? 'error' : ''}
                    help={errors?.monthlyLimit}
                  >
                    <Space>
                      <SalaryInput
                        min={0}
                        value={formData.monthlyLimit}
                        onChange={(monthlyLimit: number | null) => handleFormDataChange({ monthlyLimit })}
                      />
                      <Button
                        size="small"
                        type="text"
                        onClick={handleShowMonthlyLimit}
                        icon={<i className="fal fa-xmark" />}
                      />
                    </Space>
                  </Form.Item>
                )}
              </Col>
            </Row>
            <Row gutter={15}>
              {showPerTime && (
                <>
                  <Col span={12}>
                    <Form.Item label="Per time limit">
                      <SysOptions
                        type="cla_per_time_limit"
                        value={perTime}
                        onChange={(perTime: ClaPerTimeLimit) => {
                          setPerTime(perTime)
                          handleFormDataChange({ perTimeAmount: 0, perTimePercentage: 0, perTimeEmAmount: 0 })
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Amount"
                      validateStatus={
                        errors?.perTimeAmount || errors?.perTimePercentage || errors?.perTimeEmAmount ? 'error' : ''
                      }
                      help={errors?.perTimeAmount || errors?.perTimePercentage || errors?.perTimeEmAmount}
                    >
                      <Space>
                        {perTime === ClaPerTimeLimit.Amount && (
                          <SalaryInput
                            min={0}
                            value={formData.perTimeAmount}
                            onChange={(perTimeAmount: number | null) =>
                              handleFormDataChange({ perTimeAmount, perTimePercentage: 0, perTimeEmAmount: 0 })
                            }
                          />
                        )}
                        {perTime === ClaPerTimeLimit.Percentage && (
                          <PercentInput
                            value={formData.perTimePercentage}
                            onChange={(perTimePercentage: number | null) =>
                              handleFormDataChange({ perTimePercentage, perTimeAmount: 0, perTimeEmAmount: 0 })
                            }
                          />
                        )}
                        {perTime === ClaPerTimeLimit.EmAmount && (
                          <SalaryInput
                            min={0}
                            value={formData.perTimeEmAmount}
                            onChange={(perTimeEmAmount: number | null) =>
                              handleFormDataChange({ perTimeEmAmount, perTimeAmount: 0, perTimePercentage: 0 })
                            }
                          />
                        )}
                        <Button
                          size="small"
                          type="text"
                          onClick={handleShowPerTime}
                          icon={<i className="fal fa-xmark" />}
                        />
                      </Space>
                    </Form.Item>
                  </Col>
                </>
              )}
            </Row>
            <Row>
              <Col span={24}>
                {showMinSubmissionInterval && (
                  <Form.Item
                    label="Minimum submission interval"
                    validateStatus={errors?.minSubmissionInterval || errors?.minSubmissionIntervalUnit ? 'error' : ''}
                    help={errors?.minSubmissionInterval || errors?.minSubmissionIntervalUnit}
                  >
                    <Row gutter={15} align="middle">
                      <Col flex="none">
                        <Input.Number
                          min={0}
                          value={formData.minSubmissionInterval}
                          onChange={(minSubmissionInterval: number | null) =>
                            handleFormDataChange({ minSubmissionInterval })
                          }
                        />
                      </Col>
                      <Col span={4}>
                        <SysOptions
                          type="cla_min_submission_interval_unit"
                          allowClear={false}
                          value={formData.minSubmissionIntervalUnit}
                          onChange={(minSubmissionIntervalUnit: string) =>
                            handleFormDataChange({ minSubmissionIntervalUnit })
                          }
                        />
                      </Col>
                      <Col flex="none">
                        <Button
                          size="small"
                          type="text"
                          onClick={handleShowMinSubmissionInterval}
                          icon={<i className="fal fa-xmark" />}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                )}
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                {showMaxTimes && (
                  <Form.Item
                    label="Maximum no. of request(s)"
                    validateStatus={errors?.maxTimes || errors?.maxTimesBasis ? 'error' : ''}
                    help={errors?.maxTimes || errors?.maxTimesBasis}
                  >
                    <Row gutter={15} align="middle">
                      <Col flex="none">
                        <Input.Number
                          min={0}
                          value={formData.maxTimes}
                          onChange={(maxTimes: number | null) => handleFormDataChange({ maxTimes })}
                        />
                      </Col>
                      <Col span={12}>
                        <SysOptions
                          type="cla_max_times_basis"
                          allowClear={false}
                          value={formData.maxTimesBasis}
                          onChange={(maxTimesBasis: string) => handleFormDataChange({ maxTimesBasis })}
                        />
                      </Col>
                      <Col flex="none">
                        <Button
                          size="small"
                          type="text"
                          onClick={handleShowMaxTimes}
                          icon={<i className="fal fa-xmark" />}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                )}
              </Col>
            </Row>
          </>
        )}
      </Form>
    </DrawerForm>
  )
}
