import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { OtConfigState } from '../types'
import { FetchStrategy } from '~/types/common'
import { fetchOtConfigs } from '../actions'
import { Dictionary } from '@reduxjs/toolkit'

export const useOtConfigsDict = (fetchStrategy: FetchStrategy = 'always'): [Dictionary<OtConfigState>, boolean] => {
  const data = useSelector((state: StoreState) => state.attendance.otConfigs.entities)
  const loading = useSelector((state: StoreState) => state.attendance.otConfigsLoading)

  useEffect(() => {
    dispatch(fetchOtConfigs({ strategy: fetchStrategy }))
  }, [fetchStrategy])

  return [data, loading]
}
