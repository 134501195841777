import React, { ChangeEvent, CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import Tooltip from 'antd/lib/tooltip'
import { Alert, Form, Input, SecondaryText } from '~/core-components'
import {
  Col,
  EditableCard,
  EditableCardState,
  ReasonKeyValues,
  Row,
  EmpKeyValues,
  InfoTooltip,
  NewTabLinkIcon,
  SysOptions
} from '~/components'
import { usePermissionGate } from '~/features/iam/hooks'
import { HiringType, Permission, PermissionAction } from '~/constants'
import { formatYearMonth } from '~/utils'
import { useFocus } from '~/hooks/use-focus'
import { useFirstInView } from '~/hooks/use-first-in-view'
import { dispatch } from '~/stores/store'
import { Errors, ActionResult } from '~/types/store'
import { IEmployeeHire, EmployeeState } from '../../../types'
import { selectCurrentEmCompany } from '../../../selectors'
import { mapEmployeeStateToEmployeeEmploymentPeriod } from '../../../types/employee.mapper'
import { fetchEmCompanies, updateEmployeeHire } from '../../../actions'
import { useSysOptions } from '../../../hooks'
import { SETTINGS_ROUTES } from '~/routes/routes'

interface EmployeeHireProps {
  employee?: EmployeeState
  onEdit?: () => void
  onSave?: () => void
  onCancel?: () => void
}

const EMPTY_FORM_DATA: IEmployeeHire = {
  companyId: '',
  hireDate: '',
  hiringType: HiringType.newHire,
  initialHireDate: '',
  employmentEndDate: '',
  terminationNoticeDate: '',
  terminationReasonId: '',
  terminationNotes: '',
  serviceLength: '',
  initialServiceLength: '',
  emStatus: ''
}

const cardStyle: CSSProperties = { margin: 24 }
const labelSecondaryStyle: CSSProperties = { marginLeft: 10 }

export const EmployeeHire: FC<EmployeeHireProps> = ({ employee, onEdit, onSave, onCancel }: EmployeeHireProps) => {
  const { ref, inView } = useFirstInView<HTMLDivElement>({ threshold: 0.25 })
  const [cardState, setCardState] = useState<EditableCardState>()
  const [formData, setFormData] = useState<IEmployeeHire>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const [focusRef] = useFocus(cardState === 'editing')
  const emCompany = useSelector(selectCurrentEmCompany)(employee?.id, employee?.emCompanyId)
  const [emStatuses] = useSysOptions('em_status')
  const canView = usePermissionGate(Permission.employeeHire)
  const canModify = usePermissionGate(Permission.employeeHire, PermissionAction.Modify)
  const canModifyMs = usePermissionGate(Permission.master, PermissionAction.Modify)
  const readOnly = cardState !== 'editing' && cardState !== 'saving'
  const [hireDateChanged, setHireDateChanged] = useState(false)

  useEffect(() => {
    if (inView) {
      if (employee?.id) {
        dispatch(fetchEmCompanies(employee.id))
      }
    }
  }, [inView, employee])

  useEffect(() => {
    if (employee) {
      const companyId = emCompany?.companyId || ''
      const {
        hireDate,
        hiringType,
        initialHireDate,
        employmentEndDate,
        terminationNoticeDate,
        terminationReasonId,
        terminationNotes,
        serviceLength,
        initialServiceLength,
        emStatus
      } = employee
      setFormData({
        companyId,
        hireDate,
        hiringType,
        initialHireDate,
        employmentEndDate,
        terminationNoticeDate,
        terminationReasonId,
        terminationNotes,
        serviceLength,
        initialServiceLength,
        emStatus
      })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [emCompany, employee])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    if ('hireDate' in updates) setHireDateChanged(true)

    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  const handleEdit = useCallback(() => {
    setCardState('editing')
    typeof onEdit === 'function' && onEdit()
  }, [onEdit])

  const handleSave = useCallback(async () => {
    if (employee) {
      setCardState('saving')
      setErrors(undefined)

      typeof onSave === 'function' && onSave()

      let result: ActionResult | undefined
      try {
        result = await dispatch(
          updateEmployeeHire(
            employee.id,
            mapEmployeeStateToEmployeeEmploymentPeriod(employee, emCompany?.companyId || ''),
            formData
          )
        )
      } catch {
        setCardState('editing')
      }

      if (result?.errors) {
        setCardState('editing')
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setCardState(undefined)
        setHireDateChanged(false)
      }
    }
  }, [employee, emCompany, formData, onSave])

  const handleCancel = useCallback(() => {
    typeof onCancel === 'function' && onCancel()
    setCardState(undefined)
    setErrors(undefined)
    setHireDateChanged(false)

    if (employee) {
      const companyId = emCompany?.companyId || ''
      const {
        hireDate,
        hiringType,
        initialHireDate,
        employmentEndDate,
        terminationNoticeDate,
        terminationReasonId,
        terminationNotes,
        serviceLength,
        initialServiceLength,
        emStatus
      } = employee
      setFormData({
        companyId,
        hireDate,
        hiringType,
        initialHireDate,
        employmentEndDate,
        terminationNoticeDate,
        terminationReasonId,
        terminationNotes,
        serviceLength,
        initialServiceLength,
        emStatus
      })
    }
  }, [emCompany, employee, onCancel])

  if (!canView) return null

  return (
    <div ref={ref}>
      <EditableCard
        title="Employment period"
        style={cardStyle}
        bodyStyle={{ paddingBottom: employee ? 6 : 24, paddingTop: 6 }}
        state={canModify ? cardState : 'readonly'}
        formId="form-em-employment-period"
        onEdit={handleEdit}
        onSave={handleSave}
        onCancel={handleCancel}
      >
        <Row gutter={30}>
          <Col span={24}>
            <Form.Item className="form-item-label--block" label="Company">
              <EmpKeyValues
                id="company"
                ref={focusRef}
                value={formData.companyId}
                onChange={(value: number | null) => handleFormDataChange({ companyId: value })}
                readOnly={readOnly}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col span={12}>
            <Form.Item label="Hire date" validateStatus={errors?.hireDate ? 'error' : ''} help={errors?.hireDate}>
              <Form.Item noStyle>
                <Input.Date
                  value={formData.hireDate ? moment(formData.hireDate) : undefined}
                  inputReadOnly={readOnly}
                  onChange={(value: moment.Moment | null) => {
                    handleFormDataChange({ hireDate: value?.format('YYYY-MM-DD') })
                  }}
                />
              </Form.Item>
              <Tooltip title="Length of service">
                <SecondaryText style={labelSecondaryStyle}>{formatYearMonth(employee?.serviceLength)}</SecondaryText>
              </Tooltip>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="End of employment"
              validateStatus={errors?.employmentEndDate ? 'error' : ''}
              help={errors?.employmentEndDate}
            >
              <Form.Item noStyle>
                <Input.Date
                  value={formData.employmentEndDate ? moment(formData.employmentEndDate) : undefined}
                  inputReadOnly={readOnly}
                  onChange={(value: moment.Moment | null) =>
                    handleFormDataChange({ employmentEndDate: value?.format('YYYY-MM-DD') })
                  }
                />
              </Form.Item>
              <Tooltip title="Status">
                <SecondaryText style={labelSecondaryStyle}>{emStatuses[formData.emStatus]?.value}</SecondaryText>
              </Tooltip>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col span={12}>
            <Form.Item label="Hiring type">
              <SysOptions
                type="hiring_type"
                value={formData.hiringType}
                readOnly={readOnly}
                onChange={(hiringType: string) => handleFormDataChange({ hiringType })}
              />
            </Form.Item>
          </Col>
          <Col span={12} hidden={readOnly ? !formData.terminationNoticeDate : !formData.employmentEndDate}>
            <Form.Item label="Date of notice given">
              <Form.Item noStyle>
                <Input.Date
                  value={formData.terminationNoticeDate ? moment(formData.terminationNoticeDate) : undefined}
                  inputReadOnly={readOnly}
                  onChange={(value: moment.Moment | null) =>
                    handleFormDataChange({ terminationNoticeDate: value?.format('YYYY-MM-DD') })
                  }
                />
              </Form.Item>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col span={12} hidden={readOnly ? !formData.initialHireDate : false}>
            <Form.Item
              label={
                <>
                  Initial hire date
                  <InfoTooltip title="Only applicable if an employee has an earlier initial hire date for leave entitlement calculation" />
                </>
              }
              validateStatus={errors?.initialHireDate ? 'error' : ''}
              help={errors?.initialHireDate}
            >
              <Form.Item noStyle>
                <Input.Date
                  value={formData.initialHireDate ? moment(formData.initialHireDate) : undefined}
                  inputReadOnly={readOnly}
                  onChange={(value: moment.Moment | null) => {
                    handleFormDataChange({ initialHireDate: value?.format('YYYY-MM-DD') })
                  }}
                />
              </Form.Item>
              {employee?.initialServiceLength && employee.initialServiceLength !== '0000' && (
                <Tooltip title="Length of service">
                  <SecondaryText style={labelSecondaryStyle}>
                    {formatYearMonth(employee.initialServiceLength)}
                  </SecondaryText>
                </Tooltip>
              )}
            </Form.Item>
          </Col>
          <Col span={12} hidden={readOnly && !formData.initialHireDate ? !formData.terminationReasonId : true}></Col>
          <Col span={12} hidden={readOnly ? !formData.terminationReasonId : !formData.employmentEndDate}>
            <Form.Item label="Reason">
              <Form.Item noStyle>
                <NewTabLinkIcon
                  path={SETTINGS_ROUTES.reasons}
                  tooltipText="Open reason in a new tab"
                  tooltipPlacement="topRight"
                  hidden={!canModifyMs}
                >
                  <ReasonKeyValues
                    type="termination"
                    value={formData.terminationReasonId}
                    readOnly={readOnly}
                    onChange={(value: string) => handleFormDataChange({ terminationReasonId: value })}
                  />
                </NewTabLinkIcon>
              </Form.Item>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30} hidden={readOnly ? !formData.terminationNotes : !formData.employmentEndDate}>
          <Col span={12}></Col>
          <Col span={12}>
            <Form.Item label="Notes">
              <Form.Item noStyle>
                <Input.TextArea
                  value={formData.terminationNotes}
                  readOnly={readOnly}
                  rows={4}
                  onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                    handleFormDataChange({ terminationNotes: event.target.value })
                  }
                />
              </Form.Item>
            </Form.Item>
          </Col>
        </Row>
        <Row hidden={!hireDateChanged}>
          <Col span={24}>
            <Alert
              type="info"
              style={{ marginBottom: 20 }}
              showIcon
              message={
                <div style={{ marginLeft: 10 }}>
                  Changing the hire date may affect other employee data. Please review and update the effective dates of
                  the following fields accordingly:
                  <ul style={{ marginLeft: 10 }}>
                    <li>Employment information (e.g. department, team, job title, etc)</li>
                    <li>Work calendar</li>
                    <li>Salary</li>
                    <li>Payroll group</li>
                    <li>Leave group</li>
                    <li>Location</li>
                    <li>Shift role</li>
                  </ul>
                </div>
              }
            />
          </Col>
        </Row>
      </EditableCard>
    </div>
  )
}
