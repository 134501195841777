import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchClaimRecord } from '../actions'
import { ClaimRecordState } from '../types'

export const useClaimRecord = (id?: string): [ClaimRecordState | undefined, boolean] => {
  const claimRecord = useSelector((state: StoreState) => state.claim.claimRecords?.entities[id || ''])
  const loading = useSelector((state: StoreState) => state.claim.claimRecordsLoading)

  useEffect(() => {
    if (id) {
      dispatch(fetchClaimRecord(id))
    }
  }, [id])

  return [claimRecord, loading]
}
