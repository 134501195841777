import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { ClaimEntitlementState } from '../types'

export const selectClaimEntitlements = createSelector(
  (state: StoreState) => state.claim.claimEntitlements,
  claimEntitlementsState =>
    memoize(
      (periodYear: number, employeeId: string): ClaimEntitlementState[] => {
        const emYear = `${periodYear}|${employeeId}`
        return Object.values(claimEntitlementsState[emYear]?.entities || {}) as ClaimEntitlementState[]
      },
      (periodYear: number, employeeId: string) => `${periodYear}|${employeeId}`
    )
)
