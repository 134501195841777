import { EntityState } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { ROScheduleRecordViewState } from './ro-schedule-record.state'

export const mySchedulePersistConfig = {
  key: 'myschedule',
  whitelist: ['roScheduleRecordsView'],
  storage
}

export interface MyScheduleRootState {
  roScheduleRecordsView: EntityState<ROScheduleRecordViewState>
  roScheduleRecordsViewLoading: boolean
  roScheduleRecordsViewRefetch: number
  roScheduleRecordsViewRowRefetch: string | undefined
  roScheduleRecordsViewCellRefetch: string | undefined
}
