import { showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiLockAllIr21Drafts } from '../api/ir21.api'
import { Ir21FormState } from '../types'
import { setIr21Forms, setIr21FormLoading, setIr21SubmissionIr21View } from '../reducers'

export const lockAllIr21Drafts = (ir21SubmissionId: string): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!ir21SubmissionId) return

    try {
      dispatch(setIr21FormLoading(true))
      const { result, status, errors, message, errorData } = await apiLockAllIr21Drafts(ir21SubmissionId)
      if (status) {
        const ir21Forms = result.map(ir21 => {
          const ir21Forms = Object.values(getState().tax.ir21Form?.entities || {}) as Ir21FormState[]
          const ir21Form = ir21Forms.find(a => a.id === ir21.id)
          const data: Ir21FormState = { ...(ir21Form as Ir21FormState), ...ir21 }
          return data
        })
        dispatch(setIr21Forms(ir21Forms))

        const ir21Views = getState().tax.ir21SubmissionIr21Views
        ir21Views?.ids.forEach(viewId => {
          const ir21View = ir21Views?.entities[viewId]
          const found = ir21View?.data.find(r => r.ir21SubmissionId === ir21SubmissionId)
          if (found && ir21View) {
            const updates = ir21View.data.map(d => {
              const nd = result.find(r => r.id === d.id)
              return nd ? { ...d, ...nd } : d
            })
            dispatch(setIr21SubmissionIr21View({ ...ir21View, data: updates }))
          }
        })
      } else {
        if (Object.keys(errors).length === 0) {
          showError(message, errorData)
        }
      }
      return { errors }
    } finally {
      dispatch(setIr21FormLoading(false))
    }
  }
}
