import { showSuccess, showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { apiDeletePayTranEntryBatch } from '../api/paytran-entry.api'

export const deletePayTranEntryBatch = (payRunId: string, employeeId: string, batchId: string): ThunkResult<void> => {
  return async (): Promise<ActionResult | undefined> => {
    if (!payRunId || !employeeId || !batchId) return

    const {
      status,
      result = {},
      errors,
      message,
      errorData
    } = await apiDeletePayTranEntryBatch(payRunId, employeeId, batchId)

    if (status) {
      showSuccess('Deleted')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
