import { ThunkResult } from '~/types/store'
import { FetchOptions } from '~/types/common'
import { SysOptionType } from '~/features/employee'
import { snakeToCamel } from '~/utils'
import { fetchEmpKeyvalues } from './fetch-emp-keyvalues'

export const fetchSysOptions = (
  type: SysOptionType,
  options: FetchOptions = { strategy: 'when-empty' }
): ThunkResult<void> => {
  return async dispatch => {
    const id = snakeToCamel(type)
    dispatch(fetchEmpKeyvalues(id, 'sysoption', { type }, options))
  }
}
