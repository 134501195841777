import { replaceXeroTenantMaps, setXeroTenantMapsLoading } from '../reducers'
import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiUpdateXeroTenantMaps } from '../api/xero.api'
import { XeroTenantMapState } from '../types'

export const updateXeroTenantMaps = (data: XeroTenantMapState[]): ThunkResult<void> => {
  return async dispatch => {
    try {
      dispatch(setXeroTenantMapsLoading(true))
      const { status, result, errors, message, errorData } = await apiUpdateXeroTenantMaps(data)

      if (status) {
        dispatch(replaceXeroTenantMaps(result))
        showSuccess('Saved')
      } else {
        if (Object.keys(errors).length === 0) {
          showError(message, errorData)
        }
      }
      return { errors }
    } finally {
      dispatch(setXeroTenantMapsLoading(false))
    }
  }
}
