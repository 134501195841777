import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { Search } from '~/types/common'
import { LeaveTypeState, IAddLeaveType } from '../types'

const baseUrl = getBaseUrl('/leave/leavetype')

export const apiGetLeaveTypes = () => request<LeaveTypeState[]>('get', baseUrl)

export const apiGetLeaveTypesView = (viewId: string, params: Search) =>
  request<LeaveTypeState[]>('get', `${baseUrl}/view/${viewId}`, params)

export const apiGetLeaveType = (id: string) => request<LeaveTypeState>('get', `${baseUrl}/${id}`)

export const apiAddLeaveType = (leaveType: IAddLeaveType) => request<LeaveTypeState>('post', baseUrl, leaveType)

export const apiUpdateLeaveType = (id: string, leaveType: Operation[]) =>
  request<void>('patch', `${baseUrl}/${id}`, leaveType)

export const apiDeleteLeaveType = (id: string) => request<void>('delete', `${baseUrl}/${id}`)

export const apiGetLtSysColumns = (id: string) => request<string[]>('get', `${baseUrl}/${id}/syscolumns`)

export const apiGetGrantLeaveTypes = () => request<LeaveTypeState[]>('get', `${baseUrl}/grant`)
