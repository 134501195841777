import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { Card, CardProps } from '~/core-components'
import { StoreState } from '~/types/store'
import { MyLeaveBalanceList } from './MyLeaveBalanceList'
import { MyLeaveApplyButton } from '../../../../../Leave/MyLeaveApply/MyLeaveApplyButton'
import './MyLeaveBalance.less'

interface MyLeaveBalanceProps extends CardProps {}

export const MyLeaveBalance: FC<MyLeaveBalanceProps> = ({ className, ...props }) => {
  const classes = classNames('my-leave-balance', { [`${className}`]: className })
  const loading = useSelector((state: StoreState) => state.my.myLeaveBalancesLoading)

  return (
    <Card title="My leave balance" loading={loading} className={classes} {...props}>
      <MyLeaveBalanceList />
      <MyLeaveApplyButton>Apply leave</MyLeaveApplyButton>
    </Card>
  )
}
