import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { XeroTenantMapState } from '../types'

export const selectXeroTenantMaps = createSelector(
  (state: StoreState) => state.payroll.xeroTenantMaps,
  xeroTenantMapsState => {
    return Object.values(xeroTenantMapsState?.entities || {}) as XeroTenantMapState[]
  }
)
