import React, { FC, useCallback } from 'react'
import moment from 'moment-timezone'
import { Checkbox, CheckboxChangeEvent, Form, Input, Space } from '~/core-components'
import { Col } from '../../../components/Col/Col'
import { Row } from '../../../components/Row/Row'

interface WeeklyCronProps {
  expr: string[]
  onChange: (value: string[]) => void
}

export const WeeklyCron: FC<WeeklyCronProps> = ({ expr, onChange }) => {
  const timeHHMM = `${expr[1]}:${expr[0]}`

  const handleTimeChange = useCallback(
    (value: moment.Moment | null) => {
      const time = (value?.format('HH:mm') || '00:00').split(':')
      const newExpr = [`${parseInt(time[1])}`, `${parseInt(time[0])}`, '*', '*', expr[4]]
      onChange(newExpr)
    },
    [onChange, expr]
  )

  const handleDayCheck = useCallback(
    (e: CheckboxChangeEvent) => {
      const dayNames = expr[4].split(',')
      const day = e.target.value
      const newExpr = [expr[0], expr[1], '*', '*', '*']
      if (e.target.checked) {
        if (expr[4] === '*' || expr[4] === '?' || expr[4] === 'MON-FRI') {
          newExpr[4] = day
        } else {
          newExpr[4] = `${expr[4]},${day}`
        }
      } else {
        if (dayNames.length > 1) {
          dayNames.splice(dayNames.indexOf(day), 1)
          newExpr[4] = dayNames.join(',')
        } else {
          newExpr[4] = '*'
        }
      }
      onChange(newExpr)
    },
    [onChange, expr]
  )

  return (
    <Form.Item>
      <Space direction="vertical">
        <Row>
          <Col span={6}>
            <Checkbox value="MON" onChange={handleDayCheck} checked={expr[4].search('MON') !== -1}>
              Monday
            </Checkbox>
          </Col>
          <Col span={6}>
            <Checkbox value="TUE" onChange={handleDayCheck} checked={expr[4].search('TUE') !== -1}>
              Tuesday
            </Checkbox>
          </Col>
          <Col span={6}>
            <Checkbox value="WED" onChange={handleDayCheck} checked={expr[4].search('WED') !== -1}>
              Wednesday
            </Checkbox>
          </Col>
          <Col span={6}>
            <Checkbox value="THU" onChange={handleDayCheck} checked={expr[4].search('THU') !== -1}>
              Thursday
            </Checkbox>
          </Col>
          <Col span={6}>
            <Checkbox value="FRI" onChange={handleDayCheck} checked={expr[4].search('FRI') !== -1}>
              Friday
            </Checkbox>
          </Col>
          <Col span={6}>
            <Checkbox value="SAT" onChange={handleDayCheck} checked={expr[4].search('SAT') !== -1}>
              Saturday
            </Checkbox>
          </Col>
          <Col span={6}>
            <Checkbox value="SUN" onChange={handleDayCheck} checked={expr[4].search('SUN') !== -1}>
              Sunday
            </Checkbox>
          </Col>
        </Row>
        <Space>
          Starts at
          <Input.Time
            value={moment(timeHHMM, 'HH:mm')}
            onChange={handleTimeChange}
            allowClear={false}
            showNow={false}
            minuteStep={5}
          />
        </Space>
      </Space>
    </Form.Item>
  )
}
