import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Descriptions } from '~/core-components'
import { selectKeyvaluesById } from '~/features/master'
import { selectMainEmAddress } from '../../../../../selectors'

interface CopyEmAddressProps {
  employeeId?: string
  emAddressId?: string
}

export const CopyEmAddress: FC<CopyEmAddressProps> = ({ employeeId, emAddressId }) => {
  const main = useSelector(selectMainEmAddress)(employeeId, emAddressId)
  const countries = useSelector(selectKeyvaluesById)('country')

  return (
    <Descriptions bordered column={1} className="copy-em__content">
      <Descriptions.Item label="Effective date">
        {main?.startDate ? moment(main?.startDate).format('DD MMM YYYY') : ''}
      </Descriptions.Item>
      <Descriptions.Item label="Block no.">{main?.block}</Descriptions.Item>
      <Descriptions.Item label="Street name">{main?.street}</Descriptions.Item>
      <Descriptions.Item label="Floor - Unit no.">
        {main?.level} - {main?.unit}
      </Descriptions.Item>
      <Descriptions.Item label="City">{main?.city}</Descriptions.Item>
      <Descriptions.Item label="State">{main?.state}</Descriptions.Item>
      <Descriptions.Item label="Postal code">{main?.postalCode}</Descriptions.Item>
      <Descriptions.Item label="Country">{countries[main?.countryCode || '']?.value}</Descriptions.Item>
    </Descriptions>
  )
}
