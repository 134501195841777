import React, { FC, useCallback, useState } from 'react'
import { Button, Tooltip } from '~/core-components'
import { showSuccess, showError } from '~/utils'
import { apiRefreshOedRecords } from '../../../../api/rpt-oed-submission.api'
import { refetchOedRecordsView } from '../../../../reducers'
import { dispatch } from '~/stores/store'

interface OedRefreshButtonProps {
  oedSubmissionId: string
}

export const OedRefreshButton: FC<OedRefreshButtonProps> = ({ oedSubmissionId }) => {
  const [refreshing, setRefreshing] = useState(false)

  const handleRefreshClick = useCallback(async () => {
    try {
      setRefreshing(true)
      const { status, errors, message, errorData } = await apiRefreshOedRecords(oedSubmissionId)
      if (status) {
        showSuccess('Refreshed')
        dispatch(refetchOedRecordsView())
      } else {
        if (Object.keys(errors).length === 0) {
          showError(message, errorData)
        }
      }
    } finally {
      setRefreshing(false)
    }
  }, [oedSubmissionId])

  return (
    <Tooltip title="Refresh employee data">
      <Button icon={<i className="fal fa-refresh" />} onClick={handleRefreshClick} loading={refreshing} />
    </Tooltip>
  )
}
