import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchMyLeaveEntitlements } from '../../actions'
import { selectMyLeaveEntitlement } from '../../selectors'
import { SSLeaveEntitlementState } from '../../types'

export const useMyLeaveEntitlement = (leaveTypeId: string): [SSLeaveEntitlementState | undefined, boolean] => {
  const leaveEntitlement = useSelector(selectMyLeaveEntitlement)(leaveTypeId)
  const loading = useSelector((state: StoreState) => state.leave.leaveTypesLoading)

  useEffect(() => {
    dispatch(fetchMyLeaveEntitlements())
  }, [])

  return [leaveEntitlement, loading]
}
