import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { ErrorDisplay } from '~/components'
import { ColumnsType, Descriptions, Table } from '~/core-components'
import { Errors, StoreState } from '~/types/store'
import { ClaimImportValidateReportState } from '../../../../types'
import './ValidateClaimReport.less'

interface ValidateClaimReportProps {
  visible: boolean
}

export const ValidateClaimReport: FC<ValidateClaimReportProps> = ({ visible }) => {
  const report = useSelector((state: StoreState) => state.payroll.claimImportValidate?.report || [])
  const summary = useSelector((state: StoreState) => state.payroll.claimImportValidate?.summary)
  const errors: Errors = { '*': ['No row to import'] }

  const columns: ColumnsType<ClaimImportValidateReportState> = [
    {
      title: 'Errors / warnings',
      key: 'message',
      dataIndex: 'message',
      width: '50%'
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      width: '50%'
    }
  ]

  return (
    <div className="validate-claim-report" hidden={!visible}>
      <Descriptions bordered>
        <Descriptions.Item label="Total row(s)">{summary?.total || 0}</Descriptions.Item>
        <Descriptions.Item label="Skipped">{summary?.skip || 0}</Descriptions.Item>
        <Descriptions.Item label={<b>To import</b>}>
          <b>{summary?.proceed || 0}</b>
        </Descriptions.Item>
      </Descriptions>
      <ErrorDisplay errors={(summary?.proceed || 0) === 0 ? errors : undefined} />
      {report.length > 0 && <Table rowKey="message" dataSource={report} pagination={false} columns={columns} />}
    </div>
  )
}
