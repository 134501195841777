import React, { FC, HTMLAttributes } from 'react'
import { Link, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import { PermissionGate, useMyLogin } from '~/features/iam'
import { MenuItem } from '../../types/common'
import './MenuLinks.less'

interface MenuLinksProps extends HTMLAttributes<HTMLDivElement> {
  menus: MenuItem[]
}

export const MenuLinks: FC<MenuLinksProps> = ({ menus, className }) => {
  const location = useLocation()
  const [myLogin] = useMyLogin()

  return (
    <div className={classNames('menu-links', className)}>
      {menus.map(
        menu =>
          ((menu.internal && myLogin?.email?.endsWith('@zealys.com')) || !menu.internal) && (
            <PermissionGate key={menu.value} sysPermissionId={menu.sysPermissionId}>
              <div
                className={classNames(
                  'menu-links__item',
                  {
                    'menu-links__item--active': typeof menu.path === 'string' && location.pathname.startsWith(menu.path)
                  },
                  {
                    'menu-links__custom': typeof menu.path !== 'string'
                  }
                )}
              >
                {typeof menu.path === 'string' ? (
                  <Link to={menu.path} className="menu-links__item-link">
                    {menu.value}
                  </Link>
                ) : (
                  menu.path
                )}
              </div>
            </PermissionGate>
          )
      )}
    </div>
  )
}
