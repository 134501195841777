import { replaceKeyvalues, setKeyvaluesLoading } from '../reducers'
import { apiGetKeyvalues } from '../api/keyvalues.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchKeyvalues = (
  id: string,
  baseUri: string,
  controller?: string,
  queryParams?: any,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const loading = getState().master.keyvaluesLoading[id]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().master.keyvalues[id]?.ids?.length > 0
      const isSameParams = getState().master.keyvaluesParams[id] === JSON.stringify(queryParams)
      if (hasData && isSameParams) return
    }

    try {
      dispatch(setKeyvaluesLoading({ kv: id, loading: true }))
      const { result, status } = await apiGetKeyvalues(baseUri, controller || id, queryParams)
      if (status) {
        dispatch(replaceKeyvalues({ kv: id, data: result, params: JSON.stringify(queryParams) }))
      }
    } finally {
      dispatch(setKeyvaluesLoading({ kv: id, loading: false }))
    }
  }
}
