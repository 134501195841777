import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { PayRunViewState } from '../types'

export const selectPayRunsView = createSelector(
  (state: StoreState) => state.payroll.payRunsView,
  payRunsState =>
    memoize((viewId: string): PayRunViewState => {
      const payRuns = payRunsState.entities[viewId] || ({} as PayRunViewState)
      return payRuns
    })
)
