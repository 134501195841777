import { EntityState } from '@reduxjs/toolkit'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import storage from 'redux-persist/lib/storage'
import { OfficeState, OfficeExtraState } from './office.state'
import { CompanyState, CompanyExtraState, CompanyOfficeState } from './company.state'
import {
  MasterState,
  CalendarPatternState,
  DepartmentExtraState,
  TeamExtraState,
  HolidayDateState,
  DivisionExtraState,
  SectionExtraState
} from './master.state'
import { KeyValue } from '~/types/common'
import { BankState } from './bank.state'
import { PayItemState, PayItemBasisState } from './payitem.state'
import { PayClaimMapState } from './pay-claim-map.state'
import { PayDailyMapState } from './pay-daily-map.state'
import { PayEncashMapState } from './pay-encash-map.state'
import { PayLeaveMapState } from './pay-leave-map.state'
import { EmailGroupState } from './email-group.state'
import { PayslipItemConfigState } from './payslip-item-config.state'
import { EmploymentConfigState } from './employment-config.state'
import { SelfUpdateConfigState } from './self-update-config.state'
import { SsAccessConfigState } from './ss-access-config.state'
import { DelegationState } from './delegation.state'

export type MasterName =
  | 'calendar'
  | 'category'
  | 'costCentre'
  | 'department'
  | 'division'
  | 'docType'
  | 'employmentType'
  | 'grp'
  | 'holiday'
  | 'job'
  | 'leaveGroup'
  | 'payGroup'
  | 'qualification'
  | 'race'
  | 'reason'
  | 'religion'
  | 'salaryGrade'
  | 'section'
  | 'team'

export const getAvailableMasterNames = (): MasterName[] => [
  'calendar',
  'category',
  'costCentre',
  'employmentType',
  'department',
  'division',
  'docType',
  'grp',
  'holiday',
  'job',
  'leaveGroup',
  'payGroup',
  'qualification',
  'race',
  'religion',
  'reason',
  'salaryGrade',
  'section',
  'team'
]

export const masterPersistConfig = {
  key: 'master',
  whitelist: [
    'companies',
    'companiesExtra',
    'companyOffices',
    'offices',
    'officesExtra',
    'departmentsExtra',
    'divisionsExtra',
    'sectionsExtra',
    'teamsExtra',
    'calendarPatterns',
    'banks',
    'payItems',
    'payItemsView',
    'payItemBases',
    'payClaimMaps',
    'payDailyMaps',
    'payEncashMaps',
    'payLeaveMaps',
    'leaveTypes',
    'grantLeaveTypes',
    'holidayDates',
    'ltSysColumns',
    'ltSettings',
    'ltPolicies',
    'ltPoliciesExtra',
    'ltProrationM',
    'lpEntitlements',
    'lpLeaveGroups',
    'sysLeaveTypes',
    'sysLtFields',
    'masters',
    'keyvalues',
    'keyvaluesParams',
    'emailGroups',
    'payslipItemConfigs',
    'employmentConfigs',
    'selfUpdateConfigs'
  ],
  stateReconciler: autoMergeLevel2,
  storage
}

export interface MasterRootState {
  // People
  companies: EntityState<CompanyState>
  companiesLoading: boolean
  companiesExtra: EntityState<CompanyExtraState>
  companiesExtraLoading: boolean
  companyOffices: EntityState<CompanyOfficeState>
  companyOfficesLoading: boolean
  offices: EntityState<OfficeState>
  officesLoading: boolean
  officesExtra: EntityState<OfficeExtraState>
  officesExtraLoading: boolean
  departmentsExtra: EntityState<DepartmentExtraState>
  departmentsExtraLoading: boolean
  divisionsExtra: EntityState<DivisionExtraState>
  divisionsExtraLoading: boolean
  sectionsExtra: EntityState<SectionExtraState>
  sectionsExtraLoading: boolean
  teamsExtra: EntityState<TeamExtraState>
  teamsExtraLoading: boolean
  calendarPatterns: EntityState<CalendarPatternState>
  calendarPatternsLoading: boolean
  banks: EntityState<BankState>
  banksLoading: boolean
  holidayDates: { [id: string]: EntityState<HolidayDateState> }
  holidayDatesLoading: { [id: string]: boolean }

  // Payroll
  payItems: EntityState<PayItemState>
  payItemsLoading: boolean
  payItemsView: EntityState<PayItemState>
  payItemsViewLoading: boolean
  payItemBases: { [id: string]: EntityState<PayItemBasisState> }
  payItemBasesLoading: { [id: string]: boolean }
  payClaimMaps: { [id: string]: EntityState<PayClaimMapState> }
  payClaimMapsLoading: { [id: string]: boolean }
  payDailyMaps: { [id: string]: EntityState<PayDailyMapState> }
  payDailyMapsLoading: { [id: string]: boolean }
  payEncashMaps: { [id: string]: EntityState<PayEncashMapState> }
  payEncashMapsLoading: { [id: string]: boolean }
  payLeaveMaps: { [id: string]: EntityState<PayLeaveMapState> }
  payLeaveMapsLoading: { [id: string]: boolean }
  payslipItemConfigs: { [id: string]: EntityState<PayslipItemConfigState> }
  payslipItemConfigsLoading: { [id: string]: boolean }

  // Other
  emailGroups: EntityState<EmailGroupState>
  emailGroupsLoading: boolean
  delegations: EntityState<DelegationState>
  delegationsLoading: boolean

  // Config
  employmentConfigs: EntityState<EmploymentConfigState>
  employmentConfigsLoading: boolean
  selfUpdateConfigs: EntityState<SelfUpdateConfigState>
  selfUpdateConfigsLoading: boolean
  ssAccessConfigs: EntityState<SsAccessConfigState>
  ssAccessConfigsLoading: boolean

  // Common
  masters: { [id in MasterName]: EntityState<MasterState> }
  mastersLoading: { [id in MasterName]: boolean }
  mastersRefetch: { [id in MasterName]: number }
  keyvalues: { [id: string]: EntityState<KeyValue> }
  keyvaluesParams: { [id: string]: string }
  keyvaluesLoading: { [id: string]: boolean }
}
