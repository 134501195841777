import React, { CSSProperties, FC, useCallback } from 'react'
import moment from 'moment-timezone'
import { useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'
import confirm from 'antd/lib/modal/confirm'
import { Button, PageHeader, SecondaryLink, Space, TabTitle, Tabs, Tooltip } from '~/core-components'
import { DocumentTitle } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { REPORTS_ROUTES } from '~/routes/routes'
import { Permission, PermissionAction, OedSubmissionStatus } from '~/constants'
import { dispatch } from '~/stores/store'
import { ActionResult, StoreState } from '~/types/store'
import { useOedSubmission } from '~/features/report/hooks'
import { refetchOedSubmissionsView, setOedSubmissionExpanded } from '~/features/report/reducers'
import { deleteOedSubmission } from '~/features/report/actions'
import { OedRecords } from './OedRecords/OedRecords'
import { OedTags } from './OedTags'
import { OedSummary } from './OedSummary/OedSummary'
import './OedSubmission.less'

interface OedSubmissionProps {}

const routes = [
  {
    path: REPORTS_ROUTES.oedSubmissions,
    breadcrumbName: 'Overview'
  },
  {
    path: '',
    breadcrumbName: 'OED submission'
  }
]

export const OedSubmission: FC<OedSubmissionProps> = () => {
  const match = useRouteMatch<{ id: string; tab: string }>()
  const id = match.params.id
  const [oedSubmission] = useOedSubmission(id, 'always')
  const title = oedSubmission
    ? `OED ${moment(oedSubmission.period + '-01').format('YYYY MMM')} - ${oedSubmission.companyName} (${
        oedSubmission.companyUen
      })`
    : 'OED Report'

  const history = useHistory()
  const expanded = useSelector((state: StoreState) => state.report.oedSubmissionExpanded)
  const canDelete = usePermissionGate(Permission.rptMomOed, PermissionAction.Delete)

  const handleTabChange = useCallback(
    (activeKey: string) => {
      history.push(`${REPORTS_ROUTES.oedSubmission.replace(':id', id).replace(':tab?', activeKey)}`)
    },
    [id, history]
  )

  const handleDeleteOedSubmission = useCallback(() => {
    if (!id) return

    confirm({
      title: 'Delete',
      content: 'Do you want to delete this submission?',
      onOk: async () => {
        const result: ActionResult | undefined = await dispatch(deleteOedSubmission(id))

        if (!result?.errors) {
          dispatch(refetchOedSubmissionsView())
          history.push(REPORTS_ROUTES.oedSubmissions)
        }
      },
      width: 550,
      okText: 'Delete',
      okType: 'danger'
    })
  }, [id, history])

  return (
    <div className="oed-submission">
      <DocumentTitle title={title} />
      <PageHeader breadcrumb={{ routes }} />
      <div className="oed-submission__body">
        <Tabs
          defaultActiveKey={match.params.tab || 'record'}
          onChange={handleTabChange}
          tabBarExtraContent={{
            left: (
              <TabTitle
                left={<OedSubmissionExpandButton isExpand={expanded} />}
                title={
                  <Space align="center">
                    <span>{title}</span>
                    <OedTags oedSubmission={oedSubmission} />
                  </Space>
                }
              />
            ),
            right: (
              <Space>
                {oedSubmission?.status === OedSubmissionStatus.draft && canDelete && (
                  <Button size="small" onClick={handleDeleteOedSubmission}>
                    Delete report
                  </Button>
                )}
              </Space>
            )
          }}
          items={[
            { key: 'record', label: 'Records', children: <OedRecords oedSubmission={oedSubmission} /> },
            { key: 'summary', label: 'Summary', children: <OedSummary oedSubmission={oedSubmission} /> }
          ]}
        />
      </div>
    </div>
  )
}

const expandButtonStyle: CSSProperties = { marginRight: 16 }

const OedSubmissionExpandButton: FC<{ isExpand: boolean }> = ({ isExpand }) => {
  const handleExpand = useCallback(() => {
    dispatch(setOedSubmissionExpanded(!isExpand))
  }, [isExpand])

  return (
    <Tooltip title={isExpand ? 'Normal view' : 'Maximise view'}>
      <SecondaryLink style={expandButtonStyle} onClick={handleExpand}>
        {isExpand ? (
          <i className="fal fa-arrow-down-left-and-arrow-up-right-to-center" />
        ) : (
          <i className="fal fa-arrow-up-right-and-arrow-down-left-from-center" />
        )}
      </SecondaryLink>
    </Tooltip>
  )
}
