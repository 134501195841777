import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { IEmFamily } from '../types'

const baseUrl = getBaseUrl('/employee/employee')

export const apiGetEmFamilies = (employeeId: string) => request<IEmFamily[]>('get', `${baseUrl}/${employeeId}/family`)

export const apiAddEmFamily = (employeeId: string, emFamily: IEmFamily) =>
  request<{ id: string }>('post', `${baseUrl}/${employeeId}/family`, emFamily)

export const apiUpdateEmFamily = (employeeId: string, id: string, emFamily: Operation[]) =>
  request<void>('patch', `${baseUrl}/${employeeId}/family/${id}`, emFamily)

export const apiDeleteEmFamily = (employeeId: string, id: string) =>
  request<void>('delete', `${baseUrl}/${employeeId}/family/${id}`)
