import React, { FC, useState, useCallback, useEffect, ChangeEvent } from 'react'
import { Form, Input, Space, Switch } from '~/core-components'
import { Col, DrawerForm, Row } from '~/components'
import { useFocus } from '~/hooks/use-focus'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors } from '~/types/store'
import { ITeConfig, TeConfigState, mapTeConfigStateToTeConfigInfo } from '../../types'
import { updateTeConfig } from '../../actions'

export interface EditTeConfigDrawerProps {
  visible: boolean
  data?: TeConfigState
  onClose: () => void
}

const EMPTY_FORM_DATA: ITeConfig = {
  name: '',
  isShownInDaily: false,
  isShownInShift: false,
  isShownInShiftRole: false
}

export const EditTeConfigDrawer: FC<EditTeConfigDrawerProps> = ({ visible, data, onClose }) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<ITeConfig>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const [focusRef, setFocus] = useFocus(true)

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  useEffect(() => {
    if (data) {
      const { name, isShownInDaily, isShownInShift, isShownInShiftRole } = data
      setFormData({ name, isShownInDaily, isShownInShift, isShownInShiftRole })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      const updated = { ...formData, ...updates }
      setFormData(updated)
    },
    [formData]
  )

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      if (data) {
        result = await dispatch(updateTeConfig(data.code, mapTeConfigStateToTeConfigInfo(data), formData))
      }
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data, formData, onClose])

  return (
    <DrawerForm
      open={visible}
      title="Time element"
      onClose={onClose}
      confirmLoading={loading}
      width={500}
      formId="form-te-config"
    >
      <Form id="form-te-config" onFinish={handleOk}>
        <Row>
          <Col span={24}>
            <Form.Item label="Name" validateStatus={errors?.name ? 'error' : ''} help={errors?.name}>
              <Input
                ref={focusRef}
                value={formData.name}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ name: event.target.value })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item>
              <Space direction="vertical">
                <Space>
                  <Switch
                    checkedChildren="Shown"
                    unCheckedChildren="Hidden"
                    checked={formData.isShownInDaily}
                    onChange={(isShownInDaily: boolean) => {
                      handleFormDataChange({ isShownInDaily })
                    }}
                  />
                  <label>Daily record</label>
                </Space>
                <Space>
                  <Switch
                    checkedChildren="Shown"
                    unCheckedChildren="Hidden"
                    checked={formData.isShownInShift}
                    onChange={(isShownInShift: boolean) => {
                      handleFormDataChange({ isShownInShift })
                    }}
                  />
                  <label>Shift</label>
                </Space>
                <Space>
                  <Switch
                    checkedChildren="Shown"
                    unCheckedChildren="Hidden"
                    checked={formData.isShownInShiftRole}
                    onChange={(isShownInShiftRole: boolean) => {
                      handleFormDataChange({ isShownInShiftRole })
                    }}
                  />
                  <label>Shift role</label>
                </Space>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}
