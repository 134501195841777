import { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import debounce from 'lodash/debounce'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { FetchOptions, FetchStrategy, Pagination } from '~/types/common'
import { fetchScheduleRecordsView } from '../actions'
import { ScheduleRecordViewState } from '../types'

export const useScheduleRecordsView = (
  viewId: string,
  startDate: string,
  endDate: string,
  page: number,
  pageSize: number,
  search: string,
  debounceMs: number = 800,
  fetchStrategy: FetchStrategy = 'always'
): [ScheduleRecordViewState | undefined, boolean] => {
  const data = useSelector((state: StoreState) => state.schedule.scheduleRecordsView?.entities[viewId])
  const loading = useSelector((state: StoreState) => state.schedule.scheduleRecordsViewLoading)
  const refetch = useSelector((state: StoreState) => state.schedule.refetchScheduleRecordsView)
  const refetchRow = useSelector((state: StoreState) => state.schedule.refetchScheduleRecordsViewRow)
  const refetchCell = useSelector((state: StoreState) => state.schedule.refetchScheduleRecordsViewCell)

  const fetchData = useCallback(() => {
    const pagination: Pagination = { offset: pageSize * (page - 1), limit: pageSize }
    const strategy: FetchOptions = { strategy: fetchStrategy }
    dispatch(fetchScheduleRecordsView(viewId, startDate, endDate, pagination, search, strategy))
  }, [viewId, startDate, endDate, page, pageSize, search, fetchStrategy])

  const debounceFetch = useCallback(debounce(fetchData, debounceMs), [fetchData, debounceMs])

  useEffect(() => {
    debounceFetch()
    return () => {
      debounceFetch.cancel()
    }
  }, [refetch, debounceFetch])

  useEffect(() => {
    if (refetchRow) {
      debounceFetch()
    }
  }, [refetchRow, debounceFetch])

  useEffect(() => {
    if (refetchCell) {
      debounceFetch()
    }
  }, [refetchCell, debounceFetch])

  return [data, loading]
}
