import { replaceShiftDays, setShiftDaysLoading } from '../reducers'
import { apiGetShiftDays } from '../api/shift.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchShiftDays = (shiftId?: string, options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!shiftId) return

    const loading = getState().attendance.shiftDaysLoading[shiftId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().attendance.shiftDays[shiftId]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setShiftDaysLoading({ shiftId, loading: true }))
      const { result, status } = await apiGetShiftDays(shiftId)
      if (status) {
        dispatch(replaceShiftDays({ shiftId, data: result }))
      }
    } finally {
      dispatch(setShiftDaysLoading({ shiftId, loading: false }))
    }
  }
}
