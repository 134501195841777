import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchIrasLog } from '../actions'
import { IrasLogState } from '../types'

export const useIrasLog = (
  ytdSubmissionId?: string,
  fetchStrategy: FetchStrategy = 'when-empty'
): [IrasLogState | undefined, boolean] => {
  const irasLog = useSelector((state: StoreState) => state.tax.irasLogs?.entities[ytdSubmissionId || ''])
  const loading = useSelector((state: StoreState) => state.tax.irasLogsLoading)

  useEffect(() => {
    if (ytdSubmissionId) dispatch(fetchIrasLog(ytdSubmissionId, { strategy: fetchStrategy }))
  }, [ytdSubmissionId, fetchStrategy])

  return [irasLog, loading]
}
