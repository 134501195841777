import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchLeaveRecordApprovalHistories } from '../actions'
import { selectLeaveRecordApprovalHistories } from '../selectors'
import { LeaveRecordApprovalHistoryState } from '../types'

export const useLeaveRecordApprovalHistories = (
  leaveRecordId?: string
): [LeaveRecordApprovalHistoryState[], boolean] => {
  const leaveRecordApprovalHistoriess = useSelector(selectLeaveRecordApprovalHistories)(
    leaveRecordId || ''
  ) as LeaveRecordApprovalHistoryState[]
  const loading = useSelector(
    (state: StoreState) => state.leave.leaveRecordApprovalHistoriesLoading[leaveRecordId || '']
  )

  useEffect(() => {
    if (leaveRecordId) {
      dispatch(fetchLeaveRecordApprovalHistories(leaveRecordId))
    }
  }, [leaveRecordId])

  return [leaveRecordApprovalHistoriess, loading]
}
