import { replaceClaimEntitlementRecords, setClaimEntitlementRecordsLoading } from '../reducers'
import { apiGetClaimEntitlementRecords } from '../api/claim-record.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchClaimEntitlementRecords = (
  periodYear: number,
  claimTypeId: string,
  employeeId: string,
  paymentStatuses: string[],
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!periodYear || !claimTypeId || !employeeId) return

    const emCtId = `${employeeId}||${claimTypeId}`

    const loading = getState().claim.claimEntitlementRecordsLoading[emCtId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().claim.claimEntitlementRecords[emCtId]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setClaimEntitlementRecordsLoading({ emCtId, loading: true }))
      const { result, status } = await apiGetClaimEntitlementRecords(
        periodYear,
        claimTypeId,
        employeeId,
        paymentStatuses
      )
      if (status) {
        dispatch(replaceClaimEntitlementRecords({ emCtId, data: result }))
      }
    } finally {
      dispatch(setClaimEntitlementRecordsLoading({ emCtId, loading: false }))
    }
  }
}
