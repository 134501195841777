import { showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiIncludeOedRecord } from '../api/rpt-oed-record.api'

export const includeOedRecord = (id: string): ThunkResult<void> => {
  return async () => {
    if (!id) return

    const { status, errors, message, errorData } = await apiIncludeOedRecord(id)
    if (status) {
      return { result: true }
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }

    return { errors }
  }
}
