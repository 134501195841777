import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchPayRunXero } from '../actions'
import { PayRunXeroState } from '../types'

export const usePayRunXero = (
  payRunId: string,
  fetchStrategy: FetchStrategy = 'always'
): [PayRunXeroState | undefined, boolean] => {
  const payRunXero = useSelector((state: StoreState) => state.payroll.payRunXero.entities[payRunId])
  const loading = useSelector((state: StoreState) => state.payroll.payRunXeroLoading)
  const refetch = useSelector((state: StoreState) => state.payroll.payRunXeroRefecth)

  useEffect(() => {
    dispatch(fetchPayRunXero(payRunId, { strategy: fetchStrategy }))
  }, [payRunId, refetch, fetchStrategy])

  return [payRunXero, loading]
}
