import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { Screen, ViewState } from '../types'

export const selectViewByName = createSelector(
  (state: StoreState) => state.selection.views,
  viewsState =>
    memoize(
      (screenCode: Screen, viewName?: string): ViewState | undefined => {
        if (!viewName) return undefined

        const views = Object.values(viewsState[screenCode]?.entities || {}) as ViewState[]
        return views.find(v => v.viewName === viewName)
      },
      (screenCode: string, viewName?: string) => `${screenCode}|${viewName}`
    )
)
