import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { SecondaryLink, TabTitle, Tabs, Tooltip } from '~/core-components'
import { DocumentTitle } from '~/components'
import { Screen, SelectView, useFirstView } from '~/features/selection'
import { StoreState } from '~/types/store'
import { dispatch } from '~/stores/store'
import { EMP_ROUTES } from '~/routes/routes'
import { setEmDirectoryViewExpanded, refetchEmDirectoryView } from '../../reducers'
import { EmDirectory } from '../EmDirectory/EmDirectory'
import { EmDashboard } from '../EmDashboard/EmDashboard'
import { EmOrgChart } from '../EmOrgChart/EmOrgChart'
import { SelectCompany } from './SelectCompany'
import './EmOverview.less'

export interface EmOverviewProps {}

const SCREEN_CODE: Screen = 'em_directory'

export const EmOverview: FC<EmOverviewProps> = () => {
  const match = useRouteMatch<{ tab: string }>()
  const tab = match.params.tab
  const expanded = useSelector((state: StoreState) => state.employee.emDirectoryViewExpanded)
  const history = useHistory()

  const [view] = useFirstView(SCREEN_CODE)
  const firstViewId = view?.id || ''
  const [viewId, setViewId] = useState<string>()

  const [companyId, setCompanyId] = useState<string>('')
  const companyIds = useSelector((state: StoreState) => state.master.keyvalues['company']?.ids)

  useEffect(() => {
    if (!viewId && firstViewId) {
      setViewId(firstViewId)
    }
  }, [firstViewId, viewId])

  useEffect(() => {
    setCompanyId(companyId => {
      if (!companyId) {
        if (companyIds && companyIds.length > 0) {
          return companyIds[0].toString()
        }
      }
      return companyId
    })
  }, [companyIds])

  const handleTabChange = useCallback(
    (activeKey: string) => {
      history.push(`${EMP_ROUTES.tab.replace(':tab?', activeKey)}`)

      if (activeKey === 'directory') {
        dispatch(refetchEmDirectoryView())
      }
    },
    [history]
  )

  const handleViewChange = useCallback((viewId?: string) => {
    setViewId(viewId)
  }, [])

  const handleCompanyChange = useCallback((companyId: string) => {
    setCompanyId(companyId)
  }, [])

  return (
    <div className="em-overview">
      <DocumentTitle title="People Overview" />
      <div className="em-overview__body">
        <Tabs
          defaultActiveKey={tab || 'dashboard'}
          onChange={handleTabChange}
          tabBarExtraContent={{
            left: <TabTitle left={<EmDirectoryExpandButton isExpand={expanded} />} title="People overview" />,
            right: (
              <>
                {tab === 'directory' && (
                  <SelectView screenCode={SCREEN_CODE} viewId={viewId} size="small" onChange={handleViewChange} />
                )}
                {tab === 'organisation' && <SelectCompany companyId={companyId} onChange={handleCompanyChange} />}
              </>
            )
          }}
          items={[
            { key: 'dashboard', label: 'Dashboard', children: <EmDashboard /> },
            { key: 'directory', label: 'People', children: <EmDirectory viewId={viewId} /> },
            { key: 'organisation', label: 'Organisation', children: <EmOrgChart companyId={companyId} /> }
          ]}
        />
      </div>
    </div>
  )
}

const expandButtonStyle: CSSProperties = { marginRight: 16 }

const EmDirectoryExpandButton: FC<{ isExpand: boolean }> = ({ isExpand }) => {
  const handleExpand = useCallback(() => {
    dispatch(setEmDirectoryViewExpanded(!isExpand))
  }, [isExpand])

  return (
    <Tooltip title={isExpand ? 'Normal view' : 'Maximise view'}>
      <SecondaryLink style={expandButtonStyle} onClick={handleExpand}>
        {isExpand ? (
          <i className="fal fa-arrow-down-left-and-arrow-up-right-to-center" />
        ) : (
          <i className="fal fa-arrow-up-right-and-arrow-down-left-from-center" />
        )}
      </SecondaryLink>
    </Tooltip>
  )
}
