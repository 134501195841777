import React, { FC, HTMLAttributes, useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment-timezone'
import confirm from 'antd/lib/modal/confirm'
import { useSelector } from 'react-redux'
import { Form, Input, Radio, Space, Skeleton, SecondaryText } from '~/core-components'
import { SalaryInput, InfoTooltip, ErrorDisplay } from '~/components'
import { Ir8sFormState } from '../../../types'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors, StoreState } from '~/types/store'
import { formatMoney } from '~/utils'
import { addIr8sDraft, deleteIr8sDraft, fetchIr8sForm, updateIr8sDraft } from '../../../actions'
import { setIr8sFormRefetch, removeIr8sForm, setIr8aFormRefetch } from '../../../reducers'
import { selectIr8sForm } from '../../../selectors'
import { YtdEmptyForm } from '../YtdEmptyForm/YtdEmptyForm'
import './Ir8sForm.less'

interface Ir8sFormProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
  ytdYear: string
  employerTaxNo: string
  employeeTaxNo: string
  ytdSubmissionId: string
  readOnly?: boolean
  onFormChange: (hasChange: boolean) => void

  // Trigger action from parent component
  triggerUpdate: number
  triggerDelete: number
  triggerDiscard: number
}

export const EMPTY_IR8S_FORM_DATA: Ir8sFormState = {
  id: '',
  ytdSubmissionId: '',
  employeeTaxNo: '',
  cpfEmExcess: 0.0,
  cpfErExcess: 0.0,
  isFullContributionApproved: false,
  aw01: 0.0,
  aw02: 0.0,
  aw03: 0.0,
  aw04: 0.0,
  aw05: 0.0,
  aw06: 0.0,
  aw07: 0.0,
  aw08: 0.0,
  aw09: 0.0,
  aw10: 0.0,
  aw11: 0.0,
  aw12: 0.0,
  ow01: 0.0,
  ow02: 0.0,
  ow03: 0.0,
  ow04: 0.0,
  ow05: 0.0,
  ow06: 0.0,
  ow07: 0.0,
  ow08: 0.0,
  ow09: 0.0,
  ow10: 0.0,
  ow11: 0.0,
  ow12: 0.0,
  cpfEmAdditional01: 0.0,
  cpfEmAdditional02: 0.0,
  cpfEmAdditional03: 0.0,
  cpfEmAdditional04: 0.0,
  cpfEmAdditional05: 0.0,
  cpfEmAdditional06: 0.0,
  cpfEmAdditional07: 0.0,
  cpfEmAdditional08: 0.0,
  cpfEmAdditional09: 0.0,
  cpfEmAdditional10: 0.0,
  cpfEmAdditional11: 0.0,
  cpfEmAdditional12: 0.0,
  cpfEmOrdinary01: 0.0,
  cpfEmOrdinary02: 0.0,
  cpfEmOrdinary03: 0.0,
  cpfEmOrdinary04: 0.0,
  cpfEmOrdinary05: 0.0,
  cpfEmOrdinary06: 0.0,
  cpfEmOrdinary07: 0.0,
  cpfEmOrdinary08: 0.0,
  cpfEmOrdinary09: 0.0,
  cpfEmOrdinary10: 0.0,
  cpfEmOrdinary11: 0.0,
  cpfEmOrdinary12: 0.0,
  cpfErAdditional01: 0.0,
  cpfErAdditional02: 0.0,
  cpfErAdditional03: 0.0,
  cpfErAdditional04: 0.0,
  cpfErAdditional05: 0.0,
  cpfErAdditional06: 0.0,
  cpfErAdditional07: 0.0,
  cpfErAdditional08: 0.0,
  cpfErAdditional09: 0.0,
  cpfErAdditional10: 0.0,
  cpfErAdditional11: 0.0,
  cpfErAdditional12: 0.0,
  cpfErOrdinary01: 0.0,
  cpfErOrdinary02: 0.0,
  cpfErOrdinary03: 0.0,
  cpfErOrdinary04: 0.0,
  cpfErOrdinary05: 0.0,
  cpfErOrdinary06: 0.0,
  cpfErOrdinary07: 0.0,
  cpfErOrdinary08: 0.0,
  cpfErOrdinary09: 0.0,
  cpfErOrdinary10: 0.0,
  cpfErOrdinary11: 0.0,
  cpfErOrdinary12: 0.0,
  awRefundAmt01: 0.0,
  awRefundAmt02: 0.0,
  awRefundAmt03: 0.0,
  awRefundStartDate01: '',
  awRefundStartDate02: '',
  awRefundStartDate03: '',
  awRefundEndDate01: '',
  awRefundEndDate02: '',
  awRefundEndDate03: '',
  awRefundPaymentDate01: '',
  awRefundPaymentDate02: '',
  awRefundPaymentDate03: '',
  emRefundAmt01: 0.0,
  emRefundAmt02: 0.0,
  emRefundAmt03: 0.0,
  emRefundInterest01: 0.0,
  emRefundInterest02: 0.0,
  emRefundInterest03: 0.0,
  emRefundDate01: '',
  emRefundDate02: '',
  emRefundDate03: '',
  erRefundAmt01: 0.0,
  erRefundAmt02: 0.0,
  erRefundAmt03: 0.0,
  erRefundInterest01: 0.0,
  erRefundInterest02: 0.0,
  erRefundInterest03: 0.0,
  erRefundDate01: '',
  erRefundDate02: '',
  erRefundDate03: '',

  // calculation field
  totalOw: 0.0,
  totalCpfErOrdinary: 0.0,
  totalCpfEmOrdinary: 0.0,
  totalAw: 0.0,
  totalCpfErAdditional: 0.0,
  totalCpfEmAdditional: 0.0
}

export const Ir8sForm: FC<Ir8sFormProps> = ({
  ytdYear,
  employerTaxNo,
  employeeTaxNo,
  ytdSubmissionId,
  readOnly,
  onFormChange,
  triggerUpdate,
  triggerDelete,
  triggerDiscard,
  ...divProps
}) => {
  const emptyFormData: Ir8sFormState = useMemo(
    () => ({ ...EMPTY_IR8S_FORM_DATA, ytdSubmissionId, employeeTaxNo }),
    [ytdSubmissionId, employeeTaxNo]
  )
  const [formData, setFormData] = useState<Ir8sFormState>(emptyFormData)
  const [errors, setErrors] = useState<Errors>()
  const ir8sForm = useSelector(selectIr8sForm)(ytdSubmissionId, employeeTaxNo)
  const hasData = !!ir8sForm
  const loading = useSelector((state: StoreState) => state.tax.ir8sFormLoading || false)
  const refetch = useSelector((state: StoreState) => state.tax.ir8sFormRefetch)

  useEffect(() => {
    dispatch(fetchIr8sForm(ytdYear, employerTaxNo, employeeTaxNo, ytdSubmissionId))
  }, [refetch, ytdYear, employerTaxNo, employeeTaxNo, ytdSubmissionId])

  useEffect(() => {
    if (ir8sForm) {
      setFormData(ir8sForm)
    } else {
      setFormData(emptyFormData)
    }
  }, [ir8sForm, emptyFormData])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      setFormData(formData => {
        const updated = { ...formData, ...updates }

        updated.totalOw =
          updated.ow01 +
          updated.ow02 +
          updated.ow03 +
          updated.ow04 +
          updated.ow05 +
          updated.ow06 +
          updated.ow07 +
          updated.ow08 +
          updated.ow09 +
          updated.ow10 +
          updated.ow11 +
          updated.ow12

        updated.totalCpfErOrdinary =
          updated.cpfErOrdinary01 +
          updated.cpfErOrdinary02 +
          updated.cpfErOrdinary03 +
          updated.cpfErOrdinary04 +
          updated.cpfErOrdinary05 +
          updated.cpfErOrdinary06 +
          updated.cpfErOrdinary07 +
          updated.cpfErOrdinary08 +
          updated.cpfErOrdinary09 +
          updated.cpfErOrdinary10 +
          updated.cpfErOrdinary11 +
          updated.cpfErOrdinary12

        updated.totalCpfEmOrdinary =
          updated.cpfEmOrdinary01 +
          updated.cpfEmOrdinary02 +
          updated.cpfEmOrdinary03 +
          updated.cpfEmOrdinary04 +
          updated.cpfEmOrdinary05 +
          updated.cpfEmOrdinary06 +
          updated.cpfEmOrdinary07 +
          updated.cpfEmOrdinary08 +
          updated.cpfEmOrdinary09 +
          updated.cpfEmOrdinary10 +
          updated.cpfEmOrdinary11 +
          updated.cpfEmOrdinary12

        updated.totalAw =
          updated.aw01 +
          updated.aw02 +
          updated.aw03 +
          updated.aw04 +
          updated.aw05 +
          updated.aw06 +
          updated.aw07 +
          updated.aw08 +
          updated.aw09 +
          updated.aw10 +
          updated.aw11 +
          updated.aw12

        updated.totalCpfErAdditional =
          updated.cpfErAdditional01 +
          updated.cpfErAdditional02 +
          updated.cpfErAdditional03 +
          updated.cpfErAdditional04 +
          updated.cpfErAdditional05 +
          updated.cpfErAdditional06 +
          updated.cpfErAdditional07 +
          updated.cpfErAdditional08 +
          updated.cpfErAdditional09 +
          updated.cpfErAdditional10 +
          updated.cpfErAdditional11 +
          updated.cpfErAdditional12

        updated.totalCpfEmAdditional =
          updated.cpfEmAdditional01 +
          updated.cpfEmAdditional02 +
          updated.cpfEmAdditional03 +
          updated.cpfEmAdditional04 +
          updated.cpfEmAdditional05 +
          updated.cpfEmAdditional06 +
          updated.cpfEmAdditional07 +
          updated.cpfEmAdditional08 +
          updated.cpfEmAdditional09 +
          updated.cpfEmAdditional10 +
          updated.cpfEmAdditional11 +
          updated.cpfEmAdditional12

        return updated
      })
      onFormChange(true)
    },
    [onFormChange]
  )

  const handleCreateForm = useCallback(async () => {
    const result: ActionResult | undefined = await dispatch(
      addIr8sDraft({ ytdSubmissionId, employeeTaxNos: [formData.employeeTaxNo] })
    )

    if (!result?.errors) {
      dispatch(setIr8aFormRefetch())
      dispatch(setIr8sFormRefetch())
    }
  }, [ytdSubmissionId, formData.employeeTaxNo])

  const handleUpdateForm = useCallback(async () => {
    setErrors(undefined)

    const result = await dispatch(updateIr8sDraft(formData))

    if (result?.errors) {
      setErrors(result.errors)
    } else {
      onFormChange(false)
    }
  }, [formData, onFormChange])

  const handleDeleteForm = useCallback(async () => {
    confirm({
      title: 'Delete',
      content: 'Do you want to delete this IR8S?',
      onOk: async () => {
        const result: ActionResult | undefined = await dispatch(deleteIr8sDraft(formData.id))

        if (!result?.errors) {
          dispatch(removeIr8sForm(formData.employeeTaxNo))
        }
      },
      width: 550,
      okText: 'Delete',
      okType: 'danger'
    })
  }, [formData])

  const handleDiscardForm = useCallback(() => {
    if (ir8sForm) {
      setFormData(ir8sForm)
    }
    setErrors(undefined)
  }, [ir8sForm])

  useEffect(() => {
    if (triggerUpdate > 0) handleUpdateForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerUpdate])

  useEffect(() => {
    if (triggerDelete > 0) handleDeleteForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerDelete])

  useEffect(() => {
    if (triggerDiscard > 0) handleDiscardForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerDiscard])

  if (loading)
    return (
      <div className="ir8s">
        <div className="ir8s-form">
          <Skeleton active />
        </div>
      </div>
    )

  if (!hasData)
    return (
      <YtdEmptyForm onCreate={handleCreateForm}>
        <SecondaryText>There is no IR8S for the selected employee.</SecondaryText>
      </YtdEmptyForm>
    )

  return (
    <div {...divProps} className="ir8s">
      <div className="ir8s-form">
        <ErrorDisplay errors={errors} />
        <div className="ir8s-form__title">SECTION A: Details of monthly wages and actual contributions</div>
        <div className="ir8s-form__field mt5">
          <table className="tableBordered" width={'100%'} cellPadding={5} cellSpacing={10}>
            <thead className="tableHeader">
              <tr>
                <th rowSpan={2}>Month</th>
                <th rowSpan={2}>Ordinary Wages</th>
                <th colSpan={2}>CPF Contribution</th>
                <th rowSpan={2}>Additional Wages</th>
                <th colSpan={2}>CPF Contribution</th>
              </tr>
              <tr>
                <th>Employer</th>
                <th>Employee</th>
                <th>Employer</th>
                <th>Employee</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="centered">JAN</td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.ow01}
                    onChange={(value: number | null) => handleFormDataChange({ ow01: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.cpfErOrdinary01}
                    onChange={(value: number | null) => handleFormDataChange({ cpfErOrdinary01: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.cpfEmOrdinary01}
                    onChange={(value: number | null) => handleFormDataChange({ cpfEmOrdinary01: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.aw01}
                    onChange={(value: number | null) => handleFormDataChange({ aw01: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.cpfErAdditional01}
                    onChange={(value: number | null) => handleFormDataChange({ cpfErAdditional01: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.cpfEmAdditional01}
                    onChange={(value: number | null) => handleFormDataChange({ cpfEmAdditional01: value })}
                  />
                </td>
              </tr>
              <tr>
                <td className="centered">FEB</td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.ow02}
                    onChange={(value: number | null) => handleFormDataChange({ ow02: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.cpfErOrdinary02}
                    onChange={(value: number | null) => handleFormDataChange({ cpfErOrdinary02: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.cpfEmOrdinary02}
                    onChange={(value: number | null) => handleFormDataChange({ cpfEmOrdinary02: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.aw02}
                    onChange={(value: number | null) => handleFormDataChange({ aw02: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.cpfErAdditional02}
                    onChange={(value: number | null) => handleFormDataChange({ cpfErAdditional02: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.cpfEmAdditional02}
                    onChange={(value: number | null) => handleFormDataChange({ cpfEmAdditional02: value })}
                  />
                </td>
              </tr>
              <tr>
                <td className="centered">MAR</td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.ow03}
                    onChange={(value: number | null) => handleFormDataChange({ ow03: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.cpfErOrdinary03}
                    onChange={(value: number | null) => handleFormDataChange({ cpfErOrdinary03: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.cpfEmOrdinary03}
                    onChange={(value: number | null) => handleFormDataChange({ cpfEmOrdinary03: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.aw03}
                    onChange={(value: number | null) => handleFormDataChange({ aw03: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.cpfErAdditional03}
                    onChange={(value: number | null) => handleFormDataChange({ cpfErAdditional03: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.cpfEmAdditional03}
                    onChange={(value: number | null) => handleFormDataChange({ cpfEmAdditional03: value })}
                  />
                </td>
              </tr>
              <tr>
                <td className="centered">APR</td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.ow04}
                    onChange={(value: number | null) => handleFormDataChange({ ow04: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.cpfErOrdinary04}
                    onChange={(value: number | null) => handleFormDataChange({ cpfErOrdinary04: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.cpfEmOrdinary04}
                    onChange={(value: number | null) => handleFormDataChange({ cpfEmOrdinary04: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.aw04}
                    onChange={(value: number | null) => handleFormDataChange({ aw04: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.cpfErAdditional04}
                    onChange={(value: number | null) => handleFormDataChange({ cpfErAdditional04: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.cpfEmAdditional04}
                    onChange={(value: number | null) => handleFormDataChange({ cpfEmAdditional04: value })}
                  />
                </td>
              </tr>
              <tr>
                <td className="centered">MAY</td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.ow05}
                    onChange={(value: number | null) => handleFormDataChange({ ow05: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.cpfErOrdinary05}
                    onChange={(value: number | null) => handleFormDataChange({ cpfErOrdinary05: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.cpfEmOrdinary05}
                    onChange={(value: number | null) => handleFormDataChange({ cpfEmOrdinary05: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.aw05}
                    onChange={(value: number | null) => handleFormDataChange({ aw05: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.cpfErAdditional05}
                    onChange={(value: number | null) => handleFormDataChange({ cpfErAdditional05: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.cpfEmAdditional05}
                    onChange={(value: number | null) => handleFormDataChange({ cpfEmAdditional05: value })}
                  />
                </td>
              </tr>
              <tr>
                <td className="centered">JUN</td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.ow06}
                    onChange={(value: number | null) => handleFormDataChange({ ow06: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.cpfErOrdinary06}
                    onChange={(value: number | null) => handleFormDataChange({ cpfErOrdinary06: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.cpfEmOrdinary06}
                    onChange={(value: number | null) => handleFormDataChange({ cpfEmOrdinary06: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.aw06}
                    onChange={(value: number | null) => handleFormDataChange({ aw06: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.cpfErAdditional06}
                    onChange={(value: number | null) => handleFormDataChange({ cpfErAdditional06: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.cpfEmAdditional06}
                    onChange={(value: number | null) => handleFormDataChange({ cpfEmAdditional06: value })}
                  />
                </td>
              </tr>
              <tr>
                <td className="centered">JUL</td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.ow07}
                    onChange={(value: number | null) => handleFormDataChange({ ow07: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.cpfErOrdinary07}
                    onChange={(value: number | null) => handleFormDataChange({ cpfErOrdinary07: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.cpfEmOrdinary07}
                    onChange={(value: number | null) => handleFormDataChange({ cpfEmOrdinary07: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.aw07}
                    onChange={(value: number | null) => handleFormDataChange({ aw07: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.cpfErAdditional07}
                    onChange={(value: number | null) => handleFormDataChange({ cpfErAdditional07: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.cpfEmAdditional07}
                    onChange={(value: number | null) => handleFormDataChange({ cpfEmAdditional07: value })}
                  />
                </td>
              </tr>
              <tr>
                <td className="centered">AUG</td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.ow08}
                    onChange={(value: number | null) => handleFormDataChange({ ow08: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.cpfErOrdinary08}
                    onChange={(value: number | null) => handleFormDataChange({ cpfErOrdinary08: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.cpfEmOrdinary08}
                    onChange={(value: number | null) => handleFormDataChange({ cpfEmOrdinary08: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.aw08}
                    onChange={(value: number | null) => handleFormDataChange({ aw08: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.cpfErAdditional08}
                    onChange={(value: number | null) => handleFormDataChange({ cpfErAdditional08: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.cpfEmAdditional08}
                    onChange={(value: number | null) => handleFormDataChange({ cpfEmAdditional08: value })}
                  />
                </td>
              </tr>
              <tr>
                <td className="centered">SEP</td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.ow09}
                    onChange={(value: number | null) => handleFormDataChange({ ow09: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.cpfErOrdinary09}
                    onChange={(value: number | null) => handleFormDataChange({ cpfErOrdinary09: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.cpfEmOrdinary09}
                    onChange={(value: number | null) => handleFormDataChange({ cpfEmOrdinary09: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.aw09}
                    onChange={(value: number | null) => handleFormDataChange({ aw09: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.cpfErAdditional09}
                    onChange={(value: number | null) => handleFormDataChange({ cpfErAdditional09: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.cpfEmAdditional09}
                    onChange={(value: number | null) => handleFormDataChange({ cpfEmAdditional09: value })}
                  />
                </td>
              </tr>
              <tr>
                <td className="centered">OCT</td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.ow10}
                    onChange={(value: number | null) => handleFormDataChange({ ow10: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.cpfErOrdinary10}
                    onChange={(value: number | null) => handleFormDataChange({ cpfErOrdinary10: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.cpfEmOrdinary10}
                    onChange={(value: number | null) => handleFormDataChange({ cpfEmOrdinary10: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.aw10}
                    onChange={(value: number | null) => handleFormDataChange({ aw10: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.cpfErAdditional10}
                    onChange={(value: number | null) => handleFormDataChange({ cpfErAdditional10: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.cpfEmAdditional10}
                    onChange={(value: number | null) => handleFormDataChange({ cpfEmAdditional10: value })}
                  />
                </td>
              </tr>
              <tr>
                <td className="centered">NOV</td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.ow11}
                    onChange={(value: number | null) => handleFormDataChange({ ow11: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.cpfErOrdinary11}
                    onChange={(value: number | null) => handleFormDataChange({ cpfErOrdinary11: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.cpfEmOrdinary11}
                    onChange={(value: number | null) => handleFormDataChange({ cpfEmOrdinary11: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.aw11}
                    onChange={(value: number | null) => handleFormDataChange({ aw11: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.cpfErAdditional11}
                    onChange={(value: number | null) => handleFormDataChange({ cpfErAdditional11: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.cpfEmAdditional11}
                    onChange={(value: number | null) => handleFormDataChange({ cpfEmAdditional11: value })}
                  />
                </td>
              </tr>
              <tr>
                <td className="centered">DEC</td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.ow12}
                    onChange={(value: number | null) => handleFormDataChange({ ow12: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.cpfErOrdinary12}
                    onChange={(value: number | null) => handleFormDataChange({ cpfErOrdinary12: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.cpfEmOrdinary12}
                    onChange={(value: number | null) => handleFormDataChange({ cpfEmOrdinary12: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.aw12}
                    onChange={(value: number | null) => handleFormDataChange({ aw12: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.cpfErAdditional12}
                    onChange={(value: number | null) => handleFormDataChange({ cpfErAdditional12: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="pr-20"
                    readOnly={readOnly}
                    value={formData.cpfEmAdditional12}
                    onChange={(value: number | null) => handleFormDataChange({ cpfEmAdditional12: value })}
                  />
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td className="centered">TOTAL</td>
                <td className="autoFill">{formatMoney(formData.totalOw, 2)}</td>
                <td className="autoFill">{formatMoney(formData.totalCpfErOrdinary, 2)}</td>
                <td className="autoFill">{formatMoney(formData.totalCpfEmOrdinary, 2)}</td>
                <td className="autoFill">{formatMoney(formData.totalAw, 2)}</td>
                <td className="autoFill">{formatMoney(formData.totalCpfErAdditional, 2)}</td>
                <td className="autoFill">{formatMoney(formData.totalCpfEmAdditional, 2)}</td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div className="ir8s-form__field maxH50">
          <div className="ir8s-form__field-row">
            <div className="ir8s-form__field-content ml2">
              Has approval been given by CPF Board to make full contributions (for SPR status granted on/after 1 Jan
              2019) ?
            </div>
            <div className="ir8s-form__field-content yn">
              <Form.Item>
                <Radio.Group
                  readOnly={readOnly}
                  value={formData.isFullContributionApproved}
                  onChange={e => handleFormDataChange({ isFullContributionApproved: e.target.value })}
                >
                  <Space direction="horizontal">
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="ir8s-form__title">SECTION B: Excess/Voluntary contribution to CPF</div>
        <div className="ir8s-form__field">
          <table width={'40%'} cellPadding={5} cellSpacing={5}>
            <thead></thead>
            <tbody>
              <tr>
                <td className="W185">Employer's Contribution :</td>
                <td>
                  <SalaryInput
                    size="small"
                    className="taRight"
                    readOnly={readOnly}
                    value={formData.cpfErExcess}
                    onChange={(value: number | null) => handleFormDataChange({ cpfErExcess: value })}
                  />
                </td>
              </tr>
              <tr>
                <td className="W185">Employee's Contribution :</td>
                <td>
                  <SalaryInput
                    size="small"
                    className="taRight"
                    readOnly={readOnly}
                    value={formData.cpfEmExcess}
                    onChange={(value: number | null) => handleFormDataChange({ cpfEmExcess: value })}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="ir8s-form__title">
          SECTION C: Details of Refund claimed / to be claimed on excess CPF contributions made in {ytdYear}
        </div>
        <div className="ir8s-form__field">
          <div className="ir8s-form__field-row">
            <div className="ir8s-form__field-content ml2">
              Please complete Section C if you or your employee has claimed/will claim a refund of the excess CPF
              contribution from CPF Board.
            </div>
          </div>
        </div>
        <div className="ir8s-form__field">
          <table className="tableBordered" width={'100%'} cellPadding={5} cellSpacing={10}>
            <thead className="tableHeader">
              <tr>
                <th colSpan={4}>Additional Wages</th>
                <th colSpan={6}>Amount of Refund</th>
              </tr>
              <tr>
                <th rowSpan={2} className="tdAmount">
                  Amount
                </th>
                <th colSpan={2}>Period</th>
                <th rowSpan={2}>Date Paid</th>
                <th colSpan={3}>Employer</th>
                <th colSpan={3}>Employee</th>
              </tr>
              <tr>
                <th>From</th>
                <th>To</th>
                <th className="tdAmount">Contribution</th>
                <th className="tdInterest">Interest</th>
                <th>
                  Date
                  <InfoTooltip
                    title={`Please indicate the date refund was received. If the refund has not been received, please indicate the date the claim was made or expected to be made as at the date of completion of this form.`}
                  />
                </th>
                <th className="tdAmount">Contribution</th>
                <th className="tdInterest">
                  Interest
                  <InfoTooltip
                    title={`Interest from the refund of employee's contribution is assessable under S10(1)(d) of the Income Tax Act (for example, if the date of refund is in the year 2021, interest will be taxable in the Year of Assessment 2022). Employees are required to declare the interest amount in their income tax return.`}
                  />
                </th>
                <th>
                  Date
                  <InfoTooltip
                    title={`Please indicate the date refund was received. If the refund has not been received, please indicate the date the claim was made or expected to be made as at the date of completion of this form.`}
                  />
                </th>
              </tr>
            </thead>
            <tbody className="td-small">
              <tr>
                <td>
                  <SalaryInput
                    size="small"
                    className="amount"
                    readOnly={readOnly}
                    value={formData.awRefundAmt01}
                    onChange={(value: number | null) => handleFormDataChange({ awRefundAmt01: value })}
                  />
                </td>
                <td>
                  <Input.Date
                    size="small"
                    className="ir8a-form__input"
                    inputReadOnly={readOnly}
                    value={formData.awRefundStartDate01 ? moment(formData.awRefundStartDate01) : undefined}
                    onChange={(value: moment.Moment | null) =>
                      handleFormDataChange({
                        awRefundStartDate01: value?.format('YYYY-MM-DD')
                      })
                    }
                  />
                </td>
                <td>
                  <Input.Date
                    size="small"
                    className="ir8a-form__input"
                    inputReadOnly={readOnly}
                    value={formData.awRefundEndDate01 ? moment(formData.awRefundEndDate01) : undefined}
                    onChange={(value: moment.Moment | null) =>
                      handleFormDataChange({
                        awRefundEndDate01: value?.format('YYYY-MM-DD')
                      })
                    }
                  />
                </td>
                <td>
                  <Input.Date
                    size="small"
                    className="ir8a-form__input"
                    inputReadOnly={readOnly}
                    value={formData.awRefundPaymentDate01 ? moment(formData.awRefundPaymentDate01) : undefined}
                    onChange={(value: moment.Moment | null) =>
                      handleFormDataChange({
                        awRefundPaymentDate01: value?.format('YYYY-MM-DD')
                      })
                    }
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="amount"
                    readOnly={readOnly}
                    value={formData.erRefundAmt01}
                    onChange={(value: number | null) => handleFormDataChange({ erRefundAmt01: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="interest"
                    readOnly={readOnly}
                    value={formData.erRefundInterest01}
                    onChange={(value: number | null) => handleFormDataChange({ erRefundInterest01: value })}
                  />
                </td>
                <td>
                  <Input.Date
                    size="small"
                    className="ir8a-form__input"
                    inputReadOnly={readOnly}
                    value={formData.erRefundDate01 ? moment(formData.erRefundDate01) : undefined}
                    onChange={(value: moment.Moment | null) =>
                      handleFormDataChange({
                        erRefundDate01: value?.format('YYYY-MM-DD')
                      })
                    }
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="amount"
                    readOnly={readOnly}
                    value={formData.emRefundAmt01}
                    onChange={(value: number | null) => handleFormDataChange({ emRefundAmt01: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="interest"
                    readOnly={readOnly}
                    value={formData.emRefundInterest01}
                    onChange={(value: number | null) => handleFormDataChange({ emRefundInterest01: value })}
                  />
                </td>
                <td>
                  <Input.Date
                    size="small"
                    className="ir8a-form__input"
                    inputReadOnly={readOnly}
                    value={formData.emRefundDate01 ? moment(formData.emRefundDate01) : undefined}
                    onChange={(value: moment.Moment | null) =>
                      handleFormDataChange({
                        emRefundDate01: value?.format('YYYY-MM-DD')
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <SalaryInput
                    size="small"
                    className="amount"
                    readOnly={readOnly}
                    value={formData.awRefundAmt02}
                    onChange={(value: number | null) => handleFormDataChange({ awRefundAmt02: value })}
                  />
                </td>
                <td>
                  <Input.Date
                    size="small"
                    className="ir8a-form__input"
                    inputReadOnly={readOnly}
                    value={formData.awRefundStartDate02 ? moment(formData.awRefundStartDate02) : undefined}
                    onChange={(value: moment.Moment | null) =>
                      handleFormDataChange({
                        awRefundStartDate02: value?.format('YYYY-MM-DD')
                      })
                    }
                  />
                </td>
                <td>
                  <Input.Date
                    size="small"
                    className="ir8a-form__input"
                    inputReadOnly={readOnly}
                    value={formData.awRefundEndDate02 ? moment(formData.awRefundEndDate02) : undefined}
                    onChange={(value: moment.Moment | null) =>
                      handleFormDataChange({
                        awRefundEndDate02: value?.format('YYYY-MM-DD')
                      })
                    }
                  />
                </td>
                <td>
                  <Input.Date
                    size="small"
                    className="ir8a-form__input"
                    inputReadOnly={readOnly}
                    value={formData.awRefundPaymentDate02 ? moment(formData.awRefundPaymentDate02) : undefined}
                    onChange={(value: moment.Moment | null) =>
                      handleFormDataChange({
                        awRefundPaymentDate02: value?.format('YYYY-MM-DD')
                      })
                    }
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="amount"
                    readOnly={readOnly}
                    value={formData.erRefundAmt02}
                    onChange={(value: number | null) => handleFormDataChange({ erRefundAmt02: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="interest"
                    readOnly={readOnly}
                    value={formData.erRefundInterest02}
                    onChange={(value: number | null) => handleFormDataChange({ erRefundInterest02: value })}
                  />
                </td>
                <td>
                  <Input.Date
                    size="small"
                    className="ir8a-form__input"
                    inputReadOnly={readOnly}
                    value={formData.erRefundDate02 ? moment(formData.erRefundDate02) : undefined}
                    onChange={(value: moment.Moment | null) =>
                      handleFormDataChange({
                        erRefundDate02: value?.format('YYYY-MM-DD')
                      })
                    }
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="amount"
                    readOnly={readOnly}
                    value={formData.emRefundAmt02}
                    onChange={(value: number | null) => handleFormDataChange({ emRefundAmt02: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="interest"
                    readOnly={readOnly}
                    value={formData.emRefundInterest02}
                    onChange={(value: number | null) => handleFormDataChange({ emRefundInterest02: value })}
                  />
                </td>
                <td>
                  <Input.Date
                    size="small"
                    className="ir8a-form__input"
                    inputReadOnly={readOnly}
                    value={formData.emRefundDate02 ? moment(formData.emRefundDate02) : undefined}
                    onChange={(value: moment.Moment | null) =>
                      handleFormDataChange({
                        emRefundDate02: value?.format('YYYY-MM-DD')
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <SalaryInput
                    size="small"
                    className="amount"
                    readOnly={readOnly}
                    value={formData.awRefundAmt03}
                    onChange={(value: number | null) => handleFormDataChange({ awRefundAmt03: value })}
                  />
                </td>
                <td>
                  <Input.Date
                    size="small"
                    className="ir8a-form__input"
                    inputReadOnly={readOnly}
                    value={formData.awRefundStartDate03 ? moment(formData.awRefundStartDate03) : undefined}
                    onChange={(value: moment.Moment | null) =>
                      handleFormDataChange({
                        awRefundStartDate03: value?.format('YYYY-MM-DD')
                      })
                    }
                  />
                </td>
                <td>
                  <Input.Date
                    size="small"
                    className="ir8a-form__input"
                    inputReadOnly={readOnly}
                    value={formData.awRefundEndDate03 ? moment(formData.awRefundEndDate03) : undefined}
                    onChange={(value: moment.Moment | null) =>
                      handleFormDataChange({
                        awRefundEndDate03: value?.format('YYYY-MM-DD')
                      })
                    }
                  />
                </td>
                <td>
                  <Input.Date
                    size="small"
                    className="ir8a-form__input"
                    inputReadOnly={readOnly}
                    value={formData.awRefundPaymentDate03 ? moment(formData.awRefundPaymentDate03) : undefined}
                    onChange={(value: moment.Moment | null) =>
                      handleFormDataChange({
                        awRefundPaymentDate03: value?.format('YYYY-MM-DD')
                      })
                    }
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="amount"
                    readOnly={readOnly}
                    value={formData.erRefundAmt03}
                    onChange={(value: number | null) => handleFormDataChange({ erRefundAmt03: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="interest"
                    readOnly={readOnly}
                    value={formData.erRefundInterest03}
                    onChange={(value: number | null) => handleFormDataChange({ erRefundInterest03: value })}
                  />
                </td>
                <td>
                  <Input.Date
                    size="small"
                    className="ir8a-form__input"
                    inputReadOnly={readOnly}
                    value={formData.erRefundDate03 ? moment(formData.erRefundDate03) : undefined}
                    onChange={(value: moment.Moment | null) =>
                      handleFormDataChange({
                        erRefundDate03: value?.format('YYYY-MM-DD')
                      })
                    }
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="amount"
                    readOnly={readOnly}
                    value={formData.emRefundAmt03}
                    onChange={(value: number | null) => handleFormDataChange({ emRefundAmt03: value })}
                  />
                </td>
                <td>
                  <SalaryInput
                    size="small"
                    className="interest"
                    readOnly={readOnly}
                    value={formData.emRefundInterest03}
                    onChange={(value: number | null) => handleFormDataChange({ emRefundInterest03: value })}
                  />
                </td>
                <td>
                  <Input.Date
                    size="small"
                    className="ir8a-form__input"
                    inputReadOnly={readOnly}
                    value={formData.emRefundDate03 ? moment(formData.emRefundDate03) : undefined}
                    onChange={(value: moment.Moment | null) =>
                      handleFormDataChange({
                        emRefundDate03: value?.format('YYYY-MM-DD')
                      })
                    }
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <ErrorDisplay errors={errors} />
      </div>
    </div>
  )
}
