import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import useDeepCompareEffect from 'use-deep-compare-effect'
import moment from 'moment-timezone'
import { useSysOptions } from '~/features/employee'
import { fetchMasters, PayGroupState, selectMasters } from '~/features/master'
import { PayRunStatus, PayRunType } from '~/constants'
import { showError, isInactive } from '~/utils'
import { PAY_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { ActionResult, StoreState } from '~/types/store'
import { addPayRun, fetchPayRuns, fetchPayRunSummaries, processPayroll } from '../../actions'
import { selectPayRunsRecent } from '../../selectors'
import { mapPayRunStateToPayRunInfoNew, PayRunState } from '../../types'
import { PayRunCard } from './PayRunCard'
import './PayRunsRecent.less'

export interface PayRunsRecentProps {}

interface PayGroupWPayDate extends PayGroupState {
  payDate: string
  payPeriod: string
  payRunType: string
}

const TODAY = moment().format('YYYY-MM-DD')
const TODAY_PERIOD = moment().format('YYYY-MM')

export const PayRunsRecent: FC<PayRunsRecentProps> = () => {
  const history = useHistory()
  const [creating, setCreating] = useState(false)
  const recents = useSelector(selectPayRunsRecent)()
  const summaries = useSelector((state: StoreState) => state.payroll.payRunSummaries.entities)
  const payGroups = useSelector(selectMasters)('payGroup') as (PayGroupState | undefined)[]
  const [payRunTypes] = useSysOptions('pay_run_type')

  const maxPayRun = useMemo(() => {
    return recents
      .filter(r => r.payRunType !== PayRunType.adhoc)
      .reduce((acc: PayRunState, current: PayRunState) => {
        if ((acc.payDate || '1900-01-01') < current.payDate) {
          acc = current
        }
        return acc
      }, {} as PayRunState)
  }, [recents])

  const payRunsWSamePayDate = useMemo(() => {
    return recents.filter(r => r.payDate === maxPayRun.payDate).map(r => r.payGroupId)
  }, [recents, maxPayRun.payDate])

  useEffect(() => {
    dispatch(fetchPayRuns())
    dispatch(fetchMasters('payGroup'))
  }, [])

  useDeepCompareEffect(() => {
    dispatch(fetchPayRunSummaries(recents.map(r => r.id)))
  }, [recents])

  const handleAddPayRunNow = useCallback(
    async (payRun?: PayRunState) => {
      if (!payRun) return

      let result: ActionResult | undefined
      setCreating(true)
      try {
        const request = mapPayRunStateToPayRunInfoNew(payRun)
        result = await dispatch(addPayRun(request))
      } finally {
        setCreating(false)
      }

      if (result?.errors) {
        showError('An error has occurred')
      } else {
        if (result?.result?.id) {
          const payRunId = result?.result?.id
          history.push(PAY_ROUTES.payRun.replace(':id', payRunId).replace(':tab?', 'records'))
          dispatch(processPayroll(payRunId, undefined, 'all'))
        }
      }
    },
    [history]
  )

  const newPayRun: PayRunState = useMemo(() => {
    const maxPayDate = moment(maxPayRun.payDate).format('YYYY-MM-DD') || TODAY // Trim time
    const maxPayPeriod = maxPayRun.payPeriod || TODAY_PERIOD

    const getDate = (period: string, day: number, addMonth: number = 0) => {
      let date = moment(`${period}-01`).add(addMonth, 'months')

      const lastDayOfMonth = date.daysInMonth()
      date = date.date(day > lastDayOfMonth ? lastDayOfMonth : day)

      return date.format('YYYY-MM-DD')
    }

    const isUpcoming = (payGroupId: string, candidate: string, cutOff: string, payDate: string) => {
      return (
        (payRunsWSamePayDate.includes(payGroupId) && candidate > cutOff && candidate < payDate) ||
        (!payRunsWSamePayDate.includes(payGroupId) && candidate >= cutOff && candidate < payDate)
      )
    }

    const upcomingPayGroup = (payGroups as PayGroupState[])
      .filter(a => !isInactive(a?.inactiveDate))
      .reduce(
        (acc: PayGroupWPayDate, current: PayGroupState) => {
          let payDate: string = '',
            payPeriod: string = ''
          if (current.isMidMonth) {
            // Half 1 current month
            payDate = getDate(maxPayPeriod, current.paymentDay1, current.paymentMonth1)
            payPeriod = maxPayPeriod
            if (isUpcoming(current.id, payDate, maxPayDate, acc.payDate)) {
              acc = { ...current, payDate, payPeriod, payRunType: PayRunType.firstHalf }
            } else {
              // Half 2 current month
              payDate = getDate(maxPayPeriod, current.paymentDay2, current.paymentMonth2)
              payPeriod = maxPayPeriod
              if (isUpcoming(current.id, payDate, maxPayDate, acc.payDate)) {
                acc = { ...current, payDate, payPeriod, payRunType: PayRunType.secondHalf }
              } else {
                // Half 1 next month
                payDate = getDate(maxPayPeriod, current.paymentDay1, current.paymentMonth1 + 1)
                payPeriod = moment(maxPayPeriod).add(1, 'month').format('YYYY-MM')
                if (isUpcoming(current.id, payDate, maxPayDate, acc.payDate)) {
                  acc = { ...current, payDate, payPeriod, payRunType: PayRunType.firstHalf }
                } else {
                  // Half 2 next month
                  payDate = getDate(maxPayPeriod, current.paymentDay2, current.paymentMonth2 + 1)
                  payPeriod = moment(maxPayPeriod).add(1, 'month').format('YYYY-MM')
                  if (isUpcoming(current.id, payDate, maxPayDate, acc.payDate)) {
                    acc = { ...current, payDate, payPeriod, payRunType: PayRunType.secondHalf }
                  }
                }
              }
            }
          } else {
            // Current month
            payDate = getDate(maxPayPeriod, current.paymentDay, current.paymentMonth)
            payPeriod = maxPayPeriod
            if (isUpcoming(current.id, payDate, maxPayDate, acc.payDate)) {
              acc = { ...current, payDate, payPeriod, payRunType: PayRunType.wholeMonth }
            } else {
              // Next month
              payDate = getDate(maxPayPeriod, current.paymentDay, current.paymentMonth + 1)
              payPeriod = moment(maxPayPeriod).add(1, 'month').format('YYYY-MM')
              if (isUpcoming(current.id, payDate, maxPayDate, acc.payDate)) {
                acc = { ...current, payDate, payPeriod, payRunType: PayRunType.wholeMonth }
              }
            }
          }
          return acc
        },
        { payDate: '9999-12-31', payRunType: PayRunType.wholeMonth } as PayGroupWPayDate
      )

    const payRunTypeName = payRunTypes[upcomingPayGroup.payRunType]?.value || ''
    const defaultPayRunDesc = `${payRunTypeName} ${upcomingPayGroup.payPeriod} ${upcomingPayGroup.name}`

    return {
      id: '',
      payGroupId: upcomingPayGroup.id,
      payPeriod: upcomingPayGroup.payPeriod,
      payRunType: upcomingPayGroup.payRunType,
      description: defaultPayRunDesc,
      startDate: '', // backend derive
      endDate: '', // backend derive
      payDate: upcomingPayGroup.payDate,
      status: PayRunStatus.verification,
      otStartDate: '',
      otEndDate: '',
      notes: ''
    }
  }, [payGroups, maxPayRun.payDate, maxPayRun.payPeriod, payRunsWSamePayDate, payRunTypes])

  return (
    <div className="payruns-recent">
      <div className="payruns-recent__body">
        <PayRunCard payrun={newPayRun} loading={creating} onAdd={handleAddPayRunNow} />
        {recents.map(run => (
          <PayRunCard key={run.id} payrun={run} summary={summaries[run.id]} />
        ))}
      </div>
    </div>
  )
}
