import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { ClaimRecordApprovalHistoryState } from '../types'

export const selectClaimRecordApprovalHistories = createSelector(
  (state: StoreState) => state.claim.claimRecordApprovalHistories,
  claimRecordApprovalHistoriesState =>
    memoize((claimRecordId: string) => {
      return Object.values(
        claimRecordApprovalHistoriesState[claimRecordId]?.entities || {}
      ) as ClaimRecordApprovalHistoryState[]
    })
)
