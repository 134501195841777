import { replaceCtPolicyEt, setCtPolicyEtLoading } from '../reducers'
import { apiGetCtPolicyEt } from '../api/ct-policy.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchCtPolicyEt = (
  claimTypeId: string,
  ctPolicyId: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().claim.ctPolicyEtLoading[ctPolicyId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().claim.ctPolicyEt[ctPolicyId]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setCtPolicyEtLoading({ id: ctPolicyId, loading: true }))
      const { result, status } = await apiGetCtPolicyEt(claimTypeId, ctPolicyId)
      if (status) {
        dispatch(replaceCtPolicyEt({ id: ctPolicyId, data: result }))
      }
    } finally {
      dispatch(setCtPolicyEtLoading({ id: ctPolicyId, loading: false }))
    }
  }
}
