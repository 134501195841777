import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { PayWarningState, PayWarningTagState } from '../types'

export const selectPayWarningTags = createSelector(
  (state: StoreState) => state.payroll.payWarnings,
  (state: StoreState) => state.rule.sysRuleCriteria,
  (payWarningsState, sysRuleCriteriaState) =>
    memoize((payRunId: string): PayWarningTagState[] => {
      return (Object.values(payWarningsState[payRunId]?.entities || {}) as PayWarningState[])
        .map(w => {
          const rule = sysRuleCriteriaState?.entities[w.ruleCriteriaId]
          return {
            ...w,
            tag: w.tag || rule?.tag,
            color: w.color || rule?.color
          } as PayWarningTagState
        })
        .filter(x => x?.tag && x.color)
    })
)
