import React, { FC, useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import confirm from 'antd/lib/modal/confirm'
import { Button, PageHeader } from '~/core-components'
import { DocumentTitle } from '~/components'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { usePermissionGate } from '~/features/iam'
import { attendanceMenus, useMasterMenu } from '~/features/master'
import { dispatch } from '~/stores/store'
import { ActionResult } from '~/types/store'
import { MenuItem } from '~/types/common'
import { Permission, PermissionAction } from '~/constants'
import { ShiftInfo } from './components/ShiftInfo'
import { useShift, useShifts } from '../../hooks'
import { deleteShift } from '../../actions'
import { AddShiftDrawer } from './components/AddShiftDrawer'
import { ShiftDay } from './components/ShiftDay'
import { ShiftTes } from './components/ShiftTes'
import './Shift.less'

interface ShiftProps {}

interface ShiftParams {
  id: string
}

interface DrawerState {
  visible: boolean
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: SETTINGS_ROUTES.shifts,
    breadcrumbName: 'Overview'
  },
  {
    path: '',
    breadcrumbName: 'Shift'
  }
]

export const Shift: FC<ShiftProps> = () => {
  const { id } = useParams<ShiftParams>()
  const [shifts] = useShifts('when-empty')
  const [shift] = useShift(id)
  const [editing, setEditing] = useState(false)
  const history = useHistory()
  const canModify = usePermissionGate(Permission.shift, PermissionAction.Modify)
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const { setMenus } = useMasterMenu()

  const handleAddShift = useCallback(() => {
    setDrawerState({ visible: true })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  useEffect(() => {
    const menus: MenuItem[] = shifts.map(s => ({
      path: SETTINGS_ROUTES.shift.replace(':id', s!.id),
      value: s!.name,
      sysPermissionId: Permission.shift
    }))

    if (canModify)
      menus.push({
        path: (
          <Button type="dashed" onClick={handleAddShift} block>
            Add shift
          </Button>
        ),
        value: '',
        sysPermissionId: ''
      })

    setMenus(menus)
    return () => {
      setMenus(attendanceMenus)
    }
  }, [setMenus, shifts, canModify, handleAddShift])

  const handleEdit = useCallback(() => setEditing(true), [])
  const handleView = useCallback(() => setEditing(false), [])

  const handleDelete = useCallback(() => {
    if (id && shift) {
      confirm({
        title: 'Delete shift',
        content: `Do you want to delete shift "${shift.name}"?`,
        onOk: async () => {
          const result: ActionResult | undefined = await dispatch(deleteShift(id))
          if (!result?.errors) {
            history.push(SETTINGS_ROUTES.shifts)
          }
        },
        okText: 'Delete',
        okType: 'danger'
      })
    }
  }, [id, shift, history])

  return (
    <div id="shift" className="shift">
      <DocumentTitle title="Shift" />
      <PageHeader
        title="Shift"
        containerId="shift"
        breadcrumb={{ routes }}
        extra={
          canModify &&
          editing && (
            <Button key="add" onClick={handleDelete}>
              Delete
            </Button>
          )
        }
      />
      <div className="shift__body">
        <ShiftInfo shift={shift} onEdit={handleEdit} onSave={handleView} onCancel={handleView} />
        <ShiftDay shift={shift} />
        <ShiftTes shiftId={shift?.id} />
        {canModify && <AddShiftDrawer {...drawerState} onClose={handleCloseDrawer} />}
      </div>
    </div>
  )
}
