import React, { CSSProperties, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Card, ColumnsType, Form, Link, LinkGroup, SecondaryText, Space, Table } from '~/core-components'
import { Col, Row, SalaryInput } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { useFirstInView, useToggle } from '~/hooks'
import { Permission, PermissionAction } from '~/constants'
import { StoreState } from '~/types/store'
import { dispatch } from '~/stores/store'
import { formatMoney } from '~/utils'
import { selectCurrentShiftTe, selectShiftTes } from '../../../selectors'
import { IShiftTeInfo, ShiftTeState } from '../../../types'
import { fetchShiftTes } from '../../../actions'
import { useTeConfigsForShiftRole } from '../../../hooks'
import { MutateShiftTeDrawer } from './MutateShiftTeDrawer'

interface ShiftTesProps {
  shiftId?: string
}

type ShiftTeTable = ShiftTeState

const EMPTY_FORM_DATA: IShiftTeInfo = {
  startDate: '',
  te01: 0,
  te02: 0,
  te03: 0,
  te04: 0,
  te05: 0,
  te06: 0,
  te07: 0,
  te08: 0,
  te09: 0,
  te10: 0
}

interface DrawerState {
  visible: boolean
  shiftId?: string
  data?: ShiftTeState
  isCurrent: boolean
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false, isCurrent: false }

const cardBodyStyle: CSSProperties = { paddingTop: 6, paddingBottom: 6, paddingLeft: 0, paddingRight: 0 }
const formStyle: CSSProperties = { padding: '0 24px' }
const linkGroupStyle: CSSProperties = { position: 'absolute', top: 0, right: 0 }
const dateColWidth = 130
const teColWidth = 80
const actionColWidth = 50

export const ShiftTes: FC<ShiftTesProps> = ({ shiftId }) => {
  const { ref, inView } = useFirstInView<HTMLDivElement>({ threshold: 0.25 })
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const [formData, setFormData] = useState<IShiftTeInfo>(EMPTY_FORM_DATA)
  const current = useSelector(selectCurrentShiftTe)(shiftId)
  const currentId = current?.id
  const histories = useSelector(selectShiftTes)(shiftId)
  const [showHistory, toggleShowHistory] = useToggle()
  const canModify = usePermissionGate(Permission.shiftRole, PermissionAction.Modify)
  const loading = useSelector((state: StoreState) => state.attendance.shiftTesLoading[shiftId || ''])
  const [teConfigs] = useTeConfigsForShiftRole()

  useEffect(() => {
    if (inView) {
      if (shiftId) {
        dispatch(fetchShiftTes(shiftId))
      }
    }
  }, [inView, shiftId])

  useEffect(() => {
    if (showHistory && histories.length === 0) {
      toggleShowHistory()
    }
  }, [histories.length, showHistory, toggleShowHistory])

  useEffect(() => {
    if (current) {
      const { startDate, te01, te02, te03, te04, te05, te06, te07, te08, te09, te10 } = current
      setFormData({ startDate, te01, te02, te03, te04, te05, te06, te07, te08, te09, te10 })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [current])

  const handleEdit = useCallback(
    (shiftTe?: ShiftTeState) => {
      setDrawerState({ visible: true, shiftId, data: shiftTe, isCurrent: currentId === shiftTe?.id })
    },
    [shiftId, currentId, setDrawerState]
  )

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const tableWidth = dateColWidth + teConfigs.length * teColWidth + actionColWidth

  const columns = useMemo(() => {
    let columns: ColumnsType<ShiftTeTable> = [
      {
        title: 'Effective date',
        key: 'startDate',
        dataIndex: 'startDate',
        width: dateColWidth,
        render: (value: string) => value && moment(value).format('DD MMM YYYY')
      }
    ]

    for (const teConfig of teConfigs) {
      columns.push({
        title: teConfig.name,
        key: teConfig.code,
        dataIndex: teConfig.code,
        width: teColWidth,
        render: (value: number) => formatMoney(value, 2)
      })
    }

    columns.push({
      key: 'action',
      align: 'right',
      width: actionColWidth,
      fixed: 'right',
      render: (value: string, record) =>
        canModify && (
          <Link size="small" onClick={() => handleEdit(record)}>
            edit
          </Link>
        )
    })

    return columns
  }, [teConfigs, canModify, handleEdit])

  return (
    <div ref={ref}>
      <Card
        title={
          <Row>
            <Col flex="1">
              <Space>
                <span>Time elements</span>
                {formData.startDate && (
                  <SecondaryText size="small">
                    effective from {moment(formData.startDate).format('DD MMM YYYY')}
                  </SecondaryText>
                )}
              </Space>
            </Col>
            <Col hidden={loading}>
              <LinkGroup style={linkGroupStyle}>
                {canModify && (
                  <Link size="small" onClick={() => handleEdit(current)}>
                    {current ? 'edit' : 'add'}
                  </Link>
                )}
                {(histories?.length || 0) > 0 && (
                  <Link size="small" onClick={toggleShowHistory}>
                    {showHistory ? 'hide history' : 'history'}
                  </Link>
                )}
              </LinkGroup>
            </Col>
          </Row>
        }
        bodyStyle={cardBodyStyle}
        loading={loading}
      >
        {!showHistory && (
          <Form style={formStyle}>
            <Row gutter={30}>
              {teConfigs
                .filter(teConfig => teConfig.isShownInShift)
                .map(teConfig => (
                  <Col span={12} key={teConfig.code}>
                    <Form.Item label={teConfig.name}>
                      <SalaryInput value={formData[teConfig.code as keyof IShiftTeInfo] as number} />
                    </Form.Item>
                  </Col>
                ))}
            </Row>
          </Form>
        )}
        {showHistory && (
          <Table rowKey="id" dataSource={histories} pagination={false} columns={columns} scroll={{ x: tableWidth }} />
        )}
        {canModify && <MutateShiftTeDrawer {...drawerState} onClose={handleCloseDrawer} />}
      </Card>
    </div>
  )
}
