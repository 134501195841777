import { showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiUnlockIr8aDraft } from '../api/ytd.api'
import { Ir8aFormState } from '../types'
import { setIr8aForm, setYtdSubmissionIr8aView } from '../reducers'

export const unlockIr8aDraft = (id: string): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const { status, errors, message, errorData } = await apiUnlockIr8aDraft(id)
    if (status) {
      const unlock = { lockedBy: '', lockedDate: '' }
      const ir8aForms = Object.values(getState().tax.ir8aForm?.entities || {}) as Ir8aFormState[]
      const ir8aForm = ir8aForms.find(a => a.id === id)
      const data: Ir8aFormState = { ...(ir8aForm as Ir8aFormState), ...unlock }
      dispatch(setIr8aForm(data))

      const ir8aViews = getState().tax.ytdSubmissionIr8aViews
      ir8aViews?.ids.forEach(viewId => {
        const ir8aView = ir8aViews?.entities[viewId]
        const found = ir8aView?.data.find(r => r.id === id)
        if (found && ir8aView) {
          const updates = ir8aView.data.map(d => (d.id === id ? { ...d, ...unlock } : d))
          dispatch(setYtdSubmissionIr8aView({ ...ir8aView, data: updates }))
        }
      })
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
