import { showSuccess, showError } from '~/utils'
import { PayRunStatus } from '~/constants'
import { ThunkResult } from '~/types/store'
import { apiCompletePayRun } from '../api/payrun.api'
import { PayRunState } from '../types'
import { setPayRun } from '../reducers'

export const completePayRun = (id: string): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const { status, errors, message, errorData } = await apiCompletePayRun(id)
    if (status) {
      let payRun = getState().payroll.payRuns?.entities[id]
      dispatch(setPayRun({ ...(payRun as PayRunState), status: PayRunStatus.completed }))

      showSuccess('Payroll run has marked as complete')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
