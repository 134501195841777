import React, { FC, useCallback, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { ColumnsType, Link, LinkGroup, Spin, Table } from '~/core-components'
import { DrawerForm } from '~/components'
import { dispatch } from '~/stores/store'
import { useXeroConnections } from '../../hooks'
import { disconnectXeroConnection } from '../../actions'
import { XeroConnectionState } from '../../types'

interface XeroDisconnectDrawerProps {
  open: boolean
  onClose: () => void
}

type ConnectionTable = XeroConnectionState

export const XeroDisconnectDrawer: FC<XeroDisconnectDrawerProps> = ({ open, onClose }) => {
  const [connections, loading] = useXeroConnections()
  const [disconnecting, setDisconnecting] = useState('')

  const handleDisconnect = useCallback(async connectionId => {
    try {
      setDisconnecting(connectionId)
      await dispatch(disconnectXeroConnection(connectionId))
    } finally {
      setDisconnecting('')
    }
  }, [])

  const handleCloseDrawer = useCallback(() => {
    typeof onClose === 'function' && onClose()
  }, [onClose])

  const columns: ColumnsType<ConnectionTable> = [
    {
      title: 'Xero Organisation',
      key: 'tenantName',
      dataIndex: 'tenantName'
    },
    {
      key: 'action',
      align: 'right',
      dataIndex: 'id',
      render: (connectionId: string, record) => (
        <LinkGroup>
          {disconnecting === record.id ? (
            <Spin indicator={<LoadingOutlined spin />} />
          ) : (
            <Link onClick={() => handleDisconnect(connectionId)}>Disconnect</Link>
          )}
        </LinkGroup>
      )
    }
  ]

  return (
    <DrawerForm
      open={open}
      title="Disconnect from Xero"
      okText=""
      onClose={handleCloseDrawer}
      confirmLoading={loading}
      width={500}
      className="xero-disconnect-drawer"
      formId="form-xero-disconnect"
    >
      <Table rowKey="id" dataSource={connections} pagination={false} columns={columns} loading={loading} />
    </DrawerForm>
  )
}
