import { showError, showInfo } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiValidateDailyPolicyRow } from '../api/attendance.api'

export const validateDailyPolicyRow = (
  fieldName: string,
  expression: string,
  criteriaExpr: string
): ThunkResult<void> => {
  return async () => {
    const { status, result, errors, message, errorData } = await apiValidateDailyPolicyRow(
      fieldName,
      expression,
      criteriaExpr
    )
    if (status) {
      showInfo('Validation', 'Validate successful')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors, result }
  }
}
