import React, { FC } from 'react'
import { EmpKeyValues } from '~/components'
import { Form, SelectProps } from '~/core-components'
import './SelectCompany.less'

export interface SelectCompanyProps extends SelectProps {
  companyId: string
  onChange: (companyId: string) => void
}

export const SelectCompany: FC<SelectCompanyProps> = ({ companyId, onChange, ...props }) => {
  return (
    <div className="select-company">
      <Form layout="horizontal" colon={false}>
        <Form.Item label="Company">
          <EmpKeyValues id="company" value={companyId} size="small" allowClear={false} onChange={onChange} {...props} />
        </Form.Item>
      </Form>
    </div>
  )
}
