import { request, getBaseUrl } from '~/utils/request'
import { SSLeaveApply, SSLeaveApplyFormInfoState, SSLeaveBuddyConflictState } from '../types'
import { UploadFile } from '../../../core-components'
import { ILeaveRecord, LeaveRecordDtlState } from '~/features/leave'

const baseUrl = getBaseUrl('/leave/ssleaveapply')

export const apiApplyMyLeaveRecord = (id: string, leaveRecord: SSLeaveApply) => {
  let formData = new FormData()
  Object.entries(leaveRecord).forEach(([key, value]) => {
    if (key === 'attachments') {
      value.forEach((x: UploadFile) => {
        if (x instanceof File) formData.append(key, x, x.name)
      })
    } else if (key === 'deletedAttachmentIds') {
      value.forEach((x: string) => {
        formData.append(key, x)
      })
    } else {
      formData.append(key, value === null ? '' : value)
    }
  })

  formData.append('id', id)

  formData.append(
    'dataJsonb',
    JSON.stringify({
      ...('isShared' in leaveRecord ? { isShared: leaveRecord.isShared } : {}),
      ...('sharedQuantity' in leaveRecord ? { sharedQuantity: leaveRecord.sharedQuantity } : {}),
      ...('takenDuration' in leaveRecord ? { takenDuration: leaveRecord.takenDuration } : {}),
      ...('relationship' in leaveRecord ? { relationship: leaveRecord.relationship } : {})
    })
  )

  return request<{
    status: string
    request: SSLeaveApply
    data: ILeaveRecord
    dtls: LeaveRecordDtlState[]
    conflicts: LeaveRecordDtlState[]
    isBlock: boolean
    buddies: SSLeaveBuddyConflictState[]
  }>('post', `${baseUrl}/${id}`, formData)
}

export const apiCancelMyLeaveRecord = (id: string, leaveRecordDtlIds?: string[]) => {
  return request<{
    status: string
    data: ILeaveRecord
  }>('post', `${baseUrl}/${id}/cancel`, { id, leaveRecordDtlIds })
}

export const apiGetMyApplyFormSettings = (leaveTypeId: string) =>
  request<SSLeaveApplyFormInfoState[]>('get', `${baseUrl}/settings/${leaveTypeId}`)
