import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiDeleteScheduleRecordByRole } from '../api/ro-schedule-record.api'
import { refetchROScheduleRecordsViewCell } from '../reducers'

export const deleteScheduleRecordByRole = (role: string, id: string): ThunkResult<void> => {
  return async dispatch => {
    if (!role || !id) return

    const { status, errors, message, errorData } = await apiDeleteScheduleRecordByRole(role, id)
    if (status) {
      dispatch(refetchROScheduleRecordsViewCell(id))
      showSuccess('Deleted')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
