import React, { forwardRef, useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Select, SelectProps } from '~/core-components'
import { fetchEmpKeyvalues } from '~/features/master'
import { sortKeyValues, SysOptionType } from '~/features/employee'
import { useFirstInView } from '~/hooks'
import { isInactive } from '~/utils/dateUtil'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { FetchStrategy, KeyValue } from '~/types/common'
import { snakeToCamel } from '~/utils'
import { InactiveTag } from '../InactiveTag/InactiveTag'
import { DropdownActions } from './DropdownActions'

interface SysOptionsProps extends SelectProps {
  type: SysOptionType
  renderItem?: (kv: KeyValue) => React.ReactNode
  sort?: 'value' | 'key'
  onFilter?: (value: KeyValue | undefined, index: number, array: (KeyValue | undefined)[]) => {}
  fetchStrategy?: FetchStrategy
  allowAll?: boolean
}

const AllowAll: KeyValue = { key: '*', value: 'All' }

const SysOptionsInternal = (
  {
    type,
    renderItem,
    sort,
    onFilter,
    children,
    fetchStrategy = 'when-empty',
    allowAll = false,
    style,
    ...props
  }: SysOptionsProps,
  ref: React.Ref<any>
) => {
  const id = snakeToCamel(type)
  const { ref: inViewRef, inView } = useFirstInView<HTMLDivElement>({ threshold: 0.25 })
  const data = useSelector((state: StoreState) => state.master.keyvalues[id]?.entities || undefined)
  const loading = useSelector((state: StoreState) => state.master.keyvaluesLoading[id])

  const divStyle = useMemo(() => {
    if (style && 'width' in style) {
      return { width: style.width }
    }

    return {}
  }, [style])

  const options = useMemo(() => {
    if (!data) {
      return allowAll ? [AllowAll] : []
    }

    let result = (allowAll ? [...Object.values(data), AllowAll] : Object.values(data))
      .filter(a => !isInactive(a?.inactiveDate) || a?.key === props.value)
      .sort((a, b) => sortKeyValues(a!, b!, sort))

    if (typeof onFilter === 'function') {
      result = result.filter(onFilter)
    }
    return result
  }, [data, sort, allowAll, props.value, onFilter])

  useEffect(() => {
    if (inView) {
      dispatch(fetchEmpKeyvalues(id, 'sysoption', { type }, { strategy: fetchStrategy }))
    }
  }, [inView, id, type, fetchStrategy])

  const handleRefresh = useCallback(() => {
    dispatch(fetchEmpKeyvalues(id, 'sysoption', { type }))
  }, [id, type])

  return (
    <div ref={inViewRef} style={divStyle}>
      <Select
        ref={ref}
        showSearch
        optionFilterProp="title"
        style={style}
        dropdownRender={menu => (
          <div>
            {menu}
            <DropdownActions onRefresh={handleRefresh} />
          </div>
        )}
        {...props}
        loading={loading}
      >
        {options?.map(kv => (
          <Select.Option key={kv!.key} value={kv!.key || ''} title={kv!.value}>
            {isInactive(kv!.inactiveDate) && <InactiveTag />}
            {renderItem == null ? kv!.value : kv && renderItem(kv)}
          </Select.Option>
        ))}
      </Select>
    </div>
  )
}

export const SysOptions = forwardRef(SysOptionsInternal)
