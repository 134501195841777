import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Card, Table, Link, ColumnsType } from '~/core-components'
import { DownloadFileAuth } from '~/components'
import { fetchMasters } from '~/features/master'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { useFirstInView } from '~/hooks/use-first-in-view'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { formatFileSize, getBaseUrl } from '~/utils'
import { MutateEmDocumentDrawer } from './MutateEmDocumentDrawer'
import { fetchEmDocuments } from '../../../actions'
import { selectEmDocuments } from '../../../selectors'
import { EmDocumentState } from '../../../types'
import './EmDocuments.less'

interface EmDocumentsProps {
  employeeId?: string
}

type EmDocumentTable = {
  name: string
  children: EmDocumentState[]
}

interface DrawerState {
  visible: boolean
  employeeId?: string
  data?: EmDocumentState
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

const cardStyle: CSSProperties = { margin: 24 }
const cardBodyStyle: CSSProperties = { padding: 0 }
const groupRowStyle: CSSProperties = { whiteSpace: 'nowrap' }
const expandable = { defaultExpandAllRows: true, expandRowByClick: false }
const baseUrl = getBaseUrl('/employee')

export const EmDocuments: FC<EmDocumentsProps> = ({ employeeId }: EmDocumentsProps) => {
  const { ref, inView } = useFirstInView<HTMLDivElement>({ threshold: 0.25 })
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const loading = useSelector((state: StoreState) => state.employee.emDocumentsLoading[employeeId || ''])
  const emDocuments = useSelector(selectEmDocuments)(employeeId)
  const docTypes = useSelector((state: StoreState) => state.master.masters['docType'])
  const canView = usePermissionGate(Permission.employeeDocument)
  const canModify = usePermissionGate(Permission.employeeDocument, PermissionAction.Modify)

  useEffect(() => {
    dispatch(fetchMasters('docType', { strategy: 'when-empty' }))
  }, [])

  useEffect(() => {
    if (inView) {
      if (employeeId) {
        dispatch(fetchEmDocuments(employeeId))
      }
    }
  }, [inView, employeeId])

  const handleAddDocument = useCallback(() => {
    setDrawerState({ visible: true, employeeId, data: undefined })
  }, [employeeId, setDrawerState])

  const handleEditDocument = useCallback(
    (emDocument: EmDocumentState) => {
      setDrawerState({ visible: true, employeeId, data: emDocument })
    },
    [employeeId, setDrawerState]
  )

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const columns: ColumnsType<EmDocumentTable | EmDocumentState> = [
    {
      title: 'Document name',
      key: 'name',
      dataIndex: 'name',
      width: '40%',
      render: (value: string, record) =>
        'children' in record ? (
          <div style={groupRowStyle}>
            {docTypes?.entities[value]?.name} ({record.children.length}{' '}
            {record.children.length > 1 ? 'documents' : 'document'})
          </div>
        ) : (
          <DownloadFileAuth url={`${baseUrl}/employee/${record.employeeId}/document/${record.id}/downloadfile`}>
            {value}
          </DownloadFileAuth>
        )
    },
    {
      title: 'Notes',
      key: 'notes',
      dataIndex: 'notes',
      ellipsis: true
    },
    {
      title: 'Size',
      key: 'docFileSize',
      dataIndex: 'docFileSize',
      render: (value: number, record) => ('children' in record ? null : formatFileSize(value))
    },
    {
      title: 'Date',
      key: 'date',
      dataIndex: 'date',
      render: (value: string, record) => ('children' in record ? null : moment(value).format('DD MMM YYYY'))
    },
    {
      key: 'action',
      align: 'right',
      render: (value: string, record) =>
        !('children' in record) &&
        canModify && (
          <Link size="small" onClick={() => handleEditDocument(record)}>
            edit
          </Link>
        )
    }
  ]

  if (!canView) return null

  return (
    <div ref={ref}>
      <Card
        extra={employeeId && canModify && <Link onClick={handleAddDocument}>add</Link>}
        style={cardStyle}
        bodyStyle={cardBodyStyle}
        loading={loading}
      >
        <Table
          rowKey="id"
          dataSource={emDocuments}
          pagination={false}
          indentSize={1}
          expandable={expandable}
          columns={columns}
          loading={loading}
          className="em-document__table"
        />
        {canModify && <MutateEmDocumentDrawer {...drawerState} onClose={handleCloseDrawer} />}
      </Card>
    </div>
  )
}
