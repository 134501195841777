import { setIsEmployee, setIsEmployeesLoading } from '../reducers'
import { apiIsEmployee } from '../api/login.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchIsEmployee = (
  type: 'email' | 'employee_no',
  loginCode: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().iam.isEmployeesLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().iam.isEmployees?.entities[loginCode]
      if (hasData) return
    }

    try {
      dispatch(setIsEmployeesLoading(true))
      const { result, status } = await apiIsEmployee(type, loginCode)
      if (status) {
        dispatch(setIsEmployee({ loginCode, ...result }))
      }
    } finally {
      dispatch(setIsEmployeesLoading(false))
    }
  }
}
