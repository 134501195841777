import React, { CSSProperties, FC, useEffect } from 'react'
import moment from 'moment-timezone'
import { useSelector } from 'react-redux'
import { SecondaryText } from '~/core-components'
import { fetchUser, selectUserById } from '~/features/temp'
import { StoreState } from '~/types/store'
import { memoize } from 'lodash'
import { dispatch } from '~/stores/store'

export interface AuditProps {
  createdBy: string
  createdDate: string
  updatedBy: string
  updatedDate: string
}

const secondaryTextStyles: CSSProperties = { display: 'block', fontSize: 12 }

export const Audit: FC<AuditProps> = ({ createdBy, createdDate, updatedBy, updatedDate }) => {
  const creator = useSelector((state: StoreState) => selectUserById(state, createdBy))
  const updator = useSelector((state: StoreState) => selectUserById(state, updatedBy))

  useEffect(() => {
    dispatch(fetchUser(createdBy, { strategy: 'when-empty' }))
    dispatch(fetchUser(updatedBy, { strategy: 'when-empty' }))
  }, [createdBy, updatedBy])

  return (
    <>
      {createdBy && createdDate && (
        <SecondaryText style={secondaryTextStyles}>
          Created{getAuditDate(createdDate)}
          {getAuditUser(creator?.loginName || createdBy)}
        </SecondaryText>
      )}
      {updatedBy && updatedDate && (
        <SecondaryText style={secondaryTextStyles}>
          Last modified{getAuditDate(updatedDate)}
          {getAuditUser(updator?.loginName || updatedBy)}
        </SecondaryText>
      )}
    </>
  )
}

const getAuditUser = memoize(name => (name ? ` by ${name}` : ''))
const getAuditDate = memoize(date => (date ? ` at ${moment(date).format('DD MMM YYYY hh:mm:ss A')}` : ''))
