import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import uniqBy from 'lodash/uniqBy'
import { StoreState } from '~/types/store'

export const selectCompaniesCpfNo = createSelector(
  (state: StoreState) => state.master.companies,
  companyState =>
    memoize(() => {
      const companies = Object.values(companyState?.entities || {}).filter(co => !!co?.cpfNo)
      const uniqueCompanies = uniqBy(companies, 'cpfNo')
      return uniqueCompanies.map(co => ({
        cpfNo: co?.cpfNo || '',
        companies: companies.filter(c => c?.cpfNo === co?.cpfNo).map(c => c?.name)
      }))
    })
)
