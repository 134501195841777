import { refetchReportTemplates, setReportTemplate } from '../reducers'
import { setKeyvalue } from '~/features/master'
import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { BasePath } from '~/types/common'
import { IReportTemplateSaveAs, ReportCode } from '../types'
import { apiSaveReportTemplateAs } from '../api/report-template.api'

export const saveReportTemplateAs = (
  basePath: BasePath,
  reportCode: ReportCode,
  params: IReportTemplateSaveAs
): ThunkResult<void> => {
  return async dispatch => {
    const { result, status, errors, message, errorData } = await apiSaveReportTemplateAs(basePath, params)

    if (status) {
      if (result) {
        dispatch(setReportTemplate({ reportCode, data: result }))
        dispatch(setKeyvalue({ kv: 'reporttemplate', data: { key: result.id, value: result.templateName } }))
        dispatch(refetchReportTemplates())

        showSuccess('Saved')
      }
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
