import { request, getBaseUrl } from '~/utils/request'
import { ManagerCategory, ManagerState, IManager } from '../types'

const baseUrl = getBaseUrl('/employee/manager')

export const apiGetManagers = (category: ManagerCategory) => request<ManagerState[]>('get', `${baseUrl}/${category}`)

export const apiGetManager = (category: ManagerCategory, id: string) =>
  request<ManagerState>('get', `${baseUrl}/${category}/${id}`)

export const apiDeleteManager = (category: ManagerCategory, id: string) =>
  request<void>('delete', `${baseUrl}/${category}/${id}`)

export const apiSaveManager = (category: ManagerCategory, manager: IManager) =>
  request<{ id: string } | undefined>('post', `${baseUrl}/${category}`, manager)
