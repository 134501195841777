import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { ROLeEarnedState } from '../../types'

export const selectROLeEarned = createSelector(
  (state: StoreState) => state.my.roLeEarned,
  roLeEarnedState =>
    memoize(
      (periodCode?: string, employeeId?: string, leaveTypeId?: string): ROLeEarnedState[] => {
        const entId = `${periodCode}|${employeeId}|${leaveTypeId}`

        return Object.values(roLeEarnedState[entId]?.entities || {}).sort((a, b) => {
          const aStartDate = new Date(a!.startDate).getTime()
          const bStartDate = new Date(b!.startDate).getTime()
          return bStartDate - aStartDate
        }) as ROLeEarnedState[]
      },
      (periodCode?: string, employeeId?: string, leaveTypeId?: string) => `${periodCode}|${employeeId}|${leaveTypeId}`
    )
)
