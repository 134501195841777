import { setPayRun } from '../reducers'
import { apiGetPayRun } from '../api/payrun.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchPayRun = (id: string, options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    if (options.strategy === 'when-empty') {
      const data = getState().payroll.payRuns.entities[id]
      if (data) {
        return
      }
    }

    const { result, status } = await apiGetPayRun(id)
    if (status) {
      dispatch(setPayRun(result))
    }
  }
}
