import { showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { replacePayWarnings, setPayWarningLoading } from '../reducers'
import { apiProcessWarning } from '../api/payroll.api'

export const processPayWarnings = (payRunId: string): ThunkResult<void> => {
  return async dispatch => {
    if (!payRunId) return

    try {
      dispatch(setPayWarningLoading({ payRunId, loading: true }))
      const { result, status, errors, message, errorData } = await apiProcessWarning(payRunId)
      if (status) {
        dispatch(replacePayWarnings({ payRunId, data: result }))
      } else {
        if (Object.keys(errors).length === 0) {
          showError(message, errorData)
        }
      }
    } finally {
      dispatch(setPayWarningLoading({ payRunId, loading: false }))
    }
  }
}
