import React, { FC } from 'react'
import { useCurrencies } from '~/features/claim/hooks'

interface CurrencyNameProps {
  code: string
}

export const CurrencyName: FC<CurrencyNameProps> = ({ code }) => {
  const [currencies] = useCurrencies()
  const currency = currencies.find(c => c.code === code)
  return <div>{`${currency?.code.toUpperCase()} - ${currency?.name}`}</div>
}
