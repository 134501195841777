import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Card, Table, Link, ColumnsType, SecondaryText, Tag, Space, Button } from '~/core-components'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { MutateEmEducationDrawer } from './MutateEmEducationDrawer'
import { useFirstInView } from '~/hooks/use-first-in-view'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { EmEducationState } from '../../../types'
import { fetchEmEducations } from '../../../actions'
import { selectEmEducations } from '../../../selectors'
import { fetchEmpKeyvalues, selectQualificationKeyvalues } from '~/features/master'
import { useEmEducationRequests } from '~/features/employee/hooks'
import { EmEducationRequestsDrawer } from './EmEducationRequest/EmEducationRequestsDrawer'

interface EmEducationsProps {
  employeeId?: string
}

type EmEducationTable = EmEducationState

interface DrawerState {
  visible: boolean
  employeeId?: string
  data?: EmEducationState
}

interface RequestDrawerState {
  employeeId?: string
  visible: boolean
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }
const DEFAULT_REQUEST_DRAWER_STATE: RequestDrawerState = { visible: false }

const cardStyle: CSSProperties = { margin: 24 }
const cardBodyStyle: CSSProperties = { padding: 0 }
const labelQualificationStyle: CSSProperties = { lineHeight: 1.1 }
const labelMajorStyle: CSSProperties = { fontSize: 12 }
const btnPendingStyle: CSSProperties = {
  backgroundColor: '#ff8500',
  borderColor: '#ff8500',
  color: '#ffffff',
  marginRight: 30
}

export const EmEducations: FC<EmEducationsProps> = ({ employeeId }: EmEducationsProps) => {
  const { ref, inView } = useFirstInView<HTMLDivElement>({ threshold: 0.25 })
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const loading = useSelector((state: StoreState) => state.employee.emEducationsLoading[employeeId || ''])
  const emEducations = useSelector(selectEmEducations)(employeeId)
  const canView = usePermissionGate(Permission.employeeEducation)
  const canModify = usePermissionGate(Permission.employeeEducation, PermissionAction.Modify)
  const qualifications = useSelector(selectQualificationKeyvalues)
  const [emEducationRequests, emEducationRequestLoading] = useEmEducationRequests(employeeId || '', inView)
  const [requestDrawerState, setRequestDrawerState] = useState<RequestDrawerState>(DEFAULT_REQUEST_DRAWER_STATE)

  useEffect(() => {
    dispatch(fetchEmpKeyvalues('qualification', undefined, undefined, { strategy: 'when-empty' }))
  }, [])

  useEffect(() => {
    if (inView && employeeId) {
      dispatch(fetchEmEducations(employeeId))
    }
  }, [inView, employeeId])

  const handleAddEducation = useCallback(() => {
    setDrawerState({ visible: true, employeeId, data: undefined })
  }, [employeeId, setDrawerState])

  const handleEditEducation = useCallback(
    (emEducation: EmEducationState) => {
      setDrawerState({ visible: true, employeeId, data: emEducation })
    },
    [employeeId, setDrawerState]
  )

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const columns: ColumnsType<EmEducationTable> = [
    {
      title: 'Qualification',
      key: 'qualificationId',
      dataIndex: 'qualificationId',
      render: (value: string, record) => (
        <>
          <div style={labelQualificationStyle}>
            <Space>
              {qualifications[value]?.value}
              {record.isHighest && <Tag type="primary">highest</Tag>}
            </Space>
          </div>
          <SecondaryText style={labelMajorStyle}>{record.major}</SecondaryText>
        </>
      )
    },
    {
      title: 'Institute',
      key: 'institute',
      dataIndex: 'institute',
      width: 180
    },
    {
      title: 'Graduation year',
      key: 'graduationYear',
      dataIndex: 'graduationYear',
      width: 120,
      render: (value: string, record) => {
        const toYear = record.endDate ? moment(record.endDate).format('YYYY') : ''
        return value || toYear
      }
    },
    {
      title: 'Notes',
      key: 'notes',
      dataIndex: 'notes',
      ellipsis: true,
      width: 175
    },
    {
      key: 'action',
      align: 'right',
      width: 80,
      render: (value: string, record) =>
        canModify && (
          <Link size="small" onClick={() => handleEditEducation(record)}>
            edit
          </Link>
        )
    }
  ]

  const handleOpenRequestDrawer = useCallback(() => {
    setRequestDrawerState({ employeeId, visible: true })
  }, [employeeId])

  const handleCloseRequestDrawer = useCallback(() => {
    setRequestDrawerState(DEFAULT_REQUEST_DRAWER_STATE)
  }, [])

  if (!canView) return null

  return (
    <div ref={ref}>
      <Card
        extra={
          employeeId &&
          canModify && (
            <Space>
              {emEducationRequests.length > 0 && (
                <Button
                  style={btnPendingStyle}
                  size="small"
                  onClick={handleOpenRequestDrawer}
                  loading={emEducationRequestLoading}
                >
                  {emEducationRequests.length > 1
                    ? `${emEducationRequests.length} pending requests`
                    : 'pending request'}
                </Button>
              )}
              <Link onClick={handleAddEducation}>add</Link>
            </Space>
          )
        }
        style={cardStyle}
        bodyStyle={cardBodyStyle}
        loading={loading}
      >
        <Table
          rowKey="id"
          dataSource={emEducations}
          pagination={false}
          columns={columns}
          loading={loading}
          className="em-education__table"
        />
        {canModify && <MutateEmEducationDrawer {...drawerState} onClose={handleCloseDrawer} />}
        {canModify && emEducationRequests.length > 0 && (
          <EmEducationRequestsDrawer {...requestDrawerState} onClose={handleCloseRequestDrawer} />
        )}
      </Card>
    </div>
  )
}
