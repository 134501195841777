import React, { FC, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { EditableCard, EditableCardState } from '~/components'
import { usePermissionGate } from '~/features/iam/hooks'
import { selectFormulaById } from '~/features/formula'
import { Permission, PermissionAction } from '~/constants'
import { PayRateType } from '~/constants'
import { dispatch } from '~/stores/store'
import { Errors, ActionResult, StoreState } from '~/types/store'
import {
  PayItemSgInfoForm,
  PayItemFormData,
  PayItemInfoFormData,
  EMPTY_PAYITEM_SG_INFO_FORM_DATA
} from './PayItemSgInfoForm'
import { mapPayItemInfoStateToPayItemInfo } from '../../../types/payitem.mapper'
import { updatePayItem, updatePayItemBases } from '../../../actions'

interface PayItemSgInfoProps {
  payItem?: PayItemFormData
  onEdit?: () => void
  onSave?: () => void
  onCancel?: () => void
}

export const PayItemSgInfo: FC<PayItemSgInfoProps> = ({ payItem, onEdit, onSave, onCancel }: PayItemSgInfoProps) => {
  const [cardState, setCardState] = useState<EditableCardState>()
  const [formData, setFormData] = useState<PayItemInfoFormData>(EMPTY_PAYITEM_SG_INFO_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const formula = useSelector((state: StoreState) => selectFormulaById(state, formData.formulaId))
  const canModify = usePermissionGate(Permission.payMaster, PermissionAction.Modify)

  useDeepCompareEffect(() => {
    if (payItem) {
      setFormData({
        name: payItem.name,
        type: payItem.type,
        category: payItem.category,
        rateType: payItem.rateType,
        prorationType: payItem.prorationType,
        rate: payItem.rate,
        formulaId: payItem.formulaId,
        wageType: payItem.wageType,
        hasSdl: payItem.hasSdl,
        ir8aCategory: payItem.ir8aCategory,
        inactiveDate: payItem.inactiveDate,
        isSys: payItem.isSys,
        notes: payItem.notes,
        payItemBasisId: payItem.payItemBasisId
      })
    } else {
      setFormData(EMPTY_PAYITEM_SG_INFO_FORM_DATA)
    }
  }, [payItem || {}])

  const handleEdit = useCallback(() => {
    setCardState('editing')
    typeof onEdit === 'function' && onEdit()
  }, [onEdit])

  const handleSave = useCallback(async () => {
    if (payItem) {
      setCardState('saving')
      setErrors(undefined)

      typeof onSave === 'function' && onSave()

      let result: ActionResult | undefined
      try {
        result = await dispatch(
          updatePayItem(
            payItem.id,
            mapPayItemInfoStateToPayItemInfo(payItem, 'sg'),
            mapPayItemInfoStateToPayItemInfo(formData, 'sg'),
            'sg'
          )
        )

        // Remove pay item basis when not daily
        const payItemBasisId = formula?.rateType === PayRateType.daily ? formData.payItemBasisId : []
        await dispatch(updatePayItemBases(payItem.id, payItemBasisId))
      } catch {
        setCardState('editing')
      }

      if (result?.errors) {
        setCardState('editing')
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setCardState(undefined)
      }
    }
  }, [payItem, formData, formula, onSave])

  const handleCancel = useCallback(() => {
    typeof onCancel === 'function' && onCancel()
    setCardState(undefined)
    setErrors(undefined)

    if (payItem) {
      setFormData({
        name: payItem.name,
        type: payItem.type,
        category: payItem.category,
        rateType: payItem.rateType,
        prorationType: payItem.prorationType,
        rate: payItem.rate,
        formulaId: payItem.formulaId,
        wageType: payItem.wageType,
        hasSdl: payItem.hasSdl,
        ir8aCategory: payItem.ir8aCategory,
        inactiveDate: payItem.inactiveDate,
        isSys: payItem.isSys,
        notes: payItem.notes,
        payItemBasisId: payItem.payItemBasisId
      })
    }
  }, [payItem, onCancel])

  return (
    <EditableCard
      title="Pay item information"
      bodyStyle={{ paddingBottom: payItem ? 6 : 24, paddingTop: 6 }}
      state={!canModify || formData.isSys ? 'readonly' : cardState}
      formId="form-payitem"
      onEdit={handleEdit}
      onSave={handleSave}
      onCancel={handleCancel}
    >
      <PayItemSgInfoForm
        data={formData}
        errors={errors}
        readOnly={cardState !== 'editing' && cardState !== 'saving'}
        onChange={data => setFormData({ ...formData, ...data })}
      />
    </EditableCard>
  )
}
