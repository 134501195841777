import { request, getBaseUrl } from '~/utils/request'
import { CalendarViewerCategory, CalendarViewerState, ICalendarViewer } from '../types'

const baseUrl = getBaseUrl('/employee/calendarviewer')

export const apiGetCalendarViewers = (category: CalendarViewerCategory) =>
  request<CalendarViewerState[]>('get', `${baseUrl}/${category}`)

export const apiGetCalendarViewer = (category: CalendarViewerCategory, id: string) =>
  request<CalendarViewerState>('get', `${baseUrl}/${category}/${id}`)

export const apiDeleteCalendarViewer = (category: CalendarViewerCategory, id: string) =>
  request<void>('delete', `${baseUrl}/${category}/${id}`)

export const apiSaveCalendarViewer = (category: CalendarViewerCategory, calendarViewer: ICalendarViewer) =>
  request<{ id: string } | undefined>('post', `${baseUrl}/${category}`, calendarViewer)
