import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { Col, Row } from '~/components'
import { Form, Input, Tooltip } from '~/core-components'
import { Errors } from '~/types/store'

interface FormData {
  company: string
}

interface BankFileDbsUffFastJsonbProps {
  bankCompanyId?: string
  valueDate: string
  batchNo: string
  bankFileJsonb: string
  onChange: (key: string, value: string) => void
  canModify: boolean
  errors?: Errors
}

const EMPTY_FORM_DATA: FormData = {
  company: ''
}

export const BankFileDbsUffFastJsonb: FC<BankFileDbsUffFastJsonbProps> = ({
  bankCompanyId,
  valueDate,
  batchNo,
  bankFileJsonb,
  onChange,
  canModify,
  errors
}) => {
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)

  useEffect(() => {
    const data = JSON.parse(bankFileJsonb || '{}') as FormData
    setFormData({
      company: data.company || bankCompanyId || ''
    })
  }, [bankFileJsonb, bankCompanyId])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      if ('batchNo' in updates) typeof onChange === 'function' && onChange('batchNo', updates.batchNo)
      else if ('valueDate' in updates) typeof onChange === 'function' && onChange('valueDate', updates.valueDate)
      else {
        setFormData(formData => {
          const updated = { ...formData, ...updates }
          const value = JSON.stringify({
            company: updated.company
          })

          typeof onChange === 'function' && onChange('bankFileJsonb', value)
          return updated
        })
      }
    },
    [onChange]
  )

  return (
    <>
      <Row>
        <Col span={24}>
          <Tooltip title={canModify ? 'Organization ID assigned by DBS' : undefined}>
            <Form.Item label="Company ID" validateStatus={errors?.company ? 'error' : ''} help={errors?.company}>
              <Input
                maxLength={12}
                value={formData.company}
                readOnly={!canModify}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleFormDataChange({ company: event.target.value })
                }
              />
            </Form.Item>
          </Tooltip>
        </Col>
        <Col span={24}>
          <Form.Item label="Value date">
            <Input.Date
              value={valueDate ? moment(valueDate) : undefined}
              inputReadOnly={!canModify}
              onChange={(value: moment.Moment | null) =>
                handleFormDataChange({ valueDate: value?.format('YYYY-MM-DD') })
              }
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Batch ID" validateStatus={errors?.batchNo ? 'error' : ''} help={errors?.batchNo}>
            <Input
              maxLength={5}
              value={batchNo}
              readOnly={!canModify}
              onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ batchNo: event.target.value })}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}
