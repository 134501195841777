import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchDailyPolicyRows } from '../actions'
import { FetchStrategy } from '~/types/common'
import { DailyPolicyRowState } from '../types'
import { selectDailyPolicyRowsBySection } from '../selectors'

export const useDailyPolicyRows = (
  dailyPolicyId: string,
  section: string,
  fetchStrategy: FetchStrategy = 'always'
): [DailyPolicyRowState[], boolean] => {
  const data = useSelector(selectDailyPolicyRowsBySection)(dailyPolicyId, section)
  const loading = useSelector((state: StoreState) => state.attendance.dailyPolicyRowsLoading[dailyPolicyId])

  useEffect(() => {
    dispatch(fetchDailyPolicyRows(dailyPolicyId, { strategy: fetchStrategy }))
  }, [dailyPolicyId, fetchStrategy])

  return [data, loading]
}
