import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { FormulaRootState, FormulaState, FormulaDetailState } from './types'

const formulaAdapter = createEntityAdapter<FormulaState>()
const formulaDetailAdapter = createEntityAdapter<FormulaDetailState>()

const formulaInitialState = formulaAdapter.getInitialState()
const formulaDetailInitialState = formulaDetailAdapter.getInitialState()

const initialState: FormulaRootState = {
  formula: formulaInitialState,
  formulaLoading: false,
  formulaDetail: formulaDetailInitialState,
  formulaDetailLoading: false
}

const formulaSlice = createSlice({
  name: 'formula',
  initialState,
  reducers: {
    setFormula: (state, action: PayloadAction<FormulaState>) => {
      formulaAdapter.upsertOne(state.formula, action.payload)
    },
    replaceFormulas: (state, action: PayloadAction<FormulaState[]>) => {
      formulaAdapter.setAll(state.formula, action.payload)
    },
    removeFormula: (state, action: PayloadAction<string>) => {
      formulaAdapter.removeOne(state.formula, action.payload)
    },
    setFormulaLoading: (state, action: PayloadAction<boolean>) => {
      state.formulaLoading = action.payload
    },
    setFormulaDetail: (state, action: PayloadAction<FormulaDetailState>) => {
      formulaDetailAdapter.upsertOne(state.formulaDetail, action.payload)
    },
    replaceFormulaDetails: (state, action: PayloadAction<FormulaDetailState[]>) => {
      formulaDetailAdapter.setAll(state.formulaDetail, action.payload)
    },
    removeFormulaDetail: (state, action: PayloadAction<string>) => {
      formulaDetailAdapter.removeOne(state.formulaDetail, action.payload)
    },
    setFormulaDetailsLoading: (state, action: PayloadAction<boolean>) => {
      state.formulaDetailLoading = action.payload
    }
  }
})

export const {
  setFormula,
  replaceFormulas,
  removeFormula,
  setFormulaLoading,
  setFormulaDetail,
  replaceFormulaDetails,
  removeFormulaDetail,
  setFormulaDetailsLoading
} = formulaSlice.actions

export const { selectById: selectFormulaById } = formulaAdapter.getSelectors(
  (state: StoreState) => state.formula.formula
)
export const { selectAll: selectFormulaDetails } = formulaDetailAdapter.getSelectors(
  (state: StoreState) => state.formula.formulaDetail
)

export const formulaReducers = {
  formula: formulaSlice.reducer
}
