import React, { FC } from 'react'
import { Redirect, Route, RouteProps, Switch } from 'react-router'
import { CLA_PATH, CLA_ROUTES } from '~/routes/routes'
import { ClaimOverview } from './ClaimOverview/ClaimOverview'
import { ClaimEntitlement } from './ClaimEntitlement/ClaimEntitlement'
import './ClaimLayout.less'

const routeList: RouteProps[] = [
  {
    path: CLA_ROUTES.entitlement,
    component: ClaimEntitlement,
    exact: true
  },
  {
    path: CLA_ROUTES.tab,
    component: ClaimOverview,
    exact: true
  }
]

export const ClaimLayout: FC = () => {
  return (
    <div className="claim-layout">
      <Switch>
        <Redirect exact from={CLA_PATH} to={CLA_ROUTES.tab.replace(':tab?', 'entitlement')} />
        {routeList.map(route => (
          <Route key={route.path as string} {...route} />
        ))}
      </Switch>
    </div>
  )
}
