import { replaceHolidayDates, setHolidayDatesLoading } from '../reducers'
import { apiGetHolidayDates } from '../api/holiday.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchHolidayDates = (
  holidayId: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!holidayId) return

    if (options.strategy === 'when-empty') {
      const data = getState().master.holidayDates?.entities || {}
      if (Object.values(data).some(hd => hd?.holidayId === holidayId)) {
        return
      }
    }

    try {
      dispatch(setHolidayDatesLoading({ holidayId: holidayId, loading: true }))
      const { result, status } = await apiGetHolidayDates(holidayId)
      if (status) {
        dispatch(replaceHolidayDates({ holidayId: holidayId, data: result }))
      }
    } finally {
      dispatch(setHolidayDatesLoading({ holidayId: holidayId, loading: false }))
    }
  }
}
