import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MicroAppRootState, ManifestState, AppMethods } from './types'

const initialState: MicroAppRootState = {
  loading: false,
  error: undefined,
  apps: {},
  manifests: {}
}

const microAppSlice = createSlice({
  name: 'micro-app',
  initialState,
  reducers: {
    registerApp: (state, action: PayloadAction<{ name: string; app: AppMethods }>) => {
      state.apps[action.payload.name] = action.payload.app
    },
    setManifest: (state, action: PayloadAction<ManifestState>) => {
      state.manifests = action.payload
    },
    setManifestLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
      state.manifests = {}
    },
    setManifestError: (state, action: PayloadAction<string>) => {
      state.error = action.payload
      state.manifests = {}
    },
    setScriptLoaded: (state, action: PayloadAction<string>) => {
      state.manifests[action.payload].loaded = true
    }
  }
})

export const { registerApp, setManifest, setManifestLoading, setManifestError, setScriptLoaded } = microAppSlice.actions

export const microAppReducer = microAppSlice.reducer

export const microAppReducers = {
  microApp: microAppReducer
}
