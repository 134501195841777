import { showSuccess, showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { emptyGuid } from '~/constants'
import { apiAddJob } from '../api/job.api'
import { IJob } from '../types'

export const addJob = (job: IJob): ThunkResult<void> => {
  return async (): Promise<ActionResult> => {
    const { status, result, errors, message, errorData } = await apiAddJob({ ...job, id: emptyGuid })
    if (status) {
      showSuccess('Added')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
