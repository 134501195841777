import { replaceEmManagerSecondaries, setEmManagerSecondariesLoading } from '../reducers'
import { apiGetEmManagerSecondaries } from '../api/em-manager-secondary.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchEmManagerSecondaries = (
  employeeId: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!employeeId) return

    const loading = getState().employee.emManagerSecondariesLoading[employeeId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().employee.emManagerSecondaries[employeeId]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setEmManagerSecondariesLoading({ employeeId, loading: true }))
      const { result, status } = await apiGetEmManagerSecondaries(employeeId)
      if (status) {
        dispatch(replaceEmManagerSecondaries({ employeeId, data: result }))
      }
    } finally {
      dispatch(setEmManagerSecondariesLoading({ employeeId, loading: false }))
    }
  }
}
