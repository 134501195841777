import { setPayRunXero, setPayRunXeroLoading } from '../reducers'
import { apiGetPayRunXero } from '../api/payrun-xero.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchPayRunXero = (payRunId: string, options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!payRunId) return

    const loading = getState().payroll.payRunXeroLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().payroll.payRunXero.entities[payRunId]
      if (hasData) return
    }

    try {
      dispatch(setPayRunXeroLoading(true))
      const { result, status } = await apiGetPayRunXero(payRunId)
      if (status) {
        dispatch(setPayRunXero(result))
      }
    } finally {
      dispatch(setPayRunXeroLoading(false))
    }
  }
}
