import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { PayEncashMapState, PayItemState } from '../types'
import { PayItemCategory, PayItemType } from '~/constants'
import { isInactive } from '~/utils'

export const selectLeaveEncashPayItems = createSelector(
  (state: StoreState) => state.master.payItems,
  (state: StoreState) => state.master.payEncashMaps,
  (payItemsState, payEncashMapsState) =>
    memoize(
      (payGroupId: string, payitemType: PayItemType) => {
        if (!payGroupId || !payitemType) return []

        const payEncashMaps = Object.values(payEncashMapsState[payGroupId]?.entities || {}) as PayEncashMapState[]
        const payItems = Object.values(payItemsState?.entities || {}) as PayItemState[]
        return payItems
          .filter(
            pi =>
              (!isInactive(pi?.inactiveDate) ||
                payEncashMaps.filter(pem => pem.payItemId === pi?.id || pem.negativePayItemId === pi?.id).length > 0) &&
              pi?.category === PayItemCategory.leave &&
              pi?.type === payitemType
          )
          .sort((a, b) => a?.name.localeCompare(b?.name || '') || 0)
      },
      (payGroupId: string, payitemType: PayItemType) => `${payGroupId}|${payitemType}`
    )
)
