import React, { FC } from 'react'
import { Card, Form } from '~/core-components'
import { OedSummaryState } from '../../../../types'
import './OedOrganization.less'

interface OedOrganizationProps {
  oedSummary: OedSummaryState | undefined
}

export const OedOrganization: FC<OedOrganizationProps> = ({ oedSummary }) => {
  return (
    <Card className="oed-organization" title="Organisation's information">
      <Form layout="vertical">
        <Form.Item label="Organisation no.">{oedSummary?.companyUen}</Form.Item>
        <Form.Item label="Organisation name">{oedSummary?.companyName}</Form.Item>
      </Form>
    </Card>
  )
}
