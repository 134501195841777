import { showError, showSuccess, showWarning } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { apiSubmitIras } from '../api/ytd.api'
import {
  replaceA8aErrors,
  replaceA8aWarnings,
  replaceA8bErrors,
  replaceA8bWarnings,
  replaceIr8aErrors,
  replaceIr8aWarnings,
  replaceIr8sErrors,
  replaceIr8sWarnings
} from '../reducers'

export const submitIras = (
  ytdSubmissionId: string,
  signatureDate: string,
  validateOnly: boolean,
  bypass: boolean
): ThunkResult<void> => {
  return async (dispatch): Promise<ActionResult | undefined> => {
    const { status, result, errors, message, errorData } = await apiSubmitIras(
      ytdSubmissionId,
      signatureDate,
      validateOnly,
      bypass
    )

    if (status) {
      dispatch(replaceIr8aErrors(result.ir8aErrors))
      dispatch(replaceIr8aWarnings(result.ir8aWarnings))
      dispatch(replaceIr8sErrors(result.ir8sErrors))
      dispatch(replaceIr8sWarnings(result.ir8sWarnings))
      dispatch(replaceA8aErrors(result.a8aErrors))
      dispatch(replaceA8aWarnings(result.a8aWarnings))
      dispatch(replaceA8bErrors(result.a8bErrors))
      dispatch(replaceA8bWarnings(result.a8bWarnings))

      if (result.statusCode === '200') {
        showSuccess('Submission is successful!')
      } else {
        if (
          result.ir8aErrors.length > 0 ||
          result.ir8aWarnings.length > 0 ||
          result.ir8sErrors.length > 0 ||
          result.ir8sWarnings.length > 0 ||
          result.a8aErrors.length > 0 ||
          result.a8aWarnings.length > 0 ||
          result.a8bErrors.length > 0 ||
          result.a8bWarnings.length > 0
        ) {
          showWarning('Submission failed. Please check the errors and/or warnings.')
        } else {
          showWarning(`Submission failed${result.errorMessage ? `: ${result.errorMessage}` : ''}`)
        }
      }
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
