import React, { FC, useMemo } from 'react'
import { Upload, UploadProps } from '../../core-components'

interface UploadAuthProps extends UploadProps {}

export const UploadAuth: FC<UploadAuthProps> = ({ action, fileList, ...props }: UploadAuthProps) => {
  const getUploadProps = useMemo((): UploadProps => {
    const token = localStorage.getItem('access_token')
    const tenant = localStorage.getItem('tenant') || ''

    return {
      action,
      headers: {
        Authorization: `Bearer ${token}`,
        'X-Tenant': tenant
      },
      fileList: fileList?.map(file => ({ ...file, url: file.url })),
      ...props
    }
  }, [action, fileList, props])

  return <Upload {...getUploadProps} />
}
