import { ILeaveGrant, LeaveGrantState } from './leave-grant.state'

export const mapLeaveGrantToLeaveGrantState = (dto: ILeaveGrant): LeaveGrantState => {
  // const dataJsonb = JSON.parse(dto.dataJsonb || '{}')

  return {
    id: dto.id,
    employeeId: dto.employeeId,
    leaveTypeId: dto.leaveTypeId,
    startDate: dto.startDate,
    endDate: dto.endDate,
    leaveRecordId: dto.leaveRecordId,
    earned: dto.earned,
    taken: dto.taken,
    pending: dto.pending,
    balance: dto.balance,
    notes: dto.notes
  }
}
