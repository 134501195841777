import React, { CSSProperties, FC } from 'react'
import { Skeleton } from '~/core-components'
import { Col, InfoTooltip, Row } from '~/components'
import { ECharts, EChartsOption } from '..'
import merge from 'lodash/merge'
import './BarChart.less'

interface BarChartProps {
  title: string
  tooltip?: string
  option: EChartsOption
  loading?: boolean
  style?: CSSProperties
}

const defaultOption: EChartsOption = {
  tooltip: {
    trigger: 'axis'
  },
  grid: {
    left: 50,
    right: 16,
    top: 24,
    bottom: 24
  },
  xAxis: {
    type: 'category',
    axisLine: {
      lineStyle: { color: '#ddd' }
    },
    axisTick: {
      length: 0
    },
    axisLabel: {
      color: '#616161',
      fontFamily: "'Mulish', 'SegoeUI', -apple-system"
    }
  },
  yAxis: {
    nameLocation: 'middle',
    nameRotate: 90,
    nameGap: 36,
    nameTextStyle: { fontSize: 11, fontFamily: "'Mulish', 'SegoeUI', -apple-system", color: '#aeaeae' },
    type: 'value'
  }
}

const skeletonStyle: CSSProperties = { marginTop: 20 }

export const BarChart: FC<BarChartProps> = ({ title, tooltip, option, loading, style = { height: 150 } }) => {
  const chartOption = merge({}, defaultOption, option)

  return (
    <div className="bar-chart">
      <Row>
        <Col flex="auto" className="bar-chart__title">
          {title}
        </Col>
        <Col flex="none">{tooltip && <InfoTooltip title={tooltip} />}</Col>
      </Row>
      {loading ? (
        <div style={skeletonStyle}>
          <Skeleton active title={false} />
        </div>
      ) : (
        <Row>
          <Col span={24}>
            <ECharts option={chartOption} style={style} />
          </Col>
        </Row>
      )}
    </div>
  )
}
