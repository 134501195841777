import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiCancelMyClaimRecord } from '../api/ss-claim-apply.api'
import { refetchMyClaimBalances, refetchMyClaimRecordsView } from '../reducers'

export const cancelMyClaim = (id: string): ThunkResult<void> => {
  return async dispatch => {
    if (!id) return

    const { status, result, errors, message, errorData } = await apiCancelMyClaimRecord(id)

    if (status) {
      dispatch(refetchMyClaimBalances())
      dispatch(refetchMyClaimRecordsView())
      showSuccess('Sent')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
