import React, { FC } from 'react'
import { Layout } from 'antd'
import { Branding } from '~/components/Branding/Branding'
import { TenantSwitcher } from '~/features/iam/containers/TenantSwitcher/TenantSwitcher'
import { FeedbackButton } from '~/features/feedback/containers/FeedbackButton'
import { AdminProfileLink } from '../AdminSidebar/AdminProfileLink/AdminProfileLink'
import './AdminHeader.less'

const { Header: AntHeader } = Layout

interface AdminHeaderProps {}

export const AdminHeader: FC<AdminHeaderProps> = () => {
  return (
    <AntHeader className="admin-header">
      <div className="admin-header__left">
        <Branding.Logo />
        <div className="admin-header__divider" />
        <TenantSwitcher />
      </div>
      <div className="admin-header__actions">
        <FeedbackButton />
        <AdminProfileLink />
      </div>
    </AntHeader>
  )
}
