import React, { CSSProperties, FC } from 'react'
import { useSelector } from 'react-redux'
import { RenderedCell } from 'rc-table/lib/interface'
import moment from 'moment-timezone'
import { LoadingOutlined } from '@ant-design/icons'
import { Alert, ColumnsType, Spin, Table } from '~/core-components'
import { StoreState } from '~/types/store'
import { EmployeeImportRowState } from '../../../../../types'
import { formatMoney } from '~/utils'
import './ImportEmployee.less'

interface ImportEmployeeProps {
  visible: boolean
  success?: boolean
  importCode: string
}

const spinStyles: CSSProperties = { marginRight: 10 }

export const ImportEmployee: FC<ImportEmployeeProps> = ({ visible, success = false, importCode }) => {
  const excelColumns = useSelector((state: StoreState) => state.employee.employeeImport?.columns || [])
  const data = useSelector((state: StoreState) => state.employee.employeeImport?.validation.data || [])
  const processing = useSelector((state: StoreState) => state.employee.refreshEmployeeLoading)

  const columns: ColumnsType<EmployeeImportRowState> = []

  excelColumns.forEach(col => {
    columns.push({
      title: col.displayName,
      key: col.indexName,
      dataIndex: col.indexName,
      render: (value: string | number, record: EmployeeImportRowState, index: number) => {
        var display = value
        if (col.dataType === 'timestamp' && value) {
          display = moment(value).format('DD MMM YYYY')
        }
        if (col.dataType === 'numeric' && value) {
          display = formatMoney(value as number)
        }

        const obj: RenderedCell<EmployeeImportRowState> = {
          children: display,
          props: {}
        }

        return obj
      }
    })
  })

  columns.push({
    title: 'Status',
    key: 'isInvalid',
    dataIndex: 'isInvalid',
    render: (value: boolean) => value && 'Invalid'
  })

  const tableWidth = excelColumns.length * 200 + 150

  return (
    <div className="import-employee" hidden={!visible}>
      {success && (
        <Alert
          type="success"
          showIcon
          message={
            <>
              <div>Import successful</div>
              {processing ? (
                <div>
                  <Spin style={spinStyles} size="small" indicator={<LoadingOutlined spin />} />
                  <span>You may close this while we're importing the employee data</span>
                </div>
              ) : (
                <div>All the employee has been successfully imported</div>
              )}
            </>
          }
        />
      )}
      <Table
        className="import-employee__table"
        rowKey="id"
        dataSource={data}
        fitParent
        scroll={{ x: tableWidth, y: 1000 }}
        columns={columns}
        rowClassName={(record: EmployeeImportRowState) => (record.isInvalid ? 'import-employee__row--invalid' : '')}
      />
    </div>
  )
}
