import React, { FC, useCallback, useMemo } from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Space, Tag, Tooltip } from '~/core-components'
import { StoreState } from '~/types/store'
import { TenantSwitcherDrawer } from './TenantSwitcherDrawer'
import { useMyTenants } from '../../hooks'
import './TenantSwitcher.less'

interface TenantSwitcherProps {}

const activeTenantCode = localStorage.getItem('tenant')

interface DrawerState {
  visible: boolean
}
const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

export const TenantSwitcher: FC<TenantSwitcherProps> = () => {
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const [myTenants] = useMyTenants()
  const activeTenant = useMemo(() => myTenants.find(t => t.tenantCode === activeTenantCode), [myTenants])
  const myJwt = useSelector((state: StoreState) => state.iam.myJwt)
  const isMultipleRoles = (myJwt?.isAdmin?.length || 0) > 0 && (myJwt?.isEmployee?.length || 0) > 0

  const handleClick = useCallback(() => {
    setDrawerState({ visible: true })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  if ((myTenants?.length || 0) === 0) {
    return null
  }

  if ((myTenants?.length || 0) === 1 && !isMultipleRoles) {
    return (
      <Space className="tenant-switcher">
        <label>Workspace:</label>
        {activeTenant?.tenantName || 'Tenant'}
        <Tooltip title="Workspace code">
          <Tag type="original">{activeTenant?.workspaceCode}</Tag>
        </Tooltip>
      </Space>
    )
  }

  return (
    <div className="tenant-switcher">
      <Space>
        <label>Workspace:</label>
        <Button type="text" onClick={handleClick} size="small">
          <Space>
            <i className="fal fa-grid" />
            {activeTenant?.tenantName || 'Tenant'}
          </Space>
        </Button>
        <Tooltip title="Workspace code">
          <Tag type="original">{activeTenant?.workspaceCode}</Tag>
        </Tooltip>
      </Space>
      <TenantSwitcherDrawer {...drawerState} onClose={handleCloseDrawer} />
    </div>
  )
}
