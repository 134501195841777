import React, { FC, useEffect, useMemo, useState } from 'react'
import { ColumnsType, Table, TableRowSelection, Tooltip } from '~/core-components'
import { ErrorDisplay } from '~/components'
import { Errors } from '~/types/store'
import { useSysDailyFieldsDict } from '~/features/attendance'
import { snakeToCamel } from '~/utils'
import { DailyImportSourceState, PayRunState } from '../../../../types'
import { usePayDailyMaps } from '../../../../hooks'
import { ImportDailyFormData } from '../ImportDailyDrawer'
import './SelectDailyRecord.less'

interface SelectDailyRecordProps {
  visible: boolean
  payRun?: PayRunState
  data: Partial<ImportDailyFormData>
  errors?: Errors
  onSelect: (data: Partial<ImportDailyFormData>) => void
}

export const SelectDailyRecord: FC<SelectDailyRecordProps> = ({ visible, payRun, data, errors, onSelect }) => {
  const [selected, setSelected] = useState<DailyImportSourceState[]>([])
  const [payDailyMaps] = usePayDailyMaps(payRun?.payGroupId)
  const [sysDailyFields] = useSysDailyFieldsDict()

  useEffect(() => {
    if (data.selected) {
      setSelected(data.selected)
    }
  }, [data.selected])

  const columns: ColumnsType<DailyImportSourceState> = useMemo(() => {
    const columns: ColumnsType<DailyImportSourceState> = [
      {
        title: 'Employee',
        key: 'employeeNo',
        dataIndex: 'employeeNo',
        render: (value: string, record) => `[${value}] ${record.employeeName}`
      }
    ]

    if (payDailyMaps.length > 0) {
      for (const map of payDailyMaps) {
        columns.push({
          title: sysDailyFields[map.dailyField]?.name,
          key: map.dailyField,
          dataIndex: snakeToCamel(map.dailyField),
          align: 'right'
        })
      }
    }

    columns.push({
      key: 'isInvalid',
      dataIndex: 'isInvalid',
      width: 30,
      render: (value: boolean, record) =>
        value &&
        !!record.reason && (
          <Tooltip title={record.reason} placement="topRight">
            <i className="fal fa-triangle-exclamation" />
          </Tooltip>
        )
    })

    return columns
  }, [payDailyMaps, sysDailyFields])

  const rowSelection: TableRowSelection<DailyImportSourceState> = useMemo(
    () => ({
      type: 'checkbox',
      selectedRowKeys: selected.map(d => d.employeeId),
      hideSelectAll: false,
      onChange: (_selectedRowKeys, rows) => {
        typeof onSelect === 'function' && onSelect({ selected: rows })
        setSelected(rows)
      },
      getCheckboxProps: (record: DailyImportSourceState) => ({
        disabled: record.isInvalid
      })
    }),
    [selected, onSelect]
  )

  return (
    <div className="select-daily-record" hidden={!visible}>
      <ErrorDisplay errors={errors} />
      <Table
        rowSelection={rowSelection}
        rowKey="employeeId"
        dataSource={data.source}
        columns={columns}
        pagination={false}
      />
    </div>
  )
}
