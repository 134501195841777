import { replaceSysActionTypes, setSysActionTypesLoading } from '../reducers'
import { apiGetActionTypes } from '../api/sys-workflow.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchSysWfActionTypes = (
  flowType: string,
  eventType: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!flowType || !eventType) return

    const flowEventType = `${flowType}||${eventType}`
    const loading = getState().workflow.sysWfActionTypesLoading[flowEventType]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = (getState().workflow.sysWfActionTypes[flowEventType]?.ids?.length || 0) > 0
      if (hasData) return
    }

    try {
      dispatch(setSysActionTypesLoading({ flowEventType, loading: true }))
      const { result, status } = await apiGetActionTypes(flowType, eventType)
      if (status) {
        dispatch(replaceSysActionTypes({ flowEventType, data: result }))
      }
    } finally {
      dispatch(setSysActionTypesLoading({ flowEventType, loading: false }))
    }
  }
}
