import { setA8aForm, setA8aFormUpdating, setIr8aFormRefetch } from '../reducers'
import { apiUpdateA8aDraft } from '../api/ytd.api'
import { ThunkResult } from '~/types/store'
import { showSuccess, showError } from '~/utils'
import { A8aFormState, IYtdUpdateA8aDraft } from '../types'

export const updateA8aDraft = (request: A8aFormState): ThunkResult<void> => {
  return async dispatch => {
    const updateData = mapToDto(request)

    try {
      dispatch(setA8aFormUpdating(true))
      const { status, errors, message, errorData } = await apiUpdateA8aDraft(updateData)
      if (status) {
        dispatch(setA8aForm(request))
        dispatch(setIr8aFormRefetch())
        showSuccess('Saved')
      } else {
        if (Object.keys(errors).length === 0) {
          showError(message, errorData)
        }
      }
      return { errors }
    } finally {
      dispatch(setA8aFormUpdating(false))
    }
  }
}

const mapToDto = (a8aForm: A8aFormState): IYtdUpdateA8aDraft => {
  const updateForm: IYtdUpdateA8aDraft = {
    id: a8aForm.id,
    ytdSubmissionId: a8aForm.ytdSubmissionId,
    employeeTaxNo: a8aForm.employeeTaxNo,
    addressLine1: a8aForm.addressLine1,
    addressLine2: a8aForm.addressLine2,
    addressLine3: a8aForm.addressLine3,
    occupationStartDate: a8aForm.occupationStartDate,
    occupationEndDate: a8aForm.occupationEndDate,
    occupationDays: a8aForm.occupationDays,
    noOfEmSharing: a8aForm.noOfEmSharing,
    premisesAmt: a8aForm.premisesAmt,
    furnitureAmt: a8aForm.furnitureAmt,
    premiseType: a8aForm.premiseType,
    rentAmt: a8aForm.rentAmt,
    rentEmAmt: a8aForm.rentEmAmt,
    utilitiesAmt: a8aForm.utilitiesAmt,
    driverAmt: a8aForm.driverAmt,
    servantAmt: a8aForm.servantAmt,
    hotelAmt: a8aForm.hotelAmt,
    hotelEmAmt: a8aForm.hotelEmAmt,
    leavePassageAdj: a8aForm.leavePassageAdj,
    interestAdj: a8aForm.interestAdj,
    insuranceAdj: a8aForm.insuranceAdj,
    holidayAdj: a8aForm.holidayAdj,
    educationAdj: a8aForm.educationAdj,
    clubAdj: a8aForm.clubAdj,
    gainAdj: a8aForm.gainAdj,
    vehicleAdj: a8aForm.vehicleAdj,
    carAdj: a8aForm.carAdj,
    otherAdj: a8aForm.otherAdj,
    bikTotal: a8aForm.bikTotal
  }

  return updateForm
}
