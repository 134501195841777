import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchDailyRecordEmployees } from '../actions'
import { FetchStrategy } from '~/types/common'
import { EmSelectState } from '~/components'

export const useDailyRecordEmployees = (
  clockDate?: string,
  fetchStrategy: FetchStrategy = 'always'
): [EmSelectState[], boolean] => {
  const data = useSelector(
    (state: StoreState) => Object.values(state.attendance.dailyRecordEmployees?.entities) as EmSelectState[]
  )
  const loading = useSelector((state: StoreState) => state.attendance.dailyRecordEmployeesLoading)

  useEffect(() => {
    dispatch(fetchDailyRecordEmployees(clockDate, { strategy: fetchStrategy }))
  }, [clockDate, fetchStrategy])

  return [data, loading]
}
