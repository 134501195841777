import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { ROLeaveEntitlementViewState } from '../../types'

export const selectROLeaveEntitlementsView = createSelector(
  (state: StoreState) => state.my.roLeaveEntitlementsView,
  roLeaveEntitlementsState =>
    memoize((viewId: string): ROLeaveEntitlementViewState => {
      const roLeaveEntitlements = roLeaveEntitlementsState.entities[viewId] || ({} as ROLeaveEntitlementViewState)
      return roLeaveEntitlements
    })
)
