import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'

export interface MyLogin {
  id?: string
  name?: string
  email?: string
  isOwner: boolean
  isSuperUser: boolean
  isAdmin: boolean
  isEmployee: boolean
  timeZoneId?: string
  photoId?: string
}

const tenant = localStorage.getItem('tenant')

export const selectMyLogin = createSelector(
  (state: StoreState) => state.iam.logins,
  (state: StoreState) => state.iam.myJwt,
  (loginsState, myJwtState): MyLogin => {
    const loginId = myJwtState?.loginId
    if (!loginId) {
      return {
        id: undefined,
        name: undefined,
        email: undefined,
        isOwner: false,
        isSuperUser: false,
        isAdmin: false,
        isEmployee: false,
        timeZoneId: '',
        photoId: ''
      }
    }

    const login = loginsState.entities[loginId]
    return {
      id: login?.id,
      name: login?.loginName,
      email: login?.email,
      isOwner: myJwtState?.isOwner?.includes(tenant || '') || false,
      isSuperUser: myJwtState?.isSuperUser?.includes(tenant || '') || false,
      isAdmin: myJwtState?.isAdmin?.includes(tenant || '') || false,
      isEmployee: myJwtState?.isEmployee?.includes(tenant || '') || false,
      timeZoneId: login?.timeZoneId,
      photoId: login?.photoId
    }
  }
)
