import { ThunkResult } from '~/types/store'
import { showSuccess, showError } from '~/utils'
import { apiDeleteEmOffice } from '../api/em-office.api'
import { removeEmOffice, setEmployee } from '../reducers'

export const deleteEmOffice = (employeeId: string, id: string): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!employeeId || !id) return

    const { result, status, errors, message, errorData } = await apiDeleteEmOffice(employeeId, id)
    if (status) {
      if (result.emOfficeId) {
        const employee = getState().employee.employees.entities[employeeId]
        if (employee) {
          dispatch(setEmployee({ ...employee, emOfficeId: result.emOfficeId }))
        }
      }

      dispatch(removeEmOffice({ employeeId, id }))
      showSuccess('Deleted')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
