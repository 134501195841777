import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Descriptions } from '~/core-components'
import { fetchEmpKeyvalues, selectKeyvaluesById, selectRaceKeyvalues, selectReligionKeyvalues } from '~/features/master'
import { dispatch } from '~/stores/store'
import { EmployeeState } from '../../../../../types'
import { useSysOptions } from '../../../../../hooks'

interface CopyEmPersonalProps {
  employee?: EmployeeState
}

export const CopyEmPersonal: FC<CopyEmPersonalProps> = ({ employee }) => {
  const [genders] = useSysOptions('gender')
  const [maritalStatuses] = useSysOptions('marital_status')
  const races = useSelector(selectRaceKeyvalues)
  const religions = useSelector(selectReligionKeyvalues)
  const nationalities = useSelector(selectKeyvaluesById)('nationality')

  useEffect(() => {
    dispatch(fetchEmpKeyvalues('race', undefined, undefined, { strategy: 'when-empty' }))
    dispatch(fetchEmpKeyvalues('religion', undefined, undefined, { strategy: 'when-empty' }))
    dispatch(fetchEmpKeyvalues('nationality', undefined, undefined, { strategy: 'when-empty' }))
  }, [])

  return (
    <Descriptions bordered column={1} className="copy-em__content">
      <Descriptions.Item label="Date of birth">
        {employee?.birthDate ? moment(employee?.birthDate).format('DD MMM YYYY') : ''}
      </Descriptions.Item>
      <Descriptions.Item label="Gender">{genders[employee?.gender || '']?.value}</Descriptions.Item>
      <Descriptions.Item label="Marital status">
        {maritalStatuses[employee?.maritalStatus || '']?.value}
      </Descriptions.Item>
      <Descriptions.Item label="Marriage date">
        {employee?.marriageDate ? moment(employee?.marriageDate).format('DD MMM YYYY') : ''}
      </Descriptions.Item>
      <Descriptions.Item label="Race">{races[employee?.raceId || '']?.value}</Descriptions.Item>
      <Descriptions.Item label="Religion">{religions[employee?.religionId || '']?.value}</Descriptions.Item>
      <Descriptions.Item label="Nationality">{nationalities[employee?.nationalityId || '']?.value}</Descriptions.Item>
      <Descriptions.Item label="Birth place">{employee?.birthPlace}</Descriptions.Item>
      <Descriptions.Item label="Personal email">{employee?.personalEmail}</Descriptions.Item>
      <Descriptions.Item label="Personal phone">{employee?.personalPhone}</Descriptions.Item>
      <Descriptions.Item label="Bio">{employee?.bio}</Descriptions.Item>
    </Descriptions>
  )
}
