import { request, getBaseUrl } from '~/utils/request'
import { EmIdentityRequestState, IEmIdentityRequestApproval } from '../types'

const baseUrl = getBaseUrl('/employee/employee/request/identity')

export const apiGetEmIdentityRequests = (employeeId: string) =>
  request<EmIdentityRequestState[]>('get', `${baseUrl}/${employeeId}`)

export const apiApproveEmIdentityRequest = (id: string, emIdentityRequestApproval: IEmIdentityRequestApproval) =>
  request('post', `${baseUrl}/${id}/approve`, emIdentityRequestApproval)

export const apiRejectEmIdentityRequest = (id: string) => request('post', `${baseUrl}/${id}/reject`)
