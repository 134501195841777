import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { IPayCfConfig, PayCfConfigState } from '../types'
import { apiUpdatePayCfConfig } from '../api/pay-cf-config.api'
import { setPayCfConfig } from '../reducers'
import { clearSysSelectionFields, refetchSysSelectionFields } from '~/features/selection'

export const updatePayCfConfig = (code: string, before: IPayCfConfig, request: IPayCfConfig): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!code) return

    const after = { ...before, ...request } as IPayCfConfig
    const patch = generateJsonPatch<IPayCfConfig>(before, after)
    const { status, errors, message, errorData } = await apiUpdatePayCfConfig(code, patch)

    if (status) {
      const payCfConfig = getState().payroll.payCfConfigs?.entities[code]
      const updated: PayCfConfigState = { ...(payCfConfig as PayCfConfigState), ...after }
      dispatch(setPayCfConfig(updated))
      dispatch(clearSysSelectionFields())
      dispatch(refetchSysSelectionFields())
      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
