import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchMyTimeLogApprovalHistories } from '../actions'
import { selectMyTimeLogApprovalHistories } from '../selectors'
import { SSTimeLogApprovalHistoryState } from '../types'

export const useMyTimeLogApprovalHistories = (timeLogId?: string): [SSTimeLogApprovalHistoryState[], boolean] => {
  const approvalHistories = useSelector(selectMyTimeLogApprovalHistories)(
    timeLogId || ''
  ) as SSTimeLogApprovalHistoryState[]
  const loading = useSelector(
    (state: StoreState) => state.myAttendance.myTimeLogApprovalHistoriesLoading[timeLogId || '']
  )

  useEffect(() => {
    if (timeLogId) {
      dispatch(fetchMyTimeLogApprovalHistories(timeLogId))
    }
  }, [timeLogId])

  return [approvalHistories, loading]
}
