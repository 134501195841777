import React, { CSSProperties, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { ColumnsType, Drawer, SecondaryText, Space, Table, TablePaginationConfig, Tag } from '~/core-components'
import { EmPreviewState } from './EmPreview'
import { PersonAvatar } from '../Person'
import { emptyGuid } from '~/constants'
import { getBaseUrl } from '~/utils'
import './EmPreviewDrawer.less'

export interface EmPreviewDrawerProps {
  visible: boolean
  title?: string
  onFetch: () => Promise<EmPreviewState[]>
  onClose: () => void
}

const secondaryTextStyle: CSSProperties = { fontSize: 12 }
const tableScroll = { y: 1000 }
const baseUrl = getBaseUrl('/filestore')
const PAGE_SIZE_OPTIONS = ['20', '50', '100']

export const EmPreviewDrawer: FC<EmPreviewDrawerProps> = ({ visible, title = 'List employees', onFetch, onClose }) => {
  const [employees, setEmployees] = useState<EmPreviewState[]>()
  const [fetching, setFetching] = React.useState(false)
  const fetchRef = React.useRef(0)
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(20)

  const fetcher = useCallback(async () => {
    fetchRef.current += 1
    const fetchId = fetchRef.current
    setEmployees([])

    try {
      setFetching(true)
      const result = await onFetch()

      if (fetchId === fetchRef.current) {
        setEmployees(result)
      }
    } finally {
      if (fetchId === fetchRef.current) setFetching(false)
    }
  }, [onFetch])

  useEffect(() => {
    if (visible) fetcher()
  }, [visible, fetcher])

  const columns: ColumnsType<EmPreviewState> = useMemo(
    () => [
      {
        title: 'Employees',
        key: 'name',
        dataIndex: 'name',
        render: (value: string, record) => (
          <>
            <div className="em-preview-drawer__person">
              <PersonAvatar
                photo={
                  record.photoId && record.photoId !== emptyGuid
                    ? `${baseUrl}/file/${record.photoId}/thumbnailphoto/24`
                    : undefined
                }
                loading={fetching}
              />
              <div className="em-selection__person__info">
                <h1>{record.name}</h1>
                <Space>
                  {record.description && <SecondaryText style={secondaryTextStyle}>{record.description}</SecondaryText>}
                  {record.isResignee && <Tag type="neutral">resignee</Tag>}
                </Space>
              </div>
            </div>
          </>
        )
      }
    ],
    [fetching]
  )

  const handleCloseDrawer = useCallback(() => {
    typeof onClose === 'function' && onClose()
  }, [onClose])

  const handlePaginationChange = useCallback((page: number, pageSize?: number) => {
    setPage(page)
    setPageSize(pageSize || 20)
  }, [])

  const pagination = useMemo(
    (): TablePaginationConfig => ({
      total: employees?.length || 0,
      current: page,
      pageSize,
      pageSizeOptions: PAGE_SIZE_OPTIONS,
      showSizeChanger: true,
      position: ['bottomLeft'],
      onChange: handlePaginationChange
    }),
    [employees, handlePaginationChange, page, pageSize]
  )

  return (
    <Drawer open={visible} title={title} onClose={handleCloseDrawer} width={400} className="em-preview-drawer">
      <Table
        rowKey="id"
        dataSource={employees}
        columns={columns}
        loading={fetching}
        scroll={tableScroll}
        pagination={pagination}
        fitParent
      />
    </Drawer>
  )
}
