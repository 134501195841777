import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import cronstrue from 'cronstrue'
import { Tab, Tabs } from '~/core-components'
import { DailyCron } from './components/DailyCron'
import { DailyRemindCron } from './components/DailyRemindCron'
import { HourlyCron } from './components/HourlyCron'
import { MinutesCron } from './components/MinutesCron'
import { MonthlyCron } from './components/MonthlyCron'
import { WeeklyCron } from './components/WeeklyCron'
import { YearlyCron } from './components/YearlyCron'
import { InfoTooltip } from '../InfoTooltip/InfoTooltip'

interface CronEditorProps {
  value?: string
  onChange?: (value: string) => void
  filterTabs?: string[]
}

type TabChoice = 'minutes' | 'hourly' | 'daily' | 'daily_remind' | 'weekly' | 'monthly' | 'yearly'
const DEFAULT_TAB: TabChoice = 'daily'

export const CronEditor: FC<CronEditorProps> = ({ value, onChange, filterTabs }) => {
  const [expr, setExpr] = useState<string[]>(['0/1', '*', '*', '*', '*'])
  const [reset, setReset] = useState(0)
  const defaultTab: TabChoice = useMemo(() => (filterTabs ? (filterTabs[0] as TabChoice) : DEFAULT_TAB), [filterTabs])
  const [tab, setTab] = useState<TabChoice>(defaultTab)

  const isMinutesShow = useMemo(() => !filterTabs || filterTabs.includes('minutes'), [filterTabs])
  const isHourlyShow = useMemo(() => !filterTabs || filterTabs.includes('hourly'), [filterTabs])
  const isDailyShow = useMemo(() => !filterTabs || filterTabs.includes('daily'), [filterTabs])
  const isDailyRemindShow = useMemo(() => !filterTabs || filterTabs.includes('daily_remind'), [filterTabs])
  const isWeeklyShow = useMemo(() => !filterTabs || filterTabs.includes('weekly'), [filterTabs])
  const isMonthlyShow = useMemo(() => !filterTabs || filterTabs.includes('monthly'), [filterTabs])
  const isYearlyShow = useMemo(() => !filterTabs || filterTabs.includes('yearly'), [filterTabs])

  useEffect(() => {
    const newExpr = (value || '0/1 * * * *').split(' ')
    setExpr(newExpr)

    // detect active tab
    if (newExpr[0].includes('/')) {
      setTab('minutes')
    } else if (newExpr[1].includes('/')) {
      setTab('hourly')
    } else if (newExpr[2].includes('/')) {
      setTab('daily')
    } else if (newExpr[2] === '*' && newExpr[3] === '*' && newExpr[4] === '*') {
      setTab('daily_remind')
    } else if (newExpr[4] !== '*') {
      setTab('weekly')
    } else if (newExpr[3].includes('/')) {
      setTab('monthly')
    } else if (!newExpr[2].includes('/') && !newExpr[3].includes('/')) {
      setTab('yearly')
    }
  }, [value])

  const handleChange = useCallback(
    (value: string[]) => {
      setExpr(value)
      typeof onChange === 'function' && onChange(value.join(' '))
    },
    [onChange]
  )

  const handleTabChange = useCallback(
    (newTab: string) => {
      setTab(newTab as TabChoice)

      if (tab !== newTab) {
        let expr = ['0/1', '*', '*', '*', '*']
        if (newTab === 'hourly') {
          expr = ['0', '0/1', '*', '*', '*']
        } else if (newTab === 'daily') {
          expr = ['0', '0', '1/1', '*', '*']
        } else if (newTab === 'daily_remind') {
          expr = ['0', '0', '*', '*', '*']
        } else if (newTab === 'weekly') {
          expr = ['0', '0', '*', '*', '0']
        } else if (newTab === 'monthly') {
          expr = ['0', '0', '1', '*/1', '*']
        } else if (newTab === 'yearly') {
          expr = ['0', '0', '1', '1', '*']
        }

        handleChange(expr)
        setReset(reset => reset + 1)
      }
    },
    [handleChange, tab]
  )

  useEffect(() => {
    handleTabChange(defaultTab)
  }, [defaultTab])

  const getDisplay = useCallback(() => {
    const exprValue = expr.join(' ')
    const display = cronstrue.toString(exprValue, { throwExceptionOnParseError: false })
    if (display.search('undefined') === -1) {
      return display
    }
    return '-'
  }, [expr])

  const tabItems = useMemo(() => {
    const result: Tab[] = []

    if (isMinutesShow) {
      result.push({ key: 'minutes', label: 'Minutes', children: <MinutesCron expr={expr} onChange={handleChange} /> })
    }

    if (isHourlyShow) {
      result.push({ key: 'hourly', label: 'Hourly', children: <HourlyCron expr={expr} onChange={handleChange} /> })
    }

    if (isDailyShow) {
      result.push({
        key: 'daily',
        label: 'Daily',
        children: <DailyCron expr={expr} onChange={handleChange} reset={reset} />
      })
    }

    if (isDailyRemindShow) {
      result.push({
        key: 'daily_remind',
        label: 'Daily',
        children: <DailyRemindCron expr={expr} onChange={handleChange} />
      })
    }

    if (isWeeklyShow) {
      result.push({ key: 'weekly', label: 'Weekly', children: <WeeklyCron expr={expr} onChange={handleChange} /> })
    }

    if (isMonthlyShow) {
      result.push({ key: 'monthly', label: 'Monthly', children: <MonthlyCron expr={expr} onChange={handleChange} /> })
    }

    if (isYearlyShow) {
      result.push({ key: 'yearly', label: 'Yearly', children: <YearlyCron expr={expr} onChange={handleChange} /> })
    }

    return result
  }, [
    isMinutesShow,
    isHourlyShow,
    isDailyShow,
    isDailyRemindShow,
    isWeeklyShow,
    isMonthlyShow,
    isYearlyShow,
    expr,
    handleChange,
    reset
  ])

  return (
    <Tabs
      onChange={handleTabChange}
      activeKey={tab}
      tabBarExtraContent={<InfoTooltip title={getDisplay()} placement="topRight" />}
      items={tabItems}
    />
  )
}
