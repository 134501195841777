import React, { FC } from 'react'
import { default as AntCollapse, CollapseProps as AntCollapseProps } from 'antd/lib/collapse'
import classNames from 'classnames'
import 'antd/es/collapse/style'
import './Collapse.less'

export interface CollapseProps extends AntCollapseProps {
  noStyle?: boolean
}

export const Collapse: FC<CollapseProps> = ({ noStyle, className, ...props }) => {
  const classes = classNames('collapse', { [`${className}`]: className }, { 'collapse--no-style': noStyle })
  return <AntCollapse ghost className={classes} {...props} />
}
