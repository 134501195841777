import React, { FC, useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Card, ColumnsType, PageHeader, Space, Table } from '~/core-components'
import { DocumentTitle, InactiveTag } from '~/components'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { isInactive } from '~/utils/dateUtil'
import { ordinalSuffix } from '~/utils/ordinalSuffix'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { AttendanceGroupState } from '../../types'
import { useAttendanceGroups } from '../../hooks'
import { AddAttendanceGroupDrawer } from '../AttendanceGroup/components/AddAttendanceGroupDrawer'
import { AttendanceGroupTypeTag } from '../AttendanceGroup/components/AttendanceGroupTypeTag'
import './AttendanceGroups.less'

type AttendanceGroupTable = AttendanceGroupState

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: '',
    breadcrumbName: 'Overview'
  }
]

interface DrawerState {
  visible: boolean
}
const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

interface AttendanceGroupProps {}

export const AttendanceGroups: FC<AttendanceGroupProps> = () => {
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const [attendanceGroups, loading] = useAttendanceGroups()
  const canModify = usePermissionGate(Permission.attendanceGroup, PermissionAction.Modify)

  const handleAddAttendanceGroup = useCallback(() => {
    setDrawerState({ visible: true })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const columns: ColumnsType<AttendanceGroupTable> = [
    {
      title: 'Attendance group',
      key: 'name',
      dataIndex: 'name',
      render: (value: string, record) => (
        <Space>
          <Link to={SETTINGS_ROUTES.attendanceGroup.replace(':id', record.id).replace(':tab?', 'general')}>
            {value}
          </Link>
          {isInactive(record.inactiveDate) && <InactiveTag />}
        </Space>
      )
    },
    {
      title: 'Type',
      key: ' type',
      dataIndex: 'type',
      render: (value: string) => <AttendanceGroupTypeTag code={value} />
    },
    {
      title: 'Period',
      key: 'otStartDay',
      dataIndex: 'otStartDay',
      render: (value: number, record) => {
        if (value === 0) {
          return null
        }

        const start = ordinalSuffix(record.otStartDay)
        const end =
          record.otStartDay === 1 ? 'end of the month' : `${ordinalSuffix(record.otStartDay - 1)} of next month`
        return `${start} to ${end}`
      }
    }
  ]

  return (
    <div id="attendance-groups" className="attendance-groups">
      <DocumentTitle title="Attendance Groups" />
      <PageHeader
        title="Attendance groups"
        containerId="attendance-groups"
        breadcrumb={{ routes }}
        extra={
          canModify && (
            <Button key="add" onClick={handleAddAttendanceGroup}>
              Add attendance group
            </Button>
          )
        }
      />
      <div className="attendance-groups__body">
        <Card table>
          <Table rowKey="id" dataSource={attendanceGroups} pagination={false} columns={columns} loading={loading} />
        </Card>
        {canModify && <AddAttendanceGroupDrawer {...drawerState} onClose={handleCloseDrawer} />}
      </div>
    </div>
  )
}
