import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment-timezone'
import { RangeValue } from 'rc-picker/lib/interface.d'
import { Col, DocumentTitle, PdfViewer, Row, SysOptions } from '~/components'
import { Button, Collapse, Form, Input, PageHeader, Select, Space } from '~/core-components'
import { GroupingButton, RptScreen } from '~/features/grouping'
import { emptyGuid, ReportFilter, ReportType, RptEmStatus } from '~/constants'
import { REPORTS_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { Errors } from '~/types/store'
import { downloadWithDom, getFileTimestamp, showError } from '~/utils'
import { IRptLveRecord, ReportCode } from '../../types'
import { useFirstReportTemplate, useReportTemplate } from '../../hooks'
import { refetchReportTemplates } from '../../reducers'
import { useLeaveTypesDict } from '~/features/leave'
import { apiDownloadLveRecordExcel, apiDownloadLveRecordPdf } from '../../api/rpt-lve-record.api'
import { RptDownloadBtn } from '../components/RptDownloadBtn'
import { ReportTemplate } from '../ReportTemplate/ReportTemplate'
import { RptSortingBtn } from '../components/RptSortingBtn'
import { usePayRuns } from '~/features/payroll'
import './RptLveRecord.less'
import { Screen, useGetViewIdByName, ViewCriteriaButton } from '~/features/selection'

const routes = [
  {
    path: REPORTS_ROUTES.main,
    breadcrumbName: 'Reports'
  },
  {
    path: '',
    breadcrumbName: 'Leave records report'
  }
]

interface RptLveRecordForm extends IRptLveRecord {
  templateId: string
}

const currentYear = moment().year()

const SCREEN_CODE: Screen = 'rpt_lve_record'
const RPT_SCREEN_CODE: RptScreen = 'rpt_leave'
const REPORT_CODE: ReportCode = 'rpt_lve_record'

const EMPTY_FORM_DATA: RptLveRecordForm = {
  reportType: ReportType.summary,
  reportFilter: ReportFilter.leaveDate,
  startDate: `${currentYear}-01-01`,
  endDate: `${currentYear}-12-31`,
  payRunIds: [],
  leaveTypeIds: [],
  approvalStatuses: [],
  sorting: 'employee_name',
  groupingCodes: [],
  pageBreaks: [],
  emStatus: RptEmStatus.all,
  viewId: '',
  reportTitle: '',
  notes: '',
  templateId: emptyGuid
}

const reportTypes = [
  { key: ReportType.summary, label: 'Summary' },
  { key: ReportType.detailed, label: 'Detailed' }
]

const reportFilters = [
  { key: ReportFilter.leaveDate, label: 'Leave date' },
  { key: ReportFilter.payRun, label: 'Payroll runs' }
]

export const RptLveRecord: FC = () => {
  const [formData, setFormData] = useState<RptLveRecordForm>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const [payRuns] = usePayRuns()
  const [leaveTypes] = useLeaveTypesDict()

  const [previewUrl, setPreviewUrl] = useState<string>('')
  const [previewing, setPreviewing] = useState(false)
  const [activeKey, setActiveKey] = useState(0)

  const [firstTemplate] = useFirstReportTemplate('leave', REPORT_CODE)
  const firstTemplateId = firstTemplate?.id || ''
  const [templateId, setTemplateId] = useState<string>()
  const [template] = useReportTemplate('leave', REPORT_CODE, templateId)

  const [viewId] = useGetViewIdByName(SCREEN_CODE, templateId ? `${SCREEN_CODE}_${templateId}` : undefined)

  const sortedLeaveTypes = useMemo(() => {
    return Object.values(leaveTypes).sort((a, b) => a!.name.localeCompare(b!.name || '') || 0)
  }, [leaveTypes])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setErrors(undefined)
    setFormData(data => ({ ...data, ...updates }))
  }, [])

  useEffect(() => {
    if (!templateId && firstTemplateId) {
      setTemplateId(firstTemplateId)
    }

    if (template) {
      const saved = JSON.parse(template.dataJsonb) as RptLveRecordForm
      saved.templateId = templateId || ''
      saved.emStatus = saved.emStatus || RptEmStatus.all
      saved.viewId = viewId || ''
      setFormData(formData => ({ ...formData, ...saved }))
    }
  }, [firstTemplateId, templateId, template, viewId])

  const handlePreview = useCallback(async () => {
    setPreviewing(true)
    setErrors(undefined)

    try {
      const { status, result, errors, message, errorData } = await apiDownloadLveRecordPdf(formData)

      if (status) {
        const url = URL.createObjectURL(result)
        setPreviewUrl(url)
        dispatch(refetchReportTemplates())
      } else {
        console.error('Error while downloading', errors)
        setErrors(errors)
        showError(message, errorData)
      }
    } finally {
      setPreviewing(false)
    }
  }, [formData])

  const handleDownloadPdf = useCallback(async () => {
    setErrors(undefined)

    const { status, result, errors, message, errorData } = await apiDownloadLveRecordPdf(formData)

    if (status) {
      const fileName = `leave_records_report_${getFileTimestamp()}.pdf`
      downloadWithDom(result, fileName)
      dispatch(refetchReportTemplates())
    } else {
      console.error('Error while downloading', errors)
      setErrors(errors)
      showError(message, errorData)
    }
  }, [formData])

  const handleDownloadExcel = useCallback(async () => {
    const { status, result, errors, message, errorData } = await apiDownloadLveRecordExcel(formData)

    if (status) {
      const fileName = `leave_records_report_${getFileTimestamp()}.xlsx`
      downloadWithDom(result, fileName)
      dispatch(refetchReportTemplates())
    } else {
      console.error('Error while downloading', errors)
      setErrors(errors)
      showError(message, errorData)
    }
  }, [formData])

  const handleCollapse = useCallback(async () => {
    if (!activeKey) {
      setActiveKey(1)
    } else {
      setActiveKey(0)
    }
  }, [activeKey])

  const handleTemplateChange = useCallback((templateId?: string) => {
    setTemplateId(templateId)
  }, [])

  return (
    <div id="rpt-lve-record" className="rpt-lve-record">
      <DocumentTitle title="Leave Records Report" />
      <PageHeader
        title="Leave records report"
        containerId="rpt-lve-record"
        breadcrumb={{ routes }}
        extra={
          <ReportTemplate<IRptLveRecord>
            basePath="leave"
            reportCode={REPORT_CODE}
            templateId={templateId}
            templateData={formData}
            size="small"
            onChange={handleTemplateChange}
          />
        }
      />
      <Form className="rpt-lve-record__form" layout="horizontal" labelCol={{ flex: '130px' }}>
        <Row gutter={30}>
          <Col flex="300px">
            <Form.Item label="Report type">
              <Select
                value={formData.reportType}
                allowClear={false}
                onChange={(reportType: ReportType) => handleFormDataChange({ reportType })}
              >
                {reportTypes.map(rt => (
                  <Select.Option key={rt.key} value={rt.key} title={rt.label}>
                    {rt.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col flex="1"></Col>
          <Col flex="none">
            <Space>
              <RptSortingBtn
                sorting={formData.sorting}
                onSelect={(sorting: string) => handleFormDataChange({ sorting })}
              />
              <ViewCriteriaButton screenCode={SCREEN_CODE} viewId={formData.viewId} />
              <GroupingButton
                screenCode={RPT_SCREEN_CODE}
                groupingCodes={formData.groupingCodes || []}
                pageBreaks={formData.pageBreaks || []}
                onSelect={(groupingCodes: string[], pageBreaks: string[]) =>
                  handleFormDataChange({ groupingCodes, pageBreaks })
                }
              />
              <Button className="btn-more-settings" type={activeKey ? 'primary' : 'default'} onClick={handleCollapse}>
                <i className="fal fa-gear" />
              </Button>
              <Button type="primary" onClick={handlePreview} loading={previewing}>
                Preview
              </Button>
              <RptDownloadBtn onDownloadPdf={handleDownloadPdf} onDownloadExcel={handleDownloadExcel} />
            </Space>
          </Col>
        </Row>
        <Row gutter={30} hidden={formData.reportType === ReportType.summary}>
          <Col flex="300px">
            <Form.Item label="Report filter">
              <Select
                value={formData.reportFilter}
                allowClear={false}
                onChange={(reportFilter: ReportType) => handleFormDataChange({ reportFilter })}
              >
                {reportFilters.map(rt => (
                  <Select.Option key={rt.key} value={rt.key} title={rt.label}>
                    {rt.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row
          gutter={30}
          hidden={formData.reportType !== ReportType.summary && formData.reportFilter !== ReportFilter.leaveDate}
        >
          <Col flex="1">
            <Form.Item
              label="Leave dates"
              validateStatus={errors?.startDate || errors?.endDate ? 'error' : ''}
              help={errors?.startDate || errors?.endDate}
            >
              <Input.DateRange
                allowClear={false}
                value={[
                  formData.startDate ? moment(formData.startDate) : null,
                  formData.endDate ? moment(formData.endDate) : null
                ]}
                onCalendarChange={(dates: RangeValue<moment.Moment>) => {
                  const startDate = dates && dates[0] ? dates[0].format('YYYY-MM-DD') : ''
                  const endDate = dates && dates[1] ? dates[1].format('YYYY-MM-DD') : ''
                  handleFormDataChange({ startDate, endDate })
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row
          gutter={30}
          hidden={formData.reportType === ReportType.summary || formData.reportFilter !== ReportFilter.payRun}
        >
          <Col flex="1">
            <Form.Item label="Payroll runs" validateStatus={errors?.payRunIds ? 'error' : ''} help={errors?.payRunIds}>
              <Select
                mode="multiple"
                maxTagCount={2}
                value={formData.payRunIds || []}
                placeholder="Select payroll runs"
                optionFilterProp="title"
                onChange={(payRunIds: string[]) => handleFormDataChange({ payRunIds })}
              >
                {payRuns.map(pr => (
                  <Select.Option key={pr.id} value={pr.id} title={pr.description}>
                    {pr.description}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col flex="1">
            <Form.Item
              label="Leave types"
              validateStatus={errors?.leaveTypeIds ? 'error' : ''}
              help={errors?.leaveTypeIds}
            >
              <Select
                mode="multiple"
                maxTagCount={3}
                value={formData.leaveTypeIds || []}
                placeholder="All leave types"
                optionFilterProp="title"
                onChange={(value: string[]) => handleFormDataChange({ leaveTypeIds: value })}
              >
                {sortedLeaveTypes.map(lt => (
                  <Select.Option key={lt?.id} value={lt?.id || ''} title={lt?.name}>
                    {lt?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col flex="1">
            <Form.Item
              label="Approval status"
              validateStatus={errors?.approvalStatuses ? 'error' : ''}
              help={errors?.approvalStatuses}
            >
              <SysOptions
                type="lve_approval_status"
                mode="multiple"
                placeholder="All status"
                value={formData.approvalStatuses || []}
                onChange={(approvalStatuses: string[]) => handleFormDataChange({ approvalStatuses })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col flex="1">
            <Collapse className="rpt-lve-record__more-settings" activeKey={activeKey} onChange={handleCollapse} noStyle>
              <Collapse.Panel key="1" header={null} showArrow={false}>
                <Row gutter={30}>
                  <Col flex="490px">
                    <Form.Item
                      label="Filter by employee"
                      validateStatus={errors?.emStatus ? 'error' : ''}
                      help={errors?.emStatus}
                    >
                      <SysOptions
                        allowClear={false}
                        type="rpt_em_status"
                        placeholder="All employees"
                        value={formData.emStatus}
                        onChange={(emStatus?: string) => handleFormDataChange({ emStatus })}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={30}>
                  <Col flex="490px">
                    <Form.Item label="Report title">
                      <Input
                        value={formData.reportTitle}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                          handleFormDataChange({ reportTitle: event.target.value })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={30}>
                  <Col flex="1">
                    <Form.Item label="Custom notes">
                      <Input.TextArea
                        rows={3}
                        value={formData.notes}
                        onChange={(value?: ChangeEvent<HTMLTextAreaElement>) =>
                          handleFormDataChange({ notes: value?.target.value })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Collapse.Panel>
            </Collapse>
          </Col>
        </Row>
      </Form>
      {previewUrl && (
        <div className="rpt-lve-record__pdf-viewer">
          <Row>
            <Col>
              <PdfViewer file={previewUrl} layout="landscape" scale={1.3} />
            </Col>
          </Row>
        </div>
      )}
    </div>
  )
}
