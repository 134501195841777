import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { DEFAULT_HOOK_OPTIONS, HookOptions } from '~/types/common'
import { fetchMyClaimBalances } from '../actions'
import { selectMyEntitledClaimTypes } from '../selectors'
import { SSEntitledClaimType } from '../types'

export const useMyEntitledClaimTypes = (
  options: HookOptions = DEFAULT_HOOK_OPTIONS
): [SSEntitledClaimType[], boolean] => {
  const entitledClaimTypes = useSelector(selectMyEntitledClaimTypes)
  const loading = useSelector((state: StoreState) => state.myClaim.myClaimBalancesLoading)

  useEffect(() => {
    if (options.enabled) dispatch(fetchMyClaimBalances())
  }, [options.enabled])

  return [entitledClaimTypes, loading]
}
