import React, { FC, useCallback, useState } from 'react'
import { Modal, ModalProps } from './Modal'
import { Button } from '../Button'

interface ModalTristateProps extends ModalProps {
  visible: boolean
  yesText?: string
  noText?: string
  onYes?: () => Promise<void>
  onNo?: () => Promise<void>
  onCancel: () => void
}

export const ModalTristate: FC<ModalTristateProps> = ({
  visible,
  yesText = 'Yes',
  noText = 'No',
  onYes,
  onNo,
  onCancel,
  ...props
}) => {
  const [loading, setLoading] = useState(false)
  const [loading2, setLoading2] = useState(false)

  const handleYesClick = useCallback(async () => {
    try {
      setLoading(true)
      typeof onYes === 'function' && (await onYes())
    } finally {
      setLoading(false)
    }
  }, [onYes])

  const handleNoClick = useCallback(async () => {
    try {
      setLoading2(true)
      typeof onNo === 'function' && (await onNo())
    } finally {
      setLoading2(false)
    }
  }, [onNo])

  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      footer={
        <div className="modal__footer">
          <Button loading={loading2} disabled={loading} onClick={handleNoClick}>
            {noText}
          </Button>
          <Button type="primary" loading={loading} disabled={loading2} onClick={handleYesClick}>
            {yesText}
          </Button>
        </div>
      }
      {...props}
    />
  )
}
