import React, { FC } from 'react'
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom'
import { Employee } from './Employee/Employee'
import { EmOverview } from './EmOverview/EmOverview'
import { EMP_PATH, EMP_ROUTES } from '~/routes/routes'
import './EmployeeLayout.less'

const routeList: RouteProps[] = [
  {
    path: EMP_ROUTES.employee,
    component: Employee,
    exact: true
  },
  {
    path: EMP_ROUTES.tab,
    component: EmOverview,
    exact: true
  }
]

export const EmployeeLayout: FC = () => (
  <div className="employee-layout">
    <Switch>
      <Redirect exact from={EMP_PATH} to={EMP_ROUTES.tab.replace(':tab?', 'dashboard')} />
      {routeList.map(route => (
        <Route key={route.path as string} {...route} />
      ))}
    </Switch>
  </div>
)
