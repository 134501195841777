import { request, getBaseUrl } from '~/utils/request'
import { CtProrationMState, ICtProrationMMutate } from '../types'

const baseUrl = getBaseUrl('/claim/claimtype')

export const apiGetCtProrationM = (claimTypeId: string) =>
  request<CtProrationMState[]>('get', `${baseUrl}/${claimTypeId}/ctprorationm`)

export const apiUpdateCtProrationM = (claimTypeId: string, ctProrationM: ICtProrationMMutate) =>
  request<CtProrationMState[]>('post', `${baseUrl}/${claimTypeId}/ctprorationm/`, ctProrationM)
