import { replaceEmFieldValues, setEmFieldValuesLoading } from '../reducers'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'
import { apiGetEmEducationField } from '../api/em-education.api'

export const fetchEmEducationField = (
  field: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().employee.emFieldValuesLoading[field]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().employee.emFieldValues[field]?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setEmFieldValuesLoading({ field, loading: true }))
      const { result, status } = await apiGetEmEducationField(field)
      if (status) {
        dispatch(replaceEmFieldValues({ field, data: result }))
      }
    } finally {
      dispatch(setEmFieldValuesLoading({ field, loading: false }))
    }
  }
}
