import React, { FC } from 'react'
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom'
import { TAX_PATH, TAX_ROUTES } from '~/routes/routes'
import { IrasCallback } from './Iras/IrasCallback'
import { YtdForms } from './YtdForm/YtdForms'
import { Taxation } from './Taxation/Taxation'
import { YtdSubmission } from './YtdSubmission/YtdSubmission'
import { Ir21Submission } from './Ir21Submission/Ir21Submission'
import { Ir21Forms } from './Ir21Form/Ir21Forms'
import './TaxLayout.less'

const routeList: RouteProps[] = [
  {
    path: TAX_ROUTES.formsSubmission,
    component: YtdForms,
    exact: true
  },
  {
    path: TAX_ROUTES.ir21FormsSubmission,
    component: Ir21Forms,
    exact: true
  },
  {
    path: TAX_ROUTES.submission,
    component: YtdSubmission,
    exact: true
  },
  {
    path: TAX_ROUTES.ir21Submission,
    component: Ir21Submission,
    exact: true
  },
  {
    path: TAX_ROUTES.irasCallback,
    component: IrasCallback,
    exact: true
  },
  {
    path: TAX_ROUTES.tab,
    component: Taxation,
    exact: true
  }
]

export const TaxLayout: FC = () => {
  return (
    <div className="tax-layout">
      <Switch>
        <Redirect exact from={TAX_PATH} to={TAX_ROUTES.tab.replace(':tab?', 'submission')} />
        {routeList.map(route => (
          <Route key={route.path as string} {...route} />
        ))}
      </Switch>
    </div>
  )
}
