import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Descriptions } from '~/core-components'
import { fetchKeyvalues } from '~/features/master'
import { dispatch } from '~/stores/store'
import { selectCurrentEmShiftRole } from '../../../../../selectors'
import { StoreState } from '~/types/store'

interface CopyEmShiftRoleProps {
  employeeId?: string
  emShiftRoleId?: string
}

export const CopyEmShiftRole: FC<CopyEmShiftRoleProps> = ({ employeeId, emShiftRoleId }) => {
  const shiftRoles = useSelector((state: StoreState) => state.master.keyvalues['shiftRole']?.entities)
  const emShiftRole = useSelector(selectCurrentEmShiftRole)(employeeId, emShiftRoleId)

  useEffect(() => {
    dispatch(fetchKeyvalues('shiftRole', 'attendance', undefined, { strategy: 'when-empty' }))
  }, [])

  return (
    <Descriptions bordered column={1} className="copy-em__content">
      <Descriptions.Item label="Shift role">
        {shiftRoles && shiftRoles[emShiftRole?.shiftRoleId || '']?.value}
      </Descriptions.Item>
    </Descriptions>
  )
}
