import { replacePayItemBases, setPayItemBasesLoading } from '../reducers'
import { apiGetPayItemBases } from '../api/payitem-basis.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'
import { mapPayItemBasisToPayItemBasisState } from '../types/payitem.mapper'

export const fetchPayItemBases = (
  payItemId: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().master.payItemBasesLoading[payItemId]
    if (loading) return
   
    if (options.strategy === 'when-empty') {
      const hasData = getState().master.payItemBases[payItemId]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setPayItemBasesLoading({ id: payItemId, loading: true }))
      const { result, status } = await apiGetPayItemBases(payItemId)
      if (status) {
        const payItems = result.map(dto => mapPayItemBasisToPayItemBasisState(dto))
        dispatch(replacePayItemBases({ id: payItemId, data: payItems }))
      }
    } finally {
      dispatch(setPayItemBasesLoading({ id: payItemId, loading: false }))
    }
  }
}
