import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { ColumnsType, Form, Link, LinkGroup, Space, Table, Tag } from '~/core-components'
import { Col, EmpKeyValues, InactiveTag, Row } from '~/components'
import { selectSectionKeyvalues, fetchEmpKeyvalues } from '~/features/master'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { useToggle } from '~/hooks/use-toggle'
import { useFirstInView } from '~/hooks/use-first-in-view'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { isInactive } from '~/utils'
import { MutateEmSectionDrawer } from './MutateEmSectionDrawer'
import { IEmSectionInfo, EmSectionState } from '../../../types'
import { fetchEmSections } from '../../../actions'
import { selectCurrentEmSection, selectEmSections } from '../../../selectors'

interface EmSectionsProps {
  employeeId?: string
  id?: string
}

type EmSectionTable = EmSectionState

const EMPTY_FORM_DATA: IEmSectionInfo = {
  startDate: '',
  sectionId: ''
}

interface DrawerState {
  visible: boolean
  employeeId?: string
  data?: EmSectionState
  isCurrent: boolean
}

const DEFAULT_MODAL_STATE: DrawerState = { visible: false, isCurrent: false }

const formStyle: CSSProperties = { padding: '0 24px' }
const linkGroupStyle: CSSProperties = { position: 'absolute', top: 0, right: 0 }

export const EmSections: FC<EmSectionsProps> = ({ employeeId, id }: EmSectionsProps) => {
  const { ref, inView } = useFirstInView<HTMLDivElement>({ threshold: 0.25 })
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_MODAL_STATE)
  const [formData, setFormData] = useState<IEmSectionInfo>(EMPTY_FORM_DATA)
  const current = useSelector(selectCurrentEmSection)(employeeId, id)
  const histories = useSelector(selectEmSections)(employeeId)
  const sections = useSelector(selectSectionKeyvalues)
  const [showHistory, toggleShowHistory] = useToggle()
  const canModify = usePermissionGate(Permission.employeeCareer, PermissionAction.Modify)
  const loading = useSelector((state: StoreState) => state.employee.emSectionsLoading[employeeId || ''])

  useEffect(() => {
    if (inView) {
      dispatch(fetchEmpKeyvalues('section', undefined, undefined, { strategy: 'when-empty' }))
    }
  }, [inView])

  useEffect(() => {
    if (inView) {
      if (employeeId) {
        dispatch(fetchEmSections(employeeId))
      }
    }
  }, [inView, employeeId])

  useEffect(() => {
    if (showHistory && histories.length === 0) {
      toggleShowHistory()
    }
  }, [histories.length, showHistory, toggleShowHistory])

  useEffect(() => {
    if (current) {
      const { startDate, sectionId } = current
      setFormData({ startDate, sectionId })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [current])

  const handleEditSection = useCallback(
    (emSection?: EmSectionState) => {
      setDrawerState({ visible: true, employeeId, data: emSection, isCurrent: id === emSection?.id })
    },
    [employeeId, id, setDrawerState]
  )

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_MODAL_STATE)
  }, [])

  const columns: ColumnsType<EmSectionTable> = [
    {
      title: 'Effective date',
      key: 'startDate',
      dataIndex: 'startDate',
      width: 130,
      render: (value: string) => value && moment(value).format('DD MMM YYYY')
    },
    {
      title: 'Section',
      key: 'sectionId',
      dataIndex: 'sectionId',
      render: (value: string, record) =>
        value && (
          <Space>
            {sections[value]?.value}
            {record.id === id && <Tag type="primary">current</Tag>}
            {isInactive(sections[value]?.inactiveDate) && <InactiveTag />}
          </Space>
        )
    },
    {
      key: 'action',
      align: 'right',
      render: (value: string, record) =>
        canModify && (
          <Link size="small" onClick={() => handleEditSection(record)}>
            edit
          </Link>
        )
    }
  ]

  return (
    <div ref={ref}>
      <Form style={formStyle}>
        <Row hidden={showHistory}>
          <Col span={24}>
            <Form.Item
              className="form-item-label--block"
              label={
                <>
                  <span>Section</span>
                  {!loading && (
                    <LinkGroup style={linkGroupStyle}>
                      {canModify && (
                        <Link size="small" onClick={() => handleEditSection(current)}>
                          {current ? 'edit' : 'add'}
                        </Link>
                      )}
                      {(histories?.length || 0) > 0 && (
                        <Link size="small" onClick={toggleShowHistory}>
                          {showHistory ? 'hide history' : 'history'}
                        </Link>
                      )}
                    </LinkGroup>
                  )}
                </>
              }
            >
              <EmpKeyValues id="section" value={formData.sectionId} readOnly />
            </Form.Item>
          </Col>
        </Row>
        {showHistory && (
          <Row>
            <Col span={24}>
              <Form.Item
                className="form-item-label--block"
                label={
                  <>
                    <span>Section</span>
                    <LinkGroup style={linkGroupStyle}>
                      <Link size="small" onClick={toggleShowHistory}>
                        {showHistory ? 'hide history' : 'history'}
                      </Link>
                    </LinkGroup>
                  </>
                }
              >
                <Table rowKey="id" dataSource={histories} pagination={false} columns={columns} />
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
      {canModify && <MutateEmSectionDrawer {...drawerState} onClose={handleCloseDrawer} />}
    </div>
  )
}
