import { ThunkResult } from '~/types/store'
import { showSuccess, showError } from '~/utils'
import { apiDeleteEmStatutoryExempt } from '../api/em-statutory-exempt.api'
import { removeEmStatutoryExempt } from '../reducers'

export const deleteEmStatutoryExempt = (employeeId: string, id: string): ThunkResult<void> => {
  return async dispatch => {
    if (!employeeId || !id) return

    const { status, errors, message, errorData } = await apiDeleteEmStatutoryExempt(employeeId, id)
    if (status) {
      dispatch(removeEmStatutoryExempt({ employeeId, id }))
      showSuccess('Deleted')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
