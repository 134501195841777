import React, { FC } from 'react'
import { Form } from '~/core-components'
import { Col, Row } from '~/components'
import { LveApprovalStatus } from '~/constants'

interface LeaveCurrentApproverProps {
  statuses: string[]
  approvers: string[]
  hidden: boolean
}

export const LeaveCurrentApprover: FC<LeaveCurrentApproverProps> = ({ statuses, approvers, hidden }) => {
  return (
    <>
      {[LveApprovalStatus.pending, LveApprovalStatus.pendingCancel].some(s => statuses.includes(s)) &&
        approvers.length > 0 && (
          <Row hidden={hidden}>
            <Col span={24}>
              <Form.Item label="Pending for approval by">
                <div style={{ whiteSpace: 'pre-line' }}>{approvers.join('\n')}</div>
              </Form.Item>
            </Col>
          </Row>
        )}
    </>
  )
}
