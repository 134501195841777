import { Skeleton as InternalSkeleton } from './Skeleton'
import { SkeletonInput } from './SkeletonInput'

type TSkeleton = typeof InternalSkeleton
interface ISkeleton extends TSkeleton {
  Input: typeof SkeletonInput
}

const Skeleton = InternalSkeleton as ISkeleton
Skeleton.Input = SkeletonInput

export { Skeleton }
