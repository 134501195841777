import { showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiUnlockIr21Draft } from '../api/ir21.api'
import { Ir21FormState } from '../types'
import { setIr21Form, setIr21SubmissionIr21View } from '../reducers'

export const unlockIr21Draft = (id: string): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const { status, errors, message, errorData } = await apiUnlockIr21Draft(id)
    if (status) {
      const unlock = { lockedBy: '', lockedDate: '' }
      const ir21Forms = Object.values(getState().tax.ir21Form?.entities || {}) as Ir21FormState[]
      const ir21Form = ir21Forms.find(a => a.id === id)
      const data: Ir21FormState = { ...(ir21Form as Ir21FormState), ...unlock }
      dispatch(setIr21Form(data))

      const ir21Views = getState().tax.ir21SubmissionIr21Views
      ir21Views?.ids.forEach(viewId => {
        const ir21View = ir21Views?.entities[viewId]
        const found = ir21View?.data.find(r => r.id === id)
        if (found && ir21View) {
          const updates = ir21View.data.map(d => (d.id === id ? { ...d, ...unlock } : d))
          dispatch(setIr21SubmissionIr21View({ ...ir21View, data: updates }))
        }
      })
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
