import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { TeConfigState } from '../types'
import { FetchStrategy } from '~/types/common'
import { selectTeConfigs } from '../selectors'
import { fetchTeConfigs } from '../actions'

export const useTeConfigs = (fetchStrategy: FetchStrategy = 'always'): [TeConfigState[], boolean] => {
  const data = useSelector(selectTeConfigs)
  const loading = useSelector((state: StoreState) => state.attendance.otConfigsLoading)

  useEffect(() => {
    dispatch(fetchTeConfigs({ strategy: fetchStrategy }))
  }, [fetchStrategy])

  return [data, loading]
}
