import { replaceSysDailyPolicyExpressions, setSysDailyPolicyExpressionsLoading } from '../reducers'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'
import { apiGetSysDailyPolicyExpressions } from '../api/sys-daily-policy-expression.api'

export const fetchSysDailyPolicyExpressions = (options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().attendance.sysDailyPolicyExpressionsLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().attendance.sysDailyPolicyExpressions?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setSysDailyPolicyExpressionsLoading(true))
      const { result, status } = await apiGetSysDailyPolicyExpressions()
      if (status) {
        dispatch(replaceSysDailyPolicyExpressions(result))
      }
    } finally {
      dispatch(setSysDailyPolicyExpressionsLoading(false))
    }
  }
}
