import React, { CSSProperties, FC } from 'react'
import { Space, Tag } from '~/core-components'
import { useSysOptions } from '~/features/employee'
import { OedSubmissionStatus } from '~/constants'
import { OedSubmissionState } from '~/features/report/types'

interface OedTagsProps {
  oedSubmission?: OedSubmissionState
}

const lowercase: CSSProperties = { textTransform: 'lowercase' }

export const OedTags: FC<OedTagsProps> = ({ oedSubmission }) => {
  const [statuses] = useSysOptions('oed_submission_status')

  if (!oedSubmission) return null

  return (
    <Space align="center">
      {oedSubmission.status && (
        <Tag type={oedSubmission?.status === OedSubmissionStatus.draft ? 'neutral' : 'success'} style={lowercase}>
          {statuses[oedSubmission?.status || '']?.value}
        </Tag>
      )}
    </Space>
  )
}
