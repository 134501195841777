import { Dictionary } from '@reduxjs/toolkit'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { fetchEmpKeyvalues } from '~/features/master'
import { dispatch } from '~/stores/store'
import { KeyValue } from '~/types/common'
import { StoreState } from '~/types/store'
import { snakeToCamel } from '~/utils'
import { SysOptionType } from '../types/sys-option'

export const useSysOptions = (type: SysOptionType): [Dictionary<KeyValue>, boolean] => {
  const id = snakeToCamel(type)
  const data = useSelector((state: StoreState) => state.master.keyvalues[id]?.entities || {})
  const loading = useSelector((state: StoreState) => state.master.keyvaluesLoading[id])

  useEffect(() => {
    dispatch(fetchEmpKeyvalues(id, 'sysoption', { type }, { strategy: 'when-empty' }))
  }, [id, type])

  return [data, loading]
}
