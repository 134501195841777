import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Dictionary } from '@reduxjs/toolkit'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { SSLeaveApplyFormInfoState } from '../../types'
import { fetchMyLeaveApplyFormInfo } from '../../actions'

export const useMyLeaveApplyFormInfo = (leaveTypeId: string): [Dictionary<SSLeaveApplyFormInfoState>, boolean] => {
  const formInfo = useSelector((state: StoreState) => state.my.myLeaveApplyFormInfo[leaveTypeId]?.entities)
  const loading = useSelector((state: StoreState) => state.my.myLeaveApplyFormInfoLoading[leaveTypeId])

  useEffect(() => {
    if (leaveTypeId) {
      dispatch(fetchMyLeaveApplyFormInfo(leaveTypeId))
    }
  }, [leaveTypeId])

  return [formInfo, loading]
}
