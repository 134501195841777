import React, { FC, useCallback, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Button, Dropdown, Space } from '~/core-components'
import { PdfPasswordModal } from '~/components'
import { useIsMountedRef } from '~/hooks'
import { downloadWithDom, getFileTimestamp, showError } from '~/utils'
import { apiGetPayslipPdf, apiGetPayslipPdfPassword } from '../../api/payslip.api'
import './PayslipDownloadButton.less'

export interface PayslipDownloadButtonProps {
  payRunId: string
  employeeId: string
}

export const PayslipDownloadButton: FC<PayslipDownloadButtonProps> = ({ payRunId, employeeId }) => {
  const [downloading, setDownloading] = useState(false)
  const isMountedRef = useIsMountedRef()
  const [pdfModal, setPdfModal] = useState(false)

  const handleDownloadPdf = useCallback(async () => {
    if (payRunId && employeeId) {
      try {
        setDownloading(true)
        const { status, result, errors, message, errorData } = await apiGetPayslipPdf(payRunId, employeeId)

        if (status) {
          const fileName = `payslip_${getFileTimestamp()}.pdf`
          downloadWithDom(result, fileName)
        } else {
          console.error('Error while downloading', errors)
          showError(message, errorData)
        }
      } finally {
        if (isMountedRef.current) setDownloading(false)
      }
    }
  }, [payRunId, employeeId, isMountedRef])

  // const handleOpenPdfModal = useCallback(async () => {
  //   setPdfModal(true)
  // }, [])

  const handleClosePdfModal = useCallback(() => {
    setPdfModal(false)
  }, [])

  const handleDownloadSecurePdf = useCallback(
    async (password: string) => {
      if (payRunId && employeeId) {
        try {
          setDownloading(true)
          const { status, result, errors, message, errorData } = await apiGetPayslipPdfPassword(
            payRunId,
            employeeId,
            password
          )

          if (status) {
            const fileName = `payslip_${getFileTimestamp()}.pdf`
            downloadWithDom(result, fileName)
          } else {
            console.error('Error while downloading', errors)
            showError(message, errorData)
          }
        } finally {
          if (isMountedRef.current) setDownloading(false)
        }
      }
    },
    [payRunId, employeeId, isMountedRef]
  )

  return (
    <>
      <Dropdown
        disabled={downloading}
        menu={{
          items: [
            {
              key: 'pdf',
              label: 'PDF',
              onClick: handleDownloadPdf
            }
            // {
            //   key: 'pdfsecure',
            //   label: 'PDF (password)',
            //   onClick: handleOpenPdfModal
            // }
          ]
        }}
      >
        <Button className="payslip-download-button">
          {downloading ? (
            <LoadingOutlined />
          ) : (
            <Space size={4}>
              <i className="fal fa-arrow-down-to-bracket" />
              <i className="fa-light fa-angle-down" />
            </Space>
          )}
        </Button>
      </Dropdown>
      <PdfPasswordModal open={pdfModal} onApply={handleDownloadSecurePdf} onClose={handleClosePdfModal} />
    </>
  )
}
