import { showError, showSuccess } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { IUpdateTenant } from '../types'
import { apiUpdateTenant } from '../api/tenant.api'
import { setMyTenant } from '../reducers'

const activeTenantCode = localStorage.getItem('tenant')

export const updateTenant = (request: IUpdateTenant): ThunkResult<void> => {
  return async (dispatch, getState): Promise<ActionResult> => {
    if (!activeTenantCode || !request.tenantName) return {}

    const { status, result, errors, message, errorData } = await apiUpdateTenant(activeTenantCode, request)
    if (status) {
      const tenant = getState().iam.myTenants.entities[activeTenantCode]
      if (tenant) {
        dispatch(setMyTenant({ ...tenant, ...request }))
      }

      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
