import { request, getBaseUrl, timeout } from '~/utils/request'
import { DateRange, Pagination, Search } from '~/types/common'
import { ROLeaveRecordViewState } from '../types'

const baseUrl = getBaseUrl('/leave/roleaverecord')

export const apiGetROLeaveRecordView = (viewId: string, role: string, params: DateRange & Pagination & Search) =>
  request<ROLeaveRecordViewState>('get', `${baseUrl}/view/${viewId}/${role}`, params)

export const apiGetROLeaveRecordExcel = (viewId: string, role: string, params: DateRange & Search) =>
  request('get', `${baseUrl}/view/${viewId}/${role}/excel`, params, { responseType: 'blob', timeout })
