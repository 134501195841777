import { replaceEmailGroups, setEmailGroupsLoading } from '../reducers'
import { apiGetEmailGroups } from '../api/email-group.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchEmailGroups = (options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().master.emailGroupsLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().master.emailGroups?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setEmailGroupsLoading(true))
      const { result, status } = await apiGetEmailGroups()
      if (status) {
        dispatch(replaceEmailGroups(result))
      }
    } finally {
      dispatch(setEmailGroupsLoading(false))
    }
  }
}
