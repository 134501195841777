import { replaceMyLeaveGrants, setMyLeaveGrantsLoading } from '../../reducers'
import { apiGetMyLeaveGrants } from '../../api/ssleave.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchMyLeaveGrants = (
  leaveTypeId: string,
  hasBalance: boolean,
  validPeriodAsOf: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().my.myLeaveGrantsLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().my.myLeaveGrants?.ids.length > 0
      if (hasData) return
    }

    try {
      dispatch(setMyLeaveGrantsLoading(true))
      const { result, status } = await apiGetMyLeaveGrants(leaveTypeId, hasBalance, validPeriodAsOf)
      if (status) dispatch(replaceMyLeaveGrants(result))
    } finally {
      dispatch(setMyLeaveGrantsLoading(false))
    }
  }
}
