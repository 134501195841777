import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { ViewSchemaState } from '~/features/selection'
import { StoreState } from '~/types/store'
import { PayslipState } from '../types'

export const selectPayPayslipSchema = createSelector(
  (state: StoreState) => state.payroll.payslip,
  payslipState =>
    memoize(
      (payRunId: string, employeeId: string): ViewSchemaState | undefined => {
        const payslips = Object.values(payslipState?.entities || {}) as PayslipState[]
        var payslip = payslips.find(rec => rec.employeeId === employeeId && rec.payRunId === payRunId)
        return payslip?.paySchema
      },
      (payRunId: string, employeeId: string) => `${payRunId}|${employeeId}`
    )
)
