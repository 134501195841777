import { apiCopyEmployeeValidate } from '../api/employee.api'
import { ThunkResult } from '~/types/store'
import { ICopyEmployee } from '../types'

export const copyEmployeeValidate = (copyEmployee: ICopyEmployee): ThunkResult<void> => {
  return async () => {
    const { errors, result, status } = await apiCopyEmployeeValidate(copyEmployee)
    if (status) {
      return { result }
    } else {
      return { errors }
    }
  }
}
