import { request, getBaseUrl } from '~/utils'
import { ILeaveApply, ILeaveRecord, LeaveRecordDtlState, LeaveApplyFormInfoState } from '../types'
import { UploadFile } from '../../../core-components'

const baseUrl = getBaseUrl('/leave/leaveapply')

export const apiApplyLeaveRecord = (id: string, leaveRecord: ILeaveApply) => {
  let formData = new FormData()
  Object.entries(leaveRecord).forEach(([key, value]) => {
    if (key === 'attachments') {
      value.forEach((x: UploadFile) => {
        if (x instanceof File) formData.append(key, x, x.name)
      })
    } else if (key === 'deletedAttachmentIds' && value != null && value !== '') {
      value.forEach((x: string) => {
        formData.append(key, x)
      })
    } else {
      formData.append(key, value === null ? '' : value)
    }
  })

  formData.append('id', id)

  formData.append(
    'dataJsonb',
    JSON.stringify({
      ...(leaveRecord.isShared ? { isShared: leaveRecord.isShared } : {}),
      ...(leaveRecord.sharedQuantity ? { sharedQuantity: leaveRecord.sharedQuantity } : {}),
      ...(leaveRecord.takenDuration ? { takenDuration: leaveRecord.takenDuration } : {}),
      ...(leaveRecord.relationship ? { relationship: leaveRecord.relationship } : {})
    })
  )

  return request<{
    status: string
    request: ILeaveApply
    data: ILeaveRecord
    dtls: LeaveRecordDtlState[]
    conflicts: LeaveRecordDtlState[]
  }>('post', `${baseUrl}/${id}`, formData)
}

export const apiGetApplyFormSettings = (leaveTypeId: string, employeeId: string) =>
  request<LeaveApplyFormInfoState[]>('get', `${baseUrl}/settings/${leaveTypeId}`, { employeeId })
