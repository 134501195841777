import { request, getBaseUrl } from '~/utils/request'
import { EmPublicPersonState, EmPublicProfileState, EmPublicState } from '../types'

const baseUrl = getBaseUrl('/employee/employeepublic')

export const apiGetEmPublicPerson = (id: string) => request<EmPublicPersonState>('get', `${baseUrl}/${id}/person`)

export const apiGetEmPublicProfile = (id: string) => request<EmPublicProfileState>('get', `${baseUrl}/${id}/profile`)

export const apiGetEmPublic = (id: string) => request<EmPublicState>('get', `${baseUrl}/${id}/public`)
