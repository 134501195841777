import React, { FC, forwardRef, useCallback } from 'react'
import classNames from 'classnames'
import { SecondaryText, Select, Space, Tag } from '~/core-components'
import { SelectProps } from '~/core-components/Select/Select'
import { DropdownActions, PersonAvatar } from '~/components'
import { emptyGuid } from '~/constants'
import { useFirstInView } from '~/hooks/use-first-in-view'
import { FetchStrategy } from '~/types/common'
import { dispatch } from '~/stores/store'
import { getBaseUrl } from '~/utils'
import { refetchSysCriteriaOptions } from '../../reducers'
import { SysCriteriaFieldState, Screen } from '../../types'
import { useSysCriteriaOptions } from '../../hooks'
import './CriteriaOptions.less'

interface CriteriaOptionsProps extends SelectProps {
  screenCode: Screen
  criteria?: SysCriteriaFieldState
  display?: 'value' | 'key' | 'keyvalue'
  readOnly?: boolean
  showAsTag?: boolean
  fetchStrategy?: FetchStrategy
}

const EM_OPTIONS = ['employee', 'lve_current_approver', 'manager', 'manager_secondary']

const baseUrl = getBaseUrl('/filestore')

interface RefreshCriteriaOptionsButtonProps {
  code?: string
}

export const RefreshCriteriaOptionsButton: FC<RefreshCriteriaOptionsButtonProps> = ({ code }) => {
  const handleRefresh = useCallback(() => {
    if (code) dispatch(refetchSysCriteriaOptions(code))
  }, [code])

  return <DropdownActions onRefresh={handleRefresh} />
}

const CriteriaOptionsInternal = (
  {
    screenCode,
    criteria,
    display = 'value',
    children,
    readOnly = false,
    showAsTag = false,
    fetchStrategy = 'always',
    ...props
  }: CriteriaOptionsProps,
  ref: React.Ref<any>
) => {
  const { ref: inViewRef, inView } = useFirstInView<HTMLDivElement>({ threshold: 0.25 })
  const optionCode = criteria?.criteriaOptionCode || ''
  const [options, loading] = useSysCriteriaOptions(screenCode, optionCode, inView)
  const dropdownMatchSelectWidth = !EM_OPTIONS.includes(optionCode)

  if (showAsTag) {
    if (Array.isArray(props.value)) {
      return (
        <div ref={inViewRef}>
          <Space wrap>
            {props.value.map(v => (
              <Tag key={v} type="white">
                {options?.entities[v || '']?.value || ''}
              </Tag>
            ))}
          </Space>
        </div>
      )
    } else {
      return (
        <div ref={inViewRef}>
          <Tag>{options?.entities[props.value || '']?.value || ''}</Tag>
        </div>
      )
    }
  }

  return (
    <div
      ref={inViewRef}
      className={classNames('criteria-options', { 'criteria-options--has-value': props.value?.length > 0 })}
    >
      <Select
        ref={ref}
        mode="multiple"
        maxTagCount={1}
        showSearch
        allowClear={false}
        showArrow
        suffixIcon={<i className="fal fa-angle-down" />}
        optionFilterProp="title"
        optionLabelProp="title"
        placeholder={criteria?.description}
        dropdownMatchSelectWidth={dropdownMatchSelectWidth}
        dropdownRender={menu => (
          <div>
            {menu}
            <RefreshCriteriaOptionsButton code={optionCode} />
          </div>
        )}
        {...props}
        loading={loading}
        readOnly={readOnly}
      >
        {options?.ids.map(id => {
          const item = options?.entities[id]
          const displayText =
            display === 'value'
              ? item?.value
              : display === 'key'
              ? item?.key
              : display === 'keyvalue'
              ? `${item?.key} - ${item?.value}`
              : ''

          return (
            <Select.Option key={item?.key} value={item?.key || ''} title={displayText}>
              {EM_OPTIONS.includes(criteria?.criteriaOptionCode || '') ? (
                <div className="criteria-options__person">
                  <PersonAvatar
                    photo={
                      item?.photoId && item?.photoId !== emptyGuid
                        ? `${baseUrl}/file/${item?.photoId}/thumbnailphoto/36`
                        : undefined
                    }
                    size={36}
                    loading={loading}
                  />
                  <div className="criteria-options__person__info">
                    <h1>{item?.value}</h1>
                    <Space>
                      {item?.description && <SecondaryText>{item?.description}</SecondaryText>}
                      {item?.isResignee && <Tag type="neutral">resignee</Tag>}
                    </Space>
                  </div>
                </div>
              ) : (
                <>{displayText}</>
              )}
            </Select.Option>
          )
        })}
      </Select>
    </div>
  )
}

export const CriteriaOptions = forwardRef(CriteriaOptionsInternal)
