import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Form, Input, Modal } from '~/core-components'
import { Col, Row } from '~/components'
import { useFocus } from '~/hooks/use-focus'

interface EditNotesModalProps {
  visible: boolean
  notes: string
  onApply?: (notes: string) => void
  onClose: () => void
}

interface FormData {
  notes: string
}

const EMPTY_FORM_DATA: FormData = {
  notes: ''
}

export interface EditNotesModalState {
  visible: boolean
  notes: string
}

export const DEFAULT_EDIT_NOTES_MODAL_STATE: EditNotesModalState = {
  visible: false,
  notes: ''
}

export const EditNotesModal: FC<EditNotesModalProps> = ({ visible, notes, onApply, onClose }) => {
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)
  const [focusRef, setFocus] = useFocus(true)
  const formId = useMemo(() => `form-edit-notes-modal-${uuidv4()}`, [])

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
  }, [visible, setFocus])

  useEffect(() => {
    if (notes != null) {
      setFormData({ notes })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [notes])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => setFormData(formData => ({ ...formData, ...updates })),
    []
  )

  const handleApply = useCallback(async () => {
    typeof onApply === 'function' && onApply(formData.notes)
    setFormData(EMPTY_FORM_DATA)
    typeof onClose === 'function' && onClose()
  }, [formData, onApply, onClose])

  return (
    <Modal
      open={visible}
      title="Notes"
      width={350}
      okText="Apply"
      onCancel={onClose}
      className="edit-notes-modal"
      formId={formId}
    >
      <Form id={formId} onFinish={handleApply}>
        <Row>
          <Col span={24}>
            <Form.Item label="Notes">
              <Input.TextArea
                ref={focusRef}
                rows={2}
                value={formData.notes}
                onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                  handleFormDataChange({ notes: event.target.value })
                }
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
