import React, { CSSProperties, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { downloadWithDom, getBaseUrl, showError } from '~/utils'
import moment from 'moment-timezone'
import classNames from 'classnames'
import {
  Button,
  Card,
  ColumnsType,
  Dropdown,
  Form,
  Segmented,
  Space,
  Table,
  TableRowSelection,
  Text,
  Tooltip
} from '~/core-components'
import { Person, DocumentTitle, SearchInput } from '~/components'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction, View } from '~/constants'
import {
  Screen,
  updateViewCriteria,
  ViewCriteria,
  ViewCriteriaSimple,
  useViewSchema,
  ViewSelectionButton
} from '~/features/selection'
import { useIsMountedRef } from '~/hooks/use-is-mounted-ref'
import { EMP_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { formatYearMonth, formatMonth, formatMoney, getFileTimestamp } from '~/utils'
import { StoreState } from '~/types/store'
import { AddEmployeeDrawer } from '../Employee/components/AddEmployeeDrawer'
import { RehireEmployeeDrawer } from '../Employee/components/CopyEmployee/RehireEmployeeDrawer'
import { TransferEmployeeDrawer } from '../Employee/components/CopyEmployee/TransferEmployeeDrawer'
import { ImportEmployeeExcelDrawer } from '../Employee/components/ImportEmployeeExcel/ImportEmployeeExcelDrawer'
import { refetchEmDirectoryView, selectEmDirectoryViewById } from '../../reducers'
import { fetchEmDirectoryView } from '../../actions'
import { apiGetEmDirectoryExcel } from '../../api/em-directory.api'
import { maskString } from '../../util'
import { EmDirectoryState, EmSSActivateSummary } from '../../types'
import { apiActivateEmSS } from '../../api/employee-ss-activate.api'
import { ActivateEmSSDrawer } from './components/ActivateEmSSDrawer'
import './EmDirectory.less'

interface EmDirectoryProps {
  viewId?: string
}

interface AddEmployeeDrawerState {
  visible: boolean
}

interface RehireEmployeeDrawerState {
  visible: boolean
}

interface TransferEmployeeDrawerState {
  visible: boolean
}

interface ImportExcelDrawerState {
  visible: boolean
}

interface ActivateEmSSDrawerState {
  visible: boolean
  summary?: EmSSActivateSummary
}

const SCREEN_CODE: Screen = 'em_directory'
const DEFAULT_ADD_EM_DRAWER_STATE: AddEmployeeDrawerState = { visible: false }
const DEFAULT_REHIRE_EM_DRAWER_STATE: RehireEmployeeDrawerState = { visible: false }
const DEFAULT_TRANSFER_EM_DRAWER_STATE: TransferEmployeeDrawerState = { visible: false }
const DEFAULT_IMPORT_EXCEL_DRAWER_STATE: ImportExcelDrawerState = { visible: false }
const DEFAULT_ACTIVATE_EM_SS_DRAWER_STATE: ActivateEmSSDrawerState = { visible: false }
const PAGE_SIZE_OPTIONS = ['20', '50', '100']

const nameColWidth = 250
const defaultColWidth = 150
const paginationStyle: CSSProperties = { marginRight: 20 }
const baseUrl = getBaseUrl('/filestore')

const segmentedOptions = [
  {
    value: 'default',
    icon: (
      <Tooltip title="View employee list">
        <i className="fa-light fa-bars" />
      </Tooltip>
    )
  },
  {
    value: 'with-checkbox',
    icon: (
      <Tooltip title="Batch update employees" placement="topRight">
        <i className="fa-light fa-list" />
      </Tooltip>
    )
  }
]

export const EmDirectory: FC<EmDirectoryProps> = ({ viewId }) => {
  const [addEmDrawerState, setAddEmDrawerState] = useState<AddEmployeeDrawerState>(DEFAULT_ADD_EM_DRAWER_STATE)
  const [rehireEmDrawerState, setRehireEmDrawerState] =
    useState<RehireEmployeeDrawerState>(DEFAULT_REHIRE_EM_DRAWER_STATE)
  const [transferEmDrawerState, setTransferEmDrawerState] = useState<TransferEmployeeDrawerState>(
    DEFAULT_TRANSFER_EM_DRAWER_STATE
  )
  const [importExcelDrawerState, setImportExcelDrawerState] = useState<ImportExcelDrawerState>(
    DEFAULT_IMPORT_EXCEL_DRAWER_STATE
  )
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(20)
  const [search, setSearch] = useState<string>('')

  const selection = useSelector((state: StoreState) => state.selection.sysSelectionFields[SCREEN_CODE])
  const [schema] = useViewSchema(SCREEN_CODE, viewId)
  const expanded = useSelector((state: StoreState) => state.employee.emDirectoryViewExpanded)
  const loading = useSelector((state: StoreState) => state.employee.emDirectoryViewLoading)
  const data = useSelector((state: StoreState) => (viewId ? selectEmDirectoryViewById(state, viewId) : undefined))
  const isMountedRef = useIsMountedRef()
  const [downloading, setDownloading] = useState(false)
  const canAdd = usePermissionGate(Permission.employee, PermissionAction.Add)
  const refetch = useSelector((state: StoreState) => state.employee.emDirectoryViewRefetch)

  // Bulk update
  const canActivate = usePermissionGate(Permission.employee, PermissionAction.ActivateAccount)
  const ids = useMemo(() => data?.data.map(d => d.id) || [], [data])
  const [viewType, setViewType] = useState<string | number>('default')
  const isDefaultView = viewType === 'default'
  const [selected, setSelected] = useState<EmDirectoryState[]>([])
  const [bulkUpdating, setBulkUpdating] = useState(false)
  const [activateEmSSDrawerState, setActivateEmSSDrawerState] = useState<ActivateEmSSDrawerState>(
    DEFAULT_ACTIVATE_EM_SS_DRAWER_STATE
  )

  useEffect(() => {
    if (viewId) dispatch(fetchEmDirectoryView(viewId, { offset: pageSize * (page - 1), limit: pageSize }, search))
  }, [viewId, page, pageSize, search, refetch])

  const tableWidth =
    (schema?.selection.reduce(
      (sum, f) => (sum += selection?.entities[f.selectionFieldId]?.width || defaultColWidth),
      0
    ) || 0) + nameColWidth

  let columns: ColumnsType<EmDirectoryState> = [
    {
      title: 'Name',
      key: 'id',
      dataIndex: 'id',
      fixed: 'left',
      className: 'first-col',
      width: nameColWidth,
      render: (value: string, record) => (
        <Person
          name={record.name}
          description={!expanded && record.description}
          size={expanded ? 18 : 36}
          photo={record.photoId && `${baseUrl}/file/${record.photoId}/thumbnailphoto/${expanded ? 18 : 36}`}
          // photo={record.id && `/employee/employee/${record.id}/avatar/${expanded ? 18 : 36}?photo_id=${record.photoId}`}
          path={EMP_ROUTES.employee.replace(':id', record.id)}
          openAsNewTab={false}
        />
      )
    }
  ]

  if (schema) {
    columns.push(
      ...schema?.selection.map(f => {
        var field = selection?.entities[f.selectionFieldId]
        return {
          title: field?.description,
          key: field?.fieldName,
          dataIndex: field?.fieldName,
          width: field?.width || defaultColWidth,
          render: (value: string | number) => {
            if (field?.format === 'date' && value) {
              return moment(value).format('DD MMM YYYY')
            }
            if (field?.format === 'yearmonth' && value) {
              return formatYearMonth(value as string)
            }
            if (field?.format === 'month' && value) {
              return formatMonth(value as number)
            }
            if (field?.format === 'money' && value) {
              return formatMoney(value as number)
            }
            if (field?.fieldName === 'identityNo') {
              return <Tooltip title={value}>{maskString(value as string)}</Tooltip>
            }
            return value
          }
        }
      })
    )
  }

  const handleCloseSelectionDrawer = useCallback((changed?: boolean) => {
    if (changed) dispatch(refetchEmDirectoryView())
  }, [])

  const handleCriteriaApply = useCallback(
    async (criteria: ViewCriteria[]) => {
      if (viewId) {
        setPage(1)
        await dispatch(updateViewCriteria(SCREEN_CODE, viewId, { id: viewId, criteria }))
        dispatch(refetchEmDirectoryView())
        setSelected([])
      }
    },
    [viewId]
  )

  const handlePaginationChange = useCallback((page: number, pageSize?: number) => {
    setPage(page)
    setPageSize(pageSize || 20)
  }, [])

  const handleSearch = useCallback((value: string) => {
    setPage(1)
    setSearch(value)
  }, [])

  const handleAddEmployee = useCallback(() => {
    setAddEmDrawerState({ visible: true })
  }, [])

  const handleAddEmployeeDrawerClose = useCallback(() => {
    setAddEmDrawerState(DEFAULT_ADD_EM_DRAWER_STATE)
  }, [])

  const handleRehireEmployee = useCallback(() => {
    setRehireEmDrawerState({ visible: true })
  }, [])

  const handleRehireEmployeeDrawerClose = useCallback((success?: boolean) => {
    setRehireEmDrawerState(DEFAULT_REHIRE_EM_DRAWER_STATE)
    if (success) dispatch(refetchEmDirectoryView())
  }, [])

  const handleTransferEmployee = useCallback(() => {
    setTransferEmDrawerState({ visible: true })
  }, [])

  const handleTransferEmployeeDrawerClose = useCallback((success?: boolean) => {
    setTransferEmDrawerState(DEFAULT_TRANSFER_EM_DRAWER_STATE)
    if (success) dispatch(refetchEmDirectoryView())
  }, [])

  const handleImportExcel = useCallback(() => {
    setImportExcelDrawerState({ visible: true })
  }, [])

  const handleImportExcelDrawerClose = useCallback((success?: boolean) => {
    setImportExcelDrawerState(DEFAULT_IMPORT_EXCEL_DRAWER_STATE)
    if (success) dispatch(refetchEmDirectoryView())
  }, [])

  const handleDownloadClick = useCallback(async () => {
    if (viewId) {
      try {
        setDownloading(true)
        const { status, result, errors, message, errorData } = await apiGetEmDirectoryExcel(viewId, { search })

        if (status) {
          const fileName = `${schema?.viewName || View.default}_${getFileTimestamp()}.xlsx`
          downloadWithDom(result, fileName)
        } else {
          console.error('Error while downloading', errors)
          showError(message, errorData)
        }
      } finally {
        if (isMountedRef.current) setDownloading(false)
      }
    }
  }, [viewId, search, schema, isMountedRef])

  const handleActivateEmSS = useCallback(async () => {
    if (selected.length > 0) {
      setBulkUpdating(true)
      try {
        const { result, status, message, errorData } = await apiActivateEmSS({
          employeeIds: selected.map(s => s.id)
        })

        if (status) {
          setActivateEmSSDrawerState({ visible: true, summary: result })
        } else {
          showError(message, errorData)
        }
      } finally {
        setBulkUpdating(false)
      }
    } else {
      showError('Please select at least one employee')
    }
  }, [selected])

  const handleActivateEmSSDrawerClose = useCallback((action: 'activated' | 'cancelled') => {
    if (action === 'activated') setSelected([])
    setActivateEmSSDrawerState(DEFAULT_ACTIVATE_EM_SS_DRAWER_STATE)
  }, [])

  const rowSelection: TableRowSelection<EmDirectoryState> = useMemo(
    () => ({
      type: 'checkbox',
      selectedRowKeys: selected.map(s => s.id),
      hideSelectAll: false,
      columnWidth: 45,
      onChange: (_selectedRowKeys, selectedRows) => {
        setSelected(s => [...s.filter(o => !ids.includes(o.id)), ...selectedRows])
      }
    }),
    [selected, ids]
  )

  return (
    <div className="em-directory">
      <DocumentTitle title="People Directory" />
      <div className="em-directory__body">
        <div className={classNames('em-directory__action-bar', { 'em-directory__action-bar--expanded': expanded })}>
          <Form.Item label="">
            <SearchInput onSearch={handleSearch} />
          </Form.Item>
          <ViewCriteriaSimple screenCode={SCREEN_CODE} viewId={viewId} onApply={handleCriteriaApply} label="" />
          <Space align="start">
            {isDefaultView ? (
              <>
                {canAdd && (
                  <>
                    <Dropdown.Button
                      menu={{
                        items: [
                          { key: 'rehire', label: 'Rehire employee', onClick: handleRehireEmployee },
                          { key: 'transfer', label: 'Transfer employee', onClick: handleTransferEmployee },
                          { key: 'excel', label: 'Upload from excel', onClick: handleImportExcel }
                        ]
                      }}
                      icon={<i className="fal fa-angle-down" />}
                      onClick={handleAddEmployee}
                    >
                      Add new employee
                    </Dropdown.Button>
                  </>
                )}
                <Tooltip title="Download excel">
                  <Button
                    icon={<i className="fal fa-arrow-down-to-bracket" />}
                    onClick={handleDownloadClick}
                    loading={downloading}
                  />
                </Tooltip>
              </>
            ) : (
              canActivate && (
                <Button
                  className="em-directory__action-bar-buttons-modify"
                  loading={bulkUpdating}
                  onClick={handleActivateEmSS}
                >
                  Activate self-service
                </Button>
              )
            )}

            <ViewSelectionButton
              screenCode={SCREEN_CODE}
              viewId={viewId || ''}
              title="Information to show in people directory"
              onClose={handleCloseSelectionDrawer}
            />
          </Space>
          <Segmented options={segmentedOptions} value={viewType} onChange={setViewType} />
        </div>
        <Card fitParent table>
          <Table
            rowKey="id"
            rowSelection={!isDefaultView ? rowSelection : undefined}
            dataSource={data?.data}
            columns={columns}
            fitParent
            loading={loading}
            scroll={{ x: tableWidth, y: 1000 }}
            pagination={{
              total: data?.count,
              current: page,
              pageSize,
              pageSizeOptions: PAGE_SIZE_OPTIONS,
              showSizeChanger: true,
              onChange: handlePaginationChange,
              style: paginationStyle,
              showTotal: (total: number, range: [number, number]) => {
                if (range && range.length === 2 && !isNaN(range[0]) && !isNaN(range[1])) {
                  return (
                    <Text size="small">
                      {range[0]}-{range[1]} of {total}
                    </Text>
                  )
                }
              }
            }}
          />
        </Card>
      </div>
      {canAdd && <AddEmployeeDrawer {...addEmDrawerState} onClose={handleAddEmployeeDrawerClose} />}
      {canAdd && <RehireEmployeeDrawer {...rehireEmDrawerState} onClose={handleRehireEmployeeDrawerClose} />}
      {canAdd && <TransferEmployeeDrawer {...transferEmDrawerState} onClose={handleTransferEmployeeDrawerClose} />}
      {canAdd && <ImportEmployeeExcelDrawer {...importExcelDrawerState} onClose={handleImportExcelDrawerClose} />}
      {canAdd && <ActivateEmSSDrawer {...activateEmSSDrawerState} onClose={handleActivateEmSSDrawerClose} />}
    </div>
  )
}
