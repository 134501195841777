import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchClaimTypes } from '~/features/claim'
import { FetchStrategy } from '~/types/common'
import { fetchMyClaimBalances } from '../actions'
import { selectMyClaimBalance, selectMyClaimBalancePeriods } from '../selectors'
import { SSClaimBalanceState } from '../types'
import { PeriodInfo } from '~/features/my/selectors'

export const useMyClaimBalances = (
  fetchStrategy: FetchStrategy = 'always'
): [SSClaimBalanceState[], PeriodInfo[], boolean] => {
  const loading = useSelector((state: StoreState) => state.myClaim.myClaimBalancesLoading)
  const claimBalances = useSelector(selectMyClaimBalance)
  const periods = useSelector(selectMyClaimBalancePeriods)
  const refetch = useSelector((state: StoreState) => state.myClaim.myClaimBalancesRefetch)

  useEffect(() => {
    dispatch(fetchClaimTypes({ strategy: 'when-empty' }))
  }, [refetch])

  useEffect(() => {
    dispatch(fetchMyClaimBalances({ strategy: fetchStrategy }))
  }, [refetch, fetchStrategy])

  return [claimBalances, periods, loading]
}
