import { replaceFormulaDetails, setFormulaDetailsLoading } from '../reducers'
import { apiGetFormulaDetails } from '../api/formula.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchFormulaDetails = (
  formulaId: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!formulaId) return

    const loading = getState().formula.formulaDetailLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().formula.formula?.entities[formulaId]
      if (hasData) return
    }

    try {
      dispatch(setFormulaDetailsLoading(true))
      const { result, status } = await apiGetFormulaDetails(formulaId)
      if (status) {
        dispatch(replaceFormulaDetails(result))
      }
    } finally {
      dispatch(setFormulaDetailsLoading(false))
    }
  }
}
