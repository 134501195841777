import { showSuccess, showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { apiAddShift } from '../api/shift.api'
import { setShift } from '../reducers'
import { ShiftInfoState, ShiftState } from '../types'

export const addShift = (shift: ShiftInfoState): ThunkResult<void> => {
  return async (dispatch): Promise<ActionResult> => {
    const { status, result, errors, message, errorData } = await apiAddShift(shift)
    if (status) {
      const newShift: ShiftState = {
        ...shift,
        id: result.id
      }
      dispatch(setShift(newShift))
      showSuccess('Added')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
