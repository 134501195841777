import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { TeConfigState } from '../types'

const baseUrl = getBaseUrl('/attendance/teconfig')

export const apiGetTeConfigs = () => request<TeConfigState[]>('get', baseUrl)

export const apiUpdateTeConfig = (code: string, teConfig: Operation[]) =>
  request('patch', `${baseUrl}/${code}`, teConfig)
