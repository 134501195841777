import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ButtonTag, Form, Space, Tooltip } from '~/core-components'
import { DrawerForm } from '~/components'
import { useMyLogin, useIsEmployee, resetPasswordEmpNo, IResetPasswordEmpNo, usePermissionGate } from '~/features/iam'
import { ActionResult, StoreState } from '~/types/store'
import { dispatch } from '~/stores/store'
import { Permission, PermissionAction } from '~/constants'

interface FormData {
  employeeNo: string
  email: string
}

interface ResetPasswordTagProps {
  employeeId: string
}

const EMPTY_FORM_DATA: FormData = {
  employeeNo: '',
  email: ''
}

export const ResetPasswordTag: FC<ResetPasswordTagProps> = ({ employeeId }) => {
  const [resetting, setResetting] = useState(false)
  const [visible, setVisible] = useState(false)
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)
  const [myLogin] = useMyLogin()
  const employee = useSelector((state: StoreState) => state.employee.employees.entities[employeeId])
  const loginCode = employee?.employeeNo || ''
  const isEmailEmpty = !employee?.email
  const [member, loading] = useIsEmployee('employee_no', loginCode, 'always')
  const isActive = employee?.emStatus === 'active' || false
  const isMember = member?.isEmployee || false
  const canReset = usePermissionGate(Permission.employee, PermissionAction.ResetPassword)

  useEffect(() => {
    if (visible) {
      setFormData({
        ...EMPTY_FORM_DATA,
        employeeNo: loginCode,
        email: myLogin.email || ''
      })
    }
  }, [loginCode, isEmailEmpty, myLogin, visible])

  const handleTag = useCallback(() => {
    setVisible(true)
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setVisible(false)
  }, [])

  const handleReset = useCallback(async () => {
    let result: ActionResult | undefined
    if (employee && formData.employeeNo) {
      try {
        setResetting(true)

        const reset: IResetPasswordEmpNo = {
          email: formData.email,
          employeeNo: formData.employeeNo,
          name: employee.fullName
        }
        result = await dispatch(resetPasswordEmpNo(reset))
      } finally {
        setResetting(false)
      }
    }

    if (!result?.errors) {
      setVisible(false)
      setFormData(EMPTY_FORM_DATA)
    }
  }, [employee, formData.email, formData.employeeNo])

  if (member == null || loading || !isActive || !isEmailEmpty || !isMember || !canReset) return null

  return (
    <>
      <Tooltip title="Reset password employee">
        <ButtonTag type="primary-filled" onClick={handleTag}>
          reset password
        </ButtonTag>
      </Tooltip>
      <DrawerForm
        title="Reset password"
        onClose={handleCloseDrawer}
        okText="Send"
        confirmLoading={resetting}
        width={400}
        open={visible}
        formId="form-reset-password-employee"
      >
        <Form id="form-reset-password-employee" onFinish={handleReset}>
          <Space direction="vertical">
            <div>A temporary password will be sent to the following email:</div>
            <div>
              <b>{formData.email}</b>
            </div>
            <div>Employee will be required to set a new password after logging in.</div>
          </Space>
        </Form>
      </DrawerForm>
    </>
  )
}
