import pick from 'lodash/pick'
import { setViewSchema, setViewSchemaLoading } from '../reducers'
import { apiGetViewSchema } from '../api/view.api'
import { emptyGuid } from '~/constants'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'
import { Screen, ViewSchemaState } from '../types'

export const fetchViewSchema = (
  screenCode: Screen,
  viewId: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!screenCode || !viewId) return

    const viewSchemaLoading = getState().selection.viewSchemaLoading[screenCode]
    const loading = viewSchemaLoading && viewSchemaLoading[viewId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().selection.viewSchema[screenCode]?.entities[viewId]
      if (hasData) return
    }

    try {
      dispatch(setViewSchemaLoading({ screenCode, viewId, loading: true }))
      const { result, status } = await apiGetViewSchema(screenCode, viewId)
      if (status && result.id !== emptyGuid) {
        const stored = mapToState(result)
        dispatch(setViewSchema({ screenCode, data: stored }))
      }
    } finally {
      dispatch(setViewSchemaLoading({ screenCode, viewId, loading: false }))
    }
  }
}

const mapToState = (viewSchema: ViewSchemaState): ViewSchemaState => ({
  ...viewSchema,
  selection: viewSchema.selection.map(f => pick(f, 'id', 'selectionFieldId', 'sequence')),
  criteria: viewSchema.criteria.map(c =>
    pick(c, 'id', 'parentId', 'criteriaType', 'criteriaFieldId', 'operator', 'value', 'valueTo', 'sequence')
  )
})
