import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { SysWfFlowTypeState, WfCategory } from '../types'

export const selectSysFlowTypes = createSelector(
  (state: StoreState) => state.workflow.sysWfFlowTypes,
  sysWfFlowTypesState =>
    memoize((category: WfCategory): SysWfFlowTypeState[] => {
      const flowTypes = Object.values(sysWfFlowTypesState[category]?.entities || {}) as SysWfFlowTypeState[]
      return flowTypes
    })
)
