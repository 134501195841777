import { setEmPublicProfile, setEmPublicProfilesLoading } from '../reducers'
import { apiGetEmPublicProfile } from '../api/employee-public.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchEmPublicProfile = (id: string, options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const loading = getState().employee.emPublicProfilesLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().employee.emPublicProfiles.entities[id]
      if (hasData) return
    }

    try {
      dispatch(setEmPublicProfilesLoading(true))

      const { result, status } = await apiGetEmPublicProfile(id)
      if (status) {
        dispatch(setEmPublicProfile(result))
      }
    } finally {
      dispatch(setEmPublicProfilesLoading(false))
    }
  }
}
