import React, { FC, useCallback, useEffect, useState } from 'react'
import { ActionGroup, Button } from '~/core-components'
import { getFileTimestamp, showError, downloadWithDom } from '~/utils'
import { useIsMountedRef, useQuery } from '~/hooks'
import { apiGetOedRecordExcel } from '../../../../api/rpt-oed-submission.api'
import { OedSubmissionState } from '../../../../types'
import { OedSubmissionDrawer } from './OedSubmissionDrawer'

interface OedActionsProps {
  oedSubmission?: OedSubmissionState
}

interface SubmitDrawerState {
  visible: boolean
  oedSubmissionId?: string
}

const DEFAULT_SUBMIT_DRAWER_STATE: SubmitDrawerState = { visible: false, oedSubmissionId: undefined }

export const OedActions: FC<OedActionsProps> = ({ oedSubmission }) => {
  const oedSubmissionId = oedSubmission?.id || ''
  const query = useQuery()
  const showAction = query.get('action') === '1'
  const isMountedRef = useIsMountedRef()
  const [downloading, setDownloading] = useState(false)
  const [submitDrawerState, setSubmitDrawerState] = useState<SubmitDrawerState>(DEFAULT_SUBMIT_DRAWER_STATE)

  useEffect(() => {
    if (showAction) {
      setSubmitDrawerState({ visible: true, oedSubmissionId })
    }
  }, [showAction, oedSubmissionId])

  const handleDownloadClick = useCallback(async () => {
    try {
      setDownloading(true)
      const { status, result, errors, message, errorData } = await apiGetOedRecordExcel(oedSubmissionId)

      if (status) {
        const fileName = `oed_report_${getFileTimestamp()}.xlsx`
        downloadWithDom(result, fileName)
      } else {
        console.error('Error while downloading', errors)
        showError(message, errorData)
      }
    } finally {
      if (isMountedRef.current) setDownloading(false)
    }
  }, [oedSubmissionId, isMountedRef])

  const handleSubmit = useCallback(async () => {
    setSubmitDrawerState({ visible: true, oedSubmissionId })
  }, [oedSubmissionId])

  const handleCloseSubmitDrawer = useCallback(() => {
    setSubmitDrawerState(DEFAULT_SUBMIT_DRAWER_STATE)
  }, [])

  return (
    <div className="oed-actions">
      <ActionGroup>
        <Button onClick={handleDownloadClick} loading={downloading}>
          Download excel
        </Button>
        <Button type="primary" onClick={handleSubmit}>
          Submit to MOM...
        </Button>
        <OedSubmissionDrawer {...submitDrawerState} onClose={handleCloseSubmitDrawer} />
      </ActionGroup>
    </div>
  )
}
