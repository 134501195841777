import { List as InternalList, ListProps as InternalListProps } from './List'
import { ListItem } from './ListItem'

type TList = typeof InternalList
interface IList extends TList {
  Item: typeof ListItem
}

const List: IList = InternalList as IList
List.Item = ListItem
export { List }

export type ListProps = InternalListProps
