import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { OfficeState } from '../types'

interface OfficeOverview extends OfficeState {
  companies: { companyId?: string; name?: string; isMain?: boolean }[]
  activeEmployees: number
}

const EMPTY_OFFICE_EXTRA = { activeEmployees: 0 }

export const selectOfficesOverview = createSelector(
  (state: StoreState) => state.master.offices,
  (state: StoreState) => state.master.officesExtra,
  (state: StoreState) => state.master.companies,
  (state: StoreState) => state.master.companyOffices,
  (officesState, officesExtraState, companiesState, companyOfficesState) =>
    memoize((excludeCompanyId?: string): OfficeOverview[] => {
      let offices = Object.values(officesState.entities || {}).sort((a, b) => {
        const aInactiveDate = a!.inactiveDate ? new Date(a!.inactiveDate).getTime() : 0
        const bInactiveDate = b!.inactiveDate ? new Date(b!.inactiveDate).getTime() : 0

        if (bInactiveDate === 0 && aInactiveDate === 0) {
          return a!.name.localeCompare(b!.name)
        } else {
          return aInactiveDate - bInactiveDate
        }
      })

      if (companyOfficesState?.entities) {
        const companyOffices = Object.values(companyOfficesState.entities)
        const companies = companiesState?.entities

        if (excludeCompanyId) {
          offices = offices.filter(
            o => !companyOffices.filter(co => co?.officeId === o?.id).some(co => co?.companyId === excludeCompanyId)
          )
        }

        return offices.map(office => ({
          ...(office as OfficeState),
          ...(officesExtraState.entities[office!.id] || EMPTY_OFFICE_EXTRA),
          companies: companyOffices
            .filter(co => co?.officeId === office?.id)
            .map(co => ({
              companyId: co?.companyId,
              name: companies && co?.companyId && companies[co.companyId]?.name,
              isMain: co?.isMain
            }))
        }))
      } else {
        return offices.map(office => ({
          ...(office as OfficeState),
          ...(officesExtraState.entities[office!.id] || EMPTY_OFFICE_EXTRA),
          companies: []
        }))
      }
    })
)
