import { replaceMyLeaveRecordApprovalHistories, setMyLeaveRecordApprovalHistoriesLoading } from '../../reducers'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'
import { apiGetMyLeaveRecordApprovalHistories } from '../../api/ss-leave-record-approval-history.api'

export const fetchMyLeaveRecordApprovalHistories = (
  id: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const loading = getState().my.myLeaveRecordApprovalHistoriesLoading[id]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().my.myLeaveRecordApprovalHistories[id]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setMyLeaveRecordApprovalHistoriesLoading({ id, loading: true }))
      const { result, status } = await apiGetMyLeaveRecordApprovalHistories(id)
      if (status) dispatch(replaceMyLeaveRecordApprovalHistories({ id, data: result }))
    } finally {
      dispatch(setMyLeaveRecordApprovalHistoriesLoading({ id, loading: false }))
    }
  }
}
