import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchMyTimeLogTask } from '../actions'
import { SSTimeLogTaskState } from '../types'

export const useMyTimeLogTask = (
  id?: string,
  fetchStrategy: FetchStrategy = 'always'
): [SSTimeLogTaskState | undefined, boolean] => {
  const task = useSelector((state: StoreState) => state.myAttendance.myTimeLogTasks?.entities[id || ''])
  const loading = useSelector((state: StoreState) => state.myAttendance.myTimeLogTasksLoading)

  useEffect(() => {
    if (id) dispatch(fetchMyTimeLogTask(id, { strategy: fetchStrategy }))
  }, [id, fetchStrategy])

  return [task, loading]
}
