import React, { FC, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { BarChart, EChartsOption } from '~/charts'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchServiceLength } from '../../../actions'

interface ServiceLengthProps {}

export const ServiceLength: FC<ServiceLengthProps> = () => {
  const data = useSelector((state: StoreState) => state.employee.chartServiceLen)
  const loading = useSelector((state: StoreState) => state.employee.chartServiceLenLoading)

  const option = useMemo(() => {
    return {
      xAxis: {
        data: data?.labels || []
      },
      yAxis: {
        name: 'No. of employees'
      },
      series: [
        {
          data: data?.values,
          type: 'bar',
          barWidth: '50%',
          itemStyle: {
            color: '#ff8500'
          }
        }
      ]
    } as EChartsOption
  }, [data])

  useEffect(() => {
    dispatch(fetchServiceLength())
  }, [])

  return <BarChart title="Years of service" option={option} loading={loading} />
}
