import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Layout } from 'antd'
import { PermissionGate, selectMyTenants } from '~/features/iam'
import {
  ATT_PATH,
  CLA_PATH,
  EMP_PATH,
  JOB_PATH,
  LVE_PATH,
  PAY_PATH,
  REPORTS_PATH,
  SCH_PATH,
  SETTINGS_PATH,
  TAX_PATH
} from '~/routes/routes'
import { FeatureFlag, Permission } from '~/constants'
import { AdminSidebarLink } from './AdminSidebarLink/AdminSidebarLink'
import './AdminSidebar.less'

const { Sider } = Layout

interface AdminSidebarProps {}

const PERMISSION_EMPLOYEE = Permission.allEmployeeTabs
const PERMISSION_PAYROLL = [Permission.payRun]
const PERMISSION_SETTINGS = Permission.allSettings
const PERMISSION_TAX: string[] = [Permission.ytd]
const PERMISSION_LEAVE: string[] = Permission.allLeaveTransactions
const PERMISSION_CLAIM: string[] = Permission.allClaimTransactions
const PERMISSION_ATTENDANCE: string[] = Permission.allAttendanceTransactions
const PERMISSION_SCHEDULE: string[] = Permission.allScheduleTransactions
const PERMISSION_REPORTS: string[] = Permission.allReports

const activeTenantCode = localStorage.getItem('tenant')

export const AdminSidebar: FC<AdminSidebarProps> = () => {
  const myTenants = useSelector(selectMyTenants)
  const activeTenant = useMemo(() => myTenants.find(t => t.tenantCode === activeTenantCode), [myTenants])

  return (
    <div className="admin-sidebar">
      <Sider width={80} className="admin-sidebar__sider" trigger={null}>
        {/* <Link to="/edit-panel">Edit Panel</Link>
      <br />
      <Link to="/dashboard">Dashboard</Link>
      <br /> */}
        {/* <SidebarLink to={`${ADMIN_PATH}/home`}>
          <AppstoreOutlined/>
          Home
        </SidebarLink> */}
        <PermissionGate sysPermissionId={PERMISSION_EMPLOYEE}>
          <AdminSidebarLink to={EMP_PATH} icon={<i className="fal fa-user-friends fa-fw" />} title="People" />
        </PermissionGate>
        {activeTenant?.featureFlags?.includes(FeatureFlag.Payroll) && (
          <>
            <PermissionGate sysPermissionId={PERMISSION_PAYROLL}>
              <AdminSidebarLink
                to={PAY_PATH}
                icon={<i className="fal fa-money-check-dollar fa-fw" />}
                title="Payroll"
              />
            </PermissionGate>
            <PermissionGate sysPermissionId={PERMISSION_TAX}>
              <AdminSidebarLink to={TAX_PATH} icon={<i className="fal fa-landmark fa-fw" />} title="Taxation" />
            </PermissionGate>
          </>
        )}
        {activeTenant?.featureFlags?.includes(FeatureFlag.Schedule) && (
          <PermissionGate sysPermissionId={PERMISSION_SCHEDULE}>
            <AdminSidebarLink to={SCH_PATH} icon={<i className="fal fa-calendar-check fa-fw" />} title="Schedule" />
          </PermissionGate>
        )}
        {activeTenant?.featureFlags?.includes(FeatureFlag.Attendance) && (
          <PermissionGate sysPermissionId={PERMISSION_ATTENDANCE}>
            <AdminSidebarLink to={ATT_PATH} icon={<i className="fal fa-clock fa-fw" />} title="Attendance" />
          </PermissionGate>
        )}
        {activeTenant?.featureFlags?.includes(FeatureFlag.Leave) && (
          <PermissionGate sysPermissionId={PERMISSION_LEAVE}>
            <AdminSidebarLink to={LVE_PATH} icon={<i className="fal fa-tree-palm fa-fw" />} title="Leave" />
          </PermissionGate>
        )}
        {activeTenant?.featureFlags?.includes(FeatureFlag.Claim) && (
          <PermissionGate sysPermissionId={PERMISSION_CLAIM}>
            <AdminSidebarLink to={CLA_PATH} icon={<i className="fal fa-credit-card fa-fw" />} title="Claim" />
          </PermissionGate>
        )}
        <AdminSidebarLink to={JOB_PATH} icon={<i className="fal fa-circle-play" />} title="Automation" />
        <PermissionGate sysPermissionId={PERMISSION_REPORTS}>
          <AdminSidebarLink to={REPORTS_PATH} icon={<i className="fal fa-files fa-fw" />} title="Reports" />
        </PermissionGate>
        <PermissionGate sysPermissionId={PERMISSION_SETTINGS}>
          <AdminSidebarLink to={SETTINGS_PATH} icon={<i className="fal fa-cog fa-fw" />} title="Settings" />
        </PermissionGate>
        {/* <AdminSidebarLink to="/logout" icon={<i className="fal fa-sign-out fa-fw" />} title="Logout" /> */}
      </Sider>
    </div>
  )
}
