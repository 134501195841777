import { FC, useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment-timezone'
import { RangeValue } from 'rc-picker/lib/interface.d'
import { Form, Input } from '~/core-components'
import { Col, DrawerForm, PeriodKeyValues, Row } from '~/components'
import { ActionResult, Errors } from '~/types/store'
import { dispatch } from '~/stores/store'
import { ATT_ROUTES } from '~/routes/routes'
import { AttendanceGroupType, AttendancePeriodStatus } from '~/constants'
import { createDate } from '~/utils'
import { addAttendancePeriod } from '../../../actions'
import { useAttendanceGroupsDict } from '../../../hooks'
import { AttendanceGroupState, IAddAttendancePeriod } from '../../../types'
import { SelectAttendanceGroup } from '../../AttendanceGroup/components/SelectAttendanceGroup'

interface AddAttendancePeriodDrawerProps {
  visible: boolean
  onClose: (action: 'saved' | 'cancelled') => void
}

const currentPeriod = moment().format('YYYY-MM')

interface FormData extends IAddAttendancePeriod {}

const EMPTY_FORM_DATA: FormData = {
  name: '',
  attendanceGroupId: '',
  payPeriod: currentPeriod,
  startDate: '',
  endDate: '',
  status: AttendancePeriodStatus.Draft
}

export const AddAttendancePeriodDrawer: FC<AddAttendancePeriodDrawerProps> = ({ visible, onClose }) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const history = useHistory()

  const [attendanceGroupsDict] = useAttendanceGroupsDict('when-empty')
  const attendanceGroup = useMemo(
    () => attendanceGroupsDict[formData.attendanceGroupId],
    [attendanceGroupsDict, formData.attendanceGroupId]
  )
  const getName = useCallback(
    (formData: FormData) => {
      const attendanceGroup = attendanceGroupsDict[formData.attendanceGroupId]
      const names: string[] = []
      if (attendanceGroup?.name) {
        names.push(attendanceGroup.name)
      }
      if (formData.payPeriod) {
        names.push(formData.payPeriod)
      }
      return names.join(' ')
    },
    [attendanceGroupsDict]
  )

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      setFormData(data => {
        const updated = { ...data, ...updates }

        const prevName = getName(data)
        if (!('name' in updates) && (!data.name || prevName === data.name)) {
          updated.name = getName(updated)
        }

        return updated
      })
    },
    [getName]
  )

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      result = await dispatch(addAttendancePeriod(mapFormDataToAddRequest(formData)))
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose('saved')

      if (result?.result?.id) {
        const attendancePeriodId = result?.result?.id
        history.push(ATT_ROUTES.period.replace(':id', attendancePeriodId).replace(':tab?', 'records'))
      }
    }
  }, [formData, onClose, history])

  const handleCloseDrawer = useCallback(() => {
    typeof onClose === 'function' && onClose('cancelled')
  }, [onClose])

  const getDateRange = useCallback((period: string, attendanceGroup?: AttendanceGroupState) => {
    let startDate: moment.Moment | undefined = undefined,
      endDate: moment.Moment | undefined = undefined
    if (attendanceGroup && period) {
      startDate = createDate(period, attendanceGroup.otStartDay, attendanceGroup.otStartMonth)
      endDate = startDate.clone().add(1, 'month').add(-1, 'day')
    }
    return [startDate?.format('YYYY-MM-DD'), endDate?.format('YYYY-MM-DD')]
  }, [])

  return (
    <DrawerForm
      open={visible}
      title="Add attendance period"
      okText="Create"
      onClose={handleCloseDrawer}
      confirmLoading={loading}
      width={500}
      className="add-attendance-period-drawer"
      formId="form-add-attendance-period"
    >
      <Form id="form-add-attendance-period" onFinish={handleOk}>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Attendance group"
              validateStatus={errors?.attendanceGroupId ? 'error' : ''}
              help={errors?.attendanceGroupId}
            >
              <SelectAttendanceGroup
                type={AttendanceGroupType.Monthly}
                value={formData.attendanceGroupId}
                onChange={(attendanceGroupId: string) => {
                  const attendanceGroup = attendanceGroupsDict[attendanceGroupId]
                  const [startDate, endDate] = getDateRange(formData.payPeriod, attendanceGroup)
                  handleFormDataChange({ attendanceGroupId, startDate, endDate, payRunType: '' })
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item label="Period" validateStatus={errors?.payPeriod ? 'error' : ''} help={errors?.payPeriod}>
              <PeriodKeyValues
                value={formData.payPeriod}
                startMonth={currentPeriod}
                noOfMonthBefore={-18}
                noOfMonthAfter={10}
                onChange={(payPeriod: string) => {
                  const [startDate, endDate] = getDateRange(payPeriod, attendanceGroup)
                  handleFormDataChange({ payPeriod, startDate, endDate })
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Period"
              validateStatus={errors?.startDate || errors?.endDate ? 'error' : ''}
              help={errors?.startDate || errors?.endDate}
            >
              <Input.DateRange
                value={[
                  formData.startDate ? moment(formData.startDate) : null,
                  formData.endDate ? moment(formData.endDate) : null
                ]}
                onCalendarChange={(dates: RangeValue<moment.Moment>) => {
                  const startDate = dates && dates[0] ? dates[0].format('YYYY-MM-DD') : null
                  const endDate = dates && dates[1] ? dates[1].format('YYYY-MM-DD') : null
                  handleFormDataChange({ startDate, endDate })
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Name" validateStatus={errors?.name ? 'error' : ''} help={errors?.name}>
              <Input value={formData.name} onChange={event => handleFormDataChange({ name: event.target.value })} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}

const mapFormDataToAddRequest = (formData: FormData): IAddAttendancePeriod => ({
  name: formData.name,
  attendanceGroupId: formData.attendanceGroupId,
  payPeriod: formData.payPeriod,
  startDate: formData.startDate,
  endDate: formData.endDate,
  status: formData.status
})
