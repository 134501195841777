import { replaceMyClaimRecordAttachments, setMyClaimRecordAttachmentsLoading } from '../reducers'
import { apiGetMyClaimRecordAttachments } from '../api/ss-claim-record.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchMyClaimRecordAttachments = (
  id: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const loading = getState().myClaim.myClaimRecordAttachmentsLoading[id]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().myClaim.myClaimRecordAttachments[id]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setMyClaimRecordAttachmentsLoading({ id, loading: true }))
      const { result, status } = await apiGetMyClaimRecordAttachments(id)
      if (status) dispatch(replaceMyClaimRecordAttachments({ id, data: result }))
    } finally {
      dispatch(setMyClaimRecordAttachmentsLoading({ id, loading: false }))
    }
  }
}
