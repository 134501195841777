import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchLeaveRecordDtls } from '../actions'
import { selectLeaveRecordDtls } from '../selectors'
import { LeaveRecordDtlState } from '../types'

export const useLeaveRecordDtls = (leaveRecordId?: string): [LeaveRecordDtlState[], boolean] => {
  const leaveRecordDtls = useSelector(selectLeaveRecordDtls)(leaveRecordId || '') as LeaveRecordDtlState[]
  const loading = useSelector((state: StoreState) => state.leave.leaveRecordDtlsLoading[leaveRecordId || ''])

  useEffect(() => {
    if (leaveRecordId) {
      dispatch(fetchLeaveRecordDtls(leaveRecordId))
    }
  }, [leaveRecordId])

  return [leaveRecordDtls, loading]
}
