import { ThunkResult } from '~/types/store'
import { showSuccess, showError } from '~/utils'
import { IAddIr21A1Draft } from '../types'
import { apiAddIr21A1Draft } from '../api/ir21.api'

export const addIr21A1Draft = (request: IAddIr21A1Draft): ThunkResult<void> => {
  return async () => {
    const { status, errors, message, errorData } = await apiAddIr21A1Draft(request)
    if (status) {
      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
