import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Form, Input, Modal } from '~/core-components'
import { Col, Row } from '~/components'
import { ActionResult, Errors } from '~/types/store'
import { dispatch } from '~/stores/store'
import { useFocus } from '~/hooks/use-focus'
import { ClaRecordType } from '~/constants'
import { approveClaim, rejectClaim } from '../../../actions'
import { useMyClaimTask } from '../../../hooks'

interface MyClaimTaskActionModalProps {
  visible: boolean
  id: string
  action: 'approve' | 'reject'
  onClose: (success: boolean) => void
}

interface FormData {
  comment: string
}

const EMPTY_FORM_DATA: FormData = {
  comment: ''
}

export const MyClaimTaskActionModal: FC<MyClaimTaskActionModalProps> = ({ visible, id, action, onClose }) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const [focusRef, setFocus] = useFocus(true)
  const formId = useMemo(() => `form-my-task-action-${uuidv4()}`, [])
  const [claimTask] = useMyClaimTask(id)
  const cancellationLabel = claimTask?.recordType === ClaRecordType.cancellation ? ' cancellation' : ''

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  const handleClose = useCallback(
    (success = false) => {
      typeof onClose === 'function' && onClose(success)
      setFormData(EMPTY_FORM_DATA)
    },
    [onClose]
  )

  const handleSave = useCallback(async () => {
    setLoading(true)
    try {
      let result: ActionResult | undefined

      if (action === 'approve') {
        result = await dispatch(approveClaim(id, formData.comment))
      } else if (action === 'reject') {
        result = await dispatch(rejectClaim(id, formData.comment))
      }

      if (result?.errors) {
        setErrors(result.errors)
      }

      if (!result?.errors) {
        handleClose(true)
        setFormData(EMPTY_FORM_DATA)
      }
    } finally {
      setLoading(false)
    }
  }, [id, formData, action, handleClose])

  return (
    <Modal
      open={visible}
      title={action === 'reject' ? `Reject claim${cancellationLabel}` : `Approve claim${cancellationLabel}`}
      okText={action === 'reject' ? 'Reject' : 'Approve'}
      onCancel={() => handleClose(false)}
      width={500}
      confirmLoading={loading}
      formId={formId}
    >
      <Form id={formId} onFinish={handleSave}>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Enter your comment"
              validateStatus={errors?.comment ? 'error' : ''}
              help={errors?.comment}
            >
              <Input.TextArea
                ref={focusRef}
                rows={2}
                value={formData.comment}
                onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                  handleFormDataChange({ comment: event.target.value })
                }
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
