import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { EmOtClassState, IEmOtClass } from '../types'

const baseUrl = getBaseUrl('/employee/employee')

export const apiGetEmOtClasses = (employeeId: string) =>
  request<EmOtClassState[]>('get', `${baseUrl}/${employeeId}/otclass`)

export const apiGetEmOtClass = (employeeId: string, emOtClassId: string) =>
  request<EmOtClassState>('get', `${baseUrl}/${employeeId}/otclass/${emOtClassId}`)

export const apiAddEmOtClass = (employeeId: string, emOtClass: IEmOtClass) =>
  request<{ id: string; emOtClassId?: string }>('post', `${baseUrl}/${employeeId}/otclass`, emOtClass)

export const apiUpdateEmOtClass = (employeeId: string, id: string, emOtClass: Operation[]) =>
  request<{ emOtClassId?: string }>('patch', `${baseUrl}/${employeeId}/otclass/${id}`, emOtClass)

export const apiDeleteEmOtClass = (employeeId: string, id: string) =>
  request<{ emOtClassId?: string }>('delete', `${baseUrl}/${employeeId}/otclass/${id}`)
