import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { A8aFormState } from '../types'

export const selectA8aForm = createSelector(
  (state: StoreState) => state.tax.a8aForm,
  a8aFormState =>
    memoize(
      (ytdSubmissionId: string, employeeTaxNo: string): A8aFormState | undefined => {
        const form = a8aFormState?.entities[employeeTaxNo]
        if (form?.ytdSubmissionId === ytdSubmissionId) return form
      },
      (ytdSubmissionId: string, employeeTaxNo: string) => `${ytdSubmissionId}|${employeeTaxNo}`
    )
)
