import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Dictionary } from '@reduxjs/toolkit'
import { Form, Input, Radio, RadioChangeEvent, Select, Space, Switch } from '~/core-components'
import { Col, EditableCard, EditableCardState, EmpKeyValues, Row, SysOptions } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { dispatch } from '~/stores/store'
import { Errors, ActionResult, StoreState } from '~/types/store'
import { Permission, PermissionAction } from '~/constants'
import { ltSettingDelimiter, LveVisibleType } from '~/constants'
import { LtSettingState, LeaveTypeState } from '../../../types'
import { fetchLtSettings, updateLtSettings } from '../../../actions'
import { selectLtSettingsById, selectSysLtFieldsByCode } from '../../../selectors'
import './LeaveTypeSetting.less'

interface LeaveTypeSettingProps {
  leaveType?: LeaveTypeState
  onEdit?: () => void
  onSave?: () => void
  onCancel?: () => void
}

export const LeaveTypeSetting: FC<LeaveTypeSettingProps> = ({ leaveType, onEdit, onSave, onCancel }) => {
  const leaveTypeId = leaveType?.id || ''
  const [cardState, setCardState] = useState<EditableCardState>()
  const [formData, setFormData] = useState<Dictionary<LtSettingState>>({})
  const [errors, setErrors] = useState<Errors>()
  const sysLeaveTypeCode = leaveType?.sysLeaveTypeCode || ''
  const sysLtFields = useSelector(selectSysLtFieldsByCode)(sysLeaveTypeCode)
  const sysLtFieldsLoading = useSelector((state: StoreState) => state.leave.sysLtFieldsLoading[sysLeaveTypeCode])
  const canModify = usePermissionGate(Permission.lveType, PermissionAction.Modify)
  const ltSettings = useSelector(selectLtSettingsById)(leaveTypeId)
  const ltSettingsLoading = useSelector((state: StoreState) => state.leave.ltSettingsLoading[leaveTypeId])
  const loading = sysLtFieldsLoading || ltSettingsLoading

  useEffect(() => {
    dispatch(fetchLtSettings(leaveTypeId))
  }, [leaveTypeId])

  useEffect(() => {
    if (ltSettings) {
      setFormData(ltSettings)
    } else {
      setFormData({})
    }
  }, [ltSettings])

  const handleEdit = useCallback(() => {
    setCardState('editing')
    typeof onEdit === 'function' && onEdit()
  }, [onEdit])

  const handleSave = useCallback(async () => {
    if (leaveType) {
      setCardState('saving')
      setErrors(undefined)

      typeof onSave === 'function' && onSave()

      let result: ActionResult | undefined
      try {
        result = await dispatch(updateLtSettings(leaveType.id, Object.values(formData) as LtSettingState[]))
      } catch {
        setCardState('editing')
      }

      if (result?.errors) {
        setCardState('editing')
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setCardState(undefined)
      }
    }
  }, [leaveType, formData, onSave])

  const handleCancel = useCallback(() => {
    typeof onCancel === 'function' && onCancel()
    setCardState(undefined)
    setErrors(undefined)

    if (ltSettings) {
      setFormData(ltSettings)
    }
  }, [ltSettings, onCancel])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      let overrides: { [field: string]: LtSettingState } = {}
      Object.keys(updates).forEach(key => {
        overrides[key] = { ...formData[key], value: updates[key] } as LtSettingState
      })
      setFormData(data => ({ ...data, ...overrides }))
    },
    [formData]
  )

  const isVisible = useCallback(
    (fieldName: string) => {
      return ltSettings[fieldName] && sysLtFields[fieldName]?.visibleType === LveVisibleType.basic
    },
    [ltSettings, sysLtFields]
  )

  const readOnly = cardState !== 'editing' && cardState !== 'saving'

  const hasNationalityId = isVisible('nationality_id')
  const hasRelationType = isVisible('relation_type')
  const hasRefDateSource = isVisible('ref_date_source')
  const hasRefDataLabel = isVisible('ref_date_label')
  const hasRefMaxStart = isVisible('ref_max_start')
  const hasRefMaxEnd = isVisible('ref_max_end')
  const hasRefWithin = isVisible('ref_within')
  const hasGender = isVisible('gender')
  const hasMaxChild = isVisible('max_child') && isVisible('max_child_nc')
  const hasForm =
    hasNationalityId ||
    hasRelationType ||
    hasGender ||
    hasRefDateSource ||
    hasRefDataLabel ||
    hasRefMaxStart ||
    hasRefMaxEnd ||
    hasRefWithin ||
    hasMaxChild

  if (!hasForm && !loading) return null

  return (
    <EditableCard
      title="Criteria"
      bodyStyle={{ paddingBottom: leaveType && !loading ? 6 : 24, paddingTop: 6 }}
      loading={loading}
      state={!canModify ? 'readonly' : cardState}
      // state={!canModify || leaveType?.isSys ? 'readonly' : cardState}
      className="leavetype-setting"
      formId="form-leavetype-setting"
      onEdit={handleEdit}
      onSave={handleSave}
      onCancel={handleCancel}
    >
      {hasNationalityId && (
        <Row gutter={30}>
          <Col span={24}>
            <Form.Item validateStatus={errors?.nationalityId ? 'error' : ''} help={errors?.nationalityId}>
              <Space>
                <span>Citizen nationality</span>
                <EmpKeyValues
                  id="nationality"
                  value={formData['nationality_id']?.value}
                  readOnly={readOnly}
                  onChange={(value: string) => handleFormDataChange({ nationality_id: value })}
                />
              </Space>
            </Form.Item>
          </Col>
        </Row>
      )}
      {hasRelationType && (
        <Row className="relation-type">
          <Col span={24}>
            <Form.Item validateStatus={errors?.relationType ? 'error' : ''} help={errors?.relationType}>
              <Space>
                <span>Granted based on the information of</span>
                <SysOptions
                  type="lve_relationship"
                  mode="multiple"
                  value={
                    formData['relation_type']?.value
                      ? formData['relation_type'].value.split(ltSettingDelimiter)
                      : undefined
                  }
                  readOnly={readOnly}
                  onChange={(value: string[]) =>
                    handleFormDataChange({ relation_type: value?.join(ltSettingDelimiter) })
                  }
                />
              </Space>
            </Form.Item>
          </Col>
        </Row>
      )}
      {hasRelationType && isVisible('relation_age_min') && isVisible('relation_age_max') && (
        <Row className="relation-age subsection">
          <Col span={24}>
            <Form.Item validateStatus={errors?.age ? 'error' : ''} help={errors?.age}>
              <Space>
                <span>Age from</span>
                <Input.Number
                  readOnly={readOnly}
                  value={parseInt(formData['relation_age_min']?.value || '0')}
                  onChange={(value: number | null) => handleFormDataChange({ relation_age_min: value })}
                />
                <span>to</span>
                <Input.Number
                  readOnly={readOnly}
                  value={parseInt(formData['relation_age_max']?.value || '0')}
                  onChange={(value: number | null) => handleFormDataChange({ relation_age_max: value })}
                />
                <span>years</span>
                {isVisible('relation_age_basis') && (
                  <>
                    <span>based on</span>
                    <SysOptions
                      type="lve_relation_age_basis"
                      value={formData['relation_age_basis']?.value}
                      readOnly={readOnly}
                      onChange={(value: string) => handleFormDataChange({ lve_relation_age_basis: value })}
                    />
                  </>
                )}
              </Space>
            </Form.Item>
          </Col>
        </Row>
      )}
      {hasRelationType && isVisible('ref_min_service') && (
        <Row className="ref-min-service subsection">
          <Col span={24}>
            <Form.Item validateStatus={errors?.refMinService ? 'error' : ''} help={errors?.refMinService}>
              <Space>
                <span>Eligible after employee completed</span>
                <Input.Number
                  readOnly={readOnly}
                  value={parseInt(formData['ref_min_service']?.value || '0')}
                  onChange={(value: number | null) => handleFormDataChange({ ref_min_service: value })}
                />
                months of service
              </Space>
            </Form.Item>
          </Col>
        </Row>
      )}
      {(hasRefDateSource || hasRefDataLabel || hasRefMaxStart || hasRefMaxEnd || hasRefWithin) && (
        <Row>
          <Col span={24}>
            <Form.Item>
              <span>Validation against reference date</span>
            </Form.Item>
          </Col>
        </Row>
      )}
      {hasRefDateSource && (
        <Row className="ref-date-source subsection">
          <Col span={24}>
            <Form.Item validateStatus={errors?.refDateSource ? 'error' : ''} help={errors?.refDateSource}>
              <Space>
                <label>Reference date value</label>
                <SysOptions
                  type="lve_ref_date_source"
                  readOnly={readOnly}
                  value={formData['ref_date_source']?.value}
                  onChange={(value: string) => handleFormDataChange({ ref_date_source: value })}
                />
              </Space>
            </Form.Item>
          </Col>
        </Row>
      )}
      {hasRefDataLabel && (
        <Row className="ref-date-label subsection">
          <Col span={24}>
            <Form.Item validateStatus={errors?.refDateLabel ? 'error' : ''} help={errors?.refDateLabel}>
              <Space>
                <label>Label shown as</label>
                <Input
                  readOnly={readOnly}
                  value={formData['ref_date_label']?.value}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleFormDataChange({ ref_date_label: event.target.value })
                  }
                />
              </Space>
            </Form.Item>
          </Col>
        </Row>
      )}
      {hasRefMaxStart && (
        <Row className="max-days-before subsection">
          <Col span={24}>
            <Form.Item validateStatus={errors?.refMaxStart ? 'error' : ''} help={errors?.refMaxStart}>
              <Space>
                <Switch
                  disabled={readOnly}
                  size="small"
                  checked={formData['ref_max_start_enabled']?.value === 'true'}
                  onChange={(checked: boolean) => {
                    handleFormDataChange({ ref_max_start_enabled: checked ? 'true' : 'false' })
                  }}
                />
                <label>Earliest leave request</label>
                <Input.Number
                  readOnly={readOnly}
                  disabled={formData['ref_max_start_enabled']?.value !== 'true'}
                  value={parseInt(formData['ref_max_start']?.value || '0')}
                  min={0}
                  onChange={(value: number | null) => handleFormDataChange({ ref_max_start: value })}
                />
                <SysOptions
                  type="lve_day_basis"
                  readOnly={readOnly}
                  disabled={formData['ref_max_start_enabled']?.value !== 'true'}
                  value={formData['ref_max_basis']?.value}
                  onChange={(value: string) => handleFormDataChange({ ref_max_basis: value })}
                />
                <Select
                  allowClear={false}
                  readOnly={readOnly}
                  disabled={formData['ref_max_start_enabled']?.value !== 'true'}
                  value={parseInt(formData['ref_max_start_indicator']?.value || '-1')}
                  onChange={(value: number) => handleFormDataChange({ ref_max_start_indicator: value })}
                >
                  <Select.Option value={-1}>Before</Select.Option>
                  <Select.Option value={1}>After</Select.Option>
                </Select>
                <span>reference date</span>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      )}
      {hasRefMaxEnd && (
        <Row className="max-days-after subsection">
          <Col span={24}>
            <Form.Item validateStatus={errors?.refMaxEnd ? 'error' : ''} help={errors?.refMaxEnd}>
              <Space>
                <Switch
                  disabled={readOnly}
                  size="small"
                  checked={formData['ref_max_end_enabled']?.value === 'true'}
                  onChange={(checked: boolean) => {
                    handleFormDataChange({ ref_max_end_enabled: checked ? 'true' : 'false' })
                  }}
                />
                <label>Latest leave request</label>
                <Input.Number
                  readOnly={readOnly}
                  disabled={formData['ref_max_end_enabled']?.value !== 'true'}
                  value={parseInt(formData['ref_max_end']?.value || '0')}
                  min={0}
                  onChange={(value: number | null) => handleFormDataChange({ ref_max_end: value })}
                />
                <SysOptions
                  type="lve_day_basis"
                  readOnly={readOnly}
                  disabled={formData['ref_max_end_enabled']?.value !== 'true'}
                  value={formData['ref_max_basis']?.value}
                  onChange={(value: string) => handleFormDataChange({ ref_max_basis: value })}
                />
                <Select
                  allowClear={false}
                  readOnly={readOnly}
                  disabled={formData['ref_max_end_enabled']?.value !== 'true'}
                  value={parseInt(formData['ref_max_end_indicator']?.value || '1')}
                  onChange={(value: number) => handleFormDataChange({ ref_max_end_indicator: value })}
                >
                  <Select.Option value={-1}>Before</Select.Option>
                  <Select.Option value={1}>After</Select.Option>
                </Select>
                <span>reference date</span>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      )}
      {hasRefWithin && (
        <Row className="ref-within subsection">
          <Col span={24}>
            <Form.Item validateStatus={errors?.refMaxEnd ? 'error' : ''} help={errors?.refMaxEnd}>
              <Space>
                <Switch
                  disabled={readOnly}
                  size="small"
                  checked={formData['ref_within_enabled']?.value === 'true'}
                  onChange={(checked: boolean) => {
                    handleFormDataChange({ ref_within_enabled: checked ? 'true' : 'false' })
                  }}
                />
                <label>Leave request within the same</label>
                <Select
                  allowClear={false}
                  readOnly={readOnly}
                  disabled={formData['ref_within_enabled']?.value !== 'true'}
                  value={formData['ref_within']?.value}
                  onChange={(value: number) => handleFormDataChange({ ref_within: value })}
                >
                  <Select.Option value="m">month</Select.Option>
                  <Select.Option value="y">year</Select.Option>
                </Select>
                <span>as reference date</span>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      )}
      {hasGender && (
        <Row className="gender">
          <Col span={24}>
            <Form.Item validateStatus={errors?.gender ? 'error' : ''} help={errors?.gender}>
              <Space>
                <span>Applicant gender</span>
                <SysOptions
                  type="gender"
                  mode="multiple"
                  value={formData['gender']?.value ? formData['gender'].value.split(ltSettingDelimiter) : undefined}
                  readOnly={readOnly}
                  onChange={(value: string[]) => handleFormDataChange({ gender: value?.join(ltSettingDelimiter) })}
                />
              </Space>
            </Form.Item>
          </Col>
        </Row>
      )}
      {hasMaxChild && (
        <div className="max-child">
          <Row>
            <Col span={24}>
              <Form.Item
                className="form-item-margin-5"
                validateStatus={errors?.maxChild ? 'error' : ''}
                help={errors?.maxChild}
              >
                <Space>
                  <span className="label-col">Maximum no. of children</span>
                  <Radio.Group
                    readOnly={readOnly}
                    value={formData['max_child']?.value != null ? true : false}
                    onChange={(event: RadioChangeEvent) =>
                      handleFormDataChange({ max_child: event.target.value === true ? 0 : undefined })
                    }
                  >
                    <Radio value={true}>
                      <Input.Number
                        readOnly={readOnly}
                        value={parseInt(formData['max_child']?.value || '0')}
                        onChange={(value: number | null) => handleFormDataChange({ max_child: value })}
                      />
                    </Radio>
                    <Radio value={false}>No limit for citizen</Radio>
                  </Radio.Group>
                </Space>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item validateStatus={errors?.maxChildNc ? 'error' : ''} help={errors?.maxChildNc}>
                <Space>
                  <span className="label-col"></span>
                  <Radio.Group
                    readOnly={readOnly}
                    value={formData['max_child_nc']?.value != null ? true : false}
                    onChange={(event: RadioChangeEvent) =>
                      handleFormDataChange({ max_child_nc: event.target.value === true ? 0 : undefined })
                    }
                  >
                    <Radio value={true}>
                      <Input.Number
                        readOnly={readOnly}
                        value={parseInt(formData['max_child_nc']?.value || '0')}
                        onChange={(value: number | null) => handleFormDataChange({ max_child_nc: value })}
                      />
                    </Radio>
                    <Radio value={false}>No limit for non citizen</Radio>
                  </Radio.Group>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </div>
      )}
    </EditableCard>
  )
}
