import React, { FC, useCallback, useEffect, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Form, Spin, Switch } from '~/core-components'
import { dispatch } from '~/stores/store'
import { publishPayRecord, unpublishPayRecord } from '../../actions'
import { IPayRecordPublished } from '../../types'
import { apiGetPayRecordSgViewItem } from '../../api/payrecord-sg.api'

export interface PublishSwitcherProps {
  payRunId: string
  employeeId: string
}

export const PublishSwitcher: FC<PublishSwitcherProps> = ({ payRunId, employeeId }) => {
  const [publishing, setPublishing] = useState<boolean>(false)
  const [payRecordId, setPayRecordId] = useState<string>('')
  const [published, setPublished] = useState<boolean>(false)

  useEffect(() => {
    if (payRunId && employeeId) {
      const payRecordPublishedBy = async () => {
        const { status, result } = await apiGetPayRecordSgViewItem(payRunId, employeeId)
        if (status) {
          setPublished(result.publishedBy ? true : false)
          setPayRecordId(result.id)
        }
      }
      payRecordPublishedBy()
    }
  }, [payRunId, employeeId])

  const handlePublish = useCallback(
    async (checked: boolean) => {
      if (payRecordId) {
        setPublishing(true)
        let result: IPayRecordPublished
        try {
          if (checked) {
            result = await dispatch(publishPayRecord(payRecordId))
          } else {
            result = await dispatch(unpublishPayRecord(payRecordId))
          }
          if (result.publishedDate !== null) setPublished(checked)
        } finally {
          setPublishing(false)
        }
      }
    },
    [payRecordId]
  )

  return (
    <Form.Item label="Publish">
      {publishing ? (
        <Spin indicator={<LoadingOutlined spin />} />
      ) : (
        <Switch checkedChildren="Yes" unCheckedChildren="No" checked={published} onChange={handlePublish} />
      )}
    </Form.Item>
  )
}
