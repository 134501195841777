import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchXeroConnections } from '../actions'
import { XeroConnectionState } from '../types'
import { selectXeroConnections } from '../selectors'

export const useXeroConnections = (fetchStrategy: FetchStrategy = 'when-empty'): [XeroConnectionState[], boolean] => {
  const connections = useSelector(selectXeroConnections)
  const loading = useSelector((state: StoreState) => state.payroll.xeroConnectionsLoading)

  useEffect(() => {
    dispatch(fetchXeroConnections({ strategy: fetchStrategy }))
  }, [fetchStrategy])

  return [connections, loading]
}
