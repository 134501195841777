import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Form, Select } from '~/core-components'
import { Col, EditableCard, EditableCardState, Row } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { Permission, PermissionAction, emptyGuid } from '~/constants'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors, StoreState } from '~/types/store'
import { updateXeroConfig } from '../../actions'
import { useXeroConfig } from '../../hooks'
import { XeroSelectContact } from './XeroSelectContact'

interface XeroConfigProps {
  tenantMapId?: string
}

interface FormData {
  contactId?: string
  billStatus?: string
}

const EMPTY_FORM_DATA: FormData = {
  contactId: '',
  billStatus: ''
}

const BILL_STATUS: Record<string, string> = {
  DRAFT: 'Draft',
  SUBMITTED: 'Submitted',
  AUTHORISED: 'Approved'
}

export const XeroConfig: FC<XeroConfigProps> = ({ tenantMapId }) => {
  const tenantMap = useSelector((state: StoreState) => state.payroll.xeroTenantMaps?.entities[tenantMapId || ''])
  const companyId = tenantMap?.companyId
  const tenantId = tenantMap?.tenantId

  const [cardState, setCardState] = useState<EditableCardState>()
  const canModify = usePermissionGate(Permission.xero, PermissionAction.Modify)
  const readOnly = cardState !== 'editing'
  const [errors, setErrors] = useState<Errors>()
  const [config, configLoading] = useXeroConfig(companyId)
  const configId = config?.id
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)

  useEffect(() => {
    if (config) {
      setFormData({
        contactId: config.contactId,
        billStatus: config.billStatus
      })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [config])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setErrors(undefined)
    setFormData(data => ({ ...data, ...updates }))
  }, [])

  const handleEdit = useCallback(() => {
    setCardState('editing')
  }, [])

  const handleSave = useCallback(async () => {
    setCardState('saving')
    setErrors(undefined)

    let result: ActionResult | undefined
    try {
      result = await dispatch(updateXeroConfig(companyId, { ...formData, id: configId || emptyGuid }))
    } catch {
      setCardState('editing')
    }

    if (result?.errors) {
      setCardState('editing')
      setErrors(result.errors)
    }

    if (!result?.errors) {
      setCardState(undefined)
    }
  }, [companyId, formData, configId])

  const handleCancel = useCallback(() => {
    setCardState(undefined)
    setErrors(undefined)
  }, [])

  return (
    <EditableCard
      className="xero-line-item-map"
      title="Default values"
      bodyStyle={{ paddingBottom: !readOnly ? 6 : 24, paddingTop: 6 }}
      state={canModify ? cardState : 'readonly'}
      onEdit={handleEdit}
      onSave={handleSave}
      onCancel={handleCancel}
      loading={configLoading}
    >
      <Row gutter={30}>
        <Col span={12}>
          <Form.Item label="Default contact" validateStatus={errors?.contactId ? 'error' : ''} help={errors?.contactId}>
            <XeroSelectContact
              tenantId={tenantId}
              value={formData.contactId}
              readOnly={readOnly}
              onChange={(contactId: string) => handleFormDataChange({ contactId })}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Default bill status"
            validateStatus={errors?.billStatus ? 'error' : ''}
            help={errors?.billStatus}
          >
            <Select
              value={formData.billStatus}
              readOnly={readOnly}
              onChange={(billStatus: string) => handleFormDataChange({ billStatus })}
            >
              {Object.keys(BILL_STATUS).map(x => (
                <Select.Option key={x} value={x}>
                  {BILL_STATUS[x]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </EditableCard>
  )
}
