import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchLeaveRecordAttachment } from '../actions'
import { selectLeaveRecordAttachments } from '../selectors'
import { LeaveRecordAttachmentState } from '../types'

export const useLeaveRecordAttachments = (leaveRecordId?: string): [LeaveRecordAttachmentState[], boolean] => {
  const leaveRecordAttachments = useSelector(selectLeaveRecordAttachments)(
    leaveRecordId
  ) as LeaveRecordAttachmentState[]
  const loading = useSelector((state: StoreState) => state.leave.leaveRecordAttachmentLoading)

  useEffect(() => {
    if (leaveRecordId) {
      dispatch(fetchLeaveRecordAttachment(leaveRecordId))
    }
  }, [leaveRecordId])

  return [leaveRecordAttachments, loading]
}
