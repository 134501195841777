import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { DailySummaryViewState } from '../types'

export const selectDailySummaryView = createSelector(
  (state: StoreState) => state.attendance.dailySummaryView,
  dailySummaryState =>
    memoize((viewId: string): DailySummaryViewState => {
      return (dailySummaryState?.entities[viewId] || {}) as DailySummaryViewState
    })
)
