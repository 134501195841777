import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Dictionary } from '@reduxjs/toolkit'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { FetchStrategy } from '~/types/common'
import { ShiftState } from '../types'
import { fetchShifts } from '../actions'

export const useShiftsDict = (fetchStrategy: FetchStrategy = 'when-empty'): [Dictionary<ShiftState>, boolean] => {
  const shifts = useSelector((state: StoreState) => state.attendance.shifts.entities)
  const loading = useSelector((state: StoreState) => state.attendance.shiftsLoading)

  useEffect(() => {
    dispatch(fetchShifts({ strategy: fetchStrategy }))
  }, [fetchStrategy])

  return [shifts, loading]
}
