import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { EntitledClaimTypeState } from '../types'

export const selectEntitledClaimTypes = createSelector(
  (state: StoreState) => state.claim.entitledClaimTypes,
  entitledClaimTypesState =>
    memoize((employeeId: string): EntitledClaimTypeState[] => {
      const entitledClaimTypes = Object.values(
        entitledClaimTypesState[employeeId]?.entities || {}
      ) as EntitledClaimTypeState[]
      return entitledClaimTypes.sort((a, b) => a.claimTypeName.localeCompare(b.claimTypeName) || 0)
    })
)
