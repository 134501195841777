import React, { FC, useMemo } from 'react'
import { default as AntFormItem, FormItemProps as AntFormItemProps } from 'antd/lib/form/FormItem'
import { Space } from '../Space/Space'

export interface FormItemProps extends AntFormItemProps {}

export const FormItem: FC<FormItemProps> = ({ help, ...props }) => {
  const helpText = useMemo(() => {
    if (Array.isArray(help)) {
      return (
        <Space direction="vertical" size={0}>
          {help.map(h => h)}
        </Space>
      )
    }
    return help
  }, [help])

  return <AntFormItem help={helpText} {...props} />
}
