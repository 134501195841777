import React, { forwardRef, useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { Select, SelectProps } from '~/core-components'
import { sortKeyValues } from '~/features/employee'
import { fetchSysOptions } from '~/features/master'
import { useFirstInView } from '~/hooks'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { DropdownActions } from './DropdownActions'
import { SSRoleScreen } from '~/constants'
import './SSRoleKeyValues.less'

export interface SSRoleKeyValuesProps extends SelectProps {
  screenCode: SSRoleScreen
  fetchStrategy?: FetchStrategy
}

const SSRoleScreenMap: Record<SSRoleScreen, string[]> = {
  lve_entitlement: ['mgr', 'mgr_lve_ent'],
  lve_record: ['mgr', 'mgr_lve_rec'],
  att_schedule: ['mgr', 'mgr_att_sch']
}

const SSRoleKeyValuesInternal = (
  { children, screenCode, fetchStrategy = 'when-empty', className, ...props }: SSRoleKeyValuesProps,
  ref: React.Ref<any>
) => {
  const classes = classNames('ss-role-key-values', { [`${className}`]: className })
  const { ref: inViewRef, inView } = useFirstInView<HTMLDivElement>({ threshold: 0.25 })
  const data = useSelector((state: StoreState) => state.master.keyvalues['ssRole']?.entities || {})
  const loading = useSelector((state: StoreState) => state.master.keyvaluesLoading['ssRole'])
  const me = useSelector((state: StoreState) => state.my.myEmployee)
  const activeScreen = SSRoleScreenMap[screenCode]

  const options = useMemo(() => {
    if (!data) return []

    let result = Object.values(data)
      .filter(o => me.roles.includes(o?.key || ''))
      .sort((a, b) => sortKeyValues(a!, b!))

    if (activeScreen && activeScreen.length > 0) {
      result = result.filter(o => activeScreen.includes(o?.key || ''))
    }

    return result
  }, [data, activeScreen, me.roles])

  useEffect(() => {
    if (inView) {
      dispatch(fetchSysOptions('ss_role', { strategy: fetchStrategy }))
    }
  }, [inView, fetchStrategy])

  const handleRefresh = useCallback(() => {
    dispatch(fetchSysOptions('ss_role'))
  }, [])

  return (
    <div ref={inViewRef}>
      <Select
        ref={ref}
        showSearch
        allowClear={false}
        className={classes}
        optionFilterProp="title"
        loading={loading}
        dropdownMatchSelectWidth={false}
        dropdownRender={menu => (
          <div>
            {menu}
            <DropdownActions onRefresh={handleRefresh} />
          </div>
        )}
        {...props}
      >
        {options.map(kv => (
          <Select.Option key={kv!.key} value={kv!.key || ''} title={kv!.value}>
            {kv!.value}
          </Select.Option>
        ))}
      </Select>
    </div>
  )
}

export const SSRoleKeyValues = forwardRef(SSRoleKeyValuesInternal)
