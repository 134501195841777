import { replaceXeroContacts, setXeroContactsLoading } from '../reducers'
import { apiGetXeroContacts } from '../api/xero.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchXeroContacts = (
  tenantId?: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!tenantId) return

    const loading = getState().payroll.xeroContactsLoading[tenantId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().payroll.xeroContacts[tenantId]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setXeroContactsLoading({ tenantId, loading: true }))
      const { result, status } = await apiGetXeroContacts(tenantId)
      if (status) {
        dispatch(replaceXeroContacts({ tenantId, data: result }))
      }
    } finally {
      dispatch(setXeroContactsLoading({ tenantId, loading: false }))
    }
  }
}
