import React, { ReactNode } from 'react'
import { notification, message as messenger } from 'antd'
import { Link } from '~/core-components/Link/Link'
import { Space } from '~/core-components/Space/Space'
import { ErrorData } from './request'

export const showError = (message: string, details?: ErrorData) => {
  if (details?.ErrorMessage || message) {
    let btn: ReactNode = null
    if (details) {
      btn = (
        <Link
          onClick={() => {
            navigator.clipboard.writeText(JSON.stringify(details))
            messenger.success('Copied')
          }}
        >
          <Space>
            <i className="fal fa-copy" />
            copy error details
          </Space>
        </Link>
      )
    }
    notification.error({ message: details?.ErrorMessage || message, btn, duration: 0 })
  }
}

export const showSuccess = (message: string, description?: React.ReactNode) => {
  notification.success({ message, description, duration: 5 })
}

export const showWarning = (message: string, description?: React.ReactNode) => {
  notification.warning({ message, description, duration: 15 })
}

export const showInfo = (message: string, description?: React.ReactNode) => {
  notification.info({ message, description, duration: 5 })
}
