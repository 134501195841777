import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { CardProps, List, Space } from '~/core-components'
import { CardBanner, Person } from '~/components'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { getBaseUrl } from '~/utils'
import { SSBirthdayState } from '../../../../types'
import { fetchBirthdays } from '../../../../actions'
import { selectTodayBirthdays, selectThisWeekBirthdays, selectNextWeekBirthdays } from '../../../../selectors'
import './Birthday.less'

interface BirthdayProps extends CardProps {}

const baseUrl = getBaseUrl('/filestore')

export const Birthday: FC<BirthdayProps> = ({ className, ...props }) => {
  const classes = classNames('birthday', { [`${className}`]: className })
  const loading = useSelector((state: StoreState) => state.my.birthdaysLoading)

  const today = useSelector(selectTodayBirthdays)
  const thisWeek = useSelector(selectThisWeekBirthdays)
  const nextWeek = useSelector(selectNextWeekBirthdays)

  useEffect(() => {
    dispatch(fetchBirthdays({ strategy: 'when-empty' }))
  }, [])

  return (
    <CardBanner title="Birthdays" image="/birthday-background.jpeg" className={classes} loading={loading} {...props}>
      {today.length === 0 && thisWeek.length === 0 && nextWeek.length === 0 && !loading && (
        <>No upcoming birthday until next week</>
      )}
      {today.length > 0 && (
        <>
          <div className="subheader">Today</div>
          <List
            size="small"
            dataSource={today}
            renderItem={(item: SSBirthdayState) => (
              <List.Item key={item.id}>
                <Space>
                  <Person
                    size={20}
                    name={item.name}
                    photo={item.photoId && `${baseUrl}/publicfile/${item.photoId}/thumbnailphoto/20`}
                    // photo={item.id && `/employee/employee/${item.id}/avatar/20?photo_id=${item.photoId}`}
                  />
                </Space>
              </List.Item>
            )}
          />
        </>
      )}
      {thisWeek.length > 0 && (
        <>
          <div className="subheader">This week</div>
          <List
            size="small"
            dataSource={thisWeek}
            renderItem={(item: SSBirthdayState) => (
              <List.Item key={item.id}>
                <Space>
                  <Person
                    size={20}
                    name={item.name}
                    photo={item.photoId && `${baseUrl}/publicfile/${item.photoId}/thumbnailphoto/20`}
                    // photo={item.id && `/employee/employee/${item.id}/avatar/20?photo_id=${item.photoId}`}
                  />
                </Space>
              </List.Item>
            )}
          />
        </>
      )}
      {nextWeek.length > 0 && (
        <>
          <div className="subheader">Next week</div>
          <List
            size="small"
            dataSource={nextWeek}
            renderItem={(item: SSBirthdayState) => (
              <List.Item key={item.id}>
                <Space>
                  <Person
                    size={20}
                    name={item.name}
                    photo={item.photoId && `${baseUrl}/publicfile/${item.photoId}/thumbnailphoto/20`}
                    // photo={item.id && `/employee/employee/${item.id}/avatar/20?photo_id=${item.photoId}`}
                  />
                </Space>
              </List.Item>
            )}
          />
        </>
      )}
    </CardBanner>
  )
}
