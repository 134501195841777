import React, { useCallback, useState } from 'react'
import omit from 'lodash/omit'
import confirm from 'antd/lib/modal/confirm'
import { Col, KeyValues, Row } from '~/components'
import { ReportTemplateName } from '~/constants'
import { Button, Form, SelectProps, Tooltip } from '~/core-components'
import { dispatch } from '~/stores/store'
import { BasePath } from '~/types/common'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import { IReportTemplateSaveAs, ReportCode, ReportTemplateState } from '../../types'
import { SaveTemplateModal } from './components/SaveTemplateModal'
import { useReportTemplate } from '../../hooks'
import { deleteReportTemplate } from '../../actions/delete-report-template'
import './ReportTemplate.less'

export interface ReportTemplateProps<TTemplateData extends unknown> extends SelectProps {
  basePath: BasePath
  reportCode: ReportCode
  templateId?: string
  templateData: TTemplateData
  onChange: (templateId?: string) => void
  size?: SizeType
}

interface TemplateModalState {
  visible: boolean
  data?: IReportTemplateSaveAs
}

const DEFAULT_TEMPLATE_MODAL_STATE: TemplateModalState = { visible: false }

export const ReportTemplate = <TTemplateData extends unknown>({
  basePath,
  reportCode,
  templateId,
  templateData,
  size,
  onChange
}: ReportTemplateProps<TTemplateData>) => {
  const [templateModalState, setTemplateModalState] = useState<TemplateModalState>(DEFAULT_TEMPLATE_MODAL_STATE)
  const [template] = useReportTemplate(basePath, reportCode, templateId || '')

  const handleTemplateSave = useCallback(() => {
    if (template) {
      const data: IReportTemplateSaveAs = {
        templateId: template.id,
        templateName: template.templateName,
        templateDataJsonb: templateData ? JSON.stringify(omit(templateData as any, 'templateId')) : '{}'
      }

      setTemplateModalState({ visible: true, data })
    }
  }, [template, templateData])

  const handleTemplateDelete = useCallback(() => {
    if (template) {
      confirm({
        title: `Delete template`,
        content: `Do you want to delete template "${template.templateName}"?`,
        onOk: async () => {
          await dispatch(deleteReportTemplate(basePath, reportCode, template.id))
          typeof onChange === 'function' && onChange(undefined)
        },
        okText: 'Delete',
        okType: 'danger'
      })
    }
  }, [template, basePath, reportCode, onChange])

  const handleSavedTemplate = useCallback(
    (template?: ReportTemplateState) => {
      if (template?.id) typeof onChange === 'function' && onChange(template.id)
    },
    [onChange]
  )

  const handleCloseTemplateModal = useCallback(() => {
    setTemplateModalState(DEFAULT_TEMPLATE_MODAL_STATE)
  }, [])

  return (
    <div className="report-template">
      <Form layout="horizontal" colon={false}>
        <Row align="bottom">
          <Col>
            <Form.Item label="Template">
              <KeyValues
                basePath={basePath}
                id="reporttemplate"
                queryParams={{ reportCode }}
                value={templateId}
                allowClear={false}
                size={size}
                onChange={onChange}
                placeholder="Default"
              />
            </Form.Item>
          </Col>
          <Col>
            <Button
              className="report-template__delete"
              icon={
                <Tooltip title="Delete template">
                  <i className="fal fa-trash" />
                </Tooltip>
              }
              hidden={templateId ? template?.templateName === ReportTemplateName.default : true}
              onClick={handleTemplateDelete}
              size={size}
            />
            <Button
              className="report-template__save"
              icon={
                <Tooltip title="Save as new template">
                  <i className="fal fa-copy" />
                </Tooltip>
              }
              hidden={templateId ? false : true}
              onClick={handleTemplateSave}
              size={size}
            />
          </Col>
        </Row>
      </Form>
      <SaveTemplateModal
        {...templateModalState}
        basePath={basePath}
        reportCode={reportCode}
        onSuccess={handleSavedTemplate}
        onClose={handleCloseTemplateModal}
      />
    </div>
  )
}
