import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { Button, Card, ColumnsType, PageHeader, SecondaryText, Table } from '~/core-components'
import { DocumentTitle } from '~/components'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { useQuery } from '~/hooks/use-query'
import { StoreState } from '~/types/store'
import { AddCompanyDrawer } from './components/AddCompanyDrawer'
import { fetchCompanies, fetchCompaniesExtra } from '../../actions'
import { CompanyState } from '../../types'
import { selectCompaniesOverview } from '../../selectors'
import './Companies.less'

type CompanyTable = CompanyState & {
  companies: { name: string; isMain: boolean }[]
  activeEmployees: number
}

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: '',
    breadcrumbName: 'Overview'
  }
]

interface DrawerState {
  visible: boolean
}
const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

const labelRow1Style: CSSProperties = { lineHeight: 1.1 }
const labelRow2Style: CSSProperties = { fontSize: 12 }

export const Companies: FC = () => {
  const history = useHistory()
  const query = useQuery()
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const companies = useSelector(selectCompaniesOverview)()
  const loading = useSelector((state: StoreState) => state.master.companiesLoading)
  const canAdd = usePermissionGate(Permission.company, PermissionAction.Add)

  useEffect(() => {
    dispatch(fetchCompanies())
    dispatch(fetchCompaniesExtra())
  }, [])

  useEffect(() => {
    if (companies.length === 1 && !query.has('force')) {
      history.push(SETTINGS_ROUTES.company.replace(':id', companies[0].id))
    }
  }, [history, companies, query])

  const handleAddCompany = useCallback(() => {
    setDrawerState({ visible: true })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const columns: ColumnsType<CompanyTable> = [
    {
      title: 'Company',
      key: 'name',
      dataIndex: 'name',
      render: (value: string, record) => (
        <>
          <div style={labelRow1Style}>
            <Link to={SETTINGS_ROUTES.company.replace(':id', record.id)}>{value}</Link>
          </div>
          <SecondaryText style={labelRow2Style}>{record.registrationNo}</SecondaryText>
        </>
      )
    },
    {
      title: 'Active employees',
      key: 'activeEmployees',
      dataIndex: 'activeEmployees',
      align: 'center',
      width: '30%'
    }
  ]

  return (
    <div id="companies" className="companies">
      <DocumentTitle title="Companies" />
      <PageHeader
        title="Companies"
        containerId="companies"
        breadcrumb={{ routes }}
        extra={
          canAdd && (
            <Button key="add" onClick={handleAddCompany}>
              Add company
            </Button>
          )
        }
      />
      <div className="companies__body">
        <Card table>
          <Table rowKey="id" dataSource={companies} pagination={false} columns={columns} loading={loading}></Table>
        </Card>
        {canAdd && <AddCompanyDrawer {...drawerState} onClose={handleCloseDrawer} />}
      </div>
    </div>
  )
}
