import React, { FC, useCallback, useState } from 'react'
import confirm from 'antd/lib/modal/confirm'
import { Col, EmpKeyValues, Row } from '~/components'
import { Button, Form, SelectProps, Tooltip } from '~/core-components'
import { View } from '~/constants'
import { dispatch } from '~/stores/store'
import { useViewSchema } from '../hooks'
import { IViewSchemaSaveAs, Screen, ViewSchemaState } from '../types'
import { deleteViewSchema } from '../actions'
import { SaveViewModal } from './SaveViewModal'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import './SelectView.less'

export interface SelectViewProps extends SelectProps {
  screenCode: Screen
  viewId?: string
  onChange: (viewId?: string) => void
  size?: SizeType
}

interface ViewModalState {
  visible: boolean
  viewId: string
  data?: IViewSchemaSaveAs
}

const DEFAULT_VIEW_MODAL_STATE: ViewModalState = { visible: false, viewId: '' }

export const SelectView: FC<SelectViewProps> = ({ screenCode, viewId, size, onChange, ...props }) => {
  const [viewModalState, setViewModalState] = useState<ViewModalState>(DEFAULT_VIEW_MODAL_STATE)
  const [schema] = useViewSchema(screenCode, viewId || '')

  const handleViewSave = useCallback(() => {
    if (schema) {
      const data: IViewSchemaSaveAs = { viewId: schema.id, viewName: schema.viewName }
      setViewModalState({ visible: true, viewId: schema.id, data })
    }
  }, [schema])

  const handleViewDelete = useCallback(() => {
    if (schema) {
      confirm({
        title: `Delete view`,
        content: `Do you want to delete view "${schema.viewName}"?`,
        onOk: async () => {
          await dispatch(deleteViewSchema(screenCode, schema.id))
          typeof onChange === 'function' && onChange(undefined)
        },
        okText: 'Delete',
        okType: 'danger'
      })
    }
  }, [schema, screenCode, onChange])

  const handleSavedView = useCallback(
    (view?: ViewSchemaState) => {
      if (view?.id) typeof onChange === 'function' && onChange(view.id)
    },
    [onChange]
  )

  const handleCloseViewModal = useCallback(() => {
    setViewModalState(DEFAULT_VIEW_MODAL_STATE)
  }, [])

  return (
    <div className="select-view">
      <Form layout="horizontal" colon={false}>
        <Row align="bottom">
          <Col>
            <Form.Item label="Current view">
              <EmpKeyValues
                id="view"
                queryParams={{ screenCode }}
                value={viewId}
                allowClear={false}
                size={size}
                onChange={onChange}
                {...props}
              />
            </Form.Item>
          </Col>
          <Col>
            <Button
              className="select-view__delete"
              icon={
                <Tooltip title="Delete view">
                  <i className="fal fa-trash" />
                </Tooltip>
              }
              hidden={viewId ? schema?.viewName === View.default : false}
              onClick={handleViewDelete}
              size={size}
            />
            <Button
              className="select-view__save"
              icon={
                <Tooltip title="Save as new view" placement="topRight">
                  <i className="fal fa-copy" />
                </Tooltip>
              }
              onClick={handleViewSave}
              size={size}
            />
          </Col>
        </Row>
      </Form>
      <SaveViewModal
        {...viewModalState}
        screenCode={screenCode}
        onSuccess={handleSavedView}
        onClose={handleCloseViewModal}
      />
    </div>
  )
}
