import React, { FC, useEffect } from 'react'
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom'
import { fetchPayItems } from '~/features/master'
import { PAY_PATH, PAY_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { PayRun } from './PayRun/PayRun'
import { PayRecord } from './PayRecord/PayRecord'
import { PayOverview } from './PayOverview/PayOverview'
import './PayrollLayout.less'

const routeList: RouteProps[] = [
  {
    path: PAY_ROUTES.payRecord,
    component: PayRecord,
    exact: true
  },
  {
    path: PAY_ROUTES.payRun,
    component: PayRun,
    exact: true
  },
  {
    path: PAY_ROUTES.tab,
    component: PayOverview,
    exact: true
  }
]

export const PayrollLayout: FC = () => {
  // Pre-fetch
  useEffect(() => {
    dispatch(fetchPayItems('sg'))
  }, [])

  return (
    <div className="payroll-layout">
      <Switch>
        <Redirect exact from={PAY_PATH} to={PAY_ROUTES.tab.replace(':tab?', 'recent')} />
        {routeList.map(route => (
          <Route key={route.path as string} {...route} />
        ))}
      </Switch>
    </div>
  )
}
