import { setSysCriteriaFields, setSysCriteriaFieldsLoading } from '../reducers'
import { apiGetCriteriaFields } from '../api/sys-criteria.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'
import { Screen } from '../types'

export const fetchSysCriteriaFields = (
  screenCode: Screen,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!screenCode) return

    const loading = getState().selection.sysCriteriaFieldsLoading[screenCode]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const criteriaScreens = Object.values(getState().selection.sysCriteriaScreens[screenCode]?.entities || {})
      const criteriaCodes = criteriaScreens.map(s => s?.criteriaCode)

      const data = Object.values(getState().selection.sysCriteriaFields[screenCode]?.entities || {})
      const hasData = data?.some(f => criteriaCodes.includes(f?.criteriaCode))
      if (hasData) return
    }

    try {
      dispatch(setSysCriteriaFieldsLoading({ screenCode, loading: true }))
      const { result, status } = await apiGetCriteriaFields(screenCode)
      if (status) {
        dispatch(setSysCriteriaFields({ screenCode, data: result }))
      }
    } finally {
      dispatch(setSysCriteriaFieldsLoading({ screenCode, loading: false }))
    }
  }
}
