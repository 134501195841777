import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchLeaveRecordComments } from '../../actions'
import { ILeaveRecordComment, selectMyLeaveRecordComments } from '../../selectors'

export const useMyLeaveRecordComments = (
  leaveRecordId?: string,
  fetchStrategy: FetchStrategy = 'always'
): [ILeaveRecordComment[], boolean] => {
  const comments = useSelector(selectMyLeaveRecordComments)(leaveRecordId || '') as ILeaveRecordComment[]
  const loading = useSelector((state: StoreState) => state.my.myLeaveRecordCommentsLoading[leaveRecordId || ''])

  useEffect(() => {
    if (leaveRecordId) {
      dispatch(fetchLeaveRecordComments(leaveRecordId, { strategy: fetchStrategy }))
    }
  }, [leaveRecordId, fetchStrategy])

  return [comments, loading]
}
