import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchLogin } from '../actions'
import { selectLoginById } from '../reducers'
import { LoginState } from '../types'

const tenant = localStorage.getItem('tenant')

export const useLogin = (
  id: string,
  fetchStrategy: FetchStrategy = 'when-empty'
): [LoginState | undefined, boolean] => {
  const login = useSelector((state: StoreState) => selectLoginById(state, id))
  const loading = useSelector((state: StoreState) => state.iam.loginsLoading)

  useEffect(() => {
    if (tenant && id) {
      dispatch(fetchLogin(tenant, id, { strategy: fetchStrategy }))
    }
  }, [id, fetchStrategy])

  return [login, loading]
}
