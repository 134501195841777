import { showError, showSuccess } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiPublishAllPayRecords } from '../api/payrecord-sg.api'

export const publishAllPayRecords = (payRunId: string): ThunkResult<void> => {
  return async () => {
    if (!payRunId) return

    const { result, status, errors, message, errorData } = await apiPublishAllPayRecords(payRunId)
    if (status) {
      showSuccess('Published')
      if (result.emailStatus === 'error' || result.emailMessage !== '') {
        showError(result.emailMessage)
      }
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
