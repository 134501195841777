import { EntityState } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { RuleState } from './rule.state'
import { SysRuleCriteriaState } from './sys-rule-criteria.state'

export const rulePersistConfig = {
  key: 'rule',
  whitelist: ['rules', 'sysRuleCriteria'],
  storage
}

export interface RuleRootState {
  rules: { [referenceId: string]: EntityState<RuleState> }
  rulesLoading: { [referenceId: string]: boolean }
  sysRuleCriteria: EntityState<SysRuleCriteriaState>
  sysRuleCriteriaLoading: boolean
}
