import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { LeaveTypeState } from '../types'
import { PayEncashMapState } from '~/features/master'
import { isInactive } from '~/utils'
import { LveSysLeaveType } from '~/constants'

export const selectLeaveEncashLeaveTypes = createSelector(
  (state: StoreState) => state.leave.leaveTypes,
  (state: StoreState) => state.master.payEncashMaps,
  (leaveTypesState, payEncashMapsState) =>
    memoize((payGroupId: string) => {
      if (!payGroupId) return []

      const payEncashMaps = Object.values(payEncashMapsState[payGroupId]?.entities || {}) as PayEncashMapState[]
      const leaveTypes = Object.values(leaveTypesState?.entities || {}) as LeaveTypeState[]
      return leaveTypes
        .filter(
          lt =>
            (!isInactive(lt?.inactiveDate) || payEncashMaps.filter(pem => pem.leaveTypeId === lt?.id).length > 0) &&
            lt?.sysLeaveTypeCode === LveSysLeaveType.anl
        )
        .sort((a, b) => {
          const aInactiveDate = new Date(a!.inactiveDate || '').getTime() || 0
          const bInactiveDate = new Date(b!.inactiveDate || '').getTime() || 0

          if (bInactiveDate === 0 && aInactiveDate === 0) {
            return a!.name.localeCompare(b!.name)
          } else {
            return aInactiveDate - bInactiveDate
          }
        })
    })
)
