import React, { FC, useState, useCallback, useEffect, CSSProperties, useMemo } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { LoadingOutlined } from '@ant-design/icons'
import confirm from 'antd/lib/modal/confirm'
import { Form, Spin, Text } from '~/core-components'
import { Col, DrawerForm, EmSelect, Row } from '~/components'
import { ViewCriteria, ViewCriteriaAdvanced, useViewSchemaByName } from '~/features/selection'
import { config } from '../../config'
import { dispatch } from '~/stores/store'
import { useFocus } from '~/hooks/use-focus'
import { apiGetEmSelect } from '~/features/employee/api/employee.api'
import { ActionResult, Errors } from '~/types/store'
import { ManagerCategory, ManagerState, IManager } from '../../types'
import { deleteManager, saveManager } from '../../actions'
import './MutateManagerDrawer.less'

export interface MutateManagerDrawerProps {
  category: ManagerCategory
  visible: boolean
  data?: ManagerState
  onClose: () => void
}

const EMPTY_FORM_DATA: IManager = {
  id: '',
  category: '',
  managerId: '',
  viewId: '',
  criteria: []
}

const descriptionStyle: CSSProperties = { display: 'block', fontSize: 13 }

export const MutateManagerDrawer: FC<MutateManagerDrawerProps> = ({ category, visible, data, onClose }) => {
  const [saving, setSaving] = useState(false)
  const [formData, setFormData] = useState<IManager>(EMPTY_FORM_DATA)
  const [focusRef, setFocus] = useFocus(true)
  const [errors, setErrors] = useState<Errors>()

  const screen = config[category].screen
  const viewName = formData?.id ? `${screen}_${formData.id}` : undefined
  const [view, viewLoading] = useViewSchemaByName(screen, viewName, false)

  const criteriaDesc = useMemo(() => {
    if (category === 'att') {
      return 'attendance'
    }
  }, [category])

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  useDeepCompareEffect(() => {
    if (data) {
      const { id, category, managerId } = data

      setFormData({
        id,
        category,
        managerId,
        viewId: view?.id,
        criteria: view?.criteria || []
      })
    } else {
      setFormData({ ...EMPTY_FORM_DATA, viewId: view?.id })
    }
  }, [data || {}, view || {}])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setErrors(undefined)
    setFormData(data => ({ ...data, ...updates }))
  }, [])

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setSaving(true)
    try {
      result = await dispatch(saveManager(screen, category, formData))
    } finally {
      setSaving(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_FORM_DATA)
    }
  }, [category, screen, formData, onClose])

  const handleDelete = useCallback(
    (manager: ManagerState | undefined) => {
      if (manager) {
        const { id, managerName } = manager
        confirm({
          title: 'Delete reporting manager',
          content: `Do you want to delete this reporting manager "${managerName}"?`,
          onOk: async () => {
            const result: ActionResult | undefined = await dispatch(deleteManager(category, id))
            if (result?.errors) {
              setErrors(result.errors)
            }
            if (!result?.errors) {
              typeof onClose === 'function' && onClose()
            }
          },
          okText: 'Delete',
          okType: 'danger'
        })
      }
    },
    [onClose, category]
  )

  const handleFetchEmployees = useCallback(async () => {
    const { status, result } = await apiGetEmSelect('past3mth')
    if (status) {
      return result
    }
    return []
  }, [])

  return (
    <DrawerForm
      open={visible}
      title={data ? `Edit reporting manager` : `Add reporting manager`}
      onClose={onClose}
      confirmLoading={saving}
      width={800}
      className="mutate-manager-drawer"
      showDelete={data ? true : false}
      onDelete={() => handleDelete(data)}
      formId={`form-${category}-manager`}
    >
      <Form id={`form-${category}-manager`} onFinish={handleOk}>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Reporting manager"
              validateStatus={errors?.managerId ? 'error' : ''}
              help={errors?.managerId}
            >
              <EmSelect
                ref={focusRef}
                value={formData.managerId}
                onFetch={handleFetchEmployees}
                onChange={(managerId: string) => handleFormDataChange({ managerId })}
              />
            </Form.Item>
          </Col>
        </Row>
        {viewLoading ? (
          <Spin indicator={<LoadingOutlined spin />} />
        ) : (
          <>
            <Row>
              <Col span={24}>
                <div className="mutate-manager-drawer__section">Criteria</div>
              </Col>
              <Col span={24} style={{ marginBottom: 10 }}>
                <Text type="secondary" style={descriptionStyle}>
                  The reporting manager will be able to access the {criteriaDesc} records of employees who meet the
                  specified criteria below.
                </Text>
              </Col>
              <Col span={24}>
                <ViewCriteriaAdvanced
                  label=""
                  screenCode={screen}
                  viewId={formData.viewId}
                  onChange={(criteria: ViewCriteria[]) => handleFormDataChange({ criteria })}
                />
              </Col>
            </Row>
          </>
        )}
      </Form>
    </DrawerForm>
  )
}
