import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import { Form, Input, Modal } from '~/core-components'
import { Col, ErrorDisplay, Row } from '~/components'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors } from '~/types/store'
import { useFocus } from '~/hooks/use-focus'
import { ITimeLogColumnMap, ISaveTimeLogTemplate } from '../../../../../types'
import { saveTimeLogTemplate } from '../../../../../actions'

interface SaveTimeLogTemplateModalProps {
  visible: boolean
  data?: ITimeLogColumnMap[]
  onSuccess?: (id?: string) => void
  onClose: () => void
}

const EMPTY_FORM_DATA: ISaveTimeLogTemplate = {
  id: '',
  name: '',
  fields: []
}

export const SaveTimeLogTemplateModal: FC<SaveTimeLogTemplateModalProps> = ({ visible, data, onSuccess, onClose }) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<ISaveTimeLogTemplate>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const [focusRef, setFocus] = useFocus(true)

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  useEffect(() => {
    if (data) {
      setFormData(formData => ({ ...formData, fields: data }))
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      setFormData({ ...formData, ...updates })
    },
    [formData]
  )

  const handleSave = useCallback(async () => {
    setLoading(true)

    let result: ActionResult | undefined
    try {
      result = await dispatch(saveTimeLogTemplate(formData))
      setErrors(undefined)
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onSuccess === 'function' && onSuccess(result?.result?.id)
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_FORM_DATA)
    }
  }, [formData, onSuccess, onClose])

  return (
    <Modal
      open={visible}
      title="Save as new template"
      onCancel={onClose}
      width={300}
      confirmLoading={loading}
      className="save-time-log-template-modal"
      formId="form-save-time-log-template"
    >
      <Form id="form-save-time-log-template" onFinish={handleSave}>
        <ErrorDisplay errors={errors} />
        <Row>
          <Col span={24}>
            <Form.Item label="Specify the template name">
              <Input
                ref={focusRef}
                value={formData.name}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ name: event.target.value })}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
