import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { Screen, ViewState } from '../types'

const PUBLIC_VIEWS: Screen[] = [
  'claim_approval_workflow',
  'claim_type_policy',
  'em_person',
  'em_profile',
  'em_public',
  'em_payslip',
  'leave_approval_workflow',
  'lve_record_calendar_viewer',
  'pay_payslip',
  'permission_emp',
  'permission_pay',
  'permission_lve',
  'permission_cla',
  'ss_access_myprofile',
  'ss_access_mypayroll',
  'ss_access_myleave',
  'ss_access_myclaim',
  'ss_access_myattendance',
  'ss_access_myschedule'
]

export const selectFirstOwnedView = createSelector(
  (state: StoreState) => state.selection.views,
  (state: StoreState) => state.iam.myJwt,
  (viewsState, myJwtState) =>
    memoize((screenCode: Screen): ViewState | undefined => {
      const views = Object.values(viewsState[screenCode]?.entities || {}) as ViewState[]
      if (views.length > 0) {
        const owned = views.filter(v => PUBLIC_VIEWS.includes(screenCode) || v.ownerId === myJwtState?.loginId)
        if (owned.length > 0) {
          return owned[0]
        }
      }
    })
)
