import React, { FC, useCallback, useState } from 'react'
import { Button, Form, Space, Switch, Tag, UploadFile } from '~/core-components'
import { Col, ErrorDisplay, Row, UploadFileSelectorAuth } from '~/components'
import { Errors } from '~/types/store'
import { ImportPayExcelFormData } from '../ImportPayrollExcelDrawer'
import { apiGetPayImportTemplateExcel } from '~/features/payroll/api/payimport.api'
import { downloadWithDom, showError } from '~/utils'
import { useIsMountedRef } from '~/hooks/use-is-mounted-ref'
import './UploadPayExcel.less'

interface UploadPayExcelProps {
  visible: boolean
  data: Partial<ImportPayExcelFormData>
  errors?: Errors
  onChange: (data: Partial<ImportPayExcelFormData>) => void
}

const ACCEPT = '.xls,.xlsx,.csv'

export const UploadPayExcel: FC<UploadPayExcelProps> = ({ visible, data, errors, onChange }) => {
  const isMountedRef = useIsMountedRef()
  const [templateDownloading, setTemplateDownloading] = useState(false)

  const handleFileChange = useCallback(
    (files?: UploadFile[]) => {
      if (files && files.length > 0) {
        typeof onChange === 'function' && onChange({ file: files[0] })
      }
    },
    [onChange]
  )

  const handleFileRemove = useCallback(() => {
    typeof onChange === 'function' && onChange({ file: undefined })
  }, [onChange])

  const handleDownloadTemplate = useCallback(async () => {
    try {
      setTemplateDownloading(true)
      const { status, result, errors, message, errorData } = await apiGetPayImportTemplateExcel({
        byDates: data.byDates || false
      })

      if (status) {
        const fileName = `upload_payroll_item_template.xlsx`
        downloadWithDom(result, fileName)
      } else {
        console.error('Error while downloading', errors)
        showError(message, errorData)
      }
    } finally {
      if (isMountedRef.current) setTemplateDownloading(false)
    }
  }, [data.byDates, isMountedRef])

  return (
    <div className="upload-pay-excel" hidden={!visible}>
      <Row>
        <Col span={24}>
          <Form.Item className="entry-type">
            <label>Add by occurrence date</label>
            <Switch
              checkedChildren="Yes"
              unCheckedChildren="No"
              checked={data.byDates}
              onChange={(checked: boolean) => {
                typeof onChange === 'function' && onChange({ byDates: checked })
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="upload-pay-excel__legends">
          <div>
            Mandatory:{' '}
            <Space>
              <Tag>EmployeeNo</Tag>
              <Tag>PayItemName</Tag>
              {data.byDates ? <Tag>Date</Tag> : null}
              <Tag>Quantity</Tag>
            </Space>
          </div>
          <div>
            Optional:{' '}
            <Space>
              <Tag>EmployeeName</Tag>
              <Tag>Notes</Tag>
            </Space>
          </div>
          <div>
            Supported file type:{' '}
            <Space>
              <Tag>.xls</Tag>
              <Tag>.xlsx</Tag>
              <Tag>.csv</Tag>
            </Space>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item label="Payroll item template">
            <Button onClick={handleDownloadTemplate} loading={templateDownloading}>
              Download template
            </Button>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item label="File" validateStatus={errors?.file ? 'error' : ''} help={errors?.file}>
            <UploadFileSelectorAuth
              multiple={false}
              accept={ACCEPT}
              fileList={data.file ? [data.file] : []}
              onChange={handleFileChange}
              onRemove={handleFileRemove}
            >
              <Button size="small">choose file</Button>
            </UploadFileSelectorAuth>
          </Form.Item>
        </Col>
      </Row>
      <ErrorDisplay errors={errors} />
    </div>
  )
}
