import { replaceMasters, setMastersLoading } from '../reducers'
import { apiGetMasters } from '../api/master.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'
import { fetchCalendarPatterns } from './fetch-calendar-patterns'
import { MasterName } from '../types'

export const fetchCalendars = (
  masterName: MasterName = 'calendar',
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().master.mastersLoading['calendar']
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().master.masters['calendar']?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setMastersLoading({ masterName, loading: true }))
      const { result, status } = await apiGetMasters(masterName)
      if (status) {
        for (const master of result) {
          dispatch(fetchCalendarPatterns(master.id))
        }

        dispatch(replaceMasters({ masterName, data: result }))
      }
    } finally {
      dispatch(setMastersLoading({ masterName, loading: false }))
    }
  }
}
