import React, { FC, useState, useCallback, useEffect, CSSProperties } from 'react'
import moment from 'moment-timezone'
import confirm from 'antd/lib/modal/confirm'
import { Checkbox, CheckboxChangeEvent, Form, Input, Radio, RadioChangeEvent } from '~/core-components'
import { Col, DrawerForm, Row } from '~/components'
import { dispatch } from '~/stores/store'
import { CurrencyExchangeState, ICurrencyExchange } from '../../../types'
import { mapCurrencyExchangeStateToCurrencyExchange } from '../../../types/currency.mapper'
import { ActionResult, Errors } from '~/types/store'
import { useFocus } from '~/hooks/use-focus'
import { updateCurrencyExchange } from '~/features/claim/actions/update-currency-exchange'
import { addCurrencyExchange } from '~/features/claim/actions/add-currency-exchange'
import { refetchCurrencyExchangesView } from '~/features/claim/reducers'
import { deleteCurrencyExchange } from '~/features/claim/actions/delete-currency-exchange'
import { CurrencyKeyValues } from '~/components/KeyValues/CurrencyKeyValues'

export interface MutateCurrencyExchangeDrawerProps {
  visible: boolean
  data?: CurrencyExchangeState
  onClose: () => void
}

const TODAY = moment().format('YYYY-MM-DD')
const EMPTY_FORM_DATA: ICurrencyExchange = {
  sourceCurrencyCode: '',
  targetCurrencyCode: '',
  startDate: TODAY,
  rateType: 'manual',
  rate: 0,
  unit: 1,
  isAutoUpdate: false
}

const radioStyle: CSSProperties = { width: '100%', marginBottom: 10 }
const radioItemStyle: CSSProperties = { marginTop: 10, width: '100%' }
const radioInputStyle: CSSProperties = { margin: '0 10px', width: 200 }
// const radioButtonStyle: CSSProperties = { float: 'right' }

export const MutateCurrencyExchangeDrawer: FC<MutateCurrencyExchangeDrawerProps> = ({
  visible,
  data,
  onClose
}: MutateCurrencyExchangeDrawerProps) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<ICurrencyExchange>(EMPTY_FORM_DATA)
  const [focusRef, setFocus] = useFocus(true)
  const [errors, setErrors] = useState<Errors>()

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  useEffect(() => {
    if (data) {
      setFormData({
        sourceCurrencyCode: data.sourceCurrencyCode,
        targetCurrencyCode: data.targetCurrencyCode,
        startDate: data.startDate,
        rateType: data.rateType,
        rate: data.rate,
        unit: data.unit,
        isAutoUpdate: data.isAutoUpdate
      })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    if (updates.rateType) {
      if (updates.rateType === 'auto') {
        updates.isAutoUpdate = true
      } else {
        updates.isAutoUpdate = false
      }
    }

    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      if (data) {
        result = await dispatch(
          updateCurrencyExchange(data.id, mapCurrencyExchangeStateToCurrencyExchange(data), formData)
        )
      } else {
        result = await dispatch(addCurrencyExchange(formData))
      }

      dispatch(refetchCurrencyExchangesView())
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data, formData, onClose])

  const handleDelete = useCallback(
    (currencyExchange: CurrencyExchangeState | undefined) => {
      if (currencyExchange) {
        const { id } = currencyExchange
        confirm({
          title: 'Delete currency exchange',
          content: `Do you want to delete currency exchange?`,
          onOk: async () => {
            const result: ActionResult | undefined = await dispatch(deleteCurrencyExchange(id))
            if (result?.errors) {
              setErrors(result.errors)
            }

            if (!result?.errors) {
              dispatch(refetchCurrencyExchangesView())
              typeof onClose === 'function' && onClose()
            }
          },
          okText: 'Delete',
          okType: 'danger'
        })
      }
    },
    [onClose]
  )

  // const handleGetRate = useCallback(() => {}, [])

  return (
    <DrawerForm
      open={visible}
      title={data ? 'Edit currency exchange' : 'Add currency exchange'}
      onClose={onClose}
      confirmLoading={loading}
      width={500}
      showDelete={data ? true : false}
      onDelete={() => handleDelete(data)}
      formId="form-currency-exchange"
    >
      <Form id="form-currency-exchange" onFinish={handleOk}>
        <Row>
          <Col span={12}>
            <Form.Item
              label="Starting from"
              validateStatus={errors?.startDate ? 'error' : ''}
              help={errors?.startDate}
              rules={[{ required: true, message: 'Hire date is required' }]}
            >
              <Form.Item noStyle>
                <Input.Date
                  ref={focusRef}
                  value={formData.startDate ? moment(formData.startDate) : undefined}
                  onChange={(value: moment.Moment | null) => {
                    handleFormDataChange({ startDate: value?.format('YYYY-MM-DD') })
                  }}
                  disabledDate={current => current && current > moment().endOf('day')}
                />
              </Form.Item>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Source currency"
              validateStatus={errors?.sourceCurrencyCode ? 'error' : ''}
              help={errors?.sourceCurrencyCode}
            >
              <CurrencyKeyValues
                labelProp={kv => kv.key.toUpperCase()}
                optionLabelProp="label"
                value={formData.sourceCurrencyCode}
                onChange={(sourceCurrencyCode: string) => handleFormDataChange({ sourceCurrencyCode })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Target currency"
              validateStatus={errors?.targetCurrencyCode ? 'error' : ''}
              help={errors?.targetCurrencyCode}
            >
              <CurrencyKeyValues
                labelProp={kv => kv.key.toUpperCase()}
                optionLabelProp="label"
                value={formData.targetCurrencyCode}
                onChange={(targetCurrencyCode: string) => handleFormDataChange({ targetCurrencyCode })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row hidden={!formData.startDate || !formData.sourceCurrencyCode || !formData.targetCurrencyCode}>
          <Col span={24}>
            <Form.Item label="Exchange rate">
              <Radio.Group
                value={formData.rateType}
                onChange={(event: RadioChangeEvent) => {
                  handleFormDataChange({ rateType: event.target.value })
                }}
                style={radioStyle}
              >
                {/* Temporary hide <Radio value="auto" style={radioItemStyle}>
                  Source: Open exchange rates
                  <span style={radioButtonStyle}>
                    <Button disabled={formData.rateType !== 'auto'} size="small" onClick={handleGetRate}>
                      Get rate
                    </Button>
                  </span>
                </Radio>
                <div></div> */}
                <Radio value="manual" style={radioItemStyle}>
                  <span className="tx-uppercase">{`${formData.unit} ${formData.sourceCurrencyCode}`} = </span>
                  <Form.Item noStyle>
                    <Input.Number
                      style={radioInputStyle}
                      min={0}
                      formatter={value => `${value}`.replace(/(?<!\.\d*)(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')}
                      value={formData.rate}
                      disabled={formData.rateType !== 'manual'}
                      onChange={(value: number | null) => handleFormDataChange({ rate: value || 0 })}
                    />
                  </Form.Item>
                  <span className="tx-uppercase">{formData.targetCurrencyCode}</span>
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row hidden={data != null || formData.rateType !== 'auto'}>
          <Col span={24}>
            <Form.Item>
              <Checkbox
                checked={formData.isAutoUpdate}
                onChange={(event: CheckboxChangeEvent) => {
                  handleFormDataChange({ isAutoUpdate: event.target.checked })
                }}
              />
              Enable daily auto-update
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}
