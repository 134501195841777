import { CurrencyExchangeState, CurrencyState, ICurrency, ICurrencyExchange } from './currency.state'

export const mapCurrencyStateToCurrency = (currency: CurrencyState): ICurrency => ({
  code: currency.code,
  name: currency.name,
  isShown: currency.isShown
})

export const mapCurrencyExchangeStateToCurrencyExchange = (
  currencyExchange: CurrencyExchangeState
): ICurrencyExchange => ({
  sourceCurrencyCode: currencyExchange.sourceCurrencyCode,
  targetCurrencyCode: currencyExchange.targetCurrencyCode,
  startDate: currencyExchange.startDate,
  rateType: currencyExchange.rateType,
  rate: currencyExchange.rate,
  unit: currencyExchange.unit,
  isAutoUpdate: currencyExchange.isAutoUpdate
})
