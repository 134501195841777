import { createSelector } from '@reduxjs/toolkit'
import moment from 'moment-timezone'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'

export const selectCurrentShiftTe = createSelector(
  (state: StoreState) => state.attendance.shiftTes,
  shiftTesState =>
    memoize((shiftRoleId?: string) => {
      if (!shiftRoleId) return undefined

      return Object.values(shiftTesState[shiftRoleId]?.entities || {})
        .sort((a, b) => {
          const aStartDate = new Date(a!.startDate).getTime()
          const bStartDate = new Date(b!.startDate).getTime()
          return bStartDate - aStartDate
        })
        .find(a => moment(a?.startDate).isSameOrBefore(moment()))
    })
)

export const selectShiftTes = createSelector(
  (state: StoreState) => state.attendance.shiftTes,
  shiftTesState =>
    memoize((shiftRoleId?: string) => {
      if (!shiftRoleId) return []

      return Object.values(shiftTesState[shiftRoleId]?.entities || {}).sort((a, b) => {
        const aStartDate = new Date(a!.startDate).getTime()
        const bStartDate = new Date(b!.startDate).getTime()
        return bStartDate - aStartDate
      })
    })
)
