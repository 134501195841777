import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment-timezone'
import pick from 'lodash/pick'
import { Form, Input, Link } from '~/core-components'
import { Col, EditableCard, EditableCardState, ErrorDisplay, Row } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { Permission, PermissionAction, WorkStatus, WorkStatusType, emptyGuid } from '~/constants'
import { ActionResult, Errors } from '~/types/store'
import { dispatch } from '~/stores/store'
import { useCalendarPatterns } from '../../../hooks'
import { saveCalendarWithPatterns } from '../../../actions'
import { ICalendarAndPattern, WorkCalendarPatternState, WorkCalendarState } from '../../../types'
import { CalendarPatternRow, ICalendarPatternRow } from './CalendarPatternRow'
import './CalendarPattern.less'

interface CalendarPatternProps {
  calendar?: WorkCalendarState
  onEdit?: () => void
  onSave?: () => void
  onCancel?: () => void
}

interface FormData extends Omit<ICalendarAndPattern, 'id' | 'name' | 'inactiveDate' | 'patterns'> {
  patterns: ICalendarPatternRow[]
}

const EMPTY_FORM_DATA: FormData = { patternStartDate: '', patterns: [] }

export const CalendarPattern: FC<CalendarPatternProps> = ({ calendar, onEdit, onSave, onCancel }) => {
  const [cardState, setCardState] = useState<EditableCardState>()
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const canModify = usePermissionGate(Permission.calendar, PermissionAction.Modify)
  const readOnly = cardState !== 'editing' && cardState !== 'saving'
  const [patterns, loading] = useCalendarPatterns(calendar?.id || '')

  const maxSequence = useMemo(
    () => formData.patterns.reduce((acc, curr) => (acc > curr.sequence ? acc : curr.sequence), 0),
    [formData.patterns]
  )

  const NEW_PATTERN: WorkCalendarPatternState = useMemo(
    () => ({
      id: emptyGuid,
      sequence: maxSequence + 1,
      calendarId: calendar?.id || '',
      workStatus: ''
    }),
    [calendar, maxSequence]
  )

  const mapPatterns = (patterns: WorkCalendarPatternState[]) =>
    patterns.map(p => ({
      ...p,
      workStatusType: [WorkStatus.off, WorkStatus.rest].includes(p.workStatus)
        ? p.workStatus
        : WorkStatusType.WorkingDay
    }))

  useEffect(() => {
    if (calendar) {
      const { patternStartDate } = calendar
      setFormData({
        patternStartDate,
        patterns: mapPatterns(patterns)
      })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [calendar, patterns])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      setFormData(formData => {
        const updated = { ...formData, ...updates }
        if (
          'patternStartDate' in updates &&
          !formData.patternStartDate &&
          !('patterns' in updates) &&
          formData.patterns.length === 0
        ) {
          updated.patterns = [1, 2, 3, 4, 5, 6, 7].map(sequence => ({
            id: emptyGuid,
            sequence,
            calendarId: calendar?.id || '',
            workStatus: ''
          }))
        }

        if ('patterns' in updates) {
          updated.patterns = updated.patterns.map((p, index) => ({ ...p, sequence: index + 1 }))
        }

        return updated
      })
    },
    [calendar]
  )

  const handleEdit = useCallback(() => {
    setCardState('editing')
    typeof onEdit === 'function' && onEdit()
  }, [onEdit])

  const handleSave = useCallback(async () => {
    if (calendar) {
      setCardState('saving')
      setErrors(undefined)

      typeof onSave === 'function' && onSave()

      let result: ActionResult | undefined
      try {
        result = await dispatch(
          saveCalendarWithPatterns({ ...pick(calendar, 'id', 'name', 'inactiveDate'), ...formData })
        )
      } catch {
        setCardState('editing')
      }

      if (result?.errors) {
        setCardState('editing')
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setCardState(undefined)
      }
    }
  }, [calendar, formData, onSave])

  const handleCancel = useCallback(() => {
    typeof onCancel === 'function' && onCancel()
    setCardState(undefined)
    setErrors(undefined)

    if (calendar) {
      const { patternStartDate } = calendar
      setFormData({ patternStartDate, patterns: mapPatterns(patterns) })
    }
  }, [calendar, patterns, onCancel])

  return (
    <EditableCard
      title="Shift cycle"
      bodyStyle={{ paddingBottom: !readOnly ? 6 : 24, paddingTop: 6 }}
      state={!canModify ? 'readonly' : cardState}
      className="calendar-pattern"
      formId="form-calendar-pattern"
      onEdit={handleEdit}
      onSave={handleSave}
      onCancel={handleCancel}
      loading={loading}
    >
      <Row>
        <Col>
          <Form.Item
            label="Cycle start date"
            validateStatus={errors?.patternStartDate ? 'error' : ''}
            help={errors?.patternStartDate}
            tooltip={{
              title: `Calendar start date must be a Monday and precede the employee's work calendar effective date.`,
              icon: (
                <span>
                  <i className="fal fa-circle-info" />
                </span>
              )
            }}
          >
            <Input.Date
              allowClear={false}
              inputReadOnly={readOnly}
              value={formData.patternStartDate ? moment(formData.patternStartDate) : undefined}
              onChange={(value: moment.Moment | null) => {
                handleFormDataChange({ patternStartDate: value?.format('YYYY-MM-DD') })
              }}
              disabledDate={current => current && current.day() !== 1}
            />
          </Form.Item>
        </Col>
      </Row>
      <ErrorDisplay errors={errors} />
      {!!formData.patternStartDate && (
        <Row>
          <Col flex="1">
            <Row gutter={15} className="section-row">
              <Col flex="140px">
                <div className="section-row__title">Date</div>
              </Col>
              <Col flex="180px">
                <div className="section-row__title">Work status</div>
              </Col>
              <Col flex="1">
                <div className="section-row__title">Shift</div>
              </Col>
            </Row>
            {formData.patterns.map((p, index) => (
              <CalendarPatternRow
                key={index}
                patternStartDate={formData.patternStartDate}
                data={p}
                readOnly={readOnly}
                onChange={(updates: Partial<ICalendarPatternRow>) =>
                  handleFormDataChange({
                    patterns: [
                      ...formData.patterns.slice(0, index),
                      { ...formData.patterns[index], ...updates },
                      ...formData.patterns.slice(index + 1, formData.patterns.length)
                    ]
                  })
                }
                onDelete={() =>
                  handleFormDataChange({
                    patterns: [
                      ...formData.patterns.slice(0, index),
                      ...formData.patterns.slice(index + 1, formData.patterns.length)
                    ]
                  })
                }
              />
            ))}
            <Form.Item hidden={readOnly}>
              <Link
                onClick={() =>
                  handleFormDataChange({
                    patterns: [...formData.patterns, NEW_PATTERN]
                  })
                }
              >
                add more
              </Link>
            </Form.Item>
          </Col>
        </Row>
      )}
    </EditableCard>
  )
}
