import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { SelfUpdateConfigState } from '../types'
import { FetchStrategy } from '~/types/common'
import { selectSelfUpdateConfigs } from '../selectors'
import { fetchSelfUpdateConfigs } from '../actions'

export const useSelfUpdateConfigs = (fetchStrategy: FetchStrategy = 'always'): [SelfUpdateConfigState[], boolean] => {
  const data = useSelector(selectSelfUpdateConfigs)
  const loading = useSelector((state: StoreState) => state.master.selfUpdateConfigsLoading)

  useEffect(() => {
    dispatch(fetchSelfUpdateConfigs({ strategy: fetchStrategy }))
  }, [fetchStrategy])

  return [data, loading]
}
