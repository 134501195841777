import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { apiUpdateWorkflow } from '../api/workflow.api'
import { WorkflowState, IWorkflow, WfCategory } from '../types'
import { setWorkflow } from '../reducers'

export const updateWorkflow = <T extends IWorkflow>(
  category: WfCategory,
  id: string,
  before: T,
  request: T
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const after = { ...before, ...request } as T
    const patch = generateJsonPatch<T>(before, after)
    const { status, errors, message, errorData } = await apiUpdateWorkflow(category, id, patch)

    if (status) {
      const workflow = getState().workflow.workflows[category]?.entities[id]
      const updated = { ...(workflow as WorkflowState), ...after }
      dispatch(setWorkflow({ category, data: updated }))

      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
