import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { EmPreferenceState } from '../types'

export const selectEmPreference = createSelector(
  (state: StoreState) => state.employee.emPreference,
  emPreferenceState => (employeeId?: string) => {
    return emPreferenceState?.entities[employeeId || ''] || ({} as EmPreferenceState)
  }
)
