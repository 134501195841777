import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiDeletePayRecurringOverride } from '../api/payrecurring.api'
import { setPayRecurringOverride } from '../reducers'
import { PayRecurringOverrideState } from '../types'

export const deletePayRecurringOverride = (
  payRunId: string,
  employeeId: string,
  payItemId: string,
  payTranBatchId: string
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!payRunId || !employeeId || !payItemId) return

    const { result, status, errors, message, errorData } = await apiDeletePayRecurringOverride(
      payRunId,
      employeeId,
      payItemId,
      payTranBatchId
    )
    if (status) {
      const overrides = getState().payroll.payRecurringOverrides?.entities || {}

      let item = Object.values(overrides).find(
        item => item?.payRunId === payRunId && item.employeeId === employeeId && item.payItemId === payItemId
      ) as PayRecurringOverrideState

      item = { ...item, quantity: 0 }
      if (result?.id) {
        item = { ...item, id: result.id }
      }
      dispatch(setPayRecurringOverride(item))

      showSuccess('Deleted')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
