import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { TeConfigState } from '../types'

export const selectTeConfigsForShift = createSelector(
  (state: StoreState) => state.attendance.teConfigs,
  (teConfigState): TeConfigState[] => {
    const configs = Object.values(teConfigState?.entities || {}) as TeConfigState[]
    return configs
      .filter(a => a.isShownInShift)
      .sort((a, b) =>
        a.isShownInShift === b.isShownInShift ? a.code?.localeCompare(b.code) : a.isShownInShift ? -1 : 1
      )
  }
)
