import React, { FC, useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { useSelector } from 'react-redux'
import { LoadingOutlined, WarningOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import moment from 'moment-timezone'
import confirm from 'antd/lib/modal/confirm'
import { Button, Card, ColumnsType, Link, PageHeader, Space, Select, Table, Tooltip } from '~/core-components'
import { DocumentTitle, Person } from '~/components'
import { fetchEmployee } from '~/features/employee'
import { fetchPayItems } from '~/features/master'
import { usePermissionGate } from '~/features/iam/hooks'
import { emptyGuid, PayRunStatus, PayTranGroup, Permission, PermissionAction } from '~/constants'
import { formatMoney, getBaseUrl } from '~/utils'
import { PAY_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { ActionResult, StoreState } from '~/types/store'
import { PayRecordRow, PayRecordSgState } from '../../types'
import { selectPayRecord, selectPayRecordPayRuns, selectPayRecordRows } from '../../selectors'
import {
  deletePayRecord,
  fetchPayRecordSgPayRuns,
  fetchPayRecordSgViewItem,
  fetchPayRuns,
  fetchPayTransSg,
  processPayroll,
  unlockPayRecord,
  lockPayRecord
} from '../../actions'
import { isEditablePayTranGroup } from '../../util'
import { selectPayRunById } from '../../reducers'
import { usePayRecordEmployees } from '../../hooks'
import { PayTransByGroup } from './PayTransByGroup'
import { PayRecordNotes } from './PayRecordNotes'
import { PayRecordPayment } from './PayRecordPayment'
import { AddPayTranDrawer } from './AddPayTranDrawer'
import { EditPayTranAdjDrawer } from './EditPayTranAdjDrawer'
import { EmPayroll } from '../EmPayroll/EmPayroll'
import './PayRecord.less'

export interface PayRecordProps {}

interface PayRecordParams {
  id: string
  employeeId: string
}

interface AddPayTranDrawerState {
  visible: boolean
  payRecord?: PayRecordSgState
}

interface EditPayTranAdjDrawerState {
  visible: boolean
  payRunId: string
  employeeId: string
  originalAmount: number
  adjustmentRefCode: string
}

const TODAY = moment().format('YYYY-MM-DD')
const DEFAULT_ADD_PAY_TRAN_DRAWER_STATE: AddPayTranDrawerState = { visible: false }
const DEFAULT_EDIT_PAY_TRAN_ADJ_DRAWER_STATE: EditPayTranAdjDrawerState = {
  visible: false,
  payRunId: '',
  employeeId: '',
  originalAmount: 0,
  adjustmentRefCode: ''
}

const baseUrl = getBaseUrl('/filestore')

export const PayRecord: FC<PayRecordProps> = () => {
  const { id: payRunId, employeeId } = useParams<PayRecordParams>()

  const payRecord = useSelector(selectPayRecord)(payRunId, employeeId)
  const payRecordId = payRecord?.id
  const payRecordRows = useSelector(selectPayRecordRows)(payRunId, employeeId)
  const payRecordLoading = useSelector((state: StoreState) => state.payroll.payRecordsLoading[payRunId])
  const [employees, employeesLoading] = usePayRecordEmployees(payRunId)
  const processing = useSelector((state: StoreState) => state.payroll.payrollLoading)
  const payRuns = useSelector(selectPayRecordPayRuns)(employeeId)
  const payRunsLoading = useSelector((state: StoreState) => state.payroll.payRecordPayRunsLoading[employeeId])
  const payRun = useSelector((state: StoreState) => selectPayRunById(state, payRunId))
  const history = useHistory()
  const [locking, setLocking] = useState<string>()
  const [addPayTranDrawerState, setAddPayTranDrawerState] = useState<AddPayTranDrawerState>(
    DEFAULT_ADD_PAY_TRAN_DRAWER_STATE
  )
  const [editPayTranAdjDrawerState, setEditPayTranAdjDrawerState] = useState<EditPayTranAdjDrawerState>(
    DEFAULT_EDIT_PAY_TRAN_ADJ_DRAWER_STATE
  )
  const canModify = usePermissionGate(Permission.payRun, PermissionAction.Modify)

  const routes = [
    {
      path: PAY_ROUTES.tab.replace(':tab?', 'run'),
      breadcrumbName: 'Overview'
    },
    {
      path: PAY_ROUTES.payRun.replace(':id', payRunId).replace(':tab?', 'records'),
      breadcrumbName: 'Payroll run'
    },
    {
      path: '',
      breadcrumbName: 'Payroll record'
    }
  ]

  useEffect(() => {
    dispatch(fetchPayItems('sg', { strategy: 'when-empty' }))
    dispatch(fetchPayRuns({ strategy: 'when-empty' }))
  }, [])

  useEffect(() => {
    if (payRecordId) {
      dispatch(fetchPayTransSg(payRecordId, { strategy: 'when-empty' }))
    }
  }, [payRecordId])

  useEffect(() => {
    if (employeeId) {
      dispatch(fetchEmployee(employeeId, { strategy: 'when-empty' }))
      dispatch(fetchPayRecordSgPayRuns(employeeId))
    }
  }, [employeeId])

  useEffect(() => {
    if (!processing) {
      dispatch(fetchPayRecordSgViewItem(payRunId, employeeId))
    }
  }, [payRunId, employeeId, processing])

  useEffect(() => {
    if (!processing && payRecordId) {
      dispatch(fetchPayTransSg(payRecordId))
    }
  }, [payRecordId, processing])

  const handleCalculate = useCallback(() => {
    dispatch(processPayroll(payRunId, [employeeId]))
  }, [payRunId, employeeId])

  const handleRowExpand = useCallback((expanded: boolean, record: PayRecordRow) => {
    if (expanded) {
      dispatch(fetchPayTransSg(record.payRecordId, { strategy: 'when-empty' }))
    }
  }, [])

  const handleLock = useCallback(async () => {
    if (payRecord) {
      setLocking(payRecord.id)
      try {
        if (payRecord.lockedBy) {
          await dispatch(unlockPayRecord(payRecord.payRunId, payRecord.id))
        } else {
          await dispatch(lockPayRecord(payRecord.payRunId, payRecord.id))
        }
      } finally {
        setLocking(undefined)
      }
    }
  }, [payRecord])

  const handleAddPayTran = useCallback(() => {
    if (payRecord) {
      setAddPayTranDrawerState({ visible: true, payRecord })
    }
  }, [payRecord, setAddPayTranDrawerState])

  const handleCloseAddPayTranDrawer = useCallback(
    (action: 'save' | 'cancel') => {
      if (action === 'save') {
        dispatch(processPayroll(payRunId, [employeeId]))
      }
      setAddPayTranDrawerState(DEFAULT_ADD_PAY_TRAN_DRAWER_STATE)
    },
    [payRunId, employeeId]
  )

  const handleCloseEditPayTranAdjDrawer = useCallback(
    (action: 'save' | 'cancel') => {
      if (action === 'save') {
        dispatch(processPayroll(payRunId, [employeeId]))
      }
      setEditPayTranAdjDrawerState(DEFAULT_EDIT_PAY_TRAN_ADJ_DRAWER_STATE)
    },
    [payRunId, employeeId]
  )

  const handleEditClick = useCallback((record: PayRecordRow) => {
    const { payRunId, employeeId, adjustmentRefCode } = record
    if (adjustmentRefCode) {
      setEditPayTranAdjDrawerState({
        visible: true,
        payRunId,
        employeeId,
        originalAmount: record.amount,
        adjustmentRefCode: adjustmentRefCode
      })
    }
  }, [])

  const handleDeletePayRecord = useCallback(() => {
    if (!payRecord) return

    confirm({
      title: 'Delete payroll record',
      content: (
        <>
          <div>Do you want to delete "{payRecord.employeeName || ''}" payroll record?</div>
          <br />
          <div>This will also delete (if any) the following:</div>
          <div>&#9;- Adjustment</div>
          <div>&#9;- Import</div>
        </>
      ),
      onOk: async () => {
        const result: ActionResult | undefined = await dispatch(deletePayRecord(payRecord.payRunId, payRecord.id))
        if (!result?.errors) {
          history.push(PAY_ROUTES.payRun.replace(':id', payRecord.payRunId).replace(':tab?', 'records'))
        }
      },
      width: 550,
      okText: 'Delete',
      okType: 'danger'
    })
  }, [payRecord, history])

  const columns: ColumnsType<PayRecordRow> = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      className: 'content-bold'
    },
    {
      title: 'Amount',
      key: 'amount',
      dataIndex: 'amount',
      align: 'right',
      width: 150,
      className: 'content-bold',
      render: (value: number, record: PayRecordRow) => {
        return (
          <div
            className={classNames(
              { 'amount-negative': record.sign === '-' || (record.sign === '=' && record.amount < 0) },
              { 'amount-positive': record.sign === '+' || (record.sign === '=' && record.amount >= 0) }
            )}
          >
            {formatMoney(value, 2)}
          </div>
        )
      }
    },
    {
      key: 'group',
      dataIndex: 'sign',
      width: 20,
      render: (value: string, record: PayRecordRow) => {
        return (
          <div
            className={classNames(
              { 'amount-negative': record.sign === '-' || (record.sign === '=' && record.amount < 0) },
              { 'amount-positive': record.sign === '+' || (record.sign === '=' && record.amount >= 0) }
            )}
          >
            {value}
          </div>
        )
      }
    },
    {
      key: 'action',
      align: 'right',
      width: 50,
      render: (value: string, record: PayRecordRow) => {
        if (!canModify || payRun?.status === PayRunStatus.completed || !!payRecord?.lockedBy) {
          return
        }

        if (isEditablePayTranGroup(record.group)) {
          if (record.group === PayTranGroup.basicPay) {
            return
          }
          return (
            <Link size="small" onClick={() => handleEditClick(record)}>
              edit
            </Link>
          )
        }
      }
    }
  ]

  return (
    <div id="payrecord" className="payrecord">
      <DocumentTitle title="Payroll Record" />
      <PageHeader
        containerId="payrecord"
        title={
          <div className="payrecord__header">
            <Select
              className="payrecord__header-emselect"
              showSearch
              allowClear={false}
              loading={employeesLoading}
              optionFilterProp="title"
              onChange={(id: string) =>
                history.push(PAY_ROUTES.payRecord.replace(':id', payRunId).replace(':employeeId', id))
              }
              value={employeeId}
            >
              {employees?.map(em => (
                <Select.Option key={em.id} value={em.id} title={em.name}>
                  <Person
                    name={em.name}
                    description={em.description}
                    photo={
                      em.photoId && em.photoId !== emptyGuid
                        ? `${baseUrl}/file/${em.photoId}/thumbnailphoto/36`
                        : undefined
                    }
                  />
                </Select.Option>
              ))}
            </Select>

            {canModify && payRun?.status !== PayRunStatus.completed && (
              <Tooltip title={!!payRecord?.lockedBy ? 'Locked' : 'Unlocked'}>
                <Link
                  className={classNames('payrecord__lock', { 'payrecord__lock--locked': !!payRecord?.lockedBy })}
                  onClick={handleLock}
                >
                  {locking === payRecord?.id ? (
                    <LoadingOutlined />
                  ) : !!payRecord?.lockedBy ? (
                    <i className="fal fa-lock" />
                  ) : (
                    <i className="fal fa-lock-open" />
                  )}
                </Link>
              </Tooltip>
            )}
          </div>
        }
        extra={
          canModify &&
          payRun?.status !== PayRunStatus.completed &&
          !payRecord?.lockedBy && <Button onClick={handleDeletePayRecord}>Delete payroll record</Button>
        }
        breadcrumb={{ routes }}
      />

      <div className="payrecord__body">
        <div className="payrecord__body-left">
          <div className="payrecord__body-left-header">
            <Select
              showSearch
              allowClear={false}
              optionFilterProp="title"
              dropdownMatchSelectWidth={400}
              loading={payRunsLoading}
              value={payRunId}
              onChange={(value: string) =>
                history.push(PAY_ROUTES.payRecord.replace(':id', value).replace(':employeeId', employeeId))
              }
            >
              {payRuns?.map(run => (
                <Select.Option key={run.id} value={run.id} title={run.value}>
                  {run.value}
                </Select.Option>
              ))}
            </Select>
            {canModify && payRun?.status !== PayRunStatus.completed && !payRecord?.lockedBy && (
              <>
                <Button onClick={handleAddPayTran}>Add payroll item</Button>
                <AddPayTranDrawer {...addPayTranDrawerState} onClose={handleCloseAddPayTranDrawer} />
              </>
            )}
          </div>
          <Card table>
            <Table
              rowKey="group"
              dataSource={payRecordRows}
              loading={payRecordLoading}
              pagination={false}
              showHeader={false}
              columns={columns}
              expandable={{
                // expandRowByClick: true,
                rowExpandable: (record: PayRecordRow) => {
                  return record.trans.length > 0
                },
                expandedRowRender: (record: PayRecordRow) => (
                  <PayTransByGroup
                    payRunId={record.payRunId}
                    payRecordId={record.payRecordId}
                    group={record.group}
                    payTrans={record.trans}
                  />
                ),
                onExpand: handleRowExpand
              }}
            />
          </Card>
          <Space className="payrecord__body-left-footer">
            {canModify &&
              payRun?.status !== PayRunStatus.completed &&
              !payRecord?.lockedBy &&
              (payRecord?.isDirty ? (
                <Tooltip title="This payroll record requires recalculation">
                  <Button type="primary" onClick={handleCalculate} loading={processing} icon={<WarningOutlined />}>
                    Recalculate
                  </Button>
                </Tooltip>
              ) : (
                <Button onClick={handleCalculate} loading={processing}>
                  Recalculate
                </Button>
              ))}
            {/* <Button>Reconciliation report</Button>
              <PayrollReportButton payRunId={payRunId}>Payroll report</PayrollReportButton> */}
          </Space>
        </div>
        <div className="payrecord__body-right">
          <EmPayroll employeeId={employeeId} refreshDate={payRun?.endDate || TODAY} />
          <PayRecordPayment payRecord={payRecord} />
          <PayRecordNotes payRecord={payRecord} />
        </div>
      </div>
      {canModify && (
        <EditPayTranAdjDrawer id="" {...editPayTranAdjDrawerState} onClose={handleCloseEditPayTranAdjDrawer} />
      )}
    </div>
  )
}
