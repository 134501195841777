import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { FetchStrategy } from '~/types/common'
import { fetchShiftDays, fetchShiftDaysAggregated } from '../actions'
import { ShiftDayState } from '../types'
import { selectShiftDays, selectShiftDaysAggregated } from '../selectors'
import { fetchSysOptions } from '~/features/master'

export const useShiftDays = (
  shiftId: string,
  isAggregated: boolean = false,
  fetchStrategy: FetchStrategy = 'always'
): [ShiftDayState[], boolean] => {
  const refetch = useSelector((state: StoreState) => state.attendance.shiftDaysRefetch)

  const data = useSelector(selectShiftDays)(shiftId)
  const loading = useSelector((state: StoreState) => state.attendance.shiftDaysLoading[shiftId])

  const aggregated = useSelector(selectShiftDaysAggregated)(shiftId)
  const aggregatedLoading = useSelector((state: StoreState) => state.attendance.shiftDaysAggregatedLoading[shiftId])

  useEffect(() => {
    if (isAggregated) {
      dispatch(fetchShiftDaysAggregated(shiftId, { strategy: fetchStrategy }))
    } else {
      dispatch(fetchShiftDays(shiftId, { strategy: fetchStrategy }))
    }
    dispatch(fetchSysOptions('att_shift_day_code'))
  }, [refetch, shiftId, isAggregated, fetchStrategy])

  return [isAggregated ? aggregated : data, isAggregated ? aggregatedLoading : loading]
}
