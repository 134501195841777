import { showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiValidateClaimImport } from '../api/claim-import.api'
import { replaceClaimImportValidate } from '../reducers'

export const validateClaimImport = (payRunId: string, claimRecordIds: string[]): ThunkResult<void> => {
  return async dispatch => {
    const { status, result, errors, message, errorData } = await apiValidateClaimImport(payRunId, claimRecordIds)
    if (status) {
      dispatch(replaceClaimImportValidate(result))
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors, result }
  }
}
