import React, { FC } from 'react'
import { ClaCurrencyCode } from '~/constants'
import { ColumnsType, Drawer, Space, Table, Text } from '~/core-components'
import { useSysOptions } from '~/features/employee'
import { formatDateRange, formatMoney } from '~/utils'
import { useClaimType } from '../../hooks'
import { useCePolicies } from '../../hooks/use-ce-policies'
import { CePolicyState } from '../../types'
import { formatClaimLimit } from '../../util'
import './CePoliciesDrawer.less'

interface CePoliciesDrawerProps {
  visible: boolean
  periodCode?: string
  employeeId?: string
  claimTypeId?: string
  onClose: () => void
}

export const CePoliciesDrawer: FC<CePoliciesDrawerProps> = ({
  visible,
  periodCode,
  employeeId,
  claimTypeId,
  onClose
}) => {
  const [data, loading] = useCePolicies(periodCode, employeeId, claimTypeId)
  const [claimType] = useClaimType(claimTypeId || '')
  const [maxTimesBasis] = useSysOptions('cla_max_times_basis')
  const [prorationTypes] = useSysOptions('cla_proration_type')

  const columns: ColumnsType<CePolicyState> = [
    {
      title: 'Entitlement and limits',
      key: 'entitlement',
      dataIndex: 'entitlement',
      render: (entitlement: number, record) => {
        const limitText = formatClaimLimit(
          claimType?.currencyCode || ClaCurrencyCode.sgd,
          record.requireConfirm,
          record.requireMonths,
          record.monthlyLimit,
          record.dailyLimit,
          record.perTimeAmount,
          record.perTimePercentage,
          record.perTimeEmAmount,
          record.maxTimes,
          maxTimesBasis && maxTimesBasis[record.maxTimesBasis]?.value
        )

        return (
          <>
            <div>
              <Space>
                {(claimType?.currencyCode || ClaCurrencyCode.sgd).toUpperCase()}
                {formatMoney(entitlement, 2)}
              </Space>
            </div>
            <Text size="small" block>
              {limitText.map((l, index) => (
                <div key={index}>{l}</div>
              ))}
            </Text>
          </>
        )
      }
    },
    {
      title: `Prorated ${(prorationTypes[claimType?.prorationType || '']?.value || '').toLowerCase()}`,
      key: 'earnedBefRound',
      dataIndex: 'earnedBefRound',
      render: (earnedBefRound: number, record) => {
        return (
          <>
            <div>
              <Space>
                <b>{(claimType?.currencyCode || ClaCurrencyCode.sgd).toUpperCase()}</b>
                <b>{formatMoney(earnedBefRound, 2)}</b>
              </Space>
            </div>
            <Text size="small" block>
              {formatDateRange(record.startDate, record.endDate)}
            </Text>
          </>
        )
      }
    }
  ]

  return (
    <Drawer open={visible} title="Entitled policy" onClose={onClose} width="800px" className="ce-policies-drawer">
      <Table rowKey="id" dataSource={data} pagination={false} columns={columns} loading={loading} />
    </Drawer>
  )
}
