import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { DownloadOutlined } from '@ant-design/icons'
import { CardProps, List, Space } from '~/core-components'
import { CardBanner, DownloadFileAuth } from '~/components'
import { MySsAccessGate } from '~/features/my'
import { FeatureFlag } from '~/constants'
import { formatDateRange, getBaseUrl } from '~/utils'
import { MY_ROUTES } from '~/routes/routes'
import { useMyPayslips } from '../../hooks'
import { SSPayslipState } from '../../types'
import './MyPayslipLatest.less'

interface MyPayslipLatestProps extends CardProps {}

const baseUrl = getBaseUrl('/payroll-sg')

export const MyPayslipLatest: FC<MyPayslipLatestProps> = ({ className, ...props }) => {
  const classes = classNames('my-payslip-latest', { [`${className}`]: className })
  const [payslips, loading] = useMyPayslips()

  return (
    <MySsAccessGate featureFlag={FeatureFlag.MyPayroll}>
      <CardBanner
        title="My payslip"
        image="/my-payslip-background.jpg"
        className={classes}
        loading={loading}
        {...props}
      >
        {payslips.length === 0 && !loading && <>No payslip found</>}
        {payslips.length > 0 && (
          <List
            size="small"
            dataSource={payslips.slice(0, 5)}
            renderItem={(item: SSPayslipState) => (
              <List.Item key={item.id}>
                <DownloadFileAuth url={`${baseUrl}/sspayslip/${item.payRunId}/pdf`}>
                  <Space>
                    <span>
                      {item.name} for {formatDateRange(item.startDate, item.endDate)}
                    </span>
                    <DownloadOutlined style={{ color: '#bbb' }} />
                  </Space>
                </DownloadFileAuth>
              </List.Item>
            )}
          />
        )}
        {payslips.length > 5 && (
          <div className="my-payslip-latest__action">
            <Link style={{ fontSize: '12px' }} to={MY_ROUTES.self.replace(':tab?', 'payroll')}>
              show more
            </Link>
          </div>
        )}
      </CardBanner>
    </MySsAccessGate>
  )
}
