import { FC } from 'react'
import { DrawerForm } from '~/components'
import { DailyRecord } from './DailyRecord'

export interface DailyRecordDrawerProps {
  visible: boolean
  clockDate: string
  employeeId: string
  shiftId: string
  onClose: () => void
}

export const DailyRecordDrawer: FC<DailyRecordDrawerProps> = ({ visible, employeeId, clockDate, shiftId, onClose }) => {
  return (
    <DrawerForm
      open={visible}
      title="Daily record"
      okText={''}
      onClose={onClose}
      width={1300}
      formId="form-daily-record"
      className="daily-record-drawer"
    >
      <DailyRecord employeeId={employeeId} clockDate={clockDate} shiftId={shiftId} openAs="drawer" />
    </DrawerForm>
  )
}
