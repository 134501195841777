import React, { CSSProperties, FC, useCallback, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import moment from 'moment-timezone'
import { Button, Card, ColumnsType, Form, PageHeader, SecondaryText, Space, Table } from '~/core-components'
import { DocumentTitle, SearchInput } from '~/components'
import { Screen, ViewCriteriaSimple, ViewCriteria, updateViewCriteria, useFirstView } from '~/features/selection'
import { dispatch } from '~/stores/store'
import { usePermissionGate } from '~/features/iam'
import { Permission, PermissionAction } from '~/constants'
import { OedSubmissionState } from '~/features/report/types'
import { REPORTS_ROUTES } from '~/routes/routes'
import { useOedSubmissionView } from '~/features/report/hooks'
import { refetchOedSubmissionsView } from '~/features/report/reducers'
import { AddOedSubmissionDrawer } from './AddOedSubmissionDrawer'
import { OedTags } from '../Submission/OedTags'
import './OedSubmissions.less'

const routes = [
  {
    path: REPORTS_ROUTES.main,
    breadcrumbName: 'Reports'
  },
  {
    path: '',
    breadcrumbName: 'OED report'
  }
]

interface OedSubmissionsProps {}

interface AddDrawerState {
  visible: boolean
}

const DEFAULT_ADD_DRAWER_STATE: AddDrawerState = { visible: false }
const PAGE_SIZE_OPTIONS = ['20', '50', '100']

const SCREEN_CODE: Screen = 'rpt_oed_submission'
const labelCompanyStyle: CSSProperties = { lineHeight: 1.1 }
const labelCompanyUenStyle: CSSProperties = { fontSize: 12 }
const paginationStyle: CSSProperties = { marginRight: 20 }

export const OedSubmissions: FC<OedSubmissionsProps> = () => {
  const [view, viewLoading] = useFirstView(SCREEN_CODE)
  const viewId = view?.id || ''
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(20)
  const [search, setSearch] = useState<string>('')
  const [data, dataLoading] = useOedSubmissionView(viewId, page, pageSize, search, 'always')

  const [addDrawerState, setAddDrawerState] = useState<AddDrawerState>(DEFAULT_ADD_DRAWER_STATE)
  const canAdd = usePermissionGate(Permission.rptMomOed, PermissionAction.Add)

  const columns: ColumnsType<OedSubmissionState> = [
    {
      title: 'Year',
      key: 'startDate',
      dataIndex: 'startDate',
      width: 80,
      render: (value: string, record) => (
        <RouterLink to={REPORTS_ROUTES.oedSubmission.replace(':id', record.id).replace(':tab?', 'record')}>
          {moment(value).format('YYYY')}
        </RouterLink>
      )
    },
    {
      title: 'Month',
      key: 'startDate',
      dataIndex: 'startDate',
      width: 80,
      render: (value: string) => moment(value).format('MMMM')
    },
    {
      title: 'Company',
      key: 'companyName',
      dataIndex: 'companyName',
      render: (value: string, record) => (
        <>
          <div style={labelCompanyStyle}>{value}</div>
          <SecondaryText style={labelCompanyUenStyle}>{record.companyUen}</SecondaryText>
        </>
      )
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      width: 150,
      render: (value: string, record) => <OedTags oedSubmission={record} />
    },
    {
      title: 'Last modified',
      key: 'updatedDate',
      dataIndex: 'updatedDate',
      width: 120,
      render: (value: string) => value && moment(value).format('DD/MM/YYYY')
    }
  ]

  const handlePaginationChange = useCallback((page: number, pageSize?: number) => {
    setPage(page)
    setPageSize(pageSize || 20)
  }, [])

  const handleCriteriaApply = useCallback(
    async (criteria: ViewCriteria[]) => {
      if (viewId) {
        setPage(1)
        await dispatch(updateViewCriteria(SCREEN_CODE, viewId, { id: viewId, criteria }))
        dispatch(refetchOedSubmissionsView())
      }
    },
    [viewId]
  )

  const handleAddClick = useCallback(() => {
    setAddDrawerState({ visible: true })
  }, [])

  const handleSearch = useCallback((value: string) => {
    setPage(1)
    setSearch(value)
  }, [])

  const handleCloseAddDrawer = useCallback(() => {
    setAddDrawerState(DEFAULT_ADD_DRAWER_STATE)
  }, [])

  return (
    <div className="oed-submissions">
      <DocumentTitle title="Occupational Employment Dataset (OED)" />
      <PageHeader title="Occupational Employment Dataset (OED)" breadcrumb={{ routes }} />
      <div className="oed-submissions__body">
        <div className="oed-submissions__action-bar">
          <Form.Item label="">
            <SearchInput onSearch={handleSearch} />
          </Form.Item>
          <ViewCriteriaSimple screenCode={SCREEN_CODE} viewId={viewId} onApply={handleCriteriaApply} label="" />
          <Space align="start">{canAdd && <Button onClick={handleAddClick}>Create new OED report</Button>}</Space>
        </div>
        <Card fitParent table>
          <Table
            rowKey="id"
            dataSource={data?.data}
            columns={columns}
            fitParent
            loading={dataLoading || viewLoading}
            pagination={{
              total: data?.count,
              current: page,
              pageSize,
              pageSizeOptions: PAGE_SIZE_OPTIONS,
              showSizeChanger: true,
              onChange: handlePaginationChange,
              style: paginationStyle
            }}
          />
        </Card>
      </div>
      {canAdd && <AddOedSubmissionDrawer {...addDrawerState} onClose={handleCloseAddDrawer} />}
    </div>
  )
}
