import React, { FC, useCallback, useState } from 'react'
import classNames from 'classnames'
import { Button, ButtonProps } from '~/core-components'
import { MyClaimApplyDrawer } from './MyClaimApplyDrawer'

interface MyClaimApplyButtonProps extends ButtonProps {}

interface DrawerState {
  visible: boolean
  editing?: boolean
  editable?: boolean
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

export const MyClaimApplyButton: FC<MyClaimApplyButtonProps> = ({ className, ...props }) => {
  const classes = classNames('my-claim-apply-button', className)
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)

  const handleApplyClaim = useCallback(() => {
    setDrawerState({ visible: true, editing: true, editable: true })
  }, [setDrawerState])

  const handleCloseDrawer = useCallback(() => setDrawerState(DEFAULT_DRAWER_STATE), [])

  return (
    <>
      <Button className={classes} block onClick={handleApplyClaim} {...props} />
      <MyClaimApplyDrawer {...drawerState} onClose={handleCloseDrawer} />
    </>
  )
}
