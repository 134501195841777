import { request, getBaseUrl, timeout } from '~/utils/request'
import { SSClaimTaskTokenState } from '../types'

const baseUrl = getBaseUrl('/claim/ssclaimtasktoken')

export const apiGetMyClaimTaskByToken = (token: string) => request<SSClaimTaskTokenState>('get', `${baseUrl}/${token}`)

export const apiApproveClaimByToken = (token: string, comment: string) =>
  request('post', `${baseUrl}/approve/${token}`, { comment })

export const apiRejectClaimByToken = (token: string, comment: string) =>
  request('post', `${baseUrl}/reject/${token}`, { comment })

export const apiAddCommentByToken = (token: string, comment: string) =>
  request('post', `${baseUrl}/comment/${token}`, { comment })

export const apiDownloadAllAttachmentByToken = (token: string) =>
  request('get', `${baseUrl}/attachment/${token}/downloadall`, undefined, { responseType: 'blob', timeout })
