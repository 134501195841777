import { showError, showSuccess } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { IUpdateTenantConfigs, TenantConfigState } from '../types'
import { replaceTenantConfigs } from '../reducers'
import { apiUpdateTenantConfigs } from '../api/tenant-config.api'

export const updateTenantConfigs = (request: IUpdateTenantConfigs[]): ThunkResult<void> => {
  return async (dispatch, getState): Promise<ActionResult> => {
    const { status, result, errors, message, errorData } = await apiUpdateTenantConfigs(request)
    if (status) {
      const configs = getState().iam.tenantConfigs.entities
      if (configs) {
        request.forEach(c => {
          if (configs[c.sysConfigCode]) {
            configs[c.sysConfigCode]!.value = c.value
          }
        })
        dispatch(replaceTenantConfigs(Object.values(configs) as TenantConfigState[]))
      }

      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
