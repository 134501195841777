import React, { FC, useCallback, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { useHistory } from 'react-router'
import { Form } from '~/core-components'
import { DrawerForm, EmpKeyValues, Row, PeriodKeyValues, Col } from '~/components'
import { useFocus } from '~/hooks/use-focus'
import { REPORTS_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors } from '~/types/store'
import { IOedSubmissionNew } from '~/features/report/types'
import { addOedSubmission } from '~/features/report/actions'

export interface AddOedSubmissionDrawerProps {
  visible: boolean
  onClose: () => void
}

interface FormData extends IOedSubmissionNew {}

const currentPeriod = moment().format('YYYY-MM')

export const EMPTY_FORM_DATA: FormData = {
  period: currentPeriod,
  companyId: ''
}

export const AddOedSubmissionDrawer: FC<AddOedSubmissionDrawerProps> = ({ visible, onClose }) => {
  const [focusRef, setFocus] = useFocus(true)
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const history = useHistory()

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      result = await dispatch(addOedSubmission(formData))
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()

      if (result?.result?.id) {
        const oedSubmissionId = result?.result?.id
        history.push(REPORTS_ROUTES.oedSubmission.replace(':id', oedSubmissionId).replace(':tab?', 'record'))
      }
    }
  }, [formData, history, onClose])

  const handleCloseDrawer = useCallback(() => {
    typeof onClose === 'function' && onClose()
  }, [onClose])

  return (
    <DrawerForm
      open={visible}
      title="Create new OED report"
      okText="Create"
      onClose={handleCloseDrawer}
      confirmLoading={loading}
      width={500}
      className="add-oed-submission-drawer"
      formId="form-add-oed-submission"
    >
      <Form id="form-add-oed-submission" onFinish={handleOk}>
        <Row>
          <Col span={24}>
            <Form.Item label="Period" validateStatus={errors?.period ? 'error' : ''} help={errors?.period}>
              <PeriodKeyValues
                allowClear={false}
                value={formData.period}
                startMonth={currentPeriod}
                noOfMonthBefore={-24}
                noOfMonthAfter={2}
                onChange={(value: string) => {
                  handleFormDataChange({ period: value })
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Company" validateStatus={errors?.companyId ? 'error' : ''} help={errors?.companyId}>
              <EmpKeyValues
                id="company"
                ref={focusRef}
                allowClear={false}
                value={formData.companyId}
                onChange={(companyId?: string) => handleFormDataChange({ companyId })}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}
