import { Steps as InternalSteps } from './Steps'
import { Step } from './Step'

type TSteps = typeof InternalSteps
interface ISteps extends TSteps {
  Step: typeof Step
}

const Steps = InternalSteps as ISteps
Steps.Step = Step
export { Steps }
