import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { apiUpdateEmEmploymentType } from '../api/em-employment-type.api'
import { EmEmploymentTypeState, IEmEmploymentTypeInfo } from '../types'
import { setEmEmploymentType, setEmployee } from '../reducers'

export const updateEmEmploymentType = <T extends IEmEmploymentTypeInfo>(
  employeeId: string,
  id: string,
  before: T,
  request: T
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!employeeId || !id) return

    const after = { ...before, ...request } as T
    const patch = generateJsonPatch<T>(before, after)
    const { result, status, errors, message, errorData } = await apiUpdateEmEmploymentType(employeeId, id, patch)

    if (status) {
      if (result.emEmploymentTypeId) {
        const employee = getState().employee.employees.entities[employeeId]
        if (employee) {
          dispatch(setEmployee({ ...employee, emEmploymentTypeId: result.emEmploymentTypeId }))
        }
      }

      const emEmploymentType = getState().employee.emEmploymentTypes[employeeId]?.entities[id]
      dispatch(
        setEmEmploymentType({
          employeeId,
          data: {
            ...(emEmploymentType as EmEmploymentTypeState),
            ...after
          }
        })
      )

      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
