export interface PayCfConfigState {
  code: string
  name: string
  isShown: boolean
}

export interface IPayCfConfig {
  name: string
  isShown: boolean
}

export const mapPayCfConfigStateToPayCfConfigInfo = (payCfConfig: PayCfConfigState): IPayCfConfig => ({
  name: payCfConfig.name,
  isShown: payCfConfig.isShown
})
