import { Menu as InternalMenu, MenuProps as InternalMenuProps, ItemType as InternalItemType } from './Menu'
import { MenuItem } from './MenuItem'

type TMenu = typeof InternalMenu
interface IMenu extends TMenu {
  Item: typeof MenuItem
}

const Menu: IMenu = InternalMenu as IMenu
Menu.Item = MenuItem
export { Menu }

export type MenuProps = InternalMenuProps
export type MenuItemType = InternalItemType
