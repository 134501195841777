import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { SSLeaveRecordDtlState } from '../../types'

export const selectMyLeaveRecordDtls = createSelector(
  (state: StoreState) => state.my.myLeaveRecordDtls,
  myLeaveRecordDtlsState =>
    memoize((id: string): SSLeaveRecordDtlState[] =>
      (Object.values(myLeaveRecordDtlsState[id]?.entities || {}) as SSLeaveRecordDtlState[]).sort((a, b) => {
        const aLeaveDate = new Date(a!.leaveDate).getTime()
        const bLeaveDate = new Date(b!.leaveDate).getTime()
        return aLeaveDate - bLeaveDate // ascending
      })
    )
)
