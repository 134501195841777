import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchMyEmployee } from '../../actions'
import { SSEmployeeState } from '../../types'

export const useMyEmployee = (fetchStrategy: FetchStrategy = 'when-empty'): [SSEmployeeState, boolean] => {
  const me = useSelector((state: StoreState) => state.my.myEmployee)
  const loading = useSelector((state: StoreState) => state.my.myEmployeeLoading)

  useEffect(() => {
    dispatch(fetchMyEmployee({ strategy: fetchStrategy }))
  }, [fetchStrategy])

  return [me, loading]
}
