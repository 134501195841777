import { ThunkResult } from '~/types/store'
import { apiComparePaySnapshot } from '../api/payroll.api'
import { setPaySnapshot, setPaySnapshotLoading } from '../reducers'

export const comparePaySnapshot = (payRunId: string): ThunkResult<void> => {
  return async dispatch => {
    if (!payRunId) return

    try {
      dispatch(setPaySnapshotLoading({ payRunId, loading: true }))
      const { result, status } = await apiComparePaySnapshot(payRunId)
      if (status) {
        dispatch(setPaySnapshot({ payRunId, data: result }))
      }
    } finally {
      dispatch(setPaySnapshotLoading({ payRunId, loading: false }))
    }
  }
}
