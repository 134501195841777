import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { ShiftRoleState } from '../types'

export const selectShiftRoleKeyvalues = createSelector(
  (state: StoreState) => state.attendance.shiftRoles,
  shiftRolesState => {
    const shiftRoles = Object.values(shiftRolesState?.entities || {}) as ShiftRoleState[]

    let kv = shiftRoles.sort((a, b) => {
      const aInactiveDate = new Date(a!.inactiveDate || '').getTime() || 0
      const bInactiveDate = new Date(b!.inactiveDate || '').getTime() || 0

      if (bInactiveDate === 0 && aInactiveDate === 0) {
        return a!.name.localeCompare(b!.name)
      } else {
        return aInactiveDate - bInactiveDate
      }
    })

    return kv
  }
)
