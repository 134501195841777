import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Card, Table, Link, LinkGroup, SecondaryLink, SecondaryText, ColumnsType, Tag } from '~/core-components'
import { InactiveTag } from '~/components'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { isInactive } from '~/utils/dateUtil'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { AddOfficeDrawer } from './AddOfficeDrawer'
import { EditOfficeDrawer } from './EditOfficeDrawer'
import { deleteCompanyOffice, fetchCompanyOffices } from '../../../actions'
import { CompanyOfficeState, OfficeState } from '../../../types'
import { selectCompanyOfficesOverview } from '../../../selectors'

interface CompanyOfficesProps {
  companyId?: string
}

interface DrawerState {
  visible: boolean
}

interface AddDrawerState extends DrawerState {
  data?: { companyId: string; isMain: boolean }
}

interface EditDrawerState extends DrawerState {
  data?: CompanyOfficeTable
}

export type CompanyOfficeTable = CompanyOfficeState & OfficeState

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

const cardBodyStyle = { padding: 0 }
const labelRow1Style = { lineHeight: 1.1 }
const labelRow2Style = { fontSize: 12 }

export const CompanyOffices: FC<CompanyOfficesProps> = ({ companyId }: CompanyOfficesProps) => {
  const [addDrawerState, setAddDrawerState] = useState<AddDrawerState>(DEFAULT_DRAWER_STATE)
  const [editDrawerState, setEditDrawerState] = useState<EditDrawerState>(DEFAULT_DRAWER_STATE)
  const companyOffices = useSelector(selectCompanyOfficesOverview)(companyId)
  const canModify = usePermissionGate(Permission.company, PermissionAction.Modify)
  const loading = useSelector((state: StoreState) => state.master.companyOfficesLoading)

  useEffect(() => {
    if (companyId) {
      dispatch(fetchCompanyOffices(companyId))
    }
  }, [companyId])

  const handleAddOffice = useCallback(() => {
    if (companyId) {
      setAddDrawerState({ visible: true, data: { companyId, isMain: companyOffices.length === 0 ? true : false } })
    }
  }, [companyId, companyOffices.length])

  const handleEditOffice = useCallback(
    (company: CompanyOfficeTable) => {
      if (companyId) {
        setEditDrawerState({ visible: true, data: company })
      }
    },
    [companyId]
  )

  const handleDeleteOffice = useCallback(({ id, companyId, officeId }: CompanyOfficeTable) => {
    dispatch(deleteCompanyOffice(id, companyId, officeId))
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setAddDrawerState({ ...addDrawerState, visible: false })
    setEditDrawerState({ ...editDrawerState, visible: false })
  }, [addDrawerState, editDrawerState])

  const columns: ColumnsType<CompanyOfficeTable> = [
    {
      title: 'Office',
      key: 'name',
      dataIndex: 'name',
      render: (value: string, record) => (
        <>
          <div style={labelRow1Style}>
            {value} {record.isMain && <Tag type="primary">main office</Tag>}{' '}
            {isInactive(record.inactiveDate) && <InactiveTag />}
          </div>
          {record.phone && <SecondaryText style={labelRow2Style}>Tel. {record.phone}</SecondaryText>}
        </>
      )
    },
    {
      title: 'Address',
      key: 'addressLine1',
      dataIndex: 'addressLine1',
      render: (value: string, record) => (
        <>
          <div style={labelRow1Style}>{value}</div>
          <SecondaryText style={labelRow2Style}>{record.addressLine2}</SecondaryText>
        </>
      )
    },
    {
      key: 'action',
      align: 'right',
      render: (value: string, record) =>
        canModify && (
          <LinkGroup>
            <Link size="small" onClick={() => handleEditOffice(record)}>
              edit
            </Link>
            <SecondaryLink size="small" onClick={() => handleDeleteOffice(record)}>
              unlink
            </SecondaryLink>
          </LinkGroup>
        )
    }
  ]

  return (
    <Card
      title="Offices"
      extra={canModify && companyId && <Link onClick={handleAddOffice}>add</Link>}
      bodyStyle={cardBodyStyle}
      loading={loading}
    >
      <Table rowKey="id" dataSource={companyOffices} pagination={false} columns={columns} loading={loading} />
      {canModify && <AddOfficeDrawer {...addDrawerState} onClose={handleCloseDrawer} />}
      {canModify && <EditOfficeDrawer {...editDrawerState} onClose={handleCloseDrawer} />}
    </Card>
  )
}
