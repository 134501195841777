import { createSelector } from '@reduxjs/toolkit'
import moment from 'moment-timezone'
import { StoreState } from '~/types/store'
import { SSOthersLeaveRecordState } from '../../types'

const TODAY = moment().format('YYYY-MM-DD')

export const selectTodayAbsences = createSelector(
  (state: StoreState) => state.my.othersLeaveRecords,
  absencesState => {
    return Object.values(absencesState?.entities || {}).filter(b =>
      moment(b?.startDate).isSameOrBefore(moment(TODAY))
    ) as SSOthersLeaveRecordState[]
  }
)

export const selectThisWeekAbsences = createSelector(
  (state: StoreState) => state.my.othersLeaveRecords,
  absencesState => {
    return Object.values(absencesState?.entities || {}).filter(
      b => !moment(b?.startDate).isSameOrBefore(moment(TODAY))
    ) as SSOthersLeaveRecordState[]
  }
)
