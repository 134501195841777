import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { ReasonState } from '../types'

export const selectReasons = createSelector(
  (state: StoreState) => state.master.masters['reason'],
  reasonState =>
    memoize((type: string): (ReasonState | undefined)[] => {
      const reasons = Object.values(reasonState?.entities || {}) as (ReasonState | undefined)[]
      return reasons
        .filter(reason => reason?.type === type)
        .sort((a, b) => {
          const aInactiveDate = new Date(a!.inactiveDate || '').getTime() || 0
          const bInactiveDate = new Date(b!.inactiveDate || '').getTime() || 0

          if (bInactiveDate === 0 && aInactiveDate === 0) {
            return a!.name.localeCompare(b!.name)
          } else {
            return aInactiveDate - bInactiveDate
          }
        })
    })
)
