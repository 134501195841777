import React, { FC, useMemo } from 'react'
import { AnchorProps } from 'antd/lib/anchor'
import { Anchor } from '~/core-components'
import { PermissionGate } from '~/features/iam'
import { MenuItem } from '../../types/common'
import './MenuAnchors.less'

interface MenuAnchorsProps extends AnchorProps {
  menus: MenuItem[]
  containerId: string
}

export const MenuAnchors: FC<MenuAnchorsProps> = ({ menus, containerId, ...props }) => {
  const containerEl = document.getElementById(containerId)
  const getAnchorProps = useMemo(() => {
    return containerEl != null ? { getContainer: () => containerEl as HTMLElement } : undefined
  }, [containerEl])

  return (
    <Anchor className="menu-anchors" {...getAnchorProps} {...props}>
      {menus.map(menu => (
        <PermissionGate key={menu.value} sysPermissionId={menu.sysPermissionId}>
          {typeof menu.path === 'string' && <Anchor.Link title={menu.value} href={menu.path}></Anchor.Link>}
        </PermissionGate>
      ))}
    </Anchor>
  )
}
