import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { Alert, Button, Form, Link, Space } from '~/core-components'
import { ClaKeyValues, Col, PercentInput, Row, SalaryInput, SysOptions } from '~/components'
import { Errors } from '~/types/store'
import { ClaPerTimeLimit, emptyGuid } from '~/constants'
import { CtPolicyEtState } from '../../../types'
import { KeyValue } from '~/types/common'
import { useCtExpenseTypes, useCtPolicyEt } from '~/features/claim/hooks'
import './CtPolicyExpenseType.less'

interface CtPolicyExpenseTypeProps {
  claimTypeId: string
  ctPolicyId: string
  errors: Errors | undefined
  onChange: (ctPolicyEt: FormData) => void
}

const RELEVANT_ERRORS = ['expenseTypeId']

interface FormData {
  expenseTypes: CtPolicyEtState[]
}

const EMPTY_FORM_DATA: FormData = {
  expenseTypes: []
}

const inputNumberStyle: CSSProperties = { width: 120 }

export const CtPolicyExpenseType: FC<CtPolicyExpenseTypeProps> = ({ claimTypeId, ctPolicyId, errors, onChange }) => {
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)
  const relevantErrors = Object.keys(errors || {}).filter(e => RELEVANT_ERRORS.includes(e))
  const [ctExpenseTypes] = useCtExpenseTypes(claimTypeId)
  const [ctPolicyEt] = useCtPolicyEt(claimTypeId, ctPolicyId)

  const NEW_CT_POLICY_ET: CtPolicyEtState[] = [
    {
      id: emptyGuid,
      ctPolicyId: ctPolicyId,
      expenseTypeId: '',
      perTime: ClaPerTimeLimit.Amount,
      perTimeAmount: 0,
      perTimePercentage: 0,
      perTimeEmAmount: 0
    }
  ]

  const mapToFormData = (ctPolicyEt: CtPolicyEtState[]): FormData => ({
    expenseTypes: ctPolicyEt
  })

  useEffect(() => {
    if (ctPolicyEt.length > 0) {
      setFormData(mapToFormData(ctPolicyEt))
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [ctPolicyEt])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      setFormData(formData => {
        const updated = { ...formData, ...updates }
        typeof onChange === 'function' && onChange(updated)
        return updated
      })
    },
    [onChange]
  )

  return (
    <div className="ct-policy-et">
      <Row>
        <Col span={24}>
          {errors && relevantErrors.length > 0 && (
            <Alert
              className="ct-policy-et__error"
              type="error"
              message={
                <>
                  {relevantErrors.map((error, index) => (
                    <div key={index}>{errors[error]}</div>
                  ))}
                </>
              }
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {formData.expenseTypes.map((et, index) => (
            <Row gutter={10} key={index} className="ct-policy-et__row">
              <Col span={9}>
                {index === 0 && <div className="ct-policy-et__row-title">Expense type</div>}
                <ClaKeyValues
                  id="expenseType"
                  value={et.expenseTypeId}
                  showSearch
                  allowClear={false}
                  dropdownMatchSelectWidth={false}
                  onFilter={(value: KeyValue | undefined) => {
                    const expenseTypeIds = ctExpenseTypes.map(ctet => ctet.expenseTypeId)
                    return expenseTypeIds.includes(value?.key || '')
                  }}
                  onChange={(expenseTypeId: string) =>
                    handleFormDataChange({
                      expenseTypes: [
                        ...formData.expenseTypes.slice(0, index),
                        { ...formData.expenseTypes[index], expenseTypeId },
                        ...formData.expenseTypes.slice(index + 1, formData.expenseTypes.length)
                      ]
                    })
                  }
                />
              </Col>
              <Col span={9}>
                {index === 0 && <div className="ct-policy-et__row-title">Per time limit</div>}
                <SysOptions
                  type="cla_per_time_limit"
                  value={et.perTime}
                  dropdownMatchSelectWidth={false}
                  onChange={(perTime: string) =>
                    handleFormDataChange({
                      expenseTypes: [
                        ...formData.expenseTypes.slice(0, index),
                        { ...formData.expenseTypes[index], perTime },
                        ...formData.expenseTypes.slice(index + 1, formData.expenseTypes.length)
                      ]
                    })
                  }
                />
              </Col>
              <Col span={5}>
                {index === 0 && <div className="ct-policy-et__row-title">Amount</div>}
                <Space>
                  {et.perTime === ClaPerTimeLimit.Amount && (
                    <SalaryInput
                      style={inputNumberStyle}
                      min={0}
                      value={et.perTimeAmount}
                      onChange={(perTimeAmount: number | null) =>
                        handleFormDataChange({
                          expenseTypes: [
                            ...formData.expenseTypes.slice(0, index),
                            {
                              ...formData.expenseTypes[index],
                              perTimeAmount,
                              perTimePercentage: 0,
                              perTimeEmAmount: 0
                            },
                            ...formData.expenseTypes.slice(index + 1, formData.expenseTypes.length)
                          ]
                        })
                      }
                    />
                  )}
                  {et.perTime === ClaPerTimeLimit.Percentage && (
                    <PercentInput
                      value={et.perTimePercentage}
                      onChange={(perTimePercentage: number | null) =>
                        handleFormDataChange({
                          expenseTypes: [
                            ...formData.expenseTypes.slice(0, index),
                            {
                              ...formData.expenseTypes[index],
                              perTimePercentage,
                              perTimeAmount: 0,
                              perTimeEmAmount: 0
                            },
                            ...formData.expenseTypes.slice(index + 1, formData.expenseTypes.length)
                          ]
                        })
                      }
                    />
                  )}
                  {et.perTime === ClaPerTimeLimit.EmAmount && (
                    <SalaryInput
                      style={inputNumberStyle}
                      min={0}
                      value={et.perTimeEmAmount}
                      onChange={(perTimeEmAmount: number | null) =>
                        handleFormDataChange({
                          expenseTypes: [
                            ...formData.expenseTypes.slice(0, index),
                            {
                              ...formData.expenseTypes[index],
                              perTimeEmAmount,
                              perTimeAmount: 0,
                              perTimePercentage: 0
                            },
                            ...formData.expenseTypes.slice(index + 1, formData.expenseTypes.length)
                          ]
                        })
                      }
                    />
                  )}
                </Space>
              </Col>
              <Col span={1} style={{ whiteSpace: 'nowrap' }}>
                <Button
                  size="small"
                  type="text"
                  icon={<i className="fal fa-xmark" />}
                  onClick={() =>
                    handleFormDataChange({
                      expenseTypes: [
                        ...formData.expenseTypes.slice(0, index),
                        ...formData.expenseTypes.slice(index + 1, formData.expenseTypes.length)
                      ]
                    })
                  }
                />
              </Col>
            </Row>
          ))}
          <Form.Item>
            <Link
              onClick={() => handleFormDataChange({ expenseTypes: [...formData.expenseTypes, ...NEW_CT_POLICY_ET] })}
            >
              add more
            </Link>
          </Form.Item>
        </Col>
      </Row>
    </div>
  )
}
