import React, { forwardRef, KeyboardEvent } from 'react'
import { default as AntSelect, SelectProps as AntSelectProps } from 'antd/lib/select'
import type { CustomTagProps as AntCustomTagProps } from 'rc-select/lib/BaseSelect'
import { LoadingOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import { Spin } from '../Spin/Spin'
import 'antd/es/select/style'
import './Select.less'

export interface SelectProps extends AntSelectProps<any> {
  readOnly?: boolean
  dropdownMultiline?: boolean
}

export interface CustomTagProps extends AntCustomTagProps {}

const SelectInternal = (
  {
    readOnly,
    className,
    allowClear = true,
    popupClassName,
    dropdownMultiline = false,
    placeholder,
    ...props
  }: SelectProps,
  ref: React.Ref<any>
) => {
  const classes = classNames({ [`select--readonly`]: readOnly }, { [`${className}`]: className })

  const popupClasses = classNames(
    { [`select--dropdown-multiline`]: dropdownMultiline },
    { [`${popupClassName}`]: popupClassName }
  )

  return (
    <AntSelect
      {...props}
      ref={ref}
      className={classes}
      allowClear={readOnly ? false : allowClear}
      showSearch={readOnly ? false : props.showSearch}
      onChange={readOnly ? undefined : props.onChange}
      onSelect={readOnly ? undefined : props.onSelect}
      dropdownStyle={readOnly ? { display: 'none' } : props.dropdownStyle}
      popupClassName={popupClasses}
      notFoundContent={props.loading ? <Spin indicator={<LoadingOutlined spin />} /> : 'No data available'}
      placeholder={props.loading ? 'Loading...' : placeholder}
      onInputKeyDown={
        readOnly ? (event: KeyboardEvent<HTMLInputElement>) => event.preventDefault() : props.onInputKeyDown
      }
    />
  )
}

export const Select = forwardRef(SelectInternal)
