import { request, getBaseUrl, timeout, toQueryParams } from '~/utils/request'
import { IRptPayYtd } from '../types'

const baseUrl = getBaseUrl('/payroll-sg/rptpayytd')

export const apiDownloadPayYtdExcel = (params: IRptPayYtd) => {
  return request('get', `${baseUrl}/excel`, toQueryParams(params), { responseType: 'blob', timeout })
}

export const apiDownloadPayYtdPdf = (params: IRptPayYtd) => {
  return request('get', `${baseUrl}/pdf`, toQueryParams(params), { responseType: 'blob', timeout })
}
