import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Drawer } from '~/core-components'
import { Col, Row } from '~/components'
import { StoreState } from '~/types/store'
import { selectMyTenants } from '../../selectors'
import { TenantCard } from './TenantCard'

interface TenantSwitcherDrawerProps {
  visible: boolean
  onClose: () => void
}

export const TenantSwitcherDrawer: FC<TenantSwitcherDrawerProps> = ({ visible, onClose }) => {
  const myTenants = useSelector(selectMyTenants)
  const myJwt = useSelector((state: StoreState) => state.iam.myJwt)

  return (
    <Drawer open={visible} title="Select your workspace" placement="left" onClose={onClose} width={450}>
      <Row gutter={[30, 15]}>
        {myTenants
          .filter(t => myJwt?.isAdmin?.includes(t.tenantCode))
          .map(t => (
            <Col span={24} key={t.tenantCode}>
              <TenantCard tenant={t} role="admin" />
            </Col>
          ))}
        {myTenants
          .filter(t => myJwt?.isEmployee?.includes(t.tenantCode))
          .map(t => (
            <Col span={24} key={t.tenantCode}>
              <TenantCard tenant={t} role="employee" />
            </Col>
          ))}
      </Row>
    </Drawer>
  )
}
