import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { PayCfConfigState } from '../types'
import { FetchStrategy } from '~/types/common'
import { selectPayCfConfigs } from '../selectors'
import { fetchPayCfConfigs } from '../actions'

export const usePayCfConfigs = (fetchStrategy: FetchStrategy = 'always'): [PayCfConfigState[], boolean] => {
  const data = useSelector(selectPayCfConfigs)
  const loading = useSelector((state: StoreState) => state.payroll.payCfConfigsLoading)

  useEffect(() => {
    dispatch(fetchPayCfConfigs({ strategy: fetchStrategy }))
  }, [fetchStrategy])

  return [data, loading]
}
