import React, { CSSProperties, FC } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { LoadingOutlined } from '@ant-design/icons'
import { Alert, Card, ColumnsType, Descriptions, Spin, Table } from '~/core-components'
import { InfoTooltip } from '~/components'
import { StoreState } from '~/types/store'
import { formatNumberUnit, UnitType } from '~/utils'
import { selectNextEntitlements } from '../../../selectors'
import { NextEntitlementRowState } from '../../../types'
import './NextEntitlement.less'

interface NextEntitlementProps {
  visible: boolean
  success?: boolean
}

const spinStyles: CSSProperties = { marginRight: 10 }

export const NextEntitlement: FC<NextEntitlementProps> = ({ visible, success }) => {
  const data = useSelector(selectNextEntitlements)
  const period = useSelector((state: StoreState) => state.leave.nextEntitlementResult?.nextPeriod)
  const processing = useSelector((state: StoreState) => state.leave.leaveLoading)
  const hasEncashment = data.filter(d => d.encashedEnd > 0).length > 0
  const hasExcessExpiring = data.filter(d => d.cfExpiry !== d.cf).length > 0

  let columns: ColumnsType<NextEntitlementRowState> = [
    {
      title: 'Employee',
      key: 'employeeNo',
      dataIndex: 'employeeNo',
      width: 300,
      render: (value: string, record) => `[${value}] ${record.employeeName}`
    },
    {
      title: 'Leave type',
      key: 'leaveTypeName',
      dataIndex: 'leaveTypeName',
      width: 200
    },
    {
      title: 'Balance',
      key: 'balance',
      dataIndex: 'balance',
      width: 120,
      align: 'right',
      render: (value: number, record) => formatNumberUnit(value, record.unit as UnitType)
    }
  ]

  if (hasEncashment) {
    columns.push({
      title: 'Encash',
      key: 'encashedEnd',
      dataIndex: 'encashedEnd',
      width: 120,
      align: 'right',
      render: (value: number, record) => formatNumberUnit(value, record.unit as UnitType)
    })
  }

  columns.push({
    title: (
      <>
        Carry forward
        <InfoTooltip title="Inclusive of the expiring leave" />
      </>
    ),
    key: 'cf',
    dataIndex: 'cf',
    width: 120,
    align: 'right',
    render: (value: number, record) => formatNumberUnit(value, record.unit as UnitType)
  })

  if (hasExcessExpiring) {
    columns.push({
      title: 'Expiring',
      key: 'cfExpiry',
      dataIndex: 'cfExpiry',
      width: 120,
      align: 'right',
      render: (value: number, record) => formatNumberUnit(value, record.unit as UnitType)
    })
  }

  columns.push({
    title: 'Expiry date',
    key: 'cfExpiryDate',
    dataIndex: 'cfExpiryDate',
    width: 120,
    render: (value: number, record) => (value ? moment(value).format('DD MMM YYYY') : 'No expiry')
  })

  columns.push({
    key: 'filler'
  })

  return (
    <div className="next-entitlement" hidden={!visible}>
      {success && (
        <Alert
          type="success"
          showIcon
          message={
            <>
              <div>Leave closing successful</div>
              {processing ? (
                <div>
                  <Spin style={spinStyles} size="small" indicator={<LoadingOutlined spin />} />
                  <span>You may close this while we're refreshing the affected employees' entitlement</span>
                </div>
              ) : (
                <div>All the affected employees' payroll has been calculated</div>
              )}
            </>
          }
        />
      )}
      <Descriptions bordered>
        <Descriptions.Item label="Next period start date">
          {period?.periodStartDate ? moment(period.periodStartDate).format('DD MMM YYYY') : undefined}
        </Descriptions.Item>
        <Descriptions.Item label="Next period end Date">
          {period?.periodEndDate ? moment(period.periodEndDate).format('DD MMM YYYY') : undefined}
        </Descriptions.Item>
      </Descriptions>
      <Card table>
        <Table rowKey="id" dataSource={data} columns={columns} pagination={false} />
      </Card>
    </div>
  )
}
