import { showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiResetViewCriteria } from '../api/view.api'
import { Screen, ViewCriteriaFieldValue } from '../types'
import { setViewSchema } from '../reducers'

export const resetViewCriteria = (
  screenCode: Screen,
  viewId: string,
  resetTo: ViewCriteriaFieldValue[] = []
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!screenCode || !viewId) return

    const { status, errors, message, errorData } = await apiResetViewCriteria(screenCode, viewId, resetTo)

    if (status) {
      const viewSchema = getState().selection.viewSchema[screenCode]?.entities[viewId]
      if (viewSchema) {
        const resetCriteria = viewSchema.criteria.map(c => {
          const resetItem = resetTo.find(r => r.criteriaFieldId === c.criteriaFieldId)
          if (resetItem) {
            return { ...c, value: resetItem.value }
          }
          return { ...c, value: '' }
        })

        dispatch(
          setViewSchema({
            screenCode,
            data: {
              ...viewSchema,
              criteria: resetCriteria
            }
          })
        )
      }
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
