import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchCurrencies } from '../actions'
import { CurrencyState } from '../types'
import { selectCurrencies } from '../selectors'

export const useCurrencies = (): [CurrencyState[], boolean] => {
  const data = useSelector(selectCurrencies)
  const loading = useSelector((state: StoreState) => state.claim.currenciesLoading)

  useEffect(() => {
    dispatch(fetchCurrencies({ strategy: 'when-empty' }))
  }, [])

  return [data, loading]
}
