import React, { FC, useState, useCallback, useEffect, HTMLAttributes, CSSProperties } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import confirm from 'antd/lib/modal/confirm'
import { Alert, Form, Input, Radio, RadioChangeEvent, Tooltip } from '~/core-components'
import { Col, DrawerForm, EmpKeyValues, NewTabLinkIcon, Row, SysOptions } from '~/components'
import { CalendarState, selectCalendarKeyvalues } from '~/features/master'
import { selectEmployeeById } from '~/features/employee'
import { useHasFeatureFlagAttendance, usePermissionGate } from '~/features/iam'
import { Permission, PermissionAction, WorkSchedule } from '~/constants'
import { useFocus } from '~/hooks/use-focus'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors, StoreState } from '~/types/store'
import { addEmCalendar, deleteEmCalendar, updateEmCalendar } from '../../../actions'
import { IEmCalendarInfo, EmCalendarState } from '../../../types'
import { mapEmCalendarStateToEmCalendarInfo } from '../../../types/em-details.mapper'
import { Dictionary } from '@reduxjs/toolkit'
import { SETTINGS_ROUTES } from '~/routes/routes'

export interface MutateEmCalendarDrawerProps {
  visible: boolean
  employeeId?: string
  data?: EmCalendarState
  isCurrent: boolean
  onClose: () => void
}

const TODAY = moment().format('YYYY-MM-DD')
const EMPTY_FORM_DATA: IEmCalendarInfo = {
  startDate: TODAY,
  workSchedule: '',
  calendarId: '',
  whPerDay: 0,
  whPerWeek: 0,
  wdPerWeek: 0
}

const radioStyle: CSSProperties = { width: '100%' }
const radioItemStyle: CSSProperties = { marginTop: 20 }
const formStyle: CSSProperties = { marginLeft: 24, marginTop: 10 }
const alertStyle: CSSProperties = { marginBottom: 15 }

export const MutateEmCalendarDrawer: FC<MutateEmCalendarDrawerProps> = ({
  visible,
  employeeId,
  data,
  isCurrent,
  onClose
}: MutateEmCalendarDrawerProps) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<IEmCalendarInfo>(EMPTY_FORM_DATA)
  const [newFormData, setNewFormData] = useState<IEmCalendarInfo>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const [isNew, setIsNew] = useState(isCurrent ? true : false)
  const calendars = useSelector(selectCalendarKeyvalues)
  const employee = useSelector((state: StoreState) => selectEmployeeById(state, employeeId || ''))

  useEffect(() => {
    setIsNew(isCurrent ? true : false)
    setErrors(undefined)
  }, [visible, isCurrent])

  useEffect(() => {
    if (visible && data) setFormData(mapEmCalendarStateToEmCalendarInfo(data))
  }, [visible, data])

  const handleRadioChange = useCallback((event: RadioChangeEvent) => {
    setIsNew(event.target.value)
  }, [])

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      if (isNew || data == null) {
        if (employeeId) {
          const submit = isNew ? newFormData : formData
          result = await dispatch(addEmCalendar(employeeId, submit))
        }
      } else {
        if (data) {
          result = await dispatch(
            updateEmCalendar(data.employeeId, data.id, mapEmCalendarStateToEmCalendarInfo(data), formData)
          )
        }
      }
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_FORM_DATA)
    }
  }, [isNew, employeeId, data, newFormData, formData, onClose])

  const handleDelete = useCallback(
    (emCalendar: EmCalendarState | undefined) => {
      if (employeeId && emCalendar) {
        const { id, calendarId } = emCalendar
        const calendar = calendars[calendarId]?.value
        confirm({
          title: 'Delete work calendar',
          content: `Do you want to delete work calendar "${calendar}"?`,
          onOk: async () => {
            const result: ActionResult | undefined = await dispatch(deleteEmCalendar(employeeId, id))
            if (result?.errors) {
              setErrors(result.errors)
            }

            if (!result?.errors) {
              typeof onClose === 'function' && onClose()
            }
          },
          okText: 'Delete',
          okType: 'danger'
        })
      }
    },
    [employeeId, calendars, onClose]
  )

  return (
    <DrawerForm
      open={visible}
      title="Work calendar"
      onClose={onClose}
      confirmLoading={loading}
      width={500}
      showDelete={isNew || data == null ? false : true}
      onDelete={() => handleDelete(data)}
      formId="form-em-calendar"
    >
      <Form id="form-em-calendar" onFinish={handleOk}>
        {isCurrent ? (
          <Radio.Group value={isNew} onChange={handleRadioChange} style={radioStyle}>
            <Radio value={true}>Transfer to new work calendar</Radio>
            <EmCalendarForm
              errors={isNew ? errors : undefined}
              labelPrefix="New"
              style={formStyle}
              disabled={!isNew}
              onFormChange={data => setNewFormData({ ...newFormData, ...data })}
            />
            <Radio value={false} style={radioItemStyle}>
              Or make amendment to current work calendar
            </Radio>
            <EmCalendarForm
              data={data}
              errors={!isNew ? errors : undefined}
              labelPrefix="Current"
              style={formStyle}
              disabled={isNew}
              onFormChange={data => setFormData({ ...formData, ...data })}
            />
          </Radio.Group>
        ) : (
          <>
            {data != null && (
              <Alert
                message="Any changes to this historical record will not impact past transactions."
                banner
                style={alertStyle}
              />
            )}
            <EmCalendarForm
              data={data}
              hireDate={employee?.hireDate}
              errors={errors}
              onFormChange={data => setFormData({ ...formData, ...data })}
            />
          </>
        )}
      </Form>
    </DrawerForm>
  )
}

interface EmCalendarFormProps extends HTMLAttributes<HTMLDivElement> {
  data?: EmCalendarState
  hireDate?: string
  errors?: Errors
  labelPrefix?: string
  disabled?: boolean
  onFormChange: (data: IEmCalendarInfo) => void
}

const EmCalendarForm: FC<EmCalendarFormProps> = ({
  data,
  hireDate,
  errors,
  labelPrefix,
  onFormChange,
  style,
  disabled = false
}) => {
  const canModify = usePermissionGate(Permission.master, PermissionAction.Modify)
  const [formData, setFormData] = useState<IEmCalendarInfo>(EMPTY_FORM_DATA)
  const [focusRef, setFocus] = useFocus(true)
  const effectiveDateLabel = 'Effective date'
  const workScheduleLabel = 'Work schedule'
  const calendarLabel = 'Calendar'
  const whPerDayLabel = 'WH per day'
  const whPerWeekLabel = 'WH per week'
  const wdPerWeekLabel = 'WD per week'
  const whPerDayTooltip = 'Work hours per day'
  const whPerWeekTooltip = 'Work hours per week'
  const wdPerWeekTooltip = 'Work days per week'
  const calendarInfo = useSelector(
    (state: StoreState) => (state.master.masters['calendar']?.entities || {}) as Dictionary<CalendarState>
  )
  const hasAttendance = useHasFeatureFlagAttendance()

  useEffect(() => {
    setTimeout(() => !disabled && setFocus(), 100)
  }, [disabled, setFocus])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      const updated = { ...formData, ...updates }
      setFormData(updated)
      typeof onFormChange === 'function' && onFormChange(updated)
    },
    [formData, onFormChange]
  )

  useEffect(() => {
    if (data) {
      const { startDate, workSchedule, calendarId, whPerDay, whPerWeek, wdPerWeek } = data
      setFormData({ startDate, workSchedule, calendarId, whPerDay, whPerWeek, wdPerWeek })
    } else {
      setFormData({ ...EMPTY_FORM_DATA, startDate: hireDate || TODAY })
    }
  }, [data, hireDate])

  const getLabel = useCallback(
    (prefix: string | undefined, label: string) => (prefix ? `${prefix} ${label.toLowerCase()}` : label),
    []
  )

  return (
    <div style={style}>
      <Row>
        <Col span={24}>
          <Form.Item
            label={getLabel(labelPrefix, effectiveDateLabel)}
            validateStatus={errors?.startDate ? 'error' : ''}
            help={errors?.startDate}
          >
            <Input.Date
              ref={focusRef}
              value={formData.startDate ? moment(formData.startDate) : undefined}
              onChange={(value: moment.Moment | null) =>
                handleFormDataChange({ startDate: value?.format('YYYY-MM-DD') })
              }
              disabled={disabled}
            />
          </Form.Item>
        </Col>
      </Row>
      {hasAttendance && (
        <Row>
          <Col span={24}>
            <Form.Item
              label={getLabel(labelPrefix, workScheduleLabel)}
              validateStatus={errors?.workSchedule ? 'error' : ''}
              help={errors?.workSchedule}
            >
              <SysOptions
                type="work_schedule_display"
                value={formData.workSchedule}
                onChange={(workSchedule: string) => handleFormDataChange({ workSchedule })}
                disabled={disabled}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row>
        <Col span={24}>
          <Form.Item
            label={`${getLabel(labelPrefix, calendarLabel)}${
              formData.workSchedule === WorkSchedule.schedule ? ' (optional)' : ''
            }`}
            validateStatus={errors?.calendarId ? 'error' : ''}
            help={errors?.calendarId}
          >
            <NewTabLinkIcon
              path={SETTINGS_ROUTES.workCalendars}
              tooltipText="Open calendar in a new tab"
              tooltipPlacement="topRight"
              hidden={!canModify}
            >
              <EmpKeyValues
                id="calendar"
                value={formData.calendarId}
                onChange={(calendarId: string) =>
                  handleFormDataChange({
                    calendarId,
                    wdPerWeek: calendarInfo[calendarId]?.wdPerWeek || 0,
                    whPerWeek: formData.whPerDay * (calendarInfo[calendarId]?.wdPerWeek || 0)
                  })
                }
                disabled={disabled}
              />
            </NewTabLinkIcon>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <Form.Item
            label={<Tooltip title={whPerDayTooltip}>{getLabel(labelPrefix, whPerDayLabel)}</Tooltip>}
            validateStatus={errors?.whPerDay ? 'error' : ''}
            help={errors?.whPerDay}
          >
            <Input.Number
              precision={2}
              value={formData.whPerDay}
              onChange={(whPerDay: number | null) =>
                handleFormDataChange({
                  whPerDay: whPerDay || 0,
                  whPerWeek: whPerDay ? whPerDay * formData.wdPerWeek : 0
                })
              }
              disabled={disabled}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={<Tooltip title={whPerWeekTooltip}>{getLabel(labelPrefix, whPerWeekLabel)}</Tooltip>}>
            <Input.Number
              precision={2}
              value={formData.whPerWeek}
              onChange={(whPerWeek: number | null) => handleFormDataChange({ whPerWeek: whPerWeek || 0 })}
              disabled={disabled}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={<Tooltip title={wdPerWeekTooltip}>{getLabel(labelPrefix, wdPerWeekLabel)}</Tooltip>}>
            <Input.Number
              precision={2}
              value={formData.wdPerWeek}
              onChange={(wdPerWeek: number | null) => handleFormDataChange({ wdPerWeek: wdPerWeek || 0 })}
              disabled
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  )
}
