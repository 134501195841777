import React, { FC, useCallback } from 'react'
import moment from 'moment-timezone'
import { Form, Input, Space } from '~/core-components'

interface DailyRemindCronProps {
  expr: string[]
  onChange: (value: string[]) => void
}

export const DailyRemindCron: FC<DailyRemindCronProps> = ({ expr, onChange }) => {
  const timeHHMM = `${expr[1]}:${expr[0]}`

  const handleTimeChange = useCallback(
    (value: moment.Moment | null) => {
      const time = (value?.format('HH:mm') || '00:00').split(':')
      const newExpr = [...expr]
      newExpr[0] = `${parseInt(time[1])}`
      newExpr[1] = `${parseInt(time[0])}`
      onChange(newExpr)
    },
    [onChange, expr]
  )

  return (
    <Form.Item>
      <Space>
        At
        <Input.Time
          value={moment(timeHHMM, 'HH:mm')}
          onChange={handleTimeChange}
          allowClear={false}
          showNow={false}
          minuteStep={5}
        />
      </Space>
    </Form.Item>
  )
}
