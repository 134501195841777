import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { GroupChildren } from '~/types/common'
import { PayItemState } from '../types'

interface PayItemGroupChildren<T> extends GroupChildren<T> {
  category: string
}

export const selectPayItemsSgView = createSelector(
  (state: StoreState) => state.master.payItemsView,
  (state: StoreState) => state.master.keyvalues['payItemCategory'],
  (state: StoreState) => state.master.keyvalues['payItemType'],
  (payItemsSgViewState, payItemCategoriesState, payItemTypesState) => {
    const map = new Map<string, PayItemGroupChildren<PayItemState>>()
    const payItems = (Object.values(payItemsSgViewState?.entities || {}) as PayItemState[]).sort((a, b) => {
      const aCategory = a!.category
      const bCategory = b!.category
      if (aCategory === bCategory) {
        const aInactiveDate = new Date(a!.inactiveDate || '').getTime() || 0
        const bInactiveDate = new Date(b!.inactiveDate || '').getTime() || 0
        if (bInactiveDate === 0 && aInactiveDate === 0) {
          return 0
        } else {
          return aInactiveDate - bInactiveDate
        }
      } else {
        return bCategory > aCategory ? -1 : 1
      }
    })

    payItems.forEach(item => {
      if (item) {
        const {
          id,
          name,
          type,
          category,
          rateType,
          prorationType,
          rate,
          formulaId,
          wageType,
          hasSdl,
          ir8aCategory,
          isSys,
          inactiveDate,
          notes
        } = item

        const categoryName = payItemCategoriesState?.entities[category]?.value || ''
        const typeName = payItemTypesState?.entities[type]?.value || ''
        const groupName = category === 'xx' ? `${categoryName} ${typeName?.toLowerCase()}` : categoryName

        map.has(groupName) || map.set(groupName, { id: `group-${id}`, category, name: groupName, children: [] })
        map.get(groupName)?.children.push({
          id,
          name,
          type,
          category,
          rateType,
          prorationType,
          rate,
          formulaId,
          wageType,
          hasSdl,
          ir8aCategory,
          isSys,
          inactiveDate,
          notes
        })
      }
    })

    const result = [...map.values()]
    return result
  }
)
