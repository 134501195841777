import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Dictionary } from '@reduxjs/toolkit'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { FetchStrategy } from '~/types/common'
import { LeaveApplyFormInfoState } from '../types'
import { fetchLeaveApplyFormInfo } from '../actions'

export const useLeaveApplyFormInfo = (
  leaveTypeId: string,
  employeeId: string,
  fetcthStrategy: FetchStrategy = 'always'
): [Dictionary<LeaveApplyFormInfoState>, boolean] => {
  const emLtId = `${employeeId}||${leaveTypeId}`
  const formInfo = useSelector((state: StoreState) => state.leave.leaveApplyFormInfo[emLtId]?.entities)
  const loading = useSelector((state: StoreState) => state.leave.leaveApplyFormInfoLoading[emLtId])

  useEffect(() => {
    if (leaveTypeId && employeeId) {
      dispatch(fetchLeaveApplyFormInfo(leaveTypeId, employeeId, { strategy: fetcthStrategy }))
    }
  }, [leaveTypeId, employeeId, fetcthStrategy])

  return [formInfo, loading]
}
