import { apiGetMyTimeLogTaskByToken } from '../api/ss-time-log-task-token.api'
import { ThunkResult } from '~/types/store'

export const fetchMyTimeLogTaskByToken = (token: string): ThunkResult<void> => {
  return async () => {
    if (!token) return

    return await apiGetMyTimeLogTaskByToken(token)
  }
}
