import { Pagination } from '~/types/common'
import { request, getBaseUrl, timeout } from '~/utils/request'
import { SSClaimRecordAttachmentState, SSClaimRecordState, SSClaimRecordViewState } from '../types'

const baseUrl = getBaseUrl('/claim/ssclaimrecord')

export const apiGetMyClaimRecordsView = (params: Pagination) => request<SSClaimRecordViewState>('get', baseUrl, params)

export const apiGetMyClaimRecord = (id: string) => request<SSClaimRecordState>('get', `${baseUrl}/${id}`)

export const apiGetMyClaimRecordAttachments = (id: string) =>
  request<SSClaimRecordAttachmentState[]>('get', `${baseUrl}/${id}/attachment`)

export const apiDeleteMyClaimRecord = (id: string) => request<void>('delete', `${baseUrl}/${id}`)

export const apiDownloadAllMyClaimAttachment = (id: string) =>
  request('get', `${baseUrl}/${id}/attachment/downloadall`, undefined, { responseType: 'blob', timeout })
