import { replaceLocationsView, setLocationsViewLoading } from '../reducers'
import { apiGetLocationsView } from '../api/location.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchLocationsView = (
  viewId: string,
  search: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!viewId) return

    const loading = getState().attendance.locationsViewLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().attendance.locationsView?.entities[viewId]
      if (hasData) return
    }

    try {
      dispatch(setLocationsViewLoading(true))
      const { result, status } = await apiGetLocationsView(viewId, { search })
      if (status) {
        dispatch(replaceLocationsView(result))
      }
    } finally {
      dispatch(setLocationsViewLoading(false))
    }
  }
}
