import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'
import { setLveCalendarConfig, setLveCalendarConfigLoading } from '../reducers'
import { apiGetCalendarConfig } from '../api/calendar-config.api'
import { CalendarConfig } from '~/constants'

export const fetchLveCalendarConfig = (options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().leave.lveCalendarConfigLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = !!getState().leave.lveCalendarConfig?.entities[CalendarConfig.LveCalendarConfigId]
      if (hasData) return
    }

    try {
      dispatch(setLveCalendarConfigLoading(true))
      const { result, status } = await apiGetCalendarConfig()
      if (status) {
        dispatch(setLveCalendarConfig(result))
      }
    } finally {
      dispatch(setLveCalendarConfigLoading(false))
    }
  }
}
