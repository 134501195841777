import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { CardProps } from '~/core-components'
import { CardBanner } from '~/components'
import { MySsAccessGate } from '~/features/my'
import { FeatureFlag } from '~/constants'
import { StoreState } from '~/types/store'
import { MyClaimBalanceList } from '../MyClaimBalance/MyClaimBalanceList'
import { MyClaimTakenList } from '../MyClaimBalance/MyClaimTakenList'
import { MyClaimApplyButton } from '../MyClaimApply/MyClaimApplyButton'
import './MyClaimEntitlementOverview.less'

interface MyClaimEntitlementOverviewProps extends CardProps {}

export const MyClaimEntitlementOverview: FC<MyClaimEntitlementOverviewProps> = ({ className, ...props }) => {
  const classes = classNames('my-claim-entitlement-overview', { [`${className}`]: className })
  const loading = useSelector((state: StoreState) => state.myClaim.myClaimBalancesLoading)

  return (
    <MySsAccessGate featureFlag={FeatureFlag.MyClaim}>
      <CardBanner
        title="My claim balance"
        image="/my-claim-balance-background.jpg"
        loading={loading}
        className={classes}
        {...props}
      >
        <MyClaimBalanceList />
        <MyClaimTakenList />
        <MyClaimApplyButton>Apply claim</MyClaimApplyButton>
      </CardBanner>
    </MySsAccessGate>
  )
}
