import React, { FC, useState, useCallback, useEffect } from 'react'
import moment from 'moment-timezone'
import { Form, Input } from '~/core-components'
import { Col, DrawerForm, EmpKeyValues, EmSelect, NewTabLinkIcon, Row } from '~/components'
import { usePermissionGate } from '~/features/iam/hooks'
import { EmploymentConfig, Permission, PermissionAction } from '~/constants'
import { dispatch } from '~/stores/store'
import { addEmCareer } from '../../../actions'
import { IEmCareerInfo, EmCareerState } from '../../../types'
import { ActionResult, Errors } from '~/types/store'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { useFocus } from '~/hooks/use-focus'
import { apiGetEmSelect } from '~/features/employee/api/employee.api'
import { useHiddenEmploymentConfigs } from '~/features/master/hooks'

export interface MutateEmCareerDrawerProps {
  visible: boolean
  employeeId?: string
  data?: EmCareerState
  onClose: () => void
}

const EMPTY_FORM_DATA: IEmCareerInfo = {
  startDate: moment().format('YYYY-MM-DD'),
  departmentId: '',
  divisionId: '',
  sectionId: '',
  grpId: '',
  categoryId: '',
  teamId: '',
  jobId: '',
  managerId: '',
  managerSecondaryId: '',
  officeId: '',
  costCentreId: '',
  employmentTypeId: '',
  otClassId: ''
}

export const MutateEmCareerDrawer: FC<MutateEmCareerDrawerProps> = ({
  visible,
  employeeId,
  data,
  onClose
}: MutateEmCareerDrawerProps) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<IEmCareerInfo>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const [focusRef, setFocus] = useFocus(true)
  const canModify = usePermissionGate(Permission.master, PermissionAction.Modify)

  const [employmentConfigs] = useHiddenEmploymentConfigs()
  const hiddenEmployments = employmentConfigs.map(ec => ec.code)
  const hideDepartment = hiddenEmployments.includes(EmploymentConfig.department)
  const hideDivision = hiddenEmployments.includes(EmploymentConfig.division)
  const hideSection = hiddenEmployments.includes(EmploymentConfig.section)
  const hideGrp = hiddenEmployments.includes(EmploymentConfig.grp)
  const hideCategory = hiddenEmployments.includes(EmploymentConfig.category)
  const hideTeam = hiddenEmployments.includes(EmploymentConfig.team)
  const hideJob = hiddenEmployments.includes(EmploymentConfig.job)
  const hideManager = hiddenEmployments.includes(EmploymentConfig.manager)
  const hideManagerSecondary = hiddenEmployments.includes(EmploymentConfig.managerSecondary)
  const hideOffice = hiddenEmployments.includes(EmploymentConfig.office)
  const hideCostCentre = hiddenEmployments.includes(EmploymentConfig.costCentre)
  const hideEmploymentType = hiddenEmployments.includes(EmploymentConfig.employmentType)
  const hideOtClass = hiddenEmployments.includes(EmploymentConfig.otClass)

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  useEffect(() => {
    if (data) {
      const {
        startDate,
        departmentId,
        divisionId,
        sectionId,
        grpId,
        categoryId,
        teamId,
        jobId,
        managerId,
        managerSecondaryId,
        officeId,
        costCentreId,
        employmentTypeId,
        otClassId
      } = data
      setFormData({
        startDate,
        departmentId,
        divisionId,
        sectionId,
        grpId,
        categoryId,
        teamId,
        jobId,
        managerId,
        managerSecondaryId,
        officeId,
        costCentreId,
        employmentTypeId,
        otClassId
      })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      const updated = { ...formData, ...updates }
      setFormData(updated)
    },
    [formData]
  )

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      if (employeeId) {
        result = await dispatch(addEmCareer(employeeId, formData))
      }
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_FORM_DATA)
    }
  }, [employeeId, formData, onClose])

  const handleFetchEmployees = useCallback(async () => {
    const { status, result } = await apiGetEmSelect('active')
    if (status) {
      return result
    }
    return []
  }, [])

  return (
    <DrawerForm
      open={visible}
      title="New entry"
      onClose={onClose}
      confirmLoading={loading}
      width={500}
      formId="form-em-career"
    >
      <Form id="form-em-career" onFinish={handleOk}>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Effective date"
              validateStatus={errors?.startDate ? 'error' : ''}
              help={errors?.startDate}
            >
              <Input.Date
                ref={focusRef}
                value={formData.startDate ? moment(formData.startDate) : undefined}
                onChange={(value: moment.Moment | null) =>
                  handleFormDataChange({ startDate: value?.format('YYYY-MM-DD') })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        {!hideDepartment && (
          <Row>
            <Col span={24}>
              <Form.Item
                label="Department"
                validateStatus={errors?.departmentId ? 'error' : ''}
                help={errors?.departmentId}
              >
                <NewTabLinkIcon
                  path={SETTINGS_ROUTES.departments}
                  tooltipText="Open department in a new tab"
                  tooltipPlacement="topRight"
                  hidden={!canModify}
                >
                  <EmpKeyValues
                    id="department"
                    value={formData.departmentId}
                    onChange={(departmentId?: string) => handleFormDataChange({ departmentId })}
                  />
                </NewTabLinkIcon>
              </Form.Item>
            </Col>
          </Row>
        )}
        {!hideDivision && (
          <Row>
            <Col span={24}>
              <Form.Item label="Division" validateStatus={errors?.divisionId ? 'error' : ''} help={errors?.divisionId}>
                <NewTabLinkIcon
                  path={SETTINGS_ROUTES.divisions}
                  tooltipText="Open division in a new tab"
                  tooltipPlacement="topRight"
                  hidden={!canModify}
                >
                  <EmpKeyValues
                    id="division"
                    value={formData.divisionId}
                    onChange={(divisionId?: string) => handleFormDataChange({ divisionId })}
                  />
                </NewTabLinkIcon>
              </Form.Item>
            </Col>
          </Row>
        )}
        {!hideSection && (
          <Row>
            <Col span={24}>
              <Form.Item label="Section" validateStatus={errors?.sectionId ? 'error' : ''} help={errors?.sectionId}>
                <NewTabLinkIcon
                  path={SETTINGS_ROUTES.sections}
                  tooltipText="Open section in a new tab"
                  tooltipPlacement="topRight"
                  hidden={!canModify}
                >
                  <EmpKeyValues
                    id="section"
                    value={formData.sectionId}
                    onChange={(sectionId?: string) => handleFormDataChange({ sectionId })}
                  />
                </NewTabLinkIcon>
              </Form.Item>
            </Col>
          </Row>
        )}
        {!hideGrp && (
          <Row>
            <Col span={24}>
              <Form.Item label="Group" validateStatus={errors?.grpId ? 'error' : ''} help={errors?.grpId}>
                <NewTabLinkIcon
                  path={SETTINGS_ROUTES.grps}
                  tooltipText="Open grp in a new tab"
                  tooltipPlacement="topRight"
                  hidden={!canModify}
                >
                  <EmpKeyValues
                    id="grp"
                    value={formData.grpId}
                    onChange={(grpId?: string) => handleFormDataChange({ grpId })}
                  />
                </NewTabLinkIcon>
              </Form.Item>
            </Col>
          </Row>
        )}
        {!hideCategory && (
          <Row>
            <Col span={24}>
              <Form.Item label="Category" validateStatus={errors?.categoryId ? 'error' : ''} help={errors?.categoryId}>
                <NewTabLinkIcon
                  path={SETTINGS_ROUTES.categories}
                  tooltipText="Open category in a new tab"
                  tooltipPlacement="topRight"
                  hidden={!canModify}
                >
                  <EmpKeyValues
                    id="category"
                    value={formData.categoryId}
                    onChange={(categoryId?: string) => handleFormDataChange({ categoryId })}
                  />
                </NewTabLinkIcon>
              </Form.Item>
            </Col>
          </Row>
        )}
        {!hideTeam && (
          <Row>
            <Col span={24}>
              <Form.Item label="Team" validateStatus={errors?.teamId ? 'error' : ''} help={errors?.teamId}>
                <NewTabLinkIcon
                  path={SETTINGS_ROUTES.teams}
                  tooltipText="Open team in a new tab"
                  tooltipPlacement="topRight"
                  hidden={!canModify}
                >
                  <EmpKeyValues
                    id="team"
                    value={formData.teamId}
                    onChange={(teamId?: string) => handleFormDataChange({ teamId })}
                  />
                </NewTabLinkIcon>
              </Form.Item>
            </Col>
          </Row>
        )}
        {!hideJob && (
          <Row>
            <Col span={24}>
              <Form.Item label="Job title" validateStatus={errors?.jobId ? 'error' : ''} help={errors?.jobId}>
                <NewTabLinkIcon
                  path={SETTINGS_ROUTES.jobs}
                  tooltipText="Open job title in a new tab"
                  tooltipPlacement="topRight"
                  hidden={!canModify}
                >
                  <EmpKeyValues
                    id="job"
                    value={formData.jobId}
                    onChange={(jobId?: string) => handleFormDataChange({ jobId })}
                  />
                </NewTabLinkIcon>
              </Form.Item>
            </Col>
          </Row>
        )}
        {!hideManager && (
          <Row>
            <Col span={24}>
              <Form.Item
                label="Reporting to"
                validateStatus={errors?.managerId ? 'error' : ''}
                help={errors?.managerId}
              >
                <EmSelect
                  value={formData.managerId}
                  onFetch={handleFetchEmployees}
                  onChange={(managerId?: string) => handleFormDataChange({ managerId })}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        {!hideManagerSecondary && (
          <Row>
            <Col span={24}>
              <Form.Item
                label="Dotted line reporting to"
                validateStatus={errors?.managerSecondaryId ? 'error' : ''}
                help={errors?.managerSecondaryId}
              >
                <EmSelect
                  value={formData.managerSecondaryId}
                  onFetch={handleFetchEmployees}
                  onChange={(managerSecondaryId?: string) => handleFormDataChange({ managerSecondaryId })}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        {!hideOffice && (
          <Row>
            <Col span={24}>
              <Form.Item label="Office" validateStatus={errors?.officeId ? 'error' : ''} help={errors?.officeId}>
                <NewTabLinkIcon
                  path={SETTINGS_ROUTES.offices}
                  tooltipText="Open office in a new tab"
                  tooltipPlacement="topRight"
                  hidden={!canModify}
                >
                  <EmpKeyValues
                    id="office"
                    value={formData.officeId}
                    onChange={(officeId?: string) => handleFormDataChange({ officeId })}
                  />
                </NewTabLinkIcon>
              </Form.Item>
            </Col>
          </Row>
        )}
        {!hideCostCentre && (
          <Row>
            <Col span={24}>
              <Form.Item
                label="Cost centre"
                validateStatus={errors?.costCentreId ? 'error' : ''}
                help={errors?.costCentreId}
              >
                <NewTabLinkIcon
                  path={SETTINGS_ROUTES.costCentres}
                  tooltipText="Open cost centre in a new tab"
                  tooltipPlacement="topRight"
                  hidden={!canModify}
                >
                  <EmpKeyValues
                    id="costCentre"
                    value={formData.costCentreId}
                    onChange={(costCentreId?: string) => handleFormDataChange({ costCentreId })}
                  />
                </NewTabLinkIcon>
              </Form.Item>
            </Col>
          </Row>
        )}
        {!hideEmploymentType && (
          <Row>
            <Col span={24}>
              <Form.Item
                label="Employment type"
                validateStatus={errors?.employmentType ? 'error' : ''}
                help={errors?.employmentType}
              >
                <NewTabLinkIcon
                  path={SETTINGS_ROUTES.employmentTypes}
                  tooltipText="Open employment type in a new tab"
                  tooltipPlacement="topRight"
                  hidden={!canModify}
                >
                  <EmpKeyValues
                    id="employmentType"
                    value={formData.employmentTypeId}
                    onChange={(employmentTypeId?: string) => handleFormDataChange({ employmentTypeId })}
                  />
                </NewTabLinkIcon>
              </Form.Item>
            </Col>
          </Row>
        )}
        {!hideOtClass && (
          <Row>
            <Col span={24}>
              <Form.Item
                label="Overtime classification"
                validateStatus={errors?.otClassId ? 'error' : ''}
                help={errors?.otClassId}
              >
                <EmpKeyValues
                  id="otClass"
                  value={formData.otClassId}
                  onChange={(otClassId?: string) => handleFormDataChange({ otClassId })}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    </DrawerForm>
  )
}
