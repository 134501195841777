import { replaceSysJobs, setSysJobsLoading } from '../reducers'
import { apiGetSysJobs } from '../api/sys-job.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchSysJobs = (options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().job.sysJobsLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().job.sysJobs?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setSysJobsLoading(true))
      const { result, status } = await apiGetSysJobs()
      if (status) {
        dispatch(replaceSysJobs(result))
      }
    } finally {
      dispatch(setSysJobsLoading(false))
    }
  }
}
