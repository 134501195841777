import React, { CSSProperties, FC, useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import moment from 'moment-timezone'
import {
  Button,
  Card,
  ColumnsType,
  Form,
  Skeleton,
  Space,
  Table,
  TableRowSelection,
  Text,
  Tooltip
} from '~/core-components'
import { Person, SearchInput, WeeklyNavigation } from '~/components'
import {
  Screen,
  ViewCriteria,
  ViewCriteriaSimple,
  updateViewCriteria,
  useFirstView,
  useViewSchema
} from '~/features/selection'
import { usePermissionGate } from '~/features/iam'
import { Permission, PermissionAction } from '~/constants'
import { StoreState } from '~/types/store'
import { dispatch } from '~/stores/store'
import { formatHourMinute, getBaseUrl } from '~/utils'
import { useToggle } from '~/hooks'
import { SCH_ROUTES } from '~/routes/routes'
import { useScheduleRecordsView } from '../../hooks'
import { ScheduleRecordRowState, ScheduleRecordViewDataState } from '../../types'
import { refetchScheduleRecordsView } from '../../reducers'
import { ScheduledShiftDay } from './components/ScheduledShiftDay'
import { MutateScheduleRecordDrawer } from './components/MutateScheduleRecordDrawer'
import { BulkAddScheduleRecordButton } from './components/BulkAddScheduleRecordButton'
import { InitialiseScheduleRecordButton } from './components/InitialiseScheduleRecordButton'
import { CopyScheduleRecordButton } from './components/CopyScheduleRecordButton'
import './ScheduleRecords.less'

interface ScheduleTable extends ScheduleRecordRowState {}

interface ScheduleRecordsParams {
  start: string
}

interface DrawerState {
  visible: boolean
  defaultEmployeeId?: string
  defaultDate?: string
  data?: ScheduleRecordViewDataState
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }
const DEBOUNCE_TIMEOUT = 800

const SCREEN_CODE: Screen = 'schedule_record'
const PAGE_SIZE_OPTIONS = ['20', '50', '100']

const nameColWidth = 220
const shiftDayColWidth = 120
const defaultColWidth = 150
const paginationStyle: CSSProperties = { marginRight: 20 }

const weekStart = moment().startOf('isoWeek')
const defaultStartDate = weekStart.format('YYYY-MM-DD')
const baseUrl = getBaseUrl('/filestore')

export const ScheduleRecords: FC = () => {
  const { start } = useParams<ScheduleRecordsParams>()
  const history = useHistory()

  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(20)
  const [search, setSearch] = useState<string>('')
  const [showShiftDetails, toggleShiftDetails] = useToggle(false)

  const startDate = start || defaultStartDate
  const endDate = moment(startDate).add(6, 'days').format('YYYY-MM-DD')
  // console.log('start', start, startDate)

  const selection = useSelector((state: StoreState) => state.selection.sysSelectionFields[SCREEN_CODE])
  const [view, viewLoading] = useFirstView(SCREEN_CODE)
  const viewId = view?.id || ''
  const [schema] = useViewSchema(SCREEN_CODE, viewId)

  const rowLoading = useSelector((state: StoreState) => state.schedule.refetchScheduleRecordsViewRow)
  const cellLoading = useSelector((state: StoreState) => state.schedule.refetchScheduleRecordsViewCell)

  const [data, dataLoading] = useScheduleRecordsView(
    viewId,
    startDate,
    endDate,
    page,
    pageSize,
    search,
    DEBOUNCE_TIMEOUT
  )
  const [selected, setSelected] = useState<string[]>([])
  const ids = useMemo(() => data?.data.map(d => d.employeeId) || [], [data])

  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const canModify = usePermissionGate(Permission.schRecord, PermissionAction.Modify)

  const handleCriteriaApply = useCallback(
    async (criteria: ViewCriteria[]) => {
      if (viewId) {
        setPage(1)
        await dispatch(updateViewCriteria(SCREEN_CODE, viewId, { id: viewId, criteria }))
        dispatch(refetchScheduleRecordsView())
      }
    },
    [viewId]
  )

  const handlePaginationChange = useCallback((page: number, pageSize?: number) => {
    setPage(page)
    setPageSize(pageSize || 20)
  }, [])

  const handleSearch = useCallback((value: string) => {
    setPage(1)
    setSearch(value)
  }, [])

  const handleWeekChange = useCallback(
    (startDate: moment.Moment) => {
      const start = startDate ? startDate.format('YYYY-MM-DD') : ''
      history.replace(SCH_ROUTES.records.replace(':start', start))
      setPage(1)
      setSelected([])
    },
    [history]
  )

  const handleRefreshClick = useCallback(() => {
    dispatch(refetchScheduleRecordsView())
  }, [])

  const handleScheduleClick = useCallback((schedule: ScheduleRecordViewDataState) => {
    setDrawerState({ visible: true, data: schedule })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const handleAddShiftDay = useCallback((employeeId: string, scheduleDate: string) => {
    setDrawerState({ visible: true, defaultEmployeeId: employeeId, defaultDate: scheduleDate })
  }, [])

  const tableWidth =
    (schema?.selection.reduce(
      (sum, f) => (sum += selection?.entities[f.selectionFieldId]?.width || defaultColWidth),
      0
    ) || 0) +
    nameColWidth +
    7 * shiftDayColWidth

  const columns = useMemo(() => {
    let columns: ColumnsType<ScheduleTable> = [
      {
        // title: (
        //   <Select allowClear={false} value="view_emp" bordered={false}>
        //     <Select.Option value="view_emp">View by employee</Select.Option>
        //     <Select.Option value="view_role">View by role</Select.Option>
        //   </Select>
        // ),
        title: '',
        key: 'employeeName',
        dataIndex: 'employeeName',
        width: nameColWidth,
        render: (value: string, record) => (
          <Person
            name={record.employeeName}
            description={record.description}
            photo={record.photoId && `${baseUrl}/file/${record.photoId}/thumbnailphoto/36`}
          />
        )
      }
    ]

    for (let m = moment(startDate); m.isSameOrBefore(endDate); m.add(1, 'day')) {
      const currentDate = m.format('YYYY-MM-DD')

      columns.push({
        title: (
          <>
            <div className="shift-day__dow">{m.format('ddd')}</div>
            {m.format('D/M')}
          </>
        ),
        key: m.format('D/M'),
        dataIndex: `d${m.diff(moment(startDate), 'day')}`,
        className: 'shift-day__date',
        align: 'center',
        render: (value: ScheduleRecordViewDataState[], record) => {
          if (viewLoading || (!rowLoading && !cellLoading && dataLoading) || rowLoading === record.employeeId) {
            return <Skeleton.Input size="small" active />
          }

          return (
            <div className="shift-day__container">
              {Array.isArray(value) && value?.length > 0 ? (
                value.map(s => (
                  <ScheduledShiftDay
                    key={s.id}
                    schedule={s}
                    showLocation={showShiftDetails}
                    showShiftRole={showShiftDetails}
                    readOnly={!canModify}
                    loading={cellLoading === s.id}
                    onClick={() => handleScheduleClick(s)}
                  />
                ))
              ) : (
                <Button
                  type="dashed"
                  className="shift-day__add"
                  icon={<i className="fal fa-plus" />}
                  onClick={() => handleAddShiftDay(record.employeeId, currentDate)}
                />
              )}
            </div>
          )
        }
      })
    }

    columns.push({
      key: 'summary',
      dataIndex: 'employeeId',
      className: 'shift-day__summary',
      width: 55,
      render: (value: string, record) => {
        let totalHours = 0
        for (let i = 0; i <= 6; i++) {
          if (Array.isArray(record[`d${i}`]) && record[`d${i}`]?.length > 0) {
            totalHours += record[`d${i}`].reduce(
              (sum: number, v: ScheduleRecordViewDataState) => sum + v.normalHours,
              0
            )
          }
        }
        return (
          <Space size={4}>
            <i className="fal fa-clock" />
            {formatHourMinute(totalHours, 'hour')}
          </Space>
        )
      }
    })

    return columns
  }, [
    startDate,
    endDate,
    showShiftDetails,
    canModify,
    viewLoading,
    dataLoading,
    rowLoading,
    cellLoading,
    handleScheduleClick,
    handleAddShiftDay
  ])

  const rowSelection: TableRowSelection<ScheduleRecordRowState> = useMemo(
    () => ({
      type: 'checkbox',
      selectedRowKeys: selected,
      hideSelectAll: false,
      columnWidth: 45,
      onChange: (_selectedRowKeys, selectedRows) => {
        setSelected(s => [...s.filter(employeeId => !ids.includes(employeeId)), ...selectedRows.map(o => o.employeeId)])
      }
    }),
    [selected, ids]
  )

  return (
    <div className="schedule-records">
      <div className="schedule-records__body">
        <div className="schedule-records__action-bar">
          <Form.Item label="">
            <SearchInput onSearch={handleSearch} placeholder="Search by name" expandWidth={250} />
          </Form.Item>
          <ViewCriteriaSimple screenCode={SCREEN_CODE} viewId={viewId} onApply={handleCriteriaApply} label="" />
          {data?.hasShift && (
            <Tooltip title={showShiftDetails ? 'Hide shift details' : 'Show shift details'}>
              <Button
                icon={<i className="fal fa-info" />}
                type={showShiftDetails ? 'primary' : 'default'}
                onClick={toggleShiftDetails}
              />
            </Tooltip>
          )}
          <InitialiseScheduleRecordButton
            mode={!data?.hasShift ? 'button-text' : 'button-icon'}
            startDate={startDate}
            endDate={endDate}
          />
          <CopyScheduleRecordButton startDate={startDate} employeeIds={selected} />
          <BulkAddScheduleRecordButton startDate={startDate} />
          <Form.Item label="">
            <WeeklyNavigation value={moment(startDate)} onChange={handleWeekChange} />
          </Form.Item>
          <Tooltip title="Refresh" placement="topRight">
            <Button icon={<i className="fal fa-refresh" />} onClick={handleRefreshClick} loading={dataLoading} />
          </Tooltip>
        </div>
        <Card table fitParent>
          <Table
            rowKey="employeeId"
            dataSource={data?.data}
            rowSelection={rowSelection}
            fitParent
            columns={columns}
            loading={dataLoading || viewLoading}
            scroll={{ x: tableWidth, y: 1000 }}
            pagination={{
              total: data?.count,
              current: page,
              pageSize,
              pageSizeOptions: PAGE_SIZE_OPTIONS,
              showSizeChanger: true,
              onChange: handlePaginationChange,
              style: paginationStyle,
              showTotal: (total: number, range: [number, number]) => {
                if (range && range.length === 2 && !isNaN(range[0]) && !isNaN(range[1])) {
                  return (
                    <Text size="small">
                      {range[0]}-{range[1]} of {total}
                    </Text>
                  )
                }
              }
            }}
          />
        </Card>
        {canModify && <MutateScheduleRecordDrawer {...drawerState} onClose={handleCloseDrawer} />}
      </div>
    </div>
  )
}
