import React, { FC, useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import confirm from 'antd/lib/modal/confirm'
import { Button, PageHeader } from '~/core-components'
import { DocumentTitle } from '~/components'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { MenuItem } from '~/types/common'
import { deleteOffice, fetchCompanies, fetchOfficeCompanies, fetchEmpKeyvalues } from '../../actions'
import { useOffice, useOffices } from '../../hooks'
import { useMasterMenu } from '../MasterMenuContext'
import { commonMenus } from '../../configs'
import { OfficeInfo } from './components/OfficeInfo'
import { OfficeCompanies } from './components/OfficeCompanies'
import { AddOfficeDrawer } from './components/AddOfficeDrawer'
import './Office.less'

interface OfficeProps {}

interface OfficeParams {
  id: string
}

interface DrawerState {
  visible: boolean
}
const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: SETTINGS_ROUTES.offices,
    breadcrumbName: 'Overview'
  },
  {
    path: '',
    breadcrumbName: 'Office'
  }
]

export const Office: FC<OfficeProps> = () => {
  const { id } = useParams<OfficeParams>()
  const [offices] = useOffices()
  const [office] = useOffice(id)
  const [editing, setEditing] = useState(false)
  const history = useHistory()
  const { setMenus } = useMasterMenu()
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const canModify = usePermissionGate(Permission.master, PermissionAction.Modify)

  useEffect(() => {
    dispatch(fetchCompanies({ strategy: 'when-empty' }))
    dispatch(fetchEmpKeyvalues('country', undefined, undefined, { strategy: 'when-empty' }))
  }, [])

  useEffect(() => {
    if (id) {
      dispatch(fetchOfficeCompanies(id))
    }
  }, [id])

  const handleAddOffice = useCallback(() => {
    setDrawerState({ visible: true })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  useEffect(() => {
    const menus: MenuItem[] = Object.values(offices).map(co => ({
      path: SETTINGS_ROUTES.office.replace(':id', co!.id),
      value: co!.name,
      sysPermissionId: Permission.master
    }))

    if (canModify)
      menus.push({
        path: (
          <Button type="dashed" onClick={handleAddOffice} block>
            Add office
          </Button>
        ),
        value: '',
        sysPermissionId: ''
      })

    setMenus(menus)
    return () => {
      setMenus(commonMenus)
    }
  }, [setMenus, offices, canModify, handleAddOffice])

  const handleEdit = useCallback(() => setEditing(true), [])
  const handleView = useCallback(() => setEditing(false), [])

  const handleDelete = useCallback(() => {
    if (id && office) {
      confirm({
        title: 'Delete office',
        content: `Do you want to delete office "${office?.name}"?`,
        onOk: () => {
          dispatch(deleteOffice(id))
          history.push(SETTINGS_ROUTES.offices)
        },
        okText: 'Delete',
        okType: 'danger'
      })
    }
  }, [id, office, history])

  return (
    <div id="office" className="office">
      <DocumentTitle title="Office" />
      <PageHeader
        title="Office"
        containerId="office"
        breadcrumb={{ routes }}
        extra={canModify && editing && <Button onClick={handleDelete}>Delete</Button>}
      />
      <div className="office__body">
        <OfficeInfo office={office} onEdit={handleEdit} onSave={handleView} onCancel={handleView} />
        <OfficeCompanies officeId={id} />
        {canModify && <AddOfficeDrawer {...drawerState} onClose={handleCloseDrawer} />}
      </div>
    </div>
  )
}
