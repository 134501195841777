import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiDeletePayRun } from '../api/payrun.api'
import { removePayRun } from '../reducers'

export const deletePayRun = (id: string): ThunkResult<void> => {
  return async dispatch => {
    if (!id) return

    const { status, errors, message, errorData } = await apiDeletePayRun(id)
    if (status) {
      dispatch(removePayRun(id))
      showSuccess('Deleted')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
