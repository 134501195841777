import { setLogin } from '../reducers'
import { apiGetMyLogin } from '../api/login.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchMyLogin = (tenantCode: string, options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const myJwt = getState().iam.myJwt
    const myLoginId = myJwt?.loginId || ''

    if (options.strategy === 'when-empty') {
      const hasData = getState().iam.logins?.entities[myLoginId]
      if (hasData) return
    }
    const { result, status } = await apiGetMyLogin(tenantCode)
    if (status) {
      dispatch(setLogin(result))
    }
  }
}
