import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { fetchViews, Screen, ViewSchemaState } from '~/features/selection'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchViewSchemaByName } from '../actions'
import { selectViewSchemaByName } from '../selectors'

export const useViewSchemaByName = (
  screenCode: Screen,
  viewName?: string,
  runSetup: boolean = true
): [ViewSchemaState | undefined, boolean] => {
  const schema = useSelector(selectViewSchemaByName)(screenCode, viewName || '')
  const loading = useSelector((state: StoreState) => {
    const viewLoading = state.selection.viewLoading[screenCode]
    return (viewLoading && viewLoading[viewName || '']) || false
  })

  useEffect(() => {
    dispatch(fetchViews(screenCode))
  }, [screenCode])

  useEffect(() => {
    if (viewName) dispatch(fetchViewSchemaByName(screenCode, viewName, runSetup))
  }, [screenCode, viewName, runSetup])

  return [schema, loading]
}
