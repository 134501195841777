import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { setIr8aProcessing } from '../reducers'
import { apiResetIr8a } from '../api/ytd.api'

export const resetIr8a = (ytdSubmissionId: string, employeeTaxNo: string): ThunkResult<void> => {
  return async dispatch => {
    if (!ytdSubmissionId) return

    try {
      dispatch(setIr8aProcessing(true))
      const { status, errors, message, errorData } = await apiResetIr8a(ytdSubmissionId, employeeTaxNo)
      if (status) {
        showSuccess('IR8A reset')
      } else {
        if (Object.keys(errors).length === 0) {
          showError(message, errorData)
        }
      }
    } finally {
      dispatch(setIr8aProcessing(false))
    }
  }
}
