import React, { forwardRef } from 'react'
import { KeyValues, KeyValuesProps } from './KeyValues'

interface AttKeyValuesProps extends Omit<KeyValuesProps, 'basePath'> {}

const AttKeyValuesInternal = (props: AttKeyValuesProps, ref: React.Ref<any>) => (
  <KeyValues ref={ref} basePath="attendance" {...props} />
)

export const AttKeyValues = forwardRef(AttKeyValuesInternal)
