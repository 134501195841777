import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import persistReducer from 'redux-persist/es/persistReducer'
import {
  myAttendancePersistConfig,
  SSTimeLogTaskState,
  MyAttendanceRootState,
  SSTimeLogState,
  SSTimeLogApprovalHistoryState
} from './types'

const myTimeLogTasksAdapter = createEntityAdapter<SSTimeLogTaskState>()
const myTimeLogsAdapter = createEntityAdapter<SSTimeLogState>()
const myTimeLogApprovalHistoriesAdapter = createEntityAdapter<SSTimeLogApprovalHistoryState>()

const myTimeLogTasksInitialState = myTimeLogTasksAdapter.getInitialState()
const myTimeLogsInitialState = myTimeLogsAdapter.getInitialState()
const myTimeLogApprovalHistoriesInitialState = myTimeLogApprovalHistoriesAdapter.getInitialState()

const initialState: MyAttendanceRootState = {
  myTimeLogTasks: myTimeLogTasksInitialState,
  myTimeLogTasksLoading: false,
  myTimeLogTasksRefetch: 0,

  myTimeLogs: myTimeLogsInitialState,
  myTimeLogsLoading: false,
  myTimeLogsRefetch: 0,

  myTimeLogApprovalHistories: {},
  myTimeLogApprovalHistoriesLoading: {}
}

const myAttendanceSlice = createSlice({
  name: 'myattendance',
  initialState,
  reducers: {
    // myTimeLogTasks
    refetchMyTimeLogTasks: state => {
      state.myTimeLogTasksRefetch += 1
    },
    setMyTimeLogTasksLoading: (state, action: PayloadAction<boolean>) => {
      state.myTimeLogTasksLoading = action.payload
    },
    replaceMyTimeLogTasks: (state, action: PayloadAction<SSTimeLogTaskState[]>) => {
      myTimeLogTasksAdapter.setAll(state.myTimeLogTasks, action.payload)
    },
    setMyTimeLogTask: (state, action: PayloadAction<SSTimeLogTaskState>) => {
      myTimeLogTasksAdapter.upsertOne(state.myTimeLogTasks, action.payload)
    },
    removeMyTimeLogTask: (state, action: PayloadAction<string>) => {
      myTimeLogTasksAdapter.removeOne(state.myTimeLogTasks, action.payload)
    },

    // myTimeLogs
    replaceMyTimeLogs: (state, action: PayloadAction<SSTimeLogState[]>) => {
      myTimeLogsAdapter.setAll(state.myTimeLogs, action.payload)
    },
    setMyTimeLogsLoading: (state, action: PayloadAction<boolean>) => {
      state.myTimeLogsLoading = action.payload
    },
    refetchMyTimeLogs: state => {
      state.myTimeLogsRefetch += 1
    },

    // myTimeLogApprovalHistories
    setMyTimeLogApprovalHistoriesLoading: (state, action: PayloadAction<{ id: string; loading: boolean }>) => {
      const { id, loading } = action.payload
      state.myTimeLogApprovalHistories[id] =
        state.myTimeLogApprovalHistories[id] || myTimeLogApprovalHistoriesInitialState
      state.myTimeLogApprovalHistoriesLoading[id] = loading
    },
    replaceMyTimeLogApprovalHistories: (
      state,
      action: PayloadAction<{ id: string; data: SSTimeLogApprovalHistoryState[] }>
    ) => {
      const { id, data } = action.payload
      state.myTimeLogApprovalHistories[id] =
        state.myTimeLogApprovalHistories[id] || myTimeLogApprovalHistoriesInitialState
      myTimeLogApprovalHistoriesAdapter.setAll(state.myTimeLogApprovalHistories[id]!, data)
    }
  }
})

export const {
  // myTimeLogTasks
  refetchMyTimeLogTasks,
  setMyTimeLogTasksLoading,
  replaceMyTimeLogTasks,
  setMyTimeLogTask,
  removeMyTimeLogTask,

  // timeLogs
  replaceMyTimeLogs,
  setMyTimeLogsLoading,
  refetchMyTimeLogs,

  // myTimeLogApprovalHistories
  setMyTimeLogApprovalHistoriesLoading,
  replaceMyTimeLogApprovalHistories
} = myAttendanceSlice.actions

export const myAttendanceReducers = {
  myAttendance: persistReducer<MyAttendanceRootState>(myAttendancePersistConfig, myAttendanceSlice.reducer)
}
