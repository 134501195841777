import React, { FC, useState, useCallback, ChangeEvent } from 'react'
import { useHistory } from 'react-router'
import { Form, Input } from '~/core-components'
import { Col, DrawerForm, Row } from '~/components'
import { dispatch } from '~/stores/store'
import { useFocus } from '~/hooks/use-focus'
import { ActionResult, Errors } from '~/types/store'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { addLogin } from '../../../actions'
import { LoginInfoState } from '../../../types'

interface AddLoginDrawerProps {
  visible: boolean
  onClose: () => void
}

const EMPTY_FORM_DATA: LoginInfoState = { email: '', loginName: '', isSuperUser: false, timeZoneId: '' }

export const AddLoginDrawer: FC<AddLoginDrawerProps> = ({ visible, onClose }: AddLoginDrawerProps) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<LoginInfoState>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const [focusRef] = useFocus(true)
  const history = useHistory()

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      const updated = { ...formData, ...updates }
      setFormData(updated)
    },
    [formData]
  )

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      result = await dispatch(addLogin(formData))
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_FORM_DATA)
      history.push(SETTINGS_ROUTES.login.replace(':id', result?.result?.id))
    }
  }, [formData, onClose, history])

  return (
    <DrawerForm
      open={visible}
      title="Add user"
      onClose={onClose}
      confirmLoading={loading}
      width={650}
      formId="form-login"
    >
      <Form id="form-login" onFinish={handleOk}>
        <Row>
          <Col span={12}>
            <Form.Item label="Email" validateStatus={errors?.email ? 'error' : ''} help={errors?.email}>
              <Input
                ref={focusRef}
                value={formData.email}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ email: event.target.value })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item label="Name" validateStatus={errors?.loginName ? 'error' : ''} help={errors?.loginName}>
              <Input
                value={formData.loginName}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleFormDataChange({ loginName: event.target.value })
                }
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}
