import { showError, showSuccess } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiDeleteMyLeaveRecord } from '../../api/ssleave-record.api'
import {
  refetchMyLeaveBalances,
  refetchMyLeaveCalendarEvents,
  refetchMyLeaveEntitlements,
  refetchMyLeaveRecordsView,
  removeMyLeaveRecord
} from '../../reducers'

export const deleteMyLeave = (id: string): ThunkResult<void> => {
  return async dispatch => {
    if (!id) return

    const { status, errors, message, errorData } = await apiDeleteMyLeaveRecord(id)
    if (status) {
      dispatch(removeMyLeaveRecord(id))
      dispatch(refetchMyLeaveBalances())
      dispatch(refetchMyLeaveEntitlements())
      dispatch(refetchMyLeaveRecordsView())
      dispatch(refetchMyLeaveCalendarEvents())

      showSuccess('Deleted')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
