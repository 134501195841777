import React, { FC, useState, useCallback, useEffect, ChangeEvent } from 'react'
import moment from 'moment-timezone'
import { RangeValue } from 'rc-picker/lib/interface.d'
import { Form, Input } from '~/core-components'
import { Col, DrawerForm, Row } from '~/components'
import { useFocus } from '~/hooks/use-focus'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors } from '~/types/store'
import { mapPayRunStateToPayRunInfo, IPayRunInfo, PayRunState } from '../../types'
import { updatePayRun } from '../../actions'

export interface EditPayRunDrawerProps {
  visible: boolean
  data?: PayRunState
  onClose: () => void
}

const EMPTY_FORM_DATA: IPayRunInfo = {
  description: '',
  payDate: '',
  otStartDate: '',
  otEndDate: ''
}

export const EditPayRunDrawer: FC<EditPayRunDrawerProps> = ({ visible, data, onClose }) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<IPayRunInfo>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const [focusRef, setFocus] = useFocus(true)

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  useEffect(() => {
    if (data) {
      const { description, payDate, otStartDate, otEndDate } = data
      setFormData({ description, payDate, otStartDate, otEndDate })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      const updated = { ...formData, ...updates }
      setFormData(updated)
    },
    [formData]
  )

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      if (data) {
        result = await dispatch(updatePayRun(data.id, mapPayRunStateToPayRunInfo(data), formData))
      }
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data, formData, onClose])

  return (
    <DrawerForm
      open={visible}
      title="Payroll run"
      onClose={onClose}
      confirmLoading={loading}
      width={500}
      formId="form-payrun"
    >
      <Form id="form-payrun" onFinish={handleOk}>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Description"
              validateStatus={errors?.description ? 'error' : ''}
              help={errors?.description}
            >
              <Input
                ref={focusRef}
                value={formData.description}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleFormDataChange({ description: event.target.value })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Payment date" validateStatus={errors?.payDate ? 'error' : ''} help={errors?.payDate}>
              <Input.Date
                ref={focusRef}
                value={formData.payDate ? moment(formData.payDate) : undefined}
                onChange={(value: moment.Moment | null) =>
                  handleFormDataChange({ payDate: value?.format('YYYY-MM-DD') })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col flex="1">
            <Form.Item
              label="Overtime period"
              validateStatus={errors?.otStartDate || errors?.otEndDate ? 'error' : ''}
              help={errors?.otStartDate || errors?.otEndDate}
            >
              <Input.DateRange
                allowClear={false}
                value={[
                  formData.otStartDate ? moment(formData.otStartDate) : null,
                  formData.otEndDate ? moment(formData.otEndDate) : null
                ]}
                onCalendarChange={(dates: RangeValue<moment.Moment>) => {
                  const otStartDate = dates && dates[0] ? dates[0].format('YYYY-MM-DD') : ''
                  const otEndDate = dates && dates[1] ? dates[1].format('YYYY-MM-DD') : ''
                  handleFormDataChange({ otStartDate, otEndDate })
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}
