import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { EmFamilyRequestState } from '../types'

export const selectEmFamilyRequests = createSelector(
  (state: StoreState) => state.employee.emFamilyRequests,
  emFamilyRequestsState =>
    memoize((employeeId?: string) => {
      if (!employeeId) return []

      return Object.values(emFamilyRequestsState[employeeId]?.entities || {}) as EmFamilyRequestState[]
    })
)
