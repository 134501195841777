import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Card, Table, Link, LinkGroup, SecondaryLink, ColumnsType } from '~/core-components'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { AddCompanyDrawer } from './AddCompanyDrawer'
import { deleteCompanyOffice, fetchOfficeCompanies } from '../../../actions'
import { CompanyOfficeState, CompanyState } from '../../../types'
import { selectOfficeCompaniesOverview } from '../../../selectors'

interface OfficeCompaniesProps {
  officeId?: string
}

interface DrawerState {
  visible: boolean
}

interface AddDrawerState extends DrawerState {
  data?: { officeId: string }
}

type CompanyOfficeTable = CompanyOfficeState & CompanyState

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

export const OfficeCompanies: FC<OfficeCompaniesProps> = ({ officeId }: OfficeCompaniesProps) => {
  const [addDrawerState, setAddDrawerState] = useState<AddDrawerState>(DEFAULT_DRAWER_STATE)
  const officeCompanies = useSelector(selectOfficeCompaniesOverview)(officeId)
  const canModify = usePermissionGate(Permission.master, PermissionAction.Modify)
  const loading = useSelector((state: StoreState) => state.master.companyOfficesLoading)

  useEffect(() => {
    if (officeId) {
      dispatch(fetchOfficeCompanies(officeId))
    }
  }, [officeId])

  const handleAddCompany = useCallback(() => {
    if (officeId) {
      setAddDrawerState({ visible: true, data: { officeId } })
    }
  }, [officeId])

  const handleDeleteCompany = useCallback(({ id, companyId, officeId }: CompanyOfficeTable) => {
    dispatch(deleteCompanyOffice(id, companyId, officeId))
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setAddDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const columns: ColumnsType<CompanyOfficeTable> = [
    {
      title: 'Company',
      key: 'name',
      dataIndex: 'name'
    },
    {
      key: 'action',
      align: 'right',
      render: (value: string, record) =>
        canModify && (
          <LinkGroup>
            <SecondaryLink size="small" onClick={() => handleDeleteCompany(record)}>
              unlink
            </SecondaryLink>
          </LinkGroup>
        )
    }
  ]

  return (
    <Card
      title="Under company"
      extra={canModify && officeId && <Link onClick={handleAddCompany}>add</Link>}
      loading={loading}
      table
    >
      <Table rowKey="id" dataSource={officeCompanies} pagination={false} columns={columns} loading={loading} />
      {canModify && <AddCompanyDrawer {...addDrawerState} onClose={handleCloseDrawer} />}
    </Card>
  )
}
