import React, { FC } from 'react'
import { default as AntTag, TagProps as AntTagProps } from 'antd/lib/tag'
import classNames from 'classnames'
import 'antd/es/tag/style'
import './Tag.less'

export type TagType =
  | 'original'
  | 'white'
  | 'neutral'
  | 'success'
  | 'primary'
  | 'primary-filled'
  | 'secondary'
  | 'secondary-filled'
  | 'tertiary'
  | 'danger'
  | 'gray'

export interface TagProps extends AntTagProps {
  type?: TagType
}

export const Tag: FC<TagProps> = ({ type = 'white', className, ...props }) => (
  <AntTag className={classNames(className, `tag--${type}`)} {...props} />
)
