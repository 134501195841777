import { request, getBaseUrl } from '~/utils/request'
import { ClaimImportState, ClaimImportValidateState, ClaimImportExecuteState } from '../types'

const baseUrl = getBaseUrl('/payroll-sg/claimimport')

export const apiPrepareClaimImport = (payRunId: string, upToDate: string) => {
  return request<ClaimImportState>('post', `${baseUrl}/prepare`, { payRunId, upToDate })
}

export const apiValidateClaimImport = (payRunId: string, claimRecordIds: string[]) =>
  request<ClaimImportValidateState>('post', `${baseUrl}/validate`, { payRunId, claimRecordIds })

export const apiExecuteClaimImport = (payRunId: string) =>
  request<ClaimImportExecuteState>('post', `${baseUrl}/execute`, { payRunId })
