import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiDisconnectXero } from '../api/xero.api'
import { removeXeroConnection } from '../reducers'

export const disconnectXeroConnection = (connectionId: string): ThunkResult<void> => {
  return async dispatch => {
    if (!connectionId) return

    const { status, errors, message, errorData } = await apiDisconnectXero(connectionId)
    if (status) {
      dispatch(removeXeroConnection(connectionId))
      showSuccess('Disconnected')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
