import { showError, showSuccess } from '~/utils'
import { ThunkResult } from '~/types/store'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { apiUpdateDailyPolicy } from '../api/daily-policy.api'
import { DailyPolicyState } from '../types'
import { setDailyPolicy } from '../reducers'

export const updateDailyPolicy = (
  id: string,
  before: DailyPolicyState,
  request: DailyPolicyState
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const after = { ...before, ...request } as DailyPolicyState
    const patch = generateJsonPatch<DailyPolicyState>(before, after)
    const { status, errors, message, errorData } = await apiUpdateDailyPolicy(id, patch)
    if (status) {
      const policy = getState().attendance.dailyPolicies?.entities[id] as DailyPolicyState
      const updated: DailyPolicyState = { ...policy, ...after }
      dispatch(setDailyPolicy(updated))
      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
