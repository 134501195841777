import { request, getBaseUrl } from '~/utils/request'
import { IPayTranImport, PayTranImportState } from '../types'

const baseUrl = getBaseUrl('/payroll-sg/paytranimport')

export const apiGetPayTranImportByBatchId = (batchId: string, employeeId: string) =>
  request<PayTranImportState[]>('get', `${baseUrl}/batch/${batchId}/${employeeId}`)

export const apiGetPayTranImportAdjustment = (payRunId: string, employeeId: string, payItemId: string) =>
  request<PayTranImportState>('get', `${baseUrl}/${payRunId}/${employeeId}/${payItemId}/adjustment`)

export const apiAddPayTranImportBatch = (payRunId: string, employeeId: string, imports: IPayTranImport[]) =>
  request<void>('post', `${baseUrl}/${payRunId}/${employeeId}`, imports)

export const apiUpdatePayTranImportBatch = (
  payRunId: string,
  employeeId: string,
  batchId: string,
  payItemId: string,
  imports: IPayTranImport[]
) => request<{ batchId: string }>('patch', `${baseUrl}/${payRunId}/${employeeId}/${batchId}/${payItemId}`, imports)

export const apiDeletePayTranImportBatch = (payRunId: string, employeeId: string, batchId: string, payItemId: string) =>
  request<void>('delete', `${baseUrl}/${payRunId}/${employeeId}/${batchId}/${payItemId}`)
