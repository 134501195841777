import { setIr8aFormRefetch, setIr8sForm, setIr8sFormUpdating } from '../reducers'
import { apiUpdateIr8sDraft } from '../api/ytd.api'
import { ThunkResult } from '~/types/store'
import { showSuccess, showError } from '~/utils/msgUtil'
import { Ir8sFormState, IYtdUpdateIr8sDraft } from '../types'

export const updateIr8sDraft = (request: Ir8sFormState): ThunkResult<void> => {
  return async dispatch => {
    const updateData = mapToDto(request)

    try {
      dispatch(setIr8sFormUpdating(true))
      const { status, errors, message, errorData } = await apiUpdateIr8sDraft(updateData)
      if (status) {
        dispatch(setIr8sForm(request))
        dispatch(setIr8aFormRefetch())
        showSuccess('Saved')
      } else {
        if (Object.keys(errors).length === 0) {
          showError(message, errorData)
        }
      }
      return { errors }
    } finally {
      dispatch(setIr8sFormUpdating(false))
    }
  }
}

const mapToDto = (ir8sForm: Ir8sFormState): IYtdUpdateIr8sDraft => {
  const updateForm: IYtdUpdateIr8sDraft = {
    id: ir8sForm.id,
    ytdSubmissionId: ir8sForm.ytdSubmissionId,
    employeeTaxNo: ir8sForm.employeeTaxNo,
    cpfEmExcess: ir8sForm.cpfEmExcess,
    cpfErExcess: ir8sForm.cpfErExcess,
    isFullContributionApproved: ir8sForm.isFullContributionApproved,
    aw01: ir8sForm.aw01,
    aw02: ir8sForm.aw02,
    aw03: ir8sForm.aw03,
    aw04: ir8sForm.aw04,
    aw05: ir8sForm.aw05,
    aw06: ir8sForm.aw06,
    aw07: ir8sForm.aw07,
    aw08: ir8sForm.aw08,
    aw09: ir8sForm.aw09,
    aw10: ir8sForm.aw10,
    aw11: ir8sForm.aw11,
    aw12: ir8sForm.aw12,
    ow01: ir8sForm.ow01,
    ow02: ir8sForm.ow02,
    ow03: ir8sForm.ow03,
    ow04: ir8sForm.ow04,
    ow05: ir8sForm.ow05,
    ow06: ir8sForm.ow06,
    ow07: ir8sForm.ow07,
    ow08: ir8sForm.ow08,
    ow09: ir8sForm.ow09,
    ow10: ir8sForm.ow10,
    ow11: ir8sForm.ow11,
    ow12: ir8sForm.ow12,
    cpfEmAdditional01: ir8sForm.cpfEmAdditional01,
    cpfEmAdditional02: ir8sForm.cpfEmAdditional02,
    cpfEmAdditional03: ir8sForm.cpfEmAdditional03,
    cpfEmAdditional04: ir8sForm.cpfEmAdditional04,
    cpfEmAdditional05: ir8sForm.cpfEmAdditional05,
    cpfEmAdditional06: ir8sForm.cpfEmAdditional06,
    cpfEmAdditional07: ir8sForm.cpfEmAdditional07,
    cpfEmAdditional08: ir8sForm.cpfEmAdditional08,
    cpfEmAdditional09: ir8sForm.cpfEmAdditional09,
    cpfEmAdditional10: ir8sForm.cpfEmAdditional10,
    cpfEmAdditional11: ir8sForm.cpfEmAdditional11,
    cpfEmAdditional12: ir8sForm.cpfEmAdditional12,
    cpfEmOrdinary01: ir8sForm.cpfEmOrdinary01,
    cpfEmOrdinary02: ir8sForm.cpfEmOrdinary02,
    cpfEmOrdinary03: ir8sForm.cpfEmOrdinary03,
    cpfEmOrdinary04: ir8sForm.cpfEmOrdinary04,
    cpfEmOrdinary05: ir8sForm.cpfEmOrdinary05,
    cpfEmOrdinary06: ir8sForm.cpfEmOrdinary06,
    cpfEmOrdinary07: ir8sForm.cpfEmOrdinary07,
    cpfEmOrdinary08: ir8sForm.cpfEmOrdinary08,
    cpfEmOrdinary09: ir8sForm.cpfEmOrdinary09,
    cpfEmOrdinary10: ir8sForm.cpfEmOrdinary10,
    cpfEmOrdinary11: ir8sForm.cpfEmOrdinary11,
    cpfEmOrdinary12: ir8sForm.cpfEmOrdinary12,
    cpfErAdditional01: ir8sForm.cpfErAdditional01,
    cpfErAdditional02: ir8sForm.cpfErAdditional02,
    cpfErAdditional03: ir8sForm.cpfErAdditional03,
    cpfErAdditional04: ir8sForm.cpfErAdditional04,
    cpfErAdditional05: ir8sForm.cpfErAdditional05,
    cpfErAdditional06: ir8sForm.cpfErAdditional06,
    cpfErAdditional07: ir8sForm.cpfErAdditional07,
    cpfErAdditional08: ir8sForm.cpfErAdditional08,
    cpfErAdditional09: ir8sForm.cpfErAdditional09,
    cpfErAdditional10: ir8sForm.cpfErAdditional10,
    cpfErAdditional11: ir8sForm.cpfErAdditional11,
    cpfErAdditional12: ir8sForm.cpfErAdditional12,
    cpfErOrdinary01: ir8sForm.cpfErOrdinary01,
    cpfErOrdinary02: ir8sForm.cpfErOrdinary02,
    cpfErOrdinary03: ir8sForm.cpfErOrdinary03,
    cpfErOrdinary04: ir8sForm.cpfErOrdinary04,
    cpfErOrdinary05: ir8sForm.cpfErOrdinary05,
    cpfErOrdinary06: ir8sForm.cpfErOrdinary06,
    cpfErOrdinary07: ir8sForm.cpfErOrdinary07,
    cpfErOrdinary08: ir8sForm.cpfErOrdinary08,
    cpfErOrdinary09: ir8sForm.cpfErOrdinary09,
    cpfErOrdinary10: ir8sForm.cpfErOrdinary10,
    cpfErOrdinary11: ir8sForm.cpfErOrdinary11,
    cpfErOrdinary12: ir8sForm.cpfErOrdinary12,
    awRefundAmt01: ir8sForm.awRefundAmt01,
    awRefundAmt02: ir8sForm.awRefundAmt02,
    awRefundAmt03: ir8sForm.awRefundAmt03,
    awRefundStartDate01: ir8sForm.awRefundStartDate01,
    awRefundStartDate02: ir8sForm.awRefundStartDate02,
    awRefundStartDate03: ir8sForm.awRefundStartDate03,
    awRefundEndDate01: ir8sForm.awRefundEndDate01,
    awRefundEndDate02: ir8sForm.awRefundEndDate02,
    awRefundEndDate03: ir8sForm.awRefundEndDate03,
    awRefundPaymentDate01: ir8sForm.awRefundPaymentDate01,
    awRefundPaymentDate02: ir8sForm.awRefundPaymentDate02,
    awRefundPaymentDate03: ir8sForm.awRefundPaymentDate03,
    emRefundAmt01: ir8sForm.emRefundAmt01,
    emRefundAmt02: ir8sForm.emRefundAmt02,
    emRefundAmt03: ir8sForm.emRefundAmt03,
    emRefundInterest01: ir8sForm.emRefundInterest01,
    emRefundInterest02: ir8sForm.emRefundInterest02,
    emRefundInterest03: ir8sForm.emRefundInterest03,
    emRefundDate01: ir8sForm.emRefundDate01,
    emRefundDate02: ir8sForm.emRefundDate02,
    emRefundDate03: ir8sForm.emRefundDate03,
    erRefundAmt01: ir8sForm.erRefundAmt01,
    erRefundAmt02: ir8sForm.erRefundAmt02,
    erRefundAmt03: ir8sForm.erRefundAmt03,
    erRefundInterest01: ir8sForm.erRefundInterest01,
    erRefundInterest02: ir8sForm.erRefundInterest02,
    erRefundInterest03: ir8sForm.erRefundInterest03,
    erRefundDate01: ir8sForm.erRefundDate01,
    erRefundDate02: ir8sForm.erRefundDate02,
    erRefundDate03: ir8sForm.erRefundDate03
  }

  return updateForm
}
