import React, { FC } from 'react'
import { EmploymentConfig } from '~/constants'
import { Card, Descriptions } from '~/core-components'
import { useHiddenEmploymentConfigs } from '~/features/master/hooks'
import { SSEmPublicSelfState } from '~/features/my'

interface MyEmploymentProps {
  data: SSEmPublicSelfState
}

export const MyEmployment: FC<MyEmploymentProps> = ({ data }) => {
  const [employmentConfigs] = useHiddenEmploymentConfigs()
  const hiddenEmployments = employmentConfigs.map(ec => ec.code)
  const hideDepartment = hiddenEmployments.includes(EmploymentConfig.department)
  const hideDivision = hiddenEmployments.includes(EmploymentConfig.division)
  const hideSection = hiddenEmployments.includes(EmploymentConfig.section)
  const hideGrp = hiddenEmployments.includes(EmploymentConfig.grp)
  const hideCategory = hiddenEmployments.includes(EmploymentConfig.category)
  const hideTeam = hiddenEmployments.includes(EmploymentConfig.team)
  const hideJob = hiddenEmployments.includes(EmploymentConfig.job)
  const hideManager = hiddenEmployments.includes(EmploymentConfig.manager)
  const hideManagerSecondary = hiddenEmployments.includes(EmploymentConfig.managerSecondary)
  const hideOffice = hiddenEmployments.includes(EmploymentConfig.office)
  const hideCostCentre = hiddenEmployments.includes(EmploymentConfig.costCentre)
  const hideEmploymentType = hiddenEmployments.includes(EmploymentConfig.employmentType)
  const hideOtClass = hiddenEmployments.includes(EmploymentConfig.otClass)

  return (
    <Card title="Employment details">
      <Descriptions colon={false} column={1}>
        <Descriptions.Item label="Company">{data.companyName || '-'}</Descriptions.Item>
        <Descriptions.Item label="Hire">{data.employmentPeriod || '-'}</Descriptions.Item>
        {!hideDepartment && <Descriptions.Item label="Department">{data.departmentName || '-'}</Descriptions.Item>}
        {!hideDivision && <Descriptions.Item label="Division">{data.divisionName || '-'}</Descriptions.Item>}
        {!hideSection && <Descriptions.Item label="Section">{data.sectionName || '-'}</Descriptions.Item>}
        {!hideGrp && <Descriptions.Item label="Group">{data.grpName || '-'}</Descriptions.Item>}
        {!hideCategory && <Descriptions.Item label="Category">{data.categoryName || '-'}</Descriptions.Item>}
        {!hideTeam && <Descriptions.Item label="Team">{data.teamName || '-'}</Descriptions.Item>}
        {!hideJob && <Descriptions.Item label="Job title">{data.jobName || '-'}</Descriptions.Item>}
        {!hideManager && <Descriptions.Item label="Reporting to">{data.managerName || '-'}</Descriptions.Item>}
        {!hideManagerSecondary && (
          <Descriptions.Item label="Dotted line reporting to">{data.managerSecondaryName || '-'}</Descriptions.Item>
        )}
        {!hideOffice && <Descriptions.Item label="Office">{data.officeName || '-'}</Descriptions.Item>}
        {!hideCostCentre && <Descriptions.Item label="Cost centre">{data.costCentreName || '-'}</Descriptions.Item>}
        {!hideEmploymentType && (
          <Descriptions.Item label="Employment type">{data.employmentTypeName || '-'}</Descriptions.Item>
        )}
        {!hideOtClass && (
          <Descriptions.Item label="Overtime classification">{data.otClassName || '-'}</Descriptions.Item>
        )}
      </Descriptions>
    </Card>
  )
}
