import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchROLeavePeriodYears } from '../../actions'
import { selectROLeavePeriodYears } from '../../selectors'
import { ROLeavePeriodYearState } from '../../types'

export const useROLeavePeriodYears = (): [ROLeavePeriodYearState[], boolean] => {
  const roLeavePeriodYears = useSelector(selectROLeavePeriodYears)
  const loading = useSelector((state: StoreState) => state.my.roLeavePeriodYearsLoading)

  useEffect(() => {
    dispatch(fetchROLeavePeriodYears())
  }, [])

  return [roLeavePeriodYears, loading]
}
