import React, { forwardRef, useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Select, SelectProps, Space, Tag } from '~/core-components'
import { fetchEmpKeyvalues, ReasonState, selectReasonsKeyvalues } from '~/features/master'
import { useFirstInView } from '~/hooks'
import { isInactive } from '~/utils/dateUtil'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { FetchStrategy } from '~/types/common'
import { InactiveTag } from '../InactiveTag/InactiveTag'
import { DropdownActions } from './DropdownActions'

type ReasonTypes = 'all' | 'termination' | 'salary' | 'designation'

interface ReasonKeyValuesProps extends SelectProps {
  type: ReasonTypes
  onFilter?: (value: ReasonState | undefined, index: number, array: (ReasonState | undefined)[]) => {}
  fetchStrategy?: FetchStrategy
}

const ReasonKeyValuesInternal = (
  { type, onFilter, children, fetchStrategy = 'when-empty', ...props }: ReasonKeyValuesProps,
  ref: React.Ref<any>
) => {
  const { ref: inViewRef, inView } = useFirstInView<HTMLDivElement>({ threshold: 0.25 })
  const reasons = useSelector(selectReasonsKeyvalues)(type)
  const loading = useSelector((state: StoreState) => state.master.mastersLoading['reason'])

  const options = useMemo(() => {
    if (!reasons) return []

    let result = reasons.filter(a => {
      if (Array.isArray(props.value)) return !isInactive(a?.inactiveDate) || props.value.includes(a?.id)
      else return !isInactive(a?.inactiveDate) || a?.id === props.value
    })

    if (typeof onFilter === 'function') {
      result = result.filter(onFilter)
    }
    return result
  }, [reasons, props.value, onFilter])

  useEffect(() => {
    if (inView) {
      dispatch(fetchEmpKeyvalues('reason', undefined, undefined, { strategy: fetchStrategy }))
    }
  }, [inView, fetchStrategy])

  const handleRefresh = useCallback(() => {
    dispatch(fetchEmpKeyvalues('reason'))
  }, [])

  return (
    <div ref={inViewRef}>
      <Select
        ref={ref}
        showSearch
        optionFilterProp="title"
        dropdownRender={menu => (
          <div>
            {menu}
            <DropdownActions onRefresh={handleRefresh} />
          </div>
        )}
        {...props}
        loading={loading}
      >
        {options?.map(kv => (
          <Select.Option key={kv!.id} value={kv!.id || ''} title={kv!.name}>
            <Space>
              {isInactive(kv!.inactiveDate) && <InactiveTag />}
              {kv!.name}
              {type === 'all' && <Tag>{kv!.type}</Tag>}
            </Space>
          </Select.Option>
        ))}
      </Select>
    </div>
  )
}

export const ReasonKeyValues = forwardRef(ReasonKeyValuesInternal)
