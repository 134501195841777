import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils'
import { DateRange, Pagination, Search } from '~/types/common'
import { CurrencyExchangeState, CurrencyExchangeViewState, ICurrencyExchange } from '../types'

const baseUrl = getBaseUrl('/claim/currency/exchange')

export const apiGetCurrencyExchangeView = (viewId: string, params: Pagination & Search & DateRange) =>
  request<CurrencyExchangeViewState>('get', `${baseUrl}/view/${viewId}`, params)

export const apiGetCurrencyExchange = (id: string) => request<CurrencyExchangeState>('get', `${baseUrl}/${id}`)

export const apiAddCurrencyExchange = (currencyExchange: ICurrencyExchange) =>
  request<{ id: string }>('post', baseUrl, currencyExchange)

export const apiUpdateCurrencyExchange = (id: string, currencyExchange: Operation[]) =>
  request('patch', `${baseUrl}/${id}`, currencyExchange)

export const apiDeleteCurrencyExchange = (id: string) => request('delete', `${baseUrl}/${id}`)
