import React, { FC } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin, Tooltip } from '~/core-components'
import './TimeDayDisplay.less'

const ICON_PREV = 'fa-calendar-minus'
const ICON_NEXT = 'fa-calendar-plus'

interface TimeDayDisplayProps {
  time?: string
  day?: number
  loading?: boolean
}

export const TimeDayDisplay: FC<TimeDayDisplayProps> = ({ time, day = 0, loading }) => {
  if (loading) {
    return <Spin indicator={<LoadingOutlined spin />} />
  }

  if (!time) {
    return <>-</>
  }

  return (
    <div className="time-day-display">
      {day === 1 && (
        <Tooltip title="Next day">
          <span className="time-day-display__day">
            <i className={`fal ${ICON_NEXT}`} />
          </span>
        </Tooltip>
      )}
      {day === -1 && (
        <Tooltip title="Previous day">
          <span className="time-day-display__day">
            <i className={`fal ${ICON_PREV}`} />
          </span>
        </Tooltip>
      )}
      <span className="time-day-display__time">{time?.substring(0, 5)}</span>
    </div>
  )
}
