import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { EmAttendanceGroupState, IEmAttendanceGroup } from '../types'

const baseUrl = getBaseUrl('/employee/employee')

export const apiGetEmAttendanceGroups = (employeeId: string) =>
  request<EmAttendanceGroupState[]>('get', `${baseUrl}/${employeeId}/attendancegroup`)

export const apiGetEmAttendanceGroup = (employeeId: string, emAttendanceGroupId: string) =>
  request<EmAttendanceGroupState>('get', `${baseUrl}/${employeeId}/attendancegroup/${emAttendanceGroupId}`)

export const apiAddEmAttendanceGroup = (employeeId: string, emAttendanceGroup: IEmAttendanceGroup) =>
  request<{ id: string; emAttendanceGroupId?: string }>(
    'post',
    `${baseUrl}/${employeeId}/attendancegroup`,
    emAttendanceGroup
  )

export const apiUpdateEmAttendanceGroup = (employeeId: string, id: string, emAttendanceGroup: Operation[]) =>
  request<{ emAttendanceGroupId?: string }>(
    'patch',
    `${baseUrl}/${employeeId}/attendancegroup/${id}`,
    emAttendanceGroup
  )

export const apiDeleteEmAttendanceGroup = (employeeId: string, id: string) =>
  request<{ emAttendanceGroupId?: string }>('delete', `${baseUrl}/${employeeId}/attendancegroup/${id}`)
