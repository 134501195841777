import { createSelector } from '@reduxjs/toolkit'
import { LveSysLeaveTypeType } from '~/constants'
import { StoreState } from '~/types/store'
import { SSLeaveBalanceInfo } from './select-my-leave-balance'

export const selectMyLeaveGrantsBalance = createSelector(
  (state: StoreState) => state.my.myLeaveBalances,
  (state: StoreState) => state.leave.leaveTypes,
  (state: StoreState) => state.leave.sysLeaveTypes,
  (myLeaveBalanceState, leaveTypesState, sysLeaveTypesState) =>
    Object.values(myLeaveBalanceState?.entities || {})
      .filter(l => {
        const leaveType = leaveTypesState.entities[l?.leaveTypeId || '']
        const sysLeaveType = sysLeaveTypesState.entities[leaveType?.sysLeaveTypeCode || '']
        const balance = l?.balance || 0
        return sysLeaveType?.type === LveSysLeaveTypeType.grant && balance > 0
      })
      .map(l => {
        const leaveType = leaveTypesState.entities[l?.leaveTypeId || '']
        const sysLeaveType = sysLeaveTypesState.entities[leaveType?.sysLeaveTypeCode || '']
        return {
          ...l,
          name: leaveType?.name,
          color: leaveType?.color,
          unit: leaveType?.unit,
          sysLeaveTypeCode: leaveType?.sysLeaveTypeCode,
          sysLeaveTypeType: sysLeaveType?.type
        } as SSLeaveBalanceInfo
      })
      .sort((a, b) => {
        if (a.sysLeaveTypeType < b.sysLeaveTypeType) {
          return -1
        } else if (a.sysLeaveTypeType > b.sysLeaveTypeType) {
          return 1
        } else {
          return a.name?.localeCompare(b.name || '') || 0
        }
      })
)
