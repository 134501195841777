import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { EntityState } from '@reduxjs/toolkit'
import { Screen, SysCriteriaOptionKeyValue } from '~/features/selection'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchSysCriteriaOptions } from '../actions'

export const useSysCriteriaOptions = (
  screenCode: Screen,
  criteriaOptionCode?: string,
  inView = true,
  fetchStrategy: FetchStrategy = 'always'
): [EntityState<SysCriteriaOptionKeyValue>, boolean] => {
  const code = criteriaOptionCode || ''
  const refetch = useSelector((state: StoreState) => state.selection.sysCriteriaOptionsRefetch[code])
  const options = useSelector((state: StoreState) => state.selection.sysCriteriaOptions[code])
  const loading = useSelector((state: StoreState) => state.selection.sysCriteriaOptionsLoading[code])

  useEffect(() => {
    if (inView && code) {
      dispatch(fetchSysCriteriaOptions(screenCode, code, { strategy: fetchStrategy }))
    }
  }, [inView, screenCode, code, fetchStrategy, refetch])

  return [options, loading]
}
