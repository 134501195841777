import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { OptionGroup } from '~/types/common'
import { StoreState } from '~/types/store'
import { Screen } from '../types'

export const selectSysCriteriaFieldsOptGroup = createSelector(
  (state: StoreState) => state.selection.sysCriteriaFields,
  (state: StoreState) => state.selection.sysCriteriaScreens,
  (state: StoreState) => state.master.keyvalues['syscriteria'],
  (criteriaFieldsState, criteriaScreensState, sysCriteriaState) =>
    memoize((screenCode: Screen): OptionGroup[] => {
      const criteriaCodes = Object.values(criteriaScreensState[screenCode]?.entities || {}).map(s => s?.criteriaCode)

      const map = new Map<string, OptionGroup>()
      const criteriaFields = Object.values(criteriaFieldsState[screenCode]?.entities || {})
        .filter(f => criteriaCodes.includes(f?.criteriaCode))
        .sort((a, b) => {
          const aSequence = a!.sequence
          const bSequence = b!.sequence
          return bSequence > aSequence ? -1 : 1
        })

      criteriaFields.forEach(item => {
        if (item) {
          const { id, description, criteriaCode } = item
          const groupName = sysCriteriaState?.entities[criteriaCode]?.value || criteriaCode
          map.has(criteriaCode) || map.set(criteriaCode, { id: `group-${id}`, label: groupName, options: [] })
          map.get(criteriaCode)?.options.push({
            id,
            label: description,
            value: id
          })
        }
      })

      const result = [...map.values()]
      return result
    })
)
