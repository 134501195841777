import { Dictionary } from '@reduxjs/toolkit'
import { replaceOffices, setOfficesLoading } from '../reducers'
import { apiGetOffices } from '../api/office.api'
import { ThunkResult } from '~/types/store'
import { OfficeState } from '../types'
import { formatAddress } from '../util'
import { DEFAULT_FETCH_OPTIONS, FetchOptions, KeyValue } from '~/types/common'

export const fetchOffices = (options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().master.officesLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().master.offices?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setOfficesLoading(true))
      const { result, status } = await apiGetOffices()
      if (status) {
        const countries = getState().master.keyvalues['country']?.entities
        const stored = mapToState(result, countries)
        dispatch(replaceOffices(stored))
      }
    } finally {
      dispatch(setOfficesLoading(false))
    }
  }
}

const mapToState = (offices: OfficeState[], countries: Dictionary<KeyValue>): OfficeState[] => {
  return offices.map(o => {
    const countryName = countries[o.countryCode || '']?.value || ''
    return { ...o, ...formatAddress(o, countryName) }
  })
}
