import React, { FC } from 'react'
import classNames from 'classnames'
import { default as AntText, TextProps as AntTextProps } from 'antd/lib/typography/Text'
import 'antd/es/typography/style'
import './Text.less'

export interface TextProps extends AntTextProps {
  block?: boolean
  size?: 'small' | 'default' | 'large'
}

export const Text: FC<TextProps> = ({ className, block, size = 'default', ...props }: TextProps) => {
  const classes = classNames(className, { 'ant-typography--block': block }, `ant-typography--${size}`)
  return <AntText className={classes} {...props} />
}

export interface SecondaryTextProps extends TextProps {}
export const SecondaryText: FC<SecondaryTextProps> = (props: SecondaryTextProps) => <Text {...props} type="secondary" />
