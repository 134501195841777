import { showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiExecuteLeaveGrantBulk } from '../api/leave-grant-bulk.api'

export const executeLeaveGrantBulk = (): ThunkResult<void> => {
  return async () => {
    const { status, result, errors, message, errorData } = await apiExecuteLeaveGrantBulk()
    if (!status) {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors, result }
  }
}
