import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import persistReducer from 'redux-persist/es/persistReducer'
import { StoreState } from '~/types/store'
import { TempRootState, UserState, tempPersistConfig } from './types'

const userAdapter = createEntityAdapter<UserState>()

const userInitialState = userAdapter.getInitialState()

const initialState: TempRootState = {
  users: userInitialState,
  usersLoading: false
}

const tempSlice = createSlice({
  name: 'temp',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserState>) => {
      userAdapter.upsertOne(state.users, action.payload)
    },
    setUserLoading: (state, action: PayloadAction<boolean>) => {
      state.usersLoading = action.payload
    }
  }
})

export const { setUser, setUserLoading } = tempSlice.actions

export const { selectById: selectUserById } = userAdapter.getSelectors((state: StoreState) => state.temp.users)

export const tempReducers = { temp: persistReducer<TempRootState>(tempPersistConfig, tempSlice.reducer) }
