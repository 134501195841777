import React, { FC } from 'react'
import { default as AntCard, CardProps as AntCardProps } from 'antd/lib/card'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from '../Spin/Spin'
import classNames from 'classnames'
import 'antd/es/card/style'
import './Card.less'

export interface CardProps extends AntCardProps {
  fitParent?: boolean
  table?: boolean
}

export const Card: FC<CardProps> = ({
  fitParent = false,
  table = false,
  className,
  loading,
  extra,
  ...props
}: CardProps) => {
  const classes = classNames(className, { 'ant-card--fit-parent': fitParent }, { 'ant-card--table': table })
  const Loading = <Spin indicator={<LoadingOutlined spin />} />

  return <AntCard className={classes} extra={loading ? Loading : extra} {...props} />
}
