import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { SSClaimBalanceState } from '../types'

export const selectMyClaimBalance = createSelector(
  (state: StoreState) => state.myClaim.myClaimBalances,
  myClaimBalanceState => {
    const data = Object.values(myClaimBalanceState?.entities || {}) as SSClaimBalanceState[]
    return data.sort((a, b) => a?.claimTypeName?.localeCompare(b?.claimTypeName || '') || 0)
  }
)
