import { showSuccess, showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { apiSaveProject } from '../api/project.api'
import { fetchProject } from './fetch-project'
import { emptyGuid } from '~/constants'
import { IProject } from '../types'
import { fetchProjectEm } from './fetch-project-em'
import { setKeyvalue } from '~/features/master'

export const saveProject = (project: IProject): ThunkResult<void> => {
  return async (dispatch): Promise<ActionResult> => {
    const isNew = project.id === emptyGuid
    const { status, result, errors, message, errorData } = await apiSaveProject(project)
    if (status) {
      dispatch(fetchProject(result.id))
      dispatch(fetchProjectEm(result.id))
      dispatch(
        setKeyvalue({
          kv: 'project',
          data: { key: result.id, value: project.name, inactiveDate: project.inactiveDate }
        })
      )

      showSuccess(isNew ? 'Added' : 'Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
