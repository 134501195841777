import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { v4 as uuidv4 } from 'uuid'
import { Alert, Button, Checkbox, CheckboxChangeEvent, Input, Link, Select, Tooltip } from '~/core-components'
import { Col, EditableCard, EditableCardState, PayItemKeyValues, Row, SubHeader } from '~/components'
import { useSysOptions } from '~/features/employee'
import { usePermissionGate } from '~/features/iam'
import { Permission, PermissionAction, emptyGuid, XeroSplitType, XeroSignType, XeroPayItemCode } from '~/constants'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors, StoreState } from '~/types/store'
import { updateXeroLineItemMaps } from '../../actions'
import { useXeroLineItemMaps } from '../../hooks'
import { XeroLineItemMapState } from '../../types'
import { XeroSelectAccount } from './XeroSelectAccount'
import { XeroSelectContact } from './XeroSelectContact'
import './XeroLineItemMap.less'

interface XeroLineItemMapProps {
  tenantMapId?: string
}

interface FormData {
  lineItemMaps: XeroLineItemMapState[]
  lineItemMaps2: XeroLineItemMapState[]
}

const EMPTY_FORM_DATA: FormData = {
  lineItemMaps: [],
  lineItemMaps2: []
}

const NEW_LINE_ITEM_MAP: XeroLineItemMapState = {
  id: emptyGuid,
  payItemCode: undefined,
  payItemId: undefined,
  description: undefined,
  accountCode: undefined,
  contactId: undefined,
  signType: undefined,
  splitType: undefined
}

export const XeroLineItemMap: FC<XeroLineItemMapProps> = ({ tenantMapId }) => {
  const tenantMap = useSelector((state: StoreState) => state.payroll.xeroTenantMaps?.entities[tenantMapId || ''])
  const companyId = tenantMap?.companyId
  const tenantId = tenantMap?.tenantId

  const [cardState, setCardState] = useState<EditableCardState>()
  const canModify = usePermissionGate(Permission.xero, PermissionAction.Modify)
  const readOnly = cardState !== 'editing'
  const [errors, setErrors] = useState<Errors>()
  const [lineItemMaps, lineItemMapsLoading] = useXeroLineItemMaps(companyId)
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)
  const [payItemCodes] = useSysOptions('xero_pay_item_code')
  const payItems = useSelector((state: StoreState) => state.master.payItems.entities)

  useEffect(() => {
    if (lineItemMaps) {
      setFormData({
        lineItemMaps: lineItemMaps.filter(x => x.payItemCode != null),
        lineItemMaps2: lineItemMaps.filter(x => x.payItemCode == null)
      })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [lineItemMaps])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setErrors(undefined)
    setFormData(data => ({ ...data, ...updates }))
  }, [])

  const handleEdit = useCallback(() => {
    setCardState('editing')
  }, [])

  const handleSave = useCallback(async () => {
    setCardState('saving')
    setErrors(undefined)

    let result: ActionResult | undefined
    try {
      const combined = [...formData.lineItemMaps, ...formData.lineItemMaps2]
      result = await dispatch(updateXeroLineItemMaps(companyId, combined))
    } catch {
      setCardState('editing')
    }

    if (result?.errors) {
      setCardState('editing')
      setErrors(result.errors)
    }

    if (!result?.errors) {
      setCardState(undefined)
    }
  }, [companyId, formData])

  const handleCancel = useCallback(() => {
    setCardState(undefined)
    setErrors(undefined)

    if (lineItemMaps) {
      setFormData({
        lineItemMaps: lineItemMaps.filter(x => x.payItemCode != null),
        lineItemMaps2: lineItemMaps.filter(x => x.payItemCode == null)
      })
    }
  }, [lineItemMaps])

  return (
    <EditableCard
      className="xero-line-item-map"
      title="Map to Xero Account & Contact"
      bodyStyle={{ paddingBottom: !readOnly ? 6 : 24, paddingTop: 6 }}
      state={canModify ? cardState : 'readonly'}
      onEdit={handleEdit}
      onSave={handleSave}
      onCancel={handleCancel}
      loading={lineItemMapsLoading}
    >
      <Row>
        <Col span={24}>
          {errors && Object.keys(errors).length > 0 && (
            <Alert
              className="xero-line-item-map__error"
              type="error"
              message={
                <>
                  {Object.keys(errors).map((error, index) => (
                    <div key={index}>{errors[error]}</div>
                  ))}
                </>
              }
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Row gutter={16}>
            <Col span={2}></Col>
            <Col span={5}>
              <div className="xero-line-item-map__row-title">Payroll item</div>
            </Col>
            <Col span={4}>
              <div className="xero-line-item-map__row-title">Description</div>
            </Col>
            <Col span={6}>
              <div className="xero-line-item-map__row-title">Account</div>
            </Col>
            <Col span={5}>
              <div className="xero-line-item-map__row-title">Contact</div>
            </Col>
            <Col span={1}>Split</Col>
            <Col span={1}></Col>
          </Row>
          {formData.lineItemMaps.map((t, index) => (
            <Row gutter={16} key={index} align="middle" className="xero-line-item-map__row">
              <Col span={2}>
                {t.payItemCode !== XeroPayItemCode.PayItem && (
                  <Select
                    options={[
                      {
                        label: <PlusOutlined style={{ color: 'green' }} />,
                        value: XeroSignType.Addition
                      },
                      {
                        label: <MinusOutlined style={{ color: 'red' }} />,
                        value: XeroSignType.Deduction
                      }
                    ]}
                    readOnly={readOnly}
                    allowClear={false}
                    value={t.signType}
                    onChange={signType =>
                      handleFormDataChange({
                        lineItemMaps: [
                          ...formData.lineItemMaps.slice(0, index),
                          { ...formData.lineItemMaps[index], signType },
                          ...formData.lineItemMaps.slice(index + 1, formData.lineItemMaps.length)
                        ]
                      })
                    }
                  />
                )}
              </Col>
              <Col span={5}>{payItemCodes[t.payItemCode || '']?.value}</Col>
              <Col span={4}>
                <Input
                  readOnly={readOnly}
                  value={t.description}
                  placeholder={payItemCodes[t.payItemCode || '']?.value || payItems[t.payItemId || '']?.name}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleFormDataChange({
                      lineItemMaps: [
                        ...formData.lineItemMaps.slice(0, index),
                        { ...formData.lineItemMaps[index], description: event.target.value },
                        ...formData.lineItemMaps.slice(index + 1, formData.lineItemMaps.length)
                      ]
                    })
                  }
                />
              </Col>
              <Col span={6}>
                <XeroSelectAccount
                  tenantId={tenantId}
                  value={t.accountCode}
                  readOnly={readOnly}
                  onChange={(value: string) =>
                    handleFormDataChange({
                      lineItemMaps: [
                        ...formData.lineItemMaps.slice(0, index),
                        { ...formData.lineItemMaps[index], accountCode: value },
                        ...formData.lineItemMaps.slice(index + 1, formData.lineItemMaps.length)
                      ]
                    })
                  }
                />
              </Col>
              <Col span={5}>
                <XeroSelectContact
                  tenantId={tenantId}
                  value={t.contactId}
                  readOnly={readOnly}
                  onChange={(value: string) =>
                    handleFormDataChange({
                      lineItemMaps: [
                        ...formData.lineItemMaps.slice(0, index),
                        { ...formData.lineItemMaps[index], contactId: value },
                        ...formData.lineItemMaps.slice(index + 1, formData.lineItemMaps.length)
                      ]
                    })
                  }
                />
              </Col>
              <Col span={1}>
                <Checkbox
                  readOnly={readOnly}
                  checked={t.splitType === XeroSplitType.Employee}
                  onChange={(event: CheckboxChangeEvent) => {
                    handleFormDataChange({
                      lineItemMaps: [
                        ...formData.lineItemMaps.slice(0, index),
                        {
                          ...formData.lineItemMaps[index],
                          splitType: event.target.checked ? XeroSplitType.Employee : ''
                        },
                        ...formData.lineItemMaps.slice(index + 1, formData.lineItemMaps.length)
                      ]
                    })
                  }}
                />
              </Col>
              <Col span={1}>
                {!readOnly && !t.payItemCode?.endsWith('_2') && t.payItemCode !== XeroPayItemCode.PayItem && (
                  <Tooltip title="Copy to new line">
                    <Button
                      size="small"
                      type={
                        formData.lineItemMaps.some(x => x.payItemCode === `${t.payItemCode}_2`) ? 'primary' : 'default'
                      }
                      icon={<i className="fal fa-diagram-next" />}
                      onClick={() => {
                        const newPayItemCode = `${t.payItemCode}_2`
                        const isRemove = formData.lineItemMaps.some(x => x.payItemCode === newPayItemCode)
                        if (isRemove) {
                          handleFormDataChange({
                            lineItemMaps: [
                              ...formData.lineItemMaps.slice(0, index + 1),
                              ...formData.lineItemMaps.slice(index + 2, formData.lineItemMaps.length)
                            ]
                          })
                        } else {
                          handleFormDataChange({
                            lineItemMaps: [
                              ...formData.lineItemMaps.slice(0, index + 1),
                              {
                                id: uuidv4(),
                                payItemCode: newPayItemCode,
                                signType:
                                  t.signType === XeroSignType.Addition ? XeroSignType.Deduction : XeroSignType.Addition
                              } as XeroLineItemMapState,
                              ...formData.lineItemMaps.slice(index + 1, formData.lineItemMaps.length)
                            ]
                          })
                        }
                      }}
                    />
                  </Tooltip>
                )}
              </Col>
            </Row>
          ))}
          {formData.lineItemMaps2.length > 0 && (
            <Row gutter={16} className="xero-line-item-map__row xero-line-item-map__row-sub-header">
              <Col span={24}>
                <SubHeader>Specific payroll items (to be excluded from "All payroll items")</SubHeader>
              </Col>
            </Row>
          )}
          {formData.lineItemMaps2.map((t, index) => (
            <Row
              gutter={16}
              key={index}
              align="middle"
              className="xero-line-item-map__row xero-line-item-map__row-indent"
            >
              <Col span={7}>
                <PayItemKeyValues
                  dropdownMatchSelectWidth={false}
                  readOnly={readOnly}
                  value={t.payItemId}
                  onChange={(payItemId: string) =>
                    handleFormDataChange({
                      lineItemMaps2: [
                        ...formData.lineItemMaps2.slice(0, index),
                        { ...formData.lineItemMaps2[index], payItemId },
                        ...formData.lineItemMaps2.slice(index + 1, formData.lineItemMaps2.length)
                      ]
                    })
                  }
                />
              </Col>
              <Col span={4}>
                <Input
                  readOnly={readOnly}
                  value={t.description}
                  placeholder={payItemCodes[t.payItemCode || '']?.value || payItems[t.payItemId || '']?.name}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleFormDataChange({
                      lineItemMaps2: [
                        ...formData.lineItemMaps2.slice(0, index),
                        { ...formData.lineItemMaps2[index], description: event.target.value },
                        ...formData.lineItemMaps2.slice(index + 1, formData.lineItemMaps2.length)
                      ]
                    })
                  }
                />
              </Col>
              <Col span={6}>
                <XeroSelectAccount
                  tenantId={tenantId}
                  value={t.accountCode}
                  readOnly={readOnly}
                  onChange={(value: string) =>
                    handleFormDataChange({
                      lineItemMaps2: [
                        ...formData.lineItemMaps2.slice(0, index),
                        { ...formData.lineItemMaps2[index], accountCode: value },
                        ...formData.lineItemMaps2.slice(index + 1, formData.lineItemMaps2.length)
                      ]
                    })
                  }
                />
              </Col>
              <Col span={5}>
                <XeroSelectContact
                  tenantId={tenantId}
                  value={t.contactId}
                  readOnly={readOnly}
                  onChange={(value: string) =>
                    handleFormDataChange({
                      lineItemMaps2: [
                        ...formData.lineItemMaps2.slice(0, index),
                        { ...formData.lineItemMaps2[index], contactId: value },
                        ...formData.lineItemMaps2.slice(index + 1, formData.lineItemMaps2.length)
                      ]
                    })
                  }
                />
              </Col>
              <Col span={1}>
                <Checkbox
                  readOnly={readOnly}
                  checked={t.splitType === XeroSplitType.Employee}
                  onChange={(event: CheckboxChangeEvent) => {
                    handleFormDataChange({
                      lineItemMaps2: [
                        ...formData.lineItemMaps2.slice(0, index),
                        {
                          ...formData.lineItemMaps2[index],
                          splitType: event.target.checked ? XeroSplitType.Employee : ''
                        },
                        ...formData.lineItemMaps2.slice(index + 1, formData.lineItemMaps2.length)
                      ]
                    })
                  }}
                />
              </Col>
              <Col span={1} hidden={readOnly}>
                <Button
                  type="text"
                  icon={<i className="fal fa-xmark" />}
                  onClick={() =>
                    handleFormDataChange({
                      lineItemMaps2: [
                        ...formData.lineItemMaps2.slice(0, index),
                        ...formData.lineItemMaps2.slice(index + 1, formData.lineItemMaps2.length)
                      ]
                    })
                  }
                />
              </Col>
            </Row>
          ))}
          <Row hidden={readOnly}>
            <Col span={6}>
              <Link
                onClick={() => handleFormDataChange({ lineItemMaps2: [...formData.lineItemMaps2, NEW_LINE_ITEM_MAP] })}
              >
                {formData.lineItemMaps2.length === 0 ? 'add specific payroll item' : 'add payroll item'}
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </EditableCard>
  )
}
