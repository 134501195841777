import React, { FC, useState, useCallback } from 'react'
import { useHistory } from 'react-router'
import { Form } from '~/core-components'
import { DrawerForm } from '~/components'
import { PayGroupInfoForm, EMPTY_PAYGROUP_INFO_FORM_DATA } from './PayGroupInfoForm'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors } from '~/types/store'
import { addMaster } from '../../../actions'
import { IPayGroupInfo } from '../../../types'

interface AddPayGroupDrawerProps {
  visible: boolean
  onClose: () => void
}

export const AddPayGroupDrawer: FC<AddPayGroupDrawerProps> = ({ visible, onClose }: AddPayGroupDrawerProps) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<IPayGroupInfo>(EMPTY_PAYGROUP_INFO_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const history = useHistory()

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      result = await dispatch(addMaster('payGroup', formData))
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_PAYGROUP_INFO_FORM_DATA)
      history.push(SETTINGS_ROUTES.payGroup.replace(':id', result?.result?.id).replace(':tab?', 'general'))
    }
  }, [formData, onClose, history])

  return (
    <DrawerForm
      open={visible}
      title="Add payroll group"
      onClose={onClose}
      confirmLoading={loading}
      width={650}
      formId="form-paygroup"
    >
      <Form id="form-paygroup" onFinish={handleOk}>
        <PayGroupInfoForm data={formData} errors={errors} onChange={data => setFormData(data)} />
      </Form>
    </DrawerForm>
  )
}
