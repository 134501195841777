import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { setIr8sProcessing } from '../reducers'
import { apiResetIr8s } from '../api/ytd.api'

export const resetIr8s = (ytdSubmissionId: string, employeeTaxNo: string): ThunkResult<void> => {
  return async dispatch => {
    if (!ytdSubmissionId) return

    try {
      dispatch(setIr8sProcessing(true))
      const { status, errors, message, errorData } = await apiResetIr8s(ytdSubmissionId, employeeTaxNo)
      if (status) {
        showSuccess('IR8S reset')
      } else {
        if (Object.keys(errors).length === 0) {
          showError(message, errorData)
        }
      }
    } finally {
      dispatch(setIr8sProcessing(false))
    }
  }
}
