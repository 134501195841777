import { configureStore as reduxConfigureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { ThunkMiddleware } from 'redux-thunk'
// import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { setStore } from './store'
import { storePersistConfig, StoreState } from '../types/store'
import { createRootReducer } from './root-reducer'

export const persistConfig = {
  ...storePersistConfig,
  storage
}

export const configureStore = () => {
  const reduxDefaultMiddleware = getDefaultMiddleware<StoreState>({
    thunk: true,
    // serializableCheck: { ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER] },
    serializableCheck: false,
    immutableCheck: false
  } as any)

  const persistedReducer = persistReducer(persistConfig, createRootReducer())

  const store = reduxConfigureStore<StoreState>({
    reducer: persistedReducer,
    middleware: [...reduxDefaultMiddleware] as [ThunkMiddleware<StoreState>],
    devTools: process.env.NODE_ENV !== 'production'
  })

  setStore(store)
  let persistor = persistStore(store)

  return { store, persistor }
}
