import { setClaimPendingApprovalsView, setClaimPendingApprovalsViewLoading } from '../reducers'
import { apiGetClaimPendingApprovalsView } from '../api/claim-pending-approval.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchClaimPendingApprovalsView = (
  viewId: string,
  periodYear: number | undefined,
  search: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!viewId || !periodYear) return

    const loading = getState().claim.claimPendingApprovalsViewLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().claim.claimPendingApprovalsView?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setClaimPendingApprovalsViewLoading(true))
      const { result, status } = await apiGetClaimPendingApprovalsView(viewId, periodYear, { search })
      if (status) {
        dispatch(setClaimPendingApprovalsView(result))
      }
    } finally {
      dispatch(setClaimPendingApprovalsViewLoading(false))
    }
  }
}
