import React, { FC, useCallback, useMemo, useState } from 'react'
import { Button, Card, ColumnsType, Link, PageHeader, Space, Table, Tooltip } from '~/core-components'
import { DocumentTitle, InactiveTag } from '~/components'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { usePermissionGate } from '~/features/iam'
import { Permission, PermissionAction } from '~/constants'
import { isInactive } from '~/utils/dateUtil'
import { useProjects } from '../../hooks'
import { ProjectState } from '../../types'
import { dispatch } from '~/stores/store'
import { fetchProjects } from '../../actions'
import { MutateProjectDrawer } from './components/MutateProjectDrawer'
import './Projects.less'

interface ProjectsProps {}

type ProjectTable = ProjectState

interface DrawerState {
  visible: boolean
  data?: ProjectState
}
const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

export const Projects: FC<ProjectsProps> = () => {
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const canModify = usePermissionGate(Permission.project, PermissionAction.Modify)
  const [projects, loading] = useProjects()

  const routes = useMemo(
    () => [
      {
        path: SETTINGS_ROUTES.main,
        breadcrumbName: 'Settings'
      },
      {
        path: '',
        breadcrumbName: 'Overview'
      }
    ],
    []
  )

  const handleAddProject = useCallback(() => {
    setDrawerState({ visible: true, data: undefined })
  }, [])

  const handleEditProject = useCallback((project: ProjectState) => {
    setDrawerState({ visible: true, data: project })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const handleRefresh = useCallback(() => {
    dispatch(fetchProjects())
  }, [])

  const columns = useMemo(() => {
    let columns: ColumnsType<ProjectTable> = []

    columns.push(
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name',
        width: 300,
        render: (value: string, record) => (
          <Space>
            {value}
            {isInactive(record.inactiveDate) && <InactiveTag />}
          </Space>
        )
      },
      {
        title: 'Project members',
        key: 'employees',
        dataIndex: 'employees',
        align: 'center'
      },
      {
        key: 'action',
        align: 'right',
        width: 70,
        render: (value: string, record) =>
          canModify && (
            <Link size="small" onClick={() => handleEditProject(record)}>
              edit
            </Link>
          )
      }
    )

    return columns
  }, [canModify, handleEditProject])

  return (
    <div className="projects">
      <DocumentTitle title="Project" />
      <PageHeader
        title="Project"
        breadcrumb={{ routes }}
        extra={
          <Space>
            {canModify && (
              <Button key="add" onClick={handleAddProject}>
                Add project
              </Button>
            )}
            <Tooltip title="Refresh">
              <Button icon={<i className="fal fa-refresh" />} onClick={handleRefresh} />
            </Tooltip>
          </Space>
        }
      />
      <div className="projects__body">
        <Card table>
          <Table rowKey="id" dataSource={projects} pagination={false} columns={columns} loading={loading} />
        </Card>
        {canModify && <MutateProjectDrawer {...drawerState} onClose={handleCloseDrawer} />}
      </div>
    </div>
  )
}
