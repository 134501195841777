import { EntityState } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { KeyValue } from '~/types/common'
import { JobViewState, SysJobState } from '.'

export const jobPersistConfig = {
  key: 'job',
  whitelist: ['sysJobs'],
  storage
}

export interface JobRootState {
  jobsViewExpanded: boolean
  jobsView: EntityState<JobViewState>
  jobsViewLoading: boolean
  jobsViewRefetch: number
  sysJobs: EntityState<SysJobState>
  sysJobsLoading: boolean

  keyValues: { [id: string]: EntityState<KeyValue> }
  keyValuesParams: { [id: string]: string }
  keyValuesLoading: { [id: string]: boolean }
}
