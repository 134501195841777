import { showError, showSuccess } from '~/utils'
import { ThunkResult } from '~/types/store'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { apiUpdateCalendar } from '../api/calendar.api'
import { WorkCalendarInfoState, WorkCalendarState } from '../types'
import { setCalendar } from '../reducers'
import { setMaster } from '~/features/master'

export const updateCalendar = (
  id: string,
  before: WorkCalendarInfoState,
  request: WorkCalendarInfoState
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const after = { ...before, ...request } as WorkCalendarInfoState
    const patch = generateJsonPatch<WorkCalendarInfoState>(before, after)
    const { status, errors, message, errorData } = await apiUpdateCalendar(id, patch)
    if (status) {
      const calendar = getState().attendance.calendars?.entities[id] as WorkCalendarState
      const updated: WorkCalendarState = { ...calendar, ...after }
      dispatch(setCalendar(updated))
      dispatch(
        setMaster({
          masterName: 'calendar',
          data: { id, name: updated.name, inactiveDate: updated.inactiveDate }
        })
      )

      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
