import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { FormulaState, IFormula, FormulaDetailState, IFormulaDetail } from '../types'

const baseUrl = getBaseUrl('/payroll-sg/formula')

export const apiGetFormulas = () => request<FormulaState[]>('get', baseUrl)

export const apiAddFormula = (formula: IFormula) => request<{ id: string }>('post', baseUrl, formula)

export const apiUpdateFormula = (id: string, formula: Operation[]) => request('patch', `${baseUrl}/${id}`, formula)

export const apiDeleteFormula = (id: string) => request('delete', `${baseUrl}/${id}`)

export const apiGetFormulaDetails = (formulaId: string) =>
  request<FormulaDetailState[]>('get', `${baseUrl}/${formulaId}/detail`)

export const apiAddFormulaDetail = (formulaId: string, formulaDetail: IFormulaDetail) =>
  request<{ id: string; sequence: number }>('post', `${baseUrl}/${formulaId}/detail`, formulaDetail)

export const apiUpdateFormulaDetail = (formulaId: string, id: string, formulaDetail: Operation[]) =>
  request('patch', `${baseUrl}/${formulaId}/detail/${id}`, formulaDetail)

export const apiDeleteFormulaDetail = (formulaId: string, id: string) =>
  request('delete', `${baseUrl}/${formulaId}/detail/${id}`)
