export interface CfConfigState {
  code: string
  name: string
  isShown: boolean
}

export interface ICfConfig {
  name: string
  isShown: boolean
}

export const mapCfConfigStateToCfConfigInfo = (cfConfig: CfConfigState): ICfConfig => ({
  name: cfConfig.name,
  isShown: cfConfig.isShown
})
