import { showError, showSuccess } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiDeleteMyClaimRecord } from '../api/ss-claim-record.api'
import { refetchMyClaimBalances, refetchMyClaimRecordsView, removeMyClaimRecord } from '../reducers'

export const deleteMyClaim = (id: string): ThunkResult<void> => {
  return async dispatch => {
    if (!id) return

    const { status, errors, message, errorData } = await apiDeleteMyClaimRecord(id)
    if (status) {
      dispatch(removeMyClaimRecord(id))
      dispatch(refetchMyClaimBalances())
      dispatch(refetchMyClaimRecordsView())

      showSuccess('Deleted')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
