import { setDailyRecord, setDailyRecordsLoading } from '../reducers'
import { apiGetDailyRecord } from '../api/daily-record.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchDailyRecord = (id: string, options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const loading = getState().attendance.dailyRecordsLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().attendance.dailyRecords?.entities[id]
      if (hasData) return
    }

    try {
      dispatch(setDailyRecordsLoading(true))
      const { result, status } = await apiGetDailyRecord(id)
      if (status) {
        dispatch(setDailyRecord(result))
      }
    } finally {
      dispatch(setDailyRecordsLoading(false))
    }
  }
}
