import React, { CSSProperties, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Card, ColumnsType, Table } from '~/core-components'
import { DownloadFileAuth } from '~/components'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { getBaseUrl } from '~/utils'
import { fetchMyIrasForms } from '../../../../actions'
import { SSIrasFormViewColumnState } from '../../../../types'
import './MyIras.less'

interface MyIrasProps {}

const PAGE_SIZE_OPTIONS = ['10']

const cardBodyStyle: CSSProperties = { padding: 0 }
const paginationStyle: CSSProperties = { marginRight: 20 }
const baseUrl = getBaseUrl('/payroll-sg')

export const MyIras: FC<MyIrasProps> = () => {
  const data = useSelector((state: StoreState) => state.myPayroll.myIrasFormsView)
  const loading = useSelector((state: StoreState) => state.myPayroll.myIrasFormsViewLoading)
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)

  useEffect(() => {
    dispatch(fetchMyIrasForms({ offset: pageSize * (page - 1), limit: pageSize }))
  }, [page, pageSize])

  const handlePaginationChange = useCallback((page: number, pageSize?: number) => {
    setPage(page)
    setPageSize(pageSize || 10)
  }, [])

  const columns: ColumnsType<SSIrasFormViewColumnState> = useMemo(
    () => [
      {
        title: 'Form',
        key: 'formName',
        dataIndex: 'formName'
      },
      {
        title: 'Year',
        key: 'ytdYear',
        dataIndex: 'ytdYear',
        width: 150
      },
      {
        key: 'action',
        align: 'right',
        render: (value: string, record) => (
          <DownloadFileAuth url={`${baseUrl}/ssirasform/${record.ytdType}/${record.id}/pdf`}>download</DownloadFileAuth>
        )
      }
    ],
    []
  )

  return (
    <Card className="my-iras" title="IRAS forms" bodyStyle={cardBodyStyle}>
      <Table
        rowKey="id"
        dataSource={data?.data}
        columns={columns}
        fitParent
        loading={loading}
        pagination={{
          total: data?.count,
          current: page,
          pageSize,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
          showSizeChanger: true,
          onChange: handlePaginationChange,
          style: paginationStyle
        }}
      />
    </Card>
  )
}
