import { replaceEmLeaveBuddies, setEmLeaveBuddiesLoading } from '../reducers'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'
import { apiGetLeaveBuddyEmByEmployee } from '~/features/leave/api/leave-buddy.api'

export const fetchEmLeaveBuddies = (
  employeeId: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!employeeId) return

    const loading = getState().employee.emLeaveBuddiesLoading[employeeId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().employee.emLeaveBuddies[employeeId]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setEmLeaveBuddiesLoading({ employeeId, loading: true }))
      const { result, status } = await apiGetLeaveBuddyEmByEmployee(employeeId)
      if (status) {
        dispatch(replaceEmLeaveBuddies({ employeeId, data: result }))
      }
    } finally {
      dispatch(setEmLeaveBuddiesLoading({ employeeId, loading: false }))
    }
  }
}
