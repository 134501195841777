import { apiGetMyLeaveTaskByToken } from '../../api/ssleave-task-token.api'
import { ThunkResult } from '~/types/store'

export const fetchMyLeaveTaskByToken = (token: string): ThunkResult<void> => {
  return async () => {
    if (!token) return

    return await apiGetMyLeaveTaskByToken(token)
  }
}
