import React, { FC } from 'react'
import classNames from 'classnames'
import { LoadingOutlined } from '@ant-design/icons'
import { Avatar, Spin } from '~/core-components'
import { ImageAuth } from '../ImageAuth/ImageAuth'
import './PersonAvatar.less'

interface PersonAvatarProps {
  photo?: string
  size?: number
  mode?: 'simple' | 'drop-shadow'
  loading?: boolean
}

export const PersonAvatar: FC<PersonAvatarProps> = ({ photo, size = 36, mode = 'simple', loading }) => {
  return (
    <div className={classNames('person-avatar', { 'person-avatar--shadow': mode === 'drop-shadow' })}>
      {photo ? (
        <Avatar
          size={size}
          src={
            <ImageAuth
              src={photo}
              width={size}
              preview={false}
              defaultImage={<Avatar size={size} icon={<i className="fal fa-user" />} />}
            />
          }
        />
      ) : (
        <Avatar
          size={size}
          icon={loading ? <Spin indicator={<LoadingOutlined spin />} /> : <i className="fal fa-user" />}
        />
      )}
    </div>
  )
}
