import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { CtExpenseTypeState } from '../types'
import { fetchCtExpenseTypes } from '../actions'
import { selectCtExpenseTypes } from '../selectors'

export const useCtExpenseTypes = (
  claimTypeId: string,
  fetchStrategy: FetchStrategy = 'when-empty'
): [CtExpenseTypeState[], boolean] => {
  const ctExpenseTypes = useSelector(selectCtExpenseTypes)(claimTypeId)
  const loading = useSelector((state: StoreState) => state.claim.ctExpenseTypesLoading[claimTypeId])

  useEffect(() => {
    if (claimTypeId) {
      dispatch(fetchCtExpenseTypes(claimTypeId, { strategy: fetchStrategy }))
    }
  }, [claimTypeId, fetchStrategy])

  return [ctExpenseTypes, loading]
}
