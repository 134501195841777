import { request, getBaseUrl } from '~/utils'
import {
  IClaimAmount,
  IClaimApply,
  IClaimRecordExpense,
  IClaimAmountMultipleRequest,
  IClaimAmountMultipleResponse
} from '../types'
import { UploadFile } from '~/core-components'

const baseUrl = getBaseUrl('/claim/claimapply')

export const apiApplyClaimRecord = (claimRecord: IClaimApply, isSubmit: boolean) => {
  let formData = new FormData()
  formData.append('employeeId', claimRecord.employeeId)
  formData.append('isSubmit', isSubmit ? 'true' : 'false')

  Object.entries(claimRecord).forEach(([key, value]) => {
    if (key === 'attachments') {
      value.forEach((x: UploadFile) => {
        if (x instanceof File) formData.append(key, x, x.name)
      })
    } else if (key === 'deletedAttachmentIds' && value != null && value !== '') {
      value.forEach((x: string) => {
        formData.append(key, x)
      })
    } else if (key === 'expenses') {
      value.forEach((x: IClaimRecordExpense, index: number) => {
        Object.keys(x).forEach((key: string) => {
          const expenseValue = x[key as keyof IClaimRecordExpense] as any
          if (key === 'attachments') {
            x.attachments?.forEach((y: UploadFile) => {
              if (y instanceof File) formData.append(key, y, `${index}||${y.name}`)
            })
          } else if (key === 'deletedAttachmentIds') {
            x.deletedAttachmentIds?.forEach((y: string) => {
              formData.append(key, y)
            })
          } else {
            formData.append(`expenses[${index}][${key}]`, expenseValue === null ? '' : expenseValue)
          }
        })
      })
    } else {
      formData.append(key, value === null ? '' : value)
    }
  })

  return request<{
    status: string
    request: IClaimApply
  }>('post', baseUrl, formData)
}

export const apiGetClaimAmount = (claimAmount: IClaimAmount) => {
  return request<{ claimAmount: number; claimAmountTax: number }>('post', `${baseUrl}/claimamount`, claimAmount)
}

export const apiGetClaimAmountMultiple = (claimAmount: IClaimAmountMultipleRequest) => {
  return request<IClaimAmountMultipleResponse>('post', `${baseUrl}/claimamountmultiple`, claimAmount)
}
