import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiDeleteIr21A1Draft } from '../api/ir21.api'
import { setIr21A1FormDeleting } from '../reducers'

export const deleteIr21A1Draft = (id: string): ThunkResult<void> => {
  return async dispatch => {
    if (!id) return

    try {
      dispatch(setIr21A1FormDeleting(true))
      const { status, errors, message, errorData } = await apiDeleteIr21A1Draft(id)
      if (status) {
        showSuccess('Deleted')
      } else {
        if (Object.keys(errors).length === 0) {
          showError(message, errorData)
        }
      }
      return { errors }
    } finally {
      dispatch(setIr21A1FormDeleting(false))
    }
  }
}
