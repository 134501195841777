import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { EditableCard, EditableCardState } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { dispatch } from '~/stores/store'
import { Errors, ActionResult, StoreState } from '~/types/store'
import { Permission, PermissionAction } from '~/constants'
import { LeaveTypeInfoState, LeaveTypeState } from '../../../types'
import { updateLeaveType } from '../../../actions'
import { EMPTY_LEAVE_TYPE_INFO_FORM_DATA, LeaveTypeInfoForm } from './LeaveTypeInfoForm'

interface LeaveTypeInfoProps {
  leaveType?: LeaveTypeState
  onEdit?: () => void
  onSave?: () => void
  onCancel?: () => void
}

export const LeaveTypeInfo: FC<LeaveTypeInfoProps> = ({ leaveType, onEdit, onSave, onCancel }) => {
  const [cardState, setCardState] = useState<EditableCardState>()
  const [formData, setFormData] = useState<LeaveTypeInfoState>(EMPTY_LEAVE_TYPE_INFO_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const canModify = usePermissionGate(Permission.lveType, PermissionAction.Modify)
  const sysLeaveTypeCode = leaveType?.sysLeaveTypeCode || ''
  const sysLtFieldsLoading = useSelector((state: StoreState) => state.leave.sysLtFieldsLoading[sysLeaveTypeCode])

  useEffect(() => {
    if (leaveType) {
      const {
        name,
        sysLeaveTypeCode,
        periodBasis,
        periodDuration,
        periodStart,
        inactiveDate,
        color,
        entByCitizen,
        isSys,
        parentId,
        grantLtId,
        isPaid
      } = leaveType

      setFormData({
        name,
        sysLeaveTypeCode,
        periodBasis,
        periodDuration,
        periodStart,
        inactiveDate,
        color,
        entByCitizen,
        isSys,
        parentId,
        grantLtId,
        isPaid
      })
    } else {
      setFormData(EMPTY_LEAVE_TYPE_INFO_FORM_DATA)
    }
  }, [leaveType])

  const handleEdit = useCallback(() => {
    setCardState('editing')
    typeof onEdit === 'function' && onEdit()
  }, [onEdit])

  const handleSave = useCallback(async () => {
    if (leaveType) {
      setCardState('saving')
      setErrors(undefined)

      typeof onSave === 'function' && onSave()

      let result: ActionResult | undefined
      try {
        result = await dispatch(updateLeaveType(leaveType.id, leaveType, formData))
      } catch {
        setCardState('editing')
      }

      if (result?.errors) {
        setCardState('editing')
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setCardState(undefined)
      }
    }
  }, [leaveType, formData, onSave])

  const handleCancel = useCallback(() => {
    typeof onCancel === 'function' && onCancel()
    setCardState(undefined)
    setErrors(undefined)

    if (leaveType) {
      const {
        name,
        sysLeaveTypeCode,
        periodBasis,
        periodDuration,
        periodStart,
        inactiveDate,
        color,
        entByCitizen,
        isSys,
        parentId,
        grantLtId,
        isPaid
      } = leaveType

      setFormData({
        name,
        sysLeaveTypeCode,
        periodBasis,
        periodDuration,
        periodStart,
        inactiveDate,
        color,
        entByCitizen,
        isSys,
        parentId,
        grantLtId,
        isPaid
      })
    }
  }, [leaveType, onCancel])

  return (
    <EditableCard
      title="Basic information"
      bodyStyle={{ paddingBottom: leaveType && !sysLtFieldsLoading ? 6 : 24, paddingTop: 6 }}
      loading={sysLtFieldsLoading}
      state={!canModify ? 'readonly' : cardState}
      // state={!canModify || formData.isSys ? 'readonly' : cardState}
      formId="form-leavetype-info"
      onEdit={handleEdit}
      onSave={handleSave}
      onCancel={handleCancel}
    >
      <LeaveTypeInfoForm
        leaveTypeId={leaveType?.id}
        leaveTypeUnit={leaveType?.unit}
        data={formData}
        errors={errors}
        readOnly={cardState !== 'editing' && cardState !== 'saving'}
        onChange={data => setFormData({ ...formData, ...data })}
      />
    </EditableCard>
  )
}
