import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { PayRecordSgState } from '../types'

export const selectPayRecordById = createSelector(
  (state: StoreState) => state.payroll.payRecords,
  payRecordsState =>
    memoize(
      (payRunId: string, payRecordId: string): PayRecordSgState | undefined => {
        return payRecordsState[payRunId]?.entities[payRecordId]
      },
      (payRunId: string, payRecordId: string) => `${payRunId}|${payRecordId}`
    )
)
