import { replaceSysLtFields, setSysLtFieldsLoading } from '../reducers'
import { apiGetSysLtFields } from '../api/sys-lt-field.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchSysLtFields = (
  sysLeaveTypeCode: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!sysLeaveTypeCode) return

    const loading = getState().leave.sysLtFieldsLoading[sysLeaveTypeCode]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().leave.sysLtFields[sysLeaveTypeCode]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setSysLtFieldsLoading({ code: sysLeaveTypeCode, loading: true }))
      const { result, status } = await apiGetSysLtFields(sysLeaveTypeCode)
      if (status) {
        dispatch(replaceSysLtFields({ code: sysLeaveTypeCode, data: result }))
      }
    } finally {
      dispatch(setSysLtFieldsLoading({ code: sysLeaveTypeCode, loading: false }))
    }
  }
}
