import { showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiUnverifyPayWarning } from '../api/payroll.api'
import { PayWarningState } from '../types'
import { setPayWarning } from '../reducers'

export const unverifyPayWarning = (payRunId: string, id: string): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const { status, errors, message, errorData } = await apiUnverifyPayWarning(id)

    if (status) {
      const payWarning = getState().payroll.payWarnings[payRunId]?.entities[id]
      const data: PayWarningState = { ...(payWarning as PayWarningState), ...{ verifiedBy: '', verifiedDate: '' } }
      dispatch(setPayWarning({ payRunId, data }))
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
