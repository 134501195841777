import React, { ChangeEvent, FC, useMemo } from 'react'
import moment from 'moment-timezone'
import { Form, Input, Select } from '~/core-components'
import { Col, Row } from '~/components'
import { Errors } from '~/types/store'
import { formatNumberRange } from '~/utils'
import { LeaveApplyFormInfoState, ILeaveApply } from '../../../../types'
import { useLtSettings } from '../../../../hooks'
import { LveRelationOption } from '~/constants'
import { SSLeaveApply } from '~/features/my'

interface ReferenceNameFieldProps {
  leaveTypeId: string
  formInfo: LeaveApplyFormInfoState | undefined
  formData: ILeaveApply | SSLeaveApply
  readOnly?: boolean
  errors?: Errors
  onChange: (updates: { [field: string]: any }) => void
}

export const ReferenceNameField: FC<ReferenceNameFieldProps> = ({
  leaveTypeId,
  formInfo,
  formData,
  readOnly,
  errors,
  onChange
}) => {
  const [ltSettings] = useLtSettings(leaveTypeId)
  const ageMin = parseInt(ltSettings['relation_age_min']?.value || '0')
  const ageMax = parseInt(ltSettings['relation_age_max']?.value || '999')
  const ageDisplay = ageMax < 99 ? formatNumberRange(ageMin, ageMax + 1, 'yo') : undefined

  const options = useMemo(() => {
    return formInfo?.options
      .filter(opt => {
        const ageYear = moment(formData.startDate).year() - moment(opt.referenceDate).year()
        return (ageYear >= ageMin && ageYear <= ageMax + 1) || !opt.referenceId || !opt.referenceDate
      })
      .filter(opt => {
        if (formData.relationship) {
          return opt.relationship === formData.relationship || !opt.relationship
        }
        return true
      })
  }, [formInfo, formData.startDate, formData.relationship, ageMin, ageMax])

  if (!formInfo) return null

  return (
    <>
      <Row>
        <Col span={24}>
          <Form.Item
            label={formInfo?.label}
            validateStatus={errors?.referenceName ? 'error' : ''}
            help={errors?.referenceName}
            {...(ageDisplay && !readOnly
              ? {
                  tooltip: {
                    title: `Showing ${formInfo?.label?.toLowerCase() || ''} age ${ageDisplay}`
                  }
                }
              : {})}
          >
            {readOnly ? (
              formData.referenceName || '-'
            ) : (
              <Select
                value={formData.referenceId}
                onChange={(value: string) => {
                  var ref = formInfo?.options.find(opt => opt.referenceId === value)
                  const updates: Partial<ILeaveApply> = {
                    referenceId: value,
                    referenceSource: ref?.referenceSource || '',
                    referenceName:
                      ref?.referenceSource === LveRelationOption.entry && !ref?.referenceId
                        ? null
                        : ref?.referenceName || '',
                    referenceDate:
                      ref?.referenceSource === LveRelationOption.entry && !ref?.referenceId
                        ? null
                        : ref?.referenceDate || ''
                  }
                  onChange(updates)
                }}
              >
                {options?.map(opt => (
                  <Select.Option key={opt.referenceId} value={opt.referenceId}>
                    {opt.referenceName}
                    {opt.referenceDate ? ` - ${moment(opt.referenceDate).format('DD-MM-YYYY')}` : ''}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>
      {formData.referenceSource === LveRelationOption.entry && !formData?.referenceId && !readOnly && (
        <Row>
          <Col span={24}>
            <Form.Item label="" validateStatus={errors?.notes ? 'error' : ''} help={errors?.notes}>
              <Input
                value={formData.referenceName}
                onChange={(value?: ChangeEvent<HTMLInputElement>) => onChange({ referenceName: value?.target.value })}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
    </>
  )
}
