import { EmSelectionViewState } from '~/features/employee'
import { Pagination, Search } from '~/types/common'
import { request, getBaseUrl } from '~/utils/request'
import { IAddOedRecord, IUpdateOedRecord, OedRecordFormOfficeState, OedRecordFormState } from '../types'
import { EmSelectState } from '~/components'

const baseUrl = getBaseUrl('/employee/rptoedrecord')

export const apiGetOedRecord = (id: string) => request<OedRecordFormState>('get', `${baseUrl}/${id}`)

export const apiAddOedRecord = (formData: IAddOedRecord) => request<void>('post', `${baseUrl}`, formData)

export const apiUpdateOedRecord = (formData: IUpdateOedRecord) => request<void>('put', `${baseUrl}`, formData)

export const apiDeleteOedRecord = (id: string) => request('delete', `${baseUrl}/${id}`)

export const apiIncludeOedRecord = (id: string) => request<void>('post', `${baseUrl}/${id}/include`)

export const apiExcludeOedRecord = (id: string) => request<void>('post', `${baseUrl}/${id}/exclude`)

export const apiGetOedRecordNewEmSelections = (id: string, viewId: string, params: Pagination & Search) =>
  request<EmSelectionViewState>('get', `${baseUrl}/${id}/emselections/new/${viewId}`, params)

export const apiGetOedRecordEmSelect = (oedSubmissionId: string) =>
  request<EmSelectState[]>('get', `${baseUrl}/${oedSubmissionId}/emselect`)

export const apiGetOedRecordOfficeInfo = (oedSubmissionId: string, employeeId: string) =>
  request<OedRecordFormOfficeState>('get', `${baseUrl}/${oedSubmissionId}/office/${employeeId}`)
