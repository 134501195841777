import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { DailyPolicyRowState } from '../types'

export const selectDailyPolicyRowsBySection = createSelector(
  (state: StoreState) => state.attendance.dailyPolicyRows,
  dailyPolicyRowsState =>
    memoize(
      (dailyPolicyId: string, section: string): DailyPolicyRowState[] => {
        const data = Object.values(dailyPolicyRowsState[dailyPolicyId]?.entities || {}) as DailyPolicyRowState[]
        return data.filter(r => r.dailyPolicyId === dailyPolicyId && r.section === section)
      },
      (dailyPolicyId: string, section: string) => `${dailyPolicyId}||${section}`
    )
)
