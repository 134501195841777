import { replaceSysLeaveTypes, setSysLeaveTypesLoading } from '../reducers'
import { apiGetSysLeaveTypes } from '../api/sys-lt-field.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchSysLeaveTypes = (options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().leave.sysLeaveTypesLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().leave.sysLeaveTypes?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setSysLeaveTypesLoading(true))
      const { result, status } = await apiGetSysLeaveTypes()
      if (status) {
        dispatch(replaceSysLeaveTypes(result))
      }
    } finally {
      dispatch(setSysLeaveTypesLoading(false))
    }
  }
}
