import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { Ir21ResigneeState } from '../types'

export const selectIr21Resignees = createSelector(
  (state: StoreState) => state.tax.ir21Resignees,
  (ir21ResigneesState): Ir21ResigneeState[] => {
    return Object.values(ir21ResigneesState?.entities || {}) as Ir21ResigneeState[]
  }
)
