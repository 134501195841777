import { request, getBaseUrl } from '~/utils/request'
import { ISSTimeLogEdit, SSTimeLogApprovalHistoryState, SSTimeLogState } from '../types'

const baseUrl = getBaseUrl('/attendance/sstimelog')

export const apiGetMyTimeLogs = (startDate: string, endDate: string) =>
  request<SSTimeLogState[]>('get', baseUrl, { startDate, endDate })

export const apiUpdateMyTimeLog = (data: ISSTimeLogEdit) => request<void>('put', baseUrl, data)

export const apiGetMyTimeLogApprovalHistories = (timeLogId: string) =>
  request<SSTimeLogApprovalHistoryState[]>('get', `${baseUrl}/${timeLogId}/approvalhistory`)
