import React, { FC, useState, useCallback } from 'react'
import { useHistory } from 'react-router'
import { Form } from '~/core-components'
import { DrawerForm } from '~/components'
import { PayItemSgInfoForm, EMPTY_PAYITEM_SG_INFO_FORM_DATA, PayItemInfoFormData } from './PayItemSgInfoForm'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors } from '~/types/store'
import { addPayItem, updatePayItemBases } from '../../../actions'
import { mapPayItemInfoStateToPayItemInfo } from '../../../types/payitem.mapper'

interface AddPayItemDrawerProps {
  visible: boolean
  onClose: () => void
}

export const AddPayItemDrawer: FC<AddPayItemDrawerProps> = ({ visible, onClose }: AddPayItemDrawerProps) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<PayItemInfoFormData>(EMPTY_PAYITEM_SG_INFO_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const history = useHistory()

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      result = await dispatch(addPayItem(mapPayItemInfoStateToPayItemInfo(formData, 'sg'), 'sg'))

      if (result?.result?.id) {
        await dispatch(updatePayItemBases(result?.result?.id, formData.payItemBasisId))
      }
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_PAYITEM_SG_INFO_FORM_DATA)
      history.push(SETTINGS_ROUTES.payItemSg.replace(':id', result?.result?.id))
    }
  }, [formData, onClose, history])

  return (
    <DrawerForm
      open={visible}
      title="Add payroll item"
      onClose={onClose}
      confirmLoading={loading}
      width={650}
      formId="form-add-payitem"
    >
      <Form id="form-add-payitem" onFinish={handleOk}>
        <PayItemSgInfoForm data={formData} errors={errors} onChange={data => setFormData(data)} />
      </Form>
    </DrawerForm>
  )
}
