import { replaceLoginPermissions, setLoginPermissionsLoading } from '../reducers'
import { apiGetLoginPermissions } from '../api/login.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'
import { LoginPermissionState } from '../types'

export const fetchLoginPermissions = (
  loginId: string,
  tenantCode: string | null,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!loginId || !tenantCode) return

    const loading = getState().iam.loginPermissionsLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().iam.loginPermissions[loginId]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setLoginPermissionsLoading({ loginId, loading: true }))
      const { result, status } = await apiGetLoginPermissions(loginId, tenantCode)
      if (status) {
        dispatch(replaceLoginPermissions({ loginId, data: mapToState(result) }))
      }
    } finally {
      dispatch(setLoginPermissionsLoading({ loginId, loading: false }))
    }
  }
}

// remove createdBy, createdDate, updatedBy and updatedDate
const mapToState = (permission: LoginPermissionState[]): LoginPermissionState[] =>
  permission?.map(p => ({
    id: p.id,
    loginId: p.loginId,
    sysPermissionId: p.sysPermissionId,
    action: p.action,
    tenantCode: p.tenantCode
  }))
