import React, { CSSProperties, FC } from 'react'
import { Link } from 'react-router-dom'
import { Centered } from '~/components'
import { Image } from '~/core-components'

const centerStyles: CSSProperties = { textAlign: 'center' }

export const NoAccess: FC = () => {
  return (
    <Centered style={centerStyles}>
      <Image src="/no-access-image.png" width={250} />
      <div>There is no workspace under your account.</div>
      <Link to="/logout">Logout</Link>
    </Centered>
  )
}
