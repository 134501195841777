import { setSlackConfigs, setSlackConfigsLoading } from '../reducers'
import { apiGetSlackConfigs } from '../api/slack.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchSlackConfigs = (options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (options.strategy === 'when-empty') {
      const data = getState().notification.slackConfigs
      if (data) {
        return
      }
    }

    try {
      dispatch(setSlackConfigsLoading(true))
      const { result, status } = await apiGetSlackConfigs()
      if (status) {
        dispatch(setSlackConfigs(result))
      }
    } finally {
      dispatch(setSlackConfigsLoading(false))
    }
  }
}
