import React, { CSSProperties, FC } from 'react'
import { Button, Space } from '~/core-components'

interface DropdownActionsProps {
  onRefresh: () => void
}

const buttonsGroupStyle: CSSProperties = { padding: 10, justifyContent: 'flex-end', width: '100%' }

export const DropdownActions: FC<DropdownActionsProps> = ({ onRefresh }) => {
  return (
    <Space style={buttonsGroupStyle}>
      <Button icon={<i className="fal fa-refresh" />} size="small" onClick={onRefresh} />
    </Space>
  )
}
