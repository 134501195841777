import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Card, ColumnsType, Link, Table, Tag } from '~/core-components'
import { SSEmAddressPublicSelfState } from '~/features/my'
import { dispatch } from '~/stores/store'
import { IAddress, fetchEmpKeyvalues, formatAddress, selectKeyvaluesById } from '~/features/master'
import { fetchEmPublic } from '~/features/employee'
import { MutateMyAddressDrawer } from './MutateMyAddressDrawer'
import './MyAddresses.less'

interface MyAddressesProps {
  employeeId: string
  data: SSEmAddressPublicSelfState[]
  hideSelfUpdate: boolean
}

interface DrawerState {
  visible: boolean
  employeeId?: string
  data?: SSEmAddressPublicSelfState
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

export const MyAddresses: FC<MyAddressesProps> = ({ employeeId, data, hideSelfUpdate }) => {
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const countries = useSelector(selectKeyvaluesById)('country')

  useEffect(() => {
    dispatch(fetchEmpKeyvalues('country', undefined, undefined, { strategy: 'when-empty' }))
  }, [])

  const columns: ColumnsType<SSEmAddressPublicSelfState> = [
    {
      title: 'Effective date',
      key: 'startDateNew',
      dataIndex: 'startDateNew',
      width: 150,
      render: (value: string) => value && moment(value).format('DD MMM YYYY')
    },
    {
      title: 'Address',
      key: 'streetNew',
      dataIndex: 'streetNew',
      render: (value: string, record) => {
        const { blockNew, streetNew, levelNew, unitNew, cityNew, stateNew, postalCodeNew, countryCodeNew } = record
        const countryName = countries[countryCodeNew]?.value || ''
        const address = formatAddress(
          {
            block: blockNew,
            street: streetNew,
            level: levelNew,
            unit: unitNew,
            building: '',
            city: cityNew,
            state: stateNew,
            postalCode: postalCodeNew,
            countryCode: countryCodeNew
          } as IAddress,
          countryName
        )

        return (
          <>
            {address.addressLine1} {address.addressLine2} {record.isMainNew && <Tag type="primary">main</Tag>}
            {record.recordStatus === 'new_request' && <Tag type="secondary">new (pending)</Tag>}
            {record.recordStatus === 'has_request' && <Tag type="secondary">pending</Tag>}
          </>
        )
      }
    },
    {
      key: 'action',
      align: 'right',
      render: (value: string, record) =>
        !hideSelfUpdate && (
          <Link size="small" onClick={() => handleEditAddress(record)}>
            edit
          </Link>
        )
    }
  ]

  const handleAddAddress = useCallback(() => {
    setDrawerState({ visible: true, employeeId, data: undefined })
  }, [employeeId])

  const handleEditAddress = useCallback(
    (myAddress: SSEmAddressPublicSelfState) => {
      setDrawerState({ visible: true, employeeId, data: myAddress })
    },
    [employeeId]
  )

  const handleCloseDrawer = useCallback(
    (action: 'saved' | 'cancelled') => {
      setDrawerState(DEFAULT_DRAWER_STATE)
      if (action === 'saved') {
        dispatch(fetchEmPublic(employeeId, { strategy: 'always' }))
      }
      setDrawerState(DEFAULT_DRAWER_STATE)
    },
    [employeeId]
  )

  return (
    <Card
      title="Addresses"
      className="my-addresses"
      extra={employeeId && !hideSelfUpdate && <Link onClick={handleAddAddress}>add</Link>}
    >
      <Table rowKey="id" dataSource={data} pagination={false} columns={columns} />
      {!hideSelfUpdate && <MutateMyAddressDrawer {...drawerState} onClose={handleCloseDrawer} />}
    </Card>
  )
}
