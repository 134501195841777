import { CalendarEvent, DateRange } from '~/types/common'
import { request, getBaseUrl } from '~/utils/request'
import { SSCalendarLeaveRecordState } from '../types'

const baseUrl = getBaseUrl('/leave/ssleaverecordcalendar')

export const apiGetMyCalendarLeaveEvents = (viewAs: string, params: DateRange) =>
  request<CalendarEvent[]>('get', `${baseUrl}/${viewAs}`, params)

export const apiGetMyCalendarLeaveRecords = (viewAs: string, params: DateRange) =>
  request<SSCalendarLeaveRecordState[]>('get', `${baseUrl}/${viewAs}/record`, params)
