import { Col } from 'antd'
import moment from 'moment-timezone'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Checkbox, CheckboxChangeEvent, Form, Radio, RadioChangeEvent } from '~/core-components'
import { DrawerForm, EmpKeyValues, YearKeyValues, Row } from '~/components'
import { useFocus } from '~/hooks/use-focus'
import { YtdSubmissionFileType } from '~/constants'
import { TAX_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors } from '~/types/store'
import { IYtdSubmissionNew } from '../../types/ytd-submission.state'
import { addYtdSubmission } from '../../actions'

export interface AddYtdSubmissionDrawerProps {
  visible: boolean
  onClose: () => void
}

interface FormData extends IYtdSubmissionNew {}

const currentYear = moment().year()

export const EMPTY_FORM_DATA: FormData = {
  ytdYear: currentYear,
  employerTaxNo: '',
  fileType: YtdSubmissionFileType.original,
  processAll: true
}

export const AddYtdSubmissionDrawer: FC<AddYtdSubmissionDrawerProps> = ({ visible, onClose }) => {
  const [focusRef, setFocus] = useFocus(true)
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const history = useHistory()

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      result = await dispatch(addYtdSubmission(formData))
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()

      if (result?.result?.id) {
        const ytdSubmissionId = result?.result?.id
        history.push(TAX_ROUTES.submission.replace(':id', ytdSubmissionId).replace(':tab?', 'ir8a'))
        // if (formData.processAll) {
        //   dispatch(processPayroll(payRunId, undefined, 'all'))
        // }
      }
    }
  }, [formData, history, onClose])

  const handleCloseDrawer = useCallback(() => {
    typeof onClose === 'function' && onClose()
  }, [onClose])

  return (
    <DrawerForm
      open={visible}
      title="Create new IRAS submission"
      okText="Create"
      onClose={handleCloseDrawer}
      confirmLoading={loading}
      width={500}
      className="add-ytd-submission-drawer"
      formId="form-add-ytd-submission"
    >
      <Form id="form-add-ytd-submission" onFinish={handleOk}>
        <Row>
          <Col span={24}>
            <Form.Item label="Year" validateStatus={errors?.ytdYear ? 'error' : ''} help={errors?.ytdYear}>
              <YearKeyValues
                ref={focusRef}
                value={formData.ytdYear}
                startYear={currentYear}
                noOfYearBefore={-10}
                noOfYearAfter={1}
                allowClear={false}
                onChange={(value: string) => {
                  handleFormDataChange({ ytdYear: value })
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Company"
              validateStatus={errors?.employerTaxNo ? 'error' : ''}
              help={errors?.employerTaxNo}
            >
              <EmpKeyValues
                id="employerTax"
                display="keyvalue"
                value={formData.employerTaxNo}
                allowClear={false}
                onChange={(value: string) => handleFormDataChange({ employerTaxNo: value })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="File type" validateStatus={errors?.fileType ? 'error' : ''} help={errors?.fileType}>
              <Radio.Group
                value={formData.fileType}
                onChange={(event: RadioChangeEvent) =>
                  handleFormDataChange({
                    fileType: event.target.value,
                    processAll: event.target.value === YtdSubmissionFileType.original
                  })
                }
              >
                <Radio value={YtdSubmissionFileType.original}>Original</Radio>
                <Radio value={YtdSubmissionFileType.amendment}>Amendment</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row hidden={formData.fileType === YtdSubmissionFileType.amendment}>
          <Col span={24}>
            <Form.Item label="">
              <Checkbox
                checked={formData.processAll}
                onChange={(event: CheckboxChangeEvent) => {
                  handleFormDataChange({ processAll: event.target.checked })
                }}
              >
                Add all employees
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}
