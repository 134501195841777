import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'
import { apiGetROLeavePeriodYears } from '../../api/roleave-entitlement.api'
import { replaceROLeavePeriodYears, setROLeavePeriodYearsLoading } from '../../reducers'

export const fetchROLeavePeriodYears = (options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().my.roLeavePeriodYearsLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().my.roLeavePeriodYears?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setROLeavePeriodYearsLoading(true))
      const { result, status } = await apiGetROLeavePeriodYears()
      if (status) {
        dispatch(replaceROLeavePeriodYears(result))
      }
    } finally {
      dispatch(setROLeavePeriodYearsLoading(false))
    }
  }
}
