import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'

export const selectEmFamilies = createSelector(
  (state: StoreState) => state.employee.emFamilies,
  emFamiliesState =>
    memoize((employeeId?: string) => {
      if (!employeeId) return []

      return Object.values(emFamiliesState[employeeId]?.entities || {}).sort((a, b) => {
        if (a!.name.toLowerCase() < b!.name.toLowerCase()) return -1
        else if (a!.name.toLowerCase() > b!.name.toLowerCase()) return 1
        else return 0
      })
    })
)
