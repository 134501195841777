import React, { FC, useCallback, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { useSelector } from 'react-redux'
import { Alert, Button, Form, Input, Link, Select } from '~/core-components'
import { Col, EditableCard, EditableCardState, InactiveTag, InfoTooltip, Row } from '~/components'
import { usePermissionGate } from '~/features/iam/hooks'
import { selectLeaveTakenLeaveTypes } from '~/features/leave'
import { PayItemName } from '~/features/master'
import { usePayLeaveMaps } from '~/features/payroll'
import { emptyGuid, Permission, PermissionAction } from '~/constants'
import { dispatch } from '~/stores/store'
import { Errors, ActionResult } from '~/types/store'
import { isInactive } from '~/utils'
import { updatePayLeaveMaps } from '../../../actions'
import { selectLeaveTakenPayItems } from '../../../selectors'
import { PayLeaveMapState } from '../../../types'
import './PayLeaveMap.less'

export interface PayLeaveMapProps {
  payGroupId: string
}

const RELEVANT_ERRORS = ['leaveTypeId', 'payItemId']

interface FormData {
  leaveTakens: PayLeaveMapState[]
}

const EMPTY_FORM_DATA: FormData = {
  leaveTakens: []
}

export const PayLeaveMap: FC<PayLeaveMapProps> = ({ payGroupId }) => {
  const [cardState, setCardState] = useState<EditableCardState>()
  const canModify = usePermissionGate(Permission.payMaster, PermissionAction.Modify)
  const readOnly = cardState !== 'editing'
  const [errors, setErrors] = useState<Errors>()
  const relevantErrors = Object.keys(errors || {}).filter(e => RELEVANT_ERRORS.includes(e))
  const [payLeaveMaps, loading] = usePayLeaveMaps(payGroupId)
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)

  const unpaidLeaveTypes = useSelector(selectLeaveTakenLeaveTypes)(payGroupId)
  const deductionPayItems = useSelector(selectLeaveTakenPayItems)(payGroupId)

  const NEW_PAY_LEAVE_MAP: PayLeaveMapState[] = [
    {
      id: emptyGuid,
      payGroupId: payGroupId,
      leaveTypeId: '',
      payItemId: '',
      prevPayItemId: ''
    }
  ]

  const mapToState = (formData: FormData): PayLeaveMapState[] => {
    return [...formData.leaveTakens]
  }

  useEffect(() => {
    if (payLeaveMaps.length > 0) {
      setFormData(mapToFormData(payLeaveMaps))
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [payLeaveMaps])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setErrors(undefined)
    setFormData(data => ({ ...data, ...updates }))
  }, [])

  const handleEdit = useCallback(() => {
    setCardState('editing')
  }, [])

  const handleSave = useCallback(async () => {
    if (payGroupId) {
      setCardState('saving')
      setErrors(undefined)

      let result: ActionResult | undefined
      try {
        result = await dispatch(updatePayLeaveMaps(payGroupId, mapToState(formData)))
      } catch {
        setCardState('editing')
      }

      if (result?.errors) {
        setCardState('editing')
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setCardState(undefined)
      }
    }
  }, [payGroupId, formData])

  const handleCancel = useCallback(() => {
    setCardState(undefined)
    setErrors(undefined)

    if (payLeaveMaps.length > 0) {
      setFormData(mapToFormData(payLeaveMaps))
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [payLeaveMaps])

  return (
    <EditableCard
      className="pay-leave-map"
      title="Leave taken"
      bodyStyle={{ paddingBottom: 6, paddingTop: 6 }}
      state={canModify ? cardState : 'readonly'}
      loading={loading}
      onEdit={handleEdit}
      onSave={handleSave}
      onCancel={handleCancel}
    >
      <Row>
        <Col span={24}>
          {errors && relevantErrors.length > 0 && (
            <Alert
              className="pay-leave-map__error"
              type="error"
              message={
                <>
                  {relevantErrors.map((error, index) => (
                    <div key={index}>{errors[error]}</div>
                  ))}
                </>
              }
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {formData.leaveTakens.map((ltk, index) => (
            <Row gutter={6} key={index} className="pay-leave-map__row">
              <Col span={6}>
                {index === 0 && <div className="pay-leave-map__row-title">Leave type</div>}
                <Select
                  id="leaveType"
                  value={ltk.leaveTypeId}
                  showSearch
                  allowClear={false}
                  readOnly={readOnly}
                  optionFilterProp="title"
                  dropdownMatchSelectWidth={false}
                  onChange={(value: string) =>
                    handleFormDataChange({
                      leaveTakens: [
                        ...formData.leaveTakens.slice(0, index),
                        { ...formData.leaveTakens[index], leaveTypeId: value },
                        ...formData.leaveTakens.slice(index + 1, formData.leaveTakens.length)
                      ]
                    })
                  }
                >
                  {unpaidLeaveTypes.map(lt => {
                    return (
                      <Select.Option key={lt?.id} value={lt?.id || ''} title={lt?.name}>
                        {isInactive(lt!.inactiveDate) && <InactiveTag />}
                        {lt?.name}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Col>
              <Col span={7}>
                {index === 0 && (
                  <div className="pay-leave-map__row-title">
                    Payroll item (previous month)
                    <InfoTooltip title="Specify the payroll item for leave taken in the previous month." />
                  </div>
                )}
                <Select
                  id="payItem"
                  value={ltk.prevPayItemId}
                  showSearch
                  readOnly={readOnly}
                  optionFilterProp="title"
                  dropdownMatchSelectWidth={false}
                  onChange={(value: string) =>
                    handleFormDataChange({
                      leaveTakens: [
                        ...formData.leaveTakens.slice(0, index),
                        { ...formData.leaveTakens[index], prevPayItemId: value },
                        ...formData.leaveTakens.slice(index + 1, formData.leaveTakens.length)
                      ]
                    })
                  }
                >
                  {deductionPayItems.map(api => {
                    return (
                      <Select.Option key={api?.id} value={api?.id || ''} title={api?.name}>
                        {isInactive(api!.inactiveDate) && <InactiveTag />}
                        <PayItemName id={api!.id} />
                      </Select.Option>
                    )
                  })}
                </Select>
              </Col>
              <Col span={7}>
                {index === 0 && (
                  <div className="pay-leave-map__row-title">
                    Payroll item (current month)
                    <InfoTooltip title="Specify the payroll item for leave taken in the current month." />
                  </div>
                )}
                <Select
                  id="payItem"
                  value={ltk.payItemId}
                  showSearch
                  readOnly={readOnly}
                  optionFilterProp="title"
                  dropdownMatchSelectWidth={false}
                  onChange={(value: string) =>
                    handleFormDataChange({
                      leaveTakens: [
                        ...formData.leaveTakens.slice(0, index),
                        { ...formData.leaveTakens[index], payItemId: value },
                        ...formData.leaveTakens.slice(index + 1, formData.leaveTakens.length)
                      ]
                    })
                  }
                >
                  {deductionPayItems.map(api => {
                    return (
                      <Select.Option key={api?.id} value={api?.id || ''} title={api?.name}>
                        {isInactive(api!.inactiveDate) && <InactiveTag />}
                        <PayItemName id={api!.id} />
                      </Select.Option>
                    )
                  })}
                </Select>
              </Col>
              <Col span={3}>
                {index === 0 && (
                  <div className="pay-leave-map__row-title">
                    Start date
                    <InfoTooltip title="Only the leave records occurring from start date will be sent to payroll." />
                  </div>
                )}
                <Input.Date
                  inputReadOnly={readOnly}
                  value={ltk.startDate ? moment(ltk.startDate) : undefined}
                  onChange={(value: moment.Moment | null) => {
                    handleFormDataChange({
                      leaveTakens: [
                        ...formData.leaveTakens.slice(0, index),
                        { ...formData.leaveTakens[index], startDate: value?.format('YYYY-MM-DD') },
                        ...formData.leaveTakens.slice(index + 1, formData.leaveTakens.length)
                      ]
                    })
                  }}
                />
              </Col>
              <Col span={1} style={{ whiteSpace: 'nowrap' }}>
                <Button
                  size="small"
                  type="text"
                  icon={<i className="fal fa-xmark" />}
                  hidden={readOnly}
                  onClick={() =>
                    handleFormDataChange({
                      leaveTakens: [
                        ...formData.leaveTakens.slice(0, index),
                        ...formData.leaveTakens.slice(index + 1, formData.leaveTakens.length)
                      ]
                    })
                  }
                />
              </Col>
            </Row>
          ))}
          <Form.Item hidden={readOnly}>
            <Link
              onClick={() => handleFormDataChange({ leaveTakens: [...formData.leaveTakens, ...NEW_PAY_LEAVE_MAP] })}
            >
              add more
            </Link>
          </Form.Item>
        </Col>
      </Row>
    </EditableCard>
  )
}

const mapToFormData = (dataTakens: PayLeaveMapState[]): FormData => ({
  leaveTakens: dataTakens
})
