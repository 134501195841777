import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiUnlinkLeaveGrant } from '../api/leave-grant.api'
import { removeLeaveGrant } from '../reducers'

export const unlinkLeaveGrant = (employeeId: string, id: string): ThunkResult<void> => {
  return async dispatch => {
    const { status, errors, message, errorData } = await apiUnlinkLeaveGrant(id)
    if (status) {
      dispatch(removeLeaveGrant({ employeeId, id }))
      showSuccess('Unlinked')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
