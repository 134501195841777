import { EntityState } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { CalendarViewerState } from './calendar-viewer.state'
import { CalendarViewerCategory } from './dto'

export const calendarViewerPersistConfig = {
  key: 'calendarviewer',
  whitelist: ['calendarViewers'],
  storage
}

export interface CalendarViewerRootState {
  calendarViewers: { [category in CalendarViewerCategory]?: EntityState<CalendarViewerState> }
  calendarViewersLoading: { [category in CalendarViewerCategory]?: boolean }
}
