import React, { CSSProperties, FC, useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Button, Card, ColumnsType, Form, Skeleton, Space, Table, Text, Tooltip } from '~/core-components'
import { Person, SearchInput, WeeklyNavigation } from '~/components'
import {
  Screen,
  ViewCriteria,
  ViewCriteriaSimple,
  updateViewCriteria,
  useFirstView,
  useViewSchema
} from '~/features/selection'
import { ScheduledShiftDay } from '~/features/schedule'
import { useToggle } from '~/hooks'
import { formatHourMinute, getBaseUrl } from '~/utils'
import { StoreState } from '~/types/store'
import { dispatch } from '~/stores/store'
import { SsRole } from '~/constants'
import { refetchROScheduleRecordsView } from '../../reducers'
import { useROScheduleRecordsView } from '../../hooks'
import { ROScheduleRecordRowState, ROScheduleRecordViewDataState } from '../../types'
import { ROMutateScheduleRecordDrawer } from './components/ROMutateScheduleRecordDrawer'
import { ROInitialiseScheduleRecordDrawer } from './components/ROInitialiseScheduleRecordDrawer'
import { ROBulkAddScheduleRecordDrawer } from './components/ROBulkAddScheduleRecordDrawer'
import './ROScheduleRecords.less'

interface ROScheduleRecordsProps {
  role: SsRole
}

interface ScheduleTable extends ROScheduleRecordRowState {}

interface DrawerState {
  visible: boolean
  defaultEmployeeId?: string
  defaultDate?: string
  data?: ROScheduleRecordViewDataState
}

interface GenerateDrawerState {
  visible: boolean
  startDate?: string
  endDate?: string
  employeeIds?: string[]
  overwrite?: boolean
}

interface BulkAddDrawerState {
  visible: boolean
  startDate?: string
  employeeIds?: string[]
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }
const DEFAULT_GENERATE_DRAWER_STATE: GenerateDrawerState = { visible: false }
const DEFAULT_BULK_ADD_DRAWER_STATE: BulkAddDrawerState = { visible: false }
const DEBOUNCE_TIMEOUT = 800

const SCREEN_CODE: Screen = 'ro_schedule_record'
const PAGE_SIZE_OPTIONS = ['20', '50', '100']

const nameColWidth = 220
const shiftDayColWidth = 120
const defaultColWidth = 150
const paginationStyle: CSSProperties = { marginRight: 20 }

const weekStart = moment().startOf('isoWeek')
const defaultStartDate = weekStart.format('YYYY-MM-DD')
const baseUrl = getBaseUrl('/filestore')

export const ROScheduleRecords: FC<ROScheduleRecordsProps> = ({ role }) => {
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(20)
  const [search, setSearch] = useState<string>('')
  const [showLocation, toggleLocation] = useToggle(false)
  const [showShiftRole, toggleShiftRole] = useToggle(false)

  const [startDate, setStartDate] = useState(defaultStartDate)
  const endDate = moment(startDate).add(6, 'days').format('YYYY-MM-DD')

  const selection = useSelector((state: StoreState) => state.selection.sysSelectionFields[SCREEN_CODE])
  const [view, viewLoading] = useFirstView(SCREEN_CODE)
  const viewId = view?.id || ''
  const [schema] = useViewSchema(SCREEN_CODE, viewId)

  const rowLoading = useSelector((state: StoreState) => state.mySchedule.roScheduleRecordsViewRowRefetch)
  const cellLoading = useSelector((state: StoreState) => state.mySchedule.roScheduleRecordsViewCellRefetch)

  const [data, dataLoading] = useROScheduleRecordsView(
    viewId,
    role,
    startDate,
    endDate,
    page,
    pageSize,
    search,
    DEBOUNCE_TIMEOUT
  )

  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const [generateDrawerState, setGenerateDrawerState] = useState<GenerateDrawerState>(DEFAULT_GENERATE_DRAWER_STATE)
  const [bulkAddDrawerState, setBulkAddDrawerState] = useState<BulkAddDrawerState>(DEFAULT_BULK_ADD_DRAWER_STATE)

  const handleCriteriaApply = useCallback(
    async (criteria: ViewCriteria[]) => {
      if (viewId) {
        setPage(1)
        await dispatch(updateViewCriteria(SCREEN_CODE, viewId, { id: viewId, criteria }))
        dispatch(refetchROScheduleRecordsView())
      }
    },
    [viewId]
  )

  const handlePaginationChange = useCallback((page: number, pageSize?: number) => {
    setPage(page)
    setPageSize(pageSize || 20)
  }, [])

  const handleSearch = useCallback((value: string) => {
    setPage(1)
    setSearch(value)
  }, [])

  const handleWeekChange = useCallback((startDate: moment.Moment) => {
    setStartDate(startDate ? startDate.format('YYYY-MM-DD') : '')
  }, [])

  const handleScheduleClick = useCallback((schedule: ROScheduleRecordViewDataState) => {
    setDrawerState({ visible: true, data: schedule })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const handleOpenGenerateDrawer = useCallback(() => {
    setGenerateDrawerState({
      visible: true,
      startDate,
      endDate,
      employeeIds: []
    })
  }, [startDate, endDate])

  const handleCloseGenerateDrawer = useCallback(() => {
    setGenerateDrawerState(DEFAULT_GENERATE_DRAWER_STATE)
  }, [])

  const handleOpenBulkAddDrawer = useCallback(() => {
    setBulkAddDrawerState({
      visible: true,
      startDate,
      employeeIds: []
    })
  }, [startDate])

  const handleCloseBulkAddDrawer = useCallback(() => {
    setBulkAddDrawerState(DEFAULT_BULK_ADD_DRAWER_STATE)
  }, [])

  const handleAddShiftDay = useCallback((employeeId: string, scheduleDate: string) => {
    setDrawerState({ visible: true, defaultEmployeeId: employeeId, defaultDate: scheduleDate })
  }, [])

  const tableWidth =
    (schema?.selection.reduce(
      (sum, f) => (sum += selection?.entities[f.selectionFieldId]?.width || defaultColWidth),
      0
    ) || 0) +
    nameColWidth +
    7 * shiftDayColWidth

  const columns = useMemo(() => {
    let columns: ColumnsType<ScheduleTable> = [
      {
        // title: (
        //   <Select allowClear={false} value="view_emp" bordered={false}>
        //     <Select.Option value="view_emp">View by employee</Select.Option>
        //     <Select.Option value="view_role">View by role</Select.Option>
        //   </Select>
        // ),
        title: '',
        key: 'employeeName',
        dataIndex: 'employeeName',
        fixed: 'left',
        className: 'first-col',
        width: nameColWidth,
        render: (value: string, record) => (
          <Person
            name={record.employeeName}
            description={record.description}
            photo={record.photoId && `${baseUrl}/file/${record.photoId}/thumbnailphoto/36`}
          />
        )
      }
    ]

    for (let m = moment(startDate); m.isSameOrBefore(endDate); m.add(1, 'day')) {
      const currentDate = m.format('YYYY-MM-DD')

      columns.push({
        title: (
          <>
            <div className="shift-day__dow">{m.format('ddd')}</div>
            {m.format('D/M')}
          </>
        ),
        key: m.format('D/M'),
        dataIndex: `d${m.diff(moment(startDate), 'day')}`,
        className: 'shift-day__date',
        align: 'center',
        render: (value: ROScheduleRecordViewDataState[], record) => {
          if (viewLoading || (!rowLoading && !cellLoading && dataLoading) || rowLoading === record.employeeId) {
            return <Skeleton.Input size="small" active />
          }

          return (
            <div className="shift-day__container">
              {Array.isArray(value) && value?.length > 0 ? (
                value.map(s => (
                  <ScheduledShiftDay
                    key={s.id}
                    schedule={s}
                    showLocation={showLocation}
                    showShiftRole={showShiftRole}
                    loading={cellLoading === s.id}
                    onClick={() => handleScheduleClick(s)}
                  />
                ))
              ) : (
                <Button
                  type="dashed"
                  className="shift-day__add"
                  icon={<i className="fal fa-plus" />}
                  onClick={() => handleAddShiftDay(record.employeeId, currentDate)}
                />
              )}
            </div>
          )
        }
      })
    }

    columns.push({
      key: 'summary',
      dataIndex: 'employeeId',
      className: 'shift-day__summary',
      width: 55,
      render: (value: string, record) => {
        let totalHours = 0
        for (let i = 0; i <= 6; i++) {
          if (Array.isArray(record[`d${i}`]) && record[`d${i}`]?.length > 0) {
            totalHours += record[`d${i}`].reduce(
              (sum: number, v: ROScheduleRecordViewDataState) => sum + v.normalHours,
              0
            )
          }
        }
        return (
          <Space size={4}>
            <i className="fal fa-clock" />
            {formatHourMinute(totalHours, 'hour')}
          </Space>
        )
      }
    })

    return columns
  }, [
    startDate,
    endDate,
    showLocation,
    showShiftRole,
    viewLoading,
    dataLoading,
    rowLoading,
    cellLoading,
    handleScheduleClick,
    handleAddShiftDay
  ])

  return (
    <div className="ro-schedule-records">
      <div className="ro-schedule-records__body">
        <div className="ro-schedule-records__action-bar">
          <Form.Item label="">
            <SearchInput onSearch={handleSearch} placeholder="Search by name" expandWidth={250} />
          </Form.Item>
          <ViewCriteriaSimple screenCode={SCREEN_CODE} viewId={viewId} onApply={handleCriteriaApply} label="" />
          {data?.hasShift && (
            <>
              <Tooltip title={showLocation ? 'Hide location' : 'Show location'}>
                <Button
                  icon={<i className="fal fa-location-dot" />}
                  type={showLocation ? 'primary' : 'default'}
                  onClick={toggleLocation}
                />
              </Tooltip>
              <Tooltip title={showShiftRole ? 'Hide shift role' : 'Show shift role'}>
                <Button
                  icon={<i className="fal fa-briefcase" />}
                  type={showShiftRole ? 'primary' : 'default'}
                  onClick={toggleShiftRole}
                />
              </Tooltip>
            </>
          )}
          <Tooltip title="Generate the schedule based on the default work calendar specified in People Info. This feature applies only to employees whose work schedule is set to 'based on schedule'.">
            {!data?.hasShift ? (
              <Button type="primary" onClick={handleOpenGenerateDrawer}>
                Initialise from calendar
              </Button>
            ) : (
              <Button icon={<i className="fal fa-calendar-lines-pen" />} onClick={handleOpenGenerateDrawer} />
            )}
          </Tooltip>
          <Button type="primary" onClick={handleOpenBulkAddDrawer}>
            Plan schedule
          </Button>
          <Form.Item label="">
            <WeeklyNavigation defaultValue={moment(startDate)} onChange={handleWeekChange} />
          </Form.Item>
        </div>
        <Card table fitParent>
          <Table
            rowKey="employeeId"
            dataSource={data?.data}
            fitParent
            columns={columns}
            loading={dataLoading || viewLoading}
            scroll={{ x: tableWidth, y: 1000 }}
            pagination={{
              total: data?.count,
              current: page,
              pageSize,
              pageSizeOptions: PAGE_SIZE_OPTIONS,
              showSizeChanger: true,
              onChange: handlePaginationChange,
              style: paginationStyle,
              showTotal: (total: number, range: [number, number]) => {
                if (range && range.length === 2 && !isNaN(range[0]) && !isNaN(range[1])) {
                  return (
                    <Text size="small">
                      {range[0]}-{range[1]} of {total}
                    </Text>
                  )
                }
              }
            }}
          />
        </Card>
        <ROMutateScheduleRecordDrawer role={role} {...drawerState} onClose={handleCloseDrawer} />
        <ROInitialiseScheduleRecordDrawer role={role} {...generateDrawerState} onClose={handleCloseGenerateDrawer} />
        <ROBulkAddScheduleRecordDrawer role={role} {...bulkAddDrawerState} onClose={handleCloseBulkAddDrawer} />
      </div>
    </div>
  )
}
