import React, { CSSProperties, FC, ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import Tooltip from 'antd/lib/tooltip'
import moment from 'moment-timezone'
import { Button, Form, Input, SecondaryText } from '~/core-components'
import { Col, EditableCard, EditableCardState, EmpKeyValues, NewTabLinkIcon, Row, SysOptions } from '~/components'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { formatYearMonth } from '~/utils'
import { useFocus } from '~/hooks/use-focus'
import { dispatch } from '~/stores/store'
import { Errors, ActionResult } from '~/types/store'
import { IEmployeePersonal, EmployeeState, EmRequestState } from '../../../types'
import { mapEmployeeStateToEmployeePersonal } from '../../../types/employee.mapper'
import { updateEmployeePersonal } from '../../../actions'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { usePersonalEmRequests } from '~/features/employee/hooks'
import { EmRequestApprovalDrawer } from './EmRequestApprovalDrawer'

interface EmployeePersonalProps {
  employee?: EmployeeState
  onEdit?: () => void
  onSave?: () => void
  onCancel?: () => void
}

interface DrawerState {
  data?: EmRequestState
  title: string
  visible: boolean
}

const DEFAULT_DRAWER_STATE: DrawerState = { title: '', visible: false }

const EMPTY_FORM_DATA: IEmployeePersonal = {
  birthDate: '',
  gender: '',
  maritalStatus: '',
  marriageDate: '',
  raceId: '',
  religionId: '',
  nationalityId: '',
  age: '',
  birthPlace: '',
  personalEmail: '',
  personalPhone: '',
  bio: ''
}

const cardStyle: CSSProperties = { margin: 24 }
const labelSecondaryStyle: CSSProperties = { marginLeft: 10 }
const btnPendingStyle: CSSProperties = {
  backgroundColor: '#ff8500',
  borderColor: '#ff8500',
  color: '#ffffff',
  marginRight: 30
}

export const EmployeePersonal: FC<EmployeePersonalProps> = ({
  employee,
  onEdit,
  onSave,
  onCancel
}: EmployeePersonalProps) => {
  const [cardState, setCardState] = useState<EditableCardState>()
  const [formData, setFormData] = useState<IEmployeePersonal>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const [focusRef] = useFocus(cardState === 'editing')
  const canView = usePermissionGate(Permission.employeePersonal)
  const canModify = usePermissionGate(Permission.employeePersonal, PermissionAction.Modify)
  const canModifyMs = usePermissionGate(Permission.master, PermissionAction.Modify)
  const readOnly = cardState !== 'editing' && cardState !== 'saving'

  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const [emRequests, emRequestLoading] = usePersonalEmRequests(employee?.id || '')

  useEffect(() => {
    if (employee) {
      const {
        birthDate,
        gender,
        maritalStatus,
        marriageDate,
        raceId,
        religionId,
        nationalityId,
        age,
        birthPlace,
        personalEmail,
        personalPhone,
        bio
      } = employee
      setFormData({
        birthDate,
        gender,
        maritalStatus,
        marriageDate,
        raceId,
        religionId,
        nationalityId,
        age,
        birthPlace,
        personalEmail,
        personalPhone,
        bio
      })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [employee])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    if ('maritalStatus' in updates) {
      if (updates.maritalStatus === 'single') {
        updates.marriageDate = null
      }
    }

    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  const handleEdit = useCallback(() => {
    setCardState('editing')
    typeof onEdit === 'function' && onEdit()
  }, [onEdit])

  const handleSave = useCallback(async () => {
    if (employee) {
      setCardState('saving')
      setErrors(undefined)

      typeof onSave === 'function' && onSave()

      let result: ActionResult | undefined
      try {
        result = await dispatch(
          updateEmployeePersonal(employee.id, mapEmployeeStateToEmployeePersonal(employee), formData)
        )
      } catch {
        setCardState('editing')
      }

      if (result?.errors) {
        setCardState('editing')
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setCardState(undefined)
      }
    }
  }, [employee, formData, onSave])

  const handleCancel = useCallback(() => {
    typeof onCancel === 'function' && onCancel()
    setCardState(undefined)
    setErrors(undefined)

    if (employee) {
      const {
        birthDate,
        gender,
        maritalStatus,
        marriageDate,
        raceId,
        religionId,
        nationalityId,
        age,
        birthPlace,
        personalEmail,
        personalPhone,
        bio
      } = employee
      setFormData({
        birthDate,
        gender,
        maritalStatus,
        marriageDate,
        raceId,
        religionId,
        nationalityId,
        age,
        birthPlace,
        personalEmail,
        personalPhone,
        bio
      })
    }
  }, [employee, onCancel])

  const cardBodyStyle = useMemo(
    () => ({ paddingBottom: employee ? 6 : 24, paddingTop: canModify ? 6 : 24 }),
    [employee, canModify]
  )

  const handleOpenRequest = useCallback((emRequest?: EmRequestState) => {
    setDrawerState({ title: 'Personal request approval', visible: true, data: emRequest })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  if (!canView) return null

  return (
    <>
      <EditableCard
        style={cardStyle}
        bodyStyle={cardBodyStyle}
        state={canModify ? cardState : 'readonly'}
        formId="form-em-personal"
        onEdit={handleEdit}
        onSave={handleSave}
        onCancel={handleCancel}
        extra={
          emRequests.length > 0 &&
          canModify &&
          cardState === undefined && (
            <Button
              style={btnPendingStyle}
              size="small"
              onClick={() => handleOpenRequest(emRequests.length > 0 ? emRequests[0] : undefined)} // for now it's only get the first row
              loading={emRequestLoading}
            >
              {emRequests.length > 1 ? emRequests.length : ''} pending requests
            </Button>
          )
        }
      >
        <Row gutter={30}>
          <Col span={12}>
            <Form.Item label="Date of birth" validateStatus={errors?.birthDate ? 'error' : ''} help={errors?.birthDate}>
              <Form.Item noStyle>
                <Input.Date
                  ref={focusRef}
                  value={formData.birthDate ? moment(formData.birthDate) : undefined}
                  inputReadOnly={readOnly}
                  onChange={(value: moment.Moment | null) =>
                    handleFormDataChange({ birthDate: value?.format('YYYY-MM-DD') })
                  }
                />
              </Form.Item>
              <Tooltip title="Age">
                <SecondaryText style={labelSecondaryStyle}>{formatYearMonth(employee?.age)}</SecondaryText>
              </Tooltip>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Gender">
              <SysOptions
                type="gender"
                value={formData.gender}
                readOnly={readOnly}
                onChange={(gender: string) => handleFormDataChange({ gender })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col span={12}>
            <Form.Item label="Marital status">
              <SysOptions
                type="marital_status"
                value={formData.maritalStatus}
                readOnly={readOnly}
                onChange={(maritalStatus: string) => handleFormDataChange({ maritalStatus })}
              />
            </Form.Item>
          </Col>
          <Col span={12} hidden={formData.maritalStatus === 'single'}>
            <Form.Item label="Marriage date">
              <Input.Date
                value={formData.marriageDate ? moment(formData.marriageDate) : undefined}
                inputReadOnly={readOnly}
                onChange={(value: moment.Moment | null) =>
                  handleFormDataChange({ marriageDate: value?.format('YYYY-MM-DD') })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col span={12}>
            <Form.Item label="Race">
              <NewTabLinkIcon path={SETTINGS_ROUTES.races} tooltipText="Open race in a new tab" hidden={!canModifyMs}>
                <EmpKeyValues
                  id="race"
                  value={formData.raceId}
                  readOnly={readOnly}
                  onChange={(raceId: string) => handleFormDataChange({ raceId })}
                />
              </NewTabLinkIcon>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Religion">
              <NewTabLinkIcon
                path={SETTINGS_ROUTES.religions}
                tooltipText="Open religion in a new tab"
                tooltipPlacement="topRight"
                hidden={!canModifyMs}
              >
                <EmpKeyValues
                  id="religion"
                  value={formData.religionId}
                  readOnly={readOnly}
                  onChange={(religionId: string) => handleFormDataChange({ religionId })}
                />
              </NewTabLinkIcon>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col span={12}>
            <Form.Item label="Nationality">
              <EmpKeyValues
                id="nationality"
                value={formData.nationalityId}
                readOnly={readOnly}
                onChange={(nationalityId: string) => handleFormDataChange({ nationalityId })}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Birth place">
              <Input
                value={formData.birthPlace}
                readOnly={readOnly}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleFormDataChange({ birthPlace: event.target.value })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col span={12}>
            <Form.Item
              label="Personal email"
              validateStatus={errors?.personalEmail ? 'error' : ''}
              help={errors?.personalEmail}
            >
              <Input
                value={formData.personalEmail}
                readOnly={readOnly}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleFormDataChange({ personalEmail: event.target.value })
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Personal phone">
              <Input
                value={formData.personalPhone}
                readOnly={readOnly}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleFormDataChange({ personalPhone: event.target.value })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row hidden={readOnly && !formData.bio}>
          <Col span={24}>
            <Form.Item label="Bio">
              <Input.TextArea
                value={formData.bio}
                readOnly={readOnly}
                rows={4}
                onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                  handleFormDataChange({ bio: event.target.value })
                }
              />
            </Form.Item>
          </Col>
        </Row>
      </EditableCard>
      {canModify && <EmRequestApprovalDrawer {...drawerState} onClose={handleCloseDrawer} />}
    </>
  )
}
