import { removeEmRequest } from '../reducers'
import { apiRejectEmRequest } from '../api/em-request.api'
import { ThunkResult } from '~/types/store'
import { showError, showSuccess } from '~/utils'

export const rejectEmRequest = (employeeId: string, id: string, emInfoType: string): ThunkResult<void> => {
  return async dispatch => {
    const { status, result, errors, message } = await apiRejectEmRequest(id, emInfoType)

    if (status) {
      dispatch(removeEmRequest({ employeeId, id }))
      showSuccess('Rejected')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message)
      }
    }
    return { result, errors }
  }
}
