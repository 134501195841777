import React, { FC, useCallback, useState } from 'react'
import { Button } from '~/core-components'
import { usePermissionGate } from '~/features/iam'
import { Permission, PermissionAction } from '~/constants'
import { BulkAddScheduleRecordDrawer } from './BulkAddScheduleRecordDrawer'

interface BulkAddScheduleRecordButtonProps {
  startDate: string
}

interface DrawerState {
  visible: boolean
  startDate?: string
  employeeIds?: string[]
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

export const BulkAddScheduleRecordButton: FC<BulkAddScheduleRecordButtonProps> = ({ startDate }) => {
  const canModify = usePermissionGate(Permission.schRecord, PermissionAction.Modify)
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)

  const handleOpenDrawer = useCallback(() => {
    setDrawerState({
      visible: true,
      startDate,
      employeeIds: []
    })
  }, [startDate])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  if (!canModify) return null

  return (
    <>
      <Button type="primary" onClick={handleOpenDrawer}>
        Plan schedule
      </Button>
      <BulkAddScheduleRecordDrawer {...drawerState} onClose={handleCloseDrawer} />
    </>
  )
}
