import React, { FC, useCallback, useState } from 'react'
import { Button, Card, ColumnsType, Link, PageHeader, Table } from '~/core-components'
import { DocumentTitle, InactiveTag } from '~/components'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { isInactive } from '~/utils/dateUtil'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { MutateShiftCategoryDrawer } from './components/MutateShiftCategoryDrawer'
import { ShiftCategoryState } from '../../types'
import { useShiftCategories } from '../../hooks'
import './ShiftCategories.less'

type ShiftCategoryTable = ShiftCategoryState

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: '',
    breadcrumbName: 'Overview'
  }
]

interface DrawerState {
  visible: boolean
  data?: ShiftCategoryState
}
const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

interface ShiftCategoryProps {}

export const ShiftCategories: FC<ShiftCategoryProps> = () => {
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const [shiftCategories, loading] = useShiftCategories()
  const canModify = usePermissionGate(Permission.shiftCategory, PermissionAction.Modify)

  const handleAddShiftCategory = useCallback(() => {
    setDrawerState({ visible: true, data: undefined })
  }, [])

  const handleEditShiftCategory = useCallback((shiftCategory: ShiftCategoryState) => {
    setDrawerState({ visible: true, data: shiftCategory })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const columns: ColumnsType<ShiftCategoryTable> = [
    {
      title: 'Shift category',
      key: 'name',
      dataIndex: 'name',
      render: (value: string, record) => (
        <>
          {value} {isInactive(record.inactiveDate) && <InactiveTag />}
        </>
      )
    },
    {
      key: 'action',
      align: 'right',
      render: (value: string, record) =>
        canModify && (
          <Link size="small" onClick={() => handleEditShiftCategory(record)}>
            edit
          </Link>
        )
    }
  ]

  return (
    <div id="shift-categories" className="shift-categories">
      <DocumentTitle title="Shift Categories" />
      <PageHeader
        title="Shift categories"
        containerId="shift-categories"
        breadcrumb={{ routes }}
        extra={
          canModify && (
            <Button key="add" onClick={handleAddShiftCategory}>
              Add shift category
            </Button>
          )
        }
      />
      <div className="shift-categories__body">
        <Card table>
          <Table rowKey="id" dataSource={shiftCategories} pagination={false} columns={columns} loading={loading} />
        </Card>
        {canModify && <MutateShiftCategoryDrawer {...drawerState} onClose={handleCloseDrawer} />}
      </div>
    </div>
  )
}
