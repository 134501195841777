import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import confirm from 'antd/lib/modal/confirm'
import { Button, Form, Link, Select, Space, Tooltip } from '~/core-components'
import { Col, ErrorDisplay, Row } from '~/components'
import { Errors, StoreState } from '~/types/store'
import { dispatch } from '~/stores/store'
import { ITimeLogColumnMap } from '../../../../../types'
import { SysImportField } from '../../../../../../../constants/employee'
import { useTimeLogTemplatesDict } from '../../../../../hooks'
import { deleteTimeLogTemplate, saveTimeLogTemplate } from '../../../../../actions'
import { ImportTimeLogFormData } from '../ImportTimeLogDrawer'
import { SelectTimeLogTemplate } from './SelectTimeLogTemplate'
import { SaveTimeLogTemplateModal } from './SaveTimeLogTemplateModal'
import './TimeLogColumnMapping.less'

interface TimeLogColumnMappingProps {
  visible: boolean
  data: Partial<ImportTimeLogFormData>
  errors?: Errors
  onChange: (data: Partial<ImportTimeLogFormData>) => void
}

const NEW_ROW: ITimeLogColumnMap = {
  importColumn: '',
  sourceColumn: ''
}

interface ModalState {
  visible: boolean
  data?: ITimeLogColumnMap[]
}

const DEFAULT_MODAL_STATE: ModalState = { visible: false }

export const TimeLogColumnMapping: FC<TimeLogColumnMappingProps> = ({ visible, data, errors, onChange }) => {
  const sourceColumns = useSelector((store: StoreState) => store.attendance.timeLogImport?.sourceColumns || [])
  const importColumns = useSelector((store: StoreState) => store.attendance.timeLogImport?.importColumns || [])
  const [modalState, setModalState] = useState<ModalState>(DEFAULT_MODAL_STATE)
  const [templateDict] = useTimeLogTemplatesDict()
  const [templateId, setTemplateId] = useState<string>('')
  const template = templateDict[templateId]

  useEffect(() => {
    if (visible) {
      setTemplateId('')
    }
  }, [visible])

  const handleTemplateChange = useCallback(
    (templateId: string) => {
      setTemplateId(templateId)

      onChange({
        columnMap:
          templateDict[templateId]?.fields.map(f => ({ importColumn: f.importColumn, sourceColumn: f.sourceColumn })) ||
          []
      })
    },
    [templateDict, onChange]
  )

  const handleTemplateSave = useCallback(async () => {
    if (!template?.id) {
      setModalState({ visible: true, data: data.columnMap })
    } else {
      const payload = { id: template.id, name: template.name || 'Untitled', fields: data.columnMap || [] }
      await dispatch(saveTimeLogTemplate(payload))
    }
  }, [template, data.columnMap])

  const handleTemplateCopy = useCallback(() => {
    setModalState({ visible: true, data: data.columnMap })
  }, [data.columnMap])

  const handleTemplateSaved = useCallback((id?: string) => {
    setTemplateId(id || '')
  }, [])

  const handleCloseTemplate = useCallback(() => {
    setModalState(DEFAULT_MODAL_STATE)
  }, [])

  const handleTemplateDelete = useCallback(() => {
    if (templateId) {
      confirm({
        title: `Delete template`,
        content: `Do you want to delete template "${template?.name}"?`,
        onOk: async () => {
          await dispatch(deleteTimeLogTemplate(templateId))
          setTemplateId(Object.keys(templateDict).find(id => id !== templateId) || '')
        },
        okText: 'Delete',
        okType: 'danger'
      })
    }
  }, [templateId, template, templateDict])

  return (
    <div className="time-log-column-mapping" hidden={!visible}>
      <div className="time-log-column-mapping__template">
        <Form layout="horizontal" colon={false}>
          <Space>
            <Form.Item label="Template">
              <SelectTimeLogTemplate value={templateId} onChange={handleTemplateChange} />
            </Form.Item>
            <Button
              className="time-log-template__save"
              icon={
                <Tooltip title={templateId ? 'Save template' : 'Save as new template'}>
                  <i className="fal fa-floppy-disk" />
                </Tooltip>
              }
              onClick={handleTemplateSave}
            />
            {templateId && (
              <>
                <Button
                  className="time-log-template__copy"
                  icon={
                    <Tooltip title="Copy template">
                      <i className="fal fa-copy" />
                    </Tooltip>
                  }
                  onClick={handleTemplateCopy}
                />
                <Button
                  className="time-log-templatedelete"
                  icon={
                    <Tooltip title="Delete template">
                      <i className="fal fa-trash" />
                    </Tooltip>
                  }
                  onClick={handleTemplateDelete}
                />
              </>
            )}
          </Space>
          <SaveTimeLogTemplateModal {...modalState} onSuccess={handleTemplateSaved} onClose={handleCloseTemplate} />
        </Form>
      </div>
      <ErrorDisplay errors={errors} />
      {data.columnMap?.map((col, index) => (
        <Row gutter={6} key={index} className="time-log-column-row">
          <Col span={11}>
            {index === 0 && <div className="time-log-column-row__title">Column in Zealys</div>}
            <Select
              readOnly={col.importColumn === SysImportField.BADGE_NO}
              value={col.importColumn}
              onChange={(importColumn: string) =>
                onChange({
                  columnMap: [
                    ...data.columnMap!.slice(0, index),
                    { importColumn, sourceColumn: col.sourceColumn },
                    ...data.columnMap!.slice(index + 1, data.columnMap!.length)
                  ]
                })
              }
            >
              {importColumns.map(t => (
                <Select.Option key={t.id} value={t.id}>
                  {t.displayName}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={12}>
            {index === 0 && <div className="time-log-column-row__title">Column in Source file</div>}
            <Select
              value={
                !col.sourceColumn || sourceColumns.map(c => c.fieldName).includes(col.sourceColumn)
                  ? col.sourceColumn
                  : `${col.sourceColumn} (not found)`
              }
              onChange={(sourceColumn: string) =>
                onChange({
                  columnMap: [
                    ...data.columnMap!.slice(0, index),
                    { importColumn: col.importColumn, sourceColumn },
                    ...data.columnMap!.slice(index + 1, data.columnMap!.length)
                  ]
                })
              }
            >
              {sourceColumns.map(t => (
                <Select.Option key={t.fieldName} value={t.fieldName}>
                  {t.displayName}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={1} style={{ whiteSpace: 'nowrap' }}>
            <Button
              size="small"
              type="text"
              icon={<i className="fal fa-xmark" />}
              onClick={() =>
                onChange({
                  columnMap: [
                    ...data.columnMap!.slice(0, index),
                    ...data.columnMap!.slice(index + 1, data.columnMap!.length)
                  ]
                })
              }
            />
          </Col>
        </Row>
      ))}
      <Form.Item>
        <Link onClick={() => onChange({ columnMap: [...data.columnMap!, NEW_ROW] })}>add more</Link>
      </Form.Item>
    </div>
  )
}
