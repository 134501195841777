import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchLogins } from '../actions'
import { selectLogins } from '../reducers'
import { LoginState } from '../types'

const tenant = localStorage.getItem('tenant')

export const useLogins = (fetchStrategy: FetchStrategy = 'always'): [LoginState[], boolean] => {
  const logins = useSelector(selectLogins)
  const loading = useSelector((state: StoreState) => state.iam.loginsLoading)

  useEffect(() => {
    if (tenant) {
      dispatch(fetchLogins(tenant, { strategy: fetchStrategy }))
    }
  }, [fetchStrategy])

  return [logins, loading]
}
