import { removeEmIdentityRequest } from '../reducers'
import { apiRejectEmIdentityRequest } from '../api/em-identity-request.api'
import { ThunkResult } from '~/types/store'
import { showError, showSuccess } from '~/utils'

export const rejectEmIdentityRequest = (employeeId: string, id: string): ThunkResult<void> => {
  return async dispatch => {
    const { status, result, errors, message } = await apiRejectEmIdentityRequest(id)

    if (status) {
      dispatch(removeEmIdentityRequest({ employeeId, id }))
      showSuccess('Rejected')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message)
      }
    }
    return { result, errors }
  }
}
