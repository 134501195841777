import React, { FC, useEffect } from 'react'
import { Layout } from 'antd'
import { ErrorBoundary } from '~/components'
import { AuthConsumer } from '../../auth/AuthProvider'
import { AdminSidebar } from './AdminSidebar/AdminSidebar'
import { AdminHeader } from './AdminHeader/AdminHeader'
import './AdminLayout.less'

const { Content } = Layout

interface AdminLayoutProps {}

export const AdminLayout: FC<AdminLayoutProps> = ({ children }) => {
  useEffect(() => {
    document.body.classList.remove('self-service-layout')
    document.body.classList.add('admin-layout')
  }, [])

  return (
    <AuthConsumer>
      {({ isAuthenticated }) => {
        const isAuth = isAuthenticated()
        return (
          <Layout className="admin-layout">
            {isAuth && <AdminHeader />}
            <Layout className="admin-layout__main">
              {isAuth && <AdminSidebar />}
              <Content className="admin-layout__content">
                <ErrorBoundary>{children}</ErrorBoundary>
              </Content>
            </Layout>
          </Layout>
        )
      }}
    </AuthConsumer>
  )
}
