import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Button, Form, Input } from '~/core-components'
import { Col, EditableCard, EditableCardState, Row, SysOptions } from '~/components'
import { fetchEmpKeyvalues, selectKeyvaluesById } from '~/features/master'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { Fund } from '~/constants'
import { useFocus } from '~/hooks/use-focus'
import { useFirstInView } from '~/hooks/use-first-in-view'
import { dispatch } from '~/stores/store'
import { StoreState, Errors, ActionResult } from '~/types/store'
import { KeyValue } from '~/types/common'
import { IEmployeeStatutory, EmployeeState } from '../../../types'
import { mapEmployeeStateToEmployeeStatutory } from '../../../types/employee.mapper'
import { updateEmployeeStatutory } from '../../../actions'
import { useSysOptions } from '../../../hooks'

interface EmployeeStatutoryProps {
  employee?: EmployeeState
  onEdit?: () => void
  onSave?: () => void
  onCancel?: () => void
}

const EMPTY_FORM_DATA: IEmployeeStatutory = {
  fundCode: '',
  fundCode2: '',
  fundExcludeDate: ''
}

const cardStyle: CSSProperties = { margin: 24 }
const buttonAddSecondaryStyle: CSSProperties = { marginBottom: 20 }

export const EmployeeStatutory: FC<EmployeeStatutoryProps> = ({
  employee,
  onEdit,
  onSave,
  onCancel
}: EmployeeStatutoryProps) => {
  const { ref, inView } = useFirstInView<HTMLDivElement>({ threshold: 0.25 })
  const [cardState, setCardState] = useState<EditableCardState>()
  const [formData, setFormData] = useState<IEmployeeStatutory>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const [showSecondary, setShowSecondary] = useState(false)
  const [cpfTypes] = useSysOptions('cpf_type')
  const fwlCodes = useSelector(selectKeyvaluesById)('fwlCode')
  const emIdentity = useSelector(
    (state: StoreState) => employee && state.employee.emIdentities[employee.id]?.entities[employee.emIdentityId]
  )
  const [focusRef] = useFocus(cardState === 'editing')
  const canView = usePermissionGate(Permission.employeeStatutory)
  const canModify = usePermissionGate(Permission.employeeStatutory, PermissionAction.Modify)

  useEffect(() => {
    if (inView) {
      dispatch(fetchEmpKeyvalues('fwlCode', 'fwl', null, { strategy: 'when-empty' }))
    }
  }, [inView])

  useEffect(() => {
    if (employee) {
      const { fundCode, fundCode2, fundExcludeDate } = employee
      setFormData({ fundCode, fundCode2, fundExcludeDate })
      setShowSecondary(!!fundCode2)
    } else {
      setFormData(EMPTY_FORM_DATA)
      setShowSecondary(false)
    }
  }, [employee])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => setFormData(formData => ({ ...formData, ...updates })),
    []
  )

  const handleEdit = useCallback(() => {
    setCardState('editing')
    typeof onEdit === 'function' && onEdit()
  }, [onEdit])

  const handleSave = useCallback(async () => {
    if (employee) {
      setCardState('saving')
      setErrors(undefined)
      setShowSecondary(!!formData.fundCode2)

      typeof onSave === 'function' && onSave()

      let result: ActionResult | undefined
      try {
        result = await dispatch(
          updateEmployeeStatutory(employee.id, mapEmployeeStateToEmployeeStatutory(employee), formData)
        )
      } catch {
        setCardState('editing')
      }

      if (result?.errors) {
        setCardState('editing')
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setCardState(undefined)
      }
    }
  }, [employee, formData, onSave])

  const handleCancel = useCallback(() => {
    typeof onCancel === 'function' && onCancel()
    setCardState(undefined)
    setErrors(undefined)

    if (employee) {
      setShowSecondary(!!employee.fundCode2)

      const { fundCode, fundCode2, fundExcludeDate } = employee
      setFormData({ fundCode, fundCode2, fundExcludeDate })
    }
  }, [employee, onCancel])

  const handleAddSecondaryAccount = useCallback(() => {
    setShowSecondary(true)
  }, [])

  if (!canView) return null

  return (
    <div ref={ref}>
      <EditableCard
        title="Statutory information"
        style={cardStyle}
        bodyStyle={{ paddingBottom: employee ? 6 : 24, paddingTop: 6 }}
        state={canModify ? cardState : 'readonly'}
        formId="form-em-statutory"
        onEdit={handleEdit}
        onSave={handleSave}
        onCancel={handleCancel}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              label="Self-help group (SHG) fund"
              validateStatus={errors?.fundCode ? 'error' : ''}
              help={errors?.fundCode}
            >
              <SysOptions
                type="fund"
                value={formData.fundCode}
                readOnly={cardState !== 'editing' && cardState !== 'saving'}
                onChange={(value: string) => handleFormDataChange({ fundCode: value })}
                ref={focusRef}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row hidden={formData.fundCode !== Fund.exclude}>
          <Col span={24}>
            <Form.Item
              label="Until"
              validateStatus={errors?.fundExcludeDate ? 'error' : ''}
              help={errors?.fundExcludeDate}
            >
              <Input.Date
                value={formData.fundExcludeDate ? moment(formData.fundExcludeDate) : undefined}
                inputReadOnly={cardState !== 'editing' && cardState !== 'saving'}
                onChange={(value: moment.Moment | null) =>
                  handleFormDataChange({ fundExcludeDate: value?.format('YYYY-MM-DD') })
                }
              />
            </Form.Item>
          </Col>
        </Row>

        {![Fund.exclude, Fund.notAvailable].includes(formData.fundCode) &&
          (!showSecondary ? (
            cardState === 'editing' && (
              <Button
                type="dashed"
                size="large"
                block
                style={buttonAddSecondaryStyle}
                onClick={handleAddSecondaryAccount}
              >
                add additional SHG fund
              </Button>
            )
          ) : (
            <Row>
              <Col span={24}>
                <Form.Item
                  label="Additional SHG fund"
                  validateStatus={errors?.fundCode2 ? 'error' : ''}
                  help={errors?.fundCode2}
                >
                  <SysOptions
                    type="fund"
                    value={formData.fundCode2}
                    readOnly={cardState !== 'editing' && cardState !== 'saving'}
                    onChange={(value: string) => handleFormDataChange({ fundCode2: value })}
                    onFilter={(value: KeyValue | undefined) => Fund.exclude !== value?.key}
                  />
                </Form.Item>
              </Col>
            </Row>
          ))}

        {!!emIdentity?.cpfType && (
          <Row>
            <Col span={24}>
              <Form.Item
                label="CPF contribution type"
                tooltip={{
                  title: 'To modify, go to personal identification',
                  icon: (
                    <span>
                      <i className="fal fa-circle-info" />
                    </span>
                  )
                }}
              >
                <Input value={cpfTypes[emIdentity.cpfType]?.value} readOnly />
              </Form.Item>
            </Col>
          </Row>
        )}
        {!!emIdentity?.fwlCode && (
          <Row>
            <Col span={24}>
              <Form.Item
                label="FWL category"
                tooltip={{
                  title: 'To modify, go to personal identification',
                  icon: (
                    <span>
                      <i className="fal fa-circle-info" />
                    </span>
                  )
                }}
              >
                <Input value={fwlCodes[emIdentity.fwlCode]?.value} readOnly />
              </Form.Item>
            </Col>
          </Row>
        )}
      </EditableCard>
    </div>
  )
}
