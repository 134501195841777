import { setSlackInstalling } from '../reducers'
import { apiSlackCallback } from '../api/slack.api'
import { ThunkResult } from '~/types/store'
import { showSuccess } from '~/utils'

export const installSlack = (code: string): ThunkResult<void> => {
  return async dispatch => {
    try {
      dispatch(setSlackInstalling(true))
      const { status } = await apiSlackCallback(code)
      if (status) {
        showSuccess('Slack installed')
      }
    } finally {
      dispatch(setSlackInstalling(false))
    }
  }
}
