import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchLeaveRecord } from '../actions'
import { LeaveRecordState } from '../types'

export const useLeaveRecord = (id?: string): [LeaveRecordState | undefined, boolean] => {
  const leaveRecord = useSelector((state: StoreState) => state.leave.leaveRecords?.entities[id || ''])
  const loading = useSelector((state: StoreState) => state.leave.leaveRecordsLoading)

  useEffect(() => {
    if (id) {
      dispatch(fetchLeaveRecord(id))
    }
  }, [id])

  return [leaveRecord, loading]
}
