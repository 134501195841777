import { EntityState } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { FormulaState, FormulaDetailState } from './formula.state'

export const formulaPersistConfig = {
  key: 'formula',
  whitelist: ['formula', 'formulaDetail'],
  storage
}

export interface FormulaRootState {
  formula: EntityState<FormulaState>
  formulaLoading: boolean
  formulaDetail: EntityState<FormulaDetailState>
  formulaDetailLoading: boolean
}
