import { useMemo } from 'react'
import { FeatureFlag, FeatureFlagPermission } from '~/constants'
import { useMyTenant } from './use-my-tenant'

export const useHasFeatureFlagClaim = (sysPermissionId?: string | string[]): boolean => {
  const [myTenant] = useMyTenant()
  const permissionIds = useMemo(
    () =>
      sysPermissionId
        ? Array.isArray(sysPermissionId)
          ? sysPermissionId
          : [sysPermissionId]
        : FeatureFlagPermission.claim,
    [sysPermissionId]
  )
  const toCheck = useMemo(() => permissionIds.every(p => FeatureFlagPermission.claim.includes(p)), [permissionIds])

  if (toCheck) {
    const hasFeatureFlagClaim = myTenant?.featureFlags?.includes(FeatureFlag.Claim) || false
    if (!hasFeatureFlagClaim) return false
  }

  return true
}
