import { showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiUnlockPayRecord } from '../api/payrecord-sg.api'
import { PayRecordSgState } from '../types'
import { setPayRecord } from '../reducers'

export const unlockPayRecord = (payRunId: string, id: string): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const { status, errors, message, errorData } = await apiUnlockPayRecord(id)
    if (status) {
      const payRecord = getState().payroll.payRecords[payRunId]?.entities[id]
      const data: PayRecordSgState = { ...(payRecord as PayRecordSgState), ...{ lockedBy: '', lockedDate: '' } }
      dispatch(setPayRecord({ payRunId, data }))
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
