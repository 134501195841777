import React, { FC, useMemo } from 'react'
import moment from 'moment-timezone'
import { ColumnsType, Form, Table, Text } from '~/core-components'
import { Col, Row } from '~/components'
import { EmPublicPerson } from '~/features/employee'
import { ISaveTimeLog, TimeLogRequestData } from '~/features/attendance'
import { SSTimeLogTaskState } from '../../../types'
import './MyTimeLogTaskForm.less'

interface MyTimeLogTaskFormProps {
  data: SSTimeLogTaskState | undefined
  loading: boolean
}

export const MyTimeLogTaskForm: FC<MyTimeLogTaskFormProps> = ({ data, loading }) => {
  const columns: ColumnsType<TimeLogRequestData<ISaveTimeLog>> = useMemo(
    () => [
      {
        key: 'label',
        dataIndex: 'label',
        width: 150
      },
      {
        title: 'Current',
        key: 'current',
        dataIndex: 'current',
        render: (value: string, record: TimeLogRequestData<ISaveTimeLog>) =>
          value ? (
            record.field === 'timeOutRequest' ? (
              <>
                {record.currentTimeIn && moment(value).diff(moment(record.currentTimeIn), 'day') !== 0 && (
                  <Text size="small">{moment(value).format('DD MMM YYYY')}</Text>
                )}
                <div>{moment(value).format('HH:mm')}</div>
              </>
            ) : (
              moment(value).format('HH:mm')
            )
          ) : null
      },
      {
        align: 'center',
        render: () => <i className="fa-light fa-arrow-right-long" style={{ color: '#3caef2' }} />
      },
      {
        title: 'Change to',
        key: 'request',
        dataIndex: 'request',
        render: (value: string, record: TimeLogRequestData<ISaveTimeLog>) =>
          record.field === 'timeOutRequest' ? (
            <>
              {record.requestTimeIn && moment(value).diff(moment(record.requestTimeIn), 'day') !== 0 && (
                <Text size="small">{moment(value).format('DD MMM YYYY')}</Text>
              )}
              <div>{moment(value).format('HH:mm')}</div>
            </>
          ) : (
            moment(value).format('HH:mm')
          )
      }
    ],
    []
  )

  const requestData: TimeLogRequestData<ISaveTimeLog>[] = useMemo(() => {
    let result: TimeLogRequestData<ISaveTimeLog>[] = [
      {
        field: 'timeInRequest',
        label: 'Time in',
        current: data?.timeIn,
        request: data?.timeInRequest ?? data?.timeIn
      }
    ]

    if (data?.timeOutRequest) {
      result.push({
        field: 'timeOutRequest',
        label: 'Time out',
        current: data?.timeOut,
        request: data?.timeOutRequest ?? data?.timeOut,
        currentTimeIn: data?.timeIn,
        requestTimeIn: data?.timeInRequest
      })
    }

    return result
  }, [data])

  return (
    <div className="my-time-log-task-form">
      {!data && !loading && <div className="my-time-log-task-form__empty">Not found</div>}
      {data && (
        <Form>
          <Row>
            <Col span={16}>
              <Form.Item>
                <EmPublicPerson id={data.employeeId} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col flex="200px">
              <Form.Item label="Date">{moment(data.timeIn).format('DD MMM YYYY')}</Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item label="Location">{data.locationName}</Form.Item>
            </Col>
          </Row>
          <Row hidden={!data.projectName}>
            <Col span={24}>
              <Form.Item label="Project">{data.projectName}</Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item label="Notes">{data.notes || '-'}</Form.Item>
            </Col>
          </Row>
          <Table rowKey="field" dataSource={requestData} columns={columns} pagination={false} />
        </Form>
      )}
    </div>
  )
}
