import React, { FC, useCallback, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { RangeValue } from 'rc-picker/lib/interface.d'
import { Checkbox, CheckboxChangeEvent, Form, Input, Radio, RadioChangeEvent } from '~/core-components'
import { Col, DrawerForm, Row, SelectEmployees } from '~/components'
import { Pagination } from '~/types/common'
import { dispatch } from '~/stores/store'
import { ActionResult } from '~/types/store'
import { fetchScheduleRecordEmSelections, initialiseScheduleRecords } from '../../../actions'
import { IInitialiseScheduleRecord } from '../../../types'

interface InitialiseScheduleRecordDrawerProps {
  visible: boolean
  startDate?: string
  endDate?: string
  employeeIds?: string[]
  overwrite?: boolean
  onClose: (action: 'initialised' | 'cancelled') => void
}

interface FormData extends IInitialiseScheduleRecord {}

const EMPTY_FORM_DATA: FormData = {
  startDate: '',
  endDate: '',
  employeeIds: [],
  overwrite: false
}

export const InitialiseScheduleRecordDrawer: FC<InitialiseScheduleRecordDrawerProps> = ({
  visible,
  startDate = '',
  endDate = '',
  employeeIds = [],
  overwrite = false,
  onClose
}) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)
  const [isAllEmployees, setIsAllEmployees] = useState(true)

  useEffect(() => {
    if (visible) {
      setFormData({ startDate, endDate, employeeIds, overwrite })
    }
  }, [visible, startDate, endDate, employeeIds, overwrite])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      result = await dispatch(initialiseScheduleRecords(formData))
    } finally {
      setLoading(false)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose('initialised')
    }
  }, [formData, onClose])

  const handleFetchEmployees = useCallback(
    (viewId: string, pagination: Pagination, search: string) => {
      if (visible) {
        dispatch(fetchScheduleRecordEmSelections(formData.startDate, formData.endDate, viewId, pagination, search))
      }
    },
    [visible, formData]
  )

  const handleIsAllEmployeesChange = useCallback((event: RadioChangeEvent) => {
    setIsAllEmployees(event.target.value)
  }, [])

  const handleSelectEmployees = useCallback(
    (employeeIds: string[]) => {
      handleFormDataChange({ employeeIds })
    },
    [handleFormDataChange]
  )

  return (
    <DrawerForm
      open={visible}
      title="Initialise from calendar"
      okText="Initialise"
      onOk={handleOk}
      onClose={() => onClose('cancelled')}
      confirmLoading={loading}
      width={520}
      formId="form-initialise-schedule-record"
      className="initialise-schedule-record-drawer"
      extras={
        <Checkbox
          checked={formData.overwrite}
          onChange={(event: CheckboxChangeEvent) => handleFormDataChange({ overwrite: event.target.checked })}
        >
          Overwrite existing shift with default calendar
        </Checkbox>
      }
    >
      <Form id="form-initialise-schedule-record" onFinish={handleOk}>
        <Row>
          <Col span={24}>
            <Form.Item label="Date range">
              <Input.DateRange
                allowClear={false}
                value={[
                  formData.startDate ? moment(formData.startDate) : null,
                  formData.endDate ? moment(formData.endDate) : null
                ]}
                onCalendarChange={(dates: RangeValue<moment.Moment>) => {
                  const startDate = dates && dates[0] ? dates[0].format('YYYY-MM-DD') : null
                  const endDate = dates && dates[1] ? dates[1].format('YYYY-MM-DD') : null
                  handleFormDataChange({ startDate, endDate })
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Radio.Group value={isAllEmployees} onChange={handleIsAllEmployeesChange}>
              <Radio value={true}>All employees</Radio>
              <Radio value={false}>Specific employees</Radio>
            </Radio.Group>
          </Col>
          <Col span={24} hidden={isAllEmployees}>
            <SelectEmployees
              viewName="schedule_record_initialise"
              employeeIds={formData.employeeIds || []}
              onFetchData={handleFetchEmployees}
              onSelect={handleSelectEmployees}
            />
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}
