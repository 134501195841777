import React, { CSSProperties, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { SortableContainer, SortableContainerProps, SortableElement, SortableHandle, SortEnd } from 'react-sortable-hoc'
import { Button, Card, ColumnsType, Link, Table } from '~/core-components'
import { usePermissionGate } from '~/features/iam'
import { Permission, PermissionAction } from '~/constants'
import { arrayMove, isInactive } from '~/utils'
import { dispatch } from '~/stores/store'
import { sortDialyPolicyRows } from '../../actions'
import { useDailyPolicyRows, useSysDailyPolicyFieldsDict } from '../../hooks'
import { DailyPolicyRowState } from '../../types'
import { MutateDailyPolicyRowDrawer } from './components/MutateDailyPolicyRowDrawer'
import './DailyPolicyRows.less'

interface DrawerState {
  visible: boolean
  section: string
  dailyPolicyId: string
  data?: DailyPolicyRowState
}
const DEFAULT_DRAWER_STATE: DrawerState = { visible: false, section: '', dailyPolicyId: '' }

export type DailyPolicyRowMode = 'default' | 'sort'

interface DailyPolicyRowsProps {
  section: string
  dailyPolicyId: string
  mode?: DailyPolicyRowMode
}

const SortableItem = SortableElement((props: React.HTMLAttributes<HTMLTableRowElement>) => <tr {...props} />)
const SortableBody = SortableContainer((props: React.HTMLAttributes<HTMLTableSectionElement>) => <tbody {...props} />)

const dragHandleStyle: CSSProperties = { cursor: 'pointer', color: '#999' }
const DragHandle = SortableHandle(() => <i className="fal fa-grip-dots-vertical" style={dragHandleStyle} />)

const marginStyle: CSSProperties = { margin: 12 }

export const DailyPolicyRows: FC<DailyPolicyRowsProps> = ({ section, dailyPolicyId, mode = 'default' }) => {
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const [rows, loading] = useDailyPolicyRows(dailyPolicyId, section)
  const [data, setData] = useState<DailyPolicyRowState[]>([])
  // const [sortable, setSortable] = useState(false)
  const sortable = mode === 'sort'
  const canModify = usePermissionGate(Permission.dailyPolicies, PermissionAction.Modify)

  const [policyFields] = useSysDailyPolicyFieldsDict()

  useEffect(() => {
    setData(rows)
  }, [rows])

  const handleAddRow = useCallback(() => {
    setDrawerState({ visible: true, section, dailyPolicyId, data: undefined })
  }, [section, dailyPolicyId])

  const handleEditRow = useCallback(
    (row: DailyPolicyRowState) => {
      setDrawerState({ visible: true, section, dailyPolicyId, data: row })
    },
    [section, dailyPolicyId]
  )

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const columns = useMemo(() => {
    let columns: ColumnsType<DailyPolicyRowState> = []

    if (sortable) {
      columns.push({
        dataIndex: 'sort',
        width: 30,
        className: 'drag-visible',
        render: () => <DragHandle />
      })
    }

    columns.push(
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name',
        className: 'drag-visible'
      },
      {
        title: 'Formula',
        key: 'fieldName',
        dataIndex: 'fieldName',
        render: (value: string, record) => (
          <div
            style={{
              font: `12px/normal 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'Source Code Pro', 'source-code-pro', monospace`
            }}
          >
            [{policyFields[value]?.description}] = {record.expression}
          </div>
        )
      },
      {
        title: 'Criteria',
        key: 'criteriaExpr',
        dataIndex: 'criteriaExpr',
        render: (value: string) => (
          <div
            style={{
              font: `12px/normal 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'Source Code Pro', 'source-code-pro', monospace`
            }}
          >
            {value}
          </div>
        )
      }
    )

    if (!sortable) {
      columns.push({
        key: 'action',
        align: 'right',
        className: 'action-col',
        width: 70,
        render: (value: string, record) =>
          canModify && (
            <Link size="small" onClick={() => handleEditRow(record)}>
              edit
            </Link>
          )
      })
    }

    return columns
  }, [sortable, canModify, policyFields, handleEditRow])

  const handleSortEnd = useCallback(
    ({ oldIndex, newIndex }: SortEnd) => {
      if (oldIndex !== newIndex) {
        const sorted = arrayMove<DailyPolicyRowState>(data, oldIndex, newIndex).map((x, index) => ({
          ...x,
          sequence: index
        }))
        setData(sorted)

        dispatch(
          sortDialyPolicyRows(
            dailyPolicyId,
            sorted.map(({ id, sequence }) => ({ id, sequence }))
          )
        )
      }
    },
    [dailyPolicyId, data]
  )

  const DraggableContainer: FC<SortableContainerProps> = props => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="daily-policy-rows__row-dragging"
      onSortEnd={handleSortEnd}
      {...props}
    />
  )

  const DraggableBodyRow: FC<any> = ({ className, style, ...restProps }) => {
    const index = data.findIndex(x => x.id === (restProps['data-row-key'] as string))
    return <SortableItem index={index} {...restProps} />
  }

  return (
    <div className="daily-policy-rows">
      <div className="daily-policy-rows__body">
        <Card fitParent table>
          <Table
            rowKey="id"
            dataSource={data}
            pagination={false}
            columns={columns}
            loading={loading}
            fitParent
            rowClassName={record => (isInactive(record.inactiveDate) ? 'daily-policy-rows__table-row--inactive' : '')}
            components={
              sortable
                ? {
                    body: {
                      wrapper: DraggableContainer,
                      row: DraggableBodyRow
                    }
                  }
                : undefined
            }
          />
          {canModify && (
            <div style={marginStyle}>
              <Button type="dashed" block onClick={handleAddRow}>
                Add policy item
              </Button>
            </div>
          )}
        </Card>
      </div>
      {canModify && <MutateDailyPolicyRowDrawer {...drawerState} onClose={handleCloseDrawer} />}
    </div>
  )
}
