import { replaceRules, setRulesLoading } from '../reducers'
import { apiGetRules } from '../api/rule.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'
import { mapRuleToRuleState } from '../types'

export const fetchRules = (referenceId: string, options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!referenceId) return

    const loading = getState().rule.rulesLoading[referenceId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().rule.rules[referenceId]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setRulesLoading({ referenceId, loading: true }))
      const { result, status } = await apiGetRules(referenceId)
      if (status) {
        const data = result.map(d => mapRuleToRuleState(d))
        dispatch(replaceRules({ referenceId, data }))
      }
    } finally {
      dispatch(setRulesLoading({ referenceId, loading: false }))
    }
  }
}
