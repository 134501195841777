import { replacePayRuns, setPayRunLoading } from '../reducers'
import { apiGetPayRuns } from '../api/payrun.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchPayRuns = (options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().payroll.payRunsLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().payroll.payRuns?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setPayRunLoading(true))
      const { result, status } = await apiGetPayRuns()
      if (status) {
        dispatch(replacePayRuns(result))
      }
    } finally {
      dispatch(setPayRunLoading(false))
    }
  }
}
