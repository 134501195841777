import { apiAddEmAddress } from '../api/em-address.api'
import { ActionResult, ThunkResult } from '~/types/store'
import { formatAddress, IAddress } from '~/features/master'
import { emptyGuid } from '~/constants'
import { showSuccess, showError } from '~/utils'
import { replaceEmAddresses, setEmAddress, setEmployee } from '../reducers'
import { IEmAddressInfo, EmAddressState } from '../types'

export const addEmAddress = (employeeId: string, emAddress: IEmAddressInfo): ThunkResult<void> => {
  return async (dispatch, getState): Promise<ActionResult | undefined> => {
    if (!employeeId) return

    const { status, result, errors, message, errorData } = await apiAddEmAddress(employeeId, {
      id: emptyGuid,
      employeeId,
      ...emAddress
    })
    if (status) {
      if (result.emAddressId) {
        const employee = getState().employee.employees.entities[employeeId]
        if (employee) {
          dispatch(setEmployee({ ...employee, emAddressId: result.emAddressId }))
        }
      }

      const countries = getState().master.keyvalues['country'].entities
      const countryName = countries[emAddress.countryCode || '']?.value || ''
      const newEmAddress = {
        id: result.id,
        employeeId,
        ...emAddress,
        ...formatAddress(
          {
            block: emAddress.block,
            street: emAddress.street,
            level: emAddress.level,
            unit: emAddress.unit,
            building: '',
            city: emAddress.city,
            state: emAddress.state,
            postalCode: emAddress.postalCode,
            countryCode: emAddress.countryCode
          } as IAddress,
          countryName
        )
      } as EmAddressState

      if (newEmAddress.isMain) {
        const emAddresses = Object.values(getState().employee.emAddresses[employeeId]?.entities || {}).map(
          a => ({ ...a, isMain: false } as EmAddressState)
        )
        emAddresses.push(newEmAddress)
        dispatch(replaceEmAddresses({ employeeId, data: emAddresses }))
      } else {
        dispatch(setEmAddress({ employeeId, data: newEmAddress }))
      }

      showSuccess('Added')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
