import React, { FC, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { Avatar, Button, UploadChangeParam } from '~/core-components'
import { Col, ImageAuth, Row, UploadAuth } from '~/components'
import { MyTenantState } from '~/features/iam'
import { replaceMyTenantPhotoId } from '~/features/iam/reducers'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { getBaseUrl, getInitials } from '~/utils'
import './TenantAvatar.less'

interface TenantAvatarProps {
  tenant?: MyTenantState
  editable?: boolean
  size?: number
}

const baseUrl = getBaseUrl('/host')

export const TenantAvatar: FC<TenantAvatarProps> = ({ tenant, editable = false, size = 36 }) => {
  const [uploading, setUploading] = useState(false)
  const myTenantPhotoId = useSelector((state: StoreState) => state.iam.myTenantPhotoId)
  const tenantCode = tenant?.tenantCode || ''
  const thumbnailPhoto = tenantCode
    ? `${baseUrl}/tenant/${tenantCode}/logo/thumbnail/${size}${myTenantPhotoId ? `?id=${myTenantPhotoId}` : ''}`
    : undefined

  const handleUploadChange = useCallback((info: UploadChangeParam) => {
    setUploading(info.file.status === 'uploading')

    if (info.file.status === 'done') {
      if (info.file?.response?.id) {
        dispatch(replaceMyTenantPhotoId(info.file.response.id))
      }
    }
  }, [])

  if (!tenant) return null

  return (
    <div className="tenant-avatar">
      <Row gutter={[30, 24]}>
        <Col span={24}>
          {thumbnailPhoto ? (
            <Avatar
              size={size}
              shape="square"
              src={
                <ImageAuth
                  src={thumbnailPhoto}
                  width={size}
                  preview={false}
                  defaultImage={
                    <Avatar size={size} shape="square">
                      {getInitials(tenant.tenantName, 'tenant')}
                    </Avatar>
                  }
                />
              }
            />
          ) : (
            <Avatar size={size} shape="square" />
          )}
        </Col>
        <Col span={24}>
          <UploadAuth
            className="tenant-avatar__uploader"
            action={tenantCode ? `${baseUrl}/tenant/${tenantCode}/logo` : undefined}
            showUploadList={false}
            onChange={handleUploadChange}
          >
            <Button type="primary" loading={uploading}>
              Upload
            </Button>
          </UploadAuth>
        </Col>
      </Row>
    </div>
  )
}
