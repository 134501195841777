import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { SSCalendarViewerState } from '../../types'

export const selectMyCalendarViewers = createSelector(
  (state: StoreState) => state.my.myCalendarViewers,
  myCalendarViewersState => {
    return Object.values(myCalendarViewersState?.entities || {}) as SSCalendarViewerState[]
  }
)
