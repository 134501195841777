import { showError, showSuccess } from '~/utils'
import { ThunkResult } from '~/types/store'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { apiUpdateShiftDay } from '../api/shift.api'
import { ShiftDayInfoState, ShiftDayState } from '../types'
import { setShiftDay, setShiftDayAggregated } from '../reducers'

export const updateShiftDay = (
  shiftId: string,
  id: string,
  before: ShiftDayInfoState,
  request: ShiftDayInfoState
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!shiftId || !id) return

    const after = { ...before, ...request } as ShiftDayInfoState
    const patch = generateJsonPatch<ShiftDayInfoState>(before, after)
    const { status, errors, message, errorData } = await apiUpdateShiftDay(shiftId, id, patch)
    if (status) {
      const shift = getState().attendance.shiftDays[shiftId]?.entities[id]
      if (shift) {
        const updated: ShiftDayState = {
          ...(shift as ShiftDayState),
          ...after
        }
        dispatch(setShiftDay({ shiftId, data: updated }))
      }

      const shift2 = getState().attendance.shiftDaysAggregated[shiftId]?.entities[after.dayCode]
      if (shift2) {
        const updated2: ShiftDayState = {
          ...(shift2 as ShiftDayState),
          ...after,
          id: shift2.id,
          dayCode: shift2.dayCode
        }
        dispatch(setShiftDayAggregated({ shiftId, data: updated2 }))
      }

      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
