import { request, getBaseUrl } from '~/utils/request'
import { SSClaimRecordCommentState } from '../types'

const baseUrl = getBaseUrl('/claim/ssclaimrecordcomment')

export const apiGetMyClaimRecordComments = (id: string) =>
  request<SSClaimRecordCommentState[]>('get', `${baseUrl}/${id}/comment`)

export const apiAddMyClaimRecordComment = (id: string, comment: string) =>
  request<{ id: string; createdDate: string }>('post', `${baseUrl}/${id}/comment`, { comment })

export const apiUpdateMyClaimRecordComment = (claimRecordId: string, id: string, comment: string) =>
  request<void>('post', `${baseUrl}/${claimRecordId}/comment/${id}`, { comment })

export const apiDeleteMyClaimRecordComment = (claimRecordId: string, id: string) =>
  request<void>('delete', `${baseUrl}/${claimRecordId}/comment/${id}`)
