import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { LoadingOutlined } from '@ant-design/icons'
import { Button, PageHeader, Space, Spin, Tooltip } from '~/core-components'
import { DocumentTitle } from '~/components'
import { HELP_ROUTES, SETTINGS_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { disconnectXeroConnection, fetchXeroConnections, initiateXeroAuthentication } from '../../actions'
import { useXeroConnections } from '../../hooks'
import { selectXeroFirstTenantMapped } from '../../selectors'
import { XeroDisconnectDrawer } from './XeroDisconnectDrawer'
import { XeroTenantMap } from './XeroTenantMap'
import { XeroLineItemMap } from './XeroLineItemMap'
import { XeroSelectTenantMap } from './XeroSelectTenantMap'
import { XeroConfig } from './XeroConfig'
import './Xero.less'

interface XeroProps {}

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: '',
    breadcrumbName: 'Xero integration'
  }
]

export const Xero: FC<XeroProps> = () => {
  const [connections, loading] = useXeroConnections()
  const [connecting, setConnecting] = useState(false)
  const [disconnecting, setDisconnecting] = useState(false)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [tenantMapId, setTenantMapId] = useState<string>()
  const tenantMapped = useSelector(selectXeroFirstTenantMapped)

  useEffect(() => {
    dispatch(fetchXeroConnections({ strategy: 'always' }))
  }, [])

  useEffect(() => {
    setTenantMapId(tenantMapped?.id)
  }, [tenantMapped])

  const handleConnect = useCallback(async () => {
    try {
      setConnecting(true)
      await dispatch(initiateXeroAuthentication())
    } finally {
      setConnecting(false)
    }
  }, [])

  const handleDisconnect = useCallback(async () => {
    try {
      setDisconnecting(true)
      if (connections.length === 1) {
        await dispatch(disconnectXeroConnection(connections[0].id))
      } else {
        setDrawerVisible(true)
      }
    } finally {
      setDisconnecting(false)
    }
  }, [connections])

  const handleDrawerClose = useCallback(() => {
    setDrawerVisible(false)
  }, [])

  const handleHelpClick = useCallback(() => {
    window.open(HELP_ROUTES.page.replace(':page', 'xero'), '_blank')
  }, [])

  return (
    <div id="xero" className="xero">
      <DocumentTitle title="Xero Integration" />
      <PageHeader
        title={
          <Space>
            <img alt="Xero" src="/partners/xero.png" width="32" />
            Xero integration
          </Space>
        }
        containerId="xero"
        breadcrumb={{ routes }}
        extra={
          <Space>
            <Button type="primary" loading={connecting} onClick={handleConnect}>
              Connect to Xero
            </Button>
            <Button
              loading={disconnecting}
              onClick={handleDisconnect}
              disabled={connections == null || connections.length === 0}
            >
              Disconnect from Xero
            </Button>
            <Tooltip title="Guide to configure and send payroll data to Xero" placement="bottomRight">
              <Button icon={<i className="fal fa-circle-question" />} onClick={handleHelpClick} />
            </Tooltip>
          </Space>
        }
      />
      <div className="xero__body">
        {connections.length > 0 ? (
          <>
            <XeroTenantMap />
            <XeroSelectTenantMap value={tenantMapId} onChange={tenantMapId => setTenantMapId(tenantMapId)} />
            <XeroConfig tenantMapId={tenantMapId} />
            <XeroLineItemMap tenantMapId={tenantMapId} />
          </>
        ) : (
          loading && <Spin indicator={<LoadingOutlined spin />} />
        )}
      </div>
      <XeroDisconnectDrawer open={drawerVisible} onClose={handleDrawerClose} />
    </div>
  )
}
