import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiDeleteCurrencyExchange } from '../api/currency-exchange.api'

export const deleteCurrencyExchange = (id: string): ThunkResult<void> => {
  return async () => {
    if (!id) return

    const { status, errors, message, errorData } = await apiDeleteCurrencyExchange(id)
    if (status) {
      showSuccess('Deleted')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
