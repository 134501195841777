import { replaceLeEarned, setLeEarnedLoading } from '../reducers'
import { apiGetLeEarned } from '../api/leave-entitlement.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchLeEarned = (
  periodCode?: string,
  employeeId?: string,
  leaveTypeId?: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!periodCode || !employeeId || !leaveTypeId) return

    const entId = `${periodCode}|${employeeId}|${leaveTypeId}`

    const loading = getState().leave.leEarnedLoading[entId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().leave.leEarned[entId]
      if (hasData) return
    }

    try {
      dispatch(setLeEarnedLoading({ entId, loading: true }))
      const { result, status } = await apiGetLeEarned(periodCode, employeeId, leaveTypeId)
      if (status) {
        dispatch(replaceLeEarned({ entId, data: result }))
      }
    } finally {
      dispatch(setLeEarnedLoading({ entId, loading: false }))
    }
  }
}
