import { showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { replaceLeaveGrantBulkVerify } from '../reducers'
import { ILeaveGrantBulkVerify } from '../types'
import { apiVerifyLeaveGrantBulk } from '../api/leave-grant-bulk.api'

export const verifyLeaveGrantBulk = (leaveGrantBulkVerify: ILeaveGrantBulkVerify): ThunkResult<void> => {
  return async dispatch => {
    const { status, result, errors, message, errorData } = await apiVerifyLeaveGrantBulk(leaveGrantBulkVerify)
    if (status) {
      dispatch(replaceLeaveGrantBulkVerify(result))
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors, result }
  }
}
