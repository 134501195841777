import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { EmSectionState, IEmSection } from '../types'

const baseUrl = getBaseUrl('/employee/employee')

export const apiGetEmSections = (employeeId: string) =>
  request<EmSectionState[]>('get', `${baseUrl}/${employeeId}/section`)

export const apiGetEmSection = (employeeId: string, emSectionId: string) =>
  request<EmSectionState>('get', `${baseUrl}/${employeeId}/section/${emSectionId}`)

export const apiAddEmSection = (employeeId: string, emSection: IEmSection) =>
  request<{ id: string; emSectionId?: string }>('post', `${baseUrl}/${employeeId}/section`, emSection)

export const apiUpdateEmSection = (employeeId: string, id: string, emSection: Operation[]) =>
  request<{ emSectionId?: string }>('patch', `${baseUrl}/${employeeId}/section/${id}`, emSection)

export const apiDeleteEmSection = (employeeId: string, id: string) =>
  request<{ emSectionId?: string }>('delete', `${baseUrl}/${employeeId}/section/${id}`)
