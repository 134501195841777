import React, { CSSProperties, FC, useCallback } from 'react'
import moment from 'moment-timezone'
import { Form, Input, Space } from '~/core-components'

interface MonthlyCronProps {
  expr: string[]
  onChange: (value: string[]) => void
}

const numberStyles: CSSProperties = { width: 60 }

export const MonthlyCron: FC<MonthlyCronProps> = ({ expr, onChange }) => {
  const day = expr[2] && !isNaN(parseInt(expr[2])) ? parseInt(expr[2]) : 1
  const month = expr[3].split('/')[1] ? parseInt(expr[3].split('/')[1]) : 1
  const timeHHMM = `${expr[1]}:${expr[0]}`

  const handleDayChange = useCallback(
    (value: number | null) => {
      if ((typeof value === 'number' && value > 0 && value < 32) || value == null) {
        const newExpr = [...expr]
        newExpr[2] = `${value}`
        onChange(newExpr)
      }
    },
    [onChange, expr]
  )

  const handleMonthChange = useCallback(
    (value: number | null) => {
      if ((typeof value === 'number' && value > 0 && value < 12) || value == null) {
        const newExpr = [...expr]
        newExpr[3] = value ? `*/${value}` : `${value}`
        onChange(newExpr)
      }
    },
    [onChange, expr]
  )

  const handleTimeChange = useCallback(
    (value: moment.Moment | null) => {
      const time = (value?.format('HH:mm') || '00:00').split(':')
      const newExpr = [...expr]
      newExpr[0] = `${parseInt(time[1])}`
      newExpr[1] = `${parseInt(time[0])}`
      onChange(newExpr)
    },
    [onChange, expr]
  )

  return (
    <Form.Item>
      <Space direction="vertical">
        <Space>
          Day
          <Input.Number min={1} max={31} value={day} style={numberStyles} onChange={handleDayChange} />
          of every
          <Input.Number min={1} max={11} value={month} style={numberStyles} onChange={handleMonthChange} />
          month(s)
        </Space>
        <Space>
          Starts at
          <Input.Time
            value={moment(timeHHMM, 'HH:mm')}
            onChange={handleTimeChange}
            allowClear={false}
            showNow={false}
            minuteStep={5}
          />
        </Space>
      </Space>
    </Form.Item>
  )
}
