import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { ShiftDayState } from '../types'

export const selectShiftDaysAggregated = createSelector(
  (state: StoreState) => state.attendance.shiftDaysAggregated,
  (state: StoreState) => state.master.keyvalues['attShiftDayCode'],
  (shiftDaysState, shiftDayCodes) =>
    memoize((shiftId?: string): ShiftDayState[] => {
      if (!shiftId) return []

      const shiftDays = Object.values(shiftDaysState[shiftId]?.entities || {}) as ShiftDayState[]
      return shiftDays.sort((a, b) => {
        const aDayCodes: string[] = Array.isArray(a.dayCode) ? a.dayCode : [a.dayCode]
        const aSequence = aDayCodes.reduce<number>((acc: number, current: string) => {
          const seq = shiftDayCodes?.entities[current]?.sequence || 0
          return seq < acc ? seq : acc
        }, 99)

        const bDayCodes: string[] = Array.isArray(b.dayCode) ? b.dayCode : [b.dayCode]
        const bSequence = bDayCodes.reduce<number>((acc: number, current: string) => {
          const seq = shiftDayCodes?.entities[current]?.sequence || 0
          return seq < acc ? seq : acc
        }, 99)

        return aSequence - bSequence
      })
    })
)
