import React, { FC, useCallback, useEffect, useState } from 'react'
import { Select } from '~/core-components'
import { useYtdIndexYears } from '../../../hooks'

interface SelectYtdIndexYearsProps {
  onChange: (ytdYear: string) => void
}

export const SelectYtdIndexYears: FC<SelectYtdIndexYearsProps> = ({ onChange }) => {
  const [ytdYear, setYtdYear] = useState<string>()
  const [ytdIndexYears] = useYtdIndexYears()
  const defaultYtdIndexYear = ytdIndexYears.length > 0 ? ytdIndexYears[0] : undefined

  useEffect(() => {
    if (defaultYtdIndexYear) {
      setYtdYear(defaultYtdIndexYear)
    }
  }, [defaultYtdIndexYear])

  useEffect(() => {
    if (ytdYear) {
      typeof onChange === 'function' && onChange(ytdYear)
    }
  }, [ytdYear, onChange])

  const handleChange = useCallback(ytdYear => {
    setYtdYear(ytdYear)
  }, [])

  return (
    <Select
      className="year-input"
      allowClear={false}
      dropdownMatchSelectWidth={false}
      value={ytdYear}
      onChange={handleChange}
      optionFilterProp="title"
    >
      {ytdIndexYears.map(y => (
        <Select.Option key={y} value={y} title={y}>
          {y}
        </Select.Option>
      ))}
    </Select>
  )
}
