import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiUpdatePayRecurringOverride } from '../api/payrecurring.api'
import { PayRecurringOverrideState, IPayRecurringOverrideInfo } from '../types'
import { setPayRecurringOverride } from '../reducers'

export const updatePayRecurringOverride = (
  id: string,
  payTranBatchId: string,
  request: IPayRecurringOverrideInfo
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const override = getState().payroll.payRecurringOverrides.entities[id]
    const payRunId = override?.payRunId || ''
    const employeeId = override?.employeeId || ''
    const payItemId = override?.payItemId || ''

    if (!payRunId || !employeeId || !payItemId) return

    let update: PayRecurringOverrideState = { id, payRunId, employeeId, payTranBatchId, ...request }
    const { result, status, errors, message, errorData } = await apiUpdatePayRecurringOverride(
      payRunId,
      employeeId,
      payItemId,
      payTranBatchId,
      update
    )

    if (status) {
      if (result.id) {
        update = { ...update, id: result.id }
      }
      dispatch(setPayRecurringOverride(update))
      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
