import React from 'react'
import { Dictionary } from '@reduxjs/toolkit'
import { StoreState, ThunkResult } from '~/types/store'
import { ColumnsType } from '~/core-components'
import { EmPreview, InactiveTag, Person } from '~/components'
import {
  selectCalendarKeyvalues,
  selectCalendarKeyvaluesLoading,
  selectCalendarsOverview,
  selectCostCentreKeyvalues,
  selectCostCentreKeyvaluesLoading,
  selectDepartmentKeyvalues,
  selectDepartmentKeyvaluesLoading,
  selectDoctypeKeyvalues,
  selectDoctypeKeyvaluesLoading,
  selectEmploymentTypeKeyvalues,
  selectEmploymentTypeKeyvaluesLoading,
  selectHolidayKeyvalues,
  selectHolidayKeyvaluesLoading,
  selectJobKeyvalues,
  selectJobKeyvaluesLoading,
  selectLeaveGroupKeyvalues,
  selectLeaveGroupKeyvaluesLoading,
  selectPayGroupKeyvalues,
  selectPayGroupKeyvaluesLoading,
  selectRaceKeyvalues,
  selectRaceKeyvaluesLoading,
  selectReligionKeyvalues,
  selectReligionKeyvaluesLoading,
  selectReasonKeyvalues,
  selectReasonKeyvaluesLoading,
  selectTeamKeyvalues,
  selectTeamKeyvaluesLoading,
  selectDepartmentsOverview,
  selectTeamsOverview,
  selectQualificationKeyvalues,
  selectQualificationKeyvaluesLoading,
  DivisionOverview,
  selectDivisionsOverview,
  selectDivisionKeyvalues,
  selectDivisionKeyvaluesLoading,
  selectCategoryKeyvalues,
  selectCategoryKeyvaluesLoading,
  selectGrpKeyvalues,
  selectGrpKeyvaluesLoading,
  selectSectionsOverview,
  selectSectionKeyvalues,
  selectSectionKeyvaluesLoading,
  SectionOverview,
  selectSalaryGradeKeyvalues,
  selectSalaryGradeKeyvaluesLoading
} from '../../selectors'
import { CalendarWeekState, MasterName, MasterState } from '../../types'
import { DepartmentOverview } from '../../selectors/select-departments-overview'
import { TeamOverview } from '../../selectors/select-teams-overview'
import { FetchOptions, KeyValue } from '~/types/common'
import { deleteCalendar, fetchCalendars } from '../../actions'
import { MutateCalendarDrawer } from './components/MutateCalendarDrawer'
import { MutateCostCentreDrawer } from './components/MutateCostCentreDrawer'
import { MutateDepartmentDrawer } from './components/MutateDepartmentDrawer'
import { MutateTeamDrawer } from './components/MutateTeamDrawer'
import { MutateQualificationDrawer } from './components/MutateQualificationDrawer'
import { isInactive } from '~/utils/dateUtil'
import { MutateDivisionDrawer } from './components/MutateDivisionDrawer'
import { MutateSectionDrawer } from './components/MutateSectionDrawer'
import { MutateSalaryGradeDrawer } from './components/MutateSalaryGradeDrawer'
import { getBaseUrl } from '~/utils'
import { DowWorkStatus } from './components/DowWorkStatus'
import { WorkStatusType } from '~/constants'
import { apiGetMasterEmployees } from '../../api/master.api'

interface MasterConfigsProps {
  [masterName: string]: {
    title: string
    documentTitle?: string
    name: string
    columns?: ColumnsType<MasterState>
    selector?: (state: StoreState) => (MasterState | undefined)[]
    keyvaluesSelector: (state: StoreState) => Dictionary<KeyValue>
    keyvaluesLoadingSelector: (state: StoreState) => boolean | undefined
    drawer?: React.ComponentType<any>
    fetchData?: (masterName: MasterName, options: FetchOptions) => ThunkResult<void>
    deleteData?: (masterName: MasterName, id: string) => ThunkResult<void>
  }
}

const baseUrl = getBaseUrl('/filestore')

const handleFetchMasterEmployees = async (masterName: MasterName, masterId: string) => {
  const { status, result } = await apiGetMasterEmployees(masterName, masterId)
  if (status) {
    return result
  }
  return []
}

export const MasterConfigs: MasterConfigsProps = {
  calendar: {
    title: 'Work calendars',
    documentTitle: 'Work Calendars',
    name: 'Work calendar',
    columns: [
      {
        title: 'Calendar',
        key: 'name',
        dataIndex: 'name',
        width: 300,
        render: (value: string, record) => (
          <>
            {value} {isInactive(record.inactiveDate) && <InactiveTag />}
          </>
        )
      },
      {
        title: <DowWorkStatus category="label" />,
        key: 'weeks',
        dataIndex: 'weeks',
        width: 300,
        render: (value: CalendarWeekState[]) => {
          return value.map((w, index) => (
            <DowWorkStatus
              key={index}
              category="status"
              data={[
                w.mon?.workStatus || WorkStatusType.OffDay,
                w.tue?.workStatus || WorkStatusType.OffDay,
                w.wed?.workStatus || WorkStatusType.OffDay,
                w.thu?.workStatus || WorkStatusType.OffDay,
                w.fri?.workStatus || WorkStatusType.OffDay,
                w.sat?.workStatus || WorkStatusType.OffDay,
                w.sun?.workStatus || WorkStatusType.OffDay
              ]}
            />
          ))
        }
      }
    ],
    fetchData: fetchCalendars,
    deleteData: deleteCalendar,
    selector: selectCalendarsOverview,
    keyvaluesSelector: selectCalendarKeyvalues,
    keyvaluesLoadingSelector: selectCalendarKeyvaluesLoading,
    drawer: MutateCalendarDrawer
  },
  category: {
    title: 'Categories',
    documentTitle: 'Categories',
    name: 'Category',
    keyvaluesSelector: selectCategoryKeyvalues,
    keyvaluesLoadingSelector: selectCategoryKeyvaluesLoading
  },
  costCentre: {
    title: 'Cost centres',
    documentTitle: 'Cost Centres',
    name: 'Cost centre',
    columns: [
      {
        title: 'Cost centre',
        key: 'name',
        dataIndex: 'name',
        width: 300,
        render: (value: string, record) => (
          <>
            {value} {isInactive(record.inactiveDate) && <InactiveTag />}
          </>
        )
      },
      {
        title: 'Code',
        key: 'code',
        dataIndex: 'code',
        width: '20%'
      }
    ],
    keyvaluesSelector: selectCostCentreKeyvalues,
    keyvaluesLoadingSelector: selectCostCentreKeyvaluesLoading,
    drawer: MutateCostCentreDrawer
  },
  employmentType: {
    title: 'Employment types',
    documentTitle: 'Employment Types',
    name: 'Employment type',
    keyvaluesSelector: selectEmploymentTypeKeyvalues,
    keyvaluesLoadingSelector: selectEmploymentTypeKeyvaluesLoading
  },
  department: {
    title: 'Departments',
    name: 'Department',
    columns: [
      {
        title: 'Department',
        key: 'name',
        dataIndex: 'name',
        width: 300,
        render: (value: string, record) => (
          <>
            {value} {isInactive(record.inactiveDate) && <InactiveTag />}
          </>
        )
      },
      {
        title: 'Head of department',
        key: 'headId',
        dataIndex: 'headId',
        render: (value: string, record: DepartmentOverview) =>
          value && (
            <Person
              photo={record?.headPhotoId && `${baseUrl}/file/${record.headPhotoId}/thumbnailphoto/36`}
              // photo={record.headId && `/employee/employee/${record.headId}/avatar/36?photo_id=${record.headPhotoId}`}
              name={record?.headName}
              description={record?.headJob}
            />
          )
      },
      {
        title: 'Active employees',
        key: 'activeEmployees',
        dataIndex: 'activeEmployees',
        align: 'center',
        width: '30%',
        render: (value: number, record) => (
          <EmPreview totalEmployees={value} onFetch={() => handleFetchMasterEmployees('department', record.id)} />
        )
      }
    ],
    selector: selectDepartmentsOverview,
    keyvaluesSelector: selectDepartmentKeyvalues,
    keyvaluesLoadingSelector: selectDepartmentKeyvaluesLoading,
    drawer: MutateDepartmentDrawer
  },
  division: {
    title: 'Divisions',
    name: 'Division',
    columns: [
      {
        title: 'Division',
        key: 'name',
        dataIndex: 'name',
        width: 300,
        render: (value: string, record) => (
          <>
            {value} {isInactive(record.inactiveDate) && <InactiveTag />}
          </>
        )
      },
      {
        title: 'Head of division',
        key: 'headId',
        dataIndex: 'headId',
        render: (value: string, record: DivisionOverview) =>
          value && (
            <Person
              photo={record?.headPhotoId && `${baseUrl}/file/${record.headPhotoId}/thumbnailphoto/36`}
              name={record?.headName}
              description={record?.headJob}
            />
          )
      },
      {
        title: 'Active employees',
        key: 'activeEmployees',
        dataIndex: 'activeEmployees',
        align: 'center',
        width: '30%',
        render: (value: number, record) => (
          <EmPreview totalEmployees={value} onFetch={() => handleFetchMasterEmployees('division', record.id)} />
        )
      }
    ],
    selector: selectDivisionsOverview,
    keyvaluesSelector: selectDivisionKeyvalues,
    keyvaluesLoadingSelector: selectDivisionKeyvaluesLoading,
    drawer: MutateDivisionDrawer
  },
  docType: {
    title: 'Document types',
    documentTitle: 'Document Types',
    name: 'Document type',
    keyvaluesSelector: selectDoctypeKeyvalues,
    keyvaluesLoadingSelector: selectDoctypeKeyvaluesLoading
  },
  grp: {
    title: 'Groups',
    documentTitle: 'Groups',
    name: 'Group',
    keyvaluesSelector: selectGrpKeyvalues,
    keyvaluesLoadingSelector: selectGrpKeyvaluesLoading
  },
  holiday: {
    title: 'Holiday',
    documentTitle: 'Holiday',
    name: 'Holiday',
    keyvaluesSelector: selectHolidayKeyvalues,
    keyvaluesLoadingSelector: selectHolidayKeyvaluesLoading
  },
  job: {
    title: 'Job titles',
    documentTitle: 'Job Titles',
    name: 'Job title',
    keyvaluesSelector: selectJobKeyvalues,
    keyvaluesLoadingSelector: selectJobKeyvaluesLoading
  },
  leaveGroup: {
    title: 'Leave groups',
    documentTitle: 'Leave Groups',
    name: 'Leave group',
    keyvaluesSelector: selectLeaveGroupKeyvalues,
    keyvaluesLoadingSelector: selectLeaveGroupKeyvaluesLoading
  },
  payGroup: {
    title: 'Pay groups',
    documentTitle: 'Pay Groups',
    name: 'Pay group',
    keyvaluesSelector: selectPayGroupKeyvalues,
    keyvaluesLoadingSelector: selectPayGroupKeyvaluesLoading
  },
  race: {
    title: 'Races',
    name: 'Race',
    keyvaluesSelector: selectRaceKeyvalues,
    keyvaluesLoadingSelector: selectRaceKeyvaluesLoading
  },
  religion: {
    title: 'Religions',
    name: 'Religion',
    keyvaluesSelector: selectReligionKeyvalues,
    keyvaluesLoadingSelector: selectReligionKeyvaluesLoading
  },
  reason: {
    title: 'Reasons',
    name: 'Reason',
    keyvaluesSelector: selectReasonKeyvalues,
    keyvaluesLoadingSelector: selectReasonKeyvaluesLoading
  },
  qualification: {
    title: 'Qualifications',
    name: 'Qualification',
    keyvaluesSelector: selectQualificationKeyvalues,
    keyvaluesLoadingSelector: selectQualificationKeyvaluesLoading,
    drawer: MutateQualificationDrawer
  },
  section: {
    title: 'Sections',
    name: 'Section',
    columns: [
      {
        title: 'Section',
        key: 'name',
        dataIndex: 'name',
        width: 300,
        render: (value: string, record) => (
          <>
            {value} {isInactive(record.inactiveDate) && <InactiveTag />}
          </>
        )
      },
      {
        title: 'Head of section',
        key: 'headId',
        dataIndex: 'headId',
        render: (value: string, record: SectionOverview) =>
          value && (
            <Person
              photo={record?.headPhotoId && `${baseUrl}/file/${record.headPhotoId}/thumbnailphoto/36`}
              name={record?.headName}
              description={record?.headJob}
            />
          )
      },
      {
        title: 'Active employees',
        key: 'activeEmployees',
        dataIndex: 'activeEmployees',
        align: 'center',
        width: '30%',
        render: (value: number, record) => (
          <EmPreview totalEmployees={value} onFetch={() => handleFetchMasterEmployees('section', record.id)} />
        )
      }
    ],
    selector: selectSectionsOverview,
    keyvaluesSelector: selectSectionKeyvalues,
    keyvaluesLoadingSelector: selectSectionKeyvaluesLoading,
    drawer: MutateSectionDrawer
  },
  team: {
    title: 'Teams',
    name: 'Team',
    columns: [
      {
        title: 'Team',
        key: 'name',
        dataIndex: 'name',
        width: 300,
        render: (value: string, record) => (
          <>
            {value} {isInactive(record.inactiveDate) && <InactiveTag />}
          </>
        )
      },
      {
        title: 'Team lead',
        key: 'headId',
        dataIndex: 'headId',
        render: (value: string, record: TeamOverview) =>
          value && (
            <Person
              photo={record?.headPhotoId && `${baseUrl}/file/${record.headPhotoId}/thumbnailphoto/36`}
              // photo={record.headId && `/employee/employee/${record.headId}/avatar/36?photo_id=${record.headPhotoId}`}
              name={record?.headName}
              description={record?.headJob}
            />
          )
      },
      {
        title: 'Team members',
        key: 'activeEmployees',
        dataIndex: 'activeEmployees',
        align: 'center',
        width: '30%',
        render: (value: number, record) => (
          <EmPreview totalEmployees={value} onFetch={() => handleFetchMasterEmployees('team', record.id)} />
        )
      }
    ],
    selector: selectTeamsOverview,
    keyvaluesSelector: selectTeamKeyvalues,
    keyvaluesLoadingSelector: selectTeamKeyvaluesLoading,
    drawer: MutateTeamDrawer
  },
  salaryGrade: {
    title: 'Salary grades',
    documentTitle: 'Salary Grades',
    name: 'Salary grade',
    keyvaluesSelector: selectSalaryGradeKeyvalues,
    keyvaluesLoadingSelector: selectSalaryGradeKeyvaluesLoading,
    drawer: MutateSalaryGradeDrawer
  }
}
