import React, { FC, ChangeEvent, useCallback, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { Form, Input } from '~/core-components'
import { Col, Row } from '~/components'
import { Errors } from '~/types/store'
import { useFocus } from '~/hooks/use-focus'
import { ICompanyInfo } from '../../../types'

interface CompanyInfoFormProps {
  data: ICompanyInfo
  readOnly?: boolean
  errors?: Errors
  onChange: (data: ICompanyInfo) => void
}

export const EMPTY_COMPANY_INFO_FORM_DATA: ICompanyInfo = {
  name: '',
  registrationNo: '',
  incorporationDate: undefined
}

export const CompanyInfoForm: FC<CompanyInfoFormProps> = ({
  data,
  readOnly = false,
  errors,
  onChange
}: CompanyInfoFormProps) => {
  const [formData, setFormData] = useState<ICompanyInfo>(EMPTY_COMPANY_INFO_FORM_DATA)
  const [focusRef] = useFocus(!readOnly)

  useEffect(() => {
    if (data) {
      setFormData(data)
    } else {
      setFormData(EMPTY_COMPANY_INFO_FORM_DATA)
    }
  }, [data])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      const updated = { ...formData, ...updates }
      setFormData(updated)
      typeof onChange === 'function' && onChange(updated)
    },
    [formData, onChange]
  )

  return (
    <>
      <Row>
        <Col span={24}>
          <Form.Item label="Company name" validateStatus={errors?.name ? 'error' : ''} help={errors?.name}>
            <Input
              ref={focusRef}
              value={formData.name}
              readOnly={readOnly}
              onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ name: event.target.value })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={12}>
          <Form.Item label="Unique Entity Number (UEN)">
            <Input
              value={formData.registrationNo}
              readOnly={readOnly}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleFormDataChange({ registrationNo: event.target.value })
              }
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Date of incorporation">
            <Input.Date
              // value={moment.tz(formData.incorporationDate, 'Asia/Singapore')}
              value={formData.incorporationDate ? moment(formData.incorporationDate) : undefined}
              inputReadOnly={readOnly}
              onChange={(value: moment.Moment | null) =>
                handleFormDataChange({ incorporationDate: value?.format('YYYY-MM-DD') })
              }
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}
