import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { SysOptions } from '~/components'
import { fetchSysOptions } from '~/features/master'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { snakeToCamel } from '~/utils'

export type OperatorType =
  | 'criteria_date_operator'
  | 'criteria_number_operator'
  | 'criteria_option_operator'
  | 'criteria_simple_operator'
  | 'criteria_text_operator'
  | 'criteria_yymm_operator'

export interface CriteriaOperatorTypeProps {
  type: OperatorType
  value: string
  readOnly?: boolean
  showAsLabel?: boolean
  onChange?: (value: string) => void
}

export const CriteriaOperatorType: FC<CriteriaOperatorTypeProps> = ({
  type,
  value,
  readOnly,
  showAsLabel,
  onChange
}) => {
  const sysOptionTypeCamel = snakeToCamel(type)
  const keyValues = useSelector((state: StoreState) => state.master.keyvalues)

  useEffect(() => {
    dispatch(fetchSysOptions(type))
  }, [type])

  if (showAsLabel) {
    return <>{keyValues[sysOptionTypeCamel]?.entities[value]?.value?.toLowerCase() || ''}</>
  }

  return (
    <SysOptions
      type={type}
      value={value}
      allowClear={false}
      readOnly={readOnly}
      onChange={onChange}
      dropdownMatchSelectWidth={false}
    />
  )
}
