import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { CalendarViewerCategory, CalendarViewerState } from '../types'
import { fetchCalendarViewers } from '../actions'
import { selectCalendarViewers } from '../selectors'

export const useCalendarViewers = (category: CalendarViewerCategory): [CalendarViewerState[], boolean] => {
  const calendarViewers = useSelector(selectCalendarViewers)(category)
  const loading = useSelector((state: StoreState) => state.calendarViewer.calendarViewersLoading[category] || false)

  useEffect(() => {
    dispatch(fetchCalendarViewers(category))
  }, [category])

  return [calendarViewers, loading]
}
