import { replaceMyLeaveApplyFormInfo, setMyLeaveApplyFormInfoLoading } from '../../reducers'
import { apiGetMyApplyFormSettings } from '../../api/ssleave-apply.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'
import { emptyGuid } from '~/constants'

export const fetchMyLeaveApplyFormInfo = (
  leaveTypeId: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!leaveTypeId || leaveTypeId === emptyGuid) return

    const loading = getState().leave.leaveApplyFormInfoLoading[leaveTypeId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().leave.leaveApplyFormInfo[leaveTypeId]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setMyLeaveApplyFormInfoLoading({ leaveTypeId, loading: true }))
      const { result, status } = await apiGetMyApplyFormSettings(leaveTypeId)
      if (status) {
        dispatch(replaceMyLeaveApplyFormInfo({ leaveTypeId, data: result }))
      }
    } finally {
      dispatch(setMyLeaveApplyFormInfoLoading({ leaveTypeId, loading: false }))
    }
  }
}
