import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import persistReducer from 'redux-persist/es/persistReducer'
import {
  WfEventState,
  SysWfActionTypeState,
  SysWfEventTypeState,
  SysWfFlowTypeState,
  WorkflowRootState,
  WorkflowState,
  WfActionState
} from './types'
import { WfCategory } from './types'
import { workflowPersistConfig } from './types'

const sysFlowTypesAdapter = createEntityAdapter<SysWfFlowTypeState>({ selectId: model => model.code })
const sysEventTypesAdapter = createEntityAdapter<SysWfEventTypeState>({ selectId: model => model.code })
const sysActionTypesAdapter = createEntityAdapter<SysWfActionTypeState>({ selectId: model => model.code })
const workflowsAdapter = createEntityAdapter<WorkflowState>()
const wfEventsAdapter = createEntityAdapter<WfEventState>()
const wfActionsAdapter = createEntityAdapter<WfActionState>()

const sysFlowTypesInitialState = sysFlowTypesAdapter.getInitialState()
const sysEventTypesInitialState = sysEventTypesAdapter.getInitialState()
const sysActionTypesInitialState = sysActionTypesAdapter.getInitialState()
const workflowsInitialState = workflowsAdapter.getInitialState()
const wfEventsInitialState = wfEventsAdapter.getInitialState()
const wfActionsInitialState = wfActionsAdapter.getInitialState()

const initialState: WorkflowRootState = {
  sysWfFlowTypes: {},
  sysWfFlowTypesLoading: {},
  sysWfEventTypes: {},
  sysWfEventTypesLoading: {},
  sysWfActionTypes: {},
  sysWfActionTypesLoading: {},
  workflows: {},
  workflowsLoading: {},
  wfEvents: {},
  wfEventsLoading: {},
  wfActions: {},
  wfActionsLoading: {}
}

const workflowSlice = createSlice({
  name: 'workflow',
  initialState,
  reducers: {
    replaceSysFlowTypes: (state, action: PayloadAction<{ category: WfCategory; data: SysWfFlowTypeState[] }>) => {
      const { category, data } = action.payload
      sysFlowTypesAdapter.setAll(state.sysWfFlowTypes[category]!, data)
    },
    setSysFlowTypesLoading: (state, action: PayloadAction<{ category: WfCategory; loading: boolean }>) => {
      const { category, loading } = action.payload
      state.sysWfFlowTypes[category] = state.sysWfFlowTypes[category] || sysFlowTypesInitialState
      state.sysWfFlowTypesLoading[category] = loading
    },
    replaceSysEventTypes: (state, action: PayloadAction<{ flowType: string; data: SysWfEventTypeState[] }>) => {
      const { flowType, data } = action.payload
      sysEventTypesAdapter.setAll(state.sysWfEventTypes[flowType]!, data)
    },
    setSysEventTypesLoading: (state, action: PayloadAction<{ flowType: string; loading: boolean }>) => {
      const { flowType, loading } = action.payload
      state.sysWfEventTypes[flowType] = state.sysWfEventTypes[flowType] || sysEventTypesInitialState
      state.sysWfEventTypesLoading[flowType] = loading
    },
    replaceSysActionTypes: (state, action: PayloadAction<{ flowEventType: string; data: SysWfActionTypeState[] }>) => {
      const { flowEventType, data } = action.payload
      sysActionTypesAdapter.setAll(state.sysWfActionTypes[flowEventType]!, data)
    },
    setSysActionTypesLoading: (state, action: PayloadAction<{ flowEventType: string; loading: boolean }>) => {
      const { flowEventType, loading } = action.payload
      state.sysWfActionTypes[flowEventType] = state.sysWfActionTypes[flowEventType] || sysActionTypesInitialState
      state.sysWfActionTypesLoading[flowEventType] = loading
    },
    replaceWorkflows: (state, action: PayloadAction<{ category: WfCategory; data: WorkflowState[] }>) => {
      const { category, data } = action.payload
      workflowsAdapter.setAll(state.workflows[category]!, data)
    },
    setWorkflow: (state, action: PayloadAction<{ category: WfCategory; data: WorkflowState }>) => {
      const { category, data } = action.payload
      workflowsAdapter.upsertOne(state.workflows[category]!, data)
    },
    removeWorkflow: (state, action: PayloadAction<{ category: WfCategory; id: string }>) => {
      const { category, id } = action.payload
      workflowsAdapter.removeOne(state.workflows[category]!, id)
    },
    setWorkflowsLoading: (state, action: PayloadAction<{ category: WfCategory; loading: boolean }>) => {
      const { category, loading } = action.payload
      state.workflows[category] = state.workflows[category] || workflowsInitialState
      state.workflowsLoading[category] = loading
    },
    replaceWfEvents: (state, action: PayloadAction<{ workflowId: string; data: WfEventState[] }>) => {
      const { workflowId, data } = action.payload
      wfEventsAdapter.setAll(state.wfEvents[workflowId]!, data)
    },
    setWfEvent: (state, action: PayloadAction<{ workflowId: string; data: WfEventState }>) => {
      const { workflowId, data } = action.payload
      wfEventsAdapter.upsertOne(state.wfEvents[workflowId]!, data)
    },
    setWfEventsLoading: (state, action: PayloadAction<{ workflowId: string; loading: boolean }>) => {
      const { workflowId, loading } = action.payload
      state.wfEvents[workflowId] = state.wfEvents[workflowId] || wfEventsInitialState
      state.wfEventsLoading[workflowId] = loading
    },
    replaceWfActions: (state, action: PayloadAction<{ wfEventId: string; data: WfActionState[] }>) => {
      const { wfEventId, data } = action.payload
      wfActionsAdapter.setAll(state.wfActions[wfEventId]!, data)
    },
    setWfActionsLoading: (state, action: PayloadAction<{ wfEventId: string; loading: boolean }>) => {
      const { wfEventId, loading } = action.payload
      state.wfActions[wfEventId] = state.wfActions[wfEventId] || wfActionsInitialState
      state.wfActionsLoading[wfEventId] = loading
    }
  }
})

export const {
  replaceSysFlowTypes,
  setSysFlowTypesLoading,
  replaceSysEventTypes,
  setSysEventTypesLoading,
  replaceSysActionTypes,
  setSysActionTypesLoading,
  replaceWorkflows,
  setWorkflow,
  removeWorkflow,
  setWorkflowsLoading,
  replaceWfEvents,
  setWfEvent,
  setWfEventsLoading,
  replaceWfActions,
  setWfActionsLoading
} = workflowSlice.actions

export const workflowReducers = {
  workflow: persistReducer<WorkflowRootState>(workflowPersistConfig, workflowSlice.reducer)
}
