import { replaceMyClaimRecordApprovalHistories, setMyClaimRecordApprovalHistoriesLoading } from '../reducers'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'
import { apiGetMyClaimRecordApprovalHistories } from '../api/ss-claim-record-approval-history.api'

export const fetchMyClaimRecordApprovalHistories = (
  id: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const loading = getState().myClaim.myClaimRecordApprovalHistoriesLoading[id]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().myClaim.myClaimRecordApprovalHistories[id]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setMyClaimRecordApprovalHistoriesLoading({ id, loading: true }))
      const { result, status } = await apiGetMyClaimRecordApprovalHistories(id)
      if (status) dispatch(replaceMyClaimRecordApprovalHistories({ id, data: result }))
    } finally {
      dispatch(setMyClaimRecordApprovalHistoriesLoading({ id, loading: false }))
    }
  }
}
