import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { fetchPayClaimMaps, PayClaimMapState, selectPayClaimMaps } from '~/features/master'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'

export const usePayClaimMaps = (
  payGroupId: string,
  fetchStrategy: FetchStrategy = 'when-empty'
): [PayClaimMapState[], boolean] => {
  const payClaimMaps = useSelector(selectPayClaimMaps)(payGroupId)
  const loading = useSelector((state: StoreState) => state.master.payClaimMapsLoading[payGroupId])

  useEffect(() => {
    if (payGroupId) {
      dispatch(fetchPayClaimMaps(payGroupId, { strategy: fetchStrategy }))
    }
  }, [payGroupId, fetchStrategy])

  return [payClaimMaps, loading]
}
