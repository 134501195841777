import React, { FC, useCallback } from 'react'
import { Col, Row } from '~/components'
import { Button, Modal } from '~/core-components'
import './RptPayMonthlyExcelModal.less'

interface RptPayMonthlyExcelModalProps {
  visible: boolean
  onChange: (format: string) => Promise<void>
  onClose: () => void
}

export const RptPayMonthlyExcelModal: FC<RptPayMonthlyExcelModalProps> = ({ visible, onChange, onClose }) => {
  const handleClose = useCallback(() => {
    typeof onClose === 'function' && onClose()
  }, [onClose])

  const handleChange = useCallback(
    (format: string) => {
      onChange(format)
      typeof onClose === 'function' && onClose()
    },
    [onChange, onClose]
  )

  return (
    <Modal
      className="rpt-pay-monthly-excel-modal"
      open={visible}
      title="Select the excel report format"
      onCancel={handleClose}
      width={600}
    >
      <Row gutter={30}>
        <Col span={12}>
          <Button type="ghost" onClick={() => handleChange('column')}>
            <div className="subtitle">Format 1</div>
            <img src="/report-format-col.png" alt="Format 1" />
          </Button>
        </Col>
        <Col span={12}>
          <Button type="ghost" onClick={() => handleChange('row')}>
            <div className="subtitle">Format 2</div>
            <img src="/report-format-row.png" alt="Format 2" />
          </Button>
        </Col>
      </Row>
    </Modal>
  )
}
