import { replaceMyLeaveEntitlements, setMyLeaveEntitlementsLoading } from '../../reducers'
import { apiGetMyLeaveEntitlements } from '../../api/ssleave-entitlement.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchMyLeaveEntitlements = (options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().my.myLeaveEntitlementsLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().my.myLeaveEntitlements?.ids.length > 0
      if (hasData) return
    }

    try {
      dispatch(setMyLeaveEntitlementsLoading(true))
      const { result, status } = await apiGetMyLeaveEntitlements()
      if (status) dispatch(replaceMyLeaveEntitlements(result))
    } finally {
      dispatch(setMyLeaveEntitlementsLoading(false))
    }
  }
}
