import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiSaveLeaveBuddy } from '../api/leave-buddy.api'
import { fetchLeaveBuddy } from './fetch-leave-buddy'
import { emptyGuid } from '~/constants'
import { ILeaveBuddy } from '../types'
import { fetchLeaveBuddyEm } from './fetch-leave-buddy-em'

export const saveLeaveBuddy = (request: ILeaveBuddy): ThunkResult<void> => {
  return async dispatch => {
    request = { ...request, id: request.id || emptyGuid }
    const { status, result, errors, message, errorData } = await apiSaveLeaveBuddy(request)

    if (status) {
      const id = result?.id || request.id
      if (id) {
        dispatch(fetchLeaveBuddy(id))
        dispatch(fetchLeaveBuddyEm(id))
      }

      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
