import { setEmSelection, setEmSelectionLoading } from '~/features/employee'
import { apiGetIr8aNewEmSelections } from '../api/ytd.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions, Pagination } from '~/types/common'
import { emptyGuid } from '~/constants'

export const fetchIr8aNewEmSelections = (
  ytdSubmissionId: string,
  viewId: string,
  pagination: Pagination,
  search: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!ytdSubmissionId || ytdSubmissionId === emptyGuid || !viewId) return

    const loading = getState().employee.emSelectionLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().employee.emSelection?.entities[viewId]
      if (hasData) return
    }

    try {
      dispatch(setEmSelectionLoading(true))
      const { result, status } = await apiGetIr8aNewEmSelections(ytdSubmissionId, viewId, {
        ...pagination,
        search
      })
      if (status) {
        dispatch(setEmSelection(result))
      }
    } finally {
      dispatch(setEmSelectionLoading(false))
    }
  }
}
