import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import moment from 'moment-timezone'
import { StoreState } from '~/types/store'
import { DailyRecordState } from '../types'

export const selectDailyRecordsByEmployeeDate = createSelector(
  (state: StoreState) => state.attendance.dailyRecords,
  dailyRecordsState =>
    memoize(
      (clockDate: string, employeeId: string): DailyRecordState[] => {
        const data = Object.values(dailyRecordsState.entities || {}) as DailyRecordState[]
        return data.filter(d => moment(d?.clockDate).format('YYYY-MM-DD') === clockDate && d.employeeId === employeeId)
      },
      (clockDate: string, employeeId: string) => `${clockDate}|${employeeId}`
    )
)
