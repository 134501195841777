import { request, getBaseUrl, timeout, toQueryParams } from '~/utils/request'
import { IRptClaimRecord } from '../types'

const baseUrl = getBaseUrl('/claim/rptclaimrecord')

export const apiDownloadClaimRecordExcel = (params: IRptClaimRecord) => {
  params.fieldIds = params.fieldIds?.filter(f => f !== '')

  return request('get', `${baseUrl}/excel`, toQueryParams(params), { responseType: 'blob', timeout })
}

export const apiDownloadClaimRecordPdf = (params: IRptClaimRecord) => {
  params.fieldIds = params.fieldIds?.filter(f => f !== '')

  return request('get', `${baseUrl}/pdf`, toQueryParams(params), { responseType: 'blob', timeout })
}
