import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { apiUpdateFormulaDetail } from '../api/formula.api'
import { FormulaDetailState, IFormulaDetailInfo } from '../types'
import { setFormulaDetail } from '../reducers'

export const updateFormulaDetail = <T extends IFormulaDetailInfo>(
  formulaId: string,
  id: string,
  before: T,
  request: T
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!formulaId || !id) return

    const after = { ...before, ...request } as T
    const patch = generateJsonPatch<T>(before, after)
    const { status, errors, message, errorData } = await apiUpdateFormulaDetail(formulaId, id, patch)

    if (status) {
      let formulaDetail = getState().formula.formulaDetail.entities[id]
      const updated = { ...(formulaDetail as FormulaDetailState), ...after }
      dispatch(setFormulaDetail(updated))

      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
