import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { SsAccessConfigState } from '../types'
import { selectSsAccessConfigs } from '../selectors'
import { fetchSsAccessConfigs } from '../actions'

export const useSsAccessConfigs = (): [SsAccessConfigState[], boolean] => {
  const data = useSelector(selectSsAccessConfigs)
  const loading = useSelector((state: StoreState) => state.master.ssAccessConfigsLoading)

  useEffect(() => {
    dispatch(fetchSsAccessConfigs({ strategy: 'when-empty' }))
  }, [])

  return [data, loading]
}
