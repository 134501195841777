import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import confirm from 'antd/lib/modal/confirm'
import { Alert, Button, Form } from '~/core-components'
import { Col, Row, SysOptions } from '~/components'
import { ActionResult, Errors } from '~/types/store'
import { dispatch } from '~/stores/store'
import { updatePreference } from '../../../actions'
import { selectMyLogin } from '../../../selectors'
import { IUpdatePreference } from '../../../types'

interface EditPreferenceFormProps {}

interface FormData extends IUpdatePreference {}

const EMPTY_FORM_DATA: FormData = {
  timeZoneId: ''
}

const alertStyle: CSSProperties = { marginBottom: 15 }

export const EditPreferenceForm: FC<EditPreferenceFormProps> = () => {
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const [saving, setSaving] = useState(false)
  const login = useSelector(selectMyLogin)
  const history = useHistory()

  useEffect(() => {
    if (login) {
      const { timeZoneId } = login
      setFormData({ timeZoneId })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [login])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(data => ({ ...data, ...updates }))
  }, [])

  const handleSave = useCallback(async () => {
    let result: ActionResult | undefined
    setSaving(true)
    try {
      result = await dispatch(updatePreference(formData))
      setErrors(undefined)
    } finally {
      setSaving(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      confirm({
        title: 'Logout',
        content: 'Re-login is required to apply the time zone changes. Logout now?',
        onOk: () => history.replace('/logout'),
        okText: 'Logout'
      })
    }
  }, [formData, history])

  return (
    <Form id="form-preference" onFinish={handleSave}>
      <Row>
        <Col flex="300px">
          <Form.Item label="Time zone" validateStatus={errors?.timeZoneId ? 'error' : ''} help={errors?.timeZoneId}>
            <SysOptions
              type="time_zone"
              value={formData.timeZoneId}
              onChange={(value: string) => handleFormDataChange({ timeZoneId: value })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col>
          <Alert message="Re-login is required to apply the time zone changes" banner style={alertStyle} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button.ActionGroup>
            <Button type="primary" loading={saving} form="form-preference" htmlType="submit">
              Update time zone
            </Button>
          </Button.ActionGroup>
        </Col>
      </Row>
    </Form>
  )
}
