import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { EmEmploymentTypeState, IEmEmploymentType } from '../types'

const baseUrl = getBaseUrl('/employee/employee')

export const apiGetEmEmploymentTypes = (employeeId: string) =>
  request<EmEmploymentTypeState[]>('get', `${baseUrl}/${employeeId}/employmenttype`)

export const apiGetEmEmploymentType = (employeeId: string, emEmploymentTypeId: string) =>
  request<EmEmploymentTypeState>('get', `${baseUrl}/${employeeId}/employmenttype/${emEmploymentTypeId}`)

export const apiAddEmEmploymentType = (employeeId: string, emEmploymentType: IEmEmploymentType) =>
  request<{ id: string; emEmploymentTypeId?: string }>(
    'post',
    `${baseUrl}/${employeeId}/employmenttype`,
    emEmploymentType
  )

export const apiUpdateEmEmploymentType = (employeeId: string, id: string, emEmploymentType: Operation[]) =>
  request<{ emEmploymentTypeId?: string }>('patch', `${baseUrl}/${employeeId}/employmenttype/${id}`, emEmploymentType)

export const apiDeleteEmEmploymentType = (employeeId: string, id: string) =>
  request<{ emEmploymentTypeId?: string }>('delete', `${baseUrl}/${employeeId}/employmenttype/${id}`)
