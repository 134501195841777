import React, { FC } from 'react'
import { usePermissionGate } from '../hooks'

interface PermissionGateProps {
  sysPermissionId: string | string[]
  action?: string | string[]
}

export const PermissionGate: FC<PermissionGateProps> = ({ children, sysPermissionId, action }) => {
  const hasPermission = usePermissionGate(sysPermissionId, action)

  if (!hasPermission) return null

  return <>{children}</>
}
