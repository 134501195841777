import { Operation } from 'fast-json-patch'
import { request, getBaseUrl, timeout } from '~/utils/request'
import { IdValue, Search } from '~/types/common'
import {
  PayAccountState,
  PayRecordSgState,
  IPayRecordPayment,
  IPayRecordPaymentUpdated,
  IPayRecordNotes,
  IPayRecordLock,
  IPayRecordPublished,
  IPayRecordPublishedAll,
  PayTranSgState,
  PayTranDtlSgState
} from '../types'
import { EmSelectState } from '~/components'

const baseUrl = getBaseUrl('/payroll-sg/payrecord')

export const apiGetPayRecordsSgView = (viewId: string, payRunId: string, params: Search) =>
  request<PayRecordSgState[]>('get', `${baseUrl}/view/${viewId}/${payRunId}`, params)

export const apiGetPayRecordSgViewItem = (payRunId: string, employeeId: string) =>
  request<PayRecordSgState>('get', `${baseUrl}/viewitem/${payRunId}/${employeeId}`)

export const apiGetPayrollReportExcel = (payRunId: string) =>
  request('get', `${baseUrl}/${payRunId}/excel`, undefined, { responseType: 'blob', timeout })

export const apiGetPayRecordEmployees = (payRunId: string) =>
  request<EmSelectState[]>('get', `${baseUrl}/${payRunId}/employees`)

export const apiGetPayRecordPayRuns = (employeeId: string) =>
  request<IdValue[]>('get', `${baseUrl}/${employeeId}/payruns`)

export const apiGetPayTransSg = (recordId: string) => request<PayTranSgState[]>('get', `${baseUrl}/${recordId}/tran`)

export const apiGetPayTranDtlsSg = (recordId: string, tranId: string) =>
  request<PayTranDtlSgState[]>('get', `${baseUrl}/${recordId}/tran/${tranId}`)

export const apiGetPayAccounts = (recordId: string) =>
  request<PayAccountState[]>('get', `${baseUrl}/${recordId}/payaccounts`)

// export const apiAddPayRecord = (payRecord: PayRecordRequest) =>
//   request<{ id: string }>('post', baseUrl, payRecord)

export const apiUpdatePayRecord = (id: string, payRecord: Operation[]) =>
  request<void>('patch', `${baseUrl}/${id}`, payRecord)

export const apiUpdatePayRecordPayment = (id: string, payment: IPayRecordPayment) =>
  request<IPayRecordPaymentUpdated>('post', `${baseUrl}/${id}/payment`, payment)

export const apiUpdatePayRecordNotes = (id: string, payRecord: IPayRecordNotes) =>
  request<void>('post', `${baseUrl}/${id}/notes`, payRecord)

export const apiLockPayRecord = (id: string) => request<IPayRecordLock>('post', `${baseUrl}/${id}/lock`)

export const apiUnlockPayRecord = (id: string) => request<void>('post', `${baseUrl}/${id}/unlock`)

export const apiLockAllPayRecords = (payRunId: string) =>
  request<IPayRecordLock[]>('post', `${baseUrl}/${payRunId}/lockall`)

export const apiUnlockAllPayRecords = (payRunId: string) => request<void>('post', `${baseUrl}/${payRunId}/unlockall`)

export const apiPublishPayRecord = (id: string) => request<IPayRecordPublished>('post', `${baseUrl}/${id}/publish`)

export const apiUnpublishPayRecord = (id: string) => request<void>('post', `${baseUrl}/${id}/unpublish`)

export const apiPublishAllPayRecords = (payRunId: string) =>
  request<IPayRecordPublishedAll>('post', `${baseUrl}/${payRunId}/publishall`)

export const apiUnpublishAllPayRecords = (payRunId: string) =>
  request<void>('post', `${baseUrl}/${payRunId}/unpublishall`)

export const apiDeletePayRecord = (id: string) => request<void>('delete', `${baseUrl}/${id}`)
