import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { LoadingOutlined } from '@ant-design/icons'
import moment from 'moment-timezone'
import confirm from 'antd/lib/modal/confirm'
import { Link, SecondaryLink, Space, Spin, Tag, Tooltip } from '~/core-components'
import { Col, Row } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { fetchUser, selectUserById } from '~/features/temp'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { Permission, PermissionAction } from '~/constants'
import { sendToXero } from '../../actions'
import { usePayRunXero, useXeroConnections } from '../../hooks'
import { refetchPayRunXero } from '../../reducers'
import './XeroIntegration.less'

interface XeroIntegrationProps {
  payRunId: string
}

export const XeroIntegration: FC<XeroIntegrationProps> = ({ payRunId }) => {
  const history = useHistory()
  const [sending, setSending] = useState(false)
  const [connections] = useXeroConnections()
  const [payRunXero] = usePayRunXero(payRunId)
  const postedDate = payRunXero?.postedDate
  const postedBy = payRunXero?.postedBy
  const poster = useSelector((state: StoreState) => selectUserById(state, postedBy || ''))
  const canSend = usePermissionGate(Permission.xero, PermissionAction.Send)

  useEffect(() => {
    if (postedBy) {
      dispatch(fetchUser(postedBy, { strategy: 'when-empty' }))
    }
  }, [postedBy])

  const handleConfigure = useCallback(() => {
    history.push(SETTINGS_ROUTES.xero)
  }, [history])

  const handleSend = useCallback(async () => {
    confirm({
      title: postedBy ? 'Re-send to Xero' : 'Send to Xero',
      content: postedBy ? 'Do you want to re-send the payroll to Xero?' : 'Do you want to send the payroll to Xero?',
      onOk: async () => {
        try {
          setSending(true)
          await dispatch(sendToXero(payRunId))
          dispatch(refetchPayRunXero())
        } finally {
          setSending(false)
        }
      },
      okText: postedBy ? 'Re-send' : 'Send',
      okType: 'primary'
    })
  }, [payRunId, postedBy])

  if (!canSend) return null

  return (
    <Row className="xero-integration" align="middle">
      <Col>
        {connections.length > 0 ? (
          <Space>
            <Link className="xero-integration__link" onClick={handleSend}>
              <img alt="Xero" src="/partners/xero.png" className="xero-integration__logo" />
              Send to Xero {sending && <Spin indicator={<LoadingOutlined spin />} />}
            </Link>
            {payRunXero?.postedDate && (
              <Tooltip
                title={
                  <>
                    Sent by {poster?.loginName || poster?.id} on {moment(postedDate).format('DD MMM YYYY hh:mm:ss A')}
                  </>
                }
              >
                <Tag type="success">Sent</Tag>
              </Tooltip>
            )}
          </Space>
        ) : (
          <Link className="xero-integration__link" onClick={handleConfigure}>
            Setup Xero connection
          </Link>
        )}
      </Col>
      <Col flex="1"></Col>
      <Col hidden={connections.length === 0}>
        <Tooltip title="Configure Xero connection & item mapping">
          <SecondaryLink onClick={handleConfigure}>
            <i className="fal fa-gear" />
          </SecondaryLink>
        </Tooltip>
      </Col>
    </Row>
  )
}
