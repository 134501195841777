import React, { FC, ChangeEvent, useState, useCallback, useEffect } from 'react'
import moment from 'moment-timezone'
import { useSelector } from 'react-redux'
import { Checkbox, CheckboxChangeEvent, Form, Input, Radio, RadioChangeEvent, Space, Tag } from '~/core-components'
import { Col, ColorPicker, Row, LveKeyValues, MonthKeyValues, SysOptions, DEFAULT_COLOR } from '~/components'
import { Errors, StoreState } from '~/types/store'
import { useFocus } from '~/hooks/use-focus'
import { LvePeriodBasis, LveUnit, LveVisibleType } from '~/constants'
import { KeyValue } from '~/types/common'
import { LeaveTypeInfoState } from '../../../types'
import { selectSysLtFieldsByCode } from '../../../selectors'
import { SysLeaveTypeTypeTag } from './SysLeaveTypeTypeTag'
import { SysLeaveTypeCodeTag } from './SysLeaveTypeCodeTag'

interface LeaveTypeInfoFormProps {
  leaveTypeId?: string
  leaveTypeUnit?: string
  data: LeaveTypeInfoState
  readOnly?: boolean
  errors?: Errors
  onChange: (data: LeaveTypeInfoState) => void
}

export const EMPTY_LEAVE_TYPE_INFO_FORM_DATA: LeaveTypeInfoState = {
  isSys: false,
  name: '',
  sysLeaveTypeCode: '',
  periodBasis: '',
  periodDuration: 0,
  periodStart: 0,
  inactiveDate: '',
  color: DEFAULT_COLOR,
  entByCitizen: false,
  parentId: '',
  grantLtId: '',
  isPaid: false
}

export const LeaveTypeInfoForm: FC<LeaveTypeInfoFormProps> = ({
  leaveTypeId,
  leaveTypeUnit,
  data,
  readOnly,
  errors,
  onChange
}) => {
  const [formData, setFormData] = useState<LeaveTypeInfoState>(EMPTY_LEAVE_TYPE_INFO_FORM_DATA)
  const sysLtFields = useSelector(selectSysLtFieldsByCode)(data?.sysLeaveTypeCode || '')
  const sysColumns = useSelector((state: StoreState) => state.leave.ltSysColumns[leaveTypeId || ''] || [])
  const [focusRef] = useFocus(!readOnly)
  const [isInactive, setIsInactive] = useState(false)

  useEffect(() => {
    if (data) {
      setFormData(data)
      setIsInactive(!!data.inactiveDate)
    } else {
      setFormData(EMPTY_LEAVE_TYPE_INFO_FORM_DATA)
      setIsInactive(false)
    }
  }, [data])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      const updated = { ...formData, ...updates }
      setFormData(updated)
      typeof onChange === 'function' && onChange(updated)
    },
    [formData, onChange]
  )

  const isVisible = useCallback(
    (fieldName: string) => {
      return sysLtFields[fieldName]?.visibleType === LveVisibleType.basic
    },
    [sysLtFields]
  )

  return (
    <>
      <Row gutter={15}>
        <Col flex="auto">
          <Form.Item
            label={
              <>
                Name&nbsp;
                <SysLeaveTypeTypeTag code={formData.sysLeaveTypeCode} />
                <SysLeaveTypeCodeTag code={formData.sysLeaveTypeCode} />
                {leaveTypeUnit === LveUnit.hours && <Tag>hourly</Tag>}
              </>
            }
            validateStatus={errors?.name ? 'error' : ''}
            help={errors?.name}
          >
            <Input
              ref={focusRef}
              value={formData.name}
              readOnly={readOnly}
              onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ name: event.target.value })}
            />
          </Form.Item>
        </Col>
        <Col flex="none">
          <Form.Item label="Inactive" validateStatus={errors?.inactiveDate ? 'error' : ''} help={errors?.inactiveDate}>
            <Checkbox
              checked={isInactive}
              readOnly={readOnly}
              disabled={sysColumns.includes('inactiveDate')}
              onChange={(event: CheckboxChangeEvent) => {
                setIsInactive(event.target.checked)
                handleFormDataChange({ inactiveDate: event.target.checked ? moment().format('YYYY-MM-DD') : '' })
              }}
            />
          </Form.Item>
        </Col>
        <Col flex="none">
          <Form.Item label="Color" validateStatus={errors?.color ? 'error' : ''} help={errors?.color}>
            <ColorPicker
              color={formData.color}
              readOnly={readOnly}
              onChange={(color: string) => handleFormDataChange({ color })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        {isVisible('period_basis') && (
          <Col span={12}>
            <Form.Item
              label="Period basis"
              validateStatus={errors?.periodBasis ? 'error' : ''}
              help={errors?.periodBasis}
              tooltip={{
                title: 'Period basis cannot be changed once leave type is created',
                icon: (
                  <span>
                    <i className="fal fa-circle-info" />
                  </span>
                )
              }}
            >
              <SysOptions
                type="lve_period_basis"
                value={formData.periodBasis}
                readOnly={readOnly}
                disabled={sysColumns.includes('periodBasis') || !!data}
                onFilter={(value: KeyValue | undefined) =>
                  [LvePeriodBasis.calendar, LvePeriodBasis.custom].includes(value?.key || '')
                }
                onChange={(value: string) => handleFormDataChange({ periodBasis: value })}
              />
            </Form.Item>
          </Col>
        )}
        <Col span={12}>
          <Form.Item label="Paid leave" validateStatus={errors?.isPaid ? 'error' : ''} help={errors?.isPaid}>
            <Radio.Group
              value={formData.isPaid}
              readOnly={readOnly}
              disabled={sysColumns.includes('isPaid')}
              onChange={(event: RadioChangeEvent) => handleFormDataChange({ isPaid: event.target.value })}
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row hidden={formData.periodBasis !== LvePeriodBasis.custom} gutter={30}>
        {isVisible('period_start') && (
          <Col>
            <Form.Item
              label="Period start"
              validateStatus={errors?.periodStart ? 'error' : ''}
              help={errors?.periodStart}
            >
              <MonthKeyValues
                value={formData.periodStart}
                readOnly={readOnly}
                disabled={sysColumns.includes('periodStart')}
                onChange={(value: string) => handleFormDataChange({ periodStart: value })}
              />
            </Form.Item>
          </Col>
        )}
        {isVisible('period_duration') && (
          <Col flex={1}>
            <Form.Item
              label="Duration"
              validateStatus={errors?.periodDuration ? 'error' : ''}
              help={errors?.periodDuration}
            >
              <Space>
                <Input.Number
                  value={formData.periodDuration}
                  readOnly={readOnly}
                  disabled={sysColumns.includes('periodDuration')}
                  onChange={(value: number | null) => handleFormDataChange({ periodDuration: value })}
                ></Input.Number>
                <span>months</span>
              </Space>
            </Form.Item>
          </Col>
        )}
      </Row>
      <Row gutter={30} hidden={readOnly && !formData.parentId}>
        {isVisible('parent_id') && (
          <Col flex={1}>
            <Form.Item label="Parent" validateStatus={errors?.parentId ? 'error' : ''} help={errors?.parentId}>
              <LveKeyValues
                id="leaveType"
                queryParams={{ exceptionId: leaveTypeId, isEnabled: true }}
                value={formData.parentId}
                readOnly={readOnly}
                disabled={sysColumns.includes('parentId')}
                onChange={(value: string) => {
                  handleFormDataChange({ parentId: value })
                }}
              />
            </Form.Item>
          </Col>
        )}
      </Row>
    </>
  )
}
