import { request, getBaseUrl } from '~/utils/request'
import { EmRequestState } from '../types'

const baseUrl = getBaseUrl('/employee/employee/request')

export const apiGetEmRequests = (employeeId: string) => request<EmRequestState[]>('get', `${baseUrl}/${employeeId}`)

export const apiApproveEmRequest = (id: string, emInfoType: string) =>
  request('post', `${baseUrl}/${emInfoType}/${id}/approve`)

export const apiRejectEmRequest = (id: string, emInfoType: string) =>
  request('post', `${baseUrl}/${emInfoType}/${id}/reject`)
