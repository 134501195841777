import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchDailyPolicies } from '../actions'
import { DailyPolicyState } from '../types'
import { selectDailyPolicies } from '../selectors'

export const useDailyPolicies = (fetchStrategy: FetchStrategy = 'when-empty'): [DailyPolicyState[], boolean] => {
  const data = useSelector(selectDailyPolicies)
  const loading = useSelector((state: StoreState) => state.attendance.dailyPoliciesLoading)

  useEffect(() => {
    dispatch(fetchDailyPolicies({ strategy: fetchStrategy }))
  }, [fetchStrategy])

  return [data, loading]
}
