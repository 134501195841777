import React, { FC } from 'react'
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom'
import { LVE_PATH, LVE_ROUTES } from '~/routes/routes'
import { LeaveOverview } from './LeaveOverview/LeaveOverview'
import './LeaveLayout.less'

const routeList: RouteProps[] = [
  {
    path: LVE_ROUTES.tab,
    component: LeaveOverview,
    exact: true
  }
]

export const LeaveLayout: FC = () => {
  return (
    <div className="leave-layout">
      <Switch>
        <Redirect exact from={LVE_PATH} to={LVE_ROUTES.tab.replace(':tab?', 'entitlement')} />
        {routeList.map(route => (
          <Route key={route.path as string} {...route} />
        ))}
      </Switch>
    </div>
  )
}
