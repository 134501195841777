import { request, getBaseUrl, timeout, toQueryParams } from '~/utils/request'
import { IRptAttendanceRecord } from '../types'

const baseUrl = getBaseUrl('/attendance/rptattendancerecord')

export const apiDownloadAttendanceRecordExcel = (params: IRptAttendanceRecord) => {
  params.fieldIds = params.fieldIds?.filter(f => f !== '')

  return request('get', `${baseUrl}/excel`, toQueryParams(params), { responseType: 'blob', timeout })
}

export const apiDownloadAttendanceRecordPdf = (params: IRptAttendanceRecord) => {
  params.fieldIds = params.fieldIds?.filter(f => f !== '')

  return request('get', `${baseUrl}/pdf`, toQueryParams(params), { responseType: 'blob', timeout })
}
