import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { ICalendarAndPattern, WorkCalendarInfoState, WorkCalendarPatternState, WorkCalendarState } from '../types'

const baseUrl = getBaseUrl('/employee/calendar')

export const apiGetCalendars = () => request<WorkCalendarState[]>('get', baseUrl)

export const apiGetCalendar = (id: string) => request<WorkCalendarState>('get', `${baseUrl}/${id}`)

export const apiAddCalendar = (calendar: WorkCalendarInfoState) => request<{ id: string }>('post', baseUrl, calendar)

export const apiUpdateCalendar = (id: string, calendar: Operation[]) =>
  request<void>('patch', `${baseUrl}/${id}`, calendar)

export const apiDeleteCalendar = (id: string) => request<void>('delete', `${baseUrl}/${id}`)

export const apiGetCalendarPatterns = (calendarId: string) =>
  request<WorkCalendarPatternState[]>('get', `${baseUrl}/${calendarId}/pattern`)

export const apiSaveCalendarAndPatterns = (calendarAndPatterns: ICalendarAndPattern) =>
  request<{ id?: string; wdPerWeek: number; patterns?: WorkCalendarPatternState[] }>(
    'post',
    `${baseUrl}/add-with-patterns`,
    calendarAndPatterns
  )
