import { replaceCompanyOffices, setCompanyOfficesLoading } from '../reducers'
import { apiGetCompanyOffices } from '../api/company.api'
import { ThunkResult } from '~/types/store'
import { CompanyOfficeState } from '../types'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchCompanyOffices = (
  companyId: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!companyId) return

    if (options.strategy === 'when-empty') {
      const data = getState().master.companyOffices?.entities || {}
      if (Object.values(data).some(co => co?.companyId === companyId)) {
        return
      }
    }

    try {
      dispatch(setCompanyOfficesLoading(true))
      const { result, status } = await apiGetCompanyOffices(companyId)
      if (status) {
        let otherCompanyOffices = Object.values(getState().master.companyOffices?.entities || {}).filter(
          co => co?.companyId !== companyId
        ) as CompanyOfficeState[]
        dispatch(replaceCompanyOffices([...result, ...otherCompanyOffices]))
      }
    } finally {
      dispatch(setCompanyOfficesLoading(false))
    }
  }
}
