import React, { FC } from 'react'
import { ColumnsType, Drawer, Space, Table, Text } from '~/core-components'
import { UnitType, formatDateRange, formatNumberUnit } from '~/utils'
import { useSysOptions } from '~/features/employee'
import { useLeEarned, useLeaveType } from '../../hooks'
import { LeEarnedState } from '../../types'
import './LeEarnedDrawer.less'

interface LeEarnedDrawerProps {
  visible: boolean
  periodCode?: string
  employeeId?: string
  leaveTypeId?: string
  onClose: () => void
}

export const LeEarnedDrawer: FC<LeEarnedDrawerProps> = ({ visible, periodCode, employeeId, leaveTypeId, onClose }) => {
  const [data, loading] = useLeEarned(periodCode, employeeId, leaveTypeId)
  const [leaveType] = useLeaveType(leaveTypeId || '')
  const [prorationTypes] = useSysOptions('lve_proration_type')

  const columns: ColumnsType<LeEarnedState> = [
    {
      title: 'Entitlement',
      key: 'entitlement',
      dataIndex: 'entitlement',
      render: (entitlement: number, record) => <>{formatNumberUnit(entitlement, record.entitlementUnit as UnitType)}</>
    },
    {
      title: `Prorated ${(prorationTypes[leaveType?.prorationType || '']?.value || '').toLowerCase()}`,
      key: 'earnedBefRound',
      dataIndex: 'earnedBefRound',
      render: (earnedBefRound: number, record) => {
        return (
          <>
            <div>
              <Space>
                <b>{formatNumberUnit(earnedBefRound, leaveType?.unit as UnitType)}</b>
              </Space>
            </div>
            <Text size="small" block>
              {formatDateRange(record.startDate, record.endDate)}
            </Text>
          </>
        )
      }
    }
  ]

  return (
    <Drawer open={visible} title="Entitled policy" onClose={onClose} width="800px" className="le-earned-drawer">
      <Table rowKey="id" dataSource={data} pagination={false} columns={columns} loading={loading} />
    </Drawer>
  )
}
