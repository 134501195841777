import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiDeleteCalendar } from '../api/calendar.api'
import { removeCalendar } from '../reducers'
import { removeMaster } from '~/features/master'

export const deleteCalendar = (id: string): ThunkResult<void> => {
  return async dispatch => {
    if (!id) return

    const { status, errors, message, errorData } = await apiDeleteCalendar(id)
    if (status) {
      dispatch(removeCalendar(id))
      dispatch(removeMaster({ masterName: 'calendar', id }))
      showSuccess('Deleted')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
