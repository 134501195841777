import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import persistReducer from 'redux-persist/es/persistReducer'
import { mySchedulePersistConfig, MyScheduleRootState, ROScheduleRecordViewState } from './types'

const roScheduleRecordViewAdapter = createEntityAdapter<ROScheduleRecordViewState>({
  selectId: model => model.viewId
})

const roScheduleRecordInitialState = roScheduleRecordViewAdapter.getInitialState()

const initialState: MyScheduleRootState = {
  roScheduleRecordsView: roScheduleRecordInitialState,
  roScheduleRecordsViewLoading: false,
  roScheduleRecordsViewRefetch: 0,
  roScheduleRecordsViewRowRefetch: undefined,
  roScheduleRecordsViewCellRefetch: undefined
}

const myScheduleSlice = createSlice({
  name: 'myschedule',
  initialState,
  reducers: {
    refetchROScheduleRecordsView: state => {
      state.roScheduleRecordsViewRefetch += 1
    },
    refetchROScheduleRecordsViewRow: (state, action: PayloadAction<string | undefined>) => {
      state.roScheduleRecordsViewRowRefetch = action.payload
    },
    refetchROScheduleRecordsViewCell: (state, action: PayloadAction<string | undefined>) => {
      state.roScheduleRecordsViewCellRefetch = action.payload
    },
    setROScheduleRecordsViewLoading: (state, action: PayloadAction<boolean>) => {
      state.roScheduleRecordsViewLoading = action.payload
    },
    setROScheduleRecordsView: (state, action: PayloadAction<ROScheduleRecordViewState>) => {
      roScheduleRecordViewAdapter.upsertOne(state.roScheduleRecordsView, action.payload)
    }
  }
})

export const {
  refetchROScheduleRecordsView,
  refetchROScheduleRecordsViewRow,
  refetchROScheduleRecordsViewCell,
  setROScheduleRecordsViewLoading,
  setROScheduleRecordsView
} = myScheduleSlice.actions

export const myScheduleReducers = {
  mySchedule: persistReducer<MyScheduleRootState>(mySchedulePersistConfig, myScheduleSlice.reducer)
}
