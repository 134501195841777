import React, { CSSProperties, FC, HTMLAttributes, useCallback, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import confirm from 'antd/lib/modal/confirm'
import { Alert, Form, Input, Radio, RadioChangeEvent } from '~/core-components'
import { Col, DrawerForm, Row, SalaryInput } from '~/components'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors } from '~/types/store'
import { useFocus } from '~/hooks'
import { addShiftTe, updateShiftTe, deleteShiftTe } from '../../../actions'
import { useTeConfigsForShiftRole } from '../../../hooks'
import { IShiftTeInfo, ShiftTeState, mapShiftTeStateToShiftTeInfo } from '../../../types'

interface MutateShiftTeDrawerProps {
  visible: boolean
  shiftId?: string
  data?: ShiftTeState
  isCurrent: boolean
  onClose: () => void
}

const TODAY = moment().format('YYYY-MM-DD')
const EMPTY_FORM_DATA: IShiftTeInfo = {
  startDate: TODAY,
  te01: 0,
  te02: 0,
  te03: 0,
  te04: 0,
  te05: 0,
  te06: 0,
  te07: 0,
  te08: 0,
  te09: 0,
  te10: 0
}

const radioStyle: CSSProperties = { width: '100%' }
const radioItemStyle: CSSProperties = { marginTop: 20 }
const formStyle: CSSProperties = { marginLeft: 24, marginTop: 10 }
const alertStyle: CSSProperties = { marginBottom: 15 }

export const MutateShiftTeDrawer: FC<MutateShiftTeDrawerProps> = ({ visible, shiftId, data, isCurrent, onClose }) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<IShiftTeInfo>(EMPTY_FORM_DATA)
  const [newFormData, setNewFormData] = useState<IShiftTeInfo>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const [isNew, setIsNew] = useState(isCurrent ? true : false)

  useEffect(() => {
    setIsNew(isCurrent ? true : false)
    setErrors(undefined)
  }, [visible, isCurrent])

  useEffect(() => {
    if (visible && data) setFormData(mapShiftTeStateToShiftTeInfo(data))
  }, [visible, data])

  const handleRadioChange = useCallback((event: RadioChangeEvent) => {
    setIsNew(event.target.value)
  }, [])

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      if (isNew || data == null) {
        if (shiftId) {
          const submit = isNew ? newFormData : formData
          result = await dispatch(addShiftTe(shiftId, submit))
        }
      } else {
        if (data) {
          result = await dispatch(updateShiftTe(data.shiftId, data.id, mapShiftTeStateToShiftTeInfo(data), formData))
        }
      }
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_FORM_DATA)
    }
  }, [isNew, shiftId, data, newFormData, formData, onClose])

  const handleDelete = useCallback(
    (shiftTe: ShiftTeState | undefined) => {
      if (shiftId && shiftTe) {
        const { id } = shiftTe

        confirm({
          title: 'Delete time element',
          content: `Do you want to delete this time element rate?`,
          onOk: async () => {
            const result: ActionResult | undefined = await dispatch(deleteShiftTe(shiftId, id))
            if (result?.errors) {
              setErrors(result.errors)
            }

            if (!result?.errors) {
              typeof onClose === 'function' && onClose()
            }
          },
          okText: 'Delete',
          okType: 'danger'
        })
      }
    },
    [shiftId, onClose]
  )

  return (
    <DrawerForm
      open={visible}
      title="Time elements"
      onClose={onClose}
      confirmLoading={loading}
      width={500}
      showDelete={isNew || data == null ? false : true}
      onDelete={() => handleDelete(data)}
      formId="form-shift-te"
    >
      <Form id="form-shift-te" onFinish={handleOk}>
        {isCurrent ? (
          <Radio.Group value={isNew} onChange={handleRadioChange} style={radioStyle}>
            <Radio value={true}>Transfer to new time elements</Radio>
            <ShiftTeForm
              errors={isNew ? errors : undefined}
              labelPrefix="New"
              style={formStyle}
              disabled={!isNew}
              onFormChange={data => setNewFormData({ ...newFormData, ...data })}
            />
            <Radio value={false} style={radioItemStyle}>
              Or make amendment to current time elements
            </Radio>
            <ShiftTeForm
              data={data}
              errors={!isNew ? errors : undefined}
              labelPrefix="Current"
              style={formStyle}
              disabled={isNew}
              onFormChange={data => setFormData({ ...formData, ...data })}
            />
          </Radio.Group>
        ) : (
          <>
            {data != null && (
              <Alert
                message="Any changes to this historical record will not impact past transactions."
                banner
                style={alertStyle}
              />
            )}
            <ShiftTeForm data={data} errors={errors} onFormChange={data => setFormData({ ...formData, ...data })} />
          </>
        )}
      </Form>
    </DrawerForm>
  )
}

interface ShiftTeFormProps extends HTMLAttributes<HTMLDivElement> {
  data?: ShiftTeState
  errors?: Errors
  labelPrefix?: string
  disabled?: boolean
  onFormChange: (data: IShiftTeInfo) => void
}

const ShiftTeForm: FC<ShiftTeFormProps> = ({ data, errors, labelPrefix, onFormChange, style, disabled = false }) => {
  const [formData, setFormData] = useState<IShiftTeInfo>(EMPTY_FORM_DATA)
  const [focusRef, setFocus] = useFocus(true)
  const effectiveDateLabel = 'Effective date'
  const [teConfigs] = useTeConfigsForShiftRole()

  useEffect(() => {
    setTimeout(() => !disabled && setFocus(), 100)
  }, [disabled, setFocus])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      const updated = { ...formData, ...updates }
      setFormData(updated)
      typeof onFormChange === 'function' && onFormChange(updated)
    },
    [formData, onFormChange]
  )

  useEffect(() => {
    if (data) {
      const { startDate, te01, te02, te03, te04, te05, te06, te07, te08, te09, te10 } = data
      setFormData({ startDate, te01, te02, te03, te04, te05, te06, te07, te08, te09, te10 })
    } else {
      setFormData({ ...EMPTY_FORM_DATA, startDate: TODAY })
    }
  }, [data])

  const getLabel = useCallback(
    (prefix: string | undefined, label: string) => (prefix ? `${prefix} ${label.toLowerCase()}` : label),
    []
  )

  return (
    <div style={style}>
      <Row>
        <Col span={24}>
          <Form.Item
            label={getLabel(labelPrefix, effectiveDateLabel)}
            validateStatus={errors?.startDate ? 'error' : ''}
            help={errors?.startDate}
          >
            <Input.Date
              ref={focusRef}
              value={formData.startDate ? moment(formData.startDate) : undefined}
              onChange={(value: moment.Moment | null) =>
                handleFormDataChange({ startDate: value?.format('YYYY-MM-DD') })
              }
              disabled={disabled}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        {teConfigs.map(teConfig => (
          <Col span={12} key={teConfig.code}>
            <Form.Item
              label={getLabel(labelPrefix, teConfig.name)}
              validateStatus={errors && errors[teConfig.code] ? 'error' : ''}
              help={errors && errors[teConfig.code]}
            >
              <SalaryInput
                value={formData[teConfig.code as keyof IShiftTeInfo] as number}
                onChange={(value: number | null) => handleFormDataChange({ [teConfig.code]: value })}
                disabled={disabled}
              />
            </Form.Item>
          </Col>
        ))}
      </Row>
    </div>
  )
}
