import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchOedRecordView } from '../actions'
import { OedRecordViewState } from '../types'

export const useOedRecordView = (
  oedSubmissionId: string,
  viewId: string,
  page: number,
  pageSize: number,
  search: string,
  fetchStrategy: FetchStrategy = 'when-empty'
): [OedRecordViewState | undefined, boolean] => {
  const data = useSelector((state: StoreState) => state.report.oedRecordsView?.entities[viewId])
  const loading = useSelector((state: StoreState) => state.report.oedRecordsViewLoading)
  const refetch = useSelector((state: StoreState) => state.report.oedRecordsViewRefetch)

  useEffect(() => {
    if (viewId)
      dispatch(
        fetchOedRecordView(oedSubmissionId, viewId, { offset: pageSize * (page - 1), limit: pageSize }, search, {
          strategy: fetchStrategy
        })
      )
  }, [oedSubmissionId, viewId, page, pageSize, search, refetch, fetchStrategy])

  return [data, loading]
}
