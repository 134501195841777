import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { ErrorDisplay } from '~/components'
import { ColumnsType, Descriptions, Table } from '~/core-components'
import { Errors, StoreState } from '~/types/store'
import { EmployeeImportValidateReportState, EmployeeImportValidateSummaryState } from '../../../../../types'
import './ValidationEmployeeExcel.less'

interface ValidationEmployeeExcelProps {
  visible: boolean
  summary?: EmployeeImportValidateSummaryState
}

export const ValidationEmployeeExcel: FC<ValidationEmployeeExcelProps> = ({ visible, summary }) => {
  const report = useSelector((state: StoreState) => state.employee.employeeImport?.validation.invalidData || [])
  const errors: Errors = { '*': ['No row to import'] }

  const columns: ColumnsType<EmployeeImportValidateReportState> = [
    {
      title: 'Employee no',
      key: 'employeeNo',
      dataIndex: 'employeeNo',
      width: '20%'
    },
    {
      title: 'Employee name',
      key: 'fullName',
      dataIndex: 'fullName',
      width: '40%'
    },
    {
      title: 'Error description',
      key: 'invalidDescription',
      dataIndex: 'invalidDescription',
      width: '40%'
    }
  ]

  return (
    <div className="employee-import-validation-report" hidden={!visible}>
      <Descriptions bordered>
        <Descriptions.Item label="Total row(s)">{summary?.total || 0}</Descriptions.Item>
        <Descriptions.Item label="Skipped">{summary?.skip || 0}</Descriptions.Item>
        <Descriptions.Item label={<b>To import</b>}>
          <b>{summary?.proceed || 0}</b>
        </Descriptions.Item>
      </Descriptions>
      <ErrorDisplay errors={(summary?.proceed || 0) === 0 ? errors : undefined} />
      {report.length > 0 && <Table rowKey="id" dataSource={report} pagination={false} columns={columns} />}
    </div>
  )
}
