import React, { FC, useCallback, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { useSelector } from 'react-redux'
import { Alert, Button, Form, Input, Link, Select } from '~/core-components'
import { Col, EditableCard, EditableCardState, InactiveTag, InfoTooltip, Row } from '~/components'
import { usePermissionGate } from '~/features/iam/hooks'
import { ClaPaymentMethod, emptyGuid, PayItemCategory, PayItemType, Permission, PermissionAction } from '~/constants'
import { useClaimTypesDict } from '~/features/claim'
import { PayItemName } from '~/features/master'
import { usePayClaimMaps } from '~/features/payroll'
import { dispatch } from '~/stores/store'
import { Errors, ActionResult } from '~/types/store'
import { isInactive } from '~/utils'
import { updatePayClaimMaps } from '../../../actions'
import { selectPayItems } from '../../../selectors'
import { PayClaimMapState } from '../../../types'
import './PayClaimMap.less'

export interface PayClaimMapProps {
  payGroupId: string
}

const RELEVANT_ERRORS = ['claimTypeId', 'payItemId']

interface FormData {
  claimTakens: PayClaimMapState[]
}

const EMPTY_FORM_DATA: FormData = {
  claimTakens: []
}

export const PayClaimMap: FC<PayClaimMapProps> = ({ payGroupId }) => {
  const [cardState, setCardState] = useState<EditableCardState>()
  const canModify = usePermissionGate(Permission.payMaster, PermissionAction.Modify)
  const readOnly = cardState !== 'editing'
  const [errors, setErrors] = useState<Errors>()
  const relevantErrors = Object.keys(errors || {}).filter(e => RELEVANT_ERRORS.includes(e))
  const [claimTypes] = useClaimTypesDict()
  const [payClaimMaps] = usePayClaimMaps(payGroupId)
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)

  const payrollClaimTypes = Object.values(claimTypes).filter(
    ct =>
      (!isInactive(ct?.inactiveDate) || payClaimMaps.filter(pcm => pcm.claimTypeId === ct?.id).length > 0) &&
      ct?.paymentMethod === ClaPaymentMethod.payroll
  )
  const additionPayItems = useSelector(selectPayItems).filter(
    pi =>
      (!isInactive(pi?.inactiveDate) || payClaimMaps.filter(pcm => pcm.payItemId === pi?.id).length > 0) &&
      pi?.category === PayItemCategory.others &&
      pi?.type === PayItemType.addition
  )

  const NEW_PAY_CLAIM_MAP: PayClaimMapState[] = [
    {
      id: emptyGuid,
      payGroupId: payGroupId,
      claimTypeId: '',
      payItemId: ''
    }
  ]

  const mapToState = (formData: FormData): PayClaimMapState[] => {
    return [...formData.claimTakens]
  }

  useEffect(() => {
    if (payClaimMaps.length > 0) {
      setFormData(mapToFormData(payClaimMaps))
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [payClaimMaps])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setErrors(undefined)
    setFormData(data => ({ ...data, ...updates }))
  }, [])

  const handleEdit = useCallback(() => {
    setCardState('editing')
  }, [])

  const handleSave = useCallback(async () => {
    if (payGroupId) {
      setCardState('saving')
      setErrors(undefined)

      let result: ActionResult | undefined
      try {
        result = await dispatch(updatePayClaimMaps(payGroupId, mapToState(formData)))
      } catch {
        setCardState('editing')
      }

      if (result?.errors) {
        setCardState('editing')
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setCardState(undefined)
      }
    }
  }, [payGroupId, formData])

  const handleCancel = useCallback(() => {
    setCardState(undefined)
    setErrors(undefined)

    if (payClaimMaps.length > 0) {
      setFormData(mapToFormData(payClaimMaps))
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [payClaimMaps])

  return (
    <EditableCard
      className="pay-claim-map"
      title="Claim taken"
      bodyStyle={{ paddingBottom: 6, paddingTop: 6 }}
      state={canModify ? cardState : 'readonly'}
      onEdit={handleEdit}
      onSave={handleSave}
      onCancel={handleCancel}
    >
      <Row>
        <Col span={24}>
          {errors && relevantErrors.length > 0 && (
            <Alert
              className="pay-claim-map__error"
              type="error"
              message={
                <>
                  {relevantErrors.map((error, index) => (
                    <div key={index}>{errors[error]}</div>
                  ))}
                </>
              }
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {formData.claimTakens.map((ctk, index) => (
            <Row gutter={6} key={index} className="pay-claim-map__row">
              <Col span={9}>
                {index === 0 && <div className="pay-claim-map__row-title">Claim type</div>}
                <Select
                  id="claimType"
                  value={ctk.claimTypeId}
                  showSearch
                  allowClear={false}
                  readOnly={readOnly}
                  optionFilterProp="title"
                  dropdownMatchSelectWidth={false}
                  onChange={(value: string) =>
                    handleFormDataChange({
                      claimTakens: [
                        ...formData.claimTakens.slice(0, index),
                        { ...formData.claimTakens[index], claimTypeId: value },
                        ...formData.claimTakens.slice(index + 1, formData.claimTakens.length)
                      ]
                    })
                  }
                >
                  {payrollClaimTypes.map(ct => {
                    return (
                      <Select.Option key={ct?.id} value={ct?.id || ''} title={ct?.name}>
                        {isInactive(ct!.inactiveDate) && <InactiveTag />}
                        {ct?.name}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Col>
              <Col span={11}>
                {index === 0 && <div className="pay-claim-map__row-title">Payroll item</div>}
                <Select
                  id="payItem"
                  value={ctk.payItemId}
                  showSearch
                  readOnly={readOnly}
                  optionFilterProp="title"
                  dropdownMatchSelectWidth={false}
                  onChange={(value: string) =>
                    handleFormDataChange({
                      claimTakens: [
                        ...formData.claimTakens.slice(0, index),
                        { ...formData.claimTakens[index], payItemId: value },
                        ...formData.claimTakens.slice(index + 1, formData.claimTakens.length)
                      ]
                    })
                  }
                >
                  {additionPayItems.map(api => {
                    return (
                      <Select.Option key={api?.id} value={api?.id || ''} title={api?.name}>
                        {isInactive(api!.inactiveDate) && <InactiveTag />}
                        <PayItemName id={api!.id} />
                      </Select.Option>
                    )
                  })}
                </Select>
              </Col>
              <Col span={3}>
                {index === 0 && (
                  <div className="pay-claim-map__row-title">
                    Start date
                    <InfoTooltip title="Only the claim records occurring from start date will be sent to payroll." />
                  </div>
                )}
                <Input.Date
                  inputReadOnly={readOnly}
                  value={ctk.startDate ? moment(ctk.startDate) : undefined}
                  onChange={(value: moment.Moment | null) => {
                    handleFormDataChange({
                      claimTakens: [
                        ...formData.claimTakens.slice(0, index),
                        { ...formData.claimTakens[index], startDate: value?.format('YYYY-MM-DD') },
                        ...formData.claimTakens.slice(index + 1, formData.claimTakens.length)
                      ]
                    })
                  }}
                />
              </Col>
              <Col span={1} style={{ whiteSpace: 'nowrap' }}>
                <Button
                  size="small"
                  type="text"
                  icon={<i className="fal fa-xmark" />}
                  hidden={readOnly}
                  onClick={() =>
                    handleFormDataChange({
                      claimTakens: [
                        ...formData.claimTakens.slice(0, index),
                        ...formData.claimTakens.slice(index + 1, formData.claimTakens.length)
                      ]
                    })
                  }
                />
              </Col>
            </Row>
          ))}
          <Form.Item hidden={readOnly}>
            <Link
              onClick={() => handleFormDataChange({ claimTakens: [...formData.claimTakens, ...NEW_PAY_CLAIM_MAP] })}
            >
              add more
            </Link>
          </Form.Item>
        </Col>
      </Row>
    </EditableCard>
  )
}

const mapToFormData = (dataTakens: PayClaimMapState[]): FormData => ({
  claimTakens: dataTakens
})
