import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { clearSysSelectionFields, refetchSysSelectionFields } from '~/features/selection'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { ITeConfig, TeConfigState } from '../types'
import { apiUpdateTeConfig } from '../api/te-config.api'
import { setTeConfig } from '../reducers'

export const updateTeConfig = (code: string, before: ITeConfig, request: ITeConfig): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!code) return

    const after = { ...before, ...request } as ITeConfig
    const patch = generateJsonPatch<ITeConfig>(before, after)
    const { status, errors, message, errorData } = await apiUpdateTeConfig(code, patch)

    if (status) {
      const teConfig = getState().attendance.teConfigs?.entities[code]
      const updated: TeConfigState = { ...(teConfig as TeConfigState), ...after }
      dispatch(setTeConfig(updated))
      dispatch(clearSysSelectionFields())
      dispatch(refetchSysSelectionFields())
      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
