import { Operation } from 'fast-json-patch'
import { request, getBaseUrl, timeout } from '~/utils'
import { EmSelectState } from '~/components'
import { EmSelectionViewState } from '~/features/employee'
import { Pagination, Search } from '~/types/common'
import {
  CePolicyState,
  ClaimEntitlementState,
  ClaimEntitlementViewState,
  EntitledClaimTypeState,
  IAddClaimEntitlement
} from '../types'

const baseUrl = getBaseUrl('/claim/claimentitlement')

export const apiGetClaimEntitlementView = (viewId: string, periodYear: number, params: Pagination & Search) =>
  request<ClaimEntitlementViewState>('get', `${baseUrl}/view/${viewId}/${periodYear}`, params)

export const apiGetClaimEntitlements = (periodYear: number, employeeId: string) =>
  request<ClaimEntitlementState[]>('get', `${baseUrl}/${periodYear}/${employeeId}`)

export const apiGetClaimEntitlementExcel = (viewId: string, periodYear: number, params: Search) =>
  request('get', `${baseUrl}/view/${viewId}/${periodYear}/excel`, params, { responseType: 'blob', timeout })

export const apiGetCePolicies = (periodCode: string, employeeId: string, claimTypeId: string) =>
  request<CePolicyState[]>('get', `${baseUrl}/${periodCode}/${employeeId}/${claimTypeId}/policy`)

export const apiGetEntitledClaimTypes = (employeeId: string) =>
  request<EntitledClaimTypeState[]>('get', `${baseUrl}/${employeeId}/claimtypes`)

export const apiAddClaimEntitlement = (claimEntitlement: IAddClaimEntitlement) =>
  request<void>('post', baseUrl, claimEntitlement)

export const apiUpdateClaimEntitlement = (id: string, updates: Operation[]) =>
  request('patch', `${baseUrl}/${id}`, updates)

export const apiDeleteClaimEntitlement = (id: string) => request<void>('delete', `${baseUrl}/${id}`)

export const apiGetClaimEntitlementEmSelections = (claimTypeId: string, viewId: string, params: Pagination & Search) =>
  request<EmSelectionViewState>('get', `${baseUrl}/${claimTypeId}/emselections/${viewId}`, params)

export const apiGetClaimEntitlementEmSelect = (year: number) =>
  request<EmSelectState[]>('get', `${baseUrl}/${year}/emselect`)
