import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { PayslipState } from '../types'

export const selectPayslip = createSelector(
  (state: StoreState) => state.payroll.payslip,
  payslipState =>
    memoize(
      (payRunId: string, employeeId: string): PayslipState | undefined => {
        const payslips = Object.values(payslipState?.entities || {}) as PayslipState[]
        return payslips.find(rec => rec.employeeId === employeeId && rec.payRunId === payRunId)
      },
      (payRunId: string, employeeId: string) => `${payRunId}|${employeeId}`
    )
)
