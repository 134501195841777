import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, DateRange, FetchOptions, Pagination } from '~/types/common'
import { setROLeaveRecordsView, setROLeaveRecordsViewLoading } from '../../reducers'
import { apiGetROLeaveRecordView } from '../../api/roleave-record.api'

export const fetchROLeaveRecordsView = (
  viewId: string,
  role: string,
  startDate: string | null,
  endDate: string | null,
  pagination: Pagination,
  search: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!viewId || !role) return

    const loading = getState().my.roLeaveRecordsViewLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().my.roLeaveRecordsView?.entities[viewId]
      if (hasData) return
    }

    try {
      dispatch(setROLeaveRecordsViewLoading(true))
      const { result, status } = await apiGetROLeaveRecordView(viewId, role, {
        ...({ startDate, endDate } as DateRange),
        ...pagination,
        search
      })
      if (status) {
        dispatch(setROLeaveRecordsView(result))
      }
    } finally {
      dispatch(setROLeaveRecordsViewLoading(false))
    }
  }
}
