import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { EmploymentConfigState } from '../types'
import { FetchStrategy } from '~/types/common'
import { selectEmploymentConfigs } from '../selectors'
import { fetchEmploymentConfigs } from '../actions'

export const useEmploymentConfigs = (fetchStrategy: FetchStrategy = 'always'): [EmploymentConfigState[], boolean] => {
  const data = useSelector(selectEmploymentConfigs)
  const loading = useSelector((state: StoreState) => state.master.employmentConfigsLoading)

  useEffect(() => {
    dispatch(fetchEmploymentConfigs({ strategy: fetchStrategy }))
  }, [fetchStrategy])

  return [data, loading]
}
