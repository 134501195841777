import { ChangeEvent, FC, useCallback, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { EditOutlined } from '@ant-design/icons'
import confirm from 'antd/lib/modal/confirm'
import { Button, Form, Input, PageHeader, SecondaryLink, Space } from '~/core-components'
import { DocumentTitle } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { ATT_ROUTES } from '~/routes/routes'
import { formatDateRange } from '~/utils'
import { dispatch } from '~/stores/store'
import { ActionResult } from '~/types/store'
import { AttendancePeriodStatus, Permission, PermissionAction } from '~/constants'
import { useAttendancePeriod } from '../../hooks'
import { refetchAttendancePeriodsView } from '../../reducers'
import { completeAttendancePeriod, deleteAttendancePeriod, revertToDraftAttendancePeriod } from '../../actions'
import { AttendancePeriodState } from '../../types'
import { EditAttendancePeriodDrawer } from './components/EditAttendancePeriodDrawer'
import { DailySummary } from '../DailySummary/DailySummary'
import './AttendancePeriod.less'

interface AttendancePeriodProps {}

interface AttendancePeriodParams {
  id: string
}

interface DrawerState {
  visible: boolean
  data?: AttendancePeriodState
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

const routes = [
  {
    path: ATT_ROUTES.tab.replace(':tab?', 'period'),
    breadcrumbName: 'Monthly'
  },
  {
    path: '',
    breadcrumbName: 'Records'
  }
]

export const AttendancePeriod: FC<AttendancePeriodProps> = () => {
  const { id } = useParams<AttendancePeriodParams>()
  const [attendancePeriod] = useAttendancePeriod(id, 'always')
  const [updating, setUpdating] = useState(false)
  const history = useHistory()
  const canModify = usePermissionGate(Permission.attDailyRecord, PermissionAction.Modify)
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)

  const handleEditAttendancePeriod = useCallback(() => {
    setDrawerState({ visible: true, data: attendancePeriod })
  }, [attendancePeriod])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const handleDeleteAttendancePeriod = useCallback(() => {
    const modal = confirm({
      title: 'Delete attendance period',
      content: (
        <>
          <div>Do you want to delete attendance period "{attendancePeriod?.name || ''}"?</div>
          <br />
          <div>This will also delete the daily records.</div>
          <Form>
            <Form.Item
              className="payrun__confirm-delete"
              label={
                <>
                  Type "<b>{attendancePeriod?.name}</b>" to confirm
                </>
              }
            >
              <Input
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  modal.update({ okButtonProps: { disabled: event.target.value !== attendancePeriod?.name } })
                }}
              />
            </Form.Item>
          </Form>
        </>
      ),
      onOk: async () => {
        const result: ActionResult | undefined = await dispatch(deleteAttendancePeriod(id))
        if (!result?.errors) {
          history.push(ATT_ROUTES.tab.replace(':tab?', 'period'))
        }
      },
      width: 550,
      okText: 'Delete',
      okType: 'danger',
      okButtonProps: {
        disabled: true
      }
    })
  }, [id, attendancePeriod, history])

  const handleCompleteAttendancePeriod = useCallback(async () => {
    setUpdating(true)
    try {
      await dispatch(completeAttendancePeriod(id))
    } finally {
      setUpdating(false)
    }
  }, [id])

  const handleRevertToDraftAttendancePeriod = useCallback(async () => {
    setUpdating(true)
    try {
      await dispatch(revertToDraftAttendancePeriod(id))
      dispatch(refetchAttendancePeriodsView())
    } finally {
      setUpdating(false)
    }
  }, [id])

  return (
    <div className="attendance-period">
      <DocumentTitle title="Attendance Period" />
      <PageHeader
        title={
          <div className="attendance-period__header">
            <div className="attendance-period__header-title">{attendancePeriod?.name}</div>
            <div>
              Period {formatDateRange(attendancePeriod?.startDate, attendancePeriod?.endDate)}
              <SecondaryLink className="attendance-period__header-edit" onClick={handleEditAttendancePeriod}>
                <EditOutlined />
              </SecondaryLink>
            </div>
          </div>
        }
        breadcrumb={{ routes }}
        extra={
          <Space>
            {canModify ? (
              <Button size="small" onClick={handleDeleteAttendancePeriod}>
                Delete attendance period
              </Button>
            ) : null}
            {attendancePeriod?.status === AttendancePeriodStatus.Draft && canModify && (
              <Button type="primary" size="small" onClick={handleCompleteAttendancePeriod} loading={updating}>
                Mark as complete
              </Button>
            )}
            {attendancePeriod?.status === AttendancePeriodStatus.Completed && canModify && (
              <Button size="small" onClick={handleRevertToDraftAttendancePeriod} loading={updating}>
                Revert completion
              </Button>
            )}
          </Space>
        }
      />
      <div className="attendance-period__body">
        <DailySummary attendancePeriodId={id} />
      </div>
      <EditAttendancePeriodDrawer {...drawerState} onClose={handleCloseDrawer} />
    </div>
  )
}
