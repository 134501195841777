import { request, getBaseUrl } from '~/utils/request'
import { ApprovalTargetState } from '../types'

const baseUrl = getBaseUrl('/employee/approvaltarget')

export const apiGetTargets = (emailExpr: string, excludeSource?: string[]) =>
  request<ApprovalTargetState[]>('get', baseUrl, { emailExpr, excludeSource })

export const apiSearchTargets = (search: string, excludeSource?: string[]) =>
  request<ApprovalTargetState[]>('get', `${baseUrl}/search`, { search, excludeSource })
