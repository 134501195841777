import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { apiUpdateEmSalary } from '../api/em-salary.api'
import { EmSalaryState, IEmSalaryInfo } from '../types'
import { setEmSalary, setEmployee } from '../reducers'

export const updateEmSalary = <T extends IEmSalaryInfo>(
  employeeId: string,
  id: string,
  before: T,
  request: T
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!employeeId || !id) return

    const after = { ...before, ...request } as T
    const patch = generateJsonPatch<T>(before, after)
    const { result, status, errors, message, errorData } = await apiUpdateEmSalary(employeeId, id, patch)

    if (status) {
      if (result.emSalaryId) {
        const employee = getState().employee.employees.entities[employeeId]
        if (employee) {
          dispatch(setEmployee({ ...employee, emSalaryId: result.emSalaryId }))
        }
      }

      const emSalary = getState().employee.emSalaries[employeeId]?.entities[id]
      dispatch(
        setEmSalary({
          employeeId,
          data: {
            ...(emSalary as EmSalaryState),
            ...after
          }
        })
      )

      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
