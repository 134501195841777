import { replacePayLeaveMaps, setPayLeaveMapsLoading } from '../reducers'
import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiUpdatePayLeaveMaps } from '../api/pay-leave-map.api'
import { PayLeaveMapState } from '../types'

export const updatePayLeaveMaps = (payGroupId: string, data: PayLeaveMapState[]): ThunkResult<void> => {
  return async dispatch => {
    if (!payGroupId) return

    try {
      dispatch(setPayLeaveMapsLoading({ id: payGroupId, loading: true }))
      const { status, result, errors, message, errorData } = await apiUpdatePayLeaveMaps(payGroupId, data)

      if (status) {
        dispatch(replacePayLeaveMaps({ id: payGroupId, data: result }))
        showSuccess('Saved')
      } else {
        if (Object.keys(errors).length === 0) {
          showError(message, errorData)
        }
      }
      return { errors }
    } finally {
      dispatch(setPayLeaveMapsLoading({ id: payGroupId, loading: false }))
    }
  }
}
