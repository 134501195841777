import { request, getBaseUrl } from '~/utils/request'
import { PayDailyMapState } from '../types'

const baseUrl = getBaseUrl('/payroll-sg')

export const apiGetPayDailyMaps = (payGroupId: string) =>
  request<PayDailyMapState[]>('get', `${baseUrl}/paygroup/${payGroupId}/paydailymap`)

export const apiUpdatePayDailyMaps = (payGroupId: string, data: PayDailyMapState[]) =>
  request<PayDailyMapState[]>('post', `${baseUrl}/paygroup/${payGroupId}/paydailymap`, data)
