import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { GroupChildren } from '~/types/common'
import { StoreState } from '~/types/store'
import { EmDocumentState } from '../types'

export const selectEmDocuments = createSelector(
  (state: StoreState) => state.employee.emDocuments,
  emDocumentsState =>
    memoize((employeeId?: string) => {
      if (!employeeId) return []

      const map = new Map<string, GroupChildren<EmDocumentState>>()
      const emDocuments = Object.values(emDocumentsState[employeeId]?.entities || {}).sort((a, b) => {
        const aType = a!.docTypeId
        const bType = b!.docTypeId
        const aDate = new Date(a!.date).getTime()
        const bDate = new Date(b!.date).getTime()

        if (aType === bType) {
          return bDate - aDate
        } else {
          return bType > aType ? -1 : 1
        }
      })

      emDocuments.forEach(item => {
        if (item) {
          const {
            id,
            name,
            date,
            employeeId,
            docTypeId,
            notes,
            docFileId,
            docFileName,
            docFileSize,
            docFileFormat,
            docFileExtension
          } = item
          map.has(docTypeId) || map.set(docTypeId, { id: `group-${id}`, name: docTypeId, children: [] })
          map.get(docTypeId)?.children.push({
            id,
            name,
            date,
            employeeId,
            docTypeId,
            notes,
            docFileId,
            docFileName,
            docFileSize,
            docFileFormat,
            docFileExtension
          })
        }
      })

      const result = [...map.values()]
      return result
    })
)
