import { ActionResult, ThunkResult } from '~/types/store'
import { showSuccess, showError } from '~/utils'
import { fetchEmployee } from '..'
import { apiCopyEmployee } from '../api/employee.api'
import { ICopyEmployee } from '../types'

export const copyEmployee = (employee: ICopyEmployee): ThunkResult<void> => {
  return async (dispatch): Promise<ActionResult> => {
    const { status, result, errors, message, errorData } = await apiCopyEmployee(employee)
    if (status) {
      dispatch(fetchEmployee(result.id))
      showSuccess('Copied')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
