import { setDailyPolicyRowsLoading } from '../reducers'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'
import { apiGetDailyPolicyRow } from '../api/daily-policy.api'
import { setDailyPolicyRow } from '../reducers'

export const fetchDailyPolicyRow = (
  dailyPolicyId?: string,
  dailyPolicyRowId?: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!dailyPolicyId || !dailyPolicyRowId) return

    const loading = getState().attendance.dailyPolicyRowsLoading[dailyPolicyId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().attendance.dailyPolicyRows[dailyPolicyId]?.entities
      if (hasData) return
    }

    try {
      dispatch(setDailyPolicyRowsLoading({ dailyPolicyId, loading: true }))
      const { result, status } = await apiGetDailyPolicyRow(dailyPolicyId, dailyPolicyRowId)
      if (status) {
        dispatch(setDailyPolicyRow({ dailyPolicyId, data: result }))
      }
    } finally {
      dispatch(setDailyPolicyRowsLoading({ dailyPolicyId, loading: false }))
    }
  }
}
