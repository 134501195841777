import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiSaveDelegation } from '../api/delegation.api'
import { IDelegation } from '../types'
import { fetchDelegation } from './fetch-delegation'
import { emptyGuid } from '~/constants'

export const saveDelegation = (request: IDelegation): ThunkResult<void> => {
  return async dispatch => {
    request = { ...request, id: request.id || emptyGuid, employeeId: request.employeeId || emptyGuid }
    const { status, result, errors, message, errorData } = await apiSaveDelegation(request)

    if (status) {
      const id = result?.id || request.id
      if (id) {
        dispatch(fetchDelegation(id))
      }

      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
