import React, { FC, memo, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Card, Descriptions, Link, Space, Tag } from '~/core-components'
import { SSEmPublicSelfState } from '~/features/my'
import { dispatch } from '~/stores/store'
import { fetchEmPublic, useSysOptions } from '~/features/employee'
import { EditMyPersonalDrawer } from './EditMyPersonalDrawer'
import { fetchEmpKeyvalues, selectKeyvaluesById, selectRaceKeyvalues, selectReligionKeyvalues } from '~/features/master'

interface MyPersonalInfoProps {
  data: SSEmPublicSelfState
  hideSelfUpdate: boolean
}

interface DrawerState {
  visible: boolean
  data?: SSEmPublicSelfState
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

const PendingTag = memo(() => <Tag type="secondary">pending</Tag>)

export const MyPersonalInfo: FC<MyPersonalInfoProps> = ({ data, hideSelfUpdate }) => {
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const [genders] = useSysOptions('gender')
  const [maritalStatuses] = useSysOptions('marital_status')
  const races = useSelector(selectRaceKeyvalues)
  const religions = useSelector(selectReligionKeyvalues)
  const nationalities = useSelector(selectKeyvaluesById)('nationality')

  const isDiffBirthDate = data.birthDate !== data.birthDateNew
  const isDiffGender = data.gender !== data.genderNew
  const isDiffMaritalStatus = data.maritalStatus !== data.maritalStatusNew
  const isDiffMarriageDate = data.marriageDate !== data.marriageDateNew
  const isDiffRaceId = data.raceId !== data.raceIdNew
  const isDiffReligionId = data.religionId !== data.religionIdNew
  const isDiffNationalityId = data.nationalityId !== data.nationalityIdNew
  const isDiffBirthPlace = data.birthPlace !== data.birthPlaceNew
  const isDiffPersonalEmail = data.personalEmail !== data.personalEmailNew
  const isDiffPersonalPhone = data.personalPhone !== data.personalPhoneNew

  useEffect(() => {
    dispatch(fetchEmpKeyvalues('race', undefined, undefined, { strategy: 'when-empty' }))
    dispatch(fetchEmpKeyvalues('religion', undefined, undefined, { strategy: 'when-empty' }))
    dispatch(fetchEmpKeyvalues('nationality', undefined, undefined, { strategy: 'when-empty' }))
  }, [])

  const handleEdit = useCallback((personal: SSEmPublicSelfState) => {
    setDrawerState({ visible: true, data: personal })
  }, [])

  const handleCloseDrawer = useCallback(
    (action: 'saved' | 'cancelled') => {
      if (action === 'saved') {
        dispatch(fetchEmPublic(data.id, { strategy: 'always' }))
      }
      setDrawerState(DEFAULT_DRAWER_STATE)
    },
    [data.id]
  )

  return (
    <>
      <Card title="Personal" extra={!hideSelfUpdate && <Link onClick={() => handleEdit(data)}>edit</Link>}>
        <Descriptions colon={false} column={2}>
          <Descriptions.Item label="Date of birth">
            <Space>
              {data.birthDateNew ? moment(data.birthDateNew).format('DD MMM YYYY') : '-'}
              {isDiffBirthDate && <PendingTag />}
            </Space>
          </Descriptions.Item>
          <Descriptions.Item label="Gender">
            <Space>
              {genders[data.genderNew || '']?.value || '-'}
              {isDiffGender && <PendingTag />}
            </Space>
          </Descriptions.Item>
          <Descriptions.Item label="Marital status">
            <Space>
              {maritalStatuses[data.maritalStatusNew || '']?.value || '-'}
              {isDiffMaritalStatus && <PendingTag />}
            </Space>
          </Descriptions.Item>
          {data.maritalStatusNew === 'married' && (
            <Descriptions.Item label="Marriage date">
              <Space>
                {data.marriageDateNew ? moment(data.marriageDateNew).format('DD MMM YYYY') : '-'}
                {isDiffMarriageDate && <PendingTag />}
              </Space>
            </Descriptions.Item>
          )}
          <Descriptions.Item label="Race">
            <Space>
              {races[data.raceIdNew || '']?.value || '-'}
              {isDiffRaceId && <PendingTag />}
            </Space>
          </Descriptions.Item>
          <Descriptions.Item label="Religion">
            <Space>
              {religions[data.religionIdNew || '']?.value || '-'}
              {isDiffReligionId && <PendingTag />}
            </Space>
          </Descriptions.Item>
          <Descriptions.Item label="Nationality">
            <Space>
              {nationalities[data.nationalityIdNew || '']?.value || '-'}
              {isDiffNationalityId && <PendingTag />}
            </Space>
          </Descriptions.Item>
          <Descriptions.Item label="Birth place">
            <Space>
              {data.birthPlaceNew || '-'}
              {isDiffBirthPlace && <PendingTag />}
            </Space>
          </Descriptions.Item>
          <Descriptions.Item label="Personal email">
            <Space>
              {data.personalEmailNew || '-'}
              {isDiffPersonalEmail && <PendingTag />}
            </Space>
          </Descriptions.Item>
          <Descriptions.Item label="Personal phone">
            <Space>
              {data.personalPhoneNew || '-'}
              {isDiffPersonalPhone && <PendingTag />}
            </Space>
          </Descriptions.Item>
        </Descriptions>
      </Card>
      {!hideSelfUpdate && <EditMyPersonalDrawer {...drawerState} onClose={handleCloseDrawer} />}
    </>
  )
}
