import { ActionResult, ThunkResult } from '~/types/store'
import { emptyGuid } from '~/constants'
import { showSuccess, showError } from '~/utils'
import { apiAddEmOffice } from '../api/em-office.api'
import { setEmOffice, setEmployee } from '../reducers'
import { IEmOfficeInfo, EmOfficeState } from '../types'

export const addEmOffice = (employeeId: string, emOffice: IEmOfficeInfo): ThunkResult<void> => {
  return async (dispatch, getState): Promise<ActionResult | undefined> => {
    if (!employeeId) return

    const { status, result, errors, message, errorData } = await apiAddEmOffice(employeeId, {
      id: emptyGuid,
      employeeId,
      ...emOffice,
      officeId: emOffice.officeId || emptyGuid
    })
    if (status) {
      if (result.emOfficeId) {
        const employee = getState().employee.employees.entities[employeeId]
        if (employee) {
          dispatch(setEmployee({ ...employee, emOfficeId: result.emOfficeId }))
        }
      }

      const newEmOffice = {
        id: result.id,
        employeeId,
        ...emOffice
      } as EmOfficeState
      dispatch(setEmOffice({ employeeId, data: newEmOffice }))

      showSuccess('Added')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
