import { createSelector } from '@reduxjs/toolkit'
import moment from 'moment-timezone'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { SSClaimRecordCommentState, IClaimRecordComment } from '../types'

export const mapSSClaimRecordCommentStateToIClaimRecordComment = (
  comments: SSClaimRecordCommentState[] | undefined = []
) =>
  comments
    .map(
      c =>
        ({
          id: c.id,
          authorId: c.authorId,
          author: c.authorName,
          photoId: c.photoId,
          content: c.comment,
          datetime: c.createdDate ? moment(c.createdDate).fromNow() : undefined,
          createdDate: c.createdDate,
          updatedDate: c.updatedDate
        } as IClaimRecordComment)
    )
    .sort((a, b) => {
      const aCreatedDate = new Date(a!.createdDate).getTime()
      const bCreatedDate = new Date(b!.createdDate).getTime()
      return bCreatedDate - aCreatedDate
    })

export const selectMyClaimRecordComments = createSelector(
  (state: StoreState) => state.myClaim.myClaimRecordComments,
  myClaimRecordCommentsState =>
    memoize((id: string): IClaimRecordComment[] =>
      mapSSClaimRecordCommentStateToIClaimRecordComment(
        Object.values(myClaimRecordCommentsState[id]?.entities || {}) as SSClaimRecordCommentState[]
      )
    )
)
