import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { selectEmAddressRequests } from '../selectors'
import { fetchEmAddressRequests } from '../actions'
import { EmAddressRequestState } from '../types'

export const useEmAddressRequests = (employeeId: string, inView: boolean): [EmAddressRequestState[], boolean] => {
  const data = useSelector(selectEmAddressRequests)(employeeId)
  const loading = useSelector((state: StoreState) => state.employee.emAddressRequestsLoading[employeeId])

  useEffect(() => {
    if (inView && employeeId) dispatch(fetchEmAddressRequests(employeeId))
  }, [employeeId, inView])

  return [data, loading]
}
