import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import { dispatch } from '~/stores/store'
import { afterLogin, fetchLoginPermissions, fetchMyLogin, ForceChangePassword, replaceMyJwt } from './features/iam'
import { AuthProvider, authInstance } from './auth'
import { RenderRoutes } from './routes/RenderRoutes'
import { StoreState } from './types/store'
import env from './env'

import moment from 'moment-timezone'
import 'moment/locale/zh-cn'
import enUS from 'antd/es/locale/en_US'
import zhCN from 'antd/es/locale/zh_CN'
import { bindStoreListeners } from './stores/root-reducer'

const appVersion = env.REACT_APP_VERSION
const storedVersion = localStorage.getItem('version')
const activeTenantCode = localStorage.getItem('tenant')

if (appVersion !== storedVersion) {
  authInstance.clearLocalStorage()
  localStorage.setItem('version', appVersion)
}

const App: FC = () => {
  const { locale } = useSelector((state: StoreState) => state.locale)
  const myJwt = useSelector((state: StoreState) => state.iam.myJwt)
  const loginId = myJwt?.loginId || ''
  const isAdmin = myJwt?.isAdmin || false

  useEffect(() => {
    const jwt = authInstance.getJwtInfo()
    dispatch(replaceMyJwt(jwt))

    if (jwt?.loginId) {
      bindStoreListeners()

      if (activeTenantCode) {
        dispatch(fetchMyLogin(activeTenantCode))
        dispatch(afterLogin({ id: jwt.loginId, tenantCode: activeTenantCode }))
      }
    }
  }, [])

  useEffect(() => {
    if (isAdmin) {
      dispatch(fetchLoginPermissions(loginId, activeTenantCode))
      // dispatch(refreshEmployee())
    }
  }, [isAdmin, loginId])

  useEffect(() => {
    if (locale === 'en_US') {
      moment.locale('en', {
        week: {
          dow: 1
        }
      })
    } else if (locale === 'zh_CN') {
      moment.locale('zh-cn', {
        week: {
          dow: 1
        }
      })
    }
  }, [locale])

  const getAntdLocale = () => {
    if (locale === 'en_US') {
      return enUS
    } else if (locale === 'zh_CN') {
      return zhCN
    }
  }

  return (
    <AuthProvider authService={authInstance}>
      <ConfigProvider locale={getAntdLocale()} componentSize="middle">
        <BrowserRouter>
          <RenderRoutes />
        </BrowserRouter>
        <ForceChangePassword />
      </ConfigProvider>
    </AuthProvider>
  )
}

export default App
