import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'

export const selectLeaveRecordAttachments = createSelector(
  (state: StoreState) => state.leave.leaveRecordAttachment,
  leaveRecordAttachmentState =>
    memoize((leaveRecordId?: string) => {
      return Object.values(leaveRecordAttachmentState?.entities || {}).filter(
        lras => lras?.leaveRecordId === leaveRecordId
      )
    })
)
