import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { TeamState } from '../types'

export interface TeamOverview extends TeamState {
  headName?: string
  headJob?: string
  headPhotoId?: string
  activeEmployees?: number
}

const EMPTY_TEAM_EXTRA: Partial<TeamOverview> = {
  headName: '',
  headJob: '',
  headPhotoId: '',
  activeEmployees: 0
}

export const selectTeamsOverview = createSelector(
  (state: StoreState) => state.master.masters['team'],
  (state: StoreState) => state.master.teamsExtra,
  (teamsState, teamsExtraState): TeamOverview[] => {
    const teams = Object.values(teamsState?.entities || {})

    return teams
      .map(team => ({
        ...(team as TeamState),
        ...(teamsExtraState?.entities[team!.id] || EMPTY_TEAM_EXTRA)
      }))
      .sort((a, b) => {
        const aInactiveDate = new Date(a!.inactiveDate || '').getTime() || 0
        const bInactiveDate = new Date(b!.inactiveDate || '').getTime() || 0

        if (bInactiveDate === 0 && aInactiveDate === 0) {
          return a!.name.localeCompare(b!.name)
        } else {
          return aInactiveDate - bInactiveDate
        }
      })
  }
)
