import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { selectROLeEarned } from '../../selectors'
import { ROLeEarnedState } from '../../types'
import { fetchROLeEarned } from '../../actions'

export const useROLeEarned = (
  periodCode?: string,
  employeeId?: string,
  leaveTypeId?: string
): [ROLeEarnedState[], boolean] => {
  const entId = `${periodCode}|${employeeId}|${leaveTypeId}`
  const data = useSelector(selectROLeEarned)(periodCode, employeeId, leaveTypeId)
  const loading = useSelector((state: StoreState) => state.my.roLeEarnedLoading[entId])

  useEffect(() => {
    dispatch(fetchROLeEarned(periodCode, employeeId, leaveTypeId))
  }, [periodCode, employeeId, leaveTypeId])

  return [data, loading]
}
