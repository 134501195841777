import React, { FC, useCallback, useEffect, useState } from 'react'
import { Form, Input, Modal } from '~/core-components'
import { Col, Row } from '~/components'
import { useFocus } from '~/hooks/use-focus'

interface PdfPasswordModalProps {
  open: boolean
  onApply?: (password: string) => Promise<void>
  onClose: () => void
}

interface FormData {
  password: string
}

const EMPTY_FORM_DATA: FormData = {
  password: ''
}

export const PdfPasswordModal: FC<PdfPasswordModalProps> = ({ open, onApply, onClose }) => {
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)
  const [focusRef, setFocus] = useFocus(true)
  const [applying, setApplying] = useState(false)

  useEffect(() => {
    setTimeout(() => open && setFocus(), 100)
  }, [open, setFocus])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => setFormData(formData => ({ ...formData, ...updates })),
    []
  )

  const handleOk = useCallback(async () => {
    setApplying(true)
    try {
      typeof onApply === 'function' && (await onApply(formData.password))

      setFormData(EMPTY_FORM_DATA)
      typeof onClose === 'function' && onClose()
    } finally {
      setApplying(false)
    }
  }, [formData, onApply, onClose])

  return (
    <Modal
      open={open}
      title="Secure your PDF"
      width={350}
      okText="Download PDF"
      onCancel={onClose}
      confirmLoading={applying}
      formId="form-pdf-password"
      className="pdf-password-modal"
    >
      <Form id="form-pdf-password" onFinish={handleOk}>
        <Row>
          <Col span={24}>
            <Form.Item label="Password">
              <Input
                ref={focusRef}
                type="password"
                value={formData.password}
                onChange={event => handleFormDataChange({ password: event.target.value })}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
