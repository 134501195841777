import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { Search } from '~/types/common'
import { ClaimTypeState, IAddClaimType } from '../types'

const baseUrl = getBaseUrl('/claim/claimtype')

export const apiGetClaimTypes = () => request<ClaimTypeState[]>('get', baseUrl)

export const apiGetClaimTypesView = (viewId: string, params: Search) =>
  request<ClaimTypeState[]>('get', `${baseUrl}/view/${viewId}`, params)

export const apiGetClaimType = (id: string) => request<ClaimTypeState>('get', `${baseUrl}/${id}`)

export const apiAddClaimType = (claimType: IAddClaimType) => request<{ id: string }>('post', baseUrl, claimType)

export const apiUpdateClaimType = (id: string, claimType: Operation[]) =>
  request<void>('patch', `${baseUrl}/${id}`, claimType)

export const apiDeleteClaimType = (id: string) => request<void>('delete', `${baseUrl}/${id}`)
