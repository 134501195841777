import { replaceMyClaimBalances, setMyClaimBalancesLoading } from '../reducers'
import { apiGetMyClaimBalances } from '../api/ss-claim-entitlement.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchMyClaimBalances = (options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().myClaim.myClaimBalancesLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().myClaim.myClaimBalances?.ids.length > 0
      if (hasData) return
    }

    try {
      dispatch(setMyClaimBalancesLoading(true))
      const { result, status } = await apiGetMyClaimBalances()
      if (status) dispatch(replaceMyClaimBalances(result))
    } finally {
      dispatch(setMyClaimBalancesLoading(false))
    }
  }
}
