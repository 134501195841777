import { UserManager, WebStorageStateStore, Log, User } from 'oidc-client'
import { unbindStoreListeners } from '~/stores/root-reducer'
import { MyJwtState } from '~/features/iam'
import { IDENTITY_CONFIG } from './auth-const'
import env from '../env'

interface ClearStorageOptions {
  switchTenant?: string
  clearAccessToken?: boolean
}

export default class AuthService {
  UserManager: UserManager
  OIDC_STORAGE_KEY = `oidc.user:${env.REACT_APP_AUTH_URL}:${env.REACT_APP_IDENTITY_CLIENT_ID}`

  constructor() {
    this.UserManager = new UserManager({
      ...IDENTITY_CONFIG,
      userStore: new WebStorageStateStore({ store: window.localStorage })
    })

    Log.logger = console
    Log.level = Log.DEBUG

    this.UserManager.events.addUserLoaded(user => {
      if (window.location.href.indexOf('signin-oidc') !== -1) {
        this.navigateToScreen()
      }
    })

    this.UserManager.events.addSilentRenewError(e => {
      console.log('Silent renew error', e.message)
    })

    this.UserManager.events.addAccessTokenExpired(() => {
      // console.log('Token is expired. Renewing...')
      const tenant = localStorage.getItem('tenant')
      this.signinSilent({ tenant })
        .then(user => {
          // console.log('Renewed', user.profile)
          localStorage.setItem('access_token', user.access_token)

          const tenant = localStorage.getItem('tenant')
          if (!tenant) {
            localStorage.setItem('tenant', user.profile?.tenant)
          }
        })
        .catch(e => {
          console.log('Error while renew expired token', e)
          this.clearLocalStorage({ clearAccessToken: true })
          this.signinRedirect({ extraQueryParams: { tenant } })
        })
    })
  }

  signinRedirect = (args: any = {}) => {
    if (window.location.pathname === '/signin-oidc') return

    localStorage.setItem('redirectUri', window.location.pathname)
    this.UserManager.signinRedirect(args)
  }

  signinRedirectCallback = () => {
    this.UserManager.signinRedirectCallback('')
      .then(user => {
        // console.log('Redirect callback', user.profile)
        localStorage.setItem('access_token', user.access_token)

        const tenant = localStorage.getItem('tenant')
        if (!tenant || !(user.profile?.tenants || []).includes(tenant)) {
          localStorage.setItem('tenant', user.profile?.tenant)
        }
      })
      .catch(error => {
        // Ignore error: https://stackoverflow.com/questions/49220150/no-state-in-response-after-callback-in-oidc-client-js
        console.error('signinRedirectCallback.error', error)

        if (window.location.pathname === '/signin-oidc') {
          setTimeout(() => {
            console.warn('signinRedirectCallback.forceRedirect', env.REACT_APP_PUBLIC_URL!)
            window.location.replace(env.REACT_APP_PUBLIC_URL!)
          }, 10000)
        }
      })
  }

  getUser = async (): Promise<User | undefined> => {
    const user = await this.UserManager.getUser()
    if (!user) {
      return await this.UserManager.signinRedirectCallback()
    }
    return user
  }

  getJwtInfo = (): MyJwtState | undefined => {
    const accessToken = this.getAccessToken()
    if (!accessToken) return undefined

    try {
      const base64Url = accessToken.split('.')[1]
      const base64 = base64Url.replace('-', '+').replace('_', '/')
      const {
        sub: loginId,
        name,
        su: isSuperUser,
        owned: isOwner,
        adm: isAdmin,
        em: isEmployee,
        tenants,
        tenant
      } = JSON.parse(window.atob(base64))
      return {
        loginId,
        name,
        isEmployee: isEmployee ? (typeof isEmployee === 'string' ? [isEmployee] : isEmployee) : [],
        isAdmin: isAdmin ? (typeof isAdmin === 'string' ? [isAdmin] : isAdmin) : [],
        isSuperUser: isSuperUser ? (typeof isSuperUser === 'string' ? [isSuperUser] : isSuperUser) : [],
        isOwner: isOwner ? (typeof isOwner === 'string' ? [isOwner] : isOwner) : [],
        tenants: tenants ? (typeof tenants === 'string' ? [tenants] : tenants) : [],
        tenant
      }
    } catch (error) {
      console.log('Error while getting user id', error)
      return undefined
    }
  }

  navigateToScreen = () => {
    const redirectUri = localStorage.getItem('redirectUri')
    if (redirectUri && redirectUri !== window.location.pathname) {
      window.location.replace(redirectUri)
      localStorage.removeItem('redirectUri')
    }
  }

  isAuthenticated = (): boolean => {
    const idToken = this.getIdToken()
    if (!idToken) return false

    try {
      const base64Url = idToken.split('.')[1]
      const base64 = base64Url.replace('-', '+').replace('_', '/')
      const { exp } = JSON.parse(window.atob(base64))

      const isExpired = exp < (new Date().getTime() + 1) / 1000
      // console.log(`Token ${isExpired ? 'is expired' : 'with expiry'}`, exp)
      if (isExpired) {
        return false
      }
    } catch (error) {
      return false
    }
    return true
  }

  getIdToken = (): string | undefined => {
    const oidcStoreValue = localStorage.getItem(this.OIDC_STORAGE_KEY)
    const oidcStorage = JSON.parse(oidcStoreValue!)
    return oidcStorage?.id_token
  }

  getAccessToken = (): string | undefined => {
    const oidcStoreValue = localStorage.getItem(this.OIDC_STORAGE_KEY)
    const oidcStorage = JSON.parse(oidcStoreValue!)
    return oidcStorage?.access_token
  }

  signinSilent = async (args: any = {}) => {
    return this.UserManager.signinSilent(args)
  }

  signinSilentCallback = () => {
    this.UserManager.signinSilentCallback()
  }

  createSigninRequest = () => {
    return this.UserManager.createSigninRequest()
  }

  register = (redirectUrl: string) => {
    this.clearLocalStorage({ clearAccessToken: true })
    unbindStoreListeners()
    window.location.replace(redirectUrl)
  }

  logout = async () => {
    const user = await this.getUser()
    this.UserManager.signoutRedirect({
      id_token_hint: user?.id_token
    })
    this.clearLocalStorage({ clearAccessToken: true })
    unbindStoreListeners()
  }

  signoutRedirectCallback = () => {
    this.UserManager.clearStaleState()
    this.clearLocalStorage({ clearAccessToken: true })
    window.location.replace(env.REACT_APP_PUBLIC_URL!)
  }

  clearLocalStorage = ({ switchTenant, clearAccessToken }: ClearStorageOptions = {}) => {
    const tenant = switchTenant || localStorage.getItem('tenant')
    const accessToken = localStorage.getItem('access_token')
    const version = localStorage.getItem('version')
    const oidcStoreValue = localStorage.getItem(this.OIDC_STORAGE_KEY)

    localStorage.clear()
    localStorage.setItem('tenant', tenant || '')
    localStorage.setItem('version', version || '')

    if (!clearAccessToken) {
      localStorage.setItem('access_token', accessToken || '')
      if (oidcStoreValue) localStorage.setItem(this.OIDC_STORAGE_KEY, oidcStoreValue)
    }
  }
}
