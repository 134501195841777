import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchExpenseTypes } from '../actions'
import { ExpenseTypeState } from '../types'
import { selectExpenseTypes } from '../selectors'

export const useExpenseTypes = (): [ExpenseTypeState[], boolean] => {
  const data = useSelector(selectExpenseTypes)
  const loading = useSelector((state: StoreState) => state.claim.expenseTypesLoading)

  useEffect(() => {
    dispatch(fetchExpenseTypes({ strategy: 'when-empty' }))
  }, [])

  return [data, loading]
}
