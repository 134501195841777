import { Dictionary } from '@reduxjs/toolkit'
import { replaceEmAddresses, setEmAddressesLoading } from '../reducers'
import { apiGetEmAddresses } from '../api/em-address.api'
import { ThunkResult } from '~/types/store'
import { EmAddressState } from '../types'
import { formatAddress } from '~/features/master'
import { IAddress } from '~/features/master/types'
import { DEFAULT_FETCH_OPTIONS, FetchOptions, KeyValue } from '~/types/common'

export const fetchEmAddresses = (
  employeeId: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!employeeId) return

    const loading = getState().employee.emAddressesLoading[employeeId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().employee.emAddresses[employeeId]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setEmAddressesLoading({ employeeId, loading: true }))
      const { result, status } = await apiGetEmAddresses(employeeId)
      if (status) {
        const countries = getState().master.keyvalues['country']?.entities
        const stored = mapToState(result, countries)
        dispatch(replaceEmAddresses({ employeeId, data: stored }))
      }
    } finally {
      dispatch(setEmAddressesLoading({ employeeId, loading: false }))
    }
  }
}

const mapToState = (emAddresses: EmAddressState[], countries: Dictionary<KeyValue>): EmAddressState[] =>
  emAddresses.map(a => {
    const countryName = countries[a.countryCode || '']?.value || ''

    return {
      ...a,
      ...formatAddress(
        {
          block: a.block,
          street: a.street,
          level: a.level,
          unit: a.unit,
          building: '',
          city: a.city,
          state: a.state,
          postalCode: a.postalCode,
          countryCode: a.countryCode
        } as IAddress,
        countryName
      )
    }
  })
