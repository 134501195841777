import React, { FC } from 'react'
import { Card, CardProps, Form, SecondaryText } from '~/core-components'
import { Col, Row } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { Permission } from '~/constants'
import { PayrollReportButton } from '../PayRun/PayrollReportButton'

interface PayReportsCardProps extends CardProps {
  payRunId: string
}

export const PayReportsCard: FC<PayReportsCardProps> = ({ payRunId, ...props }) => {
  const canView = usePermissionGate(Permission.rptPayMonthly)

  if (!canView) return null

  return (
    <Card {...props}>
      <Form className="paysummary__card-report" layout="horizontal" labelCol={{ span: 12 }} colon={false}>
        <Row className="paysummary__card-report-title">
          <Col flex="auto">
            <b>Payroll reports</b>
          </Col>
        </Row>
        <Row wrap={false}>
          <Col flex={1}>
            <SecondaryText>Payroll report</SecondaryText>
          </Col>
          <Col>
            <PayrollReportButton payRunId={payRunId} type="primary" size="small">
              Download <i className="fa-light fa-angle-down fa-fw" />
            </PayrollReportButton>
          </Col>
        </Row>
        {/* <Row>
          <Col span={24}>
            <Form.Item label="Reconciliation report">
              <Select allowClear={false} value="xlsx">
                <Select.Option value="pdf">PDF</Select.Option>
                <Select.Option value="xlsx">Excel</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row> */}
      </Form>
    </Card>
  )
}
