import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import { Form, Input } from '~/core-components'
import { Col, EditableCard, EditableCardState, Row, SysOptions } from '~/components'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { dispatch } from '~/stores/store'
import { Errors, ActionResult } from '~/types/store'
import { useFocus } from '~/hooks/use-focus'
import { CompanyState, ICompanyStatutory } from '../../../types'
import { mapCompanyStateToCompanyStatutory } from '../../../types/company.mapper'
import { updateCompany } from '../../../actions'

interface StatutoryProps {
  company?: CompanyState
  onEdit?: () => void
  onSave?: () => void
  onCancel?: () => void
}

const EMPTY_FORM_DATA: ICompanyStatutory = { cpfNo: '', taxType: '', taxNo: '' }
const accountTypeUseRegNo = ['7', '8', 'U']

export const Statutory: FC<StatutoryProps> = ({ company, onEdit, onSave, onCancel }: StatutoryProps) => {
  const [cardState, setCardState] = useState<EditableCardState>()
  const [formData, setFormData] = useState<ICompanyStatutory>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const [focusRef] = useFocus(cardState === 'editing')
  const canModify = usePermissionGate(Permission.company, PermissionAction.Modify)
  const showTaxNoAsLabel: boolean = accountTypeUseRegNo.includes(formData.taxType)
  const companyRegistrationNo = company?.registrationNo || ''

  useEffect(() => {
    if (company) {
      const { cpfNo, taxType, taxNo } = company
      setFormData({ cpfNo, taxType, taxNo })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [company])

  useEffect(() => {
    if (showTaxNoAsLabel) setFormData(data => ({ ...data, taxNo: companyRegistrationNo }))
    else setFormData(data => ({ ...data, taxNo: '' }))
  }, [showTaxNoAsLabel, companyRegistrationNo])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => setFormData(data => ({ ...data, ...updates })),
    []
  )

  const handleEdit = useCallback(() => {
    setCardState('editing')
    typeof onEdit === 'function' && onEdit()
  }, [onEdit])

  const handleSave = useCallback(async () => {
    if (company) {
      setCardState('saving')
      setErrors(undefined)

      typeof onSave === 'function' && onSave()

      let result: ActionResult | undefined
      try {
        result = await dispatch(updateCompany(company.id, mapCompanyStateToCompanyStatutory(company), formData))
      } catch {
        setCardState('editing')
      }

      if (result?.errors) {
        setCardState('editing')
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setCardState(undefined)
      }
    }
  }, [company, formData, onSave])

  const handleCancel = useCallback(() => {
    typeof onCancel === 'function' && onCancel()
    setCardState(undefined)
    setErrors(undefined)

    if (company) {
      const { cpfNo, taxType, taxNo } = company
      setFormData({ cpfNo, taxType, taxNo })
    }
  }, [company, onCancel])

  return (
    <EditableCard
      title="Statutory"
      bodyStyle={{ paddingBottom: company ? 6 : 24, paddingTop: 6 }}
      state={canModify ? cardState : 'readonly'}
      formId="form-statutory"
      onEdit={handleEdit}
      onSave={handleSave}
      onCancel={handleCancel}
    >
      <Row>
        <Col span={24}>
          <Form.Item
            label="CPF submission no. (CSN)"
            validateStatus={errors?.cpfNo ? 'error' : ''}
            help={errors?.cpfNo}
          >
            <Input
              ref={focusRef}
              value={formData.cpfNo}
              readOnly={cardState !== 'editing' && cardState !== 'saving'}
              onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ cpfNo: event.target.value })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={12}>
          <Form.Item
            label="IRAS tax account type"
            validateStatus={errors?.taxType ? 'error' : ''}
            help={errors?.taxType}
          >
            <SysOptions
              type="tax_type"
              sort="key"
              value={formData.taxType}
              readOnly={cardState !== 'editing' && cardState !== 'saving'}
              onChange={(value: string) => {
                handleFormDataChange({ taxType: value })
              }}
              dropdownMultiline={true}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="IRAS tax account no." validateStatus={errors?.taxNo ? 'error' : ''} help={errors?.taxNo}>
            <Input
              hidden={showTaxNoAsLabel}
              value={formData.taxNo}
              readOnly={cardState !== 'editing' && cardState !== 'saving'}
              onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ taxNo: event.target.value })}
            />
            <label hidden={!showTaxNoAsLabel}>{formData.taxNo}</label>
          </Form.Item>
        </Col>
      </Row>
    </EditableCard>
  )
}
