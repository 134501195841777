import React, { FC } from 'react'
import { Redirect, RouteProps, Switch } from 'react-router-dom'
import { TENANT_PATH, TENANT_ROUTES } from '~/routes/routes'
import { EmptyLayout } from '~/layouts/EmptyLayout/EmptyLayout'
import { PrivateRoute } from './PrivateRoute'
import { TenantSwitcherPage } from '../features/iam/containers/TenantSwitcher/TenantSwitcherPage'

export const routes: RouteProps[] = [
  {
    path: TENANT_ROUTES.list,
    component: TenantSwitcherPage,
    exact: true
  }
]

export const TenantRoutes: FC = () => {
  return (
    <EmptyLayout>
      <Switch>
        <Redirect exact from={TENANT_PATH} to={TENANT_ROUTES.list} />
        {routes.map(({ path, ...props }) => (
          <PrivateRoute key={path as string} exact path={path} {...props} />
        ))}
      </Switch>
    </EmptyLayout>
  )
}
