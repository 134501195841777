import React, { FC } from 'react'
import { ShiftTime } from './ShiftTime'
import { useShiftDaysDict } from '~/features/attendance/hooks'

interface ShiftDayTimeProps {
  shiftId?: string
  dayCode?: string
}

export const ShiftDayTime: FC<ShiftDayTimeProps> = ({ shiftId, dayCode }) => {
  const [data] = useShiftDaysDict(shiftId || '')
  const day = data[dayCode || '']
  if (!day) return null

  const { normalStartTime, normalStartDay, normalEndTime, normalEndDay } = day

  return (
    <ShiftTime startTime={normalStartTime} startDay={normalStartDay} endTime={normalEndTime} endDay={normalEndDay} />
  )
}
