import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Dictionary } from '@reduxjs/toolkit'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { FetchStrategy } from '~/types/common'
import { fetchCalendars } from '../actions'
import { WorkCalendarState } from '../types'

export const useCalendarsDict = (fetchStrategy: FetchStrategy = 'always'): [Dictionary<WorkCalendarState>, boolean] => {
  const data = useSelector((state: StoreState) => state.attendance.calendars.entities)
  const loading = useSelector((state: StoreState) => state.attendance.calendarsLoading)

  useEffect(() => {
    dispatch(fetchCalendars({ strategy: fetchStrategy }))
  }, [fetchStrategy])

  return [data, loading]
}
