import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import pick from 'lodash/pick'
import { apiUpdateCompanyOffice } from '../api/company.api'
import { setCompanyOffices, setOffice } from '../reducers'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { ICompanyOfficeInfo, OfficeState, CompanyOfficeState } from '../types'
import { formatAddress } from '../util'

export const updateCompanyOffice = (
  id: string,
  companyId: string,
  officeId: string,
  before: ICompanyOfficeInfo,
  request: ICompanyOfficeInfo
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id || !companyId || !officeId) return

    const after = { ...before, ...request } as ICompanyOfficeInfo
    const patch = generateJsonPatch<ICompanyOfficeInfo>(before, after)
    const { status, errors, message, errorData } = await apiUpdateCompanyOffice(companyId, officeId, patch)

    if (status) {
      let offices = getState().master.offices?.entities || {}
      const newOfficeUpdates = pick(request, [
        'name',
        'block',
        'street',
        'level',
        'unit',
        'building',
        'city',
        'state',
        'postalCode',
        'countryCode',
        'phone',
        'holidayId',
        'inactiveDate'
      ])

      const countries = getState().master.keyvalues['country'].entities
      const countryName = countries[newOfficeUpdates.countryCode || '']?.value || ''
      const newOffice: OfficeState = {
        ...(offices[officeId] as OfficeState),
        ...newOfficeUpdates,
        ...formatAddress(newOfficeUpdates, countryName)
      }

      // The main "office" update
      dispatch(setOffice(newOffice))

      let newCompanyOffices: CompanyOfficeState[] = []

      const companyOffices = getState().master.companyOffices?.entities || {}
      const newCompanyOfficeUpdates = pick(request, ['isMain'])
      const newCompanyOffice: CompanyOfficeState = {
        ...(companyOffices[id] as CompanyOfficeState),
        ...newCompanyOfficeUpdates
      }

      // The main "company office" update
      newCompanyOffices.push(newCompanyOffice)

      if (!before.isMain && after.isMain) {
        // Reset isMain to false for other offices under same company
        Object.values(companyOffices).forEach(c => {
          if (c?.companyId === companyId && c?.officeId !== officeId) {
            newCompanyOffices.push({ ...c, isMain: false })
          }
        })

        // Update state together
        dispatch(setCompanyOffices(newCompanyOffices))
      }

      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
