import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { setA8aProcessing } from '../reducers'
import { apiProcessA8a } from '../api/ytd.api'

export const processA8a = (
  ytdSubmissionId: string,
  employeeTaxNos: string[] = [],
  employeeScope: 'existing' | 'all' = 'existing'
): ThunkResult<void> => {
  return async dispatch => {
    if (!ytdSubmissionId) return

    try {
      dispatch(setA8aProcessing(true))
      const { status, errors, message, errorData } = await apiProcessA8a(ytdSubmissionId, employeeScope, employeeTaxNos)
      if (status) {
        showSuccess('Appendix 8A recalculated')
      } else {
        if (Object.keys(errors).length === 0) {
          showError(message, errorData)
        }
      }
    } finally {
      dispatch(setA8aProcessing(false))
    }
  }
}
