import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiDeleteCurrency } from '../api/currency.api'
import { removeCurrency } from '../reducers'
import { removeKeyvalue } from '~/features/master'

export const deleteCurrency = (code: string): ThunkResult<void> => {
  return async dispatch => {
    if (!code) return

    const { status, errors, message, errorData } = await apiDeleteCurrency(code)
    if (status) {
      dispatch(removeCurrency(code))
      dispatch(removeKeyvalue({ kv: 'currency', id: code }))
      showSuccess('Deleted')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
