import { showError, showSuccess } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { IResetPasswordEmpNo } from '../types'
import { apiResetPasswordEmpNo } from '../api/login.api'

export const resetPasswordEmpNo = (request: IResetPasswordEmpNo): ThunkResult<void> => {
  return async (): Promise<ActionResult> => {
    const { status, result, errors, message, errorData } = await apiResetPasswordEmpNo(request)
    if (status) {
      showSuccess('Sent')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
