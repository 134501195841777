import React, { FC } from 'react'
import moment from 'moment-timezone'
import { LoadingOutlined } from '@ant-design/icons'
import { Checkbox, CheckboxValueType, Form, Radio, RadioChangeEvent, Space, Spin } from '~/core-components'
import { Col, ErrorDisplay, Row } from '~/components'
import { Errors } from '~/types/store'
import { LeaveClosingFormData } from './LeaveClosingDrawer'
import { useLeavePeriods } from '../../../hooks'
import './LeaveClosingInput.less'

interface LeaveClosingInputProps {
  visible: boolean
  data: Partial<LeaveClosingFormData>
  errors?: Errors
  onChange: (data: Partial<LeaveClosingFormData>) => void
}

export const LeaveClosingInput: FC<LeaveClosingInputProps> = ({ visible, data, errors, onChange }) => {
  const [leavePeriods, loading] = useLeavePeriods()
  const leaveTypes = leavePeriods.find(p => p.periodCode === data.periodCode)?.leaveTypes || []

  return (
    <div className="leave-closing-input" hidden={!visible}>
      <Form>
        <Row>
          <Col span={24}>
            <Form.Item label="Select period">
              {loading ? (
                <Spin size="small" indicator={<LoadingOutlined spin />} />
              ) : (
                <Radio.Group
                  value={data.periodCode}
                  onChange={(e: RadioChangeEvent) => {
                    onChange({ periodCode: e.target.value })
                  }}
                >
                  <Space direction="vertical">
                    {leavePeriods.map(p => (
                      <Radio key={p.periodCode} value={p.periodCode}>
                        {moment(p.periodStartDate).format('MMM YYYY')} - {moment(p.periodEndDate).format('MMM YYYY')}
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
              )}
            </Form.Item>
          </Col>
          <Col span={24} hidden={!data.periodCode}>
            <Form.Item label="Select leave types">
              <Checkbox.Group
                value={data.leaveTypeIds}
                onChange={(checkedValue: CheckboxValueType[]) => {
                  onChange({ leaveTypeIds: checkedValue as string[] })
                }}
              >
                <Space direction="vertical">
                  {leaveTypes.map(lt => (
                    <Checkbox key={lt.key} value={lt.key}>
                      {lt.value}
                    </Checkbox>
                  ))}
                </Space>
              </Checkbox.Group>
            </Form.Item>
          </Col>
        </Row>
        <ErrorDisplay errors={errors} />
      </Form>
    </div>
  )
}
