import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchOedSummary } from '../actions'
import { OedSummaryState } from '../types'

export const useOedSummary = (
  id?: string,
  fetchStrategy: FetchStrategy = 'always'
): [OedSummaryState | undefined, boolean] => {
  const oedSummary = useSelector((state: StoreState) => state.report.oedSummaries?.entities[id || ''])
  const loading = useSelector((state: StoreState) => state.report.oedSummariesLoading)
  const refetch = useSelector((state: StoreState) => state.report.oedSummariesRefetch)

  useEffect(() => {
    if (id) dispatch(fetchOedSummary(id, { strategy: fetchStrategy }))
  }, [id, refetch, fetchStrategy])

  return [oedSummary, loading]
}
