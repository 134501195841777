import { setDelegation, setDelegationsLoading } from '../reducers'
import { apiGetDelegation } from '../api/delegation.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchDelegation = (id: string, options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const loading = getState().master.delegationsLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = (getState().master.delegations?.ids?.length || 0) > 0
      if (hasData) return
    }

    try {
      dispatch(setDelegationsLoading(true))
      const { result, status } = await apiGetDelegation(id)
      if (status) {
        dispatch(setDelegation(result))
      }
    } finally {
      dispatch(setDelegationsLoading(false))
    }
  }
}
