import { setMyTimeLogTask, setMyTimeLogTasksLoading } from '../reducers'
import { apiGetMyTimeLogTask } from '../api/ss-time-log-task.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchMyTimeLogTask = (id: string, options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const loading = getState().myAttendance.myTimeLogTasksLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().myAttendance.myTimeLogTasks?.entities[id]
      if (hasData) return
    }

    try {
      dispatch(setMyTimeLogTasksLoading(true))
      const { result, status } = await apiGetMyTimeLogTask(id)
      if (status) dispatch(setMyTimeLogTask(result))
    } finally {
      dispatch(setMyTimeLogTasksLoading(false))
    }
  }
}
