import moment from 'moment-timezone'
import { replaceMyClaimRecordComments } from '../reducers'
import { showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { apiAddMyClaimRecordComment } from '../api/ss-claim-record-comment.api'
import { SSClaimRecordCommentState } from '../types'

export const addClaimRecordComment = (id: string, comment: string): ThunkResult<void> => {
  return async (dispatch, getState): Promise<ActionResult> => {
    const { status, errors, result, message, errorData } = await apiAddMyClaimRecordComment(id, comment)
    if (status) {
      const myEmployee = getState().my.myEmployee

      const comments = (Object.values(getState().myClaim.myClaimRecordComments[id]?.entities) ||
        []) as SSClaimRecordCommentState[]

      const newComment = {
        id: result.id,
        createdDate: moment(result.createdDate).format('YYYY-MM-DDTHH:mm:ssZ'),
        comment,
        authorId: myEmployee.id,
        authorName: myEmployee.fullName,
        photoId: myEmployee.photoId
      } as SSClaimRecordCommentState

      dispatch(replaceMyClaimRecordComments({ id, data: [...comments, newComment] }))
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
