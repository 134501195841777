import React, { forwardRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Select, SelectProps } from '~/core-components'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { useFirstInView } from '~/hooks/use-first-in-view'
import { fetchTenantConfigOptions } from '../../actions'
import { TenantConfigState } from '../../types'

interface ConfigOptionsProps extends SelectProps {
  config: TenantConfigState
}

const ConfigOptionsInternal = ({ config, ...props }: ConfigOptionsProps, ref: React.Ref<any>) => {
  const { ref: inViewRef, inView } = useFirstInView<HTMLDivElement>({ threshold: 0.25 })
  const optionCode = config.valueOptionCode
  const options = useSelector((state: StoreState) => state.iam.tenantConfigOptions[optionCode])
  const loading = useSelector((state: StoreState) => state.iam.tenantConfigOptionsLoading[optionCode])

  useEffect(() => {
    if (inView && optionCode) {
      dispatch(fetchTenantConfigOptions(optionCode))
    }
  }, [inView, optionCode])

  return (
    <div ref={inViewRef} className="config-options">
      <Select
        ref={ref}
        mode="multiple"
        showSearch
        allowClear={false}
        showArrow
        suffixIcon={<i className="fal fa-angle-down" />}
        placeholder={config?.name}
        {...props}
        loading={loading}
      >
        {options?.ids.map(id => (
          <Select.Option key={options?.entities[id]?.key || ''} value={options?.entities[id]?.key || ''}>
            {options?.entities[id]?.value}
          </Select.Option>
        ))}
      </Select>
    </div>
  )
}

export const ConfigOptions = forwardRef(ConfigOptionsInternal)
