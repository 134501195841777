import React, { FC, useCallback, useState } from 'react'
import { EditOutlined } from '@ant-design/icons'
import { Card, ColumnsType, PageHeader, SecondaryLink, Space, Switch, Table } from '~/core-components'
import { DocumentTitle } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { Permission, PermissionAction } from '~/constants'
import { dispatch } from '~/stores/store'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { updatePayCfConfig } from '../../actions'
import { usePayCfConfigs } from '../../hooks'
import { PayCfConfigState } from '../../types'
import { EditPayCfConfigDrawer } from './components/EditPayCfConfigDrawer'
import './PayCfConfigs.less'

type PayCfConfigTable = PayCfConfigState

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: '',
    breadcrumbName: 'Overview'
  }
]

interface DrawerState {
  visible: boolean
  data?: PayCfConfigState
}
const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

interface PayCfConfigsProps {}

export const PayCfConfigs: FC<PayCfConfigsProps> = () => {
  const [payCfConfigs, loading] = usePayCfConfigs()
  const [updatingVisible, setUpdatingVisible] = useState<string>()
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const canModify = usePermissionGate(Permission.payCfConfig, PermissionAction.Modify)

  const handleVisibleChange = useCallback(async (payCfConfig: PayCfConfigState, isShown: boolean) => {
    setUpdatingVisible(payCfConfig.code)
    try {
      await dispatch(updatePayCfConfig(payCfConfig.code, payCfConfig, { ...payCfConfig, isShown }))
    } finally {
      setUpdatingVisible(undefined)
    }
  }, [])

  const handleEditName = useCallback((payCfConfig: PayCfConfigState) => {
    setDrawerState({ visible: true, data: payCfConfig })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const columns: ColumnsType<PayCfConfigTable> = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      render: (value: string, record) => (
        <Space>
          {value}
          <SecondaryLink onClick={() => handleEditName(record)}>
            <EditOutlined />
          </SecondaryLink>
        </Space>
      )
    },
    {
      title: 'Visible',
      key: 'isShown',
      dataIndex: 'isShown',
      align: 'center',
      width: 100,
      render: (value: boolean, record) => (
        <Switch
          checkedChildren="Shown"
          unCheckedChildren="Hidden"
          checked={record.isShown}
          loading={updatingVisible === record.code}
          disabled={!canModify}
          onChange={(checked: boolean) => {
            handleVisibleChange(record, checked)
          }}
        />
      )
    }
  ]

  return (
    <div id="pay-cf-configs" className="pay-cf-configs">
      <DocumentTitle title="Custom Fields (People Info)" />
      <PageHeader title="Custom fields (People info)" containerId="pay-cf-configs" breadcrumb={{ routes }} />
      <div className="pay-cf-configs__body">
        <Card table>
          <Table
            rowKey="code"
            dataSource={payCfConfigs}
            pagination={false}
            columns={columns}
            loading={loading}
            rowClassName={record => (record.isShown ? 'pay-cf-configs__table-row--shown' : '')}
          />
        </Card>
      </div>
      {canModify && <EditPayCfConfigDrawer {...drawerState} onClose={handleCloseDrawer} />}
    </div>
  )
}
