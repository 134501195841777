import React, { FC } from 'react'
import { Col, Row } from '~/components'
import { MyClaimBalance } from '../MyClaimBalance/MyClaimBalance'
import { MyClaimRecords } from '../MyClaimRecords/MyClaimRecords'
import './MyClaim.less'

interface MyClaimProps {}

export const MyClaim: FC<MyClaimProps> = () => {
  return (
    <div className="my-claim">
      <Row gutter={24}>
        <Col flex="1">
          <MyClaimRecords />
        </Col>
        <Col flex="330px">
          <MyClaimBalance />
        </Col>
      </Row>
    </div>
  )
}
