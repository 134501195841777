import { request, getBaseUrl } from '~/utils/request'
import { EmAddressRequestState } from '../types'

const baseUrl = getBaseUrl('/employee/employee/request/address')

export const apiGetEmAddressRequests = (employeeId: string) =>
  request<EmAddressRequestState[]>('get', `${baseUrl}/${employeeId}`)

export const apiApproveEmAddressRequest = (id: string) => request('post', `${baseUrl}/${id}/approve`)

export const apiRejectEmAddressRequest = (id: string) => request('post', `${baseUrl}/${id}/reject`)
