import React, { FC, useState, useCallback, useEffect, ChangeEvent, CSSProperties } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { LoadingOutlined } from '@ant-design/icons'
import confirm from 'antd/lib/modal/confirm'
import { Form, Input, Link, Spin } from '~/core-components'
import { ApprovalTargets, Col, DrawerForm, Row } from '~/components'
import { Screen, ViewCriteria, ViewCriteriaAdvanced, useViewSchemaByName } from '~/features/selection'
import { dispatch } from '~/stores/store'
import { useFocus } from '~/hooks/use-focus'
import { Delimiter, emptyGuid, WfActionType, WfFlowType } from '~/constants'
import { ActionResult, Errors } from '~/types/store'
import { ISaveWfAction, ISaveWfEvent, WfCategory, WfEventState } from '../../types'
import { saveWfScenario } from '../../actions'
import { useSysWfFlowType, useWfActions } from '../../hooks'
import { deleteWfScenario } from '../../actions'
import { SelectWfEventType } from './SelectWfEventType'
import { SelectWfActionType } from './SelectWfActionType'
import { NO_TARGET_ACTION } from './WfActions'
import './MutateWfEventDrawer.less'

export interface MutateWfEventDrawerProps {
  category: WfCategory
  visible: boolean
  workflowId: string
  flowType: WfFlowType
  data?: WfEventState
  onClose: () => void
}

const EMPTY_FORM_DATA: ISaveWfEvent = {
  id: '',
  workflowId: '',
  name: '',
  viewId: '',
  flowType: '',
  eventType: '',
  actions: [{ id: emptyGuid, actionType: '', target: '' }],
  criteria: []
}

const NEW_ACTIONS: ISaveWfAction[] = [{ id: emptyGuid, actionType: '', target: '' }]
const actionRowStyle: CSSProperties = { marginBottom: 8 }

export const MutateWfEventDrawer: FC<MutateWfEventDrawerProps> = ({
  category,
  visible,
  workflowId,
  flowType,
  data,
  onClose
}) => {
  const [saving, setSaving] = useState(false)
  const [formData, setFormData] = useState<ISaveWfEvent>(EMPTY_FORM_DATA)
  const [focusRef, setFocus] = useFocus(true)
  const [errors, setErrors] = useState<Errors>()

  const [wfFlowType] = useSysWfFlowType(category, flowType)
  const screen = wfFlowType?.criteriaScreenCode as Screen

  const viewName = formData?.id ? `${category}_${formData.id}` : undefined
  const [view, viewLoading] = useViewSchemaByName(screen, viewName, false)
  const [wfActions, wfActionsLoading] = useWfActions(data?.id)

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  useDeepCompareEffect(() => {
    if (data) {
      const { workflowId, id, name, flowType, eventType } = data
      setFormData({
        workflowId,
        id,
        name,
        flowType,
        eventType,
        viewId: view?.id,
        criteria: view?.criteria || [],
        actions: wfActions
      })
    } else {
      setFormData({ ...EMPTY_FORM_DATA, workflowId, flowType, viewId: view?.id })
    }
  }, [workflowId, flowType, data || {}, view || {}, wfActions])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      const updated = { ...formData, ...updates }

      const hasActions = updated.actions.filter(a => !!a.actionType || !!a.target).length > 0
      if ('eventType' in updates && hasActions) {
        confirm({
          title: 'Reset actions',
          content: 'Changing event will reset the actions. Do you want to continue?',
          onOk: () => {
            setErrors(undefined)
            setFormData({ ...updated, actions: [] })
          },
          okText: 'Reset actions'
        })
      } else {
        setErrors(undefined)
        setFormData(updated)
      }
    },
    [formData]
  )

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setSaving(true)
    try {
      result = await dispatch(saveWfScenario(screen, category, formData.workflowId, formData))
    } finally {
      setSaving(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_FORM_DATA)
    }
  }, [category, screen, formData, onClose])

  const handleDelete = useCallback(
    (wfEvent: WfEventState | undefined) => {
      if (wfEvent) {
        const { id, name, workflowId, flowType } = wfEvent
        confirm({
          title: 'Delete scenario',
          content: `Do you want to delete this scenario "${name}"?`,
          onOk: async () => {
            const result: ActionResult | undefined = await dispatch(
              deleteWfScenario(category, flowType, workflowId, id)
            )
            if (result?.errors) {
              setErrors(result.errors)
            }
            if (!result?.errors) {
              typeof onClose === 'function' && onClose()
            }
          },
          okText: 'Delete',
          okType: 'danger'
        })
      }
    },
    [onClose, category]
  )

  return (
    <DrawerForm
      open={visible}
      title={data ? `Edit scenario` : `Add scenario`}
      onClose={onClose}
      confirmLoading={saving}
      width={800}
      className="mutate-wf-event-drawer"
      showDelete={data ? true : false}
      onDelete={() => handleDelete(data)}
      formId={`form-wf-event-${flowType}`}
    >
      <Form id={`form-wf-event-${flowType}`} onFinish={handleOk}>
        <Row>
          <Col span={24}>
            <Form.Item label="Name" validateStatus={errors?.name ? 'error' : ''} help={errors?.name}>
              <Input
                ref={focusRef}
                value={formData.name}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ name: event.target.value })}
              />
            </Form.Item>
          </Col>
        </Row>
        {viewLoading || wfActionsLoading ? (
          <Spin indicator={<LoadingOutlined spin />} />
        ) : (
          <>
            <Row>
              <Col span={24}>
                <div className="mutate-wf-event-drawer__section">Criteria</div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <ViewCriteriaAdvanced
                  label=""
                  screenCode={screen}
                  viewId={formData.viewId}
                  onChange={(criteria: ViewCriteria[]) => handleFormDataChange({ criteria })}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div className="mutate-wf-event-drawer__section">Event</div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item label="" validateStatus={errors?.eventType ? 'error' : ''} help={errors?.eventType}>
                  <SelectWfEventType
                    flowType={formData.flowType}
                    value={formData.eventType}
                    onChange={(eventType: string) => handleFormDataChange({ eventType })}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div className="mutate-wf-event-drawer__section">Action</div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  label=""
                  style={{ marginBottom: 0 }}
                  validateStatus={errors?.actions ? 'error' : ''}
                  help={errors?.actions}
                >
                  {formData.actions.map((action, index) => (
                    <Row gutter={15} key={index} align="middle" style={actionRowStyle}>
                      <Col flex="200px">
                        <SelectWfActionType
                          flowType={formData.flowType}
                          eventType={formData.eventType}
                          value={action.actionType}
                          onChange={(actionType: string) =>
                            handleFormDataChange({
                              actions: [
                                ...formData.actions.slice(0, index),
                                { ...formData.actions[index], actionType },
                                ...formData.actions.slice(index + 1, formData.actions.length)
                              ]
                            })
                          }
                        />
                      </Col>
                      <Col flex="1">
                        {!NO_TARGET_ACTION.includes(action.actionType as WfActionType) && (
                          <ApprovalTargets
                            style={{ width: '100%' }}
                            value={action.target ? action.target.split(Delimiter.pipe) : undefined}
                            onChange={(target?: string[]) =>
                              handleFormDataChange({
                                actions: [
                                  ...formData.actions.slice(0, index),
                                  { ...formData.actions[index], target: target?.join(Delimiter.pipe) },
                                  ...formData.actions.slice(index + 1, formData.actions.length)
                                ]
                              })
                            }
                          />
                        )}
                      </Col>
                      <Col>
                        <Link
                          onClick={() =>
                            handleFormDataChange({
                              actions: [
                                ...formData.actions.slice(0, index),
                                ...formData.actions.slice(index + 1, formData.actions.length)
                              ]
                            })
                          }
                        >
                          remove
                        </Link>
                      </Col>
                    </Row>
                  ))}
                </Form.Item>
                <Form.Item>
                  <Link onClick={() => handleFormDataChange({ actions: [...formData.actions, ...NEW_ACTIONS] })}>
                    add action
                  </Link>
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </Form>
    </DrawerForm>
  )
}
