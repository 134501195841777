import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import { Dictionary } from '@reduxjs/toolkit'
import { Form, Input, Skeleton } from '~/core-components'
import { Col, Row } from '~/components'
import { Delimiter } from '~/constants'
import { CfConfigState } from '~/features/claim/types'

interface ClaimRecordCfJsonbProps {
  ctCustomField?: string
  cfJsonb: string
  cfConfigsDict: Dictionary<CfConfigState>
  loading?: boolean
  readOnly?: boolean
  onChange?: (jsonb: string) => void
}

interface FormData {
  [key: string]: string
}

const EMPTY_FORM_DATA: FormData = {}

export const ClaimRecordCfJsonb: FC<ClaimRecordCfJsonbProps> = ({
  ctCustomField,
  cfJsonb,
  cfConfigsDict,
  loading,
  readOnly,
  onChange
}) => {
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)
  const [customFields, setCustomFields] = useState<string[]>([])

  const handleParseCustomField = useCallback((customField: string) => {
    let fields = customField?.split(Delimiter.pipe).filter(f => f !== '')

    if (fields?.length > 0) {
      setCustomFields(fields)
    } else {
      setCustomFields([])
    }
  }, [])

  useEffect(() => {
    if (ctCustomField) {
      handleParseCustomField(ctCustomField)
      try {
        const data = JSON.parse(cfJsonb || '{}') as FormData
        setFormData(data)
      } catch (error) {
        console.error(error)
      }
    } else {
      setFormData(EMPTY_FORM_DATA)
      setCustomFields([])
    }
  }, [cfJsonb, ctCustomField, handleParseCustomField])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      setFormData(formData => {
        const updated = { ...formData, ...updates }
        const value = JSON.stringify(updated)

        typeof onChange === 'function' && onChange(value)
        return updated
      })
    },
    [onChange]
  )

  return loading ? (
    <Skeleton active />
  ) : (
    <Row gutter={20}>
      {customFields.map(
        (cf, index) =>
          cfConfigsDict[cf]?.isShown && (
            <Col key={cf + index} flex={customFields.length > 1 ? '50%' : '100%'}>
              <Form.Item label={cfConfigsDict[cf]?.name}>
                {readOnly ? (
                  <>{formData[cf as keyof FormData] || '-'}</>
                ) : (
                  <Input
                    value={formData[cf as keyof FormData]}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      handleFormDataChange({ [cf]: event.target.value })
                    }
                  />
                )}
              </Form.Item>
            </Col>
          )
      )}
    </Row>
  )
}
