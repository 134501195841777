import moment from 'moment-timezone'
import { showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { apiAddCommentByToken } from '../../api/ssleave-task-token.api'
import { SSLeaveRecordCommentState } from '../../types'

export const addLeaveRecordCommentByToken = (token: string, comment: string): ThunkResult<void> => {
  return async (): Promise<ActionResult> => {
    const { status, errors, result, message, errorData } = await apiAddCommentByToken(token, comment)
    if (status) {
      const newComment = {
        id: result.id,
        createdDate: moment(result.createdDate).format('YYYY-MM-DDTHH:mm:ssZ'),
        comment,
        authorId: result.authorId,
        authorName: result.authorName,
        photoId: result.photoId
      } as SSLeaveRecordCommentState

      return { result: newComment }
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
