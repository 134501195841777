import { showSuccess, showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { apiRunOneTimeJob } from '../api/job.api'

export const runOnetimeJob = (id: string): ThunkResult<void> => {
  return async (): Promise<ActionResult> => {
    const { status, result, errors, message, errorData } = await apiRunOneTimeJob(id)
    if (status) {
      showSuccess('Completed')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
