import { setChartServiceLenLoading, setChartServiceLen } from '../../reducers'
import { apiGetServiceLength } from '../../api/em-dashboard.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchServiceLength = (options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().employee.chartServiceLenLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().employee.chartServiceLen
      if (hasData) return
    }

    try {
      dispatch(setChartServiceLenLoading(true))
      const { result, status } = await apiGetServiceLength()
      if (status) {
        dispatch(setChartServiceLen(result))
      }
    } finally {
      dispatch(setChartServiceLenLoading(false))
    }
  }
}
