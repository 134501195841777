import React, { Component } from 'react'
// @ts-ignore
import OrgChart from '@unicef/react-org-chart'
import { SSOrgChartState } from '~/features/my/types/ssorgchart.state'
import './OrgChart.less'

export interface OrgChartProps {
  tree: SSOrgChartState
  loadParent: (data: SSOrgChartState) => SSOrgChartState | Promise<SSOrgChartState>
  loadChildren: (data: SSOrgChartState) => SSOrgChartState[] | Promise<SSOrgChartState[]>
  loadImage: (data: SSOrgChartState) => Promise<any>
}

interface OrgChartState {
  tree: SSOrgChartState
  config: any
}

export default class OrgsChart extends Component<OrgChartProps, OrgChartState> {
  constructor(props: OrgChartProps) {
    super(props)

    this.state = {
      tree: props.tree,
      config: {}
    }
  }

  handleOnChangeConfig = (config: any) => {
    this.setState({ config })
  }

  handleLoadConfig = () => {
    const { config } = this.state
    return config
  }

  render() {
    return (
      <OrgChart
        {...this.props}
        onConfigChange={(config: any) => {
          this.handleOnChangeConfig(config)
        }}
        loadConfig={() => {
          let configuration = this.handleLoadConfig()
          if (configuration) {
            return configuration
          }
        }}
      />
    )
  }
}

export { OrgsChart }
