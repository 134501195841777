import React, { FC, useCallback, useState } from 'react'
import { Card, ColumnsType, Link, PageHeader, Switch, Table } from '~/core-components'
import { DocumentTitle } from '~/components'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { MutateCurrencyDrawer } from './components/MutateCurrencyDrawer'
import { updateCurrency } from '../../actions'
import { CurrencyState } from '../../types'
import { mapCurrencyStateToCurrency } from '../../types/currency.mapper'
import { useCurrencies } from '../../hooks'
import './Currencies.less'

type CurrencyTable = CurrencyState

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: '',
    breadcrumbName: 'Overview'
  }
]

interface DrawerState {
  visible: boolean
  data?: CurrencyState
}
const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

interface CurrencyProps {}

export const Currencies: FC<CurrencyProps> = () => {
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const [currencies, loading] = useCurrencies()
  const [updatingVisible, setUpdatingVisible] = useState<string>()
  const canModify = usePermissionGate(Permission.claCurrency, PermissionAction.Modify)

  const handleEditCurrency = useCallback((currency: CurrencyState) => {
    setDrawerState({ visible: true, data: currency })
  }, [])

  const handleVisibleChange = useCallback(async (currency: CurrencyState, isShown: boolean) => {
    setUpdatingVisible(currency.code)
    try {
      const current = mapCurrencyStateToCurrency(currency)
      await dispatch(updateCurrency(currency.code, current, { ...current, isShown }))
    } finally {
      setUpdatingVisible(undefined)
    }
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const columns: ColumnsType<CurrencyTable> = [
    {
      title: 'Code',
      key: 'code',
      dataIndex: 'code',
      render: (value: string, record) => value.toUpperCase()
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name'
    },
    {
      title: 'Visible',
      key: 'isShown',
      dataIndex: 'isShown',
      align: 'center',
      width: 100,
      render: (value: boolean, record) => (
        <Switch
          checkedChildren="Shown"
          unCheckedChildren="Hidden"
          checked={record.isShown}
          loading={updatingVisible === record.code}
          disabled={!canModify}
          onChange={(checked: boolean) => {
            handleVisibleChange(record, checked)
          }}
        />
      )
    },
    {
      key: 'action',
      align: 'right',
      render: (value: string, record) =>
        !record.isSeed && (
          <Link size="small" onClick={() => handleEditCurrency(record)}>
            edit
          </Link>
        )
    }
  ]

  return (
    <div id="currencies" className="currencies">
      <DocumentTitle title="Currencies" />
      <PageHeader title="Currencies" containerId="currencies" breadcrumb={{ routes }} />
      <div className="currencies__body">
        <Card fitParent table>
          <Table
            rowKey="code"
            dataSource={currencies}
            pagination={false}
            columns={columns}
            loading={loading}
            rowClassName={record => (record.isShown ? 'currencies__table-row--shown' : '')}
          />
        </Card>
        {canModify && <MutateCurrencyDrawer {...drawerState} onClose={handleCloseDrawer} />}
      </div>
    </div>
  )
}
