import { ActionResult, ThunkResult } from '~/types/store'
import { emptyGuid } from '~/constants'
import { showSuccess, showError } from '~/utils'
import { apiAddEmStatutoryExempt } from '../api/em-statutory-exempt.api'
import { setEmStatutoryExempt } from '../reducers'
import { IEmStatutoryExemptInfo } from '../types'
import { mapEmStatutoryExemptToEmStatutoryExemptState } from '../types/em-details.mapper'

export const addEmStatutoryExempt = (
  employeeId: string,
  emStatutoryExempt: IEmStatutoryExemptInfo
): ThunkResult<void> => {
  return async (dispatch): Promise<ActionResult | undefined> => {
    if (!employeeId) return

    const { status, result, errors, message, errorData } = await apiAddEmStatutoryExempt(employeeId, {
      id: emptyGuid,
      employeeId,
      ...emStatutoryExempt
    })
    if (status) {
      const newEmStatutoryExempt = {
        id: result.id,
        employeeId,
        ...emStatutoryExempt
      }
      const newEmStatutoryExemptState = mapEmStatutoryExemptToEmStatutoryExemptState(newEmStatutoryExempt)
      dispatch(setEmStatutoryExempt({ employeeId, data: newEmStatutoryExemptState }))

      showSuccess('Added')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
