import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { PayWarningState } from '../types'

export const selectPayWarningsCount = createSelector(
  (state: StoreState) => state.payroll.payWarnings,
  payWarningsState =>
    memoize((payRunId: string) => {
      const payWarnings = (Object.values(payWarningsState[payRunId]?.entities || {}) as PayWarningState[]).filter(
        w => !w.verifiedBy
      )
      return payWarnings?.length || 0
    })
)
