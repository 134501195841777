import { replaceMyClaimRecordComments, setMyClaimRecordCommentsLoading } from '../reducers'
import { apiGetMyClaimRecordComments } from '../api/ss-claim-record-comment.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchMyClaimRecordComments = (
  id: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const loading = getState().myClaim.myClaimRecordCommentsLoading[id]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().myClaim.myClaimRecordComments[id]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setMyClaimRecordCommentsLoading({ id, loading: true }))
      const { result, status } = await apiGetMyClaimRecordComments(id)
      if (status) dispatch(replaceMyClaimRecordComments({ id, data: result }))
    } finally {
      dispatch(setMyClaimRecordCommentsLoading({ id, loading: false }))
    }
  }
}
