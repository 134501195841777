import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { EmHireState } from '../types'

export const selectEmHires = createSelector(
  (state: StoreState) => state.employee.emHires,
  emHiresState =>
    memoize((id: string) => {
      return (Object.values(emHiresState[id]?.entities || {}) as EmHireState[]).sort((a, b) => {
        const aHireDate = new Date(a!.hireDate).getTime()
        const bHireDate = new Date(b!.hireDate).getTime()
        return bHireDate - aHireDate
      })
    })
)
