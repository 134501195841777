import { replaceYtdSubmissionEmployees, setYtdSubmissionEmployeesLoading } from '../reducers'
import { apiGetYtdSubmissionEmployees } from '../api/ytd.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchYtdSubmissionEmployees = (
  ytdFormType: string,
  ytdSubmissionId: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!ytdSubmissionId) return

    const loading = getState().tax.ytdSubmissionEmployeesLoading[ytdFormType]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().tax.ytdSubmissionEmployees[ytdFormType]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setYtdSubmissionEmployeesLoading({ ytdFormType, loading: true }))
      const { result, status } = await apiGetYtdSubmissionEmployees(ytdFormType, ytdSubmissionId)
      if (status) {
        dispatch(replaceYtdSubmissionEmployees({ ytdFormType, data: result }))
      }
    } finally {
      dispatch(setYtdSubmissionEmployeesLoading({ ytdFormType, loading: false }))
    }
  }
}
