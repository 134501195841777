import React, { FC, useState, useCallback, useEffect, ChangeEvent, CSSProperties, useMemo } from 'react'
import moment from 'moment-timezone'
import confirm from 'antd/lib/modal/confirm'
import { Checkbox, CheckboxChangeEvent, Form, Input, Link, Radio, RadioChangeEvent } from '~/core-components'
import { Col, DrawerForm, Row } from '~/components'
import { dispatch } from '~/stores/store'
import { addCalendarWithPatterns, deleteMaster } from '../../../actions'
import { mapCalendarOverviewStateToCalendarAndPattern } from '../../../types/master.mapper'
import { ActionResult, Errors } from '~/types/store'
import { useFocus } from '~/hooks/use-focus'
import { MasterConfigs } from '../MasterConfigs'
import { CalendarOverviewState } from '../../../selectors'
import { CalendarWeekState, MasterName } from '../../../types'

export interface MutateCalendarDrawerProps {
  masterName: MasterName
  visible: boolean
  data?: CalendarOverviewState
  onClose: () => void
}

const EMPTY_FORM_DATA: CalendarOverviewState = {
  id: '',
  name: '',
  patternStartDate: '',
  weeks: [{ mon: {}, tue: {}, wed: {}, thu: {}, fri: {}, sat: {}, sun: {} }]
}

const radioStyle: CSSProperties = { display: 'block', textAlign: 'left', margin: 0 }
const radioLabelStyle: CSSProperties = { display: 'block', textAlign: 'left', marginTop: 6 }
const dowStyle: CSSProperties = { marginBottom: -3 }

export const MutateCalendarDrawer: FC<MutateCalendarDrawerProps> = ({
  masterName,
  visible,
  data,
  onClose
}: MutateCalendarDrawerProps) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<CalendarOverviewState>(EMPTY_FORM_DATA)
  const [focusRef, setFocus] = useFocus(true)
  const [errors, setErrors] = useState<Errors>()
  const [isInactive, setIsInactive] = useState(false)
  const configs = MasterConfigs[masterName]

  const NEW_WEEK: CalendarWeekState = useMemo(
    () => ({ mon: {}, tue: {}, wed: {}, thu: {}, fri: {}, sat: {}, sun: {} }),
    []
  )

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  useEffect(() => {
    if (data) {
      setFormData({
        id: data.id,
        name: data.name,
        patternStartDate: data.patternStartDate,
        weeks: data.weeks,
        inactiveDate: data.inactiveDate
      })
      setIsInactive(!!data.inactiveDate)
    } else {
      setFormData(EMPTY_FORM_DATA)
      setIsInactive(false)
    }
  }, [data])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      const updated = { ...formData, ...updates }

      if (updated.weeks.length <= 1) {
        updated.patternStartDate = ''
      }

      setFormData(updated)
    },
    [formData]
  )

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      result = await dispatch(addCalendarWithPatterns(mapCalendarOverviewStateToCalendarAndPattern(formData)))
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_FORM_DATA)
    }
  }, [formData, onClose])

  const handleDelete = useCallback(
    (master: CalendarOverviewState | undefined) => {
      if (master) {
        const { id, name } = master
        confirm({
          title: `Delete ${configs.name.toLowerCase()}`,
          content: `Do you want to delete ${configs.name.toLowerCase()} record of "${name}"?`,
          onOk: async () => {
            const result: ActionResult | undefined = await dispatch(deleteMaster(masterName, id))
            if (result?.errors) {
              setErrors(result.errors)
            }

            if (!result?.errors) {
              typeof onClose === 'function' && onClose()
            }
          },
          okText: 'Delete',
          okType: 'danger'
        })
      }
    },
    [masterName, configs.name, onClose]
  )

  return (
    <DrawerForm
      open={visible}
      title={data ? `Edit ${configs.name.toLowerCase()}` : `Add ${configs.name.toLowerCase()}`}
      onClose={onClose}
      confirmLoading={loading}
      width={550}
      showDelete={data ? true : false}
      onDelete={() => handleDelete(data)}
      formId="form-calendar"
    >
      <Form id="form-calendar" onFinish={handleOk}>
        <Row gutter={15}>
          <Col flex="auto">
            <Form.Item label="Name" validateStatus={errors?.name ? 'error' : ''} help={errors?.name}>
              <Input
                ref={focusRef}
                value={formData.name}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ name: event.target.value })}
              />
            </Form.Item>
          </Col>
          <Col flex="none">
            <Form.Item
              label="Inactive"
              validateStatus={errors?.inactiveDate ? 'error' : ''}
              help={errors?.inactiveDate}
            >
              <Checkbox
                checked={isInactive}
                onChange={(event: CheckboxChangeEvent) => {
                  setIsInactive(event.target.checked)
                  handleFormDataChange({ inactiveDate: event.target.checked ? moment().format('YYYY-MM-DD') : '' })
                }}
              />
              {/* {isInactive && (
                <Input.Date
                  value={formData.inactiveDate ? moment(formData.inactiveDate) : undefined}
                  onChange={(value: moment.Moment | null) =>
                    handleFormDataChange({ inactiveDate: value?.format('YYYY-MM-DD') })
                  }
                />
              )} */}
            </Form.Item>
          </Col>
        </Row>
        {formData.weeks.map((w, index) => (
          <div key={index} style={{ textAlign: 'center' }}>
            <Row gutter={10} style={dowStyle} align="middle">
              <Col span={6}></Col>
              <Col span={2}>Mon</Col>
              <Col span={2}>Tue</Col>
              <Col span={2}>Wed</Col>
              <Col span={2}>Thu</Col>
              <Col span={2}>Fri</Col>
              <Col span={2}>Sat</Col>
              <Col span={2}>Sun</Col>
              <Col flex={1}></Col>
            </Row>
            <Row gutter={10} align="middle" style={{ marginBottom: 15 }}>
              <Col span={6}>
                <div style={radioLabelStyle}>Full day</div>
                <div style={radioLabelStyle}>Half day</div>
                <div style={radioLabelStyle}>None</div>
              </Col>
              <Col span={2}>
                <Radio.Group
                  value={w.mon?.workStatus}
                  onChange={(event: RadioChangeEvent) =>
                    handleFormDataChange({
                      weeks: [
                        ...formData.weeks.slice(0, index),
                        {
                          ...formData.weeks[index],
                          mon: { ...formData.weeks[index].mon, workStatus: event.target.value }
                        },
                        ...formData.weeks.slice(index + 1, formData.weeks.length)
                      ]
                    })
                  }
                >
                  <Radio style={radioStyle} value="f"></Radio>
                  <Radio style={radioStyle} value="h1"></Radio>
                  <Radio style={radioStyle} value="o"></Radio>
                </Radio.Group>
              </Col>
              <Col span={2}>
                <Radio.Group
                  value={w.tue?.workStatus}
                  onChange={(event: RadioChangeEvent) =>
                    handleFormDataChange({
                      weeks: [
                        ...formData.weeks.slice(0, index),
                        {
                          ...formData.weeks[index],
                          tue: { ...formData.weeks[index].tue, workStatus: event.target.value }
                        },
                        ...formData.weeks.slice(index + 1, formData.weeks.length)
                      ]
                    })
                  }
                >
                  <Radio style={radioStyle} value="f"></Radio>
                  <Radio style={radioStyle} value="h1"></Radio>
                  <Radio style={radioStyle} value="o"></Radio>
                </Radio.Group>
              </Col>
              <Col span={2}>
                <Radio.Group
                  value={w.wed?.workStatus}
                  onChange={(event: RadioChangeEvent) =>
                    handleFormDataChange({
                      weeks: [
                        ...formData.weeks.slice(0, index),
                        {
                          ...formData.weeks[index],
                          wed: { ...formData.weeks[index].wed, workStatus: event.target.value }
                        },
                        ...formData.weeks.slice(index + 1, formData.weeks.length)
                      ]
                    })
                  }
                >
                  <Radio style={radioStyle} value="f"></Radio>
                  <Radio style={radioStyle} value="h1"></Radio>
                  <Radio style={radioStyle} value="o"></Radio>
                </Radio.Group>
              </Col>
              <Col span={2}>
                <Radio.Group
                  value={w.thu?.workStatus}
                  onChange={(event: RadioChangeEvent) =>
                    handleFormDataChange({
                      weeks: [
                        ...formData.weeks.slice(0, index),
                        {
                          ...formData.weeks[index],
                          thu: { ...formData.weeks[index].thu, workStatus: event.target.value }
                        },
                        ...formData.weeks.slice(index + 1, formData.weeks.length)
                      ]
                    })
                  }
                >
                  <Radio style={radioStyle} value="f"></Radio>
                  <Radio style={radioStyle} value="h1"></Radio>
                  <Radio style={radioStyle} value="o"></Radio>
                </Radio.Group>
              </Col>
              <Col span={2}>
                <Radio.Group
                  value={w.fri?.workStatus}
                  onChange={(event: RadioChangeEvent) =>
                    handleFormDataChange({
                      weeks: [
                        ...formData.weeks.slice(0, index),
                        {
                          ...formData.weeks[index],
                          fri: { ...formData.weeks[index].fri, workStatus: event.target.value }
                        },
                        ...formData.weeks.slice(index + 1, formData.weeks.length)
                      ]
                    })
                  }
                >
                  <Radio style={radioStyle} value="f"></Radio>
                  <Radio style={radioStyle} value="h1"></Radio>
                  <Radio style={radioStyle} value="o"></Radio>
                </Radio.Group>
              </Col>
              <Col span={2}>
                <Radio.Group
                  value={w.sat?.workStatus}
                  onChange={(event: RadioChangeEvent) =>
                    handleFormDataChange({
                      weeks: [
                        ...formData.weeks.slice(0, index),
                        {
                          ...formData.weeks[index],
                          sat: { ...formData.weeks[index].sat, workStatus: event.target.value }
                        },
                        ...formData.weeks.slice(index + 1, formData.weeks.length)
                      ]
                    })
                  }
                >
                  <Radio style={radioStyle} value="f"></Radio>
                  <Radio style={radioStyle} value="h1"></Radio>
                  <Radio style={radioStyle} value="o"></Radio>
                </Radio.Group>
              </Col>
              <Col span={2}>
                <Radio.Group
                  value={w.sun?.workStatus}
                  onChange={(event: RadioChangeEvent) =>
                    handleFormDataChange({
                      weeks: [
                        ...formData.weeks.slice(0, index),
                        {
                          ...formData.weeks[index],
                          sun: { ...formData.weeks[index].sun, workStatus: event.target.value }
                        },
                        ...formData.weeks.slice(index + 1, formData.weeks.length)
                      ]
                    })
                  }
                >
                  <Radio style={radioStyle} value="f"></Radio>
                  <Radio style={radioStyle} value="h1"></Radio>
                  <Radio style={radioStyle} value="o"></Radio>
                </Radio.Group>
              </Col>
              <Col flex={1}>
                <Link
                  onClick={() =>
                    handleFormDataChange({
                      weeks: [
                        ...formData.weeks.slice(0, index),
                        ...formData.weeks.slice(index + 1, formData.weeks.length)
                      ]
                    })
                  }
                >
                  remove
                </Link>
              </Col>
            </Row>
          </div>
        ))}
        <Form.Item>
          <Link
            onClick={() =>
              handleFormDataChange({
                weeks: [...formData.weeks, NEW_WEEK]
              })
            }
          >
            add more
          </Link>
        </Form.Item>
        {formData.weeks.length > 1 && (
          <Row>
            <Col>
              <Form.Item
                label="Cycle start date"
                validateStatus={errors?.patternStartDate ? 'error' : ''}
                help={errors?.patternStartDate}
                tooltip={{
                  title: `Calendar start date must be a Monday and precede the employee's work calendar effective date.`,
                  icon: (
                    <span>
                      <i className="fal fa-circle-info" />
                    </span>
                  )
                }}
              >
                <Input.Date
                  allowClear={false}
                  value={formData.patternStartDate ? moment(formData.patternStartDate) : undefined}
                  onChange={(value: moment.Moment | null) => {
                    handleFormDataChange({ patternStartDate: value?.format('YYYY-MM-DD') })
                  }}
                  disabledDate={current => current && current.day() !== 1}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    </DrawerForm>
  )
}
