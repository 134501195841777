import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiDeleteMaster } from '../api/master.api'
import { removeKeyvalue, removeMaster } from '../reducers'
import { MasterName } from '../types'

export const deleteMaster = (masterName: MasterName, id: string): ThunkResult<void> => {
  return async dispatch => {
    if (!masterName || !id) return

    const { status, errors, message, errorData } = await apiDeleteMaster(masterName, id)
    if (status) {
      dispatch(removeMaster({ masterName, id }))

      if (masterName === 'payGroup') {
        dispatch(removeKeyvalue({ kv: 'payGroup', id }))
      }

      if (masterName === 'leaveGroup') {
        dispatch(removeKeyvalue({ kv: 'leaveGroup', id }))
      }

      showSuccess('Deleted')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
