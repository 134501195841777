import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchOedRecordForm } from '../actions'
import { OedRecordFormState } from '../types'

export const useOedRecord = (id?: string): [OedRecordFormState | undefined, boolean] => {
  const oedRecord = useSelector((state: StoreState) => state.report.oedRecordForm.entities[id || ''])
  const loading = useSelector((state: StoreState) => state.report.oedRecordFormLoading)
  const refetch = useSelector((state: StoreState) => state.report.oedRecordFormRefetch)

  useEffect(() => {
    if (id) dispatch(fetchOedRecordForm(id))
  }, [id, refetch])

  return [oedRecord, loading]
}
