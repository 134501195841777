import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { Card, CardProps } from '~/core-components'
import { StoreState } from '~/types/store'
import { MyClaimBalanceList } from './MyClaimBalanceList'
import { MyClaimTakenList } from './MyClaimTakenList'
import { MyClaimApplyButton } from '../MyClaimApply/MyClaimApplyButton'
import './MyClaimBalance.less'

interface MyClaimBalanceProps extends CardProps {}

export const MyClaimBalance: FC<MyClaimBalanceProps> = ({ className, ...props }) => {
  const classes = classNames('my-claim-balance', { [`${className}`]: className })
  const loading = useSelector((state: StoreState) => state.myClaim.myClaimBalancesLoading)

  return (
    <Card title="My claim balance" loading={loading} className={classes} {...props}>
      <MyClaimBalanceList />
      <MyClaimTakenList />
      <MyClaimApplyButton>Apply claim</MyClaimApplyButton>
    </Card>
  )
}
