import pick from 'lodash/pick'
import { setViewSchema, setViewSchemaLoading } from '../reducers'
import { apiGetDefaultViewSchema } from '../api/view.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'
import { Screen, ViewSchemaState } from '../types'
import { View } from '~/constants'

export const fetchDefaultViewSchema = (
  screenCode: Screen,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!screenCode) return

    const viewSchemaLoading = getState().selection.viewSchemaLoading[screenCode]
    const loading = viewSchemaLoading && viewSchemaLoading['default']
    if (loading) return

    if (options.strategy === 'when-empty') {
      const data = Object.values(getState().selection.viewSchema[screenCode]?.entities || {})
      const hasData = data?.some(v => v?.viewName === View.default)
      if (hasData) return
    }

    try {
      dispatch(setViewSchemaLoading({ screenCode, viewId: 'default', loading: true }))
      const { result, status } = await apiGetDefaultViewSchema(screenCode)
      if (status) {
        const stored = mapToState(result)
        dispatch(setViewSchema({ screenCode, data: stored }))
      }
    } finally {
      dispatch(setViewSchemaLoading({ screenCode, viewId: 'default', loading: false }))
    }
  }
}

const mapToState = (viewSchema: ViewSchemaState): ViewSchemaState => ({
  ...viewSchema,
  selection: viewSchema.selection.map(f => pick(f, 'id', 'selectionFieldId', 'sequence')),
  criteria: viewSchema.criteria.map(c =>
    pick(c, 'id', 'parentId', 'criteriaType', 'criteriaFieldId', 'operator', 'value', 'valueTo', 'sequence')
  )
})
