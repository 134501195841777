import React, { FC, useCallback, useEffect, useState } from 'react'
import { DrawerForm } from '~/components'
import { dispatch } from '~/stores/store'
import { ActionResult } from '~/types/store'
import { fetchViewSchema, updateViewSelection } from '../actions'
import { useFetchSysSelection } from '../hooks'
import { Screen, ViewSelectionField } from '../types'
import { ViewSelection } from './ViewSelection'

interface ViewSelectionDrawerProps {
  visible: boolean
  screenCode: Screen
  viewId: string
  title?: string
  description?: string
  onClose: (changed?: boolean) => void
}

export const ViewSelectionDrawer: FC<ViewSelectionDrawerProps> = ({
  visible,
  screenCode,
  viewId,
  title,
  description,
  onClose
}) => {
  const [loading, setLoading] = useState(false)
  const [selected, setSelected] = useState<ViewSelectionField[]>([])

  // is called upon parent screen shown
  useFetchSysSelection(screenCode)

  useEffect(() => {
    if (viewId) dispatch(fetchViewSchema(screenCode, viewId, { strategy: 'when-empty' }))
  }, [screenCode, viewId])

  const handleSelect = useCallback((value: ViewSelectionField[]) => {
    setSelected(value)
  }, [])

  const handleSave = useCallback(async () => {
    setLoading(true)
    try {
      const result: ActionResult | undefined = await dispatch(
        updateViewSelection(screenCode, viewId, { id: viewId, selection: selected })
      )

      if (!result?.errors) {
        typeof onClose === 'function' && onClose(true)
      }
    } finally {
      setLoading(false)
    }
  }, [screenCode, viewId, selected, onClose])

  return (
    <DrawerForm
      open={visible}
      title={title || 'Configure columns'}
      onOk={handleSave}
      onClose={() => onClose()}
      okText="Apply"
      width={700}
      confirmLoading={loading}
      className="view-selection-drawer"
    >
      <ViewSelection
        screenCode={screenCode}
        viewId={viewId}
        title={title}
        description={description}
        onSelect={handleSelect}
      />
    </DrawerForm>
  )
}
