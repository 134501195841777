import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'
import { replaceSsAccessConfigs, setSsAccessConfigsLoading } from '../reducers'
import { apiGetSsAccessConfigs } from '../api/ss-access-config.api'

export const fetchSsAccessConfigs = (options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().master.ssAccessConfigsLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().master.ssAccessConfigs?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setSsAccessConfigsLoading(true))
      const { result, status } = await apiGetSsAccessConfigs()
      if (status) {
        dispatch(replaceSsAccessConfigs(result))
      }
    } finally {
      dispatch(setSsAccessConfigsLoading(false))
    }
  }
}
