import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { WorkflowState, WfCategory } from '../types'

export const selectWorkflows = createSelector(
  (state: StoreState) => state.workflow.workflows,
  workflowsState =>
    memoize((category: WfCategory): WorkflowState[] => {
      const workflows = Object.values(workflowsState[category]?.entities || {}) as WorkflowState[]
      return workflows
    })
)
