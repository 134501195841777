import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Dictionary } from '@reduxjs/toolkit'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchLtSettings } from '../actions'
import { LtSettingState } from '../types'
import { selectLtSettingsById } from '../selectors'

export const useLtSettings = (leaveTypeId: string): [Dictionary<LtSettingState>, boolean] => {
  const ltSettings = useSelector(selectLtSettingsById)(leaveTypeId)
  const loading = useSelector((state: StoreState) => state.leave.ltSettingsLoading[leaveTypeId])

  useEffect(() => {
    dispatch(fetchLtSettings(leaveTypeId, { strategy: 'when-empty' }))
  }, [leaveTypeId])

  return [ltSettings, loading]
}
