import React, { FC, useState, useCallback, useEffect, ChangeEvent } from 'react'
import confirm from 'antd/lib/modal/confirm'
import { Form, Input } from '~/core-components'
import { Col, DrawerForm, Row } from '~/components'
import { dispatch } from '~/stores/store'
import { addFormulaDetail, deleteFormulaDetail, updateFormulaDetail } from '../../actions'
import { IFormulaDetailInfo, FormulaDetailState } from '../../types'
import { mapFormulaDetailStateToFormulaDetail } from '../../types/formula.mapper'
import { ActionResult, Errors } from '~/types/store'
import { useFocus } from '~/hooks/use-focus'

export interface MutateFormulaDetailDrawerProps {
  visible: boolean
  formulaId?: string
  data?: FormulaDetailState
  onClose: () => void
}

const EMPTY_FORM_DATA: IFormulaDetailInfo = {
  sequence: 0,
  expression: '',
  criteria: '',
  prorationType: ''
}

export const MutateFormulaDetailDrawer: FC<MutateFormulaDetailDrawerProps> = ({
  visible,
  formulaId,
  data,
  onClose
}: MutateFormulaDetailDrawerProps) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<IFormulaDetailInfo>(EMPTY_FORM_DATA)
  const [focusRef, setFocus] = useFocus(true)
  const [errors, setErrors] = useState<Errors>()

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  useEffect(() => {
    if (data) {
      const { sequence, expression, criteria, prorationType } = data
      setFormData({ sequence, expression, criteria, prorationType })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      const updated = { ...formData, ...updates }
      setFormData(updated)
    },
    [formData]
  )

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      if (data) {
        result = await dispatch(
          updateFormulaDetail(data.formulaId, data.id, mapFormulaDetailStateToFormulaDetail(data), formData)
        )
      } else if (formulaId) {
        result = await dispatch(addFormulaDetail(formulaId, formData))
      }
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data, formulaId, formData, onClose])

  const handleDelete = useCallback(
    (item: FormulaDetailState | undefined) => {
      if (item && formulaId) {
        const { id } = item
        confirm({
          title: 'Delete expression',
          content: 'Do you want to delete this expression?',
          onOk: async () => {
            const result: ActionResult | undefined = await dispatch(deleteFormulaDetail(formulaId, id))
            if (result?.errors) {
              setErrors(result.errors)
            }

            if (!result?.errors) {
              typeof onClose === 'function' && onClose()
            }
          },
          okText: 'Delete',
          okType: 'danger'
        })
      }
    },
    [onClose, formulaId]
  )

  return (
    <DrawerForm
      open={visible}
      title={data ? 'Edit expression' : 'Add expression'}
      onClose={onClose}
      confirmLoading={loading}
      width={500}
      showDelete={data ? true : false}
      onDelete={() => handleDelete(data)}
      formId="form-formula-detail"
    >
      <Form id="form-formula-detail" onFinish={handleOk}>
        <Row>
          <Col span={24}>
            <Form.Item label="Expression" validateStatus={errors?.expression ? 'error' : ''} help={errors?.expression}>
              <Input.TextArea
                ref={focusRef}
                rows={5}
                value={formData.expression}
                onChange={(value?: ChangeEvent<HTMLTextAreaElement>) =>
                  handleFormDataChange({ expression: value?.target.value })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Criteria" validateStatus={errors?.criteria ? 'error' : ''} help={errors?.criteria}>
              <Input.TextArea
                rows={5}
                value={formData.criteria}
                onChange={(value?: ChangeEvent<HTMLTextAreaElement>) =>
                  handleFormDataChange({ criteria: value?.target.value })
                }
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}
