import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { EmDivisionState, IEmDivision } from '../types'

const baseUrl = getBaseUrl('/employee/employee')

export const apiGetEmDivisions = (employeeId: string) =>
  request<EmDivisionState[]>('get', `${baseUrl}/${employeeId}/division`)

export const apiGetEmDivision = (employeeId: string, emDivisionId: string) =>
  request<EmDivisionState>('get', `${baseUrl}/${employeeId}/division/${emDivisionId}`)

export const apiAddEmDivision = (employeeId: string, emDivision: IEmDivision) =>
  request<{ id: string; emDivisionId?: string }>('post', `${baseUrl}/${employeeId}/division`, emDivision)

export const apiUpdateEmDivision = (employeeId: string, id: string, emDivision: Operation[]) =>
  request<{ emDivisionId?: string }>('patch', `${baseUrl}/${employeeId}/division/${id}`, emDivision)

export const apiDeleteEmDivision = (employeeId: string, id: string) =>
  request<{ emDivisionId?: string }>('delete', `${baseUrl}/${employeeId}/division/${id}`)
