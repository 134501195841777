import { Store } from 'redux'
import { getStoredState, REHYDRATE } from 'redux-persist'
import { PersistConfig } from 'redux-persist/es/types'
import { authInstance } from '~/auth'
import { StoreState } from '../types/store'

let loggingOut = false

export const synchronizeStore = async (
  store: Store<StoreState, any>,
  persistConfig: PersistConfig<any, any, any, any>
) => {
  let state = await getStoredState(persistConfig)

  if (store) {
    if (loggingOut) return

    const rootState = store.getState()
    if (!rootState.iam.myJwt?.loginId) {
      // Logging out other tabs when current tab is logged out
      loggingOut = true
      authInstance.logout()
      return
    }

    store.dispatch({
      type: REHYDRATE,
      key: persistConfig.key,
      payload: state
    })
  }
}
