import { replaceOfficesExtra, setOfficesExtraLoading } from '../reducers'
import { apiGetOfficesExtra } from '../api/office.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchOfficesExtra = (options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().master.officesExtraLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().master.officesExtra?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setOfficesExtraLoading(true))
      const { result, status } = await apiGetOfficesExtra()
      if (status) {
        dispatch(replaceOfficesExtra(result))
      }
    } finally {
      dispatch(setOfficesExtraLoading(false))
    }
  }
}
