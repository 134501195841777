import React, { FC } from 'react'
import moment from 'moment-timezone'
import { Collapse, List, Space, Tag } from '~/core-components'
import { PersonAvatar } from '~/components'
import { useShiftsDict } from '~/features/attendance'
import { SSRealTimeAttendanceState } from '~/features/my/types'
import { getBaseUrl } from '~/utils'
import './SSRealTimeAttendanceList.less'

interface SSRealTimeAttendanceListProps {
  data: SSRealTimeAttendanceState[]
}

const baseUrl = getBaseUrl('/filestore')

export const SSRealTimeAttendanceList: FC<SSRealTimeAttendanceListProps> = ({ data }) => {
  const [shifts] = useShiftsDict()

  return (
    <List
      className="ssreal-time-attendance-list"
      size="small"
      dataSource={data}
      renderItem={(item: SSRealTimeAttendanceState) => {
        return (
          <List.Item key={item.employeeId}>
            {item.details?.length > 0 ? (
              <Collapse expandIconPosition="end">
                <Collapse.Panel key={item.employeeId} header={<AttendanceRowItem item={item} />}>
                  {item.details.map(d => (
                    <div className="ssreal-time-attendance-list__details">
                      <Space>
                        <div className="ssreal-time-attendance-list__details-time">
                          {moment(d.timeIn).format('hh:mm A')}
                        </div>
                        <div className="ssreal-time-attendance-list__details-separator">
                          <i className="fal fa-chevron-right" />
                        </div>
                        <div className="ssreal-time-attendance-list__details-time">
                          {moment(d.timeOut).format('hh:mm A')}
                        </div>
                      </Space>
                      {d.shiftId && <Tag type="primary">{shifts[d.shiftId]?.name}</Tag>}
                    </div>
                  ))}
                </Collapse.Panel>
              </Collapse>
            ) : (
              <div className="attendance-row-item--no-expand">
                <AttendanceRowItem item={item} />
              </div>
            )}
          </List.Item>
        )
      }}
    />
  )
}

interface AttendanceRowItemProps {
  item: SSRealTimeAttendanceState
}

const AttendanceRowItem: FC<AttendanceRowItemProps> = ({ item }) => {
  const [shifts] = useShiftsDict()

  return (
    <div className="attendance-row-item__person">
      <div className="attendance-row-item__person__avatar">
        <PersonAvatar size={32} photo={`${baseUrl}/publicfile/${item.photoId}/thumbnailphoto/32`} />
        {item.timeIn && !item.timeOut && <div className="attendance-row-item__person__onshift" />}
        {item.timeIn && item.timeOut && <div className="attendance-row-item__person__offshift" />}
        {!item.timeIn && !item.timeIn && <div className="attendance-row-item__person__absent" />}
      </div>
      <div className="attendance-row-item__person__info">
        <h1>{item.employeeName}</h1>
        {item.shiftId && <Tag type="primary">{shifts[item.shiftId]?.name}</Tag>}
      </div>
      {item.timeIn && (
        <div className="attendance-row-item__person__clocked">
          <div>{moment(item.timeIn).format('h:mm A')}</div>
          <div>{item.timeOut ? moment(item.timeOut).format('h:mm A') : '-'}</div>
        </div>
      )}
    </div>
  )
}
