import React, { FC, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Button, Tooltip } from '~/core-components'
import { dispatch } from '~/stores/store'
import { ActionResult, StoreState } from '~/types/store'
import { processLeave } from '../../../actions'
import { refetchLeaveEntitlementsView } from '../../../reducers'
import { LeaveProcessDto } from '../../../types'

interface LeaveRefreshButtonProps {}

const EMPTY_RECAL_DATA: LeaveProcessDto = {
  employeeIds: [],
  leaveTypeIds: [],
  silentError: true
}

export const LeaveRefreshButton: FC<LeaveRefreshButtonProps> = () => {
  const leaveProcessing = useSelector((state: StoreState) => state.leave.leaveLoading)

  const handleRefreshClick = useCallback(async () => {
    const result: ActionResult | undefined = await dispatch(processLeave(EMPTY_RECAL_DATA))
    if (!result?.errors) {
      dispatch(refetchLeaveEntitlementsView())
    }
  }, [])

  return (
    <Tooltip title="Recalculate all entitlement">
      <Button icon={<i className="fal fa-refresh" />} onClick={handleRefreshClick} loading={leaveProcessing} />
    </Tooltip>
  )
}
