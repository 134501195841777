import { showError, showSuccess } from '~/utils'
import { ThunkResult } from '~/types/store'
import { IROBulkAddScheduleRecord } from '../types'
import { refetchROScheduleRecordsView } from '../reducers'
import { apiBulkAddScheduleRecordsByRole } from '../api/ro-schedule-record.api'

export const bulkAddScheduleRecordsByRole = (
  role: string | undefined,
  request: IROBulkAddScheduleRecord
): ThunkResult<void> => {
  return async dispatch => {
    if (!role) return

    const { status, errors, message, errorData } = await apiBulkAddScheduleRecordsByRole(role, request)
    if (status) {
      dispatch(refetchROScheduleRecordsView())
      showSuccess('Added')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
