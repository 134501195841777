import { request, getBaseUrl, timeout, toQueryParams } from '~/utils/request'
import { IRptPayVariance } from '../types'

const baseUrl = getBaseUrl('/payroll-sg/rptpayvariance')

export const apiDownloadPayVariancePdf = (params: IRptPayVariance) => {
  return request('get', `${baseUrl}/pdf`, toQueryParams(params), { responseType: 'blob', timeout })
}

export const apiDownloadPayVarianceExcel = (params: IRptPayVariance) => {
  return request('get', `${baseUrl}/excel`, toQueryParams(params), { responseType: 'blob', timeout })
}
