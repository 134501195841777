import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchLocations } from '../actions'
import { LocationState } from '../types'
import { selectLocations } from '../selectors'

export const useLocations = (): [LocationState[], boolean] => {
  const data = useSelector(selectLocations)
  const loading = useSelector((state: StoreState) => state.attendance.locationsLoading)

  useEffect(() => {
    dispatch(fetchLocations({ strategy: 'when-empty' }))
  }, [])

  return [data, loading]
}
