import { request, getBaseUrl } from '~/utils/request'
import { EmailRecipientState } from '../types'

const baseUrl = getBaseUrl('/employee/emailrecipient')

export const apiGetRecipients = (emailExpr: string, sources?: string[]) =>
  request<EmailRecipientState[]>('get', baseUrl, { emailExpr, sources })

export const apiSearchRecipients = (search: string, sources?: string[]) =>
  request<EmailRecipientState[]>('get', `${baseUrl}/search`, { search, sources })
