import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import { Form, Input } from '~/core-components'
import { Col, EditableCard, EditableCardState, EmpKeyValues, InfoTooltip, Row } from '~/components'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { useFocus } from '~/hooks/use-focus'
import { dispatch } from '~/stores/store'
import { Errors, ActionResult } from '~/types/store'
import { ICompanyBank, CompanyState } from '../../../types'
import { mapCompanyStateToCompanyBank } from '../../../types/company.mapper'
import { updateCompany } from '../../../actions'

interface BankInfoProps {
  company?: CompanyState
  onEdit?: () => void
  onSave?: () => void
  onCancel?: () => void
}

const EMPTY_FORM_DATA: ICompanyBank = { bankCode: '', bankAccountName: '', bankAccountNo: '', bankCompanyId: '' }

export const BankInfo: FC<BankInfoProps> = ({ company, onEdit, onSave, onCancel }: BankInfoProps) => {
  const [cardState, setCardState] = useState<EditableCardState>()
  const [formData, setFormData] = useState<ICompanyBank>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const [focusRef] = useFocus(cardState === 'editing')
  const canModify = usePermissionGate(Permission.company, PermissionAction.Modify)

  useEffect(() => {
    if (company) {
      const { bankCode, bankAccountName, bankAccountNo, bankCompanyId } = company
      setFormData({ bankCode, bankAccountName, bankAccountNo, bankCompanyId })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [company])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => setFormData({ ...formData, ...updates }),
    [formData]
  )

  const handleEdit = useCallback(() => {
    setCardState('editing')
    typeof onEdit === 'function' && onEdit()
  }, [onEdit])

  const handleSave = useCallback(async () => {
    if (company) {
      setCardState('saving')
      setErrors(undefined)

      typeof onSave === 'function' && onSave()

      let result: ActionResult | undefined
      try {
        result = await dispatch(updateCompany(company.id, mapCompanyStateToCompanyBank(company), formData))
      } catch {
        setCardState('editing')
      }

      if (result?.errors) {
        setCardState('editing')
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setCardState(undefined)
      }
    }
  }, [company, formData, onSave])

  const handleCancel = useCallback(() => {
    typeof onCancel === 'function' && onCancel()
    setCardState(undefined)
    setErrors(undefined)

    if (company) {
      const { bankCode, bankAccountName, bankAccountNo, bankCompanyId } = company
      setFormData({ bankCode, bankAccountName, bankAccountNo, bankCompanyId })
    }
  }, [company, onCancel])

  return (
    <EditableCard
      title="Bank information"
      bodyStyle={{ paddingBottom: company ? 6 : 24, paddingTop: 6 }}
      state={canModify ? cardState : 'readonly'}
      formId="form-bank-info"
      onEdit={handleEdit}
      onSave={handleSave}
      onCancel={handleCancel}
    >
      <Row>
        <Col span={24}>
          <Form.Item label="Bank" validateStatus={errors?.bankCode ? 'error' : ''} help={errors?.bankCode}>
            <EmpKeyValues
              id="bank"
              ref={focusRef}
              value={formData.bankCode}
              readOnly={cardState !== 'editing' && cardState !== 'saving'}
              onChange={(value: string) => handleFormDataChange({ bankCode: value })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            label="Account name"
            validateStatus={errors?.bankAccountName ? 'error' : ''}
            help={errors?.bankAccountName}
          >
            <Input
              value={formData.bankAccountName}
              readOnly={cardState !== 'editing' && cardState !== 'saving'}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleFormDataChange({ bankAccountName: event.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={12}>
          <Form.Item label="Account no.">
            <Input
              value={formData.bankAccountNo}
              readOnly={cardState !== 'editing' && cardState !== 'saving'}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleFormDataChange({ bankAccountNo: event.target.value })
              }
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={
              <>
                Company ID
                <InfoTooltip title="This is your unique identification code provided by the bank. It is required for some bank file generation processes. Only fill it out if applicable." />
              </>
            }
          >
            <Input
              value={formData.bankCompanyId}
              readOnly={cardState !== 'editing' && cardState !== 'saving'}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleFormDataChange({ bankCompanyId: event.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row></Row>
    </EditableCard>
  )
}
