import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { CurrencyExchangeViewState } from '../types'

export const selectCurrencyExchangesView = createSelector(
  (state: StoreState) => state.claim.currencyExchangesView,
  currencyExchangesState =>
    memoize((viewId: string): CurrencyExchangeViewState => {
      const currencyExchanges = currencyExchangesState.entities[viewId] || ({} as CurrencyExchangeViewState)
      return currencyExchanges
    })
)
