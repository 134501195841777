import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { MasterName, MasterState } from '../types'

export const selectMasterById = createSelector(
  (state: StoreState) => state.master.masters,
  mastersState =>
    memoize(
      (masterName: MasterName, id: string): MasterState | undefined => {
        const masters = mastersState[masterName]?.entities || {}
        return id in masters ? masters[id] : undefined
      },
      (masterName: string, id: string) => `${masterName}|${id}`
    )
)
