import { request, getBaseUrl } from '~/utils/request'
import { IOrgChart } from '../types'

const baseUrl = getBaseUrl('/employee/emorgchart')

export const apiGetOrgChart = (companyId: string) => request<IOrgChart[]>('get', `${baseUrl}/${companyId}`)
export const apiGetParentChart = (companyId: string, employeeId: string) =>
  request<IOrgChart[]>('get', `${baseUrl}/${companyId}/parent/${employeeId}`)
export const apiGetChildrenChart = (companyId: string, employeeId: string) =>
  request<IOrgChart[]>('get', `${baseUrl}/${companyId}/children/${employeeId}`)
