import React, { FC } from 'react'
import classNames from 'classnames'
import { BackLink } from './BackLink'
import { Breadcrumb, BreadcrumbProps } from './Breadcrumb'
import './PageBreadcrumb.less'

export interface PageBreadcrumbProps extends BreadcrumbProps {}

export const PageBreadcrumb: FC<PageBreadcrumbProps> = ({ className, ...props }) => {
  const classes = classNames('page-breadcrumb', { [`${className}`]: className })

  return (
    <div className={classes}>
      <BackLink breadcrumb={props} className="page-breadcrumb__back-link" />
      <Breadcrumb className="page-breadcrumb__breadcrumb" {...props} />
    </div>
  )
}
