import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { FetchStrategy } from '~/types/common'
import { fetchTimeLogTemplates } from '../actions'
import { TimeLogTemplateState } from '../types'
import { selectTimeLogTemplates } from '../selectors'

export const useTimeLogTemplates = (fetchStrategy: FetchStrategy = 'always'): [TimeLogTemplateState[], boolean] => {
  const data = useSelector(selectTimeLogTemplates)
  const loading = useSelector((state: StoreState) => state.attendance.timeLogTemplatesLoading)

  useEffect(() => {
    dispatch(fetchTimeLogTemplates({ strategy: fetchStrategy }))
  }, [fetchStrategy])

  return [data, loading]
}
