import memoize from 'lodash/memoize'
import { KeyValue } from '~/types/common'

export const isDependant = memoize(relation => ['spouse', 'child'].includes(relation))

export const maskString = (text: string) => text?.replace(/\w(?=\w{4})/g, '*')

export const sortKeyValues = (a: KeyValue, b: KeyValue, sortBy?: 'key' | 'value') => {
  const hasSequence = a && 'sequence' in a && a.sequence != null
  const aInactiveDate = new Date(a!.inactiveDate || '').getTime() || 0
  const bInactiveDate = new Date(b!.inactiveDate || '').getTime() || 0

  if (bInactiveDate === 0 && aInactiveDate === 0) {
    const sortField = sortBy || (hasSequence ? 'sequence' : 'value')
    if (sortField === 'key') {
      return a!.key.localeCompare(b!.key)
    } else if (sortField === 'sequence') {
      return (a!.sequence || 0) - (b!.sequence || 0)
    }
    return a!.value.localeCompare(b!.value)
  } else {
    return aInactiveDate - bInactiveDate
  }
}
