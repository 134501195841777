import React, { CSSProperties, FC, useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import {
  Button,
  Card,
  ColumnsType,
  Form,
  SecondaryText,
  Space,
  Table,
  TableRowSelection,
  Text,
  Tooltip
} from '~/core-components'
import { Person, SearchInput } from '~/components'
import {
  Screen,
  updateViewCriteria,
  useFirstView,
  useViewSchema,
  ViewCriteria,
  ViewCriteriaSimple,
  ViewSelectionButton
} from '~/features/selection'
import { dispatch } from '~/stores/store'
import { Permission, PermissionAction, YtdSubmissionStatus } from '~/constants'
import { IYtdIndexPublish, YtdIndexRowState } from '../../types'
import { StoreState } from '~/types/store'
import { refetchYtdIndexView } from '../../reducers'
import { useYtdIndexView } from '../../hooks'
import { useIsMountedRef } from '~/hooks/use-is-mounted-ref'
import { usePermissionGate } from '~/features/iam'
import { SelectYtdIndexYears } from './components/SelectYtdIndexYears'
import {
  downloadWithDom,
  formatMoney,
  formatMonth,
  formatYearMonth,
  getBaseUrl,
  getFileTimestamp,
  showError
} from '~/utils'
import { publishYtdIndex } from '../../actions/publish-ytd-index'
import { apiGetYtdIndexExcel } from '../../api/ytd-index.api'
import './YtdIndex.less'

interface YtdIndexProps {}

const PAGE_SIZE_OPTIONS = ['20', '50', '100']

const SCREEN_CODE: Screen = 'ytd_index'
const labelCompanyStyle: CSSProperties = { lineHeight: 1.1 }
const labelTaxNoStyle: CSSProperties = { fontSize: 12 }
const nameColWidth = 250
const defaultColWidth = 150
const paginationStyle: CSSProperties = { marginRight: 20 }
const baseUrl = getBaseUrl('/filestore')

export const YtdIndex: FC<YtdIndexProps> = () => {
  const [view, viewLoading] = useFirstView(SCREEN_CODE)
  const viewId = view?.id || ''
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(20)
  const [search, setSearch] = useState<string>('')
  const [ytdYear, setYtdYear] = useState<string>('')

  const selection = useSelector((state: StoreState) => state.selection.sysSelectionFields[SCREEN_CODE])
  const [schema] = useViewSchema(SCREEN_CODE, viewId)
  const [data, dataLoading] = useYtdIndexView(viewId, ytdYear, page, pageSize, search, 'always')
  const ids = useMemo(() => data?.data.map(d => d.id) || [], [data])

  const isMountedRef = useIsMountedRef()
  const [downloading, setDownloading] = useState(false)
  const canModify = usePermissionGate(Permission.ytd, PermissionAction.Modify)
  const [selected, setSelected] = useState<YtdIndexRowState[]>([])
  const [loading, setPublishing] = useState(false)

  const tableWidth =
    (schema?.selection.reduce(
      (sum, f) => (sum += selection?.entities[f.selectionFieldId]?.width || defaultColWidth),
      0
    ) || 0) + nameColWidth

  let columns: ColumnsType<YtdIndexRowState> = [
    {
      title: 'Name',
      key: 'employeeId',
      dataIndex: 'employeeId',
      fixed: 'left',
      className: 'first-col',
      width: nameColWidth,
      onCell: (record, index) => {
        const prevId = data?.data[(index as number) - 1]?.employeeId
        if (prevId === record.employeeId) {
          return { rowSpan: 0 }
        } else {
          const nextId = data?.data[(index as number) + 1]?.employeeId
          if (nextId === record.employeeId) {
            return { rowSpan: record.rowSpan }
          }
        }
        return {}
      },
      render: (value: string, record, index: number) => (
        <Person
          name={record.employeeName}
          description={record.employeeTaxNo}
          photo={record.photoId && `${baseUrl}/file/${record.photoId}/thumbnailphoto/36`}
          size={36}
        />
      )
    }
  ]

  if (schema) {
    // Configurable employee columns
    columns.push(
      ...schema?.selection.map(f => {
        var field = selection?.entities[f.selectionFieldId]
        return {
          title: field?.description,
          key: field?.fieldName,
          dataIndex: field?.fieldName,
          width: field?.width || defaultColWidth,
          onCell: (record: YtdIndexRowState, index?: number) => {
            const prevId = data?.data[(index as number) - 1]?.employeeId
            if (prevId === record.employeeId) {
              return { rowSpan: 0 }
            } else {
              const nextId = data?.data[(index as number) + 1]?.employeeId
              if (nextId === record.employeeId) {
                return { rowSpan: record.rowSpan }
              }
            }
            return {}
          },
          render: (value: string | number, record: YtdIndexRowState, index: number) => {
            var display = value
            if (field?.format === 'date' && value) {
              display = moment(value).format('DD MMM YYYY')
            }
            if (field?.format === 'yearmonth' && value) {
              display = formatYearMonth(value as string)
            }
            if (field?.format === 'month' && value) {
              display = formatMonth(value as number)
            }
            if (field?.format === 'money' && value) {
              display = formatMoney(value as number)
            }
            return display
          }
        }
      })
    )
  }

  columns.push(
    ...([
      {
        title: 'Company (IRAS)',
        key: 'companyIrasName',
        dataIndex: 'companyIrasName',
        width: 250,
        render: (value: string, record) => (
          <>
            <div style={labelCompanyStyle}>{value}</div>
            <SecondaryText style={labelTaxNoStyle}>{record.employerTaxNo}</SecondaryText>
          </>
        )
      },
      {
        title: 'Submission status',
        key: 'submissionStatus',
        dataIndex: 'submissionStatus',
        width: 150,
        render: (value: string, record) => <div>{value === YtdSubmissionStatus.submitted ? 'Submitted' : 'Draft'}</div>
      },
      {
        title: 'Published',
        key: 'publishedDate',
        dataIndex: 'publishedDate',
        width: 100,
        render: (value: string, record) => <div>{value ? 'Yes' : 'No'}</div>
      }
    ] as ColumnsType<YtdIndexRowState>)
  )

  const rowSelection: TableRowSelection<YtdIndexRowState> = useMemo(
    () => ({
      type: 'checkbox',
      selectedRowKeys: selected.map(s => s.id),
      hideSelectAll: false,
      onChange: (_selectedRowKeys, selectedRows) => {
        setSelected(s => [...s.filter(o => !ids.includes(o.id)), ...selectedRows])
      }
    }),
    [selected, ids]
  )

  const handlePaginationChange = useCallback((page: number, pageSize?: number) => {
    setPage(page)
    setPageSize(pageSize || 20)
  }, [])

  const handleSearch = useCallback((value: string) => {
    setPage(1)
    setSearch(value)
  }, [])

  const handleYtdYearChange = useCallback(ytdYear => {
    setPage(1)
    setYtdYear(ytdYear)
  }, [])

  const handleCloseSelectionDrawer = useCallback((changed?: boolean) => {
    if (changed) dispatch(refetchYtdIndexView())
  }, [])

  const handleCriteriaApply = useCallback(
    async (criteria: ViewCriteria[]) => {
      if (viewId) {
        setPage(1)
        await dispatch(updateViewCriteria(SCREEN_CODE, viewId, { id: viewId, criteria }))
        dispatch(refetchYtdIndexView())
        setSelected([])
      }
    },
    [viewId]
  )

  const handlePublish = useCallback(
    async (type: string) => {
      if (!selected || selected.length === 0) return

      setPublishing(true)
      try {
        const formData: IYtdIndexPublish = { ytdIndexIds: selected.map(s => s.id) }
        await dispatch(publishYtdIndex(type, ytdYear, formData))
      } finally {
        setPublishing(false)
      }
    },
    [selected, ytdYear]
  )

  const handleDownloadClick = useCallback(async () => {
    if (viewId && ytdYear) {
      try {
        setDownloading(true)
        const { status, result, errors, message, errorData } = await apiGetYtdIndexExcel(viewId, ytdYear, { search })

        if (status) {
          const fileName = `ytd_index_${getFileTimestamp()}.xlsx`
          downloadWithDom(result, fileName)
        } else {
          console.error('Error while downloading', errors)
          showError(message, errorData)
        }
      } finally {
        if (isMountedRef.current) setDownloading(false)
      }
    }
  }, [viewId, ytdYear, search, isMountedRef])

  return (
    <div className="ytd-index">
      <div className="ytd-index__body">
        <div className="ytd-index__action-bar">
          <Form.Item label="">
            <SelectYtdIndexYears onChange={handleYtdYearChange} />
          </Form.Item>
          <Form.Item label="">
            <SearchInput onSearch={handleSearch} />
          </Form.Item>
          <ViewCriteriaSimple screenCode={SCREEN_CODE} viewId={viewId} onApply={handleCriteriaApply} label="" />
          <Space align="start">
            <Tooltip title="Download excel">
              <Button
                icon={<i className="fal fa-arrow-down-to-bracket" />}
                onClick={handleDownloadClick}
                loading={downloading}
              />
            </Tooltip>
            <ViewSelectionButton
              screenCode={SCREEN_CODE}
              viewId={viewId}
              title="Configure columns"
              onClose={handleCloseSelectionDrawer}
            />
          </Space>
        </div>
        <Card fitParent table>
          <Table
            rowKey="id"
            rowSelection={rowSelection}
            dataSource={data?.data}
            columns={columns}
            fitParent
            loading={dataLoading || viewLoading}
            scroll={{ x: tableWidth, y: 1000 }}
            pagination={{
              total: data?.count,
              current: page,
              pageSize,
              pageSizeOptions: PAGE_SIZE_OPTIONS,
              showSizeChanger: true,
              onChange: handlePaginationChange,
              style: paginationStyle,
              showTotal: (total: number, range: [number, number]) => {
                if (range && range.length === 2 && !isNaN(range[0]) && !isNaN(range[1])) {
                  return (
                    <Text size="small">
                      {range[0]}-{range[1]} of {total}
                    </Text>
                  )
                }
              }
            }}
          />
        </Card>
      </div>
      {canModify && selected.length > 0 && (
        <div className="ytd-index__footer-bar">
          <Space className="ytd-index__footer-bar--flex"> </Space>
          <Space>
            <Button type="default" onClick={() => handlePublish('unpublish')} loading={loading}>
              Unpublish
            </Button>
            <Button type="primary" onClick={() => handlePublish('publish')} loading={loading}>
              Publish
            </Button>
          </Space>
        </div>
      )}
    </div>
  )
}
