import { replacePermittedEmployeePay } from '../reducers'
import { apiGetPermittedEmployeePay } from '../api/employee.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchPermittedEmployeePay = (options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (options.strategy === 'when-empty') {
      const data = getState().employee.permittedEmployeePay
      if (data && data.length > 0) {
        return
      }
    }

    const { result, status } = await apiGetPermittedEmployeePay()
    if (status) {
      dispatch(replacePermittedEmployeePay(result))
    }
  }
}
