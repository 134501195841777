import React, { FC, useCallback, useEffect, useState } from 'react'
import { EditableCard, EditableCardState } from '~/components'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { dispatch } from '~/stores/store'
import { Errors, ActionResult } from '~/types/store'
import { CompanyInfoForm, EMPTY_COMPANY_INFO_FORM_DATA } from './CompanyInfoForm'
import { ICompanyInfo, CompanyState } from '../../../types'
import { mapCompanyStateToCompanyInfo } from '../../../types/company.mapper'
import { updateCompany } from '../../../actions'

interface CompanyInfoProps {
  company?: CompanyState
  onEdit?: () => void
  onSave?: () => void
  onCancel?: () => void
}

export const CompanyInfo: FC<CompanyInfoProps> = ({ company, onEdit, onSave, onCancel }: CompanyInfoProps) => {
  const [cardState, setCardState] = useState<EditableCardState>()
  const [formData, setFormData] = useState<ICompanyInfo>(EMPTY_COMPANY_INFO_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const canModify = usePermissionGate(Permission.company, PermissionAction.Modify)

  useEffect(() => {
    if (company) {
      const { name, registrationNo, incorporationDate } = company
      setFormData({ name, registrationNo, incorporationDate })
    } else {
      setFormData(EMPTY_COMPANY_INFO_FORM_DATA)
    }
  }, [company])

  const handleEdit = useCallback(() => {
    setCardState('editing')
    typeof onEdit === 'function' && onEdit()
  }, [onEdit])

  const handleSave = useCallback(async () => {
    if (company) {
      setCardState('saving')
      setErrors(undefined)

      typeof onSave === 'function' && onSave()

      let result: ActionResult | undefined
      try {
        result = await dispatch(updateCompany(company.id, mapCompanyStateToCompanyInfo(company), formData))
      } catch {
        setCardState('editing')
      }

      if (result?.errors) {
        setCardState('editing')
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setCardState(undefined)
      }
    }
  }, [company, formData, onSave])

  const handleCancel = useCallback(() => {
    typeof onCancel === 'function' && onCancel()
    setCardState(undefined)
    setErrors(undefined)

    if (company) {
      const { name, registrationNo, incorporationDate } = company
      setFormData({ name, registrationNo, incorporationDate })
    }
  }, [company, onCancel])

  return (
    <EditableCard
      title="Company information"
      bodyStyle={{ paddingBottom: company ? 6 : 24, paddingTop: 6 }}
      state={canModify ? cardState : 'readonly'}
      className="company-info"
      formId="form-company-info"
      onEdit={handleEdit}
      onSave={handleSave}
      onCancel={handleCancel}
    >
      <CompanyInfoForm
        data={formData}
        errors={errors}
        readOnly={cardState !== 'editing' && cardState !== 'saving'}
        onChange={data => setFormData({ ...formData, ...data })}
      />
    </EditableCard>
  )
}
