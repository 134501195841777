import React, { FC, useCallback, useMemo, useState } from 'react'
import { Button, Dropdown, Space } from '~/core-components'
import { ImportClaimDrawer } from './ImportClaim/ImportClaimDrawer'
import { ImportEncashDrawer } from './ImportEncash/ImportEncashDrawer'
import { ImportLeaveDrawer } from './ImportLeave/ImportLeaveDrawer'
import { ImportPayExcelDrawer } from './ImportPayroll/ImportPayrollExcelDrawer'
import { ImportDailyDrawer } from './ImportDaily/ImportDailyDrawer'
import { useMyTenant } from '~/features/iam'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import { FeatureFlag } from '~/constants'

interface AddPayItemButtonProps {
  payRunId: string
}

interface ImportExcelDrawerState {
  visible: boolean
  payRunId: string
}

interface ImportLeaveDrawerState {
  visible: boolean
  payRunId: string
}

interface ImportEncashDrawerState {
  visible: boolean
  payRunId: string
}

interface ImportClaimDrawerState {
  visible: boolean
  payRunId: string
}

interface ImportDailyDrawerState {
  visible: boolean
  payRunId: string
}

const IMPORT_EXCEL_DRAWER_STATE: ImportExcelDrawerState = { visible: false, payRunId: '' }
const IMPORT_LEAVE_DRAWER_STATE: ImportLeaveDrawerState = { visible: false, payRunId: '' }
const IMPORT_ENCASH_DRAWER_STATE: ImportEncashDrawerState = { visible: false, payRunId: '' }
const IMPORT_CLAIM_DRAWER_STATE: ImportClaimDrawerState = { visible: false, payRunId: '' }
const IMPORT_DAILY_DRAWER_STATE: ImportDailyDrawerState = { visible: false, payRunId: '' }

export const AddPayItemButton: FC<AddPayItemButtonProps> = ({ payRunId }) => {
  const [importExcelDrawer, setImportExcelDrawer] = useState<ImportExcelDrawerState>(IMPORT_EXCEL_DRAWER_STATE)
  const [importLeaveDrawer, setImportLeaveDrawer] = useState<ImportLeaveDrawerState>(IMPORT_LEAVE_DRAWER_STATE)
  const [importEncashDrawer, setImportEncashDrawer] = useState<ImportEncashDrawerState>(IMPORT_ENCASH_DRAWER_STATE)
  const [importClaimDrawer, setImportClaimDrawer] = useState<ImportClaimDrawerState>(IMPORT_CLAIM_DRAWER_STATE)
  const [importDailyDrawer, setImportDailyDrawer] = useState<ImportDailyDrawerState>(IMPORT_DAILY_DRAWER_STATE)

  const [myTenant] = useMyTenant()

  const handleImportExcel = useCallback(() => {
    if (payRunId) setImportExcelDrawer({ visible: true, payRunId })
  }, [payRunId])

  const handleCloseImportExcelDrawer = useCallback(() => {
    setImportExcelDrawer(IMPORT_EXCEL_DRAWER_STATE)
  }, [])

  const handleImportLeave = useCallback(() => {
    if (payRunId) setImportLeaveDrawer({ visible: true, payRunId })
  }, [payRunId])

  const handleCloseImportLeaveDrawer = useCallback(() => {
    setImportLeaveDrawer(IMPORT_LEAVE_DRAWER_STATE)
  }, [])

  const handleImportEncash = useCallback(() => {
    if (payRunId) setImportEncashDrawer({ visible: true, payRunId })
  }, [payRunId])

  const handleCloseImportEncashDrawer = useCallback(() => {
    setImportEncashDrawer(IMPORT_ENCASH_DRAWER_STATE)
  }, [])

  const handleImportClaim = useCallback(() => {
    if (payRunId) setImportClaimDrawer({ visible: true, payRunId })
  }, [payRunId])

  const handleCloseImportClaimDrawer = useCallback(() => {
    setImportClaimDrawer(IMPORT_CLAIM_DRAWER_STATE)
  }, [])

  const handleImportDaily = useCallback(() => {
    if (payRunId) setImportDailyDrawer({ visible: true, payRunId })
  }, [payRunId])

  const handleCloseImportDailyDrawer = useCallback(() => {
    setImportDailyDrawer(IMPORT_DAILY_DRAWER_STATE)
  }, [])

  const menus = useMemo(() => {
    const menus: ItemType[] = [{ key: 'excel', label: 'Upload from excel', onClick: handleImportExcel }]

    if (myTenant?.featureFlags?.includes(FeatureFlag.Leave)) {
      menus.push({ key: 'leave', label: 'Import leave', onClick: handleImportLeave })
    }

    if (myTenant?.featureFlags?.includes(FeatureFlag.Claim)) {
      menus.push({ key: 'claim', label: 'Import claim', onClick: handleImportClaim })
    }

    if (myTenant?.featureFlags?.includes(FeatureFlag.Attendance)) {
      menus.push({ key: 'daily', label: 'Import attendance', onClick: handleImportDaily })
    }

    if (myTenant?.featureFlags?.includes(FeatureFlag.Leave)) {
      menus.push({ key: 'encash', label: 'Encash leave balance', onClick: handleImportEncash })
    }

    return menus
  }, [myTenant, handleImportExcel, handleImportLeave, handleImportClaim, handleImportDaily, handleImportEncash])

  return (
    <>
      <Dropdown menu={{ items: menus }}>
        <Button>
          <Space size={4}>
            <>Add payroll items</>
            <i className="fa-light fa-angle-down" />
          </Space>
        </Button>
      </Dropdown>
      <ImportPayExcelDrawer {...importExcelDrawer} onClose={handleCloseImportExcelDrawer} />
      <ImportLeaveDrawer {...importLeaveDrawer} onClose={handleCloseImportLeaveDrawer} />
      <ImportEncashDrawer {...importEncashDrawer} onClose={handleCloseImportEncashDrawer} />
      <ImportClaimDrawer {...importClaimDrawer} onClose={handleCloseImportClaimDrawer} />
      <ImportDailyDrawer {...importDailyDrawer} onClose={handleCloseImportDailyDrawer} />
    </>
  )
}
