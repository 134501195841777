import { apiAddA8bDraft } from '../api/ytd.api'
import { ThunkResult } from '~/types/store'
import { showSuccess, showError } from '~/utils'
import { IYtdAddA8bDraft } from '../types'

export const addA8bDraft = (request: IYtdAddA8bDraft): ThunkResult<void> => {
  return async () => {
    const { status, errors, message, errorData } = await apiAddA8bDraft(request)
    if (status) {
      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
