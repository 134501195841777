import { showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiExecuteTimeLogImport } from '../api/time-log-import.api'

export const executeTimeLogImport = (locationId: string, importType: string): ThunkResult<void> => {
  return async () => {
    const { status, result, errors, message, errorData } = await apiExecuteTimeLogImport(locationId, importType)
    if (!status) {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors, result }
  }
}
