import React, { forwardRef, useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Dictionary } from '@reduxjs/toolkit'
import { Select, SelectProps } from '~/core-components'
import {
  fetchEmpKeyvalues,
  getAvailableMasterNames,
  MasterConfigs,
  MasterName,
  selectKeyvaluesById
} from '~/features/master'
import { useFirstInView } from '~/hooks'
import { isInactive } from '~/utils/dateUtil'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { FetchStrategy, KeyValue } from '~/types/common'
import { InactiveTag } from '../InactiveTag/InactiveTag'
import { DropdownActions } from './DropdownActions'

interface EmpKeyValuesProps extends SelectProps {
  id: string
  controller?: string
  queryParams?: any
  display?: 'value' | 'key' | 'keyvalue'
  renderItem?: (kv: KeyValue) => React.ReactNode
  sort?: 'value' | 'key'
  onFilter?: (value: KeyValue | undefined, index: number, array: (KeyValue | undefined)[]) => {}
  fetchStrategy?: FetchStrategy
  allowAll?: boolean
}

const AllowAll: KeyValue = { key: '*', value: 'All' }

const EmpKeyValuesInternal = (
  {
    id,
    controller,
    queryParams,
    display = 'value',
    renderItem,
    sort,
    onFilter,
    children,
    fetchStrategy = 'when-empty',
    allowAll = false,
    ...props
  }: EmpKeyValuesProps,
  ref: React.Ref<any>
) => {
  const { ref: inViewRef, inView } = useFirstInView<HTMLDivElement>({ threshold: 0.25 })
  const keyvalues = useSelector((state: StoreState) => state.master.keyvalues[id]?.entities || undefined)
  const keyvaluesLoading = useSelector((state: StoreState) => state.master.keyvaluesLoading[id])
  const masters = useSelector((state: StoreState) =>
    MasterConfigs[id]?.keyvaluesSelector != null ? MasterConfigs[id].keyvaluesSelector(state) : undefined
  )
  const mastersLoading = useSelector((state: StoreState) =>
    MasterConfigs[id]?.keyvaluesLoadingSelector != null ? MasterConfigs[id].keyvaluesLoadingSelector(state) : undefined
  )
  const employees = useSelector((state: StoreState) => state.employee.emKeyValues?.entities)
  const payGroups = useSelector(selectKeyvaluesById)('payGroup')
  const leaveGroups = useSelector(selectKeyvaluesById)('leaveGroup')

  let data: Dictionary<KeyValue> = keyvalues
  if (id === 'employee') {
    data = employees
  } else if (id === 'payGroup') {
    data = payGroups
  } else if (id === 'leaveGroup') {
    data = leaveGroups
  } else if (getAvailableMasterNames().includes(id as MasterName)) {
    data = masters || {}
  }

  const options = useMemo(() => {
    if (!data) {
      return allowAll ? [AllowAll] : []
    }

    let result = (allowAll ? [...Object.values(data), AllowAll] : Object.values(data))
      .filter(a => {
        if (Array.isArray(props.value)) return !isInactive(a?.inactiveDate) || props.value.includes(a?.key)
        else return !isInactive(a?.inactiveDate) || a?.key === props.value
      })
      .sort((a, b) => {
        const hasSequence = a && 'sequence' in a && a.sequence != null
        const aInactiveDate = new Date(a!.inactiveDate || '').getTime() || 0
        const bInactiveDate = new Date(b!.inactiveDate || '').getTime() || 0

        if (bInactiveDate === 0 && aInactiveDate === 0) {
          const sortField = sort || hasSequence ? 'sequence' : display
          if (sortField === 'key' || sortField === 'keyvalue') {
            return a!.key.localeCompare(b!.key)
          } else if (sortField === 'sequence') {
            return (a!.sequence || 0) - (b!.sequence || 0)
          }
          return a!.value.localeCompare(b!.value)
        } else {
          return aInactiveDate - bInactiveDate
        }
      })

    if (typeof onFilter === 'function') {
      result = result.filter(onFilter)
    }
    return result
  }, [data, sort, display, allowAll, props.value, onFilter])

  let loading = keyvaluesLoading
  if (getAvailableMasterNames().includes(id as MasterName)) {
    loading = mastersLoading || false
  }

  useEffect(() => {
    if (inView) {
      dispatch(fetchEmpKeyvalues(id, controller, queryParams, { strategy: fetchStrategy }))
    }
  }, [inView, id, controller, queryParams, fetchStrategy])

  const handleRefresh = useCallback(() => {
    dispatch(fetchEmpKeyvalues(id, controller, queryParams))
  }, [id, controller, queryParams])

  return (
    <div ref={inViewRef}>
      <Select
        ref={ref}
        showSearch
        optionFilterProp="title"
        dropdownRender={menu => (
          <div>
            {menu}
            <DropdownActions onRefresh={handleRefresh} />
          </div>
        )}
        {...props}
        loading={loading}
      >
        {children}
        {options?.map(kv => {
          let displayText = kv!.value
          if (display === 'key') displayText = kv!.key
          else if (display === 'keyvalue') displayText = `${kv!.key} - ${kv!.value}`

          return (
            <Select.Option key={kv!.key} value={kv!.key || ''} title={displayText}>
              {isInactive(kv!.inactiveDate) && <InactiveTag />}
              {renderItem == null ? (
                <>
                  {display === 'value' && kv!.value}
                  {display === 'key' && kv!.key}
                  {display === 'keyvalue' && `${kv!.key} - ${kv!.value}`}
                </>
              ) : (
                kv && renderItem(kv)
              )}
            </Select.Option>
          )
        })}
      </Select>
    </div>
  )
}

export const EmpKeyValues = forwardRef(EmpKeyValuesInternal)
