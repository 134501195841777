import { showError, showSuccess } from '~/utils'
import { ThunkResult } from '~/types/store'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { apiUpdateShift } from '../api/shift.api'
import { ShiftInfoState, ShiftState } from '../types'
import { setShift } from '../reducers'

export const updateShift = (id: string, before: ShiftInfoState, request: ShiftInfoState): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const after = { ...before, ...request } as ShiftInfoState
    const patch = generateJsonPatch<ShiftInfoState>(before, after)
    const { status, errors, message, errorData } = await apiUpdateShift(id, patch)
    if (status) {
      const shift = getState().attendance.shifts?.entities[id]
      const updated: ShiftState = {
        ...(shift as ShiftState),
        ...after
      }
      dispatch(setShift(updated))

      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
