import { Card as InternalCard, CardProps as InternalCardProps } from './Card'
import { CardMeta } from './CardMeta'

type TCard = typeof InternalCard
interface ICard extends TCard {
  Meta: typeof CardMeta
}

const Card: ICard = InternalCard as ICard
Card.Meta = CardMeta
export { Card }

export type CardProps = InternalCardProps

export * from './CardTitle'
