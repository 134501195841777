import { setSysRuleCriteria, setSysRuleCriteriaLoading } from '../reducers'
import { apiGetSysRuleCriteria } from '../api/sys-rule-criteria.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'
import { Screen } from '../types'

export const fetchSysRuleCriteria = (
  screenCode: Screen,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!screenCode) return

    const loading = getState().rule.sysRuleCriteriaLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const data = Object.values(getState().rule.sysRuleCriteria?.entities || {})
      const hasData = data?.some(s => s?.screenCode === screenCode)
      if (hasData) return
    }

    try {
      dispatch(setSysRuleCriteriaLoading(true))
      const { result, status } = await apiGetSysRuleCriteria(screenCode)
      if (status) {
        dispatch(setSysRuleCriteria(result))
      }
    } finally {
      dispatch(setSysRuleCriteriaLoading(false))
    }
  }
}
