import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { PayslipItemConfigState } from '../types'
import { apiUpdatePayslipItemConfig } from '../api/payslip-item-config.api'
import { setPayslipItemConfig } from '../reducers'

export const updatePayslipItemConfig = (
  payGroupId: string,
  payItemId: string,
  id: string,
  before: PayslipItemConfigState,
  request: PayslipItemConfigState
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const after = { ...before, ...request } as PayslipItemConfigState
    const patch = generateJsonPatch<PayslipItemConfigState>(before, after)
    const { status, errors, message, errorData } = await apiUpdatePayslipItemConfig(id, payGroupId, payItemId, patch)

    if (status) {
      const payslipItemConfig = getState().master.payslipItemConfigs[payGroupId]?.entities[id]
      const updated = { ...(payslipItemConfig as PayslipItemConfigState), ...after }
      dispatch(setPayslipItemConfig({ payGroupId, data: updated }))

      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
