import { request, getBaseUrl } from '~/utils/request'
import { ViewCriteriaFieldValue, ViewSchemaState } from '../types/view.state'
import { IViewSchemaSaveAs, IViewCriteria, IViewSelection, ViewState } from '../types'
import { Screen } from '../types'

const baseUrl = getBaseUrl('/employee/view')

export const apiGetViews = (screenCode: Screen, runSetup: boolean = false) =>
  request<ViewState[]>('get', `${baseUrl}/${screenCode}`, { runSetup })

export const apiGetViewSchema = (screenCode: Screen, viewId: string) =>
  request<ViewSchemaState>('get', `${baseUrl}/${screenCode}/${viewId}/schema`)

export const apiGetViewSchemaByName = (screenCode: Screen, viewName: string, runSetup: boolean = false) =>
  request<ViewSchemaState>('get', `${baseUrl}/${screenCode}/${viewName}/schemabyname`, { runSetup })

export const apiGetDefaultViewSchema = (screenCode: Screen) =>
  request<ViewSchemaState>('get', `${baseUrl}/${screenCode}/defaultschema`)

export const apiSaveViewSchemaAs = (screenCode: Screen, viewId: string, schema: IViewSchemaSaveAs) =>
  request<ViewSchemaState>('post', `${baseUrl}/${screenCode}/${viewId}/saveas`, schema)

export const apiUpdateViewSelection = (screenCode: Screen, viewId: string, selection: IViewSelection) =>
  request<ViewSchemaState>('post', `${baseUrl}/${screenCode}/${viewId}/selection`, selection)

export const apiUpdateViewCriteria = (screenCode: Screen, viewId: string, criteria: IViewCriteria) =>
  request('post', `${baseUrl}/${screenCode}/${viewId}/criteria`, criteria)

export const apiResetViewCriteria = (screenCode: Screen, viewId: string, resetTo: ViewCriteriaFieldValue[] = []) =>
  request('post', `${baseUrl}/${screenCode}/${viewId}/criteria/reset`, { resetTo })

export const apiDeleteViewSchema = (screenCode: Screen, viewId: string) =>
  request('delete', `${baseUrl}/${screenCode}/${viewId}/schema`)
