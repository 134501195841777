import React, { FC, memo, useState, useCallback, useEffect, ChangeEvent, CSSProperties } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Form, Input, Space, Tag } from '~/core-components'
import { Col, DrawerForm, EmpKeyValues, Row, SysOptions } from '~/components'
import { dispatch } from '~/stores/store'
import { ActionResult } from '~/types/store'
import { useFocus } from '~/hooks/use-focus'
import { selectKeyvaluesById, selectRaceKeyvalues, selectReligionKeyvalues } from '~/features/master'
import { SSEmPublicSelfState } from '~/features/my/types'
import { IEmployeePersonal, useSysOptions } from '~/features/employee'
import { applyPersonalRequest } from '~/features/my/actions'

export interface EditMyPersonalDrawerProps {
  visible: boolean
  data?: SSEmPublicSelfState
  onClose: (action: 'saved' | 'cancelled') => void
}

const PendingTag = memo(() => <Tag type="secondary">pending</Tag>)

const EMPTY_FORM_DATA: IEmployeePersonal = {
  birthDate: '',
  gender: '',
  maritalStatus: '',
  marriageDate: '',
  raceId: '',
  religionId: '',
  nationalityId: '',
  age: '',
  birthPlace: '',
  personalEmail: '',
  personalPhone: '',
  bio: ''
}

const currenTextStyle: CSSProperties = { fontSize: 13, color: '#797979' }

export const EditMyPersonalDrawer: FC<EditMyPersonalDrawerProps> = ({
  visible,
  data,
  onClose
}: EditMyPersonalDrawerProps) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<IEmployeePersonal>(EMPTY_FORM_DATA)
  const [focusRef, setFocus] = useFocus(true)
  const [genders] = useSysOptions('gender')
  const [maritalStatuses] = useSysOptions('marital_status')
  const races = useSelector(selectRaceKeyvalues)
  const religions = useSelector(selectReligionKeyvalues)
  const nationalities = useSelector(selectKeyvaluesById)('nationality')

  const isDiffBirthDate = data?.birthDate !== data?.birthDateNew
  const isDiffGender = data?.gender !== data?.genderNew
  const isDiffMaritalStatus = data?.maritalStatus !== data?.maritalStatusNew
  const isDiffMarriageDate = data?.marriageDate !== data?.marriageDateNew
  const isDiffRaceId = data?.raceId !== data?.raceIdNew
  const isDiffReligionId = data?.religionId !== data?.religionIdNew
  const isDiffNationalityId = data?.nationalityId !== data?.nationalityIdNew
  const isDiffBirthPlace = data?.birthPlace !== data?.birthPlaceNew
  const isDiffPersonalEmail = data?.personalEmail !== data?.personalEmailNew
  const isDiffPersonalPhone = data?.personalPhone !== data?.personalPhoneNew

  const hasRequest =
    isDiffBirthDate ||
    isDiffGender ||
    isDiffMaritalStatus ||
    isDiffMarriageDate ||
    isDiffRaceId ||
    isDiffReligionId ||
    isDiffNationalityId ||
    isDiffBirthPlace ||
    isDiffPersonalEmail ||
    isDiffPersonalPhone

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
  }, [visible, setFocus])

  useEffect(() => {
    if (data) {
      setFormData({
        birthDate: data.birthDateNew,
        gender: data.genderNew,
        maritalStatus: data.maritalStatusNew,
        marriageDate: data.marriageDateNew,
        raceId: data.raceIdNew,
        religionId: data.religionIdNew,
        nationalityId: data.nationalityIdNew,
        birthPlace: data.birthPlaceNew,
        personalEmail: data.personalEmailNew,
        personalPhone: data.personalPhoneNew,
        age: data.age,
        bio: data.bio
      })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    if ('maritalStatus' in updates) {
      if (updates.maritalStatus === 'single') {
        updates.marriageDate = null
      }
    }

    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      result = await dispatch(applyPersonalRequest(formData))
    } finally {
      setLoading(false)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose('saved')
      setFormData(EMPTY_FORM_DATA)
    }
  }, [formData, onClose])

  return (
    <DrawerForm
      open={visible}
      title="Edit personal information"
      okText={hasRequest ? 'Resubmit' : 'Submit'}
      onClose={() => onClose('cancelled')}
      confirmLoading={loading}
      width={600}
      formId="form-personal-info"
    >
      <Form id="form-personal-info" onFinish={handleOk}>
        <Row gutter={30}>
          <Col span={12}>
            <Form.Item label={<Space>Date of birth {isDiffBirthDate && <PendingTag />}</Space>}>
              <Form.Item noStyle>
                <Input.Date
                  ref={focusRef}
                  value={formData.birthDate ? moment(formData.birthDate) : undefined}
                  onChange={(value: moment.Moment | null) =>
                    handleFormDataChange({ birthDate: value?.format('YYYY-MM-DD') })
                  }
                />
                {isDiffBirthDate && (
                  <Space style={currenTextStyle}>
                    Current: {data?.birthDate ? moment(data?.birthDate).format('DD MMM YYYY') : '-'}
                  </Space>
                )}
              </Form.Item>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={<Space>Gender {isDiffGender && <PendingTag />}</Space>}>
              <SysOptions
                type="gender"
                value={formData.gender}
                onChange={(gender: string) => handleFormDataChange({ gender })}
              />
              {isDiffGender && (
                <Space style={currenTextStyle}>Current: {genders[data?.gender || '']?.value || '-'}</Space>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col span={formData.maritalStatus === 'single' ? 24 : 12}>
            <Form.Item label={<Space>Marital status {isDiffMaritalStatus && <PendingTag />}</Space>}>
              <SysOptions
                type="marital_status"
                value={formData.maritalStatus}
                onChange={(maritalStatus: string) => handleFormDataChange({ maritalStatus })}
              />
              {isDiffMaritalStatus && (
                <Space style={currenTextStyle}>
                  Current: {maritalStatuses[data?.maritalStatus || '']?.value || '-'}
                </Space>
              )}
            </Form.Item>
          </Col>
          <Col span={12} hidden={formData.maritalStatus === 'single'}>
            <Form.Item label={<Space>Marriage date {isDiffMarriageDate && <PendingTag />}</Space>}>
              <Input.Date
                value={formData.marriageDate ? moment(formData.marriageDate) : undefined}
                onChange={(value: moment.Moment | null) =>
                  handleFormDataChange({ marriageDate: value?.format('YYYY-MM-DD') })
                }
              />
              {isDiffMarriageDate && (
                <div style={currenTextStyle}>
                  Current: {data?.marriageDate ? moment(data?.marriageDate).format('DD MMM YYYY') : '-'}
                </div>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col span={12}>
            <Form.Item label={<Space>Race {isDiffRaceId && <PendingTag />}</Space>}>
              <EmpKeyValues
                id="race"
                value={formData.raceId}
                onChange={(raceId: string) => handleFormDataChange({ raceId })}
              />
              {isDiffRaceId && (
                <Space style={currenTextStyle}>Current: {races[data?.raceId || '']?.value || '-'}</Space>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={<Space>Religion {isDiffReligionId && <PendingTag />}</Space>}>
              <EmpKeyValues
                id="religion"
                value={formData.religionId}
                onChange={(religionId: string) => handleFormDataChange({ religionId })}
              />
              {isDiffReligionId && (
                <Space style={currenTextStyle}>Current: {religions[data?.religionId || '']?.value || '-'}</Space>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col span={12}>
            <Form.Item label={<Space>Nationality {isDiffNationalityId && <PendingTag />}</Space>}>
              <EmpKeyValues
                id="nationality"
                value={formData.nationalityId}
                onChange={(nationalityId: string) => handleFormDataChange({ nationalityId })}
              />
              {isDiffNationalityId && (
                <Space style={currenTextStyle}>Current: {nationalities[data?.nationalityId || '']?.value || '-'}</Space>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={<Space>Birth place {isDiffBirthPlace && <PendingTag />}</Space>}>
              <Input
                value={formData.birthPlace}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleFormDataChange({ birthPlace: event.target.value })
                }
              />
              {isDiffBirthPlace && <Space style={currenTextStyle}>Current: {data?.birthPlace || '-'}</Space>}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col span={12}>
            <Form.Item label={<Space>Personal email {isDiffPersonalEmail && <PendingTag />}</Space>}>
              <Input
                value={formData.personalEmail}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleFormDataChange({ personalEmail: event.target.value })
                }
              />
              {isDiffPersonalEmail && <Space style={currenTextStyle}>Current: {data?.personalEmail || '-'}</Space>}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={<Space>Personal phone {isDiffPersonalPhone && <PendingTag />}</Space>}>
              <Input
                value={formData.personalPhone}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleFormDataChange({ personalPhone: event.target.value })
                }
              />
              {isDiffPersonalPhone && <Space style={currenTextStyle}>Current: {data?.personalPhone || '-'}</Space>}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}
