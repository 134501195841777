import React, { FC, useMemo } from 'react'
import moment from 'moment-timezone'
import { Card, ColumnsType, Table } from '~/core-components'
import { useOedLogs } from '../../../../hooks'
import { OedLogState } from '../../../../types'

interface OedLogsProps {
  oedSubmissionId?: string
}

export const OedLogs: FC<OedLogsProps> = ({ oedSubmissionId }) => {
  const [logs, loading] = useOedLogs(oedSubmissionId)

  const columns: ColumnsType<OedLogState> = useMemo(
    () => [
      {
        title: 'Status',
        key: 'status',
        dataIndex: 'status'
      },
      {
        title: 'Submitted date',
        key: 'submittedDate',
        dataIndex: 'submittedDate',
        render: value => (value ? moment(value).format('DD MMM YYYY HH:mm:ss') : '')
      },
      {
        title: 'Submitted by',
        key: 'submitterName',
        dataIndex: 'submitterName'
      }
    ],
    []
  )

  if (!logs || logs.length < 2) return null

  return (
    <Card title="Submission history" className="oed-logs" table>
      <Table rowKey="id" dataSource={logs} columns={columns} loading={loading} pagination={false} />
    </Card>
  )
}
