import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Screen } from '~/features/selection'
import { fetchEmpKeyvalues } from '~/features/master'
import { StoreState } from '~/types/store'
import { dispatch } from '~/stores/store'
import { fetchSysSelectionFields, fetchSysSelectionScreens } from '../actions'

export const useFetchSysSelection = (screenCode: Screen) => {
  const refetch = useSelector((state: StoreState) => state.selection.sysCriteriaFieldsRefetch)

  useEffect(() => {
    dispatch(fetchSysSelectionScreens(screenCode, { strategy: 'when-empty' }))
    dispatch(fetchSysSelectionFields(screenCode, { strategy: 'when-empty' }))
  }, [screenCode, refetch])

  useEffect(() => {
    dispatch(fetchEmpKeyvalues('sysselection', undefined, undefined, { strategy: 'when-empty' }))
  }, [])
}
