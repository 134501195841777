import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiDeleteMaster } from '../api/master.api'
import { removeMaster, replaceCalendarPatterns } from '../reducers'
import { CalendarPatternState, MasterName } from '../types'

export const deleteCalendar = (masterName: MasterName, id: string): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!masterName || !id) return

    const { status, errors, message, errorData } = await apiDeleteMaster(masterName, id)
    if (status) {
      dispatch(removeMaster({ masterName, id }))

      let calendarPatterns = Object.values(getState().master.calendarPatterns?.entities || {})
      calendarPatterns = calendarPatterns.filter(co => co?.calendarId !== id)
      dispatch(replaceCalendarPatterns(calendarPatterns as CalendarPatternState[]))

      showSuccess('Deleted')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
