import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiApplyMyClaimRecord } from '../api/ss-claim-apply.api'
import { SSClaimApply } from '../types'
import { refetchMyClaimBalances, refetchMyClaimRecordsView } from '../reducers'

export const applyMyClaim = (claimRecord: SSClaimApply, isSubmit: boolean): ThunkResult<void> => {
  return async dispatch => {
    const { status, result, errors, message, errorData } = await apiApplyMyClaimRecord(claimRecord, isSubmit)

    if (status) {
      dispatch(refetchMyClaimBalances())
      dispatch(refetchMyClaimRecordsView())
      showSuccess(isSubmit ? 'Sent' : 'Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
