import React, { FC } from 'react'
import moment from 'moment-timezone'
import { Col, Row } from '~/components'
import { Card, Form } from '~/core-components'
import { OedSubmissionState } from '../../../../types'
import { useOedSummary } from '../../../../hooks'
import { OedOrganization } from './OedOrganization'
import { OedActions } from './OedActions'
import { OedLogs } from './OedLogs'
import './OedSummary.less'

interface OedSummaryProps {
  oedSubmission?: OedSubmissionState
}

export const OedSummary: FC<OedSummaryProps> = ({ oedSubmission }) => {
  const oedSubmissionId = oedSubmission?.id || ''
  const [oedSummary] = useOedSummary(oedSubmissionId)

  return (
    <Row gutter={15} className="oed-summary">
      <Col span={14}>
        <Card className="oed-report-summary" title="Report summary">
          <Form layout="horizontal">
            <Form.Item label="Report creation date">{moment(oedSummary?.createdDate).format('DD MMMM YYYY')}</Form.Item>
            <Form.Item label="Last modified">
              {oedSummary?.updatedDate && moment(oedSummary?.updatedDate).format('DD MMMM YYYY HH:mm:ss')}
            </Form.Item>
            {oedSummary?.submittedDate && (
              <Form.Item label="Last submission">
                {moment(oedSummary?.submittedDate).format('DD MMMM YYYY HH:mm:ss')}
                {oedSummary.submitterName ? ` by ${oedSummary.submitterName}` : ''}
              </Form.Item>
            )}

            <div className="oed-report-summary__record">
              <div className="oed-report-summary__subtitle">Included in the submission</div>
              <Form.Item label="No. of records" className="ml10">
                {oedSummary?.totalRecordInclude}
              </Form.Item>
              <div className="indented">
                <div className="fw-bold">Status</div>
                <Form.Item label="Valid">{oedSummary?.totalRecordIncludeValid}</Form.Item>
                <Form.Item label="Incomplete">{oedSummary?.totalRecordIncludeIncomplete}</Form.Item>
                <Form.Item label="Invalid">{oedSummary?.totalRecordIncludeInvalid}</Form.Item>
              </div>

              <div className="oed-report-summary__subtitle">Excluded in the submission</div>
              <Form.Item label="No. of records" className="ml10">
                {oedSummary?.totalRecordExclude}
              </Form.Item>
              <div className="indented">
                <div className="fw-bold">Status</div>
                <Form.Item label="Valid">{oedSummary?.totalRecordExcludeValid}</Form.Item>
                <Form.Item label="Incomplete">{oedSummary?.totalRecordExcludeIncomplete}</Form.Item>
                <Form.Item label="Invalid">{oedSummary?.totalRecordExcludeInvalid}</Form.Item>
              </div>
              <Form.Item label="Total no. of records">{oedSummary?.totalRecord}</Form.Item>
            </div>
          </Form>
        </Card>
      </Col>
      <Col span={10}>
        <Row gutter={[30, 15]}>
          <Col span={24}>
            <OedOrganization oedSummary={oedSummary} />
          </Col>
          <Col span={24}>
            <OedActions oedSubmission={oedSubmission} />
          </Col>
          {oedSummary?.submittedDate && (
            <Col span={24}>
              <OedLogs oedSubmissionId={oedSubmissionId} />
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  )
}
