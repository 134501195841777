import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { SelfUpdateConfigState } from '../types'

const baseUrl = getBaseUrl('/employee/selfupdateconfig')

export const apiGetSelfUpdateConfigs = () => request<SelfUpdateConfigState[]>('get', baseUrl)

export const apiUpdateSelfUpdateConfig = (code: string, selfUpdateConfig: Operation[]) =>
  request('patch', `${baseUrl}/${code}`, selfUpdateConfig)
