import React, { ChangeEvent, FC } from 'react'
import moment from 'moment-timezone'
import { RangeValue } from 'rc-picker/lib/interface.d'
import { Checkbox, CheckboxChangeEvent, Form, Input, Space } from '~/core-components'
import { Col, LveKeyValues, Row, SysOptions } from '~/components'
import { Errors, StoreState } from '~/types/store'
import { LeaveGrantBulkFormData } from '../LeaveGrantBulkDrawer'
import { useSelector } from 'react-redux'
import { useSysOptions } from '~/features/employee'
import { FilterEmStatus } from '~/constants'
import './LeaveGrantBulkInput.less'

interface LeaveGrantBulkInputProps {
  visible: boolean
  data: Partial<LeaveGrantBulkFormData>
  errors?: Errors
  onChange: (data: Partial<LeaveGrantBulkFormData>) => void
}

export const LeaveGrantBulkInput: FC<LeaveGrantBulkInputProps> = ({ visible, data, errors, onChange }) => {
  const leaveTypes = useSelector((state: StoreState) => state.leave.leaveTypes.entities)
  const [leaveUnits] = useSysOptions('lve_unit_display')
  const leaveUnit = leaveUnits[leaveTypes[data?.leaveTypeId || '']?.unit || '']?.value.toLowerCase()

  return (
    <div className="leave-grant-bulk-input" hidden={!visible}>
      <Form id="form-leave-grant-bulk">
        <Row>
          <Col span={12}>
            <Form.Item
              label="Leave type"
              validateStatus={errors?.leaveTypeId ? 'error' : ''}
              help={errors?.leaveTypeId}
            >
              <LveKeyValues
                id="grantLeaveType"
                controller="leavetype/grant"
                fetchStrategy="always"
                value={data.leaveTypeId}
                onChange={(leaveTypeId: string) => onChange({ leaveTypeId })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={7}>
            <Form.Item label="Quantity" validateStatus={errors?.earned ? 'error' : ''} help={errors?.earned}>
              <Space>
                <Input.Number
                  precision={0}
                  min={0}
                  value={data.earned}
                  onChange={(value: number | null) => onChange({ earned: value || 0 })}
                />
                <span>{leaveUnit}</span>
              </Space>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              label="Validity period"
              validateStatus={errors?.startDate || errors?.endDate ? 'error' : ''}
              help={errors?.startDate || errors?.endDate}
            >
              <Input.DateRange
                value={[data.startDate ? moment(data.startDate) : null, data.endDate ? moment(data.endDate) : null]}
                onCalendarChange={(dates: RangeValue<moment.Moment>) => {
                  const startDate = dates && dates[0] ? dates[0].format('YYYY-MM-DD') : null
                  const endDate = dates && dates[1] ? dates[1].format('YYYY-MM-DD') : null

                  if (
                    startDate !== null &&
                    endDate !== null &&
                    (startDate !== data.startDate || endDate !== data.endDate)
                  ) {
                    onChange({ startDate, endDate })
                  }
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item label="Notes" validateStatus={errors?.notes ? 'error' : ''} help={errors?.notes}>
              <Input.TextArea
                rows={5}
                value={data.notes}
                onChange={(value?: ChangeEvent<HTMLTextAreaElement>) => onChange({ notes: value?.target.value })}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Row>
        <Col span={24}>
          <div className="criteria__title">Criteria</div>
        </Col>
        <Col span={24}>
          <Form.Item label="Applicable to">
            <Space>
              <SysOptions
                allowClear={false}
                type="filter_em_status"
                value={data.emStatus}
                onChange={(emStatus: string) => onChange({ emStatus })}
              />
              {data.emStatus === FilterEmStatus.existing && (
                <>
                  <span style={{ margin: '0 15px' }}>as of</span>
                  <Input.Date
                    allowClear={false}
                    value={data.asAtDate ? moment(data.asAtDate) : undefined}
                    onChange={(value: moment.Moment | null) => {
                      onChange({ asAtDate: value?.format('YYYY-MM-DD') })
                    }}
                  />
                </>
              )}
            </Space>
          </Form.Item>
          {data.emStatus === FilterEmStatus.existing && (
            <Form.Item label="" style={{ marginLeft: 95, marginTop: -15 }}>
              <Checkbox
                checked={data.isIncludeProbation}
                onChange={(event: CheckboxChangeEvent) => onChange({ isIncludeProbation: event.target.checked })}
              >
                Include employees on probation
              </Checkbox>
            </Form.Item>
          )}
        </Col>
      </Row>
    </div>
  )
}
