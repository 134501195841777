import { showError, showSuccess } from '~/utils'
import { ThunkResult } from '~/types/store'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { apiUpdateShiftCategory } from '../api/shift-category.api'
import { IShiftCategory, ShiftCategoryState } from '../types'
import { setShiftCategory } from '../reducers'
import { setKeyvalue } from '~/features/master'

export const updateShiftCategory = (id: string, before: IShiftCategory, request: IShiftCategory): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const after = { ...before, ...request } as IShiftCategory
    const patch = generateJsonPatch<IShiftCategory>(before, after)
    const { status, errors, message, errorData } = await apiUpdateShiftCategory(id, patch)
    if (status) {
      const location = getState().attendance.locations?.entities[id]
      const updated: ShiftCategoryState = {
        ...(location as ShiftCategoryState),
        ...after
      }
      dispatch(setShiftCategory(updated))
      dispatch(
        setKeyvalue({
          kv: 'shiftCategory',
          data: { key: updated.id, value: updated.name, inactiveDate: updated.inactiveDate }
        })
      )

      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
