import React, { CSSProperties, FC, useState, useCallback, useEffect, ChangeEvent } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import confirm from 'antd/lib/modal/confirm'
import { Form, Input, Radio, RadioChangeEvent } from '~/core-components'
import { Col, DrawerForm, PaySgKeyValues, Row } from '~/components'
import { selectPayItemById } from '~/features/master'
import { fetchFormulas, selectFormulaById } from '~/features/formula'
import { emptyGuid } from '~/constants'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors, StoreState } from '~/types/store'
import { useFocus } from '~/hooks/use-focus'
import { addPayRecurring, deletePayRecurring, updatePayRecurring } from '../../actions'
import { PayRecurringState } from '../../types'
import { mapPayRecurringStateToPayRecurringInfo } from '../../types/payrecurring.mapper'
import { IPayRecurringInfo } from '../../types/payrecurring.dto'

export interface MutatePayRecurringDrawerProps {
  visible: boolean
  employeeId?: string
  data?: PayRecurringState
  onClose: () => void
}

const EMPTY_FORM_DATA: IPayRecurringInfo = {
  payItemId: '',
  quantity: 0,
  rate: 0,
  startDate: moment().format('YYYY-MM-01'),
  endDate: '',
  notes: ''
}

interface RecurringEnd {
  type: 'times' | 'until' | 'unlimited'
  times?: number
  until: string
}

const EMPTY_RECURRING_END: RecurringEnd = {
  type: 'times',
  times: undefined,
  until: ''
}

const radioGroupTitleStyle: CSSProperties = { marginBottom: 15 }
const radioStyle: CSSProperties = { width: '100%', marginBottom: 10 }
const radioItemStyle: CSSProperties = { marginBottom: 5 }
const radioInputStyle: CSSProperties = { marginLeft: 20 }

export const MutatePayRecurringDrawer: FC<MutatePayRecurringDrawerProps> = ({
  visible,
  employeeId,
  data,
  onClose
}: MutatePayRecurringDrawerProps) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<IPayRecurringInfo>(EMPTY_FORM_DATA)
  const [recurringEnd, setRecurringEnd] = useState<RecurringEnd>(EMPTY_RECURRING_END)
  const [focusRef, setFocus] = useFocus(true)
  const [errors, setErrors] = useState<Errors>()
  const payItems = useSelector((state: StoreState) => state.master.payItems)
  const payItem = useSelector((state: StoreState) => selectPayItemById(state, formData.payItemId))
  const formula = useSelector((state: StoreState) => selectFormulaById(state, payItem?.formulaId || ''))

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
    setRecurringEnd(EMPTY_RECURRING_END)
    dispatch(fetchFormulas({ strategy: 'when-empty' }))
  }, [visible, setFocus])

  useEffect(() => {
    if (data) {
      const { payItemId, quantity, rate, startDate, endDate, notes } = data
      setFormData({ payItemId, quantity, rate, startDate, endDate, notes })
      if (!endDate) {
        setRecurringEnd({ type: 'unlimited', until: '' })
      } else {
        setRecurringEnd({ type: 'until', until: endDate })
      }
    } else {
      setFormData(EMPTY_FORM_DATA)
      setRecurringEnd(EMPTY_RECURRING_END)
    }
  }, [data])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(data => ({ ...data, ...updates }))
  }, [])

  const getEndDate = useCallback((startDate: string | undefined, type: string, until: string, times?: number) => {
    let endDate = ''
    if (type === 'times' && startDate) {
      endDate = moment(`${startDate.substr(0, 7)}-01`)
        .add(times || 0, 'month')
        .add(-1, 'day')
        .format('YYYY-MM-DD')
    } else if (type === 'until') {
      endDate = until
    }
    return endDate
  }, [])

  const handleRecurringEndChange = useCallback(
    (updates: { [field: string]: any }) => {
      const updated = { ...recurringEnd, ...updates }
      setRecurringEnd(updated)
      setFormData(data => ({
        ...data,
        endDate: getEndDate(formData.startDate, updated.type, updated.until, updated.times)
      }))
    },
    [recurringEnd, formData.startDate, getEndDate]
  )

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      const updates = { ...formData, payItemId: formData.payItemId || emptyGuid }
      if (data) {
        result = await dispatch(updatePayRecurring(data.id, mapPayRecurringStateToPayRecurringInfo(data), updates))
      } else if (employeeId) {
        result = await dispatch(addPayRecurring(employeeId, updates))
      }
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_FORM_DATA)
    }
  }, [employeeId, data, formData, onClose])

  const handleDelete = useCallback(
    (payRecurring: PayRecurringState | undefined) => {
      if (payRecurring) {
        const { id } = payRecurring
        confirm({
          title: 'Delete recurring',
          content: `Do you want to delete recurring record of "${payItem?.name}"?`,
          onOk: async () => {
            const result: ActionResult | undefined = await dispatch(deletePayRecurring(id))
            if (result?.errors) {
              setErrors(result.errors)
            }

            if (!result?.errors) {
              typeof onClose === 'function' && onClose()
            }
          },
          okText: 'Delete',
          okType: 'danger'
        })
      }
    },
    [payItem, onClose]
  )

  return (
    <DrawerForm
      open={visible}
      title={data ? 'Edit payroll recurring' : 'Add payroll recurring'}
      onClose={onClose}
      confirmLoading={loading}
      width={600}
      showDelete={data ? true : false}
      onDelete={() => handleDelete(data)}
      formId="form-pay-recurring"
    >
      <Form id="form-pay-recurring" onFinish={handleOk}>
        <Row>
          <Col span={24}>
            <Form.Item label="Payroll item" validateStatus={errors?.payItemId ? 'error' : ''} help={errors?.payItemId}>
              <PaySgKeyValues
                id="payItem"
                value={formData.payItemId}
                ref={focusRef}
                onChange={(value: string) => {
                  const rate = payItems.entities[value]?.rate || 1
                  handleFormDataChange({ payItemId: value, rate })
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Quantity" validateStatus={errors?.quantity ? 'error' : ''} help={errors?.quantity}>
              <Input.Number
                precision={4}
                value={formData.quantity}
                onChange={(value: number | null) => handleFormDataChange({ quantity: value })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Rate" validateStatus={errors?.rate ? 'error' : ''} help={errors?.rate}>
              <Input.Number
                precision={4}
                value={formData.rate}
                onChange={(value: number | null) => handleFormDataChange({ rate: value })}
              ></Input.Number>
            </Form.Item>
          </Col>
        </Row>
        <Row hidden={!formula?.name}>
          <Col span={24}>
            <Form.Item label="Formula">
              <label>{formula?.name}</label>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="First payment" validateStatus={errors?.startDate ? 'error' : ''} help={errors?.startDate}>
              <Input.Date
                value={formData.startDate ? moment(formData.startDate) : undefined}
                style={{ width: '200px' }}
                onChange={(value: moment.Moment | null) => {
                  const startDate = value?.format('YYYY-MM-DD')
                  const endDate = getEndDate(startDate, recurringEnd.type, recurringEnd.until, recurringEnd.times)
                  handleFormDataChange({ startDate, endDate })
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <div style={radioGroupTitleStyle}>
          <b>Recurring settings</b>
        </div>
        <Radio.Group
          value={recurringEnd.type}
          onChange={(event: RadioChangeEvent) =>
            handleRecurringEndChange({ type: event.target.value, times: undefined, until: '' })
          }
          style={radioStyle}
        >
          {!data && (
            <>
              <Radio value="times" style={radioItemStyle}>
                No. of times
              </Radio>
              <Row style={radioInputStyle}>
                <Col span={24}>
                  <Form.Item>
                    <Input.Number
                      precision={0}
                      value={recurringEnd.times}
                      min={1}
                      disabled={recurringEnd.type !== 'times'}
                      onChange={(value: number | null) => handleRecurringEndChange({ times: value })}
                    ></Input.Number>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
          <Radio value="until" style={radioItemStyle}>
            Recurring until last payment
          </Radio>
          <Row style={radioInputStyle}>
            <Col span={24}>
              <Form.Item>
                <Input.Date
                  value={recurringEnd.until ? moment(recurringEnd.until) : undefined}
                  disabled={recurringEnd.type !== 'until'}
                  onChange={(value: moment.Moment | null) =>
                    handleRecurringEndChange({ until: value?.format('YYYY-MM-DD') })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Radio value="unlimited" style={radioItemStyle}>
            Recurring until end of employment
          </Radio>
        </Radio.Group>
        <Row>
          <Col span={24}>
            <Form.Item label="Notes">
              <Input
                value={formData.notes}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ notes: event.target.value })}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}
