import { request, getBaseUrl } from '~/utils/request'
import { LtProrationMState, ILtProrationMMutate } from '../types'

const baseUrl = getBaseUrl('/leave/leavetype')

export const apiGetLtProrationM = (leaveTypeId: string) =>
  request<LtProrationMState[]>('get', `${baseUrl}/${leaveTypeId}/ltprorationm`)

export const apiUpdateLtProrationM = (leaveTypeId: string, ltProrationM: ILtProrationMMutate) =>
  request<LtProrationMState[]>('post', `${baseUrl}/${leaveTypeId}/ltprorationm/`, ltProrationM)
