import { showSuccess, showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { apiAddTimeLog } from '../api/time-log.api'
import { refetchTimeLogsView } from '../reducers'
import { ISaveTimeLog } from '../types'
import { emptyGuid } from '~/constants'

export const addTimeLog = (timeLog: ISaveTimeLog): ThunkResult<void> => {
  return async (dispatch): Promise<ActionResult> => {
    const { status, result, errors, message, errorData } = await apiAddTimeLog({
      ...timeLog,
      employeeId: timeLog.employeeId || emptyGuid
    })
    if (status) {
      dispatch(refetchTimeLogsView())
      showSuccess('Added')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
