import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { AttendanceGroupState, IAttendanceGroupInfo } from '../types'

const baseUrl = getBaseUrl('/attendance/attendancegroup')

export const apiGetAttendanceGroups = () => request<AttendanceGroupState[]>('get', baseUrl)

export const apiGetAttendanceGroup = (id: string) => request<AttendanceGroupState>('get', `${baseUrl}/${id}`)

export const apiAddAttendanceGroup = (attendanceGroup: IAttendanceGroupInfo) =>
  request<{ id: string }>('post', baseUrl, attendanceGroup)

export const apiUpdateAttendanceGroup = (id: string, attendanceGroup: Operation[]) =>
  request<void>('patch', `${baseUrl}/${id}`, attendanceGroup)

export const apiDeleteAttendanceGroup = (id: string) => request<void>('delete', `${baseUrl}/${id}`)
