import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { SSTimeLogState } from '../types'

export const selectMyTimeLogs = createSelector(
  (state: StoreState) => state.myAttendance.myTimeLogs,
  myTimeLogsState => {
    return Object.values(myTimeLogsState?.entities || {}) as SSTimeLogState[]
  }
)
