import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchXeroTenantMaps } from '../actions'
import { XeroTenantMapState } from '../types'
import { selectXeroTenantMaps } from '../selectors'

export const useXeroTenantMaps = (fetchStrategy: FetchStrategy = 'when-empty'): [XeroTenantMapState[], boolean] => {
  const tenantMap = useSelector(selectXeroTenantMaps)
  const loading = useSelector((state: StoreState) => state.payroll.xeroTenantMapsLoading)

  useEffect(() => {
    dispatch(fetchXeroTenantMaps({ strategy: fetchStrategy }))
  }, [fetchStrategy])

  return [tenantMap, loading]
}
