import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { UploadFile } from '../../../core-components'
import { EmDocumentState, IEmDocument } from '../types'

const baseUrl = getBaseUrl('/employee/employee')

export const apiGetEmDocuments = (employeeId: string) =>
  request<EmDocumentState[]>('get', `${baseUrl}/${employeeId}/document`)

export const apiAddEmDocument = (employeeId: string, emDocument: IEmDocument) => {
  let formData = new FormData()
  Object.entries(emDocument).forEach(([key, value]) => {
    if (key === 'file') {
      if (value instanceof File) formData.append(key, value, value.name)
    } else {
      formData.append(key, value)
    }
  })

  return request<{
    id: string
    docFileId?: string
    docFileName?: string
    docFileSize?: number
    docFileFormat?: string
    docFileExtension?: string
  }>('post', `${baseUrl}/${employeeId}/document`, formData)
}

export const apiUpdateEmDocument = (employeeId: string, id: string, emDocument: Operation[]) =>
  request<void>('patch', `${baseUrl}/${employeeId}/document/${id}`, emDocument)

export const apiDeleteEmDocument = (employeeId: string, id: string) =>
  request<void>('delete', `${baseUrl}/${employeeId}/document/${id}`)

export const apiUploadFileEmDocument = (employeeId: string, id: string, file: UploadFile) => {
  const formData = new FormData()
  if (file instanceof File) formData.append('file', file, file.name)

  return request<{
    docFileId?: string
    docFileName?: string
    docFileSize?: number
    docFileFormat?: string
    docFileExtension?: string
  }>('post', `${baseUrl}/${employeeId}/document/${id}/uploadfile`, formData)
}

export const apiDeleteFileEmDocument = (employeeId: string, id: string) =>
  request('get', `${baseUrl}/${employeeId}/document/${id}/deletefile`)
