import { showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiUpdatePayItemBases } from '../api/payitem-basis.api'
import { replacePayItemBases } from '../reducers'

export const updatePayItemBases = (payItemId: string, payItemBasisId: string[]): ThunkResult<void> => {
  return async dispatch => {
    if (!payItemBasisId) return

    const { status, result, errors, message, errorData } = await apiUpdatePayItemBases(payItemId, payItemBasisId)
    if (status) {
      dispatch(replacePayItemBases({ id: payItemId, data: result }))
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
