import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { ClaimTypeState } from '../types'

export const selectClaimTypesView = createSelector(
  (state: StoreState) => state.claim.claimTypesView,
  (claimTypesViewState): (ClaimTypeState | undefined)[] => {
    const claimTypesView = Object.values(claimTypesViewState?.entities || {}) as (ClaimTypeState | undefined)[]
    return claimTypesView.sort((a, b) => {
      const aInactiveDate = new Date(a!.inactiveDate || '').getTime() || 0
      const bInactiveDate = new Date(b!.inactiveDate || '').getTime() || 0

      if (bInactiveDate === 0 && aInactiveDate === 0) {
        return a?.name.localeCompare(b?.name || '') || 0
      } else {
        return aInactiveDate - bInactiveDate
      }
    })
  }
)
