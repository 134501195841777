import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { PayClaimMapState } from '../types'

export const selectPayClaimMaps = createSelector(
  (state: StoreState) => state.master.payClaimMaps,
  payClaimMapsState =>
    memoize((payGroupId: string) => {
      return (Object.values(payClaimMapsState[payGroupId]?.entities || {}) as PayClaimMapState[]) || []
    })
)
