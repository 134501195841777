import React, { FC, useMemo } from 'react'
import { Redirect, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectMyTenants } from '~/features/iam'
import { FeatureFlag } from '~/constants'
import { PrivateRoute } from '~/routes/PrivateRoute'
import { SETTINGS_PATH, SETTINGS_ROUTES } from '~/routes/routes'
import { MasterLayout } from './MasterLayout'
import { Settings } from './Settings'
import {
  commonMenus,
  commonRoutes,
  payrollMenus,
  payrollRoutes,
  leaveMenus,
  leaveRoutes,
  claimMenus,
  claimRoutes,
  attendanceRoutes,
  attendanceMenus,
  iamMenus,
  iamRoutes,
  appConfigsRoutes,
  appConfigsMenus,
  otherRoutes,
  otherMenus,
  integrationRoutes,
  integrationMenus
} from '../configs'
import { AppRouteProps } from '../types'
import { PermissionRoute } from '~/routes/PermissionRoute'
import { MasterMenuProvider } from './MasterMenuContext'

export const payrollRouteProps: AppRouteProps[] = payrollRoutes.map(({ path, exact }) => ({
  path,
  exact,
  sysPermissionId: payrollMenus.find(m => m.path === path)?.sysPermissionId,
  render: () => (
    <MasterMenuProvider menus={payrollMenus}>
      <MasterLayout routes={payrollRoutes} />
    </MasterMenuProvider>
  )
}))

export const leaveRouteProps: AppRouteProps[] = leaveRoutes.map(({ path, exact }) => ({
  path,
  exact,
  sysPermissionId: leaveMenus.find(m => m.path === path)?.sysPermissionId,
  render: () => (
    <MasterMenuProvider menus={leaveMenus}>
      <MasterLayout routes={leaveRoutes} />
    </MasterMenuProvider>
  )
}))

export const claimRouteProps: AppRouteProps[] = claimRoutes.map(({ path, exact }) => ({
  path,
  exact,
  sysPermissionId: claimMenus.find(m => m.path === path)?.sysPermissionId,
  render: () => (
    <MasterMenuProvider menus={claimMenus}>
      <MasterLayout routes={claimRoutes} />
    </MasterMenuProvider>
  )
}))

export const attendanceRouteProps: AppRouteProps[] = attendanceRoutes.map(({ path, exact }) => ({
  path,
  exact,
  sysPermissionId: attendanceMenus.find(m => m.path === path)?.sysPermissionId,
  render: () => (
    <MasterMenuProvider menus={attendanceMenus}>
      <MasterLayout routes={attendanceRoutes} />
    </MasterMenuProvider>
  )
}))

export const otherRouteProps: AppRouteProps[] = otherRoutes.map(({ path, exact }) => ({
  path,
  exact,
  sysPermissionId: otherMenus.find(m => m.path === path)?.sysPermissionId,
  render: () => (
    <MasterMenuProvider menus={otherMenus}>
      <MasterLayout routes={otherRoutes} />
    </MasterMenuProvider>
  )
}))

export const commonRouteProps: AppRouteProps[] = commonRoutes.map(({ path, exact }) => ({
  path,
  exact,
  sysPermissionId: commonMenus.find(m => m.path === path)?.sysPermissionId,
  render: () => (
    <MasterMenuProvider menus={commonMenus}>
      <MasterLayout routes={commonRoutes} />
    </MasterMenuProvider>
  )
}))

export const iamRouteProps: AppRouteProps[] = iamRoutes.map(({ path, exact }) => ({
  path,
  exact,
  sysPermissionId: iamMenus.find(m => m.path === path)?.sysPermissionId,
  render: () => (
    <MasterMenuProvider menus={iamMenus}>
      <MasterLayout routes={iamRoutes} />
    </MasterMenuProvider>
  )
}))

export const appConfigRouteProps: AppRouteProps[] = appConfigsRoutes.map(({ path, exact }) => ({
  path,
  exact,
  sysPermissionId: appConfigsMenus.find(m => m.path === path)?.sysPermissionId,
  render: () => (
    <MasterMenuProvider menus={appConfigsMenus}>
      <MasterLayout routes={appConfigsRoutes} />
    </MasterMenuProvider>
  )
}))

export const integrationRouteProps: AppRouteProps[] = integrationRoutes.map(({ path, exact }) => ({
  path,
  exact,
  sysPermissionId: integrationMenus.find(m => m.path === path)?.sysPermissionId,
  render: () => (
    <MasterMenuProvider menus={integrationMenus}>
      <MasterLayout routes={integrationRoutes} />
    </MasterMenuProvider>
  )
}))

const activeTenantCode = localStorage.getItem('tenant')

export const SettingsLayout: FC = () => {
  const myTenants = useSelector(selectMyTenants)
  const activeTenant = useMemo(() => myTenants.find(t => t.tenantCode === activeTenantCode), [myTenants])
  const hasAttendance = activeTenant?.featureFlags?.includes(FeatureFlag.Attendance)

  const filteredCommonRouteProps = useMemo(
    () => commonRouteProps.filter(r => (r.path === SETTINGS_ROUTES.workCalendars ? !hasAttendance : true)),
    [hasAttendance]
  )

  return (
    <Switch>
      <Redirect exact from={SETTINGS_PATH} to={SETTINGS_ROUTES.main} />
      <PrivateRoute exact path={SETTINGS_ROUTES.main} component={Settings} />
      {filteredCommonRouteProps.map(route =>
        route.sysPermissionId ? (
          <PermissionRoute key={route.path as string} sysPermissionId={route.sysPermissionId} {...route} />
        ) : (
          <PrivateRoute key={route.path as string} {...route} />
        )
      )}
      {payrollRouteProps.map(route =>
        route.sysPermissionId ? (
          <PermissionRoute key={route.path as string} sysPermissionId={route.sysPermissionId} {...route} />
        ) : (
          <PrivateRoute key={route.path as string} {...route} />
        )
      )}
      {leaveRouteProps.map(route =>
        route.sysPermissionId ? (
          <PermissionRoute key={route.path as string} sysPermissionId={route.sysPermissionId} {...route} />
        ) : (
          <PrivateRoute key={route.path as string} {...route} />
        )
      )}
      {claimRouteProps.map(route =>
        route.sysPermissionId ? (
          <PermissionRoute key={route.path as string} sysPermissionId={route.sysPermissionId} {...route} />
        ) : (
          <PrivateRoute key={route.path as string} {...route} />
        )
      )}
      {attendanceRouteProps.map(route =>
        route.sysPermissionId ? (
          <PermissionRoute key={route.path as string} sysPermissionId={route.sysPermissionId} {...route} />
        ) : (
          <PrivateRoute key={route.path as string} {...route} />
        )
      )}
      {otherRouteProps.map(route =>
        route.sysPermissionId ? (
          <PermissionRoute key={route.path as string} sysPermissionId={route.sysPermissionId} {...route} />
        ) : (
          <PrivateRoute key={route.path as string} {...route} />
        )
      )}
      {iamRouteProps.map(route =>
        route.sysPermissionId ? (
          <PermissionRoute key={route.path as string} sysPermissionId={route.sysPermissionId} {...route} />
        ) : (
          <PrivateRoute key={route.path as string} {...route} />
        )
      )}
      {appConfigRouteProps.map(route =>
        route.sysPermissionId ? (
          <PermissionRoute key={route.path as string} sysPermissionId={route.sysPermissionId} {...route} />
        ) : (
          <PrivateRoute key={route.path as string} {...route} />
        )
      )}
      {integrationRouteProps.map(route =>
        route.sysPermissionId ? (
          <PermissionRoute key={route.path as string} sysPermissionId={route.sysPermissionId} {...route} />
        ) : (
          <PrivateRoute key={route.path as string} {...route} />
        )
      )}
    </Switch>
  )
}
