import React, { FC, useState, useCallback, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import confirm from 'antd/lib/modal/confirm'
import { Button, PageHeader } from '~/core-components'
import { DocumentTitle } from '~/components'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { MenuItem } from '~/types/common'
import { deletePayItem, fetchPayItem, fetchPayItems, fetchPayItemBases } from '../../actions'
import { payrollMenus } from '../../configs'
import { selectPayItemById } from '../../reducers'
import { selectPayItems, selectPayItemBasesById } from '../../selectors'
import { StoreState } from '../../../../types/store'
import { useMasterMenu } from '../MasterMenuContext'
import { PayItemSgInfo } from './components/PayItemSgInfo'
import { PayItemFormData } from './components/PayItemSgInfoForm'
import { AddPayItemDrawer } from './components/AddPayItemDrawer'
import './PayItemSg.less'

export interface PayItemSgProps {}

interface PayItemSgParams {
  id: string
}

interface DrawerState {
  visible: boolean
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: SETTINGS_ROUTES.payItemsSg,
    breadcrumbName: 'Overview'
  },
  {
    path: '',
    breadcrumbName: 'Payroll item'
  }
]

export const PayItemSg: FC<PayItemSgProps> = () => {
  const { id } = useParams<PayItemSgParams>()
  const payItems = useSelector(selectPayItems)
  const payItem = useSelector((state: StoreState) => selectPayItemById(state, id))
  const payItemBases = useSelector(selectPayItemBasesById)(id)
  const [editing, setEditing] = useState(false)
  const history = useHistory()
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const canModify = usePermissionGate(Permission.payMaster, PermissionAction.Modify)
  const { setMenus, setSiderWidth } = useMasterMenu()

  useEffect(() => {
    dispatch(fetchPayItems('sg', { strategy: 'when-empty' }))
    dispatch(fetchPayItem(id, 'sg'))
    dispatch(fetchPayItemBases(id))
  }, [id])

  const handleAddPayItem = useCallback(() => {
    setDrawerState({ visible: true })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  useEffect(() => {
    const menus: MenuItem[] = payItems.map(pi => ({
      path: SETTINGS_ROUTES.payItemSg.replace(':id', pi!.id),
      value: pi!.name,
      sysPermissionId: Permission.payMaster
    }))

    if (canModify)
      menus.push({
        path: (
          <Button type="dashed" onClick={handleAddPayItem} block>
            Add payroll item
          </Button>
        ),
        value: '',
        sysPermissionId: ''
      })

    setMenus(menus)
    return () => {
      setMenus(payrollMenus)
    }
  }, [setMenus, payItems, canModify, handleAddPayItem])

  useEffect(() => {
    setSiderWidth(300)
  }, [setSiderWidth])

  const handleEdit = useCallback(() => setEditing(true), [])
  const handleView = useCallback(() => setEditing(false), [])

  const handleDelete = useCallback(() => {
    if (id && payItem) {
      confirm({
        title: 'Delete payroll item',
        content: `Do you want to delete payroll item "${payItem?.name}"?`,
        onOk: () => {
          dispatch(deletePayItem(id))
          history.push(SETTINGS_ROUTES.payItemsSg)
        },
        okText: 'Delete',
        okType: 'danger'
      })
    }
  }, [id, payItem, history])

  return (
    <div id="payitem" className="payitem">
      <DocumentTitle title="Payroll Item" />
      <PageHeader
        title="Payroll item"
        containerId="payitem"
        breadcrumb={{ routes }}
        extra={canModify && editing && <Button onClick={handleDelete}>Delete</Button>}
      />
      <div className="payitem__body">
        <PayItemSgInfo
          payItem={
            { ...(payItem || {}), payItemBasisId: payItemBases.map(pib => pib!.payItemBasisId) } as PayItemFormData
          }
          onEdit={handleEdit}
          onSave={handleView}
          onCancel={handleView}
        />
        {canModify && <AddPayItemDrawer {...drawerState} onClose={handleCloseDrawer} />}
      </div>
    </div>
  )
}
