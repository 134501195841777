import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { LeaveBuddyEmState } from '../types'
import { fetchLeaveBuddyEm } from '../actions'
import { selectLeaveBuddyEm } from '../selectors'

export const useLeaveBuddyEm = (leaveBuddyId: string): [LeaveBuddyEmState[], boolean] => {
  const leaveBuddyEm = useSelector(selectLeaveBuddyEm)(leaveBuddyId)
  const loading = useSelector((state: StoreState) => state.leave.leaveBuddyEmLoading[leaveBuddyId])

  useEffect(() => {
    if (leaveBuddyId) {
      dispatch(fetchLeaveBuddyEm(leaveBuddyId))
    }
  }, [leaveBuddyId])

  return [leaveBuddyEm, loading]
}
