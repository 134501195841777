import React, { createContext, FC, useContext, useState } from 'react'
import { MenuItem } from '~/types/common'

interface ReportMenu {
  menus: MenuItem[]
  setMenus: (menus: MenuItem[]) => void
  siderWidth: number
  setSiderWidth: (width: number) => void
}

const DEFAULT: ReportMenu = {
  menus: [],
  setMenus: () => {},
  siderWidth: 230,
  setSiderWidth: () => {}
}

const ReportMenuContext = createContext<ReportMenu>(DEFAULT)

export const useReportMenu = () => {
  return useContext(ReportMenuContext)
}

interface ReportMenuProviderProps {
  menus: MenuItem[]
}

export const ReportMenuProvider: FC<ReportMenuProviderProps> = ({ children, menus: initialMenus }) => {
  const [menus, setMenus] = useState<MenuItem[]>(initialMenus)
  const [siderWidth, setSiderWidth] = useState<number>(230)
  return (
    <ReportMenuContext.Provider value={{ menus, setMenus, siderWidth, setSiderWidth }}>
      {children}
    </ReportMenuContext.Provider>
  )
}
