import { useEffect } from 'react'
import { Dictionary } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { ProjectState } from '../types'
import { fetchProjects } from '../actions'

export const useProjectsDict = (): [Dictionary<ProjectState>, boolean] => {
  const projects = useSelector((state: StoreState) => state.attendance.projects.entities)
  const loading = useSelector((state: StoreState) => state.attendance.projectsLoading)

  useEffect(() => {
    dispatch(fetchProjects({ strategy: 'when-empty' }))
  }, [])

  return [projects, loading]
}
