import { showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiUploadEmployeeImport } from '../api/employee-import.api'
import { replaceEmployeeImport } from '../reducers'
import { ImportEmployeeExcelFormData } from '../containers/Employee/components/ImportEmployeeExcel/ImportEmployeeExcelDrawer'

export const uploadEmployeeImport = (formData: ImportEmployeeExcelFormData): ThunkResult<void> => {
  return async dispatch => {
    const { status, result, errors, message, errorData } = await apiUploadEmployeeImport(formData)
    if (status) {
      dispatch(replaceEmployeeImport(result))
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors, result }
  }
}
