import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { LoadingOutlined } from '@ant-design/icons'
import { DocumentTitle } from '~/components'
import { Button, Card, ColumnsType, Link, LinkGroup, PageHeader, Spin, Table, Tooltip } from '~/core-components'
import { Link as RouterLink } from 'react-router-dom'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { MutateHolidayDrawer } from './components/MutateHolidayDrawer'
import { fetchMasters, setDefaultHoliday } from '../../actions'
import { selectHolidays } from '../../selectors'
import { HolidayState } from '../../types'
import './Holidays.less'

type HolidayTable = HolidayState

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: '',
    breadcrumbName: 'Overview'
  }
]

interface DrawerState {
  visible: boolean
  data?: HolidayState
}
const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

interface HolidayProps {}

const labelRow1Style: CSSProperties = { lineHeight: 1.1 }

export const Holidays: FC<HolidayProps> = () => {
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const holidays = useSelector(selectHolidays)
  const loading = useSelector((state: StoreState) => state.master.mastersLoading['holiday'])
  const [updatingDefault, setUpdatingDefault] = useState<string>()
  const canModify = usePermissionGate(Permission.master, PermissionAction.Modify)

  useEffect(() => {
    dispatch(fetchMasters('holiday'))
  }, [])

  const handleAddHoliday = useCallback(() => {
    setDrawerState({ visible: true, data: undefined })
  }, [])

  const handleEditHoliday = useCallback((holiday: HolidayState) => {
    setDrawerState({ visible: true, data: holiday })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const handleSetDefault = useCallback(async (holiday: HolidayState) => {
    setUpdatingDefault(holiday.id)
    try {
      await dispatch(setDefaultHoliday(holiday.id))
    } finally {
      setUpdatingDefault(undefined)
    }
  }, [])

  const columns: ColumnsType<HolidayTable> = [
    {
      title: 'Holiday',
      key: 'name',
      dataIndex: 'name',
      render: (value: string, record) => (
        <div style={labelRow1Style}>
          <RouterLink to={`${SETTINGS_ROUTES.holidays}/${record.id}`}>{value}</RouterLink>
        </div>
      )
    },
    {
      key: 'action',
      align: 'right',
      render: (value: string, record) =>
        canModify && (
          <LinkGroup>
            <Tooltip title="Default holiday will be used when employee does not have an office assigned or when holiday is not configured in office setting.">
              {record.isDefault ? (
                <Link size="small" disabled>
                  default
                </Link>
              ) : (
                <Link size="small" onClick={() => handleSetDefault(record)} disabled={updatingDefault === record.id}>
                  {updatingDefault === record.id && (
                    <>
                      <Spin indicator={<LoadingOutlined spin />} />
                      &nbsp;
                    </>
                  )}
                  set as default
                </Link>
              )}
            </Tooltip>
            <Link size="small" onClick={() => handleEditHoliday(record)}>
              edit
            </Link>
          </LinkGroup>
        )
    }
  ]

  return (
    <div id="holidays" className="holidays">
      <DocumentTitle title="Holidays" />
      <PageHeader
        title="Holidays"
        containerId="holidays"
        breadcrumb={{ routes }}
        extra={
          canModify && (
            <Button key="add" onClick={handleAddHoliday}>
              Add holiday
            </Button>
          )
        }
      />
      <div className="holidays__body">
        <Card table>
          <Table rowKey="id" dataSource={holidays} pagination={false} columns={columns} loading={loading} />
        </Card>
        {canModify && <MutateHolidayDrawer {...drawerState} onClose={handleCloseDrawer} />}
      </div>
    </div>
  )
}
