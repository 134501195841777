export class ClaApproval {
  static get reportingManager() {
    return 'mgr'
  }
  static get workflow() {
    return 'wf'
  }
  static get notRequired() {
    return 'x'
  }
}

export class ClaApprovalStatus {
  static get approved() {
    return 'a'
  }
  static get cancelled() {
    return 'c'
  }
  static get rejected() {
    return 'r'
  }
  static get pending() {
    return 'p'
  }
  static get pendingCancel() {
    return 'pc'
  }
}

export enum ClaRecordStatus {
  Draft = 'd',
  Approved = 'a',
  Cancelled = 'c',
  Paid = 'pd',
  Pending = 'p',
  PendingCancel = 'pc',
  PendingPayment = 'pp',
  Rejected = 'r'
}

export class ClaEarnedBasis {
  static get noEntitlement() {
    return 'x'
  }
  static get monthly() {
    return 'm'
  }
  static get daily() {
    return 'd'
  }
  static get full() {
    return 'f'
  }
}

export enum ClaEntitlementType {
  PerPeriod = 'p',
  PerDiem = 'd',
  NoEntitlement = 'x'
}

export class ClaMonthProrationType {
  static get start() {
    return 'start'
  }
  static get end() {
    return 'end'
  }
  static get mid() {
    return 'mid'
  }
}

export class ClaPeriodBasis {
  static get calendar() {
    return 'c'
  }
  static get custom() {
    return 'm'
  }
}

export class ClaProrationType {
  static get day() {
    return 'd'
  }
  static get month() {
    return 'm'
  }
  static get none() {
    return 'x'
  }
}

export class ClaCurrencyCode {
  static get sgd() {
    return 'sgd'
  }
}

export class ClaPaymentMethod {
  static get payroll() {
    return 'p'
  }
  static get directPayment() {
    return 'd'
  }
}

export class ClaPaymentStatus {
  static get pendingApproval() {
    return 'p'
  }
  static get pendingPayment() {
    return 'pp'
  }
  static get paid() {
    return 'pd'
  }
}

export class ClaAttachment {
  static get optional() {
    return 'o'
  }
  static get mandatory() {
    return 'm'
  }
  static get notApplicable() {
    return 'x'
  }
}

export class ClaRecordType {
  static get new() {
    return 'n'
  }
  static get cancellation() {
    return 'c'
  }
}

export class ClaSegmentType {
  static get all() {
    return 'all'
  }
  static get latest() {
    return 'latest'
  }
  static get specific() {
    return 'specific'
  }
}

export const ClaAttachmentFileTypeAllowed = ['.bmp', '.pdf', '.jpg', '.jpeg', '.png']

export enum ClaTaxableType {
  Yes = 'y',
  No = 'n'
}

export enum ClaExpenseSubmissionType {
  Single = 's',
  Multiple = 'm'
}

export enum ClaPerTimeLimit {
  Amount = 'amt',
  Percentage = 'perct',
  EmAmount = 'em_amt'
}
