import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { fetchPayEncashMaps, PayEncashMapState, selectPayEncashMaps } from '~/features/master'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'

export const usePayEncashMaps = (
  payGroupId: string,
  fetchStrategy: FetchStrategy = 'when-empty'
): [PayEncashMapState[], boolean] => {
  const payEncashMaps = useSelector(selectPayEncashMaps)(payGroupId)
  const loading = useSelector((state: StoreState) => state.master.payEncashMapsLoading[payGroupId])

  useEffect(() => {
    if (payGroupId) {
      dispatch(fetchPayEncashMaps(payGroupId, { strategy: fetchStrategy }))
    }
  }, [payGroupId, fetchStrategy])

  return [payEncashMaps, loading]
}
