import { showSuccess, showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { apiAddShiftCategory } from '../api/shift-category.api'
import { setShiftCategory } from '../reducers'
import { IShiftCategory, ShiftCategoryState } from '../types'
import { setKeyvalue } from '~/features/master'

export const addShiftCategory = (shiftCategory: IShiftCategory): ThunkResult<void> => {
  return async (dispatch): Promise<ActionResult> => {
    const { status, result, errors, message, errorData } = await apiAddShiftCategory(shiftCategory)
    if (status) {
      const newShiftCategory: ShiftCategoryState = {
        ...shiftCategory,
        id: result.id
      }
      dispatch(setShiftCategory(newShiftCategory))
      dispatch(
        setKeyvalue({
          kv: 'shiftCategory',
          data: { key: result.id, value: shiftCategory.name, inactiveDate: shiftCategory.inactiveDate }
        })
      )
      showSuccess('Added')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
