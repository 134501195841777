import React, { CSSProperties, FC, useCallback } from 'react'
import SkeletonAvatar from 'antd/lib/skeleton/Avatar'
import { Col, EmpKeyValues, InfoTooltip, Row } from '~/components'
import { ECharts, EChartsOption } from '..'
import merge from 'lodash/merge'
import './PieChart.less'

interface PieChartProps {
  emGroup: string
  tooltip?: string
  option: EChartsOption
  onChange?: (emGroup: string) => void
  loading?: boolean
  style?: CSSProperties
}

const defaultOption: EChartsOption = {
  tooltip: {
    trigger: 'item'
  },
  grid: {
    show: false,
    left: 16,
    right: 16,
    top: 16,
    bottom: 16
  },
  legend: {
    type: 'scroll',
    orient: 'vertical',
    top: 'center',
    right: 10,
    width: 80,
    itemWidth: 10,
    itemHeight: 10,
    textStyle: { fontSize: 11, fontFamily: "'Mulish', 'SegoeUI', -apple-system" },
    pageTextStyle: { fontSize: 11, fontFamily: "'Mulish', 'SegoeUI', -apple-system" },
    pageIconSize: 10
  },
  series: [
    {
      type: 'pie',
      radius: ['40%', '70%'],
      center: ['25%', '50%'],
      label: {
        show: false
      },
      labelLine: {
        show: false
      }
    }
  ]
}

const skeletonStyle: CSSProperties = { margin: 20 }

export const PieChart: FC<PieChartProps> = ({
  emGroup,
  tooltip,
  option,
  onChange,
  loading,
  style = { height: 120 }
}) => {
  const chartOption = merge({}, defaultOption, option)

  const handleChange = useCallback(
    (emGroup: string) => {
      typeof onChange === 'function' && onChange(emGroup)
    },
    [onChange]
  )

  return (
    <div className="pie-chart">
      <Row>
        <Col flex="auto" className="pie-chart__title">
          <EmpKeyValues
            id="chartEmGroup"
            controller="emdashboard/chartemgroup"
            size="small"
            value={emGroup}
            allowClear={false}
            onChange={handleChange}
          />
        </Col>
        <Col flex="none">{tooltip && <InfoTooltip title={tooltip} />}</Col>
      </Row>
      {loading ? (
        <SkeletonAvatar active shape="circle" size={100} style={skeletonStyle} />
      ) : (
        <Row>
          <Col span={24}>
            <ECharts option={chartOption} style={style} />
          </Col>
        </Row>
      )}
    </div>
  )
}
