import { IPayRunInfo, IPayRunInfoNew, PayRunState } from './payrun.state'

export const mapPayRunStateToPayRunInfo = (payRun: PayRunState): IPayRunInfo => ({
  description: payRun.description,
  payDate: payRun.payDate,
  otStartDate: payRun.otStartDate,
  otEndDate: payRun.otEndDate
})

export const mapPayRunStateToPayRunInfoNew = (payRun: PayRunState): IPayRunInfoNew => ({
  payGroupId: payRun.payGroupId,
  payPeriod: payRun.payPeriod,
  payRunType: payRun.payRunType,
  payDate: payRun.payDate
})
