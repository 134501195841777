import React, { CSSProperties, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { EChartsOption, PieChart } from '~/charts'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchHeadcountGroupBy } from '../../../actions'

interface HeadcountDistributionProps {
  cacheKey: string
  groupBy: string
}

const chartStyle: CSSProperties = { width: 280, height: 150 }

export const HeadcountDistribution: FC<HeadcountDistributionProps> = ({ cacheKey, groupBy }) => {
  const [emGroup, setEmGroup] = useState(groupBy)
  const data = useSelector((state: StoreState) => state.employee.headcountGroupBy[cacheKey])
  const loading = useSelector((state: StoreState) => state.employee.headcountGroupByLoading[cacheKey])

  const option = useMemo(() => {
    return {
      series: [
        {
          data: data?.data || []
        }
      ]
    } as EChartsOption
  }, [data])

  useEffect(() => {
    dispatch(fetchHeadcountGroupBy(cacheKey, emGroup))
  }, [cacheKey, emGroup])

  const handleChange = useCallback((emGroup: string) => {
    setEmGroup(emGroup)
  }, [])

  return <PieChart emGroup={emGroup} option={option} onChange={handleChange} loading={loading} style={chartStyle} />
}
