import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { HolidayDateState, IHolidayDateInfo } from '../types'

const baseUrl = getBaseUrl('/employee/holiday')

export const apiSetDefaultHoliday = (holidayId: string) => request('post', `${baseUrl}/${holidayId}`)

export const apiGetHolidayDates = (holidayId: string) =>
  request<HolidayDateState[]>('get', `${baseUrl}/${holidayId}/date`)

export const apiAddHolidayDate = (holidayId: string, holidayDate: IHolidayDateInfo) =>
  request<{ id: string }>('post', `${baseUrl}/${holidayId}/date`, holidayDate)

export const apiDeleteHolidayDate = (holidayId: string, id: string) =>
  request<void>('delete', `${baseUrl}/${holidayId}/date/${id}`)

export const apiUpdateHolidayDate = (holidayId: string, id: string, holidayDate: Operation[]) =>
  request<void>('patch', `${baseUrl}/${holidayId}/date/${id}`, holidayDate)
