import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Dictionary } from '@reduxjs/toolkit'
import classNames from 'classnames'
import { Col, EditableCard, EditableCardState, Row } from '~/components'
import { Form, Input, Space } from '~/core-components'
import {
  ConfigOptions,
  fetchTenantConfigs,
  TenantConfigState,
  updateTenantConfigs,
  usePermissionGate
} from '~/features/iam'
import { Permission, PermissionAction } from '~/constants'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors, StoreState } from '~/types/store'
import './PasswordConfigs.less'

interface PasswordConfigsProps {
  onEdit?: () => void
  onSave?: () => void
  onCancel?: () => void
}

export const PasswordConfigs: FC<PasswordConfigsProps> = ({ onEdit, onSave, onCancel }) => {
  const configs = useSelector((state: StoreState) => state.iam.tenantConfigs.entities)
  const loading = useSelector((state: StoreState) => state.iam.tenantConfigsLoading)
  const [formData, setFormData] = useState<Dictionary<TenantConfigState>>({})
  const [cardState, setCardState] = useState<EditableCardState>()
  const [errors, setErrors] = useState<Errors>()
  const canModify = usePermissionGate(Permission.idpConfig, PermissionAction.Modify)

  useEffect(() => {
    dispatch(fetchTenantConfigs())
  }, [])

  useEffect(() => {
    if (configs) {
      setFormData(configs)
    } else {
      setFormData({})
    }
  }, [configs])

  const handleEdit = useCallback(() => {
    setCardState('editing')
    typeof onEdit === 'function' && onEdit()
  }, [onEdit])

  const handleSave = useCallback(async () => {
    setCardState('saving')
    setErrors(undefined)

    typeof onSave === 'function' && onSave()

    let result: ActionResult | undefined
    try {
      result = await dispatch(updateTenantConfigs(Object.values(formData) as TenantConfigState[]))
    } catch {
      setCardState('editing')
    }

    if (result?.errors) {
      setCardState('editing')
      setErrors(result.errors)
    }

    if (!result?.errors) {
      setCardState(undefined)
    }
  }, [formData, onSave])

  const handleCancel = useCallback(() => {
    typeof onCancel === 'function' && onCancel()
    setCardState(undefined)
    setErrors(undefined)
  }, [onCancel])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      let overrides: { [field: string]: TenantConfigState } = {}
      Object.keys(updates).forEach(key => {
        overrides[key] = { ...formData[key], value: updates[key] } as TenantConfigState
      })
      setFormData(data => ({ ...data, ...overrides }))
    },
    [formData]
  )

  const readOnly = cardState !== 'editing' && cardState !== 'saving'

  return (
    <div className="password-configs">
      <EditableCard
        title="Password policy"
        state={canModify ? cardState : 'readonly'}
        formId="form-password-configs"
        loading={loading}
        onEdit={handleEdit}
        onSave={handleSave}
        onCancel={handleCancel}
      >
        <Row gutter={30}>
          {Object.values(formData)
            .filter(cfg => !['pwd_max_reused', 'login_max_retry'].includes(cfg?.sysConfigCode || ''))
            .map(cfg => (
              <Col key={cfg?.sysConfigCode} span={24}>
                <Form.Item
                  label={cfg?.name}
                  className={classNames(cfg?.valueType)}
                  validateStatus={errors && errors[cfg?.sysConfigCode || ''] ? 'error' : ''}
                  help={errors && errors[cfg?.sysConfigCode || '']}
                >
                  <div className="config-description">{cfg?.description}</div>
                  <Space>
                    {cfg?.valueType === 'number' && (
                      <Input.Number
                        value={parseInt(cfg.value)}
                        readOnly={readOnly}
                        onChange={(value: number | null) => handleFormDataChange({ [cfg.sysConfigCode]: value })}
                      />
                    )}
                    {cfg?.valueType === 'multiselect' && (
                      <ConfigOptions
                        config={cfg}
                        readOnly={readOnly}
                        value={cfg.value?.split(',')}
                        onChange={(values: string[]) => {
                          handleFormDataChange({ [cfg.sysConfigCode]: values.join() })
                        }}
                      />
                    )}
                    <span className="value-suffix">{cfg?.valueSuffix}</span>
                  </Space>
                </Form.Item>
              </Col>
            ))}
        </Row>
      </EditableCard>
    </div>
  )
}
