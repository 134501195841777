import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { LeavePeriodState } from '../types'

export const selectLeavePeriods = createSelector(
  (state: StoreState) => state.leave.leavePeriods,
  (leavePeriodState): LeavePeriodState[] => {
    const periods = Object.values(leavePeriodState?.entities || {}) as LeavePeriodState[]
    if (periods && periods.length > 0) return periods.sort((a, b) => b!.periodCode.localeCompare(a!.periodCode))

    return []
  }
)
