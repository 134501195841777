import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { EmCategoryState, IEmCategory } from '../types'

const baseUrl = getBaseUrl('/employee/employee')

export const apiGetEmCategories = (employeeId: string) =>
  request<EmCategoryState[]>('get', `${baseUrl}/${employeeId}/category`)

export const apiGetEmCategory = (employeeId: string, emCategoryId: string) =>
  request<EmCategoryState>('get', `${baseUrl}/${employeeId}/category/${emCategoryId}`)

export const apiAddEmCategory = (employeeId: string, emCategory: IEmCategory) =>
  request<{ id: string; emCategoryId?: string }>('post', `${baseUrl}/${employeeId}/category`, emCategory)

export const apiUpdateEmCategory = (employeeId: string, id: string, emCategory: Operation[]) =>
  request<{ emCategoryId?: string }>('patch', `${baseUrl}/${employeeId}/category/${id}`, emCategory)

export const apiDeleteEmCategory = (employeeId: string, id: string) =>
  request<{ emCategoryId?: string }>('delete', `${baseUrl}/${employeeId}/category/${id}`)
