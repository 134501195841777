import { apiAddIr8sDraft } from '../api/ytd.api'
import { ThunkResult } from '~/types/store'
import { showSuccess, showError } from '~/utils'
import { IYtdAddIr8sDraft } from '../types'

export const addIr8sDraft = (request: IYtdAddIr8sDraft): ThunkResult<void> => {
  return async () => {
    const { status, errors, message, errorData } = await apiAddIr8sDraft(request)
    if (status) {
      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
