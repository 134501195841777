import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { PayslipItemConfigState } from '../types'

const baseUrl = getBaseUrl('/payroll-sg/paygroup')

export const apiGetPayslipItemConfigs = (payGroupId: string) =>
  request<PayslipItemConfigState[]>('get', `${baseUrl}/${payGroupId}/payslipitemconfig`)

export const apiUpdatePayslipItemConfig = (id: string, payGroupId: string, payItemId: string, data: Operation[]) =>
  request<void>('patch', `${baseUrl}/${payGroupId}/payslipitemconfig/${payItemId}/${id}`, data)
