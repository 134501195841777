import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import { Button, Form, Input, UploadFile } from '~/core-components'
import { Col, DrawerForm, Row, SysOptions, UploadFileSelectorAuth } from '~/components'
import { useFocus } from '~/hooks/use-focus'
import { dispatch } from '~/stores/store'
import { sendFeedback } from '../actions/send-feedback'
import { ActionResult, Errors } from '~/types/store'
import { IFeedback } from '../types'
import './FeedbackDrawer.less'

interface FeedbackDrawerProps {
  visible: boolean
  onClose: () => void
}

const EMPTY_FORM_DATA: IFeedback = {
  feedbackType: '',
  feedbackMessage: '',
  attachments: []
}

export const FeedbackDrawer: FC<FeedbackDrawerProps> = ({ visible, onClose }) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<IFeedback>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const [focusRef, setFocus] = useFocus(visible)

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setErrors(undefined)
    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      result = await dispatch(sendFeedback(formData))
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_FORM_DATA)
    }
  }, [formData, onClose])

  const getLabel = useCallback((feedbackType: string) => {
    if (feedbackType === 'fbt01') {
      return "What improvement or new feature that you'd like to see in Zealys?"
    } else if (feedbackType === 'fbt02') {
      return 'What would you like to know?'
    } else if (feedbackType === 'fbt03') {
      return 'Describe the issue'
    } else if (feedbackType === 'fbt04') {
      return "Let us know what's on your mind"
    }

    return 'Message'
  }, [])

  const handleFileChange = useCallback(
    (files?: UploadFile[]) => {
      if (files && files.length > 0) {
        const updated = { ...formData, attachments: files }
        setFormData(updated)
      }
    },
    [formData]
  )

  const handleFileRemove = useCallback((file?: UploadFile) => {
    if (file) {
      setFormData(formData => {
        const listAttachment = formData.attachments || []
        const updated = {
          ...formData,
          attachments: listAttachment.filter(x => x.uid !== file.uid)
        }
        return updated
      })
    }
  }, [])

  return (
    <DrawerForm
      className="feedback-drawer"
      open={visible}
      title="Give feedback to Zealys"
      okText="Send feedback"
      onClose={onClose}
      confirmLoading={loading}
      width={500}
      showDelete={false}
      formId="form-send-feedback"
    >
      <Form id="form-send-feedback" onFinish={handleOk}>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Feedback type"
              validateStatus={errors?.feedbackType ? 'error' : ''}
              help={errors?.feedbackType}
            >
              <SysOptions
                type="feedback_type"
                sort="key"
                value={formData.feedbackType}
                ref={focusRef}
                onChange={(value: string) => handleFormDataChange({ feedbackType: value })}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={getLabel(formData.feedbackType)}
              validateStatus={errors?.feedbackMessage ? 'error' : ''}
              help={errors?.feedbackMessage}
            >
              <Input.TextArea
                value={formData.feedbackMessage}
                rows={4}
                onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                  handleFormDataChange({ feedbackMessage: event.target.value })
                }
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Attachment">
              <UploadFileSelectorAuth
                fileList={formData.attachments ? formData.attachments : []}
                multiple
                onChange={handleFileChange}
                onRemove={handleFileRemove}
              >
                <Button size="small">choose file</Button>
              </UploadFileSelectorAuth>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}
