import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { apiUpdateEmCategory } from '../api/em-category.api'
import { EmCategoryState, IEmCategoryInfo } from '../types'
import { setEmCategory, setEmployee } from '../reducers'

export const updateEmCategory = <T extends IEmCategoryInfo>(
  employeeId: string,
  id: string,
  before: T,
  request: T
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!employeeId || !id) return

    const after = { ...before, ...request } as T
    const patch = generateJsonPatch<T>(before, after)
    const { result, status, errors, message, errorData } = await apiUpdateEmCategory(employeeId, id, patch)

    if (status) {
      if (result.emCategoryId) {
        const employee = getState().employee.employees.entities[employeeId]
        if (employee) {
          dispatch(setEmployee({ ...employee, emCategoryId: result.emCategoryId }))
        }
      }

      const emCategory = getState().employee.emCategories[employeeId]?.entities[id]
      dispatch(
        setEmCategory({
          employeeId,
          data: {
            ...(emCategory as EmCategoryState),
            ...after
          }
        })
      )

      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
