import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { EmLocationState, IEmLocation } from '../types'

const baseUrl = getBaseUrl('/employee/employee')

export const apiGetEmLocations = (employeeId: string) =>
  request<EmLocationState[]>('get', `${baseUrl}/${employeeId}/location`)

export const apiGetEmLocation = (employeeId: string, emLocationId: string) =>
  request<EmLocationState>('get', `${baseUrl}/${employeeId}/location/${emLocationId}`)

export const apiAddEmLocation = (employeeId: string, emLocation: IEmLocation) =>
  request<{ id: string; emLocationId?: string }>('post', `${baseUrl}/${employeeId}/location`, emLocation)

export const apiUpdateEmLocation = (employeeId: string, id: string, emLocation: Operation[]) =>
  request<{ emLocationId?: string }>('patch', `${baseUrl}/${employeeId}/location/${id}`, emLocation)

export const apiDeleteEmLocation = (employeeId: string, id: string) =>
  request<{ emLocationId?: string }>('delete', `${baseUrl}/${employeeId}/location/${id}`)
