import { setKeyvalue } from '~/features/master'
import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiSaveViewSchemaAs } from '../api/view.api'
import { Screen, IViewSchemaSaveAs } from '../types'
import { setViewSchema } from '../reducers'

export const saveViewSchemaAs = (screenCode: Screen, viewId: string, schema: IViewSchemaSaveAs): ThunkResult<void> => {
  return async dispatch => {
    if (!screenCode || !viewId) return

    const { result, status, errors, message, errorData } = await apiSaveViewSchemaAs(screenCode, viewId, schema)

    if (status) {
      if (result) {
        dispatch(setViewSchema({ screenCode, data: result }))
        dispatch(setKeyvalue({ kv: 'view', data: { key: result.id, value: result.viewName } }))

        showSuccess('Saved')
      }
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
