import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { ShiftTeState, IShiftTe } from '../types'

const baseUrl = getBaseUrl('/attendance/shift')

export const apiGetShiftTes = (shiftId: string) => request<ShiftTeState[]>('get', `${baseUrl}/${shiftId}/te`)

export const apiGetShiftTe = (shiftId: string, shiftTeId: string) =>
  request<ShiftTeState>('get', `${baseUrl}/${shiftId}/te/${shiftTeId}`)

export const apiAddShiftTe = (shiftId: string, shiftTe: IShiftTe) =>
  request<{ id: string }>('post', `${baseUrl}/${shiftId}/te`, shiftTe)

export const apiUpdateShiftTe = (shiftId: string, id: string, shiftTe: Operation[]) =>
  request<void>('patch', `${baseUrl}/${shiftId}/te/${id}`, shiftTe)

export const apiDeleteShiftTe = (shiftId: string, id: string) =>
  request<void>('delete', `${baseUrl}/${shiftId}/te/${id}`)
