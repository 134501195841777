import React, { FC, useCallback, useState } from 'react'
import { ButtonTag, Form } from '~/core-components'
import { Col, DrawerForm, Row } from '~/components'
import { ActionResult, Errors } from '~/types/store'
import { dispatch } from '~/stores/store'
import { ISendActivation } from '../types'
import { sendActivation } from '../actions/send-activation'
import { useLogin } from '../hooks'

interface ActivateLoginTagProps {
  loginId: string
}

const activeTenantCode = localStorage.getItem('tenant')

export const ActivateLoginTag: FC<ActivateLoginTagProps> = ({ loginId }) => {
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [errors, setErrors] = useState<Errors>()
  const [login] = useLogin(loginId, 'when-empty')
  const id = login?.id || ''
  const email = login?.email || ''
  const isInvited = !!login?.activationSentDate || false
  const isActivated = !!login?.activatedDate || false

  const handleTag = useCallback(() => {
    setVisible(true)
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setVisible(false)
  }, [])

  const handleActivate = useCallback(async () => {
    if (!id || !activeTenantCode) {
      return
    }

    let result: ActionResult | undefined
    try {
      setLoading(true)

      const invite: ISendActivation = { id, tenantCode: activeTenantCode }
      result = await dispatch(sendActivation(invite))
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      setVisible(false)
    }
  }, [id])

  if (isActivated === true) return null

  return (
    <>
      <ButtonTag onClick={handleTag}>{isInvited ? 're-send invite' : 'send invite'}</ButtonTag>
      <DrawerForm
        title={isInvited ? 'Re-send invite' : 'Send invite'}
        onClose={handleCloseDrawer}
        okText={isInvited ? 'Re-send' : 'Send'}
        confirmLoading={loading}
        width={400}
        open={visible}
        formId="form-activate-login"
      >
        <Form id="form-activate-login" onFinish={handleActivate}>
          <Row>
            <Col span={24}>
              <Form.Item
                label={isInvited ? 'Re-sending invitation to' : 'Sending invitation to'}
                validateStatus={errors?.email ? 'error' : ''}
                help={errors?.email}
              >
                <b>{email}</b>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </DrawerForm>
    </>
  )
}
