import { apiAddEmCalendar } from '../api/em-calendar.api'
import { ActionResult, ThunkResult } from '~/types/store'
import { emptyGuid } from '~/constants'
import { showSuccess, showError } from '~/utils'
import { setEmCalendar, setEmployee } from '../reducers'
import { IEmCalendarInfo, EmCalendarState } from '../types'

export const addEmCalendar = (employeeId: string, emCalendar: IEmCalendarInfo): ThunkResult<void> => {
  return async (dispatch, getState): Promise<ActionResult | undefined> => {
    if (!employeeId) return

    const { status, result, errors, message, errorData } = await apiAddEmCalendar(employeeId, {
      id: emptyGuid,
      employeeId,
      ...emCalendar,
      calendarId: emCalendar.calendarId
    })
    if (status) {
      if (result.emCalendarId) {
        const employee = getState().employee.employees.entities[employeeId]
        if (employee) {
          dispatch(setEmployee({ ...employee, emCalendarId: result.emCalendarId }))
        }
      }

      const newEmCalendar = {
        id: result.id,
        employeeId,
        ...emCalendar
      } as EmCalendarState
      dispatch(setEmCalendar({ employeeId, data: newEmCalendar }))

      showSuccess('Added')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
