import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import confirm from 'antd/lib/modal/confirm'
import { Button, PageHeader } from '~/core-components'
import { DocumentTitle } from '~/components'
import { attendanceMenus, useMasterMenu } from '~/features/master'
import { usePermissionGate } from '~/features/iam'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { Permission, PermissionAction } from '~/constants'
import { MenuItem } from '~/types/common'
import { dispatch } from '~/stores/store'
import { selectShiftRoles } from '../../selectors'
import { useShiftRole } from '../../hooks'
import { deleteShiftRole } from '../../actions'
import { ShiftRoleInfo } from './components/ShiftRoleInfo'
import { AddShiftRoleDrawer } from './components/AddShiftRoleDrawer'
import { ShiftRoleTes } from './components/ShiftRoleTes'
import './ShiftRole.less'

interface ShiftRoleProps {}

interface ShiftRoleParams {
  id: string
}

interface DrawerState {
  visible: boolean
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: SETTINGS_ROUTES.shiftRoles,
    breadcrumbName: 'Overview'
  },
  {
    path: '',
    breadcrumbName: 'Shift role'
  }
]

export const ShiftRole: FC<ShiftRoleProps> = () => {
  const { id } = useParams<ShiftRoleParams>()
  const shiftRoles = useSelector(selectShiftRoles)
  const [shiftRole] = useShiftRole(id)
  const [editing, setEditing] = useState(false)
  const history = useHistory()
  const canModify = usePermissionGate(Permission.shiftRole, PermissionAction.Modify)
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const { setMenus } = useMasterMenu()

  const handleAddShiftRole = useCallback(() => {
    setDrawerState({ visible: true })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  useEffect(() => {
    const menus: MenuItem[] = shiftRoles.map(s => ({
      path: SETTINGS_ROUTES.shiftRole.replace(':id', s!.id),
      value: s!.name,
      sysPermissionId: Permission.shiftRole
    }))

    if (canModify)
      menus.push({
        path: (
          <Button type="dashed" onClick={handleAddShiftRole} block>
            Add shift role
          </Button>
        ),
        value: '',
        sysPermissionId: ''
      })

    setMenus(menus)
    return () => {
      setMenus(attendanceMenus)
    }
  }, [setMenus, shiftRoles, canModify, handleAddShiftRole])

  const handleEdit = useCallback(() => setEditing(true), [])
  const handleView = useCallback(() => setEditing(false), [])

  const handleDelete = useCallback(() => {
    if (id && shiftRole) {
      confirm({
        title: 'Delete shift role',
        content: `Do you want to delete shift role "${shiftRole?.name}"?`,
        onOk: () => {
          dispatch(deleteShiftRole(id))
          history.push(SETTINGS_ROUTES.shiftRoles)
        },
        okText: 'Delete',
        okType: 'danger'
      })
    }
  }, [id, shiftRole, history])

  return (
    <div id="shift-role" className="shift-role">
      <DocumentTitle title="Shift Role" />
      <PageHeader
        title="Shift role"
        containerId="shift-role"
        breadcrumb={{ routes }}
        extra={
          canModify &&
          editing && (
            <Button key="add" onClick={handleDelete}>
              Delete
            </Button>
          )
        }
      />
      <div className="shift-role__body">
        <ShiftRoleInfo shiftRole={shiftRole} onEdit={handleEdit} onSave={handleView} onCancel={handleView} />
        <ShiftRoleTes shiftRoleId={shiftRole?.id} />
        {canModify && <AddShiftRoleDrawer {...drawerState} onClose={handleCloseDrawer} />}
      </div>
    </div>
  )
}
