import React, { FC } from 'react'
import { Redirect, Switch } from 'react-router-dom'
import { PermissionRoute } from '~/routes/PermissionRoute'
import { PrivateRoute } from '~/routes/PrivateRoute'
import { REPORTS_ROUTES, REPORTS_PATH } from '~/routes/routes'
import {
  attendanceMenus,
  attendanceRoutes,
  claimMenus,
  claimRoutes,
  leaveMenus,
  leaveRoutes,
  momSurveyMenus,
  momSurveyRoutes,
  payMonthlyMenus,
  payMonthlyRoutes,
  payYtdMenus,
  payYtdRoutes,
  peopleMenus,
  peopleRoutes
} from '../configs'
import { AppRouteProps } from '../types'
import { ReportLayout } from './ReportLayout'
import { ReportMenuProvider } from './ReportMenuContext'
import { Reports } from './Reports'

export const peopleRouteProps: AppRouteProps[] = peopleRoutes.map(({ path, exact }) => ({
  path,
  exact,
  sysPermissionId: peopleMenus.find(m => m.path === path)?.sysPermissionId,
  render: () => (
    <ReportMenuProvider menus={peopleMenus}>
      <ReportLayout routes={peopleRoutes} />
    </ReportMenuProvider>
  )
}))

export const payMonthlyRouteProps: AppRouteProps[] = payMonthlyRoutes.map(({ path, exact }) => ({
  path,
  exact,
  sysPermissionId: payMonthlyMenus.find(m => m.path === path)?.sysPermissionId,
  render: () => (
    <ReportMenuProvider menus={payMonthlyMenus}>
      <ReportLayout routes={payMonthlyRoutes} />
    </ReportMenuProvider>
  )
}))

export const payYtdRouteProps: AppRouteProps[] = payYtdRoutes.map(({ path, exact }) => ({
  path,
  exact,
  sysPermissionId: payYtdMenus.find(m => m.path === path)?.sysPermissionId,
  render: () => (
    <ReportMenuProvider menus={payYtdMenus}>
      <ReportLayout routes={payYtdRoutes} />
    </ReportMenuProvider>
  )
}))

export const leaveRouteProps: AppRouteProps[] = leaveRoutes.map(({ path, exact }) => ({
  path,
  exact,
  sysPermissionId: leaveMenus.find(m => m.path === path)?.sysPermissionId,
  render: () => (
    <ReportMenuProvider menus={leaveMenus}>
      <ReportLayout routes={leaveRoutes} />
    </ReportMenuProvider>
  )
}))

export const claimRouteProps: AppRouteProps[] = claimRoutes.map(({ path, exact }) => ({
  path,
  exact,
  sysPermissionId: claimMenus.find(m => m.path === path)?.sysPermissionId,
  render: () => (
    <ReportMenuProvider menus={claimMenus}>
      <ReportLayout routes={claimRoutes} />
    </ReportMenuProvider>
  )
}))

export const attendanceRouteProps: AppRouteProps[] = attendanceRoutes.map(({ path, exact }) => ({
  path,
  exact,
  sysPermissionId: attendanceMenus.find(m => m.path === path)?.sysPermissionId,
  render: () => (
    <ReportMenuProvider menus={attendanceMenus}>
      <ReportLayout routes={attendanceRoutes} />
    </ReportMenuProvider>
  )
}))

export const momSurveyRouteProps: AppRouteProps[] = momSurveyRoutes.map(({ path, exact }) => ({
  path,
  exact,
  sysPermissionId: momSurveyMenus.find(m => m.path === path)?.sysPermissionId,
  render: () => (
    <ReportMenuProvider menus={momSurveyMenus}>
      <ReportLayout routes={momSurveyRoutes} />
    </ReportMenuProvider>
  )
}))

export const ReportsLayout: FC = () => (
  <Switch>
    <Redirect exact from={REPORTS_PATH} to={REPORTS_ROUTES.main} />
    <PrivateRoute exact path={REPORTS_ROUTES.main} component={Reports} />
    {peopleRouteProps.map(route =>
      route.sysPermissionId ? (
        <PermissionRoute key={route.path as string} sysPermissionId={route.sysPermissionId} {...route} />
      ) : (
        <PrivateRoute key={route.path as string} {...route} />
      )
    )}
    {payMonthlyRouteProps.map(route =>
      route.sysPermissionId ? (
        <PermissionRoute key={route.path as string} sysPermissionId={route.sysPermissionId} {...route} />
      ) : (
        <PrivateRoute key={route.path as string} {...route} />
      )
    )}
    {payYtdRouteProps.map(route =>
      route.sysPermissionId ? (
        <PermissionRoute key={route.path as string} sysPermissionId={route.sysPermissionId} {...route} />
      ) : (
        <PrivateRoute key={route.path as string} {...route} />
      )
    )}
    {leaveRouteProps.map(route =>
      route.sysPermissionId ? (
        <PermissionRoute key={route.path as string} sysPermissionId={route.sysPermissionId} {...route} />
      ) : (
        <PrivateRoute key={route.path as string} {...route} />
      )
    )}
    {claimRouteProps.map(route =>
      route.sysPermissionId ? (
        <PermissionRoute key={route.path as string} sysPermissionId={route.sysPermissionId} {...route} />
      ) : (
        <PrivateRoute key={route.path as string} {...route} />
      )
    )}
    {attendanceRouteProps.map(route =>
      route.sysPermissionId ? (
        <PermissionRoute key={route.path as string} sysPermissionId={route.sysPermissionId} {...route} />
      ) : (
        <PrivateRoute key={route.path as string} {...route} />
      )
    )}
    {momSurveyRouteProps.map(route =>
      route.sysPermissionId ? (
        <PermissionRoute key={route.path as string} sysPermissionId={route.sysPermissionId} {...route} />
      ) : (
        <PrivateRoute key={route.path as string} {...route} />
      )
    )}
  </Switch>
)
