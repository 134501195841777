import React, { forwardRef } from 'react'
import classNames from 'classnames'
import { Select } from '../../core-components'
import { SelectProps } from '../../core-components/Select/Select'
import './MonthKeyValues.less'

interface MonthKeyValuesProps extends SelectProps {}

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

const MonthKeyValuesInternal = ({ className, ...props }: MonthKeyValuesProps, ref: React.Ref<any>) => {
  const classes = classNames('monthkeyvalues', { [`${className}`]: className })

  return (
    <Select ref={ref} showSearch className={classes} optionFilterProp="title" {...props}>
      {monthNames.map((monthName, index) => {
        return (
          <Select.Option key={index} value={index + 1} title={monthName}>
            {monthName}
          </Select.Option>
        )
      })}
    </Select>
  )
}

export const MonthKeyValues = forwardRef(MonthKeyValuesInternal)
