import React, { CSSProperties, FC } from 'react'
import { YtdSubmissionFileType, YtdSubmissionStatus } from '~/constants'
import { Space, Tag } from '~/core-components'
import { useSysOptions } from '~/features/employee'
import { YtdSubmissionState } from '../../types'

interface YtdTagsProps {
  ytdSubmission?: YtdSubmissionState
}

const lowercase: CSSProperties = { textTransform: 'lowercase' }

export const YtdTags: FC<YtdTagsProps> = ({ ytdSubmission }) => {
  const [fileTypes] = useSysOptions('ytd_file_type')
  const [statuses] = useSysOptions('ytd_submission_status')

  if (!ytdSubmission) return null

  return (
    <Space align="center">
      {ytdSubmission.fileType && (
        <Tag
          type={ytdSubmission.fileType === YtdSubmissionFileType.original ? 'primary' : 'secondary'}
          style={lowercase}
        >
          {fileTypes[ytdSubmission.fileType]?.value}
        </Tag>
      )}
      {ytdSubmission.status && (
        <Tag type={ytdSubmission.status === YtdSubmissionStatus.draft ? 'neutral' : 'success'} style={lowercase}>
          {statuses[ytdSubmission.status]?.value}
        </Tag>
      )}
    </Space>
  )
}
