import React, { FC, useCallback, useMemo, useState } from 'react'
import { Button, ButtonProps, Dropdown } from '~/core-components'
import { useIsMountedRef } from '~/hooks/use-is-mounted-ref'
import { downloadWithDom, showError } from '~/utils'
import { getFileTimestamp } from '~/utils/dateUtil'
import { apiDownloadPayMonthlyExcel, apiDownloadPayMonthlyPdf } from '~/features/report/api/rpt-pay-monthly.api'
import { ReportType } from '~/constants'
import { useSelector } from 'react-redux'
import { StoreState } from '~/types/store'
import { selectPayRunById } from '../../reducers'
import { useCompany, usePayGroup } from '~/features/master/hooks'
import { IRptPayMonthly } from '~/features/report'

interface PayrollReportButtonProps extends ButtonProps {
  payRunId: string
}

export const PayrollReportButton: FC<PayrollReportButtonProps> = ({ payRunId, ...buttonProps }) => {
  const [downloading, setDownloading] = useState(false)
  const isMountedRef = useIsMountedRef()
  const payRun = useSelector((state: StoreState) => selectPayRunById(state, payRunId))
  const [payGroup] = usePayGroup(payRun?.payGroupId || '', 'when-empty')
  const [company] = useCompany(payGroup?.companyId || '', 'when-empty')

  const formData: IRptPayMonthly = useMemo(
    () => ({
      paymentMonth: payRun?.payPeriod || '',
      reportType: ReportType.detailed,
      companyId: company?.id || '',
      payRunIds: [payRunId],
      sorting: '',
      groupingCodes: [],
      pageBreaks: [],
      suppressEmployee: false,
      showCpfDetails: false,
      reportTitle: '',
      notes: '',
      excelFormat: ''
    }),
    [payRun, company, payRunId]
  )

  const handleDownloadPdf = useCallback(async () => {
    try {
      setDownloading(true)
      const { status, result, errors, message, errorData } = await apiDownloadPayMonthlyPdf(formData)

      if (status) {
        const fileName = `payroll_report_${formData.paymentMonth}_${getFileTimestamp()}.pdf`
        downloadWithDom(result, fileName)
      } else {
        console.error('Error while downloading', errors)
        showError(message, errorData)
      }
    } finally {
      if (isMountedRef.current) setDownloading(false)
    }
  }, [formData, isMountedRef])

  const handleDownloadExcel = useCallback(
    async (format: string) => {
      try {
        setDownloading(true)
        const updatedFormData = { ...formData, excelFormat: format }

        const { status, result, errors, message, errorData } = await apiDownloadPayMonthlyExcel(updatedFormData)

        if (status) {
          const fileName = `payroll_report_${formData.paymentMonth}_${getFileTimestamp()}.xlsx`
          downloadWithDom(result, fileName)
        } else {
          console.error('Error while downloading', errors)
          showError(message, errorData)
        }
      } finally {
        if (isMountedRef.current) setDownloading(false)
      }
    },
    [formData, isMountedRef]
  )

  return (
    <Dropdown
      disabled={downloading}
      menu={{
        items: [
          { key: 'pdf', label: 'PDF', onClick: handleDownloadPdf },
          { key: 'excel1', label: 'Excel format 1', onClick: () => handleDownloadExcel('column') },
          { key: 'excel2', label: 'Excel format 2', onClick: () => handleDownloadExcel('row') }
        ]
      }}
    >
      <Button loading={downloading} {...buttonProps} />
    </Dropdown>
  )
}
