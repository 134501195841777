import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiDeleteOedRecord } from '../api/rpt-oed-record.api'
import { setOedRecordFormDeleting } from '../reducers'

export const deleteOedRecordForm = (id: string): ThunkResult<void> => {
  return async dispatch => {
    if (!id) return

    try {
      dispatch(setOedRecordFormDeleting(true))
      const { status, errors, message, errorData } = await apiDeleteOedRecord(id)
      if (status) {
        showSuccess('Deleted')
      } else {
        if (Object.keys(errors).length === 0) {
          showError(message, errorData)
        }
      }
      return { errors }
    } finally {
      dispatch(setOedRecordFormDeleting(false))
    }
  }
}
