import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { CtPolicyEtState } from '../types'

export const selectCtPolicyEt = createSelector(
  (state: StoreState) => state.claim.ctPolicyEt,
  ctPolicyEtState =>
    memoize((ctPolicyId: string) => {
      return (Object.values(ctPolicyEtState[ctPolicyId]?.entities || {}) as CtPolicyEtState[]) || []
    })
)
