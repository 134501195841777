import { setClaimType, setClaimTypesLoading } from '../reducers'
import { apiGetClaimType } from '../api/claim-type.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchClaimType = (id: string, options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const loading = getState().claim.claimTypesLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().claim.claimTypes.ids.includes(id)
      if (hasData) return
    }

    try {
      dispatch(setClaimTypesLoading(true))
      const { result, status } = await apiGetClaimType(id)
      if (status) {
        dispatch(setClaimType(result))
      }
    } finally {
      dispatch(setClaimTypesLoading(false))
    }
  }
}
