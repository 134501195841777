import React, { FC } from 'react'
import { RouteProps, Switch } from 'react-router-dom'
import { HELP_ROUTES } from '~/routes/routes'
import { EmptyLayout } from '~/layouts/EmptyLayout/EmptyLayout'
import { HelpPage } from '~/features/help/containers/HelpPage/HelpPage'
import { PrivateRoute } from './PrivateRoute'

export const routes: RouteProps[] = [
  {
    path: HELP_ROUTES.page,
    component: HelpPage,
    exact: true
  }
]

export const HelpRoutes: FC = () => {
  return (
    <EmptyLayout>
      <Switch>
        {routes.map(({ path, ...props }) => (
          <PrivateRoute key={path as string} exact path={path} {...props} />
        ))}
      </Switch>
    </EmptyLayout>
  )
}
