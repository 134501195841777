import { Anchor as InternalAnchor } from './Anchor'
import { AnchorLink } from './AnchorLink'

type TAnchor = typeof InternalAnchor
interface IAnchor extends TAnchor {
  Link: typeof AnchorLink
}

const Anchor: IAnchor = InternalAnchor as IAnchor
Anchor.Link = AnchorLink
export { Anchor }
