import { showSuccess, showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { apiCopyShiftDays } from '../api/shift.api'
import { refetchShiftDays } from '../reducers'

export const copyShiftDays = (shiftId: string, id: string, targetDayCodes: string[]): ThunkResult<void> => {
  return async (dispatch): Promise<ActionResult> => {
    if (!shiftId || !id || !targetDayCodes || targetDayCodes.length === 0) return {}

    const { status, errors, message, errorData } = await apiCopyShiftDays(shiftId, id, targetDayCodes)
    if (status) {
      dispatch(refetchShiftDays(shiftId))
      showSuccess('Copied')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
