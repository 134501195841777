import React, { forwardRef } from 'react'
import { KeyValues, KeyValuesProps } from './KeyValues'

interface LveKeyValuesProps extends Omit<KeyValuesProps, 'basePath'> {}

const LveKeyValuesInternal = (props: LveKeyValuesProps, ref: React.Ref<any>) => (
  <KeyValues ref={ref} basePath="leave" {...props} />
)

export const LveKeyValues = forwardRef(LveKeyValuesInternal)
