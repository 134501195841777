import { showSuccess, showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { apiAddMaster } from '../api/master.api'
import { setKeyvalue, setMaster } from '../reducers'
import { IMasterInfo, MasterName, MasterState } from '../types'

export const addMaster = (masterName: MasterName, master: IMasterInfo): ThunkResult<void> => {
  return async (dispatch): Promise<ActionResult | undefined> => {
    if (!masterName) return

    const { status, result, errors, message, errorData } = await apiAddMaster(masterName, master)
    if (status) {
      const newMaster = { id: result.id, ...master } as MasterState
      dispatch(setMaster({ masterName, data: newMaster }))

      if (masterName === 'payGroup' && newMaster) {
        const { name, inactiveDate } = newMaster
        dispatch(setKeyvalue({ kv: 'payGroup', data: { key: result.id, value: name, inactiveDate } }))
      }

      if (masterName === 'leaveGroup' && newMaster) {
        const { name, inactiveDate } = newMaster
        dispatch(setKeyvalue({ kv: 'leaveGroup', data: { key: result.id, value: name, inactiveDate } }))
      }

      showSuccess('Added')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
