import { UserManagerSettings } from 'oidc-client'
import env from '../env'

const port = window.location.port ? `:${window.location.port}` : ''
const baseUrl = `${window.location.protocol}//${window.location.hostname}${port}`

export const IDENTITY_CONFIG: UserManagerSettings = {
  authority: env.REACT_APP_AUTH_URL,
  client_id: env.REACT_APP_IDENTITY_CLIENT_ID,
  redirect_uri: `${baseUrl}${env.REACT_APP_REDIRECT_URL}`,
  silent_redirect_uri: `${baseUrl}${env.REACT_APP_SILENT_REDIRECT_URL}`,
  post_logout_redirect_uri: `${baseUrl}${env.REACT_APP_LOGOUT_REDIRECT_URL}`,
  response_type: 'code',
  scope: 'openid offline_access emp_api psg_api lve_api cla_api att_api job_api ntf_api fil_api idp_api hos_api'
}
