import React from 'react'
import { AuthConsumer } from './AuthProvider'

export const SilentRenew = () => (
  <AuthConsumer>
    {({ signinSilentCallback }) => {
      signinSilentCallback()
      return null
    }}
  </AuthConsumer>
)
