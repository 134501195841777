import React, { CSSProperties, FC, useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment-timezone'
import { useSelector } from 'react-redux'
import { AlignType, Button, Card, ColumnsType, Dropdown, Link, Space, Table, Text, Tooltip } from '~/core-components'
import { Col, DateCustomPicker, DateCustomPickerItem, Gps, Person, Row } from '~/components'
import {
  Screen,
  ViewCriteriaSimple,
  ViewCriteria,
  updateViewCriteria,
  useViewSchema,
  ViewSelectionButton,
  useFirstView
} from '~/features/selection'
import { usePermissionGate } from '~/features/iam'
import { StoreState } from '~/types/store'
import { dispatch } from '~/stores/store'
import { EMP_ROUTES } from '~/routes/routes'
import { useIsMountedRef } from '~/hooks'
import { DateRange } from '~/types/common'
import { Permission, PermissionAction } from '~/constants'
import {
  formatYearMonth,
  formatMonth,
  formatMoney,
  getFileTimestamp,
  downloadWithDom,
  showError,
  getBaseUrl,
  formatDate
} from '~/utils'
import { selectTimeLogsView } from '../../selectors'
import { fetchTimeLogsView } from '../../actions'
import { apiGetTimeLogsExcel } from '../../api/time-log.api'
import { useLocationsDict, useProjectsDict, useShiftsDict } from '../../hooks'
import { refetchTimeLogsView } from '../../reducers'
import { TimeLogRowState } from '../../types'
import { MutateTimeLogDrawer } from './components/MutateTimeLogDrawer'
import { ImportTimeLogDrawer } from './components/ImportTimeLog/ImportTimeLogDrawer'
import { TimeLogStatusTag } from '../TimeLogStatus/TimeLogStatus'
import './TimeLogs.less'

interface TimeLogsProps {}

const nameColWidth = 250
const dateTimeColWidth = 260
const smallColWidth = 80
const gpsColWidth = 120
const defaultColWidth = 150
const paginationStyle: CSSProperties = { marginRight: 20 }

interface DrawerState {
  visible: boolean
  data?: TimeLogRowState
}
interface ImportTimeLogDrawerState {
  visible: boolean
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }
const IMPORT_TIME_LOG_DRAWER_STATE: ImportTimeLogDrawerState = { visible: false }

const SCREEN_CODE: Screen = 'time_log'
const PAGE_SIZE_OPTIONS = ['20', '50', '100']
const baseUrl = getBaseUrl('/filestore')

const START_DATE = moment().startOf('isoWeek').format('YYYY-MM-DD')
const END_DATE = moment().endOf('isoWeek').format('YYYY-MM-DD')

const PERIOD_OPTIONS: DateCustomPickerItem[] = [
  {
    key: 'this_week',
    value: 'This week',
    startDate: START_DATE,
    endDate: END_DATE
  },
  {
    key: 'last_week',
    value: 'Last week',
    startDate: moment().startOf('isoWeek').add(-1, 'week').format('YYYY-MM-DD'),
    endDate: moment().endOf('isoWeek').add(-1, 'week').format('YYYY-MM-DD')
  },
  {
    key: 'this_month',
    value: 'This month',
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').format('YYYY-MM-DD')
  },
  {
    key: 'last_month',
    value: 'Last month',
    startDate: moment().startOf('month').add(-1, 'month').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').add(-1, 'month').format('YYYY-MM-DD')
  }
]

export const TimeLogs: FC<TimeLogsProps> = () => {
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(20)

  const isMountedRef = useIsMountedRef()
  const [downloading, setDownloading] = useState(false)

  const [view] = useFirstView('time_log')
  const viewId = view?.id || ''
  const selection = useSelector((state: StoreState) => state.selection.sysSelectionFields[SCREEN_CODE])
  const viewLoading = useSelector((state: StoreState) => state.selection.viewLoading[SCREEN_CODE]?.[viewId || ''])
  const [schema] = useViewSchema(SCREEN_CODE, viewId)
  const dataLoading = useSelector((state: StoreState) => state.attendance.timeLogsViewLoading)
  const data = useSelector(selectTimeLogsView)(viewId || '')
  const refetch = useSelector((state: StoreState) => state.attendance.timeLogsViewRefetch)

  const [startDate, setStartDate] = useState<string | null>(START_DATE)
  const [endDate, setEndDate] = useState<string | null>(END_DATE)
  const canViewEmployee = usePermissionGate(Permission.employee)

  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const [importTimeLogDrawerState, setImportTimeLogDrawerState] =
    useState<ImportTimeLogDrawerState>(IMPORT_TIME_LOG_DRAWER_STATE)
  const canModify = usePermissionGate(Permission.location, PermissionAction.Modify)

  const [locationDict] = useLocationsDict()
  const [projectDict] = useProjectsDict()
  const [shiftDict] = useShiftsDict()

  useEffect(() => {
    if (viewId)
      dispatch(fetchTimeLogsView(viewId, { offset: pageSize * (page - 1), limit: pageSize }, startDate, endDate))
  }, [viewId, page, pageSize, startDate, endDate, refetch])

  const handleAddTimeLog = useCallback(() => {
    setDrawerState({ visible: true })
  }, [])

  const handleEditTimeLog = useCallback((timeLog: TimeLogRowState) => {
    setDrawerState({ visible: true, data: timeLog })
  }, [])

  const tableWidth =
    (schema?.selection.reduce(
      (sum, f) => (sum += selection?.entities[f.selectionFieldId]?.width || defaultColWidth),
      0
    ) || 0) +
    nameColWidth +
    dateTimeColWidth +
    smallColWidth +
    gpsColWidth * 2 +
    defaultColWidth * 5

  const columns = useMemo(() => {
    let columns: ColumnsType<TimeLogRowState> = [
      {
        title: 'Name',
        key: 'employeeId',
        dataIndex: 'employeeId',
        fixed: 'left',
        className: 'first-col',
        width: nameColWidth,
        onCell: (record, index) => {
          const prevId = data.data[(index as number) - 1]?.employeeId
          if (prevId === record.employeeId) {
            return { rowSpan: 0 }
          } else {
            const nextId = data.data[(index as number) + 1]?.employeeId
            if (nextId === record.employeeId) {
              return { rowSpan: record.rowSpan }
            }
          }
          return {}
        },
        render: (_, record) => (
          <Person
            name={record.employeeName}
            description={record.description}
            photo={record.photoId && `${baseUrl}/file/${record.photoId}/thumbnailphoto/36`}
            path={canViewEmployee ? EMP_ROUTES.employee.replace(':id', record.employeeId) : undefined}
            size={36}
          />
        )
      },
      {
        title: 'Time (in / out)',
        key: 'timeIn',
        dataIndex: 'timeIn',
        fixed: 'left',
        width: dateTimeColWidth,
        render: (_, record) => {
          const isRequest = record.timeInRequest != null || record.timeOutRequest != null
          const timeIn = record.timeInRequest ?? record.timeIn
          const timeInObj = timeIn ? moment(timeIn) : undefined
          const timeOut = record.timeOutRequest ?? record.timeOut
          const timeOutObj = timeOut ? moment(timeOut) : undefined
          const isNextDayOutTime = timeOutObj?.isAfter(timeInObj, 'date')

          return (
            <Link onClick={() => handleEditTimeLog(record)}>
              <Row>
                <Col flex="110px">{record.timeIn ? formatDate(record.timeIn) : '-'}</Col>
                <Col flex="1">
                  <Space>
                    {timeInObj ? timeInObj.format('HH:mm') : '-'}
                    {timeOutObj ? timeOutObj.format(' - HH:mm') : ' - '}
                    {isNextDayOutTime && (
                      <Tooltip title={timeOutObj?.format('DD MMM YYYY')}>
                        <i className="fal fa-calendar-plus" />
                      </Tooltip>
                    )}
                    {isRequest && '*'}
                  </Space>
                </Col>
              </Row>
            </Link>
          )
        }
      }
    ]

    if (schema) {
      // Configurable employee columns
      columns.push(
        ...schema?.selection.map(f => {
          const field = selection?.entities[f.selectionFieldId]
          const align = field?.format === 'money' ? 'right' : ('left' as AlignType)

          return {
            title: field?.description,
            key: field?.fieldName,
            dataIndex: field?.fieldName,
            width: field?.width || defaultColWidth,
            align,
            onCell: (record: TimeLogRowState, index?: number) => {
              const prevId = data.data[(index as number) - 1]?.employeeId
              if (prevId === record.employeeId) {
                return { rowSpan: 0 }
              } else {
                const nextId = data.data[(index as number) + 1]?.employeeId
                if (nextId === record.employeeId) {
                  return { rowSpan: record.rowSpan }
                }
              }
              return {}
            },
            render: (value: string | number | boolean, record: TimeLogRowState, index: number) => {
              var display = value
              if (field?.format === 'date' && value) {
                display = moment(value as string).format('DD MMM YYYY')
              }
              if (field?.format === 'yearmonth' && value) {
                display = formatYearMonth(value as string)
              }
              if (field?.format === 'month' && value) {
                display = formatMonth(value as number)
              }
              if (field?.format === 'money' && value) {
                display = formatMoney(value as number)
              }
              if (field?.format === 'yes_no') {
                display = (value as boolean) ? 'Yes' : 'No'
              }

              return display
            }
          }
        })
      )
    }

    // Fixed time log columns
    columns.push(
      {
        title: 'Shift',
        key: 'shiftId',
        dataIndex: 'shiftId',
        width: defaultColWidth,
        render: (value: string) => (value ? shiftDict[value]?.name : '-')
      },
      {
        title: 'Location',
        key: 'locationId',
        dataIndex: 'locationId',
        width: defaultColWidth,
        render: (value: string, record) => (
          <>
            {locationDict[value]?.name}
            {record.locationId !== record.outLocationId && record.outLocationId
              ? ` / ${locationDict[record.outLocationId]?.name}`
              : ''}
          </>
        )
      },
      {
        title: 'GPS (in)',
        key: 'inLatitude',
        dataIndex: 'inLatitude',
        width: gpsColWidth,
        render: (value: string, record: TimeLogRowState) => (
          <Text size="small">
            <Gps lat={record.inLatitude} lng={record.inLongitude} />
          </Text>
        )
      },
      {
        title: 'GPS (out)',
        key: 'ouLatitude',
        dataIndex: 'ouLatitude',
        width: gpsColWidth,
        render: (value: string, record: TimeLogRowState) => (
          <Text size="small">
            <Gps lat={record.outLatitude} lng={record.outLongitude} />
          </Text>
        )
      },
      {
        title: 'Project',
        key: 'projectId',
        dataIndex: 'projectId',
        width: defaultColWidth,
        render: (value: string) => projectDict[value]?.name
      },
      {
        title: 'Notes',
        key: 'notes',
        dataIndex: 'notes',
        width: defaultColWidth
      },
      {
        title: 'Status',
        key: 'approvalStatus',
        dataIndex: 'approvalStatus',
        width: defaultColWidth,
        render: (value: string) => <TimeLogStatusTag status={value} />
      },
      {
        title: 'Daily',
        key: 'dailyRecordId',
        dataIndex: 'dailyRecordId',
        width: smallColWidth,
        render: (value: string) => (Boolean(value) ? 'Yes' : 'No')
      }
    )

    return columns
  }, [schema, canViewEmployee, selection, data.data, locationDict, shiftDict, projectDict, handleEditTimeLog])

  const handleDateRangeChange = useCallback((startDate: string | null, endDate: string | null) => {
    setPage(1)
    setStartDate(startDate)
    setEndDate(endDate)
  }, [])

  const handleDownloadClick = useCallback(async () => {
    if (viewId) {
      try {
        setDownloading(true)
        const { status, result, errors, message, errorData } = await apiGetTimeLogsExcel(viewId, {
          startDate,
          endDate
        } as DateRange)

        if (status) {
          const fileName = `time_logs_${getFileTimestamp()}.xlsx`
          downloadWithDom(result, fileName)
        } else {
          console.error('Error while downloading', errors)
          showError(message, errorData)
        }
      } finally {
        if (isMountedRef.current) setDownloading(false)
      }
    }
  }, [viewId, startDate, endDate, isMountedRef])

  const handleCloseSelectionDrawer = useCallback((changed?: boolean) => {
    if (changed) dispatch(refetchTimeLogsView())
  }, [])

  const handleCriteriaApply = useCallback(
    async (criteria: ViewCriteria[]) => {
      if (viewId) {
        setPage(1)
        await dispatch(updateViewCriteria(SCREEN_CODE, viewId, { id: viewId, criteria }))
        dispatch(refetchTimeLogsView())
      }
    },
    [viewId]
  )

  const handlePaginationChange = useCallback((page: number, pageSize?: number) => {
    setPage(page)
    setPageSize(pageSize || 20)
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const handleImportTimeLog = useCallback(async () => {
    setImportTimeLogDrawerState({ visible: true })
  }, [])

  const handleCloseImportTimeLogDrawer = useCallback((success?: boolean) => {
    setImportTimeLogDrawerState(IMPORT_TIME_LOG_DRAWER_STATE)
    if (success) dispatch(refetchTimeLogsView())
  }, [])

  const handleRefreshClick = useCallback(() => {
    dispatch(refetchTimeLogsView())
  }, [])

  return (
    <div className="time-logs">
      <div className="time-logs__body">
        <div className="time-logs__action-bar">
          <ViewCriteriaSimple screenCode={SCREEN_CODE} viewId={viewId} onApply={handleCriteriaApply} label="" />
          <Space align="start">
            {canModify && (
              <Dropdown.Button
                menu={{
                  items: [{ key: 'import', label: 'Upload time logs', onClick: handleImportTimeLog }]
                }}
                icon={<i className="fa-light fa-angle-down" />}
                onClick={handleAddTimeLog}
                buttonsRender={([leftButton, rightButton]) => [
                  React.cloneElement(leftButton as React.ReactElement),
                  rightButton
                ]}
              >
                Add time log
              </Dropdown.Button>
            )}
            <Tooltip title="Refresh">
              <Button icon={<i className="fal fa-refresh" />} onClick={handleRefreshClick} loading={dataLoading} />
            </Tooltip>
            <DateCustomPicker defaultValue="this_week" options={PERIOD_OPTIONS} onChange={handleDateRangeChange} />
            <Tooltip title="Download excel">
              <Button
                icon={<i className="fal fa-arrow-down-to-bracket" />}
                onClick={handleDownloadClick}
                loading={downloading}
              />
            </Tooltip>
            <ViewSelectionButton
              screenCode={SCREEN_CODE}
              viewId={viewId || ''}
              title="Configure time log columns"
              onClose={handleCloseSelectionDrawer}
            />
          </Space>
        </div>
        <Card fitParent table>
          <Table
            rowKey="id"
            dataSource={data?.data}
            columns={columns}
            fitParent
            loading={dataLoading || viewLoading}
            scroll={{ x: tableWidth, y: 1000 }}
            pagination={{
              total: data?.count,
              current: page,
              pageSize,
              pageSizeOptions: PAGE_SIZE_OPTIONS,
              showSizeChanger: true,
              onChange: handlePaginationChange,
              style: paginationStyle
            }}
          />
        </Card>
      </div>
      {canModify && (
        <>
          <MutateTimeLogDrawer {...drawerState} onClose={handleCloseDrawer} />
          <ImportTimeLogDrawer {...importTimeLogDrawerState} onClose={handleCloseImportTimeLogDrawer} />
        </>
      )}
    </div>
  )
}
