import { request, getBaseUrl } from '~/utils/request'
import { CtPolicyEtState, CtPolicyState } from '../types'

const baseUrl = getBaseUrl('/claim/claimtype')

export const apiGetCtPolicies = (claimTypeId: string) =>
  request<CtPolicyState[]>('get', `${baseUrl}/${claimTypeId}/policy`)

export const apiGetCtPoliciesExtra = (claimTypeId: string) =>
  request<CtPolicyState[]>('get', `${baseUrl}/${claimTypeId}/policy/extra`)

export const apiAddCtPolicy = (claimTypeId: string, ltPolicy: CtPolicyState) =>
  request<{ id: string; viewId: string }>('post', `${baseUrl}/${claimTypeId}/policy`, ltPolicy)

export const apiUpdateCtPolicy = (claimTypeId: string, id: string, ltPolicy: CtPolicyState) =>
  request<CtPolicyState>('patch', `${baseUrl}/${claimTypeId}/policy/${id}`, ltPolicy)

export const apiDeleteCtPolicy = (claimTypeId: string, id: string) =>
  request<CtPolicyState>('delete', `${baseUrl}/${claimTypeId}/policy/${id}`)

export const apiGetCtPolicyEt = (claimTypeId: string, id: string) =>
  request<CtPolicyEtState[]>('get', `${baseUrl}/${claimTypeId}/policy/${id}/expensetype`)
