import { Checkbox as InternalCheckbox } from './Checkbox'
import { CheckboxGroup } from './CheckboxGroup'
import { CheckboxChangeEvent as AntCheckboxChangeEvent } from 'antd/lib/checkbox'
import { CheckboxValueType as AntCheckboxValueType } from 'antd/lib/checkbox/Group'

type TCheckbox = typeof InternalCheckbox
interface ICheckbox extends TCheckbox {
  Group: typeof CheckboxGroup
}

const Checkbox: ICheckbox = InternalCheckbox as ICheckbox
Checkbox.Group = CheckboxGroup
export { Checkbox }

export interface CheckboxChangeEvent extends AntCheckboxChangeEvent {}
export type CheckboxValueType = AntCheckboxValueType
