import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import confirm from 'antd/lib/modal/confirm'
import omit from 'lodash/omit'
import { Button, Comment, Form, Input, Link, List } from '~/core-components'
import { PersonAvatar, Col, Row } from '~/components'
import { useMyEmployee } from '~/features/my'
import { ActionResult, Errors } from '~/types/store'
import { useFocus } from '~/hooks/use-focus'
import { dispatch } from '~/stores/store'
import { getBaseUrl } from '~/utils'
import { deleteClaimRecordComment, updateClaimRecordComment } from '../../actions'
import { useMyClaimRecordComments } from '../../hooks'
import { IClaimRecordComment } from '../../types'
import './SSClaimRecordComments.less'

interface SSClaimRecordCommentsProps {
  id: string
  showHeader?: boolean
}

interface CommentHoverState {
  id: string
  isHover?: boolean
}
const DEFAULT_HOVER: CommentHoverState = {
  id: '',
  isHover: false
}

interface CommentEditState {
  id: string
  comment?: string
  isEdit?: boolean
}
const DEFAULT_EDIT: CommentEditState = {
  id: '',
  comment: '',
  isEdit: false
}

const baseUrl = getBaseUrl('/filestore')

export const SSClaimRecordComments: FC<SSClaimRecordCommentsProps> = ({ id, showHeader = false }) => {
  const [me] = useMyEmployee()
  const myId = me.id
  const [comments] = useMyClaimRecordComments(id)

  const handleUpdate = useCallback(
    async (commentId: string, comment: string): Promise<ActionResult | undefined> => {
      if (!id || !commentId) return

      return await dispatch(updateClaimRecordComment(id, commentId, comment || ''))
    },
    [id]
  )

  const handleDelete = useCallback(
    async (commentId: string): Promise<ActionResult | undefined> => {
      if (!id || !commentId) return

      return await dispatch(deleteClaimRecordComment(id, commentId))
    },
    [id]
  )

  return (
    <SSClaimRecordCommentsComponent
      authorId={myId}
      comments={comments}
      showHeader={showHeader}
      onUpdate={handleUpdate}
      onDelete={handleDelete}
    />
  )
}

interface SSClaimRecordCommentsComponentProps {
  authorId?: string
  showHeader?: boolean
  comments?: IClaimRecordComment[]
  onUpdate?: (commentId: string, comment: string) => Promise<ActionResult | undefined>
  onDelete?: (commentId: string) => Promise<ActionResult | undefined>
}

export const SSClaimRecordCommentsComponent: FC<SSClaimRecordCommentsComponentProps> = ({
  authorId,
  comments,
  onUpdate,
  onDelete,
  showHeader = false
}) => {
  const [errors, setErrors] = useState<Errors>()
  const [focusRef, setFocus] = useFocus(true)

  const [hovered, setHovered] = useState<CommentHoverState>(DEFAULT_HOVER)
  const [onEdit, setOnEdit] = useState<CommentEditState>(DEFAULT_EDIT)
  const [commenting, setCommenting] = useState(false)

  useEffect(() => {
    setTimeout(() => setFocus(), 100)
    setErrors(undefined)
  }, [setFocus])

  const handleMouseEnter = useCallback((commentId: string) => {
    setHovered({ id: commentId, isHover: true })
  }, [])

  const handleMouseClaim = useCallback(() => {
    setHovered(DEFAULT_HOVER)
  }, [])

  const handleEditToggle = useCallback((commentId: string, comment: string) => {
    setOnEdit({ id: commentId, comment: comment, isEdit: true })
  }, [])

  const handleCancelEdit = useCallback(() => {
    setErrors(undefined)
    setOnEdit(DEFAULT_EDIT)
  }, [])

  const handleSaveComment = useCallback(
    async (commentId: string) => {
      if (!commentId || typeof onUpdate !== 'function') return

      try {
        setCommenting(true)
        const result = await onUpdate(commentId, onEdit.comment || '')
        if (!result?.errors) {
          setOnEdit(DEFAULT_EDIT)
        }
      } finally {
        setCommenting(false)
      }
    },
    [onEdit, onUpdate]
  )

  const handleDelete = useCallback(
    (commentId: string, comment: string) => {
      if (!commentId || typeof onDelete !== 'function') return

      confirm({
        title: 'Delete comment',
        content: `Do you want to delete your comment "${comment}" ?`,
        onOk: async () => {
          const result: ActionResult | undefined = await onDelete(commentId)
          if (result?.errors) {
            setOnEdit(DEFAULT_EDIT)
          }
        },
        okText: 'Delete',
        okType: 'danger'
      })
    },
    [onDelete]
  )

  if (!comments) return null

  return (
    <div className="my-claim-record-comments">
      {comments.length > 0 && (
        <List
          size="small"
          dataSource={comments.map(c => ({
            ...omit(c, ['authorId', 'photoId', 'createdDate', 'updatedDate']),
            avatar: c?.photoId && (
              <PersonAvatar photo={`${baseUrl}/publicfile/${c.photoId}/thumbnailphoto/24`} size={24} />
            ),
            author: c?.author,
            datetime: (
              <Row key={c.id}>
                <Col className="col-time">{c?.datetime}</Col>
                <Col
                  className="col-action-edit"
                  hidden={!(hovered.isHover && c?.id === hovered.id && c?.authorId === authorId && !onEdit.isEdit)}
                >
                  <Link size="small" onClick={() => handleEditToggle(c?.id, c?.content)}>
                    edit
                  </Link>
                </Col>
                <Col
                  className="col-action-delete"
                  hidden={!(hovered.isHover && c?.id === hovered.id && c?.authorId === authorId && !onEdit.isEdit)}
                >
                  <Link size="small" onClick={() => handleDelete(c?.id, c?.content)}>
                    delete
                  </Link>
                </Col>
              </Row>
            ),
            content:
              onEdit.id === c?.id && onEdit.isEdit ? (
                <Row align="top" gutter={10}>
                  <Col span={16}>
                    <Form.Item validateStatus={errors?.comment ? 'error' : ''} help={errors?.comment}>
                      <Input.TextArea
                        ref={focusRef}
                        rows={2}
                        onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                          setOnEdit({ ...onEdit, comment: event.target.value })
                        }
                        value={onEdit.comment}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item>
                      <Button size="small" loading={commenting} onClick={() => handleSaveComment(c?.id)}>
                        Save
                      </Button>
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item>
                      <Button size="small" hidden={commenting} onClick={handleCancelEdit}>
                        Cancel
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              ) : (
                <>
                  {c?.content} {c?.updatedDate && <span className="edited-label">(edited)</span>}
                </>
              )
          }))}
          header={showHeader ? `${comments.length} ${comments.length > 1 ? 'comments' : 'comment'}` : ''}
          itemLayout="horizontal"
          renderItem={props => (
            <Comment onMouseEnter={() => handleMouseEnter(props?.id)} onMouseLeave={handleMouseClaim} {...props} />
          )}
        />
      )}
    </div>
  )
}
