import { ActionResult, ThunkResult } from '~/types/store'
import { emptyGuid } from '~/constants'
import { showSuccess, showError } from '~/utils'
import { apiAddEmGrp } from '../api/em-grp.api'
import { setEmGrp, setEmployee } from '../reducers'
import { IEmGrpInfo, EmGrpState } from '../types'

export const addEmGrp = (employeeId: string, emGrp: IEmGrpInfo): ThunkResult<void> => {
  return async (dispatch, getState): Promise<ActionResult | undefined> => {
    if (!employeeId) return

    const { status, result, errors, message, errorData } = await apiAddEmGrp(employeeId, {
      id: emptyGuid,
      employeeId,
      ...emGrp,
      grpId: emGrp.grpId || emptyGuid
    })
    if (status) {
      if (result.emGrpId) {
        const employee = getState().employee.employees.entities[employeeId]
        if (employee) {
          dispatch(setEmployee({ ...employee, emGrpId: result.emGrpId }))
        }
      }

      const newEmGrp = {
        id: result.id,
        employeeId,
        ...emGrp
      } as EmGrpState
      dispatch(setEmGrp({ employeeId, data: newEmGrp }))

      showSuccess('Added')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
