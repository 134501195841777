import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { apiUpdateExpenseType } from '../api/expense-type.api'
import { ExpenseTypeState, IExpenseType } from '../types'
import { setExpenseType } from '../reducers'
import { setKeyvalue } from '~/features/master'

export const updateExpenseType = <T extends IExpenseType>(id: string, before: T, request: T): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const after = { ...before, ...request } as T
    const patch = generateJsonPatch<T>(before, after)
    const { status, errors, message, errorData } = await apiUpdateExpenseType(id, patch)

    if (status) {
      const expenseType = getState().claim.expenseTypes?.entities[id]
      const updated = { ...(expenseType as ExpenseTypeState), ...after }
      dispatch(setExpenseType(updated))
      dispatch(setKeyvalue({ kv: 'expenseType', data: { key: id, value: updated.name } }))
      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
