import React, { FC, useCallback, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { Form, Input, ModalTristate } from '~/core-components'
import { Col, ErrorDisplay, Row } from '~/components'
import { useFocus } from '~/hooks/use-focus'
import { IUpdateClaimPendingPayment } from '../../../types'
import { ActionResult, Errors } from '~/types/store'
import { dispatch } from '~/stores/store'
import { updateClaimPendingPayment } from '~/features/claim/actions'

interface UpdateClaimPendingPaymentModalProps {
  visible: boolean
  claimRecordIds: string[]
  onClose: (action: 'updated' | 'cancelled') => void
}

interface FormData extends IUpdateClaimPendingPayment {}

const EMPTY_FORM_DATA: FormData = {
  claimRecordIds: [],
  paymentDate: ''
}

export interface UpdateClaimPendingPaymentModalState {
  visible: boolean
  claimRecordIds: string[]
}

export const DEFAULT_UPDATE_PAYMENT_MODAL_STATE: UpdateClaimPendingPaymentModalState = {
  visible: false,
  claimRecordIds: []
}

export const UpdateClaimPendingPaymentModal: FC<UpdateClaimPendingPaymentModalProps> = ({
  visible,
  claimRecordIds,
  onClose
}) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)
  const [focusRef, setFocus] = useFocus(true)
  const [errors, setErrors] = useState<Errors>()

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  useEffect(() => {
    if (visible) {
      setFormData({ ...EMPTY_FORM_DATA, claimRecordIds })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [visible, claimRecordIds])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => setFormData(formData => ({ ...formData, ...updates })),
    []
  )

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      result = await dispatch(updateClaimPendingPayment(formData))
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose('updated')
    }
  }, [formData, onClose])

  const handleCancel = useCallback(async () => {
    typeof onClose === 'function' && onClose('cancelled')
  }, [onClose])

  return (
    <ModalTristate
      visible={visible}
      title="Update payment"
      width={350}
      yesText="Apply"
      noText="Cancel"
      onYes={handleOk}
      onNo={handleCancel}
      onCancel={handleCancel}
      className="update-payment-modal"
      confirmLoading={loading}
    >
      <Form>
        <Row>
          <Col span={24}>
            <Form.Item label="Payment date">
              <Input.Date
                ref={focusRef}
                value={formData.paymentDate ? moment(formData.paymentDate) : undefined}
                onChange={(value: moment.Moment | null) => {
                  handleFormDataChange({ paymentDate: value?.format('YYYY-MM-DD') })
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <ErrorDisplay errors={errors} />
      </Form>
    </ModalTristate>
  )
}
