import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { LoadingOutlined } from '@ant-design/icons'
import { Col, Row } from '~/components'
import { Button, Card, Skeleton } from '~/core-components'
import { fetchSysSelectionFields, fetchViewSchemaByName, Screen, selectViewSchemaByName } from '~/features/selection'
import { ViewSelectionDrawerByName } from '~/features/selection/containers/ViewSelectionDrawerByName'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import './PayslipConfig.less'

export interface PayslipConfigProps {
  payGroupId: string
}

interface SelectionDrawerState {
  visible: boolean
  viewName: string
}

const DEFAULT_SELECTION_DRAWER_STATE: SelectionDrawerState = { visible: false, viewName: '' }
const leftScreenCode: Screen = 'em_payslip'
const rightScreenCode: Screen = 'pay_payslip'

export const PayslipConfig: FC<PayslipConfigProps> = ({ payGroupId }) => {
  const viewName = `paygroup_${payGroupId}`
  const leftFields = useSelector((state: StoreState) => state.selection.sysSelectionFields[leftScreenCode])
  const [leftSelectionDrawerState, setLeftSelectionDrawerState] =
    useState<SelectionDrawerState>(DEFAULT_SELECTION_DRAWER_STATE)
  const leftView = useSelector(selectViewSchemaByName)(leftScreenCode, viewName)
  const leftViewId = leftView?.id || ''
  const leftSchema = useSelector((state: StoreState) =>
    leftViewId ? state.selection.viewSchema[leftScreenCode]?.entities[leftViewId] : undefined
  )
  const leftLoading = useSelector((state: StoreState) => {
    const viewSchemaLoading = state.selection.viewSchemaLoading[leftScreenCode]
    return viewSchemaLoading && viewSchemaLoading[leftViewId]
  })

  const rightFields = useSelector((state: StoreState) => state.selection.sysSelectionFields[rightScreenCode])
  const [rightSelectionDrawerState, setRightSelectionDrawerState] =
    useState<SelectionDrawerState>(DEFAULT_SELECTION_DRAWER_STATE)
  const rightView = useSelector(selectViewSchemaByName)(rightScreenCode, viewName)
  const rightViewId = rightView?.id || ''
  const rightSchema = useSelector((state: StoreState) =>
    rightViewId ? state.selection.viewSchema[rightScreenCode]?.entities[rightViewId] : undefined
  )
  const rightLoading = useSelector((state: StoreState) => {
    const viewSchemaLoading = state.selection.viewSchemaLoading[rightScreenCode]
    return viewSchemaLoading && viewSchemaLoading[rightViewId]
  })

  useEffect(() => {
    if (viewName) {
      dispatch(fetchViewSchemaByName(leftScreenCode, viewName, true))
      dispatch(fetchViewSchemaByName(rightScreenCode, viewName, true))
    }

    // is called upon parent screen shown
    dispatch(fetchSysSelectionFields(leftScreenCode, { strategy: 'when-empty' }))
    dispatch(fetchSysSelectionFields(rightScreenCode, { strategy: 'when-empty' }))
  }, [viewName])

  const handleLeftSelectionClick = useCallback(() => {
    if (viewName) {
      setLeftSelectionDrawerState({ visible: true, viewName })
    }
  }, [setLeftSelectionDrawerState, viewName])

  const handleCloseLeftSelectionDrawer = useCallback(() => {
    setLeftSelectionDrawerState(DEFAULT_SELECTION_DRAWER_STATE)
  }, [])

  const handleRightSelectionClick = useCallback(() => {
    if (viewName) {
      setRightSelectionDrawerState({ visible: true, viewName })
    }
  }, [setRightSelectionDrawerState, viewName])

  const handleCloseRightSelectionDrawer = useCallback(() => {
    setRightSelectionDrawerState(DEFAULT_SELECTION_DRAWER_STATE)
  }, [])

  return (
    <Card className="payslip-config" title="Payslip header">
      <Row gutter={30}>
        <Col span={12} className="payslip-config__left">
          <Button
            className="payslip-config__btn-setting"
            icon={leftLoading ? <LoadingOutlined spin /> : <i className="fal fa-gear" />}
            onClick={handleLeftSelectionClick}
          />
          <div className="payslip-config__fields">
            {leftSchema?.selection.map(ls => {
              const field = leftFields?.entities[ls.selectionFieldId]
              return (
                <Row key={ls.id} className="payslip-config__field">
                  <Col span={12}>{field?.description}</Col>
                  <Col span={12} className="payslip-config__field-value">
                    <Skeleton.Input />
                  </Col>
                </Row>
              )
            })}
          </div>
        </Col>
        <Col span={12} className="payslip-config__right">
          <Button
            className="payslip-config__btn-setting"
            icon={rightLoading ? <LoadingOutlined spin /> : <i className="fal fa-gear" />}
            onClick={handleRightSelectionClick}
          />
          <div className="payslip-config__fields">
            {rightSchema?.selection.map(rs => {
              const field = rightFields?.entities[rs.selectionFieldId]
              return (
                <Row key={rs.id} className="payslip-config__field">
                  <Col span={12}>{field?.description}</Col>
                  <Col span={12} className="payslip-config__field-value">
                    <Skeleton.Input />
                  </Col>
                </Row>
              )
            })}
          </div>
        </Col>
      </Row>
      <ViewSelectionDrawerByName
        {...leftSelectionDrawerState}
        screenCode={leftScreenCode}
        title="Payslip configuration (left side)"
        onClose={handleCloseLeftSelectionDrawer}
      />
      <ViewSelectionDrawerByName
        {...rightSelectionDrawerState}
        screenCode={rightScreenCode}
        title="Payslip configuration (right side)"
        onClose={handleCloseRightSelectionDrawer}
      />
    </Card>
  )
}
