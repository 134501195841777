import { setReportTemplate, setReportTemplatesLoading } from '../reducers'
import { ThunkResult } from '~/types/store'
import { BasePath, DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'
import { ReportCode } from '../types'
import { apiGetReportTemplate } from '../api/report-template.api'

export const fetchReportTemplate = (
  basePath: BasePath,
  reportCode: ReportCode,
  templateId: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!reportCode || !templateId) return

    const loading = getState().report.reportTemplatesLoading[reportCode]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().report.reportTemplates[reportCode]?.entities[templateId]
      if (hasData) return
    }

    try {
      dispatch(setReportTemplatesLoading({ reportCode, loading: true }))
      const { result, status } = await apiGetReportTemplate(basePath, reportCode, templateId)
      if (status) {
        dispatch(setReportTemplate({ reportCode, data: result }))
      }
    } finally {
      dispatch(setReportTemplatesLoading({ reportCode, loading: false }))
    }
  }
}
