import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Calendar, Form, Tooltip } from '~/core-components'
import { DocumentTitle, Col, PersonAvatar, Row } from '~/components'
import { StoreState } from '~/types/store'
import { LeaveTypeNameComponent } from '~/features/leave'
import { useMyLogin } from '~/features/iam'
import { getBaseUrl } from '~/utils'
import { MyCalendarLeaveRecords } from './components/MyCalendarLeaveRecords/MyCalendarLeaveRecords'
import { useMyCalendarLeaveEvents } from '../../hooks/leave/use-my-calendar-leave-events'
import { SSCalViewAsKeyValues } from '~/components/KeyValues/SSCalViewAsKeyValues'
import './MyCalendar.less'

interface MyCalendarProps {}

interface RecordState {
  startDate: string
  endDate: string
}

const DEFAULT_RECORD_STATE: RecordState = { startDate: '', endDate: '' }
const baseUrl = getBaseUrl('/filestore')

export const MyCalendar: FC<MyCalendarProps> = () => {
  const [startDate, setStartDate] = useState<string>('')
  const [endDate, setEndDate] = useState<string>('')
  const [viewAs, setViewAs] = useState<string>('')
  const viewAsIds = useSelector((state: StoreState) => state.my.myCalViewAs.ids)
  const [data] = useMyCalendarLeaveEvents(viewAs, startDate, endDate)
  const [recordState, setRecordState] = useState<RecordState>(DEFAULT_RECORD_STATE)
  const [myLogin] = useMyLogin()
  const tz = myLogin.timeZoneId

  useEffect(() => {
    setViewAs(viewAs => {
      if (!viewAs) {
        if (viewAsIds && viewAsIds.length > 0) {
          return viewAsIds[0].toString()
        }
      }
      return viewAs
    })
  }, [viewAsIds])

  const handleEvents = useCallback(rangeInfo => {
    const start = moment(rangeInfo.view.currentStart).format('YYYY-MM-DD')
    const end = moment(rangeInfo.view.currentEnd).subtract(1, 'day').format('YYYY-MM-DD')
    setStartDate(start)
    setEndDate(end)

    setRecordState({
      startDate: start,
      endDate: end
    })
  }, [])

  const handleEventContent = useCallback(ev => {
    return (
      <Tooltip title={ev.event.title}>
        <div className="fc-event-title fc-sticky">
          <PersonAvatar
            photo={
              ev.event.extendedProps.photoId &&
              `${baseUrl}/publicfile/${ev.event.extendedProps.photoId}/thumbnailphoto/20`
            }
            size={20}
          />
          <LeaveTypeNameComponent
            style={{ marginLeft: 5 }}
            name={ev.event.title}
            color={ev.event.extendedProps.leaveTypeColor}
          />
        </div>
      </Tooltip>
    )
  }, [])

  const handleEventTransform = useCallback(ev => {
    const evCopy = { ...ev }
    evCopy.end = moment(evCopy.end).add(1, 'day').format()
    return evCopy
  }, [])

  const handleSelect = useCallback(ev => {
    setRecordState({
      startDate: moment(ev.startStr).format('YYYY-MM-DD'),
      endDate: moment(ev.endStr).subtract(1, 'day').format('YYYY-MM-DD')
    })
  }, [])

  const handleSelectLeaveRecord = useCallback(lr => {
    setRecordState({
      startDate: moment(lr.event.startStr).format('YYYY-MM-DD'),
      endDate: moment(lr.event.endStr).subtract(1, 'day').format('YYYY-MM-DD')
    })
  }, [])

  if (viewAsIds.length < 1) return null

  return (
    <div className="my-calendar">
      <DocumentTitle title="Calendar" />
      <Row gutter={24}>
        <Col flex="1"></Col>
        <Col>
          <Form.Item label="View as">
            <SSCalViewAsKeyValues value={viewAs} onChange={setViewAs} size="small" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col flex="1">
          <Calendar
            timeZone={tz}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: ''
            }}
            initialView="dayGridMonth"
            selectable={true}
            showNonCurrentDates={false}
            datesSet={handleEvents}
            events={data}
            eventContent={handleEventContent}
            select={handleSelect}
            unselectAuto={false}
            eventClick={handleSelectLeaveRecord}
            eventDataTransform={handleEventTransform}
            eventDisplay="block"
            contentHeight="75vh"
          ></Calendar>
        </Col>
        <Col flex="520px">
          <MyCalendarLeaveRecords viewAs={viewAs} {...recordState} />
        </Col>
      </Row>
    </div>
  )
}
