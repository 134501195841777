import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { CompanyState } from '../types'

interface CompanyOverview extends CompanyState {
  offices: { officeId?: string; name?: string; isMain?: boolean }[]
  activeEmployees: number
}

const EMPTY_COMPANY_EXTRA = { activeEmployees: 0 }

export const selectCompaniesOverview = createSelector(
  (state: StoreState) => state.master.companies,
  (state: StoreState) => state.master.companiesExtra,
  (state: StoreState) => state.master.offices,
  (state: StoreState) => state.master.companyOffices,
  (companiesState, companiesExtraState, officesState, companyOfficesState) =>
    memoize((excludeOfficeId?: string): CompanyOverview[] => {
      let companies = Object.values(companiesState.entities)

      if (companyOfficesState?.entities) {
        const companyOffices = Object.values(companyOfficesState.entities)
        const offices = officesState?.entities

        if (excludeOfficeId) {
          companies = companies.filter(
            c => !companyOffices.filter(co => co?.companyId === c?.id).some(co => co?.officeId === excludeOfficeId)
          )
        }

        return companies
          .map(company => ({
            ...(company as CompanyState),
            ...(companiesExtraState.entities[company!.id] || EMPTY_COMPANY_EXTRA),
            offices: companyOffices
              .filter(co => co?.companyId === company?.id)
              .map(co => ({
                officeId: co?.officeId,
                name: offices && co?.officeId && offices[co.officeId]?.name,
                isMain: co?.isMain
              }))
          }))
          .sort((a, b) => a!.name.localeCompare(b!.name))
      } else {
        return companies
          .map(company => ({
            ...(company as CompanyState),
            ...(companiesExtraState.entities[company!.id] || EMPTY_COMPANY_EXTRA),
            offices: []
          }))
          .sort((a, b) => a!.name.localeCompare(b!.name))
      }
    })
)
