import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { CalendarViewerCategory, CalendarViewerState } from '../types'

export const selectCalendarViewers = createSelector(
  (state: StoreState) => state.calendarViewer.calendarViewers,
  calendarViewersState =>
    memoize((category: CalendarViewerCategory): CalendarViewerState[] => {
      const calendarViewers = Object.values(calendarViewersState[category]?.entities || {}) as CalendarViewerState[]
      return calendarViewers
    })
)
