import { CSSProperties, FC, useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment-timezone'
import classNames from 'classnames'
import { LoadingOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { AlignType, ColumnsType, Link, Space, Table, Tag, Tooltip } from '~/core-components'
import { Col, Person, Row } from '~/components'
import { Screen, useViewSchema } from '~/features/selection'
import { usePermissionGate } from '~/features/iam'
import { StoreState } from '~/types/store'
import { dispatch } from '~/stores/store'
import { ATT_ROUTES, EMP_ROUTES } from '~/routes/routes'
import { Permission, PermissionAction } from '~/constants'
import { formatYearMonth, formatMonth, formatMoney, getBaseUrl } from '~/utils'
import { selectDailyFields, selectDailyRecordsView } from '../../../selectors'
import { fetchDailyRecordsView, lockDailyRecord, unlockDailyRecord } from '../../../actions'
import { DailyRecordRowState } from '../../../types'
import './DailyRecordsTable.less'

interface DailyRecordsTableProps {
  viewId?: string
  startDate: string | null
  endDate: string | null
  search?: string
  page: number
  onPageChange: (page: number) => void
}

const nameColWidth = 250
const dateTimeColWidth = 280
const defaultColWidth = 150
const paginationStyle: CSSProperties = { marginRight: 20 }

const SCREEN_CODE: Screen = 'daily_record'
const PAGE_SIZE_OPTIONS = ['20', '50', '100']
const baseUrl = getBaseUrl('/filestore')

const START_DATE = moment().startOf('isoWeek').format('YYYY-MM-DD')
const END_DATE = moment().endOf('isoWeek').format('YYYY-MM-DD')

export const DailyRecordsTable: FC<DailyRecordsTableProps> = ({
  viewId,
  startDate = START_DATE,
  endDate = END_DATE,
  search,
  page,
  onPageChange
}) => {
  const [pageSize, setPageSize] = useState<number>(20)
  const history = useHistory()

  const selection = useSelector((state: StoreState) => state.selection.sysSelectionFields[SCREEN_CODE])
  const [schema] = useViewSchema(SCREEN_CODE, viewId)

  const data = useSelector(selectDailyRecordsView)(viewId || '')
  const refetch = useSelector((state: StoreState) => state.attendance.dailyRecordsViewRefetch)
  const dailyFields = useSelector(selectDailyFields)

  const viewLoading = useSelector((state: StoreState) => state.selection.viewLoading[SCREEN_CODE]?.[viewId || ''])
  const dataLoading = useSelector((state: StoreState) => state.attendance.dailyRecordsViewLoading)
  const loading = dataLoading || viewLoading
  const processing = useSelector((state: StoreState) => state.attendance.dailyProcessing)

  const [locking, setLocking] = useState<string>()
  const canModify = usePermissionGate(Permission.attDailyRecord, PermissionAction.Modify)
  const canViewEmployee = usePermissionGate(Permission.employee)

  useEffect(() => {
    if (viewId && !processing) {
      dispatch(
        fetchDailyRecordsView(viewId, { offset: pageSize * (page - 1), limit: pageSize }, search, startDate, endDate)
      )
    }
  }, [viewId, page, pageSize, search, startDate, endDate, processing, refetch])

  const handleOpenDailyRecord = useCallback(
    (clockDate: string, employeeId: string, shiftId: string) => {
      history.push(
        ATT_ROUTES.dailyRecord
          .replace(':clockDate', clockDate)
          .replace(':employeeId', employeeId)
          .replace(':shiftId?', shiftId || '')
      )
    },
    [history]
  )

  const handleLock = useCallback(async (dailyRecord: DailyRecordRowState) => {
    if (dailyRecord) {
      setLocking(dailyRecord.id)
      try {
        if (dailyRecord.lockedBy) {
          await dispatch(unlockDailyRecord(dailyRecord.id))
        } else {
          await dispatch(lockDailyRecord(dailyRecord.id))
        }
      } finally {
        setLocking(undefined)
      }
    }
  }, [])

  const tableWidth = useMemo(() => {
    return (
      (schema?.selection
        .filter(f => selection?.ids.includes(f.selectionFieldId))
        .reduce((sum, f) => (sum += selection?.entities[f.selectionFieldId]?.width || defaultColWidth), 0) || 0) +
      nameColWidth +
      dateTimeColWidth
    )
  }, [schema, selection])

  const columns = useMemo(() => {
    let columns: ColumnsType<DailyRecordRowState> = [
      {
        title: 'Name',
        key: 'employeeId',
        dataIndex: 'employeeId',
        fixed: 'left',
        className: 'first-col',
        width: nameColWidth,
        onCell: (record, index) => {
          const prevId = data.data[(index as number) - 1]?.employeeId
          if (prevId === record.employeeId) {
            return { rowSpan: 0 }
          } else {
            const nextId = data.data[(index as number) + 1]?.employeeId
            if (nextId === record.employeeId) {
              return { rowSpan: record.rowSpan }
            }
          }
          return {}
        },
        render: (_, record) => (
          <Person
            name={record.employeeName}
            description={record.description}
            photo={record.photoId && `${baseUrl}/file/${record.photoId}/thumbnailphoto/36`}
            path={canViewEmployee ? EMP_ROUTES.employee.replace(':id', record.employeeId) : undefined}
            size={36}
          />
        )
      },
      {
        title: 'Date time',
        key: 'clockDate',
        dataIndex: 'clockDate',
        fixed: 'left',
        width: dateTimeColWidth,
        render: (value: string, record) => {
          let startTime: moment.Moment | undefined = undefined,
            endTime: moment.Moment | undefined = undefined,
            isPrevDayInTime = false,
            isNextDayInTime = false,
            isPrevDayOutTime = false,
            isNextDayOutTime = false

          if (record.startTime) {
            startTime = moment(record.startTime)
            isPrevDayInTime = startTime.isBefore(record.clockDate, 'date')
            isNextDayInTime = startTime.isAfter(record.clockDate, 'date')
          }

          if (record.endTime) {
            endTime = moment(record.endTime)
            isPrevDayOutTime = endTime.isBefore(record.clockDate, 'date')
            isNextDayOutTime = endTime.isAfter(record.clockDate, 'date')
          }

          return (
            <Link
              onClick={() =>
                handleOpenDailyRecord(moment(record.clockDate).format('YYYY-MM-DD'), record.employeeId, record.shiftId)
              }
            >
              <Row>
                <Col flex="130px">{value ? moment(value).format('DD MMM YYYY (ddd)') : ''}</Col>
                <Col flex="1">
                  <Space size={4}>
                    {isPrevDayInTime && (
                      <Tooltip title={startTime?.format('DD MMM YYYY')}>
                        <i className="fal fa-calendar-minus" />
                      </Tooltip>
                    )}
                    {isNextDayInTime && (
                      <Tooltip title={startTime?.format('DD MMM YYYY')}>
                        <i className="fal fa-calendar-plus" />
                      </Tooltip>
                    )}
                    {startTime?.format('HH:mm')}
                    <>-</>
                    {isPrevDayOutTime && (
                      <Tooltip title={endTime?.format('DD MMM YYYY')}>
                        <i className="fal fa-calendar-minus" />
                      </Tooltip>
                    )}
                    {isNextDayOutTime && (
                      <Tooltip title={endTime?.format('DD MMM YYYY')}>
                        <i className="fal fa-calendar-plus" />
                      </Tooltip>
                    )}
                    {endTime?.format('HH:mm')}
                  </Space>
                </Col>
              </Row>
            </Link>
          )
        }
      }
    ]

    if (canModify) {
      columns.push({
        key: 'action',
        width: 20,
        className: 'action-cell',
        render: (value: string, record: DailyRecordRowState) => (
          <Tooltip title={!!record.lockedBy ? 'Locked' : 'Unlocked'}>
            <Link
              className={classNames('daily-records-table__lock', {
                'daily-records-table__lock--locked': !!record.lockedBy
              })}
              onClick={() => handleLock(record)}
            >
              {locking === record.id ? (
                <LoadingOutlined />
              ) : !!record.lockedBy ? (
                <i className="fal fa-lock" />
              ) : (
                <i className="fal fa-lock-open" />
              )}
            </Link>
          </Tooltip>
        )
      })
    }

    if (schema) {
      // Configurable employee columns
      columns.push(
        ...schema?.selection
          .filter(f => selection?.ids.includes(f.selectionFieldId))
          .map(f => {
            const field = selection?.entities[f.selectionFieldId]
            const align = field?.format === 'money' ? 'right' : ('left' as AlignType)

            if (field?.fieldName === 'dailyLeaveTypes') {
              return {
                title: field.description,
                key: field.fieldName,
                dataIndex: field.fieldName,
                width: field.width || defaultColWidth,
                align,
                render: (value: string) => {
                  const obj = JSON.parse(value || '{}') as {
                    full_leave: string
                    h1_leave: string
                    h2_leave: string
                    hour_leave: string
                  }
                  return (
                    <Space>
                      {obj.full_leave && <Tag>{obj.full_leave}</Tag>}
                      {obj.h1_leave && <Tag>{obj.h1_leave}</Tag>}
                      {obj.h2_leave && <Tag>{obj.h2_leave}</Tag>}
                      {obj.hour_leave && <Tag>{obj.hour_leave}</Tag>}
                    </Space>
                  )
                }
              }
            } else {
              return {
                title: field?.description,
                key: field?.fieldName,
                dataIndex: field?.fieldName,
                width: field?.width || defaultColWidth,
                align,
                onCell: (record: DailyRecordRowState, index?: number) => {
                  if (dailyFields.includes(field?.fieldName || '')) {
                    return {}
                  }

                  const prevId = data.data[(index as number) - 1]?.employeeId
                  if (prevId === record.employeeId) {
                    return { rowSpan: 0 }
                  } else {
                    const nextId = data.data[(index as number) + 1]?.employeeId
                    if (nextId === record.employeeId) {
                      return { rowSpan: record.rowSpan }
                    }
                  }
                  return {}
                },
                render: (value: string | number | boolean) => {
                  var display = value
                  if (field?.format === 'date' && value) {
                    display = moment(value as string).format('DD MMM YYYY')
                  }
                  if (field?.format === 'yearmonth' && value) {
                    display = formatYearMonth(value as string)
                  }
                  if (field?.format === 'month' && value) {
                    display = formatMonth(value as number)
                  }
                  if (field?.format === 'money' && value) {
                    display = formatMoney(value as number)
                  }
                  if (field?.format === 'yes_no') {
                    display = (value as boolean) ? 'Yes' : 'No'
                  }

                  return display
                }
              }
            }
          })
      )
    }

    return columns
  }, [
    schema,
    canViewEmployee,
    dailyFields,
    selection,
    canModify,
    locking,
    data.data,
    handleLock,
    handleOpenDailyRecord
  ])

  const handlePaginationChange = useCallback(
    (page: number, pageSize?: number) => {
      onPageChange(page)
      setPageSize(pageSize || 20)
    },
    [onPageChange]
  )

  return (
    <Table
      rowKey="id"
      className="daily-records-table"
      dataSource={data?.data}
      columns={columns}
      fitParent
      loading={loading}
      scroll={{ x: tableWidth, y: 1000 }}
      pagination={{
        total: data?.count,
        current: page,
        pageSize,
        pageSizeOptions: PAGE_SIZE_OPTIONS,
        showSizeChanger: true,
        onChange: handlePaginationChange,
        style: paginationStyle
      }}
    />
  )
}
