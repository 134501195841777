import React, { ChangeEvent, CSSProperties, FC, useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment-timezone'
import { useHistory, useRouteMatch } from 'react-router'
import { useSelector } from 'react-redux'
import confirm from 'antd/lib/modal/confirm'
import { Col, DocumentTitle, EmSelect, InfoTooltip, NewTabLinkIcon, Row, SysOptions } from '~/components'
import { OedPremiseType, Permission, PermissionAction, YtdSubmissionStatus } from '~/constants'
import { Button, Form, Input, PageHeader, Skeleton, Space } from '~/core-components'
import { usePermissionGate } from '~/features/iam'
import { EMP_ROUTES, REPORTS_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { ActionResult, StoreState } from '~/types/store'
import { useOedSubmission, useOedRecord } from '~/features/report/hooks'
import { OedRecordFormState } from '~/features/report/types'
import { apiGetOedRecordEmSelect, apiGetOedRecordOfficeInfo } from '~/features/report/api/rpt-oed-record.api'
import { OedRecordFormAlert } from './OedRecordFormAlert'
import { formatMoney } from '~/utils'
import { updateOedRecordForm, deleteOedRecordForm } from '~/features/report/actions'
import { removeOedRecordForm } from '~/features/report/reducers'
import { OedTags } from '../OedTags'
import { IncludeSwitcher } from './IncludeSwitcher'
import './OedRecordForm.less'

export interface OedRecordFormProps {}

interface OedRecordFormParams {
  id: string
  recordId: string
}

export const EMPTY_FORM_DATA: OedRecordFormState = {
  id: '',
  oedSubmissionId: '',
  employeeId: '',
  companyUen: '',
  companyName: '',
  fullName: '',
  identityType: '',
  identityNo: '',
  hireDate: '',
  employmentEndDate: '',
  highestEducation: '',
  jobTitle: '',
  department: '',
  whPerWeek: 0,
  unpaidLeaveTaken: 0,
  paidLeaveTaken: 0,
  totalWorkDays: 0,
  actualWorkDays: 0,
  paymentMode: '',
  basicWage: 0,
  grossWage: 0,
  annualLeaveEntitlement: 0,
  status: '',
  isInclude: true,
  pwmJobLevel: '',
  jobDuties: '',
  jobRole: '',
  seniority: '',
  businessArea: '',
  geoCoverage: '',
  isHr: true,
  hrPrimaryFunction: '',
  hrSecondaryFunction: '',
  hrJobLevel: '',
  occupationGroup: '',
  employeeType: '',
  fullOrPartTime: '',
  paidOtHours: 0,
  premiseType: '',
  postalCode: '',
  streetName: '',
  terminationReason: ''
}

const tooltipOverlayStyle: CSSProperties = { whiteSpace: 'pre-line', maxWidth: 400 }

export const OedRecordForm: FC<OedRecordFormProps> = () => {
  const match = useRouteMatch<OedRecordFormParams>()
  const oedSubmissionId = match.params.id
  const recordId = match.params.recordId

  const [oedSubmission] = useOedSubmission(oedSubmissionId)
  const title = oedSubmission
    ? `OED Report ${moment(oedSubmission.period + '-01').format('YYYY MMMM')} - ${oedSubmission.companyName} (${
        oedSubmission.companyUen
      })`
    : 'OED Report'

  const history = useHistory()
  const [formData, setFormData] = useState<OedRecordFormState>(EMPTY_FORM_DATA)
  const [oedRecordForm, loading] = useOedRecord(recordId)
  const updating = useSelector((state: StoreState) => state.report.oedRecordFormUpdating)
  const [deleting, setDeleting] = useState<boolean>(false)
  const canModify = usePermissionGate(Permission.rptMomOed, PermissionAction.Modify)
  const readOnly = !canModify || oedSubmission?.status === YtdSubmissionStatus.submitted
  const canViewEmployee = usePermissionGate(Permission.employee)

  const routes = useMemo(
    () => [
      {
        path: REPORTS_ROUTES.oedSubmissions,
        breadcrumbName: 'Overview'
      },
      {
        path: REPORTS_ROUTES.oedSubmission.replace(':id', oedSubmissionId).replace(':tab?', 'record'),
        breadcrumbName: title
      },
      {
        path: '',
        breadcrumbName: 'Record'
      }
    ],
    [oedSubmissionId, title]
  )

  useEffect(() => {
    if (oedRecordForm) {
      setFormData(oedRecordForm)
    }
  }, [oedRecordForm])

  const handleFetchEmployees = useCallback(async () => {
    const { status, result } = await apiGetOedRecordEmSelect(oedSubmissionId)
    if (status) {
      return result
    }
    return []
  }, [oedSubmissionId])

  const navigateTo = useCallback(
    (oedSubmissionId: string, id: string) => {
      history.replace(`${REPORTS_ROUTES.oedSubmissionRecord.replace(':id', oedSubmissionId).replace(':recordId', id)}`)
    },
    [history]
  )

  const handleEmployeeChange = useCallback(
    async (id: string) => {
      navigateTo(oedSubmissionId, id)
    },
    [navigateTo, oedSubmissionId]
  )

  const handleGetOfficeInfo = useCallback(
    async (employeeId: string) => {
      const { status, result } = await apiGetOedRecordOfficeInfo(oedSubmissionId, employeeId)
      if (status) {
        return result
      }
      return undefined
    },
    [oedSubmissionId]
  )

  const handleFormDataChange = useCallback(
    async (updates: { [field: string]: any }) => {
      if (updates.premiseType !== undefined) {
        if (updates.premiseType === OedPremiseType.headquarters || updates.premiseType === OedPremiseType.branch) {
          const office = await handleGetOfficeInfo(updates.employeeId)
          updates.postalCode = office?.postalCode
          updates.streetName = office?.streetName
        } else if (
          updates.premiseType === OedPremiseType.mobile ||
          updates.premiseType === OedPremiseType.workFromHome ||
          updates.premiseType === OedPremiseType.stationedOverseas
        ) {
          updates.postalCode = 'NIL'
          updates.streetName = 'NIL'
        } else {
          updates.postalCode = ''
          updates.streetName = ''
        }
      }

      if (updates.isHr !== undefined && !updates.isHr) {
        updates.hrPrimaryFunction = ''
        updates.hrSecondaryFunction = ''
        updates.hrJobLevel = ''
      }

      setFormData(formData => ({ ...formData, ...updates }))
    },
    [handleGetOfficeInfo]
  )

  const handleSubmitForm = useCallback(async () => {
    await dispatch(updateOedRecordForm(formData))
  }, [formData])

  const handleDeleteForm = useCallback(async () => {
    confirm({
      title: 'Delete',
      content: 'Do you want to delete this record?',
      onOk: async () => {
        setDeleting(true)
        const result: ActionResult | undefined = await dispatch(deleteOedRecordForm(recordId))

        if (!result?.errors) {
          dispatch(removeOedRecordForm(recordId))
          history.push(`${REPORTS_ROUTES.oedSubmission.replace(':id', oedSubmissionId).replace(':tab?', 'record')}`)
          setDeleting(false)
        }
      },
      width: 550,
      okText: 'Delete',
      okType: 'danger'
    })
  }, [history, oedSubmissionId, recordId])

  const handleResetForm = useCallback(() => {
    confirm({
      title: 'Reset form',
      content: `Do you want to reset the form?`,
      onOk: () => {
        if (oedRecordForm) {
          setFormData(oedRecordForm)
        }
      },
      okText: 'Reset',
      okType: 'danger'
    })
  }, [oedRecordForm])

  if (loading)
    return (
      <div style={{ padding: 20 }}>
        <Skeleton active />
      </div>
    )

  return (
    <div className="oed-record-form">
      <DocumentTitle title={title} />
      <PageHeader
        title={
          <Space align="center">
            <span>{title}</span>
            <OedTags oedSubmission={oedSubmission} />
          </Space>
        }
        breadcrumb={{ routes }}
        extra={<IncludeSwitcher id={recordId} isInclude={formData.isInclude} />}
      />
      <div className="oed-record-form__header">
        <Space align="center">
          <NewTabLinkIcon
            path={EMP_ROUTES.employee.replace(':id', formData.employeeId)}
            tooltipText="Open employee in a new tab"
            hidden={!canViewEmployee}
          >
            <EmSelect
              defaultValue={recordId}
              size="large"
              onFetch={handleFetchEmployees}
              onChange={handleEmployeeChange}
            />
          </NewTabLinkIcon>
        </Space>

        <Space className="oed-record-form__action-bar-buttons">
          {!readOnly && (
            <>
              <Button onClick={handleResetForm}>Cancel</Button>
              <Button type="primary" onClick={handleSubmitForm} loading={updating}>
                Save changes
              </Button>
              <Button onClick={handleDeleteForm} loading={deleting}>
                Delete
              </Button>
            </>
          )}
        </Space>
      </div>
      <div className="oed-record-form__body">
        <div style={{ marginBottom: 15, textAlign: 'right' }}>
          <span className="alert-sign">*</span> Mandatory fields to be considered valid
        </div>
        <table className="oed-record-form__table">
          <tbody>
            <tr>
              <td>
                <div>
                  Full name (as per NRIC/FIN)<span className="alert-sign">*</span>
                </div>
                <div className="fw-bold">{formData.fullName}</div>
              </td>
              <td>
                <div>
                  ID type
                  <InfoTooltip
                    title={`For Residents - select 'SC' (Singapore Citizen) or 'PR' (Permanent Resident)

                      For Foreigners - indicate type of work pass`}
                    overlayStyle={tooltipOverlayStyle}
                  />
                </div>
                <Row>
                  <Col flex="auto">
                    <div className="fw-bold">{formData.identityType}</div>
                  </Col>
                  <Col flex="none">
                    {!formData.identityType && (
                      <OedRecordFormAlert title="Please update ID type in People info > Identity type" />
                    )}
                  </Col>
                </Row>
              </td>
              <td>
                <div>
                  Identification number<span className="alert-sign">*</span>
                  <InfoTooltip
                    title={`For Residents (Singapore Citizen and Permanent Resident) employees, please provide their NRIC number in FULL.

                      For Foreign Employment Pass / S Pass holders / Work Permit holders, please provide FIN number in FULL.`}
                    overlayStyle={tooltipOverlayStyle}
                  />
                </div>
                <div className="fw-bold">{formData.identityNo}</div>
              </td>
            </tr>
            <tr>
              <td>
                <div>Highest education attained</div>
                <Row>
                  <Col flex="auto">
                    <div className="fw-bold">{formData.highestEducation}</div>
                  </Col>
                  <Col flex="none">
                    {!formData.highestEducation && (
                      <OedRecordFormAlert title="Please indicate highest education in People info > Education" />
                    )}
                  </Col>
                </Row>
              </td>
              <td>
                <div>Date joined (Hire date)</div>
                <div className="fw-bold">{formData.hireDate && moment(formData.hireDate).format('DD MMM YYYY')}</div>
              </td>
              <td>
                <div>Date left</div>
                <div className="fw-bold">
                  {formData.employmentEndDate && moment(formData.employmentEndDate).format('DD MMM YYYY')}
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <Form>
          <div className="oed-record-form__subtitle">Job details</div>
          <Row gutter={15}>
            <Col span={12}>
              <Form.Item
                label={
                  <>
                    PWM job level
                    <span className="alert-sign">*</span>
                    <InfoTooltip
                      title={`Refers to job role covered by the respective Progressive Wage Models (PWMs).

                        For workers covered under the PWMs, you will need to select a PWM Job Level from the dropdown list.`}
                      overlayStyle={tooltipOverlayStyle}
                    />
                  </>
                }
              >
                <SysOptions
                  type="oed_pwm_job_level"
                  readOnly={readOnly}
                  value={formData.pwmJobLevel}
                  onChange={(pwmJobLevel?: string) => handleFormDataChange({ pwmJobLevel })}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <>
                    Main job title
                    <span className="alert-sign">*</span>
                    {!formData.jobTitle && (
                      <OedRecordFormAlert title="Please update job title in People info > Employment details" />
                    )}
                  </>
                }
              >
                <span className="fw-bold">{formData.jobTitle}</span>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Main job duties">
                <Input.TextArea
                  rows={3}
                  value={formData.jobDuties}
                  maxLength={35000}
                  onChange={(value?: ChangeEvent<HTMLTextAreaElement>) =>
                    handleFormDataChange({ jobDuties: value?.target.value })
                  }
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Job roles">
                <SysOptions
                  type="oed_job_role"
                  readOnly={readOnly}
                  value={formData.jobRole}
                  onChange={(jobRole?: string) => handleFormDataChange({ jobRole })}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Seniority">
                <SysOptions
                  type="oed_seniority"
                  readOnly={readOnly}
                  value={formData.seniority}
                  onChange={(seniority?: string) => handleFormDataChange({ seniority })}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Business area">
                <SysOptions
                  type="oed_business_area"
                  readOnly={readOnly}
                  value={formData.businessArea}
                  onChange={(businessArea?: string) => handleFormDataChange({ businessArea })}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Geographical coverage">
                <SysOptions
                  type="oed_geo_coverage"
                  readOnly={readOnly}
                  value={formData.geoCoverage}
                  onChange={(geoCoverage?: string) => handleFormDataChange({ geoCoverage })}
                />
              </Form.Item>
            </Col>
            {/* <Col span={8}>
              <Form.Item label="Employee performs HR functions/roles?">
                <Radio.Group
                  style={{ marginLeft: 10 }}
                  value={formData.isHr}
                  readOnly={readOnly}
                  onChange={(event: RadioChangeEvent) => {
                    handleFormDataChange({ isHr: event.target.value })
                  }}
                >
                  <Space direction="horizontal">
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Primary HR function">
                <SysOptions
                  type="oed_hr_function"
                  readOnly={readOnly || !formData.isHr}
                  value={formData.hrPrimaryFunction}
                  onChange={(hrPrimaryFunction?: string) => handleFormDataChange({ hrPrimaryFunction })}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Secondary HR function (if applicable)">
                <SysOptions
                  type="oed_hr_function"
                  readOnly={readOnly || !formData.isHr}
                  value={formData.hrSecondaryFunction}
                  onChange={(hrSecondaryFunction?: string) => handleFormDataChange({ hrSecondaryFunction })}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="HR job level held">
                <SysOptions
                  type="oed_hr_job_level"
                  readOnly={readOnly || !formData.isHr}
                  value={formData.hrJobLevel}
                  onChange={(hrJobLevel?: string) => handleFormDataChange({ hrJobLevel })}
                />
              </Form.Item>
            </Col> */}
            <Col span={8}>
              <Form.Item label="Occupation group">
                <SysOptions
                  type="oed_occupation_group"
                  readOnly={readOnly}
                  value={formData.occupationGroup}
                  onChange={(occupationGroup?: string) => handleFormDataChange({ occupationGroup })}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Full-time or Part-time">
                <SysOptions
                  type="oed_full_or_part_time"
                  readOnly={readOnly}
                  value={formData.fullOrPartTime}
                  onChange={(fullOrPartTime?: string) => handleFormDataChange({ fullOrPartTime })}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Type of employee">
                <SysOptions
                  type="oed_employee_type"
                  readOnly={readOnly}
                  value={formData.employeeType}
                  onChange={(employeeType?: string) => handleFormDataChange({ employeeType })}
                />
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                label={
                  <>
                    Department
                    {!formData.department && (
                      <OedRecordFormAlert title="Please update department in People info > Employment details" />
                    )}
                  </>
                }
              >
                <span className="fw-bold">{formData.department}</span>
              </Form.Item>
            </Col>
          </Row>
          <div className="oed-record-form__subtitle">Hours worked</div>
          <Row gutter={15}>
            <Col span={8}>
              <Form.Item
                label={
                  <>
                    Contractual working hours (Per Week)
                    <span className="alert-sign">*</span>
                    {!formData.whPerWeek && (
                      <OedRecordFormAlert title="Please update working hours in People info > Work calendar" />
                    )}
                  </>
                }
              >
                <span className="fw-bold">{formData.whPerWeek}</span>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={
                  <>
                    Total paid OT hours
                    <InfoTooltip
                      title={`This refers to the number of paid hours spent working overtime in the given month, and these hours are used for the official computation of an employee's overtime pay

                        All forms of paid overtime hours of work should be included.`}
                      overlayStyle={tooltipOverlayStyle}
                    />
                  </>
                }
              >
                <Input.Number
                  style={{ width: '100%' }}
                  readOnly={readOnly}
                  value={formData.paidOtHours}
                  onChange={(paidOtHours: number | null) => handleFormDataChange({ paidOtHours })}
                />
                <div className="info-text">Exclude: unpaid overtime hours</div>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="No. of days of unpaid leave taken">
                <span className="fw-bold">{formData.unpaidLeaveTaken}</span>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="No. of days of paid leave taken">
                <span className="fw-bold">{formData.paidLeaveTaken}</span>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={
                  <>
                    Total number of working days in the month
                    {!formData.totalWorkDays && (
                      <OedRecordFormAlert title="Please update total working days in People info > Work calendar" />
                    )}
                  </>
                }
              >
                <span className="fw-bold">{formData.totalWorkDays}</span>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={
                  <>
                    Actual number of days/hours worked in the month
                    {!formData.actualWorkDays && (
                      <OedRecordFormAlert title="Please update total working days in People info > Work calendar and ensure that the leave balance is updated" />
                    )}
                  </>
                }
              >
                <span className="fw-bold">{formData.actualWorkDays}</span>
              </Form.Item>
            </Col>
          </Row>
          <div className="oed-record-form__subtitle">Wages & Benefits</div>
          <Row gutter={15}>
            <Col span={8}>
              <Form.Item
                label={
                  <>
                    Mode of payment
                    {!formData.paymentMode && (
                      <OedRecordFormAlert title="Please update mode of payment in People info > Salary" />
                    )}
                  </>
                }
              >
                <span className="fw-bold">{formData.paymentMode}</span>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={
                  <>
                    Basic wage ($)
                    {!formData.basicWage && (
                      <OedRecordFormAlert title="Please update basic wage in People info > Salary" />
                    )}
                  </>
                }
              >
                <span className="fw-bold">{formatMoney(formData.basicWage, 2) || '0.00'}</span>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={
                  <>
                    Gross wage received ($)
                    {!formData.grossWage && <OedRecordFormAlert title="Please ensure the payroll is updated" />}
                  </>
                }
              >
                <span className="fw-bold">{formatMoney(formData.grossWage, 2) || '0.00'}</span>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={
                  <>
                    Annual leave entitlements
                    {!formData.annualLeaveEntitlement && (
                      <OedRecordFormAlert title="Please ensure the entitlement is set in Settings > Annual leave > Policy" />
                    )}
                  </>
                }
              >
                <span className="fw-bold">{formData.annualLeaveEntitlement}</span>
              </Form.Item>
            </Col>
          </Row>
          <div className="oed-record-form__subtitle">Work location</div>
          <Row gutter={15}>
            <Col span={8}>
              <Form.Item label="Premise type">
                <SysOptions
                  type="oed_premise_type"
                  readOnly={readOnly}
                  value={formData.premiseType}
                  onChange={(premiseType?: string) =>
                    handleFormDataChange({
                      premiseType: premiseType || '',
                      employeeId: formData.employeeId
                    })
                  }
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={
                  <>
                    Postal code
                    {(formData.premiseType === OedPremiseType.headquarters ||
                      formData.premiseType === OedPremiseType.branch) &&
                      !formData.postalCode && (
                        <OedRecordFormAlert title="Please update employee's office in People info > Employment detail > Office and/or update postal code in Settings > Company > Office" />
                      )}
                  </>
                }
              >
                <Input
                  value={formData.postalCode}
                  readOnly={readOnly || formData.premiseType !== OedPremiseType.clientPremise}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleFormDataChange({ postalCode: event.target.value })
                  }
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Street name">
                <Input
                  value={formData.streetName}
                  readOnly={readOnly || formData.premiseType !== OedPremiseType.clientPremise}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleFormDataChange({ streetName: event.target.value })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <div className="oed-record-form__subtitle">Departure detail</div>
          <Row gutter={15}>
            <Col span={8}>
              <Form.Item label="Mode of leaving (if applicable)">
                <SysOptions
                  type="oed_termination_reason"
                  readOnly={readOnly}
                  value={formData.terminationReason}
                  onChange={(terminationReason?: string) => handleFormDataChange({ terminationReason })}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  )
}
