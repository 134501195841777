import React, { Component, ReactNode } from 'react'
import { UserManager } from 'oidc-client'
import AuthService from './AuthService'

const AuthContext = React.createContext<AuthService>({
  OIDC_STORAGE_KEY: '',
  UserManager: new UserManager({}),
  signinRedirect: () => {},
  signinRedirectCallback: () => {},
  getUser: () => new Promise(resolve => resolve(undefined as any)),
  getJwtInfo: () => undefined,
  navigateToScreen: () => {},
  register: () => {},
  logout: async () => {},
  signoutRedirectCallback: () => {},
  isAuthenticated: () => false,
  getIdToken: () => undefined,
  getAccessToken: () => undefined,
  signinSilent: () => new Promise(resolve => resolve(undefined as any)),
  signinSilentCallback: () => {},
  createSigninRequest: () => new Promise(resolve => resolve(undefined as any)),
  clearLocalStorage: () => {}
})

export const AuthConsumer = AuthContext.Consumer

interface AuthProviderProps {
  authService: AuthService
  children: ReactNode
}

export class AuthProvider extends Component<AuthProviderProps> {
  render() {
    return <AuthContext.Provider value={this.props.authService}>{this.props.children}</AuthContext.Provider>
  }
}
