import { showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiValidateDailyImport } from '../api/daily-import.api'
import { replaceDailyImportValidate } from '../reducers'

export const validateDailyImport = (
  payRunId: string,
  attendancePeriodId: string,
  employeeIds: string[]
): ThunkResult<void> => {
  return async dispatch => {
    const { status, result, errors, message, errorData } = await apiValidateDailyImport(
      payRunId,
      attendancePeriodId,
      employeeIds
    )
    if (status) {
      dispatch(replaceDailyImportValidate(result))
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors, result }
  }
}
