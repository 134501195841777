import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchAttendancePeriodsForPayroll } from '../actions'
import { AttendancePeriodState } from '../types'
import { selectAttendancePeriodsForPayroll } from '../selectors'

export const useAttendancePeriodsForPayroll = (payPeriod?: string): [AttendancePeriodState[], boolean] => {
  const periods = useSelector(selectAttendancePeriodsForPayroll)
  const loading = useSelector((state: StoreState) => state.attendance.attendancePeriodsForPayrollLoading)

  useEffect(() => {
    dispatch(fetchAttendancePeriodsForPayroll(payPeriod))
  }, [payPeriod])

  return [periods, loading]
}
