import { setIr21Form, setIr21FormUpdating } from '../reducers'
import { apiUpdateIr21Draft } from '../api/ir21.api'
import { ThunkResult } from '~/types/store'
import { showSuccess, showError } from '~/utils'
import { Ir21FormState } from '../types'

export const updateIr21Draft = (request: Ir21FormState): ThunkResult<void> => {
  return async dispatch => {
    try {
      dispatch(setIr21FormUpdating(true))
      const { status, errors, message, errorData } = await apiUpdateIr21Draft(request)
      if (status) {
        dispatch(setIr21Form(request))
        showSuccess('Saved')
      } else {
        if (Object.keys(errors).length === 0) {
          showError(message, errorData)
        }
      }
      return { errors }
    } finally {
      dispatch(setIr21FormUpdating(false))
    }
  }
}
