import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { LoadingOutlined } from '@ant-design/icons'
import { StoreState } from '~/types/store'
import { Form, Spin } from '~/core-components'
import { Col, Row } from '~/components'
import { formatDateRange, formatMoney } from '~/utils'
import { selectPayTranDtls } from '../../selectors'
import './PayTranDtls.less'

export interface PayTranDtlsProps {
  payTranId: string
}

export const PayTranDtls: FC<PayTranDtlsProps> = ({ payTranId }) => {
  const payTranDtls = useSelector(selectPayTranDtls)(payTranId)
  const loading = useSelector((state: StoreState) => state.payroll.payTranDtlsLoading[payTranId])

  return (
    <>
      {loading ? (
        <Spin indicator={<LoadingOutlined spin />} />
      ) : (
        <div className="paytrandtls">
          {payTranDtls.map(dtl => (
            <Form key={dtl.id}>
              <Row className="paytrandtls__dates">
                <Col span={24}>{formatDateRange(dtl.startDate, dtl.endDate)}</Col>
              </Row>
              <Row className="paytrandtls__numbers">
                <Col span={5}>
                  <Form.Item label="Quantity">{formatMoney(dtl.quantity, 4)}</Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item label="Rate">{formatMoney(dtl.rate, 4)}</Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label="Formula">{formatMoney(dtl.formula, 4)}</Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label="Proration">{formatMoney(dtl.proration, 4)}</Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="Amount">{formatMoney(dtl.amount, 4)}</Form.Item>
                </Col>
              </Row>
            </Form>
          ))}
        </div>
      )}
    </>
  )
}
