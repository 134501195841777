import { request, getBaseUrl } from '~/utils/request'
import { TimeLogTemplateState, ISaveTimeLogTemplate } from '../types'

const baseUrl = getBaseUrl('/attendance/timelogtemplate')

export const apiGetTimeLogTemplates = () => request<TimeLogTemplateState[]>('get', baseUrl)

export const apiSaveTimeLogTemplate = (template: ISaveTimeLogTemplate) =>
  request<{ id: string }>('post', baseUrl, template)

export const apiDeleteTimeLogTemplate = (id: string) => request<void>('delete', `${baseUrl}/${id}`)
