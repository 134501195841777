import React, { FC, useState, useCallback, useEffect, ChangeEvent } from 'react'
import moment from 'moment-timezone'
import confirm from 'antd/lib/modal/confirm'
import { Checkbox, CheckboxChangeEvent, Form, Input } from '~/core-components'
import { Col, DrawerForm, Row } from '~/components'
import { dispatch } from '~/stores/store'
import { addMaster, deleteMaster, updateMaster } from '../../../actions'
import { ICostCentreInfo, CostCentreState, MasterName } from '../../../types'
import { mapCostCentreStateToCostCentreInfo } from '../../../types/master.mapper'
import { ActionResult, Errors } from '~/types/store'
import { useFocus } from '~/hooks/use-focus'
import { MasterConfigs } from '../MasterConfigs'

export interface MutateCostCentreDrawerProps {
  masterName: MasterName
  visible: boolean
  data?: CostCentreState
  onClose: () => void
}

const EMPTY_FORM_DATA: ICostCentreInfo = {
  name: '',
  code: ''
}

export const MutateCostCentreDrawer: FC<MutateCostCentreDrawerProps> = ({
  masterName,
  visible,
  data,
  onClose
}: MutateCostCentreDrawerProps) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<ICostCentreInfo>(EMPTY_FORM_DATA)
  const [focusRef, setFocus] = useFocus(true)
  const [errors, setErrors] = useState<Errors>()
  const [isInactive, setIsInactive] = useState(false)
  const configs = MasterConfigs[masterName]

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  useEffect(() => {
    if (data) {
      setFormData({ name: data.name, code: data.code, inactiveDate: data.inactiveDate })
      setIsInactive(!!data.inactiveDate)
    } else {
      setFormData(EMPTY_FORM_DATA)
      setIsInactive(false)
    }
  }, [data])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      const updated = { ...formData, ...updates }
      setFormData(updated)
      setIsInactive(!!updated.inactiveDate)
    },
    [formData]
  )

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      if (data) {
        result = await dispatch(updateMaster(masterName, data.id, mapCostCentreStateToCostCentreInfo(data), formData))
      } else {
        result = await dispatch(addMaster(masterName, formData))
      }
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_FORM_DATA)
    }
  }, [masterName, data, formData, onClose])

  const handleDelete = useCallback(
    (master: CostCentreState | undefined) => {
      if (master) {
        const { id, name } = master
        confirm({
          title: `Delete ${configs.name.toLowerCase()}`,
          content: `Do you want to delete ${configs.name.toLowerCase()} record of "${name}"?`,
          onOk: async () => {
            const result: ActionResult | undefined = await dispatch(deleteMaster(masterName, id))
            if (result?.errors) {
              setErrors(result.errors)
            }

            if (!result?.errors) {
              typeof onClose === 'function' && onClose()
            }
          },
          okText: 'Delete',
          okType: 'danger'
        })
      }
    },
    [masterName, configs.name, onClose]
  )

  return (
    <DrawerForm
      open={visible}
      title={data ? `Edit ${configs.name.toLowerCase()}` : `Add ${configs.name.toLowerCase()}`}
      onClose={onClose}
      confirmLoading={loading}
      width={500}
      showDelete={data ? true : false}
      onDelete={() => handleDelete(data)}
      formId="form-cost-centre"
    >
      <Form id="form-cost-centre" onFinish={handleOk}>
        <Row gutter={15}>
          <Col flex="auto">
            <Form.Item label="Name" validateStatus={errors?.name ? 'error' : ''} help={errors?.name}>
              <Input
                ref={focusRef}
                value={formData.name}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ name: event.target.value })}
              />
            </Form.Item>
          </Col>
          <Col flex="none">
            <Form.Item
              label="Inactive"
              validateStatus={errors?.inactiveDate ? 'error' : ''}
              help={errors?.inactiveDate}
            >
              <Checkbox
                checked={isInactive}
                onChange={(event: CheckboxChangeEvent) => {
                  setIsInactive(event.target.checked)
                  handleFormDataChange({ inactiveDate: event.target.checked ? moment().format('YYYY-MM-DD') : '' })
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Code" validateStatus={errors?.code ? 'error' : ''} help={errors?.code}>
              <Input
                value={formData.code}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ code: event.target.value })}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}
