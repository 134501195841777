import React from 'react'
import { useQuery } from '~/hooks/use-query'
import { AuthConsumer } from './AuthProvider'

export const Register = () => {
  const query = useQuery()

  return (
    <AuthConsumer>
      {({ register }) => {
        if (query.has('redirectUrl')) {
          register(query.get('redirectUrl') as string)
        }
        return null
      }}
    </AuthConsumer>
  )
}
