import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { UploadFile } from '~/core-components'
import { apiUploadCompanyLogo } from '../api/company.api'
import { CompanyState } from '../types'
import { setCompany } from '../reducers'

export const uploadCompanyLogo = (id: string, file: UploadFile): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const { status, result, errors, message, errorData } = await apiUploadCompanyLogo(id, file)
    if (status) {
      const employee = getState().master.companies.entities[id]
      dispatch(setCompany({ ...(employee as CompanyState), logoId: result?.logoId }))
      showSuccess('Logo saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors, result }
  }
}
