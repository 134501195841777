import { EntityState } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { ManagerState } from './manager.state'
import { ManagerCategory } from './dto'

export const managerPersistConfig = {
  key: 'manager',
  whitelist: [],
  storage
}

export interface ManagerRootState {
  managers: { [category in ManagerCategory]?: EntityState<ManagerState> }
  managersLoading: { [category in ManagerCategory]?: boolean }
}
