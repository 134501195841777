import React, { FC, ChangeEvent, useState, useCallback, useEffect, CSSProperties } from 'react'
import moment from 'moment-timezone'
import { Checkbox, CheckboxChangeEvent, Form, Input } from '~/core-components'
import { Col, Row, EmpKeyValues, NewTabLinkIcon } from '~/components'
import { Errors } from '~/types/store'
import { useFocus } from '~/hooks/use-focus'
import { ICompanyOfficeInfo } from '../../../types'
import { Permission, PermissionAction } from '~/constants'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { usePermissionGate } from '~/features/iam'

interface OfficeFormProps {
  usage: 'office' | 'companyoffice'
  data: ICompanyOfficeInfo
  readOnly?: boolean
  errors?: Errors
  onChange: (data: ICompanyOfficeInfo) => void
}

export const EMPTY_OFFICE_INFO_FORM_DATA: ICompanyOfficeInfo = {
  name: '',
  isMain: false,
  block: '',
  street: '',
  level: '',
  unit: '',
  building: '',
  city: '',
  state: '',
  postalCode: '',
  countryCode: '',
  phone: '',
  holidayId: ''
}

const inputLevelStyle: CSSProperties = { width: '40%', marginRight: -1 }
const inputUnitStyle: CSSProperties = { width: '60%' }

export const OfficeInfoForm: FC<OfficeFormProps> = ({
  usage,
  data,
  readOnly = false,
  errors,
  onChange
}: OfficeFormProps) => {
  const [isInactive, setIsInactive] = useState(false)
  const [formData, setFormData] = useState<ICompanyOfficeInfo>(EMPTY_OFFICE_INFO_FORM_DATA)
  const [focusRef] = useFocus(!readOnly)
  const canModify = usePermissionGate(Permission.master, PermissionAction.Modify)

  useEffect(() => {
    if (data) {
      setFormData(data)
      setIsInactive(!!data.inactiveDate)
    } else {
      setFormData(EMPTY_OFFICE_INFO_FORM_DATA)
      setIsInactive(false)
    }
  }, [data])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      const updated = { ...formData, ...updates }
      setFormData(updated)
      setIsInactive(!!updated.inactiveDate)
      typeof onChange === 'function' && onChange(updated)
    },
    [formData, onChange]
  )

  return (
    <>
      <Row gutter={15}>
        <Col flex="auto">
          <Form.Item label="Office name" validateStatus={errors?.name ? 'error' : ''} help={errors?.name}>
            <Input
              ref={focusRef}
              value={formData.name}
              readOnly={readOnly}
              onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ name: event.target.value })}
            />
          </Form.Item>
        </Col>
        <Col flex="none">
          <Form.Item label="Inactive" validateStatus={errors?.inactiveDate ? 'error' : ''} help={errors?.inactiveDate}>
            <Checkbox
              checked={isInactive}
              readOnly={readOnly}
              onChange={(event: CheckboxChangeEvent) => {
                setIsInactive(event.target.checked)
                handleFormDataChange({ inactiveDate: event.target.checked ? moment().format('YYYY-MM-DD') : '' })
              }}
            />
            {/* {isInactive && (
              <Input.Date
                value={formData.inactiveDate ? moment(formData.inactiveDate) : undefined}
                inputReadOnly={readOnly}
                onChange={(value: moment.Moment | null) =>
                  handleFormDataChange({ inactiveDate: value?.format('YYYY-MM-DD') })
                }
              />
            )} */}
          </Form.Item>
        </Col>
      </Row>
      {usage === 'companyoffice' && (
        <Row>
          <Col span={24}>
            <Form.Item>
              <Checkbox
                checked={formData.isMain}
                onChange={(event: CheckboxChangeEvent) => handleFormDataChange({ isMain: event.target.checked })}
              >
                Main Office
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row gutter={15}>
        <Col span={4}>
          <Form.Item label="Block no.">
            <Input
              value={formData.block}
              readOnly={readOnly}
              onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ block: event.target.value })}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Street name">
            <Input
              value={formData.street}
              readOnly={readOnly}
              onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ street: event.target.value })}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Floor - Unit no.">
            <Input.Group>
              <Input
                style={inputLevelStyle}
                value={formData.level}
                readOnly={readOnly}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ level: event.target.value })}
              />
              <Input
                addonBefore="-"
                style={inputUnitStyle}
                value={formData.unit}
                readOnly={readOnly}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ unit: event.target.value })}
              />
            </Input.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item label="Building">
            <Input
              value={formData.building}
              readOnly={readOnly}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleFormDataChange({ building: event.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={12}>
          <Form.Item label="City">
            <Input
              value={formData.city}
              readOnly={readOnly}
              onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ city: event.target.value })}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="State">
            <Input
              value={formData.state}
              readOnly={readOnly}
              onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ state: event.target.value })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={12}>
          <Form.Item label="Postal code">
            <Input
              value={formData.postalCode}
              readOnly={readOnly}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleFormDataChange({ postalCode: event.target.value })
              }
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Country">
            <EmpKeyValues
              id="country"
              value={formData.countryCode}
              readOnly={readOnly}
              onChange={(value: string) => handleFormDataChange({ countryCode: value })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={12}>
          <Form.Item label="Phone">
            <Input
              value={formData.phone}
              readOnly={readOnly}
              onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ phone: event.target.value })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={12}>
          <Form.Item label="Holiday">
            <NewTabLinkIcon path={SETTINGS_ROUTES.holidays} tooltipText="Open holiday in a new tab" hidden={!canModify}>
              <EmpKeyValues
                id="holiday"
                value={formData.holidayId}
                readOnly={readOnly}
                onChange={(value: string) => handleFormDataChange({ holidayId: value })}
              />
            </NewTabLinkIcon>
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}
