import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { EmSelectState } from '~/components'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchPayRecordEmployees } from '../actions'
import { selectPayRecordEmployees } from '../selectors'

export const usePayRecordEmployees = (
  payRunId: string,
  fetchStrategy: FetchStrategy = 'always'
): [EmSelectState[] | undefined, boolean] => {
  const employees = useSelector(selectPayRecordEmployees)(payRunId)
  const loading = useSelector((state: StoreState) => state.payroll.payRecordEmployeesLoading[payRunId])

  useEffect(() => {
    dispatch(fetchPayRecordEmployees(payRunId, { strategy: fetchStrategy }))
  }, [payRunId, fetchStrategy])

  return [employees, loading]
}
