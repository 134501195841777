import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { XeroTenantState } from '../types'

export const selectXeroTenants = createSelector(
  (state: StoreState) => state.payroll.xeroTenants,
  xeroTenantsState => {
    return Object.values(xeroTenantsState?.entities || {}) as XeroTenantState[]
  }
)
