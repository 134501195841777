import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { PayRunState } from '../types'
import { PayRunStatus } from '~/constants'

export const selectPayRunsRecent = createSelector(
  (state: StoreState) => state.payroll.payRuns,
  payRunsState =>
    memoize(() => {
      const payRuns = Object.values(payRunsState?.entities || {}) as PayRunState[]
      if (payRuns.length > 0) {
        const payPeriods = payRuns.map(r => r.payPeriod)
        const periods = Array.from(new Set(payPeriods)).sort().reverse()
        const chosenPeriods = periods.filter((p, index) => index <= 1) // first two months
        const recent = payRuns.filter(s => chosenPeriods.includes(s.payPeriod) || s.status !== PayRunStatus.completed)
        return recent.sort((a, b) => {
          const aPayDate = new Date(a!.payDate).getTime()
          const bPayDate = new Date(b!.payDate).getTime()
          return bPayDate - aPayDate
        })
      }
      return []
    })
)
