import React, { FC, HTMLAttributes, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Form } from '~/core-components'
import { Col, Row, SalaryInput } from '~/components'
import { selectPayItemById } from '~/features/master'
import { selectFormulaById } from '~/features/formula'
import { Errors, StoreState } from '~/types/store'
import { IPayRecurringOverrideInfo } from '../../types'

export interface PayRecurringForm extends IPayRecurringOverrideInfo {}

export const EMPTY_PAY_RECURRING_FORM_DATA: PayRecurringForm = {
  payItemId: '',
  quantity: 0,
  rate: 0
}

export interface EditPayRecurringFormProps extends HTMLAttributes<HTMLDivElement> {
  data?: PayRecurringForm
  errors?: Errors
  resetForm: number
  onFormChange: (data: PayRecurringForm) => void
}

export const EditPayRecurringForm: FC<EditPayRecurringFormProps> = ({
  data,
  errors,
  resetForm,
  onFormChange,
  style
}: EditPayRecurringFormProps) => {
  const [formData, setFormData] = useState<PayRecurringForm>(data || EMPTY_PAY_RECURRING_FORM_DATA)
  const payItem = useSelector((state: StoreState) => selectPayItemById(state, data?.payItemId || ''))
  const formula = useSelector((state: StoreState) => selectFormulaById(state, payItem?.formulaId || ''))

  useEffect(() => {
    setFormData(EMPTY_PAY_RECURRING_FORM_DATA)
  }, [resetForm])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      const updated = { ...formData, ...updates }
      setFormData(updated)
      typeof onFormChange === 'function' && onFormChange(updated)
    },
    [formData, onFormChange]
  )

  useEffect(() => {
    setFormData({
      ...EMPTY_PAY_RECURRING_FORM_DATA,
      payItemId: payItem?.id || '',
      rate: payItem?.rate || 0,
      ...data
    })
  }, [data, payItem])

  return (
    <div style={style}>
      <Row>
        <Col span={12}>
          <Form.Item label="Quantity" validateStatus={errors?.quantity ? 'error' : ''} help={errors?.quantity}>
            <SalaryInput
              precision={4}
              value={formData.quantity}
              onChange={(value: number | null) => handleFormDataChange({ quantity: value })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item label="Rate" validateStatus={errors?.rate ? 'error' : ''} help={errors?.rate}>
            <SalaryInput
              precision={4}
              value={formData.rate}
              onChange={(value: number | null) => handleFormDataChange({ rate: value })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row hidden={!formula?.name}>
        <Col span={24}>
          <Form.Item label="Formula">
            <label>{formula?.name}</label>
          </Form.Item>
        </Col>
      </Row>
    </div>
  )
}
