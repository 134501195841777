import { useEffect, useRef } from 'react'

export const usePollingEffect = (asyncCallback: () => Promise<void>, { intervalSeconds = 20 } = {}) => {
  const timeoutIdRef = useRef<any>(null)

  useEffect(() => {
    if (!timeoutIdRef) return

    let _stopped = false

    ;(async function pollingCallback() {
      try {
        await asyncCallback()
      } finally {
        timeoutIdRef.current = !_stopped && setTimeout(pollingCallback, intervalSeconds * 1000)
      }
    })()

    return () => {
      _stopped = true
      clearTimeout(timeoutIdRef.current)
    }
  }, [intervalSeconds, asyncCallback])
}
