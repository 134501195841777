import { showError, showSuccess } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiExportAllPayslips } from '../api/payslip.api'

export const exportAllPayslips = (payRunId: string): ThunkResult<void> => {
  return async () => {
    if (!payRunId) return

    const { status, errors, message, errorData } = await apiExportAllPayslips(payRunId)
    if (status) {
      showSuccess('Request sent', 'We will email your export as soon as it is ready')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
