import React, { ChangeEvent, CSSProperties, FC, HTMLAttributes, useCallback, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import confirm from 'antd/lib/modal/confirm'
import { useSelector } from 'react-redux'
import { Collapse, Form, Input, SecondaryText, Select, Skeleton } from '~/core-components'
import { Col, ErrorDisplay, InfoTooltip, Row, SalaryInput } from '~/components'
import { formatMoney } from '~/utils'
import { ActionResult, Errors, StoreState } from '~/types/store'
import { Ir21A1FormState } from '../../../types'
import { removeIr21A1Form, setIr21A1FormRefetch, setIr21FormRefetch } from '~/features/tax/reducers'
import { selectIr21A1Form } from '~/features/tax/selectors'
import { dispatch } from '~/stores/store'
import { addIr21A1Draft, deleteIr21A1Draft, fetchIr21A1Form, updateIr21A1Draft } from '~/features/tax/actions'
import { Ir21EmptyForm } from '../Ir21EmptyForm/Ir21EmptyForm'
import './Ir21A1Form.less'

interface Ir21Props extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
  ir21SubmissionId: string
  employeeTaxNo: string
  readOnly?: boolean

  // Trigger action from parent component
  triggerUpdate: number
  triggerDelete: number
  triggerReset: number
}

export const EMPTY_FORM_DATA: Ir21A1FormState = {
  id: '',
  ir21SubmissionId: '',
  employeeTaxNo: '',
  employerTaxNo: '',

  r1Address: '',
  r1OccupationStartDate: '',
  r1OccupationStartDateP: '',
  r1OccupationEndDate: '',
  r1OccupationEndDateP: '',
  r1OccupationDays: 0,
  r1OccupationDaysP: 0,
  r1PremisesAmt: 0,
  r1PremisesAmtP: 0,
  r1PremisesType: '',
  r1PremisesTypeP: '',
  r1FurnitureAmt: 0,
  r1FurnitureAmtP: 0,
  r1RentAmt: 0,
  r1RentAmtP: 0,
  r1RentEmAmt: 0,
  r1RentEmAmtP: 0,

  r2Address: '',
  r2OccupationStartDate: '',
  r2OccupationStartDateP: '',
  r2OccupationEndDate: '',
  r2OccupationEndDateP: '',
  r2OccupationDays: 0,
  r2OccupationDaysP: 0,
  r2PremisesAmt: 0,
  r2PremisesAmtP: 0,
  r2PremisesType: '',
  r2PremisesTypeP: '',
  r2FurnitureAmt: 0,
  r2FurnitureAmtP: 0,
  r2RentAmt: 0,
  r2RentAmtP: 0,
  r2RentEmAmt: 0,
  r2RentEmAmtP: 0,

  utilitiesAmt: 0,
  utilitiesAmtP: 0,
  driverAmt: 0,
  driverAmtP: 0,
  servantAmt: 0,
  servantAmtP: 0,
  hotelAmt: 0,
  hotelAmtP: 0,
  leavePassageAmt: 0,
  leavePassageAmtP: 0,
  interestAmt: 0,
  interestAmtP: 0,
  insuranceAmt: 0,
  insuranceAmtP: 0,
  holidayAmt: 0,
  holidayAmtP: 0,
  educationAmt: 0,
  educationAmtP: 0,
  clubAmt: 0,
  clubAmtP: 0,
  gainAmt: 0,
  gainAmtP: 0,
  vehicleAmt: 0,
  vehicleAmtP: 0,
  carAmt: 0,
  carAmtP: 0,
  otherAmt: 0,
  otherAmtP: 0,

  bikTotal: 0,
  bikTotalP: 0,

  // calculation field
  r1PlaceOfResidenceTaxableValue: 0,
  r1PlaceOfResidenceTaxableValueP: 0,
  r2PlaceOfResidenceTaxableValue: 0,
  r2PlaceOfResidenceTaxableValueP: 0,
  accomodationTaxableBenefit: 0,
  accomodationTaxableBenefitP: 0,
  utilitiesTaxableValue: 0,
  utilitiesTaxableValueP: 0,
  hotelAccomodationTaxableValue: 0,
  hotelAccomodationTaxableValueP: 0,
  othersTotal: 0,
  othersTotalP: 0
}

const fullWidthStyle: CSSProperties = { width: '100%' }

export const Ir21A1Form: FC<Ir21Props> = ({
  ir21SubmissionId,
  employeeTaxNo,
  readOnly,
  triggerUpdate,
  triggerDelete,
  triggerReset,
  ...divProps
}) => {
  const emptyFormData: Ir21A1FormState = { ...EMPTY_FORM_DATA, ir21SubmissionId, employeeTaxNo }
  const [formData, setFormData] = useState<Ir21A1FormState>(emptyFormData)
  const [errors, setErrors] = useState<Errors>()
  const ir21A1Form = useSelector(selectIr21A1Form)(ir21SubmissionId, employeeTaxNo)
  const hasData = !!ir21A1Form
  const loading = useSelector((state: StoreState) => state.tax.ir21A1FormLoading || false)
  const refetch = useSelector((state: StoreState) => state.tax.ir21A1FormRefetch)

  useEffect(() => {
    dispatch(fetchIr21A1Form(ir21SubmissionId, employeeTaxNo))
  }, [refetch, ir21SubmissionId, employeeTaxNo])

  useEffect(() => {
    if (ir21A1Form) {
      setFormData(ir21A1Form)
    }
  }, [ir21A1Form])

  const calculateFurnitureAmt = useCallback((premisesType: string, premisesAmt: number): number => {
    let amount = premisesType === 'p' ? 0.4 * premisesAmt : premisesType === 'f' ? 0.5 * premisesAmt : 0
    return amount
  }, [])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      setFormData(formData => {
        const updated = { ...formData, ...updates }

        updated.r1FurnitureAmt = calculateFurnitureAmt(updated.r1PremisesType, updated.r1PremisesAmt)
        updated.r1FurnitureAmtP = calculateFurnitureAmt(updated.r1PremisesTypeP, updated.r1PremisesAmtP)
        updated.r2FurnitureAmt = calculateFurnitureAmt(updated.r2PremisesType, updated.r2PremisesAmt)
        updated.r2FurnitureAmtP = calculateFurnitureAmt(updated.r2PremisesTypeP, updated.r2PremisesAmtP)

        if (formData.r1PremisesAmt > 0 && updated.r1RentAmt > 0) {
          updated.r1PremisesAmt = 0
          updated.r1FurnitureAmt = 0
        }
        if (formData.r1RentAmt > 0 && updated.r1PremisesAmt > 0) {
          updated.r1RentAmt = 0
        }
        if (formData.r1PremisesAmtP > 0 && updated.r1RentAmtP > 0) {
          updated.r1PremisesAmtP = 0
          updated.r1FurnitureAmtP = 0
        }
        if (formData.r1RentAmtP > 0 && updated.r1PremisesAmtP > 0) {
          updated.r1RentAmtP = 0
        }

        if (formData.r2PremisesAmt > 0 && updated.r2RentAmt > 0) {
          updated.r2PremisesAmt = 0
          updated.r2FurnitureAmt = 0
        }
        if (formData.r2RentAmt > 0 && updated.r2PremisesAmt > 0) {
          updated.r2RentAmt = 0
        }
        if (formData.r2PremisesAmtP > 0 && updated.r2RentAmtP > 0) {
          updated.r2PremisesAmtP = 0
          updated.r2FurnitureAmtP = 0
        }
        if (formData.r2RentAmtP > 0 && updated.r2PremisesAmtP > 0) {
          updated.r2RentAmtP = 0
        }

        updated.r1PlaceOfResidenceTaxableValue =
          updated.r1PremisesAmt + updated.r1FurnitureAmt + updated.r1RentAmt - updated.r1RentEmAmt
        updated.r1PlaceOfResidenceTaxableValueP =
          updated.r1PremisesAmtP + updated.r1FurnitureAmtP + updated.r1RentAmtP - updated.r1RentEmAmtP
        updated.r2PlaceOfResidenceTaxableValue =
          updated.r2PremisesAmt + updated.r2FurnitureAmt + updated.r2RentAmt - updated.r2RentEmAmt
        updated.r2PlaceOfResidenceTaxableValueP =
          updated.r2PremisesAmtP + updated.r2FurnitureAmtP + updated.r2RentAmtP - updated.r2RentEmAmtP

        updated.accomodationTaxableBenefit =
          updated.r1PlaceOfResidenceTaxableValue + updated.r2PlaceOfResidenceTaxableValue
        updated.accomodationTaxableBenefitP =
          updated.r1PlaceOfResidenceTaxableValueP + updated.r2PlaceOfResidenceTaxableValueP

        updated.utilitiesTaxableValue = updated.utilitiesAmt + updated.driverAmt + updated.servantAmt
        updated.utilitiesTaxableValueP = updated.utilitiesAmtP + updated.driverAmtP + updated.servantAmtP
        updated.hotelAccomodationTaxableValue = updated.hotelAmt
        updated.hotelAccomodationTaxableValueP = updated.hotelAmtP

        updated.othersTotal =
          updated.leavePassageAmt +
          updated.interestAmt +
          updated.insuranceAmt +
          updated.holidayAmt +
          updated.educationAmt +
          updated.clubAmt +
          updated.gainAmt +
          updated.vehicleAmt +
          updated.carAmt +
          updated.otherAmt

        updated.othersTotalP =
          updated.leavePassageAmtP +
          updated.interestAmtP +
          updated.insuranceAmtP +
          updated.holidayAmtP +
          updated.educationAmtP +
          updated.clubAmtP +
          updated.gainAmtP +
          updated.vehicleAmtP +
          updated.carAmtP +
          updated.otherAmtP

        updated.bikTotal =
          updated.accomodationTaxableBenefit +
          updated.utilitiesTaxableValue +
          updated.hotelAccomodationTaxableValue +
          updated.othersTotal

        updated.bikTotalP =
          updated.accomodationTaxableBenefitP +
          updated.utilitiesTaxableValueP +
          updated.hotelAccomodationTaxableValueP +
          updated.othersTotalP

        return updated
      })
    },
    [calculateFurnitureAmt]
  )

  const handleCreateForm = useCallback(async () => {
    const result: ActionResult | undefined = await dispatch(
      addIr21A1Draft({ ir21SubmissionId, employeeTaxNos: [formData.employeeTaxNo] })
    )

    if (!result?.errors) {
      dispatch(setIr21FormRefetch())
      dispatch(setIr21A1FormRefetch())
    }
  }, [ir21SubmissionId, formData.employeeTaxNo])

  const handleUpdateForm = useCallback(async () => {
    setErrors(undefined)

    const result = await dispatch(updateIr21A1Draft(formData))

    if (result?.errors) {
      setErrors(result.errors)
    }
  }, [formData])

  const handleDeleteForm = useCallback(async () => {
    confirm({
      title: 'Delete',
      content: 'Do you want to delete this Appendix 1?',
      onOk: async () => {
        const result: ActionResult | undefined = await dispatch(deleteIr21A1Draft(formData.id))

        if (!result?.errors) {
          dispatch(removeIr21A1Form(formData.employeeTaxNo))
        }
      },
      width: 550,
      okText: 'Delete',
      okType: 'danger'
    })
  }, [formData])

  const handleResetForm = useCallback(() => {
    if (ir21A1Form) {
      setFormData(ir21A1Form)
    }
    setErrors(undefined)
  }, [ir21A1Form])

  useEffect(() => {
    if (triggerUpdate > 0) handleUpdateForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerUpdate])

  useEffect(() => {
    if (triggerDelete > 0) handleDeleteForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerDelete])

  useEffect(() => {
    if (triggerReset > 0) handleResetForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerReset])

  if (loading)
    return (
      <div className="ir21-a1">
        <div className="ir21-a1-form">
          <Skeleton active />
        </div>
      </div>
    )

  if (!hasData)
    return (
      <Ir21EmptyForm onCreate={handleCreateForm}>
        <SecondaryText>There is no Appendix 1 for the selected employee.</SecondaryText>
      </Ir21EmptyForm>
    )

  return (
    <div {...divProps} className="ir21-a1">
      <div className="ir21-a1-form">
        <ErrorDisplay errors={errors} />
        <div id="section-a">
          <div className="ir21-a1-form__title">
            <Row gutter={20} align="middle">
              <Col span={14}>A. Accommodation and related benefits provided by Employer to the employee</Col>
              <Col span={5} className="ta-r">
                <div>Year of Cessation</div>
                <div>S$</div>
              </Col>
              <Col span={5} className="ta-r">
                <div>Year Prior to Year of Cessation</div>
                <div>S$</div>
              </Col>
            </Row>
          </div>
          <Collapse expandIconPosition="end" className="collapsed mt10" defaultActiveKey={1}>
            <Collapse.Panel
              header={
                <Row>
                  <Col>Place of Residence 1</Col>
                </Row>
              }
              key={1}
            >
              <Row gutter={[20, 10]}>
                <Col span={1}>1.</Col>
                <Col span={6}>Address of Place of Residence 1</Col>
                <Col span={17}>
                  <Form.Item noStyle>
                    <Input
                      size="small"
                      value={formData.r1Address}
                      readOnly={readOnly}
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        handleFormDataChange({ r1Address: event.target.value })
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[20, 10]}>
                <Col span={1}>2.</Col>
                <Col span={12}>Period which the premises was occupied</Col>
                <Col span={1}>From</Col>
                <Col span={5}>
                  <Form.Item noStyle>
                    <Input.Date
                      size="small"
                      style={fullWidthStyle}
                      value={formData.r1OccupationStartDate ? moment(formData.r1OccupationStartDate) : undefined}
                      inputReadOnly={readOnly}
                      onChange={(value: moment.Moment | null) =>
                        handleFormDataChange({ r1OccupationStartDate: value?.format('YYYY-MM-DD') })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item noStyle>
                    <Input.Date
                      size="small"
                      style={fullWidthStyle}
                      value={formData.r1OccupationStartDateP ? moment(formData.r1OccupationStartDateP) : undefined}
                      inputReadOnly={readOnly}
                      onChange={(value: moment.Moment | null) =>
                        handleFormDataChange({ r1OccupationStartDateP: value?.format('YYYY-MM-DD') })
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[20, 10]}>
                <Col span={1}></Col>
                <Col span={12}></Col>
                <Col span={1}>To</Col>
                <Col span={5}>
                  <Form.Item noStyle>
                    <Input.Date
                      size="small"
                      style={fullWidthStyle}
                      value={formData.r1OccupationEndDate ? moment(formData.r1OccupationEndDate) : undefined}
                      inputReadOnly={readOnly}
                      onChange={(value: moment.Moment | null) =>
                        handleFormDataChange({ r1OccupationEndDate: value?.format('YYYY-MM-DD') })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item noStyle>
                    <Input.Date
                      size="small"
                      style={fullWidthStyle}
                      value={formData.r1OccupationEndDateP ? moment(formData.r1OccupationEndDateP) : undefined}
                      inputReadOnly={readOnly}
                      onChange={(value: moment.Moment | null) =>
                        handleFormDataChange({ r1OccupationEndDateP: value?.format('YYYY-MM-DD') })
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[20, 10]}>
                <Col span={1}>3.</Col>
                <Col span={13}>Number of days the premises was occupied</Col>
                <Col span={5}>
                  <Form.Item noStyle>
                    <Input.Number
                      size="small"
                      style={fullWidthStyle}
                      readOnly={readOnly}
                      value={formData.r1OccupationDays}
                      onChange={(value: number | null) => handleFormDataChange({ r1OccupationDays: value })}
                    />
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item noStyle>
                    <Input.Number
                      size="small"
                      style={fullWidthStyle}
                      readOnly={readOnly}
                      value={formData.r1OccupationDaysP}
                      onChange={(value: number | null) => handleFormDataChange({ r1OccupationDaysP: value })}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[20, 10]}>
                <Col span={1}>4a.</Col>
                <Col span={13}>Annual Value (AV) of Premises for the period provided</Col>
                <Col span={5}>
                  <SalaryInput
                    size="small"
                    readOnly={readOnly}
                    value={formData.r1PremisesAmt}
                    onChange={(value: number | null) => handleFormDataChange({ r1PremisesAmt: value })}
                  />
                </Col>
                <Col span={5}>
                  <SalaryInput
                    size="small"
                    readOnly={readOnly}
                    value={formData.r1PremisesAmtP}
                    onChange={(value: number | null) => handleFormDataChange({ r1PremisesAmtP: value })}
                  />
                </Col>
              </Row>
              <Row gutter={[20, 10]}>
                <Col span={1}>4b.</Col>
                <Col span={13}>The premises is</Col>
                <Col span={5}>
                  <Select
                    size="small"
                    style={fullWidthStyle}
                    readOnly={readOnly}
                    value={formData.r1PremisesType}
                    placeholder="Select the premises"
                    onChange={(value: string) => handleFormDataChange({ r1PremisesType: value })}
                  >
                    <Select.Option value="p">Partially furnished</Select.Option>
                    <Select.Option value="f">Fully furnished</Select.Option>
                  </Select>
                </Col>
                <Col span={5}>
                  <Select
                    size="small"
                    style={fullWidthStyle}
                    readOnly={readOnly}
                    value={formData.r1PremisesTypeP}
                    onChange={(value: string) => handleFormDataChange({ r1PremisesTypeP: value })}
                    placeholder="Select the premises"
                  >
                    <Select.Option value="p">Partially furnished</Select.Option>
                    <Select.Option value="f">Fully furnished</Select.Option>
                  </Select>
                </Col>
              </Row>
              <Row gutter={[20, 10]}>
                <Col span={1}>4c.</Col>
                <Col span={13}>
                  Value of Furniture & Fittings
                  <InfoTooltip
                    title={`Mandatory if 4a is completed:
                            (i) 40% of AV if premises is partially furnished or
                            (ii) 50% of AV if premises is fully furnished`}
                  />
                </Col>
                <Col span={5}>
                  <div className="auto-fill">{formatMoney(formData.r1FurnitureAmt, 2)}</div>
                </Col>
                <Col span={5}>
                  <div className="auto-fill">{formatMoney(formData.r1FurnitureAmtP, 2)}</div>
                </Col>
              </Row>
              <Row gutter={[20, 10]}>
                <Col span={1}>5.</Col>
                <Col span={13}>Actual Rent paid by employer (includes rental of furniture & fittings)</Col>
                <Col span={5}>
                  <SalaryInput
                    size="small"
                    readOnly={readOnly}
                    value={formData.r1RentAmt}
                    onChange={(value: number | null) => handleFormDataChange({ r1RentAmt: value })}
                  />
                </Col>
                <Col span={5}>
                  <SalaryInput
                    size="small"
                    readOnly={readOnly}
                    value={formData.r1RentAmtP}
                    onChange={(value: number | null) => handleFormDataChange({ r1RentAmtP: value })}
                  />
                </Col>
              </Row>
              <Row gutter={[20, 10]}>
                <Col span={1}>6.</Col>
                <Col span={13}>Less: Rent paid by employee for Place of Residence 1</Col>
                <Col span={5}>
                  <SalaryInput
                    size="small"
                    readOnly={readOnly}
                    value={formData.r1RentEmAmt}
                    onChange={(value: number | null) => handleFormDataChange({ r1RentEmAmt: value })}
                  />
                </Col>
                <Col span={5}>
                  <SalaryInput
                    size="small"
                    readOnly={readOnly}
                    value={formData.r1RentEmAmtP}
                    onChange={(value: number | null) => handleFormDataChange({ r1RentEmAmtP: value })}
                  />
                </Col>
              </Row>
              <Row gutter={[20, 10]} className="font-bold">
                <Col span={1}>7.</Col>
                <Col span={13}>Taxable Value of Place of Residence 1 [(A4a + A4c - A6) or (A5 - A6)]</Col>
                <Col span={5}>
                  <div className="auto-fill">{formatMoney(formData.r1PlaceOfResidenceTaxableValue, 2) || '0.00'}</div>
                </Col>
                <Col span={5}>
                  <div className="auto-fill">{formatMoney(formData.r1PlaceOfResidenceTaxableValueP, 2) || '0.00'}</div>
                </Col>
              </Row>
            </Collapse.Panel>
          </Collapse>
          <Collapse expandIconPosition="end" className="collapsed mt5" defaultActiveKey={1}>
            <Collapse.Panel
              header={
                <Row>
                  <Col>Place of Residence 2</Col>
                </Row>
              }
              key={1}
            >
              <Row gutter={[20, 10]}>
                <Col span={1}>8.</Col>
                <Col span={6}>Address of Place of Residence 2</Col>
                <Col span={17}>
                  <Form.Item noStyle>
                    <Input
                      size="small"
                      value={formData.r2Address}
                      readOnly={readOnly}
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        handleFormDataChange({ r2Address: event.target.value })
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[20, 10]}>
                <Col span={1}>9.</Col>
                <Col span={12}>Period which the premises was occupied</Col>
                <Col span={1}>From</Col>
                <Col span={5}>
                  <Form.Item noStyle>
                    <Input.Date
                      size="small"
                      style={fullWidthStyle}
                      value={formData.r2OccupationStartDate ? moment(formData.r2OccupationStartDate) : undefined}
                      inputReadOnly={readOnly}
                      onChange={(value: moment.Moment | null) =>
                        handleFormDataChange({ r2OccupationStartDate: value?.format('YYYY-MM-DD') })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item noStyle>
                    <Input.Date
                      size="small"
                      style={fullWidthStyle}
                      value={formData.r2OccupationStartDateP ? moment(formData.r2OccupationStartDateP) : undefined}
                      inputReadOnly={readOnly}
                      onChange={(value: moment.Moment | null) =>
                        handleFormDataChange({ r2OccupationStartDateP: value?.format('YYYY-MM-DD') })
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[20, 10]}>
                <Col span={1}></Col>
                <Col span={12}></Col>
                <Col span={1}>To</Col>
                <Col span={5}>
                  <Form.Item noStyle>
                    <Input.Date
                      size="small"
                      style={fullWidthStyle}
                      value={formData.r2OccupationEndDate ? moment(formData.r2OccupationEndDate) : undefined}
                      inputReadOnly={readOnly}
                      onChange={(value: moment.Moment | null) =>
                        handleFormDataChange({ r2OccupationEndDate: value?.format('YYYY-MM-DD') })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item noStyle>
                    <Input.Date
                      size="small"
                      style={fullWidthStyle}
                      value={formData.r2OccupationEndDateP ? moment(formData.r2OccupationEndDateP) : undefined}
                      inputReadOnly={readOnly}
                      onChange={(value: moment.Moment | null) =>
                        handleFormDataChange({ r2OccupationEndDateP: value?.format('YYYY-MM-DD') })
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[20, 10]}>
                <Col span={1}>10.</Col>
                <Col span={13}>Number of days the premises was occupied</Col>
                <Col span={5}>
                  <Form.Item noStyle>
                    <Input.Number
                      size="small"
                      style={fullWidthStyle}
                      readOnly={readOnly}
                      value={formData.r2OccupationDays}
                      onChange={(value: number | null) => handleFormDataChange({ r2OccupationDays: value })}
                    />
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item noStyle>
                    <Input.Number
                      size="small"
                      style={fullWidthStyle}
                      readOnly={readOnly}
                      value={formData.r2OccupationDaysP}
                      onChange={(value: number | null) => handleFormDataChange({ r2OccupationDaysP: value })}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[20, 10]}>
                <Col span={1}>11a.</Col>
                <Col span={13}>Annual Value (AV) of Premises for the period provided</Col>
                <Col span={5}>
                  <SalaryInput
                    size="small"
                    readOnly={readOnly}
                    value={formData.r2PremisesAmt}
                    onChange={(value: number | null) => handleFormDataChange({ r2PremisesAmt: value })}
                  />
                </Col>
                <Col span={5}>
                  <SalaryInput
                    size="small"
                    readOnly={readOnly}
                    value={formData.r2PremisesAmtP}
                    onChange={(value: number | null) => handleFormDataChange({ r2PremisesAmtP: value })}
                  />
                </Col>
              </Row>
              <Row gutter={[20, 10]}>
                <Col span={1}>11b.</Col>
                <Col span={13}>The premises is</Col>
                <Col span={5}>
                  <Select
                    size="small"
                    style={fullWidthStyle}
                    allowClear={false}
                    readOnly={readOnly}
                    value={formData.r2PremisesType}
                    onChange={(value: string) => handleFormDataChange({ r2PremisesType: value })}
                    placeholder="Select the premises"
                  >
                    <Select.Option value="p">Partially furnished</Select.Option>
                    <Select.Option value="f">Fully furnished</Select.Option>
                  </Select>
                </Col>
                <Col span={5}>
                  <Select
                    size="small"
                    style={fullWidthStyle}
                    allowClear={false}
                    readOnly={readOnly}
                    value={formData.r2PremisesTypeP}
                    onChange={(value: string) => handleFormDataChange({ r2PremisesTypeP: value })}
                    placeholder="Select the premises"
                  >
                    <Select.Option value="p">Partially furnished</Select.Option>
                    <Select.Option value="f">Fully furnished</Select.Option>
                  </Select>
                </Col>
              </Row>
              <Row gutter={[20, 10]}>
                <Col span={1}>11c.</Col>
                <Col span={13}>
                  Value of Furniture & Fittings
                  <InfoTooltip
                    title={`Mandatory if 4a is completed:
                            (i) 40% of AV if premises is partially furnished or
                            (ii) 50% of AV if premises is fully furnished`}
                  />
                </Col>
                <Col span={5}>
                  <div className="auto-fill">{formatMoney(formData.r2FurnitureAmt, 2)}</div>
                </Col>
                <Col span={5}>
                  <div className="auto-fill">{formatMoney(formData.r2FurnitureAmtP, 2)}</div>
                </Col>
              </Row>
              <Row gutter={[20, 10]}>
                <Col span={1}>12.</Col>
                <Col span={13}>Actual Rent paid by employer (includes rental of furniture & fittings)</Col>
                <Col span={5}>
                  <SalaryInput
                    size="small"
                    readOnly={readOnly}
                    value={formData.r2RentAmt}
                    onChange={(value: number | null) => handleFormDataChange({ r2RentAmt: value })}
                  />
                </Col>
                <Col span={5}>
                  <SalaryInput
                    size="small"
                    readOnly={readOnly}
                    value={formData.r2RentAmtP}
                    onChange={(value: number | null) => handleFormDataChange({ r2RentAmtP: value })}
                  />
                </Col>
              </Row>
              <Row gutter={[20, 10]}>
                <Col span={1}>13.</Col>
                <Col span={13}>Less: Rent paid by employee for Place of Residence 2</Col>
                <Col span={5}>
                  <SalaryInput
                    size="small"
                    readOnly={readOnly}
                    value={formData.r2RentEmAmt}
                    onChange={(value: number | null) => handleFormDataChange({ r2RentEmAmt: value })}
                  />
                </Col>
                <Col span={5}>
                  <SalaryInput
                    size="small"
                    readOnly={readOnly}
                    value={formData.r2RentEmAmtP}
                    onChange={(value: number | null) => handleFormDataChange({ r2RentEmAmtP: value })}
                  />
                </Col>
              </Row>
              <Row gutter={[20, 10]} className="font-bold">
                <Col span={1}>14.</Col>
                <Col span={13}>Taxable Value of Place of Residence 2 [(A11a + A11c - A13) or (A12 - A13)]</Col>
                <Col span={5}>
                  <div className="auto-fill">{formatMoney(formData.r2PlaceOfResidenceTaxableValue, 2) || '0.00'}</div>
                </Col>
                <Col span={5}>
                  <div className="auto-fill">{formatMoney(formData.r2PlaceOfResidenceTaxableValueP, 2) || '0.00'}</div>
                </Col>
              </Row>
              <Row gutter={[20, 10]} className="font-bold">
                <Col span={1}>15.</Col>
                <Col span={13}>Taxable benefit of accommodation and furnishing (A7 + A14)</Col>
                <Col span={5}>
                  <div className="auto-fill">{formatMoney(formData.accomodationTaxableBenefit, 2) || '0.00'}</div>
                </Col>
                <Col span={5}>
                  <div className="auto-fill">{formatMoney(formData.accomodationTaxableBenefitP, 2) || '0.00'}</div>
                </Col>
              </Row>
              <Row gutter={[20, 10]}>
                <Col span={1}>16.</Col>
                <Col span={13}>
                  Utilities/Telephone/Pager/Suitcase/Golf Bag & Accessories/Camera/Electronic Gadgets (e.g Tablet,
                  Laptop, etc) <br />
                  (Actual amount)
                </Col>
                <Col span={5}>
                  <SalaryInput
                    size="small"
                    readOnly={readOnly}
                    value={formData.utilitiesAmt}
                    onChange={(value: number | null) => handleFormDataChange({ utilitiesAmt: value })}
                  />
                </Col>
                <Col span={5}>
                  <SalaryInput
                    size="small"
                    readOnly={readOnly}
                    value={formData.utilitiesAmtP}
                    onChange={(value: number | null) => handleFormDataChange({ utilitiesAmtP: value })}
                  />
                </Col>
              </Row>
              <Row gutter={[20, 10]}>
                <Col span={1}>17.</Col>
                <Col span={13}>Driver [Annual Wages X (Private / Total Mileage)]</Col>
                <Col span={5}>
                  <SalaryInput
                    size="small"
                    readOnly={readOnly}
                    value={formData.driverAmt}
                    onChange={(value: number | null) => handleFormDataChange({ driverAmt: value })}
                  />
                </Col>
                <Col span={5}>
                  <SalaryInput
                    size="small"
                    readOnly={readOnly}
                    value={formData.driverAmtP}
                    onChange={(value: number | null) => handleFormDataChange({ driverAmtP: value })}
                  />
                </Col>
              </Row>
              <Row gutter={[20, 10]}>
                <Col span={1}>18.</Col>
                <Col span={13}>Servant / Gardener / Upkeep of Compound (Actual Amount)</Col>
                <Col span={5}>
                  <SalaryInput
                    size="small"
                    readOnly={readOnly}
                    value={formData.servantAmt}
                    onChange={(value: number | null) => handleFormDataChange({ servantAmt: value })}
                  />
                </Col>
                <Col span={5}>
                  <SalaryInput
                    size="small"
                    readOnly={readOnly}
                    value={formData.servantAmtP}
                    onChange={(value: number | null) => handleFormDataChange({ servantAmtP: value })}
                  />
                </Col>
              </Row>
              <Row gutter={[20, 10]} className="font-bold">
                <Col span={1}>19.</Col>
                <Col span={13}>Taxable value of utilities and housekeeping costs (A16 + A17 + A18)</Col>
                <Col span={5}>
                  <div className="auto-fill">{formatMoney(formData.utilitiesTaxableValue, 2) || '0.00'}</div>
                </Col>
                <Col span={5}>
                  <div className="auto-fill">{formatMoney(formData.utilitiesTaxableValueP, 2) || '0.00'}</div>
                </Col>
              </Row>
            </Collapse.Panel>
          </Collapse>
        </div>
        <div id="section-b">
          <div className="ir21-a1-form__title">
            <Row gutter={20} align="middle">
              <Col span={14}>B. Hotel Accommodation Provided B. Hotel Accommodation Provided</Col>
              <Col span={5} className="ta-r">
                <div>Year of Cessation</div>
                <div>S$</div>
              </Col>
              <Col span={5} className="ta-r">
                <div>Year Prior to Year of Cessation</div>
                <div>S$</div>
              </Col>
            </Row>
          </div>
          <div className="ir21-a1-form__field-row">
            <Row gutter={[20, 10]}>
              <Col span={1}>1.</Col>
              <Col span={13}>
                Hotel accommodation / Serviced Apartment within hotel building <br />
                (Actual Amount less amount paid by the employee)
              </Col>
              <Col span={5}>
                <SalaryInput
                  size="small"
                  readOnly={readOnly}
                  value={formData.hotelAmt}
                  onChange={(value: number | null) => handleFormDataChange({ hotelAmt: value })}
                />
              </Col>
              <Col span={5}>
                <SalaryInput
                  size="small"
                  readOnly={readOnly}
                  value={formData.hotelAmtP}
                  onChange={(value: number | null) => handleFormDataChange({ hotelAmtP: value })}
                />
              </Col>
            </Row>
            <Row gutter={[20, 10]} className="font-bold">
              <Col span={1}></Col>
              <Col span={13}>Taxable Value of Hotel Accommodation (B1)</Col>
              <Col span={5}>
                <div className="auto-fill">{formatMoney(formData.hotelAccomodationTaxableValue, 2) || '0.00'}</div>
              </Col>
              <Col span={5}>
                <div className="auto-fill">{formatMoney(formData.hotelAccomodationTaxableValueP, 2) || '0.00'}</div>
              </Col>
            </Row>
          </div>
        </div>
        <div id="section-c">
          <div className="ir21-a1-form__title">
            <Row gutter={20} align="middle">
              <Col span={14}>C. Others</Col>
              <Col span={5} className="ta-r">
                <div>Year of Cessation</div>
                <div>S$</div>
              </Col>
              <Col span={5} className="ta-r">
                <div>Year Prior to Year of Cessation</div>
                <div>S$</div>
              </Col>
            </Row>
          </div>
          <div className="ir21-a1-form__field-row">
            <Row gutter={[20, 10]}>
              <Col span={1}>1.</Col>
              <Col span={13}>Cost of home leave passage and incidental benefits</Col>
              <Col span={5}>
                <SalaryInput
                  size="small"
                  readOnly={readOnly}
                  value={formData.leavePassageAmt}
                  onChange={(value: number | null) => handleFormDataChange({ leavePassageAmt: value })}
                />
              </Col>
              <Col span={5}>
                <SalaryInput
                  size="small"
                  readOnly={readOnly}
                  value={formData.leavePassageAmtP}
                  onChange={(value: number | null) => handleFormDataChange({ leavePassageAmtP: value })}
                />
              </Col>
            </Row>
            <Row gutter={[20, 10]}>
              <Col span={1}>2.</Col>
              <Col span={13}>Interest payment made by employer</Col>
              <Col span={5}>
                <SalaryInput
                  size="small"
                  readOnly={readOnly}
                  value={formData.interestAmt}
                  onChange={(value: number | null) => handleFormDataChange({ interestAmt: value })}
                />
              </Col>
              <Col span={5}>
                <SalaryInput
                  size="small"
                  readOnly={readOnly}
                  value={formData.interestAmtP}
                  onChange={(value: number | null) => handleFormDataChange({ interestAmtP: value })}
                />
              </Col>
            </Row>
            <Row gutter={[20, 10]}>
              <Col span={1}>3.</Col>
              <Col span={13}>Insurance premiums paid by the employer</Col>
              <Col span={5}>
                <SalaryInput
                  size="small"
                  readOnly={readOnly}
                  value={formData.insuranceAmt}
                  onChange={(value: number | null) => handleFormDataChange({ insuranceAmt: value })}
                />
              </Col>
              <Col span={5}>
                <SalaryInput
                  size="small"
                  readOnly={readOnly}
                  value={formData.insuranceAmtP}
                  onChange={(value: number | null) => handleFormDataChange({ insuranceAmtP: value })}
                />
              </Col>
            </Row>
            <Row gutter={[20, 10]}>
              <Col span={1}>4.</Col>
              <Col span={13}>Free or subsidised holidays including air passage, etc</Col>
              <Col span={5}>
                <SalaryInput
                  size="small"
                  readOnly={readOnly}
                  value={formData.holidayAmt}
                  onChange={(value: number | null) => handleFormDataChange({ holidayAmt: value })}
                />
              </Col>
              <Col span={5}>
                <SalaryInput
                  size="small"
                  readOnly={readOnly}
                  value={formData.holidayAmtP}
                  onChange={(value: number | null) => handleFormDataChange({ holidayAmtP: value })}
                />
              </Col>
            </Row>
            <Row gutter={[20, 10]}>
              <Col span={1}>5.</Col>
              <Col span={13}>Educational expenses including tutor provided</Col>
              <Col span={5}>
                <SalaryInput
                  size="small"
                  readOnly={readOnly}
                  value={formData.educationAmt}
                  onChange={(value: number | null) => handleFormDataChange({ educationAmt: value })}
                />
              </Col>
              <Col span={5}>
                <SalaryInput
                  size="small"
                  readOnly={readOnly}
                  value={formData.educationAmtP}
                  onChange={(value: number | null) => handleFormDataChange({ educationAmtP: value })}
                />
              </Col>
            </Row>
            <Row gutter={[20, 10]}>
              <Col span={1}>6.</Col>
              <Col span={13}>Entrance/ transfer fees and annual subscription to social or recreational clubs</Col>
              <Col span={5}>
                <SalaryInput
                  size="small"
                  readOnly={readOnly}
                  value={formData.clubAmt}
                  onChange={(value: number | null) => handleFormDataChange({ clubAmt: value })}
                />
              </Col>
              <Col span={5}>
                <SalaryInput
                  size="small"
                  readOnly={readOnly}
                  value={formData.clubAmtP}
                  onChange={(value: number | null) => handleFormDataChange({ clubAmtP: value })}
                />
              </Col>
            </Row>
            <Row gutter={[20, 10]}>
              <Col span={1}>7.</Col>
              <Col span={13}>
                Gains from assets, e.g. vehicles, property, etc sold to employees at a price lower than open market
                value
              </Col>
              <Col span={5}>
                <SalaryInput
                  size="small"
                  readOnly={readOnly}
                  value={formData.gainAmt}
                  onChange={(value: number | null) => handleFormDataChange({ gainAmt: value })}
                />
              </Col>
              <Col span={5}>
                <SalaryInput
                  size="small"
                  readOnly={readOnly}
                  value={formData.gainAmtP}
                  onChange={(value: number | null) => handleFormDataChange({ gainAmtP: value })}
                />
              </Col>
            </Row>
            <Row gutter={[20, 10]}>
              <Col span={1}>8.</Col>
              <Col span={13}>Full cost of motor vehicle given to employee</Col>
              <Col span={5}>
                <SalaryInput
                  size="small"
                  readOnly={readOnly}
                  value={formData.vehicleAmt}
                  onChange={(value: number | null) => handleFormDataChange({ vehicleAmt: value })}
                />
              </Col>
              <Col span={5}>
                <SalaryInput
                  size="small"
                  readOnly={readOnly}
                  value={formData.vehicleAmtP}
                  onChange={(value: number | null) => handleFormDataChange({ vehicleAmtP: value })}
                />
              </Col>
            </Row>
            <Row gutter={[20, 10]}>
              <Col span={1}>9.</Col>
              <Col span={13}>Car benefit</Col>
              <Col span={5}>
                <SalaryInput
                  size="small"
                  readOnly={readOnly}
                  value={formData.carAmt}
                  onChange={(value: number | null) => handleFormDataChange({ carAmt: value })}
                />
              </Col>
              <Col span={5}>
                <SalaryInput
                  size="small"
                  readOnly={readOnly}
                  value={formData.carAmtP}
                  onChange={(value: number | null) => handleFormDataChange({ carAmtP: value })}
                />
              </Col>
            </Row>
            <Row gutter={[20, 10]}>
              <Col span={1}>10.</Col>
              <Col span={13}>Other non monetary awards/ benefits which do not fall within the above items</Col>
              <Col span={5}>
                <SalaryInput
                  size="small"
                  readOnly={readOnly}
                  value={formData.otherAmt}
                  onChange={(value: number | null) => handleFormDataChange({ otherAmt: value })}
                />
              </Col>
              <Col span={5}>
                <SalaryInput
                  size="small"
                  readOnly={readOnly}
                  value={formData.otherAmtP}
                  onChange={(value: number | null) => handleFormDataChange({ otherAmtP: value })}
                />
              </Col>
            </Row>
            <Row gutter={[20, 10]} className="font-bold">
              <Col span={1}>11.</Col>
              <Col span={13}>Total Others (C1 to C10)</Col>
              <Col span={5}>
                <div className="auto-fill">{formatMoney(formData.othersTotal, 2) || '0.00'}</div>
              </Col>
              <Col span={5}>
                <div className="auto-fill">{formatMoney(formData.othersTotalP, 2) || '0.00'}</div>
              </Col>
            </Row>
            <Row gutter={[20, 10]} className="font-bold">
              <Col span={14}>
                Total value of benefits-in-kind (A15 + A19 + B1 + C11) to be reflected in 4(j) of Form IR21 - Page 2
              </Col>
              <Col span={5}>
                <div className="auto-fill">{formatMoney(formData.bikTotal, 2) || '0.00'}</div>
              </Col>
              <Col span={5}>
                <div className="auto-fill">{formatMoney(formData.bikTotalP, 2) || '0.00'}</div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  )
}
