import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Drawer, Space, Steps } from '~/core-components'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors, StoreState } from '~/types/store'
import { showError } from '~/utils'
import { DailyImportExecuteState, DailyImportSourceState, DailyImportState } from '../../../types'
import { prepareDailyImport, processPayroll, executeDailyImport, validateDailyImport } from '../../../actions'
import { usePayRun } from '~/features/payroll/hooks'
import { ImportDailyInput } from './components/ImportDailyInput'
import { SelectDailyRecord } from './components/SelectDailyRecord'
import { ValidateDailyReport } from './components/ValidateDailyReport'
import { ImportDailyRecord } from './components/ImportDailyRecord'
import './ImportDailyDrawer.less'

interface ImportDailyDrawerProps {
  payRunId: string
  visible: boolean
  onClose: () => void
}

export interface ImportDailyFormData {
  attendancePeriodId: string
  source: DailyImportSourceState[]
  selected: DailyImportSourceState[]
}

const EMPTY_FORM_DATA: ImportDailyFormData = {
  attendancePeriodId: '',
  source: [],
  selected: []
}

type StepKey = 'input' | 'select' | 'validate' | 'verify' | 'complete'

interface IStep {
  key: StepKey
  title: string
  nextButton?: string
  backButton?: string
}

const STEPS: IStep[] = [
  {
    key: 'input',
    title: 'Select period'
  },
  {
    key: 'select',
    title: 'Select records'
  },
  {
    key: 'validate',
    title: 'Validate'
  },
  {
    key: 'verify',
    title: 'Verify and import',
    nextButton: 'Import'
  },
  {
    key: 'complete',
    title: 'Done',
    nextButton: 'Close'
  }
]

export const ImportDailyDrawer: FC<ImportDailyDrawerProps> = ({ payRunId, visible, onClose }) => {
  const [formData, setFormData] = useState<ImportDailyFormData>(EMPTY_FORM_DATA)
  const [step, setStep] = useState(0)
  const [nextLoading, setNextLoading] = useState(false)
  const [errors, setErrors] = useState<Errors>()
  const [payRun] = usePayRun(payRunId)
  const hasDataToImport = useSelector(
    (state: StoreState) => (state.payroll.dailyImportValidate?.summary?.proceed || 0) > 0
  )

  useEffect(() => {
    setStep(0)
    setErrors(undefined)
    setFormData(EMPTY_FORM_DATA)
  }, [visible])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setErrors(undefined)
    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  const prepareDailyRecords = useCallback(async () => {
    const errorMessages: string[] = []
    if (!formData.attendancePeriodId) {
      errorMessages.push('Please select the period')
    }

    if (errorMessages.length > 0) {
      setErrors({ '*': errorMessages })
      return
    }

    let result: ActionResult<DailyImportState> | undefined
    try {
      setNextLoading(true)
      result = await dispatch(prepareDailyImport(payRunId, formData.attendancePeriodId))

      const source = result?.result?.source || []
      handleFormDataChange({ source, selected: source.filter(s => !s.isInvalid) })
    } finally {
      setNextLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      setErrors(undefined)
      setStep(step => step + 1)
    }
  }, [payRunId, formData.attendancePeriodId, handleFormDataChange])

  const filterSource = useCallback(async () => {
    const errorMessages: string[] = []
    if (formData.selected.length === 0) {
      errorMessages.push('No daily record is selected')
    }

    if (errorMessages.length > 0) {
      setErrors({ '*': errorMessages })
      return
    }

    let result: ActionResult<DailyImportExecuteState> | undefined
    try {
      setNextLoading(true)
      const ids = formData.selected.map(s => s.employeeId)
      result = await dispatch(validateDailyImport(payRunId, formData.attendancePeriodId, ids))
    } finally {
      setNextLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      setErrors(undefined)
      setStep(step => step + 1)
    }
  }, [payRunId, formData.attendancePeriodId, formData.selected])

  const executeImport = useCallback(async () => {
    let result: ActionResult<DailyImportExecuteState> | undefined
    try {
      setNextLoading(true)
      result = await dispatch(executeDailyImport(payRunId))
    } finally {
      setNextLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      setErrors(undefined)
      setStep(step => step + 1)

      const employeeIds = result?.result?.employeeIdList
      if (employeeIds) {
        dispatch(processPayroll(payRunId, employeeIds))
      }
    }
  }, [payRunId])

  const handlePrev = useCallback(() => {
    setErrors(undefined)
    setStep(step => step - 1)
  }, [])

  const handleCloseDrawer = useCallback(() => {
    typeof onClose === 'function' && onClose()
  }, [onClose])

  const handleNext = useCallback(async () => {
    if (STEPS[step].key === 'input') {
      await prepareDailyRecords()
    } else if (STEPS[step].key === 'select') {
      await filterSource()
    } else if (STEPS[step].key === 'validate') {
      if (hasDataToImport) {
        setStep(step => step + 1)
      } else {
        showError('No row to import')
      }
    } else if (STEPS[step].key === 'verify') {
      await executeImport()
    } else if (STEPS[step].key === 'complete') {
      handleCloseDrawer()
    }
  }, [step, prepareDailyRecords, filterSource, executeImport, hasDataToImport, handleCloseDrawer])

  return (
    <Drawer
      open={visible}
      title="Import payroll items from Attendance"
      onClose={handleCloseDrawer}
      width="80%"
      className="import-daily-drawer"
      footer={
        <Space className="import-daily-drawer__action-bar-buttons">
          {STEPS[step - 1] && step !== STEPS.length - 1 && (
            <Button onClick={handlePrev}>{STEPS[step].backButton || 'Back'}</Button>
          )}
          {STEPS[step] && (
            <Button type="primary" onClick={handleNext} loading={nextLoading}>
              {STEPS[step].nextButton || 'Next'}
            </Button>
          )}
        </Space>
      }
    >
      <Steps progressDot size="small" current={step} items={STEPS.map(s => ({ title: s.title }))} />
      <ImportDailyInput
        visible={STEPS[step].key === 'input'}
        payRun={payRun}
        data={formData}
        onChange={handleFormDataChange}
        errors={errors}
      />
      <SelectDailyRecord
        visible={STEPS[step].key === 'select'}
        payRun={payRun}
        data={formData}
        onSelect={handleFormDataChange}
        errors={errors}
      />
      <ValidateDailyReport visible={STEPS[step].key === 'validate'} />
      <ImportDailyRecord
        visible={['verify', 'complete'].includes(STEPS[step].key)}
        success={STEPS[step].key === 'complete'}
        payRun={payRun}
      />
    </Drawer>
  )
}
