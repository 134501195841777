import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { XeroConnectionState } from '../types'

export const selectXeroConnections = createSelector(
  (state: StoreState) => state.payroll.xeroConnections,
  xeroConnectionsState => {
    return Object.values(xeroConnectionsState?.entities || {}) as XeroConnectionState[]
  }
)
