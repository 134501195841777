import React, { FC, useCallback, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { useSelector } from 'react-redux'
import debounce from 'lodash/debounce'
import classNames from 'classnames'
import { Card, CardProps, Divider, Dropdown, Form, Input, Link, SecondaryText, Space } from '~/core-components'
import { Col, Row, SelectEmployees } from '~/components'
import { dispatch } from '~/stores/store'
import { Pagination } from '~/types/common'
import { ActionResult, Errors, StoreState } from '~/types/store'
import { mapPayRunPayslipStateToIPayRunPayslip, PayRunPayslipState } from '../../types'
import {
  fetchPayRunPayslip,
  fetchPayRunPayslipEmSelections,
  publishAllPayRecords,
  unpublishAllPayRecords,
  updatePayRunPayslip
} from '../../actions'
import { AutomationSwitcher } from './AutomationSwitcher'
import './PayslipPublishCard.less'

export interface PayslipPublishCardProps extends CardProps {
  payRunId: string
}

const EMPTY_FORM_DATA: PayRunPayslipState = {
  id: '',
  payRunId: '',
  isAutoPublish: false,
  autoPublishDate: '',
  excludeEmployeeIds: []
}

const DEBOUNCE_TIMEOUT = 800

export const PayslipPublishCard: FC<PayslipPublishCardProps> = ({ payRunId, className, ...props }) => {
  const payRunPayslip = useSelector((state: StoreState) => state.payroll.payRunPayslip[payRunId] || {})
  const [formData, setFormData] = useState<PayRunPayslipState>(EMPTY_FORM_DATA)
  const [publishing, setPublishing] = useState<boolean>(false)
  const [exclusion, setExclusion] = useState<boolean>(false)
  const [errors, setErrors] = useState<Errors>()
  const [updating, setUpdating] = useState(false)
  const updateRef = React.useRef(0)

  useEffect(() => {
    if (payRunId) {
      dispatch(fetchPayRunPayslip(payRunId))
    }
  }, [payRunId])

  useEffect(() => {
    if (payRunPayslip) {
      setFormData(formData => ({ ...formData, ...payRunPayslip }))

      if (payRunPayslip.excludeEmployeeIds && payRunPayslip.excludeEmployeeIds.length > 0) {
        setExclusion(true)
      }
    }
  }, [payRunPayslip])

  const handlePublishAll = useCallback(async () => {
    if (payRunId) {
      setPublishing(true)
      try {
        await dispatch(publishAllPayRecords(payRunId))
      } finally {
        setPublishing(false)
      }
    }
  }, [payRunId])

  const handleUnpublishAll = useCallback(async () => {
    if (payRunId) {
      setPublishing(true)
      try {
        await dispatch(unpublishAllPayRecords(payRunId))
      } finally {
        setPublishing(false)
      }
    }
  }, [payRunId])

  const handleShowExclusion = useCallback(async () => {
    setExclusion(true)
  }, [])

  const handleUpdate = React.useMemo(() => {
    const updateForms = async (formData: PayRunPayslipState) => {
      let result: ActionResult | undefined
      updateRef.current += 1
      const updateId = updateRef.current
      setErrors(undefined)

      try {
        setUpdating(true)
        if (payRunPayslip) {
          result = await dispatch(
            updatePayRunPayslip(
              payRunPayslip.payRunId,
              payRunPayslip.id,
              mapPayRunPayslipStateToIPayRunPayslip(payRunPayslip),
              mapPayRunPayslipStateToIPayRunPayslip(formData)
            )
          )
        }
      } finally {
        if (updateId === updateRef.current) setUpdating(false)
      }

      if (result?.errors) {
        setErrors(result.errors)
      }
    }

    return debounce(updateForms, DEBOUNCE_TIMEOUT)
  }, [payRunPayslip])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      setFormData(formData => {
        const updatedFormData = { ...formData, ...updates }
        handleUpdate(updatedFormData)
        return updatedFormData
      })
    },
    [handleUpdate]
  )

  const handleFetchEmployee = useCallback(
    (viewId: string, pagination: Pagination, search: string) => {
      if (payRunId) {
        dispatch(fetchPayRunPayslipEmSelections(payRunId, viewId, pagination, search))
      }
    },
    [payRunId]
  )

  const handleSelectEmployee = useCallback(
    (excludeEmployeeIds: string[]) => {
      setFormData(formData => {
        const updates = { ...formData, excludeEmployeeIds }
        handleUpdate(updates)
        return updates
      })
    },
    [handleUpdate]
  )

  return (
    <Card className={classNames('payslip-publish-card', className)} {...props}>
      <Row>
        <Col flex="auto">
          <div className="payslip-publish-card__title">
            <b>Payslip</b>
          </div>
        </Col>
        <Col style={{ marginTop: '-5px' }}>
          <AutomationSwitcher
            checked={formData.isAutoPublish}
            loading={updating}
            onChange={(checked: boolean) => handleFormDataChange({ isAutoPublish: checked })}
          />
        </Col>
      </Row>
      {formData.isAutoPublish && (
        <Row>
          <Col span={24}>
            <Form.Item
              label="Auto-publish payslips on"
              validateStatus={errors?.autoPublishDate ? 'error' : ''}
              help={errors?.autoPublishDate}
            >
              <Input.Date
                style={{ width: '140px' }}
                value={formData.autoPublishDate ? moment(formData.autoPublishDate) : undefined}
                onChange={(value: moment.Moment | null) =>
                  handleFormDataChange({ autoPublishDate: value?.format('YYYY-MM-DD') })
                }
              />
            </Form.Item>
          </Col>
          {!exclusion && (
            <Col span={24}>
              <Link onClick={handleShowExclusion}>Add exclusion</Link>
            </Col>
          )}
          {exclusion && (
            <Col span={24}>
              <SelectEmployees
                viewName="payrun_payslip"
                label="Excluding"
                labelCol={{ flex: '160px' }}
                employeeIds={formData.excludeEmployeeIds || []}
                onFetchData={handleFetchEmployee}
                onSelect={handleSelectEmployee}
              />
            </Col>
          )}
          <Divider />
        </Row>
      )}
      <Row wrap={false}>
        <Col flex={1}>
          <SecondaryText>Show/hide payslips in Employee Portal</SecondaryText>
        </Col>
        <Col>
          <Space className="payslip-publish-card__space">
            <Dropdown.Button
              type="primary"
              size="small"
              menu={{
                className: 'payslip-publish-card__dropdown',
                items: [{ key: 'unpublish', label: 'Unpublish all', onClick: handleUnpublishAll }]
              }}
              icon={<i className="fa-light fa-angle-down" />}
              onClick={handlePublishAll}
              buttonsRender={([leftButton, rightButton]) => [
                React.cloneElement(leftButton as React.ReactElement, { loading: publishing }),
                rightButton
              ]}
            >
              Publish all
            </Dropdown.Button>
          </Space>
        </Col>
      </Row>
    </Card>
  )
}
