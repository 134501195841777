import React, { FC, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ChartSummary, EChartsOption } from '~/charts'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchAverageServiceLength } from '../../../actions'

interface AverageServiceLengthProps {}

export const AverageServiceLength: FC<AverageServiceLengthProps> = () => {
  const data = useSelector((state: StoreState) => state.employee.chartSummaries['average_svc_len'])
  const loading = useSelector((state: StoreState) => state.employee.chartSummariesLoading['average_svc_len'])

  const option = useMemo(() => {
    return {
      tooltip: {
        formatter: (params: any) => {
          const name = params[0].name
          const marker = params[0].marker
          const value = params[0].value
          const unit = 'headcount(s)'
          return `${name}<br />${marker} <b>${value}</b> ${unit}`
        }
      },
      xAxis: {
        data: data?.labels || []
      },
      series: [
        {
          type: 'bar',
          data: data?.values || [],
          itemStyle: {
            color: '#3caef2'
          }
        }
      ]
    } as EChartsOption
  }, [data])

  useEffect(() => {
    dispatch(fetchAverageServiceLength())
  }, [])

  return (
    <ChartSummary
      title="Average years of service"
      number={data?.number || 0}
      unit={data?.unit.toLowerCase()}
      option={option}
      footer={data?.median && `Median ${data.median}`}
      loading={loading}
    />
  )
}
