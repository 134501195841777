import { replaceXeroTenants, setXeroTenantsLoading } from '../reducers'
import { apiGetXeroTenants } from '../api/xero.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchXeroTenants = (options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().payroll.xeroTenantsLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().payroll.xeroTenants?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setXeroTenantsLoading(true))
      const { result, status } = await apiGetXeroTenants()
      if (status) {
        dispatch(replaceXeroTenants(result))
      }
    } finally {
      dispatch(setXeroTenantsLoading(false))
    }
  }
}
