import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils'
import {
  DailyPolicyRowState,
  DailyPolicyState,
  IAddDailyPolicy,
  ISaveDailyPolicyRow,
  ISortDailyPolicyRow
} from '../types'

const baseUrl = getBaseUrl('/attendance/dailypolicy')

export const apiGetDailyPolicies = () => request<DailyPolicyState[]>('get', baseUrl)

export const apiGetDailyPolicy = (id: string) => request<DailyPolicyState[]>('get', `${baseUrl}/${id}`)

export const apiAddDailyPolicy = (policy: IAddDailyPolicy) => request<{ id: string }>('post', baseUrl, policy)

export const apiUpdateDailyPolicy = (id: string, policy: Operation[]) =>
  request<void>('patch', `${baseUrl}/${id}`, policy)

export const apiDeleteDailyPolicy = (id: string) => request<void>('delete', `${baseUrl}/${id}`)

export const apiGetDailyPolicyRows = (dailyPolicyId: string) =>
  request<DailyPolicyRowState[]>('get', `${baseUrl}/${dailyPolicyId}`)

export const apiGetDailyPolicyRow = (dailyPolicyId: string, dailyPolicyRowId: string) =>
  request<DailyPolicyRowState>('get', `${baseUrl}/${dailyPolicyId}/${dailyPolicyRowId}`)

export const apiSortDailyPolicyRows = (dailyPolicyId: string, sort: ISortDailyPolicyRow[]) =>
  request<void>('post', `${baseUrl}/${dailyPolicyId}/sort`, { sort })

export const apiSaveDailyPolicyRow = (dailyPolicyId: string, row: ISaveDailyPolicyRow) =>
  request<{ id: string } | undefined>('post', `${baseUrl}/${dailyPolicyId}/row`, row)

export const apiDeleteDailyPolicyRow = (dailyPolicyId: string, id: string) =>
  request<void>('delete', `${baseUrl}/${dailyPolicyId}/row/${id}`)
