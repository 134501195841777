import { setCompanyOffices, setCompanyOfficesLoading } from '../reducers'
import { apiGetOfficeCompanies } from '../api/office.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchOfficeCompanies = (
  officeId: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!officeId) return

    const loading = getState().master.companyOfficesLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const data = Object.values(getState().master.companyOffices?.entities || {})
      const hasData = data.some(co => co?.officeId === officeId)
      if (hasData) return
    }

    try {
      dispatch(setCompanyOfficesLoading(true))
      const { result, status } = await apiGetOfficeCompanies(officeId)
      if (status) {
        dispatch(setCompanyOffices(result))
      }
    } finally {
      dispatch(setCompanyOfficesLoading(false))
    }
  }
}
