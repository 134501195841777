import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { CtExpenseTypeState } from '../types'

export const selectCtExpenseTypes = createSelector(
  (state: StoreState) => state.claim.ctExpenseTypes,
  ctExpenseTypesState =>
    memoize((claimTypeId: string) => {
      return (Object.values(ctExpenseTypesState[claimTypeId]?.entities || {}) as CtExpenseTypeState[]) || []
    })
)
