import React, { CSSProperties, FC, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { ColumnsType, Drawer, Link, SecondaryText, Space, Table, Tag } from '~/core-components'
import { EmEducationRequestState } from '../../../../types'
import { useIsMountedRef } from '~/hooks'
import { EmEducationRequestApprovalDrawer } from './EmEducationRequestApprovalDrawer'
import { selectEmEducationRequests } from '~/features/employee/selectors'
import { selectQualificationKeyvalues } from '~/features/master'

export interface EmEducationRequestsProps {
  employeeId?: string
  visible: boolean
  onClose: () => void
}

interface ApprovalDrawerState {
  data?: EmEducationRequestState
  visible: boolean
}

const DEFAULT_APPROVAL_DRAWER_STATE: ApprovalDrawerState = { visible: false }
const labelQualificationStyle: CSSProperties = { lineHeight: 1.1 }
const labelMajorStyle: CSSProperties = { fontSize: 12 }

export const EmEducationRequestsDrawer: FC<EmEducationRequestsProps> = ({ employeeId, visible, onClose }) => {
  const data = useSelector(selectEmEducationRequests)(employeeId)
  const qualifications = useSelector(selectQualificationKeyvalues)

  const [approvalDrawerState, setApprovalDrawerState] = useState<ApprovalDrawerState>(DEFAULT_APPROVAL_DRAWER_STATE)
  const isMountedRef = useIsMountedRef()

  const handleView = useCallback(async (request: EmEducationRequestState) => {
    setApprovalDrawerState({ data: request, visible: true })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    typeof onClose === 'function' && onClose()
  }, [onClose])

  const handleCloseApprovalDrawer = useCallback(() => {
    if (isMountedRef.current) setApprovalDrawerState(DEFAULT_APPROVAL_DRAWER_STATE)
  }, [isMountedRef])

  const columns: ColumnsType<EmEducationRequestState> = [
    {
      title: 'Qualification',
      key: 'qualificationIdNew',
      dataIndex: 'qualificationIdNew',
      render: (value: string, record) => (
        <>
          <div style={labelQualificationStyle}>
            <Space>
              {qualifications[value]?.value}
              {record.isHighestNew && <Tag type="primary">highest</Tag>}
              {record.recordStatus === 'new_request' && <Tag type="secondary">new (pending)</Tag>}
              {record.recordStatus === 'has_request' && <Tag type="secondary">pending</Tag>}
            </Space>
          </div>
          <SecondaryText style={labelMajorStyle}>{record.majorNew}</SecondaryText>
        </>
      )
    },
    {
      title: 'Institute',
      key: 'instituteNew',
      dataIndex: 'instituteNew',
      width: 180
    },
    {
      title: 'Graduation year',
      key: 'graduationYearNew',
      dataIndex: 'graduationYearNew',
      width: 120,
      render: (value: string, record) => {
        const toYear = record.endDateNew ? moment(record.endDateNew).format('YYYY') : ''
        return value || toYear
      }
    },
    {
      key: 'action',
      align: 'right',
      render: (value: string, record) => (
        <Link size="small" onClick={() => handleView(record)}>
          view
        </Link>
      )
    }
  ]

  return (
    <>
      <Drawer
        open={visible}
        title="Education request"
        onClose={() => handleCloseDrawer()}
        width={720}
        className="em-education-requests-drawer"
      >
        <Table rowKey="id" dataSource={data} columns={columns} pagination={false}></Table>
      </Drawer>
      <EmEducationRequestApprovalDrawer {...approvalDrawerState} onClose={handleCloseApprovalDrawer} />
    </>
  )
}
