import { setKeyvalue } from '~/features/master'
import { showError, showSuccess } from '~/utils'
import { ThunkResult } from '~/types/store'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { apiUpdateShiftRole } from '../api/shift-role.api'
import { IShiftRole, ShiftRoleState } from '../types'
import { setShiftRole } from '../reducers'

export const updateShiftRole = (id: string, before: IShiftRole, request: IShiftRole): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const after = { ...before, ...request } as IShiftRole
    const patch = generateJsonPatch<IShiftRole>(before, after)
    const { status, errors, message, errorData } = await apiUpdateShiftRole(id, patch)
    if (status) {
      const shiftRole = getState().attendance.shiftRoles?.entities[id]
      const updated: ShiftRoleState = {
        ...(shiftRole as ShiftRoleState),
        ...after
      }
      dispatch(setShiftRole(updated))
      dispatch(
        setKeyvalue({
          kv: 'shiftRole',
          data: { key: updated.id, value: updated.name, inactiveDate: updated.inactiveDate }
        })
      )

      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
