import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { SSClaimRecordApprovalHistoryState } from '../types'

export const selectMyClaimRecordApprovalHistories = createSelector(
  (state: StoreState) => state.myClaim.myClaimRecordApprovalHistories,
  myClaimRecordApprovalHistoriesState =>
    memoize((claimRecordId: string) => {
      return Object.values(
        myClaimRecordApprovalHistoriesState[claimRecordId]?.entities || {}
      ) as SSClaimRecordApprovalHistoryState[]
    })
)
