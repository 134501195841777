import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchClaimEntitlementRecords } from '../actions'
import { selectClaimEntitlementRecords } from '../selectors/select-claim-entitlement-records'
import { ClaimEntitlementRecordState } from '../types'

export const useClaimEntitlementRecords = (
  periodYear: number,
  claimTypeId: string,
  employeeId: string,
  paymentStatuses: string[]
): [ClaimEntitlementRecordState[], boolean] => {
  const emCtId = `${employeeId}||${claimTypeId}`
  const data = useSelector(selectClaimEntitlementRecords)(
    employeeId,
    claimTypeId,
    paymentStatuses
  ) as ClaimEntitlementRecordState[]
  const loading = useSelector((state: StoreState) => state.claim.claimEntitlementRecordsLoading[emCtId])
  const refetch = useSelector((state: StoreState) => state.claim.claimEntitlementRecordsRefetch)

  useEffect(() => {
    dispatch(fetchClaimEntitlementRecords(periodYear, claimTypeId, employeeId, paymentStatuses))
  }, [periodYear, claimTypeId, employeeId, paymentStatuses, refetch])

  return [data, loading]
}
