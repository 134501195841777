import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { CtProrationMState } from '../types'

export const selectCtProrationM = createSelector(
  (state: StoreState) => state.claim.ctProrationM,
  ctProrationMState =>
    memoize((claimTypeId: string): CtProrationMState[] => {
      const ctProrationM = Object.values(ctProrationMState[claimTypeId]?.entities || {}) as CtProrationMState[]
      return ctProrationM.sort((a, b) => {
        return a!.dayFrom - b!.dayFrom
      })
    })
)
