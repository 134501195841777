import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { EmRequestState } from '../types'
import { EmInfoType } from '~/constants'

export const selectPersonalEmRequests = createSelector(
  (state: StoreState) => state.employee.emRequests,
  emRequestsState =>
    memoize((employeeId?: string) => {
      if (!employeeId) return []

      const emRequests = Object.values(emRequestsState[employeeId]?.entities || {}) as EmRequestState[]
      return emRequests.filter(er => er.emInfoType === EmInfoType.personal)
    })
)
