import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { PayRunState } from '../types'

export const selectPayRunsByPeriod = createSelector(
  (state: StoreState) => state.payroll.payRuns,
  payRunsState =>
    memoize(
      (payPeriods: string[], payRunTypes?: string[]) => {
        return (Object.values(payRunsState?.entities || {}) as PayRunState[])
          .filter(pr => payPeriods.includes(pr.payPeriod) && (payRunTypes?.includes(pr.payRunType) || !payRunTypes))
          .sort((a, b) => {
            const aPayDate = new Date(a!.payDate).getTime()
            const bPayDate = new Date(b!.payDate).getTime()
            return bPayDate - aPayDate
          })
      },
      (payPeriods: string[], payRunTypes?: string[]) => `${payPeriods.join(',')}||${payRunTypes?.join(',')}`
    )
)
