import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { apiUpdateDailyRecord } from '../api/daily-record.api'
import { DailyRecordState, IUpdateDailyRecord } from '../types'
import { refetchDailyRecords, setDailyRecord } from '../reducers'

export const updateDailyRecord = (
  id: string,
  before: IUpdateDailyRecord,
  request: IUpdateDailyRecord
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const after = { ...before, ...request } as IUpdateDailyRecord
    const patch = generateJsonPatch<IUpdateDailyRecord>(before, after)
    const { status, errors, message, errorData } = await apiUpdateDailyRecord(id, patch)

    if (status) {
      const dailyRecord = getState().attendance.dailyRecords?.entities[id]
      const updated = { ...(dailyRecord as DailyRecordState), ...after }
      dispatch(setDailyRecord(updated))
      dispatch(refetchDailyRecords())
      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
