import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import persistReducer from 'redux-persist/es/persistReducer'
import {
  myPayrollPersistConfig,
  MyPayrollRootState,
  SSIrasFormViewState,
  SSPayslipState,
  SSPayslipYearState
} from './types'

const myPayslipYearsAdapter = createEntityAdapter<SSPayslipYearState>({ selectId: model => model.year })
const myPayslipsAdapter = createEntityAdapter<SSPayslipState>()

const myPayslipYearsInitialState = myPayslipYearsAdapter.getInitialState()
const myPayslipsInitialState = myPayslipsAdapter.getInitialState()

const initialState: MyPayrollRootState = {
  myPayslips: myPayslipsInitialState,
  myPayslipsLoading: false,
  myPayslipYears: myPayslipYearsInitialState,
  myPayslipYearsLoading: false,
  myIrasFormsView: { count: 0, data: [] },
  myIrasFormsViewLoading: false
}

const myPayrollSlice = createSlice({
  name: 'mypayroll',
  initialState,
  reducers: {
    setMyPayslipsLoading: (state, action: PayloadAction<boolean>) => {
      state.myPayslipsLoading = action.payload
    },
    replaceMyPayslips: (state, action: PayloadAction<SSPayslipState[]>) => {
      myPayslipsAdapter.setAll(state.myPayslips, action.payload)
    },
    setMyPayslipYearsLoading: (state, action: PayloadAction<boolean>) => {
      state.myPayslipYearsLoading = action.payload
    },
    replaceMyPayslipYears: (state, action: PayloadAction<SSPayslipYearState[]>) => {
      myPayslipYearsAdapter.setAll(state.myPayslipYears, action.payload)
    },
    setMyIrasFormsViewLoading: (state, action: PayloadAction<boolean>) => {
      state.myIrasFormsViewLoading = action.payload
    },
    replaceMyIrasFormsView: (state, action: PayloadAction<SSIrasFormViewState>) => {
      state.myIrasFormsView = action.payload
    }
  }
})

export const {
  setMyPayslipsLoading,
  replaceMyPayslips,
  setMyPayslipYearsLoading,
  replaceMyPayslipYears,
  setMyIrasFormsViewLoading,
  replaceMyIrasFormsView
} = myPayrollSlice.actions

export const myPayrollReducers = {
  myPayroll: persistReducer<MyPayrollRootState>(myPayrollPersistConfig, myPayrollSlice.reducer)
}
