import React, { FC, useState, useCallback, useEffect, ChangeEvent } from 'react'
import { useHistory } from 'react-router-dom'
import { Form, Input, Space } from '~/core-components'
import { Col, CurrencyKeyValues, DrawerForm, MonthKeyValues, Row, SysOptions } from '~/components'
import { dispatch } from '~/stores/store'
import { useFocus } from '~/hooks/use-focus'
import { ActionResult, Errors } from '~/types/store'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { ClaCurrencyCode, ClaEntitlementType, ClaExpenseSubmissionType, ClaPeriodBasis } from '~/constants'
import { addClaimType } from '../../../../claim/actions'
import { IAddClaimType } from '../../../types'
import { KeyValue } from '~/types/common'

export interface AddClaimTypeDrawerProps {
  visible: boolean
  onClose: () => void
}

const EMPTY_FORM_DATA: IAddClaimType = {
  name: '',
  periodBasis: '',
  periodStart: 1,
  periodDuration: 1,
  entitlementType: '',
  currencyCode: ClaCurrencyCode.sgd,
  entitlementAmount: 0,
  earnedBasis: '',
  prorationType: '',
  expenseSubmissionType: ClaExpenseSubmissionType.Single
}

export const AddClaimTypeDrawer: FC<AddClaimTypeDrawerProps> = ({ visible, onClose }: AddClaimTypeDrawerProps) => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<IAddClaimType>(EMPTY_FORM_DATA)
  const [focusRef, setFocus] = useFocus(true)
  const [errors, setErrors] = useState<Errors>()

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  useEffect(() => {
    setFormData(EMPTY_FORM_DATA)
  }, [])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      result = await dispatch(addClaimType(formData))
    } finally {
      setLoading(false)
    }
    if (result?.errors) {
      setErrors(result.errors)
    }
    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_FORM_DATA)

      if (result?.result?.id) {
        const claimTypeId = result.result.id
        history.push(`${SETTINGS_ROUTES.claimType.replace(':id', claimTypeId)}`)
      }
    }
  }, [formData, onClose, history])

  return (
    <DrawerForm
      open={visible}
      title="Add claim type"
      onClose={onClose}
      confirmLoading={loading}
      width={500}
      formId="form-claim-type"
    >
      <Form id="form-claim-type" onFinish={handleOk}>
        <Row>
          <Col span={24}>
            <Form.Item label="Name" validateStatus={errors?.name ? 'error' : ''} help={errors?.name}>
              <Input
                ref={focusRef}
                value={formData.name}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ name: event.target.value })}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Currency"
              validateStatus={errors?.currencyCode ? 'error' : ''}
              help={errors?.currencyCode}
              tooltip={{
                title:
                  'If the claim is paid through payroll, only SGD is supported. For claims paid directly outside the system, other currencies are accepted.',
                icon: (
                  <span>
                    <i className="fal fa-circle-info" />
                  </span>
                )
              }}
            >
              <CurrencyKeyValues
                dropdownMatchSelectWidth={false}
                value={formData.currencyCode}
                onChange={(currencyCode: string) => handleFormDataChange({ currencyCode })}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Expense submission format"
              validateStatus={errors?.expenseSubmissionType ? 'error' : ''}
              help={errors?.expenseSubmissionType}
              tooltip={{
                title: 'Expense submission format cannot be changed once claim type is created',
                icon: (
                  <span>
                    <i className="fal fa-circle-info" />
                  </span>
                )
              }}
            >
              <SysOptions
                type="cla_expense_submission_type"
                value={formData.expenseSubmissionType}
                onChange={(expenseSubmissionType: string) => handleFormDataChange({ expenseSubmissionType })}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Period basis"
              validateStatus={errors?.periodBasis ? 'error' : ''}
              help={errors?.periodBasis}
            >
              <SysOptions
                type="cla_period_basis"
                value={formData.periodBasis}
                onChange={(periodBasis: string) =>
                  handleFormDataChange({ periodBasis, periodStart: 1, periodDuration: 1 })
                }
              />
            </Form.Item>
          </Col>
          <Col span={12} hidden={formData.periodBasis !== ClaPeriodBasis.custom}>
            <Form.Item
              label="Period start"
              validateStatus={errors?.periodStart ? 'error' : ''}
              help={errors?.periodStart}
            >
              <MonthKeyValues
                value={formData.periodStart}
                onChange={(periodStart: string) => handleFormDataChange({ periodStart })}
              />
            </Form.Item>
          </Col>
          <Col span={12} hidden={formData.periodBasis !== ClaPeriodBasis.custom}>
            <Form.Item
              label="Duration"
              validateStatus={errors?.periodDuration ? 'error' : ''}
              help={errors?.periodDuration}
            >
              <Space>
                <Input.Number
                  value={formData.periodDuration}
                  onChange={(periodDuration: number | null) => handleFormDataChange({ periodDuration })}
                ></Input.Number>
                <span>year(s)</span>
              </Space>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Entitlement calculation"
              validateStatus={errors?.entitlementType ? 'error' : ''}
              help={errors?.entitlementType}
              tooltip={{
                title: 'Entitlement calculation type cannot be changed once claim type is created',
                icon: (
                  <span>
                    <i className="fal fa-circle-info" />
                  </span>
                )
              }}
            >
              <SysOptions
                type="cla_entitlement_type"
                value={formData.entitlementType}
                onChange={(entitlementType: string) => handleFormDataChange({ entitlementType })}
                onFilter={(value: KeyValue | undefined) => ClaEntitlementType.PerDiem !== value?.key}
              />
            </Form.Item>
          </Col>
          {formData.entitlementType === ClaEntitlementType.PerPeriod && (
            <>
              <Col span={24}>
                <Form.Item
                  label="Accrual calculation"
                  validateStatus={errors?.earnedBasis ? 'error' : ''}
                  help={errors?.earnedBasis}
                >
                  <SysOptions
                    type="cla_earned_basis"
                    value={formData.earnedBasis}
                    onChange={(earnedBasis: string) => handleFormDataChange({ earnedBasis })}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Proration type"
                  validateStatus={errors?.prorationType ? 'error' : ''}
                  help={errors?.prorationType}
                >
                  <SysOptions
                    type="cla_proration_type"
                    value={formData.prorationType}
                    onChange={(prorationType: string) => handleFormDataChange({ prorationType })}
                  />
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
      </Form>
    </DrawerForm>
  )
}
