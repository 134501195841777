import { showSuccess, showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { apiAddDailyPolicy } from '../api/daily-policy.api'
import { DailyPolicyState, IAddDailyPolicy } from '../types'
import { setDailyPolicy } from '../reducers'

export const addDailyPolicy = (policy: IAddDailyPolicy): ThunkResult<void> => {
  return async (dispatch): Promise<ActionResult> => {
    const { status, result, errors, message, errorData } = await apiAddDailyPolicy(policy)
    if (status) {
      const newPolicy: DailyPolicyState = { ...policy, id: result.id }
      dispatch(setDailyPolicy(newPolicy))
      showSuccess('Added')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
