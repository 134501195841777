import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { ManagerCategory, ManagerState } from '../types'
import { fetchManagers } from '../actions'
import { selectManagers } from '../selectors'

export const useManagers = (category: ManagerCategory): [ManagerState[], boolean] => {
  const managers = useSelector(selectManagers)(category)
  const loading = useSelector((state: StoreState) => state.manager.managersLoading[category] || false)

  useEffect(() => {
    dispatch(fetchManagers(category))
  }, [category])

  return [managers, loading]
}
