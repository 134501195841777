import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchOffice } from '../actions'
import { selectOfficeById } from '../reducers'
import { OfficeState } from '../types'

export const useOffice = (id: string): [OfficeState | undefined, boolean] => {
  const data = useSelector((state: StoreState) => selectOfficeById(state, id))
  const loading = useSelector((state: StoreState) => state.master.officesLoading)

  useEffect(() => {
    dispatch(fetchOffice(id))
  }, [id])

  return [data, loading]
}
