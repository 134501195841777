import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  Screen,
  fetchSysSelectionFields,
  fetchSysSelectionScreens,
  selectSysSelectionFieldsOptGroup
} from '~/features/selection'
import { fetchEmpKeyvalues } from '~/features/master'
import { StoreState } from '~/types/store'
import { OptionGroup } from '~/types/common'
import { dispatch } from '~/stores/store'

export const useSysSelectionFieldsOptGroup = (screenCode: Screen): [OptionGroup[], boolean] => {
  const selectionWGroup = useSelector(selectSysSelectionFieldsOptGroup)(screenCode)
  const refetch = useSelector((state: StoreState) => state.selection.sysSelectionFieldsRefetch)
  const loading = useSelector((state: StoreState) => state.selection.sysSelectionFieldsLoading[screenCode] || false)

  useEffect(() => {
    dispatch(fetchSysSelectionScreens(screenCode, { strategy: 'when-empty' }))
    dispatch(fetchSysSelectionFields(screenCode, { strategy: 'when-empty' }))
  }, [screenCode, refetch])

  useEffect(() => {
    dispatch(fetchEmpKeyvalues('sysselection', undefined, undefined, { strategy: 'when-empty' }))
  }, [])

  return [selectionWGroup, loading]
}
