import { showSuccess, showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { apiAddScheduleRecordByRole } from '../api/ro-schedule-record.api'
import { refetchROScheduleRecordsViewRow } from '../reducers'
import { IROAddScheduleRecord } from '../types'

export const addScheduleRecordByRole = (role: string, schedule: IROAddScheduleRecord): ThunkResult<void> => {
  return async (dispatch): Promise<ActionResult> => {
    const { status, result, errors, message, errorData } = await apiAddScheduleRecordByRole(role, schedule)
    if (status) {
      dispatch(refetchROScheduleRecordsViewRow(schedule.employeeId))
      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
