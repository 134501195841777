import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { apiUpdateEmStatutoryExempt } from '../api/em-statutory-exempt.api'
import { EmStatutoryExemptState, IEmStatutoryExemptInfo } from '../types'
import { setEmStatutoryExempt } from '../reducers'
import { mapEmStatutoryExemptInfoToEmStatutoryExemptInfoState } from '../types/em-details.mapper'

export const updateEmStatutoryExempt = <T extends IEmStatutoryExemptInfo>(
  employeeId: string,
  id: string,
  before: T,
  request: T
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!employeeId || !id) return

    const after = { ...before, ...request } as T
    const patch = generateJsonPatch<T>(before, after)
    const { status, errors, message, errorData } = await apiUpdateEmStatutoryExempt(employeeId, id, patch)

    if (status) {
      const emStatutoryExempt = getState().employee.emStatutoryExempts[employeeId]?.entities[id]
      const updated = {
        ...(emStatutoryExempt as EmStatutoryExemptState),
        ...mapEmStatutoryExemptInfoToEmStatutoryExemptInfoState(after)
      }
      dispatch(setEmStatutoryExempt({ employeeId, data: updated }))

      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
