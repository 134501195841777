import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { EmProjectState } from '../types'

export const selectEmProjects = createSelector(
  (state: StoreState) => state.employee.emProjects,
  emProjects =>
    memoize((employeeId?: string) => {
      if (!employeeId) return []
      return Object.values(emProjects[employeeId]?.entities || {}) as EmProjectState[]
    })
)
