import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'

export const selectPayItemBasesById = createSelector(
  (state: StoreState) => state.master.payItemBases,
  payItemBasesState =>
    memoize((id: string) => {
      return Object.values(payItemBasesState[id]?.entities || {}) || []
    })
)
