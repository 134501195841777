import { FC, useCallback, useMemo, useState } from 'react'
import moment from 'moment-timezone'
import { Card, ColumnsType, Form, Link, Space, Table, Text, Tooltip } from '~/core-components'
import { Col, DateCustomPicker, DateCustomPickerItem, Gps, Row } from '~/components'
import { TimeLogStatusTag, useLocationsDict, useProjectsDict, useShiftsDict } from '~/features/attendance'
import { formatDate } from '~/utils'
import { SSTimeLogState } from '../../types'
import { useMyTimeLogs } from '../../hooks'
import { MyTimeLogEditDrawer } from '../MyTimeLogEdit/MyTimeLogEditDrawer'
import './MyTimeLogs.less'

interface MyTimeLogsProps {}

interface DrawerState {
  visible: boolean
  data?: SSTimeLogState
  editing?: boolean
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }
const START_DATE = moment().startOf('isoWeek').format('YYYY-MM-DD')
const END_DATE = moment().endOf('isoWeek').format('YYYY-MM-DD')

const PERIOD_OPTIONS: DateCustomPickerItem[] = [
  {
    key: 'this_week',
    value: 'This week',
    startDate: START_DATE,
    endDate: END_DATE
  },
  {
    key: 'last_week',
    value: 'Last week',
    startDate: moment().startOf('isoWeek').add(-1, 'week').format('YYYY-MM-DD'),
    endDate: moment().endOf('isoWeek').add(-1, 'week').format('YYYY-MM-DD')
  },
  {
    key: 'this_month',
    value: 'This month',
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').format('YYYY-MM-DD')
  },
  {
    key: 'last_month',
    value: 'Last month',
    startDate: moment().startOf('month').add(-1, 'month').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').add(-1, 'month').format('YYYY-MM-DD')
  }
]

export const MyTimeLogs: FC<MyTimeLogsProps> = () => {
  const [startDate, setStartDate] = useState<string | null>(START_DATE)
  const [endDate, setEndDate] = useState<string | null>(END_DATE)
  const [timeLogs, loading] = useMyTimeLogs(startDate, endDate)

  const [locationDict] = useLocationsDict()
  const [projectDict] = useProjectsDict()
  const [shiftDict] = useShiftsDict()

  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)

  const handleDateRangeChange = useCallback((startDate: string | null, endDate: string | null) => {
    setStartDate(startDate)
    setEndDate(endDate)
  }, [])

  const handleEditTimeLog = useCallback((record: SSTimeLogState) => {
    setDrawerState({
      visible: true,
      data: record,
      editing: false
    })
  }, [])

  const handleCloseDrawer = useCallback(() => setDrawerState(DEFAULT_DRAWER_STATE), [])

  const columns: ColumnsType<SSTimeLogState> = useMemo(
    () => [
      {
        title: 'Time (in / out)',
        key: 'timeIn',
        dataIndex: 'timeIn',
        width: 260,
        render: (_, record) => {
          const isRequest = record.timeInRequest != null || record.timeOutRequest != null
          const timeIn = record.timeInRequest ?? record.timeIn
          const timeInObj = timeIn ? moment(timeIn) : undefined
          const timeOut = record.timeOutRequest ?? record.timeOut
          const timeOutObj = timeOut ? moment(timeOut) : undefined
          const isNextDayOutTime = timeOutObj?.isAfter(timeInObj, 'date')

          return (
            <Link onClick={() => handleEditTimeLog(record)}>
              <Row>
                <Col flex="110px">{record.timeIn ? formatDate(record.timeIn) : '-'}</Col>
                <Col flex="1">
                  <Space>
                    {timeInObj ? timeInObj.format('HH:mm') : '-'}
                    {timeOutObj ? timeOutObj.format(' - HH:mm') : ' - '}
                    {isNextDayOutTime && (
                      <Tooltip title={timeOutObj?.format('DD MMM YYYY')}>
                        <i className="fal fa-calendar-plus" />
                      </Tooltip>
                    )}
                    {isRequest && '*'}
                  </Space>
                </Col>
              </Row>
            </Link>
          )
        }
      },
      {
        title: 'Shift',
        key: 'shiftId',
        dataIndex: 'shiftId',
        width: 150,
        render: (value: string) => (value ? shiftDict[value]?.name : '-')
      },
      {
        title: 'Location',
        key: 'locationId',
        dataIndex: 'locationId',
        width: 150,
        render: (value: string, record) => (
          <>
            {locationDict[value]?.name}
            {record.locationId !== record.outLocationId && record.outLocationId
              ? ` / ${locationDict[record.outLocationId]?.name}`
              : ''}
          </>
        )
      },
      {
        title: 'GPS (in)',
        key: 'inLatitude',
        dataIndex: 'inLatitude',
        width: 120,
        render: (value: string, record: SSTimeLogState) => (
          <Text size="small">
            <Gps lat={record.inLatitude} lng={record.inLongitude} />
          </Text>
        )
      },
      {
        title: 'GPS (out)',
        key: 'ouLatitude',
        dataIndex: 'ouLatitude',
        width: 120,
        render: (value: string, record: SSTimeLogState) => (
          <Text size="small">
            <Gps lat={record.outLatitude} lng={record.outLongitude} />
          </Text>
        )
      },
      {
        title: 'Project',
        key: 'projectId',
        dataIndex: 'projectId',
        width: 150,
        render: (value: string) => projectDict[value]?.name
      },
      {
        title: 'Notes',
        key: 'notes',
        dataIndex: 'notes',
        width: 150
      },
      {
        title: 'Status',
        key: 'approvalStatus',
        dataIndex: 'approvalStatus',
        width: 150,
        render: (value: string) => <TimeLogStatusTag status={value} />
      }
    ],
    [locationDict, shiftDict, projectDict, handleEditTimeLog]
  )

  return (
    <div className="my-time-logs">
      <Card
        extra={
          <Form.Item label="Show" colon={false}>
            <DateCustomPicker defaultValue="this_week" options={PERIOD_OPTIONS} onChange={handleDateRangeChange} />
          </Form.Item>
        }
      >
        <Table rowKey="id" dataSource={timeLogs} pagination={false} columns={columns} loading={loading}></Table>
        <MyTimeLogEditDrawer {...drawerState} onClose={handleCloseDrawer} />
      </Card>
    </div>
  )
}
