import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchMyPayslips } from '../actions'
import { SSPayslipFilter, SSPayslipState } from '../types'
import { selectMyPayslips } from '../selectors'

export const useMyPayslips = (filter?: SSPayslipFilter): [SSPayslipState[], boolean] => {
  const payslips = useSelector(selectMyPayslips)
  const loading = useSelector((state: StoreState) => state.myPayroll.myPayslipsLoading)

  useEffect(() => {
    dispatch(fetchMyPayslips(filter))
  }, [filter])

  return [payslips, loading]
}
