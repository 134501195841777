import React, { FC } from 'react'
import { default as AntCheckbox, CheckboxProps as AntCheckboxProps } from 'antd/lib/checkbox'
import { CheckboxChangeEvent as AntCheckboxChangeEvent } from 'antd/lib/checkbox'
import classNames from 'classnames'
import 'antd/es/checkbox/style'
import './Checkbox.less'

export interface CheckboxChangeEvent extends AntCheckboxChangeEvent {}
export interface CheckboxProps extends AntCheckboxProps {
  readOnly?: boolean
}

export const Checkbox: FC<CheckboxProps> = ({ readOnly, className, ...props }: CheckboxProps) => {
  const classes = classNames({ [`checkbox--readonly`]: readOnly }, { [`${className}`]: className })
  return <AntCheckbox {...props} className={classes} onChange={readOnly ? undefined : props.onChange} />
}
