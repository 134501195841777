import { showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { UploadFile } from '~/core-components'
import { apiUploadPayImport } from '../api/payimport.api'
import { replacePayImport } from '../reducers'

export const uploadPayImport = (byDates: boolean, file: UploadFile): ThunkResult<void> => {
  return async dispatch => {
    const { status, result, errors, message, errorData } = await apiUploadPayImport(byDates, file)
    if (status) {
      dispatch(replacePayImport(result))
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors, result }
  }
}
