import React, { ChangeEvent, FC, useState, useCallback, useEffect, CSSProperties } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import confirm from 'antd/lib/modal/confirm'
import { Checkbox, CheckboxChangeEvent, Form, Input } from '~/core-components'
import { Col, DrawerForm, EmpKeyValues, Row } from '~/components'
import { selectKeyvaluesById } from '~/features/master'
import { dispatch } from '~/stores/store'
import { addEmAddress, deleteEmAddress, updateEmAddress } from '../../../actions'
import { IEmAddressInfo, EmAddressState } from '../../../types'
import { mapEmAddressStateToEmAddressInfo } from '../../../types/em-details.mapper'
import { ActionResult, Errors } from '~/types/store'
import { useFocus } from '~/hooks/use-focus'
import { formatAddress } from '../../../../master/util'
import { IAddress } from '../../../../master/types/common'

export interface MutateEmAddressDrawerProps {
  visible: boolean
  employeeId?: string
  data?: EmAddressState
  onClose: () => void
}

const EMPTY_FORM_DATA: IEmAddressInfo = {
  startDate: moment().format('YYYY-MM-DD'),
  isMain: true,
  block: '',
  street: '',
  level: '',
  unit: '',
  city: '',
  state: '',
  postalCode: '',
  countryCode: ''
}

const checkboxStyle: CSSProperties = { marginLeft: 10 }
const inputLevelStyle: CSSProperties = { width: '40%', marginRight: -1 }
const inputUnitStyle: CSSProperties = { width: '60%' }

export const MutateEmAddressDrawer: FC<MutateEmAddressDrawerProps> = ({
  visible,
  employeeId,
  data,
  onClose
}: MutateEmAddressDrawerProps) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<IEmAddressInfo>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const [focusRef, setFocus] = useFocus(true)
  const countries = useSelector(selectKeyvaluesById)('country')

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  useEffect(() => {
    if (data) {
      const { startDate, isMain, block, street, level, unit, city, state, postalCode, countryCode } = data
      setFormData({ startDate, isMain, block, street, level, unit, city, state, postalCode, countryCode })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      const updated = { ...formData, ...updates }
      setFormData(updated)
    },
    [formData]
  )

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      if (data) {
        result = await dispatch(
          updateEmAddress(data.employeeId, data.id, mapEmAddressStateToEmAddressInfo(data), formData)
        )
      } else if (employeeId) {
        result = await dispatch(addEmAddress(employeeId, formData))
      }
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_FORM_DATA)
    }
  }, [employeeId, data, formData, onClose])

  const handleDelete = useCallback(
    (emAddress: EmAddressState | undefined) => {
      if (employeeId && emAddress) {
        const { id, block, street, level, unit, city, state, postalCode, countryCode } = emAddress
        const countryName = countries[countryCode || '']?.value || ''
        const address = formatAddress(
          {
            block,
            street,
            level,
            unit,
            building: '',
            city,
            state,
            postalCode,
            countryCode
          } as IAddress,
          countryName
        )
        confirm({
          title: 'Delete address',
          content: `Do you want to delete address "${address.addressLine1}"?`,
          onOk: async () => {
            const result: ActionResult | undefined = await dispatch(deleteEmAddress(employeeId, id))
            if (result?.errors) {
              setErrors(result.errors)
            }

            if (!result?.errors) {
              typeof onClose === 'function' && onClose()
            }
          },
          okText: 'Delete',
          okType: 'danger'
        })
      }
    },
    [employeeId, onClose, countries]
  )

  return (
    <DrawerForm
      open={visible}
      title="Address"
      onClose={onClose}
      confirmLoading={loading}
      width={500}
      showDelete={data ? true : false}
      onDelete={() => handleDelete(data)}
      formId="form-address"
    >
      <Form id="form-address" onFinish={handleOk}>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Effective date"
              validateStatus={errors?.startDate ? 'error' : ''}
              help={errors?.startDate}
            >
              <Input.Date
                ref={focusRef}
                value={formData.startDate ? moment(formData.startDate) : undefined}
                onChange={(value: moment.Moment | null) =>
                  handleFormDataChange({ startDate: value?.format('YYYY-MM-DD') })
                }
              />
              <Checkbox
                checked={formData.isMain}
                onChange={(event: CheckboxChangeEvent) => handleFormDataChange({ isMain: event.target.checked })}
                style={checkboxStyle}
              >
                Main address
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={15}>
          <Col span={4}>
            <Form.Item label="Block no." validateStatus={errors?.block ? 'error' : ''} help={errors?.block}>
              <Input
                value={formData.block}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ block: event.target.value })}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Street name" validateStatus={errors?.street ? 'error' : ''} help={errors?.street}>
              <Input
                value={formData.street}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleFormDataChange({ street: event.target.value })
                }
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Floor - Unit no."
              validateStatus={errors?.level || errors?.unit ? 'error' : ''}
              help={(errors?.level, errors?.unit)}
            >
              <Input.Group>
                <Input
                  style={inputLevelStyle}
                  value={formData.level}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleFormDataChange({ level: event.target.value })
                  }
                />
                <Input
                  addonBefore="-"
                  style={inputUnitStyle}
                  value={formData.unit}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleFormDataChange({ unit: event.target.value })
                  }
                />
              </Input.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col span={12}>
            <Form.Item label="City" validateStatus={errors?.city ? 'error' : ''} help={errors?.city}>
              <Input
                value={formData.city}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ city: event.target.value })}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="State" validateStatus={errors?.state ? 'error' : ''} help={errors?.state}>
              <Input
                value={formData.state}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ state: event.target.value })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col span={12}>
            <Form.Item label="Postal code" validateStatus={errors?.postalCode ? 'error' : ''} help={errors?.postalCode}>
              <Input
                value={formData.postalCode}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleFormDataChange({ postalCode: event.target.value })
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Country" validateStatus={errors?.countryCode ? 'error' : ''} help={errors?.countryCode}>
              <EmpKeyValues
                id="country"
                value={formData.countryCode}
                onChange={(value: string) => handleFormDataChange({ countryCode: value })}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}
