import { showError, showSuccess } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { apiSendFeedback } from '../api/feedback.api'
import { IFeedback } from '../types'

export const sendFeedback = (feedback: IFeedback): ThunkResult<void> => {
  return async (): Promise<ActionResult> => {
    const { status, result, errors, message, errorData } = await apiSendFeedback({ ...feedback })
    if (status) {
      showSuccess('Thank you for your feedback!')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
