import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { GlobalState } from './types'

const initialState: GlobalState = {
  sidebarCollapsed: true
}

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setSidebarCollapsed: (state, action: PayloadAction<boolean>) => {
      state.sidebarCollapsed = action.payload
    }
  }
})

export const { setSidebarCollapsed } = globalSlice.actions

export const globalReducer = globalSlice.reducer

export const globalReducers = {
  global: globalReducer
}
