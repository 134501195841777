import React, { FC, useCallback, useState } from 'react'
import { Card, ColumnsType, PageHeader, Switch, Table } from '~/core-components'
import { DocumentTitle } from '~/components'
import { usePermissionGate } from '~/features/iam/hooks'
import { EmploymentConfig, Permission, PermissionAction } from '~/constants'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { showWarning } from '~/utils'
import { useEmploymentConfigs } from '~/features/master/hooks'
import { updateEmploymentConfig } from '~/features/master/actions'
import { EmploymentConfigState } from '../../../types'
import './EmploymentConfigs.less'

type EmploymentConfigTable = EmploymentConfigState

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: '',
    breadcrumbName: 'Employment fields'
  }
]

const notEditableConfigs = [
  EmploymentConfig.department,
  EmploymentConfig.job,
  EmploymentConfig.manager,
  EmploymentConfig.office
]

interface EmploymentConfigsProps {}

export const EmploymentConfigs: FC<EmploymentConfigsProps> = () => {
  const [employmentConfigs, loading] = useEmploymentConfigs()
  const [updatingVisible, setUpdatingVisible] = useState<string>()
  const canModify = usePermissionGate(Permission.employmentConfig, PermissionAction.Modify)

  const handleVisibleChange = useCallback(async (employmentConfig: EmploymentConfigState, isShown: boolean) => {
    if (notEditableConfigs.includes(employmentConfig.code)) {
      showWarning('This field cannot be hidden')
      return
    }

    setUpdatingVisible(employmentConfig.code)
    try {
      await dispatch(updateEmploymentConfig(employmentConfig.code, employmentConfig, { ...employmentConfig, isShown }))
    } finally {
      setUpdatingVisible(undefined)
    }
  }, [])

  const columns: ColumnsType<EmploymentConfigTable> = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name'
    },
    {
      title: 'Visible',
      key: 'isShown',
      dataIndex: 'isShown',
      align: 'center',
      width: 100,
      render: (value: boolean, record) => (
        <Switch
          checkedChildren="Shown"
          unCheckedChildren="Hidden"
          checked={record.isShown}
          loading={updatingVisible === record.code}
          disabled={!canModify}
          onChange={(checked: boolean) => {
            handleVisibleChange(record, checked)
          }}
        />
      )
    }
  ]

  return (
    <div id="employment-configs" className="employment-configs">
      <DocumentTitle title="Employment Fields" />
      <PageHeader title="Employment fields" containerId="employment-configs" breadcrumb={{ routes }} />
      <div className="employment-configs__body">
        <Card table>
          <Table
            rowKey="code"
            dataSource={employmentConfigs}
            pagination={false}
            columns={columns}
            loading={loading}
            rowClassName={record => (record.isShown ? 'employment-configs__table-row--shown' : '')}
          />
        </Card>
      </div>
    </div>
  )
}
