import React, { CSSProperties, FC } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Alert, ColumnsType, Spin, Table } from '~/core-components'
import { LoadingOutlined } from '@ant-design/icons'
import { StoreState } from '~/types/store'
import { formatMoney } from '~/utils'
import { ClaimImportSourceState } from '../../../../types'
import './ImportClaimRecord.less'

interface ImportClaimRecordProps {
  visible: boolean
  success?: boolean
}

const spinStyles: CSSProperties = { marginRight: 10 }

export const ImportClaimRecord: FC<ImportClaimRecordProps> = ({ visible, success }) => {
  const data = useSelector((state: StoreState) => state.payroll.claimImportValidate?.data || [])
  const processing = useSelector((state: StoreState) => state.payroll.payrollLoading)

  const columns: ColumnsType<ClaimImportSourceState> = [
    {
      title: 'Employee',
      key: 'employeeNo',
      dataIndex: 'employeeNo',
      render: (value: string, record) => `[${value}] ${record.employeeName}`
    },
    {
      title: 'Claim type',
      key: 'claimTypeName',
      dataIndex: 'claimTypeName'
    },
    {
      title: 'Expense Date',
      key: 'expenseDate',
      dataIndex: 'expenseDate',
      render: (value: string) => (value ? moment(value).format('DD MMM YYYY') : undefined)
    },
    {
      title: 'Amount',
      key: 'claimAmount',
      dataIndex: 'claimAmount',
      render: (value: number, record) => formatMoney(value, 2)
    },
    {
      title: 'Payroll item',
      key: 'payItemName',
      dataIndex: 'payItemName'
    }
  ]

  return (
    <div className="import-claim-record" hidden={!visible}>
      {success && (
        <Alert
          type="success"
          showIcon
          message={
            <>
              <div>Import successful</div>
              {processing ? (
                <div>
                  <Spin style={spinStyles} size="small" indicator={<LoadingOutlined spin />} />
                  <span>You may close this while we're calculating the affected employees' payroll</span>
                </div>
              ) : (
                <div>All the affected employees' payroll has been calculated</div>
              )}
            </>
          }
        />
      )}
      <Table rowKey="id" dataSource={data} columns={columns} pagination={false} />
    </div>
  )
}
