import { setOedRecordForm, setOedRecordFormLoading } from '../reducers'
import { ThunkResult } from '~/types/store'
import { apiGetOedRecord } from '../api/rpt-oed-record.api'

export const fetchOedRecordForm = (id: string): ThunkResult<void> => {
  return async dispatch => {
    try {
      dispatch(setOedRecordFormLoading(true))
      const { result, status } = await apiGetOedRecord(id)
      if (status) {
        dispatch(setOedRecordForm(result))
      }
    } finally {
      dispatch(setOedRecordFormLoading(false))
    }
  }
}
