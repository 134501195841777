import { useSelector } from 'react-redux'
import { selectMyLoginAndPermissions } from '../selectors'
import { useHasFeatureFlagClaim } from './use-has-feature-flag-claim'
import { useHasFeatureFlagLeave } from './use-has-feature-flag-leave'
import { useHasFeatureFlagPayroll } from './use-has-feature-flag-payroll'
import { useHasFeatureFlagAttendance } from './use-has-feature-flag-attendance'

export const usePermissionGate = (sysPermissionId: string | string[], action?: string | string[]): boolean => {
  const userInfo = useSelector(selectMyLoginAndPermissions)

  const hasFeatureFlagPayroll = useHasFeatureFlagPayroll(sysPermissionId)
  const hasFeatureFlagLeave = useHasFeatureFlagLeave(sysPermissionId)
  const hasFeatureFlagClaim = useHasFeatureFlagClaim(sysPermissionId)
  const hasFeatureFlagAttendance = useHasFeatureFlagAttendance(sysPermissionId)

  if (!hasFeatureFlagPayroll) return false
  if (!hasFeatureFlagLeave) return false
  if (!hasFeatureFlagClaim) return false
  if (!hasFeatureFlagAttendance) return false

  const isPermitted =
    userInfo?.isOwner ||
    userInfo?.isSuperUser ||
    userInfo.permissions.some(p => {
      const permissionIds = Array.isArray(sysPermissionId) ? sysPermissionId : [sysPermissionId]
      const actions = action ? (Array.isArray(action) ? action : [action]) : undefined
      return (
        permissionIds.includes(p.sysPermissionId) &&
        (action ? actions?.every(act => p.action.indexOf(act) >= 0) : p.action != null)
      )
    })

  return isPermitted
}
