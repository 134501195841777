import React, { FC, useCallback, useState } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { Form } from '~/core-components'
import { DrawerForm } from '~/components'
import { ViewCriteriaFieldValue, ViewName } from '~/features/selection'
import { Pagination } from '~/types/common'
import { EmSelection } from './EmSelection'
import './EmSelectionDrawer.less'

export interface EmSelectionDrawerProps {
  viewName: ViewName
  visible: boolean
  title?: string
  okText?: string
  employeeIds?: string[]
  onFetchData: (viewId: string, pagination: Pagination, search: string, pastResignees: boolean) => void
  onSelect: (employeeIds: string[]) => void
  onClose: () => void
  resetOnClose?: boolean
  resetTo?: ViewCriteriaFieldValue[]
}

interface EmSelectionForm {
  employeeIds: string[]
}

export const EMPTY_EM_SELECTION_FORM_DATA: EmSelectionForm = {
  employeeIds: []
}

export const EmSelectionDrawer: FC<EmSelectionDrawerProps> = ({
  viewName,
  visible,
  title = 'Select employees',
  okText = 'Select',
  employeeIds = [],
  onFetchData,
  onSelect,
  onClose,
  resetOnClose,
  resetTo
}) => {
  const [formData, setFormData] = useState<EmSelectionForm>(EMPTY_EM_SELECTION_FORM_DATA)

  useDeepCompareEffect(() => {
    setFormData({ employeeIds })
  }, [employeeIds])

  const handleOk = useCallback(async () => {
    onSelect(formData.employeeIds || [])
    typeof onClose === 'function' && onClose()
  }, [formData.employeeIds, onSelect, onClose])

  const handleSelect = useCallback((employeeIds: string[]) => {
    setFormData({ employeeIds })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    typeof onClose === 'function' && onClose()
  }, [onClose])

  return (
    <DrawerForm
      open={visible}
      title={title}
      okText={okText}
      onOk={handleOk}
      onClose={handleCloseDrawer}
      width={700}
      className="em-selection-drawer"
    >
      <Form className="em-selection-form">
        <EmSelection
          viewName={viewName}
          visible={visible}
          employeeIds={employeeIds}
          onFetchData={onFetchData}
          onSelect={handleSelect}
          resetOnClose={resetOnClose}
          resetTo={resetTo}
        />
      </Form>
    </DrawerForm>
  )
}
