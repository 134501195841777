import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiDeleteWorkflow } from '../api/workflow.api'
import { removeWorkflow } from '../reducers'
import { WfCategory } from '../types'

export const deleteWorkflow = (category: WfCategory, id: string): ThunkResult<void> => {
  return async dispatch => {
    if (!id) return

    const { status, errors, message, errorData } = await apiDeleteWorkflow(category, id)
    if (status) {
      dispatch(removeWorkflow({ category, id }))

      showSuccess('Deleted')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
