import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { CfConfigState } from '../types'
import { FetchStrategy } from '~/types/common'
import { selectCfConfigs } from '../selectors'
import { fetchCfConfigs } from '../actions'

export const useCfConfigs = (fetchStrategy: FetchStrategy = 'always'): [CfConfigState[], boolean] => {
  const data = useSelector(selectCfConfigs)
  const loading = useSelector((state: StoreState) => state.claim.cfConfigsLoading)

  useEffect(() => {
    dispatch(fetchCfConfigs({ strategy: fetchStrategy }))
  }, [fetchStrategy])

  return [data, loading]
}
