import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Button, Card, ColumnsType, Form, Input, Link, LinkGroup, Space, Table, Tag } from '~/core-components'
import { Col, EmpKeyValues, Row } from '~/components'
import { fetchEmpKeyvalues } from '~/features/master'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { useToggle } from '~/hooks/use-toggle'
import { useFirstInView } from '~/hooks/use-first-in-view'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { MutateEmAddressDrawer } from './MutateEmAddressDrawer'
import { IEmAddressInfo, EmAddressState } from '../../../types'
import { fetchEmAddresses } from '../../../actions'
import { selectMainEmAddress, selectOtherEmAddresses } from '../../../selectors'
import { useEmAddressRequests } from '~/features/employee/hooks'
import { EmAddressRequestsDrawer } from './EmAddressRequest/EmAddressRequestsDrawer'

interface EmAddressesProps {
  employeeId: string
  id: string
}

type EmAddressTable = EmAddressState

const EMPTY_FORM_DATA: IEmAddressInfo = {
  startDate: '',
  isMain: false,
  block: '',
  street: '',
  level: '',
  unit: '',
  city: '',
  state: '',
  postalCode: '',
  countryCode: ''
}

interface DrawerState {
  visible: boolean
  employeeId?: string
  data?: EmAddressState
}

interface RequestDrawerState {
  employeeId?: string
  visible: boolean
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }
const DEFAULT_REQUEST_DRAWER_STATE: RequestDrawerState = { visible: false }

const formStyle: CSSProperties = { padding: '6px 24px' }
const cardStyle: CSSProperties = { margin: 24 }
const cardBodyStyle: CSSProperties = { padding: 0 }
const tagStyle: CSSProperties = { marginLeft: 10 }
const levelInputStyle: CSSProperties = { width: '40%', marginRight: -1 }
const unitInputStyle: CSSProperties = { width: '60%' }
const showOthersStyle: CSSProperties = { margin: 10, textAlign: 'center' }
const btnPendingStyle: CSSProperties = {
  backgroundColor: '#ff8500',
  borderColor: '#ff8500',
  color: '#ffffff',
  marginRight: 30
}

export const EmAddresses: FC<EmAddressesProps> = ({ employeeId, id }: EmAddressesProps) => {
  const { ref, inView } = useFirstInView<HTMLDivElement>({ threshold: 0.25 })
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const [formData, setFormData] = useState<IEmAddressInfo>(EMPTY_FORM_DATA)
  const main = useSelector(selectMainEmAddress)(employeeId, id)
  const others = useSelector(selectOtherEmAddresses)(employeeId, id)
  const [showOthers, toggleShowOthers] = useToggle()
  const canView = usePermissionGate(Permission.employeeAddress)
  const canModify = usePermissionGate(Permission.employeeAddress, PermissionAction.Modify)
  const loading = useSelector((state: StoreState) => state.employee.emAddressesLoading[employeeId])
  const [emAddressRequests, emAddressRequestLoading] = useEmAddressRequests(employeeId || '', inView)
  const [requestDrawerState, setRequestDrawerState] = useState<RequestDrawerState>(DEFAULT_REQUEST_DRAWER_STATE)

  useEffect(() => {
    dispatch(fetchEmpKeyvalues('country', undefined, undefined, { strategy: 'when-empty' }))
  }, [inView])

  useEffect(() => {
    if (inView && employeeId) dispatch(fetchEmAddresses(employeeId))
  }, [inView, employeeId])

  useEffect(() => {
    if (main) {
      const { startDate, isMain, block, street, level, unit, city, state, postalCode, countryCode } = main
      setFormData({ startDate, isMain, block, street, level, unit, city, state, postalCode, countryCode })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [main])

  useEffect(() => {
    if (showOthers && others.length === 0) {
      toggleShowOthers()
    }
  }, [others.length, showOthers, toggleShowOthers])

  const handleAddAddress = useCallback(() => {
    setDrawerState({ visible: true, employeeId, data: undefined })
  }, [employeeId, setDrawerState])

  const handleEditAddress = useCallback(
    (emAddress?: EmAddressState) => {
      if (emAddress) setDrawerState({ visible: true, employeeId, data: emAddress })
    },
    [employeeId, setDrawerState]
  )

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const columns: ColumnsType<EmAddressTable> = [
    {
      title: 'Effective date',
      key: 'startDate',
      dataIndex: 'startDate',
      width: 150,
      render: (value: string) => value && moment(value).format('DD MMM YYYY')
    },
    {
      title: 'Address',
      key: 'addressLine1',
      dataIndex: 'addressLine1',
      render: (value: string, record) => `${value} ${record.addressLine2}`
    },
    {
      key: 'action',
      align: 'right',
      render: (value: string, record) =>
        canModify && (
          <Link size="small" onClick={() => handleEditAddress(record)}>
            edit
          </Link>
        )
    }
  ]

  const handleOpenRequestDrawer = useCallback(() => {
    setRequestDrawerState({ employeeId, visible: true })
  }, [employeeId])

  const handleCloseRequestDrawer = useCallback(() => {
    setRequestDrawerState(DEFAULT_REQUEST_DRAWER_STATE)
  }, [])

  if (!canView) return null

  return (
    <div ref={ref}>
      <Card
        title="Address"
        style={cardStyle}
        bodyStyle={cardBodyStyle}
        loading={loading}
        extra={
          employeeId &&
          canModify && (
            <Space>
              {emAddressRequests.length > 0 && (
                <Button
                  style={btnPendingStyle}
                  size="small"
                  onClick={handleOpenRequestDrawer}
                  loading={emAddressRequestLoading}
                >
                  {emAddressRequests.length > 1 ? `${emAddressRequests.length} pending requests` : 'pending request'}
                </Button>
              )}
              <LinkGroup>
                <Link onClick={handleAddAddress}>add</Link>
                {main != null && <Link onClick={() => handleEditAddress(main)}>edit</Link>}
              </LinkGroup>
            </Space>
          )
        }
      >
        <Form style={formStyle}>
          <Row hidden={!showOthers}>
            <Col span={24}>
              <Form.Item label="Effective date">
                <Input.Date value={formData.startDate ? moment(formData.startDate) : undefined} inputReadOnly />
                {formData.isMain && (
                  <Tag type="primary" style={tagStyle}>
                    main address
                  </Tag>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={15}>
            <Col span={4}>
              <Form.Item label="Block no.">
                <Input value={formData.block} readOnly />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Street name">
                <Input value={formData.street} readOnly />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Floor - Unit no.">
                <Input.Group>
                  <Input style={levelInputStyle} value={formData.level} readOnly />
                  <Input addonBefore="-" style={unitInputStyle} value={formData.unit} readOnly />
                </Input.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={30}>
            <Col span={12}>
              <Form.Item label="City">
                <Input value={formData.city} readOnly />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="State">
                <Input value={formData.state} readOnly />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={30}>
            <Col span={12}>
              <Form.Item label="Postal code">
                <Input value={formData.postalCode} readOnly />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Country">
                <EmpKeyValues id="country" value={formData.countryCode} readOnly />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        {showOthers && <Table rowKey="id" dataSource={others} pagination={false} columns={columns} />}
        {(others?.length || 0) > 0 && (
          <div style={showOthersStyle}>
            <Link onClick={toggleShowOthers}>{showOthers ? 'show less' : 'show more'}</Link>
          </div>
        )}
        {canModify && <MutateEmAddressDrawer {...drawerState} onClose={handleCloseDrawer} />}
        {canModify && emAddressRequests.length > 0 && (
          <EmAddressRequestsDrawer {...requestDrawerState} onClose={handleCloseRequestDrawer} />
        )}
      </Card>
    </div>
  )
}
