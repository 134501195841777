import React, { CSSProperties, FC, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Col, Row } from '~/components'
import { Badge, Card, ColumnsType, SecondaryText, Tab, Table, Tabs } from '~/core-components'
import { fetchCompanies } from '~/features/master'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import './IrasErrors.less'

interface IrasErrorsProps {}

interface IrasErrorTable {
  recordType: string
  recordField: string
  recordIdentifier: string
  recordIdentifierName: string
  error: string
}

const labelRow1Style: CSSProperties = { lineHeight: 1.1 }
const labelRow2Style: CSSProperties = { fontSize: 12 }

export const IrasErrors: FC<IrasErrorsProps> = () => {
  const ir8aErrors = useSelector((state: StoreState) => Object.values(state.tax.ir8aErrors.entities) || [])
  const ir8aWarnings = useSelector((state: StoreState) => Object.values(state.tax.ir8aWarnings.entities) || [])
  const ir8sErrors = useSelector((state: StoreState) => Object.values(state.tax.ir8sErrors.entities) || [])
  const ir8sWarnings = useSelector((state: StoreState) => Object.values(state.tax.ir8sWarnings.entities) || [])
  const a8aErrors = useSelector((state: StoreState) => Object.values(state.tax.a8aErrors.entities) || [])
  const a8aWarnings = useSelector((state: StoreState) => Object.values(state.tax.a8aWarnings.entities) || [])
  const a8bErrors = useSelector((state: StoreState) => Object.values(state.tax.a8bErrors.entities) || [])
  const a8bWarnings = useSelector((state: StoreState) => Object.values(state.tax.a8bWarnings.entities) || [])

  useEffect(() => {
    dispatch(fetchCompanies())
  }, [])

  const columns: ColumnsType<IrasErrorTable> = useMemo(
    () => [
      {
        title: 'Type',
        key: 'recordType',
        dataIndex: 'recordType'
      },
      {
        title: 'Field',
        key: 'recordField',
        dataIndex: 'recordField'
      },
      {
        title: 'Identifier',
        key: 'recordIdentifier',
        dataIndex: 'recordIdentifier',
        render: (value: string, record) => (
          <>
            <div style={labelRow1Style}>{value}</div>
            <SecondaryText style={labelRow2Style}>{record.recordIdentifierName}</SecondaryText>
          </>
        )
      },
      {
        title: 'Description',
        key: 'error',
        dataIndex: 'error'
      }
    ],
    []
  )

  const tabItems = useMemo(() => {
    const result: Tab[] = []

    if (ir8aErrors.length > 0) {
      result.push({
        key: 'ir8aErrors',
        label: (
          <>
            IR8A errors
            <Badge count={ir8aErrors.length} offset={[10, 0]} className="warning-badge" />
          </>
        ),
        children: <Table rowKey="id" dataSource={ir8aErrors} columns={columns} />
      })
    }

    if (ir8aWarnings.length > 0) {
      result.push({
        key: 'ir8aWarnings',
        label: (
          <>
            IR8A warnings
            <Badge count={ir8aWarnings.length} offset={[10, 0]} className="warning-badge" />
          </>
        ),
        children: <Table rowKey="id" dataSource={ir8aWarnings} columns={columns} />
      })
    }

    if (ir8sErrors.length > 0) {
      result.push({
        key: 'ir8sErrors',
        label: (
          <>
            IR8S errors
            <Badge count={ir8sErrors.length} offset={[10, 0]} className="warning-badge" />
          </>
        ),
        children: <Table rowKey="id" dataSource={ir8sErrors} columns={columns} />
      })
    }

    if (ir8sWarnings.length > 0) {
      result.push({
        key: 'ir8sWarnings',
        label: (
          <>
            IR8S warnings
            <Badge count={ir8sWarnings.length} offset={[10, 0]} className="warning-badge" />
          </>
        ),
        children: <Table rowKey="id" dataSource={ir8sWarnings} columns={columns} />
      })
    }

    if (a8aErrors.length > 0) {
      result.push({
        key: 'a8aErrors',
        label: (
          <>
            Appendix 8A errors
            <Badge count={a8aErrors.length} offset={[10, 0]} className="warning-badge" />
          </>
        ),
        children: <Table rowKey="id" dataSource={a8aErrors} columns={columns} />
      })
    }

    if (a8aWarnings.length > 0) {
      result.push({
        key: 'a8aWarnings',
        label: (
          <>
            Appendix 8A warnings
            <Badge count={a8aWarnings.length} offset={[10, 0]} className="warning-badge" />
          </>
        ),
        children: <Table rowKey="id" dataSource={a8aWarnings} columns={columns} />
      })
    }

    if (a8bErrors.length > 0) {
      result.push({
        key: 'a8bErrors',
        label: (
          <>
            Appendix 8B errors
            <Badge count={a8bErrors.length} offset={[10, 0]} className="warning-badge" />
          </>
        ),
        children: <Table rowKey="id" dataSource={a8bErrors} columns={columns} />
      })
    }

    if (a8bWarnings.length > 0) {
      result.push({
        key: 'a8bWarnings',
        label: (
          <>
            Appendix 8B warnings
            <Badge count={a8bWarnings.length} offset={[10, 0]} className="warning-badge" />
          </>
        ),
        children: <Table rowKey="id" dataSource={a8bWarnings} columns={columns} />
      })
    }

    return result
  }, [ir8aErrors, ir8aWarnings, ir8sErrors, ir8sWarnings, a8aErrors, a8aWarnings, a8bErrors, a8bWarnings, columns])

  if (
    ir8aErrors.length === 0 &&
    ir8aWarnings.length === 0 &&
    ir8sErrors.length === 0 &&
    ir8sWarnings.length === 0 &&
    a8aErrors.length === 0 &&
    a8aWarnings.length === 0 &&
    a8bErrors.length === 0 &&
    a8bWarnings.length === 0
  ) {
    return null
  }

  return (
    <div className="iras-errors">
      <Card table>
        <Row>
          <Col span={24}>
            <Tabs items={tabItems} />
          </Col>
        </Row>
      </Card>
    </div>
  )
}
