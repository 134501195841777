import React, { forwardRef, useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Select, SelectProps } from '~/core-components'
import { PayItemName, PayItemState, fetchPayItems } from '~/features/master'
import { useFirstInView } from '~/hooks'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { FetchStrategy } from '~/types/common'
import { isInactive } from '~/utils'
import { InactiveTag } from '../InactiveTag/InactiveTag'
import { DropdownActions } from './DropdownActions'

export interface PayItemKeyValuesProps extends SelectProps {
  onFilter?: (value: PayItemState | undefined, index: number, array: (PayItemState | undefined)[]) => {}
  fetchStrategy?: FetchStrategy
}

const PayItemKeyValuesInternal = (
  { onFilter, children, fetchStrategy = 'when-empty', ...props }: PayItemKeyValuesProps,
  ref: React.Ref<any>
) => {
  const { ref: inViewRef, inView } = useFirstInView<HTMLDivElement>({ threshold: 0.25 })
  const data = useSelector((state: StoreState) => state.master.payItems?.entities || undefined)
  const loading = useSelector((state: StoreState) => state.master.payItemsLoading)

  const options = useMemo(() => {
    if (!data) return []

    let result = Object.values(data)
      .filter(a => !isInactive(a?.inactiveDate) || a?.id === props.value)
      .sort((a, b) => {
        const aInactiveDate = new Date(a!.inactiveDate || '').getTime() || 0
        const bInactiveDate = new Date(b!.inactiveDate || '').getTime() || 0

        if (bInactiveDate === 0 && aInactiveDate === 0) {
          return a!.name.localeCompare(b!.name)
        } else {
          return aInactiveDate - bInactiveDate
        }
      })

    if (typeof onFilter === 'function') {
      result = result.filter(onFilter)
    }
    return result
  }, [data, props.value, onFilter])

  useEffect(() => {
    if (inView) {
      dispatch(fetchPayItems('sg', { strategy: fetchStrategy }))
    }
  }, [inView, fetchStrategy])

  const handleRefresh = useCallback(() => {
    dispatch(fetchPayItems('sg'))
  }, [])

  return (
    <div ref={inViewRef}>
      <Select
        ref={ref}
        showSearch
        optionFilterProp="title"
        loading={loading}
        dropdownRender={menu => (
          <div>
            {menu}
            <DropdownActions onRefresh={handleRefresh} />
          </div>
        )}
        {...props}
      >
        {options?.map(kv => {
          return (
            <Select.Option key={kv!.id} value={kv!.id || ''} title={kv!.name}>
              {isInactive(kv!.inactiveDate) && <InactiveTag />}
              <PayItemName id={kv!.id} />
            </Select.Option>
          )
        })}
      </Select>
    </div>
  )
}

export const PayItemKeyValues = forwardRef(PayItemKeyValuesInternal)
