import { request, getBaseUrl } from '~/utils/request'
import { LtPolicyState, LtPolicyExtraState, ILtPolicyMutate } from '../types'

const baseUrl = getBaseUrl('/leave/leavetype')

export const apiGetLtPolicies = (leaveTypeId: string) =>
  request<LtPolicyState[]>('get', `${baseUrl}/${leaveTypeId}/policy`)

export const apiGetLtPoliciesExtra = (leaveTypeId: string) =>
  request<LtPolicyExtraState[]>('get', `${baseUrl}/${leaveTypeId}/policy/extra`)

export const apiAddLtPolicy = (leaveTypeId: string, ltPolicy: ILtPolicyMutate) =>
  request<LtPolicyExtraState>('post', `${baseUrl}/${leaveTypeId}/policy`, ltPolicy)

export const apiUpdateLtPolicy = (leaveTypeId: string, id: string, ltPolicy: ILtPolicyMutate) =>
  request<LtPolicyExtraState>('patch', `${baseUrl}/${leaveTypeId}/policy/${id}`, ltPolicy)

export const apiDeleteLtPolicy = (leaveTypeId: string, id: string) =>
  request<LtPolicyExtraState>('delete', `${baseUrl}/${leaveTypeId}/policy/${id}`)
