import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { formatMoney } from '~/utils'
import { ClaRecordType } from '~/constants'
import { ETaskInfo } from '~/features/my/types'

export const selectMyClaimTasks = createSelector(
  (state: StoreState) => state.myClaim.myClaimTasks,
  (state: StoreState) => state.claim.claimTypes,
  (myClaimTasksState, claimTypesState) =>
    Object.values(myClaimTasksState?.entities || {})
      .map(e => {
        const empName = e?.employeeName
        const claimType = claimTypesState?.entities[e?.claimTypeId || '']
        const claimTypeName = claimType?.name.toLowerCase()
        const claimAmount = formatMoney(e?.claimAmount || 0, 2)
        const currency = claimType?.currencyCode.toUpperCase()
        const cancellationLabel = e?.recordType === ClaRecordType.cancellation ? ' cancellation' : ''
        return {
          id: e?.id,
          description: `Review ${empName}'s ${claimTypeName}${cancellationLabel} for ${currency} ${claimAmount}`,
          sequenceDate: e?.expenseDate
        } as ETaskInfo
      })
      .sort((a, b) => {
        const aSequenceDate = new Date(a!.sequenceDate).getTime()
        const bSequenceDate = new Date(b!.sequenceDate).getTime()
        return bSequenceDate - aSequenceDate
      })
)
