import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { LoadingOutlined } from '@ant-design/icons'
import { Space, Spin, Tooltip } from '~/core-components'
import { PersonAvatar } from '~/components'
import { FieldIcon } from '~/constants/selection'
import { fetchDefaultViewSchema, fetchSysSelectionFields, Screen, selectDefaultViewSchema } from '~/features/selection'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { getBaseUrl } from '~/utils'
import { fetchEmPublicProfile } from '../../actions'
import './EmPublicProfile.less'

interface EmPublicProfileProps {
  id: string
  defaultName?: string
  defaultPhotoId?: string
}

const screenCode: Screen = 'em_profile'
const baseUrl = getBaseUrl('/filestore')

export const EmPublicProfile: FC<EmPublicProfileProps> = ({ id, defaultName, defaultPhotoId }) => {
  const schema = useSelector(selectDefaultViewSchema)(screenCode)
  const data = useSelector((state: StoreState) => state.employee.emPublicProfiles.entities[id])
  const loading = useSelector((state: StoreState) => state.employee.emPublicProfilesLoading)
  const fields = useSelector((state: StoreState) => state.selection.sysSelectionFields[screenCode])
  const name = data?.name || defaultName
  const photoId = data?.photoId || defaultPhotoId

  useEffect(() => {
    dispatch(fetchSysSelectionFields(screenCode, { strategy: 'when-empty' }))
    dispatch(fetchDefaultViewSchema(screenCode, { strategy: 'when-empty' }))
  }, [])

  useEffect(() => {
    dispatch(fetchEmPublicProfile(id, { strategy: 'when-empty' }))
  }, [id])

  return (
    <div className="em-public-profile">
      <div className="em-public-profile__avatar">
        <PersonAvatar
          photo={photoId && `${baseUrl}/publicfile/${photoId}/thumbnailphoto/48`}
          // photo={id && `/employee/employee/${id}/avatar/64?photo_id=${photoId}`}
          size={64}
          mode="drop-shadow"
        />
        <div className="em-public-profile__name">{name}</div>
      </div>
      {loading ? (
        <Spin indicator={<LoadingOutlined spin />} />
      ) : (
        <div className="em-public-profile__info">
          <Space className="em-public-profile__header" size="small" direction="vertical">
            {schema?.selection.map(s => {
              const field = fields?.entities[s.selectionFieldId]
              const icon = FieldIcon.get(s.selectionFieldId)
              if (data && field) {
                const value = data[field?.fieldName]
                if (!value) return null

                return (
                  <span key={s.selectionFieldId} className="em-public-profile__field">
                    {icon && (
                      <>
                        <Tooltip title={field?.description} placement="topLeft">
                          <i className={`fal ${icon} em-public-profile__field-icon`} />
                        </Tooltip>
                      </>
                    )}
                    {data[field?.fieldName]}
                  </span>
                )
              }

              return null
            })}
          </Space>
        </div>
      )}
    </div>
  )
}
