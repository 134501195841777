import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { apiUpdateEmIdentity } from '../api/em-identity.api'
import { EmIdentityState, IEmIdentityInfo } from '../types'
import { setEmIdentity, setEmployee } from '../reducers'

export const updateEmIdentity = <T extends IEmIdentityInfo>(
  employeeId: string,
  id: string,
  before: T,
  request: T
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!employeeId || !id) return

    const after = { ...before, ...request } as T
    const patch = generateJsonPatch<T>(before, after)
    const { result, status, errors, message, errorData } = await apiUpdateEmIdentity(employeeId, id, patch)

    if (status) {
      if (result.emIdentityId) {
        const employee = getState().employee.employees.entities[employeeId]
        if (employee) {
          dispatch(setEmployee({ ...employee, emIdentityId: result.emIdentityId }))
        }
      }

      const emIdentity = getState().employee.emIdentities[employeeId]?.entities[id]
      dispatch(setEmIdentity({ employeeId, data: { ...(emIdentity as EmIdentityState), ...after } }))

      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
