import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchCompany } from '../actions'
import { selectCompanyById } from '../reducers'
import { CompanyState } from '../types'

export const useCompany = (
  id: string,
  fetchStrategy: FetchStrategy = 'always'
): [CompanyState | undefined, boolean] => {
  const data = useSelector((state: StoreState) => selectCompanyById(state, id))
  const loading = useSelector((state: StoreState) => state.master.companiesLoading)

  useEffect(() => {
    dispatch(fetchCompany(id, { strategy: fetchStrategy }))
  }, [id, fetchStrategy])

  return [data, loading]
}
