import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { Form, Input, Radio, RadioChangeEvent, Space } from '~/core-components'

interface DailyCronProps {
  expr: string[]
  onChange: (value: string[]) => void
  reset: number
}

const numberStyles: CSSProperties = { width: 60 }

export const DailyCron: FC<DailyCronProps> = ({ expr, onChange, reset }) => {
  const [selection, setSelection] = useState('1')
  const day = expr[2].split('/')[1] ? parseInt(expr[2].split('/')[1]) : 1
  const timeHHMM = `${expr[1]}:${expr[0]}`

  useEffect(() => {
    const isWeekday = expr[4] === 'MON-FRI'
    setSelection(isWeekday ? '2' : '1')
  }, [reset, expr])

  const handleDayChange = useCallback(
    (value: number | null) => {
      if ((typeof value === 'number' && value > 0 && value < 32) || value == null) {
        const newExpr = [...expr]
        newExpr[2] = value ? `1/${value}` : `${value}`
        onChange(newExpr)
      }
    },
    [onChange, expr]
  )

  const handleTimeChange = useCallback(
    (value: moment.Moment | null) => {
      const time = (value?.format('HH:mm') || '00:00').split(':')
      const newExpr = [...expr]
      newExpr[0] = `${parseInt(time[1])}`
      newExpr[1] = `${parseInt(time[0])}`
      onChange(newExpr)
    },
    [onChange, expr]
  )

  const handleRadio = useCallback(
    (e: RadioChangeEvent) => {
      const newSelection = e.target.value
      setSelection(newSelection)

      if (newSelection === '1') {
        onChange(['0', '0', '1/1', '*', '*'])
      } else {
        onChange([expr[0], expr[1], '1/1', '*', 'MON-FRI'])
      }
    },
    [onChange, expr]
  )

  return (
    <Form.Item>
      <Radio.Group value={selection} onChange={handleRadio}>
        <Space direction="vertical">
          <Radio value="1">
            <Space>
              Every
              <Input.Number
                min={1}
                max={31}
                value={day}
                style={numberStyles}
                onChange={handleDayChange}
                disabled={selection !== '1'}
              />
              day(s)
            </Space>
          </Radio>
          <Radio value="2">Every weekday</Radio>
          <Form.Item>
            <Space>
              Starts at
              <Input.Time
                value={moment(timeHHMM, 'HH:mm')}
                onChange={handleTimeChange}
                allowClear={false}
                showNow={false}
                minuteStep={5}
              />
            </Space>
          </Form.Item>
        </Space>
      </Radio.Group>
    </Form.Item>
  )
}
