import { showError, showSuccess } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiUnpublishAllPayRecords } from '../api/payrecord-sg.api'

export const unpublishAllPayRecords = (payRunId: string): ThunkResult<void> => {
  return async () => {
    if (!payRunId) return

    const { status, errors, message, errorData } = await apiUnpublishAllPayRecords(payRunId)
    if (status) {
      showSuccess('Unpublished')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
