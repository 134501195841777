import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { YtdCompanyState } from '../types'

const baseUrl = getBaseUrl('/payroll-sg/ytdcompany')

export const apiGetYtdCompany = (ytdSubmissionId: string) =>
  request<YtdCompanyState>('get', `${baseUrl}/submission/${ytdSubmissionId}`)

export const apiUpdateYtdCompany = (id: string, ytdCompany: Operation[]) =>
  request<void>('patch', `${baseUrl}/${id}`, ytdCompany)

export const apiRestoreYtdCompany = (id: string) => request<YtdCompanyState>('post', `${baseUrl}/${id}/restore`)
