import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import persistReducer from 'redux-persist/es/persistReducer'
import { groupingPersistConfig, GroupingRootState, RptScreen, SysGroupingScreenState, SysGroupingState } from './types'

const sysGroupingScreensAdapter = createEntityAdapter<SysGroupingScreenState>()
const sysGroupingsAdapter = createEntityAdapter<SysGroupingState>({ selectId: model => model.code })

const sysGroupingScreensInitialState = sysGroupingScreensAdapter.getInitialState()
const sysGroupingsInitialState = sysGroupingsAdapter.getInitialState()

const initialState: GroupingRootState = {
  sysGroupingScreens: {},
  sysGroupingScreensLoading: {},
  sysGroupings: {},
  sysGroupingsLoading: {},
  sysGroupingsRefetch: 0
}

const groupingSlice = createSlice({
  name: 'grouping',
  initialState,
  reducers: {
    setSysGroupingScreens: (
      state,
      action: PayloadAction<{ screenCode: RptScreen; data: SysGroupingScreenState[] }>
    ) => {
      const { screenCode, data } = action.payload
      state.sysGroupingScreens[screenCode] = state.sysGroupingScreens[screenCode] || sysGroupingScreensInitialState
      sysGroupingScreensAdapter.upsertMany(state.sysGroupingScreens[screenCode]!, data)
    },
    setSysGroupingScreensLoading: (state, action: PayloadAction<{ screenCode: RptScreen; loading: boolean }>) => {
      const { screenCode, loading } = action.payload
      state.sysGroupingScreens[screenCode] = state.sysGroupingScreens[screenCode] || sysGroupingScreensInitialState
      state.sysGroupingScreensLoading[screenCode] = loading
    },
    setSysGroupings: (state, action: PayloadAction<{ screenCode: RptScreen; data: SysGroupingState[] }>) => {
      const { screenCode, data } = action.payload
      state.sysGroupings[screenCode] = state.sysGroupings[screenCode] || sysGroupingsInitialState
      sysGroupingsAdapter.setAll(state.sysGroupings[screenCode]!, data)
    },
    setSysGroupingsLoading: (state, action: PayloadAction<{ screenCode: RptScreen; loading: boolean }>) => {
      const { screenCode, loading } = action.payload
      state.sysGroupings[screenCode] = state.sysGroupings[screenCode] || sysGroupingsInitialState
      state.sysGroupingsLoading[screenCode] = loading
    },
    refetchSysGroupings: state => {
      state.sysGroupingsRefetch += 1
    },
    clearSysGroupings: state => {
      for (const screen in state.sysGroupings) {
        sysGroupingsAdapter.removeAll(state.sysGroupings[screen as RptScreen] as EntityState<SysGroupingState>)
      }
    }
  }
})

export const {
  setSysGroupingScreens,
  setSysGroupingScreensLoading,
  setSysGroupings,
  setSysGroupingsLoading,
  refetchSysGroupings,
  clearSysGroupings
} = groupingSlice.actions

export const groupingReducers = {
  grouping: persistReducer<GroupingRootState>(groupingPersistConfig, groupingSlice.reducer)
}
