import React, { ChangeEvent, CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { Button, Form, Input, Select } from '~/core-components'
import { Col, EditableCard, EditableCardState, Row, SalaryInput } from '~/components'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { useFocus } from '~/hooks/use-focus'
import { PayMethod, PayRunStatus } from '~/constants'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors, StoreState } from '~/types/store'
import { fetchPayAccounts, updatePayRecordPayment } from '../../actions'
import { selectPayRecordAccounts } from '../../selectors'
import { IPayRecordPayment, PayRecordSgState } from '../../types'
import { selectPayRunById } from '../../reducers'

interface PayRecordPaymentProps {
  payRecord?: PayRecordSgState
  onEdit?: () => void
  onSave?: () => void
  onCancel?: () => void
}

const EMPTY_FORM_DATA: IPayRecordPayment = {
  payAccount: '',
  bankAmount: 0,
  chequeNo: '',
  payAccount2: '',
  bankAmount2: 0,
  chequeNo2: ''
}

const cardStyle: CSSProperties = { marginTop: 18 }
const buttonAddPartialPaymentStyle: CSSProperties = { marginTop: 20 }

export const PayRecordPayment: FC<PayRecordPaymentProps> = ({
  payRecord,
  onEdit,
  onSave,
  onCancel
}: PayRecordPaymentProps) => {
  const [cardState, setCardState] = useState<EditableCardState>()
  const [formData, setFormData] = useState<IPayRecordPayment>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const [showSecondary, setShowSecondary] = useState(false)
  const [focusRef] = useFocus(cardState === 'editing')
  const payAccounts = useSelector(selectPayRecordAccounts)(payRecord?.id || '')
  const loading = useSelector((state: StoreState) => state.payroll.payAccountsLoading[payRecord?.id || ''])
  const payRun = useSelector((state: StoreState) => selectPayRunById(state, payRecord?.payRunId || ''))
  const canModify = usePermissionGate(Permission.payRun, PermissionAction.Modify)
  const netPay = payRecord?.netPay || 0
  const bankAmount2 = payRecord?.bankAmount2 || 0

  const resetFormData = useCallback((payRecord: PayRecordSgState) => {
    if (payRecord) {
      const { payMethod, bankAmount, chequeNo, payMethod2, bankAmount2, chequeNo2 } = payRecord
      setFormData({
        payAccount: payMethod === PayMethod.bank ? 'py1' : payMethod,
        bankAmount,
        chequeNo,
        payAccount2: payMethod2 === PayMethod.bank ? 'py2' : payMethod2,
        bankAmount2,
        chequeNo2
      })
    }
  }, [])

  useEffect(() => {
    if (payRecord) {
      dispatch(fetchPayAccounts(payRecord.id))
    }
  }, [payRecord])

  useEffect(() => {
    if (payRecord) {
      resetFormData(payRecord)
      setShowSecondary(payRecord.bankAmount2 > 0)
    } else {
      setErrors(undefined)
      setFormData(EMPTY_FORM_DATA)
      setShowSecondary(false)
    }
  }, [payRecord, resetFormData])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  const handleEdit = useCallback(() => {
    setCardState('editing')
    typeof onEdit === 'function' && onEdit()
  }, [onEdit])

  const handleSave = useCallback(async () => {
    if (payRecord) {
      setCardState('saving')
      setErrors(undefined)
      setShowSecondary(payRecord.bankAmount2 > 0)

      typeof onSave === 'function' && onSave()

      let result: ActionResult | undefined
      try {
        result = await dispatch(updatePayRecordPayment(payRecord.payRunId, payRecord.id, formData))
      } catch {
        setCardState('editing')
      }

      if (result?.errors) {
        setCardState('editing')
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setCardState(undefined)
      }
    }
  }, [payRecord, formData, onSave])

  const handleCancel = useCallback(() => {
    typeof onCancel === 'function' && onCancel()
    setCardState(undefined)
    setErrors(undefined)

    if (payRecord) {
      setShowSecondary(payRecord.bankAmount2 > 0)
      resetFormData(payRecord)
    }
  }, [payRecord, onCancel, resetFormData])

  const handleAddPartialPayment = useCallback(() => {
    setShowSecondary(true)
  }, [])

  return (
    <EditableCard
      title="Payment"
      style={cardStyle}
      bodyStyle={{ paddingTop: 6 }}
      state={canModify && payRun?.status !== PayRunStatus.completed && !payRecord?.lockedBy ? cardState : 'readonly'}
      loading={loading}
      formId="form-payrecord-payment"
      onEdit={handleEdit}
      onSave={handleSave}
      onCancel={handleCancel}
    >
      <Row gutter={30}>
        <Col span={(!showSecondary && cardState !== 'editing') || netPay <= 0 ? 24 : 12}>
          <Row>
            <Col span={24}>
              <Form.Item
                label={!showSecondary ? 'Account' : '1st account'}
                validateStatus={errors?.bankAccountNo ? 'error' : ''}
                help={errors?.bankAccountNo}
              >
                <Select
                  ref={focusRef}
                  showSearch
                  optionFilterProp="title"
                  value={formData.payAccount}
                  readOnly={cardState !== 'editing' && cardState !== 'saving'}
                  onChange={(value: string) => handleFormDataChange({ payAccount: value })}
                >
                  {payAccounts &&
                    Object.values(payAccounts).map(acc => (
                      <Select.Option key={acc.id} value={acc.id} title={acc.name}>
                        {acc.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row hidden={formData.payAccount !== PayMethod.cheque}>
            <Col span={24}>
              <Form.Item label="Cheque no.">
                <Input
                  value={formData.chequeNo}
                  readOnly={cardState !== 'editing' && cardState !== 'saving'}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleFormDataChange({ chequeNo: event.target.value })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item label="Amount" validateStatus={errors?.bankAmount ? 'error' : ''} help={errors?.bankAmount}>
                <SalaryInput
                  readOnly
                  className={classNames({ 'ant-input-number--readonly': cardState === 'editing' })}
                  value={formData.bankAmount}
                  onChange={(value: number | null) => handleFormDataChange({ bankAmount: value })}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          {!showSecondary && cardState === 'editing' && netPay > 0 && (
            <Button
              type="dashed"
              size="large"
              block
              style={buttonAddPartialPaymentStyle}
              onClick={handleAddPartialPayment}
            >
              add partial payment
            </Button>
          )}
          {showSecondary && (netPay > 0 || bankAmount2 > 0) && (
            <>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="2nd account"
                    validateStatus={errors?.bankAccountNo2 ? 'error' : ''}
                    help={errors?.bankAccountNo2}
                  >
                    <Select
                      showSearch
                      optionFilterProp="title"
                      value={formData.payAccount2}
                      readOnly={cardState !== 'editing' && cardState !== 'saving'}
                      onChange={(value: string) =>
                        handleFormDataChange({
                          payAccount2: value,
                          bankAmount2: value === undefined ? 0 : formData.bankAmount2
                        })
                      }
                    >
                      {payAccounts &&
                        Object.values(payAccounts).map(acc => (
                          <Select.Option key={acc.id} value={acc.id} title={acc.name}>
                            {acc.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row hidden={formData.payAccount2 !== PayMethod.cheque}>
                <Col span={24}>
                  <Form.Item label="Cheque no.">
                    <Input
                      value={formData.chequeNo2}
                      readOnly={cardState !== 'editing' && cardState !== 'saving'}
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        handleFormDataChange({ chequeNo2: event.target.value })
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Amount"
                    validateStatus={errors?.bankAmount2 ? 'error' : ''}
                    help={errors?.bankAmount2}
                  >
                    <SalaryInput
                      value={formData.bankAmount2}
                      readOnly={cardState !== 'editing' && cardState !== 'saving'}
                      onChange={(value: number | null) =>
                        handleFormDataChange({
                          bankAmount2: value,
                          bankAmount: (payRecord?.netPay || 0) - Number(value)
                        })
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </EditableCard>
  )
}
