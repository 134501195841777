import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { XeroContactState } from '../types'

export const selectXeroContacts = createSelector(
  (state: StoreState) => state.payroll.xeroContacts,
  xeroContactsState =>
    memoize((tenantId: string): XeroContactState[] => {
      return Object.values(xeroContactsState[tenantId]?.entities || {}) as XeroContactState[]
    })
)
