import { useMemo } from 'react'
import { useHasFeatureFlag } from '~/features/iam'
import { FeatureFlag } from '~/constants'
import { useMyEmployee } from './use-my-employee'

export const useHasMySsAccess = (featureFlag: FeatureFlag | FeatureFlag[]): boolean => {
  const hasFeatureFlag = useHasFeatureFlag(featureFlag)
  const featureFlags = useMemo(() => (Array.isArray(featureFlag) ? featureFlag : [featureFlag]), [featureFlag])
  const [me] = useMyEmployee()

  if (!hasFeatureFlag) return false

  let hasSsAccess = false
  for (const feat of featureFlags) {
    if (me?.attributes['ss_access']?.includes(feat)) hasSsAccess = true
  }

  if (!hasSsAccess) return false

  return true
}
