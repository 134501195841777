import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'

export const selectCompaniesTaxNo = createSelector(
  (state: StoreState) => state.master.companies,
  companyState =>
    memoize(() => {
      const companies = Object.values(companyState?.entities || {}).filter(co => !!co?.taxNo)
      return companies.map(co => ({ taxNo: co?.taxNo || '', name: `${co?.taxNo || ''} (${co?.name || ''})` }))
    })
)
