import React, { FC, useCallback, useState } from 'react'
import classNames from 'classnames'
import { Button, ButtonProps } from '~/core-components'
import { MyLeaveApplyDrawer } from './MyLeaveApplyDrawer'

interface MyLeaveApplyButtonProps extends ButtonProps {
  leaveTypeId?: string
  leaveGrantId?: string
}

interface DrawerState {
  visible: boolean
  editing?: boolean
  editable?: boolean
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

export const MyLeaveApplyButton: FC<MyLeaveApplyButtonProps> = ({ className, leaveTypeId, leaveGrantId, ...props }) => {
  const classes = classNames('my-leave-apply-button', className)
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)

  const handleApplyLeave = useCallback(() => {
    setDrawerState({ visible: true, editing: true })
  }, [setDrawerState])

  const handleCloseDrawer = useCallback(() => setDrawerState(DEFAULT_DRAWER_STATE), [])

  return (
    <>
      <Button className={classes} block onClick={handleApplyLeave} {...props} />
      <MyLeaveApplyDrawer
        {...drawerState}
        leaveTypeId={leaveTypeId}
        leaveGrantId={leaveGrantId}
        onClose={handleCloseDrawer}
      />
    </>
  )
}
