import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Col, Row } from '~/components'
import { Card, Link, List, SecondaryText, Space, Text } from '~/core-components'
import { useSysOptions } from '~/features/employee'
import { usePermissionGate } from '~/features/iam'
import { formatMoney } from '~/utils'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { ClaCurrencyCode, Permission, PermissionAction } from '~/constants'
import { fetchCtPolicies } from '../../../actions'
import { selectCtPoliciesById } from '../../../selectors'
import { ClaimTypeState, CtPolicyState } from '../../../types'
import { formatClaimLimit } from '../../../util'
import { MutateCtPolicyDrawer } from './MutateCtPolicyDrawer'

interface ClaimTypePolicyProps {
  claimType?: ClaimTypeState
}

interface DrawerState {
  visible: boolean
  data?: CtPolicyState
}

const DEFAULT_MODAL_STATE: DrawerState = { visible: false }

export const ClaimTypePolicy: FC<ClaimTypePolicyProps> = ({ claimType }) => {
  const claimTypeId = claimType?.id || ''
  const ctPolicies = useSelector(selectCtPoliciesById)(claimTypeId)
  const loading = useSelector((state: StoreState) => state.claim.ctPoliciesLoading[claimTypeId])
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_MODAL_STATE)
  const [entitlementTypes] = useSysOptions('cla_entitlement_type')
  const [maxTimesBasis] = useSysOptions('cla_max_times_basis')
  const canModify = usePermissionGate(Permission.claType, PermissionAction.Modify)

  useEffect(() => {
    dispatch(fetchCtPolicies(claimTypeId))
  }, [claimTypeId])

  const handleEditPolicy = useCallback((data: CtPolicyState) => {
    setDrawerState({ visible: true, data })
  }, [])

  const handleAddPolicy = useCallback(() => {
    setDrawerState({ visible: true })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_MODAL_STATE)
  }, [])

  if (!claimType) return null

  return (
    <Card
      title="Policy"
      bodyStyle={{ paddingBottom: claimType && !loading ? 6 : 24, paddingTop: 6 }}
      extra={canModify && !loading && <Link onClick={handleAddPolicy}>add</Link>}
      loading={loading}
    >
      <List
        dataSource={ctPolicies}
        header={
          <Row>
            <Col flex="140px">
              <b>Entitlement</b>
            </Col>
            <Col flex="1">
              <b>Limit</b>
            </Col>
          </Row>
        }
        renderItem={(item: CtPolicyState) => {
          const entitlementType = entitlementTypes[claimType.entitlementType || '']

          const limitText = formatClaimLimit(
            claimType.currencyCode,
            item.requireConfirm,
            item.requireMonths,
            item.monthlyLimit,
            item.dailyLimit,
            item.perTimeAmount,
            item.perTimePercentage,
            item.perTimeEmAmount,
            item.maxTimes,
            maxTimesBasis && maxTimesBasis[item.maxTimesBasis]?.value
          )

          return (
            <List.Item key={item.id}>
              <Row>
                <Col flex="1">
                  <b>{item.policyName}</b>
                </Col>
              </Row>
              <Row>
                <Col flex="140px">
                  <Text size="small">
                    <Space>
                      {(claimType.currencyCode || ClaCurrencyCode.sgd).toUpperCase()}
                      {formatMoney(item.entitlementAmount, 2)}
                    </Space>
                  </Text>
                  <SecondaryText block size="small">
                    {entitlementType?.value || ''}
                  </SecondaryText>
                </Col>
                <Col flex="1">
                  <Text size="small">
                    {limitText.map((l, index) => (
                      <div key={index}>{l}</div>
                    ))}
                  </Text>
                </Col>
                {canModify && (
                  <Col flex="none">
                    <Link size="small" onClick={() => handleEditPolicy(item)}>
                      edit
                    </Link>
                  </Col>
                )}
              </Row>
            </List.Item>
          )
        }}
      />
      {canModify && <MutateCtPolicyDrawer {...drawerState} claimType={claimType} onClose={handleCloseDrawer} />}
    </Card>
  )
}
