import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from '~/core-components'
import { StoreState } from '~/types/store'
import { FeatureFlag, emptyGuid } from '~/constants'
import { ILoginPermission } from '../../../types'
import { PermissionActionList } from './PermissionActionList'
import { useLoginPermissions } from '~/features/iam/hooks'
import { FeatureFlagGate } from '../../FeatureFlagGate'

export interface PermissionActionProps {
  loginId: string
  readOnly: boolean
  onChange: (permissions: ILoginPermission[]) => void
}

export const PERMISSION_SECTION: { [code: string]: { label: string; featureFlag: FeatureFlag | string } } = {
  people: {
    label: 'People',
    featureFlag: '*'
  },
  payroll: {
    label: 'Payroll',
    featureFlag: FeatureFlag.Payroll
  },
  leave: {
    label: 'Leave',
    featureFlag: FeatureFlag.Leave
  },
  claim: {
    label: 'Claim',
    featureFlag: FeatureFlag.Claim
  },
  attendance: {
    label: 'Attendance',
    featureFlag: FeatureFlag.Attendance
  },
  report: {
    label: 'Report',
    featureFlag: '*'
  },
  security: {
    label: 'Access control',
    featureFlag: '*'
  },
  integration: {
    label: 'Integration',
    featureFlag: '*'
  }
}

const activeTenantCode = localStorage.getItem('tenant')

export const PermissionAction: FC<PermissionActionProps> = ({ loginId, readOnly, onChange }) => {
  const loading = useSelector((state: StoreState) => state.iam.loginPermissionsLoading)
  const [loginPermissions] = useLoginPermissions(loginId, activeTenantCode)
  const [permissions, setPermissions] = useState<ILoginPermission[]>([])

  useEffect(() => {
    setPermissions(loginPermissions.map(({ id, sysPermissionId, action }) => ({ id, sysPermissionId, action })))
  }, [loginPermissions])

  const handlePermissionChange = useCallback(
    (sysPermissionId: string, actions: string[]) => {
      setPermissions(permissions => {
        let newPermissions: ILoginPermission[] = []
        if (actions == null || actions.length === 0) {
          newPermissions = permissions.filter(pr => pr.sysPermissionId !== sysPermissionId)
        } else {
          const found = permissions.find(pr => pr.sysPermissionId === sysPermissionId)
          const others = permissions.filter(pr => pr.sysPermissionId !== sysPermissionId)
          if (found) {
            newPermissions = [...others, { ...found, action: actions.join('') }]
          } else {
            const newPermission: ILoginPermission = {
              id: emptyGuid,
              sysPermissionId,
              action: actions.join('')
            }
            newPermissions = [...others, newPermission]
          }
        }

        typeof onChange === 'function' && onChange(newPermissions)
        return newPermissions
      })
    },
    [onChange]
  )

  if (loading) {
    return <Spin size="small" indicator={<LoadingOutlined spin />} />
  }

  return (
    <>
      {Object.keys(PERMISSION_SECTION).map(section => {
        if (PERMISSION_SECTION[section].featureFlag !== '*') {
          return (
            <FeatureFlagGate featureFlag={PERMISSION_SECTION[section].featureFlag as FeatureFlag}>
              <PermissionActionList
                key={section}
                section={section}
                readOnly={readOnly}
                permissions={permissions}
                onChange={handlePermissionChange}
              />
            </FeatureFlagGate>
          )
        }

        return (
          <PermissionActionList
            key={section}
            section={section}
            readOnly={readOnly}
            permissions={permissions}
            onChange={handlePermissionChange}
          />
        )
      })}
    </>
  )
}
