import React, { FC } from 'react'
import classNames from 'classnames'
import { Select, SelectProps } from '~/core-components'
import './IconPicker.less'

interface IconPickerProps extends Omit<SelectProps, 'value'> {
  icon?: string
}

const icons = [
  'fa-bed',
  'fa-book',
  'fa-bus',
  'fa-cake-candles',
  'fa-car',
  'fa-cart-shopping',
  'fa-desktop',
  'fa-dumbbell',
  'fa-family',
  'fa-graduation-cap',
  'fa-hospital',
  'fa-house',
  'fa-house-medical',
  'fa-martini-glass',
  'fa-mobile',
  'fa-phone',
  'fa-plane',
  'fa-plate-utensils',
  'fa-plug',
  'fa-ship',
  'fa-store',
  'fa-tooth'
]

export const IconPicker: FC<IconPickerProps> = ({ icon, className, popupClassName, ...props }) => {
  const classes = classNames('icon-picker', { [`${className}`]: className })
  const dropdownClasses = classNames('icon-picker', { [`${popupClassName}`]: popupClassName })

  return (
    <Select className={classes} popupClassName={dropdownClasses} value={icon} {...props}>
      {icons.map(i => (
        <Select.Option key={i} value={i}>
          <i className={`fal ${i}`} />
        </Select.Option>
      ))}
    </Select>
  )
}
