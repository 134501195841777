import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { selectMyCalendarLeaveRecords } from '../../selectors'
import { fetchMyCalendarLeaveRecords } from '../../actions'
import { SSCalendarLeaveRecordState } from '../../types'

export const useMyCalendarLeaveRecords = (
  viewAs: string,
  startDate: string,
  endDate: string
): [SSCalendarLeaveRecordState[], boolean] => {
  const data = useSelector(selectMyCalendarLeaveRecords)
  const loading = useSelector((state: StoreState) => state.my.myCalendarLeaveRecordsLoading)
  const refetch = useSelector((state: StoreState) => state.my.myCalendarLeaveRecordsRefetch)

  useEffect(() => {
    dispatch(fetchMyCalendarLeaveRecords(viewAs, { startDate, endDate }))
  }, [startDate, endDate, viewAs, refetch])

  return [data, loading]
}
