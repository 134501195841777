import { request, getBaseUrl } from '~/utils/request'
import { SSClaimTaskState } from '../types'

const baseUrl = getBaseUrl('/claim/ssclaimtask')

export const apiGetMyClaimTasks = () => request<SSClaimTaskState[]>('get', baseUrl)

export const apiGetMyClaimTask = (id: string) => request<SSClaimTaskState>('get', `${baseUrl}/${id}`)

export const apiApproveClaim = (id: string, comment: string) => request('post', `${baseUrl}/${id}/approve`, { comment })

export const apiRejectClaim = (id: string, comment: string) => request('post', `${baseUrl}/${id}/reject`, { comment })
