import { showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { apiAddIr21Submission } from '../api/ir21-submission.api'
import { refetchIr21SubmissionViews } from '../reducers'
import { IIr21SubmissionNew } from '../types'

export const addIr21Submission = (ir21Submission: IIr21SubmissionNew): ThunkResult<void> => {
  return async (dispatch): Promise<ActionResult | undefined> => {
    const { status, result, errors, message, errorData } = await apiAddIr21Submission(ir21Submission)
    if (status) {
      dispatch(refetchIr21SubmissionViews())
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
