import React, { FC, useState, useCallback, useEffect, ChangeEvent } from 'react'
import { Form, Input, Select, Tag } from '~/core-components'
import { Col, DrawerForm, PercentInput, Row } from '~/components'
import { ActionResult, Errors } from '~/types/store'
import { dispatch } from '~/stores/store'
import { updateRule } from '../../../actions'
import { IRule, mapRuleStateToRule, RuleState } from '../../../types'
import set from 'lodash/set'

export interface EditRuleDrawerProps {
  visible: boolean
  title?: string
  data?: RuleState
  onClose: () => void
}

const EMPTY_FORM_DATA: Partial<RuleState> = {
  params: undefined,
  tag: '',
  color: ''
}

interface ParamKv {
  [key: string]: { label: string; type: string }
}

const PARAM_MAP: ParamKv = {
  variance: {
    label: 'Variance of net pay exceeds {percent}%',
    type: 'percent'
  }
}

const COLORS: string[] = [
  'magenta',
  'red',
  'volcano',
  'orange',
  'gold',
  'lime',
  'green',
  'cyan',
  'blue',
  'geekblue',
  'purple'
]

export const EditRuleDrawer: FC<EditRuleDrawerProps> = ({ visible, title, data, onClose }) => {
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<Errors>()
  const [formData, setFormData] = useState<Partial<RuleState>>(EMPTY_FORM_DATA)

  useEffect(() => {
    setErrors(undefined)
  }, [visible])

  useEffect(() => {
    if (data) {
      const { params, tag, color } = data
      setFormData({ params, tag, color })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      Object.keys(updates).forEach(k => {
        if (k.includes('.')) {
          const value = updates[k]
          delete updates[k]
          set(updates, k, value)
        }
      })
      const updated = { ...formData, ...updates }
      setFormData(updated)
    },
    [formData]
  )

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      if (data) {
        const current = mapRuleStateToRule(data)
        const form: Partial<IRule> = {
          tag: formData.tag,
          color: formData.color,
          paramJsonb: JSON.stringify(formData.params)
        }
        result = await dispatch(updateRule(data.referenceId, data.id, current, { ...current, ...form }))
      }
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data, formData, onClose])

  return (
    <DrawerForm
      open={visible}
      title={title || 'Edit rule'}
      onClose={onClose}
      confirmLoading={loading}
      width={500}
      formId="form-edit-rule"
    >
      <Form id="form-edit-rule" onFinish={handleOk}>
        {data?.params && formData.params && (
          <Row>
            {Object.keys(PARAM_MAP).map((key: string) => {
              if (key in data?.params && key in formData.params) {
                const param = PARAM_MAP[key]
                return (
                  <Col span={24}>
                    <Form.Item
                      label={param.label}
                      validateStatus={errors && errors[key] ? 'error' : ''}
                      help={errors && errors[key]}
                    >
                      {param.type === 'percent' && (
                        <PercentInput
                          value={formData.params[key]}
                          onChange={(value: number | null) => handleFormDataChange({ [`params.${key}`]: value })}
                        />
                      )}
                    </Form.Item>
                  </Col>
                )
              }
              return null
            })}
          </Row>
        )}
        <Row>
          <Col span={24}>
            <Form.Item label="Tag colour" validateStatus={errors?.color ? 'error' : ''} help={errors?.color}>
              <Select value={formData.color} onChange={(value: string) => handleFormDataChange({ color: value })}>
                {COLORS.map(c => (
                  <Select.Option key={c} value={c}>
                    <Tag type="original" color={c}>
                      {c}
                    </Tag>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Tag name" validateStatus={errors?.tag ? 'error' : ''} help={errors?.tag}>
              <Input
                value={formData.tag}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ tag: event.target.value })}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}
