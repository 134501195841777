import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiDeleteShiftCategory } from '../api/shift-category.api'
import { removeShiftCategory } from '../reducers'
import { removeKeyvalue } from '~/features/master'

export const deleteShiftCategory = (id: string): ThunkResult<void> => {
  return async dispatch => {
    if (!id) return

    const { status, errors, message, errorData } = await apiDeleteShiftCategory(id)
    if (status) {
      dispatch(removeShiftCategory(id))
      dispatch(removeKeyvalue({ kv: 'shiftCategory', id }))
      showSuccess('Deleted')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
