import React, { FC } from 'react'
import { Select, SelectProps } from '~/core-components'
import { useXeroTenants } from '../../hooks'

interface XeroSelectTenantProps extends SelectProps {}

export const XeroSelectTenant: FC<XeroSelectTenantProps> = props => {
  const [tenants] = useXeroTenants()

  return (
    <Select {...props}>
      {tenants.map(t => (
        <Select.Option key={t.id} value={t.id}>
          {t.name}
        </Select.Option>
      ))}
    </Select>
  )
}
