import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiProceedToPaymentPayRun } from '../api/payrun.api'
import { PayRunState } from '../types'
import { setPayRun } from '../reducers'
import { PayRunStatus } from '~/constants'

export const proceedToPaymentPayRun = (id: string, lockAll: boolean): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const { status, errors, message, errorData } = await apiProceedToPaymentPayRun(id, lockAll)

    if (status) {
      let payRun = getState().payroll.payRuns?.entities[id]
      dispatch(setPayRun({ ...(payRun as PayRunState), status: PayRunStatus.payment }))

      showSuccess('Payroll run has moved to payment & statutory submission')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
