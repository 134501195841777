import { replaceMyLeaveRecordComments, setMyLeaveRecordCommentsLoading } from '../../reducers'
import { apiGetMyLeaveRecordComments } from '../../api/ssleave-record-comment.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchLeaveRecordComments = (
  id: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const loading = getState().my.myLeaveRecordCommentsLoading[id]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().my.myLeaveRecordComments[id]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setMyLeaveRecordCommentsLoading({ id, loading: true }))
      const { result, status } = await apiGetMyLeaveRecordComments(id)
      if (status) dispatch(replaceMyLeaveRecordComments({ id, data: result }))
    } finally {
      dispatch(setMyLeaveRecordCommentsLoading({ id, loading: false }))
    }
  }
}
