import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiDeleteLeaveType } from '../api/leave-type.api'
import { removeLeaveType } from '../reducers'
import { removeKeyvalue } from '~/features/master'

export const deleteLeaveType = (id: string): ThunkResult<void> => {
  return async dispatch => {
    if (!id) return

    const { status, errors, message, errorData } = await apiDeleteLeaveType(id)
    if (status) {
      dispatch(removeLeaveType(id))
      dispatch(removeKeyvalue({ kv: 'leaveType', id }))
      showSuccess('Deleted')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
