import { showError, showSuccess } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { IUpdatePreference } from '../types'
import { apiUpdatePreference } from '../api/login.api'
import { setLogin } from '../reducers'

export const updatePreference = (request: IUpdatePreference): ThunkResult<void> => {
  return async (dispatch, getState): Promise<ActionResult> => {
    const { status, result, errors, message, errorData } = await apiUpdatePreference(request)
    if (status) {
      const myId = getState().iam.myJwt?.loginId || ''
      const login = getState().iam.logins.entities[myId]
      if (login) {
        dispatch(setLogin({ ...login, timeZoneId: request.timeZoneId }))
      }

      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
