import { setIr8sForm, setIr8sFormLoading } from '../reducers'
import { apiGetIr8sFormData } from '../api/ytd.api'
import { ThunkResult } from '~/types/store'
import { Ir8sFormState } from '../types'

export const fetchIr8sForm = (
  ytdYear: string,
  employerTaxNo: string,
  employeeTaxNo: string,
  ytdSubmissionId: string
): ThunkResult<void> => {
  return async dispatch => {
    try {
      dispatch(setIr8sFormLoading(true))
      const { result, status } = await apiGetIr8sFormData(ytdYear, employerTaxNo, employeeTaxNo, ytdSubmissionId)
      if (status) {
        const stored = mapToState(result)
        dispatch(setIr8sForm(stored))
      }
    } finally {
      dispatch(setIr8sFormLoading(false))
    }
  }
}

const mapToState = (ir8sForm: Ir8sFormState): Ir8sFormState => {
  ir8sForm.totalOw =
    ir8sForm.ow01 +
    ir8sForm.ow02 +
    ir8sForm.ow03 +
    ir8sForm.ow04 +
    ir8sForm.ow05 +
    ir8sForm.ow06 +
    ir8sForm.ow07 +
    ir8sForm.ow08 +
    ir8sForm.ow09 +
    ir8sForm.ow10 +
    ir8sForm.ow11 +
    ir8sForm.ow12

  ir8sForm.totalCpfErOrdinary =
    ir8sForm.cpfErOrdinary01 +
    ir8sForm.cpfErOrdinary02 +
    ir8sForm.cpfErOrdinary03 +
    ir8sForm.cpfErOrdinary04 +
    ir8sForm.cpfErOrdinary05 +
    ir8sForm.cpfErOrdinary06 +
    ir8sForm.cpfErOrdinary07 +
    ir8sForm.cpfErOrdinary08 +
    ir8sForm.cpfErOrdinary09 +
    ir8sForm.cpfErOrdinary10 +
    ir8sForm.cpfErOrdinary11 +
    ir8sForm.cpfErOrdinary12

  ir8sForm.totalCpfEmOrdinary =
    ir8sForm.cpfEmOrdinary01 +
    ir8sForm.cpfEmOrdinary02 +
    ir8sForm.cpfEmOrdinary03 +
    ir8sForm.cpfEmOrdinary04 +
    ir8sForm.cpfEmOrdinary05 +
    ir8sForm.cpfEmOrdinary06 +
    ir8sForm.cpfEmOrdinary07 +
    ir8sForm.cpfEmOrdinary08 +
    ir8sForm.cpfEmOrdinary09 +
    ir8sForm.cpfEmOrdinary10 +
    ir8sForm.cpfEmOrdinary11 +
    ir8sForm.cpfEmOrdinary12

  ir8sForm.totalAw =
    ir8sForm.aw01 +
    ir8sForm.aw02 +
    ir8sForm.aw03 +
    ir8sForm.aw04 +
    ir8sForm.aw05 +
    ir8sForm.aw06 +
    ir8sForm.aw07 +
    ir8sForm.aw08 +
    ir8sForm.aw09 +
    ir8sForm.aw10 +
    ir8sForm.aw11 +
    ir8sForm.aw12

  ir8sForm.totalCpfErAdditional =
    ir8sForm.cpfErAdditional01 +
    ir8sForm.cpfErAdditional02 +
    ir8sForm.cpfErAdditional03 +
    ir8sForm.cpfErAdditional04 +
    ir8sForm.cpfErAdditional05 +
    ir8sForm.cpfErAdditional06 +
    ir8sForm.cpfErAdditional07 +
    ir8sForm.cpfErAdditional08 +
    ir8sForm.cpfErAdditional09 +
    ir8sForm.cpfErAdditional10 +
    ir8sForm.cpfErAdditional11 +
    ir8sForm.cpfErAdditional12

  ir8sForm.totalCpfEmAdditional =
    ir8sForm.cpfEmAdditional01 +
    ir8sForm.cpfEmAdditional02 +
    ir8sForm.cpfEmAdditional03 +
    ir8sForm.cpfEmAdditional04 +
    ir8sForm.cpfEmAdditional05 +
    ir8sForm.cpfEmAdditional06 +
    ir8sForm.cpfEmAdditional07 +
    ir8sForm.cpfEmAdditional08 +
    ir8sForm.cpfEmAdditional09 +
    ir8sForm.cpfEmAdditional10 +
    ir8sForm.cpfEmAdditional11 +
    ir8sForm.cpfEmAdditional12

  return ir8sForm
}
