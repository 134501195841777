import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { setA8aProcessing } from '../reducers'
import { apiResetA8a } from '../api/ytd.api'

export const resetA8a = (ytdSubmissionId: string, employeeTaxNo: string): ThunkResult<void> => {
  return async dispatch => {
    if (!ytdSubmissionId) return

    try {
      dispatch(setA8aProcessing(true))
      const { status, errors, message, errorData } = await apiResetA8a(ytdSubmissionId, employeeTaxNo)
      if (status) {
        showSuccess('Appendix 8A reset')
      } else {
        if (Object.keys(errors).length === 0) {
          showError(message, errorData)
        }
      }
    } finally {
      dispatch(setA8aProcessing(false))
    }
  }
}
