import { showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { setIsAnyPayslipsOutdated } from '../reducers'
import { apiIsAnyPayslipsOutdated } from '../api/payslip.api'

export const isAnyPayslipsOutdated = (payRunId: string): ThunkResult<void> => {
  return async dispatch => {
    if (!payRunId) return

    try {
      const { result, status, errors, message, errorData } = await apiIsAnyPayslipsOutdated(payRunId)
      if (status) {
        dispatch(setIsAnyPayslipsOutdated({ payRunId, data: result.isOutdated }))
      } else {
        if (Object.keys(errors).length === 0) {
          showError(message, errorData)
        }
      }
    } finally {
    }
  }
}
