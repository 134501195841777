import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'
import { replaceTeConfigs, setTeConfigsLoading } from '../reducers'
import { apiGetTeConfigs } from '../api/te-config.api'

export const fetchTeConfigs = (options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().attendance.teConfigsLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().attendance.teConfigs?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setTeConfigsLoading(true))
      const { result, status } = await apiGetTeConfigs()
      if (status) {
        dispatch(replaceTeConfigs(result))
      }
    } finally {
      dispatch(setTeConfigsLoading(false))
    }
  }
}
