import { request, getBaseUrl, timeout, toQueryParams } from '~/utils/request'
import { IRptLveRecord } from '../types'

const baseUrl = getBaseUrl('/leave/rptlverecord')

export const apiDownloadLveRecordExcel = (params: IRptLveRecord) => {
  return request('get', `${baseUrl}/excel`, toQueryParams(params), { responseType: 'blob', timeout })
}

export const apiDownloadLveRecordPdf = (params: IRptLveRecord) => {
  return request('get', `${baseUrl}/pdf`, toQueryParams(params), { responseType: 'blob', timeout })
}
