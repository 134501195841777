import React, { FC, ChangeEvent, useState, useCallback, useEffect } from 'react'
import moment from 'moment-timezone'
import { Checkbox, CheckboxChangeEvent, Form, Input, Tag } from '~/core-components'
import { AttKeyValues, Col, ColorPicker, DEFAULT_COLOR, NewTabLinkIcon, Row } from '~/components'
import { Errors } from '~/types/store'
import { useFocus } from '~/hooks/use-focus'
import { AttScheduleType, Permission, PermissionAction } from '~/constants'
import { ShiftInfoState } from '../../../types'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { usePermissionGate } from '~/features/iam'

interface ShiftInfoFormProps {
  data: ShiftInfoState
  readOnly?: boolean
  errors?: Errors
  onChange: (data: ShiftInfoState) => void
}

export const EMPTY_SHIFT_INFO_FORM_DATA: ShiftInfoState = {
  name: '',
  inactiveDate: '',
  color: DEFAULT_COLOR,
  scheduleType: AttScheduleType.fixed,
  shiftCategoryId: ''
}

export const ShiftInfoForm: FC<ShiftInfoFormProps> = ({ data, readOnly, errors, onChange }) => {
  const [formData, setFormData] = useState<ShiftInfoState>(EMPTY_SHIFT_INFO_FORM_DATA)
  const [focusRef] = useFocus(!readOnly)
  const [isInactive, setIsInactive] = useState(false)

  const canModifyShiftCat = usePermissionGate(Permission.shiftCategory, PermissionAction.Modify)

  useEffect(() => {
    if (data) {
      setFormData(data)
      setIsInactive(!!data.inactiveDate)
    } else {
      setFormData(EMPTY_SHIFT_INFO_FORM_DATA)
      setIsInactive(false)
    }
  }, [data])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      const updated = { ...formData, ...updates }
      setFormData(updated)
      typeof onChange === 'function' && onChange(updated)
    },
    [formData, onChange]
  )

  return (
    <>
      <Row gutter={15}>
        <Col flex="auto">
          <Form.Item label={<>Name&nbsp;{formData.scheduleType === 'flexi' && <Tag>{formData.scheduleType}</Tag>}</>}>
            <Input
              ref={focusRef}
              value={formData.name}
              readOnly={readOnly}
              onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ name: event.target.value })}
            />
          </Form.Item>
        </Col>
        <Col flex="none">
          <Form.Item label="Inactive" validateStatus={errors?.inactiveDate ? 'error' : ''} help={errors?.inactiveDate}>
            <Checkbox
              checked={isInactive}
              readOnly={readOnly}
              onChange={(event: CheckboxChangeEvent) => {
                setIsInactive(event.target.checked)
                handleFormDataChange({ inactiveDate: event.target.checked ? moment().format('YYYY-MM-DD') : '' })
              }}
            />
          </Form.Item>
        </Col>
        <Col flex="none">
          <Form.Item label="Color" validateStatus={errors?.color ? 'error' : ''} help={errors?.color}>
            <ColorPicker
              color={formData.color}
              readOnly={readOnly}
              placement="bottom-right"
              onChange={(color: string) => handleFormDataChange({ color })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            label="Category"
            validateStatus={errors?.shiftCategoryId ? 'error' : ''}
            help={errors?.shiftCategoryId}
          >
            <NewTabLinkIcon
              path={SETTINGS_ROUTES.shiftCategories}
              tooltipText="Open shift category in a new tab"
              tooltipPlacement="topRight"
              hidden={!canModifyShiftCat}
            >
              <AttKeyValues
                id="shiftCategory"
                readOnly={readOnly}
                value={formData.shiftCategoryId}
                onChange={(shiftCategoryId: string) => handleFormDataChange({ shiftCategoryId })}
              />
            </NewTabLinkIcon>
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}
