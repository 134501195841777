import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { apiUpdateEmManager } from '../api/em-manager.api'
import { EmManagerState, IEmManagerInfo } from '../types'
import { setEmManager, setEmployee } from '../reducers'

export const updateEmManager = <T extends IEmManagerInfo>(
  employeeId: string,
  id: string,
  before: T,
  request: T
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!employeeId || !id) return

    const after = { ...before, ...request } as T
    const patch = generateJsonPatch<T>(before, after)
    const { result, status, errors, message, errorData } = await apiUpdateEmManager(employeeId, id, patch)

    if (status) {
      if (result.emManagerId) {
        const employee = getState().employee.employees.entities[employeeId]
        if (employee) {
          dispatch(setEmployee({ ...employee, emManagerId: result.emManagerId }))
        }
      }

      const emManager = getState().employee.emManagers[employeeId]?.entities[id]
      dispatch(
        setEmManager({
          employeeId,
          data: {
            ...(emManager as EmManagerState),
            ...after
          }
        })
      )

      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
