import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiDeleteManager } from '../api/manager.api'
import { removeManager } from '../reducers'
import { ManagerCategory } from '../types'

export const deleteManager = (category: ManagerCategory, id: string): ThunkResult<void> => {
  return async dispatch => {
    if (!id) return

    const { status, errors, message, errorData } = await apiDeleteManager(category, id)
    if (status) {
      dispatch(removeManager({ category, id }))

      showSuccess('Deleted')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
