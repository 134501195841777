import { request, getBaseUrl, timeout, toQueryParams } from '~/utils/request'
import { IRptTimeLog } from '../types'

const baseUrl = getBaseUrl('/attendance/rpttimelog')

export const apiDownloadTimeLogExcel = (params: IRptTimeLog) => {
  params.fieldIds = params.fieldIds?.filter(f => f !== '')

  return request('get', `${baseUrl}/excel`, toQueryParams(params), { responseType: 'blob', timeout })
}

export const apiDownloadTimeLogPdf = (params: IRptTimeLog) => {
  params.fieldIds = params.fieldIds?.filter(f => f !== '')

  return request('get', `${baseUrl}/pdf`, toQueryParams(params), { responseType: 'blob', timeout })
}
