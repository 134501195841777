import { showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiLockAllIr8aDrafts } from '../api/ytd.api'
import { Ir8aFormState } from '../types'
import { setIr8aForms, setIr8aFormLoading, setYtdSubmissionIr8aView } from '../reducers'

export const lockAllIr8aDrafts = (ytdSubmissionId: string): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!ytdSubmissionId) return

    try {
      dispatch(setIr8aFormLoading(true))
      const { result, status, errors, message, errorData } = await apiLockAllIr8aDrafts(ytdSubmissionId)
      if (status) {
        const ir8aForms = result.map(ir8a => {
          const ir8aForms = Object.values(getState().tax.ir8aForm?.entities || {}) as Ir8aFormState[]
          const ir8aForm = ir8aForms.find(a => a.id === ir8a.id)
          const data: Ir8aFormState = { ...(ir8aForm as Ir8aFormState), ...ir8a }
          return data
        })
        dispatch(setIr8aForms(ir8aForms))

        const ir8aViews = getState().tax.ytdSubmissionIr8aViews
        ir8aViews?.ids.forEach(viewId => {
          const ir8aView = ir8aViews?.entities[viewId]
          const found = ir8aView?.data.find(r => r.ytdSubmissionId === ytdSubmissionId)
          if (found && ir8aView) {
            const updates = ir8aView.data.map(d => {
              const nd = result.find(r => r.id === d.id)
              return nd ? { ...d, ...nd } : d
            })
            dispatch(setYtdSubmissionIr8aView({ ...ir8aView, data: updates }))
          }
        })
      } else {
        if (Object.keys(errors).length === 0) {
          showError(message, errorData)
        }
      }
      return { errors }
    } finally {
      dispatch(setIr8aFormLoading(false))
    }
  }
}
