import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { CtPolicyEtState } from '../types'
import { fetchCtPolicyEt } from '../actions'
import { selectCtPolicyEt } from '../selectors'

export const useCtPolicyEt = (
  claimTypeId: string,
  ctPolicyId: string,
  fetchStrategy: FetchStrategy = 'when-empty'
): [CtPolicyEtState[], boolean] => {
  const ctPolicyEt = useSelector(selectCtPolicyEt)(ctPolicyId)
  const loading = useSelector((state: StoreState) => state.claim.ctPolicyEtLoading[ctPolicyId])

  useEffect(() => {
    if (claimTypeId && ctPolicyId) {
      dispatch(fetchCtPolicyEt(claimTypeId, ctPolicyId, { strategy: fetchStrategy }))
    }
  }, [claimTypeId, ctPolicyId, fetchStrategy])

  return [ctPolicyEt, loading]
}
