import { request, getBaseUrl } from '~/utils'
import { IAddLeaveGrant, ILeaveGrant } from '../types'

const baseUrl = getBaseUrl('/leave/leavegrant')

export const apiAddLeaveGrant = (leaveGrant: IAddLeaveGrant) => request<{ id: string }>('post', baseUrl, leaveGrant)

export const apiGetLeaveGrants = (
  leaveTypeId: string,
  employeeId: string,
  hasBalance: boolean,
  validPeriodAsOf: string
) =>
  request<ILeaveGrant[]>('get', baseUrl, {
    leaveTypeId,
    employeeId,
    hasBalance,
    validPeriodAsOf
  })

export const apiGetLeaveGrantByLeaveRecord = (employeeId: string, leaveRecordId: string) =>
  request<ILeaveGrant[]>('get', baseUrl, {
    employeeId,
    leaveRecordId
  })

export const apiUnlinkLeaveGrant = (id: string) => request('post', `${baseUrl}/unlink/${id}`)

export const apiDeleteLeaveGrant = (id: string) => request('delete', `${baseUrl}/${id}`)
