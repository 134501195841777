import { replaceCalendarPatterns, setCalendarPatternsLoading } from '../reducers'
import { apiGetCalendarPatterns } from '../api/calendar.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchCalendarPatterns = (
  calendarId: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!calendarId) return

    const loading = getState().attendance.calendarPatternsLoading[calendarId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().attendance.calendarPatterns[calendarId]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setCalendarPatternsLoading({ calendarId, loading: true }))
      const { result, status } = await apiGetCalendarPatterns(calendarId)
      if (status) {
        dispatch(replaceCalendarPatterns({ calendarId, data: result }))
      }
    } finally {
      dispatch(setCalendarPatternsLoading({ calendarId, loading: false }))
    }
  }
}
