import { request, getBaseUrl } from '~/utils/request'
import { SSBirthdayState, SSCalendarViewerState, SSEmployeeState } from '../types'
import { KeyValue } from '~/types/common'

const baseUrl = getBaseUrl('/employee/ssemployee')

export const apiGetMe = () => request<SSEmployeeState>('get', `${baseUrl}/me`)

export const apiGetBirthdays = () => request<SSBirthdayState[]>('get', `${baseUrl}/birthday`)

export const apiGetMyCalendarViewers = () => request<SSCalendarViewerState[]>('get', `${baseUrl}/calendarviewer`)

export const apiGetMyCalViewAs = () => request<KeyValue[]>('get', `${baseUrl}/calendarviewas`)
