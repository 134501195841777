import { showSuccess, showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { setKeyvalue } from '~/features/master'
import { apiAddAttendanceGroup } from '../api/attendance-group.api'
import { IAttendanceGroupInfo } from '../types'
import { fetchAttendanceGroup } from './fetch-attendance-group'

export const addAttendanceGroup = (attendanceGroup: IAttendanceGroupInfo): ThunkResult<void> => {
  return async (dispatch): Promise<ActionResult> => {
    const { status, result, errors, message, errorData } = await apiAddAttendanceGroup(attendanceGroup)
    if (status) {
      dispatch(fetchAttendanceGroup(result.id))

      const { name, inactiveDate } = attendanceGroup
      dispatch(setKeyvalue({ kv: 'attendanceGroup', data: { key: result.id, value: name, inactiveDate } }))

      showSuccess('Added')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
