import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { XeroAccountState } from '../types'

export const selectXeroAccounts = createSelector(
  (state: StoreState) => state.payroll.xeroAccounts,
  xeroAccountsState =>
    memoize((tenantId: string): XeroAccountState[] => {
      return Object.values(xeroAccountsState[tenantId]?.entities || {}) as XeroAccountState[]
    })
)
