import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { EmCostCentreState, IEmCostCentre } from '../types'

const baseUrl = getBaseUrl('/employee/employee')

export const apiGetEmCostCentres = (employeeId: string) =>
  request<EmCostCentreState[]>('get', `${baseUrl}/${employeeId}/costcentre`)

export const apiGetEmCostCentre = (employeeId: string, emCostCentreId: string) =>
  request<EmCostCentreState>('get', `${baseUrl}/${employeeId}/costcentre/${emCostCentreId}`)

export const apiAddEmCostCentre = (employeeId: string, emCostCentre: IEmCostCentre) =>
  request<{ id: string; emCostCentreId?: string }>('post', `${baseUrl}/${employeeId}/costcentre`, emCostCentre)

export const apiUpdateEmCostCentre = (employeeId: string, id: string, emCostCentre: Operation[]) =>
  request<{ emCostCentreId?: string }>('patch', `${baseUrl}/${employeeId}/costcentre/${id}`, emCostCentre)

export const apiDeleteEmCostCentre = (employeeId: string, id: string) =>
  request<{ emCostCentreId?: string }>('delete', `${baseUrl}/${employeeId}/costcentre/${id}`)
