import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { EmJobState, IEmJob } from '../types'

const baseUrl = getBaseUrl('/employee/employee')

export const apiGetEmJobs = (employeeId: string) => request<EmJobState[]>('get', `${baseUrl}/${employeeId}/job`)

export const apiGetEmJob = (employeeId: string, emJobId: string) =>
  request<EmJobState>('get', `${baseUrl}/${employeeId}/job/${emJobId}`)

export const apiAddEmJob = (employeeId: string, emJob: IEmJob) =>
  request<{ id: string; emJobId?: string }>('post', `${baseUrl}/${employeeId}/job`, emJob)

export const apiUpdateEmJob = (employeeId: string, id: string, emJob: Operation[]) =>
  request<{ emJobId?: string }>('patch', `${baseUrl}/${employeeId}/job/${id}`, emJob)

export const apiDeleteEmJob = (employeeId: string, id: string) =>
  request<{ emJobId?: string }>('delete', `${baseUrl}/${employeeId}/job/${id}`)
