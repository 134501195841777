import React, { forwardRef, useEffect, useCallback, useState } from 'react'
import { ImageAuth } from '~/components'
import { Avatar, Form, SecondaryLink } from '~/core-components'
import { PlusCircleOutlined } from '@ant-design/icons'
import { ColProps } from 'antd/es/grid'
import { EmNamePhotoState } from '~/features/employee/types'
import { EmSelectionDrawer } from '~/features/employee'
import { apiGetEmNamePhoto } from '~/features/employee/api/employee.api'
import { ViewName } from '~/features/selection'
import { Pagination } from '~/types/common'
import { getBaseUrl } from '~/utils'
import './SelectEmployees.less'

export interface SelectEmployeesProps {
  viewName: ViewName
  label?: React.ReactNode
  labelCol?: ColProps
  employeeIds: string[]
  onFetchData: (viewId: string, pagination: Pagination, search: string, pastResignees: boolean) => void
  onSelect: (employeeIds: string[]) => void
}

interface DrawerState {
  visible: boolean
}
const DRAWER_STATE: DrawerState = { visible: false }

const baseUrl = getBaseUrl('/filestore')

const SelectEmployeesInternal = (
  { viewName, label, labelCol = { flex: 1 }, employeeIds, onFetchData, onSelect }: SelectEmployeesProps,
  ref: React.Ref<any>
) => {
  const [namePhotos, setNamePhotos] = useState<EmNamePhotoState[]>([])
  const [loading, setLoading] = useState(false)
  const [drawerState, setAddEmployeeDrawerState] = useState<DrawerState>(DRAWER_STATE)

  useEffect(() => {
    if (employeeIds && employeeIds.length > 0) {
      const fetchPhotoIds = async () => {
        try {
          setLoading(true)
          const { result } = await apiGetEmNamePhoto({ employeeIds })
          setNamePhotos(result)
        } finally {
          setLoading(false)
        }
      }
      fetchPhotoIds()
    }
  }, [employeeIds])

  const handleOpenDrawer = useCallback(() => {
    setAddEmployeeDrawerState({ visible: true })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setAddEmployeeDrawerState(DRAWER_STATE)
  }, [])

  return (
    <div className="select-employees">
      <Form.Item label={label} labelCol={labelCol}>
        {employeeIds && employeeIds.length > 0 && (
          <SecondaryLink ref={ref} onClick={handleOpenDrawer}>
            {employeeIds.slice(0, 3).map((employeeId, index) => {
              const photoId = namePhotos[index]?.photoId || undefined
              return (
                <span className={index > 0 ? 'layer-effect' : ''} key={employeeId}>
                  {!loading && photoId ? (
                    <Avatar
                      size={24}
                      src={
                        <ImageAuth
                          src={`${baseUrl}/file/${photoId}/thumbnailphoto/24`}
                          // src={employeeId && `/employee/employee/${employeeId}/avatar/24?photo_id=${photoId}`}
                          width={24}
                          preview={false}
                          defaultImage={<Avatar size={24} icon={<i className="fal fa-user" />} />}
                        />
                      }
                    />
                  ) : (
                    <Avatar size={24} icon={<i className="fal fa-user" />} />
                  )}
                </span>
              )
            })}
            {employeeIds.length > 3 && <span className="link-more">+{employeeIds.length - 3} more</span>}
          </SecondaryLink>
        )}
        {(!employeeIds || employeeIds.length === 0) && (
          <div className="div-avatar">
            <SecondaryLink ref={ref} onClick={handleOpenDrawer}>
              <PlusCircleOutlined />
            </SecondaryLink>
          </div>
        )}
      </Form.Item>
      <EmSelectionDrawer
        {...drawerState}
        viewName={viewName}
        employeeIds={employeeIds}
        onFetchData={onFetchData}
        onSelect={onSelect}
        onClose={handleCloseDrawer}
        resetOnClose
      />
    </div>
  )
}

export const SelectEmployees = forwardRef(SelectEmployeesInternal)
