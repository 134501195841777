import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { DivisionState } from '../types'

export interface DivisionOverview extends DivisionState {
  headName?: string
  headJob?: string
  headPhotoId?: string
  activeEmployees?: number
}

const EMPTY_DIVISION_EXTRA: Partial<DivisionOverview> = {
  headName: '',
  headJob: '',
  headPhotoId: '',
  activeEmployees: 0
}

export const selectDivisionsOverview = createSelector(
  (state: StoreState) => state.master.masters['division'],
  (state: StoreState) => state.master.divisionsExtra,
  (divisionsState, divisionsExtraState): DivisionOverview[] => {
    const divisions = Object.values(divisionsState?.entities || {})

    return divisions
      .map(division => ({
        ...(division as DivisionState),
        ...(divisionsExtraState?.entities[division!.id] || EMPTY_DIVISION_EXTRA)
      }))
      .sort((a, b) => {
        const aInactiveDate = new Date(a!.inactiveDate || '').getTime() || 0
        const bInactiveDate = new Date(b!.inactiveDate || '').getTime() || 0

        if (bInactiveDate === 0 && aInactiveDate === 0) {
          return a!.name.localeCompare(b!.name)
        } else {
          return aInactiveDate - bInactiveDate
        }
      })
  }
)
