import React, { FC, useState, useCallback, useEffect, memo, CSSProperties } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import confirm from 'antd/lib/modal/confirm'
import { Button, Checkbox, CheckboxChangeEvent, Form, Input, Space, Tag, UploadFile } from '~/core-components'
import { Col, DrawerForm, EmFieldValues, EmpKeyValues, Row, UploadFileSelectorAuth, YearKeyValues } from '~/components'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors } from '~/types/store'
import { useFocus } from '~/hooks/use-focus'
import { IEmEducationRequest, SSEmEducationPublicSelfState } from '~/features/my/types'
import { emptyGuid } from '~/constants'
import { applyEmEducationRequest, deleteEmEducationRequest } from '~/features/my/actions'
import { selectKeyvaluesById } from '~/features/master'
import { fetchEmEducationField } from '~/features/employee/actions/fetch-em-education-fields'
import { getBaseUrl } from '~/utils'

export interface MutateMyEducationDrawerProps {
  visible: boolean
  employeeId?: string
  data?: SSEmEducationPublicSelfState
  onClose: (action: 'saved' | 'cancelled') => void
}

const PendingTag = memo(() => <Tag type="secondary">pending</Tag>)

const EMPTY_FORM_DATA: IEmEducationRequest = {
  id: emptyGuid,
  employeeId: '',
  emEducationId: emptyGuid,
  isHighest: false,
  qualificationId: '',
  major: '',
  institute: '',
  startDate: '',
  endDate: '',
  graduationYear: ''
}

const currenTextStyle: CSSProperties = { fontSize: 13, color: '#797979' }
const checkboxStyle: CSSProperties = { marginLeft: 10 }
const baseUrl = getBaseUrl('/employee')

export const MutateMyEducationDrawer: FC<MutateMyEducationDrawerProps> = ({
  visible,
  employeeId,
  data,
  onClose
}: MutateMyEducationDrawerProps) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<IEmEducationRequest>(EMPTY_FORM_DATA)
  const [focusRef, setFocus] = useFocus(true)
  const [errors, setErrors] = useState<Errors>()
  const qualifications = useSelector(selectKeyvaluesById)('qualification')

  const isNew = data?.recordStatus === 'new_request'
  const isDiffIsHighest = !isNew && data?.isHighest !== data?.isHighestNew
  const isDiffQualificationId = !isNew && data?.qualificationId !== data?.qualificationIdNew
  const isDiffMajor = !isNew && data?.major !== data?.majorNew
  const isDiffInstitute = !isNew && data?.institute !== data?.instituteNew
  const isDiffStartDate = !isNew && data?.startDate !== data?.startDateNew
  const isDiffEndDate = !isNew && data?.endDate !== data?.endDateNew
  const isDiffGraduationYear = !isNew && data?.graduationYear !== data?.graduationYearNew

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  useEffect(() => {
    if (data) {
      let attachment: UploadFile | undefined
      if (data.attachmentId) {
        attachment = {
          uid: data.attachmentId,
          name: data.attachmentName || '',
          size: data.attachmentSize || 0,
          type: data.attachmentFormat || '',
          url: `${baseUrl}/ssemployeerequest/education/${data.requestId}/downloadfile`
        }
      }

      setFormData({
        id: data.requestId || emptyGuid,
        employeeId: employeeId || emptyGuid,
        emEducationId: data.id,
        isHighest: data.isHighestNew,
        qualificationId: data.qualificationIdNew,
        major: data.majorNew,
        institute: data.instituteNew,
        startDate: data.startDateNew,
        endDate: data.endDateNew,
        graduationYear: data.graduationYearNew,
        attachment
      })
    } else {
      setFormData({ ...EMPTY_FORM_DATA, employeeId: employeeId || emptyGuid })
    }
  }, [data, employeeId])

  useEffect(() => {
    if (visible) {
      dispatch(fetchEmEducationField('major'))
      dispatch(fetchEmEducationField('institute'))
    }
  }, [visible])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      result = await dispatch(applyEmEducationRequest(formData))
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose('saved')
      setFormData(EMPTY_FORM_DATA)
    }
  }, [formData, onClose])

  const handleDelete = useCallback(
    (myEducation: SSEmEducationPublicSelfState | undefined) => {
      if (employeeId && myEducation) {
        const { requestId, instituteNew } = myEducation
        confirm({
          title: 'Delete education',
          content: `Do you want to delete education record of "${instituteNew}"?`,
          onOk: async () => {
            const result: ActionResult | undefined = await dispatch(deleteEmEducationRequest(requestId))
            if (result?.errors) {
              setErrors(result.errors)
            }

            if (!result?.errors) {
              typeof onClose === 'function' && onClose('saved')
            }
          },
          okText: 'Delete',
          okType: 'danger'
        })
      }
    },
    [employeeId, onClose]
  )

  const handleFileChange = useCallback(
    (attachments?: UploadFile[]) => {
      if (attachments && attachments.length > 0) {
        setFormData({ ...formData, attachment: attachments[0] })
      }
    },
    [formData]
  )

  const handleFileRemove = useCallback(
    (file?: UploadFile) => {
      if (file) {
        setFormData({ ...formData, attachment: undefined, deletedAttachmentId: file.uid })
      }
    },
    [formData]
  )

  return (
    <DrawerForm
      open={visible}
      title={
        data ? <Space>Edit education {isNew && <Tag type="secondary">new (pending)</Tag>}</Space> : 'Add education'
      }
      okText={data?.recordStatus === 'has_request' ? 'Resubmit' : 'Submit'}
      onClose={() => onClose('cancelled')}
      confirmLoading={loading}
      width={600}
      showDelete={data ? (data?.recordStatus !== 'no_request' ? true : false) : false}
      onDelete={() => handleDelete(data)}
      formId="form-em-education"
    >
      <Form id="form-em-education" onFinish={handleOk}>
        <Row>
          <Col flex="250px">
            <Form.Item
              label={<Space>Qualification {isDiffQualificationId && <PendingTag />}</Space>}
              validateStatus={errors?.qualificationId ? 'error' : ''}
              help={errors?.qualificationId}
            >
              <EmpKeyValues
                id="qualification"
                ref={focusRef}
                value={formData.qualificationId}
                onChange={(qualificationId: string) => handleFormDataChange({ qualificationId })}
                style={{ width: 250 }}
              />
              {isDiffQualificationId && (
                <Space style={currenTextStyle}>
                  Current: {qualifications[data?.qualificationId || '']?.value || '-'}
                </Space>
              )}
            </Form.Item>
          </Col>
          <Col flex={1}>
            <Form.Item label=" ">
              <Checkbox
                checked={formData.isHighest}
                onChange={(event: CheckboxChangeEvent) => handleFormDataChange({ isHighest: event.target.checked })}
                style={checkboxStyle}
              >
                {<Space>Highest qualification {isDiffIsHighest && <PendingTag />}</Space>}
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label={<Space>Major {isDiffMajor && <PendingTag />}</Space>}>
              <EmFieldValues
                field="major"
                value={formData.major}
                onChange={(major?: string) => handleFormDataChange({ major })}
              />
              {isDiffMajor && <Space style={currenTextStyle}>Current: {data?.major || '-'}</Space>}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label={<Space>Institute {isDiffInstitute && <PendingTag />}</Space>}
              validateStatus={errors?.institute ? 'error' : ''}
              help={errors?.institute}
            >
              <EmFieldValues
                field="institute"
                value={formData.institute}
                onChange={(institute?: string) => handleFormDataChange({ institute })}
              />
              {isDiffInstitute && <Space style={currenTextStyle}>Current: {data?.institute || '-'}</Space>}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={15}>
          <Col span={12}>
            <Form.Item
              label={<Space>Start {isDiffStartDate && <PendingTag />}</Space>}
              validateStatus={errors?.startDate ? 'error' : ''}
              help={errors?.startDate}
            >
              <Input.Date
                value={formData.startDate ? moment(formData.startDate) : undefined}
                onChange={(value: moment.Moment | null) =>
                  handleFormDataChange({ startDate: value?.format('YYYY-MM-DD') })
                }
              />
              {isDiffStartDate && (
                <div style={currenTextStyle}>
                  Current: {data?.startDate ? moment(data?.startDate).format('DD MMM YYYY') : '-'}
                </div>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={<Space>End {isDiffEndDate && <PendingTag />}</Space>}>
              <Input.Date
                value={formData.endDate ? moment(formData.endDate) : undefined}
                onChange={(value: moment.Moment | null) =>
                  handleFormDataChange({ endDate: value?.format('YYYY-MM-DD') })
                }
              />
              {isDiffEndDate && (
                <div style={currenTextStyle}>
                  Current: {data?.endDate ? moment(data?.endDate).format('DD MMM YYYY') : '-'}
                </div>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              label={<Space>Graduation year {isDiffGraduationYear && <PendingTag />}</Space>}
              validateStatus={errors?.graduationYear ? 'error' : ''}
              help={errors?.graduationYear}
            >
              <YearKeyValues
                noOfYearBefore={-60}
                noOfYearAfter={6}
                sortingOrder="desc"
                value={formData.graduationYear}
                onChange={(value: string) => handleFormDataChange({ graduationYear: value })}
              />
              {isDiffGraduationYear && <div style={currenTextStyle}>Current: {data?.graduationYear || '-'}</div>}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Attachment" validateStatus={errors?.attachment ? 'error' : ''} help={errors?.attachment}>
              <UploadFileSelectorAuth
                multiple={false}
                accept=".gif,.png,.jpeg,.jpg"
                fileList={formData.attachment ? [formData.attachment] : []}
                listType="picture-card"
                onChange={handleFileChange}
                onRemove={handleFileRemove}
                withCache={false}
              >
                <Button size="small">choose file</Button>
              </UploadFileSelectorAuth>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}
