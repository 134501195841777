import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { generateJsonPatch } from '~/utils'
import { apiUpdateShiftTe } from '../api/shift-te.api'
import { ShiftTeState, IShiftTeInfo } from '../types'
import { setShiftTe } from '../reducers'

export const updateShiftTe = (
  shiftId: string,
  id: string,
  before: IShiftTeInfo,
  request: IShiftTeInfo
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!shiftId || !id) return

    const after = { ...before, ...request } as IShiftTeInfo
    const patch = generateJsonPatch<IShiftTeInfo>(before, after)
    const { status, errors, message, errorData } = await apiUpdateShiftTe(shiftId, id, patch)

    if (status) {
      const shiftTe = getState().attendance.shiftTes[shiftId]?.entities[id] || {}
      dispatch(
        setShiftTe({
          shiftId,
          data: {
            ...(shiftTe as ShiftTeState),
            ...after,
            id
          }
        })
      )

      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
