import { showError, showSuccess } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiUpdateCtProrationM } from '../api/ct-proration-m.api'
import { replaceCtProrationM } from '../reducers'
import { ICtProrationMMutate, CtProrationMState } from '../types'

export const updateCtProrationM = (claimTypeId: string, data: CtProrationMState[]): ThunkResult<void> => {
  return async dispatch => {
    if (!claimTypeId) return

    let submit: ICtProrationMMutate = {
      claimTypeId,
      ctProrationM: data
    }
    const { result, status, errors, message, errorData } = await apiUpdateCtProrationM(claimTypeId, submit)
    if (status) {
      dispatch(replaceCtProrationM({ claimTypeId, data: result }))
      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
