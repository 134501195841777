import React, { FC, useCallback, useEffect, useState } from 'react'
import { SketchPicker } from 'react-color'
import classNames from 'classnames'
import './ColorPicker.less'

interface ColorPickerProps {
  color?: string
  readOnly?: boolean
  onChange?: (color: string) => void
  placement?: 'bottom-left' | 'bottom-right'
}

export const DEFAULT_COLOR = '#ffd1e4'

export const ColorPicker: FC<ColorPickerProps> = ({ color, readOnly, placement = 'bottom-left', onChange }) => {
  const [newColor, setNewColor] = useState(DEFAULT_COLOR)
  const [showPicker, setShowPicker] = useState(false)
  const popoverClasses = classNames('color-picker__popover', `color-picker__popover--${placement}`)

  useEffect(() => setNewColor(color || DEFAULT_COLOR), [color])

  const handleClick = useCallback(() => {
    if (readOnly) return

    setShowPicker(show => !show)
  }, [readOnly])

  const handleClose = useCallback(() => {
    setShowPicker(false)
  }, [])

  const handleChange = useCallback(
    color => {
      setNewColor(color.rgb)
      typeof onChange === 'function' && onChange(color.hex)
    },
    [onChange]
  )

  return (
    <div className="color-picker">
      <div className="color-picker__swatch" onClick={handleClick}>
        <div className="color-picker__color" style={{ background: newColor }} />
      </div>
      {!readOnly && showPicker && (
        <div className={popoverClasses}>
          <div className="color-picker__cover" onClick={handleClose} />
          <SketchPicker color={newColor} onChangeComplete={handleChange} />
        </div>
      )}
    </div>
  )
}
