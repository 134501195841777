import React, { FC } from 'react'
import { ReactComponent as CircleFull } from '../../../../../svgs/circle-full.svg'
import { ReactComponent as CircleHalf } from '../../../../../svgs/circle-half.svg'
import { ReactComponent as CircleOutlined } from '../../../../../svgs/circle-outlined.svg'
import './WorkStatusIcon.less'

export type WorkStatusIconType = 'f' | 'h1' | 'o' | 'r'

interface WorkStatusProps {
  workStatus?: WorkStatusIconType
}

export const WorkStatusIcon: FC<WorkStatusProps> = ({ workStatus }) => (
  <div className="work-status-icon">
    {workStatus === 'f' ? <CircleFull /> : workStatus === 'h1' ? <CircleHalf /> : <CircleOutlined />}
  </div>
)
