import { createSelector } from '@reduxjs/toolkit'
import moment from 'moment-timezone'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { SSLeaveRecordCommentState } from '../../types'

export interface ILeaveRecordComment {
  id: string
  authorId: string
  author: string
  photoId: string
  content: string
  datetime: string
  createdDate: string
  updatedDate: string
}

export const mapSSLeaveRecordCommentStateToILeaveRecordComment = (
  comments: SSLeaveRecordCommentState[] | undefined = []
) =>
  comments
    .map(
      c =>
        ({
          id: c.id,
          authorId: c.authorId,
          author: c.authorName,
          photoId: c.photoId,
          content: c.comment,
          datetime: c.createdDate ? moment(c.createdDate).fromNow() : undefined,
          createdDate: c.createdDate,
          updatedDate: c.updatedDate
        } as ILeaveRecordComment)
    )
    .sort((a, b) => {
      const aCreatedDate = new Date(a!.createdDate).getTime()
      const bCreatedDate = new Date(b!.createdDate).getTime()
      return bCreatedDate - aCreatedDate
    })

export const selectMyLeaveRecordComments = createSelector(
  (state: StoreState) => state.my.myLeaveRecordComments,
  myLeaveRecordCommentsState =>
    memoize((id: string): ILeaveRecordComment[] =>
      mapSSLeaveRecordCommentStateToILeaveRecordComment(
        Object.values(myLeaveRecordCommentsState[id]?.entities || {}) as SSLeaveRecordCommentState[]
      )
    )
)
