import { removeLeaveGrants } from '../reducers'
import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiApplyLeaveRecord } from '../api/leave-apply.api'
import { ILeaveApply, LeaveGrantState } from '../types'

export const applyLeaveRecord = (id: string, leaveRecord: ILeaveApply): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const { status, result, errors, message, errorData } = await apiApplyLeaveRecord(id, leaveRecord)

    if (status) {
      if (!!result.data) {
        const leaveGrants = Object.values(
          getState().leave.leaveGrants[leaveRecord.employeeId]?.entities || {}
        ) as LeaveGrantState[]
        const lgIds = leaveGrants.filter(lg => lg?.leaveRecordId === id).map(lg => lg.id)
        dispatch(removeLeaveGrants({ employeeId: leaveRecord.employeeId, ids: lgIds }))

        showSuccess('Saved')
      }
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
