import React, { FC, useCallback, useMemo, useState } from 'react'
import moment from 'moment-timezone'
import { Card, ColumnsType, Link, Table, Tag, Tooltip } from '~/core-components'
import { SSEmIdentityPublicSelfState } from '../../../../../types'
import { fetchEmPublic, maskString, useSysOptions } from '~/features/employee'
import { useToggle } from '~/hooks/use-toggle'
import { MutateMyIdentityDrawer } from './MutateMyIdentityDrawer'
import { dispatch } from '~/stores/store'
import './MyIdentities.less'

interface MyIdentitiesProps {
  employeeId: string
  data: SSEmIdentityPublicSelfState[]
  hideSelfUpdate: boolean
}

interface DrawerState {
  visible: boolean
  employeeId?: string
  data?: SSEmIdentityPublicSelfState
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }
const TODAY = moment().format('YYYY-MM-DD')

export const MyIdentities: FC<MyIdentitiesProps> = ({ employeeId, data, hideSelfUpdate }) => {
  const [showExpired, toggleShowExpired] = useToggle()
  const [identityTypes] = useSysOptions('identity_type')
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)

  const dataSource = useMemo(() => {
    return data
      ?.filter(emi => (showExpired ? true : (emi?.cancelledDate || emi?.expiryDate || '9999-12-31') >= TODAY))
      .sort((a, b) => {
        const aIssueDate = new Date(a!.issueDate).getTime()
        const bIssueDate = new Date(b!.issueDate).getTime()
        const aExpiryDateStr = a!.cancelledDate ? a!.cancelledDate : a!.expiryDate
        const aExpiryDate = !!aExpiryDateStr ? new Date(aExpiryDateStr).getTime() : 0
        const bExpiryDateStr = b!.cancelledDate ? b!.cancelledDate : b!.expiryDate
        const bExpiryDate = !!bExpiryDateStr ? new Date(bExpiryDateStr).getTime() : 0

        if (bExpiryDate === 0 && aExpiryDate === 0) {
          return bIssueDate - aIssueDate
        } else {
          if (bExpiryDate === 0) {
            return 1
          } else if (aExpiryDate === 0) {
            return -1
          } else {
            return bExpiryDate - aExpiryDate
          }
        }
      })
  }, [data, showExpired])

  const hasExpired = useMemo(() => {
    return data?.filter(emi => (emi?.cancelledDate || emi?.expiryDate || '9999-12-31') < TODAY).length > 0
  }, [data])

  const isAllExpired = useMemo(() => {
    return data?.filter(emi => (emi?.cancelledDate || emi?.expiryDate || '9999-12-31') < TODAY).length === data?.length
  }, [data])

  if (isAllExpired && !showExpired) {
    toggleShowExpired()
  }

  const columns: ColumnsType<SSEmIdentityPublicSelfState> = [
    {
      title: 'Type',
      key: 'identityTypeNew',
      dataIndex: 'identityTypeNew',
      render: (value: string, record) => (
        <>
          {identityTypes[value]?.value} {record.isMain && <Tag type="primary">main</Tag>}
          {record.recordStatus === 'new_request' && <Tag type="secondary">new (pending)</Tag>}
          {record.recordStatus === 'has_request' && <Tag type="secondary">pending</Tag>}
        </>
      )
    },
    {
      title: 'Identity no.',
      key: 'identityNoNew',
      dataIndex: 'identityNoNew',
      render: (value: string) => <Tooltip title={value}>{maskString(value)}</Tooltip>
    },
    {
      title: 'Issue date',
      key: 'issueDateNew',
      dataIndex: 'issueDateNew',
      render: (value: string) => value && moment(value).format('DD MMM YYYY')
    },
    {
      title: 'Cancelled / expiry date',
      key: 'expiryDateNew',
      dataIndex: 'expiryDateNew',
      render: (value: string, record) => {
        const valueDate = record.cancelledDateNew ? record.cancelledDateNew : value
        return valueDate && moment(valueDate).format('DD MMM YYYY')
      }
    },
    {
      key: 'action',
      align: 'right',
      render: (value: string, record) =>
        !hideSelfUpdate && (
          <Link size="small" onClick={() => handleEditIdentity(record)}>
            edit
          </Link>
        )
    }
  ]

  const handleAddIdentity = useCallback(() => {
    setDrawerState({ visible: true, employeeId, data: undefined })
  }, [employeeId])

  const handleEditIdentity = useCallback(
    (myIdentity: SSEmIdentityPublicSelfState) => {
      setDrawerState({ visible: true, employeeId, data: myIdentity })
    },
    [employeeId]
  )

  const handleCloseDrawer = useCallback(
    (action: 'saved' | 'cancelled') => {
      setDrawerState(DEFAULT_DRAWER_STATE)
      if (action === 'saved') {
        dispatch(fetchEmPublic(employeeId, { strategy: 'always' }))
      }
      setDrawerState(DEFAULT_DRAWER_STATE)
    },
    [employeeId]
  )

  return (
    <Card
      title="Personal identification"
      className="my-identities"
      extra={employeeId && !hideSelfUpdate && <Link onClick={handleAddIdentity}>add</Link>}
    >
      <Table
        rowKey="id"
        dataSource={dataSource}
        pagination={false}
        columns={columns}
        {...(hasExpired && !isAllExpired ? { showMore: showExpired, onShowMoreToggle: toggleShowExpired } : {})}
      />
      {!hideSelfUpdate && <MutateMyIdentityDrawer {...drawerState} onClose={handleCloseDrawer} />}
    </Card>
  )
}
