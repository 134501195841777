import { replaceMyLeaveTasks, setMyLeaveTasksLoading } from '../../reducers'
import { apiGetMyLeaveTasks } from '../../api/ssleave-task.api'
import { ThunkResult } from '~/types/store'

export const fetchMyLeaveTasks = (): ThunkResult<void> => {
  return async dispatch => {
    try {
      dispatch(setMyLeaveTasksLoading(true))
      const { result, status } = await apiGetMyLeaveTasks()
      if (status) dispatch(replaceMyLeaveTasks(result))
    } finally {
      dispatch(setMyLeaveTasksLoading(false))
    }
  }
}
