import React, { forwardRef } from 'react'
import range from 'lodash/range'
import classNames from 'classnames'
import padStart from 'lodash/padStart'
import { Select, SelectProps } from '~/core-components'
import './PeriodKeyValues.less'

interface PeriodKeyValuesProps extends SelectProps {
  startMonth?: string
  noOfMonthBefore: number
  noOfMonthAfter: number
}

const PeriodKeyValuesInternal = (
  { startMonth, noOfMonthBefore, noOfMonthAfter, className, ...props }: PeriodKeyValuesProps,
  ref: React.Ref<any>
) => {
  const classes = classNames('periodkeyvalues', { [`${className}`]: className })

  let start = startMonth ? new Date(`${startMonth}-01`) : new Date()
  start = new Date(start.setMonth(start.getMonth() + noOfMonthBefore))
  let end = new Date(`${startMonth}-01`)
  end = new Date(end.setMonth(end.getMonth() + noOfMonthAfter))

  return (
    <Select ref={ref} showSearch className={classes} optionFilterProp="title" {...props}>
      {range(start.getFullYear() * 12 + start.getMonth() + 1, end.getFullYear() * 12 + end.getMonth() + 2).map(
        count => {
          const isDecember = count % 12 === 0
          const year = Math.floor(count / 12 + (isDecember ? -1 : 0))
          const month = padStart((isDecember ? 12 : count % 12).toString(), 2, '0')
          const value = `${year}-${month}`
          return (
            <Select.Option key={count} value={value} title={value}>
              {value}
            </Select.Option>
          )
        }
      )}
    </Select>
  )
}

export const PeriodKeyValues = forwardRef(PeriodKeyValuesInternal)
