import { IRule, RuleState } from './rule.state'

export const mapRuleToRuleState = (dto: IRule): RuleState => ({
  id: dto.id,
  ruleCriteriaId: dto.ruleCriteriaId,
  referenceId: dto.referenceId,
  isEnabled: dto.isEnabled,
  tag: dto.tag,
  color: dto.color,
  params: JSON.parse(dto.paramJsonb || '{}')
})

export const mapRuleStateToRule = (state: RuleState): IRule => ({
  id: state.id,
  ruleCriteriaId: state.ruleCriteriaId,
  referenceId: state.referenceId,
  isEnabled: state.isEnabled,
  tag: state.tag,
  color: state.color,
  paramJsonb: JSON.stringify(state.params)
})
