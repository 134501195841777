import React, { FC, useMemo } from 'react'
import { Button, ButtonProps, Drawer, DrawerProps, Space } from '../../core-components'
import classNames from 'classnames'
import './DrawerForm.less'

export interface DrawerFormProps extends DrawerProps {
  onOk?: () => void
  okText?: string | React.ReactNode
  okDisabled?: boolean
  confirmLoading?: boolean
  showDelete?: boolean
  deleteText?: string
  deleteDisabled?: boolean
  onDelete?: () => void
  deleteLoading?: boolean
  formId?: string
  extras?: React.ReactNode
  hideFooter?: boolean
}

export const DrawerForm: FC<DrawerFormProps> = ({
  onOk,
  okText = 'Save',
  okDisabled = false,
  confirmLoading,
  showDelete = false,
  deleteText = 'Delete',
  deleteDisabled = false,
  deleteLoading,
  onDelete,
  formId,
  extras,
  hideFooter = false,
  ...props
}: DrawerFormProps) => {
  const footer: React.ReactNode = useMemo(() => {
    const submitProps: Partial<ButtonProps> = formId ? { form: formId, htmlType: 'submit' } : { onClick: onOk }
    return (
      <div
        className={classNames('drawer__footer', { 'drawer__footer--space-between': showDelete })}
        hidden={hideFooter}
      >
        {showDelete && (
          <Button onClick={onDelete} disabled={deleteDisabled} loading={deleteLoading}>
            {deleteText}
          </Button>
        )}
        <Space>
          {extras}
          {okText && (
            <Button type="primary" loading={confirmLoading} disabled={okDisabled} {...submitProps}>
              {okText}
            </Button>
          )}
        </Space>
      </div>
    )
  }, [
    formId,
    onOk,
    hideFooter,
    showDelete,
    onDelete,
    deleteDisabled,
    deleteText,
    deleteLoading,
    extras,
    okText,
    confirmLoading,
    okDisabled
  ])

  return <Drawer maskClosable={false} footer={footer} {...props} />
}
