import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { PayRunState } from '../types'

export const selectPayRuns = createSelector(
  (state: StoreState) => state.payroll.payRuns,
  payRunsState =>
    memoize(() => {
      return (Object.values(payRunsState?.entities || {}) as PayRunState[]).sort((a, b) => {
        const aPayDate = new Date(a!.payDate).getTime()
        const bPayDate = new Date(b!.payDate).getTime()
        return bPayDate - aPayDate
      })
    })
)
