import { EntityState } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { SysSelectionFieldState, SysSelectionScreenState } from './selection.state'
import { SysCriteriaFieldState, SysCriteriaOptionKeyValue, SysCriteriaScreenState } from './criteria.state'
import { ViewSchemaState, ViewSelection, ViewState } from './view.state'
import { Screen } from './dto'

export type ViewSelectionField = ViewSelection & SysSelectionFieldState

export const selectionPersistConfig = {
  key: 'selection',
  whitelist: [
    'viewSchema',
    'views',
    'sysSelectionScreens',
    'sysSelectionFields',
    'sysSelectionFieldsRefetch',
    'sysCriteriaScreens',
    'sysCriteriaFields',
    'sysCriteriaFieldsRefetch',
    'sysCriteriaOptions'
  ],
  storage
}

export interface SelectionRootState {
  viewSchema: { [screenCode in Screen]?: EntityState<ViewSchemaState> }
  viewSchemaLoading: { [screenCode in Screen]?: { [viewId: string]: boolean } }
  viewSchemaByNameLoading: { [screenCode in Screen]?: { [viewName: string]: boolean } }
  views: { [screenCode in Screen]?: EntityState<ViewState> }
  viewsLoading: { [screenCode in Screen]?: boolean }
  viewLoading: { [screenCode in Screen]?: { [viewId: string]: boolean } }
  sysSelectionScreens: { [screenCode in Screen]?: EntityState<SysSelectionScreenState> }
  sysSelectionScreensLoading: { [screenCode in Screen]?: boolean }
  sysSelectionFields: { [screenCode in Screen]?: EntityState<SysSelectionFieldState> }
  sysSelectionFieldsLoading: { [screenCode in Screen]?: boolean }
  sysSelectionFieldsRefetch: number
  sysCriteriaScreens: { [screenCode in Screen]?: EntityState<SysCriteriaScreenState> }
  sysCriteriaScreensLoading: { [screenCode in Screen]?: boolean }
  sysCriteriaFields: { [screenCode in Screen]?: EntityState<SysCriteriaFieldState> }
  sysCriteriaFieldsLoading: { [screenCode in Screen]?: boolean }
  sysCriteriaFieldsRefetch: number
  sysCriteriaOptions: { [code: string]: EntityState<SysCriteriaOptionKeyValue> }
  sysCriteriaOptionsLoading: { [code: string]: boolean }
  sysCriteriaOptionsRefetch: { [code: string]: number }
}
