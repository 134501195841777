import { replaceEmIdentityRequests, setEmIdentityRequestsLoading } from '../reducers'
import { apiGetEmIdentityRequests } from '../api/em-identity-request.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchEmIdentityRequests = (
  employeeId: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!employeeId) return

    const loading = getState().employee.emIdentityRequestsLoading[employeeId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().employee.emIdentityRequests[employeeId]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setEmIdentityRequestsLoading({ employeeId, loading: true }))
      const { result, status } = await apiGetEmIdentityRequests(employeeId)
      if (status) {
        dispatch(replaceEmIdentityRequests({ employeeId, data: result }))
      }
    } finally {
      dispatch(setEmIdentityRequestsLoading({ employeeId, loading: false }))
    }
  }
}
