import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'

export const selectClaimRecordAttachments = createSelector(
  (state: StoreState) => state.claim.claimRecordAttachments,
  claimRecordAttachmentsState =>
    memoize((claimRecordId?: string) => {
      return Object.values(claimRecordAttachmentsState?.entities || {}).filter(
        cra => cra?.claimRecordId === claimRecordId
      )
    })
)
