import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchIsEmployee } from '../actions'
import { IsEmployeeState } from '../types'

export const useIsEmployee = (
  type: 'email' | 'employee_no',
  loginCode: string,
  fetchStrategy: FetchStrategy = 'when-empty'
): [IsEmployeeState | undefined, boolean] => {
  const member = useSelector((state: StoreState) => state.iam.isEmployees?.entities[loginCode])
  const loading = useSelector((state: StoreState) => state.iam.isEmployeesLoading)
  const isMember = member?.isEmployee || false

  useEffect(() => {
    if (loginCode && !isMember) {
      dispatch(fetchIsEmployee(type, loginCode, { strategy: fetchStrategy }))
    }
  }, [loginCode, isMember, fetchStrategy, type])

  return [member, loading]
}
