import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { PayTranGroup } from '~/constants'
import { PayTranSgState } from '../types'

export const selectPayRecordsTrans = createSelector(
  (state: StoreState) => state.payroll.payTrans,
  payTransState =>
    memoize((payRecordId: string): PayTranSgState[] => {
      const result: PayTranSgState[] = []
      let payTrans = Object.values(payTransState[payRecordId]?.entities || {}) as PayTranSgState[]
      payTrans = payTrans.filter(rec =>
        [
          PayTranGroup.basicPay,
          PayTranGroup.recurring,
          PayTranGroup.overtime,
          PayTranGroup.adhoc,
          PayTranGroup.leave
        ].includes(rec.group)
      )
      if (payTrans) {
        result.push(...payTrans)
      }

      result.sort((a, b) => {
        const aSequence = a!.sequence
        const bSequence = b!.sequence
        return aSequence - bSequence
      })

      return result
    })
)
