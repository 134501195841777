import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Dictionary } from '@reduxjs/toolkit'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { FetchStrategy } from '~/types/common'
import { fetchShiftCategories } from '../actions'
import { ShiftCategoryState } from '../types'

export const useShiftCategoriesDict = (
  fetchStrategy: FetchStrategy = 'when-empty'
): [Dictionary<ShiftCategoryState>, boolean] => {
  const data = useSelector((state: StoreState) => state.attendance.shiftCategories.entities)
  const loading = useSelector((state: StoreState) => state.attendance.shiftCategoriesLoading)

  useEffect(() => {
    dispatch(fetchShiftCategories({ strategy: fetchStrategy }))
  }, [fetchStrategy])

  return [data, loading]
}
