import { request, getBaseUrl, timeout } from '~/utils/request'
import { PayslipState } from '../types'

const baseUrl = getBaseUrl('/payroll-sg/payslip')

export const apiGetPayslip = (payRunId: string, employeeId: string) =>
  request<PayslipState>('get', `${baseUrl}/${payRunId}/${employeeId}`)

export const apiGeneratePayslipPdf = (payRunId: string, employeeIds: string[]) =>
  request<void>('post', `${baseUrl}/generate`, { payRunId, employeeIds })

export const apiGetPayslipPdf = (payRunId: string, employeeId: string) =>
  request('get', `${baseUrl}/${payRunId}/${employeeId}/pdf`, undefined, { responseType: 'blob', timeout })

export const apiGetPayslipPdfPassword = (payRunId: string, employeeId: string, password: string) =>
  request('get', `${baseUrl}/${payRunId}/${employeeId}/pdf-password`, { password }, { responseType: 'blob', timeout })

export const apiExportAllPayslips = (payRunId: string) =>
  request('get', `${baseUrl}/${payRunId}/exportall`, undefined, { timeout })

export const apiIsPayslipOutdated = (payRunId: string, employeeId: string) =>
  request<{ isOutdated: boolean }>('get', `${baseUrl}/${payRunId}/${employeeId}/isoutdated`)

export const apiIsAnyPayslipsOutdated = (payRunId: string) => request('get', `${baseUrl}/${payRunId}/isoutdated`)
