import { FC, useEffect, useState } from 'react'
import { Checkbox, CheckboxChangeEvent, Form, Radio, RadioChangeEvent, Space } from '~/core-components'
import { Col, ErrorDisplay, Row } from '~/components'
import { useAttendancePeriodsForPayroll } from '~/features/attendance'
import { Errors } from '~/types/store'
import { PayRunState } from '../../../../types'
import { ImportDailyFormData } from '../ImportDailyDrawer'
import './ImportDailyInput.less'

interface ImportDailyInputProps {
  visible: boolean
  payRun?: PayRunState
  data: Partial<ImportDailyFormData>
  errors?: Errors
  onChange: (data: Partial<ImportDailyFormData>) => void
}

export const ImportDailyInput: FC<ImportDailyInputProps> = ({ visible, payRun, data, errors, onChange }) => {
  const [isAllPeriod, setIsAllPeriod] = useState(false)
  const [periods, periodsLoading] = useAttendancePeriodsForPayroll(isAllPeriod ? undefined : payRun?.payPeriod)

  useEffect(() => {
    if (periods.length === 1) {
      onChange({ attendancePeriodId: periods[0].id })
    }
  }, [periods, onChange])

  return (
    <div className="import-daily-input" hidden={!visible}>
      <Form layout="horizontal" labelCol={{ span: 3 }} labelAlign="right">
        <Row>
          <Col span={24}>
            <Form.Item label="Show all periods">
              <Checkbox
                checked={isAllPeriod}
                onChange={(event: CheckboxChangeEvent) => {
                  setIsAllPeriod(event.target.checked)
                }}
              />
            </Form.Item>
            <Form.Item label="Attendance period">
              <Radio.Group
                loading={periodsLoading}
                value={data.attendancePeriodId}
                onChange={(event: RadioChangeEvent) => onChange({ attendancePeriodId: event.target.value })}
              >
                <Space direction="vertical">
                  {periods.map(p => (
                    <Radio key={p.id} value={p.id}>
                      {p.name}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <ErrorDisplay errors={errors} />
      </Form>
    </div>
  )
}
