import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { EmploymentConfigState } from '../types'
import { apiUpdateEmploymentConfig } from '../api/employment-config.api'
import { setEmploymentConfig } from '../reducers'
import {
  clearSysCriteriaFields,
  clearSysSelectionFields,
  refetchSysCriteriaFields,
  refetchSysSelectionFields
} from '~/features/selection'
import { clearSysGroupings, refetchSysGroupings } from '~/features/grouping/reducers'
import { refetchEmDirectoryView } from '~/features/employee'

export const updateEmploymentConfig = (
  code: string,
  before: EmploymentConfigState,
  request: EmploymentConfigState
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!code) return

    const after = { ...before, ...request } as EmploymentConfigState
    const patch = generateJsonPatch<EmploymentConfigState>(before, after)
    const { status, errors, message, errorData } = await apiUpdateEmploymentConfig(code, patch)

    if (status) {
      const employmentConfig = getState().master.employmentConfigs?.entities[code]
      const updated = { ...(employmentConfig as EmploymentConfigState), ...after }
      dispatch(setEmploymentConfig(updated))
      dispatch(clearSysSelectionFields())
      dispatch(clearSysCriteriaFields())
      dispatch(clearSysGroupings())
      dispatch(refetchSysSelectionFields())
      dispatch(refetchSysCriteriaFields())
      dispatch(refetchSysGroupings())
      dispatch(refetchEmDirectoryView())
      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
