import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'
import { getAvailableMasterNames, MasterName } from '../types'
import { fetchMasters } from './fetch-masters'
import { fetchKeyvalues } from './fetch-keyvalues'
import { fetchEmKeyvalue } from '~/features/employee'

const masterWithKeyValues = ['paygroup', 'leavegroup']

export const fetchEmpKeyvalues = (
  id: string,
  controller?: string,
  queryParams?: any,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async dispatch => {
    if (!id) return

    if (id === 'employee') {
      // Keyvalues for employee
      dispatch(fetchEmKeyvalue(options))
      return
    } else if (masterWithKeyValues.includes(id.toLowerCase())) {
      dispatch(fetchKeyvalues(id, 'employee', id.toLowerCase(), queryParams, options))
      return
    }

    if (getAvailableMasterNames().includes(id as MasterName)) {
      // Keyvalues for master
      dispatch(fetchMasters(id as MasterName, options))
      return
    }

    dispatch(fetchKeyvalues(id, 'employee', controller, queryParams, options))
  }
}
