import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Descriptions } from '~/core-components'
import { fetchEmpKeyvalues, selectCalendarKeyvalues } from '~/features/master'
import { useHasFeatureFlagAttendance } from '~/features/iam'
import { useSysOptions } from '~/features/employee'
import { dispatch } from '~/stores/store'
import { selectCurrentEmCalendar } from '../../../../../selectors'

interface CopyEmCalendarProps {
  employeeId?: string
  emCalendarId?: string
}

export const CopyEmCalendar: FC<CopyEmCalendarProps> = ({ employeeId, emCalendarId }) => {
  const calendars = useSelector(selectCalendarKeyvalues)
  const emCalendar = useSelector(selectCurrentEmCalendar)(employeeId, emCalendarId)
  const [workSchedule] = useSysOptions('work_schedule')
  const hasAttendance = useHasFeatureFlagAttendance()

  useEffect(() => {
    dispatch(fetchEmpKeyvalues('calendar', undefined, undefined, { strategy: 'when-empty' }))
  }, [])

  return (
    <Descriptions bordered column={1} className="copy-em__content">
      {hasAttendance && (
        <Descriptions.Item label="Work schedule">
          {workSchedule[emCalendar?.workSchedule || '']?.value}
        </Descriptions.Item>
      )}
      <Descriptions.Item label="Calendar">{calendars[emCalendar?.calendarId || '']?.value}</Descriptions.Item>
      <Descriptions.Item label="Work hours per day">{emCalendar?.whPerDay}</Descriptions.Item>
      <Descriptions.Item label="Work hours per week">{emCalendar?.whPerWeek}</Descriptions.Item>
      <Descriptions.Item label="Work days per week">{emCalendar?.wdPerWeek}</Descriptions.Item>
    </Descriptions>
  )
}
