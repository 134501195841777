import { replaceXeroTenantMaps, setXeroTenantMapsLoading } from '../reducers'
import { apiGetXeroTenantMaps } from '../api/xero.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchXeroTenantMaps = (options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().payroll.xeroTenantMapsLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().payroll.xeroTenantMaps?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setXeroTenantMapsLoading(true))
      const { result, status } = await apiGetXeroTenantMaps()
      if (status) {
        dispatch(replaceXeroTenantMaps(result))
      }
    } finally {
      dispatch(setXeroTenantMapsLoading(false))
    }
  }
}
