import { setYtdSubmission, setYtdSubmissionsLoading } from '../reducers'
import { apiGetYtdSubmissionViewItem } from '../api/ytd-submission.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchYtdSubmission = (id: string, options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const loading = getState().tax.ytdSubmissionsLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().tax.ytdSubmissions?.entities[id]
      if (hasData) return
    }

    try {
      dispatch(setYtdSubmissionsLoading(true))
      const { result, status } = await apiGetYtdSubmissionViewItem(id)
      if (status) {
        dispatch(setYtdSubmission(result))
      }
    } finally {
      dispatch(setYtdSubmissionsLoading(false))
    }
  }
}
