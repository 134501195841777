import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { PayGroupState } from '../types'
import { GroupChildren } from '~/types/common'

export const selectPayGroupsByGrouping = createSelector(
  (state: StoreState) => state.master.masters.payGroup,
  payGroupState => {
    const map = new Map<string, GroupChildren<any>>()
    const payGroups = (Object.values(payGroupState?.entities || {}) as PayGroupState[]).sort((a, b) => {
      const aCompanyId = a!.companyId
      const bCompanyId = b!.companyId
      if (aCompanyId === bCompanyId) {
        const aInactiveDate = new Date(a!.inactiveDate || '').getTime() || 0
        const bInactiveDate = new Date(b!.inactiveDate || '').getTime() || 0

        if (bInactiveDate === 0 && aInactiveDate === 0) {
          return a!.name.localeCompare(b!.name)
        } else {
          return aInactiveDate - bInactiveDate
        }
      } else {
        return bCompanyId > aCompanyId ? -1 : 1
      }
    })

    payGroups.forEach(item => {
      if (item) {
        const {
          id,
          companyId,
          name,
          startDay,
          startDay2,
          isMidMonth,
          paymentDay,
          paymentDay1,
          paymentDay2,
          half1Rate,
          inactiveDate
        } = item
        map.has(companyId) || map.set(companyId, { id: `group-${id}`, name: companyId, children: [] })
        map.get(companyId)?.children.push({
          id,
          companyId,
          name,
          startDay,
          startDay2,
          isMidMonth,
          paymentDay,
          paymentDay1,
          paymentDay2,
          half1Rate,
          inactiveDate
        })
      }
    })

    const result = [...map.values()]
    return result
  }
)
