import { replacePayEncashMaps, setPayEncashMapsLoading } from '../reducers'
import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiUpdatePayEncashMaps } from '../api/pay-encash-map.api'
import { PayEncashMapState } from '../types'

export const updatePayEncashMaps = (payGroupId: string, data: PayEncashMapState[]): ThunkResult<void> => {
  return async dispatch => {
    if (!payGroupId) return

    try {
      dispatch(setPayEncashMapsLoading({ id: payGroupId, loading: true }))

      const { status, result, errors, message, errorData } = await apiUpdatePayEncashMaps(payGroupId, data)
      if (status) {
        dispatch(replacePayEncashMaps({ id: payGroupId, data: result }))
        showSuccess('Saved')
      } else {
        if (Object.keys(errors).length === 0) {
          showError(message, errorData)
        }
      }
      return { errors }
    } finally {
      dispatch(setPayEncashMapsLoading({ id: payGroupId, loading: false }))
    }
  }
}
