import React, { FC } from 'react'
import { Select, SelectProps } from '~/core-components'
import { useXeroAccounts } from '../../hooks'

interface XeroSelectAccountProps extends SelectProps {
  tenantId?: string
}

export const XeroSelectAccount: FC<XeroSelectAccountProps> = ({ tenantId, ...props }) => {
  const [accounts] = useXeroAccounts(tenantId)

  return (
    <Select showSearch optionFilterProp="title" dropdownMatchSelectWidth={false} {...props}>
      {accounts.map(a => (
        <Select.Option key={a.code} value={a.code} title={`${a.code} ${a.name}`}>
          {a.code} - {a.name}
        </Select.Option>
      ))}
    </Select>
  )
}
