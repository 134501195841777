import React, { forwardRef, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Autocomplete, AutocompleteProps } from '~/core-components'
import { StoreState } from '~/types/store'

interface EmFieldValuesProps extends AutocompleteProps {
  field: string
}

const EmFieldValuesInternal = ({ field, ...props }: EmFieldValuesProps, ref: React.Ref<any>) => {
  const fieldvalues = useSelector((state: StoreState) => state.employee.emFieldValues[field] || [])

  const options = useMemo(() => fieldvalues?.map(val => ({ value: val, label: val })), [fieldvalues])

  return <Autocomplete {...props} ref={ref} options={options}></Autocomplete>
}

export const EmFieldValues = forwardRef(EmFieldValuesInternal)
