import React, { FC, useCallback, useState } from 'react'
import moment from 'moment-timezone'
import { LoadingOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { Button, Card, Dropdown, Form, Space, Tooltip } from '~/core-components'
import { DateCustomPicker, DateCustomPickerItem, SearchInput } from '~/components'
import { Screen, ViewCriteriaSimple, ViewCriteria, updateViewCriteria, ViewSelectionButton } from '~/features/selection'
import { usePermissionGate } from '~/features/iam'
import { StoreState } from '~/types/store'
import { dispatch } from '~/stores/store'
import { useIsMountedRef } from '~/hooks'
import { DateRange } from '~/types/common'
import { AttendancePeriodStatus, Permission, PermissionAction } from '~/constants'
import { getFileTimestamp, downloadWithDom, showError } from '~/utils'
import { processDaily } from '../../actions'
import { apiGetDailyRecordsExcel } from '../../api/daily-record.api'
import { useAttendancePeriod } from '../../hooks'
import { refetchDailyRecordsView, setDailyRecordsViewForm } from '../../reducers'
import { ProcessDailyDrawer } from './components/ProcessDailyDrawer'
import { DailyRecordsTable } from './components/DailyRecordsTable'
import './DailyRecords.less'

interface DailyRecordsProps {
  viewId?: string
  attendancePeriodId?: string
}

interface ProcessDailyDrawerState {
  visible: boolean
  startDate?: string | null
  endDate?: string | null
  employeeIds?: string[]
}

const SCREEN_CODE: Screen = 'daily_record'
const DEFAULT_PROCESS_DAILY_DRAWER_STATE: ProcessDailyDrawerState = { visible: false }

const PERIOD_OPTIONS: DateCustomPickerItem[] = [
  {
    key: 'this_week',
    value: 'This week',
    startDate: moment().startOf('isoWeek').format('YYYY-MM-DD'),
    endDate: moment().endOf('isoWeek').format('YYYY-MM-DD')
  },
  {
    key: 'last_week',
    value: 'Last week',
    startDate: moment().startOf('isoWeek').add(-1, 'week').format('YYYY-MM-DD'),
    endDate: moment().endOf('isoWeek').add(-1, 'week').format('YYYY-MM-DD')
  },
  {
    key: 'this_month',
    value: 'This month',
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').format('YYYY-MM-DD')
  },
  {
    key: 'last_month',
    value: 'Last month',
    startDate: moment().startOf('month').add(-1, 'month').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').add(-1, 'month').format('YYYY-MM-DD')
  }
]

export const DailyRecords: FC<DailyRecordsProps> = ({ viewId, attendancePeriodId }) => {
  const [attendancePeriod] = useAttendancePeriod(attendancePeriodId, 'when-empty')
  const minDate = attendancePeriod?.startDate
  const maxDate = attendancePeriod?.endDate

  const canModify = usePermissionGate(Permission.attDailyRecord, PermissionAction.Modify)

  const processing = useSelector((state: StoreState) => state.attendance.dailyProcessing)

  // const [lockingAll, setLockingAll] = useState(false)
  // const [unlockingAll, setUnlockingAll] = useState(false)

  const isMountedRef = useIsMountedRef()
  const [downloading, setDownloading] = useState(false)

  const viewForm = useSelector((state: StoreState) => state.attendance.dailyRecordsViewForm)
  const page = viewForm.page
  const search = viewForm.search
  const startDate = viewForm.startDate
  const endDate = viewForm.endDate

  const [processDailyDrawerState, setProcessDailyDrawerState] = useState<ProcessDailyDrawerState>(
    DEFAULT_PROCESS_DAILY_DRAWER_STATE
  )

  // const handleLockAll = useCallback(async () => {
  //   if (attendancePeriodId) {
  //     setLockingAll(true)
  //     try {
  //       await dispatch(lockAllDailyRecords(attendancePeriodId))
  //     } finally {
  //       setLockingAll(false)
  //     }
  //   }
  // }, [attendancePeriodId])

  // const handleUnlockAll = useCallback(async () => {
  //   if (attendancePeriodId) {
  //     setUnlockingAll(true)
  //     try {
  //       await dispatch(unlockAllDailyRecords(attendancePeriodId))
  //     } finally {
  //       setUnlockingAll(false)
  //     }
  //   }
  // }, [attendancePeriodId])

  const handleDateRangeChange = useCallback((startDate: string | null, endDate: string | null, selection: string) => {
    dispatch(setDailyRecordsViewForm({ page: 1, type: selection, startDate: startDate || '', endDate: endDate || '' }))
  }, [])

  const handleDownloadClick = useCallback(async () => {
    if (viewId) {
      try {
        setDownloading(true)
        const { status, result, errors, message, errorData } = await apiGetDailyRecordsExcel(viewId, {
          search,
          ...({ startDate, endDate } as DateRange)
        })

        if (status) {
          const fileName = `daily_records_${getFileTimestamp()}.xlsx`
          downloadWithDom(result, fileName)
        } else {
          console.error('Error while downloading', errors)
          showError(message, errorData)
        }
      } finally {
        if (isMountedRef.current) setDownloading(false)
      }
    }
  }, [viewId, search, startDate, endDate, isMountedRef])

  const handleCloseSelectionDrawer = useCallback((changed?: boolean) => {
    if (changed) dispatch(refetchDailyRecordsView())
  }, [])

  const handleCriteriaApply = useCallback(
    async (criteria: ViewCriteria[]) => {
      if (viewId) {
        dispatch(setDailyRecordsViewForm({ page: 1 }))
        await dispatch(updateViewCriteria(SCREEN_CODE, viewId, { id: viewId, criteria }))
        dispatch(refetchDailyRecordsView())
      }
    },
    [viewId]
  )

  const handleSearch = useCallback((value: string) => {
    dispatch(setDailyRecordsViewForm({ page: 1, search: value }))
  }, [])

  const handleCalculateSome = useCallback(() => {
    setProcessDailyDrawerState({
      visible: true,
      startDate,
      endDate,
      employeeIds: []
    })
  }, [startDate, endDate])

  const handleCloseProcessDailyDrawer = useCallback(() => {
    setProcessDailyDrawerState(DEFAULT_PROCESS_DAILY_DRAWER_STATE)
  }, [])

  const handleCalculateAll = useCallback(async () => {
    if (!startDate || !endDate) return

    await dispatch(processDaily(startDate, endDate, undefined, 'all'))
    dispatch(refetchDailyRecordsView())
  }, [startDate, endDate])

  return (
    <div className="daily-records">
      <div className="daily-records__body">
        <div className="daily-records__action-bar">
          <Space>
            <Form.Item label="">
              <SearchInput defaultValue={search} onSearch={handleSearch} />
            </Form.Item>
          </Space>
          <ViewCriteriaSimple screenCode={SCREEN_CODE} viewId={viewId} onApply={handleCriteriaApply} label="" />
          <Space align="start">
            <DateCustomPicker
              defaultValue={viewForm.type}
              defaultStartDate={startDate}
              defaultEndDate={endDate}
              options={PERIOD_OPTIONS}
              onChange={handleDateRangeChange}
              disabledDate={current =>
                !minDate && !maxDate ? false : current && (current > moment(maxDate) || current < moment(minDate))
              }
            />
            {canModify && attendancePeriod?.status !== AttendancePeriodStatus.Completed && (
              <>
                {/* <Dropdown
                  disabled={lockingAll || unlockingAll}
                  menu={{
                    items: [
                      { key: 'lock', label: 'Lock all employees', onClick: handleLockAll },
                      { key: 'unlock', label: 'Unlock all employees', onClick: handleUnlockAll }
                    ]
                  }}
                >
                  <Button className="daily-records__action-bar-buttons-lock">
                    {lockingAll || unlockingAll ? (
                      <LoadingOutlined />
                    ) : (
                      <Space size={4}>
                        <i className="fal fa-lock" />
                        <i className="fa-light fa-angle-down" />
                      </Space>
                    )}
                  </Button>
                </Dropdown> */}
                <Dropdown.Button
                  menu={{
                    items: [
                      { key: 'selected', label: 'Recalculate selected employees...', onClick: handleCalculateSome }
                    ]
                  }}
                  icon={<i className="fa-light fa-angle-down" />}
                  onClick={handleCalculateAll}
                  buttonsRender={([leftButton, rightButton]) => [
                    React.cloneElement(leftButton as React.ReactElement, {
                      className: 'ant-btn-icon-only'
                    }),
                    rightButton
                  ]}
                >
                  {processing ? (
                    <LoadingOutlined />
                  ) : (
                    <Tooltip title="Recalculate all employees">
                      <i className="fal fa-refresh" />
                    </Tooltip>
                  )}
                </Dropdown.Button>
              </>
            )}
            <Tooltip title="Download excel">
              <Button
                icon={<i className="fal fa-arrow-down-to-bracket" />}
                onClick={handleDownloadClick}
                loading={downloading}
              />
            </Tooltip>
            <ViewSelectionButton
              screenCode={SCREEN_CODE}
              viewId={viewId || ''}
              title="Configure daily record columns"
              onClose={handleCloseSelectionDrawer}
            />
          </Space>
        </div>
        <Card fitParent table>
          <DailyRecordsTable
            viewId={viewId}
            startDate={startDate}
            endDate={endDate}
            search={search}
            page={page}
            onPageChange={page => dispatch(setDailyRecordsViewForm({ page }))}
          />
        </Card>
      </div>
      <ProcessDailyDrawer
        {...processDailyDrawerState}
        minDate={minDate}
        maxDate={maxDate}
        onClose={handleCloseProcessDailyDrawer}
      />
    </div>
  )
}
