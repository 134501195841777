import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Descriptions } from '~/core-components'
import { fetchKeyvalues } from '~/features/master'
import { dispatch } from '~/stores/store'
import { selectCurrentEmLocation } from '../../../../../selectors'
import { StoreState } from '~/types/store'

interface CopyEmLocationProps {
  employeeId?: string
  emLocationId?: string
}

export const CopyEmLocation: FC<CopyEmLocationProps> = ({ employeeId, emLocationId }) => {
  const locations = useSelector((state: StoreState) => state.master.keyvalues['location']?.entities)
  const emLocation = useSelector(selectCurrentEmLocation)(employeeId, emLocationId)

  useEffect(() => {
    dispatch(fetchKeyvalues('location', 'attendance', undefined, { strategy: 'when-empty' }))
  }, [])

  return (
    <Descriptions bordered column={1} className="copy-em__content">
      <Descriptions.Item label="Location">
        {locations && locations[emLocation?.locationId || '']?.value}
      </Descriptions.Item>
    </Descriptions>
  )
}
