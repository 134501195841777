import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { WfEventState } from '../types'

export const selectWfEvents = createSelector(
  (state: StoreState) => state.workflow.wfEvents,
  wfEventsState =>
    memoize(
      (workflowId: string, flowType: string): WfEventState[] => {
        const events = Object.values(wfEventsState[workflowId]?.entities || {}) as WfEventState[]

        return events
          .filter(e => e.flowType === flowType)
          .sort((a, b) => {
            const aSequence = a!.sequence
            const bSequence = b!.sequence
            return bSequence > aSequence ? -1 : 1
          })
      },
      (workflowId: string, flowType: string) => `${workflowId}||${flowType}`
    )
)
