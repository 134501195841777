import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Card, ColumnsType, Form, PageHeader, Space, Table } from '~/core-components'
import { DocumentTitle, SearchInput, InactiveTag } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { Screen, updateViewCriteria, useFirstView, ViewCriteria, ViewCriteriaSimple } from '~/features/selection'
import { useSysOptions } from '~/features/employee'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { Permission, PermissionAction } from '~/constants'
import { isInactive } from '~/utils'
import { fetchClaimTypesView } from '../../../claim/actions'
import { selectClaimTypesView } from '../../../claim/selectors'
import { ClaimTypeState } from '../../../claim/types'
import { AddClaimTypeDrawer } from './components/AddClaimTypeDrawer'
import { ClaimTypeName } from '../ClaimEntitlements/components/ClaimTypeName'
import './ClaimTypes.less'

interface ClaimTypesProps {}

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: '',
    breadcrumbName: 'Overview'
  }
]

interface DrawerState {
  visible: boolean
}

const SCREEN_CODE: Screen = 'claim_type'
const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

export const ClaimTypes: FC<ClaimTypesProps> = () => {
  const [search, setSearch] = useState<string>('')

  const [view, viewLoading] = useFirstView(SCREEN_CODE)
  const viewId = view?.id || ''
  const [refetch, setRefetch] = useState(0)

  const data = useSelector(selectClaimTypesView)
  const dataLoading = useSelector((state: StoreState) => state.claim.claimTypesViewLoading)

  const [paymentMethods] = useSysOptions('cla_payment_method')

  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const canModify = usePermissionGate(Permission.claType, PermissionAction.Modify)

  useEffect(() => {
    if (viewId) dispatch(fetchClaimTypesView(viewId, search))
  }, [viewId, search, refetch])

  const handleAddClaimType = useCallback(() => {
    setDrawerState({ visible: true })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const handleCriteriaApply = useCallback(
    async (criteria: ViewCriteria[]) => {
      if (viewId) {
        await dispatch(updateViewCriteria(SCREEN_CODE, viewId, { id: viewId, criteria }))
        setRefetch(r => r + 1)
      }
    },
    [viewId]
  )

  const handleSearch = useCallback((value: string) => {
    setSearch(value)
  }, [])

  const columns: ColumnsType<ClaimTypeState> = [
    {
      title: 'Claim type',
      key: 'name',
      dataIndex: 'name',
      render: (value: string, record) => (
        <Space>
          <ClaimTypeName id={record.id} path={SETTINGS_ROUTES.claimType.replace(':id', record.id)} />
          {isInactive(record.inactiveDate) && <InactiveTag />}
        </Space>
      )
    },
    {
      title: 'Reimbursement method',
      key: 'paymentMethod',
      dataIndex: 'paymentMethod',
      render: (value: string) => paymentMethods[value]?.value || ''
    }
  ]

  return (
    <div id="claim-types" className="claim-types">
      <DocumentTitle title="Claim Types" />
      <PageHeader
        title="Claim types"
        containerId="claim-types"
        breadcrumb={{ routes }}
        extra={
          canModify && (
            <Button key="add" onClick={handleAddClaimType}>
              Add claim type
            </Button>
          )
        }
      />
      <div className="claim-types__body">
        <div className="claim-types__action-bar">
          <Form.Item label="">
            <SearchInput onSearch={handleSearch} />
          </Form.Item>
          <ViewCriteriaSimple screenCode={SCREEN_CODE} viewId={viewId} onApply={handleCriteriaApply} label="" />
        </div>
        <Card table>
          <Table
            rowKey="id"
            dataSource={data}
            pagination={false}
            columns={columns}
            loading={dataLoading || viewLoading}
          />
        </Card>
      </div>
      {canModify && <AddClaimTypeDrawer {...drawerState} onClose={handleCloseDrawer} />}
    </div>
  )
}
