import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { EmOfficeState, IEmOffice } from '../types'

const baseUrl = getBaseUrl('/employee/employee')

export const apiGetEmOffices = (employeeId: string) =>
  request<EmOfficeState[]>('get', `${baseUrl}/${employeeId}/office`)

export const apiGetEmOffice = (employeeId: string, emOfficeId: string) =>
  request<EmOfficeState>('get', `${baseUrl}/${employeeId}/office/${emOfficeId}`)

export const apiAddEmOffice = (employeeId: string, emOffice: IEmOffice) =>
  request<{ id: string; emOfficeId?: string }>('post', `${baseUrl}/${employeeId}/office`, emOffice)

export const apiUpdateEmOffice = (employeeId: string, id: string, emOffice: Operation[]) =>
  request<{ emOfficeId?: string }>('patch', `${baseUrl}/${employeeId}/office/${id}`, emOffice)

export const apiDeleteEmOffice = (employeeId: string, id: string) =>
  request<{ emOfficeId?: string }>('delete', `${baseUrl}/${employeeId}/office/${id}`)
