import { showSuccess, showError, showWarning } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiSendToXero } from '../api/payrun-xero.api'
import { initiateXeroAuthentication } from './initiate-xero-authentication'

export const sendToXero = (payRunId: string): ThunkResult<void> => {
  return async dispatch => {
    const { status, statusCode, errors, message, errorData } = await apiSendToXero(payRunId)

    if (status) {
      showSuccess('Sent')
    } else if (statusCode === 403) {
      showWarning('Access denied. Redirecting to Xero to login.')
      dispatch(initiateXeroAuthentication())
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
