import React, { forwardRef } from 'react'
import padStart from 'lodash/padStart'
import classNames from 'classnames'
import { Select } from '../../core-components'
import { SelectProps } from '../../core-components/Select/Select'
import './MonthNumberKeyValues.less'

interface MonthNumberKeyValuesProps extends SelectProps {}

const monthNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

const MonthNumberKeyValuesInternal = ({ className, ...props }: MonthNumberKeyValuesProps, ref: React.Ref<any>) => {
  const classes = classNames('monthnumberkeyvalues', { [`${className}`]: className })

  return (
    <Select ref={ref} showSearch className={classes} optionFilterProp="title" {...props}>
      {monthNumbers.map(monthNum => {
        return (
          <Select.Option key={monthNum} value={monthNum} title={monthNum.toString()}>
            {padStart(monthNum.toString(), 2, '0')}
          </Select.Option>
        )
      })}
    </Select>
  )
}

export const MonthNumberKeyValues = forwardRef(MonthNumberKeyValuesInternal)
