import { EmSelectState } from '~/components'
import { EmSelectionViewState } from '~/features/employee'
import { Pagination, Search } from '~/types/common'
import { request, getBaseUrl, timeout } from '~/utils/request'
import {
  IIr21DraftLock,
  Ir21FormDownloadDto,
  Ir21FormState,
  IAddIr21Draft,
  IUpdateIr21Draft,
  Ir21A1FormState,
  IUpdateIr21A1Draft,
  IAddIr21A1Draft
} from '../types'

const baseUrl = getBaseUrl('/payroll-sg/ir21')

// IR21
export const apiProcessIr21 = (ir21SubmissionId: string, employeeScope: 'existing' | 'all', employeeTaxNos: string[]) =>
  request('post', `${baseUrl}/process`, { ir21SubmissionId, employeeScope, employeeTaxNos })

export const apiGetIr21FormData = (ir21SubmissionId: string, employeeTaxNo: string) =>
  request<Ir21FormState>('get', `${baseUrl}/ir21`, { ir21SubmissionId, employeeTaxNo })

export const apiUpdateIr21Draft = (formData: IUpdateIr21Draft) => request<void>('put', `${baseUrl}/ir21`, formData)

export const apiAddIr21Draft = (formData: IAddIr21Draft) => request<void>('post', `${baseUrl}/ir21`, formData)

export const apiDeleteIr21Draft = (id: string) => request('delete', `${baseUrl}/ir21/${id}`)

export const apiGetIr21EmSelections = (id: string, viewId: string, params: Pagination & Search) =>
  request<EmSelectionViewState>('get', `${baseUrl}/ir21/${id}/emselections/${viewId}`, params)

export const apiGetIr21NewEmSelections = (id: string, viewId: string, params: Pagination & Search) =>
  request<EmSelectionViewState>('get', `${baseUrl}/ir21/${id}/emselections/new/${viewId}`, params)

export const apiGetIr21SubmissionEmployees = (ir21FormType: string, ir21SubmissionId: string) =>
  request<EmSelectState[]>('get', `${baseUrl}/${ir21FormType}/${ir21SubmissionId}/employees`)

export const apiDownloadIr21Form = (ir21FormType: string, params: Ir21FormDownloadDto) =>
  request('post', `${baseUrl}/${ir21FormType}/download`, params, { responseType: 'blob', timeout })

export const apiLockIr21Draft = (id: string) => request<IIr21DraftLock>('post', `${baseUrl}/${id}/lock`)

export const apiUnlockIr21Draft = (id: string) => request<void>('post', `${baseUrl}/${id}/unlock`)

export const apiLockAllIr21Drafts = (ir21SubmissionId: string) =>
  request<IIr21DraftLock[]>('post', `${baseUrl}/${ir21SubmissionId}/lockall`)

export const apiUnlockAllIr21Drafts = (ir21SubmissionId: string) =>
  request<void>('post', `${baseUrl}/${ir21SubmissionId}/unlockall`)

// IR21A1
export const apiProcessIr21A1 = (
  ir21SubmissionId: string,
  employeeScope: 'existing' | 'all',
  employeeTaxNos: string[]
) => request('post', `${baseUrl}/a1/process`, { ir21SubmissionId, employeeScope, employeeTaxNos })

export const apiGetIr21A1FormData = (ir21SubmissionId: string, employeeTaxNo: string) =>
  request<Ir21A1FormState>('get', `${baseUrl}/a1`, { ir21SubmissionId, employeeTaxNo })

export const apiUpdateIr21A1Draft = (formData: IUpdateIr21A1Draft) => request<void>('put', `${baseUrl}/a1`, formData)

export const apiAddIr21A1Draft = (formData: IAddIr21A1Draft) => request<void>('post', `${baseUrl}/a1`, formData)

export const apiDeleteIr21A1Draft = (id: string) => request('delete', `${baseUrl}/a1/${id}`)

export const apiGetIr21A1EmSelections = (id: string, viewId: string, params: Pagination & Search) =>
  request<EmSelectionViewState>('get', `${baseUrl}/a1/${id}/emselections/${viewId}`, params)

export const apiGetIr21A1NewEmSelections = (id: string, viewId: string, params: Pagination & Search) =>
  request<EmSelectionViewState>('get', `${baseUrl}/a1/${id}/emselections/new/${viewId}`, params)
