import { EntityState } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import {
  LtSettingState,
  LeaveTypeState,
  SysLeaveTypeState,
  SysLtFieldState,
  LtProrationMState,
  LtPolicyState,
  LpEntitlementState,
  LpLeaveGroupState,
  LtPolicyExtraState,
  LeaveApplyFormInfoState,
  LeaveEntitlementState,
  LeaveEntitlementViewState,
  LeavePeriodYearState,
  LeavePeriodState,
  LeaveRecordState,
  LeaveRecordViewState,
  LeaveRecordDtlState,
  LeaveRecordAttachmentState,
  LeaveGrantState,
  LeEarnedState,
  EntitledLeaveTypeState,
  NextEntitlementResultState,
  PendingLeaveResultState,
  LeaveGrantBulkState,
  LeaveGrantBulkVerifyState,
  LeavePendingApprovalViewState,
  LveCalendarConfigState,
  LeaveRecordApprovalHistoryState,
  LeaveBuddyState,
  LeaveBuddyEmState
} from '.'

export const leavePersistConfig = {
  key: 'leave',
  whitelist: [
    'grantLeaveTypes',
    'leaveTypes',
    'leEarned',
    'ltSysColumns',
    'ltSettings',
    'ltPolicies',
    'ltPoliciesExtra',
    'ltProrationM',
    'lpEntitlements',
    'lpLeaveGroups',
    'sysLeaveTypes',
    'sysLtFields'
  ],
  storage
}

export interface LeaveRootState {
  leaveTypes: EntityState<LeaveTypeState>
  leaveTypesLoading: boolean
  leaveTypesView: EntityState<LeaveTypeState>
  leaveTypesViewLoading: boolean
  grantLeaveTypes: EntityState<LeaveTypeState>
  grantLeaveTypesLoading: boolean
  ltSysColumns: { [id: string]: string[] }
  ltSysColumnsLoading: { [id: string]: boolean }
  ltSettings: { [id: string]: EntityState<LtSettingState> }
  ltSettingsLoading: { [id: string]: boolean }
  ltPolicies: { [id: string]: EntityState<LtPolicyState> }
  ltPoliciesLoading: { [id: string]: boolean }
  ltPoliciesExtra: { [id: string]: EntityState<LtPolicyExtraState> }
  ltPoliciesExtraLoading: { [id: string]: boolean }
  ltProrationM: { [id: string]: EntityState<LtProrationMState> }
  ltProrationMLoading: { [id: string]: boolean }
  lpEntitlements: { [id: string]: EntityState<LpEntitlementState> }
  lpEntitlementsLoading: { [id: string]: boolean }
  lpLeaveGroups: { [id: string]: EntityState<LpLeaveGroupState> }
  lpLeaveGroupsLoading: { [id: string]: boolean }
  sysLeaveTypes: EntityState<SysLeaveTypeState>
  sysLeaveTypesLoading: boolean
  sysLtFields: { [code: string]: EntityState<SysLtFieldState> }
  sysLtFieldsLoading: { [code: string]: boolean }
  lveCalendarConfig: EntityState<LveCalendarConfigState>
  lveCalendarConfigLoading: boolean

  leaveExpanded: boolean
  leaveLoading: boolean
  leavePeriodYears: EntityState<LeavePeriodYearState>
  leavePeriodYearsLoading: boolean
  leavePeriods: EntityState<LeavePeriodState>
  leavePeriodsLoading: boolean
  leaveEntitlementsView: EntityState<LeaveEntitlementViewState>
  leaveEntitlementsViewLoading: boolean
  leaveEntitlementsViewRefetch: number
  leaveEntitlements: EntityState<LeaveEntitlementState>
  leaveEntitlementsLoading: boolean
  leaveRecordsView: EntityState<LeaveRecordViewState>
  leaveRecordsViewLoading: boolean
  leaveRecordsViewRefetch: number
  leaveRecords: EntityState<LeaveRecordState>
  leaveRecordsLoading: boolean
  leaveRecordDtls: { [id: string]: EntityState<LeaveRecordDtlState> }
  leaveRecordDtlsLoading: { [id: string]: boolean }
  leaveApplyFormInfo: { [id: string]: EntityState<LeaveApplyFormInfoState> }
  leaveApplyFormInfoLoading: { [id: string]: boolean }
  leaveRecordAttachment: EntityState<LeaveRecordAttachmentState>
  leaveRecordAttachmentLoading: boolean
  leaveRecordApprovalHistories: { [id: string]: EntityState<LeaveRecordApprovalHistoryState> }
  leaveRecordApprovalHistoriesLoading: { [id: string]: boolean }
  leaveGrants: { [employeeId: string]: EntityState<LeaveGrantState> }
  leaveGrantsLoading: { [employeeId: string]: boolean }
  leaveGrantBulk?: LeaveGrantBulkState
  leaveGrantBulkVerify?: LeaveGrantBulkVerifyState

  leavePendingApprovalsView: EntityState<LeavePendingApprovalViewState>
  leavePendingApprovalsViewLoading: boolean
  leavePendingApprovalsViewRefetch: number

  leEarned: { [entId: string]: EntityState<LeEarnedState> }
  leEarnedLoading: { [entId: string]: boolean }
  entitledLeaveTypes: { [employeeId: string]: EntityState<EntitledLeaveTypeState> }
  entitledLeaveTypesLoading: { [employeeId: string]: boolean }
  pendingLeaveResult?: PendingLeaveResultState
  nextEntitlementResult?: NextEntitlementResultState

  leaveBuddies: EntityState<LeaveBuddyState>
  leaveBuddiesLoading: boolean
  leaveBuddyEm: { [id: string]: EntityState<LeaveBuddyEmState> }
  leaveBuddyEmLoading: { [id: string]: boolean }
}
