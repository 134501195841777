import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiDeleteBank } from '../api/bank.api'
import { removeBank, removeKeyvalue } from '../reducers'

export const deleteBank = (code: string): ThunkResult<void> => {
  return async dispatch => {
    if (!code) return

    const { status, errors, message, errorData } = await apiDeleteBank(code)
    if (status) {
      dispatch(removeBank(code))
      dispatch(removeKeyvalue({ kv: 'bank', id: code }))
      showSuccess('Deleted')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
