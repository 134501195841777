import moment from 'moment-timezone'
import { setMyClaimRecordComment } from '../reducers'
import { showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { apiUpdateMyClaimRecordComment } from '../api/ss-claim-record-comment.api'
import { SSClaimRecordCommentState } from '../types'

export const updateClaimRecordComment = (claimRecordId: string, id: string, comment: string): ThunkResult<void> => {
  return async (dispatch, getState): Promise<ActionResult> => {
    const { status, errors, message, errorData } = await apiUpdateMyClaimRecordComment(claimRecordId, id, comment)
    if (status) {
      const currentComment = getState().myClaim.myClaimRecordComments[claimRecordId]?.entities[id]
      const updated = {
        ...(currentComment as SSClaimRecordCommentState),
        ...{
          comment: comment,
          updatedDate: moment().format('YYYY-MM-DDTHH:mm:ssZ')
        }
      }

      dispatch(setMyClaimRecordComment({ id: claimRecordId, data: updated }))
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
