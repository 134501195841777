import { setDailyPoliciesLoading } from '../reducers'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'
import { apiGetDailyPolicies } from '../api/daily-policy.api'
import { replaceDailyPolicies } from '../reducers'

export const fetchDailyPolicies = (options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().attendance.dailyPoliciesLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().attendance.dailyPolicies?.ids.length
      if (hasData) return
    }

    try {
      dispatch(setDailyPoliciesLoading(true))
      const { result, status } = await apiGetDailyPolicies()
      if (status) {
        dispatch(replaceDailyPolicies(result))
      }
    } finally {
      dispatch(setDailyPoliciesLoading(false))
    }
  }
}
