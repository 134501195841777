import React, { forwardRef, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import debounce from 'lodash/debounce'
import { Select, SelectProps, Spin } from '~/core-components'
import { Person } from '~/components'
import { getBaseUrl } from '~/utils'
import { EmSelectState } from './EmSelect'
import './EmSelectDebounce.less'

interface EmSelectDebounceProps extends SelectProps {
  onFetch: (search: string) => Promise<EmSelectState[]>
}

const DEBOUNCE_TIMEOUT = 800
const baseUrl = getBaseUrl('/filestore')

const EmSelectDebounceInternal = ({ onFetch, ...props }: EmSelectDebounceProps, ref: React.Ref<any>) => {
  const [employees, setEmployees] = useState<EmSelectState[]>()
  const [fetching, setFetching] = React.useState(false)
  const fetchRef = React.useRef(0)

  const debounceFetcher = React.useMemo(() => {
    const loadOptions = async (search: string) => {
      fetchRef.current += 1
      const fetchId = fetchRef.current
      setEmployees([])

      try {
        setFetching(true)
        const result = await onFetch(search)

        if (fetchId === fetchRef.current) {
          setEmployees(result)
        }
      } finally {
        if (fetchId === fetchRef.current) setFetching(false)
      }
    }

    return debounce(loadOptions, DEBOUNCE_TIMEOUT)
  }, [onFetch])

  return (
    <Select
      className="em-select em-select-debounce"
      showSearch
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin indicator={<LoadingOutlined spin />} /> : null}
      {...props}
    >
      {employees?.map(em => (
        <Select.Option key={em.id} value={em.id}>
          <Person
            name={em.name}
            description={em.description}
            photo={em.photoId && `${baseUrl}/file/${em.photoId}/thumbnailphoto/36`}
            // photo={em.id && `/employee/employee/${em.id}/avatar/36?photo_id=${em.photoId}`}
          />
        </Select.Option>
      ))}
    </Select>
  )
}

export const EmSelectDebounce = forwardRef(EmSelectDebounceInternal)
