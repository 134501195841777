import { Operation } from 'fast-json-patch'
import { request, getBaseUrl, timeout } from '~/utils/request'
import { Search } from '~/types/common'
import { IPayItem, IPayItemInfo } from '../types'

const baseUrl = getBaseUrl('/payroll-sg/payitem')

export const apiGetPayItems = () => request<IPayItem[]>('get', baseUrl)

export const apiGetPayItemsView = (viewId: string, params: Search) =>
  request<IPayItem[]>('get', `${baseUrl}/view/${viewId}`, params)

export const apiGetPayItemsExcel = (viewId: string, params: Search) =>
  request('get', `${baseUrl}/view/${viewId}/excel`, params, { responseType: 'blob', timeout })

export const apiGetPayItem = (id: string) => request<IPayItem>('get', `${baseUrl}/${id}`)

export const apiAddPayItem = (payItem: IPayItemInfo) => request<{ id: string }>('post', baseUrl, payItem)

export const apiUpdatePayItem = (id: string, payItem: Operation[]) =>
  request<void>('patch', `${baseUrl}/${id}`, payItem)

export const apiDeletePayItem = (id: string) => request<void>('delete', `${baseUrl}/${id}`)
