import React, { FC, useCallback, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import confirm from 'antd/lib/modal/confirm'
import { Form } from '~/core-components'
import { Col, DrawerForm, Row, SysOptions } from '~/components'
import { EmPublicPerson } from '~/features/employee'
import {
  ShiftDayTime,
  ShiftKeyValues,
  LocationKeyValues,
  ShiftRoleKeyValues,
  useShiftsDict
} from '~/features/attendance'
import { useFocus } from '~/hooks/use-focus'
import { NonWorkingStatus, WorkStatusType } from '~/constants'
import { ActionResult, Errors } from '~/types/store'
import { dispatch } from '~/stores/store'
import { IROEditScheduleRecord, IROAddScheduleRecord, ROScheduleRecordViewDataState } from '../../../types'
import { addScheduleRecordByRole, deleteScheduleRecordByRole, updateScheduleRecordByRole } from '../../../actions'
import './ROMutateScheduleRecordDrawer.less'

export interface ROMutateScheduleRecordDrawerProps {
  visible: boolean
  role: string
  defaultEmployeeId?: string
  defaultDate?: string
  data?: ROScheduleRecordViewDataState
  onClose: () => void
}

type FormData = IROEditScheduleRecord

const EMPTY_FORM_DATA: FormData = {
  workStatusType: WorkStatusType.WorkingDay,
  shiftId: ''
}

export const ROMutateScheduleRecordDrawer: FC<ROMutateScheduleRecordDrawerProps> = ({
  visible,
  role,
  defaultEmployeeId,
  defaultDate,
  data,
  onClose
}) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)
  const [focusRef, setFocus] = useFocus(true)
  const [errors, setErrors] = useState<Errors>()
  const [shiftsDict] = useShiftsDict()

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  useEffect(() => {
    if (data) {
      const { workStatus, shiftId, locationId, shiftRoleId } = data
      const workStatusType = NonWorkingStatus.includes(workStatus) ? workStatus : WorkStatusType.WorkingDay
      setFormData({ workStatusType, shiftId, locationId, shiftRoleId })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      if (data && data.id) {
        result = await dispatch(
          updateScheduleRecordByRole(role, data.id, mapScheduleRecordViewDataToEditScheduleRecord(data), formData)
        )
      } else {
        const newSchedule: IROAddScheduleRecord = {
          employeeId: defaultEmployeeId || '',
          scheduleDate: defaultDate || '',
          ...formData
        }
        result = await dispatch(addScheduleRecordByRole(role, newSchedule))
      }
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_FORM_DATA)
    }
  }, [role, data, defaultEmployeeId, defaultDate, formData, onClose])

  const handleDelete = useCallback(async () => {
    if (!data) return

    const { id, shiftId } = data
    const shift = shiftsDict[shiftId || '']

    confirm({
      title: 'Delete shift',
      content: `Do you want to delete "${shift?.name}"?`,
      onOk: async () => {
        const result: ActionResult | undefined = await dispatch(deleteScheduleRecordByRole(role, id))
        if (result?.errors) {
          setErrors(result.errors)
        }

        if (!result?.errors) {
          typeof onClose === 'function' && onClose()
        }
      },
      okText: 'Delete',
      okType: 'danger'
    })
  }, [role, data, shiftsDict, onClose])

  return (
    <DrawerForm
      open={visible}
      title={data ? 'Edit shift' : 'Add shift'}
      onClose={onClose}
      confirmLoading={loading}
      width={500}
      showDelete
      onDelete={handleDelete}
      formId="form-ro-mutate-schedule-record"
      className="ro-mutate-schedule-record-drawer"
    >
      <Form id="form-ro-mutate-schedule-record" onFinish={handleOk}>
        <Row>
          <Col flex="auto">
            <Form.Item label="">
              <EmPublicPerson id={data?.employeeId || defaultEmployeeId || ''} />
            </Form.Item>
          </Col>
          <Col flex="150px">
            <Form.Item label="Date" validateStatus={errors?.scheduleDate ? 'error' : ''} help={errors?.scheduleDate}>
              {moment(data?.scheduleDate || defaultDate).format('ddd, D MMM YYYY')}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Work status"
              validateStatus={errors?.workStatusType ? 'error' : ''}
              help={errors?.workStatusType}
            >
              <SysOptions
                ref={focusRef}
                type="work_status_type"
                value={formData.workStatusType}
                onChange={(workStatusType: string) => handleFormDataChange({ workStatusType })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row hidden={NonWorkingStatus.includes(formData.workStatusType)}>
          <Col span={24}>
            <Form.Item label="Shift" validateStatus={errors?.shiftId ? 'error' : ''} help={errors?.shiftId}>
              <Row gutter={15} align="middle">
                <Col span={12}>
                  <ShiftKeyValues
                    value={formData.shiftId}
                    onChange={(shiftId: string) => handleFormDataChange({ shiftId })}
                  />
                </Col>
                <Col span={12}>
                  <ShiftDayTime
                    shiftId={formData.shiftId}
                    dayCode={moment(data?.scheduleDate || defaultDate)
                      .format('ddd')
                      .toLowerCase()}
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>
        <Row hidden={NonWorkingStatus.includes(formData.workStatusType)}>
          <Col span={24}>
            <Form.Item label="Location" validateStatus={errors?.locationId ? 'error' : ''} help={errors?.locationId}>
              <LocationKeyValues
                value={formData.locationId}
                onChange={(locationId: string) => handleFormDataChange({ locationId })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row hidden={NonWorkingStatus.includes(formData.workStatusType)}>
          <Col span={24}>
            <Form.Item
              label="Shift role"
              validateStatus={errors?.shiftRoleId ? 'error' : ''}
              help={errors?.shiftRoleId}
            >
              <ShiftRoleKeyValues
                value={formData.shiftRoleId}
                onChange={(shiftRoleId: string) => handleFormDataChange({ shiftRoleId })}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}

const mapScheduleRecordViewDataToEditScheduleRecord = (data: ROScheduleRecordViewDataState): IROEditScheduleRecord => ({
  workStatusType: NonWorkingStatus.includes(data.workStatus) ? data.workStatus : WorkStatusType.WorkingDay,
  shiftId: data.shiftId,
  locationId: data.locationId,
  shiftRoleId: data.shiftRoleId
})
