import React, { FC } from 'react'
import { Space } from '~/core-components'
import { Col, Row } from '~/components'
import { EmPublicProfile } from '~/features/employee'
import { useHasMySsAccess, useMyEmployee } from '~/features/my/hooks'
import { FeatureFlag } from '~/constants'
import { MyPayslips } from './components/MyPayslips/MyPayslips'
import { MyPayInfo } from './components/MyPayInfo/MyPayInfo'
import { MyIras } from './components/MyIras/MyIras'
import './MyPayroll.less'

interface MyPayrollProps {}

export const MyPayroll: FC<MyPayrollProps> = () => {
  const [me] = useMyEmployee()
  const hasSsAccessMyProfile = useHasMySsAccess(FeatureFlag.MyProfile)

  return (
    <div className="my-payroll">
      <Space align="start">
        {!hasSsAccessMyProfile && <EmPublicProfile id={me.id} defaultName={me.fullName} defaultPhotoId={me.photoId} />}
        <div>
          <Row gutter={24}>
            <Col flex={1}>
              <MyPayslips />
            </Col>
            <Col flex="420px">
              <MyPayInfo />
            </Col>
          </Row>
          <Row gutter={24}>
            <Col flex={1}>
              <MyIras />
            </Col>
            <Col flex="420px"></Col>
          </Row>
        </div>
      </Space>
    </div>
  )
}
