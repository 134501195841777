import { showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiValidateTimeLogImport } from '../api/time-log-import.api'
import { replaceTimeLogImportValidate } from '../reducers'
import { ITimeLogColumnMap } from '../types'

export const validateTimeLogImport = (
  locationId: string,
  columnMap: ITimeLogColumnMap[],
  importType: string
): ThunkResult<void> => {
  return async dispatch => {
    const { status, result, errors, message, errorData } = await apiValidateTimeLogImport(
      locationId,
      columnMap,
      importType
    )
    if (status) {
      dispatch(replaceTimeLogImportValidate(result))
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors, result }
  }
}
