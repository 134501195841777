export class YtdSubmissionFileType {
  static get original() {
    return 'o'
  }
  static get amendment() {
    return 'a'
  }
}

export class YtdSubmissionStatus {
  static get draft() {
    return 'd'
  }
  static get submitted() {
    return 's'
  }
}

export class Ir21SubmissionStatus {
  static get draft() {
    return 'd'
  }
  static get submitted() {
    return 's'
  }
}
