import { setEmSelection, setEmSelectionLoading } from '~/features/employee'
import { apiGetScheduleRecordEmSelections } from '../api/schedule-record.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions, Pagination } from '~/types/common'

export const fetchScheduleRecordEmSelections = (
  startDate: string,
  endDate: string,
  viewId: string,
  pagination: Pagination,
  search: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!startDate || !endDate || !viewId) return

    const loading = getState().employee.emSelectionLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().employee.emSelection?.entities[viewId]
      if (hasData) return
    }

    try {
      dispatch(setEmSelectionLoading(true))
      const { result, status } = await apiGetScheduleRecordEmSelections(startDate, endDate, viewId, {
        ...pagination,
        search
      })
      if (status) {
        dispatch(setEmSelection(result))
      }
    } finally {
      dispatch(setEmSelectionLoading(false))
    }
  }
}
