import React, { FC, useCallback, useState } from 'react'
import { Button, Card, ColumnsType, PageHeader, Space, Table, Tag } from '~/core-components'
import { DocumentTitle, InactiveTag } from '~/components'
import { usePermissionGate } from '~/features/iam/hooks'
import { AttScheduleType, Permission, PermissionAction } from '~/constants'
import { isInactive } from '~/utils/dateUtil'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { ShiftState } from '../../types'
import { useShiftCategoriesDict, useShifts } from '../../hooks'
import { AddShiftDrawer } from '../Shift/components/AddShiftDrawer'
import { ShiftName } from './components/ShiftName'
import './Shifts.less'

type ShiftTable = ShiftState

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: '',
    breadcrumbName: 'Overview'
  }
]

interface DrawerState {
  visible: boolean
  data?: ShiftState
}
const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

interface ShiftsProps {}

export const Shifts: FC<ShiftsProps> = () => {
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const canModify = usePermissionGate(Permission.shift, PermissionAction.Modify)
  const [shifts, loading] = useShifts()
  const [shiftCategories] = useShiftCategoriesDict()

  const handleAddShift = useCallback(() => {
    setDrawerState({ visible: true, data: undefined })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const columns: ColumnsType<ShiftTable> = [
    {
      title: 'Shift',
      key: 'id',
      dataIndex: 'id',
      render: (id: string, record) => (
        <Space>
          <ShiftName id={record.id} path={SETTINGS_ROUTES.shift.replace(':id', id)} />
          {isInactive(record.inactiveDate) && <InactiveTag />}
          {record.scheduleType === AttScheduleType.flexi && <Tag>flexi</Tag>}
        </Space>
      )
    },
    {
      title: 'Category',
      key: 'shiftCategoryId',
      dataIndex: 'shiftCategoryId',
      render: (value: string) => shiftCategories[value]?.name || ''
    }
  ]

  return (
    <div id="shifts" className="shifts">
      <DocumentTitle title="Shifts" />
      <PageHeader
        title="Shifts"
        containerId="shifts"
        breadcrumb={{ routes }}
        extra={
          canModify && (
            <Button key="add" onClick={handleAddShift}>
              Add shift
            </Button>
          )
        }
      />
      <div className="shifts__body">
        <Card table>
          <Table rowKey="id" dataSource={shifts} pagination={false} columns={columns} loading={loading} />
        </Card>
      </div>
      {canModify && <AddShiftDrawer {...drawerState} onClose={handleCloseDrawer} />}
    </div>
  )
}
