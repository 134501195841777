import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Form } from '~/core-components'
import { Col, EditableCard, EditableCardState, Row, SysOptions } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { dispatch } from '~/stores/store'
import { Errors, ActionResult, StoreState } from '~/types/store'
import { KeyValue } from '~/types/common'
import { LveAdvanceLeave, LveEarnedBasis, LveVisibleType, Permission, PermissionAction } from '~/constants'
import { LeaveTypeAdvanceState, LeaveTypeState } from '../../../types'
import { updateLeaveType } from '../../../actions'
import { selectSysLtFieldsByCode } from '../../../selectors'

interface LeaveTypeAdvanceProps {
  leaveType?: LeaveTypeState
  onEdit?: () => void
  onSave?: () => void
  onCancel?: () => void
}

const EMPTY_FORM_DATA: LeaveTypeAdvanceState = {
  advanceLeaveCurrent: '',
  advanceLeaveNext: ''
}

export const LeaveTypeAdvance: FC<LeaveTypeAdvanceProps> = ({ leaveType, onEdit, onSave, onCancel }) => {
  const [cardState, setCardState] = useState<EditableCardState>()
  const [formData, setFormData] = useState<LeaveTypeAdvanceState>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const sysLeaveTypeCode = leaveType?.sysLeaveTypeCode || ''
  const sysLtFields = useSelector(selectSysLtFieldsByCode)(sysLeaveTypeCode)
  const sysLtFieldsLoading = useSelector((state: StoreState) => state.leave.sysLtFieldsLoading[sysLeaveTypeCode])
  const sysColumns = useSelector((state: StoreState) => state.leave.ltSysColumns[leaveType?.id || ''] || [])
  const canModify = usePermissionGate(Permission.lveType, PermissionAction.Modify)

  useEffect(() => {
    if (leaveType) {
      const { advanceLeaveCurrent, advanceLeaveNext } = leaveType
      setFormData({ advanceLeaveCurrent, advanceLeaveNext })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [leaveType])

  const handleEdit = useCallback(() => {
    setCardState('editing')
    typeof onEdit === 'function' && onEdit()
  }, [onEdit])

  const handleSave = useCallback(async () => {
    if (leaveType) {
      setCardState('saving')
      setErrors(undefined)

      typeof onSave === 'function' && onSave()

      let result: ActionResult | undefined
      try {
        result = await dispatch(updateLeaveType(leaveType.id, leaveType, formData))
      } catch {
        setCardState('editing')
      }

      if (result?.errors) {
        setCardState('editing')
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setCardState(undefined)
      }
    }
  }, [leaveType, formData, onSave])

  const handleCancel = useCallback(() => {
    typeof onCancel === 'function' && onCancel()
    setCardState(undefined)
    setErrors(undefined)

    if (leaveType) {
      const { advanceLeaveCurrent, advanceLeaveNext } = leaveType
      setFormData({ advanceLeaveCurrent, advanceLeaveNext })
    }
  }, [leaveType, onCancel])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(data => ({ ...data, ...updates }))
  }, [])

  const isVisible = useCallback(
    (fieldName: string) => {
      return sysLtFields[fieldName]?.visibleType === LveVisibleType.basic
    },
    [sysLtFields]
  )

  const readOnly = cardState !== 'editing' && cardState !== 'saving'

  const hasAdvanceLeaveCurrent = isVisible('advance_leave_current')
  const hasAdvanceLeaveNext = isVisible('advance_leave_next')
  const hasForm = hasAdvanceLeaveCurrent || hasAdvanceLeaveNext

  if (!hasForm && !sysLtFieldsLoading) return null

  return (
    <EditableCard
      title="Advance leave setting"
      bodyStyle={{ paddingBottom: leaveType && !sysLtFieldsLoading ? 6 : 24, paddingTop: 6 }}
      loading={sysLtFieldsLoading}
      state={!canModify ? 'readonly' : cardState}
      formId="form-leavetype-advance"
      onEdit={handleEdit}
      onSave={handleSave}
      onCancel={handleCancel}
    >
      <Row gutter={30}>
        {hasAdvanceLeaveCurrent && (
          <Col span={12}>
            <Form.Item
              label="Current period"
              validateStatus={errors?.advanceLeaveCurrent ? 'error' : ''}
              help={errors?.advanceLeaveCurrent}
            >
              <SysOptions
                type="lve_advance_leave"
                value={formData.advanceLeaveCurrent}
                readOnly={readOnly}
                disabled={sysColumns.includes('advanceLeaveCurrent')}
                onChange={(advanceLeaveCurrent: string) => handleFormDataChange({ advanceLeaveCurrent })}
                onFilter={(value: KeyValue | undefined) => {
                  if (leaveType?.earnedBasis === LveEarnedBasis.full) {
                    return LveAdvanceLeave.FullYear === value?.key
                  }
                  return true
                }}
              />
            </Form.Item>
          </Col>
        )}
        {hasAdvanceLeaveNext && (
          <Col span={12}>
            <Form.Item
              label="Next period"
              validateStatus={errors?.advanceLeaveNext ? 'error' : ''}
              help={errors?.advanceLeaveNext}
            >
              <SysOptions
                type="lve_advance_leave"
                value={formData.advanceLeaveNext}
                readOnly={readOnly}
                disabled={sysColumns.includes('advanceLeaveNext')}
                onChange={(advanceLeaveNext: string) => handleFormDataChange({ advanceLeaveNext })}
                onFilter={(value: KeyValue | undefined) => {
                  if (leaveType?.earnedBasis === LveEarnedBasis.full) {
                    return LveAdvanceLeave.FullYear === value?.key
                  }
                  return true
                }}
              />
            </Form.Item>
          </Col>
        )}
      </Row>
    </EditableCard>
  )
}
