import { showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiSortWfScenario } from '../api/workflow.api'
import { ISortWfEvent, WfCategory } from '../types'

export const sortWfScenario = (
  category: WfCategory,
  flowType: string,
  workflowId: string,
  sort: ISortWfEvent[]
): ThunkResult<void> => {
  return async () => {
    if (!category || !flowType || !workflowId || (sort && sort.length === 0)) return

    const { status, errors, message, errorData } = await apiSortWfScenario(category, workflowId, sort)
    if (status) {
      // Update local state instead of fetch again
      // dispatch(fetchWfEvents(flowType))
      // showSuccess('Sorted')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
