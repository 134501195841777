import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { Screen, ViewState } from '../types'

export const selectFirstView = createSelector(
  (state: StoreState) => state.selection.views,
  viewsState =>
    memoize((screenCode: Screen): ViewState | null => {
      const views = Object.values(viewsState[screenCode]?.entities || {}) as ViewState[]
      if (views.length > 0) {
        return views[0]
      }
      return null
    })
)
