import { showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiUnlockDailyRecord } from '../api/daily-record.api'
import { DailyRecordState } from '../types'
import { setDailyRecord, setDailyRecordsView } from '../reducers'

export const unlockDailyRecord = (id: string): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const { status, errors, message, errorData } = await apiUnlockDailyRecord(id)
    if (status) {
      const unlock = { lockedBy: '', lockedDate: '' }
      const dailyRecord = getState().attendance.dailyRecords?.entities[id]
      const data: DailyRecordState = { ...(dailyRecord as DailyRecordState), ...unlock }
      dispatch(setDailyRecord(data))

      const views = getState().attendance.dailyRecordsView
      views?.ids.forEach(viewId => {
        const view = views?.entities[viewId]
        const found = view?.data.find(r => r.id === id)
        if (found && view) {
          const updates = view.data.map(d => (d.id === id ? { ...d, ...unlock } : d))
          dispatch(setDailyRecordsView({ ...view, data: updates }))
        }
      })
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
