import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import cronstrue from 'cronstrue'
import { LoadingOutlined } from '@ant-design/icons'
import {
  Button,
  Card,
  ColumnsType,
  Form,
  Link,
  PageHeader,
  SecondaryLink,
  SecondaryText,
  Space,
  Spin,
  Table,
  Tag,
  TagType,
  Tooltip
} from '~/core-components'
import { DocumentTitle, SearchInput } from '~/components'
import { Screen, ViewCriteriaSimple, ViewCriteria, updateViewCriteria, useFirstView } from '~/features/selection'
import { useSysOptions } from '~/features/employee'
import { useMyLogin } from '~/features/iam'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { JobRunStatus } from '~/constants'
import { fetchJobsView, runOnetimeJob } from '../../actions'
import { selectJobsView } from '../../selectors'
import { JobRowState } from '../../types'
import { refetchJobsView, setJobsViewExpanded } from '../../reducers'
import { MutateJobDrawer } from '../Jobs/components/MutateJobDrawer'
import './Jobs.less'

interface JobsProps {}

interface MutateJobDrawerState {
  visible: boolean
  data?: JobRowState
  editing?: boolean
}

const nameColWidth = 250
const defaultColWidth = 150
const statusColWidth = 100
const dateColWidth = 180
const errorColWidth = 300
const actionColWidth = 120
const paginationStyle: CSSProperties = { marginRight: 20 }
const labelCronStyle: CSSProperties = { display: 'block', fontSize: 12, lineHeight: 1.2 }

const SCREEN_CODE: Screen = 'job'
const DEFAULT_MUTATE_JOB_DRAWER_STATE: MutateJobDrawerState = { visible: false }
const PAGE_SIZE_OPTIONS = ['20', '50', '100']

const AUTO_REFRESH_INTERVAL = 5 * 60000

export const Jobs: FC<JobsProps> = () => {
  const [mutateJobDrawerState, setMutateJobDrawerState] = useState<MutateJobDrawerState>(
    DEFAULT_MUTATE_JOB_DRAWER_STATE
  )
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(20)
  const [search, setSearch] = useState<string>('')

  const [view, viewLoading] = useFirstView(SCREEN_CODE)
  const viewId = view?.id || ''
  const dataLoading = useSelector((state: StoreState) => state.job.jobsViewLoading)
  const data = useSelector(selectJobsView)(viewId)
  const jobExpanded = useSelector((state: StoreState) => state.job.jobsViewExpanded)
  const refetch = useSelector((state: StoreState) => state.job.jobsViewRefetch)

  const [status] = useSysOptions('job_run_status')
  const [running, setRunning] = useState<string>()

  const [myLogin] = useMyLogin()

  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(refetchJobsView())
    }, AUTO_REFRESH_INTERVAL)

    return () => clearInterval(intervalId)
  }, [])

  useEffect(() => {
    if (viewId) {
      dispatch(fetchJobsView(viewId, { offset: pageSize * (page - 1), limit: pageSize }, search))
    }
  }, [viewId, page, pageSize, search, refetch])

  const getTagType = useCallback((status: string): TagType => {
    if (status === JobRunStatus.completed) {
      return 'success'
    } else if (status === JobRunStatus.running) {
      return 'secondary'
    } else if (status === JobRunStatus.error) {
      return 'danger'
    }
    return 'neutral'
  }, [])

  const getCronDisplay = useCallback((expr: string) => {
    const display = cronstrue.toString(expr, { throwExceptionOnParseError: false })
    if (display.search('undefined') === -1) {
      return display
    }
    return '-'
  }, [])

  const handleViewJob = useCallback((job: JobRowState) => {
    setMutateJobDrawerState({ visible: true, data: job })
  }, [])

  const handleRefreshClick = useCallback(() => {
    dispatch(refetchJobsView())
  }, [])

  const handleRunNow = useCallback(async (job: JobRowState) => {
    try {
      setRunning(job.id)
      await dispatch(runOnetimeJob(job.id))
      dispatch(refetchJobsView())
    } finally {
      setRunning(undefined)
    }
  }, [])

  const tableWidth = nameColWidth + statusColWidth + defaultColWidth * 2 + dateColWidth + errorColWidth + actionColWidth

  const columns: ColumnsType<JobRowState> = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      fixed: 'left',
      className: 'first-col',
      width: nameColWidth,
      render: (value: string, record) => (
        <>
          <div>{value}</div>
          {!record.isAdhoc && (
            <SecondaryText style={labelCronStyle}>
              Schedule: {getCronDisplay(record.cronExpr)}
              <> ({record.ownerTimeZoneId || 'UTC'})</>
            </SecondaryText>
          )}
        </>
      )
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      width: statusColWidth,
      render: (value: string) => {
        const sts = status[value]
        return sts ? <Tag type={getTagType(sts.key)}>{sts.value}</Tag> : ''
      }
    },
    {
      title: 'Last run',
      key: 'lastRunDate',
      dataIndex: 'lastRunDate',
      width: defaultColWidth,
      render: (value: string) => (value ? moment(value).fromNow() : undefined)
    },
    {
      title: 'Next run',
      key: 'nextRunDate',
      dataIndex: 'nextRunDate',
      width: dateColWidth,
      render: (value: string) => (value ? moment(value).format('DD MMM YYYY hh:mm A') : undefined)
    },
    {
      title: 'Owner',
      key: 'ownerName',
      dataIndex: 'ownerName',
      width: defaultColWidth
    },
    {
      title: 'Error',
      key: 'lastRunErrorMsg',
      dataIndex: 'lastRunErrorMsg',
      width: errorColWidth,
      render: (value: string) => value || '-'
    },
    {
      key: 'action',
      align: 'right',
      width: actionColWidth,
      render: (value: string, record) =>
        record.isOwner || myLogin.isOwner ? (
          <Space size="middle">
            <Link size="small" onClick={() => handleRunNow(record)}>
              {running === record.id ? <Spin indicator={<LoadingOutlined spin />} /> : <>run now</>}
            </Link>
            <Link size="small" onClick={() => handleViewJob(record)}>
              view
            </Link>
          </Space>
        ) : (
          <Link size="small" onClick={() => handleViewJob(record)}>
            view
          </Link>
        )
    }
  ]

  const handleAddJob = useCallback(() => {
    setMutateJobDrawerState({ visible: true, editing: true })
  }, [])

  const handleMutateJobDrawerClose = useCallback(() => {
    setMutateJobDrawerState(DEFAULT_MUTATE_JOB_DRAWER_STATE)
  }, [])

  const handleCriteriaApply = useCallback(
    async (criteria: ViewCriteria[]) => {
      if (viewId) {
        await dispatch(updateViewCriteria(SCREEN_CODE, viewId, { id: viewId, criteria }))
        dispatch(refetchJobsView())
      }
    },
    [viewId]
  )

  const handlePaginationChange = useCallback((page: number, pageSize?: number) => {
    setPage(page)
    setPageSize(pageSize || 20)
  }, [])

  const handleSearch = useCallback((value: string) => {
    setPage(1)
    setSearch(value)
  }, [])

  return (
    <div className="jobs">
      <DocumentTitle title="Automation" />
      {!jobExpanded && <PageHeader title="Automation" />}
      <div className="jobs__body">
        <div className="jobs__action-bar">
          <JobExpandButton isExpand={jobExpanded} />
          <Form.Item label="">
            <SearchInput onSearch={handleSearch} />
          </Form.Item>
          <ViewCriteriaSimple screenCode={SCREEN_CODE} viewId={viewId} onApply={handleCriteriaApply} label="" />
          <Space align="start">
            <Button onClick={handleAddJob}>Add job</Button>
            <Tooltip title="Refresh" placement="topRight">
              <Button icon={<i className="fal fa-refresh" />} onClick={handleRefreshClick} loading={dataLoading} />
            </Tooltip>
          </Space>
        </div>
        <Card fitParent table>
          <Table
            rowKey="id"
            dataSource={data?.data}
            columns={columns}
            fitParent
            loading={dataLoading || viewLoading}
            scroll={{ x: tableWidth, y: 1000 }}
            pagination={{
              total: data?.count,
              current: page,
              pageSize,
              pageSizeOptions: PAGE_SIZE_OPTIONS,
              showSizeChanger: true,
              onChange: handlePaginationChange,
              style: paginationStyle
            }}
          />
        </Card>
      </div>
      <MutateJobDrawer {...mutateJobDrawerState} onClose={handleMutateJobDrawerClose} />
    </div>
  )
}

const expandButtonStyle: CSSProperties = { marginRight: 20, marginTop: 5, height: 14 }

const JobExpandButton: FC<{ isExpand: boolean }> = ({ isExpand }) => {
  const handleExpand = useCallback(() => {
    dispatch(setJobsViewExpanded(!isExpand))
  }, [isExpand])

  return (
    <Tooltip title={isExpand ? 'Normal view' : 'Maximise view'}>
      <SecondaryLink style={expandButtonStyle} onClick={handleExpand}>
        {isExpand ? (
          <i className="fal fa-arrow-down-left-and-arrow-up-right-to-center" />
        ) : (
          <i className="fal fa-arrow-up-right-and-arrow-down-left-from-center" />
        )}
      </SecondaryLink>
    </Tooltip>
  )
}
