import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { PayTranSgState } from '../types'

export const selectPayTranByPayItem = createSelector(
  (state: StoreState) => state.payroll.payTrans,
  (state: StoreState) => state.payroll.payRecords,
  (payTransState, payRecordsState) => (
    payRunId: string,
    employeeId: string,
    payItemId: string
  ): PayTranSgState | undefined => {
    const payRecord = Object.values(payRecordsState[payRunId]?.entities || {}).find(
      rec => rec?.payRunId === payRunId && rec?.employeeId === employeeId
    )
    const payTrans = Object.values(payTransState[payRecord?.id || '']?.entities || {}) as PayTranSgState[]
    return payTrans.find(rec => rec.payItemId === payItemId)
  }
)
