import { ActionResult, ThunkResult } from '~/types/store'
import { emptyGuid } from '~/constants'
import { showSuccess, showError } from '~/utils'
import { apiAddEmManager } from '../api/em-manager.api'
import { setEmManager, setEmployee } from '../reducers'
import { IEmManagerInfo, EmManagerState } from '../types'

export const addEmManager = (employeeId: string, emManager: IEmManagerInfo): ThunkResult<void> => {
  return async (dispatch, getState): Promise<ActionResult | undefined> => {
    if (!employeeId) return

    const { status, result, errors, message, errorData } = await apiAddEmManager(employeeId, {
      id: emptyGuid,
      employeeId,
      ...emManager,
      managerId: emManager.managerId || emptyGuid
    })
    if (status) {
      if (result.emManagerId) {
        const employee = getState().employee.employees.entities[employeeId]
        if (employee) {
          dispatch(setEmployee({ ...employee, emManagerId: result.emManagerId }))
        }
      }

      const newEmManager = {
        id: result.id,
        employeeId,
        ...emManager
      } as EmManagerState
      dispatch(setEmManager({ employeeId, data: newEmManager }))

      showSuccess('Added')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
