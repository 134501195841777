import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { OptionGroup } from '~/types/common'
import { StoreState } from '~/types/store'
import { Screen } from '../types'

export const selectSysSelectionFieldsOptGroup = createSelector(
  (state: StoreState) => state.selection.sysSelectionFields,
  (state: StoreState) => state.selection.sysSelectionScreens,
  (state: StoreState) => state.master.keyvalues['sysselection'],
  (selectionFieldsState, sysSelectionScreensState, sysSelectionState) =>
    memoize((screenCode: Screen): OptionGroup[] => {
      const selectionCodes = Object.values(sysSelectionScreensState[screenCode]?.entities || {}).map(
        s => s?.selectionCode
      )

      const map = new Map<string, OptionGroup>()
      const selectionFields = Object.values(selectionFieldsState[screenCode]?.entities || {})
        .filter(f => selectionCodes.includes(f?.selectionCode))
        .sort((a, b) => {
          const aSequence = a!.sequence
          const bSequence = b!.sequence
          return bSequence > aSequence ? -1 : 1
        })

      selectionFields.forEach(item => {
        if (item) {
          const { id, description, selectionCode } = item
          map.has(selectionCode) ||
            map.set(selectionCode, {
              id: selectionCode,
              label: sysSelectionState.entities[selectionCode]?.value || '',
              options: []
            })
          map.get(selectionCode)?.options.push({
            id,
            value: id,
            label: description
          })
        }
      })

      const result = [...map.values()]
      return result
    })
)
