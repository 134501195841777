import { createSelector } from '@reduxjs/toolkit'
import { WorkStatus } from '~/constants'
import { ShiftState } from '~/features/attendance'
import { StoreState } from '~/types/store'

export const selectShiftsWorkStatus = createSelector(
  (state: StoreState) => state.attendance.shifts,
  (state: StoreState) => state.master.keyvalues['workStatusType'],
  (shiftsState, workStatusTypeState): ShiftState[] => {
    const shifts = Object.values(shiftsState?.entities || {}) as ShiftState[]
    const sorted = shifts.sort((a, b) => a.name?.localeCompare(b.name))

    sorted.push(
      {
        id: WorkStatus.off,
        name: workStatusTypeState?.entities[WorkStatus.off]?.value || '',
        color: '',
        scheduleType: '',
        shiftCategoryId: ''
      },
      {
        id: WorkStatus.rest,
        name: workStatusTypeState?.entities[WorkStatus.rest]?.value || '',
        color: '',
        scheduleType: '',
        shiftCategoryId: ''
      }
    )

    return sorted
  }
)
