import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchIr21SubmissionIr21View } from '../actions'
import { Ir21SubmissionIr21ViewState } from '../types'

export const useIr21SubmissionIr21View = (
  ir21SubmissionId: string,
  viewId: string,
  page: number,
  pageSize: number,
  search: string,
  fetchStrategy: FetchStrategy = 'when-empty'
): [Ir21SubmissionIr21ViewState | undefined, boolean] => {
  const data = useSelector((state: StoreState) => state.tax.ir21SubmissionIr21Views?.entities[viewId])
  const loading = useSelector((state: StoreState) => state.tax.ir21SubmissionIr21ViewsLoading)
  const refetch = useSelector((state: StoreState) => state.tax.ir21SubmissionIr21ViewsRefetch)

  useEffect(() => {
    if (viewId)
      dispatch(
        fetchIr21SubmissionIr21View(
          ir21SubmissionId,
          viewId,
          { offset: pageSize * (page - 1), limit: pageSize },
          search,
          {
            strategy: fetchStrategy
          }
        )
      )
  }, [ir21SubmissionId, viewId, page, pageSize, search, refetch, fetchStrategy])

  return [data, loading]
}
