import React, { FC } from 'react'
import { default as AntCheckboxGroup } from 'antd/lib/checkbox/Group'
import { CheckboxGroupProps as AntCheckboxGroupProps } from 'antd/lib/checkbox'
import classNames from 'classnames'
import './CheckboxGroup.less'

export interface CheckboxGroupProps extends AntCheckboxGroupProps {
  readOnly?: boolean
}
export const CheckboxGroup: FC<CheckboxGroupProps> = ({ readOnly, className, ...props }) => {
  const classes = classNames({ [`checkbox--readonly`]: readOnly }, { [`${className}`]: className })
  return <AntCheckboxGroup {...props} className={classes} onChange={readOnly ? undefined : props.onChange} />
}
