import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import { Form, Input, Modal } from '~/core-components'
import { Col, Row } from '~/components'
import { dispatch } from '~/stores/store'
import { ActionResult } from '~/types/store'
import { useFocus } from '~/hooks/use-focus'
import { saveViewSchemaAs } from '../actions'
import { Screen, IViewSchemaSaveAs, ViewSchemaState } from '../types'

interface SaveViewModalProps {
  visible: boolean
  screenCode: Screen
  viewId: string
  data?: IViewSchemaSaveAs
  onSuccess?: (view?: ViewSchemaState) => void
  onClose: () => void
}

const EMPTY_FORM_DATA: IViewSchemaSaveAs = {
  viewId: '',
  viewName: ''
}

export const SaveViewModal: FC<SaveViewModalProps> = ({ visible, screenCode, viewId, data, onSuccess, onClose }) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<IViewSchemaSaveAs>(EMPTY_FORM_DATA)
  const [focusRef, setFocus] = useFocus(true)

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
  }, [visible, setFocus])

  useEffect(() => {
    if (data) {
      setFormData(data)
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      const updated = { ...formData, ...updates }
      setFormData(updated)
    },
    [formData]
  )

  const handleSave = useCallback(async () => {
    setLoading(true)
    try {
      let result: ActionResult<ViewSchemaState> | undefined = await dispatch(
        saveViewSchemaAs(screenCode, viewId, formData)
      )

      if (!result?.errors) {
        typeof onSuccess === 'function' && onSuccess(result?.result)
        typeof onClose === 'function' && onClose()
        setFormData(EMPTY_FORM_DATA)
      }
    } finally {
      setLoading(false)
    }
  }, [screenCode, viewId, formData, onSuccess, onClose])

  return (
    <Modal
      open={visible}
      title="Save as new view"
      onCancel={onClose}
      width={300}
      confirmLoading={loading}
      className="save-view-modal"
      formId="form-view"
    >
      <Form id="form-view" onFinish={handleSave}>
        <Row>
          <Col span={24}>
            <Form.Item label="Specify the view name">
              <Input
                ref={focusRef}
                value={formData.viewName}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleFormDataChange({ viewName: event.target.value })
                }
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
