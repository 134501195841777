import React, { ChangeEvent, FC, useCallback, useState, useEffect } from 'react'
import moment from 'moment-timezone'
import confirm from 'antd/lib/modal/confirm'
import { Form, Input, Link, Space } from '~/core-components'
import { Col, DrawerForm, InfoTooltip, Row } from '~/components'
import { EmPublicPerson } from '~/features/employee'
import { ActionResult, Errors } from '~/types/store'
import { dispatch } from '~/stores/store'
import { useFocus } from '~/hooks/use-focus'
import { formatMoney } from '~/utils'
import { useClaimType } from '../../../hooks'
import { apiGetProjectedEarned } from '~/features/claim/api/claim-process.api'
import { IAdjustClaimEntitlement, ClaimEntitlementState } from '../../../types'
import { updateClaimEntitlement, deleteClaimEntitlement } from '../../../actions'
import './EditClaimEntitlementDrawer.less'

interface EditClaimEntitlementDrawerProps {
  visible: boolean
  onClose: (action: 'saved' | 'deleted' | 'cancelled') => void
  data?: ClaimEntitlementState
}

const EMPTY_FORM_DATA: IAdjustClaimEntitlement = {
  id: '',
  employeeId: '',
  claimTypeId: '',
  entitlement: 0,
  earnedAdj: 0,
  paidAdj: 0,
  notes: ''
}

export const EditClaimEntitlementDrawer: FC<EditClaimEntitlementDrawerProps> = ({
  visible,
  onClose,
  data
}: EditClaimEntitlementDrawerProps) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<IAdjustClaimEntitlement>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const [focusRef, setFocus] = useFocus(true)
  const [showEntitlement, setShowEntitlement] = useState(false)
  const [recalculatedAmount, setRecalculatedAmount] = useState<number>(0)
  const entitlementChanged = recalculatedAmount !== formData.entitlement
  const [earned, setEarned] = useState<number>(0)

  const id = data?.id || ''
  const claimTypeId = data?.claimTypeId || ''
  const employeeId = data?.employeeId || ''

  const [claimType] = useClaimType(claimTypeId)
  const claimTypeName = claimType?.name

  const balance =
    (data?.balance || 0) +
    (earned - (data?.earned || 0)) +
    ((formData.earnedAdj || 0) - (data?.earnedAdj || 0)) -
    ((formData.paidAdj || 0) - (data?.paidAdj || 0))

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  useEffect(() => {
    if (data) {
      setEarned(data.earned)
      setRecalculatedAmount(data.entitlement)
    } else {
      setShowEntitlement(false)
    }
  }, [data])

  useEffect(() => {
    if (data) {
      const { id, employeeId, claimTypeId, entitlement, earnedAdj, paidAdj, notes } = data

      setFormData({
        id,
        employeeId,
        claimTypeId,
        entitlement,
        earnedAdj,
        paidAdj,
        notes
      })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  const handleOk = useCallback(async () => {
    if (!id || !data) return

    let result: ActionResult | undefined
    setLoading(true)
    try {
      result = await dispatch(updateClaimEntitlement(id, mapClaimEntitlementToAdjustClaimEntitlement(data), formData))
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose('saved')
      setFormData(EMPTY_FORM_DATA)
    }
  }, [id, data, formData, onClose])

  const handleDelete = useCallback(
    (data: ClaimEntitlementState | undefined) => {
      if (data) {
        confirm({
          title: 'Delete claim entitlement',
          content: `Do you want to delete claim "${claimTypeName}"?`,
          onOk: async () => {
            let result: ActionResult | undefined
            setLoading(true)
            try {
              result = await dispatch(deleteClaimEntitlement(data.id))
            } finally {
              setLoading(false)
            }

            if (result?.errors) {
              setErrors(result.errors)
            }

            if (!result?.errors) {
              typeof onClose === 'function' && onClose('deleted')
              setFormData(EMPTY_FORM_DATA)
            }
          },
          okText: 'Delete',
          okType: 'danger'
        })
      }
    },
    [claimTypeName, onClose]
  )

  // const handleShowEntitlement = useCallback(() => {
  //   setShowEntitlement(true)
  // }, [])

  const handleRecalculate = useCallback(
    async (data: ClaimEntitlementState | undefined) => {
      if (data) {
        setLoading(true)
        try {
          const { result, status } = await apiGetProjectedEarned(
            data.employeeId,
            data.claimTypeId,
            moment().format('YYYY-MM-DD'),
            { entitlement: formData.entitlement }
          )
          if (status) {
            setEarned(result)
            setRecalculatedAmount(formData.entitlement)
          }
        } finally {
          setLoading(false)
        }
      }
    },
    [formData]
  )

  return (
    <DrawerForm
      open={visible}
      title="Adjustment"
      onClose={() => onClose('cancelled')}
      onDelete={() => handleDelete(data)}
      showDelete={true}
      confirmLoading={loading}
      width={500}
      className="edit-claim-entitlement-drawer"
      formId="form-edit-claim-entitlement"
      okDisabled={entitlementChanged}
      extras={entitlementChanged && <InfoTooltip title="Click recalculate to refresh the accrued amount" />}
    >
      <Form
        id="form-edit-claim-entitlement"
        layout="horizontal"
        labelAlign="left"
        labelCol={{ span: 10 }}
        colon={false}
        onFinish={handleOk}
      >
        <Row>
          <Col span={24}>
            <Form.Item label="">
              <EmPublicPerson id={employeeId} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Claim type">{claimTypeName}</Form.Item>
          </Col>
        </Row>
        <div className="entitlement__subtitle">Accrued</div>
        {showEntitlement && (
          <Row>
            <Col span={24}>
              <Form.Item
                label="Entitlement amount"
                validateStatus={errors?.entitlement ? 'error' : ''}
                help={errors?.entitlement}
              >
                <Space>
                  <Input.Number
                    ref={focusRef}
                    value={formData.entitlement}
                    onChange={(value: number | null) => handleFormDataChange({ entitlement: value || 0 })}
                  />
                  <Link onClick={() => handleRecalculate(data)} hidden={!entitlementChanged}>
                    recalculate
                  </Link>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row>
          <Col span={23}>
            <Form.Item
              label={`Accrued${data?.asAtDate ? ` as of ${moment(data.asAtDate).format('DD MMM YYYY')}` : ''}`}
            >
              <span style={{ marginLeft: 8 }}>{formatMoney(earned, 2)}</span>
            </Form.Item>
          </Col>
          {/* <Col span={1} style={{ paddingTop: 5 }}>
            <SecondaryLink onClick={handleShowEntitlement} hidden={!!showEntitlement}>
              <EditOutlined />
            </SecondaryLink>
          </Col> */}
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Adjustment" validateStatus={errors?.earnedAdj ? 'error' : ''} help={errors?.earnedAdj}>
              <Space>
                <Input.Number
                  value={formData.earnedAdj}
                  onChange={(value: number | null) => handleFormDataChange({ earnedAdj: value || 0 })}
                />
              </Space>
            </Form.Item>
          </Col>
        </Row>
        <div className="entitlement__subtitle">Taken</div>
        <Row>
          <Col span={24}>
            <Form.Item label="Taken">
              {formatMoney((data?.pendingApproval || 0) + (data?.pendingPayment || 0) + (data?.paid || 0), 2)}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Adjustment" validateStatus={errors?.paidAdj ? 'error' : ''} help={errors?.paidAdj}>
              <Space>
                <Input.Number
                  value={formData.paidAdj}
                  onChange={(value: number | null) => handleFormDataChange({ paidAdj: value || 0 })}
                />
              </Space>
            </Form.Item>
          </Col>
        </Row>
        <div className="entitlement__subtitle">Balance</div>
        <Row>
          <Col span={24}>
            <Form.Item label="Balance">{formatMoney(balance, 2)}</Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Notes" validateStatus={errors?.notes ? 'error' : ''} help={errors?.notes}>
              <Input.TextArea
                rows={5}
                value={formData.notes}
                onChange={(value?: ChangeEvent<HTMLTextAreaElement>) =>
                  handleFormDataChange({ notes: value?.target.value })
                }
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}

const mapClaimEntitlementToAdjustClaimEntitlement = (data: ClaimEntitlementState): IAdjustClaimEntitlement => ({
  id: data.id,
  employeeId: data.employeeId,
  claimTypeId: data.claimTypeId,
  entitlement: data.entitlement,
  earnedAdj: data.earnedAdj,
  paidAdj: data.paidAdj,
  notes: data.notes
})
