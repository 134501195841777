import React, { ChangeEvent, CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Form, Input } from '~/core-components'
import { Col, EditableCard, EditableCardState, Row } from '~/components'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { useFocus } from '~/hooks/use-focus'
import { dispatch } from '~/stores/store'
import { ActionResult, StoreState } from '~/types/store'
import { updatePayRecordSgNotes } from '../../actions'
import { IPayRecordNotes, PayRecordSgState } from '../../types'

interface PayRecordNotesProps {
  payRecord?: PayRecordSgState
  onEdit?: () => void
  onSave?: () => void
  onCancel?: () => void
}

const EMPTY_FORM_DATA: IPayRecordNotes = {
  notes: '',
  payslipNotes: ''
}

const cardStyle: CSSProperties = { marginTop: 18 }

export const PayRecordNotes: FC<PayRecordNotesProps> = ({
  payRecord,
  onEdit,
  onSave,
  onCancel
}: PayRecordNotesProps) => {
  const [cardState, setCardState] = useState<EditableCardState>()
  const [formData, setFormData] = useState<IPayRecordNotes>(EMPTY_FORM_DATA)
  const [focusRef] = useFocus(cardState === 'editing')
  const loading = useSelector((state: StoreState) => state.payroll.payRecordsLoading[payRecord?.payRunId || ''])
  const canModify = usePermissionGate(Permission.payRun, PermissionAction.Modify)

  useEffect(() => {
    if (payRecord) {
      const { notes, payslipNotes } = payRecord
      setFormData({ notes, payslipNotes })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [payRecord])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => setFormData({ ...formData, ...updates }),
    [formData]
  )

  const handleEdit = useCallback(() => {
    setCardState('editing')
    typeof onEdit === 'function' && onEdit()
  }, [onEdit])

  const handleSave = useCallback(async () => {
    if (payRecord) {
      setCardState('saving')

      typeof onSave === 'function' && onSave()

      let result: ActionResult | undefined
      try {
        result = await dispatch(updatePayRecordSgNotes(payRecord.payRunId, payRecord.id, formData))
      } catch {
        setCardState('editing')
      }

      if (result?.errors) {
        setCardState('editing')
      }

      if (!result?.errors) {
        setCardState(undefined)
      }
    }
  }, [payRecord, formData, onSave])

  const handleCancel = useCallback(() => {
    typeof onCancel === 'function' && onCancel()
    setCardState(undefined)

    if (payRecord) {
      const { notes, payslipNotes } = payRecord
      setFormData({ notes, payslipNotes })
    }
  }, [payRecord, onCancel])

  return (
    <EditableCard
      title="Notes"
      style={cardStyle}
      bodyStyle={{ paddingTop: 6 }}
      state={canModify ? cardState : 'readonly'}
      loading={loading}
      formId="form-payrecord-notes"
      onEdit={handleEdit}
      onSave={handleSave}
      onCancel={handleCancel}
    >
      <Row>
        <Col span={24}>
          <Form.Item label="Notes for internal">
            <Input.TextArea
              ref={focusRef}
              rows={2}
              value={formData.notes}
              readOnly={cardState !== 'editing' && cardState !== 'saving'}
              onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                handleFormDataChange({ notes: event.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item label="Notes for payslip">
            <Input.TextArea
              rows={2}
              value={formData.payslipNotes}
              readOnly={cardState !== 'editing' && cardState !== 'saving'}
              onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                handleFormDataChange({ payslipNotes: event.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
    </EditableCard>
  )
}
