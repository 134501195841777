export type ShiftState = { id: string } & ShiftInfoState

export interface ShiftInfoState {
  name: string
  inactiveDate?: string
  color: string
  scheduleType: string
  shiftCategoryId: string
}

export type ShiftDayState = { id: string } & ShiftDayInfoState

export interface ShiftDayInfoState {
  shiftId: string
  dayCode: string
  normalStartTime: string
  normalStartDay: number
  normalEndTime: string
  normalEndDay: number
  normalHours: number
  workStatus: string
  breakId?: string
  maxHourBeforeStart: number
  maxHourAfterEnd: number
  graceLatenessMinutes: number
  graceUndertimeMinutes: number
  latenessRoundMethod: string
  latenessRoundMinutes: number
  otBeforeStartTime?: string
  otBeforeStartDay: number
  otBeforeStartMinHours: number
  otBeforeStartMaxHours: number
  otBeforeStartRate: string
  midnightOnOtBeforeStart: boolean
  otBeforeMidnightRateForWorkDay: string
  otBeforeMidnightRateForOffDay: string
  otBeforeMidnightRateForRestDay: string
  otBeforeMidnightRateForPh: string
  otAfterEndTime?: string
  otAfterEndDay: number
  otAfterEndMinHours: number
  otAfterEndMaxHours: number
  otAfterEndRate: string
  midnightOnOtAfterEnd: boolean
  otAfterMidnightRateForWorkDay: string
  otAfterMidnightRateForOffDay: string
  otAfterMidnightRateForRestDay: string
  otAfterMidnightRateForPh: string
  otWithinShiftRate: string
  midnightOnOtWithinShift: boolean
  otWithinBeforeMidnightRate: string
  otWithinAfterMidnightRateForWorkDay: string
  otWithinAfterMidnightRateForOffDay: string
  otWithinAfterMidnightRateForRestDay: string
  otWithinAfterMidnightRateForPh: string
  totalOtMinHours: number
  totalOtMaxHours: number
  otRoundMethod: string
  otRoundMinutes: number
  h1EndTime?: string
  h1Hours: number
  h2StartTime?: string
  h2Hours: number
}

export interface IShiftTeInfo {
  startDate: string
  te01: number
  te02: number
  te03: number
  te04: number
  te05: number
  te06: number
  te07: number
  te08: number
  te09: number
  te10: number
}

export interface IShiftTe extends IShiftTeInfo {
  id: string
  shiftId: string
}

export interface ShiftTeState {
  id: string
  shiftId: string
  startDate: string
  te01: number
  te02: number
  te03: number
  te04: number
  te05: number
  te06: number
  te07: number
  te08: number
  te09: number
  te10: number
}

export const mapShiftTeStateToShiftTeInfo = (shiftTe: ShiftTeState): IShiftTeInfo => ({
  startDate: shiftTe.startDate,
  te01: shiftTe.te01,
  te02: shiftTe.te02,
  te03: shiftTe.te03,
  te04: shiftTe.te04,
  te05: shiftTe.te05,
  te06: shiftTe.te06,
  te07: shiftTe.te07,
  te08: shiftTe.te08,
  te09: shiftTe.te09,
  te10: shiftTe.te10
})
