import { EmSelectionViewState } from '~/features/employee'
import { Pagination, Search } from '~/types/common'
import { getBaseUrl, request } from '~/utils/request'
import {
  IIr21SubmissionNew,
  Ir21ResigneeState,
  Ir21SubmissionA1ViewState,
  Ir21SubmissionIr21ViewState,
  Ir21SubmissionState,
  Ir21SubmissionViewState
} from '../types'

const baseUrl = getBaseUrl('/payroll-sg/ir21submission')

export const apiGetIr21SubmissionView = (viewId: string, params: Pagination & Search) =>
  request<Ir21SubmissionViewState>('get', `${baseUrl}/view/${viewId}`, params)

export const apiGetIr21SubmissionViewItem = (id: string) =>
  request<Ir21SubmissionState>('get', `${baseUrl}/${id}/viewitem`)

export const apiGetIr21Resignees = () => request<Ir21ResigneeState[]>('get', `${baseUrl}/resignee`)

export const apiAddIr21Submission = (ir21Submission: IIr21SubmissionNew) =>
  request<{ id: string; status: string }>('post', baseUrl, ir21Submission)

export const apiGetIr21NewSubmissionEmployees = (
  ytdYear: number,
  employerTaxNo: string,
  viewId: string,
  params: Pagination & Search
) => request<EmSelectionViewState>('get', `${baseUrl}/${ytdYear}/${employerTaxNo}/emselections/${viewId}`, params)

export const apiDeleteIr21Submission = (id: string) => request<void>('delete', `${baseUrl}/${id}`)

export const apiGetIr21SubmissionIr21View = (id: string, viewId: string, params: Pagination & Search) =>
  request<Ir21SubmissionIr21ViewState>('get', `${baseUrl}/${id}/ir21/view/${viewId}`, params)

export const apiGetIr21SubmissionA1View = (id: string, viewId: string, params: Pagination & Search) =>
  request<Ir21SubmissionA1ViewState>('get', `${baseUrl}/${id}/a1/view/${viewId}`, params)
