import { removeEmEducationRequest } from '../reducers'
import { apiApproveEmEducationRequest } from '../api/em-education-request.api'
import { ThunkResult } from '~/types/store'
import { showError, showSuccess } from '~/utils'
import { fetchEmEducations } from './fetch-em-educations'

export const approveEmEducationRequest = (employeeId: string, id: string): ThunkResult<void> => {
  return async dispatch => {
    const { status, result, errors, message } = await apiApproveEmEducationRequest(id)

    if (status) {
      dispatch(removeEmEducationRequest({ employeeId, id }))
      dispatch(fetchEmEducations(employeeId))
      showSuccess('Approved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message)
      }
    }
    return { result, errors }
  }
}
