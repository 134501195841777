import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { NextEntitlementRowState } from '../types'

export const selectNextEntitlements = createSelector(
  (state: StoreState) => state.leave.nextEntitlementResult,
  (nextEntitlementResultState): NextEntitlementRowState[] => {
    return nextEntitlementResultState?.nextEntitlements || []
  }
)
