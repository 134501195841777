import { EntityState } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { ReportCode, ReportTemplateState } from './report-template.state'
import { SysBankFileState } from './rpt-bank-file.state'
import {
  OedLogState,
  OedRecordFormState,
  OedRecordViewState,
  OedSubmissionState,
  OedSubmissionViewState,
  OedSummaryState
} from './rpt-mom-oed.state'

export const reportPersistConfig = {
  key: 'report',
  whitelist: [
    'sysBankFiles',
    'reportTemplates',
    'reportTemplatesRefetch',
    'oedSubmissionsView',
    'oedSubmissionsViewRefetch',
    'oedSubmissions',
    'oedSubmissionExpanded',
    'oedSummaries',
    'oedRecordsView',
    'oedRecordsViewRefetch',
    'oedRecordForm',
    'oedRecordFormRefetch'
  ],
  storage
}

export interface ReportRootState {
  sysBankFiles: EntityState<SysBankFileState>
  sysBankFilesLoading: boolean
  reportTemplates: { [reportCode in ReportCode]?: EntityState<ReportTemplateState> }
  reportTemplatesLoading: { [reportCode in ReportCode]?: boolean }
  reportTemplatesRefetch: number

  // OED Submissions
  oedSubmissions: EntityState<OedSubmissionState>
  oedSubmissionsLoading: boolean
  oedSubmissionsView: EntityState<OedSubmissionViewState>
  oedSubmissionsViewLoading: boolean
  oedSubmissionsViewRefetch: number
  oedSubmissionExpanded: boolean
  oedSummaries: EntityState<OedSummaryState>
  oedSummariesLoading: boolean
  oedSummariesRefetch: number

  // OED Logs
  oedLogs: EntityState<OedLogState>
  oedLogsLoading: boolean
  oedLogsRefetch: number

  // OED Records
  oedRecordsView: EntityState<OedRecordViewState>
  oedRecordsViewLoading: boolean
  oedRecordsViewRefetch: number

  // OED Record Form
  oedRecordForm: EntityState<OedRecordFormState>
  oedRecordFormLoading: boolean
  oedRecordFormRefetch: number
  oedRecordFormUpdating: boolean
  oedRecordFormDeleting: boolean
}
