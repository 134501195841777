import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchLveCalendarConfig } from '../actions'
import { LveCalendarConfigState } from '../types'
import { CalendarConfig } from '~/constants'

export const useLveCalendarConfig = (
  fetchStrategy: FetchStrategy = 'when-empty'
): [LveCalendarConfigState | undefined, boolean] => {
  const config = useSelector(
    (state: StoreState) => state.leave.lveCalendarConfig.entities[CalendarConfig.LveCalendarConfigId || '']
  )
  const loading = useSelector((state: StoreState) => state.leave.lveCalendarConfigLoading)

  useEffect(() => {
    dispatch(fetchLveCalendarConfig({ strategy: fetchStrategy }))
  }, [fetchStrategy])

  return [config, loading]
}
