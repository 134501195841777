import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiDeleteExpenseType } from '../api/expense-type.api'
import { removeExpenseType } from '../reducers'
import { removeKeyvalue } from '~/features/master'

export const deleteExpenseType = (id: string): ThunkResult<void> => {
  return async dispatch => {
    if (!id) return

    const { status, errors, message, errorData } = await apiDeleteExpenseType(id)
    if (status) {
      dispatch(removeExpenseType(id))
      dispatch(removeKeyvalue({ kv: 'expenseType', id }))
      showSuccess('Deleted')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
