import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { ErrorDisplay } from '~/components'
import { ColumnsType, Descriptions, Table } from '~/core-components'
import { Errors, StoreState } from '~/types/store'
import { LeaveImportValidateReportState } from '../../../../types'
import './ValidateLeaveReport.less'

interface ValidateLeaveReportProps {
  visible: boolean
}

export const ValidateLeaveReport: FC<ValidateLeaveReportProps> = ({ visible }) => {
  const report = useSelector((state: StoreState) => state.payroll.leaveImportValidate?.report || [])
  const summary = useSelector((state: StoreState) => state.payroll.leaveImportValidate?.summary)
  const errors: Errors = { '*': ['No row to import'] }

  const columns: ColumnsType<LeaveImportValidateReportState> = [
    {
      title: 'Errors / warnings',
      key: 'message',
      dataIndex: 'message',
      width: '50%'
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      width: '50%'
    }
  ]

  return (
    <div className="validate-leave-report" hidden={!visible}>
      <Descriptions bordered>
        <Descriptions.Item label="Total row(s)">{summary?.total || 0}</Descriptions.Item>
        <Descriptions.Item label="Skipped">{summary?.skip || 0}</Descriptions.Item>
        <Descriptions.Item label={<b>To import</b>}>
          <b>{summary?.proceed || 0}</b>
        </Descriptions.Item>
      </Descriptions>
      <ErrorDisplay errors={(summary?.proceed || 0) === 0 ? errors : undefined} />
      {report.length > 0 && <Table rowKey="message" dataSource={report} pagination={false} columns={columns} />}
    </div>
  )
}
