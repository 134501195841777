import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { EmPayGroupState, IEmPayGroup } from '../types'

const baseUrl = getBaseUrl('/employee/employee')

export const apiGetEmPayGroups = (employeeId: string) =>
  request<EmPayGroupState[]>('get', `${baseUrl}/${employeeId}/paygroup`)

export const apiGetEmPayGroup = (employeeId: string, emPayGroupId: string) =>
  request<EmPayGroupState>('get', `${baseUrl}/${employeeId}/paygroup/${emPayGroupId}`)

export const apiAddEmPayGroup = (employeeId: string, emPayGroup: IEmPayGroup) =>
  request<{ id: string; emPayGroupId?: string }>('post', `${baseUrl}/${employeeId}/paygroup`, emPayGroup)

export const apiUpdateEmPayGroup = (employeeId: string, id: string, emPayGroup: Operation[]) =>
  request<{ emPayGroupId?: string }>('patch', `${baseUrl}/${employeeId}/paygroup/${id}`, emPayGroup)

export const apiDeleteEmPayGroup = (employeeId: string, id: string) =>
  request<{ emPayGroupId?: string }>('delete', `${baseUrl}/${employeeId}/paygroup/${id}`)
