import { Delimiter } from '~/constants'
import { IPayRunPayslip, PayRunPayslipState } from './payrun-payslip.state'

export const mapPayRunPayslipStateToIPayRunPayslip = (payRunPayslip: PayRunPayslipState): IPayRunPayslip => ({
  id: payRunPayslip.id,
  payRunId: payRunPayslip.payRunId,
  isAutoPublish: payRunPayslip.isAutoPublish,
  autoPublishDate: payRunPayslip.autoPublishDate,
  excludeEmployeeIds: payRunPayslip.excludeEmployeeIds?.join(Delimiter.comma)
})

export const mapIPayRunPayslipToPayRunPayslipState = (payRunPayslip: IPayRunPayslip): PayRunPayslipState => {
  let excludeIds: string[] = []
  if (payRunPayslip.excludeEmployeeIds !== '') {
    excludeIds = payRunPayslip.excludeEmployeeIds?.split(Delimiter.comma)
  }

  return {
    id: payRunPayslip.id,
    payRunId: payRunPayslip.payRunId,
    isAutoPublish: payRunPayslip.isAutoPublish,
    autoPublishDate: payRunPayslip.autoPublishDate,
    excludeEmployeeIds: excludeIds
  }
}
