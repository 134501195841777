import { PayTranGroup, PayTranSource } from '~/constants'

export const isEditablePayTranSource = (source: string) =>
  source === PayTranSource.basicSalary ||
  source === PayTranSource.recurring ||
  source === PayTranSource.fund ||
  source === PayTranSource.entry ||
  source === PayTranSource.import ||
  source === PayTranSource.pyCpf

export const isEditablePayTranGroup = (group: string) =>
  group === PayTranGroup.basicPay || group === PayTranGroup.sdl || group === PayTranGroup.fwl
