import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchMyLeaveBalances } from '../../actions'
import { selectMyEntitledLeaveTypes } from '../../selectors'
import { SSEntitledLeaveType } from '../../types'

export const useMyEntitledLeaveTypes = (includeLeaveTypeId?: string): [SSEntitledLeaveType[], boolean] => {
  const entitledLeaveTypes = useSelector(selectMyEntitledLeaveTypes)(includeLeaveTypeId)
  const loading = useSelector((state: StoreState) => state.my.myLeaveBalancesLoading)

  useEffect(() => {
    dispatch(fetchMyLeaveBalances())
  }, [])

  return [entitledLeaveTypes, loading]
}
