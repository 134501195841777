import React, { ChangeEvent, CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { Alert, Button, Form, Input, Radio, RadioChangeEvent } from '~/core-components'
import {
  Col,
  EditableCard,
  EditableCardState,
  EmpKeyValues,
  NewTabLinkIcon,
  PercentInput,
  Row,
  SalaryInput,
  SysOptions
} from '~/components'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { useFocus } from '~/hooks/use-focus'
import { dispatch } from '~/stores/store'
import { Errors, ActionResult } from '~/types/store'
import { IEmployeePayment, EmployeeState } from '../../../types'
import { mapEmployeeStateToEmployeePayment } from '../../../types/employee.mapper'
import { updateEmployeePayment } from '../../../actions'
import { SETTINGS_ROUTES } from '~/routes/routes'

interface EmployeePaymentProps {
  employee?: EmployeeState
  onEdit?: () => void
  onSave?: () => void
  onCancel?: () => void
}

const EMPTY_FORM_DATA: IEmployeePayment = {
  payMethod: '',
  paymentNotes: '',
  bankCode: '',
  bankBranchCode: '',
  bankAccountNo: '',
  bankAccountName: '',
  bankCode2: '',
  bankBranchCode2: '',
  bankAccountNo2: '',
  bankAccountName2: '',
  paymentNotes2: '',
  bankAllocation: '',
  bankAmount2: 0,
  bankPercentage2: 0
}

const cardStyle: CSSProperties = { margin: 24 }
const labelPrimaryStyle: CSSProperties = { marginBottom: 7 }
const labelSecondaryStyle: CSSProperties = { marginTop: 20, marginBottom: 7 }
const buttonAddSecondaryStyle: CSSProperties = { marginBottom: 20 }
const radioStyle: CSSProperties = { width: '100%' }
const radioItemStyle: CSSProperties = { marginTop: 5 }

export const EmployeePayment: FC<EmployeePaymentProps> = ({
  employee,
  onEdit,
  onSave,
  onCancel
}: EmployeePaymentProps) => {
  const [cardState, setCardState] = useState<EditableCardState>()
  const [formData, setFormData] = useState<IEmployeePayment>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const [showSecondary, setShowSecondary] = useState(false)
  const [focusRef] = useFocus(cardState === 'editing')
  const canView = usePermissionGate(Permission.employeePayment)
  const canModify = usePermissionGate(Permission.employeePayment, PermissionAction.Modify)
  const canModifyMs = usePermissionGate(Permission.master, PermissionAction.Modify)

  useEffect(() => {
    if (employee) {
      const {
        payMethod,
        paymentNotes,
        bankCode,
        bankBranchCode,
        bankAccountNo,
        bankAccountName,
        bankCode2,
        bankBranchCode2,
        bankAccountNo2,
        bankAccountName2,
        paymentNotes2,
        bankAllocation,
        bankAmount2,
        bankPercentage2
      } = employee
      setFormData({
        payMethod,
        paymentNotes,
        bankCode,
        bankBranchCode,
        bankAccountNo,
        bankAccountName,
        bankCode2,
        bankBranchCode2,
        bankAccountNo2,
        bankAccountName2,
        paymentNotes2,
        bankAllocation,
        bankAmount2,
        bankPercentage2
      })
      setShowSecondary(!!bankCode2)
    } else {
      setFormData(EMPTY_FORM_DATA)
      setShowSecondary(false)
    }
  }, [employee])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => setFormData(formData => ({ ...formData, ...updates })),
    []
  )

  const handleEdit = useCallback(() => {
    setCardState('editing')
    typeof onEdit === 'function' && onEdit()
  }, [onEdit])

  const handleSave = useCallback(async () => {
    if (employee) {
      setCardState('saving')
      setErrors(undefined)
      setShowSecondary(!!formData.bankCode2)

      typeof onSave === 'function' && onSave()

      let result: ActionResult | undefined
      try {
        result = await dispatch(
          updateEmployeePayment(employee.id, mapEmployeeStateToEmployeePayment(employee), formData)
        )
      } catch {
        setCardState('editing')
      }

      if (result?.errors) {
        setCardState('editing')
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setCardState(undefined)
      }
    }
  }, [employee, formData, onSave])

  const handleCancel = useCallback(() => {
    typeof onCancel === 'function' && onCancel()
    setCardState(undefined)
    setErrors(undefined)

    if (employee) {
      setShowSecondary(!!employee.bankCode2)

      const {
        payMethod,
        paymentNotes,
        bankCode,
        bankBranchCode,
        bankAccountNo,
        bankAccountName,
        bankCode2,
        bankBranchCode2,
        bankAccountNo2,
        bankAccountName2,
        paymentNotes2,
        bankAllocation,
        bankAmount2,
        bankPercentage2
      } = employee

      setFormData({
        payMethod,
        paymentNotes,
        bankCode,
        bankBranchCode,
        bankAccountNo,
        bankAccountName,
        bankCode2,
        bankBranchCode2,
        bankAccountNo2,
        bankAccountName2,
        paymentNotes2,
        bankAllocation,
        bankAmount2,
        bankPercentage2
      })
    }
  }, [employee, onCancel])

  const handleAddSecondaryAccount = useCallback(() => {
    setShowSecondary(true)
  }, [])

  const readOnly = cardState !== 'editing'

  if (!canView) return null

  return (
    <EditableCard
      title="Payment information"
      style={cardStyle}
      bodyStyle={{ paddingBottom: employee ? 6 : 24, paddingTop: 6 }}
      state={canModify ? cardState : 'readonly'}
      formId="form-em-payment"
      onEdit={handleEdit}
      onSave={handleSave}
      onCancel={handleCancel}
    >
      <Row>
        <Col span={6}>
          <Form.Item label="Payment method" validateStatus={errors?.payMethod ? 'error' : ''} help={errors?.payMethod}>
            <SysOptions
              type="pay_method"
              value={formData.payMethod}
              ref={focusRef}
              readOnly={readOnly}
              onChange={(value: string) => handleFormDataChange({ payMethod: value })}
            />
          </Form.Item>
        </Col>
      </Row>
      {formData.payMethod === 'bank' && (
        <>
          <Row hidden={!showSecondary}>
            <Col span={24}>
              <div style={labelPrimaryStyle}>Primary</div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item label="Bank" validateStatus={errors?.bankCode ? 'error' : ''} help={errors?.bankCode}>
                <NewTabLinkIcon
                  path={SETTINGS_ROUTES.banks}
                  tooltipText="Open bank in a new tab"
                  tooltipPlacement="topRight"
                  hidden={!canModifyMs}
                >
                  <EmpKeyValues
                    id="bank"
                    value={formData.bankCode}
                    readOnly={readOnly}
                    onChange={(value: string) => handleFormDataChange({ bankCode: value })}
                  />
                </NewTabLinkIcon>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={30}>
            <Col span={12}>
              <Form.Item label="Branch">
                <Input
                  value={formData.bankBranchCode}
                  readOnly={readOnly}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleFormDataChange({ bankBranchCode: event.target.value })
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Account no."
                validateStatus={errors?.bankAccountNo ? 'error' : ''}
                help={errors?.bankAccountNo}
              >
                <Input
                  value={formData.bankAccountNo}
                  readOnly={readOnly}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleFormDataChange({ bankAccountNo: event.target.value })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}

      <Row hidden={readOnly && !formData.paymentNotes}>
        <Col span={24}>
          <Form.Item label="Notes">
            <Input
              value={formData.paymentNotes}
              readOnly={readOnly}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleFormDataChange({ paymentNotes: event.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>

      {formData.payMethod === 'bank' &&
        (!showSecondary ? (
          cardState === 'editing' && (
            <Button
              type="dashed"
              size="large"
              block
              style={buttonAddSecondaryStyle}
              onClick={handleAddSecondaryAccount}
            >
              add secondary account
            </Button>
          )
        ) : (
          <>
            <Row>
              <Col span={24}>
                <div style={labelSecondaryStyle}>Secondary</div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item label="Bank" validateStatus={errors?.bankCode2 ? 'error' : ''} help={errors?.bankCode2}>
                  <NewTabLinkIcon
                    path={SETTINGS_ROUTES.banks}
                    tooltipText="Open bank in a new tab"
                    tooltipPlacement="topRight"
                    hidden={!canModifyMs}
                  >
                    <EmpKeyValues
                      id="bank"
                      value={formData.bankCode2}
                      readOnly={readOnly}
                      onChange={(value: string) => handleFormDataChange({ bankCode2: value })}
                    />
                  </NewTabLinkIcon>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={30}>
              <Col span={12}>
                <Form.Item label="Branch">
                  <Input
                    value={formData.bankBranchCode2}
                    readOnly={readOnly}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      handleFormDataChange({ bankBranchCode2: event.target.value })
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Account no."
                  validateStatus={errors?.bankAccountNo2 ? 'error' : ''}
                  help={errors?.bankAccountNo2}
                >
                  <Input
                    value={formData.bankAccountNo2}
                    readOnly={readOnly}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      handleFormDataChange({ bankAccountNo2: event.target.value })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item label="Notes">
                  <Input
                    value={formData.paymentNotes2}
                    readOnly={readOnly}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      handleFormDataChange({ paymentNotes2: event.target.value })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item validateStatus={errors?.bankAllocation ? 'error' : ''} help={errors?.bankAllocation}>
              <Radio.Group
                value={formData.bankAllocation}
                onChange={(event: RadioChangeEvent) =>
                  handleFormDataChange({
                    bankAllocation: event.target.value,
                    ...{ [event.target.value === 'amount' ? 'bankPercentage2' : 'bankAmount2']: 0 }
                  })
                }
                style={radioStyle}
              >
                <Row gutter={30}>
                  <Col span={12}>
                    <Radio value="amount">By amount</Radio>
                    <Form.Item style={radioItemStyle}>
                      <SalaryInput
                        value={formData.bankAmount2}
                        readOnly={readOnly}
                        disabled={formData.bankAllocation !== 'amount'}
                        onChange={(value: number | null) => handleFormDataChange({ bankAmount2: value })}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Radio value="percent">By percentage</Radio>
                    <Form.Item style={radioItemStyle}>
                      <PercentInput
                        value={formData.bankPercentage2}
                        readOnly={readOnly}
                        disabled={formData.bankAllocation !== 'percent'}
                        onChange={(value: number | null) => handleFormDataChange({ bankPercentage2: value })}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Radio.Group>
            </Form.Item>
          </>
        ))}

      <Row hidden={!readOnly}>
        <Col span={24}>
          <Alert
            type="info"
            style={{ marginBottom: 20 }}
            showIcon
            message={
              <div style={{ marginLeft: 10 }}>
                To ensure that any changes to the payment details are reflected in the payroll record of the employee,
                please remember to reprocess their payroll after updating the details.
              </div>
            }
          />
        </Col>
      </Row>
    </EditableCard>
  )
}
