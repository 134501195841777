import { UploadFile } from '~/core-components'
import { request, getBaseUrl } from '~/utils/request'
import {
  SSClaimAmount,
  SSClaimApply,
  SSClaimRecordExpense,
  SSClaimAmountMultipleRequest,
  SSClaimAmountMultipleResponse
} from '../types'

const baseUrl = getBaseUrl('/claim/ssclaimapply')

export const apiGetMyClaimAmount = (claimAmount: SSClaimAmount) => {
  return request<{ claimAmount: number; claimAmountTax: number }>('post', `${baseUrl}/claimamount`, claimAmount)
}

export const apiGetClaimAmountMultiple = (claimAmount: SSClaimAmountMultipleRequest) => {
  return request<SSClaimAmountMultipleResponse>('post', `${baseUrl}/claimamountmultiple`, claimAmount)
}

export const apiApplyMyClaimRecord = (claimRecord: SSClaimApply, isSubmit: boolean) => {
  let formData = new FormData()
  formData.append('isSubmit', isSubmit ? 'true' : 'false')

  Object.entries(claimRecord).forEach(([key, value]) => {
    if (key === 'attachments') {
      value.forEach((x: UploadFile) => {
        if (x instanceof File) formData.append(key, x, x.name)
      })
    } else if (key === 'deletedAttachmentIds') {
      value.forEach((x: string) => {
        formData.append(key, x)
      })
    } else if (key === 'expenses') {
      value.forEach((x: SSClaimRecordExpense, index: number) => {
        Object.keys(x).forEach((key: string) => {
          const expenseValue = x[key as keyof SSClaimRecordExpense] as any
          if (key === 'attachments') {
            x.attachments?.forEach((y: UploadFile) => {
              if (y instanceof File) formData.append(key, y, `${index}||${y.name}`)
            })
          } else if (key === 'deletedAttachmentIds') {
            x.deletedAttachmentIds?.forEach((y: string) => {
              formData.append(key, y)
            })
          } else {
            formData.append(`expenses[${index}][${key}]`, expenseValue === null ? '' : expenseValue)
          }
        })
      })
    } else {
      formData.append(key, value === null ? '' : value)
    }
  })
  return request<{ status: string; request: SSClaimApply }>('post', baseUrl, formData)
}

export const apiCancelMyClaimRecord = (id: string) => {
  return request<{
    status: string
  }>('post', `${baseUrl}/cancel`, { id })
}
