import { replaceXeroConnections, setXeroConnectionLoading } from '../reducers'
import { apiGetXeroConnections } from '../api/xero.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchXeroConnections = (options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().payroll.xeroConnectionsLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().payroll.xeroConnections?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setXeroConnectionLoading(true))
      const { result, status } = await apiGetXeroConnections()
      if (status) {
        dispatch(replaceXeroConnections(result))
      }
    } finally {
      dispatch(setXeroConnectionLoading(false))
    }
  }
}
