import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Dictionary } from '@reduxjs/toolkit'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchSysLtFields } from '../actions'
import { selectSysLtFieldsByCode } from '../selectors'
import { SysLtFieldState } from '../types'

export const useSysLtFields = (sysLeaveTypeCode: string): [Dictionary<SysLtFieldState>, boolean] => {
  const sysLtFields = useSelector(selectSysLtFieldsByCode)(sysLeaveTypeCode)
  const loading = useSelector((state: StoreState) => state.leave.sysLtFieldsLoading[sysLeaveTypeCode])

  useEffect(() => {
    dispatch(fetchSysLtFields(sysLeaveTypeCode, { strategy: 'when-empty' }))
  }, [sysLeaveTypeCode])

  return [sysLtFields, loading]
}
