import { replaceEntitledClaimTypes, setEntitledClaimTypesLoading } from '../reducers'
import { apiGetEntitledClaimTypes } from '../api/claim-entitlement.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchEntitledClaimTypes = (
  employeeId: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!employeeId) return

    const loading = getState().claim.entitledClaimTypesLoading[employeeId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().claim.entitledClaimTypes[employeeId]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setEntitledClaimTypesLoading({ employeeId, loading: true }))
      const { result, status } = await apiGetEntitledClaimTypes(employeeId)
      if (status) {
        dispatch(replaceEntitledClaimTypes({ employeeId, data: result }))
      }
    } finally {
      dispatch(setEntitledClaimTypesLoading({ employeeId, loading: false }))
    }
  }
}
