import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { BankState } from '../types'

export const selectBanks = createSelector(
  (state: StoreState) => state.master.banks,
  (bankState): (BankState | undefined)[] => {
    const banks = Object.values(bankState?.entities || {}) as (BankState | undefined)[]
    return banks.sort((a, b) => a?.name.localeCompare(b?.name || '') || 0)
  }
)
