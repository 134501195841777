import React, { FC } from 'react'
import { Col, Row, SysOptions } from '~/components'
import { Alert, Form, Input, Link, Space } from '~/core-components'
import { Errors } from '~/types/store'
import { KeyValue } from '~/types/common'
import { emptyGuid, LveEarnedBasis, LveSysLeaveType, LveUnit } from '~/constants'
import { LeaveTypeState, LpEntPerTimeState } from '../../../types'
import './MutateLtPolicyDrawer.less'

interface LpEntPerTimeProps {
  leaveType?: LeaveTypeState
  errors?: Errors
  ltPolicyId?: string
  entPerTime: LpEntPerTimeState[]
  onChange: (entData: LpEntPerTimeState[]) => void
}

const RELEVANT_ERRORS = ['relation', 'entitlement', 'unit']

export const LpEntPerTime: FC<LpEntPerTimeProps> = ({ leaveType, errors, ltPolicyId, entPerTime, onChange }) => {
  const isCompassionateLve = leaveType?.sysLeaveTypeCode === LveSysLeaveType.com
  const NEW_ENTITLEMENTS: LpEntPerTimeState[] = [
    { id: emptyGuid, ltPolicyId: ltPolicyId || emptyGuid, relation: '', entitlement: 0, unit: '' }
  ]
  const relevantErrors = Object.keys(errors || {}).filter(e => RELEVANT_ERRORS.includes(e))

  if (!isCompassionateLve) return null

  return (
    <Row hidden={leaveType?.earnedBasis === LveEarnedBasis.noEntitlement}>
      <Col span={24}>
        <div className="entitlement__title">Entitlement per occurence</div>
        {errors && relevantErrors.length > 0 && (
          <Alert
            type="error"
            message={
              <>
                {relevantErrors.map((error, index) => (
                  <div key={index}>{errors[error]}</div>
                ))}
              </>
            }
          />
        )}
        {entPerTime?.map((ent, index) => (
          <Row gutter={30} key={index} className="entitlement-row">
            <Col>
              {index === 0 && <div className="entitlement-row__title">Relation</div>}
              <SysOptions
                type="lve_relationship"
                className="ent-per-time-relation"
                value={ent.relation}
                allowClear={false}
                onChange={(value: string) =>
                  onChange([
                    ...entPerTime.slice(0, index),
                    { ...entPerTime[index], relation: value },
                    ...entPerTime.slice(index + 1, entPerTime.length)
                  ])
                }
              />
            </Col>
            <Col>
              {index === 0 && <div className="entitlement-row__title">Entitlement granted</div>}
              <Space>
                <Input.Number
                  required
                  min={0}
                  value={ent.entitlement}
                  onChange={(value?: any) =>
                    onChange([
                      ...entPerTime.slice(0, index),
                      { ...entPerTime[index], entitlement: value },
                      ...entPerTime.slice(index + 1, entPerTime.length)
                    ])
                  }
                />
                <SysOptions
                  type="lve_entitlement_unit"
                  className="entitlement-unit"
                  value={ent.unit}
                  allowClear={false}
                  renderItem={(kv: KeyValue) => kv.value.toLowerCase()}
                  onChange={(value: string) =>
                    onChange([
                      ...entPerTime.slice(0, index),
                      { ...entPerTime[index], unit: value },
                      ...entPerTime.slice(index + 1, entPerTime.length)
                    ])
                  }
                />
              </Space>
            </Col>
            <Col>
              <Link
                onClick={() =>
                  onChange([...entPerTime.slice(0, index), ...entPerTime.slice(index + 1, entPerTime.length)])
                }
              >
                remove
              </Link>
            </Col>
          </Row>
        ))}
        <Form.Item>
          <Link
            onClick={() =>
              onChange([
                ...entPerTime,
                ...NEW_ENTITLEMENTS.map(ent => ({
                  ...ent,
                  unit: entPerTime.length > 0 ? entPerTime[0].unit : LveUnit.days
                }))
              ])
            }
          >
            add more entitlements
          </Link>
        </Form.Item>
      </Col>
    </Row>
  )
}
