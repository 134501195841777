import React, { CSSProperties, FC } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { LoadingOutlined } from '@ant-design/icons'
import { Alert, ColumnsType, Spin, Table } from '~/core-components'
import { StoreState } from '~/types/store'
import { TimeLogImportRowState } from '~/features/attendance/types'
import './ImportTimeLog.less'

interface ImportTimeLogProps {
  visible: boolean
  success?: boolean
}

const spinStyles: CSSProperties = { marginRight: 10 }

export const ImportTimeLog: FC<ImportTimeLogProps> = ({ visible, success = false }) => {
  const data = useSelector((state: StoreState) => state.attendance.timeLogImportValidate?.data || [])
  const processing = useSelector((state: StoreState) => state.attendance.timeLogsViewLoading)

  const columns: ColumnsType<TimeLogImportRowState> = [
    {
      title: 'Clock employee ID',
      key: 'badgeNo',
      dataIndex: 'badgeNo'
    },
    {
      title: 'Employee',
      key: 'employeeNo',
      dataIndex: 'employeeNo',
      render: (value: string, record) => `[${record.employeeNo}] ${record.employeeName}`
    },
    {
      title: 'Time in',
      key: 'timeIn',
      dataIndex: 'timeIn',
      render: (value: string) => value && moment(value).format('DD MMM YYYY HH:mm')
    },
    {
      title: 'Time out',
      key: 'timeOut',
      dataIndex: 'timeOut',
      render: (value: string) => value && moment(value).format('DD MMM YYYY HH:mm')
    },
    {
      title: 'Location',
      key: 'locationName',
      dataIndex: 'locationName'
    }
  ]

  return (
    <div className="import-time-log" hidden={!visible}>
      {success && (
        <Alert
          type="success"
          showIcon
          message={
            <>
              <div>Import successful</div>
              {processing ? (
                <div>
                  <Spin style={spinStyles} size="small" indicator={<LoadingOutlined spin />} />
                  <span>You may close this while we're importing the time logs</span>
                </div>
              ) : (
                <div>All the time logs has been successfully imported</div>
              )}
            </>
          }
        />
      )}
      <Table className="import-time-log__table" rowKey="id" dataSource={data} fitParent columns={columns} />
    </div>
  )
}
