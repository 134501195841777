import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { StoreState } from '~/types/store'
import { LoadingOutlined } from '@ant-design/icons'
import { Form, Spin } from '~/core-components'
import { Col, EmSelect, EmSelectState, PdfViewer, Row } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { PayRunStatus, Permission, PermissionAction } from '~/constants'
import { useFocus } from '~/hooks/use-focus'
import { getBaseUrl } from '~/utils'
import { apiGetPayRecordEmployees } from '../../api/payrecord-sg.api'
import { selectPayRunById } from '../../reducers'
import { PublishSwitcher } from './PublishSwitcher'
import { PayslipDownloadButton } from './PayslipDownloadButton'
import { PayslipRefreshButton } from './PayslipRefreshButton'
import { ExportAllPayslipsButton } from './ExportAllPayslipsButton'
import './Payslip.less'

export interface PayslipProps {
  payRunId: string
}

interface PayslipFormData {
  employeeId: string
}

const EMPTY_FORM_DATA: PayslipFormData = {
  employeeId: ''
}

const baseUrl = getBaseUrl('/payroll-sg')

export const Payslip: FC<PayslipProps> = ({ payRunId }) => {
  const [url, setUrl] = useState<string>('')
  const [formData, setFormData] = useState<PayslipFormData>(EMPTY_FORM_DATA)
  const [focusRef] = useFocus(true)
  const refetch = useSelector((state: StoreState) => state.payroll.payslipEmployeeRefetch)
  const [preview, setPreview] = useState(0)
  const payRun = useSelector((state: StoreState) => selectPayRunById(state, payRunId))
  const canModify = usePermissionGate(Permission.payRun, PermissionAction.Modify)

  useEffect(() => {
    if (payRunId && formData.employeeId) {
      setUrl(`${baseUrl}/payslip/${payRunId}/${formData.employeeId}/pdf?p=${preview}`)
    }
  }, [payRunId, formData.employeeId, preview])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  const handleSelectFirstEmployee = useCallback(
    (employees: EmSelectState[]) => {
      handleFormDataChange({ employeeId: employees[0].id })
    },
    [handleFormDataChange]
  )

  const handleFetchEmployees = useCallback(async () => {
    const { status, result } = await apiGetPayRecordEmployees(payRunId)
    if (status) {
      return result
    }
    return []
  }, [payRunId, refetch])

  const handleGenerated = useCallback(() => {
    setPreview(p => p + 1)
  }, [])

  return (
    <div className="payslip">
      <Row className="payslip__header" gutter={12}>
        <Col>
          <Form.Item label="Employee">
            <EmSelect
              ref={focusRef}
              value={formData.employeeId}
              onFetch={handleFetchEmployees}
              onDataLoaded={handleSelectFirstEmployee}
              onChange={(value: string) => handleFormDataChange({ employeeId: value })}
              dropdownMatchSelectWidth={false}
            />
          </Form.Item>
        </Col>
        <Col>
          <PayslipDownloadButton payRunId={payRunId} employeeId={formData.employeeId} />
        </Col>
        {canModify && payRun?.status !== PayRunStatus.completed && (
          <Col>
            <PayslipRefreshButton payRunId={payRunId} employeeId={formData.employeeId} onDone={handleGenerated} />
          </Col>
        )}
        <Col flex={1}>
          <PublishSwitcher payRunId={payRunId} employeeId={formData.employeeId} />
        </Col>
        <Col>
          <ExportAllPayslipsButton payRunId={payRunId} />
        </Col>
      </Row>
      {url ? <PdfViewer file={url} /> : <Spin indicator={<LoadingOutlined spin />} />}
    </div>
  )
}
