import { EntityState } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import {
  WfEventState,
  SysWfActionTypeState,
  SysWfEventTypeState,
  SysWfFlowTypeState,
  WorkflowState,
  WfActionState
} from './workflow.state'
import { WfCategory } from './dto'

export const workflowPersistConfig = {
  key: 'workflow',
  whitelist: ['sysWfFlowTypes', 'sysWfEventTypes', 'sysWfActionTypes'],
  storage
}

export interface WorkflowRootState {
  sysWfFlowTypes: { [category in WfCategory]?: EntityState<SysWfFlowTypeState> }
  sysWfFlowTypesLoading: { [category in WfCategory]?: boolean }
  sysWfEventTypes: { [flowType in string]?: EntityState<SysWfEventTypeState> }
  sysWfEventTypesLoading: { [flowType in string]?: boolean }
  sysWfActionTypes: { [flowEventType in string]?: EntityState<SysWfActionTypeState> }
  sysWfActionTypesLoading: { [flowEventType in string]?: boolean }
  workflows: { [category in WfCategory]?: EntityState<WorkflowState> }
  workflowsLoading: { [category in WfCategory]?: boolean }
  wfEvents: { [workflowId: string]: EntityState<WfEventState> }
  wfEventsLoading: { [workflowId: string]: boolean }
  wfActions: { [wfEventId: string]: EntityState<WfActionState> }
  wfActionsLoading: { [wfEventId: string]: boolean }
}
