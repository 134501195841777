import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { ClaimPendingPaymentViewState } from '../types'
import { fetchClaimPendingPaymentsView } from '../actions'

export const useClaimPendingPaymentView = (
  viewId: string,
  periodYear: number | undefined,
  page: number,
  pageSize: number,
  search: string,
  fetchStrategy: FetchStrategy = 'when-empty'
): [ClaimPendingPaymentViewState | undefined, boolean] => {
  const data = useSelector((state: StoreState) => state.claim.claimPendingPaymentsView?.entities[viewId])
  const loading = useSelector((state: StoreState) => state.claim.claimPendingPaymentsViewLoading)
  const refetch = useSelector((state: StoreState) => state.claim.claimPendingPaymentsViewRefetch)

  useEffect(() => {
    if (viewId)
      dispatch(
        fetchClaimPendingPaymentsView(viewId, periodYear, { offset: pageSize * (page - 1), limit: pageSize }, search, {
          strategy: fetchStrategy
        })
      )
  }, [viewId, periodYear, page, pageSize, search, refetch, fetchStrategy])

  return [data, loading]
}
