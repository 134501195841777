import { AttendanceGroupState, IAttendanceGroupInfo } from './attendance-group.state'

export const mapAttendanceGroupStateToAttendanceGroupInfo = (
  attendanceGroup: AttendanceGroupState
): IAttendanceGroupInfo => ({
  name: attendanceGroup.name,
  type: attendanceGroup.type,
  otStartDay: attendanceGroup.otStartDay,
  otStartMonth: attendanceGroup.otStartMonth,
  inactiveDate: attendanceGroup.inactiveDate
})
