import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Button, Tooltip } from '~/core-components'
import { Col, Row, SysOptions } from '~/components'
import { WorkStatusIcon, WorkStatusIconType } from '~/features/master'
import { useSysOptions } from '~/features/employee'
import { WorkStatus, WorkStatusType } from '~/constants'
import { StoreState } from '~/types/store'
import { dispatch } from '~/stores/store'
import { ICalendarPattern } from '../../../types'
import { fetchShiftDays } from '../../../actions'
import { getDayCode, selectShiftDaysDict } from '../../../selectors'
import { ShiftKeyValues } from '../../Shift/components/ShiftKeyValues'
import './CalendarPatternRow.less'

interface CalendarPatternRowProps {
  patternStartDate?: string
  data: ICalendarPatternRow
  readOnly: boolean
  onChange: (row: Partial<ICalendarPatternRow>) => void
  onDelete: () => void
}

export type ICalendarPatternRow = ICalendarPattern & { workStatusType?: string }

export const CalendarPatternRow: FC<CalendarPatternRowProps> = ({
  patternStartDate,
  data,
  readOnly,
  onChange,
  onDelete
}) => {
  const patternDate = moment(patternStartDate).add(data.sequence, 'day').add(-1, 'day').format('YYYY-MM-DD')
  const refetch = useSelector((state: StoreState) => state.attendance.shiftDaysRefetch)
  const getShiftDict = useSelector(selectShiftDaysDict)
  const [workStatus] = useSysOptions('work_status')

  useEffect(() => {
    dispatch(fetchShiftDays(data.shiftId, { strategy: 'always' }))
  }, [refetch, data.shiftId])

  return (
    <Row gutter={15} className="calendar-pattern-row section-row">
      <Col flex="140px">{moment(patternDate).format('D MMM YYYY (ddd)')}</Col>
      <Col flex="180px">
        <SysOptions
          type="work_status_type"
          readOnly={readOnly}
          value={data.workStatusType}
          onChange={(workStatusType: string) => {
            if ([WorkStatusType.OffDay, WorkStatusType.RestDay].includes(workStatusType as WorkStatusType)) {
              onChange({ workStatusType, workStatus: workStatusType })
            } else {
              onChange({ workStatusType })
            }
          }}
        />
      </Col>
      <Col flex="1">
        {data.workStatusType === WorkStatusType.WorkingDay && (
          <Row align="middle">
            <Col flex="1">
              <ShiftKeyValues
                readOnly={readOnly}
                value={data.shiftId}
                onChange={(shiftId: string) => {
                  const dayCode = getDayCode(patternDate || '', data?.workStatus || '')
                  const shiftDict = getShiftDict(shiftId)
                  onChange({ shiftId, workStatus: shiftDict[dayCode]?.workStatus || WorkStatus.full })
                }}
              />
            </Col>
            <Col>
              {workStatus[data.workStatus || ''] && (
                <Tooltip title={workStatus[data.workStatus || '']?.value}>
                  <div>
                    <WorkStatusIcon workStatus={data.workStatus as WorkStatusIconType} />
                  </div>
                </Tooltip>
              )}
            </Col>
          </Row>
        )}
      </Col>
      <Col hidden={readOnly}>
        <Tooltip title="Remove row">
          <Button size="small" type="text" icon={<i className="fal fa-xmark" />} onClick={() => onDelete()} />
        </Tooltip>
      </Col>
    </Row>
  )
}
