import React, { FC, useCallback, useState } from 'react'
import { DocumentTitle } from '~/components'
import { Permission, PermissionAction } from '~/constants'
import { Button, Card, ColumnsType, Link, PageHeader, Space, Table } from '~/core-components'
import { InactiveTag } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { isInactive } from '~/utils'
import { BreakState } from '../../types'
import { useBreaks } from '../../hooks'
import { MutateBreakDrawer } from './components/MutateBreakDrawer'
import './Breaks.less'

interface BreaksProps {}

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: '',
    breadcrumbName: 'Breaks'
  }
]

interface DrawerState {
  visible: boolean
  data?: BreakState
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

export const Breaks: FC<BreaksProps> = () => {
  const [data, dataLoading] = useBreaks()

  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const canModify = usePermissionGate(Permission.break, PermissionAction.Modify)

  const handleAddBreak = useCallback(() => {
    setDrawerState({ visible: true })
  }, [])

  const handleEditBreak = useCallback((_break: BreakState) => {
    setDrawerState({ visible: true, data: _break })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const columns: ColumnsType<BreakState> = [
    {
      title: 'Break',
      key: 'name',
      dataIndex: 'name',
      render: (value: string, record) => (
        <Space>
          {value}
          {isInactive(record.inactiveDate) && <InactiveTag />}
        </Space>
      )
    },
    {
      key: 'action',
      align: 'right',
      render: (value: string, record) => (
        <Link size="small" onClick={() => handleEditBreak(record)}>
          edit
        </Link>
      )
    }
  ]

  return (
    <div id="breaks" className="breaks">
      <DocumentTitle title="Breaks" />
      <PageHeader
        title="Breaks"
        containerId="breaks"
        breadcrumb={{ routes }}
        extra={
          canModify && (
            <Button key="add" onClick={handleAddBreak}>
              Add break
            </Button>
          )
        }
      />
      <div className="breaks__body">
        <Card table>
          <Table rowKey="id" dataSource={data} pagination={false} columns={columns} loading={dataLoading} />
        </Card>
      </div>
      {canModify && <MutateBreakDrawer {...drawerState} onClose={handleCloseDrawer} />}
    </div>
  )
}
