import React, { CSSProperties, FC } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Alert, ColumnsType, Spin, Table } from '~/core-components'
import { LoadingOutlined } from '@ant-design/icons'
import { StoreState } from '~/types/store'
import { formatNumberUnit, UnitType } from '~/utils'
import { EncashImportRowState } from '../../../../types'
import './ImportEncashRecord.less'

interface ImportEncashRecordProps {
  encashType: string
  visible: boolean
  success?: boolean
}

const spinStyles: CSSProperties = { marginRight: 10 }

export const ImportEncashRecord: FC<ImportEncashRecordProps> = ({ encashType, visible, success }) => {
  const data = useSelector((state: StoreState) => state.payroll.encashImportValidate?.data || [])
  const processing = useSelector((state: StoreState) => state.payroll.payrollLoading)

  const columns: ColumnsType<EncashImportRowState> = [
    {
      title: 'Employee',
      key: 'employeeNo',
      dataIndex: 'employeeNo',
      render: (value: string, record) => `[${value}] ${record.employeeName}`
    },
    {
      title: 'Leave type',
      key: 'leaveTypeName',
      dataIndex: 'leaveTypeName'
    },
    {
      title: encashType === 'resign' ? 'Employment end date' : 'Period end',
      key: 'date',
      dataIndex: 'date',
      render: (value: string) => (value ? moment(value).format('DD MMM YYYY') : undefined)
    },
    {
      title: 'Quantity',
      key: 'quantity',
      dataIndex: 'quantity',
      render: (value: number, record) => formatNumberUnit(value, record.unit as UnitType)
    },
    {
      title: 'Payroll item',
      key: 'payItemName',
      dataIndex: 'payItemName'
    }
  ]

  return (
    <div className="import-encash-record" hidden={!visible}>
      {success && (
        <Alert
          type="success"
          showIcon
          message={
            <>
              <div>Import successful</div>
              {processing ? (
                <div>
                  <Spin style={spinStyles} size="small" indicator={<LoadingOutlined spin />} />
                  <span>You may close this while we're calculating the affected employees' payroll</span>
                </div>
              ) : (
                <div>All the affected employees' payroll has been calculated</div>
              )}
            </>
          }
        />
      )}
      <Table rowKey="id" dataSource={data} columns={columns} pagination={false} />
    </div>
  )
}
