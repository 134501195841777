import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { EmGrpState, IEmGrp } from '../types'

const baseUrl = getBaseUrl('/employee/employee')

export const apiGetEmGrps = (employeeId: string) => request<EmGrpState[]>('get', `${baseUrl}/${employeeId}/grp`)

export const apiGetEmGrp = (employeeId: string, emGrpId: string) =>
  request<EmGrpState>('get', `${baseUrl}/${employeeId}/grp/${emGrpId}`)

export const apiAddEmGrp = (employeeId: string, emGrp: IEmGrp) =>
  request<{ id: string; emGrpId?: string }>('post', `${baseUrl}/${employeeId}/grp`, emGrp)

export const apiUpdateEmGrp = (employeeId: string, id: string, emGrp: Operation[]) =>
  request<{ emGrpId?: string }>('patch', `${baseUrl}/${employeeId}/grp/${id}`, emGrp)

export const apiDeleteEmGrp = (employeeId: string, id: string) =>
  request<{ emGrpId?: string }>('delete', `${baseUrl}/${employeeId}/grp/${id}`)
