import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiDeleteLtPolicy } from '../api/lt-policy.api'
import { removeLtPolicy, removeLtPolicyExtra } from '../reducers'

export const deleteLtPolicy = (leaveTypeId: string, id: string): ThunkResult<void> => {
  return async dispatch => {
    if (!leaveTypeId || !id) return

    const { status, errors, message, errorData } = await apiDeleteLtPolicy(leaveTypeId, id)
    if (status) {
      dispatch(removeLtPolicy({ leaveTypeId, id }))
      dispatch(removeLtPolicyExtra({ leaveTypeId, id }))
      showSuccess('Deleted')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
