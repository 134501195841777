import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Descriptions } from '~/core-components'
import { fetchKeyvalues } from '~/features/master'
import { dispatch } from '~/stores/store'
import { selectCurrentEmAttendanceGroup } from '../../../../../selectors'
import { StoreState } from '~/types/store'

interface CopyEmAttendanceGroupProps {
  employeeId?: string
  emAttendanceGroupId?: string
}

export const CopyEmAttendanceGroup: FC<CopyEmAttendanceGroupProps> = ({ employeeId, emAttendanceGroupId }) => {
  const attendanceGroups = useSelector((state: StoreState) => state.master.keyvalues['attendanceGroup']?.entities)
  const emAttendanceGroup = useSelector(selectCurrentEmAttendanceGroup)(employeeId, emAttendanceGroupId)

  useEffect(() => {
    dispatch(fetchKeyvalues('attendanceGroup', 'attendance', undefined, { strategy: 'when-empty' }))
  }, [])

  return (
    <Descriptions bordered column={1} className="copy-em__content">
      <Descriptions.Item label="Attendance group">
        {attendanceGroups && attendanceGroups[emAttendanceGroup?.attendanceGroupId || '']?.value}
      </Descriptions.Item>
    </Descriptions>
  )
}
