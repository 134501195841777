import React, { FC, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { CheckCircleFilled, LoadingOutlined } from '@ant-design/icons'
import { getBaseUrl } from '~/utils'
import { Button, Card, ColumnsType, Link, Space, Table, Tooltip } from '~/core-components'
import { NewTabLink, Person } from '~/components'
import { usePermissionGate } from '~/features/iam/hooks'
import { PayRunStatus, Permission, PermissionAction } from '~/constants'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { PayWarningState } from '../../types'
import { selectPayWarnings, selectPayWarningsCount } from '../../selectors'
import { processPayWarnings, unverifyPayWarning, verifyPayWarning } from '../../actions'
import './PayWarning.less'

export interface PayWarningProps {
  payRunId: string
}

const tableScroll = { y: 1000 }
const baseUrl = getBaseUrl('/filestore')

export const PayWarning: FC<PayWarningProps> = ({ payRunId }) => {
  const payWarnings = useSelector(selectPayWarnings)(payRunId)
  const warningCount = useSelector(selectPayWarningsCount)(payRunId)
  const loading = useSelector((state: StoreState) => state.payroll.payWarningLoading[payRunId])
  const payRun = useSelector((state: StoreState) => state.payroll.payRuns?.entities[payRunId])
  const [verifying, setVerifying] = useState<string>()
  const canModify = usePermissionGate(Permission.payRun, PermissionAction.Modify)
  const canViewPayMaster = usePermissionGate(Permission.payMaster)

  const handleVerifyClick = useCallback(
    async payWarning => {
      if (payWarning) {
        setVerifying(payWarning.id)
        try {
          if (payWarning.verifiedBy) {
            await dispatch(unverifyPayWarning(payRunId, payWarning.id))
          } else {
            await dispatch(verifyPayWarning(payRunId, payWarning.id))
          }
        } finally {
          setVerifying(undefined)
        }
      }
    },
    [payRunId]
  )

  const handleRefreshClick = useCallback(() => {
    dispatch(processPayWarnings(payRunId))
  }, [payRunId])

  const columns: ColumnsType<PayWarningState> = [
    {
      title: 'Employee',
      key: 'employeeName',
      dataIndex: 'employeeName',
      width: 200,
      sorter: (a, b) => a.employeeName.localeCompare(b.employeeName),
      render: (value: string, record) => (
        <Person
          name={record.employeeName}
          description={record.employeeDescription}
          size={24}
          photo={record.photoId && `${baseUrl}/file/${record.photoId}/thumbnailphoto/24`}
        />
      )
    },
    {
      title: 'Notes',
      key: 'message',
      dataIndex: 'message',
      sorter: (a, b) => a.message.localeCompare(b.message),
      render: (value: string, record: PayWarningState) => {
        return (
          <div className="warning-msg">
            <Tooltip title={!!record.verifiedBy ? 'Verified' : 'Unverified'}>
              <Link
                className={classNames('warning-msg__verify', { 'warning-msg__verify--verified': !!record.verifiedBy })}
                onClick={() => payRun?.status !== PayRunStatus.completed && canModify && handleVerifyClick(record)}
              >
                {verifying === record.id ? <LoadingOutlined /> : <CheckCircleFilled />}
              </Link>
            </Tooltip>
            <span>{value}</span>
          </div>
        )
      }
    }
  ]

  return (
    <div className="paywarnings">
      <Card
        fitParent
        table
        title={`${warningCount} warnings found`}
        extra={
          <Space>
            <Button
              icon={<i className="fal fa-refresh" />}
              hidden={payRun?.status === PayRunStatus.completed}
              onClick={handleRefreshClick}
              loading={loading}
              size="small"
            />
            {canViewPayMaster && payRun?.payGroupId && (
              <NewTabLink path={SETTINGS_ROUTES.payGroup.replace(':id', payRun.payGroupId).replace(':tab?', 'rules')}>
                setting
              </NewTabLink>
            )}
          </Space>
        }
      >
        <Table
          rowKey="id"
          dataSource={payWarnings}
          columns={columns}
          loading={loading}
          fitParent
          pagination={false}
          scroll={tableScroll}
          className="paywarnings__table"
          rowClassName={(record: PayWarningState) => {
            return !!record.verifiedBy ? 'paywarnings__table-row--verified' : ''
          }}
        />
      </Card>
    </div>
  )
}
