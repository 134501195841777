import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { Ir21A1FormState } from '../types'

export const selectIr21A1Form = createSelector(
  (state: StoreState) => state.tax.ir21A1Form,
  ir21A1FormState =>
    memoize(
      (ir21SubmissionId: string, employeeTaxNo: string): Ir21A1FormState | undefined => {
        const form = ir21A1FormState?.entities[employeeTaxNo]
        if (form?.ir21SubmissionId === ir21SubmissionId) return form
      },
      (ir21SubmissionId: string, employeeTaxNo: string) => `${ir21SubmissionId}|${employeeTaxNo}`
    )
)
