import { apiAddIr8aDraft } from '../api/ytd.api'
import { ThunkResult } from '~/types/store'
import { showSuccess, showError } from '~/utils'
import { IYtdAddIr8aDraft } from '../types'

export const addIr8aDraft = (request: IYtdAddIr8aDraft): ThunkResult<void> => {
  return async () => {
    const { status, errors, message, errorData } = await apiAddIr8aDraft(request)
    if (status) {
      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
