import { setKeyvalue } from '~/features/master'
import { showSuccess, showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { Delimiter, emptyGuid } from '~/constants'
import { apiSaveLocation } from '../api/location.api'
import { refetchLocationsView, setLocation } from '../reducers'
import { LocationState, ISaveLocation } from '../types'

export const saveLocation = (location: ISaveLocation): ThunkResult<void> => {
  return async (dispatch): Promise<ActionResult> => {
    const isNew = location.id === emptyGuid
    const { status, result, errors, message, errorData } = await apiSaveLocation(location)
    if (status) {
      const newLocation: LocationState = {
        ...location,
        id: result.id,
        tags: location.tags?.split(Delimiter.comma) || [],
        wifiSsid: location.wifiSsid?.split(Delimiter.comma) || []
      }
      dispatch(setLocation(newLocation))
      dispatch(refetchLocationsView())
      dispatch(
        setKeyvalue({
          kv: 'location',
          data: { key: result.id, value: newLocation.name, inactiveDate: newLocation.inactiveDate }
        })
      )
      showSuccess(isNew ? 'Added' : 'Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
