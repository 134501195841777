import { showError, showSuccess } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiSaveBreak } from '../api/break.api'
import { ISaveBreak, BreakState } from '../types'
import { setBreak } from '../reducers'
import { fetchBreakTimes } from './fetch-break-times'
import pick from 'lodash/pick'
import { setKeyvalue } from '~/features/master'

export const saveBreak = (request: ISaveBreak): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const { status, result, errors, message, errorData } = await apiSaveBreak(request)
    if (status) {
      const breakId = result?.id || request.id
      if (breakId) {
        dispatch(fetchBreakTimes(breakId))
      }
      const _break = getState().attendance.breaks?.entities[breakId || '']
      if (_break) {
        const updated: BreakState = {
          ...(_break as BreakState),
          ...request
        }

        dispatch(setBreak(updated))
        dispatch(
          setKeyvalue({
            kv: 'break',
            data: { key: updated.id, value: updated.name, inactiveDate: updated.inactiveDate }
          })
        )
      } else {
        const newBreak: BreakState = {
          ...(pick(request, 'name', 'inactiveDate') as BreakState),
          ...result
        }

        dispatch(setBreak(newBreak))
        dispatch(
          setKeyvalue({
            kv: 'break',
            data: { key: newBreak.id, value: newBreak.name, inactiveDate: newBreak.inactiveDate }
          })
        )
      }

      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
