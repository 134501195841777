import React, { forwardRef, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { Button, Form, Select, SelectProps, Space, Tooltip } from '~/core-components'
import { DropdownActions } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { Permission, PermissionAction } from '~/constants'
import { StoreState } from '~/types/store'
import { dispatch } from '~/stores/store'
import { fetchDailyPolicies } from '../../../actions'
import { selectDailyPolicies } from '../../../selectors'
import { DailyPolicyState } from '../../../types'
import { DailyPolicySelectAction, MutateDailyPolicyDrawer } from './MutateDailyPolicyDrawer'
import './SelectDailyPolicy.less'

interface SelectDailyPolicyProps extends SelectProps {
  value: string
  onChange: (value: string) => void
}

interface DrawerState {
  visible: boolean
  data?: DailyPolicyState
}
const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

const SelectDailyPolicyInternal = (
  { className, value, onChange, ...props }: SelectDailyPolicyProps,
  ref: React.Ref<any>
) => {
  const classes = classNames('select-daily-policy', { [`${className}`]: className })
  const dailyPolicies = useSelector(selectDailyPolicies)
  const loading = useSelector((state: StoreState) => state.attendance.dailyPoliciesLoading)
  const dailyPolicy = useSelector((state: StoreState) => state.attendance.dailyPolicies?.entities[value])

  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const canModify = usePermissionGate(Permission.dailyPolicies, PermissionAction.Modify)

  const handleCloseDrawer = useCallback(
    (action: DailyPolicySelectAction, dailyPolicyId?: string) => {
      if (action === 'add') {
        dailyPolicyId && typeof onChange === 'function' && onChange(dailyPolicyId)
      } else if (action === 'delete') {
        const filteredPolicies = dailyPolicies.filter(p => p.id !== dailyPolicyId)
        const firstDailyPolicyId = filteredPolicies.length > 0 && filteredPolicies[0].id
        firstDailyPolicyId && typeof onChange === 'function' && onChange(firstDailyPolicyId)
      }

      setDrawerState(DEFAULT_DRAWER_STATE)
    },
    [dailyPolicies, onChange]
  )

  const handleEditPolicy = useCallback(() => {
    setDrawerState({ visible: true, data: dailyPolicy })
  }, [dailyPolicy])

  const handleAddPolicy = useCallback(() => {
    setDrawerState({ visible: true, data: undefined })
  }, [])

  const handleRefresh = useCallback(() => {
    dispatch(fetchDailyPolicies())
  }, [])

  return (
    <div className={classes}>
      <Form layout="horizontal" colon={false}>
        <Form.Item label="Daily policy">
          <Space>
            <Select
              ref={ref}
              showSearch
              optionFilterProp="title"
              allowClear={false}
              className="select-daily-policy__select"
              loading={loading}
              value={value}
              onChange={onChange}
              dropdownRender={menu => (
                <div>
                  {menu}
                  <DropdownActions onRefresh={handleRefresh} />
                </div>
              )}
              {...props}
            >
              {dailyPolicies.map(e => (
                <Select.Option key={e.id} value={e.id} title={e.name}>
                  {e.name}
                </Select.Option>
              ))}
            </Select>
            {canModify && (
              <>
                <Button
                  className="select-daily-policy__edit"
                  onClick={handleEditPolicy}
                  icon={
                    <Tooltip title="Edit policy">
                      <i className="fal fa-pencil" />
                    </Tooltip>
                  }
                />
                <Button
                  className="select-daily-policy__add"
                  onClick={handleAddPolicy}
                  icon={
                    <Tooltip title="Add policy">
                      <i className="fal fa-add" />
                    </Tooltip>
                  }
                />
                <MutateDailyPolicyDrawer {...drawerState} onClose={handleCloseDrawer} />
              </>
            )}
          </Space>
        </Form.Item>
      </Form>
    </div>
  )
}

export const SelectDailyPolicy = forwardRef(SelectDailyPolicyInternal)
