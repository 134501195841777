import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Button, Card, ColumnsType, Form, Link, SecondaryText, Space, Table, Tooltip } from '~/core-components'
import { AmountDisplay, Person, SearchInput, YearKeyValues } from '~/components'
import {
  Screen,
  ViewCriteriaSimple,
  ViewCriteria,
  updateViewCriteria,
  useFirstView,
  useViewSchema,
  ViewSelectionButton
} from '~/features/selection'
import { usePermissionGate } from '~/features/iam'
import { StoreState } from '~/types/store'
import { dispatch } from '~/stores/store'
import { ClaRecordStatus, ClaCurrencyCode, Permission, PermissionAction, ClaExpenseSubmissionType } from '~/constants'
import { useIsMountedRef } from '~/hooks/use-is-mounted-ref'
import { EMP_ROUTES } from '~/routes/routes'
import {
  formatYearMonth,
  formatMonth,
  formatMoney,
  getFileTimestamp,
  showError,
  downloadWithDom,
  getBaseUrl
} from '~/utils'
import { selectClaimRecordsView } from '../../selectors'
import { apiGetClaimRecordExcel } from '../../api/claim-record.api'
import { useCfConfigs, useClaimTypesDict } from '../../hooks'
import { refetchClaimRecordsView } from '../../reducers'
import { fetchClaimRecordsView } from '../../actions'
import { ClaimRecordRowState } from '../../types'
import { ClaimTypeName } from '../ClaimEntitlements/components/ClaimTypeName'
import { MutateClaimRecordDrawer } from './components/MutateClaimRecordDrawer'
import { AddClaimRecordButton } from './components/AddClaimRecordButton'
import { ClaimStatusTag } from '../ClaimStatusTag/ClaimStatusTag'
import './ClaimRecords.less'

interface ClaimRecordsProps {}

interface DrawerState {
  visible: boolean
  employeeId?: string
  id?: string
  editing?: boolean
  isMultiple?: boolean
}

const nameColWidth = 250
const defaultColWidth = 150
const SCREEN_CODE: Screen = 'claim_record'
const paginationStyle: CSSProperties = { marginRight: 20 }
const tooltipOverlayStyle: CSSProperties = { whiteSpace: 'pre-line', maxWidth: 400 }
const approverIconStyle: CSSProperties = { fontSize: 12 }

const TODAY_YEAR = moment().year()
const PAGE_SIZE_OPTIONS = ['20', '50', '100']
const DEFAULT_DRAWER_STATE: DrawerState = { visible: false, editing: false }
const baseUrl = getBaseUrl('/filestore')

export const ClaimRecords: FC<ClaimRecordsProps> = () => {
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(20)
  const [search, setSearch] = useState<string>('')
  const [periodYear, setPeriodYear] = useState<number>(TODAY_YEAR)
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)

  const selection = useSelector((state: StoreState) => state.selection.sysSelectionFields[SCREEN_CODE])
  const [view, viewLoading] = useFirstView(SCREEN_CODE)
  const viewId = view?.id || ''
  const [schema] = useViewSchema(SCREEN_CODE, viewId)
  const dataLoading = useSelector((state: StoreState) => state.claim.claimRecordsViewLoading)
  const data = useSelector(selectClaimRecordsView)(viewId)
  const isMountedRef = useIsMountedRef()
  const [downloading, setDownloading] = useState(false)
  const canModify = usePermissionGate(Permission.claRecord, PermissionAction.Modify)
  const canViewEmployee = usePermissionGate(Permission.employee)
  const claimExpanded = useSelector((state: StoreState) => state.claim.claimExpanded)
  const refetch = useSelector((state: StoreState) => state.claim.claimRecordsViewRefetch)

  const [claimTypesDict] = useClaimTypesDict()
  const [cfConfigs] = useCfConfigs()

  useEffect(() => {
    if (viewId) {
      dispatch(fetchClaimRecordsView(viewId, periodYear, { offset: pageSize * (page - 1), limit: pageSize }, search))
    }
  }, [viewId, periodYear, page, pageSize, search, refetch])

  const handleOpenClaimRecord = useCallback(
    (claimRecord: ClaimRecordRowState) => {
      setDrawerState({
        visible: true,
        editing: false,
        id: claimRecord.id,
        employeeId: claimRecord.employeeId,
        isMultiple: claimRecord.id
          ? claimTypesDict[claimRecord.claimTypeId]?.expenseSubmissionType === ClaExpenseSubmissionType.Multiple
          : undefined
      })
    },
    [claimTypesDict]
  )

  const handleCloseDrawer = useCallback(
    (action: 'saved' | 'cancelled') => {
      if (action === 'saved' && viewId) {
        dispatch(refetchClaimRecordsView())
      }
      setDrawerState(DEFAULT_DRAWER_STATE)
    },
    [viewId]
  )

  const tableWidth =
    (schema?.selection.reduce(
      (sum, f) => (sum += selection?.entities[f.selectionFieldId]?.width || defaultColWidth),
      0
    ) || 0) + nameColWidth

  let columns: ColumnsType<ClaimRecordRowState> = [
    {
      title: 'Name',
      key: 'employeeId',
      dataIndex: 'employeeId',
      fixed: 'left',
      className: 'first-col',
      width: nameColWidth,
      onCell: (record, index) => {
        const prevId = data.data[(index as number) - 1]?.employeeId
        if (prevId === record.employeeId) {
          return { rowSpan: 0 }
        } else {
          const nextId = data.data[(index as number) + 1]?.employeeId
          if (nextId === record.employeeId) {
            return { rowSpan: record.rowSpan }
          }
        }
        return {}
      },
      render: (_, record) => (
        <Person
          name={record.employeeName}
          description={!claimExpanded && record.description}
          photo={record.photoId && `${baseUrl}/file/${record.photoId}/thumbnailphoto/${claimExpanded ? 18 : 36}`}
          // photo={
          //   record.employeeId &&
          //   `/employee/employee/${record.employeeId}/avatar/${claimExpanded ? 18 : 36}?photo_id=${record.photoId}`
          // }
          path={canViewEmployee ? EMP_ROUTES.employee.replace(':id', record.employeeId) : undefined}
          size={claimExpanded ? 18 : 36}
        />
      )
    }
  ]

  if (schema) {
    // Configurable employee columns
    columns.push(
      ...schema?.selection.map(f => {
        var field = selection?.entities[f.selectionFieldId]
        return {
          title: field?.description,
          key: field?.fieldName,
          dataIndex: field?.fieldName,
          width: field?.width || defaultColWidth,
          onCell: (record: ClaimRecordRowState, index?: number) => {
            const prevId = data.data[(index as number) - 1]?.employeeId
            if (prevId === record.employeeId) {
              return { rowSpan: 0 }
            } else {
              const nextId = data.data[(index as number) + 1]?.employeeId
              if (nextId === record.employeeId) {
                return { rowSpan: record.rowSpan }
              }
            }
            return {}
          },
          render: (value: string | number) => {
            var display = value
            if (field?.format === 'date' && value) {
              display = moment(value).format('DD MMM YYYY')
            }
            if (field?.format === 'yearmonth' && value) {
              display = formatYearMonth(value as string)
            }
            if (field?.format === 'month' && value) {
              display = formatMonth(value as number)
            }
            if (field?.format === 'money' && value) {
              display = formatMoney(value as number)
            }
            return display
          }
        }
      })
    )
  }

  // Fixed claim record columns
  columns.push(
    {
      title: 'Claim',
      key: 'claimTypeId',
      dataIndex: 'claimTypeId',
      width: claimExpanded ? 340 : 230,
      render: (value: string, record: ClaimRecordRowState) => {
        if (claimExpanded) {
          return (
            <Space size="large">
              <Link onClick={() => handleOpenClaimRecord(record)}>{`#${record.claimNo}`}</Link>
              <ClaimTypeName id={value} hideNewTab />
            </Space>
          )
        }

        return (
          <ClaimTypeName id={value} path={() => handleOpenClaimRecord(record)} description={`#${record.claimNo}`} />
        )
      }
    },
    {
      title: 'Provider name',
      key: 'providerName',
      dataIndex: 'providerName',
      width: 200,
      render: (value: string, record) => {
        const RenderReceipt = (
          <Space size="large">
            {record.receiptNo && (
              <Space>
                <i className="fal fa-receipt" />
                {record.receiptNo}
              </Space>
            )}
          </Space>
        )
        if (claimExpanded) {
          return (
            <Space size="large">
              {value}
              <SecondaryText size="small">{RenderReceipt}</SecondaryText>
            </Space>
          )
        }

        return (
          <>
            <div>{value || '-'}</div>
            {record.receiptNo && (
              <SecondaryText block size="small">
                <Space size="large">{RenderReceipt}</Space>
              </SecondaryText>
            )}
          </>
        )
      }
    },
    {
      title: <i className="fal fa-paperclip-vertical att-icon-header" />,
      key: 'attachmentCount',
      dataIndex: 'attachmentCount',
      width: 50,
      render: (value: number) => {
        return (
          value > 0 && (
            <>
              <i className="fal fa-paperclip-vertical" />
              <span className="att-count">{value}</span>
            </>
          )
        )
      }
    },
    {
      title: 'Expense date',
      key: 'expenseDate',
      dataIndex: 'expenseDate',
      width: 200,
      render: (value: string, record) => (
        <>
          <div>{value ? moment(value).format('DD MMM YYYY') : ''}</div>
          {!claimExpanded && record.recordStatus !== ClaRecordStatus.Draft && (
            <SecondaryText size="small">
              Submitted on {record.submittedDate ? moment(record.submittedDate).format('DD MMM YYYY') : ''}
            </SecondaryText>
          )}
        </>
      )
    },
    {
      title: 'Amount',
      key: 'claimAmount',
      dataIndex: 'claimAmount',
      width: 150,
      render: (value: number, record) => (
        <>
          <AmountDisplay
            block
            symbol={claimTypesDict[record.claimTypeId]?.currencyCode || ClaCurrencyCode.sgd}
            value={value}
          />
          {value !== record.expenseAmount &&
            !claimExpanded &&
            claimTypesDict[record.claimTypeId]?.expenseSubmissionType === ClaExpenseSubmissionType.Single && (
              <Tooltip title="Expense amount">
                <SecondaryText block size="small">
                  <AmountDisplay
                    size="small"
                    symbol={record.expenseCurrencyCode || ClaCurrencyCode.sgd}
                    value={record.expenseAmount}
                  />
                </SecondaryText>
              </Tooltip>
            )}
        </>
      )
    },
    {
      title: 'Status',
      key: 'recordStatus',
      dataIndex: 'recordStatus',
      width: 180,
      render: (value: string, record: ClaimRecordRowState) => (
        <Space>
          <ClaimStatusTag status={value} recordType={record.recordType} />
          {[ClaRecordStatus.Pending, ClaRecordStatus.PendingCancel].some(s => value === s) && (
            <Tooltip
              title={`Current approver: \n ${record.currentApprovers?.join('\n')}`}
              overlayStyle={tooltipOverlayStyle}
            >
              <i className="fal fa-user" style={approverIconStyle}></i>
            </Tooltip>
          )}
        </Space>
      )
    },
    {
      title: 'Notes',
      key: 'notes',
      dataIndex: 'notes',
      width: 300
    }
  )

  if (cfConfigs) {
    columns.push(
      ...cfConfigs
        .filter(cf => cf.isShown)
        .map(cf => {
          return {
            title: cf.name,
            key: cf.code,
            dataIndex: cf.code,
            width: defaultColWidth
          }
        })
    )
  }

  const handleCloseSelectionDrawer = useCallback((changed?: boolean) => {
    if (changed) dispatch(refetchClaimRecordsView())
  }, [])

  const handleCriteriaApply = useCallback(
    async (criteria: ViewCriteria[]) => {
      if (viewId) {
        setPage(1)
        await dispatch(updateViewCriteria(SCREEN_CODE, viewId, { id: viewId, criteria }))
        dispatch(refetchClaimRecordsView())
      }
    },
    [viewId]
  )

  const handlePaginationChange = useCallback((page: number, pageSize?: number) => {
    setPage(page)
    setPageSize(pageSize || 20)
  }, [])

  const handleSearch = useCallback((value: string) => {
    setPage(1)
    setSearch(value)
  }, [])

  const handlePeriodChange = useCallback(periodYear => {
    setPage(1)
    setPeriodYear(periodYear)
  }, [])

  const handleDownloadClick = useCallback(async () => {
    if (viewId) {
      try {
        setDownloading(true)
        const employeeId: string | null = null
        const { status, result, errors, message, errorData } = await apiGetClaimRecordExcel(viewId, periodYear, {
          search,
          employeeId
        })

        if (status) {
          const fileName = `claim_records_${getFileTimestamp()}.xlsx`
          downloadWithDom(result, fileName)
        } else {
          console.error('Error while downloading', errors)
          showError(message, errorData)
        }
      } finally {
        if (isMountedRef.current) setDownloading(false)
      }
    }
  }, [viewId, periodYear, search, isMountedRef])

  return (
    <div className="claim-records">
      <div className="claim-records__body">
        <div className="claim-records__action-bar">
          <Space>
            <Form.Item label="">
              <YearKeyValues
                noOfYearBefore={-5}
                noOfYearAfter={0}
                value={periodYear}
                onChange={handlePeriodChange}
                allowClear={false}
              />
            </Form.Item>
            <Form.Item label="">
              <SearchInput onSearch={handleSearch} />
            </Form.Item>
          </Space>
          <ViewCriteriaSimple screenCode={SCREEN_CODE} viewId={viewId} onApply={handleCriteriaApply} label="" />
          <Space align="start">
            {canModify && <AddClaimRecordButton />}
            <Tooltip title="Download excel">
              <Button
                icon={<i className="fal fa-arrow-down-to-bracket" />}
                onClick={handleDownloadClick}
                loading={downloading}
              />
            </Tooltip>
            <ViewSelectionButton
              screenCode={SCREEN_CODE}
              viewId={viewId}
              title="Configure claim record columns"
              onClose={handleCloseSelectionDrawer}
            />
          </Space>
        </div>
        <Card fitParent table>
          <Table
            rowKey="id"
            dataSource={data?.data}
            columns={columns}
            fitParent
            loading={dataLoading || viewLoading}
            scroll={{ x: tableWidth, y: 1000 }}
            pagination={{
              total: data?.count,
              current: page,
              pageSize,
              pageSizeOptions: PAGE_SIZE_OPTIONS,
              showSizeChanger: true,
              onChange: handlePaginationChange,
              style: paginationStyle
            }}
          />
        </Card>
      </div>
      <MutateClaimRecordDrawer {...drawerState} onClose={handleCloseDrawer} />
    </div>
  )
}
