import React, { FC, memo } from 'react'
import { useSelector } from 'react-redux'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { Space, Tag, TagType, Tooltip } from '~/core-components'
import { StoreState } from '~/types/store'
import { PayItemType, WageType } from '~/constants'
import { useSysOptions } from '~/features/employee'

export interface PayItemNameProps {
  id: string
}

const AdditionSign = memo(() => (
  <Tooltip title="Allowance">
    <PlusOutlined style={{ color: 'green' }} />
  </Tooltip>
))
const DeductionSign = memo(() => (
  <Tooltip title="Deduction">
    <MinusOutlined style={{ color: 'red' }} />
  </Tooltip>
))

export const PayItemName: FC<PayItemNameProps> = ({ id, children }) => {
  const payItems = useSelector((state: StoreState) => state.master.payItems.entities || {})
  const [wageTypes] = useSysOptions('wage_type')
  const wageType = payItems[id]?.wageType || ''
  const wageTypeName = wageTypes[wageType]?.value

  let tagType: TagType = 'neutral',
    wageLabel = 'N'
  switch (wageType) {
    case WageType.ordinary:
      tagType = 'primary'
      wageLabel = 'O'
      break
    case WageType.additional:
      tagType = 'tertiary'
      wageLabel = 'A'
      break
    case WageType.nonCpf:
      tagType = 'neutral'
      wageLabel = 'N'
      break
  }

  return (
    <Space align="start">
      {payItems[id]?.type === PayItemType.addition ? <AdditionSign /> : <DeductionSign />}
      <Tooltip title={wageTypeName}>
        <Tag type={tagType}>{wageLabel}</Tag>
      </Tooltip>
      <div>{payItems[id]?.name || id}</div>
      {children}
    </Space>
  )
}
