import { ActionResult, ThunkResult } from '~/types/store'
import { emptyGuid } from '~/constants'
import { showSuccess, showError } from '~/utils'
import { apiAddEmIdentity } from '../api/em-identity.api'
import { setEmIdentity, setEmployee } from '../reducers'
import { IEmIdentityInfo, EmIdentityState } from '../types'

export const addEmIdentity = (employeeId: string, emIdentity: IEmIdentityInfo): ThunkResult<void> => {
  return async (dispatch, getState): Promise<ActionResult | undefined> => {
    if (!employeeId) return

    const { status, result, errors, message, errorData } = await apiAddEmIdentity(employeeId, {
      id: emptyGuid,
      employeeId,
      ...emIdentity
    })
    if (status) {
      if (result.emIdentityId) {
        const employee = getState().employee.employees.entities[employeeId]
        if (employee) {
          dispatch(setEmployee({ ...employee, emIdentityId: result.emIdentityId }))
        }
      }

      const newEmIdentity = { id: result.id, employeeId, ...emIdentity } as EmIdentityState
      dispatch(setEmIdentity({ employeeId, data: newEmIdentity }))

      showSuccess('Added')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
