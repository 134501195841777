import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { LeaveBuddyEmState } from '../types'

export const selectLeaveBuddyEm = createSelector(
  (state: StoreState) => state.leave.leaveBuddyEm,
  leaveBuddyEmState =>
    memoize((leaveBuddyId: string) => {
      return (Object.values(leaveBuddyEmState[leaveBuddyId]?.entities || {}) as LeaveBuddyEmState[]) || []
    })
)
