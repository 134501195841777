import React, { FC } from 'react'
import moment from 'moment-timezone'
import { Form, Input } from '~/core-components'
import { Col, ErrorDisplay, Row } from '~/components'
import { Errors } from '~/types/store'
import { ImportLeaveFormData } from '../ImportLeaveDrawer'
import './ImportLeaveInput.less'

interface ImportLeaveInputProps {
  visible: boolean
  data: Partial<ImportLeaveFormData>
  errors?: Errors
  onChange: (data: Partial<ImportLeaveFormData>) => void
}

export const ImportLeaveInput: FC<ImportLeaveInputProps> = ({ visible, data, errors, onChange }) => {
  return (
    <div className="import-leave-input" hidden={!visible}>
      <Row>
        <Col span={24}>
          <Form.Item label="Import leave taken up to">
            <Input.Date
              value={data.upToDate ? moment(data.upToDate) : undefined}
              onChange={(value: moment.Moment | null) => {
                onChange({ upToDate: value?.format('YYYY-MM-DD') })
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <ErrorDisplay errors={errors} />
    </div>
  )
}
