import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { fetchClaimTypes } from '~/features/claim'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchMyClaimTasks } from '../actions'
import { selectMyClaimTasks } from '../selectors'
import { ETaskInfo } from '~/features/my/types'

export const useMyClaimTasks = (): [ETaskInfo[], boolean] => {
  const tasks = useSelector(selectMyClaimTasks)
  const refetch = useSelector((state: StoreState) => state.myClaim.myClaimTasksRefetch)
  const loading = useSelector((state: StoreState) => state.myClaim.myClaimTasksLoading)

  useEffect(() => {
    dispatch(fetchClaimTypes({ strategy: 'when-empty' }))
  }, [])

  useEffect(() => {
    dispatch(fetchMyClaimTasks())
  }, [refetch])

  return [tasks, loading]
}
