import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Drawer, Space, Steps, UploadFile } from '~/core-components'
import { showError } from '~/utils'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors, StoreState } from '~/types/store'
import {
  PayImportState,
  PayImportValidateState,
  PayImportSourceState,
  PayImportExecuteState,
  IPayItemMap
} from '../../../types'
import { processPayroll, uploadPayImport, validatePayImport, executePayImport } from '../../../actions'
import { PayItemMapping } from './components/PayItemMapping'
import { UploadPayExcel } from './components/UploadPayExcel'
import { ValidationReport } from './components/ValidationReport'
import { ImportPayTran } from './components/ImportPayTran'
import './ImportPayrollExcelDrawer.less'

interface ImportPayExcelDrawerProps {
  payRunId: string
  visible: boolean
  onClose: () => void
}

export interface ImportPayExcelFormData {
  byDates: boolean
  file?: UploadFile
  source: PayImportSourceState[]
  payItemMap: IPayItemMap[]
}

const EMPTY_FORM_DATA: ImportPayExcelFormData = { byDates: false, source: [], payItemMap: [] }

const { Step } = Steps

type StepKey = 'upload' | 'map' | 'validate' | 'verify' | 'complete'

interface IStep {
  key: StepKey
  title: string
  nextButton?: string
  backButton?: string
}

const STEPS: IStep[] = [
  {
    key: 'upload',
    title: 'Upload file'
  },
  {
    key: 'map',
    title: 'Mapping'
  },
  {
    key: 'validate',
    title: 'Validate'
  },
  {
    key: 'verify',
    title: 'Verify and import',
    nextButton: 'Import'
  },
  {
    key: 'complete',
    title: 'Done',
    nextButton: 'Close'
  }
]

export const ImportPayExcelDrawer: FC<ImportPayExcelDrawerProps> = ({ payRunId, visible, onClose }) => {
  const [formData, setFormData] = useState<ImportPayExcelFormData>(EMPTY_FORM_DATA)
  const [step, setStep] = useState(0)
  const [nextLoading, setNextLoading] = useState(false)
  const [errors, setErrors] = useState<Errors>()
  const hasDataToImport = useSelector(
    (state: StoreState) => (state.payroll.payImportValidate?.summary?.proceed || 0) > 0
  )

  useEffect(() => {
    setStep(0)
    setErrors(undefined)
    setFormData(EMPTY_FORM_DATA)
  }, [visible])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setErrors(undefined)
    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  const uploadFile = useCallback(async () => {
    const errorMessages: string[] = []
    if (!formData.file) {
      errorMessages.push('Choose the file to import')
    }

    if (errorMessages.length > 0) {
      setErrors({ '*': errorMessages })
      return
    }

    if (formData.file) {
      let result: ActionResult<PayImportState> | undefined
      try {
        setNextLoading(true)
        result = await dispatch(uploadPayImport(formData.byDates, formData.file))

        const source = result?.result?.source || []
        const payItemMapping = result?.result?.payItemMapping || []
        const byDates = source.some(s => !!s.date)

        handleFormDataChange({
          source,
          byDates,
          payItemMap: payItemMapping.map(pi => ({
            source: pi.payItemName,
            payItemId:
              pi.possibleTarget.length > 0 &&
              (pi.possibleTarget[0].value.toLowerCase().indexOf(pi.payItemName.toLowerCase()) >= 0 ||
                pi.payItemName.toLowerCase().indexOf(pi.possibleTarget[0].value.toLowerCase()) >= 0)
                ? pi.possibleTarget[0].key
                : ''
          }))
        })
      } finally {
        setNextLoading(false)
      }

      if (result?.errors) {
        if (Object.keys(result.errors).length === 0) {
          setErrors({ '*': ['Something went wrong. You might have modified the file content. Please choose again'] })
        } else {
          setErrors(result.errors)
        }
      }

      if (!result?.errors) {
        setErrors(undefined)
        setStep(step => step + 1)
      }
    }
  }, [formData.byDates, formData.file, handleFormDataChange])

  const validateSource = useCallback(async () => {
    const errorMessages: string[] = []
    if (formData.source.length === 0) {
      errorMessages.push('Data source is required')
    }
    if (formData.payItemMap.some(pi => !pi.payItemId)) {
      errorMessages.push('All the pay items must be mapped')
    }

    if (errorMessages.length > 0) {
      setErrors({ '*': errorMessages })
      return
    }

    let result: ActionResult<PayImportValidateState> | undefined
    try {
      setNextLoading(true)
      result = await dispatch(validatePayImport(payRunId, formData.source, formData.payItemMap))
    } finally {
      setNextLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      setErrors(undefined)
      setStep(step => step + 1)
    }
  }, [payRunId, formData.source, formData.payItemMap])

  const executeImport = useCallback(async () => {
    let result: ActionResult<PayImportExecuteState> | undefined
    try {
      setNextLoading(true)
      result = await dispatch(executePayImport(payRunId))
    } finally {
      setNextLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      setErrors(undefined)
      setStep(step => step + 1)

      const employeeIds = result?.result?.employeeIdList
      if (employeeIds) {
        dispatch(processPayroll(payRunId, employeeIds, 'all'))
      }
    }
  }, [payRunId])

  const handlePrev = useCallback(() => {
    setErrors(undefined)
    setStep(step => step - 1)
  }, [])

  const handleCloseDrawer = useCallback(() => {
    typeof onClose === 'function' && onClose()
  }, [onClose])

  const handleNext = useCallback(async () => {
    if (STEPS[step].key === 'upload') {
      await uploadFile()
    } else if (STEPS[step].key === 'map') {
      await validateSource()
    } else if (STEPS[step].key === 'validate') {
      if (hasDataToImport) {
        setStep(step => step + 1)
      } else {
        showError('No row to import')
      }
    } else if (STEPS[step].key === 'verify') {
      await executeImport()
    } else if (STEPS[step].key === 'complete') {
      handleCloseDrawer()
    }
  }, [step, uploadFile, validateSource, hasDataToImport, executeImport, handleCloseDrawer])

  return (
    <Drawer
      open={visible}
      title="Upload payroll items from Excel"
      onClose={handleCloseDrawer}
      width="80%"
      className="import-pay-excel-drawer"
      footer={
        <Space className="import-pay-excel-drawer__action-bar-buttons">
          {STEPS[step - 1] && step !== STEPS.length - 1 && (
            <Button onClick={handlePrev}>{STEPS[step].backButton || 'Back'}</Button>
          )}
          {STEPS[step] && (
            <Button type="primary" onClick={handleNext} loading={nextLoading}>
              {STEPS[step].nextButton || 'Next'}
            </Button>
          )}
        </Space>
      }
    >
      <Steps progressDot size="small" current={step}>
        {STEPS.map(s => (
          <Step key={s.key} title={s.title} />
        ))}
      </Steps>
      <UploadPayExcel
        visible={STEPS[step].key === 'upload'}
        data={formData}
        onChange={handleFormDataChange}
        errors={errors}
      />
      <PayItemMapping
        visible={STEPS[step].key === 'map'}
        data={formData}
        onChange={handleFormDataChange}
        errors={errors}
      />
      <ValidationReport visible={STEPS[step].key === 'validate'} />
      <ImportPayTran
        visible={['verify', 'complete'].includes(STEPS[step].key)}
        success={STEPS[step].key === 'complete'}
        byDates={formData.byDates}
      />
    </Drawer>
  )
}
