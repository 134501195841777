import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment-timezone'
import { useSelector } from 'react-redux'
import {
  Col,
  DocumentTitle,
  EmpKeyValues,
  PdfPasswordModal,
  PdfViewer,
  PeriodKeyValues,
  Row,
  SysOptions
} from '~/components'
import {
  Button,
  Checkbox,
  CheckboxChangeEvent,
  Collapse,
  Form,
  Input,
  PageHeader,
  Select,
  Space
} from '~/core-components'
import { fetchPayRuns, selectPayRuns } from '~/features/payroll'
import { GroupingButton, RptScreen } from '~/features/grouping'
import { emptyGuid } from '~/constants'
import { REPORTS_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { Errors, StoreState } from '~/types/store'
import { downloadWithDom, getFileTimestamp, showError } from '~/utils'
import { IRptPayment, ReportCode } from '../../types'
import { useFirstReportTemplate, useReportTemplate } from '../../hooks'
import { refetchReportTemplates } from '../../reducers'
import { RptDownloadBtn } from '../components/RptDownloadBtn'
import { ReportTemplate } from '../ReportTemplate/ReportTemplate'
import { fetchMasters, selectPayGroups } from '~/features/master'
import { apiDownloadPaymentPdf } from '../../api/rpt-payment.api'
import { RptSortingBtn } from '../components/RptSortingBtn'
import './RptPayment.less'

const routes = [
  {
    path: REPORTS_ROUTES.main,
    breadcrumbName: 'Reports'
  },
  {
    path: '',
    breadcrumbName: 'Payment report'
  }
]

interface RptPaymentForm extends IRptPayment {
  templateId: string
}

const currentPeriod = moment().format('YYYY-MM')

const SCREEN_CODE: RptScreen = 'rpt_payroll'
const REPORT_CODE: ReportCode = 'rpt_payment'

const EMPTY_FORM_DATA: RptPaymentForm = {
  paymentMonth: currentPeriod,
  companyId: '',
  payRunIds: [],
  payMethods: [],
  sorting: 'employee_name',
  groupingCodes: [],
  pageBreaks: [],
  suppressEmployee: false,
  showZeroOrNegativeNetPay: false,
  reportTitle: '',
  notes: '',
  templateId: emptyGuid
}

export const RptPayment: FC = () => {
  const [formData, setFormData] = useState<RptPaymentForm>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const payRuns = useSelector(selectPayRuns)()
  const companyIds = useSelector((state: StoreState) => state.master.keyvalues['company']?.ids)
  const payGroups = useSelector(selectPayGroups)
  const [previewUrl, setPreviewUrl] = useState<string>('')
  const [previewing, setPreviewing] = useState(false)
  const [activeKey, setActiveKey] = useState(0)
  const [pdfModal, setPdfModal] = useState(false)
  const [firstTemplate] = useFirstReportTemplate('payroll-sg', REPORT_CODE)
  const firstTemplateId = firstTemplate?.id || ''
  const [templateId, setTemplateId] = useState<string>()
  const [template] = useReportTemplate('payroll-sg', REPORT_CODE, templateId)

  const payGroupIds = useMemo(() => {
    return payGroups.filter(pg => pg?.companyId === formData.companyId).map(pg => pg?.id)
  }, [payGroups, formData])

  const filteredPayRuns = useMemo(() => {
    return payRuns.filter(pr => pr.payPeriod === formData.paymentMonth && payGroupIds.includes(pr.payGroupId))
  }, [payRuns, payGroupIds, formData])

  useEffect(() => {
    dispatch(fetchMasters('payGroup', { strategy: 'when-empty' }))
    dispatch(fetchPayRuns({ strategy: 'when-empty' }))
  }, [])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    if (updates.companyId) {
      updates.payGroupIds = []
    }

    setFormData(data => ({ ...data, ...updates }))
  }, [])

  useEffect(() => {
    if (!templateId && firstTemplateId) {
      setTemplateId(firstTemplateId)
    }

    if (template) {
      const saved = JSON.parse(template.dataJsonb) as RptPaymentForm
      saved.templateId = templateId || ''
      setFormData(formData => ({ ...formData, ...saved }))
    }
  }, [firstTemplateId, templateId, template])

  useEffect(() => {
    setFormData(formData => {
      if (!formData.companyId) {
        if (companyIds && companyIds.length > 0) {
          return { ...formData, companyId: companyIds[0].toString(), payGroupIds: [] }
        }
      }
      return formData
    })
  }, [companyIds])

  const handlePreview = useCallback(async () => {
    setPreviewing(true)
    setErrors(undefined)

    try {
      const { status, result, errors, message, errorData } = await apiDownloadPaymentPdf(formData)

      if (status) {
        const url = URL.createObjectURL(result)
        setPreviewUrl(url)
        dispatch(refetchReportTemplates())
      } else {
        console.error('Error while downloading', errors)
        setErrors(errors)
        showError(message, errorData)
      }
    } finally {
      setPreviewing(false)
    }
  }, [formData])

  const handleDownloadPdf = useCallback(
    async (password?: string) => {
      setErrors(undefined)

      const { status, result, errors, message, errorData } = await apiDownloadPaymentPdf({ ...formData, password })

      if (status) {
        const fileName = `payment_report_${formData.paymentMonth}_${getFileTimestamp()}.pdf`
        downloadWithDom(result, fileName)
        dispatch(refetchReportTemplates())
      } else {
        console.error('Error while downloading', errors)
        setErrors(errors)
        showError(message, errorData)
      }
    },
    [formData]
  )

  const handleOpenPdfModal = useCallback(async () => {
    setPdfModal(true)
  }, [])

  const handleClosePdfModal = useCallback(() => {
    setPdfModal(false)
  }, [])

  const handleCollapse = useCallback(async () => {
    if (!activeKey) {
      setActiveKey(1)
    } else {
      setActiveKey(0)
    }
  }, [activeKey])

  const handleTemplateChange = useCallback((templateId?: string) => {
    setTemplateId(templateId)
  }, [])

  return (
    <div id="rpt-payment" className="rpt-payment">
      <DocumentTitle title="Payment Report" />
      <PageHeader
        title="Payment report"
        containerId="rpt-payment"
        breadcrumb={{ routes }}
        extra={
          <ReportTemplate<IRptPayment>
            basePath="payroll-sg"
            reportCode={REPORT_CODE}
            templateId={templateId}
            templateData={formData}
            size="small"
            onChange={handleTemplateChange}
          />
        }
      />
      <Form className="rpt-payment__form" layout="horizontal" labelCol={{ flex: '120px' }}>
        <Row gutter={30}>
          <Col flex="280px">
            <Form.Item
              label="Payment month"
              validateStatus={errors?.paymentMonth ? 'error' : ''}
              help={errors?.paymentMonth}
            >
              <PeriodKeyValues
                allowClear={false}
                value={formData.paymentMonth}
                startMonth={currentPeriod}
                noOfMonthBefore={-24}
                noOfMonthAfter={2}
                onChange={(value: string) => {
                  handleFormDataChange({ paymentMonth: value })
                }}
              />
            </Form.Item>
          </Col>
          <Col flex="350px">
            <Form.Item
              label="Company"
              labelCol={{ flex: '80px' }}
              validateStatus={errors?.companyId ? 'error' : ''}
              help={errors?.companyId}
            >
              <EmpKeyValues
                id="company"
                allowClear={false}
                value={formData.companyId}
                onChange={(companyId?: string) => handleFormDataChange({ companyId })}
              />
            </Form.Item>
          </Col>
          <Col flex="1"></Col>
          <Col flex="none">
            <Space>
              <RptSortingBtn
                sorting={formData.sorting}
                onSelect={(sorting: string) => handleFormDataChange({ sorting })}
              />
              <GroupingButton
                screenCode={SCREEN_CODE}
                groupingCodes={formData.groupingCodes || []}
                pageBreaks={formData.pageBreaks || []}
                onSelect={(groupingCodes: string[], pageBreaks: string[]) =>
                  handleFormDataChange({ groupingCodes, pageBreaks })
                }
              />
              <Button className="btn-more-settings" type={activeKey ? 'primary' : 'default'} onClick={handleCollapse}>
                <i className="fal fa-gear" />
              </Button>
              <Button type="primary" onClick={handlePreview} loading={previewing}>
                Preview
              </Button>
              <RptDownloadBtn onDownloadPdf={handleDownloadPdf} onDownloadSecurePdf={handleOpenPdfModal} />
            </Space>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col flex="1">
            <Form.Item label="Payroll runs" validateStatus={errors?.payRunIds ? 'error' : ''} help={errors?.payRunIds}>
              <Select
                mode="multiple"
                maxTagCount={1}
                value={formData.payRunIds || []}
                showSearch
                showArrow
                suffixIcon={<i className="fal fa-angle-down" />}
                placeholder="All payroll runs"
                filterOption={(input, option) =>
                  option?.display?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={(value: string[]) => handleFormDataChange({ payRunIds: value })}
              >
                {filteredPayRuns.map(pr => (
                  <Select.Option key={pr.id} value={pr.id} display={pr.description}>
                    {pr.description}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col flex="1">
            <Form.Item
              label="Payment method"
              validateStatus={errors?.payMethods ? 'error' : ''}
              help={errors?.payMethods}
            >
              <SysOptions
                type="pay_method"
                mode="multiple"
                placeholder="All payment methods"
                value={formData.payMethods || []}
                onChange={(payMethods: string[]) => handleFormDataChange({ payMethods })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col flex="1">
            <Collapse className="rpt-payment__more-settings" activeKey={activeKey} onChange={handleCollapse} noStyle>
              <Collapse.Panel key="1" header={null} showArrow={false}>
                <Row gutter={30}>
                  <Col flex="1">
                    <Form.Item label="Options">
                      <Checkbox
                        checked={formData.suppressEmployee}
                        onChange={(event: CheckboxChangeEvent) =>
                          handleFormDataChange({ suppressEmployee: event.target.checked })
                        }
                      >
                        Suppress employee
                      </Checkbox>
                      <Checkbox
                        checked={formData.showZeroOrNegativeNetPay}
                        onChange={(event: CheckboxChangeEvent) =>
                          handleFormDataChange({ showZeroOrNegativeNetPay: event.target.checked })
                        }
                      >
                        Include zero or negative net pay
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={30}>
                  <Col flex="490px">
                    <Form.Item label="Report title">
                      <Input
                        value={formData.reportTitle}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                          handleFormDataChange({ reportTitle: event.target.value })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={30}>
                  <Col flex="1">
                    <Form.Item label="Custom notes">
                      <Input.TextArea
                        rows={3}
                        value={formData.notes}
                        onChange={(value?: ChangeEvent<HTMLTextAreaElement>) =>
                          handleFormDataChange({ notes: value?.target.value })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Collapse.Panel>
            </Collapse>
          </Col>
        </Row>
      </Form>
      {previewUrl && (
        <div className="rpt-payment__pdf-viewer">
          <Row>
            <Col>
              <PdfViewer file={previewUrl} layout="landscape" scale={1.3} />
            </Col>
          </Row>
        </div>
      )}
      <PdfPasswordModal open={pdfModal} onApply={handleDownloadPdf} onClose={handleClosePdfModal} />
    </div>
  )
}
