import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import persistReducer from 'redux-persist/es/persistReducer'
import { KeyValue } from '../../types/common'
import { StoreState } from '../../types/store'
import {
  MasterRootState,
  masterPersistConfig,
  CompanyState,
  CompanyExtraState,
  OfficeState,
  OfficeExtraState,
  CompanyOfficeState,
  MasterState,
  CalendarPatternState,
  DepartmentExtraState,
  TeamExtraState,
  MasterName,
  BankState,
  PayItemState,
  PayItemBasisState,
  PayClaimMapState,
  PayDailyMapState,
  PayEncashMapState,
  PayLeaveMapState,
  HolidayDateState,
  PayslipItemConfigState,
  DivisionExtraState,
  SectionExtraState,
  EmploymentConfigState,
  SelfUpdateConfigState,
  SsAccessConfigState,
  DelegationState
} from './types'
import { EmailGroupState } from './types/email-group.state'

const companiesAdapter = createEntityAdapter<CompanyState>()
const companiesExtraAdapter = createEntityAdapter<CompanyExtraState>()
const officesAdapter = createEntityAdapter<OfficeState>()
const officesExtraAdapter = createEntityAdapter<OfficeExtraState>()
const companyOfficesAdapter = createEntityAdapter<CompanyOfficeState>()
const mastersAdapter = createEntityAdapter<MasterState>()
const departmentsExtraAdapter = createEntityAdapter<DepartmentExtraState>()
const divisionsExtraAdapter = createEntityAdapter<DivisionExtraState>()
const sectionsExtraAdapter = createEntityAdapter<SectionExtraState>()
const teamsExtraAdapter = createEntityAdapter<TeamExtraState>()
const calendarPatternsAdapter = createEntityAdapter<CalendarPatternState>()
const payItemsViewAdapter = createEntityAdapter<PayItemState>()
const payItemsAdapter = createEntityAdapter<PayItemState>()
const payItemBasesAdapter = createEntityAdapter<PayItemBasisState>()
const payClaimMapsAdapter = createEntityAdapter<PayClaimMapState>()
const payDailyMapsAdapter = createEntityAdapter<PayDailyMapState>()
const payEncashMapsAdapter = createEntityAdapter<PayEncashMapState>()
const payLeaveMapsAdapter = createEntityAdapter<PayLeaveMapState>()
const holidayDatesAdapter = createEntityAdapter<HolidayDateState>()
const banksAdapter = createEntityAdapter<BankState>({ selectId: model => model.code })
const keyvaluesAdapter = createEntityAdapter<KeyValue>({ selectId: model => model.key })
const emailGroupsAdapter = createEntityAdapter<EmailGroupState>()
const delegationsAdapter = createEntityAdapter<DelegationState>()
const payslipItemConfigsAdapter = createEntityAdapter<PayslipItemConfigState>({ selectId: model => model.payItemId })
const employmentConfigsAdapter = createEntityAdapter<EmploymentConfigState>({ selectId: model => model.code })
const selfUpdateConfigsAdapter = createEntityAdapter<SelfUpdateConfigState>({ selectId: model => model.code })
const ssAccessConfigsAdapter = createEntityAdapter<SsAccessConfigState>({ selectId: model => model.featureFlag })

const companiesInitialState = companiesAdapter.getInitialState()
const companiesExtraInitialState = companiesExtraAdapter.getInitialState()
const officesInitialState = officesAdapter.getInitialState()
const officesExtraInitialState = officesExtraAdapter.getInitialState()
const companyOfficesInitialState = companyOfficesAdapter.getInitialState()
const mastersInitialState = mastersAdapter.getInitialState()
const departmentsExtraInitialState = departmentsExtraAdapter.getInitialState()
const divisionsExtraInitialState = divisionsExtraAdapter.getInitialState()
const sectionsExtraInitialState = sectionsExtraAdapter.getInitialState()
const teamsExtraInitialState = teamsExtraAdapter.getInitialState()
const calendarPatternsInitialState = calendarPatternsAdapter.getInitialState()
const payItemsSgViewInitialState = payItemsViewAdapter.getInitialState()
const payItemsSgInitialState = payItemsAdapter.getInitialState()
const payItemBasesInitialState = payItemBasesAdapter.getInitialState()
const payClaimMapsInitialState = payClaimMapsAdapter.getInitialState()
const payDailyMapsInitialState = payDailyMapsAdapter.getInitialState()
const payEncashMapsInitialState = payEncashMapsAdapter.getInitialState()
const payLeaveMapsInitialState = payLeaveMapsAdapter.getInitialState()
const banksInitialState = banksAdapter.getInitialState()
const holidayDatesInitialState = holidayDatesAdapter.getInitialState()
export const keyvalueInitialState = keyvaluesAdapter.getInitialState()
const emailGroupsInitialState = emailGroupsAdapter.getInitialState()
const delegationsInitialState = delegationsAdapter.getInitialState()
const payslipItemConfigsInitialState = payslipItemConfigsAdapter.getInitialState()
const employmentConfigsInitialState = employmentConfigsAdapter.getInitialState()
const selfUpdateConfigsInitialState = selfUpdateConfigsAdapter.getInitialState()
const ssAccessConfigsInitialState = ssAccessConfigsAdapter.getInitialState()

const initialState: MasterRootState = {
  companies: companiesInitialState,
  companiesLoading: false,
  companiesExtra: companiesExtraInitialState,
  companiesExtraLoading: false,
  offices: officesInitialState,
  officesLoading: false,
  officesExtra: officesExtraInitialState,
  officesExtraLoading: false,
  companyOffices: companyOfficesInitialState,
  companyOfficesLoading: false,
  departmentsExtra: departmentsExtraInitialState,
  departmentsExtraLoading: false,
  divisionsExtra: divisionsExtraInitialState,
  divisionsExtraLoading: false,
  sectionsExtra: sectionsExtraInitialState,
  sectionsExtraLoading: false,
  teamsExtra: teamsExtraInitialState,
  teamsExtraLoading: false,
  calendarPatterns: calendarPatternsInitialState,
  calendarPatternsLoading: false,
  holidayDates: {},
  holidayDatesLoading: {},
  banks: banksInitialState,
  banksLoading: false,
  payItemsView: payItemsSgViewInitialState,
  payItemsViewLoading: false,
  payItems: payItemsSgInitialState,
  payItemsLoading: false,
  payItemBases: {},
  payItemBasesLoading: {},
  payClaimMaps: {},
  payClaimMapsLoading: {},
  payDailyMaps: {},
  payDailyMapsLoading: {},
  payEncashMaps: {},
  payEncashMapsLoading: {},
  payLeaveMaps: {},
  payLeaveMapsLoading: {},
  masters: {
    costCentre: mastersInitialState,
    employmentType: mastersInitialState,
    job: mastersInitialState,
    holiday: mastersInitialState,
    department: mastersInitialState,
    division: mastersInitialState,
    section: mastersInitialState,
    grp: mastersInitialState,
    category: mastersInitialState,
    team: mastersInitialState,
    calendar: mastersInitialState,
    docType: mastersInitialState,
    race: mastersInitialState,
    reason: mastersInitialState,
    religion: mastersInitialState,
    payGroup: mastersInitialState,
    leaveGroup: mastersInitialState,
    qualification: mastersInitialState,
    salaryGrade: mastersInitialState
  },
  mastersLoading: {
    costCentre: false,
    employmentType: false,
    job: false,
    holiday: false,
    department: false,
    division: false,
    section: false,
    grp: false,
    category: false,
    team: false,
    calendar: false,
    docType: false,
    race: false,
    reason: false,
    religion: false,
    payGroup: false,
    leaveGroup: false,
    qualification: false,
    salaryGrade: false
  },
  mastersRefetch: {
    costCentre: 0,
    employmentType: 0,
    job: 0,
    holiday: 0,
    department: 0,
    division: 0,
    section: 0,
    grp: 0,
    category: 0,
    team: 0,
    calendar: 0,
    docType: 0,
    race: 0,
    reason: 0,
    religion: 0,
    payGroup: 0,
    leaveGroup: 0,
    qualification: 0,
    salaryGrade: 0
  },
  keyvalues: {},
  keyvaluesParams: {},
  keyvaluesLoading: {},
  emailGroups: emailGroupsInitialState,
  emailGroupsLoading: false,
  delegations: delegationsInitialState,
  delegationsLoading: false,
  payslipItemConfigs: {},
  payslipItemConfigsLoading: {},
  employmentConfigs: employmentConfigsInitialState,
  employmentConfigsLoading: false,
  selfUpdateConfigs: selfUpdateConfigsInitialState,
  selfUpdateConfigsLoading: false,
  ssAccessConfigs: ssAccessConfigsInitialState,
  ssAccessConfigsLoading: false
}

const masterSlice = createSlice({
  name: 'master',
  initialState,
  reducers: {
    setCompany: (state, action: PayloadAction<CompanyState>) => {
      companiesAdapter.upsertOne(state.companies, action.payload)
    },
    setCompaniesLoading: (state, action: PayloadAction<boolean>) => {
      state.companiesLoading = action.payload
    },
    replaceCompanies: (state, action: PayloadAction<CompanyState[]>) => {
      companiesAdapter.setAll(state.companies, action.payload)
    },
    removeCompany: (state, action: PayloadAction<string>) => {
      companiesAdapter.removeOne(state.companies, action.payload)
    },
    setCompaniesExtraLoading: (state, action: PayloadAction<boolean>) => {
      state.companiesExtraLoading = action.payload
    },
    replaceCompaniesExtra: (state, action: PayloadAction<CompanyExtraState[]>) => {
      companiesExtraAdapter.setAll(state.companiesExtra, action.payload)
    },
    setCompanyOffice: (state, action: PayloadAction<CompanyOfficeState>) => {
      companyOfficesAdapter.upsertOne(state.companyOffices, action.payload)
    },
    setCompanyOfficesLoading: (state, action: PayloadAction<boolean>) => {
      state.companyOfficesLoading = action.payload
    },
    setCompanyOffices: (state, action: PayloadAction<CompanyOfficeState[]>) => {
      companyOfficesAdapter.upsertMany(state.companyOffices, action.payload)
    },
    replaceCompanyOffices: (state, action: PayloadAction<CompanyOfficeState[]>) => {
      companyOfficesAdapter.setAll(state.companyOffices, action.payload)
    },
    removeCompanyOffice: (state, action: PayloadAction<string>) => {
      companyOfficesAdapter.removeOne(state.companyOffices, action.payload)
    },
    setOffice: (state, action: PayloadAction<OfficeState>) => {
      officesAdapter.upsertOne(state.offices, action.payload)
    },
    setOfficesLoading: (state, action: PayloadAction<boolean>) => {
      state.officesLoading = action.payload
    },
    replaceOffices: (state, action: PayloadAction<OfficeState[]>) => {
      officesAdapter.setAll(state.offices, action.payload)
    },
    removeOffice: (state, action: PayloadAction<string>) => {
      officesAdapter.removeOne(state.offices, action.payload)
    },
    setOfficesExtraLoading: (state, action: PayloadAction<boolean>) => {
      state.officesExtraLoading = action.payload
    },
    replaceOfficesExtra: (state, action: PayloadAction<OfficeExtraState[]>) => {
      officesExtraAdapter.setAll(state.officesExtra, action.payload)
    },
    setMaster: (state, action: PayloadAction<{ masterName: MasterName; data: MasterState }>) => {
      mastersAdapter.upsertOne(state.masters[action.payload.masterName]!, action.payload.data)
    },
    setMastersLoading: (state, action: PayloadAction<{ masterName: MasterName; loading: boolean }>) => {
      state.mastersLoading[action.payload.masterName] = action.payload.loading
    },
    replaceMasters: (state, action: PayloadAction<{ masterName: MasterName; data: MasterState[] }>) => {
      mastersAdapter.setAll(state.masters[action.payload.masterName]!, action.payload.data)
    },
    removeMaster: (state, action: PayloadAction<{ masterName: MasterName; id: string }>) => {
      mastersAdapter.removeOne(state.masters[action.payload.masterName]!, action.payload.id)
    },
    refetchMasters: (state, action: PayloadAction<{ masterName: MasterName }>) => {
      const { masterName } = action.payload
      state.mastersRefetch[masterName] += 1
    },
    setDepartmentsExtraLoading: (state, action: PayloadAction<boolean>) => {
      state.departmentsExtraLoading = action.payload
    },
    replaceDepartmentsExtra: (state, action: PayloadAction<DepartmentExtraState[]>) => {
      departmentsExtraAdapter.setAll(state.departmentsExtra, action.payload)
    },
    setDivisionsExtraLoading: (state, action: PayloadAction<boolean>) => {
      state.divisionsExtraLoading = action.payload
    },
    replaceDivisionsExtra: (state, action: PayloadAction<DivisionExtraState[]>) => {
      divisionsExtraAdapter.setAll(state.divisionsExtra, action.payload)
    },
    setSectionsExtraLoading: (state, action: PayloadAction<boolean>) => {
      state.sectionsExtraLoading = action.payload
    },
    replaceSectionsExtra: (state, action: PayloadAction<SectionExtraState[]>) => {
      sectionsExtraAdapter.setAll(state.sectionsExtra, action.payload)
    },
    setTeamsExtraLoading: (state, action: PayloadAction<boolean>) => {
      state.teamsExtraLoading = action.payload
    },
    replaceTeamsExtra: (state, action: PayloadAction<TeamExtraState[]>) => {
      teamsExtraAdapter.setAll(state.teamsExtra, action.payload)
    },
    setCalendarPatterns: (state, action: PayloadAction<CalendarPatternState[]>) => {
      calendarPatternsAdapter.upsertMany(state.calendarPatterns, action.payload)
    },
    replaceCalendarPatterns: (state, action: PayloadAction<CalendarPatternState[]>) => {
      calendarPatternsAdapter.setAll(state.calendarPatterns, action.payload)
    },
    setCalendarPatternsLoading: (state, action: PayloadAction<boolean>) => {
      state.calendarPatternsLoading = action.payload
    },
    replaceBanks: (state, action: PayloadAction<BankState[]>) => {
      banksAdapter.setAll(state.banks, action.payload)
    },
    setBank: (state, action: PayloadAction<BankState>) => {
      banksAdapter.upsertOne(state.banks, action.payload)
    },
    removeBank: (state, action: PayloadAction<string>) => {
      banksAdapter.removeOne(state.banks, action.payload)
    },
    setBanksLoading: (state, action: PayloadAction<boolean>) => {
      state.banksLoading = action.payload
    },
    replaceHolidayDates: (state, action: PayloadAction<{ holidayId: string; data: HolidayDateState[] }>) => {
      state.holidayDates[action.payload.holidayId] =
        state.holidayDates[action.payload.holidayId] || holidayDatesInitialState
      holidayDatesAdapter.setAll(state.holidayDates[action.payload.holidayId]!, action.payload.data)
    },
    setHolidayDatesLoading: (state, action: PayloadAction<{ holidayId: string; loading: boolean }>) => {
      state.holidayDates[action.payload.holidayId] =
        state.holidayDates[action.payload.holidayId] || holidayDatesInitialState
      state.holidayDatesLoading[action.payload.holidayId] = action.payload.loading
    },
    setHolidayDate: (state, action: PayloadAction<{ holidayId: string; data: HolidayDateState }>) => {
      const { holidayId, data } = action.payload
      state.holidayDates[holidayId] = state.holidayDates[holidayId] || holidayDatesInitialState
      holidayDatesAdapter.upsertOne(state.holidayDates[holidayId]!, data)
    },
    removeHolidayDate: (state, action: PayloadAction<{ holidayId: string; id: string }>) => {
      holidayDatesAdapter.removeOne(state.holidayDates[action.payload.holidayId]!, action.payload.id)
    },
    replacePayItemsView: (state, action: PayloadAction<PayItemState[]>) => {
      payItemsViewAdapter.setAll(state.payItemsView, action.payload)
    },
    setPayItemsViewLoading: (state, action: PayloadAction<boolean>) => {
      state.payItemsViewLoading = action.payload
    },
    replacePayItems: (state, action: PayloadAction<PayItemState[]>) => {
      payItemsAdapter.setAll(state.payItems, action.payload)
    },
    setPayItem: (state, action: PayloadAction<PayItemState>) => {
      payItemsAdapter.upsertOne(state.payItems, action.payload)
    },
    removePayItem: (state, action: PayloadAction<string>) => {
      payItemsAdapter.removeOne(state.payItems, action.payload)
    },
    setPayItemsLoading: (state, action: PayloadAction<boolean>) => {
      state.payItemsLoading = action.payload
    },
    replacePayItemBases: (state, action: PayloadAction<{ id: string; data: PayItemBasisState[] }>) => {
      state.payItemBases[action.payload.id] = state.payItemBases[action.payload.id] || payItemBasesInitialState
      payItemBasesAdapter.setAll(state.payItemBases[action.payload.id]!, action.payload.data)
    },
    setPayItemBasesLoading: (state, action: PayloadAction<{ id: string; loading: boolean }>) => {
      state.payItemBases[action.payload.id] = state.payItemBases[action.payload.id] || payItemBasesInitialState
      state.payItemBasesLoading[action.payload.id] = action.payload.loading
    },
    replacePayClaimMaps: (state, action: PayloadAction<{ id: string; data: PayClaimMapState[] }>) => {
      state.payClaimMaps[action.payload.id] = state.payClaimMaps[action.payload.id] || payClaimMapsInitialState
      payClaimMapsAdapter.setAll(state.payClaimMaps[action.payload.id]!, action.payload.data)
    },
    setPayClaimMapsLoading: (state, action: PayloadAction<{ id: string; loading: boolean }>) => {
      state.payClaimMaps[action.payload.id] = state.payClaimMaps[action.payload.id] || payClaimMapsInitialState
      state.payClaimMapsLoading[action.payload.id] = action.payload.loading
    },
    replacePayDailyMaps: (state, action: PayloadAction<{ id: string; data: PayDailyMapState[] }>) => {
      state.payDailyMaps[action.payload.id] = state.payDailyMaps[action.payload.id] || payDailyMapsInitialState
      payDailyMapsAdapter.setAll(state.payDailyMaps[action.payload.id]!, action.payload.data)
    },
    setPayDailyMapsLoading: (state, action: PayloadAction<{ id: string; loading: boolean }>) => {
      state.payDailyMaps[action.payload.id] = state.payDailyMaps[action.payload.id] || payDailyMapsInitialState
      state.payDailyMapsLoading[action.payload.id] = action.payload.loading
    },
    replacePayEncashMaps: (state, action: PayloadAction<{ id: string; data: PayEncashMapState[] }>) => {
      state.payEncashMaps[action.payload.id] = state.payEncashMaps[action.payload.id] || payEncashMapsInitialState
      payEncashMapsAdapter.setAll(state.payEncashMaps[action.payload.id]!, action.payload.data)
    },
    setPayEncashMapsLoading: (state, action: PayloadAction<{ id: string; loading: boolean }>) => {
      state.payEncashMaps[action.payload.id] = state.payEncashMaps[action.payload.id] || payEncashMapsInitialState
      state.payEncashMapsLoading[action.payload.id] = action.payload.loading
    },
    replacePayLeaveMaps: (state, action: PayloadAction<{ id: string; data: PayLeaveMapState[] }>) => {
      state.payLeaveMaps[action.payload.id] = state.payLeaveMaps[action.payload.id] || payLeaveMapsInitialState
      payLeaveMapsAdapter.setAll(state.payLeaveMaps[action.payload.id]!, action.payload.data)
    },
    setPayLeaveMapsLoading: (state, action: PayloadAction<{ id: string; loading: boolean }>) => {
      state.payLeaveMaps[action.payload.id] = state.payLeaveMaps[action.payload.id] || payLeaveMapsInitialState
      state.payLeaveMapsLoading[action.payload.id] = action.payload.loading
    },
    setKeyvaluesLoading: (state, action: PayloadAction<{ kv: string; loading: boolean }>) => {
      state.keyvalues[action.payload.kv] = state.keyvalues[action.payload.kv] || keyvalueInitialState
      state.keyvaluesLoading[action.payload.kv] = action.payload.loading
    },
    setKeyvalue: (state, action: PayloadAction<{ kv: string; data: KeyValue }>) => {
      state.keyvalues[action.payload.kv] = state.keyvalues[action.payload.kv] || keyvalueInitialState
      keyvaluesAdapter.upsertOne(state.keyvalues[action.payload.kv]!, action.payload.data)
    },
    replaceKeyvalues: (state, action: PayloadAction<{ kv: string; data: KeyValue[]; params: string }>) => {
      state.keyvaluesParams[action.payload.kv] = action.payload.params
      state.keyvalues[action.payload.kv] = state.keyvalues[action.payload.kv] || keyvalueInitialState
      keyvaluesAdapter.setAll(state.keyvalues[action.payload.kv]!, action.payload.data)
    },
    removeKeyvalue: (state, action: PayloadAction<{ kv: string; id: string }>) => {
      state.keyvalues[action.payload.kv] = state.keyvalues[action.payload.kv] || keyvalueInitialState
      keyvaluesAdapter.removeOne(state.keyvalues[action.payload.kv]!, action.payload.id)
    },
    replaceEmailGroups: (state, action: PayloadAction<EmailGroupState[]>) => {
      emailGroupsAdapter.setAll(state.emailGroups, action.payload)
    },
    setEmailGroup: (state, action: PayloadAction<EmailGroupState>) => {
      emailGroupsAdapter.upsertOne(state.emailGroups, action.payload)
    },
    removeEmailGroup: (state, action: PayloadAction<string>) => {
      emailGroupsAdapter.removeOne(state.emailGroups, action.payload)
    },
    setEmailGroupsLoading: (state, action: PayloadAction<boolean>) => {
      state.emailGroupsLoading = action.payload
    },
    replaceDelegations: (state, action: PayloadAction<DelegationState[]>) => {
      delegationsAdapter.setAll(state.delegations, action.payload)
    },
    setDelegation: (state, action: PayloadAction<DelegationState>) => {
      delegationsAdapter.upsertOne(state.delegations, action.payload)
    },
    removeDelegation: (state, action: PayloadAction<string>) => {
      delegationsAdapter.removeOne(state.delegations, action.payload)
    },
    setDelegationsLoading: (state, action: PayloadAction<boolean>) => {
      state.delegationsLoading = action.payload
    },
    setPayslipItemConfigsLoading: (state, action: PayloadAction<{ payGroupId: string; loading: boolean }>) => {
      const { payGroupId, loading } = action.payload
      state.payslipItemConfigs[payGroupId] = state.payslipItemConfigs[payGroupId] || payslipItemConfigsInitialState
      state.payslipItemConfigsLoading[payGroupId] = loading
    },
    replacePayslipItemConfigs: (
      state,
      action: PayloadAction<{ payGroupId: string; data: PayslipItemConfigState[] }>
    ) => {
      const { payGroupId, data } = action.payload
      payslipItemConfigsAdapter.setAll(state.payslipItemConfigs[payGroupId], data)
    },
    setPayslipItemConfig: (state, action: PayloadAction<{ payGroupId: string; data: PayslipItemConfigState }>) => {
      const { payGroupId, data } = action.payload
      payslipItemConfigsAdapter.upsertOne(state.payslipItemConfigs[payGroupId], data)
    },
    replaceEmploymentConfigs: (state, action: PayloadAction<EmploymentConfigState[]>) => {
      employmentConfigsAdapter.setAll(state.employmentConfigs, action.payload)
    },
    setEmploymentConfig: (state, action: PayloadAction<EmploymentConfigState>) => {
      employmentConfigsAdapter.upsertOne(state.employmentConfigs, action.payload)
    },
    setEmploymentConfigsLoading: (state, action: PayloadAction<boolean>) => {
      state.employmentConfigsLoading = action.payload
    },
    replaceSelfUpdateConfigs: (state, action: PayloadAction<SelfUpdateConfigState[]>) => {
      selfUpdateConfigsAdapter.setAll(state.selfUpdateConfigs, action.payload)
    },
    setSelfUpdateConfig: (state, action: PayloadAction<SelfUpdateConfigState>) => {
      selfUpdateConfigsAdapter.upsertOne(state.selfUpdateConfigs, action.payload)
    },
    setSelfUpdateConfigsLoading: (state, action: PayloadAction<boolean>) => {
      state.selfUpdateConfigsLoading = action.payload
    },
    replaceSsAccessConfigs: (state, action: PayloadAction<SsAccessConfigState[]>) => {
      ssAccessConfigsAdapter.setAll(state.ssAccessConfigs, action.payload)
    },
    setSsAccessConfigsLoading: (state, action: PayloadAction<boolean>) => {
      state.ssAccessConfigsLoading = action.payload
    }
  }
})

export const {
  setCompany,
  replaceCompanies,
  removeCompany,
  setCompaniesLoading,
  setCompaniesExtraLoading,
  replaceCompaniesExtra,
  setCompanyOffice,
  setCompanyOffices,
  replaceCompanyOffices,
  removeCompanyOffice,
  setCompanyOfficesLoading,
  setOffice,
  replaceOffices,
  removeOffice,
  setOfficesLoading,
  setOfficesExtraLoading,
  replaceOfficesExtra,
  setMaster,
  replaceMasters,
  removeMaster,
  setMastersLoading,
  refetchMasters,
  setDepartmentsExtraLoading,
  replaceDepartmentsExtra,
  setDivisionsExtraLoading,
  replaceDivisionsExtra,
  setSectionsExtraLoading,
  replaceSectionsExtra,
  setTeamsExtraLoading,
  replaceTeamsExtra,
  setCalendarPatterns,
  replaceCalendarPatterns,
  setCalendarPatternsLoading,
  replaceBanks,
  setBank,
  removeBank,
  setBanksLoading,
  setHolidayDate,
  removeHolidayDate,
  replaceHolidayDates,
  setHolidayDatesLoading,
  replacePayItemsView,
  setPayItemsViewLoading,
  replacePayItems,
  setPayItem,
  removePayItem,
  setPayItemsLoading,
  replacePayItemBases,
  setPayItemBasesLoading,
  replacePayClaimMaps,
  setPayClaimMapsLoading,
  replacePayDailyMaps,
  setPayDailyMapsLoading,
  replacePayEncashMaps,
  setPayEncashMapsLoading,
  replacePayLeaveMaps,
  setPayLeaveMapsLoading,
  setKeyvaluesLoading,
  setKeyvalue,
  replaceKeyvalues,
  removeKeyvalue,

  replaceEmailGroups,
  setEmailGroup,
  removeEmailGroup,
  setEmailGroupsLoading,

  replaceDelegations,
  setDelegation,
  removeDelegation,
  setDelegationsLoading,

  replacePayslipItemConfigs,
  setPayslipItemConfig,
  setPayslipItemConfigsLoading,

  replaceEmploymentConfigs,
  setEmploymentConfig,
  setEmploymentConfigsLoading,

  replaceSelfUpdateConfigs,
  setSelfUpdateConfig,
  setSelfUpdateConfigsLoading,

  replaceSsAccessConfigs,
  setSsAccessConfigsLoading
} = masterSlice.actions

export const { selectById: selectCompanyById, selectAll: selectCompanies } = companiesAdapter.getSelectors(
  (state: StoreState) => state.master.companies
)

export const { selectById: selectOfficeById, selectIds: selectOfficeIds } = officesAdapter.getSelectors(
  (state: StoreState) => state.master.offices
)

export const { selectById: selectPayItemById, selectEntities: selectPayItemsDict } = payItemsAdapter.getSelectors(
  (state: StoreState) => state.master.payItems
)

export const { selectById: selectBankById } = banksAdapter.getSelectors((state: StoreState) => state.master.banks)

export const { selectById: selectHolidayById } = mastersAdapter.getSelectors(
  (state: StoreState) => state.master.masters.holiday
)

export const { selectById: selectEmailGroupById } = emailGroupsAdapter.getSelectors(
  (state: StoreState) => state.master.emailGroups
)

export const masterReducers = {
  master: persistReducer<MasterRootState>(masterPersistConfig, masterSlice.reducer)
}
