import React, { FC, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ZealysLoading } from '~/components'
import { dispatch } from '~/stores/store'
import { useQuery } from '~/hooks/use-query'
import { showError } from '~/utils'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { StoreState } from '~/types/store'
import { apiGetXeroAuthToken } from '../../api/xero.api'
import { setXeroState } from '../../reducers'

interface XeroCallbackProps {}

export const XeroCallback: FC<XeroCallbackProps> = () => {
  const query = useQuery()
  const history = useHistory()
  const code = query.get('code')
  const state = query.get('state')
  const error = query.get('error')
  const redirectUrl = useSelector((state: StoreState) => state.payroll.xeroRedirectUrl)

  useEffect(() => {
    const retrieveXeroToken = async () => {
      if (code && state) {
        await dispatch(setXeroState(state))
        const { result, status, message } = await apiGetXeroAuthToken(code, state)
        console.log('Xero token', result)
        if (!status || !result) {
          showError(message || 'Error occurred when getting access token from Xero. Did you select any orgarnisation?')
        }
      } else {
        if (error === 'access_denied') {
          showError('Unable to connect. Did you cancel?')
        } else {
          showError(`Unable to connect. Error: ${error}`)
        }
      }

      if (redirectUrl) window.location.href = redirectUrl
      else history.push(SETTINGS_ROUTES.xero)
    }

    retrieveXeroToken()
  }, [code, state, error, history, redirectUrl])

  return <ZealysLoading />
}
