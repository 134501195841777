import React, { FC } from 'react'
import { Select, SelectProps } from '~/core-components'
import { useExpenseTypesByClaim } from '~/features/claim/hooks'

interface ExpenseTypeKeyValuesProps extends SelectProps {
  claimTypeId: string
}

export const ExpenseTypeKeyValues: FC<ExpenseTypeKeyValuesProps> = ({ claimTypeId, ...props }) => {
  const [expenseTypes, loading] = useExpenseTypesByClaim(claimTypeId)

  return (
    <Select showSearch allowClear optionFilterProp="title" loading={loading} {...props}>
      {expenseTypes.map(data => (
        <Select.Option key={data?.id} value={data?.id} title={data?.name}>
          {data?.name}
        </Select.Option>
      ))}
    </Select>
  )
}
