import React, { FC, useState, useCallback } from 'react'
import { useHistory } from 'react-router'
import { Form } from '~/core-components'
import { DrawerForm } from '~/components'
import { CompanyInfoForm, EMPTY_COMPANY_INFO_FORM_DATA } from './CompanyInfoForm'
import { SETTINGS_PATH } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors } from '~/types/store'
import { addCompany } from '../../../actions'
import { ICompanyInfo } from '../../../types'

interface AddCompanyDrawerProps {
  visible: boolean
  onClose: () => void
}

export const AddCompanyDrawer: FC<AddCompanyDrawerProps> = ({ visible, onClose }: AddCompanyDrawerProps) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<ICompanyInfo>(EMPTY_COMPANY_INFO_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const history = useHistory()

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      result = await dispatch(addCompany(formData))
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_COMPANY_INFO_FORM_DATA)
      history.push(`${SETTINGS_PATH}/company/${result?.result?.id}`)
    }
  }, [formData, onClose, history])

  return (
    <DrawerForm
      open={visible}
      title="Add company"
      onClose={onClose}
      confirmLoading={loading}
      width={650}
      formId="form-company"
    >
      <Form id="form-company" onFinish={handleOk}>
        <CompanyInfoForm data={formData} errors={errors} onChange={data => setFormData(data)} />
      </Form>
    </DrawerForm>
  )
}
