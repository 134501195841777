import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchMyTenants } from '../actions'
import { selectMyTenants } from '../selectors'
import { MyTenantState } from '../types'

export const useMyTenants = (fetchStrategy: FetchStrategy = 'always'): [MyTenantState[], boolean] => {
  const myTenants = useSelector(selectMyTenants)
  const loading = useSelector((state: StoreState) => state.iam.myTenantsLoading)

  useEffect(() => {
    dispatch(fetchMyTenants({ strategy: fetchStrategy }))
  }, [fetchStrategy])

  return [myTenants, loading]
}
