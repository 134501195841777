import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { PayCfConfigState } from '../types'

const baseUrl = getBaseUrl('/payroll-sg/paycfconfig')

export const apiGetPayCfConfigs = () => request<PayCfConfigState[]>('get', baseUrl)

export const apiUpdatePayCfConfig = (code: string, payCfConfig: Operation[]) =>
  request('patch', `${baseUrl}/${code}`, payCfConfig)
