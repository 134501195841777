import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { EmCalendarState, IEmCalendar } from '../types'

const baseUrl = getBaseUrl('/employee/employee')

export const apiGetEmCalendars = (employeeId: string) =>
  request<EmCalendarState[]>('get', `${baseUrl}/${employeeId}/calendar`)

export const apiGetEmCalendar = (employeeId: string, emCalendarId: string) =>
  request<EmCalendarState>('get', `${baseUrl}/${employeeId}/calendar/${emCalendarId}`)

export const apiAddEmCalendar = (employeeId: string, emCalendar: IEmCalendar) =>
  request<{ id: string; emCalendarId?: string }>('post', `${baseUrl}/${employeeId}/calendar`, emCalendar)

export const apiUpdateEmCalendar = (employeeId: string, id: string, emCalendar: Operation[]) =>
  request<{ emCalendarId?: string }>('patch', `${baseUrl}/${employeeId}/calendar/${id}`, emCalendar)

export const apiDeleteEmCalendar = (employeeId: string, id: string) =>
  request<{ emCalendarId?: string }>('delete', `${baseUrl}/${employeeId}/calendar/${id}`)
