import { showSuccess, showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { ICalendarAndPattern, WorkCalendarState } from '../types'
import { replaceCalendarPatterns, setCalendar } from '../reducers'
import { apiSaveCalendarAndPatterns } from '../api/calendar.api'
import { CalendarState, setMaster } from '~/features/master'

export const saveCalendarWithPatterns = (request: ICalendarAndPattern): ThunkResult<void> => {
  return async (dispatch, getState): Promise<ActionResult> => {
    const { status, result, errors, message, errorData } = await apiSaveCalendarAndPatterns(request)
    if (status) {
      const calendarId = result.id || request.id
      const calendar = getState().attendance.calendars?.entities[calendarId]
      const newCalendar: WorkCalendarState = {
        ...calendar,
        ...request,
        id: calendarId,
        name: calendar?.name || '',
        wdPerWeek: result.wdPerWeek
      }

      dispatch(setCalendar(newCalendar))

      const masterCalendar = getState().master.masters['calendar'].entities[calendarId]
      dispatch(
        setMaster({
          masterName: 'calendar',
          data: {
            ...masterCalendar,
            wdPerWeek: result.wdPerWeek,
            patternStartDate: newCalendar.patternStartDate
          } as CalendarState
        })
      )

      if (result.patterns) {
        dispatch(replaceCalendarPatterns({ calendarId, data: result.patterns }))
      }

      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
