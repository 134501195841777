import { ThunkResult } from '~/types/store'
import { showSuccess, showError } from '~/utils'
import { apiDeleteEmIdentity } from '../api/em-identity.api'
import { removeEmIdentity, setEmployee } from '../reducers'

export const deleteEmIdentity = (employeeId: string, id: string): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!employeeId || !id) return

    const { result, status, errors, message, errorData } = await apiDeleteEmIdentity(employeeId, id)
    if (status) {
      if (result.emIdentityId) {
        const employee = getState().employee.employees.entities[employeeId]
        if (employee) {
          dispatch(setEmployee({ ...employee, emIdentityId: result.emIdentityId }))
        }
      }

      dispatch(removeEmIdentity({ employeeId, id }))
      showSuccess('Deleted')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
