import { ActionResult, ThunkResult } from '~/types/store'
import { emptyGuid } from '~/constants'
import { showSuccess, showError } from '~/utils'
import { apiAddEmManagerSecondary } from '../api/em-manager-secondary.api'
import { setEmManagerSecondary, setEmployee } from '../reducers'
import { IEmManagerSecondaryInfo, EmManagerSecondaryState } from '../types'

export const addEmManagerSecondary = (
  employeeId: string,
  emManagerSecondary: IEmManagerSecondaryInfo
): ThunkResult<void> => {
  return async (dispatch, getState): Promise<ActionResult | undefined> => {
    if (!employeeId) return

    const { status, result, errors, message, errorData } = await apiAddEmManagerSecondary(employeeId, {
      id: emptyGuid,
      employeeId,
      ...emManagerSecondary,
      managerSecondaryId: emManagerSecondary.managerSecondaryId || emptyGuid
    })
    if (status) {
      if (result.emManagerSecondaryId) {
        const employee = getState().employee.employees.entities[employeeId]
        if (employee) {
          dispatch(setEmployee({ ...employee, emManagerSecondaryId: result.emManagerSecondaryId }))
        }
      }

      const newEmManagerSecondary = {
        id: result.id,
        employeeId,
        ...emManagerSecondary
      } as EmManagerSecondaryState
      dispatch(setEmManagerSecondary({ employeeId, data: newEmManagerSecondary }))

      showSuccess('Added')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
