import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { Select, SelectProps, Space } from '~/core-components'
import { selectXeroTenantMapped } from '../../selectors'
import './XeroSelectTenantMap.less'

interface XeroSelectTenantMapProps extends SelectProps {}

export const XeroSelectTenantMap: FC<XeroSelectTenantMapProps> = ({ className, ...props }) => {
  const companies = useSelector(selectXeroTenantMapped)
  const classes = classNames(
    'xero-select-tenant-map',
    { [`${className}`]: className },
    { 'xero-select-tenant-map--hidden': companies.length <= 1 }
  )

  return (
    <Select className={classes} allowClear={false} {...props}>
      {companies.map(c => (
        <Select.Option key={c.id} value={c.id}>
          <Space size="middle">
            {c.companyName}
            <i className="fal fa-arrow-right-long" />
            {c.tenantName}
          </Space>
        </Select.Option>
      ))}
    </Select>
  )
}
