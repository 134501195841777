import { setIr21A1Form, setIr21A1FormUpdating } from '../reducers'
import { apiUpdateIr21A1Draft } from '../api/ir21.api'
import { ThunkResult } from '~/types/store'
import { showSuccess, showError } from '~/utils'
import { Ir21A1FormState } from '../types'

export const updateIr21A1Draft = (request: Ir21A1FormState): ThunkResult<void> => {
  return async dispatch => {
    try {
      dispatch(setIr21A1FormUpdating(true))
      const { status, errors, message, errorData } = await apiUpdateIr21A1Draft(request)
      if (status) {
        dispatch(setIr21A1Form(request))
        showSuccess('Saved')
      } else {
        if (Object.keys(errors).length === 0) {
          showError(message, errorData)
        }
      }
      return { errors }
    } finally {
      dispatch(setIr21A1FormUpdating(false))
    }
  }
}
