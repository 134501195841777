import { Dictionary, createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { ShiftDayState } from '../types'

export const selectShiftDaysDict = createSelector(
  (state: StoreState) => state.attendance.shiftDays,
  shiftDaysState =>
    memoize((shiftId?: string): Dictionary<ShiftDayState> => {
      if (!shiftId) return {}

      const shiftDays = Object.values(shiftDaysState[shiftId]?.entities || {}) as ShiftDayState[]
      return Object.assign({}, ...shiftDays.map(x => ({ [x.dayCode]: x })))
    })
)
