import React, { FC, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Col, Row } from '~/components'
import { Alert, Collapse, List } from '~/core-components'
import { LeaveTypeName } from '~/features/leave'
import { MyLeaveApplyButton } from '~/features/my/containers/Leave/MyLeaveApply/MyLeaveApplyButton'
import { formatDateRange, formatNumberUnit } from '~/utils'
import { SSLeaveBalanceInfo, selectMyLeaveGrantsBalance } from '../../../../../../selectors'
import './MyLeaveGrantBalanceList.less'

interface MyLeaveGrantBalanceListProps {}

export const MyLeaveGrantBalanceList: FC<MyLeaveGrantBalanceListProps> = () => {
  const [expand, setExpand] = useState<string>()
  const leaveGrants = useSelector(selectMyLeaveGrantsBalance)

  const handleExpand = useCallback((rowKey: string) => {
    setExpand(expand => (expand === rowKey ? undefined : rowKey))
  }, [])

  return (
    <div className="my-leave-grant-balance-list">
      {leaveGrants.length > 0 && (
        <>
          <div className="subheader">Expiring leave</div>
          <List
            size="small"
            dataSource={leaveGrants}
            renderItem={(item: SSLeaveBalanceInfo, index: number) => (
              <List.Item key={item.id}>
                <Collapse activeKey={expand} onChange={() => handleExpand(`${index}`)} expandIconPosition="end">
                  <Collapse.Panel
                    key={`${index}`}
                    header={
                      <Row wrap>
                        <Col flex="1">
                          <LeaveTypeName id={item.leaveTypeId}></LeaveTypeName>
                        </Col>
                        <Col flex="80px" className="col-right">
                          {formatNumberUnit(item.balance, item.unit)}
                        </Col>
                      </Row>
                    }
                  >
                    <Row>
                      <Col flex="1">Granted</Col>
                      <Col flex="80px" className="col-right">
                        {formatNumberUnit(item.earned, item.unit)}
                      </Col>
                    </Row>
                    <Row>
                      <Col flex="1">Taken</Col>
                      <Col flex="80px" className="col-right">
                        {formatNumberUnit(item.taken + item.pending, item.unit)}
                      </Col>
                    </Row>
                    <Row>
                      <Col flex="1">{moment(item.endDate).isBefore(moment(), 'day') ? 'Expired' : 'Balance'}</Col>
                      <Col flex="80px" className="col-right">
                        {formatNumberUnit(item.balance, item.unit)}
                      </Col>
                    </Row>
                    <Row className="mt8 mx-12">
                      <Col flex="1">
                        <Alert
                          type="info"
                          message={
                            <>
                              <div>Validity: {formatDateRange(item.startDate, item.endDate)}</div>
                              {!!item.notes && <div>Notes: {item.notes}</div>}
                            </>
                          }
                        />
                      </Col>
                    </Row>
                    <Row hidden={!item.ssApply}>
                      <Col flex="1">
                        <MyLeaveApplyButton leaveTypeId={item.leaveTypeId} leaveGrantId={item.id}>
                          Apply leave
                        </MyLeaveApplyButton>
                      </Col>
                    </Row>
                  </Collapse.Panel>
                </Collapse>
              </List.Item>
            )}
          />
        </>
      )}
    </div>
  )
}
