import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Checkbox, CheckboxValueType, Form } from '~/core-components'
import { DrawerForm } from '~/components'
import { useSysOptions } from '~/features/employee'
import { ActionResult } from '~/types/store'
import { dispatch } from '~/stores/store'
import { copyShiftDays } from '../../../actions'
import { ShiftDayState, ShiftState } from '../../../types'
import './CopyShiftDayDrawer.less'

interface CopyShiftDayDrawerProps {
  visible: boolean
  shift?: ShiftState
  data?: ShiftDayState
  onClose: (action: 'saved' | 'deleted' | 'cancelled') => void
}

export const CopyShiftDayDrawer: FC<CopyShiftDayDrawerProps> = ({ visible, shift, data, onClose }) => {
  const [loading, setLoading] = useState(false)

  const sourceId = data?.id || ''
  const sourceDayCode = data?.dayCode
  const shiftId = shift?.id || ''

  const [targetDayCodes, setTargetDayCodes] = useState<string[]>([])
  const [dayCodes] = useSysOptions('att_shift_day_code')
  const options = useMemo(
    () =>
      Object.values(dayCodes)
        .filter(d => d?.key !== sourceDayCode)
        .map(d => ({ label: d!.value, value: d!.key })),
    [dayCodes, sourceDayCode]
  )

  useEffect(() => {
    if (visible) {
      setTargetDayCodes([])
    }
  }, [visible])

  const handleChange = useCallback((values: CheckboxValueType[]) => {
    setTargetDayCodes(values as string[])
  }, [])

  const handleOk = useCallback(async () => {
    if (!sourceId || !shiftId) return

    let result: ActionResult | undefined
    setLoading(true)
    try {
      result = await dispatch(copyShiftDays(shiftId, sourceId, targetDayCodes))
    } finally {
      setLoading(false)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose('saved')
    }
  }, [sourceId, shiftId, targetDayCodes, onClose])

  return (
    <DrawerForm
      open={visible}
      title="Copy shift day"
      onClose={() => onClose('cancelled')}
      okText="Copy"
      confirmLoading={loading}
      width={600}
      className="copy-shift-day-drawer"
      formId="form-copy-shift-day"
    >
      <Form id="form-copy-shift-day" onFinish={handleOk}>
        <Form.Item>
          <Checkbox.Group value={targetDayCodes} options={options} onChange={handleChange} />
        </Form.Item>
      </Form>
    </DrawerForm>
  )
}
