import { showError, showSuccess } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiUpdateLtProrationM } from '../api/lt-proration-m.api'
import { replaceLtProrationM } from '../reducers'
import { ILtProrationMMutate, LtProrationMState } from '../types'

export const updateLtProrationM = (leaveTypeId: string, data: LtProrationMState[]): ThunkResult<void> => {
  return async dispatch => {
    if (!leaveTypeId) return

    let submit: ILtProrationMMutate = {
      leaveTypeId,
      ltProrationM: data
    }
    const { result, status, errors, message, errorData } = await apiUpdateLtProrationM(leaveTypeId, submit)
    if (status) {
      dispatch(replaceLtProrationM({ leaveTypeId, data: result }))
      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
