import { request, getBaseUrl } from '~/utils/request'
import { LeaveImportState, LeaveImportValidateState, LeaveImportExecuteState } from '../types'

const baseUrl = getBaseUrl('/payroll-sg/leaveimport')

export const apiPrepareLeaveImport = (payRunId: string, upToDate: string) => {
  return request<LeaveImportState>('post', `${baseUrl}/prepare`, { payRunId, upToDate })
}

export const apiValidateLeaveImport = (payRunId: string, upToDate: string, leaveRecordDtlIds: string[]) =>
  request<LeaveImportValidateState>('post', `${baseUrl}/validate`, { payRunId, upToDate, leaveRecordDtlIds })

export const apiExecuteLeaveImport = (payRunId: string) =>
  request<LeaveImportExecuteState>('post', `${baseUrl}/execute`, { payRunId })
