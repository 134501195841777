import React, { FC, useState, useCallback, useEffect, ChangeEvent } from 'react'
import confirm from 'antd/lib/modal/confirm'
import { Form, Input } from '~/core-components'
import { Col, DrawerForm, Row } from '~/components'
import { dispatch } from '~/stores/store'
import { addFormula, deleteFormula, updateFormula } from '../../actions'
import { IFormula, FormulaState } from '../../types'
import { mapFormulaStateToFormula } from '../../types/formula.mapper'
import { ActionResult, Errors } from '~/types/store'
import { DrawerCloseReason } from '~/types/common'
import { useFocus } from '~/hooks/use-focus'

export interface MutateFormulaDrawerProps {
  visible: boolean
  data?: FormulaState
  onClose: (reason: DrawerCloseReason, data?: any) => void
}

const EMPTY_FORM_DATA: IFormula = {
  name: '',
  description: '',
  rateType: ''
}

export const MutateFormulaDrawer: FC<MutateFormulaDrawerProps> = ({
  visible,
  data,
  onClose
}: MutateFormulaDrawerProps) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<IFormula>(EMPTY_FORM_DATA)
  const [focusRef, setFocus] = useFocus(true)
  const [errors, setErrors] = useState<Errors>()

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  useEffect(() => {
    if (data) {
      const { name, description, rateType } = data
      setFormData({ name, description, rateType })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      const updated = { ...formData, ...updates }
      setFormData(updated)
    },
    [formData]
  )

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      if (data) {
        result = await dispatch(updateFormula(data.id, mapFormulaStateToFormula(data), formData))
      } else {
        result = await dispatch(addFormula(formData))
      }
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      if (typeof onClose === 'function') {
        if (data) {
          onClose('edit')
        } else {
          onClose('add', result?.result?.id)
        }
      }
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data, formData, onClose])

  const handleDelete = useCallback(
    (item: FormulaState | undefined) => {
      if (item) {
        const { id } = item
        confirm({
          title: 'Delete formula',
          content: 'Do you want to delete this formula?',
          onOk: async () => {
            const result: ActionResult | undefined = await dispatch(deleteFormula(id))
            if (result?.errors) {
              setErrors(result.errors)
            }

            if (!result?.errors) {
              typeof onClose === 'function' && onClose('delete')
            }
          },
          okText: 'Delete',
          okType: 'danger'
        })
      }
    },
    [onClose]
  )

  return (
    <DrawerForm
      open={visible}
      title={data ? 'Edit formula' : 'Add formula'}
      onClose={() => onClose('cancel')}
      confirmLoading={loading}
      width={500}
      showDelete={data ? true : false}
      onDelete={() => handleDelete(data)}
      formId="form-formula"
    >
      <Form id="form-formula" onFinish={handleOk}>
        <Row>
          <Col span={24}>
            <Form.Item label="Name" validateStatus={errors?.name ? 'error' : ''} help={errors?.name}>
              <Input
                ref={focusRef}
                value={formData.name}
                onChange={(value?: ChangeEvent<HTMLInputElement>) =>
                  handleFormDataChange({ name: value?.target.value })
                }
              ></Input>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Description"
              validateStatus={errors?.description ? 'error' : ''}
              help={errors?.description}
            >
              <Input
                value={formData.description}
                onChange={(value?: ChangeEvent<HTMLInputElement>) =>
                  handleFormDataChange({ description: value?.target.value })
                }
              ></Input>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}
