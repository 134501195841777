import { IJob, JobRowState } from './job.state'

export const mapJobStateToJob = (state: JobRowState): IJob => ({
  id: state.id,
  sysJobCode: state.sysJobCode,
  name: state.name,
  paramJsonb: state.paramJsonb,
  cronExpr: state.cronExpr,
  inactiveDate: state.inactiveDate,
  startDate: state.startDate,
  endDate: state.endDate,
  notifyEmail: state.notifyEmail,
  triggerJobId: state.triggerJobId
})
