import { showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiPublishPayRecord } from '../api/payrecord-sg.api'

export const publishPayRecord = (id: string): ThunkResult<void> => {
  return async () => {
    if (!id) return

    const { result, status, errors, message, errorData } = await apiPublishPayRecord(id)
    if (status) {
      if (result.emailStatus === 'error') {
        showError(result.emailMessage)
      }
      return result
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
