import React, { FC, useCallback, useEffect, useState } from 'react'
import { Form } from '~/core-components'
import { Col, Row } from '~/components'
import { EmailRecipients } from '~/components/EmailRecipients/EmailRecipients'
import { Delimiter } from '~/constants'
import { Errors } from '~/types/store'

interface FormData {
  members: string
}

interface ParamsMembersProps {
  paramJsonb: string
  onChange: (value: string) => void
  errors?: Errors
}

const EMPTY_FORM_DATA: FormData = {
  members: ''
}

export const ParamsMembers: FC<ParamsMembersProps> = ({ paramJsonb, onChange, errors }) => {
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)

  useEffect(() => {
    const data = JSON.parse(paramJsonb || '{}') as FormData
    setFormData({
      members: data.members
    })
  }, [paramJsonb])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      setFormData(formData => {
        const updated = { ...formData, ...updates }
        const value = JSON.stringify({
          members: updated.members
        })

        typeof onChange === 'function' && onChange(value)
        return updated
      })
    },
    [onChange]
  )

  return (
    <div className="params-members">
      <Row>
        <Col span={24}>
          <Form.Item label="Members" validateStatus={errors?.dataJsonb ? 'error' : ''} help={errors?.dataJsonb}>
            <EmailRecipients
              value={formData.members?.split(Delimiter.pipe)}
              onChange={(value?: string[]) => handleFormDataChange({ members: value?.join(Delimiter.pipe) })}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  )
}
