import { showSuccess, showError } from '~/utils'
import { apiApproveTimeLogByToken } from '../api/ss-time-log-task-token.api'
import { ThunkResult } from '~/types/store'

export const approveTimeLogByToken = (token: string, comment: string): ThunkResult<void> => {
  return async () => {
    const { status, result, errors, message, errorData } = await apiApproveTimeLogByToken(token, comment)

    if (status) {
      showSuccess('Approved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
