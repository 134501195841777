import { request, getBaseUrl } from '~/utils/request'
import { SysGroupingScreenState, SysGroupingState } from '../types'

const baseUrl = getBaseUrl('/employee/sysgrouping')

export const apiGetGroupingScreens = (screenCode: string) =>
  request<SysGroupingScreenState[]>('get', `${baseUrl}/${screenCode}/screen`)

export const apiGetGroupings = (screenCode: string) =>
  request<SysGroupingState[]>('get', `${baseUrl}/${screenCode}/grouping`)
