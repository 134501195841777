import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { SSUpcomingEventState } from '../../types'

export const selectUpcomingEvents = createSelector(
  (state: StoreState) => state.my.upcomingEvents,
  upcomingEventsState => {
    return Object.values(upcomingEventsState?.entities || {}) as SSUpcomingEventState[]
  }
)
