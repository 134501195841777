import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Dictionary } from '@reduxjs/toolkit'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { FetchStrategy } from '~/types/common'
import { SysWfEventTypeState } from '../types'
import { fetchSysWfEventTypes } from '../actions'

export const useSysWfEventTypes = (
  flowType: string,
  fetchStrategy: FetchStrategy = 'when-empty'
): [Dictionary<SysWfEventTypeState>, boolean] => {
  const eventTypes = useSelector((state: StoreState) => state.workflow.sysWfEventTypes[flowType]?.entities || {})
  const loading = useSelector((state: StoreState) => state.workflow.sysWfEventTypesLoading[flowType] || false)

  useEffect(() => {
    dispatch(fetchSysWfEventTypes(flowType, { strategy: fetchStrategy }))
  }, [flowType, fetchStrategy])

  return [eventTypes, loading]
}
