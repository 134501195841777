import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment-timezone'
import { useSelector } from 'react-redux'
import {
  Col,
  DocumentTitle,
  EmpKeyValues,
  PaySgKeyValues,
  PdfPasswordModal,
  PdfViewer,
  PeriodKeyValues,
  Row,
  SysOptions
} from '~/components'
import {
  Button,
  Checkbox,
  CheckboxChangeEvent,
  Collapse,
  Form,
  Input,
  PageHeader,
  Select,
  Space
} from '~/core-components'
import { useSysOptions } from '~/features/employee'
import { fetchPayRunsByPeriod, selectPayRunsByPeriod } from '~/features/payroll'
import { GroupingButton, RptScreen } from '~/features/grouping'
import { emptyGuid, ReconcileField, ReconcileType, ReconcileWith } from '~/constants'
import { REPORTS_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { Errors, StoreState } from '~/types/store'
import { downloadWithDom, getFileTimestamp, showError } from '~/utils'
import { IRptPayRecon, ReportCode } from '../../types'
import { useFirstReportTemplate, useReportTemplate } from '../../hooks'
import { refetchReportTemplates } from '../../reducers'
import { apiDownloadPayReconPdf } from '../../api/rpt-pay-recon.api'
import { RptDownloadBtn } from '../components/RptDownloadBtn'
import { ReportTemplate } from '../ReportTemplate/ReportTemplate'
import { RptSortingBtn } from '../components/RptSortingBtn'
import './RptPayRecon.less'

const routes = [
  {
    path: REPORTS_ROUTES.main,
    breadcrumbName: 'Reports'
  },
  {
    path: '',
    breadcrumbName: 'Payroll reconciliation report'
  }
]

interface RptPayReconForm extends IRptPayRecon {
  templateId: string
}

const currentPeriod = moment().format('YYYY-MM')

const SCREEN_CODE: RptScreen = 'rpt_payroll'
const REPORT_CODE: ReportCode = 'rpt_pay_recon'

const EMPTY_FORM_DATA: RptPayReconForm = {
  paymentMonth: currentPeriod,
  companyId: '',
  currPayRunIds: [],
  reconcileWith: ReconcileWith.prevMonth,
  prevPayRunIds: [],
  reconcileType: ReconcileType.field,
  reconcileFields: [],
  payItemIds: [],
  sorting: 'employee_name',
  groupingCodes: [],
  pageBreaks: [],
  suppressEmployee: false,
  suppressNoDiff: true,
  reportTitle: '',
  notes: '',
  templateId: emptyGuid
}

export const RptPayRecon: FC = () => {
  const [formData, setFormData] = useState<RptPayReconForm>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const companyIds = useSelector((state: StoreState) => state.master.keyvalues['company']?.ids)
  const [previewUrl, setPreviewUrl] = useState<string>('')
  const [previewing, setPreviewing] = useState(false)
  const [activeKey, setActiveKey] = useState(0)
  const [pdfModal, setPdfModal] = useState(false)
  const [reconcileWithOptions] = useSysOptions('reconcile_with')

  const [firstTemplate] = useFirstReportTemplate('payroll-sg', REPORT_CODE)
  const firstTemplateId = firstTemplate?.id || ''
  const [templateId, setTemplateId] = useState<string>()
  const [template] = useReportTemplate('payroll-sg', REPORT_CODE, templateId)

  const currPeriod = formData.paymentMonth
  const reconcileWith = formData.reconcileWith
  const prevPeriod = useMemo(
    () =>
      moment(currPeriod)
        .add(reconcileWith === 'prev_mth' ? -1 : 0, 'month')
        .format('YYYY-MM'),
    [currPeriod, reconcileWith]
  )
  const currPayRuns = useSelector(selectPayRunsByPeriod)([currPeriod])
  const prevPayRuns = useSelector(selectPayRunsByPeriod)([prevPeriod])

  useEffect(() => {
    const payPeriods = [currPeriod, prevPeriod]
    dispatch(fetchPayRunsByPeriod(payPeriods, { strategy: 'when-empty' }))
  }, [currPeriod, prevPeriod])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    if (updates.companyId) {
      updates.payGroupIds = []
    }

    setFormData(data => ({ ...data, ...updates }))
  }, [])

  useEffect(() => {
    if (!templateId && firstTemplateId) {
      setTemplateId(firstTemplateId)
    }

    if (template) {
      const saved = JSON.parse(template.dataJsonb) as RptPayReconForm
      saved.templateId = templateId || ''
      setFormData(formData => ({ ...formData, ...saved }))
    }
  }, [firstTemplateId, templateId, template])

  useEffect(() => {
    setFormData(formData => {
      if (!formData.companyId) {
        if (companyIds && companyIds.length > 0) {
          return { ...formData, companyId: companyIds[0].toString(), payGroupIds: [] }
        }
      }
      return formData
    })
  }, [companyIds])

  const handlePreview = useCallback(async () => {
    setPreviewing(true)
    setErrors(undefined)

    try {
      const { status, result, errors, message, errorData } = await apiDownloadPayReconPdf(formData)

      if (status) {
        const url = URL.createObjectURL(result)
        setPreviewUrl(url)
        dispatch(refetchReportTemplates())
      } else {
        console.error('Error while downloading', errors)
        setErrors(errors)
        showError(message, errorData)
      }
    } finally {
      setPreviewing(false)
    }
  }, [formData])

  const handleDownloadPdf = useCallback(
    async (password?: string) => {
      setErrors(undefined)

      const { status, result, errors, message, errorData } = await apiDownloadPayReconPdf({
        ...formData,
        password
      })

      if (status) {
        const fileName = `payroll_reconciliation_report_${formData.paymentMonth}_${getFileTimestamp()}.pdf`
        downloadWithDom(result, fileName)
        dispatch(refetchReportTemplates())
      } else {
        console.error('Error while downloading', errors)
        setErrors(errors)
        showError(message, errorData)
      }
    },
    [formData]
  )

  const handleOpenPdfModal = useCallback(async () => {
    setPdfModal(true)
  }, [])

  const handleClosePdfModal = useCallback(() => {
    setPdfModal(false)
  }, [])

  const handleCollapse = useCallback(async () => {
    if (!activeKey) {
      setActiveKey(1)
    } else {
      setActiveKey(0)
    }
  }, [activeKey])

  const handleTemplateChange = useCallback((templateId?: string) => {
    setTemplateId(templateId)
  }, [])

  return (
    <div id="rpt-pay-recon" className="rpt-pay-recon">
      <DocumentTitle title="Payroll Reconciliation Report" />
      <PageHeader
        title="Payroll reconciliation report"
        containerId="rpt-pay-recon"
        breadcrumb={{ routes }}
        extra={
          <ReportTemplate<IRptPayRecon>
            basePath="payroll-sg"
            reportCode={REPORT_CODE}
            templateId={templateId}
            templateData={formData}
            size="small"
            onChange={handleTemplateChange}
          />
        }
      />
      <Form className="rpt-pay-recon__form" layout="horizontal" labelCol={{ flex: '135px' }}>
        <Row gutter={30}>
          <Col flex="280px">
            <Form.Item
              label="Payment month"
              validateStatus={errors?.paymentMonth ? 'error' : ''}
              help={errors?.paymentMonth}
            >
              <PeriodKeyValues
                allowClear={false}
                value={formData.paymentMonth}
                startMonth={currentPeriod}
                noOfMonthBefore={-24}
                noOfMonthAfter={2}
                onChange={(value: string) => {
                  handleFormDataChange({ paymentMonth: value })
                }}
              />
            </Form.Item>
          </Col>
          <Col flex="260px">
            <Form.Item
              label="Company"
              labelCol={{ flex: '80px' }}
              validateStatus={errors?.companyId ? 'error' : ''}
              help={errors?.companyId}
            >
              <EmpKeyValues
                id="company"
                allowClear={false}
                value={formData.companyId}
                onChange={(companyId?: string) => handleFormDataChange({ companyId })}
              />
            </Form.Item>
          </Col>
          <Col flex="1"></Col>
          <Col flex="none">
            <Space>
              <RptSortingBtn
                sorting={formData.sorting}
                onSelect={(sorting: string) => handleFormDataChange({ sorting })}
              />
              <GroupingButton
                screenCode={SCREEN_CODE}
                groupingCodes={formData.groupingCodes || []}
                pageBreaks={formData.pageBreaks || []}
                onSelect={(groupingCodes: string[], pageBreaks: string[]) =>
                  handleFormDataChange({ groupingCodes, pageBreaks })
                }
              />
              <Button className="btn-more-settings" type={activeKey ? 'primary' : 'default'} onClick={handleCollapse}>
                <i className="fal fa-gear" />
              </Button>
              <Button type="primary" onClick={handlePreview} loading={previewing}>
                Preview
              </Button>
              <RptDownloadBtn onDownloadPdf={handleDownloadPdf} onDownloadSecurePdf={handleOpenPdfModal} />
            </Space>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col flex="1">
            <Form.Item
              label="Payroll runs"
              validateStatus={errors?.currPayRunIds ? 'error' : ''}
              help={errors?.currPayRunIds}
            >
              <Select
                mode="multiple"
                maxTagCount={1}
                value={formData.currPayRunIds || []}
                showSearch
                showArrow
                suffixIcon={<i className="fal fa-angle-down" />}
                placeholder="All current month payroll runs"
                filterOption={(input, option) =>
                  option?.display?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={(value: string[]) => handleFormDataChange({ currPayRunIds: value })}
              >
                {currPayRuns.map(pr => (
                  <Select.Option key={pr.id} value={pr.id} display={pr.description}>
                    {pr.description}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col flex="1">
            <Form.Item label="Reconcile with">
              <Row gutter={8}>
                <Col flex="195px">
                  <SysOptions
                    type="reconcile_with"
                    allowClear={false}
                    value={formData.reconcileWith}
                    onChange={(reconcileWith: string) => handleFormDataChange({ reconcileWith, prevPayRunIds: [] })}
                  />
                </Col>
                <Col flex="1">
                  <Select
                    mode="multiple"
                    maxTagCount={1}
                    value={formData.prevPayRunIds || []}
                    showSearch
                    showArrow
                    suffixIcon={<i className="fal fa-angle-down" />}
                    placeholder={`All ${
                      reconcileWithOptions[formData.reconcileWith]?.value?.toLowerCase() || ''
                    } payroll runs`}
                    filterOption={(input, option) =>
                      option?.display?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(value: string[]) => handleFormDataChange({ prevPayRunIds: value })}
                  >
                    {prevPayRuns.map(pr => (
                      <Select.Option key={pr.id} value={pr.id} display={pr.description}>
                        {pr.description}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col flex="350px">
            <Form.Item
              label="Reconciliation type"
              validateStatus={errors?.reconcileType ? 'error' : ''}
              help={errors?.reconcileType}
            >
              <SysOptions
                type="reconcile_type"
                allowClear={false}
                value={formData.reconcileType}
                onChange={(reconcileType: string) => handleFormDataChange({ reconcileType })}
              />
            </Form.Item>
          </Col>
          <Col flex="1">
            <Form.Item
              label="Reconciliation items"
              validateStatus={errors?.reconcileFields ? 'error' : ''}
              help={errors?.reconcileFields}
            >
              <SysOptions
                type="reconcile_field"
                mode="multiple"
                value={formData.reconcileFields || []}
                placeholder="All reconciliation items"
                onChange={(reconcileFields: string[]) => handleFormDataChange({ reconcileFields })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row hidden={!formData.reconcileFields?.includes(ReconcileField.payItem)}>
          <Col flex="1">
            <Form.Item
              label="Payroll items"
              validateStatus={errors?.payItemIds ? 'error' : ''}
              help={errors?.payItemIds}
            >
              <PaySgKeyValues
                id="payItem"
                mode="multiple"
                placeholder="All payroll items"
                value={formData.payItemIds || []}
                onChange={(payItemIds: string[]) => handleFormDataChange({ payItemIds })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col flex="1">
            <Collapse className="rpt-pay-recon__more-settings" activeKey={activeKey} onChange={handleCollapse} noStyle>
              <Collapse.Panel key="1" header={null} showArrow={false}>
                <Row gutter={30}>
                  <Col flex="1">
                    <Form.Item label="Options">
                      <Checkbox
                        checked={formData.suppressEmployee}
                        onChange={(event: CheckboxChangeEvent) =>
                          handleFormDataChange({ suppressEmployee: event.target.checked })
                        }
                      >
                        Suppress employee
                      </Checkbox>
                      <Checkbox
                        checked={formData.suppressNoDiff}
                        onChange={(event: CheckboxChangeEvent) =>
                          handleFormDataChange({ suppressNoDiff: event.target.checked })
                        }
                      >
                        Suppress records with no change
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={30}>
                  <Col flex="490px">
                    <Form.Item label="Report title">
                      <Input
                        value={formData.reportTitle}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                          handleFormDataChange({ reportTitle: event.target.value })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={30}>
                  <Col flex="1">
                    <Form.Item label="Custom notes">
                      <Input.TextArea
                        rows={3}
                        value={formData.notes}
                        onChange={(value?: ChangeEvent<HTMLTextAreaElement>) =>
                          handleFormDataChange({ notes: value?.target.value })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Collapse.Panel>
            </Collapse>
          </Col>
        </Row>
      </Form>
      {previewUrl && (
        <div className="rpt-pay-recon__pdf-viewer">
          <Row>
            <Col>
              <PdfViewer file={previewUrl} layout="portrait" scale={1.5} />
            </Col>
          </Row>
        </div>
      )}
      <PdfPasswordModal open={pdfModal} onApply={handleDownloadPdf} onClose={handleClosePdfModal} />
    </div>
  )
}
