import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { FetchStrategy } from '~/types/common'
import { ShiftState } from '../types'
import { fetchShifts } from '../actions'

export const useShift = (
  id: string,
  fetchStrategy: FetchStrategy = 'when-empty'
): [ShiftState | undefined, boolean] => {
  const shift = useSelector((state: StoreState) => state.attendance.shifts?.entities[id])
  const loading = useSelector((state: StoreState) => state.attendance.shiftsLoading)

  useEffect(() => {
    dispatch(fetchShifts({ strategy: fetchStrategy }))
  }, [fetchStrategy])

  return [shift, loading]
}
