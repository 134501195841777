import { replaceMyCalendarLeaveEvents, setMyCalendarLeaveEventsLoading } from '../../reducers'
import { apiGetMyCalendarLeaveEvents } from '../../api/ss-leave-record-calendar.api'
import { ThunkResult } from '~/types/store'
import { DateRange } from '~/types/common'

export const fetchMyCalendarLeaveEvents = (viewAs: string, dateRange: DateRange): ThunkResult<void> => {
  return async dispatch => {
    if (!viewAs || !dateRange.startDate || !dateRange.endDate) return

    try {
      dispatch(setMyCalendarLeaveEventsLoading(true))
      const { result, status } = await apiGetMyCalendarLeaveEvents(viewAs, dateRange)
      if (status) dispatch(replaceMyCalendarLeaveEvents(result))
    } finally {
      dispatch(setMyCalendarLeaveEventsLoading(false))
    }
  }
}
