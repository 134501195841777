import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react'
import debounce from 'lodash/debounce'
import classNames from 'classnames'
import { SecondaryText, Select, SelectProps, Space, Tag, TagType } from '~/core-components'
import { CustomTagProps } from '~/core-components/Select/Select'
import { ApprovalTargetState } from '~/features/employee'
import { apiGetTargets, apiSearchTargets } from '~/features/employee/api/approval-target.api'
import { getBaseUrl, isValidEmail, showError } from '~/utils'
import { Delimiter, ApprovalTargetSource, emptyGuid } from '~/constants'
import { PersonAvatar } from '../Person'
import './ApprovalTargets.less'

export interface ApprovalTargetsProps extends SelectProps {
  onChange?: (value?: string[]) => void
  excludeSource?: string[]
  showAsTag?: boolean
}

const DEBOUNCE_TIMEOUT = 800
const baseUrl = getBaseUrl('/filestore')

const ApprovalTargetsInternal = (
  { onChange, excludeSource, readOnly, disabled, showAsTag, className, ...props }: ApprovalTargetsProps,
  ref: React.Ref<any>
) => {
  const [data, setData] = useState<ApprovalTargetState[]>([])
  const [options, setOptions] = useState<ApprovalTargetState[]>([])
  const [fetching, setFetching] = useState(false)
  const [initializing, setInitializing] = useState(false)
  const fetchRef = React.useRef(0)
  const emailExpr = props.value?.join(Delimiter.pipe)
  const classes = classNames(
    'approval-targets',
    { [`${className}`]: className },
    { 'approval-targets--show-as-tag': showAsTag }
  )

  const dataOptions = useMemo(() => {
    if (!options) return []

    let result = options.concat(data?.filter(d => options && !options?.some(s => s.id === d.id))).sort((a, b) => {
      if (a!.source === ApprovalTargetSource.role && b!.source !== ApprovalTargetSource.role) {
        return -1
      } else if (a!.source !== ApprovalTargetSource.role && b!.source === ApprovalTargetSource.role) {
        return 1
      }

      return a!.display.localeCompare(b!.display)
    })

    return result
  }, [options, data])

  useEffect(() => {
    const initSelected = async (emailExpr: string) => {
      try {
        setInitializing(true)
        const result = await apiGetTargets(emailExpr, excludeSource)
        setOptions(result?.result || [])
      } finally {
        setInitializing(false)
      }
    }

    if (emailExpr) {
      initSelected(emailExpr)
    } else {
      initSelected('')
    }
  }, [emailExpr, excludeSource])

  const debounceFetcher = React.useMemo(() => {
    const loadOptions = async (search: string) => {
      fetchRef.current += 1
      const fetchId = fetchRef.current
      setData([])

      try {
        setFetching(true)
        if (search) {
          const result = await apiSearchTargets(search, excludeSource)

          if (fetchId === fetchRef.current) {
            setData(result.result)
          }
        }
      } finally {
        if (fetchId === fetchRef.current) setFetching(false)
      }
    }

    return debounce(loadOptions, DEBOUNCE_TIMEOUT)
  }, [excludeSource])

  const handleValueEmailExpr = useCallback((emailExpr: string, id: string) => {
    let valueIds: string[] = []
    if (emailExpr) {
      valueIds = emailExpr.split(Delimiter.pipe)
    }

    if (!valueIds.includes(id)) {
      valueIds.push(id)
    }

    return valueIds
  }, [])

  const handleDeselect = useCallback(
    (value: string) => {
      setOptions(option => {
        const updates = option.filter(s => s.id !== value)
        const valueEmailExpr = handleValueEmailExpr(emailExpr, value).filter(s => s !== value)
        if (valueEmailExpr.length === 0) {
          typeof onChange === 'function' && onChange(undefined)
        } else {
          typeof onChange === 'function' && onChange(valueEmailExpr)
        }

        return updates
      })
    },
    [onChange, emailExpr, handleValueEmailExpr]
  )

  const handleSelect = useCallback(
    (value: string) => {
      const item = data?.find(d => d.id === value)
      if (item) {
        setOptions(option => {
          let updates: ApprovalTargetState[] = option
          if (!option.some(s => s.id === item.id)) {
            updates = [...(option || []), item]
          }

          typeof onChange === 'function' && onChange(handleValueEmailExpr(emailExpr, item.id))
          return updates
        })
      } else {
        const source = value.split(Delimiter.hash)[0]
        if (source === ApprovalTargetSource.role) {
          typeof onChange === 'function' && onChange(handleValueEmailExpr(emailExpr, value))
        } else {
          if (isValidEmail(value)) {
            setOptions(option => {
              const source = ApprovalTargetSource.email
              const id = `${source}${Delimiter.hash}${value}`
              let updates: ApprovalTargetState[] = option
              if (!option.some(s => s.id === id)) {
                updates = [
                  ...(option || []),
                  {
                    id,
                    source,
                    value,
                    display: value,
                    photoId: emptyGuid,
                    description: ''
                  }
                ]
              }

              typeof onChange === 'function' && onChange(handleValueEmailExpr(emailExpr, id))
              return updates
            })
          } else {
            showError(`Invalid entry: ${value}. Please select from list or enter a valid email address.`)
          }
        }
      }
    },
    [data, onChange, emailExpr, handleValueEmailExpr]
  )

  const handleBlur = useCallback(() => {
    setData([])
  }, [])

  const getTagType = useCallback(
    (source: string): TagType => {
      if (readOnly) return 'white'

      if (source === ApprovalTargetSource.role) {
        return 'primary'
      }

      return 'original'
    },
    [readOnly]
  )

  const tagRender = useCallback(
    (props: CustomTagProps) => {
      const { label, value, closable, onClose } = props
      const source = value.toString().split(Delimiter.hash)[0]

      return (
        <Tag
          type={getTagType(source)}
          closable={!readOnly && closable}
          onClose={onClose}
          style={!showAsTag ? { fontSize: 14 } : {}}
        >
          {label}
        </Tag>
      )
    },
    [getTagType, readOnly, showAsTag]
  )

  return (
    <Select
      ref={ref}
      mode="tags"
      showSearch
      allowClear={false}
      showArrow
      onSearch={debounceFetcher}
      onSelect={handleSelect}
      onDeselect={handleDeselect}
      onBlur={handleBlur}
      filterOption={false}
      placeholder="Type and search for approver"
      loading={fetching || initializing}
      tagRender={tagRender}
      optionLabelProp="title"
      readOnly={readOnly}
      disabled={disabled || showAsTag}
      className={classes}
      {...props}
    >
      {dataOptions.map(d => (
        <Select.Option key={d.value} value={d.id} title={d.display}>
          {d.source === ApprovalTargetSource.employee ? (
            <div className="approval-targets__person">
              <PersonAvatar
                photo={
                  d.photoId && d.photoId !== emptyGuid ? `${baseUrl}/file/${d.photoId}/thumbnailphoto/36` : undefined
                }
                size={36}
                loading={fetching || initializing}
              />
              <div className="approval-targets__person__info">
                <h1>{d.display}</h1>
                <Space>{d.description && <SecondaryText>{d.description}</SecondaryText>}</Space>
              </div>
            </div>
          ) : (
            <>
              {d.display}
              {d.source === ApprovalTargetSource.role && (
                <Tag type={getTagType(d.source)} style={{ marginLeft: 10 }}>
                  {d.source}
                </Tag>
              )}
            </>
          )}
        </Select.Option>
      ))}
    </Select>
  )
}

export const ApprovalTargets = forwardRef(ApprovalTargetsInternal)
