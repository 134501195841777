import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { KeyValue } from '~/types/common'
import { MasterName, MasterState } from '../types'

const createMasterKeyvaluesSelector = (masterName: MasterName) => {
  return createSelector(
    (state: StoreState) => state.master.masters[masterName],
    mastersState => {
      const masters = Object.values(mastersState?.entities || {})
      const entities = masters.reduce((acc: { [id: string]: KeyValue }, current?: MasterState) => {
        if (current) {
          acc[current.id] = { key: current.id, value: current.name, inactiveDate: current.inactiveDate } as KeyValue
        }
        return acc
      }, {})

      return entities
    }
  )
}

const createMasterKeyvaluesLoadingSelector = (masterName: MasterName) => {
  return createSelector(
    (state: StoreState) => state.master.mastersLoading[masterName],
    mastersLoadingState => mastersLoadingState
  )
}

export const selectCalendarKeyvalues = createMasterKeyvaluesSelector('calendar')
export const selectCalendarKeyvaluesLoading = createMasterKeyvaluesLoadingSelector('calendar')
export const selectCategoryKeyvalues = createMasterKeyvaluesSelector('category')
export const selectCategoryKeyvaluesLoading = createMasterKeyvaluesLoadingSelector('category')
export const selectCostCentreKeyvalues = createMasterKeyvaluesSelector('costCentre')
export const selectCostCentreKeyvaluesLoading = createMasterKeyvaluesLoadingSelector('costCentre')
export const selectEmploymentTypeKeyvalues = createMasterKeyvaluesSelector('employmentType')
export const selectEmploymentTypeKeyvaluesLoading = createMasterKeyvaluesLoadingSelector('employmentType')
export const selectDepartmentKeyvalues = createMasterKeyvaluesSelector('department')
export const selectDepartmentKeyvaluesLoading = createMasterKeyvaluesLoadingSelector('department')
export const selectDivisionKeyvalues = createMasterKeyvaluesSelector('division')
export const selectDivisionKeyvaluesLoading = createMasterKeyvaluesLoadingSelector('division')
export const selectDoctypeKeyvalues = createMasterKeyvaluesSelector('docType')
export const selectDoctypeKeyvaluesLoading = createMasterKeyvaluesLoadingSelector('docType')
export const selectGrpKeyvalues = createMasterKeyvaluesSelector('grp')
export const selectGrpKeyvaluesLoading = createMasterKeyvaluesLoadingSelector('grp')
export const selectHolidayKeyvalues = createMasterKeyvaluesSelector('holiday')
export const selectHolidayKeyvaluesLoading = createMasterKeyvaluesLoadingSelector('holiday')
export const selectJobKeyvalues = createMasterKeyvaluesSelector('job')
export const selectJobKeyvaluesLoading = createMasterKeyvaluesLoadingSelector('job')
export const selectLeaveGroupKeyvalues = createMasterKeyvaluesSelector('leaveGroup')
export const selectLeaveGroupKeyvaluesLoading = createMasterKeyvaluesLoadingSelector('leaveGroup')
export const selectPayGroupKeyvalues = createMasterKeyvaluesSelector('payGroup')
export const selectPayGroupKeyvaluesLoading = createMasterKeyvaluesLoadingSelector('payGroup')
export const selectRaceKeyvalues = createMasterKeyvaluesSelector('race')
export const selectRaceKeyvaluesLoading = createMasterKeyvaluesLoadingSelector('race')
export const selectReligionKeyvalues = createMasterKeyvaluesSelector('religion')
export const selectReligionKeyvaluesLoading = createMasterKeyvaluesLoadingSelector('religion')
export const selectReasonKeyvalues = createMasterKeyvaluesSelector('reason')
export const selectReasonKeyvaluesLoading = createMasterKeyvaluesLoadingSelector('reason')
export const selectSectionKeyvalues = createMasterKeyvaluesSelector('section')
export const selectSectionKeyvaluesLoading = createMasterKeyvaluesLoadingSelector('section')
export const selectTeamKeyvalues = createMasterKeyvaluesSelector('team')
export const selectTeamKeyvaluesLoading = createMasterKeyvaluesLoadingSelector('team')
export const selectQualificationKeyvalues = createMasterKeyvaluesSelector('qualification')
export const selectQualificationKeyvaluesLoading = createMasterKeyvaluesLoadingSelector('qualification')
export const selectSalaryGradeKeyvalues = createMasterKeyvaluesSelector('salaryGrade')
export const selectSalaryGradeKeyvaluesLoading = createMasterKeyvaluesLoadingSelector('salaryGrade')

export const selectCompanyKeyvalues = createSelector(
  (state: StoreState) => state.master.companies,
  companiesState => {
    const companies = Object.values(companiesState?.entities || {})
    const entities = companies.reduce((acc: { [id: string]: KeyValue }, current?: MasterState) => {
      if (current) {
        acc[current.id] = { key: current.id, value: current.name, inactiveDate: current.inactiveDate } as KeyValue
      }
      return acc
    }, {})

    return entities
  }
)

export const selectOfficeKeyvalues = createSelector(
  (state: StoreState) => state.master.offices,
  officesState => {
    const offices = Object.values(officesState?.entities || {})
    const entities = offices.reduce((acc: { [id: string]: KeyValue }, current?: MasterState) => {
      if (current) {
        acc[current.id] = { key: current.id, value: current.name, inactiveDate: current.inactiveDate } as KeyValue
      }
      return acc
    }, {})

    return entities
  }
)
