import React from 'react'
import memoize from 'lodash/memoize'
import { AmountDisplay } from '~/components'
import { ClaCurrencyCode } from '~/constants'

const REQUIRE_CONFIRM_TEXT_MAP: { [type: string]: { [mth: string]: string } } = {
  n: {
    '>0': 'All employees who have fulfiled min. {0} service month(s)'
  },
  y1: {
    '0': 'Confirmed employees',
    '>0': 'Either confirmed employees or those who have fulfilled min. {0} service month(s)'
  },
  y2: {
    '0': 'Confirmed employees',
    '>0': 'Only for confirmed employees who have min. {0} service month(s)'
  }
}

export const formatClaimLimit = (
  currencyCode: string,
  requireConfirm: string | undefined,
  requireMonths: number,
  monthlyLimit: number,
  dailyLimit: number,
  perTimeAmount: number,
  perTimePercentage: number,
  perTimeEmAmount: number,
  maxTimes: number,
  maxTimesBasis?: string
): React.ReactNode[] => {
  let limitText: React.ReactNode[] = []
  currencyCode = currencyCode || ClaCurrencyCode.sgd

  if (requireConfirm) {
    if (requireMonths > 0) {
      limitText.push(REQUIRE_CONFIRM_TEXT_MAP[requireConfirm]['>0'].replace('{0}', `${requireMonths}`))
    } else {
      if (REQUIRE_CONFIRM_TEXT_MAP[requireConfirm]['0']) {
        limitText.push(REQUIRE_CONFIRM_TEXT_MAP[requireConfirm]['0'])
      }
    }
  }

  if (monthlyLimit > 0) {
    limitText.push(
      <>
        Monthly <AmountDisplay size="small" symbol={currencyCode} value={monthlyLimit} />
      </>
    )
  }
  if (dailyLimit > 0) {
    limitText.push(
      <>
        Daily <AmountDisplay size="small" symbol={currencyCode} value={dailyLimit} />
      </>
    )
  }

  if (perTimeAmount > 0) {
    limitText.push(
      <>
        Per time maximum claimable <AmountDisplay size="small" symbol={currencyCode} value={perTimeAmount} />
      </>
    )
  } else if (perTimePercentage > 0) {
    limitText.push(`Per time maximum claimable ${perTimePercentage}%`)
  } else if (perTimeEmAmount > 0) {
    limitText.push(`Per time maximum payable by employee ${perTimeEmAmount}`)
  }

  if (maxTimes > 0 && maxTimesBasis) {
    limitText.push(`Maximum ${maxTimes} ${maxTimesBasis.toLowerCase()}`)
  }

  return limitText
}

export const getTaxAmountLabel = memoize(
  (currencyCode?: string, suffix?: string) => {
    if (currencyCode === ClaCurrencyCode.sgd) {
      return suffix ? ['GST', suffix].join(' ') : 'GST'
    }
    return suffix ? ['Tax', suffix].join(' ') : 'Tax'
  },
  (currencyCode?: string, suffix?: string) => `${currencyCode}|${suffix}`
)

export const getInclusiveTaxLabel = memoize(
  (currencyCode?: string, prefix?: string) => {
    if (currencyCode === ClaCurrencyCode.sgd) {
      return [prefix, 'GST'].join(' ')
    }
    return [prefix, 'tax'].join(' ')
  },
  (currencyCode?: string, prefix?: string) => `${currencyCode}|${prefix}`
)

export const getTaxTooltip = memoize((currencyCode?: string) => {
  if (currencyCode === ClaCurrencyCode.sgd) {
    return 'If your expense includes GST, ensure that the amount entered here is inclusive of GST'
  }
  return 'If your expense includes tax, ensure that the amount entered here is inclusive of tax'
})
