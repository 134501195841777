import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { RuleState } from '../types'

export const selectRules = createSelector(
  (state: StoreState) => state.rule.rules,
  (state: StoreState) => state.rule.sysRuleCriteria,
  (rulesState, sysRuleCriteriaState) =>
    memoize((referenceId: string): RuleState[] => {
      const rules = (Object.values(rulesState[referenceId]?.entities || {}) as RuleState[]).sort((a, b) => {
        const aCriteria = sysRuleCriteriaState.entities[a.ruleCriteriaId]
        const bCriteria = sysRuleCriteriaState.entities[b.ruleCriteriaId]
        return (aCriteria?.name || '').localeCompare(bCriteria?.name || '')
      })
      return rules
    })
)
