import { request, getBaseUrl } from '~/utils/request'
import { PayWarningState, PayWarningVerifyResponse } from '../types'

const baseUrl = getBaseUrl('/payroll-sg/payroll')

export const apiProcessPayroll = (payRunId: string, employeeScope: 'existing' | 'all', employeeIds: string[]) =>
  request<void>('post', `${baseUrl}/process`, { payRunId, employeeScope, employeeIds })

export const apiProcessWarning = (payRunId: string) =>
  request<PayWarningState[]>('post', `${baseUrl}/warning`, { payRunId })

export const apiGetWarnings = (payRunId: string) => request<PayWarningState[]>('get', `${baseUrl}/warning/${payRunId}`)

export const apiVerifyPayWarning = (id: string) =>
  request<PayWarningVerifyResponse>('post', `${baseUrl}/warning/${id}/verify`)

export const apiUnverifyPayWarning = (id: string) => request<void>('post', `${baseUrl}/warning/${id}/unverify`)

export const apiCreatePaySnapshot = (payRunId: string) => request('post', `${baseUrl}/snapshot`, { payRunId })

export const apiComparePaySnapshot = (payRunId: string) => request('post', `${baseUrl}/snapshot/compare`, { payRunId })
