import React, { FC, useCallback, useState } from 'react'
import { Button } from '~/core-components'
import './YtdEmptyForm.less'

interface YtdEmptyFormProps {
  onCreate: () => Promise<void>
}

export const YtdEmptyForm: FC<YtdEmptyFormProps> = ({ children, onCreate }) => {
  const [loading, setLoading] = useState(false)

  const handleCreate = useCallback(async () => {
    try {
      setLoading(true)
      typeof onCreate === 'function' && (await onCreate())
    } finally {
      setLoading(false)
    }
  }, [onCreate])

  return (
    <div className="ytd-empty-form">
      <div className="ytd-empty-form__body">{children}</div>
      <div className="ytd-empty-form__action">
        <Button type="primary" onClick={handleCreate} loading={loading}>
          Create
        </Button>
      </div>
    </div>
  )
}
