import { Ref, useCallback, useEffect, useRef } from 'react'

export const useFocus = (isFocus: boolean): [Ref<any>, () => void] => {
  const focusRef = useRef<any>(null)

  const setFocus = useCallback(() => {
    if (focusRef.current != null) {
      typeof focusRef.current.select === 'function' && focusRef.current.select()
      focusRef.current.focus()
    }
  }, [focusRef])

  useEffect(() => {
    if (isFocus) setFocus()
  }, [isFocus, setFocus])

  return [focusRef, setFocus]
}
