import { Pagination, Search } from '~/types/common'
import { getBaseUrl, request, timeout } from '~/utils/request'
import {
  IOedSubmissionNew,
  OedLogState,
  OedRecordViewState,
  OedSubmissionState,
  OedSubmissionViewState,
  OedSummaryState,
  SubmitOedRequest,
  SubmitOedResponse
} from '../types'

const baseUrl = getBaseUrl('/employee/rptoedsubmission')

export const apiGetOedSubmissionView = (viewId: string, params: Pagination & Search) =>
  request<OedSubmissionViewState>('get', `${baseUrl}/view/${viewId}`, params)

export const apiGetOedSubmissionViewItem = (id: string) =>
  request<OedSubmissionState>('get', `${baseUrl}/${id}/viewitem`)

export const apiGetOedSummary = (id: string) => request<OedSummaryState>('get', `${baseUrl}/${id}/summary`)

export const apiAddOedSubmission = (oedSubmission: IOedSubmissionNew) =>
  request<{ id: string; status: string }>('post', baseUrl, oedSubmission)

export const apiDeleteOedSubmission = (id: string) => request<void>('delete', `${baseUrl}/${id}`)

export const apiGetOedRecordView = (id: string, viewId: string, params: Pagination & Search) =>
  request<OedRecordViewState>('get', `${baseUrl}/${id}/record/view/${viewId}`, params)

export const apiGetOedRecordExcel = (id: string) =>
  request('get', `${baseUrl}/${id}/record/excel`, undefined, { responseType: 'blob', timeout })

export const apiRefreshOedRecords = (id: string) => request<void>('get', `${baseUrl}/${id}/record/refresh`)

export const apiSubmitOed = (params: SubmitOedRequest) =>
  request<SubmitOedResponse>('post', `${baseUrl}/submit`, params)

export const apiDownloadSubmissionOedData = (params: SubmitOedRequest) =>
  request<any>('post', `${baseUrl}/submit/download`, params)

export const apiGetOedLogs = (id: string) => request<OedLogState[]>('get', `${baseUrl}/${id}/log`)
