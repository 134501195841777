import { showSuccess, showError } from '~/utils'
import { removeMyTimeLogTask } from '../reducers'
import { apiRejectTimeLog } from '../api/ss-time-log-task.api'
import { ThunkResult } from '~/types/store'

export const rejectTimeLog = (id: string, comment: string): ThunkResult<void> => {
  return async dispatch => {
    const { status, result, errors, message, errorData } = await apiRejectTimeLog(id, comment)
    if (status) {
      dispatch(removeMyTimeLogTask(id))
      showSuccess('Rejected')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
