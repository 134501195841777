import { showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiPrepareNextEntitlement } from '../api/leave-closing.api'
import { replaceNextEntitlementResult } from '../reducers'

export const prepareNextEntitlement = (periodCode: string, leaveTypeIds: string[]): ThunkResult<void> => {
  return async dispatch => {
    const { status, result, errors, message, errorData } = await apiPrepareNextEntitlement({ periodCode, leaveTypeIds })
    if (status) {
      dispatch(replaceNextEntitlementResult(result))
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors, result }
  }
}
