import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { LoadingOutlined } from '@ant-design/icons'
import moment from 'moment-timezone'
import classNames from 'classnames'
import padStart from 'lodash/padStart'
import {
  Button,
  Card,
  ColumnsType,
  ColumnType,
  Dropdown,
  Link,
  ModalTristate,
  Space,
  Table,
  Tag,
  Tooltip
} from '~/core-components'
import { Person, SearchInput } from '~/components'
import { Screen, ViewCriteria, ViewCriteriaSimple, updateViewCriteria, useFirstView } from '~/features/selection'
import { EmSelectionDrawer } from '~/features/employee'
import { fetchMaster, fetchPayItems, PayGroupState, selectMasterById } from '~/features/master'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { EMP_ROUTES, PAY_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { Pagination } from '~/types/common'
import { formatMoney, getBaseUrl } from '~/utils'
import { PayRunStatus } from '~/constants'
import {
  fetchPayTransSg,
  fetchPayRecordsSgView,
  processPayroll,
  unlockPayRecord,
  lockPayRecord,
  fetchPayRunEmSelections,
  fetchPayRunNewEmSelections,
  proceedToPaymentPayRun,
  unlockAllPayRecords,
  lockAllPayRecords
} from '../../actions'
import { selectPayRunById, refetchPayRecords } from '../../reducers'
import { selectPayRecords, selectPayWarningTags } from '../../selectors'
import { PayRecordSgState } from '../../types'
import { PayRecordsTrans } from './PayRecordsTrans'
import { PayrollReportButton } from './PayrollReportButton'
import { AddPayItemButton } from './AddPayItemButton'
import './PayRecords.less'

export interface PayRecordsProps {
  payRunId: string
  onEnablePayment?: () => void
}

type PayRecordSgTable = PayRecordSgState

interface EmSelectionDrawerState {
  visible: boolean
}

interface PaymentModalState {
  visible: boolean
}

const SCREEN_CODE: Screen = 'pay_record'
const EM_SELECTION_DRAWER_STATE: EmSelectionDrawerState = { visible: false }
const PAYMENT_MODAL_STATE: PaymentModalState = { visible: false }

const amountWidth: number = 110
const shortAmountWidth: number = 80
const baseUrl = getBaseUrl('/filestore')

export const PayRecords: FC<PayRecordsProps> = ({ payRunId, onEnablePayment }) => {
  const payRecords = useSelector(selectPayRecords)(payRunId)
  const payWarningTags = useSelector(selectPayWarningTags)(payRunId)
  const dataLoading = useSelector((state: StoreState) => state.payroll.payRecordsLoading[payRunId])
  const [view, viewLoading] = useFirstView(SCREEN_CODE)
  const viewId = view?.id || ''
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(20)
  const [search, setSearch] = useState<string>('')
  const payRun = useSelector((state: StoreState) => selectPayRunById(state, payRunId))
  const payGroupId = payRun?.payGroupId || ''
  const payGroup = useSelector(selectMasterById)('payGroup', payGroupId) as PayGroupState | undefined
  const processing = useSelector((state: StoreState) => state.payroll.payrollLoading)
  const [locking, setLocking] = useState<string>()
  const [lockingAll, setLockingAll] = useState(false)
  const [unlockingAll, setUnlockingAll] = useState(false)
  const [updating, setUpdating] = useState(false)
  const monthStart = moment(`${payRun?.payPeriod}-${padStart(payGroup?.startDay.toString() || '01', 2, '0')}`)
  const monthEnd = monthStart.clone().add(1, 'month').add(-1, 'day')
  const canModify = usePermissionGate(Permission.payRun, PermissionAction.Modify)
  const canViewEmployee = usePermissionGate(Permission.employee)
  const payRunExpanded = useSelector((state: StoreState) => state.payroll.payRunExpanded)
  const refetch = useSelector((state: StoreState) => state.payroll.payRecordsRefetch)

  const [recalculateDrawerState, setRecalculateDrawerState] =
    useState<EmSelectionDrawerState>(EM_SELECTION_DRAWER_STATE)
  const [addEmployeeDrawerState, setAddEmployeeDrawerState] =
    useState<EmSelectionDrawerState>(EM_SELECTION_DRAWER_STATE)
  const [paymentModalState, setPaymentModalState] = useState<PaymentModalState>(PAYMENT_MODAL_STATE)

  useEffect(() => {
    dispatch(fetchPayItems('sg', { strategy: 'when-empty' }))
  }, [])

  useEffect(() => {
    if (payGroupId) {
      dispatch(fetchMaster('payGroup', payGroupId))
    }
  }, [payGroupId])

  useEffect(() => {
    if (viewId && !processing) {
      dispatch(fetchPayRecordsSgView(viewId, payRunId, { offset: pageSize * (page - 1), limit: pageSize }, search))
    }
  }, [viewId, payRunId, page, pageSize, search, processing, refetch])

  const handleCriteriaApply = useCallback(
    async (criteria: ViewCriteria[]) => {
      if (viewId) {
        setPage(1)
        await dispatch(updateViewCriteria(SCREEN_CODE, viewId, { id: viewId, criteria }))
        await dispatch(refetchPayRecords())
      }
    },
    [viewId]
  )

  const handlePaginationChange = useCallback((page: number, pageSize?: number) => {
    setPage(page)
    setPageSize(pageSize || 20)
  }, [])

  const handleSearch = useCallback((value: string) => {
    setPage(1)
    setSearch(value)
  }, [])

  const handleCalculateAll = useCallback(() => {
    dispatch(processPayroll(payRunId))
  }, [payRunId])

  const handleCalculateSome = useCallback(() => {
    setRecalculateDrawerState({ visible: true })
  }, [])

  const handleFetchDataRecalculate = useCallback(
    (viewId: string, pagination: Pagination, search: string) => {
      if (payRunId) {
        dispatch(fetchPayRunEmSelections(payRunId, viewId, pagination, search))
      }
    },
    [payRunId]
  )

  const handleSelectRecalculate = useCallback(
    async (employeeIds: string[]) => {
      await dispatch(processPayroll(payRunId, employeeIds))
    },
    [payRunId]
  )

  const handleCloseRecalculateDrawer = useCallback(() => {
    setRecalculateDrawerState(EM_SELECTION_DRAWER_STATE)
  }, [])

  const handleAddEmployee = useCallback(() => {
    setAddEmployeeDrawerState({ visible: true })
  }, [])

  const handleFetchDataAddEmployee = useCallback(
    (viewId: string, pagination: Pagination, search: string, pastResignees: boolean) => {
      if (payRunId) {
        dispatch(fetchPayRunNewEmSelections(payRunId, viewId, pagination, search, pastResignees))
      }
    },
    [payRunId]
  )

  const handleSelectAddEmployee = useCallback(
    async (employeeIds: string[]) => {
      await dispatch(processPayroll(payRunId, employeeIds, 'all'))
    },
    [payRunId]
  )

  const handleCloseAddEmployeeDrawer = useCallback(() => {
    setAddEmployeeDrawerState(EM_SELECTION_DRAWER_STATE)
  }, [])

  const handleRowExpand = useCallback((expanded: boolean, record: PayRecordSgTable) => {
    if (expanded) {
      dispatch(fetchPayTransSg(record.id, { strategy: 'when-empty' }))
    }
  }, [])

  const handleLock = useCallback(async payRecord => {
    if (payRecord) {
      setLocking(payRecord.id)
      try {
        if (payRecord.lockedBy) {
          await dispatch(unlockPayRecord(payRecord.payRunId, payRecord.id))
        } else {
          await dispatch(lockPayRecord(payRecord.payRunId, payRecord.id))
        }
      } finally {
        setLocking(undefined)
      }
    }
  }, [])

  const handleLockAll = useCallback(async () => {
    if (payRunId) {
      setLockingAll(true)
      try {
        await dispatch(lockAllPayRecords(payRunId))
      } finally {
        setLockingAll(false)
      }
    }
  }, [payRunId])

  const handleUnlockAll = useCallback(async () => {
    if (payRunId) {
      setUnlockingAll(true)
      try {
        await dispatch(unlockAllPayRecords(payRunId))
      } finally {
        setUnlockingAll(false)
      }
    }
  }, [payRunId])

  const handleToPaymentClick = useCallback(async () => {
    setPaymentModalState({ visible: true })
  }, [])

  const handleCloseProceedToPaymentModal = useCallback(() => setPaymentModalState({ visible: false }), [])

  const handleProceedToPayment = useCallback(
    async (lockAll: boolean) => {
      setUpdating(true)
      try {
        await dispatch(proceedToPaymentPayRun(payRunId, lockAll))
        typeof onEnablePayment === 'function' && onEnablePayment()

        if (lockAll && viewId) {
          dispatch(fetchPayRecordsSgView(viewId, payRunId, { offset: pageSize * (page - 1), limit: pageSize }, search))
        }

        typeof handleCloseProceedToPaymentModal === 'function' && handleCloseProceedToPaymentModal()
      } finally {
        setUpdating(false)
      }
    },
    [payRunId, onEnablePayment, viewId, pageSize, page, search, handleCloseProceedToPaymentModal]
  )

  const columns: ColumnsType<PayRecordSgTable> = [
    {
      title: 'Employee',
      key: 'employeeName',
      dataIndex: 'employeeName',
      fixed: 'left',
      className: 'first-col',
      width: 250,
      render: (value: string, record) => {
        let hire = ''
        let lastDay = ''
        if (payRun) {
          const hireDate = moment(record.hireDate)
          if (hireDate.isAfter(monthStart)) {
            hire = `Join: ${hireDate.format('DD MMM YYYY')}`
          }

          const exitDate = moment(record.employmentEndDate)
          if (exitDate.isBefore(monthEnd)) {
            lastDay = `Last day: ${exitDate.format('DD MMM YYYY')}`
          }
        }
        return (
          <Person
            name={record.employeeName}
            description={
              !payRunExpanded && (
                <>
                  {record.employeeDescription && <div style={{ marginBottom: 5 }}>{record.employeeDescription}</div>}
                  {hire && <div>{hire}</div>}
                  {lastDay && <div>{lastDay}</div>}
                </>
              )
            }
            size={payRunExpanded ? 18 : 36}
            photo={record.photoId && `${baseUrl}/file/${record.photoId}/thumbnailphoto/${payRunExpanded ? 18 : 36}`}
            // photo={
            //   record.employeeId &&
            //   `/employee/employee/${record.employeeId}/avatar/${payRunExpanded ? 18 : 36}?photo_id=${record.photoId}`
            // }
            path={PAY_ROUTES.payRecord.replace(':id', payRunId).replace(':employeeId', record.employeeId)}
            openAsNewTab={false}
            newTabPath={canViewEmployee ? EMP_ROUTES.employee.replace(':id', record.employeeId) : undefined}
          />
        )
      }
    }
  ]

  if (canModify && payRun?.status !== PayRunStatus.completed) {
    columns.push({
      key: 'action',
      width: 20,
      className: 'action-cell',
      render: (value: string, record: PayRecordSgTable) => (
        <Tooltip title={!!record.lockedBy ? 'Locked' : 'Unlocked'}>
          <Link
            className={classNames('payrecords__lock', { 'payrecords__lock--locked': !!record.lockedBy })}
            onClick={() => handleLock(record)}
          >
            {locking === record.id ? (
              <LoadingOutlined />
            ) : !!record.lockedBy ? (
              <i className="fal fa-lock" />
            ) : (
              <i className="fal fa-lock-open" />
            )}
          </Link>
        </Tooltip>
      )
    })
  }

  if (payWarningTags.length > 0) {
    columns.push({
      key: 'tag',
      width: 100,
      className: 'action-cell',
      render: (value: string, record) => (
        <>
          {payWarningTags
            .filter(x => x.employeeId === record.employeeId)
            .map(
              x =>
                x.tag &&
                x.color && (
                  <Tooltip key={x.id} title={x.message}>
                    <Tag key={x.id} style={{ fontSize: '10px' }} type="original" color={x.color}>
                      {x.tag}
                    </Tag>
                  </Tooltip>
                )
            )}
        </>
      )
    })
  }

  columns.push(
    ...[
      {
        title: 'Basic pay',
        key: 'basicPay',
        dataIndex: 'basicPay',
        align: 'right',
        width: amountWidth,
        render: (value: number) => formatMoney(value, 2)
      } as ColumnType<PayRecordSgState>,
      {
        title: 'Recurring',
        key: 'recurring',
        dataIndex: 'recurring',
        align: 'right',
        width: amountWidth,
        render: (value: number) => formatMoney(value, 2)
      } as ColumnType<PayRecordSgState>,
      {
        title: 'Overtime',
        key: 'overtime',
        dataIndex: 'overtime',
        align: 'right',
        width: amountWidth,
        render: (value: number) => formatMoney(value, 2)
      } as ColumnType<PayRecordSgState>,
      {
        title: 'Leave payment',
        key: 'leave',
        dataIndex: 'leave',
        align: 'right',
        width: amountWidth,
        render: (value: number) => formatMoney(value, 2)
      } as ColumnType<PayRecordSgState>,
      {
        title: 'Ad hoc',
        key: 'adhoc',
        dataIndex: 'adhoc',
        align: 'right',
        width: amountWidth,
        render: (value: number) => formatMoney(value, 2)
      } as ColumnType<PayRecordSgState>,
      {
        title: 'SHG fund',
        key: 'fund',
        dataIndex: 'fund',
        align: 'right',
        width: shortAmountWidth,
        render: (value: number) => formatMoney(value, 2)
      } as ColumnType<PayRecordSgState>,
      {
        title: 'CPF employee',
        key: 'cpfEm',
        dataIndex: 'cpfEm',
        align: 'right',
        width: amountWidth,
        render: (value: number) => formatMoney(value, 2)
      } as ColumnType<PayRecordSgState>,
      {
        title: 'Net pay',
        key: 'netPay',
        dataIndex: 'netPay',
        align: 'right',
        width: amountWidth,
        render: (value: number) => formatMoney(value, 2)
      } as ColumnType<PayRecordSgState>,
      {
        title: 'CPF employer',
        key: 'cpfEr',
        dataIndex: 'cpfEr',
        align: 'right',
        width: amountWidth,
        render: (value: number) => formatMoney(value, 2)
      } as ColumnType<PayRecordSgState>,
      {
        title: 'SDL',
        key: 'sdl',
        dataIndex: 'sdl',
        align: 'right',
        width: shortAmountWidth,
        render: (value: number) => formatMoney(value, 2)
      } as ColumnType<PayRecordSgState>,
      {
        title: 'FWL',
        key: 'fwl',
        dataIndex: 'fwl',
        align: 'right',
        width: shortAmountWidth,
        render: (value: number) => formatMoney(value, 2)
      } as ColumnType<PayRecordSgState>
    ]
  )

  return (
    <div className="payrecords">
      <div className="payrecords__action-bar">
        <SearchInput onSearch={handleSearch} />
        <ViewCriteriaSimple screenCode={SCREEN_CODE} viewId={viewId} onApply={handleCriteriaApply} label="" />
        {canModify && payRun?.status !== PayRunStatus.completed && (
          <Space align="start">
            <Dropdown
              disabled={lockingAll || unlockingAll}
              menu={{
                items: [
                  { key: 'lock', label: 'Lock all employees', onClick: handleLockAll },
                  { key: 'unlock', label: 'Unlock all employees', onClick: handleUnlockAll }
                ]
              }}
            >
              <Button
                className="payrecords__action-bar-buttons-lock"
                hidden={payRun?.status === PayRunStatus.completed}
              >
                {lockingAll || unlockingAll ? (
                  <LoadingOutlined />
                ) : (
                  <Space size={4}>
                    <i className="fal fa-lock" />
                    <i className="fa-light fa-angle-down" />
                  </Space>
                )}
              </Button>
            </Dropdown>
            <Button onClick={handleAddEmployee}>Add employee</Button>
            <AddPayItemButton payRunId={payRunId} />
          </Space>
        )}
      </div>

      <Card fitParent table>
        <Table
          rowKey="id"
          dataSource={payRecords}
          pagination={false}
          indentSize={1}
          columns={columns}
          loading={dataLoading || viewLoading}
          fitParent
          scroll={{ x: amountWidth * 8 + shortAmountWidth * 3 + 200, y: 1000 }}
          className="payrecords__table"
          expandable={{
            expandedRowRender: (record: PayRecordSgTable) => (
              <PayRecordsTrans payRunId={record.payRunId} payRecordId={record.id} />
            ),
            onExpand: handleRowExpand
          }}
        />
      </Card>

      <div className="payrecords__footer-bar">
        <Space className="payrecords__footer-bar--flex">
          {/* <Button>Reconciliation report</Button> */}
          <PayrollReportButton payRunId={payRunId}>
            Payroll report <i className="fa-light fa-angle-down fa-fw" />
          </PayrollReportButton>
        </Space>
        {canModify && payRun?.status !== PayRunStatus.completed && (
          <Space>
            <Dropdown.Button
              menu={{
                items: [{ key: 'selected', label: 'Recalculate selected employees...', onClick: handleCalculateSome }]
              }}
              icon={<i className="fa-light fa-angle-down" />}
              onClick={handleCalculateAll}
              buttonsRender={([leftButton, rightButton]) => [
                React.cloneElement(leftButton as React.ReactElement, { loading: processing }),
                rightButton
              ]}
            >
              Recalculate all
            </Dropdown.Button>
            {payRun?.status === PayRunStatus.verification && (
              <Button type="primary" onClick={handleToPaymentClick} loading={updating}>
                Proceed to payment
              </Button>
            )}
          </Space>
        )}
      </div>
      <EmSelectionDrawer
        {...recalculateDrawerState}
        viewName="payrun_recalculate"
        title="Select the employees to recalculate payroll"
        okText="Recalculate"
        onFetchData={handleFetchDataRecalculate}
        onSelect={handleSelectRecalculate}
        onClose={handleCloseRecalculateDrawer}
        resetOnClose={true}
      />
      <EmSelectionDrawer
        {...addEmployeeDrawerState}
        viewName="payrun_newem"
        title="Add employees"
        okText="Add"
        onFetchData={handleFetchDataAddEmployee}
        onSelect={handleSelectAddEmployee}
        onClose={handleCloseAddEmployeeDrawer}
        resetOnClose={true}
      />
      <ModalTristate
        {...paymentModalState}
        title="Proceed to payment"
        width={450}
        yesText="Yes, lock all"
        noText="No, just proceed to payment"
        onYes={() => handleProceedToPayment(true)}
        onNo={() => handleProceedToPayment(false)}
        onCancel={handleCloseProceedToPaymentModal}
      >
        Do you want to lock all the records in this payroll run?
      </ModalTristate>
    </div>
  )
}
