import React, { CSSProperties, FC, useCallback } from 'react'
import { Form, Input, Space } from '~/core-components'

interface MinutesCronProps {
  expr: string[]
  onChange: (value: string[]) => void
}

const numberStyles: CSSProperties = { width: 60 }

export const MinutesCron: FC<MinutesCronProps> = ({ expr, onChange }) => {
  const minutes = expr[0].split('/')[1] ? parseInt(expr[0].split('/')[1]) : 1

  const handleChange = useCallback(
    (value: number | null) => {
      if ((typeof value === 'number' && value > 0 && value < 60) || value == null) {
        const newExpr = [...expr]
        newExpr[0] = value ? `0/${value}` : newExpr[1]
        onChange(newExpr)
      }
    },
    [onChange, expr]
  )

  return (
    <Form.Item>
      <Space>
        Every
        <Input.Number min={1} max={59} value={minutes} style={numberStyles} onChange={handleChange} />
        minute(s)
      </Space>
    </Form.Item>
  )
}
