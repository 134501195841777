import { Operation } from 'fast-json-patch'
import { KeyValue, Pagination, Search } from '~/types/common'
import { request, getBaseUrl } from '~/utils/request'
import {
  MasterState,
  DepartmentExtraState,
  TeamExtraState,
  IMasterInfo,
  DivisionExtraState,
  SectionExtraState,
  MasterName
} from '../types'
import { EmSelectionViewState } from '~/features/employee'
import { EmPreviewState } from '~/components'

const baseUrl = getBaseUrl('/employee')

export const apiGetMasters = (masterName: string) =>
  request<MasterState[]>('get', `${baseUrl}/${masterName.toLowerCase()}`)

export const apiGetMaster = (masterName: string, id: string) =>
  request<MasterState>('get', `${baseUrl}/${masterName.toLowerCase()}/${id}`)

export const apiAddMaster = (masterName: string, master: IMasterInfo) =>
  request<{ id: string }>('post', `${baseUrl}/${masterName.toLowerCase()}`, master)

export const apiDeleteMaster = (masterName: string, id: string) =>
  request<void>('delete', `${baseUrl}/${masterName.toLowerCase()}/${id}`)

export const apiUpdateMaster = (masterName: string, id: string, master: Operation[]) =>
  request<void>('patch', `${baseUrl}/${masterName.toLowerCase()}/${id}`, master)

export const apiGetDepartmentsExtra = () => request<DepartmentExtraState[]>('get', `${baseUrl}/department/extra`)

export const apiGetDivisionsExtra = () => request<DivisionExtraState[]>('get', `${baseUrl}/division/extra`)

export const apiGetSectionsExtra = () => request<SectionExtraState[]>('get', `${baseUrl}/section/extra`)

export const apiGetTeamsExtra = () => request<TeamExtraState[]>('get', `${baseUrl}/team/extra`)

export const apiGetPayGroupKeyvalues = (params: any) =>
  request<KeyValue[]>('get', `${baseUrl}/paygroup/keyvalues`, params)

export const apiGetPayGroupPayslipEmSelections = (payGroupId: string, viewId: string, params: Pagination & Search) =>
  request<EmSelectionViewState>('get', `${baseUrl}/paygroup/${payGroupId}/emselections/${viewId}`, params)

export const apiGetLeaveGroupKeyvalues = (params: any) =>
  request<KeyValue[]>('get', `${baseUrl}/leavegroup/keyvalues`, params)

export const apiGetMasterEmployees = (masterName: MasterName, masterId: string) =>
  request<EmPreviewState[]>('get', `${baseUrl}/${masterName}/${masterId}/employees`)
