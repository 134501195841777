import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { FetchStrategy } from '~/types/common'
import { WfFlowType } from '~/constants'
import { SysWfFlowTypeState, WfCategory } from '../types'
import { fetchSysWfFlowTypes } from '../actions'

export const useSysWfFlowType = (
  category: WfCategory,
  flowType: WfFlowType,
  fetchStrategy: FetchStrategy = 'when-empty'
): [SysWfFlowTypeState | undefined, boolean] => {
  const item = useSelector((state: StoreState) => state.workflow.sysWfFlowTypes[category]?.entities[flowType])
  const loading = useSelector((state: StoreState) => state.workflow.sysWfFlowTypesLoading[category] || false)

  useEffect(() => {
    dispatch(fetchSysWfFlowTypes(category, { strategy: fetchStrategy }))
  }, [category, fetchStrategy])

  return [item, loading]
}
