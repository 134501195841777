import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchEmpKeyvalues, fetchPayslipItemConfigs } from '../actions'
import { PayslipItemConfigGroupChildren, selectPayslipItemConfigs } from '../selectors'
import { PayslipItemConfigState } from '../types'

export const usePayslipItemConfigs = (
  payGroupId: string,
  fetchStrategy: FetchStrategy = 'always'
): [PayslipItemConfigGroupChildren<PayslipItemConfigState>[], boolean] => {
  const data = useSelector(selectPayslipItemConfigs)(payGroupId || '')
  const loading = useSelector((state: StoreState) => state.master.payslipItemConfigsLoading[payGroupId])

  useEffect(() => {
    // Fetch for selectPayslipItemConfigs
    dispatch(
      fetchEmpKeyvalues('payItemCategory', 'sysoption', { type: 'pay_item_category' }, { strategy: 'when-empty' })
    )
    dispatch(fetchEmpKeyvalues('payItemType', 'sysoption', { type: 'pay_item_type' }, { strategy: 'when-empty' }))

    dispatch(fetchPayslipItemConfigs(payGroupId, { strategy: fetchStrategy }))
  }, [payGroupId, fetchStrategy])

  return [data, loading]
}
