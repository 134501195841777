import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { ClaimPendingApprovalViewState } from '../types'

export const selectClaimPendingApprovalsView = createSelector(
  (state: StoreState) => state.claim.claimPendingApprovalsView,
  claimPendingApprovalsState =>
    memoize((viewId: string): ClaimPendingApprovalViewState => {
      const claimPendingApprovals = claimPendingApprovalsState.entities[viewId] || ({} as ClaimPendingApprovalViewState)
      return claimPendingApprovals
    })
)
