import { request, getBaseUrl, timeout } from '~/utils/request'
import { KeyValue } from '~/types/common'
import { IRptCpfFile } from '../types'

const baseUrl = getBaseUrl('/payroll-sg/cpffile')

export const apiGetCpfPaygroups = (params: any) => request<KeyValue[]>('get', `${baseUrl}/paygroup`, params)

export const apiGetCpfFile = (params: IRptCpfFile) =>
  request('post', `${baseUrl}/download`, params, { responseType: 'blob', timeout })
