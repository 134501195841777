import { replaceXeroLineItemMaps, setXeroLineItemMapsLoading } from '../reducers'
import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiUpdateXeroLineItemMaps } from '../api/xero.api'
import { XeroLineItemMapState } from '../types'

export const updateXeroLineItemMaps = (companyId?: string, data?: XeroLineItemMapState[]): ThunkResult<void> => {
  return async dispatch => {
    if (!companyId || !data || data.length === 0) return

    try {
      dispatch(setXeroLineItemMapsLoading({ companyId, loading: true }))
      const { status, result, errors, message, errorData } = await apiUpdateXeroLineItemMaps(companyId, data)

      if (status) {
        dispatch(replaceXeroLineItemMaps({ companyId, data: result }))
        showSuccess('Saved')
      } else {
        if (Object.keys(errors).length === 0) {
          showError(message, errorData)
        }
      }
      return { errors }
    } finally {
      dispatch(setXeroLineItemMapsLoading({ companyId, loading: false }))
    }
  }
}
