import { request, getBaseUrl } from '~/utils/request'
import { PayEncashMapState } from '../types'

const baseUrl = getBaseUrl('/payroll-sg')

export const apiGetPayEncashMaps = (payGroupId: string) =>
  request<PayEncashMapState[]>('get', `${baseUrl}/paygroup/${payGroupId}/payencashmap`)

export const apiUpdatePayEncashMaps = (payGroupId: string, data: PayEncashMapState[]) =>
  request<PayEncashMapState[]>('post', `${baseUrl}/paygroup/${payGroupId}/payencashmap`, data)
