import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchMyLeaveBalances } from '../../actions'
import { selectMyEntitledLeaveType } from '../../selectors'
import { SSEntitledLeaveType } from '../../types'

export const useMyEntitledLeaveType = (
  leaveTypeId: string,
  fetchStrategy: FetchStrategy = 'when-empty'
): [SSEntitledLeaveType | undefined, boolean] => {
  const entitledLeaveType = useSelector(selectMyEntitledLeaveType)(leaveTypeId)
  const loading = useSelector((state: StoreState) => state.my.myLeaveBalancesLoading)

  useEffect(() => {
    dispatch(fetchMyLeaveBalances({ strategy: fetchStrategy }))
  }, [fetchStrategy])

  return [entitledLeaveType, loading]
}
