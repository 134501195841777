import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import persistReducer from 'redux-persist/es/persistReducer'
import {
  SelectionRootState,
  ViewSchemaState,
  ViewState,
  SysSelectionScreenState,
  SysSelectionFieldState,
  SysCriteriaScreenState,
  SysCriteriaFieldState,
  Screen,
  selectionPersistConfig,
  SysCriteriaOptionKeyValue
} from './types'
import { StoreState } from '../../types/store'

const viewSchemaAdapter = createEntityAdapter<ViewSchemaState>()
const sysSelectionScreensAdapter = createEntityAdapter<SysSelectionScreenState>()
const sysSelectionFieldsAdapter = createEntityAdapter<SysSelectionFieldState>()
const sysCriteriaScreensAdapter = createEntityAdapter<SysCriteriaScreenState>()
const sysCriteriaFieldsAdapter = createEntityAdapter<SysCriteriaFieldState>()
const sysCriteriaOptionsAdapter = createEntityAdapter<SysCriteriaOptionKeyValue>({ selectId: model => model.key })
const viewAdapter = createEntityAdapter<ViewState>()

const viewSchemaInitialState = viewSchemaAdapter.getInitialState()
const sysSelectionScreensInitialState = sysSelectionScreensAdapter.getInitialState()
const sysSelectionFieldsInitialState = sysSelectionFieldsAdapter.getInitialState()
const sysCriteriaScreensInitialState = sysCriteriaScreensAdapter.getInitialState()
const sysCriteriaFieldsInitialState = sysCriteriaFieldsAdapter.getInitialState()
const sysCriteriaOptionsInitialState = sysCriteriaOptionsAdapter.getInitialState()
const viewInitialState = viewAdapter.getInitialState()

const initialState: SelectionRootState = {
  sysSelectionScreens: {},
  sysSelectionScreensLoading: {},
  sysSelectionFields: {},
  sysSelectionFieldsLoading: {},
  sysSelectionFieldsRefetch: 0,
  sysCriteriaScreens: {},
  sysCriteriaScreensLoading: {},
  sysCriteriaFields: {},
  sysCriteriaFieldsLoading: {},
  sysCriteriaFieldsRefetch: 0,
  sysCriteriaOptions: {},
  sysCriteriaOptionsLoading: {},
  sysCriteriaOptionsRefetch: {},
  viewSchema: {},
  viewSchemaLoading: {},
  viewSchemaByNameLoading: {},
  views: {},
  viewsLoading: {},
  viewLoading: {}
}

const selectionSlice = createSlice({
  name: 'selection',
  initialState,
  reducers: {
    setViewSchema: (state, action: PayloadAction<{ screenCode: Screen; data: ViewSchemaState }>) => {
      const { screenCode, data } = action.payload
      state.viewSchema[screenCode] = state.viewSchema[screenCode] || viewSchemaInitialState
      viewSchemaAdapter.upsertOne(state.viewSchema[screenCode]!, data)
    },
    removeViewSchema: (state, action: PayloadAction<{ screenCode: Screen; id: string }>) => {
      const { screenCode, id } = action.payload
      state.viewSchema[screenCode] = state.viewSchema[screenCode] || viewSchemaInitialState
      viewSchemaAdapter.removeOne(state.viewSchema[screenCode]!, id)
    },
    setViewSchemaLoading: (state, action: PayloadAction<{ screenCode: Screen; viewId: string; loading: boolean }>) => {
      const { screenCode, viewId, loading } = action.payload
      state.viewSchema[screenCode] = state.viewSchema[screenCode] || viewSchemaInitialState

      state.viewSchemaLoading[screenCode] = state.viewSchemaLoading[screenCode] || {}
      state.viewSchemaLoading[screenCode]![viewId] = loading
    },
    setViewSchemaByNameLoading: (
      state,
      action: PayloadAction<{ screenCode: Screen; viewName: string; loading: boolean }>
    ) => {
      const { screenCode, viewName, loading } = action.payload
      state.viewSchema[screenCode] = state.viewSchema[screenCode] || viewSchemaInitialState

      state.viewSchemaByNameLoading[screenCode] = state.viewSchemaByNameLoading[screenCode] || {}
      state.viewSchemaByNameLoading[screenCode]![viewName] = loading
    },
    replaceViews: (state, action: PayloadAction<{ screenCode: Screen; data: ViewState[] }>) => {
      const { screenCode, data } = action.payload
      viewAdapter.upsertMany(state.views[screenCode]!, data)
    },
    removeView: (state, action: PayloadAction<{ screenCode: Screen; viewId: string }>) => {
      const { screenCode, viewId } = action.payload
      viewAdapter.removeOne(state.views[screenCode]!, viewId)
    },
    setViewsLoading: (state, action: PayloadAction<{ screenCode: Screen; loading: boolean }>) => {
      const { screenCode, loading } = action.payload
      state.views[screenCode] = state.views[screenCode] || viewInitialState
      state.viewsLoading[screenCode] = loading
    },
    setViewLoading: (state, action: PayloadAction<{ screenCode: Screen; viewId: string; loading: boolean }>) => {
      const { screenCode, viewId, loading } = action.payload
      state.views[screenCode] = state.views[screenCode] || viewInitialState

      state.viewLoading[screenCode] = state.viewLoading[screenCode] || {}
      state.viewLoading[screenCode]![viewId] = loading
    },
    setSysSelectionScreens: (state, action: PayloadAction<{ screenCode: Screen; data: SysSelectionScreenState[] }>) => {
      const { screenCode, data } = action.payload
      state.sysSelectionScreens[screenCode] = state.sysSelectionScreens[screenCode] || sysSelectionScreensInitialState
      sysSelectionScreensAdapter.upsertMany(state.sysSelectionScreens[screenCode]!, data)
    },
    setSysSelectionScreensLoading: (state, action: PayloadAction<{ screenCode: Screen; loading: boolean }>) => {
      const { screenCode, loading } = action.payload
      state.sysSelectionScreens[screenCode] = state.sysSelectionScreens[screenCode] || sysSelectionScreensInitialState
      state.sysSelectionScreensLoading[screenCode] = loading
    },
    setSysSelectionFields: (state, action: PayloadAction<{ screenCode: Screen; data: SysSelectionFieldState[] }>) => {
      const { screenCode, data } = action.payload
      state.sysSelectionFields[screenCode] = state.sysSelectionFields[screenCode] || sysSelectionFieldsInitialState
      sysSelectionFieldsAdapter.setAll(state.sysSelectionFields[screenCode]!, data)
    },
    setSysSelectionFieldsLoading: (state, action: PayloadAction<{ screenCode: Screen; loading: boolean }>) => {
      const { screenCode, loading } = action.payload
      state.sysSelectionFields[screenCode] = state.sysSelectionFields[screenCode] || sysSelectionFieldsInitialState
      state.sysSelectionFieldsLoading[screenCode] = loading
    },
    refetchSysSelectionFields: state => {
      state.sysSelectionFieldsRefetch += 1
    },
    clearSysSelectionFields: state => {
      for (const screen in state.sysSelectionFields) {
        sysSelectionFieldsAdapter.removeAll(
          state.sysSelectionFields[screen as Screen] as EntityState<SysSelectionFieldState>
        )
      }
    },
    setSysCriteriaScreens: (state, action: PayloadAction<{ screenCode: Screen; data: SysCriteriaScreenState[] }>) => {
      const { screenCode, data } = action.payload
      state.sysCriteriaScreens[screenCode] = state.sysCriteriaScreens[screenCode] || sysCriteriaScreensInitialState
      sysCriteriaScreensAdapter.upsertMany(state.sysCriteriaScreens[screenCode]!, data)
    },
    setSysCriteriaScreensLoading: (state, action: PayloadAction<{ screenCode: Screen; loading: boolean }>) => {
      const { screenCode, loading } = action.payload
      state.sysCriteriaScreens[screenCode] = state.sysCriteriaScreens[screenCode] || sysCriteriaScreensInitialState
      state.sysCriteriaScreensLoading[screenCode] = loading
    },
    setSysCriteriaFields: (state, action: PayloadAction<{ screenCode: Screen; data: SysCriteriaFieldState[] }>) => {
      const { screenCode, data } = action.payload
      state.sysCriteriaFields[screenCode] = state.sysCriteriaFields[screenCode] || sysCriteriaFieldsInitialState
      sysCriteriaFieldsAdapter.setAll(state.sysCriteriaFields[screenCode]!, data)
    },
    setSysCriteriaFieldsLoading: (state, action: PayloadAction<{ screenCode: Screen; loading: boolean }>) => {
      const { screenCode, loading } = action.payload
      state.sysCriteriaFields[screenCode] = state.sysCriteriaFields[screenCode] || sysCriteriaFieldsInitialState
      state.sysCriteriaFieldsLoading[screenCode] = loading
    },
    refetchSysCriteriaFields: state => {
      state.sysCriteriaFieldsRefetch += 1
    },
    clearSysCriteriaFields: state => {
      for (const screen in state.sysCriteriaFields) {
        sysCriteriaFieldsAdapter.removeAll(
          state.sysCriteriaFields[screen as Screen] as EntityState<SysCriteriaFieldState>
        )
      }
    },
    refetchSysCriteriaOptions: (state, action: PayloadAction<string>) => {
      const code = action.payload
      state.sysCriteriaOptionsRefetch[code] = state.sysCriteriaOptionsRefetch[code] || 0
      state.sysCriteriaOptionsRefetch[code] += 1
    },
    replaceSysCriteriaOptions: (state, action: PayloadAction<{ code: string; data: SysCriteriaOptionKeyValue[] }>) => {
      sysCriteriaOptionsAdapter.setAll(state.sysCriteriaOptions[action.payload.code]!, action.payload.data)
    },
    setSysCriteriaOptionsLoading: (state, action: PayloadAction<{ code: string; loading: boolean }>) => {
      const { code, loading } = action.payload
      state.sysCriteriaOptions[code] = state.sysCriteriaOptions[code] || sysCriteriaOptionsInitialState
      state.sysCriteriaOptionsLoading[code] = loading
      state.sysCriteriaOptionsRefetch[code] = 0
    }
  }
})

export const {
  setViewSchema,
  removeViewSchema,
  setViewSchemaLoading,
  setViewSchemaByNameLoading,
  replaceViews,
  removeView,
  setViewsLoading,
  setViewLoading,
  setSysSelectionScreens,
  setSysSelectionScreensLoading,
  setSysSelectionFields,
  setSysSelectionFieldsLoading,
  clearSysSelectionFields,
  refetchSysSelectionFields,
  setSysCriteriaScreens,
  setSysCriteriaScreensLoading,
  setSysCriteriaFields,
  setSysCriteriaFieldsLoading,
  clearSysCriteriaFields,
  refetchSysCriteriaFields,
  refetchSysCriteriaOptions,
  replaceSysCriteriaOptions,
  setSysCriteriaOptionsLoading
} = selectionSlice.actions

export const { selectById: selectEmDirectoryViewById } = viewAdapter.getSelectors(
  (state: StoreState) => state.selection.views['em_directory'] || viewInitialState
)

export const { selectById: selectPayItemSgViewById } = viewAdapter.getSelectors(
  (state: StoreState) => state.selection.views['pay_item'] || viewInitialState
)

export const { selectById: selectPayRecordSgViewById } = viewAdapter.getSelectors(
  (state: StoreState) => state.selection.views['pay_record'] || viewInitialState
)

export const { selectById: selectEmPayrollViewById } = viewAdapter.getSelectors(
  (state: StoreState) => state.selection.views['em_payroll'] || viewInitialState
)

export const { selectAll: selectEmSelectionViews, selectById: selectEmSelectionViewById } = viewAdapter.getSelectors(
  (state: StoreState) => state.selection.views['em_selection'] || viewInitialState
)

export const { selectAll: selectEmPayslipViews, selectById: selectEmPayslipViewById } = viewAdapter.getSelectors(
  (state: StoreState) => state.selection.views['em_payslip'] || viewInitialState
)

export const { selectAll: selectPermissionEmpViews, selectById: selectPermissionEmpViewById } =
  viewAdapter.getSelectors((state: StoreState) => state.selection.views['permission_emp'] || viewInitialState)

export const { selectAll: selectPermissionPayViews, selectById: selectPermissionPayViewById } =
  viewAdapter.getSelectors((state: StoreState) => state.selection.views['permission_pay'] || viewInitialState)

export const { selectAll: selectPermissionLveViews, selectById: selectPermissionLveViewById } =
  viewAdapter.getSelectors((state: StoreState) => state.selection.views['permission_lve'] || viewInitialState)

export const selectionReducers = {
  selection: persistReducer<SelectionRootState>(selectionPersistConfig, selectionSlice.reducer)
}
