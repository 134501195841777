import { request, getBaseUrl } from '~/utils/request'
import { SSLeaveGrantState, SSOthersLeaveRecordState, SSOthersBackInState } from '../types'

const baseUrl = getBaseUrl('/leave/ssleave')

export const apiGetMyLeaveGrants = (leaveTypeId: string, hasBalance: boolean, validPeriodAsOf: string) =>
  request<SSLeaveGrantState[]>('get', `${baseUrl}/mygrant`, {
    leaveTypeId,
    hasBalance,
    validPeriodAsOf
  })

export const apiGetMyLeaveGrantByLeaveRecord = (leaveRecordId: string) =>
  request<SSLeaveGrantState[]>('get', `${baseUrl}/mygrant`, { leaveRecordId })

export const apiGetOthersLeaveRecords = () => request<SSOthersLeaveRecordState[]>('get', `${baseUrl}/othersleave`)

export const apiGetOthersBackInDays = (params: { leaveRecordIds: string[] }) =>
  request<SSOthersBackInState[]>('get', `${baseUrl}/othersbackin`, params)
