import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'

export const selectCurrentEmShiftRole = createSelector(
  (state: StoreState) => state.employee.emShiftRoles,
  emShiftRolesState =>
    memoize(
      (employeeId?: string, id?: string) => {
        if (!employeeId || !id) return undefined

        return emShiftRolesState[employeeId]?.entities[id]
      },
      (employeeId?: string, id?: string) => `${employeeId}|${id}`
    )
)

export const selectHistoryEmShiftRoles = createSelector(
  (state: StoreState) => state.employee.emShiftRoles,
  emShiftRolesState =>
    memoize(
      (employeeId?: string, excludeId?: string) => {
        if (!employeeId) return []

        return Object.values(emShiftRolesState[employeeId]?.entities || {})
          .filter(emlg => emlg?.id !== excludeId)
          .sort((a, b) => {
            const aStartDate = new Date(a!.startDate).getTime()
            const bStartDate = new Date(b!.startDate).getTime()
            return bStartDate - aStartDate
          })
      },
      (employeeId?: string, excludeId?: string) => `${employeeId}|${excludeId}`
    )
)
