import React, { forwardRef, useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Select, SelectProps, Space } from '~/core-components'
import { DropdownActions, InactiveTag } from '~/components'
import { useFirstInView } from '~/hooks'
import { isInactive } from '~/utils/dateUtil'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { FetchStrategy } from '~/types/common'
import { fetchClaimTypes } from '../../actions'
import { selectClaimTypes } from '../../selectors'

interface ClaimTypeKeyValuesProps extends SelectProps {
  fetchStrategy?: FetchStrategy
}

const ClaimTypeKeyValuesInternal = (
  { children, fetchStrategy = 'when-empty', ...props }: ClaimTypeKeyValuesProps,
  ref: React.Ref<any>
) => {
  const { ref: inViewRef, inView } = useFirstInView<HTMLDivElement>({ threshold: 0.25 })
  const claimTypes = useSelector(selectClaimTypes)
  const loading = useSelector((state: StoreState) => state.claim.claimTypesLoading)

  useEffect(() => {
    if (inView) {
      dispatch(fetchClaimTypes({ strategy: fetchStrategy }))
    }
  }, [inView, fetchStrategy])

  const handleRefresh = useCallback(() => {
    dispatch(fetchClaimTypes())
  }, [])

  return (
    <div ref={inViewRef}>
      <Select
        ref={ref}
        showSearch
        showArrow
        optionFilterProp="title"
        dropdownRender={menu => (
          <div>
            {menu}
            <DropdownActions onRefresh={handleRefresh} />
          </div>
        )}
        {...props}
        loading={loading}
      >
        {claimTypes?.map(kv => (
          <Select.Option key={kv.id} value={kv.id || ''} title={kv.name}>
            <Space>
              {isInactive(kv.inactiveDate) && <InactiveTag />}
              {kv.name}
            </Space>
          </Select.Option>
        ))}
      </Select>
    </div>
  )
}

export const ClaimTypeKeyValues = forwardRef(ClaimTypeKeyValuesInternal)
