export const emptyGuid = '00000000-0000-0000-0000-000000000000'
export const emptyDate = '0001-01-01T00:00:00'

export class Fund {
  static get notAvailable() {
    return 'na'
  }
  static get exclude() {
    return 'excl'
  }
}

export class SalaryBasis {
  static get monthly() {
    return 'm'
  }
  static get daily() {
    return 'd'
  }
  static get hourly() {
    return 'h'
  }
}

export const criteriaDelimiter: string = '||'
export const ltSettingDelimiter: string = '||'

export class Service {
  static get employee() {
    return 'emp'
  }
  static get payrollSG() {
    return 'psg'
  }
  static get leave() {
    return 'lve'
  }
  static get claim() {
    return 'cla'
  }
  static get attendance() {
    return 'att'
  }
  static get job() {
    return 'job'
  }
}

export class StatutoryExemptReason {
  static get workingStudent() {
    return 's'
  }
  static get overseasPosting() {
    return 'o'
  }
  static get contractForService() {
    return 'c'
  }
}

export class FamilyRelation {
  static get spouse() {
    return 'spouse'
  }
  static get child() {
    return 'child'
  }
  static get parent() {
    return 'parent'
  }
  static get others() {
    return 'others'
  }
}

export class View {
  static get default() {
    return 'Default view'
  }
}

export class EmployeeImportCode {
  static get flat() {
    return 'em_flat'
  }
  static get employee() {
    return 'em'
  }
  static get employment() {
    return 'em_employment'
  }
  static get address() {
    return 'em_address'
  }
  static get identity() {
    return 'em_identity'
  }
  static get salary() {
    return 'em_salary'
  }
  static get education() {
    return 'em_education'
  }
  static get family() {
    return 'em_family'
  }
}

export class Delimiter {
  static get pipe() {
    return '||'
  }
  static get hash() {
    return '##'
  }
  static get comma() {
    return ','
  }
}

export enum EmailRecipientSource {
  Employee = 'em',
  Email = 'email',
  Role = 'role',
  RoleLveApproval = 'role_lve_approval',
  RoleClaApproval = 'role_cla_approval',
  EmailGroup = 'email_grp'
}

export class ApprovalTargetSource {
  static get employee() {
    return 'em'
  }
  static get email() {
    return 'email'
  }
  static get role() {
    return 'role'
  }
}

export class WorkStatus {
  static get full() {
    return 'f'
  }
  static get half() {
    return 'h1'
  }
  static get off() {
    return 'o'
  }
  static get rest() {
    return 'r'
  }
}

export const NonWorkingStatus = [WorkStatus.off, WorkStatus.rest]
export const WorkingStatus = [WorkStatus.full, WorkStatus.half]

export enum WorkStatusType {
  WorkingDay = 'w',
  OffDay = 'o',
  RestDay = 'r'
}

export enum SsRole {
  ReportingManager = 'mgr',
  MgrLveEntitlement = 'mgr_lve_ent',
  MgrLveRecord = 'mgr_lve_rec',
  MgrAttSchedule = 'mgr_att_sch'
}

export enum SSRoleScreen {
  LeaveEntitlement = 'lve_entitlement',
  LeaveRecord = 'lve_record',
  Schedule = 'att_schedule'
}

export enum SsCalViewAs {
  OrgCalendar = 'org',
  ReportingManager = 'mgr',
  CalendarViewer = 'clv'
}

export enum CalendarConfig {
  LveCalendarConfigId = '6b1ba122-4d65-4649-b0fd-9d2af93c665d'
}

export enum DelegationRole {
  ReportingManager = 'mgr',
  Approver = 'approver'
}
