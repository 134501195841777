import { replaceMyLeaveBalances, setMyLeaveBalancesLoading } from '../../reducers'
import { apiGetMyLeaveBalances } from '../../api/ssleave-entitlement.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchMyLeaveBalances = (options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().my.myLeaveBalancesLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().my.myLeaveBalances?.ids.length > 0
      if (hasData) return
    }

    try {
      dispatch(setMyLeaveBalancesLoading(true))
      const { result, status } = await apiGetMyLeaveBalances()
      if (status) dispatch(replaceMyLeaveBalances(result))
    } finally {
      dispatch(setMyLeaveBalancesLoading(false))
    }
  }
}
