import React, { FC, useCallback, useEffect, useState } from 'react'
import { PhotoEditor } from '../AvatarEditor/PhotoEditor'
import { Form, Modal, UploadFile } from '~/core-components'
import { Errors } from '~/types/store'
import { useIsMountedRef } from '~/hooks/use-is-mounted-ref'
import { request, timeout } from '../../utils/request'

interface UploadPhotoModalProps {
  open: boolean
  uploading: boolean
  title: string
  src?: string
  shape?: 'circle' | 'square'
  errors?: Errors
  onSave: (file?: UploadFile) => void
  onClose: () => void
}

export const UploadPhotoModal: FC<UploadPhotoModalProps> = ({
  open = false,
  uploading = false,
  title,
  src,
  shape = 'circle',
  errors,
  onSave,
  onClose
}) => {
  const [editorState, setEditorState] = useState<any>()
  const [photo, setPhoto] = useState<UploadFile>()
  const [viewPhoto, setViewPhoto] = useState<any>()
  const [loading, setLoading] = useState(false)
  const isMountedRef = useIsMountedRef()

  useEffect(() => {
    const fetchImage = async () => {
      if (src) {
        try {
          setLoading(true)
          const { result, status } = await request('get', src, undefined, {
            responseType: 'blob',
            timeout
          })
          if (status) {
            if (isMountedRef.current) {
              setViewPhoto(URL.createObjectURL(result))
            }
          }
        } finally {
          if (isMountedRef.current) setLoading(false)
        }
      } else setViewPhoto('')
    }
    if (open) fetchImage()
  }, [open, src, isMountedRef])

  const handleSave = useCallback(() => {
    if (editorState) {
      const canvas = editorState.current.getImageScaledToCanvas().toDataURL()

      try {
        setLoading(true)
        fetch(canvas)
          .then(res => res.blob())
          .then(blob => {
            const cropped: UploadFile = {
              ...photo,
              name: `${photo?.uid}.png`,
              type: 'image/png',
              size: blob.size,
              xhr: blob
            } as UploadFile

            typeof onSave === 'function' && onSave(cropped)
          })
      } finally {
        setLoading(false)
      }
    }
  }, [editorState, photo, onSave])

  const handleChange = useCallback((editorRef, photo, viewPhoto) => {
    setEditorState(editorRef)
    setPhoto(photo)
    setViewPhoto(viewPhoto)
  }, [])

  const handleClose = useCallback(() => {
    setPhoto(undefined)
    setViewPhoto('')
    typeof onClose === 'function' && onClose()
  }, [onClose])

  return (
    <Modal
      open={open}
      title={title}
      onCancel={handleClose}
      width={500}
      confirmLoading={uploading || loading}
      formId="form-upload"
    >
      <Form id="form-upload" onFinish={handleSave}>
        <PhotoEditor
          src={src}
          shape={shape}
          defaultPhoto={viewPhoto}
          onChange={handleChange}
          loading={loading}
          errors={errors}
        />
      </Form>
    </Modal>
  )
}
