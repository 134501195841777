import { setPayRunsView, setPayRunsViewLoading } from '../reducers'
import { apiGetPayRunsView } from '../api/payrun.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions, Pagination } from '~/types/common'

export const fetchPayRunsView = (
  viewId: string,
  pagination: Pagination,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!viewId) return

    const loading = getState().payroll.payRunsViewLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().payroll.payRunsView?.entities[viewId]
      if (hasData) return
    }

    try {
      dispatch(setPayRunsViewLoading(true))
      const { result, status } = await apiGetPayRunsView(viewId, { ...pagination })
      if (status) {
        dispatch(setPayRunsView(result))
      }
    } finally {
      dispatch(setPayRunsViewLoading(false))
    }
  }
}
