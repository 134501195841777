import React, { FC, useCallback, useState } from 'react'
import { EditOutlined } from '@ant-design/icons'
import { Card, ColumnsType, PageHeader, SecondaryLink, Space, Switch, Table } from '~/core-components'
import { DocumentTitle } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { Permission, PermissionAction } from '~/constants'
import { dispatch } from '~/stores/store'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { updateOtConfig } from '../../actions'
import { useOtConfigs } from '../../hooks'
import { OtConfigState } from '../../types'
import { EditOtConfigDrawer } from './EditOtConfigDrawer'
import './OtConfigs.less'

type OtConfigTable = OtConfigState

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: '',
    breadcrumbName: 'Overview'
  }
]

interface DrawerState {
  visible: boolean
  data?: OtConfigState
}
const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

interface OtConfigsProps {}

export const OtConfigs: FC<OtConfigsProps> = () => {
  const [otConfigs, loading] = useOtConfigs()
  const [updatingVisible, setUpdatingVisible] = useState<string>()
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const canModify = usePermissionGate(Permission.otConfig, PermissionAction.Modify)

  const handleVisibleChange = useCallback(async (otConfig: OtConfigState, isShown: boolean) => {
    setUpdatingVisible(otConfig.code)
    try {
      await dispatch(updateOtConfig(otConfig.code, otConfig, { ...otConfig, isShown }))
    } finally {
      setUpdatingVisible(undefined)
    }
  }, [])

  const handleEditName = useCallback((otConfig: OtConfigState) => {
    setDrawerState({ visible: true, data: otConfig })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const columns: ColumnsType<OtConfigTable> = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      render: (value: string, record) => (
        <Space>
          {value}
          <SecondaryLink onClick={() => handleEditName(record)}>
            <EditOutlined />
          </SecondaryLink>
        </Space>
      )
    },
    {
      title: 'Visible',
      key: 'isShown',
      dataIndex: 'isShown',
      align: 'center',
      width: 100,
      render: (value: boolean, record) => (
        <Switch
          checkedChildren="Shown"
          unCheckedChildren="Hidden"
          checked={record.isShown}
          loading={updatingVisible === record.code}
          disabled={!canModify}
          onChange={(checked: boolean) => {
            handleVisibleChange(record, checked)
          }}
        />
      )
    }
  ]

  return (
    <div id="ot-configs" className="ot-configs">
      <DocumentTitle title="Overtime Fields" />
      <PageHeader title="Overtime fields" containerId="ot-configs" breadcrumb={{ routes }} />
      <div className="ot-configs__body">
        <Card table>
          <Table
            rowKey="code"
            dataSource={otConfigs}
            pagination={false}
            columns={columns}
            loading={loading}
            rowClassName={record => (record.isShown ? 'ot-configs__table-row--shown' : '')}
          />
        </Card>
      </div>
      {canModify && <EditOtConfigDrawer {...drawerState} onClose={handleCloseDrawer} />}
    </div>
  )
}
