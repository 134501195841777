import React, { FC, useCallback, useEffect, useState } from 'react'
import { DrawerForm } from '~/components'
import { Screen, ViewCriteria } from '../types'
import { useViewSchema } from '../hooks'
import { ViewCriteriaAdvanced } from './ViewCriteriaAdvanced'

interface ViewCriteriaAdvancedDrawerProps {
  visible: boolean
  screenCode: Screen
  viewId?: string
  onApply: (criteria: ViewCriteria[]) => void
  onClose: () => void
}

export const ViewCriteriaAdvancedDrawer: FC<ViewCriteriaAdvancedDrawerProps> = ({
  visible,
  screenCode,
  viewId,
  onApply,
  onClose
}) => {
  const [schema] = useViewSchema(screenCode, viewId, 'when-empty')
  const [criteria, setCriteria] = useState<ViewCriteria[]>([])

  const reset = useCallback(() => {
    if (schema) {
      setCriteria(schema.criteria)
    }
  }, [schema])

  useEffect(() => reset(), [reset])

  const handleCriteriaChange = useCallback((criteria: ViewCriteria[]) => {
    setCriteria(criteria)
  }, [])

  const handleSave = useCallback(() => {
    typeof onApply === 'function' && onApply(criteria)
    typeof onClose === 'function' && onClose()
  }, [onApply, criteria, onClose])

  return (
    <DrawerForm
      open={visible}
      title="Filters"
      onOk={handleSave}
      onClose={onClose}
      okText="Apply"
      width={800}
      className="view-criteria-advanced-drawer"
    >
      <ViewCriteriaAdvanced screenCode={screenCode} viewId={viewId} label="" onChange={handleCriteriaChange} />
    </DrawerForm>
  )
}
