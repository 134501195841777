import React, { FC, useCallback, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { DatePickerProps } from 'antd/lib/date-picker'
import { Button, Input } from '~/core-components'
import './WeeklyNavigation.less'

export interface WeeklyNavigationProps {
  value?: moment.Moment
  defaultValue?: moment.Moment
  onChange?: (startDate: moment.Moment) => void
}

const format: DatePickerProps['format'] = value =>
  `${value.format('DD/MM')} ~ ${value.clone().add(6, 'days').format('DD/MM')}`

export const WeeklyNavigation: FC<WeeklyNavigationProps> = ({ value, defaultValue, onChange }) => {
  const [startDate, setStartDate] = useState(value ?? defaultValue ?? moment().startOf('isoWeek'))

  useEffect(() => {
    if (value) setStartDate(value)
  }, [value])

  const handlePrevious = useCallback(() => {
    setStartDate(startDate => {
      const updated = moment(startDate).subtract(1, 'week')
      typeof onChange === 'function' && onChange(updated)
      return updated
    })
  }, [onChange])

  const handleNext = useCallback(() => {
    setStartDate(startDate => {
      const updated = moment(startDate).add(1, 'week')
      typeof onChange === 'function' && onChange(updated)
      return updated
    })
  }, [onChange])

  const handleDateChange = useCallback(
    (value: moment.Moment | null) => {
      setStartDate(startDate => {
        const start = value ? value.startOf('isoWeek').isoWeekday(1) : null

        if (start !== null && !start.isSame(startDate)) {
          typeof onChange === 'function' && onChange(start)
          return start
        }

        return startDate
      })
    },
    [onChange]
  )

  return (
    <div className="weekly-navigation">
      <Button type="text" icon={<i className="fal fa-chevron-left" />} onClick={handlePrevious} />
      <Input.Date
        picker="week"
        format={format}
        allowClear={false}
        value={startDate ? moment(startDate) : null}
        onChange={handleDateChange}
      />
      <Button type="text" icon={<i className="fal fa-chevron-right" />} onClick={handleNext} />
    </div>
  )
}
