import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { SsAccessConfigState } from '../types'

export const selectSsAccessConfigs = createSelector(
  (state: StoreState) => state.master.ssAccessConfigs,
  ssAccessConfigState => {
    return Object.values(ssAccessConfigState?.entities || {}) as SsAccessConfigState[]
  }
)
