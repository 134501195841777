import React, { FC } from 'react'
import './Centered.less'

interface CenteredProps extends React.HTMLAttributes<HTMLDivElement> {}

export const Centered: FC<CenteredProps> = ({ children, ...props }) => {
  return (
    <div className="center-message" {...props}>
      {children}
    </div>
  )
}
