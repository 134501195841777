import { setClaimEntitlementsView, setClaimEntitlementsViewLoading } from '../reducers'
import { apiGetClaimEntitlementView } from '../api/claim-entitlement.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions, Pagination } from '~/types/common'

export const fetchClaimEntitlementView = (
  viewId: string,
  periodYear: number | undefined,
  pagination: Pagination,
  search: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!viewId || !periodYear) return

    const loading = getState().claim.claimEntitlementsViewLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().claim.claimEntitlementsView?.entities[viewId]
      if (hasData) return
    }

    try {
      dispatch(setClaimEntitlementsViewLoading(true))
      const { result, status } = await apiGetClaimEntitlementView(viewId, periodYear, { ...pagination, search })
      if (status) {
        dispatch(setClaimEntitlementsView(result))
      }
    } finally {
      dispatch(setClaimEntitlementsViewLoading(false))
    }
  }
}
