import { ActionResult, ThunkResult } from '~/types/store'
import { emptyGuid } from '~/constants'
import { showSuccess, showError } from '~/utils'
import { apiAddEmFamily } from '../api/em-family.api'
import { setEmFamily } from '../reducers'
import { IEmFamilyInfo } from '../types'
import { mapEmFamilyToEmFamilyState } from '../types/em-details.mapper'

export const addEmFamily = (employeeId: string, emFamily: IEmFamilyInfo): ThunkResult<void> => {
  return async (dispatch): Promise<ActionResult | undefined> => {
    if (!employeeId) return

    const { status, result, errors, message, errorData } = await apiAddEmFamily(employeeId, {
      id: emptyGuid,
      employeeId,
      ...emFamily
    })
    if (status) {
      const newEmFamily = { id: result.id, employeeId, ...emFamily }
      const newEmFamilyState = mapEmFamilyToEmFamilyState(newEmFamily)
      dispatch(setEmFamily({ employeeId, data: newEmFamilyState }))

      showSuccess('Added')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
