import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { FetchStrategy } from '~/types/common'
import { fetchCalendar } from '../actions'
import { WorkCalendarState } from '../types'

export const useCalendar = (
  id: string,
  fetchStrategy: FetchStrategy = 'always'
): [WorkCalendarState | undefined, boolean] => {
  const data = useSelector((state: StoreState) => state.attendance.calendars?.entities[id])
  const loading = useSelector((state: StoreState) => state.attendance.calendarsLoading)

  useEffect(() => {
    dispatch(fetchCalendar(id, { strategy: fetchStrategy }))
  }, [id, fetchStrategy])

  return [data, loading]
}
