import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { Ir21FormState } from '../types'

export const selectIr21Form = createSelector(
  (state: StoreState) => state.tax.ir21Form,
  ir21FormState =>
    memoize(
      (ir21SubmissionId: string, employeeTaxNo: string): Ir21FormState | undefined => {
        const form = ir21FormState?.entities[employeeTaxNo]
        if (form?.ir21SubmissionId === ir21SubmissionId) return form
      },
      (ir21SubmissionId: string, employeeTaxNo: string) => `${ir21SubmissionId}|${employeeTaxNo}`
    )
)
