import React, { FC } from 'react'
import { ColumnsType, Drawer, Space, Table, Text } from '~/core-components'
import { UnitType, formatDateRange, formatNumberUnit } from '~/utils'
import { useSysOptions } from '~/features/employee'
import { useROLeEarned } from '~/features/my/hooks'
import { useLeaveType } from '~/features/leave'
import { ROLeEarnedState } from '~/features/my/types'
import './ROLeEarnedDrawer.less'

interface ROLeEarnedDrawerProps {
  visible: boolean
  periodCode?: string
  employeeId?: string
  leaveTypeId?: string
  onClose: () => void
}

export const ROLeEarnedDrawer: FC<ROLeEarnedDrawerProps> = ({
  visible,
  periodCode,
  employeeId,
  leaveTypeId,
  onClose
}) => {
  const [data, loading] = useROLeEarned(periodCode, employeeId, leaveTypeId)
  const [leaveType] = useLeaveType(leaveTypeId || '')
  const [prorationTypes] = useSysOptions('lve_proration_type')

  const columns: ColumnsType<ROLeEarnedState> = [
    {
      title: 'Entitlement',
      key: 'entitlement',
      dataIndex: 'entitlement',
      render: (entitlement: number, record) => <>{formatNumberUnit(entitlement, record.entitlementUnit as UnitType)}</>
    },
    {
      title: `Prorated ${(prorationTypes[leaveType?.prorationType || '']?.value || '').toLowerCase()}`,
      key: 'earnedBefRound',
      dataIndex: 'earnedBefRound',
      render: (earnedBefRound: number, record) => {
        return (
          <>
            <div>
              <Space>
                <b>{formatNumberUnit(earnedBefRound, leaveType?.unit as UnitType)}</b>
              </Space>
            </div>
            <Text size="small" block>
              {formatDateRange(record.startDate, record.endDate)}
            </Text>
          </>
        )
      }
    }
  ]

  return (
    <Drawer open={visible} title="Entitled policy" onClose={onClose} width="800px" className="ro-le-earned-drawer">
      <Table rowKey="id" dataSource={data} pagination={false} columns={columns} loading={loading} />
    </Drawer>
  )
}
