import { replaceAttendancePeriodsForPayroll, setAttendancePeriodsForPayrollLoading } from '../reducers'
import { apiGetAttendancePeriodsForPayroll } from '../api/attendance-period.api'
import { ThunkResult } from '~/types/store'

export const fetchAttendancePeriodsForPayroll = (payPeriod?: string): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().attendance.attendancePeriodsForPayrollLoading
    if (loading) return

    try {
      dispatch(setAttendancePeriodsForPayrollLoading(true))
      const { result, status } = await apiGetAttendancePeriodsForPayroll(payPeriod)
      if (status) {
        dispatch(replaceAttendancePeriodsForPayroll(result))
      }
    } finally {
      dispatch(setAttendancePeriodsForPayrollLoading(false))
    }
  }
}
