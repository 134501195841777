import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { UploadFile } from '~/core-components'
import { apiUploadEmployeePhoto } from '../api/employee.api'
import { EmployeeState } from '../types'
import { setEmployee } from '../reducers'

export const uploadEmployeePhoto = (id: string, file: UploadFile): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const { status, result, errors, message, errorData } = await apiUploadEmployeePhoto(id, file)
    if (status) {
      const employee = getState().employee.employees.entities[id]
      dispatch(setEmployee({ ...(employee as EmployeeState), photoId: result?.photoId }))
      showSuccess('Photo saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors, result }
  }
}
