import { replacePayItems, setPayItemsLoading } from '../reducers'
import { apiGetPayItems } from '../api/payitem.api'
import { ThunkResult } from '~/types/store'
import { Country, DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'
import { mapPayItemToPayItemState } from '../types/payitem.mapper'

export const fetchPayItems = (country: Country, options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().master.payItemsLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().master.payItems?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setPayItemsLoading(true))
      const { result, status } = await apiGetPayItems()
      if (status) {
        const payItems = result.map(dto => mapPayItemToPayItemState(dto, country))
        dispatch(replacePayItems(payItems))
      }
    } finally {
      dispatch(setPayItemsLoading(false))
    }
  }
}
