import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchMyLeaveRecord } from '../../actions'
import { SSLeaveRecordState } from '../../types'

export const useMyLeaveRecord = (id?: string): [SSLeaveRecordState | undefined, boolean] => {
  const leaveRecord = useSelector((state: StoreState) => state.my.myLeaveRecords?.entities[id || ''])
  const loading = useSelector((state: StoreState) => state.my.myLeaveRecordsLoading)

  useEffect(() => {
    if (id) {
      dispatch(fetchMyLeaveRecord(id))
    }
  }, [id])

  return [leaveRecord, loading]
}
