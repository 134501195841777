import React, { FC } from 'react'
import classNames from 'classnames'
import { RenderFunction, TooltipProps } from 'antd/lib/tooltip'
import { Tooltip } from '~/core-components'
import './InfoTooltip.less'

export type InfoTooltipProps = {
  title: string | React.ReactNode | RenderFunction
  className?: string
  icon?: string
  onClick?: () => void
} & TooltipProps

export const InfoTooltip: FC<InfoTooltipProps> = ({
  title,
  className,
  icon = 'fa-circle-info',
  onClick,
  ...tooltipProps
}) => {
  const classes = classNames('info-tooltip', { 'info-tooltip--clickable': !!onClick }, 'fal', icon, className)

  return (
    <Tooltip title={title} {...tooltipProps}>
      <i className={classes} onClick={onClick} />
    </Tooltip>
  )
}
