import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'

export const selectCurrentEmAttendanceGroup = createSelector(
  (state: StoreState) => state.employee.emAttendanceGroups,
  emAttendanceGroupsState =>
    memoize(
      (employeeId?: string, id?: string) => {
        if (!employeeId || !id) return undefined

        return emAttendanceGroupsState[employeeId]?.entities[id]
      },
      (employeeId?: string, id?: string) => `${employeeId}|${id}`
    )
)

export const selectHistoryEmAttendanceGroups = createSelector(
  (state: StoreState) => state.employee.emAttendanceGroups,
  emAttendanceGroupsState =>
    memoize(
      (employeeId?: string, excludeId?: string) => {
        if (!employeeId) return []

        return Object.values(emAttendanceGroupsState[employeeId]?.entities || {})
          .filter(ematg => ematg?.id !== excludeId)
          .sort((a, b) => {
            const aStartDate = new Date(a!.startDate).getTime()
            const bStartDate = new Date(b!.startDate).getTime()
            return bStartDate - aStartDate
          })
      },
      (employeeId?: string, excludeId?: string) => `${employeeId}|${excludeId}`
    )
)
