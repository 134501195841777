import { EntityState } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { SSTimeLogTaskState } from './ss-time-log-task.state'
import { SSTimeLogApprovalHistoryState, SSTimeLogState } from './ss-time-log.state'

export const myAttendancePersistConfig = {
  key: 'myattendance',
  whitelist: [],
  storage
}

export interface MyAttendanceRootState {
  myTimeLogTasks: EntityState<SSTimeLogTaskState>
  myTimeLogTasksLoading: boolean
  myTimeLogTasksRefetch: number

  myTimeLogs: EntityState<SSTimeLogState>
  myTimeLogsLoading: boolean
  myTimeLogsRefetch: number

  myTimeLogApprovalHistories: { [id: string]: EntityState<SSTimeLogApprovalHistoryState> }
  myTimeLogApprovalHistoriesLoading: { [id: string]: boolean }
}
