import React, { CSSProperties, FC, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'
import confirm from 'antd/lib/modal/confirm'
import { Button, PageHeader, SecondaryLink, Space, Tabs, Tooltip } from '~/core-components'
import { DocumentTitle } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { TAX_ROUTES } from '~/routes/routes'
import { Permission, PermissionAction, Ir21SubmissionStatus } from '~/constants'
import { dispatch } from '~/stores/store'
import { ActionResult, StoreState } from '~/types/store'
import { deleteIr21Submission } from '../../actions'
import { refetchIr21SubmissionViews, setIr21SubmissionExpanded } from '../../reducers'
import { useIr21Submission } from '../../hooks'
import { Ir21Records } from './Ir21Records/Ir21Records'
import { Ir21Tags } from './Ir21Tags'
import { Ir21A1Records } from './Ir21A1Records/Ir21A1Records'
import './Ir21Submission.less'

interface Ir21SubmissionProps {}

const routes = [
  {
    path: TAX_ROUTES.tab.replace(':tab?', 'submission'),
    breadcrumbName: 'Overview'
  },
  {
    path: '',
    breadcrumbName: 'IR21 submission'
  }
]

export const Ir21Submission: FC<Ir21SubmissionProps> = () => {
  const match = useRouteMatch<{ id: string; tab: string }>()
  const id = match.params.id
  const [ir21Submission] = useIr21Submission(id, 'always')
  const title = ir21Submission
    ? `IR21 ${ir21Submission?.ytdYear} - ${ir21Submission?.companyName} (${ir21Submission?.employerTaxNo})`
    : 'IR21'

  const history = useHistory()
  const expanded = useSelector((state: StoreState) => state.tax.ir21SubmissionExpanded)
  const canDelete = usePermissionGate(Permission.ytd, PermissionAction.Delete)

  const handleTabChange = useCallback(
    (activeKey: string) => {
      history.push(`${TAX_ROUTES.ir21Submission.replace(':id', id).replace(':tab?', activeKey)}`)
    },
    [id, history]
  )

  const handleDeleteIr21Submission = useCallback(() => {
    if (!id) return

    confirm({
      title: 'Delete',
      content: (
        <>
          <div>Do you want to delete this submission?</div>
          <br />
          <div>This will also delete (if any) the following:</div>
          <div>&#9;- IR21</div>
          <div>&#9;- Appendix 1</div>
        </>
      ),
      onOk: async () => {
        const result: ActionResult | undefined = await dispatch(deleteIr21Submission(id))

        if (!result?.errors) {
          dispatch(refetchIr21SubmissionViews())
          history.push(TAX_ROUTES.tab.replace(':tab?', 'submission'))
        }
      },
      width: 550,
      okText: 'Delete',
      okType: 'danger'
    })
  }, [id, history])

  return (
    <div className="ir21-submission">
      <DocumentTitle title={title} />
      {!expanded ? (
        <div className="ir21-submission__header">
          <PageHeader
            title={
              <Space align="center">
                <span>{title}</span>
                <Ir21Tags ir21Submission={ir21Submission} />
              </Space>
            }
            breadcrumb={{ routes }}
          />
        </div>
      ) : (
        <div className="ir21-submission__header ir21-submission__header--collapsed">{title}</div>
      )}
      <div className="ir21-submission__body">
        <Tabs
          defaultActiveKey={match.params.tab || 'ir21'}
          onChange={handleTabChange}
          tabBarExtraContent={{
            left: <Ir21SubmissionExpandButton isExpand={expanded} />,
            right: (
              <Space>
                {ir21Submission?.status === Ir21SubmissionStatus.draft && canDelete && (
                  <Button size="small" onClick={handleDeleteIr21Submission}>
                    Delete submission
                  </Button>
                )}
              </Space>
            )
          }}
          items={[
            { key: 'ir21', label: 'IR21', children: <Ir21Records ir21Submission={ir21Submission} /> },
            { key: 'a1', label: 'Appendix 1', children: <Ir21A1Records ir21Submission={ir21Submission} /> }
          ]}
        />
      </div>
    </div>
  )
}

const expandButtonStyle: CSSProperties = { marginRight: 16 }

const Ir21SubmissionExpandButton: FC<{ isExpand: boolean }> = ({ isExpand }) => {
  const handleExpand = useCallback(() => {
    dispatch(setIr21SubmissionExpanded(!isExpand))
  }, [isExpand])

  return (
    <Tooltip title={isExpand ? 'Normal view' : 'Maximise view'}>
      <SecondaryLink style={expandButtonStyle} onClick={handleExpand}>
        {isExpand ? (
          <i className="fal fa-arrow-down-left-and-arrow-up-right-to-center" />
        ) : (
          <i className="fal fa-arrow-up-right-and-arrow-down-left-from-center" />
        )}
      </SecondaryLink>
    </Tooltip>
  )
}
