import React, { FC, useCallback, useState } from 'react'
import { EmSelect, SelectEmployees } from '~/components'
import { Radio, RadioChangeEvent, Space } from '~/core-components'
import { fetchScheduleRecordEmSelections } from '~/features/schedule/actions'
import { apiGetScheduleRecordEmSelectionsAll } from '~/features/schedule/api/schedule-record.api'
import { dispatch } from '~/stores/store'
import { Pagination } from '~/types/common'

export interface BulkAddScheduleEmRadioProps {
  startDate: string
  endDate: string
  employeeIds: string[]
  onChange: (choice: BulkAddScheduleEmRadioChoice, employeeIds: string[]) => void
}

export type BulkAddScheduleEmRadioChoice = 'one' | 'all' | 'some'

export const BulkAddScheduleEmRadio: FC<BulkAddScheduleEmRadioProps> = ({
  startDate,
  endDate,
  employeeIds,
  onChange
}) => {
  const [choice, setChoice] = useState<BulkAddScheduleEmRadioChoice>('one')

  const handleEmFilterChange = useCallback(
    (event: RadioChangeEvent) => {
      const choice = event.target.value
      setChoice(choice)

      if (choice === 'one') {
        typeof onChange === 'function' &&
          onChange(choice, employeeIds && employeeIds.length > 0 ? [employeeIds[0]] : [])
      } else if (choice === 'all') {
        typeof onChange === 'function' && onChange(choice, [])
      }
    },
    [onChange, employeeIds]
  )

  const handleFetchAllEmployees = useCallback(async () => {
    if (!startDate || !endDate) return []

    const { status, result } = await apiGetScheduleRecordEmSelectionsAll(startDate, endDate)
    if (status) {
      return result
    }
    return []
  }, [startDate, endDate])

  const handleFetchEmployees = useCallback(
    (viewId: string, pagination: Pagination, search: string) => {
      dispatch(fetchScheduleRecordEmSelections(startDate, endDate, viewId, pagination, search))
    },
    [startDate, endDate]
  )

  const handleSelectEmployee = useCallback(
    (employeeId: string) => {
      typeof onChange === 'function' && onChange(choice, [employeeId])
    },
    [onChange, choice]
  )

  const handleSelectEmployees = useCallback(
    (employeeIds: string[]) => {
      typeof onChange === 'function' && onChange(choice, employeeIds)
    },
    [onChange, choice]
  )

  return (
    <Radio.Group value={choice} onChange={handleEmFilterChange}>
      <Space direction="vertical">
        <Radio value="one">
          <Space direction="vertical">
            Employee
            {choice === 'one' && (
              <EmSelect
                style={{ width: '430px' }}
                value={employeeIds && employeeIds.length > 0 ? employeeIds[0] : undefined}
                onFetch={handleFetchAllEmployees}
                onChange={handleSelectEmployee}
              />
            )}
          </Space>
        </Radio>
        <Radio value="all">All employees</Radio>
        <Radio value="some">
          <Space direction="vertical" size={0}>
            Specific employees
            {choice === 'some' && (
              <SelectEmployees
                viewName="schedule_record_initialise"
                employeeIds={employeeIds || []}
                onFetchData={handleFetchEmployees}
                onSelect={handleSelectEmployees}
              />
            )}
          </Space>
        </Radio>
      </Space>
    </Radio.Group>
  )
}
