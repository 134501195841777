import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiDeleteEmOtClass } from '../api/em-ot-class.api'
import { removeEmOtClass, setEmployee } from '../reducers'

export const deleteEmOtClass = (employeeId: string, id: string): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!employeeId || !id) return

    const { result, status, errors, message, errorData } = await apiDeleteEmOtClass(employeeId, id)
    if (status) {
      if (result.emOtClassId) {
        const employee = getState().employee.employees.entities[employeeId]
        if (employee) {
          dispatch(setEmployee({ ...employee, emOtClassId: result.emOtClassId }))
        }
      }

      dispatch(removeEmOtClass({ employeeId, id }))
      showSuccess('Deleted')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
