import React, { ChangeEvent, CSSProperties, FC, FocusEvent, useCallback, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { Form, Input } from '~/core-components'
import { Col, EditableCard, EditableCardState, Row } from '~/components'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { useFocus } from '~/hooks/use-focus'
import { dispatch } from '~/stores/store'
import { Errors, ActionResult } from '~/types/store'
import { IEmployeeProbation, EmployeeState } from '../../../types'
import { mapEmployeeStateToEmployeeProbation } from '../../../types/employee.mapper'
import { updateEmployeeProbation } from '../../../actions'

interface EmployeeProbationProps {
  employee?: EmployeeState
  onEdit?: () => void
  onSave?: () => void
  onCancel?: () => void
}

const EMPTY_FORM_DATA: IEmployeeProbation = {
  probationPeriod: 0,
  probationEndDate: '',
  probationNotes: '',
  confirmationDate: ''
}

const cardStyle: CSSProperties = { margin: 24 }

export const EmployeeProbation: FC<EmployeeProbationProps> = ({
  employee,
  onEdit,
  onSave,
  onCancel
}: EmployeeProbationProps) => {
  const [cardState, setCardState] = useState<EditableCardState>()
  const [formData, setFormData] = useState<IEmployeeProbation>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const [focusRef] = useFocus(cardState === 'editing')
  const canView = usePermissionGate(Permission.employeeProbation)
  const canModify = usePermissionGate(Permission.employeeProbation, PermissionAction.Modify)
  const readOnly = cardState !== 'editing' && cardState !== 'saving'

  useEffect(() => {
    if (employee) {
      const { probationPeriod, probationEndDate, probationNotes, confirmationDate } = employee
      setFormData({ probationPeriod, probationEndDate, probationNotes, confirmationDate })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [employee])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => setFormData(formData => ({ ...formData, ...updates })),
    []
  )

  const handleEdit = useCallback(() => {
    setCardState('editing')
    typeof onEdit === 'function' && onEdit()
  }, [onEdit])

  const handleSave = useCallback(async () => {
    if (employee) {
      setCardState('saving')
      setErrors(undefined)

      typeof onSave === 'function' && onSave()

      let result: ActionResult | undefined
      try {
        result = await dispatch(
          updateEmployeeProbation(employee.id, mapEmployeeStateToEmployeeProbation(employee), formData)
        )
      } catch {
        setCardState('editing')
      }

      if (result?.errors) {
        setCardState('editing')
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setCardState(undefined)
      }
    }
  }, [employee, formData, onSave])

  const handleCancel = useCallback(() => {
    typeof onCancel === 'function' && onCancel()
    setCardState(undefined)
    setErrors(undefined)

    if (employee) {
      const { probationPeriod, probationEndDate, probationNotes, confirmationDate } = employee
      setFormData({ probationPeriod, probationEndDate, probationNotes, confirmationDate })
    }
  }, [employee, onCancel])

  if (!canView) return null

  return (
    <EditableCard
      title="Probation"
      style={cardStyle}
      bodyStyle={{ paddingBottom: employee ? 6 : 24, paddingTop: 6 }}
      state={canModify ? cardState : 'readonly'}
      formId="form-em-probation"
      onEdit={handleEdit}
      onSave={handleSave}
      onCancel={handleCancel}
    >
      <Row gutter={30}>
        <Col span={12}>
          <Form.Item
            label="Probation period (in months)"
            validateStatus={errors?.probationPeriod ? 'error' : ''}
            help={errors?.probationPeriod}
          >
            <Input.Number
              ref={focusRef}
              precision={0}
              value={formData.probationPeriod}
              readOnly={readOnly}
              min={0}
              onChange={(value: number | null) => handleFormDataChange({ probationPeriod: value || 0 })}
              onBlur={(event: FocusEvent<HTMLInputElement>) => {
                const probationPeriod = Number(event.target.value || 0)

                if (probationPeriod > 0 && employee?.hireDate != null && formData?.probationEndDate == null) {
                  const probationEndDate = moment(employee.hireDate, 'YYYY-MM-DD')
                    .add(probationPeriod, 'months')
                    .add(-1, 'day')
                    .format('YYYY-MM-DD')
                  handleFormDataChange({ probationEndDate: probationEndDate })
                }
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Probation end">
            <Input.Date
              value={formData.probationEndDate ? moment(formData.probationEndDate) : undefined}
              inputReadOnly={readOnly}
              onChange={(value: moment.Moment | null) =>
                handleFormDataChange({ probationEndDate: value?.format('YYYY-MM-DD') })
              }
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Confirmation date">
            <Input.Date
              value={formData.confirmationDate ? moment(formData.confirmationDate) : undefined}
              inputReadOnly={readOnly}
              onChange={(value: moment.Moment | null) =>
                handleFormDataChange({ confirmationDate: value?.format('YYYY-MM-DD') })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30} hidden={readOnly && !formData.probationNotes}>
        <Col span={24}>
          <Form.Item label="Probation notes">
            <Form.Item noStyle>
              <Input.TextArea
                value={formData.probationNotes}
                readOnly={readOnly}
                rows={4}
                onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                  handleFormDataChange({ probationNotes: event.target.value })
                }
              />
            </Form.Item>
          </Form.Item>
        </Col>
      </Row>
    </EditableCard>
  )
}
