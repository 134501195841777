import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import persistReducer from 'redux-persist/es/persistReducer'
import storage from 'redux-persist/lib/storage'
import { LocaleState, Language } from './types'

const initialState: LocaleState = {
  locale: 'en_US'
}

const localeSlice = createSlice({
  name: 'locale',
  initialState,
  reducers: {
    setLocale: (state, action: PayloadAction<Language>) => {
      state.locale = action.payload
    }
  }
})

export const { setLocale } = localeSlice.actions

export const localeReducer = localeSlice.reducer

const persistConfig = {
  key: 'locale',
  whitelist: ['locale'],
  storage
}

export const localeReducers = {
  locale: persistReducer(persistConfig, localeReducer)
}
