import React, { CSSProperties, FC, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { SecondaryLink, Tab, TabTitle, Tabs, Tooltip } from '~/core-components'
import { DocumentTitle } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { StoreState } from '~/types/store'
import { dispatch } from '~/stores/store'
import { CLA_ROUTES } from '~/routes/routes'
import { Permission } from '~/constants'
import {
  setClaimExpanded,
  refetchClaimEntitlementsView,
  refetchClaimRecordsView,
  refetchClaimPendingApprovalsView,
  refetchClaimPendingPaymentsView
} from '../../reducers'
import { ClaimEntitlements } from '../ClaimEntitlements/ClaimEntitlements'
import { ClaimRecords } from '../ClaimRecords/ClaimRecords'
import { ClaimPendingApprovals } from '../ClaimPendingApprovals/ClaimPendingApprovals'
import { ClaimPendingPayments } from '../ClaimPendingPayments/ClaimPendingPayments'
import './ClaimOverview.less'

export interface ClaimOverviewProps {}

export const ClaimOverview: FC<ClaimOverviewProps> = () => {
  const match = useRouteMatch<{ tab: string }>()
  const expanded = useSelector((state: StoreState) => state.claim.claimExpanded)
  const history = useHistory()
  const canViewEntitlement = usePermissionGate(Permission.claEntitlement)
  const canViewRecord = usePermissionGate(Permission.claRecord)

  const handleTabChange = useCallback(
    (activeKey: string) => {
      history.push(`${CLA_ROUTES.tab.replace(':tab?', activeKey)}`)

      if (activeKey === 'entitlement') {
        dispatch(refetchClaimEntitlementsView())
      } else if (activeKey === 'records') {
        dispatch(refetchClaimRecordsView())
      } else if (activeKey === 'pending-approvals') {
        dispatch(refetchClaimPendingApprovalsView())
      } else if (activeKey === 'pending-payments') {
        dispatch(refetchClaimPendingPaymentsView())
      }
    },
    [history]
  )

  const tabItems = useMemo(() => {
    const result: Tab[] = []
    if (canViewEntitlement) {
      result.push({
        key: 'entitlement',
        label: 'Entitlement',
        children: <ClaimEntitlements />
      })
    }
    if (canViewRecord) {
      result.push(
        {
          key: 'records',
          label: 'Records',
          children: <ClaimRecords />
        },
        {
          key: 'pending-approvals',
          label: 'Pending approvals',
          children: <ClaimPendingApprovals />
        },
        {
          key: 'pending-payments',
          label: 'Pending payments',
          children: <ClaimPendingPayments />
        }
      )
    }
    return result
  }, [canViewEntitlement, canViewRecord])

  return (
    <div className="claim-overview">
      <DocumentTitle title="Claim Overview" />
      <div className="claim-overview__body">
        <Tabs
          defaultActiveKey={match.params.tab || 'entitlement'}
          onChange={handleTabChange}
          tabBarExtraContent={{
            left: <TabTitle left={<ClaimExpandButton isExpand={expanded} />} title="Claim overview" />
          }}
          items={tabItems}
        />
      </div>
    </div>
  )
}

const expandButtonStyle: CSSProperties = { marginRight: 16 }

const ClaimExpandButton: FC<{ isExpand: boolean }> = ({ isExpand }) => {
  const handleExpand = useCallback(() => {
    dispatch(setClaimExpanded(!isExpand))
  }, [isExpand])

  return (
    <Tooltip title={isExpand ? 'Normal view' : 'Maximise view'}>
      <SecondaryLink style={expandButtonStyle} onClick={handleExpand}>
        {isExpand ? (
          <i className="fal fa-arrow-down-left-and-arrow-up-right-to-center" />
        ) : (
          <i className="fal fa-arrow-up-right-and-arrow-down-left-from-center" />
        )}
      </SecondaryLink>
    </Tooltip>
  )
}
