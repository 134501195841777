import React, { forwardRef } from 'react'
import range from 'lodash/range'
import classNames from 'classnames'
import { Select, SelectProps } from '../../core-components'
import { ordinalSuffix } from '../../utils/ordinalSuffix'
import './DayKeyValues.less'

interface DayKeyValuesProps extends SelectProps {}

const DAY_NOS = range(1, 32)

const DayKeyValuesInternal = ({ className, ...props }: DayKeyValuesProps, ref: React.Ref<any>) => {
  const classes = classNames('daykeyvalues', { [`${className}`]: className })

  return (
    <Select ref={ref} showSearch className={classes} optionFilterProp="title" {...props}>
      {DAY_NOS.map(day => (
        <Select.Option key={day} value={day} title={day.toString()}>
          {ordinalSuffix(day)}
        </Select.Option>
      ))}
    </Select>
  )
}

export const DayKeyValues = forwardRef(DayKeyValuesInternal)
