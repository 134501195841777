import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { EmploymentConfigState } from '../types'

export const selectHiddenEmploymentConfigs = createSelector(
  (state: StoreState) => state.master.employmentConfigs,
  (employmentConfigState): EmploymentConfigState[] => {
    const employmentConfigs = Object.values(employmentConfigState?.entities || {}) as EmploymentConfigState[]
    return employmentConfigs.filter(ec => !ec.isShown).sort((a, b) => (a.sequence || 0) - (b.sequence || 0))
  }
)
