import { IFormula, IFormulaDetailInfo } from './formula.dto'
import { FormulaState, FormulaDetailState } from './formula.state'

export const mapFormulaStateToFormula = (formulaDetail: FormulaState): IFormula => ({
  name: formulaDetail.name,
  description: formulaDetail.description,
  rateType: formulaDetail.rateType
})

export const mapFormulaDetailStateToFormulaDetail = (formulaDetail: FormulaDetailState): IFormulaDetailInfo => ({
  sequence: formulaDetail.sequence,
  expression: formulaDetail.expression,
  criteria: formulaDetail.criteria,
  prorationType: formulaDetail.prorationType
})
