import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { SelfUpdateConfigState } from '../types'

export const selectSelfUpdateConfigs = createSelector(
  (state: StoreState) => state.master.selfUpdateConfigs,
  (selfUpdateConfigState): SelfUpdateConfigState[] => {
    const selfUpdateConfigs = Object.values(selfUpdateConfigState?.entities || {}) as SelfUpdateConfigState[]
    return selfUpdateConfigs.sort((a, b) => (a.sequence || 0) - (b.sequence || 0))
  }
)
