import { request, getBaseUrl } from '~/utils'
import { ITaxAmount, ITaxRate } from '../types'

const baseUrl = getBaseUrl('/claim/taxrate')

export const apiGetCurrentTaxRate = (currencyCode: string, asAtDate: string) =>
  request<ITaxRate>('get', `${baseUrl}/rate`, { currencyCode, asAtDate })

export const apiGetTaxAmount = (taxAmount: ITaxAmount) => {
  return request<number>('get', `${baseUrl}/taxamount`, taxAmount)
}
