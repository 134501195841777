import React, { forwardRef } from 'react'
import { default as AntSegmented, SegmentedProps as AntSegmentedProps } from 'antd/lib/segmented'
import { SegmentedValue as AntSegmentedValue } from 'antd/lib/segmented'
import 'antd/es/segmented/style'

export interface SegmentedProps extends Omit<AntSegmentedProps, 'ref'> {}
export type SegmentedValue = AntSegmentedValue

const SegmentedInternal = (props: SegmentedProps, ref: React.Ref<HTMLDivElement> | undefined) => (
  <AntSegmented ref={ref} {...props} />
)

export const Segmented = forwardRef(SegmentedInternal)
