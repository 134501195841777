import React, { FC } from 'react'
import { default as AntRadioGroup } from 'antd/lib/radio/group'
import { RadioGroupProps as AntRadioGroupProps } from 'antd/lib/radio/interface'
import classNames from 'classnames'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from '../Spin/Spin'
import './RadioGroup.less'

export interface RadioGroupProps extends AntRadioGroupProps {
  readOnly?: boolean
  loading?: boolean
}
export const RadioGroup: FC<RadioGroupProps> = ({ readOnly, loading, className, ...props }) => {
  const classes = classNames({ [`radio--readonly`]: readOnly }, { [`${className}`]: className })
  return loading ? (
    <Spin indicator={<LoadingOutlined spin />} />
  ) : (
    <AntRadioGroup {...props} className={classes} onChange={readOnly ? undefined : props.onChange} />
  )
}
