import { ThunkResult } from '~/types/store'
import { apiGetXeroAuthUrl } from '../api/xero.api'
import { setXeroRedirectUrl } from '../reducers'
import { showError } from '~/utils'

export const initiateXeroAuthentication = (): ThunkResult<void> => {
  return async dispatch => {
    const { result } = await apiGetXeroAuthUrl()
    if (result) {
      dispatch(setXeroRedirectUrl(window.location.href))
      window.location.href = result
    } else {
      showError('Failed to get Xero authentication URL')
    }
  }
}
