import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { CalendarEvent } from '~/types/common'
import { fetchMyCalendarLeaveEvents } from '../../actions'
import { selectMyCalendarLeaveEvents } from '../../selectors'

export const useMyCalendarLeaveEvents = (
  viewAs: string,
  startDate: string,
  endDate: string
): [CalendarEvent[] | undefined, boolean] => {
  const data = useSelector(selectMyCalendarLeaveEvents)
  const loading = useSelector((state: StoreState) => state.my.myCalendarLeaveEventsLoading)
  const refetch = useSelector((state: StoreState) => state.my.myCalendarLeaveEventsRefetch)

  useEffect(() => {
    dispatch(fetchMyCalendarLeaveEvents(viewAs, { startDate, endDate }))
  }, [startDate, endDate, viewAs, refetch])

  return [data, loading]
}
