import { showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiUnpublishPayRecord } from '../api/payrecord-sg.api'

export const unpublishPayRecord = (id: string): ThunkResult<void> => {
  return async () => {
    if (!id) return

    const { status, errors, message, errorData } = await apiUnpublishPayRecord(id)
    if (status) {
      return { result: true }
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
