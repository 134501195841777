import { setEmSelection, setEmSelectionLoading } from '~/features/employee'
import { apiGetPayRunNewEmSelections } from '../api/payrun.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions, Pagination } from '~/types/common'

export const fetchPayRunNewEmSelections = (
  payRunId: string,
  viewId: string,
  pagination: Pagination,
  search: string,
  pastResignees: boolean,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!payRunId || !viewId) return

    const loading = getState().employee.emSelectionLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().employee.emSelection?.entities[viewId]
      if (hasData) return
    }

    try {
      dispatch(setEmSelectionLoading(true))
      const { result, status } = await apiGetPayRunNewEmSelections(payRunId, viewId, {
        ...pagination,
        search,
        pastResignees
      })
      if (status) {
        dispatch(setEmSelection(result))
      }
    } finally {
      dispatch(setEmSelectionLoading(false))
    }
  }
}
