import { ActionResult, ThunkResult } from '~/types/store'
import { emptyGuid } from '~/constants'
import { showSuccess, showError } from '~/utils'
import { apiAddEmEducation } from '../api/em-education.api'
import { replaceEmEducations, setEmEducation } from '../reducers'
import { IEmEducationInfo, EmEducationState } from '../types'
import { getState } from '~/stores/store'

export const addEmEducation = (employeeId: string, emEducation: IEmEducationInfo): ThunkResult<void> => {
  return async (dispatch): Promise<ActionResult | undefined> => {
    if (!employeeId) return

    const { status, result, errors, message, errorData } = await apiAddEmEducation(employeeId, {
      id: emptyGuid,
      employeeId,
      ...emEducation
    })
    if (status) {
      const newEmEducation = { id: result.id, employeeId, ...emEducation } as EmEducationState

      if (newEmEducation.isHighest) {
        const emEducations = Object.values(getState().employee.emEducations[employeeId]?.entities || {}).map(
          a => ({ ...a, isHighest: false } as EmEducationState)
        )
        emEducations.push(newEmEducation)
        dispatch(replaceEmEducations({ employeeId, data: emEducations }))
      } else {
        dispatch(setEmEducation({ employeeId, data: newEmEducation }))
      }

      showSuccess('Added')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
