import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { BreadcrumbProps } from 'antd/lib/breadcrumb'
import { ArrowLeftOutlined } from '@ant-design/icons'
import './BackLink.less'

export interface BackLinkProps {
  breadcrumb?: BreadcrumbProps
  className?: string
}

export const BackLink: FC<BackLinkProps> = ({ breadcrumb, className }) => {
  const classes = classNames('back-link', { [`${className}`]: className })

  return (
    <>
      {breadcrumb?.routes && breadcrumb.routes.length > 1 && (
        <Link className={classes} to={breadcrumb.routes[breadcrumb.routes.length - 2].path}>
          <ArrowLeftOutlined />
          <label>back</label>
        </Link>
      )}
    </>
  )
}
