import { setPayRunPayslip, setPayRunPayslipLoading } from '../reducers'
import { apiGetPayRunPayslip } from '../api/payrun-payslip.api'
import { ThunkResult } from '~/types/store'
import { mapIPayRunPayslipToPayRunPayslipState } from '../types'

export const fetchPayRunPayslip = (payRunId: string): ThunkResult<void> => {
  return async dispatch => {
    if (!payRunId) return

    try {
      dispatch(setPayRunPayslipLoading({ payRunId, loading: true }))
      const { result, status } = await apiGetPayRunPayslip(payRunId)
      if (status) {
        dispatch(setPayRunPayslip({ payRunId, data: mapIPayRunPayslipToPayRunPayslipState(result) }))
      }
    } finally {
      dispatch(setPayRunPayslipLoading({ payRunId, loading: false }))
    }
  }
}
