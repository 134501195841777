import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiDeleteFormulaDetail } from '../api/formula.api'
import { removeFormulaDetail } from '../reducers'

export const deleteFormulaDetail = (formulaId: string, id: string): ThunkResult<void> => {
  return async dispatch => {
    if (!formulaId || !id) return

    const { status, errors, message, errorData } = await apiDeleteFormulaDetail(formulaId, id)
    if (status) {
      dispatch(removeFormulaDetail(id))
      showSuccess('Deleted')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
