import { request, getBaseUrl } from '~/utils/request'
import { SSLeaveBuddyConflictState, SSLeaveTaskState } from '../types'

const baseUrl = getBaseUrl('/leave/ssleavetask')

export const apiGetMyLeaveTasks = () => request<SSLeaveTaskState[]>('get', baseUrl)

export const apiGetMyLeaveTask = (id: string) => request<SSLeaveTaskState>('get', `${baseUrl}/${id}`)

export const apiGetLeaveBuddyConflicts = (id: string) =>
  request<SSLeaveBuddyConflictState[]>('get', `${baseUrl}/${id}/buddies`)

export const apiApproveLeave = (id: string, comment: string) => request('post', `${baseUrl}/${id}/approve`, { comment })

export const apiRejectLeave = (id: string, comment: string) => request('post', `${baseUrl}/${id}/reject`, { comment })
