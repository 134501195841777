import React, { FC, useCallback, useEffect, useState } from 'react'
import { Select } from '~/core-components'
import { useROLeavePeriodYears } from '~/features/my/hooks'

interface ROLeavePeriodYearsProps {
  onChange: (periodYear: number) => void
}

export const ROLeavePeriodYears: FC<ROLeavePeriodYearsProps> = ({ onChange }) => {
  const [periodYear, setPeriodYear] = useState<number>()
  const [roLeavePeriods] = useROLeavePeriodYears()
  const defaultLeavePeriod = roLeavePeriods.length > 0 ? roLeavePeriods[0].periodYear : undefined

  useEffect(() => {
    if (defaultLeavePeriod && !isNaN(defaultLeavePeriod)) {
      setPeriodYear(defaultLeavePeriod)
    }
  }, [defaultLeavePeriod])

  useEffect(() => {
    if (periodYear) {
      typeof onChange === 'function' && onChange(periodYear)
    }
  }, [periodYear, onChange])

  const handlePeriodChange = useCallback(periodYear => {
    setPeriodYear(periodYear)
  }, [])

  return (
    <Select
      className="period-input"
      allowClear={false}
      dropdownMatchSelectWidth={false}
      value={periodYear}
      onChange={handlePeriodChange}
    >
      {roLeavePeriods.map(p => (
        <Select.Option key={p.periodYear} value={p.periodYear} title={p.periodYear.toString()}>
          {p.periodYear}
        </Select.Option>
      ))}
    </Select>
  )
}
