import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import confirm from 'antd/lib/modal/confirm'
import { LoadingOutlined } from '@ant-design/icons'
import { Form, Spin } from '~/core-components'
import { DrawerForm, ErrorDisplay } from '~/components'
import { fetchFormulas } from '~/features/formula'
import { selectPayItemById } from '~/features/master'
import { EmPublicPerson, fetchEmployee, fetchEmSalaries } from '~/features/employee'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors, StoreState } from '~/types/store'
import { selectPayTranEntries } from '../../selectors'
import { selectPayItemMappingById, selectPayRunById } from '../../reducers'
import { deletePayTranEntryBatch, fetchPayTranEntryByBatchId, updatePayTranEntryBatch } from '../../actions'
import { IPayTranEntryInfo, PayTranSgState } from '../../types'
import { MutatePayTranEntryForm, PayTranEntryForm, EMPTY_PAY_TRAN_ENTRY_FORM_DATA } from './MutatePayTranEntryForm'
import './EditPayTranEntryDrawer.less'

export interface EditPayTranEntryDrawerProps {
  visible: boolean
  id: string
  payTran?: PayTranSgState
  onClose: (action: 'save' | 'cancel') => void
}

export const EditPayTranEntryDrawer: FC<EditPayTranEntryDrawerProps> = ({ visible, id, payTran, onClose }) => {
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<Errors>()
  const [resetForm, setResetForm] = useState(0)
  const [formData, setFormData] = useState<PayTranEntryForm>(EMPTY_PAY_TRAN_ENTRY_FORM_DATA)

  const payRunId = payTran?.payRunId || ''
  const employeeId = payTran?.employeeId || ''
  const payItemId = payTran?.payItemId || ''
  const batchId = payTran?.batchId || ''

  const payItem = useSelector((state: StoreState) => selectPayItemById(state, payItemId))
  const payRun = useSelector((state: StoreState) => selectPayRunById(state, payRunId))
  const payTranEntries = useSelector(selectPayTranEntries)(batchId)
  const payTranEntriesLoading = useSelector((state: StoreState) => state.payroll.payTranEntriesLoading[batchId])
  const bpDaily = useSelector((state: StoreState) => selectPayItemMappingById(state, 'bp_daily'))
  const bpDailyPayItemId = bpDaily?.payItemId || ''
  const bpHourly = useSelector((state: StoreState) => selectPayItemMappingById(state, 'bp_hourly'))
  const bpHourlyPayItemId = bpHourly?.payItemId || ''

  useEffect(() => {
    dispatch(fetchFormulas({ strategy: 'when-empty' }))
  }, [])

  useEffect(() => {
    if (employeeId) {
      dispatch(fetchEmployee(employeeId, { strategy: 'when-empty' }))
      dispatch(fetchEmSalaries(employeeId))
    }
  }, [employeeId])

  useEffect(() => {
    setErrors(undefined)
  }, [visible])

  useEffect(() => {
    dispatch(fetchPayTranEntryByBatchId(payTran?.batchId || ''))
  }, [payTran])

  useEffect(() => {
    if (payTranEntries && (payTranEntries.length || 0) > 0) {
      const { payItemId, startDate, endDate, rate, isSysSegment } = payTranEntries[0]
      const byDates = startDate === endDate
      const trans = payTranEntries.map(({ startDate: date, quantity }) => ({ date, quantity }))
      setFormData({ payItemId, byDates, trans, rate, isSysSegment })
    }
  }, [payTranEntries])

  const getIsSalaryAsRate = useCallback(
    (payItemId: string) => {
      return bpDailyPayItemId === payItemId || bpHourlyPayItemId === payItemId
    },
    [bpDailyPayItemId, bpHourlyPayItemId]
  )
  const isSalaryAsRate = getIsSalaryAsRate(formData.payItemId)

  const handleOk = useCallback(async () => {
    if (!payRunId || !employeeId || !batchId) return

    let result: ActionResult | undefined
    setLoading(true)
    try {
      const payRunStart = payRun?.startDate || ''
      const payRunEnd = payRun?.endDate || ''
      const requests = mapPayTranEntryFormToPayTranEntryInfoRequests(formData, payRunStart, payRunEnd)
      result = await dispatch(updatePayTranEntryBatch(payRunId, employeeId, batchId, requests))
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose('save')
      setFormData(EMPTY_PAY_TRAN_ENTRY_FORM_DATA)
      setResetForm(resetForm + 1)
    }
  }, [formData, payRunId, employeeId, batchId, payRun, onClose, resetForm])

  const handleDelete = useCallback(() => {
    if (!payRunId || !employeeId || !batchId) return

    confirm({
      title: 'Delete',
      content: 'Do you want to delete?',
      onOk: async () => {
        const result: ActionResult | undefined = await dispatch(deletePayTranEntryBatch(payRunId, employeeId, batchId))

        if (result?.errors) {
          setErrors(result.errors)
        }

        if (!result?.errors) {
          typeof onClose === 'function' && onClose('save')
        }
      },
      okText: 'Delete',
      okType: 'danger'
    })
  }, [payRunId, employeeId, batchId, onClose])

  const handleCloseDrawer = useCallback(() => {
    typeof onClose === 'function' && onClose('cancel')
  }, [onClose])

  return (
    <DrawerForm
      open={visible}
      title="Edit payroll item"
      onClose={handleCloseDrawer}
      confirmLoading={loading}
      width={500}
      showDelete={true}
      onDelete={handleDelete}
      className="edit-paytranentry-drawer"
      formId={`form-edit-paytranentry-${id}`}
    >
      <Form id={`form-edit-paytranentry-${id}`} onFinish={handleOk}>
        <Form.Item label="">
          <EmPublicPerson id={employeeId} size={32} />
        </Form.Item>
        <Form.Item label="Payroll item">
          <div>{payItem?.name || ''}</div>
        </Form.Item>
        <ErrorDisplay keys={['payRunId']} errors={errors} />
        {payTranEntriesLoading ? (
          <Spin indicator={<LoadingOutlined spin />} />
        ) : (
          <MutatePayTranEntryForm
            data={formData}
            defaultPickerDate={payRun?.startDate}
            errors={errors}
            rateDisabled={isSalaryAsRate}
            rateLabel={isSalaryAsRate ? 'Current salary' : 'Rate'}
            resetForm={resetForm}
            onFormChange={data => setFormData({ ...formData, ...data })}
          />
        )}
      </Form>
    </DrawerForm>
  )
}

const mapPayTranEntryFormToPayTranEntryInfoRequests = (
  form: PayTranEntryForm,
  startDate: string,
  endDate: string
): IPayTranEntryInfo[] => {
  const result: IPayTranEntryInfo[] = []
  const { byDates, trans, payItemId, rate, isSysSegment } = form

  if (byDates) {
    for (const tran of trans) {
      const { date, quantity } = tran
      if (quantity > 0 && moment(date).isValid()) {
        result.push({ payItemId, startDate: date, endDate: date, quantity, rate, isSysSegment })
      }
    }
  } else {
    const quantity = trans.length > 0 ? trans[0].quantity : 0
    result.push({ payItemId, startDate, endDate, quantity, rate, isSysSegment })
  }
  return result
}
