import React, { FC } from 'react'
import classNames from 'classnames'
import { Card, CardProps } from '~/core-components'
import { Col, Row } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { Permission, PermissionAction } from '~/constants'
import { XeroIntegration } from './XeroIntegration'
import './PayIntegrationCard.less'

interface PayIntegrationCardProps extends CardProps {
  payRunId: string
}

export const PayIntegrationCard: FC<PayIntegrationCardProps> = ({ payRunId, className, ...props }) => {
  const canXeroSend = usePermissionGate(Permission.xero, PermissionAction.Send)

  if (!canXeroSend) return null

  return (
    <Card className={classNames('pay-integration-card', className)} {...props}>
      <Row>
        <Col>
          <div className="pay-integration-card__title">
            <b>Integration</b>
          </div>
        </Col>
      </Row>
      <XeroIntegration payRunId={payRunId} />
    </Card>
  )
}
