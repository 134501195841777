import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { setIr21A1Processing } from '../reducers'
import { apiProcessIr21A1 } from '../api/ir21.api'

export const processIr21A1 = (
  ir21SubmissionId: string,
  employeeTaxNos: string[] = [],
  employeeScope: 'existing' | 'all' = 'existing'
): ThunkResult<void> => {
  return async dispatch => {
    if (!ir21SubmissionId) return

    try {
      dispatch(setIr21A1Processing(true))
      const { status, errors, message, errorData } = await apiProcessIr21A1(
        ir21SubmissionId,
        employeeScope,
        employeeTaxNos
      )
      if (status) {
        showSuccess('Recalculated')
      } else {
        if (Object.keys(errors).length === 0) {
          showError(message, errorData)
        }
      }
    } finally {
      dispatch(setIr21A1Processing(false))
    }
  }
}
