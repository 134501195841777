import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { useSelector } from 'react-redux'
import confirm from 'antd/lib/modal/confirm'
import { DocumentTitle, Person } from '~/components'
import { emptyGuid, Permission, PermissionAction, YtdSubmissionStatus } from '~/constants'
import { Button, PageHeader, Select, Slider, Space, Tabs, Tooltip } from '~/core-components'
import { usePermissionGate } from '~/features/iam'
import { useIsMountedRef } from '~/hooks/use-is-mounted-ref'
import { TAX_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { downloadWithDom, getBaseUrl, getFileTimestamp, showError } from '~/utils'
import { fetchIr21SubmissionEmployees } from '../../actions'
import { useIr21Submission } from '../../hooks'
import { apiDownloadIr21Form } from '../../api/ir21.api'
import { selectIr21Form, selectIr21SubmissionEmployees } from '../../selectors'
import { Ir21FormDownloadDto } from '../../types'
import { Ir21Form } from './Ir21Form/Ir21Form'
import { Ir21Tags } from '../Ir21Submission/Ir21Tags'
import { Ir21A1Form } from './Ir21A1Form/Ir21A1Form'
import { selectIr21A1Form } from '../../selectors/select-ir21-a1-form'
import './Ir21Forms.less'

export interface Ir21FormsProps {}

interface Ir21FormsParams {
  id: string
  employeeTaxNo: string
  tab: string
}

interface Ir21FormsSubmissionData {
  employeeTaxNo: string
}

const map: { [key: string]: string } = {
  ir21: 'IR21',
  a1: 'Appendix 1'
}

const baseUrl = getBaseUrl('/filestore')

export const Ir21Forms: FC<Ir21FormsProps> = () => {
  const match = useRouteMatch<Ir21FormsParams>()
  const ir21SubmissionId = match.params.id
  const employeeTaxNo = match.params.employeeTaxNo
  const tab = match.params.tab

  const EMPTY_FORM_DATA: Ir21FormsSubmissionData = { employeeTaxNo }
  const [ir21Submission] = useIr21Submission(ir21SubmissionId)
  const submissionTitle = ir21Submission ? `IR21 ${ir21Submission?.ytdYear} - ${ir21Submission?.employerTaxNo}` : 'IRAS'
  const title = ir21Submission
    ? `IR21 ${ir21Submission?.ytdYear} - ${ir21Submission?.companyName} (${ir21Submission?.employerTaxNo})`
    : 'IR21'

  const history = useHistory()
  const employees = useSelector(selectIr21SubmissionEmployees)
  const [formData, setFormData] = useState<Ir21FormsSubmissionData>(EMPTY_FORM_DATA)
  const fetching = useSelector((state: StoreState) => state.tax.ir21SubmissionEmployeesLoading['ir21'])
  const updating = useSelector((state: StoreState) => state.tax.ir21FormUpdating)
  const ir21Form = useSelector(selectIr21Form)(ir21SubmissionId, formData.employeeTaxNo)
  const ir21A1Form = useSelector(selectIr21A1Form)(ir21SubmissionId, formData.employeeTaxNo)
  const isMountedRef = useIsMountedRef()
  const [deleting, setDeleting] = useState<boolean>(false)
  const [downloading, setDownloading] = useState(false)
  const canModify = usePermissionGate(Permission.ytd, PermissionAction.Modify)
  const readOnly = !canModify || ir21Submission?.status === YtdSubmissionStatus.submitted

  const [zoomIr21, setZoomIr21] = useState(1)
  const [zoomIr21A1, setZoomIr21A1] = useState(1)
  const [triggerIr21Update, setTriggerIr21Update] = useState(0)
  const [triggerIr21Delete, setTriggerIr21Delete] = useState(0)
  const [triggerIr21Reset, setTriggerIr21Reset] = useState(0)
  const [triggerIr21A1Update, setTriggerIr21A1Update] = useState(0)
  const [triggerIr21A1Delete, setTriggerIr21A1Delete] = useState(0)
  const [triggerIr21A1Reset, setTriggerIr21A1Reset] = useState(0)

  const hasForm = useMemo(() => {
    if (tab === 'ir21') {
      return !!ir21Form
    } else if (tab === 'a1') {
      return !!ir21A1Form
    }
    return true
  }, [tab, ir21Form, ir21A1Form])

  const routes = useMemo(
    () => [
      {
        path: TAX_ROUTES.tab.replace(':tab?', 'submission'),
        breadcrumbName: 'Overview'
      },
      {
        path: TAX_ROUTES.ir21Submission.replace(':id', ir21SubmissionId).replace(':tab?', tab),
        breadcrumbName: submissionTitle
      },
      {
        path: '',
        breadcrumbName: employeeTaxNo
      }
    ],
    [ir21SubmissionId, submissionTitle, tab, employeeTaxNo]
  )

  useEffect(() => {
    dispatch(fetchIr21SubmissionEmployees('ir21', ir21SubmissionId))
  }, [ir21SubmissionId])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      history.push(
        `${TAX_ROUTES.ir21FormsSubmission
          .replace(':id', ir21SubmissionId)
          .replace(':employeeTaxNo', updates.employeeTaxNo)
          .replace(':tab?', tab)}`
      )

      setFormData(formData => ({ ...formData, ...updates }))
    },
    [ir21SubmissionId, tab, history]
  )

  const handleTabChange = useCallback(
    (activeKey: string) => {
      history.push(
        `${TAX_ROUTES.ir21FormsSubmission
          .replace(':id', ir21SubmissionId)
          .replace(':employeeTaxNo', employeeTaxNo)
          .replace(':tab?', activeKey)}`
      )
    },
    [ir21SubmissionId, employeeTaxNo, history]
  )

  const handleSubmitForm = useCallback(async () => {
    if (tab === 'ir21') {
      setTriggerIr21Update(t => t + 1)
    } else if (tab === 'a1') {
      setTriggerIr21A1Update(t => t + 1)
    }
  }, [tab])

  const handleDeleteForm = useCallback(async () => {
    setDeleting(true)
    try {
      if (tab === 'ir21') {
        setTriggerIr21Delete(t => t + 1)
      } else if (tab === 'a1') {
        setTriggerIr21A1Delete(t => t + 1)
      }
    } finally {
      setDeleting(false)
    }
  }, [tab])

  const handleResetForm = useCallback(() => {
    confirm({
      title: 'Reset form',
      content: `Do you want to reset the ${map[tab]} form?`,
      onOk: () => {
        if (tab === 'ir21') {
          setTriggerIr21Reset(t => t + 1)
        } else if (tab === 'a1') {
          setTriggerIr21A1Reset(t => t + 1)
        }
      },
      okText: 'Reset',
      okType: 'danger'
    })
  }, [tab])

  const handleSliderChange = useCallback(
    (value: number) => {
      if (tab === 'ir21') {
        setZoomIr21(value)
      } else if (tab === 'a1') {
        setZoomIr21A1(value)
      }
    },
    [tab]
  )

  const getZoomValue = useCallback(() => {
    if (tab === 'ir21') {
      return zoomIr21
    } else if (tab === 'a1') {
      return zoomIr21A1
    }
  }, [tab, zoomIr21, zoomIr21A1])

  const handleDownloadClick = useCallback(async () => {
    if (tab) {
      try {
        setDownloading(true)
        const { status, result, errors, message, errorData } = await apiDownloadIr21Form(tab, {
          ir21SubmissionId: ir21Submission?.id,
          employeeTaxNo: formData.employeeTaxNo
        } as Ir21FormDownloadDto)

        if (status) {
          const fileName = `${tab}_${getFileTimestamp()}.pdf`
          downloadWithDom(result, fileName)
        } else {
          console.error('Error while downloading', errors)
          showError(message, errorData)
        }
      } finally {
        if (isMountedRef.current) setDownloading(false)
      }
    }
  }, [tab, ir21Submission, formData.employeeTaxNo, isMountedRef])

  return (
    <div className="ir21-forms">
      <DocumentTitle title={title} />
      <PageHeader
        title={
          <Space align="center">
            <span>{title}</span>
            <Ir21Tags ir21Submission={ir21Submission} />
          </Space>
        }
        breadcrumb={{ routes }}
      />
      <div className="ir21-forms__header">
        <Space align="center">
          <Select
            className="ir21-forms__header-emselect"
            showSearch
            allowClear={false}
            loading={fetching}
            optionFilterProp="title"
            onChange={(value: string) => handleFormDataChange({ employeeTaxNo: value })}
            value={formData.employeeTaxNo}
          >
            {employees?.map(em => (
              <Select.Option key={em.description} value={em.description} title={em.name}>
                <Person
                  name={em.name}
                  description={em.description}
                  photo={
                    em.photoId && em.photoId !== emptyGuid
                      ? `${baseUrl}/file/${em.photoId}/thumbnailphoto/36`
                      : undefined
                  }
                />
              </Select.Option>
            ))}
          </Select>
        </Space>

        <Space className="ir21-forms__action-bar-buttons">
          {!readOnly && hasForm && (
            <>
              <Button onClick={handleResetForm}>Cancel</Button>
              <Button type="primary" onClick={handleSubmitForm} loading={updating}>
                Save changes
              </Button>
              <Button onClick={handleDeleteForm} loading={deleting}>
                Delete
              </Button>
            </>
          )}
        </Space>
      </div>
      <div className="ir21-forms__body">
        <Tabs
          defaultActiveKey={tab || 'ir21'}
          onChange={handleTabChange}
          tabBarExtraContent={{
            right: (
              <Space>
                <Tooltip title="Slide to zoom" placement="bottom">
                  <Space>
                    <i className="fal fa-magnifying-glass" />
                    <Slider min={0} max={1} onChange={handleSliderChange} value={getZoomValue()} step={0.1} />
                  </Space>
                </Tooltip>
                <Tooltip title={`Download ${tab.toUpperCase()}`} placement="bottomRight">
                  <Button
                    size="small"
                    className="btn-icon"
                    icon={<i className="fal fa-arrow-down-to-bracket" />}
                    onClick={handleDownloadClick}
                    loading={downloading}
                  />
                </Tooltip>
              </Space>
            )
          }}
          items={[
            {
              key: 'ir21',
              label: 'IR21',
              children: (
                <Ir21Form
                  style={{ zoom: zoomIr21 }}
                  ir21SubmissionId={ir21Submission?.id || ''}
                  employeeTaxNo={formData.employeeTaxNo}
                  readOnly={readOnly}
                  triggerUpdate={triggerIr21Update}
                  triggerDelete={triggerIr21Delete}
                  triggerReset={triggerIr21Reset}
                />
              )
            },
            {
              key: 'a1',
              label: 'Appendix 1',
              children: (
                <Ir21A1Form
                  style={{ zoom: zoomIr21A1 }}
                  ir21SubmissionId={ir21Submission?.id || ''}
                  employeeTaxNo={formData.employeeTaxNo}
                  readOnly={readOnly}
                  triggerUpdate={triggerIr21A1Update}
                  triggerDelete={triggerIr21A1Delete}
                  triggerReset={triggerIr21A1Reset}
                />
              )
            }
          ]}
        />
      </div>
    </div>
  )
}
