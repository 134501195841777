import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { EmailGroupState } from '../types/email-group.state'

export const selectEmailGroups = createSelector(
  (state: StoreState) => state.master.emailGroups,
  emailGroupsState => {
    const emailGroups = Object.values(emailGroupsState?.entities || {}) as EmailGroupState[]

    return emailGroups.sort((a, b) => {
      const aInactiveDate = new Date(a!.inactiveDate || '').getTime() || 0
      const bInactiveDate = new Date(b!.inactiveDate || '').getTime() || 0

      if (bInactiveDate === 0 && aInactiveDate === 0) {
        return a!.name.localeCompare(b!.name)
      } else {
        return aInactiveDate - bInactiveDate
      }
    })
  }
)
