import React, { forwardRef } from 'react'
import classNames from 'classnames'
import { Select, SelectProps } from '~/core-components'
import './SelectMonth.less'

interface SelectMonthProps extends SelectProps {}

const months = [
  { value: 1, display: 'January' },
  { value: 2, display: 'February' },
  { value: 3, display: 'March' },
  { value: 4, display: 'April' },
  { value: 5, display: 'May' },
  { value: 6, display: 'June' },
  { value: 7, display: 'July' },
  { value: 8, display: 'August' },
  { value: 9, display: 'September' },
  { value: 10, display: 'October' },
  { value: 11, display: 'November' },
  { value: 12, display: 'December' }
]

const SelectMonthInternal = ({ className, ...props }: SelectMonthProps, ref: React.Ref<any>) => {
  const classes = classNames('select-month', { [`${className}`]: className })
  return (
    <Select ref={ref} className={classes} allowClear={false} {...props}>
      {months.map(mo => (
        <Select.Option key={mo.value} value={mo.value}>
          {mo.display}
        </Select.Option>
      ))}
    </Select>
  )
}

export const SelectMonth = forwardRef(SelectMonthInternal)
