import { showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiValidatePayImport } from '../api/payimport.api'
import { replacePayImportValidate } from '../reducers'
import { PayImportSourceState, IPayItemMap } from '../types'

export const validatePayImport = (
  payRunId: string,
  source: PayImportSourceState[],
  payItemMap: IPayItemMap[]
): ThunkResult<void> => {
  return async dispatch => {
    const { status, result, errors, message, errorData } = await apiValidatePayImport(payRunId, source, payItemMap)
    if (status) {
      dispatch(replacePayImportValidate(result))
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors, result }
  }
}
