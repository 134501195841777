import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { ShiftDayState } from '../types'

export const selectShiftDays = createSelector(
  (state: StoreState) => state.attendance.shiftDays,
  (state: StoreState) => state.master.keyvalues['attShiftDayCode'],
  (shiftDaysState, shiftDayCodes) =>
    memoize((shiftId?: string): ShiftDayState[] => {
      if (!shiftId) return []

      const shiftDays = Object.values(shiftDaysState[shiftId]?.entities || {}) as ShiftDayState[]
      return shiftDays.sort((a, b) => {
        const aSequence = shiftDayCodes?.entities[a.dayCode]?.sequence || 0
        const bSequence = shiftDayCodes?.entities[b.dayCode]?.sequence || 0
        return aSequence - bSequence
      })
    })
)
