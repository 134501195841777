import React, { FC } from 'react'
import { Route, RouteProps, Switch } from 'react-router-dom'
import { JOB_ROUTES } from '~/routes/routes'
import { Jobs } from './Jobs/Jobs'
import './JobLayout.less'

const routeList: RouteProps[] = [
  {
    path: JOB_ROUTES.jobs,
    component: Jobs,
    exact: true
  }
]

export const JobLayout: FC = () => (
  <div className="job-layout">
    <Switch>
      {routeList.map(route => (
        <Route key={route.path as string} {...route} />
      ))}
    </Switch>
  </div>
)
