import moment from 'moment-timezone'
import padStart from 'lodash/padStart'
import memoize from 'lodash/memoize'

export const getDate = (period: string, day: number) => `${period}-${padStart(day.toString() || '1', 2, '0')}`

export const getFileTimestamp = () => {
  const today = new Date()
  const months = padStart((today.getMonth() + 1).toString(), 2, '0')
  const dates = padStart(today.getDate().toString(), 2, '0')
  const hours = padStart(today.getHours().toString(), 2, '0')
  const minutes = padStart(today.getMinutes().toString(), 2, '0')
  const seconds = padStart(today.getSeconds().toString(), 2, '0')

  const dateDisplay = `${today.getFullYear()}${months}${dates}`
  const timeDisplay = `${hours}${minutes}${seconds}`
  return `${dateDisplay}_${timeDisplay}`
}

export const isInactive = (inactiveDate?: string, refDate: Date = new Date()) =>
  inactiveDate ? moment(inactiveDate).isSameOrBefore(refDate) : false

export const formatDate = (date?: string) => {
  if (!date) return
  return moment(date).format('D MMM YYYY')
}

export const formatDateRange = (date1?: string, date2?: string) => {
  if (!date1 && !date2) return

  const date1Text = moment(date1).format('D MMM YYYY')
  const date2Text = moment(date2).format('D MMM YYYY')

  if (!date1) {
    return date2Text
  }

  if (!date2) {
    return date1Text
  }

  const mDate1 = moment(date1)
  const mDate2 = moment(date2)

  if (mDate1.isSame(mDate2)) {
    return date1Text
  }

  const isSameYYMM = mDate1.month() === mDate2.month() && mDate1.year() === mDate2.year()
  if (isSameYYMM) {
    return `${mDate1.format('D')} - ${date2Text}`
  }

  const isSameYY = mDate1.year() === mDate2.year()
  if (isSameYY) {
    return `${mDate1.format('D MMM')} - ${date2Text}`
  }

  return `${date1Text} - ${date2Text}`
}

export const formatDateRangeText = (date1?: string, date2?: string) => {
  const date1Text = moment(date1).format('D MMM YYYY')
  const date2Text = moment(date2).format('D MMM YYYY')

  if (!date1) {
    return `until ${date2Text}`
  }

  if (!date2) {
    return `from ${date1Text} onwards`
  }

  const mDate1 = moment(date1)
  const mDate2 = moment(date2)

  if (mDate1.isSame(mDate2)) {
    return date1Text
  }

  const isSameYYMM = mDate1.month() === mDate2.month() && mDate1.year() === mDate2.year()
  if (isSameYYMM) {
    return `${mDate1.format('D')} - ${date2Text}`
  }

  const isSameYY = mDate1.year() === mDate2.year()
  if (isSameYY) {
    return `${mDate1.format('D MMM')} - ${date2Text}`
  }

  return `${date1Text} - ${date2Text}`
}

export const formatHourMinute = memoize(
  (value?: number, unit?: 'hour' | 'minute') => {
    const shortUnit = unit === 'minute' ? 'm' : 'h'

    if (!value) return `0${shortUnit}`

    let hoursPart = 0,
      minutesPart = 0

    if (unit === 'minute') {
      hoursPart = Math.floor(value / 60)
      minutesPart = value - hoursPart * 60
    } else {
      hoursPart = Math.floor(value)
      minutesPart = Math.round((value - hoursPart) * 60)
    }

    let result = ''

    if (hoursPart > 0) {
      result += `${hoursPart.toFixed(0)}h`
    }

    if (minutesPart > 0) {
      if (result !== '') {
        result += ' '
      }
      result += `${minutesPart.toFixed(0)}m`
    }

    return result !== '' ? result : `0${shortUnit}`
  },
  (value?: number, unit?: 'hour' | 'minute') => `${value}|${unit}`
)

export const convertToTimeDay = memoize((dateTime?: string, referenceDate?: string) => {
  if (!dateTime) {
    return { time: '', day: 0 }
  }

  return {
    time: moment(dateTime).format('HH:mm'),
    day: referenceDate
      ? moment(dateTime).isAfter(referenceDate, 'date')
        ? 1
        : moment(dateTime).isBefore(referenceDate, 'date')
        ? -1
        : 0
      : 0
  }
})

export const createDate = (payPeriod: string, day: number, monthOffset: number = 0) => {
  const daysInMonth = moment(`${payPeriod}-01`).daysInMonth()

  let dayNum = day > daysInMonth ? daysInMonth : day
  dayNum = dayNum === 0 ? 1 : dayNum

  let date = moment(`${payPeriod}-${padStart(dayNum.toString(), 2, '0')}`)
  if (monthOffset !== 0) date = date.add(monthOffset, 'month')

  return date
}
