import isNumber from 'lodash/isNumber'
import memoize from 'lodash/memoize'

export const formatYearMonth = memoize((yearMonth?: string) => {
  if (yearMonth == null || yearMonth.length !== 4) {
    return ''
  }

  const year = parseInt(yearMonth.substring(0, 2))
  const month = parseInt(yearMonth.substring(2, 4))
  return `${year} ${year > 1 ? 'years' : 'year'} ${month} ${month > 1 ? 'months' : 'month'}`
})

export const formatMonth = memoize((month?: number) => `${month} ${(month || 0) > 1 ? 'months' : 'month'}`)

export const formatMoney = memoize(
  (value: number, decimal?: number) => {
    if (!isNumber(value)) return ''

    let fixedValue = ''
    if (decimal) {
      fixedValue = value?.toFixed(decimal).toString()
    } else {
      fixedValue = value?.toString()
    }

    const parts = (fixedValue || '').split('.')
    parts[0] = parts[0].replace(/(?<!\.\d*)(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
    return parts.join('.')
  },
  (value: number, decimal?: number) => `${value}|${decimal}`
)

export const formatFileSize = memoize((bytes: number) => {
  let size = Math.round(bytes / 1000)
  if (size < 1000) {
    return `${size} KB`
  }

  size = Math.round(size / 10) / 100
  return `${size} MB`
})

const unitMap: { [code: string]: { singular: string; plural: string } } = {
  m: {
    singular: 'month',
    plural: 'months'
  },
  w: {
    singular: 'week',
    plural: 'weeks'
  },
  d: {
    singular: 'day',
    plural: 'days'
  },
  h: {
    singular: 'hour',
    plural: 'hours'
  },
  yo: {
    singular: 'year old',
    plural: 'years old'
  },
  rec: {
    singular: 'record',
    plural: 'records'
  }
}

export type UnitType = 'm' | 'w' | 'd' | 'h' | 'yo' | 'rec'

export const formatNumberUnit = (value: number | string, unit?: UnitType) => {
  if (!unit) return `${value}`

  if (parseFloat(value as string) !== 1) {
    return `${value} ${unitMap[unit]?.plural || ''}`
  } else {
    return `${value} ${unitMap[unit]?.singular || ''}`
  }
}

export const formatNumberRange = memoize(
  (min: number, max: number, unit?: UnitType) => {
    const isMinEmpty = !min || min < 0
    const isMaxEmpty = !max || max === 0 || max === 999

    if (isMinEmpty && isMaxEmpty) return ''

    const minDisplay = formatNumberUnit(min, unit)
    const maxDisplay = formatNumberUnit(max, unit)

    if (isMinEmpty) {
      return `up to ${maxDisplay}`
    }

    if (isMaxEmpty) {
      return `from ${minDisplay}`
    }

    if (min === max) {
      return `equals ${minDisplay}`
    }

    return `from ${minDisplay} to ${maxDisplay}`
  },
  (min: number, max: number, unit?: UnitType) => `${min}||${max}||${unit}`
)

export const getInitials = (name: string, type: 'tenant' | 'employee' = 'employee') => {
  if (type === 'tenant') {
    const exclusions: string[] = ['Pte Ltd', 'Ltd']
    exclusions.forEach(e => (name = name?.replace(e, '').replace(/[^a-zA-Z0-9\s]/g, '')))
  }

  let initials: string = ''
  const hasTokens = name?.indexOf(' ') !== -1
  if (type === 'tenant')
    initials = name?.substring(0, hasTokens ? 1 : 2) + (hasTokens ? name?.charAt(name.lastIndexOf(' ') + 1) : '')
  else if (type === 'employee')
    initials = name?.substring(0, 1) + (hasTokens ? name?.charAt(name.indexOf(' ') + 1) : '')

  if (initials) return initials.toUpperCase()

  return ''
}

export const camelToSnake = (str: string) => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)

export const snakeToCamel = (str: string) => str.replace(/([-_])([a-z])/g, (_match, _p1, p2) => p2.toUpperCase())

export const toSentence = (str: string) => str.replace(/(^\w{1}|\.\s*\w{1})/gi, letter => letter.toUpperCase())

export const newGuid = () =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })

/* eslint-disable no-useless-escape */
const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

export const isValidEmail = (email: string) => emailRegex.test(email)

const getCssStyle = (element: any, prop: string) => {
  if (element) {
    return window.getComputedStyle(element, null).getPropertyValue(prop)
  }
}

const getCanvasFont = (el: any) => {
  const fontWeight = getCssStyle(el, 'font-weight') || 'normal'
  const fontSize = getCssStyle(el, 'font-size') || '16px'
  const fontFamily = getCssStyle(el, 'font-family') || 'Times New Roman'

  return `${fontWeight} ${fontSize} ${fontFamily}`
}

export const getTextWidth = (text: string, element: Element | undefined) => {
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  context!.font = getCanvasFont(element)
  const metrics = context?.measureText(text)
  return metrics?.width
}

export const downloadWithDom = (obj: Blob, fileName: string) => {
  const url = URL.createObjectURL(obj)
  const link = document.createElement('a')
  link.href = url

  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()

  if (link.parentNode) link.parentNode.removeChild(link)
}

export const decodeJwt = <T = any>(jwt: string): T => {
  const base64Url = jwt.split('.')[1]
  const base64 = base64Url.replace('-', '+').replace('_', '/')
  return JSON.parse(window.atob(base64))
}

export const getTextColor = (backgroundColor: string) => {
  var r = parseInt(backgroundColor.substring(1, 3), 16)
  var g = parseInt(backgroundColor.substring(3, 5), 16)
  var b = parseInt(backgroundColor.substring(5, 7), 16)
  var yiq = (r * 299 + g * 587 + b * 114) / 1000
  return yiq >= 128 ? 'black' : 'white'
}
