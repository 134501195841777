import { replacePayItemsView, setPayItemsViewLoading } from '../reducers'
import { apiGetPayItemsView } from '../api/payitem.api'
import { ThunkResult } from '~/types/store'
import { Country, DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'
import { mapPayItemToPayItemState } from '../types/payitem.mapper'

export const fetchPayItemsView = (
  viewId: string,
  search: string,
  country: Country,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!viewId) return

    const loading = getState().master.payItemsLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().master.payItems?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setPayItemsViewLoading(true))
      const { result, status } = await apiGetPayItemsView(viewId, { search })
      if (status) {
        const payItems = result.map(dto => mapPayItemToPayItemState(dto, country))
        dispatch(replacePayItemsView(payItems))
      }
    } finally {
      dispatch(setPayItemsViewLoading(false))
    }
  }
}
