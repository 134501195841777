import React, { FC, useState, useCallback, useEffect, ChangeEvent } from 'react'
import moment from 'moment-timezone'
import confirm from 'antd/lib/modal/confirm'
import { Form, Input, Checkbox, CheckboxChangeEvent, Space } from '~/core-components'
import { Col, DrawerForm, Row, PeriodKeyValues, SysOptions } from '~/components'
import { dispatch } from '~/stores/store'
import { useFocus } from '~/hooks/use-focus'
import { addEmStatutoryExempt, deleteEmStatutoryExempt, updateEmStatutoryExempt } from '../../../actions'
import { EmStatutoryExemptState, EmStatutoryExemptInfoState } from '../../../types'
import { mapEmStatutoryExemptInfoStateToEmStatutoryExemptInfo } from '../../../types/em-details.mapper'
import { ActionResult, Errors } from '~/types/store'
import { StatutoryExemptReason } from '~/constants'

export interface MutateEmStatutoryExemptDrawerProps {
  visible: boolean
  employeeId?: string
  data?: EmStatutoryExemptState
  onClose: () => void
}

const EMPTY_FORM_DATA: EmStatutoryExemptInfoState = {
  startDate: '',
  endDate: '',
  reasonCode: '',
  notes: '',
  cpf: false,
  sdl: false,
  tax: false,
  postingDate: ''
}

const currentPeriod = moment().format('YYYY-MM')

export const MutateEmStatutoryExemptDrawer: FC<MutateEmStatutoryExemptDrawerProps> = ({
  visible,
  employeeId,
  data,
  onClose
}: MutateEmStatutoryExemptDrawerProps) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<EmStatutoryExemptInfoState>(EMPTY_FORM_DATA)
  const [focusRef, setFocus] = useFocus(true)
  const [errors, setErrors] = useState<Errors>()

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  useEffect(() => {
    if (data) {
      const { startDate, endDate, reasonCode, notes, cpf, sdl, tax, postingDate } = data
      setFormData({
        startDate,
        endDate,
        reasonCode,
        notes,
        cpf,
        sdl,
        tax,
        postingDate
      })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      setErrors(undefined)
      const updated = { ...formData, ...updates }
      setFormData(updated)
    },
    [formData]
  )

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      if (data) {
        result = await dispatch(
          updateEmStatutoryExempt(
            data.employeeId,
            data.id,
            mapEmStatutoryExemptInfoStateToEmStatutoryExemptInfo(data),
            mapEmStatutoryExemptInfoStateToEmStatutoryExemptInfo(formData)
          )
        )
      } else if (employeeId) {
        result = await dispatch(
          addEmStatutoryExempt(employeeId, mapEmStatutoryExemptInfoStateToEmStatutoryExemptInfo(formData))
        )
      }
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_FORM_DATA)
    }
  }, [employeeId, data, formData, onClose])

  const handleDelete = useCallback(
    (emStatutoryExempt: EmStatutoryExemptState | undefined) => {
      if (employeeId && emStatutoryExempt) {
        const { id, startDate, endDate } = emStatutoryExempt
        confirm({
          title: 'Delete statutory exempt',
          content: `Do you want to delete statutory exemption record of "${moment(startDate).format('MMM YYYY')}${
            endDate ? ` - ${moment(endDate).format('MMM YYYY')}` : ` onwards`
          }"?`,
          onOk: async () => {
            const result: ActionResult | undefined = await dispatch(deleteEmStatutoryExempt(employeeId, id))
            if (result?.errors) {
              setErrors(result.errors)
            }

            if (!result?.errors) {
              typeof onClose === 'function' && onClose()
            }
          },
          okText: 'Delete',
          okType: 'danger'
        })
      }
    },
    [employeeId, onClose]
  )

  return (
    <DrawerForm
      open={visible}
      title={data ? 'Edit statutory exemption' : 'Add statutory exemption'}
      onClose={onClose}
      confirmLoading={loading}
      width={500}
      showDelete={data ? true : false}
      onDelete={() => handleDelete(data)}
      formId="form-em-statutory-exempt"
    >
      <Form id="form-em-statutory-exempt" onFinish={handleOk}>
        <Row>
          <Col span={24}>
            <Form.Item label="Start month" validateStatus={errors?.startDate ? 'error' : ''} help={errors?.startDate}>
              <PeriodKeyValues
                ref={focusRef}
                value={formData.startDate ? moment(formData.startDate).format('YYYY-MM') : undefined}
                startMonth={currentPeriod}
                noOfMonthBefore={-12}
                noOfMonthAfter={36}
                onChange={(value: string) => {
                  const startDate = value ? moment(`${value}-01`).format('YYYY-MM-DD') : ''
                  handleFormDataChange({ startDate })
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col span={24}>
            <Form.Item label="End month" validateStatus={errors?.endDate ? 'error' : ''} help={errors?.endDate}>
              <PeriodKeyValues
                value={formData.endDate ? moment(formData.endDate).format('YYYY-MM') : undefined}
                startMonth={currentPeriod}
                noOfMonthBefore={-12}
                noOfMonthAfter={36}
                onChange={(value: string) => {
                  let endDate = ''
                  if (value) endDate = value && moment(`${value}-01`).endOf('month').format('YYYY-MM-DD')
                  handleFormDataChange({ endDate })
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col span={12}>
            <Form.Item label="Exempted item(s)">
              <Space direction="horizontal">
                <Checkbox
                  checked={formData.cpf}
                  onChange={(event: CheckboxChangeEvent) => {
                    handleFormDataChange({ cpf: event.target.checked })
                  }}
                >
                  CPF
                </Checkbox>
                <Checkbox
                  checked={formData.sdl}
                  onChange={(event: CheckboxChangeEvent) => {
                    handleFormDataChange({ sdl: event.target.checked })
                  }}
                >
                  SDL
                </Checkbox>
                <Checkbox
                  checked={formData.tax}
                  onChange={(event: CheckboxChangeEvent) => {
                    handleFormDataChange({ tax: event.target.checked })
                  }}
                >
                  Tax
                </Checkbox>
              </Space>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col span={24}>
            <Form.Item label="Reason" validateStatus={errors?.reasonCode ? 'error' : ''} help={errors?.reasonCode}>
              <SysOptions
                type="em_statutory_exempt_reason"
                value={formData.reasonCode}
                onChange={(value: string) => {
                  let postingDate = formData.postingDate
                  if (value !== StatutoryExemptReason.overseasPosting) postingDate = ''
                  handleFormDataChange({ reasonCode: value, postingDate })
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30} hidden={formData.reasonCode !== StatutoryExemptReason.overseasPosting}>
          <Col span={24}>
            <Form.Item
              label="Posting date"
              validateStatus={errors?.postingDate ? 'error' : ''}
              help={errors?.postingDate}
            >
              <Input.Date
                value={formData.postingDate ? moment(formData.postingDate) : undefined}
                style={{ width: '200px' }}
                onChange={(value: moment.Moment | null) => {
                  handleFormDataChange({ postingDate: value?.format('YYYY-MM-DD') })
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col span={24}>
            <Form.Item label="Notes">
              <Input.TextArea
                value={formData.notes}
                rows={2}
                onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                  handleFormDataChange({ notes: event.target.value })
                }
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}
