import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { FetchStrategy } from '~/types/common'
import { fetchClaimType } from '../actions'
import { ClaimTypeState } from '../types'
import { selectClaimTypeById } from '../reducers'

export const useClaimType = (
  id: string,
  fetchStrategy: FetchStrategy = 'always'
): [ClaimTypeState | undefined, boolean] => {
  const data = useSelector((state: StoreState) => selectClaimTypeById(state, id))
  const loading = useSelector((state: StoreState) => state.claim.claimTypesLoading)

  useEffect(() => {
    dispatch(fetchClaimType(id, { strategy: fetchStrategy }))
  }, [id, fetchStrategy])

  return [data, loading]
}
