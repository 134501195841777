import { replaceSysBankFiles, setSysBankFilesLoading } from '../reducers'
import { apiGetSysBankFiles } from '../../report/api/rpt-bank-file.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchSysBankFiles = (options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().report.sysBankFilesLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().report.sysBankFiles?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setSysBankFilesLoading(true))
      const { result, status } = await apiGetSysBankFiles()
      if (status) {
        dispatch(replaceSysBankFiles(result))
      }
    } finally {
      dispatch(setSysBankFilesLoading(false))
    }
  }
}
