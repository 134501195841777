import { replaceCalendarPatterns, setCalendarPatternsLoading } from '../reducers'
import { apiGetCalendarPatterns } from '../api/calendar.api'
import { ThunkResult } from '~/types/store'
import { CalendarPatternState } from '../types'

export const fetchCalendarPatterns = (calendarId: string): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!calendarId) return

    try {
      dispatch(setCalendarPatternsLoading(true))
      const { result, status } = await apiGetCalendarPatterns(calendarId)
      if (status) {
        let otherCalendarPatterns = Object.values(getState().master.calendarPatterns?.entities || {}).filter(
          co => co?.calendarId !== calendarId
        ) as CalendarPatternState[]
        dispatch(replaceCalendarPatterns([...result, ...otherCalendarPatterns]))
      }
    } finally {
      dispatch(setCalendarPatternsLoading(false))
    }
  }
}
