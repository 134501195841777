import { showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiExecuteDailyImport } from '../api/daily-import.api'

export const executeDailyImport = (payRunId: string): ThunkResult<void> => {
  return async () => {
    const { status, result, errors, message, errorData } = await apiExecuteDailyImport(payRunId)
    if (!status) {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors, result }
  }
}
