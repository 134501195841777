import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchEmPublic } from '../actions'
import { selectEmPublicById } from '../reducers'
import { EmPublicState } from '../types'

export const useEmPublic = (
  employeeId: string,
  fetchStrategy: FetchStrategy = 'when-empty'
): [EmPublicState | undefined, boolean] => {
  const employee = useSelector((state: StoreState) => selectEmPublicById(state, employeeId))
  const loading = useSelector((state: StoreState) => state.employee.emPublicLoading)

  useEffect(() => {
    dispatch(fetchEmPublic(employeeId, { strategy: fetchStrategy }))
  }, [employeeId, fetchStrategy])

  return [employee, loading]
}
