import { replaceCompaniesExtra, setCompaniesExtraLoading } from '../reducers'
import { apiGetCompaniesExtra } from '../api/company.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchCompaniesExtra = (options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().master.companiesExtraLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().master.companiesExtra?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setCompaniesExtraLoading(true))
      const { result, status } = await apiGetCompaniesExtra()
      if (status) {
        dispatch(replaceCompaniesExtra(result))
      }
    } finally {
      dispatch(setCompaniesExtraLoading(false))
    }
  }
}
