import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import omit from 'lodash/omit'
import { StoreState } from '~/types/store'

export const selectOfficeCompaniesOverview = createSelector(
  (state: StoreState) => state.master.companies,
  (state: StoreState) => state.master.companyOffices,
  (companiesState, companyOfficesState) =>
    memoize((officeId?: string) => {
      const companies = companiesState?.entities || {}
      const companyOffices = Object.values(companyOfficesState?.entities || {})
      return companyOffices
        .filter(co => co?.officeId === officeId)
        .map(co => ({
          ...co,
          ...omit(
            co?.companyId ? companies[co.companyId] : {},
            ['id'] // id should be taken from CompanyOffice
          )
        }))
    })
)
