import { setPayItem } from '../reducers'
import { apiGetPayItem } from '../api/payitem.api'
import { Country } from '~/types/common'
import { ThunkResult } from '~/types/store'
import { mapPayItemToPayItemState } from '../types/payitem.mapper'

export const fetchPayItem = (id: string, country: Country): ThunkResult<void> => {
  return async dispatch => {
    if (!id) return

    const { result, status } = await apiGetPayItem(id)
    if (status) {
      const payItem = mapPayItemToPayItemState(result, country)
      dispatch(setPayItem(payItem))
    }
  }
}
