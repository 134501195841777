import { showSuccess, showError } from '~/utils'
import { emptyGuid } from '~/constants'
import { ActionResult, ThunkResult } from '~/types/store'
import { apiUpdatePayTranImportBatch } from '../api/paytran-import.api'
import { IPayTranImportInfo } from '../types'
import { removePayTranImportByBatch } from '../reducers'

export const updatePayTranImportBatch = (
  payRunId: string,
  employeeId: string,
  batchId: string,
  payItemId: string,
  imports: IPayTranImportInfo[]
): ThunkResult<void> => {
  return async (dispatch): Promise<ActionResult | undefined> => {
    if (!payRunId || !employeeId || !batchId || !payItemId) return

    const { status, result, errors, message, errorData } = await apiUpdatePayTranImportBatch(
      payRunId,
      employeeId,
      batchId,
      payItemId,
      imports.map(imp => ({
        id: emptyGuid,
        payRunId,
        employeeId,
        ...imp
      }))
    )

    if (status) {
      if (result?.batchId) {
        dispatch(removePayTranImportByBatch(emptyGuid))
      }

      showSuccess('Updated')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
