import { showSuccess, showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { apiAddHolidayDate } from '../api/holiday.api'
import { setHolidayDate } from '../reducers'
import { IHolidayDateInfo, HolidayDateState } from '../types'

export const addHolidayDate = (holidayDate: IHolidayDateInfo): ThunkResult<void> => {
  return async (dispatch): Promise<ActionResult | undefined> => {
    if (!holidayDate.holidayId) return

    const { status, result, errors, message, errorData } = await apiAddHolidayDate(holidayDate.holidayId, holidayDate)
    if (status) {
      const newHolidayDate = { id: result.id, ...holidayDate } as HolidayDateState
      dispatch(setHolidayDate({ holidayId: holidayDate.holidayId, data: newHolidayDate }))
      showSuccess('Added')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
