import { isInactive, showSuccess, showError } from '~/utils'
import { removeKeyvalue, setKeyvalue } from '~/features/master'
import { ThunkResult } from '~/types/store'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { apiUpdateLeaveType } from '../api/leave-type.api'
import {
  LeaveTypeAdvanceState,
  LeaveTypeApplyState,
  LeaveTypeEarnState,
  LeaveTypeInfoState,
  LeaveTypeState
} from '../types'
import { setLeaveType } from '../reducers'

export const updateLeaveType = <
  T extends LeaveTypeInfoState | LeaveTypeEarnState | LeaveTypeApplyState | LeaveTypeAdvanceState
>(
  id: string,
  before: T,
  request: T
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const after = { ...before, ...request } as T
    const patch = generateJsonPatch<T>(before, after)
    const { status, errors, message, errorData } = await apiUpdateLeaveType(id, patch)

    if (status) {
      const leaveType = getState().leave.leaveTypes?.entities[id]
      const updated = { ...(leaveType as LeaveTypeState), ...after }
      dispatch(setLeaveType(updated))

      if (isInactive(updated.inactiveDate)) {
        dispatch(removeKeyvalue({ kv: 'leaveType', id }))
      } else {
        dispatch(setKeyvalue({ kv: 'leaveType', data: { key: id, value: updated.name } }))
      }

      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
