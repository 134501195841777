import React, { FC, useCallback, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { Checkbox, CheckboxChangeEvent, Form, Input, Space, Switch, Text } from '~/core-components'
import {
  AttKeyValues,
  Col,
  DayIndicator,
  DrawerForm,
  NewTabLinkIcon,
  Row,
  SubHeader,
  SysOptions,
  TimeDayInput
} from '~/components'
import { useSysOptions } from '~/features/employee'
import { usePermissionGate } from '~/features/iam'
import { NON_WORKING_DAY_CODES, Permission, PermissionAction, WorkStatus } from '~/constants'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { ActionResult, Errors } from '~/types/store'
import { dispatch } from '~/stores/store'
import { useFocus } from '~/hooks/use-focus'
import { ShiftDayState, ShiftState } from '../../../types'
import { updateShiftDay } from '../../../actions'
import './EditShiftDayDrawer.less'

interface EditShiftDayDrawerProps {
  visible: boolean
  shift?: ShiftState
  data?: ShiftDayState
  onClose: (action: 'saved' | 'deleted' | 'cancelled') => void
}

type FormData = ShiftDayState

const EMPTY_FORM_DATA: FormData = {
  id: '',
  shiftId: '',
  dayCode: '',
  normalStartTime: '00:00',
  normalStartDay: 0,
  normalEndTime: '00:00',
  normalEndDay: 0,
  normalHours: 0,
  workStatus: '',
  breakId: '',
  maxHourBeforeStart: 0,
  maxHourAfterEnd: 0,
  graceLatenessMinutes: 0,
  graceUndertimeMinutes: 0,
  latenessRoundMethod: '',
  latenessRoundMinutes: 0,
  otBeforeStartTime: '',
  otBeforeStartDay: 0,
  otBeforeStartMinHours: 0,
  otBeforeStartMaxHours: 0,
  otBeforeStartRate: '',
  midnightOnOtBeforeStart: false,
  otBeforeMidnightRateForWorkDay: '',
  otBeforeMidnightRateForOffDay: '',
  otBeforeMidnightRateForRestDay: '',
  otBeforeMidnightRateForPh: '',
  otAfterEndTime: '',
  otAfterEndDay: 0,
  otAfterEndMinHours: 0,
  otAfterEndMaxHours: 0,
  otAfterEndRate: '',
  midnightOnOtAfterEnd: false,
  otAfterMidnightRateForWorkDay: '',
  otAfterMidnightRateForOffDay: '',
  otAfterMidnightRateForRestDay: '',
  otAfterMidnightRateForPh: '',
  otWithinShiftRate: '',
  midnightOnOtWithinShift: false,
  otWithinBeforeMidnightRate: '',
  otWithinAfterMidnightRateForWorkDay: '',
  otWithinAfterMidnightRateForOffDay: '',
  otWithinAfterMidnightRateForRestDay: '',
  otWithinAfterMidnightRateForPh: '',
  totalOtMinHours: 0,
  totalOtMaxHours: 0,
  otRoundMethod: '',
  otRoundMinutes: 0,
  h1EndTime: '',
  h1Hours: 0,
  h2StartTime: '',
  h2Hours: 0
}

export const EditShiftDayDrawer: FC<EditShiftDayDrawerProps> = ({ visible, shift, data, onClose }) => {
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<Errors>()
  const [focusRef] = useFocus(true)
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)
  const id = data?.id || ''
  const shiftId = shift?.id || ''

  const [showBreak, setShowBreak] = useState(false)
  const [showClocking, setShowClocking] = useState(false)
  const [showGrace, setShowGrace] = useState(false)
  const [showOvertime, setShowOvertime] = useState(false)
  const [showHalfDay, setShowHalfDay] = useState(false)

  const [otBeforeMidnightRateForWorkDay, setOtBeforeMidnightRateForWorkDay] = useState(false)
  const [otBeforeMidnightRateForOffDay, setOtBeforeMidnightRateForOffDay] = useState(false)
  const [otBeforeMidnightRateForRestDay, setOtBeforeMidnightRateForRestDay] = useState(false)
  const [otBeforeMidnightRateForPh, setOtBeforeMidnightRateForPh] = useState(false)

  const [otAfterMidnightRateForWorkDay, setOtAfterMidnightRateForWorkDay] = useState(false)
  const [otAfterMidnightRateForOffDay, setOtAfterMidnightRateForOffDay] = useState(false)
  const [otAfterMidnightRateForRestDay, setOtAfterMidnightRateForRestDay] = useState(false)
  const [otAfterMidnightRateForPh, setOtAfterMidnightRateForPh] = useState(false)

  const [otWithinAfterMidnightRateForWorkDay, setOtWithinAfterMidnightRateForWorkDay] = useState(false)
  const [otWithinAfterMidnightRateForOffDay, setOtWithinAfterMidnightRateForOffDay] = useState(false)
  const [otWithinAfterMidnightRateForRestDay, setOtWithinAfterMidnightRateForRestDay] = useState(false)
  const [otWithinAfterMidnightRateForPh, setOtWithinAfterMidnightRateForPh] = useState(false)

  const [shiftDayCodes] = useSysOptions('att_shift_day_code')

  const canModifyBreak = usePermissionGate(Permission.break, PermissionAction.Modify)

  useEffect(() => {
    if (data) {
      const {
        id,
        shiftId,
        dayCode,
        normalStartTime,
        normalStartDay,
        normalEndTime,
        normalEndDay,
        normalHours,
        workStatus,
        breakId,
        maxHourBeforeStart,
        maxHourAfterEnd,
        graceLatenessMinutes,
        graceUndertimeMinutes,
        latenessRoundMethod,
        latenessRoundMinutes,
        otBeforeStartTime,
        otBeforeStartDay,
        otBeforeStartMinHours,
        otBeforeStartMaxHours,
        otBeforeStartRate,
        midnightOnOtBeforeStart,
        otBeforeMidnightRateForWorkDay,
        otBeforeMidnightRateForOffDay,
        otBeforeMidnightRateForRestDay,
        otBeforeMidnightRateForPh,
        otAfterEndTime,
        otAfterEndDay,
        otAfterEndMinHours,
        otAfterEndMaxHours,
        otAfterEndRate,
        midnightOnOtAfterEnd,
        otAfterMidnightRateForWorkDay,
        otAfterMidnightRateForOffDay,
        otAfterMidnightRateForRestDay,
        otAfterMidnightRateForPh,
        otWithinShiftRate,
        midnightOnOtWithinShift,
        otWithinBeforeMidnightRate,
        otWithinAfterMidnightRateForWorkDay,
        otWithinAfterMidnightRateForOffDay,
        otWithinAfterMidnightRateForRestDay,
        otWithinAfterMidnightRateForPh,
        totalOtMinHours,
        totalOtMaxHours,
        otRoundMethod,
        otRoundMinutes,
        h1EndTime,
        h1Hours,
        h2StartTime,
        h2Hours
      } = data

      const isWorkDay = !NON_WORKING_DAY_CODES.includes(dayCode)

      setFormData({
        id,
        shiftId,
        dayCode,
        normalStartTime: isWorkDay ? normalStartTime : '',
        normalStartDay: isWorkDay ? normalStartDay : 0,
        normalEndTime: isWorkDay ? normalEndTime : '',
        normalEndDay: isWorkDay ? normalEndDay : 0,
        normalHours: isWorkDay ? normalHours : 0,
        workStatus: isWorkDay ? workStatus : '',
        breakId: isWorkDay ? breakId : '',
        maxHourBeforeStart: isWorkDay ? maxHourBeforeStart : 0,
        maxHourAfterEnd: isWorkDay ? maxHourAfterEnd : 0,
        graceLatenessMinutes: isWorkDay ? graceLatenessMinutes : 0,
        graceUndertimeMinutes: isWorkDay ? graceUndertimeMinutes : 0,
        latenessRoundMethod: isWorkDay ? latenessRoundMethod : '',
        latenessRoundMinutes: isWorkDay ? latenessRoundMinutes : 0,
        otBeforeStartTime,
        otBeforeStartDay,
        otBeforeStartMinHours,
        otBeforeStartMaxHours,
        otBeforeStartRate,
        midnightOnOtBeforeStart,
        otBeforeMidnightRateForWorkDay,
        otBeforeMidnightRateForOffDay,
        otBeforeMidnightRateForRestDay,
        otBeforeMidnightRateForPh,
        otAfterEndTime,
        otAfterEndDay,
        otAfterEndMinHours,
        otAfterEndMaxHours,
        otAfterEndRate,
        midnightOnOtAfterEnd,
        otAfterMidnightRateForWorkDay,
        otAfterMidnightRateForOffDay,
        otAfterMidnightRateForRestDay,
        otAfterMidnightRateForPh,
        otWithinShiftRate,
        midnightOnOtWithinShift,
        otWithinBeforeMidnightRate,
        otWithinAfterMidnightRateForWorkDay,
        otWithinAfterMidnightRateForOffDay,
        otWithinAfterMidnightRateForRestDay,
        otWithinAfterMidnightRateForPh,
        totalOtMinHours,
        totalOtMaxHours,
        otRoundMethod,
        otRoundMinutes,
        h1EndTime: isWorkDay ? h1EndTime : '',
        h1Hours: isWorkDay ? h1Hours : 0,
        h2StartTime: isWorkDay ? h2StartTime : '',
        h2Hours: isWorkDay ? h2Hours : 0
      })

      setShowBreak(Boolean(breakId))
      setShowClocking(maxHourBeforeStart > 0 || maxHourAfterEnd > 0)
      setShowGrace(graceLatenessMinutes > 0 || graceUndertimeMinutes > 0)
      setShowOvertime(
        NON_WORKING_DAY_CODES.includes(data?.dayCode || '')
          ? true
          : Boolean(otBeforeStartTime) ||
              otBeforeStartMinHours > 0 ||
              otBeforeStartMaxHours > 0 ||
              Boolean(otBeforeStartRate) ||
              Boolean(otAfterEndTime) ||
              otAfterEndMinHours > 0 ||
              otAfterEndMaxHours > 0 ||
              Boolean(otAfterEndRate) ||
              Boolean(otWithinShiftRate) ||
              Boolean(otWithinBeforeMidnightRate) ||
              totalOtMinHours > 0 ||
              totalOtMaxHours > 0
      )
      setShowHalfDay(Boolean(h1EndTime) || Boolean(h2StartTime))

      setOtBeforeMidnightRateForWorkDay(Boolean(otBeforeMidnightRateForWorkDay))
      setOtBeforeMidnightRateForOffDay(Boolean(otBeforeMidnightRateForOffDay))
      setOtBeforeMidnightRateForRestDay(Boolean(otBeforeMidnightRateForRestDay))
      setOtBeforeMidnightRateForPh(Boolean(otBeforeMidnightRateForPh))

      setOtAfterMidnightRateForWorkDay(Boolean(otAfterMidnightRateForWorkDay))
      setOtAfterMidnightRateForOffDay(Boolean(otAfterMidnightRateForOffDay))
      setOtAfterMidnightRateForRestDay(Boolean(otAfterMidnightRateForRestDay))
      setOtAfterMidnightRateForPh(Boolean(otAfterMidnightRateForPh))

      setOtWithinAfterMidnightRateForWorkDay(Boolean(otWithinAfterMidnightRateForWorkDay))
      setOtWithinAfterMidnightRateForOffDay(Boolean(otWithinAfterMidnightRateForOffDay))
      setOtWithinAfterMidnightRateForRestDay(Boolean(otWithinAfterMidnightRateForRestDay))
      setOtWithinAfterMidnightRateForPh(Boolean(otWithinAfterMidnightRateForPh))
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setErrors(undefined)
    setFormData(data => ({ ...data, ...updates }))
  }, [])

  const handleOk = useCallback(async () => {
    if (!id || !shiftId || !data) return

    if (!showBreak) {
      formData.breakId = ''
    }

    if (!showClocking) {
      formData.maxHourBeforeStart = 0
      formData.maxHourAfterEnd = 0
    }

    if (!showGrace) {
      formData.graceLatenessMinutes = 0
      formData.graceUndertimeMinutes = 0
      formData.latenessRoundMethod = ''
      formData.latenessRoundMinutes = 0
    }

    if (!showOvertime) {
      formData.otBeforeStartTime = ''
      formData.otBeforeStartMinHours = 0
      formData.otBeforeStartMaxHours = 0
      formData.otBeforeStartRate = ''
      formData.midnightOnOtBeforeStart = false
      formData.otBeforeMidnightRateForWorkDay = ''
      formData.otBeforeMidnightRateForOffDay = ''
      formData.otBeforeMidnightRateForRestDay = ''
      formData.otBeforeMidnightRateForPh = ''
      formData.otAfterEndTime = ''
      formData.otAfterEndDay = 0
      formData.otAfterEndMinHours = 0
      formData.otAfterEndMaxHours = 0
      formData.otAfterEndRate = ''
      formData.midnightOnOtAfterEnd = false
      formData.otAfterMidnightRateForWorkDay = ''
      formData.otAfterMidnightRateForOffDay = ''
      formData.otAfterMidnightRateForRestDay = ''
      formData.otAfterMidnightRateForPh = ''
      formData.otWithinShiftRate = ''
      formData.midnightOnOtWithinShift = false
      formData.otWithinAfterMidnightRateForWorkDay = ''
      formData.otWithinAfterMidnightRateForOffDay = ''
      formData.otWithinAfterMidnightRateForRestDay = ''
      formData.otWithinAfterMidnightRateForPh = ''
      formData.totalOtMinHours = 0
      formData.totalOtMaxHours = 0
      formData.otRoundMethod = ''
      formData.otRoundMinutes = 0
    }

    if (!showHalfDay) {
      formData.h1EndTime = ''
      formData.h1Hours = 0
      formData.h2StartTime = ''
      formData.h2Hours = 0
    }

    let result: ActionResult | undefined
    setLoading(true)
    try {
      result = await dispatch(updateShiftDay(shiftId, id, data, formData))
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose('saved')
      setFormData(EMPTY_FORM_DATA)
    }
  }, [id, shiftId, data, formData, showBreak, showClocking, showGrace, showOvertime, showHalfDay, onClose])

  const handleClose = useCallback(() => {
    typeof onClose === 'function' && onClose('cancelled')
    setErrors(undefined)
  }, [onClose])

  return (
    <DrawerForm
      open={visible}
      title={shiftDayCodes[data?.dayCode || '']?.value || 'Shift day'}
      onClose={handleClose}
      confirmLoading={loading}
      width={750}
      className="edit-shift-day-drawer"
      formId="form-edit-shift-day"
    >
      <Form id="form-edit-shift-day" onFinish={handleOk}>
        <Row hidden={NON_WORKING_DAY_CODES.includes(data?.dayCode || '')}>
          <Col span={24}>
            <SubHeader>Shift</SubHeader>
            <Row gutter={30}>
              <Col>
                <Form.Item
                  label="Start time"
                  validateStatus={errors?.normalStartTime || errors?.normalStartDay ? 'error' : ''}
                  help={errors?.normalStartTime || errors?.normalStartDay}
                >
                  <TimeDayInput
                    ref={focusRef}
                    time={formData.normalStartTime ? moment(formData.normalStartTime, 'HH:mm') : undefined}
                    day={formData.normalStartDay as DayIndicator}
                    onChange={(time, normalStartDay) =>
                      handleFormDataChange({ normalStartTime: time?.format('HH:mm'), normalStartDay })
                    }
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  label="End time"
                  validateStatus={errors?.normalEndTime || errors?.normalEndDay ? 'error' : ''}
                  help={errors?.normalEndTime || errors?.normalEndDay}
                >
                  <TimeDayInput
                    time={formData.normalEndTime ? moment(formData.normalEndTime, 'HH:mm') : undefined}
                    day={formData.normalEndDay as DayIndicator}
                    onChange={(time, normalEndDay) =>
                      handleFormDataChange({ normalEndTime: time?.format('HH:mm'), normalEndDay })
                    }
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item label="Hours" validateStatus={errors?.normalHours ? 'error' : ''} help={errors?.normalHours}>
                  <Input.Number
                    precision={2}
                    value={formData.normalHours}
                    onChange={(normalHours: number | null) => handleFormDataChange({ normalHours })}
                  />
                </Form.Item>
              </Col>
              <Col flex="1">
                <Form.Item
                  label="Work status"
                  validateStatus={errors?.workStatus ? 'error' : ''}
                  help={errors?.workStatus}
                >
                  <SysOptions
                    type="work_status"
                    value={formData.workStatus}
                    onChange={(workStatus: string) => handleFormDataChange({ workStatus })}
                    onFilter={value => [WorkStatus.full, WorkStatus.half].includes(value?.key || '')}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row hidden={NON_WORKING_DAY_CODES.includes(data?.dayCode || '')}>
          <Col span={24}>
            <SubHeader>
              <Row>
                <Col flex="1">Break</Col>
                <Col>
                  <Switch size="small" checked={showBreak} onChange={setShowBreak} />
                </Col>
              </Row>
            </SubHeader>
            <Row hidden={!showBreak}>
              <Col flex="1">
                <Form.Item label="Break" validateStatus={errors?.breakId ? 'error' : ''} help={errors?.breakId}>
                  <NewTabLinkIcon
                    path={SETTINGS_ROUTES.breaks}
                    tooltipText="Open breaks in a new tab"
                    tooltipPlacement="topRight"
                    hidden={!canModifyBreak}
                  >
                    <AttKeyValues
                      id="break"
                      value={formData.breakId}
                      onChange={(breakId: string) => handleFormDataChange({ breakId })}
                    />
                  </NewTabLinkIcon>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row hidden={NON_WORKING_DAY_CODES.includes(data?.dayCode || '')}>
          <Col span={24}>
            <SubHeader>
              <Row>
                <Col flex="1">Clocking</Col>
                <Col>
                  <Switch size="small" checked={showClocking} onChange={setShowClocking} />
                </Col>
              </Row>
            </SubHeader>
            <Row gutter={30} hidden={!showClocking}>
              <Col flex="200px">
                <Form.Item
                  label={
                    <>
                      Earliest before start&nbsp;<Text size="small">(hours)</Text>
                    </>
                  }
                  validateStatus={errors?.maxHourBeforeStart ? 'error' : ''}
                  help={errors?.maxHourBeforeStart}
                >
                  <Input.Number
                    precision={2}
                    value={formData.maxHourBeforeStart}
                    onChange={(maxHourBeforeStart: number | null) => handleFormDataChange({ maxHourBeforeStart })}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  label={
                    <>
                      Latest after end&nbsp;<Text size="small">(hours)</Text>
                    </>
                  }
                  validateStatus={errors?.maxHourAfterEnd ? 'error' : ''}
                  help={errors?.maxHourAfterEnd}
                >
                  <Input.Number
                    precision={2}
                    value={formData.maxHourAfterEnd}
                    onChange={(maxHourAfterEnd: number | null) => handleFormDataChange({ maxHourAfterEnd })}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row hidden={NON_WORKING_DAY_CODES.includes(data?.dayCode || '')}>
          <Col span={24}>
            <SubHeader>
              <Row>
                <Col flex="1">Grace</Col>
                <Col>
                  <Switch size="small" checked={showGrace} onChange={setShowGrace} />
                </Col>
              </Row>
            </SubHeader>
            <Row gutter={30} hidden={!showGrace}>
              <Col flex="200px">
                <Form.Item
                  label={
                    <>
                      Lateness&nbsp;<Text size="small">(minutes)</Text>
                    </>
                  }
                  validateStatus={errors?.graceLatenessMinutes ? 'error' : ''}
                  help={errors?.graceLatenessMinutes}
                >
                  <Input.Number
                    precision={0}
                    value={formData.graceLatenessMinutes}
                    onChange={(graceLatenessMinutes: number | null) => handleFormDataChange({ graceLatenessMinutes })}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  label={
                    <>
                      Undertime&nbsp;<Text size="small">(minutes)</Text>
                    </>
                  }
                  validateStatus={errors?.graceUndertimeMinutes ? 'error' : ''}
                  help={errors?.graceUndertimeMinutes}
                >
                  <Input.Number
                    precision={0}
                    value={formData.graceUndertimeMinutes}
                    onChange={(graceUndertimeMinutes: number | null) => handleFormDataChange({ graceUndertimeMinutes })}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={30} hidden={!showGrace}>
              <Col flex="200px">
                <Form.Item
                  label="Rounding method"
                  validateStatus={errors?.latenessRoundMethod ? 'error' : ''}
                  help={errors?.latenessRoundMethod}
                >
                  <SysOptions
                    type="att_rounding_method"
                    value={formData.latenessRoundMethod}
                    onChange={(latenessRoundMethod: string) => handleFormDataChange({ latenessRoundMethod })}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  label="Rounding minutes"
                  validateStatus={errors?.latenessRoundMinutes ? 'error' : ''}
                  help={errors?.latenessRoundMinutes}
                >
                  <Input.Number
                    precision={0}
                    value={formData.latenessRoundMinutes}
                    onChange={(latenessRoundMinutes: number | null) => handleFormDataChange({ latenessRoundMinutes })}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <SubHeader>
          <Row>
            <Col flex="1">Overtime</Col>
            <Col hidden={NON_WORKING_DAY_CODES.includes(data?.dayCode || '')}>
              <Switch size="small" checked={showOvertime} onChange={setShowOvertime} />
            </Col>
          </Row>
        </SubHeader>
        <Row hidden={!showOvertime}>
          <Col flex="1">
            <div className="section-title">Before shift start</div>
            <Row gutter={30}>
              <Col>
                <Form.Item
                  label="Earliest start time"
                  validateStatus={errors?.otBeforeStartTime || errors?.otBeforeStartDay ? 'error' : ''}
                  help={errors?.otBeforeStartTime || errors?.otBeforeStartDay}
                >
                  <TimeDayInput
                    time={formData.otBeforeStartTime ? moment(formData.otBeforeStartTime, 'HH:mm') : undefined}
                    day={formData.otBeforeStartDay as DayIndicator}
                    onChange={(time, otBeforeStartDay) =>
                      handleFormDataChange({ otBeforeStartTime: time?.format('HH:mm'), otBeforeStartDay })
                    }
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  label={
                    <>
                      Min&nbsp;<Text size="small">(hours)</Text>
                    </>
                  }
                  validateStatus={errors?.otBeforeStartMinHours ? 'error' : ''}
                  help={errors?.otBeforeStartMinHours}
                >
                  <Input.Number
                    precision={2}
                    value={formData.otBeforeStartMinHours}
                    onChange={(otBeforeStartMinHours: number | null) => handleFormDataChange({ otBeforeStartMinHours })}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  label={
                    <>
                      Max&nbsp;<Text size="small">(hours)</Text>
                    </>
                  }
                  validateStatus={errors?.otBeforeStartMaxHours ? 'error' : ''}
                  help={errors?.otBeforeStartMaxHours}
                >
                  <Input.Number
                    precision={2}
                    value={formData.otBeforeStartMaxHours}
                    onChange={(otBeforeStartMaxHours: number | null) => handleFormDataChange({ otBeforeStartMaxHours })}
                  />
                </Form.Item>
              </Col>
              <Col flex="200px">
                <Form.Item
                  label="Overtime rate"
                  validateStatus={errors?.otBeforeStartRate ? 'error' : ''}
                  help={errors?.otBeforeStartRate}
                >
                  <AttKeyValues
                    id="otConfig"
                    dropdownMatchSelectWidth={false}
                    value={formData.otBeforeStartRate}
                    onChange={(otBeforeStartRate: string) => handleFormDataChange({ otBeforeStartRate })}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  label="Split midnight"
                  validateStatus={errors?.midnightOnOtBeforeStart ? 'error' : ''}
                  help={errors?.midnightOnOtBeforeStart}
                >
                  <Checkbox
                    checked={formData.midnightOnOtBeforeStart}
                    onChange={(event: CheckboxChangeEvent) => {
                      if (event.target.checked) {
                        handleFormDataChange({ midnightOnOtBeforeStart: event.target.checked })
                      } else {
                        handleFormDataChange({
                          midnightOnOtBeforeStart: event.target.checked,
                          otBeforeMidnightRateForWorkDay: '',
                          otBeforeMidnightRateForOffDay: '',
                          otBeforeMidnightRateForRestDay: '',
                          otBeforeMidnightRateForPh: ''
                        })
                      }
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row className="split-midnight" hidden={!formData.midnightOnOtBeforeStart}>
              <Col flex="1">
                <Row gutter={30}>
                  <Col flex="220px">
                    <label>Work status before midnight</label>
                  </Col>
                  <Col flex="200px">
                    <label>Overtime rate</label>
                  </Col>
                </Row>
                <Row gutter={30}>
                  <Col flex="220px">
                    <Form.Item>
                      <Space>
                        <Switch
                          size="small"
                          checked={otBeforeMidnightRateForWorkDay}
                          onChange={(checked: boolean) => {
                            setOtBeforeMidnightRateForWorkDay(checked)
                            if (!checked) handleFormDataChange({ otBeforeMidnightRateForWorkDay: '' })
                          }}
                        />
                        <label>Working day</label>
                      </Space>
                    </Form.Item>
                  </Col>
                  <Col flex="200px" hidden={!otBeforeMidnightRateForWorkDay}>
                    <Form.Item>
                      <AttKeyValues
                        id="otConfig"
                        dropdownMatchSelectWidth={false}
                        value={formData.otBeforeMidnightRateForWorkDay}
                        onChange={(otBeforeMidnightRateForWorkDay: string) =>
                          handleFormDataChange({ otBeforeMidnightRateForWorkDay })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={30}>
                  <Col flex="220px">
                    <Form.Item>
                      <Space>
                        <Switch
                          size="small"
                          checked={otBeforeMidnightRateForOffDay}
                          onChange={(checked: boolean) => {
                            setOtBeforeMidnightRateForOffDay(checked)
                            if (!checked) handleFormDataChange({ otBeforeMidnightRateForOffDay: '' })
                          }}
                        />
                        <label>Off day</label>
                      </Space>
                    </Form.Item>
                  </Col>
                  <Col flex="200px" hidden={!otBeforeMidnightRateForOffDay}>
                    <Form.Item>
                      <AttKeyValues
                        id="otConfig"
                        dropdownMatchSelectWidth={false}
                        value={formData.otBeforeMidnightRateForOffDay}
                        onChange={(otBeforeMidnightRateForOffDay: string) =>
                          handleFormDataChange({ otBeforeMidnightRateForOffDay })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={30} hidden={!formData.midnightOnOtBeforeStart}>
                  <Col flex="220px">
                    <Form.Item>
                      <Space>
                        <Switch
                          size="small"
                          checked={otBeforeMidnightRateForRestDay}
                          onChange={(checked: boolean) => {
                            setOtBeforeMidnightRateForRestDay(checked)
                            if (!checked) handleFormDataChange({ otBeforeMidnightRateForRestDay: '' })
                          }}
                        />
                        <label>Rest day</label>
                      </Space>
                    </Form.Item>
                  </Col>
                  <Col flex="200px" hidden={!otBeforeMidnightRateForRestDay}>
                    <Form.Item>
                      <AttKeyValues
                        id="otConfig"
                        dropdownMatchSelectWidth={false}
                        value={formData.otBeforeMidnightRateForRestDay}
                        onChange={(otBeforeMidnightRateForRestDay: string) =>
                          handleFormDataChange({ otBeforeMidnightRateForRestDay })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={30} hidden={!formData.midnightOnOtBeforeStart}>
                  <Col flex="220px">
                    <Form.Item>
                      <Space>
                        <Switch
                          size="small"
                          checked={otBeforeMidnightRateForPh}
                          onChange={(checked: boolean) => {
                            setOtBeforeMidnightRateForPh(checked)
                            if (!checked) handleFormDataChange({ otBeforeMidnightRateForPh: '' })
                          }}
                        />
                        <label>Holiday</label>
                      </Space>
                    </Form.Item>
                  </Col>
                  <Col flex="200px" hidden={!otBeforeMidnightRateForPh}>
                    <Form.Item>
                      <AttKeyValues
                        id="otConfig"
                        dropdownMatchSelectWidth={false}
                        value={formData.otBeforeMidnightRateForPh}
                        onChange={(otBeforeMidnightRateForPh: string) =>
                          handleFormDataChange({ otBeforeMidnightRateForPh })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className="section-title">After shift end</div>
            <Row gutter={30}>
              <Col>
                <Form.Item
                  label="Latest end time"
                  validateStatus={errors?.otAfterEndTime || errors?.otAfterEndDay ? 'error' : ''}
                  help={errors?.otAfterEndTime || errors?.otAfterEndDay}
                >
                  <TimeDayInput
                    time={formData.otAfterEndTime ? moment(formData.otAfterEndTime, 'HH:mm') : undefined}
                    day={formData.otAfterEndDay as DayIndicator}
                    onChange={(time, otAfterEndDay) =>
                      handleFormDataChange({ otAfterEndTime: time?.format('HH:mm'), otAfterEndDay })
                    }
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  label={
                    <>
                      Min&nbsp;<Text size="small">(hours)</Text>
                    </>
                  }
                  validateStatus={errors?.otAfterEndMinHours ? 'error' : ''}
                  help={errors?.otAfterEndMinHours}
                >
                  <Input.Number
                    precision={2}
                    value={formData.otAfterEndMinHours}
                    onChange={(otAfterEndMinHours: number | null) => handleFormDataChange({ otAfterEndMinHours })}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  label={
                    <>
                      Max&nbsp;<Text size="small">(hours)</Text>
                    </>
                  }
                  validateStatus={errors?.otAfterEndMaxHours ? 'error' : ''}
                  help={errors?.otAfterEndMaxHours}
                >
                  <Input.Number
                    precision={2}
                    value={formData.otAfterEndMaxHours}
                    onChange={(otAfterEndMaxHours: number | null) => handleFormDataChange({ otAfterEndMaxHours })}
                  />
                </Form.Item>
              </Col>
              <Col flex="200px">
                <Form.Item
                  label="Overtime rate"
                  validateStatus={errors?.otAfterEndRate ? 'error' : ''}
                  help={errors?.otAfterEndRate}
                >
                  <AttKeyValues
                    id="otConfig"
                    dropdownMatchSelectWidth={false}
                    value={formData.otAfterEndRate}
                    onChange={(otAfterEndRate: string) => handleFormDataChange({ otAfterEndRate })}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  label="Split midnight"
                  validateStatus={errors?.midnightOnOtAfterEnd ? 'error' : ''}
                  help={errors?.midnightOnOtAfterEnd}
                >
                  <Checkbox
                    checked={formData.midnightOnOtAfterEnd}
                    onChange={(event: CheckboxChangeEvent) => {
                      if (event.target.checked) {
                        handleFormDataChange({ midnightOnOtAfterEnd: event.target.checked })
                      } else {
                        handleFormDataChange({
                          midnightOnOtAfterEnd: event.target.checked,
                          otAfterMidnightRateForWorkDay: '',
                          otAfterMidnightRateForOffDay: '',
                          otAfterMidnightRateForRestDay: '',
                          otAfterMidnightRateForPh: ''
                        })
                      }
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row className="split-midnight" hidden={!formData.midnightOnOtAfterEnd}>
              <Col flex="1">
                <Row gutter={30}>
                  <Col flex="220px">
                    <label>Work status after midnight</label>
                  </Col>
                  <Col flex="200px">
                    <label>Overtime rate</label>
                  </Col>
                </Row>
                <Row gutter={30}>
                  <Col flex="220px">
                    <Form.Item>
                      <Space>
                        <Switch
                          size="small"
                          checked={otAfterMidnightRateForWorkDay}
                          onChange={(checked: boolean) => {
                            setOtAfterMidnightRateForWorkDay(checked)
                            if (!checked) handleFormDataChange({ otAfterMidnightRateForWorkDay: '' })
                          }}
                        />
                        <label>Working day</label>
                      </Space>
                    </Form.Item>
                  </Col>
                  <Col flex="200px" hidden={!otAfterMidnightRateForWorkDay}>
                    <Form.Item>
                      <AttKeyValues
                        id="otConfig"
                        dropdownMatchSelectWidth={false}
                        value={formData.otAfterMidnightRateForWorkDay}
                        onChange={(otAfterMidnightRateForWorkDay: string) =>
                          handleFormDataChange({ otAfterMidnightRateForWorkDay })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={30}>
                  <Col flex="220px">
                    <Form.Item>
                      <Space>
                        <Switch
                          size="small"
                          checked={otAfterMidnightRateForOffDay}
                          onChange={(checked: boolean) => {
                            setOtAfterMidnightRateForOffDay(checked)
                            handleFormDataChange({ otAfterMidnightRateForOffDay: '' })
                          }}
                        />
                        <label>Off day</label>
                      </Space>
                    </Form.Item>
                  </Col>
                  <Col flex="200px" hidden={!otAfterMidnightRateForOffDay}>
                    <Form.Item>
                      <AttKeyValues
                        id="otConfig"
                        dropdownMatchSelectWidth={false}
                        value={formData.otAfterMidnightRateForOffDay}
                        onChange={(otAfterMidnightRateForOffDay: string) =>
                          handleFormDataChange({ otAfterMidnightRateForOffDay })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={30} hidden={!formData.midnightOnOtAfterEnd}>
                  <Col flex="220px">
                    <Form.Item>
                      <Space>
                        <Switch
                          size="small"
                          checked={otAfterMidnightRateForRestDay}
                          onChange={(checked: boolean) => {
                            setOtAfterMidnightRateForRestDay(checked)
                            handleFormDataChange({ otAfterMidnightRateForRestDay: '' })
                          }}
                        />
                        <label>Rest day</label>
                      </Space>
                    </Form.Item>
                  </Col>
                  <Col flex="200px" hidden={!otAfterMidnightRateForRestDay}>
                    <Form.Item>
                      <AttKeyValues
                        id="otConfig"
                        dropdownMatchSelectWidth={false}
                        value={formData.otAfterMidnightRateForRestDay}
                        onChange={(otAfterMidnightRateForRestDay: string) =>
                          handleFormDataChange({ otAfterMidnightRateForRestDay })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={30} hidden={!formData.midnightOnOtAfterEnd}>
                  <Col flex="220px">
                    <Form.Item>
                      <Space>
                        <Switch
                          size="small"
                          checked={otAfterMidnightRateForPh}
                          onChange={(checked: boolean) => {
                            setOtAfterMidnightRateForPh(checked)
                            handleFormDataChange({ otAfterMidnightRateForPh: '' })
                          }}
                        />
                        <label>Holiday</label>
                      </Space>
                    </Form.Item>
                  </Col>
                  <Col flex="200px" hidden={!otAfterMidnightRateForPh}>
                    <Form.Item>
                      <AttKeyValues
                        id="otConfig"
                        dropdownMatchSelectWidth={false}
                        value={formData.otAfterMidnightRateForPh}
                        onChange={(otAfterMidnightRateForPh: string) =>
                          handleFormDataChange({ otAfterMidnightRateForPh })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className="section-title" hidden={!NON_WORKING_DAY_CODES.includes(data?.dayCode || '')}>
              Within shift
            </div>
            <Row gutter={30} hidden={!NON_WORKING_DAY_CODES.includes(data?.dayCode || '')}>
              <Col flex="200px">
                <Form.Item
                  label="Overtime rate"
                  validateStatus={errors?.otWithinShiftRate ? 'error' : ''}
                  help={errors?.otWithinShiftRate}
                >
                  <AttKeyValues
                    id="otConfig"
                    dropdownMatchSelectWidth={false}
                    value={formData.otWithinShiftRate}
                    onChange={(otWithinShiftRate: string) => handleFormDataChange({ otWithinShiftRate })}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  label="Split midnight"
                  validateStatus={errors?.midnightOnOtWithinShift ? 'error' : ''}
                  help={errors?.midnightOnOtWithinShift}
                >
                  <Checkbox
                    checked={formData.midnightOnOtWithinShift}
                    onChange={(event: CheckboxChangeEvent) => {
                      if (event.target.checked) {
                        handleFormDataChange({ midnightOnOtWithinShift: event.target.checked })
                      } else {
                        handleFormDataChange({
                          midnightOnOtWithinShift: event.target.checked,
                          otWithinAfterMidnightRateForWorkDay: '',
                          otWithinAfterMidnightRateForOffDay: '',
                          otWithinAfterMidnightRateForRestDay: '',
                          otWithinAfterMidnightRateForPh: ''
                        })
                      }
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row className="split-midnight" hidden={!formData.midnightOnOtWithinShift}>
              <Col flex="1">
                <Row gutter={30}>
                  <Col flex="220px">
                    <label>Work status after midnight</label>
                  </Col>
                  <Col flex="200px">
                    <label>Overtime rate</label>
                  </Col>
                </Row>
                <Row gutter={30}>
                  <Col flex="220px">
                    <Form.Item>
                      <Space>
                        <Switch
                          size="small"
                          checked={otWithinAfterMidnightRateForWorkDay}
                          onChange={(checked: boolean) => {
                            setOtWithinAfterMidnightRateForWorkDay(checked)
                            if (!checked) handleFormDataChange({ otWithinAfterMidnightRateForWorkDay: '' })
                          }}
                        />
                        <label>Working day</label>
                      </Space>
                    </Form.Item>
                  </Col>
                  <Col flex="200px" hidden={!otWithinAfterMidnightRateForWorkDay}>
                    <Form.Item>
                      <AttKeyValues
                        id="otConfig"
                        dropdownMatchSelectWidth={false}
                        value={formData.otWithinAfterMidnightRateForWorkDay}
                        onChange={(otWithinAfterMidnightRateForWorkDay: string) =>
                          handleFormDataChange({ otWithinAfterMidnightRateForWorkDay })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={30}>
                  <Col flex="220px">
                    <Form.Item>
                      <Space>
                        <Switch
                          size="small"
                          checked={otWithinAfterMidnightRateForOffDay}
                          onChange={(checked: boolean) => {
                            setOtWithinAfterMidnightRateForOffDay(checked)
                            if (!checked) handleFormDataChange({ otWithinAfterMidnightRateForOffDay: '' })
                          }}
                        />
                        <label>Off day</label>
                      </Space>
                    </Form.Item>
                  </Col>
                  <Col flex="200px" hidden={!otWithinAfterMidnightRateForOffDay}>
                    <Form.Item>
                      <AttKeyValues
                        id="otConfig"
                        dropdownMatchSelectWidth={false}
                        value={formData.otWithinAfterMidnightRateForOffDay}
                        onChange={(otWithinAfterMidnightRateForOffDay: string) =>
                          handleFormDataChange({ otWithinAfterMidnightRateForOffDay })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={30}>
                  <Col flex="220px">
                    <Form.Item>
                      <Space>
                        <Switch
                          size="small"
                          checked={otWithinAfterMidnightRateForRestDay}
                          onChange={(checked: boolean) => {
                            setOtWithinAfterMidnightRateForRestDay(checked)
                            if (!checked) handleFormDataChange({ otWithinAfterMidnightRateForRestDay: '' })
                          }}
                        />
                        <label>Rest day</label>
                      </Space>
                    </Form.Item>
                  </Col>
                  <Col flex="200px" hidden={!otWithinAfterMidnightRateForRestDay}>
                    <Form.Item>
                      <AttKeyValues
                        id="otConfig"
                        dropdownMatchSelectWidth={false}
                        value={formData.otWithinAfterMidnightRateForRestDay}
                        onChange={(otWithinAfterMidnightRateForRestDay: string) =>
                          handleFormDataChange({ otWithinAfterMidnightRateForRestDay })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={30}>
                  <Col flex="220px">
                    <Form.Item>
                      <Space>
                        <Switch
                          size="small"
                          checked={otWithinAfterMidnightRateForPh}
                          onChange={(checked: boolean) => {
                            setOtWithinAfterMidnightRateForPh(checked)
                            if (!checked) handleFormDataChange({ otWithinAfterMidnightRateForPh: '' })
                          }}
                        />
                        <label>Holiday</label>
                      </Space>
                    </Form.Item>
                  </Col>
                  <Col flex="200px" hidden={!otWithinAfterMidnightRateForPh}>
                    <Form.Item>
                      <AttKeyValues
                        id="otConfig"
                        dropdownMatchSelectWidth={false}
                        value={formData.otWithinAfterMidnightRateForPh}
                        onChange={(otWithinAfterMidnightRateForPh: string) =>
                          handleFormDataChange({ otWithinAfterMidnightRateForPh })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className="section-title">Total overtime</div>
            <Row gutter={30}>
              <Col>
                <Form.Item
                  label={
                    <>
                      Min&nbsp;<Text size="small">(hours)</Text>
                    </>
                  }
                  validateStatus={errors?.totalOtMinHours ? 'error' : ''}
                  help={errors?.totalOtMinHours}
                >
                  <Input.Number
                    precision={2}
                    value={formData.totalOtMinHours}
                    onChange={(totalOtMinHours: number | null) => handleFormDataChange({ totalOtMinHours })}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  label={
                    <>
                      Max&nbsp;<Text size="small">(hours)</Text>
                    </>
                  }
                  validateStatus={errors?.totalOtMaxHours ? 'error' : ''}
                  help={errors?.totalOtMaxHours}
                >
                  <Input.Number
                    precision={2}
                    value={formData.totalOtMaxHours}
                    onChange={(totalOtMaxHours: number | null) => handleFormDataChange({ totalOtMaxHours })}
                  />
                </Form.Item>
              </Col>
            </Row>
            <div className="section-title">Overtime rounding</div>
            <Row gutter={30}>
              <Col flex="200px">
                <Form.Item
                  label="Rounding method"
                  validateStatus={errors?.otRoundMethod ? 'error' : ''}
                  help={errors?.otRoundMethod}
                >
                  <SysOptions
                    type="att_rounding_method"
                    value={formData.otRoundMethod}
                    onChange={(otRoundMethod: string) => handleFormDataChange({ otRoundMethod })}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  label="Rounding minutes"
                  validateStatus={errors?.otRoundMinutes ? 'error' : ''}
                  help={errors?.otRoundMinutes}
                >
                  <Input.Number
                    precision={0}
                    value={formData.otRoundMinutes}
                    onChange={(otRoundMinutes: number | null) => handleFormDataChange({ otRoundMinutes })}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row hidden={NON_WORKING_DAY_CODES.includes(data?.dayCode || '')}>
          <Col span={24}>
            <SubHeader>
              <Row>
                <Col flex="1">Half day</Col>
                <Col>
                  <Switch size="small" checked={showHalfDay} onChange={setShowHalfDay} />
                </Col>
              </Row>
            </SubHeader>
            <Row gutter={30} hidden={!showHalfDay}>
              <Col flex="1">
                <div className="section-title">1st half day</div>
                <Row gutter={30}>
                  <Col>
                    <Form.Item
                      label="End time"
                      validateStatus={errors?.h1EndTime ? 'error' : ''}
                      help={errors?.h1EndTime}
                    >
                      <TimeDayInput
                        time={formData.h1EndTime ? moment(formData.h1EndTime, 'HH:mm') : undefined}
                        day={0}
                        onChange={time => handleFormDataChange({ h1EndTime: time?.format('HH:mm') })}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item label="Hours" validateStatus={errors?.h1Hours ? 'error' : ''} help={errors?.h1Hours}>
                      <Input.Number
                        value={formData.h1Hours}
                        onChange={(h1Hours: number | null) => handleFormDataChange({ h1Hours })}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col flex="1">
                <div className="section-title">2nd half day</div>
                <Row gutter={30}>
                  <Col>
                    <Form.Item
                      label="Start time"
                      validateStatus={errors?.h2StartTime ? 'error' : ''}
                      help={errors?.h2StartTime}
                    >
                      <TimeDayInput
                        time={formData.h2StartTime ? moment(formData.h2StartTime, 'HH:mm') : undefined}
                        day={0}
                        onChange={time => handleFormDataChange({ h2StartTime: time?.format('HH:mm') })}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item label="Hours" validateStatus={errors?.h2Hours ? 'error' : ''} help={errors?.h2Hours}>
                      <Input.Number
                        value={formData.h2Hours}
                        onChange={(h2Hours: number | null) => handleFormDataChange({ h2Hours })}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}
