import React, { forwardRef, useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Select, SelectProps, Space } from '~/core-components'
import { DropdownActions, InactiveTag } from '~/components'
import { useFirstInView } from '~/hooks/use-first-in-view'
import { isInactive } from '~/utils/dateUtil'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { FetchStrategy } from '~/types/common'
import { fetchShiftRoles } from '../../../actions'
import { selectShiftRoleKeyvalues } from '../../../selectors'

interface ShiftRoleKeyValuesProps extends SelectProps {
  fetchStrategy?: FetchStrategy
}

const ShiftRoleKeyValuesInternal = (
  { children, fetchStrategy = 'when-empty', ...props }: ShiftRoleKeyValuesProps,
  ref: React.Ref<any>
) => {
  const { ref: inViewRef, inView } = useFirstInView<HTMLDivElement>({ threshold: 0.25 })
  const shiftRoles = useSelector(selectShiftRoleKeyvalues)
  const loading = useSelector((state: StoreState) => state.attendance.shiftRolesLoading)

  const options = useMemo(() => {
    if (!shiftRoles) return []

    let result = shiftRoles.filter(a => {
      if (Array.isArray(props.value)) return !isInactive(a?.inactiveDate) || props.value.includes(a?.id)
      else return !isInactive(a?.inactiveDate) || a?.id === props.value
    })

    return result
  }, [shiftRoles, props.value])

  useEffect(() => {
    if (inView) {
      dispatch(fetchShiftRoles({ strategy: fetchStrategy }))
    }
  }, [inView, fetchStrategy])

  const handleRefresh = useCallback(() => {
    dispatch(fetchShiftRoles())
  }, [])

  return (
    <div ref={inViewRef}>
      <Select
        ref={ref}
        showSearch
        optionFilterProp="title"
        dropdownRender={menu => (
          <div>
            {menu}
            <DropdownActions onRefresh={handleRefresh} />
          </div>
        )}
        {...props}
        loading={loading}
      >
        {options?.map(kv => (
          <Select.Option key={kv.id} value={kv.id || ''} title={kv.name}>
            <Space>
              {isInactive(kv.inactiveDate) && <InactiveTag />}
              {kv.name}
            </Space>
          </Select.Option>
        ))}
      </Select>
    </div>
  )
}

export const ShiftRoleKeyValues = forwardRef(ShiftRoleKeyValuesInternal)
