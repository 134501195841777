import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { ETaskInfo } from '~/features/my/types'
import moment from 'moment-timezone'

export const selectMyTimeLogTasks = createSelector(
  (state: StoreState) => state.myAttendance.myTimeLogTasks,
  myTimeLogTasksState =>
    Object.values(myTimeLogTasksState?.entities || {})
      .map(e => {
        const empName = e?.employeeName
        const date = e?.timeIn ? moment(e.timeIn).format('DD MMM YYYY') : ''
        return {
          id: e?.id,
          description: `Review ${empName}'s time log request for ${date}`,
          sequenceDate: e?.timeIn
        } as ETaskInfo
      })
      .sort((a, b) => {
        const aSequenceDate = new Date(a!.sequenceDate).getTime()
        const bSequenceDate = new Date(b!.sequenceDate).getTime()
        return bSequenceDate - aSequenceDate
      })
)
