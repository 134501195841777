import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import { Form, Input, Modal } from '~/core-components'
import { Col, Row } from '~/components'
import { dispatch } from '~/stores/store'
import { ActionResult } from '~/types/store'
import { useFocus } from '~/hooks/use-focus'
import { BasePath } from '~/types/common'
import { IReportTemplateSaveAs, ReportCode, ReportTemplateState } from '~/features/report/types'
import { saveReportTemplateAs } from '~/features/report/actions/save-report-template-as'

interface SaveTemplateModalProps {
  visible: boolean
  basePath: BasePath
  reportCode: ReportCode
  data?: IReportTemplateSaveAs
  onSuccess?: (template?: ReportTemplateState) => void
  onClose: () => void
}

const EMPTY_FORM_DATA: IReportTemplateSaveAs = {
  templateId: '',
  templateName: '',
  templateDataJsonb: ''
}

export const SaveTemplateModal: FC<SaveTemplateModalProps> = ({
  visible,
  basePath,
  reportCode,
  data,
  onSuccess,
  onClose
}) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<IReportTemplateSaveAs>(EMPTY_FORM_DATA)
  const [focusRef, setFocus] = useFocus(true)

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
  }, [visible, setFocus])

  useEffect(() => {
    if (data) {
      setFormData(data)
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      const updated = { ...formData, ...updates }
      setFormData(updated)
    },
    [formData]
  )

  const handleSave = useCallback(async () => {
    setLoading(true)
    try {
      let result: ActionResult<ReportTemplateState> | undefined = await dispatch(
        saveReportTemplateAs(basePath, reportCode, formData)
      )

      if (!result?.errors) {
        typeof onSuccess === 'function' && onSuccess(result?.result)
        typeof onClose === 'function' && onClose()
        setFormData(EMPTY_FORM_DATA)
      }
    } finally {
      setLoading(false)
    }
  }, [basePath, reportCode, formData, onSuccess, onClose])

  return (
    <Modal
      open={visible}
      title="Save as new template"
      onCancel={onClose}
      width={300}
      confirmLoading={loading}
      className="save-template-modal"
      formId="form-template"
    >
      <Form id="form-template" onFinish={handleSave}>
        <Row>
          <Col span={24}>
            <Form.Item label="Specify the template name">
              <Input
                ref={focusRef}
                value={formData.templateName}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleFormDataChange({ templateName: event.target.value })
                }
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
