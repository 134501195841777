import React, { CSSProperties, FC, useCallback, useState } from 'react'
import { Button } from '~/core-components'
import { Col, Row } from '~/components'
import { useIsMountedRef } from '~/hooks'

interface EmRequestApprovalBtnProps {
  onApproval: (action: 'approve' | 'reject') => Promise<void>
}

const buttonStyle: CSSProperties = { minWidth: 120 }

export const EmRequestApprovalBtn: FC<EmRequestApprovalBtnProps> = ({ onApproval }) => {
  const [loading, setLoading] = useState(false)
  const isMountedRef = useIsMountedRef()

  const handleApproval = useCallback(
    async (action: 'approve' | 'reject') => {
      try {
        setLoading(true)
        typeof onApproval === 'function' && (await onApproval(action))
      } finally {
        if (isMountedRef.current) setLoading(false)
      }
    },
    [onApproval, isMountedRef]
  )

  if (!onApproval) return null

  return (
    <Row>
      <Col flex={1}>
        <Button style={buttonStyle} onClick={() => handleApproval('reject')} loading={loading}>
          Reject
        </Button>
      </Col>
      <Col>
        <Button style={buttonStyle} type="primary" onClick={() => handleApproval('approve')} loading={loading}>
          Approve
        </Button>
      </Col>
    </Row>
  )
}
