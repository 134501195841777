import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchYtdSubmission } from '../actions'
import { YtdSubmissionState } from '../types'

export const useYtdSubmission = (
  id?: string,
  fetchStrategy: FetchStrategy = 'when-empty'
): [YtdSubmissionState | undefined, boolean] => {
  const ytdSubmission = useSelector((state: StoreState) => state.tax.ytdSubmissions?.entities[id || ''])
  const loading = useSelector((state: StoreState) => state.tax.ytdSubmissionsLoading)

  useEffect(() => {
    if (id) dispatch(fetchYtdSubmission(id, { strategy: fetchStrategy }))
  }, [id, fetchStrategy])

  return [ytdSubmission, loading]
}
