import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { ReportCode, ReportTemplateState } from '../types'
import { fetchReportTemplate } from '../actions'
import { BasePath, FetchStrategy } from '~/types/common'

export const useReportTemplate = (
  basePath: BasePath,
  reportCode: ReportCode,
  templateId?: string,
  fetchStrategy: FetchStrategy = 'always'
): [ReportTemplateState | undefined, boolean] => {
  const template = useSelector((state: StoreState) =>
    templateId ? state.report.reportTemplates[reportCode]?.entities[templateId] : undefined
  )
  const loading = useSelector((state: StoreState) => state.report.reportTemplatesLoading[reportCode] || false)

  useEffect(() => {
    if (templateId) dispatch(fetchReportTemplate(basePath, reportCode, templateId, { strategy: fetchStrategy }))
  }, [basePath, reportCode, templateId, fetchStrategy])

  return [template, loading]
}
