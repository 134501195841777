import React, { FC, ChangeEvent, useCallback, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { Checkbox, CheckboxChangeEvent, Form, Input, Radio, RadioChangeEvent, Space, Tabs } from '~/core-components'
import { Col, Row } from '~/components'
import { ViewCriteria } from '~/features/selection'
import { Errors } from '~/types/store'
import { useFocus } from '~/hooks/use-focus'
import { FeatureFlagPermission } from '~/constants'
import { ILoginAndPermission, ILoginPermission } from '../../../types'
import { useLogin, useMyLogin } from '../../../hooks'
import { PermissionAction } from './PermissionAction'
import { PermissionGate } from '../../PermissionGate'
import { PermissionDataset } from './PermissionDataset'
import { ActivateLoginTag } from '../../ActivateLoginTag'
import './LoginInfoForm.less'

interface LoginInfoFormProps {
  data: LoginInfoFormData
  readOnly?: boolean
  errors?: Errors
  onChange: (data: LoginInfoFormData) => void
}

export interface LoginInfoFormData extends ILoginAndPermission {
  empCriteria: ViewCriteria[]
  payCriteria: ViewCriteria[]
  lveCriteria: ViewCriteria[]
  claCriteria: ViewCriteria[]
  attCriteria: ViewCriteria[]
}

const PAY_GROUP_FIELD_ID = '0aff6b57-48c1-42d3-91a0-f6d142ae2662'
const LEAVE_GROUP_FIELD_ID = '9d09a3b5-88ac-4172-8b27-db38f13502d0'
const ATTENDANCE_GROUP_FIELD_ID = 'a9033966-06fb-48e2-a6a3-376cae31e7a5'

export const EMPTY_LOGIN_INFO_FORM_DATA: LoginInfoFormData = {
  id: '',
  email: '',
  loginName: '',
  activatedDate: '',
  isSuperUser: false,
  inactiveDate: '',
  permissions: [],
  empCriteria: [],
  payCriteria: [],
  lveCriteria: [],
  claCriteria: [],
  attCriteria: []
}

export const LoginInfoForm: FC<LoginInfoFormProps> = ({
  data,
  readOnly = false,
  errors,
  onChange
}: LoginInfoFormProps) => {
  const [formData, setFormData] = useState<LoginInfoFormData>(EMPTY_LOGIN_INFO_FORM_DATA)
  const [focusRef] = useFocus(!readOnly)
  const [isInactive, setIsInactive] = useState(false)
  const [myLogin] = useMyLogin()
  const [login] = useLogin(data.id, 'when-empty')
  const isActivated = !!login?.activatedDate || false

  useEffect(() => {
    if (data) {
      setFormData(data)
      setIsInactive(!!data.inactiveDate)
    } else {
      setFormData(EMPTY_LOGIN_INFO_FORM_DATA)
      setIsInactive(false)
    }
  }, [data])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      setFormData(formData => {
        const updated = { ...formData, ...updates }
        typeof onChange === 'function' && onChange(updated)
        return updated
      })
    },
    [onChange]
  )

  const handleFullAccessChange = useCallback(
    (event: RadioChangeEvent) => {
      handleFormDataChange({ isSuperUser: event.target.value })
    },
    [handleFormDataChange]
  )

  const handlePermissionsChange = useCallback(
    (permissions: ILoginPermission[]) => handleFormDataChange({ permissions }),
    [handleFormDataChange]
  )

  const handleEmpDatasetChange = useCallback(
    (empCriteria: ViewCriteria[]) => handleFormDataChange({ empCriteria }),
    [handleFormDataChange]
  )

  const handlePayDatasetChange = useCallback(
    (payCriteria: ViewCriteria[]) => handleFormDataChange({ payCriteria }),
    [handleFormDataChange]
  )

  const handleLveDatasetChange = useCallback(
    (lveCriteria: ViewCriteria[]) => handleFormDataChange({ lveCriteria }),
    [handleFormDataChange]
  )

  const handleClaDatasetChange = useCallback(
    (claCriteria: ViewCriteria[]) => handleFormDataChange({ claCriteria }),
    [handleFormDataChange]
  )

  const handleAttDatasetChange = useCallback(
    (attCriteria: ViewCriteria[]) => handleFormDataChange({ attCriteria }),
    [handleFormDataChange]
  )

  return (
    <div className="login-info-form">
      <Row gutter={15}>
        <Col span={12}>
          <Form.Item label="Email" validateStatus={errors?.email ? 'error' : ''} help={errors?.email}>
            <Input
              ref={focusRef}
              value={formData.email}
              readOnly={readOnly}
              disabled={isActivated}
              onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ email: event.target.value })}
            />
          </Form.Item>
        </Col>
        <Col flex="none">
          {!data.activatedDate ? (
            <Form.Item label="&nbsp;">
              <ActivateLoginTag loginId={data.id} />
            </Form.Item>
          ) : (
            <Form.Item
              label="Inactive"
              validateStatus={errors?.inactiveDate ? 'error' : ''}
              help={errors?.inactiveDate}
            >
              <Checkbox
                checked={isInactive}
                readOnly={readOnly}
                onChange={(event: CheckboxChangeEvent) => {
                  setIsInactive(event.target.checked)
                  handleFormDataChange({ inactiveDate: event.target.checked ? moment().format('YYYY-MM-DD') : '' })
                }}
              />
            </Form.Item>
          )}
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item label="Name" validateStatus={errors?.loginName ? 'error' : ''} help={errors?.loginName}>
            <Input
              value={formData.loginName}
              readOnly={readOnly}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleFormDataChange({ loginName: event.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row hidden={!(myLogin.id === data.id && myLogin.isOwner)}>
        <Col span={24} className="permission">
          <div className="permission__label">Access level</div>
          <div>Owner</div>
        </Col>
      </Row>
      <Row hidden={myLogin.id === data.id && myLogin.isOwner}>
        <Col span={24} className="permission">
          <div className="permission__label">Access level</div>
          <Radio.Group
            className="permission__choices"
            value={formData.isSuperUser}
            onChange={handleFullAccessChange}
            readOnly={readOnly}
          >
            <Space direction="vertical">
              <Radio className="permission__choice" value={true}>
                Full access
              </Radio>
              <Radio className="permission__choice" value={false}>
                Restricted access
              </Radio>
            </Space>
          </Radio.Group>
        </Col>
      </Row>
      <Row hidden={formData.isSuperUser || (myLogin.id === data.id && myLogin.isOwner)}>
        <Col span={24}>
          <Tabs
            defaultActiveKey="dataset"
            items={[
              {
                key: 'permission',
                label: 'Screens',
                children: <PermissionAction loginId={data.id} readOnly={readOnly} onChange={handlePermissionsChange} />
              },
              {
                key: 'employee',
                label: 'Dataset',
                children: (
                  <>
                    <div className="permission-dataset__title">People</div>
                    <PermissionDataset
                      loginId={data.id}
                      screenCode="permission_emp"
                      readOnly={readOnly}
                      onChange={handleEmpDatasetChange}
                    />
                    <PermissionGate sysPermissionId={FeatureFlagPermission.payroll}>
                      <>
                        <div className="permission-dataset__title">Payroll</div>
                        <PermissionDataset
                          loginId={data.id}
                          screenCode="permission_pay"
                          singleFieldId={PAY_GROUP_FIELD_ID}
                          readOnly={readOnly}
                          onChange={handlePayDatasetChange}
                        />
                      </>
                    </PermissionGate>
                    <PermissionGate sysPermissionId={FeatureFlagPermission.leave}>
                      <>
                        <div className="permission-dataset__title">Leave</div>
                        <PermissionDataset
                          loginId={data.id}
                          screenCode="permission_lve"
                          singleFieldId={LEAVE_GROUP_FIELD_ID}
                          readOnly={readOnly}
                          onChange={handleLveDatasetChange}
                        />
                      </>
                    </PermissionGate>
                    <PermissionGate sysPermissionId={FeatureFlagPermission.claim}>
                      <>
                        <div className="permission-dataset__title">Claim</div>
                        <PermissionDataset
                          loginId={data.id}
                          screenCode="permission_cla"
                          readOnly={readOnly}
                          onChange={handleClaDatasetChange}
                        />
                      </>
                    </PermissionGate>
                    <PermissionGate sysPermissionId={FeatureFlagPermission.attendance}>
                      <>
                        <div className="permission-dataset__title">Attendance</div>
                        <PermissionDataset
                          loginId={data.id}
                          screenCode="permission_att"
                          singleFieldId={ATTENDANCE_GROUP_FIELD_ID}
                          readOnly={readOnly}
                          onChange={handleAttDatasetChange}
                        />
                      </>
                    </PermissionGate>
                  </>
                )
              }
            ]}
          />
        </Col>
      </Row>
    </div>
  )
}

export const mapLoginInfoFormDataToLoginAndPermissionRequest = (formData: LoginInfoFormData): ILoginAndPermission => ({
  id: formData.id,
  email: formData.email,
  loginName: formData.loginName,
  isSuperUser: formData.isSuperUser,
  inactiveDate: formData.inactiveDate,
  permissions: formData.permissions
})
