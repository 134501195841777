import React, { FC } from 'react'
import { Link, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import './AdminSidebarLink.less'

interface AdminSidebarLinkProps {
  to: string
  active?: boolean
  className?: string
  icon: React.ReactNode
  title: string
}

export const AdminSidebarLink: FC<AdminSidebarLinkProps> = ({ to, icon, title, className }) => {
  const location = useLocation()

  const level = to.split('/').length
  const pathnames = location.pathname.split('/')
  let pathname = pathnames.join('/')

  if (pathnames.length >= level) {
    while (pathnames.length > level) pathnames.pop()
    pathname = pathnames.join('/')
  }

  return (
    <>
      <Link
        className={classNames(className, 'admin-sidebar__link', {
          'admin-sidebar__link--active': !!pathname && to === pathname
        })}
        to={to}
      >
        {icon}
        <span className="admin-sidebar__link-title">{title}</span>
      </Link>
    </>
  )
}
