import React, { FC, useCallback, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { LveDuration } from '~/constants'
import { Calendar, Space, Tag, Tooltip } from '~/core-components'
import { fetchMyLeaveCalendarEvents } from '~/features/my/actions'
import { selectMyLeaveCalendarEvents } from '~/features/my/selectors'
import { LeaveTypeNameComponent } from '~/features/leave'
import { useMyLogin } from '~/features/iam'
import { useSysOptions } from '~/features/employee'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { MyLeaveApplyDrawer } from '../../../../../Leave/MyLeaveApply/MyLeaveApplyDrawer'
import './MyLeaveCalendar.less'

interface MyLeaveCalendarProps {}

interface DrawerState {
  visible: boolean
  id?: string
  editing?: boolean
  startDate?: string
  endDate?: string
  resetOnClose?: boolean
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

export const MyLeaveCalendar: FC<MyLeaveCalendarProps> = () => {
  const match = useRouteMatch<{ id: string }>()
  const data = useSelector(selectMyLeaveCalendarEvents)
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const [startDate, setStartDate] = useState<string>('')
  const [endDate, setEndDate] = useState<string>('')
  const refetch = useSelector((state: StoreState) => state.my.myLeaveCalendarEventsRefetch)
  const [durationShort] = useSysOptions('lve_duration_short')
  const [myLogin] = useMyLogin()
  const tz = myLogin.timeZoneId

  useEffect(() => {
    if (match.params.id) {
      setDrawerState({
        visible: true,
        id: match.params.id,
        editing: false
      })
    }
  }, [match.params.id])

  useEffect(() => {
    dispatch(fetchMyLeaveCalendarEvents({ startDate, endDate }))
  }, [startDate, endDate, refetch])

  const handleEvents = useCallback(rangeInfo => {
    setStartDate(moment(rangeInfo.start).format('YYYY-MM-DD'))
    setEndDate(moment(rangeInfo.end).format('YYYY-MM-DD'))
  }, [])

  const handleEventContent = useCallback(
    ev => {
      let leaveDuration = ''
      if ([LveDuration.firstHalf, LveDuration.secondHalf].includes(ev.event.extendedProps.leaveDuration)) {
        leaveDuration = durationShort[ev.event.extendedProps.leaveDuration]?.value || ''
      }

      let tooltipTitle = leaveDuration ? `(${leaveDuration})` : ''

      return (
        <Tooltip title={`${ev.event.title} ${tooltipTitle}`}>
          <div className="fc-event-title fc-sticky">
            <Space>
              <LeaveTypeNameComponent
                style={{ marginLeft: 5 }}
                name={ev.event.title}
                color={ev.event.extendedProps.leaveTypeColor}
              />
              {leaveDuration && <Tag type="original">{leaveDuration}</Tag>}
            </Space>
          </div>
        </Tooltip>
      )
    },
    [durationShort]
  )

  const handleEventTransform = useCallback(ev => {
    const evCopy = { ...ev }
    evCopy.end = moment(evCopy.end).add(1, 'day').format()
    return evCopy
  }, [])

  const handleApplyLeave = useCallback(
    ev => {
      let endDates = ev.endStr
      if (ev.allDay) endDates = moment(ev.endStr).subtract(1).format('YYYY-MM-DD')

      setDrawerState({
        visible: true,
        editing: true,
        startDate: ev.startStr,
        endDate: endDates
      })
    },
    [setDrawerState]
  )

  const handleEditLeaveRecord = useCallback(
    lr => {
      setDrawerState({
        visible: true,
        id: lr.event.id,
        editing: false,
        startDate: lr.event.startStr,
        endDate: lr.event.endStr
      })
    },
    [setDrawerState]
  )

  const handleCloseDrawer = useCallback(() => setDrawerState(DEFAULT_DRAWER_STATE), [])

  return (
    <div className="my-leave-calendar">
      <Calendar
        timeZone={tz}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: ''
        }}
        initialView="dayGridMonth"
        selectable={true}
        selectMirror={true}
        datesSet={handleEvents}
        events={data}
        eventContent={handleEventContent}
        select={handleApplyLeave}
        eventClick={handleEditLeaveRecord}
        eventDataTransform={handleEventTransform}
        eventDisplay="block"
        contentHeight="70vh"
      ></Calendar>
      <MyLeaveApplyDrawer {...drawerState} resetOnClose onClose={handleCloseDrawer} />
    </div>
  )
}
