import React, { FC, useCallback, useState } from 'react'
import { Card, ColumnsType, PageHeader, Switch, Table } from '~/core-components'
import { DocumentTitle } from '~/components'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { SelfUpdateConfigState } from '../../../types'
import { useSelfUpdateConfigs } from '~/features/master/hooks'
import { updateSelfUpdateConfig } from '~/features/master/actions'
import './SelfUpdateConfigs.less'

type SelfUpdateConfigTable = SelfUpdateConfigState

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: '',
    breadcrumbName: 'Employee self-update'
  }
]

interface SelfUpdateConfigProps {}

export const SelfUpdateConfigs: FC<SelfUpdateConfigProps> = () => {
  const [selfUpdateConfigs, loading] = useSelfUpdateConfigs()
  const [updatingVisible, setUpdatingVisible] = useState<string>()
  const canModify = usePermissionGate(Permission.master, PermissionAction.Modify)

  const handleVisibleChange = useCallback(async (selfUpdateConfig: SelfUpdateConfigState, isShown: boolean) => {
    setUpdatingVisible(selfUpdateConfig.code)
    try {
      await dispatch(updateSelfUpdateConfig(selfUpdateConfig.code, selfUpdateConfig, { ...selfUpdateConfig, isShown }))
    } finally {
      setUpdatingVisible(undefined)
    }
  }, [])

  const columns: ColumnsType<SelfUpdateConfigTable> = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name'
    },
    {
      title: 'Enabled',
      key: 'isShown',
      dataIndex: 'isShown',
      align: 'center',
      width: 100,
      render: (value: boolean, record) => (
        <Switch
          checkedChildren="Yes"
          unCheckedChildren="No"
          checked={record.isShown}
          loading={updatingVisible === record.code}
          disabled={!canModify}
          onChange={(checked: boolean) => {
            handleVisibleChange(record, checked)
          }}
        />
      )
    }
  ]

  return (
    <div id="self-update-configs" className="self-update-configs">
      <DocumentTitle title="Employee Self-update" />
      <PageHeader title="Employee self-update" containerId="self-update-configs" breadcrumb={{ routes }} />
      <div className="self-update-configs__body">
        <Card table>
          <Table
            rowKey="code"
            dataSource={selfUpdateConfigs}
            pagination={false}
            columns={columns}
            loading={loading}
            rowClassName={record => (record.isShown ? 'self-update-configs__table-row--shown' : '')}
          />
        </Card>
      </div>
    </div>
  )
}
