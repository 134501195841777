import React, { CSSProperties, FC } from 'react'
import { Space, Tag } from '~/core-components'
import { useSysOptions } from '~/features/employee'
import { Ir21SubmissionState } from '../../types'

interface Ir21TagsProps {
  ir21Submission?: Ir21SubmissionState
}

const lowercase: CSSProperties = { textTransform: 'lowercase' }

export const Ir21Tags: FC<Ir21TagsProps> = ({ ir21Submission }) => {
  const [statuses] = useSysOptions('ytd_submission_status')

  if (!ir21Submission) return null

  return (
    <Space align="center">
      {ir21Submission.status && (
        <Tag type="neutral" style={lowercase}>
          {statuses[ir21Submission.status]?.value}
        </Tag>
      )}
    </Space>
  )
}
