import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import persistReducer from 'redux-persist/es/persistReducer'
import { SlackConfigsState } from './types'
import { notificationPersistConfig, NotificationRootState } from './types/state'

const initialState: NotificationRootState = {
  slackConfigs: undefined,
  slackConfigsLoading: false,
  slackInstalling: false
}

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setSlackConfigs: (state, action: PayloadAction<SlackConfigsState>) => {
      state.slackConfigs = action.payload
    },
    setSlackConfigsLoading: (state, action: PayloadAction<boolean>) => {
      state.slackConfigsLoading = action.payload
    },
    setSlackInstalling: (state, action: PayloadAction<boolean>) => {
      state.slackInstalling = action.payload
    }
  }
})

export const { setSlackConfigs, setSlackConfigsLoading, setSlackInstalling } = notificationSlice.actions

export const notificationReducers = {
  notification: persistReducer<NotificationRootState>(notificationPersistConfig, notificationSlice.reducer)
}
