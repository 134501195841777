import { showError, showSuccess } from '~/utils'
import { ThunkResult } from '~/types/store'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { apiUpdateScheduleRecordByRole } from '../api/ro-schedule-record.api'
import { IROEditScheduleRecord } from '../types'
import { refetchROScheduleRecordsViewCell } from '../reducers'

export const updateScheduleRecordByRole = (
  role: string,
  id: string,
  before: IROEditScheduleRecord,
  request: IROEditScheduleRecord
): ThunkResult<void> => {
  return async dispatch => {
    if (!role || !id) return

    const after = { ...before, ...request } as IROEditScheduleRecord
    const patch = generateJsonPatch<IROEditScheduleRecord>(before, after)
    const { status, errors, message, errorData } = await apiUpdateScheduleRecordByRole(role, id, patch)
    if (status) {
      dispatch(refetchROScheduleRecordsViewCell(id))
      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
