import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { apiUpdateHolidayDate } from '../api/holiday.api'
import { HolidayDateState } from '../types'
import { setHolidayDate } from '../reducers'

export const updateHolidayDate = <T>(holidayId: string, id: string, before: T, request: T): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!holidayId || !id) return

    const after = { ...before, ...request } as T
    const patch = generateJsonPatch<T>(before, after)
    const { status, errors, message, errorData } = await apiUpdateHolidayDate(holidayId, id, patch)

    if (status) {
      let holidayDate = getState().master.holidayDates[holidayId]?.entities[id]
      const updated = { ...(holidayDate as HolidayDateState), ...after }
      dispatch(setHolidayDate({ holidayId: holidayId, data: updated }))

      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
