import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { PayItemState } from '../types'

export const selectPayItems = createSelector(
  (state: StoreState) => state.master.payItems,
  payItemsState => {
    const payItems = Object.values(payItemsState?.entities || {}) as PayItemState[]
    return payItems.sort((a, b) => a?.name.localeCompare(b?.name || '') || 0)
  }
)
