import pick from 'lodash/pick'
import { setViewSchema, setViewSchemaByNameLoading } from '../reducers'
import { apiGetViewSchemaByName } from '../api/view.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'
import { Screen, ViewSchemaState } from '../types'
import { fetchViews } from './fetch-views'
import { emptyGuid } from '~/constants'

export const fetchViewSchemaByName = (
  screenCode: Screen,
  viewName: string,
  runSetup = true,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!screenCode || !viewName) return

    const viewSchemaByNameLoading = getState().selection.viewSchemaByNameLoading[screenCode]
    const loading = viewSchemaByNameLoading && viewSchemaByNameLoading[viewName]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const data = Object.values(getState().selection.viewSchema[screenCode]?.entities || {})
      const hasData = data?.some(v => v?.screenCode === screenCode && v?.viewName === viewName)
      if (hasData) return
    }

    try {
      dispatch(setViewSchemaByNameLoading({ screenCode, viewName, loading: true }))
      const { result, status } = await apiGetViewSchemaByName(screenCode, viewName, runSetup)
      if (status && result.id !== emptyGuid) {
        const stored = mapToState(result)
        dispatch(setViewSchema({ screenCode, data: stored }))

        const views = Object.values(getState().selection.views[screenCode]?.entities || {})
        const isExist = views.find(v => v?.viewName === viewName)
        if (!isExist) {
          dispatch(fetchViews(screenCode, false))
        }
      }
    } finally {
      dispatch(setViewSchemaByNameLoading({ screenCode, viewName, loading: false }))
    }
  }
}

const mapToState = (viewSchema: ViewSchemaState): ViewSchemaState => ({
  ...viewSchema,
  selection: viewSchema.selection.map(f => pick(f, 'id', 'selectionFieldId', 'sequence')),
  criteria: viewSchema.criteria.map(c =>
    pick(c, 'id', 'parentId', 'criteriaType', 'criteriaFieldId', 'operator', 'value', 'valueTo', 'sequence')
  )
})
