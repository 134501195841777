import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Card, ColumnsType, Form, Link, PageHeader, Table } from '~/core-components'
import { Col, Row, DocumentTitle, InactiveTag, SysOptions } from '~/components'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { isInactive } from '~/utils/dateUtil'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { MutateReasonDrawer } from './components/MutateReasonDrawer'
import { fetchMasters } from '../../actions'
import { selectReasons } from '../../selectors'
import { ReasonState } from '../../types'
import './Reasons.less'

type ReasonTable = ReasonState

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: '',
    breadcrumbName: 'Overview'
  }
]

interface DrawerState {
  visible: boolean
  data?: ReasonState
}
const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

interface ReasonProps {}

export const Reasons: FC<ReasonProps> = () => {
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const [type, setType] = useState<string>('termination')
  const reasons = useSelector(selectReasons)(type)
  const loading = useSelector((state: StoreState) => state.master.mastersLoading['reason'])
  const canModify = usePermissionGate(Permission.master, PermissionAction.Modify)

  useEffect(() => {
    dispatch(fetchMasters('reason'))
  }, [])

  const handleAddReason = useCallback(() => {
    setDrawerState({ visible: true, data: undefined })
  }, [])

  const handleEditReason = useCallback((reason: ReasonState) => {
    setDrawerState({ visible: true, data: reason })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const columns: ColumnsType<ReasonTable> = [
    {
      title: 'Reason',
      key: 'name',
      dataIndex: 'name',
      render: (value: string, record) => (
        <>
          {value} {isInactive(record.inactiveDate) && <InactiveTag />}
        </>
      )
    },
    {
      key: 'action',
      align: 'right',
      render: (value: string, record) =>
        canModify && (
          <Link size="small" onClick={() => handleEditReason(record)}>
            edit
          </Link>
        )
    }
  ]

  return (
    <div id="reasons" className="reasons">
      <DocumentTitle title="Reasons" />
      <PageHeader
        title="Reasons"
        containerId="reasons"
        breadcrumb={{ routes }}
        extra={
          canModify && (
            <Button key="add" onClick={handleAddReason}>
              Add reason
            </Button>
          )
        }
      />
      <div className="reasons__body">
        <Row className="reasons__select-type">
          <Col span={24}>
            <Form.Item label="Type" colon={false}>
              <SysOptions
                type="reason_type"
                value={type}
                allowClear={false}
                onChange={(value: string) => setType(value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Card table>
          <Table rowKey="id" dataSource={reasons} pagination={false} columns={columns} loading={loading} />
        </Card>
        {canModify && <MutateReasonDrawer {...drawerState} selectedType={type} onClose={handleCloseDrawer} />}
      </div>
    </div>
  )
}
