import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { fetchViews, Screen } from '~/features/selection'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchViewSchemaByName } from '../actions'
import { selectViewByName } from '../selectors'

export const useGetViewIdByName = (screenCode: Screen, viewName?: string): [string | undefined, boolean] => {
  const view = useSelector(selectViewByName)(screenCode, viewName)
  const loading = useSelector((state: StoreState) => {
    const viewLoading = state.selection.viewLoading[screenCode]
    return (viewLoading && viewLoading[viewName || '']) || false
  })

  useEffect(() => {
    dispatch(fetchViews(screenCode))
  }, [screenCode])

  useEffect(() => {
    if (viewName) dispatch(fetchViewSchemaByName(screenCode, viewName, true))
  }, [screenCode, viewName])

  return [view?.id, loading]
}
