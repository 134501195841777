import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { apiUpdateEmployeeEmergency } from '../api/employee.api'
import { EmployeeState, IEmployeeEmergency } from '../types'
import { setEmployee } from '../reducers'

export const updateEmployeeEmergency = <T extends IEmployeeEmergency>(
  id: string,
  before: T,
  request: T
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const after = { ...before, ...request } as T
    const patch = generateJsonPatch<T>(before, after)
    const { status, errors, message, errorData } = await apiUpdateEmployeeEmergency(id, patch)

    if (status) {
      const employee = getState().employee.employees.entities[id] as EmployeeState
      dispatch(setEmployee({ ...employee, ...after }))
      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
