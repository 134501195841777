import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Form, SecondaryLink, Space, Tooltip } from '~/core-components'
import { Col, EditableCard, EditableCardState, LveKeyValues, Row, SysOptions } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { dispatch } from '~/stores/store'
import { Errors, ActionResult, StoreState } from '~/types/store'
import { KeyValue } from '~/types/common'
import {
  LveEarnedBasis,
  LveProrationType,
  LveSysLeaveType,
  LveUnit,
  LveVisibleType,
  Permission,
  PermissionAction
} from '~/constants'
import { LeaveTypeEarnState, LeaveTypeState, LtProrationMState } from '../../../types'
import { updateLeaveType } from '../../../actions'
import { selectSysLtFieldsByCode } from '../../../selectors'
import { MutateLtProrationMDrawer } from './MutateLtProrationMDrawer'

interface LeaveTypeEarnProps {
  leaveType?: LeaveTypeState
  onEdit?: () => void
  onSave?: () => void
  onCancel?: () => void
}

interface LtProrationDrawerState {
  visible: boolean
  data?: LtProrationMState[]
  leaveType?: LeaveTypeState
}

const EMPTY_FORM_DATA: LeaveTypeEarnState = {
  isSys: false,
  earnedBasis: '',
  prorationType: '',
  roundingId: '',
  serviceYearBasis: '',
  unit: '',
  leaveDayBasis: ''
}

const DEFAULT_MODAL_STATE: LtProrationDrawerState = { visible: false }

export const LeaveTypeEarn: FC<LeaveTypeEarnProps> = ({ leaveType, onEdit, onSave, onCancel }) => {
  const [cardState, setCardState] = useState<EditableCardState>()
  const [formData, setFormData] = useState<LeaveTypeEarnState>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const sysLeaveTypeCode = leaveType?.sysLeaveTypeCode || ''
  const sysLtFields = useSelector(selectSysLtFieldsByCode)(sysLeaveTypeCode)
  const sysLtFieldsLoading = useSelector((state: StoreState) => state.leave.sysLtFieldsLoading[sysLeaveTypeCode])
  const sysColumns = useSelector((state: StoreState) => state.leave.ltSysColumns[leaveType?.id || ''] || [])
  const [drawerState, setDrawerState] = useState<LtProrationDrawerState>(DEFAULT_MODAL_STATE)
  const canModify = usePermissionGate(Permission.lveType, PermissionAction.Modify)

  useEffect(() => {
    if (leaveType) {
      const { isSys, earnedBasis, prorationType, roundingId, serviceYearBasis, unit, leaveDayBasis } = leaveType
      setFormData({ isSys, earnedBasis, prorationType, roundingId, serviceYearBasis, unit, leaveDayBasis })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [leaveType])

  const handleEdit = useCallback(() => {
    setCardState('editing')
    typeof onEdit === 'function' && onEdit()
  }, [onEdit])

  const handleSave = useCallback(async () => {
    if (leaveType) {
      setCardState('saving')
      setErrors(undefined)

      typeof onSave === 'function' && onSave()

      let result: ActionResult | undefined
      try {
        result = await dispatch(updateLeaveType(leaveType.id, leaveType, formData))
      } catch {
        setCardState('editing')
      }

      if (result?.errors) {
        setCardState('editing')
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setCardState(undefined)
      }
    }
  }, [leaveType, formData, onSave])

  const handleCancel = useCallback(() => {
    typeof onCancel === 'function' && onCancel()
    setCardState(undefined)
    setErrors(undefined)

    if (leaveType) {
      const { isSys, earnedBasis, prorationType, roundingId, serviceYearBasis, unit, leaveDayBasis } = leaveType
      setFormData({ isSys, earnedBasis, prorationType, roundingId, serviceYearBasis, unit, leaveDayBasis })
    }
  }, [leaveType, onCancel])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(data => ({ ...data, ...updates }))
  }, [])

  const isVisible = useCallback(
    (fieldName: string) => {
      return sysLtFields[fieldName]?.visibleType === LveVisibleType.basic
    },
    [sysLtFields]
  )

  const handleOpenDrawer = useCallback(() => {
    setDrawerState({ visible: true })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_MODAL_STATE)
  }, [])

  const readOnly = cardState !== 'editing' && cardState !== 'saving'

  const hasEarnedBasis = isVisible('earned_basis')
  const hasProrationType = isVisible('proration_type')
  const hasLeaveDayBasis = isVisible('leave_day_basis')
  const hasRoundingId = isVisible('rounding_id')
  const hasForm =
    hasEarnedBasis ||
    (hasProrationType && formData.earnedBasis !== LveEarnedBasis.noEntitlement) ||
    (hasLeaveDayBasis && formData.unit !== LveUnit.hours) ||
    (hasRoundingId && formData.earnedBasis !== LveEarnedBasis.noEntitlement)

  if (!hasForm && !sysLtFieldsLoading) return null

  return (
    <EditableCard
      title="Accrual setting"
      bodyStyle={{ paddingBottom: leaveType && !sysLtFieldsLoading ? 6 : 24, paddingTop: 6 }}
      loading={sysLtFieldsLoading}
      state={!canModify ? 'readonly' : cardState}
      // state={!canModify || formData.isSys ? 'readonly' : cardState}
      formId="form-leavetype-earn"
      onEdit={handleEdit}
      onSave={handleSave}
      onCancel={handleCancel}
    >
      <Row gutter={30}>
        {hasEarnedBasis && (
          <Col span={12}>
            <Form.Item
              label="Leave calculation"
              validateStatus={errors?.earnedBasis ? 'error' : ''}
              help={errors?.earnedBasis}
            >
              <SysOptions
                type="lve_earned_basis"
                value={formData.earnedBasis}
                readOnly={readOnly}
                disabled={sysColumns.includes('earnedBasis')}
                onChange={(value: string) => {
                  let defaultProrationType = ''
                  if (value === LveEarnedBasis.monthly) {
                    defaultProrationType = LveProrationType.month
                  } else if (value === LveEarnedBasis.daily) {
                    defaultProrationType = LveProrationType.day
                  }
                  handleFormDataChange({
                    earnedBasis: value,
                    ...(defaultProrationType ? { prorationType: defaultProrationType } : {})
                  })
                }}
              />
            </Form.Item>
          </Col>
        )}
        {hasProrationType && (
          <Col span={12} hidden={formData.earnedBasis === LveEarnedBasis.noEntitlement}>
            <Form.Item
              label="Proration type"
              validateStatus={errors?.prorationType ? 'error' : ''}
              help={errors?.prorationType}
              tooltip={{
                title: 'Define how the leave is prorated for new hires and resignees',
                icon: (
                  <span>
                    <i className="fal fa-circle-info" />
                  </span>
                )
              }}
            >
              <Space direction="horizontal" className="fill-first">
                <SysOptions
                  type="lve_proration_type"
                  value={formData.prorationType}
                  readOnly={readOnly}
                  disabled={sysColumns.includes('prorationType')}
                  onChange={(value: string) => handleFormDataChange({ prorationType: value })}
                  onFilter={(value: KeyValue | undefined) => {
                    return [
                      LveProrationType.day,
                      LveProrationType.month,
                      [LveSysLeaveType.sic, LveSysLeaveType.hos].includes(leaveType?.sysLeaveTypeCode || '') &&
                        LveProrationType.serviceMonth,
                      leaveType?.sysLeaveTypeCode === LveSysLeaveType.ccl && LveProrationType.monthWithinPeriod,
                      LveProrationType.none
                    ].includes(value?.key || '')
                  }}
                />
                {canModify && !readOnly && (
                  <SecondaryLink
                    hidden={formData?.prorationType !== LveProrationType.month}
                    disabled={readOnly}
                    onClick={handleOpenDrawer}
                    style={{ color: '#bbb' }}
                  >
                    <Tooltip title="Show proration setting">
                      <i className="fal fa-gear" />
                    </Tooltip>
                  </SecondaryLink>
                )}
              </Space>
            </Form.Item>
          </Col>
        )}
      </Row>
      <Row gutter={30}>
        {hasLeaveDayBasis && formData.unit !== LveUnit.hours && (
          <Col span={12}>
            <Form.Item
              label="Leave days based on"
              validateStatus={errors?.leaveDayBasis ? 'error' : ''}
              help={errors?.leaveDayBasis}
            >
              <SysOptions
                type="lve_day_basis"
                value={formData.leaveDayBasis}
                readOnly={readOnly}
                disabled={sysColumns.includes('leaveDayBasis')}
                onChange={(value: string) => handleFormDataChange({ leaveDayBasis: value })}
              />
            </Form.Item>
          </Col>
        )}
        {hasRoundingId && (
          <Col span={12} hidden={formData.earnedBasis === LveEarnedBasis.noEntitlement}>
            <Form.Item
              label="Earned leave is rounded to"
              validateStatus={errors?.roundingId ? 'error' : ''}
              help={errors?.roundingId}
              tooltip={{
                title: `Down 0.5 - Means 0.01 to 0.49 round to 0 and 0.50 to 0.99 is round to 0.5
                        Down 1.0 - Means 0.01 to 0.99 is round to 0

                        Nearest 0.5 - Means 0.01 to 0.24 round to 0, 0.25 to 0.74 round to 0.5 and 0.75 to 0.99 round to 1
                        Nearest 1.0 - Means 0.01 to 0.49 round to 0, 0.50 to 0.99 round to 1

                        Up 0.5 - Means 0.01 to 0.50 round to 0.5 and 0.51 to 0.99 is round to 1
                        Up 1.0 - Means 0.01 to 0.99 round to 1`,
                icon: (
                  <span>
                    <i className="fal fa-circle-info" />
                  </span>
                ),
                overlayStyle: { whiteSpace: 'pre-line', maxWidth: 500 }
              }}
            >
              <LveKeyValues
                id="lveRounding"
                controller="rounding"
                value={formData.roundingId}
                readOnly={readOnly}
                disabled={sysColumns.includes('rounding')}
                onChange={(value: string) => handleFormDataChange({ roundingId: value })}
              />
            </Form.Item>
          </Col>
        )}
      </Row>
      {canModify && <MutateLtProrationMDrawer {...drawerState} leaveType={leaveType} onClose={handleCloseDrawer} />}
    </EditableCard>
  )
}
