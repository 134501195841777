import React, { ChangeEvent, FC, useCallback, useState } from 'react'
import confirm from 'antd/lib/modal/confirm'
import omit from 'lodash/omit'
import { Button, Comment, Input, Link, List } from '~/core-components'
import { ILeaveRecordComment } from '~/features/my/selectors'
import { PersonAvatar, Col, Row } from '~/components'
import { ActionResult } from '~/types/store'
import { dispatch } from '~/stores/store'
import { getBaseUrl } from '~/utils'
import { deleteLeaveRecordComment, updateLeaveRecordComment } from '../../../actions'
import { useMyEmployee, useMyLeaveRecordComments } from '../../../hooks'
import './SSLeaveRecordComments.less'

interface SSLeaveRecordCommentsProps {
  id: string
  showHeader?: boolean
}

interface CommentHoverState {
  id: string
  isHover?: boolean
}
const DEFAULT_HOVER: CommentHoverState = {
  id: '',
  isHover: false
}

interface CommentEditState {
  id: string
  comment?: string
  isEdit?: boolean
}
const DEFAULT_EDIT: CommentEditState = {
  id: '',
  comment: '',
  isEdit: false
}

const baseUrl = getBaseUrl('/filestore')

export const SSLeaveRecordComments: FC<SSLeaveRecordCommentsProps> = ({ id, showHeader = false }) => {
  const [me] = useMyEmployee()
  const myId = me.id
  const [comments] = useMyLeaveRecordComments(id)

  const handleUpdate = useCallback(
    async (commentId: string, comment: string): Promise<ActionResult | undefined> => {
      if (!id || !commentId) return

      return await dispatch(updateLeaveRecordComment(id, commentId, comment || ''))
    },
    [id]
  )

  const handleDelete = useCallback(
    async (commentId: string): Promise<ActionResult | undefined> => {
      if (!id || !commentId) return

      return await dispatch(deleteLeaveRecordComment(id, commentId))
    },
    [id]
  )

  return (
    <SSLeaveRecordCommentsComponent
      authorId={myId}
      comments={comments}
      showHeader={showHeader}
      onUpdate={handleUpdate}
      onDelete={handleDelete}
    />
  )
}

interface SSLeaveRecordCommentsComponentProps {
  authorId?: string
  showHeader?: boolean
  comments?: ILeaveRecordComment[]
  onUpdate?: (commentId: string, comment: string) => Promise<ActionResult | undefined>
  onDelete?: (commentId: string) => Promise<ActionResult | undefined>
}

export const SSLeaveRecordCommentsComponent: FC<SSLeaveRecordCommentsComponentProps> = ({
  authorId,
  comments,
  onUpdate,
  onDelete,
  showHeader = false
}) => {
  const [hovered, setHovered] = useState<CommentHoverState>(DEFAULT_HOVER)
  const [onEdit, setOnEdit] = useState<CommentEditState>(DEFAULT_EDIT)
  const [commenting, setCommenting] = useState(false)

  const handleMouseEnter = useCallback((commentId: string) => {
    setHovered({ id: commentId, isHover: true })
  }, [])

  const handleMouseLeave = useCallback(() => {
    setHovered(DEFAULT_HOVER)
  }, [])

  const handleEditToggle = useCallback((commentId: string, comment: string) => {
    setOnEdit({ id: commentId, comment: comment, isEdit: true })
  }, [])

  const handleCancelEdit = useCallback(() => {
    setOnEdit(DEFAULT_EDIT)
  }, [])

  const handleSaveComment = useCallback(
    async (commentId: string) => {
      if (!commentId || typeof onUpdate !== 'function') return

      try {
        setCommenting(true)
        const result = await onUpdate(commentId, onEdit.comment || '')
        if (!result?.errors) {
          setOnEdit(DEFAULT_EDIT)
        }
      } finally {
        setCommenting(false)
      }
    },
    [onEdit, onUpdate]
  )

  const handleDelete = useCallback(
    (commentId: string, comment: string) => {
      if (!commentId || typeof onDelete !== 'function') return

      confirm({
        title: 'Delete comment',
        content: `Do you want to delete your comment "${comment}" ?`,
        onOk: async () => {
          const result: ActionResult | undefined = await onDelete(commentId)
          if (result?.errors) {
            setOnEdit(DEFAULT_EDIT)
          }
        },
        okText: 'Delete',
        okType: 'danger'
      })
    },
    [onDelete]
  )

  if (!comments) return null

  return (
    <div className="ssleave-record-comments">
      {comments.length > 0 && (
        <List
          size="small"
          dataSource={comments.map(c => ({
            ...omit(c, ['authorId', 'photoId', 'createdDate', 'updatedDate']),
            avatar: c?.photoId && (
              <PersonAvatar photo={`${baseUrl}/publicfile/${c.photoId}/thumbnailphoto/24`} size={24} />
            ),
            author: c?.author,
            datetime: (
              <Row>
                <Col className="col-time">{c?.datetime}</Col>
                <Col
                  className="col-action-edit"
                  hidden={!(hovered.isHover && c?.id === hovered.id && c?.authorId === authorId && !onEdit.isEdit)}
                >
                  <Link size="small" onClick={() => handleEditToggle(c?.id, c?.content)}>
                    edit
                  </Link>
                </Col>
                <Col
                  className="col-action-delete"
                  hidden={!(hovered.isHover && c?.id === hovered.id && c?.authorId === authorId && !onEdit.isEdit)}
                >
                  <Link size="small" onClick={() => handleDelete(c?.id, c?.content)}>
                    delete
                  </Link>
                </Col>
              </Row>
            ),
            content:
              onEdit.id === c?.id && onEdit.isEdit ? (
                <Row align="top" gutter={10}>
                  <Col span={16}>
                    <Input.TextArea
                      rows={2}
                      onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                        setOnEdit({ ...onEdit, comment: event.target.value })
                      }
                      value={onEdit.comment}
                    />
                  </Col>
                  <Col span={3}>
                    <Button size="small" loading={commenting} onClick={() => handleSaveComment(c?.id)}>
                      Save
                    </Button>
                  </Col>
                  <Col span={3}>
                    <Button size="small" hidden={commenting} onClick={handleCancelEdit}>
                      Cancel
                    </Button>
                  </Col>
                </Row>
              ) : (
                <>
                  {c?.content} {c?.updatedDate && <span className="edited-label">(edited)</span>}
                </>
              )
          }))}
          header={showHeader ? `${comments.length} ${comments.length > 1 ? 'comments' : 'comment'}` : ''}
          itemLayout="horizontal"
          renderItem={props => (
            <Comment onMouseEnter={() => handleMouseEnter(props?.id)} onMouseLeave={handleMouseLeave} {...props} />
          )}
        />
      )}
    </div>
  )
}
