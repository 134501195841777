import { Tabs as InternalTabs } from './Tabs'
import { TabPane } from './TabPane'
import { Tab as InternalTab } from 'rc-tabs/lib/interface'

type TTabs = typeof InternalTabs
interface ITabs extends TTabs {
  TabPane: typeof TabPane
}

const Tabs = InternalTabs as ITabs
Tabs.TabPane = TabPane
export { Tabs }

export * from './TabTitle'

export type Tab = InternalTab
