import { setOffice } from '../reducers'
import { apiGetOffice } from '../api/office.api'
import { ThunkResult } from '~/types/store'

export const fetchOffice = (id: string): ThunkResult<void> => {
  return async dispatch => {
    if (!id) return

    const { result, status } = await apiGetOffice(id)
    if (status) {
      dispatch(setOffice(result))
    }
  }
}
