import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiDeleteLoginPhoto } from '../api/login.api'
import { setLogin } from '../reducers'
import { LoginState } from '../types'

export const deleteLoginPhoto = (id: string): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return
    const { status, errors, message, errorData } = await apiDeleteLoginPhoto(id)
    if (status) {
      const login = getState().iam.logins.entities[id]
      dispatch(setLogin({ ...(login as LoginState), photoId: '' }))
      showSuccess('Photo removed')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
