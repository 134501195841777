import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Input, Select } from '~/core-components'
import { Col, ErrorDisplay, Row } from '~/components'
import { Errors, StoreState } from '~/types/store'
import { ImportPayExcelFormData } from '../ImportPayrollExcelDrawer'
import './PayItemMapping.less'

interface PayItemMappingProps {
  visible: boolean
  data: Partial<ImportPayExcelFormData>
  errors?: Errors
  onChange: (data: Partial<ImportPayExcelFormData>) => void
}

export const PayItemMapping: FC<PayItemMappingProps> = ({ visible, data, errors, onChange }) => {
  const payItemMapping = useSelector((store: StoreState) => store.payroll.payImport?.payItemMapping || [])

  return (
    <div className="pay-item-mapping" hidden={!visible}>
      <ErrorDisplay errors={errors} />
      {data.payItemMap?.map((map, index) => {
        const payItemMap = payItemMapping.find(pi => pi.payItemName === map.source)

        return (
          <Row gutter={30} key={map.source} className="pay-item-row">
            <Col span={12}>
              {index === 0 && <div className="pay-item-row__title">Pay item in Excel</div>}
              <Input value={map.source} disabled />
            </Col>
            <Col span={12}>
              {index === 0 && <div className="pay-item-row__title">Pay item in Zealys</div>}
              <Select
                value={map.payItemId}
                onChange={(value: string) =>
                  onChange({
                    payItemMap: [
                      ...data.payItemMap!.slice(0, index),
                      { source: map.source, payItemId: value },
                      ...data.payItemMap!.slice(index + 1, data.payItemMap!.length)
                    ]
                  })
                }
              >
                {payItemMap?.possibleTarget.map(t => (
                  <Select.Option key={t.key} value={t.key}>
                    {t.value}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
        )
      })}
    </div>
  )
}
