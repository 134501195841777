import React, { FC, ChangeEvent, useState, useCallback, useEffect } from 'react'
import moment from 'moment-timezone'
import { Checkbox, CheckboxChangeEvent, Form, Input } from '~/core-components'
import { Col, Row } from '~/components'
import { Errors } from '~/types/store'
import { useFocus } from '~/hooks'
import { ShiftRoleInfoState } from '../../../types'

interface ShiftRoleInfoFormProps {
  data: ShiftRoleInfoState
  readOnly?: boolean
  errors?: Errors
  onChange: (data: ShiftRoleInfoState) => void
}

export const EMPTY_SHIFT_ROLE_INFO_FORM_DATA: ShiftRoleInfoState = {
  name: '',
  inactiveDate: ''
}

export const ShiftRoleInfoForm: FC<ShiftRoleInfoFormProps> = ({ data, readOnly, errors, onChange }) => {
  const [formData, setFormData] = useState<ShiftRoleInfoState>(EMPTY_SHIFT_ROLE_INFO_FORM_DATA)
  const [focusRef] = useFocus(!readOnly)
  const [isInactive, setIsInactive] = useState(false)

  useEffect(() => {
    if (data) {
      setFormData(data)
      setIsInactive(!!data.inactiveDate)
    } else {
      setFormData(EMPTY_SHIFT_ROLE_INFO_FORM_DATA)
      setIsInactive(false)
    }
  }, [data])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      const updated = { ...formData, ...updates }
      setFormData(updated)
      typeof onChange === 'function' && onChange(updated)
    },
    [formData, onChange]
  )

  return (
    <Row gutter={15}>
      <Col flex="auto">
        <Form.Item label="Name">
          <Input
            ref={focusRef}
            value={formData.name}
            readOnly={readOnly}
            onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ name: event.target.value })}
          />
        </Form.Item>
      </Col>
      <Col flex="none">
        <Form.Item label="Inactive" validateStatus={errors?.inactiveDate ? 'error' : ''} help={errors?.inactiveDate}>
          <Checkbox
            checked={isInactive}
            readOnly={readOnly}
            onChange={(event: CheckboxChangeEvent) => {
              setIsInactive(event.target.checked)
              handleFormDataChange({ inactiveDate: event.target.checked ? moment().format('YYYY-MM-DD') : '' })
            }}
          />
        </Form.Item>
      </Col>
    </Row>
  )
}
