import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { ColumnsType, Form, Link, LinkGroup, Space, Table, Tag } from '~/core-components'
import { Col, EmpKeyValues, InactiveTag, Row } from '~/components'
import { fetchEmpKeyvalues, selectKeyvaluesById } from '~/features/master'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { useToggle } from '~/hooks/use-toggle'
import { useFirstInView } from '~/hooks/use-first-in-view'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { isInactive } from '~/utils'
import { MutateEmOtClassDrawer } from './MutateEmOtClassDrawer'
import { IEmOtClassInfo, EmOtClassState } from '../../../types'
import { fetchEmOtClasses } from '../../../actions'
import { selectCurrentEmOtClass, selectEmOtClasses } from '../../../selectors'

interface EmOtClassesProps {
  employeeId?: string
  id?: string
}

type EmOtClassTable = EmOtClassState

const EMPTY_FORM_DATA: IEmOtClassInfo = {
  startDate: '',
  otClassId: ''
}

interface DrawerState {
  visible: boolean
  employeeId?: string
  data?: EmOtClassState
  isCurrent: boolean
}

const DEFAULT_MODAL_STATE: DrawerState = { visible: false, isCurrent: false }

const formStyle: CSSProperties = { padding: '0 24px' }
const linkGroupStyle: CSSProperties = { position: 'absolute', top: 0, right: 0 }

export const EmOtClasses: FC<EmOtClassesProps> = ({ employeeId, id }: EmOtClassesProps) => {
  const { ref, inView } = useFirstInView<HTMLDivElement>({ threshold: 0.25 })
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_MODAL_STATE)
  const [formData, setFormData] = useState<IEmOtClassInfo>(EMPTY_FORM_DATA)
  const current = useSelector(selectCurrentEmOtClass)(employeeId, id)
  const histories = useSelector(selectEmOtClasses)(employeeId)
  const otClasses = useSelector(selectKeyvaluesById)('otClass')
  const [showHistory, toggleShowHistory] = useToggle()
  const canModify = usePermissionGate(Permission.employeeCareer, PermissionAction.Modify)
  const loading = useSelector((state: StoreState) => state.employee.emOtClassesLoading[employeeId || ''])

  useEffect(() => {
    if (inView) {
      dispatch(fetchEmpKeyvalues('otClass', undefined, undefined, { strategy: 'when-empty' }))
    }
  }, [inView])

  useEffect(() => {
    if (inView) {
      if (employeeId) {
        dispatch(fetchEmOtClasses(employeeId))
      }
    }
  }, [inView, employeeId])

  useEffect(() => {
    if (showHistory && histories.length === 0) {
      toggleShowHistory()
    }
  }, [histories.length, showHistory, toggleShowHistory])

  useEffect(() => {
    if (current) {
      const { startDate, otClassId } = current
      setFormData({ startDate, otClassId })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [current])

  const handleEditOtClass = useCallback(
    (emOtClass?: EmOtClassState) => {
      setDrawerState({ visible: true, employeeId, data: emOtClass, isCurrent: id === emOtClass?.id })
    },
    [employeeId, id, setDrawerState]
  )

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_MODAL_STATE)
  }, [])

  const columns: ColumnsType<EmOtClassTable> = [
    {
      title: 'Effective date',
      key: 'startDate',
      dataIndex: 'startDate',
      width: 130,
      render: (value: string) => value && moment(value).format('DD MMM YYYY')
    },
    {
      title: 'Overtime classification',
      key: 'otClassId',
      dataIndex: 'otClassId',
      render: (value: string, record) =>
        value && (
          <Space>
            {otClasses[value]?.value}
            {record.id === id && <Tag type="primary">current</Tag>}
            {isInactive(otClasses[value]?.inactiveDate) && <InactiveTag />}
          </Space>
        )
    },
    {
      key: 'action',
      align: 'right',
      render: (value: string, record) =>
        canModify && (
          <Link size="small" onClick={() => handleEditOtClass(record)}>
            edit
          </Link>
        )
    }
  ]

  return (
    <div ref={ref}>
      <Form style={formStyle}>
        <Row hidden={showHistory}>
          <Col span={24}>
            <Form.Item
              className="form-item-label--block"
              label={
                <>
                  <span>Overtime classification</span>
                  {!loading && (
                    <LinkGroup style={linkGroupStyle}>
                      {canModify && (
                        <Link size="small" onClick={() => handleEditOtClass(current)}>
                          {current ? 'edit' : 'add'}
                        </Link>
                      )}
                      {(histories?.length || 0) > 0 && (
                        <Link size="small" onClick={toggleShowHistory}>
                          {showHistory ? 'hide history' : 'history'}
                        </Link>
                      )}
                    </LinkGroup>
                  )}
                </>
              }
            >
              <EmpKeyValues id="otClass" value={formData.otClassId} readOnly />
            </Form.Item>
          </Col>
        </Row>
        {showHistory && (
          <Row>
            <Col span={24}>
              <Form.Item
                className="form-item-label--block"
                label={
                  <>
                    <span>Overtime classification</span>
                    <LinkGroup style={linkGroupStyle}>
                      <Link size="small" onClick={toggleShowHistory}>
                        {showHistory ? 'hide history' : 'history'}
                      </Link>
                    </LinkGroup>
                  </>
                }
              >
                <Table rowKey="id" dataSource={histories} pagination={false} columns={columns} />
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
      {canModify && <MutateEmOtClassDrawer {...drawerState} onClose={handleCloseDrawer} />}
    </div>
  )
}
