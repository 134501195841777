import React, { FC, useCallback, useState } from 'react'
import { Button, ButtonProps } from '~/core-components'
import { useIsMountedRef } from '~/hooks/use-is-mounted-ref'
import { downloadWithDom, showError } from '~/utils'
import { getFileTimestamp } from '~/utils/dateUtil'
import { apiGetIrasReconciliationReportExcel } from '../../api/ytd.api'

interface ReconciliationReportButtonProps extends ButtonProps {
  ytdYear: string
  employerTaxNo: string
}

export const ReconciliationReportButton: FC<ReconciliationReportButtonProps> = ({
  ytdYear,
  employerTaxNo,
  ...buttonProps
}) => {
  const [downloading, setDownloading] = useState(false)
  const isMountedRef = useIsMountedRef()

  const handleDownloadClick = useCallback(async () => {
    try {
      setDownloading(true)
      const { status, result, errors, message, errorData } = await apiGetIrasReconciliationReportExcel(
        ytdYear,
        employerTaxNo
      )

      if (status) {
        const fileName = `iras_reconciliation_report_${getFileTimestamp()}.xlsx`
        downloadWithDom(result, fileName)
      } else {
        console.error('Error while downloading', errors)
        showError(message, errorData)
      }
    } finally {
      if (isMountedRef.current) setDownloading(false)
    }
  }, [ytdYear, employerTaxNo, isMountedRef])

  return <Button onClick={handleDownloadClick} loading={downloading} {...buttonProps} />
}
