import { replaceCalendarViewers, setCalendarViewersLoading } from '../reducers'
import { apiGetCalendarViewers } from '../api/calendar-viewer.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'
import { CalendarViewerCategory } from '../types'

export const fetchCalendarViewers = (
  category: CalendarViewerCategory,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!category) return

    const loading = getState().calendarViewer.calendarViewersLoading[category]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = (getState().calendarViewer.calendarViewers[category]?.ids?.length || 0) > 0
      if (hasData) return
    }

    try {
      dispatch(setCalendarViewersLoading({ category, loading: true }))
      const { result, status } = await apiGetCalendarViewers(category)
      if (status) {
        dispatch(replaceCalendarViewers({ category, data: result }))
      }
    } finally {
      dispatch(setCalendarViewersLoading({ category, loading: false }))
    }
  }
}
