import { removeKeyvalue } from '~/features/master'
import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiDeleteViewSchema } from '../api/view.api'
import { Screen } from '../types'
import { removeView, removeViewSchema } from '../reducers'

export const deleteViewSchema = (screenCode: Screen, viewId: string): ThunkResult<void> => {
  return async dispatch => {
    if (!screenCode || !viewId) return

    const { result, status, errors, message, errorData } = await apiDeleteViewSchema(screenCode, viewId)

    if (status) {
      dispatch(removeViewSchema({ screenCode, id: viewId }))
      dispatch(removeView({ screenCode, viewId }))
      dispatch(removeKeyvalue({ kv: 'view', id: viewId }))
      showSuccess('Deleted')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
