import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { FetchStrategy } from '~/types/common'
import { fetchBreakTimes } from '../actions'
import { BreakTimeState } from '../types'
import { selectBreakTimes } from '../selectors'

export const useBreakTimes = (
  breakId: string,
  fetchStrategy: FetchStrategy = 'always'
): [BreakTimeState[], boolean] => {
  const data = useSelector(selectBreakTimes)(breakId)
  const loading = useSelector((state: StoreState) => state.attendance.breakTimesLoading[breakId])

  useEffect(() => {
    dispatch(fetchBreakTimes(breakId, { strategy: fetchStrategy }))
  }, [breakId, fetchStrategy])

  return [data, loading]
}
