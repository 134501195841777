import { showSuccess, showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { apiAddCalendar } from '../api/calendar.api'
import { setCalendar } from '../reducers'
import { WorkCalendarInfoState, WorkCalendarState } from '../types'
import { setMaster } from '~/features/master'

export const addCalendar = (calendar: WorkCalendarInfoState): ThunkResult<void> => {
  return async (dispatch): Promise<ActionResult> => {
    const { status, result, errors, message, errorData } = await apiAddCalendar(calendar)
    if (status) {
      const newCalendar: WorkCalendarState = { ...calendar, id: result.id }
      dispatch(setCalendar(newCalendar))
      dispatch(
        setMaster({
          masterName: 'calendar',
          data: { id: result.id, name: newCalendar.name, inactiveDate: newCalendar.inactiveDate }
        })
      )
      showSuccess('Added')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
