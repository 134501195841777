import { showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiUnlockAllPayRecords } from '../api/payrecord-sg.api'
import { PayRecordSgState } from '../types'
import { setPayRecords, setPayRecordLoading } from '../reducers'

export const unlockAllPayRecords = (payRunId: string): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!payRunId) return

    try {
      dispatch(setPayRecordLoading({ payRunId, loading: true }))
      const { status, errors, message, errorData } = await apiUnlockAllPayRecords(payRunId)
      if (status) {
        const payRecordValues = Object.values(getState().payroll.payRecords[payRunId]?.entities) || []

        const payRecords = payRecordValues.map(payRecord => {
          const data: PayRecordSgState = { ...(payRecord as PayRecordSgState), ...{ lockedBy: '', lockedDate: '' } }
          return data
        })

        dispatch(setPayRecords({ payRunId, data: payRecords }))
      } else {
        if (Object.keys(errors).length === 0) {
          showError(message, errorData)
        }
      }
      return { errors }
    } finally {
      dispatch(setPayRecordLoading({ payRunId, loading: false }))
    }
  }
}
