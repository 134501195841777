import React, { FC, useEffect, useCallback, useState } from 'react'
import { ImageAuth } from '~/components'
import { Avatar, Form, SecondaryLink } from '~/core-components'
import { PlusCircleOutlined } from '@ant-design/icons'
import { EmNamePhotoState } from '~/features/employee/types'
import { EmSelectionDrawer } from '~/features/employee'
import { apiGetEmNamePhotoByTaxNos } from '~/features/employee/api/employee.api'
import { ViewName } from '~/features/selection'
import { Pagination } from '~/types/common'
import { getBaseUrl } from '~/utils'
import './SelectEmployeesByTaxNo.less'

export interface SelectEmployeesByTaxNoProps {
  viewName: ViewName
  label?: string
  employeeTaxNos: string[]
  onFetchData: (viewId: string, pagination: Pagination, search: string, pastResignees: boolean) => void
  onSelect: (employeeTaxNos: string[]) => void
}

interface DrawerState {
  visible: boolean
}
const DRAWER_STATE: DrawerState = { visible: false }

const baseUrl = getBaseUrl('/filestore')

export const SelectEmployeesByTaxNo: FC<SelectEmployeesByTaxNoProps> = ({
  viewName,
  label,
  employeeTaxNos,
  onFetchData,
  onSelect
}) => {
  const [namePhotos, setNamePhotos] = useState<EmNamePhotoState[]>([])
  const [loading, setLoading] = useState(false)
  const [drawerState, setAddEmployeeDrawerState] = useState<DrawerState>(DRAWER_STATE)

  useEffect(() => {
    if (employeeTaxNos && employeeTaxNos.length > 0) {
      const fetchPhotoIds = async () => {
        try {
          setLoading(true)
          const { result } = await apiGetEmNamePhotoByTaxNos({ employeeTaxNos })
          setNamePhotos(result)
        } finally {
          setLoading(false)
        }
      }
      fetchPhotoIds()
    }
  }, [employeeTaxNos])

  const handleOpenDrawer = useCallback(() => {
    setAddEmployeeDrawerState({ visible: true })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setAddEmployeeDrawerState(DRAWER_STATE)
  }, [])

  return (
    <div className="select-employees-by-tax-no">
      <Form.Item label={label ? label : 'Selected employee(s)'}>
        {employeeTaxNos && employeeTaxNos.length > 0 && (
          <SecondaryLink onClick={handleOpenDrawer}>
            {employeeTaxNos.slice(0, 3).map((employeeTaxNo, index) => {
              const photoId = namePhotos[index]?.photoId || undefined
              return (
                <span className={index > 0 ? 'layer-effect' : ''} key={employeeTaxNo}>
                  {!loading && photoId ? (
                    <Avatar
                      size={24}
                      src={
                        <ImageAuth
                          src={`${baseUrl}/file/${photoId}/thumbnailphoto/24`}
                          // src={employeeTaxNo && `/employee/employee/${employeeTaxNo}/avatar/24?photo_id=${photoId}`}
                          width={24}
                          preview={false}
                          defaultImage={<Avatar size={24} icon={<i className="fal fa-user" />} />}
                        />
                      }
                    />
                  ) : (
                    <Avatar size={24} icon={<i className="fal fa-user" />} />
                  )}
                </span>
              )
            })}
            {employeeTaxNos.length > 3 && <span className="link-more">+{employeeTaxNos.length - 3} more</span>}
          </SecondaryLink>
        )}
        {(!employeeTaxNos || employeeTaxNos.length === 0) && (
          <div className="div-avatar">
            <SecondaryLink onClick={handleOpenDrawer}>
              <PlusCircleOutlined />
            </SecondaryLink>
          </div>
        )}
      </Form.Item>
      <EmSelectionDrawer
        {...drawerState}
        viewName={viewName}
        employeeIds={employeeTaxNos}
        onFetchData={onFetchData}
        onSelect={onSelect}
        onClose={handleCloseDrawer}
      />
    </div>
  )
}
