import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { useIsMountedRef } from '~/hooks/use-is-mounted-ref'
import { Button, Card, ColumnsType, Form, Space, Table, Tooltip } from '~/core-components'
import { AmountDisplay, InfoTooltip, Person, SearchInput, YearKeyValues } from '~/components'
import { useSysOptions } from '~/features/employee'
import {
  Screen,
  ViewCriteriaSimple,
  ViewCriteria,
  updateViewCriteria,
  useFirstView,
  useViewSchema,
  ViewSelectionButton
} from '~/features/selection'
import { usePermissionGate } from '~/features/iam'
import { Permission, PermissionAction } from '~/constants'
import { CLA_ROUTES, EMP_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import {
  formatYearMonth,
  formatMonth,
  formatMoney,
  formatDateRange,
  getFileTimestamp,
  showError,
  downloadWithDom,
  getBaseUrl
} from '~/utils'
import { fetchClaimEntitlementView } from '../../actions'
import { selectClaimEntitlementsView } from '../../selectors'
import { apiGetClaimEntitlementExcel } from '../../api/claim-entitlement.api'
import { ClaimEntitlementRowState } from '../../types'
import { refetchClaimEntitlementsView } from '../../reducers'
import { formatClaimLimit } from '../../util'
import { ClaimRefreshButton } from './components/ClaimRefreshButton'
import { ClaimTypeName } from './components/ClaimTypeName'
import { AddClaimRecordButton } from '../ClaimRecords/components/AddClaimRecordButton'
import { CePoliciesInfo } from '../CePolicies/CePoliciesInfo'
import './ClaimEntitlements.less'

interface ClaimEntitlementsProps {}

const nameColWidth = 250
const defaultColWidth = 150
const SCREEN_CODE: Screen = 'claim_entitlement'
const paginationStyle: CSSProperties = { marginRight: 20 }

const TODAY_YEAR = moment().year()
const PAGE_SIZE_OPTIONS = ['20', '50', '100']
const baseUrl = getBaseUrl('/filestore')

export const ClaimEntitlements: FC<ClaimEntitlementsProps> = () => {
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(20)
  const [search, setSearch] = useState<string>('')
  const [periodYear, setPeriodYear] = useState<number>(TODAY_YEAR)
  const [downloading, setDownloading] = useState(false)

  const selection = useSelector((state: StoreState) => state.selection.sysSelectionFields[SCREEN_CODE])
  const [view, viewLoading] = useFirstView(SCREEN_CODE)
  const viewId = view?.id || ''
  const [schema] = useViewSchema(SCREEN_CODE, viewId)
  const dataLoading = useSelector((state: StoreState) => state.claim.claimEntitlementsViewLoading)
  const data = useSelector(selectClaimEntitlementsView)(viewId)
  const isMountedRef = useIsMountedRef()
  const canModify = usePermissionGate(Permission.claEntitlement, PermissionAction.Modify)
  const canModifyClaRecord = usePermissionGate(Permission.claRecord, PermissionAction.Modify)
  const canViewEmployee = usePermissionGate(Permission.employee)
  const claimExpanded = useSelector((state: StoreState) => state.claim.claimExpanded)
  const refetch = useSelector((state: StoreState) => state.claim.claimEntitlementsViewRefetch)

  const [paymentMethods] = useSysOptions('cla_payment_method')
  const [maxTimesBasis] = useSysOptions('cla_max_times_basis')

  useEffect(() => {
    if (viewId) {
      dispatch(
        fetchClaimEntitlementView(viewId, periodYear, { offset: pageSize * (page - 1), limit: pageSize }, search)
      )
    }
  }, [viewId, periodYear, page, pageSize, search, refetch])

  const tableWidth =
    (schema?.selection.reduce(
      (sum, f) => (sum += selection?.entities[f.selectionFieldId]?.width || defaultColWidth),
      0
    ) || 0) + nameColWidth

  let columns: ColumnsType<ClaimEntitlementRowState> = [
    {
      title: 'Name',
      key: 'employeeId',
      dataIndex: 'employeeId',
      fixed: 'left',
      className: 'first-col',
      width: nameColWidth,
      onCell: (record, index) => {
        const prevId = data.data[(index as number) - 1]?.employeeId
        if (prevId === record.employeeId) {
          return { rowSpan: 0 }
        } else {
          const nextId = data.data[(index as number) + 1]?.employeeId
          if (nextId === record.employeeId) {
            return { rowSpan: record.rowSpan }
          }
        }
        return {}
      },
      render: (_, record) => (
        <Person
          name={record.employeeName}
          description={!claimExpanded && record.description}
          photo={record.photoId && `${baseUrl}/file/${record.photoId}/thumbnailphoto/${claimExpanded ? 18 : 36}`}
          // photo={
          //   record.employeeId &&
          //   `/employee/employee/${record.employeeId}/avatar/${claimExpanded ? 18 : 36}?photo_id=${record.photoId}`
          // }
          path={canViewEmployee ? EMP_ROUTES.employee.replace(':id', record.employeeId) : undefined}
          size={claimExpanded ? 18 : 36}
        />
      )
    }
  ]

  if (schema) {
    // Configurable employee columns
    columns.push(
      ...schema?.selection.map(f => {
        var field = selection?.entities[f.selectionFieldId]
        return {
          title: field?.description,
          key: field?.fieldName,
          dataIndex: field?.fieldName,
          width: field?.width || defaultColWidth,
          onCell: (record: ClaimEntitlementRowState, index?: number) => {
            const prevId = data.data[(index as number) - 1]?.employeeId
            if (prevId === record.employeeId) {
              return { rowSpan: 0 }
            } else {
              const nextId = data.data[(index as number) + 1]?.employeeId
              if (nextId === record.employeeId) {
                return { rowSpan: record.rowSpan }
              }
            }
            return {}
          },
          render: (value: string | number) => {
            var display = value
            if (field?.format === 'date' && value) {
              display = moment(value).format('DD MMM YYYY')
            }
            if (field?.format === 'yearmonth' && value) {
              display = formatYearMonth(value as string)
            }
            if (field?.format === 'month' && value) {
              display = formatMonth(value as number)
            }
            if (field?.format === 'money' && value) {
              display = formatMoney(value as number)
            }
            return display
          }
        }
      })
    )
  }

  // Fixed claim entitlement columns
  columns.push(
    ...([
      {
        title: 'Claim type',
        key: 'claimTypeId',
        dataIndex: 'claimTypeId',
        width: 230,
        render: (value: string, record: ClaimEntitlementRowState) => {
          const path = CLA_ROUTES.entitlement
            .replace(':periodYear', moment(record.periodStartDate).year().toString())
            .replace(':employeeId', record.employeeId)
            .replace(':claimTypeId', record.claimTypeId)

          if (claimExpanded) {
            return (
              <Space>
                <ClaimTypeName id={value} path={path} />
              </Space>
            )
          }

          return (
            <ClaimTypeName
              id={value}
              description={formatDateRange(record.periodStartDate, record.periodEndDate)}
              path={path}
            />
          )
        }
      },
      {
        title: 'Limit',
        key: 'monthlyLimit',
        dataIndex: 'monthlyLimit',
        align: claimExpanded ? 'center' : 'left',
        width: claimExpanded ? 50 : 180,
        render: (value: number, record) => {
          const limitText = formatClaimLimit(
            record.currencyCode,
            record.requireConfirm,
            record.requireMonths,
            record.monthlyLimit,
            record.dailyLimit,
            record.perTimeAmount,
            record.perTimePercentage,
            record.perTimeEmAmount,
            record.maxTimes,
            maxTimesBasis && maxTimesBasis[record.maxTimesBasis]?.value
          )

          if (claimExpanded) {
            if (limitText.length === 0) return null

            return (
              limitText.length > 0 && (
                <Tooltip
                  title={
                    <>
                      {limitText.map((l, index) => (
                        <div key={index}>{l}</div>
                      ))}
                    </>
                  }
                >
                  <i className="fal fa-circle-info" />
                </Tooltip>
              )
            )
          } else {
            if (limitText.length === 0) return '-'

            return (
              <>
                {limitText.map((l, index) => (
                  <div className="limit-text" key={index}>
                    {l}
                  </div>
                ))}
              </>
            )
          }
        }
      },
      {
        title: 'Accrued',
        key: 'earned',
        dataIndex: 'earned',
        align: 'right',
        width: 100,
        render: (value: number, record) => {
          let tooltipMessages: string[] = []

          const isProrated = record.entitlement !== value && !!record.entitlement
          if (isProrated) {
            tooltipMessages.push(`Entitlement (before proration): ${formatMoney(record.entitlement, 2)}`)
          }
          if (record.earnedAdj !== 0 && !!record.earnedAdj) {
            tooltipMessages.push(`Adjustment: ${formatMoney(record.earnedAdj, 2)}`)
          }

          return (
            <span>
              <AmountDisplay value={value} />
              {tooltipMessages.length > 0 && (
                <CePoliciesInfo
                  tooltip={tooltipMessages.join(' ')}
                  periodCode={isProrated ? record.periodCode : undefined}
                  employeeId={isProrated ? record.employeeId : undefined}
                  claimTypeId={isProrated ? record.claimTypeId : undefined}
                />
              )}
            </span>
          )
        }
      },
      {
        title: 'Pending for approval',
        key: 'pendingApproval',
        dataIndex: 'pendingApproval',
        align: 'right',
        width: 180,
        render: (value: number) => <AmountDisplay value={value} />
      },
      {
        title: 'Pending for payment',
        key: 'pendingPayment',
        dataIndex: 'pendingPayment',
        align: 'right',
        width: 180,
        render: (value: number) => <AmountDisplay value={value} />
      },
      {
        title: 'Paid',
        key: 'paid',
        dataIndex: 'paid',
        align: 'right',
        width: 100,
        render: (value: number, record) => (
          <span>
            <AmountDisplay value={value} />
            {record.paidAdj !== 0 && !!record.paidAdj && (
              <InfoTooltip className="cell-icon" title={`Adjustment: ${formatMoney(record.paidAdj, 2)}`} />
            )}
          </span>
        )
      },
      {
        title: 'Balance',
        key: 'balance',
        dataIndex: 'balance',
        align: 'right',
        width: 100,
        render: (value: number) => <AmountDisplay value={value} />
      },
      {
        title: 'Reimbursement method',
        key: 'paymentMethod',
        dataIndex: 'paymentMethod',
        width: 180,
        render: (value: string) => paymentMethods[value]?.value || ''
      },
      {
        key: 'notes',
        dataIndex: 'notes',
        width: 50,
        render: (value: string) =>
          value && (
            <Tooltip title={value} placement="topRight">
              <i className="fal fa-note" />
            </Tooltip>
          )
      }
    ] as ColumnsType<ClaimEntitlementRowState>)
  )

  const handleCloseSelectionDrawer = useCallback((changed?: boolean) => {
    if (changed) dispatch(refetchClaimEntitlementsView())
  }, [])

  const handleCriteriaApply = useCallback(
    async (criteria: ViewCriteria[]) => {
      if (viewId) {
        setPage(1)
        await dispatch(updateViewCriteria(SCREEN_CODE, viewId, { id: viewId, criteria }))
        dispatch(refetchClaimEntitlementsView())
      }
    },
    [viewId]
  )

  const handlePaginationChange = useCallback((page: number, pageSize?: number) => {
    setPage(page)
    setPageSize(pageSize || 20)
  }, [])

  const handleSearch = useCallback((value: string) => {
    setPage(1)
    setSearch(value)
  }, [])

  const handlePeriodChange = useCallback(periodYear => {
    setPage(1)
    setPeriodYear(periodYear)
  }, [])

  const handleDownloadClick = useCallback(async () => {
    if (viewId && periodYear) {
      try {
        setDownloading(true)
        const { status, result, errors, message, errorData } = await apiGetClaimEntitlementExcel(viewId, periodYear, {
          search
        })

        if (status) {
          const fileName = `claim_entitlement_${getFileTimestamp()}.xlsx`
          downloadWithDom(result, fileName)
        } else {
          console.error('Error while downloading', errors)
          showError(message, errorData)
        }
      } finally {
        if (isMountedRef.current) setDownloading(false)
      }
    }
  }, [viewId, periodYear, search, isMountedRef])

  return (
    <div className="claim-entitlements">
      <div className="claim-entitlements__body">
        <div className="claim-entitlements__action-bar">
          <Space>
            <Form.Item label="">
              <YearKeyValues
                noOfYearBefore={-5}
                noOfYearAfter={0}
                value={periodYear}
                onChange={handlePeriodChange}
                allowClear={false}
              />
            </Form.Item>
            <Form.Item label="">
              <SearchInput onSearch={handleSearch} />
            </Form.Item>
          </Space>
          <ViewCriteriaSimple screenCode={SCREEN_CODE} viewId={viewId} onApply={handleCriteriaApply} label="" />
          <Space align="start">
            {canModifyClaRecord && <AddClaimRecordButton />}
            {canModify && (
              <>
                {/* <AddClaimEntitlementButton /> */}
                <ClaimRefreshButton />
              </>
            )}
            <Tooltip title="Download excel">
              <Button
                icon={<i className="fal fa-arrow-down-to-bracket" />}
                onClick={handleDownloadClick}
                loading={downloading}
              />
            </Tooltip>
            <ViewSelectionButton
              screenCode={SCREEN_CODE}
              viewId={viewId}
              title="Configure claim entitlement columns"
              onClose={handleCloseSelectionDrawer}
            />
          </Space>
        </div>
        <Card fitParent table>
          <Table
            rowKey="id"
            dataSource={data?.data}
            columns={columns}
            fitParent
            loading={dataLoading || viewLoading}
            scroll={{ x: tableWidth, y: 1000 }}
            pagination={{
              total: data?.count,
              current: page,
              pageSize,
              pageSizeOptions: PAGE_SIZE_OPTIONS,
              showSizeChanger: true,
              onChange: handlePaginationChange,
              style: paginationStyle
            }}
          />
        </Card>
      </div>
    </div>
  )
}
