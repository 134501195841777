import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchEntitledLeaveTypes } from '../actions'
import { EntitledLeaveTypeState } from '../types'

export const useEntitledLeaveType = (
  employeeId: string,
  leaveTypeId: string,
  fetchStrategy: FetchStrategy = 'when-empty'
): [EntitledLeaveTypeState | undefined, boolean] => {
  const entitledLeaveType = useSelector(
    (state: StoreState) => state.leave.entitledLeaveTypes[employeeId]?.entities[leaveTypeId]
  )
  const loading = useSelector((state: StoreState) => state.leave.entitledLeaveTypesLoading[employeeId])

  useEffect(() => {
    if (employeeId) {
      dispatch(fetchEntitledLeaveTypes(employeeId, { strategy: fetchStrategy }))
    }
  }, [employeeId, fetchStrategy])

  return [entitledLeaveType, loading]
}
