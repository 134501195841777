import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Dictionary } from '@reduxjs/toolkit'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { FetchStrategy } from '~/types/common'
import { fetchShiftRoles } from '../actions'
import { ShiftRoleState } from '../types'

export const useShiftRolesDict = (
  fetchStrategy: FetchStrategy = 'when-empty'
): [Dictionary<ShiftRoleState>, boolean] => {
  const data = useSelector((state: StoreState) => state.attendance.shiftRoles.entities)
  const loading = useSelector((state: StoreState) => state.attendance.shiftRolesLoading)

  useEffect(() => {
    dispatch(fetchShiftRoles({ strategy: fetchStrategy }))
  }, [fetchStrategy])

  return [data, loading]
}
