import React, { FC, useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { LoadingOutlined } from '@ant-design/icons'
import { Button, Image, OrgsChart, Spin } from '~/core-components'
import { DocumentTitle } from '~/components'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { Permission, PermissionAction } from '~/constants'
import { usePermissionGate } from '~/features/iam'
import { request, timeout } from '~/utils/request'
import { apiGetOrgChart, apiGetChildrenChart, apiGetParentChart } from '../../api/em-orgchart.api'
import { mapIOrgChartsToOrgChartState, mapIOrgChartToOrgChartState } from '../../types/em-orgchart.mapper'
import { useCompany } from '~/features/master/hooks'
import { OrgChartState } from '../../types/em-orgchart.state'
import './EmOrgChart.less'

const EMPTY_TREE: OrgChartState = {
  id: '',
  person: { id: '', avatar: '', avatarSrc: '', department: '', name: '', title: '', totalReports: 0 },
  hasParent: false,
  hasChild: false,
  isHighlight: false,
  children: []
}

interface EmOrgChartProps {
  companyId: string
}

export const EmOrgChart: FC<EmOrgChartProps> = ({ companyId }) => {
  const history = useHistory()
  const [tree, setTree] = useState<OrgChartState>(EMPTY_TREE)
  const fetchRef = React.useRef(0)
  const [company, companyLoading] = useCompany(companyId)
  const canModifyCo = usePermissionGate(Permission.company, PermissionAction.Modify)
  const [loading, setLoading] = useState(true)

  const fetcher = useCallback(async () => {
    try {
      fetchRef.current += 1
      const fetchId = fetchRef.current
      setLoading(true)
      setTree(EMPTY_TREE)

      if (companyId) {
        const { status, result } = await apiGetOrgChart(companyId)
        if (status && fetchId === fetchRef.current) {
          const res = mapIOrgChartsToOrgChartState(result)
          setTree(res)
        }
      }
    } finally {
      setLoading(false)
    }
  }, [companyId])

  useEffect(() => {
    fetcher()
  }, [fetcher])

  const handleLoadParent = useCallback(
    async (d: OrgChartState) => {
      const { status, result } = await apiGetParentChart(companyId, d.id)
      if (status) {
        const parent = mapIOrgChartsToOrgChartState(result)
        parent.children?.push(d)

        return parent
      }
      return d
    },
    [companyId]
  )

  const handleLoadChildren = useCallback(
    async (d: OrgChartState) => {
      const { status, result } = await apiGetChildrenChart(companyId, d.id)
      if (status) {
        return result.map(c => mapIOrgChartToOrgChartState(c))
      }
      return []
    },
    [companyId]
  )

  const handleLoadImage = useCallback(async (d: OrgChartState) => {
    if (d.person.avatarSrc) {
      const { result, status } = await request('get', d.person.avatarSrc, undefined, {
        responseType: 'blob',
        timeout
      })
      if (status) {
        return URL.createObjectURL(result)
      }
    }
  }, [])

  const handleRedirect = useCallback(
    (companyId: string) => {
      if (companyId) history.push(SETTINGS_ROUTES.company.replace(':id', companyId))
      else history.push(SETTINGS_ROUTES.companies)
    },
    [history]
  )

  if (loading || companyLoading) return <Spin size="small" indicator={<LoadingOutlined spin />} />

  if (!companyId) {
    return (
      <div className="required-setup">
        <Image src="/no-company.jpg" alt="No company" />
        <div>You have not set up a company</div>
        {canModifyCo && (
          <Button type="primary" onClick={() => handleRedirect('')}>
            Create new company
          </Button>
        )}
      </div>
    )
  }

  if (!company?.topReportingMgrId) {
    return (
      <div className="required-setup">
        <Image src="/no-top-manager.jpg" alt="No top reporting manager" />
        <div>Your organization chart is currently empty because the top reporting manager has not been set up yet.</div>
        {canModifyCo && (
          <Button type="primary" onClick={() => handleRedirect(companyId)}>
            Set up top reporting manager
          </Button>
        )}
      </div>
    )
  }

  return (
    <div className="em-orgchart">
      <DocumentTitle title="People Organisation" />
      <OrgsChart
        tree={tree}
        loadParent={handleLoadParent}
        loadChildren={handleLoadChildren}
        loadImage={handleLoadImage}
      />
    </div>
  )
}
