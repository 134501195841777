import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchMyLogin } from '../actions'
import { MyLogin, selectMyLogin } from '../selectors'

const tenant = localStorage.getItem('tenant')

export const useMyLogin = (fetchStrategy: FetchStrategy = 'when-empty'): [MyLogin, boolean] => {
  const myLogin = useSelector(selectMyLogin)
  const loading = useSelector((state: StoreState) => state.iam.loginsLoading)

  useEffect(() => {
    if (tenant) {
      dispatch(fetchMyLogin(tenant, { strategy: fetchStrategy }))
    }
  }, [fetchStrategy])

  return [myLogin, loading]
}
