import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiUpdateClaimPendingPayment } from '../api/claim-pending-payment.api'
import { IUpdateClaimPendingPayment } from '../types'
import { refetchClaimPendingPaymentsView } from '../reducers'

export const updateClaimPendingPayment = (claimPendingPayment: IUpdateClaimPendingPayment): ThunkResult<void> => {
  return async dispatch => {
    const { status, result, errors, message, errorData } = await apiUpdateClaimPendingPayment(claimPendingPayment)

    if (status) {
      dispatch(refetchClaimPendingPaymentsView())
      showSuccess('Updated')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }

    return { result, errors }
  }
}
