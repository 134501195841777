import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { WorkCalendarState } from '../types'

export const selectCalendars = createSelector(
  (state: StoreState) => state.attendance.calendars,
  (calendarsState): WorkCalendarState[] => {
    const calendars = Object.values(calendarsState?.entities || {}) as WorkCalendarState[]
    return calendars.sort((a, b) => {
      const aInactiveDate = new Date(a!.inactiveDate || '').getTime() || 0
      const bInactiveDate = new Date(b!.inactiveDate || '').getTime() || 0

      if (bInactiveDate === 0 && aInactiveDate === 0) {
        return a!.name.localeCompare(b!.name)
      } else {
        return aInactiveDate - bInactiveDate
      }
    })
  }
)
