import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { WorkCalendarPatternState } from '../types'

export const selectCalendarPatterns = createSelector(
  (state: StoreState) => state.attendance.calendarPatterns,
  calendarPatternsState =>
    memoize((calendarId?: string): WorkCalendarPatternState[] => {
      if (!calendarId) return []

      const calendarPatterns = Object.values(
        calendarPatternsState[calendarId]?.entities || {}
      ) as WorkCalendarPatternState[]
      return calendarPatterns.sort((a, b) => (a.sequence || 0) - (b.sequence || 0))
    })
)
