import { replaceMyClaimRecordsView, setMyClaimRecordsViewLoading } from '../reducers'
import { apiGetMyClaimRecordsView } from '../api/ss-claim-record.api'
import { ThunkResult } from '~/types/store'
import { Pagination } from '~/types/common'

export const fetchMyClaimRecordsView = (pagination: Pagination): ThunkResult<void> => {
  return async dispatch => {
    try {
      dispatch(setMyClaimRecordsViewLoading(true))
      const { result, status } = await apiGetMyClaimRecordsView(pagination)
      if (status) dispatch(replaceMyClaimRecordsView(result))
    } finally {
      dispatch(setMyClaimRecordsViewLoading(false))
    }
  }
}
