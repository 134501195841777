import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Col, DocumentTitle, NewTabLink, PeriodKeyValues, Row } from '~/components'
import { Button, Form, Input, PageHeader, Select, Space, Tag } from '~/core-components'
import { fetchCompanies, selectCompaniesCpfNo } from '~/features/master'
import { useIsMountedRef } from '~/hooks/use-is-mounted-ref'
import { REPORTS_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { Errors } from '~/types/store'
import { downloadWithDom, getFileTimestamp, showError } from '~/utils'
import { apiGetCpfFile } from '../../api/rpt-cpf-file.api'
import { IRptCpfFile } from '../../types'
import { KeyValue } from '~/types/common'
import { fetchPayRuns, selectPayRuns } from '~/features/payroll'
import { fetchCpfPayGroups } from '../../actions'
import './RptCpfFile.less'

export interface RptCpfFileProps {}

interface RptCpfFileForm extends IRptCpfFile {}

const currentPeriod = moment().format('YYYY-MM')

const EMPTY_FORM_DATA: RptCpfFileForm = {
  paymentMonth: currentPeriod,
  cpfNo: '',
  adviceCode: '01',
  payGroupIds: []
}

const routes = [
  {
    path: REPORTS_ROUTES.main,
    breadcrumbName: 'Reports'
  },
  {
    path: '',
    breadcrumbName: 'CPF EZPay'
  }
]

export const RptCpfFile: FC<RptCpfFileProps> = () => {
  const [formData, setFormData] = useState<RptCpfFileForm>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const [downloading, setDownloading] = useState(false)
  const [payGroups, setPayGroups] = useState<KeyValue[]>([])
  const [payGroupsLoading, setPayGroupsLoading] = useState(false)
  const companiesCpfNo = useSelector(selectCompaniesCpfNo)()
  const isMountedRef = useIsMountedRef()
  const payRuns = useSelector(selectPayRuns)()
  const query = new URLSearchParams(useLocation().search)
  const month = query.get('month')

  const getCpfPayGroups = useCallback(async (cpfNo: string) => {
    setPayGroupsLoading(true)
    try {
      const { result } = await dispatch(fetchCpfPayGroups(cpfNo))
      setPayGroups(result)
    } finally {
      setPayGroupsLoading(false)
    }
  }, [])

  useEffect(() => {
    if (formData.cpfNo) getCpfPayGroups(formData.cpfNo)
  }, [formData.cpfNo, getCpfPayGroups])

  useEffect(() => {
    dispatch(fetchCompanies())
    dispatch(fetchPayRuns({ strategy: 'when-empty' }))
  }, [])

  useEffect(() => {
    if (month) {
      setFormData(data => ({ ...data, paymentMonth: month }))
    } else {
      const firstPayRunPeriod = payRuns.sort((a, b) => b.payPeriod.localeCompare(a.payPeriod)).slice(0, 1)[0]?.payPeriod
      if (firstPayRunPeriod) {
        setFormData(data => ({ ...data, paymentMonth: firstPayRunPeriod }))
      }
    }
  }, [payRuns, month])

  useEffect(() => {
    if (companiesCpfNo && companiesCpfNo.length > 0) {
      setFormData(data => ({ ...data, cpfNo: companiesCpfNo[0].cpfNo }))
    }
  }, [companiesCpfNo])

  const handleFormDataChange = useCallback(async (updates: { [field: string]: any }) => {
    if ('cpfNo' in updates) {
      updates.payGroupIds = []
    }

    setFormData(data => ({ ...data, ...updates }))
  }, [])

  const handleDownload = useCallback(async () => {
    try {
      setDownloading(true)
      const { status, result, errors, message, errorData } = await apiGetCpfFile(formData)

      if (status) {
        const fileName = `cpf_file_${getFileTimestamp()}.zip`
        downloadWithDom(result, fileName)
      } else {
        console.error('Error while downloading', errors)
        setErrors(errors)
        showError(message, errorData)
      }
    } finally {
      if (isMountedRef.current) setDownloading(false)
    }
  }, [formData, isMountedRef])

  return (
    <div id="rpt-cpf-file" className="rpt-cpf-file">
      <DocumentTitle title="CPF EZPay" />
      <PageHeader title="CPF EZPay" containerId="rpt-cpf-file" breadcrumb={{ routes }} />
      <Form className="rpt-cpf-file__form" layout="horizontal" labelCol={{ flex: '170px' }}>
        <Row>
          <Col flex="1">
            <Form.Item
              label="Payment month"
              validateStatus={errors?.paymentMonth ? 'error' : ''}
              help={errors?.paymentMonth}
            >
              <PeriodKeyValues
                value={formData.paymentMonth}
                startMonth={currentPeriod}
                noOfMonthBefore={-18}
                noOfMonthAfter={10}
                onChange={(paymentMonth: string) => handleFormDataChange({ paymentMonth })}
              />
            </Form.Item>
          </Col>
          <Col>
            <Button type="primary" onClick={handleDownload} loading={downloading}>
              Download
            </Button>
          </Col>
        </Row>
        <Row>
          <Col flex="1">
            <Form.Item
              label="CPF submission no. (CSN)"
              validateStatus={errors?.cpfNo ? 'error' : ''}
              help={errors?.cpfNo}
            >
              <Select value={formData.cpfNo} onChange={(cpfNo: string) => handleFormDataChange({ cpfNo })}>
                {companiesCpfNo?.map(co => (
                  <Select.Option key={co.cpfNo} value={co.cpfNo}>
                    <Space>
                      {co.cpfNo}
                      {co.companies
                        .filter(c => !!c)
                        .map(c => (
                          <Tag key={c}>{c}</Tag>
                        ))}
                    </Space>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col flex="280px">
            <Form.Item
              label="Advice code"
              validateStatus={errors?.adviceCode ? 'error' : ''}
              help={errors?.adviceCode}
              tooltip={{
                title:
                  'Files with same advice code will be printed on the same hardcopy record of payment. Advice code must be from 01 to 99.',
                icon: (
                  <span>
                    <i className="fal fa-circle-info" />
                  </span>
                )
              }}
            >
              <Input
                value={formData.adviceCode}
                maxLength={2}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleFormDataChange({ adviceCode: event.target.value })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col flex="1">
            <Form.Item
              label="Payroll groups"
              validateStatus={errors?.payGroupIds ? 'error' : ''}
              help={errors?.payGroupIds}
            >
              <Select
                mode="multiple"
                value={formData.payGroupIds}
                placeholder="All payroll groups"
                onChange={(payGroupIds: string[]) => handleFormDataChange({ payGroupIds })}
                loading={payGroupsLoading}
              >
                {payGroups.map(pg => (
                  <Select.Option key={pg.key} value={pg.key}>
                    {pg.value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col flex="1"></Col>
          <Col>
            <NewTabLink path="https://www2.cpf.gov.sg/ert/dsa/spcpSelection.action">Go to CPF EZPay Portal</NewTabLink>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
