import React, { CSSProperties, FC, useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import moment from 'moment-timezone'
import classNames from 'classnames'
import { LoadingOutlined } from '@ant-design/icons'
import { Button, Card, SecondaryText, Spin, Tooltip } from '~/core-components'
import { Col, Row } from '~/components'
import { dispatch } from '~/stores/store'
import { emptyGuid, Permission, PermissionAction } from '~/constants'
import { useSysOptions } from '~/features/employee'
import { usePermissionGate } from '~/features/iam/hooks'
import { fetchCompanies, fetchMasters, selectCompanyKeyvalues, selectPayGroups } from '~/features/master'
import { formatMoney, getTextWidth } from '~/utils'
import { PAY_ROUTES } from '~/routes/routes'
import { StoreState } from '~/types/store'
import { PayRunState, PayRunSummaryState } from '../../types'
import { PayRunStatusTag } from '../PayRunStatusTag/PayRunStatusTag'
import './PayRunCard.less'

export interface PayRunCardProps {
  payrun: PayRunState
  summary?: PayRunSummaryState
  loading?: boolean
  onAdd?: (defaultValue: PayRunState) => void
}

const secondaryTextStyle: CSSProperties = { fontSize: '13px' }

export const PayRunCard: FC<PayRunCardProps> = ({ payrun, summary, loading, onAdd }) => {
  const history = useHistory()
  const [payRunTypes] = useSysOptions('pay_run_type')
  const summaryLoading = useSelector((state: StoreState) => state.payroll.payRunSummariesLoading)
  const canAdd = usePermissionGate(Permission.payRun, PermissionAction.Add)
  const payRunTypeName = payRunTypes[payrun.payRunType]?.value || ''
  const payGroup = useSelector(selectPayGroups).find(pg => pg?.id === payrun.payGroupId)
  const payGroupName = payGroup?.name || ''
  const companyName = useSelector(selectCompanyKeyvalues)[payGroup?.companyId || emptyGuid]?.value || ''
  const defaultPayRunDesc = `${payRunTypeName} ${payrun.payPeriod} ${payGroupName}`
  const payRunDescEl = document.querySelector('.payrun-card__description') || undefined

  useEffect(() => {
    dispatch(fetchMasters('payGroup', { strategy: 'when-empty' }))
    dispatch(fetchCompanies({ strategy: 'when-empty' }))
  }, [])

  const handleViewClick = useCallback(() => {
    history.push(PAY_ROUTES.payRun.replace(':id', payrun.id).replace(':tab?', ''))
  }, [payrun, history])

  const handleAddClick = useCallback(() => {
    typeof onAdd === 'function' && onAdd(payrun)
  }, [payrun, onAdd])

  const isNew = !payrun.id
  const PayRunDesc = useMemo(
    () => (
      <>
        {`${payRunTypeName} `}
        {payGroupName && (
          <>
            <span className="payrun-card__dot">&#9675;</span>{' '}
          </>
        )}
        {payGroupName}
      </>
    ),
    [payRunTypeName, payGroupName]
  )

  if (isNew && !canAdd) {
    return null
  }

  return (
    <Card className={classNames('payrun-card', { 'payrun-card--new': isNew })}>
      <div className="payrun-card__header">
        <Row align="middle">
          <Col flex="200px" className="payrun-card__header-title">
            {moment(payrun.payPeriod).format('MMMM YYYY')}
          </Col>
          <Col flex="auto">
            <PayRunStatusTag status={payrun.status} />
          </Col>
          <Col>
            {payrun.id && (
              <Button type="primary" ghost onClick={handleViewClick}>
                View
              </Button>
            )}
          </Col>
        </Row>
        <Row>
          <Col flex="200px">Pay date: {moment(payrun.payDate).format('DD MMM YYYY')}</Col>
          <Col>
            {payrun.description === defaultPayRunDesc ? (
              (getTextWidth(payrun.description, payRunDescEl) || 0) > 230 ? (
                <Tooltip title={PayRunDesc}>
                  <div className="payrun-card__description">{PayRunDesc}</div>
                </Tooltip>
              ) : (
                <>{PayRunDesc}</>
              )
            ) : (
              <>
                {(getTextWidth(payrun.description, payRunDescEl) || 0) > 230 ? (
                  <Tooltip title={payrun.description}>
                    <div className="payrun-card__description">{payrun.description}</div>
                  </Tooltip>
                ) : (
                  payrun.description
                )}
              </>
            )}
          </Col>
        </Row>
        <Row>
          <SecondaryText style={secondaryTextStyle}>{companyName}</SecondaryText>
        </Row>
      </div>
      {isNew ? (
        <div className="payrun-card__overlay">
          <div className="payrun-card__overlay-flex">
            <Button type="primary" onClick={handleAddClick} loading={loading}>
              Create payroll run
            </Button>
          </div>
        </div>
      ) : summaryLoading ? (
        <Spin indicator={<LoadingOutlined spin />} />
      ) : (
        <Row>
          <Col className="payrun-card__summary">
            <Row>
              <Col flex="auto">Net pay</Col>
              <Col flex="none">$</Col>
              <Col flex="90px">{formatMoney(summary?.netPay || 0, 2)}</Col>
            </Row>
            <Row>
              <Col flex="auto">CPF employee</Col>
              <Col flex="none">$</Col>
              <Col flex="90px">{formatMoney(summary?.cpfEmployee || 0, 2)}</Col>
            </Row>
            <Row>
              <Col flex="auto">CPF employer</Col>
              <Col flex="none">$</Col>
              <Col flex="90px">{formatMoney(summary?.cpfEmployer || 0, 2)}</Col>
            </Row>
            <Row>
              <Col flex="auto">SHG fund</Col>
              <Col flex="none">$</Col>
              <Col flex="90px">{formatMoney(summary?.fund || 0, 2)}</Col>
            </Row>
            <Row>
              <Col flex="auto">FWL</Col>
              <Col flex="none">$</Col>
              <Col flex="90px">{formatMoney(summary?.fwl || 0, 2)}</Col>
            </Row>
            <Row>
              <Col flex="auto">SDL</Col>
              <Col flex="none">$</Col>
              <Col flex="90px">{formatMoney(summary?.sdl || 0, 2)}</Col>
            </Row>
          </Col>
          <Col flex="auto"></Col>
          <Col className="payrun-card__count">
            <Row>
              <Col flex="auto">
                <b>Employee</b>
              </Col>
              <Col flex="20px">{summary?.employeeCount || 0}</Col>
            </Row>
            <Row>
              <Col flex="auto">New joiner</Col>
              <Col flex="20px">{summary?.newJoinerCount || 0}</Col>
            </Row>
            <Row>
              <Col flex="auto">Resignee</Col>
              <Col flex="20px">{summary?.resigneeCount || 0}</Col>
            </Row>
          </Col>
        </Row>
      )}
    </Card>
  )
}
