import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { Col, DayKeyValues, DrawerForm, Row, SysOptions } from '~/components'
import { Form, Link, Alert } from '~/core-components'
import { emptyGuid, ClaMonthProrationType } from '~/constants'
import { ActionResult, Errors } from '~/types/store'
import { dispatch } from '~/stores/store'
import { ClaimTypeState, CtProrationMState } from '../../../types'
import { fetchCtProrationM, updateCtProrationM } from '../../../actions'
import { selectCtProrationM } from '../../../selectors'
import './MutateCtProrationMDrawer.less'

interface MutateCtProrationMDrawerProps {
  visible: boolean
  claimType?: ClaimTypeState
  onClose: () => void
}

export const MutateCtProrationMDrawer: FC<MutateCtProrationMDrawerProps> = ({ visible, claimType, onClose }) => {
  const claimTypeId = claimType?.id || ''
  const ctProrationM = useSelector(selectCtProrationM)(claimTypeId)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<Errors>()
  const [formDataNewHire, setFormDataNewHire] = useState<CtProrationMState[]>([])
  const [formDataResignee, setFormDataResignee] = useState<CtProrationMState[]>([])
  const [formDataTransfer, setFormDataTransfer] = useState<CtProrationMState[]>([])

  const NEW_PRORATION_HIRE = useMemo(
    (): CtProrationMState[] => [
      {
        id: emptyGuid,
        claimTypeId: claimTypeId || emptyGuid,
        type: ClaMonthProrationType.start,
        dayFrom: 1,
        value: 0
      }
    ],
    [claimTypeId]
  )

  const NEW_PRORATION_RESIGNEE = useMemo(
    (): CtProrationMState[] => [
      {
        id: emptyGuid,
        claimTypeId: claimTypeId || emptyGuid,
        type: ClaMonthProrationType.end,
        dayFrom: 1,
        value: 0
      }
    ],
    [claimTypeId]
  )

  const NEW_PRORATION_TRANSFER = useMemo(
    (): CtProrationMState[] => [
      {
        id: emptyGuid,
        claimTypeId: claimTypeId || emptyGuid,
        type: ClaMonthProrationType.mid,
        dayFrom: 1,
        value: 0
      }
    ],
    [claimTypeId]
  )

  useEffect(() => {
    if (claimTypeId) dispatch(fetchCtProrationM(claimTypeId))
  }, [claimTypeId])

  useEffect(() => {
    setErrors(undefined)
  }, [visible])

  useDeepCompareEffect(() => {
    if (ctProrationM) {
      setFormDataNewHire(ctProrationM.filter(x => x.type === ClaMonthProrationType.start))
      setFormDataResignee(ctProrationM.filter(x => x.type === ClaMonthProrationType.end))
      setFormDataTransfer(ctProrationM.filter(x => x.type === ClaMonthProrationType.mid))
    }
  }, [ctProrationM, visible])

  const handleFormDataNewHireChange = useCallback((formData: CtProrationMState[]) => {
    setFormDataNewHire(formData)
  }, [])

  const handleFormDataResigneeChange = useCallback((formData: CtProrationMState[]) => {
    setFormDataResignee(formData)
  }, [])

  const handleFormDataTransferChange = useCallback((formData: CtProrationMState[]) => {
    setFormDataTransfer(formData)
  }, [])

  const handleOk = useCallback(async () => {
    let formData: CtProrationMState[] = [...formDataNewHire, ...formDataResignee, ...formDataTransfer]
    let result: ActionResult | undefined
    setLoading(true)
    try {
      if (formData) {
        result = await dispatch(updateCtProrationM(claimTypeId, formData))
      }
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
    }
  }, [claimTypeId, formDataNewHire, formDataResignee, formDataTransfer, onClose])

  return (
    <DrawerForm
      open={visible}
      title="Proration by month"
      onClose={onClose}
      confirmLoading={loading}
      width={650}
      className="mutate-ct-proration-drawer"
      formId="form-ct-proration-m"
    >
      <Form id="form-ct-proration-m" onFinish={handleOk}>
        <Row>
          <Col span={24}>
            <Form.Item label="Claim type">
              <label>{claimType?.name}</label>
            </Form.Item>
          </Col>
        </Row>
        {errors && (
          <Row>
            <Col span={24}>
              <Alert
                type="error"
                message={
                  <>
                    {Object.values(errors).map((error, index) => (
                      <div key={index}>{error}</div>
                    ))}
                  </>
                }
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col span={24}>
            <div className="section__title">New Hire</div>
            {formDataNewHire.map((dat, index) => (
              <Row gutter={30} key={index} className="section-row">
                <Col flex="145px">
                  {index === 0 && <div className="section-row__title">Hire date</div>}
                  <DayKeyValues
                    allowClear={false}
                    value={dat?.dayFrom}
                    onChange={(value: number) =>
                      handleFormDataNewHireChange([
                        ...formDataNewHire.slice(0, index),
                        { ...formDataNewHire[index], dayFrom: value },
                        ...formDataNewHire.slice(index + 1, formDataNewHire.length)
                      ])
                    }
                  />
                </Col>
                <Col flex="150px">
                  {index === 0 && <div className="section-row__title">Entitlement granted</div>}
                  <SysOptions
                    type="cla_prorated_to"
                    allowClear={false}
                    value={`${dat?.value}`}
                    onChange={(value?: any) =>
                      handleFormDataNewHireChange([
                        ...formDataNewHire.slice(0, index),
                        { ...formDataNewHire[index], value: value },
                        ...formDataNewHire.slice(index + 1, formDataNewHire.length)
                      ])
                    }
                    style={{ width: 250 }}
                  />
                </Col>
                <Col>
                  <Link
                    onClick={() =>
                      handleFormDataNewHireChange([
                        ...formDataNewHire.slice(0, index),
                        ...formDataNewHire.slice(index + 1, formDataNewHire.length)
                      ])
                    }
                  >
                    remove
                  </Link>
                </Col>
              </Row>
            ))}
            <Form.Item>
              <Link onClick={() => handleFormDataNewHireChange([...formDataNewHire, ...NEW_PRORATION_HIRE])}>
                add more
              </Link>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className="section__title">Resignee</div>
            {formDataResignee.map((dat, index) => (
              <Row gutter={30} key={index} className="section-row">
                <Col flex="145px">
                  {index === 0 && <div className="section-row__title">Last day of service</div>}
                  <DayKeyValues
                    allowClear={false}
                    value={dat?.dayFrom}
                    onChange={(value: number) =>
                      handleFormDataResigneeChange([
                        ...formDataResignee.slice(0, index),
                        { ...formDataResignee[index], dayFrom: value },
                        ...formDataResignee.slice(index + 1, formDataResignee.length)
                      ])
                    }
                  />
                </Col>
                <Col flex="150px">
                  {index === 0 && <div className="section-row__title">Entitlement granted</div>}
                  <SysOptions
                    type="cla_prorated_to"
                    allowClear={false}
                    value={`${dat?.value}`}
                    onChange={(value?: any) =>
                      handleFormDataResigneeChange([
                        ...formDataResignee.slice(0, index),
                        { ...formDataResignee[index], value: value },
                        ...formDataResignee.slice(index + 1, formDataResignee.length)
                      ])
                    }
                    style={{ width: 250 }}
                  />
                </Col>
                <Col>
                  <Link
                    onClick={() =>
                      handleFormDataResigneeChange([
                        ...formDataResignee.slice(0, index),
                        ...formDataResignee.slice(index + 1, formDataResignee.length)
                      ])
                    }
                  >
                    remove
                  </Link>
                </Col>
              </Row>
            ))}
            <Form.Item>
              <Link onClick={() => handleFormDataResigneeChange([...formDataResignee, ...NEW_PRORATION_RESIGNEE])}>
                add more
              </Link>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className="section__title">Transfer</div>
            {formDataTransfer.map((dat, index) => (
              <Row gutter={30} key={index} className="section-row">
                <Col flex="145px">
                  {index === 0 && <div className="section-row__title">Transfer date</div>}
                  <DayKeyValues
                    allowClear={false}
                    value={dat?.dayFrom}
                    onChange={(value: number) =>
                      handleFormDataTransferChange([
                        ...formDataTransfer.slice(0, index),
                        { ...formDataTransfer[index], dayFrom: value },
                        ...formDataTransfer.slice(index + 1, formDataTransfer.length)
                      ])
                    }
                  />
                </Col>
                <Col flex="150px">
                  {index === 0 && <div className="section-row__title">Entitlement based on</div>}
                  <SysOptions
                    type="cla_prorated_transfer"
                    allowClear={false}
                    value={`${dat?.value}`}
                    onChange={(value?: any) =>
                      handleFormDataTransferChange([
                        ...formDataTransfer.slice(0, index),
                        { ...formDataTransfer[index], value: value },
                        ...formDataTransfer.slice(index + 1, formDataTransfer.length)
                      ])
                    }
                    style={{ width: 250 }}
                  />
                </Col>
                <Col>
                  <Link
                    onClick={() =>
                      handleFormDataTransferChange([
                        ...formDataTransfer.slice(0, index),
                        ...formDataTransfer.slice(index + 1, formDataTransfer.length)
                      ])
                    }
                  >
                    remove
                  </Link>
                </Col>
              </Row>
            ))}
            <Form.Item>
              <Link onClick={() => handleFormDataTransferChange([...formDataTransfer, ...NEW_PRORATION_TRANSFER])}>
                add more
              </Link>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Alert
              message={
                <>
                  When the setting is not configured:
                  <ul>
                    <li>Full month entitlement will be granted to employee who joins between 1st – 15th</li>
                    <li>
                      Full month entitlement will be granted to employee who resigns between between 15th – last day of
                      the month
                    </li>
                    <li>New entitlement will be given to employee who transfer between 1st - 15th</li>
                  </ul>
                </>
              }
              type="info"
              showIcon
            />
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}
