import React, { CSSProperties, FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { PageHeader, Text } from '~/core-components'
import { MenuLinks, DocumentTitle, Row, Col } from '~/components'
import {
  commonMenus,
  payrollMenus,
  leaveMenus,
  iamMenus,
  appConfigsMenus,
  claimMenus,
  attendanceMenus,
  otherMenus,
  integrationMenus
} from '../configs'
import { PermissionGate, selectMyTenants } from '~/features/iam'
import { FeatureFlag } from '~/constants'
import { MenuItem } from '~/types/common'
import { SETTINGS_ROUTES } from '~/routes/routes'
import './Settings.less'

const nameStyle: CSSProperties = { display: 'block' }
const descriptionStyle: CSSProperties = { display: 'block', fontSize: 12 }

const activeTenantCode = localStorage.getItem('tenant')

interface Setting {
  name: string
  description: string
  menus: MenuItem[]
  permissions: string[]
  gridStyles?: CSSProperties
}

const payrollSetting: Setting = {
  name: 'Payroll references',
  description: 'Payroll items, groups and other related information.',
  menus: payrollMenus,
  permissions: payrollMenus.map(m => m.sysPermissionId as string)
}

const leaveSetting: Setting = {
  name: 'Leave references',
  description: 'Leave groups and other related information.',
  menus: leaveMenus,
  permissions: leaveMenus.map(m => m.sysPermissionId as string)
}

const claimSetting: Setting = {
  name: 'Claim references',
  description: 'Claim types and other related information.',
  menus: claimMenus,
  permissions: claimMenus.map(m => m.sysPermissionId as string)
}

const attendanceSetting: Setting = {
  name: 'Attendance references',
  description: 'Locations and other related information.',
  menus: attendanceMenus,
  permissions: attendanceMenus.map(m => m.sysPermissionId as string)
}

const integrationSetting: Setting = {
  name: 'Integration',
  description: 'Integration with our partners',
  menus: integrationMenus,
  permissions: integrationMenus.map(m => m.sysPermissionId as string)
}

const otherSetting: Setting = {
  name: 'Other references',
  description: 'Email groups and other related information.',
  menus: otherMenus,
  permissions: otherMenus.map(m => m.sysPermissionId as string)
}

const userSetting: Setting = {
  name: 'User access management',
  description: 'Users, permissions and access control.',
  menus: iamMenus,
  permissions: iamMenus.map(m => m.sysPermissionId as string)
}

const appSetting: Setting = {
  name: 'Application configurations',
  description: 'Security, password policy and app-related configs.',
  menus: appConfigsMenus,
  permissions: appConfigsMenus.map(m => m.sysPermissionId as string)
}

export const Settings: FC = () => {
  const myTenants = useSelector(selectMyTenants)
  const activeTenant = useMemo(() => myTenants.find(t => t.tenantCode === activeTenantCode), [myTenants])
  const hasPayroll = activeTenant?.featureFlags?.includes(FeatureFlag.Payroll)
  const hasLeave = activeTenant?.featureFlags?.includes(FeatureFlag.Leave)
  const hasClaim = activeTenant?.featureFlags?.includes(FeatureFlag.Claim)
  const hasAttendance = activeTenant?.featureFlags?.includes(FeatureFlag.Attendance)

  const peopleSetting: Setting = useMemo(
    () => ({
      name: 'People references',
      description: 'Offices, holidays and other related information.',
      menus: commonMenus.filter(m => (m.path === SETTINGS_ROUTES.workCalendars ? !hasAttendance : true)),
      permissions: commonMenus.map(m => m.sysPermissionId as string)
    }),
    [hasAttendance]
  )

  return (
    <div className="settings">
      <DocumentTitle title="Settings" />
      <PageHeader title="Settings" />
      <div className="settings__content">
        <Row>
          <Col>
            <SettingSection setting={peopleSetting} />
          </Col>
          <Col>
            {hasPayroll && <SettingSection setting={payrollSetting} />}
            {hasClaim && <SettingSection setting={claimSetting} />}
            {hasLeave && <SettingSection setting={leaveSetting} />}
          </Col>
          <Col>{hasAttendance && <SettingSection setting={attendanceSetting} />}</Col>
          <Col>
            <SettingSection setting={integrationSetting} />
            <SettingSection setting={otherSetting} />
            <SettingSection setting={userSetting} />
            <SettingSection setting={appSetting} />
          </Col>
        </Row>
      </div>
    </div>
  )
}

interface SettingSectionProps {
  setting: Setting
}

const SettingSection: FC<SettingSectionProps> = ({ setting }) => (
  <PermissionGate key={setting.name} sysPermissionId={setting.permissions}>
    <div className="settings__section" style={setting.gridStyles}>
      <Text style={nameStyle}>{setting.name}</Text>
      <Text type="secondary" style={descriptionStyle}>
        {setting.description}
      </Text>
      <MenuLinks className="settings__menu" menus={setting.menus} />
    </div>
  </PermissionGate>
)
