import React, { FC } from 'react'
import { default as AntBadge, BadgeProps as AntBadgeProps } from 'antd/lib/badge'
import classNames from 'classnames'
import 'antd/es/badge/style'
import './Badge.less'

export type BadgeType = 'original' | 'neutral' | 'success' | 'primary' | 'secondary' | 'secondary-filled' | 'tertiary'

export interface BadgeProps extends AntBadgeProps {
  type?: BadgeType
}

export const Badge: FC<BadgeProps> = ({ type = 'neutral', className, ...props }) => (
  <AntBadge className={classNames(className, `badge--${type}`)} {...props} />
)
