import React, { CSSProperties, FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Alert, ColumnsType, Spin, Table } from '~/core-components'
import { LoadingOutlined } from '@ant-design/icons'
import { useSysDailyFieldsDict } from '~/features/attendance'
import { StoreState } from '~/types/store'
import { snakeToCamel } from '~/utils'
import { DailyImportSourceState, PayRunState } from '../../../../types'
import { usePayDailyMaps } from '../../../../hooks'
import './ImportDailyRecord.less'

interface ImportDailyRecordProps {
  visible: boolean
  success?: boolean
  payRun?: PayRunState
}

const spinStyles: CSSProperties = { marginRight: 10 }

export const ImportDailyRecord: FC<ImportDailyRecordProps> = ({ visible, success, payRun }) => {
  const data = useSelector((state: StoreState) => state.payroll.dailyImportValidate?.data || [])
  const processing = useSelector((state: StoreState) => state.payroll.payrollLoading)
  const [payDailyMaps] = usePayDailyMaps(payRun?.payGroupId)
  const [sysDailyFields] = useSysDailyFieldsDict()

  const columns: ColumnsType<DailyImportSourceState> = useMemo(() => {
    const columns: ColumnsType<DailyImportSourceState> = [
      {
        title: 'Employee',
        key: 'employeeNo',
        dataIndex: 'employeeNo',
        render: (value: string, record) => `[${value}] ${record.employeeName}`
      }
    ]

    if (payDailyMaps.length > 0) {
      for (const map of payDailyMaps) {
        columns.push({
          title: sysDailyFields[map.dailyField]?.name,
          key: map.dailyField,
          dataIndex: snakeToCamel(map.dailyField),
          align: 'right'
        })
      }
    }

    return columns
  }, [payDailyMaps, sysDailyFields])

  return (
    <div className="import-daily-record" hidden={!visible}>
      {success && (
        <Alert
          type="success"
          showIcon
          message={
            <>
              <div>Import successful</div>
              {processing ? (
                <div>
                  <Spin style={spinStyles} size="small" indicator={<LoadingOutlined spin />} />
                  <span>You may close this while we're calculating the affected employees' payroll</span>
                </div>
              ) : (
                <div>All the affected employees' payroll has been calculated</div>
              )}
            </>
          }
        />
      )}
      <Table rowKey="id" dataSource={data} columns={columns} pagination={false} />
    </div>
  )
}
