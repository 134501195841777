import React, { FC, useState, useCallback, useEffect, HTMLAttributes, CSSProperties } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import confirm from 'antd/lib/modal/confirm'
import { Alert, Form, Input, Radio, RadioChangeEvent } from '~/core-components'
import { Col, DrawerForm, AttKeyValues, NewTabLinkIcon, Row } from '~/components'
import { selectKeyvaluesById } from '~/features/master'
import { selectEmployeeById } from '~/features/employee/reducers'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { useFocus } from '~/hooks/use-focus'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors, StoreState } from '~/types/store'
import { addEmShiftRole, deleteEmShiftRole, updateEmShiftRole } from '../../../actions'
import { IEmShiftRoleInfo, EmShiftRoleState } from '../../../types'
import { mapEmShiftRoleStateToEmShiftRoleInfo } from '../../../types/em-details.mapper'
import { SETTINGS_ROUTES } from '~/routes/routes'

export interface MutateEmShiftRoleDrawerProps {
  visible: boolean
  employeeId?: string
  data?: EmShiftRoleState
  isCurrent: boolean
  onClose: () => void
}

const TODAY = moment().format('YYYY-MM-DD')
const EMPTY_FORM_DATA: IEmShiftRoleInfo = {
  startDate: TODAY,
  shiftRoleId: ''
}

const radioStyle: CSSProperties = { width: '100%' }
const radioItemStyle: CSSProperties = { marginTop: 20 }
const formStyle: CSSProperties = { marginLeft: 24, marginTop: 10 }
const alertStyle: CSSProperties = { marginBottom: 15 }

export const MutateEmShiftRoleDrawer: FC<MutateEmShiftRoleDrawerProps> = ({
  visible,
  employeeId,
  data,
  isCurrent,
  onClose
}: MutateEmShiftRoleDrawerProps) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<IEmShiftRoleInfo>(EMPTY_FORM_DATA)
  const [newFormData, setNewFormData] = useState<IEmShiftRoleInfo>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const [isNew, setIsNew] = useState(isCurrent ? true : false)
  const shiftRoles = useSelector(selectKeyvaluesById)('shiftRole')
  const employee = useSelector((state: StoreState) => selectEmployeeById(state, employeeId || ''))

  useEffect(() => {
    setIsNew(isCurrent ? true : false)
    setErrors(undefined)
  }, [visible, isCurrent])

  useEffect(() => {
    if (visible && data) setFormData(mapEmShiftRoleStateToEmShiftRoleInfo(data))
  }, [visible, data])

  const handleRadioChange = useCallback((event: RadioChangeEvent) => {
    setIsNew(event.target.value)
  }, [])

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      if (isNew || data == null) {
        if (employeeId) {
          const submit = isNew ? newFormData : formData
          result = await dispatch(addEmShiftRole(employeeId, submit))
        }
      } else {
        if (data) {
          result = await dispatch(
            updateEmShiftRole(data.employeeId, data.id, mapEmShiftRoleStateToEmShiftRoleInfo(data), formData)
          )
        }
      }
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_FORM_DATA)
    }
  }, [isNew, employeeId, data, newFormData, formData, onClose])

  const handleDelete = useCallback(
    (emShiftRole: EmShiftRoleState | undefined) => {
      if (employeeId && emShiftRole) {
        const { id, shiftRoleId } = emShiftRole
        const shiftRole = shiftRoles[shiftRoleId]?.value

        confirm({
          title: 'Delete shift role',
          content: `Do you want to delete shift role "${shiftRole}"?`,
          onOk: async () => {
            const result: ActionResult | undefined = await dispatch(deleteEmShiftRole(employeeId, id))
            if (result?.errors) {
              setErrors(result.errors)
            }

            if (!result?.errors) {
              typeof onClose === 'function' && onClose()
            }
          },
          okText: 'Delete',
          okType: 'danger'
        })
      }
    },
    [employeeId, shiftRoles, onClose]
  )

  return (
    <DrawerForm
      open={visible}
      title="Shift role"
      onClose={onClose}
      confirmLoading={loading}
      width={500}
      showDelete={isNew || data == null ? false : true}
      onDelete={() => handleDelete(data)}
      formId="form-em-shift-role"
    >
      <Form id="form-em-shift-role" onFinish={handleOk}>
        {isCurrent ? (
          <Radio.Group value={isNew} onChange={handleRadioChange} style={radioStyle}>
            <Radio value={true}>Transfer to new shift role</Radio>
            <EmShiftRoleForm
              errors={isNew ? errors : undefined}
              labelPrefix="New"
              style={formStyle}
              disabled={!isNew}
              onFormChange={data => setNewFormData({ ...newFormData, ...data })}
            />
            <Radio value={false} style={radioItemStyle}>
              Or make amendment to current shift role
            </Radio>
            <EmShiftRoleForm
              data={data}
              errors={!isNew ? errors : undefined}
              labelPrefix="Current"
              style={formStyle}
              disabled={isNew}
              onFormChange={data => setFormData({ ...formData, ...data })}
            />
          </Radio.Group>
        ) : (
          <>
            {data != null && (
              <Alert
                message="Any changes to this historical record will not impact past transactions."
                banner
                style={alertStyle}
              />
            )}
            <EmShiftRoleForm
              data={data}
              hireDate={employee?.hireDate}
              errors={errors}
              onFormChange={data => setFormData({ ...formData, ...data })}
            />
          </>
        )}
      </Form>
    </DrawerForm>
  )
}

interface EmShiftRoleFormProps extends HTMLAttributes<HTMLDivElement> {
  data?: EmShiftRoleState
  hireDate?: string
  errors?: Errors
  labelPrefix?: string
  disabled?: boolean
  onFormChange: (data: IEmShiftRoleInfo) => void
}

const EmShiftRoleForm: FC<EmShiftRoleFormProps> = ({
  data,
  hireDate,
  errors,
  labelPrefix,
  onFormChange,
  style,
  disabled = false
}) => {
  const canModify = usePermissionGate(Permission.shiftRole, PermissionAction.Modify)
  const [formData, setFormData] = useState<IEmShiftRoleInfo>(EMPTY_FORM_DATA)
  const [focusRef, setFocus] = useFocus(true)
  const effectiveDateLabel = 'Effective date'
  const shiftRoleLabel = 'Shift role'

  useEffect(() => {
    setTimeout(() => !disabled && setFocus(), 100)
  }, [disabled, setFocus])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      const updated = { ...formData, ...updates }
      setFormData(updated)
      typeof onFormChange === 'function' && onFormChange(updated)
    },
    [formData, onFormChange]
  )

  useEffect(() => {
    if (data) {
      const { startDate, shiftRoleId } = data
      setFormData({ startDate, shiftRoleId })
    } else {
      setFormData({ ...EMPTY_FORM_DATA, startDate: hireDate || TODAY })
    }
  }, [data, hireDate])

  const getLabel = useCallback(
    (prefix: string | undefined, label: string) => (prefix ? `${prefix} ${label.toLowerCase()}` : label),
    []
  )

  return (
    <div style={style}>
      <Row>
        <Col span={24}>
          <Form.Item
            label={getLabel(labelPrefix, effectiveDateLabel)}
            validateStatus={errors?.startDate ? 'error' : ''}
            help={errors?.startDate}
          >
            <Input.Date
              ref={focusRef}
              value={formData.startDate ? moment(formData.startDate) : undefined}
              onChange={(value: moment.Moment | null) =>
                handleFormDataChange({ startDate: value?.format('YYYY-MM-DD') })
              }
              disabled={disabled}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            label={getLabel(labelPrefix, shiftRoleLabel)}
            validateStatus={errors?.shiftRoleId ? 'error' : ''}
            help={errors?.shiftRoleId}
          >
            <NewTabLinkIcon
              path={SETTINGS_ROUTES.shiftRoles}
              tooltipText="Open shift role in a new tab"
              tooltipPlacement="topRight"
              hidden={!canModify}
            >
              <AttKeyValues
                id="shiftRole"
                value={formData.shiftRoleId}
                onChange={(value: string) => handleFormDataChange({ shiftRoleId: value })}
                disabled={disabled}
              />
            </NewTabLinkIcon>
          </Form.Item>
        </Col>
      </Row>
    </div>
  )
}
