import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { TimeLogApprovalHistoryState } from '../types'

export const selectTimeLogApprovalHistories = createSelector(
  (state: StoreState) => state.attendance.timeLogApprovalHistories,
  timeLogApprovalHistoriesState =>
    memoize((timeLogId: string) => {
      return Object.values(timeLogApprovalHistoriesState[timeLogId]?.entities || {}) as TimeLogApprovalHistoryState[]
    })
)
