import { request, getBaseUrl } from '~/utils/request'
import { PayLeaveMapState } from '../types'

const baseUrl = getBaseUrl('/payroll-sg')

export const apiGetPayLeaveMaps = (payGroupId: string) =>
  request<PayLeaveMapState[]>('get', `${baseUrl}/paygroup/${payGroupId}/payleavemap`)

export const apiUpdatePayLeaveMaps = (payGroupId: string, data: PayLeaveMapState[]) =>
  request<PayLeaveMapState[]>('post', `${baseUrl}/paygroup/${payGroupId}/payleavemap`, data)
