import React, { FC, useCallback, useEffect, useState } from 'react'
import confirm from 'antd/lib/modal/confirm'
import { Col, DrawerForm, EmpKeyValues, Row, SysOptions } from '~/components'
import { Form, Input } from '~/core-components'
import { LpEntitlement } from './LpEntitlement'
import { LpEntPerTime } from './LpEntPerTime'
import { ActionResult, Errors } from '~/types/store'
import { emptyGuid, LveSysLeaveType } from '~/constants'
import { dispatch } from '~/stores/store'
import { LtPolicyExt } from '../../../selectors'
import {
  ILtPolicyMutate,
  LeaveTypeState,
  LpCfInfoState,
  LpEntitlementState,
  LpEntPerTimeState,
  LtPolicyState
} from '../../../types'
import { addLtPolicy, deleteLtPolicy, updateLtPolicy } from '../../../actions'
import { LpCf } from './LpCf'
import './MutateLtPolicyDrawer.less'

interface MutateLtPolicyDrawerProps {
  visible: boolean
  leaveType?: LeaveTypeState
  data?: LtPolicyExt
  onClose: () => void
}

type FormData = {
  leaveGroupIds: string[]
  entitlements: LpEntitlementState[]
  entPerTime: LpEntPerTimeState[]
  citizen: string
  requireConfirm: string
  requireMonths: number
  forfeitAbsent: boolean
  forfeitQuantity: number
  forfeitPercentage: number
  forfeitLeaveTypeIds: string[]
} & LpCfInfoState

export const CF_EMPTY_FORM_DATA: LpCfInfoState = {
  cfCode: '',
  cfValidityPeriod: 0,
  excessAction: '',
  excessValidityPeriod: 0
}

const EMPTY_FORM_DATA: FormData = {
  leaveGroupIds: [],
  entitlements: [],
  entPerTime: [],
  citizen: '*',
  requireConfirm: 'n',
  requireMonths: 0,
  forfeitAbsent: false,
  forfeitQuantity: 0,
  forfeitPercentage: 0,
  forfeitLeaveTypeIds: [],
  ...CF_EMPTY_FORM_DATA
}

const mapFormDataToLtPolicyMutate = (ori: LtPolicyExt, formData: FormData): ILtPolicyMutate => ({
  id: ori.id || emptyGuid,
  leaveTypeId: ori.leaveTypeId || emptyGuid,
  requireConfirm: formData.requireConfirm,
  requireMonths: formData.requireMonths || 0,
  citizen: formData.citizen,
  minPerTime: ori.minPerTime || 0,
  maxPerTime: ori.maxPerTime || 0,
  maxPerEmployment: ori.maxPerEmployment || 0,
  entitlements: formData.entitlements,
  entPerTime: formData.entPerTime,
  leaveGroups: formData.leaveGroupIds.map(leaveGroupId => ({ id: emptyGuid, ltPolicyId: ori.id, leaveGroupId })),
  forfeitAbsents: formData.forfeitLeaveTypeIds.map(leaveTypeId => ({
    id: emptyGuid,
    ltPolicyId: ori.id,
    leaveTypeId
  })),
  forfeitAbsent: formData.forfeitAbsent,
  forfeitQuantity: formData.forfeitQuantity,
  forfeitPercentage: formData.forfeitPercentage,
  cfCode: formData.cfCode,
  cfValidityPeriod: formData.cfValidityPeriod,
  excessAction: formData.excessAction,
  excessValidityPeriod: formData.excessValidityPeriod
})

export const MutateLtPolicyDrawer: FC<MutateLtPolicyDrawerProps> = ({ visible, leaveType, data, onClose }) => {
  const [loading, setLoading] = useState(false)
  const [isNew, setIsNew] = useState(false)
  const [errors, setErrors] = useState<Errors>()
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)
  const leaveTypeId = leaveType?.id || ''

  useEffect(() => {
    setIsNew(false)
    setErrors(undefined)
  }, [visible])

  useEffect(() => {
    if (data) {
      setFormData(mapLtPolicyStateToFormData(data))
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setErrors(undefined)
    setFormData(data => ({ ...data, ...updates }))
  }, [])

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      if (isNew || data == null) {
        if (leaveTypeId) {
          const submit = mapFormDataToLtPolicyMutate({ leaveTypeId } as LtPolicyExt, formData)
          result = await dispatch(addLtPolicy(leaveTypeId, submit))
        }
      } else {
        if (data) {
          const submit = mapFormDataToLtPolicyMutate(data, formData)
          result = await dispatch(updateLtPolicy(leaveTypeId, data.id, submit))
        }
      }
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_FORM_DATA)
    }
  }, [isNew, leaveTypeId, data, formData, onClose])

  const handleDelete = useCallback(
    (ltPolicy: LtPolicyState | undefined) => {
      if (ltPolicy) {
        const { id, leaveTypeId } = ltPolicy
        confirm({
          title: 'Delete leave policy',
          content: `Do you want to delete leave policy?`,
          onOk: async () => {
            const result: ActionResult | undefined = await dispatch(deleteLtPolicy(leaveTypeId, id))
            if (result?.errors) {
              setErrors(result.errors)
            }
            if (!result?.errors) {
              typeof onClose === 'function' && onClose()
            }
          },
          okText: 'Delete',
          okType: 'danger'
        })
      }
    },
    [onClose]
  )

  return (
    <DrawerForm
      open={visible}
      title="Leave policy"
      onClose={onClose}
      confirmLoading={loading}
      width={650}
      showDelete={isNew || data == null ? false : true}
      onDelete={() => handleDelete(data)}
      className="mutate-lt-policy-drawer"
      formId="form-lt-policy"
    >
      <Form id="form-lt-policy" onFinish={handleOk}>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Leave groups"
              validateStatus={errors?.leaveGroups ? 'error' : ''}
              help={errors?.leaveGroups}
            >
              <EmpKeyValues
                id="leaveGroup"
                mode="multiple"
                placeholder="All employees"
                value={formData.leaveGroupIds}
                onChange={(value: string[]) => handleFormDataChange({ leaveGroupIds: value })}
              />
            </Form.Item>
          </Col>
        </Row>
        {leaveType?.entByCitizen && (
          <Row>
            <Col span={12}>
              <Form.Item label="Citizenship" validateStatus={errors?.citizen ? 'error' : ''} help={errors?.citizen}>
                <SysOptions
                  type="lve_citizenship"
                  allowAll={true}
                  value={formData.citizen}
                  onChange={(value: string) => handleFormDataChange({ citizen: value })}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row gutter={30}>
          <Col span={17}>
            <Form.Item
              label="Confirmation status"
              validateStatus={errors?.requireConfirm ? 'error' : ''}
              help={errors?.requireConfirm}
            >
              <SysOptions
                type="lve_require_confirm"
                value={formData.requireConfirm}
                onChange={(value: string) => handleFormDataChange({ requireConfirm: value })}
                dropdownMultiline={true}
              />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              label="Min. service months"
              validateStatus={errors?.requireMonths ? 'error' : ''}
              help={errors?.requireMonths}
              tooltip={{
                title: 'Leave the value as 0 if there is no min. service months required',
                icon: (
                  <span>
                    <i className="fal fa-circle-info" />
                  </span>
                )
              }}
            >
              <Input.Number
                min={0}
                value={formData.requireMonths}
                onChange={(value: number | null) => handleFormDataChange({ requireMonths: value })}
              />
            </Form.Item>
          </Col>
        </Row>
        <LpEntPerTime
          leaveType={leaveType}
          ltPolicyId={data?.id}
          entPerTime={formData.entPerTime}
          errors={errors}
          onChange={(entPerTimeData: LpEntPerTimeState[]) => handleFormDataChange({ entPerTime: entPerTimeData })}
        />
        <LpEntitlement
          leaveType={leaveType}
          ltPolicyId={data?.id}
          entitlements={formData.entitlements}
          errors={errors}
          onChange={(entData: LpEntitlementState[]) => handleFormDataChange({ entitlements: entData })}
        />
        {/* {leaveType?.sysLeaveTypeCode === LveSysLeaveType.anl && (
          <>
            <Row>
              <Col span={24}>
                <div className="entitlement__title">Forfeiture absent</div>
              </Col>
            </Row>
            {![LveProrationType.day, LveProrationType.month].includes(leaveType?.prorationType) && (
              <Alert
                type="error"
                message="This setting is only applicable when the proration type is set to either by days or by months"
              />
            )}
            {[LveProrationType.day, LveProrationType.month].includes(leaveType?.prorationType) && (
              <>
                <Row>
                  <Col span={24}>
                    <Form.Item>
                      <label>Forfeit entitlement when employee is absent</label>
                      <Switch
                        checked={formData.forfeitAbsent}
                        onChange={(checked: boolean) => {
                          let forfeitQuantity = checked ? formData.forfeitQuantity : 0
                          let forfeitPercentage = checked ? formData.forfeitPercentage : 0
                          let forfeitLeaveTypeIds = checked ? formData.forfeitLeaveTypeIds : []

                          handleFormDataChange({
                            forfeitAbsent: checked,
                            forfeitQuantity,
                            forfeitPercentage,
                            forfeitLeaveTypeIds
                          })
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row hidden={!formData.forfeitAbsent} className="forfeiture__amount">
                  <Col span={24}>
                    {leaveType?.prorationType === LveProrationType.day && (
                      <Form.Item validateStatus={errors?.forfeitQuantity ? 'error' : ''} help={errors?.forfeitQuantity}>
                        Forfeit if absent is more than{' '}
                        <Input.Number
                          min={0}
                          value={formData.forfeitQuantity}
                          onChange={(value: number | null) => handleFormDataChange({ forfeitQuantity: value })}
                        />{' '}
                        days within the leave period
                      </Form.Item>
                    )}
                    {leaveType?.prorationType === LveProrationType.month && (
                      <Form.Item
                        validateStatus={errors?.forfeitPercentage ? 'error' : ''}
                        help={errors?.forfeitPercentage}
                      >
                        Forfeit if absent is more than{' '}
                        <Input.Number
                          min={0}
                          value={formData.forfeitPercentage}
                          onChange={(value: number | null) => handleFormDataChange({ forfeitPercentage: value })}
                        />{' '}
                        % in a month
                      </Form.Item>
                    )}
                  </Col>
                </Row>
                <Row hidden={!formData.forfeitAbsent}>
                  <Col span={24}>
                    <Form.Item
                      label="Absent leave types"
                      validateStatus={errors?.forfeitLeaveTypes ? 'error' : ''}
                      help={errors?.forfeitLeaveTypes}
                    >
                      <LveKeyValues
                        id="leaveType"
                        mode="multiple"
                        placeholder="No leave type(s) selected"
                        value={formData.forfeitLeaveTypeIds}
                        onChange={(value: string[]) => handleFormDataChange({ forfeitLeaveTypeIds: value })}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
          </>
        )} */}
        {leaveType?.sysLeaveTypeCode === LveSysLeaveType.anl && (
          <LpCf
            cf={mapFormDataToLpCfInfoState(formData)}
            errors={errors}
            onChange={(data: Partial<LpCfInfoState>) => handleFormDataChange(data)}
          />
        )}
      </Form>
    </DrawerForm>
  )
}

const mapLtPolicyStateToFormData = (data: LtPolicyExt): FormData => ({
  leaveGroupIds: data.leaveGroups.map(lg => lg.leaveGroupId),
  entitlements: data.entitlements,
  entPerTime: data.entPerTime,
  citizen: data.citizen,
  requireConfirm: data.requireConfirm,
  requireMonths: data.requireMonths,
  forfeitAbsent: data.forfeitAbsent,
  forfeitQuantity: data.forfeitQuantity,
  forfeitPercentage: data.forfeitPercentage,
  forfeitLeaveTypeIds: data.forfeitLeaveTypes.map(lt => lt.leaveTypeId),
  cfCode: data.cfCode,
  cfValidityPeriod: data.cfValidityPeriod,
  excessAction: data.excessAction,
  excessValidityPeriod: data.excessValidityPeriod
})

const mapFormDataToLpCfInfoState = (data: FormData): LpCfInfoState => ({
  cfCode: data.cfCode,
  cfValidityPeriod: data.cfValidityPeriod,
  excessAction: data.excessAction,
  excessValidityPeriod: data.excessValidityPeriod
})
