import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Button, Drawer, Space, Steps } from '~/core-components'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors, StoreState } from '~/types/store'
import { showError } from '~/utils'
import { ClaimImportExecuteState, ClaimImportSourceState, ClaimImportState } from '../../../types'
import { prepareClaimImport, processPayroll, executeClaimImport, validateClaimImport } from '../../../actions'
import { usePayRun } from '~/features/payroll/hooks'
import { ImportClaimInput } from './components/ImportClaimInput'
import { SelectClaimRecord } from './components/SelectClaimRecord'
import { ImportClaimRecord } from './components/ImportClaimRecord'
import { ValidateClaimReport } from './components/ValidateClaimReport'
import './ImportClaimDrawer.less'

interface ImportClaimDrawerProps {
  payRunId: string
  visible: boolean
  onClose: () => void
}

export interface ImportClaimFormData {
  upToDate: string
  source: ClaimImportSourceState[]
  selected: ClaimImportSourceState[]
}

const EMPTY_FORM_DATA: ImportClaimFormData = {
  upToDate: moment().format('YYYY-MM-DD'),
  source: [],
  selected: []
}

const { Step } = Steps

type StepKey = 'input' | 'select' | 'validate' | 'verify' | 'complete'

interface IStep {
  key: StepKey
  title: string
  nextButton?: string
  backButton?: string
}

const STEPS: IStep[] = [
  {
    key: 'input',
    title: 'Specify date'
  },
  {
    key: 'select',
    title: 'Select records'
  },
  {
    key: 'validate',
    title: 'Validate'
  },
  {
    key: 'verify',
    title: 'Verify and import',
    nextButton: 'Import'
  },
  {
    key: 'complete',
    title: 'Done',
    nextButton: 'Close'
  }
]

export const ImportClaimDrawer: FC<ImportClaimDrawerProps> = ({ payRunId, visible, onClose }) => {
  const [formData, setFormData] = useState<ImportClaimFormData>(EMPTY_FORM_DATA)
  const [step, setStep] = useState(0)
  const [nextLoading, setNextLoading] = useState(false)
  const [errors, setErrors] = useState<Errors>()
  const [payRun] = usePayRun(payRunId)
  const endDate = payRun?.endDate || moment().format('YYYY-MM-DD')
  const hasDataToImport = useSelector(
    (state: StoreState) => (state.payroll.claimImportValidate?.summary?.proceed || 0) > 0
  )

  useEffect(() => {
    setStep(0)
    setErrors(undefined)
    setFormData({ ...EMPTY_FORM_DATA, upToDate: endDate })
  }, [endDate, visible])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setErrors(undefined)
    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  const prepareClaimRecords = useCallback(async () => {
    const errorMessages: string[] = []
    if (!formData.upToDate) {
      errorMessages.push('Please specify date')
    }

    if (errorMessages.length > 0) {
      setErrors({ '*': errorMessages })
      return
    }

    let result: ActionResult<ClaimImportState> | undefined
    try {
      setNextLoading(true)
      result = await dispatch(prepareClaimImport(payRunId, formData.upToDate))

      const source = result?.result?.source || []
      handleFormDataChange({ source, selected: source.filter(s => !s.isInvalid) })
    } finally {
      setNextLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      setErrors(undefined)
      setStep(step => step + 1)
    }
  }, [payRunId, formData.upToDate, handleFormDataChange])

  const filterSource = useCallback(async () => {
    const errorMessages: string[] = []
    if (formData.selected.length === 0) {
      errorMessages.push('No claim record is selected')
    }

    if (errorMessages.length > 0) {
      setErrors({ '*': errorMessages })
      return
    }

    let result: ActionResult<ClaimImportExecuteState> | undefined
    try {
      setNextLoading(true)
      const ids = formData.selected.map(s => s.id)
      result = await dispatch(validateClaimImport(payRunId, ids))
    } finally {
      setNextLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      setErrors(undefined)
      setStep(step => step + 1)
    }
  }, [payRunId, formData.selected])

  const executeImport = useCallback(async () => {
    let result: ActionResult<ClaimImportExecuteState> | undefined
    try {
      setNextLoading(true)
      result = await dispatch(executeClaimImport(payRunId))
    } finally {
      setNextLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      setErrors(undefined)
      setStep(step => step + 1)

      const employeeIds = result?.result?.employeeIdList
      if (employeeIds) {
        dispatch(processPayroll(payRunId, employeeIds))
      }
    }
  }, [payRunId])

  const handlePrev = useCallback(() => {
    setErrors(undefined)
    setStep(step => step - 1)
  }, [])

  const handleCloseDrawer = useCallback(() => {
    typeof onClose === 'function' && onClose()
  }, [onClose])

  const handleNext = useCallback(async () => {
    if (STEPS[step].key === 'input') {
      await prepareClaimRecords()
    } else if (STEPS[step].key === 'select') {
      await filterSource()
    } else if (STEPS[step].key === 'validate') {
      if (hasDataToImport) {
        setStep(step => step + 1)
      } else {
        showError('No row to import')
      }
    } else if (STEPS[step].key === 'verify') {
      await executeImport()
    } else if (STEPS[step].key === 'complete') {
      handleCloseDrawer()
    }
  }, [step, prepareClaimRecords, filterSource, executeImport, hasDataToImport, handleCloseDrawer])

  return (
    <Drawer
      open={visible}
      title="Import payroll items from Claim"
      onClose={handleCloseDrawer}
      width="80%"
      className="import-claim-drawer"
      footer={
        <Space className="import-claim-drawer__action-bar-buttons">
          {STEPS[step - 1] && step !== STEPS.length - 1 && (
            <Button onClick={handlePrev}>{STEPS[step].backButton || 'Back'}</Button>
          )}
          {STEPS[step] && (
            <Button type="primary" onClick={handleNext} loading={nextLoading}>
              {STEPS[step].nextButton || 'Next'}
            </Button>
          )}
        </Space>
      }
    >
      <Steps progressDot size="small" current={step}>
        {STEPS.map(s => (
          <Step key={s.key} title={s.title} />
        ))}
      </Steps>
      <ImportClaimInput
        visible={STEPS[step].key === 'input'}
        data={formData}
        onChange={handleFormDataChange}
        errors={errors}
      />
      <SelectClaimRecord
        visible={STEPS[step].key === 'select'}
        data={formData}
        onSelect={handleFormDataChange}
        errors={errors}
      />
      <ValidateClaimReport visible={STEPS[step].key === 'validate'} />
      <ImportClaimRecord
        visible={['verify', 'complete'].includes(STEPS[step].key)}
        success={STEPS[step].key === 'complete'}
      />
    </Drawer>
  )
}
