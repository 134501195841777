import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { CalendarViewerState, CalendarViewerCategory } from '../types'

export const selectCalendarViewer = createSelector(
  (state: StoreState) => state.calendarViewer.calendarViewers,
  calendarViewersState =>
    memoize(
      (category: CalendarViewerCategory, id: string): CalendarViewerState | undefined => {
        const calendarViewers = Object.values(calendarViewersState[category]?.entities || {}) as CalendarViewerState[]
        return calendarViewers.find(clv => clv.id === id)
      },
      (category: CalendarViewerCategory, id: string) => `${category}|${id}`
    )
)
