import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Card, ColumnsType, Link, PageHeader, Table } from '~/core-components'
import { DocumentTitle, InactiveTag } from '~/components'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { isInactive } from '~/utils/dateUtil'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS } from '~/types/common'
import { fetchEmailGroups } from '../../actions'
import { EmailGroupState } from '../../types/email-group.state'
import { MutateEmailGroupDrawer } from './components/MutateEmailGroupDrawer'
import { selectEmailGroups } from '../../selectors/select-email-groups'
import './EmailGroup.less'

type EmailGroupTable = EmailGroupState

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: '',
    breadcrumbName: 'Overview'
  }
]

interface DrawerState {
  visible: boolean
  data?: EmailGroupState
}
const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

interface EmailGroupProps {}

export const EmailGroup: FC<EmailGroupProps> = () => {
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const emailGroups = useSelector(selectEmailGroups)
  const loading = useSelector((state: StoreState) => state.master.emailGroupsLoading)
  const canModify = usePermissionGate(Permission.emailGroup, PermissionAction.Modify)

  useEffect(() => {
    dispatch(fetchEmailGroups(DEFAULT_FETCH_OPTIONS))
  }, [])

  const handleAddEmailGroup = useCallback(() => {
    setDrawerState({ visible: true, data: undefined })
  }, [])

  const handleEditEmailGroup = useCallback((emailGroup: EmailGroupState) => {
    setDrawerState({ visible: true, data: emailGroup })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const columns: ColumnsType<EmailGroupTable> = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      render: (value: string, record) => (
        <>
          {value} {isInactive(record.inactiveDate) && <InactiveTag />}
        </>
      )
    },
    {
      key: 'action',
      align: 'right',
      render: (value: string, record) =>
        canModify && (
          <Link size="small" onClick={() => handleEditEmailGroup(record)}>
            edit
          </Link>
        )
    }
  ]

  return (
    <div id="email-group" className="email-group">
      <DocumentTitle title="Email Groups" />
      <PageHeader
        title="Email groups"
        containerId="email-group"
        breadcrumb={{ routes }}
        extra={
          canModify && (
            <Button key="add" onClick={handleAddEmailGroup}>
              Add email group
            </Button>
          )
        }
      />
      <div className="email-group__body">
        <Card table>
          <Table rowKey="id" dataSource={emailGroups} pagination={false} columns={columns} loading={loading} />
        </Card>
        {canModify && <MutateEmailGroupDrawer {...drawerState} onClose={handleCloseDrawer} />}
      </div>
    </div>
  )
}
