import React, { ChangeEvent, CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { Button, Form, Input } from '~/core-components'
import { Col, EditableCard, EditableCardState, Row, SysOptions } from '~/components'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { useFocus } from '~/hooks/use-focus'
import { dispatch } from '~/stores/store'
import { Errors, ActionResult } from '~/types/store'
import { IEmployeeEmergency, EmployeeState, EmRequestState } from '../../../types'
import { mapEmployeeStateToEmployeeEmergency } from '../../../types/employee.mapper'
import { updateEmployeeEmergency } from '../../../actions'
import { useEmergencyEmRequests } from '~/features/employee/hooks'
import { EmRequestApprovalDrawer } from './EmRequestApprovalDrawer'

interface EmployeeEmergencyProps {
  employee?: EmployeeState
  onEdit?: () => void
  onSave?: () => void
  onCancel?: () => void
}

interface DrawerState {
  data?: EmRequestState
  title: string
  visible: boolean
}

const DEFAULT_DRAWER_STATE: DrawerState = { title: '', visible: false }

const EMPTY_FORM_DATA: IEmployeeEmergency = {
  emergencyName: '',
  emergencyRelation: '',
  emergencyPhone: '',
  emergencyName2: '',
  emergencyRelation2: '',
  emergencyPhone2: ''
}

const cardStyle: CSSProperties = { margin: 24 }
const labelPrimaryStyle: CSSProperties = { marginBottom: 7 }
const labelSecondaryStyle: CSSProperties = { marginTop: 20, marginBottom: 7 }
const buttonAddSecondaryStyle: CSSProperties = { marginBottom: 20 }
const btnPendingStyle: CSSProperties = {
  backgroundColor: '#ff8500',
  borderColor: '#ff8500',
  color: '#ffffff',
  marginRight: 30
}

export const EmployeeEmergency: FC<EmployeeEmergencyProps> = ({
  employee,
  onEdit,
  onSave,
  onCancel
}: EmployeeEmergencyProps) => {
  const [cardState, setCardState] = useState<EditableCardState>()
  const [formData, setFormData] = useState<IEmployeeEmergency>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const [showSecondary, setShowSecondary] = useState(false)
  const [focusRef] = useFocus(cardState === 'editing')
  const canView = usePermissionGate(Permission.employeeEmergency)
  const canModify = usePermissionGate(Permission.employeeEmergency, PermissionAction.Modify)

  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const [emRequests, emRequestLoading] = useEmergencyEmRequests(employee?.id || '')

  useEffect(() => {
    if (employee) {
      const { emergencyName, emergencyPhone, emergencyRelation, emergencyName2, emergencyPhone2, emergencyRelation2 } =
        employee
      setFormData({
        emergencyName,
        emergencyPhone,
        emergencyRelation,
        emergencyName2,
        emergencyPhone2,
        emergencyRelation2
      })
      setShowSecondary(!!emergencyName2)
    } else {
      setFormData(EMPTY_FORM_DATA)
      setShowSecondary(false)
    }
  }, [employee])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => setFormData(formData => ({ ...formData, ...updates })),
    []
  )

  const handleEdit = useCallback(() => {
    setCardState('editing')
    typeof onEdit === 'function' && onEdit()
  }, [onEdit])

  const handleSave = useCallback(async () => {
    if (employee) {
      setCardState('saving')
      setErrors(undefined)
      setShowSecondary(!formData.emergencyName2)

      typeof onSave === 'function' && onSave()

      let result: ActionResult | undefined
      try {
        result = await dispatch(
          updateEmployeeEmergency(employee.id, mapEmployeeStateToEmployeeEmergency(employee), formData)
        )
      } catch {
        setCardState('editing')
      }

      if (result?.errors) {
        setCardState('editing')
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setCardState(undefined)
      }
    }
  }, [employee, formData, onSave])

  const handleCancel = useCallback(() => {
    typeof onCancel === 'function' && onCancel()
    setCardState(undefined)
    setErrors(undefined)

    if (employee) {
      setShowSecondary(!!employee.emergencyName2)

      const { emergencyName, emergencyPhone, emergencyRelation, emergencyName2, emergencyPhone2, emergencyRelation2 } =
        employee
      setFormData({
        emergencyName,
        emergencyPhone,
        emergencyRelation,
        emergencyName2,
        emergencyPhone2,
        emergencyRelation2
      })
    }
  }, [employee, onCancel])

  const handleAddSecondaryAccount = useCallback(() => {
    setShowSecondary(true)
  }, [])

  const handleOpenRequest = useCallback((emRequest?: EmRequestState) => {
    setDrawerState({ title: 'Emergency request approval', visible: true, data: emRequest })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  if (!canView) return null

  return (
    <>
      <EditableCard
        style={cardStyle}
        bodyStyle={{ paddingBottom: employee ? 6 : 24, paddingTop: 6 }}
        state={canModify ? cardState : 'readonly'}
        formId="form-em-emergency"
        onEdit={handleEdit}
        onSave={handleSave}
        onCancel={handleCancel}
        extra={
          emRequests.length > 0 &&
          canModify &&
          cardState === undefined && (
            <Button
              style={btnPendingStyle}
              size="small"
              onClick={() => handleOpenRequest(emRequests.length > 0 ? emRequests[0] : undefined)} // for now it's only get the first row
              loading={emRequestLoading}
            >
              {emRequests.length > 1 ? emRequests.length : ''} pending requests
            </Button>
          )
        }
      >
        <Row hidden={!showSecondary}>
          <Col span={24}>
            <div style={labelPrimaryStyle}>Primary</div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Name" validateStatus={errors?.emergencyName ? 'error' : ''} help={errors?.emergencyName}>
              <Input
                value={formData.emergencyName}
                readOnly={cardState !== 'editing' && cardState !== 'saving'}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleFormDataChange({ emergencyName: event.target.value })
                }
                ref={focusRef}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col span={12}>
            <Form.Item label="Relationship">
              <SysOptions
                type="relationship"
                value={formData.emergencyRelation}
                readOnly={cardState !== 'editing' && cardState !== 'saving'}
                onChange={(value: string) => handleFormDataChange({ emergencyRelation: value })}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Contact no.">
              <Input
                value={formData.emergencyPhone}
                readOnly={cardState !== 'editing' && cardState !== 'saving'}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleFormDataChange({ emergencyPhone: event.target.value })
                }
              />
            </Form.Item>
          </Col>
        </Row>

        {!showSecondary ? (
          cardState === 'editing' && (
            <Button
              type="dashed"
              size="large"
              block
              style={buttonAddSecondaryStyle}
              onClick={handleAddSecondaryAccount}
            >
              add secondary contact
            </Button>
          )
        ) : (
          <>
            <Row>
              <Col span={24}>
                <div style={labelSecondaryStyle}>Secondary</div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item label="Name">
                  <Input
                    value={formData.emergencyName2}
                    readOnly={cardState !== 'editing' && cardState !== 'saving'}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      handleFormDataChange({ emergencyName2: event.target.value })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={30}>
              <Col span={12}>
                <Form.Item label="Relationship">
                  <SysOptions
                    type="relationship"
                    value={formData.emergencyRelation2}
                    readOnly={cardState !== 'editing' && cardState !== 'saving'}
                    onChange={(value: string) => handleFormDataChange({ emergencyRelation2: value })}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Contact no.">
                  <Input
                    value={formData.emergencyPhone2}
                    readOnly={cardState !== 'editing' && cardState !== 'saving'}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      handleFormDataChange({ emergencyPhone2: event.target.value })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </EditableCard>
      <EmRequestApprovalDrawer {...drawerState} onClose={handleCloseDrawer} />
    </>
  )
}
