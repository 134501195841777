import { replaceFormulas, setFormulaLoading } from '../reducers'
import { apiGetFormulas } from '../api/formula.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchFormulas = (options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().formula.formulaLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().formula.formula?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setFormulaLoading(true))
      const { result, status } = await apiGetFormulas()
      if (status) {
        dispatch(replaceFormulas(result))
      }
    } finally {
      dispatch(setFormulaLoading(false))
    }
  }
}
