import { showSuccess, showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { emptyGuid } from '~/constants'
import { apiAddPayRecurring } from '../api/payrecurring.api'
import { setPayRecurring } from '../reducers'
import { IPayRecurringInfo, PayRecurringState } from '../types'

export const addPayRecurring = (employeeId: string, payRecurring: IPayRecurringInfo): ThunkResult<void> => {
  return async (dispatch): Promise<ActionResult | undefined> => {
    if (!employeeId) return

    const { status, result, errors, message, errorData } = await apiAddPayRecurring({
      id: emptyGuid,
      employeeId,
      ...payRecurring
    })
    if (status) {
      const newPayRecurring = { id: result.id, employeeId, ...payRecurring } as PayRecurringState
      dispatch(setPayRecurring(newPayRecurring))

      showSuccess('Added')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
