import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiDeleteEmailGroup } from '../api/email-group.api'
import { removeEmailGroup } from '../reducers'

export const deleteEmailGroup = (id: string): ThunkResult<void> => {
  return async dispatch => {
    if (!id) return

    const { status, errors, message, errorData } = await apiDeleteEmailGroup(id)
    if (status) {
      dispatch(removeEmailGroup(id))
      showSuccess('Deleted')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
