import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { EmEducationRequestState } from '../types'

export const selectEmEducationRequests = createSelector(
  (state: StoreState) => state.employee.emEducationRequests,
  emEducationRequestsState =>
    memoize((employeeId?: string) => {
      if (!employeeId) return []

      return Object.values(emEducationRequestsState[employeeId]?.entities || {}) as EmEducationRequestState[]
    })
)
