import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { removeIsEmployee } from '~/features/iam'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { apiUpdateEmployeeBasic } from '../api/employee.api'
import { EmployeeState, IEmployeeBasic } from '../types'
import { setEmployee } from '../reducers'

export const updateEmployeeBasic = <T extends IEmployeeBasic>(id: string, before: T, request: T): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const after = { ...before, ...request } as T
    const patch = generateJsonPatch<T>(before, after)
    const { status, errors, message, errorData } = await apiUpdateEmployeeBasic(id, patch)
    const employee = getState().employee.employees.entities[id] as EmployeeState

    if (status) {
      dispatch(setEmployee({ ...employee, ...after }))
      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }

    if ('employeeNo' in before && 'employeeNo' in after && before.employeeNo !== after.employeeNo) {
      dispatch(removeIsEmployee(before.employeeNo))
    }

    if ('email' in before && 'email' in after && before.email !== after.email) {
      dispatch(removeIsEmployee(before.email))

      if (!after.email) {
        dispatch(removeIsEmployee(employee.employeeNo))
      }
    }

    return { errors }
  }
}
