import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'

export const selectSysLtFieldsByCode = createSelector(
  (state: StoreState) => state.leave.sysLtFields,
  sysLtFieldsState =>
    memoize((code: string) => {
      return sysLtFieldsState[code]?.entities || {}
    })
)
