import { removeEmIdentityRequest } from '../reducers'
import { apiApproveEmIdentityRequest } from '../api/em-identity-request.api'
import { ThunkResult } from '~/types/store'
import { showError, showSuccess } from '~/utils'
import { fetchEmIdentities } from './fetch-em-identities'
import { IEmIdentityRequestApproval } from '../types'

export const approveEmIdentityRequest = (
  employeeId: string,
  id: string,
  emIdentityRequestApproval: IEmIdentityRequestApproval
): ThunkResult<void> => {
  return async dispatch => {
    const { status, result, errors, message } = await apiApproveEmIdentityRequest(id, emIdentityRequestApproval)

    if (status) {
      dispatch(removeEmIdentityRequest({ employeeId, id }))
      dispatch(fetchEmIdentities(employeeId))
      showSuccess('Approved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message)
      }
    }
    return { result, errors }
  }
}
