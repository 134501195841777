import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiApplyClaimRecord } from '../api/claim-apply.api'
import { IClaimApply } from '../types'

export const applyClaimRecord = (claimRecord: IClaimApply, isSubmit: boolean): ThunkResult<void> => {
  return async () => {
    const { status, result, errors, message, errorData } = await apiApplyClaimRecord(claimRecord, isSubmit)

    if (status) {
      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
