import React, { CSSProperties, FC } from 'react'
import { Tag, TagType } from '~/core-components'
import { AttendancePeriodStatus } from '~/constants'

export interface AttendancePeriodStatusTagProps {
  status: string
}

const lowercase: CSSProperties = { textTransform: 'lowercase' }

export const AttendancePeriodStatusTag: FC<AttendancePeriodStatusTagProps> = ({ status }) => {
  let tagType: TagType = 'neutral',
    label = ''
  switch (status) {
    case AttendancePeriodStatus.Draft:
      tagType = 'neutral'
      label = 'draft'
      break
    case AttendancePeriodStatus.Completed:
      tagType = 'success'
      label = 'completed'
      break
  }
  return (
    <Tag type={tagType} style={lowercase}>
      {label}
    </Tag>
  )
}
