import React, { CSSProperties, FC } from 'react'
import { Tag, TagType } from '~/core-components'
import { PayRunStatus } from '~/constants'

export interface PayRunStatusTagProps {
  status: string
}

const lowercase: CSSProperties = { textTransform: 'lowercase' }

export const PayRunStatusTag: FC<PayRunStatusTagProps> = ({ status }) => {
  let tagType: TagType = 'neutral',
    label = ''
  switch (status) {
    case PayRunStatus.verification:
      tagType = 'neutral'
      label = 'draft'
      break
    case PayRunStatus.payment:
      tagType = 'secondary'
      label = 'pending for payment'
      break
    case PayRunStatus.completed:
      tagType = 'success'
      label = 'completed'
      break
  }
  return (
    <Tag type={tagType} style={lowercase}>
      {label}
    </Tag>
  )
}
