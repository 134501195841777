import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { EmCompanyState, IEmCompany } from '../types'

const baseUrl = getBaseUrl('/employee/employee')

export const apiGetEmCompanies = (employeeId: string) =>
  request<EmCompanyState[]>('get', `${baseUrl}/${employeeId}/company`)

export const apiGetEmCompany = (employeeId: string, emCompanyId: string) =>
  request<EmCompanyState>('get', `${baseUrl}/${employeeId}/company/${emCompanyId}`)

export const apiAddEmCompany = (employeeId: string, emCompany: IEmCompany) =>
  request<{ id: string; emCompanyId?: string }>('post', `${baseUrl}/${employeeId}/company`, emCompany)

export const apiUpdateEmCompany = (employeeId: string, id: string, emCompany: Operation[]) =>
  request<{ emCompanyId?: string }>('patch', `${baseUrl}/${employeeId}/company/${id}`, emCompany)

export const apiDeleteEmCompany = (employeeId: string, id: string) =>
  request<{ emCompanyId?: string }>('delete', `${baseUrl}/${employeeId}/company/${id}`)
