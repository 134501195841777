import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { PayItemState, PayLeaveMapState } from '../types'
import { PayItemCategory, PayItemType } from '~/constants'
import { isInactive } from '~/utils'

export const selectLeaveTakenPayItems = createSelector(
  (state: StoreState) => state.master.payItems,
  (state: StoreState) => state.master.payLeaveMaps,
  (payItemsState, payLeaveMapsState) =>
    memoize((payGroupId: string) => {
      if (!payGroupId) return []

      const payLeaveMaps = Object.values(payLeaveMapsState[payGroupId]?.entities || {}) as PayLeaveMapState[]
      const payItems = Object.values(payItemsState?.entities || {}) as PayItemState[]
      return payItems
        .filter(
          pi =>
            (!isInactive(pi?.inactiveDate) ||
              payLeaveMaps.filter(plm => plm.payItemId === pi?.id || plm.prevPayItemId === pi?.id).length > 0) &&
            pi?.category === PayItemCategory.leave &&
            pi?.type === PayItemType.deduction
        )
        .sort((a, b) => a?.name.localeCompare(b?.name || '') || 0)
    })
)
