import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { CfConfigState } from '../types'
import { FetchStrategy } from '~/types/common'
import { fetchCfConfigs } from '../actions'
import { Dictionary } from '@reduxjs/toolkit'

export const useCfConfigsDict = (fetchStrategy: FetchStrategy = 'always'): [Dictionary<CfConfigState>, boolean] => {
  const data = useSelector((state: StoreState) => state.claim.cfConfigs.entities)
  const loading = useSelector((state: StoreState) => state.claim.cfConfigsLoading)

  useEffect(() => {
    dispatch(fetchCfConfigs({ strategy: fetchStrategy }))
  }, [fetchStrategy])

  return [data, loading]
}
