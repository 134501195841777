import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { PayDailyMapState } from '../types'

export const selectPayDailyMaps = createSelector(
  (state: StoreState) => state.master.payDailyMaps,
  payDailyMapsState =>
    memoize((payGroupId?: string) => {
      return (Object.values(payDailyMapsState[payGroupId || '']?.entities || {}) as PayDailyMapState[]) || []
    })
)
