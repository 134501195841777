export interface TeConfigState {
  code: string
  name: string
  isShownInDaily: boolean
  isShownInShift: boolean
  isShownInShiftRole: boolean
}

export interface ITeConfig {
  name: string
  isShownInDaily: boolean
  isShownInShift: boolean
  isShownInShiftRole: boolean
}

export const mapTeConfigStateToTeConfigInfo = (teConfig: TeConfigState): ITeConfig => ({
  name: teConfig.name,
  isShownInDaily: teConfig.isShownInDaily,
  isShownInShift: teConfig.isShownInShift,
  isShownInShiftRole: teConfig.isShownInShiftRole
})
