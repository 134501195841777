import React, { CSSProperties, ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment-timezone'
import { Checkbox, CheckboxChangeEvent, Form, Input, Space, Tag } from '~/core-components'
import { Col, EditableCard, EditableCardState, MonthKeyValues, Row, SysOptions } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { dispatch } from '~/stores/store'
import { Errors, ActionResult } from '~/types/store'
import { ClaExpenseSubmissionType, ClaPeriodBasis, ClaTaxableType, Permission, PermissionAction } from '~/constants'
import { useFocus } from '~/hooks/use-focus'
import { ClaimTypeInfoState, ClaimTypeState } from '../../../types'
import { updateClaimType } from '../../../actions'
import { ClaKeyValues } from '~/components/KeyValues/ClaKeyValues'
import { IconPicker } from '~/components/IconPicker/IconPicker'

interface ClaimTypeInfoProps {
  claimType?: ClaimTypeState
  onEdit?: () => void
  onSave?: () => void
  onCancel?: () => void
}

interface FormData extends ClaimTypeInfoState {}

const EMPTY_FORM_DATA: FormData = {
  name: '',
  inactiveDate: '',
  icon: '',
  parentId: '',
  periodBasis: '',
  periodStart: 1,
  periodDuration: 1,
  taxableType: ClaTaxableType.Yes,
  expenseSubmissionType: ClaExpenseSubmissionType.Single
}

const taxableTypeStyle: CSSProperties = { width: 150 }

export const ClaimTypeInfo: FC<ClaimTypeInfoProps> = ({ claimType, onEdit, onSave, onCancel }) => {
  const [cardState, setCardState] = useState<EditableCardState>()
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const canModify = usePermissionGate(Permission.claType, PermissionAction.Modify)
  const readOnly = cardState !== 'editing' && cardState !== 'saving'
  const [focusRef] = useFocus(!readOnly)
  const [isInactive, setIsInactive] = useState(false)
  const isSingle = claimType?.expenseSubmissionType === ClaExpenseSubmissionType.Single

  useEffect(() => {
    if (claimType) {
      const {
        name,
        periodBasis,
        periodDuration,
        periodStart,
        inactiveDate,
        icon,
        parentId,
        taxableType,
        expenseSubmissionType
      } = claimType

      setFormData({
        name,
        periodBasis,
        periodDuration,
        periodStart,
        inactiveDate,
        icon,
        parentId,
        taxableType,
        expenseSubmissionType
      })
      setIsInactive(!!inactiveDate)
    } else {
      setFormData(EMPTY_FORM_DATA)
      setIsInactive(false)
    }
  }, [claimType])

  const handleEdit = useCallback(() => {
    setCardState('editing')
    typeof onEdit === 'function' && onEdit()
  }, [onEdit])

  const handleSave = useCallback(async () => {
    if (claimType) {
      setCardState('saving')
      setErrors(undefined)

      typeof onSave === 'function' && onSave()

      let result: ActionResult | undefined
      try {
        result = await dispatch(updateClaimType(claimType.id, claimType, formData))
      } catch {
        setCardState('editing')
      }

      if (result?.errors) {
        setCardState('editing')
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setCardState(undefined)
      }
    }
  }, [claimType, formData, onSave])

  const handleCancel = useCallback(() => {
    typeof onCancel === 'function' && onCancel()
    setCardState(undefined)
    setErrors(undefined)

    if (claimType) {
      const {
        name,
        periodBasis,
        periodDuration,
        periodStart,
        inactiveDate,
        icon,
        parentId,
        taxableType,
        expenseSubmissionType
      } = claimType

      setFormData({
        name,
        periodBasis,
        periodDuration,
        periodStart,
        inactiveDate,
        icon,
        parentId,
        taxableType,
        expenseSubmissionType
      })
    }
  }, [claimType, onCancel])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  const taxableTypeLabel = useMemo(() => {
    // SG
    return 'GST calculation'
  }, [])

  return (
    <EditableCard
      title="Basic information"
      bodyStyle={{ paddingBottom: claimType ? 6 : 24, paddingTop: 6 }}
      state={!canModify ? 'readonly' : cardState}
      formId="form-claim-type-info"
      onEdit={handleEdit}
      onSave={handleSave}
      onCancel={handleCancel}
    >
      <Row gutter={30}>
        <Col flex="auto">
          <Form.Item
            label={
              <>
                Name &nbsp;
                <Tag>{isSingle ? 'single' : 'multiple'}</Tag>
              </>
            }
            validateStatus={errors?.name ? 'error' : ''}
            help={errors?.name}
          >
            <Input
              ref={focusRef}
              value={formData.name}
              readOnly={readOnly}
              onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ name: event.target.value })}
            />
          </Form.Item>
        </Col>
        <Col flex="none">
          <Form.Item label="Inactive" validateStatus={errors?.inactiveDate ? 'error' : ''} help={errors?.inactiveDate}>
            <Checkbox
              checked={isInactive}
              readOnly={readOnly}
              onChange={(event: CheckboxChangeEvent) => {
                setIsInactive(event.target.checked)
                handleFormDataChange({ inactiveDate: event.target.checked ? moment().format('YYYY-MM-DD') : '' })
              }}
            />
          </Form.Item>
        </Col>
        <Col flex="none">
          <Form.Item label="Icon" validateStatus={errors?.icon ? 'error' : ''} help={errors?.icon}>
            <IconPicker
              icon={formData.icon}
              readOnly={readOnly}
              onChange={(icon: string) => handleFormDataChange({ icon })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={12}>
          <Form.Item
            label="Period basis"
            validateStatus={errors?.periodBasis ? 'error' : ''}
            help={errors?.periodBasis}
            tooltip={{
              title: 'Period basis type cannot be changed once claim type is created',
              icon: (
                <span>
                  <i className="fal fa-circle-info" />
                </span>
              )
            }}
          >
            <SysOptions
              type="cla_period_basis"
              value={formData.periodBasis}
              readOnly={readOnly}
              disabled={!!claimType}
              onChange={(periodBasis: string) =>
                handleFormDataChange({ periodBasis, periodStart: 1, periodDuration: 1 })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30} hidden={formData.periodBasis !== ClaPeriodBasis.custom}>
        <Col span={12}>
          <Form.Item
            label="Period start"
            validateStatus={errors?.periodStart ? 'error' : ''}
            help={errors?.periodStart}
          >
            <MonthKeyValues
              value={formData.periodStart}
              readOnly={readOnly}
              onChange={(periodStart: string) => handleFormDataChange({ periodStart })}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Duration"
            validateStatus={errors?.periodDuration ? 'error' : ''}
            help={errors?.periodDuration}
          >
            <Space>
              <Input.Number
                value={formData.periodDuration}
                readOnly={readOnly}
                onChange={(periodDuration: number | null) => handleFormDataChange({ periodDuration })}
              />
              <span>year(s)</span>
            </Space>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30} hidden={readOnly && !formData.parentId}>
        <Col flex={1}>
          <Form.Item label="Parent" validateStatus={errors?.parentId ? 'error' : ''} help={errors?.parentId}>
            <ClaKeyValues
              id="claimType"
              queryParams={{ exceptionId: claimType?.id, isEnabled: true }}
              value={formData.parentId}
              readOnly={readOnly}
              onChange={(parentId: string) => handleFormDataChange({ parentId })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={12}>
          <Form.Item
            label={taxableTypeLabel}
            validateStatus={errors?.taxableType ? 'error' : ''}
            help={errors?.taxableType}
          >
            <SysOptions
              type="cla_taxable_type"
              value={formData.taxableType}
              readOnly={readOnly}
              style={taxableTypeStyle}
              onChange={(taxableType: string) => handleFormDataChange({ taxableType })}
            />
          </Form.Item>
        </Col>
      </Row>
    </EditableCard>
  )
}
