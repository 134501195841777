import { FC } from 'react'
import { Form } from '~/core-components'
import { Col, Row } from '~/components'

interface TimeLogCurrentApproverProps {
  approvers: string[]
  hidden?: boolean
}

export const TimeLogCurrentApprover: FC<TimeLogCurrentApproverProps> = ({ approvers, hidden }) => {
  return (
    <Row hidden={hidden || approvers.length === 0}>
      <Col span={24}>
        <Form.Item label="Pending for approval by">
          <div style={{ whiteSpace: 'pre-line' }}>{approvers.join('\n')}</div>
        </Form.Item>
      </Col>
    </Row>
  )
}
