export enum WfActionType {
  APPROVAL_1 = 'approval_1',
  APPROVAL_2 = 'approval_2',
  APPROVAL_3 = 'approval_3',
  APPROVE = 'approve',
  EMAIL_CC = 'email_cc'
}

export enum WfEventType {
  SUBMIT = 'submit',
  EDIT = 'edit',
  FINAL_APPROVE = 'final_approve'
}

export enum WfFlowType {
  LVE_NEW = 'lve_new',
  LVE_CANCEL = 'lve_cancel',
  ATT_TIME_LOG_NEW = 'att_time_log_new'
}
