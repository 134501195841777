import { showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { replaceLeaveGrantBulk } from '../reducers'
import { ILeaveGrantBulk } from '../types'
import { apiPrepareLeaveGrantBulk } from '../api/leave-grant-bulk.api'
import { emptyGuid } from '~/constants'

export const prepareLeaveGrantBulk = (leaveGrantBulk: ILeaveGrantBulk): ThunkResult<void> => {
  return async dispatch => {
    const { status, result, errors, message, errorData } = await apiPrepareLeaveGrantBulk({
      ...leaveGrantBulk,
      leaveTypeId: leaveGrantBulk.leaveTypeId || emptyGuid,
      earned: leaveGrantBulk.earned || 0
    })
    if (status) {
      dispatch(replaceLeaveGrantBulk(result))
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors, result }
  }
}
