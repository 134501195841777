import { request, getBaseUrl, timeout, toQueryParams } from '~/utils/request'
import { IRptPayMonthly } from '../types'

const baseUrl = getBaseUrl('/payroll-sg/rptpaymonthly')

export const apiDownloadPayMonthlyExcel = (params: IRptPayMonthly) => {
  return request('get', `${baseUrl}/excel`, toQueryParams(params), { responseType: 'blob', timeout })
}

export const apiDownloadPayMonthlyPdf = (params: IRptPayMonthly) => {
  return request('get', `${baseUrl}/pdf`, toQueryParams(params), { responseType: 'blob', timeout })
}
