import React, { ChangeEvent, CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import classNames from 'classnames'
import padStart from 'lodash/padStart'
import { v4 as uuidv4 } from 'uuid'
import { LoadingOutlined } from '@ant-design/icons'
import { Button, Input, Select, Space, Spin, Tag } from '~/core-components'
import { Col, MonthNumberKeyValues, Row } from '~/components'
import { fetchEmpKeyvalues } from '~/features/master'
import { SysOptionType } from '~/features/employee'
import { criteriaDelimiter, CriteriaOperator, CriteriaType, CriteriaValueType, LogicalOperator } from '~/constants'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { formatYearMonth, toSentence } from '~/utils'
import { selectViewCriteriaGroup, selectSysCriteriaFieldsOptGroup, selectIsViewOrSchemaLoading } from '../selectors'
import { Screen, ViewCriteria } from '../types'
import { CriteriaOptions } from './components/CriteriaOptions'
import { CriteriaOperatorType, OperatorType } from './components/CriteriaOperatorType'
import { CriteriaInfo } from './components/CriteriaInfo'
import './ViewCriteriaGroup.less'

interface ViewCriteriaGroupProps {
  screenCode: Screen
  viewId?: string
  parent?: ViewCriteria
  readOnly?: boolean
  level: number
  onChange?: (criteria?: ViewCriteria) => void
  onRemove?: (id: string, removeParentId?: string) => void
}

const selectCriteriaStyle: CSSProperties = { minWidth: 80 }

const NO_VALUE_OP = [
  CriteriaOperator.null,
  CriteriaOperator.not_null,
  CriteriaOperator.today,
  CriteriaOperator.yesterday,
  CriteriaOperator.tomorrow,
  CriteriaOperator.before_n_today,
  CriteriaOperator.after_today,
  CriteriaOperator.next_7_day,
  CriteriaOperator.last_7_day,
  CriteriaOperator.this_week,
  CriteriaOperator.last_week,
  CriteriaOperator.next_week,
  CriteriaOperator.this_month,
  CriteriaOperator.last_month,
  CriteriaOperator.next_month,
  CriteriaOperator.this_year,
  CriteriaOperator.last_year,
  CriteriaOperator.next_year,
  CriteriaOperator.this_quarter,
  CriteriaOperator.last_quarter,
  CriteriaOperator.next_quarter
]

const getGroupCriteria = (parentId: string) => ({
  id: uuidv4(),
  parentId,
  criteriaType: CriteriaType.group,
  operator: LogicalOperator.or,
  sequence: 0
})

const getEmptyCriteria = (parentId: string) => ({
  id: uuidv4(),
  parentId,
  criteriaType: CriteriaType.criteria,
  operator: CriteriaOperator.in,
  sequence: 0
})

const DEFAULT_OPERATOR = {
  [CriteriaValueType.date]: CriteriaOperator.eq,
  [CriteriaValueType.number]: CriteriaOperator.eq,
  [CriteriaValueType.option]: CriteriaOperator.in,
  [CriteriaValueType.text]: CriteriaOperator.eq,
  [CriteriaValueType.yymm]: CriteriaOperator.eq
}

const logicalOperatorStyle: CSSProperties = { paddingTop: 5, paddingBottom: 5 }

export const ViewCriteriaGroup: FC<ViewCriteriaGroupProps> = ({
  screenCode,
  viewId,
  parent,
  readOnly = false,
  level,
  onChange,
  onRemove
}) => {
  const parentId = parent?.id
  const classes = classNames(
    'view-criteria-group',
    { 'view-criteria-group--indented': !!level },
    { 'view-criteria-group--read-only': readOnly }
  )
  const [formData, setFormData] = useState<ViewCriteria[]>([])
  const criteria = useSelector((state: StoreState) => state.selection.sysCriteriaFields[screenCode])
  const criteriaFieldsOptGroup = useSelector(selectSysCriteriaFieldsOptGroup)(screenCode)
  const criteriaFields = useSelector((state: StoreState) => state.selection.sysCriteriaFields[screenCode]?.entities)
  const viewCriteria = useSelector(selectViewCriteriaGroup)(screenCode, viewId, parentId)
  const loading = useSelector(selectIsViewOrSchemaLoading)(screenCode, viewId)

  useEffect(() => {
    dispatch(fetchEmpKeyvalues('syscriteria', undefined, undefined, { strategy: 'when-empty' }))
  }, [screenCode])

  useEffect(() => {
    setFormData([])
  }, [viewId])

  useEffect(() => {
    if (!viewCriteria || viewCriteria.length === 0) {
      if (!readOnly && parent && parentId) {
        setFormData(formData => {
          const hasParent = formData.some(f => f.id === parentId)
          if (formData.length === 0 || !hasParent) {
            return [parent, getEmptyCriteria(parentId)]
          } else {
            return formData
          }
        })
      }
    } else {
      if (viewCriteria && parentId) {
        setFormData(formData => {
          const hasParent = formData.some(f => f.id === parentId)
          if (formData.length === 0 || !hasParent) {
            return viewCriteria
          } else {
            return formData
          }
        })
      }
    }
  }, [viewId, viewCriteria, parentId, parent, readOnly, level])

  const handleFormDataChange = useCallback(
    (index: number, updates: ViewCriteria | undefined) => {
      setFormData(formData => {
        if (!updates) {
          const toRemove = formData[index]
          const isOnlyChild = formData.filter(c => c.criteriaType !== CriteriaType.group).length === 1
          const toRemoveParentId = isOnlyChild ? toRemove.parentId : undefined
          typeof onRemove === 'function' && toRemove?.id && onRemove(toRemove.id, toRemoveParentId)
          return [...formData.slice(0, index), ...formData.slice(index + 1, formData.length)]
        } else {
          typeof onChange === 'function' && onChange(updates)
          return [...formData.slice(0, index), updates, ...formData.slice(index + 1, formData.length)]
        }
      })
    },
    [onChange, onRemove]
  )

  const handleChildGroupRemove = useCallback(
    (id: string, removeParentId?: string) => {
      typeof onRemove === 'function' && onRemove(id)

      if (removeParentId) {
        setFormData(formData => {
          const index = formData.findIndex(c => c.id === removeParentId && c.criteriaType === CriteriaType.group)
          if (index >= 0) {
            const toRemoveParent = formData[index]
            if (toRemoveParent?.id) {
              typeof onRemove === 'function' && onRemove(toRemoveParent.id)
            }
            return [...formData.slice(0, index), ...formData.slice(index + 1, formData.length)]
          }

          return formData
        })
      }
    },
    [onRemove]
  )

  const handleAddCriteriaClick = useCallback(() => {
    if (parentId) {
      handleFormDataChange(formData.length, getEmptyCriteria(parentId))
    }
  }, [parentId, formData, handleFormDataChange])

  const handleAddGroupClick = useCallback(() => {
    if (parentId) {
      handleFormDataChange(formData.length, getGroupCriteria(parentId))
    }
  }, [parentId, formData, handleFormDataChange])

  const handleLogicalOperatorClick = useCallback(() => {
    const parentIndex = formData.findIndex(c => c.id === parentId)
    if (parentIndex >= 0) {
      const parent = formData[parentIndex]
      const operator = parent.operator === LogicalOperator.and ? LogicalOperator.or : LogicalOperator.and
      handleFormDataChange(parentIndex, { ...parent, operator })
    }
  }, [parentId, formData, handleFormDataChange])

  if (loading) {
    return <Spin size="small" indicator={<LoadingOutlined spin />} />
  }

  return (
    <div className={classes}>
      <Row justify="start">
        <Col span={24}>
          <div>
            {formData.map((cri, index) => {
              if (cri.criteriaType === CriteriaType.group && cri.id !== parentId) {
                if (readOnly) {
                  return (
                    <div key={cri.id}>
                      <Space align="start">
                        <>
                          {index === 1 ? (
                            <>Where</>
                          ) : (
                            toSentence(formData?.find(cri => cri.id === parentId)?.operator || '')
                          )}
                        </>
                        <ViewCriteriaGroup
                          key={cri.id}
                          screenCode={screenCode}
                          viewId={viewId}
                          parent={cri}
                          readOnly={readOnly}
                          level={level + 1}
                        />
                      </Space>
                    </div>
                  )
                }

                const classes = classNames('criteria-item__logical-operator', {
                  [`criteria-item__logical-operator--read-only`]: readOnly
                })
                return (
                  <Row key={cri.id} gutter={8} className="view-criteria-group__row-item" align="top">
                    <Col flex="75px">
                      <div style={logicalOperatorStyle}>
                        {index === 1 ? (
                          <>Where</>
                        ) : (
                          <Tag
                            type="original"
                            className={classes}
                            {...(readOnly ? {} : { onClick: handleLogicalOperatorClick })}
                          >
                            <Space size="small">
                              {formData?.find(cri => cri.id === parentId)?.operator}
                              <i className="fal fa-chevron-down" />
                            </Space>
                          </Tag>
                        )}
                      </div>
                    </Col>
                    <Col flex="1">
                      <ViewCriteriaGroup
                        key={cri.id}
                        screenCode={screenCode}
                        viewId={viewId}
                        parent={cri}
                        readOnly={readOnly}
                        level={level + 1}
                        onChange={onChange}
                        onRemove={handleChildGroupRemove}
                      />
                    </Col>
                  </Row>
                )
              } else if (cri.criteriaType === CriteriaType.criteria) {
                const field = criteria?.entities[cri.criteriaFieldId || '']
                const sysOptionType = `criteria_${field?.operatorType ?? field?.valueType}_operator` as SysOptionType

                if (readOnly) {
                  return (
                    <div key={cri.id}>
                      <Space>
                        <>
                          {index === 1 ? (
                            <>Where</>
                          ) : (
                            toSentence(formData?.find(cri => cri.id === parentId)?.operator || '')
                          )}
                        </>
                        <Tag type="white">
                          {criteriaFields ? criteriaFields[cri.criteriaFieldId || '']?.description : ''}
                        </Tag>
                        <CriteriaOperatorType type={sysOptionType as OperatorType} value={cri.operator} showAsLabel />
                        <>
                          {cri.criteriaFieldId && !NO_VALUE_OP.includes(cri.operator) && (
                            <>
                              {cri.operator === CriteriaOperator.between ? (
                                <>
                                  {field?.valueType === CriteriaValueType.option && (
                                    <CriteriaOptions
                                      screenCode={screenCode}
                                      criteria={field}
                                      value={cri.value}
                                      showAsTag
                                    />
                                  )}
                                  {field?.valueType === CriteriaValueType.date && (
                                    <Tag type="white">{moment(cri.value).format('dd MMM yyyy')}</Tag>
                                  )}
                                  {field?.valueType === CriteriaValueType.yymm && (
                                    <Tag type="white">{formatYearMonth(cri.value)}</Tag>
                                  )}
                                  {(field?.valueType === CriteriaValueType.text ||
                                    field?.valueType === CriteriaValueType.number) && (
                                    <Tag type="white">
                                      <Space>
                                        {cri.value}
                                        {field.valueSuffix}
                                      </Space>
                                    </Tag>
                                  )}
                                  <>to</>
                                  {field?.valueType === CriteriaValueType.option && (
                                    <CriteriaOptions
                                      screenCode={screenCode}
                                      criteria={field}
                                      value={cri.valueTo}
                                      showAsTag
                                    />
                                  )}
                                  {field?.valueType === CriteriaValueType.date && (
                                    <Tag type="white">{moment(cri.valueTo).format('dd MMM yyyy')}</Tag>
                                  )}
                                  {field?.valueType === CriteriaValueType.yymm && (
                                    <Tag type="white">{formatYearMonth(cri.valueTo)}</Tag>
                                  )}
                                  {(field?.valueType === CriteriaValueType.text ||
                                    field?.valueType === CriteriaValueType.number) && (
                                    <Tag type="white">
                                      <Space>
                                        {cri.valueTo}
                                        {field.valueSuffix}
                                      </Space>
                                    </Tag>
                                  )}
                                </>
                              ) : (
                                <>
                                  {field?.valueType === CriteriaValueType.option && (
                                    <CriteriaOptions
                                      screenCode={screenCode}
                                      criteria={field}
                                      value={cri.value ? cri.value.split(criteriaDelimiter) : undefined}
                                      showAsTag
                                    />
                                  )}
                                  {field?.valueType === CriteriaValueType.date && (
                                    <Tag type="white">{moment(cri.value).format('dd MMM yyyy')}</Tag>
                                  )}
                                  {field?.valueType === CriteriaValueType.yymm && (
                                    <Tag type="white">{formatYearMonth(cri.value)}</Tag>
                                  )}
                                  {(field?.valueType === CriteriaValueType.text ||
                                    field?.valueType === CriteriaValueType.number) && (
                                    <Tag type="white">
                                      <Space>
                                        {cri.value}
                                        {field.valueSuffix}
                                      </Space>
                                    </Tag>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                        <CriteriaInfo operator={cri.operator} />
                      </Space>
                    </div>
                  )
                }

                const classes = classNames('criteria-item__logical-operator', {
                  [`criteria-item__logical-operator--read-only`]: readOnly
                })
                return (
                  <Row
                    key={cri.id}
                    gutter={8}
                    className="view-criteria-group__row-item"
                    align="middle"
                    hidden={readOnly && !cri.criteriaFieldId}
                  >
                    <Col flex="75px">
                      <div style={logicalOperatorStyle}>
                        {index === 1 ? (
                          <>Where</>
                        ) : (
                          <Tag
                            type="original"
                            className={classes}
                            {...(readOnly ? {} : { onClick: handleLogicalOperatorClick })}
                          >
                            <Space size="small">
                              {formData?.find(cri => cri.id === parentId)?.operator}
                              <i className="fal fa-chevron-down" />
                            </Space>
                          </Tag>
                        )}
                      </div>
                    </Col>
                    <Col flex="160px">
                      <Select
                        value={cri.criteriaFieldId}
                        readOnly={readOnly}
                        allowClear={false}
                        showSearch
                        optionFilterProp="label"
                        dropdownMatchSelectWidth={350}
                        onChange={(value: string) =>
                          handleFormDataChange(index, {
                            ...formData[index],
                            criteriaFieldId: value,
                            operator:
                              DEFAULT_OPERATOR[criteria?.entities[value]?.valueType || CriteriaValueType.option],
                            value: undefined,
                            valueTo: undefined
                          })
                        }
                        options={criteriaFieldsOptGroup}
                      />
                    </Col>
                    <Col flex="120px">
                      {cri.criteriaFieldId && (
                        <CriteriaOperatorType
                          type={sysOptionType as OperatorType}
                          value={cri.operator}
                          onChange={(operator: string) => {
                            const updates = { ...formData[index], operator }
                            if (NO_VALUE_OP.includes(operator)) {
                              updates.value = undefined
                              updates.valueTo = undefined
                            }

                            handleFormDataChange(index, updates)
                          }}
                        />
                      )}
                    </Col>
                    <Col flex="2">
                      {cri.criteriaFieldId && !NO_VALUE_OP.includes(cri.operator) && (
                        <>
                          {cri.operator === CriteriaOperator.between ? (
                            <Row gutter={8} align="middle">
                              <Col
                                flex={
                                  [CriteriaValueType.number, CriteriaValueType.date].includes(field?.valueType || '')
                                    ? undefined
                                    : 1
                                }
                              >
                                {field?.valueType === CriteriaValueType.option && (
                                  <CriteriaOptions
                                    screenCode={screenCode}
                                    criteria={field}
                                    style={selectCriteriaStyle}
                                    value={cri.value}
                                    mode={undefined}
                                    readOnly={readOnly}
                                    onChange={(value: string) =>
                                      handleFormDataChange(index, { ...formData[index], value })
                                    }
                                  />
                                )}
                                {field?.valueType === CriteriaValueType.text && (
                                  <Input
                                    value={cri.value}
                                    readOnly={readOnly}
                                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                      handleFormDataChange(index, { ...formData[index], value: event.target.value })
                                    }
                                  />
                                )}
                                {field?.valueType === CriteriaValueType.number && (
                                  <Space>
                                    <Input.Number
                                      value={parseInt(cri.value || '0')}
                                      precision={2}
                                      readOnly={readOnly}
                                      onChange={(value: number | null) =>
                                        handleFormDataChange(index, { ...formData[index], value: value?.toString() })
                                      }
                                    />
                                    {field.valueSuffix}
                                  </Space>
                                )}
                                {field?.valueType === CriteriaValueType.date && (
                                  <Input.Date
                                    value={cri.value ? moment(cri.value) : undefined}
                                    inputReadOnly={readOnly}
                                    onChange={(value: moment.Moment | null) =>
                                      handleFormDataChange(index, {
                                        ...formData[index],
                                        value: value?.format('YYYY-MM-DD')
                                      })
                                    }
                                  />
                                )}
                                {field?.valueType === CriteriaValueType.yymm && (
                                  <Space>
                                    <Input.Number
                                      value={parseInt(`${cri.value || '00'}`.slice(0, 2))}
                                      readOnly={readOnly}
                                      onChange={(value: number | null) =>
                                        handleFormDataChange(index, {
                                          ...formData[index],
                                          value: `${padStart(value?.toString(), 2, '0')}${padStart(
                                            (cri.value || '')?.slice(-2),
                                            2,
                                            '0'
                                          )}`
                                        })
                                      }
                                    />
                                    years
                                    <MonthNumberKeyValues
                                      style={selectCriteriaStyle}
                                      value={`0${cri.value || '00'}`.slice(-2)}
                                      onChange={(value: string) =>
                                        handleFormDataChange(index, {
                                          ...formData[index],
                                          value: `${padStart(cri.value || '', 2, '0').slice(0, 2)}${padStart(
                                            value,
                                            2,
                                            '0'
                                          ).slice(-2)}`
                                        })
                                      }
                                    />
                                    months
                                  </Space>
                                )}
                              </Col>
                              <Col>to</Col>
                              <Col
                                flex={
                                  [CriteriaValueType.number, CriteriaValueType.date].includes(field?.valueType || '')
                                    ? undefined
                                    : 1
                                }
                              >
                                {field?.valueType === CriteriaValueType.option && (
                                  <CriteriaOptions
                                    screenCode={screenCode}
                                    criteria={field}
                                    style={selectCriteriaStyle}
                                    value={cri.valueTo}
                                    mode={undefined}
                                    readOnly={readOnly}
                                    onChange={(valueTo: string) =>
                                      handleFormDataChange(index, { ...formData[index], valueTo })
                                    }
                                  />
                                )}
                                {field?.valueType === CriteriaValueType.text && (
                                  <Input
                                    value={cri.valueTo}
                                    readOnly={readOnly}
                                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                      handleFormDataChange(index, { ...formData[index], valueTo: event.target.value })
                                    }
                                  />
                                )}
                                {field?.valueType === CriteriaValueType.number && (
                                  <Space>
                                    <Input.Number
                                      value={parseInt(cri.valueTo || '0')}
                                      precision={2}
                                      readOnly={readOnly}
                                      onChange={(valueTo: number | null) =>
                                        handleFormDataChange(index, {
                                          ...formData[index],
                                          valueTo: valueTo?.toString()
                                        })
                                      }
                                    />
                                    {field.valueSuffix}
                                  </Space>
                                )}
                                {field?.valueType === CriteriaValueType.date && (
                                  <Input.Date
                                    value={cri.valueTo ? moment(cri.valueTo) : undefined}
                                    inputReadOnly={readOnly}
                                    onChange={(valueTo: moment.Moment | null) =>
                                      handleFormDataChange(index, {
                                        ...formData[index],
                                        valueTo: valueTo?.format('YYYY-MM-DD')
                                      })
                                    }
                                  />
                                )}
                                {field?.valueType === CriteriaValueType.yymm && (
                                  <Space>
                                    <Input.Number
                                      value={parseInt(`${cri.value || '00'}`.slice(0, 2))}
                                      readOnly={readOnly}
                                      onChange={(valueTo: number | null) =>
                                        handleFormDataChange(index, {
                                          ...formData[index],
                                          value: `${padStart(valueTo?.toString(), 2, '0')}${padStart(
                                            (cri.valueTo || '')?.slice(-2),
                                            2,
                                            '0'
                                          )}`
                                        })
                                      }
                                    />
                                    years
                                    <MonthNumberKeyValues
                                      style={selectCriteriaStyle}
                                      value={`0${cri.valueTo || '00'}`.slice(-2)}
                                      onChange={(valueTo: string) =>
                                        handleFormDataChange(index, {
                                          ...formData[index],
                                          value: `${padStart(cri.valueTo || '', 2, '0').slice(0, 2)}${padStart(
                                            valueTo,
                                            2,
                                            '0'
                                          ).slice(-2)}`
                                        })
                                      }
                                    />
                                    months
                                  </Space>
                                )}
                              </Col>
                            </Row>
                          ) : (
                            <>
                              {field?.valueType === CriteriaValueType.option && (
                                <CriteriaOptions
                                  screenCode={screenCode}
                                  criteria={field}
                                  style={selectCriteriaStyle}
                                  value={cri.value ? cri.value.split(criteriaDelimiter) : undefined}
                                  maxTagCount={undefined}
                                  dropdownMatchSelectWidth={350}
                                  readOnly={readOnly}
                                  onChange={(value: string[]) =>
                                    handleFormDataChange(index, {
                                      ...formData[index],
                                      value: value.join(criteriaDelimiter),
                                      valueTo: undefined
                                    })
                                  }
                                />
                              )}
                              {field?.valueType === CriteriaValueType.text && (
                                <Input
                                  value={cri.value}
                                  readOnly={readOnly}
                                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                    handleFormDataChange(index, {
                                      ...formData[index],
                                      value: event.target.value,
                                      valueTo: undefined
                                    })
                                  }
                                />
                              )}
                              {field?.valueType === CriteriaValueType.number && (
                                <Space>
                                  <Input.Number
                                    value={parseInt(cri.value || '0')}
                                    precision={2}
                                    readOnly={readOnly}
                                    onChange={(value: number | null) =>
                                      handleFormDataChange(index, {
                                        ...formData[index],
                                        value: value?.toString(),
                                        valueTo: undefined
                                      })
                                    }
                                  />
                                  {field.valueSuffix}
                                </Space>
                              )}
                              {field?.valueType === CriteriaValueType.date && (
                                <Input.Date
                                  value={cri.value ? moment(cri.value) : undefined}
                                  inputReadOnly={readOnly}
                                  onChange={(value: moment.Moment | null) =>
                                    handleFormDataChange(index, {
                                      ...formData[index],
                                      value: value?.format('YYYY-MM-DD'),
                                      valueTo: undefined
                                    })
                                  }
                                />
                              )}
                              {field?.valueType === CriteriaValueType.yymm && (
                                <Space>
                                  <Input.Number
                                    value={parseInt(`${cri.value || '00'}`.slice(0, 2))}
                                    readOnly={readOnly}
                                    onChange={(value: number | null) =>
                                      handleFormDataChange(index, {
                                        ...formData[index],
                                        value: `${padStart(value?.toString(), 2, '0')}${padStart(
                                          (cri.value || '')?.slice(-2),
                                          2,
                                          '0'
                                        )}`,
                                        valueTo: undefined
                                      })
                                    }
                                  />
                                  years
                                  <MonthNumberKeyValues
                                    style={selectCriteriaStyle}
                                    value={`0${cri.value || '00'}`.slice(-2)}
                                    onChange={(value: string) =>
                                      handleFormDataChange(index, {
                                        ...formData[index],
                                        value: `${padStart(cri.value || '', 2, '0').slice(0, 2)}${padStart(
                                          value,
                                          2,
                                          '0'
                                        ).slice(-2)}`,
                                        valueTo: undefined
                                      })
                                    }
                                  />
                                  months
                                </Space>
                              )}
                            </>
                          )}
                        </>
                      )}
                      <CriteriaInfo operator={cri.operator} />
                    </Col>
                    <Col flex="32px" hidden={readOnly}>
                      <Button
                        size="small"
                        type="text"
                        icon={<i className="fal fa-trash" />}
                        onClick={() => handleFormDataChange(index, undefined)}
                      />
                    </Col>
                  </Row>
                )
              } else {
                return null
              }
            })}
          </div>
        </Col>
      </Row>
      {!readOnly && (
        <div className="view-criteria-group__actions">
          <Button className="view-criteria-group__add" type="text" size="small" onClick={handleAddCriteriaClick}>
            <Space>
              <i className="fal fa-add" />
              Add filter
            </Space>
          </Button>
          {level === 0 && (
            <Button className="view-criteria-group__add-group" type="text" size="small" onClick={handleAddGroupClick}>
              <Space>
                <i className="fal fa-add" />
                Add group
              </Space>
            </Button>
          )}
        </div>
      )}
    </div>
  )
}
