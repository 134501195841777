import React, { FC, memo, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import { ColumnsType, Space, Table, Tooltip } from '~/core-components'
import { Col, Row } from '~/components'
import { formatMoney } from '~/utils'
import { StoreState } from '~/types/store'
import { PaySnapshotTranState } from '../../types'

interface PaySnapshotTransProps {
  payRunId: string
  employeeId: string
}

const AdditionSign = memo(() => (
  <Tooltip title="Allowance">
    <PlusOutlined style={{ color: 'green' }} />
  </Tooltip>
))
const DeductionSign = memo(() => (
  <Tooltip title="Deduction">
    <MinusOutlined style={{ color: 'red' }} />
  </Tooltip>
))

export const PaySnapshotTrans: FC<PaySnapshotTransProps> = ({ payRunId, employeeId }) => {
  const paySnapshot = useSelector((state: StoreState) => state.payroll.paySnapshot[payRunId] || {})
  const payItems = useSelector((state: StoreState) => state.master.payItems.entities || {})

  const payTrans = useMemo(() => {
    return paySnapshot.payTrans.filter(pt => pt.payRunId === payRunId && pt.employeeId === employeeId)
  }, [paySnapshot, payRunId, employeeId])

  const columns: ColumnsType<PaySnapshotTranState> = [
    {
      title: 'Pay item',
      key: 'payItemId',
      dataIndex: 'payItemId',
      width: 250,
      render: (value: string) => {
        return (
          <Space align="start">
            {payItems[value]?.type === 'a' ? <AdditionSign /> : <DeductionSign />}
            <div>{payItems[value]?.name || value}</div>
          </Space>
        )
      }
    },
    {
      title: 'Amount',
      key: 'amountBefore',
      dataIndex: 'amountBefore',
      width: 150,
      align: 'right',
      render: (value: number, record) => {
        return (
          <div className={classNames('compare', { 'compare--diff': value !== record.amountAfter })}>
            <div>{formatMoney(value)}</div>
            <div>{formatMoney(record.amountAfter)}</div>
          </div>
        )
      }
    }
  ]
  return (
    <Row>
      <Col>
        <Table
          rowKey="id"
          dataSource={payTrans}
          pagination={false}
          showHeader={false}
          columns={columns}
          className="paysnapshot__trans-table"
        />
      </Col>
    </Row>
  )
}
