import { EntityState } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { RptScreen, SysGroupingScreenState, SysGroupingState } from './grouping.state'

export const groupingPersistConfig = {
  key: 'grouping',
  whitelist: ['sysGroupingScreens', 'sysGroupings', 'sysGroupingsRefetch'],
  storage
}

export interface GroupingRootState {
  sysGroupingScreens: { [screenCode in RptScreen]?: EntityState<SysGroupingScreenState> }
  sysGroupingScreensLoading: { [screenCode in RptScreen]?: boolean }
  sysGroupings: { [screenCode in RptScreen]?: EntityState<SysGroupingState> }
  sysGroupingsLoading: { [screenCode in RptScreen]?: boolean }
  sysGroupingsRefetch: number
}
