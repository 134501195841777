import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { PayslipItemConfigState } from '../types'
import { GroupChildren } from '~/types/common'

export interface PayslipItemConfigGroupChildren<T> extends Omit<GroupChildren<T>, 'id'> {
  payItemId: string
  category: string
}

export const selectPayslipItemConfigs = createSelector(
  (state: StoreState) => state.master.payslipItemConfigs,
  (state: StoreState) => state.master.keyvalues['payItemCategory'],
  (state: StoreState) => state.master.keyvalues['payItemType'],
  (payslipItemConfigsState, payItemCategoriesState, payItemTypesState) =>
    memoize((payGroupId: string) => {
      const map = new Map<string, PayslipItemConfigGroupChildren<PayslipItemConfigState>>()
      const payslipItemConfigs = (
        Object.values(payslipItemConfigsState[payGroupId]?.entities || {}) as PayslipItemConfigState[]
      ).sort((a, b) => a.category?.localeCompare(b.category || '') || 0)

      payslipItemConfigs.forEach(item => {
        if (item) {
          const { id, payGroupId, payItemId, quantityExpr, name, category, rate, type, formulaId } = item

          const categoryName = payItemCategoriesState?.entities[category]?.value || ''
          const typeName = payItemTypesState?.entities[type]?.value || ''
          const groupName = category === 'xx' ? `${categoryName} ${typeName?.toLowerCase()}` : categoryName

          map.has(groupName) ||
            map.set(groupName, { payItemId: `group-${payItemId}`, category, name: groupName, children: [] })

          map.get(groupName)?.children.push({
            id,
            payGroupId,
            payItemId,
            quantityExpr,
            name,
            category,
            rate,
            type,
            formulaId
          })
        }
      })

      const result = [...map.values()]
      return result
    })
)
