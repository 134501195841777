import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchXeroLineItemMaps } from '../actions'
import { XeroLineItemMapState } from '../types'
import { selectXeroLineItemMaps } from '../selectors'

export const useXeroLineItemMaps = (
  companyId?: string,
  fetchStrategy: FetchStrategy = 'when-empty'
): [XeroLineItemMapState[], boolean] => {
  const lineItemMap = useSelector(selectXeroLineItemMaps)(companyId || '')
  const loading = useSelector((state: StoreState) => state.payroll.xeroLineItemMapsLoading[companyId || ''])

  useEffect(() => {
    dispatch(fetchXeroLineItemMaps(companyId, { strategy: fetchStrategy }))
  }, [companyId, fetchStrategy])

  return [lineItemMap, loading]
}
