import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { LeaveGrantState } from '../types'

export const selectLeaveGrants = createSelector(
  (state: StoreState) => state.leave.leaveGrants,
  leaveGrantsState =>
    memoize((employeeId: string): LeaveGrantState[] => {
      return Object.values(leaveGrantsState[employeeId]?.entities || {}) as LeaveGrantState[]
    })
)
