import { Dictionary } from '@reduxjs/toolkit'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchClaimTypes } from '../actions'
import { ClaimTypeState } from '../types'

export const useClaimTypesDict = (): [Dictionary<ClaimTypeState>, boolean] => {
  const data = useSelector((state: StoreState) => state.claim.claimTypes?.entities || {})
  const loading = useSelector((state: StoreState) => state.claim.claimTypesLoading)

  useEffect(() => {
    dispatch(fetchClaimTypes({ strategy: 'when-empty' }))
  }, [])

  return [data, loading]
}
