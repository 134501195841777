import React, { FC } from 'react'
import { Col, Row } from '~/components'
import { YtdSubmissionStatus } from '~/constants'
import { YtdSubmissionState } from '../../../types'
import { IrasActions } from '../../Iras/IrasActions'
import { YtdCompany } from './YtdCompany'
import { YtdFileSummary } from './YtdFileSummary'
import './YtdSummary.less'

interface YtdSummaryProps {
  ytdSubmission?: YtdSubmissionState
}

export const YtdSummary: FC<YtdSummaryProps> = ({ ytdSubmission }) => {
  return (
    <Row gutter={[30, 30]} className="ytd-summary">
      <Col md={24} lg={14}>
        <YtdFileSummary ytdSubmission={ytdSubmission} />
      </Col>
      <Col md={24} lg={10}>
        <Row gutter={[30, 15]}>
          <Col span={24}>
            <YtdCompany ytdSubmission={ytdSubmission} />
          </Col>
          <Col span={24} hidden={ytdSubmission?.status !== YtdSubmissionStatus.draft}>
            <IrasActions ytdSubmission={ytdSubmission} />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
