import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiDeleteLocation } from '../api/location.api'
import { refetchLocationsView, removeLocation } from '../reducers'
import { removeKeyvalue } from '~/features/master'

export const deleteLocation = (id: string): ThunkResult<void> => {
  return async dispatch => {
    if (!id) return

    const { status, errors, message, errorData } = await apiDeleteLocation(id)
    if (status) {
      dispatch(removeLocation(id))
      dispatch(refetchLocationsView())
      dispatch(removeKeyvalue({ kv: 'location', id }))
      showSuccess('Deleted')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
