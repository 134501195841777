import { FC, useCallback, useState } from 'react'
import { Button, Drawer, Space } from '~/core-components'
import { useMyTimeLogTask } from '../../../hooks'
import { MyTimeLogTaskActionModal } from './MyTimeLogTaskActionModal'
import { MyTimeLogTaskForm } from './MyTimeLogTaskForm'
import './MyTimeLogTaskDrawer.less'

interface MyTimeLogTaskDrawerProps {
  id: string
  visible: boolean
  onClose: (success?: boolean) => void
}

interface ActionModalState {
  id: string
  visible: boolean
  action: 'approve' | 'reject'
}

const DEFAULT_ACTION_MODAL_STATE: ActionModalState = { id: '', visible: false, action: 'approve' }

export const MyTimeLogTaskDrawer: FC<MyTimeLogTaskDrawerProps> = ({ id, visible, onClose }) => {
  const [actionModalState, setActionModalState] = useState<ActionModalState>(DEFAULT_ACTION_MODAL_STATE)

  const [task, fetching] = useMyTimeLogTask(id)

  const handleCloseDrawer = useCallback(
    (success?: boolean) => {
      typeof onClose === 'function' && onClose(success)
    },
    [onClose]
  )

  const handleApprove = useCallback(async () => {
    setActionModalState({ id, visible: true, action: 'approve' })
  }, [id])

  const handleReject = useCallback(async () => {
    setActionModalState({ id, visible: true, action: 'reject' })
  }, [id])

  const handleCloseAction = useCallback(
    (success: boolean) => {
      setActionModalState(DEFAULT_ACTION_MODAL_STATE)

      if (success) {
        handleCloseDrawer(true)
      }
    },
    [handleCloseDrawer]
  )

  return (
    <Drawer
      open={visible}
      title="Time log approval"
      onClose={() => handleCloseDrawer()}
      width={600}
      className="my-time-log-task-drawer"
      footer={
        <Space className="my-time-log-task-drawer__action-bar-buttons">
          <Button type="primary" onClick={handleApprove}>
            Approve
          </Button>
          <Button onClick={handleReject}>Reject</Button>
        </Space>
      }
    >
      <MyTimeLogTaskForm data={task} loading={fetching} />
      <MyTimeLogTaskActionModal {...actionModalState} onClose={handleCloseAction} />
    </Drawer>
  )
}
