import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { PayLeaveMapState } from '../types'

export const selectPayLeaveMaps = createSelector(
  (state: StoreState) => state.master.payLeaveMaps,
  payLeaveMapsState =>
    memoize((payGroupId: string) => {
      return (Object.values(payLeaveMapsState[payGroupId]?.entities || {}) as PayLeaveMapState[]) || []
    })
)
