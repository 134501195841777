import { showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { apiAddYtdSubmission } from '../api/ytd-submission.api'
import { refetchYtdSubmissionViews } from '../reducers'
import { IYtdSubmissionNew } from '../types'

export const addYtdSubmission = (ytdSubmission: IYtdSubmissionNew): ThunkResult<void> => {
  return async (dispatch): Promise<ActionResult | undefined> => {
    const { status, result, errors, message, errorData } = await apiAddYtdSubmission(ytdSubmission)
    if (status) {
      dispatch(refetchYtdSubmissionViews())
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
