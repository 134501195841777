import { showError, showSuccess } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { apiSubmitOed } from '../api/rpt-oed-submission.api'
import { SubmitOedRequest } from '../types'
import { refetchOedLogs, refetchOedSummary, setOedSubmission } from '../reducers'
import { OedSubmissionStatus } from '~/constants'

export const submitOed = (params: SubmitOedRequest): ThunkResult<void> => {
  return async (dispatch, getState): Promise<ActionResult | undefined> => {
    if (!params.oedSubmissionId) return
    const { status, result, errors, message, errorData } = await apiSubmitOed(params)

    if (status) {
      showSuccess('Submission is successful!')

      const oedSubmission = getState().report.oedSubmissions.entities[params.oedSubmissionId]
      if (oedSubmission) {
        dispatch(setOedSubmission({ ...oedSubmission, status: OedSubmissionStatus.submitted }))
        dispatch(refetchOedLogs())
        dispatch(refetchOedSummary())
      }
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
