import React, { forwardRef } from 'react'
import {
  default as AntTextArea,
  TextAreaProps as AntTextAreaProps,
  TextAreaRef as AntTextAreaRef
} from 'antd/lib/input/TextArea'

export interface TextAreaProps extends AntTextAreaProps {}

const TextAreaInternal = (props: TextAreaProps, ref: React.Ref<AntTextAreaRef>) => <AntTextArea ref={ref} {...props} />

export const TextArea = forwardRef(TextAreaInternal)
