import React, { FC, useCallback, useState } from 'react'
import { Button, Tooltip } from '~/core-components'
import { usePermissionGate } from '~/features/iam'
import { Permission, PermissionAction } from '~/constants'
import { CopyScheduleRecordDrawer } from './CopyScheduleRecordDrawer'
import { showInfo } from '~/utils'

interface CopyScheduleRecordButtonProps {
  startDate: string
  employeeIds: string[]
}

interface DrawerState {
  visible: boolean
  startDate?: string
  employeeIds: string[]
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false, employeeIds: [] }

export const CopyScheduleRecordButton: FC<CopyScheduleRecordButtonProps> = ({ startDate, employeeIds }) => {
  const canModify = usePermissionGate(Permission.schRecord, PermissionAction.Modify)
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)

  const handleOpenDrawer = useCallback(() => {
    if (employeeIds.length === 0) {
      showInfo('Please select employee...')
      return
    }
    setDrawerState({
      visible: true,
      startDate,
      employeeIds
    })
  }, [startDate, employeeIds])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  if (!canModify) return null

  return (
    <>
      <Tooltip title="Copy schedule to...">
        <Button icon={<i className="fal fa-copy" />} onClick={handleOpenDrawer} />
      </Tooltip>
      <CopyScheduleRecordDrawer {...drawerState} onClose={handleCloseDrawer} />
    </>
  )
}
