import React, { CSSProperties, FC, memo, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Checkbox, Drawer, Form, SecondaryText, Space, Tag, UploadFile } from '~/core-components'
import { Col, Row, UploadFileSelectorAuth } from '~/components'
import { EmEducationRequestState } from '../../../../types'
import { dispatch } from '~/stores/store'
import { ActionResult } from '~/types/store'
import { selectQualificationKeyvalues } from '~/features/master'
import { approveEmEducationRequest, rejectEmEducationRequest } from '~/features/employee/actions'
import { getBaseUrl } from '~/utils'
import { EmRequestApprovalBtn } from '../EmRequestApprovalBtn'

export interface EmEducationRequestApprovalProps {
  data?: EmEducationRequestState
  visible: boolean
  onClose: () => void
}

const PendingTag = memo(() => <Tag type="secondary">pending</Tag>)

const currenTextStyle: CSSProperties = { fontSize: 12 }
const checkboxStyle: CSSProperties = { marginLeft: 10 }

const EMPTY_ATTACHMENT: UploadFile = {
  uid: '',
  name: '',
  size: 0,
  type: ''
}

const baseUrl = getBaseUrl('/employee')

export const EmEducationRequestApprovalDrawer: FC<EmEducationRequestApprovalProps> = ({ data, visible, onClose }) => {
  const qualifications = useSelector(selectQualificationKeyvalues)
  const [attachment, setAttachment] = useState<UploadFile>()

  const isNew = data?.recordStatus === 'new_request'
  const isDiffIsHighest = !isNew && data?.isHighest !== data?.isHighestNew
  const isDiffQualificationId = !isNew && data?.qualificationId !== data?.qualificationIdNew
  const isDiffMajor = !isNew && data?.major !== data?.majorNew
  const isDiffInstitute = !isNew && data?.institute !== data?.instituteNew
  const isDiffStartDate = !isNew && data?.startDate !== data?.startDateNew
  const isDiffEndDate = !isNew && data?.endDate !== data?.endDateNew
  const isDiffGraduationYear = !isNew && data?.graduationYear !== data?.graduationYearNew

  useEffect(() => {
    if (data && data.attachmentId) {
      setAttachment({
        uid: data.attachmentId,
        name: data.attachmentName || '',
        size: data.attachmentSize || 0,
        type: data.attachmentFormat || '',
        url: `${baseUrl}/employee/request/education/${data.id}/downloadfile`
      })
    }
  }, [data])

  const handleApproval = useCallback(
    async (action: 'approve' | 'reject') => {
      let result: ActionResult | undefined

      if (data) {
        if (action === 'approve') {
          result = await dispatch(approveEmEducationRequest(data.employeeId, data.id))
        } else if (action === 'reject') {
          result = await dispatch(rejectEmEducationRequest(data.employeeId, data.id))
        }
      }

      if (!result?.errors) {
        typeof onClose === 'function' && onClose()
      }
    },
    [onClose, data]
  )

  const handleCloseDrawer = useCallback(() => {
    setAttachment(EMPTY_ATTACHMENT)
    typeof onClose === 'function' && onClose()
  }, [onClose])

  const handleFileChange = useCallback(() => {}, [])

  if (!data) return null

  return (
    <Drawer
      open={visible}
      title={<Space>Education request approval {isNew && <Tag type="secondary">new (pending)</Tag>}</Space>}
      onClose={() => handleCloseDrawer()}
      width={600}
      className="em-education-request-approval-drawer"
      footer={<EmRequestApprovalBtn onApproval={handleApproval} />}
    >
      <Form id="form-em-education-request-approval">
        <Row>
          <Col flex="250px">
            <Form.Item label={<Space>Qualification {isDiffQualificationId && <PendingTag />}</Space>}>
              <div>{qualifications[data.qualificationIdNew]?.value || '-'}</div>
              {isDiffQualificationId && (
                <SecondaryText style={currenTextStyle}>
                  Current: {qualifications[data.qualificationId]?.value || '-'}
                </SecondaryText>
              )}
            </Form.Item>
          </Col>
          <Col flex={1}>
            <Form.Item label=" ">
              <Checkbox checked={data.isHighestNew} style={checkboxStyle} readOnly={true}>
                {<Space>Highest qualification {isDiffIsHighest && <PendingTag />}</Space>}
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label={<Space>Major {isDiffMajor && <PendingTag />}</Space>}>
              <div>{data.majorNew || '-'}</div>
              {isDiffMajor && <SecondaryText style={currenTextStyle}>Current: {data.major || '-'}</SecondaryText>}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label={<Space>Institute {isDiffInstitute && <PendingTag />}</Space>}>
              <div>{data.instituteNew || '-'}</div>
              {isDiffInstitute && (
                <SecondaryText style={currenTextStyle}>Current: {data.institute || '-'}</SecondaryText>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item label={<Space>Start {isDiffStartDate && <PendingTag />}</Space>}>
              <div>{data.startDateNew ? moment(data.startDateNew).format('DD MMM YYYY') : '-'}</div>
              {isDiffStartDate && (
                <SecondaryText style={currenTextStyle}>
                  Current: {data.startDate ? moment(data.startDate).format('DD MMM YYYY') : '-'}
                </SecondaryText>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={<Space>End {isDiffEndDate && <PendingTag />}</Space>}>
              <div>{data.endDateNew ? moment(data.endDateNew).format('DD MMM YYYY') : '-'}</div>
              {isDiffEndDate && (
                <SecondaryText style={currenTextStyle}>
                  Current: {data.endDate ? moment(data.endDate).format('DD MMM YYYY') : '-'}
                </SecondaryText>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Form.Item label={<Space>Graduation year {isDiffGraduationYear && <PendingTag />}</Space>}>
              <div>{data.graduationYearNew || '-'}</div>
              {isDiffGraduationYear && (
                <SecondaryText style={currenTextStyle}>Current: {data.graduationYear || '-'}</SecondaryText>
              )}
            </Form.Item>
          </Col>
        </Row>
        {attachment?.uid && (
          <Row>
            <Col span={24}>
              <Form.Item label="Attachment">
                <UploadFileSelectorAuth
                  multiple={false}
                  accept=".gif,.png,.jpeg,.jpg"
                  fileList={attachment ? [attachment] : []}
                  listType="picture-card"
                  disabled={true}
                  onChange={handleFileChange}
                  withCache={false}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    </Drawer>
  )
}
