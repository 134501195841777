import { request, getBaseUrl } from '~/utils/request'
import { WfCategory, SysWfActionTypeState, SysWfEventTypeState, SysWfFlowTypeState } from '../types'

const baseUrl = getBaseUrl('/employee/sysworkflow')

export const apiGetFlowTypes = (category: WfCategory) =>
  request<SysWfFlowTypeState[]>('get', `${baseUrl}/${category}/flowtype`)

export const apiGetEventTypes = (flowType: string) =>
  request<SysWfEventTypeState[]>('get', `${baseUrl}/${flowType}/eventtype`)

export const apiGetActionTypes = (flowType: string, eventType: string) =>
  request<SysWfActionTypeState[]>('get', `${baseUrl}/${flowType}/${eventType}/actiontype`)
