import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchSlackConfigs } from '../actions'
import { SlackConfigsState } from '../types'

export const useSlackConfigs = (): [SlackConfigsState | undefined, boolean] => {
  const data = useSelector((state: StoreState) => state.notification?.slackConfigs)
  const loading = useSelector((state: StoreState) => state.notification?.slackConfigsLoading)

  useEffect(() => {
    dispatch(fetchSlackConfigs())
  }, [])

  return [data, loading]
}
