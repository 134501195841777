import { Dictionary } from '@reduxjs/toolkit'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { fetchMasters, PayGroupState } from '~/features/master'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'

export const usePayGroupsDict = (fetchStrategy: FetchStrategy = 'always'): [Dictionary<PayGroupState>, boolean] => {
  const payGroups = useSelector(
    (state: StoreState) => state.master.masters['payGroup'].entities as Dictionary<PayGroupState>
  )
  const loading = useSelector((state: StoreState) => state.master.mastersLoading['payGroup'])

  useEffect(() => {
    dispatch(fetchMasters('payGroup', { strategy: fetchStrategy }))
  }, [fetchStrategy])

  return [payGroups, loading]
}
