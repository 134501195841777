import { setPayRecord, setPayRecordLoading } from '../reducers'
import { apiGetPayRecordSgViewItem } from '../api/payrecord-sg.api'
import { ThunkResult } from '~/types/store'

export const fetchPayRecordSgViewItem = (payRunId: string, employeeId: string): ThunkResult<void> => {
  return async dispatch => {
    if (!payRunId || !employeeId) return

    try {
      dispatch(setPayRecordLoading({ payRunId, loading: true }))
      const { result, status } = await apiGetPayRecordSgViewItem(payRunId, employeeId)
      if (status) dispatch(setPayRecord({ payRunId, data: result }))
    } finally {
      dispatch(setPayRecordLoading({ payRunId, loading: false }))
    }
  }
}
