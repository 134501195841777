import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { KeyValue } from '~/types/common'

export const selectMyCalViewAs = createSelector(
  (state: StoreState) => state.my.myCalViewAs,
  myCalViewAsState => {
    return Object.values(myCalViewAsState?.entities || {}) as KeyValue[]
  }
)
