import { CalendarEvent, DateRange, Pagination } from '~/types/common'
import { request, getBaseUrl } from '~/utils/request'
import { SSLeaveRecordViewState, SSLeaveRecordDtlState, SSLeaveRecordAttachmentState, SSLeaveRecordRes } from '../types'

const baseUrl = getBaseUrl('/leave/ssleaverecord')

export const apiGetMyLeaveCalendarEvents = (params: DateRange) =>
  request<CalendarEvent[]>('get', `${baseUrl}/calendar`, params)

export const apiGetMyLeaveRecordsView = (params: Pagination) => request<SSLeaveRecordViewState>('get', baseUrl, params)

export const apiGetMyLeaveRecord = (id: string) => request<SSLeaveRecordRes>('get', `${baseUrl}/${id}`)

export const apiGetMyLeaveRecordDtls = (id: string) => request<SSLeaveRecordDtlState[]>('get', `${baseUrl}/${id}/dtl`)

export const apiGetMyLeaveRecordAttachments = (id: string) =>
  request<SSLeaveRecordAttachmentState[]>('get', `${baseUrl}/${id}/attachment`)

export const apiDeleteMyLeaveRecord = (id: string) => request<void>('delete', `${baseUrl}/${id}`)
