import { Dictionary } from '@reduxjs/toolkit'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchOffices } from '../actions'
import { OfficeState } from '../types'

export const useOffices = (fetchStrategy: FetchStrategy = 'when-empty'): [Dictionary<OfficeState>, boolean] => {
  const data = useSelector((state: StoreState) => state.master.offices?.entities)
  const loading = useSelector((state: StoreState) => state.master.officesLoading)

  useEffect(() => {
    dispatch(fetchOffices({ strategy: fetchStrategy }))
  }, [fetchStrategy])

  return [data, loading]
}
