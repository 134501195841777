import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchLeEarned } from '../actions'
import { selectLeEarned } from '../selectors/select-le-earned'
import { LeEarnedState } from '../types'

export const useLeEarned = (
  periodCode?: string,
  employeeId?: string,
  leaveTypeId?: string
): [LeEarnedState[], boolean] => {
  const entId = `${periodCode}|${employeeId}|${leaveTypeId}`
  const data = useSelector(selectLeEarned)(periodCode, employeeId, leaveTypeId)
  const loading = useSelector((state: StoreState) => state.leave.leEarnedLoading[entId])

  useEffect(() => {
    dispatch(fetchLeEarned(periodCode, employeeId, leaveTypeId))
  }, [periodCode, employeeId, leaveTypeId])

  return [data, loading]
}
