import { showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import pick from 'lodash/pick'
import { ILoginAndPermission, LoginState } from '../types'
import { replaceLoginPermissions, setLogin } from '../reducers'
import { apiUpdateLoginAndPermissions } from '../api/login.api'

export const updateLoginWithPermissions = (request: ILoginAndPermission): ThunkResult<void> => {
  return async (dispatch, getState): Promise<ActionResult> => {
    const { status, result, errors, message, errorData } = await apiUpdateLoginAndPermissions(request)
    if (status) {
      const currentLogin = getState().iam.logins.entities[request.id] || ({} as LoginState)
      const newLoginUpdates = pick(request, ['email', 'loginName', 'isSuperUser', 'inactiveDate'])
      const newLogin: LoginState = {
        ...currentLogin,
        id: result.id || request.id,
        ...newLoginUpdates
      }

      // The "login" update
      dispatch(setLogin(newLogin))

      // The "login-permissions" update
      dispatch(replaceLoginPermissions({ loginId: newLogin.id, data: result.permissions || [] }))
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
