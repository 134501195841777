import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { TimeLogState } from '../types'

export const selectTimeLogsByDaily = createSelector(
  (state: StoreState) => state.attendance.timeLogsByDaily,
  timeLogsByDailyState =>
    memoize((dailyRecordId: string): TimeLogState[] => {
      return Object.values(timeLogsByDailyState[dailyRecordId]?.entities || {}) as TimeLogState[]
    })
)
