import React, { CSSProperties, FC, useCallback, useState } from 'react'
import moment from 'moment-timezone'
import { Button, Card, ColumnsType, Link, PageHeader, SecondaryText, Space, Table } from '~/core-components'
import { DateCustomPicker, DateCustomPickerItem, DocumentTitle } from '~/components'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { MutateCurrencyExchangeDrawer } from './components/MutateCurrencyExchangeDrawer'
import { CurrencyExchangeState } from '../../types'
import { formatMoney } from '~/utils'
import { Screen, ViewCriteria, ViewCriteriaSimple, updateViewCriteria, useFirstView } from '~/features/selection'
import { useCurrencyExchangeView } from '../../hooks/use-currency-exchange-view'
import { refetchCurrencyExchangesView } from '../../reducers'
import { CurrencyName } from './components/CurrencyName'
import './CurrencyExchanges.less'

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: '',
    breadcrumbName: 'Overview'
  }
]

interface DrawerState {
  visible: boolean
  data?: CurrencyExchangeState
}

interface CurrencyExchangesProps {}

const paginationStyle: CSSProperties = { marginRight: 20 }
const SCREEN_CODE: Screen = 'currency_exchange'
const PAGE_SIZE_OPTIONS = ['20', '50', '100']
const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }
const TODAY_MONTH = moment().month()
const PERIOD_OPTIONS: DateCustomPickerItem[] = [
  {
    key: TODAY_MONTH.toString(),
    value: 'This month',
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').format('YYYY-MM-DD')
  },
  {
    key: (TODAY_MONTH - 1).toString(),
    value: 'Last month',
    startDate: moment().startOf('month').add(-1, 'month').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').add(-1, 'month').format('YYYY-MM-DD')
  }
]

export const CurrencyExchanges: FC<CurrencyExchangesProps> = () => {
  const [startDate, setStartDate] = useState<string>(moment().startOf('month').format('YYYY-MM-DD'))
  const [endDate, setEndDate] = useState<string>(moment().endOf('month').format('YYYY-MM-DD'))
  const [view, viewLoading] = useFirstView(SCREEN_CODE)
  const viewId = view?.id || ''
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(20)
  const [data, dataLoading] = useCurrencyExchangeView(viewId, page, pageSize, startDate, endDate, 'always')
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const canModify = usePermissionGate(Permission.claCurrency, PermissionAction.Modify)

  const handleAddCurrencyExchange = useCallback(() => {
    setDrawerState({ visible: true })
  }, [])

  const handleEditCurrencyExchange = useCallback((currencyExchange: CurrencyExchangeState) => {
    setDrawerState({ visible: true, data: currencyExchange })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const handlePaginationChange = useCallback((page: number, pageSize?: number) => {
    setPage(page)
    setPageSize(pageSize || 20)
  }, [])

  const handleCriteriaApply = useCallback(
    async (criteria: ViewCriteria[]) => {
      if (viewId) {
        setPage(1)
        await dispatch(updateViewCriteria(SCREEN_CODE, viewId, { id: viewId, criteria }))
        dispatch(refetchCurrencyExchangesView())
      }
    },
    [viewId]
  )

  const handleDateRangeChange = useCallback((startDate: string, endDate: string) => {
    setStartDate(startDate)
    setEndDate(endDate)
  }, [])

  const columns: ColumnsType<CurrencyExchangeState> = [
    {
      title: 'Starting from',
      key: 'startDate',
      dataIndex: 'startDate',
      render: (value: string, record) => moment(value).format('DD MMM YYYY')
    },
    {
      title: 'Source currency',
      key: 'sourceCurrencyCode',
      dataIndex: 'sourceCurrencyCode',
      render: (value: string, record) => <CurrencyName code={value} />
    },
    {
      title: 'Target currency',
      key: 'targetCurrencyCode',
      dataIndex: 'targetCurrencyCode',
      render: (value: string, record) => <CurrencyName code={value} />
    },
    {
      title: 'Exchange rate',
      key: 'rate',
      dataIndex: 'rate',
      render: (value: number, record) => {
        const reverseRate = formatMoney(record.unit / value, 10).replace(/\.?0+$/, '')
        return (
          <>
            <div style={{}}>{formatMoney(value)}</div>
            <SecondaryText style={{ fontSize: 12 }}>
              {`${
                record.unit
              } ${record.targetCurrencyCode.toUpperCase()} = ${reverseRate} ${record.sourceCurrencyCode.toUpperCase()}`}
            </SecondaryText>
          </>
        )
      }
    },
    {
      key: 'action',
      align: 'right',
      render: (value: string, record) =>
        canModify && (
          <Link size="small" onClick={() => handleEditCurrencyExchange(record)}>
            edit
          </Link>
        )
    }
  ]

  return (
    <div id="currency-exchanges" className="currency-exchanges">
      <DocumentTitle title="Exchange Rates" />
      <PageHeader
        title="Exchange rates"
        containerId="currency-exchanges"
        breadcrumb={{ routes }}
        extra={
          canModify && (
            <Button key="add" onClick={handleAddCurrencyExchange}>
              Add exchange rate
            </Button>
          )
        }
      />
      <div className="currency-exchanges__body">
        <div className="currency-exchanges__action-bar">
          <ViewCriteriaSimple screenCode={SCREEN_CODE} viewId={viewId} onApply={handleCriteriaApply} label="" />
          <Space align="start">
            <DateCustomPicker
              defaultValue={TODAY_MONTH.toString()}
              options={PERIOD_OPTIONS}
              onChange={handleDateRangeChange}
            />
          </Space>
        </div>
        <Card table>
          <Table
            rowKey="id"
            dataSource={data?.data}
            columns={columns}
            loading={dataLoading || viewLoading}
            pagination={{
              total: data?.count,
              current: page,
              pageSize,
              pageSizeOptions: PAGE_SIZE_OPTIONS,
              showSizeChanger: true,
              onChange: handlePaginationChange,
              style: paginationStyle
            }}
          />
        </Card>
      </div>
      {canModify && <MutateCurrencyExchangeDrawer {...drawerState} onClose={handleCloseDrawer} />}
    </div>
  )
}
