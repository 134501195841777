import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchLoginPermissions } from '../actions'
import { LoginPermissionState } from '../types'
import { selectLoginPermissions } from '../selectors'

export const useLoginPermissions = (
  loginId: string,
  tenantCode: string | null,
  fetchStrategy: FetchStrategy = 'when-empty'
): [LoginPermissionState[], boolean] => {
  const loginPermissions = useSelector(selectLoginPermissions)(loginId)
  const loading = useSelector((state: StoreState) => state.iam.loginPermissionsLoading)

  useEffect(() => {
    dispatch(fetchLoginPermissions(loginId, tenantCode, { strategy: fetchStrategy }))
  }, [loginId, tenantCode, fetchStrategy])

  return [loginPermissions, loading]
}
