import { request, getBaseUrl } from '~/utils/request'
import { BreakState, BreakTimeState, ISaveBreak } from '../types'

const baseUrl = getBaseUrl('/attendance/break')

export const apiGetBreaks = () => request<BreakState[]>('get', baseUrl)

export const apiSaveBreak = (_break: ISaveBreak) => request<{ id: string }>('post', `${baseUrl}/save`, _break)

export const apiDeleteBreak = (id: string) => request<void>('delete', `${baseUrl}/${id}`)

export const apiGetBreakTimes = (breakId: string) => request<BreakTimeState[]>('get', `${baseUrl}/${breakId}/time`)
