import { request, getBaseUrl } from '~/utils/request'
import { DailyImportState, DailyImportValidateState, DailyImportExecuteState } from '../types'

const baseUrl = getBaseUrl('/payroll-sg/dailyimport')

export const apiPrepareDailyImport = (payRunId: string, attendancePeriodId: string) => {
  return request<DailyImportState>('post', `${baseUrl}/prepare`, { payRunId, attendancePeriodId })
}

export const apiValidateDailyImport = (payRunId: string, attendancePeriodId: string, employeeIds: string[]) =>
  request<DailyImportValidateState>('post', `${baseUrl}/validate`, { payRunId, attendancePeriodId, employeeIds })

export const apiExecuteDailyImport = (payRunId: string) =>
  request<DailyImportExecuteState>('post', `${baseUrl}/execute`, { payRunId })
