import React, { FC, useState, useCallback, useEffect, ChangeEvent } from 'react'
import confirm from 'antd/lib/modal/confirm'
import { Form, Input } from '~/core-components'
import { Col, DrawerForm, Row } from '~/components'
import { dispatch } from '~/stores/store'
import { addMaster, deleteMaster, updateMaster } from '../../../actions'
import { IHolidayInfo, HolidayState } from '../../../types'
import { mapHolidayStateToHolidayInfo } from '../../../types/master.mapper'
import { ActionResult, Errors } from '~/types/store'
import { useFocus } from '~/hooks/use-focus'

export interface MutateHolidayDrawerProps {
  visible: boolean
  data?: HolidayState
  onClose: () => void
}

const EMPTY_FORM_DATA: IHolidayInfo = {
  name: ''
}

export const MutateHolidayDrawer: FC<MutateHolidayDrawerProps> = ({
  visible,
  data,
  onClose
}: MutateHolidayDrawerProps) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<IHolidayInfo>(EMPTY_FORM_DATA)
  const [focusRef, setFocus] = useFocus(true)
  const [errors, setErrors] = useState<Errors>()

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  useEffect(() => {
    if (data) {
      setFormData({ name: data.name })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => setFormData(formData => ({ ...formData, ...updates })),
    []
  )

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      if (data) {
        result = await dispatch(updateMaster('holiday', data.id, mapHolidayStateToHolidayInfo(data), formData))
      } else {
        result = await dispatch(addMaster('holiday', formData))
      }
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data, formData, onClose])

  const handleDelete = useCallback(
    (master: HolidayState | undefined) => {
      if (master) {
        const { id, name } = master
        confirm({
          title: `Delete holiday`,
          content: `Do you want to delete holiday record of "${name}"?`,
          onOk: async () => {
            const result: ActionResult | undefined = await dispatch(deleteMaster('holiday', id))
            if (result?.errors) {
              setErrors(result.errors)
            }

            if (!result?.errors) {
              typeof onClose === 'function' && onClose()
            }
          },
          okText: 'Delete',
          okType: 'danger'
        })
      }
    },
    [onClose]
  )

  return (
    <DrawerForm
      open={visible}
      title={data ? `Edit holiday` : `Add holiday`}
      onClose={onClose}
      confirmLoading={loading}
      width={500}
      showDelete={data ? true : false}
      onDelete={() => handleDelete(data)}
      formId="form-holiday"
    >
      <Form id="form-holiday" onFinish={handleOk}>
        <Row>
          <Col span={24}>
            <Form.Item label="Name" validateStatus={errors?.name ? 'error' : ''} help={errors?.name}>
              <Input
                ref={focusRef}
                value={formData.name}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ name: event.target.value })}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}
