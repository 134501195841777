import React, { CSSProperties, ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import confirm from 'antd/lib/modal/confirm'
import { RangeValue } from 'rc-picker/lib/interface.d'
import {
  Alert,
  Button,
  Form,
  Link,
  Input,
  Radio,
  RadioChangeEvent,
  Select,
  Skeleton,
  Space,
  Tooltip,
  UploadFile,
  Tag,
  Checkbox,
  CheckboxChangeEvent
} from '~/core-components'
import { Col, DownloadFileAuth, Row, SysOptions, UploadFileSelectorAuth } from '~/components'
import { useSysOptions } from '~/features/employee/hooks'
import {
  LveDuration,
  LveVisibleType,
  LveAttachment,
  LveAttachmentFileTypeAllowed,
  LveSysLeaveType,
  LveSysLeaveTypeType,
  LveUnit
} from '~/constants'
import { Errors, StoreState } from '~/types/store'
import { KeyValue } from '~/types/common'
import { dispatch } from '~/stores/store'
import { formatDateRange, formatNumberUnit, request, timeout, UnitType } from '~/utils'
import { ILeaveApply, LeaveRecordDtlState, LeaveGrantState, LeaveTypeState } from '../../../types'
import { fetchLeaveGrants, fetchLeaveGrantByLeaveRecord, unlinkLeaveGrant } from '../../../actions'
import {
  useEntitledLeaveType,
  useLeaveApplyFormInfo,
  useLeaveEntitlement,
  useLeaveRecordDtls,
  useLeaveTypesDict,
  useSysLeaveType,
  useSysLtFields
} from '../../../hooks'
import { selectLeaveGrants } from '../../../selectors'
import { LeaveRecordDtls } from './LeaveRecordDtls'
import { RelationshipField } from './fields/RelationshipField'
import { ReferenceNameField } from './fields/ReferenceNameField'
import './LeaveApplyForm.less'

interface LeaveApplyFormProps {
  id?: string
  leaveType?: LeaveTypeState
  formData: ILeaveApply
  availableDates?: LeaveRecordDtlState[]
  conflictDates?: LeaveRecordDtlState[]
  errors?: Errors
  readOnly?: boolean
  onChange: (formData: Partial<ILeaveApply>) => void
}

export const LEAVE_APPLY_EMPTY_FORM_DATA: ILeaveApply = {
  employeeId: '',
  leaveTypeId: '',
  leaveGrantId: '',
  startDate: '',
  endDate: '',
  startTime: '00:00',
  endTime: '00:00',
  startDuration: LveDuration.fullDay,
  endDuration: LveDuration.fullDay,
  referenceId: '',
  referenceDate: '',
  referenceName: '',
  referenceSource: '',
  notes: '',
  isShared: false,
  sharedQuantity: 0,
  takenDuration: 0,
  relationship: '',
  attachments: [],
  deletedAttachmentIds: []
}
const takenDurationStyle: CSSProperties = { width: 140 }
const sharedQuantityStyle: CSSProperties = { width: 140, marginLeft: 20 }

export const LeaveApplyForm: FC<LeaveApplyFormProps> = ({
  id,
  leaveType,
  formData,
  availableDates,
  conflictDates,
  errors,
  readOnly,
  onChange
}) => {
  const isNew = !id
  const leaveRecord = useSelector((state: StoreState) => state.leave.leaveRecords?.entities[id || ''])

  const [refDateDisabled, setRefDateDisabled] = useState(false)

  const leaveTypeId = leaveType?.id || ''
  const [leaveTypes] = useLeaveTypesDict()
  const sysLeaveTypeCode = leaveType?.sysLeaveTypeCode || ''
  const [sysLeaveType] = useSysLeaveType(sysLeaveTypeCode)
  const sysLeaveTypeType = sysLeaveType?.type || ''

  const isAdp = sysLeaveTypeCode === LveSysLeaveType.adp
  const isMat = sysLeaveTypeCode === LveSysLeaveType.mat
  const isPat = sysLeaveTypeCode === LveSysLeaveType.pat
  const isCom = sysLeaveTypeCode === LveSysLeaveType.com
  const isHourly = leaveType?.unit === LveUnit.hours

  const [sysLtFields, sysLtFieldsLoading] = useSysLtFields(sysLeaveTypeCode)
  const [leaveRecordDtls] = useLeaveRecordDtls(id)
  const [formInfo, formInfoLoading] = useLeaveApplyFormInfo(leaveTypeId, formData.employeeId)
  const grantLeaveTypeId = leaveType?.grantLtId || ''
  const leaveGrants = useSelector(selectLeaveGrants)(formData.employeeId)
  const currentLeaveGrants = leaveGrants.filter(lg => lg.leaveRecordId === id)
  const leaveGrantsLoading = useSelector((state: StoreState) => state.leave.leaveGrantsLoading[formData.employeeId])
  const [durationShort] = useSysOptions('lve_duration_short')

  const [entitledLeaveType] = useEntitledLeaveType(formData.employeeId, leaveTypeId, 'always')
  const relations = entitledLeaveType?.entPerTime?.map(p => p.relation)
  const entPerTime = entitledLeaveType?.entPerTime?.find(p => p.relation === formData.relationship)

  const [leaveEntitlement] = useLeaveEntitlement(formData.employeeId, leaveTypeId)
  const minPerTime = leaveEntitlement?.minPerTime
  const maxPerTime = leaveEntitlement?.maxPerTime

  const formInfoRefName = formInfo && formInfo['relation_type']
  const formInfoRefDate = formInfo && formInfo['ref_date_label']
  const formInfoRefDataDisabled = formInfoRefDate?.isDisabled || false

  const [isShownExpired, setIsShownExpired] = useState(false)

  const handleFetchGrantLeave = useCallback(() => {
    if (sysLeaveTypeType === LveSysLeaveTypeType.grant && leaveTypeId && formData.employeeId) {
      const hasBalance = !id
      let validPeriodAsOf = id ? formData.startDate : moment().format('YYYY-MM-DD')
      if (isShownExpired) validPeriodAsOf = moment(validPeriodAsOf).subtract(1, 'year').format('YYYY-MM-DD')
      dispatch(fetchLeaveGrants(leaveTypeId, formData.employeeId, hasBalance, validPeriodAsOf))
    }
  }, [leaveTypeId, sysLeaveTypeType, formData.employeeId, formData.startDate, id, isShownExpired])

  useEffect(() => {
    handleFetchGrantLeave()
  }, [handleFetchGrantLeave])

  useEffect(() => {
    if (grantLeaveTypeId && id) {
      dispatch(fetchLeaveGrantByLeaveRecord(formData.employeeId, id))
    }
  }, [grantLeaveTypeId, id, formData.employeeId])

  useEffect(() => {
    setRefDateDisabled(formInfoRefDataDisabled)
  }, [formInfoRefDataDisabled])

  const handleFormDataChange = useCallback(
    (updates: Partial<ILeaveApply>) => {
      typeof onChange === 'function' && onChange(updates)
    },
    [onChange]
  )

  const handleFileDownload = useCallback(async (file: UploadFile) => {
    if (file.url) {
      const { result, status } = await request('get', file.url, undefined, {
        responseType: 'blob',
        timeout
      })
      if (status) {
        let objectUrl = window.URL.createObjectURL(result)
        window.open(objectUrl, '_blank')
      }
    }
  }, [])

  const handleFileChange = useCallback(
    (files?: UploadFile[]) => {
      if (files && files.length > 0) {
        const updated = { attachments: files }
        typeof onChange === 'function' && onChange(updated)
      }
    },
    [onChange]
  )

  const handleFileRemove = useCallback(
    (file?: UploadFile) => {
      let deletedFileIds: string[] | undefined = formData.deletedAttachmentIds
      if (file) {
        const isNewFile = file instanceof File
        if (!isNewFile) deletedFileIds?.push(file.uid)

        const listAttachment = formData.attachments || []
        const updated = {
          attachments: listAttachment.filter(x => x.uid !== file.uid),
          ...(isNewFile ? {} : { deletedAttachmentIds: deletedFileIds })
        }
        typeof onChange === 'function' && onChange(updated)
      }
    },
    [formData.deletedAttachmentIds, formData.attachments, onChange]
  )

  const handleUnlinkLeaveGrant = useCallback(
    (leaveGrant: LeaveGrantState) => {
      confirm({
        title: 'Unlink leave grant',
        content: `Do you want to unlink from leave grant "${leaveTypes[leaveGrant.leaveTypeId]?.name}"?`,
        onOk: async () => {
          await dispatch(unlinkLeaveGrant(leaveGrant.employeeId, leaveGrant.id))
        },
        okText: 'Delete',
        okType: 'danger'
      })
    },
    [leaveTypes]
  )

  const isVisible = useCallback(
    (fieldName: string) => sysLtFields[fieldName]?.visibleType === LveVisibleType.basic,
    [sysLtFields]
  )

  const getTakenDurationEndDate = useCallback(
    (startDate: string, takenDuration: number, isShared: boolean, sharedQuantity: number) => {
      return moment(startDate)
        .add(takenDuration, 'week')
        .add(isShared && takenDuration === maxPerTime && sharedQuantity > 0 ? -1 * sharedQuantity : 0, 'week')
        .add(-1, 'day')
        .format('YYYY-MM-DD')
    },
    [maxPerTime]
  )

  if (!leaveTypeId) return null
  if (Object.keys(formInfo || {}).length === 0 && (formInfoLoading || sysLtFieldsLoading)) return <Skeleton active />

  return (
    <div className="leave-apply-form">
      {sysLeaveType?.type === LveSysLeaveTypeType.grant && (
        <Row>
          <Col span={24}>
            <Form.Item label={readOnly || !isNew ? 'Selected leave grant' : 'Select leave grant'}>
              {readOnly || !isNew ? (
                <>
                  {leaveGrants
                    .filter(lg => lg.id === formData.leaveGrantId)
                    .map(lg => (
                      <div key={lg.id}>
                        Balance:{' '}
                        {formatNumberUnit(lg.balance + (leaveRecord?.leaveTotal || 0), leaveType?.unit as UnitType)}{' '}
                        valid from {formatDateRange(lg.startDate, lg.endDate)}
                      </div>
                    ))}
                </>
              ) : (
                <Space>
                  <Select
                    style={{ width: 245 }}
                    loading={leaveGrantsLoading}
                    dropdownMatchSelectWidth={false}
                    value={formData.leaveGrantId}
                    onChange={leaveGrantId => handleFormDataChange({ leaveGrantId })}
                  >
                    {leaveGrants.map(lg => (
                      <Select.Option key={lg.id} value={lg.id}>
                        Balance:{' '}
                        {formatNumberUnit(lg.balance + (leaveRecord?.leaveTotal || 0), leaveType?.unit as UnitType)}{' '}
                        valid from {formatDateRange(lg.startDate, lg.endDate)}
                      </Select.Option>
                    ))}
                  </Select>
                  <Checkbox
                    checked={isShownExpired}
                    onChange={(event: CheckboxChangeEvent) => {
                      setIsShownExpired(event.target.checked)
                      handleFormDataChange({ leaveGrantId: '' })
                    }}
                  >
                    Show expired
                  </Checkbox>
                </Space>
              )}
            </Form.Item>
          </Col>
        </Row>
      )}
      {isCom && (
        <>
          <Row>
            <Col span={24}>
              <RelationshipField
                formData={formData}
                readOnly={readOnly}
                errors={errors}
                filterRelations={relations}
                onChange={updates =>
                  handleFormDataChange({ ...updates, referenceId: '', referenceSource: '', referenceName: '' })
                }
                helpText={
                  entPerTime?.entitlement && !readOnly
                    ? `Entitled for ${formatNumberUnit(entPerTime?.entitlement, entPerTime?.unit as UnitType)}`
                    : undefined
                }
              />
            </Col>
            <Col span={24}>
              <ReferenceNameField
                leaveTypeId={leaveTypeId}
                formInfo={formInfoRefName}
                formData={formData}
                readOnly={readOnly}
                errors={errors}
                onChange={handleFormDataChange}
              />
            </Col>
          </Row>
        </>
      )}
      {isPat && (
        <>
          <Row>
            <Col span={24}>
              <Form.Item label={`Paternity leave to be taken in continuous block of`}>
                {readOnly ? (
                  `${formatNumberUnit(formData.takenDuration, 'w')}`
                ) : (
                  <Select
                    allowClear={false}
                    style={takenDurationStyle}
                    value={formData.takenDuration}
                    onChange={(value: number) => {
                      let endDate = formData.startDate
                      if (formData.startDate && value > 0) {
                        endDate = moment(formData.startDate).add(value, 'week').add(-1, 'day').format('YYYY-MM-DD')
                      }
                      handleFormDataChange({ takenDuration: value, endDate })
                    }}
                  >
                    <Select.Option value={1}>1 week</Select.Option>
                    <Select.Option value={2}>2 weeks</Select.Option>
                    <Select.Option value={3}>3 weeks</Select.Option>
                    <Select.Option value={4}>4 weeks</Select.Option>
                    <Select.Option value={0}>None</Select.Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
      {(isAdp || isMat) && (
        <>
          <Row>
            <Col span={24}>
              <Form.Item
                label={`Are you sharing ${leaveType?.name.toLowerCase()} with your spouse as shared parental leave?`}
              >
                {readOnly ? (
                  formData.isShared ? (
                    `Yes, ${formatNumberUnit(formData.sharedQuantity, 'w')}`
                  ) : (
                    'No'
                  )
                ) : (
                  <Radio.Group
                    value={formData.isShared}
                    onChange={(event: RadioChangeEvent) => {
                      let endDate = formData.startDate
                      if (formData.startDate) {
                        endDate = getTakenDurationEndDate(
                          formData.startDate,
                          formData.takenDuration,
                          event.target.value,
                          formData.sharedQuantity
                        )
                      }
                      handleFormDataChange({
                        isShared: event.target.value,
                        sharedQuantity: event.target.value ? formData.sharedQuantity : 0,
                        endDate
                      })
                    }}
                  >
                    <Space direction="vertical">
                      <Radio value={true}>
                        <Space>
                          <span>Yes, how many weeks?</span>
                        </Space>
                      </Radio>
                      <Select
                        allowClear={false}
                        style={sharedQuantityStyle}
                        disabled={!formData.isShared}
                        value={formData.sharedQuantity}
                        onChange={(value: number) => {
                          let endDate = formData.startDate
                          if (formData.startDate) {
                            endDate = getTakenDurationEndDate(
                              formData.startDate,
                              formData.takenDuration,
                              formData.isShared,
                              value
                            )
                          }
                          handleFormDataChange({ sharedQuantity: value, endDate })
                        }}
                      >
                        <Select.Option value={1}>1 week</Select.Option>
                        <Select.Option value={2}>2 weeks</Select.Option>
                        <Select.Option value={3}>3 weeks</Select.Option>
                        <Select.Option value={4}>4 weeks</Select.Option>
                      </Select>
                      <Radio value={false}>No</Radio>
                    </Space>
                  </Radio.Group>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item label={`${leaveType?.name} to be taken in continuous block of`}>
                {readOnly ? (
                  `${formatNumberUnit(formData.takenDuration, 'w')}`
                ) : (
                  <Select
                    allowClear={false}
                    style={takenDurationStyle}
                    value={formData.takenDuration}
                    onChange={(value: number) => {
                      let endDate = formData.startDate
                      if (formData.startDate)
                        endDate = getTakenDurationEndDate(
                          formData.startDate,
                          value,
                          formData.isShared,
                          formData.sharedQuantity
                        )

                      handleFormDataChange({
                        takenDuration: value,
                        endDate
                      })
                    }}
                  >
                    {minPerTime && <Select.Option value={minPerTime}>{minPerTime} weeks</Select.Option>}
                    {maxPerTime && <Select.Option value={maxPerTime}>{maxPerTime} weeks</Select.Option>}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
      <Row hidden={isAdp || isMat || isPat || isHourly}>
        <Col span={24}>
          <Form.Item
            label="Dates"
            validateStatus={errors?.startDate || errors?.endDate ? 'error' : ''}
            help={errors?.startDate || errors?.endDate}
          >
            {readOnly ? (
              <>
                <Space>
                  {formatDateRange(formData.startDate, formData.endDate) || '-'}
                  {leaveRecordDtls.length === 1 && (
                    <>
                      {leaveRecordDtls.some(d =>
                        [LveDuration.firstHalf, LveDuration.secondHalf].includes(d.leaveDuration)
                      ) && <Tag type="original">{durationShort[leaveRecordDtls[0].leaveDuration]?.value}</Tag>}
                      {leaveRecordDtls.some(d => Boolean(d.payRunPeriod)) && (
                        <Tag type="original">
                          Deduction in {moment(leaveRecordDtls[0].payRunPeriod).format('MMM YYYY')}
                        </Tag>
                      )}
                    </>
                  )}
                </Space>
                {leaveRecordDtls.length > 1 && <LeaveRecordDtls leaveType={leaveType} dtls={leaveRecordDtls} />}
              </>
            ) : (
              <Input.DateRange
                value={[
                  formData.startDate ? moment(formData.startDate) : null,
                  formData.endDate ? moment(formData.endDate) : null
                ]}
                onCalendarChange={(dates: RangeValue<moment.Moment>) => {
                  const startDate = dates && dates[0] ? dates[0].format('YYYY-MM-DD') : ''
                  const endDate = dates && dates[1] ? dates[1].format('YYYY-MM-DD') : ''

                  if (
                    startDate !== null &&
                    endDate !== null &&
                    (startDate !== formData.startDate || endDate !== formData.endDate)
                  ) {
                    let updates: Partial<ILeaveApply> = { startDate, endDate }
                    if (startDate !== endDate) {
                      updates.startDuration = LveDuration.fullDay
                      updates.endDuration = LveDuration.fullDay
                    }

                    handleFormDataChange(updates)
                  }
                }}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row hidden={!(isAdp || isMat || isPat) || isHourly}>
        <Col span={24}>
          {readOnly ? (
            <Form.Item
              label="Dates"
              validateStatus={errors?.startDate || errors?.endDate ? 'error' : ''}
              help={errors?.startDate || errors?.endDate}
            >
              {formatDateRange(formData.startDate, formData.endDate) || '-'}
            </Form.Item>
          ) : (
            <Space align="center">
              <Form.Item
                label="Dates"
                validateStatus={errors?.startDate || errors?.endDate ? 'error' : ''}
                help={errors?.startDate || errors?.endDate}
              >
                <Input.Date
                  value={formData.startDate ? moment(formData.startDate) : undefined}
                  onChange={(value: moment.Moment | null) => {
                    const startDate = value?.format('YYYY-MM-DD') || ''
                    let endDate = startDate > formData.endDate ? startDate : formData.endDate || startDate

                    if (isAdp || isMat) {
                      endDate = getTakenDurationEndDate(
                        startDate,
                        formData.takenDuration,
                        formData.isShared,
                        formData.sharedQuantity
                      )
                    } else if (isPat && formData.takenDuration > 0) {
                      endDate = moment(startDate)
                        .add(formData.takenDuration, 'week')
                        .add(-1, 'day')
                        .format('YYYY-MM-DD')
                    }

                    handleFormDataChange({ startDate, endDate })
                  }}
                />
              </Form.Item>
              <span>to</span>
              <Form.Item label="&nbsp;" validateStatus={errors?.endDate ? 'error' : ''} help={errors?.endDate}>
                <Input.Date
                  disabled={isAdp || isMat || (isPat && formData.takenDuration > 0)}
                  value={formData.endDate ? moment(formData.endDate) : undefined}
                  onChange={(value: moment.Moment | null) =>
                    handleFormDataChange({ endDate: value?.format('YYYY-MM-DD') })
                  }
                />
              </Form.Item>
            </Space>
          )}
        </Col>
      </Row>
      {isHourly && (
        <>
          <Row>
            <Col span={24}>
              <Form.Item label="Date" validateStatus={errors?.startDate ? 'error' : ''} help={errors?.startDate}>
                {readOnly ? (
                  moment(formData.startDate).format('DD MMM YYYY') || '-'
                ) : (
                  <Input.Date
                    disabled={isAdp || isMat}
                    value={formData.startDate ? moment(formData.startDate) : undefined}
                    onChange={(value: moment.Moment | null) =>
                      handleFormDataChange({
                        startDate: value?.format('YYYY-MM-DD'),
                        endDate: value?.format('YYYY-MM-DD')
                      })
                    }
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={30}>
            <Col>
              <Form.Item label="Start time" validateStatus={errors?.startTime ? 'error' : ''} help={errors?.startTime}>
                {readOnly ? (
                  moment(formData.startTime, 'HH:mm').format('HH:mm') || '-'
                ) : (
                  <Input.Time
                    value={moment(formData.startTime, 'HH:mm')}
                    minuteStep={15}
                    allowClear={false}
                    onChange={(value: moment.Moment | null) =>
                      handleFormDataChange({ startTime: value?.format('HH:mm') })
                    }
                  />
                )}
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label="End time" validateStatus={errors?.endTime ? 'error' : ''} help={errors?.endTime}>
                {readOnly ? (
                  moment(formData.endTime, 'HH:mm').format('HH:mm') || '-'
                ) : (
                  <Input.Time
                    value={moment(formData.endTime, 'HH:mm')}
                    minuteStep={15}
                    allowClear={false}
                    onChange={(value: moment.Moment | null) =>
                      handleFormDataChange({ endTime: value?.format('HH:mm') })
                    }
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
      {isVisible('allow_half_day') && !isHourly && !readOnly && (
        <>
          <Row
            hidden={moment(formData.startDate).format('YYYY-MM-DD') !== moment(formData.endDate).format('YYYY-MM-DD')}
          >
            <Col span={24}>
              <Form.Item
                label="Duration"
                validateStatus={errors?.startDuration ? 'error' : ''}
                help={errors?.startDuration}
              >
                <SysOptions
                  type="lve_duration"
                  value={formData.startDuration}
                  onChange={(value: string) => handleFormDataChange({ startDuration: value })}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row
            hidden={moment(formData.startDate).format('YYYY-MM-DD') === moment(formData.endDate).format('YYYY-MM-DD')}
            gutter={30}
          >
            <Col span={12}>
              <Form.Item
                label="First day duration"
                validateStatus={errors?.startDuration ? 'error' : ''}
                help={errors?.startDuration}
              >
                <SysOptions
                  type="lve_duration"
                  value={formData.startDuration}
                  onFilter={(value: KeyValue | undefined) =>
                    [LveDuration.fullDay, LveDuration.secondHalf, formData.startDuration].includes(value?.key || '')
                  }
                  onChange={(value: string) => handleFormDataChange({ startDuration: value })}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Last day duration"
                validateStatus={errors?.endDuration ? 'error' : ''}
                help={errors?.endDuration}
              >
                <SysOptions
                  type="lve_duration"
                  value={formData.endDuration}
                  onFilter={(value: KeyValue | undefined) =>
                    [LveDuration.fullDay, LveDuration.firstHalf, formData.endDuration].includes(value?.key || '')
                  }
                  onChange={(value: string) => handleFormDataChange({ endDuration: value })}
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
      <Row hidden={isCom}>
        <Col span={24}>
          <ReferenceNameField
            leaveTypeId={leaveTypeId}
            formInfo={formInfoRefName}
            formData={formData}
            readOnly={readOnly}
            errors={errors}
            onChange={handleFormDataChange}
          />
        </Col>
      </Row>
      {formInfoRefDate && (
        <Row>
          <Col span={24}>
            <Form.Item
              label={formInfoRefDate?.label}
              validateStatus={errors?.referenceDate ? 'error' : ''}
              help={errors?.referenceDate}
            >
              {readOnly ? (
                moment(formData.referenceDate).format('DD MMM YYYY') || '-'
              ) : (
                <Input.Date
                  value={formData.referenceDate ? moment(formData.referenceDate) : undefined}
                  disabled={refDateDisabled}
                  onChange={(value: moment.Moment | null) =>
                    handleFormDataChange({ referenceDate: value?.format('YYYY-MM-DD') })
                  }
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      )}
      {leaveType?.attachment !== LveAttachment.notApplicable && (
        <Row>
          <Col span={24}>
            <Form.Item
              label="Attachment"
              validateStatus={errors?.attachments ? 'error' : ''}
              help={errors?.attachments}
            >
              {readOnly ? (
                (formData.attachments &&
                  formData.attachments.length > 0 &&
                  Object.values(formData.attachments)?.map(
                    att =>
                      att?.url && (
                        <div key={att.uid}>
                          <DownloadFileAuth url={att.url}>{att.name}</DownloadFileAuth>
                        </div>
                      )
                  )) ||
                '-'
              ) : (
                <UploadFileSelectorAuth
                  accept={LveAttachmentFileTypeAllowed.toString()}
                  fileList={formData.attachments ? formData.attachments : []}
                  multiple
                  onChange={handleFileChange}
                  onPreview={handleFileDownload}
                  onRemove={handleFileRemove}
                >
                  <Button size="small">choose file</Button>
                </UploadFileSelectorAuth>
              )}
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row>
        <Col span={24}>
          <Form.Item label="Notes" validateStatus={errors?.notes ? 'error' : ''} help={errors?.notes}>
            {readOnly ? (
              formData.notes || '-'
            ) : (
              <Input.TextArea
                rows={2}
                value={formData.notes}
                onChange={(value?: ChangeEvent<HTMLTextAreaElement>) =>
                  handleFormDataChange({ notes: value?.target.value })
                }
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row hidden={!availableDates || availableDates.length === 0}>
        <Col span={24}>
          <Alert
            className="leave-dates-verify"
            type="info"
            message="Verify the leave dates below and click save to confirm"
          />
          <LeaveRecordDtls leaveType={leaveType} dtls={availableDates} showQuantity />
        </Col>
      </Row>
      <Row hidden={!conflictDates || conflictDates.length === 0}>
        <Col span={24}>
          <div className="leave-conflict-title">Skipped due to existing leave within the requested dates</div>
          <LeaveRecordDtls color="secondary" leaveType={leaveType} dtls={conflictDates} showLeaveType />
        </Col>
      </Row>
      <Row hidden={!grantLeaveTypeId || currentLeaveGrants.length === 0}>
        <Col span={24}>
          <Alert
            type="info"
            message={
              <>
                {currentLeaveGrants.map(lg => (
                  <Space key={lg.id}>
                    <span>
                      Auto granted {leaveTypes[lg.leaveTypeId]?.name?.toLowerCase()} with validity period from{' '}
                      {formatDateRange(lg.startDate, lg.endDate)}
                    </span>
                    {!readOnly && (
                      <Tooltip title="Unlink from leave grant">
                        <Link onClick={() => handleUnlinkLeaveGrant(lg)}>unlink</Link>
                      </Tooltip>
                    )}
                  </Space>
                ))}
              </>
            }
          />
        </Col>
      </Row>
    </div>
  )
}
