import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiRestoreYtdCompany } from '../api/ytd-company.api'
import { setYtdCompany } from '../reducers'

export const restoreYtdCompany = (id: string): ThunkResult<void> => {
  return async dispatch => {
    if (!id) return

    const { status, result, errors, message, errorData } = await apiRestoreYtdCompany(id)

    if (status) {
      dispatch(setYtdCompany(result))
      showSuccess('Restored')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
