import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { EmEducationState, IEmEducation } from '../types'

const baseUrl = getBaseUrl('/employee')
export const EmEducationField = 'major' || 'institute'

export const apiGetEmEducations = (employeeId: string) =>
  request<EmEducationState[]>('get', `${baseUrl}/employee/${employeeId}/education`)

export const apiAddEmEducation = (employeeId: string, emEducation: IEmEducation) =>
  request<{ id: string }>('post', `${baseUrl}/employee/${employeeId}/education`, emEducation)

export const apiUpdateEmEducation = (employeeId: string, id: string, emEducation: Operation[]) =>
  request<void>('patch', `${baseUrl}/employee/${employeeId}/education/${id}`, emEducation)

export const apiDeleteEmEducation = (employeeId: string, id: string) =>
  request<void>('delete', `${baseUrl}/employee/${employeeId}/education/${id}`)

export const apiGetEmEducationField = (field: string) =>
  request<string[]>('get', `${baseUrl}/emeducation/distinct/${field}`)
