import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { SSPayslipFilterOption } from '../types'

const DEFAULT_OPTIONS: SSPayslipFilterOption[] = [
  {
    value: '3m',
    label: 'Last 3 months'
  },
  {
    value: '6m',
    label: 'Last 6 months'
  }
]

export const selectMyPayslipFilters = createSelector(
  (state: StoreState) => state.myPayroll.myPayslipYears,
  myPayslipYearsState => {
    let years: SSPayslipFilterOption[] = []

    Object.values(myPayslipYearsState.entities)
      ?.sort((a, b) => parseInt(b!.year) - parseInt(a!.year))
      .forEach(year => {
        years.push({
          value: year?.year || '',
          label: year?.year || ''
        })
      })

    return [...DEFAULT_OPTIONS, ...years]
  }
)
