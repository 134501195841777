import React, { forwardRef } from 'react'
import { default as AntDatePicker, RangePickerProps as AntRangePickerProps } from 'antd/lib/date-picker'
import 'antd/es/date-picker/style'

export type DateProps = AntRangePickerProps & {}

const { RangePicker: AntRangePicker } = AntDatePicker

const DateRangeInternal = (props: DateProps, ref: React.Ref<any>) => (
  <AntRangePicker
    ref={ref}
    allowClear={!props.inputReadOnly}
    picker="date"
    format="DD/MM/YYYY"
    popupStyle={props.inputReadOnly ? { display: 'none' } : {}}
    {...props}
  />
)

export const DateRange = forwardRef(DateRangeInternal)
