import { showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiExecuteClaimImport } from '../api/claim-import.api'

export const executeClaimImport = (payRunId: string): ThunkResult<void> => {
  return async () => {
    const { status, result, errors, message, errorData } = await apiExecuteClaimImport(payRunId)
    if (!status) {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors, result }
  }
}
