import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { EmLeaveBuddyState } from '../types'

export const selectEmLeaveBuddies = createSelector(
  (state: StoreState) => state.employee.emLeaveBuddies,
  emLeaveBuddies =>
    memoize((employeeId?: string) => {
      if (!employeeId) return []
      return Object.values(emLeaveBuddies[employeeId]?.entities || {}) as EmLeaveBuddyState[]
    })
)
