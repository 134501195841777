import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'

export const selectCurrentEmCompany = createSelector(
  (state: StoreState) => state.employee.emCompanies,
  emCompaniesState =>
    memoize(
      (employeeId?: string, id?: string) => {
        if (!employeeId || !id) return undefined

        return emCompaniesState[employeeId]?.entities[id]
      },
      (employeeId?: string, id?: string) => `${employeeId}|${id}`
    )
)

export const selectEmCompanies = createSelector(
  (state: StoreState) => state.employee.emCompanies,
  emCompaniesState =>
    memoize((employeeId?: string) => {
      if (!employeeId) return []

      return Object.values(emCompaniesState[employeeId]?.entities || {}).sort((a, b) => {
        const aStartDate = new Date(a!.startDate).getTime()
        const bStartDate = new Date(b!.startDate).getTime()
        return bStartDate - aStartDate
      })
    })
)
