import React, { FC, useCallback, useState } from 'react'
import { EmPreviewDrawer } from './EmPreviewDrawer'
import { Link } from '~/core-components'

export interface EmPreviewState {
  id: string
  name: string
  photoId: string
  description: string
  isResignee: boolean
}

export interface EmPreviewProps {
  totalEmployees: number
  onFetch: () => Promise<EmPreviewState[]>
}

interface DrawerState {
  visible: boolean
}

const DRAWER_STATE: DrawerState = { visible: false }

export const EmPreview: FC<EmPreviewProps> = ({ totalEmployees, onFetch }: EmPreviewProps) => {
  const [drawerState, setDrawerState] = useState<DrawerState>(DRAWER_STATE)

  const handleOpenDrawer = useCallback(() => {
    setDrawerState({ visible: true })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DRAWER_STATE)
  }, [])

  return (
    <div className="em-preview">
      {totalEmployees > 0 ? <Link onClick={handleOpenDrawer}>{totalEmployees}</Link> : totalEmployees}
      <EmPreviewDrawer {...drawerState} onFetch={onFetch} onClose={handleCloseDrawer} />
    </div>
  )
}
