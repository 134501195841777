import { showSuccess, showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { apiDeletePayTranImportBatch } from '../api/paytran-import.api'

export const deletePayTranImportBatch = (
  payRunId: string,
  employeeId: string,
  batchId: string,
  payItemId: string
): ThunkResult<void> => {
  return async (): Promise<ActionResult | undefined> => {
    if (!payRunId || !employeeId || !batchId) return

    const {
      status,
      result = {},
      errors,
      message,
      errorData
    } = await apiDeletePayTranImportBatch(payRunId, employeeId, batchId, payItemId)

    if (status) {
      showSuccess('Deleted')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
