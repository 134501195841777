import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchPayRuns } from '../actions'
import { PayRunState } from '../types'
import { Dictionary } from '@reduxjs/toolkit'

export const usePayRunsDict = (fetchStrategy: FetchStrategy = 'when-empty'): [Dictionary<PayRunState>, boolean] => {
  const payRuns = useSelector((state: StoreState) => state.payroll.payRuns.entities)
  const loading = useSelector((state: StoreState) => state.payroll.payRunsLoading)

  useEffect(() => {
    dispatch(fetchPayRuns({ strategy: fetchStrategy }))
  }, [fetchStrategy])

  return [payRuns, loading]
}
