import React, { ComponentType, FC } from 'react'
import { RouteComponentProps, RouteProps } from 'react-router-dom'
import { PrivateRoute } from './PrivateRoute'
import { PermissionGate } from '~/features/iam'

interface PermissionRouteProps extends RouteProps {
  sysPermissionId: string | string[]
}

export const PermissionRoute: FC<PermissionRouteProps> = ({ sysPermissionId, component, render, ...rest }) => {
  const renderFn =
    (Component?: ComponentType | ComponentType<any>, render?: (props: RouteComponentProps<any>) => React.ReactNode) =>
    (props: any) => {
      if (!!Component)
        return (
          <PermissionGate sysPermissionId={sysPermissionId}>
            <Component {...props} />
          </PermissionGate>
        )
      if (!!render) return <PermissionGate sysPermissionId={sysPermissionId}>{render(props)}</PermissionGate>
    }

  return <PrivateRoute {...rest} render={renderFn(component, render)} />
}
