import React, { FC, useState, useCallback, useEffect } from 'react'
import { Checkbox, CheckboxChangeEvent, Form } from '~/core-components'
import { Col, DrawerForm, EmSelect, Row } from '~/components'
import { dispatch } from '~/stores/store'
import { useFocus } from '~/hooks/use-focus'
import { ActionResult, Errors } from '~/types/store'
import { changeApproverLeavePendingApproval } from '../../../actions'
import { ILeavePendingChangeApprover } from '../../../types'
import { apiGetEmSelect } from '~/features/leave/api/employee.api'

export interface LeavePendingChangeApproverDrawerProps {
  leaveRecordIds: string[]
  visible: boolean
  onClose: () => void
}

const EMPTY_FORM_DATA: ILeavePendingChangeApprover = {
  leaveRecordIds: [],
  approverId: '',
  notifyEmail: false
}

export const LeavePendingChangeApproverDrawer: FC<LeavePendingChangeApproverDrawerProps> = ({
  leaveRecordIds,
  visible,
  onClose
}: LeavePendingChangeApproverDrawerProps) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<ILeavePendingChangeApprover>(EMPTY_FORM_DATA)
  const [focusRef, setFocus] = useFocus(true)
  const [errors, setErrors] = useState<Errors>()

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  useEffect(() => {
    if (visible) {
      setFormData({ ...EMPTY_FORM_DATA, leaveRecordIds })
    }
  }, [leaveRecordIds, visible])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      result = await dispatch(changeApproverLeavePendingApproval(formData))
    } finally {
      setLoading(false)
    }
    if (result?.errors) {
      setErrors(result.errors)
    }
    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_FORM_DATA)
    }
  }, [formData, onClose])

  const handleFetchEmployees = useCallback(async () => {
    const { status, result } = await apiGetEmSelect('active')
    if (status) {
      return result
    }
    return []
  }, [])

  return (
    <DrawerForm
      open={visible}
      title="Change approver"
      onClose={onClose}
      confirmLoading={loading}
      width={500}
      formId="form-leave-pending-change-approver"
    >
      <Form id="form-leave-pending-change-approver" onFinish={handleOk}>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Change current approver to"
              validateStatus={errors?.approverId ? 'error' : ''}
              help={errors?.approverId}
            >
              <EmSelect
                ref={focusRef}
                value={formData.approverId}
                onFetch={handleFetchEmployees}
                onChange={(approverId: string) => handleFormDataChange({ approverId })}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="">
              <Checkbox
                checked={formData.notifyEmail}
                onChange={(event: CheckboxChangeEvent) => handleFormDataChange({ notifyEmail: event.target.checked })}
              >
                Send email notification to new approver
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}
