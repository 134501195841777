import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { IXeroTenantCompanyMap, XeroTenantMapState } from '../types'

export const selectXeroFirstTenantMapped = createSelector(
  (state: StoreState) => state.payroll.xeroTenantMaps,
  (state: StoreState) => state.master.companies,
  (state: StoreState) => state.payroll.xeroTenants,
  (xeroTenantMapsState, companiesState, xeroTenantsState) => {
    const tenantMaps = Object.values(xeroTenantMapsState?.entities || {}) as XeroTenantMapState[]

    const mapped = tenantMaps
      .filter(t => !!t.tenantId)
      .map(tm => {
        const company = companiesState.entities[tm.companyId]
        const tenant = xeroTenantsState.entities[tm.tenantId]
        return {
          id: tm.id,
          companyId: tm.companyId,
          companyName: company?.name,
          tenantId: tm.tenantId,
          tenantName: tenant?.name
        } as IXeroTenantCompanyMap
      })

    return mapped.length > 0 ? mapped[0] : null
  }
)
