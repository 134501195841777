import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { CfConfigState } from '../types'

const baseUrl = getBaseUrl('/claim/cfconfig')

export const apiGetCfConfigs = () => request<CfConfigState[]>('get', baseUrl)

export const apiUpdateCfConfig = (code: string, cfConfig: Operation[]) =>
  request('patch', `${baseUrl}/${code}`, cfConfig)
