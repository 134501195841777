import { ThunkResult } from '~/types/store'
import { showSuccess, showError } from '~/utils'
import { apiDeleteShiftTe } from '../api/shift-te.api'
import { removeShiftTe } from '../reducers'

export const deleteShiftTe = (shiftId: string, id: string): ThunkResult<void> => {
  return async dispatch => {
    if (!shiftId || !id) return

    const { status, errors, message, errorData } = await apiDeleteShiftTe(shiftId, id)
    if (status) {
      dispatch(removeShiftTe({ shiftId, id }))
      showSuccess('Deleted')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }

    return { errors }
  }
}
