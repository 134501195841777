import { replaceWfActions, setWfActionsLoading } from '../reducers'
import { apiGetWfActions } from '../api/wf-action.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchWfActions = (
  wfEventId?: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!wfEventId) return

    const loading = getState().workflow.wfActionsLoading[wfEventId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = (getState().workflow.wfActions[wfEventId]?.ids?.length || 0) > 0
      if (hasData) return
    }

    try {
      dispatch(setWfActionsLoading({ wfEventId, loading: true }))
      const { result, status } = await apiGetWfActions(wfEventId)
      if (status) {
        dispatch(replaceWfActions({ wfEventId, data: result }))
      }
    } finally {
      dispatch(setWfActionsLoading({ wfEventId, loading: false }))
    }
  }
}
