import { IOfficeInfo, OfficeState } from './office.state'

export const mapOfficeStateToOfficeInfo = (office: OfficeState): IOfficeInfo => ({
  name: office.name,
  block: office.block,
  street: office.street,
  level: office.level,
  unit: office.unit,
  building: office.building,
  city: office.city,
  state: office.state,
  postalCode: office.postalCode,
  countryCode: office.countryCode,
  phone: office.phone,
  holidayId: office.holidayId,
  inactiveDate: office.inactiveDate
})
