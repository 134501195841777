import React, { CSSProperties, FC, useMemo } from 'react'
import moment from 'moment-timezone'
import { Form, SecondaryText, Space, UploadFile } from '~/core-components'
import { AmountDisplay, Col, Row, SubHeader, UploadFileSelectorAuth } from '~/components'
import {
  ClaimRecordCfJsonb,
  getInclusiveTaxLabel,
  useCfConfigsDict,
  useClaimType,
  useCurrentTaxRate
} from '~/features/claim'
import { ClaAttachment, ClaAttachmentFileTypeAllowed } from '~/constants'
import { getBaseUrl } from '~/utils'
import { SSClaimRecordAttachmentState, SSClaimTaskState } from '../../../types'

interface MyClaimTaskSingleFormProps {
  data: SSClaimTaskState
  attachments: SSClaimRecordAttachmentState[]
}

const baseUrl = getBaseUrl('/claim')
const taxStyle: CSSProperties = { fontWeight: 'normal', fontStyle: 'italic' }

export const MyClaimTaskSingleForm: FC<MyClaimTaskSingleFormProps> = ({ data, attachments }) => {
  const [claimType] = useClaimType(data?.claimTypeId || '', 'when-empty')
  const [cfConfigsDict, cfConfigsDictLoading] = useCfConfigsDict()
  const [taxRate] = useCurrentTaxRate(data?.expenseCurrencyCode || '', data?.expenseDate || '')
  const includeTaxLabel = getInclusiveTaxLabel(taxRate.currencyCode, 'Inclusive of')

  const files = useMemo(() => {
    return data
      ? attachments
          .filter(att => !att.claimRecordExpenseId)
          .map(
            att =>
              ({
                uid: att?.fileId || '',
                name: att?.fileName || '',
                size: att?.fileSize || 0,
                type: att?.fileFormat || '',
                url: `${baseUrl}/ssclaimrecord/${data.id}/attachment/${att?.id}/downloadfile`
              } as UploadFile)
          )
      : []
  }, [data, attachments])

  return (
    <>
      {claimType?.attachment !== ClaAttachment.notApplicable && (
        <Row>
          <Col span={24}>
            <Form.Item label="Uploaded receipt(s)">
              {files.length === 0 ? (
                '-'
              ) : (
                <UploadFileSelectorAuth
                  accept={ClaAttachmentFileTypeAllowed.toString()}
                  fileList={files}
                  listType="picture-card"
                  disabled={true}
                  onChange={() => {}}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row>
        <Col span={12}>
          <Form.Item label="Receipt no.">{data.receiptNo || '-'}</Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Expense date">
            {data.expenseDate ? moment(data.expenseDate).format('DD MMM YYYY') : '-'}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item
            label={
              <Space>
                <span>Expense amount</span>
                {data.isTaxable && <i>({includeTaxLabel})</i>}
              </Space>
            }
          >
            <AmountDisplay symbol={data.expenseCurrencyCode} value={data.expenseAmount} />
          </Form.Item>
        </Col>
        <Col
          span={12}
          hidden={
            !claimType?.currencyCode ||
            !data.expenseCurrencyCode ||
            claimType?.currencyCode === data.expenseCurrencyCode
          }
        >
          <Form.Item label="Exchange rate">
            {
              <Space>
                <span style={{ fontSize: 12 }}>{claimType?.currencyCode.toUpperCase()}</span>
                {data.exchangeRate}
                {data.exchangeUnit > 1 && <SecondaryText size="small">/ {data.exchangeUnit}</SecondaryText>}
              </Space>
            }
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item label="Vendor / Provider">{data.providerName || '-'}</Form.Item>
        </Col>
      </Row>
      <ClaimRecordCfJsonb
        ctCustomField={claimType?.customField}
        cfJsonb={data.cfJsonb}
        cfConfigsDict={cfConfigsDict}
        loading={cfConfigsDictLoading}
        readOnly={true}
      />
      <Row>
        <Col span={24}>
          <Form.Item label="Notes">{data.notes || '-'}</Form.Item>
        </Col>
      </Row>
      <Row className="claim-record-form__claim-amount">
        <Col span={24}>
          <SubHeader type="primary">
            <Row>
              <Col flex="auto">Claim amount</Col>
              <Col flex="none">
                <Space direction="vertical" align="end" size={0}>
                  <AmountDisplay symbol={claimType?.currencyCode} value={data.claimAmount} />
                  {data.isTaxable && (
                    <SecondaryText size="small" style={taxStyle}>
                      {`${includeTaxLabel} `}
                      <AmountDisplay symbol={claimType?.currencyCode} value={data.claimAmountTax} />
                    </SecondaryText>
                  )}
                </Space>
              </Col>
            </Row>
          </SubHeader>
        </Col>
      </Row>
    </>
  )
}
