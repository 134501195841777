import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchMyTenants } from '../actions'
import { MyTenantState } from '../types'

const activeTenantCode = localStorage.getItem('tenant')

export const useMyTenant = (fetchStrategy: FetchStrategy = 'always'): [MyTenantState | undefined, boolean] => {
  const myTenant = useSelector((state: StoreState) => state.iam.myTenants.entities[activeTenantCode || ''])
  const loading = useSelector((state: StoreState) => state.iam.myTenantsLoading)

  useEffect(() => {
    dispatch(fetchMyTenants({ strategy: fetchStrategy }))
  }, [fetchStrategy])

  return [myTenant, loading]
}
