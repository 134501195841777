import React, { CSSProperties, FC } from 'react'
import { Col, Row } from '~/components'
import { WorkStatusIconType, WorkStatusIcon } from './WorkStatusIcon'

interface WorkStatusProps {
  category: 'label' | 'status'
  data?: string[]
}

const dowStyle: CSSProperties = { textAlign: 'center', marginBottom: 5 }
export const DowWorkStatus: FC<WorkStatusProps> = ({
  category,
  data = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
}) => (
  <Row style={dowStyle}>
    {data.map((d, index) => (
      <Col key={index} flex="40px">
        {category === 'status' ? <WorkStatusIcon workStatus={d as WorkStatusIconType} /> : d}
      </Col>
    ))}
  </Row>
)
