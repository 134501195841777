import React, { FC, useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { ColumnsType, Link, Table } from '~/core-components'
import { DrawerForm } from '~/components'
import { fetchCompanies } from '~/features/master'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { selectEmHires } from '../../../selectors'
import { fetchEmHires } from '../../../actions'
import { EmHireState } from '../../../types'

export interface EmHireProps {
  id: string
  visible: boolean
  onClose: (employeeId?: string) => void
}

export const SelectEmHireDrawer: FC<EmHireProps> = ({ id, visible, onClose }) => {
  const emHires = useSelector(selectEmHires)(id)
  const emHiresLoading = useSelector((state: StoreState) => state.employee.emHiresLoading[id])
  const companies = useSelector((state: StoreState) => state.master.companies?.entities || {})

  useEffect(() => {
    dispatch(fetchCompanies({ strategy: 'when-empty' }))
  }, [])

  useEffect(() => {
    dispatch(fetchEmHires(id, { strategy: 'when-empty' }))
  }, [id])

  const handleOk = useCallback(
    (employeeId: string) => {
      typeof onClose === 'function' && onClose(employeeId)
    },
    [onClose]
  )

  const handleCloseDrawer = useCallback(() => {
    typeof onClose === 'function' && onClose()
  }, [onClose])

  const columns: ColumnsType<EmHireState> = [
    {
      title: 'Hire date',
      key: 'hireDate',
      dataIndex: 'hireDate',
      render: (value: string) => value && moment(value).format('DD MMM YYYY')
    },
    {
      title: 'End of employment',
      key: 'employmentEndDate',
      dataIndex: 'employmentEndDate',
      render: (value: string) => (value ? moment(value).format('DD MMM YYYY') : 'Present')
    },
    {
      title: 'Company',
      key: 'companyId',
      dataIndex: 'companyId',
      render: (value: string) => companies[value]?.name || ''
    },
    {
      key: 'action',
      align: 'right',
      width: 50,
      render: (value: string, record) =>
        id !== record.id && (
          <Link size="small" onClick={() => handleOk(record.id)}>
            select
          </Link>
        )
    }
  ]

  return (
    <DrawerForm open={visible} title="Select which hire" onClose={handleCloseDrawer} width={600} okText="">
      <Table rowKey="id" dataSource={emHires} columns={columns} loading={emHiresLoading} pagination={false}></Table>
    </DrawerForm>
  )
}
