import React, { forwardRef, useState } from 'react'
import { default as AntTimePicker, TimePickerProps as AntTimePickerProps } from 'antd/lib/time-picker'
import classNames from 'classnames'
import 'antd/es/time-picker/style'
import './Time.less'

export type TimeProps = AntTimePickerProps & {}

const DEFAULT_FORMAT = 'HH:mm'
const EDIT_FORMAT = 'HHmm'

const TimeInternal = ({ className, ...props }: TimeProps, ref: React.Ref<any>) => {
  const classes = classNames('ant-time-picker', { [`${className}`]: className })
  const [format, setFormat] = useState(DEFAULT_FORMAT)

  return (
    <AntTimePicker
      ref={ref}
      format={format}
      showNow={false}
      popupStyle={props.inputReadOnly ? { display: 'none' } : {}}
      className={classes}
      placeholder="Time"
      suffixIcon={null}
      onOpenChange={open => !props.inputReadOnly && setFormat(open ? EDIT_FORMAT : DEFAULT_FORMAT)}
      {...props}
      allowClear={props.allowClear && !props.inputReadOnly}
    />
  )
}

export const Time = forwardRef(TimeInternal)
