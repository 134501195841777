import { createSelector, Dictionary } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { YtdSubmissionIr8aState } from '../types'

export const selectIr8aViewData = createSelector(
  (state: StoreState) => state.tax.ytdSubmissionIr8aViews,
  ytdSubmissionIr8aViewsState =>
    memoize((ytdSubmissionId: string): Dictionary<YtdSubmissionIr8aState> => {
      for (const viewId of ytdSubmissionIr8aViewsState?.ids) {
        const ir8aView = ytdSubmissionIr8aViewsState?.entities[viewId]
        const found = ir8aView?.data.some(r => r.ytdSubmissionId === ytdSubmissionId)
        if (found && ir8aView) {
          return Object.assign({}, ...ir8aView.data.map(r => ({ [r.id]: r })))
        }
      }
      return {}
    })
)
