import React, { FC, useState, useCallback, useEffect, useMemo, ChangeEvent } from 'react'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment-timezone'
import confirm from 'antd/lib/modal/confirm'
import { Checkbox, CheckboxChangeEvent, Form, Input } from '~/core-components'
import { Col, DrawerForm, Row } from '~/components'
import { useFocus } from '~/hooks'
import { isInactive } from '~/utils'
import { ActionResult, Errors } from '~/types/store'
import { dispatch } from '~/stores/store'
import { addDailyPolicy, deleteDailyPolicy, updateDailyPolicy } from '../../../actions'
import { DailyPolicyState } from '../../../types'

export type DailyPolicySelectAction = 'add' | 'update' | 'delete' | 'cancel'

interface MutateDailyPolicyDrawerProps {
  visible: boolean
  data?: DailyPolicyState
  onClose: (action: DailyPolicySelectAction, dailyPolicyId?: string) => void
}

interface FormData extends DailyPolicyState {}

const EMPTY_FORM_DATA: FormData = {
  id: '',
  name: ''
}

export const MutateDailyPolicyDrawer: FC<MutateDailyPolicyDrawerProps> = ({ visible, data, onClose }) => {
  const formId = useMemo(() => `form-daily-policy-${uuidv4()}`, [])
  const [saving, setSaving] = useState(false)
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)
  const [focusRef, setFocus] = useFocus(true)
  const [errors, setErrors] = useState<Errors>()

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  useEffect(() => {
    if (data) {
      const { id, name, inactiveDate } = data
      setFormData({
        id,
        name,
        inactiveDate
      })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setErrors(undefined)
    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setSaving(true)
    try {
      if (data) {
        result = await dispatch(updateDailyPolicy(formData.id, data, formData))
      } else {
        result = await dispatch(addDailyPolicy(formData))
      }
    } finally {
      setSaving(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose(data ? 'update' : 'add', result?.result?.id)
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data, formData, onClose])

  const handleDelete = useCallback(
    (row: DailyPolicyState | undefined) => {
      if (row) {
        const { id, name } = row
        confirm({
          title: 'Delete policy',
          content: `Do you want to delete this policy "${name}"?`,
          onOk: async () => {
            const result: ActionResult | undefined = await dispatch(deleteDailyPolicy(id))
            if (result?.errors) {
              setErrors(result.errors)
            }
            if (!result?.errors) {
              typeof onClose === 'function' && onClose('delete', id)
            }
          },
          okText: 'Delete',
          okType: 'danger'
        })
      }
    },
    [onClose]
  )

  return (
    <DrawerForm
      open={visible}
      title={data ? 'Edit policy' : 'Add policy'}
      onClose={() => onClose('cancel')}
      confirmLoading={saving}
      width={500}
      className="mutate-daily-policy"
      showDelete={data ? true : false}
      onDelete={() => handleDelete(data)}
      formId={formId}
    >
      <Form id={formId} onFinish={handleOk}>
        <Row gutter={30}>
          <Col flex="auto">
            <Form.Item label="Name" validateStatus={errors?.name ? 'error' : ''} help={errors?.name}>
              <Input
                ref={focusRef}
                value={formData.name}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ name: event.target.value })}
              />
            </Form.Item>
          </Col>
          <Col flex="none" hidden={!data}>
            <Form.Item
              label="Inactive"
              validateStatus={errors?.inactiveDate ? 'error' : ''}
              help={errors?.inactiveDate}
            >
              <Checkbox
                checked={isInactive(formData.inactiveDate)}
                onChange={(event: CheckboxChangeEvent) =>
                  handleFormDataChange({ inactiveDate: event.target.checked ? moment().format('YYYY-MM-DD') : '' })
                }
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}
