import React, { FC, useCallback, useEffect, useState } from 'react'
import { Button, Tooltip } from '~/core-components'
import { dispatch } from '~/stores/store'
import { generatePayslipPdf, isAnyPayslipsOutdated } from '../../actions'

export interface PayslipRefreshButtonProps {
  payRunId: string
  employeeId: string
  onDone?: () => void
}

export const PayslipRefreshButton: FC<PayslipRefreshButtonProps> = ({ payRunId, employeeId, onDone }) => {
  const [generating, setGenerating] = useState(false)

  useEffect(() => {
    if (payRunId && !generating) {
      dispatch(isAnyPayslipsOutdated(payRunId))
    }
  }, [payRunId, generating])

  const handleRefreshClick = useCallback(async () => {
    try {
      setGenerating(true)
      await dispatch(generatePayslipPdf(payRunId, [employeeId]))
      typeof onDone === 'function' && onDone()
    } finally {
      setGenerating(false)
    }
  }, [payRunId, employeeId, onDone])

  return (
    <Tooltip title="Refresh payslip">
      <Button icon={<i className="fal fa-refresh" />} onClick={handleRefreshClick} loading={generating} />
    </Tooltip>
  )
}
