import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import confirm from 'antd/lib/modal/confirm'
import { LoadingOutlined } from '@ant-design/icons'
import { Form, Spin } from '~/core-components'
import { DrawerForm, ErrorDisplay } from '~/components'
import { fetchFormulas } from '~/features/formula'
import { fetchPayItem, selectPayItemById } from '~/features/master'
import { EmPublicPerson } from '~/features/employee'
import { formatMoney } from '~/utils'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors, StoreState } from '~/types/store'
import { selectPayTranByPayItem, selectPayTranEntries } from '../../selectors'
import { selectPayRunById, selectPayItemMappingById } from '../../reducers'
import { IPayTranEntryInfo } from '../../types'
import {
  deletePayTranEntryBatch,
  fetchPayItemMapping,
  fetchPayTranEntryAdjustment,
  updatePayTranEntryBatch
} from '../../actions'
import { MutatePayTranEntryForm, PayTranEntryForm, EMPTY_PAY_TRAN_ENTRY_FORM_DATA } from './MutatePayTranEntryForm'
import { emptyGuid } from '~/constants'
import './EditPayTranAdjDrawer.less'

export interface EditPayTranAdjDrawerProps {
  visible: boolean
  id: string
  payRunId: string
  employeeId: string
  originalAmount: number
  adjustmentRefCode: string
  onClose: (action: 'save' | 'cancel') => void
}

const noRateAdjustments = ['sdl_adj', 'fwl_adj', 'cpf_em_o_adj', 'cpf_em_a_adj', 'cpf_er_o_adj', 'cpf_er_a_adj']

export const EditPayTranAdjDrawer: FC<EditPayTranAdjDrawerProps> = ({
  visible,
  id,
  payRunId,
  employeeId,
  originalAmount,
  adjustmentRefCode,
  onClose
}) => {
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<Errors>()
  const [resetForm, setResetForm] = useState(0)
  const [entryFormData, setEntryFormData] = useState<PayTranEntryForm>(EMPTY_PAY_TRAN_ENTRY_FORM_DATA)

  const payRun = useSelector((state: StoreState) => selectPayRunById(state, payRunId))
  const targetPayItem = useSelector((state: StoreState) => selectPayItemMappingById(state, adjustmentRefCode))
  const targetPayItemId = targetPayItem?.payItemId || ''
  const targetPayTran = useSelector(selectPayTranByPayItem)(payRunId, employeeId, targetPayItemId)
  const targetBatchId = targetPayTran?.batchId || emptyGuid
  const payItem = useSelector((state: StoreState) => selectPayItemById(state, targetPayItemId))
  const payTranEntries = useSelector(selectPayTranEntries)(targetBatchId)
  const payTranEntryLoading = useSelector((state: StoreState) => state.payroll.payTranEntriesLoading[targetBatchId])
  const payItemMappingLoading = useSelector((state: StoreState) => state.payroll.payItemMappingsLoading)

  useEffect(() => {
    dispatch(fetchFormulas({ strategy: 'when-empty' }))
  }, [])

  useEffect(() => {
    dispatch(fetchPayItem(targetPayItemId, 'sg'))
  }, [targetPayItemId])

  useEffect(() => {
    if (adjustmentRefCode) {
      dispatch(fetchPayItemMapping(adjustmentRefCode, { strategy: 'when-empty' }))
    }
  }, [adjustmentRefCode])

  useEffect(() => {
    setErrors(undefined)
  }, [visible])

  useEffect(() => {
    dispatch(fetchPayTranEntryAdjustment(targetBatchId, payRunId, employeeId, targetPayItemId))
  }, [targetBatchId, payRunId, employeeId, targetPayItemId])

  useEffect(() => {
    if (payTranEntries && (payTranEntries.length || 0) > 0) {
      const { startDate, endDate, rate, isSysSegment } = payTranEntries[0]
      const byDates = startDate === endDate
      const trans = payTranEntries.map(({ startDate: date, quantity }) => ({ date, quantity }))
      setEntryFormData({ payItemId: targetPayItemId, byDates, trans, rate, isSysSegment })
    }
  }, [payTranEntries, targetPayItemId])

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      const payRunStart = payRun?.startDate || ''
      const payRunEnd = payRun?.endDate || ''
      const requests = mapPayTranEntryFormToPayTranEntryInfoRequests(entryFormData, payRunStart, payRunEnd)
      result = await dispatch(updatePayTranEntryBatch(payRunId, employeeId, targetBatchId, requests))
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose('save')
      setEntryFormData(EMPTY_PAY_TRAN_ENTRY_FORM_DATA)
      setResetForm(resetForm + 1)
    }
  }, [entryFormData, targetBatchId, payRunId, employeeId, payRun, onClose, resetForm])

  const handleDelete = useCallback(() => {
    confirm({
      title: 'Delete',
      content: 'Do you want to delete?',
      onOk: async () => {
        const result: ActionResult | undefined = await dispatch(
          deletePayTranEntryBatch(payRunId, employeeId, targetBatchId)
        )

        if (result?.errors) {
          setErrors(result.errors)
        }

        if (!result?.errors) {
          typeof onClose === 'function' && onClose('save')
        }
      },
      okText: 'Delete',
      okType: 'danger'
    })
  }, [payRunId, employeeId, targetBatchId, onClose])

  const handleCloseDrawer = useCallback(() => {
    typeof onClose === 'function' && onClose('cancel')
  }, [onClose])

  return (
    <DrawerForm
      open={visible}
      title="Edit payroll item"
      onClose={handleCloseDrawer}
      confirmLoading={loading}
      width={500}
      showDelete={targetBatchId !== emptyGuid}
      onDelete={handleDelete}
      className="edit-paytranadj-drawer"
      formId={`form-edit-paytranadj-${id}`}
    >
      <Form id={`form-edit-paytranadj-${id}`} onFinish={handleOk}>
        <Form.Item label="">
          <EmPublicPerson id={employeeId} size={32} />
        </Form.Item>
        <Form.Item label="Payroll item">
          <div>{payItem?.name || ''}</div>
        </Form.Item>
        <Form.Item label="Original amount">
          <div>{formatMoney(originalAmount - (targetPayTran?.amount || 0), 2)}</div>
        </Form.Item>
        <ErrorDisplay keys={['payRunId']} errors={errors} />
        {payTranEntryLoading || payItemMappingLoading ? (
          <Spin indicator={<LoadingOutlined spin />} />
        ) : (
          <>
            <div className="edit-paytranadj-drawer__subtitle">Adjustment (enter the additional amount)</div>
            <MutatePayTranEntryForm
              data={entryFormData}
              defaultPickerDate={payRun?.startDate}
              rateHidden={noRateAdjustments.includes(adjustmentRefCode)}
              errors={errors}
              resetForm={resetForm}
              onFormChange={data => setEntryFormData({ ...entryFormData, ...data })}
            />
          </>
        )}
      </Form>
    </DrawerForm>
  )
}

const mapPayTranEntryFormToPayTranEntryInfoRequests = (
  form: PayTranEntryForm,
  startDate: string,
  endDate: string
): IPayTranEntryInfo[] => {
  const result: IPayTranEntryInfo[] = []
  const { byDates, trans, payItemId, rate, isSysSegment } = form

  if (byDates) {
    for (const tran of trans) {
      const { date, quantity } = tran
      if (quantity > 0 && moment(date).isValid()) {
        result.push({ payItemId, startDate: date, endDate: date, quantity, rate, isSysSegment })
      }
    }
  } else {
    const quantity = trans.length > 0 ? trans[0].quantity : 0
    result.push({ payItemId, startDate, endDate, quantity, rate, isSysSegment })
  }
  return result
}
