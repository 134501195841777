import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { setIsAnyPayslipsOutdated, setPayrollLoading } from '../reducers'
import { apiProcessPayroll } from '../api/payroll.api'
import { processPayWarnings } from './process-paywarnings'

export const processPayroll = (
  payRunId: string,
  employeeIds: string[] = [],
  employeeScope: 'existing' | 'all' = 'existing'
): ThunkResult<void> => {
  return async dispatch => {
    if (!payRunId) return

    try {
      dispatch(setPayrollLoading(true))
      const { status, errors, message, errorData } = await apiProcessPayroll(payRunId, employeeScope, employeeIds)
      if (status) {
        showSuccess('Recalculated')
        dispatch(processPayWarnings(payRunId))
        dispatch(setIsAnyPayslipsOutdated({ payRunId, data: true }))
      } else {
        if (Object.keys(errors).length === 0) {
          showError(message, errorData)
        }
      }
    } finally {
      dispatch(setPayrollLoading(false))
    }
  }
}
