import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { apiUpdateEmployeePayment } from '../api/employee.api'
import { EmployeeState, IEmployeePayment } from '../types'
import { setEmployee } from '../reducers'
import { PayMethod } from '~/constants'

export const updateEmployeePayment = <T extends IEmployeePayment>(
  id: string,
  before: T,
  request: T
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const after = { ...before, ...request } as T
    const patch = generateJsonPatch<T>(before, after)
    const { status, errors, message, errorData } = await apiUpdateEmployeePayment(id, patch)

    if (status) {
      const employee = getState().employee.employees.entities[id] as EmployeeState
      if (after.payMethod !== PayMethod.bank) {
        after.bankCode = ''
        after.bankBranchCode = ''
        after.bankAccountNo = ''
        after.bankAccountName = ''
        after.bankCode2 = ''
        after.bankBranchCode2 = ''
        after.bankAccountNo2 = ''
        after.bankAccountName2 = ''
        after.bankAllocation = ''
        after.bankAmount2 = 0
        after.bankPercentage2 = 0
      }
      dispatch(setEmployee({ ...employee, ...after }))
      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
