import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { SSRealTimeAttendanceState } from '../../types'

export const selectRealTimeAttendance = createSelector(
  (state: StoreState) => state.my.realTimeAttendance,
  realTimeAttendanceState =>
    memoize(
      (locationId?: string): SSRealTimeAttendanceState[] =>
        Object.values(realTimeAttendanceState[locationId || '']?.entities || {}) as SSRealTimeAttendanceState[]
    )
)
