import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { useSelector } from 'react-redux'
import { TAX_ROUTES } from '~/routes/routes'
import { LoadingOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import { Person, SearchInput } from '~/components'
import { Button, Card, ColumnType, ColumnsType, Dropdown, Form, Link, Space, Table, Tooltip } from '~/core-components'
import {
  Screen,
  updateViewCriteria,
  useFirstView,
  useViewSchema,
  ViewCriteria,
  ViewCriteriaSimple,
  ViewSelectionButton
} from '~/features/selection'
import { EmSelectionDrawer } from '~/features/employee'
import { usePermissionGate } from '~/features/iam'
import { Permission, PermissionAction, Ir21SubmissionStatus } from '~/constants'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { Pagination } from '~/types/common'
import { formatYearMonth, formatMonth, formatMoney, getBaseUrl } from '~/utils'
import { refetchIr21SubmissionA1Views } from '../../../reducers'
import {
  addIr21A1Draft,
  fetchIr21A1EmSelections,
  fetchIr21A1NewEmSelections,
  fetchIr21SubmissionA1View,
  lockIr21Draft,
  processIr21A1,
  unlockIr21Draft
} from '../../../actions'
import { selectIr21ViewData } from '../../../selectors'
import { Ir21SubmissionState, Ir21SubmissionA1State } from '../../../types'
import { useIr21SubmissionA1View } from '~/features/tax/hooks'
import './Ir21A1Records.less'

interface Ir21A1RecordsProps {
  ir21Submission?: Ir21SubmissionState
}

interface EmSelectionDrawerState {
  visible: boolean
}

const SCREEN_CODE: Screen = 'ir21_a1'
const PAGE_SIZE_OPTIONS = ['20', '50', '100']
const EM_SELECTION_DRAWER_STATE: EmSelectionDrawerState = { visible: false }

const nameColWidth = 250
const amountColWidth = 200
const defaultColWidth = 150
const paginationStyle: CSSProperties = { marginRight: 20 }
const baseUrl = getBaseUrl('/filestore')

export const Ir21A1Records: FC<Ir21A1RecordsProps> = ({ ir21Submission }) => {
  const ir21SubmissionId = ir21Submission?.id || ''
  const selection = useSelector((state: StoreState) => state.selection.sysSelectionFields[SCREEN_CODE])
  const [view, viewLoading] = useFirstView(SCREEN_CODE)
  const viewId = view?.id || ''
  const [schema] = useViewSchema(SCREEN_CODE, viewId)
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(20)
  const [search, setSearch] = useState<string>('')
  const [data, dataLoading] = useIr21SubmissionA1View(ir21SubmissionId, viewId, page, pageSize, search, 'always')
  const ir21Dict = useSelector(selectIr21ViewData)(ir21SubmissionId)

  const [locking, setLocking] = useState<string>()
  const refetch = useSelector((state: StoreState) => state.tax.ir21SubmissionA1ViewsRefetch)
  const canModify = usePermissionGate(Permission.ytd, PermissionAction.Modify)
  const expanded = useSelector((state: StoreState) => state.tax.ir21SubmissionExpanded)
  const processing = useSelector((state: StoreState) => state.tax.ir21A1Processing)

  const [recalculateDrawerState, setRecalculateDrawerState] =
    useState<EmSelectionDrawerState>(EM_SELECTION_DRAWER_STATE)
  const [addEmployeeDrawerState, setAddEmployeeDrawerState] =
    useState<EmSelectionDrawerState>(EM_SELECTION_DRAWER_STATE)

  useEffect(() => {
    if (viewId && ir21SubmissionId && !processing) {
      dispatch(
        fetchIr21SubmissionA1View(ir21SubmissionId, viewId, { offset: pageSize * (page - 1), limit: pageSize }, search)
      )
    }
  }, [viewId, ir21SubmissionId, page, pageSize, search, processing, refetch])

  const tableWidth =
    (schema?.selection.reduce(
      (sum, f) => (sum += selection?.entities[f.selectionFieldId]?.width || defaultColWidth),
      0
    ) || 0) +
    nameColWidth +
    1 * amountColWidth

  const columns: ColumnsType<Ir21SubmissionA1State> = [
    {
      title: 'Name',
      key: 'employeeTaxNo',
      dataIndex: 'employeeTaxNo',
      fixed: 'left',
      className: 'first-col',
      width: nameColWidth,
      render: (value: string, record) => (
        <Person
          name={record.employeeName}
          description={!expanded && value}
          photo={record.photoId && `${baseUrl}/file/${record.photoId}/thumbnailphoto/${expanded ? 18 : 36}`}
          // photo={
          //   record.employeeId &&
          //   `/employee/employee/${record.employeeId}/avatar/${expanded ? 18 : 36}?photo_id=${record.photoId}`
          // }
          // path={TAX_ROUTES.ir21a1}
          openAsNewTab={false}
          path={TAX_ROUTES.ir21FormsSubmission
            .replace(':id', ir21SubmissionId)
            .replace(':employeeTaxNo', value)
            .replace(':tab?', 'a1')}
          size={expanded ? 18 : 36}
        />
      )
    }
  ]

  if (canModify && ir21Submission?.status === Ir21SubmissionStatus.draft) {
    columns.push({
      key: 'action',
      width: 24,
      className: 'action-cell',
      render: (value: string, record: Ir21SubmissionA1State) => {
        const ir21 = ir21Dict[record.ir21Id]
        if (ir21) {
          return (
            <Tooltip title={!!ir21.lockedBy ? 'Locked' : 'Unlocked'}>
              <Link
                className={classNames('ir21a1records__lock', { 'ir21a1records__lock--locked': !!ir21.lockedBy })}
                onClick={() => handleLock(record)}
              >
                {locking === record.id ? (
                  <LoadingOutlined />
                ) : !!ir21.lockedBy ? (
                  <i className="fal fa-lock" />
                ) : (
                  <i className="fal fa-lock-open" />
                )}
              </Link>
            </Tooltip>
          )
        }
      }
    })
  }

  if (schema) {
    // Configurable employee columns
    columns.push(
      ...schema?.selection.map(f => {
        var field = selection?.entities[f.selectionFieldId]
        return {
          title: field?.description,
          key: field?.fieldName,
          dataIndex: field?.fieldName,
          width: field?.width || defaultColWidth,
          render: (value: string | number, record: Ir21SubmissionA1State, index: number) => {
            if (field?.format === 'date' && value) {
              return moment(value).format('DD MMM YYYY')
            }
            if (field?.format === 'yearmonth' && value) {
              return formatYearMonth(value as string)
            }
            if (field?.format === 'month' && value) {
              return formatMonth(value as number)
            }
            if (field?.format === 'money' && value) {
              return formatMoney(value as number)
            }
            return value
          }
        }
      })
    )
  }

  columns.push(
    ...[
      {
        title: 'Value of benefit-in-kind',
        key: 'bikTotal',
        dataIndex: 'bikTotal',
        width: amountColWidth,
        align: 'right',
        render: (value: number) => formatMoney(value, 2)
      } as ColumnType<Ir21SubmissionA1State>,
      {
        title: 'Value of benefit-in-kind prior',
        key: 'bikTotalP',
        dataIndex: 'bikTotalP',
        width: amountColWidth,
        align: 'right',
        render: (value: number) => formatMoney(value, 2)
      } as ColumnType<Ir21SubmissionA1State>,
      {
        key: 'filler'
      }
    ]
  )

  const handleLock = useCallback(
    async (record: Ir21SubmissionA1State) => {
      if (record) {
        setLocking(record.id)
        try {
          const ir21 = ir21Dict[record.ir21Id]
          if (ir21) {
            if (ir21.lockedBy) {
              await dispatch(unlockIr21Draft(record.ir21Id))
            } else {
              await dispatch(lockIr21Draft(record.ir21Id))
            }
          }
        } finally {
          setLocking(undefined)
        }
      }
    },
    [ir21Dict]
  )

  const handleCloseSelectionDrawer = useCallback((changed?: boolean) => {
    if (changed) dispatch(refetchIr21SubmissionA1Views())
  }, [])

  const handleCriteriaApply = useCallback(
    async (criteria: ViewCriteria[]) => {
      if (viewId) {
        setPage(1)
        await dispatch(updateViewCriteria(SCREEN_CODE, viewId, { id: viewId, criteria }))
        dispatch(refetchIr21SubmissionA1Views())
      }
    },
    [viewId]
  )

  const handlePaginationChange = useCallback((page: number, pageSize?: number) => {
    setPage(page)
    setPageSize(pageSize || 20)
  }, [])

  const handleSearch = useCallback((value: string) => {
    setPage(1)
    setSearch(value)
  }, [])

  const handleCalculateAll = useCallback(() => {
    dispatch(processIr21A1(ir21SubmissionId))
  }, [ir21SubmissionId])

  const handleCalculateSome = useCallback(() => {
    setRecalculateDrawerState({ visible: true })
  }, [])

  const handleFetchDataRecalculate = useCallback(
    (viewId: string, pagination: Pagination, search: string) => {
      if (ir21SubmissionId) {
        dispatch(fetchIr21A1EmSelections(ir21SubmissionId, viewId, pagination, search))
      }
    },
    [ir21SubmissionId]
  )

  const handleSelectRecalculate = useCallback(
    async (employeeTaxNos: string[]) => {
      await dispatch(processIr21A1(ir21SubmissionId, employeeTaxNos))
    },
    [ir21SubmissionId]
  )

  const handleCloseRecalculateDrawer = useCallback(() => {
    setRecalculateDrawerState(EM_SELECTION_DRAWER_STATE)
  }, [])

  const handleAddEmployee = useCallback(() => {
    setAddEmployeeDrawerState({ visible: true })
  }, [])

  const handleFetchDataAddEmployee = useCallback(
    (viewId: string, pagination: Pagination, search: string) => {
      if (ir21SubmissionId) {
        dispatch(fetchIr21A1NewEmSelections(ir21SubmissionId, viewId, pagination, search))
      }
    },
    [ir21SubmissionId]
  )

  const handleSelectAddEmployee = useCallback(
    async (employeeTaxNos: string[]) => {
      await dispatch(addIr21A1Draft({ ir21SubmissionId, employeeTaxNos }))
      dispatch(refetchIr21SubmissionA1Views())
    },
    [ir21SubmissionId]
  )

  const handleCloseAddEmployeeDrawer = useCallback(() => {
    setAddEmployeeDrawerState(EM_SELECTION_DRAWER_STATE)
  }, [])

  return (
    <div className="ir21-a1-records">
      <div className="ir21-a1-records__action-bar">
        <Form.Item label="">
          <SearchInput onSearch={handleSearch} />
        </Form.Item>
        <ViewCriteriaSimple screenCode={SCREEN_CODE} viewId={viewId} onApply={handleCriteriaApply} label="" />
        <Space align="start">
          {canModify && ir21Submission?.status === Ir21SubmissionStatus.draft && (
            <Button onClick={handleAddEmployee}>Add employee</Button>
          )}
          <ViewSelectionButton
            screenCode={SCREEN_CODE}
            viewId={viewId}
            title="Configure Appendix 1 record columns"
            onClose={handleCloseSelectionDrawer}
          />
        </Space>
      </div>
      <Card fitParent table>
        <Table
          rowKey="id"
          dataSource={data?.data}
          columns={columns}
          fitParent
          loading={dataLoading || viewLoading}
          scroll={{ x: tableWidth, y: 1000 }}
          pagination={{
            total: data?.count,
            current: page,
            pageSize,
            pageSizeOptions: PAGE_SIZE_OPTIONS,
            showSizeChanger: true,
            onChange: handlePaginationChange,
            style: paginationStyle
          }}
        />
      </Card>

      <div className="ir21-a1-records__footer-bar">
        <Space className="ir21-a1-records__footer-bar--flex"></Space>
        {canModify && ir21Submission?.status === Ir21SubmissionStatus.draft && (
          <Space>
            <Dropdown.Button
              menu={{
                items: [{ key: 'selected', label: 'Recalculate selected employees...', onClick: handleCalculateSome }]
              }}
              icon={<i className="fa-light fa-angle-down" />}
              onClick={handleCalculateAll}
              buttonsRender={([leftButton, rightButton]) => [
                React.cloneElement(leftButton as React.ReactElement, { loading: processing }),
                rightButton
              ]}
            >
              Recalculate all Appendix 1
            </Dropdown.Button>
          </Space>
        )}
      </div>
      <EmSelectionDrawer
        {...recalculateDrawerState}
        viewName="ir21a1_recalculate"
        title="Select the employees to recalculate Appendix 1"
        okText="Recalculate"
        onFetchData={handleFetchDataRecalculate}
        onSelect={handleSelectRecalculate}
        onClose={handleCloseRecalculateDrawer}
        resetOnClose={true}
      />
      <EmSelectionDrawer
        {...addEmployeeDrawerState}
        viewName="ir21a1_newem"
        title="Add employees"
        okText="Add"
        onFetchData={handleFetchDataAddEmployee}
        onSelect={handleSelectAddEmployee}
        onClose={handleCloseAddEmployeeDrawer}
        resetOnClose={true}
      />
    </div>
  )
}
