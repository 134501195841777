import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { WorkflowState, WfCategory } from '../types'

export const selectWorkflow = createSelector(
  (state: StoreState) => state.workflow.workflows,
  workflowsState =>
    memoize(
      (category: WfCategory, id: string): WorkflowState | undefined => {
        const workflows = Object.values(workflowsState[category]?.entities || {}) as WorkflowState[]
        return workflows.find(w => w.id === id)
      },
      (category: WfCategory, id: string) => `${category}|${id}`
    )
)
