import React, { CSSProperties, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { v4 as uuidv4 } from 'uuid'
import { LoadingOutlined } from '@ant-design/icons'
import { Form, Spin } from '~/core-components'
import { criteriaDelimiter, emptyGuid } from '~/constants'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { CriteriaOptions } from './components/CriteriaOptions'
import { fetchViewSchema } from '../actions'
import { selectViewCriteriaSimple, selectSysCriteriaFields, selectIsViewOrSchemaLoading } from '../selectors'
import { Screen, ViewCriteria } from '../types'
import { useFetchSysCriteria } from '../hooks'

interface ViewCriteriaSingleProps {
  screenCode: Screen
  viewId?: string
  label?: string
  fieldId: string
  readOnly?: boolean
  onChange: (criteria: ViewCriteria[]) => void
}

const EMPTY_FORM_DATA: ViewCriteria[] = [{ id: emptyGuid, criteriaType: 'group', operator: 'and', sequence: 0 }]

const selectCriteriaStyle: CSSProperties = { minWidth: 80 }

const getGroupCriteria = (id: string) => ({
  id,
  criteriaType: 'group',
  operator: 'and',
  sequence: 0
})

const getEmptyCriteria = (rootId: string) => ({
  id: uuidv4(),
  parentId: rootId,
  criteriaType: 'criteria',
  operator: 'in',
  sequence: 0
})

export const ViewCriteriaSingle: FC<ViewCriteriaSingleProps> = ({
  screenCode,
  viewId,
  readOnly = false,
  label,
  fieldId = '',
  onChange
}) => {
  const [formData, setFormData] = useState<ViewCriteria[]>(EMPTY_FORM_DATA)
  const criteria = useSelector((state: StoreState) => state.selection.sysCriteriaFields[screenCode])
  const criteriaFields = useSelector(selectSysCriteriaFields)(screenCode)
  const viewCriteria = useSelector(selectViewCriteriaSimple)(screenCode, viewId)
  const loading = useSelector(selectIsViewOrSchemaLoading)(screenCode, viewId)
  const isEmpty = useMemo(() => formData.filter(c => c.parentId != null && c.value).length === 0, [formData])
  useFetchSysCriteria(screenCode)

  useEffect(() => {
    if (viewId) {
      dispatch(fetchViewSchema(screenCode, viewId))
    }
  }, [screenCode, viewId])

  useDeepCompareEffect(() => {
    if (!viewCriteria || viewCriteria.filter(c => c.parentId != null).length === 0) {
      const rootId = uuidv4()
      setFormData([getGroupCriteria(rootId), { ...getEmptyCriteria(rootId), criteriaFieldId: fieldId }])
    } else {
      if (viewCriteria) {
        setFormData([...viewCriteria])
      }
    }
  }, [viewCriteria || {}])

  const handleFormDataChange = useCallback(
    (updates: ViewCriteria[]) => {
      const updated = updates.filter(
        c => c.criteriaType === 'group' || (c.criteriaType === 'criteria' && c.criteriaFieldId != null)
      )
      setFormData(updated)
      typeof onChange === 'function' && onChange(updated)
    },
    [onChange]
  )

  if (loading) {
    return <Spin size="small" indicator={<LoadingOutlined spin />} />
  }

  if (readOnly && isEmpty) {
    return <div className="criteria-empty">No criteria defined</div>
  }

  const index = formData.findIndex(cri => cri.parentId != null)
  const cri = formData[index]
  const field = criteriaFields.find(fld => fld?.id === fieldId)

  return (
    <Form.Item label={label || field?.description || ''}>
      <CriteriaOptions
        screenCode={screenCode}
        criteria={criteria?.entities[cri?.criteriaFieldId || '']}
        style={selectCriteriaStyle}
        value={cri?.value ? cri.value.split(criteriaDelimiter) : undefined}
        maxTagCount={undefined}
        dropdownMatchSelectWidth={200}
        readOnly={readOnly}
        onChange={(value: string[]) =>
          handleFormDataChange([
            ...formData.slice(0, index),
            { ...formData[index], value: value.join(criteriaDelimiter) },
            ...formData.slice(index + 1, formData.length)
          ])
        }
      />
    </Form.Item>
  )
}
