import { request, getBaseUrl } from '~/utils/request'
import { BarChartState, ChartSummaryState, PieChartState } from '../types/em-dashboard.state'

const baseUrl = getBaseUrl('/employee/emdashboard')

export const apiGetTotalHeadcount = () => request<ChartSummaryState>('get', `${baseUrl}/totalhc`)

export const apiGetAttritionRate = () => request<ChartSummaryState>('get', `${baseUrl}/attritionrate`)

export const apiGetAverageAge = () => request<ChartSummaryState>('get', `${baseUrl}/avgage`)

export const apiGetAverageServiceLength = () => request<ChartSummaryState>('get', `${baseUrl}/avgsvclen`)

export const apiGetServiceLength = () => request<BarChartState>('get', `${baseUrl}/svclen`)

export const apiGetHeadcountGroupBy = (groupBy: string) =>
  request<PieChartState>('get', `${baseUrl}/hcgrp`, { groupBy })
