import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { ROLeavePeriodYearState } from '../../types'

export const selectROLeavePeriodYears = createSelector(
  (state: StoreState) => state.my.roLeavePeriodYears,
  roLeavePeriodYearState => {
    return Object.values(roLeavePeriodYearState?.entities || {}).sort((a, b) => {
      return b!.periodYear - a!.periodYear
    }) as ROLeavePeriodYearState[]
  }
)
