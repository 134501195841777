import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Card, ColumnsType, Form, Input, Link, LinkGroup, SecondaryText, Space, Table, Tag } from '~/core-components'
import { Col, Row, SalaryInput, ReasonKeyValues, SysOptions, EmpKeyValues, InfoTooltip } from '~/components'
import { selectReasonKeyvalues, selectSalaryGradeKeyvalues } from '~/features/master'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { useToggle } from '~/hooks/use-toggle'
import { useFirstInView } from '~/hooks/use-first-in-view'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { formatMoney } from '~/utils'
import { MutateEmSalaryDrawer } from './MutateEmSalaryDrawer'
import { IEmSalaryInfo, EmSalaryState } from '../../../types'
import { fetchEmSalaries } from '../../../actions'
import { selectCurrentEmSalary, selectHistoryEmSalaries } from '../../../selectors'
import { useSysOptions } from '../../../hooks'

interface EmSalariesProps {
  employeeId?: string
  id?: string
}

type EmSalaryTable = EmSalaryState

const EMPTY_FORM_DATA: IEmSalaryInfo = {
  startDate: '',
  salaryGradeId: '',
  basicSalary: 0,
  salaryBasis: '',
  reasonId: '',
  notes: ''
}

interface DrawerState {
  visible: boolean
  employeeId?: string
  data?: EmSalaryState
  isCurrent: boolean
}

const DEFAULT_MODAL_STATE: DrawerState = { visible: false, isCurrent: false }

const cardStyle: CSSProperties = { margin: 24 }
const cardBodyStyle: CSSProperties = { padding: 0 }
const formStyle: CSSProperties = { padding: '6px 24px' }

export const EmSalaries: FC<EmSalariesProps> = ({ employeeId, id }: EmSalariesProps) => {
  const { ref, inView } = useFirstInView<HTMLDivElement>({ threshold: 0.25 })
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_MODAL_STATE)
  const [formData, setFormData] = useState<IEmSalaryInfo>(EMPTY_FORM_DATA)
  const current = useSelector(selectCurrentEmSalary)(employeeId, id)
  const histories = useSelector(selectHistoryEmSalaries)(employeeId)
  const [salaryBases] = useSysOptions('salary_basis')
  const reasons = useSelector(selectReasonKeyvalues)
  const salaryGrades = useSelector(selectSalaryGradeKeyvalues)
  const [showHistory, toggleShowHistory] = useToggle()
  const canView = usePermissionGate(Permission.employeeSalary)
  const canModify = usePermissionGate(Permission.employeeSalary, PermissionAction.Modify)
  const loading = useSelector((state: StoreState) => state.employee.emSalariesLoading[employeeId || ''])

  useEffect(() => {
    if (inView) {
      if (employeeId) {
        dispatch(fetchEmSalaries(employeeId))
      }
    }
  }, [inView, employeeId])

  useEffect(() => {
    if (showHistory && histories.length === 0) {
      toggleShowHistory()
    }
  }, [histories.length, showHistory, toggleShowHistory])

  useEffect(() => {
    if (current) {
      const { startDate, salaryGradeId, basicSalary, salaryBasis, reasonId, notes } = current
      setFormData({ startDate, salaryGradeId, basicSalary, salaryBasis, reasonId, notes })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [current])

  const handleEditSalary = useCallback(
    (emSalary?: EmSalaryState) => {
      setDrawerState({ visible: true, employeeId, data: emSalary, isCurrent: id === emSalary?.id })
    },
    [employeeId, id, setDrawerState]
  )

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_MODAL_STATE)
  }, [])

  const columns: ColumnsType<EmSalaryTable> = [
    {
      title: 'Effective date',
      key: 'startDate',
      dataIndex: 'startDate',
      width: 150,
      render: (value: string) => value && moment(value).format('DD MMM YYYY')
    },
    {
      title: 'Basic salary',
      key: 'basicSalary',
      dataIndex: 'basicSalary',
      render: (value: number, record) => (
        <Space>
          {formatMoney(value, 2)}
          {record.id === id && <Tag type="primary">current</Tag>}
        </Space>
      )
    },
    {
      title: 'Salary grade',
      key: 'salaryGradeId',
      dataIndex: 'salaryGradeId',
      render: (value: string) => salaryGrades[value]?.value
    },
    {
      title: 'Salary basis',
      key: 'salaryBasis',
      dataIndex: 'salaryBasis',
      render: (value: string) => salaryBases[value]?.value
    },
    {
      title: 'Reason',
      key: 'reasonId',
      dataIndex: 'reasonId',
      width: 150,
      render: (value: string) => reasons[value]?.value
    },
    {
      key: 'notes',
      dataIndex: 'notes',
      width: 40,
      render: (value: string, record) => value && <InfoTooltip title={value} />
    },
    {
      key: 'action',
      align: 'right',
      width: 60,
      render: (value: string, record) =>
        canModify && (
          <Link size="small" onClick={() => handleEditSalary(record)}>
            edit
          </Link>
        )
    }
  ]

  if (!canView) return null

  return (
    <div ref={ref}>
      <Card
        title="Salary"
        style={cardStyle}
        bodyStyle={cardBodyStyle}
        loading={loading}
        extra={
          <LinkGroup>
            {canModify && !showHistory && (
              <Link onClick={() => handleEditSalary(current)}>{current ? 'edit' : 'add'}</Link>
            )}
            {(histories?.length || 0) > 0 && (
              <Link onClick={toggleShowHistory}>{showHistory ? 'hide history' : 'history'}</Link>
            )}
          </LinkGroup>
        }
      >
        {!showHistory && (
          <Form style={formStyle}>
            <Row gutter={30}>
              <Col span={12}>
                <Form.Item
                  label={
                    <Space>
                      Basic salary
                      {formData.startDate && (
                        <SecondaryText size="small">
                          effective from {moment(formData.startDate).format('DD MMM YYYY')}
                        </SecondaryText>
                      )}
                    </Space>
                  }
                >
                  <SalaryInput value={formData.basicSalary} readOnly />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Salary basis">
                  <SysOptions type="salary_basis" value={formData.salaryBasis} readOnly />
                </Form.Item>
              </Col>
            </Row>
            <Row hidden={!formData.salaryGradeId}>
              <Col span={24}>
                <Form.Item label="Salary grade">
                  <EmpKeyValues id="salaryGrade" value={formData.salaryGradeId} readOnly />
                </Form.Item>
              </Col>
            </Row>
            <Row hidden={!formData.reasonId}>
              <Col span={24}>
                <Form.Item label="Reason">
                  <ReasonKeyValues id="reasonId" type="salary" value={formData.reasonId} readOnly />
                </Form.Item>
              </Col>
            </Row>
            <Row hidden={!formData.notes}>
              <Col span={24}>
                <Form.Item label="Notes">
                  <Input value={formData.notes} readOnly />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
        {showHistory && <Table rowKey="id" dataSource={histories} pagination={false} columns={columns} />}
        {canModify && <MutateEmSalaryDrawer {...drawerState} onClose={handleCloseDrawer} />}
      </Card>
    </div>
  )
}
