import React, { ChangeEvent, FC, HTMLAttributes, useCallback, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import confirm from 'antd/lib/modal/confirm'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  Button,
  Checkbox,
  CheckboxChangeEvent,
  Collapse,
  Divider,
  Form,
  Input,
  Radio,
  RadioChangeEvent,
  SecondaryText,
  Skeleton,
  Space,
  Tooltip
} from '~/core-components'
import { Col, EmpKeyValues, ErrorDisplay, Row, SalaryInput, SysOptions } from '~/components'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors, StoreState } from '~/types/store'
import { fetchIr21Form, deleteIr21Draft, addIr21Draft, updateIr21Draft } from '../../../actions'
import { removeIr21A1Form, removeIr21Form, setIr21A1FormRefetch, setIr21FormRefetch } from '../../../reducers'
import { formatMoney } from '~/utils'
import { selectIr21Form } from '../../../selectors'
import { Ir21FormState } from '../../../types'
import { Ir21EmptyForm } from '../Ir21EmptyForm/Ir21EmptyForm'
import './Ir21Form.less'

interface Ir21Props extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
  ir21SubmissionId: string
  employeeTaxNo: string
  readOnly?: boolean

  // Trigger action from parent component
  triggerUpdate: number
  triggerDelete: number
  triggerReset: number
}

export const EMPTY_FORM_DATA: Ir21FormState = {
  id: '',
  ir21SubmissionId: '',
  employeeTaxNo: '',
  employerTaxNo: '',
  companyName: '',
  companyAddress: '',
  employeeName: '',
  employeeNo: '',
  identityType: '',
  malaysianIc: '',
  hireDate: '',
  employmentEndDate: '',
  nationalityName: '',
  irasNationality: '',
  gender: '',
  birthDate: '',
  maritalStatus: '',
  job: '',
  phone: '',
  email: '',
  fileType: '',
  supersedeDate: '',
  arrivalDate: '',
  departureDate: '',
  terminationNoticeDate: '',
  lateNoticeReason: '',
  lateNoticeReasonOther: '',
  withheldAmt: 0,
  withheldIndicator: false,
  noWithheldReason: '',
  noWithheldReasonOther: '',
  lastSalaryDate: '',
  lastSalaryAmt: 0,
  lastSalaryPeriod: '',
  bankIndicator: false,
  bankCode: '',
  taxByEmployerIndicator: '',
  partiallyBorneDetails: '',
  spouseName: '',
  spouseBirthDate: '',
  spouseIdentityDate: '',
  spouseIdentityType: '',
  spouseIdentityNo: '',
  marriageDate: '',
  spouseNationality: '',
  spouseIncomeIndicator: false,
  spouseEmployerName: '',
  spouseEmployerAddress: '',
  child1Name: '',
  child1Gender: '',
  child1BirthDate: '',
  child1School: '',
  child2Name: '',
  child2Gender: '',
  child2BirthDate: '',
  child2School: '',
  child3Name: '',
  child3Gender: '',
  child3BirthDate: '',
  child3School: '',
  child4Name: '',
  child4Gender: '',
  child4BirthDate: '',
  child4School: '',
  child5Name: '',
  child5Gender: '',
  child5BirthDate: '',
  child5School: '',
  child6Name: '',
  child6Gender: '',
  child6BirthDate: '',
  child6School: '',
  child7Name: '',
  child7Gender: '',
  child7BirthDate: '',
  child7School: '',
  child8Name: '',
  child8Gender: '',
  child8BirthDate: '',
  child8School: '',
  child9Name: '',
  child9Gender: '',
  child9BirthDate: '',
  child9School: '',
  child10Name: '',
  child10Gender: '',
  child10BirthDate: '',
  child10School: '',
  grossAmt: 0,
  grossAmtP: 0,
  bonusCAmt: 0,
  bonusCAmtP: 0,
  bonusNcAmt: 0,
  bonusNcAmtP: 0,
  bonusDate: '',
  bonusDateP: '',
  directorFeeAmt: 0,
  directorFeeAmtP: 0,
  directorFeeDate: '',
  directorFeeDateP: '',
  commissionAmt: 0,
  commissionAmtP: 0,
  allowanceAmt: 0,
  allowanceAmtP: 0,
  gratuityExgratiaAmt: 0,
  gratuityExgratiaAmtP: 0,
  noticeAmt: 0,
  noticeAmtP: 0,
  compensationAmt: 0,
  compensationReason: '',
  compensationReasonOther: '',
  compensationBasis: '',
  compensationBasisOther: '',
  serviceLength: 0,
  monthlySalary: 0,
  retirementAmt: 0,
  retirementAmtP: 0,
  retirementDate: '',
  retirementDateP: '',
  retirementFundName: '',
  cpfErOverseas: 0,
  cpfErOverseasP: 0,
  cpfErOverseasFundName: '',
  excessContributionAmt: 0,
  excessContributionAmtP: 0,
  bikAmt: 0,
  bikAmtP: 0,
  gainsEmStockAmt: 0,
  gainsEmStockAmtP: 0,
  unexercisedEsopAftIndicator: false,
  unexercisedEsopBefIndicator: false,
  nilIncomeReason: '',
  cpfEmployeeFundName: '',
  cpfEmployee: 0,
  cpfEmployeeP: 0,
  fundAmt: 0,
  fundAmtP: 0,
  mbfAmt: 0,
  mbfAmtP: 0,
  signatureDate: '',

  // calculation value
  subtotal: 0,
  subtotalP: 0,
  total: 0,
  totalP: 0
}

export const Ir21Form: FC<Ir21Props> = ({
  ir21SubmissionId,
  employeeTaxNo,
  readOnly,
  triggerUpdate,
  triggerDelete,
  triggerReset,
  ...divProps
}) => {
  const emptyFormData: Ir21FormState = { ...EMPTY_FORM_DATA, ir21SubmissionId, employeeTaxNo }
  const [formData, setFormData] = useState<Ir21FormState>(emptyFormData)
  const [errors, setErrors] = useState<Errors>()
  const ir21Form = useSelector(selectIr21Form)(ir21SubmissionId, employeeTaxNo)
  const hasData = !!ir21Form
  const loading = useSelector((state: StoreState) => state.tax.ir21FormLoading || false)
  const refetch = useSelector((state: StoreState) => state.tax.ir21FormRefetch)
  const [childRows, setChildRows] = useState<number>(2)

  useEffect(() => {
    dispatch(fetchIr21Form(ir21SubmissionId, employeeTaxNo))
  }, [refetch, ir21SubmissionId, employeeTaxNo])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(formData => {
      const updated = { ...formData, ...updates }

      updated.subtotal =
        updated.commissionAmt +
        updated.allowanceAmt +
        updated.gratuityExgratiaAmt +
        updated.noticeAmt +
        updated.compensationAmt +
        updated.retirementAmt +
        updated.cpfErOverseas +
        updated.excessContributionAmt +
        updated.gainsEmStockAmt +
        updated.bikAmt

      updated.subtotalP =
        updated.commissionAmtP +
        updated.allowanceAmtP +
        updated.gratuityExgratiaAmtP +
        updated.noticeAmtP +
        updated.retirementAmtP +
        updated.cpfErOverseasP +
        updated.excessContributionAmtP +
        updated.gainsEmStockAmtP +
        updated.bikAmtP

      updated.total =
        updated.grossAmt + updated.bonusCAmt + updated.bonusNcAmt + updated.directorFeeAmt + updated.subtotal

      updated.totalP =
        updated.grossAmtP + updated.bonusCAmtP + updated.bonusNcAmtP + updated.directorFeeAmtP + updated.subtotalP

      return updated
    })
  }, [])

  useEffect(() => {
    if (ir21Form) {
      handleFormDataChange(ir21Form)
    }
  }, [ir21Form, handleFormDataChange])

  const handleDeleteChildren = useCallback(
    index => {
      setFormData(formData => {
        let updates: { [key: string]: any } = {}
        for (let i = index + 1; i < childRows; i++) {
          updates[`child${i}Name`] = formData[`child${i + 1}Name` as keyof Ir21FormState]
          updates[`child${i}Gender`] = formData[`child${i + 1}Gender` as keyof Ir21FormState]
          updates[`child${i}BirthDate`] = formData[`child${i + 1}BirthDate` as keyof Ir21FormState]
          updates[`child${i}School`] = formData[`child${i + 1}School` as keyof Ir21FormState]
        }

        updates[`child${childRows}Name`] = ''
        updates[`child${childRows}Gender`] = ''
        updates[`child${childRows}BirthDate`] = ''
        updates[`child${childRows}School`] = ''

        setChildRows(childRows - 1)
        return { ...formData, ...updates }
      })
    },
    [childRows]
  )

  const handleCreateForm = useCallback(async () => {
    const result: ActionResult | undefined = await dispatch(
      addIr21Draft({ ir21SubmissionId, employeeTaxNos: [formData.employeeTaxNo] })
    )

    if (!result?.errors) {
      dispatch(setIr21FormRefetch())
      dispatch(setIr21A1FormRefetch())
    }
  }, [ir21SubmissionId, formData.employeeTaxNo])

  const handleUpdateForm = useCallback(async () => {
    setErrors(undefined)

    const result = await dispatch(updateIr21Draft(formData))

    if (result?.errors) {
      setErrors(result.errors)
    }
  }, [formData])

  const handleDeleteForm = useCallback(async () => {
    confirm({
      title: 'Delete',
      content: (
        <>
          <div>Do you want to delete this IR21?</div>
          <br />
          <div>This will also delete (if any) the following:</div>
          <div>&#9;- Appendix 1</div>
        </>
      ),
      onOk: async () => {
        const result: ActionResult | undefined = await dispatch(deleteIr21Draft(formData.id))

        if (!result?.errors) {
          dispatch(removeIr21Form(formData.employeeTaxNo))
          dispatch(removeIr21A1Form(formData.employeeTaxNo))
        }
      },
      width: 550,
      okText: 'Delete',
      okType: 'danger'
    })
  }, [formData])

  const handleResetForm = useCallback(() => {
    if (ir21Form) {
      setFormData(ir21Form)
    }
  }, [ir21Form])

  useEffect(() => {
    if (triggerUpdate > 0) handleUpdateForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerUpdate])

  useEffect(() => {
    if (triggerDelete > 0) handleDeleteForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerDelete])

  useEffect(() => {
    if (triggerReset > 0) handleResetForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerReset])

  if (loading)
    return (
      <div className="ir21">
        <div className="ir21-form">
          <Skeleton active />
        </div>
      </div>
    )

  if (!hasData)
    return (
      <Ir21EmptyForm onCreate={handleCreateForm}>
        <SecondaryText>There is no IR21 for the selected employee.</SecondaryText>
      </Ir21EmptyForm>
    )

  return (
    <div {...divProps} className="ir21">
      <div className="ir21-form">
        <ErrorDisplay errors={errors} />
        <div id="section-a">
          <div className="ir21-form__title mt0">A. TYPE OF FORM IR21</div>
          <div className="ir21-form__field-row">
            <Radio.Group
              value={formData.fileType}
              readOnly={readOnly}
              onChange={(event: RadioChangeEvent) => {
                handleFormDataChange({ fileType: event.target.value })
              }}
            >
              <Space direction="horizontal">
                <Radio value={'o'}>Original</Radio>
                <Radio value={'a'} style={{ marginLeft: 50 }}>
                  Amendment, this supersedes Form IR21 dated:
                  <Form.Item noStyle>
                    <Input.Date
                      size="small"
                      style={{ marginLeft: 10 }}
                      value={formData.supersedeDate ? moment(formData.supersedeDate) : undefined}
                      onChange={(value: moment.Moment | null) =>
                        handleFormDataChange({ supersedeDate: value?.format('YYYY-MM-DD') })
                      }
                      inputReadOnly={readOnly}
                    />
                  </Form.Item>
                </Radio>
              </Space>
            </Radio.Group>
          </div>
        </div>
        <div id="section-b">
          <div className="ir21-form__title">B. EMPLOYER'S PARTICULARS</div>
          <div className="ir21-form__field-row">
            <Row gutter={[16, 10]}>
              <Col span={12}>
                <div>
                  <div className="ir21-form__nowrap">Company's Tax Ref. No. </div>
                  <div className="ir21-form__header">{formData.employerTaxNo}</div>
                </div>
              </Col>
              <Col span={12}>
                <div>
                  <div className="ir21-form__nowrap">Company's Name</div>
                  <div className="ir21-form__header">{formData.companyName}</div>
                </div>
              </Col>
              <Col span={24}>
                <div className="ir21-form__nowrap">Company's Address</div>
                <div className="ir21-form__header">{formData.companyAddress}</div>
              </Col>
            </Row>
          </div>
        </div>
        <div id="section-c">
          <div className="ir21-form__title">C. EMPLOYEE'S PARTICULARS</div>
          <div className="ir21-form__field-row">
            <Row gutter={[16, 10]}>
              <Col span={24}>
                <div className="ir21-form__nowrap">Full Name of Employee as per NRIC/FIN</div>
                <div className="ir21-form__header">{formData.employeeName}</div>
              </Col>
              <Col span={8}>
                <div className="ir21-form__nowrap">Identity Type</div>
                <div className="ir21-form__header">{formData.identityType.toUpperCase()}</div>
              </Col>
              <Col span={8}>
                <div className="ir21-form__nowrap">Identity No</div>
                <div className="ir21-form__header">{formData.employeeTaxNo}</div>
              </Col>
              <Col span={8}>
                <div className="ir21-form__nowrap">Malaysian IC (if applicable)</div>
                <div className="ir21-form__header">{formData.malaysianIc}</div>
              </Col>
              <Col span={8}>
                <div className="ir21-form__nowrap">Date of Birth</div>
                <div className="ir21-form__header">
                  {formData.birthDate ? moment(formData.birthDate).format('DD MMM YYYY') : ''}
                </div>
              </Col>
              <Col span={8}>
                <div className="ir21-form__nowrap">Gender</div>
                <div className="ir21-form__header">{formData.gender}</div>
              </Col>
              <Col span={8}>
                <div className="ir21-form__nowrap">Citizenship</div>
                <div className="ir21-form__header">{formData.nationalityName}</div>
              </Col>
              <Col span={8}>
                <div className="ir21-form__nowrap">Marital Status</div>
                <div className="ir21-form__header">{formData.maritalStatus}</div>
              </Col>
              <Col span={8}>
                <div className="ir21-form__nowrap">Contact No.</div>
                <div className="ir21-form__header">{formData.phone}</div>
              </Col>
              <Col span={8}>
                <div className="ir21-form__nowrap">Email Address</div>
                <div className="ir21-form__header">{formData.email}</div>
              </Col>
            </Row>
          </div>
        </div>
        <div id="section-d">
          <div className="ir21-form__title">D. EMPLOYEE'S EMPLOYMENT RECORDS</div>
          <div className="ir21-form__field-row">
            <Row gutter={[16, 10]}>
              <Col span={24}>
                <Row>
                  <Col span={6}>
                    <div className="ir21-form__nowrap">Designation</div>
                  </Col>
                  <Col span={12}>
                    <div className="ir21-form__header">{formData.job}</div>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row>
                  <Col span={12}>
                    <div className="ir21-form__nowrap">Date of Arrival, if known</div>
                  </Col>
                  <Col span={12}>
                    <Form.Item noStyle>
                      <Input.Date
                        size="small"
                        value={formData.arrivalDate ? moment(formData.arrivalDate) : undefined}
                        inputReadOnly={readOnly}
                        onChange={(value: moment.Moment | null) =>
                          handleFormDataChange({ arrivalDate: value?.format('YYYY-MM-DD') })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row>
                  <Col span={12}>
                    <div className="ir21-form__nowrap">Date of Commencement</div>
                  </Col>
                  <Col span={12}>
                    <div className="ir21-form__header">
                      {formData.hireDate ? moment(formData.hireDate).format('DD MMM YYYY') : ''}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row>
                  <Col span={12}>
                    <div className="ir21-form__nowrap">Date of Departure, if known</div>
                  </Col>
                  <Col span={12}>
                    <Form.Item noStyle>
                      <Input.Date
                        size="small"
                        value={formData.departureDate ? moment(formData.departureDate) : undefined}
                        inputReadOnly={readOnly}
                        onChange={(value: moment.Moment | null) =>
                          handleFormDataChange({ departureDate: value?.format('YYYY-MM-DD') })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row>
                  <Col span={12}>
                    <div className="ir21-form__nowrap">Date of Cessation / Overseas Posting</div>
                  </Col>
                  <Col span={12}>
                    <div className="ir21-form__header">
                      {formData.employmentEndDate ? moment(formData.employmentEndDate).format('DD MMM YYYY') : ''}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Divider className="divider" />
            <Row gutter={[16, 10]}>
              <Col span={12}>
                <div className="ir21-form__nowrap">Date of Resignation / Termination Notice Given</div>
              </Col>
              <Col span={12}>
                <Form.Item noStyle>
                  <Input.Date
                    size="small"
                    value={formData.terminationNoticeDate ? moment(formData.terminationNoticeDate) : undefined}
                    inputReadOnly={readOnly}
                    onChange={(value: moment.Moment | null) =>
                      handleFormDataChange({ terminationNoticeDate: value?.format('YYYY-MM-DD') })
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <div>Reason, if less than one month's notice is given to IRAS before employee's cessation</div>
              </Col>
              <Col span={12}>
                <Form.Item noStyle>
                  <SysOptions
                    type="ir21_late_notice_reason"
                    size="small"
                    style={{ width: '100%' }}
                    readOnly={readOnly}
                    value={formData.lateNoticeReason}
                    placeholder="Select an option and change input text above"
                    onChange={(lateNoticeReason?: string) => handleFormDataChange({ lateNoticeReason })}
                  />
                </Form.Item>
                {formData.lateNoticeReason === '4' && (
                  <Form.Item noStyle>
                    <Input
                      size="small"
                      style={{ marginTop: 10 }}
                      value={formData.lateNoticeReasonOther}
                      readOnly={readOnly}
                      placeholder="Give details, if others"
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        handleFormDataChange({ lateNoticeReasonOther: event.target.value })
                      }
                    />
                  </Form.Item>
                )}
              </Col>
            </Row>
            <Divider className="divider" />
            <Row gutter={[20, 10]}>
              <Col span={12}>
                <Space>
                  <div>Amount of monies withheld pending Tax Clearance</div>
                  <SalaryInput
                    size="small"
                    style={{ width: '125px', marginLeft: 15 }}
                    readOnly={readOnly}
                    value={formData.withheldAmt}
                    onChange={(value: number | null) => handleFormDataChange({ withheldAmt: value })}
                  />
                </Space>
              </Col>
              <Col span={12}>
                <div>
                  Are these all the monies you can withhold from the date of notification of resignation / termination /
                  overseas posting ?
                  <Radio.Group
                    style={{ marginLeft: 30 }}
                    value={formData.withheldIndicator}
                    readOnly={readOnly}
                    onChange={(event: RadioChangeEvent) => {
                      handleFormDataChange({ withheldIndicator: event.target.value })
                    }}
                  >
                    <Space direction="horizontal">
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Space>
                  </Radio.Group>
                </div>
              </Col>
              <Col span={12}>
                <div>Reason, if you have selected 'No' or reported $0.00 withheld</div>
              </Col>
              <Col span={12}>
                <SysOptions
                  type="ir21_no_withheld_reason"
                  size="small"
                  style={{ width: '100%' }}
                  readOnly={readOnly}
                  value={formData.noWithheldReason}
                  placeholder="Select an option and change input text above"
                  onChange={(value?: string) => handleFormDataChange({ noWithheldReason: value })}
                />
                {formData.noWithheldReason === '5' && (
                  <Form.Item noStyle>
                    <Input
                      size="small"
                      style={{ marginTop: 10 }}
                      value={formData.noWithheldReasonOther}
                      readOnly={readOnly}
                      placeholder="Give details, if others"
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        handleFormDataChange({ noWithheldReasonOther: event.target.value })
                      }
                    />
                  </Form.Item>
                )}
              </Col>
            </Row>
            <Divider className="divider" />
            <Row gutter={[16, 10]}>
              <Col span={24}>
                <div className="ir21-form__header">Details of last salary paid to the employee</div>
              </Col>
              <Col span={12}>
                <Row gutter={[16, 10]}>
                  <Col span={12}>
                    Date of payment
                    <Form.Item noStyle>
                      <Input.Date
                        size="small"
                        style={{ width: '115px', marginLeft: 10 }}
                        value={formData.lastSalaryDate ? moment(formData.lastSalaryDate) : undefined}
                        inputReadOnly={readOnly}
                        onChange={(value: moment.Moment | null) =>
                          handleFormDataChange({ lastSalaryDate: value?.format('YYYY-MM-DD') })
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    Amount Paid
                    <SalaryInput
                      size="small"
                      style={{ width: '125px', marginLeft: 15 }}
                      readOnly={readOnly}
                      value={formData.lastSalaryAmt}
                      onChange={(value: number | null) => handleFormDataChange({ lastSalaryAmt: value })}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row>
                  <Col span={14}>Period in relation to the Last Salary Paid</Col>
                  <Col span={10}>
                    <Form.Item noStyle>
                      <Input
                        size="small"
                        value={formData.lastSalaryPeriod}
                        readOnly={readOnly}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                          handleFormDataChange({ lastSalaryPeriod: event.target.value })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                Is salary credited to employee's bank account?
                <Radio.Group
                  style={{ marginLeft: 30 }}
                  value={formData.bankIndicator}
                  readOnly={readOnly}
                  onChange={(event: RadioChangeEvent) => {
                    handleFormDataChange({ bankIndicator: event.target.value })
                  }}
                >
                  <Space direction="horizontal">
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Space>
                </Radio.Group>
              </Col>
              <Col span={12}>
                <Row>
                  <Col span={8}>Name of Bank</Col>
                  <Col span={16}>
                    <EmpKeyValues
                      id="bank"
                      size="small"
                      style={{ width: '100%' }}
                      value={formData.bankCode}
                      readOnly={readOnly}
                      placeholder="Select an option and change input text above"
                      onChange={(value: string) => handleFormDataChange({ bankCode: value })}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                Employee's income tax borne by employer
                <Radio.Group
                  style={{ marginLeft: 30 }}
                  value={formData.taxByEmployerIndicator}
                  readOnly={readOnly}
                  onChange={(event: RadioChangeEvent) => {
                    handleFormDataChange({ taxByEmployerIndicator: event.target.value })
                  }}
                >
                  <Space direction="horizontal">
                    <Radio value={'0'}>No</Radio>
                    <Radio value={'1'}>Yes, fully borne</Radio>
                    <Radio value={'2'}>Yes, partially borne</Radio>
                  </Space>
                </Radio.Group>
                {formData.taxByEmployerIndicator === '2' && (
                  <Form.Item noStyle>
                    <Input
                      size="small"
                      style={{ width: '30%', marginLeft: 15 }}
                      value={formData.partiallyBorneDetails}
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        handleFormDataChange({ partiallyBorneDetails: event.target.value })
                      }
                      readOnly={readOnly}
                      placeholder="Give details, if partial borne"
                    />
                  </Form.Item>
                )}
              </Col>
            </Row>
          </div>
        </div>
        <div id="section-e">
          <div className="ir21-form__title">
            E. SPOUSE'S AND CHILDREN'S PARTICULARS (Please complete for dependants' relief claims)
          </div>
          <div className="ir21-form__field-row">
            <Row gutter={[20, 10]}>
              <Col span={24}>
                <div className="ir21-form__header">Spouse's Particulars</div>
              </Col>
              <Col span={12}>
                <Row>
                  <Col span={8}>
                    <div className="ir21-form__nowrap">Name</div>
                  </Col>
                  <Col span={16}>
                    <Form.Item noStyle>
                      <Input
                        size="small"
                        value={formData.spouseName}
                        readOnly={readOnly}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                          handleFormDataChange({ spouseName: event.target.value })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row>
                  <Col span={8}>
                    <div className="ir21-form__nowrap">Date of Birth</div>
                  </Col>
                  <Col span={16}>
                    <Form.Item noStyle>
                      <Input.Date
                        size="small"
                        value={formData.spouseBirthDate ? moment(formData.spouseBirthDate) : undefined}
                        inputReadOnly={readOnly}
                        onChange={(value: moment.Moment | null) =>
                          handleFormDataChange({ spouseBirthDate: value?.format('YYYY-MM-DD') })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row>
                  <Col span={8}>
                    <div className="ir21-form__nowrap">Identity No.</div>
                  </Col>
                  <Col span={6}>
                    <Form.Item noStyle>
                      <SysOptions
                        type="identity_type"
                        size="small"
                        style={{ width: '95%' }}
                        dropdownMatchSelectWidth={false}
                        value={formData.spouseIdentityType}
                        onChange={(spouseIdentityType: string) => handleFormDataChange({ spouseIdentityType })}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <Form.Item noStyle>
                      <Input
                        size="small"
                        value={formData.spouseIdentityNo}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                          handleFormDataChange({ spouseIdentityNo: event.target.value })
                        }
                        readOnly={readOnly}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row>
                  <Col span={8}>
                    <div className="ir21-form__nowrap">Date of Marriage</div>
                  </Col>
                  <Col span={16}>
                    <Form.Item noStyle>
                      <Input.Date
                        size="small"
                        inputReadOnly={readOnly}
                        value={formData.marriageDate ? moment(formData.marriageDate) : undefined}
                        onChange={(value: moment.Moment | null) =>
                          handleFormDataChange({ marriageDate: value?.format('YYYY-MM-DD') })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row>
                  <Col span={8}>
                    <div className="ir21-form__nowrap">Citizenship</div>
                  </Col>
                  <Col span={16}>
                    <Form.Item noStyle>
                      <EmpKeyValues
                        id="nationality"
                        size="small"
                        style={{ width: '100%' }}
                        value={formData.spouseNationality}
                        readOnly={readOnly}
                        onChange={(value: string) => handleFormDataChange({ spouseNationality: value })}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row>
                  Is the spouse's yearly income more than $4,000?
                  <Radio.Group
                    style={{ marginLeft: 30 }}
                    value={formData.spouseIncomeIndicator}
                    readOnly={readOnly}
                    onChange={(event: RadioChangeEvent) => {
                      handleFormDataChange({ spouseIncomeIndicator: event.target.value })
                    }}
                  >
                    <Space direction="horizontal">
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Space>
                  </Radio.Group>
                </Row>
                {formData.spouseIncomeIndicator && (
                  <Row gutter={[0, 10]} className="mt10">
                    <Col span={9}>
                      <div className="ir21-form__nowrap">Spouse’s Employer Name</div>
                    </Col>
                    <Col span={15}>
                      <Form.Item noStyle>
                        <Input
                          size="small"
                          value={formData.spouseEmployerName}
                          readOnly={readOnly}
                          onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            handleFormDataChange({ spouseEmployerName: event.target.value })
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={9}>
                      <div className="ir21-form__nowrap">Spouse’s Employer Address</div>
                    </Col>
                    <Col span={15}>
                      <Form.Item noStyle>
                        <Input
                          size="small"
                          value={formData.spouseEmployerAddress}
                          readOnly={readOnly}
                          onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            handleFormDataChange({ spouseEmployerAddress: event.target.value })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
            <Row style={{ marginTop: 15, marginBottom: 10 }}>
              <Col>
                <div className="ir21-form__header">Children's Particulars</div>
              </Col>
            </Row>
            <Row gutter={20} className="ir21-form__row-header">
              <Col span={1}>
                <div className="ta-r">No.</div>
              </Col>
              <Col span={7}>Name of Child</Col>
              <Col span={3}>Gender</Col>
              <Col span={4}>Date of Birth</Col>
              <Col span={8}>Name of school (if child is above 16 years old)</Col>
              <Col span={1}></Col>
            </Row>
            {Array.from(Array(childRows), (val, index) => (
              <Row key={index} gutter={[20, 10]}>
                <Col span={1} className="ta-r">
                  {index + 1}
                </Col>
                <Col span={7}>
                  <Form.Item noStyle>
                    <Input
                      size="small"
                      value={formData[`child${index + 1}Name` as keyof Ir21FormState] as string}
                      readOnly={readOnly}
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        handleFormDataChange({ [`child${index + 1}Name`]: event.target.value })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <SysOptions
                    type="gender"
                    size="small"
                    style={{ width: '100%' }}
                    readOnly={readOnly}
                    value={formData[`child${index + 1}Gender` as keyof Ir21FormState]}
                    onChange={(value?: string) => handleFormDataChange({ [`child${index + 1}Gender`]: value })}
                  />
                </Col>
                <Col span={4}>
                  <Form.Item noStyle>
                    <Input.Date
                      size="small"
                      value={
                        formData[`child${index + 1}BirthDate` as keyof Ir21FormState]
                          ? moment(formData[`child${index + 1}BirthDate` as keyof Ir21FormState] as string)
                          : undefined
                      }
                      inputReadOnly={readOnly}
                      onChange={(value: moment.Moment | null) =>
                        handleFormDataChange({ [`child${index + 1}BirthDate`]: value?.format('YYYY-MM-DD') })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item noStyle>
                    <Input
                      size="small"
                      value={formData[`child${index + 1}School` as keyof Ir21FormState] as string}
                      readOnly={readOnly}
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        handleFormDataChange({ [`child${index + 1}School`]: event.target.value })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={1}>
                  <Button
                    icon={
                      <Tooltip title="Delete children">
                        <i className="fal fa-trash" />
                      </Tooltip>
                    }
                    onClick={() => handleDeleteChildren(index)}
                    size="small"
                  />
                </Col>
              </Row>
            ))}
            <Form.Item hidden={childRows === 10}>
              <Link to="#" onClick={() => setChildRows(childRows + 1)}>
                add more records
              </Link>
            </Form.Item>
          </div>
        </div>
        <div id="section-f">
          <div className="ir21-form__title">
            F. INCOME RECEIVED / TO BE RECEIVED DURING THE YEAR OF CESSATION / DEPARTURE AND THE PRIOR YEAR
          </div>
          <div className="ir21-form__field-row">
            <Row gutter={20} className="ir21-form__row-header" align="middle">
              <Col span={14}>INCOME</Col>
              <Col span={5} className="ta-r">
                <div>Year of Cessation</div>
                <div>S$</div>
              </Col>
              <Col span={5} className="ta-r">
                <div>Year Prior to Year of Cessation</div>
                <div>S$</div>
              </Col>
            </Row>
            <Row gutter={[20, 10]}>
              <Col span={1}>1.</Col>
              <Col span={13}>Gross Salary, Fees, Leave Pay, Wages and Overtime pay</Col>
              <Col span={5}>
                <SalaryInput
                  size="small"
                  readOnly={readOnly}
                  value={formData.grossAmt}
                  onChange={(value: number | null) => handleFormDataChange({ grossAmt: value })}
                />
              </Col>
              <Col span={5}>
                <SalaryInput
                  size="small"
                  readOnly={readOnly}
                  value={formData.grossAmtP}
                  onChange={(value: number | null) => handleFormDataChange({ grossAmtP: value })}
                />
              </Col>
            </Row>
            <Row gutter={[20, 10]}>
              <Col span={1}>2a.</Col>
              <Col span={13}>Contractual Bonus</Col>
              <Col span={5}>
                <SalaryInput
                  size="small"
                  readOnly={readOnly}
                  value={formData.bonusCAmt}
                  onChange={(value: number | null) => handleFormDataChange({ bonusCAmt: value })}
                />
              </Col>
              <Col span={5}>
                <SalaryInput
                  size="small"
                  readOnly={readOnly}
                  value={formData.bonusCAmtP}
                  onChange={(value: number | null) => handleFormDataChange({ bonusCAmtP: value })}
                />
              </Col>
            </Row>
            <Row gutter={[20, 10]}>
              <Col span={1}>2b.</Col>
              <Col span={13}>Non-Contractual Bonus</Col>
              <Col span={5}>
                <SalaryInput
                  size="small"
                  readOnly={readOnly}
                  value={formData.bonusNcAmt}
                  onChange={(value: number | null) => handleFormDataChange({ bonusNcAmt: value })}
                />
              </Col>
              <Col span={5}>
                <SalaryInput
                  size="small"
                  readOnly={readOnly}
                  value={formData.bonusNcAmtP}
                  onChange={(value: number | null) => handleFormDataChange({ bonusNcAmtP: value })}
                />
              </Col>
            </Row>
            <Row gutter={[20, 10]}>
              <Col span={1}></Col>
              <Col span={13}>Date of Payment</Col>
              <Col span={5}>
                <Form.Item noStyle>
                  <Input.Date
                    size="small"
                    style={{ width: '100%' }}
                    value={formData.bonusDate ? moment(formData.bonusDate) : undefined}
                    inputReadOnly={readOnly}
                    onChange={(value: moment.Moment | null) =>
                      handleFormDataChange({ bonusDate: value?.format('YYYY-MM-DD') })
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item noStyle>
                  <Input.Date
                    size="small"
                    style={{ width: '100%' }}
                    value={formData.bonusDateP ? moment(formData.bonusDateP) : undefined}
                    inputReadOnly={readOnly}
                    onChange={(value: moment.Moment | null) =>
                      handleFormDataChange({ bonusDateP: value?.format('YYYY-MM-DD') })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[20, 10]}>
              <Col span={1}>3.</Col>
              <Col span={13}>Director's Fees</Col>
              <Col span={5}>
                <SalaryInput
                  size="small"
                  readOnly={readOnly}
                  value={formData.directorFeeAmt}
                  onChange={(value: number | null) => handleFormDataChange({ directorFeeAmt: value })}
                />
              </Col>
              <Col span={5}>
                <SalaryInput
                  size="small"
                  readOnly={readOnly}
                  value={formData.directorFeeAmtP}
                  onChange={(value: number | null) => handleFormDataChange({ directorFeeAmtP: value })}
                />
              </Col>
            </Row>
            <Row gutter={[20, 10]}>
              <Col span={1}></Col>
              <Col span={13}>Approved at the company's AGM / EGM on</Col>
              <Col span={5}>
                <Form.Item noStyle>
                  <Input.Date
                    size="small"
                    style={{ width: '100%' }}
                    value={formData.directorFeeDate ? moment(formData.directorFeeDate) : undefined}
                    inputReadOnly={readOnly}
                    onChange={(value: moment.Moment | null) =>
                      handleFormDataChange({ directorFeeDate: value?.format('YYYY-MM-DD') })
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item noStyle>
                  <Input.Date
                    size="small"
                    style={{ width: '100%' }}
                    value={formData.directorFeeDateP ? moment(formData.directorFeeDateP) : undefined}
                    inputReadOnly={readOnly}
                    onChange={(value: moment.Moment | null) =>
                      handleFormDataChange({ directorFeeDateP: value?.format('YYYY-MM-DD') })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Collapse expandIconPosition="end" className="collapsed">
              <Collapse.Panel
                header={
                  <Row>
                    <Col span={1}>4.</Col>
                    <Col>Others (Commissions, Allowances, Appendices, etc)</Col>
                  </Row>
                }
                key={1}
              >
                <Row gutter={[20, 10]}>
                  <Col span={1}>4a.</Col>
                  <Col span={13}>Gross Commission</Col>
                  <Col span={5}>
                    <SalaryInput
                      size="small"
                      readOnly={readOnly}
                      value={formData.commissionAmt}
                      onChange={(value: number | null) => handleFormDataChange({ commissionAmt: value })}
                    />
                  </Col>
                  <Col span={5}>
                    <SalaryInput
                      size="small"
                      readOnly={readOnly}
                      value={formData.commissionAmtP}
                      onChange={(value: number | null) => handleFormDataChange({ commissionAmtP: value })}
                    />
                  </Col>
                </Row>
                <Row gutter={[20, 10]}>
                  <Col span={1}>4b.</Col>
                  <Col span={13}>Allowances</Col>
                  <Col span={5}>
                    <SalaryInput
                      size="small"
                      readOnly={readOnly}
                      value={formData.allowanceAmt}
                      onChange={(value: number | null) => handleFormDataChange({ allowanceAmt: value })}
                    />
                  </Col>
                  <Col span={5}>
                    <SalaryInput
                      size="small"
                      readOnly={readOnly}
                      value={formData.allowanceAmtP}
                      onChange={(value: number | null) => handleFormDataChange({ allowanceAmtP: value })}
                    />
                  </Col>
                </Row>
                <Row gutter={[20, 10]}>
                  <Col span={1}>4c.</Col>
                  <Col span={13}>Gratuity / Ex-gratia Payment</Col>
                  <Col span={5}>
                    <SalaryInput
                      size="small"
                      readOnly={readOnly}
                      value={formData.gratuityExgratiaAmt}
                      onChange={(value: number | null) => handleFormDataChange({ gratuityExgratiaAmt: value })}
                    />
                  </Col>
                  <Col span={5}>
                    <SalaryInput
                      size="small"
                      readOnly={readOnly}
                      value={formData.gratuityExgratiaAmtP}
                      onChange={(value: number | null) => handleFormDataChange({ gratuityExgratiaAmtP: value })}
                    />
                  </Col>
                </Row>
                <Row gutter={[20, 10]}>
                  <Col span={1}>4d.</Col>
                  <Col span={13}>Notice Pay</Col>
                  <Col span={5}>
                    <SalaryInput
                      size="small"
                      readOnly={readOnly}
                      value={formData.noticeAmt}
                      onChange={(value: number | null) => handleFormDataChange({ noticeAmt: value })}
                    />
                  </Col>
                  <Col span={5}>
                    <SalaryInput
                      size="small"
                      readOnly={readOnly}
                      value={formData.noticeAmtP}
                      onChange={(value: number | null) => handleFormDataChange({ noticeAmtP: value })}
                    />
                  </Col>
                </Row>
                <Row gutter={[20, 10]}>
                  <Col span={1}>4e.</Col>
                  <Col span={13}>
                    Compensation for loss of office (excluding any Notice Pay which should be reflected at 4d above)
                  </Col>
                  <Col span={5}>
                    <SalaryInput
                      size="small"
                      readOnly={readOnly}
                      value={formData.compensationAmt}
                      onChange={(value: number | null) => handleFormDataChange({ compensationAmt: value })}
                    />
                  </Col>
                  <Col span={5}></Col>
                </Row>
                <Row gutter={[20, 10]}>
                  <Col span={1}></Col>
                  <Col span={5}>Reason for Payment</Col>
                  <Col span={8}>
                    <Form.Item noStyle>
                      <SysOptions
                        type="ir21_compensation_reason"
                        size="small"
                        style={{ width: '100%' }}
                        readOnly={readOnly}
                        value={formData.compensationReason}
                        placeholder="Select an option and change input text above"
                        onChange={(value: number | null) => handleFormDataChange({ compensationReason: value })}
                      />
                    </Form.Item>
                    {formData.compensationReason === '99' && (
                      <Form.Item noStyle>
                        <Input
                          size="small"
                          style={{ marginTop: 10 }}
                          value={formData.compensationReasonOther}
                          readOnly={readOnly}
                          placeholder="Give details, if others"
                          onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            handleFormDataChange({ compensationReasonOther: event.target.value })
                          }
                        />
                      </Form.Item>
                    )}
                  </Col>
                  <Col span={5} className="ta-r">
                    Length of service
                  </Col>
                  <Col span={5}>
                    <Form.Item noStyle>
                      <Input.Number
                        size="small"
                        readOnly={readOnly}
                        value={formData.serviceLength}
                        onChange={(value: number | null) => handleFormDataChange({ serviceLength: value })}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[20, 10]}>
                  <Col span={1}></Col>
                  <Col span={5}>Basis of arriving at payment</Col>
                  <Col span={8}>
                    <Form.Item noStyle>
                      <SysOptions
                        type="ir21_compensation_basis"
                        size="small"
                        style={{ width: '100%' }}
                        readOnly={readOnly}
                        value={formData.compensationBasis}
                        placeholder="Select an option and change input text above"
                        onChange={(value: number | null) => handleFormDataChange({ compensationBasis: value })}
                      />
                    </Form.Item>
                    {formData.compensationBasis === '98' && (
                      <Form.Item noStyle>
                        <Input
                          size="small"
                          style={{ marginTop: 10 }}
                          value={formData.compensationBasisOther}
                          readOnly={readOnly}
                          placeholder="Give details, if others"
                          onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            handleFormDataChange({ compensationBasisOther: event.target.value })
                          }
                        />
                      </Form.Item>
                    )}
                  </Col>
                  <Col span={5} className="ta-r">
                    Monthly salary
                  </Col>
                  <Col span={5}>
                    <SalaryInput
                      size="small"
                      readOnly={readOnly}
                      value={formData.monthlySalary}
                      onChange={(value: number | null) => handleFormDataChange({ monthlySalary: value })}
                    />
                  </Col>
                </Row>
                <Row gutter={[20, 10]}>
                  <Col span={1}>4f.</Col>
                  <Col span={13}>
                    Retirement benefits including gratuities / pension / commutation of pension / lump sum payments etc
                    from Pension / Provident Fund
                  </Col>
                  <Col span={5}>
                    <SalaryInput
                      size="small"
                      readOnly={readOnly}
                      value={formData.retirementAmt}
                      onChange={(value: number | null) => handleFormDataChange({ retirementAmt: value })}
                    />
                  </Col>
                  <Col span={5}>
                    <SalaryInput
                      size="small"
                      readOnly={readOnly}
                      value={formData.retirementAmtP}
                      onChange={(value: number | null) => handleFormDataChange({ retirementAmtP: value })}
                    />
                  </Col>
                </Row>
                <Row gutter={[20, 10]}>
                  <Col span={1}></Col>
                  <Col span={5}>Name of Fund</Col>
                  <Col span={8}>
                    <Form.Item noStyle>
                      <Input
                        size="small"
                        value={formData.retirementFundName}
                        readOnly={readOnly}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                          handleFormDataChange({ retirementFundName: event.target.value })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[20, 10]}>
                  <Col span={1}></Col>
                  <Col span={13}>Date of Payment</Col>
                  <Col span={5}>
                    <Form.Item noStyle>
                      <Input.Date
                        size="small"
                        style={{ width: '100%' }}
                        value={formData.retirementDate ? moment(formData.retirementDate) : undefined}
                        inputReadOnly={readOnly}
                        onChange={(value: moment.Moment | null) =>
                          handleFormDataChange({ retirementDate: value?.format('YYYY-MM-DD') })
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <Form.Item noStyle>
                      <Input.Date
                        size="small"
                        style={{ width: '100%' }}
                        value={formData.retirementDateP ? moment(formData.retirementDateP) : undefined}
                        inputReadOnly={readOnly}
                        onChange={(value: moment.Moment | null) =>
                          handleFormDataChange({ retirementDateP: value?.format('YYYY-MM-DD') })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[20, 10]}>
                  <Col span={1}>4g.</Col>
                  <Col span={13}>
                    Contributions made by employer to any Pension / Provident Fund constituted outside Singapore
                  </Col>
                  <Col span={5}>
                    <SalaryInput
                      size="small"
                      readOnly={readOnly}
                      value={formData.cpfErOverseas}
                      onChange={(value: number | null) => handleFormDataChange({ cpfErOverseas: value })}
                    />
                  </Col>
                  <Col span={5}>
                    <SalaryInput
                      size="small"
                      readOnly={readOnly}
                      value={formData.cpfErOverseasP}
                      onChange={(value: number | null) => handleFormDataChange({ cpfErOverseasP: value })}
                    />
                  </Col>
                </Row>
                <Row gutter={[20, 10]}>
                  <Col span={1}></Col>
                  <Col span={5}>Name of Fund</Col>
                  <Col span={8}>
                    <Form.Item noStyle>
                      <Input
                        size="small"
                        value={formData.cpfErOverseasFundName}
                        readOnly={readOnly}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                          handleFormDataChange({ cpfErOverseasFundName: event.target.value })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[20, 10]}>
                  <Col span={1}>4h.</Col>
                  <Col span={13}>Excess / Voluntary contribution to CPF by employer</Col>
                  <Col span={5}>
                    <SalaryInput
                      size="small"
                      readOnly={readOnly}
                      value={formData.excessContributionAmt}
                      onChange={(value: number | null) => handleFormDataChange({ excessContributionAmt: value })}
                    />
                  </Col>
                  <Col span={5}>
                    <SalaryInput
                      size="small"
                      readOnly={readOnly}
                      value={formData.excessContributionAmtP}
                      onChange={(value: number | null) => handleFormDataChange({ excessContributionAmtP: value })}
                    />
                  </Col>
                </Row>
                <Divider className="divider" />
                <Row gutter={[20, 10]}>
                  <Col span={1}>4i.</Col>
                  <Col span={13}>
                    Value of Benefits-in-kind
                    <Link to="#" style={{ marginLeft: 25 }}>
                      Complete Appendix 1
                    </Link>
                  </Col>
                  <Col span={5}>
                    <div className="auto-fill">{formatMoney(formData.bikAmt, 2)}</div>
                  </Col>
                  <Col span={5}>
                    <div className="auto-fill">{formatMoney(formData.bikAmtP, 2)}</div>
                  </Col>
                </Row>
                <Row gutter={[20, 10]}>
                  <Col span={1}>4j.</Col>
                  <Col span={13}>
                    Gains or profits from Employee Stock Option (ESOP) / other forms of Employee Share Ownership (ESOW)
                    Plans
                    <Link to="#" style={{ marginLeft: 25 }}>
                      Download and complete Appendix 2
                    </Link>
                  </Col>
                  <Col span={5}>
                    <div className="auto-fill">{formatMoney(formData.gainsEmStockAmt, 2)}</div>
                  </Col>
                  <Col span={5}>
                    <div className="auto-fill">{formatMoney(formData.gainsEmStockAmtP, 2)}</div>
                  </Col>
                </Row>
                <Row gutter={[20, 10]}>
                  <Col span={1}>4k.</Col>
                  <Col span={13}>
                    Employee has unexercised / unvested
                    <Link to="#" style={{ marginLeft: 25 }}>
                      Download and complete Appendix 3
                    </Link>
                  </Col>
                </Row>
                <Row gutter={[20, 10]}>
                  <Col span={1}></Col>
                  <Col span={23}>
                    <Space direction="horizontal">
                      <Checkbox
                        checked={formData.unexercisedEsopBefIndicator}
                        readOnly={readOnly}
                        onChange={(event: CheckboxChangeEvent) =>
                          handleFormDataChange({ unexercisedEsopBefIndicator: event.target.checked })
                        }
                      >
                        ESOP / ESOW granted before 1 Jan 2003
                      </Checkbox>
                      <Checkbox
                        checked={formData.unexercisedEsopAftIndicator}
                        style={{ marginLeft: 30 }}
                        readOnly={readOnly}
                        onChange={(event: CheckboxChangeEvent) => {
                          handleFormDataChange({ unexercisedEsopAftIndicator: event.target.checked })
                        }}
                      >
                        ESOP / ESOW granted on or after 1 Jan 2003 and tracking option applies
                      </Checkbox>
                    </Space>
                  </Col>
                </Row>
              </Collapse.Panel>
            </Collapse>
            <Row gutter={[20, 10]} className="mt10">
              <Col span={14} className="ta-r">
                Subtotal of items 4(a) to 4(j)
              </Col>
              <Col span={5}>
                <div className="auto-fill">{formatMoney(formData.subtotal, 2) || '0.00'}</div>
              </Col>
              <Col span={5}>
                <div className="auto-fill">{formatMoney(formData.subtotalP, 2) || '0.00'}</div>
              </Col>
            </Row>
            <Row gutter={[20, 10]}>
              <Col span={14} className="ta-r">
                Total of items 1 to 4
              </Col>
              <Col span={5}>
                <div className="auto-fill">{formatMoney(formData.total, 2) || '0.00'}</div>
              </Col>
              <Col span={5}>
                <div className="auto-fill">{formatMoney(formData.totalP, 2) || '0.00'}</div>
              </Col>
            </Row>
            <Row gutter={[20, 10]} className="my15">
              <Col span={6}>Reason for declaring nil income</Col>
              <Col span={18}>
                <Form.Item noStyle>
                  <Input
                    size="small"
                    value={formData.nilIncomeReason}
                    readOnly={readOnly}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      handleFormDataChange({ nilIncomeReason: event.target.value })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20} className="ir21-form__row-header" align="middle">
              <Col span={14}>DEDUCTIONS</Col>
              <Col span={5} className="ta-r">
                <div>Year of Cessation</div>
                <div>S$</div>
              </Col>
              <Col span={5} className="ta-r">
                <div>Year Prior to Year of Cessation</div>
                <div>S$</div>
              </Col>
            </Row>
            <Row gutter={[20, 10]}>
              <Col span={1}>5.</Col>
              <Col span={13}>
                Employee's compulsory contribution to CPF / Designated Pension or Provident Fund (less amount refundable
                / to be refunded)
              </Col>
              <Col span={5}>
                <SalaryInput
                  size="small"
                  readOnly={readOnly}
                  value={formData.cpfEmployee}
                  onChange={(value: number | null) => handleFormDataChange({ cpfEmployee: value })}
                />
              </Col>
              <Col span={5}>
                <SalaryInput
                  size="small"
                  readOnly={readOnly}
                  value={formData.cpfEmployeeP}
                  onChange={(value: number | null) => handleFormDataChange({ cpfEmployeeP: value })}
                />
              </Col>
            </Row>
            <Row gutter={[20, 10]}>
              <Col span={1}>6.</Col>
              <Col span={13}>
                Donations deducted from salary for Yayasan Mendaki Fund / Community Chest of S'pre / SINDA / CDAC / ECF
                / Other tax exempt donations
              </Col>
              <Col span={5}>
                <SalaryInput
                  size="small"
                  readOnly={readOnly}
                  value={formData.fundAmt}
                  onChange={(value: number | null) => handleFormDataChange({ fundAmt: value })}
                />
              </Col>
              <Col span={5}>
                <SalaryInput
                  size="small"
                  readOnly={readOnly}
                  value={formData.fundAmtP}
                  onChange={(value: number | null) => handleFormDataChange({ fundAmtP: value })}
                />
              </Col>
            </Row>
            <Row gutter={[20, 10]}>
              <Col span={1}>7.</Col>
              <Col span={13}>Contribution deducted from salary for Mosque Building Fund</Col>
              <Col span={5}>
                <SalaryInput
                  size="small"
                  readOnly={readOnly}
                  value={formData.mbfAmt}
                  onChange={(value: number | null) => handleFormDataChange({ mbfAmt: value })}
                />
              </Col>
              <Col span={5}>
                <SalaryInput
                  size="small"
                  readOnly={readOnly}
                  value={formData.mbfAmtP}
                  onChange={(value: number | null) => handleFormDataChange({ mbfAmtP: value })}
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  )
}
