import React, { FC, useEffect } from 'react'
import { Layout } from 'antd'
import { ErrorBoundary } from '~/components'
import './EmptyLayout.less'

const { Content } = Layout

interface EmptyLayoutProps {}

export const EmptyLayout: FC<EmptyLayoutProps> = ({ children }) => {
  useEffect(() => {
    document.body.classList.remove('admin-layout')
    document.body.classList.add('empty-layout')
  }, [])

  return (
    <Layout className="empty-layout">
      <Layout className="empty-layout__main">
        <Content className="empty-layout__content">
          <ErrorBoundary>{children}</ErrorBoundary>
        </Content>
      </Layout>
    </Layout>
  )
}
