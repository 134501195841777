import { replacePayRunSummaries, setPayRunSummaryLoading } from '../reducers'
import { apiGetPayRunSummaries } from '../api/payrun.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchPayRunSummary = (id: string, options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const loading = getState().payroll.payRunSummariesLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().payroll.payRunSummaries.entities[id]
      if (hasData) return
    }

    try {
      dispatch(setPayRunSummaryLoading(true))
      const { result, status } = await apiGetPayRunSummaries([id])
      if (status) {
        dispatch(replacePayRunSummaries(result))
      }
    } finally {
      dispatch(setPayRunSummaryLoading(false))
    }
  }
}
