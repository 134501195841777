import { showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiLockAllPayRecords } from '../api/payrecord-sg.api'
import { PayRecordSgState } from '../types'
import { setPayRecords, setPayRecordLoading } from '../reducers'

export const lockAllPayRecords = (payRunId: string): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!payRunId) return

    try {
      dispatch(setPayRecordLoading({ payRunId, loading: true }))
      const { result, status, errors, message, errorData } = await apiLockAllPayRecords(payRunId)
      if (status) {
        const payRecords = result.map(payRecordVerify => {
          const payRecord = getState().payroll.payRecords[payRunId]?.entities[payRecordVerify.id]
          const data: PayRecordSgState = { ...(payRecord as PayRecordSgState), ...payRecordVerify }
          return data
        })

        dispatch(setPayRecords({ payRunId, data: payRecords }))
      } else {
        if (Object.keys(errors).length === 0) {
          showError(message, errorData)
        }
      }
      return { errors }
    } finally {
      dispatch(setPayRecordLoading({ payRunId, loading: false }))
    }
  }
}
