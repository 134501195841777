import React, { CSSProperties, FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { FeatureFlag } from '~/constants'
import { PageHeader, Text } from '~/core-components'
import { Col, DocumentTitle, MenuLinks, Row } from '~/components'
import { PermissionGate, selectMyTenants } from '~/features/iam'
import { MenuItem } from '~/types/common'
import {
  attendanceMenus,
  claimMenus,
  leaveMenus,
  momSurveyMenus,
  payMonthlyMenus,
  payYtdMenus,
  peopleMenus
} from '../configs'
import './Reports.less'

const nameStyle: CSSProperties = { display: 'block' }
const descriptionStyle: CSSProperties = { display: 'block', fontSize: 12 }

const activeTenantCode = localStorage.getItem('tenant')

interface Report {
  name: string
  description: string
  menus: MenuItem[]
  permissions: string[]
  gridStyles?: CSSProperties
}

const peopleReport: Report = {
  name: 'People',
  description: 'People and other related information.',
  menus: peopleMenus,
  permissions: peopleMenus.map(m => m.sysPermissionId as string)
}

const payMonthlyReport: Report = {
  name: 'Payroll (Monthly)',
  description: 'Monthly payroll and other related information.',
  menus: payMonthlyMenus,
  permissions: payMonthlyMenus.map(m => m.sysPermissionId as string)
}

const payYtdReport: Report = {
  name: 'Payroll (Year-to-Date)',
  description: 'Year-to-Date payroll and other related information.',
  menus: payYtdMenus,
  permissions: payYtdMenus.map(m => m.sysPermissionId as string)
}

const leaveReport: Report = {
  name: 'Leave',
  description: 'Leave and other related information.',
  menus: leaveMenus,
  permissions: leaveMenus.map(m => m.sysPermissionId as string)
}

const claimReport: Report = {
  name: 'Claim',
  description: 'Claim and other related information.',
  menus: claimMenus,
  permissions: claimMenus.map(m => m.sysPermissionId as string)
}

const attendanceReport: Report = {
  name: 'Attendance',
  description: 'Attendance and other related information.',
  menus: attendanceMenus,
  permissions: attendanceMenus.map(m => m.sysPermissionId as string)
}

const momSurveyReport: Report = {
  name: 'MOM Survey',
  description: 'OED and other related information.',
  menus: momSurveyMenus,
  permissions: momSurveyMenus.map(m => m.sysPermissionId as string)
}

export const Reports: FC = () => {
  const myTenants = useSelector(selectMyTenants)
  const activeTenant = useMemo(() => myTenants.find(t => t.tenantCode === activeTenantCode), [myTenants])
  const hasPayroll = activeTenant?.featureFlags?.includes(FeatureFlag.Payroll)
  const hasLeave = activeTenant?.featureFlags?.includes(FeatureFlag.Leave)
  const hasClaim = activeTenant?.featureFlags?.includes(FeatureFlag.Claim)
  const hasAttendance = activeTenant?.featureFlags?.includes(FeatureFlag.Attendance)

  return (
    <div className="reports">
      <DocumentTitle title="Reports" />
      <PageHeader title="Reports" />
      <div className="reports__content">
        <Row>
          <Col>
            <ReportSection report={peopleReport} />
            {hasLeave && <ReportSection report={leaveReport} />}
            {hasClaim && <ReportSection report={claimReport} />}
            {hasAttendance && <ReportSection report={attendanceReport} />}
          </Col>
          <Col>
            {hasPayroll && <ReportSection report={payMonthlyReport} />}
            {hasPayroll && <ReportSection report={payYtdReport} />}
          </Col>
          <Col>
            <ReportSection report={momSurveyReport} />
          </Col>
        </Row>
      </div>
    </div>
  )
}

interface ReportSectionProps {
  report: Report
}

const ReportSection: FC<ReportSectionProps> = ({ report }) => (
  <PermissionGate key={report.name} sysPermissionId={report.permissions}>
    <div className="reports__section" style={report.gridStyles}>
      <Text style={nameStyle}>{report.name}</Text>
      <Text type="secondary" style={descriptionStyle}>
        {report.description}
      </Text>
      <MenuLinks className="reports__menu" menus={report.menus} />
    </div>
  </PermissionGate>
)
