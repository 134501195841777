import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { JobViewState } from '../types'

export const selectJobsView = createSelector(
  (state: StoreState) => state.job.jobsView,
  jobsState =>
    memoize((viewId: string): JobViewState => {
      const jobs = jobsState.entities[viewId] || ({} as JobViewState)
      return jobs
    })
)
