import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { LveCalendarConfigState } from '../types'
import { setLveCalendarConfig, setLveCalendarConfigLoading } from '../reducers'
import { apiUpdateCalendarConfig } from '../api/calendar-config.api'
import { getState } from '~/stores/store'
import { CalendarConfig } from '~/constants'

export const updateLveCalendarConfig = (config: LveCalendarConfigState): ThunkResult<void> => {
  return async dispatch => {
    if (!config) return

    try {
      dispatch(setLveCalendarConfigLoading(true))
      const { status, errors, message, errorData } = await apiUpdateCalendarConfig(config)

      if (status) {
        const calendarConfig = getState().leave.lveCalendarConfig.entities[CalendarConfig.LveCalendarConfigId]
        dispatch(setLveCalendarConfig({ ...(calendarConfig as LveCalendarConfigState), ...config }))
        showSuccess('Saved')
      } else {
        if (Object.keys(errors).length === 0) {
          showError(message, errorData)
        }
      }
      return { errors }
    } finally {
      dispatch(setLveCalendarConfigLoading(false))
    }
  }
}
