import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import confirm from 'antd/lib/modal/confirm'
import { Form, Input, Space } from '~/core-components'
import { Col, DrawerForm, Row, SysOptions, SelectEmployeesTable, LveKeyValues } from '~/components'
import { CriteriaFieldId, Delimiter, EmStatus, LveBuddyActionType } from '~/constants'
import { useFocus } from '~/hooks/use-focus'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors } from '~/types/store'
import { Pagination } from '~/types/common'
import { ILeaveBuddy, LeaveBuddyState } from '~/features/leave/types'
import { deleteLeaveBuddy, fetchLeaveBuddyEmSelections, saveLeaveBuddy } from '~/features/leave/actions'
import { useLeaveBuddyEm } from '~/features/leave/hooks'

export interface MutateLeaveBuddyDrawerProps {
  visible: boolean
  data?: LeaveBuddyState
  onClose: () => void
}

interface FormData extends ILeaveBuddy {}

export const EMPTY_FORM_DATA: FormData = {
  id: '',
  name: '',
  maximumBuddy: 1,
  actionType: '',
  exception: '',
  employeeIds: []
}

export const MutateLeaveBuddyDrawer: FC<MutateLeaveBuddyDrawerProps> = ({ visible, data, onClose }) => {
  const [focusRef, setFocus] = useFocus(true)
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const [leaveBuddyEm] = useLeaveBuddyEm(data?.id || '')

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setErrors(undefined)
    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  useEffect(() => {
    if (data) {
      const { id, name, maximumBuddy, actionType, exception } = data
      const employeeIds = leaveBuddyEm.map(lbe => lbe.employeeId)
      setFormData({ id, name, maximumBuddy, actionType, employeeIds, exception })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data, leaveBuddyEm])

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      result = await dispatch(saveLeaveBuddy(formData))
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_FORM_DATA)
    }
  }, [formData, onClose])

  const handleDelete = useCallback(
    (leaveBuddy: LeaveBuddyState | undefined) => {
      if (leaveBuddy) {
        const { id, name } = leaveBuddy
        confirm({
          title: 'Delete leave buddy',
          content: `Do you want to delete this leave buddy "${name}"?`,
          onOk: async () => {
            const result: ActionResult | undefined = await dispatch(deleteLeaveBuddy(id))
            if (result?.errors) {
              setErrors(result.errors)
            }
            if (!result?.errors) {
              typeof onClose === 'function' && onClose()
            }
          },
          okText: 'Delete',
          okType: 'danger'
        })
      }
    },
    [onClose]
  )

  const handleFetchEmployees = useCallback((viewId: string, pagination: Pagination, search: string) => {
    dispatch(fetchLeaveBuddyEmSelections(viewId, pagination, search))
  }, [])

  const handleSelectEmployee = useCallback((employeeIds: string[]) => {
    setFormData(formData => ({ ...formData, employeeIds }))
  }, [])

  return (
    <DrawerForm
      open={visible}
      title={data ? `Edit leave buddy` : `Add leave buddy`}
      onClose={onClose}
      confirmLoading={loading}
      width={550}
      className="mutate-leave-buddy-drawer"
      showDelete={data ? true : false}
      onDelete={() => handleDelete(data)}
      formId="form-leave-buddy"
    >
      <Form id="form-leave-buddy" onFinish={handleOk}>
        <Row>
          <Col span={24}>
            <Form.Item label="Name" validateStatus={errors?.name ? 'error' : ''} help={errors?.name}>
              <Input
                ref={focusRef}
                value={formData.name}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ name: e.target.value })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <SelectEmployeesTable
              label="Employees"
              viewName="leave_buddy"
              employeeIds={formData.employeeIds || []}
              onFetchData={handleFetchEmployees}
              onSelect={handleSelectEmployee}
              resetOnClose={true}
              resetTo={[{ criteriaFieldId: CriteriaFieldId.EmStatus, value: EmStatus.Active }]}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Criteria"
              validateStatus={errors?.maximumBuddy ? 'error' : ''}
              help={errors?.maximumBuddy}
              tooltip={{
                title:
                  'This determines the maximum number of leave buddies who can be on leave simultaneously. For example, if it is set to 2, employee will be unable to submit a leave request when there are 2 of the leave buddies are on leave on the same day.',
                icon: (
                  <span>
                    <i className="fal fa-circle-info" />
                  </span>
                )
              }}
            >
              <Space>
                <span>No. of buddy on leave</span>
                <Input.Number
                  min={1}
                  value={formData.maximumBuddy}
                  onChange={(maximumBuddy: number | null) => handleFormDataChange({ maximumBuddy })}
                />
              </Space>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Action" validateStatus={errors?.actionType ? 'error' : ''} help={errors?.actionType}>
              <SysOptions
                type="lve_buddy_action_type"
                value={formData.actionType}
                onChange={(actionType: string) => handleFormDataChange({ actionType })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row hidden={formData.actionType !== LveBuddyActionType.Block}>
          <Col span={24}>
            <Form.Item label="Exception">
              <LveKeyValues
                id="leaveType"
                mode="multiple"
                value={formData.exception ? formData.exception.split(Delimiter.pipe) : undefined}
                onChange={(value: string[]) => handleFormDataChange({ exception: value.join(Delimiter.pipe) })}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}
