import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchIr21SubmissionView } from '../actions'
import { Ir21SubmissionViewState } from '../types'

export const useIr21SubmissionView = (
  viewId: string,
  page: number,
  pageSize: number,
  fetchStrategy: FetchStrategy = 'when-empty'
): [Ir21SubmissionViewState | undefined, boolean] => {
  const data = useSelector((state: StoreState) => state.tax.ir21SubmissionViews?.entities[viewId])
  const loading = useSelector((state: StoreState) => state.tax.ir21SubmissionViewsLoading)
  const refetch = useSelector((state: StoreState) => state.tax.ir21SubmissionViewsRefetch)

  useEffect(() => {
    if (viewId)
      dispatch(
        fetchIr21SubmissionView(viewId, { offset: pageSize * (page - 1), limit: pageSize }, '', {
          strategy: fetchStrategy
        })
      )
  }, [viewId, page, pageSize, refetch, fetchStrategy])

  return [data, loading]
}
