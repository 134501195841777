import React, { FC } from 'react'
import { InfoTooltip } from '~/components'

interface OedRecordFormAlertProps {
  title: string
}

export const OedRecordFormAlert: FC<OedRecordFormAlertProps> = ({ title }) => {
  if (!title) return null

  return <InfoTooltip title={title} icon="fa-circle-exclamation" className="alert-sign" />
}
