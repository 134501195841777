import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { RangeValue } from 'rc-picker/lib/interface.d'
import { Col, DocumentTitle, PdfViewer, Row, SysOptions } from '~/components'
import {
  Button,
  Checkbox,
  CheckboxChangeEvent,
  Collapse,
  Form,
  Input,
  PageHeader,
  Select,
  Space
} from '~/core-components'
import { GroupingButton, RptScreen } from '~/features/grouping'
import { Screen, useGetViewIdByName, ViewCriteriaButton, ViewSelectionFieldList } from '~/features/selection'
import { useAttendancePeriods } from '~/features/attendance'
import { emptyGuid, RptEmStatus } from '~/constants'
import { REPORTS_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { Errors } from '~/types/store'
import { downloadWithDom, getFileTimestamp, showError } from '~/utils'
import { IRptAttendanceRecord, ReportCode } from '../../types'
import { useFirstReportTemplate, useReportTemplate } from '../../hooks'
import { refetchReportTemplates } from '../../reducers'
import { apiDownloadAttendanceRecordExcel, apiDownloadAttendanceRecordPdf } from '../../api/rpt-attendance-record.api'
import { RptDownloadBtn } from '../components/RptDownloadBtn'
import { ReportTemplate } from '../ReportTemplate/ReportTemplate'
import { RptSortingBtn } from '../components/RptSortingBtn'
import './RptAttendanceRecord.less'

const routes = [
  {
    path: REPORTS_ROUTES.main,
    breadcrumbName: 'Reports'
  },
  {
    path: '',
    breadcrumbName: 'Attendance records report'
  }
]

interface RptAttendanceRecordForm extends IRptAttendanceRecord {
  templateId: string
}

const currentYear = moment().year()

const SCREEN_CODE: Screen = 'rpt_attendance_record'
const RPT_SCREEN_CODE: RptScreen = 'rpt_attendance'
const REPORT_CODE: ReportCode = 'rpt_attendance_record'

const EMPTY_FORM_DATA: RptAttendanceRecordForm = {
  startDate: `${currentYear}-01-01`,
  endDate: `${currentYear}-12-31`,
  attendancePeriodIds: [],
  fieldIds: [],
  emStatus: RptEmStatus.all,
  sorting: 'employee_name',
  groupingCodes: [],
  pageBreaks: [],
  emPageBreak: false,
  viewId: '',
  reportTitle: '',
  notes: '',
  templateId: emptyGuid
}

export const RptAttendanceRecord: FC = () => {
  const [formData, setFormData] = useState<RptAttendanceRecordForm>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const [attendancePeriods] = useAttendancePeriods()

  const [previewUrl, setPreviewUrl] = useState<string>('')
  const [previewing, setPreviewing] = useState(false)
  const [activeKey, setActiveKey] = useState(0)

  const [firstTemplate] = useFirstReportTemplate('attendance', REPORT_CODE)
  const firstTemplateId = firstTemplate?.id || ''
  const [templateId, setTemplateId] = useState<string>()
  const [template] = useReportTemplate('attendance', REPORT_CODE, templateId)

  const [viewId] = useGetViewIdByName(SCREEN_CODE, templateId ? `${SCREEN_CODE}_${templateId}` : undefined)

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setErrors(undefined)
    setFormData(data => ({ ...data, ...updates }))
  }, [])

  useEffect(() => {
    if (!templateId && firstTemplateId) {
      setTemplateId(firstTemplateId)
    }

    if (template) {
      const saved = JSON.parse(template.dataJsonb) as RptAttendanceRecordForm
      saved.templateId = templateId || ''
      saved.emStatus = saved.emStatus || RptEmStatus.all
      saved.viewId = viewId || ''
      setFormData(formData => ({ ...formData, ...saved }))
    }
  }, [firstTemplateId, templateId, template, viewId])

  const handlePreview = useCallback(async () => {
    setPreviewing(true)
    setErrors(undefined)

    try {
      const { status, result, errors, message, errorData } = await apiDownloadAttendanceRecordPdf(formData)

      if (status) {
        const url = URL.createObjectURL(result)
        setPreviewUrl(url)
        dispatch(refetchReportTemplates())
      } else {
        console.error('Error while downloading', errors)
        setErrors(errors)
        showError(message, errorData)
      }
    } finally {
      setPreviewing(false)
    }
  }, [formData])

  const handleDownloadPdf = useCallback(async () => {
    setErrors(undefined)

    const { status, result, errors, message, errorData } = await apiDownloadAttendanceRecordPdf(formData)

    if (status) {
      const fileName = `attendance_records_report_${getFileTimestamp()}.pdf`
      downloadWithDom(result, fileName)
      dispatch(refetchReportTemplates())
    } else {
      console.error('Error while downloading', errors)
      setErrors(errors)
      showError(message, errorData)
    }
  }, [formData])

  const handleDownloadExcel = useCallback(async () => {
    const { status, result, errors, message, errorData } = await apiDownloadAttendanceRecordExcel(formData)

    if (status) {
      const fileName = `attendance_records_report_${getFileTimestamp()}.xlsx`
      downloadWithDom(result, fileName)
      dispatch(refetchReportTemplates())
    } else {
      console.error('Error while downloading', errors)
      setErrors(errors)
      showError(message, errorData)
    }
  }, [formData])

  const handleCollapse = useCallback(async () => {
    if (!activeKey) {
      setActiveKey(1)
    } else {
      setActiveKey(0)
    }
  }, [activeKey])

  const handleTemplateChange = useCallback((templateId?: string) => {
    setTemplateId(templateId)
  }, [])

  return (
    <div id="rpt-attendance-record" className="rpt-attendance-record">
      <DocumentTitle title="Attendance Records Report" />
      <PageHeader
        title="Attendance records report"
        containerId="rpt-attendance-record"
        breadcrumb={{ routes }}
        extra={
          <ReportTemplate<IRptAttendanceRecord>
            basePath="attendance"
            reportCode={REPORT_CODE}
            templateId={templateId}
            templateData={formData}
            size="small"
            onChange={handleTemplateChange}
          />
        }
      />
      <Form className="rpt-attendance-record__form" layout="horizontal" labelCol={{ flex: '130px' }}>
        <Row gutter={30}>
          <Col flex="420px">
            <Form.Item
              label="Date range"
              validateStatus={errors?.startDate || errors?.endDate ? 'error' : ''}
              help={errors?.startDate || errors?.endDate}
            >
              <Input.DateRange
                value={[
                  formData.startDate ? moment(formData.startDate) : null,
                  formData.endDate ? moment(formData.endDate) : null
                ]}
                onCalendarChange={(dates: RangeValue<moment.Moment>) => {
                  const startDate = dates && dates[0] ? dates[0].format('YYYY-MM-DD') : ''
                  const endDate = dates && dates[1] ? dates[1].format('YYYY-MM-DD') : ''
                  handleFormDataChange({ startDate, endDate })
                }}
              />
            </Form.Item>
          </Col>
          <Col flex="1"></Col>
          <Col flex="none">
            <Space>
              <RptSortingBtn
                sorting={formData.sorting}
                onSelect={(sorting: string) => handleFormDataChange({ sorting })}
              />
              <ViewCriteriaButton screenCode={SCREEN_CODE} viewId={formData.viewId} />
              <GroupingButton
                screenCode={RPT_SCREEN_CODE}
                groupingCodes={formData.groupingCodes || []}
                pageBreaks={formData.pageBreaks || []}
                onSelect={(groupingCodes: string[], pageBreaks: string[]) =>
                  handleFormDataChange({ groupingCodes, pageBreaks })
                }
              />
              <Button className="btn-more-settings" type={activeKey ? 'primary' : 'default'} onClick={handleCollapse}>
                <i className="fal fa-gear" />
              </Button>
              <Button type="primary" onClick={handlePreview} loading={previewing}>
                Preview
              </Button>
              <RptDownloadBtn onDownloadPdf={handleDownloadPdf} onDownloadExcel={handleDownloadExcel} />
            </Space>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col flex="1">
            <Form.Item
              label="Periods"
              validateStatus={errors?.attendancePeriodIds ? 'error' : ''}
              help={errors?.attendancePeriodIds}
            >
              <Select
                mode="multiple"
                maxTagCount={2}
                value={formData.attendancePeriodIds || []}
                placeholder="All attendance periods"
                optionFilterProp="title"
                onChange={(attendancePeriodIds: string[]) => handleFormDataChange({ attendancePeriodIds })}
              >
                {attendancePeriods.map(ap => (
                  <Select.Option key={ap.id} value={ap.id} title={ap.name}>
                    {ap.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col flex={1}>
            <ViewSelectionFieldList
              fieldIds={formData.fieldIds}
              screenCode="rpt_attendance_record"
              max={15}
              direction="horizontal"
              labelCol={{ flex: '130px' }}
              onChange={(fieldIds: string[]) => handleFormDataChange({ fieldIds })}
            />
          </Col>
        </Row>
        <Row>
          <Col flex="1">
            <Collapse
              className="rpt-attendance-record__more-settings"
              activeKey={activeKey}
              onChange={handleCollapse}
              noStyle
            >
              <Collapse.Panel key="1" header={null} showArrow={false}>
                <Row gutter={30}>
                  <Col flex="490px">
                    <Form.Item
                      label="Filter by employee"
                      validateStatus={errors?.emStatus ? 'error' : ''}
                      help={errors?.emStatus}
                    >
                      <SysOptions
                        allowClear={false}
                        type="rpt_em_status"
                        placeholder="All employees"
                        value={formData.emStatus}
                        onChange={(emStatus?: string) => handleFormDataChange({ emStatus })}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={30}>
                  <Col span={12}>
                    <Form.Item label="Report title">
                      <Input
                        value={formData.reportTitle}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                          handleFormDataChange({ reportTitle: event.target.value })
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Options" labelCol={{ flex: '70px' }}>
                      <Checkbox
                        checked={formData.emPageBreak}
                        onChange={(event: CheckboxChangeEvent) =>
                          handleFormDataChange({ emPageBreak: event.target.checked })
                        }
                      >
                        Page break per employee
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={30}>
                  <Col flex="1">
                    <Form.Item label="Custom notes">
                      <Input.TextArea
                        rows={3}
                        value={formData.notes}
                        onChange={(value?: ChangeEvent<HTMLTextAreaElement>) =>
                          handleFormDataChange({ notes: value?.target.value })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Collapse.Panel>
            </Collapse>
          </Col>
        </Row>
      </Form>
      {previewUrl && (
        <div className="rpt-attendance-record__pdf-viewer">
          <Row>
            <Col>
              <PdfViewer file={previewUrl} layout="landscape" scale={1.3} />
            </Col>
          </Row>
        </div>
      )}
    </div>
  )
}
