import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Alert, Button, Form, Link, Select } from '~/core-components'
import { Col, EditableCard, EditableCardState, InactiveTag, InfoTooltip, Row } from '~/components'
import { PayEncashMapState, PayItemName, selectLeaveEncashPayItems, updatePayEncashMaps } from '~/features/master'
import { selectLeaveEncashLeaveTypes } from '~/features/leave'
import { usePermissionGate } from '~/features/iam'
import { usePayEncashMaps } from '~/features/payroll'
import { Permission, PermissionAction } from '~/constants'
import { dispatch } from '~/stores/store'
import { Errors, ActionResult } from '~/types/store'
import { isInactive, newGuid } from '~/utils'
import { PayItemType } from '~/constants'
import './PayEncashMap.less'

export interface PayEncashMapProps {
  payGroupId: string
}

const RELEVANT_ERRORS = ['leaveTypeId', 'payItemId']

interface FormData {
  leaveEncashments: PayEncashMapState[]
}

const EMPTY_FORM_DATA: FormData = {
  leaveEncashments: []
}

export const PayEncashMap: FC<PayEncashMapProps> = ({ payGroupId }) => {
  const [cardState, setCardState] = useState<EditableCardState>()
  const canModify = usePermissionGate(Permission.payMaster, PermissionAction.Modify)
  const readOnly = cardState !== 'editing'
  const [errors, setErrors] = useState<Errors>()
  const relevantErrors = Object.keys(errors || {}).filter(e => RELEVANT_ERRORS.includes(e))
  const [payEncashMaps, loading] = usePayEncashMaps(payGroupId)
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)

  const anlLeaveTypes = useSelector(selectLeaveEncashLeaveTypes)(payGroupId)
  const allowancePayItems = useSelector(selectLeaveEncashPayItems)(payGroupId, PayItemType.addition)
  const deductionPayItems = useSelector(selectLeaveEncashPayItems)(payGroupId, PayItemType.deduction)

  const getNewItem = useCallback((): PayEncashMapState => {
    return {
      id: newGuid(),
      payGroupId,
      leaveTypeId: '',
      payItemId: '',
      negativePayItemId: ''
    }
  }, [payGroupId])

  const mapToState = (formData: FormData): PayEncashMapState[] => {
    return [...formData.leaveEncashments]
  }

  useEffect(() => {
    if (payEncashMaps.length > 0) {
      setFormData(mapToFormData(payEncashMaps))
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [payEncashMaps])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setErrors(undefined)
    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  const handleEdit = useCallback(() => {
    setCardState('editing')
    setFormData(formData => mapToFormData([...formData.leaveEncashments, getNewItem()]))
  }, [getNewItem])

  const handleSave = useCallback(async () => {
    if (payGroupId) {
      setCardState('saving')
      setErrors(undefined)

      let result: ActionResult | undefined
      try {
        const updates: FormData = {
          leaveEncashments: formData.leaveEncashments.filter(e => !!e.leaveTypeId || !!e.payItemId)
        }
        result = await dispatch(updatePayEncashMaps(payGroupId, mapToState(updates)))
      } catch {
        setCardState('editing')
      }

      if (result?.errors) {
        setCardState('editing')
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setCardState(undefined)
      }
    }
  }, [payGroupId, formData])

  const handleCancel = useCallback(() => {
    setCardState(undefined)
    setErrors(undefined)

    if (payEncashMaps.length > 0) {
      setFormData(mapToFormData(payEncashMaps))
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [payEncashMaps])

  return (
    <EditableCard
      className="pay-encash-map"
      title="Leave encashment"
      bodyStyle={{ paddingBottom: 6, paddingTop: 6 }}
      state={canModify ? cardState : 'readonly'}
      loading={loading}
      onEdit={handleEdit}
      onSave={handleSave}
      onCancel={handleCancel}
    >
      <Row>
        <Col span={24}>
          {errors && relevantErrors.length > 0 && (
            <Alert
              className="pay-encash-map__error"
              type="error"
              message={
                <>
                  {relevantErrors.map((error, index) => (
                    <div key={index}>{errors[error]}</div>
                  ))}
                </>
              }
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {formData.leaveEncashments.map((le, index) => (
            <Row gutter={6} key={index} className="pay-encash-map__row">
              <Col span={7}>
                {index === 0 && <div className="pay-encash-map__row-title">Leave type</div>}
                <Select
                  id="leaveType"
                  value={le.leaveTypeId}
                  showSearch
                  allowClear={false}
                  readOnly={readOnly}
                  optionFilterProp="title"
                  dropdownMatchSelectWidth={false}
                  onChange={(value: string) =>
                    handleFormDataChange({
                      leaveEncashments: [
                        ...formData.leaveEncashments.slice(0, index),
                        { ...formData.leaveEncashments[index], leaveTypeId: value },
                        ...formData.leaveEncashments.slice(index + 1, formData.leaveEncashments.length)
                      ]
                    })
                  }
                >
                  {anlLeaveTypes.map(lt => {
                    return (
                      <Select.Option key={lt?.id} value={lt?.id || ''} title={lt?.name}>
                        {isInactive(lt!.inactiveDate) && <InactiveTag />}
                        {lt?.name}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Col>
              <Col span={8}>
                {index === 0 && (
                  <div className="pay-encash-map__row-title">
                    Payroll item (positive balance)
                    <InfoTooltip title="Specify the payroll item for leave encashment when the leave balance is positive." />
                  </div>
                )}
                <Select
                  id="payItem"
                  value={le.payItemId}
                  showSearch
                  allowClear={false}
                  readOnly={readOnly}
                  optionFilterProp="title"
                  dropdownMatchSelectWidth={false}
                  onChange={(value: string) =>
                    handleFormDataChange({
                      leaveEncashments: [
                        ...formData.leaveEncashments.slice(0, index),
                        { ...formData.leaveEncashments[index], payItemId: value },
                        ...formData.leaveEncashments.slice(index + 1, formData.leaveEncashments.length)
                      ]
                    })
                  }
                >
                  {allowancePayItems.map(api => {
                    return (
                      <Select.Option key={api?.id} value={api?.id || ''} title={api?.name}>
                        {isInactive(api!.inactiveDate) && <InactiveTag />}
                        <PayItemName id={api!.id} />
                      </Select.Option>
                    )
                  })}
                </Select>
              </Col>
              <Col span={8}>
                {index === 0 && (
                  <div className="pay-encash-map__row-title">
                    Payroll item (negative balance)
                    <InfoTooltip title="Specify the payroll item for leave deduction when the leave balance is negative." />
                  </div>
                )}
                <Select
                  id="payItem"
                  value={le.negativePayItemId}
                  showSearch
                  allowClear={false}
                  readOnly={readOnly}
                  optionFilterProp="title"
                  dropdownMatchSelectWidth={false}
                  onChange={(value: string) =>
                    handleFormDataChange({
                      leaveEncashments: [
                        ...formData.leaveEncashments.slice(0, index),
                        { ...formData.leaveEncashments[index], negativePayItemId: value },
                        ...formData.leaveEncashments.slice(index + 1, formData.leaveEncashments.length)
                      ]
                    })
                  }
                >
                  {deductionPayItems.map(api => {
                    return (
                      <Select.Option key={api?.id} value={api?.id || ''} title={api?.name}>
                        {isInactive(api!.inactiveDate) && <InactiveTag />}
                        <PayItemName id={api!.id} />
                      </Select.Option>
                    )
                  })}
                </Select>
              </Col>
              <Col span={1} style={{ whiteSpace: 'nowrap' }}>
                <Button
                  size="small"
                  type="text"
                  icon={<i className="fal fa-xmark" />}
                  hidden={readOnly}
                  onClick={() =>
                    handleFormDataChange({
                      leaveEncashments: [
                        ...formData.leaveEncashments.slice(0, index),
                        ...formData.leaveEncashments.slice(index + 1, formData.leaveEncashments.length)
                      ]
                    })
                  }
                />
              </Col>
            </Row>
          ))}
          <Form.Item hidden={readOnly}>
            <Link
              onClick={() => handleFormDataChange({ leaveEncashments: [...formData.leaveEncashments, getNewItem()] })}
            >
              add more
            </Link>
          </Form.Item>
        </Col>
      </Row>
    </EditableCard>
  )
}

const mapToFormData = (dataEncashments: PayEncashMapState[]): FormData => ({
  leaveEncashments: dataEncashments
})
