import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { LeaveTypeState } from '../types'
import { fetchLeaveTypes } from '../actions'
import { selectLeaveTypeById } from '../reducers'

export const useLeaveType = (leaveTypeId: string): [LeaveTypeState | undefined, boolean] => {
  const leaveType = useSelector((state: StoreState) => selectLeaveTypeById(state, leaveTypeId))
  const loading = useSelector((state: StoreState) => state.leave.leaveTypesLoading)

  useEffect(() => {
    dispatch(fetchLeaveTypes({ strategy: 'when-empty' }))
  }, [])

  return [leaveType, loading]
}
