import { Dictionary } from '@reduxjs/toolkit'
import { setEmAddress, setEmAddressesLoading } from '../reducers'
import { apiGetEmAddressMain } from '../api/em-address.api'
import { ThunkResult } from '~/types/store'
import { EmAddressState } from '../types'
import { formatAddress } from '~/features/master'
import { IAddress } from '~/features/master/types'
import { KeyValue } from '~/types/common'

export const fetchEmAddressMain = (employeeId: string): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!employeeId) return

    try {
      dispatch(setEmAddressesLoading({ employeeId, loading: true }))
      const { result, status } = await apiGetEmAddressMain(employeeId)
      if (status) {
        const countries = getState().master.keyvalues['country']?.entities
        const stored = mapToState(result, countries)
        dispatch(setEmAddress({ employeeId, data: stored }))
      }
    } finally {
      dispatch(setEmAddressesLoading({ employeeId, loading: false }))
    }
  }
}

const mapToState = (emAddress: EmAddressState, countries: Dictionary<KeyValue>): EmAddressState => {
  const countryName = countries[emAddress.countryCode || '']?.value || ''

  return {
    ...emAddress,
    ...formatAddress(
      {
        block: emAddress.block,
        street: emAddress.street,
        level: emAddress.level,
        unit: emAddress.unit,
        building: '',
        city: emAddress.city,
        state: emAddress.state,
        postalCode: emAddress.postalCode,
        countryCode: emAddress.countryCode
      } as IAddress,
      countryName
    )
  }
}
