import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiChangeApproverLeavePendingApproval } from '../api/leave-pending-approval.api'
import { refetchLeavePendingApprovalsView } from '../reducers'
import { ILeavePendingChangeApprover } from '../types'
import { emptyGuid } from '~/constants'

export const changeApproverLeavePendingApproval = (
  leavePendingChangeApprover: ILeavePendingChangeApprover
): ThunkResult<void> => {
  return async dispatch => {
    const { status, result, errors, message, errorData } = await apiChangeApproverLeavePendingApproval({
      ...leavePendingChangeApprover,
      approverId: leavePendingChangeApprover.approverId || emptyGuid
    })

    if (status) {
      dispatch(refetchLeavePendingApprovalsView())
      showSuccess('Changed')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
