import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'

export const selectCurrentEmOtClass = createSelector(
  (state: StoreState) => state.employee.emOtClasses,
  emOtClassesState =>
    memoize(
      (employeeId?: string, id?: string) => {
        if (!employeeId || !id) return undefined

        return emOtClassesState[employeeId]?.entities[id]
      },
      (employeeId?: string, id?: string) => `${employeeId}|${id}`
    )
)

export const selectEmOtClasses = createSelector(
  (state: StoreState) => state.employee.emOtClasses,
  emOtClassesState =>
    memoize((employeeId?: string) => {
      if (!employeeId) return []

      return Object.values(emOtClassesState[employeeId]?.entities || {}).sort((a, b) => {
        const aStartDate = new Date(a!.startDate).getTime()
        const bStartDate = new Date(b!.startDate).getTime()
        return bStartDate - aStartDate
      })
    })
)
