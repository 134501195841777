import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { WfCategory, WorkflowState } from '../types'
import { fetchWorkflow } from '../actions'

export const useWorkflow = (category: WfCategory, id: string): [WorkflowState | undefined, boolean] => {
  const workflow = useSelector((state: StoreState) => state.workflow.workflows[category]?.entities[id])
  const loading = useSelector((state: StoreState) => state.workflow.workflowsLoading[category] || false)

  useEffect(() => {
    dispatch(fetchWorkflow(category, id))
  }, [category, id])

  return [workflow, loading]
}
