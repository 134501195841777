import React, { FC, useCallback, useState } from 'react'
import { Button, Card, ColumnsType, Link, PageHeader, Table } from '~/core-components'
import { DocumentTitle, InactiveTag } from '~/components'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { isInactive } from '~/utils/dateUtil'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { ExpenseTypeState } from '../../types'
import { useExpenseTypes } from '../../hooks'
import { MutateExpenseTypeDrawer } from './components/MutateExpenseTypeDrawer'
import './ExpenseTypes.less'

type ExpenseTypesTable = ExpenseTypeState

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: '',
    breadcrumbName: 'Overview'
  }
]

interface DrawerState {
  visible: boolean
  data?: ExpenseTypeState
}
const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

interface ExpenseTypesProps {}

export const ExpenseTypes: FC<ExpenseTypesProps> = () => {
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const [expenseTypes, loading] = useExpenseTypes()
  const canModify = usePermissionGate(Permission.claType, PermissionAction.Modify)

  const handleAddExpenseType = useCallback(() => {
    setDrawerState({ visible: true, data: undefined })
  }, [])

  const handleEditExpenseType = useCallback((expenseType: ExpenseTypeState) => {
    setDrawerState({ visible: true, data: expenseType })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const columns: ColumnsType<ExpenseTypesTable> = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      render: (value: string, record) => (
        <>
          {value} {isInactive(record.inactiveDate) && <InactiveTag />}
        </>
      )
    },
    {
      key: 'action',
      align: 'right',
      render: (value: string, record) =>
        canModify && (
          <Link size="small" onClick={() => handleEditExpenseType(record)}>
            edit
          </Link>
        )
    }
  ]

  return (
    <div id="expense-types" className="expense-types">
      <DocumentTitle title="Expense Types" />
      <PageHeader
        title="Expense types"
        containerId="expense-types"
        breadcrumb={{ routes }}
        extra={
          canModify && (
            <Button key="add" onClick={handleAddExpenseType}>
              Add expense type
            </Button>
          )
        }
      />
      <div className="expense-types__body">
        <Card table>
          <Table rowKey="id" dataSource={expenseTypes} pagination={false} columns={columns} loading={loading} />
        </Card>
        {canModify && <MutateExpenseTypeDrawer {...drawerState} onClose={handleCloseDrawer} />}
      </div>
    </div>
  )
}
