import { ActionResult, ThunkResult } from '~/types/store'
import { emptyGuid } from '~/constants'
import { showSuccess, showError } from '~/utils'
import { apiAddEmCategory } from '../api/em-category.api'
import { setEmCategory, setEmployee } from '../reducers'
import { IEmCategoryInfo, EmCategoryState } from '../types'

export const addEmCategory = (employeeId: string, emCategory: IEmCategoryInfo): ThunkResult<void> => {
  return async (dispatch, getState): Promise<ActionResult | undefined> => {
    if (!employeeId) return

    const { status, result, errors, message, errorData } = await apiAddEmCategory(employeeId, {
      id: emptyGuid,
      employeeId,
      ...emCategory,
      categoryId: emCategory.categoryId || emptyGuid
    })
    if (status) {
      if (result.emCategoryId) {
        const employee = getState().employee.employees.entities[employeeId]
        if (employee) {
          dispatch(setEmployee({ ...employee, emCategoryId: result.emCategoryId }))
        }
      }

      const newEmCategory = {
        id: result.id,
        employeeId,
        ...emCategory
      } as EmCategoryState
      dispatch(setEmCategory({ employeeId, data: newEmCategory }))

      showSuccess('Added')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
