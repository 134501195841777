import { request, getBaseUrl } from '~/utils/request'
import { CtExpenseTypeState } from '../types'

const baseUrl = getBaseUrl('/claim/claimtype')

export const apiGetCtExpenseTypes = (claimTypeId: string) =>
  request<CtExpenseTypeState[]>('get', `${baseUrl}/${claimTypeId}/expensetype`)

export const apiUpdateCtExpenseTypes = (claimTypeId: string, data: CtExpenseTypeState[]) =>
  request<CtExpenseTypeState[]>('post', `${baseUrl}/${claimTypeId}/expensetype`, data)
