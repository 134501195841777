import { Dictionary } from '@reduxjs/toolkit'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchLocations } from '../actions'
import { LocationState } from '../types'

export const useLocationsDict = (): [Dictionary<LocationState>, boolean] => {
  const data = useSelector((state: StoreState) => state.attendance.locations?.entities || {})
  const loading = useSelector((state: StoreState) => state.attendance.locationsLoading)

  useEffect(() => {
    dispatch(fetchLocations({ strategy: 'when-empty' }))
  }, [])

  return [data, loading]
}
