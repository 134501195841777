import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Checkbox, CheckboxChangeEvent, Form, Radio, RadioChangeEvent } from '~/core-components'
import { Col, EditableCard, EditableCardState, InfoTooltip, Row, SysOptions } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { dispatch } from '~/stores/store'
import { Errors, ActionResult, StoreState } from '~/types/store'
import {
  LveForfeitHoliday,
  LveVisibleType,
  LveAttachment,
  Permission,
  PermissionAction,
  LveApproval
} from '~/constants'
import { LeaveTypeApplyState, LeaveTypeState } from '../../../types'
import { updateLeaveType } from '../../../actions'
import { selectSysLtFieldsByCode } from '../../../selectors'
import { SelectWorkflow } from '~/features/workflow'

interface LeaveTypeApplyProps {
  leaveType?: LeaveTypeState
  onEdit?: () => void
  onSave?: () => void
  onCancel?: () => void
}

const EMPTY_FORM_DATA: LeaveTypeApplyState = {
  isSys: false,
  attachment: LveAttachment.optional,
  allowHalfDay: '',
  ssView: true,
  ssApply: true,
  approval: '',
  workflowId: '',
  forfeitHoliday: 'n',
  applyOnHoliday: 'n'
}

export const LeaveTypeApply: FC<LeaveTypeApplyProps> = ({ leaveType, onEdit, onSave, onCancel }) => {
  const [cardState, setCardState] = useState<EditableCardState>()
  const [formData, setFormData] = useState<LeaveTypeApplyState>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const sysLeaveTypeCode = leaveType?.sysLeaveTypeCode || ''
  const sysLtFields = useSelector(selectSysLtFieldsByCode)(sysLeaveTypeCode)
  const sysLtFieldsLoading = useSelector((state: StoreState) => state.leave.sysLtFieldsLoading[sysLeaveTypeCode])
  const sysColumns = useSelector((state: StoreState) => state.leave.ltSysColumns[leaveType?.id || ''] || [])
  const canModify = usePermissionGate(Permission.lveType, PermissionAction.Modify)

  useEffect(() => {
    if (leaveType) {
      const { isSys, attachment, allowHalfDay, ssView, ssApply, approval, workflowId, forfeitHoliday, applyOnHoliday } =
        leaveType

      setFormData({
        isSys,
        attachment,
        allowHalfDay,
        ssView,
        ssApply,
        approval,
        workflowId,
        forfeitHoliday,
        applyOnHoliday
      })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [leaveType])

  const handleEdit = useCallback(() => {
    setCardState('editing')
    typeof onEdit === 'function' && onEdit()
  }, [onEdit])

  const handleSave = useCallback(async () => {
    if (leaveType) {
      setCardState('saving')
      setErrors(undefined)

      typeof onSave === 'function' && onSave()

      let result: ActionResult | undefined
      try {
        result = await dispatch(updateLeaveType(leaveType.id, leaveType, formData))
      } catch {
        setCardState('editing')
      }

      if (result?.errors) {
        setCardState('editing')
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setCardState(undefined)
      }
    }
  }, [leaveType, formData, onSave])

  const handleCancel = useCallback(() => {
    typeof onCancel === 'function' && onCancel()
    setCardState(undefined)
    setErrors(undefined)

    if (leaveType) {
      const { isSys, attachment, allowHalfDay, ssView, ssApply, approval, workflowId, forfeitHoliday, applyOnHoliday } =
        leaveType
      setFormData({
        isSys,
        attachment,
        allowHalfDay,
        ssView,
        ssApply,
        approval,
        workflowId,
        forfeitHoliday,
        applyOnHoliday
      })
    }
  }, [leaveType, onCancel])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(data => ({ ...data, ...updates }))
  }, [])

  const isVisible = useCallback(
    (fieldName: string) => {
      return sysLtFields[fieldName]?.visibleType === LveVisibleType.basic
    },
    [sysLtFields]
  )

  const readOnly = cardState !== 'editing' && cardState !== 'saving'

  const hasAttachment = isVisible('attachment')
  const hasAllowHalfDay = isVisible('allow_half_day')
  const hasSsView = isVisible('ss_view')
  const hasSsApply = isVisible('ss_apply')
  const hasApproval = isVisible('approval')
  const hasForfeitHoliday = isVisible('forfeit_holiday')
  const hasApplyOnHoliday = isVisible('apply_on_holiday')
  const hasForm = hasAttachment || hasAllowHalfDay || hasApproval || hasForfeitHoliday || hasApplyOnHoliday

  if (!hasForm && !sysLtFieldsLoading) return null

  return (
    <EditableCard
      title="Leave request setting"
      bodyStyle={{ paddingBottom: leaveType && !sysLtFieldsLoading ? 6 : 24, paddingTop: 6 }}
      loading={sysLtFieldsLoading}
      state={!canModify ? 'readonly' : cardState}
      // state={!canModify || formData.isSys ? 'readonly' : cardState}
      formId="form-leavetype-apply"
      onEdit={handleEdit}
      onSave={handleSave}
      onCancel={handleCancel}
    >
      <Row gutter={30}>
        {hasAllowHalfDay && (
          <Col span={12}>
            <Form.Item
              label="Enable half day request"
              validateStatus={errors?.allowHalfDay ? 'error' : ''}
              help={errors?.allowHalfDay}
            >
              <SysOptions
                type="lve_allow_half_day"
                allowClear={false}
                value={formData.allowHalfDay}
                readOnly={readOnly}
                disabled={sysColumns.includes('allowHalfDay')}
                onChange={(allowHalfDay: string) => handleFormDataChange({ allowHalfDay })}
              />
            </Form.Item>
          </Col>
        )}
        {hasAttachment && (
          <Col span={12}>
            <Form.Item label="Attachment" validateStatus={errors?.attachment ? 'error' : ''} help={errors?.attachment}>
              <SysOptions
                type="lve_attachment"
                allowClear={false}
                value={formData.attachment}
                readOnly={readOnly}
                disabled={sysColumns.includes('attachment')}
                onChange={(attachment: string) => handleFormDataChange({ attachment })}
              />
            </Form.Item>
          </Col>
        )}
      </Row>
      <Row gutter={30}>
        {(hasSsView || hasSsApply) && (
          <Col span={12}>
            <Form.Item
              label="Self-service access"
              validateStatus={errors?.ssView || errors?.ssApply ? 'error' : ''}
              help={errors?.ssView || errors?.ssApply}
            >
              <Checkbox
                checked={formData.ssView}
                readOnly={readOnly}
                onChange={(e: CheckboxChangeEvent) => {
                  const updates: Partial<LeaveTypeApplyState> = { ssView: e.target.checked }
                  if (!e.target.checked) {
                    updates.ssApply = false
                  }
                  handleFormDataChange(updates)
                }}
              >
                View entitlement
              </Checkbox>
              <Checkbox
                checked={formData.ssApply}
                readOnly={readOnly}
                disabled={!readOnly && !formData.ssView}
                onChange={(e: CheckboxChangeEvent) => handleFormDataChange({ ssApply: e.target.checked })}
              >
                Apply leave
              </Checkbox>
            </Form.Item>
          </Col>
        )}
        {hasApplyOnHoliday && (
          <Col span={12}>
            <Form.Item
              label="Allow leave requests on holidays"
              validateStatus={errors?.applyOnHoliday ? 'error' : ''}
              help={errors?.applyOnHoliday}
            >
              <SysOptions
                type="yes_no_yn"
                allowClear={false}
                value={formData.applyOnHoliday}
                readOnly={readOnly}
                disabled={sysColumns.includes('applyOnHoliday')}
                onChange={(applyOnHoliday: string) => handleFormDataChange({ applyOnHoliday })}
              />
            </Form.Item>
          </Col>
        )}
      </Row>
      {hasApproval && (
        <Row gutter={30}>
          <Col span={12}>
            <Form.Item label="Approval" validateStatus={errors?.approval ? 'error' : ''} help={errors?.approval}>
              <SysOptions
                type="lve_approval"
                readOnly={readOnly}
                value={formData.approval}
                onChange={(approval: string) => handleFormDataChange({ approval })}
              />
            </Form.Item>
          </Col>
          <Col span={12} hidden={formData.approval !== LveApproval.workflow}>
            <Form.Item label="Workflow" validateStatus={errors?.workflowId ? 'error' : ''} help={errors?.workflowId}>
              <SelectWorkflow
                category="lve_approval"
                readOnly={readOnly}
                value={formData.workflowId}
                onChange={(workflowId: string) => handleFormDataChange({ workflowId })}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
      {hasForfeitHoliday && (
        <Row gutter={30}>
          <Col span={12}>
            <Form.Item
              label={
                <>
                  Forfeit holidays during the leave period
                  <InfoTooltip title="Include the holidays in the calculation of leave duration if the leave is taken before and after the holidays." />
                </>
              }
              validateStatus={errors?.forfeitHoliday ? 'error' : ''}
              help={errors?.forfeitHoliday}
            >
              <Radio.Group
                value={formData.forfeitHoliday}
                readOnly={readOnly}
                disabled={sysColumns.includes('forfeitHoliday')}
                onChange={(event: RadioChangeEvent) => handleFormDataChange({ forfeitHoliday: event.target.value })}
              >
                <Radio value={LveForfeitHoliday.yes}>Yes</Radio>
                <Radio value={LveForfeitHoliday.no}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      )}
    </EditableCard>
  )
}
