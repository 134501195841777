import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { EmManagerSecondaryState, IEmManagerSecondary } from '../types'

const baseUrl = getBaseUrl('/employee/employee')

export const apiGetEmManagerSecondaries = (employeeId: string) =>
  request<EmManagerSecondaryState[]>('get', `${baseUrl}/${employeeId}/managersecondary`)

export const apiGetEmManagerSecondary = (employeeId: string, emManagerSecondaryId: string) =>
  request<EmManagerSecondaryState>('get', `${baseUrl}/${employeeId}/managersecondary/${emManagerSecondaryId}`)

export const apiAddEmManagerSecondary = (employeeId: string, emManagerSecondary: IEmManagerSecondary) =>
  request<{ id: string; emManagerSecondaryId?: string }>(
    'post',
    `${baseUrl}/${employeeId}/managersecondary`,
    emManagerSecondary
  )

export const apiUpdateEmManagerSecondary = (employeeId: string, id: string, emManagerSecondary: Operation[]) =>
  request<{ emManagerSecondaryId?: string }>(
    'patch',
    `${baseUrl}/${employeeId}/managersecondary/${id}`,
    emManagerSecondary
  )

export const apiDeleteEmManagerSecondary = (employeeId: string, id: string) =>
  request<{ emManagerSecondaryId?: string }>('delete', `${baseUrl}/${employeeId}/managersecondary/${id}`)
