import React, { FC, useCallback, useMemo, useState } from 'react'
import moment from 'moment-timezone'
import { Card, ColumnsType, Form, Select, Table } from '~/core-components'
import { DownloadFileAuth } from '~/components'
import { formatDateRange, getBaseUrl } from '~/utils'
import { SSPayslipFilter, SSPayslipState } from '../../../../types'
import { useMyPayslipFilters, useMyPayslips } from '../../../../hooks'
import './MyPayslips.less'

interface MyPayslipsProps {}

const baseUrl = getBaseUrl('/payroll-sg')

export const MyPayslips: FC<MyPayslipsProps> = () => {
  const [filter, setFilter] = useState<SSPayslipFilter>('6m')
  const [filterOptions, filterLoading] = useMyPayslipFilters()
  const [payslips, loading] = useMyPayslips(filter)

  const handleFilterChange = useCallback((value: string) => {
    if (value) {
      setFilter(value as SSPayslipFilter)
    }
  }, [])

  const columns: ColumnsType<SSPayslipState> = useMemo(
    () => [
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name',
        render: (value: string, record) => (
          <span>
            {value} for {formatDateRange(record.startDate, record.endDate)}
          </span>
        )
      },
      {
        title: 'Period',
        key: 'startDate',
        dataIndex: 'startDate',
        render: (value: string) => moment(value).format('MMM YYYY')
      },
      {
        key: 'action',
        align: 'right',
        render: (_value: string, record) => (
          <DownloadFileAuth url={`${baseUrl}/sspayslip/${record.payRunId}/pdf`}>download</DownloadFileAuth>
        )
      }
    ],
    []
  )

  return (
    <Card
      className="my-payslips"
      extra={
        <Form.Item label="Show" colon={false}>
          <Select value={filter} onChange={handleFilterChange} allowClear={false} loading={filterLoading}>
            {filterOptions.map(item => (
              <Select.Option key={item.value} value={item.value}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      }
    >
      <Table rowKey="id" dataSource={payslips} pagination={false} columns={columns} loading={loading}></Table>
    </Card>
  )
}
