import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchMyClaimRecordAttachments } from '../actions'
import { selectMyClaimRecordAttachments } from '../selectors'
import { SSClaimRecordAttachmentState } from '../types'

export const useMyClaimRecordAttachments = (claimRecordId?: string): [SSClaimRecordAttachmentState[], boolean] => {
  const claimRecordAttachments = useSelector(selectMyClaimRecordAttachments)(
    claimRecordId || ''
  ) as SSClaimRecordAttachmentState[]
  const loading = useSelector((state: StoreState) => state.myClaim.myClaimRecordAttachmentsLoading[claimRecordId || ''])

  useEffect(() => {
    if (claimRecordId) {
      dispatch(fetchMyClaimRecordAttachments(claimRecordId))
    }
  }, [claimRecordId])

  return [claimRecordAttachments, loading]
}
