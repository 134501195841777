import React, { FC } from 'react'
import moment from 'moment-timezone'
import { Descriptions } from '~/core-components'
import { Fund } from '~/constants'
import { EmployeeState } from '../../../../../types'
import { useSysOptions } from '../../../../../hooks'

interface CopyEmFundProps {
  employee?: EmployeeState
}

export const CopyEmFund: FC<CopyEmFundProps> = ({ employee }) => {
  const [funds] = useSysOptions('fund')

  return (
    <>
      <Descriptions bordered column={1} className="copy-em__content">
        <Descriptions.Item label="Self-help group (SHG) fund">
          {funds[employee?.fundCode || '']?.value}
        </Descriptions.Item>
        {employee?.fundCode === Fund.exclude && (
          <Descriptions.Item label="Until">
            {employee?.fundExcludeDate ? moment(employee.fundExcludeDate).format('DD MMM YYYY') : ''}
          </Descriptions.Item>
        )}
        {![Fund.exclude, Fund.notAvailable].includes(employee?.fundCode || '') && !!employee?.fundCode2 && (
          <Descriptions.Item label="Additional SHG fund">{funds[employee?.fundCode2 || '']?.value}</Descriptions.Item>
        )}
      </Descriptions>
    </>
  )
}
