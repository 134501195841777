import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { RptScreen, SysGroupingState } from '../types'

export const selectSysGroupings = createSelector(
  (state: StoreState) => state.grouping.sysGroupings,
  sysGroupingsState =>
    memoize((screenCode: RptScreen) => {
      return (Object.values(sysGroupingsState[screenCode]?.entities || {}) as SysGroupingState[]) || []
    })
)
