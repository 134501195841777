import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { EmManagerState, IEmManager } from '../types'

const baseUrl = getBaseUrl('/employee/employee')

export const apiGetEmManagers = (employeeId: string) =>
  request<EmManagerState[]>('get', `${baseUrl}/${employeeId}/manager`)

export const apiGetEmManager = (employeeId: string, emManagerId: string) =>
  request<EmManagerState>('get', `${baseUrl}/${employeeId}/manager/${emManagerId}`)

export const apiAddEmManager = (employeeId: string, emManager: IEmManager) =>
  request<{ id: string; emManagerId?: string }>('post', `${baseUrl}/${employeeId}/manager`, emManager)

export const apiUpdateEmManager = (employeeId: string, id: string, emManager: Operation[]) =>
  request<{ emManagerId?: string }>('patch', `${baseUrl}/${employeeId}/manager/${id}`, emManager)

export const apiDeleteEmManager = (employeeId: string, id: string) =>
  request<{ emManagerId?: string }>('delete', `${baseUrl}/${employeeId}/manager/${id}`)
