import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Card, ColumnsType, Link, PageHeader, Switch, Table } from '~/core-components'
import { DocumentTitle } from '~/components'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { MutateBankDrawer } from './components/MutateBankDrawer'
import { fetchBanks, updateBank } from '../../actions'
import { selectBanks } from '../../selectors'
import { BankState } from '../../types'
import { mapBankStateToBank } from '../../types/bank.mapper'
import './Banks.less'

type BankTable = BankState

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: '',
    breadcrumbName: 'Overview'
  }
]

interface DrawerState {
  visible: boolean
  data?: BankState
}
const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

interface BankProps {}

export const Banks: FC<BankProps> = () => {
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const banks = useSelector(selectBanks)
  const loading = useSelector((state: StoreState) => state.master.banksLoading)
  const [updatingVisible, setUpdatingVisible] = useState<string>()
  const canModify = usePermissionGate(Permission.master, PermissionAction.Modify)

  useEffect(() => {
    dispatch(fetchBanks())
  }, [])

  const handleAddBank = useCallback(() => {
    setDrawerState({ visible: true, data: undefined })
  }, [])

  const handleEditBank = useCallback((bank: BankState) => {
    setDrawerState({ visible: true, data: bank })
  }, [])

  const handleVisibleChange = useCallback(async (bank: BankState, isShown: boolean) => {
    setUpdatingVisible(bank.code)
    try {
      const current = mapBankStateToBank(bank)
      await dispatch(updateBank(bank.code, current, { ...current, isShown }))
    } finally {
      setUpdatingVisible(undefined)
    }
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const columns: ColumnsType<BankTable> = [
    {
      title: 'Code',
      key: 'code',
      dataIndex: 'code'
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name'
    },
    {
      title: 'Swift code',
      key: 'swiftCode',
      dataIndex: 'swiftCode'
    },
    {
      title: 'Visible',
      key: 'isShown',
      dataIndex: 'isShown',
      align: 'center',
      width: 100,
      render: (value: boolean, record) => (
        <Switch
          checkedChildren="Shown"
          unCheckedChildren="Hidden"
          checked={record.isShown}
          loading={updatingVisible === record.code}
          disabled={!canModify}
          onChange={(checked: boolean) => {
            handleVisibleChange(record, checked)
          }}
        />
      )
    },
    {
      key: 'action',
      align: 'right',
      render: (value: string, record) =>
        !record.isSeed && (
          <Link size="small" onClick={() => handleEditBank(record)}>
            edit
          </Link>
        )
    }
  ]

  return (
    <div id="banks" className="banks">
      <DocumentTitle title="Banks" />
      <PageHeader
        title="Banks"
        containerId="banks"
        breadcrumb={{ routes }}
        extra={
          canModify && (
            <Button key="add" onClick={handleAddBank}>
              Add bank
            </Button>
          )
        }
      />
      <div className="banks__body">
        <Card table>
          <Table
            rowKey="code"
            dataSource={banks}
            pagination={false}
            columns={columns}
            loading={loading}
            rowClassName={record => (record.isShown ? 'banks__table-row--shown' : '')}
          />
        </Card>
        {canModify && <MutateBankDrawer {...drawerState} onClose={handleCloseDrawer} />}
      </div>
    </div>
  )
}
