import { replaceMyPayslips, setMyPayslipsLoading } from '../reducers'
import { apiGetMyPayslips } from '../api/ss-payslip.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'
import { SSPayslipFilter } from '../types'

export const fetchMyPayslips = (
  filter?: SSPayslipFilter,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().myPayroll.myPayslipsLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().myPayroll.myPayslips?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setMyPayslipsLoading(true))
      const { result, status } = await apiGetMyPayslips(filter)
      if (status) dispatch(replaceMyPayslips(result))
    } finally {
      dispatch(setMyPayslipsLoading(false))
    }
  }
}
