import React, { forwardRef } from 'react'
import classNames from 'classnames'
import { Select, SelectProps } from '~/core-components'
import { useWorkflows } from '../../hooks'
import { WorkflowState, WfCategory } from '../../types'

export interface SelectWorkflowProps extends SelectProps {
  category: WfCategory
  value: string
  onChange: (value: string) => void
}

const SelectWorkflowInternal = ({ category, className, ...props }: SelectWorkflowProps, ref: React.Ref<any>) => {
  const classes = classNames('select-workflow', { [`${className}`]: className })
  const [workflows] = useWorkflows(category)

  return (
    <Select ref={ref} className={classes} {...props}>
      {(Object.values(workflows) as WorkflowState[]).map(e => (
        <Select.Option key={e.id} value={e.id}>
          {e.name}
        </Select.Option>
      ))}
    </Select>
  )
}

export const SelectWorkflow = forwardRef(SelectWorkflowInternal)
