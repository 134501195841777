import React, { FC } from 'react'
import { Route, RouteProps, Switch } from 'react-router-dom'
import { EMPTY_ROUTES } from '~/routes/routes'
import { EmptyLayout } from '~/layouts/EmptyLayout/EmptyLayout'
import { MyLeaveTaskToken } from '../features/my/containers/Home/components/MyTask/MyLeaveTaskToken/MyLeaveTaskToken'
import { MyClaimTaskToken } from '../features/my-claim/containers/MyClaimTaskToken/MyClaimTaskToken'
import { MyPayslipToken } from '../features/my/containers/Payroll/MyPayslipToken/MyPayslipToken'
import { MyTimeLogTaskToken } from '~/features/my-attendance/containers/MyTimeLogTaskToken/MyTimeLogTaskToken'

export const routes: RouteProps[] = [
  {
    path: EMPTY_ROUTES.leaveTask,
    component: MyLeaveTaskToken,
    exact: false
  },
  {
    path: EMPTY_ROUTES.claimTask,
    component: MyClaimTaskToken,
    exact: false
  },
  {
    path: EMPTY_ROUTES.payslip,
    component: MyPayslipToken,
    exact: true
  },
  {
    path: EMPTY_ROUTES.timeLogTask,
    component: MyTimeLogTaskToken,
    exact: true
  }
]

export const EmptyRoutes: FC = () => {
  return (
    <EmptyLayout>
      <Switch>
        {routes.map(({ path, ...props }) => (
          <Route key={path as string} exact path={path} {...props} />
        ))}
      </Switch>
    </EmptyLayout>
  )
}
