import React, { CSSProperties, FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Table, ColumnsType, SecondaryText } from '~/core-components'
import { fetchEmpKeyvalues, selectQualificationKeyvalues } from '~/features/master'
import { dispatch } from '~/stores/store'
import { EmEducationState } from '../../../../../types'
import { selectEmEducations } from '../../../../../selectors'

interface CopyEmEducationsProps {
  employeeId?: string
}

type EmEducationTable = EmEducationState
const labelQualificationStyle: CSSProperties = { lineHeight: 1.1 }
const labelMajorStyle: CSSProperties = { fontSize: 12 }

export const CopyEmEducations: FC<CopyEmEducationsProps> = ({ employeeId }) => {
  const emEducations = useSelector(selectEmEducations)(employeeId)
  const qualifications = useSelector(selectQualificationKeyvalues)

  useEffect(() => {
    dispatch(fetchEmpKeyvalues('qualification', undefined, undefined, { strategy: 'when-empty' }))
  }, [])

  const columns: ColumnsType<EmEducationTable> = [
    {
      title: 'Qualification',
      key: 'qualificationId',
      dataIndex: 'qualificationId',
      render: (value: string, record) => (
        <>
          <div style={labelQualificationStyle}>{qualifications[value]?.value}</div>
          <SecondaryText style={labelMajorStyle}>{record.major}</SecondaryText>
        </>
      )
    },
    {
      title: 'Institute',
      key: 'institute',
      dataIndex: 'institute'
    },
    {
      title: 'Graduation year',
      key: 'graduationYear',
      dataIndex: 'graduationYear',
      render: (value: string, record) => {
        const toYear = record.endDate ? moment(record.endDate).format('YYYY') : ''
        return value || toYear
      }
    },
    {
      title: 'Notes',
      key: 'notes',
      dataIndex: 'notes',
      ellipsis: true
    }
  ]

  return (
    <Table rowKey="id" dataSource={emEducations} pagination={false} columns={columns} className="copy-em__content" />
  )
}
