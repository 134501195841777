import { setDailyRecordsByEmployeeView, setDailyRecordsByEmployeeViewLoading } from '../reducers'
import { apiGetDailyRecordsByEmployeeView } from '../api/daily-record.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, DateRange, FetchOptions } from '~/types/common'

export const fetchDailyRecordsByEmployeeView = (
  viewId: string,
  employeeId: string,
  startDate: string | null,
  endDate: string | null,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!viewId || !startDate || !endDate) return

    const loading = getState().attendance.dailyRecordsByEmployeeViewLoading[employeeId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().attendance.dailyRecordsByEmployeeView[employeeId]?.entities[viewId]
      if (hasData) return
    }

    try {
      dispatch(setDailyRecordsByEmployeeViewLoading({ employeeId, loading: true }))
      const { result, status } = await apiGetDailyRecordsByEmployeeView(viewId, employeeId, {
        startDate,
        endDate
      } as DateRange)
      if (status) {
        dispatch(setDailyRecordsByEmployeeView({ employeeId, data: result }))
      }
    } finally {
      dispatch(setDailyRecordsByEmployeeViewLoading({ employeeId, loading: false }))
    }
  }
}
