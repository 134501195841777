import React, { FC } from 'react'
import { Select, SelectProps } from '~/core-components'
import { useXeroContacts } from '../../hooks'

interface XeroSelectContactProps extends SelectProps {
  tenantId?: string
}

export const XeroSelectContact: FC<XeroSelectContactProps> = ({ tenantId, ...props }) => {
  const [contacts] = useXeroContacts(tenantId)

  return (
    <Select showSearch optionFilterProp="title" dropdownMatchSelectWidth={false} {...props}>
      {contacts.map(a => (
        <Select.Option key={a.id} value={a.id} title={a.name}>
          {a.name}
        </Select.Option>
      ))}
    </Select>
  )
}
