import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { FetchStrategy } from '~/types/common'
import { fetchBreaks } from '../actions'
import { BreakState } from '../types'
import { selectBreaks } from '../selectors'

export const useBreaks = (fetchStrategy: FetchStrategy = 'always'): [BreakState[], boolean] => {
  const data = useSelector(selectBreaks)
  const loading = useSelector((state: StoreState) => state.attendance.breaksLoading)

  useEffect(() => {
    dispatch(fetchBreaks({ strategy: fetchStrategy }))
  }, [fetchStrategy])

  return [data, loading]
}
