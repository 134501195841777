import { replacePayRunSummaries, setPayRunSummaryLoading } from '../reducers'
import { apiGetPayRunSummaries } from '../api/payrun.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchPayRunSummaries = (
  ids: string[],
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!ids || ids.length === 0) return

    const loading = getState().payroll.payRunSummariesLoading
    if (loading) return

    let fetchIds = ids
    if (options.strategy === 'when-empty') {
      const cached = Object.keys(getState().payroll.payRunSummaries.entities || {})
      const newIds = ids.filter(id => !cached.includes(id))
      if (newIds.length === 0) {
        return
      }
      fetchIds = newIds
    }

    try {
      dispatch(setPayRunSummaryLoading(true))
      const { result, status } = await apiGetPayRunSummaries(fetchIds)
      if (status) {
        dispatch(replacePayRunSummaries(result))
      }
    } finally {
      dispatch(setPayRunSummaryLoading(false))
    }
  }
}
