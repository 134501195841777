import React, { FC, useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Link as ClickableLink, SecondaryLink, SecondaryText, Space, SpaceProps, Tooltip } from '~/core-components'
import { DEFAULT_COLOR } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { Permission } from '~/constants'
import { useShiftsDict } from '../../../hooks'
import './ShiftName.less'

interface ShiftNameProps extends SpaceProps {
  id?: string
  description?: string
  path?: string | (() => void)
  hideNewTab?: boolean
  fallbackShiftName?: string
}

export const ShiftName: FC<ShiftNameProps> = ({
  id,
  description,
  path,
  hideNewTab,
  fallbackShiftName = '',
  children,
  ...props
}) => {
  const [shifts] = useShiftsDict()
  const shift = shifts[id || '']
  const canView = usePermissionGate(Permission.shift)
  const newTabPath = useMemo(() => (id ? SETTINGS_ROUTES.shift.replace(':id', id) : ''), [id])

  const handleNewTabClick = useCallback(() => {
    window.open(newTabPath, '_blank')
  }, [newTabPath])

  if (!id) return <>{fallbackShiftName}</>

  return (
    <Space {...props} className="shift-name">
      <div className="shift-name__name">
        <div>
          <Space>
            <div className="shift-name__color" style={{ backgroundColor: shift?.color || DEFAULT_COLOR }}></div>
            {path ? (
              typeof path === 'string' ? (
                <Link to={path} rel="noopener noreferrer">
                  {shift?.name || fallbackShiftName}
                </Link>
              ) : (
                <ClickableLink onClick={path}>{shift?.name}</ClickableLink>
              )
            ) : (
              shift?.name || fallbackShiftName
            )}
            {canView && newTabPath !== path && !hideNewTab && (
              <Tooltip title="Open shift in a new tab">
                <SecondaryLink className="shift-name__new-tab-link" onClick={handleNewTabClick} tabIndex={-1}>
                  <i className="fal fa-arrow-up-right-from-square" />
                </SecondaryLink>
              </Tooltip>
            )}
          </Space>
        </div>
        {description && <SecondaryText size="small">{description}</SecondaryText>}
        {children}
      </div>
    </Space>
  )
}

interface ShiftNameComponentProps extends SpaceProps {
  color?: string
  name?: string
}

export const ShiftNameComponent: FC<ShiftNameComponentProps> = ({ name, color, children, ...props }) => (
  <Space {...props} className="shift-name">
    <div className="shift-name__color" style={{ backgroundColor: color || DEFAULT_COLOR }}></div>
    <div className="shift-name__name">{name}</div>
    {children}
  </Space>
)
