import { Collapse as InternalCollapse } from './Collapse'
import { CollapsePanel } from './CollapsePanel'

type TCollapse = typeof InternalCollapse
interface ICollapse extends TCollapse {
  Panel: typeof CollapsePanel
}

const Collapse: ICollapse = InternalCollapse as ICollapse
Collapse.Panel = CollapsePanel

export { Collapse }
