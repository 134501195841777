import React, { FC, useCallback, useEffect, useState } from 'react'
import { Form, ModalTristate } from '~/core-components'
import { Col, Row, SalaryInput } from '~/components'
import { useFocus } from '~/hooks/use-focus'
import { getTaxAmountLabel } from '../../../util'
import { apiGetTaxAmount } from '../../../api/tax-rate.api'
import { ITaxAmount } from '../../../types'

interface EditTaxAmountModalProps {
  visible: boolean
  currencyCode?: string
  amount: number
  taxAmount: number
  isUserOverride: boolean
  onApply?: (taxAmount: number, isReset: boolean) => void
  onClose: () => void
}

interface FormData {
  taxAmount: number
  isReset: boolean
}

const EMPTY_FORM_DATA: FormData = {
  taxAmount: 0,
  isReset: false
}

export interface EditTaxAmountModalState {
  visible: boolean
  currencyCode?: string
  amount: number
  taxAmount: number
  isUserOverride: boolean
}

export const DEFAULT_EDIT_TAX_AMOUNT_MODAL_STATE: EditTaxAmountModalState = {
  visible: false,
  currencyCode: '',
  amount: 0,
  taxAmount: 0,
  isUserOverride: false
}

export const EditTaxAmountModal: FC<EditTaxAmountModalProps> = ({
  visible,
  currencyCode,
  amount,
  taxAmount,
  isUserOverride,
  onApply,
  onClose
}) => {
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)
  const [focusRef, setFocus] = useFocus(true)

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
  }, [visible, setFocus])

  useEffect(() => {
    if (taxAmount != null) {
      setFormData({ taxAmount, isReset: !isUserOverride })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [taxAmount, isUserOverride])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => setFormData(formData => ({ ...formData, ...updates })),
    []
  )

  const handleOk = useCallback(async () => {
    const request: ITaxAmount = { currencyCode, amount }
    const taxAmount = await apiGetTaxAmount(request)

    typeof onApply === 'function' && onApply(formData.taxAmount, taxAmount.result === formData.taxAmount)

    setFormData(EMPTY_FORM_DATA)
    typeof onClose === 'function' && onClose()
  }, [formData, amount, currencyCode, onApply, onClose])

  const handleReset = useCallback(async () => {
    const request: ITaxAmount = { currencyCode, amount }
    const taxAmount = await apiGetTaxAmount(request)

    setFormData(formData => ({ ...formData, taxAmount: taxAmount.result, isReset: true }))
  }, [amount, currencyCode])

  const taxLabel = getTaxAmountLabel(currencyCode, 'amount')

  return (
    <ModalTristate
      visible={visible}
      title="Edit"
      width={350}
      yesText="Apply"
      noText="Reset"
      onYes={handleOk}
      onNo={handleReset}
      onCancel={onClose}
      className="edit-tax-amount-modal"
    >
      <Form>
        <Row>
          <Col span={24}>
            <Form.Item label={taxLabel}>
              <SalaryInput
                ref={focusRef}
                min={0}
                value={formData.taxAmount}
                onChange={taxAmount => handleFormDataChange({ taxAmount })}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </ModalTristate>
  )
}
