import { replaceMyCalendarLeaveRecords, setMyCalendarLeaveRecordsLoading } from '../../reducers'
import { apiGetMyCalendarLeaveRecords } from '../../api/ss-leave-record-calendar.api'
import { ThunkResult } from '~/types/store'
import { DateRange } from '~/types/common'

export const fetchMyCalendarLeaveRecords = (viewAs: string, dateRange: DateRange): ThunkResult<void> => {
  return async dispatch => {
    if (!viewAs || !dateRange.startDate || !dateRange.endDate) return

    try {
      dispatch(setMyCalendarLeaveRecordsLoading(true))
      const { result, status } = await apiGetMyCalendarLeaveRecords(viewAs, dateRange)
      if (status) dispatch(replaceMyCalendarLeaveRecords(result))
    } finally {
      dispatch(setMyCalendarLeaveRecordsLoading(false))
    }
  }
}
