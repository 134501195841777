import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiDeleteWfScenario } from '../api/workflow.api'
import { WfCategory } from '../types'
import { fetchWfEvents } from './fetch-wf-events'

export const deleteWfScenario = (
  category: WfCategory,
  flowType: string,
  workflowId: string,
  wfEventId: string
): ThunkResult<void> => {
  return async dispatch => {
    if (!category || !flowType || !workflowId || !wfEventId) return

    const { status, errors, message, errorData } = await apiDeleteWfScenario(category, workflowId, wfEventId)

    if (status) {
      dispatch(fetchWfEvents(workflowId))
      showSuccess('Deleted')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
