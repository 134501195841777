import { setA8aForm, setA8aFormLoading } from '../reducers'
import { apiGetA8aForm } from '../api/ytd.api'
import { ThunkResult } from '~/types/store'
import { A8aFormState } from '../types'

export const fetchA8aForm = (
  ytdYear: string,
  employerTaxNo: string,
  employeeTaxNo: string,
  ytdSubmissionId: string
): ThunkResult<void> => {
  return async dispatch => {
    try {
      dispatch(setA8aFormLoading(true))
      const { result, status } = await apiGetA8aForm(ytdYear, employerTaxNo, employeeTaxNo, ytdSubmissionId)
      if (status) {
        const stored = mapToState(result)
        dispatch(setA8aForm(stored))
      }
    } finally {
      dispatch(setA8aFormLoading(false))
    }
  }
}

const mapToState = (a8aForm: A8aFormState): A8aFormState => {
  a8aForm.leavePassageTotal = a8aForm.leavePassageAmt + a8aForm.leavePassageAdj
  a8aForm.interestTotal = a8aForm.interestAmt + a8aForm.interestAdj
  a8aForm.insuranceTotal = a8aForm.insuranceAmt + a8aForm.insuranceAdj
  a8aForm.holidayTotal = a8aForm.holidayAmt + a8aForm.holidayAdj
  a8aForm.educationTotal = a8aForm.educationAmt + a8aForm.educationAdj
  a8aForm.otherTotal = a8aForm.otherAmt + a8aForm.otherAdj
  a8aForm.clubTotal = a8aForm.clubAmt + a8aForm.clubAdj
  a8aForm.gainTotal = a8aForm.gainAmt + a8aForm.gainAdj
  a8aForm.vehicleTotal = a8aForm.vehicleAmt + a8aForm.vehicleAdj
  a8aForm.carTotal = a8aForm.carAmt + a8aForm.carAdj
  a8aForm.placeOfResidenceTaxableValue = a8aForm.premisesAmt + a8aForm.furnitureAmt + a8aForm.rentAmt
  a8aForm.placeOfResidenceTaxableTotal = a8aForm.placeOfResidenceTaxableValue - a8aForm.rentEmAmt
  a8aForm.utilitiesTaxableValue = a8aForm.utilitiesAmt + a8aForm.driverAmt + a8aForm.servantAmt
  a8aForm.hotelAccomodationTaxableValue = a8aForm.hotelAmt - a8aForm.hotelEmAmt

  return a8aForm
}
