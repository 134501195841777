import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { EmLeaveGroupState, IEmLeaveGroup } from '../types'

const baseUrl = getBaseUrl('/employee/employee')

export const apiGetEmLeaveGroups = (employeeId: string) =>
  request<EmLeaveGroupState[]>('get', `${baseUrl}/${employeeId}/leavegroup`)

export const apiGetEmLeaveGroup = (employeeId: string, emLeaveGroupId: string) =>
  request<EmLeaveGroupState>('get', `${baseUrl}/${employeeId}/leavegroup/${emLeaveGroupId}`)

export const apiAddEmLeaveGroup = (employeeId: string, emLeaveGroup: IEmLeaveGroup) =>
  request<{ id: string; emLeaveGroupId?: string }>('post', `${baseUrl}/${employeeId}/leavegroup`, emLeaveGroup)

export const apiUpdateEmLeaveGroup = (employeeId: string, id: string, emLeaveGroup: Operation[]) =>
  request<{ emLeaveGroupId?: string }>('patch', `${baseUrl}/${employeeId}/leavegroup/${id}`, emLeaveGroup)

export const apiDeleteEmLeaveGroup = (employeeId: string, id: string) =>
  request<{ emLeaveGroupId?: string }>('delete', `${baseUrl}/${employeeId}/leavegroup/${id}`)
