import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { ManagerState, ManagerCategory } from '../types'

export const selectManager = createSelector(
  (state: StoreState) => state.manager.managers,
  managersState =>
    memoize(
      (category: ManagerCategory, id: string): ManagerState | undefined => {
        const managers = Object.values(managersState[category]?.entities || {}) as ManagerState[]
        return managers.find(mgr => mgr.id === id)
      },
      (category: ManagerCategory, id: string) => `${category}|${id}`
    )
)
