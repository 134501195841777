import React, { FC, useCallback, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Link, LinkProps, Spin } from '../../core-components'
import { request, timeout } from '~/utils/request'

interface DownloadFileAuthProps extends LinkProps {
  url: string
}

export const DownloadFileAuth: FC<DownloadFileAuthProps> = ({ url, ...props }: DownloadFileAuthProps) => {
  const [loading, setLoading] = useState(false)

  const handleFileDownload = useCallback(async () => {
    if (url) {
      const token = localStorage.getItem('access_token')
      const tenant = localStorage.getItem('tenant') || ''

      try {
        setLoading(true)

        const { result, status } = await request('get', `${url}`, undefined, {
          responseType: 'blob',
          timeout,
          headers: {
            Authorization: `Bearer ${token}`,
            'X-Tenant': tenant
          }
        })
        if (status) {
          let objectUrl = window.URL.createObjectURL(result)
          window.open(objectUrl, '_blank')
        }
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    }
  }, [url])

  if (loading) return <Spin indicator={<LoadingOutlined spin />} />

  return <Link {...props} onClick={handleFileDownload} />
}
