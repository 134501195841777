import { replaceLeaveApplyFormInfo, setLeaveApplyFormInfoLoading } from '../reducers'
import { apiGetApplyFormSettings } from '../api/leave-apply.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchLeaveApplyFormInfo = (
  leaveTypeId: string,
  employeeId: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!leaveTypeId) return
    if (!employeeId) return

    const emLtId = `${employeeId}||${leaveTypeId}`

    const loading = getState().leave.leaveApplyFormInfoLoading[emLtId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().leave.leaveApplyFormInfo[emLtId]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setLeaveApplyFormInfoLoading({ emLtId, loading: true }))
      const { result, status } = await apiGetApplyFormSettings(leaveTypeId, employeeId)
      if (status) {
        dispatch(replaceLeaveApplyFormInfo({ emLtId, data: result }))
      }
    } finally {
      dispatch(setLeaveApplyFormInfoLoading({ emLtId, loading: false }))
    }
  }
}
