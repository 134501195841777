import React, { FC, useEffect } from 'react'
import { dispatch } from '~/stores/store'
import { fetchLeaveTypes } from '~/features/leave'
import { fetchPayItems } from '../../../actions'
import { PayEncashMap } from './PayEncashMap'
import { PayLeaveMap } from './PayLeaveMap'
import { PayClaimMap } from './PayClaimMap'
import { PayDailyMap } from './PayDailyMap'

export interface PayGroupIntegrationProps {
  payGroupId: string
}

export const PayGroupIntegration: FC<PayGroupIntegrationProps> = ({ payGroupId }) => {
  useEffect(() => {
    dispatch(fetchLeaveTypes())
    dispatch(fetchPayItems('sg'))
  }, [])

  return (
    <>
      <PayEncashMap payGroupId={payGroupId} />
      <PayDailyMap payGroupId={payGroupId} />
      <PayLeaveMap payGroupId={payGroupId} />
      <PayClaimMap payGroupId={payGroupId} />
    </>
  )
}
