import { replaceCompanies, setCompaniesLoading } from '../reducers'
import { apiGetCompanies } from '../api/company.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchCompanies = (options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().master.companiesLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().master.companies?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setCompaniesLoading(true))
      const { result, status } = await apiGetCompanies()
      if (status) {
        dispatch(replaceCompanies(result))
      }
    } finally {
      dispatch(setCompaniesLoading(false))
    }
  }
}
