import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { MasterName, MasterState } from '../types'

export const selectMasters = createSelector(
  (state: StoreState) => state.master.masters,
  mastersState =>
    memoize((masterName: MasterName): MasterState[] => {
      const masters = Object.values(mastersState[masterName]?.entities || {}) as MasterState[]
      return masters.sort((a, b) => {
        const aInactiveDate = new Date(a!.inactiveDate || '').getTime() || 0
        const bInactiveDate = new Date(b!.inactiveDate || '').getTime() || 0

        if (bInactiveDate === 0 && aInactiveDate === 0) {
          return a!.name.localeCompare(b!.name)
        } else {
          return aInactiveDate - bInactiveDate
        }
      })
    })
)
