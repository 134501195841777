import { replaceMyTimeLogTasks, setMyTimeLogTasksLoading } from '../reducers'
import { apiGetMyTimeLogTasks } from '../api/ss-time-log-task.api'
import { ThunkResult } from '~/types/store'

export const fetchMyTimeLogTasks = (): ThunkResult<void> => {
  return async dispatch => {
    try {
      dispatch(setMyTimeLogTasksLoading(true))
      const { result, status } = await apiGetMyTimeLogTasks()
      if (status) dispatch(replaceMyTimeLogTasks(result))
    } finally {
      dispatch(setMyTimeLogTasksLoading(false))
    }
  }
}
