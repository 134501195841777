import React, { FC, ReactNode, useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { Link as ClickableLink, SecondaryLink, SecondaryText, Space, SpaceProps, Tooltip } from '~/core-components'
import { usePermissionGate } from '~/features/iam'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { Permission } from '~/constants'
import { useClaimTypesDict } from '../../../hooks'
import './ClaimTypeName.less'

interface ClaimTypeNameProps {
  id: string
  description?: string
  path?: string | (() => void)
  extra?: ReactNode
  hideNewTab?: boolean
}

const DEFAULT_ICON = 'fa-credit-card'

export const ClaimTypeName: FC<ClaimTypeNameProps> = ({ id, description, path, hideNewTab, children, extra }) => {
  const [claimTypes] = useClaimTypesDict()
  const iconClasses = classNames('claim-type-name__icon', 'fal', claimTypes[id]?.icon ?? DEFAULT_ICON, {
    'claim-type-name__icon--small': !description
  })
  const canView = usePermissionGate(Permission.claType)
  const newTabPath = useMemo(() => SETTINGS_ROUTES.claimType.replace(':id', id), [id])

  const handleNewTabClick = useCallback(() => {
    window.open(newTabPath, '_blank')
  }, [newTabPath])

  return (
    <Space className="claim-type-name" align="start">
      <i className={iconClasses} />
      <div className="claim-type-name__name">
        <div>
          <Space>
            {path ? (
              typeof path === 'string' ? (
                <Link to={path} rel="noopener noreferrer">
                  {claimTypes[id]?.name}
                </Link>
              ) : (
                <ClickableLink onClick={path}>{claimTypes[id]?.name}</ClickableLink>
              )
            ) : (
              claimTypes[id]?.name
            )}
            {canView && newTabPath !== path && !hideNewTab && (
              <Tooltip title="Open claim type in a new tab">
                <SecondaryLink className="claim-type-name__new-tab-link" onClick={handleNewTabClick} tabIndex={-1}>
                  <i className="fal fa-arrow-up-right-from-square" />
                </SecondaryLink>
              </Tooltip>
            )}
          </Space>
        </div>
        {description && <SecondaryText size="small">{description}</SecondaryText>}
        {children}
      </div>
      {extra}
    </Space>
  )
}

interface ClaimTypeNameComponentProps extends SpaceProps {
  icon?: string
  name?: string
}

export const ClaimTypeNameComponent: FC<ClaimTypeNameComponentProps> = ({ name, icon, children, ...props }) => (
  <Space {...props} className="claim-type-name">
    <i className={classNames('claim-type-name__icon', 'fal', icon ?? DEFAULT_ICON)} />
    <div className="claim-type-name__name">{name}</div>
    {children}
  </Space>
)
