import { replaceMyLeaveRecordDtls, setMyLeaveRecordDtlsLoading } from '../../reducers'
import { apiGetMyLeaveRecordDtls } from '../../api/ssleave-record.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchLeaveRecordDtls = (id: string, options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const loading = getState().my.myLeaveRecordDtlsLoading[id]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().my.myLeaveRecordDtls[id]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setMyLeaveRecordDtlsLoading({ id, loading: true }))
      const { result, status } = await apiGetMyLeaveRecordDtls(id)
      if (status) dispatch(replaceMyLeaveRecordDtls({ id, data: result }))
    } finally {
      dispatch(setMyLeaveRecordDtlsLoading({ id, loading: false }))
    }
  }
}
