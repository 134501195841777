import { replacePayDailyMaps, setPayDailyMapsLoading } from '../reducers'
import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiUpdatePayDailyMaps } from '../api/pay-daily-map.api'
import { PayDailyMapState } from '../types'

export const updatePayDailyMaps = (payGroupId: string, data: PayDailyMapState[]): ThunkResult<void> => {
  return async dispatch => {
    if (!payGroupId) return

    try {
      dispatch(setPayDailyMapsLoading({ id: payGroupId, loading: true }))
      const { status, result, errors, message, errorData } = await apiUpdatePayDailyMaps(payGroupId, data)

      if (status) {
        dispatch(replacePayDailyMaps({ id: payGroupId, data: result }))
        showSuccess('Saved')
      } else {
        if (Object.keys(errors).length === 0) {
          showError(message, errorData)
        }
      }
      return { errors }
    } finally {
      dispatch(setPayDailyMapsLoading({ id: payGroupId, loading: false }))
    }
  }
}
