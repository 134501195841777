import React, { FC } from 'react'
import { Space } from '~/core-components'
import { TimeDayDisplay } from '~/components'

interface ShiftTimeProps {
  startTime?: string
  startDay: number
  endTime?: string
  endDay: number
}

export const ShiftTime: FC<ShiftTimeProps> = ({ startTime, startDay, endTime, endDay }) => {
  return (
    <Space className="shift-time">
      <TimeDayDisplay time={startTime} day={startDay} />
      <span> - </span>
      <TimeDayDisplay time={endTime} day={endDay} />
    </Space>
  )
}
