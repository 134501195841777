import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { LeavePendingApprovalViewState } from '../types'

export const selectLeavePendingApprovalsView = createSelector(
  (state: StoreState) => state.leave.leavePendingApprovalsView,
  leavePendingApprovalsState =>
    memoize((viewId: string): LeavePendingApprovalViewState => {
      const leavePendingApprovals = leavePendingApprovalsState.entities[viewId] || ({} as LeavePendingApprovalViewState)
      return leavePendingApprovals
    })
)
