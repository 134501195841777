import React, { forwardRef } from 'react'
import { default as AntDatePicker, RangePickerProps as AntRangePickerProps } from 'antd/lib/date-picker'
import 'antd/es/date-picker/style'

export type MonthProps = AntRangePickerProps & {}

const { RangePicker: AntRangePicker } = AntDatePicker

const MonthRangeInternal = (props: MonthProps, ref: React.Ref<any>) => (
  <AntRangePicker
    ref={ref}
    allowClear={!props.inputReadOnly}
    picker="month"
    format="YYYY-MM"
    popupStyle={props.inputReadOnly ? { display: 'none' } : {}}
    {...props}
  />
)

export const MonthRange = forwardRef(MonthRangeInternal)
