import React, { FC } from 'react'
import { Descriptions } from '~/core-components'
import { EmployeeState } from '../../../../../types'
import { useSysOptions } from '../../../../../hooks'

interface CopyEmEmergencyProps {
  employee?: EmployeeState
}

export const CopyEmEmergency: FC<CopyEmEmergencyProps> = ({ employee }) => {
  const [relationships] = useSysOptions('relationship')

  if (!employee) return null

  return (
    <>
      {!!employee.emergencyName2 && <div className="copy-em__content copy-em__subtitle">Primary</div>}
      <Descriptions bordered column={1} className="copy-em__content">
        <Descriptions.Item label="Name">{employee.emergencyName}</Descriptions.Item>
        <Descriptions.Item label="Relationship">
          {relationships[employee.emergencyRelation || '']?.value}
        </Descriptions.Item>
        <Descriptions.Item label="Contact no.">{employee.emergencyPhone}</Descriptions.Item>
      </Descriptions>
      {!!employee.emergencyName2 && (
        <>
          <div className="copy-em__content copy-em__subtitle">Secondary</div>
          <Descriptions bordered column={1} className="copy-em__content">
            <Descriptions.Item label="Name">{employee.emergencyName2}</Descriptions.Item>
            <Descriptions.Item label="Relationship">
              {relationships[employee.emergencyRelation2 || '']?.value}
            </Descriptions.Item>
            <Descriptions.Item label="Contact no.">{employee.emergencyPhone2}</Descriptions.Item>
          </Descriptions>
        </>
      )}
    </>
  )
}
