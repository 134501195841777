import { setPayRuns, setPayRunLoading } from '../reducers'
import { apiGetPayRuns } from '../api/payrun.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'
import { PayRunState } from '../types'

export const fetchPayRunsByPeriod = (
  payPeriods: string[],
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().payroll.payRunsLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const payRuns = Object.values(getState().payroll.payRuns) as PayRunState[]
      const hasData = payPeriods.every(period => payRuns.some(pr => pr.payPeriod === period))
      if (hasData) return
    }

    try {
      dispatch(setPayRunLoading(true))
      const { result, status } = await apiGetPayRuns({ payPeriods })
      if (status) {
        dispatch(setPayRuns(result))
      }
    } finally {
      dispatch(setPayRunLoading(false))
    }
  }
}
