import { replaceTenantConfigOptions, setTenantConfigOptionsLoading } from '../reducers'
import { apiGetTenantConfigOptions } from '../api/tenant-config.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchTenantConfigOptions = (
  code: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().iam.tenantConfigOptionsLoading[code]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().iam.tenantConfigOptions[code]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setTenantConfigOptionsLoading({ code, loading: true }))
      const { result, status } = await apiGetTenantConfigOptions(code)
      if (status) {
        dispatch(replaceTenantConfigOptions({ code, data: result }))
      }
    } finally {
      dispatch(setTenantConfigOptionsLoading({ code, loading: false }))
    }
  }
}
