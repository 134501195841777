import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiDeleteCompanyLogo } from '../api/company.api'
import { CompanyState } from '../types'
import { setCompany } from '../reducers'

export const deleteCompanyLogo = (id: string): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return
    const { status, errors, message, errorData } = await apiDeleteCompanyLogo(id)
    if (status) {
      const employee = getState().master.companies.entities[id]
      dispatch(setCompany({ ...(employee as CompanyState), logoId: '' }))
      showSuccess('Logo removed')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
