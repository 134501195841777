import { showSuccess, showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { apiAddExpenseType } from '../api/expense-type.api'
import { setExpenseType } from '../reducers'
import { IExpenseType, ExpenseTypeState } from '../types'
import { setKeyvalue } from '~/features/master'

export const addExpenseType = (expenseType: IExpenseType): ThunkResult<void> => {
  return async (dispatch): Promise<ActionResult> => {
    const { status, result, errors, message, errorData } = await apiAddExpenseType(expenseType)
    if (status) {
      const newExpenseType: ExpenseTypeState = { id: result.id, ...expenseType }
      dispatch(setExpenseType(newExpenseType))
      dispatch(setKeyvalue({ kv: 'expenseType', data: { key: result.id, value: expenseType.name } }))
      showSuccess('Added')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
