import React, { FC, useCallback, useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { ZealysLoading } from '~/components'
import { apiGetMyPayslipByToken } from '~/features/my/api/sspayslip-token.api'
import { useIsMountedRef } from '~/hooks'
import { downloadWithDom, getFileTimestamp, showError } from '~/utils'
import './MyPayslipToken.less'

interface MyPayslipTokenProps {}

export const MyPayslipToken: FC<MyPayslipTokenProps> = () => {
  const match = useRouteMatch<{ tenant_code: string; token: string }>()
  const token = match.params.token
  const tenantCode = match.params.tenant_code
  const isMountedRef = useIsMountedRef()
  const [downloading, setDownloading] = useState(false)

  const download = useCallback(async () => {
    if (token) {
      try {
        setDownloading(true)
        const { status, result, errors, message, errorData } = await apiGetMyPayslipByToken(token)

        if (status) {
          const fileName = `payslip_${getFileTimestamp()}.pdf`
          downloadWithDom(result, fileName)
        } else {
          console.error('Error while downloading', errors)
          showError(message, errorData)
        }
      } finally {
        if (isMountedRef.current) setDownloading(false)
      }
    }
  }, [token, isMountedRef])

  useEffect(() => {
    if (tenantCode) {
      localStorage.setItem('tenant', tenantCode)
      download()
    }
  }, [tenantCode, download])

  return (
    <div className="my-payslip-token">
      {downloading ? (
        <ZealysLoading text="Please wait while we are downloading" />
      ) : (
        <div>You may close this window</div>
      )}
    </div>
  )
}
