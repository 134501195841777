import React, { forwardRef } from 'react'
import classNames from 'classnames'
import { Select } from '~/core-components'
import { SelectProps } from '~/core-components/Select/Select'
import { Screen } from '../../types'
import { useSysSelectionFieldsOptGroup } from '../../hooks'
import './SelectionFields.less'

interface SelectionFieldsProps extends SelectProps {
  screenCode: Screen
}

const SelectionFieldsInternal = ({ screenCode, className, ...props }: SelectionFieldsProps, ref: React.Ref<any>) => {
  const classes = classNames('selection-fields', { [`${className}`]: className })
  const [fields] = useSysSelectionFieldsOptGroup(screenCode)

  return (
    <Select
      ref={ref}
      className={classes}
      suffixIcon={<i className="fal fa-angle-down" />}
      optionFilterProp="label"
      optionLabelProp="label"
      options={fields}
      allowClear={false}
      {...props}
    />
  )
}

export const SelectionFields = forwardRef(SelectionFieldsInternal)
