import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { apiUpdateEmEducation } from '../api/em-education.api'
import { EmEducationState, IEmEducationInfo } from '../types'
import { replaceEmEducations, setEmEducation } from '../reducers'

export const updateEmEducation = <T extends IEmEducationInfo>(
  employeeId: string,
  id: string,
  before: T,
  request: T
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!employeeId || !id) return

    const after = { ...before, ...request } as T
    const patch = generateJsonPatch<T>(before, after)
    const { status, errors, message, errorData } = await apiUpdateEmEducation(employeeId, id, patch)

    if (status) {
      if (!before.isHighest && after.isHighest) {
        let newEmEducations: EmEducationState[] = []
        const emEducations = getState().employee.emEducations[employeeId]?.entities || {}
        const newEmEducation: EmEducationState = {
          ...(emEducations[id] as EmEducationState),
          ...after
        }

        newEmEducations.push(newEmEducation)
        Object.values(emEducations).forEach(c => {
          if (c?.employeeId === employeeId && c?.id !== id) {
            newEmEducations.push({ ...c, isHighest: false })
          }
        })

        dispatch(replaceEmEducations({ employeeId, data: newEmEducations }))
      } else {
        let emEducation = getState().employee.emEducations[employeeId]?.entities[id]
        dispatch(setEmEducation({ employeeId, data: { ...(emEducation as EmEducationState), ...after } }))
      }

      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
