import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { WfEventState } from '../types'
import { fetchWfEvents } from '../actions'
import { selectWfEvents } from '../selectors'

export const useWfEvents = (workflowId: string, flowType: string): [WfEventState[], boolean] => {
  const wfEvents = useSelector(selectWfEvents)(workflowId, flowType)
  const loading = useSelector((state: StoreState) => state.workflow.wfEventsLoading[workflowId] || false)

  useEffect(() => {
    dispatch(fetchWfEvents(workflowId))
  }, [workflowId])

  return [wfEvents, loading]
}
