import React, { forwardRef } from 'react'
import { KeyValues, KeyValuesProps } from './KeyValues'

interface ClaKeyValuesProps extends Omit<KeyValuesProps, 'basePath'> {}

const ClaKeyValuesInternal = (props: ClaKeyValuesProps, ref: React.Ref<any>) => (
  <KeyValues ref={ref} basePath="claim" {...props} />
)

export const ClaKeyValues = forwardRef(ClaKeyValuesInternal)
