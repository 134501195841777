import { request, getBaseUrl } from '~/utils/request'
import { SSTimeLogTaskState } from '../types'

const baseUrl = getBaseUrl('/attendance/sstimelogtask')

export const apiGetMyTimeLogTasks = () => request<SSTimeLogTaskState[]>('get', baseUrl)

export const apiGetMyTimeLogTask = (id: string) => request<SSTimeLogTaskState>('get', `${baseUrl}/${id}`)

export const apiApproveTimeLog = (id: string, comment: string) =>
  request('post', `${baseUrl}/${id}/approve`, { comment })

export const apiRejectTimeLog = (id: string, comment: string) => request('post', `${baseUrl}/${id}/reject`, { comment })
