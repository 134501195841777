import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import { Delimiter, FeatureFlag } from '~/constants'
import { Button, Card, CardProps, Select, Space } from '~/core-components'
import { Col, Row } from '~/components'
import { useLocations } from '~/features/attendance'
import { dispatch } from '~/stores/store'
import { usePollingEffect } from '~/hooks'
import { refetchRealTimeAttendance } from '../../../reducers'
import { useMyEmployee, useRealTimeAttendance, useRealTimeSummary } from '../../../hooks'
import { MySsAccessGate } from '../../MySsAccessGate'
import { SSRealTimeAttendanceList } from './SSRealTimeAttendanceList'
import './SSRealTimeAttendance.less'

interface SSRealTimeAttendanceProps extends CardProps {}

const AUTO_REFRESH_INTERVAL = 1 * 60000

export const SSRealTimeAttendance: FC<SSRealTimeAttendanceProps> = ({ className, ...props }) => {
  const classes = classNames('ssreal-time-attendance', { [`${className}`]: className })
  const [locationId, setLocationId] = useState<string>()
  const [attendance, loading] = useRealTimeAttendance(locationId)
  const [summary] = useRealTimeSummary(locationId)
  const [locations, locationsLoading] = useLocations()
  const [me] = useMyEmployee()

  const adminLocations = useMemo(() => {
    return locations.filter(
      x => me.attributes && (me.attributes['location_admins']?.split(Delimiter.comma) || []).includes(x.id)
    )
  }, [me, locations])

  const onShifts = useMemo(() => attendance.filter(r => r.status === 'on_shift'), [attendance])
  const clocked = useMemo(() => attendance.filter(r => r.status === 'clocked'), [attendance])
  const absent = useMemo(() => attendance.filter(r => r.status === 'absent'), [attendance])

  useEffect(() => {
    if (locationId == null && adminLocations?.length > 0) {
      setLocationId(adminLocations[0].id)
    }
  }, [adminLocations, locationId])

  const fetchRealTimeAttendance = useCallback(async () => {
    await dispatch(refetchRealTimeAttendance())
    await dispatch(refetchRealTimeAttendance())
  }, [])

  usePollingEffect(fetchRealTimeAttendance, { intervalSeconds: AUTO_REFRESH_INTERVAL })

  if (adminLocations == null || adminLocations.length === 0) {
    return null
  }

  return (
    <MySsAccessGate featureFlag={FeatureFlag.MyAttendance}>
      <Card title="Real-time attendance" className={classes} loading={loading} {...props}>
        <Row gutter={5}>
          <Col flex={1}>
            <Select
              className="ssreal-time-attendance__filter-location"
              showSearch
              allowClear={false}
              optionFilterProp="title"
              loading={locationsLoading}
              value={locationId}
              onChange={locationId => setLocationId(locationId)}
            >
              {adminLocations.map(l => (
                <Select.Option key={l.id} value={l.id} title={l.name}>
                  {l.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col>
            <Button icon={<i className="fal fa-rotate-right" />} onClick={fetchRealTimeAttendance} type="text" />
          </Col>
        </Row>
        <div>
          <Space size="large">
            <AttendanceCountBox count={summary?.scheduled || 0} label="Scheduled" />
            <AttendanceCountBox count={summary?.onShift || 0} label="On shift" />
            <AttendanceCountBox count={summary?.absent || 0} label="Absent" />
          </Space>
        </div>
        <div className="ssreal-time-attendance__list">
          {attendance.length === 0 && !loading && <div>No attendance record</div>}
          <SSRealTimeAttendanceList data={onShifts} />
          <SSRealTimeAttendanceList data={clocked} />
          <SSRealTimeAttendanceList data={absent} />
        </div>
      </Card>
    </MySsAccessGate>
  )
}

interface AttendanceCountBoxProps {
  count: number
  label: string
}

const AttendanceCountBox: FC<AttendanceCountBoxProps> = ({ count, label }) => {
  return (
    <div className="attendance-count-box">
      <div className="attendance-count-box__count">{count}</div>
      <div className="attendance-count-box__label">{label}</div>
    </div>
  )
}
