import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { ColumnsType, Form, Link, LinkGroup, Space, Table, Tag } from '~/core-components'
import { Col, EmpKeyValues, Row } from '~/components'
import { fetchEmpKeyvalues } from '~/features/master'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { useToggle } from '~/hooks/use-toggle'
import { useFirstInView } from '~/hooks/use-first-in-view'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { MutateEmManagerSecondaryDrawer } from './MutateEmManagerSecondaryDrawer'
import { fetchEmManagerSecondaries } from '../../../actions'
import { selectCurrentEmManagerSecondary, selectEmManagerSecondaries } from '../../../selectors'
import { IEmManagerSecondaryInfo, EmManagerSecondaryState } from '../../../types'

interface EmManagerSecondariesProps {
  employeeId?: string
  id?: string
}

type EmManagerSecondaryTable = EmManagerSecondaryState

const EMPTY_FORM_DATA: IEmManagerSecondaryInfo = {
  startDate: '',
  managerSecondaryId: ''
}

interface DrawerState {
  visible: boolean
  employeeId?: string
  data?: EmManagerSecondaryState
  isCurrent: boolean
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false, isCurrent: false }

const formStyle: CSSProperties = { padding: '0 24px' }
const linkGroupStyle: CSSProperties = { position: 'absolute', top: 0, right: 0 }

export const EmManagerSecondaries: FC<EmManagerSecondariesProps> = ({ employeeId, id }: EmManagerSecondariesProps) => {
  const { ref, inView } = useFirstInView<HTMLDivElement>({ threshold: 0.25 })
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const [formData, setFormData] = useState<IEmManagerSecondaryInfo>(EMPTY_FORM_DATA)
  const current = useSelector(selectCurrentEmManagerSecondary)(employeeId, id)
  const histories = useSelector(selectEmManagerSecondaries)(employeeId)
  const employees = useSelector((state: StoreState) => state.employee.emKeyValues?.entities || {})
  const [showHistory, toggleShowHistory] = useToggle()
  const canModify = usePermissionGate(Permission.employeeCareer, PermissionAction.Modify)
  const loading = useSelector((state: StoreState) => state.employee.emManagerSecondariesLoading[employeeId || ''])

  useEffect(() => {
    if (inView) {
      dispatch(fetchEmpKeyvalues('employee', undefined, undefined, { strategy: 'when-empty' }))
    }
  }, [inView])

  useEffect(() => {
    if (inView) {
      if (employeeId) {
        dispatch(fetchEmManagerSecondaries(employeeId))
      }
    }
  }, [inView, employeeId])

  useEffect(() => {
    if (showHistory && histories.length === 0) {
      toggleShowHistory()
    }
  }, [histories.length, showHistory, toggleShowHistory])

  useEffect(() => {
    if (current) {
      const { startDate, managerSecondaryId } = current
      setFormData({ startDate, managerSecondaryId })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [current])

  const handleEditManagerSecondary = useCallback(
    (emManagerSecondary?: EmManagerSecondaryState) => {
      setDrawerState({ visible: true, employeeId, data: emManagerSecondary, isCurrent: id === emManagerSecondary?.id })
    },
    [employeeId, id, setDrawerState]
  )

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const columns: ColumnsType<EmManagerSecondaryTable> = [
    {
      title: 'Effective date',
      key: 'startDate',
      dataIndex: 'startDate',
      width: 130,
      render: (value: string) => value && moment(value).format('DD MMM YYYY')
    },
    {
      title: 'Dotted line reporting to',
      key: 'managerSecondaryId',
      dataIndex: 'managerSecondaryId',
      render: (value: string, record) =>
        value && (
          <Space>
            {employees[value]?.value}
            {record.id === id && <Tag type="primary">current</Tag>}
          </Space>
        )
    },
    {
      key: 'action',
      align: 'right',
      render: (value: string, record) =>
        canModify && (
          <Link size="small" onClick={() => handleEditManagerSecondary(record)}>
            edit
          </Link>
        )
    }
  ]

  return (
    <div ref={ref}>
      <Form style={formStyle}>
        <Row hidden={showHistory}>
          <Col span={24}>
            <Form.Item
              className="form-item-label--block"
              label={
                <>
                  <span>Dotted line reporting to</span>
                  {!loading && (
                    <LinkGroup style={linkGroupStyle}>
                      {canModify && (
                        <Link size="small" onClick={() => handleEditManagerSecondary(current)}>
                          {current ? 'edit' : 'add'}
                        </Link>
                      )}
                      {(histories?.length || 0) > 0 && (
                        <Link size="small" onClick={toggleShowHistory}>
                          {showHistory ? 'hide history' : 'history'}
                        </Link>
                      )}
                    </LinkGroup>
                  )}
                </>
              }
            >
              <EmpKeyValues id="employee" value={formData.managerSecondaryId} readOnly />
            </Form.Item>
          </Col>
        </Row>
        {showHistory && (
          <Row>
            <Col span={24}>
              <Form.Item
                className="form-item-label--block"
                label={
                  <>
                    <span>Dotted line reporting to</span>
                    <LinkGroup style={linkGroupStyle}>
                      <Link size="small" onClick={toggleShowHistory}>
                        {showHistory ? 'hide history' : 'history'}
                      </Link>
                    </LinkGroup>
                  </>
                }
              >
                <Table rowKey="id" dataSource={histories} pagination={false} columns={columns} />
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
      {canModify && <MutateEmManagerSecondaryDrawer {...drawerState} onClose={handleCloseDrawer} />}
    </div>
  )
}
