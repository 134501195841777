import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { CardSubTitle, Col, EditableCard, EditableCardState, Row } from '~/components'
import { Permission, PermissionAction, YtdSubmissionStatus } from '~/constants'
import { Button, Form, Input } from '~/core-components'
import { usePermissionGate } from '~/features/iam'
import { fetchYtdCompany, restoreYtdCompany, updateYtdCompany } from '~/features/tax/actions'
import { useFocus } from '~/hooks/use-focus'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors, StoreState } from '~/types/store'
import { YtdCompanyState, YtdSubmissionState } from '../../../types'
import './YtdCompany.less'

interface YtdCompanyProps {
  ytdSubmission?: YtdSubmissionState
}

const EMPTY_FORM_DATA: YtdCompanyState = {
  id: '',
  ytdSubmissionId: '',

  taxNo: '',
  name: '',
  incorporationDate: '',

  authName: '',
  authDesignation: '',
  authEmail: '',
  authPhone: ''
}

export const YtdCompany: FC<YtdCompanyProps> = ({ ytdSubmission }) => {
  const ytdSubmissionId = ytdSubmission?.id || ''
  const [cardState, setCardState] = useState<EditableCardState>()
  const [formData, setFormData] = useState<YtdCompanyState>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const canModify = usePermissionGate(Permission.ytd, PermissionAction.Modify)
  const readOnly = cardState !== 'editing' && cardState !== 'saving'
  const ytdCompany = useSelector((state: StoreState) => state.tax.ytdCompanies?.entities[ytdSubmissionId])
  const loading = useSelector((state: StoreState) => state.tax.ytdCompaniesLoading)
  const [focusRef] = useFocus(!readOnly)
  const [restoring, setRestoring] = useState(false)
  const [refetch, setRefetch] = useState(0)

  useEffect(() => {
    if (ytdSubmissionId) dispatch(fetchYtdCompany(ytdSubmissionId))
  }, [ytdSubmissionId, refetch])

  useEffect(() => {
    if (ytdCompany) {
      setFormData(ytdCompany)
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [ytdCompany])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  const handleEdit = useCallback(() => {
    setCardState('editing')
  }, [])

  const handleSave = useCallback(async () => {
    if (ytdCompany) {
      setCardState('saving')
      setErrors(undefined)

      let result: ActionResult | undefined
      try {
        result = await dispatch(updateYtdCompany(ytdCompany.id, ytdCompany, formData))
      } catch {
        setCardState('editing')
      }

      if (result?.errors) {
        setCardState('editing')
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setCardState(undefined)
      }
    }
  }, [ytdCompany, formData])

  const handleCancel = useCallback(() => {
    setCardState(undefined)
    setErrors(undefined)
  }, [])

  const handleRestore = useCallback(async () => {
    if (ytdCompany) {
      setRestoring(true)
      setErrors(undefined)

      let result: ActionResult | undefined
      try {
        result = await dispatch(restoreYtdCompany(ytdCompany.id))
      } finally {
        setRestoring(false)
      }

      if (result?.errors) {
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setRefetch(r => r + 1)
        setCardState(undefined)
      }
    }
  }, [ytdCompany])

  return (
    <EditableCard
      title="Organisation's information"
      className="ytd-company"
      formId="form-ytd-company"
      loading={loading}
      state={canModify && ytdSubmission?.status === YtdSubmissionStatus.draft ? cardState : 'readonly'}
      onEdit={handleEdit}
      onSave={handleSave}
      onCancel={handleCancel}
      extraButtons={[
        <Button onClick={handleRestore} loading={restoring}>
          Restore default
        </Button>
      ]}
    >
      <Row gutter={30}>
        <Col md={12} lg={24}>
          <Form.Item label="Organisation no." validateStatus={errors?.taxNo ? 'error' : ''} help={errors?.taxNo}>
            {readOnly ? (
              formData.taxNo || '-'
            ) : (
              <Input
                ref={focusRef}
                value={formData.taxNo}
                readOnly={readOnly}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ taxNo: event.target.value })}
              />
            )}
          </Form.Item>
        </Col>
        <Col md={12} lg={24}>
          <Form.Item label="Organisation name" validateStatus={errors?.name ? 'error' : ''} help={errors?.name}>
            {readOnly ? (
              formData.name || '-'
            ) : (
              <Input
                value={formData.name}
                readOnly={readOnly}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ name: event.target.value })}
              />
            )}
          </Form.Item>
        </Col>
      </Row>

      <CardSubTitle>Details of authorised person</CardSubTitle>
      <Row gutter={30}>
        <Col md={12} lg={24}>
          <Form.Item label="Name" validateStatus={errors?.authName ? 'error' : ''} help={errors?.authName}>
            {readOnly ? (
              formData.authName || '-'
            ) : (
              <Input
                value={formData.authName}
                readOnly={cardState !== 'editing' && cardState !== 'saving'}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleFormDataChange({ authName: event.target.value })
                }
              />
            )}
          </Form.Item>
        </Col>
        <Col md={12} lg={24}>
          <Form.Item label="Designation">
            {readOnly ? (
              formData.authDesignation || '-'
            ) : (
              <Input
                value={formData.authDesignation}
                readOnly={cardState !== 'editing' && cardState !== 'saving'}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleFormDataChange({ authDesignation: event.target.value })
                }
              />
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Email">
            {readOnly ? (
              formData.authEmail || '-'
            ) : (
              <Input
                value={formData.authEmail}
                readOnly={cardState !== 'editing' && cardState !== 'saving'}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleFormDataChange({ authEmail: event.target.value })
                }
              />
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Phone">
            {readOnly ? (
              formData.authPhone || '-'
            ) : (
              <Input
                value={formData.authPhone}
                readOnly={cardState !== 'editing' && cardState !== 'saving'}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleFormDataChange({ authPhone: event.target.value })
                }
              />
            )}
          </Form.Item>
        </Col>
      </Row>
    </EditableCard>
  )
}
