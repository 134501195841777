import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { LeaveTypeState } from '../types'
import { fetchLeaveTypes } from '../actions'
import { selectLeaveTypes } from '../selectors'

export const useLeaveTypes = (): [LeaveTypeState[], boolean] => {
  const leaveTypes = useSelector(selectLeaveTypes)
  const loading = useSelector((state: StoreState) => state.leave.leaveTypesLoading)

  useEffect(() => {
    dispatch(fetchLeaveTypes({ strategy: 'when-empty' }))
  }, [])

  return [leaveTypes, loading]
}
