import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { BankState, IBank } from '../types'

const baseUrl = getBaseUrl('/employee/bank')

export const apiGetBanks = () => request<BankState[]>('get', baseUrl)

export const apiAddBank = (bank: IBank) => request<{ code: string }>('post', baseUrl, bank)

export const apiUpdateBank = (code: string, bank: Operation[]) => request('patch', `${baseUrl}/${code}`, bank)

export const apiDeleteBank = (code: string) => request('delete', `${baseUrl}/${code}`)
