import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { WfCategory, WorkflowState } from '../types'
import { fetchWorkflows } from '../actions'
import { selectWorkflows } from '../selectors'

export const useWorkflows = (category: WfCategory): [WorkflowState[], boolean] => {
  const workflows = useSelector(selectWorkflows)(category)
  const loading = useSelector((state: StoreState) => state.workflow.workflowsLoading[category] || false)

  useEffect(() => {
    dispatch(fetchWorkflows(category))
  }, [category])

  return [workflows, loading]
}
