import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Card, ColumnsType, PageHeader, SecondaryText, Table } from '~/core-components'
import { DocumentTitle, EmPreview, InactiveTag } from '~/components'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { isInactive } from '~/utils/dateUtil'
import { AddOfficeDrawer } from './components/AddOfficeDrawer'
import { fetchCompanies, fetchOfficeCompanies, fetchOffices, fetchOfficesExtra, fetchEmpKeyvalues } from '../../actions'
import { apiGetOfficeEmployees } from '../../api/office.api'
import { OfficeState } from '../../types'
import { selectOfficesOverview } from '../../selectors'
import { selectOfficeIds } from '../../reducers'
import './Offices.less'

type OfficeTable = OfficeState & {
  companies: { name: string; isMain: boolean }[]
  activeEmployees: number
}

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: '',
    breadcrumbName: 'Overview'
  }
]

interface DrawerState {
  visible: boolean
}
const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

const labelRow1Style: CSSProperties = { lineHeight: 1.1 }
const labelRow2Style: CSSProperties = { fontSize: 12 }

export const Offices: FC = () => {
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const offices = useSelector(selectOfficesOverview)()
  const officeIds = useSelector(selectOfficeIds)
  const loading = useSelector((state: StoreState) => state.master.officesLoading)
  const canModify = usePermissionGate(Permission.master, PermissionAction.Modify)

  useEffect(() => {
    dispatch(fetchCompanies({ strategy: 'when-empty' }))
    dispatch(fetchOffices())
    dispatch(fetchOfficesExtra())
    dispatch(fetchEmpKeyvalues('country', undefined, undefined, { strategy: 'when-empty' }))
  }, [])

  useEffect(() => {
    officeIds.forEach(id => {
      dispatch(fetchOfficeCompanies(id as string, { strategy: 'when-empty' }))
    })
  }, [officeIds])

  const handleAddOffice = useCallback(() => {
    setDrawerState({ visible: true })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const handleFetchEmployees = useCallback(async (officeId: string) => {
    const { status, result } = await apiGetOfficeEmployees(officeId)
    if (status) {
      return result
    }
    return []
  }, [])

  const columns: ColumnsType<OfficeTable> = [
    {
      title: 'Office',
      key: 'name',
      dataIndex: 'name',
      render: (value: string, record) => (
        <>
          <div style={labelRow1Style}>
            <Link to={SETTINGS_ROUTES.office.replace(':id', record.id)}>{value}</Link>{' '}
            {isInactive(record.inactiveDate) && <InactiveTag />}
          </div>
          {record.addressLine1 && record.addressLine2 && (
            <SecondaryText style={labelRow2Style}>
              {record.addressLine1}
              {!!record.addressLine2 ? `, ${record.addressLine2}` : ''}
            </SecondaryText>
          )}
        </>
      )
    },
    {
      title: 'Company',
      key: 'companies',
      dataIndex: 'companies',
      render: (value: string, record) => (
        <>
          {record.companies.map((c, index) => (
            <div key={index}>{c.name}</div>
          ))}
        </>
      )
    },
    {
      title: 'Active employees',
      key: 'activeEmployees',
      dataIndex: 'activeEmployees',
      align: 'center',
      width: '30%',
      render: (value: number, record) => (
        <EmPreview totalEmployees={value} onFetch={() => handleFetchEmployees(record.id)} />
      )
    }
  ]

  return (
    <div id="offices" className="offices">
      <DocumentTitle title="Offices" />
      <PageHeader
        title="Offices"
        containerId="offices"
        breadcrumb={{ routes }}
        extra={
          canModify && (
            <Button key="add" onClick={handleAddOffice}>
              Add office
            </Button>
          )
        }
      />
      <div className="offices__body">
        <Card table>
          <Table rowKey="id" dataSource={offices} pagination={false} columns={columns} loading={loading}></Table>
        </Card>
        {canModify && <AddOfficeDrawer {...drawerState} onClose={handleCloseDrawer} />}
      </div>
    </div>
  )
}
