import React, { FC, useCallback } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { Button, Card, ColumnsType, SecondaryText, Space, Table } from '~/core-components'
import { formatMoney } from '~/utils'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { PaySnapshotTrans } from './PaySnapshotTrans'
import { comparePaySnapshot, createPaySnapshot } from '../../actions'
import { PaySnapshotRecordState } from '../../types'
import './PaySnapshot.less'

export interface PaySnapshotProps {
  payRunId: string
}

const amountWidth: number = 110

export const PaySnapshot: FC<PaySnapshotProps> = ({ payRunId }) => {
  const paySnapshot = useSelector((state: StoreState) => state.payroll.paySnapshot[payRunId] || {})
  const loading = useSelector((state: StoreState) => state.payroll.paySnapshotLoading[payRunId] || false)

  const handleCreateSnapshot = useCallback(() => {
    dispatch(createPaySnapshot(payRunId))
  }, [payRunId])

  const handleCompareSnapshot = useCallback(() => {
    dispatch(comparePaySnapshot(payRunId))
  }, [payRunId])

  const columns: ColumnsType<PaySnapshotRecordState> = [
    {
      title: 'Employee',
      key: 'employeeName',
      dataIndex: 'employeeName',
      fixed: 'left',
      className: 'first-col',
      width: 150,
      render: (value: string, record) => {
        return (
          <div>
            <div>{value}</div>
            <SecondaryText>{record.employeeNo}</SecondaryText>
          </div>
        )
      }
    },
    {
      title: 'Basic pay',
      key: 'basicPayBefore',
      dataIndex: 'basicPayBefore',
      align: 'right',
      width: amountWidth,
      render: (value: number, record) => {
        return (
          <div className={classNames('compare', { 'compare--diff': value !== record.basicPayAfter })}>
            <div>{formatMoney(value)}</div>
            <div>{formatMoney(record.basicPayAfter)}</div>
          </div>
        )
      }
    },
    {
      title: 'Net pay',
      key: 'netPayBefore',
      dataIndex: 'netPayBefore',
      align: 'right',
      width: amountWidth,
      render: (value: number, record) => {
        return (
          <div className={classNames('compare', { 'compare--diff': value !== record.netPayAfter })}>
            <div>{formatMoney(value)}</div>
            <div>{formatMoney(record.netPayAfter)}</div>
          </div>
        )
      }
    },
    {
      title: 'CPF type',
      key: 'cpfTypeBefore',
      dataIndex: 'cpfTypeBefore',
      width: amountWidth,
      render: (value: string, record) => {
        return (
          <div className={classNames('compare', { 'compare--diff': value !== record.cpfTypeAfter })}>
            <div>{value}</div>
            <div>{record.cpfTypeAfter}</div>
          </div>
        )
      }
    },
    {
      title: 'CPF rate',
      key: 'cpfRateCodeBefore',
      dataIndex: 'cpfRateCodeBefore',
      width: amountWidth,
      render: (value: string, record) => {
        return (
          <div className={classNames('compare', { 'compare--diff': value !== record.cpfRateCodeAfter })}>
            <div>{value}</div>
            <div>{record.cpfRateCodeAfter}</div>
          </div>
        )
      }
    },
    {
      title: 'CPFEm',
      key: 'cpfEmBefore',
      dataIndex: 'cpfEmBefore',
      align: 'right',
      width: amountWidth,
      render: (value: number, record) => {
        return (
          <div className={classNames('compare', { 'compare--diff': value !== record.cpfEmAfter })}>
            <div>{formatMoney(value)}</div>
            <div>{formatMoney(record.cpfEmAfter)}</div>
          </div>
        )
      }
    },
    {
      title: 'CPFEm ord',
      key: 'cpfEmOrdinaryBefore',
      dataIndex: 'cpfEmOrdinaryBefore',
      align: 'right',
      width: amountWidth,
      render: (value: number, record) => {
        return (
          <div className={classNames('compare', { 'compare--diff': value !== record.cpfEmOrdinaryAfter })}>
            <div>{formatMoney(value)}</div>
            <div>{formatMoney(record.cpfEmOrdinaryAfter)}</div>
          </div>
        )
      }
    },
    {
      title: 'CPFEm ord adj',
      key: 'cpfEmOrdinaryAdjBefore',
      dataIndex: 'cpfEmOrdinaryAdjBefore',
      align: 'right',
      width: amountWidth,
      render: (value: number, record) => {
        return (
          <div className={classNames('compare', { 'compare--diff': value !== record.cpfEmOrdinaryAdjAfter })}>
            <div>{formatMoney(value)}</div>
            <div>{formatMoney(record.cpfEmOrdinaryAdjAfter)}</div>
          </div>
        )
      }
    },
    {
      title: 'CPFEm add',
      key: 'cpfEmAdditionalBefore',
      dataIndex: 'cpfEmAdditionalBefore',
      align: 'right',
      width: amountWidth,
      render: (value: number, record) => {
        return (
          <div className={classNames('compare', { 'compare--diff': value !== record.cpfEmAdditionalAfter })}>
            <div>{formatMoney(value)}</div>
            <div>{formatMoney(record.cpfEmAdditionalAfter)}</div>
          </div>
        )
      }
    },
    {
      title: 'CPFEm add adj',
      key: 'cpfEmAdditionalAdjBefore',
      dataIndex: 'cpfEmAdditionalAdjBefore',
      align: 'right',
      width: amountWidth,
      render: (value: number, record) => {
        return (
          <div className={classNames('compare', { 'compare--diff': value !== record.cpfEmAdditionalAdjAfter })}>
            <div>{formatMoney(value)}</div>
            <div>{formatMoney(record.cpfEmAdditionalAdjAfter)}</div>
          </div>
        )
      }
    },
    {
      title: 'CPFEm adj',
      key: 'cpfEmAdjBefore',
      dataIndex: 'cpfEmAdjBefore',
      align: 'right',
      width: amountWidth,
      render: (value: number, record) => {
        return (
          <div className={classNames('compare', { 'compare--diff': value !== record.cpfEmAdjAfter })}>
            <div>{formatMoney(value)}</div>
            <div>{formatMoney(record.cpfEmAdjAfter)}</div>
          </div>
        )
      }
    },
    {
      title: 'CPFEr',
      key: 'cpfErBefore',
      dataIndex: 'cpfErBefore',
      align: 'right',
      width: amountWidth,
      render: (value: number, record) => {
        return (
          <div className={classNames('compare', { 'compare--diff': value !== record.cpfErAfter })}>
            <div>{formatMoney(value)}</div>
            <div>{formatMoney(record.cpfErAfter)}</div>
          </div>
        )
      }
    },
    {
      title: 'CPFEr ord',
      key: 'cpfErOrdinaryBefore',
      dataIndex: 'cpfErOrdinaryBefore',
      align: 'right',
      width: amountWidth,
      render: (value: number, record) => {
        return (
          <div className={classNames('compare', { 'compare--diff': value !== record.cpfErOrdinaryAfter })}>
            <div>{formatMoney(value)}</div>
            <div>{formatMoney(record.cpfErOrdinaryAfter)}</div>
          </div>
        )
      }
    },
    {
      title: 'CPFEr ord adj',
      key: 'cpfErOrdinaryAdjBefore',
      dataIndex: 'cpfErOrdinaryAdjBefore',
      align: 'right',
      width: amountWidth,
      render: (value: number, record) => {
        return (
          <div className={classNames('compare', { 'compare--diff': value !== record.cpfErOrdinaryAdjAfter })}>
            <div>{formatMoney(value)}</div>
            <div>{formatMoney(record.cpfErOrdinaryAdjAfter)}</div>
          </div>
        )
      }
    },
    {
      title: 'CPFEr add',
      key: 'cpfErAdditionalBefore',
      dataIndex: 'cpfErAdditionalBefore',
      align: 'right',
      width: amountWidth,
      render: (value: number, record) => {
        return (
          <div className={classNames('compare', { 'compare--diff': value !== record.cpfErAdditionalAfter })}>
            <div>{formatMoney(value)}</div>
            <div>{formatMoney(record.cpfErAdditionalAfter)}</div>
          </div>
        )
      }
    },
    {
      title: 'CPFEr add adj',
      key: 'cpfErAdditionalAdjBefore',
      dataIndex: 'cpfErAdditionalAdjBefore',
      align: 'right',
      width: amountWidth,
      render: (value: number, record) => {
        return (
          <div className={classNames('compare', { 'compare--diff': value !== record.cpfErAdditionalAdjAfter })}>
            <div>{formatMoney(value)}</div>
            <div>{formatMoney(record.cpfErAdditionalAdjAfter)}</div>
          </div>
        )
      }
    },
    {
      title: 'CPFEr adj',
      key: 'cpfErAdjBefore',
      dataIndex: 'cpfErAdjBefore',
      align: 'right',
      width: amountWidth,
      render: (value: number, record) => {
        return (
          <div className={classNames('compare', { 'compare--diff': value !== record.cpfErAdjAfter })}>
            <div>{formatMoney(value)}</div>
            <div>{formatMoney(record.cpfErAdjAfter)}</div>
          </div>
        )
      }
    },
    {
      title: 'OW',
      key: 'owBefore',
      dataIndex: 'owBefore',
      align: 'right',
      width: amountWidth,
      render: (value: number, record) => {
        return (
          <div className={classNames('compare', { 'compare--diff': value !== record.owAfter })}>
            <div>{formatMoney(value)}</div>
            <div>{formatMoney(record.owAfter)}</div>
          </div>
        )
      }
    },
    {
      title: 'OW CPF',
      key: 'owCpfBefore',
      dataIndex: 'owCpfBefore',
      align: 'right',
      width: amountWidth,
      render: (value: number, record) => {
        return (
          <div className={classNames('compare', { 'compare--diff': value !== record.owCpfAfter })}>
            <div>{formatMoney(value)}</div>
            <div>{formatMoney(record.owCpfAfter)}</div>
          </div>
        )
      }
    },
    {
      title: 'OW prorated',
      key: 'owProratedBefore',
      dataIndex: 'owProratedBefore',
      align: 'right',
      width: amountWidth,
      render: (value: number, record) => {
        return (
          <div className={classNames('compare', { 'compare--diff': value !== record.owProratedAfter })}>
            <div>{formatMoney(value)}</div>
            <div>{formatMoney(record.owProratedAfter)}</div>
          </div>
        )
      }
    },
    {
      title: 'OW projected',
      key: 'owProjectedBefore',
      dataIndex: 'owProjectedBefore',
      align: 'right',
      width: amountWidth,
      render: (value: number, record) => {
        return (
          <div className={classNames('compare', { 'compare--diff': value !== record.owProjectedAfter })}>
            <div>{formatMoney(value)}</div>
            <div>{formatMoney(record.owProjectedAfter)}</div>
          </div>
        )
      }
    },
    {
      title: 'AW',
      key: 'awBefore',
      dataIndex: 'awBefore',
      align: 'right',
      width: amountWidth,
      render: (value: number, record) => {
        return (
          <div className={classNames('compare', { 'compare--diff': value !== record.awAfter })}>
            <div>{formatMoney(value)}</div>
            <div>{formatMoney(record.awAfter)}</div>
          </div>
        )
      }
    },
    {
      title: 'AW CPF',
      key: 'awCpfBefore',
      dataIndex: 'awCpfBefore',
      align: 'right',
      width: amountWidth,
      render: (value: number, record) => {
        return (
          <div className={classNames('compare', { 'compare--diff': value !== record.awCpfAfter })}>
            <div>{formatMoney(value)}</div>
            <div>{formatMoney(record.awCpfAfter)}</div>
          </div>
        )
      }
    },
    {
      title: 'AW prorated',
      key: 'awProratedBefore',
      dataIndex: 'awProratedBefore',
      align: 'right',
      width: amountWidth,
      render: (value: number, record) => {
        return (
          <div className={classNames('compare', { 'compare--diff': value !== record.awProratedAfter })}>
            <div>{formatMoney(value)}</div>
            <div>{formatMoney(record.awProratedAfter)}</div>
          </div>
        )
      }
    },
    {
      title: 'AW ceiling',
      key: 'awCeilingBefore',
      dataIndex: 'awCeilingBefore',
      align: 'right',
      width: amountWidth,
      render: (value: number, record) => {
        return (
          <div className={classNames('compare', { 'compare--diff': value !== record.awCeilingAfter })}>
            <div>{formatMoney(value)}</div>
            <div>{formatMoney(record.awCeilingAfter)}</div>
          </div>
        )
      }
    },
    {
      title: 'FWL',
      key: 'fwlBefore',
      dataIndex: 'fwlBefore',
      align: 'right',
      width: amountWidth,
      render: (value: number, record) => {
        return (
          <div className={classNames('compare', { 'compare--diff': value !== record.fwlAfter })}>
            <div>{formatMoney(value)}</div>
            <div>{formatMoney(record.fwlAfter)}</div>
          </div>
        )
      }
    },
    {
      title: 'SDL',
      key: 'sdlBefore',
      dataIndex: 'sdlBefore',
      align: 'right',
      width: amountWidth,
      render: (value: number, record) => {
        return (
          <div className={classNames('compare', { 'compare--diff': value !== record.sdlAfter })}>
            <div>{formatMoney(value)}</div>
            <div>{formatMoney(record.sdlAfter)}</div>
          </div>
        )
      }
    },
    {
      title: 'Cal days curr',
      key: 'calendarDaysCurrBefore',
      dataIndex: 'calendarDaysCurrBefore',
      align: 'right',
      width: amountWidth,
      render: (value: number, record) => {
        return (
          <div className={classNames('compare', { 'compare--diff': value !== record.calendarDaysCurrAfter })}>
            <div>{value}</div>
            <div>{record.calendarDaysCurrAfter}</div>
          </div>
        )
      }
    },
    {
      title: 'Cal days prev',
      key: 'calendarDaysPrevBefore',
      dataIndex: 'calendarDaysPrevBefore',
      align: 'right',
      width: amountWidth,
      render: (value: number, record) => {
        return (
          <div className={classNames('compare', { 'compare--diff': value !== record.calendarDaysPrevAfter })}>
            <div>{value}</div>
            <div>{record.calendarDaysPrevAfter}</div>
          </div>
        )
      }
    },
    {
      title: 'WD curr',
      key: 'workDaysCurrBefore',
      dataIndex: 'workDaysCurrBefore',
      align: 'right',
      width: amountWidth,
      render: (value: number, record) => {
        return (
          <div className={classNames('compare', { 'compare--diff': value !== record.workDaysCurrAfter })}>
            <div>{value}</div>
            <div>{record.workDaysCurrAfter}</div>
          </div>
        )
      }
    },
    {
      title: 'WD prev',
      key: 'workDaysPrevBefore',
      dataIndex: 'workDaysPrevBefore',
      align: 'right',
      width: amountWidth,
      render: (value: number, record) => {
        return (
          <div className={classNames('compare', { 'compare--diff': value !== record.workDaysPrevAfter })}>
            <div>{value}</div>
            <div>{record.workDaysPrevAfter}</div>
          </div>
        )
      }
    }
  ]

  return (
    <div className="paysnapshot">
      <Space className="button-groups">
        <Button onClick={handleCreateSnapshot}>Create snapshot</Button>
        <Button onClick={handleCompareSnapshot}>Compare snapshot</Button>
      </Space>
      <Card fitParent table>
        <Table
          rowKey="id"
          dataSource={paySnapshot.payRecords}
          pagination={false}
          indentSize={1}
          columns={columns}
          loading={loading}
          fitParent
          scroll={{ x: amountWidth * 30 + 150, y: 1000 }}
          className="paysnapshot__table"
          expandable={{
            expandedRowRender: (record: PaySnapshotRecordState) => (
              <PaySnapshotTrans payRunId={record.payRunId} employeeId={record.employeeId} />
            )
          }}
        />
      </Card>
    </div>
  )
}
