import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { apiUpdateCompany } from '../api/company.api'
import {
  CompanyState,
  ICompanyInfo,
  ICompanyBank,
  ICompanyAuthPerson,
  ICompanyStatutory,
  ICompanyOrgChart
} from '../types'
import { setCompany, setKeyvalue } from '../reducers'

export const updateCompany = <
  T extends ICompanyInfo | ICompanyBank | ICompanyAuthPerson | ICompanyStatutory | ICompanyOrgChart
>(
  id: string,
  before: T,
  request: T
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const after = { ...before, ...request } as T
    const patch = generateJsonPatch<T>(before, after)
    const { status, errors, message, errorData } = await apiUpdateCompany(id, patch)

    if (status) {
      const company = getState().master.companies?.entities[id]
      const updated = { ...(company as CompanyState), ...after }
      dispatch(setCompany(updated))
      dispatch(setKeyvalue({ kv: 'company', data: { key: id, value: updated.name } }))

      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
