import React, { forwardRef } from 'react'
import classNames from 'classnames'
import { Select, SelectProps } from '~/core-components'
import { useSysDailyPolicyFieldsDict } from '../../../hooks'
import { SysDailyPolicyFieldState } from '../../../types'
import './SelectDailyPolicyField.less'

export interface SelectDailyPolicyFieldProps extends SelectProps {
  value: string
  onChange: (value: string) => void
}

const SelectDailyPolicyFieldInternal = ({ className, ...props }: SelectDailyPolicyFieldProps, ref: React.Ref<any>) => {
  const classes = classNames('select-daily-policy-field', { [`${className}`]: className })
  const [fields] = useSysDailyPolicyFieldsDict()

  return (
    <Select ref={ref} className={classes} showSearch optionFilterProp="title" {...props}>
      {(Object.values(fields) as SysDailyPolicyFieldState[]).map(e => (
        <Select.Option key={e.fieldName} value={e.fieldName} title={e.description}>
          {e.description}
        </Select.Option>
      ))}
    </Select>
  )
}

export const SelectDailyPolicyField = forwardRef(SelectDailyPolicyFieldInternal)
