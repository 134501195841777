import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchAttendanceGroups } from '../actions'
import { AttendanceGroupState } from '../types'
import { selectAttendanceGroups } from '../selectors'

export const useAttendanceGroups = (): [AttendanceGroupState[], boolean] => {
  const data = useSelector(selectAttendanceGroups)
  const loading = useSelector((state: StoreState) => state.attendance.attendanceGroupsLoading)

  useEffect(() => {
    dispatch(fetchAttendanceGroups({ strategy: 'when-empty' }))
  }, [])

  return [data, loading]
}
