import { setClaimPendingPaymentsView, setClaimPendingPaymentsViewLoading } from '../reducers'
import { apiGetClaimPendingPaymentsView } from '../api/claim-pending-payment.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions, Pagination } from '~/types/common'

export const fetchClaimPendingPaymentsView = (
  viewId: string,
  periodYear: number | undefined,
  pagination: Pagination,
  search: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!viewId || !periodYear) return

    const loading = getState().claim.claimPendingPaymentsViewLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().claim.claimPendingPaymentsView?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setClaimPendingPaymentsViewLoading(true))
      const { result, status } = await apiGetClaimPendingPaymentsView(viewId, periodYear, { ...pagination, search })
      if (status) {
        dispatch(setClaimPendingPaymentsView(result))
      }
    } finally {
      dispatch(setClaimPendingPaymentsViewLoading(false))
    }
  }
}
