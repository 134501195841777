import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { SuggestionType } from '~/components'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchSysDailyPolicyExpressions } from '../actions'
import { selectSysDailyPolicyExpressionsSuggestions } from '../selectors'

export const useSysDailyPolicyExpressionsSuggestions = (
  fetchStrategy: FetchStrategy = 'when-empty'
): [SuggestionType[], boolean] => {
  const data = useSelector(selectSysDailyPolicyExpressionsSuggestions)
  const loading = useSelector((state: StoreState) => state.attendance.sysDailyPolicyExpressionsLoading)

  useEffect(() => {
    dispatch(fetchSysDailyPolicyExpressions({ strategy: fetchStrategy }))
  }, [fetchStrategy])

  return [data, loading]
}
