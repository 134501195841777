import { IPayRecurringInfo } from './payrecurring.dto'
import { PayRecurringState } from './payrecurring.state'

export const mapPayRecurringStateToPayRecurringInfo = (payRecurring: PayRecurringState): IPayRecurringInfo => ({
  payItemId: payRecurring.payItemId,
  quantity: payRecurring.quantity,
  rate: payRecurring.rate,
  startDate: payRecurring.startDate,
  endDate: payRecurring.endDate,
  notes: payRecurring.notes
})
