import { ActionResult, ThunkResult } from '~/types/store'
import { showSuccess, showError } from '~/utils'
import { apiAddEmployee } from '../api/employee.api'
import { setEmKeyValue } from '../reducers'
import { INewEmployee } from '../types'
import { fetchEmployee } from './fetch-employee'
import { fetchEmCompanies } from './fetch-em-companies'

export const addEmployee = (employee: INewEmployee): ThunkResult<void> => {
  return async (dispatch, getState): Promise<ActionResult> => {
    const { status, result, errors, message, errorData } = await apiAddEmployee(employee)
    if (status) {
      dispatch(fetchEmployee(result.id))
      dispatch(fetchEmCompanies(result.id))

      const kv = getState().employee.emKeyValues
      if (kv?.ids?.length > 0) {
        dispatch(setEmKeyValue({ key: result.id, value: employee.fullName }))
      }

      showSuccess('Added')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
