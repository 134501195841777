import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Dictionary } from '@reduxjs/toolkit'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchSysDailyFields } from '../actions'
import { SysDailyFieldState } from '../types'

export const useSysDailyFieldsDict = (
  fetchStrategy: FetchStrategy = 'when-empty'
): [Dictionary<SysDailyFieldState>, boolean] => {
  const sysDailyFields = useSelector((state: StoreState) => state.attendance.sysDailyFields.entities)
  const loading = useSelector((state: StoreState) => state.attendance.sysDailyFieldsLoading)

  useEffect(() => {
    dispatch(fetchSysDailyFields({ strategy: fetchStrategy }))
  }, [fetchStrategy])

  return [sysDailyFields, loading]
}
