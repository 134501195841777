import React, { FC, useCallback, useEffect, useState } from 'react'
import { Dropdown, Space } from '~/core-components'
import { useIsMountedRef } from '~/hooks/use-is-mounted-ref'
import { RptPayMonthlyExcelModal } from './RptPayMonthlyExcelModal'
import { RptDownloadBtn } from '../../components/RptDownloadBtn'

interface RptPayMonthlyDownloadBtnProps {
  excelFormat: string
  onDownloadExcel: (format: string) => Promise<void>
  onDownloadPdf: () => Promise<void>
  onDownloadSecurePdf: () => Promise<void>
}

interface ActionModalState {
  visible: boolean
}

type DownloadType = 'pdf' | 'excel'
const DEFAULT_ACTION_MODAL_STATE: ActionModalState = { visible: false }

export const RptPayMonthlyDownloadBtn: FC<RptPayMonthlyDownloadBtnProps> = ({
  excelFormat,
  onDownloadExcel,
  onDownloadPdf,
  onDownloadSecurePdf
}) => {
  const [downloading, setDownloading] = useState<DownloadType>()
  const isMountedRef = useIsMountedRef()
  const [actionModalState, setActionModalState] = useState<ActionModalState>(DEFAULT_ACTION_MODAL_STATE)
  const [formatType, setFormatType] = useState<string>(excelFormat)

  useEffect(() => {
    if (excelFormat) {
      setFormatType(excelFormat)
    }
  }, [excelFormat])

  const handleDownloadPdf = useCallback(async () => {
    try {
      setDownloading('pdf')
      await onDownloadPdf()
    } finally {
      if (isMountedRef.current) setDownloading(undefined)
    }
  }, [onDownloadPdf, isMountedRef])

  const handleDownloadSecurePdf = useCallback(async () => {
    try {
      setDownloading('pdf')
      await onDownloadSecurePdf()
    } finally {
      if (isMountedRef.current) setDownloading(undefined)
    }
  }, [onDownloadSecurePdf, isMountedRef])

  const handleDownloadExcel = useCallback(
    async (format: string) => {
      try {
        setDownloading('excel')
        await onDownloadExcel(format)
      } finally {
        if (isMountedRef.current) setDownloading(undefined)
      }
    },
    [onDownloadExcel, isMountedRef]
  )

  const handleChangeFormatType = useCallback(async (format: string) => {
    setFormatType(format)
  }, [])

  const handleDownload = useCallback(() => {
    setActionModalState({ visible: true })
  }, [])

  const handleCloseAction = useCallback(() => {
    setActionModalState(DEFAULT_ACTION_MODAL_STATE)
  }, [])

  return (
    <>
      <Space>
        <RptDownloadBtn onDownloadPdf={handleDownloadPdf} onDownloadSecurePdf={handleDownloadSecurePdf}>
          PDF
        </RptDownloadBtn>
        <Dropdown.Button
          overlay={<></>}
          icon={<i className="fa-light fa-ellipsis-vertical" />}
          onClick={() => handleDownloadExcel(formatType)}
          buttonsRender={([leftButton, rightButton]) => [
            React.cloneElement(leftButton as React.ReactElement, { loading: downloading === 'excel' }),
            React.cloneElement(rightButton as React.ReactElement, { onClick: handleDownload })
          ]}
        >
          Excel format {formatType === 'column' ? '1' : '2'}
        </Dropdown.Button>
      </Space>
      <RptPayMonthlyExcelModal {...actionModalState} onChange={handleChangeFormatType} onClose={handleCloseAction} />
    </>
  )
}
