import React, { CSSProperties, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Skeleton } from '~/core-components'
import { EditableCard, EditableCardState } from '~/components'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { dispatch } from '~/stores/store'
import { Errors, ActionResult } from '~/types/store'
import { EmployeeBasicForm, EMPTY_EMPLOYEE_INFO_FORM_DATA } from './EmployeeBasicForm'
import { IEmployeeBasic } from '../../../types'
import { mapEmployeeStateToEmployeeInfo } from '../../../types/employee.mapper'
import { updateEmployeeBasic } from '../../../actions'
import { useEmployee } from '~/features/employee/hooks'

interface EmployeeInfoProps {
  employeeId?: string
  onEdit?: () => void
  onSave?: () => void
  onCancel?: () => void
}

const cardStyle: CSSProperties = { margin: 24 }

export const EmployeeBasic: FC<EmployeeInfoProps> = ({ employeeId, onEdit, onSave, onCancel }: EmployeeInfoProps) => {
  const [employee, loading] = useEmployee(employeeId)
  const [cardState, setCardState] = useState<EditableCardState>()
  const [formData, setFormData] = useState<IEmployeeBasic>(EMPTY_EMPLOYEE_INFO_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const canModify = usePermissionGate(Permission.employeeBasic, PermissionAction.Modify)

  useEffect(() => {
    if (employee) {
      const { employeeNo, title, firstName, lastName, fullName, preferredName, email, workPhone } = employee
      setFormData({ employeeNo, title, firstName, lastName, fullName, preferredName, email, workPhone })
    } else {
      setFormData(EMPTY_EMPLOYEE_INFO_FORM_DATA)
    }
  }, [employee])

  const handleEdit = useCallback(() => {
    setCardState('editing')
    typeof onEdit === 'function' && onEdit()
  }, [onEdit])

  const handleSave = useCallback(async () => {
    if (employee) {
      setCardState('saving')
      setErrors(undefined)

      typeof onSave === 'function' && onSave()

      let result: ActionResult | undefined
      try {
        result = await dispatch(updateEmployeeBasic(employee.id, mapEmployeeStateToEmployeeInfo(employee), formData))
      } catch {
        setCardState('editing')
      }

      if (result?.errors) {
        setCardState('editing')
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setCardState(undefined)
      }
    }
  }, [employee, formData, onSave])

  const handleCancel = useCallback(() => {
    typeof onCancel === 'function' && onCancel()
    setCardState(undefined)
    setErrors(undefined)

    if (employee) {
      const { employeeNo, title, firstName, lastName, fullName, preferredName, email, workPhone } = employee
      setFormData({ employeeNo, title, firstName, lastName, fullName, preferredName, email, workPhone })
    }
  }, [employee, onCancel])

  const cardBodyStyle = useMemo(
    () => ({ paddingBottom: employee || loading ? 6 : 24, paddingTop: canModify ? 6 : 24 }),
    [employee, canModify, loading]
  )

  return (
    <EditableCard
      style={cardStyle}
      bodyStyle={cardBodyStyle}
      state={canModify && !loading ? cardState : 'readonly'}
      formId="form-em-info"
      onEdit={handleEdit}
      onSave={handleSave}
      onCancel={handleCancel}
    >
      {loading ? (
        <Skeleton active />
      ) : (
        <EmployeeBasicForm
          data={formData}
          savedEmail={employee?.email || ''}
          errors={errors}
          readOnly={cardState !== 'editing' && cardState !== 'saving'}
          onChange={data => setFormData({ ...formData, ...data })}
        />
      )}
    </EditableCard>
  )
}
