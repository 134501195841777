import { setA8bForm, setA8bFormLoading } from '../reducers'
import { apiGetA8bForm } from '../api/ytd.api'
import { ThunkResult } from '~/types/store'

export const fetchA8bForm = (
  ytdYear: string,
  employerTaxNo: string,
  employeeTaxNo: string,
  ytdSubmissionId: string
): ThunkResult<void> => {
  return async dispatch => {
    try {
      dispatch(setA8bFormLoading(true))
      const { result, status } = await apiGetA8bForm(ytdYear, employerTaxNo, employeeTaxNo, ytdSubmissionId)
      if (status) {
        dispatch(setA8bForm(result))
      }
    } finally {
      dispatch(setA8bFormLoading(false))
    }
  }
}
