import { request, getBaseUrl, timeout } from '~/utils'
import { DateRange, Pagination, Search } from '~/types/common'
import { ILeaveRecord, LeaveRecordAttachmentState, LeaveRecordDtlState, LeaveRecordViewState } from '../types'

const baseUrl = getBaseUrl('/leave/leaverecord')

export const apiGetLeaveRecordsView = (
  viewId: string,
  params: Pagination & Search & DateRange & { employeeId: string | null }
) => request<LeaveRecordViewState>('get', `${baseUrl}/view/${viewId}`, params)

export const apiGetLeaveRecord = (id: string) => request<ILeaveRecord>('get', `${baseUrl}/${id}`)

export const apiGetLeaveRecordDtls = (id: string) => request<LeaveRecordDtlState[]>('get', `${baseUrl}/${id}/dtl`)

export const apiGetLeaveRecordAttachments = (id: string) =>
  request<LeaveRecordAttachmentState[]>('get', `${baseUrl}/${id}/attachment`)

export const apiDeleteLeaveRecord = (id: string) => request<void>('delete', `${baseUrl}/${id}`)

export const apiGetLeaveRecordExcel = (viewId: string, params: Search & DateRange & { employeeId: string | null }) =>
  request('get', `${baseUrl}/view/${viewId}/excel`, params, { responseType: 'blob', timeout })
