import React from 'react'
import { ZealysLoading } from '~/components'
import { AuthConsumer } from './AuthProvider'

export const Callback = () => (
  <AuthConsumer>
    {({ signinRedirectCallback }) => {
      signinRedirectCallback()
      return <ZealysLoading />
    }}
  </AuthConsumer>
)
