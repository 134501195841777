import React, { FC } from 'react'
import FullCalendar, { CalendarOptions } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import './Calendar.less'

export interface CalendarProps extends CalendarOptions {}

export const Calendar: FC<CalendarProps> = (props: CalendarProps) => {
  return <FullCalendar plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]} dayMaxEvents={true} {...props} />
}
