import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { EmploymentConfigState } from '../types'
import { selectHiddenEmploymentConfigs } from '../selectors'
import { fetchEmploymentConfigs } from '../actions'

export const useHiddenEmploymentConfigs = (): [EmploymentConfigState[], boolean] => {
  const data = useSelector(selectHiddenEmploymentConfigs)
  const loading = useSelector((state: StoreState) => state.master.employmentConfigsLoading)

  useEffect(() => {
    dispatch(fetchEmploymentConfigs({ strategy: 'when-empty' }))
  }, [])

  return [data, loading]
}
