import {
  ICalendarAndPattern,
  ICalendarPattern,
  IHolidayInfo,
  IHolidayDateInfo,
  IReasonInfo,
  IMasterInfo,
  IDepartmentInfo,
  ITeamInfo,
  ICostCentreInfo,
  IQualificationInfo,
  IDivisionInfo,
  ISectionInfo,
  ISalaryGradeInfo
} from './master.dto'
import {
  HolidayState,
  HolidayDateState,
  MasterState,
  ReasonState,
  DepartmentState,
  TeamState,
  CostCentreState,
  QualificationState,
  DivisionState,
  SectionState,
  SalaryGradeState
} from './master.state'
import { CalendarOverviewState } from '../selectors/select-calendars-overview'
import { WorkStatus, emptyGuid } from '~/constants'

export const mapMasterStateToMasterInfo = (master: MasterState): IMasterInfo => ({
  name: master.name,
  inactiveDate: master.inactiveDate
})

export const mapHolidayStateToHolidayInfo = (holiday: HolidayState): IHolidayInfo => ({
  name: holiday.name,
  inactiveDate: holiday.inactiveDate
})

export const mapHolidayDateStateToHolidayDateInfo = (holidayDate: HolidayDateState): IHolidayDateInfo => ({
  name: holidayDate.name,
  holidayId: holidayDate.id,
  date: holidayDate.date
})

export const mapReasonStateToReasonInfo = (reason: ReasonState): IReasonInfo => ({
  type: reason.type,
  name: reason.name,
  inactiveDate: reason.inactiveDate
})

export const mapDepartmentStateToDepartmentInfo = (department: DepartmentState): IDepartmentInfo => ({
  name: department.name,
  headId: department.headId,
  inactiveDate: department.inactiveDate
})

export const mapDivisionStateToDivisionInfo = (division: DivisionState): IDivisionInfo => ({
  name: division.name,
  headId: division.headId,
  inactiveDate: division.inactiveDate
})

export const mapSectionStateToSectionInfo = (section: SectionState): ISectionInfo => ({
  name: section.name,
  headId: section.headId,
  inactiveDate: section.inactiveDate
})

export const mapTeamStateToTeamInfo = (team: TeamState): ITeamInfo => ({
  name: team.name,
  headId: team.headId,
  inactiveDate: team.inactiveDate
})

export const mapCostCentreStateToCostCentreInfo = (costCentre: CostCentreState): ICostCentreInfo => ({
  name: costCentre.name,
  code: costCentre.code,
  inactiveDate: costCentre.inactiveDate
})

export const mapQualificationStateToQualificationInfo = (qualification: QualificationState): IQualificationInfo => ({
  name: qualification.name,
  qualificationOed: qualification.qualificationOed,
  inactiveDate: qualification.inactiveDate
})

export const mapSalaryGradeStateToSalaryGradeInfo = (salaryGrade: SalaryGradeState): ISalaryGradeInfo => ({
  name: salaryGrade.name,
  minSalary: salaryGrade.minSalary,
  maxSalary: salaryGrade.maxSalary,
  inactiveDate: salaryGrade.inactiveDate
})

export const mapCalendarOverviewStateToCalendarAndPattern = (
  calendarInfo: CalendarOverviewState
): ICalendarAndPattern => {
  const patterns: ICalendarPattern[] = []
  calendarInfo.weeks.forEach((w, index) => {
    let weekSeq = index * 7
    patterns.push({
      id: w.mon?.id || emptyGuid,
      calendarId: calendarInfo.id || emptyGuid,
      sequence: w.mon?.sequence || weekSeq + 1,
      workStatus: w.mon?.workStatus || WorkStatus.off
    })
    patterns.push({
      id: w.tue?.id || emptyGuid,
      calendarId: calendarInfo.id || emptyGuid,
      sequence: w.tue?.sequence || weekSeq + 2,
      workStatus: w.tue?.workStatus || WorkStatus.off
    })
    patterns.push({
      id: w.wed?.id || emptyGuid,
      calendarId: calendarInfo.id || emptyGuid,
      sequence: w.wed?.sequence || weekSeq + 3,
      workStatus: w.wed?.workStatus || WorkStatus.off
    })
    patterns.push({
      id: w.thu?.id || emptyGuid,
      calendarId: calendarInfo.id || emptyGuid,
      sequence: w.thu?.sequence || weekSeq + 4,
      workStatus: w.thu?.workStatus || WorkStatus.off
    })
    patterns.push({
      id: w.fri?.id || emptyGuid,
      calendarId: calendarInfo.id || emptyGuid,
      sequence: w.fri?.sequence || weekSeq + 5,
      workStatus: w.fri?.workStatus || WorkStatus.off
    })
    patterns.push({
      id: w.sat?.id || emptyGuid,
      calendarId: calendarInfo.id || emptyGuid,
      sequence: w.sat?.sequence || weekSeq + 6,
      workStatus: w.sat?.workStatus || WorkStatus.off
    })
    patterns.push({
      id: w.sun?.id || emptyGuid,
      calendarId: calendarInfo.id || emptyGuid,
      sequence: w.sun?.sequence || weekSeq + 7,
      workStatus: w.sun?.workStatus || WorkStatus.off
    })
  })

  return {
    id: calendarInfo.id || emptyGuid,
    name: calendarInfo.name,
    inactiveDate: calendarInfo.inactiveDate,
    patternStartDate: calendarInfo.patternStartDate,
    patterns
  }
}
