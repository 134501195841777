import { replaceLeavePeriods, setLeavePeriodsLoading } from '../reducers'
import { apiGetLeavePeriods } from '../api/leave-closing.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchLeavePeriods = (options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().leave.leavePeriodsLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().leave.leavePeriods?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setLeavePeriodsLoading(true))
      const { result, status } = await apiGetLeavePeriods()
      if (status) {
        dispatch(replaceLeavePeriods(result))
      }
    } finally {
      dispatch(setLeavePeriodsLoading(false))
    }
  }
}
