import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { ISaveWfEvent, ISortWfEvent, IWorkflow, WfCategory, WorkflowState } from '../types'

const baseUrl = getBaseUrl('/employee/workflow')

export const apiGetWorkflows = (category: WfCategory) => request<WorkflowState[]>('get', `${baseUrl}/${category}`)

export const apiGetWorkflow = (category: WfCategory, id: string) =>
  request<WorkflowState>('get', `${baseUrl}/${category}/${id}`)

export const apiAddWorkflow = (category: WfCategory, workflow: IWorkflow) =>
  request<{ id: string }>('post', `${baseUrl}/${category}`, workflow)

export const apiDeleteWorkflow = (category: WfCategory, id: string) =>
  request<void>('delete', `${baseUrl}/${category}/${id}`)

export const apiUpdateWorkflow = (category: WfCategory, id: string, workflow: Operation[]) =>
  request<void>('patch', `${baseUrl}/${category}/${id}`, workflow)

export const apiSaveWfScenario = (category: WfCategory, workflowId: string, scenario: ISaveWfEvent) =>
  request<{ id: string } | undefined>('post', `${baseUrl}/${category}/${workflowId}/scenario`, scenario)

export const apiDeleteWfScenario = (category: WfCategory, workflowId: string, wfEventId: string) =>
  request<void>('delete', `${baseUrl}/${category}/${workflowId}/scenario/${wfEventId}`)

export const apiSortWfScenario = (category: WfCategory, workflowId: string, sort: ISortWfEvent[]) =>
  request<void>('post', `${baseUrl}/${category}/${workflowId}/scenario/sort`, { sort })
