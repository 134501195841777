import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'
import { setProject, setProjectsLoading } from '../reducers'
import { apiGetProject } from '../api/project.api'

export const fetchProject = (id: string, options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const loading = getState().attendance.projectsLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().attendance.projects.ids.includes(id)
      if (hasData) return
    }

    try {
      dispatch(setProjectsLoading(true))
      const { result, status } = await apiGetProject(id)
      if (status) {
        dispatch(setProject(result))
      }
    } finally {
      dispatch(setProjectsLoading(false))
    }
  }
}
