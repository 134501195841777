import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'

export const selectHasIrasError = createSelector(
  (state: StoreState) => state.tax.ir8aErrors,
  (state: StoreState) => state.tax.ir8aWarnings,
  (state: StoreState) => state.tax.ir8sErrors,
  (state: StoreState) => state.tax.ir8sWarnings,
  (state: StoreState) => state.tax.a8aErrors,
  (state: StoreState) => state.tax.a8aWarnings,
  (state: StoreState) => state.tax.a8bErrors,
  (state: StoreState) => state.tax.a8bWarnings,
  (
    ir8aErrorsState,
    ir8aWarningsState,
    ir8sErrorsState,
    ir8sWarningsState,
    a8aErrorsState,
    a8aWarningsState,
    a8bErrorsState,
    a8bWarningsState
  ) => {
    const hasIr8aErrors = ir8aErrorsState.ids.length > 0
    const hasIr8aWarnings = ir8aWarningsState.ids.length > 0
    const hasIr8sErrors = ir8sErrorsState.ids.length > 0
    const hasIr8sWarnings = ir8sWarningsState.ids.length > 0
    const hasA8aErrors = a8aErrorsState.ids.length > 0
    const hasA8aWarnings = a8aWarningsState.ids.length > 0
    const hasA8bErrors = a8bErrorsState.ids.length > 0
    const hasA8bWarnings = a8bWarningsState.ids.length > 0

    return (
      hasIr8aErrors ||
      hasIr8aWarnings ||
      hasIr8sErrors ||
      hasIr8sWarnings ||
      hasA8aErrors ||
      hasA8aWarnings ||
      hasA8bErrors ||
      hasA8bWarnings
    )
  }
)
