import { showSuccess, showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { ICurrencyExchange } from '../types'
import { apiAddCurrencyExchange } from '../api/currency-exchange.api'

export const addCurrencyExchange = (currencyExchange: ICurrencyExchange): ThunkResult<void> => {
  return async (): Promise<ActionResult> => {
    const { status, errors, message, errorData } = await apiAddCurrencyExchange(currencyExchange)
    if (status) {
      showSuccess('Added')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
