import { useMemo } from 'react'
import { useMyTenant } from './use-my-tenant'
import { FeatureFlag } from '~/constants'

export const useHasFeatureFlag = (featureFlag: FeatureFlag | FeatureFlag[]): boolean => {
  const featureFlags = useMemo(() => (Array.isArray(featureFlag) ? featureFlag : [featureFlag]), [featureFlag])
  const [myTenant] = useMyTenant()

  let hasFeatureFlag = false
  for (const feat of featureFlags) {
    if (myTenant?.featureFlags?.includes(feat)) hasFeatureFlag = true
  }

  if (!hasFeatureFlag) return false

  return true
}
