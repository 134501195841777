import { showSuccess, showError } from '~/utils'
import { emptyGuid } from '~/constants'
import { ActionResult, ThunkResult } from '~/types/store'
import { apiUpdatePayTranEntryBatch } from '../api/paytran-entry.api'
import { IPayTranEntryInfo } from '../types'
import { removePayTranEntryByBatch } from '../reducers'

export const updatePayTranEntryBatch = (
  payRunId: string,
  employeeId: string,
  batchId: string,
  entries: IPayTranEntryInfo[]
): ThunkResult<void> => {
  return async (dispatch): Promise<ActionResult | undefined> => {
    if (!payRunId || !employeeId || !batchId) return

    const { status, result, errors, message, errorData } = await apiUpdatePayTranEntryBatch(
      payRunId,
      employeeId,
      batchId,
      entries.map(entry => ({
        id: emptyGuid,
        payRunId,
        employeeId,
        ...entry
      }))
    )

    if (status) {
      if (result?.batchId) {
        dispatch(removePayTranEntryByBatch(emptyGuid))
      }
      showSuccess('Updated')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
