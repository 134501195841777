import { showSuccess, showError } from '~/utils'
import { refetchMyTimeLogs } from '../reducers'
import { ThunkResult } from '~/types/store'
import { ISSTimeLogEdit } from '../types'
import { apiUpdateMyTimeLog } from '../api/ss-time-log.api'

export const updateMyTimeLog = (data: ISSTimeLogEdit): ThunkResult<void> => {
  return async dispatch => {
    const { status, result, errors, message, errorData } = await apiUpdateMyTimeLog(data)

    if (status) {
      dispatch(refetchMyTimeLogs())
      showSuccess('Submitted')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
