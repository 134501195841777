import { replaceLeaveRecordApprovalHistories, setLeaveRecordApprovalHistoriesLoading } from '../reducers'
import { apiGetLeaveRecordApprovalHistories } from '../api/leave-record-approval-history.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchLeaveRecordApprovalHistories = (
  id: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const loading = getState().leave.leaveRecordApprovalHistoriesLoading[id]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().leave.leaveRecordApprovalHistories[id]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setLeaveRecordApprovalHistoriesLoading({ id, loading: true }))
      const { result, status } = await apiGetLeaveRecordApprovalHistories(id)
      if (status) {
        dispatch(replaceLeaveRecordApprovalHistories({ id, data: result }))
      }
    } finally {
      dispatch(setLeaveRecordApprovalHistoriesLoading({ id, loading: false }))
    }
  }
}
