import React, { FC, useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import './HelpPage.less'

interface HelpPageProps {}

export const HelpPage: FC<HelpPageProps> = () => {
  const match = useRouteMatch<{ page: string }>()
  const page = match.params.page
  const [content, setContent] = useState('')

  useEffect(() => {
    if (page) {
      fetch(`/help/${page}/index.md`)
        .then(res => res.text())
        .then(text => setContent(text))
    }
  }, [page])

  return (
    <div className="help-page markdown-body">
      <ReactMarkdown remarkPlugins={[remarkGfm]}>{content}</ReactMarkdown>
    </div>
  )
}
