import { EntityState } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { ScheduleRecordViewState } from './schedule-record.state'

export const schedulePersistConfig = {
  key: 'schedule',
  whitelist: ['scheduleRecordsView', 'scheduleRecordsViewData'],
  storage
}

export interface ScheduleRootState {
  refetchScheduleRecordsView: number
  refetchScheduleRecordsViewRow: string | undefined
  refetchScheduleRecordsViewCell: string | undefined
  scheduleRecordsView: EntityState<ScheduleRecordViewState>
  scheduleRecordsViewLoading: boolean
}
