import { replaceProjectEm, setProjectEmLoading } from '../reducers'
import { apiGetProjectEm } from '../api/project.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchProjectEm = (projectId: string, options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().attendance.projectEmLoading[projectId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().attendance.projectEm[projectId]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setProjectEmLoading({ id: projectId, loading: true }))
      const { result, status } = await apiGetProjectEm(projectId)
      if (status) {
        dispatch(replaceProjectEm({ id: projectId, data: result }))
      }
    } finally {
      dispatch(setProjectEmLoading({ id: projectId, loading: false }))
    }
  }
}
