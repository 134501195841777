import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { selectEmEducationRequests } from '../selectors'
import { fetchEmEducationRequests } from '../actions'
import { EmEducationRequestState } from '../types'

export const useEmEducationRequests = (employeeId: string, inView: boolean): [EmEducationRequestState[], boolean] => {
  const data = useSelector(selectEmEducationRequests)(employeeId)
  const loading = useSelector((state: StoreState) => state.employee.emEducationRequestsLoading[employeeId])

  useEffect(() => {
    if (inView && employeeId) dispatch(fetchEmEducationRequests(employeeId))
  }, [employeeId, inView])

  return [data, loading]
}
