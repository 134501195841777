import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { useSelector } from 'react-redux'
import { REPORTS_ROUTES } from '~/routes/routes'
import { InfoTooltip, Person, SearchInput } from '~/components'
import { Button, Card, ColumnsType, Form, Space, Table } from '~/core-components'
import {
  Screen,
  updateViewCriteria,
  useFirstView,
  useViewSchema,
  ViewCriteria,
  ViewCriteriaSimple,
  ViewSelectionButton
} from '~/features/selection'
import { EmSelectionDrawer } from '~/features/employee'
import { usePermissionGate } from '~/features/iam'
import { OedSubmissionStatus, Permission, PermissionAction } from '~/constants'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { Pagination } from '~/types/common'
import { formatYearMonth, formatMonth, formatMoney, getBaseUrl } from '~/utils'
import { refetchOedRecordsView } from '~/features/report/reducers'
import { OedRecordState, OedSubmissionState } from '~/features/report/types'
import { useOedRecordView } from '~/features/report/hooks'
import { addOedRecord, fetchOedRecordNewEmSelections, fetchOedRecordView } from '~/features/report/actions'
import { OedRefreshButton } from './OedRefreshButton'
import './OedRecords.less'

interface OedRecordsProps {
  oedSubmission?: OedSubmissionState
}

interface EmSelectionDrawerState {
  visible: boolean
}

const SCREEN_CODE: Screen = 'rpt_oed_record'
const PAGE_SIZE_OPTIONS = ['20', '50', '100']
const EM_SELECTION_DRAWER_STATE: EmSelectionDrawerState = { visible: false }

const nameColWidth = 250
const defaultColWidth = 200
const paginationStyle: CSSProperties = { marginRight: 20 }
const baseUrl = getBaseUrl('/filestore')

export const OedRecords: FC<OedRecordsProps> = ({ oedSubmission }) => {
  const oedSubmissionId = oedSubmission?.id || ''
  const selection = useSelector((state: StoreState) => state.selection.sysSelectionFields[SCREEN_CODE])
  const [view, viewLoading] = useFirstView(SCREEN_CODE)
  const viewId = view?.id || ''
  const [schema] = useViewSchema(SCREEN_CODE, viewId)
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(20)
  const [search, setSearch] = useState<string>('')
  const [data, dataLoading] = useOedRecordView(oedSubmissionId, viewId, page, pageSize, search, 'always')
  const loading = dataLoading || viewLoading

  const refetch = useSelector((state: StoreState) => state.report.oedRecordsViewRefetch)
  const canModify = usePermissionGate(Permission.rptMomOed, PermissionAction.Modify)
  const expanded = useSelector((state: StoreState) => state.report.oedSubmissionExpanded)
  const [adding, setAdding] = useState(false)

  const [addEmployeeDrawerState, setAddEmployeeDrawerState] =
    useState<EmSelectionDrawerState>(EM_SELECTION_DRAWER_STATE)

  useEffect(() => {
    if (viewId && oedSubmissionId) {
      dispatch(fetchOedRecordView(oedSubmissionId, viewId, { offset: pageSize * (page - 1), limit: pageSize }, search))
    }
  }, [viewId, oedSubmissionId, page, pageSize, search, refetch])

  const tableWidth =
    (schema?.selection.reduce(
      (sum, f) => (sum += selection?.entities[f.selectionFieldId]?.width || defaultColWidth),
      0
    ) || 0) +
    nameColWidth +
    6 * defaultColWidth

  const columns: ColumnsType<OedRecordState> = [
    {
      title: 'Name',
      key: 'identityNo',
      dataIndex: 'identityNo',
      fixed: 'left',
      className: 'first-col',
      width: nameColWidth,
      render: (value: string, record) => (
        <Person
          name={record.employeeName}
          description={!expanded && value}
          photo={record.photoId && `${baseUrl}/file/${record.photoId}/thumbnailphoto/${expanded ? 18 : 36}`}
          openAsNewTab={false}
          path={REPORTS_ROUTES.oedSubmissionRecord.replace(':id', oedSubmissionId).replace(':recordId', record.id)}
          size={expanded ? 18 : 36}
        />
      )
    }
  ]

  if (schema) {
    // Configurable employee columns
    columns.push(
      ...schema?.selection.map(f => {
        var field = selection?.entities[f.selectionFieldId]
        return {
          title: field?.description,
          key: field?.fieldName,
          dataIndex: field?.fieldName,
          width: field?.width || defaultColWidth,
          render: (value: string | number, record: OedRecordState, index: number) => {
            if (field?.format === 'date' && value) {
              return moment(value).format('DD MMM YYYY')
            }
            if (field?.format === 'yearmonth' && value) {
              return formatYearMonth(value as string)
            }
            if (field?.format === 'month' && value) {
              return formatMonth(value as number)
            }
            if (field?.format === 'money' && value) {
              return formatMoney(value as number)
            }
            return value
          }
        }
      })
    )
  }

  columns.push(
    ...([
      {
        title: 'Identity type',
        key: 'identityType',
        dataIndex: 'identityType',
        width: defaultColWidth,
        render: (value: string) => value
      },
      {
        title: 'Identity no.',
        key: 'identityNo',
        dataIndex: 'identityNo',
        width: 120,
        render: (value: string) => value
      },
      {
        title: 'PWM job level',
        key: 'pwmJobLevel',
        dataIndex: 'pwmJobLevel',
        width: 300,
        render: (value: string) => value
      },
      {
        title: 'Main job title',
        key: 'jobTitle',
        dataIndex: 'jobTitle',
        width: defaultColWidth,
        render: (value: string) => value
      },
      {
        title: (
          <>
            Status
            <InfoTooltip
              title={`Valid - All mandatory and optional fields are completed
                      Incomplete - Optional fields are incomplete
                      Invalid - Mandatory fields are incomplete`}
              overlayStyle={{ whiteSpace: 'pre-line', maxWidth: 400 }}
            />
          </>
        ),
        key: 'status',
        dataIndex: 'status',
        width: 100,
        render: (value: string) => <span className="tx-c">{value}</span>
      },
      {
        title: (
          <>
            Include in the submission?
            <InfoTooltip title="Only records included in the submission will appear in Excel download" />
          </>
        ),
        key: 'isInclude',
        dataIndex: 'isInclude',
        width: defaultColWidth,
        align: 'center',
        render: (value: string) => (value ? 'Yes' : 'No')
      }
    ] as ColumnsType<OedRecordState>)
  )

  const handleCloseSelectionDrawer = useCallback((changed?: boolean) => {
    if (changed) dispatch(refetchOedRecordsView())
  }, [])

  const handleCriteriaApply = useCallback(
    async (criteria: ViewCriteria[]) => {
      if (viewId) {
        setPage(1)
        await dispatch(updateViewCriteria(SCREEN_CODE, viewId, { id: viewId, criteria }))
        dispatch(refetchOedRecordsView())
      }
    },
    [viewId]
  )

  const handlePaginationChange = useCallback((page: number, pageSize?: number) => {
    setPage(page)
    setPageSize(pageSize || 20)
  }, [])

  const handleSearch = useCallback((value: string) => {
    setPage(1)
    setSearch(value)
  }, [])

  const handleAddEmployee = useCallback(() => {
    setAddEmployeeDrawerState({ visible: true })
  }, [])

  const handleFetchDataAddEmployee = useCallback(
    (viewId: string, pagination: Pagination, search: string) => {
      if (oedSubmissionId) {
        dispatch(fetchOedRecordNewEmSelections(oedSubmissionId, viewId, pagination, search))
      }
    },
    [oedSubmissionId]
  )

  const handleSelectAddEmployee = useCallback(
    async (employeeIds: string[]) => {
      try {
        setAdding(true)
        await dispatch(addOedRecord({ oedSubmissionId, employeeIds }))
        dispatch(refetchOedRecordsView())
      } finally {
        setAdding(false)
      }
    },
    [oedSubmissionId]
  )

  const handleCloseAddEmployeeDrawer = useCallback(() => {
    setAddEmployeeDrawerState(EM_SELECTION_DRAWER_STATE)
  }, [])

  return (
    <div className="oed-records">
      <div className="oed-records__action-bar">
        <Form.Item label="">
          <SearchInput onSearch={handleSearch} />
        </Form.Item>
        <ViewCriteriaSimple screenCode={SCREEN_CODE} viewId={viewId} onApply={handleCriteriaApply} label="" />
        <Space align="start">
          {canModify && oedSubmission?.status === OedSubmissionStatus.draft && (
            <Space>
              <Button onClick={handleAddEmployee}>Add employee</Button>
              <OedRefreshButton oedSubmissionId={oedSubmissionId} />
            </Space>
          )}
          <ViewSelectionButton
            screenCode={SCREEN_CODE}
            viewId={viewId}
            title="Configure OED record columns"
            onClose={handleCloseSelectionDrawer}
          />
        </Space>
      </div>
      <Card fitParent table>
        <Table
          rowKey="id"
          dataSource={data?.data}
          columns={columns}
          fitParent
          loading={loading || adding}
          scroll={{ x: tableWidth, y: 1000 }}
          pagination={{
            total: data?.count,
            current: page,
            pageSize,
            pageSizeOptions: PAGE_SIZE_OPTIONS,
            showSizeChanger: true,
            onChange: handlePaginationChange,
            style: paginationStyle
          }}
        />
      </Card>

      <EmSelectionDrawer
        {...addEmployeeDrawerState}
        viewName="rpt_oed_record_newem"
        title="Add employees"
        okText="Add"
        onFetchData={handleFetchDataAddEmployee}
        onSelect={handleSelectAddEmployee}
        onClose={handleCloseAddEmployeeDrawer}
        resetOnClose={true}
      />
    </div>
  )
}
