import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchYtdIndexYears } from '../actions'
import { selectYtdIndexYears } from '../selectors'

export const useYtdIndexYears = (): [string[], boolean] => {
  const ytdIndexYears = useSelector(selectYtdIndexYears)
  const loading = useSelector((state: StoreState) => state.tax.ytdIndexYearsLoading)

  useEffect(() => {
    dispatch(fetchYtdIndexYears())
  }, [])

  return [ytdIndexYears, loading]
}
