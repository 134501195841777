import React, { CSSProperties, ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import moment from 'moment-timezone'
import confirm from 'antd/lib/modal/confirm'
import pick from 'lodash/pick'
import { v4 as uuidv4 } from 'uuid'
import { AmountDisplay, Col, DrawerForm, EmSelect, ErrorDisplay, Row, SubHeader } from '~/components'
import { Button, Form, Input, Link, SecondaryText, Select, Skeleton, Tag, UploadFile } from '~/core-components'
import { EmPublicPerson } from '~/features/employee'
import { usePermissionGate } from '~/features/iam'
import { useFocus } from '~/hooks/use-focus'
import { ActionResult, Errors } from '~/types/store'
import { dispatch } from '~/stores/store'
import {
  ClaApprovalStatus,
  ClaCurrencyCode,
  ClaExpenseSubmissionType,
  ClaRecordStatus,
  ClaRecordType,
  ClaTaxableType,
  Permission,
  PermissionAction
} from '~/constants'
import { downloadWithDom, getBaseUrl, getFileTimestamp, showError } from '~/utils'
import { apiGetEmSelect } from '../../../api/employee.api'
import { apiGetClaimAmount, apiGetClaimAmountMultiple } from '../../../api/claim-apply.api'
import { IClaimAmount, IClaimAmountMultipleRequest, IClaimApply, IClaimRecordExpense } from '../../../types'
import {
  useCfConfigsDict,
  useClaimRecord,
  useClaimRecordApprovalHistories,
  useClaimRecordAttachments,
  useClaimTypesDict,
  useCurrentTaxRate,
  useEntitledClaimTypes
} from '../../../hooks'
import { applyClaimRecord, deleteClaimRecord } from '../../../actions'
import { ClaimStatusTag } from '../../ClaimStatusTag/ClaimStatusTag'
import { ClaimRecordSingleForm } from './ClaimRecordSingleForm'
import { ClaimCurrentApprover } from '../../ClaimCurrentApprover/ClaimCurrentApprover'
import { ClaimRecordApprovalHistories } from '../../ClaimRecordApprovalHistories/ClaimRecordApprovalHistories'
import { ClaimRecordMultipleFormRow, getClaimRecordExpenseEmptyForm } from './ClaimRecordMultipleFormRow'
import { apiDownloadAllClaimAttachment } from '~/features/claim/api/claim-record.api'
import { ClaimRecordCfJsonb } from './ClaimRecordCfJsonb'
import './MutateClaimRecordDrawer.less'

interface MutateClaimRecordDrawerProps {
  visible: boolean
  id?: string
  employeeId?: string
  editing?: boolean
  isMultiple?: boolean
  onClose: (action: 'saved' | 'cancelled') => void
}

const TODAY = moment().format('YYYY-MM-DD')

type IClaimApplyResetForm = Omit<IClaimApply, 'id'>

const EMPTY_FORM_DATA: IClaimApplyResetForm = {
  employeeId: '',
  claimTypeId: '',
  providerName: '',
  receiptNo: '',
  expenseDate: TODAY,
  expenseCurrencyCode: ClaCurrencyCode.sgd,
  exchangeRate: 1,
  exchangeUnit: 1,
  expenseAmount: 0,
  claimAmount: 0,
  claimAmountTax: 0,
  isTaxable: false,
  isClaimAmountTaxOverride: false,
  notes: '',
  expenses: [],
  attachments: [],
  deletedAttachmentIds: [],
  cfJsonb: ''
}
const NON_EDITABLE_STATUS = [ClaApprovalStatus.rejected, ClaApprovalStatus.cancelled]

const getEmptyForm = () => ({
  ...EMPTY_FORM_DATA,
  id: uuidv4()
})

const baseUrl = getBaseUrl('/claim')
const taxStyle: CSSProperties = { fontWeight: 'normal', fontStyle: 'italic' }

export const MutateClaimRecordDrawer: FC<MutateClaimRecordDrawerProps> = ({
  visible,
  id,
  employeeId,
  editing,
  isMultiple: isMultipleDefault,
  onClose
}) => {
  const [submitting, setSubmitting] = useState<'submit' | 'draft' | undefined>()
  const [formData, setFormData] = useState<IClaimApply>(getEmptyForm())
  const [errors, setErrors] = useState<Errors>()
  const [focusRef, setFocus] = useFocus(visible)
  const [isEditing, setIsEditing] = useState(false)
  const errorRef = useRef<any>(null)
  const formId = useMemo(() => `form-claim-record-${uuidv4()}`, [])

  const [claimTypesDict] = useClaimTypesDict()
  const [cfConfigsDict, cfConfigsDictLoading] = useCfConfigsDict()
  const claimType = claimTypesDict[formData.claimTypeId]
  const isMultiple =
    isMultipleDefault != null
      ? isMultipleDefault
      : claimType?.expenseSubmissionType === ClaExpenseSubmissionType.Multiple

  const [claimRecord, fetching] = useClaimRecord(id)
  const [claimRecordAttachments, attachmentLoading] = useClaimRecordAttachments(id)
  const [claimRecordApprovalHistories] = useClaimRecordApprovalHistories(id)
  const isNew = !claimRecord?.id
  const isDraft = claimRecord?.submittedBy == null
  const hasPayroll = claimRecord?.payRunId != null
  const hasSubmitted = claimRecord?.submittedBy != null
  const canModify =
    usePermissionGate(Permission.claRecord, PermissionAction.Modify) &&
    !NON_EDITABLE_STATUS.includes(claimRecord?.recordStatus || '')

  const [entitledClaimTypes, entitledClaimTypesLoading] = useEntitledClaimTypes(formData.employeeId, {
    enabled: isNew && isEditing
  })

  const [claimAmountLoading, setClaimAmountLoading] = useState(false)
  const [isRecompute, setIsRecompute] = useState(true)

  const maxExpenseDate = useMemo(
    () =>
      formData.expenses.reduce(
        (max, curr) => (moment(curr.expenseDate).isAfter(moment(max)) ? curr.expenseDate : max),
        '1900-01-01'
      ),
    [formData.expenses]
  )
  const [taxRate] = useCurrentTaxRate('', maxExpenseDate)
  const isExpenseTaxable = useMemo(
    () => formData.expenses.some(exp => exp.isTaxable && exp.expenseCurrencyCode === taxRate?.currencyCode),
    [formData.expenses, taxRate?.currencyCode]
  )

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)

    if (!visible) {
      setIsEditing(false)
    }
  }, [visible, setFocus])

  useEffect(() => {
    if (visible) {
      setIsEditing(!!editing)
    }
  }, [visible, editing])

  useEffect(() => {
    if (claimRecord) {
      const {
        id,
        employeeId,
        claimTypeId,
        providerName,
        receiptNo,
        expenseDate,
        expenseCurrencyCode,
        exchangeRate,
        exchangeUnit,
        expenseAmount,
        claimAmount,
        claimAmountTax,
        isTaxable,
        isClaimAmountTaxOverride,
        notes,
        expenses,
        cfJsonb
      } = claimRecord

      const attachments: UploadFile[] = claimRecordAttachments
        .filter(att => !att.claimRecordExpenseId)
        .map(
          att =>
            ({
              uid: att?.fileId || '',
              name: att?.fileName || '',
              size: att?.fileSize || 0,
              type: att?.fileFormat || '',
              status: 'done',
              url: `${baseUrl}/claimrecord/${id}/attachment/${att?.id}/downloadfile`
            } as UploadFile)
        )

      setFormData({
        employeeId,
        id,
        claimTypeId,
        providerName,
        receiptNo,
        expenseDate,
        expenseCurrencyCode,
        exchangeRate,
        exchangeUnit,
        expenseAmount,
        claimAmount,
        claimAmountTax,
        isTaxable,
        isClaimAmountTaxOverride,
        notes,
        expenses: expenses.map(exp => ({
          ...exp,
          attachments: claimRecordAttachments
            .filter(att => att.claimRecordExpenseId === exp.id)
            .map(
              att =>
                ({
                  uid: att?.fileId || '',
                  name: att?.fileName || '',
                  size: att?.fileSize || 0,
                  type: att?.fileFormat || '',
                  status: 'done',
                  url: `${baseUrl}/claimrecord/${id}/attachment/${att?.id}/downloadfile`
                } as UploadFile)
            )
        })),
        attachments,
        deletedAttachmentIds: [],
        cfJsonb
      })
    } else {
      setFormData({ ...getEmptyForm(), employeeId: employeeId || '' })
    }

    setIsRecompute(true)
  }, [claimRecord, claimRecordAttachments, employeeId])

  const handleFormDataChange = useCallback((updated: Partial<IClaimApply>) => {
    setErrors(undefined)
    setFormData(formData => ({ ...formData, ...updated }))
  }, [])

  const handleExpenseChange = useCallback((index: number, updated: Partial<IClaimRecordExpense>) => {
    setErrors(undefined)

    if (
      [
        'expenseTypeId',
        'expenseDate',
        'expenseAmount',
        'expenseCurrencyCode',
        'exchangeRate',
        'exchangeUnit',
        'isClaimAmountTaxOverride',
        'isTaxable'
      ].some(key => key in updated)
    ) {
      setIsRecompute(true)
    }

    setFormData(formData => {
      const expenses = formData.expenses.map((exp, idx) => (index === idx ? { ...exp, ...updated } : exp))
      const claimAmount = expenses.reduce((acc, current) => acc + current.claimAmount, 0)
      const claimAmountTax = expenses.reduce((acc, current) => acc + current.claimAmountTax, 0)
      const expenseDate = expenses.reduce(
        (acc, current) => (moment(acc).isBefore(moment(current.expenseDate)) ? acc : current.expenseDate),
        TODAY
      )

      return {
        ...formData,
        claimAmount,
        claimAmountTax,
        expenseDate,
        expenses
      }
    })
  }, [])

  const handleExpenseRemove = useCallback((index: number) => {
    setErrors(undefined)
    setFormData(formData => {
      const expenses = formData.expenses.filter((exp, idx) => index !== idx)
      const claimAmount = expenses.reduce((acc, current) => acc + current.claimAmount, 0)
      const claimAmountTax = expenses.reduce((acc, current) => acc + current.claimAmountTax, 0)
      const expenseDate = expenses.reduce(
        (acc, current) => (moment(acc).isBefore(moment(current.expenseDate)) ? acc : current.expenseDate),
        TODAY
      )
      const expenseAmount = expenses.reduce((acc, current) => acc + current.expenseAmount, 0)

      return {
        ...formData,
        claimAmount,
        claimAmountTax,
        expenseDate,
        expenseAmount,
        expenses
      }
    })
  }, [])

  const handleExpenseAdd = useCallback(() => {
    setErrors(undefined)
    setFormData(formData => ({
      ...formData,
      expenses: [
        ...formData.expenses,
        getClaimRecordExpenseEmptyForm({
          sequence: formData.expenses.length + 1,
          expenseCurrencyCode: claimTypesDict[formData.claimTypeId]?.currencyCode,
          isTaxable: claimTypesDict[formData.claimTypeId]?.taxableType === ClaTaxableType.Yes
        })
      ]
    }))
  }, [claimTypesDict])

  const handleOk = useCallback(
    async (isSubmit: boolean = true) => {
      let result: ActionResult | undefined
      setSubmitting(isSubmit ? 'submit' : 'draft')
      try {
        result = await dispatch(applyClaimRecord(formData, isSubmit))
      } finally {
        setSubmitting(undefined)
      }

      if (result?.errors) {
        setErrors(result.errors)
        errorRef.current?.scrollIntoView()
      }

      if (!result?.errors) {
        typeof onClose === 'function' && onClose('saved')
        setFormData(getEmptyForm())
      }
    },
    [formData, onClose]
  )

  const handleDelete = useCallback(() => {
    if (claimRecord) {
      const { id, claimTypeId } = claimRecord

      confirm({
        title: `Delete "${claimTypesDict[claimTypeId]?.name}"`,
        content: `Do you want to delete "${claimTypesDict[claimTypeId]?.name}"?`,
        onOk: async () => {
          const result: ActionResult | undefined = await dispatch(deleteClaimRecord(id))
          if (result?.errors) {
            setErrors(result.errors)
          }
          if (!result?.errors) {
            typeof onClose === 'function' && onClose('saved')
          }
        },
        okText: 'Delete',
        okType: 'danger'
      })
    }
  }, [claimTypesDict, claimRecord, onClose])

  const handleToggleEdit = useCallback(() => {
    setIsEditing(isEditing => !isEditing)
  }, [])

  const handleFetchEmployees = useCallback(async () => {
    const { status, result } = await apiGetEmSelect('past3mth')
    if (status) {
      return result
    }
    return []
  }, [])

  const handleComputeClaimAmountSingle = useCallback(
    async (
      expenseDate: string,
      expenseAmount: number,
      expenseCurrencyCode: string,
      exchangeRate: number,
      exchangeUnit: number,
      originalClaimAmount: number
    ) => {
      if (
        !formData.employeeId ||
        !formData.claimTypeId ||
        !expenseDate ||
        expenseAmount === 0 ||
        exchangeRate === 0 ||
        exchangeUnit === 0
      ) {
        return { claimAmount: 0, claimAmountTax: 0 }
      }

      try {
        setClaimAmountLoading(true)
        const request: IClaimAmount = {
          employeeId: formData.employeeId,
          claimTypeId: formData.claimTypeId,
          expenseDate,
          expenseAmount,
          expenseCurrencyCode,
          exchangeRate,
          exchangeUnit,
          originalClaimAmount
        }
        const {
          result: { claimAmount, claimAmountTax }
        } = await apiGetClaimAmount(request)
        return { claimAmount, claimAmountTax }
      } finally {
        setClaimAmountLoading(false)
      }
    },
    [formData.employeeId, formData.claimTypeId]
  )

  const drawerTitle = useMemo(() => {
    if (isNew) {
      return 'Add claim record'
    }

    if (isEditing) {
      return 'Edit claim record'
    }

    return 'Claim record'
  }, [isNew, isEditing])

  const okText = useMemo(() => {
    if (!canModify || hasPayroll) {
      return 'Close'
    }

    if (isEditing) {
      return 'Save and close'
    }

    return 'Edit'
  }, [canModify, hasPayroll, isEditing])

  const onSubmit = useCallback(() => {
    if (isEditing) {
      return handleOk(true)
    } else {
      return handleToggleEdit()
    }
  }, [isEditing, handleOk, handleToggleEdit])

  const handleComputeClaimAmountMultiple = useCallback(async () => {
    if (!formData.employeeId || !formData.claimTypeId || formData.expenses.length === 0) {
      return
    }

    try {
      setClaimAmountLoading(true)
      const request: IClaimAmountMultipleRequest = {
        employeeId: formData.employeeId,
        claimTypeId: formData.claimTypeId,
        details: formData.expenses.map(e => ({
          sequence: e.sequence,
          expenseTypeId: e.expenseTypeId,
          expenseDate: e.expenseDate,
          expenseAmount: e.expenseAmount,
          expenseCurrencyCode: e.expenseCurrencyCode,
          exchangeRate: e.exchangeRate,
          exchangeUnit: e.exchangeUnit,
          isTaxable: e.isTaxable,
          isClaimAmountTaxOverride: e.isClaimAmountTaxOverride,
          claimAmountTax: e.claimAmountTax,
          originalClaimAmount: isDraft ? 0 : claimRecord?.expenses.find(o => o.id === e.id)?.claimAmount || 0
        }))
      }
      const { result } = await apiGetClaimAmountMultiple(request)

      if (result) {
        const { totalClaimAmount, totalClaimAmountTax, details } = result

        handleFormDataChange({
          claimAmount: totalClaimAmount,
          claimAmountTax: totalClaimAmountTax,
          expenses: formData.expenses.map(e => {
            const item = details.find(d => d.sequence === e.sequence)
            return { ...e, claimAmount: item?.claimAmount || 0, claimAmountTax: item?.claimAmountTax || 0 }
          })
        })
      }

      setIsRecompute(false)
    } finally {
      setClaimAmountLoading(false)
    }
  }, [formData, claimRecord, handleFormDataChange, isDraft])

  const handleDownloadAllAttachment = useCallback(async () => {
    const { status, result, errors, message, errorData } = await apiDownloadAllClaimAttachment(claimRecord?.id || '')

    if (status) {
      const fileName = `claim_attachment_${claimRecord?.claimNo}_${getFileTimestamp()}.zip`
      downloadWithDom(result, fileName)
    } else {
      console.error('Error while downloading', errors)
      setErrors(errors)
      showError(message, errorData)
    }
  }, [claimRecord])

  return (
    <DrawerForm
      className="mutate-claim-record-drawer"
      open={visible}
      title={drawerTitle}
      okText={okText}
      okDisabled={claimAmountLoading || submitting === 'draft' || (isMultiple && isEditing && isRecompute)}
      onOk={!canModify || hasPayroll ? () => onClose('cancelled') : undefined}
      onClose={() => onClose('cancelled')}
      confirmLoading={submitting === 'submit'}
      width={isMultiple ? 1200 : 600}
      showDelete={!!id && isEditing}
      onDelete={handleDelete}
      formId={canModify && !hasPayroll ? formId : undefined}
      extras={
        <>
          {canModify && isEditing && isMultiple && (
            <Button onClick={handleComputeClaimAmountMultiple}>Calculate claim amount</Button>
          )}
          {canModify && isEditing && !hasSubmitted && (
            <Button
              onClick={() => handleOk(false)}
              loading={submitting === 'draft'}
              disabled={submitting === 'submit' || (isMultiple && isRecompute)}
            >
              Save as draft
            </Button>
          )}
        </>
      }
    >
      <Form id={formId} onFinish={onSubmit}>
        <>
          <Row gutter={15}>
            <Col span={isNew ? (isMultiple ? 12 : 24) : 15}>
              {employeeId ? (
                <Form.Item label="">
                  <EmPublicPerson id={employeeId} />
                </Form.Item>
              ) : (
                <Form.Item
                  label="Employee"
                  validateStatus={errors?.employeeId ? 'error' : ''}
                  help={errors?.employeeId}
                >
                  <EmSelect
                    ref={focusRef}
                    value={formData.employeeId}
                    onFetch={handleFetchEmployees}
                    onChange={(value: string) => {
                      handleFormDataChange({
                        ...getEmptyForm(),
                        employeeId: value
                      })
                    }}
                  />
                </Form.Item>
              )}
            </Col>
            <Col span={9} hidden={isEditing || claimRecordAttachments.length < 1} style={{ textAlign: 'right' }}>
              <Link onClick={handleDownloadAllAttachment}>download all attachments</Link>
            </Col>
          </Row>
          <Row gutter={[15, 10]} hidden={isNew}>
            <Col span={12} style={{ fontSize: 12, fontWeight: 'bold' }}>
              <Form.Item>{claimRecord?.claimNo ? `Claim #${claimRecord?.claimNo}` : ''}</Form.Item>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <Form.Item>
                <ClaimStatusTag status={claimRecord?.recordStatus} recordType={claimRecord?.recordType} />
              </Form.Item>
            </Col>
          </Row>
          {claimRecord?.recordType === ClaRecordType.cancellation && (
            <Row>
              <Col span={24}>
                <Form.Item label="Application type">
                  <Tag type="secondary">Cancellation</Tag>
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row gutter={15}>
            <Col span={isMultiple ? 12 : 24}>
              <Form.Item
                label="Claim type"
                validateStatus={errors?.claimTypeId ? 'error' : ''}
                help={errors?.claimTypeId}
              >
                {!isEditing || claimRecord?.id ? (
                  claimType?.name
                ) : (
                  <Select
                    showSearch
                    allowClear={false}
                    optionFilterProp="title"
                    loading={entitledClaimTypesLoading}
                    value={formData.claimTypeId}
                    onChange={(claimTypeId: string) =>
                      handleFormDataChange({
                        ...EMPTY_FORM_DATA,
                        employeeId: formData.employeeId,
                        claimTypeId,
                        expenseCurrencyCode: claimTypesDict[claimTypeId]?.currencyCode,
                        isTaxable: claimTypesDict[claimTypeId]?.taxableType === ClaTaxableType.Yes,
                        expenses:
                          claimTypesDict[claimTypeId]?.expenseSubmissionType === ClaExpenseSubmissionType.Multiple
                            ? [
                                getClaimRecordExpenseEmptyForm({
                                  sequence: 1,
                                  expenseCurrencyCode: claimTypesDict[claimTypeId]?.currencyCode,
                                  isTaxable: claimTypesDict[claimTypeId]?.taxableType === ClaTaxableType.Yes
                                })
                              ]
                            : []
                      })
                    }
                  >
                    {entitledClaimTypes?.map(data => (
                      <Select.Option key={data.claimTypeId} value={data.claimTypeId || ''} title={data.claimTypeName}>
                        {data.claimTypeName}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            {isMultiple && (
              <Col span={12}>
                <Form.Item label="Notes" validateStatus={errors?.notes ? 'error' : ''} help={errors?.notes}>
                  {!isEditing ? (
                    formData.notes || '-'
                  ) : (
                    <Input.TextArea
                      rows={1}
                      value={formData.notes}
                      onChange={(value?: ChangeEvent<HTMLTextAreaElement>) =>
                        handleFormDataChange({ notes: value?.target.value })
                      }
                    />
                  )}
                </Form.Item>
              </Col>
            )}
          </Row>
          <ErrorDisplay ref={errorRef} errors={errors} />
          {!isMultiple && (
            <ClaimRecordSingleForm
              data={formData}
              original={claimRecord}
              loading={fetching || attachmentLoading}
              readOnly={!isEditing}
              onChange={handleFormDataChange}
              onComputeClaimAmount={handleComputeClaimAmountSingle}
              claimAmountLoading={claimAmountLoading}
            />
          )}
          {isMultiple &&
            (fetching || attachmentLoading ? (
              <Skeleton active />
            ) : (
              <>
                <ClaimRecordCfJsonb
                  ctCustomField={claimType?.customField}
                  cfJsonb={formData.cfJsonb}
                  cfConfigsDict={cfConfigsDict}
                  loading={cfConfigsDictLoading}
                  readOnly={!isEditing}
                  onChange={(cfJsonb: string) => handleFormDataChange({ cfJsonb })}
                />
                {formData.expenses?.map((exp, index) => (
                  <>
                    <ClaimRecordMultipleFormRow
                      key={exp.id}
                      index={index}
                      claimTypeId={formData.claimTypeId}
                      data={exp}
                      readOnly={!isEditing}
                      claimAmountLoading={claimAmountLoading}
                      onChange={handleExpenseChange}
                      onRemove={handleExpenseRemove}
                    />
                    {!(index === formData.expenses.length - 1) && (
                      <div className="expense-divider">
                        <hr />
                      </div>
                    )}
                  </>
                ))}
                {isEditing && (
                  <Form.Item>
                    <Link onClick={handleExpenseAdd}>add more</Link>
                  </Form.Item>
                )}
                {[ClaRecordStatus.Pending, ClaRecordStatus.PendingCancel].includes(
                  claimRecord?.recordStatus as ClaRecordStatus
                ) && (
                  <ClaimCurrentApprover approvers={claimRecord?.currentApprovers || []} hidden={isEditing || isNew} />
                )}
                <ClaimRecordApprovalHistories
                  histories={claimRecordApprovalHistories}
                  status={claimRecord?.recordStatus || ''}
                  hidden={isEditing || isNew}
                  recordType={claimRecord?.recordType}
                  {...pick(claimRecord!, 'creatorName', 'createdDate', 'submitterName', 'submittedDate')}
                />
                <Row className="claim-record-form__claim-amount">
                  <Col span={24}>
                    <SubHeader type="primary">
                      <Row>
                        <Col flex="auto">Total claim amount</Col>
                        <Col flex="none">
                          <AmountDisplay
                            symbol={claimType?.currencyCode}
                            value={formData.claimAmount}
                            loading={claimAmountLoading}
                          />
                        </Col>
                      </Row>
                      {!claimAmountLoading && isExpenseTaxable && (
                        <Row>
                          <Col flex="auto" />
                          <Col flex="none">
                            <SecondaryText size="small" style={taxStyle}>
                              Inclusive of GST{' '}
                              <AmountDisplay symbol={claimType?.currencyCode} value={formData.claimAmountTax} />
                            </SecondaryText>
                          </Col>
                        </Row>
                      )}
                    </SubHeader>
                  </Col>
                </Row>
              </>
            ))}
        </>
      </Form>
    </DrawerForm>
  )
}
