import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectMyLoginAndPermissions } from '~/features/iam'
import { dispatch } from '~/stores/store'
import { StoreState } from '../../../types/store'
import { fetchPermittedEmployeeAtt } from '../actions'

export const useIsPermittedEmployeeAtt = (employeeId: string): boolean => {
  const myJwt = useSelector(selectMyLoginAndPermissions)
  const permittedEmployeeAtt = useSelector((state: StoreState) => state.employee.permittedEmployeeAtt)
  const isPermitted = myJwt?.isOwner || myJwt?.isSuperUser || permittedEmployeeAtt.includes(employeeId)

  useEffect(() => {
    dispatch(fetchPermittedEmployeeAtt())
  }, [])

  return isPermitted
}
