import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { apiUpdateEmSection } from '../api/em-section.api'
import { EmSectionState, IEmSectionInfo } from '../types'
import { setEmSection, setEmployee } from '../reducers'

export const updateEmSection = <T extends IEmSectionInfo>(
  employeeId: string,
  id: string,
  before: T,
  request: T
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!employeeId || !id) return

    const after = { ...before, ...request } as T
    const patch = generateJsonPatch<T>(before, after)
    const { result, status, errors, message, errorData } = await apiUpdateEmSection(employeeId, id, patch)

    if (status) {
      if (result.emSectionId) {
        const employee = getState().employee.employees.entities[employeeId]
        if (employee) {
          dispatch(setEmployee({ ...employee, emSectionId: result.emSectionId }))
        }
      }

      const emSection = getState().employee.emSections[employeeId]?.entities[id]
      dispatch(
        setEmSection({
          employeeId,
          data: {
            ...(emSection as EmSectionState),
            ...after
          }
        })
      )

      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
