import { replaceClaimRecordApprovalHistories, setClaimRecordApprovalHistoriesLoading } from '../reducers'
import { apiGetClaimRecordApprovalHistories } from '../api/claim-record-approval-history.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchClaimRecordApprovalHistories = (
  id: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const loading = getState().claim.claimRecordApprovalHistoriesLoading[id]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().claim.claimRecordApprovalHistories[id]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setClaimRecordApprovalHistoriesLoading({ id, loading: true }))
      const { result, status } = await apiGetClaimRecordApprovalHistories(id)
      if (status) {
        dispatch(replaceClaimRecordApprovalHistories({ id, data: result }))
      }
    } finally {
      dispatch(setClaimRecordApprovalHistoriesLoading({ id, loading: false }))
    }
  }
}
