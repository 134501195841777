import { replaceMyLeaveRecordsView, setMyLeaveRecordsViewLoading } from '../../reducers'
import { apiGetMyLeaveRecordsView } from '../../api/ssleave-record.api'
import { ThunkResult } from '~/types/store'
import { Pagination } from '~/types/common'

export const fetchMyLeaveRecordsView = (pagination: Pagination): ThunkResult<void> => {
  return async dispatch => {
    try {
      dispatch(setMyLeaveRecordsViewLoading(true))
      const { result, status } = await apiGetMyLeaveRecordsView(pagination)
      if (status) dispatch(replaceMyLeaveRecordsView(result))
    } finally {
      dispatch(setMyLeaveRecordsViewLoading(false))
    }
  }
}
