import { setAttendanceGroup } from '../reducers'
import { apiGetAttendanceGroup } from '../api/attendance-group.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchAttendanceGroup = (id: string, options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().attendance.attendanceGroups?.entities[id]
      if (hasData) return
    }

    const { result, status } = await apiGetAttendanceGroup(id)
    if (status) {
      dispatch(setAttendanceGroup(result))
    }
  }
}
