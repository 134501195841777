import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { SysSelectionFieldState } from '~/features/selection'

export const selectDailyFields = createSelector(
  (state: StoreState) => state.selection.sysSelectionFields['daily_record'],
  (dailyRecordFieldsState): string[] => {
    return (Object.values(dailyRecordFieldsState?.entities || {}) as SysSelectionFieldState[])
      .filter(f => f.selectionCode === 'daily_record')
      .map(f => f.fieldName)
  }
)
