import React, { FC, HTMLAttributes } from 'react'
import classNames from 'classnames'
import { Space } from '../Space/Space'
import { Divider } from '../Divider/Divider'
// import './LinkGroup.less'

interface LinkGroupProps extends HTMLAttributes<HTMLElement> {
  separator?: string
}

export const LinkGroup: FC<LinkGroupProps> = ({ separator = '|', children, className, ...props }: LinkGroupProps) => {
  return (
    <Space size="small" className={classNames('link-group', className)} split={<Divider type="vertical" />} {...props}>
      {children}
    </Space>
  )
}
