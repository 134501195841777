import React, { FC } from 'react'
import { Tag } from '~/core-components'
import { LveApprovalStatus, LveRecordType } from '~/constants'

interface LeaveStatusTagProps {
  statuses: string[]
  recordType?: string
}

export const LeaveStatusTag: FC<LeaveStatusTagProps> = ({ statuses, recordType }) => {
  const cancellationLabel = recordType === LveRecordType.cancellation ? 'cancellation' : ''

  return (
    <>
      {statuses.includes(LveApprovalStatus.approved) ? (
        <Tag type="success">Approved</Tag>
      ) : statuses.includes(LveApprovalStatus.cancelled) ? (
        <Tag type="danger">Cancelled</Tag>
      ) : statuses.includes(LveApprovalStatus.rejected) ? (
        <Tag type="danger">{cancellationLabel} Rejected</Tag>
      ) : statuses.includes(LveApprovalStatus.pendingCancel) ? (
        <Tag type="secondary">Pending for cancellation</Tag>
      ) : statuses.includes(LveApprovalStatus.pending) ? (
        <Tag type="secondary">Pending for approval</Tag>
      ) : null}
    </>
  )
}
