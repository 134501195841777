import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiDeleteCtPolicy } from '../api/ct-policy.api'
import { removeCtPolicy, removeCtPolicyEtByPolicy } from '../reducers'

export const deleteCtPolicy = (claimTypeId: string, id: string): ThunkResult<void> => {
  return async dispatch => {
    if (!claimTypeId || !id) return

    const { status, errors, message, errorData } = await apiDeleteCtPolicy(claimTypeId, id)
    if (status) {
      dispatch(removeCtPolicyEtByPolicy(id))
      dispatch(removeCtPolicy({ claimTypeId, id }))
      showSuccess('Deleted')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
