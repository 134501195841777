import { EntityState } from '@reduxjs/toolkit'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import storage from 'redux-persist/lib/storage'
import { KeyValue } from '~/types/common'
import { LoginState, IsEmployeeState } from './login.state'
import { LoginPermissionState } from './loginpermission.state'
import { MyJwtState, MyTenantState } from './my.state'
import { SysPermissionState } from './syspermission.state'
import { TenantConfigState } from './tenant-config.state'

export const iamPersistConfig = {
  key: 'iam',
  whitelist: [
    'logins',
    'loginPermissions',
    'myTenants',
    'sysPermissions',
    'isEmployees',
    'tenantConfigs',
    'tenantConfigOptions'
  ],
  stateReconciler: autoMergeLevel2,
  storage
}

export interface IamRootState {
  logins: EntityState<LoginState>
  loginsLoading: boolean
  loginPermissions: { [loginId: string]: EntityState<LoginPermissionState> }
  loginPermissionsLoading: boolean
  myJwt?: MyJwtState
  myTenantPhotoId?: string
  myTenants: EntityState<MyTenantState>
  myTenantsLoading: boolean
  sysPermissions: EntityState<SysPermissionState>
  sysPermissionsLoading: boolean
  isEmployees: EntityState<IsEmployeeState>
  isEmployeesLoading: boolean
  tenantConfigs: EntityState<TenantConfigState>
  tenantConfigsLoading: boolean
  tenantConfigOptions: { [code: string]: EntityState<KeyValue> }
  tenantConfigOptionsLoading: { [code: string]: boolean }
}
