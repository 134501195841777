import { replaceLocationProjects, setLocationProjectsLoading } from '../reducers'
import { apiGetLocationProjects } from '../api/location.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchLocationProjects = (
  locationId: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!locationId) return

    const loading = getState().attendance.locationProjectsLoading[locationId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().attendance.locationProjects[locationId]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setLocationProjectsLoading({ id: locationId, loading: true }))
      const { result, status } = await apiGetLocationProjects(locationId)
      if (status) {
        dispatch(replaceLocationProjects({ id: locationId, data: result }))
      }
    } finally {
      dispatch(setLocationProjectsLoading({ id: locationId, loading: false }))
    }
  }
}
