import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Card, ColumnsType, Form, Link, LinkGroup, SecondaryText, Space, Table, Tag } from '~/core-components'
import { Col, EmpKeyValues, InactiveTag, Row } from '~/components'
import { fetchEmpKeyvalues, selectKeyvaluesById } from '~/features/master'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { useToggle } from '~/hooks/use-toggle'
import { useFirstInView } from '~/hooks/use-first-in-view'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { isInactive } from '~/utils'
import { MutateEmLeaveGroupDrawer } from './MutateEmLeaveGroupDrawer'
import { IEmLeaveGroupInfo, EmLeaveGroupState } from '../../../types'
import { fetchEmLeaveGroups } from '../../../actions'
import { selectCurrentEmLeaveGroup, selectHistoryEmLeaveGroups } from '../../../selectors'

interface EmLeaveGroupsProps {
  employeeId?: string
  id?: string
}

type EmLeaveGroupTable = EmLeaveGroupState

const EMPTY_FORM_DATA: IEmLeaveGroupInfo = {
  startDate: '',
  leaveGroupId: ''
}

interface DrawerState {
  visible: boolean
  employeeId?: string
  data?: EmLeaveGroupState
  isCurrent: boolean
}

const DEFAULT_MODAL_STATE: DrawerState = { visible: false, isCurrent: false }

const cardStyle: CSSProperties = { margin: 24 }
const cardBodyStyle: CSSProperties = { padding: 0 }
const formStyle: CSSProperties = { padding: '6px 24px' }

export const EmLeaveGroups: FC<EmLeaveGroupsProps> = ({ employeeId, id }: EmLeaveGroupsProps) => {
  const { ref, inView } = useFirstInView<HTMLDivElement>({ threshold: 0.25 })
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_MODAL_STATE)
  const [formData, setFormData] = useState<IEmLeaveGroupInfo>(EMPTY_FORM_DATA)
  const current = useSelector(selectCurrentEmLeaveGroup)(employeeId, id)
  const histories = useSelector(selectHistoryEmLeaveGroups)(employeeId)
  const leaveGroups = useSelector(selectKeyvaluesById)('leaveGroup')
  const [showHistory, toggleShowHistory] = useToggle()
  const canView = usePermissionGate(Permission.employeeLeaveGroup)
  const canModify = usePermissionGate(Permission.employeeLeaveGroup, PermissionAction.Modify)
  const loading = useSelector((state: StoreState) => state.employee.emLeaveGroupsLoading[employeeId || ''])

  useEffect(() => {
    if (inView) {
      dispatch(fetchEmpKeyvalues('leaveGroup', undefined, undefined, { strategy: 'when-empty' }))
    }
  }, [inView])

  useEffect(() => {
    if (inView) {
      if (employeeId) {
        dispatch(fetchEmLeaveGroups(employeeId))
      }
    }
  }, [inView, employeeId])

  useEffect(() => {
    if (showHistory && histories.length === 0) {
      toggleShowHistory()
    }
  }, [histories.length, showHistory, toggleShowHistory])

  useEffect(() => {
    if (current) {
      const { startDate, leaveGroupId } = current
      setFormData({ startDate, leaveGroupId })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [current])

  const handleEditLeaveGroup = useCallback(
    (emLeaveGroup?: EmLeaveGroupState) => {
      setDrawerState({ visible: true, employeeId, data: emLeaveGroup, isCurrent: id === emLeaveGroup?.id })
    },
    [employeeId, id, setDrawerState]
  )

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_MODAL_STATE)
  }, [])

  const columns: ColumnsType<EmLeaveGroupTable> = [
    {
      title: 'Effective date',
      key: 'startDate',
      dataIndex: 'startDate',
      width: 150,
      render: (value: string) => value && moment(value).format('DD MMM YYYY')
    },
    {
      title: 'Leave group',
      key: 'leaveGroupId',
      dataIndex: 'leaveGroupId',
      render: (value: string, record) => (
        <Space>
          {leaveGroups[value]?.value}
          {record.id === id && <Tag type="primary">current</Tag>}
          {isInactive(leaveGroups[value]?.inactiveDate) && <InactiveTag />}
        </Space>
      )
    },
    {
      key: 'action',
      align: 'right',
      render: (value: string, record) =>
        canModify && (
          <Link size="small" onClick={() => handleEditLeaveGroup(record)}>
            edit
          </Link>
        )
    }
  ]

  if (!canView) return null

  return (
    <div ref={ref}>
      <Card
        title="Leave group"
        style={cardStyle}
        bodyStyle={cardBodyStyle}
        loading={loading}
        extra={
          <LinkGroup>
            {canModify && !showHistory && (
              <Link onClick={() => handleEditLeaveGroup(current)}>{current ? 'edit' : 'add'}</Link>
            )}
            {(histories?.length || 0) > 0 && (
              <Link onClick={toggleShowHistory}>{showHistory ? 'hide history' : 'history'}</Link>
            )}
          </LinkGroup>
        }
      >
        {!showHistory && (
          <Form style={formStyle}>
            <Row gutter={30}>
              <Col span={12}>
                <Form.Item
                  label={
                    <Space>
                      Leave group
                      {formData.startDate && (
                        <SecondaryText size="small">
                          effective from {moment(formData.startDate).format('DD MMM YYYY')}
                        </SecondaryText>
                      )}
                    </Space>
                  }
                >
                  <EmpKeyValues id="leaveGroup" value={formData.leaveGroupId} readOnly />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
        {showHistory && <Table rowKey="id" dataSource={histories} pagination={false} columns={columns} />}
        {canModify && <MutateEmLeaveGroupDrawer {...drawerState} onClose={handleCloseDrawer} />}
      </Card>
    </div>
  )
}
