import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { TimeLogViewState } from '../types'

export const selectTimeLogsView = createSelector(
  (state: StoreState) => state.attendance.timeLogsView,
  timeLogsState =>
    memoize((viewId: string): TimeLogViewState => {
      return (timeLogsState?.entities[viewId] || {}) as TimeLogViewState
    })
)
