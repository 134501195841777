import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import confirm from 'antd/lib/modal/confirm'
import { Button, PageHeader } from '~/core-components'
import { DocumentTitle } from '~/components'
import { attendanceMenus, useMasterMenu } from '~/features/master'
import { usePermissionGate } from '~/features/iam'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { Permission, PermissionAction } from '~/constants'
import { MenuItem } from '~/types/common'
import { dispatch } from '~/stores/store'
import { deleteCalendar } from '../../actions'
import { useCalendar } from '../../hooks'
import { selectCalendars } from '../../selectors'
import { AddCalendarDrawer } from './components/AddCalendarDrawer'
import { CalendarInfo } from './components/CalendarInfo'
import { CalendarPattern } from './components/CalendarPattern'
import './Calendar.less'

interface CalendarProps {}

interface CalendarParams {
  id: string
}

interface DrawerState {
  visible: boolean
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: SETTINGS_ROUTES.workCalendars,
    breadcrumbName: 'Overview'
  },
  {
    path: '',
    breadcrumbName: 'Work calendar'
  }
]

export const Calendar: FC<CalendarProps> = () => {
  const { id } = useParams<CalendarParams>()
  const calendars = useSelector(selectCalendars)
  const [calendar] = useCalendar(id)
  const [editing, setEditing] = useState(false)
  const history = useHistory()
  const canModify = usePermissionGate(Permission.calendar, PermissionAction.Modify)
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const { setMenus } = useMasterMenu()

  const handleAddCalendar = useCallback(() => {
    setDrawerState({ visible: true })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  useEffect(() => {
    const menus: MenuItem[] = calendars.map(s => ({
      path: SETTINGS_ROUTES.workCalendar.replace(':id', s!.id),
      value: s!.name,
      sysPermissionId: Permission.calendar
    }))

    if (canModify)
      menus.push({
        path: (
          <Button type="dashed" onClick={handleAddCalendar} block>
            Add calendar
          </Button>
        ),
        value: '',
        sysPermissionId: ''
      })

    setMenus(menus)
    return () => {
      setMenus(attendanceMenus)
    }
  }, [setMenus, calendars, canModify, handleAddCalendar])

  const handleEdit = useCallback(() => setEditing(true), [])
  const handleView = useCallback(() => setEditing(false), [])

  const handleDelete = useCallback(() => {
    if (id && calendar) {
      confirm({
        title: 'Delete calendar',
        content: `Do you want to delete calendar "${calendar?.name}"?`,
        onOk: () => {
          dispatch(deleteCalendar(id))
          history.push(SETTINGS_ROUTES.workCalendars)
        },
        okText: 'Delete',
        okType: 'danger'
      })
    }
  }, [id, calendar, history])

  return (
    <div id="calendar" className="calendar">
      <DocumentTitle title="Calendar" />
      <PageHeader
        title="Calendar"
        containerId="calendar"
        breadcrumb={{ routes }}
        extra={
          canModify &&
          editing && (
            <Button key="add" onClick={handleDelete}>
              Delete
            </Button>
          )
        }
      />
      <div className="calendar__body">
        <CalendarInfo calendar={calendar} onEdit={handleEdit} onSave={handleView} onCancel={handleView} />
        <CalendarPattern calendar={calendar} onEdit={handleEdit} onSave={handleView} onCancel={handleView} />
        {canModify && <AddCalendarDrawer {...drawerState} onClose={handleCloseDrawer} />}
      </div>
    </div>
  )
}
