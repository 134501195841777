import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { selectEmFamilyRequests } from '../selectors'
import { fetchEmFamilyRequests } from '../actions'
import { EmFamilyRequestState } from '../types'

export const useEmFamilyRequests = (employeeId: string, inView: boolean): [EmFamilyRequestState[], boolean] => {
  const data = useSelector(selectEmFamilyRequests)(employeeId)
  const loading = useSelector((state: StoreState) => state.employee.emFamilyRequestsLoading[employeeId])

  useEffect(() => {
    if (inView && employeeId) dispatch(fetchEmFamilyRequests(employeeId))
  }, [employeeId, inView])

  return [data, loading]
}
