import { Store } from 'redux'
import { StoreState } from '../types/store'

export let store: Store<StoreState>

export const setStore = (newStore: Store<StoreState>) => {
  store = newStore
}

export const getState = (): StoreState => {
  if (!store || !store.getState) {
    return {} as StoreState // used by tests
  }
  return store.getState()
}

export const dispatch = (action: any) => {
  if (!store || !store.getState) {
    return
  }
  return store.dispatch(action)
}
