import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { Screen } from '../types'

export const selectIsViewOrSchemaLoading = createSelector(
  (state: StoreState) => state.selection.viewLoading,
  (state: StoreState) => state.selection.viewSchemaLoading,
  (viewLoadingState, viewSchemaLoadingState) =>
    memoize(
      (screenCode: Screen, viewId: string | undefined): boolean => {
        const viewLoading = viewLoadingState[screenCode]
        const viewSchemaLoading = viewSchemaLoadingState[screenCode]
        return (
          (viewLoading && viewLoading[viewId || '']) || (viewSchemaLoading && viewSchemaLoading[viewId || '']) || false
        )
      },
      (screenCode: Screen, viewId: string | undefined) => `${screenCode}|${viewId}`
    )
)
