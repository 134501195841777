import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'

export const selectMainEmIdentity = createSelector(
  (state: StoreState) => state.employee.emIdentities,
  emIdentitiesState =>
    memoize(
      (employeeId?: string, id?: string) => {
        if (!employeeId || !id) return undefined

        return emIdentitiesState[employeeId]?.entities[id]
      },
      (employeeId?: string, id?: string) => `${employeeId}|${id}`
    )
)

export const selectEmIdentities = createSelector(
  (state: StoreState) => state.employee.emIdentities,
  emIdentitiesState =>
    memoize((employeeId?: string) => {
      if (!employeeId) return []

      return Object.values(emIdentitiesState[employeeId]?.entities || {}).sort((a, b) => {
        const aIssueDate = new Date(a!.issueDate).getTime()
        const bIssueDate = new Date(b!.issueDate).getTime()
        const aExpiryDateStr = a!.cancelledDate ? a!.cancelledDate : a!.expiryDate
        const aExpiryDate = !!aExpiryDateStr ? new Date(aExpiryDateStr).getTime() : 0
        const bExpiryDateStr = b!.cancelledDate ? b!.cancelledDate : b!.expiryDate
        const bExpiryDate = !!bExpiryDateStr ? new Date(bExpiryDateStr).getTime() : 0

        if (bExpiryDate === 0 && aExpiryDate === 0) {
          return bIssueDate - aIssueDate
        } else {
          if (bExpiryDate === 0) {
            return 1
          } else if (aExpiryDate === 0) {
            return -1
          } else {
            return bExpiryDate - aExpiryDate
          }
        }
      })
    })
)
