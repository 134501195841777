import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { SSTimeLogApprovalHistoryState } from '../types'

export const selectMyTimeLogApprovalHistories = createSelector(
  (state: StoreState) => state.myAttendance.myTimeLogApprovalHistories,
  myTimeLogApprovalHistoriesState =>
    memoize((timeLogId: string) => {
      return Object.values(
        myTimeLogApprovalHistoriesState[timeLogId]?.entities || {}
      ) as SSTimeLogApprovalHistoryState[]
    })
)
