import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { ShiftRoleTeState, IShiftRoleTe } from '../types'

const baseUrl = getBaseUrl('/attendance/shiftrole')

export const apiGetShiftRoleTes = (shiftRoleId: string) =>
  request<ShiftRoleTeState[]>('get', `${baseUrl}/${shiftRoleId}/te`)

export const apiGetShiftRoleTe = (shiftRoleId: string, shiftRoleTeId: string) =>
  request<ShiftRoleTeState>('get', `${baseUrl}/${shiftRoleId}/te/${shiftRoleTeId}`)

export const apiAddShiftRoleTe = (shiftRoleId: string, shiftRoleTe: IShiftRoleTe) =>
  request<{ id: string }>('post', `${baseUrl}/${shiftRoleId}/te`, shiftRoleTe)

export const apiUpdateShiftRoleTe = (shiftRoleId: string, id: string, shiftRoleTe: Operation[]) =>
  request<void>('patch', `${baseUrl}/${shiftRoleId}/te/${id}`, shiftRoleTe)

export const apiDeleteShiftRoleTe = (shiftRoleId: string, id: string) =>
  request<void>('delete', `${baseUrl}/${shiftRoleId}/te/${id}`)
