import React, { FC, useEffect, useMemo, useState } from 'react'
import { ColumnsType, Table, TableRowSelection } from '~/core-components'
import { ErrorDisplay } from '~/components'
import { formatDateRange, formatNumberUnit, UnitType } from '~/utils'
import { Errors } from '~/types/store'
import { LeaveGrantBulkFormData } from '../LeaveGrantBulkDrawer'
import { LeaveGrantBulkRowState } from '~/features/leave/types'
import './SelectLeaveGrantBulkRecord.less'

interface SelectLeaveGrantBulkRecordProps {
  visible: boolean
  data: Partial<LeaveGrantBulkFormData>
  errors?: Errors
  onSelect: (data: Partial<LeaveGrantBulkFormData>) => void
}

export const SelectLeaveGrantBulkRecord: FC<SelectLeaveGrantBulkRecordProps> = ({
  visible,
  data,
  errors,
  onSelect
}) => {
  const [selected, setSelected] = useState<LeaveGrantBulkRowState[]>([])

  useEffect(() => {
    if (data.selected) {
      setSelected(data.selected)
    }
  }, [data.selected])

  const columns: ColumnsType<LeaveGrantBulkRowState> = [
    {
      title: 'Employee',
      key: 'employeeNo',
      dataIndex: 'employeeNo',
      render: (value: string, record) => `[${value}] ${record.employeeName}`
    },
    {
      title: 'Leave type',
      key: 'leaveTypeName',
      dataIndex: 'leaveTypeName'
    },
    {
      title: 'Validity period',
      key: 'startDate',
      dataIndex: 'startDate',
      render: (value: string, record: LeaveGrantBulkRowState) => formatDateRange(value, record.endDate)
    },
    {
      title: 'Quantity',
      key: 'earned',
      dataIndex: 'earned',
      render: (value: number, record: LeaveGrantBulkRowState) => formatNumberUnit(value, record.unit as UnitType)
    },
    {
      title: 'Notes',
      key: 'notes',
      dataIndex: 'notes'
    }
  ]

  const rowSelection: TableRowSelection<LeaveGrantBulkRowState> = useMemo(
    () => ({
      type: 'checkbox',
      selectedRowKeys: selected.map(d => d.employeeId),
      hideSelectAll: false,
      onChange: (_selectedRowKeys, rows) => {
        typeof onSelect === 'function' && onSelect({ selected: rows })
        setSelected(rows)
      }
    }),
    [selected, onSelect]
  )

  return (
    <div className="select-encash-record" hidden={!visible}>
      <ErrorDisplay errors={errors} />
      <Table
        rowSelection={rowSelection}
        rowKey="employeeId"
        dataSource={data.source}
        columns={columns}
        pagination={false}
      />
    </div>
  )
}
