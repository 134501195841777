import React, { FC, useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { LoadingOutlined } from '@ant-design/icons'
import { Row, Col } from '~/components'
import { Dropdown, SecondaryText, SecondaryLink } from '~/core-components'
import { PersonAvatar } from './PersonAvatar'
import { NewTabLinkIcon } from '../NewTabLink/NewTabLink'
import './Person.less'

export interface PersonProps {
  photo?: string
  size?: number
  name?: string
  description?: React.ReactNode | string
  path?: string
  openAsNewTab?: boolean
  newTabPath?: string
  loading?: boolean
}

export const Person: FC<PersonProps> = ({
  photo,
  size = 36,
  name,
  description,
  path,
  openAsNewTab = true,
  newTabPath,
  loading
}) => {
  const [hovered, setHovered] = useState<boolean>(false)

  const handleClickNewTab = useCallback(() => {
    window.open(newTabPath, '_blank')
  }, [newTabPath])

  const handleToogleHovered = useCallback(() => {
    setHovered(!hovered)
  }, [hovered])

  return (
    <div className="person" onMouseEnter={handleToogleHovered} onMouseLeave={handleToogleHovered}>
      <PersonAvatar photo={photo} size={size} loading={loading} />
      <div className="person__info">
        {path && (!openAsNewTab || !!newTabPath) && (
          <Row gutter={20}>
            <Col>
              <Link to={path} rel="noopener noreferrer">
                {name}
              </Link>
            </Col>
            {!!newTabPath && (
              <Col hidden={!hovered}>
                <Dropdown
                  disabled={loading}
                  menu={{
                    items: [{ key: 'open', label: 'Open employee in new tab', onClick: handleClickNewTab }]
                  }}
                >
                  {loading ? (
                    <LoadingOutlined />
                  ) : (
                    <SecondaryLink>
                      <i className="fal fa-ellipsis" />
                    </SecondaryLink>
                  )}
                </Dropdown>
              </Col>
            )}
          </Row>
        )}
        {path && openAsNewTab && (
          <NewTabLinkIcon path={path} tooltipText="Open employee in a new tab" autoFlex={false}>
            {name}
          </NewTabLinkIcon>
        )}
        {!path && <h1>{name}</h1>}
        {description && <SecondaryText>{description}</SecondaryText>}
      </div>
    </div>
  )
}
