import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { LeaveRecordDtlState } from '../types'

export const selectLeaveRecordDtls = createSelector(
  (state: StoreState) => state.leave.leaveRecordDtls,
  leaveRecordDtlsState =>
    memoize((id: string): LeaveRecordDtlState[] =>
      (Object.values(leaveRecordDtlsState[id]?.entities || {}) as LeaveRecordDtlState[]).sort((a, b) => {
        const aLeaveDate = new Date(a!.leaveDate).getTime()
        const bLeaveDate = new Date(b!.leaveDate).getTime()
        return aLeaveDate - bLeaveDate // ascending
      })
    )
)
