import React, { FC, useCallback, useEffect, useState } from 'react'
import { Form, SecondaryLink, Space, Tooltip } from '~/core-components'
import { ClaKeyValues, Col, EditableCard, EditableCardState, Row, SysOptions } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { dispatch } from '~/stores/store'
import { Errors, ActionResult } from '~/types/store'
import {
  ClaCurrencyCode,
  ClaEarnedBasis,
  ClaEntitlementType,
  ClaProrationType,
  ClaSegmentType,
  Delimiter,
  Permission,
  PermissionAction
} from '~/constants'
import { useFocus } from '~/hooks/use-focus'
import { ClaimTypeEarnState, ClaimTypeState, CtProrationMState } from '../../../types'
import { updateClaimType } from '../../../actions'
import { MutateCtProrationMDrawer } from './MutateCtProrationMDrawer'
import { CurrencyKeyValues } from '~/components/KeyValues/CurrencyKeyValues'
import { KeyValue } from '~/types/common'

interface ClaimTypeEarnProps {
  claimType?: ClaimTypeState
  onEdit?: () => void
  onSave?: () => void
  onCancel?: () => void
}

interface CtProrationDrawerState {
  visible: boolean
  data?: CtProrationMState[]
  claimType?: ClaimTypeState
}

interface FormData extends ClaimTypeEarnState {}

const EMPTY_FORM_DATA: FormData = {
  entitlementType: '',
  currencyCode: ClaCurrencyCode.sgd,
  entitlementAmount: 0,
  earnedBasis: '',
  prorationType: '',
  roundingId: '',
  segmentType: '',
  segmentBy: ''
}

const DEFAULT_MODAL_STATE: CtProrationDrawerState = { visible: false }

export const ClaimTypeEarn: FC<ClaimTypeEarnProps> = ({ claimType, onEdit, onSave, onCancel }) => {
  const [cardState, setCardState] = useState<EditableCardState>()
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const [drawerState, setDrawerState] = useState<CtProrationDrawerState>(DEFAULT_MODAL_STATE)
  const canModify = usePermissionGate(Permission.claType, PermissionAction.Modify)
  const readOnly = cardState !== 'editing' && cardState !== 'saving'
  const [focusRef] = useFocus(!readOnly)

  useEffect(() => {
    if (claimType) {
      const {
        entitlementType,
        currencyCode,
        entitlementAmount,
        earnedBasis,
        prorationType,
        roundingId,
        segmentType,
        segmentBy
      } = claimType
      setFormData({
        entitlementType,
        currencyCode,
        entitlementAmount,
        earnedBasis,
        prorationType,
        roundingId,
        segmentType,
        segmentBy
      })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [claimType])

  const handleEdit = useCallback(() => {
    setCardState('editing')
    typeof onEdit === 'function' && onEdit()
  }, [onEdit])

  const handleSave = useCallback(async () => {
    if (claimType) {
      setCardState('saving')
      setErrors(undefined)

      typeof onSave === 'function' && onSave()

      let result: ActionResult | undefined
      try {
        result = await dispatch(updateClaimType(claimType.id, claimType, formData))
      } catch {
        setCardState('editing')
      }

      if (result?.errors) {
        setCardState('editing')
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setCardState(undefined)
      }
    }
  }, [claimType, formData, onSave])

  const handleCancel = useCallback(() => {
    typeof onCancel === 'function' && onCancel()
    setCardState(undefined)
    setErrors(undefined)

    if (claimType) {
      const {
        entitlementType,
        currencyCode,
        entitlementAmount,
        earnedBasis,
        prorationType,
        roundingId,
        segmentType,
        segmentBy
      } = claimType
      setFormData({
        entitlementType,
        currencyCode,
        entitlementAmount,
        earnedBasis,
        prorationType,
        roundingId,
        segmentType,
        segmentBy
      })
    }
  }, [claimType, onCancel])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    if (updates.entitlementType && updates.entitlementType !== ClaEntitlementType.PerPeriod) {
      updates.entitlementAmount = 0
    }

    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  const handleOpenDrawer = useCallback(() => {
    setDrawerState({ visible: true })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_MODAL_STATE)
  }, [])

  return (
    <EditableCard
      title="Entitlement &amp; accrual setting"
      bodyStyle={{ paddingBottom: claimType ? 6 : 24, paddingTop: 6 }}
      state={!canModify ? 'readonly' : cardState}
      formId="form-claim-type-earn"
      onEdit={handleEdit}
      onSave={handleSave}
      onCancel={handleCancel}
    >
      <Row gutter={30}>
        <Col span={12}>
          <Form.Item
            label="Entitlement calculation"
            validateStatus={errors?.entitlementType ? 'error' : ''}
            help={errors?.entitlementType}
            tooltip={{
              title: 'Entitlement calculation type cannot be changed once claim type is created',
              icon: (
                <span>
                  <i className="fal fa-circle-info" />
                </span>
              )
            }}
          >
            <SysOptions
              ref={focusRef}
              type="cla_entitlement_type"
              readOnly={readOnly || !!claimType}
              disabled={!!claimType}
              value={formData.entitlementType}
              onChange={(value: string) => handleFormDataChange({ entitlementType: value })}
              onFilter={(value: KeyValue | undefined) => ClaEntitlementType.PerDiem !== value?.key}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Proration type"
            validateStatus={errors?.prorationType ? 'error' : ''}
            help={errors?.prorationType}
            tooltip={{
              title: 'Define how the claim is prorated for new hires and resignees',
              icon: (
                <span>
                  <i className="fal fa-circle-info" />
                </span>
              )
            }}
          >
            <Space direction="horizontal" className="fill-first">
              <SysOptions
                type="cla_proration_type"
                readOnly={readOnly}
                value={formData.prorationType}
                onChange={(value: string) => handleFormDataChange({ prorationType: value })}
              />

              {canModify && !readOnly && (
                <SecondaryLink
                  hidden={formData?.prorationType !== ClaProrationType.month}
                  disabled={readOnly}
                  onClick={handleOpenDrawer}
                  style={{ color: '#bbb' }}
                >
                  <Tooltip title="Show proration setting">
                    <i className="fal fa-gear" />
                  </Tooltip>
                </SecondaryLink>
              )}
            </Space>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Currency"
            validateStatus={errors?.currencyCode ? 'error' : ''}
            help={errors?.currencyCode}
            tooltip={{
              title: 'Currency cannot be changed once claim type is created',
              icon: (
                <span>
                  <i className="fal fa-circle-info" />
                </span>
              )
            }}
          >
            <CurrencyKeyValues
              optionLabelProp="title"
              dropdownMatchSelectWidth={false}
              readOnly={readOnly}
              disabled={!!claimType}
              value={formData.currencyCode}
              onChange={(currencyCode: string) => handleFormDataChange({ currencyCode })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30} hidden={formData.entitlementType !== ClaEntitlementType.PerPeriod}>
        <Col span={12}>
          <Form.Item
            label="Accrual calculation"
            validateStatus={errors?.earnedBasis ? 'error' : ''}
            help={errors?.earnedBasis}
          >
            <SysOptions
              type="cla_earned_basis"
              readOnly={readOnly}
              value={formData.earnedBasis}
              onChange={(value: string) => handleFormDataChange({ earnedBasis: value })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col
          span={12}
          hidden={
            formData.earnedBasis === ClaEarnedBasis.noEntitlement ||
            formData.entitlementType !== ClaEntitlementType.PerPeriod
          }
        >
          <Form.Item
            label="Prorated entitlement is rounded to"
            validateStatus={errors?.roundingId ? 'error' : ''}
            help={errors?.roundingId}
          >
            <ClaKeyValues
              id="claRounding"
              controller="rounding"
              value={formData.roundingId}
              readOnly={readOnly}
              onChange={(roundingId: string) => handleFormDataChange({ roundingId })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row
        gutter={30}
        hidden={
          formData.earnedBasis === ClaEarnedBasis.noEntitlement ||
          formData.entitlementType !== ClaEntitlementType.PerPeriod
        }
      >
        <Col span={12}>
          <Form.Item
            label="Prorate when there is change in employment details"
            validateStatus={errors?.segmentType ? 'error' : ''}
            help={errors?.segmentType}
          >
            <SysOptions
              type="cla_segment_type"
              value={formData.segmentType}
              readOnly={readOnly}
              onChange={(segmentType: string) => handleFormDataChange({ segmentType })}
            />
          </Form.Item>
        </Col>
        <Col span={12} hidden={formData.segmentType !== ClaSegmentType.specific}>
          <Form.Item
            label="Select proration fields"
            validateStatus={errors?.segmentBy ? 'error' : ''}
            help={errors?.segmentBy}
          >
            <SysOptions
              type="cla_segment_by"
              value={formData.segmentBy ? formData.segmentBy.split(Delimiter.pipe) : undefined}
              mode="multiple"
              readOnly={readOnly}
              onChange={(segmentBy: string[]) => handleFormDataChange({ segmentBy: segmentBy.join(Delimiter.pipe) })}
            />
          </Form.Item>
        </Col>
      </Row>
      {canModify && <MutateCtProrationMDrawer {...drawerState} claimType={claimType} onClose={handleCloseDrawer} />}
    </EditableCard>
  )
}
