import { replaceMyLeaveCalendarEvents, setMyLeaveCalendarEventsLoading } from '../../reducers'
import { apiGetMyLeaveCalendarEvents } from '../../api/ssleave-record.api'
import { ThunkResult } from '~/types/store'
import { DateRange } from '~/types/common'

export const fetchMyLeaveCalendarEvents = (dateRange: DateRange): ThunkResult<void> => {
  return async dispatch => {
    if (!dateRange.startDate || !dateRange.endDate) return

    try {
      dispatch(setMyLeaveCalendarEventsLoading(true))
      const { result, status } = await apiGetMyLeaveCalendarEvents(dateRange)
      if (status) dispatch(replaceMyLeaveCalendarEvents(result))
    } finally {
      dispatch(setMyLeaveCalendarEventsLoading(false))
    }
  }
}
