import { request, getBaseUrl, timeout } from '~/utils'
import { ILeaveGrantBulk, ILeaveGrantBulkVerify, LeaveGrantBulkState, LeaveGrantBulkVerifyState } from '../types'

const baseUrl = getBaseUrl('/leave/leavegrantbulk')

export const apiPrepareLeaveGrantBulk = (leaveGrantBulk: ILeaveGrantBulk) => {
  return request<LeaveGrantBulkState>('post', `${baseUrl}/prepare`, leaveGrantBulk)
}

export const apiVerifyLeaveGrantBulk = (leaveGrantBulkVerify: ILeaveGrantBulkVerify) =>
  request<LeaveGrantBulkVerifyState>('post', `${baseUrl}/verify`, leaveGrantBulkVerify)

export const apiDownloadLeaveGrantBulkExcel = () =>
  request('get', `${baseUrl}/downloadexcel`, undefined, { responseType: 'blob', timeout })

export const apiExecuteLeaveGrantBulk = () => request<void>('post', `${baseUrl}/execute`)
