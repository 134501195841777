import { replacePayLeaveMaps, setPayLeaveMapsLoading } from '../reducers'
import { apiGetPayLeaveMaps } from '../api/pay-leave-map.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchPayLeaveMaps = (
  payGroupId: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().master.payLeaveMapsLoading[payGroupId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().master.payLeaveMaps[payGroupId]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setPayLeaveMapsLoading({ id: payGroupId, loading: true }))
      const { result, status } = await apiGetPayLeaveMaps(payGroupId)
      if (status) {
        dispatch(replacePayLeaveMaps({ id: payGroupId, data: result }))
      }
    } finally {
      dispatch(setPayLeaveMapsLoading({ id: payGroupId, loading: false }))
    }
  }
}
