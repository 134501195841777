import React, { FC, useState, useCallback, useEffect, ChangeEvent } from 'react'
import moment from 'moment-timezone'
import confirm from 'antd/lib/modal/confirm'
import { Form, Input } from '~/core-components'
import { Col, DrawerForm, Row } from '~/components'
import { dispatch } from '~/stores/store'
import { addHolidayDate, deleteHolidayDate, updateHolidayDate } from '../../../actions'
import { IHolidayDateInfo, HolidayDateState } from '../../../types'
import { mapHolidayDateStateToHolidayDateInfo } from '../../../types/master.mapper'
import { ActionResult, Errors } from '~/types/store'
import { useFocus } from '~/hooks/use-focus'

export interface MutateHolidayDateDrawerProps {
  visible: boolean
  holidayId?: string
  data?: HolidayDateState
  onClose: () => void
}

const EMPTY_FORM_DATA: IHolidayDateInfo = {
  holidayId: '',
  date: '',
  name: ''
}

export const MutateHolidayDateDrawer: FC<MutateHolidayDateDrawerProps> = ({
  visible,
  data,
  onClose
}: MutateHolidayDateDrawerProps) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<IHolidayDateInfo>(EMPTY_FORM_DATA)
  const [focusRef, setFocus] = useFocus(true)
  const [errors, setErrors] = useState<Errors>()

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  useEffect(() => {
    if (data) {
      setFormData({ holidayId: data.holidayId, date: data.date, name: data.name })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => setFormData(formData => ({ ...formData, ...updates })),
    []
  )

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      if (data?.id) {
        result = await dispatch(
          updateHolidayDate(data.holidayId, data.id, mapHolidayDateStateToHolidayDateInfo(data), formData)
        )
      } else {
        result = await dispatch(addHolidayDate(formData))
      }
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data, formData, onClose])

  const handleDelete = useCallback(
    (data: HolidayDateState | undefined) => {
      if (data) {
        const { id, holidayId, name } = data
        confirm({
          title: `Delete holiday`,
          content: `Do you want to delete holiday record of "${name}"?`,
          onOk: async () => {
            const result: ActionResult | undefined = await dispatch(deleteHolidayDate(holidayId || '', id))
            if (result?.errors) {
              setErrors(result.errors)
            }

            if (!result?.errors) {
              typeof onClose === 'function' && onClose()
            }
          },
          okText: 'Delete',
          okType: 'danger'
        })
      }
    },
    [onClose]
  )

  return (
    <DrawerForm
      open={visible}
      title={data?.id ? `Edit holiday` : `Add holiday`}
      onClose={onClose}
      confirmLoading={loading}
      width={500}
      showDelete={data ? true : false}
      onDelete={() => handleDelete(data)}
      formId="form-holiday"
    >
      <Form id="form-holiday" onFinish={handleOk}>
        <Row>
          <Col span={24}>
            <Form.Item label="Date" validateStatus={errors?.date ? 'error' : ''} help={errors?.date}>
              <Input.Date
                ref={focusRef}
                value={formData.date ? moment(formData.date) : undefined}
                onChange={(value: moment.Moment | null) => handleFormDataChange({ date: value?.format('YYYY-MM-DD') })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Name" validateStatus={errors?.name ? 'error' : ''} help={errors?.name}>
              <Input
                value={formData.name}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ name: event.target.value })}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}
