import React, { FC } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from '~/core-components'
import { useEmPublic } from '../../hooks'
import { EmPublicProfile } from '../EmPublicProfile/EmPublicProfile'
import { EmProfile } from './components/EmProfile'
import './EmPublic.less'

interface EmPublicProps {
  id: string
}

export const EmPublic: FC<EmPublicProps> = ({ id }) => {
  const [employee, loading] = useEmPublic(id)

  if (loading) return <Spin indicator={<LoadingOutlined spin />} />

  if (!employee) return null

  return (
    <div className="em-public">
      <EmPublicProfile id={id} />
      <EmProfile data={employee} />
    </div>
  )
}
