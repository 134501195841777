import React, { CSSProperties, FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Table, ColumnsType, SecondaryText, Tooltip } from '~/core-components'
import { fetchEmpKeyvalues, selectKeyvaluesById } from '~/features/master'
import { maskString } from '~/features/employee/util'
import { dispatch } from '~/stores/store'
import { EmFamilyState } from '../../../../../types'
import { selectEmFamilies } from '../../../../../selectors'
import { useSysOptions } from '../../../../../hooks'

interface CopyEmFamiliesProps {
  employeeId?: string
}

type EmFamilyTable = EmFamilyState
const labelRow1Style: CSSProperties = { lineHeight: 1.1 }
const labelRow2Style: CSSProperties = { fontSize: 12 }

export const CopyEmFamilies: FC<CopyEmFamiliesProps> = ({ employeeId }) => {
  const emFamilies = useSelector(selectEmFamilies)(employeeId)
  const nationalities = useSelector(selectKeyvaluesById)('nationality')
  const [identityTypes] = useSysOptions('identity_type')
  const [relationships] = useSysOptions('relationship')
  const [genders] = useSysOptions('gender')
  const [yesNo] = useSysOptions('yes_no')

  useEffect(() => {
    dispatch(fetchEmpKeyvalues('nationality', undefined, undefined, { strategy: 'when-empty' }))
  }, [])

  const columns: ColumnsType<EmFamilyTable> = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      render: (value: string, record) => (
        <>
          <div style={labelRow1Style}>{value}</div>
          <SecondaryText style={labelRow2Style}>
            {record.relation && relationships[record.relation]?.value}{' '}
            {record.gender && `(${genders[record.gender]?.value})`}
          </SecondaryText>
        </>
      )
    },
    {
      title: 'Date of birth',
      key: 'birthDate',
      dataIndex: 'birthDate',
      render: (value: string) => value && moment(value).format('DD MMM YYYY')
    },
    {
      title: 'Nationality',
      key: 'nationalityId',
      dataIndex: 'nationalityId',
      render: (value: string) => value && nationalities[value]?.value
    },
    {
      title: 'Phone',
      key: 'phone',
      dataIndex: 'phone'
    },
    {
      title: 'Dependant',
      key: 'dependant',
      dataIndex: 'dependant',
      render: (value: boolean) => yesNo[value?.toString()]?.value
    },
    {
      title: 'Identification',
      key: 'identityNo',
      dataIndex: 'identityNo',
      render: (value: string, record) => (
        <>
          <div style={labelRow1Style}>
            <Tooltip title={value}>{maskString(value)}</Tooltip>
          </div>
          <SecondaryText style={labelRow2Style}>
            {record.identityType && identityTypes[record.identityType]?.value}
          </SecondaryText>
        </>
      )
    }
  ]

  return <Table rowKey="id" dataSource={emFamilies} pagination={false} columns={columns} className="copy-em__content" />
}
