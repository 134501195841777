import { Radio as InternalRadio } from './Radio'
import { RadioGroup } from './RadioGroup'
import { RadioButton } from './RadioButton'
import { RadioChangeEvent as AntRadioChangeEvent } from 'antd/lib/radio'

type TRadio = typeof InternalRadio
interface IRadio extends TRadio {
  Group: typeof RadioGroup
  Button: typeof RadioButton
}

const Radio = InternalRadio as IRadio
Radio.Group = RadioGroup
Radio.Button = RadioButton
export { Radio }

export interface RadioChangeEvent extends AntRadioChangeEvent {}
