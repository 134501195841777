import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Button, Card, Form, Input, Space, Spin } from '~/core-components'
import { Col, DocumentTitle, Row } from '~/components'
import { LoadingOutlined } from '@ant-design/icons'
import { MY_ROUTES } from '~/routes/routes'
import { useFocus } from '~/hooks/use-focus'
import { dispatch } from '~/stores/store'
import { Errors, StoreState } from '~/types/store'
import { useMyClaimTask } from '../../hooks'
import { addClaimRecordComment, approveClaim, rejectClaim } from '../../actions'
import { MyClaimTaskForm } from '../MyClaimTasks/components/MyClaimTaskForm'
import { SSClaimRecordComments } from '../SSClaimRecordComments/SSClaimRecordComments'
import './MyClaimTask.less'

interface MyClaimTaskProps {}

type ActionType = 'approve' | 'reject' | 'comment'

export const MyClaimTask: FC<MyClaimTaskProps> = () => {
  const history = useHistory()
  const match = useRouteMatch<{ id: string }>()
  const id = match.params.id
  const [task, fetching] = useMyClaimTask(id)
  const commentCount = useSelector((state: StoreState) => state.myClaim.myClaimRecordComments[id || '']?.ids.length)
  const [loading, setLoading] = useState<ActionType>()
  const [comment, setComment] = useState('')
  const [errors, setErrors] = useState<Errors>()
  const [focusRef, setFocus] = useFocus(true)

  useEffect(() => {
    setTimeout(() => setFocus(), 100)
    setErrors(undefined)
  }, [setFocus])

  const handleApprove = useCallback(async () => {
    try {
      setLoading('approve')
      const result = await dispatch(approveClaim(id, comment))
      if (result?.errors) {
        setErrors(result.errors)
      }

      if (!result?.errors) {
        history.push(MY_ROUTES.home)
      }
    } finally {
      setLoading(undefined)
    }
  }, [id, comment, history])

  const handleReject = useCallback(async () => {
    try {
      setLoading('reject')
      const result = await dispatch(rejectClaim(id, comment))
      if (result?.errors) {
        setErrors(result.errors)
      }

      if (!result?.errors) {
        history.push(MY_ROUTES.home)
      }
    } finally {
      setLoading(undefined)
    }
  }, [id, comment, history])

  const handleComment = useCallback(async () => {
    try {
      setLoading('comment')
      const result = await dispatch(addClaimRecordComment(id, comment))
      if (result?.errors) {
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setComment('')
      }
    } finally {
      setLoading(undefined)
    }
  }, [id, comment])

  return (
    <div className="my-claim-task">
      <DocumentTitle title="Claim Approval" />
      {fetching ? (
        <Spin indicator={<LoadingOutlined spin />} />
      ) : (
        <Card style={commentCount > 0 ? { width: 'calc(100vw - 300px)' } : { width: 'auto' }}>
          <div className="my-claim-task__content">
            <Row className="my-claim-task__body" gutter={50}>
              <Col span={commentCount > 0 ? 12 : 24}>
                <MyClaimTaskForm data={task} loading={fetching} />
              </Col>
              <Col span={12} hidden={commentCount === 0}>
                <SSClaimRecordComments id={id} showHeader={true} />
              </Col>
            </Row>
            {(task || fetching) && (
              <Form className="my-claim-task__footer">
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Enter your comment"
                      validateStatus={errors?.comment ? 'error' : ''}
                      help={errors?.comment}
                    >
                      <Input.TextArea
                        ref={focusRef}
                        rows={2}
                        value={comment}
                        onChange={(event: ChangeEvent<HTMLTextAreaElement>) => setComment(event.target.value)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify="center" className="button-group">
                  <Col>
                    <Space>
                      <Button
                        type="primary"
                        onClick={handleApprove}
                        loading={loading === 'approve'}
                        disabled={loading && loading !== 'approve'}
                      >
                        Approve
                      </Button>
                      <Button
                        onClick={handleReject}
                        loading={loading === 'reject'}
                        disabled={loading && loading !== 'reject'}
                      >
                        Reject
                      </Button>
                      <Button
                        onClick={handleComment}
                        loading={loading === 'comment'}
                        disabled={loading && loading !== 'comment'}
                      >
                        Send comment
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Form>
            )}
          </div>
        </Card>
      )}
    </div>
  )
}
