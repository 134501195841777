import { request, getBaseUrl } from '~/utils/request'
import { IPayItemBasis } from '../types'

const baseUrl = getBaseUrl('/payroll-sg/payitem')

export const apiGetPayItemBases = (payItemId: string) =>
  request<IPayItemBasis[]>('get', `${baseUrl}/${payItemId}/basis`)

export const apiUpdatePayItemBases = (payItemId: string, payItemBasisId: string[]) =>
  request<IPayItemBasis[]>('post', `${baseUrl}/${payItemId}/basis`, payItemBasisId)
