import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { CtExpenseTypeState, ExpenseTypeState } from '../types'

export const selectExpenseTypesByClaim = createSelector(
  (state: StoreState) => state.claim.ctExpenseTypes,
  (state: StoreState) => state.claim.expenseTypes,
  (ctExpenseTypesState, expenseTypesState) =>
    memoize((claimTypeId: string) => {
      if (expenseTypesState.ids.length === 0) return []

      const expenseTypeIds = (
        Object.values(ctExpenseTypesState[claimTypeId]?.entities || {}) as CtExpenseTypeState[]
      ).map(o => o.expenseTypeId)

      return expenseTypeIds.map(etId => {
        return expenseTypesState.entities[etId] as ExpenseTypeState
      })
    })
)
