import { request, getBaseUrl } from '~/utils/request'
import { DelegationState, IDelegation } from '../types'

const baseUrl = getBaseUrl('/employee/delegation')

export const apiGetDelegations = () => request<DelegationState[]>('get', baseUrl)

export const apiGetDelegation = (id: string) => request<DelegationState>('get', `${baseUrl}/${id}`)

export const apiDeleteDelegation = (id: string) => request<void>('delete', `${baseUrl}/${id}`)

export const apiSaveDelegation = (delegation: IDelegation) =>
  request<{ id: string } | undefined>('post', baseUrl, delegation)
