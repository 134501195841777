import { replacePermittedEmployeeLve } from '../reducers'
import { apiGetPermittedEmployeeLve } from '../api/employee.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchPermittedEmployeeLve = (options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (options.strategy === 'when-empty') {
      const data = getState().employee.permittedEmployeeLve
      if (data && data.length > 0) {
        return
      }
    }

    const { result, status } = await apiGetPermittedEmployeeLve()
    if (status) {
      dispatch(replacePermittedEmployeeLve(result))
    }
  }
}
