import { showSuccess, showError } from '~/utils'
import { Country } from '~/types/common'
import { ActionResult, ThunkResult } from '~/types/store'
import { apiAddPayItem } from '../api/payitem.api'
import { setPayItem, setKeyvalue } from '../reducers'
import { IPayItemInfo } from '../types'
import { mapPayItemToPayItemState } from '../types/payitem.mapper'

export const addPayItem = (payItemSg: IPayItemInfo, country: Country): ThunkResult<void> => {
  return async (dispatch): Promise<ActionResult> => {
    const { status, result, errors, message, errorData } = await apiAddPayItem(payItemSg)
    if (status) {
      const newPayItem = mapPayItemToPayItemState({ id: result.id, ...payItemSg }, country)
      dispatch(setPayItem(newPayItem))
      dispatch(setKeyvalue({ kv: 'payItem', data: { key: result.id, value: payItemSg.name } }))

      showSuccess('Added')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
