import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { EmDepartmentState, IEmDepartment } from '../types'

const baseUrl = getBaseUrl('/employee/employee')

export const apiGetEmDepartments = (employeeId: string) =>
  request<EmDepartmentState[]>('get', `${baseUrl}/${employeeId}/department`)

export const apiGetEmDepartment = (employeeId: string, emDepartmentId: string) =>
  request<EmDepartmentState>('get', `${baseUrl}/${employeeId}/department/${emDepartmentId}`)

export const apiAddEmDepartment = (employeeId: string, emDepartment: IEmDepartment) =>
  request<{ id: string; emDepartmentId?: string }>('post', `${baseUrl}/${employeeId}/department`, emDepartment)

export const apiUpdateEmDepartment = (employeeId: string, id: string, emDepartment: Operation[]) =>
  request<{ emDepartmentId?: string }>('patch', `${baseUrl}/${employeeId}/department/${id}`, emDepartment)

export const apiDeleteEmDepartment = (employeeId: string, id: string) =>
  request<{ emDepartmentId?: string }>('delete', `${baseUrl}/${employeeId}/department/${id}`)
