import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { ClaimRecordViewState } from '../types'

export const selectClaimRecordsView = createSelector(
  (state: StoreState) => state.claim.claimRecordsView,
  claimRecordsState =>
    memoize((viewId: string): ClaimRecordViewState => {
      return claimRecordsState.entities[viewId] || ({} as ClaimRecordViewState)
    })
)
