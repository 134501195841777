import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { apiUpdateOffice } from '../api/office.api'
import { OfficeState, IOfficeInfo } from '../types'
import { setOffice, setKeyvalue } from '../reducers'

export const updateOffice = <T extends IOfficeInfo>(id: string, before: T, request: T): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const after = { ...before, ...request } as T
    const patch = generateJsonPatch<T>(before, after)
    const { status, errors, message, errorData } = await apiUpdateOffice(id, patch)

    if (status) {
      const office = getState().master.offices?.entities[id]
      const updated = { ...(office as OfficeState), ...after }
      const { name, inactiveDate } = updated
      dispatch(setOffice(updated))
      dispatch(setKeyvalue({ kv: 'office', data: { key: id, value: name, inactiveDate } }))

      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
