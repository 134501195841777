import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { ReportCode, ReportTemplateState } from '../types'

export const selectFirstOwnedReportTemplate = createSelector(
  (state: StoreState) => state.report.reportTemplates,
  (state: StoreState) => state.iam.myJwt,
  (templatesState, myJwtState) =>
    memoize((reportCode: ReportCode): ReportTemplateState | undefined => {
      const templates = Object.values(templatesState[reportCode]?.entities || {}) as ReportTemplateState[]

      if (templates.length > 0) {
        const owned = templates.filter(v => v.ownerId === myJwtState?.loginId)
        if (owned.length > 0) {
          return owned[0]
        }
      }
    })
)
