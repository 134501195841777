import { replaceLeaveTypesView, setLeaveTypesViewLoading } from '../reducers'
import { apiGetLeaveTypesView } from '../api/leave-type.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchLeaveTypesView = (
  viewId: string,
  search: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().leave.leaveTypesViewLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().leave.leaveTypesView?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setLeaveTypesViewLoading(true))
      const { result, status } = await apiGetLeaveTypesView(viewId, { search })
      if (status) {
        dispatch(replaceLeaveTypesView(result))
      }
    } finally {
      dispatch(setLeaveTypesViewLoading(false))
    }
  }
}
