import { replacePayTranEntry, setPayTranEntryLoading } from '../reducers'
import { apiGetPayTranEntryAdjustment } from '../api/paytran-entry.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchPayTranEntryAdjustment = (
  batchId: string,
  payRunId: string,
  employeeId: string,
  payItemId: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!batchId || !payRunId || !employeeId || !payItemId) return

    const loading = getState().payroll.payTranEntriesLoading[batchId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().payroll.payTranEntries[batchId]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setPayTranEntryLoading({ batchId, loading: true }))
      const { result, status } = await apiGetPayTranEntryAdjustment(payRunId, employeeId, payItemId)
      if (status) {
        dispatch(replacePayTranEntry({ batchId, data: [result] }))
      }
    } finally {
      dispatch(setPayTranEntryLoading({ batchId, loading: false }))
    }
  }
}
