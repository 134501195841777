import { request, getBaseUrl, timeout } from '~/utils'
import { Pagination, Search } from '~/types/common'
import { ClaimRecordAttachmentState, ClaimRecordState, ClaimRecordViewState } from '../types'

const baseUrl = getBaseUrl('/claim/claimrecord')

export const apiGetClaimRecordsView = (viewId: string, periodYear: number, params: Pagination & Search) =>
  request<ClaimRecordViewState>('get', `${baseUrl}/view/${viewId}/${periodYear}`, params)

export const apiGetClaimRecord = (id: string) => request<ClaimRecordState>('get', `${baseUrl}/${id}`)

export const apiGetClaimRecordAttachments = (id: string) =>
  request<ClaimRecordAttachmentState[]>('get', `${baseUrl}/${id}/attachment`)

export const apiDeleteClaimRecord = (id: string) => request<void>('delete', `${baseUrl}/${id}`)

export const apiGetClaimRecordExcel = (
  viewId: string,
  periodYear: number,
  params: Search & { employeeId: string | null }
) => request('get', `${baseUrl}/view/${viewId}/${periodYear}/excel`, params, { responseType: 'blob', timeout })

export const apiGetClaimEntitlementRecords = (
  periodYear: number,
  claimTypeId: string,
  employeeId: string,
  paymentStatuses: string[]
) => request<ClaimRecordState[]>('get', `${baseUrl}/${periodYear}/${claimTypeId}/${employeeId}`, { paymentStatuses })

export const apiDownloadAllClaimAttachment = (id: string) =>
  request('get', `${baseUrl}/${id}/attachment/downloadall`, undefined, { responseType: 'blob', timeout })
