import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchClaimEntitlements } from '../actions'
import { selectClaimEntitlements } from '../selectors'
import { ClaimEntitlementState } from '../types'

export const useClaimEntitlements = (periodYear: number, employeeId: string): [ClaimEntitlementState[], boolean] => {
  const emYear = `${periodYear}|${employeeId}`
  const data = useSelector(selectClaimEntitlements)(periodYear, employeeId)
  const loading = useSelector((state: StoreState) => state.claim.claimEntitlementsLoading[emYear])

  useEffect(() => {
    dispatch(fetchClaimEntitlements(periodYear, employeeId))
  }, [periodYear, employeeId])

  return [data, loading]
}
