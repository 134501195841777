import React, { FC, useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Link as ClickableLink, SecondaryLink, SecondaryText, Space, SpaceProps, Tooltip } from '~/core-components'
import { DEFAULT_COLOR } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { Permission } from '~/constants'
import { useLeaveTypesDict } from '~/features/leave/hooks'
import './LeaveTypeName.less'

interface LeaveTypeNameProps extends SpaceProps {
  id?: string
  description?: string
  path?: string | (() => void)
  hideNewTab?: boolean
}

export const LeaveTypeName: FC<LeaveTypeNameProps> = ({ id, description, path, hideNewTab, children, ...props }) => {
  const [leaveTypes] = useLeaveTypesDict()
  const leaveType = leaveTypes[id || '']
  const canView = usePermissionGate(Permission.lveType)
  const newTabPath = useMemo(() => (id ? SETTINGS_ROUTES.leaveType.replace(':id', id) : ''), [id])

  const handleNewTabClick = useCallback(() => {
    window.open(newTabPath, '_blank')
  }, [newTabPath])

  if (!id) return null

  return (
    <Space {...props} className="leave-type-name">
      <div className="leave-type-name__name">
        <div>
          <Space align="start">
            <div className="leave-type-name__color" style={{ backgroundColor: leaveType?.color || DEFAULT_COLOR }} />
            {path ? (
              typeof path === 'string' ? (
                <Link to={path} rel="noopener noreferrer">
                  {leaveType?.name || 'On leave'}
                </Link>
              ) : (
                <ClickableLink onClick={path}>{leaveType?.name}</ClickableLink>
              )
            ) : (
              leaveType?.name || 'On leave'
            )}
            {canView && newTabPath !== path && !hideNewTab && (
              <Tooltip title="Open leave type in a new tab">
                <SecondaryLink className="leave-type-name__new-tab-link" onClick={handleNewTabClick} tabIndex={-1}>
                  <i className="fal fa-arrow-up-right-from-square" />
                </SecondaryLink>
              </Tooltip>
            )}
          </Space>
        </div>
        {description && <SecondaryText size="small">{description}</SecondaryText>}
        {children}
      </div>
    </Space>
  )
}

interface LeaveTypeNameComponentProps extends SpaceProps {
  color?: string
  name?: string
}

export const LeaveTypeNameComponent: FC<LeaveTypeNameComponentProps> = ({ name, color, children, ...props }) => (
  <Space {...props} className="leave-type-name">
    <div className="leave-type-name__color" style={{ backgroundColor: color || DEFAULT_COLOR }}></div>
    <div className="leave-type-name__name">{name}</div>
    {children}
  </Space>
)
