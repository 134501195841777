import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { DEFAULT_HOOK_OPTIONS, HookOptions } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchEntitledClaimTypes } from '../actions'
import { selectEntitledClaimTypes } from '../selectors'
import { EntitledClaimTypeState } from '../types'

export const useEntitledClaimTypes = (
  employeeId: string,
  options: HookOptions = DEFAULT_HOOK_OPTIONS
): [EntitledClaimTypeState[], boolean] => {
  const entitledClaimTypes = useSelector(selectEntitledClaimTypes)(employeeId)
  const loading = useSelector((state: StoreState) => state.claim.entitledClaimTypesLoading[employeeId])

  useEffect(() => {
    if (options.enabled && employeeId) {
      dispatch(fetchEntitledClaimTypes(employeeId))
    }
  }, [employeeId, options.enabled])

  return [entitledClaimTypes, loading]
}
