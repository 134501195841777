import { setYtdSubmissionView, setYtdSubmissionViewsLoading } from '../reducers'
import { apiGetYtdSubmissionView } from '../api/ytd-submission.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions, Pagination } from '~/types/common'

export const fetchYtdSubmissionView = (
  viewId: string,
  pagination: Pagination,
  search: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!viewId) return

    const loading = getState().tax.ytdSubmissionViewsLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().tax.ytdSubmissionViews?.entities[viewId]
      if (hasData) return
    }

    try {
      dispatch(setYtdSubmissionViewsLoading(true))
      const { result, status } = await apiGetYtdSubmissionView(viewId, { ...pagination, search })
      if (status) {
        dispatch(setYtdSubmissionView(result))
      }
    } finally {
      dispatch(setYtdSubmissionViewsLoading(false))
    }
  }
}
