import React, { useEffect } from 'react'
import AceEditor, { IAceEditorProps } from 'react-ace'
import 'ace-builds/src-noconflict/mode-sql'
import 'ace-builds/src-noconflict/theme-github'
import 'ace-builds/src-noconflict/ext-language_tools'
import ace from 'ace-builds/src-noconflict/ace'
import './SqlEditor.less'

export interface SuggestionType {
  value: string
  meta: string
}

interface SqlEditorProps extends IAceEditorProps {
  suggestions?: SuggestionType[]
}

export const SqlEditor: React.FC<SqlEditorProps> = ({ suggestions = [], setOptions, ...props }) => {
  useEffect(() => {
    // Custom completer
    const customCompleter = {
      getCompletions: (
        editor: any,
        session: any,
        pos: any,
        prefix: string,
        callback: (error: any, completions: any[]) => void
      ) => {
        callback(
          null,
          suggestions.map(x => ({
            caption: x.value,
            value: x.value,
            meta: x.meta
          }))
        )
      }
    }

    // Register the custom completer
    ace.require('ace/ext/language_tools').addCompleter(customCompleter)
  }, [suggestions])

  return (
    <AceEditor
      mode="sql"
      theme="textmate"
      name="sql_editor"
      showPrintMargin={false}
      showGutter={false}
      highlightActiveLine={false}
      scrollMargin={[9, 0, 9, 0]}
      lineHeight={14}
      editorProps={{ $blockScrolling: true }}
      setOptions={{
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        enableSnippets: true,
        showLineNumbers: false,
        ...setOptions
      }}
      width="100%"
      height="200px"
      className="sql-editor"
      {...props}
    />
  )
}
