import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchMyLeaveRecordApprovalHistories } from '../../actions'
import { selectMyLeaveRecordApprovalHistories } from '../../selectors'
import { SSLeaveRecordApprovalHistoryState } from '../../types'

export const useMyLeaveRecordApprovalHistories = (
  leaveRecordId?: string
): [SSLeaveRecordApprovalHistoryState[], boolean] => {
  const leaveRecordApprovalHistories = useSelector(selectMyLeaveRecordApprovalHistories)(
    leaveRecordId || ''
  ) as SSLeaveRecordApprovalHistoryState[]
  const loading = useSelector(
    (state: StoreState) => state.my.myLeaveRecordApprovalHistoriesLoading[leaveRecordId || '']
  )

  useEffect(() => {
    if (leaveRecordId) {
      dispatch(fetchMyLeaveRecordApprovalHistories(leaveRecordId))
    }
  }, [leaveRecordId])

  return [leaveRecordApprovalHistories, loading]
}
