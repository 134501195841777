import React, { FC, useCallback, useState } from 'react'
import { Badge, Button, Drawer, Space, Tabs } from '~/core-components'
import { MyLeaveTaskForm } from './MyLeaveTaskForm'
import { MyLeaveTaskActionModal } from './MyLeaveTaskActionModal'
import { MyLeaveTaskComments } from './MyLeaveTaskComments'
import { useMyLeaveRecordComments } from '~/features/my/hooks'
import './MyLeaveTaskDrawer.less'

interface MyLeaveTaskDrawerProps {
  id: string
  visible: boolean
  onClose: (success?: boolean) => void
}

interface ActionModalState {
  id: string
  visible: boolean
  action: 'approve' | 'reject'
}

const DEFAULT_ACTION_MODAL_STATE: ActionModalState = { id: '', visible: false, action: 'approve' }

type TabChoice = 'request' | 'comment'

export const MyLeaveTaskDrawer: FC<MyLeaveTaskDrawerProps> = ({ id, visible, onClose }) => {
  const [actionModalState, setActionModalState] = useState<ActionModalState>(DEFAULT_ACTION_MODAL_STATE)
  const [comments] = useMyLeaveRecordComments(id)
  const [activeTab, setActiveTab] = useState<TabChoice>('request')

  const handleTabChange = useCallback((activeKey: string) => setActiveTab(activeKey as TabChoice), [])

  const handleCloseDrawer = useCallback(
    (success?: boolean) => {
      setActiveTab('request')
      typeof onClose === 'function' && onClose(success)
    },
    [onClose]
  )

  const handleApprove = useCallback(async () => {
    setActionModalState({ id, visible: true, action: 'approve' })
  }, [id])

  const handleReject = useCallback(async () => {
    setActionModalState({ id, visible: true, action: 'reject' })
  }, [id])

  const handleCloseAction = useCallback(
    (success: boolean) => {
      setActionModalState(DEFAULT_ACTION_MODAL_STATE)

      if (success) {
        handleCloseDrawer(true)
      }
    },
    [handleCloseDrawer]
  )

  return (
    <Drawer
      open={visible}
      title="Leave approval"
      onClose={() => handleCloseDrawer()}
      width={500}
      className="my-leave-task-drawer"
      footer={
        <Space className="my-leave-task-drawer__action-bar-buttons" hidden={activeTab === 'comment'}>
          <Button type="primary" onClick={handleApprove}>
            Approve
          </Button>
          <Button onClick={handleReject}>Reject</Button>
        </Space>
      }
    >
      <Tabs
        activeKey={activeTab}
        onChange={handleTabChange}
        {...(id ? {} : { renderTabBar: () => <></> })}
        items={[
          { key: 'request', label: 'Request', children: <MyLeaveTaskForm id={id} /> },
          {
            key: 'comment',
            label: (
              <>
                Comments
                <Badge count={comments.length} type="primary" />
              </>
            ),
            children: <MyLeaveTaskComments id={id} />
          }
        ]}
      />
      <MyLeaveTaskActionModal {...actionModalState} onClose={handleCloseAction} />
    </Drawer>
  )
}
