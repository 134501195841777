import { createSelector } from '@reduxjs/toolkit'
import moment from 'moment-timezone'
import { StoreState } from '~/types/store'

const TODAY = moment().format('YYYY-MM-DD')

const START_WEEK = moment(TODAY).startOf('isoWeek').format('YYYY-MM-DD')
const END_WEEK = moment(TODAY).endOf('isoWeek').add(2, 'day').format('YYYY-MM-DD')

const START_NEXT_WEEK = moment(TODAY).startOf('isoWeek').add(1, 'week').format('YYYY-MM-DD')
const END_NEXT_WEEK = moment(TODAY).endOf('isoWeek').add(1, 'week').add(2, 'd').format('YYYY-MM-DD')

export const selectTodayBirthdays = createSelector(
  (state: StoreState) => state.my.birthdays,
  birthdaysState => {
    return Object.values(birthdaysState?.entities || {}).filter(b => moment(b?.birthday).isSame(moment(TODAY)))
  }
)

export const selectThisWeekBirthdays = createSelector(
  (state: StoreState) => state.my.birthdays,
  birthdaysState => {
    return Object.values(birthdaysState?.entities || {}).filter(
      b =>
        !moment(b?.birthday).isSame(moment(TODAY)) &&
        moment(b?.birthday).isBetween(moment(START_WEEK), moment(END_WEEK))
    )
  }
)

export const selectNextWeekBirthdays = createSelector(
  (state: StoreState) => state.my.birthdays,
  birthdaysState => {
    return Object.values(birthdaysState?.entities || {}).filter(
      b =>
        !moment(b?.birthday).isSame(moment(TODAY)) &&
        moment(b?.birthday).isBetween(moment(START_NEXT_WEEK), moment(END_NEXT_WEEK))
    )
  }
)
