import React, { FC } from 'react'
import classNames from 'classnames'
import { Card, CardProps } from '~/core-components'
import { useSelector } from 'react-redux'
import { StoreState } from '~/types/store'
import { MyLeaveGrantBalanceList } from './MyLeaveGrantBalanceList'
import { selectMyLeaveGrantsBalance } from '~/features/my/selectors'
import './MyLeaveGrantBalance.less'

interface MyLeaveGrantBalanceProps extends CardProps {}

export const MyLeaveGrantBalance: FC<MyLeaveGrantBalanceProps> = ({ className, ...props }) => {
  const classes = classNames('my-leave-grant-balance', { [`${className}`]: className })
  const loading = useSelector((state: StoreState) => state.my.myLeaveBalancesLoading)
  const leaveGrants = useSelector(selectMyLeaveGrantsBalance)

  if (leaveGrants.length === 0) return null

  return (
    <Card title="My expiring leave" loading={loading} className={classes} {...props}>
      <MyLeaveGrantBalanceList />
    </Card>
  )
}
