import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiDeleteAttendancePeriod } from '../api/attendance-period.api'
import { removeAttendancePeriod } from '../reducers'

export const deleteAttendancePeriod = (id: string): ThunkResult<void> => {
  return async dispatch => {
    if (!id) return

    const { status, errors, message, errorData } = await apiDeleteAttendancePeriod(id)
    if (status) {
      dispatch(removeAttendancePeriod(id))
      showSuccess('Deleted')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
