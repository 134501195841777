import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { SysBankFile } from '~/constants'
import { StoreState } from '~/types/store'
import { GroupChildren } from '~/types/common'
import { SysBankFileState } from '../types'

export const selectSysBankFiles = createSelector(
  (state: StoreState) => state.report.sysBankFiles,
  sysBankFilesState =>
    memoize(() => {
      const map = new Map<string, GroupChildren<SysBankFileState>>()
      const sysBankFiles = (Object.values(sysBankFilesState?.entities || {}) as SysBankFileState[])
        .filter(item => item.id !== SysBankFile.DbsIdealInterbank) // ZCR-524 Hide 'DBS IDEAL New Interbank Giro' option in Bank File selection || 05 Apr 2022
        .sort((a, b) => a.name.localeCompare(b.name))

      sysBankFiles.forEach(item => {
        if (item) {
          map.has(item.bankCode) ||
            map.set(item.bankCode, { id: `group-${item.id}`, name: item.bankCode, children: [] })
          map.get(item.bankCode)?.children.push({ ...item })
        }
      })
      const result = [...map.values()]
      return result
    })
)
