import { request, getBaseUrl, timeout } from '~/utils/request'
import { EmployeeImportState } from '../types'
import { ImportEmployeeExcelFormData } from '../containers/Employee/components/ImportEmployeeExcel/ImportEmployeeExcelDrawer'

const baseUrl = getBaseUrl('/employee/employeeimport')

export const apiGetImportEmployeeTemplateExcel = (params: { importCode: string | null }) =>
  request('get', `${baseUrl}/template/excel`, params, { responseType: 'blob', timeout })

export const apiGetErrorReportExcel = (params: { importCode: string | null }) =>
  request('get', `${baseUrl}/errorreport/excel`, params, { responseType: 'blob', timeout })

export const apiUploadEmployeeImport = (data: ImportEmployeeExcelFormData) => {
  let formData = new FormData()
  if (data.file instanceof File) formData.append('file', data.file, data.file.name)
  formData.append('importCode', data.importCode)
  formData.append('isAutoCreateReference', data.isAutoCreateReference.toString())

  data.references.forEach((x: string) => {
    formData.append('references', x)
  })

  return request<EmployeeImportState>('post', `${baseUrl}/upload`, formData)
}

export const apiExecuteEmployeeImport = (importCode: string) =>
  request<void>('post', `${baseUrl}/execute`, { importCode })
