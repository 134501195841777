import storage from 'redux-persist/lib/storage'
import { SlackConfigsState } from './slack.state'

export const notificationPersistConfig = {
  key: 'notification',
  whitelist: ['slackConfigs'],
  storage
}

export interface NotificationRootState {
  slackConfigs?: SlackConfigsState
  slackConfigsLoading: boolean
  slackInstalling: boolean
}
