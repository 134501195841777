import React, { FC, useCallback, useState } from 'react'
import { LinkProps, SecondaryLink, Tooltip } from '~/core-components'
import { ViewTimeLogsDrawer } from './ViewTimeLogsDrawer'
import { useTimeLogsByDaily } from '../../../hooks'

export interface ViewTimeLogsLinkProps extends LinkProps {
  dailyRecordId?: string
}

interface DrawerState {
  visible: boolean
}
const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

export const ViewTimeLogsLink: FC<ViewTimeLogsLinkProps> = ({ dailyRecordId, ...props }) => {
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const [data] = useTimeLogsByDaily(dailyRecordId)

  const handleOpenDrawer = useCallback(() => {
    setDrawerState({ visible: true })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  if (!dailyRecordId) return null
  if (!data || data.length === 0) return null

  return (
    <>
      <Tooltip title="View time logs">
        <SecondaryLink className="view-time-logs-link" onClick={handleOpenDrawer} {...props}>
          <i className="fal fa-clock" />
        </SecondaryLink>
      </Tooltip>
      <ViewTimeLogsDrawer {...drawerState} dailyRecordId={dailyRecordId} onClose={handleCloseDrawer} />
    </>
  )
}
