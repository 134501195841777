import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { EntitledLeaveTypeState } from '../types'

export const selectEntitledLeaveTypes = createSelector(
  (state: StoreState) => state.leave.entitledLeaveTypes,
  entitledLeaveTypesState =>
    memoize((employeeId: string): EntitledLeaveTypeState[] => {
      return Object.values(entitledLeaveTypesState[employeeId]?.entities || {}) as EntitledLeaveTypeState[]
    })
)
