import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { DailyRecordViewState } from '../types'

export const selectDailyRecordsView = createSelector(
  (state: StoreState) => state.attendance.dailyRecordsView,
  dailyRecordsState =>
    memoize((viewId: string): DailyRecordViewState => {
      return (dailyRecordsState?.entities[viewId] || {}) as DailyRecordViewState
    })
)
