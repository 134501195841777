import React, { FC, useCallback, useEffect, useContext } from 'react'
import moment from 'moment-timezone'
import { Table, TableProps, Input, Select, ColumnType, Button } from '~/core-components'
import { SalaryInput } from '~/components'
import { Form } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { NamePath } from 'rc-field-form/lib/interface'

interface OptionProps {
  value: string
  displayValue: string
}

interface EditableColumnType<RecordType> extends ColumnType<RecordType> {
  editable?: boolean
  inputType?: string
  selectOptions?: OptionProps[]
}

interface ColumnGroupType<RecordType> extends EditableColumnType<RecordType> {
  children: EditableColumnsType<RecordType>
}

export type EditableColumnsType<RecordType = any> = (ColumnGroupType<RecordType> | EditableColumnType<RecordType>)[]

interface EditableTableProps<RecordType> extends Omit<TableProps<RecordType>, 'columns'> {
  readOnly?: boolean
  columns: EditableColumnsType<RecordType>
  onAddRow?: () => void
  onCellUpdate?: (row: RecordType) => void
  onRowDelete?: (id: string) => void
}

const EditableContext = React.createContext<FormInstance<any> | null>(null)

interface EditableRowProps {
  index: number
}

interface EditableCellProps<RecordType> {
  title: React.ReactNode
  editable: boolean
  inputType: string
  selectOptions: OptionProps[]
  children: React.ReactNode
  dataIndex: keyof RecordType
  record: RecordType
  handleSave: (record: RecordType) => void
}

const EditableRow: FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm()
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  )
}

const EditableCell = <RecordType extends object = Record<string, any>>({
  title,
  editable,
  inputType,
  children,
  dataIndex,
  record,
  selectOptions,
  handleSave,
  ...restProps
}: EditableCellProps<RecordType>) => {
  const form = useContext(EditableContext)!
  const fieldValue = record ? record[dataIndex] : undefined

  useEffect(() => {
    form.setFieldsValue({
      [dataIndex]: inputType === 'date' ? (fieldValue ? moment(fieldValue as any) : null) : fieldValue
    })
  }, [form, dataIndex, inputType, fieldValue])

  const save = async () => {
    try {
      const values = await form.validateFields()
      const transformed = Object.keys(values).reduce((result: Record<string, any>, key: string) => {
        if (values[key] instanceof moment) {
          result[key] = values[key].format('YYYY-MM-DD')
        } else {
          result[key] = values[key]
        }
        return result
      }, {} as Record<string, any>)

      handleSave({ ...record, ...transformed })
    } catch (errInfo) {
      console.log('Save failed:', errInfo)
    }
  }

  let childNode = children

  if (editable) {
    let inputField = () => {
      switch (inputType) {
        case 'date':
          return (
            <Form.Item style={{ margin: 0 }} name={dataIndex as NamePath}>
              <Input.Date onChange={save} />
            </Form.Item>
          )
        case 'text':
          return (
            <Form.Item style={{ margin: 0 }}>
              <Input onPressEnter={save} onBlur={save} />
            </Form.Item>
          )
        case 'numeric':
          return (
            <Form.Item style={{ margin: 0 }} name={dataIndex as NamePath}>
              <SalaryInput style={{ width: 88 }} onPressEnter={save} onBlur={save} />
            </Form.Item>
          )
        case 'select':
          return (
            <Form.Item style={{ margin: 0 }} name={dataIndex as NamePath}>
              <Select allowClear={false} onChange={save}>
                {selectOptions.map(opt => {
                  return (
                    <Select.Option key={opt.value} value={opt.value}>
                      {opt.displayValue}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>
          )
      }
    }
    childNode = inputField()
  }

  return <td {...restProps}>{childNode}</td>
}

export const EditableTable = <RecordType extends object = any>({
  dataSource,
  onAddRow: onAdd,
  onCellUpdate: onUpdate,
  readOnly,
  ...props
}: EditableTableProps<RecordType>) => {
  const handleAdd = useCallback(() => {
    typeof onAdd === 'function' && onAdd()
  }, [onAdd])

  const handleSave = useCallback(
    (row: RecordType) => {
      typeof onUpdate === 'function' && onUpdate(row)
    },
    [onUpdate]
  )

  type EditableTableType = Parameters<typeof Table>[0]

  type ColumnTypes = Exclude<EditableTableType['columns'], undefined>

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell
    }
  }
  const columns = props.columns?.map(col => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record: RecordType) => ({
        record,
        editable: col.editable && !readOnly,
        inputType: col.inputType,
        dataIndex: col.dataIndex,
        selectOptions: col.selectOptions,
        title: col.title,
        handleSave
      })
    }
  })

  return (
    <div style={{ marginBottom: 20 }}>
      <Table
        rowKey="id"
        components={components}
        bordered
        dataSource={dataSource}
        columns={columns as ColumnTypes}
        pagination={false}
      />
      {!readOnly && (
        <Button onClick={handleAdd} type="dashed" style={{ marginTop: 15 }}>
          add new record
        </Button>
      )}
    </div>
  )
}
