import React, { FC, useMemo, useState, CSSProperties, useCallback } from 'react'
import classNames from 'classnames'
import { LoadingOutlined } from '@ant-design/icons'
import { Link, Modal, RcFile, Spin, Upload, UploadFile, UploadProps } from '../../core-components'
import { request, timeout } from '../../utils/request'
import { ImageAuth } from '../ImageAuth/ImageAuth'
import { Row } from '../Row/Row'
import { Col } from '../Col/Col'
import './UploadFileSelectorAuth.less'

interface UploadFileSelectorAuthProps extends Omit<UploadProps, 'onChange' | 'action'> {
  onChange?: (files?: UploadFile[]) => void
  loading?: boolean
  withCache?: boolean
}

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = error => reject(error)
  })

const previewStyle: CSSProperties = { height: 72, overflow: 'hidden', wordBreak: 'break-all' }

export const UploadFileSelectorAuth: FC<UploadFileSelectorAuthProps> = ({
  fileList,
  className,
  onChange,
  loading,
  withCache = true,
  ...props
}: UploadFileSelectorAuthProps) => {
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')
  const [downloading, setDownloading] = useState<string>()

  const handleFilePreview = useCallback(async (file: UploadFile) => {
    if (file.url && file.type?.startsWith('image/')) {
      setPreviewImage(file.url)
      setPreviewOpen(true)
      setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1))
    } else {
      const { result, status } = await request('get', file.url as string, undefined, {
        responseType: 'blob',
        timeout
      })
      if (status) {
        const objectUrl = URL.createObjectURL(result)
        window.open(objectUrl, '_blank')
        URL.revokeObjectURL(objectUrl)
      }
    }
  }, [])

  const handlePreviewClose = () => setPreviewOpen(false)

  const handleFileDownload = useCallback(async (file: UploadFile) => {
    if (file.url) {
      try {
        setDownloading(file.name)
        const { result, status } = await request('get', file.url, undefined, {
          responseType: 'blob',
          timeout
        })
        if (status) {
          const objectUrl = URL.createObjectURL(result)

          const anchor = document.createElement('a')
          anchor.href = objectUrl
          anchor.download = file.fileName || file.name

          document.body.appendChild(anchor)
          anchor.click()
          document.body.removeChild(anchor)

          URL.revokeObjectURL(objectUrl)
        }
      } finally {
        setDownloading(undefined)
      }
    }
  }, [])

  const getUploadProps = useMemo((): UploadProps => {
    const token = localStorage.getItem('access_token')
    const tenant = localStorage.getItem('tenant') || ''

    return {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-Tenant': tenant
      },
      fileList,
      beforeUpload: async file => {
        let newFiles = []

        const attachment: UploadFile = file
        attachment.url = await getBase64(file as RcFile)

        if (fileList && props.multiple) {
          newFiles = [...fileList, attachment]
        } else {
          newFiles = [attachment]
        }

        typeof onChange === 'function' && onChange(newFiles)
        return false
      },
      iconRender: (file: UploadFile) => {
        return (
          <>
            <i className="fal fa-paperclip" />
            {file.fileName}
          </>
        )
      },
      itemRender: (originNode, file) => {
        if (props.listType === 'picture-card') {
          const name = file.name ?? file.fileName
          return (
            <Row>
              <Col span={24} style={previewStyle}>
                {file.type?.startsWith('image/') ? (
                  <ImageAuth
                    width={72}
                    height={72}
                    backgroundSize="contain"
                    src={file.url}
                    defaultImage={<>{name}</>}
                    withCache={withCache}
                    onClick={() => handleFilePreview(file)}
                  />
                ) : (
                  <Link onClick={() => handleFilePreview(file)}>{name}</Link>
                )}
              </Col>
              <Col flex="auto">
                {downloading === file.name ? (
                  <Spin size="small" indicator={<LoadingOutlined spin />} />
                ) : (
                  <Link size="small" onClick={() => handleFileDownload(file)}>
                    <i className="fal fa-cloud-arrow-down" />
                  </Link>
                )}
              </Col>
              <Col flex="none">
                {!props.disabled && props.onRemove && (
                  <Link size="small" onClick={() => props.onRemove!(file)}>
                    remove
                  </Link>
                )}
              </Col>
            </Row>
          )
        } else {
          return originNode
        }
      },
      showUploadList: {
        removeIcon: <i className="fal fa-trash" />
      },
      ...props
    } as UploadProps
  }, [fileList, onChange, props, handleFilePreview, handleFileDownload, downloading, withCache])

  if (loading) return <Spin indicator={<LoadingOutlined spin />} />

  return (
    <>
      <Upload className={classNames('upload-file-selector-auth', className)} {...getUploadProps} />
      <Modal width={560} open={previewOpen} title={previewTitle} footer={<div />} onCancel={handlePreviewClose}>
        <ImageAuth width={500} height={500} backgroundSize="contain" src={previewImage} withCache={withCache} />
      </Modal>
    </>
  )
}
