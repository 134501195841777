import { replaceSysCriteriaOptions, setSysCriteriaOptionsLoading } from '../reducers'
import { apiGetCriteriaOptions } from '../api/sys-criteria.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'
import { Screen } from '../types'

export const fetchSysCriteriaOptions = (
  screenCode: Screen,
  criteriaOptionCode: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!screenCode || !criteriaOptionCode) return

    const loading = getState().selection.sysCriteriaOptionsLoading[criteriaOptionCode]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().selection.sysCriteriaOptions[criteriaOptionCode]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setSysCriteriaOptionsLoading({ code: criteriaOptionCode, loading: true }))
      const { result, status } = await apiGetCriteriaOptions(screenCode, criteriaOptionCode)
      if (status) {
        dispatch(replaceSysCriteriaOptions({ code: criteriaOptionCode, data: result }))
      }
    } finally {
      dispatch(setSysCriteriaOptionsLoading({ code: criteriaOptionCode, loading: false }))
    }
  }
}
