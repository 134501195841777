import { replacePayAccount, setPayAccountLoading } from '../reducers'
import { apiGetPayAccounts } from '../api/payrecord-sg.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchPayAccounts = (
  recordId: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!recordId) return

    const loading = getState().payroll.payAccountsLoading[recordId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().payroll.payAccounts[recordId]?.ids.length > 0
      if (hasData) return
    }

    try {
      dispatch(setPayAccountLoading({ recordId, loading: true }))
      const { result, status } = await apiGetPayAccounts(recordId)
      if (status) {
        dispatch(replacePayAccount({ recordId, data: result }))
      }
    } finally {
      dispatch(setPayAccountLoading({ recordId, loading: false }))
    }
  }
}
