import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Card, ColumnsType, Form, Link, LinkGroup, SecondaryText, Space, Table, Tag } from '~/core-components'
import { Col, AttKeyValues, InactiveTag, Row } from '~/components'
import { selectKeyvaluesById } from '~/features/master'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { useToggle } from '~/hooks/use-toggle'
import { useFirstInView } from '~/hooks/use-first-in-view'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { isInactive } from '~/utils'
import { MutateEmLocationDrawer } from './MutateEmLocationDrawer'
import { IEmLocationInfo, EmLocationState } from '../../../types'
import { fetchEmLocations } from '../../../actions'
import { selectCurrentEmLocation, selectHistoryEmLocations } from '../../../selectors'

interface EmLocationsProps {
  employeeId?: string
  id?: string
}

type EmLocationTable = EmLocationState

const EMPTY_FORM_DATA: IEmLocationInfo = {
  startDate: '',
  locationId: ''
}

interface DrawerState {
  visible: boolean
  employeeId?: string
  data?: EmLocationState
  isCurrent: boolean
}

const DEFAULT_MODAL_STATE: DrawerState = { visible: false, isCurrent: false }

const cardStyle: CSSProperties = { margin: 24 }
const cardBodyStyle: CSSProperties = { padding: 0 }
const formStyle: CSSProperties = { padding: '6px 24px' }

export const EmLocations: FC<EmLocationsProps> = ({ employeeId, id }: EmLocationsProps) => {
  const { ref, inView } = useFirstInView<HTMLDivElement>({ threshold: 0.25 })
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_MODAL_STATE)
  const [formData, setFormData] = useState<IEmLocationInfo>(EMPTY_FORM_DATA)
  const current = useSelector(selectCurrentEmLocation)(employeeId, id)
  const histories = useSelector(selectHistoryEmLocations)(employeeId)
  const locations = useSelector(selectKeyvaluesById)('location')
  const [showHistory, toggleShowHistory] = useToggle()
  const canView = usePermissionGate(Permission.employeeAttendance)
  const canModify = usePermissionGate(Permission.employeeAttendance, PermissionAction.Modify)
  const loading = useSelector((state: StoreState) => state.employee.emLocationsLoading[employeeId || ''])

  useEffect(() => {
    if (inView) {
      if (employeeId) {
        dispatch(fetchEmLocations(employeeId))
      }
    }
  }, [inView, employeeId])

  useEffect(() => {
    if (showHistory && histories.length === 0) {
      toggleShowHistory()
    }
  }, [histories.length, showHistory, toggleShowHistory])

  useEffect(() => {
    if (current) {
      const { startDate, locationId } = current
      setFormData({ startDate, locationId })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [current])

  const handleEditLocation = useCallback(
    (emLocation?: EmLocationState) => {
      setDrawerState({ visible: true, employeeId, data: emLocation, isCurrent: id === emLocation?.id })
    },
    [employeeId, id, setDrawerState]
  )

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_MODAL_STATE)
  }, [])

  const columns: ColumnsType<EmLocationTable> = [
    {
      title: 'Effective date',
      key: 'startDate',
      dataIndex: 'startDate',
      width: 150,
      render: (value: string) => value && moment(value).format('DD MMM YYYY')
    },
    {
      title: 'Location',
      key: 'locationId',
      dataIndex: 'locationId',
      render: (value: string, record) => (
        <Space>
          {locations[value]?.value}
          {record.id === id && <Tag type="primary">current</Tag>}
          {isInactive(locations[value]?.inactiveDate) && <InactiveTag />}
        </Space>
      )
    },
    {
      key: 'action',
      align: 'right',
      render: (value: string, record) =>
        canModify && (
          <Link size="small" onClick={() => handleEditLocation(record)}>
            edit
          </Link>
        )
    }
  ]

  if (!canView) return null

  return (
    <div ref={ref}>
      <Card
        title="Location"
        style={cardStyle}
        bodyStyle={cardBodyStyle}
        loading={loading}
        extra={
          <LinkGroup>
            {canModify && !showHistory && (
              <Link onClick={() => handleEditLocation(current)}>{current ? 'edit' : 'add'}</Link>
            )}
            {(histories?.length || 0) > 0 && (
              <Link onClick={toggleShowHistory}>{showHistory ? 'hide history' : 'history'}</Link>
            )}
          </LinkGroup>
        }
      >
        {!showHistory && (
          <Form style={formStyle}>
            <Row gutter={30}>
              <Col span={12}>
                <Form.Item
                  label={
                    <Space>
                      Location
                      {formData.startDate && (
                        <SecondaryText size="small">
                          effective from {moment(formData.startDate).format('DD MMM YYYY')}
                        </SecondaryText>
                      )}
                    </Space>
                  }
                >
                  <AttKeyValues id="location" value={formData.locationId} readOnly />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
        {showHistory && <Table rowKey="id" dataSource={histories} pagination={false} columns={columns} />}
        {canModify && <MutateEmLocationDrawer {...drawerState} onClose={handleCloseDrawer} />}
      </Card>
    </div>
  )
}
