import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { FeatureFlag } from '~/constants'
import { dispatch } from '~/stores/store'
import { Absence } from './Absence'
import { fetchOthersLeaveRecords } from '../../../../actions'
import { selectTodayAbsences } from '../../../../selectors'
import { MySsAccessGate } from '../../../MySsAccessGate'

interface AbsenceTodayProps {}

export const AbsenceToday: FC<AbsenceTodayProps> = () => {
  const outToday = useSelector(selectTodayAbsences)
  const leaveRecordIds = outToday.map(o => o.id)

  useEffect(() => {
    dispatch(fetchOthersLeaveRecords({ strategy: 'when-empty' }))
  }, [])

  return (
    <MySsAccessGate featureFlag={FeatureFlag.MyLeave}>
      <Absence title="Out today" className="home__card" data={outToday} ids={leaveRecordIds} />
    </MySsAccessGate>
  )
}
