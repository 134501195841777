import { setMyClaimTask, setMyClaimTasksLoading } from '../reducers'
import { apiGetMyClaimTask } from '../api/ss-claim-task.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchMyClaimTask = (id: string, options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const loading = getState().myClaim.myClaimTasksLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().myClaim.myClaimTasks?.entities[id]
      if (hasData) return
    }

    try {
      dispatch(setMyClaimTasksLoading(true))
      const { result, status } = await apiGetMyClaimTask(id)
      if (status) dispatch(setMyClaimTask(result))
    } finally {
      dispatch(setMyClaimTasksLoading(false))
    }
  }
}
