import { showSuccess, showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import pick from 'lodash/pick'
import { ILogin, LoginState } from '../types'
import { setLogin } from '../reducers'
import { apiAddLogin } from '../api/login.api'

export const addLogin = (request: ILogin): ThunkResult<void> => {
  return async (dispatch): Promise<ActionResult> => {
    const { status, result, errors, message, errorData } = await apiAddLogin(request)
    if (status) {
      const newLoginUpdates = pick(request, ['email', 'loginName'])
      const newLogin: LoginState = {
        id: result.id,
        isSuperUser: result.isSuperUser,
        ...newLoginUpdates
      }

      // The "login" update
      dispatch(setLogin(newLogin))
      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
