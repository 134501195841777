import { UploadFile } from '~/core-components'
import { request, getBaseUrl, timeout } from '~/utils/request'
import {
  PayImportState,
  PayImportValidateState,
  PayImportSourceState,
  PayImportExecuteState,
  IPayItemMap
} from '../types'

const baseUrl = getBaseUrl('/payroll-sg/payimport')

export const apiGetPayImportTemplateExcel = (params: { byDates: boolean }) =>
  request('get', `${baseUrl}/template/excel`, params, { responseType: 'blob', timeout })

export const apiUploadPayImport = (byDates: boolean, file: UploadFile) => {
  let formData = new FormData()
  if (file instanceof File) formData.append('file', file, file.name)
  formData.append('byDates', byDates.toString())

  return request<PayImportState>('post', `${baseUrl}/upload`, formData)
}

export const apiValidatePayImport = (payRunId: string, source: PayImportSourceState[], payItemMap: IPayItemMap[]) =>
  request<PayImportValidateState>('post', `${baseUrl}/validate`, { payRunId, source, payItemMap })

export const apiExecutePayImport = (payRunId: string) =>
  request<PayImportExecuteState>('post', `${baseUrl}/execute`, { payRunId })
