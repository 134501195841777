import React, { CSSProperties, FC, useCallback, useMemo, useState } from 'react'
import moment from 'moment-timezone'
import confirm from 'antd/lib/modal/confirm'
import {
  Button,
  Card,
  ColumnsType,
  Form,
  Link,
  SecondaryText,
  Space,
  Table,
  TableRowSelection
} from '~/core-components'
import { AmountDisplay, Person, SearchInput, YearKeyValues } from '~/components'
import { Screen, ViewCriteriaSimple, ViewCriteria, updateViewCriteria, useFirstView } from '~/features/selection'
import { usePermissionGate } from '~/features/iam'
import { ClaCurrencyCode, ClaExpenseSubmissionType, ClaRecordStatus, Permission, PermissionAction } from '~/constants'
import { EMP_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { ActionResult } from '~/types/store'
import { getBaseUrl, showError } from '~/utils'
import { ClaimPendingPaymentRowState } from '../../types'
import { refetchClaimPendingPaymentsView } from '../../reducers'
import { ClaimStatusTag } from '../ClaimStatusTag/ClaimStatusTag'
import { revertClaimPendingPayment } from '../../actions'
import { useClaimPendingPaymentView, useClaimTypesDict } from '../../hooks'
import { ClaimTypeName } from '../ClaimEntitlements/components/ClaimTypeName'
import { MutateClaimRecordDrawer } from '../ClaimRecords/components/MutateClaimRecordDrawer'
import {
  DEFAULT_UPDATE_PAYMENT_MODAL_STATE,
  UpdateClaimPendingPaymentModal,
  UpdateClaimPendingPaymentModalState
} from './components/UpdateClaimPendingPaymentModal'
import './ClaimPendingPayments.less'

interface ClaimPendingPaymentsProps {}

interface DrawerState {
  visible: boolean
  employeeId?: string
  id?: string
  editing?: boolean
}

const SCREEN_CODE: Screen = 'claim_pending_payment'
const DEFAULT_DRAWER_STATE: DrawerState = { visible: false, editing: false }
const PAGE_SIZE_OPTIONS = ['20', '50', '100']
const TODAY_YEAR = moment().year()
const paginationStyle: CSSProperties = { marginRight: 20 }

const baseUrl = getBaseUrl('/filestore')

export const ClaimPendingPayments: FC<ClaimPendingPaymentsProps> = () => {
  const [selected, setSelected] = useState<ClaimPendingPaymentRowState[]>([])
  const [periodYear, setPeriodYear] = useState<number>(TODAY_YEAR)
  const [search, setSearch] = useState<string>('')
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const [view, viewLoading] = useFirstView(SCREEN_CODE)
  const viewId = view?.id || ''
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(20)
  const [data, dataLoading] = useClaimPendingPaymentView(viewId, periodYear, page, pageSize, search, 'always')
  const ids = useMemo(() => data?.data.map(d => d.id) || [], [data])

  const canModify = usePermissionGate(Permission.claRecord, PermissionAction.Modify)
  const canViewEmployee = usePermissionGate(Permission.employee)
  const [reverting, setReverting] = useState(false)
  const [claimTypes] = useClaimTypesDict()
  const [updatePaymentModalState, setUpdatePaymentModalState] = useState<UpdateClaimPendingPaymentModalState>(
    DEFAULT_UPDATE_PAYMENT_MODAL_STATE
  )

  const handleOpenClaimRecord = useCallback(
    (row: ClaimPendingPaymentRowState) => {
      setDrawerState({ visible: true, editing: false, id: row.id, employeeId: row.employeeId })
    },
    [setDrawerState]
  )

  let columns: ColumnsType<ClaimPendingPaymentRowState> = [
    {
      title: 'Name',
      key: 'employeeId',
      dataIndex: 'employeeId',
      width: 250,
      render: (value: string, record) => (
        <Person
          name={record.employeeName}
          photo={record.photoId && `${baseUrl}/file/${record.photoId}/thumbnailphoto/18`}
          path={canViewEmployee ? EMP_ROUTES.employee.replace(':id', value) : undefined}
          size={18}
        />
      )
    },
    {
      title: 'Claim #',
      key: 'claimNo',
      dataIndex: 'claimNo',
      width: 120,
      render: (value: string, record) => <Link onClick={() => handleOpenClaimRecord(record)}>{`#${value}`}</Link>
    },
    {
      title: 'Claim type',
      key: 'claimTypeId',
      dataIndex: 'claimTypeId',
      width: 180,
      render: (value: string) => <ClaimTypeName id={value} hideNewTab />
    },
    {
      title: 'Expense date',
      key: 'expenseDate',
      dataIndex: 'expenseDate',
      width: 130,
      render: (value: string) => (value ? moment(value).format('DD MMM YYYY') : '')
    },
    {
      title: 'Amount',
      key: 'claimAmount',
      dataIndex: 'claimAmount',
      width: 150,
      render: (value: number, record) => (
        <>
          <AmountDisplay
            block
            symbol={claimTypes[record.claimTypeId]?.currencyCode || ClaCurrencyCode.sgd}
            value={value}
          />
          {value !== record.expenseAmount &&
            claimTypes[record.claimTypeId]?.expenseSubmissionType === ClaExpenseSubmissionType.Single && (
              <SecondaryText block size="small">
                <AmountDisplay
                  size="small"
                  symbol={record.expenseCurrencyCode || ClaCurrencyCode.sgd}
                  value={record.expenseAmount}
                />
              </SecondaryText>
            )}
        </>
      )
    },
    {
      title: 'Status',
      key: 'recordStatus',
      dataIndex: 'recordStatus',
      width: 160,
      render: (value: string) => <ClaimStatusTag status={value} />
    },
    {
      title: <i className="fal fa-paperclip-vertical att-icon-header" />,
      key: 'attachmentCount',
      dataIndex: 'attachmentCount',
      width: 50,
      render: (value: number) => {
        return (
          value > 0 && (
            <>
              <i className="fal fa-paperclip-vertical" />
              <span className="att-count">{value}</span>
            </>
          )
        )
      }
    },

    {
      title: 'Approval date',
      key: 'approvalDate',
      dataIndex: 'approvalDate',
      width: 130,
      render: (value: string) => (value ? moment(value).format('DD MMM YYYY') : '')
    },
    {
      title: 'Payment date',
      key: 'paymentDate',
      dataIndex: 'paymentDate',
      width: 130,
      render: (value: string) => (value ? moment(value).format('DD MMM YYYY') : '')
    },
    {
      title: 'Notes',
      key: 'notes',
      dataIndex: 'notes',
      width: 300
    }
  ]

  const handlePaginationChange = useCallback((page: number, pageSize?: number) => {
    setPage(page)
    setPageSize(pageSize || 20)
  }, [])

  const handleCriteriaApply = useCallback(
    async (criteria: ViewCriteria[]) => {
      if (viewId) {
        await dispatch(updateViewCriteria(SCREEN_CODE, viewId, { id: viewId, criteria }))
        dispatch(refetchClaimPendingPaymentsView())
        setSelected([])
      }
    },
    [viewId]
  )

  const handleSearch = useCallback((value: string) => {
    setSearch(value)
  }, [])

  const handlePeriodChange = useCallback(periodYear => {
    setPeriodYear(periodYear)
  }, [])

  const handleCloseDrawer = useCallback(
    (action: 'saved' | 'cancelled') => {
      if (action === 'saved' && viewId) {
        dispatch(refetchClaimPendingPaymentsView())
      }
      setDrawerState(DEFAULT_DRAWER_STATE)
    },
    [viewId]
  )

  const handleUpdatePayment = useCallback(async () => {
    if (selected.length > 0) {
      const hasPaid = selected.some(c => c.recordStatus === ClaRecordStatus.Paid)
      if (hasPaid) {
        showError(
          'One or more of the selected records have already been paid. Please revert the payment of the paid records first before updating the payment date.'
        )
      } else {
        setUpdatePaymentModalState({ visible: true, claimRecordIds: selected.map(s => s.id) })
      }
    } else {
      showError('Please select at least one claim record')
    }
  }, [selected])

  const handleRevertPayment = useCallback(async () => {
    if (selected.length > 0) {
      confirm({
        title: 'Revert',
        content: 'Do you want to revert this payment record?',
        onOk: async () => {
          setReverting(true)
          const result: ActionResult | undefined = await dispatch(
            revertClaimPendingPayment({ claimRecordIds: selected.map(s => s.id) })
          )

          if (!result?.errors) {
            setReverting(false)
            setSelected([])
          }
        },
        width: 550,
        okText: 'Revert',
        okType: 'danger'
      })
    } else {
      showError('Please select at least one claim record')
    }
  }, [selected])

  const handleCloseUpdatePaymentModal = useCallback((action: 'updated' | 'cancelled') => {
    if (action === 'updated') setSelected([])
    setUpdatePaymentModalState(DEFAULT_UPDATE_PAYMENT_MODAL_STATE)
  }, [])

  const rowSelection: TableRowSelection<ClaimPendingPaymentRowState> = useMemo(
    () => ({
      type: 'checkbox',
      selectedRowKeys: selected.map(s => s.id),
      hideSelectAll: false,
      onChange: (_selectedRowKeys, selectedRows) => {
        setSelected(s => [...s.filter(o => !ids.includes(o.id)), ...selectedRows])
      }
    }),
    [selected, ids]
  )

  return (
    <div className="claim-pending-payment">
      <div className="claim-pending-payment__body">
        <div className="claim-pending-payment__action-bar">
          <Form.Item label="">
            <SearchInput onSearch={handleSearch} />
          </Form.Item>
          <ViewCriteriaSimple screenCode={SCREEN_CODE} viewId={viewId} onApply={handleCriteriaApply} label="" />
          {canModify && (
            <Space align="start">
              <YearKeyValues
                noOfYearBefore={-5}
                noOfYearAfter={0}
                value={periodYear}
                onChange={handlePeriodChange}
                allowClear={false}
              />
              <Button onClick={handleUpdatePayment}>Update payment</Button>
              <Button onClick={handleRevertPayment} loading={reverting}>
                Revert payment
              </Button>
            </Space>
          )}
        </div>
        <Card fitParent table>
          <Table
            rowSelection={rowSelection}
            rowKey="id"
            dataSource={data?.data}
            columns={columns}
            fitParent
            loading={dataLoading || viewLoading}
            scroll={{ y: 1000 }}
            pagination={{
              total: data?.count,
              current: page,
              pageSize,
              pageSizeOptions: PAGE_SIZE_OPTIONS,
              showSizeChanger: true,
              onChange: handlePaginationChange,
              style: paginationStyle
            }}
          />
        </Card>
      </div>
      <MutateClaimRecordDrawer {...drawerState} onClose={handleCloseDrawer} />
      <UpdateClaimPendingPaymentModal {...updatePaymentModalState} onClose={handleCloseUpdatePaymentModal} />
    </div>
  )
}
