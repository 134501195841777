import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { apiUpdatePayRunPayslip } from '../api/payrun-payslip.api'
import { PayRunPayslipState, IPayRunPayslip, mapIPayRunPayslipToPayRunPayslipState } from '../types'
import { setPayRunPayslip } from '../reducers'

export const updatePayRunPayslip = (
  payRunId: string,
  id: string,
  before: IPayRunPayslip,
  request: IPayRunPayslip
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const after = { ...before, ...request } as IPayRunPayslip
    const patch = generateJsonPatch<IPayRunPayslip>(before, after)
    const { status, errors, message, errorData } = await apiUpdatePayRunPayslip(id, patch)

    if (status) {
      const payRunPayslip = getState().payroll.payRunPayslip[payRunId]
      const data: PayRunPayslipState = {
        ...(payRunPayslip as PayRunPayslipState),
        ...mapIPayRunPayslipToPayRunPayslipState(after)
      }
      dispatch(setPayRunPayslip({ payRunId, data: data }))

      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
