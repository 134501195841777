import React, { FC, memo, useState, useCallback, useEffect, ChangeEvent, CSSProperties } from 'react'
import { Form, Input, Space, Tag } from '~/core-components'
import { Col, DrawerForm, Row, SysOptions } from '~/components'
import { dispatch } from '~/stores/store'
import { ActionResult } from '~/types/store'
import { useFocus } from '~/hooks/use-focus'
import { SSEmPublicSelfState } from '~/features/my/types'
import { IEmployeeEmergency, useSysOptions } from '~/features/employee'
import { applyEmergencyRequest } from '~/features/my/actions'

export interface EditMyEmergencyDrawerProps {
  visible: boolean
  data?: SSEmPublicSelfState
  onClose: (action: 'saved' | 'cancelled') => void
}

const PendingTag = memo(() => <Tag type="secondary">pending</Tag>)

const EMPTY_FORM_DATA: IEmployeeEmergency = {
  emergencyName: '',
  emergencyRelation: '',
  emergencyPhone: '',
  emergencyName2: '',
  emergencyRelation2: '',
  emergencyPhone2: ''
}

const currenTextStyle: CSSProperties = { fontSize: 13, color: '#797979' }
const labelPrimaryStyle: CSSProperties = { marginBottom: 7 }
const labelSecondaryStyle: CSSProperties = { marginTop: 20, marginBottom: 7 }

export const EditMyEmergencyDrawer: FC<EditMyEmergencyDrawerProps> = ({
  visible,
  data,
  onClose
}: EditMyEmergencyDrawerProps) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<IEmployeeEmergency>(EMPTY_FORM_DATA)
  const [focusRef, setFocus] = useFocus(true)
  const [relationships] = useSysOptions('relationship')

  const isDiffEmergencyName = data?.emergencyName !== data?.emergencyNameNew
  const isDiffEmergencyRelation = data?.emergencyRelation !== data?.emergencyRelationNew
  const isDiffEmergencyPhone = data?.emergencyPhone !== data?.emergencyPhoneNew
  const isDiffEmergencyName2 = data?.emergencyName2 !== data?.emergencyName2New
  const isDiffEmergencyRelation2 = data?.emergencyRelation2 !== data?.emergencyRelation2New
  const isDiffEmergencyPhone2 = data?.emergencyPhone2 !== data?.emergencyPhone2New

  const hasRequest =
    isDiffEmergencyName ||
    isDiffEmergencyRelation ||
    isDiffEmergencyPhone ||
    isDiffEmergencyName2 ||
    isDiffEmergencyRelation2 ||
    isDiffEmergencyPhone2

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
  }, [visible, setFocus])

  useEffect(() => {
    if (data) {
      setFormData({
        emergencyName: data.emergencyNameNew,
        emergencyRelation: data.emergencyRelationNew,
        emergencyPhone: data.emergencyPhoneNew,
        emergencyName2: data.emergencyName2New,
        emergencyRelation2: data.emergencyRelation2New,
        emergencyPhone2: data.emergencyPhone2New
      })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => setFormData(formData => ({ ...formData, ...updates })),
    []
  )

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      result = await dispatch(applyEmergencyRequest(formData))
    } finally {
      setLoading(false)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose('saved')
      setFormData(EMPTY_FORM_DATA)
    }
  }, [formData, onClose])

  return (
    <DrawerForm
      open={visible}
      title="Edit emergency contact"
      okText={hasRequest ? 'Resubmit' : 'Submit'}
      onClose={() => onClose('cancelled')}
      confirmLoading={loading}
      width={600}
      formId="form-emergency-contact"
    >
      <Form id="form-emergency-contact" onFinish={handleOk}>
        <Row>
          <Col span={24}>
            <div style={labelPrimaryStyle}>Primary</div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label={<Space>Name {isDiffEmergencyName && <PendingTag />}</Space>}>
              <Input
                ref={focusRef}
                value={formData.emergencyName}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleFormDataChange({ emergencyName: event.target.value })
                }
              />
              {isDiffEmergencyName && <Space style={currenTextStyle}>Current: {data?.emergencyName || '-'}</Space>}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col span={12}>
            <Form.Item label={<Space>Relationship {isDiffEmergencyRelation && <PendingTag />}</Space>}>
              <SysOptions
                type="relationship"
                value={formData.emergencyRelation}
                onChange={(value: string) => handleFormDataChange({ emergencyRelation: value })}
              />
              {isDiffEmergencyRelation && (
                <Space style={currenTextStyle}>
                  Current: {relationships[data?.emergencyRelation || '']?.value || '-'}
                </Space>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={<Space>Contact no. {isDiffEmergencyPhone && <PendingTag />}</Space>}>
              <Input
                value={formData.emergencyPhone}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleFormDataChange({ emergencyPhone: event.target.value })
                }
              />
              {isDiffEmergencyPhone && <Space style={currenTextStyle}>Current: {data?.emergencyPhone || '-'}</Space>}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div style={labelSecondaryStyle}>Secondary</div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label={<Space>Name {isDiffEmergencyName2 && <PendingTag />}</Space>}>
              <Input
                value={formData.emergencyName2}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleFormDataChange({ emergencyName2: event.target.value })
                }
              />
              {isDiffEmergencyName2 && <Space style={currenTextStyle}>Current: {data?.emergencyName2 || '-'}</Space>}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col span={12}>
            <Form.Item label={<Space>Relationship {isDiffEmergencyRelation2 && <PendingTag />}</Space>}>
              <SysOptions
                type="relationship"
                value={formData.emergencyRelation2}
                onChange={(value: string) => handleFormDataChange({ emergencyRelation2: value })}
              />
              {isDiffEmergencyRelation2 && (
                <Space style={currenTextStyle}>
                  Current: {relationships[data?.emergencyRelation2 || '']?.value || '-'}
                </Space>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={<Space>Contact no. {isDiffEmergencyPhone2 && <PendingTag />}</Space>}>
              <Input
                value={formData.emergencyPhone2}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleFormDataChange({ emergencyPhone2: event.target.value })
                }
              />
              {isDiffEmergencyPhone2 && <Space style={currenTextStyle}>Current: {data?.emergencyPhone2 || '-'}</Space>}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}
