import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { refetchDailyRecords, refetchDailyRecordsView, refetchDailySummaryView, setDailyProcessing } from '../reducers'
import { apiProcessDaily } from '../api/attendance.api'

export const processDaily = (
  startDate: string,
  endDate: string,
  employeeIds: string[] = [],
  employeeScope: 'existing' | 'all' = 'existing'
): ThunkResult<void> => {
  return async dispatch => {
    if (!startDate || !endDate) return

    try {
      dispatch(setDailyProcessing(true))
      const { status, errors, message, errorData } = await apiProcessDaily(
        startDate,
        endDate,
        employeeScope,
        employeeIds
      )
      if (status) {
        showSuccess('Recalculated')
        dispatch(refetchDailyRecordsView())
        dispatch(refetchDailySummaryView())
        dispatch(refetchDailyRecords())
      } else {
        if (Object.keys(errors).length === 0) {
          showError(message, errorData)
        }
      }
    } finally {
      dispatch(setDailyProcessing(false))
    }
  }
}
