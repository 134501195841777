import { FC, useCallback } from 'react'
import { Screen, ViewCriteria, ViewCriteriaAdvanced, useFirstView } from '~/features/selection'
import './FeatureFlagViewCriteria.less'

interface FeatureFlagViewCriteriaProps {
  featureFlag: string
  title: string
  readOnly?: boolean
  onChange?: (viewId: string, criteria: ViewCriteria[]) => void
}

const SCREEN_CODE_PREFIX = 'ss_access'

export const FeatureFlagViewCriteria: FC<FeatureFlagViewCriteriaProps> = ({
  featureFlag,
  title,
  readOnly,
  onChange
}) => {
  const SCREEN_CODE = `${SCREEN_CODE_PREFIX}_${featureFlag}` as Screen
  const [view] = useFirstView(SCREEN_CODE)
  const firstViewId = view?.id || ''

  const handleCriteriaChange = useCallback(
    (criteria: ViewCriteria[]) => {
      typeof onChange === 'function' && onChange(firstViewId, criteria)
    },
    [onChange, firstViewId]
  )

  return (
    <div className="feature-flag-view-criteria">
      <div className="feature-flag-view-criteria__title">{title}</div>
      <ViewCriteriaAdvanced
        screenCode={SCREEN_CODE}
        viewId={firstViewId}
        label=""
        emptyText="All employees"
        readOnly={readOnly}
        onChange={handleCriteriaChange}
      />
    </div>
  )
}
