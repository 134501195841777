import React, { CSSProperties, ChangeEvent, FC, useCallback, useState } from 'react'
import moment from 'moment-timezone'
import { EditOutlined } from '@ant-design/icons'
import { v4 as uuidv4 } from 'uuid'
import { AmountDisplay, Col, CurrencyKeyValues, Row, SalaryInput, UploadFileSelectorAuth } from '~/components'
import {
  Button,
  Checkbox,
  CheckboxChangeEvent,
  Input,
  Link,
  SecondaryLink,
  SecondaryText,
  Space,
  Tooltip,
  UploadFile
} from '~/core-components'
import {
  apiGetExchangeRate,
  DEFAULT_EDIT_NOTES_MODAL_STATE,
  DEFAULT_EDIT_TAX_AMOUNT_MODAL_STATE,
  EditNotesModal,
  EditNotesModalState,
  EditTaxAmountModal,
  EditTaxAmountModalState,
  ExpenseTypeKeyValues,
  getInclusiveTaxLabel,
  useClaimType,
  useCurrentTaxRate,
  useExpenseTypesDict
} from '~/features/claim'
import { ClaAttachmentFileTypeAllowed, ClaCurrencyCode, ClaTaxableType, emptyGuid } from '~/constants'
import { request, timeout } from '~/utils'
import { SSClaimRecordExpense } from '../../types'
import './MyClaimRecordMultipleFormRow.less'

interface MyClaimRecordMultipleFormRowProps {
  index: number
  claimTypeId: string
  data: SSClaimRecordExpense
  readOnly?: boolean
  claimAmountLoading?: boolean
  onChange: (index: number, changes: Partial<SSClaimRecordExpense>) => void
  onRemove: (index: number) => void
}

const currencyStyle: CSSProperties = { width: 80 }
const italicStyle: CSSProperties = { fontWeight: 'normal', fontStyle: 'italic' }
const editIconStyle: CSSProperties = { marginLeft: 8 }
const editedTaxIndicatorStyle: CSSProperties = { position: 'absolute', top: 0 }
const expenseTypeStyle: CSSProperties = { width: '100%' }
const notesStyle: CSSProperties = {
  fontWeight: 'normal',
  fontStyle: 'italic',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}
const tooltipOverlayStyle: CSSProperties = { whiteSpace: 'pre-line', maxWidth: 400 }
const TODAY = moment().format('YYYY-MM-DD')

export const CLAIM_RECORD_EXPENSE_EMPTY_FORM_DATA: SSClaimRecordExpense = {
  id: emptyGuid,
  sequence: 0,
  expenseTypeId: '',
  providerName: '',
  receiptNo: '',
  expenseDate: TODAY,
  expenseCurrencyCode: ClaCurrencyCode.sgd,
  exchangeRate: 1,
  exchangeUnit: 1,
  expenseAmount: 0,
  claimAmount: 0,
  claimAmountTax: 0,
  isTaxable: false,
  isClaimAmountTaxOverride: false,
  notes: '',
  attachments: [],
  deletedAttachmentIds: [],
  isNew: true
}

export const getClaimRecordExpenseEmptyForm = (extraProps: Partial<SSClaimRecordExpense> = {}) => ({
  ...CLAIM_RECORD_EXPENSE_EMPTY_FORM_DATA,
  id: uuidv4(),
  ...extraProps
})

export const MyClaimRecordMultipleFormRow: FC<MyClaimRecordMultipleFormRowProps> = ({
  index,
  claimTypeId,
  data,
  readOnly,
  claimAmountLoading,
  onChange,
  onRemove
}) => {
  const [taxRate] = useCurrentTaxRate(data.expenseCurrencyCode, data.expenseDate)
  const [claimType] = useClaimType(claimTypeId)
  const [expenseTypesDict] = useExpenseTypesDict()

  const sourceCurrency = data.expenseCurrencyCode
  const targetCurrency = claimType?.currencyCode
  const exchangeDate = data.expenseDate
  const [exchangeRateLoading, setExchangeRateLoading] = useState(false)

  const [editTaxAmountModalState, setEditTaxAmountModalState] = useState<EditTaxAmountModalState>(
    DEFAULT_EDIT_TAX_AMOUNT_MODAL_STATE
  )

  const [editNotesModalState, setEditNotesModalState] = useState<EditNotesModalState>(DEFAULT_EDIT_NOTES_MODAL_STATE)

  const fetchCurrencyExchange = useCallback(
    async (overrideSource?: string) => {
      const _sourceCurrency = overrideSource || sourceCurrency
      if (!_sourceCurrency || !targetCurrency || !exchangeDate) {
        return
      }

      if (_sourceCurrency === targetCurrency) {
        onChange(index, { exchangeRate: 1, exchangeUnit: 1 })
        return
      }

      try {
        setExchangeRateLoading(true)
        const { result } = await apiGetExchangeRate(_sourceCurrency, targetCurrency, exchangeDate)
        if (result) {
          const { rate, unit } = result
          onChange(index, { exchangeRate: rate, exchangeUnit: unit })
        }
      } finally {
        setExchangeRateLoading(false)
      }
    },
    [sourceCurrency, targetCurrency, exchangeDate, index, onChange]
  )

  const handleUpdateExchangeRate = useCallback(() => {
    fetchCurrencyExchange()
  }, [fetchCurrencyExchange])

  const handleOpenEditTaxAmountModal = useCallback(() => {
    setEditTaxAmountModalState({
      visible: true,
      currencyCode: taxRate.currencyCode,
      amount: data.claimAmount,
      taxAmount: data.claimAmountTax,
      isUserOverride: data.isClaimAmountTaxOverride
    })
  }, [taxRate, data])

  const handleCloseEditTaxAmountModal = useCallback(() => {
    setEditTaxAmountModalState(DEFAULT_EDIT_TAX_AMOUNT_MODAL_STATE)
  }, [])

  const handleOpenEditNotesModal = useCallback(() => {
    setEditNotesModalState({
      visible: true,
      notes: data.notes
    })
  }, [data.notes])

  const handleCloseEditNotesModal = useCallback(() => {
    setEditNotesModalState(DEFAULT_EDIT_NOTES_MODAL_STATE)
  }, [])

  const handleFileChange = useCallback(
    (files?: UploadFile[]) => {
      if (files && files.length > 0) {
        const updated = { ...data, attachments: files }
        typeof onChange === 'function' && onChange(index, updated)
      }
    },
    [data, index, onChange]
  )

  const handleFileRemove = useCallback(
    (file?: UploadFile) => {
      let deletedFileIds: string[] | undefined = data.deletedAttachmentIds || []
      if (file) {
        const isNewFile = file instanceof File
        if (!isNewFile) deletedFileIds?.push(file.uid)

        const listAttachment = data.attachments || []
        const updated = {
          ...data,
          attachments: listAttachment.filter(x => x.uid !== file.uid),
          ...(isNewFile ? {} : { deletedAttachmentIds: deletedFileIds })
        }
        typeof onChange === 'function' && onChange(index, updated)
      }
    },
    [data, index, onChange]
  )

  const handleFileDownload = useCallback(async (file: UploadFile) => {
    if (file.url) {
      const { result, status } = await request('get', file.url, undefined, {
        responseType: 'blob',
        timeout
      })
      if (status) {
        let objectUrl = window.URL.createObjectURL(result)
        window.open(objectUrl, '_blank')
      }
    }
  }, [])

  const includeTaxLabel = getInclusiveTaxLabel(taxRate?.currencyCode, '')

  return (
    <>
      {index === 0 && (
        <Row className="my-claim-record-multiple-form-row__header" gutter={15}>
          <Col span={4}>
            <div className="my-claim-record-multiple-form-row__label">Expense type</div>
          </Col>
          <Col span={3}>
            <div className="my-claim-record-multiple-form-row__label">Expense date</div>
          </Col>
          <Col span={3}>
            <div className="my-claim-record-multiple-form-row__label">Receipt no.</div>
          </Col>
          <Col span={5}>
            <div className="my-claim-record-multiple-form-row__label">Expense amount</div>
          </Col>
          <Col span={1}>
            <div className="my-claim-record-multiple-form-row__label">GST</div>
          </Col>
          <Col span={4}>
            <div className="my-claim-record-multiple-form-row__label">Vendor / Provider</div>
          </Col>
          <Col span={3}>
            <div className="my-claim-record-multiple-form-row__label">Claim amount</div>
          </Col>
          <Col span={1} />
        </Row>
      )}
      <Row className="my-claim-record-multiple-form-row" gutter={15}>
        <Col span={4}>
          <Space direction="vertical" style={expenseTypeStyle}>
            {readOnly ? (
              <>{expenseTypesDict[data.expenseTypeId]?.name || '-'}</>
            ) : (
              <ExpenseTypeKeyValues
                style={expenseTypeStyle}
                claimTypeId={claimTypeId}
                value={data.expenseTypeId}
                onChange={expenseTypeId => onChange(index, { expenseTypeId })}
              />
            )}
            {data.notes || readOnly ? (
              <Row>
                <Col span={20}>
                  <Tooltip title={data.notes} overlayStyle={tooltipOverlayStyle}>
                    <div style={notesStyle}>
                      <SecondaryText>{data.notes || '-'}</SecondaryText>
                    </div>
                  </Tooltip>
                </Col>
                <Col span={4}>
                  {!readOnly && (
                    <SecondaryLink style={editIconStyle}>
                      <EditOutlined onClick={handleOpenEditNotesModal} />
                    </SecondaryLink>
                  )}
                </Col>
              </Row>
            ) : (
              <Link onClick={handleOpenEditNotesModal}>add notes</Link>
            )}
          </Space>
        </Col>
        <Col span={3}>
          {readOnly ? (
            data.expenseDate ? (
              moment(data.expenseDate).format('DD MMM YYYY')
            ) : (
              '-'
            )
          ) : (
            <Input.Date
              allowClear={false}
              value={data.expenseDate ? moment(data.expenseDate) : undefined}
              onChange={(value: moment.Moment | null) => onChange(index, { expenseDate: value?.format('YYYY-MM-DD') })}
              disabledDate={current => current && current > moment().endOf('day')}
            />
          )}
        </Col>
        <Col span={3}>
          {readOnly ? (
            data.receiptNo || '-'
          ) : (
            <Input
              value={data.receiptNo}
              onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(index, { receiptNo: event.target.value })}
            />
          )}
        </Col>
        <Col span={5}>
          <Space direction="vertical">
            {readOnly ? (
              <AmountDisplay symbol={data.expenseCurrencyCode} value={data.expenseAmount} />
            ) : (
              <Space>
                <CurrencyKeyValues
                  labelProp={kv => kv.key.toUpperCase()}
                  optionLabelProp="label"
                  dropdownMatchSelectWidth={false}
                  allowClear={false}
                  style={currencyStyle}
                  value={data.expenseCurrencyCode}
                  onChange={(expenseCurrencyCode: string) => {
                    onChange(index, { expenseCurrencyCode, claimAmountTax: 0, exchangeRate: 0, exchangeUnit: 1 })
                    fetchCurrencyExchange(expenseCurrencyCode)
                  }}
                />
                <SalaryInput
                  min={0}
                  value={data.expenseAmount}
                  onChange={(value: number | null) => onChange(index, { expenseAmount: value as number })}
                />
              </Space>
            )}
            <Space
              style={{ display: 'block' }}
              hidden={
                !claimType?.currencyCode ||
                !data.expenseCurrencyCode ||
                claimType?.currencyCode === data.expenseCurrencyCode
              }
            >
              {readOnly ? (
                <SecondaryText size="small" style={italicStyle}>
                  {data.exchangeRate}
                </SecondaryText>
              ) : (
                <Input.Group compact>
                  <Input.Number
                    min={0}
                    value={data.exchangeRate}
                    formatter={value => `${value}`.replace(/(?<!\.\d*)(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')}
                    style={{ width: 110 }}
                    onChange={(value: number | null) => onChange(index, { exchangeRate: value as number })}
                  />
                  <Button
                    type="default"
                    icon={<i className="fal fa-refresh" />}
                    onClick={handleUpdateExchangeRate}
                    loading={exchangeRateLoading}
                  />
                </Input.Group>
              )}
              {data.exchangeUnit > 1 && <SecondaryText size="small">/ {data.exchangeUnit}</SecondaryText>}
            </Space>
          </Space>
        </Col>
        <Col span={1}>
          {taxRate?.currencyCode === data.expenseCurrencyCode &&
            claimType?.taxableType === ClaTaxableType.Yes &&
            (readOnly ? (
              <>{data.isTaxable ? 'Yes' : 'No'}</>
            ) : (
              <Checkbox
                checked={data.isTaxable}
                readOnly={readOnly || claimAmountLoading}
                onChange={(event: CheckboxChangeEvent) => onChange(index, { isTaxable: event.target.checked })}
              />
            ))}
        </Col>
        <Col span={4}>
          <Space direction="vertical" size={4} style={{ width: '100%' }}>
            {readOnly ? (
              data.providerName || '-'
            ) : (
              <Input
                value={data.providerName}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  onChange(index, { providerName: event.target.value })
                }
                placeholder="Vendor / Provider"
              />
            )}
            <UploadFileSelectorAuth
              accept={ClaAttachmentFileTypeAllowed.toString()}
              fileList={data.attachments ? data.attachments : []}
              multiple
              disabled={readOnly}
              onChange={handleFileChange}
              onPreview={handleFileDownload}
              onRemove={handleFileRemove}
            >
              <Button size="small">choose file</Button>
            </UploadFileSelectorAuth>
          </Space>
        </Col>
        <Col span={3}>
          <AmountDisplay symbol={claimType?.currencyCode} value={data.claimAmount} loading={claimAmountLoading} />
          {taxRate?.currencyCode === data.expenseCurrencyCode && !claimAmountLoading && data.isTaxable && (
            <Row>
              <Col flex="none">
                <SecondaryText size="small" style={italicStyle}>
                  {includeTaxLabel} <AmountDisplay symbol={claimType?.currencyCode} value={data.claimAmountTax} />
                  {data.isClaimAmountTaxOverride && <span style={editedTaxIndicatorStyle}>*</span>}
                </SecondaryText>
              </Col>
              <Col flex="20px">
                {!readOnly && (
                  <SecondaryLink style={editIconStyle}>
                    <EditOutlined onClick={handleOpenEditTaxAmountModal} />
                  </SecondaryLink>
                )}
              </Col>
            </Row>
          )}
        </Col>
        <Col span={1}>
          <Button
            size="small"
            type="text"
            icon={<i className="fal fa-xmark" />}
            hidden={readOnly}
            onClick={() => onRemove(index)}
          />
        </Col>
        <EditTaxAmountModal
          {...editTaxAmountModalState}
          onApply={(claimAmountTax, isReset) => onChange(index, { claimAmountTax, isClaimAmountTaxOverride: !isReset })}
          onClose={handleCloseEditTaxAmountModal}
        />
        <EditNotesModal
          {...editNotesModalState}
          onApply={notes => onChange(index, { notes })}
          onClose={handleCloseEditNotesModal}
        />
      </Row>
    </>
  )
}
