import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { EmailGroupState, IEmailGroupInfo } from '../types/email-group.state'

const baseUrl = getBaseUrl('/employee/emailgroup')

export const apiGetEmailGroups = () => request<EmailGroupState[]>('get', baseUrl)

export const apiGetEmailGroup = (id: string) => request<EmailGroupState>('get', `${baseUrl}/${id}`)

export const apiAddEmailGroup = (emailGroup: IEmailGroupInfo) => request<{ id: string }>('post', baseUrl, emailGroup)

export const apiUpdateEmailGroup = (id: string, emailGroup: Operation[]) =>
  request('patch', `${baseUrl}/${id}`, emailGroup)

export const apiDeleteEmailGroup = (id: string) => request('delete', `${baseUrl}/${id}`)
