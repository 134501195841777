import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { SSLeaveEntitlementState } from '../../types'

export const selectMyLeaveEntitlement = createSelector(
  (state: StoreState) => state.my.myLeaveEntitlements,
  myLeaveEntitlementsState =>
    memoize((leaveTypeId: string): SSLeaveEntitlementState | undefined => {
      const leaveEntitlements = Object.values(myLeaveEntitlementsState?.entities || {}) as SSLeaveEntitlementState[]
      return leaveEntitlements.find(le => le.leaveTypeId === leaveTypeId)
    })
)
