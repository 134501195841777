import React, { FC } from 'react'
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom'
import { IAM_PATH, IAM_ROUTES } from '~/routes/routes'
import { UserSetting } from './UserSetting/UserSetting'
import './IamLayout.less'

const routeList: RouteProps[] = [
  {
    path: IAM_ROUTES.iam,
    component: UserSetting,
    exact: true
  }
]

export const IamLayout: FC = () => (
  <div className="iam-layout">
    <Switch>
      <Redirect exact from={IAM_PATH} to={IAM_ROUTES.iam.replace(':tab?', 'security')} />
      {routeList.map(route => (
        <Route key={route.path as string} {...route} />
      ))}
    </Switch>
  </div>
)
