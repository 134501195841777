import { showSuccess, showError } from '~/utils'
import { formatAddress } from '~/features/master'
import { IAddress } from '~/features/master/types'
import { ThunkResult } from '~/types/store'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { apiUpdateEmAddress } from '../api/em-address.api'
import { EmAddressState, IEmAddressInfo } from '../types'
import { replaceEmAddresses, setEmployee } from '../reducers'

export const updateEmAddress = <T extends IEmAddressInfo>(
  employeeId: string,
  id: string,
  before: T,
  request: T
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!employeeId || !id) return

    const after = { ...before, ...request } as T
    const patch = generateJsonPatch<T>(before, after)
    const { result, status, errors, message, errorData } = await apiUpdateEmAddress(employeeId, id, patch)

    if (status) {
      if (result.emAddressId) {
        const employee = getState().employee.employees.entities[employeeId]
        if (employee) {
          dispatch(setEmployee({ ...employee, emAddressId: result.emAddressId }))
        }
      }

      let newEmAddresses: EmAddressState[] = []

      const emAddresses = getState().employee.emAddresses[employeeId]?.entities || {}
      const countries = getState().master.keyvalues['country'].entities
      const countryName = countries[emAddresses[id]?.countryCode || '']?.value || ''
      const newEmAddress: EmAddressState = {
        ...(emAddresses[id] as EmAddressState),
        ...after,
        ...formatAddress(
          {
            block: after.block,
            street: after.street,
            level: after.level,
            unit: after.unit,
            building: '',
            city: after.city,
            state: after.state,
            postalCode: after.postalCode,
            countryCode: after.countryCode
          } as IAddress,
          countryName
        )
      }

      // The main "address"
      newEmAddresses.push(newEmAddress)

      if (!before.isMain && after.isMain) {
        // Reset isMain to false for other offices under same company
        Object.values(emAddresses).forEach(c => {
          if (c?.employeeId === employeeId && c?.id !== id) {
            newEmAddresses.push({ ...c, isMain: false })
          }
        })
      }

      // Update state together
      dispatch(replaceEmAddresses({ employeeId, data: newEmAddresses }))

      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
