import { EntityState } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { SSPayslipState, SSPayslipYearState } from './ss-payslip.state'
import { SSIrasFormViewState } from './ss-iras-form.state'

export const myPayrollPersistConfig = {
  key: 'mypayroll',
  whitelist: ['myPayslips', 'myPayslipYears', 'myIrasFormsView'],
  storage
}

export interface MyPayrollRootState {
  myPayslips: EntityState<SSPayslipState>
  myPayslipsLoading: boolean
  myPayslipYears: EntityState<SSPayslipYearState>
  myPayslipYearsLoading: boolean
  myIrasFormsView: SSIrasFormViewState
  myIrasFormsViewLoading: boolean
}
