import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchClaimRecordApprovalHistories } from '../actions'
import { selectClaimRecordApprovalHistories } from '../selectors'
import { ClaimRecordApprovalHistoryState } from '../types'

export const useClaimRecordApprovalHistories = (
  claimRecordId?: string
): [ClaimRecordApprovalHistoryState[], boolean] => {
  const claimRecordApprovalHistoriess = useSelector(selectClaimRecordApprovalHistories)(
    claimRecordId || ''
  ) as ClaimRecordApprovalHistoryState[]
  const loading = useSelector(
    (state: StoreState) => state.claim.claimRecordApprovalHistoriesLoading[claimRecordId || '']
  )

  useEffect(() => {
    if (claimRecordId) {
      dispatch(fetchClaimRecordApprovalHistories(claimRecordId))
    }
  }, [claimRecordId])

  return [claimRecordApprovalHistoriess, loading]
}
