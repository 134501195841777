import React, { FC, useEffect, useMemo, useState } from 'react'
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
// import { MicroApp } from '~/features/micro-app/components/MicroApp'
import { ZealysLoading } from '~/components'
import { useMyLogin } from '~/features/iam'
// import { fetchManifest } from '~/features/micro-app/actions/fetch-manifest'
import { StoreState } from '../types/store'

import { Callback } from '../auth/Callback'
import { Logout } from '../auth/Logout'
import { LogoutCallback } from '../auth/LogoutCallback'
import { PrivateRoute } from './PrivateRoute'
import { SilentRenew } from '../auth/SilentRenew'
import { Register } from '../auth/Register'
import { NoAccess } from '../auth/NoAccess'
import { AdminRoutes } from './AdminRoutes'
import { MyRoutes } from './SelfServiceRoutes'
import { EmptyRoutes } from './EmptyRoutes'
import { ADMIN_PATH, SELF_SERVICE_PATH, EMPTY_PATH, HELP_PATH, TENANT_PATH } from './routes'
import { IrasCallback } from '~/features/tax/containers/Iras/IrasCallback'
import { HelpRoutes } from './HelpRoutes'
import { TenantRoutes } from './TenantRoutes'

// const env: { [app: string]: string | undefined } = {
//   dashboard: process.env.REACT_APP_DASHBOARD_HOST,
//   employee: process.env.REACT_APP_EMPLOYEE_HOST
// }

export const publicRoutes: RouteProps[] = [
  {
    path: EMPTY_PATH,
    component: EmptyRoutes,
    exact: false
  },
  {
    path: HELP_PATH,
    component: HelpRoutes,
    exact: false
  },
  {
    path: TENANT_PATH,
    component: TenantRoutes,
    exact: false
  }
]

export const RenderRoutes: FC = () => {
  // const manifest = useSelector((state: StoreState) => state.microApp.manifests)
  const myJwt = useSelector((state: StoreState) => state.iam.myJwt)
  const tenant = localStorage.getItem('tenant') || ''
  const [defaultRoute, setDefaultRoute] = useState('/')

  const [myLogin] = useMyLogin()
  const tz = myLogin.timeZoneId

  useEffect(() => {
    if (tz) moment.tz.setDefault(tz)
  }, [tz])

  // Not in used
  // useEffect(() => {
  //   dispatch(fetchManifest())
  // }, [])

  const isAdmin = useMemo(() => myJwt?.isAdmin || [], [myJwt])
  const isEmployee = useMemo(() => myJwt?.isEmployee || [], [myJwt])
  const routes = useMemo(() => {
    const result: RouteProps[] = []

    if (!myJwt || isAdmin.includes(tenant)) {
      result.push({
        path: ADMIN_PATH,
        component: AdminRoutes,
        exact: false
      })
    }

    if (!myJwt || isEmployee.includes(tenant)) {
      result.push({
        path: SELF_SERVICE_PATH,
        component: MyRoutes,
        exact: false
      })
    }

    return result
  }, [tenant, isAdmin, isEmployee, myJwt])

  // for (const name in manifest) {
  //   const host = env[name] || ''
  //   routes = routes.concat(
  //     manifest[name].paths.map(path => ({ path, render: props => <MicroApp name={name} host={host} {...props} /> }))
  //   )
  // }

  useEffect(() => {
    if (myJwt) {
      if (myJwt.tenants.length > 1) {
        setDefaultRoute(TENANT_PATH)
      } else if ((myJwt.isAdmin || []).includes(tenant || '')) {
        setDefaultRoute(ADMIN_PATH)
      } else if ((myJwt.isEmployee || []).includes(tenant || '')) {
        setDefaultRoute(SELF_SERVICE_PATH)
      } else if (!myJwt.tenant) {
        setDefaultRoute('/noaccess')
      }
    }
  }, [myJwt, tenant])

  return (
    <Switch>
      <Route exact path="/signin-oidc" component={Callback} />
      <Route exact path="/logout" component={Logout} />
      <Route exact path="/logout/callback" component={LogoutCallback} />
      <Route exact path="/register" component={Register} />
      <Route exact path="/silentrenew" component={SilentRenew} />
      <Route exact path="/noaccess" component={NoAccess} />

      {/* Temporary route */}
      <PrivateRoute exact path="/tax/iras/callback" component={IrasCallback} />

      {defaultRoute === '/' ? (
        <PrivateRoute exact path="/" component={ZealysLoading} />
      ) : (
        <Redirect exact from="/" to={defaultRoute} />
      )}

      {routes.map(({ path, ...props }) => (
        <PrivateRoute key={path as string} exact path={path} {...props} />
      ))}

      {publicRoutes.map(({ path, ...props }) => (
        <Route key={path as string} exact path={path} {...props} />
      ))}
    </Switch>
  )
}
