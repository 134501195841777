import React, { forwardRef, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { Select, SelectProps } from '~/core-components'
import { fetchKeyvalues } from '~/features/master'
import { sortKeyValues } from '~/features/employee'
import { useFirstInView } from '~/hooks'
import { isInactive } from '~/utils/dateUtil'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { BasePath, FetchStrategy, KeyValue } from '~/types/common'
import { InactiveTag } from '../InactiveTag/InactiveTag'
import { DropdownActions } from './DropdownActions'

export interface KeyValuesProps extends SelectProps {
  id: string
  basePath: BasePath
  controller?: string
  queryParams?: any
  display?: 'value' | 'key' | 'keyvalue'
  renderItem?: (kv: KeyValue) => React.ReactNode
  labelProp?: (kv: KeyValue) => string
  sort?: 'value' | 'key'
  onFilter?: (value: KeyValue | undefined, index: number, array: (KeyValue | undefined)[]) => {}
  fetchStrategy?: FetchStrategy
  allowAll?: boolean
}

const AllowAll: KeyValue = { key: '*', value: 'All' }

const KeyValuesInternal = (
  {
    id,
    basePath,
    controller,
    queryParams = {},
    display = 'value',
    renderItem,
    labelProp,
    sort,
    onFilter,
    children,
    fetchStrategy = 'when-empty',
    allowAll = false,
    ...props
  }: KeyValuesProps,
  ref: React.Ref<any>
) => {
  const { ref: inViewRef, inView } = useFirstInView<HTMLDivElement>({ threshold: 0.25 })
  const data = useSelector((state: StoreState) => state.master.keyvalues[id]?.entities || undefined)
  const loading = useSelector((state: StoreState) => state.master.keyvaluesLoading[id])

  const options = useMemo(() => {
    if (!data) {
      return allowAll ? [AllowAll] : []
    }

    let result = (allowAll ? [...Object.values(data), AllowAll] : Object.values(data))
      .filter(a => {
        if (Array.isArray(props.value)) return !isInactive(a?.inactiveDate) || props.value.includes(a?.key)
        else return !isInactive(a?.inactiveDate) || a?.key === props.value
      })
      .sort((a, b) => sortKeyValues(a!, b!, sort))

    if (typeof onFilter === 'function') {
      result = result.filter(onFilter)
    }
    return result
  }, [data, sort, allowAll, props.value, onFilter])

  useDeepCompareEffect(() => {
    if (inView) {
      dispatch(fetchKeyvalues(id, basePath, controller, queryParams, { strategy: fetchStrategy }))
    }
  }, [inView, id, basePath, controller, queryParams, fetchStrategy])

  const handleRefresh = useCallback(() => {
    dispatch(fetchKeyvalues(id, basePath, controller, queryParams))
  }, [id, basePath, controller, queryParams])

  return (
    <div ref={inViewRef}>
      <Select
        ref={ref}
        showSearch
        optionFilterProp="title"
        loading={loading}
        dropdownRender={menu => (
          <div>
            {menu}
            <DropdownActions onRefresh={handleRefresh} />
          </div>
        )}
        {...props}
      >
        {options?.map(kv => {
          const keyDisplay = kv!.key.toUpperCase()
          let displayText = kv!.value
          if (display === 'key') displayText = keyDisplay
          else if (display === 'keyvalue') displayText = `${keyDisplay} - ${kv!.value}`

          return (
            <Select.Option
              key={kv!.key}
              value={kv!.key || ''}
              title={displayText}
              {...(labelProp ? { label: labelProp(kv!) } : {})}
            >
              {isInactive(kv!.inactiveDate) && <InactiveTag />}
              {renderItem == null ? <>{displayText}</> : kv && renderItem(kv)}
            </Select.Option>
          )
        })}
      </Select>
    </div>
  )
}

export const KeyValues = forwardRef(KeyValuesInternal)
