import React, { forwardRef } from 'react'
import classNames from 'classnames'
import { Alert } from '~/core-components'
import { Errors } from '~/types/store'
import './ErrorDisplay.less'

interface ErrorDisplayProps {
  keys?: string[]
  errors: Errors | undefined
}

const ErrorDisplayInternal = ({ keys = ['*'], errors }: ErrorDisplayProps, ref: React.Ref<any>) => {
  if (!errors) return null

  const filtered = Object.keys(errors).filter(key => keys.includes('*') || keys.includes(key))

  if (filtered.length === 0) return null

  const errorList = filtered.flatMap(key => errors[key])

  return (
    <div ref={ref}>
      <Alert
        className="error-display"
        type="error"
        message={
          <>
            {errorList.map((error: string, index) => (
              <div
                className={classNames('error-display__point', {
                  'error-display__point--multiple': errorList.length > 1
                })}
                key={index}
              >
                {error}
              </div>
            ))}
          </>
        }
      />
    </div>
  )
}

export const ErrorDisplay = forwardRef(ErrorDisplayInternal)
