import { setSysSelectionFields, setSysSelectionFieldsLoading } from '../reducers'
import { apiGetSelectionFields } from '../api/sys-selection.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'
import { Screen } from '../types'

export const fetchSysSelectionFields = (
  screenCode: Screen,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!screenCode) return

    const loading = getState().selection.sysSelectionFieldsLoading[screenCode]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = (getState().selection.sysSelectionFields[screenCode]?.ids?.length || 0) > 0
      if (hasData) return
    }

    try {
      dispatch(setSysSelectionFieldsLoading({ screenCode, loading: true }))
      const { result, status } = await apiGetSelectionFields(screenCode)
      if (status) {
        dispatch(setSysSelectionFields({ screenCode, data: result }))
      }
    } finally {
      dispatch(setSysSelectionFieldsLoading({ screenCode, loading: false }))
    }
  }
}
