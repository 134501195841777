import React, { FC, useEffect } from 'react'
import { Layout } from 'antd'
import { ErrorBoundary } from '~/components'
import { SelfServiceHeader } from './SelfServiceHeader/SelfServiceHeader'
import { SelfServiceSidebar } from './SelfServiceSidebar/SelfServiceSidebar'
import './SelfServiceLayout.less'

const { Content } = Layout

interface SelfServiceLayoutProps {}

export const SelfServiceLayout: FC<SelfServiceLayoutProps> = ({ children }) => {
  useEffect(() => {
    document.body.classList.remove('admin-layout')
    document.body.classList.add('self-service-layout')
  }, [])

  return (
    <Layout className="self-service-layout">
      <SelfServiceHeader />
      <Layout className="self-service-layout__main">
        <SelfServiceSidebar />
        <Content className="self-service-layout__content">
          <ErrorBoundary>{children}</ErrorBoundary>
        </Content>
      </Layout>
    </Layout>
  )
}
