import { setLeaveEntitlement, setLeaveEntitlementsLoading } from '../reducers'
import { apiGetCurrentLeaveEntitlement } from '../api/leave-entitlement.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchLeaveEntitlement = (
  employeeId: string,
  leaveTypeId: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!employeeId) return

    const loading = getState().leave.leaveEntitlementsLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const leaveEntitlements = Object.values(getState().leave.leaveEntitlements?.entities || {})
      const hasData = leaveEntitlements.find(le => le?.employeeId === employeeId && le?.leaveTypeId === leaveTypeId)
      if (hasData) return
    }

    try {
      dispatch(setLeaveEntitlementsLoading(true))
      const { result, status } = await apiGetCurrentLeaveEntitlement(employeeId, leaveTypeId)
      if (status) {
        dispatch(setLeaveEntitlement(result))
      }
    } finally {
      dispatch(setLeaveEntitlementsLoading(false))
    }
  }
}
