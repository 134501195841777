import { replaceEmLeaveBuddies, setEmLeaveBuddiesLoading } from '../reducers'
import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { IEmLeaveBuddy } from '../types'
import { apiUpdateLeaveBuddyEmByEmployee } from '~/features/leave/api/leave-buddy.api'

export const updateEmLeaveBuddies = (employeeId: string, data: IEmLeaveBuddy): ThunkResult<void> => {
  return async dispatch => {
    if (!employeeId) return

    try {
      dispatch(setEmLeaveBuddiesLoading({ employeeId, loading: true }))
      const { status, result, errors, message, errorData } = await apiUpdateLeaveBuddyEmByEmployee(employeeId, data)

      if (status) {
        dispatch(replaceEmLeaveBuddies({ employeeId, data: result }))
        showSuccess('Saved')
      } else {
        if (Object.keys(errors).length === 0) {
          showError(message, errorData)
        }
      }
      return { errors }
    } finally {
      dispatch(setEmLeaveBuddiesLoading({ employeeId, loading: false }))
    }
  }
}
