import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { apiUpdateBank } from '../api/bank.api'
import { BankState, IBank } from '../types'
import { setBank, setKeyvalue } from '../reducers'

export const updateBank = <T extends IBank>(code: string, before: T, request: T): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!code) return

    const after = { ...before, ...request } as T
    const patch = generateJsonPatch<T>(before, after)
    const { status, errors, message, errorData } = await apiUpdateBank(code, patch)

    if (status) {
      const bank = getState().master.banks?.entities[code]
      const updated = { ...(bank as BankState), ...after }
      dispatch(setBank(updated))
      dispatch(setKeyvalue({ kv: 'bank', data: { key: code, value: updated.name } }))

      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
