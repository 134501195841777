import { request, getBaseUrl } from '~/utils/request'
import { PayClaimMapState } from '../types'

const baseUrl = getBaseUrl('/payroll-sg')

export const apiGetPayClaimMaps = (payGroupId: string) =>
  request<PayClaimMapState[]>('get', `${baseUrl}/paygroup/${payGroupId}/payclaimmap`)

export const apiUpdatePayClaimMaps = (payGroupId: string, data: PayClaimMapState[]) =>
  request<PayClaimMapState[]>('post', `${baseUrl}/paygroup/${payGroupId}/payclaimmap`, data)
