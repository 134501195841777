import {
  refetchScheduleRecordsViewCell,
  refetchScheduleRecordsViewRow,
  setScheduleRecordsView,
  setScheduleRecordsViewLoading
} from '../reducers'
import { apiGetScheduleRecordsView } from '../api/schedule-record.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions, Pagination } from '~/types/common'

export const fetchScheduleRecordsView = (
  viewId: string,
  startDate: string,
  endDate: string,
  pagination: Pagination,
  search: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!viewId || !startDate || !endDate) return

    const loading = getState().schedule.scheduleRecordsViewLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().schedule.scheduleRecordsView?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setScheduleRecordsViewLoading(true))
      const { result, status, errors } = await apiGetScheduleRecordsView(viewId, startDate, endDate, {
        ...pagination,
        search
      })
      if (status) {
        dispatch(setScheduleRecordsView(result))
      }
      return { result, errors }
    } finally {
      dispatch(setScheduleRecordsViewLoading(false))
      dispatch(refetchScheduleRecordsViewRow(undefined))
      dispatch(refetchScheduleRecordsViewCell(undefined))
    }
  }
}
