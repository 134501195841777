import { Select as InternalSelect, SelectProps as InternalSelectProps } from './Select'
import { default as AntSelect } from 'antd/lib/select'

type TSelect = typeof InternalSelect
interface ISelect extends TSelect {
  Option: typeof AntSelect.Option
}

const Select = InternalSelect as ISelect
Select.Option = AntSelect.Option
export { Select }

export type SelectProps = InternalSelectProps
