import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { WfActionState } from '../types'
import { fetchWfActions } from '../actions'
import { selectWfActions } from '../selectors'

export const useWfActions = (wfEventId?: string): [WfActionState[], boolean] => {
  const wfActions = useSelector(selectWfActions)(wfEventId || '')
  const loading = useSelector((state: StoreState) => state.workflow.wfActionsLoading[wfEventId || ''] || false)

  useEffect(() => {
    dispatch(fetchWfActions(wfEventId))
  }, [wfEventId])

  return [wfActions, loading]
}
