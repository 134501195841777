import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchDailyRecordsByEmployeeDate } from '../actions'
import { DailyRecordState } from '../types'
import { FetchStrategy } from '~/types/common'
import { selectDailyRecordsByEmployeeDate } from '../selectors'

export const useDailyRecordsByEmployeeDate = (
  clockDate: string,
  employeeId: string,
  fetchStrategy: FetchStrategy = 'always'
): [DailyRecordState[], boolean] => {
  const data = useSelector(selectDailyRecordsByEmployeeDate)(clockDate, employeeId)
  const loading = useSelector((state: StoreState) => state.attendance.dailyRecordsLoading)
  const refetch = useSelector((state: StoreState) => state.attendance.dailyRecordsRefetch)

  useEffect(() => {
    dispatch(fetchDailyRecordsByEmployeeDate(clockDate, employeeId, { strategy: fetchStrategy }))
  }, [clockDate, employeeId, fetchStrategy, refetch])

  return [data, loading]
}
