import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiLeaveProcess } from '../api/leave-process.api'
import { setLeaveLoading } from '../reducers'
import { LeaveProcessDto } from '../types'
import { fetchLeavePeriodYears } from './fetch-leave-period-years'
import { fetchLeavePeriods } from './fetch-leave-periods'

export const processLeave = (data: LeaveProcessDto): ThunkResult<void> => {
  return async dispatch => {
    try {
      dispatch(setLeaveLoading(true))
      const { status, result, errors, message, errorData } = await apiLeaveProcess(data)

      if (status) {
        showSuccess('Process completed')
        dispatch(fetchLeavePeriods())
        dispatch(fetchLeavePeriodYears())
      } else {
        if (Object.keys(errors).length === 0) {
          showError(message, errorData)
        }
      }

      return { result, errors }
    } finally {
      dispatch(setLeaveLoading(false))
    }
  }
}
