import React, { CSSProperties, FC, useCallback, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Button, Card, CardTitle, ColumnsType, SecondaryText, Space, Table } from '~/core-components'
import { Screen, updateViewCriteria, useFirstView, ViewCriteria, ViewCriteriaSimple } from '~/features/selection'
import { usePermissionGate } from '~/features/iam'
import { dispatch } from '~/stores/store'
import { TAX_ROUTES } from '~/routes/routes'
import { Permission, PermissionAction } from '~/constants'
import { Ir21SubmissionState } from '../../types'
import { refetchIr21SubmissionViews } from '../../reducers'
import { useIr21SubmissionView } from '../../hooks'
// import { RecentResignees } from './RecentResignees/RecentResignees'
import { AddIr21SubmissionDrawer } from './AddIr21SubmissionDrawer'
import { Ir21Tags } from '../Ir21Submission/Ir21Tags'
import './Ir21Submissions.less'

interface Ir21SubmissionsProps {}

interface AddDrawerState {
  visible: boolean
}

const DEFAULT_ADD_DRAWER_STATE: AddDrawerState = { visible: false }
const PAGE_SIZE_OPTIONS = ['20', '50', '100']

const screenCode: Screen = 'ir21_submission'
const labelCompanyStyle: CSSProperties = { lineHeight: 1.1 }
const labelTaxNoStyle: CSSProperties = { fontSize: 12 }
const paginationStyle: CSSProperties = { marginRight: 20 }

export const Ir21Submissions: FC<Ir21SubmissionsProps> = () => {
  const [view, viewLoading] = useFirstView(screenCode)
  const viewId = view?.id || ''
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(20)
  const [data, dataLoading] = useIr21SubmissionView(viewId, page, pageSize, 'always')

  const [addDrawerState, setAddDrawerState] = useState<AddDrawerState>(DEFAULT_ADD_DRAWER_STATE)
  const canAdd = usePermissionGate(Permission.ytd, PermissionAction.Add)

  const columns: ColumnsType<Ir21SubmissionState> = [
    {
      title: 'Year',
      key: 'ytdYear',
      dataIndex: 'ytdYear',
      width: 80,
      render: (value: string, record) => (
        <RouterLink to={TAX_ROUTES.ir21Submission.replace(':id', record.id).replace(':tab?', 'ir21')}>
          {value}
        </RouterLink>
      )
    },
    {
      title: 'Company',
      key: 'companyName',
      dataIndex: 'companyName',
      render: (value: string, record) => (
        <>
          <div style={labelCompanyStyle}>{value}</div>
          <SecondaryText style={labelTaxNoStyle}>{record.employerTaxNo}</SecondaryText>
        </>
      )
    },
    {
      key: 'status',
      dataIndex: 'status',
      width: 150,
      render: (value: string, record) => <Ir21Tags ir21Submission={record} />
    }
  ]

  const handlePaginationChange = useCallback((page: number, pageSize?: number) => {
    setPage(page)
    setPageSize(pageSize || 20)
  }, [])

  const handleCriteriaApply = useCallback(
    async (criteria: ViewCriteria[]) => {
      if (viewId) {
        setPage(1)
        await dispatch(updateViewCriteria(screenCode, viewId, { id: viewId, criteria }))
        dispatch(refetchIr21SubmissionViews())
      }
    },
    [viewId]
  )

  const handleAddClick = useCallback(() => {
    setAddDrawerState({ visible: true })
  }, [])

  const handleCloseAddDrawer = useCallback(() => {
    setAddDrawerState(DEFAULT_ADD_DRAWER_STATE)
  }, [])

  return (
    <Card className="ir21-submissions" table>
      {/* <RecentResignees /> */}

      <CardTitle>IR21 submissions</CardTitle>
      <div className="ir21-submissions__action-bar">
        <ViewCriteriaSimple screenCode={screenCode} viewId={viewId} onApply={handleCriteriaApply} label="" />
        <Space align="start">{canAdd && <Button onClick={handleAddClick}>Create new submission</Button>}</Space>
      </div>
      <Table
        rowKey="id"
        dataSource={data?.data}
        columns={columns}
        fitParent
        loading={dataLoading || viewLoading}
        pagination={{
          total: data?.count,
          current: page,
          pageSize,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
          showSizeChanger: true,
          onChange: handlePaginationChange,
          style: paginationStyle
        }}
      />
      {canAdd && <AddIr21SubmissionDrawer {...addDrawerState} onClose={handleCloseAddDrawer} />}
    </Card>
  )
}
