import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { generateJsonPatch } from '~/utils'
import { apiUpdateShiftRoleTe } from '../api/shift-role-te.api'
import { ShiftRoleTeState, IShiftRoleTeInfo } from '../types'
import { setShiftRoleTe } from '../reducers'

export const updateShiftRoleTe = (
  shiftRoleId: string,
  id: string,
  before: IShiftRoleTeInfo,
  request: IShiftRoleTeInfo
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!shiftRoleId || !id) return

    const after = { ...before, ...request } as IShiftRoleTeInfo
    const patch = generateJsonPatch<IShiftRoleTeInfo>(before, after)
    const { status, errors, message, errorData } = await apiUpdateShiftRoleTe(shiftRoleId, id, patch)

    if (status) {
      const shiftRoleTe = getState().attendance.shiftRoleTes[shiftRoleId]?.entities[id] || {}
      dispatch(
        setShiftRoleTe({
          shiftRoleId,
          data: {
            ...(shiftRoleTe as ShiftRoleTeState),
            ...after,
            id
          }
        })
      )

      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
