import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import { Input, SearchProps } from '~/core-components'
import './SearchInput.less'

export interface SearchInputProps extends SearchProps {
  defaultValue?: string
  expandWidth?: number
}

interface FormData {
  search?: string
}

const DEFAULT_SEARCH_PROPS: any = { placeholder: '', style: { width: 80 }, allowClear: false }
const EMPTY_FORM_DATA: FormData = { search: undefined }

export const SearchInput: FC<SearchInputProps> = ({ defaultValue, placeholder, expandWidth = 220, ...props }) => {
  const [formData, setFormData] = useState<FormData>({ search: defaultValue, ...EMPTY_FORM_DATA })
  const [searchProps, setSearchProps] = useState(DEFAULT_SEARCH_PROPS)

  const handleSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.persist()
      setFormData({ ...formData, search: event.target.value })
    },
    [formData]
  )

  const handleSearchFocus = useCallback(() => {
    setSearchProps({ placeholder: placeholder || 'Search by name', style: { width: expandWidth }, allowClear: true })
  }, [placeholder, expandWidth])

  const handleSearchBlur = useCallback(() => {
    if (!formData.search) {
      setSearchProps(DEFAULT_SEARCH_PROPS)
    }
  }, [formData.search])

  useEffect(() => {
    setFormData(formData => {
      if (!!defaultValue) {
        handleSearchFocus()
        return { ...formData, search: defaultValue }
      } else {
        handleSearchBlur()
        return formData
      }
    })
  }, [defaultValue, handleSearchFocus, handleSearchBlur])

  return (
    <Input.Search
      {...searchProps}
      className="search-input"
      value={formData.search}
      onChange={handleSearchChange}
      onFocus={handleSearchFocus}
      onBlur={handleSearchBlur}
      {...props}
    />
  )
}
