import React, { FC, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { ZealysLoading } from '~/components'
import { dispatch } from '~/stores/store'
import { useQuery } from '~/hooks/use-query'
import { TAX_ROUTES } from '~/routes/routes'
import { showError } from '~/utils'
import { apiGetCorpPassToken } from '../../api/ytd.api'
import { setIrasState, setIrasSubmissionId } from '../../reducers'
import { useSelector } from 'react-redux'
import { StoreState } from '~/types/store'

interface IrasCallbackProps {}

export const IrasCallback: FC<IrasCallbackProps> = () => {
  const query = useQuery()
  const history = useHistory()
  const historyPush = history.push
  const code = query.get('code')
  const state = query.get('state')
  const ytdSubmissionId = useSelector((state: StoreState) => state.tax.irasSubmissionId)

  useEffect(() => {
    const retrieveIrasToken = async () => {
      console.log('Retrieving IRAS token', code, state)
      if (code && state) {
        await dispatch(setIrasState(state))
        const { result, status, message } = await apiGetCorpPassToken(code, state)
        console.log('Corppass token', result)
        if (status && result) {
          historyPush(
            `${TAX_ROUTES.submission.replace(':id', ytdSubmissionId).replace(':tab?', 'submission')}?action=1`
          )
          dispatch(setIrasSubmissionId(''))
        } else {
          showError(message || 'Error occurred when getting access token from IRAS')
        }
      }
    }

    retrieveIrasToken()
  }, [code, state, ytdSubmissionId, historyPush])

  return <ZealysLoading />
}
