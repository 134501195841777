import React, { FC, useCallback, useState } from 'react'
import { Button } from '~/core-components'
import {
  refetchClaimEntitlementRecords,
  refetchClaimEntitlements,
  refetchClaimEntitlementsView,
  refetchClaimRecordsView
} from '~/features/claim/reducers'
import { dispatch } from '~/stores/store'
import { MutateClaimRecordDrawer } from './MutateClaimRecordDrawer'

interface AddClaimRecordButtonProps {
  employeeId?: string
}

interface DrawerState {
  visible: boolean
  employeeId?: string
  id?: string
  editing?: boolean
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false, editing: false, employeeId: '' }

export const AddClaimRecordButton: FC<AddClaimRecordButtonProps> = ({ employeeId }) => {
  const [addDrawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)

  const handleAddClick = useCallback(() => {
    setDrawerState({ visible: true, editing: true, employeeId })
  }, [employeeId])

  const handleCloseDrawer = useCallback((action: 'saved' | 'cancelled') => {
    setDrawerState(DEFAULT_DRAWER_STATE)
    if (action === 'saved') {
      dispatch(refetchClaimEntitlementsView())
      dispatch(refetchClaimRecordsView())
      dispatch(refetchClaimEntitlements())
      dispatch(refetchClaimEntitlementRecords())
    }
  }, [])

  return (
    <>
      <Button onClick={handleAddClick}>Add claim record</Button>
      <MutateClaimRecordDrawer {...addDrawerState} onClose={handleCloseDrawer} />
    </>
  )
}
