import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { LeEarnedState } from '../types'

export const selectLeEarned = createSelector(
  (state: StoreState) => state.leave.leEarned,
  leEarnedState =>
    memoize(
      (periodCode?: string, employeeId?: string, leaveTypeId?: string): LeEarnedState[] => {
        const entId = `${periodCode}|${employeeId}|${leaveTypeId}`

        return Object.values(leEarnedState[entId]?.entities || {}).sort((a, b) => {
          const aStartDate = new Date(a!.startDate).getTime()
          const bStartDate = new Date(b!.startDate).getTime()
          return bStartDate - aStartDate
        }) as LeEarnedState[]
      },
      (periodCode?: string, employeeId?: string, leaveTypeId?: string) => `${periodCode}|${employeeId}|${leaveTypeId}`
    )
)
