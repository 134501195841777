import React, { FC, useState, useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Form } from '~/core-components'
import { DrawerForm } from '~/components'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors } from '~/types/store'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { addShift } from '../../../actions'
import { ShiftInfoState } from '../../../types'
import { EMPTY_SHIFT_INFO_FORM_DATA, ShiftInfoForm } from './ShiftInfoForm'

export interface AddShiftDrawerProps {
  visible: boolean
  onClose: () => void
}

export const AddShiftDrawer: FC<AddShiftDrawerProps> = ({ visible, onClose }: AddShiftDrawerProps) => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<ShiftInfoState>(EMPTY_SHIFT_INFO_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()

  useEffect(() => {
    setErrors(undefined)
  }, [visible])

  useEffect(() => {
    setFormData(EMPTY_SHIFT_INFO_FORM_DATA)
  }, [])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      result = await dispatch(addShift(formData))
    } finally {
      setLoading(false)
    }
    if (result?.errors) {
      setErrors(result.errors)
    }
    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_SHIFT_INFO_FORM_DATA)

      if (result?.result?.id) {
        const shiftId = result.result.id
        history.push(`${SETTINGS_ROUTES.shift.replace(':id', shiftId)}`)
      }
    }
  }, [formData, onClose, history])

  return (
    <DrawerForm
      open={visible}
      title="Add shift"
      onClose={onClose}
      confirmLoading={loading}
      width={500}
      formId="form-add-shift"
    >
      <Form id="form-add-shift" onFinish={handleOk}>
        <ShiftInfoForm data={formData} errors={errors} onChange={handleFormDataChange} />
      </Form>
    </DrawerForm>
  )
}
