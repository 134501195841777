import React, { FC, useState, useCallback, useEffect, ChangeEvent, memo, CSSProperties } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import confirm from 'antd/lib/modal/confirm'
import { Button, Checkbox, CheckboxChangeEvent, Form, Input, Space, Tag, UploadFile } from '~/core-components'
import { Col, DrawerForm, EmpKeyValues, Row, UploadFileSelectorAuth } from '~/components'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors } from '~/types/store'
import { useFocus } from '~/hooks/use-focus'
import { IEmAddressRequest, SSEmAddressPublicSelfState } from '~/features/my/types'
import { emptyGuid } from '~/constants'
import { applyEmAddressRequest, deleteEmAddressRequest } from '~/features/my/actions'
import { IAddress, formatAddress, selectKeyvaluesById } from '~/features/master'
import { getBaseUrl } from '~/utils'

export interface MutateMyAddressDrawerProps {
  visible: boolean
  employeeId?: string
  data?: SSEmAddressPublicSelfState
  onClose: (action: 'saved' | 'cancelled') => void
}

const PendingTag = memo(() => <Tag type="secondary">pending</Tag>)

const EMPTY_FORM_DATA: IEmAddressRequest = {
  id: emptyGuid,
  employeeId: '',
  emAddressId: emptyGuid,
  isMain: false,
  startDate: moment().format('YYYY-MM-DD'),
  block: '',
  street: '',
  level: '',
  unit: '',
  city: '',
  state: '',
  postalCode: '',
  countryCode: ''
}

const currenTextStyle: CSSProperties = { fontSize: 13, color: '#797979' }
const checkboxStyle: CSSProperties = { marginLeft: 10 }
const inputLevelStyle: CSSProperties = { width: '40%', marginRight: -1 }
const inputUnitStyle: CSSProperties = { width: '60%' }
const baseUrl = getBaseUrl('/employee')

export const MutateMyAddressDrawer: FC<MutateMyAddressDrawerProps> = ({
  visible,
  employeeId,
  data,
  onClose
}: MutateMyAddressDrawerProps) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<IEmAddressRequest>(EMPTY_FORM_DATA)
  const [focusRef, setFocus] = useFocus(true)
  const [errors, setErrors] = useState<Errors>()
  const countries = useSelector(selectKeyvaluesById)('country')

  const isNew = data?.recordStatus === 'new_request'
  const isDiffIsMain = !isNew && data?.isMain !== data?.isMainNew
  const isDiffStartDate = !isNew && data?.startDate !== data?.startDateNew
  const isDiffBlock = !isNew && data?.block !== data?.blockNew
  const isDiffStreet = !isNew && data?.street !== data?.streetNew
  const isDiffLevel = !isNew && data?.level !== data?.levelNew
  const isDiffUnit = !isNew && data?.unit !== data?.unitNew
  const isDiffCity = !isNew && data?.city !== data?.cityNew
  const isDiffState = !isNew && data?.state !== data?.stateNew
  const isDiffPostalCode = !isNew && data?.postalCode !== data?.postalCodeNew
  const isDiffCountryCode = !isNew && data?.countryCode !== data?.countryCodeNew

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  useEffect(() => {
    if (data) {
      let attachment: UploadFile | undefined
      if (data.attachmentId) {
        attachment = {
          uid: data.attachmentId,
          name: data.attachmentName || '',
          size: data.attachmentSize || 0,
          type: data.attachmentFormat || '',
          url: `${baseUrl}/ssemployeerequest/address/${data.requestId}/downloadfile`
        }
      }

      setFormData({
        id: data.requestId || emptyGuid,
        employeeId: employeeId || emptyGuid,
        emAddressId: data.id,
        isMain: data.isMainNew,
        startDate: data.startDateNew,
        block: data.blockNew,
        street: data.streetNew,
        level: data.levelNew,
        unit: data.unitNew,
        city: data.cityNew,
        state: data.stateNew,
        postalCode: data.postalCodeNew,
        countryCode: data.countryCodeNew,
        attachment
      })
    } else {
      setFormData({ ...EMPTY_FORM_DATA, employeeId: employeeId || emptyGuid })
    }
  }, [data, employeeId])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => setFormData(formData => ({ ...formData, ...updates })),
    []
  )

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      result = await dispatch(applyEmAddressRequest(formData))
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose('saved')
      setFormData(EMPTY_FORM_DATA)
    }
  }, [formData, onClose])

  const handleDelete = useCallback(
    (myAddress: SSEmAddressPublicSelfState | undefined) => {
      if (employeeId && myAddress) {
        const { requestId, blockNew, streetNew, levelNew, unitNew, cityNew, stateNew, postalCodeNew, countryCodeNew } =
          myAddress
        const countryName = countries[countryCodeNew || '']?.value || ''
        const address = formatAddress(
          {
            block: blockNew,
            street: streetNew,
            level: levelNew,
            unit: unitNew,
            building: '',
            city: cityNew,
            state: stateNew,
            postalCode: postalCodeNew,
            countryCode: countryCodeNew
          } as IAddress,
          countryName
        )
        confirm({
          title: 'Delete address',
          content: `Do you want to delete address "${address.addressLine1}"?`,
          onOk: async () => {
            const result: ActionResult | undefined = await dispatch(deleteEmAddressRequest(requestId))
            if (result?.errors) {
              setErrors(result.errors)
            }

            if (!result?.errors) {
              typeof onClose === 'function' && onClose('saved')
            }
          },
          okText: 'Delete',
          okType: 'danger'
        })
      }
    },
    [employeeId, onClose, countries]
  )

  const handleFileChange = useCallback(
    (attachments?: UploadFile[]) => {
      if (attachments && attachments.length > 0) {
        setFormData({ ...formData, attachment: attachments[0] })
      }
    },
    [formData]
  )

  const handleFileRemove = useCallback(
    (file?: UploadFile) => {
      if (file) {
        setFormData({ ...formData, attachment: undefined, deletedAttachmentId: file.uid })
      }
    },
    [formData]
  )

  return (
    <DrawerForm
      open={visible}
      title={data ? <Space>Edit address {isNew && <Tag type="secondary">new (pending)</Tag>}</Space> : 'Add address'}
      okText={data?.recordStatus === 'has_request' ? 'Resubmit' : 'Submit'}
      onClose={() => onClose('cancelled')}
      confirmLoading={loading}
      width={700}
      showDelete={data ? (data?.recordStatus !== 'no_request' ? true : false) : false}
      onDelete={() => handleDelete(data)}
      formId="form-em-address"
    >
      <Form id="form-em-address" onFinish={handleOk}>
        <Row>
          <Col flex="160px">
            <Form.Item
              label={<Space>Effective date {isDiffStartDate && <PendingTag />}</Space>}
              validateStatus={errors?.startDate ? 'error' : ''}
              help={errors?.startDate}
            >
              <Input.Date
                ref={focusRef}
                value={formData.startDate ? moment(formData.startDate) : null}
                onChange={(value: moment.Moment | null) =>
                  handleFormDataChange({ startDate: value?.format('YYYY-MM-DD') || '' })
                }
              />
              {isDiffStartDate && (
                <div style={currenTextStyle}>
                  Current: {data?.startDate ? moment(data?.startDate).format('DD MMM YYYY') : '-'}
                </div>
              )}
            </Form.Item>
          </Col>
          <Col flex={1}>
            <Form.Item label=" ">
              <Checkbox
                checked={formData.isMain}
                onChange={(event: CheckboxChangeEvent) => handleFormDataChange({ isMain: event.target.checked })}
                style={checkboxStyle}
              >
                {<Space>Main address {isDiffIsMain && <PendingTag />}</Space>}
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={15}>
          <Col span={5}>
            <Form.Item
              label={<Space>Block no. {isDiffBlock && <PendingTag />}</Space>}
              validateStatus={errors?.block ? 'error' : ''}
              help={errors?.block}
            >
              <Input
                value={formData.block}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ block: event.target.value })}
              />
              {isDiffBlock && <Space style={currenTextStyle}>Current: {data?.block || '-'}</Space>}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={<Space>Street name {isDiffStreet && <PendingTag />}</Space>}
              validateStatus={errors?.street ? 'error' : ''}
              help={errors?.street}
            >
              <Input
                value={formData.street}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleFormDataChange({ street: event.target.value })
                }
              />
              {isDiffStreet && <Space style={currenTextStyle}>Current: {data?.street || '-'}</Space>}
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              label={<Space>Floor - Unit no. {(isDiffLevel || isDiffUnit) && <PendingTag />}</Space>}
              validateStatus={errors?.level || errors?.unit ? 'error' : ''}
              help={(errors?.level, errors?.unit)}
            >
              <Input.Group>
                <Input
                  style={inputLevelStyle}
                  value={formData.level}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleFormDataChange({ level: event.target.value })
                  }
                />
                <Input
                  addonBefore="-"
                  style={inputUnitStyle}
                  value={formData.unit}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleFormDataChange({ unit: event.target.value })
                  }
                />
              </Input.Group>
              {(isDiffLevel || isDiffUnit) && (
                <Space style={currenTextStyle}>
                  Current: {data?.level || '-'} {data?.unit || '-'}
                </Space>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col span={12}>
            <Form.Item
              label={<Space>City {isDiffCity && <PendingTag />}</Space>}
              validateStatus={errors?.city ? 'error' : ''}
              help={errors?.city}
            >
              <Input
                value={formData.city}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ city: event.target.value })}
              />
              {isDiffCity && <Space style={currenTextStyle}>Current: {data?.city || '-'}</Space>}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={<Space>State {isDiffState && <PendingTag />}</Space>}
              validateStatus={errors?.state ? 'error' : ''}
              help={errors?.state}
            >
              <Input
                value={formData.state}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ state: event.target.value })}
              />
              {isDiffState && <Space style={currenTextStyle}>Current: {data?.state || '-'}</Space>}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col span={12}>
            <Form.Item
              label={<Space>Postal code {isDiffPostalCode && <PendingTag />}</Space>}
              validateStatus={errors?.postalCode ? 'error' : ''}
              help={errors?.postalCode}
            >
              <Input
                value={formData.postalCode}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleFormDataChange({ postalCode: event.target.value })
                }
              />
              {isDiffPostalCode && <Space style={currenTextStyle}>Current: {data?.postalCode || '-'}</Space>}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={<Space>Country {isDiffCountryCode && <PendingTag />}</Space>}
              validateStatus={errors?.countryCode ? 'error' : ''}
              help={errors?.countryCode}
            >
              <EmpKeyValues
                id="country"
                value={formData.countryCode}
                onChange={(value: string) => handleFormDataChange({ countryCode: value || '' })}
              />
              {isDiffCountryCode && (
                <Space style={currenTextStyle}>Current: {countries[data?.countryCode || '']?.value || '-'}</Space>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Attachment" validateStatus={errors?.attachment ? 'error' : ''} help={errors?.attachment}>
              <UploadFileSelectorAuth
                multiple={false}
                accept=".gif,.png,.jpeg,.jpg"
                fileList={formData.attachment ? [formData.attachment] : []}
                listType="picture-card"
                onChange={handleFileChange}
                onRemove={handleFileRemove}
                withCache={false}
              >
                <Button size="small">choose file</Button>
              </UploadFileSelectorAuth>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}
