import { showSuccess, showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import pick from 'lodash/pick'
import { apiAddCompanyNewOffice } from '../api/company.api'
import { CompanyOfficeState, ICompanyOfficeInfo, OfficeState } from '../types'
import { setCompanyOffices, setOffice } from '../reducers'
import { formatAddress } from '../util'

export const addCompanyNewOffice = (companyId: string, request: ICompanyOfficeInfo): ThunkResult<void> => {
  return async (dispatch, getState): Promise<ActionResult | undefined> => {
    if (!companyId) return

    const { status, result, errors, message, errorData } = await apiAddCompanyNewOffice(companyId, request)
    if (status) {
      const newOfficeUpdates = pick(request, [
        'name',
        'block',
        'street',
        'level',
        'unit',
        'building',
        'city',
        'state',
        'postalCode',
        'countryCode',
        'phone',
        'holidayId',
        'inactiveDate'
      ])

      const countries = getState().master.keyvalues['country'].entities
      const countryName = countries[newOfficeUpdates.countryCode || '']?.value || ''
      const newOffice: OfficeState = {
        id: result.officeId,
        ...newOfficeUpdates,
        ...formatAddress(newOfficeUpdates, countryName)
      }

      // The "office" update
      dispatch(setOffice(newOffice))

      let newCompanyOffices: CompanyOfficeState[] = []

      let companyOffices = getState().master.companyOffices?.entities || {}
      const newCompanyOfficeUpdates = pick(request, ['isMain'])
      const newCompanyOffice: CompanyOfficeState = {
        ...(companyOffices[result.id] as CompanyOfficeState),
        ...newCompanyOfficeUpdates,
        id: result.id,
        companyId,
        officeId: result.officeId
      }

      // The "company office" update
      newCompanyOffices.push(newCompanyOffice)

      if (request.isMain) {
        // Reset isMain to false for other offices under same company
        Object.values(companyOffices).forEach(c => {
          if (c?.companyId === companyId) {
            newCompanyOffices.push({ ...c, isMain: false })
          }
        })
      }

      // Update state together
      dispatch(setCompanyOffices(newCompanyOffices))
      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
