import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchLeavePeriodYears } from '../actions'
import { selectLeavePeriodYears } from '../selectors'
import { LeavePeriodYearState } from '../types'

export const useLeavePeriodYears = (): [LeavePeriodYearState[], boolean] => {
  const leavePeriodYears = useSelector(selectLeavePeriodYears)
  const loading = useSelector((state: StoreState) => state.leave.leavePeriodYearsLoading)

  useEffect(() => {
    dispatch(fetchLeavePeriodYears())
  }, [])

  return [leavePeriodYears, loading]
}
