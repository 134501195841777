import React, { FC, useCallback, useEffect, useState } from 'react'
import { EditableCard, EditableCardState } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { ActionResult, Errors } from '~/types/store'
import { Permission, PermissionAction } from '~/constants'
import { dispatch } from '~/stores/store'
import { updateShiftRole } from '../../../actions'
import { WorkCalendarState, ShiftRoleInfoState } from '../../../types'
import { ShiftRoleInfoForm, EMPTY_SHIFT_ROLE_INFO_FORM_DATA } from './ShiftRoleInfoForm'

interface ShiftRoleInfoProps {
  shiftRole?: WorkCalendarState
  onEdit?: () => void
  onSave?: () => void
  onCancel?: () => void
}

interface FormData extends ShiftRoleInfoState {}

export const ShiftRoleInfo: FC<ShiftRoleInfoProps> = ({ shiftRole, onEdit, onSave, onCancel }) => {
  const [cardState, setCardState] = useState<EditableCardState>()
  const [formData, setFormData] = useState<FormData>(EMPTY_SHIFT_ROLE_INFO_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const canModify = usePermissionGate(Permission.shiftRole, PermissionAction.Modify)
  const readOnly = cardState !== 'editing' && cardState !== 'saving'

  useEffect(() => {
    if (shiftRole) {
      const { name, inactiveDate } = shiftRole
      setFormData({ name, inactiveDate })
    } else {
      setFormData(EMPTY_SHIFT_ROLE_INFO_FORM_DATA)
    }
  }, [shiftRole])

  const handleEdit = useCallback(() => {
    setCardState('editing')
    typeof onEdit === 'function' && onEdit()
  }, [onEdit])

  const handleSave = useCallback(async () => {
    if (shiftRole) {
      setCardState('saving')
      setErrors(undefined)

      typeof onSave === 'function' && onSave()

      let result: ActionResult | undefined
      try {
        result = await dispatch(updateShiftRole(shiftRole.id, shiftRole, formData))
      } catch {
        setCardState('editing')
      }

      if (result?.errors) {
        setCardState('editing')
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setCardState(undefined)
      }
    }
  }, [shiftRole, formData, onSave])

  const handleCancel = useCallback(() => {
    typeof onCancel === 'function' && onCancel()
    setCardState(undefined)
    setErrors(undefined)

    if (shiftRole) {
      const { name, inactiveDate } = shiftRole
      setFormData({ name, inactiveDate })
    }
  }, [shiftRole, onCancel])

  return (
    <EditableCard
      title="Shift role information"
      bodyStyle={{ paddingBottom: shiftRole ? 6 : 24, paddingTop: 6 }}
      state={!canModify ? 'readonly' : cardState}
      formId="form-shift-role-info"
      onEdit={handleEdit}
      onSave={handleSave}
      onCancel={handleCancel}
    >
      <ShiftRoleInfoForm
        data={formData}
        errors={errors}
        readOnly={readOnly}
        onChange={data => setFormData({ ...formData, ...data })}
      />
    </EditableCard>
  )
}
