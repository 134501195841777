import { replacePayTranImport, setPayTranImportLoading } from '../reducers'
import { apiGetPayTranImportByBatchId } from '../api/paytran-import.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchPayTranImportByBatchId = (
  batchId: string,
  employeeId: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!batchId) return
    if (!employeeId) return

    const emBatchId = `${employeeId}||${batchId}`

    const loading = getState().payroll.payTranImportsLoading[emBatchId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().payroll.payTranImports[emBatchId]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setPayTranImportLoading({ emBatchId, loading: true }))
      const { result, status } = await apiGetPayTranImportByBatchId(batchId, employeeId)
      if (status) {
        dispatch(replacePayTranImport({ emBatchId, data: result }))
      }
    } finally {
      dispatch(setPayTranImportLoading({ emBatchId, loading: false }))
    }
  }
}
