import React, { FC } from 'react'
import classNames from 'classnames'
import { LoadingOutlined } from '@ant-design/icons'
import { Card, CardProps, Spin } from '~/core-components'
import './CardBanner.less'

export interface CardBannerProps extends CardProps {
  image: string
}

export const CardBanner: FC<CardBannerProps> = ({ title, image, className, children, loading, ...props }) => {
  const classes = classNames('card-banner', { [`${className}`]: className })

  return (
    <Card
      className={classes}
      cover={
        <div className="card-banner__image">
          <img alt="Banner" src={image} />
          {loading && <Spin indicator={<LoadingOutlined spin />} />}
        </div>
      }
      {...props}
    >
      <div className="card-banner__title">{title}</div>
      {children}
    </Card>
  )
}
