import React, { FC, useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { Form, Tab, TabTitle, Tabs } from '~/core-components'
import { DocumentTitle, SSRoleKeyValues } from '~/components'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { ATT_ROUTES } from '~/routes/routes'
import { SsRole, SSRoleScreen } from '~/constants'
import { ROScheduleRecords } from '../ROScheduleRecords/ROScheduleRecords'
import { refetchROScheduleRecordsView } from '../../reducers'
import './ROScheduleOverview.less'

export const ROScheduleOverview: FC = () => {
  const match = useRouteMatch<{ tab: string }>()
  const history = useHistory()
  const me = useSelector((state: StoreState) => state.my.myEmployee)
  const canViewRecord = [SsRole.ReportingManager, SsRole.MgrAttSchedule].some(ro => me.roles.includes(ro))
  const defaultActiveTab = canViewRecord ? SSRoleScreen.Schedule : ''
  const activeTab = (match.params.tab || defaultActiveTab) as SSRoleScreen

  const getFirstSsRole = useCallback(
    (activeTab: SSRoleScreen) => {
      const firstRole = me.roles.find(
        role =>
          role === SsRole.ReportingManager || (role === SsRole.MgrAttSchedule && activeTab === SSRoleScreen.Schedule)
      ) as SsRole
      return firstRole
    },
    [me.roles]
  )

  const firstSsRole = getFirstSsRole(activeTab)
  const [role, setRole] = useState<SsRole>(firstSsRole)

  const handleTabChange = useCallback(
    (activeKey: string) => {
      const firstRole = getFirstSsRole(activeKey as SSRoleScreen)
      setRole(role => {
        if (role !== firstRole) {
          return firstRole
        } else {
          if (activeKey === SSRoleScreen.Schedule) {
            dispatch(refetchROScheduleRecordsView())
          }

          return role
        }
      })

      history.push(`${ATT_ROUTES.tab.replace(':tab?', activeKey)}`)
    },
    [getFirstSsRole, history]
  )

  const tabItems = useMemo(() => {
    const result: Tab[] = []
    if (canViewRecord) {
      result.push({ key: SSRoleScreen.Schedule, label: 'Records', children: <ROScheduleRecords role={role} /> })
    }

    return result
  }, [role, canViewRecord])

  return (
    <div className="ro-schedule-overview">
      <DocumentTitle title="Schedule Overview" />
      <div className="ro-schedule-overview__body">
        <Tabs
          defaultActiveKey={activeTab}
          activeKey={activeTab}
          onChange={handleTabChange}
          tabBarExtraContent={{
            left: <TabTitle title="Schedule overview" />,
            right: (
              <Form.Item label="View as">
                <SSRoleKeyValues screenCode={activeTab} value={role} onChange={setRole} size="small" />
              </Form.Item>
            )
          }}
          items={tabItems}
        />
      </div>
    </div>
  )
}
