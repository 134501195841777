import React, { ChangeEvent, CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { Form, Input } from '~/core-components'
import { Col, EditableCard, EditableCardState, Row } from '~/components'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { useFocus } from '~/hooks/use-focus'
import { dispatch } from '~/stores/store'
import { Errors, ActionResult } from '~/types/store'
import { IEmployeeAttendance, EmployeeState } from '../../../types'
import { mapEmployeeStateToEmployeeAttendance } from '../../../types/employee.mapper'
import { updateEmployeeAttendance } from '../../../actions'

interface EmployeeAttendanceProps {
  employee?: EmployeeState
  onEdit?: () => void
  onSave?: () => void
  onCancel?: () => void
}

const EMPTY_FORM_DATA: IEmployeeAttendance = {
  badgeNo: ''
}

const cardStyle: CSSProperties = { margin: 24 }

export const EmployeeAttendance: FC<EmployeeAttendanceProps> = ({
  employee,
  onEdit,
  onSave,
  onCancel
}: EmployeeAttendanceProps) => {
  const [cardState, setCardState] = useState<EditableCardState>()
  const [formData, setFormData] = useState<IEmployeeAttendance>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const [focusRef] = useFocus(cardState === 'editing')
  const canView = usePermissionGate(Permission.employeeAttendance)
  const canModify = usePermissionGate(Permission.employeeAttendance, PermissionAction.Modify)

  useEffect(() => {
    if (employee) {
      const { badgeNo } = employee
      setFormData({ badgeNo })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [employee])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => setFormData(formData => ({ ...formData, ...updates })),
    []
  )

  const handleEdit = useCallback(() => {
    setCardState('editing')
    typeof onEdit === 'function' && onEdit()
  }, [onEdit])

  const handleSave = useCallback(async () => {
    if (employee) {
      setCardState('saving')
      setErrors(undefined)

      typeof onSave === 'function' && onSave()

      let result: ActionResult | undefined
      try {
        result = await dispatch(
          updateEmployeeAttendance(employee.id, mapEmployeeStateToEmployeeAttendance(employee), formData)
        )
      } catch {
        setCardState('editing')
      }

      if (result?.errors) {
        setCardState('editing')
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setCardState(undefined)
      }
    }
  }, [employee, formData, onSave])

  const handleCancel = useCallback(() => {
    typeof onCancel === 'function' && onCancel()
    setCardState(undefined)
    setErrors(undefined)

    if (employee) {
      const { badgeNo } = employee
      setFormData({ badgeNo })
    }
  }, [employee, onCancel])

  const readOnly = cardState !== 'editing'

  if (!canView) return null

  return (
    <EditableCard
      title="Attendance information"
      style={cardStyle}
      bodyStyle={{ paddingBottom: employee ? 6 : 24, paddingTop: 6 }}
      state={canModify ? cardState : 'readonly'}
      formId="form-em-attendance"
      onEdit={handleEdit}
      onSave={handleSave}
      onCancel={handleCancel}
    >
      <Row gutter={30}>
        <Col span={12}>
          <Form.Item label="Clock employee ID" validateStatus={errors?.badgeNo ? 'error' : ''} help={errors?.badgeNo}>
            <Input
              ref={focusRef}
              value={formData.badgeNo}
              readOnly={readOnly}
              onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ badgeNo: event.target.value })}
            />
          </Form.Item>
        </Col>
      </Row>
    </EditableCard>
  )
}
