import React, { CSSProperties, FC, useMemo } from 'react'
import moment from 'moment-timezone'
import { Dictionary } from '@reduxjs/toolkit'
import { Form, SecondaryText, Space, UploadFile } from '~/core-components'
import { AmountDisplay, Col, Row, SubHeader, UploadFileSelectorAuth } from '~/components'
import { ClaAttachment } from '~/constants'
import { getBaseUrl } from '~/utils'
import { SSClaimTaskTokenState } from '../../types'
import { CfConfigState, ClaimRecordCfJsonb, getInclusiveTaxLabel } from '~/features/claim'

interface MyClaimTaskTokenSingleFormProps {
  token: string
  data: SSClaimTaskTokenState
}

const baseUrl = getBaseUrl('/claim')
const taxStyle: CSSProperties = { fontWeight: 'normal', fontStyle: 'italic' }

export const MyClaimTaskTokenSingleForm: FC<MyClaimTaskTokenSingleFormProps> = ({ token, data }) => {
  const includeTaxLabel = getInclusiveTaxLabel(data.taxRate.currencyCode, 'Inclusive of')
  const cfConfigsDict: Dictionary<CfConfigState> = (data?.cfConfigs || []).reduce((acc, config) => {
    acc[config.code] = config
    return acc
  }, {} as Dictionary<CfConfigState>)
  const hiddenExchangeRate =
    !data.claimType.currencyCode ||
    !data.task.expenseCurrencyCode ||
    data.claimType.currencyCode === data.task.expenseCurrencyCode

  const attachments = useMemo(
    () =>
      data?.attachments
        .filter(att => !att.claimRecordExpenseId)
        .map(
          att =>
            ({
              uid: att?.fileId || '',
              name: att?.fileName || '',
              size: att?.fileSize || 0,
              type: att?.fileFormat || '',
              url: `${baseUrl}/ssclaimtasktoken/attachment/${token}/${att.id}/downloadfile`
            } as UploadFile)
        ),
    [data, token]
  )

  return (
    <>
      {data.claimType.attachment !== ClaAttachment.notApplicable && (
        <Row>
          <Col span={24}>
            <Form.Item label="Uploaded receipt(s)">
              {attachments.length === 0 ? (
                '-'
              ) : (
                <UploadFileSelectorAuth
                  fileList={attachments}
                  listType="picture-card"
                  disabled={true}
                  onChange={() => {}}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row gutter={[15, 0]}>
        <Col span={12}>
          <Form.Item label="Receipt no.">{data.task.receiptNo || '-'}</Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Expense date">
            {data.task.expenseDate ? moment(data.task.expenseDate).format('DD MMM YYYY') : '-'}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[15, 0]}>
        <Col span={hiddenExchangeRate ? 24 : 12}>
          <Form.Item
            label={
              <Space direction={!hiddenExchangeRate ? 'vertical' : 'horizontal'}>
                <span>Expense amount</span>
                {data.task.isTaxable && <i>({includeTaxLabel})</i>}
              </Space>
            }
          >
            <AmountDisplay symbol={data.task.expenseCurrencyCode} value={data.task.expenseAmount} />
          </Form.Item>
        </Col>
        <Col span={12} hidden={hiddenExchangeRate}>
          <Form.Item label="Exchange rate">
            {
              <Space>
                {data.task.exchangeRate}
                {data.task.exchangeUnit > 1 && <SecondaryText size="small">/ {data.task.exchangeUnit}</SecondaryText>}
              </Space>
            }
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item label="Vendor / Provider">{data.task.providerName || '-'}</Form.Item>
        </Col>
      </Row>
      <ClaimRecordCfJsonb
        ctCustomField={data.claimType.customField}
        cfJsonb={data.task.cfJsonb}
        cfConfigsDict={cfConfigsDict}
        readOnly={true}
      />
      <Row>
        <Col span={24}>
          <Form.Item label="Notes">{data.task.notes || '-'}</Form.Item>
        </Col>
      </Row>
      <Row className="my-claim-task-token__claim-amount">
        <Col span={24}>
          <SubHeader type="primary">
            <Row>
              <Col flex="auto">Claim amount</Col>
              <Col flex="none">
                <Space direction="vertical" align="end" size={0}>
                  <AmountDisplay symbol={data.claimType?.currencyCode} value={data.task.claimAmount} />
                  {data.task.isTaxable && (
                    <SecondaryText size="small" style={taxStyle}>
                      {`${includeTaxLabel} `}
                      <AmountDisplay symbol={data.claimType?.currencyCode} value={data.task.claimAmountTax} />
                    </SecondaryText>
                  )}
                </Space>
              </Col>
            </Row>
          </SubHeader>
        </Col>
      </Row>
    </>
  )
}
