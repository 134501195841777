import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { EditableCard, EditableCardState } from '~/components'
import { useLoginPermissions, usePermissionGate } from '~/features/iam/hooks'
import { updateLoginWithPermissions } from '~/features/iam/actions'
import { updateViewCriteria } from '~/features/selection'
import { useGetViewIdByName } from '~/features/selection/hooks'
import { Permission, PermissionAction } from '~/constants'
import { dispatch } from '~/stores/store'
import { Errors, ActionResult } from '~/types/store'
import {
  LoginInfoForm,
  EMPTY_LOGIN_INFO_FORM_DATA,
  LoginInfoFormData,
  mapLoginInfoFormDataToLoginAndPermissionRequest
} from './LoginInfoForm'
import { ILoginPermission, LoginState } from '../../../types'

interface LoginInfoProps {
  login?: LoginState
  onEdit?: () => void
  onSave?: () => void
  onCancel?: () => void
}

const activeTenantCode = localStorage.getItem('tenant')

export const LoginInfo: FC<LoginInfoProps> = ({ login, onEdit, onSave, onCancel }: LoginInfoProps) => {
  const id = login?.id || ''
  const [cardState, setCardState] = useState<EditableCardState>()
  const [formData, setFormData] = useState<LoginInfoFormData>(EMPTY_LOGIN_INFO_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const [loginPermissions] = useLoginPermissions(login?.id || '', activeTenantCode)
  const canModify = usePermissionGate(Permission.login, PermissionAction.Modify)
  const [viewEmpId] = useGetViewIdByName('permission_emp', login ? `login_${login.id}` : undefined)
  const [viewPayId] = useGetViewIdByName('permission_pay', login ? `login_${login.id}` : undefined)
  const [viewLveId] = useGetViewIdByName('permission_lve', login ? `login_${login.id}` : undefined)
  const [viewClaId] = useGetViewIdByName('permission_cla', login ? `login_${login.id}` : undefined)
  const [viewAttId] = useGetViewIdByName('permission_att', login ? `login_${login.id}` : undefined)
  const permissions: ILoginPermission[] = useMemo(
    () => loginPermissions?.map(({ id, sysPermissionId, action }) => ({ id, sysPermissionId, action })),
    [loginPermissions]
  )

  useDeepCompareEffect(() => {
    if (login) {
      const { id, email, loginName, activatedDate, isSuperUser, inactiveDate } = login
      setFormData({
        id,
        email,
        loginName,
        activatedDate,
        isSuperUser,
        inactiveDate,
        permissions,
        empCriteria: [],
        payCriteria: [],
        lveCriteria: [],
        claCriteria: [],
        attCriteria: []
      })
    } else {
      setFormData(EMPTY_LOGIN_INFO_FORM_DATA)
    }
  }, [login || {}, permissions])

  const handleEdit = useCallback(() => {
    setCardState('editing')
    typeof onEdit === 'function' && onEdit()
  }, [onEdit])

  const handleSave = useCallback(async () => {
    if (login) {
      setCardState('saving')
      setErrors(undefined)

      typeof onSave === 'function' && onSave()

      let result: ActionResult | undefined
      try {
        if (viewEmpId && formData.empCriteria.length > 0) {
          await dispatch(
            updateViewCriteria('permission_emp', viewEmpId, { id: viewEmpId, criteria: formData.empCriteria })
          )
        }
        if (viewPayId && formData.payCriteria.length > 0) {
          await dispatch(
            updateViewCriteria('permission_pay', viewPayId, { id: viewPayId, criteria: formData.payCriteria })
          )
        }
        if (viewLveId && formData.lveCriteria.length > 0) {
          await dispatch(
            updateViewCriteria('permission_lve', viewLveId, { id: viewLveId, criteria: formData.lveCriteria })
          )
        }
        if (viewClaId && formData.claCriteria.length > 0) {
          await dispatch(
            updateViewCriteria('permission_cla', viewClaId, { id: viewClaId, criteria: formData.claCriteria })
          )
        }
        if (viewAttId && formData.attCriteria.length > 0) {
          await dispatch(
            updateViewCriteria('permission_att', viewAttId, { id: viewAttId, criteria: formData.attCriteria })
          )
        }
        const request = mapLoginInfoFormDataToLoginAndPermissionRequest(formData)
        result = await dispatch(updateLoginWithPermissions(request))
      } catch {
        setCardState('editing')
      }

      if (result?.errors) {
        setCardState('editing')
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setCardState(undefined)
      }
    }
  }, [login, formData, onSave, viewEmpId, viewPayId, viewLveId, viewClaId, viewAttId])

  const handleCancel = useCallback(() => {
    typeof onCancel === 'function' && onCancel()
    setCardState(undefined)
    setErrors(undefined)

    if (login) {
      const { id, email, loginName, activatedDate, isSuperUser, inactiveDate } = login
      setFormData({
        id,
        email,
        loginName,
        activatedDate,
        isSuperUser,
        inactiveDate,
        permissions,
        empCriteria: [],
        payCriteria: [],
        lveCriteria: [],
        claCriteria: [],
        attCriteria: []
      })
    }
  }, [login, permissions, onCancel])

  useEffect(() => {
    if (id) handleCancel()
  }, [id, handleCancel])

  return (
    <EditableCard
      title="Login information"
      bodyStyle={{ paddingBottom: login ? 6 : 24, paddingTop: 6 }}
      state={canModify ? cardState : 'readonly'}
      formId="form-login"
      onEdit={handleEdit}
      onSave={handleSave}
      onCancel={handleCancel}
    >
      <LoginInfoForm
        data={formData}
        errors={errors}
        readOnly={cardState !== 'editing' && cardState !== 'saving'}
        onChange={setFormData}
      />
    </EditableCard>
  )
}
