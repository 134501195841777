import React, { FC, useState, useCallback, useEffect, HTMLAttributes, CSSProperties } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import confirm from 'antd/lib/modal/confirm'
import { Alert, Checkbox, CheckboxChangeEvent, Form, Input, Radio, RadioChangeEvent, Space } from '~/core-components'
import { Col, DrawerForm, AttKeyValues, NewTabLinkIcon, Row } from '~/components'
import { selectKeyvaluesById } from '~/features/master'
import { selectEmployeeById } from '~/features/employee/reducers'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { useFocus } from '~/hooks/use-focus'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors, StoreState } from '~/types/store'
import { addEmAttendanceGroup, deleteEmAttendanceGroup, updateEmAttendanceGroup } from '../../../actions'
import { IEmAttendanceGroupInfo, EmAttendanceGroupState } from '../../../types'
import { mapEmAttendanceGroupStateToEmAttendanceGroupInfo } from '../../../types/em-details.mapper'
import { SETTINGS_ROUTES } from '~/routes/routes'

export interface MutateEmAttendanceGroupDrawerProps {
  visible: boolean
  employeeId?: string
  data?: EmAttendanceGroupState
  isCurrent: boolean
  onClose: () => void
}

const TODAY = moment().format('YYYY-MM-DD')
const EMPTY_FORM_DATA: IEmAttendanceGroupInfo = {
  startDate: TODAY,
  attendanceGroupId: '',
  isClockInRequired: true,
  isOtPayable: true,
  isLatenessDeductible: true,
  isUndertimeDeductible: true
}

const radioStyle: CSSProperties = { width: '100%' }
const radioItemStyle: CSSProperties = { marginTop: 20 }
const formStyle: CSSProperties = { marginLeft: 24, marginTop: 10 }
const alertStyle: CSSProperties = { marginBottom: 15 }
const checkboxStyle: CSSProperties = { marginLeft: 10 }

export const MutateEmAttendanceGroupDrawer: FC<MutateEmAttendanceGroupDrawerProps> = ({
  visible,
  employeeId,
  data,
  isCurrent,
  onClose
}: MutateEmAttendanceGroupDrawerProps) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<IEmAttendanceGroupInfo>(EMPTY_FORM_DATA)
  const [newFormData, setNewFormData] = useState<IEmAttendanceGroupInfo>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const [isNew, setIsNew] = useState(isCurrent ? true : false)
  const attendanceGroups = useSelector(selectKeyvaluesById)('attendanceGroup')
  const employee = useSelector((state: StoreState) => selectEmployeeById(state, employeeId || ''))

  useEffect(() => {
    setIsNew(isCurrent ? true : false)
    setErrors(undefined)
  }, [visible, isCurrent])

  useEffect(() => {
    if (visible && data) setFormData(mapEmAttendanceGroupStateToEmAttendanceGroupInfo(data))
  }, [visible, data])

  const handleRadioChange = useCallback((event: RadioChangeEvent) => {
    setIsNew(event.target.value)
  }, [])

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      if (isNew || data == null) {
        if (employeeId) {
          const submit = isNew ? newFormData : formData
          result = await dispatch(addEmAttendanceGroup(employeeId, submit))
        }
      } else {
        if (data) {
          result = await dispatch(
            updateEmAttendanceGroup(
              data.employeeId,
              data.id,
              mapEmAttendanceGroupStateToEmAttendanceGroupInfo(data),
              formData
            )
          )
        }
      }
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_FORM_DATA)
    }
  }, [isNew, employeeId, data, newFormData, formData, onClose])

  const handleDelete = useCallback(
    (emAttendanceGroup: EmAttendanceGroupState | undefined) => {
      if (employeeId && emAttendanceGroup) {
        const { id, attendanceGroupId } = emAttendanceGroup
        const attendanceGroup = attendanceGroups[attendanceGroupId]?.value

        confirm({
          title: 'Delete attendance group',
          content: `Do you want to delete attendance group "${attendanceGroup}"?`,
          onOk: async () => {
            const result: ActionResult | undefined = await dispatch(deleteEmAttendanceGroup(employeeId, id))
            if (result?.errors) {
              setErrors(result.errors)
            }

            if (!result?.errors) {
              typeof onClose === 'function' && onClose()
            }
          },
          okText: 'Delete',
          okType: 'danger'
        })
      }
    },
    [employeeId, attendanceGroups, onClose]
  )

  return (
    <DrawerForm
      open={visible}
      title="Attendance group"
      onClose={onClose}
      confirmLoading={loading}
      width={500}
      showDelete={isNew || data == null ? false : true}
      onDelete={() => handleDelete(data)}
      formId="form-em-attendance-group"
    >
      <Form id="form-em-attendance-group" onFinish={handleOk}>
        {isCurrent ? (
          <Radio.Group value={isNew} onChange={handleRadioChange} style={radioStyle}>
            <Radio value={true}>Transfer to new attendance group</Radio>
            <EmAttendanceGroupForm
              errors={isNew ? errors : undefined}
              labelPrefix="New"
              style={formStyle}
              disabled={!isNew}
              onFormChange={data => setNewFormData({ ...newFormData, ...data })}
            />
            <Radio value={false} style={radioItemStyle}>
              Or make amendment to current attendance group
            </Radio>
            <EmAttendanceGroupForm
              data={data}
              errors={!isNew ? errors : undefined}
              labelPrefix="Current"
              style={formStyle}
              disabled={isNew}
              onFormChange={data => setFormData({ ...formData, ...data })}
            />
          </Radio.Group>
        ) : (
          <>
            {data != null && (
              <Alert
                message="Any changes to this historical record will not impact past transactions."
                banner
                style={alertStyle}
              />
            )}
            <EmAttendanceGroupForm
              data={data}
              hireDate={employee?.hireDate}
              errors={errors}
              onFormChange={data => setFormData({ ...formData, ...data })}
            />
          </>
        )}
      </Form>
    </DrawerForm>
  )
}

interface EmAttendanceGroupFormProps extends HTMLAttributes<HTMLDivElement> {
  data?: EmAttendanceGroupState
  hireDate?: string
  errors?: Errors
  labelPrefix?: string
  disabled?: boolean
  onFormChange: (data: IEmAttendanceGroupInfo) => void
}

const EmAttendanceGroupForm: FC<EmAttendanceGroupFormProps> = ({
  data,
  hireDate,
  errors,
  labelPrefix,
  onFormChange,
  style,
  disabled = false
}) => {
  const canModify = usePermissionGate(Permission.attendanceGroup, PermissionAction.Modify)
  const [formData, setFormData] = useState<IEmAttendanceGroupInfo>(EMPTY_FORM_DATA)
  const [focusRef, setFocus] = useFocus(true)
  const effectiveDateLabel = 'Effective date'
  const attendanceGroupLabel = 'Attendance group'
  const settingsLabel = 'Settings'

  useEffect(() => {
    setTimeout(() => !disabled && setFocus(), 100)
  }, [disabled, setFocus])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      const updated = { ...formData, ...updates }
      setFormData(updated)
      typeof onFormChange === 'function' && onFormChange(updated)
    },
    [formData, onFormChange]
  )

  useEffect(() => {
    if (data) {
      const {
        startDate,
        attendanceGroupId,
        isClockInRequired,
        isOtPayable,
        isLatenessDeductible,
        isUndertimeDeductible
      } = data
      setFormData({
        startDate,
        attendanceGroupId,
        isClockInRequired,
        isOtPayable,
        isLatenessDeductible,
        isUndertimeDeductible
      })
    } else {
      setFormData({ ...EMPTY_FORM_DATA, startDate: hireDate || TODAY })
    }
  }, [data, hireDate])

  const getLabel = useCallback(
    (prefix: string | undefined, label: string) => (prefix ? `${prefix} ${label.toLowerCase()}` : label),
    []
  )

  return (
    <div style={style}>
      <Row>
        <Col span={24}>
          <Form.Item
            label={getLabel(labelPrefix, effectiveDateLabel)}
            validateStatus={errors?.startDate ? 'error' : ''}
            help={errors?.startDate}
          >
            <Input.Date
              ref={focusRef}
              value={formData.startDate ? moment(formData.startDate) : undefined}
              onChange={(value: moment.Moment | null) =>
                handleFormDataChange({ startDate: value?.format('YYYY-MM-DD') })
              }
              disabled={disabled}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            label={getLabel(labelPrefix, attendanceGroupLabel)}
            validateStatus={errors?.attendanceGroupId ? 'error' : ''}
            help={errors?.attendanceGroupId}
          >
            <NewTabLinkIcon
              path={SETTINGS_ROUTES.attendanceGroups}
              tooltipText="Open attendance group in a new tab"
              tooltipPlacement="topRight"
              hidden={!canModify}
            >
              <AttKeyValues
                id="attendanceGroup"
                value={formData.attendanceGroupId}
                onChange={(value: string) => handleFormDataChange({ attendanceGroupId: value })}
                disabled={disabled}
              />
            </NewTabLinkIcon>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item label={getLabel(labelPrefix, settingsLabel)}>
            <Space direction="vertical">
              <Checkbox
                checked={formData.isClockInRequired}
                onChange={(event: CheckboxChangeEvent) =>
                  handleFormDataChange({ isClockInRequired: event.target.checked })
                }
                style={checkboxStyle}
                disabled={disabled}
              >
                Clock-in required
              </Checkbox>
              <Checkbox
                checked={formData.isOtPayable}
                onChange={(event: CheckboxChangeEvent) => handleFormDataChange({ isOtPayable: event.target.checked })}
                style={checkboxStyle}
                disabled={disabled}
              >
                Overtime payable
              </Checkbox>
              <Checkbox
                checked={formData.isLatenessDeductible}
                onChange={(event: CheckboxChangeEvent) =>
                  handleFormDataChange({ isLatenessDeductible: event.target.checked })
                }
                style={checkboxStyle}
                disabled={disabled}
              >
                Lateness deductible
              </Checkbox>
              <Checkbox
                checked={formData.isUndertimeDeductible}
                onChange={(event: CheckboxChangeEvent) =>
                  handleFormDataChange({ isUndertimeDeductible: event.target.checked })
                }
                style={checkboxStyle}
                disabled={disabled}
              >
                Undertime deductible
              </Checkbox>
            </Space>
          </Form.Item>
        </Col>
      </Row>
    </div>
  )
}
