import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiUpdatePayRecordPayment } from '../api/payrecord-sg.api'
import { IPayRecordPayment, PayRecordSgState } from '../types'
import { setPayRecord } from '../reducers'

export const updatePayRecordPayment = (payRunId: string, id: string, payment: IPayRecordPayment): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const { result, status, errors, message, errorData } = await apiUpdatePayRecordPayment(id, payment)

    if (status) {
      const payRecord = getState().payroll.payRecords[payRunId].entities[id]
      const data: PayRecordSgState = { ...(payRecord as PayRecordSgState), ...result }
      dispatch(setPayRecord({ payRunId, data }))

      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
