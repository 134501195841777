import React, { FC } from 'react'
import { Form } from '~/core-components'
import { Col, Row } from '~/components'

interface ClaimCurrentApproverProps {
  approvers: string[]
  hidden: boolean
}

export const ClaimCurrentApprover: FC<ClaimCurrentApproverProps> = ({ approvers, hidden }) => {
  return (
    <>
      {approvers.length > 0 && (
        <Row hidden={hidden}>
          <Col span={24}>
            <Form.Item label="Pending for approval by">
              <div style={{ whiteSpace: 'pre-line' }}>{approvers.join('\n')}</div>
            </Form.Item>
          </Col>
        </Row>
      )}
    </>
  )
}
