import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import debounce from 'lodash/debounce'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { FetchOptions, FetchStrategy, Pagination } from '~/types/common'
import { fetchScheduleRecordsViewByRole } from '../actions'
import { ROScheduleRecordViewState } from '../types'

export const useROScheduleRecordsView = (
  viewId: string,
  role: string,
  startDate: string,
  endDate: string,
  page: number,
  pageSize: number,
  search: string,
  debounceMs: number = 800,
  fetchStrategy: FetchStrategy = 'always'
): [ROScheduleRecordViewState | undefined, boolean] => {
  const data = useSelector((state: StoreState) => state.mySchedule.roScheduleRecordsView?.entities[viewId])
  const loading = useSelector((state: StoreState) => state.mySchedule.roScheduleRecordsViewLoading)
  const refetch = useSelector((state: StoreState) => state.mySchedule.roScheduleRecordsViewRefetch)
  const refetchRow = useSelector((state: StoreState) => state.mySchedule.roScheduleRecordsViewRowRefetch)
  const refetchCell = useSelector((state: StoreState) => state.mySchedule.roScheduleRecordsViewCellRefetch)

  const debounceFetch = useMemo(() => {
    const fn = () => {
      const pagination: Pagination = { offset: pageSize * (page - 1), limit: pageSize }
      const strategy: FetchOptions = { strategy: fetchStrategy }
      dispatch(fetchScheduleRecordsViewByRole(role, viewId, startDate, endDate, pagination, search, strategy))
    }
    return debounce(fn, debounceMs)
  }, [role, viewId, startDate, endDate, page, pageSize, search, debounceMs, fetchStrategy])

  useEffect(() => {
    debounceFetch()
  }, [refetch, debounceFetch])

  useEffect(() => {
    if (refetchRow) {
      debounceFetch()
    }
  }, [refetchRow, debounceFetch])

  useEffect(() => {
    if (refetchCell) {
      debounceFetch()
    }
  }, [refetchCell, debounceFetch])

  return [data, loading]
}
