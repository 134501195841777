import React, { forwardRef } from 'react'
import classNames from 'classnames'
import { Select, SelectProps } from '~/core-components'
import { useTimeLogTemplates } from '../../../../../hooks'
import { TimeLogTemplateState } from '../../../../../types'
import './SelectTimeLogTemplate.less'

export interface SelectTimeLogTemplateProps extends SelectProps {
  value: string
  onChange: (value: string) => void
}

const SelectTimeLogTemplateInternal = ({ className, ...props }: SelectTimeLogTemplateProps, ref: React.Ref<any>) => {
  const classes = classNames('select-time-log-template', { [`${className}`]: className })
  const [timeLogTemplates] = useTimeLogTemplates()

  return (
    <Select ref={ref} className={classes} {...props}>
      {(Object.values(timeLogTemplates) as TimeLogTemplateState[]).map(e => (
        <Select.Option key={e.id} value={e.id}>
          {e.name}
        </Select.Option>
      ))}
    </Select>
  )
}

export const SelectTimeLogTemplate = forwardRef(SelectTimeLogTemplateInternal)
