import { createSelector, Dictionary } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import omit from 'lodash/omit'
import { StoreState } from '~/types/store'
import { OfficeState } from '../types'

export const selectCompanyOfficesOverview = createSelector(
  (state: StoreState) => state.master.offices,
  (state: StoreState) => state.master.companyOffices,
  (officesState, companyOfficesState) =>
    memoize((companyId?: string) => {
      const offices: Dictionary<OfficeState> = officesState.entities
      const companyOffices = Object.values(companyOfficesState.entities).sort((a, b) => {
        const aInactiveDate = offices[a!.officeId]?.inactiveDate
          ? new Date(offices[a!.officeId]?.inactiveDate || '').getTime()
          : 0
        const bInactiveDate = offices[b!.officeId]?.inactiveDate
          ? new Date(offices[b!.officeId]?.inactiveDate || '').getTime()
          : 0

        if (bInactiveDate === 0 && aInactiveDate === 0) {
          return (offices[a!.officeId]?.name || '').localeCompare(offices[b!.officeId]?.name || '')
        } else {
          return aInactiveDate - bInactiveDate
        }
      })

      return companyOffices
        .filter(co => co?.companyId === companyId)
        .map(co => ({
          ...co,
          ...omit(
            Object.values(offices).find(o => o?.id === co?.officeId),
            ['id'] // id should be taken from CompanyOffice
          )
        }))
    })
)
