import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { FetchStrategy } from '~/types/common'
import { fetchMyClaimRecordComments } from '../actions'
import { selectMyClaimRecordComments } from '../selectors'
import { IClaimRecordComment } from '../types'

export const useMyClaimRecordComments = (
  claimRecordId?: string,
  fetchStrategy: FetchStrategy = 'always'
): [IClaimRecordComment[], boolean] => {
  const comments = useSelector(selectMyClaimRecordComments)(claimRecordId || '') as IClaimRecordComment[]
  const loading = useSelector((state: StoreState) => state.myClaim.myClaimRecordCommentsLoading[claimRecordId || ''])

  useEffect(() => {
    if (claimRecordId) {
      dispatch(fetchMyClaimRecordComments(claimRecordId, { strategy: fetchStrategy }))
    }
  }, [claimRecordId, fetchStrategy])

  return [comments, loading]
}
