import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Alert } from '~/core-components'
import { StoreState } from '~/types/store'
import { ICopyEmployee } from '../../../../../types'
import './CopyEmComplete.less'

interface CopyEmCompleteProps {
  visible: boolean
  data: Partial<ICopyEmployee>
  message: string
}

export const CopyEmComplete: FC<CopyEmCompleteProps> = ({ visible, data, message }) => {
  const employee = useSelector((state: StoreState) => state.employee.employees.entities[data.employeeId || ''])

  if (!employee?.id) return null

  return (
    <div className="copy-em-complete" hidden={!visible}>
      <Alert type="success" showIcon message={message} />
    </div>
  )
}
