import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiRejectClaimPendingApproval } from '../api/claim-pending-approval.api'
import { refetchClaimPendingApprovalsView } from '../reducers'
import { IClaimPendingApproval } from '../types'

export const rejectClaimPendingApproval = (claimPendingApproval: IClaimPendingApproval): ThunkResult<void> => {
  return async dispatch => {
    const { status, result, errors, message, errorData } = await apiRejectClaimPendingApproval(claimPendingApproval)
    if (status) {
      dispatch(refetchClaimPendingApprovalsView())
      showSuccess('Rejected')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
