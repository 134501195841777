import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiDeleteCompany } from '../api/company.api'
import { removeCompany, removeKeyvalue, replaceCompanyOffices } from '../reducers'
import { CompanyOfficeState } from '../types'

export const deleteCompany = (id: string): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const { status, errors, message, errorData } = await apiDeleteCompany(id)
    if (status) {
      dispatch(removeCompany(id))
      dispatch(removeKeyvalue({ kv: 'company', id }))

      let companyOffices = Object.values(getState().master.companyOffices?.entities || {})
      companyOffices = companyOffices.filter(co => co?.companyId !== id)
      dispatch(replaceCompanyOffices(companyOffices as CompanyOfficeState[]))

      showSuccess('Deleted')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
