import React, { forwardRef } from 'react'
import classNames from 'classnames'
import { Select, SelectProps } from '~/core-components'
import { useSysWfActionTypes } from '../../hooks'
import { SysWfActionTypeState } from '../../types'
import './SelectWfActionType.less'

export interface SelectWfActionTypeProps extends SelectProps {
  flowType: string
  eventType: string
  value: string
  onChange: (value: string) => void
}

const SelectWfActionTypeInternal = (
  { flowType, eventType, className, ...props }: SelectWfActionTypeProps,
  ref: React.Ref<any>
) => {
  const classes = classNames('select-wf-action-type', { [`${className}`]: className })
  const [actionTypes] = useSysWfActionTypes(flowType, eventType)

  return (
    <Select ref={ref} className={classes} allowClear={false} {...props}>
      {(Object.values(actionTypes) as SysWfActionTypeState[]).map(e => (
        <Select.Option key={e.code} value={e.code}>
          {e.name}
        </Select.Option>
      ))}
    </Select>
  )
}

export const SelectWfActionType = forwardRef(SelectWfActionTypeInternal)
