import React, { FC, useCallback, useState } from 'react'
import moment from 'moment-timezone'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Card, ColumnsType, PageHeader, Space, Table, Tag } from '~/core-components'
import { DocumentTitle, InactiveTag } from '~/components'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { Permission, PermissionAction } from '~/constants'
import { isInactive } from '~/utils'
import { StoreState } from '~/types/store'
import { useLogins, usePermissionGate } from '../../hooks'
import { AddLoginDrawer } from './components/AddLoginDrawer'
import { LoginState } from '../../types'
import './Logins.less'

export interface LoginsProps {}

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: '',
    breadcrumbName: 'Overview'
  }
]

interface DrawerState {
  visible: boolean
}
const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

export const Logins: FC<LoginsProps> = () => {
  const [logins] = useLogins()
  const loading = useSelector((state: StoreState) => state.iam.loginsLoading)
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const canAdd = usePermissionGate(Permission.login, PermissionAction.Add)

  const handleAddLogin = useCallback(() => {
    setDrawerState({ visible: true })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const columns: ColumnsType<LoginState> = [
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
      width: 300,
      render: (value: string, record) => {
        return (
          <Space>
            <Link to={SETTINGS_ROUTES.login.replace(':id', record?.id)}>{value}</Link>
            {!record.activatedDate &&
              (!record.activationSentDate ? <Tag>draft</Tag> : <Tag type="secondary">onboarding</Tag>)}
            {isInactive(record.inactiveDate) && <InactiveTag />}
          </Space>
        )
      }
    },
    {
      title: 'Name',
      key: 'loginName',
      dataIndex: 'loginName'
    },
    {
      title: 'Last active',
      key: 'lastLoginDate',
      dataIndex: 'lastLoginDate',
      width: 190,
      render: (value: string) => (value ? moment(value).format('DD MMM YYYY HH:mm:ss') : '')
    }
  ]

  return (
    <div id="logins" className="logins">
      <DocumentTitle title="Users" />
      <PageHeader
        title="Users"
        containerId="logins"
        breadcrumb={{ routes }}
        extra={
          canAdd && (
            <Button key="add" onClick={handleAddLogin}>
              Add user
            </Button>
          )
        }
      />
      <div className="logins__body">
        <Card table>
          <Table
            rowKey="id"
            dataSource={logins}
            pagination={false}
            columns={columns}
            loading={loading}
            className="logins__table"
          />
        </Card>
        {canAdd && <AddLoginDrawer {...drawerState} onClose={handleCloseDrawer} />}
      </div>
    </div>
  )
}
