import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { LeaveTypeState } from '../types'
import { PayLeaveMapState } from '~/features/master'
import { isInactive } from '~/utils'
import { LveSysLeaveType } from '~/constants'

export const selectLeaveTakenLeaveTypes = createSelector(
  (state: StoreState) => state.leave.leaveTypes,
  (state: StoreState) => state.master.payLeaveMaps,
  (leaveTypesState, payLeaveMapsState) =>
    memoize((payGroupId: string) => {
      if (!payGroupId) return []

      const payLeaveMaps = Object.values(payLeaveMapsState[payGroupId]?.entities || {}) as PayLeaveMapState[]
      const leaveTypes = Object.values(leaveTypesState?.entities || {}) as LeaveTypeState[]
      return leaveTypes
        .filter(
          lt =>
            (!isInactive(lt?.inactiveDate) || payLeaveMaps.filter(plm => plm.leaveTypeId === lt?.id).length > 0) &&
            [LveSysLeaveType.npl, LveSysLeaveType.uicl, LveSysLeaveType.xnl].includes(lt?.sysLeaveTypeCode || '')
        )
        .sort((a, b) => {
          const aInactiveDate = new Date(a!.inactiveDate || '').getTime() || 0
          const bInactiveDate = new Date(b!.inactiveDate || '').getTime() || 0

          if (bInactiveDate === 0 && aInactiveDate === 0) {
            return a!.name.localeCompare(b!.name)
          } else {
            return aInactiveDate - bInactiveDate
          }
        })
    })
)
