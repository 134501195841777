import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import confirm from 'antd/lib/modal/confirm'
import { Button, PageHeader } from '~/core-components'
import { DocumentTitle } from '~/components'
import { claimMenus, useMasterMenu } from '~/features/master'
import { usePermissionGate } from '~/features/iam'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { ActionResult } from '~/types/store'
import { dispatch } from '~/stores/store'
import { selectClaimTypes } from '../../selectors'
import { MenuItem } from '~/types/common'
import { Permission, PermissionAction } from '~/constants'
import { deleteClaimType } from '../../actions'
import { useClaimType } from '../../hooks'
import { ClaimTypeInfo } from './components/ClaimTypeInfo'
import { ClaimTypePayment } from './components/ClaimTypePayment'
import { ClaimTypeEarn } from './components/ClaimTypeEarn'
import { ClaimTypeApply } from './components/ClaimTypeApply'
import { ClaimTypePolicy } from './components/ClaimTypePolicy'
import { AddClaimTypeDrawer } from '../ClaimTypes/components/AddClaimTypeDrawer'
import { ClaimTypeExpenseType } from './components/ClaimTypeExpenseType'
import './ClaimType.less'
import { ClaimTypeCustomField } from './components/ClaimTypeCustomField'

interface ClaimTypeProps {}

interface ClaimTypeParams {
  id: string
}

interface DrawerState {
  visible: boolean
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: SETTINGS_ROUTES.claimTypes,
    breadcrumbName: 'Overview'
  },
  {
    path: '',
    breadcrumbName: 'Claim type'
  }
]

export const ClaimType: FC<ClaimTypeProps> = () => {
  const { id } = useParams<ClaimTypeParams>()
  const claimTypes = useSelector(selectClaimTypes)
  const [claimType] = useClaimType(id)
  const [editing, setEditing] = useState(false)
  const history = useHistory()
  const { setMenus } = useMasterMenu()
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const canModify = usePermissionGate(Permission.claType, PermissionAction.Modify)

  const handleAddClaimType = useCallback(() => {
    setDrawerState({ visible: true })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  useEffect(() => {
    const menus: MenuItem[] = claimTypes.map(ct => ({
      path: SETTINGS_ROUTES.claimType.replace(':id', ct!.id),
      value: ct!.name,
      sysPermissionId: Permission.claType
    }))

    if (canModify)
      menus.push({
        path: (
          <Button type="dashed" onClick={handleAddClaimType} block>
            Add claim type
          </Button>
        ),
        value: '',
        sysPermissionId: ''
      })

    setMenus(menus)
    return () => {
      setMenus(claimMenus)
    }
  }, [setMenus, claimTypes, canModify, handleAddClaimType])

  const handleEdit = useCallback(() => setEditing(true), [])
  const handleView = useCallback(() => setEditing(false), [])

  const handleDelete = useCallback(() => {
    if (id && claimType) {
      confirm({
        title: 'Delete claim type',
        content: `Do you want to delete claim type "${claimType.name}"?`,
        onOk: async () => {
          const result: ActionResult | undefined = await dispatch(deleteClaimType(id))
          if (!result?.errors) {
            history.push(SETTINGS_ROUTES.claimTypes)
          }
        },
        okText: 'Delete',
        okType: 'danger'
      })
    }
  }, [id, claimType, history])

  return (
    <div id="claim-type" className="claim-type">
      <DocumentTitle title="Claim Type" />
      <PageHeader
        title="Claim type"
        containerId="claim-type"
        breadcrumb={{ routes }}
        extra={canModify && editing && <Button onClick={handleDelete}>Delete</Button>}
      />
      <div className="claim-type__body">
        <ClaimTypeInfo claimType={claimType} onEdit={handleEdit} onSave={handleView} onCancel={handleView} />
        <ClaimTypePayment claimType={claimType} onEdit={handleEdit} onSave={handleView} onCancel={handleView} />
        <ClaimTypeEarn claimType={claimType} onEdit={handleEdit} onSave={handleView} onCancel={handleView} />
        <ClaimTypeApply claimType={claimType} onEdit={handleEdit} onSave={handleView} onCancel={handleView} />
        <ClaimTypeCustomField claimType={claimType} onEdit={handleEdit} onSave={handleView} onCancel={handleView} />
        <ClaimTypeExpenseType
          claimTypeId={claimType?.id || ''}
          expenseSubmissionType={claimType?.expenseSubmissionType}
        />
        <ClaimTypePolicy claimType={claimType} />
        {canModify && <AddClaimTypeDrawer {...drawerState} onClose={handleCloseDrawer} />}
      </div>
    </div>
  )
}
