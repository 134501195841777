import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import {
  Card,
  ColumnsType,
  Form,
  Input,
  Link,
  LinkGroup,
  SecondaryText,
  Space,
  Table,
  Tag,
  Tooltip
} from '~/core-components'
import { Col, EmpKeyValues, InactiveTag, Row, SysOptions } from '~/components'
import { fetchEmpKeyvalues, selectCalendarKeyvalues } from '~/features/master'
import { useHasFeatureFlagAttendance, usePermissionGate } from '~/features/iam'
import { useSysOptions } from '~/features/employee'
import { Permission, PermissionAction, WorkSchedule } from '~/constants'
import { useFirstInView, useToggle } from '~/hooks'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { isInactive } from '~/utils'
import { MutateEmCalendarDrawer } from './MutateEmCalendarDrawer'
import { IEmCalendarInfo, EmCalendarState } from '../../../types'
import { fetchEmCalendars } from '../../../actions'
import { selectCurrentEmCalendar, selectHistoryEmCalendars } from '../../../selectors'

interface EmCalendarsProps {
  employeeId: string
  id: string
}

type EmCalendarTable = EmCalendarState

const EMPTY_FORM_DATA: IEmCalendarInfo = {
  startDate: '',
  workSchedule: '',
  calendarId: '',
  whPerDay: 0,
  whPerWeek: 0,
  wdPerWeek: 0
}

interface DrawerState {
  visible: boolean
  employeeId?: string
  data?: EmCalendarState
  isCurrent: boolean
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false, isCurrent: false }

const cardStyle: CSSProperties = { margin: 24 }
const cardBodyStyle: CSSProperties = { padding: 0 }
const formStyle: CSSProperties = { padding: '6px 24px' }

export const EmCalendars: FC<EmCalendarsProps> = ({ employeeId, id }: EmCalendarsProps) => {
  const { ref, inView } = useFirstInView<HTMLDivElement>({ threshold: 0.25 })
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const [formData, setFormData] = useState<IEmCalendarInfo>(EMPTY_FORM_DATA)
  const current = useSelector(selectCurrentEmCalendar)(employeeId, id)
  const histories = useSelector(selectHistoryEmCalendars)(employeeId)
  const calendars = useSelector(selectCalendarKeyvalues)
  const [showHistory, toggleShowHistory] = useToggle()
  const canView = usePermissionGate(Permission.employeeCalendar)
  const canModify = usePermissionGate(Permission.employeeCalendar, PermissionAction.Modify)
  const loading = useSelector((state: StoreState) => state.employee.emCalendarsLoading[employeeId])
  const [workSchedule] = useSysOptions('work_schedule')
  const hasAttendance = useHasFeatureFlagAttendance()

  useEffect(() => {
    if (inView) {
      dispatch(fetchEmpKeyvalues('calendar', undefined, undefined, { strategy: 'when-empty' }))
    }
  }, [inView])

  useEffect(() => {
    if (inView) {
      if (employeeId) {
        dispatch(fetchEmCalendars(employeeId))
      }
    }
  }, [inView, employeeId])

  useEffect(() => {
    if (showHistory && histories.length === 0) {
      toggleShowHistory()
    }
  }, [histories.length, showHistory, toggleShowHistory])

  useEffect(() => {
    if (current) {
      const { startDate, workSchedule, calendarId, whPerDay, whPerWeek, wdPerWeek } = current
      setFormData({ startDate, workSchedule, calendarId, whPerDay, whPerWeek, wdPerWeek })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [current])

  const handleEditCalendar = useCallback(
    (emCalendar?: EmCalendarState) => {
      setDrawerState({ visible: true, employeeId, data: emCalendar, isCurrent: id === emCalendar?.id })
    },
    [employeeId, id, setDrawerState]
  )

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const columns: ColumnsType<EmCalendarTable> = [
    {
      title: 'Effective date',
      key: 'startDate',
      dataIndex: 'startDate',
      width: 150,
      render: (value: string) => value && moment(value).format('DD MMM YYYY')
    },
    {
      title: 'Calendar',
      key: 'calendarId',
      dataIndex: 'calendarId',
      render: (value: string, record) => (
        <Space>
          {calendars[value]?.value}
          {record.id === id && <Tag type="primary">current</Tag>}
          {isInactive(calendars[value]?.inactiveDate) && <InactiveTag />}
        </Space>
      )
    },
    {
      title: <Tooltip title="Work hours per day">WH per day</Tooltip>,
      key: 'whPerDay',
      dataIndex: 'whPerDay'
    },
    {
      title: <Tooltip title="Work hours per week">WH per week</Tooltip>,
      key: 'whPerWeek',
      dataIndex: 'whPerWeek'
    },
    {
      title: <Tooltip title="Work days per week">WD per week</Tooltip>,
      key: 'wdPerWeek',
      dataIndex: 'wdPerWeek'
    },
    {
      key: 'action',
      align: 'right',
      render: (value: string, record) =>
        canModify && (
          <Link size="small" onClick={() => handleEditCalendar(record)}>
            edit
          </Link>
        )
    }
  ]

  if (hasAttendance) {
    columns.splice(1, 0, {
      title: 'Work schedule',
      key: 'workSchedule',
      dataIndex: 'workSchedule',
      render: (value: string) => workSchedule[value]?.value
    })
  }

  if (!canView) return null

  return (
    <div ref={ref}>
      <Card
        title="Work calendar"
        style={cardStyle}
        bodyStyle={cardBodyStyle}
        loading={loading}
        extra={
          <LinkGroup>
            {canModify && !showHistory && (
              <Link onClick={() => handleEditCalendar(current)}>{current ? 'edit' : 'add'}</Link>
            )}
            {(histories?.length || 0) > 0 && (
              <Link onClick={toggleShowHistory}>{showHistory ? 'hide history' : 'history'}</Link>
            )}
          </LinkGroup>
        }
      >
        {!showHistory && (
          <Form style={formStyle}>
            {hasAttendance && (
              <Row gutter={30}>
                <Col span={12}>
                  <Form.Item
                    label={
                      <Space>
                        Work schedule
                        {formData.startDate && (
                          <SecondaryText size="small">
                            effective from {moment(formData.startDate).format('DD MMM YYYY')}
                          </SecondaryText>
                        )}
                      </Space>
                    }
                  >
                    <SysOptions type="work_schedule_display" value={formData.workSchedule} readOnly />
                  </Form.Item>
                </Col>
              </Row>
            )}
            <Row gutter={30}>
              <Col span={12}>
                <Form.Item
                  label={
                    <Space>
                      Calendar
                      {hasAttendance && formData.workSchedule === WorkSchedule.schedule && (
                        <SecondaryText size="small">(optional)</SecondaryText>
                      )}
                      {!hasAttendance && formData.startDate && (
                        <SecondaryText size="small">
                          effective from {moment(formData.startDate).format('DD MMM YYYY')}
                        </SecondaryText>
                      )}
                    </Space>
                  }
                >
                  <EmpKeyValues id="calendar" value={formData.calendarId} readOnly />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  label="WH per day"
                  tooltip={{
                    title:
                      'No. of work hours per day is used in the computation of hourly rate for daily rated employees.',
                    icon: (
                      <span>
                        <i className="fal fa-circle-info" />
                      </span>
                    )
                  }}
                >
                  <Input.Number precision={2} value={formData.whPerDay} readOnly />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  label="WH per week"
                  tooltip={{
                    title:
                      'No. of work hours per week is used in the computation of hourly rate for monthly rated employees.',
                    icon: (
                      <span>
                        <i className="fal fa-circle-info" />
                      </span>
                    )
                  }}
                >
                  <Input.Number precision={2} value={formData.whPerWeek} readOnly />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  label="WD per week"
                  tooltip={{
                    title: 'No. of work days per week is used in the computation of daily rate (annualised) employees.',
                    icon: (
                      <span>
                        <i className="fal fa-circle-info" />
                      </span>
                    )
                  }}
                >
                  <Input.Number precision={2} value={formData.wdPerWeek} readOnly />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
        {showHistory && <Table rowKey="id" dataSource={histories} pagination={false} columns={columns} />}
        {canModify && <MutateEmCalendarDrawer {...drawerState} onClose={handleCloseDrawer} />}
      </Card>
    </div>
  )
}
