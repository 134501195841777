import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchDailyRecord } from '../actions'
import { DailyRecordState } from '../types'
import { FetchStrategy } from '~/types/common'

export const useDailyRecord = (
  id: string,
  fetchStrategy: FetchStrategy = 'when-empty'
): [DailyRecordState | undefined, boolean] => {
  const data = useSelector((state: StoreState) => state.attendance.dailyRecords?.entities[id])
  const loading = useSelector((state: StoreState) => state.attendance.dailyRecordsLoading)
  const refetch = useSelector((state: StoreState) => state.attendance.dailyRecordsRefetch)

  useEffect(() => {
    dispatch(fetchDailyRecord(id, { strategy: fetchStrategy }))
  }, [id, fetchStrategy])

  useEffect(() => {
    dispatch(fetchDailyRecord(id, { strategy: 'always' }))
  }, [id, refetch])

  return [data, loading]
}
