import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import persistReducer from 'redux-persist/es/persistReducer'
import {
  CalendarViewerCategory,
  calendarViewerPersistConfig,
  CalendarViewerRootState,
  CalendarViewerState
} from './types'

const calendarViewersAdapter = createEntityAdapter<CalendarViewerState>()

const calendarViewersInitialState = calendarViewersAdapter.getInitialState()

const initialState: CalendarViewerRootState = {
  calendarViewers: {},
  calendarViewersLoading: {}
}

const calendarViewerSlice = createSlice({
  name: 'calendarviewer',
  initialState,
  reducers: {
    replaceCalendarViewers: (
      state,
      action: PayloadAction<{ category: CalendarViewerCategory; data: CalendarViewerState[] }>
    ) => {
      const { category, data } = action.payload
      calendarViewersAdapter.setAll(state.calendarViewers[category]!, data)
    },
    setCalendarViewer: (
      state,
      action: PayloadAction<{ category: CalendarViewerCategory; data: CalendarViewerState }>
    ) => {
      const { category, data } = action.payload
      calendarViewersAdapter.upsertOne(state.calendarViewers[category]!, data)
    },
    removeCalendarViewer: (state, action: PayloadAction<{ category: CalendarViewerCategory; id: string }>) => {
      const { category, id } = action.payload
      calendarViewersAdapter.removeOne(state.calendarViewers[category]!, id)
    },
    setCalendarViewersLoading: (
      state,
      action: PayloadAction<{ category: CalendarViewerCategory; loading: boolean }>
    ) => {
      const { category, loading } = action.payload
      state.calendarViewers[category] = state.calendarViewers[category] || calendarViewersInitialState
      state.calendarViewersLoading[category] = loading
    }
  }
})

export const { replaceCalendarViewers, setCalendarViewer, removeCalendarViewer, setCalendarViewersLoading } =
  calendarViewerSlice.actions

export const calendarViewerReducers = {
  calendarViewer: persistReducer<CalendarViewerRootState>(calendarViewerPersistConfig, calendarViewerSlice.reducer)
}
