import React, { FC } from 'react'
import { Descriptions } from '~/core-components'
import { EmployeeState, useSysOptions } from '~/features/employee'

interface CopyEmInfoProps {
  employee?: EmployeeState
}

export const CopyEmInfo: FC<CopyEmInfoProps> = ({ employee }) => {
  const [emTitles] = useSysOptions('em_title')

  return (
    <Descriptions bordered column={1} className="copy-em__content">
      <Descriptions.Item label="Title">{emTitles[employee?.title || '']?.value}</Descriptions.Item>
      <Descriptions.Item label="First name">{employee?.firstName}</Descriptions.Item>
      <Descriptions.Item label="Last name">{employee?.lastName}</Descriptions.Item>
      <Descriptions.Item label="Full name (as per IC)">{employee?.fullName}</Descriptions.Item>
      <Descriptions.Item label="Preferred name">{employee?.preferredName}</Descriptions.Item>
      <Descriptions.Item label="Work email">{employee?.email}</Descriptions.Item>
      <Descriptions.Item label="Work phone">{employee?.workPhone}</Descriptions.Item>
    </Descriptions>
  )
}
