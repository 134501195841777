import { request, getBaseUrl } from '~/utils/request'
import { SysCriteriaFieldState, SysCriteriaOptionKeyValue, SysCriteriaScreenState } from '../types'

const baseUrl = getBaseUrl('/employee/syscriteria')

export const apiGetCriteriaScreens = (screenCode: string) =>
  request<SysCriteriaScreenState[]>('get', `${baseUrl}/${screenCode}/screen`)

export const apiGetCriteriaFields = (screenCode: string) =>
  request<SysCriteriaFieldState[]>('get', `${baseUrl}/${screenCode}/selection`)

export const apiGetCriteriaOptions = (screenCode: string, criteriaOptionCode: string) =>
  request<SysCriteriaOptionKeyValue[]>('get', `${baseUrl}/${screenCode}/options/${criteriaOptionCode}`)
