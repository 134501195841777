import React, { FC } from 'react'
import { default as AntTabs, TabPaneProps as AntTabPaneProps } from 'antd/lib/tabs'

const { TabPane: AntTabPane } = AntTabs

export interface TabPaneProps extends AntTabPaneProps {}

export const TabPane: FC<TabPaneProps> = (props: TabPaneProps) => {
  return <AntTabPane {...props} />
}
