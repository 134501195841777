import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { apiUpdateEmailGroup } from '../api/email-group.api'
import { EmailGroupState, IEmailGroupInfo } from '../types/email-group.state'
import { setEmailGroup } from '../reducers'

export const updateEmailGroup = <T extends IEmailGroupInfo>(id: string, before: T, request: T): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const after = { ...before, ...request } as T
    const patch = generateJsonPatch<T>(before, after)
    const { status, errors, message, errorData } = await apiUpdateEmailGroup(id, patch)

    if (status) {
      const emailGroup = getState().master.emailGroups?.entities[id]
      const updated = { ...(emailGroup as EmailGroupState), ...after }
      dispatch(setEmailGroup(updated))

      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
