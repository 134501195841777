import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import persistReducer from 'redux-persist/es/persistReducer'
import { ManagerCategory, managerPersistConfig, ManagerRootState, ManagerState } from './types'

const managersAdapter = createEntityAdapter<ManagerState>()

const managersInitialState = managersAdapter.getInitialState()

const initialState: ManagerRootState = {
  managers: {},
  managersLoading: {}
}

const managerSlice = createSlice({
  name: 'manager',
  initialState,
  reducers: {
    replaceManagers: (state, action: PayloadAction<{ category: ManagerCategory; data: ManagerState[] }>) => {
      const { category, data } = action.payload
      managersAdapter.setAll(state.managers[category]!, data)
    },
    setManager: (state, action: PayloadAction<{ category: ManagerCategory; data: ManagerState }>) => {
      const { category, data } = action.payload
      managersAdapter.upsertOne(state.managers[category]!, data)
    },
    removeManager: (state, action: PayloadAction<{ category: ManagerCategory; id: string }>) => {
      const { category, id } = action.payload
      managersAdapter.removeOne(state.managers[category]!, id)
    },
    setManagersLoading: (state, action: PayloadAction<{ category: ManagerCategory; loading: boolean }>) => {
      const { category, loading } = action.payload
      state.managers[category] = state.managers[category] || managersInitialState
      state.managersLoading[category] = loading
    }
  }
})

export const { replaceManagers, setManager, removeManager, setManagersLoading } = managerSlice.actions

export const managerReducers = {
  manager: persistReducer<ManagerRootState>(managerPersistConfig, managerSlice.reducer)
}
