import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { FeatureFlag } from '~/constants'
import { dispatch } from '~/stores/store'
import { Absence } from './Absence'
import { fetchOthersLeaveRecords } from '../../../../actions'
import { selectThisWeekAbsences } from '../../../../selectors'
import { MySsAccessGate } from '../../../MySsAccessGate'

interface AbsenceThisWeekProps {}

export const AbsenceThisWeek: FC<AbsenceThisWeekProps> = () => {
  const outThisWeek = useSelector(selectThisWeekAbsences)

  useEffect(() => {
    dispatch(fetchOthersLeaveRecords({ strategy: 'when-empty' }))
  }, [])

  return (
    <MySsAccessGate featureFlag={FeatureFlag.MyLeave}>
      <Absence title="Out this week" className="home__card" data={outThisWeek} />
    </MySsAccessGate>
  )
}
