import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { Form } from '~/core-components'
import { Col, EditableCard, EditableCardState, PercentInput, Row } from '~/components'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { dispatch } from '~/stores/store'
import { Errors, ActionResult } from '~/types/store'
import { useFocus } from '~/hooks/use-focus'
import { IPayGroupSalaryCalculation, PayGroupState } from '../../../types'
import { mapPayGroupStateToPayGroupSalaryCalculation } from '../../../types/paygroup.mapper'
import { updateMaster } from '../../../actions'

interface PayGroupSalaryCalculationProps {
  payGroup?: PayGroupState
  onEdit?: () => void
  onSave?: () => void
  onCancel?: () => void
}

export const EMPTY_FORM_DATA: IPayGroupSalaryCalculation = {
  half1Rate: 0
}

const cardStyle: CSSProperties = { margin: 24 }
const percentStyle: CSSProperties = { width: 80 }

export const PayGroupSalaryCalculation: FC<PayGroupSalaryCalculationProps> = ({
  payGroup,
  onEdit,
  onSave,
  onCancel
}: PayGroupSalaryCalculationProps) => {
  const [cardState, setCardState] = useState<EditableCardState>()
  const [formData, setFormData] = useState<IPayGroupSalaryCalculation>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const [focusRef] = useFocus(cardState === 'editing')
  const canModify = usePermissionGate(Permission.payMaster, PermissionAction.Modify)

  useEffect(() => {
    if (payGroup) {
      const { half1Rate } = payGroup
      setFormData({ half1Rate })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [payGroup])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => setFormData({ ...formData, ...updates }),
    [formData]
  )

  const handleEdit = useCallback(() => {
    setCardState('editing')
    typeof onEdit === 'function' && onEdit()
  }, [onEdit])

  const handleSave = useCallback(async () => {
    if (payGroup) {
      setCardState('saving')
      setErrors(undefined)

      typeof onSave === 'function' && onSave()

      let result: ActionResult | undefined
      try {
        result = await dispatch(
          updateMaster('payGroup', payGroup.id, mapPayGroupStateToPayGroupSalaryCalculation(payGroup), formData)
        )
      } catch {
        setCardState('editing')
      }

      if (result?.errors) {
        setCardState('editing')
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setCardState(undefined)
      }
    }
  }, [payGroup, formData, onSave])

  const handleCancel = useCallback(() => {
    setCardState(undefined)
    typeof onCancel === 'function' && onCancel()
    if (payGroup) {
      const { half1Rate } = payGroup
      setFormData({ half1Rate })
    }
  }, [payGroup, onCancel])

  return (
    <EditableCard
      title="Basic salary calculation"
      style={cardStyle}
      bodyStyle={{ paddingBottom: payGroup ? 6 : 24, paddingTop: 6 }}
      state={canModify ? cardState : 'readonly'}
      formId="form-paygroup-salary"
      onEdit={handleEdit}
      onSave={handleSave}
      onCancel={handleCancel}
    >
      <Row hidden={!payGroup?.isMidMonth}>
        <Col span={24}>
          <Form.Item label="" validateStatus={errors?.half1Rate ? 'error' : ''} help={errors?.half1Rate}>
            {`1st half rate `}
            <PercentInput
              ref={focusRef}
              value={formData.half1Rate}
              readOnly={cardState !== 'editing' && cardState !== 'saving'}
              style={percentStyle}
              onChange={(half1Rate: number | null) => handleFormDataChange({ half1Rate })}
            />
            {` of the monthly basic salary`}
          </Form.Item>
        </Col>
      </Row>
    </EditableCard>
  )
}
