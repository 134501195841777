import React, { FC, useCallback, useState } from 'react'
import { Page } from 'react-pdf'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from '~/core-components'
import { PdfDocAuth, PdfDocAuthProps } from '../PdfDocAuth/PdfDocAuth'
import { Paper } from '../Paper/Paper'
import './PdfViewer.less'

export interface PdfViewerProps extends PdfDocAuthProps {
  layout?: 'portrait' | 'landscape'
  scale?: number
}

export const PdfViewer: FC<PdfViewerProps> = ({ onLoadSuccess, layout = 'portrait', scale = 1, ...props }) => {
  const [totalPages, setTotalPages] = useState(0)

  const onDocumentLoadSuccess = useCallback(({ numPages }) => {
    setTotalPages(numPages)
  }, [])

  return (
    <PdfDocAuth
      className="pdf-viewer"
      onLoadSuccess={onDocumentLoadSuccess}
      loading={<Spin indicator={<LoadingOutlined spin />} />}
      {...props}
    >
      {Array.apply(null, Array(totalPages))
        .map((x, i) => i + 1)
        .map(page => (
          <Paper key={page} layout={layout} scale={scale}>
            <Page pageNumber={page} scale={scale} renderTextLayer={false} />
          </Paper>
        ))}
    </PdfDocAuth>
  )
}
