import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { Button, Card, ColumnsType, Form, PageHeader, Space, Table } from '~/core-components'
import { DocumentTitle, InactiveTag, SearchInput } from '~/components'
import { usePermissionGate } from '~/features/iam'
import {
  fetchViewSchema,
  Screen,
  ViewCriteria,
  ViewCriteriaSimple,
  updateViewCriteria,
  useFirstView,
  useViewSchema
} from '~/features/selection'
import { Permission, PermissionAction } from '~/constants'
import { fetchEmpKeyvalues } from '~/features/master'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { isInactive } from '~/utils'
import { fetchLeaveTypesView, fetchSysLeaveTypes } from '../../actions'
import { selectLeaveTypesView } from '../../selectors'
import { LeaveTypeState } from '../../types'
import { LeaveTypeName } from '../LeaveEntitlement/components/LeaveTypeName'
import { AddLeaveTypeDrawer } from './components/AddLeaveTypeDrawer'
import { SysLeaveTypeTypeTag } from './components/SysLeaveTypeTypeTag'
import './LeaveTypes.less'

type LeaveTypeTable = LeaveTypeState

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: '',
    breadcrumbName: 'Overview'
  }
]

interface DrawerState {
  visible: boolean
}

const SCREEN_CODE: Screen = 'leave_type'
const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

export const LeaveTypes: FC = () => {
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const canModify = usePermissionGate(Permission.lveType, PermissionAction.Modify)
  const leaveTypes = useSelector(selectLeaveTypesView)
  const dataLoading = useSelector((state: StoreState) => state.leave.leaveTypesLoading)

  const [view, viewLoading] = useFirstView(SCREEN_CODE)
  const viewId = view?.id || ''
  const [schema, schemaLoading] = useViewSchema(SCREEN_CODE, viewId)
  const [search, setSearch] = useState<string>('')

  useEffect(() => {
    dispatch(
      fetchEmpKeyvalues('sysLeaveTypeType', 'sysoption', { type: 'sys_leave_type_type' }, { strategy: 'when-empty' })
    )
  }, [])

  useEffect(() => {
    if (viewId) dispatch(fetchViewSchema(SCREEN_CODE, viewId))
  }, [viewId])

  useDeepCompareEffect(() => {
    if (schema && viewId) {
      dispatch(fetchLeaveTypesView(viewId, search))
    }
  }, [schema || {}, viewId, search])

  const handleAddLeaveType = useCallback(() => {
    setDrawerState({ visible: true })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  useEffect(() => {
    dispatch(fetchSysLeaveTypes({ strategy: 'when-empty' }))
  }, [])

  const handleCriteriaApply = useCallback(
    (criteria: ViewCriteria[]) => {
      if (schema) {
        dispatch(updateViewCriteria(SCREEN_CODE, schema.id, { id: schema.id, criteria }))
      }
    },
    [schema]
  )

  const handleSearch = useCallback((value: string) => {
    setSearch(value)
  }, [])

  const columns: ColumnsType<LeaveTypeTable> = [
    {
      title: 'Leave type',
      key: 'name',
      dataIndex: 'name',
      render: (value: string, record) => (
        <Space>
          <LeaveTypeName id={record.id} path={SETTINGS_ROUTES.leaveType.replace(':id', record.id)} />
          {isInactive(record.inactiveDate) && <InactiveTag />}
        </Space>
      )
    },
    {
      title: 'Type',
      key: ' type',
      dataIndex: 'sysLeaveTypeCode',
      render: (value: string) => <SysLeaveTypeTypeTag code={value} />
    }
  ]

  return (
    <div id="leave-types" className="leave-types">
      <DocumentTitle title="Leave Types" />
      <PageHeader
        title="Leave types"
        containerId="leave-types"
        breadcrumb={{ routes }}
        extra={
          canModify && (
            <Button key="add" onClick={handleAddLeaveType}>
              Add leave type
            </Button>
          )
        }
      />
      <div className="leave-types__body">
        <div className="leave-types__action-bar">
          <Form.Item label="">
            <SearchInput onSearch={handleSearch} />
          </Form.Item>
          <ViewCriteriaSimple screenCode={SCREEN_CODE} viewId={viewId} onApply={handleCriteriaApply} label="" />
        </div>
        <Card table>
          <Table
            rowKey="id"
            dataSource={leaveTypes}
            pagination={false}
            columns={columns}
            loading={dataLoading || viewLoading || schemaLoading}
          ></Table>
        </Card>
      </div>
      {canModify && <AddLeaveTypeDrawer {...drawerState} onClose={handleCloseDrawer} />}
    </div>
  )
}
