import React, { FC } from 'react'
import { Card, Descriptions } from '~/core-components'
import { SSEmPublicSelfState } from '~/features/my'

interface MyBasicInfoProps {
  data: SSEmPublicSelfState
}

export const MyBasicInfo: FC<MyBasicInfoProps> = ({ data }) => {
  return (
    <Card title="Basic information">
      <Descriptions colon={false} column={2}>
        <Descriptions.Item label="Employee no.">{data.employeeNo || '-'}</Descriptions.Item>
        <Descriptions.Item label="Title">{data.titleName || '-'}</Descriptions.Item>
        <Descriptions.Item label="First name">{data.firstName || '-'}</Descriptions.Item>
        <Descriptions.Item label="Last name">{data.lastName || '-'}</Descriptions.Item>
        <Descriptions.Item label="Full name">{data.fullName || '-'}</Descriptions.Item>
        <Descriptions.Item label="Preferred name">{data.preferredName || '-'}</Descriptions.Item>
        <Descriptions.Item label="Work email">{data.email || '-'}</Descriptions.Item>
        <Descriptions.Item label="Work phone">{data.workPhone || '-'}</Descriptions.Item>
      </Descriptions>
    </Card>
  )
}
