import { showSuccess, showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { setKeyvalue } from '~/features/master'
import { apiAddShiftRole } from '../api/shift-role.api'
import { setShiftRole } from '../reducers'
import { IShiftRole, ShiftRoleState } from '../types'

export const addShiftRole = (shiftRole: IShiftRole): ThunkResult<void> => {
  return async (dispatch): Promise<ActionResult> => {
    const { status, result, errors, message, errorData } = await apiAddShiftRole(shiftRole)
    if (status) {
      const newShiftRole: ShiftRoleState = {
        ...shiftRole,
        id: result.id
      }
      dispatch(setShiftRole(newShiftRole))
      dispatch(
        setKeyvalue({
          kv: 'shiftRole',
          data: { key: result.id, value: newShiftRole.name, inactiveDate: newShiftRole.inactiveDate }
        })
      )
      showSuccess('Added')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
