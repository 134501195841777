import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Collapse, List } from '~/core-components'
import { AmountDisplay, Col, InfoTooltip, Row } from '~/components'
import { ClaimTypeName, fetchClaimTypes } from '~/features/claim'
import { ClaCurrencyCode, ClaEntitlementType } from '~/constants'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchMyClaimBalances } from '../../actions'
import { useMyClaimBalances } from '../../hooks'
import { SSClaimBalanceState } from '../../types'
import './MyClaimBalanceList.less'

interface MyClaimBalanceListProps {}

export const MyClaimBalanceList: FC<MyClaimBalanceListProps> = () => {
  const [expand, setExpand] = useState<string>()
  const [claimBalances, periods, loading] = useMyClaimBalances()
  const refetch = useSelector((state: StoreState) => state.myClaim.myClaimBalancesRefetch)
  const withBalance = claimBalances.filter(c => c?.entitlementType === ClaEntitlementType.PerPeriod)

  useEffect(() => {
    dispatch(fetchClaimTypes({ strategy: 'when-empty' }))
  }, [])

  useEffect(() => {
    dispatch(fetchMyClaimBalances())
  }, [refetch])

  const handleExpand = useCallback((rowKey: string) => {
    setExpand(expand => (expand === rowKey ? undefined : rowKey))
  }, [])

  return (
    <div className="my-claim-balance-list">
      {claimBalances.length === 0 && !loading && <>No claim entitled</>}

      {withBalance.length > 0 &&
        periods.map(p => {
          const data = withBalance.filter(b => b?.periodCode === p.periodCode)
          if (data.length === 0) {
            return null
          }

          return (
            <div key={p.periodCode}>
              {periods.length > 1 && p.startDate && p.endDate && (
                <div className="subheader">
                  {moment(p.startDate).format('DD MMM YYYY')} - {moment(p.endDate).format('DD MMM YYYY')}
                </div>
              )}
              <List
                size="small"
                dataSource={data}
                renderItem={(item: SSClaimBalanceState, index: number) => (
                  <List.Item key={item.id}>
                    <Collapse
                      activeKey={expand}
                      onChange={() => handleExpand(`${p.periodCode}-${index}`)}
                      expandIconPosition="end"
                    >
                      <Collapse.Panel
                        key={`${p.periodCode}-${index}`}
                        header={
                          <Row align="top">
                            <Col flex="1">
                              <ClaimTypeName id={item.claimTypeId} />
                            </Col>
                            <Col flex="none">
                              <AmountDisplay symbol={item.currencyCode || ClaCurrencyCode.sgd} value={item.balance} />
                            </Col>
                          </Row>
                        }
                      >
                        <Row>
                          <Col flex="1">Earned</Col>
                          <Col flex="none">
                            <AmountDisplay value={item.earned} />
                            <InfoTooltip
                              title={`Earned as of ${item.asAtDate ? moment(item.asAtDate).format('DD MMM YYYY') : ''}`}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col flex="1">Taken</Col>
                          <Col flex="none">
                            <AmountDisplay value={item.taken} />
                          </Col>
                        </Row>
                        <Row>
                          <Col flex="1">
                            <b>Balance</b>
                          </Col>
                          <Col flex="none">
                            <AmountDisplay value={item.balance} />
                          </Col>
                        </Row>
                      </Collapse.Panel>
                    </Collapse>
                  </List.Item>
                )}
              />
            </div>
          )
        })}
    </div>
  )
}
