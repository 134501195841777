import React, { CSSProperties, FC } from 'react'
import { default as AntAvatar, AvatarProps as AntAvatarProps } from 'antd/lib/avatar'
import 'antd/es/avatar/style'

export interface AvatarProps extends AntAvatarProps {}
export const Avatar: FC<AvatarProps> = ({ size, ...props }: AvatarProps) => {
  const styles: CSSProperties = {}
  if (typeof size === 'number') {
    styles.fontSize = size / 2
    styles.fontWeight = 700
  }
  return <AntAvatar size={size} style={styles} {...props} />
}
