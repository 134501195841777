import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { PayTranImportState } from '../types'

export const selectPayTranImports = createSelector(
  (state: StoreState) => state.payroll.payTranImports,
  payTranImportsState =>
    memoize(
      (batchId: string, employeeId: string, payItemId: string): PayTranImportState[] => {
        const emBatchId = `${employeeId}||${batchId}`

        return (Object.values(payTranImportsState[emBatchId]?.entities || {}) as PayTranImportState[])
          .filter(imp => imp.payItemId === payItemId)
          .sort((a, b) => {
            const aStartDate = new Date(a!.startDate).getTime()
            const bStartDate = new Date(b!.startDate).getTime()
            const aEndDate = new Date(a!.endDate).getTime()
            const bEndDate = new Date(b!.endDate).getTime()

            if (bEndDate === 0 && aEndDate === 0) {
              return aStartDate - bStartDate
            } else {
              if (bEndDate === 0) {
                return 1
              } else if (aEndDate === 0) {
                return -1
              } else {
                return aEndDate - bEndDate
              }
            }
          })
      },
      (batchId: string, payItemId: string) => `${batchId}||${payItemId}`
    )
)
