import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { EmAddressState, IEmAddress } from '../types'

const baseUrl = getBaseUrl('/employee/employee')

export const apiGetEmAddresses = (employeeId: string) =>
  request<EmAddressState[]>('get', `${baseUrl}/${employeeId}/address`)

export const apiGetEmAddressMain = (employeeId: string) =>
  request<EmAddressState>('get', `${baseUrl}/${employeeId}/address/main`)

export const apiAddEmAddress = (employeeId: string, emAddress: IEmAddress) =>
  request<{ id: string; emAddressId?: string }>('post', `${baseUrl}/${employeeId}/address`, emAddress)

export const apiUpdateEmAddress = (employeeId: string, id: string, emAddress: Operation[]) =>
  request<{ emAddressId?: string }>('patch', `${baseUrl}/${employeeId}/address/${id}`, emAddress)

export const apiDeleteEmAddress = (employeeId: string, id: string) =>
  request<{ emAddressId?: string }>('delete', `${baseUrl}/${employeeId}/address/${id}`)
