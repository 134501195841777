import { showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiExecuteLeaveClosing } from '../api/leave-closing.api'
import { IPendingAction } from '../types'
import { fetchLeavePeriodYears } from './fetch-leave-period-years'
import { fetchLeavePeriods } from './fetch-leave-periods'

export const executeLeaveClosing = (
  periodCode: string,
  leaveTypeIds: string[],
  pendingActions: IPendingAction[]
): ThunkResult<void> => {
  return async dispatch => {
    const { status, result, errors, message, errorData } = await apiExecuteLeaveClosing({
      periodCode,
      leaveTypeIds,
      pendingActions
    })
    if (status) {
      dispatch(fetchLeavePeriods())
      dispatch(fetchLeavePeriodYears())
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors, result }
  }
}
