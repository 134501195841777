import { replaceEmLeaveGroups, setEmLeaveGroupsLoading } from '../reducers'
import { apiGetEmLeaveGroups } from '../api/em-leave-group.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchEmLeaveGroups = (
  employeeId: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!employeeId) return

    const loading = getState().employee.emLeaveGroupsLoading[employeeId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().employee.emLeaveGroups[employeeId]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setEmLeaveGroupsLoading({ employeeId, loading: true }))
      const { result, status } = await apiGetEmLeaveGroups(employeeId)
      if (status) {
        dispatch(replaceEmLeaveGroups({ employeeId, data: result }))
      }
    } finally {
      dispatch(setEmLeaveGroupsLoading({ employeeId, loading: false }))
    }
  }
}
