import React, { FC, useState, useCallback, useEffect, ChangeEvent } from 'react'
import confirm from 'antd/lib/modal/confirm'
import { Form, Input } from '~/core-components'
import { Col, DrawerForm, Row } from '~/components'
import { dispatch } from '~/stores/store'
import { addCurrency, deleteCurrency, updateCurrency } from '../../../actions'
import { ICurrency, CurrencyState } from '../../../types'
import { mapCurrencyStateToCurrency } from '../../../types/currency.mapper'
import { ActionResult, Errors } from '~/types/store'
import { useFocus } from '~/hooks/use-focus'

export interface MutateCurrencyDrawerProps {
  visible: boolean
  data?: CurrencyState
  onClose: () => void
}

type CurrencyForm = Omit<ICurrency, 'isShown'>

const EMPTY_FORM_DATA: CurrencyForm = {
  code: '',
  name: ''
}

export const MutateCurrencyDrawer: FC<MutateCurrencyDrawerProps> = ({
  visible,
  data,
  onClose
}: MutateCurrencyDrawerProps) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<CurrencyForm>(EMPTY_FORM_DATA)
  const [focusRef, setFocus] = useFocus(true)
  const [errors, setErrors] = useState<Errors>()

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  useEffect(() => {
    if (data) {
      setFormData({ code: data.code, name: data.name })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      if (data) {
        result = await dispatch(
          updateCurrency(data.code, mapCurrencyStateToCurrency(data), { ...formData, isShown: data.isShown })
        )
      } else {
        result = await dispatch(addCurrency({ ...formData, isShown: true }))
      }
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data, formData, onClose])

  const handleDelete = useCallback(
    (currency: CurrencyState | undefined) => {
      if (currency) {
        const { code, name } = currency
        confirm({
          title: 'Delete currency',
          content: `Do you want to delete currency record of "${name}"?`,
          onOk: async () => {
            const result: ActionResult | undefined = await dispatch(deleteCurrency(code))
            if (result?.errors) {
              setErrors(result.errors)
            }

            if (!result?.errors) {
              typeof onClose === 'function' && onClose()
            }
          },
          okText: 'Delete',
          okType: 'danger'
        })
      }
    },
    [onClose]
  )

  return (
    <DrawerForm
      open={visible}
      title={data ? 'Edit currency' : 'Add currency'}
      onClose={onClose}
      confirmLoading={loading}
      width={500}
      showDelete={data ? true : false}
      onDelete={() => handleDelete(data)}
      formId="form-currency"
    >
      <Form id="form-currency" onFinish={handleOk}>
        <Row>
          <Col span={24}>
            <Form.Item label="Code" validateStatus={errors?.code ? 'error' : ''} help={errors?.code}>
              <Input
                ref={focusRef}
                value={formData.code}
                disabled={data != null}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ code: event.target.value })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Name" validateStatus={errors?.name ? 'error' : ''} help={errors?.name}>
              <Input
                value={formData.name}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ name: event.target.value })}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}
