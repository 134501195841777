import React, { ComponentType, FC } from 'react'
import { useSelector } from 'react-redux'
import { Route, RouteComponentProps, RouteProps } from 'react-router-dom'
import { ZealysLoading } from '~/components'
import { StoreState } from '~/types/store'
import { AuthConsumer } from '../auth/AuthProvider'

interface PrivateRouteProps extends RouteProps {}

export const PrivateRoute: FC<PrivateRouteProps> = ({ component, render, ...rest }) => {
  const myJwt = useSelector((state: StoreState) => state.iam.myJwt)

  const renderFn =
    (Component?: ComponentType | ComponentType<any>, render?: (props: RouteComponentProps<any>) => React.ReactNode) =>
    (props: any) => {
      return (
        <AuthConsumer>
          {({ isAuthenticated, signinRedirect }) => {
            const isAuth = isAuthenticated()
            if (isAuth) {
              if (!myJwt?.tenant) return null
              if (!!Component) return <Component {...props} />
              if (!!render) return render(props)
            } else {
              const tenant = localStorage.getItem('tenant')
              const searchParams = new URLSearchParams(window.location.search)
              signinRedirect({ extraQueryParams: { tenant, mode: searchParams.get('mode') } })
              return <ZealysLoading />
            }
          }}
        </AuthConsumer>
      )
    }

  return <Route {...rest} render={renderFn(component, render)} />
}
