import { setIrasLog, setIrasLogsLoading } from '../reducers'
import { apiGetIrasLog } from '../api/ytd-submission.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchIrasLog = (
  ytdSubmissionId: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!ytdSubmissionId) return

    const loading = getState().tax.irasLogsLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().tax.irasLogs?.entities[ytdSubmissionId]
      if (hasData) return
    }

    try {
      dispatch(setIrasLogsLoading(true))
      const { result, status } = await apiGetIrasLog(ytdSubmissionId)
      if (status) {
        dispatch(setIrasLog(result))
      }
    } finally {
      dispatch(setIrasLogsLoading(false))
    }
  }
}
