import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Form, Switch } from '~/core-components'
import { Col, DrawerForm, Row } from '~/components'
import { useLoginPermissions, useMyLogin } from '~/features/iam'
import { CriteriaOptions } from './components/CriteriaOptions'
import { CriteriaValueType } from '~/constants'
import { selectSysCriteriaFields } from '../selectors'
import { SysCriteriaFieldState, ICriteriaKeyValue, Screen } from '../types'
import { mapViewCriteriaToCriteriaKeyValue } from '../types/view.mapper'
import { useViewSchema } from '../hooks'
import './ViewCriteriaSimpleDrawer.less'

interface ViewCriteriaSimpleDrawerProps {
  visible: boolean
  screenCode: Screen
  viewId: string
  onApply: (criteriaValues: ICriteriaKeyValue) => void
  onClose: () => void
}

const activeTenantCode = localStorage.getItem('tenant')

export const ViewCriteriaSimpleDrawer: FC<ViewCriteriaSimpleDrawerProps> = ({
  visible,
  screenCode,
  viewId,
  onApply,
  onClose
}) => {
  const criteria = useSelector(selectSysCriteriaFields)(screenCode)
  const [schema] = useViewSchema(screenCode, viewId, 'when-empty')
  const [criteriaValues, setCriteriaValues] = useState<ICriteriaKeyValue>()
  const [myLogin] = useMyLogin()
  const [loginPermissions] = useLoginPermissions(myLogin.id || '', activeTenantCode)
  const permittedPermissions = Array.from(new Set(loginPermissions.map(p => p.sysPermissionId)))

  const reset = useCallback(() => {
    if (schema) {
      const mapped = mapViewCriteriaToCriteriaKeyValue(schema.criteria)
      setCriteriaValues(mapped)
    }
  }, [schema, setCriteriaValues])

  useEffect(() => reset(), [reset])

  const handleCriteriaChange = useCallback(
    (fieldId: string, value: string[]) => {
      const newCriteriaValues = { ...(criteriaValues || {}), [fieldId]: value }
      setCriteriaValues(newCriteriaValues)
    },
    [setCriteriaValues, criteriaValues]
  )

  const handleSave = useCallback(() => {
    if (typeof onApply === 'function') {
      if (criteriaValues) {
        onApply(criteriaValues)
      }
    }
    typeof onClose === 'function' && onClose()
  }, [onApply, criteriaValues, onClose])

  const handleShowChange = useCallback(
    (criteriaField: SysCriteriaFieldState, checked: boolean) => {
      if (checked) {
        setCriteriaValues({ ...(criteriaValues || {}), [criteriaField.id]: [] })
      } else {
        const values = { ...(criteriaValues || {}) }
        delete values[criteriaField.id]
        setCriteriaValues(values)
      }
    },
    [setCriteriaValues, criteriaValues]
  )

  return (
    <DrawerForm
      open={visible}
      title="Filters"
      onOk={handleSave}
      onClose={onClose}
      okText="Apply"
      width={600}
      className="view-criteria-simple-drawer"
    >
      <Form layout="horizontal" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
        <Row gutter={30}>
          <Col flex={1}></Col>
          <Col>
            <label>Show</label>
          </Col>
        </Row>
        {criteriaValues &&
          criteria
            .filter(c => c?.valueType === CriteriaValueType.option)
            .map(
              c =>
                c?.id &&
                (myLogin.isOwner ||
                  myLogin.isSuperUser ||
                  !c.sysPermissionId ||
                  permittedPermissions.includes(c.sysPermissionId)) && (
                  <Row gutter={15} key={c.id}>
                    <Col flex={1}>
                      <Form.Item label={c?.description}>
                        <CriteriaOptions
                          screenCode={screenCode}
                          criteria={c}
                          maxTagCount={undefined}
                          value={criteriaValues[c.id]}
                          placeholder="Please select"
                          onChange={(value: string[]) => c.id && handleCriteriaChange(c.id, value)}
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item>
                        <Switch
                          size="small"
                          checked={criteriaValues[c.id] ? true : false}
                          onChange={(checked: boolean) => handleShowChange(c, checked)}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )
            )}
      </Form>
    </DrawerForm>
  )
}
