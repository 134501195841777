import React, { createContext, FC, useContext, useState } from 'react'
import { MenuItem } from '~/types/common'

interface MasterMenu {
  menus: MenuItem[]
  setMenus: (menus: MenuItem[]) => void
  siderWidth: number
  setSiderWidth: (width: number) => void
}

const DEFAULT: MasterMenu = {
  menus: [],
  setMenus: () => {},
  siderWidth: 220,
  setSiderWidth: () => {}
}

const MasterMenuContext = createContext<MasterMenu>(DEFAULT)

export const useMasterMenu = () => {
  return useContext(MasterMenuContext)
}

interface MasterMenuProviderProps {
  menus: MenuItem[]
}

export const MasterMenuProvider: FC<MasterMenuProviderProps> = ({ children, menus: initialMenus }) => {
  const [menus, setMenus] = useState<MenuItem[]>(initialMenus)
  const [siderWidth, setSiderWidth] = useState<number>(220)
  return (
    <MasterMenuContext.Provider value={{ menus, setMenus, siderWidth, setSiderWidth }}>
      {children}
    </MasterMenuContext.Provider>
  )
}
