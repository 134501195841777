import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Dictionary } from '@reduxjs/toolkit'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { FetchStrategy } from '~/types/common'
import { fetchBreaks } from '../actions'
import { BreakState } from '../types'

export const useBreaksDict = (fetchStrategy: FetchStrategy = 'when-empty'): [Dictionary<BreakState>, boolean] => {
  const data = useSelector((state: StoreState) => state.attendance.breaks.entities)
  const loading = useSelector((state: StoreState) => state.attendance.breaksLoading)

  useEffect(() => {
    dispatch(fetchBreaks({ strategy: fetchStrategy }))
  }, [fetchStrategy])

  return [data, loading]
}
