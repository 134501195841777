import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { PayWarningState } from '../types'

export const selectPayWarnings = createSelector(
  (state: StoreState) => state.payroll.payWarnings,
  payWarningsState =>
    memoize((payRunId: string) => {
      const payWarnings = (Object.values(payWarningsState[payRunId]?.entities || {}) as PayWarningState[]).sort(
        (a, b) => {
          if (!a.verifiedBy && !b.verifiedBy) {
            return a.employeeName.localeCompare(b.employeeName)
          } else {
            if (!b.verifiedBy) {
              return 1
            } else if (!a.verifiedBy) {
              return -1
            } else {
              return 0
            }
          }
        }
      )
      return payWarnings
    })
)
