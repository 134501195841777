import { replaceCtProrationM, setCtProrationMLoading } from '../reducers'
import { apiGetCtProrationM } from '../api/ct-proration-m.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchCtProrationM = (
  claimTypeId: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().claim.ctProrationMLoading[claimTypeId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().claim.ctProrationM[claimTypeId]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setCtProrationMLoading({ claimTypeId, loading: true }))
      const { result, status } = await apiGetCtProrationM(claimTypeId)
      if (status) {
        dispatch(replaceCtProrationM({ claimTypeId, data: result }))
      }
    } finally {
      dispatch(setCtProrationMLoading({ claimTypeId, loading: false }))
    }
  }
}
