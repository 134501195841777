import React, { FocusEvent, forwardRef, useCallback } from 'react'
import { default as AntInput, InputProps as AntInputProps, InputRef } from 'antd/lib/input'
import 'antd/es/input/style'
import './Input.less'

export interface InputProps extends AntInputProps {}

const InputInternal = ({ onChange, onBlur, ...props }: InputProps, ref: React.Ref<InputRef>) => {
  const handleBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      if (!props.readOnly && !props.disabled) {
        const originalValue = event.target.value
        event.target.value = event.target.value.trim()

        if (event.target.value !== originalValue) {
          typeof onChange === 'function' && onChange(event)
        }
      }

      typeof onBlur === 'function' && onBlur(event)
    },
    [onChange, onBlur, props.readOnly, props.disabled]
  )

  return <AntInput ref={ref} {...props} onChange={onChange} onBlur={handleBlur} />
}

export const Input = forwardRef(InputInternal)
