import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'
import { apiGetROLeEarned } from '../../api/roleave-entitlement.api'
import { replaceROLeEarned, setROLeEarnedLoading } from '../../reducers'

export const fetchROLeEarned = (
  periodCode?: string,
  employeeId?: string,
  leaveTypeId?: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!periodCode || !employeeId || !leaveTypeId) return

    const entId = `${periodCode}|${employeeId}|${leaveTypeId}`

    const loading = getState().my.roLeEarnedLoading[entId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().my.roLeEarned[entId]
      if (hasData) return
    }

    try {
      dispatch(setROLeEarnedLoading({ entId, loading: true }))
      const { result, status } = await apiGetROLeEarned(periodCode, employeeId, leaveTypeId)
      if (status) {
        dispatch(replaceROLeEarned({ entId, data: result }))
      }
    } finally {
      dispatch(setROLeEarnedLoading({ entId, loading: false }))
    }
  }
}
