import React, { FC, useCallback, useEffect, useState } from 'react'
import { OrgsChart } from '~/core-components'
import { DocumentTitle } from '~/components'
import { request, timeout } from '~/utils/request'
import { apiGetChildrenChart, apiGetMyOrgCharts, apiGetParentChart } from '../../api/ssorgchart.api'
import {
  mapSSOrgChartResponsesToSSOrgChartState,
  mapSSOrgChartResToSSOrgChartState
} from '../../types/ssorgchart.mapper'
import { SSOrgChartState } from '../../types/ssorgchart.state'
import './MyOrgChart.less'

const EMPTY_TREE: SSOrgChartState = {
  id: '',
  person: { id: '', avatar: '', avatarSrc: '', department: '', name: '', title: '', totalReports: 0 },
  hasParent: false,
  hasChild: false,
  isHighlight: false,
  children: []
}

export const MyOrgChart: FC = () => {
  const [tree, setTree] = useState<SSOrgChartState>(EMPTY_TREE)
  const fetchRef = React.useRef(0)

  const fetcher = useCallback(async () => {
    fetchRef.current += 1
    const fetchId = fetchRef.current
    setTree(EMPTY_TREE)

    const { status, result } = await apiGetMyOrgCharts()
    if (status && fetchId === fetchRef.current) {
      const res = mapSSOrgChartResponsesToSSOrgChartState(result)
      setTree(res)
    }
  }, [])

  useEffect(() => {
    fetcher()
  }, [fetcher])

  const handleLoadParent = useCallback(async (d: SSOrgChartState) => {
    const { status, result } = await apiGetParentChart(d.id)
    if (status) {
      const parent = mapSSOrgChartResponsesToSSOrgChartState(result)
      parent.children?.push(d)

      return parent
    }
    return d
  }, [])

  const handleLoadChildren = useCallback(async (d: SSOrgChartState) => {
    const { status, result } = await apiGetChildrenChart(d.id)
    if (status) {
      return result.map(c => mapSSOrgChartResToSSOrgChartState(c))
    }
    return []
  }, [])

  const handleLoadImage = useCallback(async (d: SSOrgChartState) => {
    if (d.person.avatarSrc) {
      const { result, status } = await request('get', d.person.avatarSrc, undefined, {
        responseType: 'blob',
        timeout
      })
      if (status) {
        return URL.createObjectURL(result)
      }
    }
  }, [])

  if (!tree.id) {
    return null
  }

  return (
    <>
      <DocumentTitle title="Organisation Chart" />
      <OrgsChart
        tree={tree}
        loadParent={handleLoadParent}
        loadChildren={handleLoadChildren}
        loadImage={handleLoadImage}
      />
    </>
  )
}
