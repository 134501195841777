import { setCompany } from '../reducers'
import { apiGetCompany } from '../api/company.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'
import { getState } from '~/stores/store'

export const fetchCompany = (id: string, options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async dispatch => {
    if (!id) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().master.companies?.entities[id]
      if (hasData) return
    }

    const { result, status } = await apiGetCompany(id)
    if (status) {
      dispatch(setCompany(result))
    }
  }
}
