import React, { FC } from 'react'
import { TimeLogStatus } from '~/constants'
import { Tag } from '~/core-components'

interface TimeLogStatusTagProps {
  status: string | undefined
}

export const TimeLogStatusTag: FC<TimeLogStatusTagProps> = ({ status }) => {
  return (
    <>
      {status === TimeLogStatus.Pending ? (
        <Tag type="secondary">Pending for approval</Tag>
      ) : status === TimeLogStatus.Approved ? (
        <Tag type="success">Approved</Tag>
      ) : null}
    </>
  )
}
