import React, { FC, useCallback, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Card, Descriptions, Link, LinkGroup, List, Spin, Text, Tooltip } from '~/core-components'
import { Col, Row, SubHeader, TimeDayDisplay } from '~/components'
import { useSysOptions } from '~/features/employee'
import { usePermissionGate } from '~/features/iam'
import { NON_WORKING_DAY_CODES, Permission, PermissionAction } from '~/constants'
import { ShiftDayState, ShiftState } from '../../../types'
import { useBreaksDict, useOtConfigsDict, useShiftDays } from '../../../hooks'
import { EditShiftDayDrawer } from './EditShiftDayDrawer'
import { CopyShiftDayDrawer } from './CopyShiftDayDrawer'
import './ShiftDay.less'

interface ShiftDayProps {
  shift?: ShiftState
}

interface EditDrawerState {
  visible: boolean
  data?: ShiftDayState
}

const DEFAULT_EDIT_DRAWER_STATE: EditDrawerState = { visible: false }

interface CopyDrawerState {
  visible: boolean
  data?: ShiftDayState
}

const DEFAULT_COPY_DRAWER_STATE: CopyDrawerState = { visible: false }

export const ShiftDay: FC<ShiftDayProps> = ({ shift }) => {
  const shiftId = shift?.id || ''
  const [isAggregated, setIsAggregated] = useState(true)
  const [shiftDays, loading] = useShiftDays(shiftId, isAggregated)
  const [breaks] = useBreaksDict()
  const [editDrawerState, setEditDrawerState] = useState<EditDrawerState>(DEFAULT_EDIT_DRAWER_STATE)
  const [copyDrawerState, setCopyDrawerState] = useState<CopyDrawerState>(DEFAULT_COPY_DRAWER_STATE)
  const [shiftDayCodes] = useSysOptions('att_shift_day_code')
  const [roundingMethod] = useSysOptions('att_rounding_method')
  const [workStatus] = useSysOptions('work_status')
  const canModify = usePermissionGate(Permission.shift, PermissionAction.Modify)
  const [otConfigs] = useOtConfigsDict()

  const handleEditDay = useCallback((data: ShiftDayState) => {
    setEditDrawerState({ visible: true, data })
  }, [])

  const handleCloseEditDrawer = useCallback(() => {
    setEditDrawerState(DEFAULT_EDIT_DRAWER_STATE)
  }, [])

  const handleCopyDay = useCallback((data: ShiftDayState) => {
    setCopyDrawerState({ visible: true, data })
  }, [])

  const handleCloseCopyDrawer = useCallback(() => {
    setCopyDrawerState(DEFAULT_COPY_DRAWER_STATE)
  }, [])

  if (!shift) return null

  return (
    <Card
      title={
        <Row gutter={15}>
          <Col flex="1">
            <b>Shift</b>
          </Col>
          <Col flex="1">
            <b>Overtime</b>
          </Col>
          <Col flex="1">
            <Row>
              <Col flex="1">
                <b>Half day</b>
              </Col>
              <Col>{loading && <Spin indicator={<LoadingOutlined spin />} />}</Col>
            </Row>
          </Col>
        </Row>
      }
      className="shift-day"
      bodyStyle={{ paddingBottom: shift && !loading ? 6 : 24, paddingTop: 6 }}
    >
      <List
        dataSource={shiftDays}
        renderItem={(item: ShiftDayState) => {
          const {
            dayCode,
            breakId,
            graceLatenessMinutes,
            graceUndertimeMinutes,
            otBeforeStartTime,
            otBeforeStartMinHours,
            otBeforeStartMaxHours,
            otBeforeStartRate,
            otAfterEndTime,
            otAfterEndMinHours,
            otAfterEndMaxHours,
            otAfterEndRate,
            otWithinShiftRate,
            otWithinBeforeMidnightRate,
            totalOtMinHours,
            totalOtMaxHours,
            h1EndTime,
            h2StartTime
          } = item

          const showBreak = Boolean(breakId)
          const showGrace = graceLatenessMinutes > 0 || graceUndertimeMinutes > 0
          const showOvertime = NON_WORKING_DAY_CODES.includes(dayCode)
            ? true
            : Boolean(otBeforeStartTime) ||
              otBeforeStartMinHours > 0 ||
              otBeforeStartMaxHours > 0 ||
              Boolean(otBeforeStartRate) ||
              Boolean(otAfterEndTime) ||
              otAfterEndMinHours > 0 ||
              otAfterEndMaxHours > 0 ||
              Boolean(otAfterEndRate) ||
              Boolean(otWithinShiftRate) ||
              Boolean(otWithinBeforeMidnightRate) ||
              totalOtMinHours > 0 ||
              totalOtMaxHours > 0
          const showHalfDay = Boolean(h1EndTime) || Boolean(h2StartTime)

          return (
            <List.Item key={item.id} className="shift-day__list-item">
              <SubHeader>
                <Row>
                  <Col flex="1">
                    <b>
                      {isAggregated && Array.isArray(item.dayCode)
                        ? (item.dayCode as string[])
                            .slice()
                            .sort((a, b) => (shiftDayCodes[a]?.sequence || 0) - (shiftDayCodes[b]?.sequence || 0))
                            .map(dayCode => shiftDayCodes[dayCode]?.value)
                            .join(', ')
                        : shiftDayCodes[item.dayCode]?.value}
                    </b>
                  </Col>
                  <Col>
                    {isAggregated ? (
                      Array.isArray(item.id) && item.id.length === 1 ? (
                        <LinkGroup>
                          <Link onClick={() => handleCopyDay({ ...item, id: item.id[0], dayCode: item.dayCode[0] })}>
                            copy
                          </Link>
                          <Link onClick={() => handleEditDay({ ...item, id: item.id[0], dayCode: item.dayCode[0] })}>
                            edit
                          </Link>
                        </LinkGroup>
                      ) : (
                        <Tooltip title="Expand the days to edit">
                          <Link onClick={() => setIsAggregated(false)}>
                            <i className="fal fa-pen-to-square" />
                          </Link>
                        </Tooltip>
                      )
                    ) : (
                      <LinkGroup>
                        <Link onClick={() => handleCopyDay(item)}>copy</Link>
                        <Link onClick={() => handleEditDay(item)}>edit</Link>
                      </LinkGroup>
                    )}
                  </Col>
                </Row>
              </SubHeader>
              <Row gutter={15}>
                <Col span={8}>
                  <Row>
                    <Col span={24}>
                      <Descriptions colon={false} column={1} className="shift-day__normal">
                        <Descriptions.Item label="Start time">
                          <TimeDayDisplay time={item.normalStartTime} day={item.normalStartDay} />
                        </Descriptions.Item>
                        <Descriptions.Item label="End time">
                          <TimeDayDisplay time={item.normalEndTime} day={item.normalEndDay} />
                        </Descriptions.Item>
                        <Descriptions.Item label="Hours">
                          <>{item.normalHours}</>
                        </Descriptions.Item>
                        <Descriptions.Item label="Work status">
                          <>{workStatus[item.workStatus]?.value}</>
                        </Descriptions.Item>
                      </Descriptions>
                    </Col>
                    <Col span={24} hidden={!showBreak}>
                      <div className="shift-day__section-title shift-day__section-title--space">Break</div>
                      <Text>{breaks[item.breakId || '']?.name || '-'}</Text>
                    </Col>
                    <Col span={24} hidden={item.maxHourBeforeStart === 0 && item.maxHourAfterEnd === 0}>
                      <div className="shift-day__section-title shift-day__section-title--space">Clocking</div>
                      <Descriptions colon={false} column={1} className="shift-day__clocking">
                        <Descriptions.Item
                          label={
                            <>
                              Earliest before start&nbsp;<Text size="small">(hours)</Text>
                            </>
                          }
                        >
                          <>{item.maxHourBeforeStart}</>
                        </Descriptions.Item>
                        <Descriptions.Item
                          label={
                            <>
                              Latest after end&nbsp;<Text size="small">(hours)</Text>
                            </>
                          }
                        >
                          <>{item.maxHourAfterEnd}</>
                        </Descriptions.Item>
                      </Descriptions>
                    </Col>
                    <Col span={24} hidden={!showGrace}>
                      <div className="shift-day__section-title shift-day__section-title--space">Grace</div>
                      <Descriptions colon={false} column={1} className="shift-day__grace">
                        <Descriptions.Item
                          label={
                            <>
                              Lateness&nbsp;<Text size="small">(minutes)</Text>
                            </>
                          }
                        >
                          <>{item.graceLatenessMinutes}</>
                        </Descriptions.Item>
                        <Descriptions.Item
                          label={
                            <>
                              Undertime&nbsp;<Text size="small">(minutes)</Text>
                            </>
                          }
                        >
                          <>{item.graceUndertimeMinutes}</>
                        </Descriptions.Item>
                        {item.latenessRoundMethod && (
                          <>
                            <Descriptions.Item label="Rounding method">
                              <>{roundingMethod[item.latenessRoundMethod]?.value}</>
                            </Descriptions.Item>
                            <Descriptions.Item label="Rounding minutes">
                              <>{item.latenessRoundMinutes}</>
                            </Descriptions.Item>
                          </>
                        )}
                      </Descriptions>
                    </Col>
                  </Row>
                </Col>
                <Col span={8} hidden={!showOvertime}>
                  {(item.otBeforeStartTime || item.otBeforeStartMinHours > 0 || item.otBeforeStartMaxHours > 0) && (
                    <>
                      <div className="shift-day__section-title">Before shift start</div>
                      <Descriptions colon={false} column={1} className="shift-day__ot">
                        <Descriptions.Item label="Earliest start time">
                          <TimeDayDisplay time={item.otBeforeStartTime} day={item.otBeforeStartDay} />
                        </Descriptions.Item>
                        <Descriptions.Item
                          label={
                            <>
                              Min&nbsp;<Text size="small">(hours)</Text>
                            </>
                          }
                        >
                          <>{item.otBeforeStartMinHours}</>
                        </Descriptions.Item>
                        <Descriptions.Item
                          label={
                            <>
                              Max&nbsp;<Text size="small">(hours)</Text>
                            </>
                          }
                        >
                          <>{item.otBeforeStartMaxHours}</>
                        </Descriptions.Item>
                        <Descriptions.Item label="Overtime rate">
                          <>{otConfigs[item.otBeforeStartRate]?.name}</>
                        </Descriptions.Item>
                      </Descriptions>
                      {item.midnightOnOtBeforeStart && (
                        <>
                          <div className="shift-day__section-title shift-day__section-title--space">
                            Rate before midnight
                          </div>
                          <Descriptions colon={false} column={1} className="shift-day__ot">
                            <Descriptions.Item label="Working day rate">
                              <>{otConfigs[item.otBeforeMidnightRateForWorkDay]?.name}</>
                            </Descriptions.Item>
                            <Descriptions.Item label="Off day rate">
                              <>{otConfigs[item.otBeforeMidnightRateForOffDay]?.name}</>
                            </Descriptions.Item>
                            <Descriptions.Item label="Rest day rate">
                              <>{otConfigs[item.otBeforeMidnightRateForRestDay]?.name}</>
                            </Descriptions.Item>
                            <Descriptions.Item label="Holiday rate">
                              <>{otConfigs[item.otBeforeMidnightRateForPh]?.name}</>
                            </Descriptions.Item>
                          </Descriptions>
                        </>
                      )}
                    </>
                  )}
                  {(item.otAfterEndTime || item.otAfterEndMinHours > 0 || item.otAfterEndMaxHours > 0) && (
                    <>
                      <div className="shift-day__section-title shift-day__section-title--space">After shift end</div>
                      <Descriptions colon={false} column={1} className="shift-day__ot">
                        <Descriptions.Item label="Latest end time">
                          <TimeDayDisplay time={item.otAfterEndTime} day={item.otAfterEndDay} />
                        </Descriptions.Item>
                        <Descriptions.Item
                          label={
                            <>
                              Min&nbsp;<Text size="small">(hours)</Text>
                            </>
                          }
                        >
                          <>{item.otAfterEndMinHours}</>
                        </Descriptions.Item>
                        <Descriptions.Item
                          label={
                            <>
                              Max&nbsp;<Text size="small">(hours)</Text>
                            </>
                          }
                        >
                          <>{item.otAfterEndMaxHours}</>
                        </Descriptions.Item>
                        <Descriptions.Item label="Overtime rate">
                          <>{otConfigs[item.otAfterEndRate]?.name}</>
                        </Descriptions.Item>
                      </Descriptions>
                      {item.midnightOnOtAfterEnd && (
                        <>
                          <div className="shift-day__section-title shift-day__section-title--space">
                            Rate after midnight
                          </div>
                          <Descriptions colon={false} column={1} className="shift-day__ot">
                            <Descriptions.Item label="Working day rate">
                              <>{otConfigs[item.otAfterMidnightRateForWorkDay]?.name}</>
                            </Descriptions.Item>
                            <Descriptions.Item label="Off day rate">
                              <>{otConfigs[item.otAfterMidnightRateForOffDay]?.name}</>
                            </Descriptions.Item>
                            <Descriptions.Item label="Rest day rate">
                              <>{otConfigs[item.otAfterMidnightRateForRestDay]?.name}</>
                            </Descriptions.Item>
                            <Descriptions.Item label="Holiday rate">
                              <>{otConfigs[item.otAfterMidnightRateForPh]?.name}</>
                            </Descriptions.Item>
                          </Descriptions>
                        </>
                      )}
                    </>
                  )}
                  {item.otWithinShiftRate && (
                    <>
                      <div className="shift-day__section-title shift-day__section-title--space">
                        Within shift {item.dayCode}
                      </div>
                      <Descriptions colon={false} column={1} className="shift-day__ot">
                        <Descriptions.Item label="Overtime rate">
                          <>{otConfigs[item.otWithinShiftRate]?.name}</>
                        </Descriptions.Item>
                      </Descriptions>
                      {item.midnightOnOtWithinShift && (
                        <>
                          <div className="shift-day__section-title shift-day__section-title--space">
                            Rate after midnight
                          </div>
                          <Descriptions colon={false} column={1} className="shift-day__ot">
                            <Descriptions.Item label="Working day rate">
                              <>{otConfigs[item.otWithinAfterMidnightRateForWorkDay]?.name}</>
                            </Descriptions.Item>
                            <Descriptions.Item label="Off day rate">
                              <>{otConfigs[item.otWithinAfterMidnightRateForOffDay]?.name}</>
                            </Descriptions.Item>
                            <Descriptions.Item label="Rest day rate">
                              <>{otConfigs[item.otWithinAfterMidnightRateForRestDay]?.name}</>
                            </Descriptions.Item>
                            <Descriptions.Item label="Holiday rate">
                              <>{otConfigs[item.otWithinAfterMidnightRateForPh]?.name}</>
                            </Descriptions.Item>
                          </Descriptions>
                        </>
                      )}
                    </>
                  )}
                  {(item.totalOtMinHours > 0 || item.totalOtMaxHours > 0) && (
                    <>
                      <div className="shift-day__section-title shift-day__section-title--space">Total overtime</div>
                      <Descriptions colon={false} column={1} className="shift-day__ot">
                        <Descriptions.Item
                          label={
                            <>
                              Min&nbsp;<Text size="small">(hours)</Text>
                            </>
                          }
                        >
                          <>{item.totalOtMinHours}</>
                        </Descriptions.Item>
                        <Descriptions.Item
                          label={
                            <>
                              Max&nbsp;<Text size="small">(hours)</Text>
                            </>
                          }
                        >
                          <>{item.totalOtMaxHours}</>
                        </Descriptions.Item>
                        {item.otRoundMethod && (
                          <>
                            <Descriptions.Item label="Rounding method">
                              <>{roundingMethod[item.otRoundMethod]?.value}</>
                            </Descriptions.Item>
                            <Descriptions.Item label="Rounding minutes">
                              <>{item.otRoundMinutes}</>
                            </Descriptions.Item>
                          </>
                        )}
                      </Descriptions>
                    </>
                  )}
                </Col>
                <Col span={8} hidden={!showHalfDay}>
                  <div className="shift-day__section-title">1st half day</div>
                  <Descriptions colon={false} column={1} className="shift-day__half-day">
                    <Descriptions.Item label="End time">
                      <TimeDayDisplay time={item.h1EndTime} day={0} />
                    </Descriptions.Item>
                    <Descriptions.Item label="Hours">
                      <>{item.h1Hours}</>
                    </Descriptions.Item>
                  </Descriptions>
                  <div className="shift-day__section-title shift-day__section-title--space">2nd half day</div>
                  <Descriptions colon={false} column={1} className="shift-day__half-day">
                    <Descriptions.Item label="Start time">
                      <TimeDayDisplay time={item.h2StartTime} day={0} />
                    </Descriptions.Item>
                    <Descriptions.Item label="Hours">
                      <>{item.h2Hours}</>
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>
            </List.Item>
          )
        }}
      />
      {canModify && <EditShiftDayDrawer {...editDrawerState} shift={shift} onClose={handleCloseEditDrawer} />}
      {canModify && <CopyShiftDayDrawer {...copyDrawerState} shift={shift} onClose={handleCloseCopyDrawer} />}
    </Card>
  )
}
