import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Form, Tab, Tabs } from '~/core-components'
import { DrawerForm, PayItemKeyValues } from '~/components'
import { fetchFormulas } from '~/features/formula'
import { PayItemState, selectPayItemById, selectPayItemsDict } from '~/features/master'
import { EmPublicPerson, fetchEmployee, fetchEmSalaries, selectEmSalaryByDate } from '~/features/employee'
import { PayItemCategory, PayRunType } from '~/constants'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors, StoreState } from '~/types/store'
import { useFocus } from '~/hooks/use-focus'
import { IPayTranEntryInfo, PayRecordSgState } from '../../types'
import { selectPayItemMappingById, selectPayRunById } from '../../reducers'
import { addPayRecurring, addPayTranEntryBatch, fetchPayItemMapping } from '../../actions'
import { MutatePayTranEntryForm, PayTranEntryForm, EMPTY_PAY_TRAN_ENTRY_FORM_DATA } from './MutatePayTranEntryForm'
import { AddPayRecurringForm, PayRecurringForm, EMPTY_PAY_RECURRING_FORM_DATA } from './AddPayRecurringForm'
import './AddPayTranDrawer.less'

export interface AddPayTranDrawerProps {
  visible: boolean
  payRecord?: PayRecordSgState
  onClose: (action: 'save' | 'cancel') => void
}

type TabChoice = 'entry' | 'recurring'

export const AddPayTranDrawer: FC<AddPayTranDrawerProps> = ({ visible, payRecord, onClose }) => {
  const [focusRef, setFocus] = useFocus(true)
  const [loading, setLoading] = useState(false)
  const [resetForm, setResetForm] = useState(0)
  const [errors, setErrors] = useState<Errors>()
  const [activeTab, setActiveTab] = useState<TabChoice>('entry')
  const [entryFormData, setEntryFormData] = useState<PayTranEntryForm>(EMPTY_PAY_TRAN_ENTRY_FORM_DATA)
  const [recurringFormData, setRecurringFormData] = useState<PayRecurringForm>(EMPTY_PAY_RECURRING_FORM_DATA)

  const payRunId = payRecord?.payRunId || ''
  const employeeId = payRecord?.employeeId || ''

  const payRun = useSelector((state: StoreState) => selectPayRunById(state, payRunId))
  const payRunStart = payRun?.startDate || ''
  const payRunEnd = payRun?.endDate || ''
  const payItems = useSelector((state: StoreState) => selectPayItemsDict(state))
  const payItem = useSelector((state: StoreState) => selectPayItemById(state, entryFormData.payItemId))

  const bpDaily = useSelector((state: StoreState) => selectPayItemMappingById(state, 'bp_daily'))
  const bpDailyPayItemId = bpDaily?.payItemId || ''
  const bpHourly = useSelector((state: StoreState) => selectPayItemMappingById(state, 'bp_hourly'))
  const bpHourlyPayItemId = bpHourly?.payItemId || ''
  const emSalary = useSelector(selectEmSalaryByDate)(employeeId, payRunEnd)

  useEffect(() => {
    dispatch(fetchFormulas({ strategy: 'when-empty' }))
    dispatch(fetchPayItemMapping('bp_daily', { strategy: 'when-empty' }))
    dispatch(fetchPayItemMapping('bp_hourly', { strategy: 'when-empty' }))
  }, [])

  useEffect(() => {
    if (employeeId) {
      dispatch(fetchEmployee(employeeId, { strategy: 'when-empty' }))
      dispatch(fetchEmSalaries(employeeId))
    }
  }, [employeeId])

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  const getIsSalaryAsRate = useCallback(
    (payItemId: string) => {
      return bpDailyPayItemId === payItemId || bpHourlyPayItemId === payItemId
    },
    [bpDailyPayItemId, bpHourlyPayItemId]
  )

  const handleOk = useCallback(async () => {
    if (!payRunId || !employeeId) return

    let result: ActionResult | undefined
    setLoading(true)
    try {
      if (activeTab === 'entry') {
        const requests = mapPayTranEntryFormToPayTranEntryInfoRequests(entryFormData, payRunStart, payRunEnd)
        result = await dispatch(addPayTranEntryBatch(payRunId, employeeId, requests))
      } else if (activeTab === 'recurring') {
        recurringFormData.payRunId = payRunId
        result = await dispatch(addPayRecurring(employeeId, recurringFormData))
      }
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose('save')
      setEntryFormData(EMPTY_PAY_TRAN_ENTRY_FORM_DATA)
      setRecurringFormData(EMPTY_PAY_RECURRING_FORM_DATA)
      setResetForm(resetForm + 1)
    }
  }, [activeTab, entryFormData, recurringFormData, payRunId, payRunStart, payRunEnd, employeeId, onClose, resetForm])

  const handleCloseDrawer = useCallback(() => {
    typeof onClose === 'function' && onClose('cancel')
  }, [onClose])

  const handleEntryFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setEntryFormData(data => ({ ...data, ...updates }))
  }, [])

  const handleRecurringFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setRecurringFormData(data => ({ ...data, ...updates }))
  }, [])

  const handleTabChange = useCallback((activeKey: string) => setActiveTab(activeKey as TabChoice), [])
  const isSalaryAsRate = getIsSalaryAsRate(entryFormData.payItemId)

  const tabItems = useMemo(() => {
    const result: Tab[] = [
      {
        key: 'entry',
        label: 'One time',
        children: (
          <MutatePayTranEntryForm
            data={entryFormData}
            defaultPickerDate={payRun?.startDate}
            errors={errors}
            rateDisabled={isSalaryAsRate}
            rateLabel={isSalaryAsRate ? 'Current salary' : 'Rate'}
            resetForm={resetForm}
            onFormChange={data => setEntryFormData({ ...entryFormData, ...data })}
          />
        )
      }
    ]

    if ([PayRunType.wholeMonth, PayRunType.secondHalf].includes(payRun?.payRunType || '')) {
      result.push({
        key: 'recurring',
        label: 'Recurring',
        children: (
          <AddPayRecurringForm
            payItem={payItem}
            startDate={payRun?.startDate}
            errors={errors}
            resetForm={resetForm}
            onFormChange={data => setRecurringFormData({ ...recurringFormData, ...data })}
          />
        )
      })
    }

    return result
  }, [entryFormData, payRun, errors, isSalaryAsRate, resetForm, payItem, recurringFormData])

  return (
    <DrawerForm
      open={visible}
      title="Add payroll item"
      onClose={handleCloseDrawer}
      confirmLoading={loading}
      width={500}
      className="add-paytran-drawer"
      formId={`form-add-payitem-${payRecord?.id || ''}`}
    >
      <Form id={`form-add-payitem-${payRecord?.id || ''}`} onFinish={handleOk}>
        <Form.Item label="">
          <EmPublicPerson id={employeeId} size={32} />
        </Form.Item>
        <Form.Item label="Payroll item" validateStatus={errors?.payItemId ? 'error' : ''} help={errors?.payItemId}>
          <PayItemKeyValues
            ref={focusRef}
            value={entryFormData.payItemId}
            onFilter={(value: PayItemState | undefined) => PayItemCategory.cpf !== value?.category}
            onChange={(value: string) => {
              let rate = payItems[value]?.rate || 1
              if (getIsSalaryAsRate(value)) {
                rate = emSalary?.basicSalary || 0
              }
              handleEntryFormDataChange({ payItemId: value, rate })
              handleRecurringFormDataChange({ payItemId: value, rate })
            }}
          />
        </Form.Item>
        <Tabs onChange={handleTabChange} items={tabItems} />
      </Form>
    </DrawerForm>
  )
}

const mapPayTranEntryFormToPayTranEntryInfoRequests = (
  form: PayTranEntryForm,
  startDate: string,
  endDate: string
): IPayTranEntryInfo[] => {
  const result: IPayTranEntryInfo[] = []

  if (form.byDates) {
    for (const tran of form.trans) {
      if (tran.quantity > 0 && moment(tran.date).isValid()) {
        result.push({
          payItemId: form.payItemId,
          startDate: tran.date,
          endDate: tran.date,
          quantity: tran.quantity,
          rate: form.rate,
          isSysSegment: form.isSysSegment
        })
      }
    }
  } else {
    result.push({
      payItemId: form.payItemId,
      startDate: startDate,
      endDate: endDate,
      quantity: form.trans.length > 0 ? form.trans[0].quantity : 0,
      rate: form.rate,
      isSysSegment: form.isSysSegment
    })
  }
  return result
}
