import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { apiUpdateRule } from '../api/rule.api'
import { RuleState, IRule, mapRuleToRuleState } from '../types'
import { setRule } from '../reducers'

export const updateRule = (referenceId: string, id: string, before: IRule, request: IRule): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const after = { ...before, ...request } as IRule
    const patch = generateJsonPatch<IRule>(before, after)
    const { status, errors, message, errorData } = await apiUpdateRule(id, patch)

    if (status) {
      const rule = getState().rule.rules[referenceId]?.entities[id]
      const updated = { ...(rule as RuleState), ...mapRuleToRuleState(after) }
      dispatch(setRule({ referenceId, data: updated }))

      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
