import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { PayRecurringState, PayRecurringTable } from '../types'

export const selectPayRecurrings = createSelector(
  (state: StoreState) => state.payroll.payRecurrings,
  (state: StoreState) => state.master.payItems,
  (payRecurringsState, payItemsState) =>
    memoize((employeeId?: string): PayRecurringTable[] => {
      return (Object.values(payRecurringsState?.entities || {}) as PayRecurringState[])
        .filter(rec => rec?.employeeId === employeeId)
        .map(rec => {
          const payItem = payItemsState.entities[rec.payItemId]
          return {
            ...rec,
            ...{ payItemName: payItem?.name, formulaId: payItem?.formulaId }
          }
        })
        .sort((a, b) => {
          const aStartDate = new Date(a!.startDate).getTime()
          const bStartDate = new Date(b!.startDate).getTime()
          const aEndDate = !!a!.endDate ? new Date(a!.endDate).getTime() : 0
          const bEndDate = !!b!.endDate ? new Date(b!.endDate).getTime() : 0

          if (aEndDate === 0 && bEndDate === 0) {
            return bStartDate - aStartDate
          } else {
            if (bEndDate === 0) {
              return 1
            } else if (aEndDate === 0) {
              return -1
            } else {
              return bEndDate - aEndDate
            }
          }
        })
    })
)
