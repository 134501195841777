import { showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { apiAddOedSubmission } from '../api/rpt-oed-submission.api'
import { refetchOedSubmissionsView } from '../reducers'
import { IOedSubmissionNew } from '../types'
import { emptyGuid } from '~/constants'

export const addOedSubmission = (oedSubmission: IOedSubmissionNew): ThunkResult<void> => {
  return async (dispatch): Promise<ActionResult | undefined> => {
    const { status, result, errors, message, errorData } = await apiAddOedSubmission({
      ...oedSubmission,
      companyId: oedSubmission.companyId || emptyGuid
    })
    if (status) {
      dispatch(refetchOedSubmissionsView())
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
