import React, { FC } from 'react'
import { default as AntDrawer, DrawerProps as AntDrawerProps } from 'antd/lib/drawer'
import 'antd/es/drawer/style'
import './Drawer.less'

export interface DrawerProps extends AntDrawerProps {}

export const Drawer: FC<DrawerProps> = (props: DrawerProps) => {
  return <AntDrawer {...props} />
}
