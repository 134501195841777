import React, { FC, HTMLAttributes, useCallback, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { useSelector } from 'react-redux'
import { Form, Input, Link, Switch } from '~/core-components'
import { Col, Row, SalaryInput } from '~/components'
import { fetchPayItem, selectPayItemById } from '~/features/master'
import { selectFormulaById } from '~/features/formula'
import { dispatch } from '~/stores/store'
import { Errors, StoreState } from '~/types/store'
import { PayItemCategory } from '~/constants'
import { fetchPayItemMapping } from '../../actions'
import { selectPayItemMappingById } from '../../reducers'

export interface PayTranImportForm {
  payItemId: string
  byDates: boolean
  trans: { date: string; quantity: number }[]
  rate: number
  isSysSegment: boolean
}

export const EMPTY_PAY_TRAN_IMPORT_FORM_DATA: PayTranImportForm = {
  payItemId: '',
  byDates: false,
  trans: [{ date: '', quantity: 0 }],
  rate: 0,
  isSysSegment: false
}

const NEW_DATES = [
  { date: '', quantity: 0 },
  { date: '', quantity: 0 },
  { date: '', quantity: 0 }
]

export interface MutatePayTranImportFormProps extends HTMLAttributes<HTMLDivElement> {
  data?: PayTranImportForm
  defaultPickerDate?: string
  errors?: Errors
  rateHidden?: boolean
  rateReadOnly?: boolean
  rateLabel?: string
  resetForm: number
  readOnly?: boolean
  onFormChange: (data: PayTranImportForm) => void
}

export const MutatePayTranImportForm: FC<MutatePayTranImportFormProps> = ({
  data,
  defaultPickerDate,
  errors,
  rateHidden = false,
  rateReadOnly = false,
  rateLabel = 'Rate',
  resetForm,
  readOnly,
  onFormChange,
  style
}: MutatePayTranImportFormProps) => {
  const [formData, setFormData] = useState<PayTranImportForm>(data || EMPTY_PAY_TRAN_IMPORT_FORM_DATA)
  const payItemId = data?.payItemId || ''
  const payItem = useSelector((state: StoreState) => selectPayItemById(state, payItemId))
  const formula = useSelector((state: StoreState) => selectFormulaById(state, payItem?.formulaId || ''))
  const bpDaily = useSelector((state: StoreState) => selectPayItemMappingById(state, 'bp_daily'))
  const bpHourly = useSelector((state: StoreState) => selectPayItemMappingById(state, 'bp_hourly'))

  useEffect(() => {
    dispatch(fetchPayItemMapping('bp_daily', { strategy: 'when-empty' }))
    dispatch(fetchPayItemMapping('bp_hourly', { strategy: 'when-empty' }))
  }, [])

  useEffect(() => {
    dispatch(fetchPayItem(payItemId, 'sg'))
  }, [payItemId])

  useEffect(() => {
    setFormData(EMPTY_PAY_TRAN_IMPORT_FORM_DATA)
  }, [resetForm])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      const updated = { ...formData, ...updates }
      setFormData(updated)
      typeof onFormChange === 'function' && onFormChange(updated)
    },
    [formData, onFormChange]
  )

  useEffect(() => {
    setFormData({
      ...EMPTY_PAY_TRAN_IMPORT_FORM_DATA,
      payItemId,
      rate: payItem?.rate || 0,
      ...data
    })
  }, [data, payItem, payItemId])

  const isAdhoc =
    payItem?.category !== PayItemCategory.fund &&
    (payItem?.category !== PayItemCategory.basicPay ||
      payItem?.id === bpDaily?.payItemId ||
      payItem?.id === bpHourly?.payItemId) &&
    payItem?.category !== PayItemCategory.sdl &&
    payItem?.category !== PayItemCategory.fwl &&
    payItem?.category !== PayItemCategory.cpf

  return (
    <div style={style}>
      <Row hidden={!isAdhoc}>
        <Col span={24}>
          <Form.Item className="entry-type">
            <label>Add by occurrence date</label>
            <Switch
              checkedChildren="Yes"
              unCheckedChildren="No"
              disabled={readOnly}
              checked={formData.byDates}
              onChange={(checked: boolean) => {
                if (data) {
                  handleFormDataChange({
                    byDates: checked,
                    trans: checked ? [...data.trans] : [data.trans[0]]
                  })
                } else {
                  handleFormDataChange({
                    byDates: checked,
                    trans: checked ? NEW_DATES : [{ date: '', quantity: 0 }]
                  })
                }
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row hidden={!formData.byDates}>
        <Col span={24}>
          {formData.trans.map((tran, index) => (
            <Row gutter={30} key={index} className="date-row">
              <Col>
                {index === 0 && <div className="date-row__title">Date</div>}
                <Input.Date
                  inputReadOnly={readOnly}
                  value={tran.date ? moment(tran.date) : undefined}
                  defaultPickerValue={moment(tran.date || defaultPickerDate)}
                  onChange={(value: moment.Moment | null) =>
                    handleFormDataChange({
                      trans: [
                        ...formData.trans.slice(0, index),
                        { date: value?.format('YYYY-MM-DD'), quantity: formData.trans[index].quantity },
                        ...formData.trans.slice(index + 1, formData.trans.length)
                      ]
                    })
                  }
                />
              </Col>
              <Col>
                {index === 0 && <div className="date-row__title">Quantity</div>}
                <SalaryInput
                  precision={4}
                  readOnly={readOnly}
                  value={tran.quantity}
                  onChange={(value: number | null) =>
                    handleFormDataChange({
                      trans: [
                        ...formData.trans.slice(0, index),
                        { quantity: value, date: formData.trans[index].date },
                        ...formData.trans.slice(index + 1, formData.trans.length)
                      ]
                    })
                  }
                />
              </Col>
              <Col hidden={readOnly}>
                <Link
                  onClick={() =>
                    handleFormDataChange({
                      trans: [
                        ...formData.trans.slice(0, index),
                        ...formData.trans.slice(index + 1, formData.trans.length)
                      ]
                    })
                  }
                >
                  remove
                </Link>
              </Col>
            </Row>
          ))}
          <Form.Item hidden={readOnly}>
            <Link onClick={() => handleFormDataChange({ trans: [...formData.trans, ...NEW_DATES] })}>
              add more dates
            </Link>
          </Form.Item>
        </Col>
      </Row>
      <Row hidden={formData.byDates}>
        <Col span={24}>
          <Row>
            <Col span={12}>
              <Form.Item
                label={rateHidden ? 'Amount' : 'Quantity'}
                validateStatus={errors?.quantity ? 'error' : ''}
                help={errors?.quantity}
              >
                <SalaryInput
                  precision={4}
                  readOnly={readOnly}
                  value={formData.trans[0]?.quantity}
                  onChange={(value: number | null) => handleFormDataChange({ trans: [{ quantity: value }] })}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row hidden={rateHidden}>
        <Col span={12}>
          <Form.Item label={rateLabel} validateStatus={errors?.rate ? 'error' : ''} help={errors?.rate}>
            <SalaryInput
              value={formData.rate}
              precision={4}
              readOnly={rateReadOnly || readOnly}
              onChange={(value: number | null) => handleFormDataChange({ rate: value })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row hidden={!formula?.name}>
        <Col span={24}>
          <Form.Item label="Formula">
            <label>{formula?.name}</label>
          </Form.Item>
        </Col>
      </Row>
      <Row hidden={formData.byDates || !isAdhoc}>
        <Col span={24}>
          <Form.Item>
            <label>Auto prorate by system</label>
            <Switch
              checkedChildren="Yes"
              unCheckedChildren="No"
              disabled={readOnly}
              checked={formData.isSysSegment}
              onChange={(checked: boolean) => handleFormDataChange({ isSysSegment: checked })}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  )
}
