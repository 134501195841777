import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'

export const selectLtPoliciesExtraById = createSelector(
  (state: StoreState) => state.leave.ltPoliciesExtra,
  ltPoliciesExtraState =>
    memoize((id: string) => {
      return ltPoliciesExtraState[id]?.entities || {}
    })
)
