import React, { FC, useCallback, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Form, Spin, Switch } from '~/core-components'
import { dispatch } from '~/stores/store'
import { excludeOedRecord, includeOedRecord } from '~/features/report/actions'

export interface IncludeSwitcherProps {
  id: string
  isInclude: boolean
}

export const IncludeSwitcher: FC<IncludeSwitcherProps> = ({ id, isInclude }) => {
  const [including, setIncluding] = useState<boolean>(false)
  const [included, setIncluded] = useState<boolean>(isInclude)

  const handleInclude = useCallback(
    async (checked: boolean) => {
      if (id) {
        setIncluding(true)
        try {
          if (checked) {
            await dispatch(includeOedRecord(id))
          } else {
            await dispatch(excludeOedRecord(id))
          }
          setIncluded(checked)
        } finally {
          setIncluding(false)
        }
      }
    },
    [id]
  )

  return (
    <Form.Item label="Include in the submission?" className="include-switcher">
      {including ? (
        <Spin indicator={<LoadingOutlined spin />} />
      ) : (
        <Switch checkedChildren="Yes" unCheckedChildren="No" checked={included} onChange={handleInclude} />
      )}
    </Form.Item>
  )
}
