import { Pagination, Search } from '~/types/common'
import { request, getBaseUrl, timeout } from '~/utils/request'
import { IYtdIndexPublish, YtdIndexViewState } from '../types'

const baseUrl = getBaseUrl('/payroll-sg/ytdindex')

export const apiGetYtdIndexView = (viewId: string, ytdYear: string, params: Pagination & Search) =>
  request<YtdIndexViewState>('get', `${baseUrl}/view/${viewId}/${ytdYear}`, params)

export const apiGetYtdIndexYears = () => request('get', `${baseUrl}/ytdyear`)

export const apiGetYtdIndexExcel = (viewId: string, ytdYear: string, params: Search) =>
  request('get', `${baseUrl}/view/${viewId}/${ytdYear}/excel`, params, { responseType: 'blob', timeout })

export const apiPublishYtdIndex = (type: string, ytdYear: string, ids: IYtdIndexPublish) =>
  request<void>('post', `${baseUrl}/${ytdYear}/${type}`, ids)
