import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiDeleteJob } from '../api/job.api'

export const deleteJob = (code: string): ThunkResult<void> => {
  return async () => {
    if (!code) return

    const { status, errors, message, errorData } = await apiDeleteJob(code)
    if (status) {
      showSuccess('Deleted')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
