import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchUpcomingEvents } from '../../actions'
import { selectUpcomingEvents } from '../../selectors'
import { SSUpcomingEventState } from '../../types'

export const useUpcomingEvents = (fetchStrategy: FetchStrategy = 'when-empty'): [SSUpcomingEventState[], boolean] => {
  const upcomingEvents = useSelector(selectUpcomingEvents)
  const loading = useSelector((state: StoreState) => state.my.upcomingEventsLoading)

  useEffect(() => {
    dispatch(fetchUpcomingEvents({ strategy: fetchStrategy }))
  }, [fetchStrategy])

  return [upcomingEvents, loading]
}
