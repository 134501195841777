import { showSuccess, showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { apiAddOffice } from '../api/office.api'
import { setOffice, setKeyvalue } from '../reducers'
import { IOfficeInfo, OfficeState } from '../types'
import { formatAddress } from '../util'

export const addOffice = (office: IOfficeInfo): ThunkResult<void> => {
  return async (dispatch, getState): Promise<ActionResult> => {
    const { status, result, errors, message, errorData } = await apiAddOffice(office)
    if (status) {
      const countries = getState().master.keyvalues['country'].entities
      const countryName = countries[office.countryCode || '']?.value || ''
      const newOffice: OfficeState = { id: result.id, ...office, ...formatAddress(office, countryName) }
      const { name, inactiveDate } = office
      dispatch(setOffice(newOffice))
      dispatch(setKeyvalue({ kv: 'office', data: { key: result.id, value: name, inactiveDate } }))

      showSuccess('Added')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
