import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Form, Input } from '~/core-components'
import { Col, DrawerForm, Row } from '~/components'
import { apiGetApexAuthUrl, setOedSubmissionId } from '~/features/employee'
import { useMyLogin } from '~/features/iam'
import { useFocus, useIsMountedRef } from '~/hooks'
import { downloadWithDom, getFileTimestamp, showError } from '~/utils'
import { ActionResult, Errors, StoreState } from '~/types/store'
import { dispatch } from '~/stores/store'
import { submitOed } from '../../../../actions'
import { SubmitOedRequest, SubmitOedResponse } from '../../../../types'
import { apiDownloadSubmissionOedData } from '../../../../api/rpt-oed-submission.api'

interface OedSubmissionDrawerProps {
  oedSubmissionId?: string
  visible: boolean
  onClose: () => void
}

interface FormData extends Omit<SubmitOedRequest, 'oedSubmissionId'> {}

const EMPTY_FORM_DATA: FormData = {
  requestorEmail: '',
  requestorName: ''
}

const REDIRECT_PATH = '/admin/report/oed-submission/callback'
const SCOPE = 'MomOedSub'

export const OedSubmissionDrawer: FC<OedSubmissionDrawerProps> = ({ oedSubmissionId, visible, onClose }) => {
  const [focusRef, setFocus] = useFocus(true)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<Errors>()
  const [myLogin] = useMyLogin()

  const [downloading, setDownloading] = useState(false)
  const isMountedRef = useIsMountedRef()

  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)
  const [redirecting, setRedirecting] = useState(false)
  const oedApexState = useSelector((state: StoreState) =>
    (new Date().getTime() - state.employee.oedApexStateTimestamp) / 60000 >= 30 ? null : state.employee.oedApexState
  )

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  useEffect(() => {
    if (visible) {
      handleFormDataChange({
        requestorEmail: myLogin.email || '',
        requestorName: myLogin.name || ''
      })
    }
  }, [myLogin, visible, handleFormDataChange])

  const handleLogin = useCallback(async () => {
    if (!oedSubmissionId) return

    try {
      setRedirecting(true)
      dispatch(setOedSubmissionId(oedSubmissionId))

      const url = await apiGetApexAuthUrl(REDIRECT_PATH, SCOPE)
      if (url) window.location.href = url.result
    } finally {
      setRedirecting(false)
    }
  }, [oedSubmissionId])

  const handleOk = useCallback(async () => {
    if (!oedSubmissionId) return

    let result: ActionResult<SubmitOedResponse> | undefined
    try {
      setLoading(true)

      result = await dispatch(submitOed({ oedSubmissionId, ...formData }))
      if (!result?.errors) {
        typeof onClose === 'function' && onClose()
      }
    } finally {
      setLoading(false)
    }
  }, [oedSubmissionId, formData, onClose])

  const handleCloseDrawer = useCallback(() => {
    typeof onClose === 'function' && onClose()
  }, [onClose])

  const handleDownload = useCallback(async () => {
    if (!oedSubmissionId) return

    try {
      setDownloading(true)
      const { status, result, errors, message, errorData } = await apiDownloadSubmissionOedData({
        oedSubmissionId,
        ...formData
      })
      if (status) {
        const fileName = `oed_data_${getFileTimestamp()}.json`
        const blob = new Blob([JSON.stringify(result)], { type: 'application/json' })
        downloadWithDom(blob, fileName)
      } else {
        console.error('Error while downloading', errors)
        showError(message, errorData)
      }
    } finally {
      if (isMountedRef.current) setDownloading(false)
    }
  }, [oedSubmissionId, formData, isMountedRef])
  return (
    <DrawerForm
      open={visible}
      title="MOM OED submission"
      okText="Submit to MOM"
      okDisabled={!oedApexState}
      showDelete
      deleteText="Download submission data"
      onDelete={handleDownload}
      deleteLoading={downloading}
      onClose={handleCloseDrawer}
      confirmLoading={loading}
      width={500}
      className="oed-submission-drawer"
      formId="form-oed-submission"
    >
      <Form id="form-oed-submission" onFinish={handleOk}>
        <Row hidden={!!oedApexState}>
          <Col span={24}>
            <Form.Item label="You've not login to APEX, login is required">
              <Button onClick={handleLogin} loading={redirecting}>
                Login to APEX
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Row hidden={!oedApexState}>
          <Col span={24} style={{ marginBottom: 7 }}>
            You have successfully login to APEX.
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Requestor email"
              validateStatus={errors?.requestorEmail ? 'error' : ''}
              help={errors?.requestorEmail}
            >
              <Input
                ref={focusRef}
                value={formData.requestorEmail}
                onChange={event => handleFormDataChange({ requestorEmail: event.target.value })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Requestor name"
              validateStatus={errors?.requestorName ? 'error' : ''}
              help={errors?.requestorName}
            >
              <Input
                value={formData.requestorName}
                onChange={event => handleFormDataChange({ requestorName: event.target.value })}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}
