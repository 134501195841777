import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { LocationAdminState } from '../types'
import { fetchLocationAdmins } from '../actions'
import { selectLocationAdmins } from '../selectors'

export const useLocationAdmins = (locationId: string): [LocationAdminState[], boolean] => {
  const locationAdmins = useSelector(selectLocationAdmins)(locationId)
  const loading = useSelector((state: StoreState) => state.attendance.locationAdminsLoading[locationId])

  useEffect(() => {
    if (locationId) {
      dispatch(fetchLocationAdmins(locationId))
    }
  }, [locationId])

  return [locationAdmins, loading]
}
