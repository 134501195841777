import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiCancelMyLeaveRecord } from '../../api/ssleave-apply.api'
import {
  refetchMyLeaveBalances,
  refetchMyLeaveCalendarEvents,
  refetchMyLeaveEntitlements,
  refetchMyLeaveRecordsView
} from '../../reducers'

export const cancelMyLeave = (id: string, leaveRecordDtlIds?: string[]): ThunkResult<void> => {
  return async dispatch => {
    if (!id) return

    const { status, result, errors, message, errorData } = await apiCancelMyLeaveRecord(id, leaveRecordDtlIds)

    if (status) {
      if (!!result.data) {
        dispatch(refetchMyLeaveBalances())
        dispatch(refetchMyLeaveEntitlements())
        dispatch(refetchMyLeaveRecordsView())
        dispatch(refetchMyLeaveCalendarEvents())

        showSuccess('Sent')
      }
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
