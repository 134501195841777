import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Tag, TagProps, Tooltip } from '~/core-components'
import { StoreState } from '~/types/store'

interface SysLeaveTypeCodeTagProps extends TagProps {
  code?: string
}

export const SysLeaveTypeCodeTag: FC<SysLeaveTypeCodeTagProps> = ({ code, className, ...props }) => {
  const sysLeaveType = useSelector((state: StoreState) => state.leave.sysLeaveTypes?.entities[code || ''])

  if (!code) return null

  return (
    <Tooltip title={sysLeaveType?.name}>
      <Tag {...props}>{code}</Tag>
    </Tooltip>
  )
}
