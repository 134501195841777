import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiApplyEmAddressRequest } from '../../api/ssemployee-request.api'
import { IEmAddressRequest } from '../../types'

export const applyEmAddressRequest = (address: IEmAddressRequest): ThunkResult<void> => {
  return async () => {
    const { status, result, errors, message, errorData } = await apiApplyEmAddressRequest(address)

    if (status) {
      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
