import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchSysPermissions } from '../actions'
import { SysPermissionState } from '../types'
import { selectSysPermissions } from '../reducers'

export const useSysPermissions = (fetchStrategy: FetchStrategy = 'when-empty'): [SysPermissionState[], boolean] => {
  const sysPermissions = useSelector(selectSysPermissions)
  const loading = useSelector((state: StoreState) => state.iam.sysPermissionsLoading)

  useEffect(() => {
    dispatch(fetchSysPermissions({ strategy: fetchStrategy }))
  }, [fetchStrategy])

  return [sysPermissions, loading]
}
