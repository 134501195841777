import React, { FC, useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { DocumentTitle } from '~/components'
import { Permission, PermissionAction } from '~/constants'
import { Button, Card, ColumnsType, PageHeader, Space, Table } from '~/core-components'
import { InactiveTag } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { isInactive } from '~/utils'
import { WorkCalendarState } from '../../types'
import { useCalendars } from '../../hooks'
import { AddCalendarDrawer } from '../Calendar/components/AddCalendarDrawer'
import './Calendars.less'

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: '',
    breadcrumbName: 'Overview'
  }
]

interface DrawerState {
  visible: boolean
  data?: WorkCalendarState
}
const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

interface CalendarsProps {}

export const Calendars: FC<CalendarsProps> = () => {
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const [calendars, loading] = useCalendars()
  const canModify = usePermissionGate(Permission.calendar, PermissionAction.Modify)

  const handleAddCalendar = useCallback(() => {
    setDrawerState({ visible: true, data: undefined })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const columns: ColumnsType<WorkCalendarState> = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      render: (value: string, record) => (
        <Space>
          <Link to={SETTINGS_ROUTES.workCalendar.replace(':id', record.id)}>{value}</Link>
          {isInactive(record.inactiveDate) && <InactiveTag />}
        </Space>
      )
    }
  ]

  return (
    <div id="calendars" className="calendars">
      <DocumentTitle title="Work Calendars" />
      <PageHeader
        title="Work calendars"
        containerId="calendars"
        breadcrumb={{ routes }}
        extra={
          canModify && (
            <Button key="add" onClick={handleAddCalendar}>
              Add calendar
            </Button>
          )
        }
      />
      <div className="calendars__body">
        <Card table>
          <Table rowKey="id" dataSource={calendars} pagination={false} columns={columns} loading={loading} />
        </Card>
      </div>
      {canModify && <AddCalendarDrawer {...drawerState} onClose={handleCloseDrawer} />}
    </div>
  )
}
