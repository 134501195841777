import React, { FC, useState, useCallback, useEffect, ChangeEvent } from 'react'
import moment from 'moment-timezone'
import confirm from 'antd/lib/modal/confirm'
import { Button, Form, Input, UploadFile } from '~/core-components'
import { Col, DrawerForm, EmpKeyValues, NewTabLinkIcon, Row, UploadFileSelectorAuth } from '~/components'
import { dispatch } from '~/stores/store'
import { getBaseUrl, request, timeout } from '~/utils/request'
import { Permission, PermissionAction } from '~/constants'
import { addEmDocument, deleteEmDocument, updateEmDocument } from '../../../actions'
import { IEmDocumentInfo, EmDocumentState } from '../../../types'
import { mapEmDocumentStateToEmDocumentInfo } from '../../../types/em-details.mapper'
import { ActionResult, Errors } from '~/types/store'
import { useFocus } from '~/hooks/use-focus'
import { usePermissionGate } from '~/features/iam'
import { SETTINGS_ROUTES } from '~/routes/routes'

export interface MutateEmDocumentDrawerProps {
  visible: boolean
  employeeId?: string
  data?: EmDocumentState
  onClose: () => void
}

const EMPTY_FORM_DATA: IEmDocumentInfo = {
  date: moment().format('YYYY-MM-DD'),
  docTypeId: '',
  name: '',
  docFileId: '',
  notes: ''
}

const baseUrl = getBaseUrl('/employee')

export const MutateEmDocumentDrawer: FC<MutateEmDocumentDrawerProps> = ({
  visible,
  employeeId,
  data,
  onClose
}: MutateEmDocumentDrawerProps) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<IEmDocumentInfo>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const [focusRef, setFocus] = useFocus(true)
  const canModify = usePermissionGate(Permission.master, PermissionAction.Modify)

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  useEffect(() => {
    if (data) {
      const { date, docTypeId, name, docFileId, notes } = data
      let file: UploadFile | undefined
      if (data.docFileId) {
        file = {
          uid: data.docFileId,
          name: data.docFileName || '',
          size: data.docFileSize || 0,
          type: data.docFileFormat || '',
          url: `${baseUrl}/employee/${employeeId}/document/${data.id}/downloadfile`
        }
      }
      setFormData({ date, docTypeId, name, docFileId, notes, file })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [employeeId, data])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      const updated = { ...formData, ...updates }
      setFormData(updated)
    },
    [formData]
  )

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      if (data) {
        result = await dispatch(
          updateEmDocument(data.employeeId, data.id, mapEmDocumentStateToEmDocumentInfo(data), formData)
        )
      } else if (employeeId) {
        result = await dispatch(addEmDocument(employeeId, formData))
      }
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_FORM_DATA)
    }
  }, [employeeId, data, formData, onClose])

  const handleDelete = useCallback(
    (emDocument: EmDocumentState | undefined) => {
      if (employeeId && emDocument) {
        const { id, name } = emDocument
        confirm({
          title: 'Delete document',
          content: `Do you want to delete document record of "${name}"?`,
          onOk: async () => {
            const result: ActionResult | undefined = await dispatch(deleteEmDocument(employeeId, id))
            if (result?.errors) {
              setErrors(result.errors)
            }

            if (!result?.errors) {
              typeof onClose === 'function' && onClose()
            }
          },
          okText: 'Delete',
          okType: 'danger'
        })
      }
    },
    [employeeId, onClose]
  )

  const handleFileDownload = useCallback(async (file: UploadFile) => {
    if (file.url) {
      const { result, status } = await request('get', file.url, undefined, {
        responseType: 'blob',
        timeout
      })
      if (status) {
        let objectUrl = window.URL.createObjectURL(result)
        window.open(objectUrl, '_blank')
      }
    }
  }, [])

  const handleFileChange = useCallback(
    (files?: UploadFile[]) => {
      if (files && files.length > 0) {
        setFormData({ ...formData, file: files[0] })
      }
    },
    [formData]
  )

  const handleFileRemove = useCallback(() => {
    setFormData({ ...formData, file: undefined })
  }, [formData])

  return (
    <DrawerForm
      open={visible}
      title={data ? 'Edit document' : 'Add document'}
      onClose={onClose}
      confirmLoading={loading}
      width={650}
      showDelete={data ? true : false}
      onDelete={() => handleDelete(data)}
      formId="form-em-document"
    >
      <Form id="form-em-document" onFinish={handleOk}>
        <Row>
          <Col span={24}>
            <Form.Item label="Category" validateStatus={errors?.docTypeId ? 'error' : ''} help={errors?.docTypeId}>
              <NewTabLinkIcon
                path={SETTINGS_ROUTES.docTypes}
                tooltipText="Open document type in a new tab"
                tooltipPlacement="topRight"
                hidden={!canModify}
              >
                <EmpKeyValues
                  id="docType"
                  value={formData.docTypeId}
                  ref={focusRef}
                  onChange={(value: string) => handleFormDataChange({ docTypeId: value })}
                />
              </NewTabLinkIcon>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Document name" validateStatus={errors?.name ? 'error' : ''} help={errors?.name}>
              <Input
                value={formData.name}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ name: event.target.value })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Document date" validateStatus={errors?.date ? 'error' : ''} help={errors?.date}>
              <Input.Date
                value={formData.date ? moment(formData.date) : undefined}
                onChange={(value: moment.Moment | null) =>
                  handleFormDataChange({ date: value?.format('YYYY-MM-DD') || '' })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="File" validateStatus={errors?.file ? 'error' : ''} help={errors?.file}>
              <UploadFileSelectorAuth
                multiple={false}
                accept=".gif,.png,.jpeg,.jpg,.pdf,.docx,.doc,.xls,.xlsx"
                fileList={formData.file ? [formData.file] : []}
                onChange={handleFileChange}
                onPreview={handleFileDownload}
                onRemove={handleFileRemove}
              >
                <Button size="small">choose file</Button>
              </UploadFileSelectorAuth>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Notes">
              <Input
                value={formData.notes}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ notes: event.target.value })}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}
