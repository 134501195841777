import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { selectEmergencyEmRequests } from '../selectors'
import { fetchEmRequests } from '../actions'
import { EmRequestState } from '../types'

export const useEmergencyEmRequests = (employeeId: string): [EmRequestState[], boolean] => {
  const data = useSelector(selectEmergencyEmRequests)(employeeId)
  const loading = useSelector((state: StoreState) => state.employee.emRequestsLoading[employeeId])

  useEffect(() => {
    dispatch(fetchEmRequests(employeeId))
  }, [employeeId])

  return [data, loading]
}
