import React, { FC, useCallback } from 'react'
import { TooltipPlacement } from 'antd/lib/tooltip'
import { Row } from '../Row/Row'
import { Col } from '../Col/Col'
import { Link, SecondaryLink, Tooltip } from '../../core-components'
import './NewTabLink.less'

export interface NewTabLinkProps {
  path: string
  tooltipText?: string
  tooltipPlacement?: TooltipPlacement
  clickArea?: 'icon' | 'all'
  hidden?: boolean
  autoFlex?: boolean
}

export const NewTabLink: FC<NewTabLinkProps> = ({
  path,
  tooltipText,
  tooltipPlacement = 'top',
  clickArea = 'all',
  children,
  hidden = false,
  autoFlex = true
}) => {
  const handleClick = useCallback(() => {
    window.open(path, '_blank')
  }, [path])

  if (clickArea === 'all')
    return (
      <Tooltip title={tooltipText}>
        <Link className="newtab-link newtab-link--inline" onClick={handleClick}>
          <Row>
            <Col flex="auto">{children}</Col>
            {!hidden && (
              <Col flex="none" className="newtab-link__icon">
                <i className="fal fa-arrow-up-right-from-square" />
              </Col>
            )}
          </Row>
        </Link>
      </Tooltip>
    )

  return (
    <div className="newtab-link">
      <Row align="middle">
        <Col flex={autoFlex ? 'auto' : 'none'}>{children}</Col>
        {!hidden && (
          <Col flex="none" className="newtab-link__icon">
            <Tooltip title={tooltipText} placement={tooltipPlacement}>
              <SecondaryLink onClick={handleClick} tabIndex={-1}>
                <i className="fal fa-arrow-up-right-from-square" />
              </SecondaryLink>
            </Tooltip>
          </Col>
        )}
      </Row>
    </div>
  )
}

export const NewTabLinkIcon: FC<NewTabLinkProps> = props => <NewTabLink {...props} clickArea="icon" />
