import { request, getBaseUrl } from '~/utils/request'
import { EmEducationRequestState } from '../types'

const baseUrl = getBaseUrl('/employee/employee/request/education')

export const apiGetEmEducationRequests = (employeeId: string) =>
  request<EmEducationRequestState[]>('get', `${baseUrl}/${employeeId}`)

export const apiApproveEmEducationRequest = (id: string) => request('post', `${baseUrl}/${id}/approve`)

export const apiRejectEmEducationRequest = (id: string) => request('post', `${baseUrl}/${id}/reject`)
