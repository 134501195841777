import * as jsonpatch from 'fast-json-patch'

type MapperFunction<TSource, TTarget> = (source: TSource, target: TTarget) => void

export const generateJsonPatchMapper = <TSource, TTarget>(
  before: TSource,
  after: TSource,
  mapper: MapperFunction<TSource, TTarget>
) => {
  const observe: TTarget = {} as TTarget
  mapper(before, observe)
  const observer = jsonpatch.observe<TTarget>(observe as Object)
  mapper(after, observe)
  return jsonpatch.generate<TTarget>(observer as jsonpatch.Observer<Object>)
}

export const generateJsonPatch = <T>(before: T, after: T) => {
  return jsonpatch.compare(before as Object, after as Object)
}
