import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { DelegationState } from '../types'

export const selectDelegations = createSelector(
  (state: StoreState) => state.master.delegations,
  delegationsState => {
    const delegations = Object.values(delegationsState?.entities || {}) as DelegationState[]
    return delegations.sort((a, b) => {
      const aStartDate = new Date(a!.startDate).getTime()
      const bStartDate = new Date(b!.startDate).getTime()
      const aEndDate = new Date(a!.endDate).getTime() || 0
      const bEndDate = new Date(b!.endDate).getTime() || 0

      if (aStartDate !== bStartDate) {
        return bStartDate - aStartDate
      }

      return bEndDate - aEndDate
    })
  }
)
