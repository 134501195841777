import { request, getBaseUrl } from '~/utils'
import { Pagination, Search } from '~/types/common'
import { IClaimPendingPayment, ClaimPendingPaymentViewState, IUpdateClaimPendingPayment } from '../types'

const baseUrl = getBaseUrl('/claim/claimpendingpayment')

export const apiGetClaimPendingPaymentsView = (viewId: string, periodYear: number, params: Pagination & Search) =>
  request<ClaimPendingPaymentViewState>('get', `${baseUrl}/view/${viewId}/${periodYear}`, params)

export const apiUpdateClaimPendingPayment = (claimPendingPayment: IUpdateClaimPendingPayment) =>
  request('post', `${baseUrl}/update`, claimPendingPayment)

export const apiRevertClaimPendingPayment = (claimPendingPayment: IClaimPendingPayment) =>
  request('post', `${baseUrl}/revert`, claimPendingPayment)
