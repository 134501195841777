import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchCePolicies } from '../actions'
import { selectCePolicies } from '../selectors/select-ce-policies'
import { CePolicyState } from '../types'

export const useCePolicies = (
  periodCode?: string,
  employeeId?: string,
  claimTypeId?: string
): [CePolicyState[], boolean] => {
  const entId = `${periodCode}|${employeeId}|${claimTypeId}`
  const data = useSelector(selectCePolicies)(periodCode, employeeId, claimTypeId)
  const loading = useSelector((state: StoreState) => state.claim.cePoliciesLoading[entId])

  useEffect(() => {
    dispatch(fetchCePolicies(periodCode, employeeId, claimTypeId))
  }, [periodCode, employeeId, claimTypeId])

  return [data, loading]
}
