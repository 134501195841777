import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'
import { setLeaveBuddy, setLeaveBuddiesLoading } from '../reducers'
import { apiGetLeaveBuddy } from '../api/leave-buddy.api'

export const fetchLeaveBuddy = (id: string, options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const loading = getState().leave.leaveBuddiesLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().leave.leaveBuddies.ids.includes(id)
      if (hasData) return
    }

    try {
      dispatch(setLeaveBuddiesLoading(true))
      const { result, status } = await apiGetLeaveBuddy(id)
      if (status) {
        dispatch(setLeaveBuddy(result))
      }
    } finally {
      dispatch(setLeaveBuddiesLoading(false))
    }
  }
}
