import React, { FC, useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ColumnsType, Drawer, Table } from '~/core-components'
import { fetchEmpKeyvalues, selectKeyvaluesById, selectRaceKeyvalues, selectReligionKeyvalues } from '~/features/master'
import { dispatch } from '~/stores/store'
import { ActionResult } from '~/types/store'
import { approveEmRequest, rejectEmRequest } from '../../../actions'
import { EmRequestDtlState, EmRequestState } from '../../../types'
import { useSysOptions } from '~/features/employee/hooks'
import { EmRequestApprovalBtn } from './EmRequestApprovalBtn'

export interface EmRequestApprovalProps {
  data?: EmRequestState
  title: string
  visible: boolean
  onClose: () => void
}

export const EmRequestApprovalDrawer: FC<EmRequestApprovalProps> = ({ data, title, visible, onClose }) => {
  const [genders] = useSysOptions('gender')
  const [maritalStatuses] = useSysOptions('marital_status')
  const races = useSelector(selectRaceKeyvalues)
  const religions = useSelector(selectReligionKeyvalues)
  const nationalities = useSelector(selectKeyvaluesById)('nationality')

  useEffect(() => {
    dispatch(fetchEmpKeyvalues('race', undefined, undefined, { strategy: 'when-empty' }))
    dispatch(fetchEmpKeyvalues('religion', undefined, undefined, { strategy: 'when-empty' }))
    dispatch(fetchEmpKeyvalues('nationality', undefined, undefined, { strategy: 'when-empty' }))
  }, [])

  const handleApproval = useCallback(
    async (action: 'approve' | 'reject') => {
      let result: ActionResult | undefined

      if (action === 'approve') {
        result = await dispatch(approveEmRequest(data?.employeeId || '', data?.id || '', data?.emInfoType || ''))
      } else if (action === 'reject') {
        result = await dispatch(rejectEmRequest(data?.employeeId || '', data?.id || '', data?.emInfoType || ''))
      }

      if (!result?.errors) {
        typeof onClose === 'function' && onClose()
      }
    },
    [onClose, data]
  )

  const handleCloseDrawer = useCallback(() => {
    typeof onClose === 'function' && onClose()
  }, [onClose])

  const columns: ColumnsType<EmRequestDtlState> = [
    {
      key: 'label',
      dataIndex: 'label',
      width: 150,
      render: (value: string) => value
    },
    {
      title: 'Current',
      key: 'currentValue',
      dataIndex: 'currentValue',
      render: (value: string, record) => {
        let currentVal = value
        if (record.field === 'gender') {
          currentVal = genders[value]?.value || '-'
        } else if (record.field === 'marital_status') {
          currentVal = maritalStatuses[value]?.value || '-'
        } else if (record.field === 'race_id') {
          currentVal = races[value]?.value || '-'
        } else if (record.field === 'religion_id') {
          currentVal = religions[value]?.value || '-'
        } else if (record.field === 'nationality_id') {
          currentVal = nationalities[value]?.value || '-'
        }

        return currentVal
      }
    },
    {
      align: 'center',
      render: () => <i className="fa-light fa-arrow-right-long" style={{ color: '#3caef2' }}></i>
    },
    {
      title: 'New',
      key: 'newValue',
      dataIndex: 'newValue',
      render: (value: string, record) => {
        let newVal = value
        if (record.field === 'gender') {
          newVal = genders[value]?.value || '-'
        } else if (record.field === 'marital_status') {
          newVal = maritalStatuses[value]?.value || '-'
        } else if (record.field === 'race_id') {
          newVal = races[value]?.value || '-'
        } else if (record.field === 'religion_id') {
          newVal = religions[value]?.value || '-'
        } else if (record.field === 'nationality_id') {
          newVal = nationalities[value]?.value || '-'
        }
        return newVal
      }
    }
  ]

  return (
    <Drawer
      open={visible}
      title={title}
      onClose={() => handleCloseDrawer()}
      width={600}
      className="approval-em-request-drawer"
      footer={<EmRequestApprovalBtn onApproval={handleApproval} />}
    >
      <Table rowKey="id" dataSource={data?.emRequestDtls} columns={columns} pagination={false}></Table>
    </Drawer>
  )
}
