import { EntityState } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import {
  ClaimTypeState,
  CtExpenseTypeState,
  CtPolicyEtState,
  CtPolicyState,
  CtProrationMState
} from './claim-type.state'
import {
  CePolicyState,
  ClaimEntitlementState,
  ClaimEntitlementViewState,
  EntitledClaimTypeState
} from './claim-entitlement.state'
import {
  ClaimEntitlementRecordState,
  ClaimRecordApprovalHistoryState,
  ClaimRecordAttachmentState,
  ClaimRecordState,
  ClaimRecordViewState
} from './claim-record.state'
import { CurrencyExchangeViewState, CurrencyState } from './currency.state'
import { ClaimPendingApprovalViewState } from './claim-pending-approval.state'
import { ClaimPendingPaymentViewState } from './claim-pending-payment.state'
import { ExpenseTypeState } from './expense-type.state'
import { CfConfigState } from './cf-config.state'

export const claimPersistConfig = {
  key: 'claim',
  whitelist: [
    'claimTypes',
    'claimTypesView',
    'ctProrationM',
    'currencies',
    'claimExpanded',
    'claimEntitlementsView',
    'claimEntitlementsViewRefetch',
    'claimEntitlements',
    'claimEntitlementsRefetch',
    'entitledClaimTypes',
    'claimRecordsView',
    'claimRecordsViewRefetch',
    'claimRecords',
    'claimEntitlementRecords',
    'cePolicies'
  ],
  storage
}

export interface ClaimRootState {
  claimTypes: EntityState<ClaimTypeState>
  claimTypesLoading: boolean
  claimTypesView: EntityState<ClaimTypeState>
  claimTypesViewLoading: boolean
  ctExpenseTypes: { [id: string]: EntityState<CtExpenseTypeState> }
  ctExpenseTypesLoading: { [id: string]: boolean }
  ctPolicies: { [id: string]: EntityState<CtPolicyState> }
  ctPoliciesLoading: { [id: string]: boolean }
  ctPolicyEt: { [id: string]: EntityState<CtPolicyEtState> }
  ctPolicyEtLoading: { [id: string]: boolean }
  ctProrationM: { [id: string]: EntityState<CtProrationMState> }
  ctProrationMLoading: { [id: string]: boolean }
  expenseTypes: EntityState<ExpenseTypeState>
  expenseTypesLoading: boolean

  cfConfigs: EntityState<CfConfigState>
  cfConfigsLoading: boolean

  currencies: EntityState<CurrencyState>
  currenciesLoading: boolean
  currencyExchangesView: EntityState<CurrencyExchangeViewState>
  currencyExchangesViewLoading: boolean
  currencyExchangesViewRefetch: number

  claimExpanded: boolean
  claimLoading: boolean

  claimEntitlementsView: EntityState<ClaimEntitlementViewState>
  claimEntitlementsViewLoading: boolean
  claimEntitlementsViewRefetch: number

  claimEntitlements: { [emYear: string]: EntityState<ClaimEntitlementState> }
  claimEntitlementsLoading: { [emYear: string]: boolean }
  claimEntitlementsRefetch: number

  entitledClaimTypes: { [employeeId: string]: EntityState<EntitledClaimTypeState> }
  entitledClaimTypesLoading: { [employeeId: string]: boolean }

  claimRecordsView: EntityState<ClaimRecordViewState>
  claimRecordsViewLoading: boolean
  claimRecordsViewRefetch: number

  claimRecords: EntityState<ClaimRecordState>
  claimRecordsLoading: boolean

  claimEntitlementRecords: { [id: string]: EntityState<ClaimEntitlementRecordState> }
  claimEntitlementRecordsLoading: { [id: string]: boolean }
  claimEntitlementRecordsRefetch: number

  cePolicies: { [entId: string]: EntityState<CePolicyState> }
  cePoliciesLoading: { [entId: string]: boolean }

  claimRecordAttachments: EntityState<ClaimRecordAttachmentState>
  claimRecordAttachmentsLoading: boolean

  claimRecordApprovalHistories: { [id: string]: EntityState<ClaimRecordApprovalHistoryState> }
  claimRecordApprovalHistoriesLoading: { [id: string]: boolean }

  claimPendingApprovalsView: EntityState<ClaimPendingApprovalViewState>
  claimPendingApprovalsViewLoading: boolean
  claimPendingApprovalsViewRefetch: number

  claimPendingPaymentsView: EntityState<ClaimPendingPaymentViewState>
  claimPendingPaymentsViewLoading: boolean
  claimPendingPaymentsViewRefetch: number
}
