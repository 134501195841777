import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { DailyRecordViewState } from '../types'

export const selectDailyRecordsByEmployeeView = createSelector(
  (state: StoreState) => state.attendance.dailyRecordsByEmployeeView,
  dailyRecordsState =>
    memoize(
      (employeeId: string, viewId: string): DailyRecordViewState => {
        return (dailyRecordsState[employeeId]?.entities[viewId] || {}) as DailyRecordViewState
      },
      (employeeId: string, viewId: string) => `${employeeId}|${viewId}`
    )
)
