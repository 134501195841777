import { request, getBaseUrl, timeout } from '~/utils'
import { Search } from '~/types/common'
import {
  LeaveEntitlementViewState,
  LeaveEntitlementState,
  EntitledLeaveTypeState,
  IAdjustLeaveEntitlement,
  LeEarnedState
} from '../types'

const baseUrl = getBaseUrl('/leave/leaveentitlement')

export const apiGetLeaveEntitlementView = (viewId: string, periodYear: number, params: Search) =>
  request<LeaveEntitlementViewState>('get', `${baseUrl}/view/${viewId}/${periodYear}`, params)

export const apiGetCurrentLeaveEntitlement = (employeeId: string, leaveTypeId: string) =>
  request<LeaveEntitlementState>('get', `${baseUrl}/current/${employeeId}/${leaveTypeId}`)

export const apiGetLeavePeriodYears = () => request('get', `${baseUrl}/periodyear`)

export const apiGetLeaveEntitlementExcel = (viewId: string, periodYear: number, params: Search) =>
  request('get', `${baseUrl}/view/${viewId}/${periodYear}/excel`, params, { responseType: 'blob', timeout })

export const apiGetEntitledLeaveTypes = (employeeId: string) =>
  request<EntitledLeaveTypeState[]>('get', `${baseUrl}/leavetypes/${employeeId}`)

export const apiAdjustLeaveEntitlement = (employeeId: string, leaveTypeId: string, adjust: IAdjustLeaveEntitlement) =>
  request('post', `${baseUrl}/current/${employeeId}/${leaveTypeId}`, adjust)

export const apiGetLeEarned = (periodCode: string, employeeId: string, leaveTypeId: string) =>
  request<LeEarnedState[]>('get', `${baseUrl}/${periodCode}/${employeeId}/${leaveTypeId}/earned`)
