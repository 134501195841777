import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'

export const selectKeyvaluesById = createSelector(
  (state: StoreState) => state.master.keyvalues,
  keyvaluesState =>
    memoize((id: string) => {
      return keyvaluesState[id]?.entities || {}
    })
)
