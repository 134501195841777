import React, { FC, useCallback, useMemo } from 'react'
import classNames from 'classnames'
import { Document, DocumentProps } from 'react-pdf'

export interface PdfDocAuthProps extends DocumentProps {}

export const PdfDocAuth: FC<PdfDocAuthProps> = ({ file, className, ...props }: PdfDocAuthProps) => {
  const classes = classNames('pdfdoc__wrapper', { [`${className}`]: className })

  const getHeaders = useCallback(() => {
    const headers: any = {}

    const token = localStorage.getItem('access_token')
    if (token) {
      headers['Authorization'] = `Bearer ${token}`
    }

    const tenant = localStorage.getItem('tenant')
    if (tenant) {
      headers['X-Tenant'] = tenant
    }

    return headers
  }, [])

  const fileProps = useMemo(
    () => ({
      url: file,
      httpHeaders: getHeaders()
    }),
    [file, getHeaders]
  )

  return (
    <div className={classes}>
      <Document file={fileProps} {...props} />
    </div>
  )
}
