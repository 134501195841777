import { request, getBaseUrl } from '~/utils/request'
import { SysSelectionFieldState, SysSelectionScreenState } from '../types/selection.state'

const baseUrl = getBaseUrl('/employee/sysselection')

export const apiGetSelectionScreens = (screenCode: string) =>
  request<SysSelectionScreenState[]>('get', `${baseUrl}/${screenCode}/screen`)

export const apiGetSelectionFields = (screenCode: string) =>
  request<SysSelectionFieldState[]>('get', `${baseUrl}/${screenCode}/selection`)
