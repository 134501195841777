import { showError, showSuccess } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { ISendActivation } from '../types'
import { apiSendActivation } from '../api/login.api'
import { setLogin } from '../reducers'
import moment from 'moment-timezone'

export const sendActivation = (request: ISendActivation): ThunkResult<void> => {
  return async (dispatch, getState): Promise<ActionResult> => {
    const { status, result, errors, message, errorData } = await apiSendActivation(request.id, request.tenantCode)
    if (status) {
      showSuccess('Sent')

      const login = getState().iam.logins.entities[request.id]
      if (login) {
        dispatch(setLogin({ ...login, activationSentDate: moment().format('YYYY-MM-DD') }))
      }
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
