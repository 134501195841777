import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Dictionary } from '@reduxjs/toolkit'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { FetchStrategy } from '~/types/common'
import { SysWfActionTypeState } from '../types'
import { fetchSysWfActionTypes } from '../actions'

export const useSysWfActionTypes = (
  flowType: string,
  eventType: string,
  fetchStrategy: FetchStrategy = 'when-empty'
): [Dictionary<SysWfActionTypeState>, boolean] => {
  const flowEventType = `${flowType}||${eventType}`
  const actionTypes = useSelector((state: StoreState) => state.workflow.sysWfActionTypes[flowEventType]?.entities || {})
  const loading = useSelector((state: StoreState) => state.workflow.sysWfActionTypesLoading[flowEventType] || false)

  useEffect(() => {
    dispatch(fetchSysWfActionTypes(flowType, eventType, { strategy: fetchStrategy }))
  }, [flowType, eventType, fetchStrategy])

  return [actionTypes, loading]
}
