export interface OtConfigState {
  code: string
  name: string
  isShown: boolean
}

export interface IOtConfig {
  name: string
  isShown: boolean
}

export const mapOtConfigStateToOtConfigInfo = (otConfig: OtConfigState): IOtConfig => ({
  name: otConfig.name,
  isShown: otConfig.isShown
})
