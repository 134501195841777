import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiDeleteFormula } from '../api/formula.api'
import { removeFormula } from '../reducers'

export const deleteFormula = (id: string): ThunkResult<void> => {
  return async dispatch => {
    if (!id) return

    const { status, errors, message, errorData } = await apiDeleteFormula(id)
    if (status) {
      dispatch(removeFormula(id))
      showSuccess('Deleted')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
