import React, { forwardRef, useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'
import { SecondaryText, Select, SelectProps, Space, Tag } from '~/core-components'
import { PersonAvatar } from '~/components'
import { emptyGuid } from '~/constants'
import { getBaseUrl } from '~/utils'
import './EmSelect.less'

export interface EmSelectState {
  id: string
  name: string
  photoId: string
  description: string
  isResignee: boolean
}

export interface EmSelectProps extends Omit<SelectProps, 'size'> {
  size?: 'default' | 'large'
  onDataLoaded?: (employees: EmSelectState[]) => void
  onFetch: () => Promise<EmSelectState[]>
}

const baseUrl = getBaseUrl('/filestore')

const EmSelectInternal = (
  { size = 'default', onFetch, onDataLoaded, className, ...props }: EmSelectProps,
  ref: React.Ref<any>
) => {
  const [employees, setEmployees] = useState<EmSelectState[]>()
  const [fetching, setFetching] = React.useState(false)
  const fetchRef = React.useRef(0)
  const classes = classNames('em-select', `em-select-${size}`, { [`${className}`]: className })

  const fetcher = useCallback(async () => {
    fetchRef.current += 1
    const fetchId = fetchRef.current
    setEmployees([])

    try {
      setFetching(true)
      const result = await onFetch()

      if (fetchId === fetchRef.current) {
        setEmployees(result)
      }
    } finally {
      if (fetchId === fetchRef.current) setFetching(false)
    }
  }, [onFetch])

  useEffect(() => {
    fetcher()
  }, [fetcher])

  useEffect(() => {
    if (fetching && employees && employees.length > 0) {
      typeof onDataLoaded === 'function' && onDataLoaded(employees)
    }
  }, [employees, onDataLoaded, fetching])

  return (
    <Select
      className={classes}
      showSearch
      allowClear={false}
      dropdownMatchSelectWidth={false}
      loading={fetching}
      optionFilterProp="title"
      {...props}
    >
      {employees?.map(em => (
        <Select.Option key={em.id} value={em.id} title={`${em.name}-${em.description}`}>
          <div className="em-select__person">
            <PersonAvatar
              photo={
                em.photoId && em.photoId !== emptyGuid ? `${baseUrl}/file/${em.photoId}/thumbnailphoto/36` : undefined
              }
              size={36}
              loading={fetching}
            />
            <div className="em-select__person__info">
              <h1>{em.name}</h1>
              <Space className="em-select__person__info-desc">
                {em.description && <SecondaryText>{em.description}</SecondaryText>}
                {em.isResignee && <Tag type="neutral">resignee</Tag>}
              </Space>
            </div>
          </div>
        </Select.Option>
      ))}
    </Select>
  )
}

export const EmSelect = forwardRef(EmSelectInternal)
