import { EntityState } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import {
  SSClaimRecordApprovalHistoryState,
  SSClaimRecordAttachmentState,
  SSClaimRecordCommentState,
  SSClaimRecordState,
  SSClaimRecordViewState,
  SSClaimTaskState
} from './ss-claim-record.state'
import { SSClaimBalanceState } from './ss-claim-entitlement.state'

export const myClaimPersistConfig = {
  key: 'myclaim',
  whitelist: [
    'myClaimBalances',
    'myClaimBalancesRefetch',
    'myClaimRecords',
    'myClaimRecordsView',
    'myClaimRecordsViewRefetch',
    'myClaimRecordAttachments',
    'myClaimRecordComments',
    'myClaimTasks',
    'myClaimTasksRefetch'
  ],
  storage
}

export interface MyClaimRootState {
  myClaimBalances: EntityState<SSClaimBalanceState>
  myClaimBalancesLoading: boolean
  myClaimBalancesRefetch: number
  myClaimRecords: EntityState<SSClaimRecordState>
  myClaimRecordsLoading: boolean
  myClaimRecordsView: SSClaimRecordViewState
  myClaimRecordsViewLoading: boolean
  myClaimRecordsViewRefetch: number
  myClaimRecordApprovalHistories: { [id: string]: EntityState<SSClaimRecordApprovalHistoryState> }
  myClaimRecordApprovalHistoriesLoading: { [id: string]: boolean }
  myClaimRecordAttachments: { [id: string]: EntityState<SSClaimRecordAttachmentState> }
  myClaimRecordAttachmentsLoading: { [id: string]: boolean }
  myClaimRecordComments: { [id: string]: EntityState<SSClaimRecordCommentState> }
  myClaimRecordCommentsLoading: { [id: string]: boolean }
  myClaimTasks: EntityState<SSClaimTaskState>
  myClaimTasksLoading: boolean
  myClaimTasksRefetch: number
}
