import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchIr21Submission } from '../actions'
import { Ir21SubmissionState } from '../types'

export const useIr21Submission = (
  id?: string,
  fetchStrategy: FetchStrategy = 'when-empty'
): [Ir21SubmissionState | undefined, boolean] => {
  const ir21Submission = useSelector((state: StoreState) => state.tax.ir21Submissions?.entities[id || ''])
  const loading = useSelector((state: StoreState) => state.tax.ir21SubmissionsLoading)

  useEffect(() => {
    if (id) dispatch(fetchIr21Submission(id, { strategy: fetchStrategy }))
  }, [id, fetchStrategy])

  return [ir21Submission, loading]
}
