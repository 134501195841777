import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { ShiftDayState, ShiftInfoState, ShiftState } from '../types'

const baseUrl = getBaseUrl('/attendance/shift')

export const apiGetShifts = () => request<ShiftState[]>('get', baseUrl)

export const apiAddShift = (shift: ShiftInfoState) => request<{ id: string }>('post', baseUrl, shift)

export const apiUpdateShift = (id: string, shift: Operation[]) => request<void>('patch', `${baseUrl}/${id}`, shift)

export const apiDeleteShift = (id: string) => request<void>('delete', `${baseUrl}/${id}`)

export const apiGetShiftDays = (shiftId: string) => request<ShiftDayState[]>('get', `${baseUrl}/${shiftId}/day`)

export const apiGetShiftDaysAggregated = (shiftId: string) =>
  request<ShiftDayState[]>('get', `${baseUrl}/${shiftId}/day/aggregated`)

export const apiUpdateShiftDay = (shiftId: string, id: string, shiftDay: Operation[]) =>
  request<void>('patch', `${baseUrl}/${shiftId}/day/${id}`, shiftDay)

export const apiCopyShiftDays = (shiftId: string, id: string, targetDayCodes: string[]) =>
  request<void>('post', `${baseUrl}/${shiftId}/day/${id}/copy`, { targetDayCodes })
