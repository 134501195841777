import { Dropdown as InternalDropdown, DropDownProps as InternalDropdownProps } from './Dropdown'
import { DropdownButton } from './DropdownButton'

type TDropdown = typeof InternalDropdown
interface IDropdown extends TDropdown {
  Button: typeof DropdownButton
}

const Dropdown: IDropdown = InternalDropdown as IDropdown
Dropdown.Button = DropdownButton
export { Dropdown }

export type DropdownProps = InternalDropdownProps
