import React, { FC, useCallback, useState } from 'react'
import { Button } from '~/core-components'
import './Ir21EmptyForm.less'

interface Ir21EmptyFormProps {
  onCreate: () => Promise<void>
}

export const Ir21EmptyForm: FC<Ir21EmptyFormProps> = ({ children, onCreate }) => {
  const [loading, setLoading] = useState(false)

  const handleCreate = useCallback(async () => {
    try {
      setLoading(true)
      typeof onCreate === 'function' && (await onCreate())
    } finally {
      setLoading(false)
    }
  }, [onCreate])

  return (
    <div className="ir21-empty-form">
      <div className="ir21-empty-form__body">{children}</div>
      <div className="ir21-empty-form__action">
        <Button type="primary" onClick={handleCreate} loading={loading}>
          Create
        </Button>
      </div>
    </div>
  )
}
