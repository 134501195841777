import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'
import { replaceCfConfigs, setCfConfigsLoading } from '../reducers'
import { apiGetCfConfigs } from '../api/cf-config.api'

export const fetchCfConfigs = (options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().claim.cfConfigsLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().claim.cfConfigs?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setCfConfigsLoading(true))
      const { result, status } = await apiGetCfConfigs()
      if (status) {
        dispatch(replaceCfConfigs(result))
      }
    } finally {
      dispatch(setCfConfigsLoading(false))
    }
  }
}
