import React, { FC, useCallback, useMemo } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { Tab, TabTitle, Tabs } from '~/core-components'
import { DocumentTitle } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { dispatch } from '~/stores/store'
import { ATT_ROUTES } from '~/routes/routes'
import { Permission } from '~/constants'
import { ScheduleRecords } from '../ScheduleRecords/ScheduleRecords'
import { refetchScheduleRecordsView } from '../../reducers'
import './ScheduleOverview.less'

export const ScheduleOverview: FC = () => {
  const match = useRouteMatch<{ tab: string }>()
  const history = useHistory()
  const canViewRecord = usePermissionGate(Permission.schRecord)

  const handleTabChange = useCallback(
    (activeKey: string) => {
      history.push(`${ATT_ROUTES.tab.replace(':tab?', activeKey)}`)

      if (activeKey === 'records') {
        dispatch(refetchScheduleRecordsView())
      }
    },
    [history]
  )

  const tabItems = useMemo(() => {
    const result: Tab[] = []
    if (canViewRecord) {
      result.push({ key: 'records', label: 'Records', children: <ScheduleRecords /> })
    }
    return result
  }, [canViewRecord])

  return (
    <div className="schedule-overview">
      <DocumentTitle title="Schedule Overview" />
      <div className="schedule-overview__body">
        <Tabs
          defaultActiveKey={match.params.tab || 'records'}
          onChange={handleTabChange}
          tabBarExtraContent={{
            left: <TabTitle title="Schedule overview" />
          }}
          items={tabItems}
        />
      </div>
    </div>
  )
}
