import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchSysLeaveTypes } from '../actions'
import { SysLeaveTypeState } from '../types'

export const useSysLeaveType = (code: string): [SysLeaveTypeState | undefined, boolean] => {
  const sysLeaveType = useSelector((state: StoreState) => state.leave.sysLeaveTypes?.entities[code])
  const loading = useSelector((state: StoreState) => state.leave.sysLeaveTypesLoading)

  useEffect(() => {
    dispatch(fetchSysLeaveTypes({ strategy: 'when-empty' }))
  }, [])

  return [sysLeaveType, loading]
}
