import React, { CSSProperties, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Card, ColumnsType, PageHeader, Skeleton, Table } from '~/core-components'
import { DocumentTitle, InactiveTag } from '~/components'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { AddPayGroupDrawer } from './components/AddPayGroupDrawer'
import { fetchCompanies, fetchMasters } from '../../actions'
import { selectCompaniesDict, selectPayGroupsByGrouping } from '../../selectors'
import { PayGroupState } from '../../types'
import { isInactive } from '~/utils/dateUtil'
import { ordinalSuffix } from '../../../../utils/ordinalSuffix'
import './PayGroups.less'

type PayGroupTable = {
  name: string
  children: PayGroupState[]
}

interface DrawerState {
  visible: boolean
  data?: PayGroupState
}

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: '',
    breadcrumbName: 'Overview'
  }
]

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

const groupRowStyle: CSSProperties = { whiteSpace: 'nowrap' }
const expandable = { defaultExpandAllRows: true, expandRowByClick: false }

export const PayGroups: FC = () => {
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const payGroups = useSelector(selectPayGroupsByGrouping)
  const loading = useSelector((state: StoreState) => state.master.mastersLoading.payGroup)
  const companies = useSelector(selectCompaniesDict)
  const canModify = usePermissionGate(Permission.payMaster, PermissionAction.Modify)

  useEffect(() => {
    dispatch(fetchMasters('payGroup'))
    dispatch(fetchCompanies({ strategy: 'when-empty' }))
  }, [])

  const handleAddPayGroup = useCallback(() => {
    setDrawerState({ visible: true, data: undefined })
  }, [setDrawerState])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const columns: ColumnsType<PayGroupTable | PayGroupState> = useMemo(
    () => [
      {
        title: 'Payroll group',
        key: 'name',
        dataIndex: 'name',
        render: (value: string, record) => {
          return 'children' in record ? (
            <div style={groupRowStyle}>{companies[value]?.name}</div>
          ) : (
            <Link to={SETTINGS_ROUTES.payGroup.replace(':id', record.id).replace(':tab?', 'general')}>
              {value} {isInactive(record.inactiveDate) && <InactiveTag />}
            </Link>
          )
        }
      },
      {
        title: 'Period',
        key: 'startDay',
        dataIndex: 'startDay',
        render: (value: number, record) => {
          if ('children' in record) {
            return null
          }
          const start = ordinalSuffix(record.startDay)
          const end = record.startDay === 1 ? 'end of the month' : `${ordinalSuffix(record.startDay - 1)} of next month`
          return `${start} to ${end}`
        }
      },
      {
        title: 'Payment day',
        key: 'paymentDay',
        dataIndex: 'paymentDay',
        width: 140,
        render: (value: number, record) => {
          if ('children' in record) {
            return null
          }

          if (record.isMidMonth) {
            return `${ordinalSuffix(record.paymentDay1)}, ${ordinalSuffix(record.paymentDay2)}`
          }
          return `${ordinalSuffix(value)}`
        }
      }
    ],
    [companies]
  )

  return (
    <div id="paygroups" className="paygroups">
      <DocumentTitle title="Payroll Groups" />
      <PageHeader
        title="Payroll groups"
        containerId="paygroups"
        breadcrumb={{ routes }}
        extra={
          canModify && (
            <Button key="add" onClick={handleAddPayGroup}>
              Add payroll group
            </Button>
          )
        }
      />
      <div className="paygroups__body">
        {payGroups?.length > 0 ? (
          <Card table>
            <Table
              rowKey="id"
              dataSource={payGroups}
              pagination={false}
              indentSize={1}
              expandable={expandable}
              columns={columns}
              loading={loading}
              className="paygroups__table"
            />
          </Card>
        ) : (
          <Skeleton active />
        )}
        {canModify && <AddPayGroupDrawer {...drawerState} onClose={handleCloseDrawer} />}
      </div>
    </div>
  )
}
