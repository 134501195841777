import React, { FC } from 'react'
import { Tag, TagProps, TagType } from '~/core-components'
import { useSysOptions } from '~/features/employee'

interface AttendanceGroupTypeTagProps extends TagProps {
  code?: string
}

const AttendanceGroupTypeMap: { [type: string]: { tagType: TagType } } = {
  m: {
    tagType: 'primary'
  },
  w: {
    tagType: 'tertiary'
  },
  r: {
    tagType: 'original'
  }
}

export const AttendanceGroupTypeTag: FC<AttendanceGroupTypeTagProps> = ({ code, ...props }) => {
  const [attendanceGroupTypes] = useSysOptions('attendance_group_type')
  const type = attendanceGroupTypes[code || '']

  if (!code) return null

  return (
    <Tag {...props} type={AttendanceGroupTypeMap[type?.key || '']?.tagType}>
      {type?.value?.toLowerCase()}
    </Tag>
  )
}
