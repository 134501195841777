import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import persistReducer from 'redux-persist/es/persistReducer'
import { StoreState } from '~/types/store'
import {
  OedLogState,
  OedRecordFormState,
  OedRecordViewState,
  OedSubmissionState,
  OedSubmissionViewState,
  OedSummaryState,
  ReportCode,
  reportPersistConfig,
  ReportRootState,
  ReportTemplateState,
  SysBankFileState
} from './types'

const sysBankFileAdapter = createEntityAdapter<SysBankFileState>()
const reportTemplatesAdapter = createEntityAdapter<ReportTemplateState>()
const oedSubmissionsAdapter = createEntityAdapter<OedSubmissionState>()
const oedSubmissionViewAdapter = createEntityAdapter<OedSubmissionViewState>({ selectId: model => model.viewId })
const oedRecordViewAdapter = createEntityAdapter<OedRecordViewState>({ selectId: model => model.viewId })
const oedRecordFormAdapter = createEntityAdapter<OedRecordFormState>()
const oedSummariesAdapter = createEntityAdapter<OedSummaryState>()
const oedLogsAdapter = createEntityAdapter<OedLogState>()

const sysBankFileInitialState = sysBankFileAdapter.getInitialState()
const reportTemplatesInitialState = reportTemplatesAdapter.getInitialState()
const oedSubmissionsInitialState = oedSubmissionsAdapter.getInitialState()
const oedSubmissionViewInitialState = oedSubmissionViewAdapter.getInitialState()
const oedRecordViewInitialState = oedRecordViewAdapter.getInitialState()
const oedRecordFormInitialState = oedRecordFormAdapter.getInitialState()
const oedSummariesInitialState = oedSummariesAdapter.getInitialState()
const oedLogsInitialState = oedLogsAdapter.getInitialState()

const initialState: ReportRootState = {
  sysBankFiles: sysBankFileInitialState,
  sysBankFilesLoading: false,
  reportTemplates: {},
  reportTemplatesLoading: {},
  reportTemplatesRefetch: 0,

  // OED Submissions
  oedSubmissions: oedSubmissionsInitialState,
  oedSubmissionsLoading: false,
  oedSubmissionsView: oedSubmissionViewInitialState,
  oedSubmissionsViewLoading: false,
  oedSubmissionsViewRefetch: 0,
  oedSubmissionExpanded: false,

  // OED Summary
  oedSummaries: oedSummariesInitialState,
  oedSummariesLoading: false,
  oedSummariesRefetch: 0,

  // OED Logs
  oedLogs: oedLogsInitialState,
  oedLogsLoading: false,
  oedLogsRefetch: 0,

  // OED Records
  oedRecordsView: oedRecordViewInitialState,
  oedRecordsViewLoading: false,
  oedRecordsViewRefetch: 0,

  // OED Record Form
  oedRecordForm: oedRecordFormInitialState,
  oedRecordFormLoading: false,
  oedRecordFormRefetch: 0,
  oedRecordFormUpdating: false,
  oedRecordFormDeleting: false
}

const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    replaceSysBankFiles: (state, action: PayloadAction<SysBankFileState[]>) => {
      sysBankFileAdapter.setAll(state.sysBankFiles, action.payload)
    },
    setSysBankFilesLoading: (state, action: PayloadAction<boolean>) => {
      state.sysBankFilesLoading = action.payload
    },

    // Report template
    refetchReportTemplates: state => {
      state.reportTemplatesRefetch += 1
    },
    setReportTemplatesLoading: (state, action: PayloadAction<{ reportCode: ReportCode; loading: boolean }>) => {
      const { reportCode, loading } = action.payload
      state.reportTemplates[reportCode] = state.reportTemplates[reportCode] || reportTemplatesInitialState
      state.reportTemplatesLoading[reportCode] = loading
    },
    setReportTemplate: (state, action: PayloadAction<{ reportCode: ReportCode; data: ReportTemplateState }>) => {
      const { reportCode, data } = action.payload
      reportTemplatesAdapter.upsertOne(state.reportTemplates[reportCode]!, data)
    },
    replaceReportTemplates: (state, action: PayloadAction<{ reportCode: ReportCode; data: ReportTemplateState[] }>) => {
      const { reportCode, data } = action.payload
      reportTemplatesAdapter.setAll(state.reportTemplates[reportCode]!, data)
    },
    removeReportTemplate: (state, action: PayloadAction<{ reportCode: ReportCode; id: string }>) => {
      const { reportCode, id } = action.payload
      reportTemplatesAdapter.removeOne(state.reportTemplates[reportCode]!, id)
    },

    // OED Submissions
    setOedSubmission: (state, action: PayloadAction<OedSubmissionState>) => {
      oedSubmissionsAdapter.upsertOne(state.oedSubmissions, action.payload)
    },
    setOedSubmissionsLoading: (state, action: PayloadAction<boolean>) => {
      state.oedSubmissionsLoading = action.payload
    },
    refetchOedSubmissionsView: state => {
      state.oedSubmissionsViewRefetch += 1
    },
    setOedSubmissionsViewLoading: (state, action: PayloadAction<boolean>) => {
      state.oedSubmissionsViewLoading = action.payload
    },
    setOedSubmissionsView: (state, action: PayloadAction<OedSubmissionViewState>) => {
      oedSubmissionViewAdapter.upsertOne(state.oedSubmissionsView, action.payload)
    },
    setOedSubmissionExpanded: (state, action: PayloadAction<boolean>) => {
      state.oedSubmissionExpanded = action.payload
    },

    // OED Summary
    setOedSummary: (state, action: PayloadAction<OedSummaryState>) => {
      oedSummariesAdapter.upsertOne(state.oedSummaries, action.payload)
    },
    setOedSummariesLoading: (state, action: PayloadAction<boolean>) => {
      state.oedSummariesLoading = action.payload
    },
    refetchOedSummary: state => {
      state.oedSummariesRefetch += 1
    },

    // OED Logs
    replaceOedLogs: (state, action: PayloadAction<OedLogState[]>) => {
      oedLogsAdapter.setAll(state.oedLogs, action.payload)
    },
    setOedLogsLoading: (state, action: PayloadAction<boolean>) => {
      state.oedLogsLoading = action.payload
    },
    refetchOedLogs: state => {
      state.oedLogsRefetch += 1
    },

    // OED Records
    refetchOedRecordsView: state => {
      state.oedRecordsViewRefetch += 1
    },
    setOedRecordsView: (state, action: PayloadAction<OedRecordViewState>) => {
      oedRecordViewAdapter.upsertOne(state.oedRecordsView, action.payload)
    },
    setOedRecordsViewLoading: (state, action: PayloadAction<boolean>) => {
      state.oedRecordsViewLoading = action.payload
    },

    // OED Record Form
    setOedRecordForm: (state, action: PayloadAction<OedRecordFormState>) => {
      oedRecordFormAdapter.upsertOne(state.oedRecordForm, action.payload)
    },
    setOedRecordFormLoading: (state, action: PayloadAction<boolean>) => {
      state.oedRecordFormLoading = action.payload
    },
    removeOedRecordForm: (state, action: PayloadAction<string>) => {
      oedRecordFormAdapter.removeOne(state.oedRecordForm, action.payload)
    },
    setOedRecordFormRefetch: state => {
      state.oedRecordFormRefetch += 1
    },
    setOedRecordFormUpdating: (state, action: PayloadAction<boolean>) => {
      state.oedRecordFormUpdating = action.payload
    },
    setOedRecordFormDeleting: (state, action: PayloadAction<boolean>) => {
      state.oedRecordFormDeleting = action.payload
    }
  }
})

export const {
  replaceSysBankFiles,
  setSysBankFilesLoading,
  refetchReportTemplates,
  setReportTemplatesLoading,
  setReportTemplate,
  replaceReportTemplates,
  removeReportTemplate,

  // OED Submissions
  setOedSubmission,
  setOedSubmissionsLoading,
  refetchOedSubmissionsView,
  setOedSubmissionsViewLoading,
  setOedSubmissionsView,
  setOedSubmissionExpanded,

  // OED Summary
  setOedSummary,
  setOedSummariesLoading,
  refetchOedSummary,

  // OED Logs
  replaceOedLogs,
  setOedLogsLoading,
  refetchOedLogs,

  // OED Records
  refetchOedRecordsView,
  setOedRecordsViewLoading,
  setOedRecordsView,

  // OED Record Form
  setOedRecordForm,
  setOedRecordFormLoading,
  removeOedRecordForm,
  setOedRecordFormRefetch,
  setOedRecordFormUpdating,
  setOedRecordFormDeleting
} = reportSlice.actions

export const { selectById: selectSysBankFileById } = sysBankFileAdapter.getSelectors(
  (state: StoreState) => state.report.sysBankFiles
)

export const reportReducers = { report: persistReducer<ReportRootState>(reportPersistConfig, reportSlice.reducer) }
