import { request, getBaseUrl } from '~/utils/request'
import { SSLeaveTaskTokenState } from '../types'

const baseUrl = getBaseUrl('/leave/ssleavetasktoken')

export const apiGetMyLeaveTaskByToken = (token: string) => request<SSLeaveTaskTokenState>('get', `${baseUrl}/${token}`)

export const apiApproveLeaveByToken = (token: string, comment: string) =>
  request('post', `${baseUrl}/approve/${token}`, { comment })

export const apiRejectLeaveByToken = (token: string, comment: string) =>
  request('post', `${baseUrl}/reject/${token}`, { comment })

export const apiAddCommentByToken = (token: string, comment: string) =>
  request('post', `${baseUrl}/comment/${token}`, { comment })
