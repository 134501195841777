import React, { ChangeEvent, FC, useCallback, useState, useEffect } from 'react'
import moment from 'moment-timezone'
import confirm from 'antd/lib/modal/confirm'
import { RangeValue } from 'rc-picker/lib/interface.d'
import { Col, DrawerForm, InfoTooltip, Row } from '~/components'
import { Collapse, Form, Input, Space } from '~/core-components'
import { EmPublicPerson, useSysOptions } from '~/features/employee'
import { LeaveTypeName, removeLeaveGrant, useLeaveType, useSysLeaveType } from '~/features/leave'
import { usePayRun } from '~/features/payroll'
import { ActionResult, Errors } from '~/types/store'
import { dispatch } from '~/stores/store'
import { useToggle } from '~/hooks/use-toggle'
import { useFocus } from '~/hooks/use-focus'
import { formatDateRange, formatNumberUnit, UnitType } from '~/utils'
import { IAdjustLeaveEntitlement, LeaveEntitlementRowState } from '../../../types'
import { adjustLeaveEntitlement, deleteLeaveGrant, revertSingleLeaveClosing } from '../../../actions'
import { LveSysLeaveTypeType, LveSysLeaveType } from '~/constants/leave'
import './EditLeaveEntitlementDrawer.less'

interface EditLeaveEntitlementDrawerProps {
  visible: boolean
  onClose: (success: boolean) => void
  entitlement?: LeaveEntitlementRowState
}

const EMPTY_FORM_DATA: IAdjustLeaveEntitlement = {
  id: '',
  sysLeaveTypeType: '',
  employeeId: '',
  leaveTypeId: '',
  cf: 0,
  cfExpiry: 0,
  cfExpiryDate: '',
  cfTaken: 0,
  earnedAdj: 0,
  takenAdj: 0,
  periodStartDate: '',
  periodEndDate: '',
  notes: '',
  encashed: 0,
  balance: 0
}

export const EditLeaveEntitlementDrawer: FC<EditLeaveEntitlementDrawerProps> = ({
  visible,
  onClose,
  entitlement
}: EditLeaveEntitlementDrawerProps) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<IAdjustLeaveEntitlement>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const [focusRef, setFocus] = useFocus(true)

  const leaveTypeId = entitlement?.leaveTypeId || ''
  const employeeId = entitlement?.employeeId || ''

  const [payRun] = usePayRun(entitlement?.encashedPayRunId || '')
  const payRunName = payRun?.description || ''

  const [leaveType] = useLeaveType(leaveTypeId)
  const leaveTypeName = leaveType?.name
  const sysLeaveTypeCode = leaveType?.sysLeaveTypeCode || ''
  const [sysLeaveType] = useSysLeaveType(sysLeaveTypeCode)
  const sysLeaveTypeType = sysLeaveType?.type || ''

  const [leaveUnits] = useSysOptions('lve_unit_display')
  const leaveUnit = leaveUnits[leaveType?.unit || '']?.value.toLowerCase()

  const [expandCf, toggleExpandCf] = useToggle()

  const showDelete = sysLeaveType?.type === LveSysLeaveTypeType.grant && entitlement?.taken === 0
  const showRevert =
    [LveSysLeaveTypeType.entitlement, LveSysLeaveTypeType.noEntitlement].includes(sysLeaveType?.type || '') &&
    entitlement?.isActivePeriod

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  useEffect(() => {
    if (entitlement) {
      const {
        id,
        employeeId,
        leaveTypeId,
        cf,
        cfExpiry,
        cfExpiryDate,
        cfTaken,
        earned,
        earnedAdj,
        takenAdj,
        periodStartDate,
        periodEndDate,
        notes,
        encashed,
        balance
      } = entitlement

      setFormData({
        id,
        employeeId,
        leaveTypeId,
        cf,
        cfExpiry,
        cfExpiryDate,
        cfTaken,
        earnedAdj: sysLeaveType?.type === LveSysLeaveTypeType.grant ? earned : earnedAdj,
        takenAdj,
        periodStartDate,
        periodEndDate,
        notes,
        encashed,
        balance
      })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [entitlement, sysLeaveType])

  useEffect(() => {
    if (sysLeaveTypeType) {
      setFormData(formData => ({ ...formData, sysLeaveTypeType }))
    }
  }, [sysLeaveTypeType])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      const updated = { ...formData, ...updates }

      updated.balance =
        (entitlement?.balance || 0) +
        ((updated?.earnedAdj || 0) - (entitlement?.earnedAdj || 0)) -
        ((updated?.takenAdj || 0) - (entitlement?.takenAdj || 0)) -
        ((updated?.encashed || 0) - (entitlement?.encashed || 0))

      if (sysLeaveType?.type === LveSysLeaveTypeType.grant)
        updated.balance = updated.balance - (entitlement?.earned || 0)

      setFormData({ ...updated, balance: parseFloat(updated.balance.toFixed(2)) })
    },
    [formData, entitlement, sysLeaveType]
  )

  const handleOk = useCallback(async () => {
    if (!leaveTypeId || !employeeId) return

    let result: ActionResult | undefined
    setLoading(true)
    try {
      result = await dispatch(adjustLeaveEntitlement(employeeId, leaveTypeId, formData))
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose(true)
      setFormData(EMPTY_FORM_DATA)
    }
  }, [leaveTypeId, employeeId, formData, onClose])

  const handleDelete = useCallback(
    (entitlement: LeaveEntitlementRowState | undefined) => {
      if (entitlement) {
        confirm({
          title: 'Delete leave entitlement',
          content: `Do you want to delete leave "${leaveTypeName} (${
            moment(entitlement.periodStartDate).isAfter(moment.now())
              ? `Valid from ${formatDateRange(entitlement.periodStartDate, entitlement.periodEndDate)}`
              : `Valid till ${moment(entitlement.periodEndDate).format('DD MMM YYYY')}`
          })"?`,
          onOk: async () => {
            let result: ActionResult | undefined
            setLoading(true)
            try {
              if (sysLeaveTypeType === LveSysLeaveTypeType.grant) {
                result = await dispatch(deleteLeaveGrant(entitlement.id))
                dispatch(removeLeaveGrant({ employeeId: entitlement.employeeId, id: entitlement.id }))
              }
            } finally {
              setLoading(false)
            }

            if (result?.errors) {
              setErrors(result.errors)
            }

            if (!result?.errors) {
              typeof onClose === 'function' && onClose(true)
              setFormData(EMPTY_FORM_DATA)
            }
          },
          okText: 'Delete',
          okType: 'danger'
        })
      }
    },
    [leaveTypeName, sysLeaveTypeType, onClose]
  )

  const handleRevert = useCallback(
    (entitlement: LeaveEntitlementRowState | undefined) => {
      if (entitlement) {
        confirm({
          title: 'Delete leave entitlement',
          content: `Do you want to delete leave "${leaveTypeName}?`,
          onOk: async () => {
            let result: ActionResult | undefined
            setLoading(true)
            try {
              if ([LveSysLeaveTypeType.entitlement, LveSysLeaveTypeType.noEntitlement].includes(sysLeaveTypeType)) {
                result = await dispatch(revertSingleLeaveClosing(entitlement.employeeId, entitlement.leaveTypeId))
              }
            } finally {
              setLoading(false)
            }

            if (result?.errors) {
              setErrors(result.errors)
            }

            if (!result?.errors) {
              typeof onClose === 'function' && onClose(true)
              setFormData(EMPTY_FORM_DATA)
            }
          },
          okText: 'Delete',
          okType: 'danger'
        })
      }
    },
    [leaveTypeName, sysLeaveTypeType, onClose]
  )

  return (
    <DrawerForm
      open={visible}
      title="Adjustment"
      onClose={() => onClose(false)}
      onDelete={() => (showDelete ? handleDelete(entitlement) : handleRevert(entitlement))}
      showDelete={showDelete || showRevert}
      confirmLoading={loading}
      width={500}
      className="edit-leave-entitlement-drawer"
      formId="form-ent-leave"
    >
      <Form
        id="form-ent-leave"
        layout="horizontal"
        labelAlign="left"
        labelCol={{ flex: '200px' }}
        colon={false}
        onFinish={handleOk}
      >
        <Row>
          <Col span={24}>
            <Form.Item>
              <EmPublicPerson id={employeeId} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Leave type">
              <LeaveTypeName id={leaveTypeId} hideNewTab />
            </Form.Item>
          </Col>
        </Row>
        {sysLeaveType?.code === LveSysLeaveType.anl && (
          <>
            <div className="entitlement__subtitle">Carry forward</div>
            <Row>
              <Col span={24}>
                <Collapse activeKey={expandCf ? 1 : 0} onChange={toggleExpandCf} expandIconPosition="end">
                  <Collapse.Panel
                    key="1"
                    header={
                      <Form.Item label="Carry forward" validateStatus={errors?.cf ? 'error' : ''} help={errors?.cf}>
                        <Space>
                          <Input.Number
                            value={formData.cf}
                            step={0.5}
                            onChange={(value: number | null) => handleFormDataChange({ cf: value })}
                          />
                          <span>{leaveUnit}</span>
                        </Space>
                      </Form.Item>
                    }
                  >
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          label="Carry forward with expiry"
                          labelCol={{ flex: '175px' }}
                          validateStatus={errors?.cfExpiry ? 'error' : ''}
                          help={errors?.cfExpiry}
                        >
                          <Space>
                            <Input.Number
                              value={formData.cfExpiry}
                              step={0.5}
                              onChange={(value: number | null) => handleFormDataChange({ cfExpiry: value })}
                            />
                            <span>{leaveUnit}</span>
                          </Space>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Form.Item label="Taken" labelCol={{ flex: '175px' }}>
                          <Space>
                            <span className="value-readonly">{formData.cfTaken}</span>
                            <span>{leaveUnit}</span>
                          </Space>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <hr />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Form.Item label="Balance" labelCol={{ flex: '175px' }}>
                          <Space>
                            <span className="value-readonly">{formData.cfExpiry - formData.cfTaken}</span>
                            <span>{leaveUnit}</span>
                          </Space>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          label="Expiry on"
                          labelCol={{ flex: '175px' }}
                          validateStatus={errors?.cfExpiryDate ? 'error' : ''}
                          help={errors?.cfExpiryDate}
                        >
                          <Input.Date
                            value={formData.cfExpiryDate ? moment(formData.cfExpiryDate) : undefined}
                            onChange={(value: moment.Moment | null) => handleFormDataChange({ cfExpiryDate: value })}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Collapse.Panel>
                </Collapse>
              </Col>
            </Row>
          </>
        )}
        {sysLeaveType?.type === LveSysLeaveTypeType.entitlement && (
          <>
            <div className="entitlement__subtitle">Earned</div>
            <Row>
              <Col span={24}>
                <Form.Item
                  label={
                    <Space>
                      <span>Earned</span>
                      <span className="label--small">
                        {entitlement?.asAtDate ? ` as of ${moment(entitlement.asAtDate).format('DD MMM YYYY')}` : ''}
                      </span>
                    </Space>
                  }
                >
                  {formatNumberUnit(
                    ((entitlement?.earned || 0) - (entitlement?.earnedAdj || 0)).toFixed(2),
                    leaveType?.unit as UnitType
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  label="Adjustment"
                  validateStatus={errors?.earnedAdj ? 'error' : ''}
                  help={errors?.earnedAdj}
                >
                  <Space>
                    <Input.Number
                      ref={focusRef}
                      value={formData.earnedAdj}
                      onChange={(value: number | null) => handleFormDataChange({ earnedAdj: value })}
                    />
                    <span>{leaveUnit}</span>
                  </Space>
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
        {sysLeaveType?.type === LveSysLeaveTypeType.grant && (
          <>
            <div className="entitlement__subtitle">Earned</div>
            <Row>
              <Col span={24}>
                <Form.Item label="Granted" validateStatus={errors?.earned ? 'error' : ''} help={errors?.earned}>
                  <Space>
                    <Input.Number
                      ref={focusRef}
                      value={formData.earnedAdj}
                      onChange={(value: number | null) => handleFormDataChange({ earnedAdj: value })}
                    />
                    <span>{leaveUnit}</span>
                  </Space>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  label="Validity period"
                  validateStatus={errors?.periodStartDate || errors?.periodEndDate ? 'error' : ''}
                  help={errors?.periodStartDate || errors?.periodEndDate}
                >
                  <Input.DateRange
                    value={[
                      formData.periodStartDate ? moment(formData.periodStartDate) : null,
                      formData.periodEndDate ? moment(formData.periodEndDate) : null
                    ]}
                    onCalendarChange={(dates: RangeValue<moment.Moment>) => {
                      const periodStartDate = dates && dates[0] ? dates[0].format('YYYY-MM-DD') : null
                      const periodEndDate = dates && dates[1] ? dates[1].format('YYYY-MM-DD') : null

                      if (
                        periodStartDate !== null &&
                        periodEndDate !== null &&
                        (periodStartDate !== formData.periodStartDate || periodEndDate !== formData.periodEndDate)
                      ) {
                        handleFormDataChange({ periodStartDate, periodEndDate })
                      }
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item label="Notes" validateStatus={errors?.notes ? 'error' : ''} help={errors?.notes}>
                  <Input.TextArea
                    rows={5}
                    value={formData.notes}
                    onChange={(value?: ChangeEvent<HTMLTextAreaElement>) =>
                      handleFormDataChange({ notes: value?.target.value })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}

        <div className="entitlement__subtitle">Taken</div>
        <Row>
          <Col span={24}>
            <Form.Item label="Taken">
              <Space>
                <span className="value-readonly">
                  {((entitlement?.cfTaken || 0) + (entitlement?.taken || 0)).toFixed(2)}
                </span>
                <span>{leaveUnit}</span>
              </Space>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Adjustment" validateStatus={errors?.takenAdj ? 'error' : ''} help={errors?.takenAdj}>
              <Space>
                <Input.Number
                  value={formData.takenAdj}
                  onChange={(value: number | null) => handleFormDataChange({ takenAdj: value })}
                />
                <span>{leaveUnit}</span>
              </Space>
            </Form.Item>
          </Col>
        </Row>
        <Row hidden={(entitlement?.pending || 0) === 0}>
          <Col span={24}>
            <Form.Item label="Pending">
              <Space>
                <span className="value-readonly">{entitlement?.pending || 0}</span>
                <span>{leaveUnit}</span>
              </Space>
            </Form.Item>
          </Col>
        </Row>
        <Row hidden={(entitlement?.cfForfeited || 0) === 0}>
          <Col span={24}>
            <Form.Item label="Expired">
              <Space>
                <span className="value-readonly">{entitlement?.cfForfeited || 0}</span>
                <span>{leaveUnit}</span>
              </Space>
            </Form.Item>
          </Col>
        </Row>
        {sysLeaveType?.type !== LveSysLeaveTypeType.noEntitlement && (
          <>
            <div className="entitlement__subtitle">Balance</div>
            <Row>
              <Col span={24}>
                <Form.Item label="Balance">
                  <Space>
                    <span className={formData.balance < 0 ? 'value-readonly minus-sign' : 'value-readonly'}>
                      {formData.balance}
                    </span>
                    <span>{leaveUnit}</span>
                    {!!entitlement?.poolTaken && (
                      <InfoTooltip
                        title={`${formatNumberUnit(
                          entitlement?.poolTaken || 0,
                          leaveType?.unit as UnitType
                        )} taken from other leave type(s)`}
                      />
                    )}
                  </Space>
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
        {sysLeaveTypeCode === LveSysLeaveType.anl && (
          <>
            <Row>
              <Col span={24}>
                <Form.Item label="Encashed" validateStatus={errors?.encashed ? 'error' : ''} help={errors?.encashed}>
                  {payRunName !== '' ? (
                    <Space>
                      <span className="value-readonly">{entitlement?.encashed || 0}</span>
                      <span>{leaveUnit}</span>
                    </Space>
                  ) : (
                    <Space>
                      <Input.Number
                        value={formData.encashed}
                        onChange={(value: number | null) => handleFormDataChange({ encashed: value })}
                        readOnly={payRunName !== ''}
                      />
                      <span>{leaveUnit}</span>
                    </Space>
                  )}
                </Form.Item>
              </Col>
            </Row>
            {formData.encashed !== 0 && (
              <Row>
                <Col span={24}>
                  <Form.Item label="Payment">{payRunName !== '' ? payRunName : 'Pending'}</Form.Item>
                </Col>
              </Row>
            )}
          </>
        )}
      </Form>
    </DrawerForm>
  )
}
