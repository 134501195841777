import { replaceClaimEntitlements, setClaimEntitlementsLoading } from '../reducers'
import { apiGetClaimEntitlements } from '../api/claim-entitlement.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchClaimEntitlements = (
  periodYear: number | undefined,
  employeeId: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!periodYear || !employeeId) return

    const emYear = `${periodYear}|${employeeId}`

    const loading = getState().claim.claimEntitlementsLoading[emYear]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().claim.claimEntitlements[emYear]
      if (hasData) return
    }

    try {
      dispatch(setClaimEntitlementsLoading({ emYear, loading: true }))
      const { result, status } = await apiGetClaimEntitlements(periodYear, employeeId)
      if (status) {
        dispatch(replaceClaimEntitlements({ emYear, data: result }))
      }
    } finally {
      dispatch(setClaimEntitlementsLoading({ emYear, loading: false }))
    }
  }
}
