import React, { FC, useCallback, useEffect, useState } from 'react'
import { Form } from '~/core-components'
import { Col, EditableCard, EditableCardState, Row, SysOptions } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { dispatch } from '~/stores/store'
import { Errors, ActionResult } from '~/types/store'
import { ClaCurrencyCode, ClaPaymentMethod, Permission, PermissionAction } from '~/constants'
import { useFocus } from '~/hooks/use-focus'
import { ClaimTypePaymentState, ClaimTypeState } from '../../../types'
import { updateClaimType } from '../../../actions'
import { KeyValue } from '~/types/common'

interface ClaimTypePaymentProps {
  claimType?: ClaimTypeState
  onEdit?: () => void
  onSave?: () => void
  onCancel?: () => void
}

interface FormData extends ClaimTypePaymentState {}

const EMPTY_FORM_DATA: FormData = {
  paymentMethod: ''
}

export const ClaimTypePayment: FC<ClaimTypePaymentProps> = ({ claimType, onEdit, onSave, onCancel }) => {
  const [cardState, setCardState] = useState<EditableCardState>()
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const canModify = usePermissionGate(Permission.claType, PermissionAction.Modify)
  const readOnly = cardState !== 'editing' && cardState !== 'saving'
  const [focusRef] = useFocus(!readOnly)

  useEffect(() => {
    if (claimType) {
      const { paymentMethod } = claimType
      setFormData({ paymentMethod })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [claimType])

  const handleEdit = useCallback(() => {
    setCardState('editing')
    typeof onEdit === 'function' && onEdit()
  }, [onEdit])

  const handleSave = useCallback(async () => {
    if (claimType) {
      setCardState('saving')
      setErrors(undefined)

      typeof onSave === 'function' && onSave()

      let result: ActionResult | undefined
      try {
        result = await dispatch(updateClaimType(claimType.id, claimType, formData))
      } catch {
        setCardState('editing')
      }

      if (result?.errors) {
        setCardState('editing')
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setCardState(undefined)
      }
    }
  }, [claimType, formData, onSave])

  const handleCancel = useCallback(() => {
    typeof onCancel === 'function' && onCancel()
    setCardState(undefined)
    setErrors(undefined)

    if (claimType) {
      const { paymentMethod } = claimType
      setFormData({ paymentMethod })
    }
  }, [claimType, onCancel])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  return (
    <EditableCard
      title="Reimbursement setting"
      bodyStyle={{ paddingBottom: claimType ? 6 : 24, paddingTop: 6 }}
      state={!canModify ? 'readonly' : cardState}
      formId="form-claim-type-payment"
      onEdit={handleEdit}
      onSave={handleSave}
      onCancel={handleCancel}
    >
      <Row gutter={30}>
        <Col span={12}>
          <Form.Item
            label="Reimbursement method"
            validateStatus={errors?.paymentMethod ? 'error' : ''}
            help={errors?.paymentMethod}
          >
            <SysOptions
              ref={focusRef}
              type="cla_payment_method"
              readOnly={readOnly}
              value={formData.paymentMethod}
              onFilter={(value: KeyValue | undefined) =>
                claimType?.currencyCode === ClaCurrencyCode.sgd || ClaPaymentMethod.payroll !== value?.key
              }
              onChange={(value: string) => handleFormDataChange({ paymentMethod: value })}
            />
          </Form.Item>
        </Col>
      </Row>
    </EditableCard>
  )
}
