import React, { FC, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { LoadingOutlined } from '@ant-design/icons'
import { ColumnsType, Link, Spin, Table } from '~/core-components'
import { isEditablePayTranSource, selectPayRecordById } from '~/features/payroll'
import { usePermissionGate } from '~/features/iam/hooks'
import { PayItemId, PayRunStatus, PayTranGroup, PayTranSource, Permission, PermissionAction } from '~/constants'
import { formatMoney } from '~/utils'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchPayTranDtlsSg, processPayroll } from '../../actions'
import { selectPayRunById } from '../../reducers'
import { PayTranSgState } from '../../types'
import { EditPayTranEntryDrawer } from './EditPayTranEntryDrawer'
import { EditPayTranImportDrawer } from './EditPayTranImportDrawer'
import { EditPayRecurringDrawer } from './EditPayRecurringDrawer'
import { EditPayTranAdjDrawer } from './EditPayTranAdjDrawer'
import { PayTranDtls } from './PayTranDtls'
import { PayItemName } from '~/features/master'
import './PayTransByGroup.less'

export interface PayTransByGroupProps {
  payRunId: string
  payRecordId: string
  group: string
  payTrans: PayTranSgState[]
}

type PayTranSgTable = PayTranSgState

interface DrawerState {
  visible: boolean
  payTran?: PayTranSgState
}

interface AdjDrawerState {
  visible: boolean
  payRunId: string
  employeeId: string
  originalAmount: number
  adjustmentRefCode: string
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }
const DEFAULT_ADJ_DRAWER_STATE: AdjDrawerState = {
  visible: false,
  payRunId: '',
  employeeId: '',
  originalAmount: 0,
  adjustmentRefCode: ''
}

export const PayTransByGroup: FC<PayTransByGroupProps> = ({ payRunId, payRecordId, group, payTrans }) => {
  const [entryDrawerState, setEntryDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const [importDrawerState, setImportDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const [recurringDrawerState, setRecurringDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const [adjDrawerState, setAdjDrawerState] = useState<AdjDrawerState>(DEFAULT_ADJ_DRAWER_STATE)
  const payRecord = useSelector(selectPayRecordById)(payRunId, payRecordId)
  const loading = useSelector((state: StoreState) => state.payroll.payTransLoading[payRecordId])
  const payRun = useSelector((state: StoreState) => selectPayRunById(state, payRunId))
  const canModify = usePermissionGate(Permission.payRun, PermissionAction.Modify)

  const handleCloseEntryDrawer = useCallback(
    (action: 'save' | 'cancel') => {
      if (action === 'save' && payRecord) {
        dispatch(processPayroll(payRecord.payRunId, [payRecord.employeeId]))
      }
      setEntryDrawerState(DEFAULT_DRAWER_STATE)
    },
    [payRecord]
  )

  const handleCloseImportDrawer = useCallback(
    (action: 'save' | 'cancel') => {
      if (action === 'save' && payRecord) {
        dispatch(processPayroll(payRecord.payRunId, [payRecord.employeeId]))
      }
      setImportDrawerState(DEFAULT_DRAWER_STATE)
    },
    [payRecord]
  )

  const handleCloseRecurringDrawer = useCallback(
    (action: 'save' | 'cancel') => {
      if (action === 'save' && payRecord) {
        dispatch(processPayroll(payRecord.payRunId, [payRecord.employeeId]))
      }
      setRecurringDrawerState(DEFAULT_DRAWER_STATE)
    },
    [payRecord]
  )

  const handleCloseAdjDrawer = useCallback(
    (action: 'save' | 'cancel') => {
      if (action === 'save' && payRecord) {
        dispatch(processPayroll(payRecord.payRunId, [payRecord.employeeId]))
      }
      setAdjDrawerState(DEFAULT_ADJ_DRAWER_STATE)
    },
    [payRecord]
  )

  const handleTranEditClick = useCallback((payTran: PayTranSgTable) => {
    const payRunId = payTran?.payRunId || ''
    const employeeId = payTran?.employeeId || ''
    const payItemId = payTran?.payItemId || ''

    if (payTran.source === PayTranSource.entry) {
      setEntryDrawerState({ visible: true, payTran })
    } else if (payTran.source === PayTranSource.import) {
      setImportDrawerState({ visible: true, payTran })
    } else if (payTran.source === PayTranSource.recurring) {
      setRecurringDrawerState({ visible: true, payTran })
    } else if (payTran.source === PayTranSource.fund) {
      setAdjDrawerState({
        visible: true,
        payRunId,
        employeeId,
        originalAmount: payTran.amount,
        adjustmentRefCode: payItemId
      })
    } else if (payTran.source === PayTranSource.pyCpf) {
      setAdjDrawerState({
        visible: true,
        payRunId,
        employeeId,
        originalAmount: payTran.amount,
        adjustmentRefCode: `${payTran.id}_adj`
      })
    }
  }, [])

  const handleRowExpand = useCallback(
    (expanded: boolean, payTran: PayTranSgTable) => {
      if (expanded) {
        dispatch(fetchPayTranDtlsSg(payRecordId, payTran.id, { strategy: 'when-empty' }))
      }
    },
    [payRecordId]
  )

  const columns: ColumnsType<PayTranSgTable> = [
    {
      title: 'Pay item',
      key: 'payItemId',
      dataIndex: 'payItemId',
      width: 180,
      render: (value: string) => <PayItemName id={value} />
    },
    {
      title: 'Amount',
      key: 'amount',
      dataIndex: 'amount',
      width: 120,
      align: 'right',
      render: (value: number) => {
        return formatMoney(value, 2)
      }
    },
    {
      key: 'action',
      align: 'right',
      width: 50,
      render: (value: any, record: PayTranSgTable) => {
        if (!canModify || payRun?.status === PayRunStatus.completed || !!payRecord?.lockedBy) {
          return
        }

        if (isEditablePayTranSource(record.source)) {
          if (record.payItemId === PayItemId.BasicPay && record.source === PayTranSource.basicSalary) {
            return
          }

          if (
            record.source === PayTranSource.basicSalary &&
            payTrans.filter(pt => pt.group === PayTranGroup.basicPay).length > 1
          ) {
            return
          }

          if (
            record.source === PayTranSource.fund &&
            payTrans.filter(pt => pt.group === PayTranGroup.fund).length > 1
          ) {
            return
          }

          return (
            <Link size="small" onClick={() => handleTranEditClick(record)}>
              edit
            </Link>
          )
        }
      }
    }
  ]

  return (
    <>
      {loading ? (
        <Spin indicator={<LoadingOutlined spin />} />
      ) : (
        <Table
          rowKey="id"
          dataSource={payTrans}
          pagination={false}
          showHeader={false}
          columns={columns}
          className="paytransbygroup__table"
          expandable={{
            // expandRowByClick: true,
            rowExpandable: (record: PayTranSgTable) => {
              if (
                record.group === PayTranGroup.fund ||
                record.group === PayTranGroup.cpfEmployee ||
                record.group === PayTranGroup.cpfEmployer
              ) {
                return false
              }
              return true
            },
            expandedRowRender: (record: PayTranSgTable) => <PayTranDtls payTranId={record.id} />,
            onExpand: handleRowExpand
          }}
        />
      )}
      {canModify && <EditPayTranEntryDrawer id={group} {...entryDrawerState} onClose={handleCloseEntryDrawer} />}
      {canModify && <EditPayTranImportDrawer id={group} {...importDrawerState} onClose={handleCloseImportDrawer} />}
      {canModify && (
        <EditPayRecurringDrawer id={group} {...recurringDrawerState} onClose={handleCloseRecurringDrawer} />
      )}
      {canModify && <EditPayTranAdjDrawer id={group} {...adjDrawerState} onClose={handleCloseAdjDrawer} />}
    </>
  )
}
