import React, { FC, memo, useCallback, useState } from 'react'
import { Card, Descriptions, Link, Space, Tag } from '~/core-components'
import { fetchEmPublic, useSysOptions } from '~/features/employee'
import { SSEmPublicSelfState } from '~/features/my'
import { dispatch } from '~/stores/store'
import { EditMyEmergencyDrawer } from './EditMyEmergencyDrawer'

interface MyEmergencyContactsProps {
  data: SSEmPublicSelfState
  hideSelfUpdate: boolean
}

interface DrawerState {
  visible: boolean
  data?: SSEmPublicSelfState
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

const PendingTag = memo(() => <Tag type="secondary">pending</Tag>)

export const MyEmergencyContacts: FC<MyEmergencyContactsProps> = ({ data, hideSelfUpdate }) => {
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const [relationships] = useSysOptions('relationship')

  const isDiffEmergencyName = data.emergencyName !== data.emergencyNameNew
  const isDiffEmergencyRelation = data.emergencyRelation !== data.emergencyRelationNew
  const isDiffEmergencyPhone = data.emergencyPhone !== data.emergencyPhoneNew
  const isDiffEmergencyName2 = data.emergencyName2 !== data.emergencyName2New
  const isDiffEmergencyRelation2 = data.emergencyRelation2 !== data.emergencyRelation2New
  const isDiffEmergencyPhone2 = data.emergencyPhone2 !== data.emergencyPhone2New

  const showSecondary = !!data.emergencyName2 || !!data.emergencyName2New

  const handleEdit = useCallback((personal: SSEmPublicSelfState) => {
    setDrawerState({ visible: true, data: personal })
  }, [])

  const handleCloseDrawer = useCallback(
    (action: 'saved' | 'cancelled') => {
      if (action === 'saved') {
        dispatch(fetchEmPublic(data.id, { strategy: 'always' }))
      }
      setDrawerState(DEFAULT_DRAWER_STATE)
    },
    [data.id]
  )
  return (
    <>
      <Card title="Emergency contacts" extra={!hideSelfUpdate && <Link onClick={() => handleEdit(data)}>edit</Link>}>
        <Descriptions colon={false} column={2}>
          {showSecondary && <Descriptions.Item span={2}>Primary</Descriptions.Item>}
          <Descriptions.Item label="Name" span={2}>
            <Space>
              {data.emergencyNameNew || '-'}
              {isDiffEmergencyName && <PendingTag />}
            </Space>
          </Descriptions.Item>
          <Descriptions.Item label="Relationship">
            <Space>
              {relationships[data.emergencyRelationNew || '']?.value || '-'}
              {isDiffEmergencyRelation && <PendingTag />}
            </Space>
          </Descriptions.Item>
          <Descriptions.Item label="Contact no.">
            <Space>
              {data.emergencyPhoneNew || '-'}
              {isDiffEmergencyPhone && <PendingTag />}
            </Space>
          </Descriptions.Item>
          {showSecondary && (
            <>
              <Descriptions.Item span={2}>Secondary</Descriptions.Item>
              <Descriptions.Item label="Name" span={2}>
                <Space>
                  {data.emergencyName2New || '-'}
                  {isDiffEmergencyName2 && <PendingTag />}
                </Space>
              </Descriptions.Item>
              <Descriptions.Item label="Relationship">
                <Space>
                  {relationships[data.emergencyRelation2New || '']?.value || '-'}
                  {isDiffEmergencyRelation2 && <PendingTag />}
                </Space>
              </Descriptions.Item>
              <Descriptions.Item label="Contact no.">
                <Space>
                  {data.emergencyPhone2New || '-'}
                  {isDiffEmergencyPhone2 && <PendingTag />}
                </Space>
              </Descriptions.Item>
            </>
          )}
        </Descriptions>
      </Card>
      {!hideSelfUpdate && <EditMyEmergencyDrawer {...drawerState} onClose={handleCloseDrawer} />}
    </>
  )
}
