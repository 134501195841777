import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Card, ColumnsType, PageHeader, Table, Link } from '~/core-components'
import { DocumentTitle, InactiveTag } from '~/components'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { isInactive } from '~/utils/dateUtil'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS } from '~/types/common'
import { MutateMasterDrawer } from './components/MutateMasterDrawer'
import {
  fetchDepartmentsExtra,
  fetchDivisionsExtra,
  fetchMasters,
  fetchSectionsExtra,
  fetchTeamsExtra
} from '../../actions'
import { MasterName, MasterState } from '../../types'
import { MasterConfigs } from './MasterConfigs'
import { selectMasters } from '../../selectors'
import './Masters.less'

interface DrawerState {
  visible: boolean
  data?: MasterState
}

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: '',
    breadcrumbName: 'Overview'
  }
]

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

interface MastersProps {
  masterName: MasterName
  sysPermissionId?: string
}

const Masters: FC<MastersProps> = ({ sysPermissionId = Permission.master, masterName }: MastersProps) => {
  if (!(masterName in MasterConfigs)) {
    console.error(`Missing ${masterName} in MasterConfig`)
  }

  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const configs = MasterConfigs[masterName]
  const MutationDrawer: React.ComponentType<any> = configs.drawer || MutateMasterDrawer
  const masters = useSelector(selectMasters)(masterName)
  const customs = useSelector((state: StoreState) => (configs.selector ? configs.selector(state) : undefined))
  const loading = useSelector((state: StoreState) => state.master.mastersLoading[masterName])
  const data = configs.selector ? customs : masters
  const fetchData = configs.fetchData ? configs.fetchData : fetchMasters
  const canModify = usePermissionGate(sysPermissionId, PermissionAction.Modify)
  const refetch = useSelector((state: StoreState) => state.master.mastersRefetch[masterName])

  useEffect(() => {
    dispatch(fetchData(masterName, DEFAULT_FETCH_OPTIONS))
  }, [masterName, fetchData, refetch])

  const handleAddMaster = useCallback(() => {
    setDrawerState({ visible: true, data: undefined })
  }, [])

  const handleEditMaster = useCallback((master: MasterState) => {
    setDrawerState({ visible: true, data: master })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const masterColumns = configs.columns || [
    {
      title: configs.title,
      key: 'name',
      dataIndex: 'name',
      render: (value: string, record) => (
        <>
          {value} {isInactive(record.inactiveDate) && <InactiveTag />}
        </>
      )
    }
  ]

  const columns: ColumnsType<MasterState> = [
    ...masterColumns,
    {
      key: 'action',
      align: 'right',
      className: 'fit-width',
      render: (value: string, record) =>
        canModify && (
          <Link size="small" onClick={() => handleEditMaster(record)}>
            edit
          </Link>
        )
    }
  ]

  return (
    <div id="masters" className="masters">
      <DocumentTitle title={configs.documentTitle || configs.title} />
      <PageHeader
        title={configs.title}
        containerId="masters"
        breadcrumb={{ routes }}
        extra={
          canModify && (
            <Button key="add" onClick={handleAddMaster}>
              Add {configs.name.toLowerCase()}
            </Button>
          )
        }
      />
      <div className="masters__body">
        <Card table>
          <Table rowKey="id" dataSource={data} pagination={false} columns={columns} loading={loading} />
        </Card>
        {canModify && <MutationDrawer masterName={masterName} {...drawerState} onClose={handleCloseDrawer} />}
      </div>
    </div>
  )
}

export const Grps: FC = () => <Masters masterName="grp" />
export const Categories: FC = () => <Masters masterName="category" />
export const CostCentres: FC = () => <Masters masterName="costCentre" />
export const EmploymentTypes: FC = () => <Masters masterName="employmentType" />
export const JobTitles: FC = () => <Masters masterName="job" />
export const WorkCalendars: FC = () => <Masters masterName="calendar" />
export const SalaryGrades: FC = () => <Masters masterName="salaryGrade" />
export const DocumentTypes: FC = () => <Masters masterName="docType" />
export const Races: FC = () => <Masters masterName="race" />
export const Religions: FC = () => <Masters masterName="religion" />
export const Qualifications: FC = () => <Masters masterName="qualification" />

export const Departments: FC = () => {
  useEffect(() => {
    dispatch(fetchDepartmentsExtra())
  }, [])
  return <Masters masterName="department" />
}

export const Divisions: FC = () => {
  useEffect(() => {
    dispatch(fetchDivisionsExtra())
  }, [])
  return <Masters masterName="division" />
}

export const Sections: FC = () => {
  useEffect(() => {
    dispatch(fetchSectionsExtra())
  }, [])
  return <Masters masterName="section" />
}

export const Teams: FC = () => {
  useEffect(() => {
    dispatch(fetchTeamsExtra())
  }, [])
  return <Masters masterName="team" />
}

export const LeaveGroups: FC = () => <Masters masterName="leaveGroup" sysPermissionId={Permission.lveGroup} />
