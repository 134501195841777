import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Col, EmpKeyValues, Row, SysOptions } from '~/components'
import { PayRunType } from '~/constants'
import { Form, Select } from '~/core-components'
import { useSysOptions } from '~/features/employee'
import { PayGroupState, selectMasterById, selectMasters, usePayGroupsDict } from '~/features/master'
import { usePayRuns, usePayRunsDict } from '~/features/payroll'
import { KeyValue } from '~/types/common'
import { Errors } from '~/types/store'

interface FormData {
  payRunId: string
  payGroupId: string
  payRunType: string
  payRunMonth: number
}

interface ParamsPayExportAllPayslipsProps {
  isAdhoc: boolean
  paramJsonb: string
  isEditing?: boolean
  onChange: (value: string) => void
  errors?: Errors
}

const EMPTY_FORM_DATA: FormData = {
  payRunId: '',
  payGroupId: '',
  payRunType: PayRunType.wholeMonth,
  payRunMonth: 0
}

const PAY_RUN_MONTH_OPTIONS = [
  {
    key: -1,
    value: 'Previous month'
  },
  {
    key: 0,
    value: 'Current month'
  }
]

export const ParamsPayExportAllPayslips: FC<ParamsPayExportAllPayslipsProps> = ({
  isAdhoc,
  paramJsonb,
  isEditing,
  onChange,
  errors
}) => {
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)
  const payGroups = useSelector(selectMasters)('payGroup') as (PayGroupState | undefined)[]
  const payGroup = useSelector(selectMasterById)('payGroup', formData.payGroupId) as PayGroupState | undefined
  const [payRuns] = usePayRuns('when-empty')
  const [payRunsDict] = usePayRunsDict('when-empty')
  const [payGroupsDict] = usePayGroupsDict('when-empty')
  const [payRunTypesDict] = useSysOptions('pay_run_type')

  useEffect(() => {
    const data = JSON.parse(paramJsonb || '{}') as FormData
    setFormData({
      payRunId: data.payRunId,
      payGroupId: data.payGroupId,
      payRunMonth: data.payRunMonth || 0,
      payRunType: data.payRunType || PayRunType.wholeMonth
    })
  }, [paramJsonb])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      setFormData(formData => {
        const updated = { ...formData, ...updates }
        const value = JSON.stringify({
          payRunId: updated.payRunId,
          payGroupId: updated.payGroupId,
          payRunMonth: updated.payRunMonth,
          payRunType: updated.payRunType || PayRunType.wholeMonth
        })

        typeof onChange === 'function' && onChange(value)
        return updated
      })
    },
    [onChange]
  )

  if (isAdhoc) {
    return (
      <div className="params-pay-export-all-payslips">
        <Row>
          <Col span={24}>
            <Form.Item label="Payroll runs" validateStatus={errors?.payRunId ? 'error' : ''} help={errors?.payRunId}>
              {isEditing ? (
                <Select
                  value={formData.payRunId}
                  optionFilterProp="title"
                  onChange={(value: string[]) => handleFormDataChange({ payRunId: value })}
                >
                  {payRuns.map(pr => (
                    <Select.Option key={pr.id} value={pr.id} title={pr.description}>
                      {pr.description}
                    </Select.Option>
                  ))}
                </Select>
              ) : (
                <>{payRunsDict[formData.payRunId]?.description || '-'}</>
              )}
            </Form.Item>
          </Col>
        </Row>
      </div>
    )
  }

  return (
    <div className="params-pay-export-all-payslips">
      <Row>
        <Col span={24}>
          <Form.Item label="Payroll group" validateStatus={errors?.payGroupId ? 'error' : ''} help={errors?.payGroupId}>
            {isEditing ? (
              <EmpKeyValues
                id="payGroup"
                value={formData.payGroupId}
                onChange={(payGroupId: string) => {
                  const payGroup = payGroups.find(pg => pg?.id === payGroupId)
                  const payRunType = payGroup?.isMidMonth ? PayRunType.firstHalf : PayRunType.wholeMonth
                  handleFormDataChange({ payGroupId, payRunType })
                }}
              />
            ) : (
              <>{payGroupsDict[formData.payGroupId]?.name || '-'}</>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row hidden={!payGroup?.isMidMonth}>
        <Col span={24}>
          <Form.Item label="Run type" validateStatus={errors?.payRunType ? 'error' : ''} help={errors?.payRunType}>
            {isEditing ? (
              <SysOptions
                type="pay_run_type"
                value={formData.payRunType}
                onFilter={(value: KeyValue | undefined) => {
                  if (payGroup?.isMidMonth) {
                    return [PayRunType.firstHalf, PayRunType.secondHalf].includes(value?.key || '')
                  } else {
                    return [PayRunType.wholeMonth].includes(value?.key || '')
                  }
                }}
                onChange={(value: string) => {
                  handleFormDataChange({ payRunType: value })
                }}
              />
            ) : (
              <>{payRunTypesDict[formData.payRunType]?.value || '-'}</>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item label="Month" validateStatus={errors?.payRunMonth ? 'error' : ''} help={errors?.payRunMonth}>
            {isEditing ? (
              <Select
                value={formData.payRunMonth}
                onChange={(value: number) => handleFormDataChange({ payRunMonth: value })}
              >
                {PAY_RUN_MONTH_OPTIONS.map(opt => (
                  <Select.Option key={opt.key} value={opt.key} display={opt.value}>
                    {opt.value}
                  </Select.Option>
                ))}
              </Select>
            ) : (
              <>{PAY_RUN_MONTH_OPTIONS.find(m => m.key === formData.payRunMonth)?.value || '-'}</>
            )}
          </Form.Item>
        </Col>
      </Row>
    </div>
  )
}
