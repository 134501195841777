import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { AttendancePeriodViewState } from '../types'

export const selectAttendancePeriodsView = createSelector(
  (state: StoreState) => state.attendance.attendancePeriodsView,
  attendancePeriodsState =>
    memoize((viewId: string): AttendancePeriodViewState => {
      return (attendancePeriodsState?.entities[viewId] || {}) as AttendancePeriodViewState
    })
)
