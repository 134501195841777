import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'

export const selectEmStatutoryExempts = createSelector(
  (state: StoreState) => state.employee.emStatutoryExempts,
  emStatutoryExemptsState =>
    memoize((employeeId?: string) => {
      if (!employeeId) return []

      return Object.values(emStatutoryExemptsState[employeeId]?.entities || {}).sort((a, b) => {
        const aStartDate = new Date(a!.startDate).getTime() || 0
        const bStartDate = new Date(b!.startDate).getTime() || 0
        const aEndDate = new Date(a!.endDate ?? '9999-12-31').getTime() || 0
        const bEndDate = new Date(b!.endDate ?? '9999-12-31').getTime() || 0

        if (aEndDate === 0 && bEndDate === 0) {
          return bStartDate - aStartDate
        } else {
          if (bEndDate === 0) {
            return 1
          } else if (aEndDate === 0) {
            return -1
          } else {
            return bEndDate - aEndDate
          }
        }
      })
    })
)
