import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { ClaimPeriodInfo, SSClaimBalanceState } from '../types'

export const selectMyClaimBalancePeriods = createSelector(
  (state: StoreState) => state.myClaim.myClaimBalances,
  myClaimBalanceState => {
    const periodCodes: string[] = []
    return Object.values(myClaimBalanceState?.entities || {})
      .reduce((periods: ClaimPeriodInfo[], current: SSClaimBalanceState | undefined) => {
        const periodCode = current?.periodCode || ''
        if (current && !periodCodes.includes(periodCode)) {
          periodCodes.push(periodCode)
          periods.push({ periodCode, startDate: current.periodStartDate, endDate: current.periodEndDate })
        }
        return periods
      }, [] as ClaimPeriodInfo[])
      .sort((a, b) => b.startDate.localeCompare(a.startDate))
  }
)
