import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { formatDateRange, formatNumberUnit, UnitType } from '~/utils'
import { LveRecordType } from '~/constants'
import { ETaskInfo } from '../../types'

export const selectMyLeaveTasks = createSelector(
  (state: StoreState) => state.my.myLeaveTasks,
  (state: StoreState) => state.leave.leaveTypes,
  (myLeaveTasksState, leaveTypesState) =>
    Object.values(myLeaveTasksState?.entities || {})
      .map(e => {
        const leaveType = leaveTypesState?.entities[e?.leaveTypeId || '']
        const leaveTypeName = leaveType?.name.toLowerCase()
        const dates = formatDateRange(e?.startDate, e?.endDate)
        const days = formatNumberUnit(e?.leaveTotal || 0, leaveType?.unit as UnitType)
        const cancellationLabel = e?.recordType === LveRecordType.cancellation ? ' cancellation' : ''
        return {
          id: e?.id,
          description: `Review ${e?.employeeName}'s ${leaveTypeName}${cancellationLabel} on ${dates} (${days})`,
          sequenceDate: e?.startDate
        } as ETaskInfo
      })
      .sort((a, b) => {
        const aSequenceDate = new Date(a!.sequenceDate).getTime()
        const bSequenceDate = new Date(b!.sequenceDate).getTime()
        return bSequenceDate - aSequenceDate
      })
)
