import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { SysDailyPolicyExpressionState } from '../types'
import { SuggestionType } from '~/components'

export const selectSysDailyPolicyExpressionsSuggestions = createSelector(
  (state: StoreState) => state.attendance.sysDailyPolicyExpressions,
  sysDailyPolicyExpressionsState => {
    return (Object.values(sysDailyPolicyExpressionsState?.entities || {}) as SysDailyPolicyExpressionState[])
      .sort((a, b) => a.sequence - b.sequence)
      .map(e => ({ value: e.columnExpression, meta: 'column' } as SuggestionType))
  }
)
