import { FC, useCallback, useState } from 'react'
import { DocumentTitle, EditableCard, EditableCardState } from '~/components'
import { PageHeader } from '~/core-components'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { FeatureFlagViewCriteria } from './FeatureFlagViewCriteria'
import { FeatureFlagGate, usePermissionGate } from '~/features/iam'
import { ISsAccessConfigCriteria } from '~/features/master'
import { ViewCriteria } from '~/features/selection'
import { FeatureFlag, Permission, PermissionAction } from '~/constants'
import { ActionResult } from '~/types/store'
import { dispatch } from '~/stores/store'
import { saveSsAccessConfigs } from '../../../actions'
import { useSsAccessConfigs } from '../../../hooks'
import './SsAccessConfigs.less'

interface SsAccessConfigsProps {}

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: '',
    breadcrumbName: 'Self-service access'
  }
]

const FEATURE_FLAGS = [
  {
    key: 'myprofile',
    title: 'My profile'
  },
  {
    key: 'mypayroll',
    title: 'My payroll'
  },
  {
    key: 'myleave',
    title: 'My leave'
  },
  {
    key: 'myclaim',
    title: 'My claim'
  },
  {
    key: 'myattendance',
    title: 'My attendance'
  },
  {
    key: 'myschedule',
    title: 'My schedule'
  }
]

type FormData = Record<string, Omit<ISsAccessConfigCriteria, 'featureFlag'>>

const EMPTY_FORM_DATA: FormData = Object.keys(FEATURE_FLAGS).reduce(_ => ({ viewId: '', criteria: [] }), {})

export const SsAccessConfigs: FC<SsAccessConfigsProps> = () => {
  const [data] = useSsAccessConfigs()
  const [cardState, setCardState] = useState<EditableCardState>()
  const canModify = usePermissionGate(Permission.ssAccessConfig, PermissionAction.Modify)
  const readOnly = cardState !== 'editing' && cardState !== 'saving'
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)

  const handleEdit = useCallback(() => {
    setCardState('editing')
  }, [])

  const handleSave = useCallback(async () => {
    if (data) {
      setCardState('saving')

      let result: ActionResult | undefined
      try {
        result = await dispatch(
          saveSsAccessConfigs(Object.entries(formData).map(x => ({ featureFlag: x[0], ...x[1] })))
        )
      } catch {
        setCardState('editing')
      }

      if (result?.errors) {
        setCardState('editing')
      }

      if (!result?.errors) {
        setCardState(undefined)
      }
    }
  }, [data, formData])

  const handleCancel = useCallback(() => {
    setCardState(undefined)

    if (data) {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data])

  const handleCriteriaChange = useCallback((featureFlag: string, viewId: string, criteria: ViewCriteria[]) => {
    setFormData(formData => {
      formData[featureFlag] = { ...formData[featureFlag], viewId, criteria }
      return formData
    })
  }, [])

  return (
    <div id="ss-access-configs" className="ss-access-configs">
      <DocumentTitle title="Self-Service Access" />
      <PageHeader title="Self-service access" containerId="ss-access-configs" breadcrumb={{ routes }} />
      <EditableCard
        bodyStyle={{ paddingBottom: !readOnly ? 6 : 24, paddingTop: 6 }}
        className="ss-access-configs__body"
        formId="form-ss-access-configs"
        state={canModify ? cardState : 'readonly'}
        onEdit={handleEdit}
        onSave={handleSave}
        onCancel={handleCancel}
      >
        {FEATURE_FLAGS.map(ff => (
          <FeatureFlagGate featureFlag={ff.key as FeatureFlag}>
            <FeatureFlagViewCriteria
              key={ff.key}
              featureFlag={ff.key}
              title={ff.title}
              readOnly={!canModify || readOnly}
              onChange={(viewId, criteria) => handleCriteriaChange(ff.key, viewId, criteria)}
            />
          </FeatureFlagGate>
        ))}
      </EditableCard>
    </div>
  )
}
