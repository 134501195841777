import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { ClaRecordStatus, ClaPaymentStatus } from '~/constants'
import { CheckboxValueType } from '~/core-components'
import { StoreState } from '~/types/store'
import { ClaimEntitlementRecordState } from '../types'

export const selectClaimEntitlementRecords = createSelector(
  (state: StoreState) => state.claim.claimEntitlementRecords,
  claimEntitlementRecords =>
    memoize(
      (
        employeeId: string,
        claimTypeId: string,
        paymentStatuses: CheckboxValueType[]
      ): ClaimEntitlementRecordState[] => {
        const emCtId = `${employeeId}||${claimTypeId}`

        return Object.values(claimEntitlementRecords[emCtId]?.entities || {}).filter(c => {
          let condition1 = false
          if (paymentStatuses.includes(ClaPaymentStatus.pendingApproval)) {
            condition1 = c?.recordStatus === ClaRecordStatus.Pending
          }

          let condition2 = false
          if (paymentStatuses.includes(ClaPaymentStatus.pendingPayment)) {
            condition2 = c?.recordStatus === ClaRecordStatus.PendingPayment
          }

          let condition3 = false
          if (paymentStatuses.includes(ClaPaymentStatus.paid)) {
            condition3 = c?.recordStatus === ClaRecordStatus.Approved || c?.recordStatus === ClaRecordStatus.Paid
          }

          if (paymentStatuses.length === 0) {
            return true
          }

          return condition1 || condition2 || condition3
        }) as ClaimEntitlementRecordState[]
      }
    )
)
