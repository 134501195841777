import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiApplyEmEducationRequest } from '../../api/ssemployee-request.api'
import { IEmEducationRequest } from '../../types'

export const applyEmEducationRequest = (education: IEmEducationRequest): ThunkResult<void> => {
  return async () => {
    const { status, result, errors, message, errorData } = await apiApplyEmEducationRequest(education)

    if (status) {
      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
