import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import moment from 'moment-timezone'
import { Col, Row, YearKeyValues, DocumentTitle } from '~/components'
import { Button, Card, ColumnsType, Form, Link, PageHeader, Table } from '~/core-components'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { MenuItem } from '~/types/common'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { fetchHolidayDates } from '../../actions'
import { selectHolidays, selectHolidayDates } from '../../selectors'
import { selectHolidayById } from '../../reducers'
import { HolidayDateState } from '../../types'
import { commonMenus } from '../../configs'
import { useMasterMenu } from '../MasterMenuContext'
import { MutateHolidayDateDrawer } from './components/MutateHolidayDateDrawer'
import { MutateHolidayDrawer } from './components/MutateHolidayDrawer'
import './Holiday.less'

type HolidayDateTable = HolidayDateState

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: SETTINGS_ROUTES.holidays,
    breadcrumbName: 'Overview'
  },
  {
    path: '',
    breadcrumbName: 'Holiday'
  }
]

interface DrawerState {
  visible: boolean
  data?: HolidayDateState
}
const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

interface HolidayDrawerState {
  visible: boolean
}
const DEFAULT_HOLIDAY_DRAWER_STATE: HolidayDrawerState = { visible: false }

interface HolidayProps {}

interface HolidayParams {
  id: string
}

const cardStyle: CSSProperties = { margin: 24 }
const cardBodyStyle: CSSProperties = { padding: 0 }

export const Holiday: FC<HolidayProps> = () => {
  const { id } = useParams<HolidayParams>()
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const [holidayDrawerState, setHolidayDrawerState] = useState<HolidayDrawerState>(DEFAULT_HOLIDAY_DRAWER_STATE)
  const [year, setYear] = useState<number>(new Date().getFullYear())
  const holidays = useSelector(selectHolidays)
  const holiday = useSelector((state: StoreState) => selectHolidayById(state, id))
  const holidayDates = useSelector(selectHolidayDates)(id, year)
  const loading = useSelector((state: StoreState) => state.master.mastersLoading['holiday'])
  const canModify = usePermissionGate(Permission.master, PermissionAction.Modify)
  const { setMenus } = useMasterMenu()

  useEffect(() => {
    if (id) dispatch(fetchHolidayDates(id))
  }, [id])

  const handleAddHoliday = useCallback(() => {
    setHolidayDrawerState({ visible: true })
  }, [])

  const handleCloseHolidayDrawer = useCallback(() => {
    setHolidayDrawerState(DEFAULT_HOLIDAY_DRAWER_STATE)
  }, [])

  useEffect(() => {
    const menus: MenuItem[] = holidays.map(h => ({
      path: `${SETTINGS_ROUTES.holidays}/${h!.id}`,
      value: h!.name,
      sysPermissionId: Permission.master
    }))

    if (canModify)
      menus.push({
        path: (
          <Button type="dashed" onClick={handleAddHoliday} block>
            Add holiday
          </Button>
        ),
        value: '',
        sysPermissionId: ''
      })

    setMenus(menus)
    return () => {
      setMenus(commonMenus)
    }
  }, [setMenus, holidays, canModify, handleAddHoliday])

  const handleAddHolidayDate = useCallback(() => {
    setDrawerState({ visible: true, data: { id: '', holidayId: id, name: '', date: '' } })
  }, [id])

  const handleEditHolidayDate = useCallback((holidayDate: HolidayDateState) => {
    setDrawerState({ visible: true, data: holidayDate })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const columns: ColumnsType<HolidayDateTable> = [
    {
      title: 'Date',
      key: 'date',
      dataIndex: 'date',
      render: (value: string) => moment(value).format('DD MMM YYYY (ddd)')
    },
    {
      title: 'Holiday',
      key: 'name',
      dataIndex: 'name'
    },
    {
      key: 'action',
      align: 'right',
      render: (value: string, record) =>
        canModify && (
          <Link size="small" onClick={() => handleEditHolidayDate(record)}>
            edit
          </Link>
        )
    }
  ]

  return (
    <div className="holiday">
      <DocumentTitle title={holiday?.name || 'Holiday'} />
      <PageHeader
        title={holiday?.name || 'Holiday'}
        breadcrumb={{ routes }}
        extra={
          canModify && (
            <Button key="add" onClick={handleAddHolidayDate}>
              Add holiday date
            </Button>
          )
        }
      />
      <Row className="holiday__select-year">
        <Col span={24}>
          <Form.Item label="Year" colon={false}>
            <YearKeyValues
              startYear={year}
              value={year}
              noOfYearBefore={-10}
              noOfYearAfter={5}
              allowClear={false}
              onChange={(value: number) => setYear(value)}
            />
          </Form.Item>
        </Col>
      </Row>
      <Card style={cardStyle} bodyStyle={cardBodyStyle}>
        <Table rowKey="date" dataSource={holidayDates} pagination={false} columns={columns} loading={loading} />
      </Card>
      {canModify && <MutateHolidayDateDrawer {...drawerState} onClose={handleCloseDrawer} />}
      {canModify && <MutateHolidayDrawer {...holidayDrawerState} onClose={handleCloseHolidayDrawer} />}
    </div>
  )
}
