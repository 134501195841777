import React, { FC, ReactNode } from 'react'
import './TabTitle.less'

interface TabTitleProps {
  left?: ReactNode
  title: string | ReactNode
}

export const TabTitle: FC<TabTitleProps> = ({ left, title }) => {
  return (
    <div className="tab-title">
      {left}
      {typeof title === 'string' ? <span className="tab-title__text">{title}</span> : title}
    </div>
  )
}
