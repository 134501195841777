import React, { forwardRef, useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Select, SelectProps } from '~/core-components'
import { fetchKeyValues } from '~/features/job/actions'
import { useFirstInView } from '~/hooks/use-first-in-view'
import { isInactive } from '~/utils/dateUtil'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { FetchStrategy, KeyValue } from '~/types/common'
import { InactiveTag } from '../InactiveTag/InactiveTag'
import { DropdownActions } from './DropdownActions'

interface JobKeyValuesProps extends SelectProps {
  id: string
  controller?: string
  queryParams?: any
  display?: 'value' | 'key' | 'keyvalue'
  renderItem?: (kv: KeyValue) => React.ReactNode
  sort?: 'value' | 'key'
  onFilter?: (value: KeyValue | undefined, index: number, array: (KeyValue | undefined)[]) => boolean
  fetchStrategy?: FetchStrategy
  allowAll?: boolean
}

const AllowAll: KeyValue = { key: '*', value: 'All' }

const JobKeyValuesInternal = (
  {
    id,
    controller,
    queryParams,
    display = 'value',
    renderItem,
    sort,
    onFilter,
    children,
    fetchStrategy = 'when-empty',
    allowAll = false,
    ...props
  }: JobKeyValuesProps,
  ref: React.Ref<any>
) => {
  const { ref: inViewRef, inView } = useFirstInView<HTMLDivElement>({ threshold: 0.25 })
  const data = useSelector((state: StoreState) => state.job.keyValues[id]?.entities || undefined)
  const loading = useSelector((state: StoreState) => state.job.keyValuesLoading[id])

  const options = useMemo(() => {
    if (!data) {
      return allowAll ? [AllowAll] : []
    }

    let result = (allowAll ? [...Object.values(data), AllowAll] : Object.values(data))
      .filter(a => !isInactive(a?.inactiveDate) || a?.key === props.value)
      .sort((a, b) => {
        const hasSequence = a && 'sequence' in a && a.sequence != null
        const aInactiveDate = new Date(a!.inactiveDate || '').getTime() || 0
        const bInactiveDate = new Date(b!.inactiveDate || '').getTime() || 0

        if (bInactiveDate === 0 && aInactiveDate === 0) {
          const sortField = sort || hasSequence ? 'sequence' : display
          if (sortField === 'key' || sortField === 'keyvalue') {
            return a!.key.localeCompare(b!.key)
          } else if (sortField === 'sequence') {
            return (a!.sequence || 0) - (b!.sequence || 0)
          }

          // Temporary sorting
          if (a!.value.startsWith('[Upcoming]') && !b!.value.startsWith('[Upcoming]')) {
            return 1
          } else if (!a!.value.startsWith('[Upcoming]') && b!.value.startsWith('[Upcoming]')) {
            return -1
          }

          return a!.value.localeCompare(b!.value)
        } else {
          return aInactiveDate - bInactiveDate
        }
      })

    if (typeof onFilter === 'function') {
      result = result.filter(onFilter)
    }
    return result
  }, [data, sort, display, allowAll, props.value, onFilter])

  useEffect(() => {
    if (inView) {
      dispatch(fetchKeyValues(id, controller, queryParams, { strategy: fetchStrategy }))
    }
  }, [inView, id, controller, queryParams, fetchStrategy])

  const handleRefresh = useCallback(() => {
    dispatch(fetchKeyValues(id, controller, queryParams))
  }, [id, controller, queryParams])

  return (
    <div ref={inViewRef}>
      <Select
        ref={ref}
        showSearch
        optionFilterProp="title"
        dropdownRender={menu => (
          <div>
            {menu}
            <DropdownActions onRefresh={handleRefresh} />
          </div>
        )}
        {...props}
        loading={loading}
      >
        {data &&
          options.map(kv => {
            let displayText = kv!.value
            if (display === 'key') displayText = kv!.key
            else if (display === 'keyvalue') displayText = `${kv!.key} - ${kv!.value}`

            return (
              <Select.Option key={kv!.key} value={kv!.key || ''} title={displayText}>
                {isInactive(kv!.inactiveDate) && <InactiveTag />}
                {renderItem == null ? (
                  <>
                    {display === 'value' && kv!.value}
                    {display === 'key' && kv!.key}
                    {display === 'keyvalue' && `${kv!.key} - ${kv!.value}`}
                  </>
                ) : (
                  kv && renderItem(kv)
                )}
              </Select.Option>
            )
          })}
      </Select>
    </div>
  )
}

export const JobKeyValues = forwardRef(JobKeyValuesInternal)
