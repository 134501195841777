import { showSuccess, showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { setCalendar } from '../reducers'
import { AttendancePeriodState, IAddAttendancePeriod } from '../types'
import { apiAddAttendancePeriod } from '../api/attendance-period.api'

export const addAttendancePeriod = (attendancePeriod: IAddAttendancePeriod): ThunkResult<void> => {
  return async (dispatch): Promise<ActionResult> => {
    const { status, result, errors, message, errorData } = await apiAddAttendancePeriod(attendancePeriod)
    if (status) {
      const newAttendancePeriod: AttendancePeriodState = { ...attendancePeriod, id: result.id }
      dispatch(setCalendar(newAttendancePeriod))
      showSuccess('Added')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
