import { showSuccess, showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import pick from 'lodash/pick'
import { ICalendarAndPattern, CalendarState } from '../types'
import { refetchMasters, setMaster } from '../reducers'
import { apiAddCalendarAndPatterns } from '../api/calendar.api'

export const addCalendarWithPatterns = (request: ICalendarAndPattern): ThunkResult<void> => {
  return async (dispatch): Promise<ActionResult> => {
    const { status, result, errors, message, errorData } = await apiAddCalendarAndPatterns(request)
    if (status) {
      const newCalendarUpdates = pick(request, ['name', 'inactiveDate'])
      const newCalendar: CalendarState = {
        id: result.id || request.id,
        ...newCalendarUpdates,
        wdPerWeek: result.wdPerWeek
      }

      // The "calendar" update
      dispatch(setMaster({ masterName: 'calendar', data: newCalendar }))
      dispatch(refetchMasters({ masterName: 'calendar' }))
      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
