import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import confirm from 'antd/lib/modal/confirm'
import { LoadingOutlined } from '@ant-design/icons'
import { Alert, Form, Spin } from '~/core-components'
import { DrawerForm, ErrorDisplay } from '~/components'
import { fetchFormulas } from '~/features/formula'
import { selectPayItemById } from '~/features/master'
import { EmPublicPerson } from '~/features/employee'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors, StoreState } from '~/types/store'
import { selectPayRecurringOverride } from '../../selectors'
import { IPayRecurringOverrideInfo, PayTranSgState } from '../../types'
import { deletePayRecurringOverride, fetchPayRecurringOverride, updatePayRecurringOverride } from '../../actions'
import { EditPayRecurringForm, PayRecurringForm, EMPTY_PAY_RECURRING_FORM_DATA } from './EditPayRecurringForm'
import './EditPayRecurringDrawer.less'

export interface EditPayRecurringDrawerProps {
  visible: boolean
  id: string
  payTran?: PayTranSgState
  onClose: (action: 'save' | 'cancel') => void
}

export const EditPayRecurringDrawer: FC<EditPayRecurringDrawerProps> = ({ visible, id, payTran, onClose }) => {
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<Errors>()
  const [resetForm, setResetForm] = useState(0)
  const [recurringFormData, setRecurringFormData] = useState<PayRecurringForm>(EMPTY_PAY_RECURRING_FORM_DATA)

  const payRunId = payTran?.payRunId || ''
  const employeeId = payTran?.employeeId || ''
  const payItemId = payTran?.payItemId || ''
  const batchId = payTran?.batchId || ''

  const payItem = useSelector((state: StoreState) => selectPayItemById(state, payItemId))
  const payRecurringOverride = useSelector(selectPayRecurringOverride)(payRunId, employeeId, payItemId)
  const payRecurringOverrideLoading = useSelector((state: StoreState) => state.payroll.payRecurringOverridesLoading)

  useEffect(() => {
    dispatch(fetchFormulas({ strategy: 'when-empty' }))
  }, [])

  useEffect(() => {
    setErrors(undefined)
  }, [visible])

  useEffect(() => {
    dispatch(fetchPayRecurringOverride(payRunId, employeeId, payItemId, batchId))
  }, [payRunId, employeeId, payItemId, batchId])

  useEffect(() => {
    if (payRecurringOverride) {
      setRecurringFormData({
        payItemId: payRecurringOverride.payItemId,
        quantity: payRecurringOverride.quantity,
        rate: payRecurringOverride.rate
      })
    }
  }, [payRecurringOverride])

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      const request = mapPayRecurringFormToPayRecurringOverrideInfoRequest(recurringFormData)
      result = await dispatch(updatePayRecurringOverride(payRecurringOverride?.id || '', batchId, request))
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose('save')
      setRecurringFormData(EMPTY_PAY_RECURRING_FORM_DATA)
      setResetForm(resetForm + 1)
    }
  }, [recurringFormData, payRecurringOverride, batchId, onClose, resetForm])

  const handleDelete = useCallback(() => {
    if (!payTran) return

    confirm({
      title: 'Delete',
      content: 'Do you want to delete?',
      onOk: async () => {
        const result: ActionResult | undefined = await dispatch(
          deletePayRecurringOverride(payRunId, employeeId, payItemId, batchId)
        )

        if (result?.errors) {
          setErrors(result.errors)
        }

        if (!result?.errors) {
          typeof onClose === 'function' && onClose('save')
        }
      },
      okText: 'Delete',
      okType: 'danger'
    })
  }, [payTran, payRunId, employeeId, payItemId, batchId, onClose])

  const handleCloseDrawer = useCallback(() => {
    typeof onClose === 'function' && onClose('cancel')
  }, [onClose])

  return (
    <DrawerForm
      open={visible}
      title="Edit payroll item"
      onClose={handleCloseDrawer}
      confirmLoading={loading}
      width={500}
      showDelete={true}
      onDelete={handleDelete}
      className="edit-payrecurring-drawer"
      formId={`form-edit-payrecurring-${id}`}
    >
      <Form id={`form-edit-payrecurring-${id}`} onFinish={handleOk}>
        <Form.Item label="">
          <EmPublicPerson id={employeeId} size={32} />
        </Form.Item>
        <Form.Item label="Payroll item">
          <div>{payItem?.name || ''}</div>
        </Form.Item>
        <ErrorDisplay keys={['payRunId']} errors={errors} />
        {payRecurringOverrideLoading ? (
          <Spin indicator={<LoadingOutlined spin />} />
        ) : (
          <EditPayRecurringForm
            data={recurringFormData}
            errors={errors}
            resetForm={resetForm}
            onFormChange={data => setRecurringFormData({ ...recurringFormData, ...data })}
          />
        )}
      </Form>
      <Alert message="Notes: This change will not affect future recurrence." type="warning" />
    </DrawerForm>
  )
}

const mapPayRecurringFormToPayRecurringOverrideInfoRequest = (form: PayRecurringForm): IPayRecurringOverrideInfo => ({
  payItemId: form.payItemId,
  quantity: form.quantity,
  rate: form.rate
})
