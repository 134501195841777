import { replaceCtExpenseTypes, setCtExpenseTypesLoading } from '../reducers'
import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiUpdateCtExpenseTypes } from '../api/ct-expense-type.api'
import { CtExpenseTypeState } from '../types'

export const updateCtExpenseTypes = (claimTypeId: string, data: CtExpenseTypeState[]): ThunkResult<void> => {
  return async dispatch => {
    if (!claimTypeId) return

    try {
      dispatch(setCtExpenseTypesLoading({ id: claimTypeId, loading: true }))
      const { status, result, errors, message, errorData } = await apiUpdateCtExpenseTypes(claimTypeId, data)

      if (status) {
        dispatch(replaceCtExpenseTypes({ id: claimTypeId, data: result }))
        showSuccess('Saved')
      } else {
        if (Object.keys(errors).length === 0) {
          showError(message, errorData)
        }
      }
      return { errors }
    } finally {
      dispatch(setCtExpenseTypesLoading({ id: claimTypeId, loading: false }))
    }
  }
}
