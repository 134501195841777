import React, { FC, useCallback, useEffect, useState } from 'react'
import { Col, InfoTooltip, Row, SysOptions } from '~/components'
import { LveExcessAction } from '~/constants'
import { Form, Input, Radio, RadioChangeEvent, Space, Switch } from '~/core-components'
import { KeyValue } from '~/types/common'
import { Errors } from '~/types/store'
import { LpCfInfoState } from '../../../types'
import { CF_EMPTY_FORM_DATA } from './MutateLtPolicyDrawer'

interface LpCfProps {
  errors?: Errors
  cf: LpCfInfoState
  onChange: (cfData: Partial<LpCfInfoState>) => void
}

export const LpCf: FC<LpCfProps> = ({ errors, cf, onChange }) => {
  const cfCode = cf.cfCode
  const cfValidityPeriod = cf.cfValidityPeriod
  const [isCf, setIsCf] = useState(!!cfCode)
  const [hasExpiry, setHasExpiry] = useState(cfValidityPeriod > 1)

  useEffect(() => {
    setIsCf(!!cfCode)
  }, [cfCode])

  useEffect(() => {
    setHasExpiry(cfValidityPeriod > 1)
  }, [cfValidityPeriod])

  const handleEnableChange = useCallback(
    (checked: boolean) => {
      setIsCf(checked)
      if (!checked) {
        typeof onChange === 'function' && onChange(CF_EMPTY_FORM_DATA)
      }
    },
    [onChange]
  )

  return (
    <div className="lp-cf">
      <Row>
        <Col span={24}>
          <div className="entitlement__title">Carry forward</div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item>
            <label>Employees are allowed to carry forward unused leave to the next period</label>
            <Switch checked={isCf} onChange={handleEnableChange} />
          </Form.Item>
        </Col>
      </Row>
      <Row hidden={!isCf}>
        <Col span={12}>
          <Form.Item
            label="Carry forward leave up to"
            validateStatus={errors?.cfCode ? 'error' : ''}
            help={errors?.cfCode}
          >
            <SysOptions type="lve_cf" value={cf.cfCode} onChange={(cfCode: string) => onChange({ cfCode })} />
          </Form.Item>
        </Col>
      </Row>
      <Row hidden={!isCf}>
        <Col span={24}>
          <Form.Item
            label="Carried forward leave to be used within"
            validateStatus={errors?.cfValidityPeriod ? 'error' : ''}
            help={errors?.cfValidityPeriod}
          >
            <Radio.Group
              value={hasExpiry}
              onChange={(event: RadioChangeEvent) => {
                const value = event.target.value
                setHasExpiry(value)

                const changes: Partial<LpCfInfoState> = { cfValidityPeriod: value ? 1 : 0 }
                if (value) {
                  changes.excessAction = LveExcessAction.forfeited
                  changes.excessValidityPeriod = 0
                }

                onChange(changes)
              }}
            >
              <Radio value={true}>
                <Space>
                  <Input.Number
                    value={cf.cfValidityPeriod}
                    disabled={!hasExpiry}
                    min={1}
                    max={12}
                    onChange={(value: number | null) => onChange({ cfValidityPeriod: value || 1 })}
                  />
                  month(s)
                </Space>
              </Radio>
              <Radio value={false}>No expiry</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row hidden={!isCf}>
        <Col span={24}>
          <Form.Item
            label="Any excess balance will be"
            validateStatus={errors?.excessAction || errors?.excessValidityPeriod ? 'error' : ''}
            help={errors?.excessAction || errors?.excessValidityPeriod}
          >
            <Row gutter={15}>
              <Col span={12}>
                <SysOptions
                  type="lve_cf_excess"
                  value={cf.excessAction}
                  onChange={(value: string) => onChange({ excessAction: value })}
                  onFilter={(value: KeyValue | undefined) => {
                    if (hasExpiry) {
                      return value?.key !== LveExcessAction.extended
                    }
                    return true
                  }}
                />
              </Col>
            </Row>
          </Form.Item>
        </Col>
        <Row hidden={cf.excessAction !== LveExcessAction.extended}>
          <Col span={24}>
            <Form.Item
              label={
                <>
                  To be used within
                  <InfoTooltip title="Leave will be forfeited by default if not taken within the given duration." />
                </>
              }
              validateStatus={errors?.excessValidityPeriod ? 'error' : ''}
              help={errors?.excessVaidityPeriod}
            >
              <Space>
                <Input.Number
                  value={cf.excessValidityPeriod}
                  min={1}
                  max={12}
                  onChange={(value: number | null) => onChange({ excessValidityPeriod: value || 1 })}
                />
                month(s)
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Row>
    </div>
  )
}
