import { showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiUpdateAttendancePeriod } from '../api/attendance-period.api'
import { AttendancePeriodState, IUpdateAttendancePeriod } from '../types'
import { setAttendancePeriod } from '../reducers'

export const updateAttendancePeriod = (id: string, data: IUpdateAttendancePeriod): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const { status, errors, message, errorData } = await apiUpdateAttendancePeriod(id, data)
    if (status) {
      const attendancePeriod = getState().attendance.attendancePeriods?.entities[id]
      const updated: AttendancePeriodState = {
        ...(attendancePeriod as AttendancePeriodState),
        ...data
      }
      dispatch(setAttendancePeriod(updated))
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
