import { showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { IAfterLogin } from '../types'
import { apiAfterLogin } from '../api/login.api'
import { setLogin } from '../reducers'

export const afterLogin = (request: IAfterLogin): ThunkResult<void> => {
  return async (dispatch, getState): Promise<ActionResult> => {
    const { status, result, errors, message, errorData } = await apiAfterLogin(request.id, request.tenantCode)
    if (status) {
      const login = getState().iam.logins.entities[request.id]
      if (login) {
        const { lastLoginDate, isPasswordExpiring, forceChangePassword } = result
        dispatch(setLogin({ ...login, lastLoginDate, isPasswordExpiring, forceChangePassword }))
      }
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
