import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ButtonTag, Form, Space, Tooltip } from '~/core-components'
import { DrawerForm } from '~/components'
import { IInvite, sendInvite, useMyLogin, useIsEmployee, usePermissionGate } from '~/features/iam'
import { ActionResult, Errors, StoreState } from '~/types/store'
import { dispatch } from '~/stores/store'
import { Permission, PermissionAction } from '~/constants'

interface FormData {
  employeeNo: string
  email: string
}

interface ActivateEmployeeTagProps {
  employeeId: string
}

const EMPTY_FORM_DATA: FormData = {
  employeeNo: '',
  email: ''
}

export const ActivateEmployeeTag: FC<ActivateEmployeeTagProps> = ({ employeeId }) => {
  const [sending, setSending] = useState(false)
  const [visible, setVisible] = useState(false)
  const [errors, setErrors] = useState<Errors>()
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)
  const [myLogin] = useMyLogin()
  const employee = useSelector((state: StoreState) => state.employee.employees.entities[employeeId])
  const employeeName = employee?.fullName || ''
  const loginCode = employee?.email || employee?.employeeNo || ''
  const isEmailEmpty = !employee?.email
  const [member, loading] = useIsEmployee(isEmailEmpty ? 'employee_no' : 'email', loginCode, 'always')
  const isMember = member?.isEmployee || false
  const isInvited = member?.isInvited || false
  const isActive = employee?.emStatus === 'active' || employee?.emStatus === 'onboarding' || false
  const canActivate = usePermissionGate(Permission.employee, PermissionAction.ActivateAccount)

  useEffect(() => {
    if (visible) {
      setFormData({
        ...EMPTY_FORM_DATA,
        employeeNo: loginCode,
        email: isEmailEmpty ? myLogin.email || '' : loginCode
      })
    }
  }, [loginCode, isEmailEmpty, myLogin, visible])

  const handleTag = useCallback(() => {
    setVisible(true)
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setVisible(false)
  }, [])

  const handleActivate = useCallback(async () => {
    let result: ActionResult | undefined
    if (formData.employeeNo) {
      try {
        setSending(true)

        const invite: IInvite = {
          employeeId,
          email: formData.email,
          employeeNo: formData.employeeNo,
          name: employeeName,
          inviteType: 'employee'
        }
        result = await dispatch(sendInvite(invite))
      } finally {
        setSending(false)
      }
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      setVisible(false)
      setFormData(EMPTY_FORM_DATA)
    }
  }, [employeeName, employeeId, formData.email, formData.employeeNo])

  if (member == null || isMember === true || loading || !isActive || !canActivate) return null

  return (
    <>
      <Tooltip title="Activate employee portal account">
        <ButtonTag onClick={handleTag}>{isInvited ? 're-activate account' : 'activate account'}</ButtonTag>
      </Tooltip>
      <DrawerForm
        title={isInvited ? 'Re-activate account' : 'Activate account'}
        onClose={handleCloseDrawer}
        okText={isInvited ? 'Re-send' : 'Send'}
        confirmLoading={sending}
        width={400}
        open={visible}
        formId="form-activate-employee"
      >
        <Form id="form-activate-employee" onFinish={handleActivate}>
          {isEmailEmpty ? (
            <Space direction="vertical">
              <div>This employee does not have a registered work email.</div>
              <div>Employee login details will be sent to the following email:</div>
              <div>
                <b>{formData.email}</b>
              </div>
            </Space>
          ) : (
            <Form.Item
              label={isInvited ? 'Re-sending invitation to' : 'Sending invitation to'}
              validateStatus={errors?.email ? 'error' : ''}
              help={errors?.email}
            >
              <b>{formData.email}</b>
            </Form.Item>
          )}
        </Form>
      </DrawerForm>
    </>
  )
}
