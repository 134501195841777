import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { LoginPermissionState } from '../types'

export const selectLoginPermissions = createSelector(
  (state: StoreState) => state.iam.loginPermissions,
  loginPermissionsState =>
    memoize((loginId: string): LoginPermissionState[] => {
      return Object.values(loginPermissionsState[loginId]?.entities || {}) as LoginPermissionState[]
    })
)
