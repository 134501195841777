import React, { FC, useCallback, useEffect, ChangeEvent, HTMLAttributes, useState, useMemo } from 'react'
import moment from 'moment-timezone'
import confirm from 'antd/lib/modal/confirm'
import { useSelector } from 'react-redux'
import { Form, Input, Radio, Space, Skeleton, SecondaryText } from '~/core-components'
import { Row, Col, SalaryInput, InfoTooltip, ErrorDisplay } from '~/components'
import { dispatch } from '~/stores/store'
import { useFocus } from '~/hooks/use-focus'
import { StoreState, Errors, ActionResult } from '~/types/store'
import { formatMoney } from '~/utils'
import { A8aFormState } from '../../../types'
import { addA8aDraft, deleteA8aDraft, fetchA8aForm, updateA8aDraft } from '../../../actions'
import { setA8aFormRefetch, removeA8aForm, setIr8aFormRefetch } from '../../../reducers'
import { selectA8aForm } from '../../../selectors'
import { YtdEmptyForm } from '../YtdEmptyForm/YtdEmptyForm'
import './A8aForm.less'

interface A8aFormProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
  ytdYear: string
  employerTaxNo: string
  employeeTaxNo: string
  ytdSubmissionId: string
  readOnly?: boolean
  onFormChange: (hasChange: boolean, hasError: boolean) => void

  // Trigger action from parent component
  triggerUpdate: number
  triggerDelete: number
  triggerDiscard: number
}

const EMPTY_FORM_DATA: A8aFormState = {
  id: '',
  ytdSubmissionId: '',
  employeeTaxNo: '',
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  occupationStartDate: '',
  occupationEndDate: '',
  occupationDays: 0,
  noOfEmSharing: 1,
  premisesAmt: 0.0,
  furnitureAmt: 0.0,
  premiseType: '',
  rentAmt: 0.0,
  rentEmAmt: 0.0,
  utilitiesAmt: 0.0,
  driverAmt: 0.0,
  servantAmt: 0.0,
  hotelAmt: 0.0,
  hotelEmAmt: 0.0,
  leavePassageAmt: 0.0,
  interestAmt: 0.0,
  insuranceAmt: 0.0,
  holidayAmt: 0.0,
  educationAmt: 0.0,
  otherAmt: 0.0,
  clubAmt: 0.0,
  gainAmt: 0.0,
  vehicleAmt: 0.0,
  carAmt: 0.0,
  bikTotal: 0.0,
  leavePassageAdj: 0.0,
  interestAdj: 0.0,
  insuranceAdj: 0.0,
  holidayAdj: 0.0,
  educationAdj: 0.0,
  otherAdj: 0.0,
  clubAdj: 0.0,
  gainAdj: 0.0,
  vehicleAdj: 0.0,
  carAdj: 0.0,
  leavePassageTotal: 0.0,
  interestTotal: 0.0,
  insuranceTotal: 0.0,
  holidayTotal: 0.0,
  educationTotal: 0.0,
  otherTotal: 0.0,
  clubTotal: 0.0,
  gainTotal: 0.0,
  vehicleTotal: 0.0,
  carTotal: 0.0,

  // calculation field
  placeOfResidenceTaxableValue: 0.0,
  placeOfResidenceTaxableTotal: 0.0,
  utilitiesTaxableValue: 0.0,
  hotelAccomodationTaxableValue: 0.0
}

export const A8aForm: FC<A8aFormProps> = ({
  ytdYear,
  employerTaxNo,
  employeeTaxNo,
  ytdSubmissionId,
  readOnly,
  onFormChange,
  triggerUpdate,
  triggerDelete,
  triggerDiscard,
  ...divProps
}) => {
  const [focusRef, setFocus] = useFocus(true)
  const emptyFormData: A8aFormState = useMemo(
    () => ({ ...EMPTY_FORM_DATA, ytdSubmissionId, employeeTaxNo }),
    [ytdSubmissionId, employeeTaxNo]
  )
  const [formData, setFormData] = useState<A8aFormState>(emptyFormData)
  const [errors, setErrors] = useState<Errors>()
  const a8aForm = useSelector(selectA8aForm)(ytdSubmissionId, employeeTaxNo)
  const hasData = !!a8aForm
  const loading = useSelector((state: StoreState) => state.tax.a8aFormLoading || false)
  const refetch = useSelector((state: StoreState) => state.tax.a8aFormRefetch)

  useEffect(() => {
    setTimeout(() => setFocus(), 100)
  }, [setFocus])

  useEffect(() => {
    dispatch(fetchA8aForm(ytdYear, employerTaxNo, employeeTaxNo, ytdSubmissionId))
  }, [refetch, ytdYear, employerTaxNo, employeeTaxNo, ytdSubmissionId])

  useEffect(() => {
    if (a8aForm) {
      setFormData(a8aForm)
    } else {
      setFormData(emptyFormData)
    }
  }, [a8aForm, emptyFormData])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      setFormData(formData => {
        const updated = { ...formData, ...updates }

        if ('leavePassageTotal' in updates)
          updated.leavePassageAdj = updated.leavePassageTotal - updated.leavePassageAmt
        if ('interestTotal' in updates) updated.interestAdj = updated.interestTotal - updated.interestAmt
        if ('insuranceTotal' in updates) updated.insuranceAdj = updated.insuranceTotal - updated.insuranceAmt
        if ('holidayTotal' in updates) updated.holidayAdj = updated.holidayTotal - updated.holidayAmt
        if ('educationTotal' in updates) updated.educationAdj = updated.educationTotal - updated.educationAmt
        if ('clubTotal' in updates) updated.clubAdj = updated.clubTotal - updated.clubAmt
        if ('gainTotal' in updates) updated.gainAdj = updated.gainTotal - updated.gainAmt
        if ('vehicleTotal' in updates) updated.vehicleAdj = updated.vehicleTotal - updated.vehicleAmt
        if ('carTotal' in updates) updated.carAdj = updated.carTotal - updated.carAmt
        if ('otherTotal' in updates) updated.otherAdj = updated.otherTotal - updated.otherAmt

        updated.furnitureAmt =
          updated.premiseType === 'p'
            ? 0.4 * updated.premisesAmt
            : updated.premiseType === 'f'
            ? 0.5 * updated.premisesAmt
            : 0.0

        if (formData.premisesAmt > 0 && updated.rentAmt > 0.0) {
          updated.premisesAmt = 0.0
          updated.furnitureAmt = 0.0
        }

        if (updated.premisesAmt === 0) {
          updated.premiseType = ''
        }

        if (formData.rentAmt > 0 && updated.premisesAmt > 0.0) {
          updated.rentAmt = 0.0
        }

        updated.placeOfResidenceTaxableValue = updated.premisesAmt + updated.furnitureAmt + updated.rentAmt
        updated.placeOfResidenceTaxableTotal = updated.placeOfResidenceTaxableValue - updated.rentEmAmt
        updated.utilitiesTaxableValue = updated.utilitiesAmt + updated.driverAmt + updated.servantAmt
        updated.hotelAccomodationTaxableValue = updated.hotelAmt - updated.hotelEmAmt

        updated.bikTotal =
          updated.placeOfResidenceTaxableTotal +
          updated.utilitiesTaxableValue +
          updated.hotelAccomodationTaxableValue +
          updated.leavePassageTotal +
          updated.interestTotal +
          updated.insuranceTotal +
          updated.holidayTotal +
          updated.educationTotal +
          updated.clubTotal +
          updated.gainTotal +
          updated.vehicleTotal +
          updated.carTotal +
          updated.otherTotal

        return updated
      })

      onFormChange(true, false)
    },
    [onFormChange]
  )

  const handleCreateForm = useCallback(async () => {
    const result: ActionResult | undefined = await dispatch(
      addA8aDraft({ ytdSubmissionId, employeeTaxNos: [formData.employeeTaxNo] })
    )

    if (!result?.errors) {
      dispatch(setIr8aFormRefetch())
      dispatch(setA8aFormRefetch())
    }
  }, [ytdSubmissionId, formData.employeeTaxNo])

  const handleUpdateForm = useCallback(async () => {
    setErrors(undefined)

    const result = await dispatch(updateA8aDraft(formData))

    if (result?.errors) {
      setErrors(result.errors)
      onFormChange(true, true)
    } else {
      onFormChange(false, false)
    }
  }, [formData, onFormChange])

  const handleDeleteForm = useCallback(async () => {
    confirm({
      title: 'Delete',
      content: 'Do you want to delete this Appendix 8A?',
      onOk: async () => {
        const result: ActionResult | undefined = await dispatch(deleteA8aDraft(formData.id))

        if (!result?.errors) {
          dispatch(removeA8aForm(formData.employeeTaxNo))
        }
      },
      width: 550,
      okText: 'Delete',
      okType: 'danger'
    })
  }, [formData])

  const handleDiscardForm = useCallback(() => {
    if (a8aForm) {
      setFormData(a8aForm)
    }
    setErrors(undefined)
  }, [a8aForm])

  useEffect(() => {
    if (triggerUpdate > 0) handleUpdateForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerUpdate])

  useEffect(() => {
    if (triggerDelete > 0) handleDeleteForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerDelete])

  useEffect(() => {
    if (triggerDiscard > 0) handleDiscardForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerDiscard])

  if (loading)
    return (
      <div className="a8a">
        <div className="a8a-form">
          <Skeleton active />
        </div>
      </div>
    )

  if (!hasData)
    return (
      <YtdEmptyForm onCreate={handleCreateForm}>
        <SecondaryText>There is no Appendix 8A for the selected employee.</SecondaryText>
      </YtdEmptyForm>
    )

  return (
    <div {...divProps} className="a8a">
      <div className="a8a-form">
        <ErrorDisplay errors={errors} />
        <Form>
          <div className="a8a-form__title mt0">1. Place of Residence provided by Employer</div>
          <div className="a8a-form__field">
            <div className="a8a-form__field-name">
              <div>
                <Row gutter={30}>
                  <Col span={16}>
                    <Form.Item
                      label="Address"
                      validateStatus={errors?.addressLine1 ? 'error' : ''}
                      help={errors?.addressLine1}
                    >
                      <Input
                        ref={focusRef}
                        className="a8a-form__input w520"
                        readOnly={readOnly}
                        value={formData.addressLine1}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                          handleFormDataChange({ addressLine1: event.target.value })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={30}>
                  <Col span={16}>
                    <Form.Item validateStatus={errors?.addressLine2 ? 'error' : ''} help={errors?.addressLine2}>
                      <Input
                        className="a8a-form__input w520"
                        readOnly={readOnly}
                        value={formData.addressLine2}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                          handleFormDataChange({ addressLine2: event.target.value })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={30}>
                  <Col span={16}>
                    <Form.Item validateStatus={errors?.addressLine3 ? 'error' : ''} help={errors?.addressLine3}>
                      <Input
                        className="a8a-form__input w520"
                        readOnly={readOnly}
                        value={formData.addressLine3}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                          handleFormDataChange({ addressLine3: event.target.value })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="a8a-form__field-content">
              <Row gutter={30}>
                <Col>
                  <Space>
                    <Form.Item
                      label="Period of occupation"
                      validateStatus={errors?.occupationStartDate ? 'error' : ''}
                      help={errors?.occupationStartDate}
                    >
                      <Input.Date
                        className="a8a-form__input"
                        inputReadOnly={readOnly}
                        value={formData.occupationStartDate ? moment(formData.occupationStartDate) : undefined}
                        onChange={(value: moment.Moment | null) =>
                          handleFormDataChange({
                            occupationStartDate: value?.format('YYYY-MM-DD'),
                            occupationDays:
                              formData.occupationEndDate && value
                                ? moment(formData.occupationEndDate).diff(moment(value), 'days') + 1
                                : 0
                          })
                        }
                      />
                    </Form.Item>
                    <span>to</span>
                    <Form.Item
                      label=" "
                      validateStatus={errors?.occupationEndDate ? 'error' : ''}
                      help={errors?.occupationEndDate}
                    >
                      <Input.Date
                        className="a8a-form__input"
                        inputReadOnly={readOnly}
                        value={formData.occupationEndDate ? moment(formData.occupationEndDate) : undefined}
                        onChange={(value: moment.Moment | null) =>
                          handleFormDataChange({
                            occupationEndDate: value?.format('YYYY-MM-DD'),
                            occupationDays:
                              formData.occupationStartDate && value
                                ? moment(value).diff(moment(formData.occupationStartDate), 'days') + 1
                                : 0
                          })
                        }
                      />
                    </Form.Item>
                  </Space>
                </Col>
              </Row>
              <Row gutter={30}>
                <Col>
                  <Form.Item
                    label="No. of days"
                    validateStatus={errors?.occupationDays ? 'error' : ''}
                    help={errors?.occupationDays}
                  >
                    <Input.Number
                      className="w150"
                      readOnly={readOnly}
                      min={0}
                      value={formData.occupationDays}
                      onChange={(occupationDays: number | null) => handleFormDataChange({ occupationDays })}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={30}>
                <Col>
                  <Form.Item
                    label="No. of employees sharing (exclude family members who are not employees)"
                    validateStatus={errors?.noOfEmSharing ? 'error' : ''}
                    help={errors?.noOfEmSharing}
                  >
                    <Input.Number
                      className="w150"
                      readOnly={readOnly}
                      min={1}
                      value={formData.noOfEmSharing}
                      onChange={(noOfEmSharing: number | null) => handleFormDataChange({ noOfEmSharing })}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </div>
          <div className="a8a-form__title">2. Accommodation and related benefits provided by Employer</div>
          <div className="a8a-form__field">
            <div className="a8a-form__field-name">
              <div className="a8a-form__field-row">
                <div className="a8a-form__field-content">
                  a. &nbsp;&nbsp;Annual Value (AV) of Premises for the period provided (state apportioned amount, if
                  applicable)
                </div>
              </div>
              <div className="a8a-form__field-row pt0 mt-5">
                <div className="a8a-form__field-content">
                  <div className={`ml23 ${!errors?.premiseType ? 'h30' : ''}`}>
                    <Form.Item validateStatus={errors?.premiseType ? 'error' : ''} help={errors?.premiseType}>
                      Premises type :&nbsp;&nbsp;&nbsp;
                      <Radio.Group
                        readOnly={readOnly}
                        value={formData.premiseType}
                        onChange={e => handleFormDataChange({ premiseType: e.target.value })}
                      >
                        <Space direction="horizontal">
                          <Radio value="p">Partially furnished</Radio>
                          <Radio value="f">Fully furnished</Radio>
                        </Space>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
            <div className="a8a-form__field-action">
              <SalaryInput
                size="small"
                readOnly={readOnly}
                value={formData.premisesAmt}
                onChange={(premisesAmt: number | null) => handleFormDataChange({ premisesAmt })}
              />
            </div>
          </div>
          <div className="a8a-form__field">
            <div className="a8a-form__field-name">
              <div className="a8a-form__field-row">
                <div className="a8a-form__field-content maxW180">b. &nbsp;&nbsp;Value of Furniture & Fitting</div>
                <div className="a8a-form__field-content Tooltip2b">
                  <InfoTooltip
                    title={`Mandatory if 2a is completed:
                            (i) 40% of AV if premises is partially furnished or
                            (ii) 50% of AV if premises is fully furnished`}
                  />
                </div>
              </div>
            </div>
            <div className="a8a-form__field-action autoFill">{formatMoney(formData.furnitureAmt, 2)}</div>
          </div>
          <div className="a8a-form__field">
            <div className="a8a-form__field-name">
              <div className="a8a-form__field-row">
                <div className="a8a-form__field-content">
                  c. &nbsp;&nbsp;Rent paid by the employer including rental of Furniture & Fittings (state apportioned
                  amount, if applicable)
                </div>
              </div>
            </div>
            <div className="a8a-form__field-action">
              <SalaryInput
                size="small"
                readOnly={readOnly}
                value={formData.rentAmt}
                onChange={(value: number | null) => handleFormDataChange({ rentAmt: value })}
              />
            </div>
          </div>
          <div className="a8a-form__field boldText">
            <div className="a8a-form__field-name">
              <div className="a8a-form__field-row">
                <div className="a8a-form__field-content">
                  d. &nbsp;&nbsp;Taxable Value of Place of Residence (2a + 2b) or 2c
                </div>
              </div>
            </div>
            <div className="a8a-form__field-action autoFill">
              {formatMoney(formData.placeOfResidenceTaxableValue, 2)}
            </div>
          </div>
          <div className="a8a-form__field">
            <div className="a8a-form__field-name">
              <div className="a8a-form__field-row">
                <div className="a8a-form__field-content">
                  e. &nbsp;&nbsp;Total Rent paid by employee for Place of Residence
                </div>
              </div>
            </div>
            <div className="a8a-form__field-action">
              <SalaryInput
                size="small"
                readOnly={readOnly}
                value={formData.rentEmAmt}
                onChange={(value: number | null) => handleFormDataChange({ rentEmAmt: value })}
              />
            </div>
          </div>
          <div className="a8a-form__field">
            <div className="a8a-form__field-name">
              <div className="a8a-form__field-row">
                <div className="a8a-form__field-content boldText">
                  f. &nbsp;&nbsp;Total Taxable Value of Place of Residence (2d - 2e)
                </div>
              </div>
            </div>
            <div className="a8a-form__field-action autoFill">
              {formatMoney(formData.placeOfResidenceTaxableTotal, 2)}
            </div>
          </div>
          <div className="a8a-form__field">
            <div className="a8a-form__field-name">
              <div className="a8a-form__field-row">
                <div className="a8a-form__field-content">
                  g. &nbsp;&nbsp;Utilities/Telephone/Pager/Suitcase/Golf Bag & Accessories/Camera/Electronic Gadgets
                  (e.g Tablet, Laptop, etc)[Actual Amount]
                </div>
              </div>
            </div>
            <div className="a8a-form__field-action">
              <SalaryInput
                size="small"
                readOnly={readOnly}
                value={formData.utilitiesAmt}
                onChange={(value: number | null) => handleFormDataChange({ utilitiesAmt: value })}
              />
            </div>
          </div>
          <div className="a8a-form__field">
            <div className="a8a-form__field-name">
              <div className="a8a-form__field-row">
                <div className="a8a-form__field-content">
                  h. &nbsp;&nbsp;Driver [Annual Wages X (Private / Total Mileage)]
                </div>
              </div>
            </div>
            <div className="a8a-form__field-action">
              <SalaryInput
                size="small"
                readOnly={readOnly}
                value={formData.driverAmt}
                onChange={(value: number | null) => handleFormDataChange({ driverAmt: value })}
              />
            </div>
          </div>
          <div className="a8a-form__field">
            <div className="a8a-form__field-name">
              <div className="a8a-form__field-row">
                <div className="a8a-form__field-content">i. &nbsp;&nbsp;Servant / Gardener / Upkeep of Compound</div>
              </div>
              <div className="a8a-form__field-row">
                <div className="a8a-form__field-content">
                  <div className="ml15">
                    &nbsp;[Annual wages of actual amount paid by employer to a company to perform these services]
                  </div>
                </div>
              </div>
            </div>
            <div className="a8a-form__field-action">
              <SalaryInput
                size="small"
                readOnly={readOnly}
                value={formData.servantAmt}
                onChange={(value: number | null) => handleFormDataChange({ servantAmt: value })}
              />
            </div>
          </div>
          <div className="a8a-form__field">
            <div className="a8a-form__field-name">
              <div className="a8a-form__field-row">
                <div className="a8a-form__field-content boldText">
                  j. &nbsp;&nbsp;Taxable value of utilities and housekeeping costs (2g + 2h + 2i)
                </div>
              </div>
            </div>
            <div className="a8a-form__field-action autoFill">{formatMoney(formData.utilitiesTaxableValue, 2)}</div>
          </div>
          <div className="a8a-form__title">3. Hotel Accommodation Provided</div>
          <div className="a8a-form__field">
            <div className="a8a-form__field-name">
              <div className="a8a-form__field-row">
                <div className="a8a-form__field-content">
                  a. &nbsp;&nbsp;Actual cost of Hotel accommodation/Serviced Apartment within hotel building
                </div>
              </div>
            </div>
            <div className="a8a-form__field-action">
              <SalaryInput
                size="small"
                value={formData.hotelAmt}
                onChange={(value: number | null) => handleFormDataChange({ hotelAmt: value })}
              />
            </div>
          </div>
          <div className="a8a-form__field">
            <div className="a8a-form__field-name">
              <div className="a8a-form__field-row">
                <div className="a8a-form__field-content">b. &nbsp;&nbsp;Amount paid by the employee</div>
              </div>
            </div>
            <div className="a8a-form__field-action">
              <SalaryInput
                size="small"
                readOnly={readOnly}
                value={formData.hotelEmAmt}
                onChange={(value: number | null) => handleFormDataChange({ hotelEmAmt: value })}
              />
            </div>
          </div>
          <div className="a8a-form__field">
            <div className="a8a-form__field-name">
              <div className="a8a-form__field-row">
                <div className="a8a-form__field-content boldText">
                  c. &nbsp;&nbsp;Taxable Value of Hotel Accommodation (3a - 3b)
                </div>
              </div>
            </div>
            <div className="a8a-form__field-action autoFill">
              {formatMoney(formData.hotelAccomodationTaxableValue, 2)}
            </div>
          </div>
          <div className="a8a-form__title">4. Others</div>
          <div className="a8a-form__field">
            <div className="a8a-form__field-name">
              <div className="a8a-form__field-row">
                <div className="a8a-form__field-content">
                  a. &nbsp;&nbsp;Cost of home leave passages and incidental benefits provided to employee and his
                  family:
                </div>
              </div>
            </div>
            <div className="a8a-form__field-action">
              <SalaryInput
                size="small"
                readOnly={readOnly}
                value={formData.leavePassageTotal}
                onChange={(leavePassageTotal: number | null) => handleFormDataChange({ leavePassageTotal })}
              />
              <A8aTooltip originalAmt={formData.leavePassageAmt} adjustmentAmt={formData.leavePassageAdj} />
            </div>
          </div>
          <div className="a8a-form__field">
            <div className="a8a-form__field-name">
              <div className="a8a-form__field-row">
                <div className="a8a-form__field-content">
                  b. &nbsp;&nbsp;Interest payment made by the employer to a third party on behalf of an employee and/or
                  interest benefits arising from loans provided by employer
                </div>
              </div>
              <div className="a8a-form__field-row pt0">
                <div className="a8a-form__field-content ml30">
                  &nbsp;&nbsp;interest free or at a rate below market rate to the employee who has substantial
                  shareholding or control or influence over the company
                </div>
              </div>
            </div>
            <div className="a8a-form__field-action">
              <SalaryInput
                size="small"
                readOnly={readOnly}
                value={formData.interestTotal}
                onChange={(interestTotal: number | null) => handleFormDataChange({ interestTotal })}
              />
              <A8aTooltip originalAmt={formData.interestAmt} adjustmentAmt={formData.interestAdj} />
            </div>
          </div>
          <div className="a8a-form__field">
            <div className="a8a-form__field-name">
              <div className="a8a-form__field-row">
                <div className="a8a-form__field-content">c. &nbsp;&nbsp;Insurance premiums paid by the employer</div>
              </div>
            </div>
            <div className="a8a-form__field-action">
              <SalaryInput
                size="small"
                readOnly={readOnly}
                value={formData.insuranceTotal}
                onChange={(insuranceTotal: number | null) => handleFormDataChange({ insuranceTotal })}
              />
              <A8aTooltip originalAmt={formData.insuranceAmt} adjustmentAmt={formData.insuranceAdj} />
            </div>
          </div>
          <div className="a8a-form__field">
            <div className="a8a-form__field-name">
              <div className="a8a-form__field-row">
                <div className="a8a-form__field-content">
                  d. &nbsp;&nbsp;Free or subsidised holidays including air passage, etc.
                </div>
              </div>
            </div>
            <div className="a8a-form__field-action">
              <SalaryInput
                size="small"
                readOnly={readOnly}
                value={formData.holidayTotal}
                onChange={(holidayTotal: number | null) => handleFormDataChange({ holidayTotal })}
              />
              <A8aTooltip originalAmt={formData.holidayAmt} adjustmentAmt={formData.holidayAdj} />
            </div>
          </div>
          <div className="a8a-form__field">
            <div className="a8a-form__field-name">
              <div className="a8a-form__field-row">
                <div className="a8a-form__field-content">
                  e. &nbsp;&nbsp;Educational expenses including tutor provided
                </div>
              </div>
            </div>
            <div className="a8a-form__field-action">
              <SalaryInput
                size="small"
                readOnly={readOnly}
                value={formData.educationTotal}
                onChange={(educationTotal: number | null) => handleFormDataChange({ educationTotal })}
              />
              <A8aTooltip originalAmt={formData.educationAmt} adjustmentAmt={formData.educationAdj} />
            </div>
          </div>
          <div className="a8a-form__field">
            <div className="a8a-form__field-name">
              <div className="a8a-form__field-row">
                <div className="a8a-form__field-content">
                  f. &nbsp;&nbsp;Entrance/transfer fees and annual subscription to social or recreational clubs
                </div>
              </div>
            </div>
            <div className="a8a-form__field-action">
              <SalaryInput
                size="small"
                readOnly={readOnly}
                value={formData.clubTotal}
                onChange={(clubTotal: number | null) => handleFormDataChange({ clubTotal })}
              />
              <A8aTooltip originalAmt={formData.clubAmt} adjustmentAmt={formData.clubAdj} />
            </div>
          </div>
          <div className="a8a-form__field">
            <div className="a8a-form__field-name">
              <div className="a8a-form__field-row">
                <div className="a8a-form__field-content">
                  g. &nbsp;&nbsp;Gains from assets, e.g. vehicles, property, etc. sold to employees at a price lower
                  than open market value:
                </div>
              </div>
            </div>
            <div className="a8a-form__field-action">
              <SalaryInput
                size="small"
                readOnly={readOnly}
                value={formData.gainTotal}
                onChange={(gainTotal: number | null) => handleFormDataChange({ gainTotal })}
              />
              <A8aTooltip originalAmt={formData.gainAmt} adjustmentAmt={formData.gainAdj} />
            </div>
          </div>
          <div className="a8a-form__field">
            <div className="a8a-form__field-name">
              <div className="a8a-form__field-row">
                <div className="a8a-form__field-content">
                  h. &nbsp;&nbsp;Full cost of motor vehicles given to employee
                </div>
              </div>
            </div>
            <div className="a8a-form__field-action">
              <SalaryInput
                size="small"
                readOnly={readOnly}
                value={formData.vehicleTotal}
                onChange={(vehicleTotal: number | null) => handleFormDataChange({ vehicleTotal })}
              />
              <A8aTooltip originalAmt={formData.vehicleAmt} adjustmentAmt={formData.vehicleAdj} />
            </div>
          </div>
          <div className="a8a-form__field">
            <div className="a8a-form__field-name">
              <div className="a8a-form__field-row">
                <div className="a8a-form__field-content">i. &nbsp;&nbsp;Car benefits</div>
              </div>
            </div>
            <div className="a8a-form__field-action">
              <SalaryInput
                size="small"
                readOnly={readOnly}
                value={formData.carTotal}
                onChange={(carTotal: number | null) => handleFormDataChange({ carTotal })}
              />
              <A8aTooltip originalAmt={formData.carAmt} adjustmentAmt={formData.carAdj} />
            </div>
          </div>
          <div className="a8a-form__field">
            <div className="a8a-form__field-name">
              <div className="a8a-form__field-row">
                <div className="a8a-form__field-content">
                  j. &nbsp;&nbsp;Other non-monetary awards/benefits which do not fall within the above items
                </div>
              </div>
            </div>
            <div className="a8a-form__field-action">
              <SalaryInput
                size="small"
                readOnly={readOnly}
                value={formData.otherTotal}
                onChange={(otherTotal: number | null) => handleFormDataChange({ otherTotal })}
              />
              <A8aTooltip originalAmt={formData.otherAmt} adjustmentAmt={formData.otherAdj} />
            </div>
          </div>
          <div className="a8a-form__field">
            <div className="a8a-form__field-name">
              <div className="a8a-form__field-row">
                <div className="a8a-form__field-content boldText">
                  TOTAL VALUE OF BENEFITS-IN-KIND (ITEMS 2 TO 4) TO BE REFLECTED IN ITEM d9 OF FORM IR8A
                </div>
              </div>
            </div>
            <div className="a8a-form__field-action autoFill">{formatMoney(formData.bikTotal, 2)}</div>
          </div>
        </Form>
        <ErrorDisplay errors={errors} />
      </div>
    </div>
  )
}

interface A8aTooltipProps {
  originalAmt: number
  adjustmentAmt: number
}

const A8aTooltip: FC<A8aTooltipProps> = ({ originalAmt, adjustmentAmt }) => {
  if (adjustmentAmt === 0) return null

  return (
    <InfoTooltip
      title={`Original amount: ${formatMoney(originalAmt, 2)}
        Adjustment amount: ${formatMoney(adjustmentAmt, 2)}`}
      overlayStyle={{ whiteSpace: 'pre-line', maxWidth: 400 }}
    />
  )
}
