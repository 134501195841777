import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'
import { replaceOtConfigs, setOtConfigsLoading } from '../reducers'
import { apiGetOtConfigs } from '../api/ot-config.api'

export const fetchOtConfigs = (options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().attendance.otConfigsLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().attendance.otConfigs?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setOtConfigsLoading(true))
      const { result, status } = await apiGetOtConfigs()
      if (status) {
        dispatch(replaceOtConfigs(result))
      }
    } finally {
      dispatch(setOtConfigsLoading(false))
    }
  }
}
