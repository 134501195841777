import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { Tab, TabTitle, Tabs } from '~/core-components'
import { DocumentTitle } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { SelectView, useFirstView } from '~/features/selection'
import { dispatch } from '~/stores/store'
import { ATT_ROUTES } from '~/routes/routes'
import { Permission } from '~/constants'
import {
  clearDailyRecordsView,
  refetchAttendancePeriodsView,
  refetchDailyRecordsView,
  refetchTimeLogsView
} from '../../reducers'
import { TimeLogs } from '../TimeLogs/TimeLogs'
import { DailyRecords } from '../DailyRecords/DailyRecords'
import { AttendancePeriods } from '../AttendancePeriods/AttendancePeriods'
import './AttendanceOverview.less'

export const AttendanceOverview: FC = () => {
  const match = useRouteMatch<{ tab: string }>()
  const tab = match.params.tab || 'daily'
  const history = useHistory()
  const canViewDailyRecord = usePermissionGate(Permission.attDailyRecord)
  const canViewTimeLog = usePermissionGate(Permission.attTimeLog)

  const [view] = useFirstView('daily_record')
  const firstViewId = view?.id || ''
  const [viewId, setViewId] = useState<string>()

  useEffect(() => {
    if (!viewId && firstViewId) {
      setViewId(firstViewId)
      dispatch(clearDailyRecordsView())
    }
  }, [firstViewId, viewId])

  const handleViewChange = useCallback((viewId?: string) => {
    setViewId(viewId)
  }, [])

  const handleTabChange = useCallback(
    (activeKey: string) => {
      history.push(`${ATT_ROUTES.tab.replace(':tab?', activeKey)}`)

      if (activeKey === 'daily') {
        dispatch(refetchDailyRecordsView())
      } else if (activeKey === 'period') {
        dispatch(refetchAttendancePeriodsView())
      } else if (activeKey === 'clock') {
        dispatch(refetchTimeLogsView())
      }
    },
    [history]
  )

  const tabItems = useMemo(() => {
    const result: Tab[] = []
    if (canViewDailyRecord) {
      result.push({
        key: 'daily',
        label: 'Daily records',
        children: <DailyRecords viewId={viewId} />
      })
    }

    if (canViewTimeLog) {
      result.push({
        key: 'clock',
        label: 'Time logs',
        children: <TimeLogs />
      })
    }

    if (canViewDailyRecord) {
      result.push({
        key: 'period',
        label: 'Monthly',
        children: <AttendancePeriods />
      })
    }
    return result
  }, [viewId, canViewDailyRecord, canViewTimeLog])

  return (
    <div className="attendance-overview">
      <DocumentTitle title="Attendance Overview" />
      <div className="attendance-overview__body">
        <Tabs
          defaultActiveKey={tab}
          onChange={handleTabChange}
          tabBarExtraContent={{
            left: <TabTitle title="Attendance overview" />,
            right: (
              <>
                {tab === 'daily' && (
                  <SelectView screenCode="daily_record" viewId={viewId} size="small" onChange={handleViewChange} />
                )}
              </>
            )
          }}
          items={tabItems}
        />
      </div>
    </div>
  )
}
