import React, { ChangeEvent, CSSProperties, FC, HTMLAttributes, useCallback, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { useSelector } from 'react-redux'
import { Form, Input, Radio, RadioChangeEvent } from '~/core-components'
import { Col, Row, SalaryInput } from '~/components'
import { selectFormulaById } from '~/features/formula'
import { PayItemState } from '~/features/master'
import { Errors, StoreState } from '~/types/store'
import { IPayRecurringInfo } from '../../types'

export interface PayRecurringForm extends IPayRecurringInfo {
  payRunId: string
}

export const EMPTY_PAY_RECURRING_FORM_DATA: PayRecurringForm = {
  payItemId: '',
  quantity: 0,
  rate: 0,
  startDate: '',
  endDate: '',
  notes: '',
  payRunId: ''
}

const radioGroupTitleStyle: CSSProperties = { marginBottom: 15 }
const radioStyle: CSSProperties = { width: '100%', marginBottom: 10 }
const radioItemStyle: CSSProperties = { marginBottom: 5 }
const radioInputStyle: CSSProperties = { marginLeft: 20 }

export interface AddPayRecurringFormProps extends HTMLAttributes<HTMLDivElement> {
  payItem?: PayItemState
  startDate?: string
  errors?: Errors
  resetForm: number
  onFormChange: (data: PayRecurringForm) => void
}

interface RecurringEnd {
  type: 'times' | 'until' | 'unlimited'
  times?: number
  until: string
}

const EMPTY_RECURRING_END: RecurringEnd = {
  type: 'times',
  times: undefined,
  until: ''
}

export const AddPayRecurringForm: FC<AddPayRecurringFormProps> = ({
  payItem,
  startDate,
  errors,
  resetForm,
  onFormChange,
  style
}: AddPayRecurringFormProps) => {
  const [formData, setFormData] = useState<PayRecurringForm>(EMPTY_PAY_RECURRING_FORM_DATA)
  const [recurringEnd, setRecurringEnd] = useState<RecurringEnd>(EMPTY_RECURRING_END)
  const formula = useSelector((state: StoreState) => selectFormulaById(state, payItem?.formulaId || ''))

  useEffect(() => {
    setFormData(EMPTY_PAY_RECURRING_FORM_DATA)
    setRecurringEnd(EMPTY_RECURRING_END)
  }, [resetForm])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      const updated = { ...formData, ...updates }
      setFormData(updated)
      typeof onFormChange === 'function' && onFormChange(updated)
    },
    [formData, onFormChange]
  )

  const handleRecurringEndChange = useCallback(
    (updates: { [field: string]: any }) => {
      const updated = { ...recurringEnd, ...updates }
      setRecurringEnd(updated)

      let endDate = ''
      if (updated.type === 'times') {
        endDate = moment(startDate)
          .add((updated.times || 0) - 1, 'month')
          .format('YYYY-MM-DD')
      } else if (updated.type === 'until') {
        endDate = updated.until
      }
      handleFormDataChange({ endDate })
    },
    [recurringEnd, startDate, handleFormDataChange]
  )

  useEffect(() => {
    setFormData({
      ...EMPTY_PAY_RECURRING_FORM_DATA,
      startDate: startDate || '',
      payItemId: payItem?.id || '',
      rate: payItem?.rate || 0
    })
  }, [payItem, startDate])

  return (
    <div style={style}>
      <Row>
        <Col span={12}>
          <Form.Item label="Quantity" validateStatus={errors?.quantity ? 'error' : ''} help={errors?.quantity}>
            <SalaryInput
              precision={4}
              value={formData.quantity}
              onChange={(value: number | null) => handleFormDataChange({ quantity: value })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item label="Rate" validateStatus={errors?.rate ? 'error' : ''} help={errors?.rate}>
            <SalaryInput
              precision={4}
              value={formData.rate}
              onChange={(value: number | null) => handleFormDataChange({ rate: value })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row hidden={!formula?.name}>
        <Col span={24}>
          <Form.Item label="Formula">
            <label>{formula?.name}</label>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <Form.Item label="First payment">
            <label>{moment(startDate).format('DD MMM YYYY')}</label>
          </Form.Item>
        </Col>
      </Row>
      <div style={radioGroupTitleStyle}>
        <b>Recurring settings</b>
      </div>
      <Radio.Group
        value={recurringEnd.type}
        onChange={(event: RadioChangeEvent) =>
          handleRecurringEndChange({ type: event.target.value, times: undefined, until: '' })
        }
        style={radioStyle}
      >
        <Radio value="times" style={radioItemStyle}>
          No. of times
        </Radio>
        <Row style={radioInputStyle}>
          <Col span={24}>
            <Form.Item>
              <Input.Number
                precision={0}
                value={recurringEnd.times}
                min={1}
                disabled={recurringEnd.type !== 'times'}
                onChange={(value: number | null) => handleRecurringEndChange({ times: value })}
              ></Input.Number>
            </Form.Item>
          </Col>
        </Row>
        <Radio value="until" style={radioItemStyle}>
          Recurring until last payment
        </Radio>
        <Row style={radioInputStyle}>
          <Col span={8}>
            <Form.Item>
              <Input.Date
                value={recurringEnd.until ? moment(recurringEnd.until) : undefined}
                disabled={recurringEnd.type !== 'until'}
                onChange={(value: moment.Moment | null) =>
                  handleRecurringEndChange({ until: value?.format('YYYY-MM-DD') })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Radio value="unlimited" style={radioItemStyle}>
          Recurring until end of employment
        </Radio>
      </Radio.Group>
      <Row>
        <Col span={24}>
          <Form.Item label="Notes">
            <Input
              value={formData.notes}
              onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ notes: event.target.value })}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  )
}
