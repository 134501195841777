import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import confirm from 'antd/lib/modal/confirm'
import { LoadingOutlined } from '@ant-design/icons'
import { Link, PageHeader, Space, Select, Tabs, Button, Slider, Tooltip } from '~/core-components'
import { DocumentTitle, Person } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { emptyGuid, Permission, PermissionAction, YtdSubmissionStatus } from '~/constants'
import { useIsMountedRef } from '~/hooks/use-is-mounted-ref'
import { TAX_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { showError, getFileTimestamp, downloadWithDom, getBaseUrl } from '~/utils'
import {
  fetchYtdSubmissionEmployees,
  lockIr8aDraft,
  processA8a,
  processIr8a,
  processIr8s,
  resetA8a,
  resetIr8a,
  resetIr8s,
  unlockIr8aDraft
} from '../../actions'
import { YtdFormDownloadDto } from '../../types'
import {
  selectA8aForm,
  selectA8bForm,
  selectIr8aForm,
  selectIr8aSubmissionEmployees,
  selectIr8sForm
} from '../../selectors'
import { useYtdSubmission } from '../../hooks'
import { apiDownloadYtdForm } from '../../api/ytd.api'
import { setA8aFormRefetch, setIr8aFormRefetch, setIr8sFormRefetch } from '../../reducers'
import { Ir8aForm } from './Ir8aForm/Ir8aForm'
import { Ir8sForm } from './Ir8sForm/Ir8sForm'
import { A8aForm } from './A8aForm/A8aForm'
import { A8bForm } from './A8bForm/A8bForm'
import { YtdTags } from '../YtdSubmission/YtdTags'
import './YtdForms.less'

interface YtdFormsProps {}

interface YtdFormsParams {
  id: string
  employeeTaxNo: string
  tab: string
}

interface YtdFormsSubmissionData {
  employeeTaxNo: string
}

const map: { [key: string]: string } = {
  ir8a: 'IR8A',
  ir8s: 'IR8S',
  a8a: 'Appendix 8A',
  a8b: 'Appendix 8B'
}

const baseUrl = getBaseUrl('/filestore')

export const YtdForms: FC<YtdFormsProps> = () => {
  const match = useRouteMatch<YtdFormsParams>()
  const ytdSubmissionId = match.params.id
  const employeeTaxNo = match.params.employeeTaxNo
  const [tab, setTab] = useState<string>(match.params.tab)

  const EMPTY_FORM_DATA: YtdFormsSubmissionData = { employeeTaxNo }
  const [ytdSubmission] = useYtdSubmission(ytdSubmissionId)
  const submissionTitle = ytdSubmission ? `IRAS ${ytdSubmission?.ytdYear} - ${ytdSubmission?.employerTaxNo}` : 'IRAS'
  const title = ytdSubmission
    ? `IRAS ${ytdSubmission?.ytdYear} - ${ytdSubmission?.companyName} (${ytdSubmission?.employerTaxNo})`
    : 'IRAS'

  const history = useHistory()
  const employees = useSelector(selectIr8aSubmissionEmployees)
  const fetching = useSelector((state: StoreState) => state.tax.ytdSubmissionEmployeesLoading['ir8a'])

  const [locking, setLocking] = useState<string>()
  const [deleting, setDeleting] = useState<boolean>(false)
  const [formData, setFormData] = useState<YtdFormsSubmissionData>(EMPTY_FORM_DATA)
  const updating = useSelector(
    (state: StoreState) =>
      state.tax.ir8aFormUpdating || state.tax.ir8sFormUpdating || state.tax.a8aFormUpdating || state.tax.a8bFormUpdating
  )
  const [calculating, setCalculating] = useState<boolean>(false)
  const [resetting, setResetting] = useState<boolean>(false)

  const ir8aForm = useSelector(selectIr8aForm)(ytdSubmissionId, formData.employeeTaxNo)
  const ir8sForm = useSelector(selectIr8sForm)(ytdSubmissionId, formData.employeeTaxNo)
  const a8aForm = useSelector(selectA8aForm)(ytdSubmissionId, formData.employeeTaxNo)
  const a8bForm = useSelector(selectA8bForm)(ytdSubmissionId, formData.employeeTaxNo)
  const isMountedRef = useIsMountedRef()
  const [downloading, setDownloading] = useState(false)
  const canModify = usePermissionGate(Permission.ytd, PermissionAction.Modify)
  const isLocked = !!ir8aForm?.lockedBy
  const readOnly = !canModify || isLocked || ytdSubmission?.status === YtdSubmissionStatus.submitted

  const [zoomIr8a, setZoomIr8a] = useState(1)
  const [zoomIr8s, setZoomIr8s] = useState(1)
  const [zoomA8a, setZoomA8a] = useState(1)
  const [zoomA8b, setZoomA8b] = useState(1)

  const [editing, setEditing] = useState<boolean>(false)
  const [isEditingIr8a, setIsEditingIr8a] = useState<boolean>(false)
  const [isEditingIr8s, setIsEditingIr8s] = useState<boolean>(false)
  const [isEditingA8a, setIsEditingA8a] = useState<boolean>(false)
  const [isEditingA8b, setIsEditingA8b] = useState<boolean>(false)

  const [hasChangeIr8a, setHasChangeIr8a] = useState<boolean>(false)
  const [hasChangeIr8s, setHasChangeIr8s] = useState<boolean>(false)
  const [hasChangeA8a, setHasChangeA8a] = useState<boolean>(false)
  const [hasChangeA8b, setHasChangeA8b] = useState<boolean>(false)

  const [triggerIr8aUpdate, setTriggerIr8aUpdate] = useState(0)
  const [triggerIr8aDelete, setTriggerIr8aDelete] = useState(0)
  const [triggerIr8aDiscard, setTriggerIr8aDiscard] = useState(0)
  const [triggerIr8sUpdate, setTriggerIr8sUpdate] = useState(0)
  const [triggerIr8sDelete, setTriggerIr8sDelete] = useState(0)
  const [triggerIr8sDiscard, setTriggerIr8sDiscard] = useState(0)
  const [triggerA8aUpdate, setTriggerA8aUpdate] = useState(0)
  const [triggerA8aDelete, setTriggerA8aDelete] = useState(0)
  const [triggerA8aDiscard, setTriggerA8aDiscard] = useState(0)
  const [triggerA8bUpdate, setTriggerA8bUpdate] = useState(0)
  const [triggerA8bDelete, setTriggerA8bDelete] = useState(0)
  const [triggerA8bDiscard, setTriggerA8bDiscard] = useState(0)

  const hasForm = useMemo(() => {
    if (tab === 'ir8a') {
      return !!ir8aForm
    } else if (tab === 'ir8s') {
      return !!ir8sForm
    } else if (tab === 'a8a') {
      return !!a8aForm
    } else if (tab === 'a8b') {
      return !!a8bForm
    }
    return true
  }, [tab, ir8aForm, ir8sForm, a8aForm, a8bForm])

  const editForm = useMemo(() => {
    if (tab === 'ir8a') {
      return isEditingIr8a
    } else if (tab === 'ir8s') {
      return isEditingIr8s
    } else if (tab === 'a8a') {
      return isEditingA8a
    } else if (tab === 'a8b') {
      return isEditingA8b
    }
    return true
  }, [tab, isEditingIr8a, isEditingIr8s, isEditingA8a, isEditingA8b])

  const routes = useMemo(
    () => [
      {
        path: TAX_ROUTES.tab.replace(':tab?', 'submission'),
        breadcrumbName: 'Overview'
      },
      {
        path: TAX_ROUTES.submission.replace(':id', ytdSubmissionId).replace(':tab?', tab),
        breadcrumbName: submissionTitle
      },
      {
        path: '',
        breadcrumbName: employeeTaxNo
      }
    ],
    [ytdSubmissionId, submissionTitle, tab, employeeTaxNo]
  )

  useEffect(() => {
    dispatch(fetchYtdSubmissionEmployees('ir8a', ytdSubmissionId))
  }, [ytdSubmissionId])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      history.push(
        `${TAX_ROUTES.formsSubmission
          .replace(':id', ytdSubmissionId)
          .replace(':employeeTaxNo', updates.employeeTaxNo)
          .replace(':tab?', tab)}`
      )

      setFormData(formData => ({ ...formData, ...updates }))
    },
    [ytdSubmissionId, tab, history]
  )

  const handleDiscardForm = useCallback(
    (callbackFn?: () => void) => {
      const tabToChangeMap: { [key: string]: boolean } = {
        ir8a: hasChangeIr8a,
        ir8s: hasChangeIr8s,
        a8a: hasChangeA8a,
        a8b: hasChangeA8b
      }
      const hasChange = tabToChangeMap[tab] || false

      const resetForm = () => {
        if (tab === 'ir8a') {
          setTriggerIr8aDiscard(t => t + 1)
          setHasChangeIr8a(false)
          setIsEditingIr8a(false)
        } else if (tab === 'ir8s') {
          setTriggerIr8sDiscard(t => t + 1)
          setHasChangeIr8s(false)
          setIsEditingIr8s(false)
        } else if (tab === 'a8a') {
          setTriggerA8aDiscard(t => t + 1)
          setHasChangeA8a(false)
          setIsEditingA8a(false)
        } else if (tab === 'a8b') {
          setTriggerA8bDiscard(t => t + 1)
          setHasChangeA8b(false)
          setIsEditingA8b(false)
        }

        typeof callbackFn === 'function' && callbackFn()
      }

      if (hasChange) {
        confirm({
          title: 'Discard changes',
          content: `We won’t be able to save your changes if you move away from this ${map[tab]} form. Are you sure you want to cancel and discard your changes?`,
          onOk: resetForm,
          okText: 'Discard changes',
          okType: 'danger',
          cancelText: 'Go back'
        })
      } else {
        resetForm()
      }
    },
    [tab, hasChangeIr8a, hasChangeIr8s, hasChangeA8a, hasChangeA8b]
  )

  const handleTabChange = useCallback(
    (activeKey: string) => {
      handleDiscardForm(() => {
        history.push(
          `${TAX_ROUTES.formsSubmission
            .replace(':id', ytdSubmissionId)
            .replace(':employeeTaxNo', employeeTaxNo)
            .replace(':tab?', activeKey)}`
        )
        setTab(activeKey)
      })
    },
    [handleDiscardForm, ytdSubmissionId, employeeTaxNo, history]
  )

  const handleSubmitForm = useCallback(async () => {
    if (tab === 'ir8a') {
      setTriggerIr8aUpdate(t => t + 1)
      setIsEditingIr8a(false)
    } else if (tab === 'ir8s') {
      setTriggerIr8sUpdate(t => t + 1)
      setIsEditingIr8s(false)
    } else if (tab === 'a8a') {
      setTriggerA8aUpdate(t => t + 1)
      setIsEditingA8a(false)
    } else if (tab === 'a8b') {
      setTriggerA8bUpdate(t => t + 1)
      setIsEditingA8b(false)
    }
  }, [tab])

  const handleDeleteForm = useCallback(async () => {
    setDeleting(true)
    try {
      if (tab === 'ir8a') {
        setTriggerIr8aDelete(t => t + 1)
      } else if (tab === 'ir8s') {
        setTriggerIr8sDelete(t => t + 1)
      } else if (tab === 'a8a') {
        setTriggerA8aDelete(t => t + 1)
      } else if (tab === 'a8b') {
        setTriggerA8bDelete(t => t + 1)
      }
    } finally {
      setDeleting(false)
    }
  }, [tab])

  const handleResetForm = useCallback(() => {
    setResetting(true)
    try {
      confirm({
        title: 'Reset to original values',
        content: `Clicking ‘Reset’ will discard all manual adjustments
          ${tab === 'a8a' ? 'in Section 4 (Others)' : ''}
          and revert amounts to the original values calculated by the system. Are you sure you want to proceed?`,
        onOk: async () => {
          if (tab === 'ir8a') {
            await dispatch(resetIr8a(ytdSubmissionId, employeeTaxNo))
            dispatch(setIr8aFormRefetch())
          } else if (tab === 'ir8s') {
            await dispatch(resetIr8s(ytdSubmissionId, employeeTaxNo))
            dispatch(setIr8sFormRefetch())
          } else if (tab === 'a8a') {
            await dispatch(resetA8a(ytdSubmissionId, employeeTaxNo))
            dispatch(setA8aFormRefetch())
          }
        },
        okText: 'Reset',
        okType: 'danger'
      })
    } finally {
      setResetting(false)
    }
  }, [tab, ytdSubmissionId, employeeTaxNo])

  const handleCalculateForm = useCallback(async () => {
    setCalculating(true)
    try {
      if (tab === 'ir8a') {
        await dispatch(processIr8a(ytdSubmissionId, [employeeTaxNo]))
        dispatch(setIr8aFormRefetch())
      } else if (tab === 'ir8s') {
        await dispatch(processIr8s(ytdSubmissionId, [employeeTaxNo]))
        dispatch(setIr8sFormRefetch())
      } else if (tab === 'a8a') {
        await dispatch(processA8a(ytdSubmissionId, [employeeTaxNo]))
        dispatch(setA8aFormRefetch())
      }
    } finally {
      setCalculating(false)
    }
  }, [tab, ytdSubmissionId, employeeTaxNo])

  const handleEditForm = useCallback(async () => {
    setEditing(true)
    try {
      if (tab === 'ir8a') {
        setIsEditingIr8a(true)
      } else if (tab === 'ir8s') {
        setIsEditingIr8s(true)
      } else if (tab === 'a8a') {
        setIsEditingA8a(true)
      } else if (tab === 'a8b') {
        setIsEditingA8b(true)
      }
    } finally {
      setEditing(false)
    }
  }, [tab])

  const handleSliderChange = useCallback(
    (value: number) => {
      if (tab === 'ir8a') {
        setZoomIr8a(value)
      } else if (tab === 'ir8s') {
        setZoomIr8s(value)
      } else if (tab === 'a8a') {
        setZoomA8a(value)
      } else if (tab === 'a8b') {
        setZoomA8b(value)
      }
    },
    [tab]
  )

  const getZoomValue = useCallback(() => {
    if (tab === 'ir8a') {
      return zoomIr8a
    } else if (tab === 'ir8s') {
      return zoomIr8s
    } else if (tab === 'a8a') {
      return zoomA8a
    } else if (tab === 'a8b') {
      return zoomA8b
    }
  }, [tab, zoomIr8a, zoomIr8s, zoomA8a, zoomA8b])

  const handleLock = useCallback(async () => {
    if (ir8aForm) {
      setLocking(ir8aForm.id)
      try {
        if (ir8aForm.lockedBy) {
          await dispatch(unlockIr8aDraft(ir8aForm.id))
        } else {
          await dispatch(lockIr8aDraft(ir8aForm.id))
        }
      } finally {
        setLocking(undefined)
      }
    }
  }, [ir8aForm])

  const handleDownloadClick = useCallback(async () => {
    if (tab) {
      try {
        setDownloading(true)
        const { status, result, errors, message, errorData } = await apiDownloadYtdForm(tab, {
          ytdYear: ytdSubmission?.ytdYear || '',
          employerTaxNo: ytdSubmission?.employerTaxNo || '',
          employeeTaxNo: formData.employeeTaxNo,
          ytdSubmissionId: ytdSubmission?.id
        } as YtdFormDownloadDto)

        if (status) {
          const fileName = `${tab}_${getFileTimestamp()}.pdf`
          downloadWithDom(result, fileName)
        } else {
          console.error('Error while downloading', errors)
          showError(message, errorData)
        }
      } finally {
        if (isMountedRef.current) setDownloading(false)
      }
    }
  }, [tab, ytdSubmission, formData.employeeTaxNo, isMountedRef])

  return (
    <div className="ytd-forms">
      <DocumentTitle title={title} />
      <PageHeader
        title={
          <Space align="center">
            <span>{title}</span>
            <YtdTags ytdSubmission={ytdSubmission} />
          </Space>
        }
        breadcrumb={{ routes }}
      />
      <div className="ytd-forms__header">
        <Space align="center">
          <Select
            className="ytd-forms__header-emselect"
            showSearch
            allowClear={false}
            loading={fetching}
            optionFilterProp="title"
            onChange={(value: string) => handleFormDataChange({ employeeTaxNo: value })}
            value={formData.employeeTaxNo}
          >
            {employees?.map(em => (
              <Select.Option key={em.description} value={em.description} title={em.name}>
                <Person
                  name={em.name}
                  description={em.description}
                  photo={
                    em.photoId && em.photoId !== emptyGuid
                      ? `${baseUrl}/file/${em.photoId}/thumbnailphoto/36`
                      : undefined
                  }
                  // photo={em.id && `/employee/employee/${em.id}/avatar/36?photo_id=${em.photoId}`}
                />
              </Select.Option>
            ))}
          </Select>
          {canModify && ytdSubmission?.status === YtdSubmissionStatus.draft && (
            <Tooltip title={!!ir8aForm?.lockedBy ? 'Locked' : 'Unlocked'}>
              <Link
                className={classNames('ytd-forms__lock', { 'ytd-forms__lock--locked': !!ir8aForm?.lockedBy })}
                onClick={handleLock}
              >
                {!!locking && locking === ir8aForm?.id ? (
                  <LoadingOutlined />
                ) : !!ir8aForm?.lockedBy ? (
                  <i className="fal fa-lock" />
                ) : (
                  <i className="fal fa-lock-open" />
                )}
              </Link>
            </Tooltip>
          )}
        </Space>
        <Space className="ytd-forms__action-bar-buttons">
          {!readOnly && hasForm && (
            <>
              {editForm ? (
                <>
                  <Button onClick={() => handleDiscardForm()}>Cancel</Button>
                  <Button type="primary" onClick={handleSubmitForm} loading={updating}>
                    Save changes
                  </Button>
                </>
              ) : (
                <>
                  {tab !== 'a8b' && (
                    <>
                      <Tooltip title={`Recalculate ${map[tab]}`}>
                        <Button
                          icon={<i className="fal fa-refresh" />}
                          onClick={handleCalculateForm}
                          loading={calculating}
                        />
                      </Tooltip>
                      <Button onClick={handleResetForm} loading={resetting}>
                        Reset
                      </Button>
                    </>
                  )}
                  <Button type="primary" onClick={handleEditForm} loading={editing}>
                    Edit
                  </Button>
                  <Button onClick={handleDeleteForm} loading={deleting}>
                    Delete
                  </Button>
                </>
              )}
            </>
          )}
        </Space>
      </div>
      <div className="ytd-forms__body">
        <Tabs
          activeKey={tab}
          defaultActiveKey={tab || 'ir8a'}
          onChange={handleTabChange}
          tabBarExtraContent={{
            right: (
              <Space>
                <Tooltip title="Slide to zoom" placement="bottom">
                  <Space>
                    <i className="fal fa-magnifying-glass" />
                    <Slider min={0} max={1} onChange={handleSliderChange} value={getZoomValue()} step={0.1} />
                  </Space>
                </Tooltip>
                <Tooltip title={`Download ${tab.toUpperCase()}`} placement="bottomRight">
                  <Button
                    size="small"
                    className="btn-icon"
                    icon={<i className="fal fa-arrow-down-to-bracket" />}
                    onClick={handleDownloadClick}
                    loading={downloading}
                  />
                </Tooltip>
              </Space>
            )
          }}
          items={[
            {
              key: 'ir8a',
              label: 'IR8A',
              children: (
                <Ir8aForm
                  style={{ zoom: zoomIr8a }}
                  ytdYear={ytdSubmission?.ytdYear || ''}
                  employerTaxNo={ytdSubmission?.employerTaxNo || ''}
                  employeeTaxNo={formData.employeeTaxNo}
                  ytdSubmissionId={ytdSubmission?.id || ''}
                  readOnly={readOnly || !isEditingIr8a}
                  onFormChange={(hasChange: boolean) => setHasChangeIr8a(hasChange)}
                  triggerUpdate={triggerIr8aUpdate}
                  triggerDelete={triggerIr8aDelete}
                  triggerDiscard={triggerIr8aDiscard}
                />
              )
            },
            {
              key: 'ir8s',
              label: 'IR8S',
              children: (
                <Ir8sForm
                  style={{ zoom: zoomIr8s }}
                  ytdYear={ytdSubmission?.ytdYear || ''}
                  employerTaxNo={ytdSubmission?.employerTaxNo || ''}
                  employeeTaxNo={formData.employeeTaxNo}
                  ytdSubmissionId={ytdSubmission?.id || ''}
                  readOnly={readOnly || !isEditingIr8s}
                  onFormChange={(hasChange: boolean) => setHasChangeIr8s(hasChange)}
                  triggerUpdate={triggerIr8sUpdate}
                  triggerDelete={triggerIr8sDelete}
                  triggerDiscard={triggerIr8sDiscard}
                />
              )
            },
            {
              key: 'a8a',
              label: 'Appendix 8A',
              children: (
                <A8aForm
                  style={{ zoom: zoomA8a }}
                  ytdYear={ytdSubmission?.ytdYear || ''}
                  employerTaxNo={ytdSubmission?.employerTaxNo || ''}
                  employeeTaxNo={formData.employeeTaxNo}
                  ytdSubmissionId={ytdSubmission?.id || ''}
                  readOnly={readOnly || !isEditingA8a}
                  onFormChange={(hasChange: boolean, hasError: boolean) => {
                    setHasChangeA8a(hasChange)
                    if (hasError) setIsEditingA8a(true)
                  }}
                  triggerUpdate={triggerA8aUpdate}
                  triggerDelete={triggerA8aDelete}
                  triggerDiscard={triggerA8aDiscard}
                />
              )
            },
            {
              key: 'a8b',
              label: 'Appendix 8B',
              children: (
                <A8bForm
                  style={{ zoom: zoomA8b }}
                  ytdYear={ytdSubmission?.ytdYear || ''}
                  employerTaxNo={ytdSubmission?.employerTaxNo || ''}
                  employeeTaxNo={formData.employeeTaxNo}
                  ytdSubmissionId={ytdSubmission?.id || ''}
                  readOnly={readOnly || !isEditingA8b}
                  onFormChange={(hasChange: boolean) => setHasChangeA8b(hasChange)}
                  triggerUpdate={triggerA8bUpdate}
                  triggerDelete={triggerA8bDelete}
                  triggerDiscard={triggerA8bDiscard}
                />
              )
            }
          ]}
        />
      </div>
    </div>
  )
}
