import { request, getBaseUrl } from '~/utils/request'
import { LtSettingState } from '../types'

const baseUrl = getBaseUrl('/leave/leavetype')

export const apiGetLtSettings = (leaveTypeId: string) =>
  request<LtSettingState[]>('get', `${baseUrl}/${leaveTypeId}/setting`)

export const apiUpdateLtSettings = (leaveTypeId: string, ltSettings: LtSettingState[]) =>
  request<void>('post', `${baseUrl}/${leaveTypeId}/setting`, { ltSettings })
