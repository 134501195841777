import { replaceMyTimeLogs, setMyTimeLogsLoading } from '../reducers'
import { apiGetMyTimeLogs } from '../api/ss-time-log.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchMyTimeLogs = (
  startDate: string | null,
  endDate: string | null,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<Promise<void>> => {
  return async (dispatch, getState) => {
    if (!startDate || !endDate) return

    const loading = getState().myAttendance.myTimeLogsLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().myAttendance.myTimeLogs != null
      if (hasData) return
    }

    try {
      dispatch(setMyTimeLogsLoading(true))
      const { result, status } = await apiGetMyTimeLogs(startDate, endDate)
      if (status) dispatch(replaceMyTimeLogs(result))
    } finally {
      dispatch(setMyTimeLogsLoading(false))
    }
  }
}
