import { setKeyvalue } from '~/features/master'
import { showError, showSuccess } from '~/utils'
import { ThunkResult } from '~/types/store'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { apiUpdateAttendanceGroup } from '../api/attendance-group.api'
import { AttendanceGroupTimeLogState, AttendanceGroupState, IAttendanceGroupInfo } from '../types'
import { setAttendanceGroup } from '../reducers'

export const updateAttendanceGroup = <T extends IAttendanceGroupInfo | AttendanceGroupTimeLogState>(
  id: string,
  before: T,
  request: T
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const after = { ...before, ...request } as T
    const patch = generateJsonPatch<T>(before, after)
    const { status, errors, message, errorData } = await apiUpdateAttendanceGroup(id, patch)

    if (status) {
      const attendanceGroup = getState().attendance.attendanceGroups?.entities[id]
      const updated: AttendanceGroupState = {
        ...(attendanceGroup as AttendanceGroupState),
        ...after
      }
      dispatch(setAttendanceGroup(updated))
      dispatch(
        setKeyvalue({
          kv: 'attendanceGroup',
          data: { key: updated.id, value: updated.name, inactiveDate: updated.inactiveDate }
        })
      )

      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
