import { request, getBaseUrl } from '~/utils'
import { Search } from '~/types/common'
import { IClaimPendingApproval, IClaimPendingChangeApprover, ClaimPendingApprovalViewState } from '../types'

const baseUrl = getBaseUrl('/claim/claimpendingapproval')

export const apiGetClaimPendingApprovalsView = (viewId: string, periodYear: number, params: Search) =>
  request<ClaimPendingApprovalViewState>('get', `${baseUrl}/view/${viewId}/${periodYear}`, params)

export const apiApproveClaimPendingApproval = (claimPendingApproval: IClaimPendingApproval) =>
  request('post', `${baseUrl}/approve`, claimPendingApproval)

export const apiRejectClaimPendingApproval = (claimPendingApproval: IClaimPendingApproval) =>
  request('post', `${baseUrl}/reject`, claimPendingApproval)

export const apiChangeApproverClaimPendingApproval = (claimPendingChangeApprover: IClaimPendingChangeApprover) =>
  request('post', `${baseUrl}/changeapprover`, claimPendingChangeApprover)
