import React, { forwardRef } from 'react'
import classNames from 'classnames'
import { Select, SelectProps } from '~/core-components'
import { AttendanceGroupType } from '~/constants'
import { useAttendanceGroups } from '../../../hooks'
import './SelectAttendanceGroup.less'

export interface SelectAttendanceGroupProps extends SelectProps {
  type: AttendanceGroupType
  value: string
  onChange: (value: string) => void
}

const SelectAttendanceGroupInternal = (
  { type, className, ...props }: SelectAttendanceGroupProps,
  ref: React.Ref<any>
) => {
  const classes = classNames('select-attendance-group', { [`${className}`]: className })
  const [groups] = useAttendanceGroups()

  return (
    <Select ref={ref} className={classes} showSearch optionFilterProp="title" {...props}>
      {groups
        .filter(g => g.type === type)
        .map(g => (
          <Select.Option key={g.id} value={g.id} title={g.name}>
            {g.name}
          </Select.Option>
        ))}
    </Select>
  )
}

export const SelectAttendanceGroup = forwardRef(SelectAttendanceGroupInternal)
