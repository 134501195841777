import { setMaster } from '../reducers'
import { apiGetMaster } from '../api/master.api'
import { ThunkResult } from '~/types/store'
import { MasterName } from '../types'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'
import { getState } from '~/stores/store'

export const fetchMaster = (
  masterName: MasterName,
  id: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async dispatch => {
    if (!masterName || !id) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().master.masters[masterName]?.entities[id]
      if (hasData) return
    }

    const { result, status } = await apiGetMaster(masterName, id)
    if (status) {
      dispatch(setMaster({ masterName, data: result }))
    }
  }
}
