import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { apiUpdatePayRun } from '../api/payrun.api'
import { PayRunState, IPayRunInfo } from '../types'
import { setPayRun } from '../reducers'

export const updatePayRun = (id: string, before: IPayRunInfo, request: IPayRunInfo): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const after = { ...before, ...request } as IPayRunInfo
    const patch = generateJsonPatch<IPayRunInfo>(before, after)
    const { status, errors, message, errorData } = await apiUpdatePayRun(id, patch)

    if (status) {
      const payRecord = getState().payroll.payRuns.entities[id]
      const data: PayRunState = { ...(payRecord as PayRunState), ...after }
      dispatch(setPayRun(data))

      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
