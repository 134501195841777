import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchMyPayslipYears } from '../actions'
import { selectMyPayslipFilters } from '../selectors'
import { SSPayslipFilterOption } from '../types'

export const useMyPayslipFilters = (): [SSPayslipFilterOption[], boolean] => {
  const options = useSelector(selectMyPayslipFilters)
  const loading = useSelector((state: StoreState) => state.myPayroll.myPayslipYearsLoading)

  useEffect(() => {
    dispatch(fetchMyPayslipYears({ strategy: 'when-empty' }))
  }, [])

  return [options, loading]
}
