import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { OtConfigState } from '../types'
import { FetchStrategy } from '~/types/common'
import { selectOtConfigs } from '../selectors'
import { fetchOtConfigs } from '../actions'

export const useOtConfigs = (fetchStrategy: FetchStrategy = 'always'): [OtConfigState[], boolean] => {
  const data = useSelector(selectOtConfigs)
  const loading = useSelector((state: StoreState) => state.attendance.otConfigsLoading)

  useEffect(() => {
    dispatch(fetchOtConfigs({ strategy: fetchStrategy }))
  }, [fetchStrategy])

  return [data, loading]
}
