import React, { FC, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ChartSummary, EChartsOption } from '~/charts'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchAverageAge } from '../../../actions'

interface AverageAgeProps {}

export const AverageAge: FC<AverageAgeProps> = () => {
  const data = useSelector((state: StoreState) => state.employee.chartSummaries['average_age'])
  const loading = useSelector((state: StoreState) => state.employee.chartSummariesLoading['average_age'])

  const option = useMemo(() => {
    return {
      tooltip: {
        formatter: (params: any) => {
          const name = (params[0].name as string).toLowerCase()
          const marker = params[0].marker
          const value = params[0].value
          const unit = 'headcount(s)'
          return `Age ${name}<br />${marker} <b>${value}</b> ${unit}`
        }
      },
      xAxis: {
        data: data?.labels || []
      },
      series: [
        {
          type: 'bar',
          data: data?.values || [],
          itemStyle: {
            color: '#3caef2'
          }
        }
      ]
    } as EChartsOption
  }, [data])

  useEffect(() => {
    dispatch(fetchAverageAge())
  }, [])

  return (
    <ChartSummary
      title="Average age"
      number={data?.number || 0}
      option={option}
      footer={data?.median && `Median ${data.median}`}
      loading={loading}
    />
  )
}
