import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchYtdSubmissionView } from '../actions'
import { YtdSubmissionViewState } from '../types'

export const useYtdSubmissionView = (
  viewId: string,
  page: number,
  pageSize: number,
  fetchStrategy: FetchStrategy = 'when-empty'
): [YtdSubmissionViewState | undefined, boolean] => {
  const data = useSelector((state: StoreState) => state.tax.ytdSubmissionViews?.entities[viewId])
  const loading = useSelector((state: StoreState) => state.tax.ytdSubmissionViewsLoading)
  const refetch = useSelector((state: StoreState) => state.tax.ytdSubmissionViewsRefetch)

  useEffect(() => {
    if (viewId)
      dispatch(
        fetchYtdSubmissionView(viewId, { offset: pageSize * (page - 1), limit: pageSize }, '', {
          strategy: fetchStrategy
        })
      )
  }, [viewId, page, pageSize, refetch, fetchStrategy])

  return [data, loading]
}
