import React, { FC } from 'react'
import { default as AntSteps, StepProps as AntStepProps } from 'antd/lib/steps'

const { Step: AntStep } = AntSteps

export interface StepProps extends AntStepProps {}

export const Step: FC<StepProps> = (props: StepProps) => {
  return <AntStep {...props} />
}
