import React, { FC } from 'react'

interface GpsProps {
  lat: number | undefined
  lng: number | undefined
}

export const Gps: FC<GpsProps> = ({ lat, lng }) => {
  return (
    <>
      {!lat && !lng ? (
        <>-</>
      ) : !lat || !lng ? (
        <>
          {lat || '-'}, {lng || '-'}
        </>
      ) : (
        <a href={`https://www.google.com/maps?q=${lat},${lng}`} target="_blank" rel="noreferrer">
          {lat.toFixed(4)}, {lng.toFixed(4)}
        </a>
      )}
    </>
  )
}
