import { replaceLtSysColumns, setLtSysColumnsLoading } from '../reducers'
import { apiGetLtSysColumns } from '../api/leave-type.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchLtSysColumns = (id: string, options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().leave.ltSysColumnsLoading[id]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().leave.ltSysColumns[id]?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setLtSysColumnsLoading({ leaveTypeId: id, loading: true }))
      const { result, status } = await apiGetLtSysColumns(id)
      if (status) {
        dispatch(replaceLtSysColumns({ leaveTypeId: id, data: result }))
      }
    } finally {
      dispatch(setLtSysColumnsLoading({ leaveTypeId: id, loading: false }))
    }
  }
}
