import { replaceMyClaimTasks, setMyClaimTasksLoading } from '../reducers'
import { apiGetMyClaimTasks } from '../api/ss-claim-task.api'
import { ThunkResult } from '~/types/store'

export const fetchMyClaimTasks = (): ThunkResult<void> => {
  return async dispatch => {
    try {
      dispatch(setMyClaimTasksLoading(true))
      const { result, status } = await apiGetMyClaimTasks()
      if (status) dispatch(replaceMyClaimTasks(result))
    } finally {
      dispatch(setMyClaimTasksLoading(false))
    }
  }
}
