import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'

export const selectCurrentEmEmploymentType = createSelector(
  (state: StoreState) => state.employee.emEmploymentTypes,
  emEmploymentTypesState =>
    memoize(
      (employeeId?: string, id?: string) => {
        if (!employeeId || !id) return undefined

        return emEmploymentTypesState[employeeId]?.entities[id]
      },
      (employeeId?: string, id?: string) => `${employeeId}|${id}`
    )
)

export const selectEmEmploymentTypes = createSelector(
  (state: StoreState) => state.employee.emEmploymentTypes,
  emEmploymentTypesState =>
    memoize((employeeId?: string) => {
      if (!employeeId) return []

      return Object.values(emEmploymentTypesState[employeeId]?.entities || {}).sort((a, b) => {
        const aStartDate = new Date(a!.startDate).getTime()
        const bStartDate = new Date(b!.startDate).getTime()
        return bStartDate - aStartDate
      })
    })
)
