import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { apiDeleteFileEmDocument, apiUpdateEmDocument, apiUploadFileEmDocument } from '../api/em-document.api'
import { EmDocumentState, IEmDocumentInfo, IEmDocumentInfoUpdate } from '../types'
import { setEmDocument } from '../reducers'
import omit from 'lodash/omit'
import merge from 'lodash/merge'

export const updateEmDocument = <T extends IEmDocumentInfo>(
  employeeId: string,
  id: string,
  before: T,
  request: T
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!employeeId || !id) return

    const before2: IEmDocumentInfoUpdate = omit({ ...before }, 'file', 'docFileId')
    const after: IEmDocumentInfoUpdate = omit({ ...before, ...request } as T, 'file', 'docFileId')
    const patch = generateJsonPatch<IEmDocumentInfoUpdate>(before2, after)
    let { status, errors, message, errorData } = await apiUpdateEmDocument(employeeId, id, patch)

    if (status) {
      const emDocument = getState().employee.emDocuments[employeeId]?.entities[id]
      let newEmDocument = { ...(emDocument as EmDocumentState), ...after }
      let messageType: 'success' | 'error' | 'none' = 'success'
      let message = 'Saved'

      if (request.file) {
        const {
          result: uploadResult,
          status: uploadStatus,
          errors: uploadErrors
        } = await apiUploadFileEmDocument(employeeId, id, request.file)
        if (uploadStatus) {
          const { docFileId, docFileName, docFileSize, docFileFormat, docFileExtension } = uploadResult
          newEmDocument = {
            ...(newEmDocument as EmDocumentState),
            docFileId,
            docFileName,
            docFileSize,
            docFileFormat,
            docFileExtension
          }
        } else {
          if (Object.keys(uploadErrors).length === 0) {
            messageType = 'error'
            message = 'An error has occurred while uploading file'
          } else {
            messageType = 'none'
            errors = merge(errors, uploadErrors)
          }
        }
      } else {
        if (before.docFileId) {
          const { status: deleteStatus, errors: deleteErrors } = await apiDeleteFileEmDocument(employeeId, id)
          if (deleteStatus) {
            newEmDocument = {
              ...(newEmDocument as EmDocumentState),
              docFileId: undefined,
              docFileName: undefined,
              docFileSize: undefined,
              docFileFormat: undefined,
              docFileExtension: undefined
            }
          } else {
            if (Object.keys(deleteErrors).length === 0) {
              messageType = 'error'
              message = 'An error has occurred while deleting file'
            } else {
              messageType = 'none'
              errors = merge(errors, deleteErrors)
            }
          }
        }
      }

      dispatch(setEmDocument({ employeeId, data: newEmDocument }))
      if (messageType === 'success') {
        showSuccess(message)
      } else if (messageType === 'error') {
        showError(message, errorData)
      }
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
