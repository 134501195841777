import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { Ir8sFormState } from '../types'

export const selectIr8sForm = createSelector(
  (state: StoreState) => state.tax.ir8sForm,
  ir8sFormState =>
    memoize(
      (ytdSubmissionId: string, employeeTaxNo: string): Ir8sFormState | undefined => {
        const form = ir8sFormState?.entities[employeeTaxNo]
        if (form?.ytdSubmissionId === ytdSubmissionId) return form
      },
      (ytdSubmissionId: string, employeeTaxNo: string) => `${ytdSubmissionId}|${employeeTaxNo}`
    )
)
