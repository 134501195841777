import React, { FC, useCallback, useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Button, PageHeader } from '~/core-components'
import { Col, DocumentTitle, Row } from '~/components'
import confirm from 'antd/lib/modal/confirm'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { dispatch } from '~/stores/store'
import { ActionResult } from '~/types/store'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { MenuItem } from '~/types/common'
import { useMasterMenu } from '../MasterMenuContext'
import { deleteCompany, fetchOffices, fetchCompanyOffices, fetchEmpKeyvalues } from '../../actions'
import { useCompanies, useCompany } from '../../hooks'
import { commonMenus } from '../../configs'
import { CompanyInfo } from './components/CompanyInfo'
import { CompanyOffices } from './components/CompanyOffices'
import { BankInfo } from './components/BankInfo'
import { AuthorisedPerson } from './components/AuthorisedPerson'
import { Statutory } from './components/Statutory'
import { CompanyOrgChart } from './components/CompanyOrgChart'
import { CompanyLogo } from './components/CompanyLogo'
import { AddCompanyDrawer } from './components/AddCompanyDrawer'
import './Company.less'

interface CompanyProps {}

interface CompanyParams {
  id: string
}

interface DrawerState {
  visible: boolean
}
const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: `${SETTINGS_ROUTES.companies}?force=1`,
    breadcrumbName: 'Overview'
  },
  {
    path: '',
    breadcrumbName: 'Company'
  }
]

export const Company: FC<CompanyProps> = () => {
  const { id } = useParams<CompanyParams>()
  const [companies] = useCompanies()
  const [company] = useCompany(id)
  const [editing, setEditing] = useState(false)
  const history = useHistory()
  const { setMenus } = useMasterMenu()
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const canAdd = usePermissionGate(Permission.payMaster, PermissionAction.Add)
  const canDelete = usePermissionGate(Permission.company, PermissionAction.Delete)

  useEffect(() => {
    dispatch(fetchOffices({ strategy: 'when-empty' }))
    dispatch(fetchEmpKeyvalues('country', undefined, undefined, { strategy: 'when-empty' }))
  }, [])

  useEffect(() => {
    if (id) {
      dispatch(fetchCompanyOffices(id))
    }
  }, [id])

  const handleAddCompany = useCallback(() => {
    setDrawerState({ visible: true })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  useEffect(() => {
    const menus: MenuItem[] = Object.values(companies).map(co => ({
      path: SETTINGS_ROUTES.company.replace(':id', co!.id),
      value: co!.name,
      sysPermissionId: Permission.company
    }))

    if (canAdd)
      menus.push({
        path: (
          <Button type="dashed" onClick={handleAddCompany} block>
            Add company
          </Button>
        ),
        value: '',
        sysPermissionId: ''
      })

    setMenus(menus)
    return () => {
      setMenus(commonMenus)
    }
  }, [setMenus, companies, canAdd, handleAddCompany])

  const handleEdit = useCallback(() => setEditing(true), [])
  const handleView = useCallback(() => setEditing(false), [])

  const handleDelete = useCallback(() => {
    if (id && company) {
      confirm({
        title: 'Delete company',
        content: `Do you want to delete company "${company?.name}"?`,
        onOk: async () => {
          const result: ActionResult | undefined = await dispatch(deleteCompany(id))
          if (!result?.errors) {
            history.push(SETTINGS_ROUTES.companies)
          }
        },
        okText: 'Delete',
        okType: 'danger'
      })
    }
  }, [id, company, history])

  return (
    <div id="company" className="company">
      <DocumentTitle title="Company" />
      <PageHeader
        title="Company"
        containerId="company"
        breadcrumb={{ routes }}
        extra={canDelete && editing && <Button onClick={handleDelete}>Delete</Button>}
      />
      <div className="company__body">
        <Row>
          <Col flex="1">
            <CompanyInfo company={company} onEdit={handleEdit} onSave={handleView} onCancel={handleView} />
          </Col>
          <Col>
            <CompanyLogo company={company} />
          </Col>
        </Row>
        <CompanyOffices companyId={id} />
        <CompanyOrgChart company={company} onEdit={handleEdit} onSave={handleView} onCancel={handleView} />
        <AuthorisedPerson company={company} onEdit={handleEdit} onSave={handleView} onCancel={handleView} />
        <BankInfo company={company} onEdit={handleEdit} onSave={handleView} onCancel={handleView} />
        <Statutory company={company} onEdit={handleEdit} onSave={handleView} onCancel={handleView} />
        {canAdd && <AddCompanyDrawer {...drawerState} onClose={handleCloseDrawer} />}
      </div>
    </div>
  )
}
