import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { LocationAdminState } from '../types'

export const selectLocationAdmins = createSelector(
  (state: StoreState) => state.attendance.locationAdmins,
  locationAdminsState =>
    memoize((locationId: string) => {
      return (Object.values(locationAdminsState[locationId]?.entities || {}) as LocationAdminState[]) || []
    })
)
