import { removeMyLeaveRecordComment } from '../../reducers'
import { showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiDeleteMyLeaveRecordComment } from '../../api/ssleave-record-comment.api'

export const deleteLeaveRecordComment = (leaveRecordId: string, id: string): ThunkResult<void> => {
  return async dispatch => {
    if (!leaveRecordId || !id) return

    const { status, errors, message, errorData } = await apiDeleteMyLeaveRecordComment(leaveRecordId, id)
    if (status) dispatch(removeMyLeaveRecordComment({ leaveRecordId, id }))
    else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
