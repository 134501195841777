import React, { FC, Ref, forwardRef, useCallback } from 'react'
import { default as AntInputNumber, InputNumberProps as AntInputNumberProps } from 'antd/lib/input-number'
import type { ValueType } from 'rc-input-number/lib/utils/MiniDecimal'
import 'antd/es/input-number/style'
import './Number.less'

export interface NumberProps<T extends ValueType = number> extends AntInputNumberProps<T> {
  ref?: Ref<HTMLInputElement>
}

const NumberInternal = <T extends ValueType = number>(
  { onChange, ...props }: NumberProps<T>,
  ref?: Ref<HTMLInputElement>
) => {
  const handleOnChange = useCallback(
    (value: T | null) => {
      typeof onChange === 'function' && onChange(value)
    },
    [onChange]
  )
  return <AntInputNumber<T> onChange={handleOnChange} {...props} ref={ref} />
}

interface WithForwardRefType<T extends ValueType = number> extends FC<NumberProps<T>> {
  <T extends ValueType = number>(props: NumberProps<T>): ReturnType<FC<NumberProps<T>>>
}

export const Number: WithForwardRefType<ValueType> = forwardRef<HTMLInputElement, NumberProps<ValueType>>(
  NumberInternal<ValueType>
)
