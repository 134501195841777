import { setEmIdentity, setEmIdentitiesLoading } from '../reducers'
import { apiGetEmIdentity } from '../api/em-identity.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchEmIdentity = (
  employeeId: string,
  emIdentityId: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!employeeId || !emIdentityId) return

    const loading = getState().employee.emIdentitiesLoading[employeeId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().employee.emIdentities[employeeId]?.entities[emIdentityId]
      if (hasData) return
    }

    try {
      dispatch(setEmIdentitiesLoading({ employeeId: employeeId, loading: true }))
      const { result, status } = await apiGetEmIdentity(employeeId, emIdentityId)
      if (status) {
        dispatch(setEmIdentity({ employeeId, data: result }))
      }
    } finally {
      dispatch(setEmIdentitiesLoading({ employeeId: employeeId, loading: false }))
    }
  }
}
