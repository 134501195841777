import React, { FC, useState, useCallback, useEffect, useMemo, ChangeEvent } from 'react'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment-timezone'
import confirm from 'antd/lib/modal/confirm'
import { Button, Checkbox, CheckboxChangeEvent, Form, Input } from '~/core-components'
import { Col, DrawerForm, Row, SqlEditor } from '~/components'
import { useFocus } from '~/hooks'
import { isInactive } from '~/utils'
import { ActionResult, Errors } from '~/types/store'
import { dispatch } from '~/stores/store'
import { deleteDailyPolicyRow, saveDailyPolicyRow, validateDailyPolicyRow } from '../../../actions'
import { useSysDailyPolicyExpressionsSuggestions } from '../../../hooks'
import { DailyPolicyRowState, ISaveDailyPolicyRow } from '../../../types'
import { SelectDailyPolicyField } from './SelectDailyPolicyField'

interface MutateDailyPolicyRowDrawerProps {
  visible: boolean
  section: string
  dailyPolicyId: string
  data?: DailyPolicyRowState
  onClose: () => void
}

const EMPTY_FORM_DATA: ISaveDailyPolicyRow = {
  id: '',
  dailyPolicyId: '',
  section: '',
  sequence: 0,
  name: '',
  fieldName: '',
  expression: '',
  criteriaExpr: ''
}

export const MutateDailyPolicyRowDrawer: FC<MutateDailyPolicyRowDrawerProps> = ({
  visible,
  section,
  dailyPolicyId,
  data,
  onClose
}) => {
  const formId = useMemo(() => `form-daily-policy-row-${uuidv4()}`, [])
  const [saving, setSaving] = useState(false)
  const [validating, setValidating] = useState(false)
  const [formData, setFormData] = useState<ISaveDailyPolicyRow>(EMPTY_FORM_DATA)
  const [focusRef, setFocus] = useFocus(true)
  const [errors, setErrors] = useState<Errors>()
  const [suggestions] = useSysDailyPolicyExpressionsSuggestions()

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  useEffect(() => {
    if (data) {
      const { dailyPolicyId, id, section, sequence, name, fieldName, expression, criteriaExpr, inactiveDate } = data
      setFormData({
        dailyPolicyId,
        id,
        section,
        sequence,
        name,
        fieldName,
        expression,
        criteriaExpr,
        inactiveDate
      })
    } else {
      setFormData({ ...EMPTY_FORM_DATA, section, dailyPolicyId })
    }
  }, [dailyPolicyId, section, data])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setErrors(undefined)
    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setSaving(true)
    try {
      result = await dispatch(saveDailyPolicyRow(formData.dailyPolicyId, formData))
    } finally {
      setSaving(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_FORM_DATA)
    }
  }, [formData, onClose])

  const handleDelete = useCallback(
    (row: DailyPolicyRowState | undefined) => {
      if (row) {
        const { id, name, dailyPolicyId } = row
        confirm({
          title: 'Delete item',
          content: `Do you want to delete this item "${name}"?`,
          onOk: async () => {
            const result: ActionResult | undefined = await dispatch(deleteDailyPolicyRow(dailyPolicyId, id))
            if (result?.errors) {
              setErrors(result.errors)
            }
            if (!result?.errors) {
              typeof onClose === 'function' && onClose()
            }
          },
          okText: 'Delete',
          okType: 'danger'
        })
      }
    },
    [onClose]
  )

  const handleValidate = useCallback(async () => {
    setValidating(true)
    try {
      await dispatch(validateDailyPolicyRow(formData.fieldName, formData.expression, formData.criteriaExpr))
    } finally {
      setValidating(false)
    }
  }, [formData])

  return (
    <DrawerForm
      open={visible}
      title={data ? 'Edit policy item' : 'Add policy item'}
      onClose={onClose}
      confirmLoading={saving}
      width={800}
      className="mutate-daily-policy-row"
      showDelete={data ? true : false}
      onDelete={() => handleDelete(data)}
      extras={
        <Button onClick={handleValidate} loading={validating}>
          Validate
        </Button>
      }
      formId={formId}
    >
      <Form id={formId} onFinish={handleOk}>
        <Row gutter={30}>
          <Col flex="auto">
            <Form.Item label="Name" validateStatus={errors?.name ? 'error' : ''} help={errors?.name}>
              <Input
                ref={focusRef}
                value={formData.name}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ name: event.target.value })}
              />
            </Form.Item>
          </Col>
          <Col flex="none">
            <Form.Item
              label="Inactive"
              validateStatus={errors?.inactiveDate ? 'error' : ''}
              help={errors?.inactiveDate}
            >
              <Checkbox
                checked={isInactive(formData.inactiveDate)}
                onChange={(event: CheckboxChangeEvent) =>
                  handleFormDataChange({ inactiveDate: event.target.checked ? moment().format('YYYY-MM-DD') : '' })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={15}>
          <Col span={7}>
            <Form.Item label="Field" validateStatus={errors?.fieldName ? 'error' : ''} help={errors?.fieldName}>
              <Row gutter={15}>
                <Col flex="auto">
                  <SelectDailyPolicyField
                    style={{ width: 185 }}
                    value={formData.fieldName}
                    onChange={(fieldName: string) => handleFormDataChange({ fieldName })}
                  />
                </Col>
                <Col flex="15px">=</Col>
              </Row>
            </Form.Item>
          </Col>
          <Col span={17}>
            <Form.Item label="Expression" validateStatus={errors?.expression ? 'error' : ''} help={errors?.expression}>
              <SqlEditor
                height="64px"
                suggestions={suggestions}
                value={formData.expression}
                onChange={(expression: string) => handleFormDataChange({ expression })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Criteria"
              validateStatus={errors?.criteriaExpr ? 'error' : ''}
              help={errors?.criteriaExpr}
            >
              <SqlEditor
                suggestions={suggestions}
                value={formData.criteriaExpr}
                onChange={(criteriaExpr: string) => handleFormDataChange({ criteriaExpr })}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}
