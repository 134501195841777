import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { CompanyOfficeState, OfficeState, OfficeExtraState, IOfficeInfo } from '../types'
import { EmPreviewState } from '~/components'

const baseUrl = getBaseUrl('/employee/office')

export const apiGetOffices = () => request<OfficeState[]>('get', baseUrl)

export const apiGetOfficesExtra = () => request<OfficeExtraState[]>('get', `${baseUrl}/extra`)

export const apiGetOffice = (id: string) => request<OfficeState>('get', `${baseUrl}/${id}`)

export const apiAddOffice = (office: IOfficeInfo) => request<{ id: string }>('post', baseUrl, office)

export const apiDeleteOffice = (id: string) => request<void>('delete', `${baseUrl}/${id}`)

export const apiUpdateOffice = (id: string, office: Operation[]) => request<void>('patch', `${baseUrl}/${id}`, office)

export const apiGetOfficeCompanies = (officeId: string) =>
  request<CompanyOfficeState[]>('get', `${baseUrl}/${officeId}/company`)

export const apiGetOfficeEmployees = (officeId: string) =>
  request<EmPreviewState[]>('get', `${baseUrl}/${officeId}/employees`)
