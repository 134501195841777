import { request, getBaseUrl } from '~/utils/request'
import { IUpdateTenantConfigs } from '../types'

const baseUrl = getBaseUrl('/oauth2/tenantconfig')

export const apiGetTenantConfigs = () => request('get', baseUrl)

export const apiUpdateTenantConfigs = (tenantConfigs: IUpdateTenantConfigs[]) =>
  request('post', baseUrl, { tenantConfigs })

export const apiGetTenantConfigOptions = (code: string) => request('get', `${baseUrl}/options/${code}`)
