import { LeaveGrantBulkFormData } from '../containers/LeaveGrantBulk/LeaveGrantBulkDrawer'

export interface LeaveGrantBulkState {
  source: LeaveGrantBulkRowState[]
}

export interface LeaveGrantBulkVerifyState {
  data: LeaveGrantBulkRowState[]
}

export interface LeaveGrantBulkRowState {
  employeeId: string
  employeeNo: string
  employeeName: string
  leaveTypeId: string
  leaveTypeName: string
  startDate: string
  endDate: string
  earned: number
  unit: string
  notes: string
}

export interface ILeaveGrantBulk {
  leaveTypeId: string
  startDate: string
  endDate: string
  earned: number
  notes: string
  asAtDate: string
  emStatus: string
  isIncludeProbation: boolean
}

export interface ILeaveGrantBulkVerify {
  leaveTypeId: string
  startDate: string
  endDate: string
  earned: number
  notes: string
  employeeIds: string[]
}

export const mapLeaveGrantBulkToILeaveGrantBulk = (formData: LeaveGrantBulkFormData): ILeaveGrantBulk => {
  return {
    leaveTypeId: formData.leaveTypeId,
    startDate: formData.startDate,
    endDate: formData.endDate,
    earned: formData.earned,
    notes: formData.notes,
    asAtDate: formData.asAtDate,
    emStatus: formData.emStatus,
    isIncludeProbation: formData.isIncludeProbation
  }
}

export const mapLeaveGrantBulkToILeaveGrantBulkVerify = (formData: LeaveGrantBulkFormData): ILeaveGrantBulkVerify => {
  const ids = formData.selected.map(s => s.employeeId)

  return {
    leaveTypeId: formData.leaveTypeId,
    startDate: formData.startDate,
    endDate: formData.endDate,
    earned: formData.earned,
    notes: formData.notes,
    employeeIds: ids
  }
}
