import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchLeavePeriods } from '../actions'
import { selectLeavePeriods } from '../selectors'
import { LeavePeriodState } from '../types'

export const useLeavePeriods = (): [LeavePeriodState[], boolean] => {
  const leavePeriods = useSelector(selectLeavePeriods)
  const loading = useSelector((state: StoreState) => state.leave.leavePeriodsLoading)

  useEffect(() => {
    dispatch(fetchLeavePeriods())
  }, [])

  return [leavePeriods, loading]
}
