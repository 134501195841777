import React, { FC, useCallback, useState } from 'react'
import { Card, ColumnsType, Link, PageHeader, Space, Table, Tag } from '~/core-components'
import { DocumentTitle } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { Permission, PermissionAction } from '~/constants'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { useTeConfigs } from '../../hooks'
import { TeConfigState } from '../../types'
import { EditTeConfigDrawer } from './EditTeConfigDrawer'
import './TeConfigs.less'

type TeConfigTable = TeConfigState

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: '',
    breadcrumbName: 'Overview'
  }
]

interface DrawerState {
  visible: boolean
  data?: TeConfigState
}
const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

interface TeConfigsProps {}

export const TeConfigs: FC<TeConfigsProps> = () => {
  const [teConfigs, loading] = useTeConfigs()
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const canModify = usePermissionGate(Permission.teConfig, PermissionAction.Modify)

  const handleEditTeConfig = useCallback((teConfig: TeConfigState) => {
    setDrawerState({ visible: true, data: teConfig })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const columns: ColumnsType<TeConfigTable> = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name'
    },
    {
      title: 'Shown in',
      key: 'isShown',
      dataIndex: 'isShown',
      render: (value: boolean, record) => (
        <Space>
          {record.isShownInDaily && <Tag>daily record</Tag>}
          {record.isShownInShift && <Tag>shift</Tag>}
          {record.isShownInShiftRole && <Tag>shift role</Tag>}
        </Space>
      )
    },
    {
      key: 'action',
      align: 'right',
      render: (value: string, record) =>
        canModify && (
          <Link size="small" onClick={() => handleEditTeConfig(record)}>
            edit
          </Link>
        )
    }
  ]

  return (
    <div id="te-configs" className="te-configs">
      <DocumentTitle title="Time Elements" />
      <PageHeader title="Time elements" containerId="te-configs" breadcrumb={{ routes }} />
      <div className="te-configs__body">
        <Card table>
          <Table
            rowKey="code"
            dataSource={teConfigs}
            pagination={false}
            columns={columns}
            loading={loading}
            rowClassName={record =>
              record.isShownInDaily || record.isShownInShift || record.isShownInShiftRole
                ? 'te-configs__table-row--shown'
                : ''
            }
          />
        </Card>
      </div>
      {canModify && <EditTeConfigDrawer {...drawerState} onClose={handleCloseDrawer} />}
    </div>
  )
}
