import { request, getBaseUrl, timeout } from '~/utils/request'
import { EmSelectionViewState } from '~/features/employee'
import { Pagination, Search } from '~/types/common'
import { IRptBankFile } from '../types'

const baseUrl = getBaseUrl('/payroll-sg/bankfile')

export const apiGetBankFile = (payRunId: string, params: IRptBankFile) =>
  request('post', `${baseUrl}/download`, { payRunId, ...params }, { responseType: 'blob', timeout })

export const apiGetSysBankFiles = () => request('get', `${baseUrl}/bank`)

export const apiGetBankFileEmSelections = (payRunid: string, viewId: string, params: Pagination & Search) =>
  request<EmSelectionViewState>('get', `${baseUrl}/${payRunid}/emselections/${viewId}`, params)
