import { ViewCriteria } from './view.state'
import { ICriteriaKeyValue } from './view.dto'
import { criteriaDelimiter, emptyGuid } from '~/constants'

export const mapViewCriteriaToCriteriaKeyValue = (viewCriteria: ViewCriteria[]) => {
  return viewCriteria.reduce((acc: ICriteriaKeyValue, current: ViewCriteria) => {
    if (current.criteriaFieldId) {
      acc[current.criteriaFieldId] = current.value ? current.value.split(criteriaDelimiter) : []
    }
    return acc
  }, {})
}

export const mapCriteriaKeyValueToViewCriteria = (criteriaValues: ICriteriaKeyValue, existing?: ViewCriteria[]) => {
  let root = existing?.filter(c => c.parentId == null) || []
  if (root.length === 0) {
    throw Error("View criteria doesn't have a valid root")
    // root = [{ operator: 'and', criteriaType: 'group', sequence: 0 }]
  }

  let viewCriteria: ViewCriteria[] = root
  if (criteriaValues) {
    Object.entries(criteriaValues).forEach(([fieldId, values]) => {
      const found = existing?.filter(d => d.criteriaFieldId === fieldId) || []
      const value = values.join(criteriaDelimiter)
      if (found.length > 0) {
        const { id, parentId, criteriaType, criteriaFieldId, operator, valueTo, sequence } = found[0]
        viewCriteria.push({
          id,
          parentId,
          criteriaType,
          criteriaFieldId,
          operator,
          value,
          valueTo,
          sequence
        })
      } else {
        viewCriteria.push({
          id: emptyGuid,
          parentId: root[0].id,
          criteriaType: 'criteria',
          criteriaFieldId: fieldId,
          operator: 'in',
          value,
          valueTo: undefined,
          sequence: 0
        })
      }
    })
  }
  return viewCriteria
}
