import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { LeaveRecordApprovalHistoryState } from '../types'

export const selectLeaveRecordApprovalHistories = createSelector(
  (state: StoreState) => state.leave.leaveRecordApprovalHistories,
  leaveRecordApprovalHistoriesState =>
    memoize((leaveRecordId: string) => {
      return Object.values(
        leaveRecordApprovalHistoriesState[leaveRecordId]?.entities || {}
      ) as LeaveRecordApprovalHistoryState[]
    })
)
