import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { ColumnsType, Form, Link, LinkGroup, Space, Table, Tag } from '~/core-components'
import { Col, EmpKeyValues, InactiveTag, Row } from '~/components'
import { selectEmploymentTypeKeyvalues } from '~/features/master'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { useToggle } from '~/hooks/use-toggle'
import { useFirstInView } from '~/hooks/use-first-in-view'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { isInactive } from '~/utils'
import { MutateEmEmploymentTypeDrawer } from './MutateEmEmploymentTypeDrawer'
import { IEmEmploymentTypeInfo, EmEmploymentTypeState } from '../../../types'
import { fetchEmEmploymentTypes } from '../../../actions'
import { selectCurrentEmEmploymentType, selectEmEmploymentTypes } from '../../../selectors'

interface EmEmploymentTypesProps {
  employeeId?: string
  id?: string
}

type EmEmploymentTypeTable = EmEmploymentTypeState

const EMPTY_FORM_DATA: IEmEmploymentTypeInfo = {
  startDate: '',
  employmentTypeId: ''
}

interface DrawerState {
  visible: boolean
  employeeId?: string
  data?: EmEmploymentTypeState
  isCurrent: boolean
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false, isCurrent: false }

const formStyle: CSSProperties = { padding: '0 24px' }
const linkGroupStyle: CSSProperties = { position: 'absolute', top: 0, right: 0 }

export const EmEmploymentTypes: FC<EmEmploymentTypesProps> = ({ employeeId, id }: EmEmploymentTypesProps) => {
  const { ref, inView } = useFirstInView<HTMLDivElement>({ threshold: 0.25 })
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const [formData, setFormData] = useState<IEmEmploymentTypeInfo>(EMPTY_FORM_DATA)
  const current = useSelector(selectCurrentEmEmploymentType)(employeeId, id)
  const histories = useSelector(selectEmEmploymentTypes)(employeeId)
  const employmentTypes = useSelector(selectEmploymentTypeKeyvalues)
  const [showHistory, toggleShowHistory] = useToggle()
  const canModify = usePermissionGate(Permission.employeeCareer, PermissionAction.Modify)
  const loading = useSelector((state: StoreState) => state.employee.emEmploymentTypesLoading[employeeId || ''])

  useEffect(() => {
    if (inView) {
      if (employeeId) {
        dispatch(fetchEmEmploymentTypes(employeeId))
      }
    }
  }, [inView, employeeId])

  useEffect(() => {
    if (showHistory && histories.length === 0) {
      toggleShowHistory()
    }
  }, [histories.length, showHistory, toggleShowHistory])

  useEffect(() => {
    if (current) {
      const { startDate, employmentTypeId } = current
      setFormData({ startDate, employmentTypeId })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [current])

  const handleEditEmploymentType = useCallback(
    (emEmploymentType?: EmEmploymentTypeState) => {
      setDrawerState({ visible: true, employeeId, data: emEmploymentType, isCurrent: id === emEmploymentType?.id })
    },
    [employeeId, id, setDrawerState]
  )

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const columns: ColumnsType<EmEmploymentTypeTable> = [
    {
      title: 'Effective date',
      key: 'startDate',
      dataIndex: 'startDate',
      width: 130,
      render: (value: string) => value && moment(value).format('DD MMM YYYY')
    },
    {
      title: 'Employment type',
      key: 'employmentTypeId',
      dataIndex: 'employmentTypeId',
      render: (value: string, record) =>
        value && (
          <Space>
            {employmentTypes[value]?.value}
            {record.id === id && <Tag type="primary">current</Tag>}
            {isInactive(employmentTypes[value]?.inactiveDate) && <InactiveTag />}
          </Space>
        )
    },
    {
      key: 'action',
      align: 'right',
      render: (value: string, record) =>
        canModify && (
          <Link size="small" onClick={() => handleEditEmploymentType(record)}>
            edit
          </Link>
        )
    }
  ]

  return (
    <div ref={ref}>
      <Form style={formStyle}>
        <Row hidden={showHistory}>
          <Col span={24}>
            <Form.Item
              className="form-item-label--block"
              label={
                <>
                  <span>Employment type</span>
                  {!loading && (
                    <LinkGroup style={linkGroupStyle}>
                      {canModify && (
                        <Link size="small" onClick={() => handleEditEmploymentType(current)}>
                          {current ? 'edit' : 'add'}
                        </Link>
                      )}
                      {(histories?.length || 0) > 0 && (
                        <Link size="small" onClick={toggleShowHistory}>
                          {showHistory ? 'hide history' : 'history'}
                        </Link>
                      )}
                    </LinkGroup>
                  )}
                </>
              }
            >
              <EmpKeyValues id="employmentType" value={formData.employmentTypeId} readOnly />
            </Form.Item>
          </Col>
        </Row>
        {showHistory && (
          <Row>
            <Col span={24}>
              <Form.Item
                className="form-item-label--block"
                label={
                  <>
                    <span>Employment type</span>
                    <LinkGroup style={linkGroupStyle}>
                      <Link size="small" onClick={toggleShowHistory}>
                        {showHistory ? 'hide history' : 'history'}
                      </Link>
                    </LinkGroup>
                  </>
                }
              >
                <Table rowKey="id" dataSource={histories} pagination={false} columns={columns} />
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
      {canModify && <MutateEmEmploymentTypeDrawer {...drawerState} onClose={handleCloseDrawer} />}
    </div>
  )
}
