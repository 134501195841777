import { registerApp } from './features/micro-app/reducers'
import { AppMethods } from './features/micro-app/types'
import { dispatch } from './stores/store'

if (window.Zealys === undefined) {
  window.Zealys = {
    registerMicroApp: (name: string, app: AppMethods) => {
      dispatch(registerApp({ name, app }))
    }
  }
} else {
  if (typeof window.Zealys !== 'object') {
    console.log("window.Zealys is defined but it is not an object. Zealys can't start.")
  }
}
