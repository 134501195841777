import { request, getBaseUrl } from '~/utils/request'
import { LeaveBuddyState, ILeaveBuddy, LeaveBuddyEmState } from '../types'
import { Pagination, Search } from '~/types/common'
import { EmLeaveBuddyState, EmSelectionViewState, IEmLeaveBuddy } from '~/features/employee'

const baseUrl = getBaseUrl('/leave/leavebuddy')

export const apiGetLeaveBuddies = () => request<LeaveBuddyState[]>('get', baseUrl)

export const apiGetLeaveBuddy = (id: string) => request<LeaveBuddyState>('get', `${baseUrl}/${id}`)

export const apiSaveLeaveBuddy = (leaveBuddy: ILeaveBuddy) =>
  request<{ id: string } | undefined>('post', `${baseUrl}`, leaveBuddy)

export const apiDeleteLeaveBuddy = (id: string) => request<void>('delete', `${baseUrl}/${id}`)

export const apiGetLeaveBuddyEm = (leaveBuddyId: string) =>
  request<LeaveBuddyEmState[]>('get', `${baseUrl}/${leaveBuddyId}/employee`)

export const apiGetLeaveBuddyEmByEmployee = (employeeId: string) =>
  request<EmLeaveBuddyState[]>('get', `${baseUrl}/employee/${employeeId}`)

export const apiUpdateLeaveBuddyEmByEmployee = (employeeId: string, data: IEmLeaveBuddy) =>
  request<EmLeaveBuddyState[]>('post', `${baseUrl}/employee/${employeeId}`, data)

export const apiGetLeaveBuddyEmSelections = (viewId: string, params: Pagination & Search) =>
  request<EmSelectionViewState>('get', `${baseUrl}/emselections/${viewId}`, params)
