import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { IEmStatutoryExempt } from '../types'

const baseUrl = getBaseUrl('/employee/employee')

export const apiGetEmStatutoryExempts = (employeeId: string) =>
  request<IEmStatutoryExempt[]>('get', `${baseUrl}/${employeeId}/statutoryexempt`)

export const apiAddEmStatutoryExempt = (employeeId: string, emStatutoryExempt: IEmStatutoryExempt) =>
  request<{ id: string }>('post', `${baseUrl}/${employeeId}/statutoryexempt`, emStatutoryExempt)

export const apiUpdateEmStatutoryExempt = (employeeId: string, id: string, emStatutoryExempt: Operation[]) =>
  request<void>('patch', `${baseUrl}/${employeeId}/statutoryexempt/${id}`, emStatutoryExempt)

export const apiDeleteEmStatutoryExempt = (employeeId: string, id: string) =>
  request<void>('delete', `${baseUrl}/${employeeId}/statutoryexempt/${id}`)
