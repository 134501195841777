import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { PayRecordSgState } from '../types'

export const selectPayRecord = createSelector(
  (state: StoreState) => state.payroll.payRecords,
  payRecordsState =>
    memoize(
      (payRunId: string, employeeId: string): PayRecordSgState | undefined => {
        return (Object.values(payRecordsState[payRunId]?.entities || {}) as PayRecordSgState[]).find(
          rec => rec.employeeId === employeeId && rec.payRunId === payRunId
        )
      },
      (payRunId: string, employeeId: string) => `${payRunId}|${employeeId}`
    )
)
