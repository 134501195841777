import React, { FC, useState, useCallback, useEffect, ChangeEvent } from 'react'
import { Form, Input, Switch } from '~/core-components'
import { Col, DrawerForm, Row } from '~/components'
import { useFocus } from '~/hooks/use-focus'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors } from '~/types/store'
import { IOtConfig, OtConfigState, mapOtConfigStateToOtConfigInfo } from '../../types'
import { updateOtConfig } from '../../actions'

export interface EditOtConfigDrawerProps {
  visible: boolean
  data?: OtConfigState
  onClose: () => void
}

const EMPTY_FORM_DATA: IOtConfig = {
  name: '',
  isShown: false
}

export const EditOtConfigDrawer: FC<EditOtConfigDrawerProps> = ({ visible, data, onClose }) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<IOtConfig>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const [focusRef, setFocus] = useFocus(true)

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  useEffect(() => {
    if (data) {
      const { name, isShown } = data
      setFormData({ name, isShown })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      const updated = { ...formData, ...updates }
      setFormData(updated)
    },
    [formData]
  )

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      if (data) {
        result = await dispatch(updateOtConfig(data.code, mapOtConfigStateToOtConfigInfo(data), formData))
      }
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data, formData, onClose])

  return (
    <DrawerForm
      open={visible}
      title="Overtime field"
      onClose={onClose}
      confirmLoading={loading}
      width={500}
      formId="form-ot-config"
    >
      <Form id="form-ot-config" onFinish={handleOk}>
        <Row>
          <Col span={24}>
            <Form.Item label="Name" validateStatus={errors?.name ? 'error' : ''} help={errors?.name}>
              <Input
                ref={focusRef}
                value={formData.name}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ name: event.target.value })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item>
              <label>Visible</label>
              <Switch
                checkedChildren="Shown"
                unCheckedChildren="Hidden"
                checked={formData.isShown}
                onChange={(isShown: boolean) => {
                  handleFormDataChange({ isShown })
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}
