import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { EditOutlined, LoadingOutlined } from '@ant-design/icons'
import { Dropdown, SecondaryLink, Spin, UploadFile } from '~/core-components'
import { PersonAvatar, UploadPhotoModal } from '~/components'
import { fetchLogin, fetchMyLogin, deleteLoginPhoto, uploadLoginPhoto } from '~/features/iam/actions'
import { selectMyLogin } from '~/features/iam/selectors'
import { dispatch } from '~/stores/store'
import './ProfileAvatar.less'
import { getBaseUrl } from '~/utils'

const activeTenantCode = localStorage.getItem('tenant')

interface ProfileAvatarProps {
  loginId: string
  photoId?: string
  editable?: boolean
  size?: number
  mode?: 'simple' | 'drop-shadow'
}

const baseUrl = getBaseUrl('/oauth2')

export const ProfileAvatar: FC<ProfileAvatarProps> = ({ loginId, editable = false, size = 48, mode = 'simple' }) => {
  const [uploading, setUploading] = useState(false)
  const [uploadModal, setUploadModal] = useState(false)
  const myLogin = useSelector(selectMyLogin)
  const myLoginId = myLogin.id
  const myPhotoId = myLogin.photoId
  const photo = myPhotoId && `${baseUrl}/login/${loginId}/photo?id=${myPhotoId}`
  const thumbnailPhoto = myPhotoId && `${baseUrl}/login/${loginId}/photo/thumbnail/${size}?id=${myPhotoId}`

  useEffect(() => {
    if (activeTenantCode && myLoginId && loginId) {
      if (myLoginId === loginId) {
        dispatch(fetchMyLogin(activeTenantCode, { strategy: 'when-empty' }))
      } else {
        dispatch(fetchLogin(activeTenantCode, loginId, { strategy: 'when-empty' }))
      }
    }
  }, [myLoginId, loginId])

  const handleUploadChange = useCallback(
    async (file?: UploadFile) => {
      try {
        setUploading(true)
        if (file) {
          await dispatch(uploadLoginPhoto(loginId, file))
        }
      } finally {
        setUploading(false)
        setUploadModal(false)
      }
    },
    [loginId]
  )

  const handleShowUploadModal = useCallback(() => {
    setUploadModal(true)
  }, [])

  const handleCloseUploadModal = useCallback(() => {
    setUploadModal(false)
  }, [])

  const handleRemovePhoto = useCallback(async () => {
    try {
      setUploading(true)
      if (loginId) {
        await dispatch(deleteLoginPhoto(loginId))
      }
    } finally {
      setUploading(false)
    }
  }, [loginId])

  return (
    <div className="profile-avatar">
      <PersonAvatar photo={thumbnailPhoto} size={size} mode={mode} />
      {editable && (
        <Dropdown
          className="profile-avatar__uploader"
          menu={{
            items: [
              { key: 'change', label: 'Change profile photo', onClick: handleShowUploadModal },
              { key: 'remove', label: 'Remove', onClick: handleRemovePhoto, disabled: !myPhotoId }
            ]
          }}
        >
          {uploading ? (
            <Spin size="small" indicator={<LoadingOutlined spin />} />
          ) : (
            <SecondaryLink>
              <EditOutlined size={48} />
            </SecondaryLink>
          )}
        </Dropdown>
      )}
      <UploadPhotoModal
        title="Change profile photo"
        src={photo}
        open={uploadModal}
        uploading={uploading}
        onSave={handleUploadChange}
        onClose={handleCloseUploadModal}
      />
    </div>
  )
}
