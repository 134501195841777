import omit from 'lodash/omit'
import { fetchViewSchema, Screen } from '~/features/selection'
import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { ISsAccessConfigCriteria } from '../types'
import { replaceSsAccessConfigs } from '../reducers'
import { apiSaveSsAccessConfigs } from '../api/ss-access-config.api'

export const saveSsAccessConfigs = (request: ISsAccessConfigCriteria[]): ThunkResult<void> => {
  return async dispatch => {
    console.log('saveSsAccessConfigs', request)
    const { status, errors, message, errorData } = await apiSaveSsAccessConfigs(request)

    if (status) {
      dispatch(replaceSsAccessConfigs(request.map(x => omit(x, 'criteria'))))

      for (const item of request) {
        if (item.viewId) {
          dispatch(fetchViewSchema(`ss_access_${item.featureFlag}` as Screen, item.viewId))
        }
      }

      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
