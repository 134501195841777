import { replacePayRecords, setPayRecordLoading } from '../reducers'
import { apiGetPayRecordsSgView } from '../api/payrecord-sg.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions, Pagination } from '~/types/common'

export const fetchPayRecordsSgView = (
  viewId: string,
  payRunId: string,
  pagination: Pagination,
  search: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!viewId || !payRunId) return

    const loading = getState().payroll.payRecordsLoading[payRunId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().payroll.payRecords[payRunId]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setPayRecordLoading({ payRunId, loading: true }))
      const { result, status } = await apiGetPayRecordsSgView(viewId, payRunId, { ...pagination, search })
      if (status) dispatch(replacePayRecords({ payRunId, data: result }))
    } finally {
      dispatch(setPayRecordLoading({ payRunId, loading: false }))
    }
  }
}
