import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils'
import { CurrencyExchangeState, CurrencyState, ICurrency } from '../types'

const baseUrl = getBaseUrl('/claim/currency')

export const apiGetCurrencies = () => request<CurrencyState[]>('get', baseUrl)

export const apiAddCurrency = (currency: ICurrency) => request<{ code: string }>('post', baseUrl, currency)

export const apiUpdateCurrency = (code: string, currency: Operation[]) =>
  request('patch', `${baseUrl}/${code}`, currency)

export const apiDeleteCurrency = (code: string) => request('delete', `${baseUrl}/${code}`)

export const apiGetExchangeRate = (source: string, target: string, date: string) =>
  request<CurrencyExchangeState>('get', `${baseUrl}/exchange/${source}/${target}/${date}`)
