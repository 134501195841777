import {
  ICompanyInfo,
  ICompanyAuthPerson,
  ICompanyBank,
  ICompanyStatutory,
  ICompany,
  ICompanyOrgChart
} from './company.state'
import { CompanyState } from './company.state'

export const mapCompanyStateToCompanyInfo = (company: CompanyState): ICompanyInfo => ({
  name: company.name,
  registrationNo: company.registrationNo,
  incorporationDate: company.incorporationDate
})

export const mapCompanyStateToCompanyAuthPerson = (company: CompanyState): ICompanyAuthPerson => ({
  authName: company.authName,
  authDesignation: company.authDesignation,
  authEmail: company.authEmail,
  authPhone: company.authPhone
})

export const mapCompanyStateToCompanyBank = (company: CompanyState): ICompanyBank => ({
  bankCode: company.bankCode,
  bankAccountName: company.bankAccountName,
  bankAccountNo: company.bankAccountNo,
  bankCompanyId: company.bankCompanyId
})

export const mapCompanyStateToCompanyStatutory = (company: CompanyState): ICompanyStatutory => ({
  cpfNo: company.cpfNo,
  taxType: company.taxType,
  taxNo: company.taxNo
})

export const mapCompanyStateToCompanyOrgChart = (company: CompanyState): ICompanyOrgChart => ({
  topReportingMgrId: company.topReportingMgrId
})

export const mapCompanyStateToCompany = (company: CompanyState): ICompany => ({
  id: company.id,
  name: company.name,
  registrationNo: company.registrationNo,
  incorporationDate: company.incorporationDate,
  logoId: company.logoId,

  topReportingMgrId: company.topReportingMgrId,

  authName: company.authName,
  authDesignation: company.authDesignation,
  authEmail: company.authEmail,
  authPhone: company.authPhone,

  bankCode: company.bankCode,
  bankAccountName: company.bankAccountName,
  bankAccountNo: company.bankAccountNo,
  bankCompanyId: company.bankCompanyId,

  cpfNo: company.cpfNo,
  taxType: company.taxType,
  taxNo: company.taxNo
})
