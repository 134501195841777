import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { useSelector } from 'react-redux'
import { TAX_ROUTES } from '~/routes/routes'
import { LoadingOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import { Person, SearchInput } from '~/components'
import { Button, Card, ColumnType, ColumnsType, Dropdown, Form, Link, Space, Table, Tooltip } from '~/core-components'
import {
  Screen,
  updateViewCriteria,
  useFirstView,
  useViewSchema,
  ViewCriteria,
  ViewCriteriaSimple,
  ViewSelectionButton
} from '~/features/selection'
import { EmSelectionDrawer } from '~/features/employee'
import { usePermissionGate } from '~/features/iam'
import { Permission, PermissionAction, Ir21SubmissionStatus } from '~/constants'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { Pagination } from '~/types/common'
import { formatYearMonth, formatMonth, formatMoney, getBaseUrl } from '~/utils'
import { refetchIr21SubmissionIr21Views } from '../../../reducers'
import {
  addIr21Draft,
  fetchIr21EmSelections,
  fetchIr21NewEmSelections,
  fetchIr21SubmissionIr21View,
  lockAllIr21Drafts,
  lockIr21Draft,
  processIr21,
  unlockAllIr21Drafts,
  unlockIr21Draft
} from '../../../actions'
import { Ir21SubmissionState, Ir21SubmissionIr21State } from '../../../types'
import { useIr21SubmissionIr21View } from '../../../hooks'
import './Ir21Records.less'

interface Ir21RecordsProps {
  ir21Submission?: Ir21SubmissionState
}

interface EmSelectionDrawerState {
  visible: boolean
}

const SCREEN_CODE: Screen = 'ir21'
const PAGE_SIZE_OPTIONS = ['20', '50', '100']
const EM_SELECTION_DRAWER_STATE: EmSelectionDrawerState = { visible: false }

const nameColWidth = 250
const amountColWidth = 100
const defaultColWidth = 150
const paginationStyle: CSSProperties = { marginRight: 20 }
const baseUrl = getBaseUrl('/filestore')

export const Ir21Records: FC<Ir21RecordsProps> = ({ ir21Submission }) => {
  const ir21SubmissionId = ir21Submission?.id || ''
  const selection = useSelector((state: StoreState) => state.selection.sysSelectionFields[SCREEN_CODE])
  const [view, viewLoading] = useFirstView(SCREEN_CODE)
  const viewId = view?.id || ''
  const [schema] = useViewSchema(SCREEN_CODE, viewId)
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(20)
  const [search, setSearch] = useState<string>('')
  const [data, dataLoading] = useIr21SubmissionIr21View(ir21SubmissionId, viewId, page, pageSize, search, 'always')
  const loading = dataLoading || viewLoading

  const [locking, setLocking] = useState<string>()
  const [lockingAll, setLockingAll] = useState(false)
  const [unlockingAll, setUnlockingAll] = useState(false)
  const refetch = useSelector((state: StoreState) => state.tax.ir21SubmissionIr21ViewsRefetch)
  const canModify = usePermissionGate(Permission.ytd, PermissionAction.Modify)
  const expanded = useSelector((state: StoreState) => state.tax.ir21SubmissionExpanded)
  const processing = useSelector((state: StoreState) => state.tax.ir21Processing)
  const [adding, setAdding] = useState(false)

  const [recalculateDrawerState, setRecalculateDrawerState] =
    useState<EmSelectionDrawerState>(EM_SELECTION_DRAWER_STATE)
  const [addEmployeeDrawerState, setAddEmployeeDrawerState] =
    useState<EmSelectionDrawerState>(EM_SELECTION_DRAWER_STATE)

  useEffect(() => {
    if (viewId && ir21SubmissionId && !processing) {
      dispatch(
        fetchIr21SubmissionIr21View(
          ir21SubmissionId,
          viewId,
          { offset: pageSize * (page - 1), limit: pageSize },
          search
        )
      )
    }
  }, [viewId, ir21SubmissionId, page, pageSize, search, processing, refetch])

  const tableWidth =
    (schema?.selection.reduce(
      (sum, f) => (sum += selection?.entities[f.selectionFieldId]?.width || defaultColWidth),
      0
    ) || 0) +
    nameColWidth +
    7 * amountColWidth

  const columns: ColumnsType<Ir21SubmissionIr21State> = [
    {
      title: 'Name',
      key: 'employeeTaxNo',
      dataIndex: 'employeeTaxNo',
      fixed: 'left',
      className: 'first-col',
      width: nameColWidth,
      render: (value: string, record) => (
        <Person
          name={record.employeeName}
          description={!expanded && value}
          photo={record.photoId && `${baseUrl}/file/${record.photoId}/thumbnailphoto/${expanded ? 18 : 36}`}
          // photo={
          //   record.employeeId &&
          //   `/employee/employee/${record.employeeId}/avatar/${expanded ? 18 : 36}?photo_id=${record.photoId}`
          // }
          openAsNewTab={false}
          path={TAX_ROUTES.ir21FormsSubmission
            .replace(':id', ir21SubmissionId)
            .replace(':employeeTaxNo', value)
            .replace(':tab?', 'ir21')}
          size={expanded ? 18 : 36}
        />
      )
    }
  ]

  if (canModify && ir21Submission?.status === Ir21SubmissionStatus.draft) {
    columns.push({
      key: 'action',
      width: 24,
      className: 'action-cell',
      render: (value: string, record: Ir21SubmissionIr21State) => (
        <Tooltip title={!!record.lockedBy ? 'Locked' : 'Unlocked'}>
          <Link
            className={classNames('ir21records__lock', { 'ir21records__lock--locked': !!record.lockedBy })}
            onClick={() => handleLock(record)}
          >
            {locking === record.id ? (
              <LoadingOutlined />
            ) : !!record.lockedBy ? (
              <i className="fal fa-lock" />
            ) : (
              <i className="fal fa-lock-open" />
            )}
          </Link>
        </Tooltip>
      )
    })
  }

  if (schema) {
    // Configurable employee columns
    columns.push(
      ...schema?.selection.map(f => {
        var field = selection?.entities[f.selectionFieldId]
        return {
          title: field?.description,
          key: field?.fieldName,
          dataIndex: field?.fieldName,
          width: field?.width || defaultColWidth,
          render: (value: string | number, record: Ir21SubmissionIr21State, index: number) => {
            if (field?.format === 'date' && value) {
              return moment(value).format('DD MMM YYYY')
            }
            if (field?.format === 'yearmonth' && value) {
              return formatYearMonth(value as string)
            }
            if (field?.format === 'month' && value) {
              return formatMonth(value as number)
            }
            if (field?.format === 'money' && value) {
              return formatMoney(value as number)
            }
            return value
          }
        }
      })
    )
  }

  columns.push(
    ...[
      {
        title: 'Salary',
        key: 'grossAmt',
        dataIndex: 'grossAmt',
        width: amountColWidth,
        align: 'right',
        render: (value: number) => formatMoney(value, 2)
      } as ColumnType<Ir21SubmissionIr21State>,
      {
        title: 'Bonus',
        key: 'bonusAmt',
        dataIndex: 'bonusAmt',
        width: amountColWidth,
        align: 'right',
        render: (value: number) => formatMoney(value, 2)
      } as ColumnType<Ir21SubmissionIr21State>,
      {
        title: "Director's fee",
        key: 'directorFeeAmt',
        dataIndex: 'directorFeeAmt',
        width: amountColWidth,
        align: 'right',
        render: (value: number) => formatMoney(value, 2)
      } as ColumnType<Ir21SubmissionIr21State>,
      {
        title: 'Commission',
        key: 'commissionAmt',
        dataIndex: 'commissionAmt',
        width: amountColWidth,
        align: 'right',
        render: (value: number) => formatMoney(value, 2)
      } as ColumnType<Ir21SubmissionIr21State>,
      {
        title: 'Allowance',
        key: 'allowanceAmt',
        dataIndex: 'allowanceAmt',
        width: amountColWidth,
        align: 'right',
        render: (value: number) => formatMoney(value, 2)
      } as ColumnType<Ir21SubmissionIr21State>,
      {
        title: 'Gratuity Exgratia',
        key: 'gratuityExgratiaAmt',
        dataIndex: 'gratuityExgratiaAmt',
        width: amountColWidth,
        align: 'right',
        render: (value: number) => formatMoney(value, 2)
      } as ColumnType<Ir21SubmissionIr21State>,
      {
        title: 'Notice',
        key: 'noticeAmt',
        dataIndex: 'noticeAmt',
        width: amountColWidth,
        align: 'right',
        render: (value: number) => formatMoney(value, 2)
      } as ColumnType<Ir21SubmissionIr21State>,
      {
        title: 'Compensation',
        key: 'compensationAmt',
        dataIndex: 'compensationAmt',
        width: amountColWidth,
        align: 'right',
        render: (value: number) => formatMoney(value, 2)
      } as ColumnType<Ir21SubmissionIr21State>,
      {
        title: 'Retirement',
        key: 'retirementAmt',
        dataIndex: 'retirementAmt',
        width: amountColWidth,
        align: 'right',
        render: (value: number) => formatMoney(value, 2)
      } as ColumnType<Ir21SubmissionIr21State>,
      {
        title: 'CPF employer overseas',
        key: 'cpfErOverseas',
        dataIndex: 'cpfErOverseas',
        width: amountColWidth,
        align: 'right',
        render: (value: number) => formatMoney(value, 2)
      } as ColumnType<Ir21SubmissionIr21State>,
      {
        title: 'CPF employee',
        key: 'cpfEmployee',
        dataIndex: 'cpfEmployee',
        width: amountColWidth,
        align: 'right',
        render: (value: number) => formatMoney(value, 2)
      } as ColumnType<Ir21SubmissionIr21State>,
      {
        title: 'Fund',
        key: 'fundAmt',
        dataIndex: 'fundAmt',
        width: amountColWidth,
        align: 'right',
        render: (value: number) => formatMoney(value, 2)
      } as ColumnType<Ir21SubmissionIr21State>,
      {
        title: 'MBF',
        key: 'mbfAmt',
        dataIndex: 'mbfAmt',
        width: amountColWidth,
        align: 'right',
        render: (value: number) => formatMoney(value, 2)
      } as ColumnType<Ir21SubmissionIr21State>
    ]
  )

  const handleLock = useCallback(async record => {
    if (record) {
      setLocking(record.id)
      try {
        if (record.lockedBy) {
          await dispatch(unlockIr21Draft(record.id))
        } else {
          await dispatch(lockIr21Draft(record.id))
        }
      } finally {
        setLocking(undefined)
      }
    }
  }, [])

  const handleLockAll = useCallback(async () => {
    if (ir21SubmissionId) {
      setLockingAll(true)
      try {
        await dispatch(lockAllIr21Drafts(ir21SubmissionId))
      } finally {
        setLockingAll(false)
      }
    }
  }, [ir21SubmissionId])

  const handleUnlockAll = useCallback(async () => {
    if (ir21SubmissionId) {
      setUnlockingAll(true)
      try {
        await dispatch(unlockAllIr21Drafts(ir21SubmissionId))
      } finally {
        setUnlockingAll(false)
      }
    }
  }, [ir21SubmissionId])

  const handleCloseSelectionDrawer = useCallback((changed?: boolean) => {
    if (changed) dispatch(refetchIr21SubmissionIr21Views())
  }, [])

  const handleCriteriaApply = useCallback(
    async (criteria: ViewCriteria[]) => {
      if (viewId) {
        setPage(1)
        await dispatch(updateViewCriteria(SCREEN_CODE, viewId, { id: viewId, criteria }))
        dispatch(refetchIr21SubmissionIr21Views())
      }
    },
    [viewId]
  )

  const handlePaginationChange = useCallback((page: number, pageSize?: number) => {
    setPage(page)
    setPageSize(pageSize || 20)
  }, [])

  const handleSearch = useCallback((value: string) => {
    setPage(1)
    setSearch(value)
  }, [])

  const handleCalculateAll = useCallback(() => {
    dispatch(processIr21(ir21SubmissionId))
  }, [ir21SubmissionId])

  const handleCalculateSome = useCallback(() => {
    setRecalculateDrawerState({ visible: true })
  }, [])

  const handleFetchDataRecalculate = useCallback(
    (viewId: string, pagination: Pagination, search: string) => {
      if (ir21SubmissionId) {
        dispatch(fetchIr21EmSelections(ir21SubmissionId, viewId, pagination, search))
      }
    },
    [ir21SubmissionId]
  )

  const handleSelectRecalculate = useCallback(
    (employeeTaxNos: string[]) => {
      dispatch(processIr21(ir21SubmissionId, employeeTaxNos))
    },
    [ir21SubmissionId]
  )

  const handleCloseRecalculateDrawer = useCallback(() => {
    setRecalculateDrawerState(EM_SELECTION_DRAWER_STATE)
  }, [])

  const handleAddEmployee = useCallback(() => {
    setAddEmployeeDrawerState({ visible: true })
  }, [])

  const handleFetchDataAddEmployee = useCallback(
    (viewId: string, pagination: Pagination, search: string) => {
      if (ir21SubmissionId) {
        dispatch(fetchIr21NewEmSelections(ir21SubmissionId, viewId, pagination, search))
      }
    },
    [ir21SubmissionId]
  )

  const handleSelectAddEmployee = useCallback(
    async (employeeTaxNos: string[]) => {
      try {
        setAdding(true)
        await dispatch(addIr21Draft({ ir21SubmissionId, employeeTaxNos }))
        dispatch(refetchIr21SubmissionIr21Views())
      } finally {
        setAdding(false)
      }
    },
    [ir21SubmissionId]
  )

  const handleCloseAddEmployeeDrawer = useCallback(() => {
    setAddEmployeeDrawerState(EM_SELECTION_DRAWER_STATE)
  }, [])

  return (
    <div className="ir21-records">
      <div className="ir21-records__action-bar">
        <Form.Item label="">
          <SearchInput onSearch={handleSearch} />
        </Form.Item>
        <ViewCriteriaSimple screenCode={SCREEN_CODE} viewId={viewId} onApply={handleCriteriaApply} label="" />
        <Space align="start">
          {canModify && ir21Submission?.status === Ir21SubmissionStatus.draft && (
            <>
              <Dropdown
                disabled={lockingAll || unlockingAll}
                menu={{
                  items: [
                    { key: 'lock', label: 'Lock all employees', onClick: handleLockAll },
                    { key: 'unlock', label: 'Unlock all employees', onClick: handleUnlockAll }
                  ]
                }}
              >
                <Button
                  className="ir21records__action-bar-buttons-lock"
                  hidden={ir21Submission?.status !== Ir21SubmissionStatus.draft}
                >
                  {lockingAll || unlockingAll ? (
                    <LoadingOutlined />
                  ) : (
                    <Space size={4}>
                      <i className="fal fa-lock" />
                      <i className="fa-light fa-angle-down" />
                    </Space>
                  )}
                </Button>
              </Dropdown>
              <Button onClick={handleAddEmployee}>Add employee</Button>
            </>
          )}
          <ViewSelectionButton
            screenCode={SCREEN_CODE}
            viewId={viewId}
            title="Configure IR21 record columns"
            onClose={handleCloseSelectionDrawer}
          />
        </Space>
      </div>
      <Card fitParent table>
        <Table
          rowKey="id"
          dataSource={data?.data}
          columns={columns}
          fitParent
          loading={loading}
          scroll={{ x: tableWidth, y: 1000 }}
          pagination={{
            total: data?.count,
            current: page,
            pageSize,
            pageSizeOptions: PAGE_SIZE_OPTIONS,
            showSizeChanger: true,
            onChange: handlePaginationChange,
            style: paginationStyle
          }}
        />
      </Card>

      <div className="ir21-records__footer-bar">
        <Space className="ir21-records__footer-bar--flex"></Space>
        {canModify && ir21Submission?.status === Ir21SubmissionStatus.draft && (
          <Space>
            <Dropdown.Button
              menu={{
                items: [{ key: 'selected', label: 'Recalculate selected employees', onClick: handleCalculateSome }]
              }}
              icon={<i className="fa-light fa-angle-down" />}
              onClick={handleCalculateAll}
              buttonsRender={([leftButton, rightButton]) => [
                React.cloneElement(leftButton as React.ReactElement, { loading: processing || adding }),
                rightButton
              ]}
            >
              Recalculate all
            </Dropdown.Button>
          </Space>
        )}
      </div>
      <EmSelectionDrawer
        {...recalculateDrawerState}
        viewName="ir21_recalculate"
        title="Select the employees to recalculate IR21"
        okText="Recalculate"
        onFetchData={handleFetchDataRecalculate}
        onSelect={handleSelectRecalculate}
        onClose={handleCloseRecalculateDrawer}
        resetOnClose={true}
      />
      <EmSelectionDrawer
        {...addEmployeeDrawerState}
        viewName="ir21_newem"
        title="Add employees"
        okText="Add"
        onFetchData={handleFetchDataAddEmployee}
        onSelect={handleSelectAddEmployee}
        onClose={handleCloseAddEmployeeDrawer}
        resetOnClose={true}
      />
    </div>
  )
}
