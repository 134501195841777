import { showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { emptyGuid } from '~/constants'
import { apiAddPayRun } from '../api/payrun.api'
import { setPayRun } from '../reducers'
import { IPayRunInfoNew, PayRunState } from '../types'

export const addPayRun = (payRun: IPayRunInfoNew): ThunkResult<void> => {
  return async (dispatch): Promise<ActionResult | undefined> => {
    const { status, result, errors, message, errorData } = await apiAddPayRun({
      id: emptyGuid,
      ...payRun
    })
    if (status) {
      const newPayRun = { ...result, ...payRun } as PayRunState
      dispatch(setPayRun(newPayRun))
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
