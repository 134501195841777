import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Card } from '~/core-components'
import { Col, Row } from '~/components'
import { formatMoney } from '~/utils'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchPayRunSummary } from '../../actions'
import { selectPaySummaryById } from '../../reducers'
import { BankFileCard } from '../BankFile/BankFileCard'
import { CpfFileCard } from '../CpfFile/CpfFileCard'
import { PayslipPublishCard } from '../PayslipPublish/PayslipPublishCard'
import { PayReportsCard } from '../PayReports/PayReportsCard'
import { PayIntegrationCard } from '../PayIntegration/PayIntegrationCard'
import './PaySummary.less'

export interface PaySummaryProps {
  payRunId: string
}

export const PaySummary: FC<PaySummaryProps> = ({ payRunId }) => {
  const summary = useSelector((state: StoreState) => selectPaySummaryById(state, payRunId))
  const refetch = useSelector((state: StoreState) => state.payroll.payRunSummariesRefetch)

  useEffect(() => {
    dispatch(fetchPayRunSummary(payRunId))
  }, [payRunId, refetch])

  return (
    <Row className="paysummary" gutter={[15, 0]}>
      <Col>
        <Card className="paysummary__card">
          <div className="paysummary__card-count">
            <Row align="middle">
              <Col span={12}>
                <div className="employee-count">{summary?.employeeCount || 0}</div>
                <div>employees</div>
              </Col>
              <Col span={12}>
                <Row>
                  <Col flex="20px">{summary?.newJoinerCount || 0}</Col>
                  <Col flex="auto">new joiners</Col>
                </Row>
                <Row>
                  <Col flex="20px">{summary?.resigneeCount || 0}</Col>
                  <Col flex="auto">resignees</Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Card>
        <Card className="paysummary__card">
          <div className="paysummary__card-amount">
            <Row>
              <Col flex="auto">Net pay</Col>
              <Col flex="none">$</Col>
              <Col flex="90px">{formatMoney(summary?.netPay || 0, 2)}</Col>
            </Row>
            <Row>
              <Col flex="auto">CPF employee</Col>
              <Col flex="none">$</Col>
              <Col flex="90px">{formatMoney(summary?.cpfEmployee || 0, 2)}</Col>
            </Row>
            <Row>
              <Col flex="auto">CPF employer</Col>
              <Col flex="none">$</Col>
              <Col flex="90px">{formatMoney(summary?.cpfEmployer || 0, 2)}</Col>
            </Row>
            <Row>
              <Col flex="auto">SHG fund</Col>
              <Col flex="none">$</Col>
              <Col flex="90px">{formatMoney(summary?.fund || 0, 2)}</Col>
            </Row>
            <Row>
              <Col flex="auto">FWL</Col>
              <Col flex="none">$</Col>
              <Col flex="90px">{formatMoney(summary?.fwl || 0, 2)}</Col>
            </Row>
            <Row>
              <Col flex="auto">SDL</Col>
              <Col flex="none">$</Col>
              <Col flex="90px">{formatMoney(summary?.sdl || 0, 2)}</Col>
            </Row>
          </div>
        </Card>
        <PayReportsCard payRunId={payRunId} className="paysummary__card" />
      </Col>
      <Col>
        <BankFileCard payRunId={payRunId} className="paysummary__card" />
        <CpfFileCard payRunId={payRunId} className="paysummary__card" />
      </Col>
      <Col>
        <PayslipPublishCard payRunId={payRunId} className="paysummary__card" />
        <PayIntegrationCard payRunId={payRunId} className="paysummary__card" />
      </Col>
    </Row>
  )
}
