import { setLeaveEntitlementsView, setLeaveEntitlementsViewLoading } from '../reducers'
import { apiGetLeaveEntitlementView } from '../api/leave-entitlement.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions, Pagination } from '~/types/common'

export const fetchLeaveEntitlementView = (
  viewId: string,
  periodYear: number | undefined,
  pagination: Pagination,
  search: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!viewId || !periodYear) return

    const loading = getState().leave.leaveEntitlementsViewLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().leave.leaveEntitlementsView?.entities[viewId]
      if (hasData) return
    }

    try {
      dispatch(setLeaveEntitlementsViewLoading(true))
      const { result, status } = await apiGetLeaveEntitlementView(viewId, periodYear, { ...pagination, search })
      if (status) {
        dispatch(setLeaveEntitlementsView(result))
      }
    } finally {
      dispatch(setLeaveEntitlementsViewLoading(false))
    }
  }
}
