import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import classNames from 'classnames'
import { LoadingOutlined } from '@ant-design/icons'
import { Card, CardProps, Link, List, Space, Spin, Tag } from '~/core-components'
import { Person, Separator } from '~/components'
import { formatDateRange, formatNumberUnit, getBaseUrl } from '~/utils'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { SSOthersLeaveRecordState } from '../../../../types'
import { fetchOthersBackInDays } from '../../../../actions'
import { LveDuration } from '~/constants'
import './Absence.less'

interface AbsenceProps extends CardProps {
  data: SSOthersLeaveRecordState[]
  ids?: string[]
}

const TODAY = moment().format('YYYY-MM-DD')
const baseUrl = getBaseUrl('/filestore')

export const Absence: FC<AbsenceProps> = ({ className, ...props }) => {
  const classes = classNames('absence', className)
  const loading = useSelector((state: StoreState) => state.my.othersLeaveRecordsLoading)
  const [expanded, setExpanded] = useState<boolean>(false)
  const leaveRecordIds = props.ids || undefined
  const backIns = useSelector((state: StoreState) => Object.values(state.my.othersBackInDays.entities))
  const backInsLoading = useSelector((state: StoreState) => state.my.othersBackInDaysLoading)

  useEffect(() => {
    if (leaveRecordIds && leaveRecordIds.length > 0) {
      dispatch(fetchOthersBackInDays(leaveRecordIds))
    }
  }, [leaveRecordIds])

  const handleToggleExpand = useCallback(() => {
    setExpanded(expanded => !expanded)
  }, [])

  return (
    <Card title="Absence" className={classes} loading={loading} {...props}>
      {props.data.length === 0 && !loading && <>Everyone is at work</>}
      {props.data.length > 0 && (
        <List
          size="small"
          dataSource={props.data}
          renderItem={(item: SSOthersLeaveRecordState, index: number) => {
            let description = ''
            const backIn = backIns?.find(b => b?.id === item.id)
            if (moment(item.startDate).isSameOrBefore(moment(TODAY))) {
              if (item.endDuration === LveDuration.firstHalf) {
                description = `back today`
              } else {
                description = `back in ${formatNumberUnit(backIn?.backInDays || 1, 'd')}`
              }
            } else {
              description =
                (item.startDate !== item.endDate ? 'from ' : '') + formatDateRange(item.startDate, item.endDate)
            }

            let items = (
              <List.Item key={item.id}>
                <Space>
                  <Person
                    size={20}
                    name={item.name}
                    photo={item.photoId && `${baseUrl}/publicfile/${item.photoId}/thumbnailphoto/20`}
                    // photo={
                    //   item.employeeId && `/employee/employee/${item.employeeId}/avatar/20?photo_id=${item.photoId}`
                    // }
                    path="#"
                  />
                  <Separator />
                  {backInsLoading ? <Spin indicator={<LoadingOutlined spin />} /> : <span>{description}</span>}
                  {moment(item.endDate).isSame(moment(TODAY)) && item.endDuration === LveDuration.firstHalf && (
                    <Tag>1st half</Tag>
                  )}
                  {moment(item.startDate).isSame(moment(TODAY)) && item.startDuration === LveDuration.secondHalf && (
                    <Tag>2nd half</Tag>
                  )}
                </Space>
              </List.Item>
            )

            return expanded ? items : index <= 2 && items
          }}
        />
      )}
      {props.data.length > 3 && (
        <div className="absence__action">
          <Link size="small" onClick={handleToggleExpand}>
            {!expanded ? `and ${props.data.length - 3} more` : 'show less'}
          </Link>
        </div>
      )}
    </Card>
  )
}
