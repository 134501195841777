import React, { FC, useCallback, useEffect, useState } from 'react'
import { OfficeInfoForm, EMPTY_OFFICE_INFO_FORM_DATA } from '../../Company/components/OfficeInfoForm'
import { EditableCard, EditableCardState } from '~/components'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { dispatch } from '~/stores/store'
import { Errors, ActionResult } from '~/types/store'
import { IOfficeInfo, OfficeState } from '../../../types'
import { mapOfficeStateToOfficeInfo } from '../../../types/office.mapper'
import { updateOffice } from '../../../actions'

interface OfficeInfoProps {
  office?: OfficeState
  onEdit?: () => void
  onSave?: () => void
  onCancel?: () => void
}

export const OfficeInfo: FC<OfficeInfoProps> = ({ office, onEdit, onSave, onCancel }: OfficeInfoProps) => {
  const [cardState, setCardState] = useState<EditableCardState>()
  const [formData, setFormData] = useState<IOfficeInfo>(EMPTY_OFFICE_INFO_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const canModify = usePermissionGate(Permission.master, PermissionAction.Modify)

  useEffect(() => {
    if (office) {
      const {
        name,
        block,
        street,
        level,
        unit,
        building,
        city,
        state,
        postalCode,
        countryCode,
        phone,
        holidayId,
        inactiveDate
      } = office
      setFormData({
        name,
        block,
        street,
        level,
        unit,
        building,
        city,
        state,
        postalCode,
        countryCode,
        phone,
        holidayId,
        inactiveDate
      })
    } else {
      setFormData(EMPTY_OFFICE_INFO_FORM_DATA)
    }
  }, [office])

  const handleEdit = useCallback(() => {
    setCardState('editing')
    typeof onEdit === 'function' && onEdit()
  }, [onEdit])

  const handleSave = useCallback(async () => {
    if (office) {
      setCardState('saving')
      setErrors(undefined)

      typeof onSave === 'function' && onSave()

      let result: ActionResult | undefined
      try {
        result = await dispatch(updateOffice(office.id, mapOfficeStateToOfficeInfo(office), formData))
      } catch {
        setCardState('editing')
      }

      if (result?.errors) {
        setCardState('editing')
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setCardState(undefined)
      }
    }
  }, [office, formData, onSave])

  const handleCancel = useCallback(() => {
    typeof onCancel === 'function' && onCancel()
    setCardState(undefined)
    setErrors(undefined)

    if (office) {
      const {
        name,
        block,
        street,
        level,
        unit,
        building,
        city,
        state,
        postalCode,
        countryCode,
        phone,
        holidayId,
        inactiveDate
      } = office
      setFormData({
        name,
        block,
        street,
        level,
        unit,
        building,
        city,
        state,
        postalCode,
        countryCode,
        phone,
        holidayId,
        inactiveDate
      })
    }
  }, [office, onCancel])

  return (
    <EditableCard
      title="Office information"
      bodyStyle={{ paddingBottom: office ? 6 : 24, paddingTop: 6 }}
      state={canModify ? cardState : 'readonly'}
      formId="form-office-info"
      onEdit={handleEdit}
      onSave={handleSave}
      onCancel={handleCancel}
    >
      <OfficeInfoForm
        usage="office"
        data={formData}
        errors={errors}
        readOnly={cardState !== 'editing' && cardState !== 'saving'}
        onChange={data => setFormData({ ...formData, ...data })}
      />
    </EditableCard>
  )
}
