import React, { FC, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { Dropdown, SecondaryText } from '~/core-components'
import { ProfileAvatar, selectMyLogin } from '~/features/iam'
import { StoreState } from '~/types/store'
import { IAM_ROUTES } from '~/routes/routes'
import './AdminProfileLink.less'

export const AdminProfileLink: FC = () => {
  const myJwt = useSelector((state: StoreState) => state.iam.myJwt)
  const myLoginId = myJwt?.loginId || ''
  const login = useSelector(selectMyLogin)
  const history = useHistory()

  const handleProfileClick = useCallback(() => {
    history.push(`${IAM_ROUTES.iam.replace(':tab?', 'security')}`)
  }, [history])

  const handleLogout = useCallback(() => {
    history.push('/logout')
  }, [history])

  return (
    <Dropdown
      trigger={['click']}
      menu={{
        items: [
          {
            key: 'name',
            type: 'group',
            label: (
              <div className="self-service-name__my-name">
                {login.name}
                <SecondaryText>{login.email}</SecondaryText>
              </div>
            )
          },
          { key: 'div1', type: 'divider' },
          { key: 'profile', label: 'Profile settings', onClick: handleProfileClick },
          { key: 'logout', label: 'Logout', onClick: handleLogout }
        ]
      }}
    >
      <Link to={IAM_ROUTES.iam.replace(':tab?', 'security')} className="my-name">
        <ProfileAvatar loginId={myLoginId} size={32} />
      </Link>
    </Dropdown>
  )
}
