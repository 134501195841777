import { showSuccess, showError } from '~/utils'
import { Country } from '~/types/common'
import { ThunkResult } from '~/types/store'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { apiUpdatePayItem } from '../api/payitem.api'
import { IPayItemInfo, PayItemState } from '../types'
import { mapPayItemToPayItemState } from '../types/payitem.mapper'
import { setPayItem, setKeyvalue } from '../reducers'

export const updatePayItem = <T extends IPayItemInfo>(
  id: string,
  before: T,
  request: T,
  country: Country
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const after = { ...before, ...request } as T
    const patch = generateJsonPatch<T>(before, after)
    const { status, errors, message, errorData } = await apiUpdatePayItem(id, patch)

    if (status) {
      const payItem = getState().master.payItems?.entities[id]
      const updated = {
        ...(payItem as PayItemState),
        ...mapPayItemToPayItemState({ id: payItem!.id, ...after }, country)
      }
      dispatch(setPayItem(updated))
      dispatch(setKeyvalue({ kv: 'payItem', data: { key: id, value: updated.name } }))

      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
