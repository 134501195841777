import { FC, useMemo } from 'react'
import { useHasFeatureFlag } from '~/features/iam'
import { FeatureFlag } from '~/constants'
import { useMyEmployee } from '../hooks'

interface SsAccessGateProps {
  featureFlag: FeatureFlag | FeatureFlag[]
}

export const MySsAccessGate: FC<SsAccessGateProps> = ({ children, featureFlag }) => {
  const hasFeatureFlag = useHasFeatureFlag(featureFlag)
  const featureFlags = useMemo(() => (Array.isArray(featureFlag) ? featureFlag : [featureFlag]), [featureFlag])
  const [me] = useMyEmployee()

  if (!hasFeatureFlag) return null

  let hasSsAccess = false
  for (const feat of featureFlags) {
    if (me?.attributes['ss_access']?.includes(feat)) hasSsAccess = true
  }

  if (!hasSsAccess) return null

  return <>{children}</>
}
