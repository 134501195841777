import React, { FC } from 'react'
import { Layout } from 'antd'
import { Branding } from '~/components'
import { SelfServiceProfileLink } from '../SelfServiceSidebar/SelfServiceProfileLink/SelfServiceProfileLink'
import { TenantSwitcher } from '~/features/iam/containers/TenantSwitcher/TenantSwitcher'
import './SelfServiceHeader.less'

const { Header: AntHeader } = Layout

interface SelfServiceHeaderProps {}

export const SelfServiceHeader: FC<SelfServiceHeaderProps> = () => {
  return (
    <AntHeader className="self-service-header">
      <div className="self-service-header__left">
        <Branding.Logo />
        <div className="self-service-header__divider" />
        <TenantSwitcher />
      </div>
      <div className="self-service-header__actions">
        <SelfServiceProfileLink />
      </div>
    </AntHeader>
  )
}
