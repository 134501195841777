import React, { FC, useEffect, useRef, useState, useCallback } from 'react'
import AvatarEditor from 'react-avatar-editor'
import { UploadFileSelectorAuth } from '~/components'
import { Avatar, Button, Form, Spin, UploadFile } from '~/core-components'
import { Slider } from 'antd'
import { FileImageOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons'
import { Errors } from '~/types/store'
import './PhotoEditor.less'

export interface PhotoEditorProps {
  src?: string
  shape?: 'circle' | 'square'
  defaultPhoto: any
  loading?: boolean
  errors?: Errors
  onChange: (editorRef: any, photo?: UploadFile, viewPhoto?: any) => void
}

const ACCEPT = '.jpg,.jpeg,.png,.bmp,.gif'

export const PhotoEditor: FC<PhotoEditorProps> = ({
  shape = 'circle',
  defaultPhoto,
  loading = false,
  errors,
  onChange
}: PhotoEditorProps) => {
  const [photo, setPhoto] = useState<UploadFile>()
  const [viewPhoto, setViewPhoto] = useState<any>()
  const [scaleValue, setScaleValue] = useState<number>(1)
  const editorRef = useRef<any>(null)

  const min = 0.1
  const max = 3
  const mid = parseFloat(((max - min) / 2).toFixed(5))
  const preColorCls = scaleValue >= mid ? '' : 'icon-wrapper-active'
  const nextColorCls = scaleValue >= mid ? 'icon-wrapper-active' : ''

  useEffect(() => {
    if (defaultPhoto) setViewPhoto(defaultPhoto)
    else setViewPhoto('')

    setScaleValue(1)
  }, [defaultPhoto])

  const handleFileChange = useCallback(
    (files?: UploadFile[]) => {
      if (files && files.length > 0) {
        setPhoto(files[0])
        setViewPhoto(files[0].url)
        setScaleValue(1)
        typeof onChange === 'function' && onChange(editorRef, files[0], files[0].url)
      }
    },
    [editorRef, onChange]
  )

  const handleScaleChange = useCallback(
    (value: number) => {
      setScaleValue(value)
      typeof onChange === 'function' && onChange(editorRef, photo, viewPhoto)
    },
    [editorRef, photo, viewPhoto, onChange]
  )

  const handleChange = useCallback(() => {
    typeof onChange === 'function' && onChange(editorRef, photo, viewPhoto)
  }, [editorRef, photo, viewPhoto, onChange])

  return (
    <div className="photo-editor">
      {viewPhoto ? (
        <AvatarEditor
          image={viewPhoto}
          width={250}
          height={250}
          border={50}
          color={[0, 0, 0, 0.5]}
          scale={scaleValue}
          rotate={0}
          ref={editorRef}
          onImageChange={handleChange}
        />
      ) : loading ? (
        <Spin size="small" indicator={<LoadingOutlined spin />} />
      ) : (
        <Avatar size={250} shape={shape} icon={<i className="fal fa-user" />} />
      )}
      <Form.Item className="upload-select" validateStatus={errors?.upload ? 'error' : ''} help={errors?.upload}>
        <UploadFileSelectorAuth
          multiple={false}
          accept={ACCEPT}
          fileList={photo ? [photo] : []}
          onChange={handleFileChange}
          showUploadList={false}
        >
          <Button icon={<UploadOutlined />} loading={loading}>
            Choose file
          </Button>
        </UploadFileSelectorAuth>
      </Form.Item>
      <div className="icon-wrapper">
        <FileImageOutlined className={preColorCls} />
        <Slider
          onChange={handleScaleChange}
          value={scaleValue}
          min={min}
          max={max}
          step={0.01}
          tooltipVisible={false}
        />
        <FileImageOutlined className={nextColorCls} />
      </div>
    </div>
  )
}
