import React, { FC, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Button, Tooltip } from '~/core-components'
import { dispatch } from '~/stores/store'
import { ActionResult, StoreState } from '~/types/store'
import { processClaim } from '../../../actions'
import { refetchClaimEntitlementsView } from '../../../reducers'
import { ClaimProcessDto } from '../../../types'

interface ClaimRefreshButtonProps {}

const EMPTY_RECAL_DATA: ClaimProcessDto = {
  employeeIds: [],
  claimTypeIds: [],
  silentError: true
}

export const ClaimRefreshButton: FC<ClaimRefreshButtonProps> = () => {
  const claimProcessing = useSelector((state: StoreState) => state.claim.claimLoading)

  const handleRefreshClick = useCallback(async () => {
    const result: ActionResult | undefined = await dispatch(processClaim(EMPTY_RECAL_DATA))
    if (!result?.errors) {
      dispatch(refetchClaimEntitlementsView())
    }
  }, [])

  return (
    <Tooltip title="Recalculate all entitlement">
      <Button icon={<i className="fal fa-refresh" />} onClick={handleRefreshClick} loading={claimProcessing} />
    </Tooltip>
  )
}
