import { showSuccess, showError } from '~/utils'
import { AttendancePeriodStatus } from '~/constants'
import { ThunkResult } from '~/types/store'
import { apiCompleteAttendancePeriod } from '../api/attendance-period.api'
import { AttendancePeriodState } from '../types'
import { setAttendancePeriod } from '../reducers'

export const completeAttendancePeriod = (id: string): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const { status, errors, message, errorData } = await apiCompleteAttendancePeriod(id)
    if (status) {
      let attendancePeriod = getState().attendance.attendancePeriods?.entities[id]
      dispatch(
        setAttendancePeriod({
          ...(attendancePeriod as AttendancePeriodState),
          status: AttendancePeriodStatus.Completed
        })
      )

      showSuccess('Attendance period has marked as complete')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
