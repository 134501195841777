import React, { forwardRef, useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Select, SelectProps } from '~/core-components'
import { fetchKeyvalues } from '~/features/master'
import { sortKeyValues } from '~/features/employee'
import { useFirstInView } from '~/hooks'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { FetchStrategy, KeyValue } from '~/types/common'
import { InactiveTag } from '../InactiveTag/InactiveTag'
import { DropdownActions } from './DropdownActions'

export interface CurrencyKeyValuesProps extends SelectProps {
  queryParams?: any
  labelProp?: (kv: KeyValue) => string
  onFilter?: (value: KeyValue | undefined, index: number, array: (KeyValue | undefined)[]) => {}
  fetchStrategy?: FetchStrategy
}

const CurrencyKeyValuesInternal = (
  { queryParams = {}, labelProp, onFilter, children, fetchStrategy = 'when-empty', ...props }: CurrencyKeyValuesProps,
  ref: React.Ref<any>
) => {
  const { ref: inViewRef, inView } = useFirstInView<HTMLDivElement>({ threshold: 0.25 })
  const data = useSelector((state: StoreState) => state.master.keyvalues['currency']?.entities || undefined)
  const loading = useSelector((state: StoreState) => state.master.keyvaluesLoading['currency'])

  const options = useMemo(() => {
    if (!data) return []

    let result = Object.values(data)
      .filter(a => a?.isShown || a?.key === props.value)
      .sort((a, b) => sortKeyValues(a!, b!, 'key'))

    if (typeof onFilter === 'function') {
      result = result.filter(onFilter)
    }
    return result
  }, [data, props.value, onFilter])

  useEffect(() => {
    if (inView) {
      dispatch(fetchKeyvalues('currency', 'claim', undefined, queryParams, { strategy: fetchStrategy }))
    }
  }, [inView, queryParams, fetchStrategy])

  const handleRefresh = useCallback(() => {
    dispatch(fetchKeyvalues('currency', 'claim', undefined, queryParams))
  }, [queryParams])

  return (
    <div ref={inViewRef}>
      <Select
        ref={ref}
        showSearch
        optionFilterProp="title"
        loading={loading}
        dropdownRender={menu => (
          <div>
            {menu}
            <DropdownActions onRefresh={handleRefresh} />
          </div>
        )}
        {...props}
      >
        {options?.map(kv => {
          const displayText = `${kv!.key.toUpperCase()} - ${kv!.value}`

          return (
            <Select.Option
              key={kv!.key}
              value={kv!.key || ''}
              title={displayText}
              {...(labelProp ? { label: labelProp(kv!) } : {})}
            >
              {!kv!.isShown && <InactiveTag />}
              {displayText}
            </Select.Option>
          )
        })}
      </Select>
    </div>
  )
}

export const CurrencyKeyValues = forwardRef(CurrencyKeyValuesInternal)
