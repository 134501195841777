import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { UploadFile } from '~/core-components'
import { apiUploadLoginPhoto } from '../api/login.api'
import { setLogin } from '../reducers'
import { LoginState } from '../types'

export const uploadLoginPhoto = (id: string, file: UploadFile): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const { status, result, errors, message, errorData } = await apiUploadLoginPhoto(id, file)
    if (status) {
      const login = getState().iam.logins.entities[id]
      dispatch(setLogin({ ...(login as LoginState), photoId: result?.photoId }))
      showSuccess('Photo saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors, result }
  }
}
