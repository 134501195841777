import React, { CSSProperties, FC, useMemo } from 'react'
import { default as AntCol, ColProps as AntColProps } from 'antd/es/col'

interface ColProps extends AntColProps {
  hidden?: boolean
}
export const Col: FC<ColProps> = ({ hidden, style, ...props }: ColProps) => {
  const getStyle = useMemo((): CSSProperties => {
    return { ...style, ...(hidden ? { display: 'none' } : {}) }
  }, [style, hidden])

  return <AntCol style={getStyle} {...props} />
}
