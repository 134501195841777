import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchLeaveRecordDtls } from '../../actions'
import { selectMyLeaveRecordDtls } from '../../selectors'
import { SSLeaveRecordDtlState } from '../../types'

export const useMyLeaveRecordDtls = (leaveRecordId?: string): [SSLeaveRecordDtlState[], boolean] => {
  const myLeaveRecordDtls = useSelector(selectMyLeaveRecordDtls)(leaveRecordId || '') as SSLeaveRecordDtlState[]
  const loading = useSelector((state: StoreState) => state.my.myLeaveRecordDtlsLoading[leaveRecordId || ''])

  useEffect(() => {
    if (leaveRecordId) {
      dispatch(fetchLeaveRecordDtls(leaveRecordId))
    }
  }, [leaveRecordId])

  return [myLeaveRecordDtls, loading]
}
