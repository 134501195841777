import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment-timezone'
import { RangeValue } from 'rc-picker/lib/interface.d'
import { Input, Select, Space } from '../../core-components'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import './DateCustomPicker.less'

interface DateCustomPickerProps {
  defaultValue: string
  defaultStartDate?: string | null
  defaultEndDate?: string | null
  options: DateCustomPickerItem[]
  onChange: (startDate: string, endDate: string, selection: string) => void
  hidden?: boolean
  size?: SizeType
  datePickerPosition?: 'left' | 'right'
  disabledDate?: (date: moment.Moment) => boolean
}

export interface DateCustomPickerItem {
  key: string
  value: string
  startDate: string | null
  endDate: string | null
}

const DEFAULT_DATES_PROPS: any = { style: { visibility: 'hidden' } }

export const DateCustomPicker: FC<DateCustomPickerProps> = ({
  defaultValue,
  defaultStartDate,
  defaultEndDate,
  options,
  onChange,
  hidden,
  size = 'middle',
  datePickerPosition = 'left',
  disabledDate
}) => {
  const selection: DateCustomPickerItem[] = useMemo(
    () => [
      ...options,
      {
        key: 'custom',
        value: 'Custom dates',
        startDate: null,
        endDate: null
      }
    ],
    [options]
  )

  const [selected, setSelected] = useState(defaultValue)
  const found = selection.find(item => item.key === selected)
  const foundStartDate = found?.startDate
  const foundEndDate = found?.endDate

  const [startDate, setStartDate] = useState<string | null>(foundStartDate || defaultStartDate || null)
  const [endDate, setEndDate] = useState<string | null>(foundEndDate || defaultEndDate || null)
  const [datesProps, setDatesProps] = useState(DEFAULT_DATES_PROPS)

  useEffect(() => {
    if (selected === 'custom') {
      setDatesProps({ style: { visibility: 'visible' } })
    } else {
      setDatesProps(DEFAULT_DATES_PROPS)
    }
  }, [selected])

  // useEffect(() => {
  //   const newStartDate = foundStartDate || defaultStartDate || null
  //   const newEndDate = foundEndDate || defaultEndDate || null
  //   if (newStartDate !== null && newEndDate !== null) {
  //     typeof onChange == 'function' && onChange(newStartDate, newEndDate, selected)
  //   }
  // }, [foundStartDate, defaultStartDate, foundEndDate, defaultEndDate, onChange, selected])

  const handleSelectChange = useCallback(
    (key: string) => {
      const found = selection.find(item => item.key === key)
      const start = found?.startDate || null
      const end = found?.endDate || null

      if (found?.key !== 'custom') {
        if (start !== null && end !== null && (start !== startDate || end !== endDate)) {
          typeof onChange == 'function' && onChange(start, end, key)

          setStartDate(start)
          setEndDate(end)
        }
      }

      setSelected(key)
    },
    [selection, startDate, endDate, onChange, setSelected]
  )

  const handleDateRangeChange = useCallback(
    (dates: RangeValue<moment.Moment>) => {
      const start = dates && dates[0] ? dates[0].format('YYYY-MM-DD') : null
      const end = dates && dates[1] ? dates[1].format('YYYY-MM-DD') : null

      if (start !== null && end !== null && (start !== startDate || end !== endDate)) {
        typeof onChange == 'function' && onChange(start, end, selected)

        setStartDate(start)
        setEndDate(end)
      }
    },
    [startDate, endDate, onChange, selected]
  )

  const DatePicker = (
    <Input.DateRange
      {...datesProps}
      allowClear={false}
      value={[startDate ? moment(startDate) : null, endDate ? moment(endDate) : null]}
      onCalendarChange={handleDateRangeChange}
      size={size}
      disabledDate={disabledDate}
    />
  )

  return (
    <div className="date-custom-picker" hidden={hidden}>
      <Space>
        {selected === 'custom' && datePickerPosition === 'left' && DatePicker}
        <Select value={selected} onSelect={handleSelectChange} allowClear={false} size={size}>
          {selection.map(item => (
            <Select.Option key={item.key} value={item.key}>
              {item.value}
            </Select.Option>
          ))}
        </Select>
        {selected === 'custom' && datePickerPosition === 'right' && DatePicker}
      </Space>
    </div>
  )
}
