import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { IShiftRole, ShiftRoleState } from '../types'

const baseUrl = getBaseUrl('/attendance/shiftrole')

export const apiGetShiftRoles = () => request<ShiftRoleState[]>('get', baseUrl)

export const apiGetShiftRole = (id: string) => request<ShiftRoleState>('get', `${baseUrl}/${id}`)

export const apiAddShiftRole = (shiftRole: IShiftRole) => request<{ id: string }>('post', baseUrl, shiftRole)

export const apiUpdateShiftRole = (id: string, shiftRole: Operation[]) =>
  request<void>('patch', `${baseUrl}/${id}`, shiftRole)

export const apiDeleteShiftRole = (id: string) => request<void>('delete', `${baseUrl}/${id}`)
