import { replaceAttendanceGroups, setAttendanceGroupsLoading } from '../reducers'
import { apiGetAttendanceGroups } from '../api/attendance-group.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchAttendanceGroups = (options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().attendance.attendanceGroupsLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().attendance.attendanceGroups?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setAttendanceGroupsLoading(true))
      const { result, status } = await apiGetAttendanceGroups()
      if (status) {
        dispatch(replaceAttendanceGroups(result))
      }
    } finally {
      dispatch(setAttendanceGroupsLoading(false))
    }
  }
}
