import { setLogin, setLoginsLoading } from '../reducers'
import { apiGetLogin } from '../api/login.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchLogin = (
  tenantCode: string,
  loginId: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!tenantCode || !loginId) return

    const loading = getState().iam.loginsLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().iam.logins.entities[loginId]
      if (hasData) return
    }

    try {
      dispatch(setLoginsLoading(true))
      const { result, status } = await apiGetLogin(tenantCode, loginId)
      if (status) {
        dispatch(setLogin(result))
      }
    } finally {
      dispatch(setLoginsLoading(false))
    }
  }
}
