import { Dictionary } from '@reduxjs/toolkit'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { AttendanceGroupState } from '../types'
import { fetchAttendanceGroups } from '../actions'

export const useAttendanceGroupsDict = (
  fetchStrategy: FetchStrategy = 'when-empty'
): [Dictionary<AttendanceGroupState>, boolean] => {
  const attendanceGroups = useSelector((state: StoreState) => state.attendance.attendanceGroups.entities)
  const loading = useSelector((state: StoreState) => state.attendance.attendanceGroupsLoading)

  useEffect(() => {
    dispatch(fetchAttendanceGroups({ strategy: fetchStrategy }))
  }, [fetchStrategy])

  return [attendanceGroups, loading]
}
