import { FC, useMemo } from 'react'
import { FeatureFlag } from '~/constants'
import { useMyTenant } from '../hooks/use-my-tenant'

interface FeatureFlagGateProps {
  featureFlag: FeatureFlag | FeatureFlag[]
}

export const FeatureFlagGate: FC<FeatureFlagGateProps> = ({ children, featureFlag }) => {
  const featureFlags = useMemo(() => (Array.isArray(featureFlag) ? featureFlag : [featureFlag]), [featureFlag])
  const [myTenant] = useMyTenant()

  let hasFeatureFlag = false
  for (const feat of featureFlags) {
    if (myTenant?.featureFlags?.includes(feat)) hasFeatureFlag = true
  }

  if (!hasFeatureFlag) return null

  return <>{children}</>
}
