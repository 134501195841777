import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { apiUpdateYtdCompany } from '../api/ytd-company.api'
import { YtdCompanyState } from '../types'
import { setYtdCompany } from '../reducers'

export const updateYtdCompany = (id: string, before: YtdCompanyState, request: YtdCompanyState): ThunkResult<void> => {
  return async dispatch => {
    if (!id) return

    const after = { ...before, ...request } as YtdCompanyState
    const patch = generateJsonPatch<YtdCompanyState>(before, after)
    const { status, errors, message, errorData } = await apiUpdateYtdCompany(id, patch)

    if (status) {
      dispatch(setYtdCompany(after))

      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
