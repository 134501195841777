import { EntityState } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { UserState } from './user.state'

export const tempPersistConfig = {
  key: 'temp',
  whitelist: ['users'],
  storage
}

export interface TempRootState {
  users: EntityState<UserState>
  usersLoading: boolean
}
