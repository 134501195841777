import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment-timezone'
import { Button, Dropdown, Input, Space, Tooltip } from '~/core-components'
import { TimeProps } from '~/core-components/Input/Time'
import './TimeDayInput.less'

export type DayIndicator = -1 | 0 | 1

const ICON_PREV = 'fa-calendar-minus'
const ICON_NEXT = 'fa-calendar-plus'
const ICON_CURR = 'fa-calendar'

export interface TimeDayInputProps extends Omit<TimeProps, 'value' | 'onChange' | 'inputReadOnly'> {
  time: moment.Moment | null | undefined
  day?: DayIndicator
  date?: string
  readOnly?: boolean
  readOnlyBorderless?: boolean
  hideCurrentDayIndicator?: boolean
  onChange?: (time: moment.Moment | null | undefined, day: DayIndicator | undefined) => void
}

const TimeDayInputInternal = (
  { time, day, date, readOnly, readOnlyBorderless, hideCurrentDayIndicator, onChange, ...props }: TimeDayInputProps,
  ref: React.Ref<any>
) => {
  const [dayIndicator, setDayIndicator] = useState<DayIndicator | undefined>(day)
  const dayIcon = useMemo(
    () => (dayIndicator === -1 ? ICON_PREV : dayIndicator === 1 ? ICON_NEXT : ICON_CURR),
    [dayIndicator]
  )
  const dateIndicator = date
    ? moment(date)
        .add(day as number, 'day')
        .format('DD MMM YYYY (ddd)')
    : undefined

  useEffect(() => {
    setDayIndicator(day)
  }, [day])

  const handleTimeChange = useCallback(
    (time: moment.Moment | null) => {
      typeof onChange === 'function' && onChange(time, dayIndicator)
    },
    [dayIndicator, onChange]
  )

  const handlePrevious = useCallback(() => {
    setDayIndicator(-1)
    typeof onChange === 'function' && onChange(time, -1)
  }, [time, onChange])

  const handleCurrent = useCallback(() => {
    setDayIndicator(0)
    typeof onChange === 'function' && onChange(time, 0)
  }, [time, onChange])

  const handleNext = useCallback(() => {
    setDayIndicator(1)
    typeof onChange === 'function' && onChange(time, 1)
  }, [time, onChange])

  const isDayIconHidden = hideCurrentDayIndicator && dayIcon === ICON_CURR

  return (
    <span className="time-day-input">
      {readOnly && readOnlyBorderless ? (
        time ? (
          <Space>
            {!isDayIconHidden && (
              <Tooltip title={dateIndicator}>
                <i className={`fal ${dayIcon}`} />
              </Tooltip>
            )}
            <span>{time.format('HH:mm')}</span>
          </Space>
        ) : (
          '-'
        )
      ) : (
        <>
          <Dropdown
            trigger={['click']}
            disabled={readOnly}
            overlayClassName="time-day-input__overlay"
            menu={{
              items: [
                {
                  key: '-1',
                  label: (
                    <Space>
                      <i className={`fal ${ICON_PREV}`} />
                      Previous day
                    </Space>
                  ),
                  onClick: handlePrevious,
                  className: dayIndicator === -1 ? 'time-day-input__overlay-item--selected' : undefined
                },
                {
                  key: '0',
                  label: (
                    <Space>
                      <i className={`fal ${ICON_CURR}`} />
                      Current day
                    </Space>
                  ),
                  onClick: handleCurrent,
                  className: dayIndicator === 0 ? 'time-day-input__overlay-item--selected' : undefined
                },

                {
                  key: '1',
                  label: (
                    <Space>
                      <i className={`fal ${ICON_NEXT}`} />
                      Next day
                    </Space>
                  ),
                  onClick: handleNext,
                  className: dayIndicator === 1 ? 'time-day-input__overlay-item--selected' : undefined
                }
              ]
            }}
          >
            <Button
              icon={
                <Tooltip title={dateIndicator}>
                  <i className={`fal ${dayIcon}`} />
                </Tooltip>
              }
            />
          </Dropdown>
          <Input.Time
            ref={ref}
            value={time}
            onChange={handleTimeChange}
            minuteStep={5}
            allowClear
            inputReadOnly={readOnly}
            {...props}
          />
        </>
      )}
    </span>
  )
}

export const TimeDayInput = forwardRef(TimeDayInputInternal)
