import { replaceWfEvents, setWfEventsLoading } from '../reducers'
import { apiGetWfEvents } from '../api/wf-event.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchWfEvents = (workflowId: string, options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!workflowId) return

    const loading = getState().workflow.wfEventsLoading[workflowId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = (getState().workflow.wfEvents[workflowId]?.ids?.length || 0) > 0
      if (hasData) return
    }

    try {
      dispatch(setWfEventsLoading({ workflowId, loading: true }))
      const { result, status } = await apiGetWfEvents(workflowId)
      if (status) {
        dispatch(replaceWfEvents({ workflowId, data: result }))
      }
    } finally {
      dispatch(setWfEventsLoading({ workflowId, loading: false }))
    }
  }
}
