import { getBaseUrl } from '~/utils'
import { OrgChartState, IOrgChart } from './em-orgchart.state'

const EMPTY_ORG_CHART: OrgChartState = {
  id: '',
  person: { id: '', avatar: '', avatarSrc: '', department: '', name: '', title: '', totalReports: 0 },
  hasParent: false,
  hasChild: false,
  isHighlight: false,
  children: []
}

export const mapIOrgChartsToOrgChartState = (dtos: IOrgChart[]): OrgChartState => {
  let orgChart = EMPTY_ORG_CHART

  if (dtos) {
    // get the first object because the OrgChart needs only one object
    let firstChart = dtos.shift()
    if (firstChart !== undefined) {
      orgChart = mapIOrgChartToOrgChartState(firstChart)

      if (orgChart.hasChild) {
        const childs = dtos.filter(dto => dto.managerId === orgChart.id)

        if (childs.length > 0) {
          childs.forEach(c => {
            const child = mapChildRecursive(mapIOrgChartToOrgChartState(c), dtos)
            orgChart.children?.push(child)
          })
        }
      }
    }
  }

  return orgChart
}

export const mapChildRecursive = (child: OrgChartState, dtos: IOrgChart[]): OrgChartState => {
  if (child.hasChild) {
    const childs = dtos.filter(dto => dto.managerId === child.id)

    if (childs.length > 0) {
      childs.forEach(c => {
        const gchild = mapChildRecursive(mapIOrgChartToOrgChartState(c), dtos)
        child.children?.push(gchild)
      })
    }
  }

  return child
}

const baseUrl = getBaseUrl('/filestore')

export const mapIOrgChartToOrgChartState = (d: IOrgChart): OrgChartState => {
  let title = []
  if (d.department) title.push(d.department)
  if (d.job) title.push(d.job)

  let chartNode: OrgChartState = {
    id: d.id,
    person: {
      id: d.id,
      avatar: '/avatar-personnel.svg',
      avatarSrc: d.photoId ? `${baseUrl}/file/${d.photoId}/thumbnailphoto/48` : '',
      department: d.department,
      name: d.employeeName,
      title: title.join(' - '),
      totalReports: d.totalReports
    },
    hasParent: d.hasParent,
    hasChild: d.hasChild,
    isHighlight: d.isHighlight
  }

  if (!d.hasParent) {
    chartNode.children = []
  }

  return chartNode
}
