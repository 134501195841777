import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiDeleteEmployee } from '../api/employee.api'
import { removeEmKeyValue, removeEmployee } from '../reducers'

export const deleteEmployee = (id: string): ThunkResult<void> => {
  return async dispatch => {
    if (!id) return

    const { status, errors, message, errorData } = await apiDeleteEmployee(id)
    if (status) {
      dispatch(removeEmployee(id))
      dispatch(removeEmKeyValue(id))

      showSuccess('Deleted')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
