import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Card, ColumnsType, Link, Switch, Table, Tag } from '~/core-components'
import { fetchRules, fetchSysRuleCriteria, updateRule } from '../../actions'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { selectRules } from '../../selectors'
import { mapRuleStateToRule, RuleState, Screen } from '../../types'
import { EditRuleDrawer } from './components/EditRuleDrawer'
import './Rules.less'

interface RulesProps {
  screenCode: Screen
  referenceId: string
  readOnly: boolean
}

interface DrawerState {
  visible: boolean
  data?: RuleState
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

export const Rules: FC<RulesProps> = ({ screenCode, referenceId, readOnly }) => {
  const rules = useSelector(selectRules)(referenceId)
  const ruleCriteria = useSelector((state: StoreState) => state.rule.sysRuleCriteria.entities)
  const loading = useSelector((state: StoreState) => state.rule.rulesLoading[referenceId])
  const [updatingEnable, setUpdatingEnable] = useState<string>()
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)

  useEffect(() => {
    dispatch(fetchRules(referenceId))
  }, [referenceId])

  useEffect(() => {
    dispatch(fetchSysRuleCriteria(screenCode, { strategy: 'when-empty' }))
  }, [screenCode])

  const handleEnableChange = useCallback(
    async (rule: RuleState, isEnabled: boolean) => {
      setUpdatingEnable(rule.id)
      try {
        const current = mapRuleStateToRule(rule)
        await dispatch(updateRule(referenceId, rule.id, current, { ...current, isEnabled }))
      } finally {
        setUpdatingEnable(undefined)
      }
    },
    [referenceId]
  )

  const handleEdit = useCallback((rule: RuleState) => {
    setDrawerState({ visible: true, data: rule })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const columns: ColumnsType<RuleState> = [
    {
      title: 'Enabled',
      key: 'isEnabled',
      dataIndex: 'isEnabled',
      align: 'center',
      width: 100,
      render: (value: boolean, record) => (
        <Switch
          checkedChildren="Yes"
          unCheckedChildren="No"
          checked={record.isEnabled}
          loading={updatingEnable === record.id}
          disabled={readOnly}
          size="small"
          onChange={(checked: boolean) => {
            handleEnableChange(record, checked)
          }}
        />
      )
    },
    {
      title: 'Rule',
      key: 'ruleCriteriaId',
      dataIndex: 'ruleCriteriaId',
      render: (value: string) => {
        return ruleCriteria[value]?.name || ''
      }
    },
    {
      title: 'Tag',
      key: 'tag',
      dataIndex: 'tag',
      width: 100,
      render: (value: string, record) =>
        value && (
          <Tag type="original" color={record.color}>
            {value}
          </Tag>
        )
    },
    {
      key: 'action',
      align: 'right',
      width: 60,
      render: (value: string, record) => (
        <Link size="small" onClick={() => handleEdit(record)}>
          edit
        </Link>
      )
    }
  ]

  return (
    <div className="rules">
      <Card table>
        <Table
          rowKey="id"
          dataSource={rules}
          pagination={false}
          columns={columns}
          loading={loading}
          rowClassName={record => (!record.isEnabled ? 'rules__table-row--disabled' : '')}
        />
        <EditRuleDrawer {...drawerState} onClose={handleCloseDrawer} />
      </Card>
    </div>
  )
}
