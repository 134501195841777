import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions, Pagination } from '~/types/common'
import { setROLeaveEntitlementsView, setROLeaveEntitlementsViewLoading } from '../../reducers'
import { apiGetROLeaveEntitlementView } from '../../api/roleave-entitlement.api'

export const fetchROLeaveEntitlementView = (
  viewId: string,
  role: string,
  periodYear: number | undefined,
  pagination: Pagination,
  search: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!viewId || !role || !periodYear) return

    const loading = getState().my.roLeaveEntitlementsViewLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().my.roLeaveEntitlementsView?.entities[viewId]
      if (hasData) return
    }

    try {
      dispatch(setROLeaveEntitlementsViewLoading(true))
      const { result, status } = await apiGetROLeaveEntitlementView(viewId, role, periodYear, { ...pagination, search })
      if (status) {
        dispatch(setROLeaveEntitlementsView(result))
      }
    } finally {
      dispatch(setROLeaveEntitlementsViewLoading(false))
    }
  }
}
