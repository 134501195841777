import { ActionResult, ThunkResult } from '~/types/store'
import { emptyGuid } from '~/constants'
import { showSuccess, showError } from '~/utils'
import { apiAddEmSalary } from '../api/em-salary.api'
import { setEmSalary, setEmployee } from '../reducers'
import { IEmSalaryInfo, EmSalaryState } from '../types'

export const addEmSalary = (employeeId: string, emSalary: IEmSalaryInfo): ThunkResult<void> => {
  return async (dispatch, getState): Promise<ActionResult | undefined> => {
    if (!employeeId) return

    const { status, result, errors, message, errorData } = await apiAddEmSalary(employeeId, {
      id: emptyGuid,
      employeeId,
      ...emSalary
    })
    if (status) {
      if (result.emSalaryId) {
        const employee = getState().employee.employees.entities[employeeId]
        if (employee) {
          dispatch(setEmployee({ ...employee, emSalaryId: result.emSalaryId }))
        }
      }

      const newEmSalary = {
        id: result.id,
        employeeId,
        ...emSalary
      } as EmSalaryState
      dispatch(setEmSalary({ employeeId, data: newEmSalary }))

      showSuccess('Added')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
