import { setEmSection, setEmSectionsLoading } from '../reducers'
import { apiGetEmSection } from '../api/em-section.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchEmSection = (
  employeeId: string,
  emSectionId: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!employeeId || !emSectionId) return

    const loading = getState().employee.emSectionsLoading[employeeId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().employee.emSections[employeeId]?.entities[emSectionId]
      if (hasData) return
    }

    try {
      dispatch(setEmSectionsLoading({ employeeId, loading: true }))
      const { result, status } = await apiGetEmSection(employeeId, emSectionId)
      if (status) {
        dispatch(setEmSection({ employeeId, data: result }))
      }
    } finally {
      dispatch(setEmSectionsLoading({ employeeId, loading: false }))
    }
  }
}
