import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { PayEncashMapState } from '../types'

export const selectPayEncashMaps = createSelector(
  (state: StoreState) => state.master.payEncashMaps,
  payEncashMapsState =>
    memoize((payGroupId: string) => {
      return (Object.values(payEncashMapsState[payGroupId]?.entities || {}) as PayEncashMapState[]) || []
    })
)
