import { Operation } from 'fast-json-patch'
import { request, getBaseUrl, timeout } from '~/utils'
import { DateRange, Pagination, Search } from '~/types/common'
import { EmSelectState } from '~/components'
import { EmSelectionViewState } from '~/features/employee'
import { DailyRecordLabelState, DailyRecordState, DailyRecordViewState, IDailyRecordLock } from '../types'

const baseUrl = getBaseUrl('/attendance/dailyrecord')

export const apiGetDailyRecordsView = (
  viewId: string,
  params: Pagination & Search & DateRange & { employeeId?: string; screenCode?: string }
) => request<DailyRecordViewState>('get', `${baseUrl}/view/${viewId}`, params)

export const apiGetDailyRecordsByEmployeeView = (viewId: string, employeeId: string, params: DateRange) =>
  request<DailyRecordViewState>('get', `${baseUrl}/view/${viewId}/${employeeId}`, params)

export const apiGetDailyRecordsExcel = (viewId: string, params: Search & DateRange) =>
  request('get', `${baseUrl}/view/${viewId}/excel`, params, { responseType: 'blob', timeout })

export const apiGetDailyEmSelections = (
  startDate: string,
  endDate: string,
  viewId: string,
  params: Pagination & Search
) => request<EmSelectionViewState>('get', `${baseUrl}/${startDate}/${endDate}/emselections/${viewId}`, params)

export const apiGetDailyRecord = (id: string) => request<DailyRecordState>('get', `${baseUrl}/${id}`)

export const apiGetDailyRecordByEmployeeDate = (clockDate: string, employeeId: string) =>
  request<DailyRecordState[]>('get', `${baseUrl}/${clockDate}/${employeeId}`)

export const apiGetDailyRecordLabels = (id: string) => request<DailyRecordLabelState[]>('get', `${baseUrl}/${id}/label`)

export const apiGetDailyRecordEmployees = (clockDate: string) =>
  request<EmSelectState[]>('get', `${baseUrl}/${clockDate}/employees`)

export const apiUpdateDailyRecord = (id: string, dailyRecord: Operation[]) =>
  request<void>('patch', `${baseUrl}/${id}`, dailyRecord)

export const apiDeleteDailyRecord = (id: string) => request<void>('delete', `${baseUrl}/${id}`)

export const apiLockDailyRecord = (id: string) => request<IDailyRecordLock>('post', `${baseUrl}/${id}/lock`)

export const apiUnlockDailyRecord = (id: string) => request<void>('post', `${baseUrl}/${id}/unlock`)

export const apiLockAllDailyRecords = (attendancePeriodId: string) =>
  request<IDailyRecordLock[]>('post', `${baseUrl}/${attendancePeriodId}/lockall`)

export const apiUnlockAllDailyRecords = (attendancePeriodId: string) =>
  request<IDailyRecordLock[]>('post', `${baseUrl}/${attendancePeriodId}/unlockall`)
