import omit from 'lodash/omit'
import { emptyGuid } from '~/constants'
import { showSuccess, showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { apiAddEmDocument } from '../api/em-document.api'
import { setEmDocument } from '../reducers'
import { IEmDocumentInfo } from '../types'

export const addEmDocument = (employeeId: string, emDocument: IEmDocumentInfo): ThunkResult<void> => {
  return async (dispatch): Promise<ActionResult | undefined> => {
    if (!employeeId) return

    const { status, result, errors, message, errorData } = await apiAddEmDocument(employeeId, {
      id: emptyGuid,
      employeeId,
      ...emDocument
    })
    if (status) {
      const { id, docFileId, docFileName, docFileSize, docFileFormat, docFileExtension } = result
      const newEmDocument = {
        id,
        employeeId,
        docFileId,
        docFileName,
        docFileSize,
        docFileFormat,
        docFileExtension,
        ...omit(emDocument, 'file', 'docFileId')
      }
      dispatch(setEmDocument({ employeeId, data: newEmDocument }))

      showSuccess('Added')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
