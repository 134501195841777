import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { AttendanceGroupState } from '../types'

export const selectAttendanceGroups = createSelector(
  (state: StoreState) => state.attendance.attendanceGroups,
  (attendanceGroupsState): AttendanceGroupState[] => {
    const attendanceGroups = Object.values(attendanceGroupsState?.entities || {}) as AttendanceGroupState[]
    return attendanceGroups.sort((a, b) => {
      const aInactiveDate = new Date(a!.inactiveDate || '').getTime() || 0
      const bInactiveDate = new Date(b!.inactiveDate || '').getTime() || 0

      if (bInactiveDate === 0 && aInactiveDate === 0) {
        return a!.name.localeCompare(b!.name)
      } else {
        return aInactiveDate - bInactiveDate
      }
    })
  }
)
