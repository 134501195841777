import { setAttendancePeriodsView, setAttendancePeriodsViewLoading } from '../reducers'
import { apiGetAttendancePeriodsView } from '../api/attendance-period.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions, Pagination } from '~/types/common'

export const fetchAttendancePeriodsView = (
  viewId: string,
  periodYear: number,
  pagination: Pagination,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!viewId) return

    const loading = getState().attendance.attendancePeriodsViewLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().attendance.attendancePeriodsView?.entities[viewId]
      if (hasData) return
    }

    try {
      dispatch(setAttendancePeriodsViewLoading(true))
      const { result, status } = await apiGetAttendancePeriodsView(viewId, periodYear, pagination)
      if (status) {
        dispatch(setAttendancePeriodsView(result))
      }
    } finally {
      dispatch(setAttendancePeriodsViewLoading(false))
    }
  }
}
