import React, { FC, useMemo } from 'react'
import classNames from 'classnames'
import { Card, Skeleton, Space } from '~/core-components'
import { TimeDayDisplay } from '~/components'
import { useSysOptions } from '~/features/employee'
import { getDayCode, useLocationsDict, useShiftDaysDict, useShiftRolesDict, useShiftsDict } from '~/features/attendance'
import { getTextColor } from '~/utils'
import { ScheduleRecordViewDataState } from '../../../types'
import './ScheduledShiftDay.less'

export interface ScheduledShiftDayProps {
  schedule?: ScheduleRecordViewDataState
  showLocation?: boolean
  showShiftRole?: boolean
  loading?: boolean
  readOnly?: boolean
  onClick: () => void
}

export const ScheduledShiftDay: FC<ScheduledShiftDayProps> = ({
  schedule,
  showLocation,
  showShiftRole,
  readOnly,
  loading,
  onClick
}) => {
  const [workStatus] = useSysOptions('work_status')
  const [shiftsDict] = useShiftsDict()
  const [shiftDaysDict] = useShiftDaysDict(schedule?.shiftId || '')
  const [locationsDict] = useLocationsDict()
  const [shiftRolesDict] = useShiftRolesDict()

  const classes = classNames(
    'scheduled-shift-day',
    { 'scheduled-shift-day--readonly': readOnly },
    { [`scheduled-shift-day__work-status-${schedule?.workStatus}`]: schedule?.workStatus != null }
  )

  const shift = shiftsDict[schedule?.shiftId || '']
  const dayCode = getDayCode(schedule?.scheduleDate || '', schedule?.workStatus || '')
  const shiftDay = shiftDaysDict[dayCode]

  const shiftColor = shift?.color || '#ffffff'
  const textColor = useMemo(() => getTextColor(shiftColor), [shiftColor])
  const shiftCardStyle = useMemo(() => ({ backgroundColor: shiftColor, color: textColor }), [shiftColor, textColor])

  if (!schedule) return null

  if (loading) return <Skeleton.Input size="small" active />

  if (schedule.shiftId)
    return (
      <Card className={classes} style={shiftCardStyle} onClick={onClick}>
        <div className="scheduled-shift-day__name">{shift?.name}</div>
        <div className="scheduled-shift-day__time">
          <Space>
            <TimeDayDisplay time={shiftDay?.normalStartTime} day={shiftDay?.normalStartDay} />
            <span>-</span>
            <TimeDayDisplay time={shiftDay?.normalEndTime} day={shiftDay?.normalEndDay} />
          </Space>
        </div>
        {showLocation && schedule?.locationId && (
          <div className="scheduled-shift-day__location">
            <i className="fal fa-location-dot" /> {locationsDict[schedule.locationId]?.name}
          </div>
        )}
        {showShiftRole && schedule?.shiftRoleId && (
          <div className="scheduled-shift-day__shift-role">{shiftRolesDict[schedule.shiftRoleId]?.name}</div>
        )}
      </Card>
    )

  return (
    <Card className={classes} onClick={onClick}>
      {workStatus[schedule.workStatus]?.value}
    </Card>
  )
}
