import { ActionResult, ThunkResult } from '~/types/store'
import { showSuccess, showError } from '~/utils'
import { apiAddEmCareer } from '../api/em-career.api'
import { setEmployee } from '../reducers'
import { IEmCareerInfo } from '../types'
import { fetchEmCostCentres } from './fetch-em-costcentres'
import { fetchEmDepartments } from './fetch-em-departments'
import { fetchEmEmploymentTypes } from './fetch-em-employment-types'
import { fetchEmJobs } from './fetch-em-jobs'
import { fetchEmManagers } from './fetch-em-managers'
import { fetchEmOffices } from './fetch-em-offices'
import { fetchEmTeams } from './fetch-em-teams'
import { fetchEmCategories } from './fetch-em-categories'
import { fetchEmDivisions } from './fetch-em-divisions'
import { fetchEmGrps } from './fetch-em-grps'
import { fetchEmOtClasses } from './fetch-em-ot-classes'
import { fetchEmSections } from './fetch-em-sections'
import { fetchEmManagerSecondaries } from './fetch-em-manager-secondaries'

export const addEmCareer = (employeeId: string, emCareer: IEmCareerInfo): ThunkResult<void> => {
  return async (dispatch, getState): Promise<ActionResult | undefined> => {
    if (!employeeId) return

    const { status, result, errors, message, errorData } = await apiAddEmCareer(employeeId, {
      employeeId,
      ...emCareer
    })
    if (status) {
      if (result.refresh && Object.keys(result.refresh).length > 0) {
        const employee = getState().employee.employees.entities[employeeId]
        if (employee) {
          dispatch(setEmployee({ ...employee, ...result.refresh }))
        }
      }

      if (result.emDepartmentId) {
        dispatch(fetchEmDepartments(employeeId))
      }
      if (result.emDivisionId) {
        dispatch(fetchEmDivisions(employeeId))
      }
      if (result.emSectionId) {
        dispatch(fetchEmSections(employeeId))
      }
      if (result.emGrpId) {
        dispatch(fetchEmGrps(employeeId))
      }
      if (result.emCategoryId) {
        dispatch(fetchEmCategories(employeeId))
      }
      if (result.emTeamId) {
        dispatch(fetchEmTeams(employeeId))
      }
      if (result.emJobId) {
        dispatch(fetchEmJobs(employeeId))
      }
      if (result.emManagerId) {
        dispatch(fetchEmManagers(employeeId))
      }
      if (result.emManagerSecondaryId) {
        dispatch(fetchEmManagerSecondaries(employeeId))
      }
      if (result.emOfficeId) {
        dispatch(fetchEmOffices(employeeId))
      }
      if (result.emCostCentreId) {
        dispatch(fetchEmCostCentres(employeeId))
      }
      if (result.emEmploymentTypeId) {
        dispatch(fetchEmEmploymentTypes(employeeId))
      }
      if (result.emOtClassId) {
        dispatch(fetchEmOtClasses(employeeId))
      }

      showSuccess('Added')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
