import { showSuccess, showError } from '~/utils'
import { AttendancePeriodStatus } from '~/constants'
import { ThunkResult } from '~/types/store'
import { apiRevertToDraftAttendancePeriod } from '../api/attendance-period.api'
import { AttendancePeriodState } from '../types'
import { setAttendancePeriod } from '../reducers'

export const revertToDraftAttendancePeriod = (id: string): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const { status, errors, message, errorData } = await apiRevertToDraftAttendancePeriod(id)
    if (status) {
      let payRun = getState().attendance.attendancePeriods?.entities[id]
      dispatch(setAttendancePeriod({ ...(payRun as AttendancePeriodState), status: AttendancePeriodStatus.Draft }))

      showSuccess('Attendance period has marked as draft')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
