import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { SectionState } from '../types'

export interface SectionOverview extends SectionState {
  headName?: string
  headJob?: string
  headPhotoId?: string
  activeEmployees?: number
}

const EMPTY_SECTION_EXTRA: Partial<SectionOverview> = {
  headName: '',
  headJob: '',
  headPhotoId: '',
  activeEmployees: 0
}

export const selectSectionsOverview = createSelector(
  (state: StoreState) => state.master.masters['section'],
  (state: StoreState) => state.master.sectionsExtra,
  (sectionsState, sectionsExtraState): SectionOverview[] => {
    const sections = Object.values(sectionsState?.entities || {})

    return sections
      .map(section => ({
        ...(section as SectionState),
        ...(sectionsExtraState?.entities[section!.id] || EMPTY_SECTION_EXTRA)
      }))
      .sort((a, b) => {
        const aInactiveDate = new Date(a!.inactiveDate || '').getTime() || 0
        const bInactiveDate = new Date(b!.inactiveDate || '').getTime() || 0

        if (bInactiveDate === 0 && aInactiveDate === 0) {
          return a!.name.localeCompare(b!.name)
        } else {
          return aInactiveDate - bInactiveDate
        }
      })
  }
)
