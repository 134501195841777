import React, { FC, useState, useCallback, CSSProperties } from 'react'
import { useSelector } from 'react-redux'
import { ColumnsType, Table, TableRowSelection, Tag } from '~/core-components'
import { DrawerForm } from '~/components'
import { dispatch } from '~/stores/store'
import { ActionResult } from '~/types/store'
import { CompanyState } from '../../../types'
import { selectCompaniesOverview } from '../../../selectors'
import { addCompanyOffice } from '../../../actions'

interface AddCompanyDrawerProps {
  visible: boolean
  data?: {
    officeId: string
  }
  onClose: (action: 'save' | 'cancel') => void
}

type CompanyTable = CompanyState & {
  offices: { name: string; isMain: boolean }[]
}

const tableStyle: CSSProperties = { border: '1px solid #ededed', marginBottom: 15 }

export const AddCompanyDrawer: FC<AddCompanyDrawerProps> = ({ visible, data, onClose }: AddCompanyDrawerProps) => {
  const [loading, setLoading] = useState(false)
  const [existingCompanyId, setExistingCompanyId] = useState<string>()
  const companies = useSelector(selectCompaniesOverview)(data?.officeId)

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    if (data?.officeId && existingCompanyId) {
      setLoading(true)
      try {
        result = await dispatch(addCompanyOffice(existingCompanyId, data?.officeId))
      } finally {
        setLoading(false)
      }
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose('save')
    }
  }, [existingCompanyId, data, onClose])

  const columns: ColumnsType<CompanyTable> = [
    {
      title: 'Company',
      key: 'name',
      dataIndex: 'name'
    },
    {
      title: 'Office',
      key: 'offices',
      dataIndex: 'companies',
      render: (value: string, record) => (
        <>
          {record.offices.map((c, index) => (
            <div key={index}>
              {c.name}
              {c.isMain ? <Tag type="primary">main office</Tag> : ''}
            </div>
          ))}
        </>
      )
    }
  ]

  const rowSelection: TableRowSelection<CompanyTable> = {
    type: 'radio',
    onChange: selectedRowKeys => {
      if (selectedRowKeys.length > 0) setExistingCompanyId(selectedRowKeys[0] as string)
    }
  }

  return (
    <DrawerForm
      open={visible}
      title="Add company"
      onOk={handleOk}
      onClose={() => onClose('cancel')}
      confirmLoading={loading}
      width={650}
    >
      <Table
        rowSelection={rowSelection}
        rowKey="id"
        dataSource={companies}
        pagination={false}
        columns={columns}
        style={tableStyle}
      />
    </DrawerForm>
  )
}
