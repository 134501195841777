export interface ShiftRoleInfoState {
  name: string
  inactiveDate?: string
}

export interface ShiftRoleState extends ShiftRoleInfoState {
  id: string
}

export interface IShiftRole {
  name: string
  inactiveDate?: string
}

export interface IShiftRoleTeInfo {
  startDate: string
  te01: number
  te02: number
  te03: number
  te04: number
  te05: number
  te06: number
  te07: number
  te08: number
  te09: number
  te10: number
}

export interface IShiftRoleTe extends IShiftRoleTeInfo {
  id: string
  shiftRoleId: string
}

export interface ShiftRoleTeState {
  id: string
  shiftRoleId: string
  startDate: string
  te01: number
  te02: number
  te03: number
  te04: number
  te05: number
  te06: number
  te07: number
  te08: number
  te09: number
  te10: number
}

export const mapShiftRoleTeStateToShiftRoleTeInfo = (shiftRoleTe: ShiftRoleTeState): IShiftRoleTeInfo => ({
  startDate: shiftRoleTe.startDate,
  te01: shiftRoleTe.te01,
  te02: shiftRoleTe.te02,
  te03: shiftRoleTe.te03,
  te04: shiftRoleTe.te04,
  te05: shiftRoleTe.te05,
  te06: shiftRoleTe.te06,
  te07: shiftRoleTe.te07,
  te08: shiftRoleTe.te08,
  te09: shiftRoleTe.te09,
  te10: shiftRoleTe.te10
})
