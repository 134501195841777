import { showError, showSuccess } from '~/utils'
import { ThunkResult } from '~/types/store'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { apiUpdateTimeLog } from '../api/time-log.api'
import { ISaveTimeLog } from '../types'
import { refetchTimeLogsView } from '../reducers'

export const updateTimeLog = (id: string, before: ISaveTimeLog, request: ISaveTimeLog): ThunkResult<void> => {
  return async dispatch => {
    if (!id) return

    const after = { ...before, ...request } as ISaveTimeLog
    const patch = generateJsonPatch<ISaveTimeLog>(before, after)
    const { status, errors, message, errorData } = await apiUpdateTimeLog(id, patch)
    if (status) {
      dispatch(refetchTimeLogsView())
      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
