import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { PayAccountState } from '../types'

export const selectPayRecordAccounts = createSelector(
  (state: StoreState) => state.payroll.payAccounts,
  payAccountsState =>
    memoize((payRecordId: string): PayAccountState[] => {
      const payAccounts = Object.values(payAccountsState[payRecordId]?.entities || {}) as PayAccountState[]
      return payAccounts
    })
)
