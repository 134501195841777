import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { FetchStrategy } from '~/types/common'
import { fetchShiftCategories } from '../actions'
import { ShiftCategoryState } from '../types'
import { selectShiftCategories } from '../selectors'

export const useShiftCategories = (fetchStrategy: FetchStrategy = 'always'): [ShiftCategoryState[], boolean] => {
  const data = useSelector(selectShiftCategories)
  const loading = useSelector((state: StoreState) => state.attendance.shiftCategoriesLoading)

  useEffect(() => {
    dispatch(fetchShiftCategories({ strategy: fetchStrategy }))
  }, [fetchStrategy])

  return [data, loading]
}
