import React, { FC, useCallback, useMemo } from 'react'
import { Button, Dropdown, Space, Tooltip } from '~/core-components'

interface RptSortingBtnProps {
  sorting: string
  onSelect: (sorting: string) => void
}

export const RptSortingBtn: FC<RptSortingBtnProps> = ({ sorting, onSelect }) => {
  const tooltipText = useMemo(() => (sorting !== '' ? `Sorted by ${sorting.replace('_', ' ')}` : 'Sorting'), [sorting])

  const handleChange = useCallback(
    (value: string) => {
      onSelect(value)
    },
    [onSelect]
  )

  return (
    <>
      <Tooltip title={tooltipText}>
        <Dropdown
          trigger={['click']}
          menu={{
            items: [
              {
                key: 'employee_name',
                label: (
                  <Space>
                    <i className="fal fa-arrow-down-a-z" />
                    Sort by employee name
                  </Space>
                ),
                onClick: () => handleChange('employee_name')
              },
              {
                key: 'employee_no',
                label: (
                  <Space>
                    <i className="fal fa-arrow-down-1-9" />
                    Sort by employee no.
                  </Space>
                ),
                onClick: () => handleChange('employee_no')
              }
            ]
          }}
        >
          <Button>
            <Space>
              {sorting === 'employee_name' ? (
                <i className="fal fa-arrow-down-a-z" />
              ) : (
                <i className="fal fa-arrow-down-1-9" />
              )}
              <i className="fa-light fa-angle-down" />
            </Space>
          </Button>
        </Dropdown>
      </Tooltip>
    </>
  )
}
