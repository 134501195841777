import { replaceTimeLogTemplates, setTimeLogTemplatesLoading } from '../reducers'
import { apiGetTimeLogTemplates } from '../api/time-log-template.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchTimeLogTemplates = (options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().attendance.timeLogTemplatesLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().attendance.timeLogTemplates?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setTimeLogTemplatesLoading(true))
      const { result, status } = await apiGetTimeLogTemplates()
      if (status) {
        dispatch(replaceTimeLogTemplates(result))
      }
    } finally {
      dispatch(setTimeLogTemplatesLoading(false))
    }
  }
}
