import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { LeavePeriodYearState } from '../types'

export const selectLeavePeriodYears = createSelector(
  (state: StoreState) => state.leave.leavePeriodYears,
  leavePeriodYearState => {
    return Object.values(leavePeriodYearState?.entities || {}).sort((a, b) => {
      return b!.periodYear - a!.periodYear
    }) as LeavePeriodYearState[]
  }
)
