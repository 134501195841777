import { showSuccess, showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { apiAddCurrency } from '../api/currency.api'
import { setCurrency } from '../reducers'
import { ICurrency, CurrencyState } from '../types'
import { setKeyvalue } from '~/features/master'

export const addCurrency = (currency: ICurrency): ThunkResult<void> => {
  return async (dispatch): Promise<ActionResult> => {
    const { status, result, errors, message, errorData } = await apiAddCurrency(currency)
    if (status) {
      const newCurrency: CurrencyState = { ...currency, isSeed: false }
      dispatch(setCurrency(newCurrency))
      dispatch(
        setKeyvalue({ kv: 'currency', data: { key: currency.code, value: currency.name, isShown: currency.isShown } })
      )
      showSuccess('Added')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
