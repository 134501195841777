import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { FetchStrategy } from '~/types/common'
import { ShiftState } from '../types'
import { fetchShifts } from '../actions'
import { selectShifts } from '../selectors'

export const useShifts = (fetchStrategy: FetchStrategy = 'always'): [ShiftState[], boolean] => {
  const shifts = useSelector(selectShifts)
  const loading = useSelector((state: StoreState) => state.attendance.shiftsLoading)

  useEffect(() => {
    dispatch(fetchShifts({ strategy: fetchStrategy }))
  }, [fetchStrategy])

  return [shifts, loading]
}
