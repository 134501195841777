import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import moment from 'moment-timezone'
import padStart from 'lodash/padStart'
import { Checkbox, CheckboxChangeEvent, Form, Input } from '~/core-components'
import { Col, DrawerForm, EmpKeyValues, PeriodKeyValues, Row, SysOptions } from '~/components'
import { selectMasterById, selectMasters, PayGroupState } from '~/features/master'
import { useFocus } from '~/hooks/use-focus'
import { PayRunType } from '~/constants'
import { PAY_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { KeyValue } from '~/types/common'
import { ActionResult, Errors } from '~/types/store'
import { IPayRunInfoNew, PayRunState } from '../../types'
import { addPayRun, processPayroll } from '../../actions'

export interface AddPayRunDrawerProps {
  visible: boolean
  defaultValue?: PayRunState
  onClose: () => void
}

interface PayRunForm extends IPayRunInfoNew {
  processAll: boolean
}

export const EMPTY_FORM_DATA: PayRunForm = {
  payGroupId: '',
  payPeriod: '',
  payRunType: '',
  payDate: '',
  processAll: true
}

const currentPeriod = moment().format('YYYY-MM')

export const AddPayRunDrawer: FC<AddPayRunDrawerProps> = ({ visible, defaultValue, onClose }) => {
  const [focusRef, setFocus] = useFocus(true)
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<PayRunForm>(EMPTY_FORM_DATA)
  const [resetForm, setResetForm] = useState(0)
  const [errors, setErrors] = useState<Errors>()
  const payGroups = useSelector(selectMasters)('payGroup') as (PayGroupState | undefined)[]
  const payGroup = useSelector(selectMasterById)('payGroup', formData.payGroupId) as PayGroupState | undefined
  const history = useHistory()

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  const getPayDate = useCallback((payRunType: string, payPeriod: string, payGroup?: PayGroupState) => {
    if (payGroup) {
      const daysInMonth = moment(`${payPeriod}-01`).daysInMonth()
      if (payRunType === PayRunType.wholeMonth) {
        const day = payGroup.paymentDay > daysInMonth ? daysInMonth : payGroup.paymentDay
        let payDate = moment(`${payPeriod}-${padStart(day.toString(), 2, '0')}`)
        if (payGroup.paymentMonth !== 0) payDate = payDate.add(payGroup.paymentMonth, 'month')
        if (payDate.isValid()) {
          return payDate.format('YYYY-MM-DD')
        }
      } else if (payRunType === PayRunType.firstHalf) {
        const day = payGroup.paymentDay1 > daysInMonth ? daysInMonth : payGroup.paymentDay1
        let payDate = moment(`${payPeriod}-${padStart(day.toString(), 2, '0')}`)
        if (payGroup.paymentMonth1 !== 0) payDate = payDate.add(payGroup.paymentMonth1, 'month')
        if (payDate.isValid()) {
          return payDate.format('YYYY-MM-DD')
        }
      } else if (payRunType === PayRunType.secondHalf) {
        const day = payGroup.paymentDay2 > daysInMonth ? daysInMonth : payGroup.paymentDay2
        let payDate = moment(`${payPeriod}-${padStart(day.toString(), 2, '0')}`)
        if (payGroup.paymentMonth2 !== 0) payDate = payDate.add(payGroup.paymentMonth2, 'month')
        if (payDate.isValid()) {
          return payDate.format('YYYY-MM-DD')
        }
      }
    }
    return ''
  }, [])

  useEffect(() => {
    const newData: PayRunForm = { ...EMPTY_FORM_DATA }

    if (defaultValue) {
      const { payGroupId, payPeriod, payRunType } = defaultValue
      newData.payGroupId = payGroupId
      newData.payPeriod = payPeriod
      newData.payRunType = payRunType
    }

    if (!newData.payPeriod) {
      newData.payPeriod = currentPeriod
    }

    if ((payGroups?.length || 0) > 0) {
      const defaultPayGroup = payGroups.find(pg => pg?.id === newData.payGroupId)
      const wholeMonthPayGroup = payGroups.find(pg => !pg?.isMidMonth)
      const firstPayGroup = payGroups[0]
      const payGroup = defaultPayGroup || wholeMonthPayGroup || firstPayGroup

      newData.payDate = getPayDate(newData.payRunType, newData.payPeriod, payGroup)
      if (payGroup) {
        if (!newData.payGroupId) {
          newData.payGroupId = payGroup.id
        }
        if (!newData.payRunType) {
          newData.payRunType = payGroup?.isMidMonth ? PayRunType.firstHalf : PayRunType.wholeMonth
        }
      }
    }

    setFormData({ ...newData })
  }, [resetForm, defaultValue, payGroups, getPayDate])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(data => ({ ...data, ...updates }))
  }, [])

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      result = await dispatch(addPayRun(formData))
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setResetForm(resetForm + 1)

      if (result?.result?.id) {
        const payRunId = result?.result?.id
        history.push(PAY_ROUTES.payRun.replace(':id', payRunId).replace(':tab?', 'records'))

        if (formData.processAll) {
          dispatch(processPayroll(payRunId, undefined, 'all'))
        }
      }
    }
  }, [formData, onClose, resetForm, history])

  const handleCloseDrawer = useCallback(() => {
    typeof onClose === 'function' && onClose()
  }, [onClose])

  return (
    <DrawerForm
      open={visible}
      title="Add payroll run"
      okText="Create"
      onClose={handleCloseDrawer}
      confirmLoading={loading}
      width={500}
      className="add-payrun-drawer"
      formId="form-add-payrun"
    >
      <Form id="form-add-payrun" onFinish={handleOk}>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Payroll group"
              validateStatus={errors?.payGroupId ? 'error' : ''}
              help={errors?.payGroupId}
            >
              <EmpKeyValues
                id="payGroup"
                ref={focusRef}
                value={formData.payGroupId}
                onChange={(value: string) => {
                  const payGroup = payGroups.find(pg => pg?.id === value)
                  const payRunType = payGroup?.isMidMonth ? PayRunType.firstHalf : PayRunType.wholeMonth
                  const payDate = getPayDate(payRunType, formData.payPeriod, payGroup)
                  handleFormDataChange({ payGroupId: value, payRunType, payDate })
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Period" validateStatus={errors?.payPeriod ? 'error' : ''} help={errors?.payPeriod}>
              <PeriodKeyValues
                value={formData.payPeriod}
                startMonth={currentPeriod}
                noOfMonthBefore={-18}
                noOfMonthAfter={10}
                onChange={(value: string) => {
                  const payDate = getPayDate(formData.payRunType, value, payGroup)
                  handleFormDataChange({ payPeriod: value, payDate })
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Run type" validateStatus={errors?.payRunType ? 'error' : ''} help={errors?.payRunType}>
              <SysOptions
                type="pay_run_type"
                value={formData.payRunType}
                onFilter={(value: KeyValue | undefined) => {
                  if (payGroup?.isMidMonth) {
                    return [PayRunType.firstHalf, PayRunType.secondHalf, PayRunType.adhoc].includes(value?.key || '')
                  } else {
                    return [PayRunType.wholeMonth, PayRunType.adhoc].includes(value?.key || '')
                  }
                }}
                onChange={(value: string) => {
                  const payDate = getPayDate(value, formData.payPeriod, payGroup)
                  handleFormDataChange({ payRunType: value, payDate })
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Pay date" validateStatus={errors?.payDate ? 'error' : ''} help={errors?.payDate}>
              <Input.Date
                value={formData.payDate ? moment(formData.payDate) : undefined}
                onChange={(value: moment.Moment | null) =>
                  handleFormDataChange({ payDate: value?.format('YYYY-MM-DD') })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="">
              <Checkbox
                checked={formData.processAll}
                onChange={(event: CheckboxChangeEvent) => {
                  handleFormDataChange({ processAll: event.target.checked })
                }}
              >
                Calculate for all employees
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}
