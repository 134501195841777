import { getBaseUrl } from '~/utils'
import { SSOrgChartState, SSOrgChartRes } from './ssorgchart.state'

const EMPTY_ORG_CHART: SSOrgChartState = {
  id: '',
  person: { id: '', avatar: '', avatarSrc: '', department: '', name: '', title: '', totalReports: 0 },
  hasParent: false,
  hasChild: false,
  isHighlight: false,
  children: []
}

export const mapSSOrgChartResponsesToSSOrgChartState = (responses: SSOrgChartRes[]): SSOrgChartState => {
  let orgChart = EMPTY_ORG_CHART

  if (responses) {
    // get the first object because the OrgChart needs only one object
    let firstChart = responses.shift()
    if (firstChart !== undefined) {
      orgChart = mapSSOrgChartResToSSOrgChartState(firstChart, true)

      if (orgChart.hasChild) {
        const childs = responses.filter(dto => dto.managerId === orgChart.id)

        if (childs.length > 0) {
          childs.forEach(c => {
            const child = mapChildRecursive(mapSSOrgChartResToSSOrgChartState(c), responses)
            orgChart.children?.push(child)
          })
        }
      }
    }
  }

  return orgChart
}

export const mapChildRecursive = (child: SSOrgChartState, responses: SSOrgChartRes[]): SSOrgChartState => {
  if (child.hasChild) {
    const childs = responses.filter(dto => dto.managerId === child.id)

    if (childs.length > 0) {
      childs.forEach(c => {
        const gchild = mapChildRecursive(mapSSOrgChartResToSSOrgChartState(c), responses)
        child.children?.push(gchild)
      })
    }
  }

  return child
}

const baseUrl = getBaseUrl('/filestore')

export const mapSSOrgChartResToSSOrgChartState = (d: SSOrgChartRes, isFirst: boolean = false): SSOrgChartState => {
  let title = []
  if (d.department) title.push(d.department)
  if (d.job) title.push(d.job)

  let chartNode: SSOrgChartState = {
    id: d.id,
    person: {
      id: d.id,
      avatar: '/avatar-personnel.svg',
      avatarSrc: d.photoId ? `${baseUrl}/file/${d.photoId}/thumbnailphoto/48` : '',
      department: d.department,
      name: d.employeeName,
      title: title.join(' - '),
      totalReports: d.totalReports
    },
    hasParent: d.hasParent,
    hasChild: d.hasChild,
    isHighlight: d.isHighlight
  }

  if (!d.hasParent || isFirst) {
    chartNode.children = []
  }

  return chartNode
}
