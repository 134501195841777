import { showSuccess } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { apiAddCompanyOffice } from '../api/company.api'
import { setCompanyOffice } from '../reducers'
import { CompanyOfficeState } from '../types'

export const addCompanyOffice = (companyId: string, officeId: string): ThunkResult<void> => {
  return async (dispatch): Promise<ActionResult | undefined> => {
    if (!companyId) return

    const { status, result, errors } = await apiAddCompanyOffice(companyId, officeId)
    if (status) {
      const newCompanyOffice: CompanyOfficeState = {
        id: result.id,
        isMain: result.isMain || false,
        companyId,
        officeId
      }
      dispatch(setCompanyOffice(newCompanyOffice))

      showSuccess('Saved')
    }
    return { result, errors }
  }
}
