import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchXeroAccounts } from '../actions'
import { XeroAccountState } from '../types'
import { selectXeroAccounts } from '../selectors'

export const useXeroAccounts = (
  tenantId?: string,
  fetchStrategy: FetchStrategy = 'when-empty'
): [XeroAccountState[], boolean] => {
  const accounts = useSelector(selectXeroAccounts)(tenantId || '')
  const loading = useSelector((state: StoreState) => state.payroll.xeroAccountsLoading[tenantId || ''])

  useEffect(() => {
    dispatch(fetchXeroAccounts(tenantId, { strategy: fetchStrategy }))
  }, [tenantId, fetchStrategy])

  return [accounts, loading]
}
