import { ActionResult, ThunkResult } from '~/types/store'
import { emptyGuid } from '~/constants'
import { showSuccess, showError } from '~/utils'
import { apiAddEmAttendanceGroup } from '../api/em-attendance-group.api'
import { setEmAttendanceGroup, setEmployee } from '../reducers'
import { IEmAttendanceGroupInfo, EmAttendanceGroupState } from '../types'

export const addEmAttendanceGroup = (
  employeeId: string,
  emAttendanceGroup: IEmAttendanceGroupInfo
): ThunkResult<void> => {
  return async (dispatch, getState): Promise<ActionResult | undefined> => {
    if (!employeeId) return

    const { status, result, errors, message, errorData } = await apiAddEmAttendanceGroup(employeeId, {
      id: emptyGuid,
      employeeId,
      ...emAttendanceGroup,
      attendanceGroupId: emAttendanceGroup.attendanceGroupId || emptyGuid
    })
    if (status) {
      if (result.emAttendanceGroupId) {
        const employee = getState().employee.employees.entities[employeeId]
        if (employee) {
          dispatch(setEmployee({ ...employee, emAttendanceGroupId: result.emAttendanceGroupId }))
        }
      }

      const newEmAttendanceGroup = {
        id: result.id,
        employeeId,
        ...emAttendanceGroup
      } as EmAttendanceGroupState
      dispatch(setEmAttendanceGroup({ employeeId, data: newEmAttendanceGroup }))

      showSuccess('Added')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
