import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { Col, DayKeyValues, DrawerForm, Row, SysOptions } from '~/components'
import { Form, Link, Alert } from '~/core-components'
import { emptyGuid, LveMonthProrationType } from '~/constants'
import { ActionResult, Errors, StoreState } from '~/types/store'
import { dispatch } from '~/stores/store'
import { LeaveTypeState, LtProrationMState } from '../../../types'
import { fetchLtProrationM, updateLtProrationM } from '../../../actions'
import './MutateLtProrationMDrawer.less'

interface MutateLtProrationMDrawerProps {
  visible: boolean
  leaveType?: LeaveTypeState
  onClose: () => void
}

export const MutateLtProrationMDrawer: FC<MutateLtProrationMDrawerProps> = ({ visible, leaveType, onClose }) => {
  const leaveTypeId = leaveType?.id || ''
  const ltProrationM = useSelector((state: StoreState) =>
    Object.values(state.leave.ltProrationM[leaveTypeId]?.entities || []).sort((a, b) => {
      return a!.dayFrom - b!.dayFrom
    })
  ) as LtProrationMState[]
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<Errors>()
  const [formDataNewHire, setFormDataNewHire] = useState<LtProrationMState[]>([])
  const [formDataResignee, setFormDataResignee] = useState<LtProrationMState[]>([])

  const NEW_PRORATION_HIRE: LtProrationMState[] = [
    {
      id: emptyGuid,
      leaveTypeId: leaveTypeId || emptyGuid,
      type: LveMonthProrationType.start,
      dayFrom: 1,
      value: 0
    }
  ]

  const NEW_PRORATION_RESIGNEE: LtProrationMState[] = [
    {
      id: emptyGuid,
      leaveTypeId: leaveTypeId || emptyGuid,
      type: LveMonthProrationType.end,
      dayFrom: 1,
      value: 0
    }
  ]

  useEffect(() => {
    if (leaveTypeId) dispatch(fetchLtProrationM(leaveTypeId))
  }, [leaveTypeId])

  useEffect(() => {
    setErrors(undefined)
  }, [visible])

  useDeepCompareEffect(() => {
    if (ltProrationM) {
      setFormDataNewHire(ltProrationM.filter(x => x.type === LveMonthProrationType.start))
      setFormDataResignee(ltProrationM.filter(x => x.type === LveMonthProrationType.end))
    }
  }, [ltProrationM, visible])

  const handleFormDataNewHireChange = useCallback((formData: LtProrationMState[]) => {
    setFormDataNewHire(formData)
  }, [])

  const handleFormDataResigneeChange = useCallback((formData: LtProrationMState[]) => {
    setFormDataResignee(formData)
  }, [])

  const handleOk = useCallback(async () => {
    let formData: LtProrationMState[] = [...formDataNewHire, ...formDataResignee]
    let result: ActionResult | undefined
    setLoading(true)
    try {
      if (formData) {
        result = await dispatch(updateLtProrationM(leaveTypeId, formData))
      }
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
    }
  }, [leaveTypeId, formDataNewHire, formDataResignee, onClose])

  return (
    <DrawerForm
      open={visible}
      title="Proration by month"
      onClose={onClose}
      confirmLoading={loading}
      width={650}
      className="mutate-lt-proration-drawer"
      formId="form-lt-proration-m"
    >
      <Form id="form-lt-proration-m" onFinish={handleOk}>
        <Row>
          <Col span={24}>
            <Form.Item label="Leave type">
              <label>{leaveType?.name}</label>
            </Form.Item>
          </Col>
        </Row>
        {errors && (
          <Row>
            <Col span={24}>
              <Alert
                type="error"
                message={
                  <>
                    {Object.values(errors).map((error, index) => (
                      <div key={index}>{error}</div>
                    ))}
                  </>
                }
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col span={24}>
            <div className="section__title">New Hire</div>
            {formDataNewHire.map((dat, index) => (
              <Row gutter={30} key={index} className="section-row">
                <Col flex="145px">
                  {index === 0 && <div className="section-row__title">Hire date</div>}
                  <DayKeyValues
                    allowClear={false}
                    value={dat?.dayFrom}
                    onChange={(value: number) =>
                      handleFormDataNewHireChange([
                        ...formDataNewHire.slice(0, index),
                        { ...formDataNewHire[index], dayFrom: value },
                        ...formDataNewHire.slice(index + 1, formDataNewHire.length)
                      ])
                    }
                  />
                </Col>
                <Col flex="150px">
                  {index === 0 && <div className="section-row__title">Entitlement granted</div>}
                  <SysOptions
                    type="lve_prorated_to"
                    allowClear={false}
                    value={`${dat?.value}`}
                    onChange={(value?: any) =>
                      handleFormDataNewHireChange([
                        ...formDataNewHire.slice(0, index),
                        { ...formDataNewHire[index], value: value },
                        ...formDataNewHire.slice(index + 1, formDataNewHire.length)
                      ])
                    }
                    style={{ width: 250 }}
                  />
                </Col>
                <Col>
                  <Link
                    onClick={() =>
                      handleFormDataNewHireChange([
                        ...formDataNewHire.slice(0, index),
                        ...formDataNewHire.slice(index + 1, formDataNewHire.length)
                      ])
                    }
                  >
                    remove
                  </Link>
                </Col>
              </Row>
            ))}
            <Form.Item>
              <Link onClick={() => handleFormDataNewHireChange([...formDataNewHire, ...NEW_PRORATION_HIRE])}>
                add more
              </Link>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className="section__title">Resignee</div>
            {formDataResignee.map((dat, index) => (
              <Row gutter={30} key={index} className="section-row">
                <Col flex="145px">
                  {index === 0 && <div className="section-row__title">Last day of service</div>}
                  <DayKeyValues
                    allowClear={false}
                    value={dat?.dayFrom}
                    onChange={(value: number) =>
                      handleFormDataResigneeChange([
                        ...formDataResignee.slice(0, index),
                        { ...formDataResignee[index], dayFrom: value },
                        ...formDataResignee.slice(index + 1, formDataResignee.length)
                      ])
                    }
                  />
                </Col>
                <Col flex="150px">
                  {index === 0 && <div className="section-row__title">Entitlement granted</div>}
                  <SysOptions
                    type="lve_prorated_to"
                    allowClear={false}
                    value={`${dat?.value}`}
                    onChange={(value?: any) =>
                      handleFormDataResigneeChange([
                        ...formDataResignee.slice(0, index),
                        { ...formDataResignee[index], value: value },
                        ...formDataResignee.slice(index + 1, formDataResignee.length)
                      ])
                    }
                    style={{ width: 250 }}
                  />
                </Col>
                <Col>
                  <Link
                    onClick={() =>
                      handleFormDataResigneeChange([
                        ...formDataResignee.slice(0, index),
                        ...formDataResignee.slice(index + 1, formDataResignee.length)
                      ])
                    }
                  >
                    remove
                  </Link>
                </Col>
              </Row>
            ))}
            <Form.Item>
              <Link onClick={() => handleFormDataResigneeChange([...formDataResignee, ...NEW_PRORATION_RESIGNEE])}>
                add more
              </Link>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Alert
              message="When the setting is not configured, full month entitlement will be granted to employee who joins between 1st – 15th or resigns between between 15th – last day of the month."
              type="info"
              showIcon
            />
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}
