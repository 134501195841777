import { removeMyClaimRecordComment } from '../reducers'
import { showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiDeleteMyClaimRecordComment } from '../api/ss-claim-record-comment.api'

export const deleteClaimRecordComment = (claimRecordId: string, id: string): ThunkResult<void> => {
  return async dispatch => {
    if (!claimRecordId || !id) return

    const { status, errors, message, errorData } = await apiDeleteMyClaimRecordComment(claimRecordId, id)
    if (status) dispatch(removeMyClaimRecordComment({ claimRecordId, id }))
    else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
