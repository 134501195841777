import { request, getBaseUrl } from '~/utils/request'
import { ICalendarAndPattern, CalendarPatternState } from '../types'

const baseUrl = getBaseUrl('/employee/calendar')

export const apiGetCalendarPatterns = (calendarId: string) =>
  request<CalendarPatternState[]>('get', `${baseUrl}/${calendarId}/pattern`)

export const apiAddCalendarAndPatterns = (calendarAndPatterns: ICalendarAndPattern) =>
  request<{ id?: string; wdPerWeek: number; patterns?: CalendarPatternState[] }>(
    'post',
    `${baseUrl}/add-with-patterns`,
    calendarAndPatterns
  )
