import { setEmJob, setEmJobsLoading } from '../reducers'
import { apiGetEmJob } from '../api/em-job.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchEmJob = (
  employeeId: string,
  emJobId: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!employeeId || !emJobId) return

    const loading = getState().employee.emJobsLoading[employeeId]
    if (loading) return
    if (options.strategy === 'when-empty') {
      const hasData = getState().employee.emJobs[employeeId]?.entities[emJobId]
      if (hasData) return
    }

    try {
      dispatch(setEmJobsLoading({ employeeId, loading: true }))
      const { result, status } = await apiGetEmJob(employeeId, emJobId)
      if (status) {
        dispatch(setEmJob({ employeeId, data: result }))
      }
    } finally {
      dispatch(setEmJobsLoading({ employeeId, loading: false }))
    }
  }
}
