import React, { FC } from 'react'
import { Form } from '~/core-components'
import { SysOptions } from '~/components'
import { Errors } from '~/types/store'
import { ILeaveApply } from '../../../../types'
import { useSysOptions } from '~/features/employee'
import { SSLeaveApply } from '~/features/my'
import { KeyValue } from '~/types/common'

interface RelationshipFieldProps {
  label?: string
  formData: ILeaveApply | SSLeaveApply
  readOnly?: boolean
  errors?: Errors
  filterRelations?: string[]
  onChange: (updates: { [field: string]: any }) => void
  helpText?: string
}

export const RelationshipField: FC<RelationshipFieldProps> = ({
  label = 'Relationship',
  formData,
  readOnly,
  errors,
  filterRelations,
  onChange,
  helpText
}) => {
  const [relationship] = useSysOptions('lve_relationship')
  return (
    <Form.Item
      label={label}
      validateStatus={errors?.relationship ? 'error' : ''}
      help={errors?.relationship || helpText}
    >
      {readOnly ? (
        relationship[formData.relationship || '']?.value || '-'
      ) : (
        <SysOptions
          type="lve_relationship"
          value={formData.relationship}
          onFilter={(value?: KeyValue) => {
            if (filterRelations && filterRelations.length > 0) {
              return filterRelations.includes(value?.key || '')
            }
            return true
          }}
          onChange={(relationship: string) => onChange({ relationship })}
        />
      )}
    </Form.Item>
  )
}
