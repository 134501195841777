import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchEmployee } from '../actions'
import { EmployeeState } from '../types'

export const useEmployee = (
  employeeId?: string,
  fetchStrategy: FetchStrategy = 'when-empty'
): [EmployeeState | undefined, boolean] => {
  const employee = useSelector((state: StoreState) => state.employee.employees?.entities[employeeId || ''])
  const loading = useSelector((state: StoreState) => state.employee.employeesLoading)
  const refetch = useSelector((state: StoreState) => state.employee.employeesRefetch[employeeId || ''])

  useEffect(() => {
    dispatch(fetchEmployee(employeeId, { strategy: fetchStrategy }))
  }, [employeeId, fetchStrategy, refetch])

  return [employee, loading]
}
