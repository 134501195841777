import { replaceDailyRecordEmployees, setDailyRecordEmployeesLoading } from '../reducers'
import { apiGetDailyRecordEmployees } from '../api/daily-record.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchDailyRecordEmployees = (
  clockDate?: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!clockDate) return

    const loading = getState().attendance.dailyRecordEmployeesLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().attendance.dailyRecordEmployees?.entities
      if (hasData) return
    }

    try {
      dispatch(setDailyRecordEmployeesLoading(true))
      const { result, status } = await apiGetDailyRecordEmployees(clockDate)
      if (status) {
        dispatch(replaceDailyRecordEmployees(result))
      }
    } finally {
      dispatch(setDailyRecordEmployeesLoading(false))
    }
  }
}
