import React, { FC, ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Checkbox, CheckboxChangeEvent, Divider, Form, Input, Select } from '~/core-components'
import { Col, DayKeyValues, EmpKeyValues, Row } from '~/components'
import { Errors } from '~/types/store'
import { fetchCompanies } from '~/features/master/actions'
import { selectCompanies } from '~/features/master/reducers'
import { useFocus } from '~/hooks/use-focus'
import { dispatch } from '~/stores/store'
import { IPayGroupInfo } from '../../../types'
import { ordinalSuffix } from '../../../../../utils/ordinalSuffix'

interface PayGroupInfoFormProps {
  data: IPayGroupInfo
  readOnly?: boolean
  errors?: Errors
  onChange: (data: IPayGroupInfo) => void
}

export const EMPTY_PAYGROUP_INFO_FORM_DATA: IPayGroupInfo = {
  name: '',
  companyId: '',
  startDay: 1,
  startDay2: 0,
  paymentDay: 0,
  paymentMonth: 0,
  paymentDay1: 0,
  paymentMonth1: 0,
  paymentDay2: 0,
  paymentMonth2: 0,
  isMidMonth: false,
  otStartDay: 0,
  otStartMonth: 0,
  otStartDay1: 0,
  otStartMonth1: 0,
  otStartDay2: 0,
  otStartMonth2: 0,
  isAutoPayslipPublish: true,
  excludePayslipEmployeeIds: ''
}

const payDayStyle = { marginRight: -1 }
const payMonthStyle = { width: 150 }

export const PayGroupInfoForm: FC<PayGroupInfoFormProps> = ({
  data,
  readOnly = false,
  errors,
  onChange
}: PayGroupInfoFormProps) => {
  const companies = useSelector(selectCompanies)
  const [formData, setFormData] = useState<IPayGroupInfo>(EMPTY_PAYGROUP_INFO_FORM_DATA)
  const [focusRef] = useFocus(!readOnly)
  const [isInactive, setIsInactive] = useState(false)

  useEffect(() => {
    dispatch(fetchCompanies({ strategy: 'when-empty' }))
  }, [])

  useEffect(() => {
    if (data) {
      if (companies.length === 1) {
        setFormData({ ...data, companyId: companies[0].id })
      } else {
        setFormData(data)
      }
      setIsInactive(!!data.inactiveDate)
    } else {
      setFormData(EMPTY_PAYGROUP_INFO_FORM_DATA)
      setIsInactive(false)
    }
  }, [data, companies])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      const updated = { ...formData, ...updates }
      setFormData(updated)
      setIsInactive(!!updated.inactiveDate)
      typeof onChange === 'function' && onChange(updated)
    },
    [formData, onChange]
  )

  return (
    <>
      <Row gutter={15}>
        <Col flex="auto">
          <Form.Item label="Payroll group name" validateStatus={errors?.name ? 'error' : ''} help={errors?.name}>
            <Input
              ref={focusRef}
              value={formData.name}
              readOnly={readOnly}
              onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ name: event.target.value })}
            />
          </Form.Item>
        </Col>
        <Col flex="none">
          <Form.Item label="Inactive" validateStatus={errors?.inactiveDate ? 'error' : ''} help={errors?.inactiveDate}>
            <Checkbox
              checked={isInactive}
              readOnly={readOnly}
              onChange={(event: CheckboxChangeEvent) => {
                setIsInactive(event.target.checked)
                handleFormDataChange({ inactiveDate: event.target.checked ? moment().format('YYYY-MM-DD') : '' })
              }}
            />
            {/* {isInactive && (
              <Input.Date
                value={formData.inactiveDate ? moment(formData.inactiveDate) : undefined}
                inputReadOnly={readOnly}
                onChange={(value: moment.Moment | null) =>
                  handleFormDataChange({ inactiveDate: value?.format('YYYY-MM-DD') })
                }
              />
            )} */}
          </Form.Item>
        </Col>
      </Row>
      <Row hidden={companies.length === 1}>
        <Col span={24}>
          <Form.Item label="Company" validateStatus={errors?.companyId ? 'error' : ''} help={errors?.companyId}>
            <EmpKeyValues
              id="company"
              value={formData.companyId}
              readOnly={readOnly}
              onChange={(value: string) => handleFormDataChange({ companyId: value })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <i>
            This payroll period starts from <u>{ordinalSuffix(formData.startDay)}</u> of the month till{' '}
            {formData.startDay === 1 ? (
              <u>end of the month</u>
            ) : (
              <>
                <u>{ordinalSuffix(formData.startDay - 1)}</u> of next month
              </>
            )}
          </i>
        </Col>
      </Row>
      <Divider />
      <Row hidden={formData.isMidMonth}>
        <Col span={24}>
          <Form.Item label="Payment day" validateStatus={errors?.paymentDay ? 'error' : ''} help={errors?.paymentDay}>
            <DayKeyValues
              value={formData.paymentDay}
              style={payDayStyle}
              readOnly={readOnly}
              onChange={(value: number) => handleFormDataChange({ paymentDay: value })}
            />
            <Select
              value={formData.paymentMonth}
              style={payMonthStyle}
              allowClear={false}
              readOnly={readOnly}
              onChange={(value: number) => handleFormDataChange({ paymentMonth: value })}
            >
              <Select.Option value={0}>Current month</Select.Option>
              <Select.Option value={1}>Next month</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row hidden={formData.isMidMonth}>
        <Col span={24}>
          <Form.Item
            label="Overtime period starts on"
            validateStatus={errors?.otStartDay ? 'error' : ''}
            help={errors?.otStartDay}
          >
            <DayKeyValues
              value={formData.otStartDay}
              style={payDayStyle}
              readOnly={readOnly}
              onChange={(value: number) => handleFormDataChange({ otStartDay: value })}
            />
            <Select
              value={formData.otStartMonth}
              style={payMonthStyle}
              allowClear={false}
              readOnly={readOnly}
              onChange={(value: number) => handleFormDataChange({ otStartMonth: value })}
            >
              <Select.Option value={-1}>Previous month</Select.Option>
              <Select.Option value={0}>Current month</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item label="">
            <Checkbox
              readOnly={readOnly}
              checked={formData.isMidMonth}
              onChange={(event: CheckboxChangeEvent) => {
                if (event.target.checked) {
                  handleFormDataChange({
                    isMidMonth: event.target.checked,
                    paymentDay2: formData.paymentDay,
                    otStartDay1: formData.otStartDay,
                    otStartMonth1: formData.otStartMonth
                  })
                } else {
                  handleFormDataChange({
                    isMidMonth: event.target.checked,
                    paymentDay: formData.paymentDay2,
                    otStartDay: formData.otStartDay1,
                    otStartMonth: formData.otStartMonth1
                  })
                }
              }}
            >
              Enable mid-month payroll
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row hidden={!formData.isMidMonth}>
        <Col flex={1}>
          <Form.Item label="1st half starts on">
            <i>1st of the month</i>
            {/* <DayKeyValues
              value={formData.startDay}
              readOnly={readOnly}
              onChange={(value: number) => handleFormDataChange({ startDay: value })}
            /> */}
          </Form.Item>
        </Col>
        <Col flex={1}>
          <Form.Item
            label="2nd half starts on"
            validateStatus={errors?.startDay2 ? 'error' : ''}
            help={errors?.startDay2}
          >
            <DayKeyValues
              value={formData.startDay2}
              style={payDayStyle}
              allowClear={false}
              readOnly={readOnly}
              onChange={(value: number) => handleFormDataChange({ startDay2: value })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row hidden={!formData.isMidMonth}>
        <Col flex={1}>
          <Form.Item
            label="1st half payment day is on"
            validateStatus={errors?.paymentDay1 ? 'error' : ''}
            help={errors?.paymentDay1}
          >
            <Input.Group>
              <DayKeyValues
                value={formData.paymentDay1}
                style={payDayStyle}
                allowClear={false}
                readOnly={readOnly}
                onChange={(value: number) => handleFormDataChange({ paymentDay1: value })}
              />
              <Select
                value={formData.paymentMonth1}
                style={payMonthStyle}
                allowClear={false}
                readOnly={readOnly}
                onChange={(value: number) => handleFormDataChange({ paymentMonth1: value })}
              >
                <Select.Option value={0}>Current month</Select.Option>
                <Select.Option value={1}>Next month</Select.Option>
              </Select>
            </Input.Group>
          </Form.Item>
        </Col>
        <Col flex={1}>
          <Form.Item
            label="2nd half payment day is on"
            validateStatus={errors?.paymentDay2 ? 'error' : ''}
            help={errors?.paymentDay2}
          >
            <Input.Group>
              <DayKeyValues
                value={formData.paymentDay2}
                style={payDayStyle}
                allowClear={false}
                readOnly={readOnly}
                onChange={(value: number) => handleFormDataChange({ paymentDay2: value })}
              />
              <Select
                value={formData.paymentMonth2}
                style={payMonthStyle}
                allowClear={false}
                readOnly={readOnly}
                onChange={(value: number) => handleFormDataChange({ paymentMonth2: value })}
              >
                <Select.Option value={0}>Current month</Select.Option>
                <Select.Option value={1}>Next month</Select.Option>
              </Select>
            </Input.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row hidden={!formData.isMidMonth}>
        <Col flex={1}>
          <Form.Item
            label="1st half overtime period starts on"
            validateStatus={errors?.otStartDay1 ? 'error' : ''}
            help={errors?.otStartDay1}
          >
            <Input.Group>
              <DayKeyValues
                value={formData.otStartDay1}
                style={payDayStyle}
                allowClear={false}
                readOnly={readOnly}
                onChange={(value: number) => handleFormDataChange({ otStartDay1: value })}
              />
              <Select
                value={formData.otStartMonth1}
                style={payMonthStyle}
                allowClear={false}
                readOnly={readOnly}
                onChange={(value: number) => handleFormDataChange({ otStartMonth1: value })}
              >
                <Select.Option value={-1}>Previous month</Select.Option>
                <Select.Option value={0}>Current month</Select.Option>
              </Select>
            </Input.Group>
          </Form.Item>
        </Col>
        <Col flex={1}>
          <Form.Item
            label="2nd half overtime period starts on"
            validateStatus={errors?.otStartDay2 ? 'error' : ''}
            help={errors?.otStartDay2}
          >
            <Input.Group>
              <DayKeyValues
                value={formData.otStartDay2}
                style={payDayStyle}
                allowClear={false}
                readOnly={readOnly}
                onChange={(value: number) => handleFormDataChange({ otStartDay2: value })}
              />
              <Select
                value={formData.otStartMonth2}
                style={payMonthStyle}
                allowClear={false}
                readOnly={readOnly}
                onChange={(value: number) => handleFormDataChange({ otStartMonth2: value })}
              >
                <Select.Option value={-1}>Previous month</Select.Option>
                <Select.Option value={0}>Current month</Select.Option>
              </Select>
            </Input.Group>
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}
