import React, { CSSProperties, FC, useMemo } from 'react'
import moment from 'moment-timezone'
import classNames from 'classnames'
import { Space, List, SecondaryLink, Tooltip, Alert } from '~/core-components'
import { useToggle } from '~/hooks/use-toggle'
import { Col, Row } from '~/components'
import { SSLeaveBuddyConflictState } from '~/features/my'
import { LveBuddyEventType } from '~/constants'
import './LeaveBuddyConflicts.less'

interface LeaveBuddyConflictsProps {
  isBlock?: boolean
  buddies?: SSLeaveBuddyConflictState[]
  eventType?: LveBuddyEventType
}

const SHOW_MORE_CUT_OFF = 14
const tooltipOverlayStyle: CSSProperties = { whiteSpace: 'pre-line', maxWidth: 400 }
const displayStyle: CSSProperties = { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }

export const LeaveBuddyConflicts: FC<LeaveBuddyConflictsProps> = ({
  isBlock,
  buddies,
  eventType = LveBuddyEventType.Submission
}) => {
  const [showMore, toggleShowMore] = useToggle()
  const alertType = useMemo(() => {
    return isBlock ? 'error' : 'warning'
  }, [isBlock])
  const alertMsg = useMemo(() => {
    return isBlock
      ? 'Leave request cannot be submitted as your leave buddy is also on leave on the following dates:'
      : `Please note that ${eventType === LveBuddyEventType.Submission ? 'your' : 'employee’s'}
        leave buddy is also on leave on the following dates:`
  }, [isBlock, eventType])

  const dates = useMemo(() => {
    return buddies?.filter((b, index) => (showMore ? true : index < SHOW_MORE_CUT_OFF))
  }, [buddies, showMore])

  return (
    <>
      <Alert className="alert-leave-buddy-dates" type={alertType} message={alertMsg} />
      <div className={classNames('leave-buddy-conflicts', isBlock ? 'leave-buddy-conflicts--block' : '')}>
        <List
          size="small"
          dataSource={dates}
          renderItem={(item: SSLeaveBuddyConflictState) => (
            <List.Item key={item.leaveDate}>
              <Row>
                <Col span={8}>
                  <Space>{item.leaveDate ? moment(item.leaveDate).format('DD MMM YYYY (ddd)') : undefined}</Space>
                </Col>
                <Col span={16}>
                  <Tooltip title={item.buddies?.join('\n')} overlayStyle={tooltipOverlayStyle}>
                    <div style={displayStyle}>{item.buddies?.join(', ')}</div>
                  </Tooltip>
                </Col>
              </Row>
            </List.Item>
          )}
        />
      </div>
      {buddies && buddies.length > SHOW_MORE_CUT_OFF && (
        <div>
          <SecondaryLink onClick={toggleShowMore}>{showMore ? 'show less' : 'show more'}</SecondaryLink>
        </div>
      )}
    </>
  )
}
