import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchLeaveRecordAttachments } from '../../actions'
import { selectMyLeaveRecordAttachments } from '../../selectors'
import { SSLeaveRecordAttachmentState } from '../../types'

export const useMyLeaveRecordAttachments = (leaveRecordId?: string): [SSLeaveRecordAttachmentState[], boolean] => {
  const leaveRecordAttachments = useSelector(selectMyLeaveRecordAttachments)(
    leaveRecordId || ''
  ) as SSLeaveRecordAttachmentState[]
  const loading = useSelector((state: StoreState) => state.my.myLeaveRecordAttachmentsLoading[leaveRecordId || ''])

  useEffect(() => {
    if (leaveRecordId) {
      dispatch(fetchLeaveRecordAttachments(leaveRecordId))
    }
  }, [leaveRecordId])

  return [leaveRecordAttachments, loading]
}
