import React, { FC, useCallback, useEffect, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Card, Form, Link, Spin, Switch } from '~/core-components'
import { Col, Row, SelectEmployees } from '~/components'
import { dispatch } from '~/stores/store'
import { EMPTY_PAYGROUP_INFO_FORM_DATA } from './PayGroupInfoForm'
import { IPayGroupInfo, PayGroupState } from '../../../types'
import { mapPayGroupStateToPayGroupInfo } from '../../../types/paygroup.mapper'
import { fetchPayGroupPayslipEmSelections, updateMaster } from '../../../actions'
import { Delimiter } from '~/constants'
import { Pagination } from '~/types/common'

interface PayslipAutomationProps {
  payGroup?: PayGroupState
}

export const PayslipAutomation: FC<PayslipAutomationProps> = ({ payGroup }: PayslipAutomationProps) => {
  const [formData, setFormData] = useState<IPayGroupInfo>(EMPTY_PAYGROUP_INFO_FORM_DATA)
  const [updating, setUpdating] = useState(false)
  const [exclusion, setExclusion] = useState<boolean>(false)

  useEffect(() => {
    if (payGroup) {
      setFormData({
        name: payGroup.name,
        companyId: payGroup.companyId,
        startDay: payGroup.startDay,
        startDay2: payGroup.startDay2,
        paymentDay: payGroup.paymentDay,
        paymentMonth: payGroup.paymentMonth,
        paymentDay1: payGroup.paymentDay1,
        paymentMonth1: payGroup.paymentMonth1,
        paymentDay2: payGroup.paymentDay2,
        paymentMonth2: payGroup.paymentMonth2,
        isMidMonth: payGroup.isMidMonth,
        inactiveDate: payGroup.inactiveDate,
        otStartDay: payGroup.otStartDay,
        otStartMonth: payGroup.otStartMonth,
        otStartDay1: payGroup.otStartDay1,
        otStartMonth1: payGroup.otStartMonth1,
        otStartDay2: payGroup.otStartDay2,
        otStartMonth2: payGroup.otStartMonth2,
        isAutoPayslipPublish: payGroup.isAutoPayslipPublish,
        excludePayslipEmployeeIds: payGroup.excludePayslipEmployeeIds
      })

      if (payGroup.excludePayslipEmployeeIds != null && payGroup.excludePayslipEmployeeIds !== '') {
        setExclusion(true)
      }
    } else {
      setFormData(EMPTY_PAYGROUP_INFO_FORM_DATA)
    }
  }, [payGroup])

  const handleUpdate = useCallback(
    async (formData: IPayGroupInfo) => {
      try {
        setUpdating(true)
        if (payGroup) {
          await dispatch(updateMaster('payGroup', payGroup.id, mapPayGroupStateToPayGroupInfo(payGroup), formData))
        }
      } finally {
        setUpdating(false)
      }
    },
    [payGroup]
  )

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      setFormData(formData => {
        const updatedFormData = { ...formData, ...updates }
        handleUpdate(updatedFormData)
        return updatedFormData
      })
    },
    [handleUpdate]
  )

  const handleShowExclusion = useCallback(async () => {
    setExclusion(true)
  }, [])

  const handleFetchEmployee = useCallback(
    (viewId: string, pagination: Pagination, search: string) => {
      if (payGroup?.id) {
        dispatch(fetchPayGroupPayslipEmSelections(payGroup?.id, viewId, pagination, search))
      }
    },
    [payGroup?.id]
  )

  const handleSelectEmployee = useCallback(
    (excludePayslipEmployeeIds: string[]) => {
      setFormData(formData => {
        const updates = { ...formData, excludePayslipEmployeeIds: excludePayslipEmployeeIds?.join(Delimiter.comma) }
        handleUpdate(updates)
        return updates
      })
    },
    [handleUpdate]
  )

  return (
    <Card className="payslip-automation-config" title="Automation">
      <Row>
        <Col span={24}>
          <Form.Item label="Automate payslip publishing">
            {updating ? (
              <Spin indicator={<LoadingOutlined spin />} />
            ) : (
              <Switch
                checkedChildren="Yes"
                unCheckedChildren="No"
                checked={formData.isAutoPayslipPublish}
                onChange={(isAutoPayslipPublish: boolean) => handleFormDataChange({ isAutoPayslipPublish })}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      {formData.isAutoPayslipPublish && (
        <Row>
          {!exclusion && (
            <Col span={24}>
              <Link onClick={handleShowExclusion}>Add exclusion</Link>
            </Col>
          )}
          {exclusion && (
            <Col span={24}>
              <SelectEmployees
                viewName="pay_group_payslip"
                label="Exclusion"
                labelCol={{ flex: '80px' }}
                employeeIds={
                  formData.excludePayslipEmployeeIds ? formData.excludePayslipEmployeeIds.split(Delimiter.comma) : []
                }
                onFetchData={handleFetchEmployee}
                onSelect={handleSelectEmployee}
              />
            </Col>
          )}
        </Row>
      )}
    </Card>
  )
}
