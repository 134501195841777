import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { fetchPayLeaveMaps, PayLeaveMapState, selectPayLeaveMaps } from '~/features/master'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'

export const usePayLeaveMaps = (
  payGroupId: string,
  fetchStrategy: FetchStrategy = 'when-empty'
): [PayLeaveMapState[], boolean] => {
  const payLeaveMaps = useSelector(selectPayLeaveMaps)(payGroupId)
  const loading = useSelector((state: StoreState) => state.master.payLeaveMapsLoading[payGroupId])

  useEffect(() => {
    if (payGroupId) {
      dispatch(fetchPayLeaveMaps(payGroupId, { strategy: fetchStrategy }))
    }
  }, [payGroupId, fetchStrategy])

  return [payLeaveMaps, loading]
}
