import { Pagination, Search } from '~/types/common'
import { request, getBaseUrl, timeout } from '~/utils/request'
import { EmDirectoryViewState } from '../types'

const baseUrl = getBaseUrl('/employee/emdirectory')

export const apiGetEmDirectory = (viewId: string, params: Pagination & Search) =>
  request<EmDirectoryViewState>('get', `${baseUrl}/${viewId}`, params)

export const apiGetEmDirectoryExcel = (viewId: string, params: Search) =>
  request('get', `${baseUrl}/${viewId}/excel`, params, { responseType: 'blob', timeout })
