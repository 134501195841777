import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { ExpenseTypeState } from '../types'
import { fetchCtExpenseTypes, fetchExpenseTypes } from '../actions'
import { selectExpenseTypesByClaim } from '../selectors'

export const useExpenseTypesByClaim = (
  claimTypeId: string,
  fetchStrategy: FetchStrategy = 'when-empty'
): [ExpenseTypeState[], boolean] => {
  const expenseTypes = useSelector(selectExpenseTypesByClaim)(claimTypeId)
  const loading = useSelector(
    (state: StoreState) => state.claim.ctExpenseTypesLoading[claimTypeId] || state.claim.expenseTypesLoading
  )
  useEffect(() => {
    if (claimTypeId) {
      dispatch(fetchCtExpenseTypes(claimTypeId, { strategy: fetchStrategy }))
    }
  }, [claimTypeId, fetchStrategy])

  useEffect(() => {
    dispatch(fetchExpenseTypes({ strategy: fetchStrategy }))
  }, [fetchStrategy])

  return [expenseTypes, loading]
}
