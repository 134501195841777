import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { TeConfigState } from '../types'

export const selectTeConfigsForDaily = createSelector(
  (state: StoreState) => state.attendance.teConfigs,
  (teConfigState): TeConfigState[] => {
    const configs = Object.values(teConfigState?.entities || {}) as TeConfigState[]
    return configs
      .filter(a => a.isShownInDaily)
      .sort((a, b) =>
        a.isShownInDaily === b.isShownInDaily ? a.code?.localeCompare(b.code) : a.isShownInDaily ? -1 : 1
      )
  }
)
