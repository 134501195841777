import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { fetchPayDailyMaps, PayDailyMapState, selectPayDailyMaps } from '~/features/master'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'

export const usePayDailyMaps = (
  payGroupId?: string,
  fetchStrategy: FetchStrategy = 'when-empty'
): [PayDailyMapState[], boolean] => {
  const payDailyMaps = useSelector(selectPayDailyMaps)(payGroupId)
  const loading = useSelector((state: StoreState) => state.master.payDailyMapsLoading[payGroupId || ''])

  useEffect(() => {
    if (payGroupId) {
      dispatch(fetchPayDailyMaps(payGroupId, { strategy: fetchStrategy }))
    }
  }, [payGroupId, fetchStrategy])

  return [payDailyMaps, loading]
}
