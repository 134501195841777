import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchLeaveEntitlement } from '../actions'
import { selectLeaveEntitlement } from '../selectors'
import { LeaveEntitlementState } from '../types'

export const useLeaveEntitlement = (
  employeeId: string,
  leaveTypeId: string
): [LeaveEntitlementState | undefined, boolean] => {
  const leaveEntitlement = useSelector(selectLeaveEntitlement)(employeeId, leaveTypeId)
  const loading = useSelector((state: StoreState) => state.leave.leaveTypesLoading)

  useEffect(() => {
    if (employeeId && leaveTypeId) {
      dispatch(fetchLeaveEntitlement(employeeId, leaveTypeId, { strategy: 'when-empty' }))
    }
  }, [employeeId, leaveTypeId])

  return [leaveEntitlement, loading]
}
