import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Card, ColumnsType, Link, SecondaryText, Space, Table, Tag } from '~/core-components'
import { SSEmEducationPublicSelfState } from '../../../../../types'
import { fetchEmpKeyvalues, selectQualificationKeyvalues } from '~/features/master'
import { dispatch } from '~/stores/store'
import { fetchEmPublic } from '~/features/employee'
import { MutateMyEducationDrawer } from './MutateMyEducationDrawer'
import './MyEducations.less'

interface MyEducationsProps {
  employeeId: string
  data: SSEmEducationPublicSelfState[]
  hideSelfUpdate: boolean
}

interface DrawerState {
  visible: boolean
  employeeId?: string
  data?: SSEmEducationPublicSelfState
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

const labelQualificationStyle: CSSProperties = { lineHeight: 1.1 }
const labelMajorStyle: CSSProperties = { fontSize: 12 }

export const MyEducations: FC<MyEducationsProps> = ({ employeeId, data, hideSelfUpdate }) => {
  const qualifications = useSelector(selectQualificationKeyvalues)
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)

  useEffect(() => {
    dispatch(fetchEmpKeyvalues('qualification', undefined, undefined, { strategy: 'when-empty' }))
  }, [])

  const columns: ColumnsType<SSEmEducationPublicSelfState> = [
    {
      title: 'Qualification',
      key: 'qualificationIdNew',
      dataIndex: 'qualificationIdNew',
      render: (value: string, record) => (
        <>
          <div style={labelQualificationStyle}>
            <Space>
              {qualifications[value]?.value} {record.isHighestNew && <Tag type="primary">highest</Tag>}
              {record.recordStatus === 'new_request' && <Tag type="secondary">new (pending)</Tag>}
              {record.recordStatus === 'has_request' && <Tag type="secondary">pending</Tag>}
            </Space>
          </div>
          <SecondaryText style={labelMajorStyle}>{record.majorNew}</SecondaryText>
        </>
      )
    },
    {
      title: 'Institute',
      key: 'instituteNew',
      dataIndex: 'instituteNew'
    },
    {
      title: 'Graduation year',
      key: 'graduationYearNew',
      dataIndex: 'graduationYearNew',
      render: (value: string, record) => {
        const toYear = record.endDateNew ? moment(record.endDateNew).format('YYYY') : ''
        return value || toYear
      }
    },
    {
      key: 'action',
      align: 'right',
      render: (value: string, record) =>
        !hideSelfUpdate && (
          <Link size="small" onClick={() => handleEditEducation(record)}>
            edit
          </Link>
        )
    }
  ]

  const handleAddEducation = useCallback(() => {
    setDrawerState({ visible: true, employeeId, data: undefined })
  }, [employeeId])

  const handleEditEducation = useCallback(
    (myEducation: SSEmEducationPublicSelfState) => {
      setDrawerState({ visible: true, employeeId, data: myEducation })
    },
    [employeeId]
  )

  const handleCloseDrawer = useCallback(
    (action: 'saved' | 'cancelled') => {
      setDrawerState(DEFAULT_DRAWER_STATE)
      if (action === 'saved') {
        dispatch(fetchEmPublic(employeeId, { strategy: 'always' }))
      }
      setDrawerState(DEFAULT_DRAWER_STATE)
    },
    [employeeId]
  )

  return (
    <Card
      title="Educations"
      className="my-educations"
      extra={employeeId && !hideSelfUpdate && <Link onClick={handleAddEducation}>add</Link>}
    >
      <Table rowKey="id" dataSource={data} pagination={false} columns={columns} />
      {!hideSelfUpdate && <MutateMyEducationDrawer {...drawerState} onClose={handleCloseDrawer} />}
    </Card>
  )
}
