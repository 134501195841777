import React, { FC, useEffect, useMemo } from 'react'
import moment from 'moment-timezone'
import { useSelector } from 'react-redux'
import { Dictionary } from '@reduxjs/toolkit'
import { List, SecondaryLink, Space, Tag } from '~/core-components'
import { useSysOptions } from '~/features/employee'
import { dispatch } from '~/stores/store'
import { useToggle } from '~/hooks/use-toggle'
import { LveDuration } from '~/constants'
import { KeyValue } from '~/types/common'
import { fetchLeaveRecordDtls } from '../../../actions'
import { SSLeaveRecordDtlState } from '../../../types'
import { selectMyLeaveRecordDtls } from '../../../selectors'
import './SSLeaveRecordDtls.less'

interface SSLeaveRecordDtlsProps {
  id: string
}

const SHOW_MORE_CUT_OFF = 14

export const SSLeaveRecordDtls: FC<SSLeaveRecordDtlsProps> = ({ id }) => {
  const dtls = useSelector(selectMyLeaveRecordDtls)(id)
  const [durationsShort] = useSysOptions('lve_duration_short')

  useEffect(() => {
    if (id) {
      dispatch(fetchLeaveRecordDtls(id, { strategy: 'when-empty' }))
    }
  }, [id])

  return <SSLeaveRecordDtlsComponent dtls={dtls} durationsShort={durationsShort} />
}

interface SSLeaveRecordDtlsComponentProps {
  dtls: SSLeaveRecordDtlState[]
  durationsShort: Dictionary<KeyValue>
}

export const SSLeaveRecordDtlsComponent: FC<SSLeaveRecordDtlsComponentProps> = ({ dtls, durationsShort }) => {
  const [showMore, toggleShowMore] = useToggle()

  const dates = useMemo(() => {
    return dtls?.filter((d, index) => (showMore ? true : index < SHOW_MORE_CUT_OFF))
  }, [dtls, showMore])

  if (!dtls || dtls?.length <= 1) return null

  return (
    <div className="ssleave-record-dtls">
      <List
        size="small"
        dataSource={dates}
        renderItem={(item: SSLeaveRecordDtlState) => (
          <List.Item key={item.id}>
            <Space>
              {item.leaveDate ? moment(item.leaveDate).format('DD MMM YYYY (ddd)') : undefined}
              {[LveDuration.firstHalf, LveDuration.secondHalf].includes(item.leaveDuration) && (
                <Tag type="original">{durationsShort[item.leaveDuration]?.value}</Tag>
              )}
            </Space>
          </List.Item>
        )}
      />
      {dtls.length > SHOW_MORE_CUT_OFF && (
        <div>
          <SecondaryLink onClick={toggleShowMore}>{showMore ? 'show less' : 'show more'}</SecondaryLink>
        </div>
      )}
    </div>
  )
}
