import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { ProjectEmState } from '../types'
import { fetchProjectEm } from '../actions'
import { selectProjectEm } from '../selectors'

export const useProjectEm = (projectId: string): [ProjectEmState[], boolean] => {
  const projectEm = useSelector(selectProjectEm)(projectId)
  const loading = useSelector((state: StoreState) => state.attendance.projectEmLoading[projectId])

  useEffect(() => {
    if (projectId) {
      dispatch(fetchProjectEm(projectId))
    }
  }, [projectId])

  return [projectEm, loading]
}
