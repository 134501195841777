import React, { CSSProperties, FC, useMemo } from 'react'
import classNames from 'classnames'
import './Paper.less'

type PaperSize = 'A3' | 'A4' | 'A5'
type PaperLayout = 'portrait' | 'landscape'

export interface PaperProps {
  size?: PaperSize
  width?: number
  height?: number
  scale?: number
  layout?: PaperLayout
  className?: string
}

export const Paper: FC<PaperProps> = ({
  size = 'A4',
  width,
  height,
  scale = 1,
  layout = 'portrait',
  className,
  children
}) => {
  const styles = useMemo(() => getPaperSize(size, scale, layout, width, height), [size, scale, layout, width, height])

  return (
    <div className={classNames('paper', className)} style={styles}>
      {children}
    </div>
  )
}

const DPI = 72
const INCH_TO_CM = 2.54

const PAPER_CM = {
  A3: {
    widthCm: 29.7,
    heightCm: 42
  },
  A4: {
    widthCm: 21,
    heightCm: 29.7
  },
  A5: {
    widthCm: 14.8,
    heightCm: 21
  }
}

const getPaperSize = (
  size: PaperSize,
  scale = 1,
  layout: PaperLayout = 'portrait',
  overrideWidth = 0,
  overrideHeight = 0
): CSSProperties => {
  const { widthCm, heightCm } = PAPER_CM[size]

  const layoutWidth = layout === 'portrait' ? widthCm : heightCm
  const layoutHeight = layout === 'portrait' ? heightCm : widthCm

  const width = overrideWidth > 0 ? overrideWidth : ((layoutWidth * DPI) / INCH_TO_CM) * scale
  const height = overrideHeight > 0 ? overrideHeight : ((layoutHeight * DPI) / INCH_TO_CM) * scale

  return { width, height }
}
