import { request, getBaseUrl } from '~/utils/request'
import { IEmCareer } from '../types'

const baseUrl = getBaseUrl('/employee/employee')

export const apiAddEmCareer = (employeeId: string, emCareer: IEmCareer) =>
  request<{
    id: string
    emDepartmentId?: string
    emDivisionId?: string
    emSectionId?: string
    emGrpId?: string
    emCategoryId?: string
    emTeamId?: string
    emJobId?: string
    emManagerId?: string
    emManagerSecondaryId?: string
    emOfficeId?: string
    emCostCentreId?: string
    emEmploymentTypeId?: string
    emOtClassId?: string
    refresh: {
      emDepartmentId?: string
      emDivisionId?: string
      emSectionId?: string
      emGrpId?: string
      emCategoryId?: string
      emTeamId?: string
      emJobId?: string
      emManagerId?: string
      emManagerSecondaryId?: string
      emOfficeId?: string
      emCostCentreId?: string
      emEmploymentType?: string
      emOtClassId?: string
    }
  }>('post', `${baseUrl}/${employeeId}/career`, emCareer)
