import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiDeleteShiftRole } from '../api/shift-role.api'
import { removeShiftRole } from '../reducers'
import { removeKeyvalue } from '~/features/master'

export const deleteShiftRole = (id: string): ThunkResult<void> => {
  return async dispatch => {
    if (!id) return

    const { status, errors, message, errorData } = await apiDeleteShiftRole(id)
    if (status) {
      dispatch(removeShiftRole(id))
      dispatch(removeKeyvalue({ kv: 'shiftRole', id }))
      showSuccess('Deleted')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
