import { request, getBaseUrl } from '~/utils'
import { ClaimProcessResultState, ClaimProcessDto, IClaimEarned } from '../types'

const baseUrl = getBaseUrl('/claim/claim')

export const apiClaimProcess = (data: ClaimProcessDto) =>
  request<ClaimProcessResultState>('post', `${baseUrl}/process`, data)

export const apiGetProjectedEarned = (
  employeeId: string,
  claimTypeId: string,
  date: string,
  claimEarned: IClaimEarned
) => request<number>('get', `${baseUrl}/earned/${employeeId}/${claimTypeId}/${date}`, claimEarned)
