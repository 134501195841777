import React, { FC } from 'react'
import './CardSubTitle.less'

interface CardSubTitleProps {}

export const CardSubTitle: FC<CardSubTitleProps> = ({ children }) => {
  return (
    <div className="card-subtitle">
      <div className="card-subtitle__title">{children}</div>
    </div>
  )
}
