import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Col, Row, SysOptions } from '~/components'
import { Alert, Form, Input, Link, Space, Tooltip } from '~/core-components'
import { Errors } from '~/types/store'
import { KeyValue } from '~/types/common'
import { StoreState } from '~/types/store'
import { emptyGuid, LveEarnedBasis, LveSysLeaveType, LveSysLeaveTypeType, LveUnit } from '~/constants'
import { LeaveTypeState, LpEntitlementState } from '../../../types'
import './MutateLtPolicyDrawer.less'

interface LpEntitlementProps {
  leaveType?: LeaveTypeState
  errors?: Errors
  ltPolicyId?: string
  entitlements: LpEntitlementState[]
  onChange: (entData: LpEntitlementState[]) => void
}

const RELEVANT_ERRORS = ['serviceYear', 'entitlement', 'unit', 'maxCf']

export const LpEntitlement: FC<LpEntitlementProps> = ({ leaveType, errors, ltPolicyId, entitlements, onChange }) => {
  const sysLeaveTypeCode = leaveType?.sysLeaveTypeCode || ''
  const sysLeaveType = useSelector((state: StoreState) => state.leave.sysLeaveTypes?.entities[sysLeaveTypeCode])
  const isEntitlementType = sysLeaveType?.type === LveSysLeaveTypeType.entitlement
  const isCompassionateLve = leaveType?.sysLeaveTypeCode === LveSysLeaveType.com
  const relevantErrors = Object.keys(errors || {}).filter(e => RELEVANT_ERRORS.includes(e))

  const NEW_ENTITLEMENTS: LpEntitlementState[] = [
    {
      id: emptyGuid,
      ltPolicyId: ltPolicyId || emptyGuid,
      isSys: true,
      serviceYear: 1,
      entitlement: 0,
      unit: '',
      maxCf: 0
    }
  ]

  if (!isEntitlementType || isCompassionateLve) return null

  return (
    <Row hidden={leaveType?.earnedBasis === LveEarnedBasis.noEntitlement}>
      <Col span={24}>
        <div className="entitlement__title">Entitlement</div>
        {errors && relevantErrors.length > 0 && (
          <Alert
            type="error"
            message={
              <>
                {relevantErrors.map((error, index) => (
                  <div key={index}>{errors[error]}</div>
                ))}
              </>
            }
          />
        )}
        {entitlements.map((ent, index) => (
          <Row gutter={30} key={index} className="entitlement-row">
            <Col>
              {index === 0 && (
                <Tooltip title="Periods of 12 calendar months starting from employee’s hire date, 1 means 1st service year of the employee, etc.">
                  <div className="entitlement-row__title">
                    Service year
                    <span className="entitlement-row__title-info">
                      <i className="fal fa-circle-info" />
                    </span>
                  </div>
                </Tooltip>
              )}
              <Input.Number
                required
                min={1}
                value={ent.serviceYear}
                onChange={(value?: any) =>
                  onChange([
                    ...entitlements.slice(0, index),
                    { ...entitlements[index], serviceYear: value },
                    ...entitlements.slice(index + 1, entitlements.length)
                  ])
                }
              />
            </Col>
            <Col>
              {index === 0 && <div className="entitlement-row__title">Entitlement granted</div>}
              <Space>
                <Input.Number
                  required
                  min={0}
                  value={ent.entitlement}
                  onChange={(value?: any) =>
                    onChange([
                      ...entitlements.slice(0, index),
                      { ...entitlements[index], entitlement: value },
                      ...entitlements.slice(index + 1, entitlements.length)
                    ])
                  }
                />
                <SysOptions
                  type="lve_entitlement_unit"
                  className="entitlement-unit"
                  value={ent.unit}
                  allowClear={false}
                  renderItem={(kv: KeyValue) => kv.value.toLowerCase()}
                  onChange={(value: string) =>
                    onChange([
                      ...entitlements.slice(0, index),
                      { ...entitlements[index], unit: value },
                      ...entitlements.slice(index + 1, entitlements.length)
                    ])
                  }
                />
              </Space>
            </Col>
            <Col flex="150px">
              {index === 0 && <div className="entitlement-row__title">Max. carry forward</div>}
              <Input.Number
                required
                min={0}
                value={ent.maxCf}
                onChange={(value?: any) =>
                  onChange([
                    ...entitlements.slice(0, index),
                    { ...entitlements[index], maxCf: value },
                    ...entitlements.slice(index + 1, entitlements.length)
                  ])
                }
              />
            </Col>
            <Col>
              <Link
                onClick={() =>
                  onChange([...entitlements.slice(0, index), ...entitlements.slice(index + 1, entitlements.length)])
                }
              >
                remove
              </Link>
            </Col>
          </Row>
        ))}
        <Form.Item>
          <Link
            onClick={() =>
              onChange([
                ...entitlements,
                ...NEW_ENTITLEMENTS.map(ent => ({
                  ...ent,
                  serviceYear: entitlements.length > 0 ? entitlements[entitlements.length - 1].serviceYear + 1 : 1,
                  unit: entitlements.length > 0 ? entitlements[0].unit : LveUnit.days
                }))
              ])
            }
          >
            add more entitlements
          </Link>
        </Form.Item>
      </Col>
    </Row>
  )
}
