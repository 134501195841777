import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { TimeLogTemplateState } from '../types'

export const selectTimeLogTemplates = createSelector(
  (state: StoreState) => state.attendance.timeLogTemplates,
  (timeLogTemplatesState): TimeLogTemplateState[] => {
    const timeLogTemplates = Object.values(timeLogTemplatesState?.entities || {}) as TimeLogTemplateState[]
    return timeLogTemplates.sort((a, b) => a!.name.localeCompare(b!.name))
  }
)
