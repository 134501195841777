import { apiGetMyClaimTaskByToken } from '../api/ss-claim-task-token.api'
import { ThunkResult } from '~/types/store'

export const fetchMyClaimTaskByToken = (token: string): ThunkResult<void> => {
  return async () => {
    if (!token) return

    return await apiGetMyClaimTaskByToken(token)
  }
}
