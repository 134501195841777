import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { apiUpdateCurrency } from '../api/currency.api'
import { CurrencyState, ICurrency } from '../types'
import { setCurrency } from '../reducers'
import { setKeyvalue } from '~/features/master'

export const updateCurrency = <T extends ICurrency>(code: string, before: T, request: T): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!code) return

    const after = { ...before, ...request } as T
    const patch = generateJsonPatch<T>(before, after)
    const { status, errors, message, errorData } = await apiUpdateCurrency(code, patch)

    if (status) {
      const currency = getState().claim.currencies?.entities[code]
      const updated = { ...(currency as CurrencyState), ...after }
      dispatch(setCurrency(updated))
      dispatch(setKeyvalue({ kv: 'currency', data: { key: code, value: updated.name, isShown: updated.isShown } }))
      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
