import { replaceCePolicies, setCePoliciesLoading } from '../reducers'
import { apiGetCePolicies } from '../api/claim-entitlement.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchCePolicies = (
  periodCode?: string,
  employeeId?: string,
  claimTypeId?: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!periodCode || !employeeId || !claimTypeId) return

    const entId = `${periodCode}|${employeeId}|${claimTypeId}`

    const loading = getState().claim.cePoliciesLoading[entId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().claim.cePolicies[entId]
      if (hasData) return
    }

    try {
      dispatch(setCePoliciesLoading({ entId, loading: true }))
      const { result, status } = await apiGetCePolicies(periodCode, employeeId, claimTypeId)
      if (status) {
        dispatch(replaceCePolicies({ entId, data: result }))
      }
    } finally {
      dispatch(setCePoliciesLoading({ entId, loading: false }))
    }
  }
}
