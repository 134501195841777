import { showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiSortDailyPolicyRows } from '../api/daily-policy.api'
import { ISortDailyPolicyRow } from '../types'

export const sortDialyPolicyRows = (dailyPolicyId: string, sort: ISortDailyPolicyRow[]): ThunkResult<void> => {
  return async () => {
    if (!dailyPolicyId || (sort && sort.length === 0)) return

    const { status, errors, message, errorData } = await apiSortDailyPolicyRows(dailyPolicyId, sort)
    if (status) {
      // showSuccess('Sorted')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
