import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'
import { replacePayCfConfigs, setPayCfConfigsLoading } from '../reducers'
import { apiGetPayCfConfigs } from '../api/pay-cf-config.api'

export const fetchPayCfConfigs = (options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().payroll.payCfConfigsLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().payroll.payCfConfigs?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setPayCfConfigsLoading(true))
      const { result, status } = await apiGetPayCfConfigs()
      if (status) {
        dispatch(replacePayCfConfigs(result))
      }
    } finally {
      dispatch(setPayCfConfigsLoading(false))
    }
  }
}
