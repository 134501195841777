import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { RangeValue } from 'rc-picker/lib/interface.d'
import { Button, Collapse, Form, Input, PageHeader, Space, Tooltip } from '~/core-components'
import { Col, DocumentTitle, PdfViewer, ReasonKeyValues, Row, SysOptions } from '~/components'
import { emptyGuid, FeatureFlag, MovementField, RptEmStatus } from '~/constants'
import { KeyValue } from '~/types/common'
import { GroupingButton, RptScreen } from '~/features/grouping'
import { useMyTenant } from '~/features/iam'
import { Screen, useGetViewIdByName, ViewCriteriaButton } from '~/features/selection'
import { Errors } from '~/types/store'
import { REPORTS_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { downloadWithDom, getFileTimestamp, showError } from '~/utils'
import { IRptEmMovement, ReportCode } from '../../types'
import { useFirstReportTemplate, useReportTemplate } from '../../hooks'
import { refetchReportTemplates } from '../../reducers'
import { apiDownloadEmMovementPdf } from '../../api/rpt-em-movement.api'
import { RptDownloadBtn } from '../components/RptDownloadBtn'
import { ReportTemplate } from '../ReportTemplate/ReportTemplate'
import { RptSortingBtn } from '../components/RptSortingBtn'
import './RptEmMovement.less'

const routes = [
  {
    path: REPORTS_ROUTES.main,
    breadcrumbName: 'Reports'
  },
  {
    path: '',
    breadcrumbName: 'Employee movement report'
  }
]

interface RptEmMovementForm extends IRptEmMovement {
  templateId: string
}

const currentYear = moment().format('YYYY')

const SCREEN_CODE: Screen = 'rpt_em_movement'
const RPT_SCREEN_CODE: RptScreen = 'rpt_people'
const REPORT_CODE: ReportCode = 'rpt_em_movement'

const EMPTY_FORM_DATA: RptEmMovementForm = {
  startDate: `${currentYear}-01-01`,
  endDate: `${currentYear}-12-31`,
  movementFields: [],
  reasonIds: [],
  emStatus: RptEmStatus.all,
  sorting: 'employee_name',
  groupingCodes: [],
  pageBreaks: [],
  viewId: '',
  screenCode: SCREEN_CODE,
  reportTitle: '',
  notes: '',
  templateId: emptyGuid
}

export const RptEmMovement: FC = () => {
  const [formData, setFormData] = useState<RptEmMovementForm>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const [previewUrl, setPreviewUrl] = useState<string>('')
  const [previewing, setPreviewing] = useState(false)
  const [activeKey, setActiveKey] = useState(0)

  const [firstTemplate] = useFirstReportTemplate('employee', REPORT_CODE)
  const firstTemplateId = firstTemplate?.id || ''
  const [templateId, setTemplateId] = useState<string>()
  const [template] = useReportTemplate('employee', REPORT_CODE, templateId)

  const [viewId] = useGetViewIdByName(SCREEN_CODE, templateId ? `${SCREEN_CODE}_${templateId}` : undefined)

  const [myTenant] = useMyTenant()
  const hasAttendance = myTenant?.featureFlags.includes(FeatureFlag.Attendance) || false

  useEffect(() => {
    if (!templateId && firstTemplateId) {
      setTemplateId(firstTemplateId)
    }

    if (template) {
      const saved = JSON.parse(template.dataJsonb) as RptEmMovementForm
      saved.templateId = templateId || ''
      saved.emStatus = saved.emStatus || RptEmStatus.all
      saved.viewId = viewId || ''
      saved.screenCode = SCREEN_CODE
      setFormData(formData => ({ ...formData, ...saved }))
    }
  }, [firstTemplateId, templateId, template, viewId])

  const handlePreview = useCallback(async () => {
    setPreviewing(true)
    setErrors(undefined)

    try {
      const { status, result, errors, message, errorData } = await apiDownloadEmMovementPdf(formData)
      if (status) {
        const url = URL.createObjectURL(result)
        setPreviewUrl(url)
        dispatch(refetchReportTemplates())
      } else {
        console.error('Error while downloading', errors)
        setErrors(errors)
        showError(message, errorData)
      }
    } finally {
      setPreviewing(false)
    }
  }, [formData])

  const handleDownloadPdf = useCallback(async () => {
    setErrors(undefined)

    const { status, result, errors, message, errorData } = await apiDownloadEmMovementPdf(formData)

    if (status) {
      const fileName = `employee_movement_report_${getFileTimestamp()}.pdf`
      downloadWithDom(result, fileName)
      dispatch(refetchReportTemplates())
    } else {
      console.error('Error while downloading', errors)
      setErrors(errors)
      showError(message, errorData)
    }
  }, [formData])

  const handleCollapse = useCallback(async () => {
    if (!activeKey) {
      setActiveKey(1)
    } else {
      setActiveKey(0)
    }
  }, [activeKey])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(data => ({ ...data, ...updates }))
  }, [])

  const handleTemplateChange = useCallback((templateId?: string) => {
    setTemplateId(templateId)
  }, [])

  return (
    <div id="rpt-em-movement" className="rpt-em-movement">
      <DocumentTitle title="Employee Movement Report" />
      <PageHeader
        title="Employee movement report"
        containerId="rpt-em-movement"
        breadcrumb={{ routes }}
        extra={
          <ReportTemplate<IRptEmMovement>
            basePath="employee"
            reportCode={REPORT_CODE}
            templateId={templateId}
            templateData={formData}
            size="small"
            onChange={handleTemplateChange}
          />
        }
      />
      <Form className="rpt-em-movement__form" layout="horizontal" labelCol={{ flex: '130px' }}>
        <Row gutter={30}>
          <Col flex="1">
            <Form.Item label="Date range" validateStatus={errors?.startDate ? 'error' : ''} help={errors?.startDate}>
              <Input.DateRange
                value={[
                  formData.startDate ? moment(formData.startDate) : null,
                  formData.endDate ? moment(formData.endDate) : null
                ]}
                onCalendarChange={(dates: RangeValue<moment.Moment>) => {
                  const startDate = dates && dates[0] ? dates[0].format('YYYY-MM-DD') : undefined
                  const endDate = dates && dates[1] ? dates[1].format('YYYY-MM-DD') : undefined
                  handleFormDataChange({ startDate, endDate })
                }}
              />
            </Form.Item>
          </Col>
          <Col flex="none">
            <Space>
              <RptSortingBtn
                sorting={formData.sorting}
                onSelect={(sorting: string) => handleFormDataChange({ sorting })}
              />
              <ViewCriteriaButton screenCode={SCREEN_CODE} viewId={formData.viewId} />
              <GroupingButton
                screenCode={RPT_SCREEN_CODE}
                groupingCodes={formData.groupingCodes || []}
                pageBreaks={formData.pageBreaks || []}
                onSelect={(groupingCodes: string[], pageBreaks: string[]) =>
                  handleFormDataChange({ groupingCodes, pageBreaks })
                }
              />
              <Tooltip title={`${activeKey ? 'Hide' : 'Show'} more settings`}>
                <Button className="btn-more-settings" type={activeKey ? 'primary' : 'default'} onClick={handleCollapse}>
                  <i className="fal fa-gear" />
                </Button>
              </Tooltip>
              <Button type="primary" onClick={handlePreview} loading={previewing}>
                Preview
              </Button>
              <RptDownloadBtn onDownloadPdf={handleDownloadPdf} />
            </Space>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col flex="1">
            <Form.Item
              label="Show movement in"
              validateStatus={errors?.movementFields ? 'error' : ''}
              help={errors?.movementFields}
            >
              <SysOptions
                type="movement_field"
                mode="multiple"
                placeholder="All fields"
                value={formData.movementFields || []}
                onFilter={(value: KeyValue | undefined) => {
                  if (hasAttendance) return true
                  else return MovementField.location !== value?.key
                }}
                onChange={(movementFields: string[]) => handleFormDataChange({ movementFields })}
              />
            </Form.Item>
          </Col>
          <Col flex="1">
            <Form.Item
              label="Filter by reason"
              validateStatus={errors?.reasonIds ? 'error' : ''}
              help={errors?.reasonIds}
            >
              <ReasonKeyValues
                type="all"
                mode="multiple"
                placeholder="All reasons"
                value={formData.reasonIds || []}
                onChange={(reasonIds: string[]) => handleFormDataChange({ reasonIds })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col flex="1">
            <Collapse
              className="rpt-em-movement__more-settings"
              activeKey={activeKey}
              onChange={handleCollapse}
              noStyle
            >
              <Collapse.Panel key="1" header={null} showArrow={false}>
                <Row gutter={30}>
                  <Col flex="490px">
                    <Form.Item
                      label="Filter by employee"
                      validateStatus={errors?.emStatus ? 'error' : ''}
                      help={errors?.emStatus}
                    >
                      <SysOptions
                        allowClear={false}
                        type="rpt_em_status"
                        placeholder="All employees"
                        value={formData.emStatus}
                        onChange={(emStatus?: string) => handleFormDataChange({ emStatus })}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={30}>
                  <Col flex="490px">
                    <Form.Item label="Report title">
                      <Input
                        value={formData.reportTitle}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                          handleFormDataChange({ reportTitle: event.target.value })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={30}>
                  <Col flex="1">
                    <Form.Item label="Custom notes">
                      <Input.TextArea
                        rows={3}
                        value={formData.notes}
                        onChange={(value?: ChangeEvent<HTMLTextAreaElement>) =>
                          handleFormDataChange({ notes: value?.target.value })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Collapse.Panel>
            </Collapse>
          </Col>
        </Row>
      </Form>
      {previewUrl && (
        <div className="rpt-em-movement__pdf-viewer">
          <Row>
            <Col>
              <PdfViewer file={previewUrl} layout="portrait" scale={1.5} />
            </Col>
          </Row>
        </div>
      )}
    </div>
  )
}
