import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { Pagination, Search } from '~/types/common'
import {
  IROAddScheduleRecord,
  ROScheduleRecordViewState,
  IROInitialiseScheduleRecord,
  IROBulkAddScheduleRecord
} from '../types'
import { EmSelectionViewState } from '~/features/employee'
import { EmSelectState } from '~/components'

const baseUrl = getBaseUrl('/attendance/roschedulerecord')

export const apiGetScheduleRecordsViewByRole = (
  role: string,
  viewId: string,
  startDate: string,
  endDate: string,
  params: Pagination & Search
) => request<ROScheduleRecordViewState>('get', `${baseUrl}/${role}/view/${viewId}/${startDate}/${endDate}`, params)

export const apiGetScheduleRecordEmSelectionsAllByRole = (role: string, startDate: string, endDate: string) =>
  request<EmSelectState[]>('get', `${baseUrl}/${role}/${startDate}/${endDate}/emselections`)

export const apiGetScheduleRecordEmSelectionsByRole = (
  role: string,
  startDate: string,
  endDate: string,
  viewId: string,
  params: Pagination & Search
) => request<EmSelectionViewState>('get', `${baseUrl}/${role}/${startDate}/${endDate}/emselections/${viewId}`, params)

export const apiInitialiseScheduleRecordsByRole = (role: string, data: IROInitialiseScheduleRecord) =>
  request<void>('post', `${baseUrl}/${role}/initialise`, data)

export const apiBulkAddScheduleRecordsByRole = (role: string, data: IROBulkAddScheduleRecord) =>
  request<void>('post', `${baseUrl}/${role}/bulkadd`, data)

export const apiAddScheduleRecordByRole = (role: string, schedule: IROAddScheduleRecord) =>
  request<{ id: string }>('post', `${baseUrl}/${role}`, schedule)

export const apiUpdateScheduleRecordByRole = (role: string, id: string, schedule: Operation[]) =>
  request<void>('patch', `${baseUrl}/${role}/${id}`, schedule)

export const apiDeleteScheduleRecordByRole = (role: string, id: string) =>
  request<void>('delete', `${baseUrl}/${role}/${id}`)
