import React, { FC } from 'react'
import { Redirect, Route, RouteProps, Switch } from 'react-router'
import { SCH_PATH, SCH_ROUTES } from '~/routes/routes'
import { ScheduleOverview } from './ScheduleOverview/ScheduleOverview'
import './ScheduleLayout.less'

const routeList: RouteProps[] = [
  {
    path: SCH_ROUTES.records,
    component: ScheduleOverview,
    exact: true
  },
  {
    path: SCH_ROUTES.tab,
    component: ScheduleOverview,
    exact: true
  }
]

export const ScheduleLayout: FC = () => {
  return (
    <div className="schedule-layout">
      <Switch>
        <Redirect exact from={SCH_PATH} to={SCH_ROUTES.tab.replace(':tab?', 'records')} />
        {routeList.map(route => (
          <Route key={route.path as string} {...route} />
        ))}
      </Switch>
    </div>
  )
}
