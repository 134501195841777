import { showSuccess, showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { apiAddLeaveGrant } from '../api/leave-grant.api'
import { IAddLeaveGrant } from '../types'
import { emptyGuid } from '~/constants'

export const addLeaveGrant = (leaveGrant: IAddLeaveGrant): ThunkResult<void> => {
  return async (): Promise<ActionResult> => {
    const { status, result, errors, message, errorData } = await apiAddLeaveGrant({
      ...leaveGrant,
      employeeId: leaveGrant.employeeId || emptyGuid,
      leaveTypeId: leaveGrant.leaveTypeId || emptyGuid,
      earned: leaveGrant.earned || 0
    })
    if (status) {
      showSuccess('Added')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
