import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { FetchStrategy } from '~/types/common'
import { SysWfFlowTypeState, WfCategory } from '../types'
import { fetchSysWfFlowTypes } from '../actions'
import { selectSysFlowTypes } from '../selectors'

export const useSysWfFlowTypes = (
  category: WfCategory,
  fetchStrategy: FetchStrategy = 'when-empty'
): [SysWfFlowTypeState[], boolean] => {
  const flowTypes = useSelector(selectSysFlowTypes)(category)
  const loading = useSelector((state: StoreState) => state.workflow.sysWfFlowTypesLoading[category] || false)

  useEffect(() => {
    dispatch(fetchSysWfFlowTypes(category, { strategy: fetchStrategy }))
  }, [category, fetchStrategy])

  return [flowTypes, loading]
}
