import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { OtConfigState } from '../types'

const baseUrl = getBaseUrl('/attendance/otconfig')

export const apiGetOtConfigs = () => request<OtConfigState[]>('get', baseUrl)

export const apiUpdateOtConfig = (code: string, otConfig: Operation[]) =>
  request('patch', `${baseUrl}/${code}`, otConfig)
