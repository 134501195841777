import { setA8bForm, setA8bFormUpdating, setIr8aFormRefetch } from '../reducers'
import { apiUpdateA8bDraft } from '../api/ytd.api'
import { ThunkResult } from '~/types/store'
import { showSuccess, showError } from '~/utils'
import { A8bFormState } from '../types'

export const updateA8bDraft = (request: A8bFormState): ThunkResult<void> => {
  return async dispatch => {
    try {
      dispatch(setA8bFormUpdating(true))
      const { status, errors, message, errorData } = await apiUpdateA8bDraft(request)
      if (status) {
        dispatch(setA8bForm(request))
        dispatch(setIr8aFormRefetch())
        showSuccess('Saved')
      } else {
        if (Object.keys(errors).length === 0) {
          showError(message, errorData)
        }
      }
      return { errors }
    } finally {
      dispatch(setA8bFormUpdating(false))
    }
  }
}
