import React, { FC } from 'react'
import classNames from 'classnames'
import './SubHeader.less'

interface SubHeaderProps {
  className?: string
  size?: 'small' | 'default'
  type?: 'primary' | 'default'
}

export const SubHeader: FC<SubHeaderProps> = ({ size = 'default', type = 'default', className, children }) => {
  const classes = classNames(
    'sub-header',
    { [`sub-header--${size}`]: size !== 'default' },
    { [`sub-header--${type}`]: type !== 'default' },
    { [`${className}`]: className }
  )
  return <div className={classes}>{children}</div>
}
