import { showSuccess, showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { apiAddFormula } from '../api/formula.api'
import { setFormula } from '../reducers'
import { IFormula, FormulaState } from '../types'

export const addFormula = (formula: IFormula): ThunkResult<void> => {
  return async (dispatch): Promise<ActionResult> => {
    const { status, result, errors, message, errorData } = await apiAddFormula(formula)
    if (status) {
      const newItem = { id: result.id, ...formula } as FormulaState
      dispatch(setFormula(newItem))
      showSuccess('Added')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
