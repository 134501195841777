import { setXeroConfig, setXeroConfigLoading } from '../reducers'
import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiUpdateXeroConfig } from '../api/xero.api'
import { IUpdateXeroConfig } from '../types'

export const updateXeroConfig = (companyId?: string, data?: IUpdateXeroConfig): ThunkResult<void> => {
  return async dispatch => {
    if (!companyId || !data) return

    try {
      dispatch(setXeroConfigLoading(true))
      const { status, result, errors, message, errorData } = await apiUpdateXeroConfig(companyId, data)

      if (status) {
        dispatch(setXeroConfig(result))
        showSuccess('Saved')
      } else {
        if (Object.keys(errors).length === 0) {
          showError(message, errorData)
        }
      }
      return { errors }
    } finally {
      dispatch(setXeroConfigLoading(false))
    }
  }
}
