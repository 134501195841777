import { setMyLeaveRecord, setMyLeaveRecordsLoading } from '../../reducers'
import { apiGetMyLeaveRecord } from '../../api/ssleave-record.api'
import { ThunkResult } from '~/types/store'
import { emptyGuid } from '~/constants'
import { mapSSLeaveRecordResponseToSSLeaveRecordState } from '../../types/ssleave.mapper'

export const fetchMyLeaveRecord = (id: string): ThunkResult<void> => {
  return async dispatch => {
    if (!id || id === emptyGuid) {
      return
    }

    try {
      dispatch(setMyLeaveRecordsLoading(true))
      const { result, status } = await apiGetMyLeaveRecord(id)
      if (status) dispatch(setMyLeaveRecord(mapSSLeaveRecordResponseToSSLeaveRecordState(result)))
    } finally {
      dispatch(setMyLeaveRecordsLoading(false))
    }
  }
}
