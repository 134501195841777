import React, { FC } from 'react'
import { Space } from '~/core-components'
import { Col, Row } from '~/components'
import { EmGroup } from '~/constants'
import { TotalHeadcount } from './components/TotalHeadcount'
import { AverageAge } from './components/AverageAge'
import { AverageServiceLength } from './components/AverageServiceLength'
import { AttritionRate } from './components/AttritionRate'
import { HeadcountDistribution } from './components/HeadcountDistribution'
import { ServiceLength } from './components/ServiceLength'
import './EmDashboard.less'

interface EmDashboardProps {}

export const EmDashboard: FC<EmDashboardProps> = () => {
  return (
    <div className="em-dashboard">
      <Row>
        <Col span={24}>
          <Space align="start">
            <TotalHeadcount />
            <AverageAge />
            <AverageServiceLength />
            <AttritionRate />
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="row-title">Headcount distribution</div>
        </Col>
        <Col span={24}>
          <Space align="start">
            <HeadcountDistribution cacheKey="hc_1" groupBy={EmGroup.gender} />
            <HeadcountDistribution cacheKey="hc_2" groupBy={EmGroup.nationality} />
            <HeadcountDistribution cacheKey="hc_3" groupBy={EmGroup.employmentType} />
            <ServiceLength />
          </Space>
        </Col>
      </Row>
    </div>
  )
}
