import { setSysGroupingScreens, setSysGroupingScreensLoading } from '../reducers'
import { apiGetGroupingScreens } from '../api/sys-grouping.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'
import { RptScreen } from '../types'

export const fetchSysGroupingScreens = (
  screenCode: RptScreen,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!screenCode) return

    const loading = getState().grouping.sysGroupingScreensLoading[screenCode]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = (getState().grouping.sysGroupingScreens[screenCode]?.ids?.length || 0) > 0
      if (hasData) return
    }

    try {
      dispatch(setSysGroupingScreensLoading({ screenCode, loading: true }))
      const { result, status } = await apiGetGroupingScreens(screenCode)
      if (status) {
        dispatch(setSysGroupingScreens({ screenCode, data: result }))
      }
    } finally {
      dispatch(setSysGroupingScreensLoading({ screenCode, loading: false }))
    }
  }
}
