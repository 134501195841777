import React, { FC, useCallback, useMemo, useState } from 'react'
import { InfoTooltip } from '~/components'
import { CePoliciesDrawer } from './CePoliciesDrawer'
import './CePoliciesInfo.less'

type CePoliciesInfoProps = {
  tooltip: string
  periodCode?: string
  employeeId?: string
  claimTypeId?: string
}

interface DrawerState {
  visible: boolean
  periodCode?: string
  employeeId?: string
  claimTypeId?: string
}

const DEFAULT_MODAL_STATE: DrawerState = { visible: false }

export const CePoliciesInfo: FC<CePoliciesInfoProps> = ({ tooltip, periodCode, employeeId, claimTypeId }) => {
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_MODAL_STATE)
  const isClickable = useMemo(
    () => !!periodCode && !!employeeId && !!claimTypeId,
    [periodCode, employeeId, claimTypeId]
  )

  const handleInfoClick = useCallback(() => {
    setDrawerState({
      visible: true,
      periodCode,
      employeeId,
      claimTypeId
    })
  }, [periodCode, employeeId, claimTypeId])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_MODAL_STATE)
  }, [])

  return (
    <>
      <InfoTooltip
        className="cell-icon"
        title={tooltip}
        overlayStyle={{ maxWidth: 280 }}
        {...(isClickable ? { onClick: handleInfoClick } : {})}
      />
      {isClickable && <CePoliciesDrawer {...drawerState} onClose={handleCloseDrawer} />}
    </>
  )
}
