import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchAttendancePeriods } from '../actions'
import { AttendancePeriodState } from '../types'
import { selectAttendancePeriods } from '../selectors'

export const useAttendancePeriods = (
  fetchStrategy: FetchStrategy = 'when-empty'
): [AttendancePeriodState[], boolean] => {
  const periods = useSelector(selectAttendancePeriods)()
  const loading = useSelector((state: StoreState) => state.attendance.attendancePeriodsLoading)

  useEffect(() => {
    dispatch(fetchAttendancePeriods({ strategy: fetchStrategy }))
  }, [fetchStrategy])

  return [periods, loading]
}
