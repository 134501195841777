import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { LoadingOutlined } from '@ant-design/icons'
import { Button, PageHeader, Space, Spin, Switch, Tabs, Text } from '~/core-components'
import { DocumentTitle } from '~/components'
import { useSysOptions } from '~/features/employee'
import { usePermissionGate } from '~/features/iam'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { Permission, PermissionAction } from '~/constants'
import { useDailyPolicies } from '../../hooks'
import { DailyPolicyRows } from '../DailyPolicyRows/DailyPolicyRows'
import { SelectDailyPolicy } from './components/SelectDailyPolicy'
import { MutateDailyPolicyDrawer } from './components/MutateDailyPolicyDrawer'
import './DailyPolicy.less'

interface DailyPolicyProps {}

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: '',
    breadcrumbName: 'Daily policies'
  }
]

export const DailyPolicy: FC<DailyPolicyProps> = () => {
  const match = useRouteMatch<{ tab: string }>()
  const [policySections] = useSysOptions('att_daily_policy_section')

  const [dailyPolicyId, setDailyPolicyId] = useState('')
  const [dailyPolicies, loading] = useDailyPolicies()
  const firstDailyPolicyId = dailyPolicies.length > 0 ? dailyPolicies[0].id : undefined

  const [sortable, setSortable] = useState(false)

  const [drawerState, setDrawerState] = useState<{ visible: boolean }>({ visible: false })
  const canModify = usePermissionGate(Permission.dailyPolicies, PermissionAction.Modify)

  useEffect(() => {
    if (firstDailyPolicyId) setDailyPolicyId(firstDailyPolicyId)
  }, [firstDailyPolicyId])

  const handleDailyPolicyChange = useCallback((value: string) => {
    setDailyPolicyId(value)
  }, [])

  const tabItems = useMemo(() => {
    return Object.entries(policySections).map(([key, s]) => ({
      key,
      label: s?.value,
      children: <DailyPolicyRows section={key} dailyPolicyId={dailyPolicyId} mode={sortable ? 'sort' : 'default'} />
    }))
  }, [policySections, dailyPolicyId, sortable])

  const handleAddPolicy = useCallback(() => {
    setDrawerState({ visible: true })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState({ visible: false })
  }, [])

  if (loading) {
    return (
      <div id="daily-policy" className="daily-policy daily-policy--loading">
        <Spin indicator={<LoadingOutlined spin />} />
      </div>
    )
  }

  if (dailyPolicies.length === 0) {
    return (
      <div id="daily-policy" className="daily-policy daily-policy--empty">
        <Text type="secondary">No daily policy found.</Text>
        {canModify && (
          <>
            <Button onClick={handleAddPolicy}>Create your first daily policy</Button>
            <MutateDailyPolicyDrawer {...drawerState} onClose={handleCloseDrawer} />
          </>
        )}
      </div>
    )
  }

  return (
    <div id="daily-policy" className="daily-policy">
      <DocumentTitle title="Daily Policy" />
      <PageHeader
        containerId="daily-policy"
        title={
          <div className="daily-policy__header">
            <SelectDailyPolicy value={dailyPolicyId} onChange={handleDailyPolicyChange} />
          </div>
        }
        breadcrumb={{ routes }}
      />
      <div className="daily-policy__body">
        <Tabs
          defaultActiveKey={match.params.tab}
          items={tabItems}
          tabBarExtraContent={{
            right: (
              <Space>
                <label>Sort</label>
                <Switch size="small" checked={sortable} onChange={setSortable} />
              </Space>
            )
          }}
        />
      </div>
    </div>
  )
}
