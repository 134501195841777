import React, { FC, useCallback, useState } from 'react'
import { Button, Tooltip } from '~/core-components'
import { usePermissionGate } from '~/features/iam'
import { Permission, PermissionAction } from '~/constants'
import { InitialiseScheduleRecordDrawer } from './InitialiseScheduleRecordDrawer'

interface InitialiseScheduleRecordButtonProps {
  mode: 'button-text' | 'button-icon'
  startDate: string
  endDate: string
}

interface DrawerState {
  visible: boolean
  startDate?: string
  endDate?: string
  employeeIds?: string[]
  overwrite?: boolean
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

export const InitialiseScheduleRecordButton: FC<InitialiseScheduleRecordButtonProps> = ({
  mode,
  startDate,
  endDate
}) => {
  const canModify = usePermissionGate(Permission.schRecord, PermissionAction.Modify)
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)

  const handleOpenDrawer = useCallback(() => {
    setDrawerState({
      visible: true,
      startDate,
      endDate,
      employeeIds: []
    })
  }, [startDate, endDate])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  if (!canModify) return null

  return (
    <>
      <Tooltip title="Generate the schedule based on the default work calendar specified in People Info. This feature applies only to employees whose work schedule is set to 'based on schedule'.">
        {mode === 'button-text' ? (
          <Button type="primary" onClick={handleOpenDrawer}>
            Initialise from calendar
          </Button>
        ) : (
          <Button icon={<i className="fal fa-calendar-lines-pen" />} onClick={handleOpenDrawer} />
        )}
      </Tooltip>
      <InitialiseScheduleRecordDrawer {...drawerState} onClose={handleCloseDrawer} />
    </>
  )
}
