import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchXeroConfig } from '../actions'
import { XeroConfigState } from '../types'

export const useXeroConfig = (
  companyId?: string,
  fetchStrategy: FetchStrategy = 'when-empty'
): [XeroConfigState | undefined, boolean] => {
  const config = useSelector((state: StoreState) => state.payroll.xeroConfig.entities[companyId || ''])
  const loading = useSelector((state: StoreState) => state.payroll.xeroConfigLoading)

  useEffect(() => {
    dispatch(fetchXeroConfig(companyId, { strategy: fetchStrategy }))
  }, [companyId, fetchStrategy])

  return [config, loading]
}
