import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { DepartmentState } from '../types'

export interface DepartmentOverview extends DepartmentState {
  headName?: string
  headJob?: string
  headPhotoId?: string
  activeEmployees?: number
}

const EMPTY_DEPARTMENT_EXTRA: Partial<DepartmentOverview> = {
  headName: '',
  headJob: '',
  headPhotoId: '',
  activeEmployees: 0
}

export const selectDepartmentsOverview = createSelector(
  (state: StoreState) => state.master.masters['department'],
  (state: StoreState) => state.master.departmentsExtra,
  (departmentsState, departmentsExtraState): DepartmentOverview[] => {
    const departments = Object.values(departmentsState?.entities || {})

    return departments
      .map(department => ({
        ...(department as DepartmentState),
        ...(departmentsExtraState?.entities[department!.id] || EMPTY_DEPARTMENT_EXTRA)
      }))
      .sort((a, b) => {
        const aInactiveDate = new Date(a!.inactiveDate || '').getTime() || 0
        const bInactiveDate = new Date(b!.inactiveDate || '').getTime() || 0

        if (bInactiveDate === 0 && aInactiveDate === 0) {
          return a!.name.localeCompare(b!.name)
        } else {
          return aInactiveDate - bInactiveDate
        }
      })
  }
)
