import { replacePayRecordEmployees, setPayRecordEmployeesLoading } from '../reducers'
import { apiGetPayRecordEmployees } from '../api/payrecord-sg.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchPayRecordEmployees = (
  payRunId: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!payRunId) return

    const loading = getState().payroll.payRecordEmployeesLoading[payRunId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().payroll.payRecordEmployees[payRunId]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setPayRecordEmployeesLoading({ payRunId, loading: true }))
      const { result, status } = await apiGetPayRecordEmployees(payRunId)
      if (status) {
        dispatch(replacePayRecordEmployees({ payRunId, data: result }))
      }
    } finally {
      dispatch(setPayRecordEmployeesLoading({ payRunId, loading: false }))
    }
  }
}
