import { useMemo } from 'react'
import { FeatureFlag, FeatureFlagPermission } from '~/constants'
import { useMyTenant } from './use-my-tenant'

export const useHasFeatureFlagAttendance = (sysPermissionId?: string | string[]): boolean => {
  const [myTenant] = useMyTenant()
  const permissionIds = useMemo(
    () =>
      sysPermissionId
        ? Array.isArray(sysPermissionId)
          ? sysPermissionId
          : [sysPermissionId]
        : FeatureFlagPermission.attendance,
    [sysPermissionId]
  )
  const toCheck = useMemo(() => permissionIds.every(p => FeatureFlagPermission.attendance.includes(p)), [permissionIds])

  if (toCheck) {
    const hasFeatureFlagAttendance = myTenant?.featureFlags?.includes(FeatureFlag.Attendance) || false
    if (!hasFeatureFlagAttendance) return false
  }

  return true
}
