import { createSelector } from '@reduxjs/toolkit'
import { LveSysLeaveTypeType } from '~/constants'
import { StoreState } from '~/types/store'
import { UnitType } from '~/utils'
import { SSLeaveBalanceState } from '../../types'

export interface SSLeaveBalanceInfo extends SSLeaveBalanceState {
  name?: string
  color?: string
  unit?: UnitType
  sysLeaveTypeCode: string
  sysLeaveTypeType: string
}

export const selectMyLeaveBalance = createSelector(
  (state: StoreState) => state.my.myLeaveBalances,
  (state: StoreState) => state.leave.leaveTypes,
  (state: StoreState) => state.leave.sysLeaveTypes,
  (myLeaveBalanceState, leaveTypesState, sysLeaveTypesState) =>
    Object.values(myLeaveBalanceState?.entities || {})
      .filter(l => {
        const leaveType = leaveTypesState.entities[l?.leaveTypeId || '']
        const sysLeaveType = sysLeaveTypesState.entities[leaveType?.sysLeaveTypeCode || '']
        return sysLeaveType?.type !== LveSysLeaveTypeType.grant
      })
      .map(l => {
        const leaveType = leaveTypesState.entities[l?.leaveTypeId || '']
        const sysLeaveType = sysLeaveTypesState.entities[leaveType?.sysLeaveTypeCode || '']
        return {
          ...l,
          name: leaveType?.name,
          color: leaveType?.color,
          unit: leaveType?.unit,
          sysLeaveTypeCode: leaveType?.sysLeaveTypeCode,
          sysLeaveTypeType: sysLeaveType?.type
        } as SSLeaveBalanceInfo
      })
      .sort((a, b) => {
        if (a.sysLeaveTypeType < b.sysLeaveTypeType) {
          return -1
        } else if (a.sysLeaveTypeType > b.sysLeaveTypeType) {
          return 1
        } else {
          return a.name?.localeCompare(b.name || '') || 0
        }
      })
)
