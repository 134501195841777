import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import persistReducer from 'redux-persist/es/persistReducer'
import { StoreState } from '~/types/store'
import { RuleRootState, RuleState, SysRuleCriteriaState, rulePersistConfig } from './types'

const ruleAdapter = createEntityAdapter<RuleState>()
const sysRuleCriteriaAdapter = createEntityAdapter<SysRuleCriteriaState>()

const ruleInitialState = ruleAdapter.getInitialState()
const sysRuleCriteriaInitialState = sysRuleCriteriaAdapter.getInitialState()

const initialState: RuleRootState = {
  rules: {},
  rulesLoading: {},
  sysRuleCriteria: sysRuleCriteriaInitialState,
  sysRuleCriteriaLoading: false
}

const ruleSlice = createSlice({
  name: 'rule',
  initialState,
  reducers: {
    setRulesLoading: (state, action: PayloadAction<{ referenceId: string; loading: boolean }>) => {
      const { referenceId, loading } = action.payload
      state.rules[referenceId] = state.rules[referenceId] || ruleInitialState
      state.rulesLoading[referenceId] = loading
    },
    replaceRules: (state, action: PayloadAction<{ referenceId: string; data: RuleState[] }>) => {
      const { referenceId, data } = action.payload
      ruleAdapter.setAll(state.rules[referenceId], data)
    },
    setRule: (state, action: PayloadAction<{ referenceId: string; data: RuleState }>) => {
      const { referenceId, data } = action.payload
      ruleAdapter.upsertOne(state.rules[referenceId], data)
    },
    setSysRuleCriteria: (state, action: PayloadAction<SysRuleCriteriaState[]>) => {
      sysRuleCriteriaAdapter.upsertMany(state.sysRuleCriteria, action.payload)
    },
    setSysRuleCriteriaLoading: (state, action: PayloadAction<boolean>) => {
      state.sysRuleCriteriaLoading = action.payload
    }
  }
})

export const { replaceRules, setRule, setRulesLoading, setSysRuleCriteria, setSysRuleCriteriaLoading } =
  ruleSlice.actions

export const { selectAll: selectRuleCriteria } = sysRuleCriteriaAdapter.getSelectors(
  (state: StoreState) => state.rule.sysRuleCriteria
)

export const ruleReducers = {
  rule: persistReducer<RuleRootState>(rulePersistConfig, ruleSlice.reducer)
}
