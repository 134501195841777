import { replaceEntitledLeaveTypes, setEntitledLeaveTypesLoading } from '../reducers'
import { apiGetEntitledLeaveTypes } from '../api/leave-entitlement.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchEntitledLeaveTypes = (
  employeeId: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!employeeId) return

    const loading = getState().leave.entitledLeaveTypesLoading[employeeId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().leave.entitledLeaveTypes[employeeId]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setEntitledLeaveTypesLoading({ employeeId, loading: true }))
      const { result, status } = await apiGetEntitledLeaveTypes(employeeId)
      if (status) {
        dispatch(replaceEntitledLeaveTypes({ employeeId, data: result }))
      }
    } finally {
      dispatch(setEntitledLeaveTypesLoading({ employeeId, loading: false }))
    }
  }
}
