import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { CePolicyState } from '../types'

export const selectCePolicies = createSelector(
  (state: StoreState) => state.claim.cePolicies,
  cePoliciesState =>
    memoize(
      (periodCode?: string, employeeId?: string, claimTypeId?: string): CePolicyState[] => {
        const entId = `${periodCode}|${employeeId}|${claimTypeId}`

        return Object.values(cePoliciesState[entId]?.entities || {}).sort((a, b) => {
          const aStartDate = new Date(a!.startDate).getTime()
          const bStartDate = new Date(b!.startDate).getTime()
          return bStartDate - aStartDate
        }) as CePolicyState[]
      },
      (periodCode?: string, employeeId?: string, claimTypeId?: string) => `${periodCode}|${employeeId}|${claimTypeId}`
    )
)
