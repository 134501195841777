import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'

export const selectLtSettingsById = createSelector(
  (state: StoreState) => state.leave.ltSettings,
  ltSettingsState =>
    memoize((id: string) => {
      return ltSettingsState[id]?.entities || {}
    })
)
