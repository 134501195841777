import { setChartSummary, setChartSummaryLoading } from '../../reducers'
import { apiGetTotalHeadcount } from '../../api/em-dashboard.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'
import { ChartId } from '../../types'

const chartId: ChartId = 'total_hc'

export const fetchTotalHeadcount = (options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().employee.chartSummariesLoading[chartId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().employee.chartSummaries[chartId]
      if (hasData) return
    }

    try {
      dispatch(setChartSummaryLoading({ chartId, loading: true }))
      const { result, status } = await apiGetTotalHeadcount()
      if (status) {
        dispatch(setChartSummary({ chartId, data: result }))
      }
    } finally {
      dispatch(setChartSummaryLoading({ chartId, loading: false }))
    }
  }
}
