import { IAddress } from './types/common'

export const formatAddress = (address: IAddress, countryName: string) => {
  const blockStreet = `${address.block || ''} ${address.street || ''}${
    (address.block || address.street) && (address.level || address.unit) ? ', ' : ''
  }`
  const level = address.level ? `#${address.level}` : ''
  const unit = address.unit ? `-${address.unit}` : ''
  const country = countryName || ''
  const postalCode = address.postalCode ? ` ${address.postalCode}` : ''

  return {
    addressLine1: `${blockStreet}${level}${unit}`,
    addressLine2: `${country}${postalCode}`
  }
}

export const formatLevelUnit = (level: string, unit: string) => {
  return !level && !unit ? '-' : !level || !unit ? level ?? unit : `${level} - ${unit}`
}
