import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Form, Input, Space } from '~/core-components'
import { Col, Row, SysOptions } from '~/components'
import { selectMyTenants } from '~/features/iam'
import { useQuery } from '~/hooks/use-query'
import { ActionResult, Errors } from '~/types/store'
import { dispatch } from '~/stores/store'
import { updateTenant } from '../../../actions'
import { IUpdateTenant } from '../../../types'
import { TenantAvatar } from './TenantAvatar'
import { SlackIntegration } from '../../../../notification/containers/SlackIntegration/SlackIntegration'

interface EditWorkspaceFormProps {}

interface FormData extends IUpdateTenant {}

const EMPTY_FORM_DATA: FormData = {
  tenantName: '',
  timeZoneId: ''
}

const activeTenantCode = localStorage.getItem('tenant')

export const EditWorkspaceForm: FC<EditWorkspaceFormProps> = () => {
  const query = useQuery()
  const code = query.get('code')
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const [saving, setSaving] = useState(false)
  const myTenants = useSelector(selectMyTenants)
  const tenant = myTenants.find(t => t.tenantCode === activeTenantCode)

  useEffect(() => {
    if (tenant) {
      const { tenantName, timeZoneId } = tenant
      setFormData({ tenantName, timeZoneId })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [tenant])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(data => ({ ...data, ...updates }))
  }, [])

  const handleSave = useCallback(async () => {
    let result: ActionResult | undefined
    setSaving(true)
    try {
      result = await dispatch(updateTenant(formData))
      setErrors(undefined)
    } finally {
      setSaving(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }
  }, [formData])

  if (!activeTenantCode) return null

  return (
    <div className="edit-workspace-form">
      <Form>
        <Row>
          <Col>
            <Form.Item label="Logo" validateStatus={errors?.file ? 'error' : ''} help={errors?.file}>
              <TenantAvatar tenant={tenant} size={128} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Form id="form-workspace" onFinish={handleSave}>
        <Row>
          <Col flex="300px">
            <Form.Item
              label="Workspace name"
              validateStatus={errors?.tenantName ? 'error' : ''}
              help={errors?.tenantName}
            >
              <Space>
                <Input
                  value={formData.tenantName}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleFormDataChange({ tenantName: event.target.value })
                  }
                />
              </Space>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col flex="300px">
            <Form.Item label="Time zone" validateStatus={errors?.timeZoneId ? 'error' : ''} help={errors?.timeZoneId}>
              <SysOptions
                type="time_zone"
                value={formData.timeZoneId}
                onChange={(value: string) => handleFormDataChange({ timeZoneId: value })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button.ActionGroup>
              <Button type="primary" loading={saving} form="form-workspace" htmlType="submit">
                Update workspace
              </Button>
            </Button.ActionGroup>
          </Col>
        </Row>
      </Form>
      <Form id="form-slack">
        <Row style={{ marginTop: 20 }}>
          <Col>
            <Form.Item label="Slack integration">
              <SlackIntegration code={code} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
