import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'

export const selectCurrentEmJob = createSelector(
  (state: StoreState) => state.employee.emJobs,
  emJobsState =>
    memoize(
      (employeeId?: string, id?: string) => {
        if (!employeeId || !id) return undefined

        return emJobsState[employeeId]?.entities[id]
      },
      (employeeId?: string, id?: string) => `${employeeId}|${id}`
    )
)

export const selectEmJobs = createSelector(
  (state: StoreState) => state.employee.emJobs,
  emJobsState =>
    memoize((employeeId?: string) => {
      if (!employeeId) return []

      return Object.values(emJobsState[employeeId]?.entities || {}).sort((a, b) => {
        const aStartDate = new Date(a!.startDate).getTime()
        const bStartDate = new Date(b!.startDate).getTime()
        return bStartDate - aStartDate
      })
    })
)

interface EmJobMaster {
  id: string
  startDate: string
  jobId: string
  jobName?: string
}

export const selectEmJobMaster = createSelector(
  (state: StoreState) => state.master.masters['job'],
  (state: StoreState) => state.employee.employees,
  (state: StoreState) => state.employee.emJobs,
  (jobsState, employeesState, emJobsState) =>
    memoize((employeeId: string): EmJobMaster => {
      const employee = employeesState.entities[employeeId]
      if (employee) {
        const emJob = emJobsState[employeeId]?.entities[employee.emJobId]
        if (emJob?.jobId) {
          const { id, startDate, jobId } = emJob
          return { id, startDate, jobId, jobName: jobsState?.entities[jobId]?.name }
        }
      }
      return { id: '', startDate: '', jobId: '', jobName: '' }
    })
)
