import { showError, showSuccess } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiAddLtPolicy } from '../api/lt-policy.api'
import { setLtPolicy, setLtPolicyExtra } from '../reducers'
import { ILtPolicyMutate } from '../types'
import { mapLtPolicyMutateToLtPolicyState } from '../types/lt-policy.mapper'

export const addLtPolicy = (leaveTypeId: string, ltPolicy: ILtPolicyMutate): ThunkResult<void> => {
  return async dispatch => {
    if (!leaveTypeId) return

    const { status, result, errors, message, errorData } = await apiAddLtPolicy(leaveTypeId, ltPolicy)
    if (status) {
      const ltPolicyState = mapLtPolicyMutateToLtPolicyState({ ...ltPolicy, id: result.id })
      dispatch(setLtPolicy({ leaveTypeId, data: ltPolicyState }))
      dispatch(setLtPolicyExtra({ leaveTypeId, data: result }))
      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
