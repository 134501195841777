import React, { CSSProperties, FC, memo, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Drawer, Form, SecondaryText, Space, Tag, UploadFile } from '~/core-components'
import { Col, Row, UploadFileSelectorAuth } from '~/components'
import { EmFamilyRequestState } from '../../../../types'
import { dispatch } from '~/stores/store'
import { ActionResult } from '~/types/store'
import { selectKeyvaluesById } from '~/features/master'
import { approveEmFamilyRequest, rejectEmFamilyRequest } from '~/features/employee/actions'
import { useSysOptions } from '~/features/employee/hooks'
import { getBaseUrl } from '~/utils'
import { EmRequestApprovalBtn } from '../EmRequestApprovalBtn'

export interface EmFamilyRequestApprovalProps {
  data?: EmFamilyRequestState
  visible: boolean
  onClose: () => void
}

const PendingTag = memo(() => <Tag type="secondary">pending</Tag>)

const currenTextStyle: CSSProperties = { fontSize: 12 }

const EMPTY_ATTACHMENT: UploadFile = {
  uid: '',
  name: '',
  size: 0,
  type: ''
}

const baseUrl = getBaseUrl('/employee')

export const EmFamilyRequestApprovalDrawer: FC<EmFamilyRequestApprovalProps> = ({ data, visible, onClose }) => {
  const [attachment, setAttachment] = useState<UploadFile>()
  const [relationships] = useSysOptions('relationship')
  const [genders] = useSysOptions('gender')
  const [identityTypes] = useSysOptions('identity_type')
  const [yesNo] = useSysOptions('yes_no')
  const nationalities = useSelector(selectKeyvaluesById)('nationality')

  const isNew = data?.recordStatus === 'new_request'
  const isDiffName = !isNew && data?.name !== data?.nameNew
  const isDiffRelation = !isNew && data?.relation !== data?.relationNew
  const isDiffGender = !isNew && data?.gender !== data?.genderNew
  const isDiffBirthDate = !isNew && data?.birthDate !== data?.birthDateNew
  const isDiffNationalityId = !isNew && data?.nationalityId !== data?.nationalityIdNew
  const isDiffIdentityType = !isNew && data?.identityType !== data?.identityTypeNew
  const isDiffIdentityNo = !isNew && data?.identityNo !== data?.identityNoNew
  const isDiffPhone = !isNew && data?.phone !== data?.phoneNew
  const isDiffDependant = !isNew && data?.dependant !== data?.dependantNew

  useEffect(() => {
    if (data && data.attachmentId) {
      setAttachment({
        uid: data.attachmentId,
        name: data.attachmentName || '',
        size: data.attachmentSize || 0,
        type: data.attachmentFormat || '',
        url: `${baseUrl}/employee/request/family/${data.id}/downloadfile`
      })
    }
  }, [data])

  const handleApproval = useCallback(
    async (action: 'approve' | 'reject') => {
      let result: ActionResult | undefined

      if (data) {
        if (action === 'approve') {
          result = await dispatch(approveEmFamilyRequest(data.employeeId, data.id))
        } else if (action === 'reject') {
          result = await dispatch(rejectEmFamilyRequest(data.employeeId, data.id))
        }
      }

      if (!result?.errors) {
        typeof onClose === 'function' && onClose()
      }
    },
    [onClose, data]
  )

  const handleCloseDrawer = useCallback(() => {
    setAttachment(EMPTY_ATTACHMENT)
    typeof onClose === 'function' && onClose()
  }, [onClose])

  const handleFileChange = useCallback(() => {}, [])

  if (!data) return null

  return (
    <Drawer
      open={visible}
      title={<Space>Family request approval {isNew && <Tag type="secondary">new (pending)</Tag>}</Space>}
      onClose={() => handleCloseDrawer()}
      width={600}
      className="em-family-request-approval-drawer"
      footer={<EmRequestApprovalBtn onApproval={handleApproval} />}
    >
      <Form id="form-em-family-request-approval">
        <Row>
          <Col span={24}>
            <Form.Item label={<Space>Name (as per IC) {isDiffName && <PendingTag />}</Space>}>
              <div>{data.nameNew}</div>
              {isDiffName && <SecondaryText style={currenTextStyle}>Current: {data.name || '-'}</SecondaryText>}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col span={12}>
            <Form.Item label={<Space>Relationship {isDiffRelation && <PendingTag />}</Space>}>
              <div>{relationships[data.relationNew]?.value || '-'}</div>
              {isDiffRelation && (
                <SecondaryText style={currenTextStyle}>
                  Current: {relationships[data.relation]?.value || '-'}
                </SecondaryText>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={<Space>Gender {isDiffGender && <PendingTag />}</Space>}>
              <div>{genders[data.genderNew]?.value || '-'}</div>
              {isDiffGender && (
                <SecondaryText style={currenTextStyle}>Current: {genders[data.gender]?.value || '-'}</SecondaryText>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col span={12}>
            <Form.Item label={<Space>Date of birth {isDiffBirthDate && <PendingTag />}</Space>}>
              <div>{data.birthDateNew ? moment(data.birthDateNew).format('DD MMM YYYY') : '-'}</div>
              {isDiffBirthDate && (
                <SecondaryText style={currenTextStyle}>
                  Current: {data.birthDate ? moment(data.birthDate).format('DD MMM YYYY') : '-'}
                </SecondaryText>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={<Space>Nationality {isDiffNationalityId && <PendingTag />}</Space>}>
              <div>{nationalities[data.nationalityIdNew]?.value || '-'}</div>
              {isDiffNationalityId && (
                <SecondaryText style={currenTextStyle}>
                  Current: {nationalities[data.nationalityId]?.value || '-'}
                </SecondaryText>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col span={12}>
            <Form.Item label={<Space>Identity type {isDiffIdentityType && <PendingTag />}</Space>}>
              <div>{identityTypes[data.identityTypeNew]?.value || '-'}</div>
              {isDiffIdentityType && (
                <SecondaryText style={currenTextStyle}>
                  Current: {identityTypes[data.identityType]?.value || '-'}
                </SecondaryText>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={<Space>Identity no. {isDiffIdentityNo && <PendingTag />}</Space>}>
              <div>{data.identityNoNew?.toUpperCase() || '-'}</div>
              {isDiffIdentityNo && (
                <SecondaryText style={currenTextStyle}>Current: {data.identityNo || '-'}</SecondaryText>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col span={12}>
            <Form.Item label={<Space>Phone {isDiffPhone && <PendingTag />}</Space>}>
              <div>{data.phoneNew || '-'}</div>
              {isDiffPhone && <SecondaryText style={currenTextStyle}>Current: {data.phone || '-'}</SecondaryText>}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={<Space>Dependant? {isDiffDependant && <PendingTag />}</Space>}>
              <div>{yesNo[data.dependantNew.toString()]?.value}</div>
              {isDiffDependant && (
                <SecondaryText style={currenTextStyle}>
                  Current: {yesNo[data.dependant.toString()]?.value}
                </SecondaryText>
              )}
            </Form.Item>
          </Col>
        </Row>
        {attachment?.uid && (
          <Row>
            <Col span={24}>
              <Form.Item label="Attachment">
                <UploadFileSelectorAuth
                  multiple={false}
                  accept=".gif,.png,.jpeg,.jpg"
                  fileList={[attachment]}
                  listType="picture-card"
                  disabled={true}
                  onChange={handleFileChange}
                  withCache={false}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    </Drawer>
  )
}
