import React, { FC, ReactNode } from 'react'
import { Skeleton, Space } from '~/core-components'
import { Col, InfoTooltip, Row } from '~/components'
import { ECharts, EChartsOption } from '..'
import merge from 'lodash/merge'
import './ChartSummary.less'

interface ChartSummaryProps {
  title: string | ReactNode
  tooltip?: string
  number: number | string
  unit?: string
  option: EChartsOption
  footer?: string | ReactNode
  loading?: boolean
}

const defaultOption: EChartsOption = {
  tooltip: {
    trigger: 'axis'
  },
  grid: {
    show: false,
    left: 16,
    right: 16,
    top: 16,
    bottom: 16
  },
  xAxis: {
    type: 'category',
    show: false,
    boundaryGap: false
  },
  yAxis: {
    type: 'value',
    show: false
  }
}

export const ChartSummary: FC<ChartSummaryProps> = ({ title, tooltip, number, unit, option, footer, loading }) => {
  const chartOption = merge({}, defaultOption, option)

  return (
    <div className="chart-summary">
      <Row>
        <Col flex="auto" className="chart-summary__title">
          {title}
        </Col>
        <Col flex="none">{tooltip && <InfoTooltip title={tooltip} />}</Col>
      </Row>
      <Row>
        <Col span={24}>
          <Space align="end">
            <div className="chart-summary__number">{number}</div>
            {unit && <div className="chart-summary__number-unit">{unit}</div>}
          </Space>
        </Col>
      </Row>
      {loading ? (
        <Skeleton active paragraph={false} />
      ) : (
        <>
          <Row>
            <Col span={24}>
              <ECharts option={chartOption} style={{ height: 60 }} />
            </Col>
          </Row>
          <Row>
            <Col span={24}>{footer && <div className="chart-summary__footer">{footer}</div>}</Col>
          </Row>
        </>
      )}
    </div>
  )
}
