import { setEmOtClass, setEmOtClassesLoading } from '../reducers'
import { apiGetEmOtClass } from '../api/em-ot-class.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchEmOtClass = (
  employeeId: string,
  emOtClassId: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!employeeId || !emOtClassId) return

    const loading = getState().employee.emOtClassesLoading[employeeId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().employee.emOtClasses[employeeId]?.entities[emOtClassId]
      if (hasData) return
    }

    try {
      dispatch(setEmOtClassesLoading({ employeeId, loading: true }))
      const { result, status } = await apiGetEmOtClass(employeeId, emOtClassId)
      if (status) {
        dispatch(setEmOtClass({ employeeId, data: result }))
      }
    } finally {
      dispatch(setEmOtClassesLoading({ employeeId, loading: false }))
    }
  }
}
