import React, { CSSProperties, FC, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { ColumnsType, Drawer, Link, SecondaryText, Space, Table, Tag, Tooltip } from '~/core-components'
import { EmFamilyRequestState } from '../../../../types'
import { useIsMountedRef } from '~/hooks'
import { EmFamilyRequestApprovalDrawer } from './EmFamilyRequestApprovalDrawer'
import { selectEmFamilyRequests } from '~/features/employee/selectors'
import { selectKeyvaluesById } from '~/features/master'
import { useSysOptions } from '~/features/employee/hooks'
import { maskString } from '~/features/employee/util'

export interface EmFamilyRequestsProps {
  employeeId?: string
  visible: boolean
  onClose: () => void
}

interface ApprovalDrawerState {
  data?: EmFamilyRequestState
  visible: boolean
}

const DEFAULT_APPROVAL_DRAWER_STATE: ApprovalDrawerState = { visible: false }
const labelRow1Style: CSSProperties = { lineHeight: 1.1 }
const labelRow2Style: CSSProperties = { fontSize: 12 }

export const EmFamilyRequestsDrawer: FC<EmFamilyRequestsProps> = ({ employeeId, visible, onClose }) => {
  const data = useSelector(selectEmFamilyRequests)(employeeId)
  const [relationships] = useSysOptions('relationship')
  const [genders] = useSysOptions('gender')
  const [identityTypes] = useSysOptions('identity_type')
  const [yesNo] = useSysOptions('yes_no')
  const nationalities = useSelector(selectKeyvaluesById)('nationality')

  const [approvalDrawerState, setApprovalDrawerState] = useState<ApprovalDrawerState>(DEFAULT_APPROVAL_DRAWER_STATE)
  const isMountedRef = useIsMountedRef()

  const handleView = useCallback(async (request: EmFamilyRequestState) => {
    setApprovalDrawerState({ data: request, visible: true })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    typeof onClose === 'function' && onClose()
  }, [onClose])

  const handleCloseApprovalDrawer = useCallback(() => {
    if (isMountedRef.current) setApprovalDrawerState(DEFAULT_APPROVAL_DRAWER_STATE)
  }, [isMountedRef])

  const columns: ColumnsType<EmFamilyRequestState> = [
    {
      title: 'Name',
      key: 'nameNew',
      dataIndex: 'nameNew',
      render: (value: string, record) => (
        <>
          <div style={labelRow1Style}>
            <Space>
              {value}
              {record.recordStatus === 'new_request' && <Tag type="secondary">new (pending)</Tag>}
              {record.recordStatus === 'has_request' && <Tag type="secondary">pending</Tag>}
            </Space>
          </div>
          <SecondaryText style={labelRow2Style}>
            {relationships[record.relationNew]?.value || '-'} ({genders[record.genderNew]?.value || '-'})
          </SecondaryText>
        </>
      )
    },
    {
      title: 'Date of birth',
      key: 'birthDateNew',
      dataIndex: 'birthDateNew',
      width: 110,
      render: (value: string) => value && moment(value).format('DD MMM YYYY')
    },
    {
      title: 'Nationality',
      key: 'nationalityIdNew',
      dataIndex: 'nationalityIdNew',
      render: (value: string) => nationalities[value]?.value
    },
    {
      title: 'Phone',
      key: 'phoneNew',
      dataIndex: 'phoneNew'
    },
    {
      title: 'Dependant',
      key: 'dependantNew',
      dataIndex: 'dependantNew',
      render: (value: boolean) => yesNo[value.toString()]?.value
    },
    {
      title: 'Identification',
      key: 'identityNoNew',
      dataIndex: 'identityNoNew',
      render: (value: string, record) => (
        <>
          <div style={labelRow1Style}>
            <Tooltip title={value}>{maskString(value)}</Tooltip>
          </div>
          <SecondaryText style={labelRow2Style}>{identityTypes[record.identityTypeNew]?.value}</SecondaryText>
        </>
      )
    },
    {
      key: 'action',
      align: 'right',
      render: (value: string, record) => (
        <Link size="small" onClick={() => handleView(record)}>
          view
        </Link>
      )
    }
  ]

  return (
    <>
      <Drawer
        open={visible}
        title="Family request"
        onClose={() => handleCloseDrawer()}
        width={750}
        className="em-family-requests-drawer"
      >
        <Table rowKey="id" dataSource={data} columns={columns} pagination={false}></Table>
      </Drawer>
      <EmFamilyRequestApprovalDrawer {...approvalDrawerState} onClose={handleCloseApprovalDrawer} />
    </>
  )
}
