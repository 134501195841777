import { replaceEmJobs, setEmJobsLoading } from '../reducers'
import { apiGetEmJobs } from '../api/em-job.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchEmJobs = (employeeId: string, options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!employeeId) return

    const loading = getState().employee.emJobsLoading[employeeId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().employee.emJobs[employeeId]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setEmJobsLoading({ employeeId, loading: true }))
      const { result, status } = await apiGetEmJobs(employeeId)
      if (status) {
        dispatch(replaceEmJobs({ employeeId, data: result }))
      }
    } finally {
      dispatch(setEmJobsLoading({ employeeId, loading: false }))
    }
  }
}
