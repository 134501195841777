import { apiAddA8aDraft } from '../api/ytd.api'
import { ThunkResult } from '~/types/store'
import { showSuccess, showError } from '~/utils'
import { IYtdAddA8aDraft } from '../types'

export const addA8aDraft = (request: IYtdAddA8aDraft): ThunkResult<void> => {
  return async () => {
    const { status, errors, message, errorData } = await apiAddA8aDraft(request)
    if (status) {
      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
