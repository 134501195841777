import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { ColumnsType, Table, Tag, Tooltip } from '~/core-components'
import { maskString } from '../../../../../util'
import { EmIdentityState } from '../../../../../types'
import { selectEmIdentities } from '../../../../../selectors'
import { useSysOptions } from '../../../../../hooks'

interface CopyEmIdentitiesProps {
  employeeId?: string
  emIdentityId?: string
}

type EmIdentityTable = EmIdentityState
const TODAY = moment().format('YYYY-MM-DD')

export const CopyEmIdentities: FC<CopyEmIdentitiesProps> = ({ employeeId, emIdentityId }) => {
  const emIdentities = useSelector(selectEmIdentities)(employeeId)
  const [identityTypes] = useSysOptions('identity_type')

  const columns: ColumnsType<EmIdentityTable> = [
    {
      title: 'Type',
      key: 'identityType',
      dataIndex: 'identityType',
      render: (value: string, record) => (
        <>
          {identityTypes[value]?.value} {record.id === emIdentityId ? <Tag type="primary">main</Tag> : ''}
        </>
      )
    },
    {
      title: 'Identity no.',
      key: 'identityNo',
      dataIndex: 'identityNo',
      render: (value: string) => <Tooltip title={value}>{maskString(value)}</Tooltip>
    },
    {
      title: 'Issue date',
      key: 'issueDate',
      dataIndex: 'issueDate',
      render: (value: string) => value && moment(value).format('DD MMM YYYY')
    },
    {
      title: 'Cancelled / expiry date',
      key: 'expiryDate',
      dataIndex: 'expiryDate',
      render: (value: string, record) => {
        const valueDate = record.cancelledDate ? record.cancelledDate : value
        return valueDate && moment(valueDate).format('DD MMM YYYY')
      }
    }
  ]

  const dataSource = useMemo(
    () => emIdentities.filter(emi => moment(emi?.cancelledDate || emi?.expiryDate || '9999-12-31').isAfter(TODAY)),
    [emIdentities]
  )

  return <Table rowKey="id" dataSource={dataSource} pagination={false} columns={columns} className="copy-em__content" />
}
