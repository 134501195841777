import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { PayRecurringOverrideState } from '../types'

export const selectPayRecurringOverride = createSelector(
  (state: StoreState) => state.payroll.payRecurringOverrides,
  payRecurringOverridesState =>
    memoize(
      (payRunId: string, employeeId: string, payItemId: string): PayRecurringOverrideState | undefined => {
        return (Object.values(payRecurringOverridesState?.entities || {}) as PayRecurringOverrideState[]).find(
          rec => rec.payRunId === payRunId && rec.employeeId === employeeId && rec.payItemId === payItemId
        )
      },
      (payRunId: string, employeeId: string, payItemId: string) => `${payRunId}|${employeeId}|${payItemId}`
    )
)
