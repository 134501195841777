import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'
import { replaceEmploymentConfigs, setEmploymentConfigsLoading } from '../reducers'
import { apiGetEmploymentConfigs } from '../api/employment-config.api'

export const fetchEmploymentConfigs = (options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().master.employmentConfigsLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().master.employmentConfigs?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setEmploymentConfigsLoading(true))
      const { result, status } = await apiGetEmploymentConfigs()
      if (status) {
        dispatch(replaceEmploymentConfigs(result))
      }
    } finally {
      dispatch(setEmploymentConfigsLoading(false))
    }
  }
}
