import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { ShiftCategoryState } from '../types'

export const selectShiftCategories = createSelector(
  (state: StoreState) => state.attendance.shiftCategories,
  (shiftCategoriesState): ShiftCategoryState[] => {
    const shiftCategories = Object.values(shiftCategoriesState?.entities || {}) as ShiftCategoryState[]
    return shiftCategories.sort((a, b) => {
      const aInactiveDate = new Date(a!.inactiveDate || '').getTime() || 0
      const bInactiveDate = new Date(b!.inactiveDate || '').getTime() || 0

      if (bInactiveDate === 0 && aInactiveDate === 0) {
        return a!.name.localeCompare(b!.name)
      } else {
        return aInactiveDate - bInactiveDate
      }
    })
  }
)
