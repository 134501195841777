import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { fetchMaster, PayGroupState, selectMasterById } from '~/features/master'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'

export const usePayGroup = (
  payGroupId: string,
  fetchStrategy: FetchStrategy = 'always'
): [PayGroupState | undefined, boolean] => {
  const payGroup = useSelector(selectMasterById)('payGroup', payGroupId) as PayGroupState | undefined
  const loading = useSelector((state: StoreState) => state.master.mastersLoading['payGroup'])

  useEffect(() => {
    dispatch(fetchMaster('payGroup', payGroupId, { strategy: fetchStrategy }))
  }, [payGroupId, fetchStrategy])

  return [payGroup, loading]
}
