import { replaceClaimRecordAttachments, setClaimRecordAttachmentsLoading } from '../reducers'
import { apiGetClaimRecordAttachments } from '../api/claim-record.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchClaimRecordAttachments = (
  id: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const loading = getState().claim.claimRecordAttachmentsLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const data = Object.values(getState().claim.claimRecordAttachments?.entities || {})
      const hasData = data.some(x => x?.claimRecordId === id)
      if (hasData) return
    }

    try {
      dispatch(setClaimRecordAttachmentsLoading(true))
      const { result, status } = await apiGetClaimRecordAttachments(id)
      if (status) {
        dispatch(replaceClaimRecordAttachments(result))
      }
    } finally {
      dispatch(setClaimRecordAttachmentsLoading(false))
    }
  }
}
