import { request, getBaseUrl } from '~/utils/request'
import { EmFamilyRequestState } from '../types'

const baseUrl = getBaseUrl('/employee/employee/request/family')

export const apiGetEmFamilyRequests = (employeeId: string) =>
  request<EmFamilyRequestState[]>('get', `${baseUrl}/${employeeId}`)

export const apiApproveEmFamilyRequest = (id: string) => request('post', `${baseUrl}/${id}/approve`)

export const apiRejectEmFamilyRequest = (id: string) => request('post', `${baseUrl}/${id}/reject`)
