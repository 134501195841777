import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { PayCfConfigState } from '../types'

export const selectPayCfConfigs = createSelector(
  (state: StoreState) => state.payroll.payCfConfigs,
  (payCfConfigState): PayCfConfigState[] => {
    const configs = Object.values(payCfConfigState?.entities || {}) as PayCfConfigState[]
    return configs.sort((a, b) => (a.isShown === b.isShown ? a.code?.localeCompare(b.code) : a.isShown ? -1 : 1))
  }
)
