import React, { FC, useCallback, useMemo, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Button, Dropdown, MenuItemType, Space } from '~/core-components'
import { useIsMountedRef } from '~/hooks/use-is-mounted-ref'

interface RptDownloadBtnProps {
  onDownloadExcel?: (format: string) => Promise<void>
  onDownloadPdf?: () => Promise<void>
  onDownloadSecurePdf?: () => Promise<void>
}

export const RptDownloadBtn: FC<RptDownloadBtnProps> = ({
  onDownloadExcel,
  onDownloadPdf,
  onDownloadSecurePdf,
  children
}) => {
  const [downloading, setDownloading] = useState(false)
  const isMountedRef = useIsMountedRef()

  const handleDownloadPdf = useCallback(async () => {
    try {
      setDownloading(true)
      typeof onDownloadPdf === 'function' && (await onDownloadPdf())
    } finally {
      if (isMountedRef.current) setDownloading(false)
    }
  }, [onDownloadPdf, isMountedRef])

  // const handleDownloadSecurePdf = useCallback(async () => {
  //   try {
  //     setDownloading(true)
  //     typeof onDownloadSecurePdf === 'function' && (await onDownloadSecurePdf())
  //   } finally {
  //     if (isMountedRef.current) setDownloading(false)
  //   }
  // }, [onDownloadSecurePdf, isMountedRef])

  const handleDownloadExcel = useCallback(
    async (format: string) => {
      try {
        setDownloading(true)
        typeof onDownloadExcel === 'function' && (await onDownloadExcel(format))
      } finally {
        if (isMountedRef.current) setDownloading(false)
      }
    },
    [onDownloadExcel, isMountedRef]
  )

  const items = useMemo(() => {
    const result: MenuItemType[] = []
    if (onDownloadExcel) {
      result.push({ key: 'excel', label: 'Excel', onClick: () => handleDownloadExcel('row') })
    }
    if (onDownloadPdf) {
      result.push({ key: 'pdf', label: 'PDF', onClick: handleDownloadPdf })
    }
    // if (onDownloadSecurePdf) {
    //   result.push({ key: 'pdfsecure', label: 'PDF (password)', onClick: handleDownloadSecurePdf })
    // }
    return result
  }, [
    onDownloadExcel,
    onDownloadPdf,
    // onDownloadSecurePdf,
    handleDownloadExcel,
    handleDownloadPdf
    // handleDownloadSecurePdf
  ])

  if (!onDownloadExcel && !onDownloadPdf) return null

  return (
    <Dropdown disabled={downloading} menu={{ items }}>
      <Button>
        {downloading ? (
          <LoadingOutlined />
        ) : (
          <Space size={4}>
            {children ? <>{children}</> : <i className="fal fa-arrow-down-to-bracket" />}
            <i className="fa-light fa-angle-down" />
          </Space>
        )}
      </Button>
    </Dropdown>
  )
}
