import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { EmSelectionViewState } from '~/features/employee'
import { Pagination, Search } from '~/types/common'
import { IPayRunPayslip } from '../types/payrun-payslip.state'

const baseUrl = getBaseUrl('/payroll-sg/payrun/payslip')

export const apiGetPayRunPayslip = (id: string) => request<IPayRunPayslip>('get', `${baseUrl}/${id}`)

export const apiUpdatePayRunPayslip = (id: string, payRunPayslip: Operation[]) =>
  request<void>('patch', `${baseUrl}/${id}`, payRunPayslip)

export const apiGetPayRunPayslipEmSelections = (payRunid: string, viewId: string, params: Pagination & Search) =>
  request<EmSelectionViewState>('get', `${baseUrl}/${payRunid}/emselections/${viewId}`, params)
