import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Card, Checkbox, ColumnsType, Form, Link, LinkGroup, SecondaryText, Space, Table, Tag } from '~/core-components'
import { Col, AttKeyValues, InactiveTag, Row } from '~/components'
import { selectKeyvaluesById } from '~/features/master'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { useToggle } from '~/hooks/use-toggle'
import { useFirstInView } from '~/hooks/use-first-in-view'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { isInactive } from '~/utils'
import { IEmAttendanceGroupInfo, EmAttendanceGroupState } from '../../../types'
import { fetchEmAttendanceGroups } from '../../../actions'
import { selectCurrentEmAttendanceGroup, selectHistoryEmAttendanceGroups } from '../../../selectors'
import { MutateEmAttendanceGroupDrawer } from './MutateEmAttendanceGroupDrawer'

interface EmAttendanceGroupsProps {
  employeeId?: string
  id?: string
}

type EmAttendanceGroupTable = EmAttendanceGroupState

const EMPTY_FORM_DATA: IEmAttendanceGroupInfo = {
  startDate: '',
  attendanceGroupId: '',
  isClockInRequired: false,
  isOtPayable: false,
  isLatenessDeductible: false,
  isUndertimeDeductible: false
}

interface DrawerState {
  visible: boolean
  employeeId?: string
  data?: EmAttendanceGroupState
  isCurrent: boolean
}

const DEFAULT_MODAL_STATE: DrawerState = { visible: false, isCurrent: false }

const cardStyle: CSSProperties = { margin: 24 }
const cardBodyStyle: CSSProperties = { padding: 0 }
const formStyle: CSSProperties = { padding: '6px 24px' }

export const EmAttendanceGroups: FC<EmAttendanceGroupsProps> = ({ employeeId, id }: EmAttendanceGroupsProps) => {
  const { ref, inView } = useFirstInView<HTMLDivElement>({ threshold: 0.25 })
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_MODAL_STATE)
  const [formData, setFormData] = useState<IEmAttendanceGroupInfo>(EMPTY_FORM_DATA)
  const current = useSelector(selectCurrentEmAttendanceGroup)(employeeId, id)
  const histories = useSelector(selectHistoryEmAttendanceGroups)(employeeId)
  const attendanceGroups = useSelector(selectKeyvaluesById)('attendanceGroup')
  const [showHistory, toggleShowHistory] = useToggle()
  const canView = usePermissionGate(Permission.employeeAttendance)
  const canModify = usePermissionGate(Permission.employeeAttendance, PermissionAction.Modify)
  const loading = useSelector((state: StoreState) => state.employee.emAttendanceGroupsLoading[employeeId || ''])

  useEffect(() => {
    if (inView) {
      if (employeeId) {
        dispatch(fetchEmAttendanceGroups(employeeId))
      }
    }
  }, [inView, employeeId])

  useEffect(() => {
    if (showHistory && histories.length === 0) {
      toggleShowHistory()
    }
  }, [histories.length, showHistory, toggleShowHistory])

  useEffect(() => {
    if (current) {
      const {
        startDate,
        attendanceGroupId,
        isClockInRequired,
        isOtPayable,
        isLatenessDeductible,
        isUndertimeDeductible
      } = current
      setFormData({
        startDate,
        attendanceGroupId,
        isClockInRequired,
        isOtPayable,
        isLatenessDeductible,
        isUndertimeDeductible
      })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [current])

  const handleEditAttendanceGroup = useCallback(
    (emAttendanceGroup?: EmAttendanceGroupState) => {
      setDrawerState({ visible: true, employeeId, data: emAttendanceGroup, isCurrent: id === emAttendanceGroup?.id })
    },
    [employeeId, id, setDrawerState]
  )

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_MODAL_STATE)
  }, [])

  const columns: ColumnsType<EmAttendanceGroupTable> = [
    {
      title: 'Effective date',
      key: 'startDate',
      dataIndex: 'startDate',
      width: 150,
      render: (value: string) => value && moment(value).format('DD MMM YYYY')
    },
    {
      title: 'Attendance group',
      key: 'attendanceGroupId',
      dataIndex: 'attendanceGroupId',
      render: (value: string, record) => (
        <Space>
          {attendanceGroups[value]?.value}
          {record.id === id && <Tag type="primary">current</Tag>}
          {isInactive(attendanceGroups[value]?.inactiveDate) && <InactiveTag />}
        </Space>
      )
    },
    {
      title: 'Clock-in',
      key: 'isClockInRequired',
      dataIndex: 'isClockInRequired',
      align: 'center',
      render: (value: boolean) => <Checkbox checked={value} readOnly></Checkbox>
    },
    {
      title: 'Overtime',
      key: 'isOtPayable',
      dataIndex: 'isOtPayable',
      align: 'center',
      render: (value: boolean) => <Checkbox checked={value} readOnly></Checkbox>
    },
    {
      title: 'Lateness',
      key: 'isLatenessDeductible',
      dataIndex: 'isLatenessDeductible',
      align: 'center',
      render: (value: boolean) => <Checkbox checked={value} readOnly></Checkbox>
    },
    {
      title: 'Undertime',
      key: 'isUndertimeDeductible',
      dataIndex: 'isUndertimeDeductible',
      align: 'center',
      render: (value: boolean) => <Checkbox checked={value} readOnly></Checkbox>
    },
    {
      key: 'action',
      align: 'right',
      render: (value: string, record) =>
        canModify && (
          <Link size="small" onClick={() => handleEditAttendanceGroup(record)}>
            edit
          </Link>
        )
    }
  ]

  if (!canView) return null

  return (
    <div ref={ref}>
      <Card
        title="Attendance group"
        style={cardStyle}
        bodyStyle={cardBodyStyle}
        loading={loading}
        extra={
          <LinkGroup>
            {canModify && !showHistory && (
              <Link onClick={() => handleEditAttendanceGroup(current)}>{current ? 'edit' : 'add'}</Link>
            )}
            {(histories?.length || 0) > 0 && (
              <Link onClick={toggleShowHistory}>{showHistory ? 'hide history' : 'history'}</Link>
            )}
          </LinkGroup>
        }
      >
        {!showHistory && (
          <Form style={formStyle}>
            <Row gutter={30}>
              <Col span={12}>
                <Form.Item
                  label={
                    <Space>
                      Attendance group
                      {formData.startDate && (
                        <SecondaryText size="small">
                          effective from {moment(formData.startDate).format('DD MMM YYYY')}
                        </SecondaryText>
                      )}
                    </Space>
                  }
                >
                  <AttKeyValues id="attendanceGroup" value={formData.attendanceGroupId} readOnly />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[60, 10]} style={{ textAlign: 'center' }}>
              <Col>
                <Form.Item label="Clock-in required">
                  <Checkbox checked={formData.isClockInRequired} readOnly></Checkbox>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item label="Overtime payable">
                  <Checkbox checked={formData.isOtPayable} readOnly></Checkbox>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item label="Lateness deductible">
                  <Checkbox checked={formData.isLatenessDeductible} readOnly></Checkbox>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item label="Undertime deductible">
                  <Checkbox checked={formData.isUndertimeDeductible} readOnly></Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
        {showHistory && <Table rowKey="id" dataSource={histories} pagination={false} columns={columns} />}
        {canModify && <MutateEmAttendanceGroupDrawer {...drawerState} onClose={handleCloseDrawer} />}
      </Card>
    </div>
  )
}
