import { showSuccess, showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { apiAddBank } from '../api/bank.api'
import { setBank, setKeyvalue } from '../reducers'
import { IBank, BankState } from '../types'

export const addBank = (bank: IBank): ThunkResult<void> => {
  return async (dispatch): Promise<ActionResult> => {
    const { status, result, errors, message, errorData } = await apiAddBank(bank)
    if (status) {
      const newBank: BankState = { ...bank, isSeed: false }
      dispatch(setBank(newBank))
      dispatch(setKeyvalue({ kv: 'bank', data: { key: bank.code, value: bank.name } }))

      showSuccess('Added')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
