import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Card, Table, Link, ColumnsType, SecondaryText } from '~/core-components'
import { fetchEmpKeyvalues, selectKeyvaluesById } from '~/features/master'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { useFirstInView } from '~/hooks/use-first-in-view'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { MutateEmStatutoryExemptDrawer } from './MutateEmStatutoryExemptDrawer'
import { EmStatutoryExemptState } from '../../../types'
import { fetchEmStatutoryExempts } from '../../../actions'
import { selectEmStatutoryExempts } from '../../../selectors'

interface EmStatutoryProps {
  employeeId?: string
}

type EmStatutoryExemptTable = EmStatutoryExemptState

interface DrawerState {
  visible: boolean
  employeeId?: string
  data?: EmStatutoryExemptState
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

const cardStyle: CSSProperties = { margin: 24 }
const cardBodyStyle: CSSProperties = { padding: 0 }
const labelRow1Style: CSSProperties = { lineHeight: 1.1 }
const labelRow2Style: CSSProperties = { fontSize: 12 }

export const EmStatutoryExempt: FC<EmStatutoryProps> = ({ employeeId }: EmStatutoryProps) => {
  const { ref, inView } = useFirstInView<HTMLDivElement>({ threshold: 0.25 })
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const loading = useSelector((state: StoreState) => state.employee.emStatutoryExemptsLoading[employeeId || ''])
  const emStatutoryExempts = useSelector(selectEmStatutoryExempts)(employeeId)
  const emStatutoryExemptReasons = useSelector(selectKeyvaluesById)('emStatutoryExemptReason')
  const canView = usePermissionGate(Permission.employeeStatExempt)
  const canModify = usePermissionGate(Permission.employeeStatExempt, PermissionAction.Modify)

  useEffect(() => {
    if (inView) {
      dispatch(
        fetchEmpKeyvalues(
          'emStatutoryExemptReason',
          'sysoption',
          { type: 'em_statutory_exempt_reason' },
          { strategy: 'when-empty' }
        )
      )
    }
  }, [inView])

  useEffect(() => {
    if (inView) {
      if (employeeId) {
        dispatch(fetchEmStatutoryExempts(employeeId))
      }
    }
  }, [inView, employeeId])

  const handleAddStatutoryExempt = useCallback(() => {
    setDrawerState({ visible: true, employeeId, data: undefined })
  }, [employeeId, setDrawerState])

  const handleEditStatutoryExempt = useCallback(
    (emStatutoryExempt: EmStatutoryExemptState) => {
      setDrawerState({ visible: true, employeeId, data: emStatutoryExempt })
    },
    [employeeId, setDrawerState]
  )

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const columns: ColumnsType<EmStatutoryExemptTable> = [
    {
      title: 'Period',
      key: 'startDate',
      dataIndex: 'startDate',
      render: (value: string, record) => (
        <>
          <div style={labelRow1Style}>
            {value && moment(value).format('MMM YYYY')}
            {record.endDate ? ` - ${moment(record.endDate).format('MMM YYYY')}` : ' onwards'}
          </div>
        </>
      )
    },
    {
      title: 'Exempted item',
      key: 'displayedItem',
      dataIndex: 'displayedItem',
      render: (value: string) => value
    },
    {
      title: 'Reason',
      key: 'reasonCode',
      dataIndex: 'reasonCode',
      render: (value: string, record) =>
        value && (
          <>
            <div style={labelRow1Style}>{emStatutoryExemptReasons[value]?.value}</div>
            <SecondaryText style={labelRow2Style}>
              {record.postingDate ? `Posted on: ${moment(record.postingDate).format('DD/MM/YYYY')}` : ''}
            </SecondaryText>
          </>
        )
    },
    {
      title: 'Notes',
      key: 'notes',
      dataIndex: 'notes'
    },
    {
      key: 'action',
      align: 'right',
      render: (value: string, record) =>
        canModify && (
          <Link size="small" onClick={() => handleEditStatutoryExempt(record)}>
            edit
          </Link>
        )
    }
  ]

  if (!canView) return null

  return (
    <div ref={ref}>
      <Card
        title="Statutory exemption"
        extra={employeeId && canModify && <Link onClick={handleAddStatutoryExempt}>add</Link>}
        style={cardStyle}
        bodyStyle={cardBodyStyle}
        loading={loading}
      >
        <Table rowKey="id" dataSource={emStatutoryExempts} pagination={false} columns={columns} loading={loading} />
        {canModify && <MutateEmStatutoryExemptDrawer {...drawerState} onClose={handleCloseDrawer} />}
      </Card>
    </div>
  )
}
