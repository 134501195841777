import React, { FC, useMemo } from 'react'
import { Redirect, RouteProps, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  EMP_PATH,
  PAY_PATH,
  LVE_PATH,
  TAX_PATH,
  SETTINGS_PATH,
  IAM_PATH,
  ADMIN_PATH,
  SELF_SERVICE_PATH,
  JOB_PATH,
  CLA_PATH,
  ATT_PATH,
  SCH_PATH,
  REPORTS_PATH
} from '~/routes/routes'
import { StoreState } from '~/types/store'
import { SettingsLayout } from '~/features/master/containers/SettingsLayout'
import { EmployeeLayout } from '~/features/employee/containers/EmployeeLayout'
import { PayrollLayout } from '~/features/payroll/containers/PayrollLayout'
import { TaxLayout } from '~/features/tax/containers/TaxLayout'
import { LeaveLayout } from '~/features/leave/containers/LeaveLayout'
import { ClaimLayout } from '~/features/claim/containers/ClaimLayout'
import { AttendanceLayout } from '~/features/attendance/containers/AttendanceLayout'
import { ScheduleLayout } from '~/features/schedule/containers/ScheduleLayout'
import { IamLayout } from '~/features/iam/containers/IamLayout'
import { JobLayout } from '~/features/job/containers/JobLayout'
import { AdminLayout } from '../layouts/AdminLayout/AdminLayout'
import { ReportsLayout } from '~/features/report/containers/ReportsLayout'
import { usePermissionGate } from '~/features/iam'
import { Permission } from '~/constants'
import { PrivateRoute } from './PrivateRoute'

export const routes: RouteProps[] = [
  {
    path: SETTINGS_PATH,
    component: SettingsLayout,
    exact: false
  },
  {
    path: EMP_PATH,
    component: EmployeeLayout,
    exact: false
  },
  {
    path: PAY_PATH,
    component: PayrollLayout,
    exact: false
  },
  {
    path: TAX_PATH,
    component: TaxLayout,
    exact: false
  },
  {
    path: LVE_PATH,
    component: LeaveLayout,
    exact: false
  },
  {
    path: CLA_PATH,
    component: ClaimLayout,
    exact: false
  },
  {
    path: ATT_PATH,
    component: AttendanceLayout,
    exact: false
  },
  {
    path: SCH_PATH,
    component: ScheduleLayout,
    exact: false
  },
  {
    path: IAM_PATH,
    component: IamLayout,
    exact: false
  },
  {
    path: JOB_PATH,
    component: JobLayout,
    exact: false
  },
  {
    path: REPORTS_PATH,
    component: ReportsLayout,
    exact: false
  }
]

export const AdminRoutes: FC = () => {
  const myJwt = useSelector((state: StoreState) => state.iam.myJwt)
  const canViewEmployee = usePermissionGate(Permission.allEmployeeTabs)
  const canViewPayroll = usePermissionGate(Permission.payRun)
  const canViewYtd = usePermissionGate(Permission.ytd)
  const canViewLeave = usePermissionGate(Permission.allLeaveTransactions)
  const canViewClaim = usePermissionGate(Permission.allClaimTransactions)
  const canViewAttendance = usePermissionGate(Permission.allAttendanceTransactions)
  const canViewSchedule = usePermissionGate(Permission.allScheduleTransactions)
  const canViewReport = usePermissionGate(Permission.allReports)
  const canViewSetting = usePermissionGate(Permission.allSettings)

  const defaultAdminPath = useMemo(() => {
    if (canViewEmployee) {
      return EMP_PATH
    } else if (canViewPayroll) {
      return PAY_PATH
    } else if (canViewYtd) {
      return TAX_PATH
    } else if (canViewLeave) {
      return LVE_PATH
    } else if (canViewClaim) {
      return CLA_PATH
    } else if (canViewAttendance) {
      return ATT_PATH
    } else if (canViewSchedule) {
      return SCH_PATH
    } else if (canViewReport) {
      return REPORTS_PATH
    } else if (canViewSetting) {
      return SETTINGS_PATH
    }
  }, [
    canViewEmployee,
    canViewPayroll,
    canViewYtd,
    canViewLeave,
    canViewClaim,
    canViewAttendance,
    canViewSchedule,
    canViewReport,
    canViewSetting
  ])

  return (
    <AdminLayout>
      <Switch>
        {!!myJwt?.isEmployee && !myJwt?.isAdmin && <Redirect to={SELF_SERVICE_PATH} />}
        {defaultAdminPath && <Redirect exact from={ADMIN_PATH} to={defaultAdminPath} />}
        {routes.map(({ path, ...props }) => (
          <PrivateRoute key={path as string} exact path={path} {...props} />
        ))}
      </Switch>
    </AdminLayout>
  )
}
