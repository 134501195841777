import { BasePath } from '~/types/common'
import { request, getBaseUrl } from '~/utils/request'
import { IReportTemplateSaveAs, ReportCode, ReportTemplateState } from '../types'

const baseUrl = getBaseUrl('')

export const apiGetReportTemplates = (bastPath: BasePath, reportCode: ReportCode) =>
  request<ReportTemplateState[]>('get', `${baseUrl}/${bastPath}/reporttemplate/${reportCode}`)

export const apiGetReportTemplate = (bastPath: BasePath, reportCode: ReportCode, templateId: string) =>
  request<ReportTemplateState>('get', `${baseUrl}/${bastPath}/reporttemplate/${reportCode}/${templateId}`)

export const apiSaveReportTemplateAs = (bastPath: BasePath, params: IReportTemplateSaveAs) =>
  request<ReportTemplateState>('post', `${baseUrl}/${bastPath}/reporttemplate/saveas`, params)

export const apiDeleteReportTemplate = (bastPath: BasePath, templateId: string) =>
  request('delete', `${baseUrl}/${bastPath}/reporttemplate/${templateId}`)
