import React, { CSSProperties, FC } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { LoadingOutlined } from '@ant-design/icons'
import { Alert, ColumnsType, Spin, Table } from '~/core-components'
import { StoreState } from '~/types/store'
import { formatMoney } from '~/utils'
import { PayImportRowState } from '../../../../types'
import './ImportPayTran.less'

interface ImportPayTranProps {
  visible: boolean
  success?: boolean
  byDates: boolean
}

const spinStyles: CSSProperties = { marginRight: 10 }

export const ImportPayTran: FC<ImportPayTranProps> = ({ visible, success = false, byDates }) => {
  const data = useSelector((state: StoreState) => state.payroll.payImportValidate?.data || [])
  const processing = useSelector((state: StoreState) => state.payroll.payrollLoading)

  const columns: ColumnsType<PayImportRowState> = [
    {
      title: 'Employee',
      key: 'employeeNo',
      dataIndex: 'employeeNo',
      render: (value: string, record) => `[${record.employeeNo}] ${record.employeeName}`
    },
    {
      title: 'Pay item',
      key: 'payItemName',
      dataIndex: 'payItemName'
    },
    {
      title: 'Quantity',
      key: 'quantity',
      dataIndex: 'quantity',
      align: 'right',
      render: (value: number) => formatMoney(value, 4)
    },
    {
      title: 'Rate',
      key: 'rate',
      dataIndex: 'rate',
      align: 'right',
      render: (value: number) => formatMoney(value, 4)
    }
  ]

  if (byDates) {
    columns.push({
      title: 'Date',
      key: 'date',
      dataIndex: 'date',
      render: (value: string) => value && moment(value).format('DD MMM YYYY')
    })
  }

  columns.push(
    {
      title: 'Notes',
      key: 'notes',
      dataIndex: 'notes'
    },
    {
      title: 'Status',
      key: 'isInvalid',
      dataIndex: 'isInvalid',
      render: (value: boolean) => value && 'Invalid'
    }
  )

  return (
    <div className="import-pay-tran" hidden={!visible}>
      {success && (
        <Alert
          type="success"
          showIcon
          message={
            <>
              <div>Import successful</div>
              {processing ? (
                <div>
                  <Spin style={spinStyles} size="small" indicator={<LoadingOutlined spin />} />
                  <span>You may close this while we're calculating the affected employees' payroll</span>
                </div>
              ) : (
                <div>All the affected employees' payroll has been calculated</div>
              )}
            </>
          }
        />
      )}
      <Table
        rowKey="id"
        dataSource={data}
        columns={columns}
        rowClassName={(record: PayImportRowState) => (record.isInvalid ? 'import-pay-tran__row--invalid' : '')}
      />
    </div>
  )
}
