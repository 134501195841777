import React, { ChangeEvent, FC, useCallback, useState } from 'react'
import { Col, Row } from '~/components'
import { Button, Form, Input } from '~/core-components'
import { dispatch } from '~/stores/store'
import { addLeaveRecordComment } from '../../../../../../actions'
import { SSLeaveRecordComments } from '../../../../../Leave/SSLeaveRecordComments/SSLeaveRecordComments'

interface MyLeaveTaskCommentsProps {
  id: string
}

export const MyLeaveTaskComments: FC<MyLeaveTaskCommentsProps> = ({ id }) => {
  const [commenting, setCommenting] = useState(false)
  const [comment, setComment] = useState('')

  const handleComment = useCallback(async () => {
    if (!id) return

    try {
      setCommenting(true)
      const result = await dispatch(addLeaveRecordComment(id, comment))
      if (!result?.errors) {
        setComment('')
      }
    } finally {
      setCommenting(false)
    }
  }, [id, comment])

  return (
    <Form>
      <Row align="top" gutter={15}>
        <Col flex={1}>
          <Form.Item label="Enter your comment">
            <Input.TextArea
              rows={2}
              value={comment}
              onChange={(event: ChangeEvent<HTMLTextAreaElement>) => setComment(event.target.value)}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="&nbsp;">
            <Button onClick={handleComment} loading={commenting}>
              Send
            </Button>
          </Form.Item>
        </Col>
      </Row>
      <SSLeaveRecordComments id={id} />
    </Form>
  )
}
