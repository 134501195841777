import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiDeleteIr8sDraft } from '../api/ytd.api'
import { setIr8sFormDeleting } from '../reducers'

export const deleteIr8sDraft = (id: string): ThunkResult<void> => {
  return async dispatch => {
    if (!id) return

    try {
      dispatch(setIr8sFormDeleting(true))
      const { status, errors, message, errorData } = await apiDeleteIr8sDraft(id)
      if (status) {
        showSuccess('Deleted')
      } else {
        if (Object.keys(errors).length === 0) {
          showError(message, errorData)
        }
      }
      return { errors }
    } finally {
      dispatch(setIr8sFormDeleting(false))
    }
  }
}
