import { Input as InternalInput } from './Input'
import { Search } from './Search'
import { Password } from './Password'
import { TextArea } from './TextArea'
import { Number } from './Number'
import { Date } from './Date'
import { DateRange } from './DateRange'
import { Time } from './Time'
import { InputGroup } from './InputGroup'
import { MonthRange } from './MonthRange'

type TInput = typeof InternalInput
interface IInput extends TInput {
  Search: typeof Search
  Password: typeof Password
  TextArea: typeof TextArea
  Number: typeof Number
  Date: typeof Date
  DateRange: typeof DateRange
  MonthRange: typeof MonthRange
  Time: typeof Time
  Group: typeof InputGroup
}

const Input: IInput = InternalInput as IInput
Input.Search = Search
Input.Password = Password
Input.TextArea = TextArea
Input.Number = Number
Input.Date = Date
Input.DateRange = DateRange
Input.MonthRange = MonthRange
Input.Time = Time
Input.Group = InputGroup
export { Input }
