import { createSelector } from '@reduxjs/toolkit'
import { ClaEntitlementType } from '~/constants'
import { StoreState } from '~/types/store'

export const selectMyClaimTaken = createSelector(
  (state: StoreState) => state.myClaim.myClaimBalances,
  myClaimBalanceState => {
    return Object.values(myClaimBalanceState?.entities || {})
      .filter(c => c?.entitlementType !== ClaEntitlementType.PerPeriod)
      .sort((a, b) => a?.claimTypeName?.localeCompare(b?.claimTypeName || '') || 0)
  }
)
