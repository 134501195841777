import { replacePayTranDtls, setPayTranDtlLoading } from '../reducers'
import { apiGetPayTranDtlsSg } from '../api/payrecord-sg.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchPayTranDtlsSg = (
  recordId: string,
  tranId: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!recordId || !tranId) return

    const loading = getState().payroll.payTranDtlsLoading[tranId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().payroll.payTranDtls[tranId]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setPayTranDtlLoading({ tranId, loading: true }))
      const { result, status } = await apiGetPayTranDtlsSg(recordId, tranId)
      if (status) {
        dispatch(replacePayTranDtls({ tranId, data: result }))
      }
    } finally {
      dispatch(setPayTranDtlLoading({ tranId, loading: false }))
    }
  }
}
