import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { Form } from '~/core-components'
import { Col, EditableCard, EditableCardState, EmSelect, Row } from '~/components'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors } from '~/types/store'
import { updateCompany } from '../../../actions'
import { CompanyState, ICompanyOrgChart } from '../../../types'
import { mapCompanyStateToCompanyOrgChart } from '../../../types/company.mapper'
import { useFocus } from '~/hooks/use-focus'
import { apiGetEmSelect } from '~/features/employee/api/employee.api'

interface CompanyOrgChartProps {
  company?: CompanyState
  onEdit?: () => void
  onSave?: () => void
  onCancel?: () => void
}

const EMPTY_FORM_DATA: ICompanyOrgChart = { topReportingMgrId: '' }

const cardStyle: CSSProperties = { margin: 24 }

export const CompanyOrgChart: FC<CompanyOrgChartProps> = ({
  company,
  onEdit,
  onSave,
  onCancel
}: CompanyOrgChartProps) => {
  const [cardState, setCardState] = useState<EditableCardState>()
  const [formData, setFormData] = useState<ICompanyOrgChart>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const [focusRef] = useFocus(cardState === 'editing')
  const canModify = usePermissionGate(Permission.company, PermissionAction.Modify)

  useEffect(() => {
    if (company) {
      const { topReportingMgrId } = company
      setFormData({ topReportingMgrId })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [company])

  const handleFetchEmployees = useCallback(async () => {
    const { status, result } = await apiGetEmSelect('active')
    if (status) {
      return result
    }
    return []
  }, [])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => setFormData({ ...formData, ...updates }),
    [formData]
  )

  const handleEdit = useCallback(() => {
    setCardState('editing')
    typeof onEdit === 'function' && onEdit()
  }, [onEdit])

  const handleSave = useCallback(async () => {
    if (company) {
      setCardState('saving')
      setErrors(undefined)

      typeof onSave === 'function' && onSave()

      let result: ActionResult | undefined
      try {
        result = await dispatch(updateCompany(company.id, mapCompanyStateToCompanyOrgChart(company), formData))
      } catch {
        setCardState('editing')
      }

      if (result?.errors) {
        setCardState('editing')
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setCardState(undefined)
      }
    }
  }, [company, formData, onSave])

  const handleCancel = useCallback(() => {
    typeof onCancel === 'function' && onCancel()
    setCardState(undefined)
    setErrors(undefined)

    if (company) {
      const { topReportingMgrId } = company
      setFormData({ topReportingMgrId })
    }
  }, [company, onCancel])

  return (
    <EditableCard
      title="Organisation chart"
      style={cardStyle}
      bodyStyle={{ paddingBottom: company ? 6 : 24, paddingTop: 6 }}
      state={canModify ? cardState : 'readonly'}
      formId="form-company-org-chart"
      onEdit={handleEdit}
      onSave={handleSave}
      onCancel={handleCancel}
    >
      <Row>
        <Col span={24}>
          <Form.Item
            label="Top reporting manager"
            validateStatus={errors?.topReportingMgrId ? 'error' : ''}
            help={errors?.topReportingMgrId}
          >
            <EmSelect
              ref={focusRef}
              allowClear={true}
              value={formData.topReportingMgrId}
              readOnly={cardState !== 'editing' && cardState !== 'saving'}
              onFetch={handleFetchEmployees}
              onChange={(value: string) => handleFormDataChange({ topReportingMgrId: value })}
            />
          </Form.Item>
        </Col>
      </Row>
    </EditableCard>
  )
}
