import { removeReportTemplate } from '../reducers'
import { removeKeyvalue } from '~/features/master'
import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { BasePath } from '~/types/common'
import { ReportCode } from '../types'
import { apiDeleteReportTemplate } from '../api/report-template.api'

export const deleteReportTemplate = (
  basePath: BasePath,
  reportCode: ReportCode,
  templateId: string
): ThunkResult<void> => {
  return async dispatch => {
    if (!reportCode || !templateId) return

    const { result, status, errors, message, errorData } = await apiDeleteReportTemplate(basePath, templateId)

    if (status) {
      dispatch(removeReportTemplate({ reportCode, id: templateId }))
      dispatch(removeKeyvalue({ kv: 'reporttemplate', id: templateId }))
      showSuccess('Deleted')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
