import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Card, ColumnsType, Table, Tooltip } from '~/core-components'
import { Screen, ViewCriteria, ViewCriteriaSimple, updateViewCriteria, useFirstView } from '~/features/selection'
import { downloadWithDom, formatMoney, getFileTimestamp, showError } from '~/utils'
import { PAY_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { useIsMountedRef } from '~/hooks/use-is-mounted-ref'
import { selectPayRunsView } from '../../selectors'
import { PayRunRowState } from '../../types'
import { refetchPayRunsView } from '../../reducers'
import { fetchPayRunsView } from '../../actions'
import { apiGetPayRunsViewExcel } from '../../api/payrun.api'
import { PayRunStatusTag } from '../PayRunStatusTag/PayRunStatusTag'
import './PayRuns.less'

export interface PayRunsProps {}

const paginationStyle: CSSProperties = { marginRight: 20 }

const SCREEN_CODE: Screen = 'pay_run'
const PAGE_SIZE_OPTIONS = ['20', '50', '100']

export const PayRuns: FC<PayRunsProps> = () => {
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(20)
  const [downloading, setDownloading] = useState(false)

  const [view, viewLoading] = useFirstView(SCREEN_CODE)
  const viewId = view?.id || ''
  const dataLoading = useSelector((state: StoreState) => state.payroll.payRunsViewLoading)
  const data = useSelector(selectPayRunsView)(viewId)
  const refetch = useSelector((state: StoreState) => state.payroll.payRunsViewRefetch)
  const isMountedRef = useIsMountedRef()

  useEffect(() => {
    if (viewId) {
      dispatch(fetchPayRunsView(viewId, { offset: pageSize * (page - 1), limit: pageSize }))
    }
  }, [viewId, page, pageSize, refetch])

  const handleDownloadClick = useCallback(async () => {
    if (viewId) {
      try {
        setDownloading(true)
        const { status, result, errors, message, errorData } = await apiGetPayRunsViewExcel(viewId)

        if (status) {
          const fileName = `payroll_run_${getFileTimestamp()}.xlsx`
          downloadWithDom(result, fileName)
        } else {
          console.error('Error while downloading', errors)
          showError(message, errorData)
        }
      } finally {
        if (isMountedRef.current) setDownloading(false)
      }
    }
  }, [viewId, isMountedRef])

  const handleCriteriaApply = useCallback(
    async (criteria: ViewCriteria[]) => {
      if (viewId) {
        setPage(1)
        await dispatch(updateViewCriteria(SCREEN_CODE, viewId, { id: viewId, criteria }))
        dispatch(refetchPayRunsView())
      }
    },
    [viewId]
  )

  const handlePaginationChange = useCallback((page: number, pageSize?: number) => {
    setPage(page)
    setPageSize(pageSize || 20)
  }, [])

  const columns: ColumnsType<PayRunRowState> = [
    {
      title: 'Payment month',
      key: 'payPeriod',
      dataIndex: 'payPeriod',
      fixed: 'left',
      className: 'first-col',
      width: 120
    },
    {
      title: 'Payroll run',
      key: 'description',
      dataIndex: 'description',
      fixed: 'left',
      width: 250,
      render: (value: string, record) => (
        <Link to={PAY_ROUTES.payRun.replace(':id', record.id).replace(':tab?', '')}>{value}</Link>
      )
    },
    {
      key: 'status',
      dataIndex: 'status',
      width: 140,
      render: (value: string) => <PayRunStatusTag status={value} />
    },
    {
      title: 'Net pay',
      key: 'netPay',
      dataIndex: 'netPay',
      width: 150,
      align: 'right',
      render: (netPay: number) => formatMoney(netPay || 0, 2)
    },
    {
      title: 'CPF employee',
      key: 'cpfEm',
      dataIndex: 'cpfEm',
      width: 150,
      align: 'right',
      render: (cpfEm: number) => formatMoney(cpfEm || 0, 2)
    },
    {
      title: 'CPF employer',
      key: 'cpfEr',
      dataIndex: 'cpfEr',
      width: 150,
      align: 'right',
      render: (cpfEr: number) => formatMoney(cpfEr || 0, 2)
    },
    {
      title: 'SHG fund',
      key: 'fund',
      dataIndex: 'fund',
      width: 100,
      align: 'right',
      render: (fund: number) => formatMoney(fund || 0, 2)
    },
    {
      title: 'FWL',
      key: 'fwl',
      dataIndex: 'fwl',
      width: 100,
      align: 'right',
      render: (fwl: number) => formatMoney(fwl || 0, 2)
    },
    {
      title: 'SDL',
      key: 'sdl',
      dataIndex: 'sdl',
      width: 100,
      align: 'right',
      render: (sdl: number) => formatMoney(sdl || 0, 2)
    },
    {
      title: 'Employees',
      key: 'employeeCount',
      dataIndex: 'employeeCount',
      width: 100,
      align: 'right',
      render: (employeeCount: number) => employeeCount || 0
    }
  ]

  return (
    <div className="payruns">
      <div className="payruns__body">
        <div className="payruns__action-bar">
          <ViewCriteriaSimple screenCode={SCREEN_CODE} viewId={viewId} onApply={handleCriteriaApply} label="" />
          <Tooltip title="Download excel">
            <Button
              icon={<i className="fal fa-arrow-down-to-bracket" />}
              onClick={handleDownloadClick}
              loading={downloading}
            />
          </Tooltip>
        </div>
        <Card fitParent table>
          <Table
            rowKey="id"
            dataSource={data?.data}
            columns={columns}
            fitParent
            loading={dataLoading || viewLoading}
            scroll={{ x: 100, y: 1000 }}
            pagination={{
              total: data?.count,
              current: page,
              pageSize,
              pageSizeOptions: PAGE_SIZE_OPTIONS,
              showSizeChanger: true,
              onChange: handlePaginationChange,
              style: paginationStyle
            }}
          />
        </Card>
      </div>
    </div>
  )
}
