import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { fetchLeaveTypes } from '~/features/leave'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchMyLeaveTasks } from '../../actions'
import { selectMyLeaveTasks } from '../../selectors'
import { ETaskInfo } from '../../types'

export const useMyLeaveTasks = (): [ETaskInfo[], boolean] => {
  const tasks = useSelector(selectMyLeaveTasks)
  const refetch = useSelector((state: StoreState) => state.my.myLeaveTasksRefetch)
  const loading = useSelector((state: StoreState) => state.my.myLeaveTasksLoading)

  useEffect(() => {
    dispatch(fetchLeaveTypes({ strategy: 'when-empty' }))
  }, [])

  useEffect(() => {
    dispatch(fetchMyLeaveTasks())
  }, [refetch])

  return [tasks, loading]
}
