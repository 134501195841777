import React, { FC, ReactNode } from 'react'
import { default as AntPageHeader, PageHeaderProps as AntPageHeaderProps } from 'antd/lib/page-header'
import { Affix } from '../Affix/Affix'
import { PageBreadcrumb } from './PageBreadcrumb'
import 'antd/es/page-header/style'
import './PageHeader.less'

export interface PageHeaderProps extends Omit<AntPageHeaderProps, 'title'> {
  title?: ReactNode
  containerId?: string
}

export const PageHeader: FC<PageHeaderProps> = ({ breadcrumb, containerId, title, ...props }) => {
  const containerEl = document.getElementById(containerId || '')

  return (
    <div className="page-header">
      <Affix target={() => containerEl}>
        <PageBreadcrumb {...breadcrumb} />
      </Affix>
      {title && <AntPageHeader title={title} {...props} />}
    </div>
  )
}
