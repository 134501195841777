import React, { FC, useCallback, useMemo, useState } from 'react'
import { Button, Card, ColumnsType, Link, PageHeader, Table } from '~/core-components'
import { DocumentTitle, Person } from '~/components'
import { EMP_ROUTES, SETTINGS_ROUTES } from '~/routes/routes'
import { usePermissionGate } from '~/features/iam'
import { Permission, PermissionAction } from '~/constants'
import { CalendarViewerCategory, CalendarViewerState } from '../types'
import { config } from '../config'
import { useCalendarViewers } from '../hooks'
import { MutateCalendarViewerDrawer } from './components/MutateCalendarViewerDrawer'
import { ViewCriteriaAdvanced } from '~/features/selection'
import { getBaseUrl } from '~/utils'
import './CalendarViewers.less'

interface CalendarViewersProps {
  category: CalendarViewerCategory
}

type CalendarViewerTable = CalendarViewerState

interface DrawerState {
  visible: boolean
  data?: CalendarViewerState
}
const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }
const baseUrl = getBaseUrl('/filestore')

export const CalendarViewers: FC<CalendarViewersProps> = ({ category }) => {
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const canModify = usePermissionGate(config[category].permission, PermissionAction.Modify)
  const canViewEmployee = usePermissionGate(Permission.employee)
  const [calendarViewers, loading] = useCalendarViewers(category)

  const routes = useMemo(
    () => [
      {
        path: SETTINGS_ROUTES.main,
        breadcrumbName: 'Settings'
      },
      {
        path: '',
        breadcrumbName: config[category].pageTitle
      }
    ],
    [category]
  )

  const handleAddViewer = useCallback(() => {
    setDrawerState({ visible: true, data: undefined })
  }, [])

  const handleEditViewer = useCallback((calendarViewer: CalendarViewerState) => {
    setDrawerState({ visible: true, data: calendarViewer })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const columns = useMemo(() => {
    let columns: ColumnsType<CalendarViewerTable> = []

    columns.push(
      {
        title: 'Viewer',
        key: 'employeeId',
        dataIndex: 'employeeId',
        render: (value: string, record: CalendarViewerState) => (
          <Person
            name={record.employeeName}
            description={record.description}
            photo={record.photoId && `${baseUrl}/file/${record.photoId}/thumbnailphoto/36`}
            path={canViewEmployee ? EMP_ROUTES.employee.replace(':id', record.employeeId) : undefined}
          />
        )
      },
      {
        title: 'Criteria',
        key: 'viewId',
        dataIndex: 'viewId',
        render: (value: string) => (
          <ViewCriteriaAdvanced viewId={value} screenCode={config[category].screen} label="" readOnly />
        )
      },
      {
        key: 'action',
        align: 'right',
        width: 70,
        render: (value: string, record) =>
          canModify && (
            <Link size="small" onClick={() => handleEditViewer(record)}>
              edit
            </Link>
          )
      }
    )

    return columns
  }, [canModify, canViewEmployee, category, handleEditViewer])

  return (
    <div className="calendar-viewers">
      <DocumentTitle title={config[category].documentTitle} />
      <PageHeader
        title={config[category].pageTitle}
        breadcrumb={{ routes }}
        extra={
          canModify && (
            <Button key="add" onClick={handleAddViewer}>
              Add viewer
            </Button>
          )
        }
      />
      <div className="calendar-viewers__body">
        <Card table>
          <Table rowKey="id" dataSource={calendarViewers} pagination={false} columns={columns} loading={loading} />
        </Card>
        {canModify && <MutateCalendarViewerDrawer {...drawerState} category={category} onClose={handleCloseDrawer} />}
      </div>
    </div>
  )
}

export const LveRecordCalendarViewers: FC = () => <CalendarViewers category="lve_record" />
