import { ThunkResult } from '~/types/store'
import { showSuccess, showError } from '~/utils'
import { apiDeleteEmManager } from '../api/em-manager.api'
import { removeEmManager, setEmployee } from '../reducers'

export const deleteEmManager = (employeeId: string, id: string): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!employeeId || !id) return

    const { result, status, errors, message, errorData } = await apiDeleteEmManager(employeeId, id)
    if (status) {
      if (result.emManagerId) {
        const employee = getState().employee.employees.entities[employeeId]
        if (employee) {
          dispatch(setEmployee({ ...employee, emManagerId: result.emManagerId }))
        }
      }

      dispatch(removeEmManager({ employeeId, id }))
      showSuccess('Deleted')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
