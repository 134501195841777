import React, { FC, useEffect, useMemo, useState } from 'react'
import moment from 'moment-timezone'
import { useSelector } from 'react-redux'
import { LoadingOutlined } from '@ant-design/icons'
import { Form, Space, Spin, Tag } from '~/core-components'
import { Col, DownloadFileAuth, Row } from '~/components'
import { EmPublicPerson, useSysOptions } from '~/features/employee'
import { fetchLeaveTypes, LeaveTypeName, selectLeaveTypeById } from '~/features/leave'
import { dispatch } from '~/stores/store'
import { LveAttachment, LveBuddyEventType, LveRecordType } from '~/constants'
import { StoreState } from '~/types/store'
import { formatDateRange, formatNumberUnit, getBaseUrl, UnitType } from '~/utils'
import { fetchMyLeaveApplyFormInfo } from '../../../../../../actions'
import { useMyLeaveRecordAttachments, useMyLeaveRecordDtls, useMyLeaveTask } from '../../../../../../hooks'
import { SSLeaveRecordDtls } from '../../../../../Leave/SSLeaveRecordDtls/SSLeaveRecordDtls'
import { apiGetLeaveBuddyConflicts } from '~/features/my/api/ssleave-task.api'
import { SSLeaveBuddyConflictState } from '~/features/my/types'
import { LeaveBuddyConflicts } from '~/features/my/containers/Leave/MyLeaveApply/components/LeaveBuddyConflicts'
import './MyLeaveTaskForm.less'

interface MyLeaveTaskFormProps {
  id: string
}

const baseUrl = getBaseUrl('/leave')

export const MyLeaveTaskForm: FC<MyLeaveTaskFormProps> = ({ id }) => {
  const [task, fetching] = useMyLeaveTask(id)
  const isNew = task?.recordType === LveRecordType.new

  const [attachments] = useMyLeaveRecordAttachments(id)
  const [dtls] = useMyLeaveRecordDtls(id)

  const leaveType = useSelector((state: StoreState) => selectLeaveTypeById(state, task?.leaveTypeId || ''))
  const leaveTypeId = leaveType?.id || ''
  const [durationsShort] = useSysOptions('lve_duration_short')

  const formInfo = useSelector((state: StoreState) => state.my.myLeaveApplyFormInfo[leaveTypeId]?.entities)
  const formInfoRefName = useMemo(() => formInfo && formInfo['relation_type'], [formInfo])
  const formInfoRefDate = useMemo(() => formInfo && formInfo['ref_date_label'], [formInfo])

  const [conflictBuddies, setConflictBuddies] = useState<SSLeaveBuddyConflictState[]>([])
  const [conflictBuddiesLoading, setConflictBuddiesLoading] = useState(false)

  useEffect(() => {
    dispatch(fetchLeaveTypes({ strategy: 'when-empty' }))
  }, [])

  useEffect(() => {
    if (leaveTypeId) {
      dispatch(fetchMyLeaveApplyFormInfo(leaveTypeId, { strategy: 'when-empty' }))
    }
  }, [leaveTypeId])

  useEffect(() => {
    if (id && isNew) {
      const fetchBuddies = async () => {
        try {
          setConflictBuddiesLoading(true)
          const { result } = await apiGetLeaveBuddyConflicts(id)
          setConflictBuddies(result)
        } finally {
          setConflictBuddiesLoading(false)
        }
      }

      fetchBuddies()
    } else {
      setConflictBuddies([])
    }
  }, [id, isNew])

  return (
    <div className="my-leave-task-form">
      {!task && !fetching && <div className="my-leave-task-form__empty">Not found</div>}
      {task && (
        <Form>
          <Row>
            <Col span={24}>
              <Form.Item>
                <EmPublicPerson id={task.employeeId} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item label="Leave type">
                <LeaveTypeName id={leaveType?.id} />
              </Form.Item>
            </Col>
          </Row>
          {task.recordType === LveRecordType.cancellation && (
            <Row>
              <Col span={24}>
                <Form.Item label="Application type">
                  <Tag type="secondary">Cancellation</Tag>
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row>
            <Col span={24}>
              <Form.Item label="Dates">
                {dtls.length === 1 && task.leaveTotal === 0.5 ? (
                  <Space>
                    {task.startDate ? moment(task.startDate).format('DD MMM YYYY (ddd)') : undefined}
                    <Tag type="original">{durationsShort[dtls[0]?.leaveDuration || '']?.value}</Tag>
                  </Space>
                ) : (
                  `${formatDateRange(task.startDate, task.endDate)} - ${formatNumberUnit(
                    task.leaveTotal,
                    leaveType?.unit as UnitType
                  )}`
                )}
                <SSLeaveRecordDtls id={id} />
              </Form.Item>
            </Col>
          </Row>
          {formInfoRefName && (
            <Row>
              <Col span={24}>
                <Form.Item label={formInfoRefName?.label}>{task.referenceName}</Form.Item>
              </Col>
            </Row>
          )}
          {formInfoRefDate && (
            <Row>
              <Col span={24}>
                <Form.Item label={formInfoRefDate?.label}>
                  {task.referenceDate ? moment(task.referenceDate).format('DD MMM YYYY') : ''}
                </Form.Item>
              </Col>
            </Row>
          )}
          {leaveType?.attachment !== LveAttachment.notApplicable && (
            <Row>
              <Col span={24}>
                <Form.Item label="Attachment">
                  {attachments?.length > 0
                    ? attachments.map(
                        att =>
                          att && (
                            <div key={att.id}>
                              <DownloadFileAuth
                                url={`${baseUrl}/ssleaverecord/${id}/attachment/${att.id}/downloadfile`}
                              >
                                {att.fileName}
                              </DownloadFileAuth>
                            </div>
                          )
                      )
                    : '-'}
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row>
            <Col span={24}>
              <Form.Item label="Notes">{task.notes || '-'}</Form.Item>
            </Col>
          </Row>
          <Row hidden={!conflictBuddies || conflictBuddies.length === 0}>
            <Col span={24}>
              {conflictBuddiesLoading ? (
                <Spin indicator={<LoadingOutlined spin />} />
              ) : (
                <LeaveBuddyConflicts buddies={conflictBuddies} eventType={LveBuddyEventType.Approval} />
              )}
            </Col>
          </Row>
        </Form>
      )}
    </div>
  )
}
