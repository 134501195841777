import React, { FC, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Card, Link } from '~/core-components'
import { usePermissionGate } from '~/features/iam/hooks'
import { EmploymentConfig, Permission, PermissionAction } from '~/constants'
import { StoreState } from '~/types/store'
import { EmCareerState, EmployeeState } from '../../../types'
import { EmDepartments } from './EmDepartments'
import { EmJobs } from './EmJobs'
import { EmManagers } from './EmManagers'
import { EmTeams } from './EmTeams'
import { EmOffices } from './EmOffices'
import { EmCostCentres } from './EmCostCentres'
import { EmEmploymentTypes } from './EmEmploymentTypes'
import { EmCategories } from './EmCategories'
import { EmDivisions } from './EmDivisions'
import { EmGrps } from './EmGrps'
import { EmManagerSecondaries } from './EmManagerSecondaries'
import { EmOtClasses } from './EmOtClasses'
import { EmSections } from './EmSections'
import {
  selectCurrentEmDepartment,
  selectCurrentEmJob,
  selectCurrentEmManager,
  selectCurrentEmTeam,
  selectCurrentEmOffice,
  selectCurrentEmCostCentre,
  selectCurrentEmEmploymentType,
  selectCurrentEmCategory,
  selectCurrentEmDivision,
  selectCurrentEmGrp,
  selectCurrentEmManagerSecondary,
  selectCurrentEmOtClass,
  selectCurrentEmSection
} from '../../../selectors'
import { MutateEmCareerDrawer } from './MutateEmCareerDrawer'
import { useHiddenEmploymentConfigs } from '~/features/master/hooks'
import './EmCareer.less'

interface EmCareerProps {
  employee: EmployeeState
}

const cardStyle = { margin: 24 }
const cardBodyStyle = { padding: 0 }

interface DrawerState {
  visible: boolean
  employeeId?: string
  data?: EmCareerState
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }
const TODAY = moment().format('YYYY-MM-DD')

export const EmCareer: FC<EmCareerProps> = ({ employee }) => {
  const {
    id,
    hireDate,
    emDepartmentId,
    emDivisionId,
    emSectionId,
    emGrpId,
    emCategoryId,
    emTeamId,
    emJobId,
    emManagerId,
    emManagerSecondaryId,
    emOfficeId,
    emCostCentreId,
    emEmploymentTypeId,
    emOtClassId
  } = employee
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const emDepartment = useSelector(selectCurrentEmDepartment)(id, emDepartmentId)
  const emDivision = useSelector(selectCurrentEmDivision)(id, emDivisionId)
  const emSection = useSelector(selectCurrentEmSection)(id, emSectionId)
  const emGrp = useSelector(selectCurrentEmGrp)(id, emGrpId)
  const emCategory = useSelector(selectCurrentEmCategory)(id, emCategoryId)
  const emTeam = useSelector(selectCurrentEmTeam)(id, emTeamId)
  const emManager = useSelector(selectCurrentEmManager)(id, emManagerId)
  const emManagerSecondary = useSelector(selectCurrentEmManagerSecondary)(id, emManagerSecondaryId)
  const emJob = useSelector(selectCurrentEmJob)(id, emJobId)
  const emOffice = useSelector(selectCurrentEmOffice)(id, emOfficeId)
  const emCostCentre = useSelector(selectCurrentEmCostCentre)(id, emCostCentreId)
  const emEmploymentType = useSelector(selectCurrentEmEmploymentType)(id, emEmploymentTypeId)
  const emOtClass = useSelector(selectCurrentEmOtClass)(id, emOtClassId)
  const canView = usePermissionGate(Permission.employeeCareer)
  const canModify = usePermissionGate(Permission.employeeCareer, PermissionAction.Modify)
  const loading = useSelector(
    (state: StoreState) =>
      state.employee.emDepartmentsLoading[id] ||
      state.employee.emDivisionsLoading[id] ||
      state.employee.emSectionsLoading[id] ||
      state.employee.emGrpsLoading[id] ||
      state.employee.emCategoriesLoading[id] ||
      state.employee.emTeamsLoading[id] ||
      state.employee.emJobsLoading[id] ||
      state.employee.emManagersLoading[id] ||
      state.employee.emManagerSecondariesLoading[id] ||
      state.employee.emOfficesLoading[id] ||
      state.employee.emCostCentresLoading[id] ||
      state.employee.emEmploymentTypesLoading[id] ||
      state.employee.emOtClassesLoading[id]
  )

  const departmentId = emDepartment?.departmentId
  const divisionId = emDivision?.divisionId
  const sectionId = emSection?.sectionId
  const grpId = emGrp?.grpId
  const categoryId = emCategory?.categoryId
  const teamId = emTeam?.teamId
  const jobId = emJob?.jobId
  const managerId = emManager?.managerId
  const managerSecondaryId = emManagerSecondary?.managerSecondaryId
  const officeId = emOffice?.officeId
  const costCentreId = emCostCentre?.costCentreId
  const employmentTypeId = emEmploymentType?.employmentTypeId
  const otClassId = emOtClass?.otClassId

  const [employmentConfigs] = useHiddenEmploymentConfigs()
  const hiddenEmployments = employmentConfigs.map(ec => ec.code)
  const hideDepartment = hiddenEmployments.includes(EmploymentConfig.department)
  const hideDivision = hiddenEmployments.includes(EmploymentConfig.division)
  const hideSection = hiddenEmployments.includes(EmploymentConfig.section)
  const hideGrp = hiddenEmployments.includes(EmploymentConfig.grp)
  const hideCategory = hiddenEmployments.includes(EmploymentConfig.category)
  const hideTeam = hiddenEmployments.includes(EmploymentConfig.team)
  const hideJob = hiddenEmployments.includes(EmploymentConfig.job)
  const hideManager = hiddenEmployments.includes(EmploymentConfig.manager)
  const hideManagerSecondary = hiddenEmployments.includes(EmploymentConfig.managerSecondary)
  const hideOffice = hiddenEmployments.includes(EmploymentConfig.office)
  const hideCostCentre = hiddenEmployments.includes(EmploymentConfig.costCentre)
  const hideEmploymentType = hiddenEmployments.includes(EmploymentConfig.employmentType)
  const hideOtClass = hiddenEmployments.includes(EmploymentConfig.otClass)

  const handleNewTransfer = useCallback(() => {
    if (id) {
      const isAllEmpty =
        !departmentId &&
        !divisionId &&
        !sectionId &&
        !grpId &&
        !categoryId &&
        !teamId &&
        !jobId &&
        !managerId &&
        !managerSecondaryId &&
        !officeId &&
        !costCentreId &&
        !employmentTypeId &&
        !otClassId

      const data: EmCareerState = {
        employeeId: id,
        startDate: isAllEmpty ? hireDate || TODAY : TODAY,
        departmentId,
        divisionId,
        sectionId,
        grpId,
        categoryId,
        teamId,
        jobId,
        managerId,
        managerSecondaryId,
        officeId,
        costCentreId,
        employmentTypeId,
        otClassId
      }

      setDrawerState({ visible: true, employeeId: id, data })
    }
  }, [
    id,
    hireDate,
    departmentId,
    divisionId,
    sectionId,
    grpId,
    categoryId,
    teamId,
    jobId,
    managerId,
    managerSecondaryId,
    officeId,
    costCentreId,
    employmentTypeId,
    otClassId
  ])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  if (!canView) return null

  return (
    <Card
      title="Employment details"
      className="em-career"
      style={cardStyle}
      bodyStyle={cardBodyStyle}
      loading={loading}
      extra={canModify && <Link onClick={handleNewTransfer}>new entry</Link>}
    >
      {!hideDepartment && <EmDepartments employeeId={id} id={emDepartmentId} />}
      {!hideDivision && <EmDivisions employeeId={id} id={emDivisionId} />}
      {!hideSection && <EmSections employeeId={id} id={emSectionId} />}
      {!hideGrp && <EmGrps employeeId={id} id={emGrpId} />}
      {!hideCategory && <EmCategories employeeId={id} id={emCategoryId} />}
      {!hideTeam && <EmTeams employeeId={id} id={emTeamId} />}
      {!hideJob && <EmJobs employeeId={id} id={emJobId} />}
      {!hideManager && <EmManagers employeeId={id} id={emManagerId} />}
      {!hideManagerSecondary && <EmManagerSecondaries employeeId={id} id={emManagerSecondaryId} />}
      {!hideOffice && <EmOffices employeeId={id} id={emOfficeId} />}
      {!hideCostCentre && <EmCostCentres employeeId={id} id={emCostCentreId} />}
      {!hideEmploymentType && <EmEmploymentTypes employeeId={id} id={emEmploymentTypeId} />}
      {!hideOtClass && <EmOtClasses employeeId={id} id={emOtClassId} />}
      {canModify && <MutateEmCareerDrawer {...drawerState} onClose={handleCloseDrawer} />}
    </Card>
  )
}
