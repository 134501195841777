import { request, getBaseUrl } from '~/utils'
import { DateRange, Search } from '~/types/common'
import { ILeavePendingApproval, ILeavePendingChangeApprover, LeavePendingApprovalViewState } from '../types'

const baseUrl = getBaseUrl('/leave/leavependingapproval')

export const apiGetLeavePendingApprovalsView = (viewId: string, params: Search & DateRange) =>
  request<LeavePendingApprovalViewState>('get', `${baseUrl}/view/${viewId}`, params)

export const apiApproveLeavePendingApproval = (leavePendingApproval: ILeavePendingApproval) =>
  request('post', `${baseUrl}/approve`, leavePendingApproval)

export const apiRejectLeavePendingApproval = (leavePendingApproval: ILeavePendingApproval) =>
  request('post', `${baseUrl}/reject`, leavePendingApproval)

export const apiChangeApproverLeavePendingApproval = (leavePendingChangeApprover: ILeavePendingChangeApprover) =>
  request('post', `${baseUrl}/changeapprover`, leavePendingChangeApprover)
