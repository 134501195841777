import React, { FC, useState, useCallback, useEffect } from 'react'
import { Form } from '~/core-components'
import { DrawerForm } from '~/components'
import { EMPTY_OFFICE_INFO_FORM_DATA, OfficeInfoForm } from './OfficeInfoForm'
import { dispatch } from '~/stores/store'
import { updateCompanyOffice } from '../../../actions'
import { ICompanyOfficeInfo } from '../../../types'
import { CompanyOfficeTable } from './CompanyOffices'

interface EditOfficeDrawerProps {
  visible: boolean
  data?: CompanyOfficeTable
  onClose: () => void
}

export const EditOfficeDrawer: FC<EditOfficeDrawerProps> = ({ visible, data, onClose }: EditOfficeDrawerProps) => {
  const [formData, setFormData] = useState<ICompanyOfficeInfo>(EMPTY_OFFICE_INFO_FORM_DATA)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (data) {
      setFormData({
        name: data.name,
        isMain: data.isMain,
        block: data.block,
        street: data.street,
        level: data.level,
        unit: data.unit,
        building: data.building,
        city: data.city,
        state: data.state,
        postalCode: data.postalCode,
        countryCode: data.countryCode,
        phone: data.phone,
        holidayId: data.holidayId,
        inactiveDate: data.inactiveDate
      })
    } else {
      setFormData(EMPTY_OFFICE_INFO_FORM_DATA)
    }
  }, [data])

  const handleOk = useCallback(async () => {
    if (data?.id) {
      setLoading(true)
      try {
        await dispatch(updateCompanyOffice(data.id, data.companyId, data.officeId, mapToRequest(data), formData))
      } finally {
        setLoading(false)
      }
    }
    typeof onClose === 'function' && onClose()
    setFormData(EMPTY_OFFICE_INFO_FORM_DATA)
  }, [formData, data, onClose])

  return (
    <DrawerForm
      open={visible}
      title="Edit office"
      onClose={onClose}
      confirmLoading={loading}
      width={550}
      formId="form-office"
    >
      <Form id="form-office" onFinish={handleOk}>
        <OfficeInfoForm
          usage="companyoffice"
          data={formData}
          onChange={data => setFormData({ ...formData, ...data })}
        />
      </Form>
    </DrawerForm>
  )
}

const mapToRequest = (companyOffice: CompanyOfficeTable): ICompanyOfficeInfo => ({
  name: companyOffice.name,
  isMain: companyOffice.isMain,
  block: companyOffice.block,
  street: companyOffice.street,
  level: companyOffice.level,
  unit: companyOffice.unit,
  building: companyOffice.building,
  city: companyOffice.city,
  state: companyOffice.state,
  postalCode: companyOffice.postalCode,
  countryCode: companyOffice.countryCode,
  phone: companyOffice.phone,
  holidayId: companyOffice.holidayId,
  inactiveDate: companyOffice.inactiveDate
})
