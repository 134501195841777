import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { SysSelectionFieldState, Screen } from '../types'

export const selectSysSelectionField = createSelector(
  (state: StoreState) => state.selection.sysSelectionFields,
  (state: StoreState) => state.selection.sysSelectionScreens,
  (selectionFieldsState, sysSelectionScreensState) =>
    memoize((screenCode: Screen): SysSelectionFieldState[] => {
      const selectionCodes = Object.values(sysSelectionScreensState[screenCode]?.entities || {}).map(
        s => s?.selectionCode
      )

      const selectionFields = Object.values(selectionFieldsState[screenCode]?.entities || {})
        .filter(f => selectionCodes.includes(f?.selectionCode))
        .sort((a, b) => {
          const aSequence = a!.sequence
          const bSequence = b!.sequence
          return bSequence > aSequence ? -1 : 1
        })

      return selectionFields as SysSelectionFieldState[]
    })
)
