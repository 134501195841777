import { ThunkAction, ThunkDispatch as GenericThunkDispatch } from 'redux-thunk'
import { PayloadAction } from '@reduxjs/toolkit'
import { GlobalState } from '~/features/global/types'
import { LocaleState } from '~/features/locale/types'
import { MicroAppRootState } from '~/features/micro-app/types'
import { MasterRootState } from '~/features/master/types'
import { EmployeeRootState } from '~/features/employee/types'
import { SelectionRootState } from '~/features/selection/types'
import { RuleRootState } from '~/features/rule/types'
import { FormulaRootState } from '~/features/formula/types'
import { PayrollRootState } from '~/features/payroll/types'
import { TaxRootState } from '~/features/tax'
import { LeaveRootState } from '~/features/leave'
import { ClaimRootState } from '~/features/claim'
import { AttendanceRootState } from '~/features/attendance/types'
import { ScheduleRootState } from '~/features/schedule/types'
import { JobRootState } from '~/features/job'
import { NotificationRootState } from '~/features/notification/types/state'
import { IamRootState } from '~/features/iam/types'
import { TempRootState } from '~/features/temp/types'
import { ReportRootState } from '~/features/report'
import { GroupingRootState } from '~/features/grouping'
import { WorkflowRootState } from '~/features/workflow'
import { MyRootState } from '~/features/my/types'
import { MyClaimRootState } from '~/features/my-claim/types'
import { MyPayrollRootState } from '~/features/my-payroll/types'
import { MyAttendanceRootState } from '~/features/my-attendance'
import { MyScheduleRootState } from '~/features/my-schedule'
import { CalendarViewerRootState } from '~/features/calendar-viewer'
import { ManagerRootState } from '~/features/manager'

export const storePersistConfig = {
  key: 'root',
  whitelist: ['global']
}

export interface StoreState {
  global: GlobalState
  locale: LocaleState
  microApp: MicroAppRootState
  master: MasterRootState
  employee: EmployeeRootState
  payroll: PayrollRootState
  tax: TaxRootState
  leave: LeaveRootState
  claim: ClaimRootState
  attendance: AttendanceRootState
  schedule: ScheduleRootState
  job: JobRootState
  report: ReportRootState
  notification: NotificationRootState
  selection: SelectionRootState
  workflow: WorkflowRootState
  grouping: GroupingRootState
  rule: RuleRootState
  formula: FormulaRootState
  iam: IamRootState
  temp: TempRootState
  my: MyRootState
  myClaim: MyClaimRootState
  myPayroll: MyPayrollRootState
  myAttendance: MyAttendanceRootState
  mySchedule: MyScheduleRootState
  calendarViewer: CalendarViewerRootState
  manager: ManagerRootState
}

export const DEFAULT_REMOTE_DATA = { loading: false, data: undefined }

export type ThunkResult<R> = ThunkAction<R, StoreState, undefined, PayloadAction<any>>

export type ThunkDispatch = GenericThunkDispatch<StoreState, undefined, any>

export interface Result {
  [key: string]: any
}

export interface Errors {
  [key: string]: string[]
}

export interface ActionResult<T = Result> {
  result?: T
  errors?: Errors
}
