import { setIr21SubmissionA1View, setIr21SubmissionA1ViewsLoading } from '../reducers'
import { apiGetIr21SubmissionA1View } from '../api/ir21-submission.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions, Pagination } from '~/types/common'

export const fetchIr21SubmissionA1View = (
  id: string | undefined,
  viewId: string,
  pagination: Pagination,
  search: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id || !viewId) return

    const loading = getState().tax.ir21SubmissionA1ViewsLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().tax.ir21SubmissionA1Views?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setIr21SubmissionA1ViewsLoading(true))
      const { result, status } = await apiGetIr21SubmissionA1View(id, viewId, { ...pagination, search })
      if (status) {
        dispatch(setIr21SubmissionA1View(result))
      }
    } finally {
      dispatch(setIr21SubmissionA1ViewsLoading(false))
    }
  }
}
