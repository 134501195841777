import { Descriptions as InternalDescriptions, DescriptionsProps as InternalDescriptionsProps } from './Descriptions'
import { DescriptionsItem } from './DescriptionsItem'

type TDescriptions = typeof InternalDescriptions
interface IDescriptions extends TDescriptions {
  Item: typeof DescriptionsItem
}

const Descriptions: IDescriptions = InternalDescriptions as IDescriptions
Descriptions.Item = DescriptionsItem
export { Descriptions }

export type DescriptionsProps = InternalDescriptionsProps
