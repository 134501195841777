import { showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiExecuteEmployeeImport } from '../api/employee-import.api'

export const executeEmployeeImport = (importCode: string): ThunkResult<void> => {
  return async () => {
    const { status, result, errors, message, errorData } = await apiExecuteEmployeeImport(importCode)
    if (!status) {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors, result }
  }
}
