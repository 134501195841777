import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { ReasonState } from '../types'

export const selectReasonsKeyvalues = createSelector(
  (state: StoreState) => state.master.masters['reason'],
  reasonState =>
    memoize((type: string): ReasonState[] => {
      let reasons = Object.values((reasonState?.entities as { [id: string]: ReasonState }) || {})
      if (type !== 'all') {
        reasons = reasons.filter(reason => reason?.type === type)
      }

      let kv = reasons.sort((a, b) => {
        const aInactiveDate = new Date(a!.inactiveDate || '').getTime() || 0
        const bInactiveDate = new Date(b!.inactiveDate || '').getTime() || 0

        if (bInactiveDate === 0 && aInactiveDate === 0) {
          return a!.name.localeCompare(b!.name)
        } else {
          return aInactiveDate - bInactiveDate
        }
      })

      return kv
    })
)
