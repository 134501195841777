import { SSLeaveRecordRes, SSLeaveRecordState } from './ssleave.state'

export const mapSSLeaveRecordResponseToSSLeaveRecordState = (dto: SSLeaveRecordRes): SSLeaveRecordState => {
  const dataJsonb = JSON.parse(dto.dataJsonb || '{}')

  return {
    id: dto.id,
    employeeId: dto.employeeId,
    leaveTypeId: dto.leaveTypeId,
    leaveGrantId: dto.leaveGrantId,
    startDate: dto.startDate,
    endDate: dto.endDate,
    startTime: dto.startTime,
    endTime: dto.endTime,
    startDuration: dto.startDuration,
    endDuration: dto.endDuration,
    leaveTotal: dto.leaveTotal,
    recordType: dto.recordType,
    referenceId: dto.referenceId,
    referenceName: dto.referenceName,
    referenceDate: dto.referenceDate,
    referenceSource: dto.referenceSource,
    notes: dto.notes,
    isShared: dataJsonb.isShared,
    sharedQuantity: dataJsonb.sharedQuantity,
    takenDuration: dataJsonb.takenDuration,
    relationship: dataJsonb.relationship,
    approvalStatuses: dto.approvalStatuses,
    currentApprovers: dto.currentApprovers,
    createdBy: dto.createdBy,
    createdDate: dto.createdDate,
    creatorName: dto.creatorName
  }
}
