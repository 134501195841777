import React, { CSSProperties, FC, memo, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { Card, ColumnsType, SecondaryText, Select, Skeleton, Space, Table, Tooltip } from '~/core-components'
import { emptyGuid, PayslipItemConfigQtyExpr, Permission, PermissionAction } from '~/constants'
import { useFormulas } from '~/features/formula'
import { usePermissionGate } from '~/features/iam/hooks'
import { PayslipItemConfigState, updatePayslipItemConfig } from '~/features/master'
import { usePayslipItemConfigs } from '~/features/master/hooks'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { newGuid } from '~/utils'
import './PayslipItemConfig.less'

type PayslipItemConfigTable = {
  name: string
  children: PayslipItemConfigState[]
}

export interface PayItemsSgProps {
  payGroupId: string
}

const cardBodyStyle: CSSProperties = { padding: 0 }
const groupRowStyle: CSSProperties = { whiteSpace: 'nowrap' }
const secondaryTextStyles: CSSProperties = { display: 'block', fontSize: 12 }
const expandable = { defaultExpandAllRows: true, expandRowByClick: false }
const labelRow1Style: CSSProperties = { lineHeight: 1.1 }

const AdditionSign = memo(() => (
  <Tooltip title="Allowance">
    <PlusOutlined style={{ color: 'green' }} />
  </Tooltip>
))
const DeductionSign = memo(() => (
  <Tooltip title="Deduction">
    <MinusOutlined style={{ color: 'red' }} />
  </Tooltip>
))

export const PayslipItemConfig: FC<PayItemsSgProps> = ({ payGroupId }) => {
  const [updating, setUpdating] = useState<string>()
  const [payslipItemConfigs] = usePayslipItemConfigs(payGroupId)
  const dataLoading = useSelector((state: StoreState) => state.master.payItemsLoading)
  const [formulas] = useFormulas()
  const canModify = usePermissionGate(Permission.payMaster, PermissionAction.Modify)

  const handleFormDataChange = useCallback(
    async (payslipItemConfig: PayslipItemConfigState, updates: { [field: string]: any }) => {
      setUpdating(payslipItemConfig.payItemId)
      try {
        if (payslipItemConfig.id === emptyGuid) {
          payslipItemConfig.id = newGuid()
        }

        const updated = { ...payslipItemConfig, ...updates }
        await dispatch(
          updatePayslipItemConfig(
            payslipItemConfig.payGroupId,
            payslipItemConfig.payItemId,
            payslipItemConfig.id,
            payslipItemConfig,
            updated
          )
        )
      } finally {
        setUpdating(undefined)
      }
    },
    []
  )

  const columns: ColumnsType<PayslipItemConfigTable | PayslipItemConfigState> = [
    {
      title: 'Payroll item',
      key: 'name',
      dataIndex: 'name',
      render: (value: string, record) => {
        if ('children' in record) {
          return <div style={groupRowStyle}>{value}</div>
        }

        let rateDisplay = ''
        if (record.formulaId) {
          if (record.rate > 1) {
            rateDisplay = `${record.rate} x ${formulas[record.formulaId]?.name.toLowerCase()}`
          } else {
            rateDisplay = formulas[record.formulaId]?.name || ''
          }
        } else if (record.rate > 1) {
          rateDisplay = `Fixed rate: ${record.rate.toString()}` // fixed rate
        }

        return (
          <Space align="start">
            {record.type === 'a' ? <AdditionSign /> : <DeductionSign />}
            <div style={labelRow1Style}>
              {value} <SecondaryText style={secondaryTextStyles}>{rateDisplay}</SecondaryText>
            </div>
          </Space>
        )
      }
    },
    {
      title: 'Show quantity / rate',
      key: 'quantityExpr',
      dataIndex: 'quantityExpr',
      align: 'right',
      render: (value: string, record) => {
        if ('children' in record) {
          return null
        }

        return value ? (
          <Select
            loading={updating === record.payItemId}
            size="small"
            dropdownMatchSelectWidth={false}
            value={value}
            allowClear={false}
            readOnly={!canModify}
            onChange={(val: string) => handleFormDataChange(record, { quantityExpr: val })}
          >
            <Select.Option key={PayslipItemConfigQtyExpr.qtyOnly} value={PayslipItemConfigQtyExpr.qtyOnly}>
              Quantity only
            </Select.Option>
            <Select.Option key={PayslipItemConfigQtyExpr.qtyRate} value={PayslipItemConfigQtyExpr.qtyRate}>
              Quantity x Rate
            </Select.Option>
            <Select.Option key={PayslipItemConfigQtyExpr.none} value={PayslipItemConfigQtyExpr.none}>
              Not show
            </Select.Option>
          </Select>
        ) : null
      }
    }
  ]

  return (
    <div className="payslip-items payslip-items-sg">
      {payslipItemConfigs?.length > 0 ? (
        <Card bodyStyle={cardBodyStyle}>
          <Table
            rowKey="payItemId"
            dataSource={payslipItemConfigs}
            pagination={false}
            expandable={expandable}
            columns={columns}
            loading={dataLoading}
            className="payslip-items__table"
          />
        </Card>
      ) : (
        <Skeleton active />
      )}
    </div>
  )
}
