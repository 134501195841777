import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Screen } from '~/features/selection'
import { StoreState } from '~/types/store'
import { dispatch } from '~/stores/store'
import { fetchSysCriteriaFields, fetchSysCriteriaScreens } from '../actions'

export const useFetchSysCriteria = (screenCode: Screen) => {
  const refetch = useSelector((state: StoreState) => state.selection.sysCriteriaFieldsRefetch)

  useEffect(() => {
    dispatch(fetchSysCriteriaScreens(screenCode, { strategy: 'when-empty' }))
    dispatch(fetchSysCriteriaFields(screenCode, { strategy: 'when-empty' }))
  }, [screenCode, refetch])
}
