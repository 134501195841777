import React, { FC } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Form, Spin, Switch } from '~/core-components'

export interface AutomationSwitcherProps {
  checked: boolean
  loading: boolean
  onChange?: (checked: boolean) => void
}

export const AutomationSwitcher: FC<AutomationSwitcherProps> = ({ checked, loading, onChange }) => {
  return (
    <Form.Item label="Automation">
      {loading ? (
        <Spin indicator={<LoadingOutlined spin />} />
      ) : (
        <Switch size="small" checkedChildren="Yes" unCheckedChildren="No" checked={checked} onChange={onChange} />
      )}
    </Form.Item>
  )
}
