import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import persistReducer from 'redux-persist/es/persistReducer'
import { schedulePersistConfig, ScheduleRootState, ScheduleRecordViewState } from './types'

const scheduleRecordsViewAdapter = createEntityAdapter<ScheduleRecordViewState>({ selectId: model => model.viewId })

const scheduleRecordViewInitialState = scheduleRecordsViewAdapter.getInitialState()

const initialState: ScheduleRootState = {
  refetchScheduleRecordsView: 0,
  refetchScheduleRecordsViewRow: undefined,
  refetchScheduleRecordsViewCell: undefined,
  scheduleRecordsView: scheduleRecordViewInitialState,
  scheduleRecordsViewLoading: false
}

const scheduleSlice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    // scheduleRecordsView
    refetchScheduleRecordsView: state => {
      state.refetchScheduleRecordsView += 1
    },
    refetchScheduleRecordsViewRow: (state, action: PayloadAction<string | undefined>) => {
      state.refetchScheduleRecordsViewRow = action.payload
    },
    refetchScheduleRecordsViewCell: (state, action: PayloadAction<string | undefined>) => {
      state.refetchScheduleRecordsViewCell = action.payload
    },
    setScheduleRecordsView: (state, action: PayloadAction<ScheduleRecordViewState>) => {
      scheduleRecordsViewAdapter.upsertOne(state.scheduleRecordsView, action.payload)
    },
    setScheduleRecordsViewLoading: (state, action: PayloadAction<boolean>) => {
      state.scheduleRecordsViewLoading = action.payload
    }
  }
})

export const {
  refetchScheduleRecordsView,
  refetchScheduleRecordsViewRow,
  refetchScheduleRecordsViewCell,
  setScheduleRecordsView,
  setScheduleRecordsViewLoading
} = scheduleSlice.actions

export const scheduleReducers = {
  schedule: persistReducer<ScheduleRootState>(schedulePersistConfig, scheduleSlice.reducer)
}
