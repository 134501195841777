import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Person, PersonProps } from '~/components'
import { fetchDefaultViewSchema, fetchSysSelectionFields, Screen } from '~/features/selection'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { getBaseUrl } from '~/utils'
import { fetchEmPublicPerson } from '../../actions'
import { selectEmPublicDescription } from '../../selectors'
import './EmPublicPerson.less'

export interface EmPublicPersonProps extends Omit<PersonProps, 'photo'> {
  id: string
  defaultName?: string
  defaultPhotoId?: string
  size?: number
}

const screenCode: Screen = 'em_person'
const baseUrl = getBaseUrl('/filestore')

export const EmPublicPerson: FC<EmPublicPersonProps> = ({ id, defaultName, defaultPhotoId, size = 48, ...props }) => {
  const data = useSelector((state: StoreState) => state.employee.emPublicPersons.entities[id])
  const loading = useSelector((state: StoreState) => state.employee.emPublicPersonsLoading)
  const description = useSelector(selectEmPublicDescription)(id)
  const name = data?.name || defaultName
  const photoId = data?.photoId || defaultPhotoId

  useEffect(() => {
    dispatch(fetchSysSelectionFields(screenCode, { strategy: 'when-empty' }))
    dispatch(fetchDefaultViewSchema(screenCode, { strategy: 'when-empty' }))
  }, [])

  useEffect(() => {
    dispatch(fetchEmPublicPerson(id, { strategy: 'when-empty' }))
  }, [id])

  return (
    <div className="em-public-person">
      <Person
        {...props}
        name={name}
        description={description}
        size={size}
        loading={loading}
        photo={photoId && `${baseUrl}/publicfile/${photoId}/thumbnailphoto/${size}`}
        // photo={id && `/employee/employee/${id}/avatar/${size}?photo_id=${photoId}`}
      />
    </div>
  )
}
