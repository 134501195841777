import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { IAdjustLeaveEntitlement } from '../types'
import { apiAdjustLeaveEntitlement } from '../api/leave-entitlement.api'

export const adjustLeaveEntitlement = (
  employeeId: string,
  leaveTypeId: string,
  adjust: IAdjustLeaveEntitlement
): ThunkResult<void> => {
  return async () => {
    const { status, errors, message, errorData } = await apiAdjustLeaveEntitlement(employeeId, leaveTypeId, adjust)
    if (status) {
      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
