import { setPayRecurringOverride, setPayRecurringOverrideLoading } from '../reducers'
import { apiGetPayRecurringOverride } from '../api/payrecurring.api'
import { ThunkResult } from '~/types/store'

export const fetchPayRecurringOverride = (
  payRunId: string,
  employeeId: string,
  payItemId: string,
  payTranBatchId: string
): ThunkResult<void> => {
  return async dispatch => {
    if (!payRunId || !employeeId || !payItemId) return

    try {
      dispatch(setPayRecurringOverrideLoading(true))
      const { result, status } = await apiGetPayRecurringOverride(payRunId, employeeId, payItemId, payTranBatchId)
      if (status) {
        dispatch(setPayRecurringOverride(result))
      }
    } finally {
      dispatch(setPayRecurringOverrideLoading(false))
    }
  }
}
