import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { EmSalaryState, IEmSalary } from '../types'

const baseUrl = getBaseUrl('/employee/employee')

export const apiGetEmSalaries = (employeeId: string) =>
  request<EmSalaryState[]>('get', `${baseUrl}/${employeeId}/salary`)

export const apiGetEmSalary = (employeeId: string, emSalaryId: string) =>
  request<EmSalaryState>('get', `${baseUrl}/${employeeId}/salary/${emSalaryId}`)

export const apiAddEmSalary = (employeeId: string, emSalary: IEmSalary) =>
  request<{ id: string; emSalaryId?: string }>('post', `${baseUrl}/${employeeId}/salary`, emSalary)

export const apiUpdateEmSalary = (employeeId: string, id: string, emSalary: Operation[]) =>
  request<{ emSalaryId?: string }>('patch', `${baseUrl}/${employeeId}/salary/${id}`, emSalary)

export const apiDeleteEmSalary = (employeeId: string, id: string) =>
  request<{ emSalaryId?: string }>('delete', `${baseUrl}/${employeeId}/salary/${id}`)
