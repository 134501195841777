export enum LveAdvanceLeave {
  Forecast = 'forecast',
  FullYear = 'full_year'
}

export class LveAllowHalfDay {
  static get yes() {
    return 'y'
  }
  static get no() {
    return 'n'
  }
}

export class LveApproval {
  static get reportingManager() {
    return 'mgr'
  }
  static get workflow() {
    return 'wf'
  }
  static get notRequired() {
    return 'x'
  }
}

export class LveApprovalStatus {
  static get approved() {
    return 'a'
  }
  static get cancelled() {
    return 'c'
  }
  static get rejected() {
    return 'r'
  }
  static get pending() {
    return 'p'
  }
  static get pendingCancel() {
    return 'pc'
  }
}

export class LveCitizen {
  static get citizen() {
    return 'c'
  }
  static get nonCitizen() {
    return 'nc'
  }
  static get all() {
    return '*'
  }
}

export class LveDuration {
  static get fullDay() {
    return 'f'
  }
  static get firstHalf() {
    return 'h1'
  }
  static get secondHalf() {
    return 'h2'
  }
}

export class LveEarnedBasis {
  static get monthly() {
    return 'm'
  }
  static get daily() {
    return 'd'
  }
  static get full() {
    return 'f'
  }
  static get noEntitlement() {
    return 'x'
  }
}

export class LveEntitlementUnit {
  static get days() {
    return 'd'
  }
  static get weeks() {
    return 'w'
  }
}

export class LveExcessAction {
  static get forfeited() {
    return 'exc_fft'
  }
  static get encashed() {
    return 'exc_enc'
  }
  static get extended() {
    return 'exc_ext'
  }
}

export class LveForfeitHoliday {
  static get yes() {
    return 'y'
  }
  static get no() {
    return 'n'
  }
}

export class LvePeriodBasis {
  static get calendar() {
    return 'c'
  }
  static get custom() {
    return 'm'
  }
  static get anniversary() {
    return 'a'
  }
  static get contract() {
    return 'n'
  }
}

export class LveProrationType {
  static get day() {
    return 'd'
  }
  static get month() {
    return 'm'
  }
  static get serviceMonth() {
    return 'ms'
  }
  static get monthWithinPeriod() {
    return 'mp'
  }
  static get none() {
    return 'x'
  }
}

export class LveMonthProrationType {
  static get start() {
    return 'start'
  }
  static get end() {
    return 'end'
  }
}

export class LveRecordType {
  static get new() {
    return 'n'
  }
  static get cancellation() {
    return 'c'
  }
}

export class LveRequireConfirm {
  static get yesEither() {
    return 'y1'
  }
  static get yesBoth() {
    return 'y2'
  }
  static get no() {
    return 'n'
  }
}

export class LveRelationOption {
  static get family() {
    return 'f'
  }
  static get entry() {
    return 'e'
  }
}

export class LveSysLeaveType {
  static get adp() {
    return 'adp'
  }
  static get adpf() {
    return 'adpf'
  }
  static get anl() {
    return 'anl'
  }
  static get bdl() {
    return 'bdl'
  }
  static get ccl() {
    return 'ccl'
  }
  static get com() {
    return 'com'
  }
  static get ecl() {
    return 'ecl'
  }
  static get enl() {
    return 'enl'
  }
  static get grl() {
    return 'grl'
  }
  static get hos() {
    return 'hos'
  }
  static get mar() {
    return 'mar'
  }
  static get mat() {
    return 'mat'
  }
  static get matf() {
    return 'matf'
  }
  static get npl() {
    return 'npl'
  }
  static get nsl() {
    return 'nsl'
  }
  static get pat() {
    return 'pat'
  }
  static get spl() {
    return 'spl'
  }
  static get sic() {
    return 'sic'
  }
  static get uicl() {
    return 'uicl'
  }
  static get xnl() {
    return 'xnl'
  }
}

export class LveSysLeaveTypeType {
  static get entitlement() {
    return 'en'
  }
  static get noEntitlement() {
    return 'xn'
  }
  static get grant() {
    return 'gr'
  }
}

export class LveUnit {
  static get days() {
    return 'd'
  }
  static get hours() {
    return 'h'
  }
  static get weeks() {
    return 'w'
  }
}

export class LveVisibleType {
  static get hidden() {
    return 'x'
  }
  static get basic() {
    return 'b'
  }
  static get advance() {
    return 'a'
  }
}

export class LveAttachment {
  static get optional() {
    return 'o'
  }
  static get mandatory() {
    return 'm'
  }
  static get notApplicable() {
    return 'x'
  }
}

export const LveAttachmentFileTypeAllowed = [
  '.bmp',
  '.pdf',
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
  '.csv',
  '.txt',
  '.rtf',
  '.jpg',
  '.jpeg',
  '.png',
  '.gif'
]

export enum LveBuddyActionType {
  Block = 'b',
  Display = 'd',
  DisplayOnApproval = 'da'
}

export enum LveBuddyEventType {
  Submission = 'submission',
  Approval = 'approval'
}
