import { replacePayRecurrings, setPayRecurringLoading } from '../reducers'
import { apiGetPayRecurring } from '../api/payrecurring.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchPayRecurrings = (
  employeeId: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!employeeId) return

    const loading = getState().payroll.payRecurringsLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const data = Object.values(getState().payroll.payRecurrings?.entities || {})
      const hasData = data?.some(x => x?.employeeId === employeeId)
      if (hasData) return
    }

    try {
      dispatch(setPayRecurringLoading(true))
      const { result, status } = await apiGetPayRecurring(employeeId)
      if (status) {
        dispatch(replacePayRecurrings(result))
      }
    } finally {
      dispatch(setPayRecurringLoading(false))
    }
  }
}
