import { replaceLocationAdmins, setLocationAdminsLoading } from '../reducers'
import { apiGetLocationAdmins } from '../api/location.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchLocationAdmins = (
  locationId: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().attendance.locationAdminsLoading[locationId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().attendance.locationAdmins[locationId]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setLocationAdminsLoading({ id: locationId, loading: true }))
      const { result, status } = await apiGetLocationAdmins(locationId)
      if (status) {
        dispatch(replaceLocationAdmins({ id: locationId, data: result }))
      }
    } finally {
      dispatch(setLocationAdminsLoading({ id: locationId, loading: false }))
    }
  }
}
