import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiDeleteOffice } from '../api/office.api'
import { removeOffice, removeKeyvalue, replaceCompanyOffices } from '../reducers'
import { CompanyOfficeState } from '../types'

export const deleteOffice = (id: string): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const { status, errors, message, errorData } = await apiDeleteOffice(id)
    if (status) {
      dispatch(removeOffice(id))
      dispatch(removeKeyvalue({ kv: 'office', id }))

      let companyOffices = Object.values(getState().master.companyOffices?.entities || {})
      companyOffices = companyOffices.filter(co => co?.officeId !== id)
      dispatch(replaceCompanyOffices(companyOffices as CompanyOfficeState[]))

      showSuccess('Deleted')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
