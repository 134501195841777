import React, { FC, useCallback, useState } from 'react'
import { Button, Space } from '~/core-components'
import { useIsMountedRef } from '~/hooks/use-is-mounted-ref'
import { dispatch } from '~/stores/store'
import { exportAllPayslips } from '../../actions'

export interface ExportAllPayslipsButtonProps {
  payRunId: string
}

export const ExportAllPayslipsButton: FC<ExportAllPayslipsButtonProps> = ({ payRunId }) => {
  const [sending, setSending] = useState(false)
  const isMountedRef = useIsMountedRef()

  const handleExportClick = useCallback(async () => {
    if (payRunId) {
      try {
        setSending(true)
        await dispatch(exportAllPayslips(payRunId))
      } finally {
        if (isMountedRef.current) setSending(false)
      }
    }
  }, [payRunId, isMountedRef])

  return (
    <Button onClick={handleExportClick} loading={sending}>
      <Space>
        <i className="fal fa-paper-plane" />
        Export all payslips
      </Space>
    </Button>
  )
}
