import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import confirm from 'antd/lib/modal/confirm'
import { LoadingOutlined } from '@ant-design/icons'
import { Form, SecondaryText, Space, Spin, Tag } from '~/core-components'
import { DrawerForm, ErrorDisplay } from '~/components'
import { fetchFormulas } from '~/features/formula'
import { selectPayItemById } from '~/features/master'
import { EmPublicPerson, fetchEmployee, fetchEmSalaries, selectEmSalaryByDate } from '~/features/employee'
import { PayTranSourceTable, SalaryBasis } from '~/constants'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors, StoreState } from '~/types/store'
import { selectPayTranImports } from '../../selectors'
import { selectPayItemMappingById, selectPayRunById } from '../../reducers'
import { deletePayTranImportBatch, fetchPayTranImportByBatchId, updatePayTranImportBatch } from '../../actions'
import { IPayTranImportInfo, PayTranSgState } from '../../types'
import { MutatePayTranImportForm, PayTranImportForm, EMPTY_PAY_TRAN_IMPORT_FORM_DATA } from './MutatePayTranImportForm'
import './EditPayTranImportDrawer.less'

export interface EditPayTranImportDrawerProps {
  visible: boolean
  id: string
  payTran?: PayTranSgState
  onClose: (action: 'save' | 'cancel') => void
}

const SOURCE_NAME: { [key in PayTranSourceTable]: string } = {
  [PayTranSourceTable.LeaveRecordDtl]: 'Leave record',
  [PayTranSourceTable.LeaveEntitlement]: 'Leave entitlement',
  [PayTranSourceTable.ClaimRecord]: 'Claim record'
}

export const EditPayTranImportDrawer: FC<EditPayTranImportDrawerProps> = ({ visible, id, payTran, onClose }) => {
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<Errors>()
  const [resetForm, setResetForm] = useState(0)
  const [formData, setFormData] = useState<PayTranImportForm>(EMPTY_PAY_TRAN_IMPORT_FORM_DATA)

  const payRunId = payTran?.payRunId || ''
  const employeeId = payTran?.employeeId || ''
  const payItemId = payTran?.payItemId || ''
  const batchId = payTran?.batchId || ''

  const payItem = useSelector((state: StoreState) => selectPayItemById(state, payItemId))
  const payRun = useSelector((state: StoreState) => selectPayRunById(state, payRunId))
  const payRunEnd = payRun?.endDate || ''
  const payTranImports = useSelector(selectPayTranImports)(batchId, employeeId, payItemId)
  const payTranImportsLoading = useSelector((state: StoreState) => state.payroll.payTranImportsLoading[batchId])
  const bpDaily = useSelector((state: StoreState) => selectPayItemMappingById(state, 'bp_daily'))
  const bpDailyPayItemId = bpDaily?.payItemId || ''
  const bpHourly = useSelector((state: StoreState) => selectPayItemMappingById(state, 'bp_hourly'))
  const bpHourlyPayItemId = bpHourly?.payItemId || ''
  const emSalary = useSelector(selectEmSalaryByDate)(employeeId, payRunEnd)
  const emSalaryBasis = emSalary?.salaryBasis || ''

  const sourceInfo = useMemo(() => {
    if (payTranImports == null || payTranImports.length === 0) {
      return {
        hasSource: false,
        sourceName: undefined
      }
    }

    return {
      hasSource: payTranImports.some(p => !!p.sourceId),
      sourceName: SOURCE_NAME[payTranImports[0].sourceTable as PayTranSourceTable]
    }
  }, [payTranImports])

  useEffect(() => {
    dispatch(fetchFormulas({ strategy: 'when-empty' }))
  }, [])

  useEffect(() => {
    if (employeeId) {
      dispatch(fetchEmployee(employeeId, { strategy: 'when-empty' }))
      dispatch(fetchEmSalaries(employeeId))
    }
  }, [employeeId])

  useEffect(() => {
    setErrors(undefined)
  }, [visible])

  useEffect(() => {
    dispatch(fetchPayTranImportByBatchId(batchId, employeeId))
  }, [batchId, employeeId])

  useEffect(() => {
    if (payTranImports && (payTranImports.length || 0) > 0) {
      const { payItemId, startDate, endDate, rate, isSysSegment } = payTranImports[0]
      const byDates = startDate === endDate
      const trans = payTranImports.map(({ startDate: date, quantity }) => ({ date, quantity }))
      setFormData({ payItemId, byDates, trans, rate, isSysSegment })
    }
  }, [payTranImports])

  const getIsSalaryAsRate = useCallback(
    (payItemId: string) => {
      return (
        (bpDailyPayItemId === payItemId && SalaryBasis.daily === emSalaryBasis) ||
        (bpHourlyPayItemId === payItemId && SalaryBasis.hourly === emSalaryBasis)
      )
    },
    [bpDailyPayItemId, bpHourlyPayItemId, emSalaryBasis]
  )
  const isSalaryAsRate = getIsSalaryAsRate(formData.payItemId)

  const handleOk = useCallback(async () => {
    if (!payRunId || !employeeId || !batchId || !payItemId) return

    let result: ActionResult | undefined
    setLoading(true)
    try {
      const payRunStart = payRun?.startDate || ''
      const payRunEnd = payRun?.endDate || ''
      const requests = mapPayTranImportFormToPayTranImportInfoRequests(formData, payRunStart, payRunEnd)
      result = await dispatch(updatePayTranImportBatch(payRunId, employeeId, batchId, payItemId, requests))
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose('save')
      setFormData(EMPTY_PAY_TRAN_IMPORT_FORM_DATA)
      setResetForm(resetForm + 1)
    }
  }, [formData, payRunId, employeeId, batchId, payItemId, payRun, onClose, resetForm])

  const handleDelete = useCallback(() => {
    if (!payRunId || !employeeId || !batchId || !payItemId) return

    confirm({
      title: 'Delete',
      content: sourceInfo.hasSource ? (
        <>
          Delete this will reverse the "Paid" information in {sourceInfo.sourceName} and allow you to import again.
          <br />
          <br />
          Do you want to delete?
        </>
      ) : (
        'Do you want to delete?'
      ),
      onOk: async () => {
        const result: ActionResult | undefined = await dispatch(
          deletePayTranImportBatch(payRunId, employeeId, batchId, payItemId)
        )

        if (result?.errors) {
          setErrors(result.errors)
        }

        if (!result?.errors) {
          typeof onClose === 'function' && onClose('save')
        }
      },
      okText: 'Delete',
      okType: 'danger'
    })
  }, [payRunId, employeeId, batchId, payItemId, sourceInfo.hasSource, sourceInfo.sourceName, onClose])

  const handleCloseDrawer = useCallback(() => {
    typeof onClose === 'function' && onClose('cancel')
  }, [onClose])

  return (
    <DrawerForm
      open={visible}
      title={
        <Space>
          <span>Edit payroll item</span>
          {sourceInfo.sourceName && (
            <Space>
              <SecondaryText size="small">source:</SecondaryText>
              <Tag>{sourceInfo.sourceName.toLowerCase()}</Tag>
            </Space>
          )}
        </Space>
      }
      onClose={handleCloseDrawer}
      confirmLoading={loading}
      width={500}
      okText={sourceInfo.hasSource ? '' : 'Save'}
      showDelete={true}
      onDelete={handleDelete}
      className="edit-paytranimport-drawer"
      formId={`form-edit-paytranimport-${id}`}
    >
      <Form id={`form-edit-paytranimport-${id}`} onFinish={handleOk}>
        <Form.Item label="">
          <EmPublicPerson id={employeeId} size={32} />
        </Form.Item>
        <Form.Item label="Payroll item">
          <div>{payItem?.name || ''}</div>
        </Form.Item>
        <ErrorDisplay keys={['payRunId']} errors={errors} />
        {payTranImportsLoading ? (
          <Spin indicator={<LoadingOutlined spin />} />
        ) : (
          <MutatePayTranImportForm
            data={formData}
            defaultPickerDate={payRun?.startDate}
            errors={errors}
            rateReadOnly={isSalaryAsRate}
            rateLabel={isSalaryAsRate ? 'Current salary' : 'Rate'}
            resetForm={resetForm}
            readOnly={sourceInfo.hasSource}
            onFormChange={data => setFormData({ ...formData, ...data })}
          />
        )}
      </Form>
    </DrawerForm>
  )
}

const mapPayTranImportFormToPayTranImportInfoRequests = (
  form: PayTranImportForm,
  startDate: string,
  endDate: string
): IPayTranImportInfo[] => {
  const result: IPayTranImportInfo[] = []
  const { byDates, trans, payItemId, rate, isSysSegment } = form

  if (byDates) {
    for (const tran of trans) {
      const { date, quantity } = tran
      if (quantity > 0 && moment(date).isValid()) {
        result.push({ payItemId, startDate: date, endDate: date, quantity, rate, isSysSegment })
      }
    }
  } else {
    const quantity = trans.length > 0 ? trans[0].quantity : 0
    result.push({ payItemId, startDate, endDate, quantity, rate, isSysSegment })
  }
  return result
}
