import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Tag, TagProps, TagType, Tooltip } from '~/core-components'
import { StoreState } from '~/types/store'

interface SysLeaveTypeTypeTagProps extends TagProps {
  code?: string
}

const LveSysLeaveTypeTypeMap: { [type: string]: { name: string; description: string; tagType: TagType } } = {
  en: {
    name: 'Entitlement leave',
    description: 'Leave entitlement is auto-calculated according to the policy setting.',
    tagType: 'success'
  },
  xn: {
    name: 'No entitlement leave',
    description:
      'Employee who is eligible to no entitlement leave may apply for the leave without cap, subject to the approval (as per setting). Example of such leave types: no pay leave, reservist leave, etc.',
    tagType: 'secondary'
  },
  gr: {
    name: 'Grant leave',
    description: 'Employee is eligible to apply for grant leave after the leave is granted by the administrator.',
    tagType: 'tertiary'
  }
}

export const SysLeaveTypeTypeTag: FC<SysLeaveTypeTypeTagProps> = ({ code, ...props }) => {
  const sysLeaveType = useSelector((state: StoreState) => state.leave.sysLeaveTypes?.entities[code || ''])
  const type = sysLeaveType?.type || ''

  if (!code || !type) return null

  return (
    <Tooltip title={LveSysLeaveTypeTypeMap[type]?.description}>
      <Tag {...props} type={LveSysLeaveTypeTypeMap[type]?.tagType}>
        {LveSysLeaveTypeTypeMap[type]?.name?.toLowerCase()}
      </Tag>
    </Tooltip>
  )
}
