import { replaceReportTemplates, setReportTemplatesLoading } from '../reducers'
import { ThunkResult } from '~/types/store'
import { BasePath, DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'
import { ReportCode } from '../types'
import { apiGetReportTemplates } from '../api/report-template.api'

export const fetchReportTemplates = (
  basePath: BasePath,
  reportCode: ReportCode,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!reportCode) return

    const loading = getState().report.reportTemplatesLoading[reportCode]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = (getState().report.reportTemplates[reportCode]?.ids?.length || 0) > 0
      if (hasData) return
    }

    try {
      dispatch(setReportTemplatesLoading({ reportCode, loading: true }))
      const { result, status } = await apiGetReportTemplates(basePath, reportCode)
      if (status) {
        dispatch(replaceReportTemplates({ reportCode, data: result }))
      }
    } finally {
      dispatch(setReportTemplatesLoading({ reportCode, loading: false }))
    }
  }
}
