import { replaceLtProrationM, setLtProrationMLoading } from '../reducers'
import { apiGetLtProrationM } from '../api/lt-proration-m.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchLtProrationM = (
  leaveTypeId: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().leave.ltProrationMLoading[leaveTypeId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().leave.ltProrationM[leaveTypeId]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setLtProrationMLoading({ leaveTypeId, loading: true }))
      const { result, status } = await apiGetLtProrationM(leaveTypeId)
      if (status) {
        dispatch(replaceLtProrationM({ leaveTypeId, data: result }))
      }
    } finally {
      dispatch(setLtProrationMLoading({ leaveTypeId, loading: false }))
    }
  }
}
