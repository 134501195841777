import React, { FC, ChangeEvent, useState, useCallback, useEffect } from 'react'
import moment from 'moment-timezone'
import { Checkbox, CheckboxChangeEvent, Form, Input, Select } from '~/core-components'
import { Col, DayKeyValues, Row, SysOptions } from '~/components'
import { Errors } from '~/types/store'
import { useFocus } from '~/hooks'
import { IAttendanceGroupInfo } from '../../../types'
import { AttendanceGroupType } from '~/constants'
import { AttendanceGroupTypeTag } from './AttendanceGroupTypeTag'

interface AttendanceGroupInfoFormProps {
  data: IAttendanceGroupInfo
  isNew?: boolean
  readOnly?: boolean
  errors?: Errors
  onChange: (data: IAttendanceGroupInfo) => void
}

export const EMPTY_ATTENDANCE_GROUP_INFO_FORM_DATA: IAttendanceGroupInfo = {
  name: '',
  inactiveDate: '',
  type: AttendanceGroupType.Monthly,
  otStartDay: 0,
  otStartMonth: 0
}

const otDayStyle = { marginRight: -1 }
const otMonthStyle = { width: 150 }

export const AttendanceGroupInfoForm: FC<AttendanceGroupInfoFormProps> = ({
  data,
  isNew = false,
  readOnly,
  errors,
  onChange
}) => {
  const [formData, setFormData] = useState<IAttendanceGroupInfo>(EMPTY_ATTENDANCE_GROUP_INFO_FORM_DATA)
  const [focusRef] = useFocus(!readOnly)
  const [isInactive, setIsInactive] = useState(false)

  useEffect(() => {
    if (data) {
      setFormData(data)
      setIsInactive(!!data.inactiveDate)
    } else {
      setFormData(EMPTY_ATTENDANCE_GROUP_INFO_FORM_DATA)
      setIsInactive(false)
    }
  }, [data])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      const updated = { ...formData, ...updates }

      if ('otStartDay' in updates) {
        if (updated.otStartDay === undefined) updated.otStartDay = 0
      }

      if ('type' in updates && updated.type !== AttendanceGroupType.Monthly) {
        updated.otStartDay = 0
        updated.otStartMonth = 0
      }

      setFormData(updated)
      typeof onChange === 'function' && onChange(updated)
    },
    [formData, onChange]
  )

  return (
    <>
      <Row gutter={15}>
        <Col flex="auto">
          <Form.Item
            label={
              <>
                Name
                {!isNew && <AttendanceGroupTypeTag code={formData.type} style={{ marginLeft: 10 }} />}
              </>
            }
            validateStatus={errors?.name ? 'error' : ''}
            help={errors?.name}
          >
            <Input
              ref={focusRef}
              value={formData.name}
              readOnly={readOnly}
              onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ name: event.target.value })}
            />
          </Form.Item>
        </Col>
        <Col flex="none">
          <Form.Item label="Inactive" validateStatus={errors?.inactiveDate ? 'error' : ''} help={errors?.inactiveDate}>
            <Checkbox
              checked={isInactive}
              readOnly={readOnly}
              onChange={(event: CheckboxChangeEvent) => {
                setIsInactive(event.target.checked)
                handleFormDataChange({ inactiveDate: event.target.checked ? moment().format('YYYY-MM-DD') : '' })
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={12}>
          <Form.Item
            label="Type"
            validateStatus={errors?.type ? 'error' : ''}
            help={errors?.type}
            tooltip={{
              title: 'Type cannot be changed once attendance group is created',
              icon: (
                <span>
                  <i className="fal fa-circle-info" />
                </span>
              )
            }}
          >
            <SysOptions
              type="attendance_group_type"
              allowClear={false}
              disabled={!isNew}
              value={formData.type}
              onChange={(type: string) => handleFormDataChange({ type })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row hidden={formData.type !== AttendanceGroupType.Monthly}>
        <Col span={24}>
          <Form.Item
            label="Overtime period starts on"
            validateStatus={errors?.otStartDay ? 'error' : ''}
            help={errors?.otStartDay}
          >
            <DayKeyValues
              value={formData.otStartDay || 0}
              style={otDayStyle}
              readOnly={readOnly}
              onChange={(otStartDay: number) => handleFormDataChange({ otStartDay })}
            />
            <Select
              value={formData.otStartMonth}
              style={otMonthStyle}
              allowClear={false}
              readOnly={readOnly}
              onChange={(otStartMonth: number) => handleFormDataChange({ otStartMonth })}
            >
              <Select.Option value={-1}>Previous month</Select.Option>
              <Select.Option value={0}>Current month</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}
