import { setPayItemMapping, setPayItemMappingLoading } from '../reducers'
import { apiGetPayItemMapping } from '../api/payitem-mapping.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchPayItemMapping = (
  referenceCode: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!referenceCode) return

    const loading = getState().payroll.payItemMappingsLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().payroll.payItemMappings.entities[referenceCode]
      if (hasData) return
    }

    try {
      dispatch(setPayItemMappingLoading(true))
      const { result, status } = await apiGetPayItemMapping(referenceCode)
      if (status) {
        dispatch(setPayItemMapping(result))
      }
    } finally {
      dispatch(setPayItemMappingLoading(false))
    }
  }
}
