import { setEmGrp, setEmGrpsLoading } from '../reducers'
import { apiGetEmGrp } from '../api/em-grp.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchEmGrp = (
  employeeId: string,
  emGrpId: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!employeeId || !emGrpId) return

    const loading = getState().employee.emGrpsLoading[employeeId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().employee.emGrps[employeeId]?.entities[emGrpId]
      if (hasData) return
    }

    try {
      dispatch(setEmGrpsLoading({ employeeId, loading: true }))
      const { result, status } = await apiGetEmGrp(employeeId, emGrpId)
      if (status) {
        dispatch(setEmGrp({ employeeId, data: result }))
      }
    } finally {
      dispatch(setEmGrpsLoading({ employeeId, loading: false }))
    }
  }
}
