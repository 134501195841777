import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchMyTimeLogTasks } from '../actions'
import { selectMyTimeLogTasks } from '../selectors'
import { ETaskInfo } from '~/features/my/types'

export const useMyTimeLogTasks = (): [ETaskInfo[], boolean] => {
  const tasks = useSelector(selectMyTimeLogTasks)
  const refetch = useSelector((state: StoreState) => state.myAttendance.myTimeLogTasksRefetch)
  const loading = useSelector((state: StoreState) => state.myAttendance.myTimeLogTasksLoading)

  useEffect(() => {
    dispatch(fetchMyTimeLogTasks())
  }, [refetch])

  return [tasks, loading]
}
