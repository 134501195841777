import { refetchEmployees, removeEmAddressRequest } from '../reducers'
import { apiApproveEmAddressRequest } from '../api/em-address-request.api'
import { ThunkResult } from '~/types/store'
import { showError, showSuccess } from '~/utils'
import { fetchEmAddresses } from './fetch-em-addresses'

export const approveEmAddressRequest = (employeeId: string, id: string): ThunkResult<void> => {
  return async dispatch => {
    const { status, result, errors, message } = await apiApproveEmAddressRequest(id)

    if (status) {
      dispatch(removeEmAddressRequest({ employeeId, id }))
      dispatch(fetchEmAddresses(employeeId))
      dispatch(refetchEmployees({ employeeId }))
      showSuccess('Approved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message)
      }
    }
    return { result, errors }
  }
}
