import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { PageHeader, SecondaryText, Tab, Tabs, Tag } from '~/core-components'
import { DocumentTitle } from '~/components'
import { IAM_ROUTES, MY_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { EditPasswordForm } from './components/EditPasswordForm'
import { EditPreferenceForm } from './components/EditPreferenceForm'
import { EditWorkspaceForm } from './components/EditWorkspaceForm'
import { ProfileAvatar } from './components/ProfileAvatar'
import { selectMyLogin } from '../../selectors'
import { fetchMyLogin } from '../../actions'
import './UserSetting.less'

export interface UserSettingProps {
  path: string
  isPortal?: boolean
}

const activeTenantCode = localStorage.getItem('tenant')

const UserSettingInternal: FC<UserSettingProps> = ({ path, isPortal }) => {
  const myJwt = useSelector((state: StoreState) => state.iam.myJwt)
  const myLoginId = myJwt?.loginId || ''
  const login = useSelector(selectMyLogin)
  const match = useRouteMatch<{ tab: string }>()
  const history = useHistory()

  useEffect(() => {
    if (activeTenantCode) {
      dispatch(fetchMyLogin(activeTenantCode, { strategy: 'when-empty' }))
    }
  }, [])

  const handleTabChange = useCallback(
    (activeKey: string) => {
      history.push(`${path.replace(':tab?', activeKey)}`)
    },
    [history, path]
  )
  const tabItems = useMemo(() => {
    const result: Tab[] = [
      { key: 'security', label: 'Security', children: <EditPasswordForm /> },
      { key: 'preference', label: 'Preference', children: <EditPreferenceForm /> }
    ]
    if (!isPortal && login.isOwner) {
      result.push({ key: 'workspace', label: 'Workspace', children: <EditWorkspaceForm /> })
    }
    return result
  }, [isPortal, login.isOwner])

  return (
    <div className="profile">
      <DocumentTitle title="Profile Settings" />
      <PageHeader title="Profile settings" />
      <div className="profile__person">
        <ProfileAvatar loginId={myLoginId} size={84} editable={true} mode="drop-shadow" />
        <div className="profile__info">
          <div className="profile__info-name">{login.name || ''}</div>
          <SecondaryText>{login?.email || ''}</SecondaryText>
          {!isPortal && (
            <div className="profile__info-tags">
              <Tag type="primary">{login.isSuperUser ? 'full access user' : 'restricted access user'}</Tag>
              {login.isOwner && <Tag type="primary">owner</Tag>}
            </div>
          )}
        </div>
      </div>
      <Tabs defaultActiveKey={match.params.tab || 'security'} onChange={handleTabChange} items={tabItems}></Tabs>
    </div>
  )
}

export const UserSetting: FC = () => <UserSettingInternal path={IAM_ROUTES.iam} />
export const MyUserSetting: FC = () => <UserSettingInternal path={MY_ROUTES.user} isPortal />
