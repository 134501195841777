import { replaceSysEventTypes, setSysEventTypesLoading } from '../reducers'
import { apiGetEventTypes } from '../api/sys-workflow.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchSysWfEventTypes = (
  flowType: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!flowType) return

    const loading = getState().workflow.sysWfEventTypesLoading[flowType]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = (getState().workflow.sysWfEventTypes[flowType]?.ids?.length || 0) > 0
      if (hasData) return
    }

    try {
      dispatch(setSysEventTypesLoading({ flowType, loading: true }))
      const { result, status } = await apiGetEventTypes(flowType)
      if (status) {
        dispatch(replaceSysEventTypes({ flowType, data: result }))
      }
    } finally {
      dispatch(setSysEventTypesLoading({ flowType, loading: false }))
    }
  }
}
