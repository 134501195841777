import React, { FC } from 'react'
import moment from 'moment-timezone'
import { Form, Input, Select } from '~/core-components'
import { Col, ErrorDisplay, Row } from '~/components'
import { Errors } from '~/types/store'
import { ImportEncashFormData } from '../ImportEncashDrawer'
import './ImportEncashInput.less'

interface ImportEncashInputProps {
  visible: boolean
  data: Partial<ImportEncashFormData>
  errors?: Errors
  onChange: (data: Partial<ImportEncashFormData>) => void
}

export const ImportEncashInput: FC<ImportEncashInputProps> = ({ visible, data, errors, onChange }) => {
  return (
    <div className="import-encash-input" hidden={!visible}>
      <Row>
        <Col span={24}>
          <Form.Item label="Type">
            <Select
              value={data.encashType}
              onChange={(value: string) => onChange({ encashType: value })}
              allowClear={false}
            >
              <Select.Option key="resign" value="resign">
                End of employment
              </Select.Option>
              <Select.Option key="year_end" value="year_end">
                Year end
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24} hidden={data.encashType !== 'resign'}>
          <Form.Item label="Employment end date up to">
            <Input.Date
              value={data.upToDate ? moment(data.upToDate) : undefined}
              onChange={(value: moment.Moment | null) => {
                onChange({ upToDate: value?.format('YYYY-MM-DD') })
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <ErrorDisplay errors={errors} />
    </div>
  )
}
