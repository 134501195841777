import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils'
import { Search } from '~/types/common'
import { IJob, JobViewState } from '../types'

const baseUrl = getBaseUrl('/job/job')

export const apiGetJobView = (viewId: string, params: Search) =>
  request<JobViewState>('get', `${baseUrl}/view/${viewId}`, params)

export const apiAddJob = (job: IJob) => request<{ id: string }>('post', baseUrl, job)

export const apiUpdateJob = (id: string, job: Operation[]) => request('patch', `${baseUrl}/${id}`, job)

export const apiDeleteJob = (id: string) => request('delete', `${baseUrl}/${id}`)

export const apiRunOneTimeJob = (id: string) => request('post', `${baseUrl}/${id}/run`)
