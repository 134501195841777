import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { Card, ColumnsType, Link, SecondaryText, Space, Table, Tag, Tooltip } from '~/core-components'
import { SSEmFamilyPublicSelfState } from '../../../../../types'
import { fetchEmPublic, maskString, useSysOptions } from '~/features/employee'
import './MyFamilies.less'
import { useSelector } from 'react-redux'
import { fetchEmpKeyvalues, selectKeyvaluesById } from '~/features/master'
import { dispatch } from '~/stores/store'
import { MutateMyFamilyDrawer } from './MutateMyFamilyDrawer'

interface MyFamiliesProps {
  employeeId: string
  data: SSEmFamilyPublicSelfState[]
  hideSelfUpdate: boolean
}

interface DrawerState {
  visible: boolean
  employeeId?: string
  data?: SSEmFamilyPublicSelfState
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

const labelRow1Style: CSSProperties = { lineHeight: 1.1 }
const labelRow2Style: CSSProperties = { fontSize: 12 }

export const MyFamilies: FC<MyFamiliesProps> = ({ employeeId, data, hideSelfUpdate }) => {
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const [relationships] = useSysOptions('relationship')
  const [genders] = useSysOptions('gender')
  const [identityTypes] = useSysOptions('identity_type')
  const [yesNo] = useSysOptions('yes_no')
  const nationalities = useSelector(selectKeyvaluesById)('nationality')

  useEffect(() => {
    dispatch(fetchEmpKeyvalues('nationality', undefined, undefined, { strategy: 'when-empty' }))
  }, [])

  const columns: ColumnsType<SSEmFamilyPublicSelfState> = [
    {
      title: 'Name',
      key: 'nameNew',
      dataIndex: 'nameNew',
      render: (value: string, record) => (
        <>
          <div style={labelRow1Style}>
            <Space>
              {value}
              {record.recordStatus === 'new_request' && <Tag type="secondary">new (pending)</Tag>}
              {record.recordStatus === 'has_request' && <Tag type="secondary">pending</Tag>}
            </Space>
          </div>
          <SecondaryText style={labelRow2Style}>
            {relationships[record.relationNew]?.value || '-'} ({genders[record.genderNew]?.value || '-'})
          </SecondaryText>
        </>
      )
    },
    {
      title: 'Date of birth',
      key: 'birthDateNew',
      dataIndex: 'birthDateNew',
      width: 110,
      render: (value: string) => value && moment(value).format('DD MMM YYYY')
    },
    {
      title: 'Nationality',
      key: 'nationalityIdNew',
      dataIndex: 'nationalityIdNew',
      render: (value: string) => nationalities[value]?.value
    },
    {
      title: 'Phone',
      key: 'phoneNew',
      dataIndex: 'phoneNew'
    },
    {
      title: 'Dependant',
      key: 'dependantNew',
      dataIndex: 'dependantNew',
      render: (value: boolean) => yesNo[value.toString()]?.value
    },
    {
      title: 'Identification',
      key: 'identityNoNew',
      dataIndex: 'identityNoNew',
      render: (value: string, record) => (
        <>
          <div style={labelRow1Style}>
            <Tooltip title={value}>{maskString(value)}</Tooltip>
          </div>
          <SecondaryText style={labelRow2Style}>{identityTypes[record.identityTypeNew]?.value}</SecondaryText>
        </>
      )
    },
    {
      key: 'action',
      align: 'right',
      render: (value: string, record) =>
        !hideSelfUpdate && (
          <Link size="small" onClick={() => handleEditFamily(record)}>
            edit
          </Link>
        )
    }
  ]

  const handleAddFamily = useCallback(() => {
    setDrawerState({ visible: true, employeeId, data: undefined })
  }, [employeeId])

  const handleEditFamily = useCallback(
    (myFamily: SSEmFamilyPublicSelfState) => {
      setDrawerState({ visible: true, employeeId, data: myFamily })
    },
    [employeeId]
  )

  const handleCloseDrawer = useCallback(
    (action: 'saved' | 'cancelled') => {
      setDrawerState(DEFAULT_DRAWER_STATE)
      if (action === 'saved') {
        dispatch(fetchEmPublic(employeeId, { strategy: 'always' }))
      }
      setDrawerState(DEFAULT_DRAWER_STATE)
    },
    [employeeId]
  )

  return (
    <Card
      title="Family members"
      className="my-families"
      extra={employeeId && !hideSelfUpdate && <Link onClick={handleAddFamily}>add</Link>}
    >
      <Table rowKey="id" dataSource={data} pagination={false} columns={columns} />
      {!hideSelfUpdate && <MutateMyFamilyDrawer {...drawerState} onClose={handleCloseDrawer} />}
    </Card>
  )
}
