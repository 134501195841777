import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Modal } from '~/core-components'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { setLogin } from '../reducers'
import { EditPasswordForm } from './UserSetting/components/EditPasswordForm'

export const ForceChangePassword: FC = () => {
  const [visible, setVisible] = useState(false)
  const myLoginId = useSelector((state: StoreState) => state.iam.myJwt?.loginId)
  const login = useSelector((state: StoreState) => state.iam.logins.entities[myLoginId || ''])
  const isForceChangePassword = login?.forceChangePassword === true

  useEffect(() => {
    if (isForceChangePassword) {
      setVisible(true)
    }
  }, [isForceChangePassword])

  const handleComplete = useCallback(() => {
    if (login) {
      dispatch(setLogin({ ...login, forceChangePassword: false }))
      setVisible(false)
    }
  }, [login])

  return (
    <Modal open={visible} title="Change password" width={300} closable={false}>
      <EditPasswordForm label="Change password" onComplete={handleComplete} />
    </Modal>
  )
}
