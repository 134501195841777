import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { CtPolicyState } from '../../claim/types'

export type LtPolicyExt = CtPolicyState & {
  confirmDisplay: string
}

export const selectCtPoliciesById = createSelector(
  (state: StoreState) => state.claim.ctPolicies,
  ctPoliciesState =>
    memoize((claimTypeId: string) => {
      const ctPolicies = Object.values(ctPoliciesState[claimTypeId]?.entities || {}) as CtPolicyState[]
      return ctPolicies.sort((a, b) => a.policyName?.localeCompare(b.policyName))
    })
)
