import { setOedRecordForm, setOedRecordFormUpdating } from '../reducers'
import { apiUpdateOedRecord } from '../api/rpt-oed-record.api'
import { ThunkResult } from '~/types/store'
import { showSuccess, showError } from '~/utils'
import { OedRecordFormState } from '../types'

export const updateOedRecordForm = (request: OedRecordFormState): ThunkResult<void> => {
  return async dispatch => {
    try {
      dispatch(setOedRecordFormUpdating(true))
      const { status, errors, message, errorData } = await apiUpdateOedRecord(request)
      if (status) {
        dispatch(setOedRecordForm(request))
        showSuccess('Saved')
      } else {
        if (Object.keys(errors).length === 0) {
          showError(message, errorData)
        }
      }
      return { errors }
    } finally {
      dispatch(setOedRecordFormUpdating(false))
    }
  }
}
