import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { selectEmIdentityRequests } from '../selectors'
import { fetchEmIdentityRequests } from '../actions'
import { EmIdentityRequestState } from '../types'

export const useEmIdentityRequests = (employeeId: string, inView: boolean): [EmIdentityRequestState[], boolean] => {
  const data = useSelector(selectEmIdentityRequests)(employeeId)
  const loading = useSelector((state: StoreState) => state.employee.emIdentityRequestsLoading[employeeId])

  useEffect(() => {
    if (inView && employeeId) dispatch(fetchEmIdentityRequests(employeeId))
  }, [employeeId, inView])

  return [data, loading]
}
