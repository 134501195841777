import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchTimeLogApprovalHistories } from '../actions'
import { selectTimeLogApprovalHistories } from '../selectors'
import { TimeLogApprovalHistoryState } from '../types'

export const useTimeLogApprovalHistories = (timeLogId?: string): [TimeLogApprovalHistoryState[], boolean] => {
  const timeLogApprovalHistoriess = useSelector(selectTimeLogApprovalHistories)(
    timeLogId || ''
  ) as TimeLogApprovalHistoryState[]
  const loading = useSelector((state: StoreState) => state.attendance.timeLogApprovalHistoriesLoading[timeLogId || ''])

  useEffect(() => {
    if (timeLogId) {
      dispatch(fetchTimeLogApprovalHistories(timeLogId))
    }
  }, [timeLogId])

  return [timeLogApprovalHistoriess, loading]
}
