import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { LoginPermissionState } from '../types'

export interface MyLoginAndPermissions {
  email?: string
  isOwner: boolean
  isSuperUser: boolean
  permissions: LoginPermissionState[]
}

const activeTenantCode = localStorage.getItem('tenant')

export const selectMyLoginAndPermissions = createSelector(
  (state: StoreState) => state.iam.logins,
  (state: StoreState) => state.iam.loginPermissions,
  (state: StoreState) => state.iam.myJwt,
  (loginsState, loginPermissionsState, myJwtState): MyLoginAndPermissions => {
    const loginId = myJwtState?.loginId
    if (!loginId) {
      return { email: undefined, isOwner: false, isSuperUser: false, permissions: [] }
    }

    const login = loginsState.entities[loginId]
    const loginPermissions = Object.values(loginPermissionsState[loginId]?.entities || {}) as LoginPermissionState[]
    return {
      email: login?.email,
      isOwner: myJwtState?.isOwner?.includes(activeTenantCode || '') || false,
      isSuperUser: myJwtState?.isSuperUser?.includes(activeTenantCode || '') || false,
      permissions: loginPermissions
    }
  }
)
