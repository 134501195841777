import { request, getBaseUrl } from '~/utils/request'
import { ProjectState, IProject, ProjectEmState, ProjectByEmployeeState } from '../types'
import { Pagination, Search } from '~/types/common'
import { EmProjectState, EmSelectionViewState, IEmProject } from '~/features/employee'

const baseUrl = getBaseUrl('/attendance/project')

export const apiGetProjects = () => request<ProjectState[]>('get', baseUrl)

export const apiGetProjectsByEmployee = (employeeId: string) =>
  request<ProjectByEmployeeState[]>('get', `${baseUrl}/${employeeId}/project`)

export const apiGetProject = (id: string) => request<ProjectState>('get', `${baseUrl}/${id}`)

export const apiSaveProject = (project: IProject) => request<{ id: string }>('post', baseUrl, project)

export const apiDeleteProject = (id: string) => request<void>('delete', `${baseUrl}/${id}`)

export const apiGetProjectEm = (projectId: string) =>
  request<ProjectEmState[]>('get', `${baseUrl}/${projectId}/employee`)

export const apiGetProjectEmByEmployee = (employeeId: string) =>
  request<EmProjectState[]>('get', `${baseUrl}/employee/${employeeId}`)

export const apiUpdateProjectEmByEmployee = (employeeId: string, data: IEmProject) =>
  request<EmProjectState[]>('post', `${baseUrl}/employee/${employeeId}`, data)

export const apiGetProjectEmSelections = (viewId: string, params: Pagination & Search) =>
  request<EmSelectionViewState>('get', `${baseUrl}/emselections/${viewId}`, params)
