import React, { ChangeEvent, CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { Col, InfoTooltip, Row, SysOptions } from '~/components'
import { Form, Space, Input } from '~/core-components'
import { Errors } from '~/types/store'

interface FormData {
  batchDescription: string
  reference: string
  referenceCustomText: string
}

interface BankFileMaybankApsJsonbProps {
  bankFileJsonb: string
  onChange: (key: string, value: string) => void
  canModify: boolean
  errors?: Errors
  dropdownStyle?: CSSProperties
}

const EMPTY_FORM_DATA: FormData = {
  batchDescription: '',
  reference: '',
  referenceCustomText: ''
}

export const BankFileMaybankApsJsonb: FC<BankFileMaybankApsJsonbProps> = ({
  bankFileJsonb,
  onChange,
  canModify,
  errors,
  dropdownStyle
}) => {
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)

  useEffect(() => {
    const data = JSON.parse(bankFileJsonb || '{}') as FormData
    setFormData({
      batchDescription: data.batchDescription,
      reference: data.reference,
      referenceCustomText: data.referenceCustomText
    })
  }, [bankFileJsonb])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      setFormData(formData => {
        const updated = { ...formData, ...updates }
        const value = JSON.stringify({
          batchDescription: updated.batchDescription,
          reference: updated.reference,
          referenceCustomText: updated.referenceCustomText
        })

        typeof onChange === 'function' && onChange('bankFileJsonb', value)
        return updated
      })
    },
    [onChange]
  )

  return (
    <>
      <Row>
        <Col span={24}>
          <Form.Item
            label="Batch description"
            validateStatus={errors?.batchDescription ? 'error' : ''}
            help={errors?.batchDescription}
          >
            <Space direction="horizontal" className="fill-first">
              <Input
                maxLength={140}
                value={formData.batchDescription}
                readOnly={!canModify}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleFormDataChange({ batchDescription: event.target.value })
                }
              />
              <InfoTooltip title="Description to identify the batch. It will be shown on company’s bank account statement." />
            </Space>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Reference" validateStatus={errors?.reference ? 'error' : ''} help={errors?.reference}>
            <Space direction="horizontal" className="fill-first">
              <SysOptions
                style={dropdownStyle}
                allowClear={false}
                showSearch={false}
                type="bank_file_reference"
                value={formData.reference}
                readOnly={!canModify}
                dropdownMatchSelectWidth={false}
                onChange={(value: string) => handleFormDataChange({ reference: value })}
              />
              <InfoTooltip
                title="Description to identify the transaction to the employee and will be shown on the employee’s bank account
              statement."
              />
            </Space>
          </Form.Item>
        </Col>
        <Col span={24} hidden={formData.reference !== 'custom_text'}>
          <Form.Item
            label=" "
            colon={false}
            validateStatus={errors?.referenceCustomText ? 'error' : ''}
            help={errors?.referenceCustomText}
          >
            <Input
              maxLength={35}
              value={formData.referenceCustomText}
              readOnly={!canModify}
              placeholder="Enter your own text"
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleFormDataChange({ referenceCustomText: event.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}
