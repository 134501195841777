import React, { FC, useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Card, ColumnsType, PageHeader, Space, Table } from '~/core-components'
import { DocumentTitle, InactiveTag } from '~/components'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { isInactive } from '~/utils/dateUtil'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { ShiftRoleState } from '../../types'
import { useShiftRoles } from '../../hooks'
import { AddShiftRoleDrawer } from '../ShiftRole/components/AddShiftRoleDrawer'
import './ShiftRoles.less'

type ShiftRoleTable = ShiftRoleState

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: '',
    breadcrumbName: 'Overview'
  }
]

interface DrawerState {
  visible: boolean
  data?: ShiftRoleState
}
const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

interface ShiftRoleProps {}

export const ShiftRoles: FC<ShiftRoleProps> = () => {
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const [shiftRoles, loading] = useShiftRoles()
  const canModify = usePermissionGate(Permission.shiftRole, PermissionAction.Modify)

  const handleAddShiftRole = useCallback(() => {
    setDrawerState({ visible: true, data: undefined })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const columns: ColumnsType<ShiftRoleTable> = [
    {
      title: 'Shift role',
      key: 'name',
      dataIndex: 'name',
      render: (value: string, record) => (
        <Space>
          <Link to={SETTINGS_ROUTES.shiftRole.replace(':id', record.id)}>{value}</Link>
          {isInactive(record.inactiveDate) && <InactiveTag />}
        </Space>
      )
    }
  ]

  return (
    <div id="shift-roles" className="shift-roles">
      <DocumentTitle title="Shift Roles" />
      <PageHeader
        title="Shift roles"
        containerId="shift-roles"
        breadcrumb={{ routes }}
        extra={
          canModify && (
            <Button key="add" onClick={handleAddShiftRole}>
              Add shift role
            </Button>
          )
        }
      />
      <div className="shift-roles__body">
        <Card table>
          <Table rowKey="id" dataSource={shiftRoles} pagination={false} columns={columns} loading={loading} />
        </Card>
        {canModify && <AddShiftRoleDrawer {...drawerState} onClose={handleCloseDrawer} />}
      </div>
    </div>
  )
}
