import React, { CSSProperties, FC, useCallback } from 'react'
import moment from 'moment-timezone'
import { Form, Input, Space } from '~/core-components'

interface HourlyCronProps {
  expr: string[]
  onChange: (value: string[]) => void
}

const numberStyles: CSSProperties = { width: 60 }

export const HourlyCron: FC<HourlyCronProps> = ({ expr, onChange }) => {
  const hour = expr[1].split('/')[1] ? parseInt(expr[1].split('/')[1]) : undefined
  const timeHHMM = `${expr[1]}:${expr[0]}`

  const handleHourChange = useCallback(
    (value: number | null) => {
      if ((typeof value === 'number' && value > 0 && value < 60) || value == null) {
        const newExpr = [...expr]
        newExpr[1] = value ? `0/${value}` : `${value}`
        onChange(newExpr)
      }
    },
    [onChange, expr]
  )

  const handleTimeChange = useCallback(
    (value: moment.Moment | null) => {
      const time = (value?.format('HH:mm') || '00:00').split(':')
      const newExpr = [...expr]
      newExpr[0] = `${parseInt(time[1])}`
      newExpr[1] = `0/1`
      onChange(newExpr)
    },
    [onChange, expr]
  )

  const getDisabledHours = useCallback(
    () => [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
    []
  )

  return (
    <Form.Item>
      <Space direction="vertical">
        <Space>
          Every
          <Input.Number min={1} max={23} value={hour} style={numberStyles} onChange={handleHourChange} />
          hour(s)
        </Space>
        <Space>
          Starts at
          <Input.Time
            value={moment(timeHHMM, 'HH:mm')}
            onChange={handleTimeChange}
            allowClear={false}
            showNow={false}
            disabledHours={getDisabledHours}
            minuteStep={5}
          />
        </Space>
      </Space>
    </Form.Item>
  )
}
