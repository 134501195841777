import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { EmSelectState } from '~/components'
import { StoreState } from '~/types/store'

export const selectPayRecordEmployees = createSelector(
  (state: StoreState) => state.payroll.payRecordEmployees,
  payRecordEmployeesState =>
    memoize((payRunId: string): EmSelectState[] => {
      return Object.values(payRecordEmployeesState[payRunId]?.entities || {}) as EmSelectState[]
    })
)
