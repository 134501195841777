import React, { FC, useCallback, useState } from 'react'
import { Button } from '~/core-components'
import { dispatch } from '~/stores/store'
import { refetchAttendancePeriodsView } from '../../../reducers'
import { AddAttendancePeriodDrawer } from './AddAttendancePeriodDrawer'

interface AddAttendancePeriodButtonProps {}

interface DrawerState {
  visible: boolean
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

export const AddAttendancePeriodButton: FC<AddAttendancePeriodButtonProps> = () => {
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)

  const handleAddClick = useCallback(() => {
    setDrawerState({ visible: true })
  }, [])

  const handleCloseDrawer = useCallback((action: 'saved' | 'cancelled') => {
    setDrawerState(DEFAULT_DRAWER_STATE)

    if (action === 'saved') {
      dispatch(refetchAttendancePeriodsView())
    }
  }, [])

  return (
    <>
      <Button onClick={handleAddClick}>Add attendance period</Button>
      <AddAttendancePeriodDrawer {...drawerState} onClose={handleCloseDrawer} />
    </>
  )
}
