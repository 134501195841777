import React, { CSSProperties, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import pick from 'lodash/pick'
import { Audit } from '~/components'
import { Card, ColumnsType, Link, SecondaryText, Table } from '~/core-components'
import { fetchKeyvalues, fetchPayItems } from '~/features/master'
import { useFormulas } from '~/features/formula'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { formatMoney } from '~/utils'
import { useToggle } from '~/hooks/use-toggle'
import { useFirstInView } from '~/hooks/use-first-in-view'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { MutatePayRecurringDrawer } from './MutatePayRecurringDrawer'
import { selectPayRecurrings } from '../../selectors'
import { PayRecurringState, PayRecurringTable } from '../../types'
import { fetchPayRecurrings } from '../../actions'

export interface PayRecurringsProps {
  employeeId?: string
}

interface DrawerState {
  visible: boolean
  employeeId?: string
  data?: PayRecurringState
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }
const TODAY = moment().format('YYYY-MM-DD')

const secondaryTextStyles: CSSProperties = { display: 'block', fontSize: 12 }
const cardStyle = { margin: 24 }
const cardBodyStyle = { padding: 0 }

export const PayRecurrings: FC<PayRecurringsProps> = ({ employeeId }) => {
  const { ref, inView } = useFirstInView<HTMLDivElement>({ threshold: 0.25 })
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const [showExpired, toggleShowExpired] = useToggle()
  const payRecurrings = useSelector(selectPayRecurrings)(employeeId)
  const loading = useSelector((state: StoreState) => state.payroll.payRecurringsLoading)
  const [formulas] = useFormulas()
  const canView = usePermissionGate(Permission.employeePayRecurring)
  const canModify = usePermissionGate(Permission.employeePayRecurring, PermissionAction.Modify)

  useEffect(() => {
    if (inView) {
      dispatch(fetchKeyvalues('payItem', 'payroll-sg', undefined, { strategy: 'when-empty' }))
      dispatch(fetchPayItems('sg', { strategy: 'when-empty' }))
    }
  }, [inView])

  useEffect(() => {
    if (inView) {
      if (employeeId) {
        dispatch(fetchPayRecurrings(employeeId))
      }
    }
  }, [inView, employeeId])

  const handleAddPayRecurring = useCallback(() => {
    setDrawerState({ visible: true, employeeId, data: undefined })
  }, [employeeId, setDrawerState])

  const handleEditPayRecurring = useCallback(
    (payRecurring: PayRecurringTable) => {
      setDrawerState({ visible: true, employeeId, data: payRecurring })
    },
    [employeeId, setDrawerState]
  )

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const dataSource = useMemo(() => {
    return payRecurrings.filter(rec => (showExpired ? true : (rec?.endDate || '9999-12-31') >= TODAY))
  }, [payRecurrings, showExpired])

  const hasExpired = useMemo(() => {
    return payRecurrings.filter(rec => (rec?.endDate || '9999-12-01') < TODAY).length > 0
  }, [payRecurrings])

  const isAllExpired = useMemo(() => {
    return payRecurrings.filter(rec => (rec?.endDate || '9999-12-31') < TODAY).length === payRecurrings.length
  }, [payRecurrings])

  if (isAllExpired && !showExpired) {
    toggleShowExpired()
  }

  const columns: ColumnsType<PayRecurringTable> = [
    {
      title: 'Payroll item',
      key: 'payItemId',
      dataIndex: 'payItemId',
      render: (value: string, record) => {
        let rateDisplay = ''
        if (record.formulaId) {
          rateDisplay = formulas[record.formulaId]?.name || ''
        }

        return (
          <div>
            {record.payItemName || value}
            <SecondaryText style={secondaryTextStyles}>{rateDisplay}</SecondaryText>
          </div>
        )
      }
    },
    {
      title: 'Quantity',
      key: 'quantity',
      dataIndex: 'quantity',
      align: 'right',
      render: (value: number) => {
        return formatMoney(value, 4)
      }
    },
    {
      title: 'Rate',
      key: 'rate',
      dataIndex: 'rate',
      align: 'right',
      render: (value: number) => {
        return formatMoney(value, 4)
      }
    },
    {
      title: 'First payment',
      key: 'startDate',
      dataIndex: 'startDate',
      render: (value: string) => {
        return moment(value).format('DD MMM YYYY')
      }
    },
    {
      title: 'Last payment',
      key: 'endDate',
      dataIndex: 'endDate',
      render: (value: string) => {
        if (!value) return 'End of employment'
        return moment(value).format('DD MMM YYYY')
      }
    },
    {
      key: 'action',
      align: 'right',
      render: (value: string, record) =>
        canModify && (
          <Link size="small" onClick={() => handleEditPayRecurring(record)}>
            edit
          </Link>
        )
    }
  ]

  if (!canView) return null

  return (
    <div ref={ref}>
      <Card
        title="Recurring payroll items"
        extra={employeeId && canModify && <Link onClick={handleAddPayRecurring}>add</Link>}
        style={cardStyle}
        bodyStyle={cardBodyStyle}
        loading={loading}
      >
        <Table
          rowKey="id"
          dataSource={dataSource}
          pagination={false}
          columns={columns}
          loading={loading}
          {...(hasExpired && !isAllExpired ? { showMore: showExpired, onShowMoreToggle: toggleShowExpired } : {})}
          expandable={{
            expandedRowRender: (record: PayRecurringTable) => (
              <>
                <p style={{ margin: 0 }}>{record.notes}</p>
                <Audit {...pick(record, 'createdBy', 'createdDate', 'updatedBy', 'updatedDate')} />
              </>
            )
          }}
        />
        {canModify && <MutatePayRecurringDrawer {...drawerState} onClose={handleCloseDrawer} />}
      </Card>
    </div>
  )
}
