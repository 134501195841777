import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { LeaveBuddyState } from '../types'

export const selectLeaveBuddies = createSelector(
  (state: StoreState) => state.leave.leaveBuddies,
  (leaveBuddiesState): LeaveBuddyState[] => {
    const leaveBuddies = Object.values(leaveBuddiesState?.entities || {}) as LeaveBuddyState[]
    return leaveBuddies.sort((a, b) => a.name?.localeCompare(b.name))
  }
)
