import React, { forwardRef } from 'react'
import { default as AntButton, ButtonProps as AntButtonProps } from 'antd/lib/button'
import 'antd/es/button/style'
import './Button.less'

export interface ButtonProps extends AntButtonProps {}

const ButtonInternal = (props: ButtonProps, ref: React.Ref<any>) => <AntButton {...props} ref={ref} />

export const Button = forwardRef(ButtonInternal)
