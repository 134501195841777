import { SETTINGS_ROUTES } from '~/routes/routes'
import { CalendarViewerCategory } from './types'
import { Screen } from '../selection'
import { Permission } from '~/constants'

interface Config {
  documentTitle: string
  pageTitle: string
  routePath: string
  screen: Screen
  permission: string
}

export const config: { [category in CalendarViewerCategory]: Config } = {
  lve_record: {
    documentTitle: 'Leave Calendar Viewer',
    pageTitle: 'Leave calendar viewer',
    routePath: SETTINGS_ROUTES.lveRecordCalendarViewer,
    screen: 'lve_record_calendar_viewer',
    permission: Permission.lveRecordCalendarViewer
  }
}
