import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { EmIdentityState, IEmIdentity } from '../types'

const baseUrl = getBaseUrl('/employee/employee')

export const apiGetEmIdentities = (employeeId: string) =>
  request<EmIdentityState[]>('get', `${baseUrl}/${employeeId}/identity`)

export const apiGetEmIdentity = (employeeId: string, emIdentityId: string) =>
  request<EmIdentityState>('get', `${baseUrl}/${employeeId}/identity/${emIdentityId}`)

export const apiAddEmIdentity = (employeeId: string, emIdentity: IEmIdentity) =>
  request<{ id: string; emIdentityId?: string }>('post', `${baseUrl}/${employeeId}/identity`, emIdentity)

export const apiUpdateEmIdentity = (employeeId: string, id: string, emIdentity: Operation[]) =>
  request<{ emIdentityId?: string }>('patch', `${baseUrl}/${employeeId}/identity/${id}`, emIdentity)

export const apiDeleteEmIdentity = (employeeId: string, id: string) =>
  request<{ emIdentityId?: string }>('delete', `${baseUrl}/${employeeId}/identity/${id}`)
