import React, { FC, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ChartSummary, EChartsOption } from '~/charts'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchAttritionRate } from '../../../actions'

interface AttritionRateProps {}

export const AttritionRate: FC<AttritionRateProps> = () => {
  const data = useSelector((state: StoreState) => state.employee.chartSummaries['attrition_rate'])
  const loading = useSelector((state: StoreState) => state.employee.chartSummariesLoading['attrition_rate'])

  const option = useMemo(() => {
    return {
      tooltip: {
        show: false
      },
      grid: {
        left: 5
      },
      xAxis: {
        type: 'value',
        show: false,
        max: 100
      },
      yAxis: {
        type: 'category',
        show: false,
        data: ['Progress']
      },
      series: [
        {
          data: [data?.number],
          type: 'bar',
          showBackground: true,
          backgroundStyle: {
            color: 'rgba(180, 180, 180, 0.2)'
          },
          itemStyle: {
            color: '#1db7b5'
          }
        }
      ]
    } as EChartsOption
  }, [data])

  useEffect(() => {
    dispatch(fetchAttritionRate())
  }, [])

  return (
    <ChartSummary
      title="Attrition rate"
      number={`${data?.number || 0}%`}
      option={option}
      footer={
        data?.change != null ? (
          <>
            YoY change {data.change.toFixed(2)}%{' '}
            {data.change !== 0 && <i className={`fal ${data.change > 0 ? 'fa-caret-up' : 'fa-caret-down'}`} />}
          </>
        ) : (
          <>YoY change n/a</>
        )
      }
      loading={loading}
    />
  )
}
