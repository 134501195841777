import { useEffect } from 'react'
import { useSelector } from 'react-redux'
// import { fetchSysJobs, SysJobState, selectSysJobByCode } from '~/features/master'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchSysJobs } from '../actions/fetch-sys-jobs'
import { selectSysJobByCode } from '../reducers'
import { SysJobState } from '../types'

export const useSysJob = (sysJobCode: string): [SysJobState | undefined, boolean] => {
  const sysJob = useSelector((state: StoreState) => selectSysJobByCode(state, sysJobCode))
  const loading = useSelector((state: StoreState) => state.job.sysJobsLoading)

  useEffect(() => {
    dispatch(fetchSysJobs({ strategy: 'when-empty' }))
  }, [])

  return [sysJob, loading]
}
