import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { SSEntitledLeaveType } from '../../types'

export const selectMyEntitledLeaveTypes = createSelector(
  (state: StoreState) => state.my.myLeaveBalances,
  (state: StoreState) => state.leave.leaveTypes,
  (myLeaveBalancesState, leaveTypesState) =>
    memoize((includeLeaveTypeId?: string) =>
      Object.values(myLeaveBalancesState?.entities || {})
        .filter(e => !!e?.ssApply || e?.leaveTypeId === includeLeaveTypeId)
        .map(e => {
          const leaveType = leaveTypesState.entities[e?.leaveTypeId || '']

          return {
            id: leaveType?.id,
            name: leaveType?.name,
            entPerTime: e?.entPerTime
          } as SSEntitledLeaveType
        })
        .sort((a, b) => {
          return a.name?.localeCompare(b.name)
        })
        // to hide multiple grant leave types
        .filter((e, idx, self) => idx === self.findIndex(s => s.id === e.id))
    )
)
