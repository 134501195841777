import { replaceShiftRoleTes, setShiftRoleTesLoading } from '../reducers'
import { apiGetShiftRoleTes } from '../api/shift-role-te.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchShiftRoleTes = (
  shiftRoleId: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!shiftRoleId) return

    const loading = getState().attendance.shiftRoleTesLoading[shiftRoleId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().attendance.shiftRoleTes[shiftRoleId]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setShiftRoleTesLoading({ shiftRoleId, loading: true }))
      const { result, status } = await apiGetShiftRoleTes(shiftRoleId)
      if (status) {
        dispatch(replaceShiftRoleTes({ shiftRoleId, data: result }))
      }
    } finally {
      dispatch(setShiftRoleTesLoading({ shiftRoleId, loading: false }))
    }
  }
}
