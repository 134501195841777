import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { EmployerTaxState, Ir21ResigneeState } from '../types'

export const selectIr21ResigneeCompanies = createSelector(
  (state: StoreState) => state.tax.ir21Resignees,
  ir21ResigneesState => {
    const data = Object.values(ir21ResigneesState?.entities || {}) as Ir21ResigneeState[]
    return data.reduce<EmployerTaxState[]>((acc, r) => {
      if (!acc.map(c => c.taxNo).includes(r.employerTaxNo)) {
        acc.push({ taxNo: r.employerTaxNo, name: r.companyName, count: 1 })
      } else {
        const employer = acc.find(c => c.taxNo === r.employerTaxNo)
        if (employer) employer.count += 1
      }
      return acc
    }, [])
  }
)
