import { createSelector } from '@reduxjs/toolkit'
import moment from 'moment-timezone'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'

export const selectCurrentShiftRoleTe = createSelector(
  (state: StoreState) => state.attendance.shiftRoleTes,
  shiftRoleTesState =>
    memoize((shiftRoleId?: string) => {
      if (!shiftRoleId) return undefined

      return Object.values(shiftRoleTesState[shiftRoleId]?.entities || {})
        .sort((a, b) => {
          const aStartDate = new Date(a!.startDate).getTime()
          const bStartDate = new Date(b!.startDate).getTime()
          return bStartDate - aStartDate
        })
        .find(a => moment(a?.startDate).isSameOrBefore(moment()))
    })
)

export const selectShiftRoleTes = createSelector(
  (state: StoreState) => state.attendance.shiftRoleTes,
  shiftRoleTesState =>
    memoize((shiftRoleId?: string) => {
      if (!shiftRoleId) return []

      return Object.values(shiftRoleTesState[shiftRoleId]?.entities || {}).sort((a, b) => {
        const aStartDate = new Date(a!.startDate).getTime()
        const bStartDate = new Date(b!.startDate).getTime()
        return bStartDate - aStartDate
      })
    })
)
