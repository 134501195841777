import React, { FC, useState, useCallback, useEffect, CSSProperties } from 'react'
import { useSelector } from 'react-redux'
import { ColumnsType, Form, Radio, RadioChangeEvent, SecondaryText, Table, TableRowSelection } from '~/core-components'
import { Col, DrawerForm, InactiveTag, Row } from '~/components'
import { OfficeInfoForm, EMPTY_OFFICE_INFO_FORM_DATA } from './OfficeInfoForm'
import { isInactive } from '~/utils/dateUtil'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors } from '~/types/store'
import { addCompanyOffice, addCompanyNewOffice } from '../../../actions'
import { ICompanyOfficeInfo, OfficeState } from '../../../types'
import { selectOfficesOverview } from '../../../selectors'

interface AddOfficeDrawerProps {
  visible: boolean
  data?: {
    companyId: string
    isMain: boolean
  }
  onClose: () => void
}

type OfficeTable = OfficeState & {
  companies: { name: string; isMain: boolean }[]
}

const labelRow1Style: CSSProperties = { lineHeight: 1.1 }
const labelRow2Style: CSSProperties = { fontSize: 12 }
const tableStyle: CSSProperties = { border: '1px solid #ededed', marginBottom: 15 }
const rowRadioStyle: CSSProperties = { marginBottom: 30 }

export const AddOfficeDrawer: FC<AddOfficeDrawerProps> = ({ visible, data, onClose }: AddOfficeDrawerProps) => {
  const [loading, setLoading] = useState(false)
  const [formType, setFormType] = useState<'existing' | 'new'>('existing')
  const offices = useSelector(selectOfficesOverview)(data?.companyId)
  const [formData, setFormData] = useState<ICompanyOfficeInfo>({
    ...EMPTY_OFFICE_INFO_FORM_DATA,
    isMain: data?.isMain || offices.length === 0 ? true : false
  })
  const [existingOfficeId, setExistingOfficeId] = useState<string>()
  const [errors, setErrors] = useState<Errors>()

  useEffect(() => {
    setFormType(offices.length > 0 ? 'existing' : 'new')
  }, [offices.length])

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    if (data?.companyId) {
      setLoading(true)
      try {
        if (formType === 'new') {
          result = await dispatch(addCompanyNewOffice(data?.companyId, formData))
        } else if (existingOfficeId) {
          result = await dispatch(addCompanyOffice(data?.companyId, existingOfficeId))
        }
      } finally {
        setLoading(false)
      }
      if (result?.errors) {
        setErrors(result.errors)
      }
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_OFFICE_INFO_FORM_DATA)
    }
  }, [formType, formData, existingOfficeId, data, onClose])

  const handleRadioChanged = useCallback((event: RadioChangeEvent) => setFormType(event.target.value), [])

  const columns: ColumnsType<OfficeTable> = [
    {
      title: 'Office',
      key: 'name',
      dataIndex: 'name',
      render: (value: string, record) => (
        <>
          <div style={labelRow1Style}>
            {value} {isInactive(record.inactiveDate) && <InactiveTag />}
          </div>
          <SecondaryText style={labelRow2Style}>
            {record.addressLine1}, {record.addressLine2}
          </SecondaryText>
        </>
      )
    },
    {
      title: 'Company',
      key: 'companies',
      dataIndex: 'companies',
      render: (value: string, record) => (
        <>
          {record.companies.map((c, index) => (
            <div key={index}>{c.name}</div>
          ))}
        </>
      )
    }
  ]

  const rowSelection: TableRowSelection<OfficeTable> = {
    type: 'radio',
    onChange: selectedRowKeys => {
      if (selectedRowKeys.length > 0) setExistingOfficeId(selectedRowKeys[0] as string)
    }
  }

  return (
    <DrawerForm
      open={visible}
      title="Add office"
      onClose={onClose}
      confirmLoading={loading}
      width={650}
      formId="form-office"
    >
      {offices.length > 0 && (
        <Row justify="center" style={rowRadioStyle}>
          <Col>
            <Radio.Group value={formType} buttonStyle="solid" onChange={handleRadioChanged}>
              <Radio.Button value="existing">Choose existing</Radio.Button>
              <Radio.Button value="new">Add new office</Radio.Button>
            </Radio.Group>
          </Col>
        </Row>
      )}

      <Form id="form-office" onFinish={handleOk}>
        {formType === 'existing' ? (
          <Table
            rowSelection={rowSelection}
            rowKey="id"
            dataSource={offices}
            pagination={false}
            columns={columns}
            style={tableStyle}
          />
        ) : (
          <OfficeInfoForm usage="companyoffice" data={formData} errors={errors} onChange={data => setFormData(data)} />
        )}
      </Form>
    </DrawerForm>
  )
}
