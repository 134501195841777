import { setLeaveRecordsView, setLeaveRecordsViewLoading } from '../reducers'
import { apiGetLeaveRecordsView } from '../api/leave-record.api'
import { ThunkResult } from '~/types/store'
import { DateRange, DEFAULT_FETCH_OPTIONS, FetchOptions, Pagination } from '~/types/common'

export const fetchLeaveRecordsView = (
  viewId: string,
  pagination: Pagination,
  search: string,
  startDate: string | null,
  endDate: string | null,
  employeeId: string | null,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!viewId) return

    const loading = getState().leave.leaveRecordsViewLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().leave.leaveRecordsView?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setLeaveRecordsViewLoading(true))
      const { result, status } = await apiGetLeaveRecordsView(viewId, {
        ...pagination,
        search,
        ...({ startDate, endDate } as DateRange),
        employeeId
      })
      if (status) {
        dispatch(setLeaveRecordsView(result))
      }
    } finally {
      dispatch(setLeaveRecordsViewLoading(false))
    }
  }
}
