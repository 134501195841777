import { EntityState } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { PayRecurringState, PayRecurringOverrideState } from './payrecurring.state'
import { PayRecordSgState, PayTranSgState, PayTranDtlSgState, PayAccountState } from './payrecord.state'
import { PayItemMappingState, PayTranEntryState } from './paytran-entry.state'
import { PayTranImportState } from './paytran-import.state'
import { PayRunState, PayRunSummaryState, PayRunViewState, PayRunXeroState } from './payrun.state'
import { PayrollState, PaySnapshotState, PayWarningState } from './payroll.state'
import { EmPayrollState } from './em-payroll.state'
import { PayslipState } from './payslip.state'
import { PayImportState, PayImportValidateState } from './payimport.state'
import { LeaveImportState, LeaveImportValidateState } from './leave-import.state'
import { EncashImportState, EncashImportValidateState } from './encash-import.state'
import { ClaimImportState, ClaimImportValidateState } from './claim-import.state'
import { DailyImportState, DailyImportValidateState } from './daily-import.state'
import { PayRunPayslipState } from './payrun-payslip.state'
import { IdValue } from '~/types/common'
import { EmSelectState } from '~/components'
import {
  XeroAccountState,
  XeroConfigState,
  XeroConnectionState,
  XeroContactState,
  XeroLineItemMapState,
  XeroTenantMapState,
  XeroTenantState
} from './xero.state'
import { PayCfConfigState } from './pay-cf-config.state'

export const payrollPersistConfig = {
  key: 'payroll',
  whitelist: [
    'xeroState',
    'xeroStateTimestamp',
    'payRecurrings',
    'payRecurringOverrides',
    'payRuns',
    'payRunSummaries',
    'payRunsView',
    'payRecords',
    'payRecordPayRuns',
    'payWarnings',
    'paySnapshot',
    'payTrans',
    'payTranDtls',
    'payTranEntries',
    'payTranImports',
    'payAccounts',
    'payItemMappings',
    'payroll',
    'emPayroll',
    'payslip',
    'isPayslipOutdated',
    'isAnyPayslipsOutdated',
    'refetchPaySummary',
    'refetchPayRecords',
    'refetchPayslips',
    'payRunPayslip',
    'xeroRedirectUrl'
  ],
  storage
}

export interface PayrollRootState {
  payRecurrings: EntityState<PayRecurringState>
  payRecurringsLoading: boolean
  payRecurringOverrides: EntityState<PayRecurringOverrideState>
  payRecurringOverridesLoading: boolean
  payRunExpanded: boolean
  payRuns: EntityState<PayRunState>
  payRunsLoading: boolean
  payRunSummaries: EntityState<PayRunSummaryState>
  payRunSummariesLoading: boolean
  payRunsView: EntityState<PayRunViewState>
  payRunsViewLoading: boolean
  payRunsViewRefetch: number
  payRecords: { [payRunId: string]: EntityState<PayRecordSgState> }
  payRecordsLoading: { [payRunId: string]: boolean }
  payRecordPayRuns: { [employeeId: string]: EntityState<IdValue> }
  payRecordPayRunsLoading: { [employeeId: string]: boolean }
  payRecordEmployees: { [payRunId: string]: EntityState<EmSelectState> }
  payRecordEmployeesLoading: { [payRunId: string]: boolean }
  payWarnings: { [payRunId: string]: EntityState<PayWarningState> }
  payWarningLoading: { [payRunId: string]: boolean }
  paySnapshot: { [payRunId: string]: PaySnapshotState }
  paySnapshotLoading: { [payRunId: string]: boolean }
  payTrans: { [recordId: string]: EntityState<PayTranSgState> }
  payTransLoading: { [recordId: string]: boolean }
  payTranDtls: { [tranId: string]: EntityState<PayTranDtlSgState> }
  payTranDtlsLoading: { [tranId: string]: boolean }
  payTranEntries: { [batchId: string]: EntityState<PayTranEntryState> }
  payTranEntriesLoading: { [batchId: string]: boolean }
  payTranImports: { [emBatchId: string]: EntityState<PayTranImportState> }
  payTranImportsLoading: { [emBatchId: string]: boolean }
  payAccounts: { [recordId: string]: EntityState<PayAccountState> }
  payAccountsLoading: { [recordId: string]: boolean }
  payItemMappings: EntityState<PayItemMappingState>
  payItemMappingsLoading: boolean
  payroll: EntityState<PayrollState>
  payrollLoading: boolean
  emPayroll: EntityState<EmPayrollState>
  emPayrollLoading: boolean
  payslip: EntityState<PayslipState>
  payslipLoading: boolean
  payslipGenerating: boolean
  isPayslipOutdated: { [employeeId: string]: boolean }
  isAnyPayslipsOutdated: { [payRunId: string]: boolean }
  payImport?: PayImportState
  payImportValidate?: PayImportValidateState
  leaveImport?: LeaveImportState
  leaveImportValidate?: LeaveImportValidateState
  encashImport?: EncashImportState
  encashImportValidate?: EncashImportValidateState
  claimImport?: ClaimImportState
  claimImportValidate?: ClaimImportValidateState
  dailyImport?: DailyImportState
  dailyImportValidate?: DailyImportValidateState
  payRunSummariesRefetch: number
  payRecordsRefetch: number
  payslipEmployeeRefetch: number
  payRunPayslip: { [payRunId: string]: PayRunPayslipState }
  payRunPayslipLoading: { [payRunId: string]: boolean }
  payRunXero: EntityState<PayRunXeroState>
  payRunXeroLoading: boolean
  payRunXeroRefecth: number
  payCfConfigs: EntityState<PayCfConfigState>
  payCfConfigsLoading: boolean

  // Xero integration
  xeroState: string
  xeroStateTimestamp: number
  xeroRedirectUrl: string
  xeroConnections: EntityState<XeroConnectionState>
  xeroConnectionsLoading: boolean
  xeroTenants: EntityState<XeroTenantState>
  xeroTenantsLoading: boolean
  xeroTenantMaps: EntityState<XeroTenantMapState>
  xeroTenantMapsLoading: boolean
  xeroConfig: EntityState<XeroConfigState>
  xeroConfigLoading: boolean
  xeroLineItemMaps: { [companyId: string]: EntityState<XeroLineItemMapState> }
  xeroLineItemMapsLoading: { [companyId: string]: boolean }
  xeroAccounts: { [tenantId: string]: EntityState<XeroAccountState> }
  xeroAccountsLoading: { [tenantId: string]: boolean }
  xeroContacts: { [tenantId: string]: EntityState<XeroContactState> }
  xeroContactsLoading: { [tenantId: string]: boolean }
}
