import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { SSClaimRecordAttachmentState } from '../types'

export const selectMyClaimRecordAttachments = createSelector(
  (state: StoreState) => state.myClaim.myClaimRecordAttachments,
  myClaimRecordAttachmentsState =>
    memoize((claimRecordId: string) => {
      return Object.values(
        myClaimRecordAttachmentsState[claimRecordId]?.entities || {}
      ) as SSClaimRecordAttachmentState[]
    })
)
