import { createSelector } from '@reduxjs/toolkit'
import moment from 'moment-timezone'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { HolidayDateState } from '../types'

export const selectHolidayDates = createSelector(
  (state: StoreState) => state.master.holidayDates,
  holidayDateState =>
    memoize(
      (holidayId: string, year: number): (HolidayDateState | undefined)[] => {
        const holidays = Object.values(holidayDateState[holidayId]?.entities || {}) as (HolidayDateState | undefined)[]
        return holidays.filter(hol => moment(hol?.date).year() === year).sort((a, b) => a!.date.localeCompare(b!.date))
      },
      (holidayId: string, year: number) => `${holidayId}|${year}`
    )
)
