import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { ErrorDisplay } from '~/components'
import { Alert, Card, ColumnsType, Table } from '~/core-components'
import { useSysOptions } from '~/features/employee'
import { useLeaveTypesDict } from '~/features/leave/hooks'
import { Errors, StoreState } from '~/types/store'
import { formatDateRange } from '~/utils'
import { PendingLeaveRecordState } from '../../../types'
import { LeaveTypeName } from '../../LeaveEntitlement/components/LeaveTypeName'
import { LeaveClosingFormData } from './LeaveClosingDrawer'
import './PendingLeaveAction.less'

interface PendingLeaveActionProps {
  visible: boolean
  data: Partial<LeaveClosingFormData>
  errors?: Errors
  onChange: (data: Partial<LeaveClosingFormData>) => void
}

export const PendingLeaveAction: FC<PendingLeaveActionProps> = ({ visible, data, errors, onChange }) => {
  const pending = useSelector((state: StoreState) => state.leave.pendingLeaveResult?.pending || [])
  const [leaveUnits] = useSysOptions('lve_unit')
  const [leaveTypes] = useLeaveTypesDict()

  const columns: ColumnsType<PendingLeaveRecordState> = [
    {
      title: 'Employee',
      key: 'employeeNo',
      dataIndex: 'employeeNo',
      width: 180,
      render: (value: string, record) => `[${value}] ${record.employeeName}`
    },
    {
      title: 'Leave type',
      key: 'leaveTypeId',
      dataIndex: 'leaveTypeId',
      width: 180,
      render: (value: string) => <LeaveTypeName id={value} />
    },
    {
      title: 'Date',
      key: 'startDate',
      dataIndex: 'startDate',
      width: 200,
      render: (value: string, record: PendingLeaveRecordState) => formatDateRange(record.startDate, record.endDate)
    },
    {
      title: 'Pending',
      key: 'pendingTotal',
      dataIndex: 'pendingTotal',
      width: 100,
      render: (value: string, record: PendingLeaveRecordState) =>
        `${value} ${leaveUnits[leaveTypes[record.leaveTypeId]?.unit || '']?.value?.toLowerCase() || ''}`
    },
    {
      title: 'Notes',
      key: 'notes',
      dataIndex: 'notes'
    }
  ]

  return (
    <div className="pending-leave-action" hidden={!visible}>
      <ErrorDisplay errors={errors} />
      {pending.length === 0 ? (
        <Alert message="No pending leave" type="info" />
      ) : (
        <>
          <Alert
            message="Unable to proceed with leave closing as there are leave records pending for approval"
            type="error"
            style={{ marginBottom: 15 }}
          />
          <Card table>
            <Table rowKey="id" dataSource={pending} columns={columns} pagination={false} />
          </Card>
        </>
      )}
    </div>
  )
}
