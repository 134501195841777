import { setMyClaimRecord, setMyClaimRecordsLoading } from '../reducers'
import { apiGetMyClaimRecord } from '../api/ss-claim-record.api'
import { ThunkResult } from '~/types/store'
import { emptyGuid } from '~/constants'

export const fetchMyClaimRecord = (id: string): ThunkResult<void> => {
  return async dispatch => {
    if (!id || id === emptyGuid) {
      return
    }

    try {
      dispatch(setMyClaimRecordsLoading(true))
      const { result, status } = await apiGetMyClaimRecord(id)
      if (status) dispatch(setMyClaimRecord(result))
    } finally {
      dispatch(setMyClaimRecordsLoading(false))
    }
  }
}
