import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import {
  Button,
  Card,
  ColumnsType,
  Form,
  Link,
  SecondaryText,
  Space,
  Table,
  TableRowSelection
} from '~/core-components'
import { AmountDisplay, Person, SearchInput, YearKeyValues } from '~/components'
import { Screen, ViewCriteriaSimple, ViewCriteria, updateViewCriteria, useFirstView } from '~/features/selection'
import { usePermissionGate } from '~/features/iam'
import { ClaCurrencyCode, ClaExpenseSubmissionType, Permission, PermissionAction } from '~/constants'
import { EMP_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { selectClaimPendingApprovalsView } from '../../selectors'
import { getBaseUrl, showError } from '~/utils'
import { ClaimPendingApprovalRowState } from '../../types'
import { refetchClaimPendingApprovalsView } from '../../reducers'
import { ClaimStatusTag } from '../ClaimStatusTag/ClaimStatusTag'
import { approveClaimPendingApproval, fetchClaimPendingApprovalsView, rejectClaimPendingApproval } from '../../actions'
import { ClaimPendingChangeApproverDrawer } from './components/ClaimPendingChangeApproverDrawer'
import { useClaimTypesDict } from '../../hooks'
import { ClaimTypeName } from '../ClaimEntitlements/components/ClaimTypeName'
import { MutateClaimRecordDrawer } from '../ClaimRecords/components/MutateClaimRecordDrawer'
import './ClaimPendingApprovals.less'

interface ClaimPendingApprovalsProps {}

interface DrawerState {
  visible: boolean
  employeeId?: string
  id?: string
  editing?: boolean
}

interface ChangeApproverDrawerState {
  claimRecordIds: string[]
  visible: boolean
}

const SCREEN_CODE: Screen = 'claim_pending_approval'
const DEFAULT_DRAWER_STATE: DrawerState = { visible: false, editing: false }
const DEFAULT_CHANGE_APPROVER_DRAWER_STATE: ChangeApproverDrawerState = { claimRecordIds: [], visible: false }

const TODAY_YEAR = moment().year()

const baseUrl = getBaseUrl('/filestore')

export const ClaimPendingApprovals: FC<ClaimPendingApprovalsProps> = () => {
  const [selected, setSelected] = useState<ClaimPendingApprovalRowState[]>([])
  const [periodYear, setPeriodYear] = useState<number>(TODAY_YEAR)
  const [search, setSearch] = useState<string>('')
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const [changeApproverDrawerState, setChangeApproverDrawerState] = useState<ChangeApproverDrawerState>(
    DEFAULT_CHANGE_APPROVER_DRAWER_STATE
  )
  const [view, viewLoading] = useFirstView(SCREEN_CODE)
  const viewId = view?.id || ''
  const dataLoading = useSelector((state: StoreState) => state.claim.claimPendingApprovalsViewLoading)
  const data = useSelector(selectClaimPendingApprovalsView)(viewId)
  const canModify = usePermissionGate(Permission.claRecord, PermissionAction.Modify)
  const canViewEmployee = usePermissionGate(Permission.employee)
  const refetch = useSelector((state: StoreState) => state.claim.claimPendingApprovalsViewRefetch)
  const [approving, setApproving] = useState(false)
  const [rejecting, setRejecting] = useState(false)
  const [claimTypes] = useClaimTypesDict()

  useEffect(() => {
    if (viewId) dispatch(fetchClaimPendingApprovalsView(viewId, periodYear, search))
  }, [viewId, periodYear, search, refetch])

  const handleOpenClaimRecord = useCallback(
    (row: ClaimPendingApprovalRowState) => {
      setDrawerState({ visible: true, editing: false, id: row.id, employeeId: row.employeeId })
    },
    [setDrawerState]
  )

  let columns: ColumnsType<ClaimPendingApprovalRowState> = [
    {
      title: 'Name',
      key: 'employeeId',
      dataIndex: 'employeeId',
      width: 250,
      render: (value: string, record) => (
        <Person
          name={record.employeeName}
          photo={record.photoId && `${baseUrl}/file/${record.photoId}/thumbnailphoto/18`}
          path={canViewEmployee ? EMP_ROUTES.employee.replace(':id', value) : undefined}
          size={18}
        />
      )
    },
    {
      title: 'Claim #',
      key: 'claimNo',
      dataIndex: 'claimNo',
      width: 120,
      render: (value: string, record) => <Link onClick={() => handleOpenClaimRecord(record)}>{`#${value}`}</Link>
    },
    {
      title: 'Claim type',
      key: 'claimTypeId',
      dataIndex: 'claimTypeId',
      width: 180,
      render: (value: string) => <ClaimTypeName id={value} hideNewTab />
    },
    {
      title: 'Expense date',
      key: 'expenseDate',
      dataIndex: 'expenseDate',
      width: 130,
      render: (value: string) => (value ? moment(value).format('DD MMM YYYY') : '')
    },
    {
      title: 'Amount',
      key: 'claimAmount',
      dataIndex: 'claimAmount',
      width: 150,
      render: (value: number, record) => (
        <>
          <AmountDisplay
            block
            symbol={claimTypes[record.claimTypeId]?.currencyCode || ClaCurrencyCode.sgd}
            value={value}
          />
          {value !== record.expenseAmount &&
            claimTypes[record.claimTypeId]?.expenseSubmissionType === ClaExpenseSubmissionType.Single && (
              <SecondaryText block size="small">
                <AmountDisplay
                  size="small"
                  symbol={record.expenseCurrencyCode || ClaCurrencyCode.sgd}
                  value={record.expenseAmount}
                />
              </SecondaryText>
            )}
        </>
      )
    },
    {
      title: 'Status',
      key: 'approvalStatus',
      dataIndex: 'approvalStatus',
      width: 160,
      render: (value: string) => <ClaimStatusTag status={value} />
    },
    {
      title: <i className="fal fa-paperclip-vertical att-icon-header" />,
      key: 'attachmentCount',
      dataIndex: 'attachmentCount',
      width: 50,
      render: (value: number) => {
        return (
          value > 0 && (
            <>
              <i className="fal fa-paperclip-vertical" />
              <span className="att-count">{value}</span>
            </>
          )
        )
      }
    },
    {
      title: 'Current approver',
      key: 'currentApprover',
      dataIndex: 'currentApprover',
      width: 200
    },
    {
      title: 'Notes',
      key: 'notes',
      dataIndex: 'notes',
      width: 300
    }
  ]

  const handleCriteriaApply = useCallback(
    async (criteria: ViewCriteria[]) => {
      if (viewId) {
        await dispatch(updateViewCriteria(SCREEN_CODE, viewId, { id: viewId, criteria }))
        dispatch(refetchClaimPendingApprovalsView())
      }
    },
    [viewId]
  )

  const handleSearch = useCallback((value: string) => {
    setSearch(value)
  }, [])

  const handlePeriodChange = useCallback(periodYear => {
    setPeriodYear(periodYear)
  }, [])

  const handleCloseDrawer = useCallback(
    (action: 'saved' | 'cancelled') => {
      if (action === 'saved' && viewId) {
        dispatch(refetchClaimPendingApprovalsView())
      }
      setDrawerState(DEFAULT_DRAWER_STATE)
    },
    [viewId]
  )

  const handleApprove = useCallback(async () => {
    try {
      setApproving(true)
      await dispatch(approveClaimPendingApproval({ claimRecordIds: selected.map(s => s.id) }))
      setSelected([])
    } finally {
      setApproving(false)
    }
  }, [selected])

  const handleReject = useCallback(async () => {
    try {
      setRejecting(true)
      await dispatch(rejectClaimPendingApproval({ claimRecordIds: selected.map(s => s.id) }))
      setSelected([])
    } finally {
      setRejecting(false)
    }
  }, [selected])

  const handleChangeApprover = useCallback(async () => {
    if (selected.length > 0) {
      setChangeApproverDrawerState({ visible: true, claimRecordIds: selected.map(s => s.id) })
    } else {
      showError('Please select at least one claim record')
    }
  }, [selected])

  const handleCloseChangeApproverDrawer = useCallback(() => {
    setChangeApproverDrawerState(DEFAULT_CHANGE_APPROVER_DRAWER_STATE)
  }, [])

  const rowSelection: TableRowSelection<ClaimPendingApprovalRowState> = useMemo(
    () => ({
      type: 'checkbox',
      selectedRowKeys: selected.map(s => s.id),
      hideSelectAll: false,
      onChange: (_selectedRowKeys, rows) => {
        setSelected(rows)
      }
    }),
    [selected]
  )

  return (
    <div className="claim-pending-approval">
      <div className="claim-pending-approval__body">
        <div className="claim-pending-approval__action-bar">
          <Form.Item label="">
            <SearchInput onSearch={handleSearch} />
          </Form.Item>
          <ViewCriteriaSimple screenCode={SCREEN_CODE} viewId={viewId} onApply={handleCriteriaApply} label="" />
          {canModify && (
            <Space align="start">
              <YearKeyValues
                noOfYearBefore={-5}
                noOfYearAfter={0}
                value={periodYear}
                onChange={handlePeriodChange}
                allowClear={false}
              />
              <Button onClick={handleChangeApprover}>
                <Space>
                  <i className="fa-light fa-right-left" />
                  Change approver
                </Space>
              </Button>
              <Button onClick={handleReject} loading={rejecting}>
                <Space>
                  <i className="fa-light fa-times" />
                  Reject
                </Space>
              </Button>
              <Button onClick={handleApprove} loading={approving}>
                <Space>
                  <i className="fa-light fa-check" />
                  Approve
                </Space>
              </Button>
            </Space>
          )}
        </div>
        <Card fitParent table>
          <Table
            rowSelection={rowSelection}
            rowKey="id"
            dataSource={data?.data}
            columns={columns}
            fitParent
            loading={dataLoading || viewLoading}
            scroll={{ y: 1000 }}
            pagination={false}
          />
        </Card>
      </div>
      <MutateClaimRecordDrawer {...drawerState} onClose={handleCloseDrawer} />
      <ClaimPendingChangeApproverDrawer {...changeApproverDrawerState} onClose={handleCloseChangeApproverDrawer} />
    </div>
  )
}
