import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { EmIdentityRequestState } from '../types'

export const selectEmIdentityRequests = createSelector(
  (state: StoreState) => state.employee.emIdentityRequests,
  emIdentityRequestsState =>
    memoize((employeeId?: string) => {
      if (!employeeId) return []

      return Object.values(emIdentityRequestsState[employeeId]?.entities || {}) as EmIdentityRequestState[]
    })
)
