import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchClaimRecordAttachments } from '../actions'
import { selectClaimRecordAttachments } from '../selectors'
import { ClaimRecordAttachmentState } from '../types'

export const useClaimRecordAttachments = (claimRecordId?: string): [ClaimRecordAttachmentState[], boolean] => {
  const claimRecordAttachments = useSelector(selectClaimRecordAttachments)(
    claimRecordId
  ) as ClaimRecordAttachmentState[]
  const loading = useSelector((state: StoreState) => state.claim.claimRecordAttachmentsLoading)

  useEffect(() => {
    if (claimRecordId) {
      dispatch(fetchClaimRecordAttachments(claimRecordId))
    }
  }, [claimRecordId])

  return [claimRecordAttachments, loading]
}
