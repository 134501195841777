import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Alert, Collapse, List } from '~/core-components'
import { Col, InfoTooltip, Row } from '~/components'
import { fetchLeaveTypes, fetchSysLeaveTypes, LeaveTypeName } from '~/features/leave'
import { formatNumberUnit, formatDateRange, formatDate } from '~/utils'
import { LveSysLeaveType, LveSysLeaveTypeType } from '~/constants'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchMyLeaveBalances } from '../../../../../../actions'
import {
  SSLeaveBalanceInfo,
  PeriodInfo,
  selectMyLeaveBalance,
  selectMyLeaveBalancePeriods,
  selectMyLeaveGrantsBalance
} from '../../../../../../selectors'
import './MyLeaveBalanceList.less'

interface MyLeaveBalanceListProps {
  showEmptyMsg?: boolean
}

export const MyLeaveBalanceList: FC<MyLeaveBalanceListProps> = ({ showEmptyMsg = true }) => {
  const [expand, setExpand] = useState<string>()
  const loading = useSelector((state: StoreState) => state.my.myLeaveBalancesLoading)
  const leaveBalances = useSelector(selectMyLeaveBalance)
  const periods = useSelector(selectMyLeaveBalancePeriods)
  const refetch = useSelector((state: StoreState) => state.my.myLeaveBalancesRefetch)
  const leaveGrants = useSelector(selectMyLeaveGrantsBalance)
  const leaveGrantBalances = leaveGrants.filter(lg => lg.balance > 0)

  useEffect(() => {
    dispatch(fetchLeaveTypes({ strategy: 'when-empty' }))
    dispatch(fetchSysLeaveTypes({ strategy: 'when-empty' }))
  }, [])

  useEffect(() => {
    dispatch(fetchMyLeaveBalances())
  }, [refetch])

  const handleExpand = useCallback((rowKey: string) => {
    setExpand(expand => (expand === rowKey ? undefined : rowKey))
  }, [])

  return (
    <div className="my-leave-balance-list">
      {!loading &&
        leaveBalances.length === 0 &&
        (leaveGrantBalances.length === 0 ? 'No leave entitled or granted' : showEmptyMsg && 'No leave entitled')}

      {leaveBalances.length > 0 &&
        periods
          .filter((p: PeriodInfo) => leaveBalances.some(b => b.periodCode === p.periodCode))
          .map((p: PeriodInfo) => (
            <React.Fragment key={p.periodCode}>
              {periods.length > 1 && p.startDate && p.endDate && (
                <div className="subheader">
                  {moment(p.startDate).format('DD MMM YYYY')} - {moment(p.endDate).format('DD MMM YYYY')}
                </div>
              )}
              <List
                size="small"
                dataSource={leaveBalances.filter(b => b.periodCode === p.periodCode)}
                renderItem={(item: SSLeaveBalanceInfo, index: number) => {
                  let tooltipTaken: { label: string; value?: string }[] = []

                  if (!!item.planned) {
                    tooltipTaken.push(
                      {
                        label: 'Used:',
                        value: formatNumberUnit(item.taken - item.planned, item.unit)
                      },
                      {
                        label: 'Planned:',
                        value: formatNumberUnit(item.planned, item.unit)
                      }
                    )
                  }

                  if (!!item.pending) {
                    tooltipTaken.push({
                      label: 'Pending:',
                      value: formatNumberUnit(item.pending, item.unit)
                    })
                  }

                  if (!!item.poolTaken) {
                    tooltipTaken.push({
                      label: 'From other leave type(s):',
                      value: formatNumberUnit(item.poolTaken, item.unit)
                    })
                  }

                  let tooltipCf: { label: string; value?: string }[] = []

                  if (!!item.cfExpiry) {
                    tooltipCf.push(
                      {
                        label: 'Carry forward with expiry:',
                        value: formatNumberUnit(item.cfExpiry, item.unit)
                      },
                      {
                        label: 'Taken:',
                        value: formatNumberUnit(item.cfTaken, item.unit)
                      },
                      { label: '-', value: undefined },
                      {
                        label: 'Balance:',
                        value: formatNumberUnit(item.cfExpiry - item.cfTaken, item.unit)
                      },
                      {
                        label: 'Expired on:',
                        value: item.cfExpiryDate ? moment(item.cfExpiryDate).format('DD MMM YYYY') : ''
                      }
                    )
                  }

                  return (
                    <List.Item key={item.id}>
                      <Collapse
                        activeKey={expand}
                        onChange={() => handleExpand(`${p.periodCode}-${index}`)}
                        expandIconPosition="end"
                      >
                        <Collapse.Panel
                          key={`${p.periodCode}-${index}`}
                          header={
                            <Row wrap>
                              <Col flex="1">
                                <LeaveTypeName id={item.leaveTypeId}>
                                  {item.sysLeaveTypeCode === LveSysLeaveType.grl && item.startDate && item.endDate && (
                                    <InfoTooltip
                                      icon="fa-calendar"
                                      className="cell-icon"
                                      title={
                                        item.startDate === item.endDate
                                          ? `Valid on ${formatDate(item.startDate)}`
                                          : `Valid from ${formatDateRange(item.startDate, item.endDate)}`
                                      }
                                    />
                                  )}
                                </LeaveTypeName>
                              </Col>
                              <Col
                                flex="80px"
                                className="col-right"
                                hidden={item.sysLeaveTypeType !== LveSysLeaveTypeType.entitlement}
                              >
                                {formatNumberUnit(item.balance, item.unit)}
                              </Col>
                            </Row>
                          }
                        >
                          <Row hidden={!item.cf}>
                            <Col flex="1">Carry forward</Col>
                            <Col flex="80px" className="col-right">
                              {formatNumberUnit(item.cf, item.unit)}
                              {tooltipCf.length > 0 && (
                                <InfoTooltip
                                  title={
                                    <>
                                      {tooltipCf.map(t => (
                                        <Row key={t.label}>
                                          <Col flex="1" className={t.label === '-' ? undefined : 'mr8'}>
                                            {t.label === '-' ? <hr /> : t.label}
                                          </Col>
                                          <Col>{t.value}</Col>
                                        </Row>
                                      ))}
                                    </>
                                  }
                                />
                              )}
                            </Col>
                          </Row>
                          <Row hidden={item.sysLeaveTypeType !== LveSysLeaveTypeType.entitlement}>
                            <Col flex="1">Earned</Col>
                            <Col flex="80px" className="col-right">
                              {formatNumberUnit(item.earned, item.unit)}
                              <InfoTooltip
                                title={`Earned as of ${
                                  item.asAtDate ? moment(item.asAtDate).format('DD MMM YYYY') : ''
                                }`}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col flex="1">Taken</Col>
                            <Col flex="80px" className="col-right">
                              {formatNumberUnit(item.taken + item.pending + item.poolTaken, item.unit)}
                              {tooltipTaken.length > 0 && (
                                <InfoTooltip
                                  title={
                                    <>
                                      {tooltipTaken.map(t => (
                                        <Row key={t.label}>
                                          <Col flex="1" className="mr8">
                                            {t.label}
                                          </Col>
                                          <Col>{t.value}</Col>
                                        </Row>
                                      ))}
                                    </>
                                  }
                                />
                              )}
                            </Col>
                          </Row>
                          <Row hidden={!item.forfeited}>
                            <Col flex="1">Forfeited</Col>
                            <Col flex="80px" className="col-right">
                              {formatNumberUnit(item.forfeited, item.unit)}
                            </Col>
                          </Row>
                          <Row hidden={item.sysLeaveTypeType !== LveSysLeaveTypeType.entitlement}>
                            <Col flex="1">
                              <b>Balance</b>
                            </Col>
                            <Col flex="80px" className="col-right">
                              <b>{formatNumberUnit(item.balance, item.unit)}</b>
                            </Col>
                          </Row>
                          <Row hidden={!item.cfExpiry || !item.cfExpiryDate} className="mt8 mx-12">
                            <Col flex="1">
                              <Alert
                                type="warning"
                                message={`${formatNumberUnit(item.cfExpiry, item.unit)} will expire on ${moment(
                                  item.cfExpiryDate
                                ).format('DD MMM YYYY')}`}
                              />
                            </Col>
                          </Row>
                          <Row hidden={!item.futurePlanned} className="row-dashed">
                            <Col flex="1">Planned (future)</Col>
                            <Col flex="80px" className="col-right">
                              {formatNumberUnit(item.futurePlanned, item.unit)}
                            </Col>
                          </Row>
                        </Collapse.Panel>
                      </Collapse>
                    </List.Item>
                  )
                }}
              />
            </React.Fragment>
          ))}
    </div>
  )
}
