import { ActionResult, ThunkResult } from '~/types/store'
import { emptyGuid } from '~/constants'
import { showSuccess, showError } from '~/utils'
import { apiAddEmDivision } from '../api/em-division.api'
import { setEmDivision, setEmployee } from '../reducers'
import { IEmDivisionInfo, EmDivisionState } from '../types'

export const addEmDivision = (employeeId: string, emDivision: IEmDivisionInfo): ThunkResult<void> => {
  return async (dispatch, getState): Promise<ActionResult | undefined> => {
    if (!employeeId) return

    const { status, result, errors, message, errorData } = await apiAddEmDivision(employeeId, {
      id: emptyGuid,
      employeeId,
      ...emDivision,
      divisionId: emDivision.divisionId || emptyGuid
    })
    if (status) {
      if (result.emDivisionId) {
        const employee = getState().employee.employees.entities[employeeId]
        if (employee) {
          dispatch(setEmployee({ ...employee, emDivisionId: result.emDivisionId }))
        }
      }

      const newEmDivision = {
        id: result.id,
        employeeId,
        ...emDivision
      } as EmDivisionState
      dispatch(setEmDivision({ employeeId, data: newEmDivision }))

      showSuccess('Added')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
