import React, { CSSProperties, FC, MouseEvent, useMemo } from 'react'
import {
  default as AntTable,
  TableProps as AntTableProps,
  ColumnsType as AntColumnsType,
  ColumnType as AntColumnType
} from 'antd/lib/table'
import {
  TablePaginationConfig as AntTablePaginationConfig,
  TableRowSelection as AntTableRowSelection
} from 'antd/lib/table/interface'
import { RenderExpandIconProps as AntRenderExpandIconProps } from 'rc-table/lib/interface'
import { LoadingOutlined, UpOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import { AlignType as AntAlignType } from 'rc-table/lib/interface'
import { Spin } from '../Spin/Spin'
import { SecondaryLink } from '../Link'
import 'antd/es/table/style'
import './Table.less'

export interface TableRowSelection<T> extends AntTableRowSelection<T> {}
export interface ColumnsType<RecordType = any> extends AntColumnsType<RecordType> {}
export interface ColumnType<RecordType = any> extends AntColumnType<RecordType> {}
export interface RenderExpandIconProps<RecordType = any> extends AntRenderExpandIconProps<RecordType> {}
export interface TablePaginationConfig extends AntTablePaginationConfig {}
export type AlignType = AntAlignType

export interface TableProps<RecordType = any> extends AntTableProps<RecordType> {
  loading?: boolean
  fitParent?: boolean
  showMore?: boolean
  onShowMoreToggle?: () => void
}

const showMoreStyle: CSSProperties = { margin: 10, textAlign: 'center' }

export const Table: FC<TableProps> = ({
  loading,
  fitParent = false,
  expandable,
  showMore,
  onShowMoreToggle,
  dataSource,
  ...props
}: TableProps<any>) => {
  const expandableProp = expandable
    ? {
        expandable: {
          expandIcon: ({ expanded, expandable: isExpandable, onExpand, record }: RenderExpandIconProps) => {
            const iconPrefix = `ant-table-row-expand-btn`
            const iconProps = expandable.expandRowByClick
              ? {}
              : { onClick: (e: MouseEvent<HTMLElement>) => onExpand(record, e) }

            return (
              <div
                className={classNames(iconPrefix, {
                  [`${iconPrefix}-spaced`]: !isExpandable,
                  [`${iconPrefix}-expanded`]: isExpandable && expanded,
                  [`${iconPrefix}-collapsed`]: isExpandable && !expanded
                })}
                {...iconProps}
              >
                <UpOutlined />
              </div>
            )
          },
          ...expandable
        }
      }
    : {}

  const isEmpty = useMemo(() => (dataSource?.length || 0) === 0, [dataSource])

  return (
    <div className={classNames('table__wrapper', { 'table__wrapper--fit-parent': fitParent })}>
      {!isEmpty && loading && (
        <Spin className="table__loading" indicator={<LoadingOutlined className="table__loading-icon" spin />} />
      )}
      <AntTable
        size="small"
        loading={{ indicator: <LoadingOutlined spin />, spinning: isEmpty && !!loading }}
        locale={{ emptyText: loading ? 'Please wait...' : 'No data available' }}
        dataSource={dataSource}
        {...expandableProp}
        {...props}
      />
      {typeof onShowMoreToggle === 'function' && (
        <div style={showMoreStyle}>
          <SecondaryLink onClick={onShowMoreToggle}>{showMore ? 'show less' : 'show more'}</SecondaryLink>
        </div>
      )}
    </div>
  )
}
