import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { LoadingOutlined } from '@ant-design/icons'
import { v4 as uuidv4 } from 'uuid'
import classNames from 'classnames'
import { Spin } from '~/core-components'
import { Col, Row } from '~/components'
import { dispatch } from '~/stores/store'
import { CriteriaType, LogicalOperator } from '~/constants'
import { fetchViewSchema } from '../actions'
import { selectViewCriteria, selectIsViewOrSchemaLoading } from '../selectors'
import { Screen, ViewCriteria } from '../types'
import { useFetchSysCriteria } from '../hooks'
import { ViewCriteriaGroup } from './ViewCriteriaGroup'
import './ViewCriteriaAdvanced.less'

interface ViewCriteriaAdvancedProps {
  screenCode: Screen
  viewId?: string
  readOnly?: boolean
  label?: string
  emptyText?: string
  onChange?: (criteria: ViewCriteria[]) => void
  reset?: number
}

const getRootCriteria = (): ViewCriteria => ({
  id: uuidv4(),
  parentId: undefined,
  criteriaType: CriteriaType.group,
  operator: LogicalOperator.or,
  sequence: 0
})

export const ViewCriteriaAdvanced: FC<ViewCriteriaAdvancedProps> = ({
  screenCode,
  viewId,
  readOnly = false,
  label = 'Filter',
  emptyText,
  onChange,
  reset
}) => {
  const viewCriteria = useSelector(selectViewCriteria)(screenCode, viewId)
  const loading = useSelector(selectIsViewOrSchemaLoading)(screenCode, viewId)
  const [criteria, setCriteria] = useState<ViewCriteria[]>([])
  const rootCriteria = useMemo(() => criteria.find(c => c.parentId == null), [criteria])
  const isEmpty = useMemo(
    () => viewCriteria == null || viewCriteria.filter(c => c.parentId != null).length === 0,
    [viewCriteria]
  )
  const classes = classNames('view-criteria-advanced', { 'view-criteria-advanced--read-only': readOnly })
  useFetchSysCriteria(screenCode)

  useEffect(() => {
    if (viewId) {
      dispatch(fetchViewSchema(screenCode, viewId, { strategy: 'when-empty' }))
    }
  }, [screenCode, viewId])

  useEffect(() => {
    if (!viewCriteria || viewCriteria.length === 0) {
      setCriteria([getRootCriteria()])
    } else {
      setCriteria(viewCriteria)
    }
  }, [viewCriteria, reset])

  const handleChange = useCallback(
    (update?: ViewCriteria) => {
      if (!update) return

      setCriteria(criteria => {
        const index = criteria.findIndex(c => c.id === update.id)
        let updates: ViewCriteria[]
        if (index >= 0) {
          updates = [...criteria.slice(0, index), update, ...criteria.slice(index + 1, criteria.length)]
        } else {
          updates = [...criteria, update]
        }
        updates = updates.map((c, index) => ({ ...c, sequence: index }))
        typeof onChange === 'function' && onChange(updates)

        return updates
      })
    },
    [onChange]
  )

  const handleRemove = useCallback(
    (id: string) => {
      setCriteria(criteria => {
        const index = criteria.findIndex(c => c.id === id)
        let updates: ViewCriteria[] = criteria
        if (index >= 0) {
          updates = [...criteria.slice(0, index), ...criteria.slice(index + 1, criteria.length)]
        }
        updates = updates.map((c, index) => ({ ...c, sequence: index }))
        typeof onChange === 'function' && onChange(updates)

        return updates
      })
    },
    [onChange]
  )

  return (
    <div className={classes}>
      {loading ? (
        <Spin size="small" indicator={<LoadingOutlined spin />} />
      ) : (
        <Row>
          <Col span={24}>
            {readOnly && isEmpty ? (
              <div className="criteria-empty">
                {emptyText ? emptyText : `No ${label.toLowerCase() || 'criteria'} defined`}
              </div>
            ) : (
              label && <div className="criteria-item__label">{label}</div>
            )}
            <ViewCriteriaGroup
              screenCode={screenCode}
              parent={rootCriteria}
              viewId={viewId}
              readOnly={readOnly}
              level={0}
              onChange={handleChange}
              onRemove={handleRemove}
            />
          </Col>
        </Row>
      )}
    </div>
  )
}
