import React, { FC, ChangeEvent, useCallback, useEffect, useState } from 'react'
import { Alert, Form, Input } from '~/core-components'
import { Col, Row, SysOptions } from '~/components'
import { useIsEmployee, usePermissionGate } from '~/features/iam/hooks'
import { Permission } from '~/constants'
import { useFocus } from '~/hooks/use-focus'
import { Errors } from '~/types/store'
import { IEmployeeBasic } from '../../../types'

interface EmployeeBasicFormProps {
  data: IEmployeeBasic
  savedEmail?: string
  readOnly?: boolean
  errors?: Errors
  onChange: (data: IEmployeeBasic) => void
}

export const EMPTY_EMPLOYEE_INFO_FORM_DATA: IEmployeeBasic = {
  employeeNo: '',
  title: '',
  firstName: '',
  lastName: '',
  fullName: '',
  preferredName: '',
  email: '',
  workPhone: ''
}

export const EmployeeBasicForm: FC<EmployeeBasicFormProps> = ({
  data,
  savedEmail,
  errors,
  readOnly = false,
  onChange
}: EmployeeBasicFormProps) => {
  const [formData, setFormData] = useState<IEmployeeBasic>(EMPTY_EMPLOYEE_INFO_FORM_DATA)
  const [focusRef] = useFocus(!readOnly)
  const [emailChanged, setEmailChanged] = useState<boolean>(false)
  const canView = usePermissionGate(Permission.employeeBasic)
  const [member] = useIsEmployee('email', savedEmail || '')
  const isMember = member?.isEmployee || false

  useEffect(() => {
    if (data) {
      setFormData(data)
    } else {
      setFormData(EMPTY_EMPLOYEE_INFO_FORM_DATA)
    }
  }, [data])

  useEffect(() => {
    setEmailChanged(!!savedEmail && formData.email !== savedEmail)
  }, [formData.email, savedEmail])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      const updated = { ...formData, ...updates }
      setFormData(updated)
      typeof onChange === 'function' && onChange(updated)
    },
    [formData, onChange]
  )

  return (
    <>
      {canView && (
        <>
          <Row gutter={30}>
            <Col span={12}>
              <Form.Item
                label="Employee no."
                validateStatus={errors?.employeeNo ? 'error' : ''}
                help={errors?.employeeNo}
              >
                <Form.Item noStyle>
                  <Input
                    ref={focusRef}
                    value={formData.employeeNo}
                    readOnly={readOnly}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      handleFormDataChange({ employeeNo: event.target.value?.replace(/[^a-zA-Z0-9]/g, '') })
                    }
                  />
                </Form.Item>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Title">
                <SysOptions
                  type="em_title"
                  value={formData.title}
                  readOnly={readOnly}
                  onChange={(title: string) => handleFormDataChange({ title })}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={30}>
            <Col span={12}>
              <Form.Item label="First name">
                <Input
                  value={formData.firstName}
                  readOnly={readOnly}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleFormDataChange({ firstName: event.target.value })
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Last name">
                <Input
                  value={formData.lastName}
                  readOnly={readOnly}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleFormDataChange({ lastName: event.target.value })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
      <Row gutter={30}>
        <Col span={12}>
          <Form.Item
            label="Full name (as per IC)"
            validateStatus={errors?.fullName ? 'error' : ''}
            help={errors?.fullName}
          >
            <Input
              value={formData.fullName}
              readOnly={readOnly}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleFormDataChange({ fullName: event.target.value })
              }
            />
          </Form.Item>
        </Col>
        {canView && (
          <Col span={12}>
            <Form.Item label="Preferred name">
              <Input
                value={formData.preferredName}
                readOnly={readOnly}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleFormDataChange({ preferredName: event.target.value })
                }
              />
            </Form.Item>
          </Col>
        )}
      </Row>
      {canView && (
        <>
          <Row gutter={30}>
            <Col span={12}>
              <Form.Item label="Work email" validateStatus={errors?.email ? 'error' : ''} help={errors?.email}>
                <Input
                  value={formData.email}
                  readOnly={readOnly}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleFormDataChange({ email: event.target.value })
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Work phone">
                <Input
                  value={formData.workPhone}
                  readOnly={readOnly}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleFormDataChange({ workPhone: event.target.value })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={30} hidden={readOnly || !emailChanged || !isMember}>
            <Col flex={1}>
              <Alert
                type="warning"
                message="Change in email address will require reactivation of employee portal account"
              />
            </Col>
          </Row>
        </>
      )}
    </>
  )
}
