import { setCurrencyExchangesView, setCurrencyExchangesViewLoading } from '../reducers'
import { apiGetCurrencyExchangeView } from '../api/currency-exchange.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, DateRange, FetchOptions, Pagination } from '~/types/common'

export const fetchCurrencyExchangeView = (
  viewId: string,
  pagination: Pagination,
  search: string,
  startDate: string,
  endDate: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!viewId) return

    const loading = getState().claim.currencyExchangesViewLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().claim.currencyExchangesView?.entities[viewId]
      if (hasData) return
    }

    try {
      dispatch(setCurrencyExchangesViewLoading(true))
      const { result, status } = await apiGetCurrencyExchangeView(viewId, {
        ...pagination,
        search,
        ...({ startDate, endDate } as DateRange)
      })
      if (status) {
        dispatch(setCurrencyExchangesView(result))
      }
    } finally {
      dispatch(setCurrencyExchangesViewLoading(false))
    }
  }
}
