import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchMyLeaveTask } from '../../actions'
import { SSLeaveTaskState } from '../../types'

export const useMyLeaveTask = (
  id?: string,
  fetchStrategy: FetchStrategy = 'always'
): [SSLeaveTaskState | undefined, boolean] => {
  const task = useSelector((state: StoreState) => state.my.myLeaveTasks.entities[id || ''])
  const loading = useSelector((state: StoreState) => state.my.myLeaveTasksLoading)

  useEffect(() => {
    if (id) dispatch(fetchMyLeaveTask(id, { strategy: fetchStrategy }))
  }, [id, fetchStrategy])

  return [task, loading]
}
