import { removeLeaveRecord, removeLeaveGrants } from '../reducers'
import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiDeleteLeaveRecord } from '../api/leave-record.api'
import { LeaveGrantState } from '../types'

export const deleteLeaveRecord = (id: string): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const { status, errors, message, errorData } = await apiDeleteLeaveRecord(id)
    if (status) {
      const leaveRecord = getState().leave.leaveRecords?.entities[id]
      if (leaveRecord) {
        const leaveGrants = getState().leave.leaveGrants[leaveRecord.employeeId]
        if (leaveGrants && leaveGrants.ids.length > 0) {
          const leaveRecordGrants = Object.values(leaveGrants?.entities || {}).filter(
            lg => lg?.leaveRecordId === id
          ) as LeaveGrantState[]
          dispatch(removeLeaveGrants({ employeeId: leaveRecord.employeeId, ids: leaveRecordGrants.map(lg => lg.id) }))
        }
      }

      dispatch(removeLeaveRecord(id))

      showSuccess('Deleted')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
