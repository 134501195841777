import { showError, showSuccess } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiSendInviteBulk } from '../api/invite.api'
import { refetchEmDirectoryView } from '~/features/employee'

export const sendInviteBulk = (): ThunkResult<void> => {
  return async dispatch => {
    const { status, result, errors, message, errorData } = await apiSendInviteBulk()
    if (status) {
      dispatch(refetchEmDirectoryView())
      showSuccess('Sent')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
