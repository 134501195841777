import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { IShiftCategory, ShiftCategoryState } from '../types'

const baseUrl = getBaseUrl('/attendance/shiftcategory')

export const apiGetShiftCategories = () => request<ShiftCategoryState[]>('get', baseUrl)

export const apiAddShiftCategory = (location: IShiftCategory) => request<{ id: string }>('post', baseUrl, location)

export const apiUpdateShiftCategory = (id: string, location: Operation[]) =>
  request<void>('patch', `${baseUrl}/${id}`, location)

export const apiDeleteShiftCategory = (id: string) => request<void>('delete', `${baseUrl}/${id}`)
