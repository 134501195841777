import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Dictionary } from '@reduxjs/toolkit'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { FetchStrategy } from '~/types/common'
import { fetchShiftDays } from '../actions'
import { ShiftDayState } from '../types'
import { selectShiftDaysDict } from '../selectors'

export const useShiftDaysDict = (
  shiftId?: string,
  fetchStrategy: FetchStrategy = 'when-empty'
): [Dictionary<ShiftDayState>, boolean] => {
  const refetch = useSelector((state: StoreState) => state.attendance.shiftDaysRefetch)
  const data = useSelector(selectShiftDaysDict)(shiftId)
  const loading = useSelector((state: StoreState) => state.attendance.shiftDaysLoading[shiftId || ''])

  useEffect(() => {
    dispatch(fetchShiftDays(shiftId, { strategy: fetchStrategy }))
  }, [refetch, shiftId, fetchStrategy])

  return [data, loading]
}
