import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { FetchStrategy } from '~/types/common'
import { Screen, ViewState } from '../types'
import { fetchViews } from '../actions'
import { selectFirstOwnedView } from '../selectors'

export const useFirstView = (
  screenCode: Screen,
  runSetup = true,
  fetchStrategy: FetchStrategy = 'always'
): [ViewState | undefined, boolean] => {
  const view = useSelector(selectFirstOwnedView)(screenCode)
  const loading = useSelector((state: StoreState) => state.selection.viewsLoading[screenCode] || false)

  useEffect(() => {
    dispatch(fetchViews(screenCode, runSetup, { strategy: fetchStrategy }))
  }, [screenCode, runSetup, fetchStrategy])

  return [view, loading]
}
