import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { ROLeaveRecordViewState } from '../../types'

export const selectROLeaveRecordsView = createSelector(
  (state: StoreState) => state.my.roLeaveRecordsView,
  roLeaveRecordsState =>
    memoize((viewId: string): ROLeaveRecordViewState => {
      const roLeaveRecords = roLeaveRecordsState.entities[viewId] || ({} as ROLeaveRecordViewState)
      return roLeaveRecords
    })
)
