import React, { FC, useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from '~/core-components'
import { useSlackConfigs, fetchSlackConfigs } from '~/features/notification'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { installSlack } from '../../actions/install-slack'

interface SlackIntegrationProps {
  code: string | null
}

export const SlackIntegration: FC<SlackIntegrationProps> = ({ code }) => {
  const [slackConfigs, loading] = useSlackConfigs()
  const installing = useSelector((state: StoreState) => state.notification.slackInstalling)

  const installingSlack = useCallback(async code => {
    await dispatch(installSlack(code))
    dispatch(fetchSlackConfigs())
  }, [])

  useEffect(() => {
    if (code) {
      installingSlack(code)
    }
  }, [code, installingSlack])

  if (installing || loading) {
    return <Spin indicator={<LoadingOutlined spin />} />
  }

  if (!slackConfigs?.clientId) {
    return <>-</>
  }

  if (slackConfigs?.installed) {
    return (
      <>
        <img alt="Slack logo" src="slack_logo.svg" height="42" />
        Slack has been successfully integrated
      </>
    )
  }

  return (
    <a
      href={`https://slack.com/oauth/v2/authorize?client_id=${slackConfigs.clientId}&scope=${slackConfigs.scope}&user_scope=${slackConfigs.userScope}`}
    >
      <img
        alt="Add to Slack"
        height="32"
        width="120"
        src="https://platform.slack-edge.com/img/add_to_slack.png"
        srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
      />
    </a>
  )
}
