import { setIr21A1Form, setIr21A1FormLoading } from '../reducers'
import { ThunkResult } from '~/types/store'
import { apiGetIr21A1FormData } from '../api/ir21.api'

export const fetchIr21A1Form = (ir21SubmissionId: string, employeeTaxNo: string): ThunkResult<void> => {
  return async dispatch => {
    try {
      dispatch(setIr21A1FormLoading(true))
      const { result, status } = await apiGetIr21A1FormData(ir21SubmissionId, employeeTaxNo)
      if (status) {
        dispatch(setIr21A1Form(result))
      }
    } finally {
      dispatch(setIr21A1FormLoading(false))
    }
  }
}
