import { request, getBaseUrl, timeout } from '~/utils/request'
import { EmSSActivateSummary } from '../types'

const baseUrl = getBaseUrl('/employee/employeessactivate')

export const apiActivateEmSS = (params: { employeeIds: string[] }) =>
  request<EmSSActivateSummary>('post', baseUrl, params)

export const apiGetActivateSSExcelReport = () =>
  request('get', `${baseUrl}/report/excel`, undefined, { responseType: 'blob', timeout })
