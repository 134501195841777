import { replaceEmManagers, setEmManagersLoading } from '../reducers'
import { apiGetEmManagers } from '../api/em-manager.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchEmManagers = (
  employeeId: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!employeeId) return

    const loading = getState().employee.emManagersLoading[employeeId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().employee.emManagers[employeeId]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setEmManagersLoading({ employeeId, loading: true }))
      const { result, status } = await apiGetEmManagers(employeeId)
      if (status) {
        dispatch(replaceEmManagers({ employeeId, data: result }))
      }
    } finally {
      dispatch(setEmManagersLoading({ employeeId, loading: false }))
    }
  }
}
