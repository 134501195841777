import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { View } from '~/constants'
import { StoreState } from '~/types/store'
import { Screen, ViewSchemaState } from '../types'

export const selectDefaultViewSchema = createSelector(
  (state: StoreState) => state.selection.viewSchema,
  viewSchemaState =>
    memoize((screenCode: Screen): ViewSchemaState | undefined => {
      const viewSchema = Object.values(viewSchemaState[screenCode]?.entities || {}).find(
        v => v?.screenCode === screenCode && v?.viewName === View.default
      )
      return viewSchema
    })
)
