import React, { FC, useState, useCallback, useEffect, ChangeEvent } from 'react'
import confirm from 'antd/lib/modal/confirm'
import { Form, Input } from '~/core-components'
import { Col, DrawerForm, Row } from '~/components'
import { dispatch } from '~/stores/store'
import { addBank, deleteBank, updateBank } from '../../../actions'
import { IBank, BankState } from '../../../types'
import { mapBankStateToBank } from '../../../types/bank.mapper'
import { ActionResult, Errors } from '~/types/store'
import { useFocus } from '~/hooks/use-focus'

export interface MutateBankDrawerProps {
  visible: boolean
  data?: BankState
  onClose: () => void
}

type BankForm = Omit<IBank, 'isShown'>

const EMPTY_FORM_DATA: BankForm = {
  code: '',
  name: '',
  swiftCode: ''
}

export const MutateBankDrawer: FC<MutateBankDrawerProps> = ({ visible, data, onClose }: MutateBankDrawerProps) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<BankForm>(EMPTY_FORM_DATA)
  const [focusRef, setFocus] = useFocus(true)
  const [errors, setErrors] = useState<Errors>()

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  useEffect(() => {
    if (data) {
      setFormData({ code: data.code, name: data.name, swiftCode: data.swiftCode })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      const updated = { ...formData, ...updates }
      setFormData(updated)
    },
    [formData]
  )

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      if (data) {
        result = await dispatch(updateBank(data.code, mapBankStateToBank(data), { ...formData, isShown: data.isShown }))
      } else {
        result = await dispatch(addBank({ ...formData, isShown: true }))
      }
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data, formData, onClose])

  const handleDelete = useCallback(
    (bank: BankState | undefined) => {
      if (bank) {
        const { code, name } = bank
        confirm({
          title: 'Delete bank',
          content: `Do you want to delete bank record of "${name}"?`,
          onOk: async () => {
            const result: ActionResult | undefined = await dispatch(deleteBank(code))
            if (result?.errors) {
              setErrors(result.errors)
            }

            if (!result?.errors) {
              typeof onClose === 'function' && onClose()
            }
          },
          okText: 'Delete',
          okType: 'danger'
        })
      }
    },
    [onClose]
  )

  return (
    <DrawerForm
      open={visible}
      title={data ? 'Edit bank' : 'Add bank'}
      onClose={onClose}
      confirmLoading={loading}
      width={500}
      showDelete={data ? true : false}
      onDelete={() => handleDelete(data)}
      formId="form-bank"
    >
      <Form id="form-bank" onFinish={handleOk}>
        <Row>
          <Col span={24}>
            <Form.Item label="Code" validateStatus={errors?.code ? 'error' : ''} help={errors?.code}>
              <Input
                ref={focusRef}
                value={formData.code}
                disabled={data != null}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ code: event.target.value })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Name" validateStatus={errors?.name ? 'error' : ''} help={errors?.name}>
              <Input
                value={formData.name}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ name: event.target.value })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Swift code" validateStatus={errors?.swiftCode ? 'error' : ''} help={errors?.swiftCode}>
              <Input
                value={formData.swiftCode}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleFormDataChange({ swiftCode: event.target.value })
                }
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}
