import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { apiUpdateEmployeeHire } from '../api/employee.api'
import { EmployeeState, IEmployeeHire } from '../types'
import { setEmCompany, setEmployee } from '../reducers'
import pick from 'lodash/pick'

export const updateEmployeeHire = <T extends IEmployeeHire>(id: string, before: T, request: T): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const after = { ...before, ...request } as T
    const patch = generateJsonPatch<T>(before, after)
    const { result, status, errors, message, errorData } = await apiUpdateEmployeeHire(id, patch)

    if (status) {
      const employee = getState().employee.employees.entities[id] as EmployeeState
      const refreshFields = pick(
        result,
        'emCompanyId',
        'companyId',
        'serviceLength',
        'initialServiceLength',
        'emStatus',
        'terminationReasonId',
        'terminationNotes',
        'terminationNoticeDate'
      )
      dispatch(setEmployee({ ...employee, ...after, ...refreshFields }))

      if (result.emCompanyId && result.companyId) {
        const emCompany = getState().employee.emCompanies[id]?.entities[result.emCompanyId]
        if (emCompany) {
          dispatch(setEmCompany({ employeeId: id, data: { ...emCompany, companyId: result.companyId } }))
        }
      }

      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
