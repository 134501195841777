import React, { FC, useCallback, useEffect, useState } from 'react'
import { Alert, Button, Drawer, SecondaryText, Space } from '~/core-components'
import { Col, ErrorDisplay, Row } from '~/components'
import { downloadWithDom, showError } from '~/utils'
import { dispatch } from '~/stores/store'
import { useIsMountedRef } from '~/hooks/use-is-mounted-ref'
import { ActionResult, Errors } from '~/types/store'
import { sendInviteBulk } from '~/features/iam'
import { apiGetActivateSSExcelReport } from '~/features/employee/api/employee-ss-activate.api'
import { EmSSActivateSummary } from '~/features/employee/types'
import './ActivateEmSSDrawer.less'

interface ActivateEmSSDrawerProps {
  visible: boolean
  summary?: EmSSActivateSummary
  onClose: (action: 'activated' | 'cancelled') => void
}

export const ActivateEmSSDrawer: FC<ActivateEmSSDrawerProps> = ({ visible, summary, onClose }) => {
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<Errors>()
  const isMountedRef = useIsMountedRef()
  const [downloading, setDownloading] = useState(false)

  useEffect(() => {
    setErrors(undefined)
  }, [visible])

  const handleSend = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      result = await dispatch(sendInviteBulk())
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose('activated')
    }
  }, [onClose])

  const handleCloseDrawer = useCallback(() => {
    typeof onClose === 'function' && onClose('cancelled')
  }, [onClose])

  const handleDownloadReport = useCallback(async () => {
    try {
      setDownloading(true)
      const { status, result, errors, message, errorData } = await apiGetActivateSSExcelReport()

      if (status) {
        const fileName = `self_service_activation_error_report.xlsx`
        downloadWithDom(result, fileName)
      } else {
        console.error('Error while downloading', errors)
        showError(message, errorData)
      }
    } finally {
      if (isMountedRef.current) setDownloading(false)
    }
  }, [isMountedRef])

  return (
    <Drawer
      open={visible}
      title="Activate account"
      onClose={handleCloseDrawer}
      width="600"
      className="activate-em-ss-drawer"
      footer={
        <>
          <Space className="activate-em-ss-drawer__download-bar-buttons">
            <Button onClick={handleDownloadReport} loading={downloading}>
              Download report
            </Button>
          </Space>
          <Space className="activate-em-ss-drawer__action-bar-buttons">
            <Button
              type="primary"
              onClick={handleSend}
              loading={loading}
              disabled={(summary?.withEmail || 0) === 0 && (summary?.withoutEmail || 0) === 0}
            >
              Send
            </Button>
          </Space>
        </>
      }
    >
      <Row gutter={15}>
        <Col flex="1">No. of employees selected</Col>
        <Col flex="60px" className="text-right">
          {summary?.total}
        </Col>
      </Row>
      <div className="indented-row">
        <Row gutter={15}>
          <Col flex="1">
            Employees with email address
            <div>
              <SecondaryText className="secondary-text">
                The activation email will be sent to each employee's email address
              </SecondaryText>
            </div>
          </Col>
          <Col flex="60px" className="text-right">
            {summary?.withEmail}
          </Col>
        </Row>
        <Row gutter={15}>
          <Col flex="1">
            Employees without email address
            <div>
              <SecondaryText className="secondary-text">
                A default password will be generated for employees without an email address
              </SecondaryText>
            </div>
          </Col>
          <Col flex="60px" className="text-right">
            {summary?.withoutEmail}
          </Col>
        </Row>
        <Row gutter={15}>
          <Col span={24}>
            <Alert
              type="info"
              message={
                <>
                  <div className="font-bold">Default password format:</div>
                  <div>[Last 4 characters of identity number] + [date of birth]</div>
                  <div>Example: 123A01Jan90</div>
                </>
              }
            />
          </Col>
        </Row>
        <Row gutter={15}>
          <Col flex="1">Invalid records</Col>
          <Col flex="60px" className="text-right">
            {summary?.invalid}
          </Col>
        </Row>
      </div>
      <ErrorDisplay errors={errors} />
    </Drawer>
  )
}
