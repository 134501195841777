import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Descriptions } from '~/core-components'
import {
  fetchEmpKeyvalues,
  selectCategoryKeyvalues,
  selectCostCentreKeyvalues,
  selectDepartmentKeyvalues,
  selectDivisionKeyvalues,
  selectEmploymentTypeKeyvalues,
  selectGrpKeyvalues,
  selectJobKeyvalues,
  selectKeyvaluesById,
  selectSectionKeyvalues,
  selectTeamKeyvalues
} from '~/features/master'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { EmploymentConfig } from '~/constants'
import {
  selectCurrentEmDepartment,
  selectCurrentEmSection,
  selectCurrentEmDivision,
  selectCurrentEmGrp,
  selectCurrentEmCategory,
  selectCurrentEmTeam,
  selectCurrentEmJob,
  selectCurrentEmManager,
  selectCurrentEmManagerSecondary,
  selectCurrentEmOffice,
  selectCurrentEmCostCentre,
  selectCurrentEmEmploymentType,
  selectCurrentEmOtClass
} from '../../../../../selectors'
import { useHiddenEmploymentConfigs } from '~/features/master/hooks'

interface CopyEmCareerProps {
  employeeId?: string
  emDepartmentId?: string
  emDivisionId?: string
  emSectionId?: string
  emGrpId?: string
  emCategoryId?: string
  emTeamId?: string
  emJobId?: string
  emManagerId?: string
  emManagerSecondaryId?: string
  emOfficeId?: string
  emCostCentreId?: string
  emEmploymentTypeId?: string
  emOtClassId?: string
}

export const CopyEmCareer: FC<CopyEmCareerProps> = ({
  employeeId,
  emDepartmentId,
  emDivisionId,
  emSectionId,
  emGrpId,
  emCategoryId,
  emTeamId,
  emJobId,
  emManagerId,
  emManagerSecondaryId,
  emOfficeId,
  emCostCentreId,
  emEmploymentTypeId,
  emOtClassId
}) => {
  const [employmentConfigs] = useHiddenEmploymentConfigs()
  const hiddenEmployments = employmentConfigs.map(ec => ec.code)
  const hideDepartment = hiddenEmployments.includes(EmploymentConfig.department)
  const hideDivision = hiddenEmployments.includes(EmploymentConfig.division)
  const hideSection = hiddenEmployments.includes(EmploymentConfig.section)
  const hideGrp = hiddenEmployments.includes(EmploymentConfig.grp)
  const hideCategory = hiddenEmployments.includes(EmploymentConfig.category)
  const hideTeam = hiddenEmployments.includes(EmploymentConfig.team)
  const hideJob = hiddenEmployments.includes(EmploymentConfig.job)
  const hideManager = hiddenEmployments.includes(EmploymentConfig.manager)
  const hideManagerSecondary = hiddenEmployments.includes(EmploymentConfig.managerSecondary)
  const hideOffice = hiddenEmployments.includes(EmploymentConfig.office)
  const hideCostCentre = hiddenEmployments.includes(EmploymentConfig.costCentre)
  const hideEmploymentType = hiddenEmployments.includes(EmploymentConfig.employmentType)
  const hideOtClass = hiddenEmployments.includes(EmploymentConfig.otClass)

  const departments = useSelector(selectDepartmentKeyvalues)
  const divisions = useSelector(selectDivisionKeyvalues)
  const sections = useSelector(selectSectionKeyvalues)
  const grps = useSelector(selectGrpKeyvalues)
  const categories = useSelector(selectCategoryKeyvalues)
  const teams = useSelector(selectTeamKeyvalues)
  const jobs = useSelector(selectJobKeyvalues)
  const employees = useSelector((state: StoreState) => state.employee.emKeyValues?.entities || {})
  const offices = useSelector(selectKeyvaluesById)('office')
  const costCentres = useSelector(selectCostCentreKeyvalues)
  const employmentTypes = useSelector(selectEmploymentTypeKeyvalues)
  const otClasses = useSelector(selectKeyvaluesById)('otClass')

  const emDepartment = useSelector(selectCurrentEmDepartment)(employeeId, emDepartmentId)
  const emDivision = useSelector(selectCurrentEmDivision)(employeeId, emDivisionId)
  const emSection = useSelector(selectCurrentEmSection)(employeeId, emSectionId)
  const emGrp = useSelector(selectCurrentEmGrp)(employeeId, emGrpId)
  const emCategory = useSelector(selectCurrentEmCategory)(employeeId, emCategoryId)
  const emTeam = useSelector(selectCurrentEmTeam)(employeeId, emTeamId)
  const emJob = useSelector(selectCurrentEmJob)(employeeId, emJobId)
  const emManager = useSelector(selectCurrentEmManager)(employeeId, emManagerId)
  const emManagerSecondary = useSelector(selectCurrentEmManagerSecondary)(employeeId, emManagerSecondaryId)
  const emOffice = useSelector(selectCurrentEmOffice)(employeeId, emOfficeId)
  const emCostCentre = useSelector(selectCurrentEmCostCentre)(employeeId, emCostCentreId)
  const emEmploymentType = useSelector(selectCurrentEmEmploymentType)(employeeId, emEmploymentTypeId)
  const emOtClass = useSelector(selectCurrentEmOtClass)(employeeId, emOtClassId)

  useEffect(() => {
    if (!hideDepartment) dispatch(fetchEmpKeyvalues('department', undefined, undefined, { strategy: 'when-empty' }))
    if (!hideDivision) dispatch(fetchEmpKeyvalues('division', undefined, undefined, { strategy: 'when-empty' }))
    if (!hideSection) dispatch(fetchEmpKeyvalues('section', undefined, undefined, { strategy: 'when-empty' }))
    if (!hideGrp) dispatch(fetchEmpKeyvalues('grp', undefined, undefined, { strategy: 'when-empty' }))
    if (!hideCategory) dispatch(fetchEmpKeyvalues('category', undefined, undefined, { strategy: 'when-empty' }))
    if (!hideTeam) dispatch(fetchEmpKeyvalues('team', undefined, undefined, { strategy: 'when-empty' }))
    if (!hideJob) dispatch(fetchEmpKeyvalues('job', undefined, undefined, { strategy: 'when-empty' }))
    if (!hideOffice) dispatch(fetchEmpKeyvalues('office', undefined, undefined, { strategy: 'when-empty' }))
    if (!hideCostCentre) dispatch(fetchEmpKeyvalues('costCentre', undefined, undefined, { strategy: 'when-empty' }))
    if (!hideEmploymentType)
      dispatch(fetchEmpKeyvalues('employmentType', undefined, undefined, { strategy: 'when-empty' }))
    if (!hideOtClass) dispatch(fetchEmpKeyvalues('otClass', undefined, undefined, { strategy: 'when-empty' }))
  }, [
    hideDepartment,
    hideDivision,
    hideSection,
    hideGrp,
    hideCategory,
    hideTeam,
    hideJob,
    hideOffice,
    hideCostCentre,
    hideEmploymentType,
    hideOtClass
  ])

  return (
    <Descriptions bordered column={1} className="copy-em__content">
      {!hideDepartment && (
        <Descriptions.Item label="Department">{departments[emDepartment?.departmentId || '']?.value}</Descriptions.Item>
      )}
      {!hideDivision && (
        <Descriptions.Item label="Division">{divisions[emDivision?.divisionId || '']?.value}</Descriptions.Item>
      )}
      {!hideSection && (
        <Descriptions.Item label="Section">{sections[emSection?.sectionId || '']?.value}</Descriptions.Item>
      )}
      {!hideGrp && <Descriptions.Item label="Group">{grps[emGrp?.grpId || '']?.value}</Descriptions.Item>}
      {!hideCategory && (
        <Descriptions.Item label="Category">{categories[emCategory?.categoryId || '']?.value}</Descriptions.Item>
      )}
      {!hideTeam && <Descriptions.Item label="Team">{teams[emTeam?.teamId || '']?.value}</Descriptions.Item>}
      {!hideJob && <Descriptions.Item label="Job title">{jobs[emJob?.jobId || '']?.value}</Descriptions.Item>}
      {!hideManager && (
        <Descriptions.Item label="Reporting to">{employees[emManager?.managerId || '']?.value}</Descriptions.Item>
      )}
      {!hideManagerSecondary && (
        <Descriptions.Item label="Dotted line reporting to">
          {employees[emManagerSecondary?.managerSecondaryId || '']?.value}
        </Descriptions.Item>
      )}
      {!hideOffice && <Descriptions.Item label="Office">{offices[emOffice?.officeId || '']?.value}</Descriptions.Item>}
      {!hideCostCentre && (
        <Descriptions.Item label="Cost centre">
          {costCentres[emCostCentre?.costCentreId || '']?.value}
        </Descriptions.Item>
      )}
      {!hideEmploymentType && (
        <Descriptions.Item label="Employment type">
          {employmentTypes[emEmploymentType?.employmentTypeId || '']?.value}
        </Descriptions.Item>
      )}
      {!hideOtClass && (
        <Descriptions.Item label="Overtime Classification">
          {otClasses[emOtClass?.otClassId || '']?.value}
        </Descriptions.Item>
      )}
    </Descriptions>
  )
}
