import React, { FC, useCallback, useState } from 'react'
import { Button, Tooltip } from '~/core-components'
import { LeaveClosingDrawer } from './components/LeaveClosingDrawer'

interface LeaveClosingButtonProps {
  visible: boolean
}

interface LeaveClosingDrawerState {
  visible: boolean
}

const LEAVE_CLOSING_DRAWER_STATE: LeaveClosingDrawerState = { visible: false }

export const LeaveClosingButton: FC<LeaveClosingButtonProps> = ({ visible }) => {
  const [leaveClosingDrawer, setLeaveClosingDrawer] = useState<LeaveClosingDrawerState>(LEAVE_CLOSING_DRAWER_STATE)

  const handleLeaveClosingClick = useCallback(() => {
    setLeaveClosingDrawer({ visible: true })
  }, [])

  const handleCloseLeaveClosingDrawer = useCallback(() => {
    setLeaveClosingDrawer(LEAVE_CLOSING_DRAWER_STATE)
  }, [])

  if (!visible) return null

  return (
    <>
      <Tooltip title="Leave closing">
        <Button icon={<i className="fal fa-box-archive" />} onClick={handleLeaveClosingClick} />
      </Tooltip>
      <LeaveClosingDrawer {...leaveClosingDrawer} onClose={handleCloseLeaveClosingDrawer} />
    </>
  )
}
