import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { LeaveTypeState } from '../types'

export const selectLeaveTypesView = createSelector(
  (state: StoreState) => state.leave.leaveTypesView,
  (leaveTypeViewState): (LeaveTypeState | undefined)[] => {
    const leaveTypes = Object.values(leaveTypeViewState?.entities || {}) as (LeaveTypeState | undefined)[]
    return leaveTypes.sort((a, b) => {
      const aInactiveDate = new Date(a!.inactiveDate || '').getTime() || 0
      const bInactiveDate = new Date(b!.inactiveDate || '').getTime() || 0

      if (bInactiveDate === 0 && aInactiveDate === 0) {
        return a!.name.localeCompare(b!.name)
      } else {
        return aInactiveDate - bInactiveDate
      }
    })
  }
)
