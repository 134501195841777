import React, { FC, useCallback, useState } from 'react'
import { Col, Row } from '~/components'
import { Form, Radio, RadioChangeEvent, Space } from '~/core-components'
import { MyLeaveBalance } from './components/MyLeaveBalance/MyLeaveBalance'
import { MyLeaveCalendar } from './components/MyLeaveCalendar/MyLeaveCalendar'
import { MyLeaveGrantBalance } from './components/MyLeaveGrantBalance/MyLeaveGrantBalance'
import { MyLeaveRecords } from './components/MyLeaveRecords/MyLeaveRecords'
import './MyLeave.less'

interface MyLeaveProps {}

export const MyLeave: FC<MyLeaveProps> = () => {
  const [viewType, setViewType] = useState<'calendar' | 'list'>('calendar')
  const handleRadioChanged = useCallback((event: RadioChangeEvent) => setViewType(event.target.value), [])

  return (
    <div className="my-leave">
      <Row gutter={24}>
        <Col flex="1">
          <Row justify="end">
            <Col>
              <Form.Item label="View mode">
                <Radio.Group value={viewType} buttonStyle="solid" onChange={handleRadioChanged}>
                  <Radio.Button value="calendar">
                    <i className="fa-light fa-calendar"></i>
                  </Radio.Button>
                  <Radio.Button value="list">
                    <i className="fa-light fa-list"></i>
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          {viewType === 'calendar' ? <MyLeaveCalendar /> : <MyLeaveRecords />}
        </Col>
        <Col flex="380px">
          <Space direction="vertical" size="large">
            <MyLeaveGrantBalance />
            <MyLeaveBalance />
          </Space>
        </Col>
      </Row>
    </div>
  )
}
