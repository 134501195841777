import React, { FC, useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { useSelector } from 'react-redux'
import confirm from 'antd/lib/modal/confirm'
import { Button, PageHeader } from '~/core-components'
import { DocumentTitle } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { useMasterMenu } from '~/features/master/containers/MasterMenuContext'
import { leaveMenus } from '~/features/master/configs'
import { Permission, PermissionAction, LveSysLeaveTypeType } from '~/constants'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { ActionResult, StoreState } from '~/types/store'
import { MenuItem } from '~/types/common'
import { selectLeaveTypes } from '../../selectors'
import {
  deleteLeaveType,
  fetchLeaveTypes,
  fetchSysLeaveTypes,
  fetchSysLtFields,
  fetchLtSysColumns
} from '../../actions'
import { useLeaveType } from '../../hooks'
import { LeaveTypeInfo } from './components/LeaveTypeInfo'
import { LeaveTypeEarn } from './components/LeaveTypeEarn'
import { LeaveTypeApply } from './components/LeaveTypeApply'
import { LeaveTypeSetting } from './components/LeaveTypeSetting'
import { LeaveTypeAdvance } from './components/LeaveTypeAdvance'
import { LeaveTypePolicy } from './components/LeaveTypePolicy'
import { AddLeaveTypeDrawer } from './components/AddLeaveTypeDrawer'
import './LeaveType.less'

interface LeaveTypeProps {}

interface LeaveTypeParams {
  id: string
}

interface DrawerState {
  visible: boolean
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: SETTINGS_ROUTES.leaveTypes,
    breadcrumbName: 'Overview'
  },
  {
    path: '',
    breadcrumbName: 'Leave type'
  }
]

export const LeaveType: FC<LeaveTypeProps> = () => {
  const { id } = useParams<LeaveTypeParams>()
  const leaveTypes = useSelector(selectLeaveTypes)
  const [leaveType] = useLeaveType(id)
  const sysLeaveTypeCode = leaveType?.sysLeaveTypeCode || ''
  const sysLeaveType = useSelector((state: StoreState) => state.leave.sysLeaveTypes?.entities[sysLeaveTypeCode || ''])
  const [editing, setEditing] = useState(false)
  const history = useHistory()
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const canModify = usePermissionGate(Permission.lveType, PermissionAction.Modify)
  const { setMenus } = useMasterMenu()

  useEffect(() => {
    dispatch(fetchLeaveTypes())
    dispatch(fetchSysLeaveTypes({ strategy: 'when-empty' }))
  }, [])

  useEffect(() => {
    dispatch(fetchSysLtFields(sysLeaveTypeCode, { strategy: 'when-empty' }))
  }, [sysLeaveTypeCode])

  useEffect(() => {
    dispatch(fetchLtSysColumns(id, { strategy: 'when-empty' }))
  }, [id])

  const handleAddLeaveType = useCallback(() => {
    setDrawerState({ visible: true })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  useEffect(() => {
    const menus: MenuItem[] = leaveTypes.map(lt => ({
      path: SETTINGS_ROUTES.leaveType.replace(':id', lt!.id),
      value: lt!.name,
      sysPermissionId: Permission.lveType
    }))

    if (canModify)
      menus.push({
        path: (
          <Button type="dashed" onClick={handleAddLeaveType} block>
            Add leave type
          </Button>
        ),
        value: '',
        sysPermissionId: ''
      })

    setMenus(menus)
    return () => {
      setMenus(leaveMenus)
    }
  }, [setMenus, leaveTypes, canModify, handleAddLeaveType])

  const handleEdit = useCallback(() => setEditing(true), [])
  const handleView = useCallback(() => setEditing(false), [])

  const handleDelete = useCallback(() => {
    if (id && leaveType) {
      confirm({
        title: 'Delete leave type',
        content: `Do you want to delete leave type "${leaveType.name}"?`,
        onOk: async () => {
          const result: ActionResult | undefined = await dispatch(deleteLeaveType(id))
          if (!result?.errors) {
            history.push(SETTINGS_ROUTES.leaveTypes)
          }
        },
        okText: 'Delete',
        okType: 'danger'
      })
    }
  }, [id, leaveType, history])

  return (
    <div id="leave-type" className="leave-type">
      <DocumentTitle title="Leave Type" />
      <PageHeader
        title="Leave type"
        containerId="leave-type"
        breadcrumb={{ routes }}
        extra={canModify && editing && <Button onClick={handleDelete}>Delete</Button>}
      />
      <div className="leave-type__body">
        <LeaveTypeInfo leaveType={leaveType} onEdit={handleEdit} onSave={handleView} onCancel={handleView} />
        <LeaveTypeEarn leaveType={leaveType} onEdit={handleEdit} onSave={handleView} onCancel={handleView} />
        <LeaveTypeApply leaveType={leaveType} onEdit={handleEdit} onSave={handleView} onCancel={handleView} />
        <LeaveTypeAdvance leaveType={leaveType} onEdit={handleEdit} onSave={handleView} onCancel={handleView} />
        <LeaveTypeSetting leaveType={leaveType} onEdit={handleEdit} onSave={handleView} onCancel={handleView} />
        {sysLeaveType?.type !== LveSysLeaveTypeType.grant && <LeaveTypePolicy leaveType={leaveType} />}
        {canModify && <AddLeaveTypeDrawer {...drawerState} onClose={handleCloseDrawer} />}
      </div>
    </div>
  )
}
