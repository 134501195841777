import React, { CSSProperties, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Form } from '~/core-components'
import { Col, EditableCard, EditableCardState, LveKeyValues, Row } from '~/components'
import { usePermissionGate } from '~/features/iam/hooks'
import { useFocus } from '~/hooks'
import { Permission, PermissionAction } from '~/constants'
import { dispatch } from '~/stores/store'
import { ActionResult, StoreState } from '~/types/store'
import { IEmLeaveBuddy } from '../../../types'
import { useFirstInView } from '~/hooks/use-first-in-view'
import { fetchKeyvalues } from '~/features/master'
import { fetchEmLeaveBuddies, updateEmLeaveBuddies } from '~/features/employee/actions'
import { selectEmLeaveBuddies } from '~/features/employee/selectors'

interface EmLeaveBuddiesProps {
  employeeId?: string
  onEdit?: () => void
  onSave?: () => void
  onCancel?: () => void
}

const EMPTY_FORM_DATA: IEmLeaveBuddy = {
  leaveBuddyIds: []
}

const cardStyle: CSSProperties = { margin: 24 }

export const EmLeaveBuddies: FC<EmLeaveBuddiesProps> = ({
  employeeId,
  onEdit,
  onSave,
  onCancel
}: EmLeaveBuddiesProps) => {
  const { ref, inView } = useFirstInView<HTMLDivElement>({ threshold: 0.25 })
  const [cardState, setCardState] = useState<EditableCardState>()
  const [formData, setFormData] = useState<IEmLeaveBuddy>(EMPTY_FORM_DATA)
  const emLeaveBuddies = useSelector(selectEmLeaveBuddies)(employeeId)
  const loading = useSelector((state: StoreState) => state.employee.emLeaveBuddiesLoading[employeeId || ''])
  const [focusRef] = useFocus(cardState === 'editing')
  const canModify = usePermissionGate(Permission.lveBuddy, PermissionAction.Modify)
  const readOnly = cardState !== 'editing' && cardState !== 'saving'

  useEffect(() => {
    if (inView) {
      dispatch(fetchKeyvalues('leaveBuddy', 'leave', undefined, { strategy: 'when-empty' }))
    }
  }, [inView])

  useEffect(() => {
    if (inView) {
      if (employeeId) {
        dispatch(fetchEmLeaveBuddies(employeeId))
      }
    }
  }, [inView, employeeId])

  useEffect(() => {
    if (emLeaveBuddies.length > 0) {
      const emLeaveBuddyIds = emLeaveBuddies.map(elb => elb.leaveBuddyId)
      setFormData({ leaveBuddyIds: emLeaveBuddyIds })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [emLeaveBuddies])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(data => ({ ...data, ...updates }))
  }, [])

  const handleEdit = useCallback(() => {
    setCardState('editing')
    typeof onEdit === 'function' && onEdit()
  }, [onEdit])

  const handleSave = useCallback(async () => {
    if (employeeId) {
      setCardState('saving')

      typeof onSave === 'function' && onSave()

      let result: ActionResult | undefined
      try {
        result = await dispatch(updateEmLeaveBuddies(employeeId, formData))
      } catch {
        setCardState('editing')
      }

      if (result?.errors) {
        setCardState('editing')
      }

      if (!result?.errors) {
        setCardState(undefined)
      }
    }
  }, [employeeId, formData, onSave])

  const handleCancel = useCallback(() => {
    typeof onCancel === 'function' && onCancel()
    setCardState(undefined)

    if (emLeaveBuddies.length > 0) {
      const emLeaveBuddyIds = emLeaveBuddies.map(elb => elb.leaveBuddyId)
      setFormData({ leaveBuddyIds: emLeaveBuddyIds })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [emLeaveBuddies, onCancel])

  const cardBodyStyle = useMemo(
    () => ({ paddingBottom: employeeId || loading ? 6 : 24, paddingTop: canModify ? 6 : 24 }),
    [employeeId, canModify, loading]
  )

  return (
    <div ref={ref}>
      <EditableCard
        title="Leave buddy"
        style={cardStyle}
        bodyStyle={cardBodyStyle}
        state={canModify && !loading ? cardState : 'readonly'}
        formId="form-em-leave-buddy"
        onEdit={handleEdit}
        onSave={handleSave}
        onCancel={handleCancel}
      >
        <Row>
          <Col span={24}>
            <Form.Item label="Leave buddy">
              <LveKeyValues
                ref={focusRef}
                id="leaveBuddy"
                mode="multiple"
                allowClear={false}
                value={formData.leaveBuddyIds}
                showSearch
                readOnly={readOnly}
                onChange={(leaveBuddyIds: string[]) => {
                  handleFormDataChange({ leaveBuddyIds })
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </EditableCard>
    </div>
  )
}
