import { LtPolicyState, ILtPolicyMutate } from './lt-policy.state'

export const mapLtPolicyMutateToLtPolicyState = (ltPolicy: ILtPolicyMutate): LtPolicyState => ({
  id: ltPolicy.id,
  isSys: false,
  leaveTypeId: ltPolicy.leaveTypeId,
  requireConfirm: ltPolicy.requireConfirm,
  requireMonths: ltPolicy.requireMonths,
  citizen: ltPolicy.citizen,
  minPerTime: ltPolicy.minPerTime,
  maxPerTime: ltPolicy.maxPerTime,
  maxPerEmployment: ltPolicy.maxPerEmployment,
  forfeitAbsent: ltPolicy.forfeitAbsent,
  forfeitQuantity: ltPolicy.forfeitQuantity,
  forfeitPercentage: ltPolicy.forfeitPercentage,
  cfCode: ltPolicy.cfCode,
  cfValidityPeriod: ltPolicy.cfValidityPeriod,
  excessAction: ltPolicy.excessAction,
  excessValidityPeriod: ltPolicy.excessValidityPeriod
})
