import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { IdValue } from '~/types/common'

export const selectPayRecordPayRuns = createSelector(
  (state: StoreState) => state.payroll.payRecordPayRuns,
  payRecordPayRunsState =>
    memoize((employeeId: string): IdValue[] | undefined => {
      const payRuns = Object.values(payRecordPayRunsState[employeeId]?.entities || {}) as IdValue[]
      return payRuns
    })
)
