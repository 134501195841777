import React, { FC, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { Badge, Button, Tooltip } from '~/core-components'
import { dispatch } from '~/stores/store'
import { updateViewCriteria } from '../actions'
import { selectViewCriteriaCount } from '../selectors'
import { Screen, ViewCriteria } from '../types'
import { ViewCriteriaAdvancedDrawer } from './ViewCriteriaAdvancedDrawer'

interface ViewCriteriaButtonProps {
  screenCode: Screen
  viewId?: string
}

interface DrawerState {
  visible: boolean
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

export const ViewCriteriaButton: FC<ViewCriteriaButtonProps> = ({ screenCode, viewId }) => {
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const criteriaCount = useSelector(selectViewCriteriaCount)(screenCode, viewId)

  const handleApply = useCallback(
    async (criteria: ViewCriteria[]) => {
      if (!screenCode || !viewId) return
      await dispatch(updateViewCriteria(screenCode, viewId, { id: viewId, criteria }))
    },
    [screenCode, viewId]
  )

  const handleOpenDrawer = useCallback(() => {
    setDrawerState({ visible: true })
  }, [setDrawerState])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  return (
    <>
      <Tooltip title="Filter options">
        <Button onClick={handleOpenDrawer}>
          <i className="fal fa-bars-filter" />
          {criteriaCount > 0 && <Badge count={criteriaCount} />}
        </Button>
      </Tooltip>
      <ViewCriteriaAdvancedDrawer
        {...drawerState}
        screenCode={screenCode}
        viewId={viewId}
        onApply={handleApply}
        onClose={handleCloseDrawer}
      />
    </>
  )
}
