import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchMyCalViewAs } from '../../actions'
import { selectMyCalViewAs } from '../../selectors'
import { KeyValue } from '~/types/common'

export const useMyCalViewAs = (): [KeyValue[], boolean] => {
  const myCalViewAs = useSelector(selectMyCalViewAs)
  const loading = useSelector((state: StoreState) => state.my.myCalViewAsLoading)

  useEffect(() => {
    dispatch(fetchMyCalViewAs())
  }, [])

  return [myCalViewAs, loading]
}
