import { showSuccess, showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { apiAddFormulaDetail } from '../api/formula.api'
import { setFormulaDetail } from '../reducers'
import { IFormulaDetailInfo, FormulaDetailState } from '../types'

export const addFormulaDetail = (formulaId: string, formulaDetail: IFormulaDetailInfo): ThunkResult<void> => {
  return async (dispatch): Promise<ActionResult | undefined> => {
    if (!formulaId) return

    const { status, result, errors, message, errorData } = await apiAddFormulaDetail(formulaId, {
      formulaId,
      ...formulaDetail
    })
    if (status) {
      const newItem = { id: result.id, formulaId, ...formulaDetail, sequence: result.sequence } as FormulaDetailState
      dispatch(setFormulaDetail(newItem))
      showSuccess('Added')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
