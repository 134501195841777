import { replaceEmProjects, setEmProjectsLoading } from '../reducers'
import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { IEmProject } from '../types'
import { apiUpdateProjectEmByEmployee } from '~/features/attendance/api/project.api'

export const updateEmProjects = (employeeId: string, data: IEmProject): ThunkResult<void> => {
  return async dispatch => {
    if (!employeeId) return

    try {
      dispatch(setEmProjectsLoading({ employeeId, loading: true }))
      const { status, result, errors, message, errorData } = await apiUpdateProjectEmByEmployee(employeeId, data)

      if (status) {
        dispatch(replaceEmProjects({ employeeId, data: result }))
        showSuccess('Saved')
      } else {
        if (Object.keys(errors).length === 0) {
          showError(message, errorData)
        }
      }
      return { errors }
    } finally {
      dispatch(setEmProjectsLoading({ employeeId, loading: false }))
    }
  }
}
