import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { Form, Select, Text } from '~/core-components'
import { Col, EditableCard, EditableCardState, EmpKeyValues, Row, SysOptions } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { dispatch } from '~/stores/store'
import { Errors, ActionResult } from '~/types/store'
import { Delimiter, Permission, PermissionAction } from '~/constants'
import { LveCalendarConfigState } from '../../types'
import { useLveCalendarConfig } from '../../hooks'
import { updateLveCalendarConfig } from '../../actions'

interface LveCalendarConfigProps {}

export const EMPTY_FORM_DATA: LveCalendarConfigState = {
  leaveRecordCriteria: '',
  leaveRecordOperator: '',
  leaveRecordApprovalStatus: ''
}

const VISIBILITY_CRITERIA: Record<string, string> = {
  everyone: 'Everyone',
  company: 'Company'
}

const MATCHING_MODE: Record<string, string> = {
  and: 'Match all criteria',
  or: 'Match any criteria'
}

const descriptionStyle: CSSProperties = { display: 'block', fontSize: 13 }

export const LveCalendarConfig: FC<LveCalendarConfigProps> = () => {
  const [cardState, setCardState] = useState<EditableCardState>()
  const readOnly = cardState !== 'editing'
  const [formData, setFormData] = useState<LveCalendarConfigState>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const canModify = usePermissionGate(Permission.calendarConfig, PermissionAction.Modify)
  const [config, configLoading] = useLveCalendarConfig()

  useEffect(() => {
    if (config) {
      setFormData({
        leaveRecordCriteria: config.leaveRecordCriteria,
        leaveRecordOperator: config.leaveRecordOperator,
        leaveRecordApprovalStatus: config.leaveRecordApprovalStatus
      })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [config])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setErrors(undefined)
    setFormData(data => ({ ...data, ...updates }))
  }, [])

  const handleEdit = useCallback(() => {
    setCardState('editing')
  }, [])

  const handleSave = useCallback(async () => {
    setCardState('saving')
    setErrors(undefined)

    let result: ActionResult | undefined
    try {
      result = await dispatch(updateLveCalendarConfig(formData))
    } catch {
      setCardState('editing')
    }

    if (result?.errors) {
      setCardState('editing')
      setErrors(result.errors)
    }

    if (!result?.errors) {
      setCardState(undefined)
    }
  }, [formData])

  const handleCancel = useCallback(() => {
    setCardState(undefined)
    setErrors(undefined)

    if (config) {
      setFormData({
        leaveRecordCriteria: config.leaveRecordCriteria,
        leaveRecordOperator: config.leaveRecordOperator,
        leaveRecordApprovalStatus: config.leaveRecordApprovalStatus
      })
    }
  }, [config])

  return (
    <EditableCard
      title="Leave visibility"
      bodyStyle={{ paddingBottom: !readOnly ? 6 : 24, paddingTop: 6 }}
      loading={configLoading}
      state={canModify ? cardState : 'readonly'}
      onEdit={handleEdit}
      onSave={handleSave}
      onCancel={handleCancel}
    >
      <Row gutter={30}>
        <Col span={24} style={{ marginBottom: 20 }}>
          <Text type="secondary" style={descriptionStyle}>
            Choose the criteria for leave visibility across the organisation. Individuals who meet these criteria will
            have access to leave information of others with the same attributes (e.g., department, team, etc) as defined
            in visibility criteria.
          </Text>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Visibility criteria"
            validateStatus={errors?.leaveRecordCriteria ? 'error' : ''}
            help={errors?.leaveRecordCriteria}
            tooltip={{
              title: `The leave information displayed includes leave dates and approval status. Leave type is not visible to users with this access.`,
              icon: (
                <span>
                  <i className="fal fa-circle-info" />
                </span>
              )
            }}
          >
            <EmpKeyValues
              id="employmentConfig"
              mode="multiple"
              value={formData.leaveRecordCriteria ? formData.leaveRecordCriteria.split(Delimiter.pipe) : undefined}
              readOnly={readOnly}
              children={Object.keys(VISIBILITY_CRITERIA).map(x => (
                <Select.Option key={x} value={x}>
                  {VISIBILITY_CRITERIA[x]}
                </Select.Option>
              ))}
              onChange={(value: string[]) => handleFormDataChange({ leaveRecordCriteria: value.join(Delimiter.pipe) })}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Matching mode"
            validateStatus={errors?.leaveRecordOperator ? 'error' : ''}
            help={errors?.leaveRecordOperator}
          >
            <Select
              value={formData.leaveRecordOperator}
              readOnly={readOnly}
              onChange={(leaveRecordOperator: string) => handleFormDataChange({ leaveRecordOperator })}
            >
              {Object.keys(MATCHING_MODE).map(x => (
                <Select.Option key={x} value={x}>
                  {MATCHING_MODE[x]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Approval status"
            validateStatus={errors?.leaveRecordApprovalStatus ? 'error' : ''}
            help={errors?.leaveRecordApprovalStatus}
          >
            <SysOptions
              type="lve_approval_status"
              mode="multiple"
              value={
                formData.leaveRecordApprovalStatus
                  ? formData.leaveRecordApprovalStatus.split(Delimiter.pipe)
                  : undefined
              }
              readOnly={readOnly}
              onChange={(value: string[]) =>
                handleFormDataChange({ leaveRecordApprovalStatus: value.join(Delimiter.pipe) })
              }
            />
          </Form.Item>
        </Col>
      </Row>
    </EditableCard>
  )
}
