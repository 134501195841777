import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment-timezone'
import { RangeValue } from 'rc-picker/lib/interface.d'
import { Checkbox, CheckboxChangeEvent, Form, Input } from '~/core-components'
import { Col, DrawerForm, ErrorDisplay, Row } from '~/components'
import { SCH_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { useFocus } from '~/hooks'
import { ActionResult, Errors } from '~/types/store'
import { copyScheduleRecords } from '../../../actions'
import { ICopyScheduleRecord } from '../../../types'
import './CopyScheduleRecordDrawer.less'

export interface CopyScheduleRecordDrawerProps {
  visible: boolean
  startDate?: string
  employeeIds?: string[]
  onClose: () => void
}

interface FormData extends ICopyScheduleRecord {}

const EMPTY_FORM_DATA: FormData = {
  startDate: '',
  endDate: '',
  targetStartDate: '',
  targetEndDate: '',
  employeeIds: [],
  overwrite: true
}

export const CopyScheduleRecordDrawer: FC<CopyScheduleRecordDrawerProps> = ({
  visible,
  startDate = '',
  employeeIds = [],
  onClose
}) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)
  const [focusRef, setFocus] = useFocus(true)
  const [errors, setErrors] = useState<Errors>()
  const history = useHistory()

  const endDate = useMemo(() => {
    return moment(startDate).add(1, 'week').add(-1, 'day').format('YYYY-MM-DD')
  }, [startDate])

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
    setFormData(EMPTY_FORM_DATA)
  }, [visible, setFocus])

  useEffect(() => {
    if (visible) {
      setFormData(formData => ({
        ...formData,
        startDate,
        endDate,
        employeeIds,
        targetStartDate: moment(startDate).add(1, 'week').format('YYYY-MM-DD'),
        targetEndDate: moment(endDate).add(1, 'week').format('YYYY-MM-DD')
      }))
    }
  }, [visible, startDate, endDate, employeeIds])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      result = await dispatch(copyScheduleRecords(formData))
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      history.replace(
        SCH_ROUTES.records.replace(':start', moment(formData.targetStartDate).startOf('isoWeek').format('YYYY-MM-DD'))
      )
      typeof onClose === 'function' && onClose()
    }
  }, [formData, history, onClose])

  return (
    <DrawerForm
      open={visible}
      title="Copy schedule"
      okText="Copy shift"
      onClose={onClose}
      confirmLoading={loading}
      width={500}
      formId="form-copy-schedule-record"
      className="copy-schedule-record-drawer"
      extras={
        <Checkbox
          checked={formData.overwrite}
          onChange={(event: CheckboxChangeEvent) => handleFormDataChange({ overwrite: event.target.checked })}
        >
          Overwrite existing shift
        </Checkbox>
      }
    >
      <Form id="form-copy-schedule-record" layout="horizontal" onFinish={handleOk}>
        <Row>
          <Col span={24}>
            <Form.Item label="Copy to">
              <Input.DateRange
                ref={focusRef}
                allowClear={false}
                value={[
                  formData.targetStartDate ? moment(formData.targetStartDate) : null,
                  formData.targetEndDate ? moment(formData.targetEndDate) : null
                ]}
                onCalendarChange={(dates: RangeValue<moment.Moment>) => {
                  const targetStartDate = dates && dates[0] ? dates[0].format('YYYY-MM-DD') : null
                  const targetEndDate = dates && dates[1] ? dates[1].format('YYYY-MM-DD') : null
                  handleFormDataChange({ targetStartDate, targetEndDate })
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <ErrorDisplay errors={errors} />
    </DrawerForm>
  )
}
