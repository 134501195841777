import React, { CSSProperties, FC } from 'react'
import { Card, Descriptions } from '~/core-components'
import { useEmPublic } from '~/features/employee'
import { useMyEmployee } from '~/features/my/hooks'
import { formatMoney } from '~/utils'

interface MyPayInfoProps {}

const cardStyle: CSSProperties = { minHeight: 280 }

export const MyPayInfo: FC<MyPayInfoProps> = () => {
  const [me] = useMyEmployee()
  const myId = me.id
  const [myEm] = useEmPublic(myId)
  const showPrimaryBank = myEm?.payMethod === 'bank'
  const showSecondaryBank = myEm?.payMethod === 'bank' && !!myEm?.bankName2

  return (
    <Card title="Payment information" style={cardStyle}>
      <Descriptions colon={false} column={1}>
        <Descriptions.Item label="Payment method">{myEm?.payMethodName || '-'}</Descriptions.Item>
        {showPrimaryBank && (
          <>
            {showSecondaryBank && <Descriptions.Item>Primary</Descriptions.Item>}
            <Descriptions.Item label="Bank">{myEm?.bankName || '-'}</Descriptions.Item>
            <Descriptions.Item label="Branch">{myEm?.bankBranchCode || '-'}</Descriptions.Item>
            <Descriptions.Item label="Account no">{myEm?.bankAccountNo || '-'}</Descriptions.Item>
            <Descriptions.Item label="Beneficiary name">{myEm?.bankAccountName || '-'}</Descriptions.Item>
          </>
        )}
        {showPrimaryBank && showSecondaryBank && (
          <>
            <Descriptions.Item>Secondary</Descriptions.Item>
            <Descriptions.Item label="Bank">{myEm?.bankName2 || '-'}</Descriptions.Item>
            <Descriptions.Item label="Branch">{myEm?.bankBranchCode2 || '-'}</Descriptions.Item>
            <Descriptions.Item label="Account no">{myEm?.bankAccountNo2 || '-'}</Descriptions.Item>
            <Descriptions.Item label="Beneficiary name">{myEm?.bankAccountName2 || '-'}</Descriptions.Item>
            {myEm?.bankAllocation === 'percent' && (
              <Descriptions.Item label="Allocation by percentage">
                {`${myEm?.bankPercentage2}%` || '-'}
              </Descriptions.Item>
            )}
            {myEm?.bankAllocation === 'amount' && (
              <Descriptions.Item label="Allocation by amount">
                {formatMoney(myEm?.bankAmount2) || '-'}
              </Descriptions.Item>
            )}
          </>
        )}
      </Descriptions>
    </Card>
  )
}
