import { replaceXeroAccounts, setXeroAccountsLoading } from '../reducers'
import { apiGetXeroAccounts } from '../api/xero.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchXeroAccounts = (
  tenantId?: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!tenantId) return

    const loading = getState().payroll.xeroAccountsLoading[tenantId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().payroll.xeroAccounts[tenantId]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setXeroAccountsLoading({ tenantId, loading: true }))
      const { result, status } = await apiGetXeroAccounts(tenantId)
      if (status) {
        dispatch(replaceXeroAccounts({ tenantId, data: result }))
      }
    } finally {
      dispatch(setXeroAccountsLoading({ tenantId, loading: false }))
    }
  }
}
