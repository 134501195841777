import moment from 'moment-timezone'
import { setMyLeaveRecordComment } from '../../reducers'
import { showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { apiUpdateMyLeaveRecordComment } from '../../api/ssleave-record-comment.api'
import { SSLeaveRecordCommentState } from '../../types'

export const updateLeaveRecordComment = (leaveRecordId: string, id: string, comment: string): ThunkResult<void> => {
  return async (dispatch, getState): Promise<ActionResult> => {
    const { status, errors, message, errorData } = await apiUpdateMyLeaveRecordComment(leaveRecordId, id, comment)
    if (status) {
      const currentComment = getState().my.myLeaveRecordComments[leaveRecordId]?.entities[id]
      const updated = {
        ...(currentComment as SSLeaveRecordCommentState),
        ...{
          comment: comment,
          updatedDate: moment().format('YYYY-MM-DDTHH:mm:ssZ')
        }
      }

      dispatch(setMyLeaveRecordComment({ id: leaveRecordId, data: updated }))
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
