import { replacePayTrans, setPayTranLoading } from '../reducers'
import { apiGetPayTransSg } from '../api/payrecord-sg.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchPayTransSg = (recordId: string, options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!recordId) return

    const loading = getState().payroll.payTransLoading[recordId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().payroll.payTrans[recordId]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setPayTranLoading({ recordId, loading: true }))
      const { result, status } = await apiGetPayTransSg(recordId)
      if (status) {
        dispatch(replacePayTrans({ recordId, data: result }))
      }
    } finally {
      dispatch(setPayTranLoading({ recordId, loading: false }))
    }
  }
}
