import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'

export const selectEmSalaryByDate = createSelector(
  (state: StoreState) => state.employee.emSalaries,
  emSalariesState =>
    memoize(
      (employeeId: string, refreshDate: string) => {
        const salary = Object.values(emSalariesState[employeeId]?.entities || {})
          .filter(ems => ems?.employeeId === employeeId && (ems?.startDate || '') <= refreshDate)
          .sort((a, b) => {
            const aStartDate = new Date(a!.startDate).getTime()
            const bStartDate = new Date(b!.startDate).getTime()
            return bStartDate - aStartDate
          })
        return salary && salary.length > 0 ? salary[0] : undefined
      },
      (employeeId: string, refreshDate: string) => `${employeeId}|${refreshDate}`
    )
)
