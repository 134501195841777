import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { TeConfigState } from '../types'

export const selectTeConfigs = createSelector(
  (state: StoreState) => state.attendance.teConfigs,
  (teConfigState): TeConfigState[] => {
    const configs = Object.values(teConfigState?.entities || {}) as TeConfigState[]
    return configs.sort((a, b) =>
      a.isShownInDaily === b.isShownInDaily ||
      a.isShownInShift === b.isShownInShift ||
      a.isShownInShiftRole === b.isShownInShiftRole
        ? a.code?.localeCompare(b.code)
        : a.isShownInDaily || a.isShownInShift || a.isShownInShiftRole
        ? -1
        : 1
    )
  }
)
