import { setTimeLogsView, setTimeLogsViewLoading } from '../reducers'
import { apiGetTimeLogsView } from '../api/time-log.api'
import { ThunkResult } from '~/types/store'
import { DateRange, DEFAULT_FETCH_OPTIONS, FetchOptions, Pagination } from '~/types/common'

export const fetchTimeLogsView = (
  viewId: string,
  pagination: Pagination,
  startDate: string | null,
  endDate: string | null,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!viewId) return

    const loading = getState().attendance.timeLogsViewLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().attendance.timeLogsView?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setTimeLogsViewLoading(true))
      const { result, status } = await apiGetTimeLogsView(viewId, {
        ...pagination,
        ...({ startDate, endDate } as DateRange)
      })
      if (status) {
        dispatch(setTimeLogsView(result))
      }
    } finally {
      dispatch(setTimeLogsViewLoading(false))
    }
  }
}
