import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { PayTranEntryState } from '../types'

export const selectPayTranEntries = createSelector(
  (state: StoreState) => state.payroll.payTranEntries,
  payTranEntriesState =>
    memoize((batchId: string): PayTranEntryState[] => {
      return (Object.values(payTranEntriesState[batchId || '']?.entities || {}) as PayTranEntryState[]).sort((a, b) => {
        const aStartDate = new Date(a!.startDate).getTime()
        const bStartDate = new Date(b!.startDate).getTime()
        const aEndDate = new Date(a!.endDate).getTime()
        const bEndDate = new Date(b!.endDate).getTime()

        if (bEndDate === 0 && aEndDate === 0) {
          return aStartDate - bStartDate
        } else {
          if (bEndDate === 0) {
            return 1
          } else if (aEndDate === 0) {
            return -1
          } else {
            return aEndDate - bEndDate
          }
        }
      })
    })
)
