import React, { CSSProperties, FocusEvent, forwardRef, useCallback, useEffect, useState } from 'react'
import { Input } from '../../core-components/Input'
import { NumberProps } from '../../core-components/Input/Number'

interface PercentInputProps extends Omit<NumberProps<string>, 'value' | 'onChange' | 'min' | 'max'> {
  value?: number | null
  onChange?: (value: number | null) => void
}

const inputNumberStyle: CSSProperties = { width: 120 }

const PercentInputInternal = ({ value, onChange, ...props }: PercentInputProps, ref: React.Ref<any>) => {
  const [isFocus, setIsFocus] = useState(false)
  const formatted = `${value}%`
  const [internalValue, setInternalValue] = useState(formatted)

  useEffect(() => {
    if (isFocus) return
    setInternalValue(formatted)
  }, [isFocus, formatted])

  const handleFocus = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      setIsFocus(true)
      setInternalValue(`${value}`)
      setTimeout(() => event?.target?.select(), 0)
    },
    [value]
  )

  const handleBlur = useCallback(() => {
    setIsFocus(false)
    setInternalValue(formatted)
  }, [formatted])

  const handleChange = useCallback(
    (value: string | null) => {
      setInternalValue(`${value}`)
      typeof onChange === 'function' && onChange(Number(value))
    },
    [onChange]
  )

  return (
    <Input.Number<string>
      ref={ref}
      style={inputNumberStyle}
      min="0"
      max="100"
      precision={0}
      value={internalValue}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      stringMode
      {...props}
    />
  )
}

export const PercentInput = forwardRef(PercentInputInternal)
