import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { IRule } from '../types'

const baseUrl = getBaseUrl('/employee/rule')

export const apiGetRules = (referenceId: string) => request<IRule[]>('get', `${baseUrl}/${referenceId}`)

export const apiUpdateRule = (id: string, rule: Operation[]) => request<void>('patch', `${baseUrl}/${id}`, rule)

export const apiSetupRule = (referenceId: string, screenCode: string) =>
  request<void>('post', `${baseUrl}/${referenceId}/${screenCode}/setup`)
