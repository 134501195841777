import React, { forwardRef } from 'react'
import { default as AntDatePicker, DatePickerProps as AntDatePickerProps } from 'antd/lib/date-picker'
import 'antd/es/date-picker/style'

export type DateProps = AntDatePickerProps & {}

const DateInternal = (props: DateProps, ref: React.Ref<any>) => (
  <AntDatePicker
    ref={ref}
    picker="date"
    format="DD/MM/YYYY"
    placeholder="dd/mm/yyyy"
    allowClear={!props.inputReadOnly}
    popupStyle={props.inputReadOnly ? { display: 'none' } : {}}
    {...props}
  />
)

export const Date = forwardRef(DateInternal)
