import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { Screen, ViewSchemaState } from '../types'
import { fetchViewSchema } from '../actions'
import { FetchStrategy } from '~/types/common'

export const useViewSchema = (
  screenCode: Screen,
  viewId?: string,
  fetchStrategy: FetchStrategy = 'always'
): [ViewSchemaState | undefined, boolean] => {
  const schema = useSelector((state: StoreState) =>
    viewId ? state.selection.viewSchema[screenCode]?.entities[viewId] : undefined
  )
  const loading = useSelector((state: StoreState) => {
    const viewSchemaLoading = state.selection.viewSchemaLoading[screenCode]
    return (viewSchemaLoading && viewSchemaLoading[viewId || '']) || false
  })

  useEffect(() => {
    if (viewId) dispatch(fetchViewSchema(screenCode, viewId, { strategy: fetchStrategy }))
  }, [screenCode, viewId, fetchStrategy])

  return [schema, loading]
}
