import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { BasePath, FetchStrategy } from '~/types/common'
import { fetchReportTemplates } from '../actions'
import { selectFirstOwnedReportTemplate } from '../selectors'
import { ReportCode, ReportTemplateState } from '../types'

export const useFirstReportTemplate = (
  basePath: BasePath,
  reportCode: ReportCode,
  fetchStrategy: FetchStrategy = 'always'
): [ReportTemplateState | undefined, boolean] => {
  const template = useSelector(selectFirstOwnedReportTemplate)(reportCode)
  const loading = useSelector((state: StoreState) => state.report.reportTemplatesLoading[reportCode] || false)
  const refetch = useSelector((state: StoreState) => state.report.reportTemplatesRefetch)

  useEffect(() => {
    dispatch(fetchReportTemplates(basePath, reportCode, { strategy: fetchStrategy }))
  }, [basePath, reportCode, fetchStrategy, refetch])

  return [template, loading]
}
