import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import persistReducer from 'redux-persist/es/persistReducer'
import { KeyValue } from '~/types/common'
import { StoreState } from '~/types/store'
import {
  IamRootState,
  LoginState,
  LoginPermissionState,
  IsEmployeeState,
  MyJwtState,
  MyTenantState,
  SysPermissionState,
  iamPersistConfig,
  TenantConfigState
} from './types'

const loginsAdapter = createEntityAdapter<LoginState>()
const loginPermissionsAdapter = createEntityAdapter<LoginPermissionState>()
const myTenantsAdapter = createEntityAdapter<MyTenantState>({
  selectId: model => model.tenantCode
})
const sysPermissionsAdapter = createEntityAdapter<SysPermissionState>()
const isEmployeesAdapter = createEntityAdapter<IsEmployeeState>({
  selectId: model => model.loginCode
})
const tenantConfigsAdapter = createEntityAdapter<TenantConfigState>({ selectId: model => model.sysConfigCode })
const keyvaluesAdapter = createEntityAdapter<KeyValue>({ selectId: model => model.key })

const loginsInitialState = loginsAdapter.getInitialState()
const loginPermissionsInitialState = loginPermissionsAdapter.getInitialState()
const myTenantsInitialState = myTenantsAdapter.getInitialState()
const sysPermissionsInitialState = sysPermissionsAdapter.getInitialState()
const isEmployeesInitialState = isEmployeesAdapter.getInitialState()
const tenantConfigsInitialState = tenantConfigsAdapter.getInitialState()
const keyvaluesInitialState = keyvaluesAdapter.getInitialState()

const initialState: IamRootState = {
  logins: loginsInitialState,
  loginsLoading: false,
  loginPermissions: {},
  loginPermissionsLoading: false,
  myJwt: undefined,
  myTenantPhotoId: undefined,
  myTenants: myTenantsInitialState,
  myTenantsLoading: false,
  sysPermissions: sysPermissionsInitialState,
  sysPermissionsLoading: false,
  isEmployees: isEmployeesInitialState,
  isEmployeesLoading: false,
  tenantConfigs: tenantConfigsInitialState,
  tenantConfigsLoading: false,
  tenantConfigOptions: {},
  tenantConfigOptionsLoading: {}
}

const iamSlice = createSlice({
  name: 'iam',
  initialState,
  reducers: {
    setLoginsLoading: (state, action: PayloadAction<boolean>) => {
      state.loginsLoading = action.payload
    },
    setLogin: (state, action: PayloadAction<LoginState>) => {
      loginsAdapter.upsertOne(state.logins, action.payload)
    },
    replaceLogins: (state, action: PayloadAction<LoginState[]>) => {
      loginsAdapter.setAll(state.logins, action.payload)
    },
    removeLogin: (state, action: PayloadAction<string>) => {
      loginsAdapter.removeOne(state.logins, action.payload)
    },
    setLoginPermissionsLoading: (state, action: PayloadAction<{ loginId: string; loading: boolean }>) => {
      const { loginId, loading } = action.payload
      state.loginPermissions[loginId] = state.loginPermissions[loginId] || loginPermissionsInitialState
      state.loginPermissionsLoading = loading
    },
    replaceLoginPermissions: (state, action: PayloadAction<{ loginId: string; data: LoginPermissionState[] }>) => {
      const { loginId, data } = action.payload
      loginPermissionsAdapter.setAll(state.loginPermissions[loginId], data)
    },
    replaceMyJwt: (state, action: PayloadAction<MyJwtState | undefined>) => {
      state.myJwt = action.payload
    },
    replaceMyTenantPhotoId: (state, action: PayloadAction<string>) => {
      state.myTenantPhotoId = action.payload
    },
    replaceMyTenants: (state, action: PayloadAction<MyTenantState[]>) => {
      myTenantsAdapter.setAll(state.myTenants, action.payload)
    },
    setMyTenant: (state, action: PayloadAction<MyTenantState>) => {
      myTenantsAdapter.upsertOne(state.myTenants, action.payload)
    },
    setMyTenantsLoading: (state, action: PayloadAction<boolean>) => {
      state.myTenantsLoading = action.payload
    },
    setSysPermissionsLoading: (state, action: PayloadAction<boolean>) => {
      state.sysPermissionsLoading = action.payload
    },
    replaceSysPermissions: (state, action: PayloadAction<SysPermissionState[]>) => {
      sysPermissionsAdapter.setAll(state.sysPermissions, action.payload)
    },
    setIsEmployee: (state, action: PayloadAction<IsEmployeeState>) => {
      isEmployeesAdapter.upsertOne(state.isEmployees, action.payload)
    },
    removeIsEmployee: (state, action: PayloadAction<string>) => {
      isEmployeesAdapter.removeOne(state.isEmployees, action.payload)
    },
    setIsEmployeesLoading: (state, action: PayloadAction<boolean>) => {
      state.isEmployeesLoading = action.payload
    },
    replaceTenantConfigs: (state, action: PayloadAction<TenantConfigState[]>) => {
      tenantConfigsAdapter.setAll(state.tenantConfigs, action.payload)
    },
    setTenantConfigsLoading: (state, action: PayloadAction<boolean>) => {
      state.tenantConfigsLoading = action.payload
    },
    setTenantConfigOptionsLoading: (state, action: PayloadAction<{ code: string; loading: boolean }>) => {
      const { code, loading } = action.payload
      state.tenantConfigOptions[code] = state.tenantConfigOptions[code] || keyvaluesInitialState
      state.tenantConfigOptionsLoading[code] = loading
    },
    replaceTenantConfigOptions: (state, action: PayloadAction<{ code: string; data: KeyValue[] }>) => {
      const { code, data } = action.payload
      keyvaluesAdapter.setAll(state.tenantConfigOptions[code], data)
    }
  }
})

export const {
  setLoginsLoading,
  setLogin,
  replaceLogins,
  removeLogin,
  replaceLoginPermissions,
  setLoginPermissionsLoading,
  replaceMyJwt,
  replaceMyTenantPhotoId,
  replaceMyTenants,
  setMyTenant,
  setMyTenantsLoading,
  replaceSysPermissions,
  setSysPermissionsLoading,
  setIsEmployee,
  removeIsEmployee,
  setIsEmployeesLoading,
  replaceTenantConfigs,
  setTenantConfigsLoading,
  setTenantConfigOptionsLoading,
  replaceTenantConfigOptions
} = iamSlice.actions

export const { selectAll: selectLogins, selectById: selectLoginById } = loginsAdapter.getSelectors(
  (state: StoreState) => state.iam.logins
)

export const { selectAll: selectSysPermissions } = sysPermissionsAdapter.getSelectors(
  (state: StoreState) => state.iam.sysPermissions
)

export const iamReducers = { iam: persistReducer<IamRootState>(iamPersistConfig, iamSlice.reducer) }
