import React, { forwardRef } from 'react'
import { KeyValues, KeyValuesProps } from './KeyValues'

interface PaySgKeyValuesProps extends Omit<KeyValuesProps, 'basePath'> {}

const PaySgKeyValuesInternal = (props: PaySgKeyValuesProps, ref: React.Ref<any>) => (
  <KeyValues ref={ref} basePath="payroll-sg" {...props} />
)

export const PaySgKeyValues = forwardRef(PaySgKeyValuesInternal)
