import { setCalendar, setCalendarsLoading } from '../reducers'
import { apiGetCalendar } from '../api/calendar.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchCalendar = (id: string, options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const loading = getState().attendance.calendarsLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().attendance.calendars?.entities[id]
      if (hasData) return
    }

    try {
      dispatch(setCalendarsLoading(true))
      const { result, status } = await apiGetCalendar(id)
      if (status) {
        dispatch(setCalendar(result))
      }
    } finally {
      dispatch(setCalendarsLoading(false))
    }
  }
}
