import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { apiUpdateEmployeePersonal } from '../api/employee.api'
import { EmployeeState, IEmployeePersonal } from '../types'
import { setEmployee } from '../reducers'
import pick from 'lodash/pick'

export const updateEmployeePersonal = <T extends IEmployeePersonal>(
  id: string,
  before: T,
  request: T
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const after = { ...before, ...request } as T
    const patch = generateJsonPatch<T>(before, after)
    const { result, status, errors, message, errorData } = await apiUpdateEmployeePersonal(id, patch)

    if (status) {
      const employee = getState().employee.employees.entities[id] as EmployeeState
      const refreshFields = pick(result, 'age', 'retireDate')
      dispatch(setEmployee({ ...employee, ...after, ...refreshFields }))
      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
