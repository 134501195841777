import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { ProjectEmState } from '../types'

export const selectProjectEm = createSelector(
  (state: StoreState) => state.attendance.projectEm,
  projectEmState =>
    memoize((projectId: string) => {
      return (Object.values(projectEmState[projectId]?.entities || {}) as ProjectEmState[]) || []
    })
)
