import React, { CSSProperties, FC, memo, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Checkbox, Drawer, Form, SecondaryText, Space, Tag, UploadFile } from '~/core-components'
import { Col, Row, UploadFileSelectorAuth } from '~/components'
import { EmAddressRequestState } from '../../../../types'
import { dispatch } from '~/stores/store'
import { ActionResult } from '~/types/store'
import { formatLevelUnit, selectKeyvaluesById } from '~/features/master'
import { approveEmAddressRequest, rejectEmAddressRequest } from '~/features/employee/actions'
import { EmRequestApprovalBtn } from '../EmRequestApprovalBtn'
import { getBaseUrl } from '~/utils'

export interface EmAddressRequestApprovalProps {
  data?: EmAddressRequestState
  visible: boolean
  onClose: () => void
}

const PendingTag = memo(() => <Tag type="secondary">pending</Tag>)

const currenTextStyle: CSSProperties = { fontSize: 12 }
const checkboxStyle: CSSProperties = { marginLeft: 10 }

const EMPTY_ATTACHMENT: UploadFile = {
  uid: '',
  name: '',
  size: 0,
  type: ''
}

const baseUrl = getBaseUrl('/employee')

export const EmAddressRequestApprovalDrawer: FC<EmAddressRequestApprovalProps> = ({ data, visible, onClose }) => {
  const countries = useSelector(selectKeyvaluesById)('country')
  const [attachment, setAttachment] = useState<UploadFile>()

  const isNew = data?.recordStatus === 'new_request'
  const isDiffIsMain = !isNew && data?.isMain !== data?.isMainNew
  const isDiffStartDate = !isNew && data?.startDate !== data?.startDateNew
  const isDiffBlock = !isNew && data?.block !== data?.blockNew
  const isDiffStreet = !isNew && data?.street !== data?.streetNew
  const isDiffLevel = !isNew && data?.level !== data?.levelNew
  const isDiffUnit = !isNew && data?.unit !== data?.unitNew
  const isDiffCity = !isNew && data?.city !== data?.cityNew
  const isDiffState = !isNew && data?.state !== data?.stateNew
  const isDiffPostalCode = !isNew && data?.postalCode !== data?.postalCodeNew
  const isDiffCountryCode = !isNew && data?.countryCode !== data?.countryCodeNew

  useEffect(() => {
    if (data && data.attachmentId) {
      setAttachment({
        uid: data.attachmentId,
        name: data.attachmentName || '',
        size: data.attachmentSize || 0,
        type: data.attachmentFormat || '',
        url: `${baseUrl}/employee/request/address/${data.id}/downloadfile`
      })
    }
  }, [data])

  const handleApproval = useCallback(
    async (action: 'approve' | 'reject') => {
      let result: ActionResult | undefined

      if (data) {
        if (action === 'approve') {
          result = await dispatch(approveEmAddressRequest(data.employeeId, data.id))
        } else if (action === 'reject') {
          result = await dispatch(rejectEmAddressRequest(data.employeeId, data.id))
        }
      }

      if (!result?.errors) {
        typeof onClose === 'function' && onClose()
      }
    },
    [onClose, data]
  )

  const handleCloseDrawer = useCallback(() => {
    setAttachment(EMPTY_ATTACHMENT)
    typeof onClose === 'function' && onClose()
  }, [onClose])

  const handleFileChange = useCallback(() => {}, [])

  if (!data) return null

  return (
    <Drawer
      open={visible}
      title={<Space>Address request approval {isNew && <Tag type="secondary">new (pending)</Tag>}</Space>}
      onClose={() => handleCloseDrawer()}
      width={700}
      className="em-address-request-approval-drawer"
      footer={<EmRequestApprovalBtn onApproval={handleApproval} />}
    >
      <Form id="form-em-address-request-approval">
        <Row>
          <Col flex="160px">
            <Form.Item label={<Space>Effective date {isDiffStartDate && <PendingTag />}</Space>}>
              <div>{data.startDateNew ? moment(data.startDateNew).format('DD MMM YYYY') : '-'}</div>
              {isDiffStartDate && (
                <SecondaryText style={currenTextStyle}>
                  Current: {data.startDate ? moment(data.startDate).format('DD MMM YYYY') : '-'}
                </SecondaryText>
              )}
            </Form.Item>
          </Col>
          <Col flex={1}>
            <Form.Item label=" ">
              <Checkbox checked={data.isMainNew} style={checkboxStyle} readOnly={true}>
                {<Space>Main address {isDiffIsMain && <PendingTag />}</Space>}
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={15}>
          <Col span={5}>
            <Form.Item label={<Space>Block no. {isDiffBlock && <PendingTag />}</Space>}>
              <div>{data.blockNew || '-'}</div>
              {isDiffBlock && <SecondaryText style={currenTextStyle}>Current: {data.block || '-'}</SecondaryText>}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={<Space>Street name {isDiffStreet && <PendingTag />}</Space>}>
              <div>{data.streetNew || '-'}</div>
              {isDiffStreet && <SecondaryText style={currenTextStyle}>Current: {data.street || '-'}</SecondaryText>}
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item label={<Space>Floor - Unit no. {(isDiffLevel || isDiffUnit) && <PendingTag />}</Space>}>
              <div>{formatLevelUnit(data.levelNew, data.unitNew)}</div>
              {(isDiffLevel || isDiffUnit) && (
                <SecondaryText style={currenTextStyle}>Current: {formatLevelUnit(data.level, data.unit)}</SecondaryText>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col span={12}>
            <Form.Item label={<Space>City {isDiffCity && <PendingTag />}</Space>}>
              <div>{data.cityNew || '-'}</div>
              {isDiffCity && <SecondaryText style={currenTextStyle}>Current: {data.city || '-'}</SecondaryText>}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={<Space>State {isDiffState && <PendingTag />}</Space>}>
              <div>{data.stateNew || '-'}</div>
              {isDiffState && <SecondaryText style={currenTextStyle}>Current: {data.state || '-'}</SecondaryText>}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col span={12}>
            <Form.Item label={<Space>Postal code {isDiffPostalCode && <PendingTag />}</Space>}>
              <div>{data.postalCodeNew || '-'}</div>
              {isDiffPostalCode && (
                <SecondaryText style={currenTextStyle}>Current: {data.postalCode || '-'}</SecondaryText>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={<Space>Country {isDiffCountryCode && <PendingTag />}</Space>}>
              <div>{countries[data.countryCodeNew]?.value || '-'}</div>
              {isDiffCountryCode && (
                <SecondaryText style={currenTextStyle}>
                  Current: {countries[data.countryCode]?.value || '-'}
                </SecondaryText>
              )}
            </Form.Item>
          </Col>
        </Row>
        {attachment?.uid && (
          <Row>
            <Col span={24}>
              <Form.Item label="Attachment">
                <UploadFileSelectorAuth
                  multiple={false}
                  accept=".gif,.png,.jpeg,.jpg"
                  fileList={attachment ? [attachment] : []}
                  listType="picture-card"
                  disabled={true}
                  onChange={handleFileChange}
                  withCache={false}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    </Drawer>
  )
}
