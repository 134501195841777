import React, { createRef, CSSProperties, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Space, SecondaryLink, Tooltip } from '~/core-components'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { CriteriaOptions, RefreshCriteriaOptionsButton } from './components/CriteriaOptions'
import { ViewCriteriaSimpleDrawer } from './ViewCriteriaSimpleDrawer'
import { fetchViewSchema } from '../actions'
import { selectViewCriteriaSimple } from '../selectors'
import { ICriteriaKeyValue, Screen, ViewCriteria } from '../types'
import { mapCriteriaKeyValueToViewCriteria, mapViewCriteriaToCriteriaKeyValue } from '../types/view.mapper'
import { useFetchSysCriteria } from '../hooks'
import './ViewCriteriaSimple.less'

interface ViewCriteriaSimpleProps {
  screenCode: Screen
  viewId?: string
  onApply: (criteria: ViewCriteria[]) => void
  label?: string
}

interface CriteriaDrawerState {
  visible: boolean
  viewId: string
}

const DEFAULT_CRITERIA_DRAWER_STATE: CriteriaDrawerState = { visible: false, viewId: '' }

const selectCriteriaStyle: CSSProperties = { minWidth: 80 }
const buttonsGroupStyle: CSSProperties = { padding: 10, justifyContent: 'flex-end', width: '100%' }

export const ViewCriteriaSimple: FC<ViewCriteriaSimpleProps> = ({
  screenCode,
  viewId,
  onApply,
  label = 'Filter options'
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [changed, setChanged] = useState(false)
  const [length, setLength] = useState(0)
  const [criteriaValues, setCriteriaValues] = useState<ICriteriaKeyValue>()
  const criteria = useSelector((state: StoreState) => state.selection.sysCriteriaFields[screenCode])
  const viewCriteria = useSelector(selectViewCriteriaSimple)(screenCode, viewId)
  const criRefs = useRef<any[]>([])
  const btnRef = useRef<any>()
  const [criteriaDrawerState, setCriteriaDrawerState] = useState<CriteriaDrawerState>(DEFAULT_CRITERIA_DRAWER_STATE)
  useFetchSysCriteria(screenCode)
  const showClear = useMemo(() => Object.values(criteriaValues || []).some(v => v.length > 0), [criteriaValues])

  // Get the count of criteria values
  useEffect(() => {
    setLength(criteriaValues ? Object.keys(criteriaValues).length : 0)
  }, [criteriaValues])

  // Creating array of refs for criteria
  useEffect(() => {
    if (criRefs.current.length !== length) {
      Array(length)
        .fill('')
        .forEach((_, index) => (criRefs.current[index] = criRefs.current[index] || createRef()))
    }
  }, [length])

  useEffect(() => {
    if (viewCriteria) {
      const mapped = mapViewCriteriaToCriteriaKeyValue(viewCriteria)
      setChanged(false)
      setCriteriaValues(mapped)
    }
  }, [screenCode, viewCriteria, setCriteriaValues, setChanged])

  // Reset criteria values based on view criteria from redux
  const reset = useCallback(() => {
    if (viewCriteria) {
      const mapped = mapViewCriteriaToCriteriaKeyValue(viewCriteria)
      setChanged(false)
      setCriteriaValues(mapped)
    }
  }, [viewCriteria, setCriteriaValues, setChanged])

  // On initialize, reset
  useEffect(() => reset(), [reset])

  // Fetch view schema if empty
  useEffect(() => {
    if (viewId) {
      dispatch(fetchViewSchema(screenCode, viewId, { strategy: 'when-empty' }))
    }
  }, [screenCode, viewId])

  // Invoke apply event for applying criteria
  const raiseOnApplyEvent = useCallback(
    (criteriaValues: ICriteriaKeyValue) => {
      if (typeof onApply === 'function') {
        onApply(mapCriteriaKeyValueToViewCriteria(criteriaValues, viewCriteria))
      }
    },
    [viewCriteria, onApply]
  )

  // Update criteria values upon criteria options changed
  const handleCriteriaChange = useCallback(
    (fieldId: string, value: string[]) => {
      const newCriteriaValues = { ...(criteriaValues || {}), [fieldId]: value }
      setCriteriaValues(newCriteriaValues)

      if (isOpen) {
        setChanged(true)
      } else {
        raiseOnApplyEvent(newCriteriaValues)
      }
    },
    [setCriteriaValues, criteriaValues, isOpen, setChanged, raiseOnApplyEvent]
  )

  // Raise apply event when dropdown closed (and has changes)
  const handleDropdownVisibleChange = useCallback(
    (open: boolean) => {
      setIsOpen(open)

      if (!open && changed) {
        setChanged(false)
        raiseOnApplyEvent(criteriaValues || {})
      }
    },
    [setIsOpen, raiseOnApplyEvent, criteriaValues, changed, setChanged]
  )

  // Reset upon cancel and close the dropdown
  const handleCancel = useCallback(
    (ref: React.MutableRefObject<HTMLElement>) => {
      reset()

      // hackish way just to close the dropdown
      setTimeout(() => {
        btnRef?.current?.focus()
        setTimeout(() => ref?.current?.focus(), 100)
      }, 100)
    },
    [reset]
  )

  // Close the dropdown (and it will invoke apply event)
  const handleApply = useCallback((ref: React.MutableRefObject<HTMLElement>) => {
    // hackish way just to close the dropdown
    setTimeout(() => {
      btnRef?.current?.focus()
      setTimeout(() => ref?.current?.focus(), 100)
    }, 100)
  }, [])

  // Showing list of criteria drawer
  const handleAddCriteriaClick = useCallback(() => {
    if (viewId) {
      setCriteriaDrawerState({ visible: true, viewId })
    }
  }, [setCriteriaDrawerState, viewId])

  // Closing the drawer of criteria list
  const handleCloseCriteriaDrawer = useCallback(() => {
    setCriteriaDrawerState(DEFAULT_CRITERIA_DRAWER_STATE)
  }, [])

  // Raise apply event on drawer's apply event
  const handleDrawerApply = useCallback(
    (criteriaValues: ICriteriaKeyValue) => {
      raiseOnApplyEvent(criteriaValues)
    },
    [raiseOnApplyEvent]
  )

  // Clear all criteria
  const handleClearAll = useCallback(() => {
    if (criteriaValues) {
      let newCriteriaValues = { ...criteriaValues }
      Object.keys(newCriteriaValues).forEach(fieldId => {
        newCriteriaValues = { ...newCriteriaValues, [fieldId]: [] }
      })
      setCriteriaValues({ ...newCriteriaValues })
      raiseOnApplyEvent(newCriteriaValues)
    }
  }, [criteriaValues, setCriteriaValues, raiseOnApplyEvent])

  return (
    <div className="view-criteria-simple">
      {label && <label>{label}</label>}
      <Space>
        {criteriaValues &&
          Object.keys(criteriaValues).map((fieldId, index) => {
            const cri = criteria?.entities[fieldId]
            return (
              <Tooltip key={fieldId} title={cri?.description}>
                <CriteriaOptions
                  ref={criRefs.current[index]}
                  screenCode={screenCode}
                  criteria={cri}
                  style={selectCriteriaStyle}
                  value={criteriaValues[fieldId]}
                  dropdownMatchSelectWidth={false}
                  fetchStrategy="when-empty"
                  onChange={(value: string[]) => handleCriteriaChange(fieldId, value)}
                  onDropdownVisibleChange={handleDropdownVisibleChange}
                  dropdownRender={menu => (
                    <div>
                      {menu}
                      <Space style={buttonsGroupStyle}>
                        <RefreshCriteriaOptionsButton code={cri?.criteriaOptionCode} />
                        <Button onClick={() => handleCancel(criRefs.current[index])}>Cancel</Button>
                        <Button type="primary" onClick={() => handleApply(criRefs.current[index])}>
                          Apply
                        </Button>
                      </Space>
                    </div>
                  )}
                />
              </Tooltip>
            )
          })}
        <Tooltip title="Add new filter">
          <Button ref={btnRef} icon={<i className="fal fa-filter" />} onClick={handleAddCriteriaClick} />
        </Tooltip>
        <Tooltip title="Clear all filter">
          <SecondaryLink onClick={handleClearAll} hidden={!showClear}>
            Clear
          </SecondaryLink>
        </Tooltip>
      </Space>
      <ViewCriteriaSimpleDrawer
        {...criteriaDrawerState}
        screenCode={screenCode}
        onClose={handleCloseCriteriaDrawer}
        onApply={handleDrawerApply}
      />
    </div>
  )
}
