import { PayGroupState, IPayGroupInfo, IPayGroupSalaryCalculation, IPayGroupPayslipFooter } from './paygroup.state'

export const mapPayGroupStateToPayGroupInfo = (payGroup: PayGroupState): IPayGroupInfo => ({
  name: payGroup.name,
  companyId: payGroup.companyId,
  startDay: payGroup.startDay,
  startDay2: payGroup.startDay2,
  isMidMonth: payGroup.isMidMonth,
  paymentDay: payGroup.paymentDay,
  paymentMonth: payGroup.paymentMonth,
  paymentDay1: payGroup.paymentDay1,
  paymentMonth1: payGroup.paymentMonth1,
  paymentDay2: payGroup.paymentDay2,
  paymentMonth2: payGroup.paymentMonth2,
  otStartDay: payGroup.otStartDay,
  otStartMonth: payGroup.otStartMonth,
  otStartDay1: payGroup.otStartDay1,
  otStartMonth1: payGroup.otStartMonth1,
  otStartDay2: payGroup.otStartDay2,
  otStartMonth2: payGroup.otStartMonth2,
  isAutoPayslipPublish: payGroup.isAutoPayslipPublish,
  excludePayslipEmployeeIds: payGroup.excludePayslipEmployeeIds
})

export const mapPayGroupStateToPayGroupSalaryCalculation = (payGroup: PayGroupState): IPayGroupSalaryCalculation => ({
  half1Rate: payGroup.half1Rate
})

export const mapPayGroupStateToPayGroupPayslipFooter = (payGroup: PayGroupState): IPayGroupPayslipFooter => ({
  payslipFooterNotes: payGroup.payslipFooterNotes
})
