import React, { CSSProperties, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { CloseCircleOutlined } from '@ant-design/icons'
import type { SortableContainerProps, SortEnd } from 'react-sortable-hoc'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import { Button, Checkbox, CheckboxChangeEvent, ColumnsType, Table, TableRowSelection } from '~/core-components'
import { arrayMove } from '~/utils'
import { StoreState } from '~/types/store'
import { RptScreen, SysGroupingState } from '../types'
import { selectSysGroupings } from '../selectors/select-sys-groupings'
import './Grouping.less'

interface GroupingProps {
  screenCode: RptScreen
  groupingCodes: string[]
  pageBreaks: string[]
  visible: boolean
  onSelect: (groupingCodes: string[], pageBreaks: string[]) => void
}

const SortableItem = SortableElement((props: React.HTMLAttributes<HTMLTableRowElement>) => <tr {...props} />)
const SortableBody = SortableContainer((props: React.HTMLAttributes<HTMLTableSectionElement>) => <tbody {...props} />)

const dragHandleStyle: CSSProperties = { cursor: 'pointer', color: '#999' }
const tableScroll = { y: 1000 }

const DragHandle = SortableHandle(() => <i className="fal fa-grip-dots-vertical" style={dragHandleStyle} />)

export const Grouping: FC<GroupingProps> = ({ screenCode, groupingCodes, pageBreaks, visible, onSelect }) => {
  const [selected, setSelected] = useState<SysGroupingState[]>([])
  const [selectedPageBreak, setSelectedPageBreak] = useState<string[]>([])
  const selection = useSelector(selectSysGroupings)(screenCode)
  const loading = useSelector((state: StoreState) => state.grouping.sysGroupingsLoading[screenCode])
  const [init, setInit] = useState(true)

  useEffect(() => {
    if (visible) {
      setInit(true)
    }
  }, [visible])

  useEffect(() => {
    if (init && !loading) {
      const selected = (selection.filter(d => groupingCodes?.includes(d.code)) || []).sort((a, b) => {
        const indexA = groupingCodes?.indexOf(a.code)
        const indexB = groupingCodes?.indexOf(b.code)

        return indexA - indexB
      })
      const selectedPageBreak = selection.filter(d => pageBreaks?.includes(d.code)).map(pb => pb.code) || []

      setSelected(selected)
      setSelectedPageBreak(selectedPageBreak)
      setInit(false)
    }
  }, [init, loading, selection, groupingCodes, pageBreaks])

  useEffect(() => {
    typeof onSelect === 'function' &&
      onSelect(
        selected.map(s => s.code),
        selectedPageBreak
      )
  }, [selected, selectedPageBreak, onSelect])

  const handleRemove = useCallback(
    (record: SysGroupingState) => {
      setSelected(selected.filter(s => s.code !== record.code))
      setSelectedPageBreak(selectedPageBreak.filter(pb => pb !== record.code))
    },
    [selected, selectedPageBreak]
  )
  const handlePageBreakChange = useCallback(
    (e: CheckboxChangeEvent) => {
      if (e.target.checked) {
        setSelectedPageBreak(pb => [...pb, e.target.value])
      } else {
        setSelectedPageBreak(selectedPageBreak.filter(pb => pb !== e.target.value))
      }
    },
    [selectedPageBreak]
  )

  const columnsSelection: ColumnsType<SysGroupingState> = useMemo(
    () => [
      {
        title: 'Selections',
        key: 'code',
        dataIndex: 'code',
        render: (value: string, record) => (
          <>
            <div>{record.name}</div>
          </>
        )
      }
    ],
    []
  )

  const columnsSelected: ColumnsType<SysGroupingState> = useMemo(
    () => [
      {
        dataIndex: 'sort',
        width: 30,
        className: 'drag-visible',
        render: () => <DragHandle />
      },
      {
        title: `${selected.length} selected`,
        key: 'name',
        dataIndex: 'name',
        className: 'drag-visible'
      },
      {
        title: 'Page break',
        width: 70,
        align: 'center',
        render: (value, record) => (
          <Checkbox
            value={record.code}
            checked={selectedPageBreak.includes(record.code)}
            onChange={handlePageBreakChange}
          />
        )
      },
      {
        key: 'action',
        width: 45,
        align: 'right',
        render: (value, record) => (
          <Button
            type="text"
            className="remove-button"
            icon={<CloseCircleOutlined />}
            onClick={() => handleRemove(record)}
          />
        )
      }
    ],
    [handleRemove, handlePageBreakChange, selected.length, selectedPageBreak]
  )

  const handleSortEnd = useCallback(
    ({ oldIndex, newIndex }: SortEnd) => {
      const sorted = arrayMove<SysGroupingState>(selected, oldIndex, newIndex).map((x, index) => ({
        ...x,
        sequence: index
      }))
      setSelected(sorted)
    },
    [selected]
  )

  const rowSelection: TableRowSelection<SysGroupingState> = useMemo(
    () => ({
      type: 'checkbox',
      selectedRowKeys: selected.map(s => s.code),
      hideSelectAll: true,
      onChange: (rowKeys, rows) => {
        setSelected(selected => {
          const newSelected = [...selected, ...rows]
          typeof onSelect === 'function' &&
            onSelect(
              newSelected.map(s => s.code),
              selectedPageBreak
            )
          return newSelected
        })
      }
    }),
    [selected, selectedPageBreak, onSelect]
  )

  const DraggableContainer: FC<SortableContainerProps> = props => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="view-grouping__row-dragging"
      onSortEnd={handleSortEnd}
      {...props}
    />
  )

  const DraggableBodyRow: FC<any> = ({ className, style, ...restProps }) => {
    const index = selected.findIndex(x => x.code === (restProps['data-row-key'] as string))
    return <SortableItem index={index} {...restProps} />
  }

  return (
    <div className="view-grouping">
      <div className="view-grouping__table">
        <div className="view-grouping__table-selection">
          {selection?.length > 0 && (
            <Table
              className="selection"
              rowKey="code"
              rowSelection={rowSelection}
              dataSource={loading ? [] : selection.filter(s => !selected.map(x => x.code).includes(s.code))}
              columns={columnsSelection}
              indentSize={0}
              pagination={false}
              scroll={tableScroll}
              fitParent
            />
          )}
        </div>
        <div className="view-grouping__table-selected">
          <Table
            className="selected"
            rowKey="code"
            dataSource={selected}
            columns={columnsSelected}
            pagination={false}
            scroll={tableScroll}
            fitParent
            components={{
              body: {
                wrapper: DraggableContainer,
                row: DraggableBodyRow
              }
            }}
          />
        </div>
      </div>
    </div>
  )
}
