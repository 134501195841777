import { replaceShiftDaysAggregated, setShiftDaysAggregatedLoading } from '../reducers'
import { apiGetShiftDaysAggregated } from '../api/shift.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchShiftDaysAggregated = (
  shiftId: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!shiftId) return

    const loading = getState().attendance.shiftDaysAggregatedLoading[shiftId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().attendance.shiftDaysAggregated[shiftId]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setShiftDaysAggregatedLoading({ shiftId, loading: true }))
      const { result, status } = await apiGetShiftDaysAggregated(shiftId)
      if (status) {
        dispatch(replaceShiftDaysAggregated({ shiftId, data: result }))
      }
    } finally {
      dispatch(setShiftDaysAggregatedLoading({ shiftId, loading: false }))
    }
  }
}
