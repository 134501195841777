import React, { FC, useCallback, useState } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { DrawerForm } from '~/components'
import { useFetchSysGrouping } from '../hooks'
import { RptScreen } from '../types'
import { Grouping } from './Grouping'

interface GroupingDrawerProps {
  visible: boolean
  screenCode: RptScreen
  groupingCodes: string[]
  pageBreaks: string[]
  title?: string
  onSelect: (groupingCodes: string[], pageBreaks: string[]) => void
  onClose: () => void
}

interface GroupingForm {
  groupingCodes: string[]
  pageBreaks: string[]
}

export const EMPTY_GROUPING_FORM_DATA: GroupingForm = {
  groupingCodes: [],
  pageBreaks: []
}

export const GroupingDrawer: FC<GroupingDrawerProps> = ({
  visible,
  screenCode,
  title = 'Select grouping',
  groupingCodes,
  pageBreaks,
  onSelect,
  onClose
}) => {
  const [formData, setFormData] = useState<GroupingForm>(EMPTY_GROUPING_FORM_DATA)

  // is called upon parent screen shown
  useFetchSysGrouping(screenCode)

  useDeepCompareEffect(() => {
    setFormData({ groupingCodes, pageBreaks })
  }, [groupingCodes, pageBreaks])

  const handleSelect = useCallback((groupingCodes: string[], pageBreaks: string[]) => {
    setFormData({ groupingCodes, pageBreaks })
  }, [])

  const handleOk = useCallback(async () => {
    onSelect(formData.groupingCodes || [], formData.pageBreaks || [])
    typeof onClose === 'function' && onClose()
  }, [formData, onSelect, onClose])

  return (
    <DrawerForm
      open={visible}
      title={title}
      onOk={handleOk}
      onClose={() => onClose()}
      okText="Apply"
      width={600}
      className="grouping-drawer"
    >
      <Grouping
        screenCode={screenCode}
        groupingCodes={groupingCodes}
        pageBreaks={pageBreaks}
        visible={visible}
        onSelect={handleSelect}
      />
    </DrawerForm>
  )
}
