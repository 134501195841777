import { useSelector } from 'react-redux'
import {
  Screen,
  SysSelectionFieldState,
  fetchSysSelectionFields,
  fetchSysSelectionScreens,
  selectSysSelectionFieldsWGroup
} from '~/features/selection'
import { StoreState } from '~/types/store'
import { GroupChildren } from '~/types/common'
import { useEffect } from 'react'
import { dispatch } from '~/stores/store'
import { fetchEmpKeyvalues } from '~/features/master'

export const useSysSelectionFieldsWGroup = (screenCode: Screen): [GroupChildren<SysSelectionFieldState>[], boolean] => {
  const selectionWGroup = useSelector(selectSysSelectionFieldsWGroup)(screenCode)
  const loading = useSelector((state: StoreState) => state.selection.sysSelectionFieldsLoading[screenCode] || false)
  const refetch = useSelector((state: StoreState) => state.selection.sysSelectionFieldsRefetch)

  useEffect(() => {
    dispatch(fetchSysSelectionScreens(screenCode, { strategy: 'when-empty' }))
    dispatch(fetchSysSelectionFields(screenCode, { strategy: 'when-empty' }))
  }, [screenCode, refetch])

  useEffect(() => {
    dispatch(fetchEmpKeyvalues('sysselection', undefined, undefined, { strategy: 'when-empty' }))
  }, [])

  return [selectionWGroup, loading]
}
