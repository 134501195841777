import { apiAddIr21Draft } from '../api/ir21.api'
import { ThunkResult } from '~/types/store'
import { showSuccess, showError } from '~/utils'
import { IAddIr21Draft } from '../types'

export const addIr21Draft = (request: IAddIr21Draft): ThunkResult<void> => {
  return async () => {
    const { status, errors, message, errorData } = await apiAddIr21Draft(request)
    if (status) {
      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
