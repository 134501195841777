import { setEmPayroll, setEmPayrollLoading } from '../reducers'
import { apiGetEmPayroll } from '../api/em-payroll.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchEmPayroll = (
  viewId: string,
  employeeId: string,
  refreshDate: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!viewId || !employeeId) return

    const loading = getState().payroll.emPayrollLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().payroll.emPayroll.entities[employeeId]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setEmPayrollLoading(true))
      const { result, status } = await apiGetEmPayroll(viewId, employeeId, refreshDate)
      if (status) {
        dispatch(setEmPayroll(result))
      }
    } finally {
      dispatch(setEmPayrollLoading(false))
    }
  }
}
