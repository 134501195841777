import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { CalendarPatternState, CalendarState, CalendarWeekState } from '../types'

export interface CalendarOverviewState extends CalendarState {
  weeks: CalendarWeekState[]
}

export const selectCalendarsOverview = createSelector(
  (state: StoreState) => state.master.masters.calendar,
  (state: StoreState) => state.master.calendarPatterns,
  (calendarState, calendarPatternsState): CalendarOverviewState[] => {
    const calendars = Object.values(calendarState?.entities || {}) as CalendarState[]
    const calendarPatterns = Object.values(calendarPatternsState?.entities || {})
    if (calendarPatterns == null || calendarPatterns.length === 0) {
      const weeks: CalendarWeekState[] = [{ mon: {}, tue: {}, wed: {}, thu: {}, fri: {}, sat: {}, sun: {} }]
      return calendars.map(calendar => ({
        ...(calendar as CalendarState),
        weeks
      }))
    } else {
      return calendars
        .sort((a, b) => {
          const aInactiveDate = new Date(a!.inactiveDate || '').getTime() || 0
          const bInactiveDate = new Date(b!.inactiveDate || '').getTime() || 0

          if (bInactiveDate === 0 && aInactiveDate === 0) {
            return a!.name.localeCompare(b!.name)
          } else {
            return aInactiveDate - bInactiveDate
          }
        })
        .map(calendar => {
          const patterns = calendarPatterns
            .filter(cp => cp!.calendarId === calendar.id)
            .sort((a, b) => (a!.sequence || 0) - (b!.sequence || 0))
          const weeks = groupIntoChunks(patterns, 7)

          return {
            ...(calendar as CalendarState),
            weeks
          }
        })
    }
  }
)

const groupIntoChunks = (array: any[], chunkSize: number) => {
  // Define an array of day names
  const daysOfWeek = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']

  return array.reduce((result, current: CalendarPatternState, index) => {
    // Calculate the day of the week based on the sequence number
    const sequence = current.sequence
    const dayIndex = (sequence - 1) % 7 // 0-based index for daysOfWeek array
    const dayName = daysOfWeek[dayIndex]

    // Calculate the chunk index
    const chIndex = Math.floor(index / chunkSize)

    if (!result[chIndex]) {
      result[chIndex] = {}
    }

    if (!result[chIndex][dayName]) {
      result[chIndex][dayName] = current
    }

    return result
  }, [])
}
