import { replaceLeaveBuddies, setLeaveBuddiesLoading } from '../reducers'
import { apiGetLeaveBuddies } from '../api/leave-buddy.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchLeaveBuddies = (options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().leave.leaveBuddiesLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().leave.leaveBuddies?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setLeaveBuddiesLoading(true))
      const { result, status } = await apiGetLeaveBuddies()
      if (status) {
        dispatch(replaceLeaveBuddies(result))
      }
    } finally {
      dispatch(setLeaveBuddiesLoading(false))
    }
  }
}
