import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment-timezone'
import { useSelector } from 'react-redux'
import { Col, DocumentTitle, EmpKeyValues, PdfPasswordModal, PdfViewer, PeriodKeyValues, Row } from '~/components'
import {
  Button,
  Checkbox,
  CheckboxChangeEvent,
  Collapse,
  Form,
  Input,
  PageHeader,
  Select,
  Space
} from '~/core-components'
import { fetchPayRuns, selectPayRuns } from '~/features/payroll'
import { GroupingButton, RptScreen } from '~/features/grouping'
import { emptyGuid } from '~/constants'
import { REPORTS_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { Errors, StoreState } from '~/types/store'
import { downloadWithDom, getFileTimestamp, showError } from '~/utils'
import { IRptPayVariance, ReportCode } from '../../types'
import { useFirstReportTemplate, useReportTemplate } from '../../hooks'
import { refetchReportTemplates } from '../../reducers'
import { apiDownloadPayVarianceExcel, apiDownloadPayVariancePdf } from '../../api/rpt-pay-variance.api'
import { RptDownloadBtn } from '../components/RptDownloadBtn'
import { ReportTemplate } from '../ReportTemplate/ReportTemplate'
import { fetchMasters, selectPayGroups } from '~/features/master'
import { RptSortingBtn } from '../components/RptSortingBtn'
import './RptPayVariance.less'

const routes = [
  {
    path: REPORTS_ROUTES.main,
    breadcrumbName: 'Reports'
  },
  {
    path: '',
    breadcrumbName: 'Payroll variance report'
  }
]

interface RptPayVarianceForm extends IRptPayVariance {
  templateId: string
}

const currentPeriod = moment().format('YYYY-MM')
const prevPeriod = moment().add(-1, 'month').format('YYYY-MM')

const SCREEN_CODE: RptScreen = 'rpt_payroll'
const REPORT_CODE: ReportCode = 'rpt_pay_variance'

const EMPTY_FORM_DATA: RptPayVarianceForm = {
  paymentMonth: currentPeriod,
  companyId: '',
  currPayRunIds: [],
  compareWith: prevPeriod,
  prevPayRunIds: [],
  sorting: 'employee_name',
  groupingCodes: [],
  pageBreaks: [],
  suppressEmployee: false,
  suppressNoDiff: true,
  reportTitle: '',
  notes: '',
  templateId: emptyGuid
}

export const RptPayVariance: FC = () => {
  const [formData, setFormData] = useState<RptPayVarianceForm>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const payRuns = useSelector(selectPayRuns)()
  const companyIds = useSelector((state: StoreState) => state.master.keyvalues['company']?.ids)
  const payGroups = useSelector(selectPayGroups)

  const [previewUrl, setPreviewUrl] = useState<string>('')
  const [previewing, setPreviewing] = useState(false)
  const [activeKey, setActiveKey] = useState(0)
  const [pdfModal, setPdfModal] = useState(false)

  const [firstTemplate] = useFirstReportTemplate('payroll-sg', REPORT_CODE)
  const firstTemplateId = firstTemplate?.id || ''
  const [templateId, setTemplateId] = useState<string>()
  const [template] = useReportTemplate('payroll-sg', REPORT_CODE, templateId)

  const payGroupIds = useMemo(() => {
    return payGroups.filter(pg => pg?.companyId === formData.companyId).map(pg => pg?.id)
  }, [payGroups, formData])

  const currPayRuns = useMemo(() => {
    return payRuns.filter(pr => pr.payPeriod === formData.paymentMonth && payGroupIds.includes(pr.payGroupId))
  }, [payRuns, payGroupIds, formData.paymentMonth])

  const prevPayRuns = useMemo(() => {
    return payRuns.filter(pr => pr.payPeriod === formData.compareWith && payGroupIds.includes(pr.payGroupId))
  }, [payRuns, payGroupIds, formData.compareWith])

  useEffect(() => {
    dispatch(fetchMasters('payGroup', { strategy: 'when-empty' }))
    dispatch(fetchPayRuns({ strategy: 'when-empty' }))
  }, [])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    if ('companyId' in updates) {
      updates.currPayRunIds = []
      updates.prevPayRunIds = []
    }

    if ('paymentMonth' in updates) {
      updates.compareWith = moment(updates.paymentMonth).add(-1, 'month').format('YYYY-MM')
      updates.currPayRunIds = []
    }

    if ('compareWith' in updates) {
      updates.prevPayRunIds = []
    }

    setFormData(data => ({ ...data, ...updates }))
  }, [])

  useEffect(() => {
    if (!templateId && firstTemplateId) {
      setTemplateId(firstTemplateId)
    }

    if (template) {
      const saved = JSON.parse(template.dataJsonb) as RptPayVarianceForm
      saved.templateId = templateId || ''
      setFormData(formData => ({ ...formData, ...saved }))
    }
  }, [firstTemplateId, templateId, template])

  useEffect(() => {
    setFormData(formData => {
      if (!formData.companyId) {
        if (companyIds && companyIds.length > 0) {
          return { ...formData, companyId: companyIds[0].toString(), payGroupIds: [] }
        }
      }
      return formData
    })
  }, [companyIds])

  const handlePreview = useCallback(async () => {
    setPreviewing(true)
    setErrors(undefined)

    try {
      const { status, result, errors, message, errorData } = await apiDownloadPayVariancePdf(formData)

      if (status) {
        const url = URL.createObjectURL(result)
        setPreviewUrl(url)
        dispatch(refetchReportTemplates())
      } else {
        console.error('Error while downloading', errors)
        setErrors(errors)
        showError(message, errorData)
      }
    } finally {
      setPreviewing(false)
    }
  }, [formData])

  const handleDownloadPdf = useCallback(
    async (password?: string) => {
      setErrors(undefined)

      const { status, result, errors, message, errorData } = await apiDownloadPayVariancePdf({ ...formData, password })

      if (status) {
        const fileName = `payroll_variance_report_${getFileTimestamp()}.pdf`
        downloadWithDom(result, fileName)
        dispatch(refetchReportTemplates())
      } else {
        console.error('Error while downloading', errors)
        setErrors(errors)
        showError(message, errorData)
      }
    },
    [formData]
  )

  const handleOpenPdfModal = useCallback(async () => {
    setPdfModal(true)
  }, [])

  const handleClosePdfModal = useCallback(() => {
    setPdfModal(false)
  }, [])

  const handleDownloadExcel = useCallback(async () => {
    const { status, result, errors, message, errorData } = await apiDownloadPayVarianceExcel(formData)

    if (status) {
      const fileName = `payroll_variance_report_${getFileTimestamp()}.xlsx`
      downloadWithDom(result, fileName)
      dispatch(refetchReportTemplates())
    } else {
      console.error('Error while downloading', errors)
      setErrors(errors)
      showError(message, errorData)
    }
  }, [formData])

  const handleCollapse = useCallback(async () => {
    if (!activeKey) {
      setActiveKey(1)
    } else {
      setActiveKey(0)
    }
  }, [activeKey])

  const handleTemplateChange = useCallback((templateId?: string) => {
    setTemplateId(templateId)
  }, [])

  return (
    <div id="rpt-pay-variance" className="rpt-pay-variance">
      <DocumentTitle title="Payroll Variance Report" />
      <PageHeader
        title="Payroll variance report"
        containerId="rpt-pay-variance"
        breadcrumb={{ routes }}
        extra={
          <ReportTemplate<IRptPayVariance>
            basePath="payroll-sg"
            reportCode={REPORT_CODE}
            templateId={templateId}
            templateData={formData}
            size="small"
            onChange={handleTemplateChange}
          />
        }
      />
      <Form className="rpt-pay-variance__form" layout="horizontal" labelCol={{ flex: '135px' }}>
        <Row gutter={30}>
          <Col flex="280px">
            <Form.Item
              label="Payment month"
              validateStatus={errors?.paymentMonth ? 'error' : ''}
              help={errors?.paymentMonth}
            >
              <PeriodKeyValues
                allowClear={false}
                value={formData.paymentMonth}
                startMonth={currentPeriod}
                noOfMonthBefore={-24}
                noOfMonthAfter={2}
                onChange={(paymentMonth: string) => handleFormDataChange({ paymentMonth })}
              />
            </Form.Item>
          </Col>
          <Col flex="350px">
            <Form.Item
              label="Company"
              labelCol={{ flex: '80px' }}
              validateStatus={errors?.companyId ? 'error' : ''}
              help={errors?.companyId}
            >
              <EmpKeyValues
                id="company"
                allowClear={false}
                value={formData.companyId}
                onChange={(companyId: string) => handleFormDataChange({ companyId })}
              />
            </Form.Item>
          </Col>
          <Col flex="1"></Col>
          <Col flex="none">
            <Space>
              <RptSortingBtn
                sorting={formData.sorting}
                onSelect={(sorting: string) => handleFormDataChange({ sorting })}
              />
              <GroupingButton
                screenCode={SCREEN_CODE}
                groupingCodes={formData.groupingCodes || []}
                pageBreaks={formData.pageBreaks || []}
                onSelect={(groupingCodes: string[], pageBreaks: string[]) =>
                  handleFormDataChange({ groupingCodes, pageBreaks })
                }
              />
              <Button className="btn-more-settings" type={activeKey ? 'primary' : 'default'} onClick={handleCollapse}>
                <i className="fal fa-gear" />
              </Button>
              <Button type="primary" onClick={handlePreview} loading={previewing}>
                Preview
              </Button>
              <RptDownloadBtn
                onDownloadPdf={handleDownloadPdf}
                onDownloadSecurePdf={handleOpenPdfModal}
                onDownloadExcel={handleDownloadExcel}
              />
            </Space>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col flex="1">
            <Form.Item
              label="Payroll runs"
              validateStatus={errors?.currPayRunIds ? 'error' : ''}
              help={errors?.currPayRunIds}
            >
              <Select
                mode="multiple"
                value={formData.currPayRunIds || []}
                placeholder="All current month payroll runs"
                optionFilterProp="title"
                onChange={(currPayRunIds: string[]) => handleFormDataChange({ currPayRunIds })}
              >
                {currPayRuns.map(pr => (
                  <Select.Option key={pr.id} value={pr.id} title={pr.description}>
                    {pr.description}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col flex="1">
            <Form.Item
              label="Compare with"
              validateStatus={errors?.compareWith ? 'error' : ''}
              help={errors?.compareWith}
            >
              <Row gutter={8}>
                <Col flex="125px">
                  <PeriodKeyValues
                    allowClear={false}
                    value={formData.compareWith}
                    startMonth={formData.paymentMonth}
                    noOfMonthBefore={-24}
                    noOfMonthAfter={0}
                    onChange={(compareWith: string) => handleFormDataChange({ compareWith })}
                  />
                </Col>
                <Col flex="1">
                  <Select
                    mode="multiple"
                    value={formData.prevPayRunIds || []}
                    placeholder="All compare with payroll runs"
                    optionFilterProp="title"
                    onChange={(prevPayRunIds: string[]) => handleFormDataChange({ prevPayRunIds })}
                  >
                    {prevPayRuns.map(pr => (
                      <Select.Option key={pr.id} value={pr.id} title={pr.description}>
                        {pr.description}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col flex="1">
            <Collapse
              className="rpt-pay-variance__more-settings"
              activeKey={activeKey}
              onChange={handleCollapse}
              noStyle
            >
              <Collapse.Panel key="1" header={null} showArrow={false}>
                <Row gutter={30}>
                  <Col flex="1">
                    <Form.Item label="Options">
                      <Checkbox
                        checked={formData.suppressEmployee}
                        onChange={(event: CheckboxChangeEvent) =>
                          handleFormDataChange({ suppressEmployee: event.target.checked })
                        }
                      >
                        Suppress employee
                      </Checkbox>
                      <Checkbox
                        checked={formData.suppressNoDiff}
                        onChange={(event: CheckboxChangeEvent) =>
                          handleFormDataChange({ suppressNoDiff: event.target.checked })
                        }
                      >
                        Suppress records with no change
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={30}>
                  <Col flex="490px">
                    <Form.Item label="Report title">
                      <Input
                        value={formData.reportTitle}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                          handleFormDataChange({ reportTitle: event.target.value })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={30}>
                  <Col flex="1">
                    <Form.Item label="Custom notes">
                      <Input.TextArea
                        rows={3}
                        value={formData.notes}
                        onChange={(value?: ChangeEvent<HTMLTextAreaElement>) =>
                          handleFormDataChange({ notes: value?.target.value })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Collapse.Panel>
            </Collapse>
          </Col>
        </Row>
      </Form>
      {previewUrl && (
        <div className="rpt-pay-variance__pdf-viewer">
          <Row>
            <Col>
              <PdfViewer file={previewUrl} layout="portrait" scale={1.5} />
            </Col>
          </Row>
        </div>
      )}
      <PdfPasswordModal open={pdfModal} onApply={handleDownloadPdf} onClose={handleClosePdfModal} />
    </div>
  )
}
