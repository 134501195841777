import React, { forwardRef, useEffect, useCallback, useState, useMemo, CSSProperties } from 'react'
import { PersonAvatar } from '~/components'
import { ColumnsType, Form, SecondaryLink, SecondaryText, Space, Table, Tag } from '~/core-components'
import { EditOutlined } from '@ant-design/icons'
import { EmNameDescState } from '~/features/employee/types'
import { EmSelectionDrawer } from '~/features/employee'
import { apiGetEmNameDesc } from '~/features/employee/api/employee.api'
import { ViewCriteriaFieldValue, ViewName } from '~/features/selection'
import { Pagination } from '~/types/common'
import { getBaseUrl } from '~/utils'
import { emptyGuid } from '~/constants'
import './SelectEmployeesTable.less'

export interface SelectEmployeesTableProps {
  viewName: ViewName
  label?: React.ReactNode
  employeeIds: string[]
  onFetchData: (viewId: string, pagination: Pagination, search: string, pastResignees: boolean) => void
  onSelect: (employeeIds: string[]) => void
  resetOnClose?: boolean
  resetTo?: ViewCriteriaFieldValue[]
}

interface DrawerState {
  visible: boolean
}
const DRAWER_STATE: DrawerState = { visible: false }
const secondaryTextStyle: CSSProperties = { fontSize: 12 }

const baseUrl = getBaseUrl('/filestore')
const tableScroll = { y: 250 }

const SelectEmployeesTableInternal = (
  { viewName, label, employeeIds, onFetchData, onSelect, resetOnClose = true, resetTo }: SelectEmployeesTableProps,
  ref: React.Ref<any>
) => {
  const [nameDescs, setNameDescs] = useState<EmNameDescState[]>([])
  const [loading, setLoading] = useState(false)
  const [drawerState, setAddEmployeeDrawerState] = useState<DrawerState>(DRAWER_STATE)

  useEffect(() => {
    if (employeeIds && employeeIds.length > 0) {
      const fetchPhotoIds = async () => {
        try {
          setLoading(true)
          const { result } = await apiGetEmNameDesc(employeeIds)
          setNameDescs(result)
        } finally {
          setLoading(false)
        }
      }
      fetchPhotoIds()
    }
  }, [employeeIds])

  const columns = useMemo(() => {
    let columns: ColumnsType<EmNameDescState> = []

    columns.push({
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      render: (value: string, record) => (
        <>
          <div className="em-selection__person">
            <PersonAvatar
              photo={
                record.photoId && record.photoId !== emptyGuid
                  ? `${baseUrl}/file/${record.photoId}/thumbnailphoto/24`
                  : undefined
              }
              loading={loading}
            />
            <div className="em-selection__person__info">
              <h1>{record.name}</h1>
              <Space>
                {record.description && <SecondaryText style={secondaryTextStyle}>{record.description}</SecondaryText>}
                {record.isResignee && <Tag type="neutral">resignee</Tag>}
              </Space>
            </div>
          </div>
        </>
      )
    })

    return columns
  }, [loading])

  const handleOpenDrawer = useCallback(() => {
    setAddEmployeeDrawerState({ visible: true })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setAddEmployeeDrawerState(DRAWER_STATE)
  }, [])

  return (
    <div className="select-employees-table">
      <Form.Item
        label={
          <SecondaryLink ref={ref} onClick={handleOpenDrawer}>
            <Space>
              <span className="label">{label}</span>
              <EditOutlined />
            </Space>
          </SecondaryLink>
        }
      >
        {employeeIds && employeeIds.length > 0 && (
          <Table
            rowKey="id"
            className="selection"
            dataSource={loading ? [] : nameDescs}
            columns={columns}
            loading={loading}
            scroll={tableScroll}
            pagination={false}
            fitParent
            bordered
          />
        )}
        {(!employeeIds || employeeIds.length === 0) && <SecondaryText>No data available</SecondaryText>}
      </Form.Item>
      <EmSelectionDrawer
        {...drawerState}
        viewName={viewName}
        employeeIds={employeeIds}
        onFetchData={onFetchData}
        onSelect={onSelect}
        onClose={handleCloseDrawer}
        resetOnClose={resetOnClose}
        resetTo={resetTo}
      />
    </div>
  )
}

export const SelectEmployeesTable = forwardRef(SelectEmployeesTableInternal)
