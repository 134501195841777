import { setLeaveGrants, setLeaveGrantsLoading } from '../reducers'
import { apiGetLeaveGrantByLeaveRecord } from '../api/leave-grant.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'
import { mapLeaveGrantToLeaveGrantState } from '../types/leave-grant.mapper'

export const fetchLeaveGrantByLeaveRecord = (
  employeeId: string,
  leaveRecordId: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!employeeId || !leaveRecordId) return

    const loading = getState().leave.leaveGrantsLoading[employeeId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().leave.leaveGrants[employeeId]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setLeaveGrantsLoading({ employeeId, loading: true }))
      const { result, status } = await apiGetLeaveGrantByLeaveRecord(employeeId, leaveRecordId)
      if (status) {
        const leaveGrants = result.map(dto => mapLeaveGrantToLeaveGrantState(dto))
        dispatch(setLeaveGrants({ employeeId, data: leaveGrants }))
      }
    } finally {
      dispatch(setLeaveGrantsLoading({ employeeId, loading: false }))
    }
  }
}
