import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { IPayRecurring, PayRecurringState, PayRecurringOverrideState, IPayRecurringOverride } from '../types'

const baseUrl = getBaseUrl('/payroll-sg/payrecurring')

export const apiGetPayRecurring = (employeeId: string) =>
  request<PayRecurringState[]>('get', `${baseUrl}/employee/${employeeId}`)

export const apiAddPayRecurring = (payRecurring: IPayRecurring) =>
  request<{ id: string }>('post', baseUrl, payRecurring)

export const apiUpdatePayRecurring = (id: string, payRecurring: Operation[]) =>
  request<void>('patch', `${baseUrl}/${id}`, payRecurring)

export const apiDeletePayRecurring = (id: string) => request<void>('delete', `${baseUrl}/${id}`)

export const apiGetPayRecurringOverride = (
  payRunId: string,
  employeeId: string,
  payItemId: string,
  payTranBatchId: string
) =>
  request<PayRecurringOverrideState>(
    'get',
    `${baseUrl}/override/${payRunId}/${employeeId}/${payItemId}/${payTranBatchId}`
  )

export const apiUpdatePayRecurringOverride = (
  payRunId: string,
  employeeId: string,
  payItemId: string,
  payTranBatchId: string,
  override: IPayRecurringOverride
) =>
  request<{ id: string }>(
    'post',
    `${baseUrl}/override/${payRunId}/${employeeId}/${payItemId}/${payTranBatchId}`,
    override
  )

export const apiDeletePayRecurringOverride = (
  payRunId: string,
  employeeId: string,
  payItemId: string,
  payTranBatchId: string
) => request<{ id: string }>('delete', `${baseUrl}/override/${payRunId}/${employeeId}/${payItemId}/${payTranBatchId}`)
