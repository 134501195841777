import React, { FC, useState, useCallback } from 'react'
import { useHistory } from 'react-router'
import { Form } from '~/core-components'
import { DrawerForm } from '~/components'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors } from '~/types/store'
import { OfficeInfoForm, EMPTY_OFFICE_INFO_FORM_DATA } from '../../Company/components/OfficeInfoForm'
import { addOffice } from '../../../actions'
import { IOfficeInfo } from '../../../types'

interface AddOfficeDrawerProps {
  visible: boolean
  onClose: () => void
}

export const AddOfficeDrawer: FC<AddOfficeDrawerProps> = ({ visible, onClose }: AddOfficeDrawerProps) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<IOfficeInfo>(EMPTY_OFFICE_INFO_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const history = useHistory()

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      result = await dispatch(addOffice(formData))
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_OFFICE_INFO_FORM_DATA)
      history.push(SETTINGS_ROUTES.office.replace(':id', result?.result?.id))
    }
  }, [formData, onClose, history])

  return (
    <DrawerForm
      open={visible}
      title="Add office"
      onClose={onClose}
      confirmLoading={loading}
      width={650}
      formId="form-office"
    >
      <Form id="form-office" onFinish={handleOk}>
        <OfficeInfoForm usage="office" data={formData} errors={errors} onChange={data => setFormData(data)} />
      </Form>
    </DrawerForm>
  )
}
