import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { fetchSysGroupings, fetchSysGroupingScreens } from '../actions'
import { RptScreen } from '../types'
import { StoreState } from '~/types/store'

export const useFetchSysGrouping = (screenCode: RptScreen) => {
  const refetch = useSelector((state: StoreState) => state.grouping.sysGroupingsRefetch)

  useEffect(() => {
    dispatch(fetchSysGroupingScreens(screenCode, { strategy: 'when-empty' }))
    dispatch(fetchSysGroupings(screenCode, { strategy: 'when-empty' }))
  }, [screenCode, refetch])
}
