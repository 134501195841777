import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { Screen, ViewCriteria } from '../types'

export const selectViewCriteria = createSelector(
  (state: StoreState) => state.selection.viewSchema,
  (state: StoreState) => state.selection.sysCriteriaFields,
  (viewSchemaState, criteriaFieldsState) =>
    memoize(
      (screenCode: Screen, viewId?: string): ViewCriteria[] => {
        if (!viewId) return []

        const viewSchema = viewSchemaState[screenCode]?.entities[viewId]
        const criteria = [...(viewSchema?.criteria || [])]

        return criteria.sort((a, b) => {
          if (a.parentId == null) {
            return -1
          } else if (b.parentId == null) {
            return 1
          }

          // Sorted by non-empty criteria value and criteria field's sequence
          const aSequence =
            (a.criteriaFieldId && criteriaFieldsState[screenCode]?.entities[a.criteriaFieldId]?.sequence) || 0
          const bSequence =
            (b.criteriaFieldId && criteriaFieldsState[screenCode]?.entities[b.criteriaFieldId]?.sequence) || 0
          const aValue = a.value
          const bValue = b.value

          if (
            ((aValue == null || aValue === '') && (bValue == null || bValue === '')) ||
            (aValue != null && aValue !== '' && bValue != null && bValue !== '')
          ) {
            return aSequence - bSequence
          } else {
            if (bValue == null || bValue === '') {
              return -1
            } else if (aValue == null || aValue === '') {
              return 1
            } else {
              return 0
            }
          }
        })
      },
      (screenCode: Screen, viewId?: string) => `${screenCode}||${viewId}`
    )
)
