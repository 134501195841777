import React, { forwardRef, useMemo } from 'react'
import range from 'lodash/range'
import classNames from 'classnames'
import { Select } from '../../core-components'
import { SelectProps } from '../../core-components/Select/Select'
import './YearKeyValues.less'

interface YearKeyValuesProps extends SelectProps {
  startYear?: number
  noOfYearBefore: number
  noOfYearAfter: number
  sortingOrder?: 'asc' | 'desc'
}

const YearKeyValuesInternal = (
  { startYear, noOfYearBefore, noOfYearAfter, sortingOrder, className, ...props }: YearKeyValuesProps,
  ref: React.Ref<any>
) => {
  const classes = classNames('yearkeyvalues', { [`${className}`]: className })

  const start = useMemo(() => {
    let start = startYear ? new Date(`${startYear}-01-01`) : new Date()
    start = new Date(start.setFullYear(start.getFullYear() + noOfYearBefore))
    return start
  }, [startYear, noOfYearBefore])

  const end = useMemo(() => {
    let end = new Date()
    end = new Date(end.setFullYear(end.getFullYear() + noOfYearAfter))
    return end
  }, [noOfYearAfter])

  const data = useMemo(() => {
    let result = range(start.getFullYear(), end.getFullYear() + 1)
    if (!sortingOrder) {
      return result
    }

    if (sortingOrder === 'desc') {
      result = result.reverse()
    }

    return result
  }, [start, end, sortingOrder])

  return (
    <Select ref={ref} showSearch className={classes} optionFilterProp="title" {...props}>
      {data.map(yearNum => {
        return (
          <Select.Option key={yearNum} value={yearNum} title={yearNum.toString()}>
            {yearNum}
          </Select.Option>
        )
      })}
    </Select>
  )
}

export const YearKeyValues = forwardRef(YearKeyValuesInternal)
