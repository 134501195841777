import React, { FC, useEffect, useMemo, useState } from 'react'
import moment from 'moment-timezone'
import { ColumnsType, Table, TableRowSelection, Tooltip } from '~/core-components'
import { ErrorDisplay } from '~/components'
import { formatMoney } from '~/utils'
import { Errors } from '~/types/store'
import { ClaimImportSourceState } from '../../../../types'
import { ImportClaimFormData } from '../ImportClaimDrawer'
import './SelectClaimRecord.less'

interface SelectClaimRecordProps {
  visible: boolean
  data: Partial<ImportClaimFormData>
  errors?: Errors
  onSelect: (data: Partial<ImportClaimFormData>) => void
}

export const SelectClaimRecord: FC<SelectClaimRecordProps> = ({ visible, data, errors, onSelect }) => {
  const [selected, setSelected] = useState<ClaimImportSourceState[]>([])

  useEffect(() => {
    if (data.selected) {
      setSelected(data.selected)
    }
  }, [data.selected])

  const columns: ColumnsType<ClaimImportSourceState> = [
    {
      title: 'Employee',
      key: 'employeeNo',
      dataIndex: 'employeeNo',
      render: (value: string, record) => `[${value}] ${record.employeeName}`
    },
    {
      title: 'Claim type',
      key: 'claimTypeName',
      dataIndex: 'claimTypeName'
    },
    {
      title: 'Expense Date',
      key: 'expenseDate',
      dataIndex: 'expenseDate',
      render: (value: string) => (value ? moment(value).format('DD MMM YYYY') : undefined)
    },
    {
      title: 'Amount',
      key: 'claimAmount',
      dataIndex: 'claimAmount',
      render: (value: number, record) => formatMoney(value, 2)
    },
    {
      title: 'Payroll item',
      key: 'payItemName',
      dataIndex: 'payItemName'
    },
    {
      key: 'isInvalid',
      dataIndex: 'isInvalid',
      width: 30,
      render: (value: boolean, record) =>
        value &&
        !!record.reason && (
          <Tooltip title={record.reason} placement="topRight">
            <i className="fal fa-triangle-exclamation" />
          </Tooltip>
        )
    }
  ]

  const rowSelection: TableRowSelection<ClaimImportSourceState> = useMemo(
    () => ({
      type: 'checkbox',
      selectedRowKeys: selected.map(d => d.id),
      hideSelectAll: false,
      onChange: (_selectedRowKeys, rows) => {
        typeof onSelect === 'function' && onSelect({ selected: rows })
        setSelected(rows)
      },
      getCheckboxProps: (record: ClaimImportSourceState) => ({
        disabled: record.isInvalid
      })
    }),
    [selected, onSelect]
  )

  return (
    <div className="select-claim-record" hidden={!visible}>
      <ErrorDisplay errors={errors} />
      <Table rowSelection={rowSelection} rowKey="id" dataSource={data.source} columns={columns} pagination={false} />
    </div>
  )
}
