export enum XeroPayItemCode {
  BasicPay = 'basic_pay',
  BasicPay2 = 'basic_pay_2',
  CpfEr = 'cpf_er',
  CpfEr2 = 'cpf_er_2',
  CpfEm = 'cpf_em',
  CpfEm2 = 'cpf_em_2',
  Sdl = 'sdl',
  Sdl2 = 'sdl_2',
  Fwl = 'fwl',
  Fwl2 = 'fwl_2',
  PayItem = 'pay_item'
}

export enum XeroSignType {
  Addition = '+',
  Deduction = '-'
}

export enum XeroSplitType {
  Employee = 'em'
}
