import { setYtdCompany, setYtdCompaniesLoading } from '../reducers'
import { apiGetYtdCompany } from '../api/ytd-company.api'
import { ThunkResult } from '~/types/store'

export const fetchYtdCompany = (ytdSubmissionId: string): ThunkResult<void> => {
  return async dispatch => {
    try {
      dispatch(setYtdCompaniesLoading(true))
      const { result, status } = await apiGetYtdCompany(ytdSubmissionId)
      if (status) {
        dispatch(setYtdCompany(result))
      }
    } finally {
      dispatch(setYtdCompaniesLoading(false))
    }
  }
}
