import { replaceLeaveBuddyEm, setLeaveBuddyEmLoading } from '../reducers'
import { apiGetLeaveBuddyEm } from '../api/leave-buddy.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchLeaveBuddyEm = (
  leaveBuddyId: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().leave.leaveBuddyEmLoading[leaveBuddyId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().leave.leaveBuddyEm[leaveBuddyId]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setLeaveBuddyEmLoading({ id: leaveBuddyId, loading: true }))
      const { result, status } = await apiGetLeaveBuddyEm(leaveBuddyId)
      if (status) {
        dispatch(replaceLeaveBuddyEm({ id: leaveBuddyId, data: result }))
      }
    } finally {
      dispatch(setLeaveBuddyEmLoading({ id: leaveBuddyId, loading: false }))
    }
  }
}
