import { Button as InternalButton, ButtonProps as InternalButtonProps } from './Button'
import { ActionGroup } from './ActionGroup'

type TButton = typeof InternalButton
interface IButton extends TButton {
  ActionGroup: typeof ActionGroup
}

const Button: IButton = InternalButton as IButton
Button.ActionGroup = ActionGroup
export { Button }

export type ButtonProps = InternalButtonProps

export * from './ButtonTag'
export * from './ActionGroup'
