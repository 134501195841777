import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { ManagerCategory, ManagerState } from '../types'

export const selectManagers = createSelector(
  (state: StoreState) => state.manager.managers,
  managersState =>
    memoize((category: ManagerCategory): ManagerState[] => {
      const managers = Object.values(managersState[category]?.entities || {}) as ManagerState[]
      return managers
    })
)
