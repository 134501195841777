import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchOedLogs } from '../actions'
import { OedLogState } from '../types'

export const useOedLogs = (id?: string, fetchStrategy: FetchStrategy = 'always'): [OedLogState[], boolean] => {
  const oedLogs = useSelector((state: StoreState) => Object.values(state.report.oedLogs?.entities) as OedLogState[])
  const loading = useSelector((state: StoreState) => state.report.oedLogsLoading)
  const refetch = useSelector((state: StoreState) => state.report.oedLogsRefetch)

  useEffect(() => {
    if (id) dispatch(fetchOedLogs(id, { strategy: fetchStrategy }))
  }, [id, refetch, fetchStrategy])

  return [oedLogs, loading]
}
