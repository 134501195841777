import React, { CSSProperties, FC, useCallback, useMemo } from 'react'
import moment from 'moment-timezone'
import { AmountDisplay, Col, Row, UploadFileSelectorAuth } from '~/components'
import { Button, SecondaryText, Space, UploadFile } from '~/core-components'
import { getInclusiveTaxLabel } from '~/features/claim'
import { ClaAttachmentFileTypeAllowed, ClaTaxableType } from '~/constants'
import { getBaseUrl, request, timeout } from '~/utils'
import {
  SSClaimRecordAttachmentState,
  SSClaimRecordExpenseTokenState,
  SSClaimTypeState,
  SSTaxRateState
} from '../../types'
import './MyClaimTaskTokenMultipleFormRow.less'

interface MyClaimTaskTokenMultipleFormRowProps {
  token: string
  index: number
  claimType: SSClaimTypeState
  data: SSClaimRecordExpenseTokenState
  attachments: SSClaimRecordAttachmentState[]
  taxRate: SSTaxRateState
}

const baseUrl = getBaseUrl('/claim')
const taxStyle: CSSProperties = { fontWeight: 'normal', fontStyle: 'italic' }
const editedTaxIndicatorStyle: CSSProperties = { position: 'absolute', top: 0 }
const expenseTypeStyle: CSSProperties = { width: '100%' }

export const MyClaimTaskTokenMultipleFormRow: FC<MyClaimTaskTokenMultipleFormRowProps> = ({
  token,
  index,
  claimType,
  data,
  attachments,
  taxRate
}) => {
  const includeTaxLabel = getInclusiveTaxLabel(taxRate?.currencyCode, '')

  const files = useMemo(() => {
    return data
      ? attachments
          .filter(att => att.claimRecordExpenseId === data.id)
          .map(
            att =>
              ({
                uid: att?.fileId || '',
                name: att?.fileName || '',
                size: att?.fileSize || 0,
                type: att?.fileFormat || '',
                status: 'done',
                url: `${baseUrl}/ssclaimtasktoken/attachment/${token}/${att?.id}/downloadfile`
              } as UploadFile)
          )
      : []
  }, [data, attachments, token])

  const handleFileDownload = useCallback(async (file: UploadFile) => {
    if (file.url) {
      const { result, status } = await request('get', file.url, undefined, {
        responseType: 'blob',
        timeout
      })
      if (status) {
        let objectUrl = window.URL.createObjectURL(result)
        window.open(objectUrl, '_blank')
      }
    }
  }, [])

  return (
    <>
      {index === 0 && (
        <Row className="my-claim-task-multiple-form-row__header" gutter={15}>
          <Col span={4}>
            <div className="my-claim-task-multiple-form-row__label">Expense type</div>
          </Col>
          <Col span={3}>
            <div className="my-claim-task-multiple-form-row__label">Expense date</div>
          </Col>
          <Col span={3}>
            <div className="my-claim-task-multiple-form-row__label">Receipt no.</div>
          </Col>
          <Col span={4}>
            <div className="my-claim-task-multiple-form-row__label">Expense amount</div>
          </Col>
          <Col span={1}>
            <div className="my-claim-task-multiple-form-row__label">GST</div>
          </Col>
          <Col span={4}>
            <div className="my-claim-task-multiple-form-row__label">Vendor / Provider</div>
          </Col>
          <Col span={4}>
            <div className="my-claim-task-multiple-form-row__label">Claim amount</div>
          </Col>
          <Col span={1} />
        </Row>
      )}
      <Row className="my-claim-task-multiple-form-row" gutter={15}>
        <Col span={4}>
          <Space direction="vertical" style={expenseTypeStyle}>
            <>{data.expenseTypeName || '-'}</>
            {data.notes && <>{data.notes}</>}
          </Space>
        </Col>
        <Col span={3}>{data.expenseDate ? moment(data.expenseDate).format('DD MMM YYYY') : '-'}</Col>
        <Col span={3}>{data.receiptNo || '-'}</Col>
        <Col span={4}>
          <Space direction="vertical">
            <AmountDisplay symbol={data.expenseCurrencyCode} value={data.expenseAmount} />
            <Space
              hidden={
                !claimType?.currencyCode ||
                !data.expenseCurrencyCode ||
                claimType?.currencyCode === data.expenseCurrencyCode
              }
            >
              <>{data.exchangeRate}</>
              {data.exchangeUnit > 1 && <SecondaryText size="small">/ {data.exchangeUnit}</SecondaryText>}
            </Space>
          </Space>
        </Col>
        <Col span={1}>
          {taxRate?.currencyCode === data.expenseCurrencyCode && claimType?.taxableType === ClaTaxableType.Yes && (
            <>{data.isTaxable ? 'Yes' : 'No'}</>
          )}
        </Col>
        <Col span={4}>
          {data.providerName || '-'}
          <UploadFileSelectorAuth
            accept={ClaAttachmentFileTypeAllowed.toString()}
            fileList={files}
            multiple
            disabled
            onPreview={handleFileDownload}
          >
            <Button size="small">choose file</Button>
          </UploadFileSelectorAuth>
        </Col>
        <Col span={4}>
          <AmountDisplay symbol={claimType?.currencyCode} value={data.claimAmount} />
          {taxRate?.currencyCode === data.expenseCurrencyCode && data.isTaxable && (
            <Row>
              <Col flex="none">
                <SecondaryText size="small" style={taxStyle}>
                  {includeTaxLabel} <AmountDisplay symbol={claimType?.currencyCode} value={data.claimAmountTax} />
                  {data.isClaimAmountTaxOverride && <span style={editedTaxIndicatorStyle}>*</span>}
                </SecondaryText>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </>
  )
}
