import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiSetDefaultHoliday } from '../api/holiday.api'
import { replaceMasters } from '../reducers'
import { HolidayState } from '../types'

export const setDefaultHoliday = (holidayId: string): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!holidayId) return

    const { status, errors, message, errorData } = await apiSetDefaultHoliday(holidayId)
    if (status) {
      const holidays = Object.values(getState().master.masters['holiday']?.entities || {}) as HolidayState[]
      const updated = holidays.map(h => ({ ...h, isDefault: h.id === holidayId }))
      dispatch(replaceMasters({ masterName: 'holiday', data: updated }))
      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
