import React, { FC, useCallback, useEffect, useState } from 'react'
import { EditableCard, EditableCardState } from '~/components'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { dispatch } from '~/stores/store'
import { Errors, ActionResult } from '~/types/store'
import { PayGroupInfoForm, EMPTY_PAYGROUP_INFO_FORM_DATA } from './PayGroupInfoForm'
import { IPayGroupInfo, PayGroupState } from '../../../types'
import { mapPayGroupStateToPayGroupInfo } from '../../../types/paygroup.mapper'
import { updateMaster } from '../../../actions'

interface PayGroupInfoProps {
  payGroup?: PayGroupState
  onEdit?: () => void
  onSave?: () => void
  onCancel?: () => void
}

export const PayGroupInfo: FC<PayGroupInfoProps> = ({ payGroup, onEdit, onSave, onCancel }: PayGroupInfoProps) => {
  const [cardState, setCardState] = useState<EditableCardState>()
  const [formData, setFormData] = useState<IPayGroupInfo>(EMPTY_PAYGROUP_INFO_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const canModify = usePermissionGate(Permission.payMaster, PermissionAction.Modify)

  useEffect(() => {
    if (payGroup) {
      setFormData({
        name: payGroup.name,
        companyId: payGroup.companyId,
        startDay: payGroup.startDay,
        startDay2: payGroup.startDay2,
        paymentDay: payGroup.paymentDay,
        paymentMonth: payGroup.paymentMonth,
        paymentDay1: payGroup.paymentDay1,
        paymentMonth1: payGroup.paymentMonth1,
        paymentDay2: payGroup.paymentDay2,
        paymentMonth2: payGroup.paymentMonth2,
        isMidMonth: payGroup.isMidMonth,
        inactiveDate: payGroup.inactiveDate,
        otStartDay: payGroup.otStartDay,
        otStartMonth: payGroup.otStartMonth,
        otStartDay1: payGroup.otStartDay1,
        otStartMonth1: payGroup.otStartMonth1,
        otStartDay2: payGroup.otStartDay2,
        otStartMonth2: payGroup.otStartMonth2,
        isAutoPayslipPublish: payGroup.isAutoPayslipPublish,
        excludePayslipEmployeeIds: payGroup.excludePayslipEmployeeIds
      })
    } else {
      setFormData(EMPTY_PAYGROUP_INFO_FORM_DATA)
    }
  }, [payGroup])

  const handleEdit = useCallback(() => {
    setCardState('editing')
    typeof onEdit === 'function' && onEdit()
  }, [onEdit])

  const handleSave = useCallback(async () => {
    if (payGroup) {
      setCardState('saving')
      setErrors(undefined)

      typeof onSave === 'function' && onSave()

      let result: ActionResult | undefined
      try {
        result = await dispatch(
          updateMaster('payGroup', payGroup.id, mapPayGroupStateToPayGroupInfo(payGroup), formData)
        )
      } catch {
        setCardState('editing')
      }

      if (result?.errors) {
        setCardState('editing')
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setCardState(undefined)
      }
    }
  }, [payGroup, formData, onSave])

  const handleCancel = useCallback(() => {
    typeof onCancel === 'function' && onCancel()
    setCardState(undefined)
    setErrors(undefined)

    if (payGroup) {
      setFormData({
        name: payGroup.name,
        companyId: payGroup.companyId,
        startDay: payGroup.startDay,
        startDay2: payGroup.startDay2,
        paymentDay: payGroup.paymentDay,
        paymentMonth: payGroup.paymentMonth,
        paymentDay1: payGroup.paymentDay1,
        paymentMonth1: payGroup.paymentMonth1,
        paymentDay2: payGroup.paymentDay2,
        paymentMonth2: payGroup.paymentMonth2,
        isMidMonth: payGroup.isMidMonth,
        inactiveDate: payGroup.inactiveDate,
        otStartDay: payGroup.otStartDay,
        otStartMonth: payGroup.otStartMonth,
        otStartDay1: payGroup.otStartDay1,
        otStartMonth1: payGroup.otStartMonth1,
        otStartDay2: payGroup.otStartDay2,
        otStartMonth2: payGroup.otStartMonth2,
        isAutoPayslipPublish: payGroup.isAutoPayslipPublish,
        excludePayslipEmployeeIds: payGroup.excludePayslipEmployeeIds
      })
    }
  }, [payGroup, onCancel])

  return (
    <EditableCard
      title="Pay group information"
      bodyStyle={{ paddingBottom: payGroup ? 6 : 24, paddingTop: 6 }}
      state={canModify ? cardState : 'readonly'}
      formId="form-paygroup-info"
      onEdit={handleEdit}
      onSave={handleSave}
      onCancel={handleCancel}
    >
      <PayGroupInfoForm
        data={formData}
        errors={errors}
        readOnly={cardState !== 'editing' && cardState !== 'saving'}
        onChange={data => setFormData({ ...formData, ...data })}
      />
    </EditableCard>
  )
}
