import { setWorkflow, setWorkflowsLoading } from '../reducers'
import { apiGetWorkflow } from '../api/workflow.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'
import { WfCategory } from '../types'

export const fetchWorkflow = (
  category: WfCategory,
  id: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!category || !id) return

    const loading = getState().workflow.workflowsLoading[category]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = (getState().workflow.workflows[category]?.ids?.length || 0) > 0
      if (hasData) return
    }

    try {
      dispatch(setWorkflowsLoading({ category, loading: true }))
      const { result, status } = await apiGetWorkflow(category, id)
      if (status) {
        dispatch(setWorkflow({ category, data: result }))
      }
    } finally {
      dispatch(setWorkflowsLoading({ category, loading: false }))
    }
  }
}
