import { Form as InternalForm, FormProps as InternalFormProps } from './Form'
import { FormItem } from './FormItem'
// import { useForm } from 'antd/lib/form/Form'

type TForm = typeof InternalForm
interface IForm extends TForm {
  Item: typeof FormItem
  // useForm: typeof useForm
}

const Form: IForm = InternalForm as IForm
Form.Item = FormItem
// Form.useForm = useForm
export { Form }

export type FormProps = InternalFormProps
