import { apiAddShiftTe } from '../api/shift-te.api'
import { ActionResult, ThunkResult } from '~/types/store'
import { emptyGuid } from '~/constants'
import { showSuccess, showError } from '~/utils'
import { setShiftTe } from '../reducers'
import { IShiftTeInfo, ShiftTeState } from '../types'

export const addShiftTe = (shiftId: string, shiftRole: IShiftTeInfo): ThunkResult<void> => {
  return async (dispatch): Promise<ActionResult | undefined> => {
    if (!shiftId) return

    const { status, result, errors, message, errorData } = await apiAddShiftTe(shiftId, {
      id: emptyGuid,
      shiftId,
      ...shiftRole
    })
    if (status) {
      const newShiftTe = {
        id: result.id,
        shiftId,
        ...shiftRole
      } as ShiftTeState
      dispatch(setShiftTe({ shiftId, data: newShiftTe }))

      showSuccess('Added')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
