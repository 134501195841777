import { replaceCtPolicies, setCtPoliciesLoading } from '../reducers'
import { apiGetCtPolicies } from '../api/ct-policy.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchCtPolicies = (
  claimTypeId: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().claim.ctPoliciesLoading[claimTypeId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().claim.ctPolicies[claimTypeId]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setCtPoliciesLoading({ claimTypeId, loading: true }))
      const { result, status } = await apiGetCtPolicies(claimTypeId)
      if (status) {
        dispatch(replaceCtPolicies({ claimTypeId, data: result }))
      }
    } finally {
      dispatch(setCtPoliciesLoading({ claimTypeId, loading: false }))
    }
  }
}
