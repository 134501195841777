import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { SSLeaveBalanceState } from '../../types'

export interface PeriodInfo {
  periodCode: string
  startDate: string
  endDate: string
}

export const selectMyLeaveBalancePeriods = createSelector(
  (state: StoreState) => state.my.myLeaveBalances,
  myLeaveBalanceState => {
    const periodCodes: string[] = []
    return Object.values(myLeaveBalanceState?.entities || {})
      .reduce((periods: PeriodInfo[], current: SSLeaveBalanceState | undefined) => {
        const periodCode = current?.periodCode || ''
        if (current && !periodCodes.includes(periodCode)) {
          periodCodes.push(periodCode)
          periods.push({ periodCode, startDate: current.startDate, endDate: current.endDate })
        }
        return periods
      }, [] as PeriodInfo[])
      .sort((a: PeriodInfo, b: PeriodInfo) => b.startDate.localeCompare(a.startDate))
  }
)
