import { createSelector, Dictionary } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { Ir21SubmissionIr21State } from '../types'

export const selectIr21ViewData = createSelector(
  (state: StoreState) => state.tax.ir21SubmissionIr21Views,
  ir21SubmissionIr21ViewsState =>
    memoize((ir21SubmissionId: string): Dictionary<Ir21SubmissionIr21State> => {
      for (const viewId of ir21SubmissionIr21ViewsState?.ids) {
        const ir21View = ir21SubmissionIr21ViewsState?.entities[viewId]
        const found = ir21View?.data.some(r => r.ir21SubmissionId === ir21SubmissionId)
        if (found && ir21View) {
          return Object.assign({}, ...ir21View.data.map(r => ({ [r.id]: r })))
        }
      }
      return {}
    })
)
