import { replaceLeaveRecordAttachment, setLeaveRecordAttachmentLoading } from '../reducers'
import { apiGetLeaveRecordAttachments } from '../api/leave-record.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchLeaveRecordAttachment = (
  id: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const loading = getState().leave.leaveRecordAttachmentLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const data = Object.values(getState().leave.leaveRecordAttachment?.entities || {})
      const hasData = data.some(x => x?.leaveRecordId === id)
      if (hasData) return
    }

    try {
      dispatch(setLeaveRecordAttachmentLoading(true))
      const { result, status } = await apiGetLeaveRecordAttachments(id)
      if (status) {
        dispatch(replaceLeaveRecordAttachment(result))
      }
    } finally {
      dispatch(setLeaveRecordAttachmentLoading(false))
    }
  }
}
