import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { SSLeaveRecordAttachmentState } from '../../types'

export const selectMyLeaveRecordAttachments = createSelector(
  (state: StoreState) => state.my.myLeaveRecordAttachments,
  myLeaveRecordAttachmentsState =>
    memoize((leaveRecordId: string) => {
      return Object.values(
        myLeaveRecordAttachmentsState[leaveRecordId]?.entities || {}
      ) as SSLeaveRecordAttachmentState[]
    })
)
