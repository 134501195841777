import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { ClaimEntitlementViewState } from '../types'

export const selectClaimEntitlementsView = createSelector(
  (state: StoreState) => state.claim.claimEntitlementsView,
  claimEntitlementsState =>
    memoize((viewId: string): ClaimEntitlementViewState => {
      return claimEntitlementsState.entities[viewId] || ({} as ClaimEntitlementViewState)
    })
)
