import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { FetchStrategy } from '~/types/common'
import { useHasChange } from '~/hooks'
import { StoreState } from '~/types/store'
import { dispatch } from '~/stores/store'
import { fetchMyTimeLogs } from '../actions'
import { SSTimeLogState } from '../types'
import { selectMyTimeLogs } from '../selectors'

export const useMyTimeLogs = (
  startDate: string | null,
  endDate: string | null,
  fetchStrategy: FetchStrategy = 'always'
): [SSTimeLogState[], boolean] => {
  const records = useSelector(selectMyTimeLogs)
  const loading = useSelector((state: StoreState) => state.myAttendance.myTimeLogsLoading)
  const isRefetch = useHasChange((state: StoreState) => state.myAttendance.myTimeLogsRefetch)
  const strategy = isRefetch ? 'always' : fetchStrategy

  useEffect(() => {
    dispatch(fetchMyTimeLogs(startDate, endDate, { strategy }))
  }, [startDate, endDate, isRefetch, strategy])

  return [records, loading]
}
