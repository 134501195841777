import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchEntitledLeaveTypes } from '../actions'
import { selectEntitledLeaveTypes } from '../selectors'
import { EntitledLeaveTypeState } from '../types'

export const useEntitledLeaveTypes = (
  employeeId: string,
  fetchStrategy: FetchStrategy = 'always'
): [EntitledLeaveTypeState[], boolean] => {
  const entitledLeaveTypes = useSelector(selectEntitledLeaveTypes)(employeeId)
  const loading = useSelector((state: StoreState) => state.leave.entitledLeaveTypesLoading[employeeId])

  useEffect(() => {
    if (employeeId) {
      dispatch(fetchEntitledLeaveTypes(employeeId, { strategy: fetchStrategy }))
    }
  }, [employeeId, fetchStrategy])

  return [entitledLeaveTypes, loading]
}
