import { request, getBaseUrl } from '~/utils/request'
import { UploadFile } from '~/core-components'
import {
  LoginPermissionState,
  LoginState,
  IIsEmployee,
  ILoginAndPermission,
  ILogin,
  IUpdatePassword,
  IUpdatePreference,
  MyTenantState,
  IResetPasswordEmpNo
} from '../types'

const baseUrl = getBaseUrl('/oauth2/login')

export const apiGetLogins = (tenantCode: string) =>
  request<LoginState[]>('get', `${baseUrl}/${tenantCode}/login`, { isAdmin: true })

export const apiGetLogin = (tenantCode: string, loginId: string) =>
  request<LoginState>('get', `${baseUrl}/${tenantCode}/login/${loginId}`)

export const apiGetLoginPermissions = (loginId: string, tenantCode: string) =>
  request<LoginPermissionState[]>('get', `${baseUrl}/${loginId}/${tenantCode}/permission`)

export const apiGetMyTenants = () => request<MyTenantState[]>('get', `${baseUrl}/mytenants`)

export const apiGetMyLogin = (tenantCode: string) => request<LoginState>('get', `${baseUrl}/${tenantCode}/mylogin`)

export const apiAddLogin = (login: ILogin) => request<{ id: string; isSuperUser: boolean }>('post', baseUrl, login)

export const apiUpdateLoginAndPermissions = (loginAndPermissions: ILoginAndPermission) =>
  request<{ id?: string; permissions?: LoginPermissionState[] }>(
    'post',
    `${baseUrl}/update-with-permissions`,
    loginAndPermissions
  )

export const apiDeleteLogin = (id: string) => request('delete', `${baseUrl}/${id}`)

export const apiUpdatePassword = (updatePassword: IUpdatePassword) =>
  request('post', `${baseUrl}/update-password`, updatePassword)

export const apiUpdatePreference = (updatePreference: IUpdatePreference) =>
  request('post', `${baseUrl}/update-preference`, updatePreference)

export const apiIsEmployee = (type: 'email' | 'employee_no', loginCode: string) =>
  request<IIsEmployee>('get', `${baseUrl}/isemployee/${type}/${loginCode}`)

export const apiAfterLogin = (id: string, tenantCode: string) =>
  request<{ lastLoginDate: string; isPasswordExpiring: boolean; forceChangePassword: boolean }>(
    'post',
    `${baseUrl}/${id}/tenant/${tenantCode}/afterlogin`
  )

export const apiSendActivation = (id: string, tenantCode: string) =>
  request('post', `${baseUrl}/${id}/tenant/${tenantCode}/sendactivation`)

export const apiDeleteLoginPhoto = (id: string) => request<void>('post', `${baseUrl}/${id}/photo/remove`)

export const apiUploadLoginPhoto = (id: string, file: UploadFile) => {
  let formData = new FormData()
  formData.append('file', file.xhr, file.name)

  return request<{ photoId: string }>('post', `${baseUrl}/${id}/photo`, formData)
}

export const apiResetPasswordEmpNo = (reset: IResetPasswordEmpNo) =>
  request('post', `${baseUrl}/reset-password-empno`, reset)
