import { apiAddShiftRoleTe } from '../api/shift-role-te.api'
import { ActionResult, ThunkResult } from '~/types/store'
import { emptyGuid } from '~/constants'
import { showSuccess, showError } from '~/utils'
import { setShiftRoleTe } from '../reducers'
import { IShiftRoleTeInfo, ShiftRoleTeState } from '../types'

export const addShiftRoleTe = (shiftRoleId: string, shiftRole: IShiftRoleTeInfo): ThunkResult<void> => {
  return async (dispatch): Promise<ActionResult | undefined> => {
    if (!shiftRoleId) return

    const { status, result, errors, message, errorData } = await apiAddShiftRoleTe(shiftRoleId, {
      id: emptyGuid,
      shiftRoleId,
      ...shiftRole
    })
    if (status) {
      const newShiftRoleTe = {
        id: result.id,
        shiftRoleId,
        ...shiftRole
      } as ShiftRoleTeState
      dispatch(setShiftRoleTe({ shiftRoleId, data: newShiftRoleTe }))

      showSuccess('Added')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
