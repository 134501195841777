import { setEmCompany, setEmCompaniesLoading } from '../reducers'
import { apiGetEmCompany } from '../api/em-company.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchEmCompany = (
  employeeId: string,
  emCompanyId: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!employeeId || !emCompanyId) return

    const loading = getState().employee.emCompaniesLoading[employeeId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().employee.emCompanies[employeeId]?.entities[emCompanyId]
      if (hasData) return
    }

    try {
      dispatch(setEmCompaniesLoading({ employeeId, loading: true }))
      const { result, status } = await apiGetEmCompany(employeeId, emCompanyId)
      if (status) {
        dispatch(setEmCompany({ employeeId, data: result }))
      }
    } finally {
      dispatch(setEmCompaniesLoading({ employeeId, loading: false }))
    }
  }
}
