import React, { FC, useCallback, useEffect, useState } from 'react'
import { Button, Form, Link } from '~/core-components'
import { ClaKeyValues, Col, EditableCard, EditableCardState, Row } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { dispatch } from '~/stores/store'
import { ActionResult } from '~/types/store'
import { Delimiter, Permission, PermissionAction } from '~/constants'
import { ClaimTypeCustomFieldState, ClaimTypeState } from '../../../types'
import { updateClaimType } from '../../../actions'

interface ClaimTypeCustomFieldProps {
  claimType?: ClaimTypeState
  onEdit?: () => void
  onSave?: () => void
  onCancel?: () => void
}

interface FormData extends ClaimTypeCustomFieldState {}

const EMPTY_FORM_DATA: FormData = {
  customField: ''
}

const MAX_COLUMN = 2

export const ClaimTypeCustomField: FC<ClaimTypeCustomFieldProps> = ({ claimType, onEdit, onSave, onCancel }) => {
  const [cardState, setCardState] = useState<EditableCardState>()
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)
  const [customFields, setCustomFields] = useState<string[]>([])
  const canModify = usePermissionGate(Permission.claType, PermissionAction.Modify)
  const readOnly = cardState !== 'editing' && cardState !== 'saving'

  const handleParseCustomField = useCallback((customField: string) => {
    let fields = customField?.split(Delimiter.pipe).filter(f => f !== '')

    if (fields?.length > 0) {
      setCustomFields(fields)
      const newCustomField = fields.join(Delimiter.pipe)
      setFormData(formData => ({ ...formData, customField: newCustomField }))
      return newCustomField
    } else {
      setFormData(EMPTY_FORM_DATA)
      setCustomFields([])
      return ''
    }
  }, [])

  useEffect(() => {
    if (claimType) {
      const { customField } = claimType
      handleParseCustomField(customField)
    } else {
      setFormData(EMPTY_FORM_DATA)
      setCustomFields([])
    }
  }, [claimType, handleParseCustomField])

  const handleEdit = useCallback(() => {
    setCardState('editing')
    typeof onEdit === 'function' && onEdit()
  }, [onEdit])

  const handleSave = useCallback(async () => {
    if (claimType) {
      setCardState('saving')

      typeof onSave === 'function' && onSave()

      let result: ActionResult | undefined
      try {
        const newCustomField = handleParseCustomField(formData.customField)
        const updatedFormData = { ...formData, customField: newCustomField }
        result = await dispatch(updateClaimType(claimType.id, claimType, updatedFormData))
      } catch {
        setCardState('editing')
      }

      if (result?.errors) {
        setCardState('editing')
      }

      if (!result?.errors) {
        setCardState(undefined)
      }
    }
  }, [claimType, formData, onSave, handleParseCustomField])

  const handleCancel = useCallback(() => {
    typeof onCancel === 'function' && onCancel()
    setCardState(undefined)

    if (claimType) {
      const { customField } = claimType
      handleParseCustomField(customField)
    }
  }, [claimType, onCancel, handleParseCustomField])

  const handleChange = useCallback((fieldCode, index) => {
    setCustomFields(customFields => {
      let updated: string[] = [
        ...customFields.slice(0, index),
        fieldCode,
        ...customFields.slice(index + 1, customFields.length)
      ]

      setFormData({ customField: updated.join(Delimiter.pipe) })
      return updated
    })
  }, [])

  const handleRemoveRow = useCallback(index => {
    setCustomFields(customFields => {
      const updated: string[] = [...customFields.slice(0, index), ...customFields.slice(index + 1, customFields.length)]

      setFormData({ customField: updated.join(Delimiter.pipe) })
      return updated
    })
  }, [])

  const handleAddRow = useCallback(() => {
    setCustomFields(customFields => {
      const updated: string[] = customFields.length >= MAX_COLUMN ? customFields : customFields.concat('')

      setFormData({ customField: updated.join(Delimiter.pipe) })
      return updated
    })
  }, [])

  return (
    <EditableCard
      title="Custom fields"
      bodyStyle={{ paddingBottom: claimType ? 6 : 24, paddingTop: 6 }}
      state={!canModify ? 'readonly' : cardState}
      formId="form-claim-type-custom-field"
      onEdit={handleEdit}
      onSave={handleSave}
      onCancel={handleCancel}
    >
      <Row gutter={20} align="middle">
        {customFields?.map((code, index) => (
          <Col key={code + index} flex="50%">
            <Row gutter={5} align="middle">
              <Col flex="1">
                <Form.Item label={`Custom field #${index + 1}`}>
                  <ClaKeyValues
                    id="claCustomField"
                    controller="cfconfig"
                    value={code}
                    readOnly={readOnly}
                    onChange={code => handleChange(code, index)}
                  />
                </Form.Item>
              </Col>
              <Col flex="50px">
                <Button
                  size="small"
                  type="text"
                  icon={<i className="fal fa-xmark" />}
                  hidden={readOnly}
                  onClick={() => handleRemoveRow(index)}
                />
              </Col>
            </Row>
          </Col>
        ))}
      </Row>

      {customFields.length < MAX_COLUMN && (
        <Row gutter={15} hidden={readOnly}>
          <Col flex="1">
            <Form.Item>
              <Link onClick={handleAddRow}>add more</Link>
            </Form.Item>
          </Col>
        </Row>
      )}
    </EditableCard>
  )
}
