import { Country } from '~/types/common'
import {
  IPayItemBasis,
  IPayItemInfo,
  IPayItem,
  PayItemState,
  PayItemInfoState,
  PayItemBasisState
} from './payitem.state'

export const mapPayItemInfoStateToPayItemInfo = (
  payItem: PayItemState | PayItemInfoState,
  country: Country
): IPayItemInfo => ({
  name: payItem.name,
  type: payItem.type,
  category: payItem.category,
  rateType: payItem.rateType,
  prorationType: payItem.prorationType,
  rate: payItem.rate,
  formulaId: payItem.formulaId,
  wageType: payItem.wageType,
  inactiveDate: payItem.inactiveDate || '',
  isSys: payItem.isSys,
  notes: payItem.notes,
  dataJsonb: JSON.stringify(country === 'sg' ? { hasSdl: payItem.hasSdl, ir8aCategory: payItem.ir8aCategory } : {})
})

export const mapPayItemToPayItemState = (dto: IPayItem, country: Country): PayItemState => {
  const dataJsonb = JSON.parse(dto.dataJsonb || '{}')

  return {
    id: dto.id,
    name: dto.name,
    type: dto.type,
    category: dto.category,
    rateType: dto.rateType,
    prorationType: dto.prorationType,
    rate: dto.rate,
    formulaId: dto.formulaId,
    wageType: dto.wageType,
    inactiveDate: dto.inactiveDate,
    isSys: dto.isSys,
    notes: dto.notes,
    ...(country === 'sg' ? { hasSdl: dataJsonb.hasSdl, ir8aCategory: dataJsonb.ir8aCategory } : {})
  }
}

export const mapPayItemBasisToPayItemBasisState = (dto: IPayItemBasis): PayItemBasisState => ({
  id: dto.id,
  payItemId: dto.payItemId,
  payItemBasisId: dto.payItemBasisId
})
