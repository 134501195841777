import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { apiUpdatePayRecurring } from '../api/payrecurring.api'
import { PayRecurringState, IPayRecurringInfo } from '../types'
import { setPayRecurring } from '../reducers'

export const updatePayRecurring = <T extends IPayRecurringInfo>(
  id: string,
  before: T,
  request: T
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const after = { ...before, ...request } as T
    const patch = generateJsonPatch<T>(before, after)
    const { status, errors, message, errorData } = await apiUpdatePayRecurring(id, patch)

    if (status) {
      let payRecurring = getState().payroll.payRecurrings?.entities[id]
      dispatch(setPayRecurring({ ...(payRecurring as PayRecurringState), ...after }))

      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
