import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { Pagination, Search } from '~/types/common'
import {
  IAddScheduleRecord,
  ScheduleRecordViewState,
  IInitialiseScheduleRecord,
  IBulkAddScheduleRecord,
  ICopyScheduleRecord
} from '../types'
import { EmSelectionViewState } from '~/features/employee'
import { EmSelectState } from '~/components'

const baseUrl = getBaseUrl('/attendance/schedulerecord')

export const apiGetScheduleRecordsView = (
  viewId: string,
  startDate: string,
  endDate: string,
  params: Pagination & Search
) => request<ScheduleRecordViewState>('get', `${baseUrl}/view/${viewId}/${startDate}/${endDate}`, params)

export const apiGetScheduleRecordEmSelectionsAll = (startDate: string, endDate: string) =>
  request<EmSelectState[]>('get', `${baseUrl}/${startDate}/${endDate}/emselections`)

export const apiGetScheduleRecordEmSelections = (
  startDate: string,
  endDate: string,
  viewId: string,
  params: Pagination & Search
) => request<EmSelectionViewState>('get', `${baseUrl}/${startDate}/${endDate}/emselections/${viewId}`, params)

export const apiInitialiseScheduleRecords = (data: IInitialiseScheduleRecord) =>
  request<void>('post', `${baseUrl}/initialise`, data)

export const apiBulkAddScheduleRecords = (data: IBulkAddScheduleRecord) =>
  request<void>('post', `${baseUrl}/bulkadd`, data)

export const apiCopyScheduleRecords = (data: ICopyScheduleRecord) => request<void>('post', `${baseUrl}/copy`, data)

export const apiAddScheduleRecord = (schedule: IAddScheduleRecord) => request<{ id: string }>('post', baseUrl, schedule)

export const apiUpdateScheduleRecord = (id: string, schedule: Operation[]) =>
  request<void>('patch', `${baseUrl}/${id}`, schedule)

export const apiDeleteScheduleRecord = (id: string) => request<void>('delete', `${baseUrl}/${id}`)

export const apiResetScheduleRecord = (id: string) => request<void>('post', `${baseUrl}/${id}/reset`)
