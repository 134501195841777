import { FC, useCallback, useState } from 'react'
import { Link, List } from '~/core-components'
import { ETaskInfo } from '~/features/my/types'
import { MyTimeLogTaskDrawer } from './components/MyTimeLogTaskDrawer'
import { MyTimeLogTaskActionModal } from './components/MyTimeLogTaskActionModal'

interface MyTimeLogTasksProps {
  data: ETaskInfo[]
}

interface DrawerState {
  visible: boolean
  id: string
}

interface ActionModalState {
  id: string
  visible: boolean
  action: 'approve' | 'reject'
}

const DRAWER_STATE: DrawerState = { visible: false, id: '' }
const DEFAULT_ACTION_MODAL_STATE: ActionModalState = { id: '', visible: false, action: 'approve' }

export const MyTimeLogTasks: FC<MyTimeLogTasksProps> = ({ data }) => {
  const [drawerState, setDrawerState] = useState<DrawerState>(DRAWER_STATE)
  const [actionModalState, setActionModalState] = useState<ActionModalState>(DEFAULT_ACTION_MODAL_STATE)

  const handleView = useCallback((id: string) => {
    setDrawerState({ visible: true, id })
  }, [])

  const handleDrawerClose = useCallback((success?: boolean) => {
    setDrawerState(DRAWER_STATE)
  }, [])

  const handleApprove = useCallback(async (id: string) => {
    setActionModalState({ id, visible: true, action: 'approve' })
  }, [])

  const handleReject = useCallback(async (id: string) => {
    setActionModalState({ id, visible: true, action: 'reject' })
  }, [])

  const handleCloseAction = useCallback((success: boolean) => {
    setActionModalState(DEFAULT_ACTION_MODAL_STATE)
  }, [])

  return (
    <>
      <List
        size="small"
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item: ETaskInfo) => (
          <List.Item
            key={item.id}
            actions={[
              <Link key="approve" onClick={() => handleApprove(item.id)}>
                approve
              </Link>,
              <Link key="reject" onClick={() => handleReject(item.id)}>
                reject
              </Link>,
              <Link key="view" onClick={() => handleView(item.id)}>
                view
              </Link>
            ]}
          >
            {item.description}
          </List.Item>
        )}
      />
      <MyTimeLogTaskDrawer {...drawerState} onClose={handleDrawerClose} />
      <MyTimeLogTaskActionModal {...actionModalState} onClose={handleCloseAction} />
    </>
  )
}
