import React from 'react'
import { useEffect } from 'react'
import { replaceMyJwt } from '~/features/iam'
import { dispatch } from '~/stores/store'
import { AuthConsumer } from './AuthProvider'

export const Logout = () => {
  useEffect(() => {
    dispatch(replaceMyJwt(undefined))
  }, [])

  return (
    <AuthConsumer>
      {({ logout }) => {
        logout()
        return null
      }}
    </AuthConsumer>
  )
}
