import React, { FC, useCallback, useMemo, useState } from 'react'
import { InfoTooltip } from '~/components'
import { LeEarnedDrawer } from './LeEarnedDrawer'
import './LeEarnedInfo.less'

type LeEarnedInfoProps = {
  tooltip: string
  periodCode?: string
  employeeId?: string
  leaveTypeId?: string
}

interface DrawerState {
  visible: boolean
  periodCode?: string
  employeeId?: string
  leaveTypeId?: string
}

const DEFAULT_MODAL_STATE: DrawerState = { visible: false }

export const LeEarnedInfo: FC<LeEarnedInfoProps> = ({ tooltip, periodCode, employeeId, leaveTypeId }) => {
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_MODAL_STATE)
  const isClickable = useMemo(
    () => !!periodCode && !!employeeId && !!leaveTypeId,
    [periodCode, employeeId, leaveTypeId]
  )

  const handleInfoClick = useCallback(() => {
    setDrawerState({
      visible: true,
      periodCode,
      employeeId,
      leaveTypeId
    })
  }, [periodCode, employeeId, leaveTypeId])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_MODAL_STATE)
  }, [])

  return (
    <>
      <InfoTooltip
        className="cell-icon"
        title={tooltip}
        overlayStyle={{ maxWidth: 280, whiteSpace: 'pre-line' }}
        {...(isClickable ? { onClick: handleInfoClick } : {})}
      />
      {isClickable && <LeEarnedDrawer {...drawerState} onClose={handleCloseDrawer} />}
    </>
  )
}
