import omit from 'lodash/omit'
import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiSaveCalendarViewer } from '../api/calendar-viewer.api'
import { CalendarViewerCategory, ICalendarViewer } from '../types'
import { Screen, fetchViewSchema } from '~/features/selection'
import { fetchCalendarViewer } from './fetch-calendar-viewer'
import { emptyGuid } from '~/constants'

export const saveCalendarViewer = (
  screenCode: Screen,
  category: CalendarViewerCategory,
  request: ICalendarViewer
): ThunkResult<void> => {
  return async dispatch => {
    if (!category) return

    request = { ...request, id: request.id || emptyGuid, employeeId: request.employeeId || emptyGuid, category }
    const { status, result, errors, message, errorData } = await apiSaveCalendarViewer(
      category,
      omit(request, 'viewId')
    )

    if (status) {
      const id = result?.id || request.id
      if (id) {
        dispatch(fetchCalendarViewer(category, id))
      }

      if (request.viewId) {
        dispatch(fetchViewSchema(screenCode, request.viewId))
      }

      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
