import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchPayRun } from '../actions'
import { selectPayRunById } from '../reducers'
import { PayRunState } from '../types'

export const usePayRun = (
  payRunId: string,
  fetchStrategy: FetchStrategy = 'when-empty'
): [PayRunState | undefined, boolean] => {
  const payRun = useSelector((state: StoreState) => selectPayRunById(state, payRunId))
  const loading = useSelector((state: StoreState) => state.payroll.payRunsLoading)

  useEffect(() => {
    dispatch(fetchPayRun(payRunId, { strategy: fetchStrategy }))
  }, [payRunId, fetchStrategy])

  return [payRun, loading]
}
