import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { HolidayState } from '../types'

export const selectHolidays = createSelector(
  (state: StoreState) => state.master.masters['holiday'],
  holidayState => {
    const holidays = Object.values(holidayState?.entities || {}) as HolidayState[]
    return holidays.sort((a, b) => a!.name?.localeCompare(b!.name))
  }
)
