import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils'
import { ExpenseTypeState, IExpenseType } from '../types'

const baseUrl = getBaseUrl('/claim/expensetype')

export const apiGetExpenseTypes = () => request<ExpenseTypeState[]>('get', baseUrl)

export const apiAddExpenseType = (expenseType: IExpenseType) => request<{ id: string }>('post', baseUrl, expenseType)

export const apiUpdateExpenseType = (id: string, expenseType: Operation[]) =>
  request('patch', `${baseUrl}/${id}`, expenseType)

export const apiDeleteExpenseType = (id: string) => request('delete', `${baseUrl}/${id}`)
