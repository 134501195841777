import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiRejectLeavePendingApproval } from '../api/leave-pending-approval.api'
import { refetchLeavePendingApprovalsView } from '../reducers'
import { ILeavePendingApproval } from '../types'

export const rejectLeavePendingApproval = (leavePendingApproval: ILeavePendingApproval): ThunkResult<void> => {
  return async dispatch => {
    const { status, result, errors, message, errorData } = await apiRejectLeavePendingApproval(leavePendingApproval)
    if (status) {
      dispatch(refetchLeavePendingApprovalsView())
      showSuccess('Rejected')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
