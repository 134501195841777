import { setDailySummaryView, setDailySummaryViewLoading } from '../reducers'
import { apiGetDailySummaryView } from '../api/daily-summary.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions, Pagination } from '~/types/common'

export const fetchDailySummaryView = (
  viewId: string,
  attendancePeriodId: string,
  pagination: Pagination,
  search: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!viewId || !attendancePeriodId) return

    const loading = getState().attendance.dailySummaryViewLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().attendance.dailySummaryView?.entities[viewId]
      if (hasData) return
    }

    try {
      dispatch(setDailySummaryViewLoading(true))
      const { result, status } = await apiGetDailySummaryView(viewId, attendancePeriodId, {
        ...pagination,
        search
      })
      if (status) {
        dispatch(setDailySummaryView(result))
      }
    } finally {
      dispatch(setDailySummaryViewLoading(false))
    }
  }
}
