import { replaceMyIrasFormsView, setMyIrasFormsViewLoading } from '../reducers'
import { apiGetMyIrasFormsView } from '../api/ss-iras-form.api'
import { ThunkResult } from '~/types/store'
import { Pagination } from '~/types/common'

export const fetchMyIrasForms = (pagination: Pagination): ThunkResult<void> => {
  return async dispatch => {
    try {
      dispatch(setMyIrasFormsViewLoading(true))
      const { result, status } = await apiGetMyIrasFormsView(pagination)
      if (status) dispatch(replaceMyIrasFormsView(result))
    } finally {
      dispatch(setMyIrasFormsViewLoading(false))
    }
  }
}
