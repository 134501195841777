import React, { FC, useCallback, useEffect } from 'react'
import classNames from 'classnames'
import { Button, Card, CardProps } from '~/core-components'
import { MyClaimTasks, refetchMyClaimTasks, useMyClaimTasks } from '~/features/my-claim'
import { MyTimeLogTasks, refetchMyTimeLogTasks, useMyTimeLogTasks } from '~/features/my-attendance'
import { dispatch } from '~/stores/store'
import { FeatureFlag } from '~/constants'
import { useMyLeaveTasks } from '../../../../hooks'
import { refetchMyLeaveTasks } from '../../../../reducers'
import { MySsAccessGate } from '../../../MySsAccessGate'
import { MyLeaveTasks } from './MyLeaveTasks/MyLeaveTasks'
import './MyTask.less'

interface MyTaskProps extends CardProps {}

const AUTO_REFRESH_INTERVAL = 5 * 60000

export const MyTask: FC<MyTaskProps> = ({ className, ...props }) => {
  const classes = classNames('my-task', { [`${className}`]: className })
  const [leaveTasks, leaveTasksLoading] = useMyLeaveTasks()
  const [claimTasks, claimTasksLoading] = useMyClaimTasks()
  const [timeLogTasks, timeLogTasksLoading] = useMyTimeLogTasks()
  const loading = leaveTasksLoading || claimTasksLoading || timeLogTasksLoading

  const fetchMyTasks = useCallback(() => {
    dispatch(refetchMyLeaveTasks())
    dispatch(refetchMyClaimTasks())
    dispatch(refetchMyTimeLogTasks())
  }, [])

  useEffect(() => {
    const intervalId = setInterval(fetchMyTasks, AUTO_REFRESH_INTERVAL)
    return () => clearInterval(intervalId)
  }, [fetchMyTasks])

  return (
    <Card
      title="My tasks"
      className={classes}
      loading={loading}
      extra={<Button icon={<i className="fal fa-rotate-right" />} onClick={fetchMyTasks} type="text" />}
      {...props}
    >
      {!loading && leaveTasks.length === 0 && claimTasks.length === 0 && <>All tasks are caught up</>}
      <MySsAccessGate featureFlag={FeatureFlag.MyLeave}>
        {leaveTasks.length > 0 && (
          <>
            <div className="subheader">Leave</div>
            <MyLeaveTasks data={leaveTasks} />
          </>
        )}
      </MySsAccessGate>
      <MySsAccessGate featureFlag={FeatureFlag.MyClaim}>
        {claimTasks.length > 0 && (
          <>
            <div className="subheader">Claim</div>
            <MyClaimTasks data={claimTasks} />
          </>
        )}
      </MySsAccessGate>
      <MySsAccessGate featureFlag={FeatureFlag.MyAttendance}>
        {timeLogTasks.length > 0 && (
          <>
            <div className="subheader">Time log</div>
            <MyTimeLogTasks data={timeLogTasks} />
          </>
        )}
      </MySsAccessGate>
    </Card>
  )
}
