import { replaceLeavePeriodYears, setLeavePeriodYearsLoading } from '../reducers'
import { apiGetLeavePeriodYears } from '../api/leave-entitlement.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchLeavePeriodYears = (options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().leave.leavePeriodYearsLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().leave.leavePeriodYears?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setLeavePeriodYearsLoading(true))
      const { result, status } = await apiGetLeavePeriodYears()
      if (status) {
        dispatch(replaceLeavePeriodYears(result))
      }
    } finally {
      dispatch(setLeavePeriodYearsLoading(false))
    }
  }
}
