import React, { FC, useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Button, Form, PageHeader, Select, Space, Tooltip, SecondaryLink } from '~/core-components'
import { Col, Row, DocumentTitle, SelectEmployees } from '~/components'
import { fetchMasters, useCompany, usePayGroup } from '~/features/master'
import { useIsMountedRef } from '~/hooks/use-is-mounted-ref'
import { PayRunStatus, SysBankFile } from '~/constants'
import { getFileTimestamp } from '~/utils/dateUtil'
import { downloadWithDom, showError } from '~/utils'
import { dispatch } from '~/stores/store'
import { Pagination } from '~/types/common'
import { StoreState, Errors } from '~/types/store'
import { apiGetBankFile } from '../../api/rpt-bank-file.api'
import { IRptBankFileSelective } from '../../types'
import { EditOutlined } from '@ant-design/icons'
import { REPORTS_ROUTES } from '~/routes/routes'
import { fetchPayRuns, selectPayRunById, selectPayRuns } from '~/features/payroll'
import { fetchBankFileEmSelections } from '../../actions'
import { selectSysBankFileById } from '../../reducers'
import { SelectBankFileDrawer } from './components/SelectBankFileDrawer'
import { BankFileUobFastGiroJsonb } from './components/BankFileUobFastGiroJsonb'
import { BankFileOcbcVelocityGiroJsonb } from './components/BankFileOcbcVelocityGiroJsonb'
import { BankFileDbsInterbankGiroJsonb } from './components/BankFileDbsInterbankGiroJsonb'
import { BankFileDbsUffFastJsonb } from './components/BankFileDbsUffFastJsonb'
import { BankFileMaybankApsJsonb } from './components/BankFileMaybankApsJsonb'
import './RptBankFile.less'

export interface RptBankFileProps {}

interface RptBankFileForm extends IRptBankFileSelective {}

interface DrawerState {
  visible: boolean
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

const EMPTY_FORM_DATA: RptBankFileForm = {
  payRunId: '',
  sysBankFileId: '',
  valueDate: '',
  batchNo: '',
  bankFileJsonb: '',
  employeeIds: [],
  employeePhotoIds: [],
  isSelective: true
}

const routes = [
  {
    path: REPORTS_ROUTES.main,
    breadcrumbName: 'Reports'
  },
  {
    path: '',
    breadcrumbName: 'Bank file'
  }
]

export const RptBankFile: FC<RptBankFileProps> = () => {
  const payRuns = useSelector(selectPayRuns)()
  const query = new URLSearchParams(useLocation().search)
  const [payRunId, setPayRunId] = useState(
    query.get('id') || payRuns.sort((a, b) => b.payPeriod.localeCompare(a.payPeriod)).slice(0, 1)[0]?.id
  )

  const [formData, setFormData] = useState<RptBankFileForm>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const [downloading, setDownloading] = useState(false)
  const isMountedRef = useIsMountedRef()
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)

  const payRun = useSelector((state: StoreState) => selectPayRunById(state, payRunId))
  const [payGroup] = usePayGroup(payRun?.payGroupId || '')
  const [company] = useCompany(payGroup?.companyId || '')
  const sysBankFile = useSelector((state: StoreState) => selectSysBankFileById(state, formData.sysBankFileId))
  const canModify = payRun?.status !== PayRunStatus.completed

  useEffect(() => {
    dispatch(fetchMasters('payGroup', { strategy: 'when-empty' }))
    dispatch(fetchPayRuns({ strategy: 'when-empty' }))
  }, [])

  useEffect(() => {
    if (payRun) {
      setFormData({
        payRunId: payRun.id,
        sysBankFileId: payRun.sysBankFileId || '',
        valueDate: payRun.valueDate || '',
        batchNo: payRun.batchNo || '',
        bankFileJsonb: payRun.bankFileJsonb || '',
        isSelective: true
      })
    }
  }, [payRun])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    if (updates.payRunId) {
      setPayRunId(updates.payRunId)
    }

    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  const handleDownload = useCallback(async () => {
    try {
      setDownloading(true)
      setErrors(undefined)

      const { status, result, errors, message, errorData } = await apiGetBankFile(payRun?.id || '', formData)

      if (errors) {
        setErrors(errors)
      }

      if (status) {
        let filename = `bank_file_${getFileTimestamp()}.zip`
        if (formData.sysBankFileId === SysBankFile.UobFastGiro) {
          let batchNoFormatted = '01'
          if (formData.batchNo !== '') {
            batchNoFormatted = formData.batchNo
          }

          filename = 'UGBI' + moment().format('DDMM') + batchNoFormatted
        }
        downloadWithDom(result, filename)
      } else {
        console.error('Error while downloading', errors)
        setErrors(errors)
        showError(message, errorData)
      }
    } finally {
      if (isMountedRef.current) setDownloading(false)
    }
  }, [payRun, formData, isMountedRef])

  const handleOpenDrawer = useCallback(() => {
    setDrawerState({ visible: true })
  }, [])

  const handleCloseDrawer = useCallback(
    (sysBankFileId?: string) => {
      if (sysBankFileId) {
        let bankFileJsonb = ''
        if (sysBankFileId === payRun?.sysBankFileId) bankFileJsonb = payRun.bankFileJsonb || ''

        handleFormDataChange({ sysBankFileId, bankFileJsonb })
      }
      setDrawerState(DEFAULT_DRAWER_STATE)
    },
    [payRun, handleFormDataChange]
  )

  const handleFetchEmployee = useCallback(
    (viewId: string, pagination: Pagination, search: string) => {
      if (payRunId) {
        dispatch(fetchBankFileEmSelections(payRunId, viewId, pagination, search))
      }
    },
    [payRunId]
  )

  const handleSelectEmployee = useCallback((employeeIds: string[]) => {
    setFormData(formData => ({ ...formData, employeeIds }))
  }, [])

  return (
    <div id="rpt-bank-file" className="rpt-bank-file">
      <DocumentTitle title="Bank File" />
      <PageHeader title="Bank file" containerId="rpt-bank-file" breadcrumb={{ routes }} />
      <Form className="rpt-bank-file__form" layout="horizontal" labelCol={{ flex: '130px' }}>
        <Row gutter={30}>
          <Col flex="1">
            <Form.Item label="Payroll run" validateStatus={errors?.payRunId ? 'error' : ''} help={errors?.payRunId}>
              <Select
                showSearch
                allowClear={false}
                optionFilterProp="title"
                dropdownMatchSelectWidth={400}
                value={formData.payRunId}
                onChange={(value: string) => handleFormDataChange({ payRunId: value })}
              >
                {payRuns.map(run => (
                  <Select.Option key={run.id} value={run.id} title={run.description}>
                    {run.description}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Button type="primary" onClick={handleDownload} loading={downloading}>
              Download
            </Button>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col span={24}>
            <Form.Item label="Bank file" validateStatus={errors?.payRunId ? 'error' : ''} help={errors?.payRunId}>
              <Space>
                <div className="bank-file__choice">{sysBankFile?.name || ''}</div>
                {canModify && (
                  <Tooltip title="Select bank file">
                    <SecondaryLink onClick={handleOpenDrawer}>
                      <EditOutlined />
                    </SecondaryLink>
                  </Tooltip>
                )}
              </Space>
            </Form.Item>
          </Col>
        </Row>
        {formData.sysBankFileId === SysBankFile.OcbcVelocityGiroFast && (
          <BankFileOcbcVelocityGiroJsonb
            valueDate={formData.valueDate}
            batchNo={formData.batchNo}
            bankFileJsonb={formData.bankFileJsonb}
            onChange={(key: string, value: string) => handleFormDataChange({ [key]: value })}
            canModify={canModify}
            errors={errors}
          />
        )}
        {formData.sysBankFileId === SysBankFile.UobFastGiro && (
          <BankFileUobFastGiroJsonb
            bankCompanyId={company?.bankCompanyId}
            valueDate={formData.valueDate}
            batchNo={formData.batchNo}
            bankFileJsonb={formData.bankFileJsonb}
            onChange={(key: string, value: string) => handleFormDataChange({ [key]: value })}
            canModify={canModify}
            errors={errors}
          />
        )}
        {formData.sysBankFileId === SysBankFile.DbsInterbankGiro && (
          <BankFileDbsInterbankGiroJsonb
            bankCompanyId={company?.bankCompanyId}
            valueDate={formData.valueDate}
            batchNo={formData.batchNo}
            bankFileJsonb={formData.bankFileJsonb}
            onChange={(key: string, value: string) => handleFormDataChange({ [key]: value })}
            canModify={canModify}
            errors={errors}
          />
        )}
        {formData.sysBankFileId === SysBankFile.DbsUffFast && (
          <BankFileDbsUffFastJsonb
            bankCompanyId={company?.bankCompanyId}
            valueDate={formData.valueDate}
            batchNo={formData.batchNo}
            bankFileJsonb={formData.bankFileJsonb}
            onChange={(key: string, value: string) => handleFormDataChange({ [key]: value })}
            canModify={canModify}
            errors={errors}
          />
        )}
        {formData.sysBankFileId === SysBankFile.MaybankAps && (
          <BankFileMaybankApsJsonb
            bankFileJsonb={formData.bankFileJsonb}
            onChange={(key: string, value: string) => handleFormDataChange({ [key]: value })}
            canModify={canModify}
            errors={errors}
          />
        )}
        <Row align="bottom">
          <Col>
            <SelectEmployees
              viewName="bank_file"
              label="Selected employee(s)"
              employeeIds={formData.employeeIds || []}
              onFetchData={handleFetchEmployee}
              onSelect={handleSelectEmployee}
            />
          </Col>
        </Row>
      </Form>
      {canModify && <SelectBankFileDrawer {...drawerState} onClose={handleCloseDrawer} />}
    </div>
  )
}
