import { Dictionary } from '@reduxjs/toolkit'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchSysLeaveTypes } from '../actions'
import { SysLeaveTypeState } from '../types'

export const useSysLeaveTypes = (): [Dictionary<SysLeaveTypeState>, boolean] => {
  const sysLeaveTypes = useSelector((state: StoreState) => state.leave.sysLeaveTypes?.entities)
  const loading = useSelector((state: StoreState) => state.leave.sysLeaveTypesLoading)

  useEffect(() => {
    dispatch(fetchSysLeaveTypes({ strategy: 'when-empty' }))
  }, [])

  return [sysLeaveTypes, loading]
}
