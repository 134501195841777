import React, { FC, useState, useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Form } from '~/core-components'
import { DrawerForm } from '~/components'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors } from '~/types/store'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { addCalendar } from '../../../actions'
import { WorkCalendarInfoState } from '../../../types'
import { EMPTY_CALENDAR_INFO_FORM_DATA, CalendarInfoForm } from './CalendarInfoForm'

export interface AddCalendarDrawerProps {
  visible: boolean
  onClose: () => void
}

export const AddCalendarDrawer: FC<AddCalendarDrawerProps> = ({ visible, onClose }: AddCalendarDrawerProps) => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<WorkCalendarInfoState>(EMPTY_CALENDAR_INFO_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()

  useEffect(() => {
    setErrors(undefined)
  }, [visible])

  useEffect(() => {
    setFormData(EMPTY_CALENDAR_INFO_FORM_DATA)
  }, [])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      result = await dispatch(addCalendar(formData))
    } finally {
      setLoading(false)
    }
    if (result?.errors) {
      setErrors(result.errors)
    }
    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_CALENDAR_INFO_FORM_DATA)

      if (result?.result?.id) {
        const calendarId = result.result.id
        history.push(`${SETTINGS_ROUTES.workCalendar.replace(':id', calendarId)}`)
      }
    }
  }, [formData, onClose, history])

  return (
    <DrawerForm
      open={visible}
      title="Add calendar"
      onClose={onClose}
      confirmLoading={loading}
      width={500}
      formId="form-add-calendar"
    >
      <Form id="form-add-calendar" onFinish={handleOk}>
        <CalendarInfoForm data={formData} errors={errors} onChange={handleFormDataChange} />
      </Form>
    </DrawerForm>
  )
}
