import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Dictionary } from '@reduxjs/toolkit'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { LeaveTypeState } from '../types'
import { fetchLeaveTypes } from '../actions'

export const useLeaveTypesDict = (): [Dictionary<LeaveTypeState>, boolean] => {
  const leaveTypes = useSelector((state: StoreState) => state.leave.leaveTypes.entities)
  const loading = useSelector((state: StoreState) => state.leave.leaveTypesLoading)

  useEffect(() => {
    dispatch(fetchLeaveTypes({ strategy: 'when-empty' }))
  }, [])

  return [leaveTypes, loading]
}
