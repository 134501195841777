import React, { forwardRef, useCallback, KeyboardEvent } from 'react'
import { default as AntLink, LinkProps as AntLinkProps } from 'antd/lib/typography/Link'
import classNames from 'classnames'
import 'antd/es/typography/style'
import './Link.less'

export interface LinkProps extends AntLinkProps {
  size?: 'default' | 'small'
  variant?: 'secondary'
  noWrap?: boolean
}

const LinkInternal = ({ size = 'default', variant, noWrap, className, ...props }: LinkProps, ref: React.Ref<any>) => {
  const classes = classNames(
    'link',
    { [`link--${variant}`]: variant },
    { [`link--${size}`]: size },
    { 'link--no-wrap': noWrap },
    { [`${className}`]: className }
  )

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLAnchorElement>) => {
      if (event.key === 'Enter') {
        typeof props.onClick === 'function' && props.onClick(null as any)
      }
    },
    [props]
  )

  return <AntLink ref={ref} tabIndex={0} {...props} className={classes} onKeyDown={handleKeyDown} />
}

export const Link = forwardRef(LinkInternal)

const SecondaryLinkInternal = (props: LinkProps, ref: React.Ref<any>) => (
  <Link ref={ref} {...props} variant="secondary" />
)

export const SecondaryLink = forwardRef(SecondaryLinkInternal)
