import React, { FC, useCallback, useEffect, useState } from 'react'
import { ColProps } from 'antd/lib/grid/col'
import { Screen } from '../types'
import { Button, Form, Link } from '~/core-components'
import { Col, Row } from '~/components'
import { SelectionFields } from './components/SelectionFields'
import './ViewSelectionFieldList.less'

interface ViewSelectionFieldListProps {
  screenCode: Screen
  direction?: 'vertical' | 'horizontal'
  max?: number
  fieldIds?: string[]
  labelCol?: ColProps
  onChange?: (fieldIds: string[]) => void
}

const EMPTY_ARRAY = ['']

export const ViewSelectionFieldList: FC<ViewSelectionFieldListProps> = ({
  screenCode,
  direction = 'vertical',
  max = 100,
  fieldIds,
  labelCol = { flex: '70px' },
  onChange
}) => {
  const [selectedIds, setSelectedIds] = useState<string[]>([])

  useEffect(() => {
    if (fieldIds && fieldIds.length > 0) {
      setSelectedIds(fieldIds)
    } else {
      setSelectedIds(EMPTY_ARRAY)
    }
  }, [fieldIds])

  const handleChange = useCallback(
    (fieldId, index) => {
      setSelectedIds(selectedIds => {
        const updated: string[] = [
          ...selectedIds.slice(0, index),
          fieldId,
          ...selectedIds.slice(index + 1, selectedIds.length)
        ]

        typeof onChange === 'function' && onChange(updated)
        return updated
      })
    },
    [onChange]
  )

  const handleRemoveRow = useCallback(
    index => {
      setSelectedIds(selectedIds => {
        const updated: string[] = [...selectedIds.slice(0, index), ...selectedIds.slice(index + 1, selectedIds.length)]
        typeof onChange === 'function' && onChange(updated)
        return updated
      })
    },
    [onChange]
  )

  const handleAddRow = useCallback(() => {
    setSelectedIds(selectedIds => {
      const updated: string[] = selectedIds.length >= max ? selectedIds : selectedIds.concat('')
      typeof onChange === 'function' && onChange(updated)
      return updated
    })
  }, [max, onChange])

  return (
    <div className="view-selection-field-list">
      {direction === 'vertical' &&
        selectedIds.map((id, index) => (
          <Row key={id + index} gutter={15} align="middle">
            <Col flex="1">
              <Form.Item label="Column" labelCol={labelCol}>
                <SelectionFields key={id} screenCode={screenCode} value={id} onChange={id => handleChange(id, index)} />
              </Form.Item>
            </Col>
            <Col flex="65px" style={{ marginBottom: 9 }}>
              <Link onClick={() => handleRemoveRow(index)}>remove</Link>
            </Col>
          </Row>
        ))}

      {direction === 'horizontal' && (
        <Row gutter={30} align="middle">
          {selectedIds.map((id, index) => (
            <Col flex="50%">
              <Row key={id + index} gutter={15} align="middle">
                <Col flex="1">
                  <Form.Item label="Column" labelCol={labelCol}>
                    <SelectionFields
                      key={id}
                      screenCode={screenCode}
                      value={id}
                      onChange={id => handleChange(id, index)}
                    />
                  </Form.Item>
                </Col>
                <Col flex="65px" style={{ marginBottom: 9 }}>
                  <Link onClick={() => handleRemoveRow(index)}>remove</Link>
                </Col>
              </Row>
            </Col>
          ))}
        </Row>
      )}

      {selectedIds.length < max && (
        <Row gutter={15}>
          <Col flex="1">
            <Form.Item label="&nbsp;" labelCol={labelCol} colon={false}>
              <Button type="dashed" block onClick={handleAddRow}>
                Add column
              </Button>
            </Form.Item>
          </Col>
          <Col flex="65px" />
        </Row>
      )}
    </div>
  )
}
