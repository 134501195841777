import { showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiUploadTimeLogImport } from '../api/time-log-import.api'
import { replaceTimeLogImport } from '../reducers'
import { ImportTimeLogFormData } from '../containers/TimeLogs/components/ImportTimeLog/ImportTimeLogDrawer'

export const uploadTimeLogImport = (formData: ImportTimeLogFormData): ThunkResult<void> => {
  return async dispatch => {
    const { status, result, errors, message, errorData } = await apiUploadTimeLogImport(formData)
    if (status) {
      dispatch(replaceTimeLogImport(result))
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }

    return { errors, result }
  }
}
