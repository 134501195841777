import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { A8bFormState } from '../types'

export const selectA8bForm = createSelector(
  (state: StoreState) => state.tax.a8bForm,
  a8bFormState =>
    memoize(
      (ytdSubmissionId: string, employeeTaxNo: string): A8bFormState | undefined => {
        const form = a8bFormState?.entities[employeeTaxNo]
        if (form?.ytdSubmissionId === ytdSubmissionId) return form
      },
      (ytdSubmissionId: string, employeeTaxNo: string) => `${ytdSubmissionId}|${employeeTaxNo}`
    )
)
