import React, { CSSProperties, FC, useMemo } from 'react'
import { default as AntRow, RowProps as AntRowProps } from 'antd/es/row'

interface RowProps extends AntRowProps {
  hidden?: boolean
}
export const Row: FC<RowProps> = ({ hidden, style, ...props }: RowProps) => {
  const getStyle = useMemo((): CSSProperties => {
    return { ...style, ...(hidden ? { display: 'none' } : {}) }
  }, [style, hidden])

  return <AntRow style={getStyle} {...props} />
}
