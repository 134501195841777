import { showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiPrepareLeaveImport } from '../api/leave-import.api'
import { replaceLeaveImport } from '../reducers'

export const prepareLeaveImport = (payRunId: string, upToDate: string): ThunkResult<void> => {
  return async dispatch => {
    const { status, result, errors, message, errorData } = await apiPrepareLeaveImport(payRunId, upToDate)
    if (status) {
      dispatch(replaceLeaveImport(result))
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors, result }
  }
}
