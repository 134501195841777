import React, { FC, useState, useCallback, useEffect, ChangeEvent } from 'react'
import moment from 'moment-timezone'
import confirm from 'antd/lib/modal/confirm'
import { Checkbox, CheckboxChangeEvent, Form, Input } from '~/core-components'
import { Col, DrawerForm, Row } from '~/components'
import { dispatch } from '~/stores/store'
import { addShiftCategory, deleteShiftCategory, updateShiftCategory } from '../../../actions'
import { IShiftCategory, ShiftCategoryState } from '../../../types'
import { ActionResult, Errors } from '~/types/store'
import { useFocus } from '~/hooks/use-focus'

export interface MutateShiftCategoryDrawerProps {
  visible: boolean
  data?: ShiftCategoryState
  onClose: () => void
}

const EMPTY_FORM_DATA: IShiftCategory = {
  name: ''
}

export const MutateShiftCategoryDrawer: FC<MutateShiftCategoryDrawerProps> = ({
  visible,
  data,
  onClose
}: MutateShiftCategoryDrawerProps) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<IShiftCategory>(EMPTY_FORM_DATA)
  const [focusRef, setFocus] = useFocus(true)
  const [errors, setErrors] = useState<Errors>()
  const [isInactive, setIsInactive] = useState(false)

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  useEffect(() => {
    if (data) {
      setFormData({ name: data.name, inactiveDate: data.inactiveDate })
      setIsInactive(!!data.inactiveDate)
    } else {
      setFormData(EMPTY_FORM_DATA)
      setIsInactive(false)
    }
  }, [data])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      const updated = { ...formData, ...updates }
      setFormData(updated)
      setIsInactive(!!updated.inactiveDate)
    },
    [formData]
  )

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      if (data) {
        result = await dispatch(updateShiftCategory(data.id, data, formData))
      } else {
        result = await dispatch(addShiftCategory(formData))
      }
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data, formData, onClose])

  const handleDelete = useCallback(
    (master: ShiftCategoryState | undefined) => {
      if (master) {
        const { id, name } = master
        confirm({
          title: `Delete shift category`,
          content: `Do you want to delete shift category record of "${name}"?`,
          onOk: async () => {
            const result: ActionResult | undefined = await dispatch(deleteShiftCategory(id))
            if (result?.errors) {
              setErrors(result.errors)
            }

            if (!result?.errors) {
              typeof onClose === 'function' && onClose()
            }
          },
          okText: 'Delete',
          okType: 'danger'
        })
      }
    },
    [onClose]
  )

  return (
    <DrawerForm
      open={visible}
      title={data ? `Edit shift category` : `Add shift category`}
      onClose={onClose}
      confirmLoading={loading}
      width={500}
      showDelete={data ? true : false}
      onDelete={() => handleDelete(data)}
      formId="form-shift-category"
    >
      <Form id="form-shift-category" onFinish={handleOk}>
        <Row gutter={15}>
          <Col flex="auto">
            <Form.Item label="Name" validateStatus={errors?.name ? 'error' : ''} help={errors?.name}>
              <Input
                value={formData.name}
                ref={focusRef}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ name: event.target.value })}
              />
            </Form.Item>
          </Col>
          <Col flex="none">
            <Form.Item
              label="Inactive"
              validateStatus={errors?.inactiveDate ? 'error' : ''}
              help={errors?.inactiveDate}
            >
              <Checkbox
                checked={isInactive}
                onChange={(event: CheckboxChangeEvent) => {
                  setIsInactive(event.target.checked)
                  handleFormDataChange({ inactiveDate: event.target.checked ? moment().format('YYYY-MM-DD') : '' })
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}
