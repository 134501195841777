export class ReportTemplateName {
  static get default() {
    return 'Default'
  }
}

export class ReportType {
  static get summary() {
    return 's'
  }
  static get detailed() {
    return 'd'
  }
}

export class ReportFilter {
  static get leaveDate() {
    return 'ld'
  }
  static get payRun() {
    return 'pr'
  }
}

export class RptEmStatus {
  static get all() {
    return 'all'
  }
  static get existing() {
    return 'existing'
  }
  static get resignees() {
    return 'resignees'
  }
}

export class EmFlexi {
  static get employee() {
    return 'employee'
  }
}

export class OedSubmissionStatus {
  static get draft() {
    return 'd'
  }
  static get submitted() {
    return 's'
  }
}

export class OedPremiseType {
  static get headquarters() {
    return 'prt01'
  }
  static get branch() {
    return 'prt02'
  }
  static get clientPremise() {
    return 'prt03'
  }
  static get mobile() {
    return 'prt04'
  }
  static get workFromHome() {
    return 'prt05'
  }
  static get stationedOverseas() {
    return 'prt06'
  }
}
