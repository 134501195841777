import { request, getBaseUrl } from '~/utils'
import {
  IPreparePendingLeave,
  IPrepareNextEntitlement,
  ILeaveClosing,
  IRevertLeaveClosing,
  PendingLeaveResultState,
  NextEntitlementResultState,
  LeaveClosingResultState
} from '../types'

const baseUrl = getBaseUrl('/leave/leaveclosing')

export const apiGetLeavePeriods = () => request('get', `${baseUrl}/period`)

export const apiPreparePendingLeave = (data: IPreparePendingLeave) =>
  request<PendingLeaveResultState>('post', `${baseUrl}/prepare`, data)

export const apiPrepareNextEntitlement = (data: IPrepareNextEntitlement) =>
  request<NextEntitlementResultState>('post', `${baseUrl}/verify`, data)

export const apiExecuteLeaveClosing = (data: ILeaveClosing) =>
  request<LeaveClosingResultState>('post', `${baseUrl}/execute`, data)

export const apiRevertLeaveClosing = (data: IRevertLeaveClosing) => request<void>('post', `${baseUrl}/revert`, data)
