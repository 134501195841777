import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchAttendancePeriod } from '../actions'
import { selectAttendancePeriodById } from '../reducers'
import { AttendancePeriodState } from '../types'

export const useAttendancePeriod = (
  attendancePeriodId?: string,
  fetchStrategy: FetchStrategy = 'when-empty'
): [AttendancePeriodState | undefined, boolean] => {
  const period = useSelector((state: StoreState) => selectAttendancePeriodById(state, attendancePeriodId || ''))
  const loading = useSelector((state: StoreState) => state.attendance.attendancePeriodsLoading)

  useEffect(() => {
    dispatch(fetchAttendancePeriod(attendancePeriodId, { strategy: fetchStrategy }))
  }, [attendancePeriodId, fetchStrategy])

  return [period, loading]
}
