import { showSuccess, showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { apiAddEmailGroup } from '../api/email-group.api'
import { setEmailGroup } from '../reducers'
import { EmailGroupState, IEmailGroupInfo } from '../types/email-group.state'

export const addEmailGroup = (emailgroup: IEmailGroupInfo): ThunkResult<void> => {
  return async (dispatch): Promise<ActionResult> => {
    const { status, result, errors, message, errorData } = await apiAddEmailGroup(emailgroup)
    if (status) {
      const newEmailGroup: EmailGroupState = { id: result.id, ...emailgroup }
      dispatch(setEmailGroup(newEmailGroup))

      showSuccess('Added')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
