import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiDeleteCompanyOffice } from '../api/company.api'
import { removeCompanyOffice } from '../reducers'

export const deleteCompanyOffice = (id: string, companyId: string, officeId: string): ThunkResult<void> => {
  return async dispatch => {
    if (!id || !companyId || !officeId) return

    const { status, errors, message, errorData } = await apiDeleteCompanyOffice(companyId, officeId)
    if (status) {
      dispatch(removeCompanyOffice(id))
      showSuccess('Successfully unlinked')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
