import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment-timezone'
import { Alert, Button, Drawer, Space, Steps } from '~/core-components'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors, StoreState } from '~/types/store'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { showError } from '~/utils'
import { usePayEncashMaps } from '../../../hooks'
import { EncashImportExecuteState, EncashImportSourceState, EncashImportState } from '../../../types'
import { prepareEncashImport, processPayroll, executeEncashImport, validateEncashImport } from '../../../actions'
import { ImportEncashInput } from './components/ImportEncashInput'
import { SelectEncashRecord } from './components/SelectEncashRecord'
import { ImportEncashRecord } from './components/ImportEncashRecord'
import { ValidateEncashReport } from './components/ValidateEncashReport'
import './ImportEncashDrawer.less'

interface ImportEncashDrawerProps {
  payRunId: string
  visible: boolean
  onClose: () => void
}

export interface ImportEncashFormData {
  encashType: string
  upToDate: string
  source: EncashImportSourceState[]
  selected: EncashImportSourceState[]
}

const EMPTY_FORM_DATA: ImportEncashFormData = {
  encashType: 'resign',
  upToDate: moment().format('YYYY-MM-DD'),
  source: [],
  selected: []
}

const { Step } = Steps

type StepKey = 'input' | 'select' | 'validate' | 'verify' | 'complete'

interface IStep {
  key: StepKey
  title: string
  nextButton?: string
  backButton?: string
}

const STEPS: IStep[] = [
  {
    key: 'input',
    title: 'Specify date'
  },
  {
    key: 'select',
    title: 'Select records'
  },
  {
    key: 'validate',
    title: 'Validate'
  },
  {
    key: 'verify',
    title: 'Verify and import',
    nextButton: 'Import'
  },
  {
    key: 'complete',
    title: 'Done',
    nextButton: 'Close'
  }
]

export const ImportEncashDrawer: FC<ImportEncashDrawerProps> = ({ payRunId, visible, onClose }) => {
  const [formData, setFormData] = useState<ImportEncashFormData>(EMPTY_FORM_DATA)
  const [step, setStep] = useState(0)
  const [nextLoading, setNextLoading] = useState(false)
  const [errors, setErrors] = useState<Errors>()
  const payRun = useSelector((state: StoreState) => state.payroll.payRuns?.entities[payRunId])
  const endDate = payRun?.endDate || moment().format('YYYY-MM-DD')
  const hasDataToImport = useSelector(
    (state: StoreState) => (state.payroll.encashImportValidate?.summary?.proceed || 0) > 0
  )
  const [payEncashMaps] = usePayEncashMaps(payRun?.payGroupId || '')

  useEffect(() => {
    setStep(0)
    setErrors(undefined)
    setFormData({ ...EMPTY_FORM_DATA, upToDate: endDate })
  }, [visible, endDate])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setErrors(undefined)
    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  const prepareEncashRecords = useCallback(async () => {
    const errorMessages: string[] = []
    if (!formData.upToDate) {
      errorMessages.push('Please specify date')
    }

    if (errorMessages.length > 0) {
      setErrors({ '*': errorMessages })
      return
    }

    let result: ActionResult<EncashImportState> | undefined
    try {
      setNextLoading(true)
      result = await dispatch(prepareEncashImport(payRunId, formData.encashType, formData.upToDate))

      const source = result?.result?.source || []
      handleFormDataChange({ source, selected: source })
    } finally {
      setNextLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      setErrors(undefined)
      setStep(step => step + 1)
    }
  }, [payRunId, formData.encashType, formData.upToDate, handleFormDataChange])

  const filterSource = useCallback(async () => {
    const errorMessages: string[] = []
    if (formData.selected.length === 0) {
      errorMessages.push('No leave balance is selected')
    }

    if (errorMessages.length > 0) {
      setErrors({ '*': errorMessages })
      return
    }

    let result: ActionResult<EncashImportExecuteState> | undefined
    try {
      setNextLoading(true)
      const ids = formData.selected.map(s => s.id)
      result = await dispatch(validateEncashImport(payRunId, formData.encashType, ids))
    } finally {
      setNextLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      setErrors(undefined)
      setStep(step => step + 1)
    }
  }, [payRunId, formData.encashType, formData.selected])

  const executeImport = useCallback(async () => {
    let result: ActionResult<EncashImportExecuteState> | undefined
    try {
      setNextLoading(true)
      result = await dispatch(executeEncashImport(payRunId))
    } finally {
      setNextLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      setErrors(undefined)
      setStep(step => step + 1)

      const employeeIds = result?.result?.employeeIdList
      if (employeeIds) {
        dispatch(processPayroll(payRunId, employeeIds))
      }
    }
  }, [payRunId])

  const handlePrev = useCallback(() => {
    setErrors(undefined)
    setStep(step => step - 1)
  }, [])

  const handleCloseDrawer = useCallback(() => {
    typeof onClose === 'function' && onClose()
  }, [onClose])

  const handleNext = useCallback(async () => {
    if (STEPS[step].key === 'input') {
      await prepareEncashRecords()
    } else if (STEPS[step].key === 'select') {
      await filterSource()
    } else if (STEPS[step].key === 'validate') {
      if (hasDataToImport) {
        setStep(step => step + 1)
      } else {
        showError('No row to import')
      }
    } else if (STEPS[step].key === 'verify') {
      await executeImport()
    } else if (STEPS[step].key === 'complete') {
      handleCloseDrawer()
    }
  }, [step, prepareEncashRecords, filterSource, executeImport, hasDataToImport, handleCloseDrawer])

  return (
    <Drawer
      open={visible}
      title="Encash leave balance"
      onClose={handleCloseDrawer}
      width="80%"
      className="import-encash-drawer"
      footer={
        <Space className="import-encash-drawer__action-bar-buttons">
          {STEPS[step - 1] && step !== STEPS.length - 1 && (
            <Button onClick={handlePrev}>{STEPS[step].backButton || 'Back'}</Button>
          )}
          {STEPS[step] && (
            <Button type="primary" onClick={handleNext} loading={nextLoading}>
              {STEPS[step].nextButton || 'Next'}
            </Button>
          )}
        </Space>
      }
    >
      <Steps progressDot size="small" current={step}>
        {STEPS.map(s => (
          <Step key={s.key} title={s.title} />
        ))}
      </Steps>
      {payEncashMaps.length === 0 && payRun?.payGroupId && (
        <Alert
          className="import-encash-drawer__empty-settings"
          type="warning"
          message={
            <>
              The leave encashment mapping for this payroll group has not been setup. Please configure it{' '}
              <Link
                to={SETTINGS_ROUTES.payGroup.replace(':id', payRun.payGroupId).replace(':tab?', 'integration')}
                target="_blank"
              >
                here
              </Link>
              .
            </>
          }
        />
      )}
      <ImportEncashInput
        visible={STEPS[step].key === 'input'}
        data={formData}
        onChange={handleFormDataChange}
        errors={errors}
      />
      <SelectEncashRecord
        visible={STEPS[step].key === 'select'}
        data={formData}
        onSelect={handleFormDataChange}
        errors={errors}
      />
      <ValidateEncashReport visible={STEPS[step].key === 'validate'} />
      <ImportEncashRecord
        encashType={formData.encashType}
        visible={['verify', 'complete'].includes(STEPS[step].key)}
        success={STEPS[step].key === 'complete'}
      />
    </Drawer>
  )
}
