import React, { FC, useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Form } from '~/core-components'
import { Col, DrawerForm, EmpKeyValues, YearKeyValues, Row, SelectEmployeesByTaxNo } from '~/components'
import { useFocus } from '~/hooks/use-focus'
import { TAX_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors, StoreState } from '~/types/store'
import { Pagination } from '~/types/common'
import { addIr21Submission } from '../../actions'
import { IIr21SubmissionNew } from '../../types'
import { fetchIr21NewSubmissionEmSelections } from '../../actions'

export interface AddIr21SubmissionDrawerProps {
  visible: boolean
  onClose: () => void
}

interface FormData extends IIr21SubmissionNew {}

const currentYear = moment().year()

export const EMPTY_FORM_DATA: FormData = {
  ytdYear: currentYear,
  employerTaxNo: '',
  employeeTaxNos: []
}

export const AddIr21SubmissionDrawer: FC<AddIr21SubmissionDrawerProps> = ({ visible, onClose }) => {
  const [focusRef, setFocus] = useFocus(true)
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const history = useHistory()
  const employerTaxNos = useSelector((state: StoreState) => state.master.keyvalues['employerTax']?.ids)

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  useEffect(() => {
    setFormData(formData => {
      if (!formData.employerTaxNo) {
        if (employerTaxNos && employerTaxNos.length > 0) {
          return { ...formData, employerTaxNo: employerTaxNos[0].toString() }
        }
      }
      return formData
    })
  }, [employerTaxNos])

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      result = await dispatch(addIr21Submission(formData))
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()

      if (result?.result?.id) {
        const ir21SubmissionId = result?.result?.id
        history.push(TAX_ROUTES.ir21Submission.replace(':id', ir21SubmissionId).replace(':tab?', 'ir21'))
      }
    }
  }, [formData, history, onClose])

  const handleCloseDrawer = useCallback(() => {
    typeof onClose === 'function' && onClose()
  }, [onClose])

  const handleFetchEmployee = useCallback(
    (viewId: string, pagination: Pagination, search: string) => {
      dispatch(fetchIr21NewSubmissionEmSelections(formData.ytdYear, formData.employerTaxNo, viewId, pagination, search))
    },
    [formData.ytdYear, formData.employerTaxNo]
  )

  const handleSelectEmployee = useCallback((employeeTaxNos: string[]) => {
    setFormData(formData => ({ ...formData, employeeTaxNos }))
  }, [])

  return (
    <DrawerForm
      open={visible}
      title="Create new IR21 submission"
      okText="Create"
      onClose={handleCloseDrawer}
      confirmLoading={loading}
      width={500}
      className="add-ir21-submission-drawer"
      formId="form-add-ir21-submission"
    >
      <Form id="form-add-ir21-submission" onFinish={handleOk}>
        <Row>
          <Col span={24}>
            <Form.Item label="Year" validateStatus={errors?.ytdYear ? 'error' : ''} help={errors?.ytdYear}>
              <YearKeyValues
                ref={focusRef}
                value={formData.ytdYear}
                startYear={currentYear}
                noOfYearBefore={-10}
                noOfYearAfter={1}
                allowClear={false}
                onChange={(value: string) => {
                  handleFormDataChange({ ytdYear: value })
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Company"
              validateStatus={errors?.employerTaxNo ? 'error' : ''}
              help={errors?.employerTaxNo}
            >
              <EmpKeyValues
                id="employerTax"
                display="keyvalue"
                value={formData.employerTaxNo}
                allowClear={false}
                onChange={(value: string) => handleFormDataChange({ employerTaxNo: value })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <SelectEmployeesByTaxNo
              viewName="ir21_newem"
              employeeTaxNos={formData.employeeTaxNos || []}
              onFetchData={handleFetchEmployee}
              onSelect={handleSelectEmployee}
            />
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}
