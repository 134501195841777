import { request, getBaseUrl } from '~/utils/request'
import { SSTimeLogTaskTokenState } from '../types'

const baseUrl = getBaseUrl('/attendance/sstimelogtasktoken')

export const apiGetMyTimeLogTaskByToken = (token: string) =>
  request<SSTimeLogTaskTokenState>('get', `${baseUrl}/${token}`)

export const apiApproveTimeLogByToken = (token: string, comment: string) =>
  request('post', `${baseUrl}/approve/${token}`, { comment })

export const apiRejectTimeLogByToken = (token: string, comment: string) =>
  request('post', `${baseUrl}/reject/${token}`, { comment })
