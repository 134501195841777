import React, { FC, useCallback, useEffect, useState } from 'react'
import { EditableCard, EditableCardState } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { ActionResult, Errors } from '~/types/store'
import { Permission, PermissionAction } from '~/constants'
import { dispatch } from '~/stores/store'
import { updateAttendanceGroup } from '../../../actions'
import { AttendanceGroupState, IAttendanceGroupInfo } from '../../../types'
import { AttendanceGroupInfoForm, EMPTY_ATTENDANCE_GROUP_INFO_FORM_DATA } from './AttendanceGroupInfoForm'
import { mapAttendanceGroupStateToAttendanceGroupInfo } from '~/features/attendance/types/attendance-group.mapper'

interface AttendanceGroupInfoProps {
  attendanceGroup?: AttendanceGroupState
  onEdit?: () => void
  onSave?: () => void
  onCancel?: () => void
}

interface FormData extends IAttendanceGroupInfo {}

export const AttendanceGroupInfo: FC<AttendanceGroupInfoProps> = ({ attendanceGroup, onEdit, onSave, onCancel }) => {
  const [cardState, setCardState] = useState<EditableCardState>()
  const [formData, setFormData] = useState<FormData>(EMPTY_ATTENDANCE_GROUP_INFO_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const canModify = usePermissionGate(Permission.attendanceGroup, PermissionAction.Modify)
  const readOnly = cardState !== 'editing' && cardState !== 'saving'

  useEffect(() => {
    if (attendanceGroup) {
      const { name, inactiveDate, type, otStartDay, otStartMonth } = attendanceGroup
      setFormData({ name, inactiveDate, type, otStartDay, otStartMonth })
    } else {
      setFormData(EMPTY_ATTENDANCE_GROUP_INFO_FORM_DATA)
    }
  }, [attendanceGroup])

  const handleEdit = useCallback(() => {
    setCardState('editing')
    typeof onEdit === 'function' && onEdit()
  }, [onEdit])

  const handleSave = useCallback(async () => {
    if (attendanceGroup) {
      setCardState('saving')
      setErrors(undefined)

      typeof onSave === 'function' && onSave()

      let result: ActionResult | undefined
      try {
        result = await dispatch(
          updateAttendanceGroup(
            attendanceGroup.id,
            mapAttendanceGroupStateToAttendanceGroupInfo(attendanceGroup),
            formData
          )
        )
      } catch {
        setCardState('editing')
      }

      if (result?.errors) {
        setCardState('editing')
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setCardState(undefined)
      }
    }
  }, [attendanceGroup, formData, onSave])

  const handleCancel = useCallback(() => {
    typeof onCancel === 'function' && onCancel()
    setCardState(undefined)
    setErrors(undefined)

    if (attendanceGroup) {
      const { name, inactiveDate, type, otStartDay, otStartMonth } = attendanceGroup
      setFormData({ name, inactiveDate, type, otStartDay, otStartMonth })
    }
  }, [attendanceGroup, onCancel])

  return (
    <EditableCard
      title="Attendance group information"
      bodyStyle={{ paddingBottom: attendanceGroup ? 6 : 24, paddingTop: 6 }}
      state={!canModify ? 'readonly' : cardState}
      formId="form-attendance-group-info"
      onEdit={handleEdit}
      onSave={handleSave}
      onCancel={handleCancel}
    >
      <AttendanceGroupInfoForm
        data={formData}
        errors={errors}
        readOnly={readOnly}
        onChange={data => setFormData({ ...formData, ...data })}
      />
    </EditableCard>
  )
}
