import { replaceOthersBackInDays, setOthersBackInDaysLoading } from '../../reducers'
import { apiGetOthersBackInDays } from '../../api/ssleave.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchOthersBackInDays = (
  leaveRecordIds: string[],
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().my.othersBackInDaysLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().my.othersBackInDays?.ids.length > 0
      if (hasData) return
    }

    try {
      dispatch(setOthersBackInDaysLoading(true))
      const { result, status } = await apiGetOthersBackInDays({ leaveRecordIds })
      if (status) dispatch(replaceOthersBackInDays(result))
    } finally {
      dispatch(setOthersBackInDaysLoading(false))
    }
  }
}
