import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiDeleteA8bDraft } from '../api/ytd.api'
import { setA8bFormDeleting } from '../reducers'

export const deleteA8bDraft = (id: string): ThunkResult<void> => {
  return async dispatch => {
    if (!id) return

    try {
      dispatch(setA8bFormDeleting(true))
      const { status, errors, message, errorData } = await apiDeleteA8bDraft(id)
      if (status) {
        showSuccess('Deleted')
      } else {
        if (Object.keys(errors).length === 0) {
          showError(message, errorData)
        }
      }
      return { errors }
    } finally {
      dispatch(setA8bFormDeleting(false))
    }
  }
}
