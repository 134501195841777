import React, { FC, useCallback, useEffect, useState } from 'react'
import { Checkbox, CheckboxChangeEvent, Form } from '~/core-components'
import { Col, EditableCard, EditableCardState, Row, SysOptions } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { SelectWorkflow } from '~/features/workflow'
import { dispatch } from '~/stores/store'
import { Errors, ActionResult } from '~/types/store'
import { ClaApproval, Permission, PermissionAction } from '~/constants'
import { useFocus } from '~/hooks/use-focus'
import { ClaimTypeApplyState, ClaimTypeState } from '../../../types'
import { updateClaimType } from '../../../actions'

interface ClaimTypeApplyProps {
  claimType?: ClaimTypeState
  onEdit?: () => void
  onSave?: () => void
  onCancel?: () => void
}

interface FormData extends ClaimTypeApplyState {}

const EMPTY_FORM_DATA: FormData = {
  attachment: '',
  ssView: true,
  ssApply: true,
  approval: '',
  workflowId: ''
}

export const ClaimTypeApply: FC<ClaimTypeApplyProps> = ({ claimType, onEdit, onSave, onCancel }) => {
  const [cardState, setCardState] = useState<EditableCardState>()
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const canModify = usePermissionGate(Permission.claType, PermissionAction.Modify)
  const readOnly = cardState !== 'editing' && cardState !== 'saving'
  const [focusRef] = useFocus(!readOnly)

  useEffect(() => {
    if (claimType) {
      const { attachment, ssView, ssApply, approval, workflowId } = claimType
      setFormData({ attachment, ssView, ssApply, approval, workflowId })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [claimType])

  const handleEdit = useCallback(() => {
    setCardState('editing')
    typeof onEdit === 'function' && onEdit()
  }, [onEdit])

  const handleSave = useCallback(async () => {
    if (claimType) {
      setCardState('saving')
      setErrors(undefined)

      typeof onSave === 'function' && onSave()

      let result: ActionResult | undefined
      try {
        result = await dispatch(updateClaimType(claimType.id, claimType, formData))
      } catch {
        setCardState('editing')
      }

      if (result?.errors) {
        setCardState('editing')
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setCardState(undefined)
      }
    }
  }, [claimType, formData, onSave])

  const handleCancel = useCallback(() => {
    typeof onCancel === 'function' && onCancel()
    setCardState(undefined)
    setErrors(undefined)

    if (claimType) {
      const { attachment, ssView, ssApply, approval, workflowId } = claimType
      setFormData({ attachment, ssView, ssApply, approval, workflowId })
    }
  }, [claimType, onCancel])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  return (
    <EditableCard
      title="Claim request setting"
      bodyStyle={{ paddingBottom: claimType ? 6 : 24, paddingTop: 6 }}
      state={!canModify ? 'readonly' : cardState}
      formId="form-claim-type-apply"
      onEdit={handleEdit}
      onSave={handleSave}
      onCancel={handleCancel}
    >
      <Row gutter={30}>
        <Col span={12}>
          <Form.Item label="Attachment" validateStatus={errors?.attachment ? 'error' : ''} help={errors?.attachment}>
            <SysOptions
              ref={focusRef}
              type="cla_attachment"
              readOnly={readOnly}
              value={formData.attachment}
              onChange={(value: string) => handleFormDataChange({ attachment: value })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={12}>
          <Form.Item
            label="Self-service access"
            validateStatus={errors?.ssView || errors?.ssApply ? 'error' : ''}
            help={errors?.ssView || errors?.ssApply}
          >
            <Checkbox
              checked={formData.ssView}
              readOnly={readOnly}
              onChange={(e: CheckboxChangeEvent) => {
                const updates: Partial<ClaimTypeApplyState> = { ssView: e.target.checked }
                if (!e.target.checked) {
                  updates.ssApply = false
                }
                handleFormDataChange(updates)
              }}
            >
              View entitlement
            </Checkbox>
            <Checkbox
              checked={formData.ssApply}
              readOnly={readOnly}
              disabled={!readOnly && !formData.ssView}
              onChange={(e: CheckboxChangeEvent) => handleFormDataChange({ ssApply: e.target.checked })}
            >
              Apply claim
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={12}>
          <Form.Item label="Approval" validateStatus={errors?.approval ? 'error' : ''} help={errors?.approval}>
            <SysOptions
              type="cla_approval"
              readOnly={readOnly}
              value={formData.approval}
              onChange={(value: string) => handleFormDataChange({ approval: value })}
            />
          </Form.Item>
        </Col>
        <Col span={12} hidden={formData.approval !== ClaApproval.workflow}>
          <Form.Item label="Workflow" validateStatus={errors?.workflowId ? 'error' : ''} help={errors?.workflowId}>
            <SelectWorkflow
              category="cla_approval"
              readOnly={readOnly}
              value={formData.workflowId}
              onChange={(workflowId: string) => handleFormDataChange({ workflowId })}
            />
          </Form.Item>
        </Col>
      </Row>
    </EditableCard>
  )
}
