import React, { FC } from 'react'
import { Space } from '~/core-components'
import { Col, DocumentTitle, Row } from '~/components'
import { useLveCalendarConfig } from '~/features/leave'
import { MyPayslipLatest } from '~/features/my-payroll'
import { MyClaimEntitlementOverview } from '~/features/my-claim'
import { FeatureFlag } from '~/constants'
import { MyLeaveEntitlementOverview } from './components/MyLeaveEntitlementOverview/MyLeaveEntitlementOverview'
import { UpcomingEvents } from './components/UpcomingEvent/UpcomingEvents'
import { Birthday } from './components/Birthday/Birthday'
import { AbsenceToday, AbsenceThisWeek } from './components/Absence'
import { MyTask } from './components/MyTask/MyTask'
import { SSRealTimeAttendance } from '../Attendance/SSRealTimeAttendance/SSRealTimeAttendance'
import { MySsAccessGate } from '../MySsAccessGate'
import './Home.less'

interface HomeProps {}

export const Home: FC<HomeProps> = () => {
  const [calendarConfig] = useLveCalendarConfig()

  return (
    <div className="home">
      <DocumentTitle title="Home" />
      <Row gutter={[30, 20]} align="top" className="home__content">
        <MySsAccessGate featureFlag={[FeatureFlag.MyPayroll, FeatureFlag.MyLeave, FeatureFlag.MyClaim]}>
          <Col xs={24} md={9} lg={7}>
            <Space direction="vertical" size={20}>
              <MySsAccessGate featureFlag={[FeatureFlag.MyLeave]}>
                <MyLeaveEntitlementOverview className="home__card" />
              </MySsAccessGate>
              <MySsAccessGate featureFlag={[FeatureFlag.MyClaim]}>
                <MyClaimEntitlementOverview className="home__card" />
              </MySsAccessGate>
              <MySsAccessGate featureFlag={[FeatureFlag.MyPayroll]}>
                <MyPayslipLatest className="home__card" />
              </MySsAccessGate>
            </Space>
          </Col>
        </MySsAccessGate>
        <MySsAccessGate featureFlag={[FeatureFlag.MyLeave, FeatureFlag.MyClaim, FeatureFlag.MyAttendance]}>
          <Col xs={24} md={15} lg={10}>
            <Space direction="vertical" size={20}>
              <MySsAccessGate featureFlag={[FeatureFlag.MyLeave, FeatureFlag.MyClaim]}>
                <MyTask className="home__card" />
              </MySsAccessGate>
              <MySsAccessGate featureFlag={[FeatureFlag.MyAttendance]}>
                <SSRealTimeAttendance className="home__card" />
              </MySsAccessGate>
            </Space>
          </Col>
        </MySsAccessGate>
        <MySsAccessGate featureFlag={[FeatureFlag.MyLeave, FeatureFlag.MyProfile]}>
          <Col xs={24} md={9} lg={7}>
            <Space direction="vertical" size={20}>
              {calendarConfig?.leaveRecordCriteria && (
                <MySsAccessGate featureFlag={[FeatureFlag.MyLeave]}>
                  <Space direction="vertical" size={20}>
                    <AbsenceToday />
                    <AbsenceThisWeek />
                  </Space>
                </MySsAccessGate>
              )}
              <MySsAccessGate featureFlag={[FeatureFlag.MyProfile]}>
                <Space direction="vertical" size={20}>
                  <UpcomingEvents className="home__card" />
                  {calendarConfig?.leaveRecordCriteria && <Birthday className="home__card" />}
                </Space>
              </MySsAccessGate>
            </Space>
          </Col>
        </MySsAccessGate>
      </Row>
    </div>
  )
}
