import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { XeroLineItemMapState } from '../types'

export const selectXeroLineItemMaps = createSelector(
  (state: StoreState) => state.payroll.xeroLineItemMaps,
  xeroLineItemMapsState =>
    memoize((companyId: string): XeroLineItemMapState[] => {
      return Object.values(xeroLineItemMapsState[companyId]?.entities || {}) as XeroLineItemMapState[]
    })
)
