import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { LeaveEntitlementViewState } from '../types'

export const selectLeaveEntitlementsView = createSelector(
  (state: StoreState) => state.leave.leaveEntitlementsView,
  leaveEntitlementsState =>
    memoize((viewId: string): LeaveEntitlementViewState => {
      const leaveEntitlements = leaveEntitlementsState.entities[viewId] || ({} as LeaveEntitlementViewState)
      return leaveEntitlements
    })
)
