import React, { FC, useMemo } from 'react'
import { SecondaryText, Select } from '~/core-components'
import { ILoginPermission } from '../../../types'
import {
  useHasFeatureFlagClaim,
  useHasFeatureFlagLeave,
  useHasFeatureFlagPayroll,
  useSysPermissions
} from '../../../hooks'
import { PERMISSION_SECTION } from './PermissionAction'
import './PermissionActionList.less'

export interface PermissionActionListProps {
  section: string
  readOnly: boolean
  permissions: ILoginPermission[]
  onChange: (sysPermissionId: string, actions: string[]) => void
}

const ACTIONS: { [code: string]: string } = {
  v: 'View',
  a: 'Add',
  m: 'Modify',
  d: 'Delete',
  p: 'Publish',
  e: 'Email',
  s: 'Send',
  c: 'Activate account',
  r: 'Reset password'
}

export const PermissionActionList: FC<PermissionActionListProps> = ({ section, readOnly, permissions, onChange }) => {
  const [sysPermissions] = useSysPermissions()
  const filtered = useMemo(() => sysPermissions.filter(spr => spr.section === section), [sysPermissions, section])
  const permissionIds = useMemo(() => filtered.map(p => p.id), [filtered])
  const hasPayroll = useHasFeatureFlagPayroll(permissionIds)
  const hasLeave = useHasFeatureFlagLeave(permissionIds)
  const hasClaim = useHasFeatureFlagClaim(permissionIds)
  const hasFeatureFlags = hasPayroll && hasLeave && hasClaim

  if (filtered.length === 0) return null
  if (!hasFeatureFlags) return null

  return (
    <div className="permission-action">
      <div className="permission-action__title">{PERMISSION_SECTION[section].label}</div>
      {filtered.map(spr => {
        const level = spr.sequence.toString().includes('.') ? 1 : 0
        const permission = permissions.find(pr => pr.sysPermissionId === spr.id)

        return (
          <div key={spr.id} className={`permission-action__service permission-action__service--level-${level}`}>
            <div className="permission-action__service-name">
              <div>{spr.name}</div>
              {spr.description && (
                <SecondaryText>
                  {spr.description.split('\\n').map((text, i) => (
                    <span key={i}>
                      {text}
                      <br />
                    </span>
                  ))}
                </SecondaryText>
              )}
            </div>
            <div className="permission-action__service-action">
              {spr.action && (
                <Select
                  mode="multiple"
                  size="small"
                  placeholder="Select permitted actions"
                  readOnly={readOnly}
                  value={permission?.action?.split('')}
                  allowClear={false}
                  onChange={(actions: string[]) => onChange(spr.id, actions)}
                >
                  {spr.action?.split('').map(a => (
                    <Select.Option key={a} value={a}>
                      {ACTIONS[a]}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}
