import React, { Component, ErrorInfo } from 'react'
import { ErrorDetail } from './ErrorDetail'

interface ErrorBoundaryProps {}
interface ErrorBoundaryState {
  error?: Error
  errorInfo?: ErrorInfo
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { error: undefined, errorInfo: undefined }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error, errorInfo })
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <ErrorDetail
          title="Something went wrong with application"
          subtitle={this.state.error && this.state.error.toString()}
        />
      )
    }
    return this.props.children
  }
}
