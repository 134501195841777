import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { Breadcrumb as AntBreadcrumb } from 'antd'
import { BreadcrumbProps as AntBreadcrumbProps } from 'antd/lib/breadcrumb'
import './Breadcrumb.less'

export interface BreadcrumbProps extends AntBreadcrumbProps {}

export const Breadcrumb: FC<BreadcrumbProps> = ({ routes, className, ...props }) => {
  const classes = classNames('breadcrumb', { [`${className}`]: className })

  if (!routes) {
    return null
  }

  return (
    <AntBreadcrumb className={classes} {...props}>
      {routes?.map((item, index) => (
        <AntBreadcrumb.Item key={index}>
          {index === routes!.length - 1 ? (
            item.breadcrumbName
          ) : (
            <Link className="breadcrumb__link" to={item.path}>
              {item.breadcrumbName}
            </Link>
          )}
        </AntBreadcrumb.Item>
      ))}
    </AntBreadcrumb>
  )
}
