import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Collapse, List } from '~/core-components'
import { AmountDisplay, Col, Row } from '~/components'
import { ClaimTypeName, fetchClaimTypes } from '~/features/claim'
import { ClaEntitlementType } from '~/constants'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchMyClaimBalances } from '../../actions'
import { useMyClaimBalances } from '../../hooks'
import { SSClaimBalanceState } from '../../types'
import './MyClaimTakenList.less'

interface MyClaimTakenListProps {}

export const MyClaimTakenList: FC<MyClaimTakenListProps> = () => {
  const [expand, setExpand] = useState<string>()
  const [claimBalances, periods, loading] = useMyClaimBalances()
  const refetch = useSelector((state: StoreState) => state.myClaim.myClaimBalancesRefetch)
  const withTaken = claimBalances.filter(c => c?.entitlementType !== ClaEntitlementType.PerPeriod)

  useEffect(() => {
    dispatch(fetchClaimTypes({ strategy: 'when-empty' }))
  }, [])

  useEffect(() => {
    dispatch(fetchMyClaimBalances())
  }, [refetch])

  const handleExpand = useCallback((rowKey: string) => {
    setExpand(expand => (expand === rowKey ? undefined : rowKey))
  }, [])

  return (
    <div className="my-claim-taken-list">
      {claimBalances.length === 0 && !loading && <>No claim entitled</>}

      {withTaken.length > 0 &&
        periods.map(p => {
          const data = withTaken.filter(b => b?.periodCode === p.periodCode)
          if (data.length === 0) {
            return null
          }

          return (
            <div key={p.periodCode}>
              {periods.length > 1 && p.startDate && p.endDate && (
                <div className="subheader">
                  {moment(p.startDate).format('DD MMM YYYY')} - {moment(p.endDate).format('DD MMM YYYY')}
                </div>
              )}
              <List
                size="small"
                dataSource={data}
                renderItem={(item: SSClaimBalanceState, index: number) => (
                  <List.Item key={item.id}>
                    <Collapse
                      activeKey={expand}
                      onChange={() => handleExpand(`${p.periodCode}-${index}`)}
                      expandIconPosition="end"
                    >
                      <Collapse.Panel
                        key={`${p.periodCode}-${index}`}
                        header={
                          <Row align="middle">
                            <Col flex="auto">
                              <ClaimTypeName id={item.claimTypeId} />
                            </Col>
                          </Row>
                        }
                      >
                        <Row>
                          <Col flex="1">Taken</Col>
                          <Col flex="none">
                            <AmountDisplay value={item.taken} />
                          </Col>
                        </Row>
                      </Collapse.Panel>
                    </Collapse>
                  </List.Item>
                )}
              />
            </div>
          )
        })}
    </div>
  )
}
