import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { LocationProjectState } from '../types'

export const selectLocationProjects = createSelector(
  (state: StoreState) => state.attendance.locationProjects,
  locationProjectsState =>
    memoize((locationId: string) => {
      return (Object.values(locationProjectsState[locationId]?.entities || {}) as LocationProjectState[]) || []
    })
)
