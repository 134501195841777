import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { LeaveEntitlementState } from '../types'

export const selectLeaveEntitlement = createSelector(
  (state: StoreState) => state.leave.leaveEntitlements,
  leaveEntitlementsState =>
    memoize(
      (employeeId: string, leaveTypeId: string): LeaveEntitlementState | undefined => {
        const leaveEntitlements = Object.values(leaveEntitlementsState?.entities || {}) as LeaveEntitlementState[]
        return leaveEntitlements.find(le => le.employeeId === employeeId && le.leaveTypeId === leaveTypeId)
      },
      (employeeId: string, leaveTypeId: string) => `${employeeId}|${leaveTypeId}`
    )
)
