import React, { FC, useState, useCallback, useEffect, ChangeEvent, memo, CSSProperties } from 'react'
import moment from 'moment-timezone'
import confirm from 'antd/lib/modal/confirm'
import { Button, Form, Input, Space, Tag, UploadFile } from '~/core-components'
import { Col, DrawerForm, Row, SysOptions, UploadFileSelectorAuth } from '~/components'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors } from '~/types/store'
import { useFocus } from '~/hooks/use-focus'
import { IEmIdentityRequest, SSEmIdentityPublicSelfState } from '~/features/my/types'
import { emptyGuid } from '~/constants'
import { applyEmIdentityRequest, deleteEmIdentityRequest } from '~/features/my/actions'
import { useSysOptions } from '~/features/employee'
import { getBaseUrl } from '~/utils'

export interface MutateMyIdentityDrawerProps {
  visible: boolean
  employeeId?: string
  data?: SSEmIdentityPublicSelfState
  onClose: (action: 'saved' | 'cancelled') => void
}

const PendingTag = memo(() => <Tag type="secondary">pending</Tag>)

const EMPTY_FORM_DATA: IEmIdentityRequest = {
  id: emptyGuid,
  employeeId: '',
  emIdentityId: emptyGuid,
  identityType: '',
  identityNo: '',
  issueDate: '',
  expiryDate: '',
  cancelledDate: '',
  workPermitNo: '',
  sPassNo: ''
}

const currenTextStyle: CSSProperties = { fontSize: 13, color: '#797979' }
const baseUrl = getBaseUrl('/employee')

export const MutateMyIdentityDrawer: FC<MutateMyIdentityDrawerProps> = ({
  visible,
  employeeId,
  data,
  onClose
}: MutateMyIdentityDrawerProps) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<IEmIdentityRequest>(EMPTY_FORM_DATA)
  const [focusRef, setFocus] = useFocus(true)
  const [errors, setErrors] = useState<Errors>()
  const [identityTypes] = useSysOptions('identity_type')

  const isNew = data?.recordStatus === 'new_request'
  const isDiffIdentityType = !isNew && data?.identityType !== data?.identityTypeNew
  const isDiffIdentityNo = !isNew && data?.identityNo !== data?.identityNoNew
  const isDiffIssueDate = !isNew && data?.issueDate !== data?.issueDateNew
  const isDiffExpiryDate = !isNew && data?.expiryDate !== data?.expiryDateNew
  const isDiffCancelledDate = !isNew && data?.cancelledDate !== data?.cancelledDateNew
  const isDiffWorkPermitNo = !isNew && data?.workPermitNo !== data?.workPermitNoNew
  const isDiffSPassNo = !isNew && data?.sPassNo !== data?.sPassNoNew

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  useEffect(() => {
    if (data) {
      let attachment: UploadFile | undefined
      if (data.attachmentId) {
        attachment = {
          uid: data.attachmentId,
          name: data.attachmentName || '',
          size: data.attachmentSize || 0,
          type: data.attachmentFormat || '',
          url: `${baseUrl}/ssemployeerequest/identity/${data.requestId}/downloadfile`
        }
      }

      setFormData({
        id: data.requestId || emptyGuid,
        employeeId: employeeId || emptyGuid,
        emIdentityId: data.id,
        identityType: data.identityTypeNew,
        identityNo: data.identityNoNew,
        issueDate: data.issueDateNew,
        expiryDate: data.expiryDateNew,
        cancelledDate: data.cancelledDateNew,
        workPermitNo: data.workPermitNoNew,
        sPassNo: data.sPassNoNew,
        attachment
      })
    } else {
      setFormData({ ...EMPTY_FORM_DATA, employeeId: employeeId || emptyGuid })
    }
  }, [data, employeeId])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    if ('identityType' in updates) {
      if (updates.identityType === 'sg06') {
        updates.workPermitNo = ''
      } else if (updates.identityType === 'sg07') {
        updates.sPassNo = ''
      } else {
        updates.workPermitNo = ''
        updates.sPassNo = ''
      }
    }

    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      result = await dispatch(applyEmIdentityRequest(formData))
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose('saved')
      setFormData(EMPTY_FORM_DATA)
    }
  }, [formData, onClose])

  const handleDelete = useCallback(
    (myIdentity: SSEmIdentityPublicSelfState | undefined) => {
      if (employeeId && myIdentity) {
        const { requestId, identityNoNew } = myIdentity
        confirm({
          title: 'Delete personal identification',
          content: `Do you want to delete personal identification record of "${identityNoNew}"?`,
          onOk: async () => {
            const result: ActionResult | undefined = await dispatch(deleteEmIdentityRequest(requestId))
            if (result?.errors) {
              setErrors(result.errors)
            }

            if (!result?.errors) {
              typeof onClose === 'function' && onClose('saved')
            }
          },
          okText: 'Delete',
          okType: 'danger'
        })
      }
    },
    [employeeId, onClose]
  )

  const handleFileChange = useCallback(
    (attachments?: UploadFile[]) => {
      if (attachments && attachments.length > 0) {
        setFormData({ ...formData, attachment: attachments[0] })
      }
    },
    [formData]
  )

  const handleFileRemove = useCallback(
    (file?: UploadFile) => {
      if (file) {
        setFormData({ ...formData, attachment: undefined, deletedAttachmentId: file.uid })
      }
    },
    [formData]
  )

  return (
    <DrawerForm
      open={visible}
      title={
        data ? (
          <Space>Edit personal identification {isNew && <Tag type="secondary">new (pending)</Tag>}</Space>
        ) : (
          'Add personal identification'
        )
      }
      okText={data?.recordStatus === 'has_request' ? 'Resubmit' : 'Submit'}
      onClose={() => onClose('cancelled')}
      confirmLoading={loading}
      width={600}
      showDelete={data ? (data?.recordStatus !== 'no_request' ? true : false) : false}
      onDelete={() => handleDelete(data)}
      formId="form-em-identity"
    >
      <Form id="form-em-identity" onFinish={handleOk}>
        <Row>
          <Col span={24}>
            <Form.Item
              label={<Space>Identity type {isDiffIdentityType && <PendingTag />}</Space>}
              validateStatus={errors?.identityType ? 'error' : ''}
              help={errors?.identityType}
            >
              <SysOptions
                type="identity_type"
                sort="key"
                value={formData.identityType}
                ref={focusRef}
                onChange={(value: string) => handleFormDataChange({ identityType: value })}
              />
              {isDiffIdentityType && (
                <Space style={currenTextStyle}>Current: {identityTypes[data?.identityType || '']?.value || '-'}</Space>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label={<Space>Identity no. {isDiffIdentityNo && <PendingTag />}</Space>}
              validateStatus={errors?.identityNo ? 'error' : ''}
              help={errors?.identityNo}
            >
              <Input
                value={formData.identityNo}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleFormDataChange({ identityNo: event.target.value.toUpperCase() })
                }
              />
              {isDiffIdentityNo && <Space style={currenTextStyle}>Current: {data?.identityNo || '-'}</Space>}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label={<Space>Issue date {isDiffIssueDate && <PendingTag />}</Space>}
              validateStatus={errors?.issueDate ? 'error' : ''}
              help={errors?.issueDate}
            >
              <Input.Date
                value={formData.issueDate ? moment(formData.issueDate) : undefined}
                onChange={(value: moment.Moment | null) =>
                  handleFormDataChange({ issueDate: value?.format('YYYY-MM-DD') })
                }
              />
              {isDiffIssueDate && (
                <div style={currenTextStyle}>
                  Current: {data?.issueDate ? moment(data?.issueDate).format('DD MMM YYYY') : '-'}
                </div>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label={<Space>Expiry date {isDiffExpiryDate && <PendingTag />}</Space>}
              validateStatus={errors?.expiryDate ? 'error' : ''}
              help={errors?.expiryDate}
            >
              <Input.Date
                value={formData.expiryDate ? moment(formData.expiryDate) : undefined}
                onChange={(value: moment.Moment | null) =>
                  handleFormDataChange({ expiryDate: value?.format('YYYY-MM-DD') })
                }
              />
              {isDiffExpiryDate && (
                <div style={currenTextStyle}>
                  Current: {data?.expiryDate ? moment(data?.expiryDate).format('DD MMM YYYY') : '-'}
                </div>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label={<Space>Cancelled date {isDiffCancelledDate && <PendingTag />}</Space>}
              validateStatus={errors?.cancelledDate ? 'error' : ''}
              help={errors?.cancelledDate}
            >
              <Input.Date
                value={formData.cancelledDate ? moment(formData.cancelledDate) : undefined}
                onChange={(value: moment.Moment | null) =>
                  handleFormDataChange({ cancelledDate: value?.format('YYYY-MM-DD') })
                }
              />
              {isDiffCancelledDate && (
                <div style={currenTextStyle}>
                  Current: {data?.cancelledDate ? moment(data?.cancelledDate).format('DD MMM YYYY') : '-'}
                </div>
              )}
            </Form.Item>
          </Col>
        </Row>
        {formData.identityType === 'sg07' && (
          <Row>
            <Col span={24}>
              <Form.Item
                label={<Space>Work permit no. {isDiffWorkPermitNo && <PendingTag />}</Space>}
                validateStatus={errors?.workPermitNo ? 'error' : ''}
                help={errors?.workPermitNo}
              >
                <Input
                  value={formData.workPermitNo}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleFormDataChange({ workPermitNo: event.target.value.toUpperCase() })
                  }
                />
                {isDiffWorkPermitNo && <Space style={currenTextStyle}>Current: {data?.workPermitNo || '-'}</Space>}
              </Form.Item>
            </Col>
          </Row>
        )}
        {formData.identityType === 'sg06' && (
          <Row>
            <Col span={24}>
              <Form.Item
                label={<Space>S Pass no. {isDiffSPassNo && <PendingTag />}</Space>}
                validateStatus={errors?.sPassNo ? 'error' : ''}
                help={errors?.sPassNo}
              >
                <Input
                  value={formData.sPassNo}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleFormDataChange({ sPassNo: event.target.value.toUpperCase() })
                  }
                />
                {isDiffSPassNo && <Space style={currenTextStyle}>Current: {data?.sPassNo || '-'}</Space>}
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row>
          <Col span={24}>
            <Form.Item label="Attachment" validateStatus={errors?.attachment ? 'error' : ''} help={errors?.attachment}>
              <UploadFileSelectorAuth
                multiple={false}
                accept=".gif,.png,.jpeg,.jpg"
                fileList={formData.attachment ? [formData.attachment] : []}
                listType="picture-card"
                onChange={handleFileChange}
                onRemove={handleFileRemove}
                withCache={false}
              >
                <Button size="small">choose file</Button>
              </UploadFileSelectorAuth>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}
