import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { BreakTimeState } from '../types'

export const selectBreakTimes = createSelector(
  (state: StoreState) => state.attendance.breakTimes,
  breakTimesState =>
    memoize((breakId?: string): BreakTimeState[] => {
      if (!breakId) return []

      const breakTimes = Object.values(breakTimesState[breakId]?.entities || {}) as BreakTimeState[]
      return breakTimes.sort((a, b) => a.startTime?.localeCompare(b.startTime))
    })
)
