import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { LocationProjectState } from '../types'
import { fetchLocationProjects } from '../actions'
import { selectLocationProjects } from '../selectors'

export const useLocationProjects = (locationId: string): [LocationProjectState[], boolean] => {
  const locationProjects = useSelector(selectLocationProjects)(locationId)
  const loading = useSelector((state: StoreState) => state.attendance.locationProjectsLoading[locationId])

  useEffect(() => {
    if (locationId) {
      dispatch(fetchLocationProjects(locationId))
    }
  }, [locationId])

  return [locationProjects, loading]
}
