import { replaceMyEmployee, setMyEmployeeLoading } from '../../reducers'
import { apiGetMe } from '../../api/ssemployee.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchMyEmployee = (options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().my.myEmployeeLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = !!getState().my.myEmployee.id
      if (hasData) return
    }

    try {
      dispatch(setMyEmployeeLoading(true))
      const { result, status } = await apiGetMe()
      if (status) dispatch(replaceMyEmployee(result))
    } finally {
      dispatch(setMyEmployeeLoading(false))
    }
  }
}
