import { showError, showSuccess } from '~/utils'
import { ThunkResult } from '~/types/store'
import { IBulkAddScheduleRecord } from '../types'
import { refetchScheduleRecordsView } from '../reducers'
import { apiBulkAddScheduleRecords } from '../api/schedule-record.api'

export const bulkAddScheduleRecords = (request: IBulkAddScheduleRecord): ThunkResult<void> => {
  return async dispatch => {
    const { status, errors, message, errorData } = await apiBulkAddScheduleRecords(request)
    if (status) {
      dispatch(refetchScheduleRecordsView())
      showSuccess('Added')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
