import { showSuccess, showError } from '~/utils'
import { removeMyClaimTask } from '../reducers'
import { apiRejectClaim } from '../api/ss-claim-task.api'
import { ThunkResult } from '~/types/store'

export const rejectClaim = (id: string, comment: string): ThunkResult<void> => {
  return async dispatch => {
    const { status, result, errors, message, errorData } = await apiRejectClaim(id, comment)
    if (status) {
      dispatch(removeMyClaimTask(id))
      showSuccess('Rejected')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
