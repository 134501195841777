import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchTimeLogsByDaily } from '../actions'
import { FetchStrategy } from '~/types/common'
import { TimeLogState } from '../types'
import { selectTimeLogsByDaily } from '../selectors'

export const useTimeLogsByDaily = (
  dailyRecordId?: string,
  fetchStrategy: FetchStrategy = 'always'
): [TimeLogState[], boolean] => {
  const data = useSelector(selectTimeLogsByDaily)(dailyRecordId || '')
  const loading = useSelector((state: StoreState) => state.attendance.timeLogsByDailyLoading[dailyRecordId || ''])
  const refetch = useSelector((state: StoreState) => state.attendance.timeLogsByDailyRefetch[dailyRecordId || ''])

  useEffect(() => {
    dispatch(fetchTimeLogsByDaily(dailyRecordId, { strategy: fetchStrategy }))
  }, [dailyRecordId, refetch, fetchStrategy])

  return [data, loading]
}
