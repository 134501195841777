import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { DelegationState } from '../types'
import { fetchDelegations } from '../actions'
import { selectDelegations } from '../selectors'

export const useDelegations = (): [DelegationState[], boolean] => {
  const delegations = useSelector(selectDelegations)
  const loading = useSelector((state: StoreState) => state.master.delegationsLoading || false)

  useEffect(() => {
    dispatch(fetchDelegations())
  }, [])

  return [delegations, loading]
}
