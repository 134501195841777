import { showSuccess, showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { apiAddCompany } from '../api/company.api'
import { setCompany, setKeyvalue } from '../reducers'
import { ICompanyInfo, CompanyState } from '../types'

export const addCompany = (company: ICompanyInfo): ThunkResult<void> => {
  return async (dispatch): Promise<ActionResult> => {
    const { status, result, errors, message, errorData } = await apiAddCompany(company)
    if (status) {
      const newCompany = { id: result.id, ...company } as CompanyState
      dispatch(setCompany(newCompany))
      dispatch(setKeyvalue({ kv: 'company', data: { key: result.id, value: company.name } }))

      showSuccess('Added')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
