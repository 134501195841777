import React, { FC, useMemo } from 'react'
import moment from 'moment-timezone'
import classNames from 'classnames'
import { Space, List, Tag, SecondaryLink, Tooltip } from '~/core-components'
import { LveDuration } from '~/constants'
import { useToggle } from '~/hooks/use-toggle'
import { Col, Row } from '~/components'
import { useSysOptions } from '~/features/employee'
import { SSLeaveRecordDtlState } from '~/features/my'
import { useLeaveTypesDict } from '../../../hooks'
import { LeaveRecordDtlState, LeaveTypeState } from '../../../types'
import './LeaveRecordDtls.less'

interface LeaveRecordDtlsProps {
  leaveType?: LeaveTypeState
  dtls?: LeaveRecordDtlState[] | SSLeaveRecordDtlState[]
  showLeaveType?: boolean
  showQuantity?: boolean
  color?: 'primary' | 'secondary'
}

const SHOW_MORE_CUT_OFF = 14

export const LeaveRecordDtls: FC<LeaveRecordDtlsProps> = ({ leaveType, dtls, showLeaveType, showQuantity, color }) => {
  const [durationShort] = useSysOptions('lve_duration_short')
  const [leaveUnits] = useSysOptions('lve_unit')
  const [leaveTypes] = useLeaveTypesDict()
  const unit = leaveUnits[leaveType?.unit || '']?.value?.toLowerCase() || ''
  const [showMore, toggleShowMore] = useToggle()

  const dates = useMemo(() => {
    return dtls?.filter((d, index) => (showMore ? true : index < SHOW_MORE_CUT_OFF))
  }, [dtls, showMore])

  return (
    <>
      <div className={classNames('leave-record-dtls', `leave-record-dtls--${color}`)}>
        <List
          size="small"
          dataSource={dates}
          renderItem={(item: LeaveRecordDtlState) => (
            <List.Item key={item.id}>
              <Row>
                <Col flex="180px">
                  <Space>
                    {item.leaveDate ? moment(item.leaveDate).format('DD MMM YYYY (ddd)') : undefined}
                    {[LveDuration.firstHalf, LveDuration.secondHalf].includes(item.leaveDuration) && (
                      <Tag type="original">{durationShort[item.leaveDuration]?.value}</Tag>
                    )}
                  </Space>
                </Col>
                <Col hidden={!showLeaveType} flex="1">
                  {leaveTypes[item.leaveTypeId]?.name}
                </Col>
                <Col hidden={!showQuantity} flex="55px" style={{ textAlign: 'right' }}>
                  {item.quantity} {unit}
                </Col>
                <Col flex="1" style={{ textAlign: 'right' }}>
                  <Tooltip title={item.payRunDesc}>
                    {item.payRunPeriod ? `Deduction in ${moment(item.payRunPeriod).format('MMM YYYY')}` : ''}
                  </Tooltip>
                </Col>
              </Row>
            </List.Item>
          )}
        />
      </div>
      {dtls && dtls.length > SHOW_MORE_CUT_OFF && (
        <div>
          <SecondaryLink onClick={toggleShowMore}>{showMore ? 'show less' : 'show more'}</SecondaryLink>
        </div>
      )}
    </>
  )
}
