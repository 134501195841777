import React, { FC, useEffect, useMemo, useState } from 'react'
import moment from 'moment-timezone'
import { ColumnsType, Table, TableRowSelection } from '~/core-components'
import { ErrorDisplay } from '~/components'
import { formatNumberUnit, UnitType } from '~/utils'
import { Errors } from '~/types/store'
import { EncashImportSourceState } from '../../../../types'
import { ImportEncashFormData } from '../ImportEncashDrawer'
import './SelectEncashRecord.less'

interface SelectEncashRecordProps {
  visible: boolean
  data: Partial<ImportEncashFormData>
  errors?: Errors
  onSelect: (data: Partial<ImportEncashFormData>) => void
}

export const SelectEncashRecord: FC<SelectEncashRecordProps> = ({ visible, data, errors, onSelect }) => {
  const [selected, setSelected] = useState<EncashImportSourceState[]>([])

  useEffect(() => {
    if (data.selected) {
      setSelected(data.selected)
    }
  }, [data.selected])

  const columns: ColumnsType<EncashImportSourceState> = [
    {
      title: 'Employee',
      key: 'employeeNo',
      dataIndex: 'employeeNo',
      render: (value: string, record) => `[${value}] ${record.employeeName}`
    }
  ]

  if (data.encashType === 'resign') {
    columns.push({
      title: 'Employment End Date',
      key: 'employmentEndDate',
      dataIndex: 'employmentEndDate',
      render: (value: string) => (value ? moment(value).format('DD MMM YYYY') : undefined)
    })
  }

  columns.push(
    ...[
      {
        title: 'Leave type',
        key: 'leaveTypeName',
        dataIndex: 'leaveTypeName'
      },
      {
        title: 'Leave balance',
        key: 'encashed',
        dataIndex: 'encashed',
        render: (value: number, record: EncashImportSourceState) => formatNumberUnit(value, record.unit as UnitType)
      },
      {
        title: 'Payroll item',
        key: 'payItemName',
        dataIndex: 'payItemName'
      }
    ]
  )

  const rowSelection: TableRowSelection<EncashImportSourceState> = useMemo(
    () => ({
      type: 'checkbox',
      selectedRowKeys: selected.map(d => d.id),
      hideSelectAll: false,
      onChange: (_selectedRowKeys, rows) => {
        typeof onSelect === 'function' && onSelect({ selected: rows })
        setSelected(rows)
      }
    }),
    [selected, onSelect]
  )

  return (
    <div className="select-encash-record" hidden={!visible}>
      <ErrorDisplay errors={errors} />
      <Table rowSelection={rowSelection} rowKey="id" dataSource={data.source} columns={columns} pagination={false} />
    </div>
  )
}
