import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { ShiftState } from '../types'

export const selectShiftKeyvalues = createSelector(
  (state: StoreState) => state.attendance.shifts,
  shiftsState => {
    const shifts = Object.values(shiftsState?.entities || {}) as ShiftState[]

    let kv = shifts.sort((a, b) => {
      const aInactiveDate = new Date(a!.inactiveDate || '').getTime() || 0
      const bInactiveDate = new Date(b!.inactiveDate || '').getTime() || 0

      if (bInactiveDate === 0 && aInactiveDate === 0) {
        return a!.name.localeCompare(b!.name)
      } else {
        return aInactiveDate - bInactiveDate
      }
    })

    return kv
  }
)
