import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchXeroContacts } from '../actions'
import { XeroContactState } from '../types'
import { selectXeroContacts } from '../selectors'

export const useXeroContacts = (
  tenantId?: string,
  fetchStrategy: FetchStrategy = 'when-empty'
): [XeroContactState[], boolean] => {
  const contacts = useSelector(selectXeroContacts)(tenantId || '')
  const loading = useSelector((state: StoreState) => state.payroll.xeroContactsLoading[tenantId || ''])

  useEffect(() => {
    dispatch(fetchXeroContacts(tenantId, { strategy: fetchStrategy }))
  }, [tenantId, fetchStrategy])

  return [contacts, loading]
}
