import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { FetchStrategy } from '~/types/common'
import { fetchCalendarPatterns } from '../actions'
import { WorkCalendarPatternState } from '../types'
import { selectCalendarPatterns } from '../selectors'

export const useCalendarPatterns = (
  calendarId: string,
  fetchStrategy: FetchStrategy = 'always'
): [WorkCalendarPatternState[], boolean] => {
  const data = useSelector(selectCalendarPatterns)(calendarId)
  const loading = useSelector((state: StoreState) => state.attendance.calendarPatternsLoading[calendarId])

  useEffect(() => {
    dispatch(fetchCalendarPatterns(calendarId, { strategy: fetchStrategy }))
  }, [calendarId, fetchStrategy])

  return [data, loading]
}
