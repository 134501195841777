import React, { CSSProperties, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Form } from '~/core-components'
import { Col, EditableCard, EditableCardState, Row, SalaryInput } from '~/components'
import { Permission, PermissionAction } from '~/constants'
import { EmployeeState, IEmployeeSalary } from '~/features/employee/types'
import { ActionResult } from '~/types/store'
import { usePermissionGate } from '~/features/iam/hooks'
import { dispatch } from '~/stores/store'
import { updateEmployeeSalary } from '~/features/employee/actions'
import { mapEmployeeStateToEmployeeSalary } from '~/features/employee/types/employee.mapper'
import { usePayCfConfigs } from '~/features/payroll'

interface EmployeeSalaryCfJsonbProps {
  employee?: EmployeeState
  onEdit?: () => void
  onSave?: () => void
  onCancel?: () => void
}

const EMPTY_FORM_DATA: IEmployeeSalary = {
  payCfJsonb: ''
}

interface FormDataCfJsonb {
  [key: string]: number
}

const EMPTY_FORM_DATA_CF_JSONB: FormDataCfJsonb = {}

const cardStyle: CSSProperties = { margin: 24 }

export const EmployeeSalaryCfJsonb: FC<EmployeeSalaryCfJsonbProps> = ({ employee, onEdit, onSave, onCancel }) => {
  const [cardState, setCardState] = useState<EditableCardState>()
  const [formData, setFormData] = useState<IEmployeeSalary>(EMPTY_FORM_DATA)
  const canView = usePermissionGate(Permission.employeeSalary)
  const canModify = usePermissionGate(Permission.employeeSalary, PermissionAction.Modify)
  const readOnly = cardState !== 'editing'

  // custom field jsonb
  const [formDataCfJsonb, setFormDataCfJsonb] = useState<FormDataCfJsonb>(EMPTY_FORM_DATA_CF_JSONB)
  const [payCfConfigs, payCfConfigsLoading] = usePayCfConfigs()
  const shownPayCfConfigs = useMemo(() => payCfConfigs.filter(pcf => pcf.isShown), [payCfConfigs])

  useEffect(() => {
    if (employee) {
      const { payCfJsonb } = employee
      setFormData({ payCfJsonb })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [employee])

  useEffect(() => {
    if (formData.payCfJsonb) {
      try {
        const data = JSON.parse(formData.payCfJsonb || '{}') as FormDataCfJsonb
        setFormDataCfJsonb(data)
      } catch (error) {
        console.error(error)
      }
    } else {
      setFormDataCfJsonb(EMPTY_FORM_DATA_CF_JSONB)
    }
  }, [formData.payCfJsonb])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => setFormData(formData => ({ ...formData, ...updates })),
    []
  )

  const handleFormDataChangeCfJsonb = useCallback(
    (updates: { [field: string]: any }) => {
      setFormDataCfJsonb(formData => {
        const updated = { ...formData, ...updates }
        const payCfJsonb = JSON.stringify(updated)

        handleFormDataChange({ payCfJsonb })
        return updated
      })
    },
    [handleFormDataChange]
  )

  const handleEdit = useCallback(() => {
    setCardState('editing')
    typeof onEdit === 'function' && onEdit()
  }, [onEdit])

  const handleSave = useCallback(async () => {
    if (employee) {
      setCardState('saving')

      typeof onSave === 'function' && onSave()

      let result: ActionResult | undefined
      try {
        result = await dispatch(updateEmployeeSalary(employee.id, mapEmployeeStateToEmployeeSalary(employee), formData))
      } catch {
        setCardState('editing')
      }

      if (result?.errors) {
        setCardState('editing')
      }

      if (!result?.errors) {
        setCardState(undefined)
      }
    }
  }, [employee, formData, onSave])

  const handleCancel = useCallback(() => {
    typeof onCancel === 'function' && onCancel()
    setCardState(undefined)

    if (employee) {
      const { payCfJsonb } = employee
      setFormData({ payCfJsonb })
    }
  }, [employee, onCancel])

  if (!canView || shownPayCfConfigs.length < 1) return null

  return (
    <EditableCard
      title="Custom fields"
      style={cardStyle}
      bodyStyle={{ paddingBottom: employee ? 6 : 24, paddingTop: 6 }}
      state={canModify ? cardState : 'readonly'}
      formId="form-employee-salary-cf-jsonb"
      loading={payCfConfigsLoading}
      onEdit={handleEdit}
      onSave={handleSave}
      onCancel={handleCancel}
    >
      <Row gutter={20}>
        {shownPayCfConfigs.map(pcf => (
          <Col key={pcf.code} span={12}>
            <Form.Item label={pcf.name}>
              <SalaryInput
                value={(formDataCfJsonb[pcf.code as keyof FormDataCfJsonb] as number) || 0}
                readOnly={readOnly}
                onChange={value => handleFormDataChangeCfJsonb({ [pcf.code]: value })}
              />
            </Form.Item>
          </Col>
        ))}
      </Row>
    </EditableCard>
  )
}
