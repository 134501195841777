import React, { CSSProperties, ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import confirm from 'antd/lib/modal/confirm'
import moment from 'moment-timezone'
import pick from 'lodash/pick'
import { v4 as uuidv4 } from 'uuid'
import {
  Badge,
  Button,
  Form,
  Input,
  Link,
  SecondaryText,
  Select,
  Skeleton,
  Space,
  Tabs,
  Tag,
  UploadFile
} from '~/core-components'
import { AmountDisplay, Col, DrawerForm, ErrorDisplay, Row, SubHeader } from '~/components'
import {
  ClaimCurrentApprover,
  ClaimRecordApprovalHistories,
  ClaimRecordCfJsonb,
  ClaimStatusTag,
  useCfConfigsDict,
  useClaimTypesDict
} from '~/features/claim'
import { ClaRecordStatus, ClaCurrencyCode, ClaRecordType, ClaTaxableType, ClaExpenseSubmissionType } from '~/constants'
import { ActionResult, Errors } from '~/types/store'
import { useFocus } from '~/hooks/use-focus'
import { dispatch } from '~/stores/store'
import { downloadWithDom, getBaseUrl, getFileTimestamp, showError } from '~/utils'
import { apiGetMyClaimAmount, apiGetClaimAmountMultiple } from '../../api/ss-claim-apply.api'
import { SSClaimAmount, SSClaimAmountMultipleRequest, SSClaimApply, SSClaimRecordExpense } from '../../types'
import {
  useMyClaimRecord,
  useMyClaimRecordApprovalHistories,
  useMyClaimRecordAttachments,
  useMyClaimRecordComments,
  useMyEntitledClaimTypes
} from '../../hooks'
import { addClaimRecordComment, applyMyClaim, cancelMyClaim, deleteMyClaim } from '../../actions'
import { SSClaimRecordComments } from '../SSClaimRecordComments/SSClaimRecordComments'
import { MyClaimRecordSingleForm } from './MyClaimRecordSingleForm'
import { MyClaimRecordMultipleFormRow, getClaimRecordExpenseEmptyForm } from './MyClaimRecordMultipleFormRow'
import { apiDownloadAllMyClaimAttachment } from '../../api/ss-claim-record.api'
import './MyClaimApplyDrawer.less'

interface MyClaimApplyDrawerProps {
  visible: boolean
  id?: string
  editing?: boolean
  isMultiple?: boolean
  onClose: (action: 'saved' | 'cancelled') => void
}

const TODAY = moment().format('YYYY-MM-DD')

type SSClaimApplyResetForm = Omit<SSClaimApply, 'id'>

const EMPTY_FORM_DATA: SSClaimApplyResetForm = {
  claimTypeId: '',
  providerName: '',
  receiptNo: '',
  expenseDate: TODAY,
  expenseCurrencyCode: ClaCurrencyCode.sgd,
  exchangeRate: 1,
  exchangeUnit: 1,
  expenseAmount: 0,
  claimAmount: 0,
  claimAmountTax: 0,
  isTaxable: false,
  isClaimAmountTaxOverride: false,
  notes: '',
  expenses: [],
  attachments: [],
  deletedAttachmentIds: [],
  cfJsonb: ''
}

const getEmptyForm = () => ({
  ...EMPTY_FORM_DATA,
  id: uuidv4()
})

const baseUrl = getBaseUrl('/claim')
const taxStyle: CSSProperties = { fontWeight: 'normal', fontStyle: 'italic' }

type TabChoice = 'request' | 'comment'

export const MyClaimApplyDrawer: FC<MyClaimApplyDrawerProps> = ({
  visible,
  id,
  editing,
  isMultiple: isMultipleDefault,
  onClose
}) => {
  const [submitting, setSubmitting] = useState<'submit' | 'draft' | undefined>()
  const [formData, setFormData] = useState<SSClaimApply>(getEmptyForm())
  const [errors, setErrors] = useState<Errors>()
  const [isEditing, setIsEditing] = useState(false)
  const [commenting, setCommenting] = useState(false)
  const [comment, setComment] = useState('')
  const [focusRef, setFocus] = useFocus(true)
  const errorRef = useRef<any>(null)
  const formId = useMemo(() => `form-my-claim-apply-${uuidv4()}`, [])
  const [activeTab, setActiveTab] = useState<TabChoice>('request')

  const [claimTypesDict] = useClaimTypesDict()
  const [cfConfigsDict, cfConfigsDictLoading] = useCfConfigsDict()
  const claimType = claimTypesDict[formData.claimTypeId]
  const isMultiple =
    isMultipleDefault != null
      ? isMultipleDefault
      : claimType?.expenseSubmissionType === ClaExpenseSubmissionType.Multiple

  const [claimRecord, fetching] = useMyClaimRecord(id)
  const [claimRecordAttachments, attachmentLoading] = useMyClaimRecordAttachments(id)
  const [claimRecordApprovalHistories] = useMyClaimRecordApprovalHistories(id)
  const isNew = !claimRecord?.id
  const isDraft = claimRecord?.submittedBy == null
  const recordStatus = claimRecord?.recordStatus
  const hasSubmitted = claimRecord?.submittedBy != null
  const [comments] = useMyClaimRecordComments(id)

  const [claimAmountLoading, setClaimAmountLoading] = useState(false)
  const [isRecompute, setIsRecompute] = useState(true)

  const [entitledClaimTypes, entitledClaimTypesLoading] = useMyEntitledClaimTypes({ enabled: isNew && isEditing })

  const isExpenseTaxable = useMemo(() => formData.expenses?.some(exp => exp.isTaxable), [formData.expenses])
  const isDeletable = useMemo(
    () => recordStatus === ClaRecordStatus.PendingCancel || recordStatus === ClaRecordStatus.Draft,
    [recordStatus]
  )
  const isCancellable = useMemo(
    () =>
      recordStatus === ClaRecordStatus.Approved ||
      recordStatus === ClaRecordStatus.Pending ||
      recordStatus === ClaRecordStatus.PendingPayment,
    [recordStatus]
  )
  const isEditable = useMemo(
    () => isNew || recordStatus === ClaRecordStatus.Pending || recordStatus === ClaRecordStatus.Draft,
    [isNew, recordStatus]
  )

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)

    if (!visible) {
      setIsEditing(false)
    }
  }, [visible, setFocus])

  useEffect(() => {
    if (visible) {
      setIsEditing(!!isEditable && !!editing)
    }
  }, [visible, isEditable, editing])

  useEffect(() => {
    if (claimRecord) {
      const {
        id,
        claimTypeId,
        providerName,
        receiptNo,
        expenseDate,
        expenseCurrencyCode,
        exchangeRate,
        exchangeUnit,
        expenseAmount,
        claimAmount,
        claimAmountTax,
        isTaxable,
        isClaimAmountTaxOverride,
        notes,
        expenses,
        cfJsonb
      } = claimRecord

      const attachments: UploadFile[] = claimRecordAttachments
        .filter(att => !att.claimRecordExpenseId)
        .map(
          att =>
            ({
              uid: att?.fileId || '',
              name: att?.fileName || '',
              size: att?.fileSize || 0,
              type: att?.fileFormat || '',
              url: `${baseUrl}/ssclaimrecord/${id}/attachment/${att?.id}/downloadfile`
            } as UploadFile)
        )

      setFormData({
        id,
        claimTypeId,
        providerName,
        receiptNo,
        expenseDate,
        expenseCurrencyCode,
        exchangeRate,
        exchangeUnit,
        expenseAmount,
        claimAmount,
        claimAmountTax,
        isTaxable,
        isClaimAmountTaxOverride,
        notes,
        expenses: expenses?.map(exp => ({
          ...exp,
          attachments: claimRecordAttachments
            .filter(att => att.claimRecordExpenseId === exp.id)
            .map(
              att =>
                ({
                  uid: att?.fileId || '',
                  name: att?.fileName || '',
                  size: att?.fileSize || 0,
                  type: att?.fileFormat || '',
                  status: 'done',
                  url: `${baseUrl}/ssclaimrecord/${id}/attachment/${att?.id}/downloadfile`
                } as UploadFile)
            )
        })),
        attachments,
        deletedAttachmentIds: [],
        cfJsonb
      })
    } else {
      setFormData(getEmptyForm())
    }

    setComment('')
    setIsRecompute(true)
  }, [claimRecord, claimRecordAttachments])

  const handleFormDataChange = useCallback((updated: Partial<SSClaimApply>) => {
    setErrors(undefined)
    setFormData(formData => ({ ...formData, ...updated }))
  }, [])

  const handleExpenseChange = useCallback((index: number, updated: Partial<SSClaimRecordExpense>) => {
    setErrors(undefined)

    if (
      [
        'expenseTypeId',
        'expenseDate',
        'expenseAmount',
        'expenseCurrencyCode',
        'exchangeRate',
        'exchangeUnit',
        'isClaimAmountTaxOverride',
        'isTaxable'
      ].some(key => key in updated)
    ) {
      setIsRecompute(true)
    }

    setFormData(formData => {
      const expenses = formData.expenses.map((exp, idx) => (index === idx ? { ...exp, ...updated } : exp))
      const claimAmount = expenses.reduce((acc, current) => acc + current.claimAmount, 0)
      const claimAmountTax = expenses.reduce((acc, current) => acc + current.claimAmountTax, 0)
      const expenseDate = expenses.reduce(
        (acc, current) => (moment(acc).isBefore(moment(current.expenseDate)) ? acc : current.expenseDate),
        TODAY
      )
      const expenseAmount = expenses.reduce((acc, current) => acc + current.expenseAmount, 0)

      return {
        ...formData,
        claimAmount,
        claimAmountTax,
        expenseDate,
        expenseAmount,
        expenses
      }
    })
  }, [])

  const handleExpenseRemove = useCallback((index: number) => {
    setErrors(undefined)
    setFormData(formData => {
      const expenses = formData.expenses.filter((exp, idx) => index !== idx)
      const claimAmount = expenses.reduce((acc, current) => acc + current.claimAmount, 0)
      const claimAmountTax = expenses.reduce((acc, current) => acc + current.claimAmountTax, 0)
      const expenseDate = expenses.reduce(
        (acc, current) => (moment(acc).isBefore(moment(current.expenseDate)) ? acc : current.expenseDate),
        TODAY
      )
      const expenseAmount = expenses.reduce((acc, current) => acc + current.expenseAmount, 0)

      return {
        ...formData,
        claimAmount,
        claimAmountTax,
        expenseDate,
        expenseAmount,
        expenses
      }
    })
  }, [])

  const handleExpenseAdd = useCallback(() => {
    setErrors(undefined)
    setFormData(formData => ({
      ...formData,
      expenses: [
        ...formData.expenses,
        getClaimRecordExpenseEmptyForm({
          sequence: formData.expenses.length + 1,
          expenseCurrencyCode: claimTypesDict[formData.claimTypeId]?.currencyCode,
          isTaxable: claimTypesDict[formData.claimTypeId]?.taxableType === ClaTaxableType.Yes
        })
      ]
    }))
  }, [claimTypesDict])

  const handleOk = useCallback(
    async (isSubmit: boolean = true) => {
      let result: ActionResult | undefined
      setSubmitting(isSubmit ? 'submit' : 'draft')
      try {
        result = await dispatch(applyMyClaim(formData, isSubmit))
      } finally {
        setSubmitting(undefined)
      }

      if (result?.errors) {
        setErrors(result.errors)
        errorRef.current?.scrollIntoView()
      }

      if (!result?.errors) {
        typeof onClose === 'function' && onClose('saved')
        setFormData(getEmptyForm())
      }
    },
    [formData, onClose]
  )

  const handleDelete = useCallback(() => {
    if (claimRecord) {
      const { id, claimTypeId } = claimRecord

      if (isDeletable) {
        confirm({
          title: `Delete "${claimTypesDict[claimTypeId]?.name}"`,
          content: `Do you want to delete "${claimTypesDict[claimTypeId]?.name}"?`,
          onOk: async () => {
            const result: ActionResult | undefined = await dispatch(deleteMyClaim(id))
            if (result?.errors) {
              setErrors(result.errors)
            }
            if (!result?.errors) {
              typeof onClose === 'function' && onClose('saved')
            }
          },
          okText: 'Delete',
          okType: 'danger'
        })
      } else {
        confirm({
          title: `Cancel "${claimTypesDict[claimTypeId]?.name}"`,
          content: `Do you want to cancel this "${claimTypesDict[claimTypeId]?.name}"?`,
          onOk: async () => {
            const result: ActionResult | undefined = await dispatch(cancelMyClaim(id))
            if (result?.errors) {
              setErrors(result.errors)
            }
            if (!result?.errors) {
              typeof onClose === 'function' && onClose('saved')
            }
          },
          okText: 'Cancel claim',
          okType: 'danger'
        })
      }
    }
  }, [claimTypesDict, claimRecord, isDeletable, onClose])

  const handleToggleEdit = useCallback(() => {
    setIsEditing(isEditing => !isEditing)
  }, [])

  const handleComment = useCallback(async () => {
    if (!id) return

    try {
      setCommenting(true)
      const result = await dispatch(addClaimRecordComment(id, comment))
      if (result?.errors) {
        setErrors(result.errors)
      }
      if (!result?.errors) {
        setErrors(undefined)
        setComment('')
      }
    } finally {
      setCommenting(false)
    }
  }, [id, comment])

  const handleComputeClaimAmountSingle = useCallback(
    async (
      expenseDate: string,
      expenseAmount: number,
      expenseCurrencyCode: string,
      exchangeRate: number,
      exchangeUnit: number,
      originalClaimAmount: number
    ) => {
      if (!formData.claimTypeId || !expenseDate || expenseAmount === 0 || exchangeRate === 0 || exchangeUnit === 0) {
        return { claimAmount: 0, claimAmountTax: 0 }
      }

      try {
        setClaimAmountLoading(true)
        const request: SSClaimAmount = {
          claimTypeId: formData.claimTypeId,
          expenseDate,
          expenseAmount,
          expenseCurrencyCode,
          exchangeRate,
          exchangeUnit,
          originalClaimAmount
        }
        const {
          result: { claimAmount, claimAmountTax }
        } = await apiGetMyClaimAmount(request)
        return { claimAmount, claimAmountTax }
      } finally {
        setClaimAmountLoading(false)
      }
    },
    [formData.claimTypeId]
  )

  const handleTabChange = useCallback((activeKey: string) => setActiveTab(activeKey as TabChoice), [])

  const handleCloseDrawer = useCallback(() => {
    setActiveTab('request')
    onClose('cancelled')
  }, [onClose])

  const drawerTitle = useMemo(() => {
    if (isNew) {
      return 'Apply claim'
    }

    if (isEditing) {
      return 'Edit my claim'
    }

    return 'My claim'
  }, [isNew, isEditing])

  const okText = useMemo(() => {
    if (!isEditable) {
      return 'Close'
    }

    if (isNew || (isEditing && recordStatus === ClaRecordStatus.Draft)) {
      return (
        <Space>
          Send request <i className="fal fa-paper-plane-top" />
        </Space>
      )
    }

    if (isEditing) {
      return (
        <Space>
          Resend request <i className="fal fa-paper-plane-top" />
        </Space>
      )
    }

    return 'Edit'
  }, [isEditable, isNew, isEditing, recordStatus])

  const onSubmit = useCallback(() => {
    if (!isEditable) {
      return onClose('cancelled')
    }

    if (isEditing) {
      return handleOk(true)
    } else {
      return handleToggleEdit()
    }
  }, [isEditable, isEditing, onClose, handleOk, handleToggleEdit])

  const handleComputeClaimAmountMultple = useCallback(async () => {
    if (!formData.claimTypeId || formData.expenses.length === 0) {
      return
    }

    try {
      setClaimAmountLoading(true)
      const request: SSClaimAmountMultipleRequest = {
        claimTypeId: formData.claimTypeId,
        details: formData.expenses.map(e => ({
          sequence: e.sequence,
          expenseTypeId: e.expenseTypeId,
          expenseDate: e.expenseDate,
          expenseAmount: e.expenseAmount,
          expenseCurrencyCode: e.expenseCurrencyCode,
          exchangeRate: e.exchangeRate,
          exchangeUnit: e.exchangeUnit,
          isTaxable: e.isTaxable,
          isClaimAmountTaxOverride: e.isClaimAmountTaxOverride,
          claimAmountTax: e.claimAmountTax,
          originalClaimAmount: isDraft ? 0 : claimRecord?.expenses.find(o => o.id === e.id)?.claimAmount || 0
        }))
      }
      const { result } = await apiGetClaimAmountMultiple(request)

      if (result) {
        const { totalClaimAmount, totalClaimAmountTax, details } = result

        handleFormDataChange({
          claimAmount: totalClaimAmount,
          claimAmountTax: totalClaimAmountTax,
          expenses: formData.expenses.map(e => {
            const item = details.find(d => d.sequence === e.sequence)
            return { ...e, claimAmount: item?.claimAmount || 0, claimAmountTax: item?.claimAmountTax || 0 }
          })
        })
      }

      setIsRecompute(false)
    } finally {
      setClaimAmountLoading(false)
    }
  }, [formData, claimRecord, handleFormDataChange, isDraft])

  const handleDownloadAllAttachment = useCallback(async () => {
    const { status, result, errors, message, errorData } = await apiDownloadAllMyClaimAttachment(claimRecord?.id || '')

    if (status) {
      const fileName = `claim_attachment_${claimRecord?.claimNo}_${getFileTimestamp()}.zip`
      downloadWithDom(result, fileName)
    } else {
      console.error('Error while downloading', errors)
      setErrors(errors)
      showError(message, errorData)
    }
  }, [claimRecord])

  return (
    <DrawerForm
      className="my-claim-apply-drawer"
      open={visible}
      title={drawerTitle}
      okText={okText}
      okDisabled={claimAmountLoading || submitting === 'draft' || (isMultiple && isEditing && isRecompute)}
      onClose={handleCloseDrawer}
      confirmLoading={submitting === 'submit'}
      width={isMultiple ? 1200 : 600}
      showDelete={!!id && (isDeletable || isCancellable)}
      deleteText={isDeletable ? 'Delete' : 'Cancel claim'}
      onDelete={handleDelete}
      formId={formId}
      hideFooter={activeTab === 'comment'}
      extras={
        <>
          {isEditing && isMultiple && <Button onClick={handleComputeClaimAmountMultple}>Calculate claim amount</Button>}
          {isEditing && !hasSubmitted && (
            <Button
              onClick={() => handleOk(false)}
              loading={submitting === 'draft'}
              disabled={submitting === 'submit' || (isMultiple && isRecompute)}
            >
              Save as draft
            </Button>
          )}
        </>
      }
    >
      <Form id={formId} onFinish={onSubmit}>
        {fetching ? (
          <Skeleton active />
        ) : (
          <Tabs
            defaultActiveKey={activeTab || 'request'}
            onChange={handleTabChange}
            {...(id ? {} : { renderTabBar: () => <></> })}
            items={[
              {
                key: 'request',
                label: 'Request',
                children: (
                  <>
                    {claimRecord?.id && (
                      <Row gutter={[15, 10]} hidden={isNew}>
                        <Col span={12} style={{ fontSize: 12, fontWeight: 'bold' }}>
                          <Form.Item>{claimRecord?.claimNo ? `Claim #${claimRecord?.claimNo}` : ''}</Form.Item>
                        </Col>
                        <Col span={12} style={{ textAlign: 'right' }}>
                          <Space direction="vertical">
                            {!isEditing && claimRecordAttachments.length > 0 && (
                              <Link onClick={handleDownloadAllAttachment}>download all attachments</Link>
                            )}
                            <ClaimStatusTag status={recordStatus} recordType={claimRecord.recordType} />
                          </Space>
                        </Col>
                      </Row>
                    )}
                    {claimRecord?.recordType === ClaRecordType.cancellation && (
                      <Row>
                        <Col span={24}>
                          <Form.Item label="Application type">
                            <Tag type="secondary">Cancellation</Tag>
                          </Form.Item>
                        </Col>
                      </Row>
                    )}
                    <Row gutter={15}>
                      <Col span={isMultiple ? 12 : 24}>
                        <Form.Item
                          label="Claim type"
                          validateStatus={errors?.claimTypeId ? 'error' : ''}
                          help={errors?.claimTypeId}
                        >
                          {!isEditing || !isNew ? (
                            claimType?.name
                          ) : (
                            <Select
                              showSearch
                              allowClear={false}
                              optionFilterProp="title"
                              loading={entitledClaimTypesLoading}
                              value={formData.claimTypeId}
                              onChange={(claimTypeId: string) =>
                                handleFormDataChange({
                                  ...EMPTY_FORM_DATA,
                                  claimTypeId,
                                  expenseCurrencyCode: claimTypesDict[claimTypeId]?.currencyCode,
                                  isTaxable: claimTypesDict[claimTypeId]?.taxableType === ClaTaxableType.Yes,
                                  expenses:
                                    claimTypesDict[claimTypeId]?.expenseSubmissionType ===
                                    ClaExpenseSubmissionType.Multiple
                                      ? [
                                          getClaimRecordExpenseEmptyForm({
                                            sequence: 1,
                                            expenseCurrencyCode: claimTypesDict[claimTypeId]?.currencyCode,
                                            isTaxable: claimTypesDict[claimTypeId]?.taxableType === ClaTaxableType.Yes
                                          })
                                        ]
                                      : []
                                })
                              }
                            >
                              {entitledClaimTypes?.map(data => (
                                <Select.Option key={data.id} value={data.id || ''} title={data.name}>
                                  {data.name}
                                </Select.Option>
                              ))}
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                      {isMultiple && (
                        <Col span={12}>
                          <Form.Item label="Notes" validateStatus={errors?.notes ? 'error' : ''} help={errors?.notes}>
                            {!isEditing ? (
                              formData.notes || '-'
                            ) : (
                              <Input.TextArea
                                rows={1}
                                value={formData.notes}
                                onChange={(value?: ChangeEvent<HTMLTextAreaElement>) =>
                                  handleFormDataChange({ notes: value?.target.value })
                                }
                              />
                            )}
                          </Form.Item>
                        </Col>
                      )}
                    </Row>
                    <ErrorDisplay ref={errorRef} errors={errors} />
                    {!isMultiple && (
                      <MyClaimRecordSingleForm
                        data={formData}
                        original={claimRecord}
                        loading={fetching || attachmentLoading}
                        readOnly={!isEditing}
                        onChange={handleFormDataChange}
                        onComputeClaimAmount={handleComputeClaimAmountSingle}
                        claimAmountLoading={claimAmountLoading}
                      />
                    )}
                    {isMultiple &&
                      (fetching || attachmentLoading ? (
                        <Skeleton active />
                      ) : (
                        <>
                          <ClaimRecordCfJsonb
                            ctCustomField={claimType?.customField}
                            cfJsonb={formData.cfJsonb}
                            cfConfigsDict={cfConfigsDict}
                            loading={cfConfigsDictLoading}
                            readOnly={!isEditing}
                            onChange={(cfJsonb: string) => handleFormDataChange({ cfJsonb })}
                          />
                          {formData.expenses?.map((exp, index) => (
                            <>
                              <MyClaimRecordMultipleFormRow
                                key={exp.id}
                                index={index}
                                claimTypeId={formData.claimTypeId}
                                data={exp}
                                readOnly={!isEditing}
                                claimAmountLoading={claimAmountLoading}
                                onChange={handleExpenseChange}
                                onRemove={handleExpenseRemove}
                              />
                              {!(index === formData.expenses.length - 1) && (
                                <div className="expense-divider">
                                  <hr />
                                </div>
                              )}
                            </>
                          ))}
                          {isEditing && (
                            <Form.Item>
                              <Link onClick={handleExpenseAdd}>add more</Link>
                            </Form.Item>
                          )}
                          {[ClaRecordStatus.Pending, ClaRecordStatus.PendingCancel].includes(
                            recordStatus as ClaRecordStatus
                          ) && (
                            <ClaimCurrentApprover
                              approvers={claimRecord?.currentApprovers || []}
                              hidden={isEditing || isNew}
                            />
                          )}
                          <ClaimRecordApprovalHistories
                            histories={claimRecordApprovalHistories}
                            status={recordStatus || ''}
                            hidden={isEditing || isNew}
                            recordType={claimRecord?.recordType}
                            {...pick(claimRecord!, 'creatorName', 'createdDate', 'submitterName', 'submittedDate')}
                          />
                          <Row className="claim-record-form__claim-amount">
                            <Col span={24}>
                              <SubHeader type="primary">
                                <Row>
                                  <Col flex="auto">Total claim amount</Col>
                                  <Col flex="none">
                                    <AmountDisplay
                                      symbol={claimType?.currencyCode}
                                      value={formData.claimAmount}
                                      loading={claimAmountLoading}
                                    />
                                  </Col>
                                </Row>
                                {!claimAmountLoading && isExpenseTaxable && (
                                  <Row>
                                    <Col flex="auto" />
                                    <Col flex="none">
                                      <SecondaryText size="small" style={taxStyle}>
                                        Inclusive of GST{' '}
                                        <AmountDisplay
                                          symbol={claimType?.currencyCode}
                                          value={formData.claimAmountTax}
                                        />
                                      </SecondaryText>
                                    </Col>
                                  </Row>
                                )}
                              </SubHeader>
                            </Col>
                          </Row>
                        </>
                      ))}
                  </>
                )
              },
              {
                key: 'comment',
                label: (
                  <>
                    Comments
                    <Badge count={comments.length} type="primary" />
                  </>
                ),
                children: (
                  <>
                    <Row align="top" gutter={15}>
                      <Col flex={1}>
                        <Form.Item
                          label="Enter your comment"
                          validateStatus={errors?.comment ? 'error' : ''}
                          help={errors?.comment}
                        >
                          <Input.TextArea
                            ref={focusRef}
                            rows={2}
                            value={comment}
                            onChange={(event: ChangeEvent<HTMLTextAreaElement>) => setComment(event.target.value)}
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item label="&nbsp;">
                          <Button onClick={handleComment} loading={commenting}>
                            Send
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                    {id && <SSClaimRecordComments id={id} />}
                  </>
                )
              }
            ]}
          />
        )}
      </Form>
    </DrawerForm>
  )
}
