import { createSelector, Dictionary } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { KeyValue } from '~/types/common'
import { StoreState } from '~/types/store'
import { LveRequireConfirm } from '~/constants'
import {
  LeaveTypeState,
  LpEntitlementState,
  LpEntPerTimeState,
  LpLeaveGroupState,
  LtPolicyState,
  LpForfeitAbsentState,
  LpCfInfoState
} from '../types'
import { formatNumberUnit, UnitType } from '~/utils/util'

export type LtPolicyExt = LtPolicyState & {
  confirmDisplay: string
  entitlements: LpEntitlementState[]
  entitlementDisplay: string
  entPerTime: LpEntPerTimeState[]
  entPerTimeDisplay: string
  leaveGroups: LpLeaveGroupState[]
  leaveGroupDisplay: string
  forfeitLeaveTypes: LpForfeitAbsentState[]
  forfeitLeaveTypeDisplay: string
} & LpCfInfoState

const requireConfirmMap: { [key: string]: string } = {
  [LveRequireConfirm.yesEither]: 'or',
  [LveRequireConfirm.yesBoth]: 'and'
}

export const selectLtPoliciesById = createSelector(
  (state: StoreState) => state.leave.ltPolicies,
  (state: StoreState) => state.leave.ltPoliciesExtra,
  (state: StoreState) => state.master.keyvalues['leaveGroup'],
  (state: StoreState) => state.leave.leaveTypes,
  (ltPoliciesState, ltPoliciesExtraState, leaveGroupsState, leaveTypesState) =>
    memoize((leaveTypeId: string) => {
      const ltPoliciesExtra = ltPoliciesExtraState[leaveTypeId]?.entities || {}
      const leaveGroups = leaveGroupsState?.entities
      const ltPolicies = Object.values(ltPoliciesState[leaveTypeId]?.entities || {}) as LtPolicyState[]
      const leaveTypes = leaveTypesState?.entities

      return ltPolicies.map(p => {
        const lpEntitlements = ltPoliciesExtra[p.id]?.entitlements || []
        const lpEntPerTime = ltPoliciesExtra[p.id]?.entPerTime || []
        const lpLeaveGroups = ltPoliciesExtra[p.id]?.leaveGroups || []
        const lpForfeitAbsents = ltPoliciesExtra[p.id]?.forfeitAbsents || []

        return {
          ...p,
          confirmDisplay: getConfirmDisplay(p.requireConfirm, p.requireMonths),
          entitlements: lpEntitlements,
          entitlementDisplay: getEntitlementDisplay(lpEntitlements),
          entPerTime: lpEntPerTime,
          entPerTimeDisplay: getEntPerTimeDisplay(lpEntPerTime),
          leaveGroups: lpLeaveGroups,
          leaveGroupDisplay: getLeaveGroupDisplay(lpLeaveGroups, leaveGroups),
          forfeitLeaveTypes: lpForfeitAbsents,
          forfeitLeaveTypeDisplay: getForfeitAbsentDisplay(lpForfeitAbsents, leaveTypes)
        } as LtPolicyExt
      })
    })
)

const getConfirmDisplay = (requireConfirm: string, requireMonths: number) => {
  const confirmCodes = [LveRequireConfirm.yesEither, LveRequireConfirm.yesBoth]
  const join = requireConfirmMap[requireConfirm] || ''
  const requireMonthsStr = requireMonths > 0 ? `Minimum ${requireMonths} service months ${join}` : ''
  const requireConfirmStr = requireMonthsStr
    ? ` ${confirmCodes.includes(requireConfirm) ? 'confirmed' : ''}`
    : `${confirmCodes.includes(requireConfirm) ? 'Confirmed employees' : ''}`
  return `${requireMonthsStr}${requireConfirmStr}`
}

const getEntitlementDisplay = (entitlements: LpEntitlementState[]) => {
  let min: number | undefined, max: number | undefined, unit: UnitType | undefined

  entitlements.forEach(ent => {
    if (min == null || min > ent.entitlement) {
      min = ent.entitlement
    }
    if (max == null || max < ent.entitlement) {
      max = ent.entitlement
    }
    unit = ent.unit as UnitType
  })

  if (!min || !max || !unit) return ''

  if (min === max) {
    return `${formatNumberUnit(min, unit)}`
  } else {
    return `From ${formatNumberUnit(min, unit)} - up to ${formatNumberUnit(max, unit)}`
  }
}

const getEntPerTimeDisplay = (entPerTime: LpEntPerTimeState[]) => {
  let min: number | undefined, max: number | undefined, unit: UnitType | undefined

  entPerTime.forEach(ent => {
    if (min == null || min > ent.entitlement) {
      min = ent.entitlement
    }
    if (max == null || max < ent.entitlement) {
      max = ent.entitlement
    }
    unit = ent.unit as UnitType
  })

  if (!min || !max || !unit) return ''

  if (min === max) {
    return `${formatNumberUnit(min, unit)}`
  } else {
    return `From ${formatNumberUnit(min, unit)} - up to ${formatNumberUnit(max, unit)}`
  }
}

const getLeaveGroupDisplay = (lpLeaveGroups: LpLeaveGroupState[], leaveGroups: Dictionary<KeyValue>) => {
  if (lpLeaveGroups.length === 0) {
    return 'All employees'
  } else {
    let leaveGroupIds: string[] = []
    lpLeaveGroups.forEach(item => {
      !leaveGroupIds.includes(item.leaveGroupId) && leaveGroupIds.push(item.leaveGroupId)
    })

    return leaveGroupIds
      .map(id => leaveGroups[id]?.value || '')
      .filter(lg => !!lg)
      .join(', ')
  }
}

const getForfeitAbsentDisplay = (lpForfeitAbsents: LpForfeitAbsentState[], leaveTypes: Dictionary<LeaveTypeState>) => {
  if (lpForfeitAbsents.length === 0) {
    return 'No leave type(s) selected'
  } else {
    let leaveTypeIds: string[] = []
    lpForfeitAbsents.forEach(item => {
      !leaveTypeIds.includes(item.leaveTypeId) && leaveTypeIds.push(item.leaveTypeId)
    })

    return leaveTypeIds
      .map(id => leaveTypes[id]?.name || '')
      .filter(lt => !!lt)
      .join(', ')
  }
}
