import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Dictionary } from '@reduxjs/toolkit'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchFormulas } from '../actions'
import { FormulaState } from '../types'

export const useFormulas = (): [Dictionary<FormulaState>, boolean] => {
  const data = useSelector((state: StoreState) => state.formula.formula?.entities || {})
  const loading = useSelector((state: StoreState) => state.formula.formulaLoading)

  useEffect(() => {
    dispatch(fetchFormulas({ strategy: 'when-empty' }))
  }, [])

  return [data, loading]
}
