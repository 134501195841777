import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { AttendancePeriodState } from '../types'

export const selectAttendancePeriodsForPayroll = createSelector(
  (state: StoreState) => state.attendance.attendancePeriodsForPayroll,
  attendancePeriodsState => {
    return (Object.values(attendancePeriodsState?.entities || {}) as AttendancePeriodState[]).sort((a, b) => {
      if (a.payPeriod === b.payPeriod) {
        if (a.startDate === b.startDate) {
          return a.endDate > b.endDate ? -1 : a.endDate < b.endDate ? 1 : 0
        }
        return a.startDate > b.startDate ? -1 : a.startDate < b.startDate ? 1 : 0
      } else {
        return a.payPeriod > b.payPeriod ? -1 : 1
      }
    })
  }
)
