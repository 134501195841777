import { showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiUpdateViewSelection } from '../api/view.api'
import { Screen, IViewSelection } from '../types'
import { setViewSchema } from '../reducers'

export const updateViewSelection = (screenCode: Screen, viewId: string, schema: IViewSelection): ThunkResult<void> => {
  return async dispatch => {
    if (!screenCode || !viewId) return

    const { status, result, errors, message, errorData } = await apiUpdateViewSelection(screenCode, viewId, schema)

    if (status) {
      dispatch(setViewSchema({ screenCode, data: result }))
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
