import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { PayGroupState } from '../types'

export const selectPayGroups = createSelector(
  (state: StoreState) => state.master.masters['payGroup'],
  (payGroupState): PayGroupState[] => {
    const payGroups = Object.values(payGroupState?.entities || {}) as PayGroupState[]
    return payGroups.sort((a, b) => a?.name.localeCompare(b?.name || '') || 0)
  }
)
