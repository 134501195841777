import React, { FC } from 'react'
import { ReactComponent as ZealysLogo } from '~/svgs/logo/logo-secondary-text.svg'
import './Branding.less'

const Logo: FC = () => {
  return (
    <div className="branding">
      <ZealysLogo className="branding__logo" />
      {/* <img src="/schlumberger.png" alt="Company Logo" /> */}
      {/* <h1>Schlumberger</h1> */}
    </div>
  )
}

export class Branding {
  static Logo = Logo
}
