import { Operation } from 'fast-json-patch'
import { UploadFile } from '~/core-components'
import { request, getBaseUrl } from '~/utils/request'
import { CompanyState, CompanyExtraState, CompanyOfficeState, ICompanyInfo, ICompanyOfficeInfo } from '../types'

const baseUrl = getBaseUrl('/employee/company')

export const apiGetCompanies = () => request<CompanyState[]>('get', baseUrl)

export const apiGetCompaniesExtra = () => request<CompanyExtraState[]>('get', `${baseUrl}/extra`)

export const apiGetCompany = (id: string) => request<CompanyState>('get', `${baseUrl}/${id}`)

export const apiAddCompany = (company: ICompanyInfo) => request<{ id: string }>('post', baseUrl, company)

export const apiUpdateCompany = (id: string, company: Operation[]) =>
  request<void>('patch', `${baseUrl}/${id}`, company)

export const apiDeleteCompany = (id: string) => request<void>('delete', `${baseUrl}/${id}`)

export const apiGetCompanyOffices = (companyId: string) =>
  request<CompanyOfficeState[]>('get', `${baseUrl}/${companyId}/office`)

export const apiAddCompanyOffice = (companyId: string, officeId: string) =>
  request<{ id: string; isMain?: boolean }>('post', `${baseUrl}/${companyId}/office/${officeId}`)

export const apiAddCompanyNewOffice = (companyId: string, companyOffice: ICompanyOfficeInfo) =>
  request<{ id: string; officeId: string }>('post', `${baseUrl}/${companyId}/office`, companyOffice)

export const apiUpdateCompanyOffice = (companyId: string, officeId: string, companyOffice: Operation[]) =>
  request<void>('patch', `${baseUrl}/${companyId}/office/${officeId}`, companyOffice)

export const apiDeleteCompanyOffice = (companyId: string, officeId: string) =>
  request<void>('delete', `${baseUrl}/${companyId}/office/${officeId}`)

export const apiDeleteCompanyLogo = (id: string) => request<void>('post', `${baseUrl}/${id}/removelogo`)

export const apiUploadCompanyLogo = (id: string, file: UploadFile) => {
  let formData = new FormData()
  formData.append('file', file.xhr, file.name)

  return request<{ logoId: string }>('post', `${baseUrl}/${id}/uploadlogo`, formData)
}
