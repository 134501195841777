import { FC, useCallback, useEffect, useState } from 'react'
import { Col, EditableCard, EditableCardState, Row, SysOptions } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { ActionResult, Errors } from '~/types/store'
import { dispatch } from '~/stores/store'
import { AttApproval, Permission, PermissionAction } from '~/constants'
import { AttendanceGroupTimeLogState, AttendanceGroupState } from '../../../types'
import { updateAttendanceGroup } from '../../../actions'
import { Alert, Form, Space, Switch } from '~/core-components'
import { SelectWorkflow } from '~/features/workflow'

interface AttendanceGroupTimeLogProps {
  attendanceGroup?: AttendanceGroupState
  onEdit?: () => void
  onSave?: () => void
  onCancel?: () => void
}

const EMPTY_FORM_DATA: AttendanceGroupTimeLogState = {
  ssTimeLogEdit: false,
  timeLogApproval: AttApproval.ReportingManager
}

export const AttendanceGroupTimeLog: FC<AttendanceGroupTimeLogProps> = ({
  attendanceGroup,
  onEdit,
  onSave,
  onCancel
}) => {
  const [cardState, setCardState] = useState<EditableCardState>()
  const [formData, setFormData] = useState<AttendanceGroupTimeLogState>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const canModify = usePermissionGate(Permission.attendanceGroup, PermissionAction.Modify)

  useEffect(() => {
    if (attendanceGroup) {
      const { ssTimeLogEdit, timeLogApproval } = attendanceGroup
      setFormData({
        ssTimeLogEdit,
        timeLogApproval
      })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [attendanceGroup])

  const handleEdit = useCallback(() => {
    setCardState('editing')
    typeof onEdit === 'function' && onEdit()
  }, [onEdit])

  const handleSave = useCallback(async () => {
    if (attendanceGroup) {
      setCardState('saving')
      setErrors(undefined)

      typeof onSave === 'function' && onSave()

      let result: ActionResult | undefined
      try {
        result = await dispatch(updateAttendanceGroup(attendanceGroup.id, attendanceGroup, formData))
      } catch {
        setCardState('editing')
      }

      if (result?.errors) {
        setCardState('editing')
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setCardState(undefined)
      }
    }
  }, [attendanceGroup, formData, onSave])

  const handleCancel = useCallback(() => {
    typeof onCancel === 'function' && onCancel()
    setCardState(undefined)
    setErrors(undefined)

    if (attendanceGroup) {
      const { ssTimeLogEdit, timeLogApproval } = attendanceGroup
      setFormData({
        ssTimeLogEdit,
        timeLogApproval
      })
    }
  }, [attendanceGroup, onCancel])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(data => ({ ...data, ...updates }))
  }, [])

  const readOnly = cardState !== 'editing' && cardState !== 'saving'

  return (
    <EditableCard
      title="Time log setting"
      bodyStyle={{ paddingBottom: attendanceGroup ? 6 : 24, paddingTop: 6 }}
      state={!canModify ? 'readonly' : cardState}
      formId="form-attendance-group-time-log"
      onEdit={handleEdit}
      onSave={handleSave}
      onCancel={handleCancel}
    >
      <Row gutter={30}>
        <Col span={24}>
          <Form.Item validateStatus={errors?.ssTimeLogEdit ? 'error' : ''} help={errors?.ssTimeLogEdit}>
            <Space>
              <label>Allow edit</label>
              <Switch
                checkedChildren="Yes"
                unCheckedChildren="No"
                checked={formData.ssTimeLogEdit}
                onChange={(checked: boolean) => handleFormDataChange({ ssTimeLogEdit: checked })}
                disabled={readOnly}
              />
            </Space>
          </Form.Item>
        </Col>
      </Row>
      <Row hidden={!formData.ssTimeLogEdit}>
        <Col span={12}>
          <Alert message="Note: If time logs are editable, changes will require approval from the attendance or main reporting manager." />
          {/* <Form.Item
            label="Approval"
            validateStatus={errors?.timeLogApproval ? 'error' : ''}
            help={errors?.timeLogApproval}
          >
            <SysOptions
              type="att_approval"
              readOnly={readOnly}
              value={formData.timeLogApproval}
              onChange={(timeLogApproval: string) => handleFormDataChange({ timeLogApproval })}
            />
          </Form.Item> */}
        </Col>
      </Row>
    </EditableCard>
  )
}
