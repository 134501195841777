import { setYtdIndexView, setYtdIndexViewLoading } from '../reducers'
import { apiGetYtdIndexView } from '../api/ytd-index.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions, Pagination } from '~/types/common'

export const fetchYtdIndexView = (
  viewId: string,
  ytdYear: string | undefined,
  pagination: Pagination,
  search: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!viewId || !ytdYear) return

    const loading = getState().tax.ytdIndexViewLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().tax.ytdIndexView?.entities[viewId]
      if (hasData) return
    }

    try {
      dispatch(setYtdIndexViewLoading(true))
      const { result, status } = await apiGetYtdIndexView(viewId, ytdYear, { ...pagination, search })
      if (status) {
        dispatch(setYtdIndexView(result))
      }
    } finally {
      dispatch(setYtdIndexViewLoading(false))
    }
  }
}
