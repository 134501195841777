import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { ClaimPendingPaymentViewState } from '../types'

export const selectClaimPendingPaymentsView = createSelector(
  (state: StoreState) => state.claim.claimPendingPaymentsView,
  claimPendingPaymentsState =>
    memoize((viewId: string): ClaimPendingPaymentViewState => {
      const claimPendingPayments = claimPendingPaymentsState.entities[viewId] || ({} as ClaimPendingPaymentViewState)
      return claimPendingPayments
    })
)
