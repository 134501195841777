import React, { FC, useCallback } from 'react'
import { Form, Link, Tag } from '~/core-components'
import { Col, Person, Row } from '~/components'
import { ClaimTypeNameComponent } from '~/features/claim'
import { ClaExpenseSubmissionType, ClaRecordType } from '~/constants'
import { downloadWithDom, getBaseUrl, getFileTimestamp, showError } from '~/utils'
import { SSClaimTaskTokenState } from '../../types'
import { apiDownloadAllAttachmentByToken } from '../../api/ss-claim-task-token.api'

interface MyClaimTaskTokenHeaderProps {
  token: string
  data: SSClaimTaskTokenState
}

const fileBaseUrl = getBaseUrl('/filestore')

export const MyClaimTaskTokenHeader: FC<MyClaimTaskTokenHeaderProps> = ({ token, data }) => {
  const isMultiple = data?.claimType?.expenseSubmissionType === ClaExpenseSubmissionType.Multiple

  const handleDownloadAllAttachment = useCallback(async () => {
    const { status, result, errors, message, errorData } = await apiDownloadAllAttachmentByToken(token)

    if (status) {
      const fileName = `claim_attachment_${data.task.claimNo}_${getFileTimestamp()}.zip`
      downloadWithDom(result, fileName)
    } else {
      console.error('Error while downloading', errors)
      showError(message, errorData)
    }
  }, [token, data.task.claimNo])

  return (
    <>
      <Row>
        <Col span={16}>
          <Form.Item>
            <Person
              name={data.employee.name}
              description={data.employee.description}
              size={36}
              photo={data.employee.photoId && `${fileBaseUrl}/publicfile/${data.employee.photoId}/thumbnailphoto/${36}`}
            />
          </Form.Item>
        </Col>
        <Col span={8} style={{ textAlign: 'right' }}>
          {data.attachments.length > 0 && <Link onClick={handleDownloadAllAttachment}>download all attachments</Link>}
        </Col>
      </Row>
      <Row gutter={[15, 10]}>
        <Col span={24} style={{ fontSize: 12, fontWeight: 'bold' }}>
          <Form.Item>{data.task.claimNo ? `Claim #${data.task.claimNo}` : ''}</Form.Item>
        </Col>
      </Row>
      {data.task.recordType === ClaRecordType.cancellation && (
        <Row>
          <Col span={24}>
            <Form.Item label="Application type">
              <Tag type="secondary">Cancellation</Tag>
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row gutter={[15, 10]}>
        <Col span={isMultiple ? 12 : 24}>
          <Form.Item label="Claim type">
            <ClaimTypeNameComponent icon={data.claimType.icon} name={data.claimType.name} />
          </Form.Item>
        </Col>
        {isMultiple && (
          <Col span={12}>
            <Form.Item label="Notes">{data.task.notes || '-'}</Form.Item>
          </Col>
        )}
      </Row>
    </>
  )
}
