import { RefObject } from 'react'
import useInView from 'react-cool-inview'

interface Options<T> {
  ref?: RefObject<T>
  root?: HTMLElement
  rootMargin?: string
  threshold?: number | number[]
  trackVisibility?: boolean
  delay?: number
}

export const useFirstInView = <T extends HTMLElement>(options?: Options<T>) => {
  const { ref, inView, ...rest } = useInView<T>({ ...options, unobserveOnEnter: true })

  return { ref, inView, ...rest }
}
