import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import classNames from 'classnames'
import moment from 'moment-timezone'
import { LoadingOutlined } from '@ant-design/icons'
import { Button, Card, ColumnsType, Form, Input, Space, Spin, Table, Text } from '~/core-components'
import { Col, DocumentTitle, Person, Row } from '~/components'
import { ISaveTimeLog, TimeLogRequestData } from '~/features/attendance'
import { EMP_PATH } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { Errors } from '~/types/store'
import { decodeJwt, getBaseUrl } from '~/utils'
import { approveTimeLogByToken, fetchMyTimeLogTaskByToken, rejectTimeLogByToken } from '../../actions'
import { SSTimeLogTaskTokenState } from '../../types'
import './MyTimeLogTaskToken.less'

interface MyTimeLogTaskTokenProps {}

type ActionType = 'approve' | 'reject' | 'comment'

const fileBaseUrl = getBaseUrl('/filestore')

export const MyTimeLogTaskToken: FC<MyTimeLogTaskTokenProps> = () => {
  const history = useHistory()
  const match = useRouteMatch<{ token: string }>()
  const token = match.params.token
  const [fetching, setFetching] = useState(true)
  const [data, setData] = useState<SSTimeLogTaskTokenState>()
  const [forbidden, setForbidden] = useState(false)
  const [completed, setCompleted] = useState<'approved' | 'rejected'>()
  const classes = classNames('my-time-log-task-token', { 'my-time-log-task-token--completed': !!completed })

  const [loading, setLoading] = useState<ActionType>()
  const [comment, setComment] = useState('')
  const [errors, setErrors] = useState<Errors>()

  useEffect(() => {
    const { tenant_code } = decodeJwt(token)
    localStorage.setItem('tenant', tenant_code || '')
  }, [token])

  const fetchData = useCallback(async () => {
    if (token) {
      try {
        setFetching(true)
        const { result, status, statusCode } = await dispatch(fetchMyTimeLogTaskByToken(token))
        if (status) {
          setData(result)
        } else if (statusCode === 403) {
          setForbidden(true)
        }
      } finally {
        setFetching(false)
      }
    }
  }, [token])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const handleApprove = useCallback(async () => {
    try {
      setLoading('approve')
      setErrors(undefined)

      const result = await dispatch(approveTimeLogByToken(token, comment))

      if (result?.errors) {
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setCompleted('approved')
      }
    } finally {
      setLoading(undefined)
    }
  }, [token, comment])

  const handleReject = useCallback(async () => {
    try {
      setLoading('reject')
      setErrors(undefined)

      const result = await dispatch(rejectTimeLogByToken(token, comment))

      if (result?.errors) {
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setCompleted('rejected')
      }
    } finally {
      setLoading(undefined)
    }
  }, [token, comment])

  const handleLogin = useCallback(() => {
    history.replace(EMP_PATH)
  }, [history])

  const columns: ColumnsType<TimeLogRequestData<ISaveTimeLog>> = useMemo(
    () => [
      {
        key: 'label',
        dataIndex: 'label',
        width: 150
      },
      {
        title: 'Current',
        key: 'current',
        dataIndex: 'current',
        render: (value: string, record: TimeLogRequestData<ISaveTimeLog>) =>
          value ? (
            record.field === 'timeOutRequest' ? (
              <>
                {record.currentTimeIn && moment(value).diff(moment(record.currentTimeIn), 'day') !== 0 && (
                  <Text size="small">{moment(value).format('DD MMM YYYY')}</Text>
                )}
                <div>{moment(value).format('HH:mm')}</div>
              </>
            ) : (
              moment(value).format('HH:mm')
            )
          ) : null
      },
      {
        align: 'center',
        render: () => <i className="fa-light fa-arrow-right-long" style={{ color: '#3caef2' }} />
      },
      {
        title: 'Change to',
        key: 'request',
        dataIndex: 'request',
        render: (value: string, record: TimeLogRequestData<ISaveTimeLog>) =>
          record.field === 'timeOutRequest' ? (
            <>
              {record.requestTimeIn && moment(value).diff(moment(record.requestTimeIn), 'day') !== 0 && (
                <Text size="small">{moment(value).format('DD MMM YYYY')}</Text>
              )}
              <div>{moment(value).format('HH:mm')}</div>
            </>
          ) : (
            moment(value).format('HH:mm')
          )
      }
    ],
    []
  )

  const requestData: TimeLogRequestData<ISaveTimeLog>[] = useMemo(() => {
    let result: TimeLogRequestData<ISaveTimeLog>[] = [
      {
        field: 'timeInRequest',
        label: 'Time in',
        current: data?.task?.timeIn,
        request: data?.task?.timeInRequest ?? data?.task?.timeIn
      }
    ]

    if (data?.task?.timeOutRequest) {
      result.push({
        field: 'timeOutRequest',
        label: 'Time out',
        current: data?.task?.timeOut,
        request: data?.task?.timeOutRequest ?? data?.task?.timeOut,
        currentTimeIn: data?.task?.timeIn,
        requestTimeIn: data?.task?.timeInRequest
      })
    }

    return result
  }, [data])

  return (
    <div className={classes}>
      <DocumentTitle title="Time Log Approval" />
      {fetching ? (
        <Spin indicator={<LoadingOutlined spin />} />
      ) : (
        <Card style={{ width: 'auto' }}>
          <div className="my-time-log-task-token__content">
            <Row className="my-time-log-task-token__body" gutter={50}>
              <Col span={24}>
                <div className="my-time-log-task-token__form">
                  {!data && !fetching && (
                    <div className="my-time-log-task-token__form--empty">
                      {forbidden ? 'Access denied' : 'Not found'}
                    </div>
                  )}
                  {data && !fetching && (
                    <Form>
                      <Row justify="center" hidden={!completed}>
                        <Col style={{ textAlign: 'center' }}>
                          <Form.Item>
                            <h2>Time log {completed}</h2>
                            <h4>You have successfully {completed} the time log.</h4>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Form.Item>
                            <Person
                              name={data.employee.name}
                              description={data.employee.description}
                              size={36}
                              photo={
                                data.employee.photoId &&
                                `${fileBaseUrl}/publicfile/${data.employee.photoId}/thumbnailphoto/${36}`
                              }
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Form.Item label="Date">{moment(data.task.timeIn).format('DD MMM YYYY')}</Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Form.Item label="Location">{data.task.locationName}</Form.Item>
                        </Col>
                      </Row>
                      <Row hidden={!data.task.projectName}>
                        <Col span={24}>
                          <Form.Item label="Project">{data.task.projectName}</Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Form.Item label="Notes">{data.task.notes || '-'}</Form.Item>
                        </Col>
                      </Row>
                      <Table rowKey="field" dataSource={requestData} columns={columns} pagination={false} />
                    </Form>
                  )}
                </div>
              </Col>
            </Row>
            {data && !fetching && (
              <Form className="my-time-log-task-token__footer">
                {completed ? (
                  <Row justify="center">
                    <Col>
                      <Space direction="vertical">
                        <div>You may close the tab or login to continue with Zealys.</div>
                        <Button type="primary" onClick={handleLogin}>
                          Login
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                ) : (
                  <>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          label="Enter your comment"
                          validateStatus={errors?.comment ? 'error' : ''}
                          help={errors?.comment}
                        >
                          <Input.TextArea
                            rows={2}
                            value={comment}
                            onChange={(event: ChangeEvent<HTMLTextAreaElement>) => setComment(event.target.value)}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row justify="center" className="button-group">
                      <Col>
                        <Space>
                          <Button
                            type="primary"
                            onClick={handleApprove}
                            loading={loading === 'approve'}
                            disabled={loading && loading !== 'approve'}
                          >
                            Approve
                          </Button>
                          <Button
                            onClick={handleReject}
                            loading={loading === 'reject'}
                            disabled={loading && loading !== 'reject'}
                          >
                            Reject
                          </Button>
                        </Space>
                      </Col>
                    </Row>
                  </>
                )}
              </Form>
            )}
          </div>
        </Card>
      )}
    </div>
  )
}
