import { SETTINGS_ROUTES } from '~/routes/routes'
import { WfCategory } from './types'

interface Config {
  documentTitle: string
  pageTitle: string
  routePath: string
  routeTabPath: string
}

export const config: { [category in WfCategory]: Config } = {
  lve_approval: {
    documentTitle: 'Leave Approval',
    pageTitle: 'Leave approval',
    routePath: SETTINGS_ROUTES.leaveApproval,
    routeTabPath: SETTINGS_ROUTES.leaveApprovalTab
  },
  cla_approval: {
    documentTitle: 'Claim Approval',
    pageTitle: 'Claim approval',
    routePath: SETTINGS_ROUTES.claimApproval,
    routeTabPath: SETTINGS_ROUTES.claimApprovalTab
  },
  att_approval: {
    documentTitle: 'Attendance Approval',
    pageTitle: 'Attendance approval',
    routePath: SETTINGS_ROUTES.attendanceApproval,
    routeTabPath: SETTINGS_ROUTES.attendanceApprovalTab
  }
}
