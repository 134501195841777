import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { setIr8sProcessing } from '../reducers'
import { apiProcessIr8s } from '../api/ytd.api'

export const processIr8s = (
  ytdSubmissionId: string,
  employeeTaxNos: string[] = [],
  employeeScope: 'existing' | 'all' = 'existing'
): ThunkResult<void> => {
  return async dispatch => {
    if (!ytdSubmissionId) return

    try {
      dispatch(setIr8sProcessing(true))
      const { status, errors, message, errorData } = await apiProcessIr8s(
        ytdSubmissionId,
        employeeScope,
        employeeTaxNos
      )
      if (status) {
        showSuccess('IR8S recalculated')
      } else {
        if (Object.keys(errors).length === 0) {
          showError(message, errorData)
        }
      }
    } finally {
      dispatch(setIr8sProcessing(false))
    }
  }
}
