import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { SysCriteriaFieldState, Screen } from '../types'

export const selectSysCriteriaFields = createSelector(
  (state: StoreState) => state.selection.sysCriteriaFields,
  (state: StoreState) => state.selection.sysCriteriaScreens,
  (criteriaFieldsState, criteriaScreensState) =>
    memoize((screenCode: Screen): (SysCriteriaFieldState | undefined)[] => {
      const criteriaCodes = Object.values(criteriaScreensState[screenCode]?.entities || {}).map(s => s?.criteriaCode)

      return Object.values(criteriaFieldsState[screenCode]?.entities || {})
        .filter(f => criteriaCodes.includes(f?.criteriaCode))
        .sort((a, b) => {
          const aSequence = a!.sequence
          const bSequence = b!.sequence
          return bSequence > aSequence ? -1 : 1
        })
    })
)
