import { Operation } from 'fast-json-patch'
import { EmSelectionViewState } from '~/features/employee'
import { Pagination, Search } from '~/types/common'
import { request, getBaseUrl, timeout, toQueryParams } from '~/utils'
import { PayRunState, IPayRunNew, PayRunSummaryState, IGetPayRuns, PayRunViewState } from '../types'

const baseUrl = getBaseUrl('/payroll-sg/payrun')

export const apiGetPayRunsView = (viewId: string, params: Pagination) =>
  request<PayRunViewState>('get', `${baseUrl}/view/${viewId}`, params)

export const apiGetPayRunsViewExcel = (viewId: string) =>
  request('get', `${baseUrl}/view/${viewId}/excel`, undefined, { responseType: 'blob', timeout })

export const apiGetPayRuns = (params?: IGetPayRuns) => request<PayRunState[]>('get', baseUrl, toQueryParams(params))

export const apiGetPayRun = (id: string) => request<PayRunState>('get', `${baseUrl}/${id}`)

export const apiGetPayRunSummaries = (ids: string[]) =>
  request<PayRunSummaryState[]>('post', `${baseUrl}/summary`, { ids })

export const apiGetPayRunEmSelections = (id: string, viewId: string, params: Pagination & Search) =>
  request<EmSelectionViewState>('get', `${baseUrl}/${id}/emselections/${viewId}`, params)

export const apiGetPayRunNewEmSelections = (
  id: string,
  viewId: string,
  params: Pagination & Search & { pastResignees: boolean }
) => request<EmSelectionViewState>('get', `${baseUrl}/${id}/emselections/new/${viewId}`, params)

export const apiAddPayRun = (payRun: IPayRunNew) =>
  request<{
    id: string
    description: string
    startDate: string
    endDate: string
    payDate: string
    status: string
  }>('post', `${baseUrl}/create`, payRun)

export const apiUpdatePayRun = (id: string, payRun: Operation[]) => request<void>('patch', `${baseUrl}/${id}`, payRun)

export const apiDeletePayRun = (id: string) => request<void>('delete', `${baseUrl}/${id}`)

export const apiProceedToPaymentPayRun = (payRunId: string, lockAll: boolean) =>
  request<void>('post', `${baseUrl}/to-payment`, { payRunId, lockAll })

export const apiCompletePayRun = (payRunId: string) => request<void>('post', `${baseUrl}/to-complete`, { payRunId })

export const apiRevertToPaymentPayRun = (payRunId: string) =>
  request<void>('post', `${baseUrl}/revert-to-payment`, { payRunId })
