import React, { CSSProperties, ChangeEvent, FC, memo, useCallback, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { Drawer, Form, Input, SecondaryText, Space, Tag, UploadFile } from '~/core-components'
import { Col, EmpKeyValues, Row, SysOptions, UploadFileSelectorAuth } from '~/components'
import { EmIdentityRequestState, IEmIdentityRequestApproval } from '../../../../types'
import { useSysOptions } from '~/features/employee/hooks'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors } from '~/types/store'
import { fetchEmpKeyvalues } from '~/features/master'
import { approveEmIdentityRequest, rejectEmIdentityRequest } from '~/features/employee/actions'
import { getBaseUrl } from '~/utils'
import { EmRequestApprovalBtn } from '../EmRequestApprovalBtn'

export interface EmIdentityRequestApprovalProps {
  data?: EmIdentityRequestState
  visible: boolean
  onClose: () => void
}

const PendingTag = memo(() => <Tag type="secondary">pending</Tag>)

const currentTextStyle: CSSProperties = { fontSize: 12 }

const EMPTY_FORM_DATA: IEmIdentityRequestApproval = {
  identityType: '',
  cpfType: '',
  fwlCode: '',
  notes: ''
}

const EMPTY_ATTACHMENT: UploadFile = {
  uid: '',
  name: '',
  size: 0,
  type: ''
}

const baseUrl = getBaseUrl('/employee')

export const EmIdentityRequestApprovalDrawer: FC<EmIdentityRequestApprovalProps> = ({ data, visible, onClose }) => {
  const [identityTypes] = useSysOptions('identity_type')
  const [formData, setFormData] = useState<IEmIdentityRequestApproval>(EMPTY_FORM_DATA)
  const [attachment, setAttachment] = useState<UploadFile>()
  const [errors, setErrors] = useState<Errors>()

  const isNew = data?.recordStatus === 'new_request'
  const isDiffIdentityType = !isNew && data?.identityType !== data?.identityTypeNew
  const isDiffIdentityNo = !isNew && data?.identityNo !== data?.identityNoNew
  const isDiffIssueDate = !isNew && data?.issueDate !== data?.issueDateNew
  const isDiffExpiryDate = !isNew && data?.expiryDate !== data?.expiryDateNew
  const isDiffCancelledDate = !isNew && data?.cancelledDate !== data?.cancelledDateNew
  const isDiffWorkPermitNo = !isNew && data?.workPermitNo !== data?.workPermitNoNew
  const isDiffSPassNo = !isNew && data?.sPassNo !== data?.sPassNoNew

  useEffect(() => {
    setErrors(undefined)
    dispatch(fetchEmpKeyvalues('fwlCode', 'fwl', null, { strategy: 'when-empty' }))
  }, [])

  useEffect(() => {
    if (data) {
      if (data.attachmentId) {
        setAttachment({
          uid: data.attachmentId,
          name: data.attachmentName || '',
          size: data.attachmentSize || 0,
          type: data.attachmentFormat || '',
          url: `${baseUrl}/employee/request/identity/${data.id}/downloadfile`
        })
      }

      setFormData({
        identityType: data.identityTypeNew,
        cpfType: data.cpfType,
        fwlCode: data.fwlCode,
        notes: data.notes
      })
    } else {
      setFormData({ ...EMPTY_FORM_DATA })
    }
  }, [data])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  const handleApproval = useCallback(
    async (action: 'approve' | 'reject') => {
      let result: ActionResult | undefined

      if (data) {
        if (action === 'approve') {
          result = await dispatch(approveEmIdentityRequest(data.employeeId, data.id, formData))
        } else if (action === 'reject') {
          result = await dispatch(rejectEmIdentityRequest(data.employeeId, data.id))
        }
      }

      if (result?.errors) {
        setErrors(result.errors)
      }

      if (!result?.errors) {
        typeof onClose === 'function' && onClose()
      }
    },
    [onClose, data, formData]
  )

  const handleCloseDrawer = useCallback(() => {
    setAttachment(EMPTY_ATTACHMENT)
    typeof onClose === 'function' && onClose()
  }, [onClose])

  const handleFileChange = useCallback(() => {}, [])

  if (!data) return null

  return (
    <Drawer
      open={visible}
      title={<Space>Identity request approval {isNew && <Tag type="secondary">new (pending)</Tag>}</Space>}
      onClose={() => handleCloseDrawer()}
      width={500}
      className="em-identity-request-approval-drawer"
      footer={<EmRequestApprovalBtn onApproval={handleApproval} />}
    >
      <Form id="form-em-identity-request-approval">
        <Row>
          <Col span={24}>
            <Form.Item label={<Space>Identity type {isDiffIdentityType && <PendingTag />}</Space>}>
              <div>{identityTypes[data.identityTypeNew]?.value || '-'}</div>
              {isDiffIdentityType && (
                <SecondaryText style={currentTextStyle}>
                  Current: {identityTypes[data?.identityType || '']?.value || '-'}
                </SecondaryText>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label={<Space>Identity no. {isDiffIdentityNo && <PendingTag />}</Space>}>
              <div>{data.identityNoNew.toUpperCase()}</div>
              {isDiffIdentityNo && (
                <SecondaryText style={currentTextStyle}>Current: {data.identityNo || '-'}</SecondaryText>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label={<Space>Issue date {isDiffIssueDate && <PendingTag />}</Space>}>
              <div>{data.issueDateNew ? moment(data.issueDateNew).format('DD MMM YYYY') : '-'}</div>
              {isDiffIssueDate && (
                <SecondaryText style={currentTextStyle}>
                  Current: {data.issueDate ? moment(data.issueDate).format('DD MMM YYYY') : '-'}
                </SecondaryText>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label={<Space>Expiry date {isDiffExpiryDate && <PendingTag />}</Space>}>
              <div>{data.expiryDateNew ? moment(data.expiryDateNew).format('DD MMM YYYY') : '-'}</div>
              {isDiffExpiryDate && (
                <SecondaryText style={currentTextStyle}>
                  Current: {data.expiryDate ? moment(data.expiryDate).format('DD MMM YYYY') : '-'}
                </SecondaryText>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label={<Space>Cancelled date {isDiffCancelledDate && <PendingTag />}</Space>}>
              <div>{data.cancelledDateNew ? moment(data.cancelledDateNew).format('DD MMM YYYY') : '-'}</div>
              {isDiffCancelledDate && (
                <SecondaryText style={currentTextStyle}>
                  Current: {data.cancelledDate ? moment(data.cancelledDate).format('DD MMM YYYY') : '-'}
                </SecondaryText>
              )}
            </Form.Item>
          </Col>
        </Row>
        {data.identityTypeNew === 'sg07' && (
          <Row>
            <Col span={24}>
              <Form.Item label={<Space>Work permit no. {isDiffWorkPermitNo && <PendingTag />}</Space>}>
                <div>{data.workPermitNoNew.toUpperCase() || '-'}</div>
                {isDiffWorkPermitNo && (
                  <SecondaryText style={currentTextStyle}>Current: {data.workPermitNo || '-'}</SecondaryText>
                )}
              </Form.Item>
            </Col>
          </Row>
        )}
        {data.identityTypeNew === 'sg06' && (
          <Row>
            <Col span={24}>
              <Form.Item label={<Space>S Pass no. {isDiffSPassNo && <PendingTag />}</Space>}>
                <div>{data.sPassNoNew.toUpperCase() || '-'}</div>
                {isDiffSPassNo && (
                  <SecondaryText style={currentTextStyle}>Current: {data.sPassNo || '-'}</SecondaryText>
                )}
              </Form.Item>
            </Col>
          </Row>
        )}
        {(data.identityTypeNew === 'sg01' || data.identityTypeNew === 'sg02') && (
          <Row>
            <Col span={24}>
              <Form.Item label="CPF type" validateStatus={errors?.cpfType ? 'error' : ''} help={errors?.cpfType}>
                <SysOptions
                  type="cpf_type"
                  value={formData.cpfType}
                  onChange={(cpfType: string) => handleFormDataChange({ cpfType })}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        {(data.identityTypeNew === 'sg06' || data.identityTypeNew === 'sg07') && (
          <Row>
            <Col span={24}>
              <Form.Item label="FWL category" validateStatus={errors?.fwlCode ? 'error' : ''} help={errors?.fwlCode}>
                <EmpKeyValues
                  id="fwl"
                  value={formData.fwlCode}
                  onChange={(fwlCode: string) => handleFormDataChange({ fwlCode })}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row>
          <Col span={24}>
            <Form.Item label="Notes">
              <Input.TextArea
                value={formData.notes}
                rows={4}
                onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                  handleFormDataChange({ notes: event.target.value })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        {attachment?.uid && (
          <Row>
            <Col span={24}>
              <Form.Item label="Attachment">
                <UploadFileSelectorAuth
                  multiple={false}
                  accept=".gif,.png,.jpeg,.jpg"
                  fileList={attachment ? [attachment] : []}
                  listType="picture-card"
                  disabled={true}
                  onChange={handleFileChange}
                  withCache={false}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    </Drawer>
  )
}
