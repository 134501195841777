import React, { CSSProperties, FC, ChangeEvent, useCallback, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { useSelector } from 'react-redux'
import useDeepCompareEffect from 'use-deep-compare-effect'
import {
  Checkbox,
  CheckboxChangeEvent,
  Form,
  Input,
  Radio,
  RadioChangeEvent,
  Select,
  Space,
  Tag
} from '~/core-components'
import { Col, EmpKeyValues, PaySgKeyValues, Row, SysOptions } from '~/components'
import { fetchCompanies } from '~/features/master'
import { selectPayItems } from '~/features/master'
import { SysOptionType, useSysOptions } from '~/features/employee'
import { fetchFormulas, selectFormulaById } from '~/features/formula'
import { useFocus } from '~/hooks/use-focus'
import { dispatch } from '~/stores/store'
import { Ir8aCategory, PayItemCategory, PayRateType, WageType } from '~/constants'
import { Errors, StoreState } from '~/types/store'
import { PayItemInfoState } from '../../../types'
import './PayItemSgInfoForm.less'

interface PayItemSgInfoFormProps {
  data: PayItemInfoFormData
  readOnly?: boolean
  errors?: Errors
  onChange: (data: PayItemInfoFormData) => void
}

export interface PayItemInfoFormData extends PayItemInfoState {
  payItemBasisId: string[]
}

export interface PayItemFormData extends PayItemInfoFormData {
  id: string
}

export const EMPTY_PAYITEM_SG_INFO_FORM_DATA: PayItemInfoFormData = {
  name: '',
  type: '',
  category: '',
  rateType: 'm',
  prorationType: '',
  rate: 1,
  formulaId: '',
  wageType: '',
  hasSdl: false,
  ir8aCategory: '',
  inactiveDate: '',
  isSys: false,
  notes: '',
  payItemBasisId: []
}

const checkboxStyle: CSSProperties = { marginLeft: 10 }
const statutorySpaceStyles: CSSProperties = { width: '100%' }
const statutoryDropdownStyles: CSSProperties = { marginLeft: 20 }
const rateGroupStyles: CSSProperties = { width: '100%' }
const rateRowStyles: CSSProperties = { marginTop: 7 }
const rateInputStyles: CSSProperties = { marginLeft: 20 }
const formulaInputStyles: CSSProperties = { width: '100%' }
const multiplyStyles: CSSProperties = { width: 30, textAlign: 'center' }
const hiddenStyles: CSSProperties = { display: 'none' }

export const PayItemSgInfoForm: FC<PayItemSgInfoFormProps> = ({
  data,
  readOnly = false,
  errors,
  onChange
}: PayItemSgInfoFormProps) => {
  const [formData, setFormData] = useState<PayItemInfoFormData>(EMPTY_PAYITEM_SG_INFO_FORM_DATA)
  const [isFormula, setIsFormula] = useState(false)
  const [hasWageType, setHasWageType] = useState<boolean>()
  const [hasIr8a, setHasIr8a] = useState<boolean>()
  const [formulaQueryParams, setFormulaQueryParams] = useState<any>()
  const [payRateTypes] = useSysOptions('pay_rate_type')
  const [focusRef] = useFocus(!readOnly)
  const formula = useSelector((state: StoreState) => selectFormulaById(state, formData.formulaId))
  const payItems = useSelector(selectPayItems)
  const [isInactive, setIsInactive] = useState(false)
  const isBasicPay = formData.category === PayItemCategory.basicPay

  useEffect(() => {
    dispatch(fetchCompanies({ strategy: 'when-empty' }))
    dispatch(fetchFormulas())
  }, [])

  useDeepCompareEffect(() => {
    if (data) {
      setFormData(data)
      setIsFormula(data.formulaId != null)
      setHasWageType(!!data.wageType && data.wageType !== WageType.nonCpf)
      setHasIr8a(!!data.ir8aCategory)
      setIsInactive(!!data.inactiveDate)
    }
  }, [data])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      if (updates.category && updates.category === PayItemCategory.basicPay) {
        updates.wageType = WageType.ordinary
        updates.hasSdl = true
        updates.ir8aCategory = Ir8aCategory.ir8aSal
        setHasWageType(true)
        setHasIr8a(true)
      }

      setFormData(formData => {
        const updated = { ...formData, ...updates }
        setFormulaQueryParams({ rateType: updated.rateType })
        typeof onChange === 'function' && onChange(updated)
        return updated
      })
    },
    [onChange]
  )

  return (
    <div className="payitem-info-form">
      <Row>
        <Col flex={1}>
          <Form.Item
            label={
              <>
                Payroll item name &nbsp;
                {formData.isSys && <Tag type="original">system</Tag>}
              </>
            }
            validateStatus={errors?.name ? 'error' : ''}
            help={errors?.name}
          >
            <Input
              ref={focusRef}
              value={formData.name}
              readOnly={readOnly}
              onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ name: event.target.value })}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="&nbsp;">
            <Checkbox
              checked={isInactive}
              onChange={(event: CheckboxChangeEvent) => {
                setIsInactive(event.target.checked)
                handleFormDataChange({ inactiveDate: event.target.checked ? moment().format('YYYY-MM-DD') : '' })
              }}
              readOnly={readOnly}
              style={checkboxStyle}
            >
              Inactive
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={12}>
          <Form.Item label="Type" validateStatus={errors?.type ? 'error' : ''} help={errors?.type}>
            <SysOptions
              type={`pay_item_type${readOnly ? '' : '_selectable'}` as SysOptionType}
              value={formData.type}
              readOnly={readOnly}
              onChange={(value: string) => handleFormDataChange({ type: value })}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Category" validateStatus={errors?.category ? 'error' : ''} help={errors?.category}>
            <SysOptions
              type={`pay_item_category${readOnly ? '' : '_selectable'}` as SysOptionType}
              value={formData.category}
              readOnly={readOnly}
              onChange={(value: string) => handleFormDataChange({ category: value })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item label="Rate type">
            <Radio.Group
              value={formData.rateType}
              readOnly={readOnly}
              onChange={(event: RadioChangeEvent) =>
                handleFormDataChange({
                  rateType: event.target.value,
                  ...(event.target.value === 'm' ? { formulaId: null } : { prorationType: 'x' })
                })
              }
            >
              {Object.keys(payRateTypes).map(key => (
                <Radio key={key} value={key}>
                  {payRateTypes[key]?.value}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row hidden={formData.rateType !== 'm'}>
        <Col span={12}>
          <Form.Item
            label="Proration type for incomplete month"
            validateStatus={errors?.prorationType ? 'error' : ''}
            help={errors?.prorationType}
          >
            <SysOptions
              type="proration_type"
              value={formData.prorationType}
              readOnly={readOnly}
              onChange={(value: string) => handleFormDataChange({ prorationType: value })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row hidden={formData.rateType !== 'm'}>
        <Col span={24}>
          <Form.Item label="Default rate" validateStatus={errors?.rate ? 'error' : ''} help={errors?.rate}>
            <Input.Number
              precision={4}
              value={formData.rate}
              readOnly={readOnly}
              onChange={(value: number | null) => handleFormDataChange({ rate: value })}
            ></Input.Number>
          </Form.Item>
        </Col>
      </Row>
      <Row hidden={formData.rateType === 'm'}>
        <Radio.Group
          value={isFormula}
          style={rateGroupStyles}
          readOnly={readOnly}
          onChange={(event: RadioChangeEvent) => {
            setIsFormula(event.target.value)
            if (!event.target.value) {
              handleFormDataChange({ formulaId: null })
            }
          }}
        >
          <Col span={24}>
            <Radio value={false}>Fixed rate</Radio>
            <Radio value={true}>Formula</Radio>
          </Col>
        </Radio.Group>
      </Row>
      <Row hidden={formData.rateType === 'm' || isFormula}>
        <Col span={24}>
          <Form.Item label="" style={rateRowStyles}>
            <Input.Number
              precision={4}
              value={formData.rate}
              readOnly={readOnly}
              style={rateInputStyles}
              onChange={(value: number | null) => handleFormDataChange({ rate: value })}
            ></Input.Number>
          </Form.Item>
        </Col>
      </Row>
      <Row hidden={formData.rateType === 'm' || !isFormula}>
        <Col span={24}>
          <Form.Item label="" style={rateRowStyles}>
            <Row>
              <Col>
                <Input.Number
                  precision={4}
                  value={formData.rate}
                  readOnly={readOnly}
                  style={rateInputStyles}
                  onChange={(value: number | null) => handleFormDataChange({ rate: value })}
                ></Input.Number>
              </Col>
              <Col style={multiplyStyles}>x</Col>
              <Col flex={1}>
                <PaySgKeyValues
                  id="formula"
                  queryParams={formulaQueryParams}
                  value={formData.formulaId}
                  readOnly={readOnly}
                  style={formulaInputStyles}
                  onChange={(value: string) => handleFormDataChange({ formulaId: value })}
                />
              </Col>
            </Row>
          </Form.Item>
        </Col>
      </Row>
      <Row hidden={formula?.rateType !== PayRateType.daily}>
        <Col span={24}>
          <Form.Item label="Select the payroll items to be included in Gross Rate of Pay (if applicable)">
            <Select
              mode="multiple"
              className="payitem-basis"
              readOnly={readOnly}
              value={formData.payItemBasisId}
              allowClear={false}
              optionFilterProp="title"
              onChange={(values: string[]) => {
                const existing = formData.payItemBasisId.filter(pib => values.includes(pib))
                const newOnes = values.filter(pib => !existing.includes(pib))
                handleFormDataChange({ payItemBasisId: [...existing, ...newOnes] })
              }}
            >
              {payItems.map(pi => (
                <Select.Option key={pi.id} value={pi.id} title={pi.name}>
                  {pi.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item label="Statutory settings">
            <Space direction="vertical" style={statutorySpaceStyles}>
              <div>
                <Checkbox
                  readOnly={isBasicPay ? true : readOnly}
                  checked={hasWageType}
                  onChange={(event: CheckboxChangeEvent) => {
                    setHasWageType(event.target.checked)
                    if (!event.target.checked) {
                      handleFormDataChange({ wageType: null })
                    }
                  }}
                >
                  CPF wage type
                </Checkbox>
                <SysOptions
                  type={`wage_type${readOnly ? '' : '_selectable'}` as SysOptionType}
                  value={formData.wageType}
                  readOnly={isBasicPay ? true : readOnly}
                  style={hasWageType ? statutoryDropdownStyles : hiddenStyles}
                  onChange={(value: string) => {
                    handleFormDataChange({ wageType: value })
                  }}
                />
              </div>
              <Checkbox
                readOnly={isBasicPay ? true : readOnly}
                checked={formData.hasSdl}
                onChange={(event: CheckboxChangeEvent) => handleFormDataChange({ hasSdl: event.target.checked })}
              >
                SDL
              </Checkbox>
              <div>
                <Checkbox
                  readOnly={isBasicPay ? true : readOnly}
                  checked={hasIr8a}
                  onChange={(event: CheckboxChangeEvent) => {
                    setHasIr8a(event.target.checked)
                    if (!event.target.checked) {
                      handleFormDataChange({ ir8aCategory: null })
                    }
                  }}
                >
                  Taxable (IR8A category)
                </Checkbox>
                <EmpKeyValues
                  id="ir8aCategory"
                  value={formData.ir8aCategory}
                  readOnly={isBasicPay ? true : readOnly}
                  style={hasIr8a || !!formData.ir8aCategory ? statutoryDropdownStyles : hiddenStyles}
                  onChange={(value: string) => {
                    handleFormDataChange({ ir8aCategory: value })
                  }}
                />
              </div>
            </Space>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item label="Notes">
            <Input
              value={formData.notes}
              readOnly={readOnly}
              onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ notes: event.target.value })}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  )
}
