import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiApproveLeavePendingApproval } from '../api/leave-pending-approval.api'
import { ILeavePendingApproval } from '../types'
import { refetchLeavePendingApprovalsView } from '../reducers'

export const approveLeavePendingApproval = (leavePendingApproval: ILeavePendingApproval): ThunkResult<void> => {
  return async dispatch => {
    const { status, result, errors, message, errorData } = await apiApproveLeavePendingApproval(leavePendingApproval)

    if (status) {
      dispatch(refetchLeavePendingApprovalsView())
      showSuccess('Approved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
