import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { SSLeaveRecordApprovalHistoryState } from '../../types'

export const selectMyLeaveRecordApprovalHistories = createSelector(
  (state: StoreState) => state.my.myLeaveRecordApprovalHistories,
  myLeaveRecordApprovalHistoriesState =>
    memoize((leaveRecordId: string) => {
      return Object.values(
        myLeaveRecordApprovalHistoriesState[leaveRecordId]?.entities || {}
      ) as SSLeaveRecordApprovalHistoryState[]
    })
)
