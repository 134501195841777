import { showError, showSuccess } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiAddCtPolicy } from '../api/ct-policy.api'
import { setCtPolicy } from '../reducers'
import { CtPolicyState } from '../types'
import { fetchCtPolicyEt } from './fetch-ct-policy-et'

export const addCtPolicy = (claimTypeId: string, ctPolicy: CtPolicyState): ThunkResult<void> => {
  return async dispatch => {
    if (!claimTypeId) return

    const { status, result, errors, message, errorData } = await apiAddCtPolicy(claimTypeId, ctPolicy)
    if (status) {
      if (claimTypeId && result.id) {
        dispatch(fetchCtPolicyEt(claimTypeId, result.id))
      }

      dispatch(setCtPolicy({ claimTypeId, data: { ...ctPolicy, id: result.id, viewId: result.viewId } }))
      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
