import React, { CSSProperties, FC } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Space, Spin } from '~/core-components'
import { Centered } from '~/components'
import { ReactComponent as ZealysLogo } from '~/svgs/logo/logo-primary-white.svg'

const spinStyles: CSSProperties = { marginRight: 10 }
const centerStyles: CSSProperties = { textAlign: 'center' }

interface ZealysLoadingProps {
  text?: string
}

export const ZealysLoading: FC<ZealysLoadingProps> = ({ text }) => {
  return (
    <Centered style={centerStyles}>
      <ZealysLogo />
      <br />
      <Space>
        <Spin style={spinStyles} indicator={<LoadingOutlined spin />} />
        {text}
      </Space>
    </Centered>
  )
}
