import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { PayRecordSgState } from '../types'

export const selectPayRecords = createSelector(
  (state: StoreState) => state.payroll.payRecords,
  payRecordsState =>
    memoize((payRunId: string): PayRecordSgState[] => {
      const payRecords = (Object.values(payRecordsState[payRunId]?.entities || {}) as PayRecordSgState[]).sort(
        (a, b) => {
          const aName = a.employeeName
          const bName = b.employeeName
          return aName?.localeCompare(bName)
        }
      )
      return payRecords
    })
)
