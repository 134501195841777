import { replaceKeyValues, setKeyValuesLoading } from '../reducers'
import { apiGetKeyValues } from '../api/key-values.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

/*
fetch key value pairs response from /job/<id>/keyValues/
*/
export const fetchKeyValues = (
  id: string,
  controller?: string,
  queryParams?: any,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const loading = getState().job.keyValuesLoading[id]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().job.keyValues[id]?.ids?.length > 0
      const isSameParams = getState().job.keyValuesParams[id] === JSON.stringify(queryParams)
      if (hasData && isSameParams) return
    }

    try {
      dispatch(setKeyValuesLoading({ kv: id, loading: true }))
      const { result, status } = await apiGetKeyValues('job', controller || id, queryParams)
      if (status) {
        dispatch(replaceKeyValues({ kv: id, data: result, params: JSON.stringify(queryParams) }))
      }
    } finally {
      dispatch(setKeyValuesLoading({ kv: id, loading: false }))
    }
  }
}
