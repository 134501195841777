import { request, getBaseUrl } from '~/utils/request'
import { IPayTranEntry, PayTranEntryState } from '../types'

const baseUrl = getBaseUrl('/payroll-sg/paytranentry')

export const apiGetPayTranEntryByBatchId = (batchId: string) =>
  request<PayTranEntryState[]>('get', `${baseUrl}/batch/${batchId}`)

export const apiGetPayTranEntryAdjustment = (payRunId: string, employeeId: string, payItemId: string) =>
  request<PayTranEntryState>('get', `${baseUrl}/${payRunId}/${employeeId}/${payItemId}/adjustment`)

export const apiAddPayTranEntryBatch = (payRunId: string, employeeId: string, entries: IPayTranEntry[]) =>
  request<void>('post', `${baseUrl}/${payRunId}/${employeeId}`, entries)

export const apiUpdatePayTranEntryBatch = (
  payRunId: string,
  employeeId: string,
  batchId: string,
  entries: IPayTranEntry[]
) => request<{ batchId: string }>('patch', `${baseUrl}/${payRunId}/${employeeId}/${batchId}`, entries)

export const apiDeletePayTranEntryBatch = (payRunId: string, employeeId: string, batchId: string) =>
  request<void>('delete', `${baseUrl}/${payRunId}/${employeeId}/${batchId}`)
