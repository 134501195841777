import { ActionResult, ThunkResult } from '~/types/store'
import { emptyGuid } from '~/constants'
import { showSuccess, showError } from '~/utils'
import { apiAddEmShiftRole } from '../api/em-shift-role.api'
import { setEmShiftRole, setEmployee } from '../reducers'
import { IEmShiftRoleInfo, EmShiftRoleState } from '../types'

export const addEmShiftRole = (employeeId: string, emShiftRole: IEmShiftRoleInfo): ThunkResult<void> => {
  return async (dispatch, getState): Promise<ActionResult | undefined> => {
    if (!employeeId) return

    const { status, result, errors, message, errorData } = await apiAddEmShiftRole(employeeId, {
      id: emptyGuid,
      employeeId,
      ...emShiftRole,
      shiftRoleId: emShiftRole.shiftRoleId || emptyGuid
    })
    if (status) {
      if (result.emShiftRoleId) {
        const employee = getState().employee.employees.entities[employeeId]
        if (employee) {
          dispatch(setEmployee({ ...employee, emShiftRoleId: result.emShiftRoleId }))
        }
      }

      const newEmShiftRole = {
        id: result.id,
        employeeId,
        ...emShiftRole
      } as EmShiftRoleState
      dispatch(setEmShiftRole({ employeeId, data: newEmShiftRole }))

      showSuccess('Added')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
