import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchMyClaimRecordApprovalHistories } from '../actions'
import { selectMyClaimRecordApprovalHistories } from '../selectors'
import { SSClaimRecordApprovalHistoryState } from '../types'

export const useMyClaimRecordApprovalHistories = (
  claimRecordId?: string
): [SSClaimRecordApprovalHistoryState[], boolean] => {
  const claimRecordApprovalHistories = useSelector(selectMyClaimRecordApprovalHistories)(
    claimRecordId || ''
  ) as SSClaimRecordApprovalHistoryState[]
  const loading = useSelector(
    (state: StoreState) => state.myClaim.myClaimRecordApprovalHistoriesLoading[claimRecordId || '']
  )

  useEffect(() => {
    if (claimRecordId) {
      dispatch(fetchMyClaimRecordApprovalHistories(claimRecordId))
    }
  }, [claimRecordId])

  return [claimRecordApprovalHistories, loading]
}
