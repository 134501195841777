import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Col, Row } from '~/components'
import { Card, Link, List, Tag } from '~/core-components'
import { usePermissionGate } from '~/features/iam'
import { fetchEmpKeyvalues } from '~/features/master'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { LveCitizen } from '~/constants'
import { Permission, PermissionAction, LveSysLeaveType } from '~/constants'
import { fetchLtPolicies, fetchLtPoliciesExtra } from '../../../actions'
import { LtPolicyExt, selectLtPoliciesById } from '../../../selectors'
import { LeaveTypeState, LpLeaveGroupState } from '../../../types'
import { MutateLtPolicyDrawer } from './MutateLtPolicyDrawer'

interface LeaveTypePolicyProps {
  leaveType?: LeaveTypeState
}

interface DrawerState {
  visible: boolean
  data?: LtPolicyExt
  leaveGroups?: LpLeaveGroupState[]
}

const DEFAULT_MODAL_STATE: DrawerState = { visible: false }

const citizenMap: { [key: string]: string } = {
  [LveCitizen.citizen]: 'Citizen',
  [LveCitizen.nonCitizen]: 'Non citizen'
}

export const LeaveTypePolicy: FC<LeaveTypePolicyProps> = ({ leaveType }) => {
  const leaveTypeId = leaveType?.id || ''
  const ltPolicies = useSelector(selectLtPoliciesById)(leaveTypeId)
  const loading = useSelector((state: StoreState) => state.leave.ltPoliciesLoading[leaveTypeId])
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_MODAL_STATE)
  const canModify = usePermissionGate(Permission.lveType, PermissionAction.Modify)

  useEffect(() => {
    dispatch(fetchEmpKeyvalues('leaveGroup', undefined, undefined, { strategy: 'when-empty' }))
  }, [])

  useEffect(() => {
    dispatch(fetchLtPolicies(leaveTypeId))
    dispatch(fetchLtPoliciesExtra(leaveTypeId))
  }, [leaveTypeId])

  const handleEditPolicy = useCallback((data: LtPolicyExt) => {
    setDrawerState({ visible: true, data })
  }, [])

  const handleAddPolicy = useCallback(() => {
    setDrawerState({ visible: true })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_MODAL_STATE)
  }, [])

  return (
    <Card
      title="Policy"
      bodyStyle={{ paddingBottom: leaveType && !loading ? 6 : 24, paddingTop: 6 }}
      extra={canModify && !loading && <Link onClick={handleAddPolicy}>add</Link>}
      loading={loading}
    >
      <List
        dataSource={ltPolicies}
        renderItem={(item: LtPolicyExt) => (
          <List.Item key={item.id}>
            <Row>
              <Col flex="auto">
                {item.leaveGroupDisplay} {citizenMap[item.citizen] && <Tag>{citizenMap[item.citizen]}</Tag>}
              </Col>
              <Col flex="250px">
                {leaveType?.sysLeaveTypeCode === LveSysLeaveType.com ? item.entPerTimeDisplay : item.entitlementDisplay}
              </Col>
              {canModify && (
                <Col flex="none">
                  <Link size="small" onClick={() => handleEditPolicy(item)}>
                    edit
                  </Link>
                </Col>
              )}
            </Row>
            <Row>
              <Col span={24}>{item.confirmDisplay}</Col>
            </Row>
          </List.Item>
        )}
      />
      {canModify && <MutateLtPolicyDrawer {...drawerState} leaveType={leaveType} onClose={handleCloseDrawer} />}
    </Card>
  )
}
