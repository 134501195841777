import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { LeaveRecordViewState } from '../types'

export const selectLeaveRecordsView = createSelector(
  (state: StoreState) => state.leave.leaveRecordsView,
  leaveRecordsState =>
    memoize((viewId: string): LeaveRecordViewState => {
      const leaveRecords = leaveRecordsState.entities[viewId] || ({} as LeaveRecordViewState)
      return leaveRecords
    })
)
