import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchMyClaimRecord } from '../actions'
import { SSClaimRecordState } from '../types'

export const useMyClaimRecord = (id?: string): [SSClaimRecordState | undefined, boolean] => {
  const claimRecord = useSelector((state: StoreState) => state.myClaim.myClaimRecords?.entities[id || ''])
  const loading = useSelector((state: StoreState) => state.myClaim.myClaimRecordsLoading)

  useEffect(() => {
    if (id) {
      dispatch(fetchMyClaimRecord(id))
    }
  }, [id])

  return [claimRecord, loading]
}
