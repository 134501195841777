import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { IAdjustClaimEntitlement } from '../types'
import { apiUpdateClaimEntitlement } from '../api/claim-entitlement.api'

export const updateClaimEntitlement = (
  id: string,
  before: IAdjustClaimEntitlement,
  request: IAdjustClaimEntitlement
): ThunkResult<void> => {
  return async () => {
    if (!id) return

    const after = { ...before, ...request } as IAdjustClaimEntitlement
    const patch = generateJsonPatch<IAdjustClaimEntitlement>(before, after)
    const { status, errors, message, errorData } = await apiUpdateClaimEntitlement(id, patch)

    if (status) {
      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
