import React, { FC, useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Checkbox, CheckboxChangeEvent, Form, Space, Tag, UploadFile } from '~/core-components'
import { Col, ErrorDisplay, Row, SysOptions, UploadFileSelectorAuth } from '~/components'
import { Errors } from '~/types/store'
import { ImportEmployeeExcelFormData } from '../ImportEmployeeExcelDrawer'
import { useIsMountedRef } from '~/hooks/use-is-mounted-ref'
import { apiGetImportEmployeeTemplateExcel } from '~/features/employee/api/employee-import.api'
import { downloadWithDom, showError } from '~/utils'
import { HELP_ROUTES } from '~/routes/routes'
import { EmployeeImportCode } from '~/constants'
import './UploadEmployeeExcel.less'

interface UploadEmployeeExcelProps {
  visible: boolean
  data: Partial<ImportEmployeeExcelFormData>
  errors?: Errors
  onChange: (data: Partial<ImportEmployeeExcelFormData>) => void
}

const ACCEPT = '.xls,.xlsx,.csv'

export const UploadEmployeeExcel: FC<UploadEmployeeExcelProps> = ({ visible, data, errors, onChange }) => {
  const isMountedRef = useIsMountedRef()
  const [templateDownloading, setTemplateDownloading] = useState(false)

  const handleFileChange = useCallback(
    (files?: UploadFile[]) => {
      if (files && files.length > 0) {
        typeof onChange === 'function' && onChange({ file: files[0] })
      }
    },
    [onChange]
  )

  const handleFileRemove = useCallback(() => {
    typeof onChange === 'function' && onChange({ file: undefined })
  }, [onChange])

  const handleDownloadTemplate = useCallback(async () => {
    try {
      setTemplateDownloading(true)
      const { status, result, errors, message, errorData } = await apiGetImportEmployeeTemplateExcel({
        importCode: data.importCode || EmployeeImportCode.employee
      })

      if (status) {
        const fileName = `upload_${data.importCode}_template.xlsx`
        downloadWithDom(result, fileName)
      } else {
        console.error('Error while downloading', errors)
        showError(message, errorData)
      }
    } finally {
      if (isMountedRef.current) setTemplateDownloading(false)
    }
  }, [data.importCode, isMountedRef])

  return (
    <div className="upload-employee-excel" hidden={!visible}>
      <Row>
        <Col>
          <div className="upload-employee-excel__info">
            Please note the following before proceeding:
            <ul>
              <li>Set up the relevant information in people references. Settings {'>'} People References </li>
              <li>Fill up all the highlighted columns in the template</li>
              <li>
                Refer to the{' '}
                <Link to={HELP_ROUTES.page.replace(':page', 'em-import')} target="_blank" rel="noopener noreferrer">
                  step by step guideline
                </Link>{' '}
                here
              </li>
            </ul>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item className="entry-type" label="Select import type">
            <Space>
              <SysOptions
                style={{ width: 250 }}
                type="em_import_code"
                allowClear={false}
                value={data.importCode}
                onChange={(importCode: string) => typeof onChange === 'function' && onChange({ importCode })}
              />
              <Button onClick={handleDownloadTemplate} loading={templateDownloading}>
                Download template
              </Button>
            </Space>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="upload-pay-excel__legends">
          <div>
            Supported file type:{' '}
            <Space>
              <Tag>.xls</Tag>
              <Tag>.xlsx</Tag>
              <Tag>.csv</Tag>
            </Space>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item label="File" validateStatus={errors?.file ? 'error' : ''} help={errors?.file}>
            <UploadFileSelectorAuth
              multiple={false}
              accept={ACCEPT}
              fileList={data.file ? [data.file] : []}
              onChange={handleFileChange}
              onRemove={handleFileRemove}
            >
              <Button size="small">choose file</Button>
            </UploadFileSelectorAuth>
          </Form.Item>
        </Col>
      </Row>
      <Row hidden={data.importCode !== EmployeeImportCode.flat}>
        <Col span={12}>
          <Form.Item label="">
            <Space direction="vertical">
              <Checkbox
                checked={data.isAutoCreateReference}
                onChange={(event: CheckboxChangeEvent) =>
                  typeof onChange === 'function' && onChange({ isAutoCreateReference: event.target.checked })
                }
              >
                Auto-create missing references
              </Checkbox>
              <SysOptions
                style={{ width: 400, marginLeft: 20 }}
                type="em_import_auto_create_reference"
                mode="multiple"
                placeholder="All references"
                value={data.references}
                onChange={(references: string[]) => typeof onChange === 'function' && onChange({ references })}
              />
            </Space>
          </Form.Item>
        </Col>
      </Row>
      <ErrorDisplay errors={errors} />
    </div>
  )
}
