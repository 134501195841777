import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Descriptions } from '~/core-components'
import { Col, Person, Row } from '~/components'
import { fetchEmpKeyvalues, selectKeyvaluesById, selectMasterById } from '~/features/master'
import { ICopyEmployee, selectCurrentEmJob } from '~/features/employee'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { getBaseUrl } from '~/utils'
import './RehireDisplay.less'

interface RehireDisplayProps {
  data?: ICopyEmployee
}

const baseUrl = getBaseUrl('/filestore')

export const RehireDisplay: FC<RehireDisplayProps> = ({ data }) => {
  const companies = useSelector(selectKeyvaluesById)('company')
  const payGroups = useSelector(selectKeyvaluesById)('payGroup')
  const leaveGroups = useSelector(selectKeyvaluesById)('leaveGroup')
  const employee = useSelector((state: StoreState) => state.employee.employees.entities[data?.employeeId || ''])
  const emJob = useSelector(selectCurrentEmJob)(employee?.id, employee?.emJobId)
  const job = useSelector(selectMasterById)('job', emJob?.jobId || '')

  useEffect(() => {
    dispatch(fetchEmpKeyvalues('company', undefined, undefined, { strategy: 'when-empty' }))
    dispatch(fetchEmpKeyvalues('payGroup', undefined, undefined, { strategy: 'when-empty' }))
    dispatch(fetchEmpKeyvalues('leaveGroup', undefined, undefined, { strategy: 'when-empty' }))
  }, [])

  return (
    <div className="rehire-display">
      <Row>
        <Col span={24}>
          <Person
            name={employee?.fullName || ''}
            description={job?.name || ''}
            photo={employee?.photoId && `${baseUrl}/file/${employee?.photoId}/thumbnailphoto/36`}
            // photo={employee?.id && `/employee/employee/${employee?.id}/avatar/36?photo_id=${employee?.photoId}`}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Descriptions bordered column={1}>
            <Descriptions.Item label="New employee no.">{data?.employeeNo}</Descriptions.Item>
            <Descriptions.Item label="Hire date">
              {data?.hireDate ? moment(data.hireDate).format('DD MMM YYYY') : ''}
            </Descriptions.Item>
            <Descriptions.Item label="Company">{companies[data?.companyId || '']?.value}</Descriptions.Item>
            <Descriptions.Item label="Payroll group">{payGroups[data?.payGroupId || '']?.value}</Descriptions.Item>
            <Descriptions.Item label="Leave group">{leaveGroups[data?.leaveGroupId || '']?.value}</Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    </div>
  )
}
