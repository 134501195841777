import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'

export const selectCurrentEmDepartment = createSelector(
  (state: StoreState) => state.employee.emDepartments,
  emDepartmentsState =>
    memoize(
      (employeeId?: string, id?: string) => {
        if (!employeeId || !id) return undefined

        return emDepartmentsState[employeeId]?.entities[id]
      },
      (employeeId?: string, id?: string) => `${employeeId}|${id}`
    )
)

export const selectEmDepartments = createSelector(
  (state: StoreState) => state.employee.emDepartments,
  emDepartmentsState =>
    memoize((employeeId?: string) => {
      if (!employeeId) return []

      return Object.values(emDepartmentsState[employeeId]?.entities || {}).sort((a, b) => {
        const aStartDate = new Date(a!.startDate).getTime()
        const bStartDate = new Date(b!.startDate).getTime()
        return bStartDate - aStartDate
      })
    })
)

interface EmDepartmentMaster {
  id: string
  startDate: string
  departmentId: string
  departmentName?: string
}

export const selectEmDepartmentMaster = createSelector(
  (state: StoreState) => state.master.masters['department'],
  (state: StoreState) => state.employee.employees,
  (state: StoreState) => state.employee.emDepartments,
  (departmentsState, employeesState, emDepartmentsState) =>
    memoize((employeeId: string): EmDepartmentMaster => {
      const employee = employeesState.entities[employeeId]
      if (employee) {
        const emDepartment = emDepartmentsState[employeeId]?.entities[employee.emDepartmentId]
        if (emDepartment?.departmentId) {
          const { id, startDate, departmentId } = emDepartment
          return { id, startDate, departmentId, departmentName: departmentsState?.entities[departmentId]?.name }
        }
      }
      return { id: '', startDate: '', departmentId: '', departmentName: '' }
    })
)
