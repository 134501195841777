import { replaceEmTeams, setEmTeamsLoading } from '../reducers'
import { apiGetEmTeams } from '../api/em-team.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchEmTeams = (employeeId: string, options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!employeeId) return

    const loading = getState().employee.emTeamsLoading[employeeId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().employee.emTeams[employeeId]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setEmTeamsLoading({ employeeId, loading: true }))
      const { result, status } = await apiGetEmTeams(employeeId)
      if (status) {
        dispatch(replaceEmTeams({ employeeId, data: result }))
      }
    } finally {
      dispatch(setEmTeamsLoading({ employeeId, loading: false }))
    }
  }
}
