import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment-timezone'
import { RangeValue } from 'rc-picker/lib/interface.d'
import { Checkbox, CheckboxChangeEvent, Form, Input, Link, Radio, RadioChangeEvent } from '~/core-components'
import { Col, DrawerForm, ErrorDisplay, Row } from '~/components'
import { LocationKeyValues, ShiftRoleKeyValues, ShiftWorkStatusKeyValues } from '~/features/attendance'
import { bulkAddScheduleRecords } from '../../../actions'
import { IBulkAddScheduleRecord } from '../../../types'
import { NonWorkingStatus, WorkStatusType } from '~/constants'
import { useFocus } from '~/hooks'
import { ActionResult, Errors } from '~/types/store'
import { dispatch } from '~/stores/store'
import { BulkAddScheduleEmRadio, BulkAddScheduleEmRadioChoice } from './BulkAddScheduleEmRadio'
import './BulkAddScheduleRecordDrawer.less'

export interface BulkAddScheduleRecordDrawerProps {
  visible: boolean
  startDate?: string
  employeeIds?: string[]
  onClose: () => void
}

interface FormData extends IBulkAddScheduleRecord {}

const EMPTY_FORM_DATA: FormData = {
  startDate: '',
  endDate: '',
  schedules: [],
  employeeIds: [],
  defaultLocation: true,
  defaultShiftRole: true,
  overwrite: true
}

interface ISchedule {
  shiftId: string
  locationId: string
  shiftRoleId: string
}

export const BulkAddScheduleRecordDrawer: FC<BulkAddScheduleRecordDrawerProps> = ({
  visible,
  startDate = '',
  employeeIds = [],
  onClose
}) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)
  const [schedules, setSchedules] = useState<Record<string, ISchedule>>({})
  const [focusRef, setFocus] = useFocus(true)
  const [errors, setErrors] = useState<Errors>()
  const [weeks, setWeeks] = useState(1)
  const [emRadioChoice, setEmRadioChoice] = useState<BulkAddScheduleEmRadioChoice>('one')

  const endDate = useMemo(() => {
    return moment(startDate).add(weeks, 'week').add(-1, 'day').format('YYYY-MM-DD')
  }, [startDate, weeks])

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
    setFormData(EMPTY_FORM_DATA)
    setSchedules({})
    setWeeks(1)
  }, [visible, setFocus])

  useEffect(() => {
    if (visible) {
      setFormData(formData => ({ ...formData, startDate, endDate, employeeIds }))
    }
  }, [visible, startDate, endDate, employeeIds])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  const handleOk = useCallback(async () => {
    if ((emRadioChoice === 'one' || emRadioChoice === 'some') && formData.employeeIds.length === 0) {
      setErrors({ '*': ['Please select employee'] })
      return
    }

    let result: ActionResult | undefined
    setLoading(true)
    try {
      formData.schedules = []
      let weekNo = 0
      for (let m = moment(formData.startDate); m.isSameOrBefore(formData.endDate); m.add(1, 'day')) {
        const dayNo = parseInt(m.format('E')) - 1
        const schedule = schedules[`w${weekNo}d${dayNo}`]
        const isWorking = Boolean(schedule?.shiftId) && !NonWorkingStatus.includes(schedule.shiftId)
        const workStatusType = isWorking ? WorkStatusType.WorkingDay : schedule?.shiftId

        formData.schedules.push({
          date: m.format('YYYY-MM-DD'),
          shiftId: isWorking ? schedule?.shiftId : undefined,
          workStatusType,
          locationId: formData.defaultLocation ? undefined : schedule?.locationId,
          shiftRoleId: formData.defaultShiftRole ? undefined : schedule?.shiftRoleId
        })

        if (dayNo === 6) {
          if (weekNo < weeks - 1) {
            weekNo++
          } else {
            weekNo = 0
          }
        }
      }

      result = await dispatch(bulkAddScheduleRecords(formData))
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
    }
  }, [formData, schedules, weeks, emRadioChoice, onClose])

  const handleLocationChange = useCallback(
    (event: RadioChangeEvent) => {
      handleFormDataChange({ defaultLocation: event.target.value })
    },
    [handleFormDataChange]
  )

  const handleShiftRoleChange = useCallback(
    (event: RadioChangeEvent) => {
      handleFormDataChange({ defaultShiftRole: event.target.value })
    },
    [handleFormDataChange]
  )

  const handleSelectEmployees = useCallback(
    (choice: BulkAddScheduleEmRadioChoice, employeeIds: string[]) => {
      setEmRadioChoice(choice)
      handleFormDataChange({ employeeIds })
    },
    [handleFormDataChange]
  )

  const handleRemoveWeek = useCallback(
    (weekIndex: number) => {
      setSchedules(schedules => {
        for (let week = weekIndex; week < weeks; week++) {
          for (let day = 0; day <= 6; day++) {
            schedules[`w${week}d${day}`] = schedules[`w${week + 1}d${day}`]
          }
        }

        for (let day = 0; day <= 6; day++) {
          delete schedules[`w${weeks - 1}d${day}`]
        }

        setWeeks(weeks => weeks - 1)

        return schedules
      })
    },
    [weeks]
  )

  return (
    <DrawerForm
      open={visible}
      title="Plan schedule"
      okText="Add shift"
      onClose={onClose}
      confirmLoading={loading}
      width={1030}
      formId="form-bulk-add-schedule-record"
      className="bulk-add-schedule-record-drawer"
      extras={
        <Checkbox
          checked={formData.overwrite}
          onChange={(event: CheckboxChangeEvent) => handleFormDataChange({ overwrite: event.target.checked })}
        >
          Overwrite existing shift
        </Checkbox>
      }
    >
      <Form id="form-bulk-add-schedule-record" layout="horizontal" onFinish={handleOk}>
        <Row className="weekday">
          <Col span={24}>
            <Row gutter={[15, 5]}>
              <Col flex="80px"></Col>
              {Array.from({ length: 7 }, (_, index) => (
                <Col key={index} flex="115px" className="weekday__name">
                  {moment()
                    .day(index + 1)
                    .format('ddd')}
                </Col>
              ))}
            </Row>
          </Col>
          <Col span={24}>
            {Array.from({ length: weeks }, (_, weekIdx) => (
              <div key={weekIdx} className="weekday__inputs">
                <Row gutter={[15, 5]} align="middle">
                  <Col flex="80px" className="weekday__label">
                    Week {weekIdx + 1}
                  </Col>
                  {Array.from({ length: 7 }, (_, dayIdx) => (
                    <Col key={dayIdx} flex="115px">
                      <ShiftWorkStatusKeyValues
                        ref={weekIdx === 0 && dayIdx === 0 ? focusRef : undefined}
                        dropdownMatchSelectWidth={false}
                        value={schedules[`w${weekIdx}d${dayIdx}`]?.shiftId}
                        onChange={(shiftId: string) =>
                          setSchedules(schedules => ({
                            ...schedules,
                            [`w${weekIdx}d${dayIdx}`]: {
                              ...schedules[`w${weekIdx}d${dayIdx}`],
                              shiftId
                            }
                          }))
                        }
                      />
                    </Col>
                  ))}
                  <Col flex="80px">{weeks > 1 && <Link onClick={() => handleRemoveWeek(weekIdx)}>remove</Link>}</Col>
                </Row>
                {!formData.defaultLocation && (
                  <Row gutter={[15, 5]} align="middle">
                    <Col flex="80px" className="weekday__label">
                      <i className="fal fa-location-dot" />
                    </Col>
                    {Array.from({ length: 7 }, (_, dayIdx) => (
                      <Col key={dayIdx} flex="115px">
                        {!NonWorkingStatus.includes(schedules[`w${weekIdx}d${dayIdx}`]?.shiftId) && (
                          <LocationKeyValues
                            value={schedules[`w${weekIdx}d${dayIdx}`]?.locationId}
                            onChange={(locationId: string) =>
                              setSchedules(schedules => ({
                                ...schedules,
                                [`w${weekIdx}d${dayIdx}`]: {
                                  ...schedules[`w${weekIdx}d${dayIdx}`],
                                  locationId
                                }
                              }))
                            }
                          />
                        )}
                      </Col>
                    ))}
                  </Row>
                )}
                {!formData.defaultShiftRole && (
                  <Row gutter={[15, 5]} align="middle">
                    <Col flex="80px" className="weekday__label">
                      <i className="fal fa-briefcase" />
                    </Col>
                    {Array.from({ length: 7 }, (_, dayIdx) => (
                      <Col key={dayIdx} flex="115px">
                        {!NonWorkingStatus.includes(schedules[`w${weekIdx}d${dayIdx}`]?.shiftId) && (
                          <ShiftRoleKeyValues
                            value={schedules[`w${weekIdx}d${dayIdx}`]?.shiftRoleId}
                            onChange={(shiftRoleId: string) =>
                              setSchedules(schedules => ({
                                ...schedules,
                                [`w${weekIdx}d${dayIdx}`]: {
                                  ...schedules[`w${weekIdx}d${dayIdx}`],
                                  shiftRoleId
                                }
                              }))
                            }
                          />
                        )}
                      </Col>
                    ))}
                  </Row>
                )}
              </div>
            ))}
          </Col>
          <Col span={24}>
            <Row>
              <Col flex="80px" className="weekday__label" />
              <Col>
                <Link onClick={() => setWeeks(w => w + 1)}>add week</Link>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Location">
              <Radio.Group value={formData.defaultLocation} onChange={handleLocationChange}>
                <Radio value={true}>Employee's default location</Radio>
                <Radio value={false}>Specific location</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Shift role">
              <Radio.Group value={formData.defaultShiftRole} onChange={handleShiftRoleChange}>
                <Radio value={true}>Employee's default shift role</Radio>
                <Radio value={false}>Specific shift role</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Apply to">
              <Input.DateRange
                allowClear={false}
                value={[
                  formData.startDate ? moment(formData.startDate) : null,
                  formData.endDate ? moment(formData.endDate) : null
                ]}
                onCalendarChange={(dates: RangeValue<moment.Moment>) => {
                  const startDate = dates && dates[0] ? dates[0].format('YYYY-MM-DD') : null
                  const endDate = dates && dates[1] ? dates[1].format('YYYY-MM-DD') : null
                  handleFormDataChange({ startDate, endDate })
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Employees">
              <BulkAddScheduleEmRadio
                startDate={formData.startDate}
                endDate={formData.endDate}
                employeeIds={formData.employeeIds}
                onChange={handleSelectEmployees}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <ErrorDisplay errors={errors} />
    </DrawerForm>
  )
}
