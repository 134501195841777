import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { FetchStrategy } from '~/types/common'
import { fetchShiftRole } from '../actions'
import { ShiftRoleState } from '../types'

export const useShiftRole = (
  id: string,
  fetchStrategy: FetchStrategy = 'always'
): [ShiftRoleState | undefined, boolean] => {
  const data = useSelector((state: StoreState) => state.attendance.shiftRoles?.entities[id])
  const loading = useSelector((state: StoreState) => state.attendance.shiftRolesLoading)

  useEffect(() => {
    dispatch(fetchShiftRole(id, { strategy: fetchStrategy }))
  }, [id, fetchStrategy])

  return [data, loading]
}
