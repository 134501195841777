import React, { ChangeEvent, FC, useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Form, Input } from '~/core-components'
import { Col, EmpKeyValues, EmSelect, ErrorDisplay, NewTabLinkIcon, Row } from '~/components'
import { apiGetEmSelect } from '~/features/employee/api/employee.api'
import { selectMyTenants, usePermissionGate } from '~/features/iam'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { FeatureFlag, Permission, PermissionAction } from '~/constants'
import { Errors, StoreState } from '~/types/store'
import { useFocus } from '~/hooks/use-focus'
import { ICopyEmployee } from '../../../../../types'
import './TransferEntry.less'

interface TransferEntryProps {
  visible: boolean
  data: Partial<ICopyEmployee>
  errors?: Errors
  onChange: (data: Partial<ICopyEmployee>) => void
}

const activeTenantCode = localStorage.getItem('tenant')

export const TransferEntry: FC<TransferEntryProps> = ({ visible, data, errors, onChange }) => {
  const myTenants = useSelector(selectMyTenants)
  const activeTenant = useMemo(() => myTenants.find(t => t.tenantCode === activeTenantCode), [myTenants])
  const employee = useSelector((state: StoreState) => state.employee.employees.entities[data.employeeId || ''])
  const employeeNo = employee?.employeeNo
  const canModifyCo = usePermissionGate(Permission.company, PermissionAction.Modify)
  const [focusRef] = useFocus(true)

  const handleFetchEmployees = useCallback(async () => {
    const { status, result } = await apiGetEmSelect('active')
    if (status) {
      return result
    }
    return []
  }, [])

  useEffect(() => {
    onChange({ employeeNo })
  }, [employeeNo, onChange])

  return (
    <div className="transfer-entry" hidden={!visible}>
      <Form>
        <Row>
          <Col span={12}>
            <Form.Item
              label="Select employee"
              validateStatus={errors?.employeeId ? 'error' : ''}
              help={errors?.employeeId}
              rules={[{ required: true, message: 'Employee is required' }]}
              tooltip={{
                title: 'Active employees.',
                icon: (
                  <span>
                    <i className="fal fa-circle-info" />
                  </span>
                )
              }}
            >
              <EmSelect
                ref={focusRef}
                value={data.employeeId}
                onFetch={handleFetchEmployees}
                onChange={(value: string) => onChange({ employeeId: value })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              label="New company"
              validateStatus={errors?.companyId ? 'error' : ''}
              help={errors?.companyId}
              rules={[{ required: true, message: 'Company is required' }]}
            >
              <NewTabLinkIcon
                path={SETTINGS_ROUTES.companies}
                tooltipText="Open company in a new tab"
                hidden={!canModifyCo}
              >
                <EmpKeyValues
                  id="company"
                  allowClear={false}
                  value={data.companyId}
                  onChange={(value?: string) => onChange({ companyId: value })}
                />
              </NewTabLinkIcon>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              label="Transfer date"
              validateStatus={errors?.hireDate ? 'error' : ''}
              help={errors?.hireDate}
              rules={[{ required: true, message: 'Transfer date is required' }]}
            >
              <Form.Item noStyle>
                <Input.Date
                  ref={focusRef}
                  value={data.hireDate ? moment(data.hireDate) : undefined}
                  onChange={(value: moment.Moment | null) => {
                    onChange({ hireDate: value?.format('YYYY-MM-DD') })
                  }}
                />
              </Form.Item>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              label="Employee no."
              validateStatus={errors?.employeeNo ? 'error' : ''}
              help={errors?.employeeNo}
              rules={[{ required: true, message: 'Employee no. is required' }]}
            >
              <Input
                value={data.employeeNo}
                onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({ employeeNo: event.target.value })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row hidden={!activeTenant?.featureFlags?.includes(FeatureFlag.Payroll)}>
          <Col span={12}>
            <Form.Item
              label="Payroll group"
              validateStatus={errors?.payGroupId ? 'error' : ''}
              help={errors?.payGroupId}
              rules={[{ required: true, message: 'Payroll group is required' }]}
            >
              <NewTabLinkIcon
                path={SETTINGS_ROUTES.payGroups}
                tooltipText="Open payroll group in a new tab"
                hidden={!canModifyCo}
              >
                <EmpKeyValues
                  id="payGroup"
                  value={data.payGroupId}
                  queryParams={{ companyId: data.companyId }}
                  onChange={(value?: string) => onChange({ payGroupId: value })}
                />
              </NewTabLinkIcon>
            </Form.Item>
          </Col>
        </Row>
        <Row hidden={!activeTenant?.featureFlags?.includes(FeatureFlag.Leave)}>
          <Col span={12}>
            <Form.Item
              label="Leave group"
              validateStatus={errors?.leaveGroupId ? 'error' : ''}
              help={errors?.leaveGroupId}
              rules={[{ required: true, message: 'Leave group is required' }]}
            >
              <NewTabLinkIcon
                path={SETTINGS_ROUTES.leaveGroups}
                tooltipText="Open leave group in a new tab"
                hidden={!canModifyCo}
              >
                <EmpKeyValues
                  id="leaveGroup"
                  value={data.leaveGroupId}
                  onChange={(value?: string) => onChange({ leaveGroupId: value })}
                />
              </NewTabLinkIcon>
            </Form.Item>
          </Col>
        </Row>
        <ErrorDisplay errors={errors} />
      </Form>
    </div>
  )
}
