import React, { FC, useCallback, useState, ChangeEvent, useEffect, CSSProperties } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment-timezone'
import { LoadingOutlined } from '@ant-design/icons'
import { Form, Input, Spin } from '~/core-components'
import { Col, DrawerForm, EmpKeyValues, NewTabLinkIcon, Row, SysOptions } from '~/components'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { dispatch } from '~/stores/store'
import { addEmployee } from '../../../actions'
import { INewEmployee } from '../../../types'
import { ActionResult, Errors } from '~/types/store'
import { useFocus } from '~/hooks/use-focus'
import { EMP_ROUTES, SETTINGS_ROUTES } from '~/routes/routes'
import { apiGetLatestEmployeeNo } from '~/features/employee/api/employee.api'

export interface AddEmployeeDrawerProps {
  visible: boolean
  onClose: () => void
}

const EMPTY_FORM_DATA: INewEmployee = {
  firstName: '',
  lastName: '',
  fullName: '',
  email: '',
  employeeNo: '',
  companyId: '',
  hireDate: '',
  birthDate: '',
  gender: '',
  maritalStatus: '',
  nationalityId: ''
}

const tooltipOverlayStyle: CSSProperties = { whiteSpace: 'pre-line' }

export const AddEmployeeDrawer: FC<AddEmployeeDrawerProps> = ({ visible, onClose }: AddEmployeeDrawerProps) => {
  const history = useHistory()
  const canModify = usePermissionGate(Permission.company, PermissionAction.Modify)
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<INewEmployee>(EMPTY_FORM_DATA)
  const [focusRef, setFocus] = useFocus(true)
  const [errors, setErrors] = useState<Errors>()
  const [latestEmployeeNo, setLatestEmployeeNo] = useState<string>('')
  const [emNoFetching, setEmNoFetching] = useState(false)

  const handleLatestEmployeeNo = useCallback(async () => {
    try {
      setEmNoFetching(true)
      const { status, result } = await apiGetLatestEmployeeNo()
      if (status) {
        setLatestEmployeeNo(result)
      }
    } finally {
      setEmNoFetching(false)
    }
  }, [])

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)

    if (visible) {
      handleLatestEmployeeNo()
    }
  }, [visible, setFocus, handleLatestEmployeeNo])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      result = await dispatch(addEmployee(formData))
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_FORM_DATA)

      if (result?.result?.id) {
        const employeeId = result.result.id
        history.push(`${EMP_ROUTES.employee.replace(':id', employeeId)}`)
      }
    }
  }, [formData, onClose, history])

  const handleCloseDrawer = useCallback(() => {
    typeof onClose === 'function' && onClose()
    setLatestEmployeeNo('')
  }, [onClose])

  return (
    <DrawerForm
      open={visible}
      title="Add new employee"
      onClose={handleCloseDrawer}
      confirmLoading={loading}
      width={650}
      formId="form-employee"
    >
      <Form id="form-employee" onFinish={handleOk}>
        <Row gutter={30}>
          <Col span={12}>
            <Form.Item label="First name">
              <Input
                ref={focusRef}
                value={formData.firstName}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleFormDataChange({
                    firstName: event.target.value,
                    fullName: `${event.target.value} ${formData.lastName}`.trim()
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Last name">
              <Input
                value={formData.lastName}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleFormDataChange({
                    lastName: event.target.value,
                    fullName: `${formData.firstName} ${event.target.value}`.trim()
                  })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Full name (as per IC)"
              validateStatus={errors?.fullName ? 'error' : ''}
              help={errors?.fullName}
            >
              <Input
                value={formData.fullName}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleFormDataChange({ fullName: event.target.value })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Work email" validateStatus={errors?.email ? 'error' : ''} help={errors?.email}>
              <Input
                value={formData.email}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ email: event.target.value })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col span={12}>
            <Form.Item
              label="Employee no."
              validateStatus={errors?.employeeNo ? 'error' : ''}
              help={errors?.employeeNo}
              tooltip={{
                title: `Latest employee no. : \n ${latestEmployeeNo}`,
                overlayStyle: tooltipOverlayStyle,
                icon: emNoFetching ? (
                  <Spin indicator={<LoadingOutlined spin />} />
                ) : (
                  <span>
                    <i className="fal fa-circle-info" />
                  </span>
                )
              }}
            >
              <Form.Item noStyle>
                <Input
                  value={formData.employeeNo}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleFormDataChange({ employeeNo: event.target.value?.replace(/[^a-zA-Z0-9]/g, '') })
                  }
                />
              </Form.Item>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Hire date" validateStatus={errors?.hireDate ? 'error' : ''} help={errors?.hireDate}>
              <Input.Date
                value={formData.hireDate ? moment(formData.hireDate) : undefined}
                onChange={(value: moment.Moment | null) =>
                  handleFormDataChange({ hireDate: value?.format('YYYY-MM-DD') })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col span={24}>
            <Form.Item label="Company" validateStatus={errors?.companyId ? 'error' : ''} help={errors?.companyId}>
              <NewTabLinkIcon
                path={SETTINGS_ROUTES.companies}
                tooltipText="Open company in a new tab"
                tooltipPlacement="topRight"
                hidden={!canModify}
              >
                <EmpKeyValues
                  id="company"
                  value={formData.companyId}
                  onChange={(value: number | null) => handleFormDataChange({ companyId: value })}
                />
              </NewTabLinkIcon>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col span={12}>
            <Form.Item label="Date of birth" validateStatus={errors?.birthDate ? 'error' : ''} help={errors?.birthDate}>
              <Form.Item noStyle>
                <Input.Date
                  value={formData.birthDate ? moment(formData.birthDate) : undefined}
                  onChange={(value: moment.Moment | null) =>
                    handleFormDataChange({ birthDate: value?.format('YYYY-MM-DD') })
                  }
                />
              </Form.Item>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Gender" validateStatus={errors?.gender ? 'error' : ''} help={errors?.gender}>
              <SysOptions
                type="gender"
                value={formData.gender}
                onChange={(value: string) => handleFormDataChange({ gender: value })}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}
