import React, { FC } from 'react'
import { default as AntTabs, TabsProps as AntTabsProps } from 'antd/lib/tabs'
import { BreadcrumbProps as AntBreadcrumbProps } from 'antd/lib/breadcrumb'
import classNames from 'classnames'
import { Affix } from '../Affix/Affix'
import { PageBreadcrumb } from '../PageHeader/PageBreadcrumb'
import 'antd/es/tabs/style'
import './Tabs.less'

export interface TabsProps extends AntTabsProps {
  containerId?: string
  breadcrumb?: AntBreadcrumbProps
}

export const Tabs: FC<TabsProps> = ({ className, containerId, breadcrumb, ...props }) => {
  const containerClasses = classNames('tabs-container', { 'tabs-container--page': !!breadcrumb })
  const containerEl = document.getElementById(containerId || '')
  const classes = classNames('tabs', { [`${className}`]: className })

  return (
    <div className={containerClasses}>
      {breadcrumb && (
        <Affix target={() => containerEl}>
          <PageBreadcrumb {...breadcrumb} />
        </Affix>
      )}
      <AntTabs size="small" className={classes} {...props} />
    </div>
  )
}
