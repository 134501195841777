import React, { FC } from 'react'
import classNames from 'classnames'
import { Tag, TagProps } from '../Tag/Tag'
import './ButtonTag.less'

interface ButtonTagProps extends TagProps {}

export const ButtonTag: FC<ButtonTagProps> = ({ className, ...props }) => (
  <Tag type="secondary-filled" className={classNames('button-tag', className)} {...props} />
)
