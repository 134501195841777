import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchPayRuns } from '../actions'
import { PayRunState } from '../types'
import { selectPayRuns } from '../selectors'

export const usePayRuns = (fetchStrategy: FetchStrategy = 'when-empty'): [PayRunState[], boolean] => {
  const payRuns = useSelector(selectPayRuns)()
  const loading = useSelector((state: StoreState) => state.payroll.payRunsLoading)

  useEffect(() => {
    dispatch(fetchPayRuns({ strategy: fetchStrategy }))
  }, [fetchStrategy])

  return [payRuns, loading]
}
