import { showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiUpdateViewCriteria } from '../api/view.api'
import { Screen, IViewCriteria } from '../types'
import { setViewSchema } from '../reducers'

export const updateViewCriteria = (screenCode: Screen, viewId: string, schema: IViewCriteria): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!screenCode || !viewId) return

    const { status, errors, message, errorData } = await apiUpdateViewCriteria(screenCode, viewId, schema)

    if (status) {
      const viewSchema = getState().selection.viewSchema[screenCode]?.entities[viewId]
      if (viewSchema) {
        dispatch(
          setViewSchema({
            screenCode,
            data: {
              ...viewSchema,
              ...schema
            }
          })
        )
      }
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
