import { showSuccess, showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { apiUpdateLtSettings } from '../api/lt-setting.api'
import { replaceLtSettings } from '../reducers'
import { LtSettingState } from '../types'

export const updateLtSettings = (leaveTypeId: string, ltSettings: LtSettingState[]): ThunkResult<void> => {
  return async (dispatch): Promise<ActionResult> => {
    const { status, errors, message, errorData } = await apiUpdateLtSettings(leaveTypeId, ltSettings)
    if (status) {
      dispatch(replaceLtSettings({ leaveTypeId, data: ltSettings }))
      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
