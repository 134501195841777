import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { LeaveBuddyState } from '../types'
import { fetchLeaveBuddies } from '../actions'
import { selectLeaveBuddies } from '../selectors'

export const useLeaveBuddies = (): [LeaveBuddyState[], boolean] => {
  const leaveBuddies = useSelector(selectLeaveBuddies)
  const loading = useSelector((state: StoreState) => state.leave.leaveBuddiesLoading)

  useEffect(() => {
    dispatch(fetchLeaveBuddies())
  }, [])

  return [leaveBuddies, loading]
}
