import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { SelfUpdateConfigState } from '../types'
import { selectHiddenSelfUpdateConfigs } from '../selectors'
import { fetchSelfUpdateConfigs } from '../actions'

export const useHiddenSelfUpdateConfigs = (): [SelfUpdateConfigState[], boolean] => {
  const data = useSelector(selectHiddenSelfUpdateConfigs)
  const loading = useSelector((state: StoreState) => state.master.selfUpdateConfigsLoading)

  useEffect(() => {
    dispatch(fetchSelfUpdateConfigs({ strategy: 'when-empty' }))
  }, [])

  return [data, loading]
}
