import { setEmDirectoryView, setEmDirectoryViewLoading } from '../reducers'
import { apiGetEmDirectory } from '../api/em-directory.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions, Pagination } from '~/types/common'

export const fetchEmDirectoryView = (
  viewId: string,
  pagination: Pagination,
  search: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!viewId) return

    const loading = getState().employee.emDirectoryViewLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().employee.emDirectoryView?.entities[viewId]
      if (hasData) return
    }

    try {
      dispatch(setEmDirectoryViewLoading(true))
      const { result, status } = await apiGetEmDirectory(viewId, { ...pagination, search })
      if (status) {
        dispatch(setEmDirectoryView(result))
      }
    } finally {
      dispatch(setEmDirectoryViewLoading(false))
    }
  }
}
