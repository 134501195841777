import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { OtConfigState } from '../types'

export const selectOtConfigs = createSelector(
  (state: StoreState) => state.attendance.otConfigs,
  (otConfigState): OtConfigState[] => {
    const configs = Object.values(otConfigState?.entities || {}) as OtConfigState[]
    return configs.sort((a, b) => (a.isShown === b.isShown ? a.code?.localeCompare(b.code) : a.isShown ? -1 : 1))
  }
)
