import React, { forwardRef } from 'react'
import classNames from 'classnames'
import { Select, SelectProps } from '~/core-components'
import { useSysWfEventTypes } from '../../hooks'
import { SysWfEventTypeState } from '../../types'
import './SelectWfEventType.less'

export interface SelectWfEventTypeProps extends SelectProps {
  flowType: string
  value: string
  onChange: (value: string) => void
}

const SelectWfEventTypeInternal = ({ flowType, className, ...props }: SelectWfEventTypeProps, ref: React.Ref<any>) => {
  const classes = classNames('select-wf-event-type', { [`${className}`]: className })
  const [eventTypes] = useSysWfEventTypes(flowType)

  return (
    <Select ref={ref} className={classes} allowClear={false} {...props}>
      {(Object.values(eventTypes) as SysWfEventTypeState[]).map(e => (
        <Select.Option key={e.code} value={e.code}>
          {e.name}
        </Select.Option>
      ))}
    </Select>
  )
}

export const SelectWfEventType = forwardRef(SelectWfEventTypeInternal)
