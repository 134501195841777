import { UploadFile } from '~/core-components'
import { getBaseUrl, request } from '~/utils'
import { IFeedback } from '../types'

const baseUrl = getBaseUrl('/employee/feedback')

export const apiSendFeedback = (feedback: IFeedback) => {
  let formData = new FormData()

  Object.entries(feedback).forEach(([key, value]) => {
    if (key === 'attachments') {
      value.forEach((x: UploadFile) => {
        if (x instanceof File) formData.append(key, x, x.name)
      })
    } else {
      formData.append(key, value === null ? '' : value)
    }
  })

  return request<{}>('post', baseUrl, formData)
}
