import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Descriptions } from '~/core-components'
import { fetchEmpKeyvalues, selectKeyvaluesById } from '~/features/master'
import { dispatch } from '~/stores/store'
import { EmployeeState } from '../../../../../types'
import { useSysOptions } from '../../../../../hooks'

interface CopyEmPaymentProps {
  employee?: EmployeeState
}

export const CopyEmPayment: FC<CopyEmPaymentProps> = ({ employee }) => {
  const [payMethods] = useSysOptions('pay_method')
  const banks = useSelector(selectKeyvaluesById)('bank')

  useEffect(() => {
    dispatch(fetchEmpKeyvalues('bank', undefined, undefined, { strategy: 'when-empty' }))
  }, [])

  return (
    <>
      {!!employee?.bankCode2 && <div className="copy-em__content copy-em__subtitle">Primary</div>}
      <Descriptions bordered column={1} className="copy-em__content">
        <Descriptions.Item label="Payment method">{payMethods[employee?.payMethod || '']?.value}</Descriptions.Item>
        {employee?.payMethod === 'bank' && (
          <>
            <Descriptions.Item label="Bank">{banks[employee?.bankCode || '']?.value}</Descriptions.Item>
            <Descriptions.Item label="Branch">{employee?.bankBranchCode}</Descriptions.Item>
            <Descriptions.Item label="Account no.">{employee?.bankAccountNo}</Descriptions.Item>
            {employee?.bankAccountName && (
              <Descriptions.Item label="Beneficiary name">{employee?.bankAccountName}</Descriptions.Item>
            )}
          </>
        )}
      </Descriptions>
      {!!employee?.bankCode2 && (
        <>
          <div className="copy-em__content copy-em__subtitle">Secondary</div>
          <Descriptions bordered column={1} className="copy-em__content">
            <Descriptions.Item label="Bank">{banks[employee?.bankCode2 || '']?.value}</Descriptions.Item>
            <Descriptions.Item label="Branch">{employee?.bankBranchCode2}</Descriptions.Item>
            <Descriptions.Item label="Account no.">{employee?.bankAccountNo2}</Descriptions.Item>
            {employee?.bankAccountName2 && (
              <Descriptions.Item label="Beneficiary name">{employee?.bankAccountName2}</Descriptions.Item>
            )}
          </Descriptions>
        </>
      )}
    </>
  )
}
