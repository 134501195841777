import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { SSEntitledLeaveType } from '../../types'

export const selectMyEntitledLeaveType = createSelector(
  (state: StoreState) => state.my.myLeaveBalances,
  (state: StoreState) => state.leave.leaveTypes,
  (myLeaveBalancesState, leaveTypesState) =>
    memoize((leaveTypeId: string): SSEntitledLeaveType | undefined => {
      const item = Object.values(myLeaveBalancesState?.entities || {}).find(e => e?.leaveTypeId === leaveTypeId)
      const leaveType = leaveTypesState.entities[item?.leaveTypeId || '']

      return {
        id: leaveType?.id,
        name: leaveType?.name,
        entPerTime: item?.entPerTime
      } as SSEntitledLeaveType
    })
)
