import { request, getBaseUrl } from '~/utils/request'
import { SSLeaveRecordCommentState } from '../types'

const baseUrl = getBaseUrl('/leave/ssleaverecordcomment')

export const apiGetMyLeaveRecordComments = (leaveRecordId: string) =>
  request<SSLeaveRecordCommentState[]>('get', `${baseUrl}/${leaveRecordId}`)

export const apiAddMyLeaveRecordComments = (leaveRecordId: string, comment: string) =>
  request<{ id: string; createdDate: string }>('post', `${baseUrl}/${leaveRecordId}`, { comment })

export const apiUpdateMyLeaveRecordComment = (leaveRecordId: string, id: string, comment: string) =>
  request<void>('post', `${baseUrl}/${leaveRecordId}/${id}`, { comment })

export const apiDeleteMyLeaveRecordComment = (leaveRecordId: string, id: string) =>
  request<void>('delete', `${baseUrl}/${leaveRecordId}/${id}`)
