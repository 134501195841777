import { request, getBaseUrl, timeout } from '~/utils/request'
import { Search } from '~/types/common'
import { ROLeEarnedState, ROLeaveEntitlementViewState } from '../types'

const baseUrl = getBaseUrl('/leave/roleaveentitlement')

export const apiGetROLeaveEntitlementView = (viewId: string, role: string, periodYear: number, params: Search) =>
  request<ROLeaveEntitlementViewState>('get', `${baseUrl}/view/${viewId}/${role}/${periodYear}`, params)

export const apiGetROLeavePeriodYears = () => request('get', `${baseUrl}/periodyear`)

export const apiGetROLeaveEntitlementExcel = (viewId: string, role: string, periodYear: number, params: Search) =>
  request('get', `${baseUrl}/view/${viewId}/${role}/${periodYear}/excel`, params, { responseType: 'blob', timeout })

export const apiGetROLeEarned = (periodCode: string, employeeId: string, leaveTypeId: string) =>
  request<ROLeEarnedState[]>('get', `${baseUrl}/${periodCode}/${employeeId}/${leaveTypeId}/earned`)
