import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Dictionary } from '@reduxjs/toolkit'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { KeyValue } from '~/types/common'
import { fetchKeyvalues } from '../actions'
import { selectKeyvaluesById } from '../selectors'

export const useIr8aCategories = (): [Dictionary<KeyValue>, boolean] => {
  const data = useSelector(selectKeyvaluesById)('ir8aCategory')
  const loading = useSelector((state: StoreState) => state.master.keyvaluesLoading['ir8aCategory'])

  useEffect(() => {
    dispatch(fetchKeyvalues('ir8aCategory', 'payroll-sg', undefined, { strategy: 'when-empty' }))
  }, [])

  return [data, loading]
}
