import { setSysGroupings, setSysGroupingsLoading } from '../reducers'
import { apiGetGroupings } from '../api/sys-grouping.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'
import { RptScreen } from '../types'

export const fetchSysGroupings = (
  screenCode: RptScreen,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!screenCode) return

    const loading = getState().grouping.sysGroupingsLoading[screenCode]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const groupingScreens = Object.values(getState().grouping.sysGroupingScreens[screenCode]?.entities || {})
      const groupingCodes = groupingScreens.map(s => s?.groupingCode)

      const groupings = Object.values(getState().grouping.sysGroupings[screenCode]?.entities || {})
      const hasData = groupings.some(s => groupingCodes.includes(s?.code))
      if (hasData) return
    }

    try {
      dispatch(setSysGroupingsLoading({ screenCode, loading: true }))
      const { result, status } = await apiGetGroupings(screenCode)
      if (status) {
        dispatch(setSysGroupings({ screenCode, data: result }))
      }
    } finally {
      dispatch(setSysGroupingsLoading({ screenCode, loading: false }))
    }
  }
}
