import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { View } from '~/constants'
import { Screen } from '~/features/selection'

const screenCode: Screen = 'em_person'

export const selectEmPublicDescription = createSelector(
  (state: StoreState) => state.employee.emPublicPersons,
  (state: StoreState) => state.selection.sysSelectionFields,
  (state: StoreState) => state.selection.viewSchema,
  (emPublicInfosState, sysSelectionFieldsState, viewSchemaState) =>
    memoize((employeeId: string) => {
      const schema = Object.values(viewSchemaState[screenCode]?.entities || {}).find(
        v => v?.screenCode === screenCode && v?.viewName === View.default
      )
      const data = emPublicInfosState?.entities[employeeId]

      return schema?.selection
        .filter(s => {
          const field = sysSelectionFieldsState[screenCode]?.entities[s.selectionFieldId]
          return data && field
        })
        .map(s => {
          const field = sysSelectionFieldsState[screenCode]?.entities[s.selectionFieldId]
          if (data && field) return data[field.fieldName]
          return null
        })
        .join(', ')
    })
)
