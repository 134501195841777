import React, { forwardRef } from 'react'
import { default as AntAutocomplete, AutoCompleteProps as AntAutocompleteProps } from 'antd/lib/auto-complete'
import 'antd/es/auto-complete/style'

export interface AutocompleteProps extends AntAutocompleteProps {
  readOnly?: boolean
}

const AutocompleteInternal = (
  { readOnly, allowClear = true, filterOption = true, ...props }: AutocompleteProps,
  ref: React.Ref<any>
) => {
  return (
    <AntAutocomplete
      {...props}
      ref={ref}
      allowClear={readOnly ? false : allowClear}
      filterOption={readOnly ? false : filterOption}
      onChange={readOnly ? undefined : props.onChange}
      onSelect={readOnly ? undefined : props.onSelect}
    />
  )
}

export const Autocomplete = forwardRef(AutocompleteInternal)
