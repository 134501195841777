import React, {
  CSSProperties,
  FocusEvent,
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { Input } from '../../core-components/Input'
import { NumberProps } from '../../core-components/Input/Number'

interface SalaryInputProps extends Omit<NumberProps<string>, 'value' | 'onChange' | 'min' | 'max'> {
  value?: number | null
  onChange?: (value: number | null) => void
  min?: number | null
  max?: number | null
}

const inputSalaryStyle: CSSProperties = { width: '100%' }

const SalaryInputInternal = (
  { value, onChange, min, max, ...props }: SalaryInputProps,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const [isFocus, setIsFocus] = useState(false)
  const formatted = useMemo(() => (value || 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','), [value])
  const [internalValue, setInternalValue] = useState(formatted)

  useEffect(() => {
    if (isFocus) return

    setInternalValue(formatted)
  }, [isFocus, formatted])

  const handleFocus = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      setIsFocus(true)
      setInternalValue(`${value}`)
      setTimeout(() => event?.target?.select(), 0)
    },
    [value]
  )

  const handleBlur = useCallback(() => {
    setIsFocus(false)
    setInternalValue(formatted)
  }, [formatted])

  const handleChange = useCallback(
    (value: string | null) => {
      setInternalValue(`${value}`)
      typeof onChange === 'function' && onChange(Number(value))
    },
    [onChange]
  )

  return (
    <Input.Number<string>
      ref={ref}
      style={inputSalaryStyle}
      min={`${min}`}
      max={`${max}`}
      precision={2}
      value={internalValue}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      stringMode
      {...props}
    />
  )
}

export const SalaryInput = forwardRef(SalaryInputInternal)
