import { showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiPrepareDailyImport } from '../api/daily-import.api'
import { replaceDailyImport } from '../reducers'

export const prepareDailyImport = (payRunId: string, attendancePeriodId: string): ThunkResult<void> => {
  return async dispatch => {
    const { status, result, errors, message, errorData } = await apiPrepareDailyImport(payRunId, attendancePeriodId)
    if (status) {
      dispatch(replaceDailyImport(result))
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors, result }
  }
}
