import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { WfActionState } from '../types'

export const selectWfActions = createSelector(
  (state: StoreState) => state.workflow.wfActions,
  wfActionsState =>
    memoize((wfEventId: string): WfActionState[] => {
      const events = Object.values(wfActionsState[wfEventId]?.entities || {}) as WfActionState[]
      return events
    })
)
