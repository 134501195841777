import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import confirm from 'antd/lib/modal/confirm'
import { Button, PageHeader } from '~/core-components'
import { DocumentTitle } from '~/components'
import { iamMenus } from '~/features/master/configs'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { Permission, PermissionAction } from '~/constants'
import { dispatch } from '~/stores/store'
import { ActionResult, StoreState } from '~/types/store'
import { useLogins, usePermissionGate } from '../../hooks'
import { deleteLogin } from '../../actions'
import { selectLoginById } from '../../reducers'
import { LoginInfo } from './components/LoginInfo'
import { useMasterMenu } from '../../../master/containers/MasterMenuContext'
import './Login.less'

export interface LoginProps {}

interface LoginParams {
  id: string
}

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: SETTINGS_ROUTES.logins,
    breadcrumbName: 'Overview'
  },
  {
    path: '',
    breadcrumbName: 'User'
  }
]

export const Login: FC<LoginProps> = () => {
  const { id } = useParams<LoginParams>()
  const [logins] = useLogins()
  const login = useSelector((state: StoreState) => selectLoginById(state, id))
  const [editing, setEditing] = useState(false)
  const history = useHistory()
  const canDelete = usePermissionGate(Permission.login, PermissionAction.Delete)
  const { setMenus } = useMasterMenu()

  useEffect(() => {
    setMenus(
      logins
        .filter(lg => !!lg)
        .map(lg => ({
          path: SETTINGS_ROUTES.login.replace(':id', lg!.id),
          value: lg!.email,
          sysPermissionId: Permission.login
        }))
    )

    return () => {
      setMenus(iamMenus)
    }
  }, [setMenus, logins])

  const handleEdit = useCallback(() => setEditing(true), [])
  const handleView = useCallback(() => setEditing(false), [])

  const handleDelete = useCallback(() => {
    if (id && login) {
      confirm({
        title: 'Remove user',
        content: `Do you want to remove user "${login?.loginName}" from workspace?`,
        onOk: async () => {
          const result: ActionResult | undefined = await dispatch(deleteLogin(id))
          if (!result?.errors) {
            history.push(SETTINGS_ROUTES.logins)
          }
        },
        okText: 'Remove',
        okType: 'danger'
      })
    }
  }, [id, login, history])

  return (
    <div id="login" className="login">
      <DocumentTitle title="User" />
      <PageHeader
        title="User"
        containerId="login"
        breadcrumb={{ routes }}
        extra={canDelete && editing ? <Button onClick={handleDelete}>Remove</Button> : null}
      />
      <div className="login__body">
        <LoginInfo login={login} onEdit={handleEdit} onSave={handleView} onCancel={handleView} />
      </div>
    </div>
  )
}
