import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { FetchStrategy } from '~/types/common'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { SSRealTimeSummaryState } from '../../types'
import { fetchRealTimeSummary } from '../../actions'

export const useRealTimeSummary = (
  locationId?: string,
  fetchStrategy: FetchStrategy = 'always'
): [SSRealTimeSummaryState | undefined, boolean] => {
  const data = useSelector((state: StoreState) => state.my.realTimeSummary)
  const loading = useSelector((state: StoreState) => state.my.realTimeSummaryLoading)
  const refetch = useSelector((state: StoreState) => state.my.realTimeSummaryRefetch)

  useEffect(() => {
    dispatch(fetchRealTimeSummary(locationId, { strategy: fetchStrategy }))
  }, [refetch, locationId, fetchStrategy])

  return [data, loading]
}
