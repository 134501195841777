import React, { FC } from 'react'
import { Route, RouteProps, Switch } from 'react-router-dom'
import { MyClaimTask } from '~/features/my-claim'
import { MyUserSetting } from '~/features/iam'
import { MY_ROUTES } from '~/routes/routes'
import { Home } from './Home/Home'
import { Me } from './MyProfile/Me'
import { MyOrgChart } from './MyOrgChart/MyOrgChart'
import { MyCalendar } from './MyCalendar/MyCalendar'
import { ROLeaveOverview } from './Leave/ROLeave/ROLeaveOverview'
import { ROScheduleOverview } from '~/features/my-schedule/containers/ROScheduleOverview/ROScheduleOverview'
import './MyLayout.less'

const routeList: RouteProps[] = [
  {
    path: MY_ROUTES.home,
    component: Home,
    exact: true
  },
  {
    path: MY_ROUTES.self,
    component: Me,
    exact: true
  },
  {
    path: MY_ROUTES.selfTabId,
    component: Me,
    exact: true
  },
  {
    path: MY_ROUTES.calendar,
    component: MyCalendar,
    exact: true
  },
  {
    path: MY_ROUTES.org,
    component: MyOrgChart,
    exact: true
  },
  {
    path: MY_ROUTES.roLeave,
    component: ROLeaveOverview,
    exact: true
  },
  {
    path: MY_ROUTES.roSchedule,
    component: ROScheduleOverview,
    exact: true
  },
  {
    path: MY_ROUTES.user,
    component: MyUserSetting,
    exact: true
  },
  {
    path: MY_ROUTES.claimTask,
    component: MyClaimTask,
    exact: true
  }
]

export const MyLayout: FC = () => {
  return (
    <div className="my-layout">
      <Switch>
        {routeList.map(route => (
          <Route key={route.path as string} {...route} />
        ))}
      </Switch>
    </div>
  )
}
