import React, { FC, useCallback, useEffect, useState } from 'react'
import { Select } from '~/core-components'
import { useLeavePeriodYears } from '../../../hooks'

interface SelectLeavePeriodYearsProps {
  onChange: (periodYear: number) => void
}

export const SelectLeavePeriodYears: FC<SelectLeavePeriodYearsProps> = ({ onChange }) => {
  const [periodYear, setPeriodYear] = useState<number>()
  const [leavePeriods] = useLeavePeriodYears()
  const defaultLeavePeriod = leavePeriods.length > 0 ? leavePeriods[0].periodYear : undefined

  useEffect(() => {
    if (defaultLeavePeriod && !isNaN(defaultLeavePeriod)) {
      setPeriodYear(defaultLeavePeriod)
    }
  }, [defaultLeavePeriod])

  useEffect(() => {
    if (periodYear) {
      typeof onChange === 'function' && onChange(periodYear)
    }
  }, [periodYear, onChange])

  const handlePeriodChange = useCallback(periodYear => {
    setPeriodYear(periodYear)
  }, [])

  return (
    <Select
      className="period-input"
      allowClear={false}
      dropdownMatchSelectWidth={false}
      value={periodYear}
      onChange={handlePeriodChange}
      optionFilterProp="title"
    >
      {leavePeriods.map(p => (
        <Select.Option key={p.periodYear} value={p.periodYear} title={p.periodYear.toString()}>
          {p.periodYear}
        </Select.Option>
      ))}
    </Select>
  )
}
