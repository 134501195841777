import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { FetchStrategy } from '~/types/common'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { SSRealTimeAttendanceState } from '../../types'
import { selectRealTimeAttendance } from '../../selectors'
import { fetchRealTimeAttendance } from '../../actions'

export const useRealTimeAttendance = (
  locationId?: string,
  fetchStrategy: FetchStrategy = 'always'
): [SSRealTimeAttendanceState[], boolean] => {
  const records = useSelector(selectRealTimeAttendance)(locationId)
  const loading = useSelector((state: StoreState) => state.my.realTimeAttendanceLoading[locationId || ''])
  const refetch = useSelector((state: StoreState) => state.my.realTimeAttendanceRefetch)

  useEffect(() => {
    dispatch(fetchRealTimeAttendance(locationId, { strategy: fetchStrategy }))
  }, [fetchStrategy, locationId, refetch])

  return [records, loading]
}
