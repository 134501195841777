import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Card, ColumnsType, Link, Space, Table, Tooltip } from '~/core-components'
import { AmountDisplay } from '~/components'
import { ClaimStatusTag, ClaimTypeName, fetchClaimTypes, useClaimTypesDict } from '~/features/claim'
import { ClaRecordStatus, ClaCurrencyCode, ClaExpenseSubmissionType } from '~/constants'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchMyClaimRecordsView } from '../../actions'
import { SSClaimRecordViewColumnState } from '../../types'
import { MyClaimApplyDrawer } from '../MyClaimApply/MyClaimApplyDrawer'
import './MyClaimRecords.less'

interface MyClaimRecordsProps {}

interface DrawerState {
  visible: boolean
  id?: string
  editing?: boolean
  isMultiple?: boolean
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }
const PAGE_SIZE_OPTIONS = ['20', '50', '100']

const cardBodyStyle: CSSProperties = { padding: 0 }
const paginationStyle: CSSProperties = { marginRight: 20 }
const tooltipOverlayStyle: CSSProperties = { whiteSpace: 'pre-line', maxWidth: 400 }
const approverIconStyle: CSSProperties = { fontSize: 12 }

export const MyClaimRecords: FC<MyClaimRecordsProps> = () => {
  const match = useRouteMatch<{ id: string }>()
  const data = useSelector((state: StoreState) => state.myClaim.myClaimRecordsView)
  const loading = useSelector((state: StoreState) => state.myClaim.myClaimRecordsViewLoading)
  const [claimTypes] = useClaimTypesDict()
  const refetch = useSelector((state: StoreState) => state.myClaim.myClaimRecordsViewRefetch)
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(20)
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)

  useEffect(() => {
    dispatch(fetchClaimTypes({ strategy: 'when-empty' }))
  }, [])

  useEffect(() => {
    if (match.params.id) {
      setDrawerState({
        visible: true,
        id: match.params.id,
        editing: false
      })
    }
  }, [match.params.id, data.data])

  useEffect(() => {
    dispatch(fetchMyClaimRecordsView({ offset: pageSize * (page - 1), limit: pageSize }))
  }, [page, pageSize, refetch])

  const handleOpenClaimRecord = useCallback(
    (claimRecord: SSClaimRecordViewColumnState) => {
      setDrawerState({
        visible: true,
        id: claimRecord.id,
        editing: false,
        isMultiple: claimRecord.id
          ? claimTypes[claimRecord.claimTypeId]?.expenseSubmissionType === ClaExpenseSubmissionType.Multiple
          : undefined
      })
    },
    [setDrawerState, claimTypes]
  )

  const handleCloseDrawer = useCallback(() => setDrawerState(DEFAULT_DRAWER_STATE), [])

  const handlePaginationChange = useCallback((page: number, pageSize?: number) => {
    setPage(page)
    setPageSize(pageSize || 20)
  }, [])

  const columns: ColumnsType<SSClaimRecordViewColumnState> = [
    {
      title: 'Claim #',
      key: 'claimNo',
      dataIndex: 'claimNo',
      render: (value: string, record) => <Link onClick={() => handleOpenClaimRecord(record)}>{`#${value}`}</Link>
    },
    {
      title: 'Claim type',
      key: 'claimTypeId',
      dataIndex: 'claimTypeId',
      render: (value: string) => <ClaimTypeName id={value} />
    },
    {
      title: 'Expense date',
      key: 'expenseDate',
      dataIndex: 'expenseDate',
      width: 110,
      render: (value: string) => (value ? moment(value).format('DD MMM YYYY') : '')
    },
    {
      title: 'Amount',
      key: 'claimAmount',
      dataIndex: 'claimAmount',
      width: 100,
      render: (value: number, record) => (
        <AmountDisplay
          block
          symbol={claimTypes[record.claimTypeId]?.currencyCode || ClaCurrencyCode.sgd}
          value={value}
        />
      )
    },
    {
      title: 'Status',
      key: 'recordStatus',
      dataIndex: 'recordStatus',
      width: 120,
      render: (value: string, record) => (
        <Space>
          <ClaimStatusTag status={value} recordType={record.recordType} />
          {[ClaRecordStatus.Pending, ClaRecordStatus.PendingCancel].some(s => value === s) && (
            <Tooltip
              title={`Current approver: \n ${record.currentApprovers?.join('\n')}`}
              overlayStyle={tooltipOverlayStyle}
            >
              <i className="fal fa-user" style={approverIconStyle}></i>
            </Tooltip>
          )}
        </Space>
      )
    },
    {
      title: <i className="fal fa-paperclip-vertical att-icon-header" />,
      key: 'attachmentCount',
      dataIndex: 'attachmentCount',
      width: 50,
      align: 'center',
      render: (value: number) => {
        return (
          value > 0 && (
            <>
              <i className="fal fa-paperclip-vertical" />
              <span className="att-count">{value}</span>
            </>
          )
        )
      }
    },
    {
      title: 'Notes',
      key: 'notes',
      dataIndex: 'notes',
      width: 50,
      align: 'center',
      render: (value: string) =>
        value && (
          <Tooltip title={value}>
            <i className="fal fa-note" />
          </Tooltip>
        )
    }
  ]

  return (
    <Card className="my-claim-records" bodyStyle={cardBodyStyle}>
      <Table
        rowKey="id"
        dataSource={data?.data}
        columns={columns}
        fitParent
        loading={loading}
        pagination={{
          total: data?.count,
          current: page,
          pageSize,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
          showSizeChanger: true,
          onChange: handlePaginationChange,
          style: paginationStyle
        }}
      />
      <MyClaimApplyDrawer {...drawerState} onClose={handleCloseDrawer} />
    </Card>
  )
}
