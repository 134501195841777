import { setMyLeaveGrants, setMyLeaveGrantsLoading } from '../../reducers'
import { apiGetMyLeaveGrantByLeaveRecord } from '../../api/ssleave.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchMyLeaveGrantByLeaveRecord = (
  leaveRecordId: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!leaveRecordId) return

    const loading = getState().my.myLeaveGrantsLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().my.myLeaveGrants?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setMyLeaveGrantsLoading(true))
      const { result, status } = await apiGetMyLeaveGrantByLeaveRecord(leaveRecordId)
      if (status) {
        dispatch(setMyLeaveGrants(result))
      }
    } finally {
      dispatch(setMyLeaveGrantsLoading(false))
    }
  }
}
