import { showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiRevertLeaveClosing } from '../api/leave-closing.api'
import { fetchLeavePeriods } from './fetch-leave-periods'
import { fetchLeavePeriodYears } from './fetch-leave-period-years'

export const revertSingleLeaveClosing = (employeeId: string, leaveTypeId: string): ThunkResult<void> => {
  return async dispatch => {
    const { status, result, errors, message, errorData } = await apiRevertLeaveClosing({
      employeeIds: [employeeId],
      leaveTypeIds: [leaveTypeId]
    })
    if (status) {
      dispatch(fetchLeavePeriods())
      dispatch(fetchLeavePeriodYears())
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors, result }
  }
}
