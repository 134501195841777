import { replacePayClaimMaps, setPayClaimMapsLoading } from '../reducers'
import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiUpdatePayClaimMaps } from '../api/pay-claim-map.api'
import { PayClaimMapState } from '../types'

export const updatePayClaimMaps = (payGroupId: string, data: PayClaimMapState[]): ThunkResult<void> => {
  return async dispatch => {
    if (!payGroupId) return

    try {
      dispatch(setPayClaimMapsLoading({ id: payGroupId, loading: true }))
      const { status, result, errors, message, errorData } = await apiUpdatePayClaimMaps(payGroupId, data)

      if (status) {
        dispatch(replacePayClaimMaps({ id: payGroupId, data: result }))
        showSuccess('Saved')
      } else {
        if (Object.keys(errors).length === 0) {
          showError(message, errorData)
        }
      }
      return { errors }
    } finally {
      dispatch(setPayClaimMapsLoading({ id: payGroupId, loading: false }))
    }
  }
}
