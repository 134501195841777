import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { apiUpdateClaimType } from '../api/claim-type.api'
import {
  ClaimTypeInfoState,
  ClaimTypeState,
  ClaimTypeApplyState,
  ClaimTypeEarnState,
  ClaimTypeLimitState,
  ClaimTypePaymentState,
  ClaimTypeCustomFieldState
} from '../types'
import { setClaimType } from '../reducers'

export const updateClaimType = <
  T extends
    | ClaimTypeInfoState
    | ClaimTypePaymentState
    | ClaimTypeEarnState
    | ClaimTypeLimitState
    | ClaimTypeApplyState
    | ClaimTypeCustomFieldState
>(
  id: string,
  before: T,
  request: T
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const after = { ...before, ...request } as T
    const patch = generateJsonPatch<T>(before, after)
    const { status, errors, message, errorData } = await apiUpdateClaimType(id, patch)

    if (status) {
      const claimType = getState().claim.claimTypes?.entities[id]
      const updated = { ...(claimType as ClaimTypeState), ...after }
      dispatch(setClaimType(updated))
      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
