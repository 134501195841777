import React, { CSSProperties, FC, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { SecondaryLink, Tab, TabTitle, Tabs, Tooltip } from '~/core-components'
import { DocumentTitle } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { StoreState } from '~/types/store'
import { dispatch } from '~/stores/store'
import { LVE_ROUTES } from '~/routes/routes'
import { Permission } from '~/constants'
import {
  setLeaveExpanded,
  refetchLeaveEntitlementsView,
  refetchLeaveRecordsView,
  refetchLeavePendingApprovalsView
} from '../../reducers'
import { LeaveEntitlement } from '../LeaveEntitlement/LeaveEntitlement'
import { LeaveRecord } from '../LeaveRecord/LeaveRecord'
import { LeavePendingApproval } from '../LeavePendingApproval/LeavePendingApproval'
import './LeaveOverview.less'

export interface LeaveOverviewProps {}

export const LeaveOverview: FC<LeaveOverviewProps> = () => {
  const match = useRouteMatch<{ tab: string }>()
  const expanded = useSelector((state: StoreState) => state.leave.leaveExpanded)
  const history = useHistory()
  const canViewEntitlement = usePermissionGate(Permission.lveEntitlement)
  const canViewRecord = usePermissionGate(Permission.lveRecord)

  const handleTabChange = useCallback(
    (activeKey: string) => {
      history.push(`${LVE_ROUTES.tab.replace(':tab?', activeKey)}`)

      if (activeKey === 'entitlement') {
        dispatch(refetchLeaveEntitlementsView())
      } else if (activeKey === 'records') {
        dispatch(refetchLeaveRecordsView())
      } else if (activeKey === 'pendings') {
        dispatch(refetchLeavePendingApprovalsView())
      }
    },
    [history]
  )

  const tabItems = useMemo(() => {
    const result: Tab[] = []
    if (canViewEntitlement) {
      result.push({ key: 'entitlement', label: 'Entitlement', children: <LeaveEntitlement /> })
    }
    if (canViewRecord) {
      result.push(
        { key: 'records', label: 'Records', children: <LeaveRecord /> },
        {
          key: 'pendings',
          label: 'Pending approvals',
          children: <LeavePendingApproval />
        }
      )
    }
    return result
  }, [canViewEntitlement, canViewRecord])

  return (
    <div className="leave-overview">
      <DocumentTitle title="Leave Overview" />
      <div className="leave-overview__body">
        <Tabs
          defaultActiveKey={match.params.tab || 'entitlement'}
          onChange={handleTabChange}
          tabBarExtraContent={{
            left: <TabTitle left={<LeaveExpandButton isExpand={expanded} />} title="Leave overview" />
          }}
          items={tabItems}
        />
      </div>
    </div>
  )
}

const expandButtonStyle: CSSProperties = { marginRight: 16 }

const LeaveExpandButton: FC<{ isExpand: boolean }> = ({ isExpand }) => {
  const handleExpand = useCallback(() => {
    dispatch(setLeaveExpanded(!isExpand))
  }, [isExpand])

  return (
    <Tooltip title={isExpand ? 'Normal view' : 'Maximise view'}>
      <SecondaryLink style={expandButtonStyle} onClick={handleExpand}>
        {isExpand ? (
          <i className="fal fa-arrow-down-left-and-arrow-up-right-to-center" />
        ) : (
          <i className="fal fa-arrow-up-right-and-arrow-down-left-from-center" />
        )}
      </SecondaryLink>
    </Tooltip>
  )
}
