import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { Screen, ViewSchemaState } from '../types'

export const selectViewSchemaByName = createSelector(
  (state: StoreState) => state.selection.viewSchema,
  viewSchemaState =>
    memoize(
      (screenCode: Screen, viewName: string): ViewSchemaState | undefined => {
        const viewSchema = Object.values(viewSchemaState[screenCode]?.entities || {}).find(
          v => v?.screenCode === screenCode && v?.viewName === viewName
        )
        return viewSchema
      },
      (screenCode: Screen, viewName: string) => `${screenCode}|${viewName}`
    )
)
