import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { FetchStrategy } from '~/types/common'
import { fetchLocationsView } from '../actions'
import { LocationState } from '../types'
import { selectLocationsView } from '../selectors'

export const useLocationsView = (
  viewId: string,
  search: string,
  fetchStrategy: FetchStrategy = 'always'
): [LocationState[], boolean] => {
  const data = useSelector(selectLocationsView)
  const loading = useSelector((state: StoreState) => state.attendance.locationsViewLoading)
  const refetch = useSelector((state: StoreState) => state.attendance.refetchLocationsView)

  useEffect(() => {
    dispatch(fetchLocationsView(viewId, search, { strategy: fetchStrategy }))
  }, [viewId, search, fetchStrategy, refetch])

  return [data, loading]
}
