import { setXeroConfig, setXeroConfigLoading } from '../reducers'
import { apiGetXeroConfig } from '../api/xero.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchXeroConfig = (
  companyId?: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!companyId) return

    const loading = getState().payroll.xeroConfigLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = !!getState().payroll.xeroConfig?.entities[companyId]
      if (hasData) return
    }

    try {
      dispatch(setXeroConfigLoading(true))
      const { result, status } = await apiGetXeroConfig(companyId)
      if (status) {
        dispatch(setXeroConfig({ ...result, companyId }))
      }
    } finally {
      dispatch(setXeroConfigLoading(false))
    }
  }
}
