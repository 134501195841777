import { replaceOthersLeaveRecords, setOthersLeaveRecordsLoading } from '../../reducers'
import { apiGetOthersLeaveRecords } from '../../api/ssleave.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchOthersLeaveRecords = (options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().my.othersLeaveRecordsLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().my.othersLeaveRecords?.ids.length > 0
      if (hasData) return
    }

    try {
      dispatch(setOthersLeaveRecordsLoading(true))
      const { result, status } = await apiGetOthersLeaveRecords()
      if (status) dispatch(replaceOthersLeaveRecords(result))
    } finally {
      dispatch(setOthersLeaveRecordsLoading(false))
    }
  }
}
