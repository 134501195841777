import { createSelector, Dictionary } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { PayItemState } from '~/features/master/types'
import { StoreState } from '~/types/store'
import { PayTranGroup, PayTranSource } from '~/constants'
import { PayRecordSgState, PayRecordRow, PayTranSgState } from '../types'

export const selectPayRecordRows = createSelector(
  (state: StoreState) => state.payroll.payRecords,
  (state: StoreState) => state.payroll.payTrans,
  (state: StoreState) => state.master.payItems,
  (payRecordsState, payTransState, payItemsState) =>
    memoize(
      (payRunId: string, employeeId: string): PayRecordRow[] => {
        const payRecord = (Object.values(payRecordsState[payRunId]?.entities || {}) as PayRecordSgState[]).find(
          rec => rec.employeeId === employeeId && rec.payRunId === payRunId
        )

        const result: PayRecordRow[] = []

        if (payRecord) {
          const payTrans = Object.values(payTransState[payRecord.id]?.entities || {}) as PayTranSgState[]

          const {
            id: payRecordId,
            payRunId,
            employeeId,
            basicPay,
            recurring,
            overtime,
            leave,
            adhoc,
            fund,
            cpfEm,
            cpfEmOrdinary,
            cpfEmAdditional,
            cpfEmAdj,
            netPay,
            cpfEr,
            cpfErOrdinary,
            cpfErAdditional,
            cpfErAdj,
            sdl,
            fwl
          } = payRecord

          result.push(
            {
              group: PayTranGroup.basicPay,
              sign: basicPay >= 0 ? '+' : '-',
              adjustmentRefCode: 'bp_adj',
              payRecordId,
              payRunId,
              employeeId,
              name: 'Basic pay',
              amount: Math.abs(basicPay),
              trans: getPayTrans(payTrans, PayTranGroup.basicPay, payItemsState.entities)
            },
            {
              group: PayTranGroup.recurring,
              sign: recurring >= 0 ? '+' : '-',
              payRecordId,
              payRunId,
              employeeId,
              name: 'Recurring',
              amount: Math.abs(recurring),
              trans: getPayTrans(payTrans, PayTranGroup.recurring, payItemsState.entities)
            },
            {
              group: PayTranGroup.overtime,
              sign: overtime >= 0 ? '+' : '-',
              payRecordId,
              payRunId,
              employeeId,
              name: 'Overtime',
              amount: Math.abs(overtime),
              trans: getPayTrans(payTrans, PayTranGroup.overtime, payItemsState.entities)
            },
            {
              group: PayTranGroup.leave,
              sign: leave >= 0 ? '+' : '-',
              payRecordId,
              payRunId,
              employeeId,
              name: 'Leave payment',
              amount: Math.abs(leave),
              trans: getPayTrans(payTrans, PayTranGroup.leave, payItemsState.entities)
            },
            {
              group: PayTranGroup.adhoc,
              sign: adhoc >= 0 ? '+' : '-',
              payRecordId,
              payRunId,
              employeeId,
              name: 'Ad hoc',
              amount: Math.abs(adhoc),
              trans: getPayTrans(payTrans, PayTranGroup.adhoc, payItemsState.entities)
            },
            {
              group: PayTranGroup.fund,
              sign: fund > 0 ? '-' : '+', // deduction
              payRecordId,
              payRunId,
              employeeId,
              name: 'SHG fund',
              amount: Math.abs(fund),
              trans: getPayTrans(payTrans, PayTranGroup.fund, payItemsState.entities)
            },
            {
              group: PayTranGroup.cpfEmployee,
              sign: cpfEm > 0 ? '-' : '+', // deduction
              payRecordId,
              payRunId,
              employeeId,
              name: 'CPF employee',
              amount: Math.abs(cpfEm),
              trans: getCpfEmployeeTrans(payRunId, employeeId, cpfEmOrdinary, cpfEmAdditional, cpfEmAdj)
            },
            {
              group: PayTranGroup.netPay,
              sign: '=',
              payRecordId,
              payRunId,
              employeeId,
              name: 'Net pay',
              amount: netPay,
              trans: []
            },

            {
              group: PayTranGroup.cpfEmployer,
              sign: '',
              payRecordId,
              payRunId,
              employeeId,
              name: 'CPF employer',
              amount: cpfEr,
              trans: getCpfEmployerTrans(payRunId, employeeId, cpfErOrdinary, cpfErAdditional, cpfErAdj)
            },
            {
              group: PayTranGroup.sdl,
              sign: '',
              adjustmentRefCode: 'sdl_adj',
              payRecordId,
              payRunId,
              employeeId,
              name: 'Skill development levy (SDL)',
              amount: sdl,
              trans: []
            },
            {
              group: PayTranGroup.fwl,
              sign: '',
              adjustmentRefCode: 'fwl_adj',
              payRecordId,
              payRunId,
              employeeId,
              name: 'Foreign worker levy (FWL)',
              amount: fwl,
              trans: []
            }
          )
        }

        return result
      },
      (payRunId: string, employeeId: string) => `${payRunId}|${employeeId}`
    )
)

const getPayTrans = (payTrans: PayTranSgState[], group: string, payItemsDict: Dictionary<PayItemState>) =>
  payTrans
    .filter(rec => rec.group === group)
    .sort((a, b) => {
      const aName = payItemsDict[a.payItemId]?.name || ''
      const bName = payItemsDict[b.payItemId]?.name || ''
      return aName.localeCompare(bName)
    })

const getCpfEmployeeTrans = (
  payRunId: string,
  employeeId: string,
  cpfEmOrdinary: number,
  cpfEmAdditional: number,
  cpfEmAdj: number
) => {
  const result = [
    {
      id: 'cpf_em_o',
      payRunId,
      employeeId,
      payItemId: 'CPF employee ordinary',
      group: PayTranGroup.cpfEmployee,
      groupName: 'CPF employee',
      quantity: cpfEmOrdinary,
      amount: cpfEmOrdinary,
      sequence: 0,
      source: PayTranSource.pyCpf,
      batchId: ''
    },
    {
      id: 'cpf_em_a',
      payRunId,
      employeeId,
      payItemId: 'CPF employee additional',
      group: PayTranGroup.cpfEmployee,
      groupName: 'CPF employee',
      quantity: cpfEmAdditional,
      amount: cpfEmAdditional,
      sequence: 0,
      source: PayTranSource.pyCpf,
      batchId: ''
    }
  ]

  if (cpfEmAdj !== 0) {
    result.push({
      id: 'cpf_em_adj',
      payRunId,
      employeeId,
      payItemId: 'CPF employee YE adjustment',
      group: PayTranGroup.cpfEmployee,
      groupName: 'CPF employee',
      quantity: cpfEmAdj,
      amount: cpfEmAdj,
      sequence: 0,
      source: PayTranSource.yeCpf,
      batchId: ''
    })
  }

  return result
}

const getCpfEmployerTrans = (
  payRunId: string,
  employeeId: string,
  cpfErOrdinary: number,
  cpfErAdditional: number,
  cpfErAdj: number
) => {
  const result: PayTranSgState[] = [
    {
      id: 'cpf_er_o',
      payRunId,
      employeeId,
      payItemId: 'CPF employer ordinary',
      group: PayTranGroup.cpfEmployer,
      groupName: 'CPF employer',
      quantity: cpfErOrdinary,
      amount: cpfErOrdinary,
      sequence: 0,
      source: PayTranSource.pyCpf,
      batchId: ''
    },
    {
      id: 'cpf_er_a',
      payRunId,
      employeeId,
      payItemId: 'CPF employer additional',
      group: PayTranGroup.cpfEmployer,
      groupName: 'CPF employer',
      quantity: cpfErAdditional,
      amount: cpfErAdditional,
      sequence: 0,
      source: PayTranSource.pyCpf,
      batchId: ''
    }
  ]

  if (cpfErAdj !== 0) {
    result.push({
      id: 'cpf_er_adj',
      payRunId,
      employeeId,
      payItemId: 'CPF employee YE adjustment',
      group: PayTranGroup.cpfEmployee,
      groupName: 'CPF employee',
      quantity: cpfErAdj,
      amount: cpfErAdj,
      sequence: 0,
      source: PayTranSource.yeCpf,
      batchId: ''
    })
  }

  return result
}
