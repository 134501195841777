import { replaceLeaveRecordDtls, setLeaveRecordDtlsLoading } from '../reducers'
import { apiGetLeaveRecordDtls } from '../api/leave-record.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchLeaveRecordDtls = (id: string, options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const loading = getState().leave.leaveRecordDtlsLoading[id]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().leave.leaveRecordDtls[id]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setLeaveRecordDtlsLoading({ id, loading: true }))
      const { result, status } = await apiGetLeaveRecordDtls(id)
      if (status) dispatch(replaceLeaveRecordDtls({ id, data: result }))
    } finally {
      dispatch(setLeaveRecordDtlsLoading({ id, loading: false }))
    }
  }
}
