import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchXeroTenants } from '../actions'
import { XeroTenantState } from '../types'
import { selectXeroTenants } from '../selectors'

export const useXeroTenants = (fetchStrategy: FetchStrategy = 'when-empty'): [XeroTenantState[], boolean] => {
  const tenants = useSelector(selectXeroTenants)
  const loading = useSelector((state: StoreState) => state.payroll.xeroTenantsLoading)

  useEffect(() => {
    dispatch(fetchXeroTenants({ strategy: fetchStrategy }))
  }, [fetchStrategy])

  return [tenants, loading]
}
