import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { EditOutlined, SortAscendingOutlined } from '@ant-design/icons'
import { Button, Card, ColumnsType, Link, LinkGroup, Menu, PageHeader, SecondaryLink, Table } from '~/core-components'
import { Col, Row, DocumentTitle } from '~/components'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { DrawerCloseReason } from '~/types/common'
import { MutateFormulaDrawer } from './components/MutateFormulaDrawer'
import { MutateFormulaDetailDrawer } from './components/MutateFormulaDetailDrawer'
import { fetchFormulas, fetchFormulaDetails } from '../actions'
import { FormulaState, FormulaDetailState } from '../types'
import { selectFormulaById, selectFormulaDetails } from '../reducers'
import { selectFormulas } from '../selectors'
import './Formulas.less'

interface FormulasProps {}

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: '',
    breadcrumbName: 'Overview'
  }
]

export type FormulaDetailTable = FormulaDetailState

interface DrawerState {
  visible: boolean
  data?: FormulaState
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

interface DetailDrawerState {
  visible: boolean
  formulaId?: string
  data?: FormulaDetailState
}

const DEFAULT_DETAIL_DRAWER_STATE: DetailDrawerState = { visible: false }

const cardStyle: CSSProperties = { margin: 24 }
const cardBodyStyle: CSSProperties = { padding: 0 }
const menuStyle = { height: '100%' }
const menuItemStyle: CSSProperties = {
  height: 'auto',
  lineHeight: 'initial',
  padding: 16,
  borderBottom: '1px solid #f2f2f2',
  marginTop: 0,
  marginBottom: 0
}
const wrappedTextStyle: CSSProperties = { whiteSpace: 'normal' }
const marginStyle: CSSProperties = { margin: 24 }

export const Formulas: FC<FormulasProps> = () => {
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const [detailDrawerState, setDetailDrawerState] = useState<DetailDrawerState>(DEFAULT_DETAIL_DRAWER_STATE)
  const [formulaId, setFormulaId] = useState<string>()
  const formulas = useSelector(selectFormulas)
  const formula = useSelector((state: StoreState) => (formulaId ? selectFormulaById(state, formulaId) : undefined))
  const formulaDetails = useSelector((state: StoreState) => selectFormulaDetails(state))
  const formulaDetailsLoading = useSelector((state: StoreState) => state.formula.formulaDetailLoading)
  const canModify = usePermissionGate(Permission.payMaster, PermissionAction.Modify)

  useEffect(() => {
    dispatch(fetchFormulas())
  }, [])

  useEffect(() => {
    if (formulaId) dispatch(fetchFormulaDetails(formulaId))
  }, [formulaId])

  useEffect(() => {
    if (formulaId == null) {
      const formulaKeys = Object.keys(formulas)
      if (formulaKeys.length > 0) {
        setFormulaId(formulaKeys[0])
      }
    }
  }, [formulas, formulaId])

  const handleMenuItemSelected = useCallback((formulaId: string) => {
    setFormulaId(formulaId)
  }, [])

  const handleAddFormula = useCallback(() => {
    setDrawerState({ visible: true, data: undefined })
  }, [])

  const handleEditFormula = useCallback(() => {
    setDrawerState({ visible: true, data: formula })
  }, [formula])

  const handleCloseDrawer = useCallback((reason: DrawerCloseReason, newFormulaId: string) => {
    setDrawerState(DEFAULT_DRAWER_STATE)

    if (reason === 'delete') {
      setFormulaId(undefined)
    } else if (reason === 'add' && newFormulaId) {
      setFormulaId(newFormulaId)
    }
  }, [])

  const handleAddFormulaDetail = useCallback(() => {
    setDetailDrawerState({ visible: true, formulaId, data: undefined })
  }, [formulaId])

  const handleEditFormulaDetail = useCallback(
    (master: FormulaDetailState) => {
      setDetailDrawerState({ visible: true, formulaId, data: master })
    },
    [formulaId]
  )

  const handleCloseDetailDrawer = useCallback(() => {
    setDetailDrawerState(DEFAULT_DETAIL_DRAWER_STATE)
  }, [])

  const columns: ColumnsType<FormulaDetailTable> = [
    {
      title: <SortAscendingOutlined />,
      key: 'sequence',
      dataIndex: 'sequence',
      align: 'right',
      width: 50
    },
    {
      title: 'Expression',
      key: 'expression',
      dataIndex: 'expression',
      width: '50%'
    },
    {
      title: 'Criteria',
      key: 'criteria',
      dataIndex: 'criteria',
      width: '50%'
    },
    {
      key: 'action',
      align: 'right',
      render: (value: string, record) =>
        canModify && (
          <LinkGroup>
            <Link size="small" onClick={() => handleEditFormulaDetail(record)}>
              edit
            </Link>
          </LinkGroup>
        )
    }
  ]

  return (
    <div className="formulas">
      <DocumentTitle title="Formula" />
      <PageHeader title="Formula" breadcrumb={{ routes }} />
      <Card style={cardStyle} bodyStyle={cardBodyStyle}>
        <Row>
          <Col span={6}>
            <Menu mode="inline" selectedKeys={formulaId ? [formulaId] : []} style={menuStyle}>
              {Object.values(formulas).map(
                (formula?: FormulaState) =>
                  formula && (
                    <Menu.Item
                      key={formula.id}
                      style={menuItemStyle}
                      onClick={info => handleMenuItemSelected(info.key as string)}
                    >
                      <div style={wrappedTextStyle}>{formula.name}</div>
                    </Menu.Item>
                  )
              )}
              {canModify && (
                <div key="new" style={menuItemStyle}>
                  <Button type="dashed" block onClick={handleAddFormula}>
                    Add formula
                  </Button>
                </div>
              )}
            </Menu>
          </Col>
          <Col span={18}>
            <Row style={marginStyle}>
              <Col>
                {formula?.description}
                {canModify && (
                  <SecondaryLink className="payrun__header-edit" onClick={handleEditFormula}>
                    <EditOutlined />
                  </SecondaryLink>
                )}
              </Col>
            </Row>
            <Table
              rowKey="id"
              dataSource={formulaDetails}
              pagination={false}
              columns={columns}
              loading={formulaDetailsLoading}
            />
            {canModify && (
              <div style={marginStyle}>
                <Button type="dashed" block onClick={handleAddFormulaDetail}>
                  Add expression
                </Button>
              </div>
            )}
          </Col>
        </Row>
      </Card>
      {canModify && <MutateFormulaDrawer {...drawerState} onClose={handleCloseDrawer} />}
      {canModify && <MutateFormulaDetailDrawer {...detailDrawerState} onClose={handleCloseDetailDrawer} />}
    </div>
  )
}
