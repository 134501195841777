import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectMyLoginAndPermissions } from '~/features/iam'
import { dispatch } from '~/stores/store'
import { StoreState } from '../../../types/store'
import { fetchPermittedEmployeePay } from '../actions'

export const useIsPermittedEmployeePay = (employeeId: string): boolean => {
  const myJwt = useSelector(selectMyLoginAndPermissions)
  const permittedEmployeePay = useSelector((state: StoreState) => state.employee.permittedEmployeePay)
  const isPermitted = myJwt?.isOwner || myJwt?.isSuperUser || permittedEmployeePay.includes(employeeId)

  useEffect(() => {
    dispatch(fetchPermittedEmployeePay())
  }, [])

  return isPermitted
}
