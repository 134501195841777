import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiDeletePayItem } from '../api/payitem.api'
import { removePayItem, removeKeyvalue } from '../reducers'

export const deletePayItem = (id: string): ThunkResult<void> => {
  return async dispatch => {
    if (!id) return

    const { status, errors, message, errorData } = await apiDeletePayItem(id)
    if (status) {
      dispatch(removePayItem(id))
      dispatch(removeKeyvalue({ kv: 'payItem', id }))

      showSuccess('Deleted')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
