import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { EmTeamState, IEmTeam } from '../types'

const baseUrl = getBaseUrl('/employee/employee')

export const apiGetEmTeams = (employeeId: string) => request<EmTeamState[]>('get', `${baseUrl}/${employeeId}/team`)

export const apiGetEmTeam = (employeeId: string, emTeamId: string) =>
  request<EmTeamState>('get', `${baseUrl}/${employeeId}/team/${emTeamId}`)

export const apiAddEmTeam = (employeeId: string, emTeam: IEmTeam) =>
  request<{ id: string; emTeamId?: string }>('post', `${baseUrl}/${employeeId}/team`, emTeam)

export const apiUpdateEmTeam = (employeeId: string, id: string, emTeam: Operation[]) =>
  request<{ emTeamId?: string }>('patch', `${baseUrl}/${employeeId}/team/${id}`, emTeam)

export const apiDeleteEmTeam = (employeeId: string, id: string) =>
  request<{ emTeamId?: string }>('delete', `${baseUrl}/${employeeId}/team/${id}`)
