import { FC, useEffect } from 'react'

interface DocumentTitleProps {
  title: string
}

export const DocumentTitle: FC<DocumentTitleProps> = ({ title }) => {
  useEffect(() => {
    document.title = title + ' - Zealys'
  }, [title])

  return null
}
