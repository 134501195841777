import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { apiUpdateMaster } from '../api/master.api'
import { MasterState, MasterName } from '../types'
import { setKeyvalue, setMaster } from '../reducers'

export const updateMaster = <T>(masterName: MasterName, id: string, before: T, request: T): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!masterName || !id) return

    const after = { ...before, ...request } as T
    const patch = generateJsonPatch<T>(before, after)
    const { status, errors, message, errorData } = await apiUpdateMaster(masterName, id, patch)

    if (status) {
      let master = getState().master.masters[masterName]?.entities[id]
      const updated = { ...(master as MasterState), ...after }
      dispatch(setMaster({ masterName, data: updated }))

      if (masterName === 'payGroup' && updated) {
        const { id, name, inactiveDate } = updated
        dispatch(setKeyvalue({ kv: 'payGroup', data: { key: id, value: name, inactiveDate } }))
      }

      if (masterName === 'leaveGroup' && updated) {
        const { id, name, inactiveDate } = updated
        dispatch(setKeyvalue({ kv: 'leaveGroup', data: { key: id, value: name, inactiveDate } }))
      }

      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
