import React, { forwardRef, useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { Select, SelectProps } from '~/core-components'
import { useFirstInView } from '~/hooks'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { DropdownActions } from './DropdownActions'
import { fetchMyCalViewAs, selectMyCalViewAs } from '~/features/my'
import './SSCalViewAsKeyValues.less'

export interface SSCalViewAsKeyValuesProps extends SelectProps {
  fetchStrategy?: FetchStrategy
}

const SSCalViewAsKeyValuesInternal = (
  { children, fetchStrategy = 'when-empty', className, ...props }: SSCalViewAsKeyValuesProps,
  ref: React.Ref<any>
) => {
  const classes = classNames('ss-cal-view-as-key-values', { [`${className}`]: className })
  const { ref: inViewRef, inView } = useFirstInView<HTMLDivElement>({ threshold: 0.25 })
  const data = useSelector(selectMyCalViewAs)
  const loading = useSelector((state: StoreState) => state.my.myCalViewAsLoading)

  useEffect(() => {
    if (inView) {
      dispatch(fetchMyCalViewAs({ strategy: fetchStrategy }))
    }
  }, [inView, fetchStrategy])

  const handleRefresh = useCallback(() => {
    dispatch(fetchMyCalViewAs())
  }, [])

  return (
    <div ref={inViewRef}>
      <Select
        ref={ref}
        showSearch
        allowClear={false}
        className={classes}
        optionFilterProp="title"
        loading={loading}
        dropdownRender={menu => (
          <div>
            {menu}
            <DropdownActions onRefresh={handleRefresh} />
          </div>
        )}
        {...props}
      >
        {data.map(kv => (
          <Select.Option key={kv!.key} value={kv!.key || ''} title={kv!.value}>
            {kv!.value}
          </Select.Option>
        ))}
      </Select>
    </div>
  )
}

export const SSCalViewAsKeyValues = forwardRef(SSCalViewAsKeyValuesInternal)
