import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiDeleteEmCategory } from '../api/em-category.api'
import { removeEmCategory, setEmployee } from '../reducers'

export const deleteEmCategory = (employeeId: string, id: string): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!employeeId || !id) return

    const { result, status, errors, message, errorData } = await apiDeleteEmCategory(employeeId, id)
    if (status) {
      if (result.emCategoryId) {
        const employee = getState().employee.employees.entities[employeeId]
        if (employee) {
          dispatch(setEmployee({ ...employee, emCategoryId: result.emCategoryId }))
        }
      }

      dispatch(removeEmCategory({ employeeId, id }))
      showSuccess('Deleted')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
