import { showSuccess, showError } from '~/utils'
import { removeMyLeaveTask } from '../../reducers'
import { apiApproveLeave } from '../../api/ssleave-task.api'
import { ThunkResult } from '~/types/store'

export const approveLeave = (id: string, comment: string): ThunkResult<void> => {
  return async dispatch => {
    const { status, result, errors, message, errorData } = await apiApproveLeave(id, comment)

    if (status) {
      dispatch(removeMyLeaveTask(id))
      showSuccess('Approved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
