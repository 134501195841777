import React, { FC } from 'react'
import moment from 'moment-timezone'
import { CriteriaOperator } from '~/constants'
import { SecondaryText } from '~/core-components'

export interface CriteriaInfoProps {
  operator: string
}

export const CriteriaInfo: FC<CriteriaInfoProps> = ({ operator }) => {
  return (
    <>
      {operator === CriteriaOperator.today && <SecondaryText>{moment().format('DD MMM YYYY')}</SecondaryText>}
      {operator === CriteriaOperator.yesterday && (
        <SecondaryText>{moment().subtract(1, 'days').format('DD MMM YYYY')}</SecondaryText>
      )}
      {operator === CriteriaOperator.tomorrow && (
        <SecondaryText>{moment().add(1, 'days').format('DD MMM YYYY')}</SecondaryText>
      )}
      {operator === CriteriaOperator.before_n_today && (
        <SecondaryText>{moment().format('DD MMM YYYY')} & earlier</SecondaryText>
      )}
      {operator === CriteriaOperator.after_today && (
        <SecondaryText>Later than {moment().format('DD MMM YYYY')}</SecondaryText>
      )}
      {operator === CriteriaOperator.next_7_day && (
        <SecondaryText>
          From {moment().format('DD MMM YYYY')} to {moment().add(7, 'days').format('DD MMM YYYY')}
        </SecondaryText>
      )}
      {operator === CriteriaOperator.last_7_day && (
        <SecondaryText>
          From {moment().subtract(7, 'days').format('DD MMM YYYY')} to {moment().format('DD MMM YYYY')}
        </SecondaryText>
      )}
      {operator === CriteriaOperator.this_week && (
        <SecondaryText>
          From {moment().startOf('isoWeek').format('DD MMM YYYY')} to {moment().endOf('isoWeek').format('DD MMM YYYY')}
        </SecondaryText>
      )}
      {operator === CriteriaOperator.last_week && (
        <SecondaryText>
          From {moment().subtract(1, 'week').startOf('isoWeek').format('DD MMM YYYY')} to{' '}
          {moment().subtract(1, 'week').endOf('isoWeek').format('DD MMM YYYY')}
        </SecondaryText>
      )}
      {operator === CriteriaOperator.next_week && (
        <SecondaryText>
          From {moment().add(1, 'week').startOf('isoWeek').format('DD MMM YYYY')} to{' '}
          {moment().add(1, 'week').endOf('isoWeek').format('DD MMM YYYY')}
        </SecondaryText>
      )}
      {operator === CriteriaOperator.this_month && (
        <SecondaryText>
          From {moment().startOf('month').format('DD MMM YYYY')} to {moment().endOf('month').format('DD MMM YYYY')}
        </SecondaryText>
      )}
      {operator === CriteriaOperator.last_month && (
        <SecondaryText>
          From {moment().subtract(1, 'month').startOf('month').format('DD MMM YYYY')} to{' '}
          {moment().subtract(1, 'month').endOf('month').format('DD MMM YYYY')}
        </SecondaryText>
      )}
      {operator === CriteriaOperator.next_month && (
        <SecondaryText>
          From {moment().add(1, 'month').startOf('month').format('DD MMM YYYY')} to{' '}
          {moment().add(1, 'month').endOf('month').format('DD MMM YYYY')}
        </SecondaryText>
      )}
      {operator === CriteriaOperator.this_year && (
        <SecondaryText>
          From {moment().startOf('year').format('DD MMM YYYY')} to {moment().endOf('year').format('DD MMM YYYY')}
        </SecondaryText>
      )}
      {operator === CriteriaOperator.last_year && (
        <SecondaryText>
          From {moment().subtract(1, 'year').startOf('year').format('DD MMM YYYY')} to{' '}
          {moment().subtract(1, 'year').endOf('year').format('DD MMM YYYY')}
        </SecondaryText>
      )}
      {operator === CriteriaOperator.next_year && (
        <SecondaryText>
          From {moment().add(1, 'year').startOf('year').format('DD MMM YYYY')} to{' '}
          {moment().add(1, 'year').endOf('year').format('DD MMM YYYY')}
        </SecondaryText>
      )}
      {operator === CriteriaOperator.this_quarter && (
        <SecondaryText>
          From {moment().startOf('quarter').format('DD MMM YYYY')} to {moment().endOf('quarter').format('DD MMM YYYY')}
        </SecondaryText>
      )}
      {operator === CriteriaOperator.last_quarter && (
        <SecondaryText>
          From {moment().subtract(1, 'quarter').startOf('quarter').format('DD MMM YYYY')} to{' '}
          {moment().subtract(1, 'quarter').endOf('quarter').format('DD MMM YYYY')}
        </SecondaryText>
      )}
      {operator === CriteriaOperator.next_quarter && (
        <SecondaryText>
          From {moment().add(1, 'quarter').startOf('quarter').format('DD MMM YYYY')} to{' '}
          {moment().add(1, 'quarter').endOf('quarter').format('DD MMM YYYY')}
        </SecondaryText>
      )}
    </>
  )
}
