import { ActionResult, ThunkResult } from '~/types/store'
import { emptyGuid } from '~/constants'
import { showSuccess, showError } from '~/utils'
import { apiAddEmTeam } from '../api/em-team.api'
import { setEmTeam, setEmployee } from '../reducers'
import { IEmTeamInfo, EmTeamState } from '../types'

export const addEmTeam = (employeeId: string, emTeam: IEmTeamInfo): ThunkResult<void> => {
  return async (dispatch, getState): Promise<ActionResult | undefined> => {
    if (!employeeId) return

    const { status, result, errors, message, errorData } = await apiAddEmTeam(employeeId, {
      id: emptyGuid,
      employeeId,
      ...emTeam,
      teamId: emTeam.teamId || emptyGuid
    })
    if (status) {
      if (result.emTeamId) {
        const employee = getState().employee.employees.entities[employeeId]
        if (employee) {
          dispatch(setEmployee({ ...employee, emTeamId: result.emTeamId }))
        }
      }

      const newEmTeam = {
        id: result.id,
        employeeId,
        ...emTeam
      } as EmTeamState
      dispatch(setEmTeam({ employeeId, data: newEmTeam }))

      showSuccess('Added')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
