import React, { FC, useCallback, useState } from 'react'
import { Button, Card, ColumnsType, Form, Link, PageHeader, Space, Table, Tag } from '~/core-components'
import { DocumentTitle, SearchInput, InactiveTag } from '~/components'
import { Screen, updateViewCriteria, useFirstView, ViewCriteria, ViewCriteriaSimple } from '~/features/selection'
import { usePermissionGate } from '~/features/iam'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { isInactive } from '~/utils'
import { Permission, PermissionAction } from '~/constants'
import { refetchLocationsView } from '../../reducers'
import { useLocationsView } from '../../hooks'
import { LocationState } from '../../types'
import { MutateLocationDrawer } from './components/MutateLocationDrawer'
import './Locations.less'

interface LocationsProps {}

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: '',
    breadcrumbName: 'Locations'
  }
]

const SCREEN_CODE: Screen = 'location'

interface DrawerState {
  visible: boolean
  data?: LocationState
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

export const Locations: FC<LocationsProps> = () => {
  const [search, setSearch] = useState<string>('')
  const [view, viewLoading] = useFirstView(SCREEN_CODE)
  const viewId = view?.id || ''
  const [data, dataLoading] = useLocationsView(viewId, search)

  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const canModify = usePermissionGate(Permission.location, PermissionAction.Modify)

  const handleAddLocation = useCallback(() => {
    setDrawerState({ visible: true })
  }, [])

  const handleEditLocation = useCallback((location: LocationState) => {
    setDrawerState({ visible: true, data: location })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const handleCriteriaApply = useCallback(
    async (criteria: ViewCriteria[]) => {
      if (viewId) {
        await dispatch(updateViewCriteria(SCREEN_CODE, viewId, { id: viewId, criteria }))
        dispatch(refetchLocationsView())
      }
    },
    [viewId]
  )

  const handleSearch = useCallback((value: string) => {
    setSearch(value)
  }, [])

  const columns: ColumnsType<LocationState> = [
    {
      title: 'Location',
      key: 'name',
      dataIndex: 'name',
      render: (value: string, record) => (
        <Space>
          {value}
          {isInactive(record.inactiveDate) && <InactiveTag />}
        </Space>
      )
    },
    {
      title: 'Tags',
      key: 'tags',
      dataIndex: 'tags',
      render: (value: string[]) => (
        <Space>
          {value?.map(t => (
            <Tag key={t}>{t}</Tag>
          ))}
        </Space>
      )
    },
    {
      title: 'Geofencing',
      key: 'isGeofencingIn',
      dataIndex: 'isGeofencingIn',
      render: (value: string, record) => (
        <>
          {record.isGeofencingIn && <Tag>in</Tag>}
          {record.isGeofencingOut && <Tag>out</Tag>}
        </>
      )
    },
    {
      key: 'action',
      align: 'right',
      render: (value: string, record) => (
        <Link size="small" onClick={() => handleEditLocation(record)}>
          edit
        </Link>
      )
    }
  ]

  return (
    <div id="locations" className="locations">
      <DocumentTitle title="Locations" />
      <PageHeader
        title="Locations"
        containerId="locations"
        breadcrumb={{ routes }}
        extra={
          canModify && (
            <Button key="add" onClick={handleAddLocation}>
              Add location
            </Button>
          )
        }
      />
      <div className="locations__body">
        <div className="locations__action-bar">
          <Form.Item label="">
            <SearchInput onSearch={handleSearch} placeholder="Search by name or tags" expandWidth={250} />
          </Form.Item>
          <ViewCriteriaSimple screenCode={SCREEN_CODE} viewId={viewId} onApply={handleCriteriaApply} label="" />
        </div>
        <Card table>
          <Table
            rowKey="id"
            dataSource={data}
            pagination={false}
            columns={columns}
            loading={dataLoading || viewLoading}
          />
        </Card>
      </div>
      {canModify && <MutateLocationDrawer {...drawerState} onClose={handleCloseDrawer} />}
    </div>
  )
}
