import { EntityState } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { CalendarEvent, KeyValue } from '~/types/common'
import { SSEmployeeState, SSBirthdayState, SSCalendarViewerState } from './ssemployee.state'
import {
  SSLeaveGrantState,
  SSLeaveRecordState,
  SSLeaveRecordDtlState,
  SSLeaveRecordAttachmentState,
  SSLeaveRecordCommentState,
  SSLeaveApplyFormInfoState,
  SSLeaveRecordViewState,
  SSLeaveTaskState,
  SSOthersLeaveRecordState,
  SSOthersBackInState,
  SSCalendarLeaveRecordState,
  SSLeaveRecordApprovalHistoryState
} from './ssleave.state'
import { SSLeaveBalanceState, SSLeaveEntitlementState } from './ssleave-entitlement.state'
import { SSUpcomingEventState } from './sscalendar.state'

import { ROLeEarnedState, ROLeaveEntitlementViewState, ROLeavePeriodYearState } from './roleave-entitlement.state'
import { ROLeaveRecordViewState } from './roleave-record.state'
import { SSRealTimeAttendanceState, SSRealTimeSummaryState } from './ss-time-log.state'

export const myPersistConfig = {
  key: 'my',
  whitelist: [
    'myEmployee',
    'birthdays',
    'myCalViewAs',
    'upcomingEvents',
    'myLeaveBalances',
    'myLeaveBalancesRefetch',
    'myLeaveEntitlements',
    'myLeaveEntitlementsRefetch',
    'myLeaveGrants',
    'myLeaveCalendarEvents',
    'myLeaveCalendarEventsRefetch',
    'myLeaveRecords',
    'myLeaveRecordsView',
    'myLeaveRecordsViewRefetch',
    'myLeaveRecordDtls',
    'myLeaveRecordAttachments',
    'myLeaveRecordComments',
    'myLeaveApplyFormInfo',
    'myLeaveTasks',
    'myLeaveTasksRefetch',
    'roLeaveEntitlementsView',
    'roLeaveEntitlementsViewRefetch',
    'roLeavePeriodYears',
    'roLeEarned',
    'roLeaveRecordsView',
    'roLeaveRecordsViewRefetch',
    'othersLeaveRecords',
    'othersBackInDays'
  ],
  storage
}

export interface MyRootState {
  myEmployee: SSEmployeeState
  myEmployeeLoading: boolean
  birthdays: EntityState<SSBirthdayState>
  birthdaysLoading: boolean
  upcomingEvents: EntityState<SSUpcomingEventState>
  upcomingEventsLoading: boolean
  myCalendarViewers: EntityState<SSCalendarViewerState>
  myCalendarViewersLoading: boolean
  myCalViewAs: EntityState<KeyValue>
  myCalViewAsLoading: boolean

  // leave
  myLeaveBalances: EntityState<SSLeaveBalanceState>
  myLeaveBalancesLoading: boolean
  myLeaveBalancesRefetch: number
  myLeaveEntitlements: EntityState<SSLeaveEntitlementState>
  myLeaveEntitlementsLoading: boolean
  myLeaveEntitlementsRefetch: number
  myLeaveGrants: EntityState<SSLeaveGrantState>
  myLeaveGrantsLoading: boolean
  myLeaveCalendarEvents: EntityState<CalendarEvent>
  myLeaveCalendarEventsLoading: boolean
  myLeaveCalendarEventsRefetch: number
  myLeaveRecords: EntityState<SSLeaveRecordState>
  myLeaveRecordsLoading: boolean
  myLeaveRecordsView: SSLeaveRecordViewState
  myLeaveRecordsViewLoading: boolean
  myLeaveRecordsViewRefetch: number
  myLeaveRecordDtls: { [id: string]: EntityState<SSLeaveRecordDtlState> }
  myLeaveRecordDtlsLoading: { [id: string]: boolean }
  myLeaveRecordApprovalHistories: { [id: string]: EntityState<SSLeaveRecordApprovalHistoryState> }
  myLeaveRecordApprovalHistoriesLoading: { [id: string]: boolean }
  myLeaveRecordAttachments: { [id: string]: EntityState<SSLeaveRecordAttachmentState> }
  myLeaveRecordAttachmentsLoading: { [id: string]: boolean }
  myLeaveRecordComments: { [id: string]: EntityState<SSLeaveRecordCommentState> }
  myLeaveRecordCommentsLoading: { [id: string]: boolean }
  myLeaveApplyFormInfo: { [id: string]: EntityState<SSLeaveApplyFormInfoState> }
  myLeaveApplyFormInfoLoading: { [id: string]: boolean }
  myLeaveTasks: EntityState<SSLeaveTaskState>
  myLeaveTasksLoading: boolean
  myLeaveTasksRefetch: number
  othersLeaveRecords: EntityState<SSOthersLeaveRecordState>
  othersLeaveRecordsLoading: boolean
  othersBackInDays: EntityState<SSOthersBackInState>
  othersBackInDaysLoading: boolean
  myCalendarLeaveEvents: EntityState<CalendarEvent>
  myCalendarLeaveEventsLoading: boolean
  myCalendarLeaveEventsRefetch: number
  myCalendarLeaveRecords: EntityState<SSCalendarLeaveRecordState>
  myCalendarLeaveRecordsLoading: boolean
  myCalendarLeaveRecordsRefetch: number
  roLeaveEntitlementsView: EntityState<ROLeaveEntitlementViewState>
  roLeaveEntitlementsViewLoading: boolean
  roLeaveEntitlementsViewRefetch: number
  roLeavePeriodYears: EntityState<ROLeavePeriodYearState>
  roLeavePeriodYearsLoading: boolean
  roLeEarned: { [entId: string]: EntityState<ROLeEarnedState> }
  roLeEarnedLoading: { [entId: string]: boolean }
  roLeaveRecordsView: EntityState<ROLeaveRecordViewState>
  roLeaveRecordsViewLoading: boolean
  roLeaveRecordsViewRefetch: number

  // attendance
  realTimeAttendance: { [id: string]: EntityState<SSRealTimeAttendanceState> }
  realTimeAttendanceLoading: { [id: string]: boolean }
  realTimeAttendanceRefetch: number
  realTimeSummary: SSRealTimeSummaryState | undefined
  realTimeSummaryLoading: boolean
  realTimeSummaryRefetch: number
}
