import React, { FC } from 'react'
import classNames from 'classnames'
import moment from 'moment-timezone'
import { CardProps, List } from '~/core-components'
import { CardBanner, Col, Row } from '~/components'
import { SSUpcomingEventState } from '../../../../types'
import { useUpcomingEvents } from '../../../../hooks'
import './UpcomingEvents.less'

interface UpcomingEventsProps extends CardProps {}

export const UpcomingEvents: FC<UpcomingEventsProps> = ({ className, ...props }) => {
  const classes = classNames('upcoming-events', { [`${className}`]: className })
  const [upcomingEvents, loading] = useUpcomingEvents()

  return (
    <CardBanner title="Upcoming events" image="/upcoming-events.jpeg" className={classes} loading={loading} {...props}>
      {upcomingEvents.length === 0 && upcomingEvents.length === 0 && !loading && <>No upcoming event until next week</>}
      {upcomingEvents.length > 0 && (
        <List
          size="small"
          dataSource={upcomingEvents}
          renderItem={(item: SSUpcomingEventState) => (
            <List.Item key={item.id}>
              <Row>
                <Col span={12}>{item.name}</Col>
                <Col span={12} className="col-right">
                  {moment(item.date).format('DD MMM YYYY, ddd')}
                </Col>
              </Row>
            </List.Item>
          )}
        />
      )}
    </CardBanner>
  )
}
