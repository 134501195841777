import { showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiPrepareEncashImport } from '../api/encash-import.api'
import { replaceEncashImport } from '../reducers'

export const prepareEncashImport = (payRunId: string, encashType: string, upToDate: string): ThunkResult<void> => {
  return async dispatch => {
    const { status, result, errors, message, errorData } = await apiPrepareEncashImport(payRunId, encashType, upToDate)
    if (status) {
      dispatch(replaceEncashImport(result))
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors, result }
  }
}
