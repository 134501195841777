import React, { FC } from 'react'
import { PageHeader } from '~/core-components'
import { DocumentTitle } from '~/components'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { LveCalendarConfig } from '~/features/leave/containers/LveCalendarConfig/LveCalendarConfig'
import './CalendarConfig.less'

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: '',
    breadcrumbName: 'Organisation calendar'
  }
]

interface CalendarConfigProps {}

export const CalendarConfig: FC<CalendarConfigProps> = () => {
  return (
    <div id="calendar-config" className="calendar-config">
      <DocumentTitle title="Organisation Calendar" />
      <PageHeader title="Organisation calendar" containerId="calendar-config" breadcrumb={{ routes }} />
      <div className="calendar-config__body">
        <LveCalendarConfig />
      </div>
    </div>
  )
}
