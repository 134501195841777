import React, { CSSProperties, ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { useSelector } from 'react-redux'
import { EditOutlined } from '@ant-design/icons'
import moment from 'moment-timezone'
import confirm from 'antd/lib/modal/confirm'
import classNames from 'classnames'
import {
  Badge,
  Button,
  Form,
  Input,
  PageHeader,
  SecondaryLink,
  Space,
  Steps,
  Tab,
  TabTitle,
  Tabs,
  Tooltip
} from '~/core-components'
import { DocumentTitle } from '~/components'
import { setPayRunExpanded, usePayRun } from '~/features/payroll'
import { useMyLogin, usePermissionGate } from '~/features/iam'
import { Permission, PermissionAction } from '~/constants'
import { PayRunStatus } from '~/constants'
import { PAY_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { ActionResult, StoreState } from '~/types/store'
import { deletePayRun, completePayRun, revertToPaymentPayRun, fetchPayWarnings } from '../../actions'
import { fetchSysRuleCriteria } from '~/features/rule'
import { refetchPaySummary, refetchPayslips, refetchPayRecords } from '../../reducers'
import { useCompany, usePayGroup } from '~/features/master/hooks'
import { selectPayWarningsCount } from '../../selectors'
import { PayRecords } from './PayRecords'
import { PaySnapshot } from './PaySnapshot'
import { PayWarning } from '../PayRules/PayWarning'
import { PaySummary } from '../PaySummary/PaySummary'
import { Payslip } from '../Payslip/Payslip'
import { EditPayRunDrawer } from './EditPayRunDrawer'
import { PayRunState } from '../../types'
import './PayRun.less'

export interface PayRunProps {}

interface PayRunParams {
  id: string
  tab: string
}

interface DrawerState {
  visible: boolean
  data?: PayRunState
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }
const FEAT_SNAPSHOT_USERS = ['nelly.khoo@zealys.com', 'tosco.ong@zealys.com']

const routes = [
  {
    path: PAY_ROUTES.tab.replace(':tab?', 'run'),
    breadcrumbName: 'Overview'
  },
  {
    path: '',
    breadcrumbName: 'Payroll run'
  }
]

const payRunStatusMap = {
  [PayRunStatus.verification]: 0,
  [PayRunStatus.payment]: 1,
  [PayRunStatus.completed]: 2
}

export const PayRun: FC<PayRunProps> = () => {
  const { id, tab } = useParams<PayRunParams>()
  const [description, setDescription] = useState<string | undefined>()
  const [updating, setUpdating] = useState(false)
  const [payRun] = usePayRun(id, 'always')
  const [payDate, setPayDate] = useState<string | undefined>()
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const expanded = useSelector((state: StoreState) => state.payroll.payRunExpanded)
  const warningCount = useSelector(selectPayWarningsCount)(id)
  const [myLogin] = useMyLogin()
  const history = useHistory()
  const canDelete = usePermissionGate(Permission.payRun, PermissionAction.Delete)
  const canModify = usePermissionGate(Permission.payRun, PermissionAction.Modify)
  const [payGroup] = usePayGroup(payRun?.payGroupId || '', 'when-empty')
  const [company] = useCompany(payGroup?.companyId || '', 'when-empty')
  const classes = classNames('payrun', { 'payrun--expanded': expanded })

  useEffect(() => {
    dispatch(fetchPayWarnings(id))
  }, [id])

  useEffect(() => {
    dispatch(fetchSysRuleCriteria('pay_warning', { strategy: 'when-empty' }))
  }, [])

  useEffect(() => {
    setDescription(payRun?.description)
    setPayDate(`Pay date on ${moment(payRun?.payDate).format('DD MMM YYYY')}`)
  }, [payRun])

  const handleEditPayRun = useCallback(() => {
    setDrawerState({ visible: true, data: payRun })
  }, [payRun])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const handleDeletePayRun = useCallback(() => {
    const modal = confirm({
      title: 'Delete payroll run',
      content: (
        <>
          <div>Do you want to delete payroll run "{payRun?.description || ''}"?</div>
          <br />
          <div>This will also delete (if any) the following:</div>
          <div>&#9;- Payroll records</div>
          <div>&#9;- Adjustment</div>
          <div>&#9;- Import</div>
          <div>&#9;- Leave payment</div>
          <Form.Item
            className="payrun__confirm-delete"
            label={
              <>
                Type "<b>{payRun?.description}</b>" to confirm
              </>
            }
          >
            <Input
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                modal.update({ okButtonProps: { disabled: event.target.value !== payRun?.description } })
              }}
            />
          </Form.Item>
        </>
      ),
      onOk: async () => {
        const result: ActionResult | undefined = await dispatch(deletePayRun(id))
        if (!result?.errors) {
          history.push(PAY_ROUTES.tab.replace(':tab?', 'run'))
        }
      },
      width: 550,
      okText: 'Delete',
      okType: 'danger',
      okButtonProps: {
        disabled: true
      }
    })
  }, [id, payRun, history])

  const handleCompletePayRun = useCallback(async () => {
    setUpdating(true)
    try {
      await dispatch(completePayRun(id))
    } finally {
      setUpdating(false)
    }
  }, [id])

  const handleRevertToPaymentPayRun = useCallback(async () => {
    setUpdating(true)
    try {
      await dispatch(revertToPaymentPayRun(id))
      dispatch(refetchPayRecords())
    } finally {
      setUpdating(false)
    }
  }, [id])

  const handleTabChange = useCallback(
    (activeKey: string) => {
      if (activeKey === 'summary') {
        dispatch(refetchPaySummary())
      } else if (activeKey === 'records') {
        dispatch(refetchPayRecords())
      } else if (activeKey === 'payslip') {
        dispatch(refetchPayslips())
      }
      history.push(`${PAY_ROUTES.payRun.replace(':id', id).replace(':tab?', activeKey)}`)
    },
    [id, history]
  )

  const handleEnablePayment = useCallback(() => {
    history.push(`${PAY_ROUTES.payRun.replace(':id', id).replace(':tab?', tab)}`)
  }, [id, tab, history])

  const tabItems = useMemo(() => {
    const result: Tab[] = [
      {
        key: 'records',
        label: 'Records',
        children: <PayRecords payRunId={id} onEnablePayment={handleEnablePayment} />
      }
    ]

    if (FEAT_SNAPSHOT_USERS.includes(myLogin?.email || '')) {
      result.push({ key: 'snapshot', label: 'Snapshot', children: <PaySnapshot payRunId={id} /> })
    }

    result.push({
      key: 'warning',
      label: (
        <>
          Warnings
          <Badge count={warningCount} className="warning-badge" />
        </>
      ),
      children: <PayWarning payRunId={id} />
    })

    if (payRun?.status === PayRunStatus.payment || payRun?.status === PayRunStatus.completed) {
      result.push({ key: 'payslip', label: 'Payslip', children: <Payslip payRunId={id} /> })
    }

    if (payRun?.status !== PayRunStatus.verification) {
      result.push({ key: 'summary', label: 'Summary', children: <PaySummary payRunId={id} /> })
    }

    return result
  }, [id, handleEnablePayment, myLogin, payRun, warningCount])

  return (
    <div className={classes}>
      {!expanded && (
        <>
          <DocumentTitle title="Payroll Run" />
          <PageHeader
            title={
              <div className="payrun__header">
                <div className="payrun__header-title">
                  {description} <span className="payrun__header-dot">&#9675;</span> {company?.name}
                </div>
                <div>
                  {payDate}
                  {payRun?.status !== PayRunStatus.completed && (
                    <SecondaryLink className="payrun__header-edit" onClick={handleEditPayRun}>
                      <EditOutlined />
                    </SecondaryLink>
                  )}
                </div>
              </div>
            }
            breadcrumb={{ routes }}
            extra={
              <Steps
                progressDot
                size="small"
                current={payRunStatusMap[payRun?.status || PayRunStatus.verification]}
                items={[{ title: 'Verification' }, { title: 'Payment & statutory' }, { title: 'Completed' }]}
              />
            }
          />
        </>
      )}
      <div className="payrun__body">
        <Tabs
          defaultActiveKey={tab || 'records'}
          onChange={handleTabChange}
          tabBarExtraContent={{
            left: expanded ? (
              <TabTitle left={<PayRunExpandButton isExpand={expanded} />} title={payRun?.description} />
            ) : (
              <PayRunExpandButton isExpand={expanded} />
            ),
            right: (
              <Space>
                {payRun?.status !== PayRunStatus.completed && canDelete && (
                  <Button size="small" onClick={handleDeletePayRun}>
                    Delete payroll run
                  </Button>
                )}
                {payRun?.status === PayRunStatus.payment && canModify && (
                  <Button type="primary" size="small" onClick={handleCompletePayRun} loading={updating}>
                    Mark as complete
                  </Button>
                )}
                {payRun?.status === PayRunStatus.completed && canModify && (
                  <Button size="small" onClick={handleRevertToPaymentPayRun} loading={updating}>
                    Revert completion
                  </Button>
                )}
              </Space>
            )
          }}
          items={tabItems}
        />
      </div>
      {canModify && <EditPayRunDrawer {...drawerState} onClose={handleCloseDrawer} />}
    </div>
  )
}
const expandButtonStyle: CSSProperties = { marginRight: 16 }

const PayRunExpandButton: FC<{ isExpand: boolean }> = ({ isExpand }) => {
  const handleExpand = useCallback(() => {
    dispatch(setPayRunExpanded(!isExpand))
  }, [isExpand])

  return (
    <Tooltip title={isExpand ? 'Normal view' : 'Maximise view'}>
      <SecondaryLink style={expandButtonStyle} onClick={handleExpand}>
        {isExpand ? (
          <i className="fal fa-arrow-down-left-and-arrow-up-right-to-center" />
        ) : (
          <i className="fal fa-arrow-up-right-and-arrow-down-left-from-center" />
        )}
      </SecondaryLink>
    </Tooltip>
  )
}
