import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { MyTenantState } from '../types'

export const selectMyTenants = createSelector(
  (state: StoreState) => state.iam.myTenants,
  (myTenantsState): MyTenantState[] => {
    return Object.values(myTenantsState.entities || {}).sort((a, b) =>
      a!.tenantName?.localeCompare(b!.tenantName)
    ) as MyTenantState[]
  }
)
