import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { CardProps } from '~/core-components'
import { CardBanner } from '~/components'
import { FeatureFlag } from '~/constants'
import { StoreState } from '~/types/store'
import { MyLeaveApplyButton } from '../../../Leave/MyLeaveApply/MyLeaveApplyButton'
import { MyLeaveBalanceList } from '../../../MyProfile/components/MyLeave/components/MyLeaveBalance/MyLeaveBalanceList'
import { MySsAccessGate } from '../../../MySsAccessGate'
import { MyLeaveGrantBalanceList } from '../../../MyProfile/components/MyLeave/components/MyLeaveGrantBalance/MyLeaveGrantBalanceList'
import './MyLeaveEntitlementOverview.less'

interface MyLeaveEntitlementOverviewProps extends CardProps {}

export const MyLeaveEntitlementOverview: FC<MyLeaveEntitlementOverviewProps> = ({ className, ...props }) => {
  const classes = classNames('my-leave-entitlement-overview', { [`${className}`]: className })
  const loading = useSelector((state: StoreState) => state.my.myLeaveBalancesLoading)

  return (
    <MySsAccessGate featureFlag={FeatureFlag.MyLeave}>
      <CardBanner
        title="My leave balance"
        image="/my-leave-balance-background.jpeg"
        loading={loading}
        className={classes}
        {...props}
      >
        <MyLeaveGrantBalanceList />
        <MyLeaveBalanceList showEmptyMsg={false} />
        <MyLeaveApplyButton>Apply leave</MyLeaveApplyButton>
      </CardBanner>
    </MySsAccessGate>
  )
}
