import { useCallback, useEffect, useState } from 'react'
import { DEFAULT_HOOK_OPTIONS, HookOptions } from '~/types/common'
import { ITaxRate } from '../types'
import { apiGetCurrentTaxRate } from '../api/tax-rate.api'

const EMPTY_TAX_RATE: ITaxRate = {
  startDate: '',
  currencyCode: '',
  taxRate: 0
}

export const useCurrentTaxRate = (
  currencyCode: string,
  asAtDate: string,
  options: HookOptions = DEFAULT_HOOK_OPTIONS
): [ITaxRate, boolean] => {
  const [taxRate, setTaxRate] = useState<ITaxRate>(EMPTY_TAX_RATE)
  const [loading, setLoading] = useState(false)

  const fetchCurrentTaxRate = useCallback(async () => {
    try {
      setLoading(true)
      const { result } = await apiGetCurrentTaxRate(currencyCode, asAtDate)
      setTaxRate(result)
    } finally {
      setLoading(false)
    }
  }, [currencyCode, asAtDate])

  useEffect(() => {
    if (options.enabled) fetchCurrentTaxRate()
  }, [fetchCurrentTaxRate, options.enabled])

  return [taxRate, loading]
}
