import { showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiVerifyPayWarning } from '../api/payroll.api'
import { PayWarningState } from '../types'
import { setPayWarning } from '../reducers'

export const verifyPayWarning = (payRunId: string, id: string): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const { result, status, errors, message, errorData } = await apiVerifyPayWarning(id)

    if (status) {
      const payWarning = getState().payroll.payWarnings[payRunId]?.entities[id]
      const data: PayWarningState = { ...(payWarning as PayWarningState), ...result }
      dispatch(setPayWarning({ payRunId, data }))
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
