import { replaceExpenseTypes, setExpenseTypesLoading } from '../reducers'
import { apiGetExpenseTypes } from '../api/expense-type.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchExpenseTypes = (options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().claim.expenseTypesLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().claim.expenseTypes?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setExpenseTypesLoading(true))
      const { result, status } = await apiGetExpenseTypes()
      if (status) {
        dispatch(replaceExpenseTypes(result))
      }
    } finally {
      dispatch(setExpenseTypesLoading(false))
    }
  }
}
