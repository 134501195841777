import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Alert, ColumnsType, Table } from '~/core-components'
import { StoreState } from '~/types/store'
import { formatDateRange, formatNumberUnit, UnitType } from '~/utils'
import { LeaveGrantBulkRowState } from '~/features/leave/types'
import './LeaveGrantBulkRecord.less'

interface LeaveGrantBulkRecordProps {
  visible: boolean
  success?: boolean
}

export const LeaveGrantBulkRecord: FC<LeaveGrantBulkRecordProps> = ({ visible, success }) => {
  const data = useSelector((state: StoreState) => state.leave.leaveGrantBulkVerify?.data || [])

  const columns: ColumnsType<LeaveGrantBulkRowState> = [
    {
      title: 'Employee',
      key: 'employeeNo',
      dataIndex: 'employeeNo',
      render: (value: string, record) => `[${value}] ${record.employeeName}`
    },
    {
      title: 'Leave type',
      key: 'leaveTypeName',
      dataIndex: 'leaveTypeName'
    },
    {
      title: 'Validity period',
      key: 'startDate',
      dataIndex: 'startDate',
      render: (value: string, record: LeaveGrantBulkRowState) => formatDateRange(value, record.endDate)
    },
    {
      title: 'Quantity',
      key: 'earned',
      dataIndex: 'earned',
      render: (value: number, record) => formatNumberUnit(value, record.unit as UnitType)
    },
    {
      title: 'Notes',
      key: 'notes',
      dataIndex: 'notes'
    }
  ]

  return (
    <div className="leave-grant-bulk-record" hidden={!visible}>
      {success && <Alert type="success" showIcon message="The leaves have been granted successfully" />}
      <Table rowKey="employeeId" dataSource={data} columns={columns} pagination={false} />
    </div>
  )
}
