import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiClaimProcess } from '../api/claim-process.api'
import { setClaimLoading } from '../reducers'
import { ClaimProcessDto } from '../types'

export const processClaim = (data: ClaimProcessDto): ThunkResult<void> => {
  return async dispatch => {
    try {
      dispatch(setClaimLoading(true))
      const { status, result, errors, message, errorData } = await apiClaimProcess(data)

      if (status) {
        showSuccess('Process completed')
      } else {
        if (Object.keys(errors).length === 0) {
          showError(message, errorData)
        }
      }

      return { result, errors }
    } finally {
      dispatch(setClaimLoading(false))
    }
  }
}
