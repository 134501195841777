import React, { FC, useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { Form, Tab, TabTitle, Tabs } from '~/core-components'
import { DocumentTitle, SSRoleKeyValues } from '~/components'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { MY_ROUTES } from '~/routes/routes'
import { SsRole, SSRoleScreen } from '~/constants'
import { refetchROLeaveEntitlementsView, refetchROLeaveRecordsView } from '../../../reducers'
import { ROLeaveEntitlement } from './ROLeaveEntitlement/ROLeaveEntitlement'
import { ROLeaveRecord } from './ROLeaveRecord/ROLeaveRecord'
import './ROLeaveOverview.less'

export interface ROLeaveOverviewProps {}

export const ROLeaveOverview: FC<ROLeaveOverviewProps> = () => {
  const match = useRouteMatch<{ tab: string }>()
  const history = useHistory()
  const me = useSelector((state: StoreState) => state.my.myEmployee)
  const canViewEntitlement = [SsRole.ReportingManager, SsRole.MgrLveEntitlement].some(ro => me.roles.includes(ro))
  const canViewRecord = [SsRole.ReportingManager, SsRole.MgrLveRecord].some(ro => me.roles.includes(ro))
  const defaultActiveTab = canViewEntitlement
    ? SSRoleScreen.LeaveEntitlement
    : canViewRecord
    ? SSRoleScreen.LeaveRecord
    : ''
  const activeTab = (match.params.tab || defaultActiveTab) as SSRoleScreen

  const getFirstSsRole = useCallback(
    (activeTab: SSRoleScreen) => {
      const firstRole = me.roles.find(
        role =>
          role === SsRole.ReportingManager ||
          (role === SsRole.MgrLveEntitlement && activeTab === SSRoleScreen.LeaveEntitlement) ||
          (role === SsRole.MgrLveRecord && activeTab === SSRoleScreen.LeaveRecord)
      ) as SsRole
      return firstRole
    },
    [me.roles]
  )

  const firstSsRole = getFirstSsRole(activeTab)
  const [role, setRole] = useState<SsRole>(firstSsRole)

  const handleTabChange = useCallback(
    (activeKey: string) => {
      const firstRole = getFirstSsRole(activeKey as SSRoleScreen)
      setRole(role => {
        if (role !== firstRole) {
          return firstRole
        } else {
          if (activeKey === SSRoleScreen.LeaveEntitlement) {
            dispatch(refetchROLeaveEntitlementsView())
          } else if (activeKey === SSRoleScreen.LeaveRecord) {
            dispatch(refetchROLeaveRecordsView())
          }

          return role
        }
      })

      history.push(`${MY_ROUTES.roLeave.replace(':tab?', activeKey)}`)
    },
    [getFirstSsRole, history]
  )

  const tabItems = useMemo(() => {
    const result: Tab[] = []
    if (canViewEntitlement) {
      result.push({
        key: SSRoleScreen.LeaveEntitlement,
        label: 'Entitlement',
        children: <ROLeaveEntitlement role={role} isActive={activeTab === SSRoleScreen.LeaveEntitlement} />
      })
    }
    if (canViewRecord) {
      result.push({
        key: SSRoleScreen.LeaveRecord,
        label: 'Records',
        children: <ROLeaveRecord role={role} isActive={activeTab === SSRoleScreen.LeaveRecord} />
      })
    }

    return result
  }, [role, canViewEntitlement, canViewRecord, activeTab])

  return (
    <div className="roleave-overview">
      <DocumentTitle title="Leave Overview" />
      <div className="roleave-overview__body">
        <Tabs
          defaultActiveKey={activeTab}
          activeKey={activeTab}
          onChange={handleTabChange}
          tabBarExtraContent={{
            left: <TabTitle title="Leave overview" />,
            right: (
              <Form.Item label="View as">
                <SSRoleKeyValues screenCode={activeTab} value={role} onChange={setRole} size="small" />
              </Form.Item>
            )
          }}
          items={tabItems}
        />
      </div>
    </div>
  )
}
