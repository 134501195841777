import omit from 'lodash/omit'
import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiSaveManager } from '../api/manager.api'
import { ManagerCategory, IManager } from '../types'
import { Screen, fetchViewSchema } from '~/features/selection'
import { fetchManager } from './fetch-manager'
import { emptyGuid } from '~/constants'

export const saveManager = (screenCode: Screen, category: ManagerCategory, request: IManager): ThunkResult<void> => {
  return async dispatch => {
    if (!category) return

    request = { ...request, id: request.id || emptyGuid, managerId: request.managerId || emptyGuid, category }
    const { status, result, errors, message, errorData } = await apiSaveManager(category, omit(request, 'viewId'))

    if (status) {
      const id = result?.id || request.id
      if (id) {
        dispatch(fetchManager(category, id))
      }

      if (request.viewId) {
        dispatch(fetchViewSchema(screenCode, request.viewId))
      }

      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
