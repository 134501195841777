import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { EmShiftRoleState, IEmShiftRole } from '../types'

const baseUrl = getBaseUrl('/employee/employee')

export const apiGetEmShiftRoles = (employeeId: string) =>
  request<EmShiftRoleState[]>('get', `${baseUrl}/${employeeId}/shiftrole`)

export const apiGetEmShiftRole = (employeeId: string, emShiftRoleId: string) =>
  request<EmShiftRoleState>('get', `${baseUrl}/${employeeId}/shiftrole/${emShiftRoleId}`)

export const apiAddEmShiftRole = (employeeId: string, emShiftRole: IEmShiftRole) =>
  request<{ id: string; emShiftRoleId?: string }>('post', `${baseUrl}/${employeeId}/shiftrole`, emShiftRole)

export const apiUpdateEmShiftRole = (employeeId: string, id: string, emShiftRole: Operation[]) =>
  request<{ emShiftRoleId?: string }>('patch', `${baseUrl}/${employeeId}/shiftrole/${id}`, emShiftRole)

export const apiDeleteEmShiftRole = (employeeId: string, id: string) =>
  request<{ emShiftRoleId?: string }>('delete', `${baseUrl}/${employeeId}/shiftrole/${id}`)
