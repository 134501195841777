import { setEmCostCentre, setEmCostCentresLoading } from '../reducers'
import { apiGetEmCostCentre } from '../api/em-cost-centre.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchEmCostCentre = (
  employeeId: string,
  emCostCentreId: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!employeeId || !emCostCentreId) return

    const loading = getState().employee.emCostCentresLoading[employeeId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().employee.emCostCentres[employeeId]?.entities[emCostCentreId]
      if (hasData) return
    }

    try {
      dispatch(setEmCostCentresLoading({ employeeId, loading: true }))
      const { result, status } = await apiGetEmCostCentre(employeeId, emCostCentreId)
      if (status) {
        dispatch(setEmCostCentre({ employeeId, data: result }))
      }
    } finally {
      dispatch(setEmCostCentresLoading({ employeeId, loading: false }))
    }
  }
}
