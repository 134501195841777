import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Drawer, Space, Steps } from '~/core-components'
import { Col, Row } from '~/components'
import { LVE_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors } from '~/types/store'
import { preparePendingLeave, prepareNextEntitlement, executeLeaveClosing, processLeave } from '../../../actions'
import { PendingLeaveResultState, LeaveClosingResultState, IPendingAction, ApprovalAction } from '../../../types'
import { refetchLeaveEntitlementsView } from '../../../reducers'
import { LeaveClosingInput } from './LeaveClosingInput'
import { PendingLeaveAction } from './PendingLeaveAction'
import { NextEntitlement } from './NextEntitlement'
import './LeaveClosingDrawer.less'

interface LeaveClosingDrawerProps {
  visible: boolean
  onClose: () => void
}

export interface PendingAction {
  id: string
  action: ApprovalAction
}

export interface LeaveClosingFormData {
  periodCode: string
  leaveTypeIds: string[]
  pendingActions: IPendingAction[]
}

const EMPTY_FORM_DATA: LeaveClosingFormData = {
  periodCode: '',
  leaveTypeIds: [],
  pendingActions: []
}

const { Step } = Steps

type StepKey = 'input' | 'pendingaction' | 'verify' | 'complete'

interface IStep {
  key: StepKey
  title: string
  nextButton?: string
  backButton?: string
}

const STEPS: IStep[] = [
  {
    key: 'input',
    title: 'Select leave types'
  },
  {
    key: 'pendingaction',
    title: 'Review pending leave'
  },
  {
    key: 'verify',
    title: 'Verify',
    nextButton: 'Execute leave closing'
  },
  {
    key: 'complete',
    title: 'Done',
    nextButton: 'Close'
  }
]

export const LeaveClosingDrawer: FC<LeaveClosingDrawerProps> = ({ visible, onClose }) => {
  const [formData, setFormData] = useState<LeaveClosingFormData>(EMPTY_FORM_DATA)
  const [step, setStep] = useState(0)
  const [nextLoading, setNextLoading] = useState(false)
  const [errors, setErrors] = useState<Errors>()

  const hasPendingActions = useMemo(() => {
    return STEPS[step].key === 'pendingaction' && formData.pendingActions.length > 0
  }, [step, formData.pendingActions])

  useEffect(() => {
    setStep(0)
    setErrors(undefined)
    setFormData(EMPTY_FORM_DATA)
  }, [visible])

  const handlePrev = useCallback(() => {
    setErrors(undefined)
    setStep(step => step - 1)
  }, [])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setErrors(undefined)
    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  const preparePendingLeaveStep = useCallback(async () => {
    const errorMessages: string[] = []
    if (!formData.periodCode) {
      errorMessages.push('Please select leave period')
    }
    if (!formData.leaveTypeIds || formData.leaveTypeIds.length === 0) {
      errorMessages.push('Please select leave type')
    }

    if (errorMessages.length > 0) {
      setErrors({ '*': errorMessages })
      return
    }

    let result: ActionResult<PendingLeaveResultState> | undefined
    try {
      setNextLoading(true)
      result = await dispatch(preparePendingLeave(formData.periodCode, formData.leaveTypeIds))

      const pending = result?.result?.pending || []
      handleFormDataChange({ pendingActions: pending.map(p => ({ id: p.id, action: 'a' })) })
    } finally {
      setNextLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      setErrors(undefined)
      setStep(step => step + 1)
    }
  }, [formData, handleFormDataChange])

  const prepareNextEntitlementStep = useCallback(async () => {
    const errorMessages: string[] = []
    if (!formData.periodCode) {
      errorMessages.push('Please select leave period')
    }
    if (!formData.leaveTypeIds || formData.leaveTypeIds.length === 0) {
      errorMessages.push('Please select leave type')
    }

    if (errorMessages.length > 0) {
      setErrors({ '*': errorMessages })
      return
    }

    let result: ActionResult<PendingLeaveResultState> | undefined
    try {
      setNextLoading(true)
      result = await dispatch(prepareNextEntitlement(formData.periodCode, formData.leaveTypeIds))
    } finally {
      setNextLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      setErrors(undefined)
      setStep(step => step + 1)
    }
  }, [formData])

  const executeLeaveClosingStep = useCallback(async () => {
    let result: ActionResult<LeaveClosingResultState> | undefined
    try {
      setNextLoading(true)
      result = await dispatch(executeLeaveClosing(formData.periodCode, formData.leaveTypeIds, formData.pendingActions))
    } finally {
      setNextLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      setErrors(undefined)
      setStep(step => step + 1)

      if (result?.result) {
        const { employeeIds, leaveTypeIds } = result.result
        if (employeeIds && leaveTypeIds) {
          await dispatch(processLeave({ employeeIds, leaveTypeIds, silentError: false }))
          dispatch(refetchLeaveEntitlementsView())
        }
      }
    }
  }, [formData])

  const handleCloseDrawer = useCallback(() => {
    typeof onClose === 'function' && onClose()
  }, [onClose])

  const handleNext = useCallback(() => {
    if (STEPS[step].key === 'input') {
      preparePendingLeaveStep()
    } else if (STEPS[step].key === 'pendingaction') {
      prepareNextEntitlementStep()
    } else if (STEPS[step].key === 'verify') {
      executeLeaveClosingStep()
    } else if (STEPS[step].key === 'complete') {
      handleCloseDrawer()
    }
  }, [step, preparePendingLeaveStep, prepareNextEntitlementStep, executeLeaveClosingStep, handleCloseDrawer])

  const handleReviewPendingClick = useCallback(() => {
    window.open(`${LVE_ROUTES.tab.replace(':tab?', 'pendings')}`, '_blank')
  }, [])

  return (
    <Drawer
      open={visible}
      title="Leave closing"
      onClose={handleCloseDrawer}
      width={1030}
      className="leave-closing-drawer"
      footer={
        <Row>
          <Col flex={1}>
            {hasPendingActions && <Button onClick={handleReviewPendingClick}>Review pending leave</Button>}
          </Col>
          <Col>
            <Space className="leave-closing-drawer__action-bar-buttons">
              {STEPS[step - 1] && step !== STEPS.length - 1 && (
                <Button onClick={handlePrev}>{STEPS[step].backButton || 'Back'}</Button>
              )}
              {STEPS[step] && (
                <Button type="primary" onClick={handleNext} loading={nextLoading} disabled={hasPendingActions}>
                  {STEPS[step].nextButton || 'Next'}
                </Button>
              )}
            </Space>
          </Col>
        </Row>
      }
    >
      <Steps progressDot size="small" current={step}>
        {STEPS.map(s => (
          <Step key={s.key} title={s.title} />
        ))}
      </Steps>
      <LeaveClosingInput
        visible={STEPS[step].key === 'input'}
        data={formData}
        onChange={handleFormDataChange}
        errors={errors}
      />
      <PendingLeaveAction
        visible={STEPS[step].key === 'pendingaction'}
        data={formData}
        onChange={handleFormDataChange}
        errors={errors}
      />
      <NextEntitlement
        visible={['verify', 'complete'].includes(STEPS[step].key)}
        success={STEPS[step].key === 'complete'}
      />
    </Drawer>
  )
}
