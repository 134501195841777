import { showSuccess, showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { apiAddWorkflow } from '../api/workflow.api'
import { setWorkflow } from '../reducers'
import { IWorkflow, WfCategory, WorkflowState } from '../types'

export const addWorkflow = (category: WfCategory, workflow: IWorkflow): ThunkResult<void> => {
  return async (dispatch): Promise<ActionResult> => {
    const { status, result, errors, message, errorData } = await apiAddWorkflow(category, workflow)
    if (status) {
      const newWorkflow: WorkflowState = { id: result.id, ...workflow, category }
      dispatch(setWorkflow({ category, data: newWorkflow }))

      showSuccess('Added')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
