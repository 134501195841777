import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import moment from 'moment-timezone'
import { StoreState } from '~/types/store'

export const selectEmEducations = createSelector(
  (state: StoreState) => state.employee.emEducations,
  emEducationsState =>
    memoize((employeeId?: string) => {
      if (!employeeId) return []

      return Object.values(emEducationsState[employeeId]?.entities || {}).sort((a, b) => {
        const aEndDate = parseInt(moment(a!.endDate).format('YYYY')) || 0
        const bEndDate = parseInt(moment(b!.endDate).format('YYYY')) || 0
        const aGraduationYear = parseInt(a!.graduationYear) || aEndDate
        const bGraduationYear = parseInt(b!.graduationYear) || bEndDate

        return bGraduationYear - aGraduationYear
      })
    })
)
