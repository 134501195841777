import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import persistReducer from 'redux-persist/es/persistReducer'
import { KeyValue } from '~/types/common'
import { StoreState } from '~/types/store'
import { jobPersistConfig, JobRootState, JobViewState, SysJobState } from './types'

const jobsViewAdapter = createEntityAdapter<JobViewState>({ selectId: model => model.viewId })
const sysJobsAdapter = createEntityAdapter<SysJobState>({ selectId: model => model.code })

const jobsViewInitialState = jobsViewAdapter.getInitialState()
const sysJobsInitialState = sysJobsAdapter.getInitialState()

const keyValuesAdapter = createEntityAdapter<KeyValue>({ selectId: model => model.key })
const keyValueInitialState = keyValuesAdapter.getInitialState()

const initialState: JobRootState = {
  // jobs view
  jobsViewExpanded: false,
  jobsViewRefetch: 0,
  jobsView: jobsViewInitialState,
  jobsViewLoading: false,

  // sys jobs
  sysJobs: sysJobsInitialState,
  sysJobsLoading: false,

  // key values
  keyValues: {},
  keyValuesParams: {},
  keyValuesLoading: {}
}

const jobSlice = createSlice({
  name: 'job',
  initialState,
  reducers: {
    // jobs view
    setJobsViewExpanded: (state, action: PayloadAction<boolean>) => {
      state.jobsViewExpanded = action.payload
    },
    refetchJobsView: state => {
      state.jobsViewRefetch += 1
    },
    setJobsViewLoading: (state, action: PayloadAction<boolean>) => {
      state.jobsViewLoading = action.payload
    },
    setJobsView: (state, action: PayloadAction<JobViewState>) => {
      jobsViewAdapter.upsertOne(state.jobsView, action.payload)
    },

    // sys job
    replaceSysJobs: (state, action: PayloadAction<SysJobState[]>) => {
      sysJobsAdapter.setAll(state.sysJobs, action.payload)
    },
    setSysJobsLoading: (state, action: PayloadAction<boolean>) => {
      state.sysJobsLoading = action.payload
    },

    // key values
    setKeyValuesLoading: (state, action: PayloadAction<{ kv: string; loading: boolean }>) => {
      state.keyValues[action.payload.kv] = state.keyValues[action.payload.kv] || keyValueInitialState
      state.keyValuesLoading[action.payload.kv] = action.payload.loading
    },
    setKeyValue: (state, action: PayloadAction<{ kv: string; data: KeyValue }>) => {
      state.keyValues[action.payload.kv] = state.keyValues[action.payload.kv] || keyValueInitialState
      keyValuesAdapter.upsertOne(state.keyValues[action.payload.kv]!, action.payload.data)
    },
    replaceKeyValues: (state, action: PayloadAction<{ kv: string; data: KeyValue[]; params: string }>) => {
      state.keyValuesParams[action.payload.kv] = action.payload.params
      state.keyValues[action.payload.kv] = state.keyValues[action.payload.kv] || keyValueInitialState
      keyValuesAdapter.setAll(state.keyValues[action.payload.kv]!, action.payload.data)
    },
    removeKeyValue: (state, action: PayloadAction<{ kv: string; id: string }>) => {
      state.keyValues[action.payload.kv] = state.keyValues[action.payload.kv] || keyValueInitialState
      keyValuesAdapter.removeOne(state.keyValues[action.payload.kv]!, action.payload.id)
    }
  }
})

export const {
  // jobs view
  setJobsViewExpanded,
  refetchJobsView,
  setJobsViewLoading,
  setJobsView,

  // sys jobs
  replaceSysJobs,
  setSysJobsLoading,

  // key values
  setKeyValuesLoading,
  setKeyValue,
  replaceKeyValues,
  removeKeyValue
} = jobSlice.actions

export const { selectById: selectSysJobByCode } = sysJobsAdapter.getSelectors((state: StoreState) => state.job.sysJobs)

export const jobReducers = { job: persistReducer<JobRootState>(jobPersistConfig, jobSlice.reducer) }
